function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import { resetPassword } from '@/api/risma/password';
import { LogoSettings } from '@/constants/admin/CustomizationSettings';
export default {
  name: 'ResetPassword',
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification
  },
  token: '<reset-password />',
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      isResetSuccess: false,
      password: '',
      verifyPassword: '',
      notificationMessages: '',
      invalidToken: false,
      notificationType: 'error',
      translate: getTranslate['ResetPassword']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    logoSrc: function logoSrc() {
      return LogoSettings.DEFAULT_WITH_BLUE_TEXT;
    }
  }),
  methods: {
    onResetPassword: function onResetPassword() {
      var _this = this;
      this.notificationMessages = '';
      var password = this.password.trim();
      var verifyPassword = this.verifyPassword.trim();
      if (this.isValidPassword(password, verifyPassword)) {
        resetPassword(this.token, {
          newPassword: password
        }).then(function () {
          _this.isResetSuccess = true;
        }).catch(function (error) {
          if (error.response === 'Invalid token') {
            _this.invalidToken = true;
          } else {
            if (Array.isArray(error.response)) {
              var errors = error.response.map(function (error) {
                return _this.$trans(error);
              });
              _this.notificationMessages = errors[0] + ': ' + errors.slice(1).join(', ');
            } else {
              _this.notificationMessages = error.response;
            }
          }
        });
      }
    },
    isValidPassword: function isValidPassword(password, verifyPassword) {
      if (!password) {
        this.notificationMessages = this.translate.pleaseFillOutPasswordField;
        return false;
      }
      if (password && verifyPassword === '') {
        this.notificationMessages = this.translate.repeatTheNewPassword;
        return false;
      }
      if (password !== verifyPassword) {
        this.notificationMessages = this.translate.passwordsDoesNotMatch;
        return false;
      }
      return true;
    },
    goToLogin: function goToLogin() {
      this.$router.push('/');
    }
  }
};