import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex items-center"
};
var _hoisted_2 = {
  class: "mr-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tag_item = _resolveComponent("tag-item");
  return $data.items.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.filterTagsLabel) + ":", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, function (item, key) {
    return _openBlock(), _createBlock(_component_tag_item, {
      key: key,
      title: item.name,
      class: "mr-2",
      onClose: function onClose($event) {
        return $options.removeItem(item);
      }
    }, null, 8 /* PROPS */, ["title", "onClose"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true);
}