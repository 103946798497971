import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.attributesFormatted, function (attribute, attrIdx) {
    return _openBlock(), _createElementBlock("div", {
      key: attrIdx,
      class: "flex flex-wrap items-center break-all glob-b1 text-rm-text mb-1"
    }, [_createElementVNode("p", {
      class: "font-semibold mr-1",
      title: attribute.key
    }, [_createElementVNode("span", null, _toDisplayString(attribute.key + ':'), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute.items, function (attr, idx) {
      return _openBlock(), _createElementBlock("span", {
        key: idx,
        class: "text-rm-text"
      }, _toDisplayString(attr + (attribute.items.length > idx + 1 ? ', ' : '')), 1 /* TEXT */);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))]);
}