function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { updateUser } from '@/api/admin/user';
import ChatHeader from '@/components/Organisms/AiAssistant/ChatHeader';
import ChatInputField from '@/components/Organisms/AiAssistant/ChatInputField';
import ChatMessage from '@/components/Organisms/AiAssistant/ChatMessage';
import CitationModal from '@/components/Organisms/AiAssistant/CitationModal';
import ChatEllipsisMenu from '@/components/Organisms/AiAssistant/ChatEllipsisMenu';
import ChatConfirmationDialog from '@/components/Organisms/AiAssistant/ChatConfirmationDialog';
import { SENDER_ROLES, CONFIRMATION_TYPES } from '@/components/Organisms/AiAssistant/utils';
import { throttle } from '@/utils/Utils';
export default {
  name: 'AiChatWidget',
  components: {
    ChatHeader: ChatHeader,
    ChatInputField: ChatInputField,
    ChatMessage: ChatMessage,
    CitationModal: CitationModal,
    ChatEllipsisMenu: ChatEllipsisMenu,
    ChatConfirmationDialog: ChatConfirmationDialog
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false
    },
    chatHistory: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    region: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  emits: ['minimize', 'submit', 'clear:history', 'close', 'change:region', 'reload:message', 'delete:message'],
  data: function data() {
    return {
      isConfirmationVisible: false,
      confirmationType: '',
      activeCitation: null,
      resetOnlyHistory: false,
      confirmationContent: [],
      width: 300,
      height: 440,
      isResizing: false,
      resizeVertically: false,
      cursorStyle: '',
      translate: {
        typeYourQuestion: this.$trans('Type your message'),
        yourQuestionAbout: this.$trans('Your question about the'),
        aiGeneratedContentMayBe: this.$trans('AI generated content may be incorrect')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isAiTermsAccepted: function isAiTermsAccepted() {
      return this.currentUser.aiTermsAccepted;
    },
    relevantQuestions: function relevantQuestions() {
      return this.chatHistory.filter(function (message) {
        return !message.isGeneralMessage;
      });
    },
    lastMessage: function lastMessage() {
      return this.relevantQuestions[this.relevantQuestions.length - 1] || {};
    },
    isLastMessageWithSource: function isLastMessageWithSource() {
      var _this$lastMessage;
      return !!((_this$lastMessage = this.lastMessage) !== null && _this$lastMessage !== void 0 && _this$lastMessage.source);
    },
    inputPlaceholder: function inputPlaceholder() {
      if (this.isLastMessageWithSource) {
        var _this$lastMessage$sou = this.lastMessage.source.split(' > '),
          _this$lastMessage$sou2 = _slicedToArray(_this$lastMessage$sou, 3),
          projectName = _this$lastMessage$sou2[0],
          fieldName = _this$lastMessage$sou2[2];
        return "".concat(this.translate.yourQuestionAbout, " ").concat(projectName, ": ").concat(fieldName);
      }
      return this.translate.typeYourQuestion;
    }
  }),
  watch: {
    chatHistory: {
      handler: function handler() {
        this.scrollToBottom();
      },
      deep: true
    },
    isVisible: function isVisible(_isVisible) {
      if (_isVisible) {
        this.scrollToBottom();
        this.focusInputField();
      }
    },
    isAiTermsAccepted: {
      handler: function handler() {
        if (!this.isAiTermsAccepted) {
          this.isConfirmationVisible = true;
          this.confirmationType = CONFIRMATION_TYPES.AI_TERMS;
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.scrollToBottom();
    this.startResizingThrottled = throttle(this.startResizing, 100);
  },
  methods: {
    submitChat: function submitChat(data) {
      this.$emit('submit', _objectSpread({
        sender: SENDER_ROLES.user
      }, data));
    },
    handleSubmit: function handleSubmit(message) {
      var data = {
        message: message
      };
      if (this.lastMessage.source) {
        data.source = this.lastMessage.source;
        data.context = this.lastMessage.context;
      }
      this.submitChat(data);
    },
    resetAndCloseChat: function resetAndCloseChat() {
      this.isConfirmationVisible = false;
      this.$emit('close');
    },
    resetHistory: function resetHistory() {
      this.isConfirmationVisible = false;
      this.$emit('clear:history');
      this.resetOnlyHistory = false;
    },
    scrollToBottom: function scrollToBottom() {
      var _this = this;
      this.$nextTick(function () {
        var wrapper = _this.$refs.chatContent;
        if (wrapper) {
          wrapper.scrollTop = wrapper.scrollHeight;
        }
      });
    },
    focusInputField: function focusInputField() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.$refs.chatInput.$el.querySelector('textarea').focus();
      });
    },
    setActiveCitation: function setActiveCitation(citation) {
      this.activeCitation = this.prepareCitation(citation);
    },
    prepareCitation: function prepareCitation(citation) {
      if (!citation.filepath) {
        return {
          content: citation.content || '',
          title: citation.title || ''
        };
      }
      var fileNameWithExtension = citation.filepath.split('/').pop();
      var title = fileNameWithExtension.split('.').slice(0, -1).join('.');
      fileNameWithExtension = fileNameWithExtension.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      var regex = new RegExp(".*".concat(fileNameWithExtension, "\\s*"));
      var cleanContent = citation.content.replace(regex, '');
      return {
        content: cleanContent,
        title: title.split('_').join(' ')
      };
    },
    initializeResize: function initializeResize(resizeVertically) {
      this.chatWidgetRec = this.$refs.chatWidget.getBoundingClientRect();
      this.chatContentRec = this.$refs.chatContent.getBoundingClientRect();
      this.headerHeight = this.$refs.chatHeader.$el.getBoundingClientRect().height;
      this.resizeVertically = resizeVertically;
      window.addEventListener('mousemove', this.startResizingThrottled, false);
      window.addEventListener('mouseup', this.stopResizing, false);
    },
    startResizing: function startResizing(event) {
      event.preventDefault();
      var minimalPadding = 20;
      this.isResizing = true;
      var chatWidgetRec = this.$refs.chatWidget.getBoundingClientRect();
      var chatContentRec = this.$refs.chatContent.getBoundingClientRect();
      var headerHeight = this.$refs.chatHeader.$el.getBoundingClientRect().height;
      var left = chatWidgetRec.left,
        width = chatWidgetRec.width;
      var top = chatContentRec.top,
        height = chatContentRec.height;
      if (!this.resizeVertically) {
        this.width = left - event.clientX + minimalPadding + width;
      }
      this.height = top - headerHeight - event.clientY + height;
      this.cursorStyle = this.resizeVertically ? 'cursor-ns-resize' : 'cursor-nw-resize';
      document.body.classList.add('no-select', this.cursorStyle);
    },
    stopResizing: function stopResizing() {
      this.startResizingThrottled.flush();
      this.isResizing = false;
      window.removeEventListener('mousemove', this.startResizingThrottled, false);
      window.removeEventListener('mouseup', this.stopResizing, false);
      document.body.classList.remove('no-select', this.cursorStyle);
    },
    canBeReloaded: function canBeReloaded(messageId) {
      var lastMessage = this.relevantQuestions[this.relevantQuestions.length - 1];
      return lastMessage.sender === SENDER_ROLES.system && lastMessage.id === messageId;
    },
    canBeDeleted: function canBeDeleted(message) {
      var lastMessage = this.relevantQuestions[this.relevantQuestions.length - 1];
      return lastMessage.sender === SENDER_ROLES.custom && lastMessage.message === message.message && !!message.source;
    },
    minimizeChat: function minimizeChat() {
      this.$emit('minimize');
      this.closeConfirmation();
    },
    handleConfirmation: function handleConfirmation() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CONFIRMATION_TYPES.RESET;
      this.isConfirmationVisible = true;
      this.confirmationType = type;
    },
    handleClearChatConfirmation: function handleClearChatConfirmation() {
      this.handleConfirmation(CONFIRMATION_TYPES.CLEAR);
    },
    closeConfirmation: function closeConfirmation() {
      this.isConfirmationVisible = false;
    },
    handleChangeRegion: function handleChangeRegion(region) {
      this.isConfirmationVisible = true;
      this.confirmationType = CONFIRMATION_TYPES.REGION;
      this.regionDraft = region;
    },
    changeRegion: function changeRegion() {
      this.$emit('change:region', this.regionDraft);
    },
    clearAndCloseChat: function clearAndCloseChat() {
      this.$emit('close');
    },
    clearHistory: function clearHistory() {
      this.$emit('clear:history');
    },
    acceptAiTerms: function acceptAiTerms() {
      return updateUser({
        ai_terms_accepted: 1
      }, this.currentUser.id);
    },
    handleDismissConfirmation: function handleDismissConfirmation() {
      if (this.confirmationType === CONFIRMATION_TYPES.AI_TERMS) {
        return this.$emit('close');
      }
      this.closeConfirmation();
    },
    handleAcceptConfirmation: function handleAcceptConfirmation() {
      var CONFIRMATION_ACCEPT_EVENT_MAP = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, CONFIRMATION_TYPES.AI_TERMS, this.acceptAiTerms), CONFIRMATION_TYPES.RESET, this.clearAndCloseChat), CONFIRMATION_TYPES.CLEAR, this.clearHistory), CONFIRMATION_TYPES.REGION, this.changeRegion);
      CONFIRMATION_ACCEPT_EVENT_MAP[this.confirmationType]();
      this.closeConfirmation();
    }
  }
};