import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "mb-8"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  class: "mr-32"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_users_activities_list = _resolveComponent("users-activities-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.userAnonymization,
    class: "mb-6"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, _toDisplayString($data.translate.youCanDeactivateAnonymizeOrDoBothAtTheSameTime), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $data.selectedUserId,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.selectedUserId = $event;
    }), $options.onUserSelect],
    title: $data.translate.users,
    options: $options.usersOptions,
    placeholder: $data.translate.selectUser,
    "with-search": true,
    class: "min-w-370px mb-8"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"]), $options.actionOptions.length ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_risma_title, {
    title: "".concat($data.capitalize($data.translate.chooseAction), ":"),
    type: "medium",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_radio_buttons, {
    modelValue: $data.actionSelected,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.actionSelected = $event;
    }),
    options: $options.actionOptions,
    "no-unchecked-value": true,
    class: "mb-8"
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), $data.selectedUserId ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.submit,
    type: "save",
    onClick: $options.onSubmit
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)]), !$data.isLoading ? (_openBlock(), _createBlock(_component_users_activities_list, {
    key: 0,
    "compliance-projects": $data.complianceProjects,
    "risk-projects": $data.riskProjects,
    "selected-user-id": $data.selectedUserId
  }, null, 8 /* PROPS */, ["compliance-projects", "risk-projects", "selected-user-id"])) : _createCommentVNode("v-if", true)])]);
}