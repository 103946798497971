function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import ExpansionPanel from '@/components/Atoms/ExpansionPanel/ExpansionPanel';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
export default {
  name: 'RiskAssessmentRow',
  description: 'This component contains selectors with consequences and probabilities and list of relevant threats',
  components: {
    ExpansionPanel: ExpansionPanel,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink
  },
  inject: {
    isPreview: {
      default: false
    },
    depRelationEnabled: {
      default: false
    }
  },
  props: {
    objType: {
      type: String,
      require: true,
      default: ''
    },
    rowData: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    row: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    },
    startExpanded: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If the panel should start as expanded'
    },
    riskAssessmentGroups: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'data to show under RISK Assessment CIA'
    },
    customThreatsEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    inherentRisksEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    threatUpdateInProgress: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['onValueChange', 'updateCustomThreat', 'updateUnderlyingRisk'],
  data: function data() {
    var _this$rowData$inheren, _this$rowData$inheren2, _this$rowData$residua, _this$rowData$residua2;
    return {
      inherentConsequenceSelected: (_this$rowData$inheren = this.rowData.inherentConsequence) !== null && _this$rowData$inheren !== void 0 ? _this$rowData$inheren : 0,
      inherentProbabilitySelected: (_this$rowData$inheren2 = this.rowData.inherentProbability) !== null && _this$rowData$inheren2 !== void 0 ? _this$rowData$inheren2 : 0,
      residualConsequenceSelected: (_this$rowData$residua = this.rowData.residualConsequence) !== null && _this$rowData$residua !== void 0 ? _this$rowData$residua : 0,
      residualProbabilitySelected: (_this$rowData$residua2 = this.rowData.residualProbability) !== null && _this$rowData$residua2 !== void 0 ? _this$rowData$residua2 : 0,
      showDiscarded: false,
      editingThreatsIds: [],
      RiskBaseModelProps: RiskBaseModelProps,
      translate: getTranslate['RiskAssessmentRow']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return this.getSettingValue('feature.risk_comments_field_on_axis');
    },
    consequenceTitle: function consequenceTitle() {
      var inherentConsequenceTitle = "".concat(this.translate.inherentRisk, " - ").concat(this.row.consequenceLabel.toLowerCase());
      var residualConsequenceTitle = "".concat(this.translate.residualRisk, " - ").concat(this.row.consequenceLabel.toLowerCase());
      var consequenceTitle = this.inherentRisksEnabled ? residualConsequenceTitle : this.row.consequenceLabel;
      return {
        inherent: inherentConsequenceTitle + this.suffixString(this.row.consequenceOrigin),
        residual: consequenceTitle + this.suffixString(this.row.consequenceOrigin)
      };
    },
    probabilityTitle: function probabilityTitle() {
      var inherentProbabilityTitle = "".concat(this.translate.inherentRisk, " - ").concat(this.row.probabilityLabel.toLowerCase());
      var residualProbabilityTitle = "".concat(this.translate.residualRisk, " - ").concat(this.row.probabilityLabel.toLowerCase());
      var probabilityTitle = this.inherentRisksEnabled ? residualProbabilityTitle : this.row.probabilityLabel;
      return {
        inherent: inherentProbabilityTitle + this.suffixString(this.row.probabilityOrigin),
        residual: probabilityTitle + this.suffixString(this.row.probabilityOrigin)
      };
    },
    inherentScore: function inherentScore() {
      return this.inherentConsequenceSelected * this.inherentProbabilitySelected;
    },
    residualScore: function residualScore() {
      return this.residualConsequenceSelected * this.residualProbabilitySelected;
    },
    collapsedHeaderLabel: function collapsedHeaderLabel() {
      if (!this.inherentRisksEnabled) return "".concat(this.translate.riskScore, ": ").concat(this.residualScore);
      return "".concat(this.translate.inherentScore, ": ").concat(this.inherentScore, ", ").concat(this.translate.residualScore, ": ").concat(this.residualScore);
    },
    probabilityReadOnly: function probabilityReadOnly() {
      return this.readOnly || this.row.probabilityOrigin === 1 && this.objType === ObjectTypes.COMPLIANCE_NODE;
    },
    consequenceReadOnly: function consequenceReadOnly() {
      return this.readOnly || this.row.consequenceOrigin === 2 && this.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM;
    },
    consequenceOptionsExtended: function consequenceOptionsExtended() {
      return [{
        label: '0. N/A',
        value: 0
      }].concat(_toConsumableArray(this.row.consequences));
    },
    probabilityOptionsExtended: function probabilityOptionsExtended() {
      return [{
        label: '0. N/A',
        value: 0
      }].concat(_toConsumableArray(this.row.probabilities));
    },
    consequenceOptionsCustom: function consequenceOptionsCustom() {
      return this.row.consequences.map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          label: option.value.toString()
        });
      });
    },
    probabilityOptionsCustom: function probabilityOptionsCustom() {
      return this.row.probabilities.map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          label: option.value.toString()
        });
      });
    },
    discardedThreatsPresent: function discardedThreatsPresent() {
      var _this$riskAssessmentG;
      return (_this$riskAssessmentG = this.riskAssessmentGroups.find(function (group) {
        return group.isCurrentRisk;
      })) === null || _this$riskAssessmentG === void 0 ? void 0 : _this$riskAssessmentG.items.find(function (item) {
        return !item.relevant;
      });
    }
  }),
  methods: {
    suffixString: function suffixString(origin) {
      var suffix = '';
      if (origin === 1 && this.objType === ObjectTypes.COMPLIANCE_NODE) {
        suffix = ' (' + this.translate.maxValueFromUnderlyingSystems + ')';
      }
      if (origin === 2 && this.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM) {
        suffix = ' (' + this.translate.valueIsFromTheParentNode + ')';
      }
      return suffix;
    },
    onValueChange: function onValueChange(value, type) {
      if (this.readOnly) return;
      this.$emit('onValueChange', {
        label: this.row.label,
        id: this.row.id,
        value: value || 0,
        comment: this.rowData["".concat(type, "Comment")]
      }, type);
    },
    onCommentChange: function onCommentChange(value, type) {
      if (this.readOnly) return;
      this.$emit('onValueChange', {
        label: this.row.label,
        id: this.row.id,
        value: this["".concat(type, "Selected")],
        comment: value
      }, type);
    },
    updateCustomThreat: function updateCustomThreat(data) {
      this.$emit('updateCustomThreat', data, this.row.id, true);
    },
    toggleRelevant: function toggleRelevant(data) {
      !this.threatUpdateInProgress && this.updateCustomThreat(_objectSpread(_objectSpread({}, data), {}, {
        relevant: !data.relevant
      }));
    },
    toggleEditingThreat: function toggleEditingThreat(id) {
      this.editingThreatsIds = this.editingThreatsIds.includes(id) ? this.editingThreatsIds.filter(function (threatId) {
        return threatId !== id;
      }) : [].concat(_toConsumableArray(this.editingThreatsIds), [id]);
    },
    getThreatName: function getThreatName(threat) {
      var mark = threat.customThreatId ? '(*)' : '';
      return "".concat(threat.label).concat(mark);
    }
  }
};