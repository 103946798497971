import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex -mb-1.5px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tabs, function (tab, i) {
    return _openBlock(), _createBlock(_component_light_tab_item, {
      key: i,
      "background-class": "bg-zinc-50 z-10",
      to: tab.to
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(_ctx.$trans(tab.label)), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}