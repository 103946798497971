function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var store = {};
export default {
  methods: {
    registerNestable: function registerNestable(nestable) {
      var storeGroup = this._getByGroup(nestable.group);
      storeGroup.onDragStartListeners.push(nestable.onDragStart);
      storeGroup.onMouseEnterListeners.push(nestable.onMouseEnter);
      storeGroup.onMouseMoveListeners.push(nestable.onMouseMove);
    },
    notifyDragStart: function notifyDragStart(group, event, item) {
      var storeGroup = this._getByGroup(group);
      var _iterator = _createForOfIteratorHelper(storeGroup.onDragStartListeners),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var listener = _step.value;
          listener(event, item);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    notifyMouseEnter: function notifyMouseEnter(group, event, eventList, item) {
      var storeGroup = this._getByGroup(group);
      var _iterator2 = _createForOfIteratorHelper(storeGroup.onMouseEnterListeners),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var listener = _step2.value;
          listener(event, eventList, item);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    notifyMouseMove: function notifyMouseMove(group, event) {
      var storeGroup = this._getByGroup(group);
      var _iterator3 = _createForOfIteratorHelper(storeGroup.onMouseMoveListeners),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var listener = _step3.value;
          listener(event);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    },
    _getByGroup: function _getByGroup(group) {
      // the group already exists, return the reference
      if (store[group]) {
        return store[group];
      }

      // otherwise create a new object for the group
      store[group] = {
        onDragStartListeners: [],
        onMouseEnterListeners: [],
        onMouseMoveListeners: [],
        onDragStart: [],
        dragItem: null
      };
      return store[group];
    }
  }
};