/**
 * plugin to add centered text inside doughnut chart
 *
 * @param txt - text to display
 * @param maxFontSize - property to control max font size
 * @param color
 * @param fontStyle
 * @param fontFamily
 * @param padding - inner padding
 * @param borderColor - border color of inner circle
 * @param fillColor - fill color of inner circle
 */
export default {
  id: 'center',
  beforeDraw: function beforeDraw(chart) {
    var _config$options;
    var ctx = chart.ctx;
    var config = chart.config;
    var pluginConfig = (_config$options = config.options) === null || _config$options === void 0 || (_config$options = _config$options.plugins) === null || _config$options === void 0 ? void 0 : _config$options.center;
    if (!pluginConfig || config.type !== 'doughnut') return;
    var cutoutPadding = +config.options.borderWidth + 1 || 3;
    var txt = pluginConfig.text;
    var maxFontSize = pluginConfig.maxFontSize || 60;
    var color = pluginConfig.color || '#000';
    var fontStyle = pluginConfig.fontStyle || 'normal';
    var fontFamily = pluginConfig.fontFamily || 'Inter, sans-serif';
    var padding = pluginConfig.padding || 0;
    var chartWidth = chart.chartArea.left + chart.chartArea.right;
    var chartHeight = chart.chartArea.top + chart.chartArea.bottom;
    var chartMinSide = Math.min(chartWidth, chartHeight);
    var cutoutRadius;
    if (config.options.cutout) {
      if (config.options.cutout.indexOf('%') !== -1) {
        cutoutRadius = parseInt(config.options.cutout) * chartMinSide / 100 / 2 - cutoutPadding;
      } else {
        cutoutRadius = parseInt(config.options.cutout) - cutoutPadding;
      }
    } else {
      cutoutRadius = 50 * chartMinSide / 100 / 2 - cutoutPadding;
    }
    var optimalFontSize = Math.floor(cutoutRadius - cutoutRadius * 0.02 - padding);
    var centerX = chartWidth / 2;
    var centerY = chartHeight / 2;
    if (pluginConfig.borderColor && pluginConfig.fillColor) {
      var borderRadius = pluginConfig.borderRadius || 1;
      var borderColor = pluginConfig.borderColor;
      var fillColor = pluginConfig.fillColor;
      var fillRadius = cutoutRadius - padding;
      if (fillRadius < 1) return;
      ctx.beginPath();
      ctx.arc(centerX, centerY, fillRadius, 0, 2 * Math.PI, false);
      ctx.fillStyle = borderColor;
      ctx.fill();
      ctx.beginPath();
      ctx.arc(centerX, centerY, fillRadius - borderRadius, 0, 2 * Math.PI, false);
      ctx.fillStyle = fillColor;
      ctx.fill();
    }
    ctx.font = fontStyle + ' ' + Math.min(chartHeight, optimalFontSize, maxFontSize) + 'px ' + fontFamily;
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(txt, centerX, centerY);
  }
};