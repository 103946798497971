import { getCompany as _getCompany } from '@/api/admin/companies';
import { VAT_STATUSES, vatStatusLabelByValue } from '@/constants/companies/vatStatuses';
export var LinkedCompanyMixin = {
  data: function data() {
    return {
      linkedCompany: null
    };
  },
  emits: ['companyStatusChange', 'propertyChanged'],
  computed: {
    companyStatusNotificationMessage: function companyStatusNotificationMessage() {
      var _this$linkedCompany;
      return ((_this$linkedCompany = this.linkedCompany) === null || _this$linkedCompany === void 0 ? void 0 : _this$linkedCompany.vatStatus) > VAT_STATUSES.NORMAL.value ? ": ".concat(this.$trans(vatStatusLabelByValue[this.linkedCompany.vatStatus])) : '';
    }
  },
  watch: {
    companyStatusNotificationMessage: function companyStatusNotificationMessage() {
      this.$emit('companyStatusChange', this.companyStatusNotificationMessage);
    }
  },
  methods: {
    getCompany: function getCompany() {
      var _this = this;
      if (!this.companyId) return;
      return _getCompany(this.companyId).then(function (data) {
        return _this.linkedCompany = data;
      });
    },
    linkCompany: function linkCompany(company) {
      this.linkedCompany = company;
      this.$emit('propertyChanged', {
        property: 'companyId',
        value: company.id
      });
    },
    unlinkCompany: function unlinkCompany() {
      this.linkedCompany = null;
      this.$emit('propertyChanged', {
        property: 'companyId',
        value: null
      });
    },
    onCompanyUpdated: function onCompanyUpdated(data) {
      this.linkedCompany = data;
    }
  }
};