function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
export default {
  methods: {
    // ––––––––––––––––––––––––––––––––––––
    // Getter methods
    // ––––––––––––––––––––––––––––––––––––
    getPathById: function getPathById(id) {
      var _this = this;
      var items = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.modelValue;
      var path = [];
      items.every(function (item, i) {
        if (item[_this.keyProp] === id) {
          path.push(i);
        } else if (item[_this.childrenProp]) {
          var childrenPath = _this.getPathById(id, item[_this.childrenProp]);
          if (childrenPath.length) {
            path = path.concat(i).concat(childrenPath);
          }
        }
        return path.length === 0;
      });
      return path;
    },
    getItemByPath: function getItemByPath(path) {
      var _this2 = this;
      var items = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.modelValue;
      var item = null;
      path.forEach(function (index) {
        var list = item && item[_this2.childrenProp] ? item[_this2.childrenProp] : items;
        item = list[index];
      });
      return item;
    },
    getItemDepth: function getItemDepth(item) {
      var level = 1;
      if (item[this.childrenProp] && item[this.childrenProp].length > 0) {
        var childrenDepths = item[this.childrenProp].map(this.getItemDepth);
        level += Math.max.apply(Math, _toConsumableArray(childrenDepths));
      }
      return level;
    },
    getSplicePath: function getSplicePath(path) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var splicePath = {};
      var numToRemove = options.numToRemove || 0;
      var itemsToInsert = options.itemsToInsert || [];
      var lastIndex = path.length - 1;
      var currentPath = splicePath;
      path.forEach(function (index, i) {
        if (i === lastIndex) {
          currentPath.$splice = [[index, numToRemove].concat(_toConsumableArray(itemsToInsert))];
        } else {
          var nextPath = {};
          currentPath[index] = _defineProperty({}, options.childrenProp, nextPath);
          currentPath = nextPath;
        }
      });
      return splicePath;
    },
    getRealNextPath: function getRealNextPath(prevPath, nextPath) {
      var ppLastIndex = prevPath.length - 1;
      var npLastIndex = nextPath.length - 1;
      if (prevPath.length < nextPath.length) {
        // move into deep
        var wasShifted = false;
        return nextPath.map(function (nextIndex, i) {
          if (wasShifted) {
            return i === npLastIndex ? nextIndex + 1 : nextIndex;
          }
          if (typeof prevPath[i] !== 'number') {
            return nextIndex;
          }
          if (nextPath[i] > prevPath[i] && i === ppLastIndex) {
            wasShifted = true;
            return nextIndex - 1;
          }
          return nextIndex;
        });
      } else if (prevPath.length === nextPath.length) {
        // if move bottom + move to item with children => make it a first child instead of swap
        if (nextPath[npLastIndex] > prevPath[npLastIndex]) {
          var target = this.getItemByPath(nextPath);
          if (target[this.childrenProp] && target[this.childrenProp].length && !this.isCollapsed(target)) {
            return nextPath.slice(0, -1).concat(nextPath[npLastIndex] - 1).concat(0);
          }
        }
      }
      return nextPath;
    }
  }
};