// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watermark-background[data-v-6623187d] {
  background-position: -85% 50%;
  background-size: 80%;
}
.card-item[data-v-6623187d] {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 15px;
  z-index: 2;
  background-color: #f3f3f3;
}
.card-item[data-v-6623187d]:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: -5% 50%;
  opacity: 0.7;
}
.shadow[data-v-6623187d] {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}
.shadow-lg[data-v-6623187d] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}
.compact-card-table[data-v-6623187d]  .static-table-head-cell:first-child {
  min-width: 250px;
}
.compact-card-table[data-v-6623187d]  .static-table-head-cell {
  min-width: 150px;
}
.compact-card-table[data-v-6623187d]  td {
  vertical-align: top;
}
.activity-header[data-v-6623187d] {
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
}
.activity-header .activity-traffic[data-v-6623187d] {
  margin-right: 4px;
  line-height: 30px;
}
.activity-header .activity-traffic[data-v-6623187d]  svg {
  top: 0;
}
.activity-header .action-title[data-v-6623187d] {
  flex-grow: 1;
  display: flex;
  align-items: baseline;
}
.activity-header .action-title[data-v-6623187d]  .risma-title h3 {
  cursor: pointer !important;
}
.activity-header.red .activity-list-link[data-v-6623187d]:hover {
  text-decoration-color: #f25050;
}
.activity-header.red .activity-list-link[data-v-6623187d]  .risma-title > h3 {
  color: #f25050;
}
.activity-header .activity-list-link[data-v-6623187d] {
  margin-right: 6px;
}
.activity-header .activity-list-link[data-v-6623187d]  .risma-title > h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  color: #347480;
}
.activity-status-title[data-v-6623187d]  p {
  margin-bottom: 0;
}
.activity-status[data-v-6623187d] {
  word-wrap: break-word;
  max-height: 80px;
  overflow: hidden;
}
.action-deadline[data-v-6623187d] {
  display: flex;
  margin-top: auto;
  color: #4D4D4D;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
.action-dates p[data-v-6623187d] {
  margin-bottom: 0;
  text-align: right;
  font-weight: 700;
}
.depending-on-list[data-v-6623187d] {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}
.depending-on-list .depending-on-list-traffic[data-v-6623187d] {
  padding-bottom: 7px;
}
.depending-on-list .depending-on-list-responsible[data-v-6623187d] {
  color: #4D4D4D;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
