import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "risma-column-filters relative"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1,
  class: "p-3"
};
var _hoisted_4 = {
  class: "overflow-auto max-h-300px"
};
var _hoisted_5 = {
  key: 0,
  class: "border-b border-b-gray-650"
};
var _hoisted_6 = {
  class: "px-3 py-1 hover:bg-blue-250 hover:text-white cursor-pointer flex justify-between items-center"
};
var _hoisted_7 = {
  class: "mr-3",
  style: {
    wordBreak: 'break-word'
  }
};
var _hoisted_8 = ["checked"];
var _hoisted_9 = {
  class: "mr-3",
  style: {
    wordBreak: 'break-word'
  }
};
var _hoisted_10 = ["checked", "onClick"];
var _hoisted_11 = {
  key: 1,
  class: "pb-3 text-center"
};
var _hoisted_12 = {
  key: 3,
  class: "mt-2 px-3 py-2"
};
var _hoisted_13 = {
  key: 0,
  class: "flex justify-center items-center"
};
var _hoisted_14 = {
  key: 1,
  class: "flex items-center justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$options.hideSearchField ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_input_field, {
    modelValue: $data.searchString,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.searchString = $event;
    }),
    type: "text",
    placeholder: "".concat($data.translate.filterBy, "..."),
    class: "m-3 search-field",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"]))
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])) : _createCommentVNode("v-if", true), $props.datesFilter ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_date_range_picker, {
    "start-date": $data.dateRange.after,
    "end-date": $data.dateRange.before,
    ranges: $data.ranges,
    "popup-classes": "table-date-range-picker",
    opens: $props.columnIndex < 3 ? 'right' : 'left',
    onChanged: $options.onDatePickerChange
  }, null, 8 /* PROPS */, ["start-date", "end-date", "ranges", "opens", "onChanged"])])) : _createCommentVNode("v-if", true), $options.checkedValues.length > 0 ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "pr-3 underline text-right",
    onClick: _cache[2] || (_cache[2] = _withModifiers(function () {
      return $options.deselectAllFilters && $options.deselectAllFilters.apply($options, arguments);
    }, ["stop"]))
  }, _toDisplayString($data.translate.deselectAll), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [$props.additionalOptionLabel ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("label", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString($props.additionalOptionLabel), 1 /* TEXT */), _createElementVNode("input", {
    type: "checkbox",
    class: "cursor-pointer",
    checked: $props.additionalOptionValue,
    onChange: _cache[3] || (_cache[3] = function ($event) {
      return $options.onAdditionalOptionChange($event.target.checked);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8)])])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedFilters, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.value
    }, [_createElementVNode("div", null, [_createElementVNode("label", {
      class: _normalizeClass(["px-3 py-1 hover:bg-blue-250 hover:text-white flex justify-between items-center", $options.tableDataLoading ? 'cursor-wait' : 'cursor-pointer'])
    }, [_createElementVNode("span", _hoisted_9, _toDisplayString(item.label), 1 /* TEXT */), _createElementVNode("input", {
      type: "checkbox",
      class: _normalizeClass($options.tableDataLoading ? 'cursor-wait' : 'cursor-pointer'),
      checked: $options.checkedValues.includes(item.value),
      onClick: function onClick($event) {
        return $options.onFiltersChange(item.value, $event);
      }
    }, null, 10 /* CLASS, PROPS */, _hoisted_10)], 2 /* CLASS */)])]);
  }), 128 /* KEYED_FRAGMENT */)), $data.searchString && !$options.sortedFilters.length ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), $options.showLoadMoreButton || $data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_12, [$data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_loading_indicator_small)])) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", {
    class: "text-right text-gray-650 italic mr-2",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.onLoadMoreClick(true);
    })
  }, [_createElementVNode("span", null, _toDisplayString($data.translate.loadAll), 1 /* TEXT */)]), _createElementVNode("div", {
    class: "text-right text-gray-650 italic ml-2",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.onLoadMoreClick(false);
    })
  }, [_createElementVNode("span", null, _toDisplayString($data.translate.loadMore) + "...", 1 /* TEXT */)])]))])) : _createCommentVNode("v-if", true)]);
}