function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAllQuestionnaires() {
  var url = '/questionnaire?data[]=usage';
  return handleRequestResponse(Http.get(url));
}
export function getSelectableQuestionnairesByQuestionnaireTypeId(questionnaireTypeId) {
  return getQuestionnairesByQuestionnaireTypeId(questionnaireTypeId).then(function (response) {
    return response.list.filter(function (q) {
      return !!q.selectable;
    });
  });
}
export function getQuestionnairesByQuestionnaireTypeId(questionnaireTypeId) {
  var url = '/questionnaire' + "?filter[questionnaireTypeId]=".concat(questionnaireTypeId);
  return handleRequestResponse(Http.get(url));
}
export function getQuestionnaireAssessments_TIA_LIA(baseUrl) {
  var url = baseUrl + '/tialia';
  return handleRequestResponse(Http.get(url));
}

/**
 * Fetch all questions based on a projectId and a questionnaireId
 *
 * @param projectId
 * @param questionnaireId
 */
export function getAllQuestionsForProject(projectId, questionnaireId) {
  var url = "/questionnaire/".concat(questionnaireId, "?filter[projectId]=").concat(projectId);
  return handleRequestResponse(Http.get(url));
}
export function getAllQuestions(questionnaireId) {
  return handleRequestResponse(Http.get("/questionnaire/".concat(questionnaireId)));
}
export function getAllQuestionsIncludingHidden(questionnaireId) {
  return handleRequestResponse(Http.get("/questionnaire/".concat(questionnaireId, "?filter[]=includeHiddenQuestions")));
}
export function updateQuestionForNode(projectId, nodeId, question) {
  return handleRequestResponse(Http.patch("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/questionanswers/").concat(question.id), question));
}
export function getAllQuestionAnswersForContract(id, questionnaireId) {
  return handleRequestResponse(Http.get("/contracts/contract/".concat(id, "/questionanswers/").concat(questionnaireId)));
}
export function updateContractQuestion(id, question) {
  return handleRequestResponse(Http.patch("/contracts/contract/".concat(id, "/questionanswers/").concat(question.id), question));
}
export function getAllQuestionAnswersForIncident(id, questionnaireId) {
  return handleRequestResponse(Http.get("/incidents/incident/".concat(id, "/questionanswers/").concat(questionnaireId)));
}
export function updateIncidentQuestion(id, question) {
  return handleRequestResponse(Http.patch("/incidents/incident/".concat(id, "/questionanswers/").concat(question.id), question));
}
export function getAllQuestionAnswersForRisk(projectId, projectPeriodId, id, questionnaireId) {
  return handleRequestResponse(Http.get("/risks/projects/".concat(projectId, "/periods/").concat(projectPeriodId, "/risk/").concat(id, "/questionanswers/").concat(questionnaireId)));
}
export function updateRiskQuestion(projectId, projectPeriodId, id, question) {
  return handleRequestResponse(Http.patch("/risks/projects/".concat(projectId, "/periods/").concat(projectPeriodId, "/risk/").concat(id, "/questionanswers/").concat(question.id), question));
}
export function getAllQuestionAnswersForNode(projectId, nodeId) {
  var questionnaireId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  questionnaireId ? questionnaireId = '/' + questionnaireId : '';
  return handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/questionanswers").concat(questionnaireId)));
}
export function getInformationAssetQuestionnaire(processorId) {
  return handleRequestResponse(Http.get("compliance/informationAssets/processors/".concat(processorId, "/questionanswers")));
}
export function getInformationAssetQuestionnaireForProcessor() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia-processor'));
}
export function getInformationAssetQuestionnairesForCustomIA() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia'));
}
export function getInformationAssetQuestionnaireForSystem() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia-system'));
}
export function getInformationAssetQuestionnaireForController() {
  return handleRequestResponse(Http.get('compliance/informationAssets/questionnaire/ia-controller'));
}
export function getInformationAssetQuestionAnswersByType(type, id, questionnaireId) {
  questionnaireId ? questionnaireId = '/' + questionnaireId : '';
  return handleRequestResponse(Http.get("compliance/informationAssets/".concat(type, "/").concat(id, "/questionanswers").concat(questionnaireId)));
}
export function getQuestionAnswersByBaseUrl(baseUrl, questionnaireId) {
  questionnaireId = questionnaireId ? '/' + questionnaireId : '';
  return handleRequestResponse(Http.get("".concat(baseUrl, "/questionanswers").concat(questionnaireId)));
}

/**
 * Copy questionnaire to a new questionnaire
 *
 * @param questionnaireId
 * @param title
 */
export function copyQuestionnaire(questionnaireId, title) {
  var data = {
    title: title,
    copyFromQuestionnaireId: questionnaireId
  };
  return handleRequestResponse(Http.post('/questionnaire', data));
}

/**
 * Set 'selectable' on a questionnaire
 *
 * @param questionnaireId
 * @param title
 * @param selectable
 * @returns {boolean}
 */
export function enableQuestionnaire(questionnaireId, title, selectable) {
  var data = {
    questionnaireId: questionnaireId,
    selectable: selectable
  };
  return handleRequestResponse(Http.patch('/questionnaire', data));
}

/**
 * Delete questionnaire if possible
 *
 * @param questionnaireId
 * @returns {Promise<T | string>}
 */
export function deleteQuestionnaire(questionnaireId) {
  return handleRequestResponse(Http.delete('/questionnaire/' + questionnaireId));
}

/**
 * Update field on a questionnaire
 *
 * @param questionnaireId
 * @param data
 * @param field
 * @returns {boolean}
 */
export function changeFieldOnQuestionnaire(questionnaireId, title) {
  var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'title';
  var data = _defineProperty({
    questionnaireId: questionnaireId
  }, field, title);
  return handleRequestResponse(Http.patch('/questionnaire', data));
}
export function getQuestionnaireTypes() {
  var onlyExisting = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var onlyWithMatchingGap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var url = '/compliance/questionnairetype';
  var params = [];
  if (onlyExisting) {
    params.push('onlyExisting=1');
  }
  if (onlyWithMatchingGap) {
    params.push('onlyWithMatchingGap=1');
  }
  if (params.length > 0) {
    url += '?' + params.join('&');
  }
  return handleRequestResponse(Http.get(url));
}
export function getQuestionnaireScores(id) {
  return handleRequestResponse(Http.get("compliance/questionoptionsscore/".concat(id)));
}
export function updateQuestionnaireScores(id, payload) {
  return handleRequestResponse(Http.patch("compliance/questionoptionsscore/".concat(id), payload));
}
export function getQuestionnaireAssessments(id) {
  return handleRequestResponse(Http.get("compliance/questionnairescoringcategories/".concat(id)));
}
export function updateQuestionnaireAssessments(id, payload) {
  return handleRequestResponse(Http.patch("compliance/questionnairescoringcategories/".concat(id), payload));
}
export function getAllQuestionnaireTemplates(type) {
  return handleRequestResponse(Http.get("admin/compliance/questionnairetemplate?type=".concat(type)));
}
export function createQuestionnaireFromTemplate(templateId) {
  return handleRequestResponse(Http.post("admin/compliance/questionnairetemplate/".concat(templateId)));
}