// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li[data-v-62f76a9e] {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: -1px;
}
li a[data-v-62f76a9e] {
  display: inline-block;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 35px;
  padding: 0.25em 0.5em;
  margin: 0;
  text-decoration: none;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(14 41 84 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(14 41 84 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
li a[data-v-62f76a9e]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(99 121 148 / var(--tw-border-opacity));
}
li a.router-link-active[data-v-62f76a9e] {
  --tw-border-opacity: 1;
  border-color: rgb(99 121 148 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(99 121 148 / var(--tw-bg-opacity));
  border-bottom: transparent;
}
li a.disabled[data-v-62f76a9e] {
  pointer-events: none;
  opacity: 0.8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
