import GanttChartJs from '@/components/Molecules/Charts/GanttChartJs';
export default {
  components: {
    GanttChartJs: GanttChartJs
  },
  data: function data() {
    return {
      startDate: '2002-03-15',
      chartData: {
        labels: Array.from({
          length: 9
        }, function (v, i) {
          return 'Label' + ++i;
        }),
        datasets: [{
          backgroundColor: '#5AA744',
          data: [['2002-03-15', '2008-04-22'], ['2003-03-22', '2005-07-22'], ['2007-03-22', '2009-03-22'], ['2010-03-22', '2018-06-22'], ['2019-03-22', '2022-03-22'], ['2005-03-22', '2007-07-22'], ['2020-03-22', '2022-05-22'], ['2012-03-22', '2017-04-22'], ['2012-03-22', '2013-03-22']]
        }]
      }
    };
  },
  methods: {
    handleClick: function handleClick(index) {
      // eslint-disable-next-line no-console
      console.log('clicked on label at index:', index);
    }
  }
};