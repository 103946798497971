function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { defineAsyncComponent } from 'vue';
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import Alert from '@/components/Molecules/Modal/Alert';
import HeaderNavBar from '@/components/Organisms/HeaderNavBar';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
var AiAssistant = defineAsyncComponent(function () {
  return import('@/components/Organisms/AiAssistant/AiAssistant');
});
import { useNotificationStore } from '@/Store/notificationStore';
import { useNotificationPopUpStore } from '@/Store/notificationPopUpStore';
import { setGlobalDateLocale } from '@/utils/date';
import { getSelectedRoute } from '@/utils/base/tabs';
export default {
  el: '#risma',
  name: 'Chanel',
  components: {
    Alert: Alert,
    HeaderNavBar: HeaderNavBar,
    NotificationPopUp: NotificationPopUp,
    PreviewModalLink: PreviewModalLink,
    AiAssistant: AiAssistant,
    DescriptionModal: defineAsyncComponent(function () {
      return import(/* webpackChunkName: "updates" */'@/components/Molecules/Modal/DescriptionModal');
    })
  },
  data: function data() {
    return {
      updatesByPages: [],
      promptText: '',
      previewActivity: null,
      previewSelectedRoute: null,
      updatedFromPreview: false,
      navBarIndex: 0,
      routerViewIndex: 0,
      topPadding: 'pt-105px'
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'userIsLoggedIn', 'userLanguage'])), mapState(useNotificationStore, ['notificationType', 'notificationHeader', 'notificationBody', 'notificationVisible', 'options'])), mapState(useNotificationPopUpStore, ['notificationPopUpVisible', 'notificationPopUpData'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    aiAssistantEnabled: 'aiAssistantEnabled',
    aiTermsAccepted: 'aiTermsAccepted'
  })), {}, {
    keyField: function keyField() {
      var _this = this;
      var key = this.$route.fullPath;
      this.$route.matched.forEach(function (route) {
        if (route.meta.keyFunction) {
          key = route.meta.keyFunction(_this.$route);
        }
      });
      return key;
    },
    updatesForPath: function updatesForPath() {
      var _this2 = this;
      return this.updatesByPages.find(function (item) {
        return !item.fuzzyMatch ? item.path === _this2.keyField : _this2.keyField.startsWith(item.path);
      });
    }
  }),
  watch: {
    keyField: function keyField() {
      if (this.notificationPopUpVisible) {
        this.dismissNotificationPopUp();
      }
      if (this.previewActivity) {
        this.onPreviewDismiss();
      }
    }
  },
  mounted: function mounted() {
    if (this.userIsLoggedIn) {
      setGlobalDateLocale(this.userLanguage);
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useNotificationStore, ['handleNotification'])), mapActions(useNotificationPopUpStore, ['dismissNotificationPopUp'])), {}, {
    onHandleAlert: function onHandleAlert(result) {
      if (this.notificationType === 'prompt') {
        this.handleNotification(this.promptText);
      } else {
        this.handleNotification(result);
      }
    },
    showPreview: function showPreview(event) {
      var _this3 = this;
      this.previewActivity = cloneDeep(this.notificationPopUpData.activity);
      this.previewSelectedRoute = getSelectedRoute(this.previewActivity.url);
      this.$nextTick(function () {
        return _this3.$refs.preview.showModal(event);
      });
    },
    onPreviewDismiss: function onPreviewDismiss() {
      if (this.updatedFromPreview) {
        this.routerViewIndex += 1;
      }
      this.previewActivity = null;
      this.updatedFromPreview = false;
    }
  })
};