// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-link[data-v-29c81078] {
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-self: stretch;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(236 236 236 / var(--tw-border-opacity));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: center;
  font-weight: 600;
}
.tab-link[data-v-29c81078]:hover {
  background-color: rgb(0 219 185 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
}
.tab-link[data-v-29c81078]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.tab-link[data-v-29c81078]:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(0 219 185 / var(--tw-border-opacity));
}
.router-link-active[data-v-29c81078],
.active[data-v-29c81078] {
  position: relative;
  border-top-width: 1px;
  border-color: transparent;
  background-color: rgb(0 219 185 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.router-link-active[data-v-29c81078]:hover,
.active[data-v-29c81078]:hover {
  --tw-bg-opacity: 0.25;
}
.disabled[data-v-29c81078] {
  pointer-events: none;
  cursor: default;
  --tw-text-opacity: 1;
  color: rgb(235 235 235 / var(--tw-text-opacity));
}
.active[data-v-29c81078]:after,
.router-link-active[data-v-29c81078]:after {
  content: "";
  left: -1px;
  right: -1px;
  bottom: -1px;
  height: 3px;
  position: absolute;
  --tw-bg-opacity: 1;
  background-color: rgb(0 219 185 / var(--tw-bg-opacity));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
