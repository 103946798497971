import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_light_tab_item, {
    "is-current": false
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("Tab item")]);
    }),
    _: 1 /* STABLE */
  }), _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_light_tab_item, {
    "is-current": true
  }, {
    default: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createTextVNode("Tab item (isCurrent:true)")]);
    }),
    _: 1 /* STABLE */
  }), _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_light_tab_item, {
    "is-current": true
  }, {
    default: _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createTextVNode(" Tab item (isCurrent:true tab-background:grey-50) ")]);
    }),
    _: 1 /* STABLE */
  }), _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_light_tab_item, {
    to: "/dev/kitchensink/molecules"
  }, {
    default: _withCtx(function () {
      return _cache[3] || (_cache[3] = [_createTextVNode("Tab item (router-link)")]);
    }),
    _: 1 /* STABLE */
  }), _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    "is-current": false
  }, {
    default: _withCtx(function () {
      return _cache[4] || (_cache[4] = [_createTextVNode("Tab item")]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_light_tab_item, {
    "is-current": true
  }, {
    default: _withCtx(function () {
      return _cache[5] || (_cache[5] = [_createTextVNode("Tab item (active)")]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_light_tab_item, {
    to: "/dev/kitchensink/molecules"
  }, {
    default: _withCtx(function () {
      return _cache[6] || (_cache[6] = [_createTextVNode("Tab item (router-link)")]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_light_tab_item, {
    "is-current": true,
    "tab-background": "grey-50"
  }, {
    default: _withCtx(function () {
      return _cache[7] || (_cache[7] = [_createTextVNode(" Tab item (active with a given background) ")]);
    }),
    _: 1 /* STABLE */
  })])]);
}