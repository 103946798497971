function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import isEqual from 'lodash/isEqual';
import Selector from '@/components/Atoms/Inputs/Selector';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'AddContractsModal',
  introduction: 'Multiselect popup',
  description: 'Modal with group selector, contracts selector and list of selected items',
  token: '<add-contracts-modal :preselected-contracts=[] :contracts=[] />',
  components: {
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal
  },
  props: {
    preselectedContracts: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected contract items'
    },
    contracts: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all available contracts'
    }
  },
  emits: ['update', 'closeModal'],
  data: function data() {
    return {
      selectedContracts: [],
      searchFields: ['title'],
      isContractValid: true,
      translate: getTranslate['AddContractsModal']()
    };
  },
  computed: {
    selectableItems: function selectableItems() {
      var _this = this;
      return this.contracts.filter(function (contract) {
        return !_this.selectedContracts.find(function (selected) {
          return selected.id === contract.id;
        });
      });
    }
  },
  watch: {
    selectedContracts: {
      deep: true,
      handler: function handler() {
        this.isContractValid = true;
      }
    }
  },
  mounted: function mounted() {
    this.selectedContracts = _toConsumableArray(this.preselectedContracts);
  },
  methods: {
    selected: function selected(item) {
      this.selectedContracts.push(item);
    },
    deselect: function deselect(item) {
      this.selectedContracts = this.selectedContracts.filter(function (selected) {
        return item.id !== selected.id;
      });
    },
    onAccept: function onAccept() {
      if (!isEqual(this.selectedContracts, this.preselectedContracts)) {
        return this.$emit('update', this.selectedContracts);
      }
      if (!this.selectedContracts.length) {
        return this.isContractValid = false;
      }
      return this.$emit('closeModal');
    }
  }
};