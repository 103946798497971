import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "tree-navigations"
};
var _hoisted_3 = {
  class: "mb-8"
};
var _hoisted_4 = {
  class: "tree_external ml-4"
};
var _hoisted_5 = {
  class: "-ml-3 pr-4"
};
var _hoisted_6 = {
  class: "mb-6"
};
var _hoisted_7 = {
  class: "tree_internal ml-4"
};
var _hoisted_8 = {
  class: "-ml-3 pr-4"
};
var _hoisted_9 = {
  key: 0,
  class: "mt-10 mr-4 ml-8 preview"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_add_contract_party_modal_extended = _resolveComponent("add-contract-party-modal-extended");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_mini_tree = _resolveComponent("mini-tree");
  var _component_contract_party_external_preview = _resolveComponent("contract-party-external-preview");
  var _component_contract_party_internal_preview = _resolveComponent("contract-party-internal-preview");
  var _component_links_dep_preview = _resolveComponent("links-dep-preview");
  var _component_horizontal_resize_box = _resolveComponent("horizontal-resize-box");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showExtendedModal ? (_openBlock(), _createBlock(_component_add_contract_party_modal_extended, {
    key: 0,
    "all-companies": $options.companyList,
    type: $data.modalType,
    "parent-company": $data.parentCompany,
    "paying-part-enabled": !$options.companyPartyAsPayingParty,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showExtendedModal = false;
    }),
    "onCreate:contractParties": $options.handleContractPartiesCreation,
    "onRefresh:companies": $options.getCompanies,
    onCopy: $options.copySectionToSubcompanies
  }, null, 8 /* PROPS */, ["all-companies", "type", "parent-company", "paying-part-enabled", "onCreate:contractParties", "onRefresh:companies", "onCopy"])) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_horizontal_resize_box, {
    key: 1,
    "local-storage-key": "contract-party-resize-box",
    class: "horizontal-resize-box"
  }, {
    leftColumn: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: _normalizeClass($data.headerCssStyles)
      }, [_createElementVNode("span", {
        class: _normalizeClass($data.titleCssStyles)
      }, _toDisplayString($options.externalTitle), 3 /* TEXT, CLASS */), _createElementVNode("button", {
        type: "save",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $options.handleCreate($data.CONTRACT_PARTY_TYPES.EXTERNAL, null);
        })
      }, [_createVNode(_component_feather_icon, {
        class: "mr-1 stroke-rm-text focus:outline-none stroke-2",
        icon: "plus-circle"
      })])], 2 /* CLASS */), _createElementVNode("div", _hoisted_4, [_createVNode(_component_mini_tree, {
        ref: "".concat($data.treeRefPrefix).concat($data.CONTRACT_PARTY_TYPES.EXTERNAL),
        branch: $options.externalTreeData,
        "has-toggle-menu-prop": true,
        "node-emits": $options.externalEmits,
        "has-horizontal-resize-box": false,
        "has-optional-functionality": false,
        "local-storage-unique-key": "".concat($data.treeStorageKey).concat($data.CONTRACT_PARTY_TYPES.EXTERNAL),
        onAddSubNode: _cache[3] || (_cache[3] = function ($event) {
          return $options.handleCreate($data.CONTRACT_PARTY_TYPES.EXTERNAL, $event);
        }),
        onDeleteNode: $options.handleRemoveParty,
        "onEdit:signingParty": $options.handleUpdateSigningParty,
        onSelect: _cache[4] || (_cache[4] = function ($event) {
          return $options.selectCompany($event, $data.CONTRACT_PARTY_TYPES.EXTERNAL);
        })
      }, {
        emptyListLabel: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_5, [_createElementVNode("a", {
            href: "#",
            class: "glob-link",
            onClick: _cache[2] || (_cache[2] = function ($event) {
              return $options.handleCreate($data.CONTRACT_PARTY_TYPES.EXTERNAL);
            })
          }, _toDisplayString($data.translate.start), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($data.translate.byAddingYourFirstExternal), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["branch", "node-emits", "local-storage-unique-key", "onDeleteNode", "onEdit:signingParty"])])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
        class: _normalizeClass($data.headerCssStyles)
      }, [_createElementVNode("span", {
        class: _normalizeClass($data.titleCssStyles)
      }, _toDisplayString($options.internalTitle), 3 /* TEXT, CLASS */), _createElementVNode("button", {
        type: "save",
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return $options.handleCreate($data.CONTRACT_PARTY_TYPES.INTERNAL, null);
        })
      }, [_createVNode(_component_feather_icon, {
        class: "mr-1 stroke-rm-text focus:outline-none stroke-2",
        icon: "plus-circle"
      })])], 2 /* CLASS */), _createElementVNode("div", _hoisted_7, [_createVNode(_component_mini_tree, {
        ref: "".concat($data.treeRefPrefix).concat($data.CONTRACT_PARTY_TYPES.INTERNAL),
        branch: $options.internalTreeData,
        "has-toggle-menu-prop": true,
        "node-emits": $options.internalEmits,
        "has-horizontal-resize-box": false,
        "has-optional-functionality": false,
        "local-storage-unique-key": "".concat($data.treeStorageKey).concat($data.CONTRACT_PARTY_TYPES.INTERNAL),
        onAddSubNode: _cache[7] || (_cache[7] = function ($event) {
          return $options.handleCreate($data.CONTRACT_PARTY_TYPES.INTERNAL, $event);
        }),
        "onEdit:payingParty": $options.handleUpdatePayingParty,
        "onEdit:signingParty": $options.handleUpdateSigningParty,
        onDeleteNode: $options.handleRemoveParty,
        onSelect: _cache[8] || (_cache[8] = function ($event) {
          return $options.selectCompany($event, $data.CONTRACT_PARTY_TYPES.INTERNAL);
        })
      }, {
        emptyListLabel: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_8, [_createElementVNode("a", {
            href: "#",
            class: "glob-link",
            onClick: _cache[6] || (_cache[6] = function ($event) {
              return $options.handleCreate($data.CONTRACT_PARTY_TYPES.INTERNAL);
            })
          }, _toDisplayString($data.translate.start), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($data.translate.byAddingYourFirstInternal), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["branch", "node-emits", "local-storage-unique-key", "onEdit:payingParty", "onEdit:signingParty", "onDeleteNode"])])])])];
    }),
    rightColumn: _withCtx(function () {
      return [$options.showPreview ? (_openBlock(), _createElementBlock("div", _hoisted_9, [$options.isExternalPreview ? (_openBlock(), _createBlock(_component_contract_party_external_preview, {
        key: 0,
        data: $data.selectedCompany.company,
        "contract-party": $data.selectedCompany.contractParty,
        "company-contacts": $data.companyContactsMap[$data.selectedCompany.company.id],
        "linked-company-contacts": $props.data.links.company_contact,
        "select-box-options": $options.externalSelectOptions,
        "external-sections-enabled": $options.doraEnabled,
        "onUpdate:contact": $options.emitUpdateLinks,
        "onCreate:contact": _cache[9] || (_cache[9] = function ($event) {
          return $options.handleCreateContact($data.selectedCompany.company.id, $event);
        }),
        onUpdateFromPreview: $options.handleUpdateFromPreview,
        onUpdateDependency: _cache[10] || (_cache[10] = function ($event) {
          return _ctx.refreshMaps($data.selectedCompany.company.id);
        }),
        onUpdate: $options.handleContractPartyUpdate
      }, null, 8 /* PROPS */, ["data", "contract-party", "company-contacts", "linked-company-contacts", "select-box-options", "external-sections-enabled", "onUpdate:contact", "onUpdateFromPreview", "onUpdate"])) : $props.data.doraEnabled ? (_openBlock(), _createBlock(_component_contract_party_internal_preview, {
        key: 1,
        company: $data.selectedCompany.company,
        "contract-party": $data.selectedCompany.contractParty,
        "select-box-options": $options.internalSelectOptions,
        "service-provider-options": $options.serviceProviderOptions,
        "company-functions": $data.companyFunctionsMap[$data.selectedCompany.company.id],
        "internal-sections-enabled": $options.doraEnabled,
        onUpdate: $options.handleContractPartyUpdate,
        "onCopy:section": $options.copySection,
        onUpdateFromPreview: $options.handleUpdateFromPreview
      }, null, 8 /* PROPS */, ["company", "contract-party", "select-box-options", "service-provider-options", "company-functions", "internal-sections-enabled", "onUpdate", "onCopy:section", "onUpdateFromPreview"])) : (_openBlock(), _createBlock(_component_links_dep_preview, {
        key: 2,
        data: $data.selectedCompany.company,
        "onCopy:section": $options.copySection,
        onUpdateFromPreview: $options.handleUpdateFromPreview
      }, null, 8 /* PROPS */, ["data", "onCopy:section", "onUpdateFromPreview"]))])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  }))]);
}