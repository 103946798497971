// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-dbb597f2] .region-name + svg {
  width: 16px;
  height: 16px;
}
[data-v-dbb597f2] .collapsible-panel-header {
  padding-right: 1rem;
}
[data-v-dbb597f2] .collapsible-panel-content {
  padding-bottom: 0;
}
[data-v-dbb597f2] .collapsible-panel-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
[data-v-dbb597f2] .collapsible-panel-header:hover {
  background-color: rgba(33, 130, 253, 0.06);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
