import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_static_tab_item = _resolveComponent("static-tab-item");
  var _component_static_tabs = _resolveComponent("static-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_static_tabs, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_static_tab_item, {
        to: "#first"
      }, {
        default: _withCtx(function () {
          return _cache[0] || (_cache[0] = [_createTextVNode("This is a tab #1 inside Tabs")]);
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_static_tab_item, {
        to: "#second"
      }, {
        default: _withCtx(function () {
          return _cache[1] || (_cache[1] = [_createTextVNode("This is a tab #2 inside Tabs")]);
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })]);
}