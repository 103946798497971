import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_release_notification = _resolveComponent("release-notification");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.showReleaseNotifications ? (_openBlock(), _createBlock(_component_release_notification, {
    key: 0,
    onDismiss: $options.dismissVersionPanel
  }, null, 8 /* PROPS */, ["onDismiss"])) : (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showReleaseNotifications = true;
    })
  }, {
    default: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createTextVNode(" Show notification ")]);
    }),
    _: 1 /* STABLE */
  }))]);
}