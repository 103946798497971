import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_complete_control = _resolveComponent("complete-control");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.title,
    "show-buttons": false,
    "dismiss-button-disabled": $data.interactionDisabled,
    "dismiss-on-click-outside": false,
    type: $options.completeModalWide ? 'wide' : undefined,
    onDismiss: $options.onDismiss
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_complete_control, {
        id: $props.id,
        cpath: $props.cpath,
        "is-files-disable": $options.isFilesDisable,
        onToggleDisableInteraction: $options.toggleDisableInteraction,
        onDataUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $data.isUpdated = true;
        }),
        onClose: $options.handleClose,
        onUpdate: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('update', $event);
        }),
        onUpdateModalTitle: $options.setModalTitle
      }, null, 8 /* PROPS */, ["id", "cpath", "is-files-disable", "onToggleDisableInteraction", "onClose", "onUpdateModalTitle"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "dismiss-button-disabled", "type", "onDismiss"]);
}