import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex-grow"
};
var _hoisted_2 = {
  key: 1,
  class: "p-4 border border-gray-350"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : $options.dataset.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_static_table, {
    "dataset-table": $options.dataset,
    columns: $options.columns,
    theme: $data.StaticTableThemes.zebra,
    class: "w-full"
  }, {
    title: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData,
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $options.updateFromPreviewModal($event);
        }),
        onDeleteItem: function onDeleteItem($event) {
          return $options.deleteFromPreviewModal(slotData);
        }
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.getTitle(slotData)), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDeleteItem"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns", "theme"])])) : _createCommentVNode("v-if", true)]);
}