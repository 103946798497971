function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { setUserProjectLevel } from '@/api/risma/data';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { clickOutside } from '@/utils/directives/clickOutside';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaUserRightsPicker',
  token: '<risma-user-rights-picker :readonly="isReadonly" :projectProperty="project" :userProperty="user"/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    userProperty: {
      type: Object,
      required: true,
      note: 'User object for the user to change permissions on'
    },
    projectProperty: {
      type: Object,
      required: true,
      default: function _default() {},
      note: 'Project object with information about the project to change permissions for'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['error', 'updateUser', 'update:opened'],
  data: function data() {
    return {
      UserLevels: UserLevels,
      hideModal: true,
      project: {},
      isLocked: false,
      translate: getTranslate['RismaUserRightsPicker']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceAccessLevelByProjectId: 'complianceAccessLevelByProjectId',
    currentUserRiskAccessLevelByProjectId: 'riskAccessLevelByProjectId'
  })), {}, {
    supervisorFeatureEnabled: function supervisorFeatureEnabled() {
      return this.getSettingValue('feature.supervisor_enabled') && this.project.objType === ObjectTypes.COMPLIANCE_PROJECT;
    },
    supervisorText: function supervisorText() {
      return this.project.supervisor ? this.translate.unassignAsSupervisor : this.translate.supervisor;
    },
    isLockedClick: function isLockedClick() {
      return !this.allowEdit || this.readonly || this.isEmptyList;
    },
    isSuperUser: function isSuperUser() {
      return this.project.access_level === UserLevels.SUPER;
    },
    currentLevelIcon: function currentLevelIcon() {
      return 'icon-risma-' + this.levelToIcon(this.project.access_level);
    },
    supervisorYellowIcon: function supervisorYellowIcon() {
      return this.project.supervisor ? 'icon-risma-superUser-yellow' : '';
    },
    isAzureUser: function isAzureUser() {
      return this.userProperty.ad_objectid && !this.getSettingValue('app.ignore_ad_permissions');
    },
    allowAdmin: function allowAdmin() {
      return this.currentUser.level_admin == 1 && !this.isAzureUser;
    },
    isEmptyList: function isEmptyList() {
      return this.isAzureUser && !this.supervisorFeatureEnabled || this.isAzureUser && !this.isSuperUser;
    },
    allowEdit: function allowEdit() {
      var currentUser = this.currentUser;
      return (this.projectProperty.objType === ObjectTypes.RISK_PROJECT ? this.currentUserRiskAccessLevelByProjectId(this.projectProperty.id) === UserLevels.ADMIN : this.currentUserComplianceAccessLevelByProjectId(this.projectProperty.id) === UserLevels.ADMIN && currentUser.id != this.userProperty.id && this.projectProperty.access_level < UserLevels.ADMIN) || currentUser.level_admin == 1;
    },
    isLockedPossible: function isLockedPossible() {
      return this.projectProperty.access_level !== UserLevels.NONE && this.projectProperty.access_level !== UserLevels.ADMIN && !this.isAzureUser;
    }
  }),
  mounted: function mounted() {
    this.project = this.projectProperty;
    this.isLocked = this.project.locked ? true : false;
  },
  methods: {
    closeModal: function closeModal() {
      if (!this.hideModal) this.$emit('update:opened');
      this.hideModal = true;
    },
    toggleModal: function toggleModal() {
      if (this.isLockedClick) {
        return;
      }
      this.hideModal = !this.hideModal;
      this.$emit('update:opened');
    },
    levelToIcon: function levelToIcon(accessLevel) {
      var icons = ['noAccess', 'user', 'privUser', 'superUser', 'admin'];
      return icons[accessLevel];
    },
    setLevel: function setLevel(accessLevel, supervisor) {
      var _this = this;
      var locked = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var accessLevelNew = accessLevel === undefined ? this.projectProperty.access_level : accessLevel;
      var userId = this.userProperty.id;
      if (accessLevelNew !== UserLevels.SUPER) {
        supervisor = 0;
      }
      if (accessLevelNew > 3 && !this.allowAdmin) {
        return;
      }
      var projectId = this.project.objType === ObjectTypes.COMPLIANCE_PROJECT ? this.project.id : null;
      var riskProjectId = this.project.objType === ObjectTypes.RISK_PROJECT ? this.project.id : null;
      var params = {
        user_id: userId,
        project_id: projectId,
        r_project_id: riskProjectId,
        access_level: accessLevelNew,
        supervisor: supervisor,
        locked: locked ? 1 : 0
      };
      setUserProjectLevel(params).then(function () {
        _this.project.access_level = params.access_level;
        _this.project.supervisor = params.supervisor;
        _this.project.locked = params.locked;
        _this.isLocked = _this.project.locked ? true : false;
        _this.closeModal();
        var userUpdate = JSON.parse(JSON.stringify(_this.userProperty));
        _this.updateUserRightsByProject(userUpdate, _this.project);
        _this.$emit('updateUser', userUpdate);
      }).catch(function (err) {
        var _err$response;
        _this.$emit('error', ((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) || _this.translate.somethingWentWrongUserWasNotUpdated);
        window.scrollTo(0, 0);
      });
    },
    updateUserRightsByProject: function updateUserRightsByProject(user, project) {
      var projectIndex = user.projects.findIndex(function (item) {
        return project.id === item.id && project.objType === item.objType;
      });
      user.projects[projectIndex] = project;
    }
  }
};