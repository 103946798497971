import { defineStore } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import { getUsers, flushGetUsersCache } from '@/api/risma/data';
import { userHasProjectAccess } from '@/utils/access';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
export var useUsersStore = defineStore('usersStore', {
  state: function state() {
    return {
      users: []
    };
  },
  getters: {
    allUsers: function allUsers() {
      return this.users;
    },
    activeUsers: function activeUsers() {
      return this.users.filter(function (user) {
        return user.active;
      });
    },
    usersWithComplianceAccessByProjectId: function usersWithComplianceAccessByProjectId() {
      var _this = this;
      return function (projectId) {
        return _this.users.filter(function (user) {
          return userHasProjectAccess(user, projectId, ObjectTypes.COMPLIANCE_PROJECT);
        });
      };
    },
    activeUsersWithComplianceAccessByProjectId: function activeUsersWithComplianceAccessByProjectId() {
      var _this2 = this;
      return function (projectId) {
        return _this2.activeUsers.filter(function (user) {
          return userHasProjectAccess(user, projectId, ObjectTypes.COMPLIANCE_PROJECT);
        });
      };
    },
    usersWithActionsAccess: function usersWithActionsAccess() {
      return this.activeUsers.filter(function (user) {
        return user.is_admin || user.level_execution > UserLevels.NONE;
      });
    },
    usersWithIAAccess: function usersWithIAAccess() {
      return this.users.filter(function (user) {
        return user.iaAccess || user.is_admin;
      });
    },
    usersWithPoliciesAccess: function usersWithPoliciesAccess() {
      return this.users.filter(function (user) {
        var _user$AccessLevels;
        return ((_user$AccessLevels = user.AccessLevels) === null || _user$AccessLevels === void 0 || (_user$AccessLevels = _user$AccessLevels.PolicyManagement) === null || _user$AccessLevels === void 0 ? void 0 : _user$AccessLevels.level) || user.is_admin;
      });
    },
    activeUsersWithLevelAccess: function activeUsersWithLevelAccess() {
      var _this3 = this;
      return function (field, level) {
        return _this3.activeUsers.filter(function (user) {
          return user[field] >= level || user.is_admin;
        });
      };
    }
  },
  actions: {
    set: function set(users) {
      this.users = cloneDeep(users);
    },
    load: function load() {
      var _this4 = this;
      flushGetUsersCache(true);
      return getUsers(true).then(function (_ref) {
        var list = _ref.list;
        _this4.users = list;
      });
    },
    update: function update(user) {
      this.users = this.users.map(function (u) {
        return u.id == user.id ? user : u;
      });
    },
    add: function add(user) {
      this.users.push(user);
    },
    getUserById: function getUserById(id) {
      if (!id) return null;
      return this.users.find(function (user) {
        return +user.id === +id;
      });
    }
  }
});