import { handleRequestResponse } from '@/api/httpUtils';
import Http from '@/api';
export function getAttributesForProject(projectId) {
  return handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/gap_attributes")));
}
export function getAttributes(gapSchemaId) {
  return handleRequestResponse(Http.get("compliance/gapschema/".concat(gapSchemaId, "/attributes")));
}
export function setAttributes(gapSchemaId, attributes) {
  return handleRequestResponse(Http.patch("admin/compliance/gapschema/".concat(gapSchemaId, "/attributes"), {
    gapSchemaId: gapSchemaId,
    attributes: attributes
  }));
}