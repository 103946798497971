import { defineStore } from 'pinia';
import { getCurrentUser, getLoginInfo } from '../api/risma/data';
import { ObjectTypes } from '../constants/ObjectTypes';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { getUserLanguage } from '@/utils/Utils';
import { MODULES } from '@/constants/modules';
import { useSettingsStore } from '@/Store/settingsStore';
export var useUserStore = defineStore('userStore', {
  state: function state() {
    return {
      currentUser: {},
      userIsLoggedIn: false
    };
  },
  getters: {
    accessLevelByModule: function accessLevelByModule() {
      var _this = this;
      return function (moduleName) {
        return Object.assign({}, _this.currentUser.AccessLevels[moduleName]);
      };
    },
    accessLevelByProjectId: function accessLevelByProjectId() {
      var _this2 = this;
      return function (projectId) {
        return _this2.currentUser.projects.find(function (project) {
          return project.id === +projectId;
        }).access_level;
      };
    },
    showGapByProjectId: function showGapByProjectId() {
      var _this3 = this;
      return function (projectId) {
        return _this3.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }).show_gap;
      };
    },
    complianceProjects: function complianceProjects() {
      return this.currentUser.projects.filter(function (project) {
        return project.objType === ObjectTypes.COMPLIANCE_PROJECT;
      });
    },
    complianceProjectById: function complianceProjectById() {
      var _this4 = this;
      return function (projectId) {
        return _this4.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }) || {};
      };
    },
    complianceAccessLevelByProjectId: function complianceAccessLevelByProjectId() {
      var _this5 = this;
      return function (projectId) {
        return _this5.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }).access_level;
      };
    },
    complianceCanCreateByProjectId: function complianceCanCreateByProjectId() {
      var _this6 = this;
      return function (projectId) {
        return _this6.complianceProjects.find(function (project) {
          return project.id === +projectId;
        }).canCreate;
      };
    },
    riskProjects: function riskProjects() {
      return this.currentUser.projects.filter(function (project) {
        return project.objType === ObjectTypes.RISK_PROJECT;
      });
    },
    riskProjectById: function riskProjectById() {
      var _this7 = this;
      return function (projectId) {
        return _this7.riskProjects.find(function (project) {
          return project.id === +projectId;
        }) || {};
      };
    },
    riskAccessLevelByProjectId: function riskAccessLevelByProjectId() {
      var _this8 = this;
      return function (projectId) {
        var project = _this8.riskProjects.find(function (project) {
          return project.id === +projectId;
        });
        if (project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
          return _this8.currentUser.level_risk;
        }
        return project.access_level;
      };
    },
    canCreateRiskByProjectId: function canCreateRiskByProjectId() {
      var _this9 = this;
      return function (projectId) {
        var project = _this9.riskProjects.find(function (project) {
          return project.id === +projectId;
        });
        return project.canCreate;
      };
    },
    isAdmin: function isAdmin() {
      return !!this.currentUser.is_admin;
    },
    isMfaEnabled: function isMfaEnabled() {
      return this.currentUser.mfa_type;
    },
    userHasAccessLevel: function userHasAccessLevel() {
      var _this10 = this;
      return function (field, level) {
        return _this10.currentUser[field] >= level || _this10.isAdmin;
      };
    },
    userLanguage: function userLanguage() {
      return getUserLanguage(this.currentUser);
    },
    canCreateInformationAssets: function canCreateInformationAssets() {
      var _this11 = this;
      return function (module, activityTypeId) {
        var accessLevels = _this11.currentUser.AccessLevels['InformationAssets'][module];
        if (module === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
          var _accessLevels$activit;
          return ((_accessLevels$activit = accessLevels[activityTypeId]) === null || _accessLevels$activit === void 0 ? void 0 : _accessLevels$activit.canCreate) || false;
        }
        return accessLevels.canCreate;
      };
    },
    userHasCompaniesAccess: function userHasCompaniesAccess() {
      var settingsStore = useSettingsStore();
      return settingsStore.settings['app.module_companies_enabled'] === 1 && (this.currentUser.level_company || this.currentUser.isAdmin);
    }
  },
  actions: {
    set: function set(user) {
      this.currentUser = user;
    },
    setUserIsLoggedIn: function setUserIsLoggedIn(userIsLoggedIn) {
      this.userIsLoggedIn = userIsLoggedIn;
    },
    loadLoginInfo: function loadLoginInfo() {
      var _this12 = this;
      return getLoginInfo().then(function (response) {
        _this12.userIsLoggedIn = response.userIsLoggedIn;
        return response;
      });
    },
    updateUser: function updateUser(user) {
      // Check the arguments
      if (+user.id === +this.currentUser.id) {
        this.currentUser = user;
      }
    },
    load: function load() {
      var _this13 = this;
      return getCurrentUser(true).then(function (user) {
        _this13.currentUser = user;
      });
    }
  }
});