import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_info_box = _resolveComponent("info-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_info_box, {
    class: "mb-6"
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("div", null, " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_info_box, {
    class: "mb-6",
    title: "Lorem Ipsum is simply dummy text"
  }, {
    default: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createElementVNode("div", null, " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_info_box, {
    class: "mb-6",
    title: "Lorem Ipsum is simply dummy text",
    link: "/dev/kitchensink/atoms/info-box"
  }, {
    default: _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createElementVNode("div", null, " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_info_box, {
    class: "mb-6",
    title: "Lorem Ipsum is simply dummy text",
    link: "/dev/kitchensink/atoms/info-box"
  }, {
    icon: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "star",
        class: "stroke-2 text-red-50"
      })];
    }),
    default: _withCtx(function () {
      return [_cache[3] || (_cache[3] = _createElementVNode("div", null, " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ", -1 /* HOISTED */))];
    }),
    _: 1 /* STABLE */
  })]);
}