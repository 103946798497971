import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_preview_risk = _resolveComponent("preview-risk");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.risks.length || '0',
    title: _ctx.$trans($props.title),
    class: "risks",
    "cookie-hidden-name": "rismahider-my-page-risks",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [$props.viewMode == $data.mypageViews.COMPACT ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        "visibility-switch": true,
        "dataset-table": $options.dataset,
        columns: $options.columns,
        "visibility-storage-name": $options.getVisibilityStorageName(),
        "visibility-default-columns": $options.visibilityDefaultColumns
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createVNode(_component_router_link, {
            to: slotData.url,
            class: _normalizeClass(slotData.additionalClasses)
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(slotData.title), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns", "visibility-storage-name", "visibility-default-columns"])) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass("mypage-list mypage-list-risks my-risks-list-".concat($props.viewMode))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.risks, function (risk) {
        return _openBlock(), _createBlock(_component_preview_risk, {
          key: "preview-risk".concat(risk.id),
          risk: risk,
          scoring: $props.scoring,
          mode: $props.viewMode,
          "is-risk2": !$data.checkERMByProjectId(risk.projectId, _ctx.currentUser),
          "is-approval-flow-enabled": !!(risk.project && risk.project.riskApproval)
        }, null, 8 /* PROPS */, ["risk", "scoring", "mode", "is-risk2", "is-approval-flow-enabled"]);
      }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}