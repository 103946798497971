import ToolTip from '@/components/Atoms/Containers/ToolTip';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
export default {
  name: 'InfoToolTip',
  components: {
    ToolTip: ToolTip,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    toolTipData: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['refresh', 'reset:toolTipData'],
  data: function data() {
    return {
      selectedActivity: null
    };
  },
  methods: {
    onModalDismiss: function onModalDismiss() {
      this.selectedActivity = null;
      this.$emit('refresh');
    },
    selectNode: function selectNode(item) {
      var _this = this;
      this.selectedActivity = item;
      this.$nextTick(function () {
        _this.$refs.preview.showModal(item);
        _this.$emit('reset:toolTipData');
      });
    }
  }
};