import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "action-notification"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_action_breadcrumbs = _resolveComponent("action-breadcrumbs");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [$data.isNotificationShow ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: $options.handleDismissNotification
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */), _createElementVNode("div", {
        innerHTML: $data.notificationDetails
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), $data.initiative.closed === 1 ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.closedMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.isUserLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), $data.isLoaded ? (_openBlock(), _createBlock(_component_action_breadcrumbs, {
    key: 0,
    initiative: $data.initiative
  }, null, 8 /* PROPS */, ["initiative"])) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $data.isLoaded,
    item: $data.initiative,
    "dep-header-props": $options.depHeaderProps,
    "dep-relations-tabs": $data.depRelationsTabs,
    "local-storage-key": "relations-action",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onNotify: $options.handleShowNotify,
    onPropertyChanged: $options.activityPropertyChanged,
    onGetActivityData: $options.getActivityData,
    onSaveStatusClicked: $options.saveStatusClicked,
    onDeleteAction: $options.deleteAction,
    onChangeTab: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    onStatusChanged: $options.onStatusChanged,
    onArchiveAction: $options.onActionArchive,
    "onSilentUpdate:attachments": _cache[1] || (_cache[1] = function ($event) {
      return $data.initiative.attachments = $event;
    })
  }, {
    delegation: _withCtx(function () {
      return [$options.delegationData ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "model-value": $options.delegationData,
        initiatives: $options.allInitiativesExceptCurrent,
        organisations: _ctx.organisations,
        "initiative-types": _ctx.initiativeTypes,
        "read-only": $options.inputsDisabled,
        onAddNewItem: _ctx.updateDelegationList,
        onPropertyChanged: $options.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "initiatives", "organisations", "initiative-types", "read-only", "onAddNewItem", "onPropertyChanged"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [_createVNode(_component_activity_type_selector, {
        "activity-types": _ctx.initiativeTypes,
        "model-value": $data.initiative.activityType,
        disabled: $options.inputsDisabled,
        label: $data.translate.activityType,
        class: "mb-5",
        "onUpdate:modelValue": $options.activityTypeChanged
      }, null, 8 /* PROPS */, ["activity-types", "model-value", "disabled", "label", "onUpdate:modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "item", "dep-header-props", "dep-relations-tabs", "onUpdatingAttachments", "onFileUpdate", "onNotify", "onPropertyChanged", "onGetActivityData", "onSaveStatusClicked", "onDeleteAction", "onStatusChanged", "onArchiveAction"])]);
}