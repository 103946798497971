function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "mb-6"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 0,
  class: "mb-2 w-80"
};
var _hoisted_4 = {
  key: 0,
  class: "w-80"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "mb-1"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_tree_list = _resolveComponent("tree-list");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.dangerZone
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_button, {
        text: $data.translate.copyAllEnterpriseRisksToANewRiskProject,
        type: "save",
        class: "my-4",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$confirm('', $data.translate.areYouSure, function (res) {
            return res && $options.copyRisks();
          });
        })
      }, null, 8 /* PROPS */, ["text"]), _createVNode(_component_risma_button, {
        text: $data.translate.setAllControlsRecent,
        type: "save",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$confirm('', $data.translate.areYouSure, function (res) {
            return res && $options.resetControls();
          });
        })
      }, null, 8 /* PROPS */, ["text"])]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.allUsers.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
        class: "flex items-center mb-2 cursor-pointer",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $data.showSection.users = !$data.showSection.users;
        })
      }, [_createVNode(_component_risma_title, {
        title: $data.translate.hardDeleteUser,
        class: "mr-4"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "".concat($data.showSection.users ? 'chevron-down' : 'chevron-up'),
        width: "25",
        height: "25",
        class: "cursor-pointer"
      }, null, 8 /* PROPS */, ["icon"])]), $data.showSection.users && _ctx.allUsers ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $data.usersData.deleteUserId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.usersData.deleteUserId = $event;
        }),
        title: $data.translate.userToDelete,
        class: "mb-4",
        options: $options.allUsersObject.deleteList,
        placeholder: $data.translate.userToDelete
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.usersData.sendToId,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.usersData.sendToId = $event;
        }),
        title: $data.translate.moveChangelogEntriesTo,
        class: "mb-4",
        options: $options.allUsersObject.sendToList,
        disabled: !$data.usersData.deleteUserId,
        placeholder: $data.translate.moveChangelogEntriesTo
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "placeholder"]), _createVNode(_component_risma_button, {
        disabled: !$data.usersData.sendToId && !$data.usersData.deleteUserId,
        text: $data.translate.delete,
        type: "save",
        class: "my-4",
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$confirm('', $options.deleteUserConfirmMessage, function (res) {
            return res && $options.deleteUser();
          });
        })
      }, null, 8 /* PROPS */, ["disabled", "text"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.massDelete, function (value, key) {
        return _openBlock(), _createElementBlock("div", {
          key: key
        }, [_createElementVNode("div", {
          class: "flex items-center mb-2 cursor-pointer w-30rem",
          onClick: function onClick($event) {
            return $data.showSection[key] = !$data.showSection[key];
          }
        }, [_createVNode(_component_risma_title, {
          title: _ctx.$trans('Mass delete ' + value.label),
          class: "mr-4"
        }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
          icon: "".concat($data.showSection[key] ? 'chevron-down' : 'chevron-up'),
          width: "25",
          height: "25"
        }, null, 8 /* PROPS */, ["icon"])], 8 /* PROPS */, _hoisted_5), _withDirectives(_createElementVNode("div", null, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_checkboxes, {
          "checkbox-size": 20,
          options: {
            checked: false,
            label: $data.translate.selectAll
          },
          onUpdated: function onUpdated($event) {
            return $options.setAllValuesTo(key, $event.isChecked);
          }
        }, null, 8 /* PROPS */, ["options", "onUpdated"])]), value.model.type === $data.ModelTypes.TREE ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.checkboxOptions, function (root) {
          return _openBlock(), _createBlock(_component_tree_list, {
            key: root.value,
            margin: 16,
            item: root
          }, {
            title: _withCtx(function (_ref) {
              var item = _ref.item,
                parent = _ref.parent;
              return [_createVNode(_component_checkboxes, {
                "checkbox-size": 20,
                disabled: parent && $data.toggleObjects[key][parent.value],
                options: _objectSpread(_objectSpread({}, item), {}, {
                  checked: $data.toggleObjects[key][item.value] || false
                }),
                onUpdated: function onUpdated($event) {
                  return $options.onTreeItemCheck(key, item, $event.isChecked);
                }
              }, null, 8 /* PROPS */, ["disabled", "options", "onUpdated"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["item"]);
        }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), value.model.type === $data.ModelTypes.FLAT ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_checkboxes, {
          "checkbox-size": 20,
          options: value.checkboxOptions,
          onUpdated: function onUpdated($event) {
            return $data.toggleObjects[key][$event.value] = $event.isChecked;
          }
        }, null, 8 /* PROPS */, ["options", "onUpdated"])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
          text: _ctx.$trans('Delete all selected ' + value.label),
          type: "save",
          class: "my-4",
          disabled: !$options.flatEntitiesToDelete[key].length,
          onClick: function onClick($event) {
            return _ctx.$confirm('', $data.translate.areYouSure, function (res) {
              return res && $options.deleteEntities(key);
            });
          }
        }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])], 512 /* NEED_PATCH */), [[_vShow, $data.showSection[key]]])]);
      }), 128 /* KEYED_FRAGMENT */))]))];
    }),
    _: 1 /* STABLE */
  });
}