import debounce from 'lodash/debounce';
var timeout;
export default {
  name: 'FreeTextChoice',
  introduction: 'Simple question with text area.',
  description: 'Simple question with text area. The `freeText` is required prop.',
  token: '<free-text-choice free-text="String" @answered="fn" />',
  inject: {
    depRelationEnabled: {
      default: false
    }
  },
  props: {
    freeText: {
      type: String,
      required: true
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    }
  },
  emits: ['forceUpdate', 'answered'],
  data: function data() {
    return {
      text: null,
      handleDebouncedResize: null,
      translate: {
        typeHere: this.$trans('Type here')
      }
    };
  },
  computed: {
    placeholder: function placeholder() {
      return this.readOnly ? '' : "".concat(this.translate.typeHere, "...");
    }
  },
  watch: {
    depRelationEnabled: function depRelationEnabled() {
      this.handleDebouncedResize();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.text = this.freeText;
    this.handleDebouncedResize = debounce(this.resizeHeight, 100);
    this.$nextTick(function () {
      return _this.handleDebouncedResize();
    });
    window.addEventListener('resize', this.handleDebouncedResize);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.handleDebouncedResize);
  },
  methods: {
    emitAnswered: function emitAnswered() {
      var trimmed = this.text.trim();
      if (trimmed !== this.freeText) {
        this.$emit('answered', trimmed);
      }
    },
    setFreeText: function setFreeText(event) {
      var _this2 = this;
      if (this.readOnly) return;
      this.text = event.target.value;
      this.handleDebouncedResize();
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        _this2.emitAnswered();
      }, 400);
    },
    resizeHeight: function resizeHeight() {
      var minHeight = 160;
      var element = this.$el.querySelector('textarea');
      if (!element) return;
      element.style.height = 'auto';
      var newHeight = Math.max(element.scrollHeight, minHeight);
      requestAnimationFrame(function () {
        element.style.height = "".concat(newHeight, "px");
      });
    }
  }
};