// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-edit-modal-kitchen[data-v-0225b223] {
  display: flex;
  justify-content: space-around;
}
.user-edit-modal-kitchen .create[data-v-0225b223] {
  text-align: center;
}
.user-edit-modal-kitchen .update[data-v-0225b223]  h3 {
  cursor: pointer !important;
}
[data-v-0225b223]  i {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
