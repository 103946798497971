function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { ChoiceViewTypes } from '@/constants/ChoiceViewTypes';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
export default {
  name: 'MultiChoice',
  introduction: 'MultiChoice is RismaSelect or Checkboxes component',
  description: 'Ability to select multiple values from the drop-down or checkboxes, uses vue-multiselect as base.',
  token: "\n<multi-choice options=\"options\" answers=\"answers\" @answered=\"fn\"/>\n// Properties looks like\nconst options = {\n    9720:'1-1,000',\n    9721:'1,001-10,000',\n    9725:'Above 10,000,000',\n    9726:'Not possible to indicate',\n};\nconst answers = {\n    9720:'1-1,000',\n    9725:'Above 10,000,000',\n},\n",
  components: {
    Checkboxes: Checkboxes,
    RismaSelect: RismaSelect
  },
  props: {
    options: {
      required: true,
      type: [Object, Array],
      note: 'A list of possible answers'
    },
    answers: {
      required: true,
      type: [Object, Array],
      note: 'A list of predefined answers'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    }
  },
  emits: ['forceUpdate', 'answered'],
  data: function data() {
    return {
      selected: [],
      values: [],
      cbValue: [],
      isOpen: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    viewTypes: function viewTypes() {
      return ChoiceViewTypes;
    },
    viewType: function viewType() {
      return this.getSettingValue('risma.multiple_choice_as_checkbox') ? ChoiceViewTypes.CHECKBOX : ChoiceViewTypes.DROPDOWN;
    },
    cbOptions: function cbOptions() {
      var _this = this;
      return this.options.map(function (option) {
        return {
          value: option.id,
          disabled: _this.readOnly,
          label: option.label
        };
      });
    },
    cbAnswers: function cbAnswers() {
      return Object.keys(this.answers).map(function (i) {
        return +i;
      });
    }
  }),
  watch: {
    options: {
      handler: function handler() {
        this.optionsToValues();
      },
      deep: true
    },
    answers: {
      handler: function handler() {
        this.answersToSelected();
      },
      deep: true
    }
  },
  created: function created() {
    this.optionsToValues();
    this.answersToSelected();
    this.cbValue = Object.keys(this.answers).map(function (i) {
      return +i;
    });
  },
  methods: {
    createLabel: function createLabel(option) {
      return option.option;
    },
    setAnswers: function setAnswers(newVal) {
      var answers = {};
      if (newVal !== null) {
        newVal.forEach(function (v) {
          answers[v.id] = v.option;
        });
      }
      this.$emit('answered', answers);
      if (!this.isOpen) {
        this.$emit('forceUpdate');
      }
    },
    setAnswerRadioButton: function setAnswerRadioButton(answeredOptions) {
      var answers = {};
      if (answeredOptions) {
        answeredOptions.forEach(function (option) {
          return answers[option.value] = option.label;
        });
      }
      this.$emit('answered', answers);
      this.$emit('forceUpdate');
    },
    optionsToValues: function optionsToValues() {
      var values = [];
      for (var key in this.options) {
        var option = this.options[key];
        values.push({
          id: +option.id,
          option: option.label,
          orderBy: +option.orderBy
        });
      }
      this.values = values.sort(function (a, b) {
        return a.orderBy - b.orderBy;
      });
    },
    answersToSelected: function answersToSelected() {
      var _this2 = this;
      var selected = [];
      if (this.answers.null === undefined) {
        var keys = Object.keys(this.answers);
        this.values.forEach(function (value) {
          var key = keys.find(function (key) {
            return value.id === +key;
          });
          if (key) selected.push({
            id: +key,
            option: _this2.answers[key]
          });
        });
      }
      this.selected = selected;
    }
  }
};