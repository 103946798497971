import { stripRecognisedHTMLOnly } from '@/utils';
import { TitleTypes } from '@/constants/TitleTypes';
import { getTextSize } from '@/utils/textSize';
export default {
  name: 'RismaTitle',
  introduction: 'Title and headers',
  description: "The risma-title component is used to make titles on the risma site.\n        Big titles are usually used as page titles, while medium titles are used as section header on a page.\n        The small titles usually is used for input labels, or for listing of strings.\n        The title can not be changed to a empty string, if title is editable, it return last valid/non-empty string.",
  token: '<risma-title title="Main Title" @titleChange="fn" @titleChangeLazy="fn" />',
  props: {
    title: {
      required: true,
      type: String,
      note: 'The text that should be titled'
    },
    editable: {
      required: false,
      default: false,
      type: Boolean,
      note: 'A boolean to determine if the title, should be editable'
    },
    truncate: {
      required: false,
      default: true,
      type: Boolean,
      note: 'A boolean to determine if the title should be truncated'
    },
    type: {
      required: false,
      type: String,
      default: TitleTypes.BIG,
      validator: function validator(type) {
        return Object.values(TitleTypes).indexOf(type) !== -1;
      },
      note: "Type of title. Possible values: ".concat(Object.values(TitleTypes).join(', '))
    },
    titleAttribute: {
      required: false,
      type: String,
      default: '',
      note: 'The extra information about an element, shows when the mouse moves over the element'
    },
    useCalculatedWidth: {
      required: false,
      default: true,
      type: Boolean,
      note: 'Should input field use calculated width when editable'
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: null
    },
    maxLength: {
      required: false,
      type: Number,
      default: 255
    },
    minInputWidth: {
      required: false,
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['click', 'titleChangeLazy', 'titleChange'],
  data: function data() {
    return {
      editedTitle: '',
      lastValidTitle: '',
      timeout: 0,
      sizeOfLetters: {
        small: 14,
        medium: 18,
        big: 27
      },
      TitleTypes: TitleTypes
    };
  },
  computed: {
    sizeLetter: function sizeLetter() {
      return this.sizeOfLetters[this.type];
    },
    titleHTML: function titleHTML() {
      if (this.titleAttribute) {
        return this.titleAttribute;
      }
      return this.truncate ? this.title : '';
    },
    isEmpty: function isEmpty() {
      return this.editedTitle.trim() == '';
    },
    isEditedTitleValid: function isEditedTitleValid() {
      return this.validEditedTitle === this.editedTitle;
    },
    validEditedTitle: function validEditedTitle() {
      return stripRecognisedHTMLOnly(this.editedTitle);
    }
  },
  watch: {
    title: function title() {
      this.editedTitle = this.title;
    }
  },
  created: function created() {
    this.editedTitle = this.title;
    this.lastValidTitle = this.title;
  },
  mounted: function mounted() {
    this.setInputWidth();
  },
  updated: function updated() {
    this.setInputWidth();
  },
  methods: {
    setInputWidth: function setInputWidth() {
      if (!this.editable || !this.useCalculatedWidth) return;
      var input = this.$el;
      if (input !== null) {
        var calcWidth = Math.max(this.minInputWidth, Math.ceil(getTextSize(input.value, "600 ".concat(this.sizeLetter, "px Inter"))) + 20);
        input.style.width = calcWidth + 'px';
      }
    },
    handleChange: function handleChange() {
      var _this = this;
      clearTimeout(this.timeout);
      if (!this.isEditedTitleValid) return;
      this.timeout = setTimeout(function () {
        if (!_this.allowEmpty) {
          if (!_this.isEmpty) {
            _this.lastValidTitle = _this.editedTitle;
            _this.$emit('titleChange', _this.editedTitle);
          }
        } else {
          _this.$emit('titleChange', _this.editedTitle);
        }
      }, 300);
    },
    handleChangeLazy: function handleChangeLazy() {
      if (!this.isEditedTitleValid) return;
      if (this.editedTitle === '' && !this.allowEmpty) this.editedTitle = this.lastValidTitle;
      this.$emit('titleChangeLazy', this.editedTitle);
    },
    handleBlur: function handleBlur() {
      if (!this.isEditedTitleValid) {
        this.editedTitle = this.validEditedTitle;
        this.$emit('titleChangeLazy', this.editedTitle);
        this.$emit('titleChange', this.editedTitle);
      }
    }
  }
};