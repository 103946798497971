import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "mb-4 mt-8"
};
var _hoisted_2 = {
  class: "flex justify-between mb-4"
};
var _hoisted_3 = {
  class: "glob-h2 group-hover:underline"
};
var _hoisted_4 = ["title", "onClick"];
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  key: 1,
  class: "text-center mb-4"
};
var _hoisted_7 = {
  class: "glob-l1 text-rm-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_links_dep_preview = _resolveComponent("links-dep-preview");
  var _component_contract_party_external_section = _resolveComponent("contract-party-external-section");
  var _component_main_well = _resolveComponent("main-well");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_add_contact_person_modal = _resolveComponent("add-contact-person-modal");
  var _component_company_contact_modal = _resolveComponent("company-contact-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_links_dep_preview, {
    data: $props.data,
    onUpdateFromPreview: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreview', $event);
    })
  }, null, 8 /* PROPS */, ["data"]), $props.externalSectionsEnabled ? (_openBlock(), _createBlock(_component_main_well, {
    key: 0,
    class: "h-auto mb-4 mt-8 !z-10"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.sections, function (section, index) {
        return _openBlock(), _createBlock(_component_contract_party_external_section, {
          key: "".concat(section.id).concat($props.contractParty.id),
          section: section,
          options: $options.sectionOptions,
          onUpdate: function onUpdate($event) {
            return $options.handleSectionUpdate($event, index);
          }
        }, null, 8 /* PROPS */, ["section", "options", "onUpdate"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_main_well, {
    class: "h-auto"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $data.translate.contacts
      }, null, 8 /* PROPS */, ["title"]), !$props.readOnly ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "focus:outline-none mr-5 group flex items-center",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
          return $data.showAddContactModal = true;
        }, ["stop"]))
      }, [_createVNode(_component_feather_icon, {
        class: "stroke-rm-text focus:outline-none stroke-2 mr-2",
        icon: "plus-circle"
      }), _createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.addContactPerson), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), $options.datasetTable.length ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        "dataset-table": $options.datasetTable,
        columns: $options.columns,
        "columns-styles": $data.columnsStyles
      }, {
        delete: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "focus:outline-none mr-5",
            title: $data.translate.delete,
            onClick: function onClick($event) {
              return $options.deleteContact(slotData.id);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "text-red-50 stroke-2 cursor-pointer",
            icon: "x",
            title: $data.translate.delete
          }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_4)) : (_openBlock(), _createElementBlock("div", _hoisted_5))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns", "columns-styles"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)]))];
    }),
    _: 1 /* STABLE */
  })]), $data.showAddContactModal && $options.availableContacts.length ? (_openBlock(), _createBlock(_component_add_contact_person_modal, {
    key: 1,
    users: $options.availableContacts,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showAddContactModal = false;
    }),
    onAdd: $options.addContacts
  }, null, 8 /* PROPS */, ["users", "onAdd"])) : _createCommentVNode("v-if", true), $data.showAddContactModal && !$options.availableContacts.length ? (_openBlock(), _createBlock(_component_company_contact_modal, {
    key: 2,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return $data.showAddContactModal = false;
    }),
    onSave: $options.handleCreateContact
  }, null, 8 /* PROPS */, ["onSave"])) : _createCommentVNode("v-if", true)]);
}