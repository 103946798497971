import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex items-end mb-5"
};
var _hoisted_2 = {
  class: "w-38 mb-1"
};
var _hoisted_3 = {
  class: "flex items-end"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "mb-5"
};
var _hoisted_6 = {
  class: "mb-5"
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  class: "mb-5"
};
var _hoisted_9 = {
  class: "mb-5"
};
var _hoisted_10 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_table = _resolveComponent("risma-table");
  var _component_single_select = _resolveComponent("single-select");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
        modelValue: $data.dbName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.dbName = $event;
        }),
        title: $data.translate.templateDb,
        "is-static-height": true,
        class: "template-db-input",
        onOnEnter: $options.handleDBStatusClick
      }, null, 8 /* PROPS */, ["modelValue", "title", "onOnEnter"]), _createVNode(_component_risma_button, {
        type: "util",
        text: $data.translate.update,
        class: "ml-2 h-11 flex items-center",
        onClick: $options.handleDBStatusClick
      }, null, 8 /* PROPS */, ["text", "onClick"])]), _createElementVNode("span", null, _toDisplayString($data.translate.connectionStatus) + ": " + _toDisplayString($data.connectionStatus), 1 /* TEXT */)])]), _createVNode(_component_tab_list, {
        tabs: $options.tabs,
        class: "flex mt-6 mb-6",
        onUpdate: _cache[1] || (_cache[1] = function ($event) {
          return $data.currentTab = $event;
        })
      }, null, 8 /* PROPS */, ["tabs"]), $data.statusLoaded ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_withDirectives(_createElementVNode("div", null, [$data.currentTab === $data.tabNames.questionnaire ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.syncedQuestionnaires,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_table, {
        ref: "syncedQuestionnaires",
        api: $options.getSyncedQuestionnaireData,
        onReload: $options.reloadQuestionnaireTables,
        onError: _cache[2] || (_cache[2] = function ($event) {
          return $options.setNotification($event);
        }),
        "onUpdate:loading": _cache[3] || (_cache[3] = function ($event) {
          return $data.loading = $event;
        })
      }, null, 8 /* PROPS */, ["api", "onReload"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
        title: $data.translate.questionnairesThatCanBeSynced,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_table, {
        ref: "availableQuestionnaires",
        api: $options.getAvailableQuestionnaireData,
        onReload: $options.reloadQuestionnaireTables,
        onError: _cache[4] || (_cache[4] = function ($event) {
          return $options.setNotification($event);
        }),
        "onUpdate:loading": _cache[5] || (_cache[5] = function ($event) {
          return $data.loading = $event;
        })
      }, null, 8 /* PROPS */, ["api", "onReload"])])])) : _createCommentVNode("v-if", true), $data.currentTab === $data.tabNames.gapSchema ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
        title: "Synced Schemas",
        class: "mb-2"
      }), _createVNode(_component_risma_table, {
        ref: "syncedGapSchemas",
        api: $options.getSyncedGapSchemas,
        onReload: $options.reloadGapTables,
        onError: _cache[6] || (_cache[6] = function ($event) {
          return $options.setNotification($event);
        }),
        "onUpdate:loading": _cache[7] || (_cache[7] = function ($event) {
          return $data.loading = $event;
        })
      }, null, 8 /* PROPS */, ["api", "onReload"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
        title: "Schemas that can be synced",
        class: "mb-2"
      }), _createVNode(_component_risma_table, {
        ref: "availableGapSchemas",
        api: $options.getAvailableGapSchemas,
        onReload: $options.reloadGapTables,
        onError: _cache[8] || (_cache[8] = function ($event) {
          return $options.setNotification($event);
        }),
        "onUpdate:loading": _cache[9] || (_cache[9] = function ($event) {
          return $data.loading = $event;
        })
      }, null, 8 /* PROPS */, ["api", "onReload"])])])) : _createCommentVNode("v-if", true), $data.currentTab === $data.tabNames.project ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
        title: "Projects that are synced",
        class: "mb-2"
      }), _createVNode(_component_risma_table, {
        ref: "syncedProjects",
        api: $options.getSyncedProjects,
        onReload: $options.reloadProjectTable,
        onError: _cache[10] || (_cache[10] = function ($event) {
          return $options.setNotification($event);
        }),
        "onUpdate:loading": _cache[11] || (_cache[11] = function ($event) {
          return $data.loading = $event;
        })
      }, null, 8 /* PROPS */, ["api", "onReload"]), _createVNode(_component_risma_title, {
        title: "Sync new project",
        class: "mb-2"
      }), _createVNode(_component_single_select, {
        modelValue: $data.selectedSourceProject,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
          return $data.selectedSourceProject = $event;
        }),
        title: "From",
        placeholder: "Select source project",
        options: $options.sourceProjects
      }, null, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.selectedTargetProject,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = function ($event) {
          return $data.selectedTargetProject = $event;
        }),
        title: "To",
        placeholder: "Select target project",
        options: $options.targetProjects
      }, null, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_risma_button, {
        text: "Synchronise",
        type: "save",
        onClick: $options.synchroniseProject
      }, null, 8 /* PROPS */, ["onClick"])])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), [[_vShow, !$data.loading]]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}