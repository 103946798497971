// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stacked-bar-chart-wrapper .bar-line .bar-item[data-v-9743d01c] {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.stacked-bar-chart-wrapper .bar-line .bar-item.disabled[data-v-9743d01c] {
  display: none;
}
.stacked-bar-chart-wrapper .bar-line .bar-item .bar-item__text[data-v-9743d01c] {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
}
.stacked-bar-chart-wrapper .bar-line.labels[data-v-9743d01c] {
  justify-content: center;
  cursor: pointer;
}
.stacked-bar-chart-wrapper .bar-line.labels .bar-label[data-v-9743d01c] {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  font-weight: bold;
  font-size: 16px;
}
.stacked-bar-chart-wrapper .bar-line.labels .bar-label.disabled .bar-label__square[data-v-9743d01c] {
  background: #737373 !important;
}
.stacked-bar-chart-wrapper .bar-line.labels .bar-label .bar-label__square[data-v-9743d01c] {
  width: 16px;
  height: 16px;
  text-align: center;
  margin-right: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
