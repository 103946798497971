function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import VerticalMenuItem from '@/components/Atoms/VerticalMenuItem/VerticalMenuItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'VerticalMenu',
  introduction: 'Vertical menu',
  description: 'Vertical menu',
  token: "<vertical-menu\n    items=[{\n        groupTitle: 'Group A Title',\n        groupItems: [\n           {\n            path: '/dev/kitchensink/atoms',\n            title: 'Atoms',\n            color: '#000', // optional property\n        }, {\n            path: '/dev/kitchensink/molecules',\n            title: 'Molecules',\n            color: 'green', // optional property\n        }],\n    }, {\n        path: '/dev/kitchensink/organisms'\n        title: 'Organisms'\n    }]\n/>",
  components: {
    VerticalMenuItem: VerticalMenuItem,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    items: {
      type: Array,
      required: true,
      note: 'Array of objects with required fields: path, title'
    },
    selectedId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Is used when in preview mode, to show selected item'
    },
    visibilityStorageKey: {
      type: String,
      required: false,
      default: 'vertical_menu_hidden_groups',
      note: 'local storage key to store hidden groups'
    },
    removable: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Manage removing buttons in each menu'
    },
    openedGroups: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'List of opened groups on load'
    },
    exactMatch: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Determine if router link should be exactly matched with url'
    },
    bottomItems: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'elements in the bottom, mainly its about 1 component, but its possible to have 2 elements'
    }
  },
  emits: ['changeItem', 'removeItem'],
  setup: function setup(props) {
    return {
      closedGroups: getLocalStorageComputed(props.visibilityStorageKey, [], true)
    };
  },
  watch: {
    openedGroups: {
      deep: true,
      handler: function handler() {
        this.setGroupsStatus();
      }
    }
  },
  mounted: function mounted() {
    this.setGroupsStatus();
  },
  methods: {
    setGroupsStatus: function setGroupsStatus() {
      var _this = this;
      if (this.openedGroups.length) {
        this.closedGroups = this.items.map(function (item) {
          return item.groupTitle;
        }).filter(function (item) {
          return !_this.openedGroups.includes(item);
        });
      }
    },
    handleGroupToggle: function handleGroupToggle(groupTitle) {
      if (this.closedGroups.includes(groupTitle)) {
        this.closedGroups = this.closedGroups.filter(function (group) {
          return group !== groupTitle;
        });
        return;
      }
      this.closedGroups = [].concat(_toConsumableArray(this.closedGroups), [groupTitle]);
    },
    isGroupClosed: function isGroupClosed(groupTitle) {
      return this.closedGroups.includes(groupTitle);
    },
    changeItem: function changeItem(id) {
      this.$emit('changeItem', id);
    },
    removeItem: function removeItem(id) {
      this.$emit('removeItem', id);
    }
  }
};