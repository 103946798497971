// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container[data-v-9a9924c8] {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}
.flex-container.flex-container-checked .flex-item[data-v-9a9924c8] {
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-container.flex-container-checked .flex-item .check-icon-container[data-v-9a9924c8] {
  position: relative;
}
.flex-container.flex-container-checked .flex-item .check-icon-container[data-v-9a9924c8]  svg {
  position: inherit;
  top: 0px !important;
  left: 0px !important;
}
.show-more-dots[data-v-9a9924c8] {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
