function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { getTranslate } from './translate';
import { createRisk } from '@/api/risk/risk';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { userHasProjectAccess } from '@/utils/access';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'RiskCreationModal',
  introduction: 'RiskCreationModal multiselect popup',
  description: 'RiskCreationModal with selector and list of selected items',
  token: '<risk-creation-modal />',
  components: {
    RismaSelect: RismaSelect,
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    FrameworksSelector: FrameworksSelector,
    SingleSelect: SingleSelect
  },
  mixins: [RaciTitleMixin],
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
    periodId: {
      type: [String, Number],
      required: false,
      default: null
    },
    parentRnoProp: {
      type: String,
      required: false,
      default: '',
      note: 'if element should have parent'
    },
    risks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      title: '',
      errorMessages: '',
      selectedFrameworks: [],
      isRismaSelectOpen: false,
      responsibleUserIds: [],
      createInProgress: false,
      parentRno: '',
      translate: getTranslate['RiskCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser', 'riskProjectById'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    parentRisks: function parentRisks() {
      return this.risks.filter(function (risk) {
        return !risk.parent;
      }).map(function (risk) {
        return {
          value: risk.rno,
          label: "".concat(risk.rno, ". ").concat(risk.title)
        };
      });
    },
    filteredUsers: function filteredUsers() {
      var _this = this;
      if (this.isERM) {
        return this.users.filter(function (user) {
          return user.level_risk >= UserLevels.NORMAL;
        });
      }
      return this.users.filter(function (user) {
        return userHasProjectAccess(user, _this.projectId, ObjectTypes.RISK_PROJECT) && user.level_risk >= UserLevels.NORMAL;
      });
    },
    preparedUserOptions: function preparedUserOptions() {
      return this.filteredUsers.map(function (item) {
        return {
          name: item.display_name,
          value: item.id
        };
      });
    },
    preselectedResponsibleUsers: function preselectedResponsibleUsers() {
      return [{
        name: this.currentUser.display_name,
        value: this.currentUser.id
      }];
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    responsibleListTitle: function responsibleListTitle() {
      if (this.isERM) {
        return this.translate.dailyRiskOwner;
      }
      return this.$trans(this.responsibleTitle(true));
    },
    currentProject: function currentProject() {
      return this.riskProjectById(this.projectId);
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    this.responsibleUserIds = this.preselectedResponsibleUsers.map(function (item) {
      return item.value;
    });
    if (this.currentProject.solutionId) {
      var framework = this.frameworks.find(function (framework) {
        return framework.id === _this2.currentProject.solutionId;
      });
      this.selectedFrameworks = framework ? [framework] : [];
    }
  },
  methods: {
    handleOnSubmit: function handleOnSubmit() {
      var _this3 = this;
      var data = {
        title: this.title,
        solutionIds: this.selectedFrameworks.map(function (item) {
          return item.id;
        }),
        responsibleUserIds: this.responsibleUserIds,
        activityTypeId: this.currentProject.defaultActivityTypeId
      };
      if (this.parentRnoProp || this.parentRno) {
        data.parent = this.parentRnoProp || this.parentRno;
      }
      if (!this.handleValidation(data)) {
        return;
      }
      this.createInProgress = true;
      createRisk(this.projectId, this.periodId, data).then(function (response) {
        _this3.$emit('create', response);
      }).catch(function (error) {
        return _this3.errorMessages = error || error.error;
      }).finally(function () {
        return _this3.createInProgress = false;
      });
    },
    handleValidation: function handleValidation(data) {
      this.errorMessages = '';
      if (!data.title) {
        this.errorMessages = this.translate.titleCannotBeEmpty;
      }
      return !this.errorMessages;
    },
    onUserSelected: function onUserSelected(users) {
      this.responsibleUserIds = users.map(function (item) {
        return item.value;
      });
    }
  }
};