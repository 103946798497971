import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_tree = _resolveComponent("tree");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.notificationError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    class: "notification-wrap whitespace-pre-line",
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.notificationError = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", {
        innerHTML: $data.notificationError
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_checkboxes, {
    "checkbox-size": 20,
    options: {
      checked: $setup.isSorted,
      label: $data.translate.sortInAlphabeticalOrder
    },
    "center-checkbox": true,
    onUpdated: $options.sortByAlphabet
  }, null, 8 /* PROPS */, ["options", "onUpdated"])]), Object.keys($data.treeData).length !== 0 ? (_openBlock(), _createBlock(_component_tree, {
    ref: "tree",
    key: "tree_refresh_key_".concat($data.treeKey),
    "level-select": false,
    copy: false,
    "tree-rights": true,
    "tree-data": $data.treeData,
    "do-move": false,
    "dataflow-toolbar": false,
    "tree-id": $data.treeId,
    "ability-to-add": true,
    "create-article30-report-option": false,
    "disable-item-menu": false,
    "disable-progress-shown": true,
    "lock-tree": true,
    "delete-node-option": true,
    "new-child": $data.newChild,
    "delete-node-function": $options.deleteOrganisation,
    "update-node-function": $options.updateNode,
    "delete-node-alert-message": $data.translate.areYouSureYouWantToDeleteThisOrganisation,
    "delete-node-label": $data.translate.deleteOrganisation,
    "update-node-label": $data.translate.updateOrganisation,
    "add-sub-node-label": $data.translate.addUnderlyingOrganisation,
    "add-new-node-label": $data.translate.addNew,
    "is-organisation": true,
    "links-structure-by-module": null,
    "show-levels-classes": "right-25px -top-55px mt-2",
    onCreateChild: $options.addChild
  }, null, 8 /* PROPS */, ["tree-data", "tree-id", "new-child", "delete-node-function", "update-node-function", "delete-node-alert-message", "delete-node-label", "update-node-label", "add-sub-node-label", "add-new-node-label", "onCreateChild"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2,
    class: "mt-25vh-80"
  })) : _createCommentVNode("v-if", true)]);
}