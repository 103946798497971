function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getTranslate } from './translate';
import * as dateUtils from '@/utils/date';
import { getAuditFollowup as _getAuditFollowup } from '@/api/compliance/reports/questionnaire';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
export default {
  name: 'AuditFollowup',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    PreviewModalLink: PreviewModalLink
  },
  data: function data() {
    return {
      isLoaded: false,
      audits: [],
      dataset: [],
      error: null,
      updatedFromPreview: false,
      translate: getTranslate['AuditFollowup']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    columns: function columns() {
      return [this.translate.created, this.translate.createdBy, this.translate.recipient, this.translate.assets, this.translate.firstClick, this.translate.deadline];
    }
  }),
  mounted: function mounted() {
    this.getAuditFollowup();
  },
  methods: {
    getAuditFollowup: function getAuditFollowup() {
      var _this = this;
      return _getAuditFollowup().then(function (response) {
        _this.audits = response.data.list;
        _this.dataset = _this.formatAudits(_this.audits, _this.users);
        _this.isLoaded = true;
      }).catch(function (error) {
        _this.error = error;
        _this.isLoaded = true;
      });
    },
    createTableAuditRecord: function createTableAuditRecord(audit, users) {
      var creator = users.find(function (user) {
        return user.id === audit.createdBy;
      });
      return {
        created: dateUtils.toLocalDateTimeFull(audit.created),
        creator: creator.display_name,
        fullNameAndEmail: "".concat(audit.fullname, " (").concat(audit.email, ")"),
        questionnaireActivity: {
          activity: audit.activity
        },
        firstClick: audit.firstClick ? dateUtils.toLocalDateTimeFull(audit.firstClick) : '',
        expiresDate: dateUtils.toLocalDate(audit.expires)
      };
    },
    formatAudits: function formatAudits(audits, users) {
      var _this2 = this;
      var filteredAudits = audits.filter(function (audit) {
        return audit.answered === null || audit.answered.length === 0;
      });
      return filteredAudits.map(function (audit) {
        return _this2.createTableAuditRecord(audit, users);
      });
    },
    onDismissModal: function onDismissModal() {
      if (!this.updatedFromPreview) return;
      this.updatedFromPreview = false;
      return this.getAuditFollowup();
    }
  }
};