import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ExpansionPanel from '@/components/Atoms/ExpansionPanel/ExpansionPanel';
import ExternalSectionOverview from '@/components/Organisms/Contracts/ExternalSectionOverview';
import { getTranslate } from './translate';
export default {
  name: 'ContractPartyExternalSection',
  components: {
    ExpansionPanel: ExpansionPanel,
    ExternalSectionOverview: ExternalSectionOverview,
    RismaTitle: RismaTitle
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      translate: getTranslate['ContractPartyExternalSection']()
    };
  }
};