function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { sortByIndex } from '@/utils/sort';
import { Colors } from '@/Colors';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
export default {
  name: 'QuestionDependencyEditor',
  introduction: '',
  description: '',
  token: '<question-dependency-editor />',
  components: {
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      note: 'Main data model'
    },
    questions: {
      type: [Object, Array],
      required: true
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      idToDelete: null,
      colors: Colors,
      dependencies: [],
      translate: getTranslate['QuestionDependencyEditor']()
    };
  },
  computed: {
    questionsSelect: function questionsSelect() {
      var _this = this;
      var result = [];
      var allowTypes = [QuestionTypes().singleChoice.id, QuestionTypes().multipleChoice.id];
      this.filteredQuestions.forEach(function (item) {
        if (allowTypes.includes(item.questionType)) {
          if (item.options && Object.keys(item.options).length && item.id !== _this.modelValue.id) {
            result.push({
              label: item.questionnumber,
              value: item.questionnumber
            });
          }
        }
      });
      return result;
    },
    filteredQuestions: function filteredQuestions() {
      var ids = this.getDependentQuestionIds(this.questions, this.modelValue.questionnumber);
      return this.questions.filter(function (item) {
        return !ids.includes(item.questionnumber);
      });
    },
    selectedDependencyKeys: function selectedDependencyKeys() {
      return this.dependencies.map(function (item) {
        return item.key;
      });
    },
    hasOptions: function hasOptions() {
      var _this2 = this;
      return this.questionsSelect.filter(function (question) {
        return !_this2.selectedDependencyKeys.includes(question.value);
      }).length > 0;
    }
  },
  beforeMount: function beforeMount() {
    this.dependencies = this.getDependenciesAsArray(this.modelValue.dependencies);
    sortByIndex(this.dependencies, 'key');
  },
  methods: {
    getDependenciesAsArray: function getDependenciesAsArray(data) {
      var result = [];
      if (data) {
        for (var key in data) {
          result.push({
            key: key,
            values: data[key],
            isDisabled: true
          });
        }
      }
      return result;
    },
    prepareOptions: function prepareOptions(key) {
      var result = [];
      var question = this.questions.find(function (item) {
        return item.questionnumber === key;
      });
      if (question && Object.keys(question.options).length) {
        for (var _key in question.options) {
          result.push({
            key: _key,
            title: question.options[_key]
          });
        }
      }
      return result;
    },
    prepareSelectedOptions: function prepareSelectedOptions(key, data) {
      var allOptions = this.prepareOptions(key);
      return allOptions.filter(function (item) {
        return data.includes(item.title);
      });
    },
    onQuestionChanged: function onQuestionChanged(item, val) {
      var existItem = this.dependencies.find(function (dependency) {
        return dependency.key === val;
      });
      if (!existItem) {
        item.key = val;
        item.values = [];
      }
    },
    onSelectAnswers: function onSelectAnswers(data, key) {
      this.$emit('changed', {
        data: data,
        key: key
      });
      var current = this.dependencies.find(function (item) {
        return item.key === key;
      });
      current.values = data.map(function (item) {
        return item.title;
      });
      current.isDisabled = current.values.length > 0;
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      this.$emit('changed', {
        key: this.idToDelete,
        data: []
      });
      this.dependencies = this.dependencies.filter(function (item) {
        return item.key !== _this3.idToDelete;
      });
    },
    deleteDependency: function deleteDependency(data) {
      var _this4 = this;
      this.idToDelete = data.key;
      if (data.isDisabled) {
        this.$confirm('', '', function (res) {
          return res && _this4.onDeleteConfirm();
        });
        return;
      }
      this.onDeleteConfirm();
    },
    addOption: function addOption() {
      var _this5 = this;
      var firstAvailable = {
        key: null,
        values: []
      };
      var _loop = function _loop(i) {
        if (!_this5.dependencies.find(function (item) {
          return item.key === _this5.questionsSelect[i].label;
        })) {
          firstAvailable = {
            key: _this5.questionsSelect[i].label,
            values: []
          };
          return 1; // break
        }
      };
      for (var i = 0; i < this.questionsSelect.length; i++) {
        if (_loop(i)) break;
      }
      this.dependencies.push(_objectSpread(_objectSpread({}, firstAvailable), {}, {
        isDisabled: false
      }));
    },
    getDependentQuestionIds: function getDependentQuestionIds(questions, questionnumber) {
      var _this6 = this;
      var dependencies = questions.filter(function (item) {
        if (!item.dependencies) {
          return false;
        }
        return Object.prototype.hasOwnProperty.call(item.dependencies, questionnumber);
      });
      var questionnumbers = dependencies.map(function (item) {
        return item.questionnumber;
      });
      questionnumbers.forEach(function (questionNumb) {
        if (questionNumb !== _this6.modelValue.questionnumber) {
          questionnumbers.push.apply(questionnumbers, _toConsumableArray(_this6.getDependentQuestionIds(questions, questionNumb)));
        }
      });
      return questionnumbers;
    }
  }
};