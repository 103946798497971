import * as dpmAPI from '@/api/compliance/dpm';
import Notification from '@/components/Molecules/Notification';
import DpmAuditQuestionnaire from '@/components/Organisms/DpmAuditQuestionnaire';
export default {
  name: 'DpmAuditPage',
  components: {
    Notification: Notification,
    DpmAuditQuestionnaire: DpmAuditQuestionnaire
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      user: {},
      isLoaded: false,
      dpmData: {},
      questionnaire: {},
      dpmActivity: {},
      showError: false,
      errorMessage: ''
    };
  },
  mounted: function mounted() {
    this.loadAll();
  },
  methods: {
    loadAll: function loadAll() {
      var _this = this;
      var question = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return dpmAPI.getByToken(this.token).then(function (data) {
        if (question) {
          var _this$dpmData;
          var _ref = ((_this$dpmData = _this.dpmData) === null || _this$dpmData === void 0 ? void 0 : _this$dpmData.questionnaire) || {
              questionAnswers: []
            },
            questionAnswers = _ref.questionAnswers;
          _this.dpmData.questionnaire.questionAnswers = questionAnswers.map(function (answer) {
            if (answer.id === question.id) {
              return data.questionnaire.questionAnswers.find(function (item) {
                return item.id === question.id;
              });
            }
            return answer;
          });
        } else {
          _this.dpmData = data;
          _this.dpmActivity = _this.dpmData.activity || null;
        }
        _this.dpmData.questionnaire.questionAnswers.forEach(function (answer) {
          answer.files.forEach(function (file) {
            if (file.url && file.url.endsWith('?download')) {
              if (question && question.id === answer.id || !question) {
                file.originUrl = file.url;
                file.url = "/api/2.0/dpm/files/".concat(file.id, "/").concat(_this.token, "?download");
              }
            }
          });
        });
        _this.questionnaire = _this.dpmData.questionnaire;
        if (data.answered !== null && data.moreInfoRequested === 0) {
          _this.$refs.auditQuestionnaire.questionnaireSubmitted = true;
        }
        _this.isLoaded = true;
      }).catch(function (e) {
        _this.errorMessage = e.response.error;
        _this.showError = true;
      });
    },
    handleSubmit: function handleSubmit(questions) {
      var _this2 = this;
      var data = {
        questions: questions
      };
      return dpmAPI.submitAudit(this.token, data).catch(function (e) {
        _this2.errorMessage = e.response.error;
        _this2.showError = true;
      });
    }
  }
};