function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getRiskProjects as _getRiskProjects, updateRiskProject as _updateRiskProject, setHeatmapColors as _setHeatmapColors } from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RiskHeatMapSelector from '@/components/Molecules/Risk/RiskHeatMapSelector';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RiskProjects as RiskProjectsConstants } from '@/constants/risks/RiskProjects';
import { DefaultHeatMapColors } from '@/constants/risks/RisksHeatMapColors';
import { prepareDefaultHeatMapColors } from '@/utils/heatMap';
export default {
  name: 'AdminRiskHeatMap',
  description: 'Page with risk heat map settings',
  token: '<admin-risk-heat-map />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    RismaToggle: RismaToggle,
    RismaButton: RismaButton,
    RiskHeatMapSelector: RiskHeatMapSelector,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      projectId: null,
      riskProjects: [],
      toggleState: false,
      currentScoring: {},
      heatMapColors: {},
      showResetAlert: false,
      translate: getTranslate['AdminRiskHeatMap']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['isAdmin'])), {}, {
    isReadonly: function isReadonly() {
      return !this.isAdmin;
    },
    riskProjectsOptions: function riskProjectsOptions() {
      return this.riskProjects.map(function (project) {
        return {
          value: project.id,
          label: project.title
        };
      });
    },
    project: function project() {
      var _this = this;
      return this.riskProjects.find(function (project) {
        return project.id === _this.projectId;
      });
    },
    isErm: function isErm() {
      var _this$project;
      return +((_this$project = this.project) === null || _this$project === void 0 ? void 0 : _this$project.type) === RiskProjectsConstants.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    currentPeriod: function currentPeriod() {
      var _this$project2;
      return (_this$project2 = this.project) === null || _this$project2 === void 0 || (_this$project2 = _this$project2.projectPeriods) === null || _this$project2 === void 0 ? void 0 : _this$project2.find(function (period) {
        return period.isCurrent;
      });
    },
    consequenceScores: function consequenceScores() {
      var _this$currentScoring, _this$currentPeriod;
      return this.isErm ? (_this$currentScoring = this.currentScoring) === null || _this$currentScoring === void 0 ? void 0 : _this$currentScoring.likelihood_scores : (_this$currentPeriod = this.currentPeriod) === null || _this$currentPeriod === void 0 ? void 0 : _this$currentPeriod.consequenceLevels;
    },
    probabilityScores: function probabilityScores() {
      var _this$currentScoring2, _this$currentPeriod2;
      return this.isErm ? (_this$currentScoring2 = this.currentScoring) === null || _this$currentScoring2 === void 0 ? void 0 : _this$currentScoring2.impact_scores : (_this$currentPeriod2 = this.currentPeriod) === null || _this$currentPeriod2 === void 0 ? void 0 : _this$currentPeriod2.probabilityLevels;
    },
    numberOfRows: function numberOfRows() {
      var _this$project3;
      return (_this$project3 = this.project) !== null && _this$project3 !== void 0 && _this$project3.matrixInverted ? this.probabilityScores : this.consequenceScores;
    },
    numberOfColumns: function numberOfColumns() {
      var _this$project4;
      return (_this$project4 = this.project) !== null && _this$project4 !== void 0 && _this$project4.matrixInverted ? this.consequenceScores : this.probabilityScores;
    },
    defaultHeatMapColors: function defaultHeatMapColors() {
      var colorsArraySize = this.numberOfColumns + this.numberOfRows;
      if (colorsArraySize > DefaultHeatMapColors.length) {
        return prepareDefaultHeatMapColors(colorsArraySize);
      }
      return DefaultHeatMapColors;
    },
    defaultColors: function defaultColors() {
      var result = {};
      var maxGridSize = (this.defaultHeatMapColors.length + 1) / 2;
      var xShift = Math.floor((maxGridSize - this.numberOfColumns) / 2);
      var yShift = Math.floor((maxGridSize - this.numberOfRows) / 2);
      var totalShift = xShift + yShift;
      var numberOfCells = this.numberOfRows * this.numberOfColumns;
      for (var i = 0; i < numberOfCells; i++) {
        var index = i % this.numberOfColumns + Math.floor(i / this.numberOfColumns) + totalShift;
        result[i + 1] = this.defaultHeatMapColors[index];
      }
      return result;
    },
    dataset: function dataset() {
      var rows = [];
      for (var row = this.numberOfRows; row > 0; row--) {
        var cells = [];
        for (var col = 1; col <= this.numberOfColumns; col++) {
          var value = (row - 1) * this.numberOfColumns + col;
          cells.push({
            backgroundColor: this.heatMapColors[value],
            value: value
          });
        }
        rows.push(cells);
      }
      return rows;
    },
    isLoading: function isLoading() {
      return !this.dataset.length;
    }
  }),
  mounted: function mounted() {
    this.getData().then(this.setData);
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.getRiskProjects(), this.getCurrentScoring()]);
    },
    getRiskProjects: function getRiskProjects() {
      var _this2 = this;
      return _getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return _this2.riskProjects = list;
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this3 = this;
      return _getCurrentScoring().then(function (scoring) {
        return _this3.currentScoring = scoring;
      });
    },
    setData: function setData() {
      var _this$riskProjects$;
      this.projectId = (_this$riskProjects$ = this.riskProjects[0]) === null || _this$riskProjects$ === void 0 ? void 0 : _this$riskProjects$.id;
      this.setProjectData();
    },
    setProjectData: function setProjectData() {
      var _this$project5;
      this.setDefaultColors();
      this.setColorsFromProject();
      this.toggleState = !!((_this$project5 = this.project) !== null && _this$project5 !== void 0 && _this$project5.customHeatmapEnabled);
    },
    setDefaultColors: function setDefaultColors() {
      this.heatMapColors = _objectSpread({}, this.defaultColors);
    },
    setColorsFromProject: function setColorsFromProject() {
      var _this4 = this;
      this.project.heatmapOptions.forEach(function (_ref2) {
        var number = _ref2.number,
          hexCode = _ref2.hexCode;
        _this4.heatMapColors[number] = "#".concat(hexCode);
      });
    },
    setColor: function setColor(value, color) {
      this.heatMapColors[value] = color;
    },
    resetToDefaultColours: function resetToDefaultColours() {
      this.setDefaultColors();
      this.saveSettings();
      this.showResetAlert = false;
    },
    saveSettings: function saveSettings() {
      var _this5 = this;
      var promises = [];
      if (!!this.project.customHeatmapEnabled !== !!this.toggleState) {
        promises.push(this.updateRiskProject());
      }
      var heatmapOptions = [];
      Object.entries(this.heatMapColors).forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          number = _ref4[0],
          value = _ref4[1];
        var hexCode = value.slice(1);
        heatmapOptions.push({
          number: number,
          hexCode: hexCode
        });
      });
      this.setHeatmapColors(heatmapOptions);
      Promise.all(promises).then(function () {
        return _this5.$notify({
          title: 'Settings are saved',
          type: 'success'
        });
      });
    },
    setHeatmapColors: function setHeatmapColors(heatmapOptions) {
      var _this6 = this;
      return _setHeatmapColors(this.projectId, {
        heatmapOptions: heatmapOptions
      }).then(function (response) {
        return _this6.setColorInProject(response.list);
      });
    },
    updateRiskProject: function updateRiskProject() {
      var _this7 = this;
      var data = {
        projectId: this.projectId,
        customHeatmapEnabled: this.toggleState
      };
      return _updateRiskProject(this.projectId, data).then(function (response) {
        _this7.project.customHeatmapEnabled = response.customHeatmapEnabled;
      });
    },
    setColorInProject: function setColorInProject(heatmapOptions) {
      var _this8 = this;
      var project = this.riskProjects.find(function (project) {
        return +project.id === +_this8.projectId;
      });
      heatmapOptions.forEach(function (_ref5) {
        var number = _ref5.number,
          hexCode = _ref5.hexCode;
        var heatmapOption = project.heatmapOptions.find(function (option) {
          return +option.number === +number;
        });
        if (heatmapOption) {
          heatmapOption.hexCode = hexCode;
          return;
        }
        project.heatmapOptions.push({
          number: number,
          hexCode: hexCode
        });
      });
    },
    handleResetNotification: function handleResetNotification() {
      var _this9 = this;
      this.$confirm(this.translate.areYouSure, this.translate.areYouSureYouWantToResetToDefaultColors, function (res) {
        return res && _this9.resetToDefaultColours();
      }, {
        buttonOkText: this.translate.ok,
        buttonDismissText: this.translate.cancel
      });
    }
  }
};