function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import { createNewGroup, updateFieldGroups } from '@/api/admin/fieldGroups';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Modal from '@/components/Molecules/Modal/Modal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'FieldGroupsModal',
  components: {
    InputField: InputField,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    draggable: draggable
  },
  props: {
    fieldGroups: {
      type: Array,
      default: function _default() {
        return [];
      },
      require: false
    },
    groupItems: {
      type: Object,
      default: null,
      require: false
    },
    scoringId: {
      type: [String, Number],
      default: null,
      require: true
    }
  },
  emits: ['create', 'dismiss', 'move:groups', 'move:fields'],
  data: function data() {
    return {
      groupName: '',
      showAddGroup: false,
      updatedData: {},
      translate: getTranslate['FieldGroupsModal']()
    };
  },
  computed: {
    tasksGroupNested: function tasksGroupNested() {
      var _this = this;
      return this.fieldGroups.map(function (item) {
        item.tasks = _this.groupItems[item.id] || [];
        return item;
      });
    }
  },
  methods: {
    dismissModal: function dismissModal() {
      this.$emit('dismiss');
    },
    showAddGroupBlock: function showAddGroupBlock() {
      this.showAddGroup = !this.showAddGroup;
    },
    startTransition: function startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    endTransition: function endTransition(el) {
      el.style.height = '';
      this.groupName = '';
    },
    addGroup: function addGroup() {
      var _this2 = this;
      return createNewGroup(this.scoringId, {
        name: this.groupName
      }).then(function (response) {
        var newGroup = _objectSpread({}, response);
        delete newGroup._debug;
        _this2.$emit('create', newGroup);
        _this2.showAddGroupBlock();
      });
    },
    moveGroup: function moveGroup(_ref) {
      var _this3 = this;
      var moved = _ref.moved;
      var fieldGroups = this.changeOrderLocal(this.fieldGroups, moved.oldIndex, moved.newIndex);
      return updateFieldGroups(this.scoringId, {
        fieldGroups: fieldGroups
      }).then(function (response) {
        _this3.$emit('move:groups', response);
      });
    },
    changeOrderLocal: function changeOrderLocal(array, from, to) {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return array;
    },
    onMoveFields: function onMoveFields(groupId, fields) {
      fields.forEach(function (field, index) {
        field.sorting = ++index;
        field.fieldGroup = groupId;
      });
      this.updatedData[groupId] = fields;
    },
    moveFields: function moveFields() {
      this.$emit('move:fields', this.updatedData);
      this.updatedData = {};
    }
  }
};