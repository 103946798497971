function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import { ControlMyKeys } from '@/constants/ControlMyKeys';
import { viewTypes } from '@/constants/ListViewType';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { MODULES } from '@/constants/modules';
var MODULE_NAME = MODULES.CONTROLS;
export default {
  name: 'ControlsListPreview',
  components: {
    PreviewListExpanded: PreviewListExpanded,
    PreviewListTile: PreviewListTile,
    PreviewList: PreviewList,
    CompactCard: CompactCard
  },
  props: {
    activities: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    filteredOrganisations: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    groupLabel: {
      type: String,
      required: true,
      default: ''
    },
    cookieHiddenKey: {
      type: String,
      required: true,
      default: ''
    },
    additionalFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    viewType: {
      type: String,
      required: false,
      default: viewTypes.TILES,
      validator: function validator(type) {
        return [viewTypes.TILES, viewTypes.EXPANDED, viewTypes.COMPACT, viewTypes.RISMA_TABLE].includes(type);
      }
    },
    isMotherControls: {
      type: Boolean,
      required: false,
      default: false
    },
    excludeActivityVisibleProps: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['reloadRootPage', 'updateFromPreviewMode', 'dismissModal', 'onCompleteControl'],
  data: function data() {
    return {
      ControlButtonTypes: ControlButtonTypes,
      viewTypes: viewTypes,
      MODULE_NAME: MODULE_NAME,
      ControlMyKeys: ControlMyKeys
    };
  },
  computed: _objectSpread({}, mapState(useUsersStore, ['users'])),
  methods: {
    onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
      this.$emit('updateFromPreviewMode', $event);
    },
    onCompleteControl: function onCompleteControl($event) {
      this.$emit('onCompleteControl', $event);
    }
  }
};