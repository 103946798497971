function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var RismaVueChartMixin = {
  props: {
    datasets: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'the datasets to create the component'
    },
    labels: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    options: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'the options to create the component'
    },
    showLegend: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine if there legend should be displayed or not'
    },
    legendPosition: {
      required: false,
      type: String,
      default: 'bottom',
      validator: function validator(position) {
        return ['top', 'bottom', 'left', 'right'].includes(position);
      }
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    },
    excelExportEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    chartOptions: function chartOptions() {
      return _objectSpread({
        plugins: {
          legend: {
            display: this.showLegend,
            position: this.legendPosition
          },
          tooltip: {
            enabled: true,
            label: ''
          },
          datalabels: {
            color: '#fff',
            align: 'center',
            anchor: 'center',
            offset: -3,
            font: {
              size: this.datasets.length > 1 ? '9px' : '14px'
            },
            display: function display(context) {
              return +context.dataset.data[context.dataIndex];
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 10
            }
          }
        },
        fill: true,
        cubicInterpolationMode: 'monotone',
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 1
      }, this.options);
    },
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    },
    isExcelExportAvailable: function isExcelExportAvailable() {
      return this.excelExportEnabled;
    }
  },
  methods: {
    excelExportFunction: function excelExportFunction() {
      var hiddenData = localStorage.getItem(this.localStorageKey);
      var dataset = [];
      var columns = {
        0: ''
      };
      if (this.datasets.length > 1) {
        dataset = this.datasets.map(function (row) {
          var result = {
            0: row.label || ''
          };
          row.data && row.data.forEach(function (item, idx) {
            result[idx + 1] = item || '';
          });
          return result;
        });
        if (hiddenData && this.localStorageKey) {
          hiddenData = JSON.parse(hiddenData);
          dataset = dataset.filter(function (item) {
            return hiddenData.find(function (data) {
              return data.label === item[0];
            }).visible;
          });
        }
        this.labels.forEach(function (label, idx) {
          columns[idx + 1] = label || '';
        });
      } else {
        dataset = [Object.assign({}, this.datasets[0].data || [])];
        columns = Object.assign({}, this.labels || []);
      }
      return Promise.resolve({
        dataset: dataset,
        columns: columns
      });
    }
  }
};