import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  width: "20",
  height: "14",
  viewBox: "0 0 20 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = ["fill"];
var _hoisted_4 = ["fill"];
var _hoisted_5 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [$props.titleAttrEnabled ? (_openBlock(), _createElementBlock("title", _hoisted_2, "RISMA AI Partner")) : _createCommentVNode("v-if", true), _createElementVNode("path", {
    d: "M18.0941 13.3288C17.7533 13.3288 17.4734 13.2193 17.2544 13.0002C17.0353 12.7811 16.9258 12.5012 16.9258 12.1605C16.9258 11.8197 17.0353 11.5398 17.2544 11.3208C17.4734 11.1017 17.7533 10.9922 18.0941 10.9922C18.4348 10.9922 18.7147 11.1017 18.9338 11.3208C19.1529 11.5398 19.2624 11.8197 19.2624 12.1605C19.2624 12.5012 19.1529 12.7811 18.9338 13.0002C18.7147 13.2193 18.4348 13.3288 18.0941 13.3288Z",
    fill: $props.fillColor
  }, null, 8 /* PROPS */, _hoisted_3), _createElementVNode("path", {
    d: "M13.1397 13.3245C13.018 13.3245 12.3655 13.3245 12.3872 12.6435V1.32448C12.3656 0.550285 13.0186 0.550285 13.1403 0.550285C13.262 0.550285 14.1397 0.479511 14.1397 1.32448V12.6435C14.1397 13.3023 13.6485 13.3246 13.5268 13.3246L13.1397 13.3245Z",
    fill: $props.fillColor
  }, null, 8 /* PROPS */, _hoisted_4), _createElementVNode("path", {
    d: "M8.87664 13.325C8.76711 13.325 8.61983 13.1897 8.48954 12.9379C8.35925 12.686 7.76367 11.0466 7.76367 11.0466C7.7515 11.0101 7.72716 10.9918 7.69065 10.9918H2.56109C2.52458 10.9918 2.50024 11.0101 2.48807 11.0466L1.90587 12.9379C1.86936 13.0352 1.6283 13.325 1.51878 13.325C1.51878 13.325 0.884723 13.3284 0.750855 13.3284C0.616988 13.3284 0.196167 13.1427 0.362579 12.5508C0.399088 12.4169 3.45932 2.09856 3.45932 2.09856C3.84642 0.550781 4.96648 0.550782 5.0077 0.550782C4.11938 0.551008 5.06247 0.550768 5.0077 0.550782C5.89603 0.550555 6.169 0.937255 6.55609 2.09855L10.0412 12.5508C10.3251 13.1824 9.76361 13.325 9.65408 13.325H8.87664ZM2.94444 9.51322C2.93227 9.53756 2.93227 9.5619 2.94444 9.58624C2.96878 9.59841 2.99312 9.60449 3.01746 9.60449H7.21603C7.24037 9.60449 7.25863 9.59841 7.2708 9.58624C7.29514 9.5619 7.30122 9.53756 7.28905 9.51322L5.17151 2.74074C5.15934 2.7164 5.14108 2.70423 5.11674 2.70423C5.0924 2.70423 5.07415 2.7164 5.06198 2.74074L2.94444 9.51322Z",
    fill: $props.fillColor
  }, null, 8 /* PROPS */, _hoisted_5)]);
}