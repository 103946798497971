import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "mb-8"
};
var _hoisted_2 = {
  key: 1,
  onsubmit: "return false"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  class: "flex-grow relative"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = {
  class: "flex gap-x-6 mb-8"
};
var _hoisted_8 = {
  key: 0,
  class: "w-1/2"
};
var _hoisted_9 = {
  key: 1,
  class: "w-1/2"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  key: 0,
  class: "col-12 mb-8"
};
var _hoisted_12 = {
  key: 1,
  class: "flex gap-x-6 mb-8"
};
var _hoisted_13 = {
  class: "w-1/2"
};
var _hoisted_14 = {
  class: "w-1/2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_link_company = _resolveComponent("link-company");
  var _component_linked_company = _resolveComponent("linked-company");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risk_grid = _resolveComponent("risk-grid");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risk_module = _resolveComponent("risk-module");
  var _component_risk_assessments_isms = _resolveComponent("risk-assessments-isms");
  var _component_single_select = _resolveComponent("single-select");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_questionnaire_stats = _resolveComponent("questionnaire-stats");
  var _component_alert = _resolveComponent("alert");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [!$data.pageLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("form", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_ctx.isFieldInOptionalFields('description') ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_tiny_mce_box, {
    class: "mb-6",
    headline: $data.translate.description,
    "initial-content": _ctx.description,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content"])])) : _createCommentVNode("v-if", true), _ctx.isFieldInOptionalFields('purpose') ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_tiny_mce_box, {
    class: "mb-6",
    headline: $data.translate.purpose,
    "initial-content": _ctx.purpose,
    onChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.purpose = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content"])])) : _createCommentVNode("v-if", true), $options.isUserAbleToEditCompanies && !_ctx.companyId ? (_openBlock(), _createBlock(_component_link_company, {
    key: 2,
    class: "mb-8",
    onLink: _ctx.linkCompany
  }, null, 8 /* PROPS */, ["onLink"])) : _createCommentVNode("v-if", true), _ctx.linkedCompany ? (_openBlock(), _createBlock(_component_linked_company, {
    key: 3,
    company: _ctx.linkedCompany,
    "vat-no": $props.data.vatNo,
    class: "mb-8",
    "is-recently-linked": _ctx.isRecentlyLinked,
    "read-only": !$options.isUserAbleToEditCompanies,
    onUnlink: _ctx.unlinkCompany,
    "onUpdate:company": _ctx.onCompanyUpdated
  }, null, 8 /* PROPS */, ["company", "vat-no", "is-recently-linked", "read-only", "onUnlink", "onUpdate:company"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_7, [_ctx.isFieldInOptionalFields('location') ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_input_field, {
    modelValue: _ctx.location,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.location = $event;
    }),
    title: $data.translate.city,
    placeholder: $data.translate.city,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])) : _createCommentVNode("v-if", true), _ctx.isFieldInOptionalFields('email') ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_input_field, {
    modelValue: _ctx.email,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.email = $event;
    }),
    title: $data.translate.email,
    placeholder: $data.translate.email,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])) : _createCommentVNode("v-if", true)])]), $options.showRiskOnSystems && _ctx.currentPeriod && _ctx.riskRelevantToProject ? (_openBlock(), _createBlock(_component_risk_grid, {
    key: 0,
    "risk-grid-data": _ctx.matrix,
    "without-z-axis": true,
    "grid-box-size": _ctx.gridBoxSize,
    "enable-prev-position": false,
    "is-grid-clickable": false,
    "start-color": _ctx.startColor,
    "end-color": _ctx.endColor,
    "axis-with-labels": false,
    "custom-heatmap-options": _ctx.currentRiskProject.customHeatmapEnabled ? _ctx.currentRiskProject.heatmapOptions : [],
    class: "pl-10 pt-12",
    "dot-label-hover-function": _ctx.dotHoverFunction,
    "dot-connector-enabled": _ctx.inherentRisksEnabled
  }, null, 8 /* PROPS */, ["risk-grid-data", "grid-box-size", "start-color", "end-color", "custom-heatmap-options", "dot-label-hover-function", "dot-connector-enabled"])) : _createCommentVNode("v-if", true)]), $data.pageLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_ctx.showRiskAssessment ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.riskAssessment,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _ctx.riskData !== null ? (_openBlock(), _createBlock(_component_risk_module, {
    key: 0,
    id: "risk",
    risks: _ctx.riskData,
    onImpactUpdate: _ctx.updateRisk,
    onLikelihoodUpdate: _ctx.updateRisk
  }, null, 8 /* PROPS */, ["risks", "onImpactUpdate", "onLikelihoodUpdate"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.showRiskOnSystems ? (_openBlock(), _createBlock(_component_risk_assessments_isms, {
    key: 1,
    activity: _ctx.risk,
    "obj-type": $props.data.objType,
    "current-risk-project": _ctx.currentRiskProject,
    period: _ctx.currentPeriod,
    threats: _ctx.threatsWithCategoryLabel,
    "custom-threats": _ctx.customThreats,
    "custom-threats-enabled": _ctx.customThreatsEnabled,
    "inherent-risks-enabled": _ctx.inherentRisksEnabled,
    "read-only": _ctx.isCreatingRisk,
    class: "flex-grow mb-8",
    "threat-update-in-progress": _ctx.threatUpdateInProgress,
    onChanged: _ctx.onRiskAssesmentsChange,
    onUpdateCustomThreat: _ctx.onUpdateCustomThreat
  }, null, 8 /* PROPS */, ["activity", "obj-type", "current-risk-project", "period", "threats", "custom-threats", "custom-threats-enabled", "inherent-risks-enabled", "read-only", "threat-update-in-progress", "onChanged", "onUpdateCustomThreat"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _ctx.getSettingValue('feature.risk_assessment_on_systems') && _ctx.rtoOptions && _ctx.rtoOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createVNode(_component_single_select, {
    title: $data.translate.recoveryTimeObjective,
    "model-value": _ctx.rtoValue,
    options: _ctx.rtoOptions,
    "with-reset": true,
    "un-sorted": true,
    placeholder: $data.translate.selectRto,
    disabled: $options.inheritRtoRpo,
    class: "mr-2",
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.selectRecoveryValue($event, _ctx.rtoFieldId);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_single_select, {
    title: $data.translate.recoveryPointObjective,
    "model-value": _ctx.rpoValue,
    options: _ctx.rpoOptions,
    "with-reset": true,
    "un-sorted": true,
    placeholder: $data.translate.selectRpo,
    disabled: $options.inheritRtoRpo,
    class: "mr-2",
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.selectRecoveryValue($event, _ctx.rpoFieldId);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled"])])])) : _createCommentVNode("v-if", true), _ctx.activityTypes.length && $props.data.activityTypeId && _ctx.currentActivityType ? (_openBlock(), _createBlock(_component_activity_custom_fields, {
    key: 2,
    "activity-type": _ctx.currentActivityType,
    "custom-field-values": $props.data.customFieldValues,
    disabled: false,
    class: "mb-8",
    onChanged: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'customFieldValuesParsed',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values"])) : _createCommentVNode("v-if", true)])), _createVNode(_component_questionnaire_stats, {
    questionnaires: $props.data.questionnaires
  }, null, 8 /* PROPS */, ["questionnaires"])]), $data.showRetry && $data.retryAction ? (_openBlock(), _createBlock(_component_alert, {
    key: 0,
    header: $data.translate.anErrorOccurred,
    "button-ok-text": $data.translate.tryAgain,
    body: "",
    onAccept: $data.retryAction,
    onDismiss: _cache[7] || (_cache[7] = function ($event) {
      return $data.showRetry = false;
    })
  }, null, 8 /* PROPS */, ["header", "button-ok-text", "onAccept"])) : _createCommentVNode("v-if", true)]);
}