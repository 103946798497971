import { UserLevels } from '@/constants/UserLevels';
import { calcIncidentsRights, calcComplianceRights, calcContractRights, calcRiskRights } from '@/menus/quicknav';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { userHasSomeProjectAccess } from '@/utils/access';
import { userHasControlPanelAccess, isAdmin } from '@/routes/auth';
import { ConfigurableAuthMethods } from '@/constants/admin/ConfigurableAuthMethods';
export var SettingsNav = {
  personal: {
    title: 'Personal settings',
    path: '/settings/user',
    routerName: 'UserSettingsProfile'
  },
  user: {
    title: 'User settings',
    path: '/settings/user',
    routerName: 'UserSettingsProfile'
  },
  notifications: {
    title: 'Notifications',
    path: '/settings/notifications'
  },
  general: {
    title: 'General administration',
    path: '/settings/administration',
    routerName: 'UserRightsTable',
    rights: function rights(user) {
      return user.is_admin;
    }
  },
  anonymization: {
    title: 'User anonymization',
    path: '/settings/anonymization',
    rights: function rights(user, settings) {
      return user.is_admin && settings['feature.anonymization_admin_enabled'] === 1;
    }
  },
  emails: {
    title: 'E-mails',
    path: '/settings/emails'
  },
  siemControlPanel: {
    title: 'SIEM control panel',
    path: '/settings/siem'
  },
  activityTypes: {
    title: 'Custom fields and Activity types',
    path: '/settings/activitytypes'
  },
  suggestedActivities: {
    title: 'Suggested activities',
    path: '/settings/suggestedactivities'
  },
  tags: {
    title: 'Tags',
    path: '/settings/tags'
  },
  nonWorkingDays: {
    title: 'Non-working days',
    path: '/settings/nonworkingdays'
  },
  admin: {
    title: 'RISMA admin',
    path: '/settings/rismaadmin',
    routerName: 'RismaAdminEntry'
  },
  adsettings: {
    title: 'Single sign-on settings',
    path: '/settings/adsettings',
    rights: function rights(user, settings) {
      return user.is_admin && (ConfigurableAuthMethods.includes(settings['app.authentication_mode']) || settings['app.authentication_mode'] === undefined);
    }
  },
  auditReports: {
    title: 'Audit reports',
    path: '/settings/reports'
  },
  bulkUpdate: {
    title: 'Bulk update',
    path: '/settings/bulk'
  },
  complianceSettings: {
    title: 'Compliance settings',
    path: '/settings/complianceprojects',
    routerName: 'ProjectsAdmin',
    rights: function rights(user, settings) {
      return user.is_admin && settings['app.module_compliance_enabled'] === 1;
    }
  },
  complianceProjects: {
    title: 'Projects',
    path: '/settings/complianceprojects'
  },
  complianceRiskAssessment: {
    title: 'Risk assessment',
    path: '/settings/complianceriskassessment'
  },
  complianceAssets: {
    title: 'Assets',
    path: '/settings/complianceassets',
    rights: function rights(user, settings) {
      return user.is_admin && settings['app.module_compliance_enabled'] === 1 && settings['feature.disable_information_assets'] !== 1;
    }
  },
  actionsSettings: {
    title: 'Actions settings',
    path: '/settings/actioncp',
    routerName: 'ActionControlPanel',
    rights: function rights(user, settings) {
      return (user.is_admin || user.level_execution >= UserLevels.SUPER) && settings['app.module_execution_enabled'] === 1;
    }
  },
  actionControlPanel: {
    title: 'Action control panel',
    path: '/settings/actioncp'
  },
  complianceTemplateNodes: {
    title: 'Template nodes',
    path: '/settings/templatenodes'
  },
  complianceQuestionnaires: {
    title: 'Questionnaires',
    path: '/settings/compliancequestionnaires'
  },
  contractQuestionnaires: {
    title: 'Questionnaires',
    path: '/settings/contractquestionnaires'
  },
  complianceLiaTia: {
    title: 'LIA and TIA settings',
    path: '/settings/liatia'
  },
  complianceGapSchemas: {
    title: 'Gap schemas',
    path: '/settings/gapschemas'
  },
  complianceRismaRules: {
    title: 'RISMA logic',
    path: '/settings/rismarules'
  },
  complianceRelevantQuestions: {
    title: 'Relevant questions',
    path: '/settings/relevantquestions'
  },
  complianceControlPanel: {
    title: 'Compliance control panel',
    path: '/settings/compliancecp',
    rights: function rights(user, settings) {
      return calcComplianceRights(user, settings, UserLevels.SUPER);
    },
    routerName: 'ComplianceControlPanel'
  },
  workflowStatuses: {
    title: 'Workflow statuses',
    path: '/settings/workflowstatuses',
    rights: function rights() {
      return isAdmin();
    }
  },
  controlPanel: {
    title: 'Control panel',
    path: '/settings/controlpanel',
    routerName: 'AdminControlPanel',
    rights: function rights() {
      return userHasControlPanelAccess(UserLevels.SUPER);
    }
  },
  controlsSettings: {
    title: 'Controls settings',
    path: '/settings/controlsadmin',
    routerName: 'AdminControls',
    rights: function rights(user, settings) {
      return (user.level_controls >= UserLevels.SUPER || user.is_admin) && settings['app.module_controls_enabled'] === 1;
    }
  },
  controlsAdmin: {
    title: 'Account/Process/Risk/Control risk type',
    path: '/settings/controlsadmin'
  },
  controlControlPanel: {
    title: 'Controls control panel',
    path: '/settings/controlcp'
  },
  importData: {
    title: 'Data import',
    path: '/settings/importdata'
  },
  organisations: {
    title: 'Organisations',
    path: '/settings/organisations',
    routerName: 'OrganisationTree'
  },
  bankruptcy: {
    title: 'Bankruptcy list',
    path: '/settings/bankruptcy'
  },
  contract: {
    title: 'Contracts settings',
    path: '/settings/contractscontrolpanel',
    routerName: 'ContractsControlPanel',
    rights: function rights(user, settings) {
      return user.is_admin && settings['app.module_contracts_enabled'] === 1;
    }
  },
  riskProjects: {
    title: 'Projects',
    routerName: 'RiskAdminProjects',
    path: '/settings/riskprojects',
    rights: function rights(user, settings) {
      return calcRiskRights(user, settings, UserLevels.SUPER);
    }
  },
  riskSettings: {
    title: 'Risk settings',
    path: '/risk/admin',
    rights: function rights(user, settings) {
      return calcRiskRights(user, settings, UserLevels.SUPER);
    }
  },
  riskPeriod: {
    title: 'Risk period',
    path: '/settings/riskproject',
    rights: function rights(user, settings) {
      return settings['app.module_risks2_enabled'] && userHasSomeProjectAccess(user, ObjectTypes.RISK_PROJECT, UserLevels.SUPER);
    }
  },
  riskSettingsERM: {
    title: 'ERM period',
    path: '/risk/admin',
    rights: function rights(user) {
      return user.is_admin;
    }
  },
  riskThreatsVulnerability: {
    title: 'Threats and vulnerability catalogue',
    path: '/settings/threatsvulnerability',
    routerName: 'AdminRisksThreatsVulnerability',
    rights: function rights(user, settings) {
      return settings['app.module_risks2_enabled'] && user.is_admin && user.projects.find(function (project) {
        return project.objType === ObjectTypes.RISK_PROJECT && project.enabled;
      });
    }
  },
  riskControlPanel: {
    title: 'Risk control panel',
    path: '/settings/riskcp',
    rights: function rights(user, settings) {
      return calcRiskRights(user, settings, UserLevels.SUPER);
    }
  },
  riskHeatMap: {
    title: 'Risk matrix settings',
    path: '/settings/riskheatmap',
    rights: function rights(user, settings) {
      return calcRiskRights(user, settings, UserLevels.SUPER);
    }
  },
  riskQuestionnaire: {
    title: 'Questionnaires',
    path: '/settings/riskquestionnaires'
  },
  userAdmin: {
    title: 'User administration',
    path: '/settings/administration',
    routerName: 'UserRightsTable'
  },
  userActionRequirements: {
    title: 'User actions configuration',
    path: '/settings/requirements'
  },
  lookFeel: {
    title: 'Look and feel',
    path: '/settings/customization'
  },
  appSettings: {
    title: 'App settings',
    path: '/settings/appinvitations',
    routerName: 'AppInvitations',
    rights: function rights(user, settings) {
      return user.is_admin && settings['feature.app_settings_enabled'] === 1;
    }
  },
  appInvitation: {
    title: 'Invitations',
    path: '/settings/appinvitations'
  },
  appControlPanel: {
    title: 'App control panel',
    path: '/settings/appcontrolpanel'
  },
  contractsControlPanel: {
    title: 'Contracts control panel',
    path: '/settings/contractscontrolpanel',
    routerName: 'ContractsControlPanel',
    rights: function rights(user, settings) {
      return calcContractRights(user, settings, UserLevels.SUPER);
    }
  },
  incident: {
    title: 'Incidents settings',
    routerName: 'IncidentsControlPanel',
    path: '/settings/incidentscontrolpanel',
    rights: function rights(user, settings) {
      return calcIncidentsRights(user, settings, UserLevels.SUPER);
    }
  },
  incidentsControlPanel: {
    title: 'Incidents control panel',
    path: '/settings/incidentscontrolpanel'
  },
  incidentsWorkflows: {
    title: 'Incidents workflows',
    path: '/settings/incidents/workflow',
    rights: function rights(user, settings) {
      return user.is_admin && settings['feature.incidents.workflow'] === 1;
    }
  },
  incidentsQuestionnaire: {
    title: 'Questionnaires',
    path: '/settings/incidentquestionnaires'
  },
  documentTypes: {
    title: 'Document types',
    path: '/settings/documenttypes'
  },
  framework: {
    title: 'Framework',
    path: '/settings/framework',
    rights: function rights(user, settings) {
      return user.is_admin && settings['feature.performance_report_enabled'] === 1;
    }
  },
  aiPartner: {
    title: 'AI Partner',
    path: '/settings/aipartner',
    rights: function rights(user, settings) {
      return user.is_admin && settings['feature.overall.ai_assistant_enabled'] === 1;
    }
  }
};