var _myPageKeyByObjectTyp;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ObjectTypes } from '@/constants/ObjectTypes';
import { getModuleTitles } from '@/constants/modules';
export var MypageKeys = {
  SHARED_REPORTS: 'sharedReports',
  MY_REPORTS: 'reports',
  MY_INITIATIVES: 'initiatives',
  MY_CONTROLS: 'controls',
  MY_RISKS: 'risks',
  MY_PROCESS: 'processes',
  MY_INCIDENTS: 'incidents',
  MY_CONTRACTS: 'contracts',
  PROCESS_LIBRARY: 'processTreeNodes',
  INFORMATION_ASSETS: 'informationAssets',
  UNANSWERED_AWARENESS: 'unansweredAwareness'
};
export var getCurrentActivities = function getCurrentActivities() {
  var _ref;
  var ModuleTitles = getModuleTitles();
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, ObjectTypes.INCIDENTS_INCIDENT, {
    title: ModuleTitles.incidents,
    icon: '/assets/svg/ikon_incident.svg'
  }), ObjectTypes.INCIDENTS_INCIDENT_LIMITED, {
    title: ModuleTitles.incidents,
    icon: '/assets/svg/ikon_incident.svg'
  }), ObjectTypes.CONTROLS_CONTROL, {
    title: ModuleTitles.control,
    icon: '/assets/svg/ikon_controls.svg'
  }), ObjectTypes.CONTROLS_CONTROL_LIMITED, {
    title: ModuleTitles.control,
    icon: '/assets/svg/ikon_controls.svg'
  }), ObjectTypes.COMPLIANCE_NODE, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), ObjectTypes.COMPLIANCE_NODE_LIMITED, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), ObjectTypes.RISK_RISK_LIMITED, {
    title: ModuleTitles.risk,
    icon: '/assets/svg/ikon_risk.svg'
  }), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, {
    title: ModuleTitles.compliance_process_tree_node,
    icon: '/assets/svg/icon_compliance.svg'
  }), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED, {
    title: ModuleTitles.compliance_process_tree_node,
    icon: '/assets/svg/ikon_execution.svg'
  }), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, {
    title: ModuleTitles.compliance,
    icon: '/assets/svg/icon_compliance.svg'
  }), ObjectTypes.EXECUTION_INITIATIVE, {
    title: ModuleTitles.execution,
    icon: '/assets/svg/ikon_execution.svg'
  }), ObjectTypes.EXECUTION_INITIATIVE_LIMITED, {
    title: ModuleTitles.execution,
    icon: '/assets/svg/ikon_execution.svg'
  }), ObjectTypes.CONTRACT, {
    title: ModuleTitles.contracts,
    icon: '/assets/svg/ikon_contracts.svg'
  }), ObjectTypes.CONTRACT_LIMITED, {
    title: ModuleTitles.contracts,
    icon: '/assets/svg/ikon_contracts.svg'
  });
};
export var myPageKeyByObjectType = (_myPageKeyByObjectTyp = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_myPageKeyByObjectTyp, ObjectTypes.RISK_RISK_LIMITED, MypageKeys.MY_RISKS), ObjectTypes.EXECUTION_INITIATIVE, MypageKeys.MY_INITIATIVES), ObjectTypes.EXECUTION_INITIATIVE_LIMITED, MypageKeys.MY_INITIATIVES), ObjectTypes.COMPLIANCE_NODE, MypageKeys.MY_PROCESS), ObjectTypes.COMPLIANCE_NODE_LIMITED, MypageKeys.MY_PROCESS), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, MypageKeys.PROCESS_LIBRARY), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED, MypageKeys.PROCESS_LIBRARY), ObjectTypes.INCIDENTS_INCIDENT, MypageKeys.MY_INCIDENTS), ObjectTypes.INCIDENTS_INCIDENT_LIMITED, MypageKeys.MY_INCIDENTS), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, MypageKeys.INFORMATION_ASSETS), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_myPageKeyByObjectTyp, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, MypageKeys.INFORMATION_ASSETS), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, MypageKeys.INFORMATION_ASSETS), ObjectTypes.CONTROLS_CONTROL, MypageKeys.MY_CONTROLS), ObjectTypes.CONTRACT, MypageKeys.MY_CONTRACTS), ObjectTypes.CONTRACT_LIMITED, MypageKeys.MY_CONTRACTS));