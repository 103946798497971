// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[data-v-5f5f3d21]::-ms-clear {
  display: none;
}
input[data-v-5f5f3d21]::-moz-placeholder {
  font-style: italic;
}
input[data-v-5f5f3d21]::placeholder {
  font-style: italic;
}
.required input[data-v-5f5f3d21] {
  border-color: #B03D00;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
