import GapSummaryTable from '@/components/Organisms/Compliance/GapSummaryTable';
export default {
  name: '',
  components: {
    GapSummaryTable: GapSummaryTable
  },
  data: function data() {
    return {
      dataset1: [{
        node: {
          title: 'Test C1',
          activity: {
            objType: 'compliance-node',
            id: null,
            compliance_projects_id: null
          }
        },
        questionnaire: 'GDPR default questionnaire',
        total: 93,
        gaps: [81, 2, 2, 2, 4, 2]
      }, {
        node: {
          title: 'Test C2',
          activity: {
            objType: 'compliance-node',
            id: null,
            compliance_projects_id: null
          }
        },
        questionnaire: 'Plesner default v10 EN',
        total: 55,
        gaps: [5, 20, 20, 10, 0, 0]
      }, {
        node: {
          title: 'Test C3',
          activity: {
            objType: 'compliance-node',
            id: null,
            compliance_projects_id: null
          }
        },
        questionnaire: 'GDPR DK Databehandler',
        total: 55,
        gaps: [55, 0, 0, 0, 0, 0]
      }],
      dataset2: [{
        node: {
          title: 'Test C1',
          activity: {
            objType: 'compliance-node',
            id: null,
            compliance_projects_id: null
          }
        },
        total: 93,
        gaps: [81, 2, 2, 2, 4, 2]
      }, {
        node: {
          title: 'Test C2',
          activity: {
            objType: 'compliance-node',
            id: null,
            compliance_projects_id: null
          }
        },
        total: 55,
        gaps: [5, 20, 20, 10, 0, 0]
      }, {
        node: {
          title: 'Test C3',
          activity: {
            objType: 'compliance-node',
            id: null,
            compliance_projects_id: null
          }
        },
        total: 55,
        gaps: [55, 0, 0, 0, 0, 0]
      }]
    };
  }
};