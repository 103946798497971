function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { updateUser } from '@/api/admin/user';
import { getCurrentScoring } from '@/api/risk/scoring';
import { getAllNotifications, setNotificationRead, setAllNotificationAsRead } from '@/api/risma/comm';
import { clickOutside } from '@/utils/directives/clickOutside';
import { getTranslate } from '@/components/Organisms/translate';
import NavItem from '@/components/Atoms/NavItem/NavItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import UserMenu from '@/components/Organisms/UserMenu';
import ReleaseNotification from '@/components/Molecules/ReleaseNotification';
import QuickCreateIncidentModal from '@/components/Organisms/QuickCreateIncidentModal';
import { buildNavBar } from '@/menus/navbar';
import { buildMainNavMenuStructure, getModuleFromPath } from '@/menus';
import { MenuKeys } from '@/constants/menu/MenuKeys';
import { MODULES } from '@/constants/modules';
import { ColorSettings, LogoSettings } from '@/constants/admin/CustomizationSettings';
import { Colors } from '@/Colors';
import { getCustomMultiShadow } from '@/utils/colors';
export default {
  name: 'HeaderNavBar',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    NavItem: NavItem,
    UserMenu: UserMenu,
    FeatherIcon: FeatherIcon,
    ReleaseNotification: ReleaseNotification,
    QuickCreateIncidentModal: QuickCreateIncidentModal
  },
  props: {
    risma2enable: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true,
      default: 0,
      note: 'the id to get all the data about user'
    },
    userMenuEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'display user menu component'
    }
  },
  emits: ['adjustContentPadding'],
  data: function data() {
    return {
      isLoaded: false,
      currentPath: '',
      projects: [],
      navBar: [],
      currentSubTitle: null,
      activeNavItem: {},
      adminSubMenuList: [],
      module: null,
      subMenuList: [],
      notifications: [],
      scoring: {},
      logoSettings: LogoSettings,
      Colors: Colors,
      ColorSettings: ColorSettings,
      showNavBarScroll: false,
      currentScrollPosition: 0,
      maxScroll: null,
      hasScrollToTheEnd: false,
      navBarScrollLeft: 0,
      handleDebouncedScroll: null,
      showCreateIncidentModal: false,
      translate: getTranslate['HeaderNavBar']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    settings: 'settings'
  })), mapState(useActivityTypesStore, ['activityTypes'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUserStore, ['currentUser'])), {}, {
    rismaVersion: function rismaVersion() {
      return this.getSettingValue('app.version');
    },
    showReleaseNotifications: function showReleaseNotifications() {
      return this.rismaVersion !== this.currentUser.current_version;
    },
    colorSettings: function colorSettings() {
      var _this = this;
      var settings = {};
      Object.keys(ColorSettings).forEach(function (item) {
        //  '--' helps it to work in css (line 2 adds it to css)
        settings['--' + item] = _this.getSettingValue(ColorSettings[item].settingName) || ColorSettings[item].color;
      });
      return settings;
    },
    hasLeftArrow: function hasLeftArrow() {
      return this.currentScrollPosition;
    },
    hasRightArrow: function hasRightArrow() {
      return this.currentScrollPosition < this.maxScroll;
    },
    imgSrc: function imgSrc() {
      var hasCustomLogo = this.getSettingValue(this.logoSettings.SETTING);
      if (hasCustomLogo) {
        return this.logoSettings.CUSTOM;
      }
      if (this.currentPath === '/') {
        return this.logoSettings.DEFAULT_WITH_RISMA;
      }
      return this.logoSettings.DEFAULT;
    },
    currentSection: function currentSection() {
      var category = this.activeNavItem.title || this.currentSubTitle;
      var subCategory = this.activeNavItem.title && this.currentSubTitle;
      return subCategory ? subCategory : category;
    },
    isAdminSectionActive: function isAdminSectionActive() {
      return this.currentPath && this.currentPath.indexOf('admin') !== -1;
    },
    informationAssetsName: function informationAssetsName() {
      var settingValue = this.getSettingValue('feature.information_assets_name');
      return settingValue === 'Information Assets' ? this.$trans(settingValue) : this.getSettingValue('feature.information_assets_name');
    },
    childStyle: function childStyle() {
      var margin = -16;
      if (this.showNavBarScroll) margin -= this.navBarScrollLeft;
      return "margin-left: ".concat(margin, "px;");
    },
    arrowShadows: function arrowShadows() {
      var color = this.colorSettings['--settingsBgColor'];
      return {
        left: getCustomMultiShadow(color, 0.45, 0.82, 1),
        right: getCustomMultiShadow(color, 0.45, 0.82, -1)
      };
    },
    watchedSettings: function watchedSettings() {
      return {
        'feature.enable_data_controllers': this.settings['feature.enable_data_controllers'],
        'feature.enable_data_processors': this.settings['feature.enable_data_processors'],
        'feature.enable_systems': this.settings['feature.enable_systems'],
        'feature.information_assets_name': this.settings['feature.information_assets_name'],
        'feature.process_library_name': this.settings['feature.process_library_name']
      };
    }
  }),
  watch: {
    informationAssetsName: function informationAssetsName() {
      if (!this.navBar) return;
      var complianceMenu = this.navBar.find(function (item) {
        return item.key === MODULES.COMPLIANCE;
      });
      if (!complianceMenu || !complianceMenu.children) return;
      var iaMenu = complianceMenu.children.find(function (item) {
        return item.menuKey === MenuKeys.ia;
      });
      if (!iaMenu) return;
      iaMenu.title = this.informationAssetsName;
      if (!iaMenu.children) return;
      var myIaMenu = iaMenu.children.find(function (item) {
        return item.menuKey === MenuKeys.myIA;
      });
      if (!myIaMenu) return;
      myIaMenu.title = "".concat(this.translate.my, " ").concat(this.informationAssetsName);
    },
    $route: function $route(to) {
      if (this.showReleaseNotifications && this.currentPath != to.path) {
        this.dismissVersionPanel();
      }
      this.currentPath = to.path;
      this.module = this.getModuleName(to.path);
      this.setActiveItemOfMainNav(this.module);
      this.subMenuList = this.setSubMenuList();
    },
    showReleaseNotifications: {
      handler: function handler() {
        this.$emit('adjustContentPadding', this.showReleaseNotifications ? 'pt-140px' : 'pt-105px');
      },
      immediate: true
    },
    'currentUser.projects': function currentUserProjects(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        this.prepareNavigation();
      }
    },
    watchedSettings: function watchedSettings(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        this.prepareNavigation();
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.currentPath = window.location.pathname;
    this.module = this.getModuleName(this.currentPath);
    this.handleDebouncedScroll = debounce(this.handleTopScroll, 100);
    this.$refs.navBar.addEventListener('scroll', this.handleDebouncedScroll);
    Promise.all([getAllNotifications().then(function (_ref) {
      var messages = _ref.messages;
      return _this2.notifications = messages;
    }), this.getScoringForSearchMenu()]).then(function () {
      _this2.prepareNavigation();
      _this2.setActiveItemOfMainNav(_this2.module);
      // updates for risma1
      _this2.adjustCurrentPath(_this2.module);
      _this2.subMenuList = _this2.setSubMenuList();
      _this2.$nextTick(function () {
        window.addEventListener('resize', this.checkNavBarWidth);
        this.addTriggerToResizeOnTheScrollBarAppearance();
        this.checkNavBarWidth();
      });
      _this2.isLoaded = true;
    });
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.checkNavBarWidth);
  },
  methods: {
    addTriggerToResizeOnTheScrollBarAppearance: function addTriggerToResizeOnTheScrollBarAppearance() {
      //the only way to listen to the scrollbar appearance
      var iframe = document.createElement('iframe');
      iframe.style.cssText = 'height: 0; background-color: transparent; margin: 0; padding: 0; overflow: hidden; border-width: 0; position: absolute; width: 100%;';
      iframe.onload = function () {
        iframe.contentWindow.addEventListener('resize', function () {
          var evt = document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        });
      };
      document.body.appendChild(iframe);
    },
    handleTopScroll: function handleTopScroll() {
      this.currentScrollPosition = Math.floor(this.$refs.navBar.scrollLeft);
    },
    scrollNavBar: function scrollNavBar(value) {
      this.$refs.navBar.scrollLeft += value;
    },
    checkNavBarWidth: function checkNavBarWidth() {
      var _this3 = this;
      if (!this.$refs.navBar) return;
      this.$nextTick(function () {
        _this3.showNavBarScroll = _this3.$refs.navBar.scrollWidth > _this3.$refs.navBar.offsetWidth;
        _this3.$refs.submenuItems.forEach(function (item) {
          item.classList.remove('top-border');
          if (item.hasAttribute('data-has-children') && item.offsetTop > 24) item.classList.add('top-border');
        });
        _this3.$nextTick(function () {
          var fullWidth = _this3.$refs.navBar.scrollWidth;
          var offsetWidth = _this3.$refs.navBar.offsetWidth;
          _this3.maxScroll = fullWidth - offsetWidth;
          _this3.currentScrollPosition = Math.floor(_this3.$refs.navBar.scrollLeft);
          if (_this3.hasScrollToTheEnd) {
            _this3.setScrollToTheEnd();
            _this3.hasScrollToTheEnd = false;
          }

          // max scroll sometimes can be 1px higher than offsetWidth possible
          var maxDifference = 1;
          if (Math.abs(_this3.currentScrollPosition - _this3.maxScroll) <= maxDifference) _this3.currentScrollPosition = _this3.maxScroll;
        });
      });
    },
    setScrollToTheEnd: function setScrollToTheEnd() {
      this.$refs.navBar.scrollLeft = this.maxScroll;
    },
    getScoringForSearchMenu: function getScoringForSearchMenu() {
      var _this4 = this;
      return getCurrentScoring().then(function (scoring) {
        _this4.scoring = scoring;
      });
    },
    setSubMenuList: function setSubMenuList() {
      if (this.isAdminSectionActive) {
        this.activeNavItem = {};
        this.currentSubTitle = this.translate.administration;
        return this.adminSubMenuList;
      }
      var isActiveNavItem = !!Object.keys(this.activeNavItem).length;
      if (isActiveNavItem && this.isBigMenu(this.activeNavItem)) {
        var currentList = this.getSectionList();
        this.currentSubTitle = currentList && currentList.title;
        return currentList && currentList.children.filter(function (item) {
          return !item.isHiddenInSubMenu;
        });
      }
      this.currentSubTitle = null;
      return isActiveNavItem ? this.activeNavItem.children.filter(function (item) {
        return !item.isHiddenInSubMenu && !item.isButton;
      }) : [];
    },
    getSectionList: function getSectionList() {
      var _this5 = this;
      return this.activeNavItem.children.find(function (childrenItem) {
        if (childrenItem.aliasesRegex && _this5.currentPath.match(childrenItem.aliasesRegex)) {
          return true;
        }
        return childrenItem.children.some(function (item) {
          if (item.children) {
            var _item$children;
            return (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.find(function (item) {
              return item.url === _this5.currentPath || item.aliasesRegex && _this5.currentPath.match(item.aliasesRegex);
            });
          }
          return item.url === _this5.currentPath || item.aliasesRegex && _this5.currentPath.match(item.aliasesRegex);
        });
      });
    },
    getModuleName: function getModuleName(currentPath) {
      var moduleName = getModuleFromPath(currentPath, this.settings);
      if (moduleName === MODULES.EXECUTION) moduleName = MODULES.EXECUTION;
      if (moduleName === MODULES.AWARENESS) moduleName = MODULES.COMPLIANCE;
      if (currentPath.indexOf('admin') !== -1) moduleName = 'admin';
      return moduleName;
    },
    prepareNavigation: function prepareNavigation() {
      var navBar = [];
      var menuStructure = buildNavBar(this.settings, this.currentUser);
      var innerStructure = buildMainNavMenuStructure(this.settings, this.currentUser, this.activityTypes.filter(function (item) {
        return item.module === MODULES.COMPLIANCE_INFORMATION_ASSETS;
      }));
      this.adminSubMenuList = innerStructure && innerStructure.admin;
      Object.keys(menuStructure).forEach(function (item) {
        var menu = menuStructure[item];
        if (!menu.rights) {
          return;
        }
        menu.selected = false;
        menu.hovered = false;
        menu.key = item;
        menu.children = [];
        if (innerStructure[item]) {
          menu.children = innerStructure[item].filter(function (nav) {
            if (nav.children && nav.children.length) {
              nav.children = nav.children.filter(function (navItem) {
                return navItem.rights;
              });
            }
            nav.hovered = false;
            return nav.rights;
          });
        }
        navBar.push(menu);
        navBar.push({}); // every second menu is dummy, used for layout purpose
      });
      this.navBar = navBar;
    },
    isBigMenu: function isBigMenu(item) {
      if (!item.children) return false;
      return item.children.some(function (item) {
        return item.children && item.children.length > 0;
      });
    },
    setActiveItemOfMainNav: function setActiveItemOfMainNav(module) {
      var _this6 = this;
      if (!this.navBar.length) return;
      var activeNav = {};
      this.navBar.forEach(function (item) {
        if (item.key == module) {
          item.active = true;
          activeNav = item;
        } else {
          item.active = false;
        }
      });
      this.activeNavItem = activeNav;
      this.$nextTick(function () {
        var isLast = _this6.navBar.slice(-2).find(function (item) {
          return item.key === module;
        });
        if (isLast) {
          if (!_this6.maxScroll) _this6.hasScrollToTheEnd = true;else _this6.setScrollToTheEnd();
        } else {
          var selectedMenuElement = document.getElementsByClassName('selected-main-menu-item')[0];
          if (!selectedMenuElement) return;
          selectedMenuElement.scrollIntoView({
            block: 'nearest',
            inline: 'center'
          });
        }
      });
    },
    adjustCurrentPath: function adjustCurrentPath(module) {
      var segmentsList = this.currentPath.split('/');
      if (segmentsList[2] === 'q' && module === MODULES.EXECUTION) {
        var _activeModule = this.navBar.find(function (item) {
          return item.key === MODULES.EXECUTION;
        });
        var activeMenu = _activeModule.children.find(function (item) {
          return item.menuKey === MenuKeys.reports;
        });
        return this.currentPath = activeMenu.url;
      }
      var activeModule = this.navBar.find(function (item) {
        return item.key === module;
      });
      if (segmentsList[3] === 'my-risks' && module === MODULES.RISK) {
        var _activeMenu = activeModule.children[0].children.find(function (item) {
          return item.menuKey === MenuKeys.myRisks;
        });
        return this.currentPath = _activeMenu.url;
      }
      if (segmentsList[2] === 'overview' && module === MODULES.RISK) {
        var _activeMenu2 = activeModule.children[0].children.find(function (item) {
          return item.menuKey === MenuKeys.riskOverview;
        });
        return this.currentPath = _activeMenu2.url;
      }
    },
    getSubMenuStyle: function getSubMenuStyle(link) {
      var isBigMenu = this.isBigMenu(link);
      var visibleStyle = link.selected ? 'visible' : '';
      var mainStyle = isBigMenu ? 'flex w-full py-3' : 'flex-col py-6';
      return "".concat(mainStyle, " ").concat(visibleStyle);
    },
    selectNavItem: function selectNavItem(event, linkItem) {
      if (!event.target.closest('.main-nav-item')) return this.hideSubNavigation();
      if (!linkItem.selected) {
        this.hideSubNavigation();
      }
      linkItem.selected = !linkItem.selected;
      this.navBarScrollLeft = this.$refs.navBar.scrollLeft;
      if (linkItem.isRedirectLink) {
        this.$router.push(linkItem.url);
      }
    },
    hideSubNavigation: function hideSubNavigation() {
      this.navBar.forEach(function (link) {
        return link.selected = false;
      });
    },
    onNotificationClick: function onNotificationClick(notificationId) {
      var _this7 = this;
      var index, notification;
      notification = this.notifications.find(function (notification, idx) {
        index = idx;
        return notification.id === notificationId;
      });
      if (notification) {
        if (!notification.read) {
          setNotificationRead(notificationId).then(function () {
            notification.read = true;
            _this7.notifications[index] = notification;
          });
        }
        if (this.$route.path !== notification.regarding_url) {
          this.$router.push(notification.regarding_url);
        }
      }
    },
    handleMarkAllNotificationsAsRead: function handleMarkAllNotificationsAsRead() {
      var _this8 = this;
      setAllNotificationAsRead().then(function (_ref2) {
        var messages = _ref2.messages;
        return _this8.notifications = messages;
      });
    },
    checkActiveSubItem: function checkActiveSubItem(item) {
      if (!item.checkActiveSubItem) return item.url === this.currentPath;
      return item.checkActiveSubItem(this.currentPath);
    },
    dismissVersionPanel: function dismissVersionPanel() {
      var data = {
        current_version: this.rismaVersion
      };
      this.updateCurrentUser(data);
    },
    updateCurrentUser: function updateCurrentUser(data) {
      var _this9 = this;
      return updateUser(data, this.currentUser.id).then(function () {
        _this9.currentUser.current_version = _this9.rismaVersion;
      });
    },
    onButtonClick: function onButtonClick(action) {
      this[action]();
    },
    openCreateIncident: function openCreateIncident() {
      this.showCreateIncidentModal = true;
    }
  }
};