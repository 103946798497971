import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  key: 3
};
var _hoisted_2 = {
  class: "w-80 mb-3"
};
var _hoisted_3 = ["data-id", "data-method", "onClick"];
var _hoisted_4 = {
  class: "mr-2"
};
var _hoisted_5 = ["href"];
var _hoisted_6 = ["href"];
var _hoisted_7 = {
  key: 2
};
var _hoisted_8 = {
  key: 0,
  class: "w-80 mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_gap_types_list = _resolveComponent("gap-types-list");
  var _component_template_list = _resolveComponent("template-list");
  var _component_info_tool_tip = _resolveComponent("info-tool-tip");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.gapSchemas,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), $data.showTitleModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.enterNewTitle,
    type: "small",
    "show-buttons": true,
    "ok-button-disabled": !$data.newTitle,
    "button-ok-text": $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.doConfirm,
    onDismiss: $options.closePopups
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.newTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newTitle = $event;
        }),
        modelModifiers: {
          trim: true
        },
        "focus-on-mount": true,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "ok-button-disabled", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
    modelValue: $data.gapSchemaType,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.gapSchemaType = $event;
    }),
    title: $data.translate.chooseGapSchemaType,
    options: $options.gapSchemaTypes,
    "un-sorted": true,
    placeholder: $data.translate.selectOne
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), $options.formattedData.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 0,
    columns: $options.columns,
    "dataset-table": $options.formattedData
  }, _createSlots({
    usage: _withCtx(function (_ref) {
      var _slotData$nodes;
      var slotData = _ref.slotData;
      return [_withDirectives((_openBlock(), _createElementBlock("div", {
        ref: "helptooltip".concat(slotData.index),
        class: "flex"
      }, [_createElementVNode("span", _hoisted_4, _toDisplayString(slotData.count), 1 /* TEXT */), (_slotData$nodes = slotData.nodes) !== null && _slotData$nodes !== void 0 && _slotData$nodes.length ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "info",
        class: "stroke-3 text-blue-750 cursor-pointer",
        onClick: function onClick($event) {
          return _ctx.handleHelpCircleClick(slotData.nodes, slotData.index);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, function (evt) {
        return _ctx.handleClickOusideToolTip(slotData.index, evt);
      }]])];
    }),
    enabled: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createVNode(_component_checkboxes, {
        "checkbox-size": 20,
        disabled: slotData.isDisabled,
        options: slotData.isChecked,
        "center-checkbox": true,
        onUpdated: function onUpdated($event) {
          return $options.handleEvents(['enabled', slotData.param3, slotData.param4, slotData.param5]);
        }
      }, null, 8 /* PROPS */, ["disabled", "options", "onUpdated"])];
    }),
    edit: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createVNode(_component_router_link, {
        to: slotData.url
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            class: "inline-block",
            icon: "edit"
          })];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
    }),
    download: _withCtx(function (_ref4) {
      var slotData = _ref4.slotData;
      return [_createElementVNode("a", {
        href: slotData.url + '?download=csv',
        class: "pointer-cursor"
      }, [_createVNode(_component_feather_icon, {
        class: "inline-block",
        icon: "file-text"
      })], 8 /* PROPS */, _hoisted_5), _cache[3] || (_cache[3] = _createTextVNode("   ")), _createElementVNode("a", {
        href: slotData.url + '?download=excel',
        class: "pointer-cursor"
      }, [_createVNode(_component_feather_icon, {
        class: "inline-block",
        icon: "download"
      })], 8 /* PROPS */, _hoisted_6)];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.eventFields, function (eventSlotName) {
    return {
      name: eventSlotName,
      fn: _withCtx(function (_ref5) {
        var slotData = _ref5.slotData;
        return [slotData.disableEvent ? (_openBlock(), _createElementBlock("div", {
          key: "disabled_div_".concat(eventSlotName)
        }, _toDisplayString(slotData.text), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("a", {
          key: "event_div_".concat(eventSlotName),
          href: "#",
          "data-id": slotData.dataId,
          "data-method": slotData.dataMethod,
          class: "pointer-cursor",
          onClick: function onClick($event) {
            return $options.handleEvents([slotData.param2, slotData.param3, slotData.param4, slotData.param5]);
          }
        }, [!slotData.removeIcon ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          class: "inline-block",
          icon: slotData.icon
        }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(slotData.text), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_3))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset-table"])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: $data.translate.thereAreNoGapSchemasOfTheSelectedType,
    type: "medium",
    class: ""
  }, null, 8 /* PROPS */, ["title"])), $data.projectIdBySelectedType ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.gapTypes,
    class: "mt-10 mb-3"
  }, null, 8 /* PROPS */, ["title"]), $options.isCSRD ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_single_select, {
    modelValue: $data.projectIdBySelectedType,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.projectIdBySelectedType = $event;
    }),
    title: $data.translate.chooseProject,
    options: $options.csrdProjectOptions,
    "un-sorted": true,
    placeholder: $data.translate.selectOne
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])])) : _createCommentVNode("v-if", true), $options.showGapArticleTypesForSelectedProject ? (_openBlock(), _createBlock(_component_gap_types_list, {
    key: 1,
    "compliance-project-id": $data.projectIdBySelectedType
  }, null, 8 /* PROPS */, ["compliance-project-id"])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 2,
    title: $data.translate.gapTypesAreDeactivatedForThisProject,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]))])) : _createCommentVNode("v-if", true), _createVNode(_component_template_list, {
    type: "gap",
    templates: $data.templates,
    "selected-type": $data.gapSchemaType,
    class: "mt-10",
    onCreated: $options.getGapSchemas
  }, null, 8 /* PROPS */, ["templates", "selected-type", "onCreated"]), _createVNode(_component_info_tool_tip, {
    "tool-tip-data": $data.currentToolTipData,
    onRefresh: $options.getGapSchemas,
    "onReset:toolTipData": _ctx.resetToolTip
  }, null, 8 /* PROPS */, ["tool-tip-data", "onRefresh", "onReset:toolTipData"])]))]);
}