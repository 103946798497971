function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import { getNodeById as _getNodeById, updateNodeById } from '@/api/compliance/processtree';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import GovernanceReviewSection from '@/components/Organisms/Compliance/GovernanceReview/GovernanceReviewSection';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { GovernanceReviewMixin } from '@/mixins/GovernanceReviewMixin';
import { TabTypes } from '@/constants/Tabs';
import { saveStatus } from '@/constants/SaveStatuses';
import { MODULES } from '@/constants/modules';
import { isLockedProcessLibrary } from '@/utils/access';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'ProcessNodeEntry',
  description: 'Common page for show process node',
  token: '<process-node-entry />',
  components: {
    RismaTitle: RismaTitle,
    DepWell: DepWell,
    DelegationWell: DelegationWell,
    GovernanceReviewSection: GovernanceReviewSection,
    ActivityTypeSelector: ActivityTypeSelector,
    Notification: Notification
  },
  mixins: [GovernanceReviewMixin, ProcessLibraryTitleMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    processTreeId: {
      required: true,
      type: [String, Number],
      note: 'Process tree Id from route'
    },
    processNodeId: {
      required: true,
      type: [String, Number],
      note: 'Process note Id from route'
    }
  },
  emits: ['changeTab', 'updated', 'updateFromPreviewMode', 'accessError'],
  data: function data() {
    return {
      processNode: null,
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      module: MODULES.COMPLIANCE_PROCESS_TREE_NODE,
      moduleId: this.processNodeId,
      translate: {
        areYouSure: this.$trans('Are you sure?'),
        activityType: this.$trans('Activity type'),
        thisActivityIsReadonlyAsYourUserIsConfigu: this.$trans('This activity is read-only as your user is configured to only have read-only access')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['isAdmin', 'currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers',
    usersWithPoliciesAccess: 'usersWithPoliciesAccess'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, ['processNodeActivityTypes'])), {}, {
    isUserLocked: function isUserLocked() {
      return !this.isAdmin && isLockedProcessLibrary(this.currentUser);
    },
    isLoaded: function isLoaded() {
      return this.processNode != null;
    },
    awarenessEnabled: function awarenessEnabled() {
      return this.getSettingValue('feature.awareness_enabled') === 1;
    },
    tabs: function tabs() {
      var tabs = [TabTypes.DESCRIPTION, TabTypes.LINKS, TabTypes.CHANGELOG];
      if (this.awarenessEnabled) {
        tabs.splice(tabs.length - 1, 0, TabTypes.AWARENESS);
      }
      return createTabsList(tabs, "/compliance/processtree/".concat(this.processTreeId, "/nodes/").concat(this.processNodeId), getLinksLabel(this.processNode));
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.processNode.uniqueId || '',
        title: this.processNode.title,
        editableId: !this.isUserLocked,
        moduleId: this.moduleId,
        module: this.module,
        editableTitle: !this.isUserLocked,
        editableAttachments: !this.isUserLocked,
        titleField: 'title',
        idField: 'uniqueId',
        filesList: this.processNode.attachments,
        users: this.usersWithPoliciesAccess,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        trafficLightSettings: {
          value: this.processNode.trafficLight,
          lightType: this.module,
          field: 'trafficLight',
          readOnly: this.isUserLocked
        },
        hasVersionButtons: !this.isUserLocked
      };
    },
    processNodeFormatted: function processNodeFormatted() {
      if (!this.processNode) return {};
      var result = _objectSpread(_objectSpread({}, this.processNode), {}, {
        module: this.module,
        customFieldValues: this.processNode.customFieldValuesParsed
      });
      if (!result.customFieldValues) {
        delete result.customFieldValues;
      }
      return result;
    }
  }),
  mounted: function mounted() {
    this.getNodeById(this.processTreeId, this.processNodeId);
  },
  methods: {
    getNodeById: function getNodeById(treeId, nodeId) {
      var _this = this;
      return _getNodeById(treeId, nodeId).then(function (response) {
        if (response.status && response.status === 403) {
          if (_this.isPreview) {
            _this.$emit('accessError');
            return;
          }
          catch403(_this.$router, 'ComplianceProcessTree');
        }
        if (response.rootNodeId === undefined) {
          _this.$router.replace({
            name: 'ComplianceProcessTree'
          });
        }
        _this.processNode = response;
      }).catch(function () {
        if (_this.isPreview) {
          _this.$emit('accessError');
          return;
        }
        catch403(_this.$router, 'ComplianceProcessTree');
      });
    },
    processNodePropertyChanged: function processNodePropertyChanged(_ref) {
      var property = _ref.property,
        value = _ref.value;
      if (property === 'traffic_light') {
        this.processNode = _objectSpread(_objectSpread({}, this.processNode), {}, {
          trafficLight: value
        });
      } else {
        this.processNode = _objectSpread(_objectSpread({}, this.processNode), {}, _defineProperty({}, property, value));
      }
      this.setUpdateTimer();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.processNodePropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    saveUpdates: function saveUpdates() {
      var _this2 = this;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      updateNodeById(this.processTreeId, this.processNodeId, this.processNodeFormatted).then(function (response) {
        _this2.saveStatus = saveStatus.SAVED;
        // update the processNode if there were no new changes during the save time
        if (timerId === _this2.updateTimer) {
          _this2.processNode = response;
        }
        _this2.$emit('updated', _objectSpread({}, _this2.processNode));
        _this2.$emit('updateFromPreviewMode');
      }).catch(function () {
        _this2.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$processNode;
      updateFileLocally(fileId, (_this$processNode = this.processNode) === null || _this$processNode === void 0 ? void 0 : _this$processNode.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    getActivityData: function getActivityData() {
      var _this3 = this;
      this.getNodeById(this.processTreeId, this.processNodeId).then(function () {
        return _this3.saveStatus = saveStatus.SAVED;
      });
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    handleChangeReview: function handleChangeReview(event) {
      this.$emit('updated', _objectSpread({}, this.processNode));
      this.changeReview(event);
    }
  }
};