import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getNotifications() {
  return handleRequestResponse(Http.get('/admin/notifications'));
}
export function updateNotificationMessage(id, message) {
  return handleRequestResponse(Http.put("/admin/notifications/".concat(id), {
    message: message
  }));
}
export function getNotificationPreview(id) {
  return handleRequestResponse(Http.get("/admin/notifications_preview/".concat(id)));
}