function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import BadgesList from '@/components/Organisms/BadgesList';
import * as Utils from '@/utils/Utils';
import { checkERMByProjectId } from '@/utils/risks/check';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { Colors } from '@/Colors';
export default {
  name: 'LinksDepPreview',
  introduction: 'LinksDepPreview is a component for linkstab',
  description: 'LinksDepPreview is a component to show the module data',
  token: "<links-dep-preview\n        v-if=\"previewData !== null\"\n        :organisations=\"organisations\"\n        :users=\"users\"\n        :data=\"previewData\"\n        :scoring=\"scoring\" />",
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    RismaTitleWithIcon: RismaTitleWithIcon,
    PreviewModalLink: PreviewModalLink,
    BadgesList: BadgesList
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'data object'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all users'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all organisations'
    },
    scoring: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['updateFromPreview'],
  data: function data() {
    return {
      colors: Colors,
      translate: getTranslate['LinksDepPreview'](),
      updatedActivity: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    showStatus: function showStatus() {
      if (!this.data.objType || this.data.objType !== ObjectTypes.RISK_RISK) return true;
      return checkERMByProjectId(this.data.projectId, this.currentUser);
    },
    trafficLightColor: function trafficLightColor() {
      if (this.data.vatStatus !== undefined) {
        return this.getTrafficLightColorByObjectTypeAndNumber(this.data.objType, this.data.vatStatus);
      }
      if (this.data.isMothercontrol || this.data.trafficLight === undefined || this.data.trafficLight === null) {
        return '';
      }
      return this.getTrafficLightColorByObjectTypeAndNumber(this.data.objType, this.data.trafficLight);
    },
    severityColor: function severityColor() {
      if (this.data.severity === undefined) {
        return '';
      }
      return this.getTrafficLightColorByObjectTypeAndNumber(this.data.objType, this.data.severity);
    },
    organisationsAssigned: function organisationsAssigned() {
      return Utils.findPropsByIds(this.data.organisationIds, this.organisations, 'visible_name');
    },
    usersResponsible: function usersResponsible() {
      return Utils.findPropsByIds(this.data.responsibleIds, this.users, 'display_name');
    },
    usersReview: function usersReview() {
      return Utils.findPropsByIds(this.data.reviewIds, this.users, 'display_name');
    },
    likelyhoodScore: function likelyhoodScore() {
      if (!this.getSettingValue('risma.likelihood_percentage_disabled') && this.scoring.likelihood_values[this.data.likelihood]) {
        return this.scoring.likelihood_values[this.data.likelihood];
      }
      if (this.scoring.likelihood_labels && this.data.likelyhood > 0) {
        return this.scoring.likelihood_labels[this.data.likelyhood - 1].description;
      }
      return '';
    },
    impactLabel: function impactLabel() {
      if (this.data.impact) {
        var _this$scoring, _this$scoring2;
        return "".concat(((_this$scoring = this.scoring) === null || _this$scoring === void 0 ? void 0 : _this$scoring.impact_label) || '', " ").concat(this.data.impact, " ").concat(((_this$scoring2 = this.scoring) === null || _this$scoring2 === void 0 || (_this$scoring2 = _this$scoring2.impact_labels) === null || _this$scoring2 === void 0 ? void 0 : _this$scoring2[this.data.impact].value) || '');
      }
      return '';
    },
    lineThroughTitle: function lineThroughTitle() {
      if (this.data.active === undefined) {
        return true;
      }
      return this.data.active;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorForModule', 'getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    dismissPreview: function dismissPreview() {
      this.updatedActivity && this.$emit('updateFromPreview', this.updatedActivity);
      this.updatedActivity = null;
    }
  })
};