import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "date-time-picker"
};
var _hoisted_2 = {
  class: "flex mb-1"
};
var _hoisted_3 = {
  key: 0,
  class: "glob-l1 mt-1 text-rm-signal-red"
};
var _hoisted_4 = {
  key: 1,
  class: "glob-l1 mt-1 text-rm-signal-grey-dark"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_target_icon = _resolveComponent("target-icon");
  var _component_date_picker = _resolveComponent("date-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    class: "cursor-pointer",
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return $data.open = !$data.open;
    }, ["stop"]))
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $props.helpToolTip ? (_openBlock(), _createBlock(_component_help_tool_tip, {
    key: 1,
    class: "ml-2 self-center"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.helpToolTip), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createVNode(_component_date_picker, {
    ref: "datePicker",
    value: $data.dateValue,
    "onUpdate:value": _cache[2] || (_cache[2] = function ($event) {
      return $data.dateValue = $event;
    }),
    open: $data.open,
    "onUpdate:open": _cache[3] || (_cache[3] = function ($event) {
      return $data.open = $event;
    }),
    "value-type": "format",
    class: _normalizeClass(['group border rounded-md outline outline-2', $options.calculatedBorderClasses, {
      'tabular-nums': $data.open,
      open: $data.open
    }]),
    "popup-class": "border border-rm-grey",
    "default-value": $options.options.defaultDate,
    format: $options.options.format,
    lang: $options.options.locale,
    clearable: $props.deleteEnabled,
    disabled: $props.disabled,
    type: $options.datePickerType,
    "show-time-panel": $data.showTimePanel,
    "append-to-body": false,
    "popup-style": $options.popupStyle,
    "input-attr": {
      tabindex: $props.tabindex
    },
    "disabled-date": $options.disabledDateFunc,
    placeholder: $options.options.format,
    onChange: $options.handleChange,
    onClose: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('close');
    })
  }, {
    "icon-calendar": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "calendar",
        width: "18",
        height: "18",
        class: _normalizeClass(['mx-2 stroke-2', {
          'text-rm-text': $data.open,
          'group-hover:text-rm-text rm-icon active:!text-rm-active-blue': !$props.disabled
        }]),
        onClick: $options.onCalendarClick
      }, null, 8 /* PROPS */, ["class", "onClick"])];
    }),
    "icon-clear": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        tabindex: "0",
        icon: "x",
        width: "18",
        height: "18",
        class: "mx-2 stroke-2 rm-icon focus:text-rm-text focus:bg-rm-grey-light",
        onKeydown: _withKeys($options.resetDate, ["enter"])
      }, null, 8 /* PROPS */, ["onKeydown"])];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", {
        class: _normalizeClass(["date-picker-footer flex", $options.footerClass])
      }, [$options.options.showTodayButton ? (_openBlock(), _createBlock(_component_target_icon, {
        key: 0,
        class: "mx-2 rm-icon",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $options.setToday();
        })
      })) : _createCommentVNode("v-if", true), $options.isTimeType ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: $data.showTimePanel ? 'calendar' : 'clock',
        class: "mx-2 rm-icon",
        onClick: $options.toggleTimePanel
      }, null, 8 /* PROPS */, ["icon", "onClick"])) : _createCommentVNode("v-if", true), $options.options.showClear ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 2,
        icon: "trash-2",
        class: "mx-2 rm-icon",
        title: $data.translate.resetSelection,
        onClick: $options.resetDate
      }, null, 8 /* PROPS */, ["title", "onClick"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["value", "open", "class", "default-value", "format", "lang", "clearable", "disabled", "type", "show-time-panel", "popup-style", "input-attr", "disabled-date", "placeholder", "onChange"]), $props.errorText ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.errorText), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $props.helpText ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.helpText), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
}