import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "excel-label flex text-blue-750"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "relative pb-1",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.confirmExport && $options.confirmExport.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_feather_icon, {
      icon: "download"
    }), _cache[1] || (_cache[1] = _createElementVNode("span", {
      class: "excel-text"
    }, "Excel", -1 /* HOISTED */))])];
  }, true)])]);
}