// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dates-title[data-v-31ce868c] {
  font-size: 16px;
  font-weight: 300;
  color: #4D4D4D;
}
.dates-wrap-start[data-v-31ce868c]::after {
  content: "";
  width: 15px;
  height: 2px;
  border-bottom: 2px dotted #000000;
}
.dates-wrap-deadline[data-v-31ce868c]::before {
  content: "";
  width: 15px;
  height: 2px;
  border-bottom: 2px dotted #000000;
  margin-right: 0.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
