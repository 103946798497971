import RiskHeatMapSelector from '@/components/Molecules/Risk/RiskHeatMapSelector';
export default {
  name: '',
  components: {
    RiskHeatMapSelector: RiskHeatMapSelector
  },
  data: function data() {
    return {
      numberOfRows: 5,
      numberOfColumns: 4,
      heatMapColors: {
        1: '#43A63C',
        2: '#61C256',
        3: '#7DDE70',
        4: '#EFF25F',
        5: '#61C256',
        6: '#7DDE70',
        7: '#EFF25F',
        8: '#ECEC37',
        9: '#7DDE70',
        10: '#EFF25F',
        11: '#ECEC37',
        12: '#FDDA25',
        13: '#EFF25F',
        14: '#ECEC37',
        15: '#FDDA25',
        16: '#FD9125',
        17: '#ECEC37',
        18: '#FDDA25',
        19: '#FD9125',
        20: '#FF6B00'
      }
    };
  },
  computed: {
    dataset: function dataset() {
      var rows = [];
      for (var row = this.numberOfRows; row > 0; row--) {
        var cells = [];
        for (var col = 1; col <= this.numberOfColumns; col++) {
          var value = (row - 1) * this.numberOfColumns + col;
          cells.push({
            backgroundColor: this.heatMapColors[value],
            value: value
          });
        }
        rows.push(cells);
      }
      return rows;
    }
  },
  methods: {
    setColor: function setColor(value, color) {
      this.heatMapColors[value] = color;
    }
  }
};