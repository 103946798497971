import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  ref: "resizeBox",
  class: "w-full flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "overflow-auto",
    style: _normalizeStyle("width: ".concat($setup.leftColumnWidth, "px"))
  }, [_renderSlot(_ctx.$slots, "leftColumn")], 4 /* STYLE */), _createElementVNode("div", {
    class: "resizer cursor-col-resize w-1 h-full mr-10 bg-gray-450 hover:bg-gray-600",
    onMousedown: _cache[0] || (_cache[0] = function () {
      return $options.initialiseResize && $options.initialiseResize.apply($options, arguments);
    })
  }, null, 32 /* NEED_HYDRATION */), _createElementVNode("div", {
    class: "overflow-auto",
    style: _normalizeStyle("width: calc(100% - ".concat($setup.leftColumnWidth, "px)"))
  }, [_renderSlot(_ctx.$slots, "rightColumn")], 4 /* STYLE */)], 512 /* NEED_PATCH */);
}