export default {
  name: 'ToolTip',
  introduction: 'a tooltip for help, or info',
  description: "The tool-tip component has a slot to input text, or html to be displayed in it.\n        It also has an option prop to hide it on demand.\n        Remember to wrap it in a position:relative div to make it stay in place.",
  token: '<tool-tip :hidden="hidden">isn\'t testing wonderful?!</tool-tip>',
  props: {
    hidden: {
      type: Boolean,
      default: false,
      note: 'bool to determine if the tooltip, should be hidden'
    },
    arrowDirection: {
      type: String,
      required: false,
      default: 'left',
      note: 'determine tooltip`s arrow direction',
      validator: function validator(val) {
        return ['left', 'top', 'right', 'bottom'].includes(val);
      }
    },
    tooltipClasses: {
      type: String,
      required: false,
      default: '',
      note: 'additional classes for tooltip'
    }
  }
};