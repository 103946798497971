import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import StaticTable from '@/components/Molecules/StaticTable';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { escapeHtml } from '@/utils';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
export default {
  name: 'AddContactPersonModal',
  description: 'Modal for adding contact person',
  token: '<add-contact-person-modal users="users" />',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    StaticTable: StaticTable,
    NoSearchResults: NoSearchResults,
    Checkboxes: Checkboxes
  },
  props: {
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of users to choose from'
    },
    companies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of companies'
    }
  },
  emits: ['add', 'dismiss'],
  data: function data() {
    return {
      StaticTableThemes: StaticTableThemes,
      columnsStyles: {
        checkbox: 'width: 50px'
      },
      translate: getTranslate['AddContactPersonModal']()
    };
  },
  computed: {
    datasetTable: function datasetTable() {
      return this.users.map(function (user, index) {
        var _escapeHtml, _escapeHtml2;
        return {
          name: escapeHtml(user.name),
          title: (_escapeHtml = escapeHtml(user.title)) !== null && _escapeHtml !== void 0 ? _escapeHtml : '',
          phone: (_escapeHtml2 = escapeHtml(user.phone)) !== null && _escapeHtml2 !== void 0 ? _escapeHtml2 : '',
          checkbox: {
            index: index,
            value: [],
            id: user.id
          }
        };
      });
    },
    columns: function columns() {
      return [this.translate.name, this.translate.title, this.translate.phone, this.translate.choose];
    }
  },
  methods: {
    addUser: function addUser() {
      var ids = this.datasetTable.reduce(function (acc, item) {
        if (item.checkbox.value.length) acc.push(item.checkbox.id);
        return acc;
      }, []);
      if (ids.length) {
        this.$emit('add', ids);
      }
    },
    clickRow: function clickRow(row) {
      var userId = this.users[row].id;
      this.addUser(userId);
    },
    addContact: function addContact(index, value) {
      if (this.datasetTable[index].checkbox) {
        this.datasetTable[index].checkbox.value = value;
      }
    }
  }
};