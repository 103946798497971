var _excluded = ["total"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useComplianceOverview } from '@/Store/complianceOverview';
import GapSummaryTable from '@/components/Organisms/Compliance/GapSummaryTable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'ComplianceGapSummary',
  components: {
    GapSummaryTable: GapSummaryTable,
    RismaTitle: RismaTitle
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      translate: {
        headline: this.$trans('Gap summary')
      },
      columns: [this.$trans('Title'), this.$trans('Total no. of gaps')],
      nodeWasChanged: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useComplianceOverview, ['gapOverview'])), {}, {
    dataset: function dataset() {
      return this.prepareDataset(this.gapOverview);
    }
  }),
  beforeMount: function beforeMount() {
    this.init();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceOverview, ['fetchOverviewDashboard'])), {}, {
    init: function init() {
      var _this = this;
      this.fetchOverviewDashboard(this.projectId).finally(function () {
        return _this.isLoaded = true;
      });
    },
    prepareDataset: function prepareDataset(gapOverview) {
      var _this2 = this;
      return gapOverview.map(function (item) {
        var _item$gapSummary = item.gapSummary,
          total = _item$gapSummary.total,
          gaps = _objectWithoutProperties(_item$gapSummary, _excluded);
        return {
          node: {
            title: item.title,
            activity: {
              objType: ObjectTypes.COMPLIANCE_NODE,
              id: item.id,
              compliance_projects_id: +_this2.projectId,
              url: item.url
            }
          },
          total: total,
          gaps: Object.values(gaps)
        };
      });
    },
    onPreviewModalDismiss: function onPreviewModalDismiss() {
      if (this.nodeWasChanged) {
        this.init();
        this.nodeWasChanged = false;
      }
    },
    updateFromPreviewModalLink: function updateFromPreviewModalLink() {
      this.nodeWasChanged = true;
    }
  })
};