// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-148fbee7]  .matrix-inner {
  left: 0;
  padding-left: 116px;
}
[data-v-148fbee7]  .horizontal {
  position: relative;
  left: 116px;
  width: -moz-fit-content;
  width: fit-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
