import i18n from '@/i18n';
export var getTranslate = {
  GovernanceReviewModal: function GovernanceReviewModal() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      frequency: i18n.t('Frequency'),
      deadline: i18n.t('Deadline'),
      deadlinePreview: i18n.t('Deadline preview'),
      responsible: i18n.t('Responsible'),
      escalatingTo: i18n.t('Escalating to'),
      reviewer: i18n.t('Reviewer'),
      reviewDeadline: i18n.t('Review deadline'),
      inOrderToSetReviewDeadlineYouHaveToSelec: i18n.t('In order to set review deadline, you have to select a reviewer. A reviewer is the person who has to approve the control that the responsible performs.'),
      organisation: i18n.t('Organisation'),
      tags: i18n.t('Tags'),
      areYouSure: i18n.t('Are you sure?'),
      ifYouDeleteThisDraftTheEnteredDataWillNo: i18n.t('If you delete this draft, the entered data will not be saved.'),
      reviewDeadlineCantBeBeforeTheControlDeadl: i18n.t("Review deadline can't be before the control deadline. Please correct this before saving")
    };
  },
  GovernanceReviewSection: function GovernanceReviewSection() {
    return {
      governanceReview: i18n.t('Governance review'),
      title: i18n.t('Title'),
      completedBy: i18n.t('Completed by'),
      lastCompletion: i18n.t('Last completion'),
      nextDeadline: i18n.t('Next deadline')
    };
  }
};