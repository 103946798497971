import i18n from '@/i18n';
export var getTranslate = {
  RismaTableColumnMenu: function RismaTableColumnMenu() {
    return {
      sortDescending: i18n.t('Sort descending'),
      sortAscending: i18n.t('Sort ascending'),
      filterBy: i18n.t('Filter by'),
      deselectAll: i18n.t('Deselect all'),
      loadMore: i18n.t('Load more'),
      loadAll: i18n.t('Load all')
    };
  },
  RismaTableHeader: function RismaTableHeader() {
    return {
      savedReports: i18n.t('Saved reports'),
      saveCurrentReport: i18n.t('Save current report'),
      showDeactivatedControls: i18n.t('Show deactivated controls'),
      print: i18n.t('Print'),
      columnVisibility: i18n.t('Column visibility'),
      selectdeselectAll: i18n.t('Select/deselect all'),
      resetFormatting: i18n.t('Reset formatting'),
      search: i18n.t('Search')
    };
  },
  RismaTableCell: function RismaTableCell() {
    return {
      yes: i18n.t('Yes'),
      no: i18n.t('No')
    };
  },
  RismaTableReport: function RismaTableReport() {
    return {
      reportCreated: i18n.t('Report created'),
      saveCurrentReport: i18n.t('Save current report'),
      savedReports: i18n.t('Saved reports'),
      reportShared: i18n.t('Report shared'),
      theReportWasSuccessfullyDeleted: i18n.t('The report was successfully deleted')
    };
  }
};