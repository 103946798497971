import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1,
  class: "flex mb-2"
};
var _hoisted_3 = {
  class: "w-1/3"
};
var _hoisted_4 = {
  class: "w-1/3 pl-2"
};
var _hoisted_5 = {
  class: "w-1/3 pl-5"
};
var _hoisted_6 = ["title"];
var _hoisted_7 = ["title", "onClick"];
var _hoisted_8 = {
  key: 0,
  class: "w-1/3 flex items-center pl-5"
};
var _hoisted_9 = ["title", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.typesByQuestion, function (questionData, questionNumber) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: questionNumber
    }, [!$props.extendedWithStoragePeriod ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
      type: "medium",
      title: $data.translate.chooseLegalBasisBehindDataTypes
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
      type: "small",
      title: "".concat($data.translate.question, " ").concat(questionNumber)
    }, null, 8 /* PROPS */, ["title"])])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
      type: "medium",
      title: $data.translate.dataType
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
      type: "small",
      title: "".concat($data.translate.question, " ").concat(questionNumber)
    }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
      type: "medium",
      title: $data.translate.legalBasis
    }, null, 8 /* PROPS */, ["title"]), questionData.legalBasisQuestionNumber ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      type: "small",
      title: "".concat($data.translate.question, " ").concat(questionData.legalBasisQuestionNumber)
    }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
      type: "medium",
      title: $data.translate.storagePeriod
    }, null, 8 /* PROPS */, ["title"]), questionData.storagePeriodQuestionNumber ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      type: "small",
      title: "".concat($data.translate.question, " ").concat(questionData.storagePeriodQuestionNumber)
    }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])])), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionData.dataTypes, function (item, index) {
      return _openBlock(), _createElementBlock("div", {
        key: "basis-table-row-".concat(index),
        class: "flex items-center mb-3"
      }, [_createElementVNode("p", {
        class: _normalizeClass(["text-base text-gray-750", $props.extendedWithStoragePeriod ? 'w-1/3' : 'w-1/2']),
        title: item.text
      }, _toDisplayString(item.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_6), _createElementVNode("div", {
        class: _normalizeClass(["flex items-center", $props.extendedWithStoragePeriod ? 'w-1/3 pr-3 pl-2' : '!w-1/2 !min-w-1/2'])
      }, [_createVNode(_component_single_select, {
        "model-value": $props.selectedOptions[$data.DataFlowFields.CURRENT_BASIS_SELECTED][item.uniqueId],
        options: questionData.legalBasisOptions,
        placeholder: $data.translate.chooseLegalBasis,
        "with-reset": true,
        class: "mr-4 min-w-0",
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.onOptionSelect(questionData.legalBasisOptions, $event, item.uniqueId, $data.DataFlowFields.CURRENT_BASIS_SELECTED);
        }
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder", "onUpdate:modelValue"]), _createElementVNode("span", {
        title: $data.translate.applyLegalBasisToAll,
        class: _normalizeClass($props.selectedOptions[$data.DataFlowFields.CURRENT_BASIS_SELECTED][item.uniqueId] ? 'cursor-pointer' : 'text-gray-350'),
        onClick: function onClick($event) {
          return $options.selectAll(questionData.legalBasisOptions, questionData.dataTypes, item.uniqueId, $data.DataFlowFields.CURRENT_BASIS_SELECTED);
        }
      }, [_createVNode(_component_feather_icon, {
        width: "30",
        height: "30",
        icon: "copy"
      })], 10 /* CLASS, PROPS */, _hoisted_7)], 2 /* CLASS */), $props.extendedWithStoragePeriod ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_single_select, {
        "model-value": $props.selectedOptions[$data.DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED][item.uniqueId],
        options: questionData.storagePeriodOptions,
        placeholder: $data.translate.chooseStoragePeriod,
        "with-reset": true,
        class: "mr-4 min-w-0",
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.onOptionSelect(questionData.storagePeriodOptions, $event, item.uniqueId, $data.DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED);
        }
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder", "onUpdate:modelValue"]), _createElementVNode("span", {
        title: $data.translate.applyStoragePeriodToAll,
        class: _normalizeClass($props.selectedOptions[$data.DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED][item.uniqueId] ? 'cursor-pointer' : 'text-gray-350'),
        onClick: function onClick($event) {
          return $options.selectAll(questionData.storagePeriodOptions, questionData.dataTypes, item.uniqueId, $data.DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED);
        }
      }, [_createVNode(_component_feather_icon, {
        width: "30",
        height: "30",
        icon: "copy"
      })], 10 /* CLASS, PROPS */, _hoisted_9)])) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))]);
}