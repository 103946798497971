export var exportTypes = {
  PDF: 'PDF',
  PNG: 'PNG',
  JPEG: 'JPG',
  XLSX: 'XLSX',
  JSON: 'JSON',
  PRINT: 'PRINT',
  CSV: 'CSV'
};
export var getExportFn = function getExportFn(canvas) {
  return function (type) {
    if (type === exportTypes.PDF) {
      var padding = 10;
      var w = canvas.width;
      var h = canvas.height;
      var orientation = w > h ? 'l' : 'p';
      import(/*webpackChunkName: 'jsPDF'*/'jspdf').then(function (_ref) {
        var jsPDF = _ref.jsPDF;
        var pdf = new jsPDF(orientation, 'px', [w, h]);
        pdf.addImage(canvas, 'PNG', padding, padding, w - padding * 2, h - padding * 2);
        pdf.save('Risma.pdf');
      });
    }
    if (type === exportTypes.PNG || type === exportTypes.JPEG) {
      var image = canvas.toDataURL('image/' + type, 1);
      var a = document.createElement('a');
      a.download = 'Risma.' + type;
      a.href = image;
      a.click();
    }
  };
};