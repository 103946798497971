function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { sendAppInvitation } from '@/api/risma/userSettings';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { sortAlphabetic } from '@/utils/sort';
import { toLocalDateTime } from '@/utils/date';
export default {
  name: 'AppInvitations',
  components: {
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    RismaButton: RismaButton,
    RismaToggle: RismaToggle
  },
  props: {
    app: {
      type: String,
      require: true,
      default: null
    }
  },
  data: function data() {
    return {
      tableThema: StaticTableThemes.zebra,
      invitedOnly: false,
      translate: getTranslate['AppInvitations']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    headline: function headline() {
      return {
        incident: this.translate.incidentAppInvitations,
        controls: this.translate.controlsAppInvitations
      };
    },
    columns: function columns() {
      return [this.translate.username, this.translate.fullName, this.translate.email, this.translate.status, this.translate.invitationDate, ''];
    },
    dataset: function dataset() {
      var _this = this;
      return this.filteredUsers.map(function (user) {
        return {
          username: user.username,
          fullname: user.fullname,
          email: user.email,
          status: _this.getInvitationStatus(user),
          invited: _this.getInvitationDate(user),
          sendInvitation: {
            userId: user.id,
            disabled: false
          }
        };
      });
    },
    filteredUsers: function filteredUsers() {
      var _this2 = this;
      if (!this.invitedOnly) {
        return this.usersInAlphabeticalOrder;
      }
      return this.usersInAlphabeticalOrder.filter(function (user) {
        var status = _this2.getInvitationStatus(user);
        return status === _this2.translate.invited;
      });
    },
    usersInAlphabeticalOrder: function usersInAlphabeticalOrder() {
      return sortAlphabetic(this.users, 'display_name');
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    sendInvitation: function sendInvitation(slotData) {
      var _this3 = this;
      slotData.disabled = true;
      return sendAppInvitation(slotData.userId, {
        app: this.app
      }).then(function () {
        return _this3.loadUsers();
      }).finally(function () {
        return slotData.disabled = false;
      });
    },
    getInvitationStatus: function getInvitationStatus(user) {
      var invitationsByApp = this.getInvitationsByApp(user);
      if (invitationsByApp.length > 0) {
        return this.translate.invited;
      }
      return this.translate.notInvited;
    },
    getInvitationDate: function getInvitationDate(user) {
      var invitationsByApp = this.getInvitationsByApp(user);
      if (invitationsByApp.length > 0) {
        return toLocalDateTime(invitationsByApp.pop().created_at);
      }
      return this.translate.notInvited;
    },
    getInvitationsByApp: function getInvitationsByApp(user) {
      var _this4 = this;
      return user === null || user === void 0 ? void 0 : user.appInvitations.filter(function (item) {
        return item.app === _this4.app;
      });
    },
    toggleInvitedFilter: function toggleInvitedFilter(event) {
      this.invitedOnly = event.value;
    }
  })
};