function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { getTranslate } from './translate';
import GanttChartJs from '@/components/Molecules/Charts/GanttChartJs';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { Colors } from '@/Colors';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'GanttChartActions',
  description: 'This component takes a list of actions and displays them as a gantt chart, there is also a sorting',
  components: {
    GanttChartJs: GanttChartJs,
    PreviewModalLink: PreviewModalLink,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  props: {
    activities: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    isSortingVisible: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Display sorting or not'
    },
    localStorageKey: {
      required: false,
      type: String,
      default: '',
      note: 'A string with that name of the cookie which the chart should save and load its sort state from'
    }
  },
  emits: ['dismissModal', 'updateFromPreviewMode'],
  setup: function setup(props) {
    return {
      sortKey: getLocalStorageComputed(props.localStorageKey, '', false)
    };
  },
  data: function data() {
    return {
      selectedIndex: null,
      translate: getTranslate['GanttChartActions']()
    };
  },
  computed: {
    sortKeys: function sortKeys() {
      return [{
        value: 'path',
        label: this.translate.number
      }, {
        value: 'deadline',
        label: this.translate.deadline
      }, {
        value: 'title',
        label: this.translate.title
      }];
    },
    selectedActivity: function selectedActivity() {
      return this.activitiesSorted[this.selectedIndex] || {};
    },
    chartData: function chartData() {
      var _this = this;
      var labels = [];
      var datasets = {
        data: [],
        backgroundColor: [],
        barThickness: 25
      };
      this.activitiesSorted.forEach(function (item) {
        var label = "".concat(item.closed ? "\u2713" : '', " ").concat(item.path, " ").concat(item.title);
        labels.push(label);
        datasets.data.push([new Date(item.start), new Date(item.deadline)]);
        datasets.backgroundColor.push(_this.getBarColor(item));
      });
      return {
        labels: labels,
        data: [datasets]
      };
    },
    activitiesSorted: function activitiesSorted() {
      if (this.isSortingVisible) {
        return this.sortDataBySortKey(this.activities);
      }
      return this.activities;
    },
    ganttChartHeight: function ganttChartHeight() {
      var _this$chartData$label;
      var PADDING_TOP = 100;
      if ((_this$chartData$label = this.chartData.labels) !== null && _this$chartData$label !== void 0 && _this$chartData$label.length) {
        var _this$chartData$label2;
        return ((_this$chartData$label2 = this.chartData.labels) === null || _this$chartData$label2 === void 0 ? void 0 : _this$chartData$label2.length) * 40 + PADDING_TOP;
      }
      return 400;
    }
  },
  mounted: function mounted() {
    this.setSortKeyDefault();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightByObjectTypeAndNumber'])), {}, {
    setSortKeyDefault: function setSortKeyDefault() {
      var sortKeyId = this.sortKey;
      if (this.isSortingVisible) {
        var sortValue = this.sortKeys.find(function (item) {
          return item.value === sortKeyId;
        });
        if (sortValue) {
          this.sortKey = sortValue.value;
        } else {
          this.sortKey = this.sortKeys[0].value;
        }
      }
    },
    getBarColor: function getBarColor(item) {
      if (item.closed) return Colors.blueLight;
      return item.trafficLight !== undefined ? this.getTrafficLightByObjectTypeAndNumber(item.objType, item.trafficLight).color : Colors.noTrafficLight;
    },
    showPreviewModal: function showPreviewModal(index) {
      this.selectedIndex = index;
      this.$refs.preview.showModal();
    },
    sortDataBySortKey: function sortDataBySortKey(data) {
      var _this2 = this;
      return data.slice(0).sort(function (a, b) {
        return a[_this2.sortKey] < b[_this2.sortKey] ? -1 : a[_this2.sortKey] > b[_this2.sortKey] ? 1 : 0;
      });
    }
  })
};