import { getTranslate } from './translate';
import { getAll, updateProject as _updateProject } from '@/api/compliance/projects';
import { getTreeByProjectId } from '@/api/compliance/tree';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { formatNodes } from '@/utils/format/Compliance';
export default {
  name: 'ProjectsNodeTemplates',
  introduction: 'Section of the Compliance Setting page ',
  description: 'This component renders the table with node templates list',
  token: '<projects-node-templates />',
  components: {
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    SingleSelect: SingleSelect,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  data: function data() {
    return {
      isLoaded: false,
      projects: [],
      nodesOptionsHash: {},
      translate: getTranslate['ProjectsNodeTemplates']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.title, this.translate.enable, this.translate.selectTemplateNode];
    }
  },
  mounted: function mounted() {
    this.getAllData();
  },
  methods: {
    getAllData: function getAllData() {
      var _this = this;
      return getAll(true).then(function (response) {
        _this.projects = response.list;
        var requests = _this.projects.map(function (project) {
          return getTreeByProjectId(project.id);
        });
        return Promise.all(requests);
      }).then(function (response) {
        _this.projects.forEach(function (project, index) {
          var _response$index;
          var list = ((_response$index = response[index]) === null || _response$index === void 0 ? void 0 : _response$index.children) || [];
          var listFormatted = formatNodes(list, '', [], true);
          _this.nodesOptionsHash[project.id] = _this.createOptions(listFormatted);
        });
        _this.isLoaded = true;
      });
    },
    updateProject: function updateProject(projectId, templateId) {
      var _this2 = this;
      return _updateProject(projectId, {
        templateId: templateId
      }).then(function (response) {
        _this2.projects = _this2.projects.map(function (project) {
          return project.id === projectId ? response : project;
        });
        _this2.$notify({
          title: _this2.translate.templateNodeUpdated,
          type: 'success'
        });
      }).catch(function () {
        _this2.$notify({
          title: _this2.translate.somethingWentWrong,
          type: 'error'
        });
      });
    },
    createOptions: function createOptions() {
      var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return array.reduce(function (prev, curr) {
        var _curr$children;
        if (curr !== null && curr !== void 0 && (_curr$children = curr.children) !== null && _curr$children !== void 0 && _curr$children.length) {
          prev.push({
            value: curr.id,
            label: "".concat(curr.title, " > ").concat(curr === null || curr === void 0 ? void 0 : curr.children.map(function (item) {
              return item.title;
            }).join(' > '))
          });
        }
        return prev;
      }, []);
    },
    onEnableNodeTemplate: function onEnableNodeTemplate(value, projectId, templateId) {
      if (!templateId) return;
      templateId = value ? templateId : '';
      this.updateProject(projectId, templateId);
    },
    onChangeTemplateId: function onChangeTemplateId(templateId, projectId) {
      this.updateProject(projectId, templateId);
    }
  }
};