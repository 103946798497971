import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_2 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_7 = {
  class: "mb-3"
};
var _hoisted_8 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_9 = {
  key: 0,
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_text_box = _resolveComponent("text-box");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.article.id ? $data.translate.editArticle : $data.translate.addArticle,
    "show-buttons": false,
    onDismiss: $options.toggleModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", {
        class: _normalizeClass([{
          'input-error': $data.errors.article
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.formData.article,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.formData.article = $event;
        }),
        title: $data.translate.article,
        required: true,
        placeholder: $data.translate.typeHere,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.errors.article ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$trans($data.errors.article)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'input-error': $data.errors.articleTitle
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.formData.articleTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.formData.articleTitle = $event;
        }),
        title: $data.translate.articleTitle,
        required: true,
        placeholder: $data.translate.typeHere,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $data.errors.articleTitle ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$trans($data.errors.articleTitle)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.requirement,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
        class: _normalizeClass({
          'input-error': $data.errors.requirement
        })
      }, [_createVNode(_component_text_box, {
        text: $data.formData.requirement,
        rows: 5,
        placeholder: $data.translate.typeHere + '...',
        class: "w-full border-gray-450",
        onUpdated: _cache[2] || (_cache[2] = function ($event) {
          return $data.formData.requirement = $event;
        })
      }, null, 8 /* PROPS */, ["text", "placeholder"]), $data.errors.requirement ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$trans($data.errors.requirement)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.domain,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
        class: _normalizeClass({
          'input-error': $data.errors.domain
        })
      }, [_createVNode(_component_risma_select, {
        "selected-options": $options.domainSelectedOptions,
        multiple: false,
        options: $props.domains,
        taggable: true,
        "un-sorted": true,
        "selected-on-top": false,
        "close-on-select": true,
        placeholder: $data.translate.typeHere,
        "deselect-label": $data.translate.pressEnterToRemove,
        "select-label": $data.translate.selected,
        "selected-label": $data.translate.pressEnterToSelect,
        "track-by": "id",
        "label-key": "label",
        onTag: $options.addNewOption,
        "onUpdate:modelValue": $options.onSelectDomain
      }, null, 8 /* PROPS */, ["selected-options", "options", "placeholder", "deselect-label", "select-label", "selected-label", "onTag", "onUpdate:modelValue"]), $data.errors.domain ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$trans($data.errors.domain)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
        title: $data.translate.flags,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
        class: _normalizeClass({
          'input-error': $data.errors.flags
        })
      }, [_createVNode(_component_risma_select, {
        "selected-options": $options.selectedFlags,
        options: $options.flagOptions,
        multiple: true,
        "close-on-select": false,
        placeholder: $data.translate.typeHere,
        "deselect-label": $data.translate.pressEnterToRemove,
        "select-label": $data.translate.selected,
        "selected-on-top": false,
        "un-sorted": true,
        "selected-label": $data.translate.pressEnterToSelect,
        "track-by": "value",
        "label-key": "label",
        "onUpdate:modelValue": $options.onSelectFlags
      }, null, 8 /* PROPS */, ["selected-options", "options", "placeholder", "deselect-label", "select-label", "selected-label", "onUpdate:modelValue"]), $data.errors.flags ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$trans($data.errors.flags)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]), $data.attributesArr.length ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
        title: $data.translate.attributes,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.attributesArr, function (attribute) {
        return _openBlock(), _createElementBlock("div", {
          key: attribute.id,
          class: "mt-2"
        }, [_createVNode(_component_risma_select, {
          "label-key": "label",
          "track-by": "value",
          title: attribute.category,
          options: $data.labelOptions[attribute.id],
          "selected-options": $data.preselectedLabelOptions[attribute.id],
          onSelected: function onSelected($event) {
            return $options.onLabelsUpdate(attribute.id, $event);
          }
        }, null, 8 /* PROPS */, ["title", "options", "selected-options", "onSelected"])]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $options.hasOverallFramework ? (_openBlock(), _createBlock(_component_frameworks_selector, {
        key: 1,
        "selected-options": $options.selectedFrameworks,
        onSelected: $options.onFrameworksSelected
      }, null, 8 /* PROPS */, ["selected-options", "onSelected"])) : _createCommentVNode("v-if", true), _createVNode(_component_buttons_row, {
        class: "items-center justify-end mt-5",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"]);
}