import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_percentage_box = _resolveComponent("percentage-box");
  return _openBlock(), _createBlock(_component_percentage_box, {
    color: $data.color
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("5%")]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["color"]);
}