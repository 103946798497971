function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getCompanies, deleteCompany as _deleteCompany } from '@/api/admin/companies';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import CreateCompany from '@/components/Molecules/Companies/CreateCompany';
import CompanyWizardWrapper from '@/components/Organisms/Companies/CompanyWizardWrapper';
import { VAT_STATUSES } from '@/constants/companies/vatStatuses';
import { generateKey } from '@/utils/Utils';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { calcCompanyRights } from '@/menus/quicknav';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'Companies',
  components: {
    RismaTitle: RismaTitle,
    Notification: Notification,
    RismaTable: RismaTable,
    CreateCompany: CreateCompany,
    CompanyWizardWrapper: CompanyWizardWrapper
  },
  props: {
    isBankruptcy: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show only bankrupt companies. vatStatus 2 or 4'
    }
  },
  emits: ['updateBankruptcyCount'],
  data: function data() {
    return {
      bankruptcyInit: false,
      timer: 0,
      selectedCompanyId: null,
      selectedCompanyTitle: '',
      tableGeneratedKey: generateKey(),
      translate: getTranslate['Companies']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    companiesStateKey: function companiesStateKey() {
      return this.isBankruptcy ? 'companies_table_bankruptcy' : 'companies_table';
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'company');
    },
    tableHeader: function tableHeader() {
      return this.isBankruptcy ? this.translate.bankruptcyList : this.translate.companies;
    },
    companyWizardEnabled: function companyWizardEnabled() {
      return this.getSettingValue('feature.company.wizard');
    },
    isSuperUser: function isSuperUser() {
      return calcCompanyRights(this.currentUser, this.getAllSettings, UserLevels.SUPER);
    }
  }),
  watch: {
    isBankruptcy: function isBankruptcy() {
      this.bankruptcyInit = false;
    }
  },
  methods: {
    handleDeleteCompany: function handleDeleteCompany(_ref) {
      var _this = this;
      var id = _ref.id,
        title = _ref.title;
      this.selectedCompanyId = id;
      this.selectedCompanyTitle = title;
      this.$confirm(this.translate.areYouSure, "".concat(this.translate.deleteCompany, " ").concat(this.selectedCompanyTitle, "?"), function (res) {
        return res && _this.deleteCompany();
      }, {
        buttonOkText: this.translate.delete,
        buttonDismissText: this.translate.cancel
      });
    },
    showNotification: function showNotification(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.$notify({
        title: this.$trans(message),
        type: type
      });
    },
    getCompaniesBE: function getCompaniesBE(params) {
      var _this2 = this;
      var separator = params ? '&' : '';
      var filterParams = '';
      if (this.isBankruptcy && !this.bankruptcyInit) {
        filterParams = "".concat(separator, "filters[vat_status_as_text_translated][]=").concat(VAT_STATUSES.BANKRUPT.value, "\n                    &filters[vat_status_as_text_translated][]=").concat(VAT_STATUSES.UNDER_BANKRUPTCY.value);
        this.bankruptcyInit = true;
      }
      return getCompanies("".concat(params).concat(filterParams)).catch(function (error) {
        _this2.$notify({
          title: error.fullError.message,
          type: 'error'
        });
        throw error;
      });
    },
    deleteCompany: function deleteCompany() {
      var _this3 = this;
      _deleteCompany(this.selectedCompanyId).then(function (company) {
        _this3.refreshCompanyTable();
        _this3.updateBankruptcyCount(company, -1);
      }).catch(function (error) {
        _this3.$notify({
          title: error.fullError.message,
          type: 'error'
        });
      });
    },
    createCompany: function createCompany(company) {
      this.refreshCompanyTable();
      this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        activity: getFieldsFromObjectForPreview(company)
      });
      this.updateBankruptcyCount(company, 1);
    },
    updateBankruptcyCount: function updateBankruptcyCount(company, difference) {
      if (![VAT_STATUSES.BANKRUPT.value, VAT_STATUSES.UNDER_BANKRUPTCY.value].includes(company.vatStatus)) return;
      this.$emit('updateBankruptcyCount', difference);
    },
    refreshCompanyTable: function refreshCompanyTable() {
      this.tableGeneratedKey = generateKey();
    }
  }
};