import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "glob-l1 text-rm-text inline-block align-top"
};
var _hoisted_2 = {
  key: 0,
  class: "mr-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risk_indicator = _resolveComponent("risk-indicator");
  var _component_risk_row = _resolveComponent("risk-row");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [!$data.isEditMode ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "flex items-center",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
      return $props.readOnly ? undefined : $data.isEditMode = true;
    }, ["stop"]))
  }, [_createElementVNode("p", _hoisted_1, _toDisplayString($data.translate.riskBeforeafter), 1 /* TEXT */), _createElementVNode("div", {
    class: "ml-4 inline-block",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return $props.readOnly ? undefined : $data.isEditMode = true;
    }, ["stop"]))
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.risks, function (risk, index) {
    return _openBlock(), _createElementBlock("div", {
      key: risk.id,
      class: "inline-block"
    }, [_createVNode(_component_risk_indicator, {
      "risk-level": risk.rpi,
      class: _normalizeClass(['text-white inline-block w-8 py-0 rounded-sm mr-2 text-center cursor-pointer glob-l1', {
        'cursor-not-allowed': $props.readOnly
      }])
    }, null, 8 /* PROPS */, ["risk-level", "class"]), index !== $props.risks.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, "/")) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])])) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($props.risks, function (risk) {
    return _openBlock(), _createBlock(_component_risk_row, {
      key: risk.id,
      tense: $data.riskModuleTense[risk.riskIndex],
      impact: risk.impact,
      likelihood: risk.likelihood,
      "risk-level": risk.rpi,
      class: "inline-block",
      onImpactChange: function onImpactChange($event) {
        return $options.emitImpact(risk.riskIndex, $event);
      },
      onLikelihoodChange: function onLikelihoodChange($event) {
        return $options.emitLikelihood(risk.riskIndex, $event);
      }
    }, null, 8 /* PROPS */, ["tense", "impact", "likelihood", "risk-level", "onImpactChange", "onLikelihoodChange"]);
  }), 128 /* KEYED_FRAGMENT */))])), [[_directive_click_outside, $options.onClickOutside]]);
}