function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import * as Cookies from 'tiny-cookie';
import { userHasAccessToComplianceProjects } from '@/utils/access';
import { getControlReport } from '@/api/controls/controls';
import * as DataAPI from '@/api/risma/data';
import { getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getControlsReportFields, getDisabledFiltersByColumns, columnsMeta, fieldsByTypes, getDecoupleFilterFields, ControlObjectProps } from '@/constants/ControlsReportProps';
import { getAdditionalFilterOptions } from '@/constants/datatable/filters';
import { ControlCookies } from '@/constants/ControlCookies';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { UserLevels } from '@/constants/UserLevels';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { ControlReportsMixin } from '@/components/Pages/Controls/ControlReportsMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { pushChildrenToParent, getCustomFieldsColsFromActivityTypes } from '@/utils/Utils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ControlReports',
  introduction: 'Control Reports',
  description: 'This is Page component for /controls2/reports/main',
  token: '<control-reports></control-reports>',
  components: {
    RismaToggle: RismaToggle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaDatatable: RismaDatatable,
    SaveReport: SaveReport,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    RismaTitleWithIcon: RismaTitleWithIcon,
    SingleSelect: SingleSelect
  },
  mixins: [ControlReportsMixin, RismaDatatableDefaultMixin],
  setup: function setup() {
    return {
      selectedDateType: getLocalStorageComputed('controls2_reports_date_type', 'deadline')
    };
  },
  data: function data() {
    return {
      localStorageRangeKey: ControlLocalStorage.REPORTS_RANGE,
      stateLocal: ControlLocalStorage.REPORTS,
      toggleDeactivatedTimer: null,
      showDeactivated: false,
      columnsMeta: columnsMeta,
      isLoaded: false,
      allControls: [],
      initiatives: [],
      risks: [],
      processLibraryNodes: [],
      systems: [],
      iaDataProcessors: [],
      riskProjects: [],
      complianceProjects: [],
      reportType: 'controls-report',
      ranges: getRangesWithNextThreeMonths(),
      additionalFilterOptions: getAdditionalFilterOptions(),
      translate: getTranslate['ControlReports']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    disabledFilterByColumns: function disabledFilterByColumns() {
      var disableFilterForCustomFieldTypes = [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD, CustomFieldTypes.TRAFFIC_LIGHT];
      var result = getDisabledFiltersByColumns();
      result.push(ControlObjectProps.REJECTED_REVIEWS);
      Object.entries(this.customFields).forEach(function (field) {
        var _field = _slicedToArray(field, 2),
          key = _field[0],
          value = _field[1];
        if (disableFilterForCustomFieldTypes.includes(value.fieldType)) {
          result.push(key);
        }
      });
      if (this.efficiencyAssessmentEnabled) result.push(ControlObjectProps.COMPLETED_EFFICIENCY_COMMENT);
      return result;
    },
    reportFields: function reportFields() {
      var result = getControlsReportFields();
      if (this.getSettingValue('risma.organisational_groups') !== 1) {
        delete result[ControlObjectProps.ORGANISATION_GROUPS];
      }
      if (this.getSettingValue('risma.show_expected_evidence') !== 1) {
        delete result[ControlObjectProps.EXPECTED_EVIDENCE];
        delete result[ControlObjectProps.MOTHERCONTROL_EXPECTED_EVIDENCE];
      }
      if (!this.getSettingValue('risma.show_key_control')) {
        delete result[ControlObjectProps.KEY_CONTROL];
      }
      if (!this.getSettingValue('risma.show_automatic_manual')) {
        delete result[ControlObjectProps.KEY_AUTOMATIC_MANUAL];
      }
      if (!this.getSettingValue('risma.show_preventive_detective')) {
        delete result[ControlObjectProps.KEY_PREVENTIVE_DETECTIVE];
      }
      if (!this.getSettingValue('risma.show_high_complexity_low_complexity')) {
        delete result[ControlObjectProps.KEY_HIGH_LOW_COMPLEXITY];
      }
      if (!this.efficiencyAssessmentEnabled) {
        delete result[ControlObjectProps.COMPLETED_EFFICIENCY];
        delete result[ControlObjectProps.COMPLETED_EFFICIENCY_COMMENT];
      }
      return result;
    },
    customFields: function customFields() {
      return getCustomFieldsColsFromActivityTypes(this.activityTypes, this.customUnitPrefix);
    },
    complianceGapsFields: function complianceGapsFields() {
      var _this = this;
      if (!userHasAccessToComplianceProjects(this.currentUser, this.getSettingValue('app.module_compliance_enabled'))) return {};
      var result = {};
      var filteredProjects = this.currentUser.projects.compliance.filter(function (project) {
        return +project.access_level > UserLevels.NONE;
      });
      filteredProjects.forEach(function (project) {
        var title = _this.prepareComplianceTitle(project.id);
        if (title) {
          result["linkedCompliances-".concat(project.id)] = title;
        }
      });
      return result;
    },
    columns: function columns() {
      var _this2 = this;
      var translatedReportFields = {};
      var translatedCustomFields = {};
      Object.entries(this.reportFields).forEach(function (field) {
        var _field2 = _slicedToArray(field, 2),
          key = _field2[0],
          value = _field2[1];
        translatedReportFields[key] = _this2.$trans(value);
      });
      Object.entries(this.customFields).forEach(function (field) {
        var _field3 = _slicedToArray(field, 2),
          key = _field3[0],
          value = _field3[1];
        translatedCustomFields[key] = _this2.$trans(value.label);
      });
      return _objectSpread(_objectSpread(_objectSpread({}, translatedReportFields), translatedCustomFields), this.complianceGapsFields);
    },
    linkedColumnsForPreview: function linkedColumnsForPreview() {
      var asLinksList = fieldsByTypes.asLinksList;
      var complianceGapsKeys = Object.keys(this.complianceGapsFields);
      return [].concat(_toConsumableArray(asLinksList), complianceGapsKeys);
    },
    decoupleFilterFields: function decoupleFilterFields() {
      var complianceGapsFilters = Object.keys(this.complianceGapsFields).map(function (key) {
        return {
          key: key
        };
      });
      return [].concat(_toConsumableArray(getDecoupleFilterFields()), _toConsumableArray(complianceGapsFilters));
    },
    dateTypeOptions: function dateTypeOptions() {
      var _this3 = this;
      var dateTypeMapping = {
        deadline: 'Deadline date',
        completed: 'Completion date'
      };
      return Object.entries(dateTypeMapping).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          label = _ref2[1];
        return {
          value: value,
          label: _this3.$trans(label)
        };
      });
    },
    efficiencyAssessmentEnabled: function efficiencyAssessmentEnabled() {
      return !!this.getSettingValue('feature.controls_efficiency_enabled');
    }
  }),
  watch: {
    selectedDateType: function selectedDateType() {
      this.refreshTable();
    }
  },
  mounted: function mounted() {
    this.getDeactivatedState();
    this.initDateRange();
    Promise.all([this.getRiskProjects(), this.getRisksWithScores(), this.getControls(), this.getInitiatives(), this.getNodeElementAllData(), this.getInformationAssetsSystems(), this.getInformationAssetsProcessors(), this.getComplianceProjects(), this.getData()]).then(this.loadDataFromQuery).catch(function () {});
  },
  methods: {
    refreshTable: function refreshTable() {
      this.getDateRange();
      return this.getData();
    },
    getData: function getData() {
      var _this4 = this;
      this.isLoaded = false;
      var dateOptions = {
        dateAfter: this.deadlineAfter,
        dateBefore: this.deadlineBefore,
        dateType: this.selectedDateType
      };
      var SortByCpath = true;
      return getControlReport(dateOptions, this.showDeactivated, SortByCpath).then(function (list) {
        _this4.dataset = _this4.prepareDataset(list);
        _this4.enhanceDataset(_this4.dataset, _this4.columns);
      }).catch(function () {}).finally(function () {
        _this4.isLoaded = true;
      });
    },
    getControls: function getControls() {
      var _this5 = this;
      return DataAPI.getControls().then(function (_ref3) {
        var list = _ref3.list;
        return _this5.allControls = list;
      });
    },
    getInitiatives: function getInitiatives() {
      var _this6 = this;
      return DataAPI.getInitiatives().then(function (_ref4) {
        var list = _ref4.list;
        return _this6.initiatives = list;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      var _this7 = this;
      return DataAPI.getAllTrees().then(function (response) {
        return _this7.setIdToProccessTreeIdAndGetNodes(response);
      });
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this8 = this;
      var processTreeId = null;
      if (response[0].id !== null) {
        processTreeId = response[0].id;
      } else {
        return [];
      }
      return DataAPI.getNodesByProcessTreeId(processTreeId).then(function (response) {
        var result = [];
        pushChildrenToParent(response, result);
        _this8.processLibraryNodes = result.map(function (node) {
          return _objectSpread(_objectSpread({}, node), {}, {
            processTreeId: processTreeId
          });
        });
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this9 = this;
      return _getRiskProjects().then(function (_ref5) {
        var list = _ref5.list;
        return _this9.riskProjects = list;
      });
    },
    getRisksWithScores: function getRisksWithScores() {
      var _this10 = this;
      return DataAPI.getRisks('?data[]=scores').then(function (_ref6) {
        var list = _ref6.list;
        return _this10.risks = list;
      });
    },
    getInformationAssetsSystems: function getInformationAssetsSystems() {
      var _this11 = this;
      return DataAPI.getInformationAssetsSystems().then(function (_ref7) {
        var list = _ref7.list;
        return _this11.systems = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this12 = this;
      return DataAPI.getInformationAssetsProcessors().then(function (_ref8) {
        var list = _ref8.list;
        return _this12.iaDataProcessors = list;
      });
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this13 = this;
      return DataAPI.getComplianceProjects().then(function (_ref9) {
        var list = _ref9.list;
        return _this13.complianceProjects = list;
      });
    },
    prepareDataset: function prepareDataset(controls) {
      var _this14 = this;
      var result = [];
      controls.forEach(function (control) {
        var baseControl = _this14.prepareItemBaseFields(control);
        var customFields = _this14.prepareItemCustomFields(control);
        var gapsFields = _this14.prepareItemGapsFields(control);
        result.push(_objectSpread(_objectSpread(_objectSpread({}, baseControl), customFields), gapsFields));
      });
      return result;
    },
    getDeactivatedState: function getDeactivatedState() {
      var cookieVal = Cookies.get(ControlCookies.REPORTS_DEACTIVATED);
      if (cookieVal) {
        try {
          this.showDeactivated = JSON.parse(cookieVal);
        } catch (e) {
          // nothing
        }
      }
    },
    toggleDeactivated: function toggleDeactivated() {
      var _this15 = this;
      Cookies.set(ControlCookies.REPORTS_DEACTIVATED, this.showDeactivated, {
        samesite: 'strict'
      });
      clearTimeout(this.toggleDeactivatedTimer);
      this.toggleDeactivatedTimer = setTimeout(function () {
        _this15.refreshTable();
      }, 300);
    },
    prepareComplianceTitle: function prepareComplianceTitle(id) {
      var complianceProject = this.complianceProjects.find(function (item) {
        return +item.id === +id;
      });
      var result = null;
      if (complianceProject && complianceProject.title) {
        result = "".concat(complianceProject.title, " ").concat(this.translate.gaps);
      }
      return result;
    }
  }
};