import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
var _hoisted_1 = {
  ref: "selector",
  class: "rights-picker cursor-pointer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
    class: _normalizeClass([{
      'forbidden cursor-not-allowed': $options.isLockedClick
    }, {
      'opacity-50': !$data.hideModal
    }]),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleModal && $options.toggleModal.apply($options, arguments);
    })
  }, [_createElementVNode("span", {
    class: _normalizeClass([[$options.currentLevelIcon, $options.supervisorYellowIcon], "level"])
  }, null, 2 /* CLASS */), $data.project.locked && $options.isLockedPossible ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "lock",
    width: "18",
    height: "18",
    "fill-class": "fill-blueMain",
    class: "text-white absolute top-1/2-4 left-4"
  })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass([{
      hidden: $data.hideModal
    }, "access_modal"])
  }, [!$options.isAzureUser ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.setLevel($data.UserLevels.NONE, 0, $data.isLocked);
    })
  }, [_cache[9] || (_cache[9] = _createElementVNode("span", {
    class: "icon-risma-noAccess modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.noAccess), 1 /* TEXT */)]), _createElementVNode("div", {
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.setLevel($data.UserLevels.NORMAL, 0, $data.isLocked);
    })
  }, [_cache[10] || (_cache[10] = _createElementVNode("span", {
    class: "icon-risma-user modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.normal), 1 /* TEXT */)]), _createElementVNode("div", {
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.setLevel($data.UserLevels.PRIVILEGED, 0, $data.isLocked);
    })
  }, [_cache[11] || (_cache[11] = _createElementVNode("span", {
    class: "icon-risma-privUser modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.privileged), 1 /* TEXT */)]), _createElementVNode("div", {
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.setLevel($data.UserLevels.SUPER, 0, $data.isLocked);
    })
  }, [_cache[12] || (_cache[12] = _createElementVNode("span", {
    class: "icon-risma-superUser modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.super), 1 /* TEXT */)])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), $options.supervisorFeatureEnabled && $options.isSuperUser ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.setLevel($data.UserLevels.SUPER, $data.project.supervisor ? 0 : 1);
    })
  }, [_cache[13] || (_cache[13] = _createElementVNode("span", {
    class: "icon-risma-superUser icon-risma-superUser-yellow modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($options.supervisorText), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $options.allowAdmin ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.setLevel(4);
    })
  }, [_cache[14] || (_cache[14] = _createElementVNode("span", {
    class: "icon-risma-admin modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.admin), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $options.isLockedPossible ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "locked-item",
    onClick: _cache[8] || (_cache[8] = function ($event) {
      return $options.setLevel(undefined, 0, !$data.isLocked);
    })
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.isLocked = $event;
    }),
    class: "locked-item",
    type: "checkbox"
  }, null, 512 /* NEED_PATCH */), [[_vModelCheckbox, $data.isLocked]]), _createTextVNode(" " + _toDisplayString($data.translate.locked), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])), [[_directive_click_outside, $options.closeModal]]);
}