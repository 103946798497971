function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "container-full margin-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_required_fields_notification = _resolveComponent("required-fields-notification");
  var _component_status_transition = _resolveComponent("status-transition");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_multiple_questionnaire_selector = _resolveComponent("multiple-questionnaire-selector");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.isUserLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.isLoaded ? (_openBlock(), _createBlock(_component_required_fields_notification, {
    key: 1,
    "missing-required-fields": $options.missingRequiredFields
  }, null, 8 /* PROPS */, ["missing-required-fields"])) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $options.isLoaded,
    "dep-header-props": $options.depHeaderProps,
    item: $data.incident,
    "read-only": $options.readOnly,
    "is-user-locked": $options.isUserLocked,
    "dep-relations-tabs": $options.depRelationsTabs,
    "local-storage-key": "incident_dep_relations",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.incidentPropertyChanged,
    onSaveStatusClicked: $options.saveStatusClicked,
    onGetActivityData: $options.getActivityData,
    onChangeTab: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    "onUpdate:isPageWithCustomUpdateDirty": $options.handleUpdateForCustomPageUpdate
  }, _createSlots({
    delegation: _withCtx(function () {
      return [_createVNode(_component_delegation_well, {
        "model-value": _objectSpread(_objectSpread({}, $data.incident), {}, {
          module: $data.module
        }),
        organisations: _ctx.organisations,
        "read-only": $options.readOnly,
        "current-activity-type": $options.currentActivityType,
        onPropertyChanged: $options.saveChangesImmediately
      }, null, 8 /* PROPS */, ["model-value", "organisations", "read-only", "current-activity-type", "onPropertyChanged"])];
    }),
    details: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.activityType
      }, null, 8 /* PROPS */, ["title"]), $data.incident && _ctx.activityTypes.length ? (_openBlock(), _createBlock(_component_activity_type_selector, {
        key: 0,
        modelValue: $data.incident.activityTypeId,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.incident.activityTypeId = $event;
        }), _cache[1] || (_cache[1] = function ($event) {
          return $options.incidentPropertyChanged({
            property: 'activityTypeId',
            value: $event
          });
        })],
        "activity-types": _ctx.activityTypes,
        "can-be-reset": false,
        disabled: $options.readOnly,
        "confirm-alert-message": $data.translate.areYouSureYouWantToChangeTheActivityType,
        class: "mb-5"
      }, null, 8 /* PROPS */, ["modelValue", "activity-types", "disabled", "confirm-alert-message"])) : _createCommentVNode("v-if", true), $data.incident.id && $data.questionnaires.length ? (_openBlock(), _createBlock(_component_multiple_questionnaire_selector, {
        key: 1,
        class: "mb-4 pb-6 border-b-2 border-gray-250",
        "questionnaire-options": $data.questionnaires,
        disabled: !!$options.readOnly,
        "selected-questionnaires": $data.incident.questionnaires,
        onAddQuestionnaire: $options.addQuestionnaire,
        onRemoveQuestionnaire: $options.removeQuestionnaire
      }, null, 8 /* PROPS */, ["questionnaire-options", "disabled", "selected-questionnaires", "onAddQuestionnaire", "onRemoveQuestionnaire"])) : _createCommentVNode("v-if", true)];
    }),
    _: 2 /* DYNAMIC */
  }, [$options.showIncidentWorkflowStatus ? {
    name: "status-transition",
    fn: _withCtx(function () {
      return [_createVNode(_component_status_transition, {
        incident: $data.incident,
        class: "ml-auto",
        onPropertyChanged: $options.incidentPropertyChanged
      }, null, 8 /* PROPS */, ["incident", "onPropertyChanged"])];
    }),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-loaded", "dep-header-props", "item", "read-only", "is-user-locked", "dep-relations-tabs", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onGetActivityData", "onUpdate:isPageWithCustomUpdateDirty"])]);
}