// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination[data-v-14f17b42] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.pagination .pagination-page[data-v-14f17b42] {
  margin: 0 0 0 2px;
  padding: 8px 15px;
  border-radius: 2px;
  background: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;
}
.pagination .pagination-page[data-v-14f17b42]:not(.label-button):hover, .pagination .pagination-page[data-v-14f17b42]:not(.label-button):disabled {
  background: #737373;
  color: #FFFFFF;
}
.pagination .pagination-page.label-button[data-v-14f17b42]:disabled {
  cursor: default;
}
.pagination .pagination-page.label-button[data-v-14f17b42]:hover:not(:disabled) {
  background: #737373;
  color: #FFFFFF;
}
.pagination .pagination-page.alt-design[data-v-14f17b42] {
  padding: 4px 12px;
  border-radius: 4px;
}
.pagination .pagination-page.alt-design[data-v-14f17b42]:not(.label-button):hover, .pagination .pagination-page.alt-design[data-v-14f17b42]:not(.label-button):disabled, .pagination .pagination-page.alt-design.label-button[data-v-14f17b42]:hover:not(:disabled) {
  background: #2182fd;
  color: #FFFFFF;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
