// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-switch[data-v-fa847122] {
  display: flex;
  margin: 10px 0;
  align-items: center;
}
.control-switch-left[data-v-fa847122] {
  margin-right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
