import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "container mx-auto"
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_compact_card = _resolveComponent("compact-card");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.archivedActions,
    type: "big",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, [$data.archivedActions.length ? (_openBlock(), _createBlock(_component_compact_card, {
    key: 0,
    module: $data.MODULES.EXECUTION,
    activities: $options.compactDataset,
    users: _ctx.activeUsers,
    organisations: _ctx.organisations,
    "activity-types": _ctx.activityTypes,
    "watermark-image": $options.watermarkImageUrl,
    "visibility-storage-name": "archived_actions_compact_card",
    onDeleteItem: $options.deleteAction
  }, null, 8 /* PROPS */, ["module", "activities", "users", "organisations", "activity-types", "watermark-image", "onDeleteItem"])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    class: "text-center",
    type: "medium",
    title: $data.translate.nothingToShow
  }, null, 8 /* PROPS */, ["title"]))])])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1,
    class: "mt-25vh"
  }))]);
}