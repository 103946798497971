import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-6"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "glob-l1-sb"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  key: 1,
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_text_box = _resolveComponent("text-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_buttons_row = _resolveComponent("buttons-row");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.article.id ? $data.translate.editArticle : $data.translate.addArticle,
    "show-buttons": false,
    onDismiss: $options.toggleModal
  }, {
    body: _withCtx(function () {
      var _$data$formData;
      return [$data.showDomainModal ? (_openBlock(), _createBlock(_component_modal, {
        key: 0,
        header: $data.translate.createNewDomain,
        onAccept: $options.acceptDomainModal,
        onDismiss: $options.dismissDomainModal
      }, {
        body: _withCtx(function () {
          return [_createVNode(_component_input_field, {
            modelValue: $data.newDomainName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.newDomainName = $event;
            }),
            modelModifiers: {
              trim: true
            },
            title: $data.translate.domainName,
            required: true,
            placeholder: $data.translate.typeHere,
            invalid: !!$data.newDomainErrorText,
            "error-help-text": $data.newDomainErrorText,
            type: "text"
          }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["header", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: _normalizeClass([{
          'input-error': $data.errors.article
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.formData.article,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.formData.article = $event;
        }),
        title: $data.translate.article,
        required: true,
        placeholder: $data.translate.typeHere,
        invalid: !!$data.errors.article,
        "error-help-text": _ctx.$trans($data.errors.article),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"])], 2 /* CLASS */), _createElementVNode("div", {
        class: _normalizeClass([{
          'input-error': $data.errors.articleTitle
        }, "mb-3"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.formData.articleTitle,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.formData.articleTitle = $event;
        }),
        title: $data.translate.articleTitle,
        required: true,
        placeholder: $data.translate.typeHere,
        invalid: !!$data.errors.articleTitle,
        "error-help-text": _ctx.$trans($data.errors.articleTitle),
        maxlength: 1000,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"])], 2 /* CLASS */), _createElementVNode("div", _hoisted_1, [_createVNode(_component_text_box, {
        text: $data.formData.requirement,
        placeholder: $data.translate.typeHere + '...',
        label: $data.translate.requirement,
        error: $data.errors.requirement,
        class: "w-full border-gray-450",
        onUpdated: _cache[3] || (_cache[3] = function ($event) {
          return $data.formData.requirement = $event;
        })
      }, null, 8 /* PROPS */, ["text", "placeholder", "label", "error"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass({
          'input-error': $data.errors.domain
        })
      }, [_createVNode(_component_single_select, {
        "model-value": ((_$data$formData = $data.formData) === null || _$data$formData === void 0 || (_$data$formData = _$data$formData.domain) === null || _$data$formData === void 0 ? void 0 : _$data$formData.id) || null,
        options: $options.domainsList,
        placeholder: $data.translate.typeHere,
        "required-text": $data.errors.domain,
        title: $data.translate.domain,
        "onUpdate:modelValue": $options.onSelectDomain
      }, {
        newOption: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "flex items-center cursor-pointer text-rm-signal-grey-dark hover:border-rm-text border-rm-signal-grey-dark hover:text-rm-text hover:underline",
            onClick: _cache[4] || (_cache[4] = function ($event) {
              return $data.showDomainModal = true;
            })
          }, [_createVNode(_component_feather_icon, {
            icon: "plus-circle",
            width: "20",
            height: "20",
            class: "stroke-2 mr-1"
          }), _createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.createNewDomain), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value", "options", "placeholder", "required-text", "title", "onUpdate:modelValue"])], 2 /* CLASS */)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
        title: $data.translate.flags,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
        class: _normalizeClass({
          'input-error': $data.errors.flags
        })
      }, [_createVNode(_component_risma_select, {
        "selected-options": $options.selectedFlags,
        options: $options.flagOptions,
        "close-on-select": false,
        placeholder: $data.translate.typeHere,
        "deselect-label": $data.translate.pressEnterToRemove,
        "select-label": $data.translate.selected,
        "un-sorted": true,
        "selected-label": $data.translate.pressEnterToSelect,
        "track-by": "value",
        "label-key": "label",
        "error-message": $data.errors.flags,
        "onUpdate:modelValue": $options.onSelectFlags
      }, null, 8 /* PROPS */, ["selected-options", "options", "placeholder", "deselect-label", "select-label", "selected-label", "error-message", "onUpdate:modelValue"])], 2 /* CLASS */)]), $data.attributesArr.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.attributes,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.attributesArr, function (attribute) {
        return _openBlock(), _createElementBlock("div", {
          key: attribute.id,
          class: "mt-2"
        }, [_createVNode(_component_risma_select, {
          "label-key": "label",
          "track-by": "value",
          title: attribute.category,
          options: $data.labelOptions[attribute.id],
          "selected-options": $data.preselectedLabelOptions[attribute.id],
          onSelected: function onSelected($event) {
            return $options.onLabelsUpdate(attribute.id, $event);
          }
        }, null, 8 /* PROPS */, ["title", "options", "selected-options", "onSelected"])]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $options.hasOverallFramework ? (_openBlock(), _createBlock(_component_frameworks_selector, {
        key: 2,
        "selected-options": $options.selectedFrameworks,
        onSelected: $options.onFrameworksSelected
      }, null, 8 /* PROPS */, ["selected-options", "onSelected"])) : _createCommentVNode("v-if", true), _createVNode(_component_buttons_row, {
        class: "items-center justify-end mt-5",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"]);
}