function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineStore } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllTrafficLights, getTrafficLightsByModule } from '@/api/admin/trafficLights';
import { MODULES, TRAFFIC_LIGHT_MODULES } from '@/constants/modules';
import { OBJ_TYPE_TO_MODULE, ObjectTypes } from '@/constants/ObjectTypes';
import { Colors, COLOR_LABEL_MAPPING, getDefaultTrafficLights, prepareTrafficLights } from '@/Colors';
import { capitalize } from '@/utils';
export var useTrafficLights = defineStore('trafficLightsStore', {
  state: function state() {
    return {
      trafficLights: getDefaultTrafficLights()
    };
  },
  getters: {
    overdueControlColor: function overdueControlColor() {
      var _this$colorToLabelMap;
      var color = useSettingsStore().settings['feature.controls_overdue_completed_color'] || '#A80000';
      var legend = capitalize(((_this$colorToLabelMap = this.colorToLabelMapping.default) === null || _this$colorToLabelMap === void 0 ? void 0 : _this$colorToLabelMap[color]) || COLOR_LABEL_MAPPING[color]);
      return {
        color: color,
        legend: legend
      };
    },
    colorToLabelMapping: function colorToLabelMapping() {
      var _this = this;
      var mapping = {};
      var _loop = function _loop(key) {
        if (Object.prototype.hasOwnProperty.call(_this.trafficLights, key)) {
          var trafficLightsByModule = _this.trafficLights[key];
          mapping[key] = {};
          trafficLightsByModule.forEach(function (trafficLight) {
            mapping[key][trafficLight.color] = trafficLight.legend;
          });
        }
      };
      for (var key in this.trafficLights) {
        _loop(key);
      }
      return mapping;
    },
    gapTrafficLights: function gapTrafficLights() {
      return this.trafficLights[TRAFFIC_LIGHT_MODULES.gaps] || Colors[TRAFFIC_LIGHT_MODULES.gaps];
    }
  },
  actions: {
    load: function load() {
      var _this2 = this;
      return getAllTrafficLights().then(function (response) {
        delete response._debug;
        Object.keys(response).forEach(function (key) {
          return response[key] = prepareTrafficLights(response[key]);
        });
        _this2.trafficLights = response;
      }).catch(function () {
        return _this2.trafficLights = getDefaultTrafficLights();
      });
    },
    fetchTrafficLightsByModule: function fetchTrafficLightsByModule(moduleName) {
      var _this3 = this;
      return getTrafficLightsByModule(moduleName).then(function (_ref) {
        var _list$;
        var list = _ref.list;
        var realModuleName = (_list$ = list[0]) === null || _list$ === void 0 ? void 0 : _list$.module;
        _this3.trafficLights[realModuleName] = prepareTrafficLights(list);
      });
    },
    getTrafficLightsForModule: function getTrafficLightsForModule(moduleName) {
      return this.trafficLights[moduleName] || this.trafficLights.default;
    },
    findLightForModuleByNumber: function findLightForModuleByNumber(moduleName, number, safe) {
      var trafficLights = this.getTrafficLightsForModule(moduleName);
      var trafficLight = trafficLights[number];
      return safe && !trafficLight ? trafficLights[0] : trafficLight;
    },
    findLightForModuleByLegend: function findLightForModuleByLegend(moduleName, label, safe) {
      var trafficLights = this.getTrafficLightsForModule(moduleName);
      var trafficLight = trafficLights.find(function (trafficLight) {
        return trafficLight.legend.toLowerCase() === label.toLowerCase();
      });
      return safe && !trafficLight ? trafficLights[0] : trafficLight;
    },
    findTrafficLightsByObjectType: function findTrafficLightsByObjectType(objType, number) {
      var safe = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var moduleName = OBJ_TYPE_TO_MODULE[objType];
      if (!moduleName) {
        throw new Error("Module not found for object type: ".concat(objType));
      }
      return this.findLightForModuleByNumber(moduleName, number, safe);
    },
    getTrafficLightByObjectTypeAndNumber: function getTrafficLightByObjectTypeAndNumber(objType, number) {
      var safe = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return this.findTrafficLightsByObjectType(objType, number, safe);
    },
    getTrafficLightColorByObjectTypeAndNumber: function getTrafficLightColorByObjectTypeAndNumber(objType, number) {
      var _this$findTrafficLigh;
      var safe = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return (_this$findTrafficLigh = this.findTrafficLightsByObjectType(objType, number, safe)) === null || _this$findTrafficLigh === void 0 ? void 0 : _this$findTrafficLigh.color;
    },
    getTrafficLightColorForModule: function getTrafficLightColorForModule(moduleName, number, safe) {
      var _this$findLightForMod;
      return ((_this$findLightForMod = this.findLightForModuleByNumber(moduleName, number, safe)) === null || _this$findLightForMod === void 0 ? void 0 : _this$findLightForMod.color) || null;
    },
    getTrafficLightLegendForModule: function getTrafficLightLegendForModule(moduleName, number, safe) {
      var _this$findLightForMod2;
      return ((_this$findLightForMod2 = this.findLightForModuleByNumber(moduleName, number, safe)) === null || _this$findLightForMod2 === void 0 ? void 0 : _this$findLightForMod2.legend) || '';
    },
    getTrafficLightByObjectTypeAndLegend: function getTrafficLightByObjectTypeAndLegend(objType, legend) {
      var safe = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var moduleName = OBJ_TYPE_TO_MODULE[objType];
      if (!moduleName) {
        throw new Error("Module not found for object type: ".concat(objType));
      }
      return this.findLightForModuleByLegend(moduleName, legend, safe);
    },
    getTrafficLabelForModuleByColor: function getTrafficLabelForModuleByColor(moduleName, colorHex) {
      var _this$colorToLabelMap2, _this$colorToLabelMap3;
      return ((_this$colorToLabelMap2 = this.colorToLabelMapping[moduleName]) === null || _this$colorToLabelMap2 === void 0 ? void 0 : _this$colorToLabelMap2[colorHex]) || ((_this$colorToLabelMap3 = this.colorToLabelMapping.default) === null || _this$colorToLabelMap3 === void 0 ? void 0 : _this$colorToLabelMap3[colorHex]) || COLOR_LABEL_MAPPING[colorHex] || null;
    },
    getTrafficLightColor: function getTrafficLightColor(item) {
      if (+item.id === 0) return;
      if (item.objType === ObjectTypes.INCIDENTS_INCIDENT || item.objType === ObjectTypes.INCIDENTS_INCIDENT_LIMITED) {
        return this.getTrafficLightByObjectTypeAndNumber(item.objType, item.severity).color;
      }
      if (item.objType === ObjectTypes.COMPANY) return this.getTrafficLightByObjectTypeAndNumber(item.objType, item.vatStatus).color;
      if (item.trafficLight !== undefined) return this.getTrafficLightByObjectTypeAndNumber(item.objType, item.trafficLight).color;
      if (item.trafficLightText) return this.getTrafficLightByObjectTypeAndLegend(item.objType, item.trafficLightText).color;
      return this.findLightForModuleByNumber(TRAFFIC_LIGHT_MODULES.default, 0).color;
    },
    // CONTROLS
    getControlTrafficLightByNumber: function getControlTrafficLightByNumber(number, useExtendedTraffics) {
      if (+number === 1 && useExtendedTraffics) {
        return _objectSpread({
          number: 1
        }, this.overdueControlColor);
      }
      var trafficLights = this.getTrafficLightsForModule(MODULES.CONTROLS);
      var trafficLight = trafficLights.find(function (trafficLight) {
        return +trafficLight.number === +number;
      });
      return trafficLight || {
        number: 0,
        color: '#BEBEBE',
        legend: 'grey'
      };
    },
    getControlTrafficLightColorByNumber: function getControlTrafficLightColorByNumber(number, useExtendedTraffics) {
      var _this$getControlTraff;
      return ((_this$getControlTraff = this.getControlTrafficLightByNumber(number, useExtendedTraffics)) === null || _this$getControlTraff === void 0 ? void 0 : _this$getControlTraff.color) || null;
    },
    // GAPS
    getGapLight: function getGapLight(number) {
      return this.gapTrafficLights[number];
    },
    getGapColor: function getGapColor(number) {
      return this.gapTrafficLights[number].color;
    },
    getGapLegend: function getGapLegend(number) {
      return this.gapTrafficLights[number].legend;
    },
    findGapLightByLabel: function findGapLightByLabel(label) {
      var labelLowerCase = label.toLowerCase();
      return this.gapTrafficLights.find(function (item) {
        return item.legend.toLowerCase() === labelLowerCase;
      }) || {};
    },
    getCustomFieldLegend: function getCustomFieldLegend(number) {
      var _this$trafficLights$T;
      return (_this$trafficLights$T = this.trafficLights[TRAFFIC_LIGHT_MODULES.default].find(function (trafficLight) {
        return +trafficLight.number === +number;
      })) === null || _this$trafficLights$T === void 0 ? void 0 : _this$trafficLights$T.legend;
    }
  }
});