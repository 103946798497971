import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createComplianceNode,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "ok-button-disabled": $data.isLoading,
    "dismiss-button-disabled": $data.isLoading,
    onAccept: $options.handleOnSubmit,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [!!$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.title = $event;
        }),
        modelModifiers: {
          trim: true
        },
        title: $data.translate.title,
        "focus-on-mount": true,
        invalid: !!$data.titleError,
        "error-help-text": $data.titleError,
        placeholder: $data.translate.enterTitle,
        type: "text",
        class: "mb-6"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "placeholder"]), $options.isDPOEnabled ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createVNode(_component_input_field, {
        modelValue: $data.dpoName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.dpoName = $event;
        }),
        modelModifiers: {
          trim: true
        },
        title: $options.dpoLabel,
        placeholder: $data.translate.name,
        type: "text",
        class: "mb-6"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $options.dataControllersEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_help_tool_tip, {
        class: "!absolute w-96 ml-36"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.dataControllerHint), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_risma_select, {
        title: $data.translate.dataControllers,
        options: $data.controllersOptions,
        "selected-options": $data.selectedControllers,
        placeholder: $data.translate.clickToSelectDataControllers,
        "label-key": "label",
        class: "mb-6",
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $data.selectedControllers = $event;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), $options.isFrameworkEnabled ? (_openBlock(), _createBlock(_component_frameworks_selector, {
        key: 2,
        "selected-options": $data.selectedFrameworks,
        onSelected: _cache[3] || (_cache[3] = function ($event) {
          return $data.selectedFrameworks = $event;
        })
      }, null, 8 /* PROPS */, ["selected-options"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled", "dismiss-button-disabled", "onAccept"]);
}