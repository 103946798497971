import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
var _hoisted_2 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_tree_list = _resolveComponent("tree-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tree_list, {
    item: $data.treeData
  }, {
    title: _withCtx(function (_ref) {
      var item = _ref.item,
        parent = _ref.parent;
      return [_createElementVNode("div", _hoisted_2, [item.color ? (_openBlock(), _createBlock(_component_traffic_light, {
        key: 0,
        class: "mr-2",
        color: item.color
      }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(item.title) + " - parent : " + _toDisplayString(parent && parent.title || 'No parent'), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item"])]);
}