import { getTranslate } from './translate';
import { closeTheYear as _closeTheYear } from '@/api/compliance/reports/managementReport';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
export default {
  name: 'CloseYear',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      isLoading: false,
      translate: getTranslate['CloseYear']()
    };
  },
  methods: {
    confirmClosing: function confirmClosing() {
      var _this = this;
      this.$confirm('', this.translate.areYouSure, function (res) {
        return res && _this.closeTheYear();
      });
    },
    closeTheYear: function closeTheYear() {
      var _this2 = this;
      return _closeTheYear().then(function () {
        return _this2.$notify({
          title: _this2.translate.theReportingYearIsClosed
        });
      }).catch(function (err) {
        return _this2.$notify({
          title: err.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this2.isLoading = false;
      });
    }
  }
};