import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "attachment-modal"
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  key: 0,
  class: "mb-2"
};
var _hoisted_4 = {
  key: 1,
  class: "mb-5"
};
var _hoisted_5 = {
  key: 3,
  class: "mt-8 mb-6"
};
var _hoisted_6 = {
  key: 4,
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_files_list = _resolveComponent("files-list");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $options.linkEnabled ? $data.translate.addLink : $data.translate.addAttachment,
    "button-dismiss-text": $data.translate.cancel,
    "button-ok-text": $data.translate.apply,
    "ok-button-disabled": $props.isApplyDisabled,
    onDismiss: $options.toggleModal,
    onAccept: $options.onApply
  }, {
    body: _withCtx(function () {
      return [$data.isLinkSaveFail ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        ref: "linkFailNotification",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.pleaseFillLinkToFieldsCorrectly), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), $options.fileErrorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 1,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.fileErrorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $options.fileEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_file_upload, {
        "single-file-load": $props.singleFileLoad,
        "show-uploaded-files": $data.files.length ? true : false,
        onUpload: $options.fileUploaded,
        "onUpload:unsupported": $options.setExtensionError
      }, null, 8 /* PROPS */, ["single-file-load", "show-uploaded-files", "onUpload", "onUpload:unsupported"]), $props.hasTitle && $data.files.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.files, function (file, index) {
        return _openBlock(), _createBlock(_component_input_field, {
          key: index,
          modelValue: file.title,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return file.title = $event;
          },
          title: $data.files.length > 1 ? $data.translate.titles : $data.translate.title,
          type: "text",
          class: "mb-1"
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "title"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $props.typeOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_select, {
        title: $data.translate.type,
        options: $props.typeOptions,
        "selected-options": $data.documentTypes,
        placeholder: $data.translate.chooseType,
        disabled: $data.documentTypesDisabled,
        "label-key": "label",
        "track-by": "id",
        onSelected: _cache[0] || (_cache[0] = function (values) {
          return $data.documentTypes = values;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.linkEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_input_field, {
        modelValue: $data.linkURL,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.linkURL = $event;
        }),
        title: $data.translate.linkTo,
        placeholder: $data.translate.insertLink + ' (https, http, ftp, etc.)',
        "focus-on-mount": true,
        maxlength: 1000,
        class: "mb-3",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
        modelValue: $data.linkTitle,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.linkTitle = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.insertLinkTitle,
        class: "mb-3",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])) : _createCommentVNode("v-if", true), $props.hasPin ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", {
        class: "flex mr-5 cursor-pointer",
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return $data.pinned = !$data.pinned;
        })
      }, [_createVNode(_component_feather_icon, {
        class: _normalizeClass(["mr-3 stroke-2", {
          'text-green-410': $data.pinned
        }]),
        icon: "award"
      }, null, 8 /* PROPS */, ["class"]), _createVNode(_component_risma_title, {
        title: _ctx.$trans($data.pinned ? 'Pinned' : 'Pin'),
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_risma_title, {
        class: "text-rm-urban",
        title: $data.translate.whenYouPinADocumentItWillBeAddedToTheAt,
        truncate: false,
        type: "small"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createVNode(_component_files_list, {
        files: $data.existingUrls
      }, null, 8 /* PROPS */, ["files"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text", "button-ok-text", "ok-button-disabled", "onDismiss", "onAccept"])]);
}