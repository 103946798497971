// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr td[data-v-42d3b0b4]:first-child {
  text-align: left;
  margin-right: 0.25rem;
}
.titleCol[data-v-42d3b0b4] {
  font-weight: 600;
}
.titleCol[data-v-42d3b0b4]  a:hover {
  text-decoration: underline;
}
.titleCol[data-v-42d3b0b4]  .cursor-pointer:hover {
  text-decoration: underline;
}
tr[data-v-42d3b0b4] {
  border-top: 2px solid rgb(235.9, 235.9, 235.9);
}
td[data-v-42d3b0b4] {
  line-height: normal;
  padding: 0.5rem 1rem 0.5rem 0;
}
td[data-v-42d3b0b4]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
td[data-v-42d3b0b4]  ul ul {
  list-style-type: circle;
}
td[data-v-42d3b0b4]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
td[data-v-42d3b0b4]  li {
  padding-left: 0.3em;
}
td.wide[data-v-42d3b0b4] {
  min-width: 32px;
}
.empty[data-v-42d3b0b4] {
  border-top: none !important;
  height: 38px;
}
tr.child[data-v-42d3b0b4] {
  background-color: #ebebeb;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
