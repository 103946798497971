// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline[data-v-2492b466]::after {
  content: "";
  display: block;
  width: 1px;
  height: 13px;
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translate(-100%, 0);
  background-color: #000000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
