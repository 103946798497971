function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { MODULES } from '@/constants/modules';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
export var AdminTypesModalMixin = {
  data: function data() {
    return {
      lockButtons: false
    };
  },
  emits: ['toggle'],
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    moduleTypesOptions: function moduleTypesOptions() {
      var _this = this;
      var modules = [MODULES.EXECUTION, MODULES.INCIDENTS, MODULES.CONTROLS, MODULES.RISK, MODULES.COMPLIANCE, MODULES.CONTRACTS, MODULES.COMPLIANCE_PROCESS_TREE_NODE, MODULES.COMPLIANCE_INFORMATION_ASSETS, MODULES.COMPLIANCE_IA_CONTROLLER, MODULES.COMPLIANCE_IA_PROCESSOR, MODULES.COMPLIANCE_IA_SYSTEM];
      var items = modules.map(function (module) {
        return {
          label: _this.moduleTitles[module],
          value: module
        };
      });
      var values = getDisabledModuleFromArrayBySetting(items.map(function (item) {
        return item.value;
      }), this.getSettingValue);
      values.forEach(function (item) {
        items.splice(items.findIndex(function (element) {
          return element.value === item;
        }), 1);
      });
      return items;
    }
  }),
  methods: {
    onChangeLabel: function onChangeLabel(e) {
      this.labelName = e;
    },
    beforeOpen: function beforeOpen() {
      this.clearFields();
      this.resetValidation();
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
      this.$emit('toggle', this.showModal);
    },
    resetValidation: function resetValidation() {
      this.labelNameValidation = null;
      this.selectOptionsValidation = null;
      this.fieldTypeValidation = null;
    }
  }
};