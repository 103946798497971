import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "mb-6 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_3 = {
  class: "flex justify-between"
};
var _hoisted_4 = {
  class: "w-12/25"
};
var _hoisted_5 = {
  key: 0,
  class: "text-red-50"
};
var _hoisted_6 = {
  class: "w-12/25"
};
var _hoisted_7 = {
  key: 0,
  class: "text-red-50"
};
var _hoisted_8 = {
  class: "mb-3 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_9 = {
  class: "w-full"
};
var _hoisted_10 = {
  class: "border-b-2 border-gray-160"
};
var _hoisted_11 = {
  class: "pb-2 text-left glob-h3 text-rm-text"
};
var _hoisted_12 = {
  class: "pb-2 text-left glob-h3 text-rm-text"
};
var _hoisted_13 = {
  class: "pb-2 glob-h3 text-rm-text"
};
var _hoisted_14 = {
  class: "pb-2 glob-h3 text-rm-text"
};
var _hoisted_15 = {
  class: "py-1"
};
var _hoisted_16 = {
  class: "py-1 capitalize"
};
var _hoisted_17 = {
  class: "py-1"
};
var _hoisted_18 = {
  class: "py-1"
};
var _hoisted_19 = {
  class: "mt-6 mb-1 glob-h3 text-rm-text"
};
var _hoisted_20 = {
  class: "mb-3 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_21 = {
  class: "flex items-center mb-6"
};
var _hoisted_22 = {
  class: "glob-h1 text-rm-text"
};
var _hoisted_23 = ["onClick"];
var _hoisted_24 = ["checked", "disabled", "value", "onChange"];
var _hoisted_25 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  var _component_input_field = _resolveComponent("input-field");
  var _component_color_picker = _resolveComponent("color-picker");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showDeleteModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.deleteStatus,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[1] || (_cache[1] = function ($event) {
      return $options.deleteStatus($data.deleteStatusData);
    }),
    onDismiss: $options.closeDeleteModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.youAreTryingToDeleteStatus($data.deleteStatusData.title, $data.deleteStatusData.usageCount)), 1 /* TEXT */), _createVNode(_component_single_select, {
        modelValue: $data.moveToState,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.moveToState = $event;
        }),
        title: $data.translate.status,
        options: $data.moveToStatuses,
        placeholder: $data.translate.selectAStatus,
        "required-text": $data.deleteStatusData.errorMessage
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "required-text"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showStatusModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.statusSettings,
    "button-ok-text": $data.isCreateMode ? $data.translate.create : $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "body-scroll-y": true,
    onAccept: $options.onStatusSettingsChange,
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.showStatusModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_input_field, {
        modelValue: $data.editedStatusData.title,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.editedStatusData.title = $event;
        }),
        title: $data.translate.statusTitle,
        "focus-on-mount": true,
        invalid: $options.isStatusTitleInvalid,
        placeholder: $data.translate.enterTitle,
        maxlength: 50,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "placeholder"]), $options.isStatusTitleInvalid ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString("".concat($data.translate.title, " ").concat($data.translate.isMandatory)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_color_picker, {
        color: $data.editedStatusData.color,
        "adaptive-width": true,
        title: $data.translate.statusColor,
        "value-type": $data.colorValueType,
        onInput: $options.setColor
      }, null, 8 /* PROPS */, ["color", "title", "value-type", "onInput"]), $options.isColorInvalid ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString("".concat($data.translate['Invalid color'])), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _createVNode(_component_risma_select, {
        title: $data.translate.transitions,
        options: $options.transitionsOptions,
        "selected-options": $options.selectedTransitions,
        "un-sorted": true,
        placeholder: $data.translate.selectTheStatusesItCanTransitionTo,
        "label-key": "title",
        class: "mt-6",
        onSelected: _cache[3] || (_cache[3] = function ($event) {
          return $data.editedStatusData.targetStatuses = $event;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"]), _createVNode(_component_risma_select, {
        title: $data.translate.defaultResponsible,
        options: $options.responsibleOptions,
        "selected-options": $options.selectedResponsible,
        placeholder: $data.translate.selectThePersonsThatWillDefaultBeSelectedAsResponsibles,
        "label-key": "display_name",
        class: "mt-6",
        onSelected: $options.onSelectResponsible
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"]), _createVNode(_component_risma_label, {
        title: $data.translate.activeFields,
        class: "mt-6"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_8, _toDisplayString($data.translate.selectTheFieldsThatCanBeEditedIn), 1 /* TEXT */), _createElementVNode("table", _hoisted_9, [_createElementVNode("thead", _hoisted_10, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_11, _toDisplayString($data.translate.title), 1 /* TEXT */), _createElementVNode("th", _hoisted_12, _toDisplayString($data.translate.fieldType), 1 /* TEXT */), _createElementVNode("th", _hoisted_13, _toDisplayString($data.translate.editable), 1 /* TEXT */), _createElementVNode("th", _hoisted_14, _toDisplayString($data.translate.hide), 1 /* TEXT */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customFields, function (customField) {
        return _openBlock(), _createElementBlock("tr", {
          key: customField.id
        }, [_createElementVNode("td", _hoisted_15, _toDisplayString(customField.label), 1 /* TEXT */), _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.$trans($data.getCustomFieldTypeText(customField.fieldType))), 1 /* TEXT */), _createElementVNode("td", _hoisted_17, [_createVNode(_component_checkboxes, {
          "checkbox-size": 20,
          options: $options.isFieldEditable(customField.id),
          "center-checkbox": true,
          disabled: $options.isFieldHidden(customField.id),
          onUpdated: function onUpdated($event) {
            return $options.updateIsEditable(customField.id, $event.isChecked);
          }
        }, null, 8 /* PROPS */, ["options", "disabled", "onUpdated"])]), _createElementVNode("td", _hoisted_18, [_createVNode(_component_checkboxes, {
          "checkbox-size": 20,
          options: $options.isFieldHidden(customField.id),
          "center-checkbox": true,
          disabled: $options.isFieldEditable(customField.id),
          onUpdated: function onUpdated($event) {
            return $options.updateIsHidden(customField.id, $event.isChecked);
          }
        }, null, 8 /* PROPS */, ["options", "disabled", "onUpdated"])])]);
      }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("h3", _hoisted_19, _toDisplayString($data.translate.instructions), 1 /* TEXT */), _createElementVNode("p", _hoisted_20, _toDisplayString($data.translate.theInstructionsWillBeVisibleOnTheActivity), 1 /* TEXT */), _createVNode(_component_tiny_mce_box, {
        type: "mini",
        "initial-content": $data.editedStatusData.instructions,
        "always-open": true,
        "debounce-timeout": 0,
        onChanged: _cache[4] || (_cache[4] = function ($event) {
          return $data.editedStatusData.instructions = $event;
        })
      }, null, 8 /* PROPS */, ["initial-content"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_21, [_createVNode(_component_router_link, {
    to: {
      name: 'IncidentsWorkflows'
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "chevron-left",
        width: "30",
        height: "30",
        class: "mr-2 stroke-2 text-rm-text cursor-pointer"
      })];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("h1", _hoisted_22, _toDisplayString($data.workflow.title), 1 /* TEXT */), !$options.readOnly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.addStatus,
    type: "save",
    class: "add-button ml-auto",
    disabled: $data.saveStatus === $data.saveStatuses.SAVING,
    onClick: $options.addStatus
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        width: "18",
        height: "18",
        class: "mr-2 stroke-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "onClick"])) : _createCommentVNode("v-if", true)]), $data.saveStatus === $data.saveStatuses.SAVING ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 2
  })) : $options.tableDataset.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 3,
    class: "w-full",
    "dataset-table": $options.tableDataset,
    columns: $options.tableColumns,
    theme: $data.StaticTableThemes.zebra
  }, {
    statusTitle: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("p", {
        class: _normalizeClass(["glob-b1 text-rm-text", {
          'hover:font-semibold hover:underline cursor-pointer': !$options.readOnly
        }]),
        onClick: function onClick($event) {
          return $options.editStatus(slotData.id);
        }
      }, _toDisplayString(slotData.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_23)];
    }),
    isStartStatus: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("input", {
        checked: slotData.checked,
        disabled: $options.readOnly,
        value: slotData.id,
        name: "is-start-status",
        type: "radio",
        class: _normalizeClass(["ml-2", [$data.radioButtonClasses, $options.readOnly ? 'cursor-not-allowed' : 'cursor-pointer']]),
        onChange: function onChange($event) {
          return $options.changeIsStartStatus(slotData.id);
        }
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_24)];
    }),
    delete: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [!$options.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "x",
        width: "24",
        height: "24",
        class: "ml-2 stroke-2 text-red-50 cursor-pointer",
        onClick: function onClick($event) {
          return $options.openDeleteModal(slotData.id);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock("span", _hoisted_25))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns", "theme"])) : _createCommentVNode("v-if", true)]);
}