function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
export var generatedData = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.STAKEHOLDER_OPTIONS, [{
  value: '2590a2d2b9c6991a35e71ab3975f0692',
  label: 'Applicants'
}, {
  value: '5b99a80f22067f1cd562413296f79683',
  label: 'Employees'
}, {
  value: '85058f819a20c815ddece81ee230421d',
  label: '[LINK TO CONTACT CARD]'
}, {
  value: 'fa8af73ac7cb7766ce8982616e756ead',
  label: '[LINK TO CONTACT CARD]'
}]), DataFlowFields.DATA_TYPE_OPTIONS, [{
  id: 24,
  questionId: 51,
  text: 'Absence records',
  type: 'question_option',
  legalBasisOptionId: null,
  legalBasisOptionLabel: null,
  uniqueId: '1d7e77d0aab7caaeea1531fa1c19b2af'
}, {
  id: 25,
  questionId: 52,
  text: 'Age',
  type: 'question_option',
  legalBasisOptionId: null,
  legalBasisOptionLabel: null,
  uniqueId: 'ea3aa01bb5634eedaa1d12249510e37f'
}, {
  id: 65,
  questionId: 53,
  text: 'Data concerning racial or ethnic origin',
  type: 'question_option',
  legalBasisOptionId: null,
  legalBasisOptionLabel: null,
  uniqueId: '1cb6eeabbaf7f4689d13c756869bfb5d'
}, {
  id: 66,
  questionId: 54,
  text: 'Data concerning political opinions',
  type: 'question_option',
  legalBasisOptionId: null,
  legalBasisOptionLabel: null,
  uniqueId: '0ef9276508bf114f55ed8638eb25998d'
}]), DataFlowFields.BASIS_OPTIONS, [{
  value: '115',
  label: 'Consent for one or more specific purposes'
}, {
  value: '116',
  label: 'Necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract'
}, {
  value: '117',
  label: 'Processing is necessary for compliance with a legal obligation to which the controller is subject'
}, {
  value: '118',
  label: 'Processing is necessary in order to protect the vital interests of the data subject or of another natural person'
}, {
  value: '119',
  label: 'Processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller'
}, {
  value: '120',
  label: 'Processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child.'
}, {
  value: '121',
  label: 'None'
}]), DataFlowFields.SYSTEMS, [{
  label: 'Systems',
  items: [{
    id: 1,
    text: 'System1',
    type: 'dataprocessor_system',
    uniqueId: '50881df9b4605b8094a56f76a43d6c51'
  }, {
    id: 2,
    text: 'System2',
    type: 'dataprocessor_system',
    uniqueId: 'f9f9203db0d55c608976fd3bd2bdad1b'
  }, {
    id: 10,
    text: 'System3',
    type: 'dataprocessor_system',
    uniqueId: '372420bef39dec85ce5be4351ffea051'
  }, {
    id: 17,
    text: 'SystemWithQuestionnaireAndAnswers',
    type: 'dataprocessor_system',
    uniqueId: '557926dcd9a6830365bfdf9bd98e379c'
  }, {
    id: 22,
    text: 'Test System1',
    type: 'dataprocessor_system',
    uniqueId: '9ac48cc6f74f118f9526154914cd488c'
  }]
}]), "questions", [{
  id: 51,
  questionnumber: '1a',
  questionobject: {
    legalBasisQuestionId: 1
  }
}, {
  id: 52,
  questionnumber: '1b',
  questionobject: {
    legalBasisQuestionId: 2
  }
}, {
  id: 53,
  questionnumber: '1c',
  questionobject: {
    legalBasisQuestionId: 3
  }
}, {
  id: 54,
  questionnumber: '1d',
  questionobject: {
    legalBasisQuestionId: 4
  }
}]);
export var dataFromAPI = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.STAKEHOLDER_OPTIONS, [{
  label: 'Data processors',
  $isGroup: true
}, {
  label: 'Microsoft',
  value: '80b8871971a7d0d63f57da21d28f313f',
  groupName: 'Data processors'
}, {
  label: 'Software AG',
  value: '00d9d0b22ad7b987ef042b6f8588e011',
  groupName: 'Data processors'
}, {
  label: 'Data subjects',
  $isGroup: true
}, {
  label: '[LINK TO CONTACT CARD]',
  value: 'a011378f83e1665a3cd27461c948f122',
  groupName: 'Data subjects'
}, {
  label: 'Executives',
  value: '663050d6ddb3c9491fa82c118b5cb1aa',
  groupName: 'Data subjects'
}, {
  label: 'External consultants',
  value: '4ec006b7d5f9ad6a77e27c4a672a87b6',
  groupName: 'Data subjects'
}]), DataFlowFields.DATA_TYPE_OPTIONS, [{
  id: 25,
  text: 'Age',
  type: 'question_option',
  uniqueId: 'ea3aa01bb5634eedaa1d12249510e37f',
  questionId: 2846
}, {
  id: 27,
  text: 'Bank account or credit card number',
  type: 'question_option',
  uniqueId: '6e8e1014d424dd62b2297359bfe87f9d',
  questionId: 2847
}, {
  id: 50,
  text: 'Not applicable',
  type: 'question_option',
  uniqueId: '108a857a29cf47710bf7d4063025758d',
  questionId: 2848
}, {
  id: 66,
  text: 'Data concerning political opinions',
  type: 'question_option',
  uniqueId: '0ef9276508bf114f55ed8638eb25998d',
  questionId: 2849
}, {
  id: 67,
  text: 'Data concerning religious or philosophical beliefs',
  type: 'question_option',
  uniqueId: '6386ea479460f6f533cb6627ada02b53',
  questionId: 2850
}]), DataFlowFields.SYSTEMS, [{
  label: 'Data processors',
  items: [{
    id: 2,
    text: 'Microsoft',
    type: 'dataprocessor_processor',
    uniqueId: '80b8871971a7d0d63f57da21d28f313f',
    questionId: 2
  }, {
    id: 6,
    text: 'Software AG',
    type: 'dataprocessor_processor',
    uniqueId: '00d9d0b22ad7b987ef042b6f8588e011',
    questionId: 1
  }]
}, {
  label: 'Systems',
  items: [{
    id: 1,
    text: 'E-conomic',
    type: 'dataprocessor_system',
    uniqueId: 'f9f9203db0d55c608976fd3bd2bdad1b',
    questionId: 3
  }, {
    id: 4,
    text: 'JIRA',
    type: 'dataprocessor_system',
    uniqueId: 'eaa12170fe9029eda9c9db9e8203090e',
    questionId: 3
  }, {
    id: 2,
    text: 'Office 365',
    type: 'dataprocessor_system',
    uniqueId: '50881df9b4605b8094a56f76a43d6c51',
    questionId: 3
  }, {
    id: 3,
    text: 'Stackoverflow',
    type: 'dataprocessor_system',
    uniqueId: 'ee234d3054c41b1c4c6b106e6223322d',
    questionId: 3
  }]
}]), DataFlowFields.BASIS_OPTIONS, []);
export var flowData = {
  comments: '',
  dataTypes: [{
    id: 11329,
    text: '2',
    type: 'question_option',
    legalBasisOptionId: 9885,
    legalBasisOptionLabel: 'Biometric data (for the purpose of uniquely identifying a natural person)',
    questionId: 2848,
    uniqueId: '393cd8b529ec15bd9aef830e8174ad93'
  }],
  direction: 'deliver',
  stakeholder: {
    id: 9681,
    text: 'Partners',
    type: 'question_option',
    legalBasisOptionId: null,
    legalBasisOptionLabel: null,
    questionId: 2848,
    uniqueId: 'a8ddbf50e4b8044709ee7152d9f20f13'
  },
  systems: [{
    id: 4,
    text: 'JIRA',
    type: 'dataprocessor_system',
    uniqueId: 'eaa12170fe9029eda9c9db9e8203090e',
    questionId: 3
  }]
};