import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "risk-grid"
};
var _hoisted_2 = {
  key: 0,
  class: "zoom-actions"
};
var _hoisted_3 = {
  class: "current-zoom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_matrix = _resolveComponent("matrix");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.enableZoom ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.currentZoomPercentage) + " %", 1 /* TEXT */), _createVNode(_component_buttons_row, {
    class: "ml-2",
    data: $options.buttonsRowData
  }, null, 8 /* PROPS */, ["data"])])) : _createCommentVNode("v-if", true), _createVNode(_component_matrix, {
    ref: "matrix",
    matrix: $data.grid,
    "color-codes": $props.riskGridData.colorCodes,
    "matrix-color": $props.colorDirection,
    labels: $options.computedLabels,
    size: $data.gridBoxSizeUser,
    checked: $data.checked,
    periods: $props.periods,
    "show-previous-position-label": $props.showPreviousPositionLabel,
    "zoom-range": $props.zoomRange,
    "zoom-step": $props.zoomStep,
    class: _normalizeClass({
      'ml-[68px]': $props.enableZoom
    }),
    "enable-prev-position": $props.enablePrevPosition,
    "is-matrix-clickable": $props.isGridClickable,
    "dot-label-hover-function": $props.dotLabelHoverFunction,
    "start-color": $props.startColor,
    "end-color": $props.endColor,
    "custom-heatmap-options": $props.customHeatmapOptions,
    "dot-connector-enabled": $props.dotConnectorEnabled,
    "show-lines": $props.showLines,
    "are-dots-center-aligned": $props.areDotsCenterAligned,
    onZoom: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('zoom', $event);
    }),
    onOnPeriodChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('onPeriodChanged', $event);
    }),
    onOnStateChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('onStateChanged', $event);
    }),
    onTogglePreviousPosition: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('togglePreviousPosition', $event);
    })
  }, null, 8 /* PROPS */, ["matrix", "color-codes", "matrix-color", "labels", "size", "checked", "periods", "show-previous-position-label", "zoom-range", "zoom-step", "class", "enable-prev-position", "is-matrix-clickable", "dot-label-hover-function", "start-color", "end-color", "custom-heatmap-options", "dot-connector-enabled", "show-lines", "are-dots-center-aligned"])]);
}