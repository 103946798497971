import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex mb-1"
};
var _hoisted_3 = {
  key: 1,
  class: "w-full"
};
var _hoisted_4 = {
  class: "w-10"
};
var _hoisted_5 = {
  class: "text-gray-610 text-left"
};
var _hoisted_6 = {
  key: 0,
  class: "text-gray-610 text-left"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  class: "py-1 border-t pl-1"
};
var _hoisted_9 = {
  class: "py-1 border-t"
};
var _hoisted_10 = {
  key: 0,
  class: "py-1 border-t"
};
var _hoisted_11 = {
  class: "py-1 flex items-center justify-end border-t"
};
var _hoisted_12 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_controls_accounts_tree = _resolveComponent("controls-accounts-tree");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalHeader,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    "ok-button-disabled": !$data.isSomethingChanged,
    type: "small",
    onAccept: $options.handleModalAccept,
    onDismiss: $options.closeModal
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.modalItem.title,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.modalItem.title = $event;
        }), $options.onChange],
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        required: true,
        "focus-on-mount": true,
        type: "text",
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "onUpdate:modelValue"]), $props.isAccountsWell ? (_openBlock(), _createBlock(_component_single_select, {
        key: 0,
        modelValue: $data.modalItem.parentId,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.modalItem.parentId = $event;
        }), $options.onChange],
        title: $data.translate.parent,
        options: $options.accountsForSingleSelect,
        "un-sorted": true,
        placeholder: $data.translate.chooseParentOptional,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), $props.riskTypes.length ? (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        modelValue: $data.modalItem.riskTypeId,
        "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
          return $data.modalItem.riskTypeId = $event;
        }), $options.onChange],
        title: $data.translate.type,
        options: $options.riskTypesFofSingleSelect,
        "un-sorted": true,
        placeholder: $data.translate.chooseType
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $props.title,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    class: "ml-auto stroke-3 text-blue-350 cursor-pointer",
    icon: "plus",
    height: "35",
    width: "35",
    onClick: _withModifiers($options.openCreateForm, ["stop"])
  }, null, 8 /* PROPS */, ["onClick"])]), !$props.isAccountsWell ? (_openBlock(), _createElementBlock("table", _hoisted_3, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_4, [_createVNode(_component_feather_icon, {
    class: "stroke-2 text-gray-610",
    icon: "hash",
    height: "20",
    width: "20"
  })]), _createElementVNode("th", _hoisted_5, _toDisplayString($data.translate.title), 1 /* TEXT */), $props.riskTypes.length ? (_openBlock(), _createElementBlock("th", _hoisted_6, _toDisplayString($data.translate.type), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _cache[3] || (_cache[3] = _createElementVNode("th", null, null, -1 /* HOISTED */))])]), !$props.loading && $props.items.length ? (_openBlock(), _createElementBlock("tbody", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index
    }, [_createElementVNode("td", _hoisted_8, _toDisplayString(index + 1), 1 /* TEXT */), _createElementVNode("td", _hoisted_9, _toDisplayString(item.title), 1 /* TEXT */), $props.riskTypes.length ? (_openBlock(), _createElementBlock("td", _hoisted_10, _toDisplayString(item.riskTypeId ? item.riskType.title : ''), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("td", _hoisted_11, [_createVNode(_component_feather_icon, {
      class: _normalizeClass(["stroke-2 text-blue-350 pr-2 cursor-pointer", {
        'mr-5': item.inUse
      }]),
      icon: "edit",
      height: "25",
      width: "25",
      onClick: function onClick($event) {
        return $options.openEditItem(item);
      }
    }, null, 8 /* PROPS */, ["class", "onClick"]), !item.inUse ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "stroke-2 text-red-50 cursor-pointer",
      icon: "x",
      height: "20",
      width: "20",
      onClick: function onClick($event) {
        return $options.deleteItem(item);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $props.isAccountsWell && !$props.loading && $props.items.length ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_controls_accounts_tree, {
    list: $props.items,
    class: "pr-2",
    onEditAccount: $options.openEditItem,
    onDeleteAccount: $options.deleteItem
  }, null, 8 /* PROPS */, ["list", "onEditAccount", "onDeleteAccount"])])) : _createCommentVNode("v-if", true), $props.loading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 3
  })) : _createCommentVNode("v-if", true)]);
}