import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-3"
};
var _hoisted_2 = {
  class: "w-full mr-4"
};
var _hoisted_3 = {
  class: "w-full ml-4"
};
var _hoisted_4 = {
  class: "flex justify-between mb-3"
};
var _hoisted_5 = {
  class: "w-full mr-4"
};
var _hoisted_6 = {
  class: "w-full ml-4"
};
var _hoisted_7 = {
  class: "flex justify-between mb-3"
};
var _hoisted_8 = {
  class: "w-full mr-4"
};
var _hoisted_9 = {
  class: "w-full ml-4"
};
var _hoisted_10 = {
  class: "flex justify-between mb-3"
};
var _hoisted_11 = {
  class: "w-full mr-4"
};
var _hoisted_12 = {
  class: "w-full ml-4"
};
var _hoisted_13 = {
  class: "flex justify-between mb-3"
};
var _hoisted_14 = {
  class: "w-1/2 pr-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.dataFetchedFromPublicRegister,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.$emit('accept');
    }),
    onDismiss: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [$props.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $props.errorMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($props.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        type: "small",
        class: "mb-3",
        title: $data.translate.thisIsThePublicDataThatWasFetched + ' ' + $data.currentDateTime
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $props.modalData.name = $event;
        }),
        title: $data.translate.name,
        placeholder: $data.translate.name,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.address,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $props.modalData.address = $event;
        }),
        title: $data.translate.address,
        placeholder: $data.translate.address,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.city,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $props.modalData.city = $event;
        }),
        title: $data.translate.city,
        placeholder: $data.translate.city,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.zipCode,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $props.modalData.zipCode = $event;
        }),
        title: $data.translate.zipCode,
        placeholder: $data.translate.zipCode,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.phone,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $props.modalData.phone = $event;
        }),
        title: $data.translate.phone,
        placeholder: $data.translate.phone,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.email,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return $props.modalData.email = $event;
        }),
        title: $data.translate.email,
        placeholder: $data.translate.email,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_input_field, {
        modelValue: $props.modalData.vatNo,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return $props.modalData.vatNo = $event;
        }),
        title: $data.translate.vatNumber,
        placeholder: $data.translate.vatNumber,
        enabled: false,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_input_field, {
        modelValue: $options.companyTrafficLightLegend,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
          return $options.companyTrafficLightLegend = $event;
        }),
        title: $data.translate.companyStatus,
        placeholder: $data.translate.companyStatus,
        enabled: false,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_input_field, _mergeProps({
        modelValue: $props.modalData.lei,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
          return $props.modalData.lei = $event;
        })
      }, $data.LEI_INPUT_PROPS, {
        enabled: false
      }), null, 16 /* FULL_PROPS */, ["modelValue"])])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}