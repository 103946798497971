import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = ["question-id", "number"];
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = ["title"];
var _hoisted_4 = ["title"];
var _hoisted_5 = {
  class: "w-7/10 mb-6 mt-2"
};
var _hoisted_6 = {
  key: 1,
  class: "questionSection"
};
var _hoisted_7 = {
  key: 10,
  class: "w-75"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$question, _$props$myInformation, _$props$myInformation2, _$props$question2;
  var _component_ai_clarify_sender = _resolveComponent("ai-clarify-sender");
  var _component_help_text = _resolveComponent("help-text");
  var _component_framework_overall = _resolveComponent("framework-overall");
  var _component_single_choice = _resolveComponent("single-choice");
  var _component_multi_choice = _resolveComponent("multi-choice");
  var _component_information_assets_choice = _resolveComponent("information-assets-choice");
  var _component_free_text_choice = _resolveComponent("free-text-choice");
  var _component_attachment_choice = _resolveComponent("attachment-choice");
  var _component_single_asset_choice = _resolveComponent("single-asset-choice");
  var _component_process_library_choice = _resolveComponent("process-library-choice");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_new_choice = _resolveComponent("new-choice");
  return $props.question ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "question-id": $props.question.id,
    number: $props.question.questionnumber,
    class: "w-full mt-12 first:mt-0 question"
  }, [_createElementVNode("div", _hoisted_2, [$props.showHeadline ? (_openBlock(), _createElementBlock("h1", {
    key: 0,
    class: _normalizeClass(["glob-h1 text-rm-text inline", {
      'text-gray-175': !$props.question.answeredDependencies
    }]),
    title: $props.question.headline
  }, _toDisplayString($props.question.headline), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true), $options.showAiClarifySender ? (_openBlock(), _createBlock(_component_ai_clarify_sender, {
    key: 1,
    context: "".concat($props.question.questionnumber, " ").concat($props.question.question, " ").concat($props.question.help),
    source: "".concat($options.projectTitle, " > ").concat($options.currentQuestion.node.title, " > ").concat($data.t.question, " ").concat($props.question.questionnumber),
    class: "inline-block ml-2"
  }, null, 8 /* PROPS */, ["context", "source"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [_createElementVNode("span", {
    title: "".concat($props.question.questionnumber, "  ").concat($props.question.question),
    class: "glob-b1 text-rm-text",
    type: "small",
    truncate: false
  }, _toDisplayString("".concat($props.question.questionnumber, "  ").concat($props.question.question)), 9 /* TEXT, PROPS */, _hoisted_4), _createElementVNode("p", _hoisted_5, [_createVNode(_component_help_text, {
    text: $props.question.help,
    class: "glob-l1 text-rm-signal-grey-dark",
    "count-truncate": 260
  }, null, 8 /* PROPS */, ["text"])])]), $options.hasOverallFramework ? (_openBlock(), _createBlock(_component_framework_overall, {
    key: 0,
    class: "mb-6",
    "framework-ids": ((_$props$question = $props.question) === null || _$props$question === void 0 || (_$props$question = _$props$question.questionobject) === null || _$props$question === void 0 ? void 0 : _$props$question.solutionIds) || []
  }, null, 8 /* PROPS */, ["framework-ids"])) : _createCommentVNode("v-if", true), $props.question.answeredDependencies ? (_openBlock(), _createElementBlock("div", _hoisted_6, [$props.question.questiontype === $data.QuestionTypes.singleChoice.id ? (_openBlock(), _createBlock(_component_single_choice, {
    key: 0,
    "read-only": $props.readOnly,
    options: $props.question.optionDetails,
    answers: $props.question.answers_options || {},
    "radio-button-focus": !$options.showFullQuestionsList,
    onAnswered: $options.getSingleAnswers
  }, null, 8 /* PROPS */, ["read-only", "options", "answers", "radio-button-focus", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.multipleChoice.id ? (_openBlock(), _createBlock(_component_multi_choice, {
    key: 1,
    "read-only": $props.readOnly,
    options: $props.question.optionDetails,
    answers: $props.question.answers_options || {},
    onAnswered: $options.getAnswers,
    onForceUpdate: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "options", "answers", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.informationAsset.id ? (_openBlock(), _createBlock(_component_information_assets_choice, {
    key: 2,
    "read-only": $props.readOnly,
    assets: $options.informationAssetsWithFilteredProcessors,
    "selected-assets": $options.filteredIAQuestion || {},
    onAnswered: $options.getIA,
    onNewAsset: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "selected-assets", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.freeText.id || $props.question.questiontype === $data.QuestionTypes.attachmentAndFreeText.id ? (_openBlock(), _createBlock(_component_free_text_choice, {
    key: 3,
    "read-only": $props.readOnly,
    "free-text": $props.question.freeText,
    onAnswered: $options.getFreeText,
    onForceUpdate: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "free-text", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.attachment.id || $props.question.questiontype === $data.QuestionTypes.attachmentAndFreeText.id ? (_openBlock(), _createBlock(_component_attachment_choice, {
    key: 4,
    "read-only": $props.readOnly,
    files: $props.question.files,
    onAnswered: $options.getFiles
  }, null, 8 /* PROPS */, ["read-only", "files", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.informationAssetSystems.id ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 5,
    "read-only": $props.readOnly || !_ctx.isSystemsEnabled,
    assets: $props.informationAssets.systems,
    "my-assets": ((_$props$myInformation = $props.myInformationAssetsByType) === null || _$props$myInformation === void 0 ? void 0 : _$props$myInformation.systems) || [],
    selected: $options.selectedSystems,
    type: $data.AssetsTypes.SYSTEMS,
    onAnswered: $options.getIA,
    onNewAsset: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.informationAssetProcessors.id ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 6,
    "read-only": $props.readOnly || !_ctx.isDataProcessorsEnabled,
    assets: $options.filteredProcessors,
    "my-assets": $options.myFilteredProcessorsIds,
    selected: $options.filteredProcessorsQuestion || [],
    type: $data.AssetsTypes.PROCESSORS,
    onAnswered: $options.getIA,
    onNewAsset: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.informationAssetControllers.id ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 7,
    "read-only": $props.readOnly || !_ctx.isDataControllersEnabled,
    assets: $props.informationAssets.controllers,
    "my-assets": ((_$props$myInformation2 = $props.myInformationAssetsByType) === null || _$props$myInformation2 === void 0 ? void 0 : _$props$myInformation2.controllers) || [],
    selected: $options.selectedControllers,
    type: $data.AssetsTypes.CONTROLLERS,
    onAnswered: $options.getIA,
    onNewAsset: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.processLibrary.id ? (_openBlock(), _createBlock(_component_process_library_choice, {
    key: 8,
    "read-only": $props.readOnly,
    selected: $props.question.processLibrary || [],
    "node-element": $props.nodeElement,
    onAnswered: $options.getAnswers,
    onForceUpdate: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "selected", "node-element", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.customInformationAsset.id ? (_openBlock(), _createBlock(_component_single_asset_choice, {
    key: 9,
    "read-only": $props.readOnly,
    assets: $options.filteredCustomIA,
    "my-assets": $options.myFilteredCustomIA,
    selected: $options.selectedCustomIA || [],
    "type-id": (_$props$question2 = $props.question) === null || _$props$question2 === void 0 || (_$props$question2 = _$props$question2.questionobject) === null || _$props$question2 === void 0 ? void 0 : _$props$question2.informationAssetActivityType,
    "activity-types": $props.activityTypes,
    type: $data.AssetsTypes.INFORMATION_ASSETS_API,
    onAnswered: $options.getIA,
    onNewAsset: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.$emit('newAsset', $event);
    }),
    onForceUpdate: _cache[12] || (_cache[12] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["read-only", "assets", "my-assets", "selected", "type-id", "activity-types", "type", "onAnswered"])) : _createCommentVNode("v-if", true), $props.question.questiontype === $data.QuestionTypes.date.id || $props.question.questiontype === $data.QuestionTypes.dateTime.id ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_date_time_picker, {
    "model-value": $options.dateTime,
    disabled: $props.readOnly,
    "plugin-options": $options.datePickerOptions,
    "delete-enabled": true,
    onValueUpdated: $options.getDate,
    onClose: _cache[13] || (_cache[13] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "plugin-options", "onValueUpdated"])])) : _createCommentVNode("v-if", true), !!$props.question.allowNewAnswerOption && ($props.question.questiontype === $data.QuestionTypes.singleChoice.id || $props.question.questiontype === $data.QuestionTypes.multipleChoice.id) && !$props.readOnly ? (_openBlock(), _createBlock(_component_new_choice, {
    key: 11,
    "read-only": $props.readOnly,
    options: $props.question.options,
    onNew: $options.addOption
  }, null, 8 /* PROPS */, ["read-only", "options", "onNew"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true);
}