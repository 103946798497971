import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  ref: "tabs",
  class: "tab-bar flex-grow overflow-x-scroll scroll-smooth"
};
var _hoisted_5 = {
  class: "flex flex-nowrap mt-8 border-b-2 border-b-rGrey-400"
};
var _hoisted_6 = {
  class: "flex items-center"
};
var _hoisted_7 = {
  class: "whitespace-nowrap"
};
var _hoisted_8 = {
  class: "flex ml-auto pl-4 border-b-2 border-b-rGrey-400 w-fit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_risma_editable_title = _resolveComponent("risma-editable-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_tabs = _resolveComponent("tabs");
  var _component_attachment = _resolveComponent("attachment");
  var _component_save_button = _resolveComponent("save-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.trafficLightSettings.value !== undefined ? (_openBlock(), _createBlock(_component_traffic_light_selector, {
    key: 0,
    "model-value": $props.trafficLightSettings.value,
    "light-type": $props.trafficLightSettings.lightType,
    "light-with-attribute": $props.trafficLightSettings.lightWithAttribute,
    "read-only": $props.trafficLightSettings.readOnly,
    size: "big",
    class: "self-center mr-3",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTrafficLight', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "light-type", "light-with-attribute", "read-only"])) : _createCommentVNode("v-if", true), $options.displayIdInTitle && ($props.id || $props.editableId) ? (_openBlock(), _createBlock(_component_risma_editable_title, {
    key: 1,
    title: $props.id + '',
    disabled: !$props.editableId,
    truncate: false,
    "max-length": $props.idMaxLength,
    "is-id": true,
    class: _normalizeClass(["max-w-220px mr-2 lining-nums", [{
      'line-through': $props.lineThroughTitle
    }]]),
    "allow-empty": $props.allowEmptyId,
    type: $props.idType,
    onTitleChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('changeId', $event);
    })
  }, null, 8 /* PROPS */, ["title", "disabled", "max-length", "class", "allow-empty", "type"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_editable_title, {
    title: $props.title,
    disabled: !$props.editableTitle,
    truncate: true,
    "allow-empty": $props.allowEmptyTitle,
    class: _normalizeClass([{
      'line-through': $props.lineThroughTitle
    }, $options.displayIdInTitle && ($props.id || $props.editableId) ? 'max-w-[calc(100%-350px)]' : 'max-w-[calc(100%-250px)]']),
    onTitleChange: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('changeTitle', $event);
    })
  }, null, 8 /* PROPS */, ["title", "disabled", "allow-empty", "class"]), $props.showCheckmark ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 2,
    icon: "check",
    height: "25",
    width: "25",
    class: "stroke-3 self-center"
  })) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "status-transition", {}, undefined, true)]), _createElementVNode("div", _hoisted_3, [$data.showTabsScroll ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["z-1 self-end flex-shrink-0 flex w-8 h-54px border-b-2 border-b-rGrey-400", $data.scrollLeft ? 'text-rGrey-800 hover:text-rGrey-900 bg-rGrey-100 hover:bg-rGrey-400 cursor-pointer' : 'text-rGrey-400 bg-none']),
    style: _normalizeStyle($data.scrollLeft ? $options.arrowShadows.left : ''),
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.scrollTabs(-100);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    width: "16",
    class: "m-auto stroke-2"
  })], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_tabs, {
    class: "-mb-0.5 flex flex-nowrap"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, function (tab, index) {
        return _openBlock(), _createBlock(_component_tab_item, _mergeProps({
          key: index,
          link: tab.url,
          selected: tab.selected,
          disabled: tab.disabled,
          ref_for: true
        }, _ctx.$attrs, {
          class: "tab-item relative"
        }), {
          default: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(tab.label), 1 /* TEXT */), tab.labelExtra ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["label-extra h-5 min-w-20px ml-2 px-1 rounded-sm text-[13px] text-center text-rGrey-800 bg-rGrey-300", {
                'text-rGrey-600': tab.disabled
              }])
            }, _toDisplayString(tab.labelExtra), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true)])];
          }),
          _: 2 /* DYNAMIC */
        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["link", "selected", "disabled"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }), $props.isAttachmentEnabled ? (_openBlock(), _createBlock(_component_attachment, {
    key: 0,
    ref: "attachment",
    "files-list": $props.filesList,
    module: $props.module,
    "module-id": $props.moduleId,
    "read-only": !$props.editableAttachments,
    "has-version-buttons": $props.hasVersionButtons,
    "single-file-load": $props.singleFileLoad,
    "has-pin": $props.attachmentHasPin,
    "has-title": $props.attachmentHasTitle,
    "type-options": $props.fileTypeOptions,
    "files-and-links-separated": $props.attachmentsFilesAndLinksSeparated,
    "file-types-enabled": $props.attachmentFileTypesEnabled,
    "edit-file-enabled": $props.attachmentEditFileEnabled,
    "scroll-left": $data.scrollLeft,
    class: "-mb-0.5",
    onUpdatingAttachments: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('updatingAttachments');
    }),
    onGetActivityData: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('getActivityData');
    }),
    onFileUpdate: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('fileUpdate', $event);
    })
  }, null, 8 /* PROPS */, ["files-list", "module", "module-id", "read-only", "has-version-buttons", "single-file-load", "has-pin", "has-title", "type-options", "files-and-links-separated", "file-types-enabled", "edit-file-enabled", "scroll-left"])) : _createCommentVNode("v-if", true)])], 512 /* NEED_PATCH */), $data.showTabsScroll ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["z-1 self-end flex-shrink-0 flex w-8 h-54px border-b-2 border-b-rGrey-400", $data.isMaxScrolled ? 'text-rGrey-400 bg-none' : 'text-rGrey-800 hover:text-rGrey-900 bg-rGrey-100 hover:bg-rGrey-400 cursor-pointer']),
    style: _normalizeStyle($data.isMaxScrolled ? '' : $options.arrowShadows.right),
    onClick: _cache[7] || (_cache[7] = function ($event) {
      return $options.scrollTabs(100);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-right",
    width: "16",
    class: "m-auto stroke-2"
  })], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [$props.saveStatus ? (_openBlock(), _createBlock(_component_save_button, {
    key: 0,
    "save-status": $props.saveStatus,
    class: "save-button h-10 self-end ml-auto mr-4 mb-2",
    onSaveStatusClicked: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('saveStatusClicked');
    })
  }, null, 8 /* PROPS */, ["save-status"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "dep-relations-control", {}, undefined, true)])])]);
}