import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import MarkdownRenderer from '@/components/Atoms/MarkdownRenderer';
import AiAssistantIcon from '@/components/Atoms/FeatherIcon/AiAssistantIcon';
export default {
  name: 'CitationModal',
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    MarkdownRenderer: MarkdownRenderer,
    AiAssistantIcon: AiAssistantIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    citation: {
      type: String,
      required: true
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      translate: {
        citation: this.$trans('Reference')
      }
    };
  }
};