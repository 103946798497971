function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import LinksDepPreview from '@/components/Organisms/Links/LinksDepPreview';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import StaticTable from '@/components/Molecules/StaticTable';
import AddContactPersonModal from '@/components/Organisms/Contracts/AddContactPersonModal';
import CompanyContactModal from '@/components/Organisms/Companies/CompanyContactModal';
import ContractPartyExternalSection from '@/components/Organisms/Contracts/ContractPartyExternalSection';
import { getTranslate } from './translate';
import { escapeHtml } from '@/utils';
import { filterArrayByListOfPropValues } from '@/utils/Utils';
import ContractRolesMixin from '@/mixins/ContractRolesMixin';
export default {
  name: 'ContractPartyExternalPreview',
  components: {
    LinksDepPreview: LinksDepPreview,
    MainWell: MainWell,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    AddContactPersonModal: AddContactPersonModal,
    CompanyContactModal: CompanyContactModal,
    ContractPartyExternalSection: ContractPartyExternalSection
  },
  mixins: [ContractRolesMixin],
  props: {
    company: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    companyContacts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    linkedCompanyContacts: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    contractParty: {
      required: true,
      type: Object,
      note: 'data object'
    },
    selectBoxOptions: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    doraEnabled: {
      type: Boolean,
      default: false
    },
    currencyOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:contact', 'create:contact', 'updateFromPreview', 'update'],
  data: function data() {
    return {
      showAddContactModal: false,
      columnsStyles: {
        delete: 'width: 50px'
      },
      sections: [],
      translate: getTranslate['ContractPartyExternalPreview']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.name, this.translate.title, this.translate.phone, this.translate.email, ''];
    },
    datasetTable: function datasetTable() {
      return this.contacts.map(function (item) {
        return {
          name: item.name,
          title: escapeHtml(item.title),
          phone: escapeHtml(item.phone),
          email: item.email,
          delete: {
            id: item.id
          }
        };
      });
    },
    contacts: function contacts() {
      var _this = this;
      return this.companyContacts.filter(function (item) {
        return _this.linkedCompanyContacts.includes(item.id);
      });
    },
    availableContacts: function availableContacts() {
      var _this2 = this;
      return this.companyContacts.filter(function (item) {
        return !_this2.linkedCompanyContacts.includes(item.id);
      });
    },
    sectionOptions: function sectionOptions() {
      return {
        typeOfServices: this.selectBoxOptions.typeOfICTService,
        substitutabilityOfICTProvider: this.selectBoxOptions.substitutabilityOfICTProvider,
        substitutabilityReason: this.selectBoxOptions.substitutabilityReason,
        existenceOfExitPlan: this.selectBoxOptions.binaryYesNo,
        possibilityOfReintegration: this.selectBoxOptions.possibilityOfReintegration,
        impactOfDiscontinuation: this.selectBoxOptions.impactOfDiscontinuation,
        alternativeIctTppIdentified: this.selectBoxOptions.yesNoNotAssessed,
        currencyOptions: this.currencyOptions
      };
    },
    preparePureSection: function preparePureSection() {
      return {
        typeOfServices: [],
        substitutabilityOfICTProvider: null,
        substitutabilityReason: null,
        lastAudit: null,
        existenceOfExitPlan: null,
        possibilityOfReintegration: null,
        impactOfDiscontinuation: null,
        alternativeIctTppIdentified: null,
        identificationOfAlternativeIctTpp: null,
        totalValue: null,
        totalValueCurrency: null
      };
    }
  },
  watch: {
    contractParty: {
      deep: true,
      immediate: true,
      handler: function handler() {
        if (this.doraEnabled) {
          this.sections = this.prepareSections();
        }
      }
    }
  },
  methods: {
    addContacts: function addContacts(ids) {
      this.$emit('update:contact', [].concat(_toConsumableArray(this.linkedCompanyContacts), _toConsumableArray(ids)));
      this.closeModal();
    },
    deleteContact: function deleteContact(id) {
      var _this3 = this;
      this.$confirm('', this.translate.areYouSureYouWantToRemoveThisActivity, function (res) {
        return res && _this3.onDeleteContactConfirm(id);
      });
    },
    onDeleteContactConfirm: function onDeleteContactConfirm(id) {
      var filteredList = this.linkedCompanyContacts.filter(function (item) {
        return item !== id;
      });
      this.$emit('update:contact', filteredList);
    },
    handleCreateContact: function handleCreateContact(contactData) {
      this.$emit('create:contact', contactData);
      this.closeModal();
    },
    closeModal: function closeModal() {
      this.showAddContactModal = false;
    },
    prepareSections: function prepareSections() {
      var _this$contractParty,
        _this$contractParty2,
        _this4 = this;
      if (!((_this$contractParty = this.contractParty) !== null && _this$contractParty !== void 0 && _this$contractParty.externalSections.length)) {
        return [_objectSpread({}, this.preparePureSection)];
      }
      return (_this$contractParty2 = this.contractParty) === null || _this$contractParty2 === void 0 ? void 0 : _this$contractParty2.externalSections.map(function (section) {
        return {
          typeOfServices: filterArrayByListOfPropValues(_this4.selectBoxOptions.typeOfICTService, section.typeOfServices, 'value'),
          substitutabilityOfICTProvider: section.substitutabilityOfICTProvider,
          substitutabilityReason: section.substitutabilityReason,
          lastAudit: section.lastAudit,
          existenceOfExitPlan: section.existenceOfExitPlan,
          possibilityOfReintegration: section.possibilityOfReintegration,
          impactOfDiscontinuation: section.impactOfDiscontinuation,
          alternativeIctTppIdentified: section.alternativeIctTppIdentified,
          identificationOfAlternativeIctTpp: section.identificationOfAlternativeIctTpp,
          totalValue: section.totalValue,
          totalValueCurrency: section.totalValueCurrency
        };
      });
    },
    prepareSectionsToSave: function prepareSectionsToSave(section) {
      var updatedSection = _objectSpread({}, section);
      Object.keys(updatedSection).forEach(function (item) {
        if (updatedSection[item] && Array.isArray(updatedSection[item])) {
          updatedSection[item] = updatedSection[item].map(function (item) {
            return item.value;
          });
        }
      });
      return updatedSection;
    },
    handleSectionUpdate: function handleSectionUpdate(section, index) {
      var _this$contractParty3;
      var updatedSection = this.prepareSectionsToSave(section, index);
      var externalSections = (_this$contractParty3 = this.contractParty) === null || _this$contractParty3 === void 0 ? void 0 : _this$contractParty3.externalSections;
      externalSections[index] = updatedSection;
      this.$emit('update', this.contractParty);
    }
  }
};