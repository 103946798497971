import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_help_text = _resolveComponent("help-text");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_help_text, {
    text: $data.textLong
  }, null, 8 /* PROPS */, ["text"]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_help_text, {
    text: $data.textShort,
    "count-truncate": 125
  }, null, 8 /* PROPS */, ["text"])]);
}