import RiskRow from '@/components/Molecules/RiskRow';
import RiskIndicator from '@/components/Atoms/RiskIndicator';
import { RiskModuleTense } from '@/constants/risks/RiskModuleTense';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RiskModule',
  introduction: 'Making a module for risk assessment',
  description: 'A component to display risk assessments. when updating the risk assessment the component will emit impactChange and likelihoodChange events.' + ' Remember when updating the risks prop, update the root array as a whole, else the component will not detect change',
  token: '<risk-module></risk-module>\n\n' + '//resembles the data from DB\n' + '                riskTestData:[\n' + '                    {id:1, module: "test", moduleId: 1, riskIndex: 0, likelihood: 2, impact: 5, rpi: 10},\n' + '                    {id:2, module: "test", moduleId: 1, riskIndex: 1, likelihood: 3, impact: 1, rpi: 3}\n' + '                ]',
  components: {
    RiskRow: RiskRow,
    RiskIndicator: RiskIndicator
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    risks: {
      required: true,
      type: Array,
      note: 'The risk data, as an array of objects, that needs to be displayed'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['likelihoodUpdate', 'impactUpdate'],
  data: function data() {
    return {
      isEditMode: false,
      riskModuleTense: RiskModuleTense,
      translate: {
        riskBeforeafter: this.$trans('Risk before/after')
      }
    };
  },
  methods: {
    onClickOutside: function onClickOutside() {
      this.isEditMode = false;
    },
    emitImpact: function emitImpact(row, event) {
      var temp = Object.assign({}, this.risks[row]);
      temp.impact = parseInt(event);
      temp.rpi = temp.impact * temp.likelihood;
      this.$emit('impactUpdate', temp);
    },
    emitLikelihood: function emitLikelihood(row, event) {
      var temp = Object.assign({}, this.risks[row]);
      temp.likelihood = parseInt(event);
      temp.rpi = temp.impact * temp.likelihood;
      this.$emit('likelihoodUpdate', temp);
    },
    riskItem: function riskItem(index) {
      var item = this.risks.filter(function (riskItem) {
        return riskItem.riskIndex === index;
      })[0];
      return typeof item !== 'undefined' ? item : {};
    },
    getrisks: function getrisks(item, key) {
      return typeof item[key] !== 'undefined' ? item[key] : 0;
    }
  }
};