// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewlist .previewlist-header[data-v-947f395a] {
  margin: 0 0 15px 0;
}
.previewlist .previewlist-header .previewlist-showstate[data-v-947f395a] {
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
  cursor: pointer;
}
.previewlist .previewlist-header .previewlist-showstate span[data-v-947f395a] {
  margin: 0 0 0 5px;
  color: #0e2954;
  font-size: 18px;
}
.fade-enter-active[data-v-947f395a],
.fade-leave-active[data-v-947f395a] {
  transition: opacity 0.3s;
}
.fade-enter-from[data-v-947f395a],
.fade-leave-to[data-v-947f395a] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
