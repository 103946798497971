import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "flex justify-between mb-1"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  var _component_document_type_modal = _resolveComponent("document-type-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.documentTypesAdministration,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.documentTypes
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.toggleCreateModal
      }, null, 8 /* PROPS */, ["onClick"])]), _createVNode(_component_static_table, {
        columns: $options.columns,
        "dataset-table": $options.dataset
      }, {
        edit: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [slotData.item !== null ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "focus:outline-none mr-5",
            onClick: function onClick($event) {
              return $options.toggleEditModal(slotData.item);
            }
          }, [_createVNode(_component_feather_icon, {
            icon: "edit",
            class: "stroke-2 text-blue-350",
            title: $data.translate.edit
          }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_4)) : (_openBlock(), _createElementBlock("span", _hoisted_5))];
        }),
        delete: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [slotData.id !== null ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "focus:outline-none mr-5",
            onClick: function onClick($event) {
              return $options.toggleDeleteModal(slotData.id, slotData.name);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "text-red-50 stroke-2 cursor-pointer",
            icon: "x",
            title: $data.translate.delete
          }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_6)) : (_openBlock(), _createElementBlock("span", _hoisted_7))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset-table"])]))])];
    }),
    _: 1 /* STABLE */
  }), $data.showDocTypeModal ? (_openBlock(), _createBlock(_component_document_type_modal, {
    key: 0,
    "document-type": _ctx.docTypeSelected,
    "all-document-types": $data.documentTypes,
    onAccept: $options.acceptDocTypeModal,
    onDismiss: $options.dismissModal
  }, null, 8 /* PROPS */, ["document-type", "all-document-types", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}