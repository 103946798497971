import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import AttachmentSelector from '@/components/Organisms/AttachmentSelector/AttachmentSelector';
import Notification from '@/components/Molecules/Notification';
import * as awarenessAPI from '@/api/compliance/awareness';
import { nl2br } from '@/utils';
export default {
  name: 'AwarenessDetails',
  token: '<awareness-details />',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    AttachmentSelector: AttachmentSelector,
    Notification: Notification
  },
  props: {
    activityType: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      isLoaded: true,
      awareness: {},
      translate: getTranslate['AwarenessDetails'](),
      showErrorNotification: false
    };
  },
  computed: {
    requestMessage: function requestMessage() {
      return nl2br(this.awareness.request.message, true);
    },
    signoffDate: function signoffDate() {
      return this.awareness.signoff.substring(0, 10);
    },
    nodeData: function nodeData() {
      return this.awareness.processNodes[0] || {};
    },
    titleUniqueId: function titleUniqueId() {
      var uId = this.nodeData.uniqueId || '';
      return uId.trim() === '' ? 'Id' : uId;
    },
    fullTitle: function fullTitle() {
      return "".concat(this.titleUniqueId, " ").concat(this.nodeData.title);
    },
    attachments: function attachments() {
      return this.nodeData.attachments || [];
    }
  },
  mounted: function mounted() {
    this.loadAll();
  },
  methods: {
    loadAll: function loadAll() {
      var _this = this;
      this.isLoaded = false;
      return awarenessAPI.getByToken(this.token).then(function (data) {
        _this.awareness = data;
        _this.isLoaded = true;
      }).catch(function (e) {
        if (e.status === 404) _this.showErrorNotification = true;
        _this.isLoaded = true;
      });
    },
    onConfirm: function onConfirm() {
      var _this2 = this;
      this.isLoaded = false;
      return awarenessAPI.acceptRequest(this.token, {}).then(function (data) {
        _this2.isLoaded = true;
        _this2.awareness = data;
      }).catch(function (e) {
        throw e;
      });
    }
  }
};