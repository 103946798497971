import { getTranslate } from './translate';
import { changePasswordLegacy as _changePasswordLegacy } from '@/api/risma/userSettings';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import InputField from '@/components/Atoms/Inputs/InputField';
import { LogoSettings } from '@/constants/admin/CustomizationSettings';
export default {
  name: 'ChangePassword',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    InputField: InputField
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      passwordData: {
        passwordCurrent: '',
        passwordNew: '',
        passwordNewRepeat: ''
      },
      LogoSettings: LogoSettings,
      translate: getTranslate['ChangePassword']()
    };
  },
  methods: {
    changePasswordLegacy: function changePasswordLegacy() {
      var _this = this;
      return _changePasswordLegacy(this.userId, this.passwordData).then(function () {
        _this.$notify({
          title: _this.translate.passwordChangedPleaseLogin
        });
        _this.$router.push('/');
      }).catch(function (error) {
        var _error$response;
        return _this.$notify({
          title: (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error) || _this.translate.somethingWentWrong,
          type: 'error'
        });
      });
    }
  }
};