import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.privacyPolicy,
    "button-ok-text": $data.translate.ok,
    "show-dismiss-button": false,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, function (item, key) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: "template-".concat(key)
        }, [_createVNode(_component_risma_title, {
          title: item.header,
          class: "mb-3",
          truncate: false,
          type: "medium"
        }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, function (smallItem, smallKey) {
          return _openBlock(), _createBlock(_component_risma_title, {
            key: "small-item-".concat(key, "-").concat(smallKey),
            title: smallItem,
            class: "mb-3",
            truncate: false,
            type: "small"
          }, null, 8 /* PROPS */, ["title"]);
        }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text"]);
}