function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import isEqual from 'lodash/isEqual';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getCustomIAModules } from '@/constants/admin/userActivityModules';
import { getAvailableUserActivityModules } from '@/utils/questionnaires';
import { userActivityDelegationFieldsToRaciTitle, userActivityControlDelegationFields, userActivityContractDelegationFields } from '@/constants/admin/userActivityFields';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
export var BulkUpdateMixin = {
  props: {
    complianceProjects: {
      type: Array,
      required: true
    },
    riskProjects: {
      type: Array,
      required: true
    }
  },
  mixins: [RaciTitleMixin],
  data: function data() {
    return {
      modules: {},
      tableData: [],
      checkGroup: [],
      selectedModules: [],
      hasNoRecordsTitle: false,
      selectAllActivities: false,
      notification: {
        type: 'error',
        message: ''
      },
      itemToUpdate: null,
      delegationFields: userActivityDelegationFieldsToRaciTitle,
      delegationControlFields: userActivityControlDelegationFields,
      delegationContractFields: userActivityContractDelegationFields
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    allModules: function allModules() {
      var allModules = [];
      for (var item in this.modules) {
        var fullName = item;
        if (this.modules[item].objType === ObjectTypes.INFORMATION_ASSET_LIMITED) {
          fullName = MODULES.COMPLIANCE_INFORMATION_ASSETS;
        }
        allModules.push(_objectSpread(_objectSpread({}, this.modules[item]), {}, {
          id: allModules.length + 1,
          option: this.modules[item].defaultLanguage ? this.modules[item].name : this.$trans(this.modules[item].name),
          fullName: fullName
        }));
      }
      return allModules;
    },
    raciTitles: function raciTitles() {
      return {
        responsibleTitle: this.responsibleTitle(true),
        accountableTitle: this.accountableTitle(true),
        consultedTitle: this.consultedTitle(true),
        informedTitle: this.informedTitle(true)
      };
    }
  }),
  mounted: function mounted() {
    this.modules = _objectSpread(_objectSpread({}, getAvailableUserActivityModules()), getCustomIAModules());
  },
  methods: {
    deleteFromPreviewModal: function deleteFromPreviewModal() {
      this.findData();
    },
    updateFromPreviewModal: function updateFromPreviewModal() {
      var _this = this;
      if (!this.itemToUpdate) return;
      var idx = this.tableData.findIndex(function (tableItem) {
        return tableItem.id === _this.itemToUpdate.id && tableItem.objType.includes(_this.itemToUpdate.objType);
      });
      if (idx === -1) return;
      if (this.sensitiveFields && this.sensitiveFields.length) {
        var sensitiveDataChanged = this.sensitiveFields.find(function (field) {
          if (!_this.itemToUpdate[field] && !_this.tableData[idx][field]) return;
          return !isEqual(_this.itemToUpdate[field], _this.tableData[idx][field]);
        });
        if (sensitiveDataChanged) {
          this.findData();
          return;
        }
      }
      this.itemToUpdate.moduleTitle = this.tableData[idx].moduleTitle;
      this.itemToUpdate.module = this.tableData[idx].module;
      this.tableData[idx] = this.itemToUpdate;
      this.itemToUpdate = null;
    },
    setTableData: function setTableData(list) {
      this.tableData = list || [];
      this.hasNoRecordsTitle = !(list && list.length);
      this.setEmptyCheckGroup();
    },
    getArrayOfPatchChunks: function getArrayOfPatchChunks(array) {
      return array.reduce(function (resultArray, item, index) {
        var chunkIndex = Math.floor(index / 10);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },
    setEmptyCheckGroup: function setEmptyCheckGroup() {
      this.checkGroup.length = this.tableData.length;
      this.checkGroup.fill(false);
      this.selectAllActivities = false;
      this.$forceUpdate();
    },
    toggleAll: function toggleAll(isChecked) {
      this.checkGroup = new Array(this.checkGroup.length).fill(isChecked);
    },
    onActivityToggle: function onActivityToggle(isChecked, index) {
      this.checkGroup[index] = isChecked;
    },
    filterBySelectedModules: function filterBySelectedModules(list, activityTypes) {
      var newList = list.slice(0);
      var selectedIAIds = [];
      this.selectedModules.forEach(function (module) {
        if (module.fullName === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
          selectedIAIds.push(module.activityTypeId);
        }
      });
      if (this.selectedModules.find(function (module) {
        return module.fullName === MODULES.INCIDENTS;
      })) {
        newList = newList.map(function (item) {
          if ([ObjectTypes.INCIDENTS_INCIDENT, ObjectTypes.INCIDENTS_INCIDENT_LIMITED].includes(item.objType)) {
            var copyIncident = _objectSpread({}, item);
            var activityType = activityTypes.find(function (item) {
              return item.id === copyIncident.activityTypeId;
            });
            if (!isFieldInOptionalFields(activityType, 'description')) {
              copyIncident.description = '';
            }
            return copyIncident;
          }
          return item;
        });
      }
      if (selectedIAIds && selectedIAIds.length) {
        newList = newList.filter(function (item) {
          return !(item.objType === ObjectTypes.INFORMATION_ASSET_LIMITED && !selectedIAIds.includes(item.activityTypeId));
        });
      }
      return newList;
    },
    getActivityIdsWithModules: function getActivityIdsWithModules(isCheckedArray, dataItems, modules) {
      var _this2 = this;
      var activities = [];
      isCheckedArray.forEach(function (item, index) {
        if (!item) {
          return;
        }
        var module = _this2.getModuleIndex(dataItems[index], modules);
        if (dataItems[index].objType === ObjectTypes.INFORMATION_ASSET_LIMITED) {
          module = MODULES.COMPLIANCE_INFORMATION_ASSETS;
        }
        activities.push({
          id: dataItems[index].id,
          module: module,
          title: _this2.getTitle(dataItems[index])
        });
      });
      return activities;
    },
    getModuleIndex: function getModuleIndex(data, modules) {
      var compareFunc = function compareFunc(item) {
        return modules[item].objType.startsWith(data.objType);
      };
      if (ObjectTypes.INFORMATION_ASSET_LIMITED.startsWith(data.objType)) {
        compareFunc = function compareFunc(item) {
          return modules[item].objType.startsWith(data.objType) && data.activityTypeId === modules[item].activityTypeId;
        };
      }
      return Object.keys(modules).find(compareFunc);
    },
    getTitle: function getTitle(item) {
      if (item.objType === ObjectTypes.CONTROLS_CONTROL_LIMITED || item.objType === ObjectTypes.CONTROLS_CONTROL) {
        return "".concat(item.cpath, " ").concat(item.title);
      }
      return item.displayHeadline || item.title || item.name;
    },
    hasEmptyFields: function hasEmptyFields(activitiesBySelectedIds) {
      if (activitiesBySelectedIds.length == 0) {
        this.notification.message = this.$trans('Choose activities');
        return true;
      }
      this.notification.message = '';
      return false;
    },
    handleSuccessfulPatch: function handleSuccessfulPatch(list) {
      this.setTableData(list);
      this.setEmptyCheckGroup();
      this.setNotificationSuccess();
      this.resetSuccessMessageAfterInterval();
    },
    handleErrorPatch: function handleErrorPatch(error, item) {
      this.notification.type = 'error';
      if (this.notification.message.indexOf(error.response.error) === -1) {
        this.notification.message = error.response.error + ' \n';
        this.notification.message += this.$trans('Found problem in:') + '\n';
      }
      this.notification.message += "".concat(this.modules[item.module].name, ":  ").concat(item.title, " \n");
    },
    resetSuccessMessageAfterInterval: function resetSuccessMessageAfterInterval() {
      var _this3 = this;
      setTimeout(function () {
        _this3.notification.message = '';
      }, this.$debounceTimeout * 2);
    },
    setNotificationSuccess: function setNotificationSuccess(text) {
      this.notification.type = 'success';
      this.notification.message = text || this.translate.activitiesWereAssigned;
    },
    findProjectTitleById: function findProjectTitleById(id, projects) {
      var project = projects.find(function (pj) {
        return +pj.id === +id;
      });
      if (project) {
        return project.title;
      }
      return '';
    },
    enhanceData: function enhanceData(items) {
      var _this4 = this;
      var result = [];
      items.forEach(function (item) {
        var _this4$allModules$fin, _this4$modules$_this;
        var moduleTitle = '';
        if (item.objType.startsWith(ObjectTypes.COMPLIANCE_NODE)) {
          moduleTitle = _this4.findProjectTitleById(item.compliance_projects_id, _this4.complianceProjects);
        } else if (item.objType.startsWith(ObjectTypes.RISK_RISK)) {
          moduleTitle = _this4.findProjectTitleById(item.projectId, _this4.riskProjects);
        }
        if (item.objType === ObjectTypes.INCIDENTS_INCIDENT) {
          item.objType = ObjectTypes.INCIDENTS_INCIDENT_LIMITED;
        }
        var module = ((_this4$allModules$fin = _this4.allModules.find(function (module) {
          return module.objType.startsWith(item.objType);
        })) === null || _this4$allModules$fin === void 0 ? void 0 : _this4$allModules$fin.fullName) || '';
        moduleTitle = moduleTitle || ((_this4$modules$_this = _this4.modules[_this4.getModuleIndex(item, _this4.modules)]) === null || _this4$modules$_this === void 0 ? void 0 : _this4$modules$_this.name) || '';
        result.push(_objectSpread(_objectSpread({}, item), {}, {
          moduleTitle: moduleTitle,
          module: module
        }));
      });
      return result;
    },
    getAttachedLine: function getAttachedLine(item) {
      var text = [];
      switch (item.objType) {
        case ObjectTypes.COMPLIANCE_NODE:
        case ObjectTypes.COMPLIANCE_NODE_LIMITED:
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE:
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER_LIMITED:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR_LIMITED:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM_LIMITED:
        case ObjectTypes.INFORMATION_ASSET:
        case ObjectTypes.INFORMATION_ASSET_LIMITED:
        case ObjectTypes.INCIDENTS_INCIDENT:
        case ObjectTypes.INCIDENTS_INCIDENT_LIMITED:
          this.setAssignedFromCustomRaciDelegation(item, text);
          break;
        case ObjectTypes.CONTRACT:
        case ObjectTypes.CONTRACT_LIMITED:
          this.setAssignedFromContractDelegation(item, text);
          break;
        case ObjectTypes.CONTROLS_CONTROL:
        case ObjectTypes.CONTROLS_CONTROL_LIMITED:
          this.setAssignedFromControlDelegation(item, text);
          break;
        case ObjectTypes.RISK_RISK:
        case ObjectTypes.RISK_RISK_LIMITED:
          this.setAssignedFromRiskDelegation(item, text);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
        case ObjectTypes.EXECUTION_INITIATIVE_LIMITED:
          this.setAssignedFromActionDelegation(item, text);
          break;
        default:
          this.setAssignedFromNormalDelegation(item, text);
      }
      text = text.join(', ');
      return text;
    },
    setAssignedFromCustomRaciDelegation: function setAssignedFromCustomRaciDelegation(data, text) {
      var _this5 = this;
      for (var item in this.delegationFields) {
        if (data[item] && data[item].filter(function (user) {
          return user == _this5.selectedUserId;
        }).length) {
          text.push(this.raciTitles[this.delegationFields[item]]);
        }
      }
    },
    setAssignedFromContractDelegation: function setAssignedFromContractDelegation(data, text) {
      var _this6 = this;
      for (var item in this.delegationContractFields) {
        if (data[item] && data[item].filter(function (user) {
          return user == _this6.selectedUserId;
        }).length) {
          var activityType = this.activityTypes.find(function (type) {
            return +type.id === +data.activityTypeId;
          });
          if (activityType) {
            text.push(activityType[this.delegationContractFields[item]]);
          }
        }
      }
    },
    setAssignedFromControlDelegation: function setAssignedFromControlDelegation(data, text) {
      var _this7 = this;
      if (data.userIds) {
        for (var item in this.delegationControlFields) {
          if (data.userIds[item] && data.userIds[item].filter(function (user) {
            return user == _this7.selectedUserId;
          }).length) {
            text.push(this.delegationControlFields[item]);
          }
        }
      }
      if (data.dailyTeam && data.dailyTeam.userIds) {
        if (data.dailyTeam.userIds.filter(function (user) {
          return user == _this7.selectedUserId;
        }).length) {
          text.push(this.translate.dailyTeam);
        }
      }
    },
    setAssignedFromActionDelegation: function setAssignedFromActionDelegation(data, text) {
      var _this8 = this;
      if (data.userIds && data.userIds.responsible) {
        if (data.userIds.responsible.filter(function (user) {
          return user == _this8.selectedUserId;
        }).length) {
          text.push(this.translate.responsible);
        }
      }
      if (data.userIds && data.userIds.notifications) {
        if (data.userIds.notifications.filter(function (user) {
          return user == _this8.selectedUserId;
        }).length) {
          text.push(this.translate.notifications);
        }
      }
      if (data.dailyTeamIds && data.dailyTeamIds.users) {
        if (data.dailyTeamIds.users.filter(function (user) {
          return user == _this8.selectedUserId;
        }).length) {
          text.push(this.translate.dailyTeamusers);
        }
      }
    },
    setAssignedFromNormalDelegation: function setAssignedFromNormalDelegation(data, text) {
      var _this9 = this;
      for (var item in this.delegationFields) {
        if (data[item] && data[item].filter(function (user) {
          return user == _this9.selectedUserId;
        }).length) {
          text.push(this[this.delegationFields[item]]());
        }
      }
    },
    setAssignedFromRiskDelegation: function setAssignedFromRiskDelegation(data, text) {
      var _data$responsibleUser,
        _this10 = this,
        _data$consultedUserId,
        _data$informedUserIds,
        _data$accountableUser;
      if (data.dailyOwner && data.dailyOwner == this.selectedUserId) {
        text.push(this.translate.dailyRiskOwner);
      }
      if (data.primaryOwner && data.primaryOwner == this.selectedUserId) {
        text.push(this.translate.primaryRiskOwner);
      }
      if ((_data$responsibleUser = data.responsibleUserIds) !== null && _data$responsibleUser !== void 0 && _data$responsibleUser.find(function (user) {
        return +user === +_this10.selectedUserId;
      })) {
        text.push(this.translate.responsible);
      }
      if ((_data$consultedUserId = data.consultedUserIds) !== null && _data$consultedUserId !== void 0 && _data$consultedUserId.find(function (user) {
        return +user === +_this10.selectedUserId;
      })) {
        text.push(this.translate.consulted);
      }
      if ((_data$informedUserIds = data.informedUserIds) !== null && _data$informedUserIds !== void 0 && _data$informedUserIds.find(function (user) {
        return +user === +_this10.selectedUserId;
      })) {
        text.push(this.translate.informed);
      }
      if ((_data$accountableUser = data.accountableUserIds) !== null && _data$accountableUser !== void 0 && _data$accountableUser.find(function (user) {
        return +user === +_this10.selectedUserId;
      })) {
        text.push(this.translate.accountable);
      }
    }
  }
};