import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  AdminActivityTypesModal: function AdminActivityTypesModal() {
    return {
      title: i18n.t('Title'),
      module: i18n.t('Module'),
      roleTitle: i18n.t('Role title'),
      optionalFields: i18n.t('Optional fields'),
      customFields: i18n.t('Custom fields'),
      fieldType: i18n.t('Field type'),
      dontShow: i18n.t("Don't show"),
      descriptionPage: i18n.t('Description page'),
      completionWindow: i18n.t('Completion window'),
      active: i18n.t('Active'),
      required: i18n.t('Required'),
      cancel: i18n.t('Cancel'),
      create: i18n.t('Create'),
      save: i18n.t('Save'),
      custom: i18n.t('Custom'),
      system: i18n.t('System'),
      dataController: i18n.t('Data controller'),
      dataProcessor: i18n.t('Data processor'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      maxLengthIs50Characters: i18n.t('Max length is 50 characters'),
      theActivityTypeWithThisTitleAlreadyExists: i18n.t('The activity type with this title already exists'),
      disabled: i18n.t('Disabled'),
      efficiencyAssessment: i18n.t('Effectiveness assessment'),
      efficiencyAssessmentRed: i18n.t('Effectiveness assessment (Red, Yellow, and Green assessment scale)'),
      extendedEfficiencyAssessment: i18n.t('Extended Effectiveness assessment “Effectiveness assessment (Red, Yellow, Green and Grey assessment scale)'),
      makeAssessmentMandatoryOnControlCompletion: i18n.t('Make assessment mandatory on control completion'),
      visibleTo: i18n.t('Visible to'),
      visibleToAll: i18n.t('Visible to all'),
      applyVisibilityToAll: i18n.t('Apply visibility to all'),
      normalUser: i18n.t('Normal user'),
      privilegedUser: i18n.t('Privileged user'),
      super: i18n.t('Super'),
      admin: i18n.t('Admin'),
      select: i18n.t('Select'),
      defaultResponsible: i18n.t('Default responsible'),
      updateActivityType: i18n.t('Update activity type'),
      createActivityType: i18n.t('Create activity type'),
      enterTitle: getTitlePlaceholder()
    };
  },
  ControlPanelSettings: function ControlPanelSettings() {
    return {
      settingsAreSaved: i18n.t('Settings are saved'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while uploading'),
      notSet: i18n.t('Not set'),
      mustBeThan: function mustBeThan(value) {
        return i18n.t('Must be %s than', [value]).toLowerCase();
      },
      lower: i18n.t('Lower'),
      higher: i18n.t('Higher')
    };
  },
  AdminActivityTypesWell: function AdminActivityTypesWell() {
    return {
      activityTypes: i18n.t('Activity types'),
      title: i18n.t('Title'),
      module: i18n.t('Module'),
      areYouSureYouWantToDeleteThisActivityType: i18n.t('Are you sure you want to delete this activity type?'),
      activityTypeDeleted: i18n.t('Activity type deleted'),
      activityTypeCreated: i18n.t('Activity type created'),
      activityTypeWasSuccessfullyUpdated: i18n.t('Activity type was successfully updated'),
      custom: i18n.t('Custom'),
      system: i18n.t('System'),
      dataProcessor: i18n.t('Data processor'),
      dataController: i18n.t('Data controller'),
      filterByModule: i18n.t('Filter by module'),
      noFiltersApplied: i18n.t('No filters applied'),
      download: i18n.t('Download')
    };
  },
  AdminBulkTabItems: function AdminBulkTabItems() {
    return {
      activities: i18n.t('Activities'),
      updateFrameworkIds: i18n.t('Update frameworks'),
      assignOrganisation: i18n.t('Assign organisation'),
      ownership: i18n.t('Ownership'),
      assignTags: i18n.t('Assign tags')
    };
  },
  AdminControlsWell: function AdminControlsWell() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      parent: i18n.t('Parent'),
      chooseParentOptional: i18n.t('Choose parent (optional)'),
      type: i18n.t('Type'),
      chooseType: i18n.t('Choose type'),
      create: i18n.t('Create'),
      noParent: i18n.t('No parent'),
      noType: i18n.t('No type'),
      edit: i18n.t('Edit'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this'),
      delete: i18n.t('Delete'),
      typeLow: i18n.t('Type').toLowerCase(),
      enterTitle: getTitlePlaceholder()
    };
  },
  AdminCustomFieldsModal: function AdminCustomFieldsModal() {
    return {
      title: i18n.t('Title'),
      module: i18n.t('Module'),
      cancel: i18n.t('Cancel'),
      save: i18n.t('Save'),
      create: i18n.t('Create'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      custom: i18n.t('Custom'),
      dataController: i18n.t('Data controller'),
      dataProcessor: i18n.t('Data processor'),
      system: i18n.t('System'),
      explanatoryText: i18n.t('Explanatory text'),
      editCustomField: i18n.t('Edit custom field'),
      createCustomField: i18n.t('Create custom field'),
      enterTitle: getTitlePlaceholder(),
      enterExplanatoryText: i18n.t('Enter explanatory text')
    };
  },
  AdminCustomFieldsWell: function AdminCustomFieldsWell() {
    return {
      customFields: i18n.t('Custom fields'),
      title: i18n.t('Title'),
      fieldType: i18n.t('Field type'),
      module: i18n.t('Module'),
      areYouSureYouWantToDeleteThisCustomField: i18n.t("Are you sure you want to delete this custom field and all it's values?"),
      fieldDeleted: i18n.t('Field deleted'),
      fieldWasCreated: i18n.t('Field was created'),
      fieldWasUpdated: i18n.t('Field was updated'),
      filterByModule: i18n.t('Filter by module'),
      noFiltersApplied: i18n.t('No filters applied')
    };
  },
  AdminNonWorkingDaysWell: function AdminNonWorkingDaysWell() {
    return {
      nonWorkingDays: i18n.t('Non-working days'),
      choose: i18n.t('Choose'),
      title: i18n.t('Title'),
      date: i18n.t('Date'),
      create: i18n.t('Create'),
      noKeepDeadlines: i18n.t('No, keep deadlines'),
      theFollowingControlsHaveDeadlineOnANonwork: i18n.t('The following controls have deadline on a non-working day'),
      doYouWantToMoveTheControlsToTheNextAvail: i18n.t('Do you want to move the controls to the next available working day?'),
      yesMoveDeadlines: i18n.t('Yes, move deadlines'),
      enterTitle: getTitlePlaceholder(),
      deleteNonWorkingDay: i18n.t('Delete non-working day'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      nonWorkingDayCreated: i18n.t('The non-working day is created'),
      nonWorkingDayDeleted: i18n.t('The non-working day was successfully deleted')
    };
  },
  AdminTagsFieldWell: function AdminTagsFieldWell() {
    return {
      title: i18n.t('Title'),
      deleteTag: i18n.t('Delete') + ' ' + i18n.t('Tag').toLowerCase(),
      areYouSureYouWantToDeleteThisTag: i18n.t('Are you sure you want to delete this tag?'),
      tagHasBeenCreated: i18n.t('Tag has been created'),
      tagHasBeenDeleted: i18n.t('Tag has been deleted'),
      tagHasBeenUpdated: i18n.t('Tag has been updated')
    };
  },
  CreationMatrixAxisModal: function CreationMatrixAxisModal() {
    return {
      title: i18n.t('Title'),
      numberOfScores: i18n.t('Number of scores'),
      select: i18n.t('Select'),
      parametersInTable: i18n.t('Parameters in table'),
      create: i18n.t('Create'),
      createNewRiskMatrixAxis: i18n.t('Create new risk matrix axis'),
      pleaseCompleteThisFormToCreateANewTableba: i18n.t('Please complete this form to create a new table-based axis.'),
      enterTitle: getTitlePlaceholder()
    };
  },
  CustomRiskFieldsWell: function CustomRiskFieldsWell() {
    return {
      areYouSure: i18n.t('Are you sure?'),
      delete: i18n.t('Delete'),
      customRiskFields: i18n.t('Custom risk fields'),
      theseAreYourOwnCustomRiskFields: i18n.t('These are your own custom risk fields'),
      notGrouped: i18n.t('Not grouped'),
      addCustomField: i18n.t('Add custom field'),
      savePeriodToChangeCustomFieldOrder: i18n.t('Save period to change custom field order'),
      reorderCustomFields: i18n.t('Reorder custom fields')
    };
  },
  RiskMatrixAxisParametersWell: function RiskMatrixAxisParametersWell() {
    return {
      riskMatrixAxisParameters: i18n.t('Risk matrix axis parameters'),
      verticalAxisLikelihood: i18n.t('Vertical axis, Likelihood'),
      select: i18n.t('Select'),
      scoresForLikelihood: i18n.t('Scores for likelihood'),
      likelihood: i18n.t('Likelihood'),
      horizontalAxisImpact: i18n.t('Horizontal axis, Impact'),
      financialImpactUnit: i18n.t('Financial impact unit'),
      denominationAndCurrencyExMioUsd: i18n.t('Denomination and currency (Ex. Mio USD)?'),
      financialImpact: i18n.t('Financial impact'),
      maxForScore: i18n.t('Max for score'),
      impact: i18n.t('Impact'),
      thirdAxisZaxis: i18n.t('Third axis (Z-axis)'),
      riskMatrixPreview: i18n.t('Risk matrix preview')
    };
  },
  SuggestedActivitiesModal: function SuggestedActivitiesModal() {
    return {
      addNew: i18n.t('Add new'),
      article: i18n.t('Article'),
      selectArticle: i18n.t('Select article'),
      chooseActivityType: i18n.t('Choose activity type'),
      choose: i18n.t('Choose'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      save: i18n.t('Save'),
      enterTitle: getTitlePlaceholder()
    };
  },
  AiTermsOfServiceModal: function AiTermsOfServiceModal() {
    return {
      termsAndConditions: i18n.t('TERMS AND CONDITIONS FOR RISMA AI PARTNER'),
      accept: i18n.t('Accept'),
      cancel: i18n.t('Cancel'),
      pleaseNoteThatTheAccept: i18n.t('Please note that the Accept button will remain disabled until you scroll through the entire text.')
    };
  },
  AiPartnerTerms: function AiPartnerTerms() {
    return {
      termsAndConditions: i18n.t('TERMS AND CONDITIONS FOR RISMA AI PARTNER'),
      definitions: i18n.t('Definitions'),
      inTheseTermsAndConditions: i18n.t('In these terms and conditions, capitalised terms shall have the same meaning as set out in the RISMA Systems - General Terms and Conditions, unless otherwise specified in these terms and conditions.'),
      theAccessToAndUse: i18n.t('The access to and use of RISMA AI Partner is subject to the below terms and conditions for RISMA AI Partner'),
      prismaAIPartnerTerms: i18n.t('RISMA AI Partner Terms and Conditions'),
      belowWitchApply: i18n.t('below which apply as supplementary and RISMA AI Partner-specific terms and conditions to the RISMA Systems - General Terms and Conditions:'),
      whenYouUseRismaAI: i18n.t('When you use RISMA AI Partner, you acknowledge and agree that:'),
      theOutputGenerated: i18n.t('The output generated may not always be accurate. It should not be relied upon as the sole source of information or as a substitute for expert advice;'),
      youAreResponsible: i18n.t('You are responsible for assessing the accuracy and relevance of the output for your specific needs, including by performing a human review before using the output generated from RISMA AI Partner in any way;'),
      youMustNotUseAnyOutput: i18n.t('You must not use any output relating to a natural or legal person in a way that could affect them, such as using the output as the basis for a decision, a processing activity, etc.;'),
      rismaAiPartnerMayGenerate: i18n.t('RISMA AI Partner may generate output that is incomplete, incorrect, offensive or that is inconsistent with the views and values of RISMA. Any references to third parties generated by RISMA AI Partner do not imply that the third parties approve of or are affiliated with RISMA.'),
      interpretationAndDispute: i18n.t('Interpretation and dispute resolution'),
      inCaseOfAnyDiscrepancies: i18n.t('In case of any discrepancies or conflict between the individual documents and the RISMA AI Partner Terms and Conditions, the RISMA AI Partner Terms and Conditions shall be read as an addendum to, and given precedence in the same order as, the RISMA Systems - General Terms andConditions.'),
      theseRismaAiPartnerTerms: i18n.t('These RISMA AI Partner Terms and Conditions shall be governed by the law and any dispute settled in accordance with the relevant provisions set out in and applicable to the Main Agreement.')
    };
  }
};