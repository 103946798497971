function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import WizardFlow from '@/components/Molecules/WizardFlow';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { generatedData } from '@/test/data/wizardFlow';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
import { sortAlphabetic } from '@/utils/sort';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getDataflowThirdPartiesTitle, getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
export default {
  components: {
    WizardFlow: WizardFlow,
    RismaButton: RismaButton
  },
  setup: function setup() {
    return {
      welcomePageState: getLocalStorageComputed('wizard_flow_kithen_welcome_disabled', 'false')
    };
  },
  data: function data() {
    return {
      selectedOptions: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.CURRENT_DATA_SUBJECT, null), DataFlowFields.CURRENT_TYPES_SELECTED, []), DataFlowFields.CURRENT_BASIS_SELECTED, {}), DataFlowFields.SYSTEMS_SELECTED, []), DataFlowFields.SYSTEMS_COMMENT, ''),
      DataFlowFields: DataFlowFields,
      data: generatedData,
      isOpened: false,
      translate: {
        welcome: this.$trans('Welcome'),
        choose: this.$trans('Choose')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    items: function items() {
      var stakeholderName = getDataflowStakeholdersTitle();
      var systemName = getDataflowThirdPartiesTitle();
      return [{
        headline: this.translate.welcome,
        icon: 'info',
        component: 'DataFlowWizardStep1',
        hasShowMessageAgain: true
      }, {
        headline: stakeholderName,
        headline2: stakeholderName,
        icon: 'user',
        component: 'DataFlowWizardStepDataSubject',
        questionsString: 'Question 2',
        isLockedNextStep: !this.selectedOptions[DataFlowFields.CURRENT_DATA_SUBJECT],
        lockMessage: "".concat(this.translate.choose, " ").concat(stakeholderName)
      }, {
        headline: 'Data type',
        headline2: 'Choose all the data types that you will be storing',
        icon: 'filter',
        questionsString: 'Question 3',
        isLockedNextStep: !this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].length,
        lockMessage: 'Choose all the data types that you will be storing',
        component: 'DataFlowWizardStepDataType'
      }, {
        headline: 'Legal basis',
        headline2: 'Choose legal basis behind data types',
        icon: 'clipboard',
        questionsString: 'Question 10a',
        lockMessage: 'Choose legal basis',
        component: 'DataFlowWizardStepLegalBasis'
      }, {
        headline: systemName,
        headline2: systemName,
        icon: 'server',
        questionsString: 'Question 27',
        isLockedNextStep: !this.selectedOptions[DataFlowFields.SYSTEMS_SELECTED].length,
        lockMessage: "".concat(this.translate.choose, " ").concat(systemName),
        component: 'DataFlowWizardStepSystems'
      }];
    }
  }),
  methods: {
    updateSelected: function updateSelected(_ref) {
      var _this = this;
      var value = _ref.value,
        field = _ref.field,
        additionalField = _ref.additionalField;
      var index;
      switch (field) {
        case DataFlowFields.CURRENT_TYPES_SELECTED:
          // adds new selected and removes option from the list
          index = this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].findIndex(function (item) {
            return item.uniqueId === value.uniqueId;
          });
          if (index === -1) {
            this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].push(value);
            this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED] = sortAlphabetic(this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED], 'text');
            this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED][value.uniqueId] = null;
            this.data[DataFlowFields.DATA_TYPE_OPTIONS] = this.data[DataFlowFields.DATA_TYPE_OPTIONS].filter(function (type) {
              return type.uniqueId !== value.uniqueId;
            });
          } else {
            this.data[DataFlowFields.DATA_TYPE_OPTIONS].push(value);
            this.data[DataFlowFields.DATA_TYPE_OPTIONS] = sortAlphabetic(this.data[DataFlowFields.DATA_TYPE_OPTIONS], 'text');
            this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].splice(index, 1);
            delete this.selectedOptions[DataFlowFields.CURRENT_BASIS_SELECTED][value.uniqueId];
          }
          break;
        case DataFlowFields.CURRENT_BASIS_SELECTED:
          if (additionalField) {
            this.selectedOptions[field][additionalField] = value;
          } else {
            var keys = Object.keys(this.selectedOptions[field]);
            keys.forEach(function (key) {
              return _this.selectedOptions[field][key] = value;
            });
          }
          break;
        default:
          this.selectedOptions[field] = value;
          break;
      }
    },
    handleUpdateStorage: function handleUpdateStorage(value) {
      this.welcomePageState = value + '';
    },
    handleAccept: function handleAccept(_ref2) {
      var messageState = _ref2.messageState;
      this.handleUpdateStorage(messageState);
      this.isOpened = false;
    }
  }
};