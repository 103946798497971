function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "glob-h2"
};
var _hoisted_2 = {
  class: "py-2 px-5"
};
var _hoisted_3 = {
  key: 0,
  class: "flex justify-between"
};
var _hoisted_4 = {
  class: "w-9/2 mr-3"
};
var _hoisted_5 = {
  class: "pb-3"
};
var _hoisted_6 = {
  class: "w-9/2"
};
var _hoisted_7 = {
  class: "pb-3"
};
var _hoisted_8 = {
  class: "pt-1"
};
var _hoisted_9 = {
  class: "mb-1.5 glob-h3-sr text-rm-text text-center"
};
var _hoisted_10 = {
  class: "min-h-40px glob-b1 text-center align-middle text-rm-text leading-10"
};
var _hoisted_11 = {
  class: "flex justify-between"
};
var _hoisted_12 = {
  class: "w-9/2 mr-3"
};
var _hoisted_13 = {
  class: "pb-3"
};
var _hoisted_14 = {
  class: "w-9/2"
};
var _hoisted_15 = {
  class: "pb-3"
};
var _hoisted_16 = {
  class: "pt-1"
};
var _hoisted_17 = {
  class: "mb-1.5 glob-h3-sr text-rm-text text-center"
};
var _hoisted_18 = {
  class: "min-h-40px glob-b1 text-center align-middle text-rm-text leading-10"
};
var _hoisted_19 = {
  class: "glob-h2 text-rm-text"
};
var _hoisted_20 = {
  key: 0,
  class: "w-full table-fixed text-center text-rm-text"
};
var _hoisted_21 = {
  class: "w-9/2 px-3 py-1 text-left glob-h3-sr"
};
var _hoisted_22 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_23 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_24 = {
  class: "px-3 py-1 glob-h3-sr"
};
var _hoisted_25 = {
  key: 0
};
var _hoisted_26 = {
  class: "px-3 py-1 glob-h3-sr"
};
var _hoisted_27 = {
  key: 0
};
var _hoisted_28 = {
  class: "glob-b1"
};
var _hoisted_29 = {
  class: "text-left px-3 py-1"
};
var _hoisted_30 = {
  class: "px-3 py-1"
};
var _hoisted_31 = {
  key: 1
};
var _hoisted_32 = {
  class: "px-3 py-1"
};
var _hoisted_33 = {
  key: 1
};
var _hoisted_34 = {
  class: "px-3 py-1"
};
var _hoisted_35 = {
  class: "px-3 py-1"
};
var _hoisted_36 = {
  key: 2,
  class: "w-full table-fixed text-center text-rm-text"
};
var _hoisted_37 = {
  class: "w-9/2 px-3 py-1 text-left glob-h3-sr"
};
var _hoisted_38 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_39 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_40 = {
  class: "px-3 py-1 glob-h3-sr"
};
var _hoisted_41 = {
  key: 0
};
var _hoisted_42 = {
  class: "glob-b1"
};
var _hoisted_43 = {
  class: "px-3 py-1 text-left"
};
var _hoisted_44 = {
  class: "px-3 py-1"
};
var _hoisted_45 = {
  class: "px-3 py-1"
};
var _hoisted_46 = {
  class: "px-3 py-1"
};
var _hoisted_47 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_48 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_49 = {
  class: "glob-b1"
};
var _hoisted_50 = {
  class: "text-left px-3 py-1"
};
var _hoisted_51 = {
  class: "px-3 py-1"
};
var _hoisted_52 = {
  class: "px-3 py-1"
};
var _hoisted_53 = {
  key: 1,
  class: "w-full table-fixed text-center text-rm-text"
};
var _hoisted_54 = {
  class: "w-9/2 px-3 py-1 text-left glob-h3-sr"
};
var _hoisted_55 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_56 = {
  key: 0
};
var _hoisted_57 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_58 = {
  key: 0
};
var _hoisted_59 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_60 = {
  class: "px-3 py-1 break-words glob-h3-sr"
};
var _hoisted_61 = {
  class: "glob-b1"
};
var _hoisted_62 = {
  class: "text-left px-3 py-1"
};
var _hoisted_63 = {
  class: "px-3 py-1"
};
var _hoisted_64 = {
  key: 0
};
var _hoisted_65 = {
  class: "px-3 py-1"
};
var _hoisted_66 = {
  key: 0
};
var _hoisted_67 = {
  class: "px-3 py-1"
};
var _hoisted_68 = {
  class: "px-3 py-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  return _openBlock(), _createBlock(_component_expansion_panel, {
    "start-expanded": $props.startExpanded
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("h2", _hoisted_1, _toDisplayString($props.row.label), 1 /* TEXT */)];
    }),
    "collapsed-header": _withCtx(function () {
      return [_createTextVNode("(" + _toDisplayString($options.collapsedHeaderLabel) + ")", 1 /* TEXT */)];
    }),
    content: _withCtx(function () {
      var _$props$rowData$inher, _$props$rowData$inher2, _$props$rowData$resid, _$props$rowData$resid2;
      return [_createElementVNode("div", _hoisted_2, [$props.inherentRisksEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        modelValue: $data.inherentConsequenceSelected,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.inherentConsequenceSelected = $event;
        }), _cache[1] || (_cache[1] = function ($event) {
          return $options.onValueChange($event, $data.RiskBaseModelProps.INHERENT_CONSEQUENCE);
        })],
        placeholder: $data.translate.select,
        options: $options.consequenceOptionsExtended,
        "un-sorted": true,
        disabled: $options.consequenceReadOnly,
        title: $options.consequenceTitle.inherent
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled", "title"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_tiny_mce_box, {
        type: "comment",
        height: "250px",
        "initial-content": (_$props$rowData$inher = $props.rowData.inherentConsequenceComment) !== null && _$props$rowData$inher !== void 0 ? _$props$rowData$inher : '',
        "read-only": $options.consequenceReadOnly,
        "always-open": !!$options.keepCommentFieldsExpanded,
        onChanged: _cache[2] || (_cache[2] = function ($event) {
          return $options.onCommentChange($event, $data.RiskBaseModelProps.INHERENT_CONSEQUENCE);
        })
      }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_single_select, {
        modelValue: $data.inherentProbabilitySelected,
        "onUpdate:modelValue": [_cache[3] || (_cache[3] = function ($event) {
          return $data.inherentProbabilitySelected = $event;
        }), _cache[4] || (_cache[4] = function ($event) {
          return $options.onValueChange($event, $data.RiskBaseModelProps.INHERENT_PROBABILITY);
        })],
        placeholder: $data.translate.select,
        "un-sorted": true,
        options: $options.probabilityOptionsExtended,
        disabled: $options.probabilityReadOnly,
        title: $options.probabilityTitle.inherent
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled", "title"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_tiny_mce_box, {
        type: "comment",
        height: "250px",
        "initial-content": (_$props$rowData$inher2 = $props.rowData.inherentProbabilityComment) !== null && _$props$rowData$inher2 !== void 0 ? _$props$rowData$inher2 : '',
        "read-only": $options.probabilityReadOnly,
        "always-open": !!$options.keepCommentFieldsExpanded,
        onChanged: _cache[5] || (_cache[5] = function ($event) {
          return $options.onCommentChange($event, $data.RiskBaseModelProps.INHERENT_PROBABILITY);
        })
      }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("h3", _hoisted_9, _toDisplayString($data.translate.inherentScore), 1 /* TEXT */), _createElementVNode("p", _hoisted_10, _toDisplayString($options.inherentScore + ''), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_single_select, {
        modelValue: $data.residualConsequenceSelected,
        "onUpdate:modelValue": [_cache[6] || (_cache[6] = function ($event) {
          return $data.residualConsequenceSelected = $event;
        }), _cache[7] || (_cache[7] = function ($event) {
          return $options.onValueChange($event, $data.RiskBaseModelProps.RESIDUAL_CONSEQUENCE);
        })],
        placeholder: $data.translate.select,
        options: $options.consequenceOptionsExtended,
        "un-sorted": true,
        disabled: $options.consequenceReadOnly,
        title: $options.consequenceTitle.residual
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled", "title"]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_tiny_mce_box, {
        type: "comment",
        height: "250px",
        "initial-content": (_$props$rowData$resid = $props.rowData.residualConsequenceComment) !== null && _$props$rowData$resid !== void 0 ? _$props$rowData$resid : '',
        "read-only": $options.consequenceReadOnly,
        "always-open": !!$options.keepCommentFieldsExpanded,
        onChanged: _cache[8] || (_cache[8] = function ($event) {
          return $options.onCommentChange($event, $data.RiskBaseModelProps.RESIDUAL_CONSEQUENCE);
        })
      }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_single_select, {
        modelValue: $data.residualProbabilitySelected,
        "onUpdate:modelValue": [_cache[9] || (_cache[9] = function ($event) {
          return $data.residualProbabilitySelected = $event;
        }), _cache[10] || (_cache[10] = function ($event) {
          return $options.onValueChange($event, $data.RiskBaseModelProps.RESIDUAL_PROBABILITY);
        })],
        placeholder: $data.translate.select,
        "un-sorted": true,
        options: $options.probabilityOptionsExtended,
        disabled: $options.probabilityReadOnly,
        title: $options.probabilityTitle.residual
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled", "title"]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_tiny_mce_box, {
        type: "comment",
        height: "250px",
        "initial-content": (_$props$rowData$resid2 = $props.rowData.residualProbabilityComment) !== null && _$props$rowData$resid2 !== void 0 ? _$props$rowData$resid2 : '',
        "read-only": $options.probabilityReadOnly,
        "always-open": !!$options.keepCommentFieldsExpanded,
        onChanged: _cache[11] || (_cache[11] = function ($event) {
          return $options.onCommentChange($event, $data.RiskBaseModelProps.RESIDUAL_PROBABILITY);
        })
      }, null, 8 /* PROPS */, ["initial-content", "read-only", "always-open"])])]), _createElementVNode("div", _hoisted_16, [_createElementVNode("h3", _hoisted_17, _toDisplayString($props.inherentRisksEnabled ? $data.translate.residualScore : $data.translate.riskScore), 1 /* TEXT */), _createElementVNode("p", _hoisted_18, _toDisplayString($options.residualScore + ''), 1 /* TEXT */)])]), _createCommentVNode(" relevant threats "), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.riskAssessmentGroups, function (group) {
        return _openBlock(), _createBlock(_component_expansion_panel, {
          key: group.title,
          "start-expanded": true,
          type: "util",
          class: "mb-2"
        }, _createSlots({
          header: _withCtx(function () {
            return [_createElementVNode("h2", _hoisted_19, _toDisplayString(group.title), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, [group.isCurrentRisk && $props.customThreatsEnabled ? {
          name: "content",
          fn: _withCtx(function () {
            return [group.items.filter(function (i) {
              return i.relevant;
            }).length ? (_openBlock(), _createElementBlock("table", _hoisted_20, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_21, _toDisplayString($data.translate.threat), 1 /* TEXT */), _createElementVNode("th", _hoisted_22, _toDisplayString($props.row.consequenceLabel), 1 /* TEXT */), _createElementVNode("th", _hoisted_23, _toDisplayString($props.row.probabilityLabel), 1 /* TEXT */), _createElementVNode("th", _hoisted_24, [!$props.readOnly ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString($data.translate.edit), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("th", _hoisted_26, [!$props.readOnly ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString($data.translate.discardThreat), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])])]), _createElementVNode("tbody", _hoisted_28, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items.filter(function (i) {
              return i.relevant;
            }), function (item) {
              return _openBlock(), _createElementBlock("tr", {
                key: item.id
              }, [_createElementVNode("td", _hoisted_29, [_createElementVNode("span", {
                class: _normalizeClass({
                  'line-through': !item.relevant
                })
              }, _toDisplayString($options.getThreatName(item)), 3 /* TEXT, CLASS */)]), _createElementVNode("td", _hoisted_30, [$data.editingThreatsIds.includes(item.id) ? (_openBlock(), _createBlock(_component_single_select, {
                key: 0,
                "model-value": item.consequence,
                placeholder: "0",
                options: $options.consequenceOptionsCustom,
                "un-sorted": true,
                disabled: $options.consequenceReadOnly || !item.relevant,
                class: "max-w-100px ml-auto mr-auto",
                "onUpdate:modelValue": function onUpdateModelValue($event) {
                  return $options.updateCustomThreat(_objectSpread(_objectSpread({}, item), {}, {
                    impact: $event
                  }));
                }
              }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(item.consequence), 1 /* TEXT */))]), _createElementVNode("td", _hoisted_32, [$data.editingThreatsIds.includes(item.id) ? (_openBlock(), _createBlock(_component_single_select, {
                key: 0,
                "model-value": item.probability,
                placeholder: "0",
                options: $options.probabilityOptionsCustom,
                "un-sorted": true,
                disabled: $options.consequenceReadOnly || !item.relevant,
                class: "max-w-100px ml-auto mr-auto",
                "onUpdate:modelValue": function onUpdateModelValue($event) {
                  return $options.updateCustomThreat(_objectSpread(_objectSpread({}, item), {}, {
                    likelihood: $event
                  }));
                }
              }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(item.probability), 1 /* TEXT */))]), _createElementVNode("td", _hoisted_34, [!$props.readOnly ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [!$data.editingThreatsIds.includes(item.id) ? (_openBlock(), _createBlock(_component_feather_icon, {
                key: 0,
                class: "stroke-2 m-auto text-blue-750 cursor-pointer",
                icon: "edit",
                onClick: function onClick($event) {
                  return $options.toggleEditingThreat(item.id);
                }
              }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createBlock(_component_feather_icon, {
                key: 1,
                class: "stroke-2 m-auto text-green-150 cursor-pointer",
                icon: "check",
                onClick: function onClick($event) {
                  return $options.toggleEditingThreat(item.id);
                }
              }, null, 8 /* PROPS */, ["onClick"]))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("td", _hoisted_35, [!$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
                key: 0,
                class: _normalizeClass(["stroke-2 m-auto cursor-pointer", !item.relevant ? 'text-gray-175' : 'text-red-50']),
                icon: !item.relevant ? 'refresh-cw' : 'x',
                onClick: function onClick($event) {
                  return $options.toggleRelevant(item);
                }
              }, null, 8 /* PROPS */, ["class", "icon", "onClick"])) : _createCommentVNode("v-if", true)])]);
            }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), $options.discardedThreatsPresent ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "flex items-center mt-5 cursor-pointer",
              onClick: _cache[12] || (_cache[12] = function ($event) {
                return $data.showDiscarded = !$data.showDiscarded;
              })
            }, [_createVNode(_component_risma_title, {
              title: _ctx.$trans($data.showDiscarded ? 'Hide discarded threats' : 'Show discarded threats'),
              type: "medium"
            }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
              class: "ml-2 stroke-2",
              icon: $data.showDiscarded ? 'chevron-up' : 'chevron-down'
            }, null, 8 /* PROPS */, ["icon"])])) : _createCommentVNode("v-if", true), $data.showDiscarded && group.items.filter(function (i) {
              return !i.relevant;
            }).length ? (_openBlock(), _createElementBlock("table", _hoisted_36, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_37, _toDisplayString($data.translate.threat), 1 /* TEXT */), _createElementVNode("th", _hoisted_38, _toDisplayString($props.row.consequenceLabel), 1 /* TEXT */), _createElementVNode("th", _hoisted_39, _toDisplayString($props.row.probabilityLabel), 1 /* TEXT */), _cache[14] || (_cache[14] = _createElementVNode("th", {
              class: "px-3 py-1"
            }, null, -1 /* HOISTED */)), _createElementVNode("th", _hoisted_40, [!$props.readOnly ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString($data.translate.restoreThreat), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])])]), _createElementVNode("tbody", _hoisted_42, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items.filter(function (i) {
              return !i.relevant;
            }), function (item) {
              return _openBlock(), _createElementBlock("tr", {
                key: item.id
              }, [_createElementVNode("td", _hoisted_43, [_createElementVNode("span", {
                class: _normalizeClass({
                  'line-through': !item.relevant
                })
              }, _toDisplayString($options.getThreatName(item)), 3 /* TEXT, CLASS */)]), _createElementVNode("td", _hoisted_44, _toDisplayString(item.consequence), 1 /* TEXT */), _createElementVNode("td", _hoisted_45, _toDisplayString(item.probability), 1 /* TEXT */), _cache[15] || (_cache[15] = _createElementVNode("td", {
                class: "px-3 py-1"
              }, null, -1 /* HOISTED */)), _createElementVNode("td", _hoisted_46, [!$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
                key: 0,
                class: _normalizeClass(["stroke-2 m-auto cursor-pointer", !item.relevant ? 'text-gray-175' : 'text-red-50']),
                icon: !item.relevant ? 'refresh-cw' : 'x',
                onClick: function onClick($event) {
                  return $options.toggleRelevant(item);
                }
              }, null, 8 /* PROPS */, ["class", "icon", "onClick"])) : _createCommentVNode("v-if", true)])]);
            }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)];
          }),
          key: "0"
        } : {
          name: "content",
          fn: _withCtx(function () {
            return [group.isThreat ? (_openBlock(), _createElementBlock("table", {
              key: 0,
              class: _normalizeClass(["table-fixed text-center text-rm-text", $options.depRelationEnabled && $options.isPreview ? 'w-full' : 'w-full lg:w-[72.7%]'])
            }, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
              class: _normalizeClass(["px-3 py-1 text-left glob-h3-sr", $options.depRelationEnabled && $options.isPreview ? 'w-9/2' : 'w-9/2 lg:w-[62%]'])
            }, _toDisplayString(group.tableHeadTitle || $data.translate.threat), 3 /* TEXT, CLASS */), _createElementVNode("th", _hoisted_47, _toDisplayString($props.row.consequenceLabel), 1 /* TEXT */), _createElementVNode("th", _hoisted_48, _toDisplayString($props.row.probabilityLabel), 1 /* TEXT */)])]), _createElementVNode("tbody", _hoisted_49, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, function (item) {
              return _openBlock(), _createElementBlock("tr", {
                key: item.id
              }, [_createElementVNode("td", _hoisted_50, _toDisplayString($options.getThreatName(item)), 1 /* TEXT */), _createElementVNode("td", _hoisted_51, [_createElementVNode("span", null, _toDisplayString(item.consequence), 1 /* TEXT */)]), _createElementVNode("td", _hoisted_52, [_createElementVNode("span", null, _toDisplayString(item.probability), 1 /* TEXT */)])]);
            }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */)) : (_openBlock(), _createElementBlock("table", _hoisted_53, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_54, _toDisplayString(group.tableHeadTitle || $data.translate.threat), 1 /* TEXT */), _createElementVNode("th", _hoisted_55, [$props.inherentRisksEnabled ? (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString($options.consequenceTitle.inherent), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("th", _hoisted_57, [$props.inherentRisksEnabled ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString($options.probabilityTitle.inherent), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("th", _hoisted_59, _toDisplayString($options.consequenceTitle.residual), 1 /* TEXT */), _createElementVNode("th", _hoisted_60, _toDisplayString($options.probabilityTitle.residual), 1 /* TEXT */)])]), _createElementVNode("tbody", _hoisted_61, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, function (item) {
              return _openBlock(), _createElementBlock("tr", {
                key: item.id
              }, [_createElementVNode("td", _hoisted_62, [$options.isPreview ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.url,
                class: "hover:underline"
              }, {
                default: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)];
                }),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])) : (_openBlock(), _createBlock(_component_preview_modal_link, {
                key: 1,
                activity: item,
                class: "hover:underline",
                onUpdated: _cache[13] || (_cache[13] = function ($event) {
                  return _ctx.$emit('updateUnderlyingRisk', $event);
                })
              }, {
                default: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)];
                }),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]))]), _createElementVNode("td", _hoisted_63, [$props.inherentRisksEnabled ? (_openBlock(), _createElementBlock("span", _hoisted_64, _toDisplayString(item.inherentConsequence), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("td", _hoisted_65, [$props.inherentRisksEnabled ? (_openBlock(), _createElementBlock("span", _hoisted_66, _toDisplayString(item.inherentProbability), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("td", _hoisted_67, _toDisplayString(item.residualConsequence), 1 /* TEXT */), _createElementVNode("td", _hoisted_68, _toDisplayString(item.residualProbability), 1 /* TEXT */)]);
            }), 128 /* KEYED_FRAGMENT */))])]))];
          }),
          key: "1"
        }]), 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["start-expanded"]);
}