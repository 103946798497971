function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { MODULES } from '@/constants/modules';
import { getTranslate } from './translate';
export default {
  name: 'LinkedCompany',
  description: 'Component contains a link to unlinking of the company and full info about the linked company',
  components: {
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    FeatherIcon: FeatherIcon
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    company: {
      type: Object,
      required: true,
      deafult: function deafult() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    isRecentlyLinked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['unlink', 'edit:company', 'update:company'],
  data: function data() {
    return {
      isOpened: this.isRecentlyLinked,
      translate: getTranslate['LinkedCompany']()
    };
  },
  computed: {
    companyUrl: function companyUrl() {
      if (!this.company) return '';
      return "/company/".concat(this.company.id, "/description");
    },
    companyTrafficLight: function companyTrafficLight() {
      return this.getTrafficLightLegendForModule(MODULES.COMPANY, this.company.vatStatus);
    }
  },
  methods: _objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightLegendForModule']))
};