import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_feather_icon, {
    key: 1,
    class: "cursor-pointer text-blue-750 inline-block",
    icon: "download",
    onClick: $options.downloadFile
  }, null, 8 /* PROPS */, ["onClick"]))]);
}