var _OptionalFieldsLabels;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var OptionalFields = {
  DESCRIPTION: 'description',
  STARTDATE: 'startDate',
  EXPIRATIONDATE: 'expirationDate',
  NOTIFICATION_UPON_EXPIRATION_DATE: 'notificationUponExpirationDate',
  RENEWAL_DEADLINE: 'renewalDeadline',
  NOTIFICATION_UPON_RENEWAL_DEADLINE: 'notificationUponRenewalDeadline',
  RENEWAL_AMOUNT: 'renewalAmount',
  TERMINATION_DEADLINE: 'terminationDeadline',
  NOTIFICATION_UPON_TERMINATION_DEADLINE: 'notificationUponTerminationDeadline',
  AUTO_RENEWAL: 'autoRenewal',
  AMOUNT: 'amount',
  PAYMENT_INTERVAL: 'paymentInterval',
  PAYMENT_START_MONTH: 'paymentIntervalStartMonth',
  CONTRACT_VERSION: 'contractVersion',
  STATUS_CHANGENOTE: 'statusChangeNote'
};
export var OptionalFieldsLabels = (_OptionalFieldsLabels = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_OptionalFieldsLabels, OptionalFields.DESCRIPTION, 'Description'), OptionalFields.STARTDATE, 'Start date'), OptionalFields.EXPIRATIONDATE, 'Expiration date'), OptionalFields.NOTIFICATION_UPON_EXPIRATION_DATE, 'Notification upon expiration date'), OptionalFields.RENEWAL_DEADLINE, 'Renewal deadline'), OptionalFields.NOTIFICATION_UPON_RENEWAL_DEADLINE, 'Notification upon renewal deadline'), OptionalFields.RENEWAL_AMOUNT, 'Renewal amount'), OptionalFields.TERMINATION_DEADLINE, 'Commitment deadline'), OptionalFields.NOTIFICATION_UPON_TERMINATION_DEADLINE, 'Notification upon commitment deadline'), OptionalFields.AUTO_RENEWAL, 'Auto renewal'), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_OptionalFieldsLabels, OptionalFields.AMOUNT, 'Amount'), OptionalFields.PAYMENT_INTERVAL, 'Payment interval'), OptionalFields.PAYMENT_START_MONTH, 'Payment start month'), OptionalFields.CONTRACT_VERSION, 'Contract version'), OptionalFields.STATUS_CHANGENOTE, 'Status change note'));
export var RelatedOptionalFields = _defineProperty(_defineProperty(_defineProperty({}, OptionalFields.EXPIRATIONDATE, OptionalFields.NOTIFICATION_UPON_EXPIRATION_DATE), OptionalFields.RENEWAL_DEADLINE, OptionalFields.NOTIFICATION_UPON_RENEWAL_DEADLINE), OptionalFields.TERMINATION_DEADLINE, OptionalFields.NOTIFICATION_UPON_TERMINATION_DEADLINE);