function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getTranslate } from './translate';
import { getAllWelcomePages, createWelcomePage, updateWelcomePage as _updateWelcomePage } from '@/api/admin/welcomePage';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import InputField from '@/components/Atoms/Inputs/InputField';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'RiskAdminUserGuide',
  token: '<risk-admin-user-guide></risk-admin-user-guide>',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaToggle: RismaToggle,
    TinyMceBox: TinyMceBox,
    InputField: InputField,
    ButtonsRow: ButtonsRow
  },
  props: {
    projectId: {
      type: [String, Number],
      required: true,
      note: ''
    }
  },
  data: function data() {
    return {
      isUpdating: false,
      showUserGuideEdit: false,
      userGuide: {
        title: '',
        content: '',
        active: false,
        id: null
      },
      lastSavedGuide: {
        title: '',
        content: '',
        active: false
      },
      translate: getTranslate['RiskAdminUserGuide']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['riskAccessLevelByProjectId'])), {}, {
    isEditable: function isEditable() {
      return this.riskAccessLevelByProjectId(this.projectId) > UserLevels.PRIVILEGED;
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.cancel,
        type: 'util',
        clickEvent: this.cancelChanges,
        disabled: this.isUpdating
      }, {
        text: this.translate.save,
        type: 'save',
        clickEvent: this.saveChanges,
        disabled: this.isUpdating
      }];
    }
  }),
  mounted: function mounted() {
    this.getRiskAssessmentGuide();
  },
  methods: {
    getRiskAssessmentGuide: function getRiskAssessmentGuide() {
      var _this = this;
      return getAllWelcomePages().then(function (_ref) {
        var _list$find, _guide$active;
        var list = _ref.list;
        var guide = (_list$find = list.find(function (_ref2) {
          var rProjectsId = _ref2.rProjectsId;
          return +rProjectsId === +_this.projectId;
        })) !== null && _list$find !== void 0 ? _list$find : {};
        _this.userGuide.name = guide.name || _this.translate.userGuide;
        _this.userGuide.contentHtml = guide.contentHtml || '';
        _this.userGuide.id = guide.id;
        _this.userGuide.active = (_guide$active = guide.active) !== null && _guide$active !== void 0 ? _guide$active : false;
        _this.updateLatestSavedGuide();
      });
    },
    updateWelcomePage: function updateWelcomePage() {
      var _this2 = this;
      var updateWithLastChanges = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var dataObject = updateWithLastChanges ? this.lastSavedGuide : this.userGuide;
      var promise = dataObject.id ? _updateWelcomePage(dataObject.id, dataObject.contentHtml, dataObject.name, this.userGuide.active, this.projectId) : createWelcomePage(dataObject.contentHtml, dataObject.name, this.userGuide.active, this.projectId);
      this.isUpdating = true;
      return promise.then(function () {
        _this2.updateLatestSavedGuide();
        _this2.isUpdating = false;
      });
    },
    updateLatestSavedGuide: function updateLatestSavedGuide() {
      this.lastSavedGuide.title = this.userGuide.name;
      this.lastSavedGuide.content = this.userGuide.contentHtml;
      this.lastSavedGuide.active = this.userGuide.active;
      this.lastSavedGuide.id = this.userGuide.id;
    },
    toggleUserGuideEdit: function toggleUserGuideEdit() {
      this.showUserGuideEdit = !this.showUserGuideEdit;
    },
    contentChanged: function contentChanged(rawData) {
      this.userGuide.contentHtml = rawData;
    },
    saveChanges: function saveChanges() {
      this.toggleUserGuideEdit();
      this.updateWelcomePage();
    },
    cancelChanges: function cancelChanges() {
      this.userGuide.name = this.lastSavedGuide.title;
      this.userGuide.contentHtml = this.lastSavedGuide.content;
      this.toggleUserGuideEdit();
    },
    toggleUserGuideActive: function toggleUserGuideActive() {
      var updateWithLastChanges = !!this.showUserGuideEdit;
      this.updateWelcomePage(updateWithLastChanges);
    }
  }
};