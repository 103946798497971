import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { getReportById } from '@/api/compliance/processtree';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'ProcessTreeThinReport',
  description: 'RismaTable view type of the Process tree report',
  token: '<process-tree-thin-report />',
  components: {
    RismaTable: RismaTable
  },
  props: {
    processTreeId: {
      type: [String, Number],
      required: true
    }
  },
  data: function data() {
    return {
      stateLocal: 'process-tree-report-risma-table-key',
      reportType: ReportTypes.PROCESS_LIBRARY_THIN
    };
  },
  methods: {
    getDataflowsBE: function getDataflowsBE(params) {
      return getReportById(this.processTreeId, "&".concat(params));
    }
  }
};