import i18n from '@/i18n';
export var getTranslate = {
  QuestionnaireStats: function QuestionnaireStats() {
    return {
      questionnaireStats: i18n.t('Questionnaire stats'),
      questionCount: i18n.t('Question count'),
      questionsAnswered: i18n.t('Questions answered'),
      questionnaireScore: i18n.t('Questionnaire score'),
      assessmentScoring: i18n.t('Assessment scoring')
    };
  },
  RismaModulesRightsPicker: function RismaModulesRightsPicker() {
    return {
      noAccess: i18n.t('No access'),
      normal: i18n.t('Normal'),
      privileged: i18n.t('Privileged'),
      super: i18n.t('Super'),
      locked: i18n.t('Locked'),
      admin: i18n.t('Admin')
    };
  },
  RismaUserRightsPicker: function RismaUserRightsPicker() {
    return {
      noAccess: i18n.t('No access'),
      normal: i18n.t('Normal'),
      privileged: i18n.t('Privileged'),
      super: i18n.t('Super'),
      admin: i18n.t('Admin'),
      locked: i18n.t('Locked'),
      supervisor: i18n.t('Supervisor'),
      unassignAsSupervisor: i18n.t('Unassign as Supervisor'),
      somethingWentWrongUserWasNotUpdated: i18n.t('Something went wrong. User was not updated')
    };
  },
  TodoList: function TodoList() {
    return {
      areYouSure: i18n.t('Are you sure?'),
      chooseAction: i18n.t('Choose action'),
      andAllUnderlyingActionsWillBeApproved: i18n.t('and all underlying actions will be approved'),
      approvedBy: i18n.t('Approved by'),
      approve: i18n.t('Approve')
    };
  }
};