// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
input[data-v-0ee924e6] {
  height: 20px;
  width: 20px;
  margin: 0 calc(0.5rem + 2px) 0 2px;
  border: 1px solid #0e2954 !important;
  -webkit-appearance: none;
  position: relative;
}
input[data-v-0ee924e6]:checked:after {
  color: #0e2954 !important;
  content: "✔";
  font-size: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
