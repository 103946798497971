var btnTypes = ['util', 'save', 'danger', 'secondary'];
var btnFuncTypes = ['button', 'reset', 'submit'];
export default {
  name: 'RismaButton',
  introduction: 'buttons!',
  description: "A button with the risma styling.\n        The button can be any type of button via the function prop.\n        The risma-button component also has a slot for potential icons.",
  token: "<risma-button text=\"Save\" type=\"save\" @click=\"fn\">\n        <feather-icon icon=\"save\" />\n        </risma-button>",
  props: {
    text: {
      type: String,
      required: true,
      note: 'the text that should appear on the button'
    },
    title: {
      type: String,
      default: null,
      note: 'Mouse-over title for button'
    },
    type: {
      type: String,
      default: 'util',
      validator: function validator(type) {
        return btnTypes.indexOf(type) !== -1;
      },
      note: "The type of the button. This is used to control styling. Possible values: ".concat(btnTypes.join(', '))
    },
    func: {
      type: String,
      default: 'button',
      validator: function validator(type) {
        return btnFuncTypes.indexOf(type) !== -1;
      },
      note: "Corresponds to a button tags normal type prop. Possible values: ".concat(btnFuncTypes.join(', '))
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Bool to determine if the button should be disabled or not.'
    },
    noMargins: {
      type: Boolean,
      default: null,
      required: false
    },
    isCustomButtonSize: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if button needs custom size (w-fit disables override by classes, only css styles, without this prop'
    }
  },
  emits: ['click'],
  methods: {
    buttonClicked: function buttonClicked(e) {
      if (this.disabled) return;
      this.$emit('click', e);
    }
  }
};