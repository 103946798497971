import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "pb-4 flex justify-between"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  class: "focus:outline-none cursor-default"
};
var _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_risk_management_modal = _resolveComponent("risk-management-modal");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $props.title
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.toggleModal && $options.toggleModal.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "ml-auto stroke-3 text-blue-350 cursor-pointer"
      })])]), _createVNode(_component_static_table, {
        columns: $props.columns,
        "dataset-table": $props.dataset
      }, {
        edit: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("button", {
            class: "focus:outline-none",
            onClick: function onClick($event) {
              return $options.selectItem(slotData);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "text-blue-350 stroke-2 cursor-pointer",
            icon: "edit"
          })], 8 /* PROPS */, _hoisted_2)];
        }),
        delete: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [+slotData.risks ? (_openBlock(), _createElementBlock("button", _hoisted_3, [_createVNode(_component_feather_icon, {
            icon: "x",
            class: "stroke-2 text-gray-620"
          })])) : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "focus:outline-none",
            onClick: function onClick($event) {
              return $options.openConfirmation(slotData);
            }
          }, [_createVNode(_component_feather_icon, {
            icon: "x",
            class: "stroke-2 text-red-50 cursor-pointer"
          })], 8 /* PROPS */, _hoisted_4))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset-table"]), $data.showModal ? (_openBlock(), _createBlock(_component_risk_management_modal, {
        key: 0,
        data: $data.selectedItem,
        "generic-data": $options.genericData,
        onDismiss: $options.dismissModal
      }, null, 8 /* PROPS */, ["data", "generic-data", "onDismiss"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}