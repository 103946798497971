// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-right.complete[data-v-079d37b3]::after {
  --tw-bg-opacity: 1;
  background-color: rgb(22 222 191 / var(--tw-bg-opacity));
}
.arrow-right[data-v-079d37b3]::after {
  content: "";
  position: absolute;
  background: red;
  top: 30px;
  left: calc(50% + 32px);
  width: calc(50% - 32px);
  height: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}
.arrow-right.right-spacing[data-v-079d37b3]::after {
  left: calc(50% + 28px);
  width: calc(50% - 28px);
}
.arrow-left.previous-complete[data-v-079d37b3]::before {
  --tw-bg-opacity: 1;
  background-color: rgb(22 222 191 / var(--tw-bg-opacity));
}
.arrow-left[data-v-079d37b3]::before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0%;
  width: calc(50% - 32px);
  height: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
