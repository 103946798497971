import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mb-6 pb-6 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [$props.riskTypes.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_single_select, {
    title: $options.riskTypeListTitle,
    options: $options.riskTypeOptions,
    "model-value": $props.risk.riskTypeId,
    placeholder: $options.riskTypeListTitle,
    disabled: $props.disabled,
    "un-sorted": true,
    "onUpdate:modelValue": $options.riskTypeChanged
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "disabled", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true)]);
}