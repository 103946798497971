function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import NotificationPopUp from '@/components/Atoms/NotificationPopUp';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  components: {
    NotificationPopUp: NotificationPopUp,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      notificationData: null
    };
  },
  methods: {
    handleInstantNotification: function handleInstantNotification(data) {
      var _this = this;
      this.notificationData = null;
      this.$nextTick(function () {
        return _this.setNotificationData(data);
      });
    },
    setNotificationData: function setNotificationData(data) {
      this.notificationData = _objectSpread({}, data);
    },
    dismissNotificationPopUp: function dismissNotificationPopUp() {
      this.notificationData = null;
    },
    showPopUpFunction: function showPopUpFunction(state) {
      switch (state) {
        case 'normal':
          this.handleInstantNotification({
            title: this.$trans('Your activity has been created'),
            url: '22 Some activity name'
          });
          break;
        case 'superLarge':
          this.handleInstantNotification({
            title: 'This is a super large title that supposed to break popup, some more random text written here, some more random text written here',
            url: 'This is a super large url that supposed to break popup'
          });
          break;
        case 'large':
          this.handleInstantNotification({
            title: 'This is a large title that supposed to break popup',
            url: 'This is a large url that supposed to break popup'
          });
          break;
        case 'small':
          this.handleInstantNotification({
            title: 'tiny title',
            url: 'tiny url'
          });
          break;
        case 'error':
          this.handleInstantNotification({
            title: 'error title',
            type: 'error'
          });
          break;
      }
    }
  }
};