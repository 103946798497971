import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex -mb-1.5px"
};
var _hoisted_3 = {
  class: "border border-gray-350 bg-zinc-50 p-3"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_notification = _resolveComponent("notification");
  var _component_router_view = _resolveComponent("router-view");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tabItems, function (tab, i) {
    return _openBlock(), _createBlock(_component_light_tab_item, {
      key: i,
      to: $options.tabUrlPrefix + tab.module,
      "is-exact": true,
      "background-class": "bg-zinc-50"
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(tab.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_3, [$data.error != '' ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", {
        innerHTML: $data.error
      }, null, 8 /* PROPS */, _hoisted_4)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.success != '' ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "success"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", {
        innerHTML: $data.success
      }, null, 8 /* PROPS */, _hoisted_5)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_router_view, {
    "preview-data": $data.importedData,
    "preview-fields": $data.previewModuleFields,
    "file-types-allowed": $options.fileTypesAllowed,
    onLoadData: $options.onLoad,
    onError: _cache[0] || (_cache[0] = function ($event) {
      return $data.error = $event;
    })
  }, null, 8 /* PROPS */, ["preview-data", "preview-fields", "file-types-allowed", "onLoadData"]), $data.uploading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : _createCommentVNode("v-if", true)])]);
}