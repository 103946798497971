import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "switch-label switch-left"
};
var _hoisted_2 = {
  key: 1,
  class: "switch-label switch-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'switch--on': $options.state,
      'switch--labels': $props.isLabelsShow,
      disabled: $props.disabled
    }, "switch switch--between"]),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.switchState && $options.switchState.apply($options, arguments);
    })
  }, [$props.isLabelsShow ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.options[0].name), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "switch-body"
  }, [_createElementVNode("i", {
    class: "switch-icon switch-icon-switch"
  })], -1 /* HOISTED */)), $props.isLabelsShow ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.options[1].name), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}