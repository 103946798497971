// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-filters .risk-filters-content[data-v-23236246] {
  display: flex;
  flex-wrap: wrap;
}
.risk-filters .risk-filters-content .risk-filters-section[data-v-23236246] {
  margin: 0 12px 12px 12px;
  min-width: 220px;
}
.risk-filters .risk-filters-content .risk-filters-section.risk-filters-section-orgs[data-v-23236246] {
  min-width: 450px;
}
.risk-filters .risk-filters-content .risk-filters-section.risk-filters-section-orgs[data-v-23236246]  .multiselect__tags {
  border: none;
}
.risk-filters .risk-filters-content .risk-filters-section.risk-filters-section-prisk[data-v-23236246] {
  min-width: 300px;
}
.risk-filters .risk-filters-content .risk-filters-section.risk-filters-section-prisk[data-v-23236246]  .multiselect__tags {
  border: none;
}
.risk-filters .risk-filters-content .risk-filters-section.risk-filters-section-tags[data-v-23236246]  .multiselect__tags {
  border: none;
}
.risk-filters .risk-filters-content .risk-filters-section[data-v-23236246]  h3.medium {
  font-weight: 600;
}
.risk-filters .risk-filters-content .risk-filters-section[data-v-23236246]  .single-select .single-select-reset {
  z-index: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
