import { UserLevels } from '@/constants/UserLevels';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { userHasAccessByModule } from '@/utils/access';
export default {
  name: 'RowOfRights',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    accessLevel: {
      required: true,
      type: Number
    },
    title: {
      required: true,
      type: String
    },
    cellClasses: {
      required: false,
      type: String,
      default: ''
    },
    usersData: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    module: {
      required: true,
      type: String
    },
    projectId: {
      required: false,
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      rightsList: [UserLevels.NORMAL, UserLevels.PRIVILEGED, UserLevels.SUPER, UserLevels.ADMIN],
      userHasAccessByModule: userHasAccessByModule
    };
  },
  computed: {
    userIcons: function userIcons() {
      var _this = this;
      return this.usersData.map(function (user) {
        var hasAccess = userHasAccessByModule(user, _this.module, _this.accessLevel, _this.projectId);
        return {
          icon: hasAccess ? 'check' : 'x',
          iconClasses: hasAccess ? _this.featherIconClasses.check : _this.featherIconClasses.x
        };
      });
    },
    featherIconClasses: function featherIconClasses() {
      return {
        check: 'mx-auto text-white bg-green-150 rounded-full stroke-3 p-1',
        x: 'mx-auto text-white bg-red-610 rounded stroke-3 p-1'
      };
    }
  }
};