function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import TagItem from '@/components/Atoms/Containers/TagItem';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { objectToQueryString } from '@/api/httpUtils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var DATA_FIELDS = {
  TITLE: 'search',
  ORGANISATIONS: 'organisations',
  RESPONSIBLE: 'responsible',
  TAGS: 'tags',
  ACTIVITY_TYPE: 'activityTypes'
};
export default {
  name: 'MiniTreeFilters',
  components: {
    InputField: InputField,
    RismaSelect: RismaSelect,
    TagItem: TagItem,
    RismaButton: RismaButton
  },
  mixins: [RaciTitleMixin],
  props: {
    filterOptionsData: {
      required: false,
      type: Object,
      default: function _default() {
        return {
          users: [],
          activityTypes: []
        };
      }
    },
    openedState: {
      type: Boolean,
      required: true
    },
    localStorageUniqueKey: {
      type: String,
      required: false,
      default: null,
      note: 'project id for localStorage'
    }
  },
  emits: ['search'],
  setup: function setup(props) {
    var localStorageKey = "mini_tree_filters_".concat(props.localStorageUniqueKey);
    return {
      selectedStorage: getLocalStorageComputed(localStorageKey, {}, true)
    };
  },
  data: function data() {
    return {
      searchString: '',
      filterDataSelected: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DATA_FIELDS.ORGANISATIONS, []), DATA_FIELDS.RESPONSIBLE, []), DATA_FIELDS.TAGS, []), DATA_FIELDS.ACTIVITY_TYPE, []),
      oldFilterDataSelected: null,
      dataFields: DATA_FIELDS,
      filterData: {},
      translate: getTranslate['MiniTreeFilters'](),
      selectedData: {
        filterString: '',
        closedHeadlines: []
      },
      filterString: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), {}, {
    itemDataToHeadline: function itemDataToHeadline() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DATA_FIELDS.TITLE, this.translate.title), DATA_FIELDS.ORGANISATIONS, this.translate.organisations), DATA_FIELDS.RESPONSIBLE, this.responsibleTitle(true)), DATA_FIELDS.TAGS, this.translate.tags), DATA_FIELDS.ACTIVITY_TYPE, this.translate.activityTypes);
    },
    responsible: function responsible() {
      return this.filterOptionsData.users;
    },
    activityTypes: function activityTypes() {
      return this.filterOptionsData.activityTypes || [];
    }
  }),
  watch: {
    filterDataSelected: {
      deep: true,
      handler: function handler(value) {
        if (JSON.stringify(value) !== JSON.stringify(this.oldFilterDataSelected)) {
          this.updateFiltersData();
        }
        this.oldFilterDataSelected = _objectSpread({}, value);
      }
    },
    openedState: function openedState() {
      this.updateFiltersData();
    }
  },
  mounted: function mounted() {
    this.loadLocalStorageData();
  },
  methods: {
    resetFilter: function resetFilter() {
      this.filterDataSelected = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, DATA_FIELDS.ORGANISATIONS, []), DATA_FIELDS.RESPONSIBLE, []), DATA_FIELDS.TAGS, []), DATA_FIELDS.ACTIVITY_TYPE, []);
      this.searchString = '';
      this.updateFiltersData();
    },
    loadLocalStorageData: function loadLocalStorageData() {
      try {
        this.setPreselectedData(this.selectedStorage);
        this.updateFiltersData(true);
      } catch (_unused) {
        this.selectedStorage = {};
      }
    },
    setPreselectedData: function setPreselectedData(data) {
      var _this = this;
      var keys = Object.values(DATA_FIELDS);
      Object.keys(data).forEach(function (key) {
        if (!keys.includes(key)) return;
        var value = _this.getPreselectedValue(data[key], key);
        if (key === DATA_FIELDS.TITLE) {
          _this.searchString = value;
          return;
        }
        _this.filterDataSelected[key] = value;
      });
    },
    getPreselectedValue: function getPreselectedValue(data, key) {
      var _this2 = this;
      if (key === DATA_FIELDS.TITLE) {
        return data;
      }
      if (!this[key]) return;
      var result = [];
      data.forEach(function (id) {
        var item = _this2[key].find(function (element) {
          return element.id === id;
        });
        if (item) result.push(item);
      });
      return result;
    },
    setSelected: function setSelected(list, key) {
      this.filterDataSelected[key] = list === null ? [] : list;
    },
    removeHeadline: function removeHeadline(item) {
      if (item === DATA_FIELDS.TITLE) {
        this.searchString = '';
        this.updateFiltersData();
      } else {
        this.filterDataSelected[item] = [];
      }
    },
    updateFiltersData: function updateFiltersData() {
      var _this3 = this;
      var firstEnter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var filtersData = _objectSpread(_objectSpread({}, this.filterDataSelected), {}, _defineProperty({}, DATA_FIELDS.TITLE, this.searchString));
      var filterString = '';
      var closedHeadlines = [];
      this.selectedStorage = {};
      Object.keys(filtersData).forEach(function (key) {
        if (_this3.isFieldUsed(filtersData, key)) {
          closedHeadlines.push({
            field: key,
            title: _this3.itemDataToHeadline[key]
          });
          if (filterString.length) {
            filterString += '&';
          } else {
            filterString += '?';
          }
          var _this3$prepareFilterD = _this3.prepareFilterDataByType(filtersData, key),
            string = _this3$prepareFilterD.string,
            value = _this3$prepareFilterD.value;
          _this3.selectedStorage = _objectSpread(_objectSpread({}, _this3.selectedStorage), {}, _defineProperty({}, key, value));
          filterString += string;
        }
      });
      this.selectedData = {
        filterString: filterString,
        closedHeadlines: closedHeadlines
      };
      if (this.filterString !== filterString || firstEnter) {
        this.filterString = filterString;
        this.$emit('search', filterString, firstEnter);
      }
    },
    isFieldUsed: function isFieldUsed(data, key) {
      return !!data[key].length;
    },
    prepareFilterDataByType: function prepareFilterDataByType(data, key) {
      if (key === DATA_FIELDS.TITLE) {
        return {
          string: "".concat(key, "=").concat(this.searchString),
          value: this.searchString
        };
      }
      var value = data[key].map(function (item) {
        return item.id;
      });
      return {
        string: objectToQueryString({
          filters: _defineProperty({}, key, value)
        }),
        value: value
      };
    }
  }
};