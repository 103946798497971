// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-6642fdb1]  .static-table .static-table-head-cell {
  width: 25%;
}
[data-v-6642fdb1]  .static-table th {
  padding: 1rem 0;
}
[data-v-6642fdb1]  .static-table td {
  padding: 14px 8px;
  background-color: #FFFFFF;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}
[data-v-6642fdb1]  .static-table td ul {
  margin-bottom: 0;
}
[data-v-6642fdb1]  .static-table td:last-child {
  border-right: 1px solid #CCCCCC;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
[data-v-6642fdb1]  .static-table td:first-child {
  border-left: 1px solid #CCCCCC;
}
[data-v-6642fdb1]  .static-table td:nth-child(2)::first-letter {
  text-transform: uppercase;
}
[data-v-6642fdb1]  .static-table tbody:after {
  content: "";
  display: block;
  height: 11px;
}
[data-v-6642fdb1]  .static-table .medium {
  color: #4D4D4D;
  font-weight: 600;
  font-size: 16px;
  height: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
