function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { computed } from 'vue';
import Pagination from '@/components/Atoms/Pagination';
import RismaTableHeader from '@/components/Molecules/RismaTable/RismaTableHeader';
import RismaTableBody from '@/components/Molecules/RismaTable/RismaTableBody';
import RismaTableReport from '@/components/Molecules/RismaTable/RismaTableReport';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import { getCellTextContent } from '@/components/Molecules/RismaTable/RismaTableUtil';
import { TableCellsTypes } from '@/constants/TableCellsTypes';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { isDateValid } from '@/utils/date';
var QUERY_KEYS_MAP = {
  searchString: 'q'
};
export default {
  name: 'RismaTable',
  description: "Universal table. That requires api that will return formated data.\n        This table will call api on some events to update data byitself. So basically only api is required.",
  token: '<risma-table :api="Http.get("/companies/company") print-title="Test" />',
  components: {
    Pagination: Pagination,
    RismaTableHeader: RismaTableHeader,
    RismaTableBody: RismaTableBody,
    RismaTableReport: RismaTableReport,
    RismaDateRangePicker: RismaDateRangePicker
  },
  provide: function provide() {
    var _this = this;
    return {
      tableDataLoading: computed(function () {
        return _this.loadState;
      })
    };
  },
  props: {
    api: {
      required: true,
      type: Function,
      default: function _default() {},
      note: 'Api to call when any change requires to return {headers, data, options}'
    },
    pagingOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [10, 25, 50, 100];
      },
      note: 'Paging options'
    },
    preselectedPageSize: {
      type: Number,
      required: false,
      default: null
    },
    stateKey: {
      type: String,
      required: false,
      default: '',
      note: 'Key to store table state'
    },
    showSearchField: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Show search field in top right corner of header'
    },
    printTitle: {
      required: false,
      type: String,
      default: '',
      note: 'Title of table in print mode'
    },
    bodyHeight: {
      type: String,
      required: false,
      default: ''
    },
    reportType: {
      required: false,
      type: String,
      default: '',
      note: 'Type of report to save/load'
    },
    complianceProjectId: {
      type: [Number, String],
      default: null,
      note: 'Compliance project id that corresponds to this report'
    },
    reportOptions: {
      type: Object,
      default: null,
      note: 'Report options that will be saved along with the report'
    }
  },
  emits: ['delete', 'dismissModal', 'resetFormating', 'sortByColumn', 'changeVisibleColumns', 'pageChange', 'pageSizeChange', 'searchStringChange', 'starred', 'error', 'reload', 'iconClicked', 'update:loading'],
  setup: function setup(props) {
    return {
      latestSavedQueryParams: getLocalStorageComputed(props.stateKey + '_query_string', '')
    };
  },
  data: function data() {
    return {
      tableState: {},
      paginationInfo: {
        count: 0
      },
      columnsSetting: [],
      timer: null,
      timeoutBeforeSearch: 500,
      bodyOffset: 100,
      columns: {},
      enhancedDataset: [],
      underlyingCheckboxValues: {},
      includeUnderlyingKey: 'IncludeUnderlying',
      requestNumber: 0,
      filters: new URLSearchParams(),
      loadState: false,
      ranges: getRangesWithNextThreeMonths()
    };
  },
  computed: {
    datesRangeLocalStorageKey: function datesRangeLocalStorageKey() {
      return this.stateKey + '_date_range';
    },
    dateColumn: function dateColumn() {
      return this.columnsSetting.find(function (col) {
        if (!col.dateRangeHeaderFilter) return false;
        var _col$dateRangeHeaderF = col.dateRangeHeaderFilter,
          before = _col$dateRangeHeaderF.before,
          after = _col$dateRangeHeaderF.after;
        return isDateValid(before) && isDateValid(after);
      }) || null;
    },
    datasetToShow: function datasetToShow() {
      return this.getDatasetOrderedByColumns(this.enhancedDataset);
    },
    columnsToExport: function columnsToExport() {
      var result = {};
      this.columnsSetting.forEach(function (col) {
        if (!col.visible || col.fieldType === TableCellsTypes.DELETE_BUTTON) return;
        result[col.key] = col.label;
      });
      return result;
    },
    exportHandler: function exportHandler() {
      var _this2 = this;
      return function () {
        return _this2.getAllData().then(function (_ref) {
          var data = _ref.data;
          var enhancedDataset = _this2.enhanceDataset(data);
          var orderedEnhancedDataset = _this2.getDatasetOrderedByColumns(enhancedDataset);
          return Promise.resolve({
            dataset: _this2.prepareDatasetToExport(orderedEnhancedDataset),
            columns: _this2.columnsToExport
          });
        });
      };
    },
    showPagination: function showPagination() {
      return !!this.datasetToShow.length;
    },
    reversedQueryKeysMap: function reversedQueryKeysMap() {
      return Object.entries(QUERY_KEYS_MAP).reduce(function (acc, _ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          value = _ref3[1];
        acc[value] = key;
        return acc;
      }, {});
    },
    activePaginationPage: function activePaginationPage() {
      return Math.ceil((this.paginationInfo.offset + 1) / this.paginationInfo.limit);
    }
  },
  watch: {
    columns: function columns() {
      this.initColumns();
    },
    showPagination: function showPagination() {
      this.$nextTick(this.setBodyOffset);
    }
  },
  created: function created() {
    this.setDefaultTableState();
  },
  mounted: function mounted() {
    this.setBodyOffset();
    this.getInitialData();
  },
  methods: {
    getInitialData: function getInitialData() {
      var _this$$route$query, _JSON$parse;
      var queryParams = ((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.query) && ((_JSON$parse = JSON.parse(this.$route.query.query)) === null || _JSON$parse === void 0 || (_JSON$parse = _JSON$parse.query) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.params);
      var params = queryParams || this.latestSavedQueryParams;
      this.initStateAndFiltersFromUrl(params);
      this.getData();
    },
    getData: function getData() {
      var _this3 = this;
      var query = this.buildQueryWithStateAndQueryString(this.tableState, this.filters.toString());
      this.loadState = true;
      return this.apiCallWithErrorHandling(query).then(function (_ref4) {
        var headers = _ref4.headers,
          data = _ref4.data,
          options = _ref4.options;
        if (!headers || !data) {
          _this3.columns = {};
          _this3.enhancedDataset = [];
          _this3.setDefaultTableState();
          return;
        }
        if (!data.length && options.offset >= options.count && options.offset !== 0) {
          return _this3.goToLastAvailablePage(options);
        }
        _this3.columns = headers;
        _this3.enhancedDataset = _this3.enhanceDataset(data);
        _this3.paginationInfo = options;
      }).catch(function (e) {
        return _this3.$emit('error', e);
      }).finally(function () {
        return _this3.loadState = false;
      });
    },
    apiCallWithErrorHandling: function apiCallWithErrorHandling(query) {
      var _this4 = this;
      var saveQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (this.requestNumber > 1) return;
      return this.api(query).then(function (response) {
        if (saveQuery) {
          _this4.latestSavedQueryParams = query;
        }
        _this4.requestNumber = 0;
        return response;
      }).catch(function (e) {
        if (_this4.isSortingError(e)) {
          _this4.requestNumber++;
          _this4.resetFormating();
        }
        throw e;
      });
    },
    buildQueryWithStateAndQueryString: function buildQueryWithStateAndQueryString(tableState, queryString) {
      var queryParams = new URLSearchParams();
      var mergedState = _objectSpread({
        start: 0,
        limit: 10,
        format: 'table'
      }, tableState);
      Object.entries(mergedState).forEach(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
          key = _ref6[0],
          value = _ref6[1];
        var keyName = QUERY_KEYS_MAP[key] || key;
        if (value !== '') {
          queryParams.append(keyName, value);
        }
      });
      if (queryString) {
        return queryParams.toString() + '&' + queryString;
      }
      return queryParams.toString();
    },
    goToLastAvailablePage: function goToLastAvailablePage(_ref7) {
      var limit = _ref7.limit,
        count = _ref7.count;
      this.tableState.start = Math.max(0, Math.ceil(count / limit - 1) * limit);
      return this.getData();
    },
    isSortingError: function isSortingError(e) {
      var _e$response;
      return (_e$response = e.response) === null || _e$response === void 0 || (_e$response = _e$response.error) === null || _e$response === void 0 ? void 0 : _e$response.includes('Cannot sort by');
    },
    saveAndGetData: function saveAndGetData() {
      this.saveState();
      return this.getData();
    },
    getAllData: function getAllData() {
      var query = this.buildQueryWithStateAndQueryString(_objectSpread(_objectSpread({}, this.tableState), {}, {
        limit: 0
      }), this.filters.toString());
      return this.apiCallWithErrorHandling(query, false);
    },
    setBodyOffset: function setBodyOffset() {
      var _this$$el$querySelect2;
      var scrollTop = document.querySelector('html').scrollTop || 0;
      var _this$$el$querySelect = this.$el.querySelector('.risma-table-body').getBoundingClientRect(),
        tableBodyOffsetTop = _this$$el$querySelect.y;
      var _ref8 = ((_this$$el$querySelect2 = this.$el.querySelector('.risma-table-pagination')) === null || _this$$el$querySelect2 === void 0 ? void 0 : _this$$el$querySelect2.getBoundingClientRect()) || {
          height: 20
        },
        paginationHeight = _ref8.height;
      this.bodyOffset = scrollTop + tableBodyOffsetTop + paginationHeight + 50;
    },
    setDefaultTableState: function setDefaultTableState() {
      var limit = this.preselectedPageSize && this.pagingOptions.includes(this.preselectedPageSize) ? this.preselectedPageSize : this.pagingOptions[0] || 0;
      this.tableState = {
        searchString: '',
        start: 0,
        limit: limit,
        sortField: '',
        sortOrder: ''
      };
    },
    enhanceDataset: function enhanceDataset(dataset) {
      var _this5 = this;
      var result = [];
      dataset.forEach(function (row) {
        var newRow = {};
        Object.keys(row).forEach(function (rowKey) {
          var type = row[rowKey].type || _this5.columns[rowKey].type || TableCellsTypes.TEXT;
          newRow[rowKey] = JSON.parse(JSON.stringify(row[rowKey]));
          newRow[rowKey].textContent = getCellTextContent(row[rowKey], type);
          newRow[rowKey].type = type;
        });
        result.push(newRow);
      });
      return result;
    },
    initColumns: function initColumns() {
      var _this6 = this;
      this.columnsSetting = Object.keys(this.columns).map(function (key) {
        return _objectSpread(_objectSpread({}, _this6.columns[key]), {}, {
          key: key,
          visible: true
        });
      });
      this.loadColumnsState();
    },
    changePage: function changePage(e) {
      if (e.mounted) return;
      if ((e.currentPage - 1) * this.tableState.limit !== this.tableState.start) {
        this.tableState.start = (e.currentPage - 1) * this.tableState.limit;
        this.getData();
        this.$emit('pageChange', e);
      }
    },
    changeVisibleColumns: function changeVisibleColumns(columns) {
      this.columnsSetting = this.columnsSetting.map(function (col) {
        return _objectSpread(_objectSpread({}, col), {}, {
          visible: columns.includes(col.key)
        });
      });
      this.saveState();
      this.$emit('changeVisibleColumns', columns);
    },
    onColumnResize: function onColumnResize(_ref9) {
      var key = _ref9.key,
        width = _ref9.width;
      var idx = this.columnsSetting.findIndex(function (col) {
        return col.key === key;
      });
      if (idx === -1) return;
      this.columnsSetting[idx] = _objectSpread(_objectSpread({}, this.columnsSetting[idx]), {}, {
        userSelectedWidth: width
      });
      this.saveState();
    },
    onColumnsChange: function onColumnsChange(updatedColumns) {
      this.columnsSetting = updatedColumns;
      this.saveState();
    },
    onPageSizeChange: function onPageSizeChange(newPageSize) {
      if (newPageSize !== this.tableState.limit) {
        this.tableState.limit = newPageSize;
        this.saveAndGetData();
        this.$emit('pageSizeChange', newPageSize);
      }
    },
    onModalDismiss: function onModalDismiss() {
      this.getData();
      this.$emit('dismissModal');
    },
    onGlobalSearch: function onGlobalSearch(event) {
      var _this7 = this;
      this.tableState.searchString = event;
      this.resetStartOffset();
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this7.getData();
        _this7.$emit('searchStringChange', _this7.tableState.searchString.toLowerCase());
      }, this.timeoutBeforeSearch);
    },
    onFiltersChange: function onFiltersChange(_ref10) {
      var _this8 = this;
      var filters = _ref10.filters,
        columnKey = _ref10.columnKey,
        _ref10$isUnderlyingLo = _ref10.isUnderlyingLogic,
        isUnderlyingLogic = _ref10$isUnderlyingLo === void 0 ? false : _ref10$isUnderlyingLo,
        _ref10$underlyingLogi = _ref10.underlyingLogicValue,
        underlyingLogicValue = _ref10$underlyingLogi === void 0 ? false : _ref10$underlyingLogi;
      var idx = this.columnsSetting.findIndex(function (col) {
        return col.key === columnKey;
      });
      if (idx === -1) return;
      var column = this.columnsSetting[idx];
      var filterName = this.getFilterName(isUnderlyingLogic, column);
      var filterKey = "filters[".concat(filterName, "]");
      if (column.datesFilter) {
        this.onDatesFilterChange(filterKey, filters);
        return;
      }
      var isStringFilter = typeof filters === 'string';
      filterKey += isStringFilter || isUnderlyingLogic ? '' : '[]';
      this.filters.delete(filterKey);
      if (isStringFilter && filters) {
        this.filters.append(filterKey, filters);
      } else if (!isStringFilter && !isUnderlyingLogic) {
        filters.forEach(function (filterValue) {
          _this8.filters.append(filterKey, filterValue);
        });
      } else if (isUnderlyingLogic) {
        this.underlyingCheckboxValues[column.filterName] = underlyingLogicValue;
        if (underlyingLogicValue) this.filters.append(filterKey, 1);
      }
      this.resetStartOffset();
      this.saveAndGetData();
    },
    getFilterName: function getFilterName(isUnderlyingLogic, column) {
      return isUnderlyingLogic ? "".concat(column.filterName).concat(this.includeUnderlyingKey) : column.filterName;
    },
    changedDates: function changedDates(event) {
      var filterKey = "filters[".concat(this.dateColumn.filterName, "]");
      this.onDatesFilterChange(filterKey, [event.startDate, event.endDate]);
    },
    onDatesFilterChange: function onDatesFilterChange(filterKey) {
      var filters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var _filters = _slicedToArray(filters, 2),
        after = _filters[0],
        before = _filters[1];
      var afterKey = filterKey + '[after]';
      var beforeKey = filterKey + '[before]';
      if (!after || !before) {
        this.filters.delete(afterKey);
        this.filters.delete(beforeKey);
      } else {
        this.filters.set(afterKey, after);
        this.filters.set(beforeKey, before);
      }
      this.saveAndGetData();
    },
    onSortByColumnEvt: function onSortByColumnEvt(change) {
      this.sortByColumn(change);
      this.saveAndGetData();
    },
    sortByColumn: function sortByColumn(change) {
      var index = change.index,
        direction = change.direction;
      this.tableState.sortField = this.columnsSetting[index].key;
      this.tableState.sortOrder = direction;
      this.$emit('sortByColumn', {
        key: this.columnsSetting[index].key,
        direction: direction
      });
    },
    resetFormating: function resetFormating() {
      this.clearState();
      this.setDefaultTableState();
      this.clearFilters();
      this.getData();
      this.$emit('resetFormating');
    },
    resetStartOffset: function resetStartOffset() {
      this.tableState.start = 0;
    },
    clearFilters: function clearFilters() {
      this.filters = new URLSearchParams();
      this.underlyingCheckboxValues = {};
    },
    getDatasetOrderedByColumns: function getDatasetOrderedByColumns(dataset) {
      var _this9 = this;
      var result = [];
      dataset.forEach(function (dataRow) {
        var row = {};
        _this9.columnsSetting.forEach(function (col) {
          if (col.visible || col.static) {
            row[col.key] = dataRow[col.key];
          }
        });
        result.push(row);
      });
      return result;
    },
    prepareDatasetToExport: function prepareDatasetToExport(dataset) {
      var result = [];
      dataset.forEach(function (row, idx) {
        result[idx] = {};
        for (var key in row) {
          if (row[key].type !== TableCellsTypes.DELETE_BUTTON) {
            result[idx][key] = row[key].textContent;
          }
        }
      });
      return result;
    },
    saveState: function saveState() {
      if (!this.stateKey) return;
      var columnsState = this.columnsSetting.map(function (row) {
        return {
          visible: row.visible,
          key: row.key,
          sorted: row.sorted || false,
          sortDirection: row.sortDirection || '',
          userSelectedWidth: row.userSelectedWidth || null
        };
      });
      var stateToSave = {
        columns: columnsState
      };
      localStorage.setItem(this.stateKey, JSON.stringify(stateToSave));
    },
    loadColumnsState: function loadColumnsState() {
      if (!this.stateKey && !localStorage.getItem(this.stateKey)) return;
      try {
        //load columns order and visibility state
        var state = JSON.parse(localStorage.getItem(this.stateKey));
        var columnsState = state.columns;
        var columnsMap = {};
        var updatedColumns = [];
        if (!this.isColumnsStateValid(columnsState, this.columnsSetting)) return null;
        this.columnsSetting.forEach(function (col) {
          columnsMap[col.key] = col;
        });
        columnsState.forEach(function (stateCol) {
          if (!columnsMap[stateCol.key]) return;
          updatedColumns.push(_objectSpread(_objectSpread({}, columnsMap[stateCol.key]), {}, {
            visible: stateCol.visible,
            userSelectedWidth: stateCol.userSelectedWidth || null
          }));
        });
        this.columnsSetting = updatedColumns;
      } catch (_unused) {
        this.clearState();
      }
    },
    isColumnsStateValid: function isColumnsStateValid(savedColumns, expectedColumns) {
      if (!(savedColumns !== null && savedColumns !== void 0 && savedColumns.length) || savedColumns.length !== expectedColumns.length) {
        return false;
      }
      var columnsStateMap = new Map(savedColumns.map(function (column) {
        return [column.key, column];
      }));
      return expectedColumns.every(function (column) {
        return columnsStateMap.has(column.key);
      });
    },
    clearState: function clearState() {
      if (!this.stateKey) return;
      localStorage.removeItem(this.stateKey);
    },
    initStateAndFiltersFromUrl: function initStateAndFiltersFromUrl(queryParams) {
      var params = new URLSearchParams(queryParams);
      var updatedState = {};
      var updatedFilters = new URLSearchParams();
      var filtersPattern = /^filters\[(\w+)\](\[(\w+)?\])?$/;
      var paramNamesToSkip = ['format'];
      var _iterator = _createForOfIteratorHelper(params),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
            key = _step$value[0],
            value = _step$value[1];
          if (paramNamesToSkip.includes(key)) continue;
          if (filtersPattern.test(key)) {
            updatedFilters.append(key, value);
          } else {
            var keyName = this.reversedQueryKeysMap[key] || key;
            updatedState[keyName] = isNaN(value) || value === '' ? value : parseInt(value);
          }
          if (key.includes(this.includeUnderlyingKey)) {
            var filterName = key.replace('filters[', '').replace(this.includeUnderlyingKey + ']', '');
            this.underlyingCheckboxValues[filterName] = value === '1';
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.tableState = _objectSpread(_objectSpread({}, this.tableState), updatedState);
      this.filters = updatedFilters;
    },
    loadReport: function loadReport(params) {
      this.initStateAndFiltersFromUrl(params);
      this.getData();
    }
  }
};