import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "ReportClearResult"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("button", {
    class: "btn btn-login",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleClearResults && $options.handleClearResults.apply($options, arguments);
    })
  }, _toDisplayString($data.translate.clearSearchResults), 1 /* TEXT */)]);
}