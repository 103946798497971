import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "pl-5 mb-5 max-w-1100px"
};
var _hoisted_3 = {
  key: 0,
  class: "border-separate"
};
var _hoisted_4 = {
  class: "text-left pr-4"
};
var _hoisted_5 = {
  class: "text-left pr-4 w-32"
};
var _hoisted_6 = {
  class: "w-40-rem text-left pr-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_single_select = _resolveComponent("single-select");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.templateNodes,
    class: "mb-5"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.forEachComplianceProjectYouCanSelect), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.theNodeWillContainAllRelevantData), 1 /* TEXT */)]), $data.isLoaded ? (_openBlock(), _createElementBlock("table", _hoisted_3, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (title, key) {
    return _openBlock(), _createElementBlock("th", {
      key: "col-key-".concat(key),
      class: "text-left pr-4"
    }, _toDisplayString(title), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.projects, function (project) {
    return _openBlock(), _createElementBlock("tr", {
      key: project.id
    }, [_createElementVNode("td", _hoisted_4, _toDisplayString(project.title), 1 /* TEXT */), _createElementVNode("td", _hoisted_5, [_createVNode(_component_risma_toggle, {
      "model-value": !!project.templateId,
      disabled: !project.templateId,
      onInput: function onInput($event) {
        return $options.onEnableNodeTemplate($event, project.id, project.templateId);
      }
    }, null, 8 /* PROPS */, ["model-value", "disabled", "onInput"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_single_select, {
      modelValue: project.templateId,
      "onUpdate:modelValue": [function ($event) {
        return project.templateId = $event;
      }, function ($event) {
        return $options.onChangeTemplateId($event, project.id);
      }],
      options: $data.nodesOptionsHash[project.id],
      placeholder: $data.translate.selectOne
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "placeholder"])])]);
  }), 128 /* KEYED_FRAGMENT */))])])) : (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 1
  }))]);
}