export var art46TransferBasis = function art46TransferBasis(trans) {
  return [{
    value: 1,
    label: trans('Standard Contractual Clauses for the Transfer of Personal Data to Third Countries (Published by the Commission)')
  }, {
    value: 2,
    label: trans('A legally binding and enforceable instrument between public authorities or bodies')
  }, {
    value: 3,
    label: trans('Binding corporate rules in accordance with Article 47')
  }, {
    value: 4,
    label: trans('Standard data protection clauses adopted by a supervisory authority')
  }, {
    value: 5,
    label: trans('An approved code of conduct pursuant to Article 40')
  }, {
    value: 6,
    label: trans('An approved certification mechanism pursuant to Article 42')
  }, {
    value: 7,
    label: trans('EU-U.S. Privacy Framework Certification in accordance with Article 45')
  }, {
    value: 8,
    label: trans('Adequacy decision in accordance with Article 45')
  }, {
    value: 9,
    label: trans('No transfer basis')
  }];
};
export var art46TransferBasisById = function art46TransferBasisById(trans) {
  return art46TransferBasis(trans).reduce(function (acc, cur) {
    acc[cur.value] = cur.label;
    return acc;
  }, {});
};