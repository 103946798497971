import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-4"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_process_tree_report = _resolveComponent("process-tree-report");
  var _component_process_tree_thin_report = _resolveComponent("process-tree-thin-report");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.report,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.display,
    type: "medium",
    class: "mr-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_view_switcher, {
    view: $setup.viewSelected,
    "onUpdate:view": [_cache[0] || (_cache[0] = function ($event) {
      return $setup.viewSelected = $event;
    }), $options.updateView],
    views: $data.PROCESS_TREE_REPORT_VIEWS
  }, null, 8 /* PROPS */, ["view", "views", "onUpdate:view"])])]), $data.processTreeId ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$setup.viewSelected === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_process_tree_report, {
    key: 0,
    "process-tree-id": $data.processTreeId
  }, null, 8 /* PROPS */, ["process-tree-id"])) : _createCommentVNode("v-if", true), $setup.viewSelected === $data.viewTypes.RISMA_TABLE ? (_openBlock(), _createBlock(_component_process_tree_thin_report, {
    key: 1,
    "process-tree-id": $data.processTreeId
  }, null, 8 /* PROPS */, ["process-tree-id"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}