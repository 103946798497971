function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { Colors } from '@/Colors';
import * as toBlob from 'canvas-to-blob';
import FileSaver from 'file-saver';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'SaveImage',
  introduction: 'Component is needed to save the tree',
  description: 'Component draw canvas from tree data and then save it',
  token: "<save-image\n      :tree=\"myTree\"/>",
  components: {
    FeatherIcon: FeatherIcon
  },
  data: function data() {
    return {
      lowestY: 10,
      highestX: 0,
      rectHeight: 30,
      rectWidth: 280,
      spaceHeight: 10,
      spaceWidth: 50,
      canvasHeight: 0,
      canvasWidth: 0,
      tree: {},
      colors: Colors,
      closedChildrenList: []
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    handleClick: function handleClick() {
      this.closedChildrenList = this.getOpenedNodesList();
      this.tree = $('#tree-jquery').tree('getTree');
      //there are bugs with createelement and filling text in it so use getElementById
      var canvas = document.getElementById('canvas');
      var ctx = canvas.getContext('2d');
      this.createCanvas(canvas, ctx);
      this.save(canvas);
      this.resetAll(canvas);
    },
    getOpenedNodesList: function getOpenedNodesList() {
      var list = [];
      $('#tree-jquery').find('.jqtree-closed').each(function () {
        var node = $('#tree-jquery').tree('getNodeByHtmlElement', this);
        list.push(node.id);
      });
      return list;
    },
    createCanvas: function createCanvas(canvas, ctx) {
      if (this.tree.children) {
        this.setSize(this.tree.children, 10);
      }
      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;
      if (this.tree.children) {
        this.drawAll(ctx, this.tree.children, 10);
      }
    },
    save: function save(canvas) {
      toBlob.init();
      canvas.toBlob(function (blob) {
        FileSaver.saveAs(blob, 'tree.png');
      });
    },
    setSize: function setSize(items, x) {
      var _this = this;
      items.forEach(function (el) {
        _this.canvasHeight += _this.rectHeight + _this.spaceHeight;
        _this.canvasWidth <= x ? _this.canvasWidth = x + _this.rectWidth + _this.spaceWidth : '';
        if (el.children && _this.closedChildrenList.indexOf(el.id) === -1) {
          _this.setSize(el.children, x + _this.rectWidth + _this.spaceWidth);
        }
      });
    },
    resetAll: function resetAll(canvas) {
      this.lowestY = 10;
      this.highestX = 0;
      this.canvasHeight = 0;
      this.canvasWidth = 0;
      canvas.width = 0;
      canvas.height = 0;
    },
    drawAll: function drawAll(canvas, items, x) {
      var _this2 = this;
      var parentX = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var parentY = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      items.forEach(function (el) {
        var pX = x;
        var pY = _this2.lowestY;
        _this2.drawRect(canvas, x, _this2.lowestY);
        _this2.addText(canvas, x + _this2.rectWidth / 10, _this2.lowestY + _this2.rectHeight * 2 / 3, _this2.getText(el.name), !el.checkaccess ? _this2.rectHeight / 6 : !el.checkaccess);
        if (el.traffic_light !== undefined && el.objType !== ObjectTypes.ORGANISATION) {
          _this2.drawTrafficLight(canvas, x, _this2.lowestY, _this2.getTrafficLightColor(el.traffic_light, el.objType));
        }
        _this2.handleLine(canvas, parentX, parentY, x);
        _this2.lowestY += _this2.rectHeight + _this2.spaceHeight;
        _this2.highestX <= x ? _this2.highestX = x + _this2.rectWidth + _this2.spaceWidth : '';
        if (el.children && _this2.closedChildrenList.indexOf(el.id) === -1) {
          _this2.drawAll(canvas, el.children, x + _this2.rectWidth + _this2.spaceWidth, pX, pY);
        }
      });
    },
    drawRect: function drawRect(canvas, x, y) {
      var width = this.rectWidth;
      var height = this.rectHeight;
      var radius = 5;
      canvas.beginPath();
      canvas.fillStyle = 'white';
      canvas.moveTo(x + radius, y);
      canvas.lineTo(x + width - radius, y);
      canvas.quadraticCurveTo(x + width, y, x + width, y + radius);
      canvas.lineTo(x + width, y + height - radius);
      canvas.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      canvas.lineTo(x + radius, y + height);
      canvas.quadraticCurveTo(x, y + height, x, y + height - radius);
      canvas.lineTo(x, y + radius);
      canvas.quadraticCurveTo(x, y, x + radius, y);
      canvas.closePath();
      canvas.fill();
      canvas.stroke();
    },
    drawLine: function drawLine(canvas, firstX, firstY, lastX, lastY) {
      canvas.lineWidth = 3;
      canvas.beginPath();
      canvas.moveTo(firstX, firstY);
      canvas.lineTo(firstX + (lastX - firstX) / 2, firstY);
      canvas.stroke();
      canvas.beginPath();
      canvas.moveTo(firstX + (lastX - firstX) / 2, firstY);
      canvas.lineTo(firstX + (lastX - firstX) / 2, lastY);
      canvas.stroke();
      canvas.beginPath();
      canvas.moveTo(firstX + (lastX - firstX) / 2, lastY);
      canvas.lineTo(lastX, lastY);
      canvas.stroke();
      canvas.lineWidth = 1;
    },
    drawTrafficLight: function drawTrafficLight(canvas, x, y, color) {
      canvas.beginPath();
      canvas.arc(x + 15, y + this.rectHeight / 2, 5, 0, 2 * Math.PI);
      canvas.fillStyle = color;
      canvas.fill();
      canvas.stroke();
    },
    addText: function addText(canvas, x, y, text) {
      var linethroughY = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      canvas.beginPath();
      canvas.font = '1em Inter';
      canvas.fillStyle = 'black';
      canvas.fillText(text, x, y);
      if (linethroughY) {
        var _canvas$measureText = canvas.measureText(text),
          width = _canvas$measureText.width;
        canvas.fillRect(x, y - linethroughY, width, 2);
      }
      canvas.stroke();
    },
    getText: function getText(text) {
      if (text.length > 30) {
        return this.$truncate(text, 30);
      } else {
        return text;
      }
    },
    getTrafficLightColor: function getTrafficLightColor(number, objectType) {
      return this.getTrafficLightColorByObjectTypeAndNumber(objectType, number || 0);
    },
    handleLine: function handleLine(canvas, parentX, parentY, x) {
      if (parentX && parentY) {
        this.drawLine(canvas, parentX + this.rectWidth, parentY + this.rectHeight / 2, x, this.lowestY + this.rectHeight / 2);
      }
    }
  })
};