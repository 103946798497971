import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAllForNode(projectId, nodeId) {
  var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return handleRequestResponse(Http.get("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/gapanalysis") + query));
}
export function updateGap(gap, baseUrl) {
  return handleRequestResponse(Http.patch("".concat(baseUrl, "/gapanalysis"), gap));
}
export function setGapLinks(projectId, nodeId, links, gapId) {
  return handleRequestResponse(Http.post("compliance/projects/".concat(projectId, "/nodes/").concat(nodeId, "/link"), {
    id: gapId,
    links: links
  }));
}
export function getGapTypes(projectId) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/gap_article_types")));
}
export function createGapType(projectId, gapType) {
  return handleRequestResponse(Http.post("/compliance/projects/".concat(projectId, "/gap_article_types"), gapType));
}
export function patchGapType(projectId, gapType) {
  var params = {
    id: gapType.id,
    number: gapType.number,
    label: gapType.label
  };
  return handleRequestResponse(Http.patch("/compliance/projects/".concat(projectId, "/gap_article_types/").concat(gapType.id), params));
}
export function deleteGapType(projectId, gapTypeId) {
  return handleRequestResponse(Http.delete("compliance/projects/".concat(projectId, "/gap_article_types/").concat(gapTypeId)));
}