import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded p-3"
};
var _hoisted_2 = {
  class: "relative"
};
var _hoisted_3 = {
  class: "absolute right-0 flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_dataflow_report = _resolveComponent("dataflow-report");
  var _component_dataflow_thin_report = _resolveComponent("dataflow-thin-report");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.display,
    type: "medium",
    class: "mr-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_view_switcher, {
    view: $data.viewSelected,
    "onUpdate:view": _cache[0] || (_cache[0] = function ($event) {
      return $data.viewSelected = $event;
    }),
    views: $data.DATAFLOW_VIEWS
  }, null, 8 /* PROPS */, ["view", "views"])])]), $data.viewSelected === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_dataflow_report, {
    key: 0,
    "project-id": $props.projectId
  }, null, 8 /* PROPS */, ["project-id"])) : (_openBlock(), _createBlock(_component_dataflow_thin_report, {
    key: 1,
    "project-id": $props.projectId,
    class: "mt-10"
  }, null, 8 /* PROPS */, ["project-id"]))]);
}