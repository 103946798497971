import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["src"];
var _hoisted_2 = {
  class: "max-w-500px mx-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("img", {
    src: $data.LogoSettings.DEFAULT_WITH_BLUE_TEXT,
    class: "block w-48 mx-auto mt-24"
  }, null, 8 /* PROPS */, _hoisted_1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.changePassword,
    type: "big",
    class: "mb-8 text-center"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.youNeedToChangeYourPasswordBeforeYouCanLogin,
    type: "medium",
    truncate: false,
    class: "mb-8 text-center"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.passwordData.passwordCurrent,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.passwordData.passwordCurrent = $event;
    }),
    title: $data.translate.currentPassword,
    placeholder: $data.translate.currentPassword,
    class: "mb-4",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.passwordData.passwordNew,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.passwordData.passwordNew = $event;
    }),
    title: $data.translate.newPassword,
    placeholder: $data.translate.newPassword,
    class: "mb-4",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.passwordData.passwordNewRepeat,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.passwordData.passwordNewRepeat = $event;
    }),
    title: $data.translate.repeatNewPassword,
    placeholder: $data.translate.repeatNewPassword,
    class: "mb-4",
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_risma_button, {
    type: "save",
    class: "w-full change-button",
    text: $data.translate.changePassword,
    onClick: $options.changePasswordLegacy
  }, null, 8 /* PROPS */, ["text", "onClick"])])]);
}