export var InfoToolTipMixin = {
  data: function data() {
    return {
      currentToolTipData: null
    };
  },
  methods: {
    handleClickOusideToolTip: function handleClickOusideToolTip(index, evt) {
      var _this$currentToolTipD;
      // if clickoutside is called on the wrong element in the list or on the dropdown with nodes
      if (index !== ((_this$currentToolTipD = this.currentToolTipData) === null || _this$currentToolTipD === void 0 ? void 0 : _this$currentToolTipD.index) || evt.target.closest('.tool-tip')) return;
      this.resetToolTip();
    },
    handleHelpCircleClick: function handleHelpCircleClick(nodes, index) {
      var _this$currentToolTipD2,
        _this = this;
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      // if clicked when opened - close it
      if (index === ((_this$currentToolTipD2 = this.currentToolTipData) === null || _this$currentToolTipD2 === void 0 ? void 0 : _this$currentToolTipD2.index)) {
        this.resetToolTip();
        return;
      }
      this.currentToolTipData = {
        nodes: nodes,
        index: index,
        isToolTipShown: true,
        toolTipPosition: null
      };
      var refName = 'helptooltip' + index;
      if (type) refName += "-".concat(type);
      var element = this.$refs[refName];
      if (Array.isArray(element)) element = element[0];
      var toolTipPosition = element.lastChild.getBoundingClientRect();
      this.$nextTick(function () {
        _this.currentToolTipData.toolTipPosition = {
          left: "".concat(Math.round(toolTipPosition.left), "px"),
          top: "".concat(Math.round(toolTipPosition.top + window.scrollY) - 5, "px")
        };
      });
    },
    resetToolTip: function resetToolTip() {
      this.currentToolTipData = {
        isToolTipShown: false,
        toolTipPosition: null,
        currentToolTipData: null
      };
    }
  }
};