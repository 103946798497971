import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
var _hoisted_2 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
var _hoisted_3 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
var _hoisted_4 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_label, {
    title: $options.annualCostOfServicesLabel,
    for: "costOfServices",
    class: _normalizeClass($options.labelStyles)
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.annualCostCurrencyLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleLabelClick('totalValueCurrency');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.typeOfIctServicesLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleLabelClick('typeOfServices');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_input_field, {
    "model-value": $props.data.totalValue,
    placeholder: $data.translate.annualCostOfServices,
    enabled: !$props.readOnly,
    "enable-currency-template": true,
    "input-id": "costOfServices",
    class: "self-start",
    "allow-null": true,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $options.handleInputUpdate('totalValue', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "enabled"]), _createVNode(_component_single_select, {
    ref: "totalValueCurrency",
    "model-value": $props.data.totalValueCurrency,
    disabled: $props.readOnly,
    options: $props.options.currencyOptions,
    placeholder: $data.translate.annualCostCurrency,
    "with-reset": true,
    "with-search": true,
    class: "self-start",
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $options.handleUpdate('totalValueCurrency', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "placeholder"]), _createVNode(_component_risma_select, {
    ref: "typeOfServices",
    options: $props.options.typeOfServices,
    "selected-options": $props.data.typeOfServices,
    placeholder: $data.translate.typeOfIctServices,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    class: "self-start",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $options.handleUpdate('typeOfServices', $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_label, {
    title: $options.ictTppSubstitutabilityLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.handleLabelClick('substitutability');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.reasonForSubstitutabilityLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.handleLabelClick('substitutabilityReason');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.dateOfLastAuditLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[7] || (_cache[7] = function ($event) {
      return $options.handleLabelClick('lastAudit');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_single_select, {
    ref: "substitutability",
    "model-value": $props.data.substitutabilityOfICTProvider,
    disabled: $props.readOnly,
    options: $props.options.substitutabilityOfICTProvider,
    placeholder: $data.translate.ictTppSubstitutability,
    "with-reset": true,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $options.handleUpdate('substitutabilityOfICTProvider', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "placeholder"]), _createVNode(_component_single_select, {
    ref: "substitutabilityReason",
    "model-value": $props.data.substitutabilityReason,
    disabled: $props.readOnly,
    options: $props.options.substitutabilityReason,
    placeholder: $data.translate.reasonForSubstitutability,
    "with-reset": true,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $options.handleUpdate('substitutabilityReason', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "placeholder"]), _createVNode(_component_date_time_picker, {
    ref: "lastAudit",
    modelValue: $props.data.lastAudit,
    "onUpdate:modelValue": [_cache[10] || (_cache[10] = function ($event) {
      return $props.data.lastAudit = $event;
    }), _cache[11] || (_cache[11] = function ($event) {
      return $options.handleUpdate('lastAudit', $event);
    })],
    "plugin-options": $options.pickerConfig,
    disabled: $props.readOnly,
    "delete-enabled": true
  }, null, 8 /* PROPS */, ["modelValue", "plugin-options", "disabled"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_label, {
    title: $options.exitPlanLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[12] || (_cache[12] = function ($event) {
      return $options.handleLabelClick('exitPlan');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.reintegrationServiceLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[13] || (_cache[13] = function ($event) {
      return $options.handleLabelClick('reintegration');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.impactOfDiscontinuingLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[14] || (_cache[14] = function ($event) {
      return $options.handleLabelClick('impact');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_single_select, {
    ref: "exitPlan",
    "model-value": $props.data.existenceOfExitPlan,
    disabled: $props.readOnly,
    options: $props.options.existenceOfExitPlan,
    placeholder: $data.translate.exitPlan,
    "with-reset": true,
    "onUpdate:modelValue": _cache[15] || (_cache[15] = function ($event) {
      return $options.handleUpdate('existenceOfExitPlan', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "placeholder"]), _createVNode(_component_single_select, {
    ref: "reintegration",
    "model-value": $props.data.possibilityOfReintegration,
    disabled: $props.readOnly,
    options: $props.options.possibilityOfReintegration,
    placeholder: $data.translate.reintegrationService,
    "with-reset": true,
    "onUpdate:modelValue": _cache[16] || (_cache[16] = function ($event) {
      return $options.handleUpdate('possibilityOfReintegration', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "placeholder"]), _createVNode(_component_single_select, {
    ref: "impact",
    "model-value": $props.data.impactOfDiscontinuation,
    disabled: $props.readOnly,
    placeholder: $data.translate.impactOfDiscontinuing,
    options: $props.options.impactOfDiscontinuation,
    "with-reset": true,
    "onUpdate:modelValue": _cache[17] || (_cache[17] = function ($event) {
      return $options.handleUpdate('impactOfDiscontinuation', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "options"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_label, {
    title: $options.alternativeIctTppLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[18] || (_cache[18] = function ($event) {
      return $options.handleLabelClick('ictTppId');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.identificationOfAlternativeIctTppLabel,
    class: _normalizeClass($options.labelStyles),
    for: "alternativeIctTpp"
  }, null, 8 /* PROPS */, ["title", "class"]), _cache[21] || (_cache[21] = _createElementVNode("span", null, null, -1 /* HOISTED */)), _createVNode(_component_single_select, {
    ref: "ictTppId",
    "model-value": $props.data.alternativeIctTppIdentified,
    disabled: $props.readOnly,
    placeholder: $data.translate.alternativeIctTpp,
    options: $props.options.alternativeIctTppIdentified,
    "with-reset": true,
    "onUpdate:modelValue": _cache[19] || (_cache[19] = function ($event) {
      return $options.handleUpdate('alternativeIctTppIdentified', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "options"]), _createVNode(_component_input_field, {
    "model-value": $props.data.identificationOfAlternativeIctTpp,
    placeholder: $data.translate.alternativeIctTppOther,
    enabled: !$props.readOnly,
    "input-id": "alternativeIctTpp",
    "onUpdate:modelValue": _cache[20] || (_cache[20] = function ($event) {
      return $options.handleInputUpdate('identificationOfAlternativeIctTpp', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "enabled"]), _cache[22] || (_cache[22] = _createElementVNode("span", null, null, -1 /* HOISTED */))])], 64 /* STABLE_FRAGMENT */);
}