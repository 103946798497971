import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
import { useUserStore } from '@/Store/userStore';
import { ObjectTypes } from '@/constants/ObjectTypes';
function buildRisksMenu(settings, user) {
  var menuStructure = {
    main: []
  };
  var ENTERPRISE_RISK_MANAGEMENT_TYPE = 1;
  var risksIsEnabled = settings['app.module_risk_enabled'] === 1;
  var risks2IsEnabled = settings['app.module_risks2_enabled'] === 1;
  var risk2MREnabled = settings['feature.risk2_management_report'];
  var risksMRRights = risksIsEnabled && settings['risma.risk_reportgenerator'] && (user.level_risk >= UserLevels.PRIVILEGED || user.is_admin);
  var anyNumber = '\\d+';
  var aliasRegex = function aliasRegex(projectId) {
    return new RegExp("risk2/projects/".concat(projectId, "/periods/").concat(anyNumber, "/risk/\\S+/[^\\s]+"));
  };
  var riskProjects = user.projects.filter(function (project) {
    return project.objType === ObjectTypes.RISK_PROJECT;
  });
  var ERMprojects = riskProjects.filter(function (item) {
    return item.type === ENTERPRISE_RISK_MANAGEMENT_TYPE;
  });
  var userHasRiskProjectAccess = function userHasRiskProjectAccess(projectId, level) {
    return useUserStore().riskAccessLevelByProjectId(projectId) >= level;
  };
  if (ERMprojects.length) {
    ERMprojects.forEach(function (project) {
      if (project.enabled) {
        menuStructure.main.push(createERMMenuItems(project));
      }
    });
  }
  addProjectMenuItems(menuStructure.main, aliasRegex, settings, riskProjects);
  function createERMMenuItems(ERMproject) {
    var ermAliasRegex = new RegExp('risk2/projects/1/periods/\\S+/risk/\\S+/[^\\s]+');
    var ERM = {
      url: "/risk2/projects/".concat(ERMproject.id, "/my-risks"),
      title: ERMproject !== undefined ? ERMproject.title : 'Enterprise risk management',
      translatable: false,
      rights: risksIsEnabled,
      menuKey: MenuKeys.erm,
      aliasesRegex: ermAliasRegex,
      children: [{
        url: "/risk2/projects/".concat(ERMproject.id, "/my-risks"),
        title: 'My risks',
        exact: true,
        rights: risksIsEnabled,
        menuKey: MenuKeys.myRisks
      }, {
        url: "/risk2/projects/".concat(ERMproject.id, "/overview"),
        title: 'Overview',
        exact: true,
        rights: risksIsEnabled,
        menuKey: MenuKeys.riskOverview
      }, {
        url: "/risk2/projects/".concat(ERMproject.id, "/reports/risks"),
        title: 'Risk reporting',
        rights: risksIsEnabled
      }, {
        url: '/risk2/reports/pdfmain',
        title: 'Management reporting',
        rights: risksMRRights && ERMproject.id === 1
      }, {
        url: "/risk2/projects/".concat(ERMproject.id, "/dashboard"),
        title: 'Dashboard',
        rights: risksIsEnabled
      }]
    };
    return ERM;
  }
  function addProjectMenuItems(menu, regex, settings, riskProjects) {
    if (riskProjects) {
      var _ENTERPRISE_RISK_MANAGEMENT_TYPE = 1;
      var filteredProjects = riskProjects.filter(function (item) {
        return item.type !== _ENTERPRISE_RISK_MANAGEMENT_TYPE;
      });
      filteredProjects.map(function (project) {
        var menuItemBaseUrl = "".concat(RiskBaseUrls.projects, "/").concat(project.id);
        menu.push({
          url: "".concat(menuItemBaseUrl, "/overview"),
          title: project.title,
          translatable: false,
          rights: risks2IsEnabled,
          aliasesRegex: regex(project.id),
          children: prepareRiskChildrenMenu(project, menuItemBaseUrl, settings)
        });
      });
    }
  }
  function prepareRiskChildrenMenu(project, menuUrl, settings) {
    var riskChildReports = [{
      url: '/risk2/projects/' + project.id + '/reports/risks'
    }, {
      url: '/risk2/projects/' + project.id + '/reports/threats'
    }, {
      url: '/risk2/projects/' + project.id + '/reports/vulnerabilities'
    }];
    if (settings['feature.custom_risk_assessment_threats']) riskChildReports.push({
      url: '/risk2/projects/' + project.id + '/reports/threatassessments'
    });
    return [{
      url: "".concat(menuUrl, "/my-risks"),
      title: 'My risks',
      exact: true,
      rights: risks2IsEnabled,
      menuKey: MenuKeys.myRisks
    }, {
      url: "".concat(menuUrl, "/overview"),
      title: 'Overview',
      exact: true,
      rights: risks2IsEnabled
    }, {
      url: "".concat(menuUrl, "/reports"),
      title: 'Reports',
      rights: risks2IsEnabled,
      children: riskChildReports
    }, {
      url: "".concat(menuUrl, "/management-reports"),
      title: 'Management reporting',
      rights: risks2IsEnabled && risk2MREnabled && userHasRiskProjectAccess(project.id, UserLevels.SUPER)
    }, {
      url: "".concat(menuUrl, "/dashboard"),
      title: 'Dashboard',
      rights: risks2IsEnabled
    }];
  }
  return menuStructure;
}
export default buildRisksMenu;