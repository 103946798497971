import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-wrap items-center"
};
var _hoisted_2 = {
  key: 1,
  class: "table-tmp"
};
var _hoisted_3 = {
  class: "m-2 w-full table-fixed"
};
var _hoisted_4 = {
  class: "border-t border-gray-350"
};
var _hoisted_5 = {
  class: "m-auto w-9.5rem text-center p-2"
};
var _hoisted_6 = {
  class: "w-32 p-2 text-left border-t border-gray-350"
};
var _hoisted_7 = {
  class: "p-2 text-left"
};
var _hoisted_8 = {
  class: "break-words p-2 text-left"
};
var _hoisted_9 = {
  class: "m-auto w-32 text-center p-2"
};
var _hoisted_10 = {
  class: "w-32 p-2"
};
var _hoisted_11 = {
  class: "p-2"
};
var _hoisted_12 = {
  key: 1
};
var _hoisted_13 = {
  class: "break-words p-2"
};
var _hoisted_14 = ["innerHTML"];
var _hoisted_15 = {
  key: 2,
  class: "text-center font-semibold my-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$notification;
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.switchActivitiesFromOneOrganisationToAnother,
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), (_ctx$notification = _ctx.notification) !== null && _ctx$notification !== void 0 && _ctx$notification.message ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: _ctx.notification.type,
    class: "whitespace-pre-line",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.notification.message = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.notification.message), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_select, {
    modelValue: _ctx.selectedModules,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return _ctx.selectedModules = $event;
    }), $options.findData],
    class: "flex-grow mr-3 mb-2",
    options: _ctx.allModules,
    placeholder: $data.translate.chooseModules,
    "label-key": "option"
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
    modelValue: $data.selectedOrganisationId,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
      return $data.selectedOrganisationId = $event;
    }), $options.findData],
    class: "flex-grow -mt-1 mr-3 mb-2",
    options: $options.allOrganisations,
    disabled: $options.organisationLock,
    "with-search": true,
    placeholder: $data.translate.chooseOrganisationToUnassignActivitiesFrom
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "placeholder", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
    modelValue: $data.selectedToOrganisationId,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.selectedToOrganisationId = $event;
    }),
    options: $options.filteredOrganisations,
    disabled: $options.organisationToLock,
    "with-search": true,
    placeholder: $data.translate.chooseOrganisationToAssignActivitiesTo,
    class: "flex-grow -mt-1 mr-3 mb-2"
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "placeholder"]), _createVNode(_component_risma_button, {
    text: $data.translate.submit,
    disabled: $options.submitButtonLock,
    type: "save",
    class: "mb-2",
    onClick: $options.updateData
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])]), _ctx.tableData.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("table", _hoisted_3, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_4, [_createElementVNode("th", _hoisted_5, [_createVNode(_component_checkboxes, {
    "checkbox-size": 20,
    options: _ctx.selectAllActivities,
    "center-checkbox": true,
    onUpdated: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.toggleAll($event.isChecked);
    })
  }, null, 8 /* PROPS */, ["options"]), _createTextVNode(" " + _toDisplayString($data.translate.selectdeselectAllActivities), 1 /* TEXT */)]), _createElementVNode("th", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.module,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.title,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.description,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: "table-item-".concat(index),
      class: "border-t border-gray-350"
    }, [_createElementVNode("td", _hoisted_9, [_createVNode(_component_checkboxes, {
      "checkbox-size": 20,
      options: _ctx.checkGroup[index],
      "center-checkbox": true,
      onUpdated: function onUpdated($event) {
        return _ctx.onActivityToggle($event.isChecked, index);
      }
    }, null, 8 /* PROPS */, ["options", "onUpdated"])]), _createElementVNode("td", _hoisted_10, _toDisplayString(item.moduleTitle), 1 /* TEXT */), _createElementVNode("td", _hoisted_11, [item.url ? (_openBlock(), _createBlock(_component_preview_modal_link, {
      key: 0,
      activity: item,
      onUpdated: _cache[5] || (_cache[5] = function ($event) {
        return _ctx.itemToUpdate = $event;
      }),
      onDeleteItem: _ctx.deleteFromPreviewModal,
      onDismissModal: _ctx.updateFromPreviewModal
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(_ctx.getTitle(item)), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDeleteItem", "onDismissModal"])) : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.getTitle(item)), 1 /* TEXT */))]), _createElementVNode("td", _hoisted_13, [_createElementVNode("div", {
      innerHTML: _ctx.$truncate(item.description, 100)
    }, null, 8 /* PROPS */, _hoisted_14)])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _ctx.hasNoRecordsTitle ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
}