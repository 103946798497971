import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "container risks-list risks-my inline"
};
var _hoisted_2 = {
  key: 3
};
var _hoisted_3 = {
  key: 4
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_notification = _resolveComponent("notification");
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_expanded_card = _resolveComponent("expanded-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.isLoaded && $data.isLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.thereWasAnErrorPleaseTryAgain), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_catalogue_header, {
    "is-action-btn-enabled": false,
    "view-type": $setup.currentView,
    "view-types": $data.MY_RISKS_VIEWS,
    "is-overview-options-available": false,
    "overview-options-state": $data.overviewOptionsState,
    "onUpdate:viewType": _cache[0] || (_cache[0] = function ($event) {
      return $setup.currentView = $event;
    }),
    "onUpdate:overviewoption": _cache[1] || (_cache[1] = function (state) {
      return $data.overviewOptionsState = state;
    })
  }, null, 8 /* PROPS */, ["view-type", "view-types", "overview-options-state"]), $data.isLoaded && $setup.currentView === $data.viewTypes.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_preview_list_tile, {
    activities: $data.risks,
    title: $data.translate.myRisks + ' - ' + $options.periodOrScoringTitle,
    class: _normalizeClass(['preview-list', $options.viewClass]),
    "cookie-hidden-name": "rismahider-my-page-risks",
    "watermark-image": "url('/assets/svg/ikon_risk.svg')",
    onUpdateFromPreviewMode: $options.updateFromPreviewMode,
    onDeleteItem: $options.deleteRiskFromList
  }, null, 8 /* PROPS */, ["activities", "title", "class", "onUpdateFromPreviewMode", "onDeleteItem"]), $options.isERM ? (_openBlock(), _createBlock(_component_preview_list_tile, {
    key: 0,
    activities: $data.dailyTeamRisks,
    title: $data.translate.dailyTeamRisks,
    class: _normalizeClass(['preview-list', $options.viewClass]),
    "cookie-hidden-name": "rismahider-my-page-dt-risks",
    "watermark-image": "url('/assets/svg/ikon_risk.svg')"
  }, null, 8 /* PROPS */, ["activities", "title", "class"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $data.isLoaded && $setup.currentView === $data.viewTypes.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.myRisks,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.risks, function (activity, index) {
    return _openBlock(), _createBlock(_component_expanded_card, {
      key: "my-expand-card-".concat(index),
      activity: activity,
      users: _ctx.activeUsers,
      organisations: _ctx.organisations,
      "activity-types": $data.activityTypes,
      scoring: $data.scoring,
      period: _ctx.period,
      "watermark-image": "url('/assets/svg/ikon_risk.svg')",
      onUpdateFromPreviewMode: $options.updateFromPreviewMode,
      onDeleteItem: $options.deleteRiskFromList
    }, null, 8 /* PROPS */, ["activity", "users", "organisations", "activity-types", "scoring", "period", "onUpdateFromPreviewMode", "onDeleteItem"]);
  }), 128 /* KEYED_FRAGMENT */)), $options.isERM ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $data.translate.dailyTeamRisks,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dailyTeamRisks, function (activity, index) {
    return _openBlock(), _createBlock(_component_expanded_card, {
      key: "dt-expand-card-".concat(index),
      activity: activity,
      users: _ctx.activeUsers,
      organisations: _ctx.organisations,
      "activity-types": $data.activityTypes,
      scoring: $data.scoring,
      period: _ctx.period,
      "watermark-image": "url('/assets/svg/ikon_risk.svg')",
      onUpdateFromPreviewMode: $options.updateFromPreviewMode
    }, null, 8 /* PROPS */, ["activity", "users", "organisations", "activity-types", "scoring", "period", "onUpdateFromPreviewMode"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}