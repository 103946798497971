function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { addCompany } from '@/api/admin/companies';
import { getCVRCompany } from '@/api/external/cvrapi';
import { getAllProcessors, create as createInformationAsset } from '@/api/compliance/informationAssets';
import InputField from '@/components/Atoms/Inputs/InputField';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import RismaSplitButton from '@/components/Atoms/Buttons/RismaSplitButton';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import StaticTable from '@/components/Molecules/StaticTable';
import AddContractPartyModal from '@/components/Organisms/Contracts/AddContractPartyModal';
import { VatCountryCode } from '@/constants/admin/VatCountryCode';
import { getCVRQueryParam, getCompanyWithUrl } from '@/utils/format/companies';
import { CreateCompanyModes } from '@/constants/companies/companiesColumns';
export default {
  name: 'CreateCompany',
  components: {
    InputField: InputField,
    Notification: Notification,
    RismaSplitButton: RismaSplitButton,
    Modal: Modal,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable,
    AddContractPartyModal: AddContractPartyModal,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    allCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Needed for external button'
    },
    linkedCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    creationMode: {
      type: String,
      required: false,
      default: 'create',
      validator: function validator(type) {
        return type === 'create' || type === 'add';
      }
    }
  },
  emits: ['createCompany', 'showNotification', 'addInternalCompany'],
  data: function data() {
    return {
      showCreateViaVatModal: false,
      showManuallyCreateCompanyModal: false,
      showAddViaVatModule: false,
      companyName: '',
      countryCode: VatCountryCode.DK.value,
      companySearchFieldValue: '',
      companyDataset: [],
      selectedCompanyIndex: null,
      checkedCreateAsDataProcessorIndex: null,
      createCompanyNotification: {
        message: '',
        type: null
      },
      createCompanyTimer: 0,
      foundCompanies: null,
      dataProcessors: [],
      isCompanyNameValid: true,
      isLoaded: true,
      translate: getTranslate['CreateCompany']()
    };
  },
  computed: {
    createAsDataProcessorSelected: function createAsDataProcessorSelected() {
      return this.checkedCreateAsDataProcessorIndex !== null && this.checkedCreateAsDataProcessorIndex === this.selectedCompanyIndex;
    },
    isCountryCodeSE: function isCountryCodeSE() {
      return this.countryCode === VatCountryCode.SE.value;
    },
    searchFieldLabel: function searchFieldLabel() {
      return this.isCountryCodeSE ? this.translate.searchingByNameIsNotAvailableForSeCompani : "".concat(this.translate.vatNumber, " ").concat(this.translate.orCompanyName);
    },
    selectedCompanyToCreate: function selectedCompanyToCreate() {
      if (!this.foundCompanies) return {};
      var company = this.foundCompanies[this.selectedCompanyIndex];
      var countryCode = company.countryCode.toUpperCase();
      var vatNo = "".concat(company.vatNo);
      return _objectSpread(_objectSpread({}, company), {}, {
        countryCode: countryCode,
        vatNo: vatNo
      });
    },
    countryCodeOptions: function countryCodeOptions() {
      return Object.values(VatCountryCode).map(function (_ref) {
        var value = _ref.value;
        return {
          value: value,
          label: value
        };
      });
    },
    companyColumns: function companyColumns() {
      var _this = this;
      var columns = ['Choose', 'Name', 'Address', 'City', 'VAT number', 'Also create data processor'];
      return columns.map(function (column) {
        return _this.$trans(column);
      });
    },
    selectedDataProcessorExists: function selectedDataProcessorExists() {
      var _this2 = this;
      var processorExists = this.dataProcessors.find(function (processor) {
        return processor.vatNo === _this2.selectedCompanyToCreate.vatNo || processor.name === _this2.selectedCompanyToCreate.name;
      });
      return this.createAsDataProcessorSelected && processorExists;
    },
    isCreateMode: function isCreateMode() {
      return this.creationMode === 'create';
    },
    content: function content() {
      return CreateCompanyModes(this.$trans)[this.creationMode];
    }
  },
  mounted: function mounted() {
    this.getProcessors();
  },
  methods: {
    getProcessors: function getProcessors() {
      var _this3 = this;
      return getAllProcessors().then(function (_ref2) {
        var list = _ref2.list;
        return _this3.dataProcessors = _this3.getDataProcessorsFlat(list);
      });
    },
    getDataProcessorsFlat: function getDataProcessorsFlat(processors) {
      return processors.reduce(function (list, processor) {
        var _processor$childrenOb;
        list.push(processor);
        if ((_processor$childrenOb = processor.childrenObjects) !== null && _processor$childrenOb !== void 0 && _processor$childrenOb.length) list.push.apply(list, _toConsumableArray(processor.childrenObjects));
        return list;
      }, []);
    },
    resetCreateAsDataProcessorIndex: function resetCreateAsDataProcessorIndex(value) {
      if (value === this.selectedCompanyIndex) return;
      this.checkedCreateAsDataProcessorIndex = null;
    },
    openCreateViaVatModal: function openCreateViaVatModal() {
      if (this.isCreateMode) {
        this.showCreateViaVatModal = true;
        return;
      }
      this.showAddViaVatModule = true;
    },
    openManuallyCreateCompanyModal: function openManuallyCreateCompanyModal() {
      this.showManuallyCreateCompanyModal = true;
    },
    hideCreateViaVatModal: function hideCreateViaVatModal() {
      this.showCreateViaVatModal = false;
      this.resetCreateViaVatModalData();
    },
    hideManuallyCreateCompanyModal: function hideManuallyCreateCompanyModal() {
      this.showManuallyCreateCompanyModal = false;
      this.companyName = '';
      this.isCompanyNameValid = true;
    },
    manuallyCreateCompany: function manuallyCreateCompany() {
      var _this4 = this;
      this.isCompanyNameValid = !!this.companyName;
      if (!this.isCompanyNameValid) return;
      var data = {
        name: this.companyName
      };
      this.createCompany(data).then(function (company) {
        if (_this4.isCreateMode) {
          company && _this4.$router.push({
            name: 'CompanyDescription',
            params: {
              id: company.id
            }
          });
        } else {
          _this4.hideManuallyCreateCompanyModal();
          _this4.$emit('createCompany', getCompanyWithUrl(company), true);
        }
      });
    },
    createCompany: function createCompany(data) {
      var _this5 = this;
      return addCompany(data).catch(function (_ref3) {
        var response = _ref3.response;
        _this5.$emit('showNotification', _this5.$trans(response === null || response === void 0 ? void 0 : response.error));
      });
    },
    resetCreateViaVatModalData: function resetCreateViaVatModalData() {
      this.countryCode = VatCountryCode.DK.value;
      this.companySearchFieldValue = '';
      this.resetSearchResults();
    },
    resetSearchResults: function resetSearchResults() {
      this.companyDataset = [];
      this.selectedCompanyIndex = null;
      this.checkedCreateAsDataProcessorIndex = null;
    },
    createCompanyViaVat: function createCompanyViaVat() {
      var _this6 = this;
      if (this.selectedDataProcessorExists) {
        this.showCreateCompanyNotification(this.translate.dataProcessorWithTheSameVatNumberOrNameAl);
        return;
      }
      this.createCompany(this.selectedCompanyToCreate).then(function (companyData) {
        if (!companyData) return;
        _this6.$emit('createCompany', getCompanyWithUrl(companyData));
        if (_this6.createAsDataProcessorSelected) {
          _this6.createDataProcessor(companyData);
        }
      }).finally(function () {
        return _this6.hideCreateViaVatModal();
      });
    },
    showCreateCompanyNotification: function showCreateCompanyNotification(message) {
      var _this7 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.createCompanyNotification.message = message;
      this.createCompanyNotification.type = type;
      clearTimeout(this.createCompanyTimer);
      this.timer = setTimeout(function () {
        _this7.createCompanyNotification.message = '';
      }, this.$debounceTimeout);
    },
    searchCompany: function searchCompany() {
      var _this8 = this;
      if (!this.companySearchFieldValue) return;
      this.resetSearchResults();
      var params = getCVRQueryParam(this.countryCode);
      this.isLoaded = false;
      return getCVRCompany(this.companySearchFieldValue, params).then(function (_ref4) {
        var list = _ref4.list;
        _this8.foundCompanies = list;
        _this8.companyDataset = _this8.createCompanyDataset();
      }).catch(function (error) {
        if (error.status === 404) {
          _this8.showCreateCompanyNotification(_this8.translate.noCompaniesFound);
        } else {
          _this8.showCreateCompanyNotification(_this8.translate.anErrorOccurred);
        }
      }).finally(function () {
        return _this8.isLoaded = true;
      });
    },
    createCompanyDataset: function createCompanyDataset() {
      return this.foundCompanies.map(function (item, index) {
        return {
          selected: index,
          name: item.name,
          address: item.address,
          city: item.city,
          vatNumber: item.vatNo,
          dataProcessor: index
        };
      });
    },
    toggleCheckedAsDataProcessor: function toggleCheckedAsDataProcessor(index, value) {
      if (value) {
        this.checkedCreateAsDataProcessorIndex = index;
        return;
      }
      this.checkedCreateAsDataProcessorIndex = null;
    },
    createDataProcessor: function createDataProcessor(_ref5) {
      var _this9 = this;
      var name = _ref5.name,
        vatNo = _ref5.vatNo,
        id = _ref5.id;
      var data = {
        name: name,
        vatNo: vatNo,
        companyId: id
      };
      return createInformationAsset('processors', data).then(function (data) {
        return _this9.dataProcessors.push(data);
      });
    },
    onAddContractParty: function onAddContractParty(company) {
      this.showAddViaVatModule = false;
      this.$emit('createCompany', getCompanyWithUrl(company));
    },
    addInternalCompany: function addInternalCompany(companyId) {
      this.showAddViaVatModule = false;
      this.$emit('addInternalCompany', companyId);
    }
  }
};