function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import ManagementReportEditModal from '@/components/Molecules/Modal/ManagementReportEditModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import DownloadLink from '@/components/Atoms/DownloadLink/DownloadLink';
import { getRisk2ManagementReports, createRisk2ManagementReport, deleteRisk2ManagementReport, editRisk2ManagementReport } from '@/api/risk/risk';
import { findPropById, findPropsByIds } from '@/utils/Utils';
export default {
  name: 'ManagementReport',
  components: {
    PreviewList: PreviewList,
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    ManagementReportEditModal: ManagementReportEditModal,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    DownloadLink: DownloadLink
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      default: null
    }
  },
  data: function data() {
    return {
      loading: true,
      managementReports: [],
      editModalShown: false,
      previewFile: null,
      reportToDelete: null,
      reportToEdit: null,
      fileName: 'ManagementReport',
      translate: getTranslate['ManagementReport']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUserStore, ['riskProjectById'])), {}, {
    currentRiskProject: function currentRiskProject() {
      return this.riskProjectById(this.projectId);
    },
    readOnly: function readOnly() {
      var _this$currentRiskProj;
      return !!((_this$currentRiskProj = this.currentRiskProject) !== null && _this$currentRiskProj !== void 0 && _this$currentRiskProj.locked);
    },
    relevantThreatsEnabled: function relevantThreatsEnabled() {
      var _this$currentRiskProj2;
      return !!((_this$currentRiskProj2 = this.currentRiskProject) !== null && _this$currentRiskProj2 !== void 0 && _this$currentRiskProj2.threatsEnabled);
    },
    dataset: function dataset() {
      var _this = this;
      return this.managementReports.map(function (report) {
        var result = {
          title: report.title,
          createdBy: findPropById(report.userId, _this.users, 'display_name'),
          organisations: findPropsByIds(report.organisationIds, _this.organisations, 'visible_name'),
          includeComments: report.includeComments ? _this.translate.yes : _this.translate.no,
          includeRelevantThreats: report.includeRelevantThreats ? _this.translate.yes : _this.translate.no,
          download: _this.getDownloadLink(_this.projectId, report.id),
          edit: {
            report: report
          },
          delete: {
            id: report.id,
            title: report.title
          }
        };
        if (!_this.relevantThreatsEnabled) {
          delete result.includeRelevantThreats;
        }
        if (_this.readOnly) {
          delete result.edit;
          delete result.delete;
        }
        return result;
      });
    },
    columns: function columns() {
      var result = [this.translate.title, this.translate.createdBy, this.translate.organisations, this.translate.includeComments, this.relevantThreatsEnabled && this.translate.includeRelevantThreats, this.translate.download, !this.readOnly && this.translate.edit, !this.readOnly && this.translate.delete];
      return result.filter(Boolean);
    }
  }),
  mounted: function mounted() {
    this.getManagementReports();
  },
  methods: {
    editReport: function editReport() {
      var _this2 = this;
      var report = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (report) {
        this.reportToEdit = _objectSpread(_objectSpread({}, report), {}, {
          organisations: report.organisationIds.map(function (organisationId) {
            return {
              id: organisationId,
              visible_name: findPropById(organisationId, _this2.organisations, 'visible_name')
            };
          })
        });
      }
      this.editModalShown = true;
    },
    closeEditModal: function closeEditModal() {
      this.reportToEdit = null;
      this.editModalShown = false;
    },
    getManagementReports: function getManagementReports() {
      var _this3 = this;
      this.loading = true;
      return getRisk2ManagementReports(this.projectId).then(function (_ref) {
        var list = _ref.list;
        _this3.managementReports = list;
        _this3.loading = false;
      });
    },
    createManagementReport: function createManagementReport(body) {
      var _this4 = this;
      return createRisk2ManagementReport(this.projectId, body).then(function (newReport) {
        _this4.managementReports.push(newReport);
        _this4.closeEditModal();
      });
    },
    editManagementReport: function editManagementReport(body) {
      var _this5 = this;
      return editRisk2ManagementReport(this.projectId, body).then(function (newReport) {
        var reportIdx = _this5.managementReports.findIndex(function (report) {
          return report.id === body.id;
        });
        if (reportIdx !== -1) {
          _this5.managementReports[reportIdx] = newReport;
        }
        _this5.closeEditModal();
      });
    },
    deleteManagementReport: function deleteManagementReport() {
      var _this6 = this;
      return deleteRisk2ManagementReport(this.projectId, this.reportToDelete.id).then(function () {
        _this6.managementReports = _this6.managementReports.filter(function (report) {
          return report.id !== _this6.reportToDelete.id;
        });
        _this6.reportToDelete = null;
      });
    },
    handleDeleteConfirmation: function handleDeleteConfirmation(data) {
      var _this7 = this;
      this.reportToDelete = data;
      this.$confirm(this.translate.areYouSure, "".concat(this.reportToDelete.title, " ").concat(this.translate.willBeRemoved), function (res) {
        return res ? _this7.deleteManagementReport() : _this7.reportToDelete = null;
      }, {
        buttonOkText: this.translate.ok,
        buttonDismissText: this.translate.cancel
      });
    },
    getDownloadLink: function getDownloadLink(projectId, reportId) {
      return "risks/projects/".concat(projectId, "/managementreport/").concat(reportId, "/download");
    }
  }
};