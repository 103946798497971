// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gap-logic-information-box-question[data-v-8705b932] {
  font-size: 14px;
  padding: 1rem 0 0;
  color: #FFFFFF;
}
.gap-logic-information-box-question .question-container[data-v-8705b932] {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  display: inline-block;
}
.gap-logic-information-box-question .question-container .question-number[data-v-8705b932] {
  font-weight: bolder;
}
.gap-logic-information-box-question .help-text[data-v-8705b932] {
  font-style: italic;
}
.gap-logic-information-box-question.expanded .question-container[data-v-8705b932] {
  white-space: initial;
}
.gap-logic-information-box-question .answer-letter[data-v-8705b932] {
  font-weight: bolder;
}
.gap-logic-information-box-question .col-1[data-v-8705b932],
.gap-logic-information-box-question .col-11[data-v-8705b932],
.gap-logic-information-box-question .col-12[data-v-8705b932] {
  padding-left: 10px;
  padding-right: 0;
}
.gap-logic-information-box-question .col-1[data-v-8705b932] {
  padding-left: 20px;
}
.gap-logic-information-box-question p[data-v-8705b932] {
  margin: 0.5rem 0 0;
}
.gap-logic-information-box-question .show-more[data-v-8705b932],
.gap-logic-information-box-question .show-less[data-v-8705b932] {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
