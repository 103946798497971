function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Modal from '@/components/Molecules/Modal/Modal';
import { ModalDismissTypes } from '@/constants/ModalDismissTypes';
export default {
  components: {
    Modal: Modal,
    InputField: InputField,
    Checkboxes: Checkboxes,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    modalData: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'data that is used on update'
    },
    testButtonLoadState: {
      type: Boolean,
      required: false,
      default: false,
      note: 'shows loading indicator on test'
    }
  },
  emits: ['dismiss', 'accept', 'testConnection'],
  data: function data() {
    return {
      preparedData: {
        username: '',
        emailAddress: '',
        password: '',
        mailServerAddress: '',
        mailServerPort: '',
        service: '',
        tls: false,
        ssl: false
      },
      translate: getTranslate['InboxModal']()
    };
  },
  computed: {
    type: function type() {
      return Object.keys(this.modalData).length ? 'update' : 'create';
    },
    modalHeader: function modalHeader() {
      return "".concat(this.type === 'create' ? this.translate.create : this.translate.update, " ").concat(this.translate.email.toLowerCase());
    },
    buttonText: function buttonText() {
      return this.type === 'create' ? this.translate.create : this.translate.update;
    },
    checkboxOptions: function checkboxOptions() {
      return [{
        value: 'tls',
        checked: !!this.modalData.tls,
        label: 'TLS'
      }, {
        value: 'ssl',
        checked: !!this.modalData.ssl,
        label: 'SSL'
      }];
    }
  },
  mounted: function mounted() {
    this.preparedData = _objectSpread(_objectSpread({}, this.preparedData), this.modalData);
  },
  methods: {
    handleAccept: function handleAccept() {
      this.$emit('accept', {
        type: this.type,
        data: this.preparedData
      });
    },
    handleDismiss: function handleDismiss(type) {
      if (type === ModalDismissTypes.ICON) return this.$emit('dismiss');
      this.$emit('testConnection', this.preparedData);
    },
    onCheckboxChange: function onCheckboxChange(_ref) {
      var value = _ref.value,
        isChecked = _ref.isChecked;
      this.preparedData[value] = isChecked;
    }
  }
};