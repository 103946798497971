function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
import { defaultSorting } from '@/utils/sort';
export default {
  name: 'DataFlowLegalBasisAndStoragePeriod',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    },
    extendedWithStoragePeriod: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      DataFlowFields: DataFlowFields,
      translate: getTranslate['DataFlowLegalBasisAndStoragePeriod']()
    };
  },
  computed: {
    typesByQuestion: function typesByQuestion() {
      var _this = this;
      var types = this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].reduce(function (acc, cur) {
        var question = _this.data.questionsById[cur.questionId];
        var questionNumber = question.questionnumber;
        var legalBasisQuestionId = question.questionobject.legalBasisQuestionId;
        var storagePeriodQuestionId = question.questionobject.retentionQuestionId;
        if (!legalBasisQuestionId && (!_this.extendedWithStoragePeriod || !storagePeriodQuestionId)) return acc;
        if (!acc[questionNumber]) {
          var _this$data$questionsB, _this$data$questionsB2;
          acc[questionNumber] = {
            dataTypes: [],
            legalBasisQuestionNumber: ((_this$data$questionsB = _this.data.questionsById[legalBasisQuestionId]) === null || _this$data$questionsB === void 0 ? void 0 : _this$data$questionsB.questionnumber) || '',
            legalBasisOptions: _this.getQuestionOptions(legalBasisQuestionId),
            storagePeriodQuestionNumber: ((_this$data$questionsB2 = _this.data.questionsById[storagePeriodQuestionId]) === null || _this$data$questionsB2 === void 0 ? void 0 : _this$data$questionsB2.questionnumber) || '',
            storagePeriodOptions: _this.getQuestionOptions(storagePeriodQuestionId)
          };
        }
        acc[questionNumber].dataTypes.push(cur);
        return acc;
      }, {});
      Object.keys(types).forEach(function (questionNumber) {
        types[questionNumber].dataTypes.sort(defaultSorting('text'));
      });
      return types;
    }
  },
  methods: {
    getQuestionOptions: function getQuestionOptions(id) {
      var _this$data$questionsB3;
      var options = (_this$data$questionsB3 = this.data.questionsById[id]) === null || _this$data$questionsB3 === void 0 ? void 0 : _this$data$questionsB3.options;
      if (!options) return [];
      return Object.entries(options).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          label = _ref2[1];
        return {
          value: value,
          label: label,
          questionId: id
        };
      });
    },
    getSelectedOption: function getSelectedOption(options, id) {
      var _options$find = options.find(function (o) {
          return +o.value === +id;
        }),
        label = _options$find.label,
        value = _options$find.value,
        questionId = _options$find.questionId;
      return {
        id: value,
        text: label,
        questionId: questionId
      };
    },
    onOptionSelect: function onOptionSelect(options, id, uniqueId, field) {
      this.$emit('update:selected', {
        value: id && this.getSelectedOption(options, id),
        field: field,
        uniqueId: uniqueId
      });
    },
    selectAll: function selectAll(options, dataTypes, uniqueId, field) {
      var _this2 = this;
      var selectedOptionId = this.selectedOptions[field][uniqueId];
      if (!selectedOptionId) return;
      dataTypes.forEach(function (type) {
        _this2.$emit('update:selected', {
          value: _this2.getSelectedOption(options, selectedOptionId),
          uniqueId: type.uniqueId,
          field: field
        });
      });
    }
  }
};