import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { Bar as BarChart } from 'vue-chartjs';
import { toLocalDate, getMaxDate, getMinDate, toPureDateFormat } from '@/utils/date';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import { xAxisInteractionPlugin } from '@/components/Molecules/Charts/chartPlugins';
import { zoomRangeSlider } from '@/components/Molecules/Charts/zoomRangeSliderPlugin';
import { DateFormats } from '@/constants/DateFormats';
import { generateKey } from '@/utils/Utils';
export default {
  name: 'GanttChartJs',
  introduction: 'horizontal bar chart with using the vue-chartjs',
  description: 'base horizontal bar chart component',
  token: "<gantt-chart-js\n      :datasets=\"[{\n                    backgroundColor: '#5AA744',\n                    data: [\n                        ['2002-03-15', '2008-04-22'],\n                    ]\n                }]\"\n      :labels=\"['Label 1']\" /> ",
  components: {
    BarChart: BarChart,
    ExportFrame: ExportFrame
  },
  props: {
    datasets: {
      required: false,
      type: Array,
      default: function _default() {
        return [{
          data: []
        }];
      },
      note: 'the data to create the component'
    },
    labels: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    height: {
      require: false,
      type: Number,
      default: 400
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    },
    xAxisClickEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Adds an underline on hover for X-axis labels'
    }
  },
  emits: ['click:xAxis'],
  data: function data() {
    return {
      selectedIndex: null,
      chartInitialized: false,
      displayFormats: {
        day: DateFormats.DATE_LONG_FORMAT,
        month: DateFormats.DATE_SHORT_FORMAT,
        year: DateFormats.DATE_YEAR_FORMAT,
        quarter: DateFormats.DATE_SHORT_FORMAT
      },
      translate: {
        from: this.$trans('From'),
        to: this.$trans('To')
      },
      chartKey: generateKey()
    };
  },
  computed: {
    pluginsInUse: function pluginsInUse() {
      return [zoomRangeSlider(this.startDate, this.endDate)];
    },
    defaultOptions: function defaultOptions() {
      var _this = this;
      return {
        locale: 'en',
        indexAxis: 'y',
        layout: {
          padding: {
            top: 70,
            right: 10
          }
        },
        scales: {
          x: {
            type: 'time',
            position: 'top',
            time: {
              displayFormats: this.displayFormats
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0
            },
            min: this.startDate,
            max: this.endDate
          }
        },
        events: ['mousemove', 'mouseout', 'click', 'mousedown', 'mouseup'],
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function label(ctx) {
                var array = JSON.parse(ctx.formattedValue);
                return "".concat(_this.translate.from, " ").concat(toLocalDate(array[0]), " - ").concat(_this.translate.to, " ").concat(toLocalDate(array[1]));
              }
            }
          }
        },
        maintainAspectRatio: false
      };
    },
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    },
    startDate: function startDate() {
      return toPureDateFormat(getMinDate(this.datasets[0].data.map(function (date) {
        return date[0];
      })));
    },
    endDate: function endDate() {
      var _this$datasets;
      return toPureDateFormat(getMaxDate((_this$datasets = this.datasets[this.datasets.length - 1]) === null || _this$datasets === void 0 || (_this$datasets = _this$datasets.data) === null || _this$datasets === void 0 ? void 0 : _this$datasets.map(function (date) {
        return date[1];
      })));
    },
    containerStyles: function containerStyles() {
      return {
        height: this.height + 'px'
      };
    }
  },
  watch: {
    datasets: {
      handler: function handler() {
        this.chartKey = generateKey();
        this.updatePlugins();
      }
    }
  },
  created: function created() {
    this.updatePlugins();
  },
  methods: {
    updatePlugins: function updatePlugins() {
      var _this$datasets$,
        _this2 = this;
      if (this.xAxisClickEnabled && (_this$datasets$ = this.datasets[0]) !== null && _this$datasets$ !== void 0 && (_this$datasets$ = _this$datasets$.data) !== null && _this$datasets$ !== void 0 && _this$datasets$.length) {
        this.pluginsInUse.push(xAxisInteractionPlugin(function (index) {
          return _this2.$emit('click:xAxis', index);
        }));
      }
    }
  }
};