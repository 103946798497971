function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { WrongModules } from '@/constants/WrongModules';
import { TabTypes } from '@/constants/Tabs';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
var countLinks = function countLinks(links, wrongModules) {
  var linksCopy = JSON.parse(JSON.stringify(links));
  wrongModules.forEach(function (wrongModule) {
    delete linksCopy[wrongModule];
  });
  return Object.values(linksCopy).reduce(function (total, current) {
    return current.length + total;
  }, 0);
};
export var getLinksLabel = function getLinksLabel(item) {
  if (!item || !item.links) return {};
  var wrongModules = _toConsumableArray(WrongModules);
  if (item.objType === ObjectTypes.CONTRACT) wrongModules.push(MODULES.CONTRACTS);
  var linksNumber = countLinks(item.links, wrongModules);
  if (item.governanceReviewLinks) {
    linksNumber += countLinks(item.governanceReviewLinks, WrongModules);
  }
  if (item.riskAssessmentActivity) {
    linksNumber += 1;
  }
  return _defineProperty({}, TabTypes.LINKS, {
    labelExtra: linksNumber
  });
};