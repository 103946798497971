function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTreeByProjectId as _getTreeByProjectId } from '@/api/compliance/tree';
import { getIdsFromTree } from '@/utils/tree';
import { formatNodes } from '@/utils/format/Compliance';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
var getUsersProject = function getUsersProject(users, projectId) {
  return users.filter(function (user) {
    return user.projects.some(function (project) {
      return project.id === +projectId && project.objType === ObjectTypes.COMPLIANCE_PROJECT && project.access_level > UserLevels.NONE;
    });
  });
};
export var RismaSelectorBarMixin = {
  data: function data() {
    return {
      users: [],
      isLoadedStaticData: false,
      allNodeIds: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    storeUsers: 'activeUsers'
  })), mapState(useOrganisationsStore, {
    orgs: 'organisations'
  })),
  mounted: function mounted() {
    this.loadStaticData();
  },
  methods: {
    loadStaticData: function loadStaticData() {
      var _this = this;
      this.isLoadedStaticData = false;
      return Promise.all([this.setUsers(this.projectId), this.getTreeByProjectId(this.projectId), this.loadData()]).then(function () {
        _this.setFiltersFromLocalStorage();
        _this.isLoadedStaticData = true;
      });
    },
    setUsers: function setUsers() {
      var projectId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.users = projectId === 0 ? this.storeUsers : getUsersProject(this.storeUsers, projectId);
    },
    getTreeByProjectId: function getTreeByProjectId(projectId) {
      var _this2 = this;
      return _getTreeByProjectId(projectId).then(function (_ref) {
        var children = _ref.children;
        _this2.nodes = formatNodes(children);
        _this2.allNodeIds = getIdsFromTree(children);
      });
    },
    saveState: function saveState(objectToSave) {
      return localStorage.setItem(this.localStorageKey, JSON.stringify(objectToSave));
    },
    loadState: function loadState() {
      var state;
      try {
        state = JSON.parse(localStorage.getItem(this.localStorageKey));
      } catch (_unused) {
        state = null;
      }
      return state;
    },
    setFiltersFromLocalStorage: function setFiltersFromLocalStorage() {
      var storageObj = this.loadState();
      if (!storageObj) return;
      if (storageObj.filterObj) {
        this.lastAppliedFilters = storageObj.filterObj;
      }
      if (storageObj.includeChildNodes !== undefined) {
        this.includeChildToggleOption = storageObj.includeChildNodes;
      }
      if (storageObj.includeFrameworkChildToggleOption !== undefined) {
        this.includeFrameworkChildToggleOption = storageObj.includeFrameworkChildToggleOption;
      }
    },
    loadData: function loadData() {
      return Promise.resolve(); //for cases when any of dashboards don`t need to load any data
    }
  }
};