// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-menu-item[data-v-235232cc] {
  position: relative;
}
.vertical-menu-item .vertical-menu-item-marker[data-v-235232cc] {
  position: absolute;
  width: 8px;
  height: 100%;
}
.vertical-menu-item .vertical-menu-link[data-v-235232cc] {
  display: block;
  padding: 15px;
  color: #4D4D4D;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
}
.vertical-menu-item .vertical-menu-link[data-v-235232cc]:hover {
  background: #ebebeb;
}
.vertical-menu-item .vertical-menu-link.router-link-active + span[data-v-235232cc], .vertical-menu-item .vertical-menu-link.router-link-active[data-v-235232cc] {
  font-weight: bold;
  background: #ebebeb;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
