import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "card-wrapper"
};
var _hoisted_2 = {
  class: "card card-compliance"
};
var _hoisted_3 = {
  class: "trafficChangeWrap"
};
var _hoisted_4 = {
  class: "trafficlight_placer"
};
var _hoisted_5 = {
  class: "subtitle"
};
var _hoisted_6 = {
  class: "card-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_progress_bar = _resolveComponent("risma-progress-bar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_traffic_light, {
    color: _ctx.getTrafficLightByObjectTypeAndNumber($props.activity.objType, $props.activity.traffic_light).color
  }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("span", {
    class: _normalizeClass("header-placer truncate ".concat($props.activity.traffic_light === 1 ? 'red' : ''))
  }, [_createVNode(_component_preview_modal_link, {
    activity: $props.activity
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.activity.title
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])], 2 /* CLASS */)]), _createElementVNode("span", _hoisted_5, _toDisplayString($props.activity.project.title), 1 /* TEXT */), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$truncate($options.stripHtml($props.activity.description), 100)), 1 /* TEXT */), $props.activity.values.questions_count > 0 ? (_openBlock(), _createBlock(_component_risma_progress_bar, {
    key: 0,
    value: $props.activity.values.percentage_questions_answered,
    label: $props.activity.values.questions_answered + ' / ' + $props.activity.values.questions_count + ' ' + $data.translate.questionsAnswered
  }, null, 8 /* PROPS */, ["value", "label"])) : _createCommentVNode("v-if", true)])]);
}