import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_save_button = _resolveComponent("save-button");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.saveButtonShown ? (_openBlock(), _createBlock(_component_save_button, {
    key: 0,
    "save-status": $data.saveStatus,
    onSaveStatusClicked: _cache[0] || (_cache[0] = function ($event) {
      return $options.showResult('SaveButton clicked');
    })
  }, null, 8 /* PROPS */, ["save-status"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_toggle, {
    modelValue: $data.saveButtonShown,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.saveButtonShown = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue"]), _cache[2] || (_cache[2] = _createElementVNode("span", null, "Show floating button", -1 /* HOISTED */))]);
}