import i18n from '@/i18n';
export var getTranslate = {
  ActivityFeedCard: function ActivityFeedCard() {
    return {
      initiateCompletion: i18n.t('Initiate completion'),
      initiateReview: i18n.t('Initiate review'),
      description: i18n.t('Description'),
      deadline: i18n.t('Deadline'),
      expirationDate: i18n.t('Expiration date'),
      deadlineWas: i18n.t('Deadline was'),
      ago: i18n.t('ago'),
      toDeadline: i18n.t('to deadline'),
      approved: i18n.t('Approved'),
      notApproved: i18n.t('Not approved')
    };
  },
  CompactCard: function CompactCard() {
    return {
      none: i18n.t('None'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      automatic: i18n.t('Automatic'),
      manual: i18n.t('Manual'),
      preventive: i18n.t('Preventive'),
      detective: i18n.t('Detective'),
      high: i18n.t('High'),
      notSet: i18n.t('Not set'),
      low: i18n.t('Low'),
      review: i18n.t('Review'),
      completion: i18n.t('Completion'),
      signoffPeriodPending: i18n.t('Signoff period pending')
    };
  },
  ExpandedCard: function ExpandedCard() {
    return {
      start: i18n.t('Start date'),
      deadline: i18n.t('Deadline'),
      status: i18n.t('Status'),
      description: i18n.t('Description'),
      target: i18n.t('Target'),
      responsible: i18n.t('Responsible'),
      organisations: i18n.t('Organisations'),
      activityType: i18n.t('Activity type'),
      tags: i18n.t('Tags'),
      controlDeactivated: i18n.t('Control deactivated'),
      daughterControls: i18n.t('Daughter controls'),
      reviewer: i18n.t('Reviewer'),
      completed: i18n.t('Completed'),
      reviewDeadline: i18n.t('Review deadline'),
      notSet: i18n.t('Not set'),
      frameworks: i18n.t('Frameworks')
    };
  },
  TileCard: function TileCard() {
    return {
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      deadline: i18n.t('Deadline'),
      questionsAnswered: i18n.t('questions answered'),
      approved: i18n.t('Approved'),
      notApproved: i18n.t('Not approved'),
      dateSent: i18n.t('Date sent'),
      created: i18n.t('Created'),
      incidentOccurred: i18n.t('Incident occurred'),
      incidentArchived: i18n.t('Incident archived'),
      notSet: i18n.t('Not set'),
      expirationDate: i18n.t('Expiration date'),
      accountable: i18n.t('Accountable')
    };
  }
};