function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "answerWrap"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $props.title,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions, function (question) {
    return _openBlock(), _createElementBlock("div", {
      key: question.id,
      class: "answer"
    }, [_createElementVNode("div", {
      class: "question",
      onClick: function onClick($event) {
        return $options.toggleAnswer(question.id);
      }
    }, [_createElementVNode("span", {
      class: "truncate text-blue-750",
      onMouseover: _cache[0] || (_cache[0] = function () {
        return $options.fullLengthQuestion && $options.fullLengthQuestion.apply($options, arguments);
      }),
      onMouseleave: _cache[1] || (_cache[1] = function () {
        return $options.truncateQuestion && $options.truncateQuestion.apply($options, arguments);
      })
    }, _toDisplayString(question.questionnumber) + ". " + _toDisplayString(question.question), 33 /* TEXT, NEED_HYDRATION */)], 8 /* PROPS */, _hoisted_2), _withDirectives(_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.answerOptionsByQuestions[question.id], function (answer, key) {
      return _openBlock(), _createElementBlock("span", {
        key: key,
        class: "text-blue-750 question-answer"
      }, [_createTextVNode(_toDisplayString($options.getRightTextByAnswer(answer)) + " ", 1 /* TEXT */), _cache[2] || (_cache[2] = _createElementVNode("span", null, ", ", -1 /* HOISTED */)), _createCommentVNode(" questiontype === any informationasset or attachment with text "), _typeof(answer) === 'object' || question.freeText ? (_openBlock(), _createElementBlock("br", _hoisted_3)) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */)), $options.getFreeText(question) ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($options.getFreeText(question)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), [[_vShow, $data.expandedQuestions[question.id]]])]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}