import { getTranslate } from './translate';
import ProjectQuestionnaireGapSelector from '@/components/Organisms/Compliance/Admin/ProjectQuestionnaireGapSelector';
import ProjectQuestionGapSelectorMixin from '@/components/Organisms/Compliance/ProjectQuestionGapSelectorMixin';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { getRelevantQuestions as _getRelevantQuestions, saveRelevantQuestions } from '@/api/compliance/gapRelevantQuestions';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RelevantQuestions',
  components: {
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    TextBox: TextBox,
    ProjectQuestionnaireGapSelector: ProjectQuestionnaireGapSelector
  },
  mixins: [ProjectQuestionGapSelectorMixin],
  data: function data() {
    return {
      relevantQuestions: [],
      validated: true,
      relevantQuestionText: '',
      entries: [],
      translate: getTranslate['RelevantQuestions']()
    };
  },
  computed: {
    relevantQuestionsGrouped: function relevantQuestionsGrouped() {
      return this.relevantQuestions.reduce(function (acc, item) {
        if (!acc[item.article]) {
          acc[item.article] = [];
        }
        acc[item.article].push(item);
        return acc;
      }, {});
    },
    buttonActive: function buttonActive() {
      return this.validated && this.gapSchemaId && this.questionnaireId;
    }
  },
  watch: {
    gapSchemaId: function gapSchemaId() {
      this.getRelevantQuestions();
    },
    questionnaireId: function questionnaireId() {
      this.getRelevantQuestions();
    }
  },
  methods: {
    relevantQuestionToText: function relevantQuestionToText() {
      var _this = this;
      var text = '';
      Object.keys(this.relevantQuestionsGrouped).forEach(function (article) {
        text += article + ';';
        text += _this.questionListToComma(_this.relevantQuestionsGrouped[article]);
        text += '\n';
      });
      this.relevantQuestionText = text;
    },
    getRelevantQuestions: function getRelevantQuestions() {
      var _this2 = this;
      if (!this.gapSchemaId || !this.questionnaireId) {
        return;
      }
      _getRelevantQuestions(this.gapSchemaId, this.questionnaireId).then(function (_ref) {
        var list = _ref.list;
        _this2.relevantQuestions = list;
        _this2.relevantQuestionToText();
      }).catch(function (_ref2) {
        var response = _ref2.response;
        _this2.$notify({
          title: response.error,
          type: 'error'
        });
      });
    },
    addQuestion: function addQuestion() {
      this.relevantQuestions.push({
        article: this.articleNumber,
        question: this.questionNumber
      });
      this.relevantQuestionToText();
    },
    saveQuestions: function saveQuestions() {
      var _this3 = this;
      if (!this.buttonActive) {
        return;
      }
      saveRelevantQuestions(this.gapSchemaId, this.questionnaireId, this.relevantQuestions).then(function (_ref3) {
        var list = _ref3.list;
        _this3.relevantQuestions = list;
        _this3.relevantQuestionToText();
        _this3.$notify({
          title: _this3.translate.saved,
          type: 'success'
        });
      }).catch(function (_ref4) {
        var response = _ref4.response;
        _this3.$notify({
          title: response.error,
          type: 'error'
        });
      });
    },
    validate: function validate(newText) {
      var _this4 = this;
      this.relevantQuestionText = newText;
      this.validated = true;
      var entries = newText.trim().replace(/\/\*[\s\S]*?\*\/|\/\/.*|#.*/g, '') // Remove comments
      .split('\n').filter(Boolean);
      var validatedEntries = [];
      entries.forEach(function (entry) {
        var item = entry.split(';');
        if (item.filter(Boolean).length !== 2) {
          _this4.validated = false;
          return true;
        }
        validatedEntries.push({
          article: item[0],
          question: item[1]
        });
      });
      if (this.validated) {
        this.relevantQuestions = validatedEntries;
      }
    },
    questionListToComma: function questionListToComma(questionList) {
      return questionList.map(function (item) {
        return item.question;
      }).join(',');
    }
  }
};