import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { DateFormats } from '@/constants/DateFormats';
import { getTranslate } from './translate';
export default {
  name: 'ExternalSectionOverview',
  components: {
    DateTimePicker: DateTimePicker,
    SingleSelect: SingleSelect,
    InputField: InputField,
    RismaSelect: RismaSelect
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      updatingTimer: null,
      updatingDelay: 500,
      translate: getTranslate['ExternalSectionOverview']()
    };
  },
  computed: {
    typeOfIctServicesLabel: function typeOfIctServicesLabel() {
      return "".concat(this.translate.typeOfIctServices, " (07.01.0040)");
    },
    ictTppSubstitutabilityLabel: function ictTppSubstitutabilityLabel() {
      return "".concat(this.translate.ictTppSubstitutability, " (07.01.0050)");
    },
    reasonForSubstitutabilityLabel: function reasonForSubstitutabilityLabel() {
      return "".concat(this.translate.reasonForSubstitutability, " (07.01.0060)");
    },
    dateOfLastAuditLabel: function dateOfLastAuditLabel() {
      return "".concat(this.translate.dateOfLastAudit, " (07.01.0070)");
    },
    exitPlanLabel: function exitPlanLabel() {
      return "".concat(this.translate.exitPlan, " (07.01.0080)");
    },
    reintegrationServiceLabel: function reintegrationServiceLabel() {
      return "".concat(this.translate.reintegrationService, " (07.01.0090)");
    },
    impactOfDiscontinuingLabel: function impactOfDiscontinuingLabel() {
      return "".concat(this.translate.impactOfDiscontinuing, " (07.01.0100)");
    },
    alternativeIctTppLabel: function alternativeIctTppLabel() {
      return "".concat(this.translate.alternativeIctTpp, " (07.01.0110)");
    },
    identificationOfAlternativeIctTppLabel: function identificationOfAlternativeIctTppLabel() {
      return "".concat(this.translate.alternativeIctTppOther, " (07.01.0120)");
    },
    pickerConfig: function pickerConfig() {
      return {
        format: DateFormats.DATE_FORMAT
      };
    }
  },
  methods: {
    handleUpdate: function handleUpdate(property, value) {
      this.data[property] = value;
      this.$emit('update', this.data);
    },
    handleInputUpdate: function handleInputUpdate(property, value) {
      var _this = this;
      clearTimeout(this.updatingTimer);
      this.updatingTimer = setTimeout(function () {
        _this.handleUpdate(property, value);
      }, this.updatingDelay);
    }
  }
};