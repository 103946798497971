import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "control-details"
};
var _hoisted_2 = {
  class: "control-content col-9"
};
var _hoisted_3 = {
  key: 1,
  class: "control-details-data-entry-section"
};
var _hoisted_4 = {
  class: "mb-5"
};
var _hoisted_5 = {
  class: "flex items-baseline"
};
var _hoisted_6 = {
  class: "relative"
};
var _hoisted_7 = {
  key: 0,
  ref: "deadlineModal",
  class: "absolute -top-55px z-10 w-40rem border-2 border-gray-610 bg-white px-6 pt-3 pb-4"
};
var _hoisted_8 = {
  class: "flex items-center justify-between mb-3"
};
var _hoisted_9 = {
  key: 0,
  class: "flex items-baseline justify-between mb-3"
};
var _hoisted_10 = {
  class: "font-bold"
};
var _hoisted_11 = {
  key: 1,
  class: "flex justify-between items-baseline mb-3"
};
var _hoisted_12 = {
  class: "font-bold"
};
var _hoisted_13 = {
  key: 2,
  class: "flex items-baseline justify-between mb-3"
};
var _hoisted_14 = {
  class: "font-bold"
};
var _hoisted_15 = {
  key: 0,
  class: "flex"
};
var _hoisted_16 = {
  class: "pr-1"
};
var _hoisted_17 = {
  key: 1
};
var _hoisted_18 = {
  key: 1
};
var _hoisted_19 = {
  class: "control-details-section"
};
var _hoisted_20 = {
  class: "control-details-body row"
};
var _hoisted_21 = {
  class: "col-8"
};
var _hoisted_22 = {
  key: 0,
  class: "control-details-description"
};
var _hoisted_23 = ["innerHTML"];
var _hoisted_24 = {
  key: 1,
  class: "control-details-description"
};
var _hoisted_25 = ["innerHTML"];
var _hoisted_26 = {
  class: "control-details-description"
};
var _hoisted_27 = {
  key: 2,
  class: "control-details-evidence"
};
var _hoisted_28 = {
  class: "col-4"
};
var _hoisted_29 = {
  key: 0,
  class: "control-details-description"
};
var _hoisted_30 = {
  class: "control-details-mother-attaches"
};
var _hoisted_31 = {
  class: "control-details-section"
};
var _hoisted_32 = {
  class: "control-details-body row"
};
var _hoisted_33 = {
  key: 1,
  class: "control-details-section activity-custom-fields-wrap"
};
var _hoisted_34 = {
  key: 2,
  class: "control-entry_footer-section"
};
var _hoisted_35 = {
  class: "flex justify-center"
};
var _hoisted_36 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_creation_modal = _resolveComponent("control-creation-modal");
  var _component_confidential_box = _resolveComponent("confidential-box");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_timezone = _resolveComponent("timezone");
  var _component_control_frequency = _resolveComponent("control-frequency");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_link_item = _resolveComponent("link-item");
  var _component_control_traffic = _resolveComponent("control-traffic");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_complete_modal = _resolveComponent("complete-modal");
  var _component_send_to_jira_modal = _resolveComponent("send-to-jira-modal");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isCreationModalShow ? (_openBlock(), _createBlock(_component_control_creation_modal, {
    key: 0,
    "mother-control": $props.data,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return $data.isCreationModalShow = false;
    }),
    onUpdate: $options.moveToNewControl
  }, null, 8 /* PROPS */, ["mother-control", "onUpdate"])) : _createCommentVNode("v-if", true), !$props.data.isMothercontrol && !!_ctx.getSettingValue('feature.controls_confidential_enabled') ? (_openBlock(), _createBlock(_component_confidential_box, {
    key: 1,
    class: "mb-4",
    confidential: !!$props.data.confidential,
    "read-only": $options.confidentialReadOnly,
    "confidentiality-names": $options.confidentialityNames,
    onChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'confidential',
        value: +$event
      });
    })
  }, null, 8 /* PROPS */, ["confidential", "read-only", "confidentiality-names"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([{
      disabled: $options.inputsDisabled,
      'is-unlock-description': !$options.descriptionReadOnly,
      'un-active': !$props.data.active
    }, "control-main-wrap row"])
  }, [_createElementVNode("div", _hoisted_2, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("section", _hoisted_3, [_createElementVNode("div", {
    class: _normalizeClass([{
      'has-scroll': $props.data.activityTypeId
    }, "main-control-well"])
  }, [!$options.isLockedControlUser ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    options: $options.dataEntryMenuOptions,
    class: "control-details-toggler"
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [!$props.data.isMothercontrol ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: "".concat($data.translate.controlDeadline, ":"),
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [$data.showDeadlineModal ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.deadline)
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    class: "stroke-3 text-blue-750 cursor-pointer",
    icon: "x",
    height: "30",
    width: "30",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.closeDeadlineModal(null);
    })
  })]), !$props.data.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString($data.translate.controlDeadline) + ": ", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
    "model-value": $props.data.deadline,
    "plugin-options": $data.dateTimeOptions,
    disabled: $options.inputsDisabled,
    class: "w-64 mr-4",
    onValueUpdated: _cache[3] || (_cache[3] = function ($event) {
      return $options.updateControlDateField($event, 'deadline', true);
    })
  }, null, 8 /* PROPS */, ["model-value", "plugin-options", "disabled"])])) : _createCommentVNode("v-if", true), !$props.data.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, _toDisplayString($data.translate.timezone) + ":", 1 /* TEXT */), _createVNode(_component_timezone, {
    disabled: $options.inputsDisabled,
    "model-value": $props.data.deadlineTimezone,
    class: "w-64 mr-4",
    onOnSelect: $options.timezoneOnSelect
  }, null, 8 /* PROPS */, ["disabled", "model-value", "onOnSelect"])])) : _createCommentVNode("v-if", true), $options.reviewDeadlineVisible ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString($data.translate.reviewDeadline) + ": ", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
    "model-value": $props.data.reviewDeadline,
    "plugin-options": $data.dateTimeOptions,
    disabled: $options.inputsDisabled,
    class: "w-64 mr-4",
    onValueUpdated: _cache[4] || (_cache[4] = function ($event) {
      return $options.updateControlDateField($event, 'reviewDeadline');
    })
  }, null, 8 /* PROPS */, ["model-value", "plugin-options", "disabled"])])) : _createCommentVNode("v-if", true), $props.data.id ? (_openBlock(), _createBlock(_component_control_frequency, {
    key: 3,
    ref: "frequencyComponent",
    control: $props.data,
    disabled: $options.inputsDisabled,
    "is-daughter": $options.isDaughter,
    "review-days-setting-default-value": $options.reviewDaysSettingDefaultValue,
    class: "mb-4",
    onTurnYellowChanged: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.turnYellowDaysBeforeDeadLine = $event;
    }),
    onToggleInnerModal: _cache[6] || (_cache[6] = function ($event) {
      return $data.isInnerModalShown = $event;
    }),
    onUpdateControlDeadline: $options.setDeadline,
    onUpdateReviewOffset: $options.updateReviewOffset,
    onChanged: $options.controlFrequencyChanged
  }, null, 8 /* PROPS */, ["control", "disabled", "is-daughter", "review-days-setting-default-value", "onUpdateControlDeadline", "onUpdateReviewOffset", "onChanged"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeDeadlineModal]]) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "underline cursor-pointer",
    onClick: _cache[7] || (_cache[7] = _withModifiers(function ($event) {
      return $data.showDeadlineModal = true;
    }, ["stop"]))
  }, [$props.data.isMothercontrol ? (_openBlock(), _createElementBlock("p", _hoisted_15, [_createElementVNode("span", _hoisted_16, _toDisplayString($data.translate.daughterControlDeadlineSettings), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    class: "stroke-2 text-blue-750",
    icon: "edit"
  })])) : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString($props.data.deadline), 1 /* TEXT */))]), $props.data.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: $data.translate.frequency,
    type: "medium",
    class: "mt-5"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.selectedFrequencyLabel), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $data.translate.trafficLightTurnsYellow,
    type: "medium",
    class: "mt-5"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.selectedTrafficLightIntervalLabel), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [$options.isDaughter && $props.data.mothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_risma_title, {
    title: $options.motherDescriptionTitle,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "mothercontrol-description list-reset-styles",
    innerHTML: $props.data.mothercontrol.description
  }, null, 8 /* PROPS */, _hoisted_23)])) : _createCommentVNode("v-if", true), $options.isEvidenceVisibleFlag && $options.isDaughter && $options.isMotherExpectedEvidenceShow ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_risma_title, {
    title: $options.motherExpectedEvidenceTitle,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "list-reset-styles",
    innerHTML: $props.data.mothercontrol.expectedEvidence
  }, null, 8 /* PROPS */, _hoisted_25)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_26, [_createVNode(_component_tiny_mce_box, {
    "initial-content": _ctx.description,
    headline: $data.translate.description,
    "read-only": $options.descriptionReadOnly,
    onChanged: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["initial-content", "headline", "read-only"])]), $options.isEvidenceVisibleFlag ? (_openBlock(), _createElementBlock("div", _hoisted_27, [_createVNode(_component_tiny_mce_box, {
    "initial-content": _ctx.expectedEvidence,
    headline: $data.translate.expectedEvidence,
    "read-only": $options.descriptionReadOnly,
    onChanged: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.expectedEvidence = $event;
    })
  }, null, 8 /* PROPS */, ["initial-content", "headline", "read-only"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_28, [$options.isDaughter && $options.isMotherAttachmentsShow ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createVNode(_component_risma_title, {
    title: $options.motherAttachmentsTitle,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_30, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.mothercontrol.attachments, function (attachment) {
    return _openBlock(), _createBlock(_component_link_item, {
      key: attachment.id,
      title: attachment.filename,
      link: attachment.url,
      icon: attachment.module,
      class: "mb-1"
    }, null, 8 /* PROPS */, ["title", "link", "icon"]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)])])]), _createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, [_createElementVNode("div", {
    class: _normalizeClass([{
      'inherited-from-mother': $options.isDaughter
    }, "col-4 traffic-light-interval"])
  }, [_createVNode(_component_control_traffic, {
    control: $props.data,
    "is-daughter": $options.isDaughter,
    disabled: $options.inputsDisabled,
    onChangedCreateRemoteTickets: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.createRemoteTickets = $event;
    })
  }, null, 8 /* PROPS */, ["control", "is-daughter", "disabled"])], 2 /* CLASS */)])]), $props.data.activityTypeId ? (_openBlock(), _createElementBlock("div", _hoisted_33, [$options.currentActivityType ? (_openBlock(), _createBlock(_component_activity_custom_fields, {
    key: 0,
    "activity-type": $options.currentActivityType,
    "custom-field-values": _ctx.customFieldValues,
    disabled: $options.inputsDisabled,
    onChanged: _cache[11] || (_cache[11] = function ($event) {
      return _ctx.customFieldValuesParsed = $event;
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $props.data.active && $props.data.userIds.responsible.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_34, [_createElementVNode("div", _hoisted_35, [_createElementVNode("div", _hoisted_36, [$props.data.isMothercontrol == 0 && !$options.isSignoffPeriodPending ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.completion,
    type: "save",
    disabled: $props.saveStatus !== $data.saveStatusValues.SAVED,
    onClick: $options.handleCompleteControl
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])) : _createCommentVNode("v-if", true), $props.data.isMothercontrol == 0 && $options.isSignoffPeriodPending ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.signoffPeriodPending,
    disabled: true,
    type: "util"
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true)])])])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])) : _createCommentVNode("v-if", true)]), $data.isLoaded && $data.isCompleteModalShow ? (_openBlock(), _createBlock(_component_complete_modal, {
    key: 0,
    id: $props.data.id,
    cpath: $options.cpath,
    onClose: _cache[12] || (_cache[12] = function ($event) {
      return $data.isCompleteModalShow = false;
    }),
    onUpdate: _cache[13] || (_cache[13] = function ($event) {
      return _ctx.$emit('onCompleteControl', $event);
    })
  }, null, 8 /* PROPS */, ["id", "cpath"])) : _createCommentVNode("v-if", true), $data.showSendToJira ? (_openBlock(), _createBlock(_component_send_to_jira_modal, {
    key: 1,
    module: $data.MODULES.CONTROLS,
    "module-id": $props.data.cpath,
    onDismiss: _cache[14] || (_cache[14] = function ($event) {
      return $data.showSendToJira = false;
    }),
    onCreated: _cache[15] || (_cache[15] = function ($event) {
      return $data.showSendToJira = false;
    })
  }, null, 8 /* PROPS */, ["module", "module-id"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
}