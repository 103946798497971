import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "inline-block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_circle = _resolveComponent("risma-circle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_circle, null, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("2")]);
    }),
    _: 1 /* STABLE */
  })]);
}