import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "flex self-stretch shrink-0 z-1 w-82px text-white"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  key: 0,
  class: "scroll-left relative shrink-0 self-stretch flex w-8 border-r border-rGrey-400 bg-transparent"
};
var _hoisted_4 = {
  ref: "navBar",
  class: "nav-bar self-stretch flex-grow overflow-x-scroll scroll-smooth"
};
var _hoisted_5 = {
  class: "flex h-full"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = ["onClick"];
var _hoisted_8 = ["data-has-children"];
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
  key: 0,
  class: "scroll-right relative shrink-0 self-stretch flex w-8 border-l border-rGrey-400 bg-transparent"
};
var _hoisted_12 = {
  class: "flex items-stretch h-full"
};
var _hoisted_13 = {
  class: "self-center pr-12 glob-h2"
};
var _hoisted_14 = {
  class: "m-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$subMenuList;
  var _component_quick_create_incident_modal = _resolveComponent("quick-create-incident-modal");
  var _component_nav_item = _resolveComponent("nav-item");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_user_menu = _resolveComponent("user-menu");
  var _component_release_notification = _resolveComponent("release-notification");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", {
    "data-trap": "headerNavbar",
    class: "fixed w-full top-0 z-110",
    style: _normalizeStyle($options.colorSettings)
  }, [$data.showCreateIncidentModal ? (_openBlock(), _createBlock(_component_quick_create_incident_modal, {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showCreateIncidentModal = false;
    })
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "flex items-center pl-6 pr-4 min-h-3.5rem relative",
    style: _normalizeStyle("background-color: ".concat($options.colorSettings['--settingsBgColor']))
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_nav_item, {
    "is-risma2-component": $props.risma2enable,
    url: "/mypage",
    class: "my-auto mr-auto"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("img", {
        src: $options.imgSrc,
        class: "logo-image"
      }, null, 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-risma2-component"]), $data.showNavBarScroll ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$options.hasLeftArrow ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "cursor-pointer flex items-center w-full",
    style: _normalizeStyle($options.arrowShadows.left),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.scrollNavBar(-100);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    width: "16",
    class: "m-auto stroke-2 text-rGrey-400"
  })], 4 /* STYLE */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), _withDirectives((_openBlock(), _createElementBlock("nav", _hoisted_4, [_createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navBar, function (link, index) {
    var _link$children, _link$children3;
    return _openBlock(), _createElementBlock("li", {
      key: "".concat(link.url, "-").concat(index),
      class: _normalizeClass(["text-xl", link.title ? 'shrink-0' : 'w-6'])
    }, [link.title ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["main-nav-item h-full flex", {
        'selected-main-menu-item': link.active
      }])
    }, [_createVNode(_component_nav_item, {
      "is-risma2-component": $props.risma2enable,
      url: link.url,
      exact: link.exact,
      class: _normalizeClass(["shrink-0 relative group flex text-0.9rem pl-4 pr-2 py-0 items-center main-nav-link leading-6", {
        'bg-trans-200': link.active || link.selected,
        'pr-4': !((_link$children = link.children) !== null && _link$children !== void 0 && _link$children.length)
      }])
    }, {
      default: _withCtx(function () {
        var _link$children2;
        return [_createElementVNode("button", {
          class: _normalizeClass(["flex items-center border-2 border-transparent focus-visible:border-black focus:outline-none", {
            'custom-color': $options.colorSettings['--settingsTextColor'] !== $data.ColorSettings.settingsTextColor.default
          }]),
          onClick: function onClick($event) {
            return $options.selectNavItem($event, link);
          }
        }, [_createElementVNode("span", {
          class: "font-medium",
          style: _normalizeStyle([link.selected || link.active ? {
            color: $data.Colors.white
          } : {}])
        }, _toDisplayString(link.title), 5 /* TEXT, STYLE */), (_link$children2 = link.children) !== null && _link$children2 !== void 0 && _link$children2.length ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["transform transition-transform duration-300 ml-2", {
            'rotate-180': link.selected
          }])
        }, [_createVNode(_component_feather_icon, {
          style: _normalizeStyle([link.selected || link.active ? {
            color: $data.Colors['brand-500']
          } : {}]),
          icon: "chevron-down",
          width: "16",
          class: "stroke-2"
        }, null, 8 /* PROPS */, ["style"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_6)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact", "class"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), (_link$children3 = link.children) !== null && _link$children3 !== void 0 && _link$children3.length ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass(["sublist bg-rWhite-500 shadow-drop-down absolute top-full h-auto z-2", [$options.isBigMenu(link) ? 'left-0 w-full' : "-mt-2px rounded", link.selected ? 'visible' : 'invisible']]),
      style: _normalizeStyle($options.isBigMenu(link) ? '' : $options.childStyle)
    }, [_createElementVNode("ul", {
      class: _normalizeClass(["h-auto flex flex-wrap rounded px-4 z-100 cursor-default group-hover:visible text-rBlack-500", $options.getSubMenuStyle(link)]),
      onClick: function onClick($event) {
        return $options.selectNavItem($event, link);
      }
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(link.children, function (linkInner, linkIndex) {
      return _openBlock(), _createElementBlock("li", {
        key: "".concat(linkInner.url, "-").concat(linkIndex),
        ref_for: true,
        ref: "submenuItems",
        "data-has-children": linkInner.children,
        class: _normalizeClass({
          'relative my-3 border-r last:border-r-0 border-rGrey-400': linkInner.children,
          'w-200px': $options.isBigMenu(link)
        })
      }, [linkInner.isButton ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", {
        class: "flex items-center min-w-168px mx-2 pl-2 pr-6 rounded h-8 my-px first:mt-0 last:mb-0 leading-8 text-sm font-medium text-rm-active-blue hover:bg-rGrey-400 cursor-pointer",
        onClick: function onClick($event) {
          return $options.onButtonClick(linkInner.action);
        }
      }, [linkInner.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: linkInner.icon,
        width: "20",
        height: "20",
        class: "mr-2 p-px stroke-2 border-2 border-rm-active-blue rounded-full"
      }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(_ctx.$trans(linkInner.title)), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_10), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "mb-1 pb-1 mx-2 pl-2 pr-6 border-b"
      }, null, -1 /* HOISTED */))])) : (_openBlock(), _createBlock(_component_nav_item, {
        key: 1,
        "is-risma2-component": $props.risma2enable,
        url: linkInner.children ? '' : linkInner.url,
        exact: linkInner.exact,
        class: _normalizeClass(["block min-w-168px mx-2 pl-2 pr-6 rounded", linkInner.children ? 'mb-3 cursor-default text-base font-semibold' : 'h-8 my-px first:mt-0 last:mb-0 leading-8 text-sm font-medium hover:bg-rGrey-400'])
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(linkInner.translatable === false ? linkInner.title : _ctx.$trans(linkInner.title)), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact", "class"])), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linkInner.children, function (linkItem, key) {
        return _openBlock(), _createBlock(_component_nav_item, {
          key: "".concat(linkItem.url, "-").concat(key),
          "is-risma2-component": $props.risma2enable,
          url: linkItem.url,
          exact: linkItem.exact,
          class: "block mx-2 my-px first:mt-0 last:mb-0 py-1.5 pl-2 pr-6 rounded hover:bg-rGrey-400 leading-5 text-sm font-medium"
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", null, _toDisplayString(linkItem.translatable === false ? linkItem.title : _ctx.$trans(linkItem.title)), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["is-risma2-component", "url", "exact"]);
      }), 128 /* KEYED_FRAGMENT */))])], 10 /* CLASS, PROPS */, _hoisted_8);
    }), 128 /* KEYED_FRAGMENT */))], 10 /* CLASS, PROPS */, _hoisted_7)], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))])])), [[_directive_click_outside, $options.hideSubNavigation]]), $data.showNavBarScroll ? (_openBlock(), _createElementBlock("div", _hoisted_11, [$options.hasRightArrow ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "cursor-pointer flex items-center w-full",
    style: _normalizeStyle($options.arrowShadows.right),
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.scrollNavBar(100);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-right",
    width: "16",
    class: "m-auto stroke-2 text-rGrey-400"
  })], 4 /* STYLE */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $props.userMenuEnabled ? (_openBlock(), _createBlock(_component_user_menu, {
    key: 1,
    id: $props.id,
    ref: "usermenu",
    notifications: $data.notifications,
    "old-version": false,
    scoring: $data.scoring,
    users: _ctx.users,
    organisations: _ctx.organisations,
    "activity-types": _ctx.activityTypes,
    "color-settings": $options.colorSettings,
    style: _normalizeStyle({
      color: $options.colorSettings['--settingsTextColor'],
      backgroundColor: 'inherit'
    }),
    class: "shrink-0 ml-auto pl-4",
    onClickNotification: $options.onNotificationClick,
    onMarkAllNotificationsAsRead: $options.handleMarkAllNotificationsAsRead,
    onShowSearchChanged: $options.checkNavBarWidth
  }, null, 8 /* PROPS */, ["id", "notifications", "scoring", "users", "organisations", "activity-types", "color-settings", "style", "onClickNotification", "onMarkAllNotificationsAsRead", "onShowSearchChanged"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */), (_$data$subMenuList = $data.subMenuList) !== null && _$data$subMenuList !== void 0 && _$data$subMenuList.length ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "submenu h-12 pl-6 pr-4 text-sm text-black",
    style: _normalizeStyle("background-color: ".concat($options.colorSettings['--settingsSubBgColor'], "; color: ").concat($options.colorSettings['--settingsSubTextColor']))
  }, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString($options.currentSection), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.subMenuList, function (item) {
    return _openBlock(), _createBlock(_component_nav_item, {
      key: item.title,
      class: _normalizeClass(["flex px-8 border-x border-transparent hover:bg-rGrey-400", {
        'router-link-active': $options.checkActiveSubItem(item)
      }]),
      "is-risma2-component": $props.risma2enable,
      url: item.url,
      exact: item.exact
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$trans(item.title)), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "is-risma2-component", "url", "exact"]);
  }), 128 /* KEYED_FRAGMENT */))])], 4 /* STYLE */)) : _createCommentVNode("v-if", true), $data.isLoaded && $options.showReleaseNotifications ? (_openBlock(), _createBlock(_component_release_notification, {
    key: 2,
    onDismiss: $options.dismissVersionPanel
  }, null, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */);
}