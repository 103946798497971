import MultipleDateRange from '@/components/Molecules/MultipleDateRange';
export default {
  name: '',
  components: {
    MultipleDateRange: MultipleDateRange
  },
  data: function data() {
    return {
      ranges: []
    };
  }
};