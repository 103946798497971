// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-cont[data-v-3f873d1a] {
  position: relative;
  padding: 15px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #CCCCCC;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
  z-index: 2;
}
.preview-cont[data-v-3f873d1a]:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: -85% 50%;
  background-size: 80%;
  opacity: 0.7;
}
.preview-header[data-v-3f873d1a] {
  display: flex;
  align-items: center;
}
.preview-header .preview-header-trafficlight[data-v-3f873d1a] {
  margin: 0 5px 0 0;
}
.preview-header .preview-header-trafficlight[data-v-3f873d1a]  svg {
  top: 0;
}
.preview-header .preview-header-title[data-v-3f873d1a] {
  overflow: hidden;
}
.preview-header .preview-header-title[data-v-3f873d1a]  .big {
  font-size: 24px;
}
.preview.red[data-v-3f873d1a]  .big {
  color: #f25050;
}
.preview.red .preview-header-title[data-v-3f873d1a]  a:hover {
  color: #f25050;
}
.preview-footer[data-v-3f873d1a] {
  padding-top: 5px;
}
.preview-footer[data-v-3f873d1a]  .small {
  margin: 0;
}
.preview-risk-tiles[data-v-3f873d1a] {
  height: 100%;
  min-height: 170px;
  margin-bottom: 0;
}
.preview-risk-tiles[data-v-3f873d1a]:before {
  background-image: url("/assets/svg/ikon_risk.svg");
}
.preview-risk-tiles .preview-footer .title-not-approved[data-v-3f873d1a]  h3 {
  color: #B03D00;
}
.preview-risk-tiles.preview-risk-closed[data-v-3f873d1a]  .big {
  text-decoration: line-through !important;
}
.preview-risk-tiles .preview-description[data-v-3f873d1a] {
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.preview-risk-tiles .preview-description[data-v-3f873d1a]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.preview-risk-tiles .preview-description[data-v-3f873d1a]  ul ul {
  list-style-type: circle;
}
.preview-risk-tiles .preview-description[data-v-3f873d1a]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.preview-risk-tiles .preview-description[data-v-3f873d1a]  li {
  padding-left: 0.3em;
}
.preview-risk-tiles .preview-body[data-v-3f873d1a] {
  display: flex;
  flex-direction: column;
}
.preview-risk-tiles .preview-body .preview-body-section[data-v-3f873d1a] {
  margin: 0 0 10px 0;
}
.preview-risk-tiles .preview-body .preview-body-section.highlight[data-v-3f873d1a] {
  color: #737373;
}
.preview-risk-tiles .preview-footer[data-v-3f873d1a] {
  padding-top: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
