import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "grid grid-cols-3 gap-x-6"
};
var _hoisted_2 = {
  class: "questionnaire-type-select"
};
var _hoisted_3 = {
  class: "questionnaire-select"
};
var _hoisted_4 = {
  class: "processors-select"
};
var _hoisted_5 = {
  key: 4,
  class: "mb-4"
};
var _hoisted_6 = {
  key: 0,
  class: "col-12 mt-8 text-center info-message"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_question_selection = _resolveComponent("question-selection");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_article30_table = _resolveComponent("article30-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.errorFromBackend ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorFromBackend)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.answeredAudits
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        title: $data.translate.selectOneQuestionnaireAndThenOptionalFilte,
        class: "mb-2",
        type: "small"
      }, null, 8 /* PROPS */, ["title"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        title: $data.translate.selectAnAuditType,
        options: $options.questionnaireTypesOptions,
        "model-value": $data.selectedQuestionnaireTypeId,
        "un-sorted": true,
        placeholder: $data.translate.selectAnAuditType,
        "onUpdate:modelValue": $options.onQuestionnaireTypeSelect
      }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        title: $data.translate.selectAQuestionnaire,
        options: $options.questionnairesOptions,
        "model-value": $data.selectedQuestionnaireId,
        "un-sorted": true,
        placeholder: $data.translate.selectAQuestionnaire,
        "onUpdate:modelValue": $options.onQuestionnaireSelect
      }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_4, [$data.showSelectProcessors ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 0,
        title: $data.translate.selectProcessors,
        "selected-options": $data.selectedProcessors,
        options: $options.processorsOptions,
        placeholder: $data.translate.selectProcessors,
        "label-key": "title",
        onSelected: $options.onSelectProcessors
      }, null, 8 /* PROPS */, ["title", "selected-options", "options", "placeholder", "onSelected"])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $data.selectedQuestionnaireId && $data.showQuestions ? (_openBlock(), _createBlock(_component_question_selection, {
        key: 3,
        questions: $data.questions,
        "initial-selected-questions": $data.selectedQuestions,
        onQuestionsSelected: $options.onQuestionsSelect
      }, null, 8 /* PROPS */, ["questions", "initial-selected-questions", "onQuestionsSelected"])) : _createCommentVNode("v-if", true), $data.selectedQuestions.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_button, {
        text: $data.translate.createReport,
        class: "pr-2 text-right",
        type: "save",
        onClick: $options.onCreateReport
      }, null, 8 /* PROPS */, ["text", "onClick"])])) : _createCommentVNode("v-if", true), $data.rows.length ? _withDirectives((_openBlock(), _createBlock(_component_article30_table, {
        key: 5,
        rows: $data.rows,
        "name-column-title": $options.nameColumnTitle,
        "state-local": $data.localStateName,
        "report-type": $data.savedReportType,
        "report-options": $data.reportOptions,
        "table-view-on-load": $data.selectedTableView,
        mode: "processorAudit",
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $data.updatedFromPreview = true;
        }),
        onDismissModal: $options.onDismissModal,
        onUpdateFromContactCards: _cache[1] || (_cache[1] = function ($event) {
          return $data.updatedFromPreview = true;
        }),
        onDismissContractCardModal: $options.onDismissModal
      }, null, 8 /* PROPS */, ["rows", "name-column-title", "state-local", "report-type", "report-options", "table-view-on-load", "onDismissModal", "onDismissContractCardModal"])), [[_vShow, $data.showReport]]) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), $data.showReport && !$data.rows.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]);
}