import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "py-4"
};
var _hoisted_2 = {
  class: "flex mb-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
    modelValue: $data.currency,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.currency = $event;
    }), $options.refreshChart],
    options: $data.currencyOptions,
    "un-sorted": true,
    title: $data.translate.currency,
    class: "mr-10"
  }, null, 8 /* PROPS */, ["modelValue", "options", "title", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
    modelValue: $data.year,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.year = $event;
    }), $options.refreshChart],
    options: $options.yearOptions,
    title: $data.translate.year
  }, null, 8 /* PROPS */, ["modelValue", "options", "title", "onUpdate:modelValue"])]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_bar_chart, {
    key: 1,
    labels: $options.shortLabels,
    datasets: $options.datasets,
    "show-data-labels": true,
    type: "Stacked",
    onOnClick: $options.setContracts
  }, null, 8 /* PROPS */, ["labels", "datasets", "onOnClick"]))]);
}