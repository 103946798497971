// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-a1a9878e]  .nestable-handle {
  width: 280px;
  height: 66px;
}
[data-v-a1a9878e]  ol {
  margin-left: calc(280px + 3rem);
  margin-top: -45px;
}
[data-v-a1a9878e]  .nestable-group {
  margin-left: 0;
}
[data-v-a1a9878e]  .nestable-group ol > li {
  position: relative;
}
[data-v-a1a9878e]  .nestable-group ol > li::before {
  /* left hori arrow default */
  content: "";
  position: absolute;
  left: -24px;
  top: 12px;
  border-left: 1px dotted #4D4D4D;
  height: 100%;
}
[data-v-a1a9878e]  .nestable-group ol > li:first-child::before {
  /* left hori arrow */
  height: 100%;
  top: 12px;
}
[data-v-a1a9878e]  .nestable-group ol > li:last-child {
  /* left hori arrow */
}
[data-v-a1a9878e]  .nestable-group ol > li:last-child::before {
  height: 0;
}
[data-v-a1a9878e]  .nestable-group ol > li:last-child .nestable-item-content {
  position: relative;
}
[data-v-a1a9878e]  .nestable-group ol > li:last-child .nestable-item-content::before {
  content: "";
  position: absolute;
  left: -24px;
  border-left: 1px dotted #4D4D4D;
  height: calc(50% - 12px);
  top: 12px;
}

/* needed for a specific bug, drag and drop drag element has bigger clickable layout than tree-rectangle, and this 'wrapper' cannot be reachable */
.invisible-layout[data-v-a1a9878e]  .nestable-drag-layer .tree-rectangle {
  display: none;
}
.tmp[data-v-a1a9878e] {
  /* should be removed at the end of impelenting */
  height: 200rem;
  margin-top: 4rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
