import { getRandomString } from '@/utils/random';
export default {
  name: 'RadioButtons',
  introduction: 'Input with type=radio',
  description: 'This is a input with type=radio that emits an "input"-event when the value changes',
  token: "\nconst options = [{value: 1, label: 'Yes'}, {value: 2, label: 'No'}];\nconst checked = 2; // or \"false\" for unchecked value\n\n<radio-button v-model=\"checked\" :options=\"options\" @updated=\"handleChange(checkedObject)\" />",
  props: {
    options: {
      type: Array,
      required: true,
      note: 'A list of items'
    },
    modelValue: {
      type: [Number, Boolean, String],
      required: false,
      default: false,
      note: 'Value for v-model, use only false to set unchecked items'
    },
    noUncheckedValue: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If is possible to uncheck value(click on checked value), to have false for a value'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Radiobuttons are read-only'
    },
    radioButtonFocus: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Determine whether selected or first button will be focused after mounting'
    }
  },
  emits: ['updated', 'update:modelValue', 'change'],
  data: function data() {
    return {
      prevValue: false
    };
  },
  computed: {
    randomGroupName: function randomGroupName() {
      return getRandomString();
    }
  },
  watch: {
    modelValue: {
      handler: function handler(valueChanged) {
        this.prevValue = this.getUpdatedValue(valueChanged);
      }
    }
  },
  created: function created() {
    this.prevValue = this.modelValue;
  },
  mounted: function mounted() {
    if (this.radioButtonFocus) {
      this.focusButton();
    }
  },
  methods: {
    focusButton: function focusButton() {
      var button;
      if (this.prevValue) {
        button = this.$el.querySelector("input[value=\"".concat(this.prevValue, "\"]"));
      } else {
        button = this.$el.querySelector('input[type="radio"]');
      }
      button.focus();
    },
    handleChange: function handleChange(valueChanged) {
      if (this.disabled) return;
      var valueUpdated = this.getUpdatedValue(valueChanged);
      var optionUpdated = this.getUpdatedOption(valueUpdated);
      this.$emit('updated', optionUpdated);
      this.$emit('update:modelValue', valueUpdated);
    },
    getUpdatedOption: function getUpdatedOption(valueUpdated) {
      var checked = this.options.find(function (option) {
        return option.value === valueUpdated;
      });
      return Object.assign({}, checked);
    },
    getUpdatedValue: function getUpdatedValue(valueChanged) {
      if (this.noUncheckedValue) return valueChanged;
      return valueChanged == this.prevValue ? false : valueChanged;
    }
  }
};