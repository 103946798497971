import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'RismaAdminTabs',
  components: {
    LightTabItem: LightTabItem
  },
  computed: {
    tabs: function tabs() {
      return [{
        to: {
          name: 'RismaAdminGuide'
        },
        label: 'Guide'
      }, {
        to: {
          name: 'UploadQuestionnaire'
        },
        label: 'Upload Questionnaire'
      }, {
        to: {
          name: 'CreateComplianceProject'
        },
        label: 'Create Compliance Project'
      }, {
        to: {
          name: 'CreateRiskProject'
        },
        label: 'Risk Projects'
      }, {
        to: {
          name: 'UploadGap'
        },
        label: 'Upload Gap'
      }, {
        to: {
          name: 'EmailSetup'
        },
        label: 'E-mail'
      }, {
        to: {
          name: 'Kpis'
        },
        label: 'Kpis'
      }, {
        to: {
          name: 'SyncAdmin'
        },
        label: 'SYNC'
      }, {
        to: {
          name: 'Saml'
        },
        label: 'SAML'
      }, {
        to: {
          name: 'Inbox'
        },
        label: 'Inbox'
      }, {
        to: {
          name: 'DeletedNodes'
        },
        label: 'Deleted nodes'
      }, {
        to: {
          name: 'FrameworksTable'
        },
        label: 'Framework'
      }, {
        to: {
          name: 'DangerZone'
        },
        label: 'Danger Zone'
      }];
    }
  }
};