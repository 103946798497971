import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "risk-report-wrap"
};
var _hoisted_2 = {
  key: 1,
  class: "risk-report-content overview-hidden"
};
var _hoisted_3 = {
  class: "risk-description"
};
var _hoisted_4 = {
  class: "flex"
};
var _hoisted_5 = {
  key: 2
};
var _hoisted_6 = {
  class: "row"
};
var _hoisted_7 = {
  class: "col-8 text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risk_reports_filters = _resolveComponent("risk-reports-filters");
  var _component_risk_reports_filters_erm = _resolveComponent("risk-reports-filters-erm");
  var _component_risk_grid_display = _resolveComponent("risk-grid-display");
  var _component_risk_report_grid = _resolveComponent("risk-report-grid");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.riskReport
  }, null, 8 /* PROPS */, ["title"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($options.riskDescription), 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [!$options.isErm ? (_openBlock(), _createBlock(_component_risk_reports_filters, {
    key: 0,
    "selected-filters": $data.filters,
    class: "flex-grow",
    options: $options.filterOptions,
    onChangePeriod: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleChangePeriod('xAxis', $event);
    }),
    onCreate: $options.handleCreateReport,
    onReset: $options.handleResetFilters
  }, null, 8 /* PROPS */, ["selected-filters", "options", "onCreate", "onReset"])) : (_openBlock(), _createBlock(_component_risk_reports_filters_erm, {
    key: 1,
    "selected-filters": $data.filters,
    class: "flex-grow",
    options: $options.filterOptions,
    "current-period": $options.currentPeriod,
    onChangePeriod: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleChangePeriod('yAxis', $event);
    }),
    onCreate: $options.handleCreateReport,
    onReset: $options.handleResetFilters
  }, null, 8 /* PROPS */, ["selected-filters", "options", "current-period", "onCreate", "onReset"])), $options.riskGridData && $options.riskGridData.gridSizeY.length && $options.riskGridData.gridSizeX.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(), _createBlock(_component_risk_grid_display, {
    key: $data.riskGridUpdateKey,
    "risk-grid-data": $options.riskGridData,
    "show-arrows": false,
    "start-color": _ctx.startColor,
    "end-color": _ctx.endColor,
    "custom-heatmap-options": _ctx.currentRiskProject.customHeatmapEnabled ? _ctx.currentRiskProject.heatmapOptions : [],
    onOnStateChanged: $options.onStateChanged
  }, null, 8 /* PROPS */, ["risk-grid-data", "start-color", "end-color", "custom-heatmap-options", "onOnStateChanged"]))])) : _createCommentVNode("v-if", true)]), $data.isReportLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), _withDirectives(_createVNode(_component_risk_report_grid, {
    "project-id": $props.projectId,
    "matrix-data": $data.gridData,
    users: _ctx.users,
    organisations: _ctx.organisations,
    threats: _ctx.threats,
    "level-of-threats": $options.levelOfThreatsFull,
    vulnerabilities: _ctx.vulnerabilities,
    probabilities: $options.probabilitiesByPeriod,
    periods: $options.renderedPeriods,
    "current-period": $options.currentPeriod,
    "is-current-period-selected": $options.isCurrentPeriodSelected,
    "consequence-list": $options.consequences,
    "compare-period": $data.comparePeriodByConsequence,
    "previous-periods": $data.previousPeriods,
    risks: $data.risks,
    tags: _ctx.tags,
    "risk-approval-enabled": $data.riskApprovalEnabled,
    controls: _ctx.controls,
    initiatives: _ctx.initiatives,
    systems: $data.systems,
    "process-library-nodes": $data.processLibraryNodes,
    "compliance-nodes": $data.complianceNodes,
    incidents: $data.incidents,
    "management-levels": $data.managementLevels,
    "risk-types": $data.riskTypes,
    "is-erm": $options.isErm,
    "start-color": _ctx.startColor,
    "end-color": _ctx.endColor,
    "parent-risks": $options.parentRisksList,
    "inherent-risks-enabled": $options.inherentRisksEnabled,
    "custom-heatmap-options": _ctx.currentRiskProject.customHeatmapEnabled ? _ctx.currentRiskProject.heatmapOptions : [],
    "report-options": $data.reportOptions,
    "show-previous-position-array": $data.previousPositionsState,
    onOnPeriodChanged: $options.onPeriodChanged,
    onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
    onDismissModal: $options.createReport,
    onFetchSavedReport: $options.fetchSavedReport,
    onTogglePreviousPosition: $options.togglePreviousPosition
  }, null, 8 /* PROPS */, ["project-id", "matrix-data", "users", "organisations", "threats", "level-of-threats", "vulnerabilities", "probabilities", "periods", "current-period", "is-current-period-selected", "consequence-list", "compare-period", "previous-periods", "risks", "tags", "risk-approval-enabled", "controls", "initiatives", "systems", "process-library-nodes", "compliance-nodes", "incidents", "management-levels", "risk-types", "is-erm", "start-color", "end-color", "parent-risks", "inherent-risks-enabled", "custom-heatmap-options", "report-options", "show-previous-position-array", "onOnPeriodChanged", "onUpdateFromPreviewMode", "onDismissModal", "onFetchSavedReport", "onTogglePreviousPosition"]), [[_vShow, $data.risks.length && $data.isLoaded && !$data.isReportLoading]]), _createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.tryAdjustingTheFiltrationParameters), 1 /* TEXT */)], 512 /* NEED_PATCH */), [[_vShow, $data.nothingToShowMessageVisible]])])]))]);
}