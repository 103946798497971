function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import MainWell from '@/components/Atoms/Containers/MainWell';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import CustomAxisTable from '@/components/Molecules/Risk/CustomAxisTable';
import RiskGridDisplay from '@/components/Molecules/Risk/RiskGridDisplay';
import ScoringAxisEditNew from '@/components/Molecules/Risk/ScoringAxisEditNew';
import CreationMatrixAxisModal from '@/components/Organisms/Admin/CreationMatrixAxisModal';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { LIKELIHOOD_OPTIONS, IMPACT_OPTIONS } from '@/constants/admin/RiskPeriod';
import { GradientTypes } from '@/constants/admin/RiskMatrixSettings';
export default {
  name: 'RiskMatrixAxisParametersWell',
  token: '<risk-matrix-axis-parameters-well />',
  components: {
    InputField: InputField,
    CustomAxisTable: CustomAxisTable,
    MainWell: MainWell,
    RiskGridDisplay: RiskGridDisplay,
    RismaLabel: RismaLabel,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    ScoringAxisEditNew: ScoringAxisEditNew,
    CreationMatrixAxisModal: CreationMatrixAxisModal
  },
  props: {
    scoring: {
      type: Object,
      require: true,
      default: null
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['change', 'change:table', 'change:calculation', 'create:table', 'update:calculationType'],
  data: function data() {
    return {
      likelihoodOptions: this.translateOptions(LIKELIHOOD_OPTIONS),
      impactOptions: this.translateOptions(IMPACT_OPTIONS),
      RiskBaseModelProps: RiskBaseModelProps,
      riskGridShow: true,
      showCreateModal: false,
      impactScores: null,
      likelihoodScores: null,
      axis: '',
      translate: getTranslate['RiskMatrixAxisParametersWell']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    likelihoodPercentageDisabled: function likelihoodPercentageDisabled() {
      return this.getSettingValue('risma.likelihood_percentage_disabled');
    },
    riskZAxixSettings: function riskZAxixSettings() {
      return this.getSettingValue('risma.risk.z_axis');
    },
    likelihood_max: function likelihood_max() {
      return this.getScoringPropertiesAsArray('likelihood_max', this.scoring);
    },
    impact_max: function impact_max() {
      return this.getScoringPropertiesAsArray('impact_max', this.scoring);
    },
    impactGridLabels: function impactGridLabels() {
      var _this = this;
      return Array(this.scoring.impact_scores).fill({}).map(function (item, index) {
        var impactMax = _this.impactMax[index] || '';
        if (index === 0) {
          return {
            label: "< ".concat(impactMax, " ").concat(_this.scoring.impact_scale)
          };
        }
        if (index === _this.impactMax.length) {
          return {
            label: "> ".concat(_this.impactMax[index - 1] || '', " ").concat(_this.scoring.impact_scale)
          };
        }
        return {
          label: "".concat(_this.impactMax[index - 1], " - ").concat(impactMax, " ").concat(_this.scoring.impact_scale)
        };
      });
    },
    likelihoodGridLabels: function likelihoodGridLabels() {
      var _this2 = this;
      return Array(this.scoring.likelihood_scores).fill({}).map(function (item, index) {
        var _this2$scoring$likeli;
        var likelihoodMax = _this2.likelihoodMax[index];
        var likelihoodLabel = (_this2$scoring$likeli = _this2.scoring.likelihood_labels.find(function (label) {
          return index === label.likelihood_number - 1;
        })) === null || _this2$scoring$likeli === void 0 ? void 0 : _this2$scoring$likeli.description;
        if (index === 0) {
          return {
            label: _this2.getScoreWithLabel("< ".concat(likelihoodMax), likelihoodLabel)
          };
        }
        if (index === _this2.likelihoodMax.length) {
          return {
            label: _this2.getScoreWithLabel("> ".concat(_this2.likelihoodMax[index - 1]), likelihoodLabel)
          };
        }
        return {
          label: _this2.getScoreWithLabel("".concat(_this2.likelihoodMax[index - 1], " - ").concat(likelihoodMax), likelihoodLabel)
        };
      });
    },
    gridSizeXTable: function gridSizeXTable() {
      var _this$scoring;
      return Object.values(((_this$scoring = this.scoring) === null || _this$scoring === void 0 ? void 0 : _this$scoring.impact_labels) || {}).map(function (item) {
        return {
          label: item.value
        };
      });
    },
    gridSizeYTable: function gridSizeYTable() {
      var _this$scoring2,
        _this3 = this;
      return Object.values((_this$scoring2 = this.scoring) === null || _this$scoring2 === void 0 ? void 0 : _this$scoring2.likelihood_labels).map(function (item) {
        return {
          label: "".concat(_this3.scoring.likelihood_values[item['likelihood_number']])
        };
      });
    },
    riskGridData: function riskGridData() {
      return {
        xLabel: this.impactScores === 0 ? this.$trans(this.scoring.impact_label) : this.translate.impact,
        yLabel: this.likelihoodScores === 0 ? this.$trans(this.scoring.likelihood_label) : this.translate.likelihood,
        gridSizeX: this.impactScores === 0 ? this.gridSizeXTable : this.impactGridLabels,
        gridSizeY: this.likelihoodScores === 0 ? this.gridSizeYTable : this.likelihoodGridLabels,
        checked: false,
        colorCodes: {},
        dots: []
      };
    },
    likelihoodMax: function likelihoodMax() {
      var array = _toConsumableArray(this.likelihood_max);
      if (this.scoring.likelihood_scores === 5) {
        array.splice(4, 1);
      }
      return array;
    },
    likelihoodLabels: function likelihoodLabels() {
      if (!this.scoring.likelihood_labels) return [];
      var array = _toConsumableArray(this.scoring.likelihood_labels);
      array.splice(this.scoring.likelihood_scores, 1);
      return array;
    },
    impactMax: function impactMax() {
      if (!this.impact_max) return [];
      var array = _toConsumableArray(this.impact_max);
      if (this.scoring.impact_scores === 5) {
        array.splice(4, 1);
      }
      return array;
    },
    tableSecondColumnImpact: function tableSecondColumnImpact() {
      var _this$scoring$RiskBas;
      return (_this$scoring$RiskBas = this.scoring[RiskBaseModelProps.CUSTOM_IMPACT]) !== null && _this$scoring$RiskBas !== void 0 && _this$scoring$RiskBas.options ? Object.values(this.scoring[RiskBaseModelProps.CUSTOM_IMPACT].options) : [];
    },
    tableSecondColumnLikelihood: function tableSecondColumnLikelihood() {
      var _this$scoring$RiskBas2;
      return (_this$scoring$RiskBas2 = this.scoring[RiskBaseModelProps.CUSTOM_LIKELIHOOD]) !== null && _this$scoring$RiskBas2 !== void 0 && _this$scoring$RiskBas2.options ? Object.values(this.scoring[RiskBaseModelProps.CUSTOM_LIKELIHOOD].options) : [];
    }
  }),
  mounted: function mounted() {
    this.impactScores = this.scoring.customImpact ? 0 : this.scoring.impact_scores;
    this.likelihoodScores = this.scoring.customLikelihood ? 0 : this.scoring.likelihood_scores;
  },
  methods: {
    getScoringPropertiesAsArray: function getScoringPropertiesAsArray(property, scoring) {
      var number = 1;
      var result = [];
      while (scoring["".concat(property, "_").concat(number)] !== undefined) {
        result.push(scoring["".concat(property, "_").concat(number)]);
        number++;
      }
      return result;
    },
    getAxisRows: function getAxisRows(data) {
      data.forEach(function (item) {
        item.value = 0;
      });
      return data;
    },
    translateOptions: function translateOptions(array) {
      var _this4 = this;
      var arrayCopy = _toConsumableArray(array);
      arrayCopy.forEach(function (item) {
        return item.label = _this4.$trans(item.label);
      });
      return arrayCopy;
    },
    changeLikelihood: function changeLikelihood(value) {
      var _this5 = this;
      if (value) {
        this.$emit('change', 'likelihood_scores', value);
        this.likelihoodMax.forEach(function (item, index) {
          _this5.onAxisChange('likelihoodMax', index, item);
        });
      }
      if (!value && !this.scoring[RiskBaseModelProps.CUSTOM_LIKELIHOOD]) {
        this.axis = GradientTypes.vertical;
        return this.showCreateModal = true;
      }
      this.likelihoodScores = value;
      this.reloadMatrix();
    },
    changeImpact: function changeImpact(value) {
      var _this6 = this;
      if (value) {
        this.$emit('change', 'impact_scores', value);
        this.impactMax.forEach(function (item, index) {
          _this6.onAxisChange('impactMax', index, item);
        });
      }
      if (!value && !this.scoring[RiskBaseModelProps.CUSTOM_IMPACT]) {
        this.axis = GradientTypes.horizontal;
        return this.showCreateModal = true;
      }
      this.impactScores = value;
    },
    onAxisChange: function onAxisChange(propName, propNumber, value) {
      var maxNumber = propNumber + 1;
      var config = {
        likelihoodMax: 'likelihood_max_' + maxNumber,
        impactMax: 'impact_max_' + maxNumber
      };
      var property = config[propName] || propName;
      this.$emit('change', property, value);
    },
    onLabelsAxisChange: function onLabelsAxisChange(index, value) {
      var array = _toConsumableArray(this.likelihoodLabels);
      array.splice(index, 1, value);
      this.$emit('change', 'likelihood_labels', array);
    },
    dismissModal: function dismissModal() {
      this.showCreateModal = false;
    },
    createTable: function createTable() {
      if (this.axis === GradientTypes.vertical) {
        this.likelihoodScores = 0;
        this.reloadMatrix();
      } else {
        this.impactScores = 0;
      }
      this.$emit('create:table');
    },
    onScoringAxisChange: function onScoringAxisChange() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this.$emit.apply(this, ['change'].concat(args));
    },
    getScoreWithLabel: function getScoreWithLabel(score, label) {
      if (label) {
        return "".concat(score, " (").concat(label, ")");
      }
      return score;
    },
    reloadMatrix: function reloadMatrix() {
      var _this7 = this;
      // to refresh view of risk matrix
      this.riskGridShow = false;
      setTimeout(function () {
        _this7.riskGridShow = true;
      }, 100);
    }
  }
};