import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ComplianceStatsWidget from '@/components/Organisms/Compliance/Overview/ComplianceStatsWidget';
import ComplianceStatusOverview from '@/components/Organisms/Compliance/Overview/ComplianceStatusOverview';
import ComplianceReportsBox from '@/components/Organisms/Compliance/Overview/ComplianceReportsBox';
import ComplianceGeneralList from '@/components/Organisms/Compliance/Overview/ComplianceGeneralList';
import MyComplianceNodes from '@/components/Organisms/Compliance/Overview/MyComplianceNodes';
import ComplianceDistributionChart from '@/components/Organisms/Compliance/Overview/ComplianceDistributionChart';
import MaterialityAssessment from '@/components/Organisms/Compliance/Overview//MaterialityAssessment';
import ComplianceGapSummary from '@/components/Organisms/Compliance/Overview/ComplianceGapSummary';
export default {
  name: 'OverviewDynamicLayout',
  description: 'Component for dynamically render content within an overview.  It offers a flexible layout structure that adapts to different sections and components specified through the pageStructure prop',
  components: {
    RismaTitle: RismaTitle,
    ComplianceReportsBox: ComplianceReportsBox,
    ComplianceGeneralList: ComplianceGeneralList,
    ComplianceStatusOverview: ComplianceStatusOverview,
    ComplianceStatsWidget: ComplianceStatsWidget,
    MyComplianceNodes: MyComplianceNodes,
    ComplianceDistributionChart: ComplianceDistributionChart,
    MaterialityAssessment: MaterialityAssessment,
    ComplianceGapSummary: ComplianceGapSummary
  },
  props: {
    pageStructure: {
      required: true,
      type: Array,
      validator: function validator(value) {
        return value.every(function (obj) {
          return obj.components.every(function (component) {
            return Object.prototype.hasOwnProperty.call(component, 'name');
          });
        });
      }
    }
  }
};