function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import * as fileAPI from '@/api/risma/file';
import { clickOutside } from '@/utils/directives/clickOutside';
import { isLockedControlUser as _isLockedControlUser } from '@/utils/access';
import { AttachmentModalMixin } from '@/components/Organisms/AttachmentModalMixin';
import AttachmentDropdown from '@/components/Molecules/AttachmentSection/AttachmentDropdown';
import AttachmentModal from '@/components/Organisms/AttachmentModal';
import EditAttachment from '@/components/Organisms/Contracts/EditAttachment';
import AttachmentVersionsModal from '@/components/Organisms/AttachmentVersionsModal';
import { MODULES } from '@/constants/modules';
import { ATTACHMENT_MODAL_MODES } from '@/constants/Attachments';
import { compareDates } from '@/utils/compare';
export default {
  name: 'Attachment',
  introduction: 'AttachmentSection contains all components to work with load files',
  description: 'The component contains AttachmentDropdown and AttachmentModal',
  token: "<attachement\n              :files-list=[] />",
  components: {
    AttachmentDropdown: AttachmentDropdown,
    AttachmentModal: AttachmentModal,
    AttachmentVersionsModal: AttachmentVersionsModal,
    EditAttachment: EditAttachment
  },
  directives: {
    clickOutside: clickOutside
  },
  mixins: [AttachmentModalMixin],
  props: {
    filesList: {
      type: Array,
      required: true,
      note: 'List of files'
    },
    module: {
      type: String,
      required: true,
      note: 'A name of module, for example, "control" or "risk"'
    },
    moduleId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'An id of one of the entities of modules. For example, an cpath of the specific control'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If attachments are readonly and can be downloaded'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Loading only one file, on reload will replace the file'
    },
    hasPin: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Has pin/unpin functionality'
    },
    hasTitle: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Has title field'
    },
    typeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    filesAndLinksSeparated: {
      type: Boolean,
      required: true,
      note: 'a mode where you can add files and links separately'
    },
    fileTypesEnabled: {
      type: Boolean,
      required: true,
      note: 'show selector with file types'
    },
    editFileEnabled: {
      type: Boolean,
      required: true,
      note: 'show edit button on files'
    },
    scrollLeft: {
      type: Number,
      required: false,
      default: 0,
      note: 'scrollLeft of the parent'
    }
  },
  emits: ['fileUpdate', 'getActivityData', 'updatingAttachments'],
  data: function data() {
    var _this = this;
    return {
      isDropdownOpen: false,
      isConfirmOpen: false,
      isModalOpen: false,
      isVersionsModalOpen: false,
      fileVersionsAddNewMode: true,
      remote: true,
      selectedFileId: false,
      file: null,
      documentType: 0,
      isEditModalActive: false,
      editedFile: null,
      pinnedByDefault: false,
      translate: {
        areYouSureYouWantToDeleteThisAttachment: "\n                    ".concat(this.$trans('Are you sure you want to delete this'), " ").concat(this.$trans('Attachment').toLowerCase(), "?\n                "),
        deleteAttachment: this.$trans('Delete') + ' ' + this.$trans('Attachment').toLowerCase(),
        yes: this.$trans('Yes'),
        no: this.$trans('No'),
        theFileMayBeCorruptedDuringTheUpload: function theFileMayBeCorruptedDuringTheUpload(filename) {
          return _this.$trans('The %s file may be corrupted during the upload process', [filename]);
        }
      },
      isApplyDisabled: false,
      attachmentModalMode: ATTACHMENT_MODAL_MODES.FULL
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    attachmentTitle: function attachmentTitle() {
      return this.$trans(this.filteredFilesList.lengt === 1 ? 'Attachment' : 'Attachments');
    },
    ownerId: function ownerId() {
      return this.currentUser.id;
    },
    isLockedControlUser: function isLockedControlUser() {
      return _isLockedControlUser(this.currentUser);
    },
    hasVisibleFiles: function hasVisibleFiles() {
      return this.module === MODULES.CONTRACTS ? this.filesList.filter(function (file) {
        return file.pinned;
      }).length : this.filesList.length;
    },
    isToggleDropdownActive: function isToggleDropdownActive() {
      return this.hasVisibleFiles || !this.readOnly;
    },
    filteredFilesList: function filteredFilesList() {
      if (!this.hasPin) return this.filesList;
      return this.filesList.filter(function (file) {
        return !file.mimeType || file.pinned;
      });
    },
    sortedFilesList: function sortedFilesList() {
      return _toConsumableArray(this.filteredFilesList).sort(function (a, b) {
        return compareDates(a.created, b.created, true);
      });
    }
  }),
  methods: {
    hide: function hide() {
      if (this.isConfirmOpen || this.isModalOpen) return;
      this.isDropdownOpen = false;
    },
    togglePin: function togglePin(id) {
      var file = this.filesList.find(function (attachment) {
        return +attachment.id === +id;
      });
      if (file) {
        var updatedFile = {
          id: file.id,
          pinned: !file.pinned
        };
        this.updateFile(updatedFile);
      }
    },
    openEditModal: function openEditModal(file) {
      this.editedFile = file;
      this.isEditModalActive = true;
    },
    onFileEdit: function onFileEdit(file) {
      this.isEditModalActive = false;
      this.updateFile(file);
    },
    updateFile: function updateFile(file) {
      var _this2 = this;
      this.$emit('updatingAttachments');
      fileAPI.editFile(file).then(function (response) {
        _this2.checkForCorruptedFiles([file], [response]);
        _this2.$emit('fileUpdate', response.id);
      });
    },
    closeAttachmentModal: function closeAttachmentModal() {
      this.dismissModal();
      this.attachFileErrorMessage = '';
      this.dismissVersionModal();
    },
    dismissModal: function dismissModal() {
      this.isModalOpen = false;
      this.documentType = 0;
    },
    toggleModal: function toggleModal(modalMode) {
      var pinnedByDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.pinnedByDefault = pinnedByDefault;
      this.isModalOpen = !this.isModalOpen;
      if (!this.filesAndLinksSeparated) return;
      this.attachmentModalMode = modalMode;
    },
    toggleVersionsModal: function toggleVersionsModal(file) {
      this.file = file;
      this.fileVersionsAddNewMode = true;
      this.isVersionsModalOpen = !this.isVersionsModalOpen;
    },
    addAttachmentsRemote: function addAttachmentsRemote(attachments) {
      var _this3 = this;
      this.$emit('updatingAttachments');
      this.isApplyDisabled = true;
      fileAPI.uploadFiles(attachments).then(function (responseFiles) {
        _this3.checkForCorruptedFiles(attachments, responseFiles);
        _this3.$emit('getActivityData');
        _this3.closeAttachmentModal();
      }).catch(function (error) {
        _this3.attachFileErrorMessage = _this3.handleResponseError(error);
      }).finally(function () {
        return _this3.isApplyDisabled = false;
      });
    },
    handleAttachmentsNew: function handleAttachmentsNew(attachmentsNew) {
      var newVersion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!attachmentsNew.length) {
        return;
      }
      var fileAttachmentsCount = attachmentsNew.reduce(function (count, attachment) {
        return attachment.url ? count : count + 1;
      }, 0);
      var attachmentBase = {
        owner: this.ownerId
      };
      if (this.module === MODULES.CONTRACTS && fileAttachmentsCount > 1) {
        return;
      }
      if (!newVersion) {
        attachmentBase.module = this.module;
        attachmentBase.moduleId = this.moduleId;
      }
      var attachments = attachmentsNew.map(function (attach) {
        return Object.assign({}, attachmentBase, attach);
      });
      this.addAttachmentsRemote(attachments);
    },
    openConfirmModal: function openConfirmModal(id) {
      var _this4 = this;
      this.selectedFileId = id;
      this.$confirm(this.translate.deleteAttachment, this.translate.areYouSureYouWantToDeleteThisAttachment, function (res) {
        return res && _this4.removeAttachment();
      }, {
        buttonOkText: this.translate.yes,
        buttonDissmisText: this.translate.no
      });
    },
    unselectFile: function unselectFile() {
      this.selectedFileId = null;
    },
    removeAttachment: function removeAttachment() {
      var _this5 = this;
      this.$emit('updatingAttachments');
      return fileAPI.deleteFile(this.selectedFileId).then(function (_ref) {
        var id = _ref.id;
        _this5.$emit('getActivityData');
        _this5.file && _this5.afterDeletion(id);
        _this5.unselectFile();
      });
    },
    onCreateNewVersion: function onCreateNewVersion(id) {
      var _this6 = this;
      this.getFileById(id).then(function () {
        _this6.fileVersionsAddNewMode = true;
        _this6.isVersionsModalOpen = true;
      });
    },
    onShowVersions: function onShowVersions(id) {
      var _this7 = this;
      this.getFileById(id).then(function () {
        _this7.fileVersionsAddNewMode = false;
        _this7.isVersionsModalOpen = true;
      });
    },
    afterDeletion: function afterDeletion(id) {
      this.file.revisions = this.file.revisions.filter(function (revision) {
        return revision.id != id;
      });
    },
    getFileById: function getFileById(id) {
      var _this8 = this;
      return fileAPI.getFileById(id).then(function (data) {
        _this8.file = data;
      });
    },
    dismissVersionModal: function dismissVersionModal() {
      this.isVersionsModalOpen = false;
      this.file = null;
    },
    checkForCorruptedFiles: function checkForCorruptedFiles() {
      var _this9 = this;
      var uploadedFiles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var responseFiles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      uploadedFiles.filter(function (file) {
        return !!file.mimeType || !file.url;
      }).forEach(function (file) {
        var responseFile = responseFiles.find(function (rFile) {
          return rFile.filename === file.filename;
        });
        if (responseFile && responseFile.size !== file.size) {
          _this9.$notify({
            title: _this9.translate.theFileMayBeCorruptedDuringTheUpload(file.filename),
            type: 'error'
          });
        }
      });
    }
  }
};