// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-not-valid[data-v-3420d238]  input {
  border-color: #f25050;
}
.is-not-valid[data-v-3420d238]  input:hover {
  border-color: #f25050;
}
.is-not-valid[data-v-3420d238]  input:focus {
  border-color: #f25050;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
