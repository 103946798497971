function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import draggable from 'vuedraggable';
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { updateWorkflowStatus, createWorkflowStatusByModule, deleteWorkflowStatusByModule, getWorkflowById } from '@/api/admin/workflow';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { DragOptions } from '@/constants/DragOptions';
import { MODULES } from '@/constants/modules';
import { saveStatus, saveStatus as saveStatuses } from '@/constants/SaveStatuses';
import { useWorkflowStore } from '@/Store/workflowStore';
export default {
  name: 'IncidentsWorkflowEdit',
  description: '',
  token: '<incidents-workflow-edit />',
  components: {
    draggable: draggable,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    DragAndDropIcon: DragAndDropIcon,
    Modal: Modal,
    InputField: InputField,
    TinyMceBox: TinyMceBox,
    SingleSelect: SingleSelect,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  props: {
    id: {
      required: false,
      default: null,
      type: String
    }
  },
  data: function data() {
    return {
      saveStatus: saveStatuses.SAVED,
      saveStatuses: saveStatuses,
      showStatusModal: false,
      showDeleteModal: false,
      isCreateMode: false,
      moveToState: null,
      editedStatusData: {
        id: null,
        title: '',
        instructions: ''
      },
      deleteStatusData: {},
      isOkButtonPressed: false,
      moveToStatuses: [],
      workflowStatuses: [],
      DragOptions: DragOptions,
      translate: getTranslate['IncidentsWorkflowEdit']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['isAdmin'])), {}, {
    isStatusTitleInvalid: function isStatusTitleInvalid() {
      return this.isOkButtonPressed && !this.editedStatusData.title;
    },
    readOnly: function readOnly() {
      return !this.isAdmin;
    }
  }),
  mounted: function mounted() {
    this.getWorkflowStatuses();
  },
  methods: {
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this = this;
      return getWorkflowById(this.id).then(function (workflow) {
        _this.workflowStatuses = workflow.workflowStatuses;
      });
    },
    goBack: function goBack() {
      this.$router.push({
        name: 'IncidentsWorkflows'
      });
    },
    addStatus: function addStatus() {
      this.editedStatusData.title = '';
      this.editedStatusData.instructions = '';
      this.isCreateMode = true;
      this.showStatusModal = true;
      this.isOkButtonPressed = false;
    },
    editStatus: function editStatus(status) {
      if (this.readOnly) return;
      var id = status.id,
        title = status.title,
        instructions = status.instructions;
      this.editedStatusData = {
        id: id,
        title: title,
        instructions: instructions
      };
      this.isCreateMode = false;
      this.showStatusModal = true;
      this.isOkButtonPressed = false;
    },
    openDeleteModal: function openDeleteModal(status) {
      var _this2 = this;
      if (!status.inUse) {
        this.$confirm(this.translate.deleteStatus, "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(status.title), function (res) {
          return res && _this2.deleteStatus(status);
        }, {
          buttonOkText: this.translate.delete,
          headerType: 'big'
        });
        return;
      }
      this.showDeleteModal = true;
      this.moveToStatuses = this.workflowStatuses.filter(function (_ref) {
        var id = _ref.id;
        return status.id !== id;
      }).map(function (s) {
        return {
          value: s.id,
          label: s.title
        };
      });
      this.deleteStatusData = status;
    },
    onStatusSettingsChange: function onStatusSettingsChange() {
      var _this3 = this;
      if (!this.editedStatusData.title) {
        this.isOkButtonPressed = true;
        return;
      }
      this.saveStatus = saveStatuses.SAVING;
      this.showStatusModal = false;
      var promise = this.isCreateMode ? this.createStatus(_objectSpread(_objectSpread({}, this.editedStatusData), {}, {
        id: null,
        workflowId: this.id
      })) : this.updateStatus(_objectSpread(_objectSpread({}, this.editedStatusData), {}, {
        workflowId: this.id
      }));
      return promise.catch(function (err) {
        return _this3.showError(err);
      }).finally(function () {
        _this3.editedStatusData.title = '';
        _this3.editedStatusData.instructions = '';
        _this3.isOkButtonPressed = false;
        _this3.saveStatus = saveStatuses.SAVED;
      });
    },
    createStatus: function createStatus(data) {
      var _this4 = this;
      return createWorkflowStatusByModule(MODULES.INCIDENTS, data).then(function () {
        _this4.$notify({
          title: "".concat(_this4.translate.workflowStatus, " ").concat(_this4.translate.created)
        });
        _this4.getWorkflowStatuses();
      }).then(useWorkflowStore().load);
    },
    updateStatus: function updateStatus(data) {
      var _this5 = this;
      return updateWorkflowStatus(MODULES.INCIDENTS, data).then(this.getWorkflowStatuses).then(function () {
        return _this5.$notify({
          title: "".concat(_this5.translate.workflowStatus, " ").concat(_this5.translate.updated)
        });
      }).then(useWorkflowStore().load);
    },
    deleteStatus: function deleteStatus(status) {
      var _this6 = this;
      this.saveStatus = saveStatuses.SAVING;
      this.showDeleteModal = false;
      var data = this.moveToState ? {
        moveToState: this.moveToState
      } : null;
      deleteWorkflowStatusByModule(MODULES.INCIDENTS, status.id, data).then(function () {
        _this6.workflowStatuses = _this6.workflowStatuses.filter(function (_ref2) {
          var id = _ref2.id;
          return +id !== +status.id;
        });
        _this6.deleteStatusData = {};
        _this6.moveToState = null;
        _this6.$notify({
          title: "".concat(_this6.translate.workflowStatus, " ").concat(_this6.translate.deleted)
        });
      }).then(useWorkflowStore().load).catch(function (err) {
        return _this6.showError(err);
      }).finally(function () {
        return _this6.saveStatus = saveStatuses.SAVED;
      });
    },
    statusMoved: function statusMoved(_ref3) {
      var _this7 = this;
      var moved = _ref3.moved;
      this.saveStatus = saveStatus.SAVING;
      return this.updateStatus(_objectSpread(_objectSpread({}, moved.element), {}, {
        order: moved.newIndex + 1
      })).then(useWorkflowStore().load).catch(function (err) {
        return _this7.showError(err);
      }).finally(function () {
        return _this7.saveStatus = saveStatuses.SAVED;
      });
    },
    showError: function showError(err) {
      var _err$response$error, _err$response;
      this.$notify({
        title: (_err$response$error = err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) !== null && _err$response$error !== void 0 ? _err$response$error : this.translate.anErrorOccurred,
        type: 'error'
      });
    }
  }
};