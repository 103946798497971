import i18n from '@/i18n';
export var getTranslate = {
  AdminSuggestedActivities: function AdminSuggestedActivities() {
    return {
      suggestedActivities: i18n.t('Suggested activities'),
      gapSchemaType: i18n.t('Gap schema type'),
      chooseGapSchemaType: i18n.t('Choose gap schema type'),
      gapSchema: i18n.t('Gap schema'),
      chooseGapSchema: i18n.t('Choose gap schema'),
      addNew: i18n.t('Add new'),
      theActivityWillBeDeleted: i18n.t('The activity will be deleted'),
      areYouSure: i18n.t('Are you sure'),
      article: i18n.t('Article'),
      articleTitle: i18n.t('Article Title'),
      requirement: i18n.t('Requirement'),
      suggestedActions: i18n.t('Suggested actions'),
      suggestedControls: i18n.t('Suggested controls'),
      delete: i18n.t('Delete')
    };
  }
};