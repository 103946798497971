function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import * as Cookies from 'tiny-cookie';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TileCard from '@/components/Molecules/Cards/TileCard';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import { findPropById } from '@/utils/Utils';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'PreviewListTile',
  introduction: 'Container for tile list',
  description: 'Has show / hide feature and saves state to cookie',
  token: '<preview-list-tile title="All list"> <slot /> </preview-list-tile>',
  components: {
    FeatherIcon: FeatherIcon,
    TileCard: TileCard,
    RismaTitle: RismaTitle,
    LoadMoreWrapper: LoadMoreWrapper
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
      note: 'A title of a list'
    },
    cookieHiddenName: {
      type: String,
      required: false,
      default: '',
      note: 'Save last state of show/hide feature in cookie'
    },
    activities: {
      type: Array,
      required: true,
      note: 'All activities'
    },
    watermarkImage: {
      type: [String, Function],
      required: false,
      default: null,
      note: 'Url of background watermark image'
    },
    disablePreview: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if PreviewModalLink is disable (example: Reports)'
    },
    internalLinkEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if TileCard will have title as internal link'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isControlShownFromParent: {
      type: Boolean,
      required: false,
      default: false
    },
    workflows: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      isShowAll: false,
      translate: {
        hide: this.$trans('Hide'),
        show: this.$trans('Show')
      }
    };
  },
  computed: {
    fullTitle: function fullTitle() {
      return this.title + " (".concat(this.activities.length, ")");
    },
    showStateTitle: function showStateTitle() {
      return this.isShowAll ? this.translate.hide : this.translate.show;
    },
    formattedActivities: function formattedActivities() {
      var _this = this;
      if (this.workflows.length) {
        return this.activities.map(function (activity) {
          return _this.formatActivity(activity);
        });
      }
      return this.activities;
    }
  },
  watch: {
    isControlShownFromParent: function isControlShownFromParent(changed) {
      this.isShowAll = changed;
    }
  },
  mounted: function mounted() {
    var cookieValue = Cookies.getCookie(this.cookieHiddenName);
    var isHidden = cookieValue || cookieValue === 'hidden';
    this.isShowAll = !isHidden;
    this.$emit('changeShowState', this.isShowAll);
  },
  methods: {
    changeShowState: function changeShowState(action) {
      this.isShowAll = action !== null ? action : !this.isShowAll;
      if (this.isShowAll) {
        Cookies.removeCookie(this.cookieHiddenName);
      } else {
        Cookies.setCookie(this.cookieHiddenName, 'hidden', {
          samesite: 'strict'
        });
      }
      this.$emit('changeShowState', this.isShowAll);
    },
    formatActivity: function formatActivity(activity) {
      switch (activity.objType) {
        case ObjectTypes.CONTRACT:
          return _objectSpread(_objectSpread({}, activity), {}, {
            statusLabel: findPropById(activity.workflowStatusId, this.workflows, 'title')
          });
        case ObjectTypes.INCIDENTS_INCIDENT:
          return _objectSpread(_objectSpread({}, activity), {}, {
            statusLabel: findPropById(activity.workflowStatusId, this.workflows, 'label')
          });
        default:
          return activity;
      }
    }
  }
};