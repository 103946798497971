function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import ExpansionPanel from '@/components/Atoms/ExpansionPanel/ExpansionPanel';
import InternalSectionOverview from '@/components/Organisms/Contracts/InternalSectionOverview';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { getTranslate } from './translate';
export default {
  name: 'ContractPartyInternalSection',
  components: {
    InternalSectionOverview: InternalSectionOverview,
    ExpansionPanel: ExpansionPanel,
    ToggleMenu: ToggleMenu,
    RismaTitle: RismaTitle
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  emits: ['update', 'copy', 'delete'],
  data: function data() {
    return {
      VIEWS: {
        list: 'list',
        overview: 'overview'
      },
      selectedView: 'overview',
      menuItemClasses: 'text-xs text-rm-text hover:bg-rGrey-400 stroke-rm-text',
      translate: getTranslate['ContractPartyInternalSection']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasListOption: function hasListOption() {
      return this.getSettingValue('feature.contracts.parties_list_option');
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var list = [];
      list.push({
        icon: 'copy',
        title: this.translate.copyToSubcompany,
        class: this.menuItemClasses,
        action: this.handleCopy
      });
      if (this.hasListOption) {
        var viewItem = this.getViewItem();
        list.push(viewItem);
      }
      list.push({
        title: this.translate.deleteSection,
        icon: 'trash',
        class: 'text-rm-signal-red text-xs hover:bg-rGrey-400 stroke-signal-red',
        action: this.handleDelete
      });
      return list;
    }
  }),
  methods: {
    getViewItem: function getViewItem() {
      var _this = this;
      if (this.selectedView === this.VIEWS.list) {
        return {
          title: this.translate.backToOverview,
          icon: 'align-justify',
          class: this.menuItemClasses,
          action: function action() {
            return _this.selectedView = _this.VIEWS.overview;
          }
        };
      }
      return {
        title: this.translate.seeAsList,
        icon: 'align-justify',
        class: this.menuItemClasses,
        action: function action() {
          return _this.selectedView = _this.VIEWS.list;
        }
      };
    },
    handleCopy: function handleCopy() {
      this.$emit('copy', this.section.id);
    },
    handleDelete: function handleDelete() {
      var _this$section$service;
      this.$emit('delete', {
        id: this.section.id,
        sectionName: (_this$section$service = this.section.serviceProviders[0]) === null || _this$section$service === void 0 ? void 0 : _this$section$service.label
      });
    }
  }
};