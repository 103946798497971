// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-image[data-v-f7cd66ec] {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.save-image[data-v-f7cd66ec]:hover {
  text-decoration: none;
}
.save-image.disabled[data-v-f7cd66ec] {
  pointer-events: none;
  opacity: 0.7;
}
.save-image[data-v-f7cd66ec]  .risma-title {
  margin-right: 0.5rem;
}
.save-image[data-v-f7cd66ec]  .risma-title h1 {
  font-size: 17px;
  color: #737373;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
