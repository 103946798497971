import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "risk-report-grid-wrap"
};
var _hoisted_2 = {
  class: "flex flex-wrap"
};
var _hoisted_3 = {
  class: "mb-6"
};
var _hoisted_4 = {
  class: "risk-grid-labels-wrap"
};
var _hoisted_5 = {
  class: "risk-grid-labels"
};
var _hoisted_6 = {
  key: 0,
  class: "flex items-center"
};
var _hoisted_7 = {
  key: 0,
  class: "info"
};
var _hoisted_8 = {
  key: 1,
  class: "border border-gray-450 p-4 mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risk_grid = _resolveComponent("risk-grid");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_export_frame = _resolveComponent("export-frame");
  var _component_preview_risk_list = _resolveComponent("preview-risk-list");
  var _component_risk_report_datatable = _resolveComponent("risk-report-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.matrixData, function (matrix, matrixIndex) {
    return _openBlock(), _createElementBlock("div", {
      key: matrixIndex,
      class: "min-w-580px"
    }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_export_frame, null, {
      default: _withCtx(function () {
        return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
          title: matrix.label || $data.translate.legend,
          type: "medium"
        }, null, 8 /* PROPS */, ["title"]), _createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($data.riskGridData.colorCodes), function (item, index) {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("span", {
            style: _normalizeStyle($data.riskGridData.colorCodes[item]),
            class: "label-circle"
          }, null, 4 /* STYLE */), _createElementVNode("span", null, _toDisplayString($options.labelNames[item]), 1 /* TEXT */)]);
        }), 128 /* KEYED_FRAGMENT */))])]), _createVNode(_component_risk_grid, {
          "grid-box-size": $data.gridBoxSize,
          "risk-grid-data": $options.preparedRiskGridData[matrixIndex],
          "without-z-axis": true,
          periods: $options.prevPeriods(matrix.id),
          "enable-prev-position": !!$props.previousPeriods.length,
          "show-previous-position-label": $data.translate.displayPreviousPositionRelativeToThePeriod,
          "enable-zoom": $data.enableZoom,
          "zoom-range": $data.zoomRange,
          "zoom-step": $data.zoomStep,
          "start-color": $props.startColor,
          "end-color": $props.endColor,
          "use-export": false,
          "show-lines": $props.showPreviousPositionArray[matrixIndex] || false,
          "custom-heatmap-options": $props.customHeatmapOptions,
          "dot-connector-enabled": $data.showInherentMatrixIds[matrix.id],
          onOnPeriodChanged: function onOnPeriodChanged($event) {
            return _ctx.$emit('onPeriodChanged', {
              event: $event,
              consequenceId: matrix.id
            });
          },
          onTogglePreviousPosition: function onTogglePreviousPosition($event) {
            return $options.handleTogglePreviousPosition($event, matrixIndex, matrix.id);
          },
          onOnStateChanged: _cache[0] || (_cache[0] = function ($event) {
            return $data.matrixState = $event;
          })
        }, null, 8 /* PROPS */, ["grid-box-size", "risk-grid-data", "periods", "enable-prev-position", "show-previous-position-label", "enable-zoom", "zoom-range", "zoom-step", "start-color", "end-color", "show-lines", "custom-heatmap-options", "dot-connector-enabled", "onOnPeriodChanged", "onTogglePreviousPosition"]), $props.inherentRisksEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_toggle, {
          modelValue: $data.showInherentMatrixIds[matrix.id],
          "onUpdate:modelValue": [function ($event) {
            return $data.showInherentMatrixIds[matrix.id] = $event;
          }, function ($event) {
            return $options.onShowInherentMatrixIds(matrixIndex);
          }],
          class: "mr-1.5"
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_risma_title, {
          title: _ctx.$trans('Display inherent risk value'),
          type: "medium"
        }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])];
      }),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)])]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", null, [$options.risksSelectedSections[0].list.length ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_preview_risk_list, {
    sections: $options.risksSelectedSections,
    organisations: $props.organisations,
    onUpdateFromPreviewMode: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    })
  }, null, 8 /* PROPS */, ["sections", "organisations"])])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [$props.risks.length ? (_openBlock(), _createBlock(_component_risk_report_datatable, {
    key: 0,
    "project-id": $props.projectId,
    tags: $props.tags,
    risks: $props.risks,
    "current-period": $props.currentPeriod,
    "is-current-period-selected": $props.isCurrentPeriodSelected,
    "consequence-list": $props.consequenceList,
    "risk-approval-enabled": $props.riskApprovalEnabled,
    organisations: $props.organisations,
    users: $props.users,
    "management-levels": $props.managementLevels,
    "is-erm": $props.isErm,
    threats: $props.threats,
    "level-of-threats": $props.levelOfThreats,
    vulnerabilities: $props.vulnerabilities,
    probabilities: $props.probabilities,
    controls: $props.controls,
    initiatives: $props.initiatives,
    systems: $props.systems,
    incidents: $props.incidents,
    "process-library-nodes": $props.processLibraryNodes,
    "compliance-nodes": $props.complianceNodes,
    "risk-types": $props.riskTypes,
    "parent-risks": $props.parentRisks,
    "inherent-risks-enabled": $props.inherentRisksEnabled,
    "report-options": $props.reportOptions,
    onDismissModal: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismissModal', $event);
    })
  }, null, 8 /* PROPS */, ["project-id", "tags", "risks", "current-period", "is-current-period-selected", "consequence-list", "risk-approval-enabled", "organisations", "users", "management-levels", "is-erm", "threats", "level-of-threats", "vulnerabilities", "probabilities", "controls", "initiatives", "systems", "incidents", "process-library-nodes", "compliance-nodes", "risk-types", "parent-risks", "inherent-risks-enabled", "report-options"])) : _createCommentVNode("v-if", true)]))])]);
}