// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-74efb720]::-webkit-input-placeholder {
  color: #cccccc;
  font-style: italic;
}
[data-v-74efb720]:-moz-placeholder {
  color: #cccccc;
  font-style: italic;
}
[data-v-74efb720]::-moz-placeholder {
  color: #cccccc;
  font-style: italic;
}
[data-v-74efb720]:-ms-input-placeholder {
  color: #cccccc;
  font-style: italic;
}
.items-list[data-v-74efb720] {
  max-height: 205px;
}
.item[data-v-74efb720]:focus {
  outline: 2px solid #6fa3f4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
