import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  key: 0,
  class: "relative mb-3"
};
var _hoisted_3 = {
  class: "flex items-center"
};
var _hoisted_4 = {
  class: "pb-3 border-t border-gray-450"
};
var _hoisted_5 = {
  class: "py-2 font-bold text-gray-650"
};
var _hoisted_6 = {
  class: "mr-2 flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_selector = _resolveComponent("selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.attach + ' ' + $options.informationAssetsName,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    "modal-fixed-width": "530px",
    onAccept: $options.updateLinks,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
        modelValue: $data.selectedLinkOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedLinkOption = $event;
        }),
        title: $options.selectIALabel,
        options: $options.linkOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"])]), $data.selectedLinkOption ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$options.showCreateItemButton ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute right-4 flex cursor-pointer",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('showAssetCreationModal', $options.assetTypeBySelectedModule);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus"
      }), _createVNode(_component_risma_title, {
        title: $data.translate.createNew,
        type: "small"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createVNode(_component_selector, {
        items: $options.selectableItems,
        title: $options.selectLinkLabel,
        "search-placeholder": $options.selectLinkPlaceholder,
        "search-fields": ['title'],
        class: "mb-2",
        onSelected: $options.onLinkSelect
      }, {
        displayedName: _withCtx(function (slotProps) {
          return [_createElementVNode("div", _hoisted_3, [slotProps.item.trafficLight !== undefined && slotProps.item.trafficLight !== null ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            color: _ctx.getTrafficLightColor(slotProps.item),
            class: "inline-block align-baseline mr-4"
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(slotProps.item.title || slotProps.item.label), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["items", "title", "search-placeholder", "onSelected"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.addedLinks), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedItems, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "flex items-baseline px-1 py-1 rounded-md cursor-default hover:bg-gray-350"
        }, [_createElementVNode("div", _hoisted_6, [item.trafficLight !== undefined ? (_openBlock(), _createBlock(_component_traffic_light, {
          key: 0,
          color: _ctx.getTrafficLightColor(item),
          class: "inline-block align-baseline mr-4"
        }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(item.title || item.name), 1 /* TEXT */)]), _createVNode(_component_feather_icon, {
          icon: "x",
          width: "12",
          height: "12",
          class: _normalizeClass('stroke-gray stroke-3 hover:stroke-red-50 cursor-pointer'),
          onClick: function onClick($event) {
            return $options.onLinkDeselect(item);
          }
        }, null, 8 /* PROPS */, ["onClick"])]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}