import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex items-center outline-none mx-4 mb-2 border-b border-rm-grey"
};
var _hoisted_2 = ["placeholder"];
var _hoisted_3 = {
  ref: "options",
  tabindex: "-1",
  class: "max-h-300px overflow-auto"
};
var _hoisted_4 = ["onMousedown", "onMouseenter"];
var _hoisted_5 = ["title"];
var _hoisted_6 = {
  key: 0,
  class: "mr-4 ml-8 flex-grow h-0.5px bg-rm-grey"
};
var _hoisted_7 = {
  key: 0,
  class: "glob-h3-sr sticky flex items-center pt-2 pb-1 px-4 justify-between bg-white"
};
var _hoisted_8 = {
  class: "italic"
};
var _hoisted_9 = {
  key: 1,
  class: "glob-h3-sr text-rm-text px-4 py-1 content-center"
};
var _hoisted_10 = {
  key: 1,
  class: "glob-h3-sr text-rm-text px-4 py-1 content-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _directive_focus = _resolveDirective("focus");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full border border-rm-grey rounded-md py-2 glob-h3-sr my-1 bg-rm-white absolute z-100", [{
      'bottom-full': $props.openDirection === 'top'
    }, {
      'top-full': $props.openDirection === 'below'
    }]])
  }, [!$props.isEmpty ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [$props.searchEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_feather_icon, {
    icon: "search",
    class: "stroke-2 text-rm-grey"
  }), _withDirectives(_createElementVNode("input", {
    ref: "input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.searchValue = $event;
    }),
    placeholder: $data.translate.search,
    type: "search",
    class: "h-11 w-full text-rm-text bg-inherit px-2 outline-none",
    onInput: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('search', $event.target.value);
    }),
    onKeyup: [_cache[2] || (_cache[2] = _withKeys(function ($event) {
      return _ctx.$emit('close');
    }, ["esc"])), _cache[5] || (_cache[5] = _withKeys(_withModifiers(function ($event) {
      return _ctx.$emit('inputEvent', 'enter');
    }, ["stop"]), ["enter"]))],
    onKeydown: [_cache[3] || (_cache[3] = _withKeys(_withModifiers(function ($event) {
      return _ctx.$emit('inputEvent', 'down');
    }, ["prevent"]), ["down"])), _cache[4] || (_cache[4] = _withKeys(_withModifiers(function ($event) {
      return _ctx.$emit('inputEvent', 'up');
    }, ["prevent"]), ["up"])), _cache[6] || (_cache[6] = _withKeys(_withModifiers(function ($event) {
      return _ctx.$emit('inputEvent', 'delete');
    }, ["stop"]), ["delete"]))],
    onBlur: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('inputBlur');
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), [[_vModelText, $data.searchValue], [_directive_focus, true]])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    onMouseleave: _cache[10] || (_cache[10] = function ($event) {
      return _ctx.$emit('onMouseleave');
    })
  }, [$props.enableSelectAll ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["text-rm-grey px-4 py-2 content-center w-full glob-h3-sr flex min-h-40px cursor-pointer", {
      'underline text-rm-text': $props.currentPointerIndex === -1
    }]),
    onMousedown: _cache[8] || (_cache[8] = _withModifiers(function ($event) {
      return _ctx.$emit('selectAll');
    }, ["stop", "prevent"])),
    onMouseenter: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.$emit('hover', -1);
    })
  }, _toDisplayString($options.selectAllText), 35 /* TEXT, CLASS, NEED_HYDRATION */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [$props.filteredOptions.length ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filteredOptions, function (option, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "dropdown_option_".concat(index),
      onMousedown: _withModifiers(function ($event) {
        return _ctx.$emit('select', {
          option: option,
          index: index
        });
      }, ["stop", "prevent"]),
      onMouseenter: function onMouseenter($event) {
        return _ctx.$emit('hover', index);
      }
    }, [_renderSlot(_ctx.$slots, "option", {
      option: option
    }, function () {
      return [_createElementVNode("div", {
        class: _normalizeClass(["px-4 py-2 content-center w-full flex min-h-40px items-center break-all", $options.getTextAndBgClasses(option, index)])
      }, [_renderSlot(_ctx.$slots, "label", {
        option: option
      }, function () {
        return [_createElementVNode("span", {
          title: option[$props.labelKey],
          class: _normalizeClass([{
            '-ml-2 glob-l1 text-rm-grey min-w-36': option.isGroup
          }, {
            'mr-4': $props.selectedOptions.includes(option[$props.trackBy])
          }])
        }, _toDisplayString(option[$props.labelKey]), 11 /* TEXT, CLASS, PROPS */, _hoisted_5)];
      }), option.isGroup ? (_openBlock(), _createElementBlock("div", _hoisted_6)) : _createCommentVNode("v-if", true), $props.selectedOptions.includes(option[$props.trackBy]) ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "check",
        class: "stroke-2 ml-auto w-6 flex text-rm-active-blue min-w-1.5rem"
      })) : _createCommentVNode("v-if", true)], 2 /* CLASS */)];
    })], 40 /* PROPS, NEED_HYDRATION */, _hoisted_4);
  }), 128 /* KEYED_FRAGMENT */)), !$props.allDataShown ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString($data.translate.scrollDownForMoreResults), 1 /* TEXT */), $props.isLazyLoadingShown ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($data.translate.noElementsFoundConsiderChangingTheSearchQu), 1 /* TEXT */))], 512 /* NEED_PATCH */)], 32 /* NEED_HYDRATION */)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */))], 2 /* CLASS */);
}