function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getDocumentTypesAll } from '@/api/admin/documentTypes';
import { getTableContracts } from '@/api/contracts/contracts';
import * as fileAPI from '@/api/risma/file';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTable from '@/components/Molecules/StaticTable';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { MODULES } from '@/constants/modules';
import { toLocalDateTimeFull } from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { findPropsByIds } from '@/utils/Utils';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { getFiltersQueryString } from '@/api/httpUtils';
export default {
  name: 'ContractsFileDocuments',
  description: "Component for displaying a table with the Contract's Documents",
  token: '<contracts-file-documents />',
  components: {
    RismaButton: RismaButton,
    PreviewModalLink: PreviewModalLink,
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    InputField: InputField,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    RismaTitleWithIcon: RismaTitleWithIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    isContent: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is content page'
    }
  },
  emits: ['dismissModal'],
  data: function data() {
    return {
      files: [],
      searchField: '',
      contractStatuses: [],
      selectedFileTypes: [],
      selectedContractStatuses: [],
      contracts: [],
      searchClicked: false,
      documentTypes: [],
      translate: getTranslate['ContractsFileDocuments'](),
      isLoaded: false,
      isTableDataLoading: false
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.title, this.translate.contract, this.translate.contractStatus, this.translate.uploadDate, this.translate.type, this.translate.versions, ''];
    },
    enhancedFiles: function enhancedFiles() {
      var _this = this;
      return this.files.map(function (file) {
        var fileContract = _this.contracts.find(function (contract) {
          return file.moduleId === contract.id;
        });
        return _objectSpread(_objectSpread({}, file), {}, {
          contract: fileContract
        });
      });
    },
    dataset: function dataset() {
      var _this2 = this;
      return this.enhancedFiles.map(function (item) {
        var _this2$preparedContra;
        var typeString = findPropsByIds(item.docTypeIds, _this2.fileTypes, 'label', ', ');
        typeString || (typeString = _this2.$trans('Not specified'));
        return {
          title: {
            url: item.url,
            filename: item.filename,
            title: item.title || ''
          },
          contract: {
            activity: item.contract,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item.contract)
          },
          contractStatus: ((_this2$preparedContra = _this2.preparedContractStatuses.find(function (status) {
            var _item$contract;
            return status.id === (item === null || item === void 0 || (_item$contract = item.contract) === null || _item$contract === void 0 ? void 0 : _item$contract.workflowStatusId);
          })) === null || _this2$preparedContra === void 0 ? void 0 : _this2$preparedContra.label) || '',
          upload: toLocalDateTimeFull(item.created),
          type: typeString,
          versions: item.version,
          download: item
        };
      });
    },
    localStorageKey: function localStorageKey() {
      return this.isContent ? 'contract-content-filters' : 'contract-documents-filters';
    },
    fileTypes: function fileTypes() {
      var _this3 = this;
      var map = this.documentTypes.map(function (type) {
        return {
          id: type.id,
          label: _this3.$trans(type.name)
        };
      });
      map.unshift({
        id: DocumentTypesIds.UNKNOWN,
        label: this.$trans('Not specified')
      });
      return map;
    },
    preparedContractStatuses: function preparedContractStatuses() {
      var _this4 = this;
      return this.contractStatuses.map(function (item) {
        return {
          id: item.id,
          label: _this4.$trans(item.title)
        };
      });
    },
    searchTitle: function searchTitle() {
      return this.$trans(this.isContent ? 'Full text search' : 'Search');
    },
    preparedFilterParams: function preparedFilterParams() {
      var params = '';
      if (this.selectedContractStatuses.length > 0) {
        this.selectedContractStatuses.forEach(function (contractStatus) {
          params += '&filters[workflowStatusId][]=' + contractStatus.id;
        });
      }
      if (this.selectedFileTypes.length > 0) {
        this.selectedFileTypes.forEach(function (fileType) {
          params += '&filters[documentTypeId][]=' + fileType.id;
        });
      }
      if (this.isContent && this.searchField) {
        params += '&filters[content]=' + this.searchField;
      }
      return params;
    },
    preparedSearchParam: function preparedSearchParam() {
      var result = '&filters[type]=file';
      if (!this.isContent && this.searchField) {
        result += '&search=' + this.searchField;
      }
      return result;
    }
  },
  mounted: function mounted() {
    var _this5 = this;
    var storageValue = localStorage.getItem(this.localStorageKey);
    this.getData().then(function () {
      if (storageValue) {
        _this5.getValuesFromStorage(storageValue);
      } else {
        _this5.selectedFileTypes = _this5.fileTypes;
        _this5.selectedContractStatuses = _this5.preparedContractStatuses;
      }
    }).finally(function () {
      return _this5.isLoaded = true;
    });
  },
  methods: {
    getValuesFromStorage: function getValuesFromStorage(storageValue) {
      var _JSON$parse = JSON.parse(storageValue),
        selectedContractStatuses = _JSON$parse.selectedContractStatuses,
        selectedFileTypes = _JSON$parse.selectedFileTypes;
      this.selectedFileTypes = this.fileTypes.filter(function (type) {
        return selectedFileTypes.includes(type.id);
      });
      this.selectedContractStatuses = this.preparedContractStatuses.filter(function (type) {
        return selectedContractStatuses.includes(type.id);
      });
    },
    loadFiles: function loadFiles() {
      var _this6 = this;
      return fileAPI.searchFiles("?module=contracts".concat(this.preparedSearchParam).concat(this.preparedFilterParams)).then(function (_ref) {
        var list = _ref.list;
        return _this6.files = list;
      });
    },
    getData: function getData() {
      return Promise.all([this.getWorkflowStatuses(), this.getDocumentTypes()]);
    },
    getDocumentTypes: function getDocumentTypes() {
      var _this7 = this;
      return getDocumentTypesAll().then(function (_ref2) {
        var list = _ref2.list;
        _this7.documentTypes = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this8 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref3) {
        var list = _ref3.list;
        return _this8.contractStatuses = list;
      });
    },
    getContracts: function getContracts() {
      var _this9 = this;
      var query = this.getContractsFiltersQuery();
      return getTableContracts(query).then(function (_ref4) {
        var list = _ref4.list;
        return _this9.contracts = list;
      });
    },
    getContractsFiltersQuery: function getContractsFiltersQuery() {
      var ids = this.files.map(function (file) {
        return file.moduleId;
      });
      var uniqueIds = _toConsumableArray(new Set(ids));
      return getFiltersQueryString('id', uniqueIds);
    },
    selectContractStatus: function selectContractStatus(e) {
      this.selectedContractStatuses = e;
    },
    selectFileTypes: function selectFileTypes(e) {
      this.selectedFileTypes = e;
    },
    doSearch: function doSearch() {
      var _this10 = this;
      this.isTableDataLoading = true;
      this.saveFiltersToLocalStorage();
      if (this.isContent && !this.searchField) {
        this.searchClicked = true;
        this.isTableDataLoading = false;
        return;
      }
      this.loadFiles().then(function () {
        if (_this10.files.length) return _this10.getContracts();
      }).then(function () {
        _this10.searchClicked = true;
        _this10.isTableDataLoading = false;
      });
    },
    resetFilter: function resetFilter() {
      this.selectedContractStatuses = [];
      this.selectedFileTypes = [];
      this.searchField = '';
      this.saveFiltersToLocalStorage();
    },
    saveFiltersToLocalStorage: function saveFiltersToLocalStorage() {
      var preparedDataToSave = JSON.stringify({
        selectedContractStatuses: this.selectedContractStatuses.map(function (_ref5) {
          var id = _ref5.id;
          return id;
        }),
        selectedFileTypes: this.selectedFileTypes.map(function (_ref6) {
          var id = _ref6.id;
          return id;
        })
      });
      localStorage.setItem(this.localStorageKey, preparedDataToSave);
    }
  }
};