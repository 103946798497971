import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded px-6 py-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_table, {
    "state-key": $data.stateLocal,
    "report-type": $data.reportType,
    "show-search-field": true,
    "compliance-project-id": $props.projectId,
    api: $options.getTableReport
  }, null, 8 /* PROPS */, ["state-key", "report-type", "compliance-project-id", "api"])]);
}