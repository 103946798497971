function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ListOptions from '@/components/Atoms/ListOptions/ListOptions';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaColumnSwitcher from '@/components/Atoms/RismaColumnSwitcher/RismaColumnSwitcher';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RiskFilters from '@/components/Molecules/Risk/RiskFilters';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import RiskGroupSortBy from '@/components/Molecules/Risk/RiskGroupSortBy';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import RiskCreationModal from '@/components/Molecules/Modal/RiskCreationModal';
import ScoringInfoBox from '@/components/Organisms/Risk/ScoringInfoBox';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import TagItem from '@/components/Atoms/Containers/TagItem';
import { RiskListMixin } from '@/components/Pages/Risks/RiskListMixin';
import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { MODULES } from '@/constants/modules';
import { UserLevels } from '@/constants/UserLevels';
import { getRiskVisibleProps } from '@/constants/ActivityVisibleProps';
var MODULE_NAME = MODULES.RISK;
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import { getDataControls as _getDataControls } from '@/api/controls/controls';
import { getRiskTypes as _getRiskTypes, getRisks } from '@/api/risk/risk';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { sortBy } from '@/utils/risks/sortRisks';
import { groupBy } from '@/utils/risks/groupRisks';
import { generateKey } from '@/utils/Utils';
import { filterRisksByOrg, filterRisksByParentRisk, filterRisksByTags, searchByTitleAndId } from '@/utils/risks/filterRisks';
import { checkERMByProjectId } from '@/utils/risks/check';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var OPTION_DEFAULT = {
  search: '',
  filterByTags: [],
  filterByParentRisk: null,
  filterByOrganisations: [],
  sortBy: null,
  groupBy: null
};
export default {
  name: 'RiskOverview',
  introduction: '',
  description: '',
  token: '<risk-overview />',
  components: {
    RismaTitle: RismaTitle,
    ListOptions: ListOptions,
    PreviewList: PreviewList,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RiskGroupSortBy: RiskGroupSortBy,
    RiskFilters: RiskFilters,
    CatalogueHeader: CatalogueHeader,
    RismaButton: RismaButton,
    PreviewListTile: PreviewListTile,
    CompactCard: CompactCard,
    ScoringInfoBox: ScoringInfoBox,
    PreviewListExpanded: PreviewListExpanded,
    RiskCreationModal: RiskCreationModal,
    RismaTable: RismaTable,
    RismaColumnSwitcher: RismaColumnSwitcher,
    FeatherIcon: FeatherIcon,
    TagItem: TagItem
  },
  mixins: [CommonAssetsMixin, RiskListMixin],
  setup: function setup() {
    return {
      hiddenColumnIndexes: getLocalStorageComputed('risk_overview_columns_view', ''),
      viewSelected: getLocalStorageComputed('risk_overview_view_storage', viewTypes.EXPANDED),
      isOverviewOptionsExpanded: getLocalStorageComputed('risk_overview_options', false, true)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      risksLoadedOnce: false,
      tableGeneratedKey: generateKey(),
      options: Object.assign({}, OPTION_DEFAULT),
      sortBy: null,
      groupBy: null,
      unformattedRisks: [],
      visibleColumns: [],
      output: [],
      STANDARD_VIEWS: STANDARD_VIEWS,
      viewTypes: viewTypes,
      scoring: {},
      riskTypes: [],
      MODULE_NAME: MODULE_NAME,
      showRiskCreationModal: false,
      translate: getTranslate['RiskOverview']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser', 'isAdmin', 'canCreateRiskByProjectId'])), {}, {
    userCanCreateRisk: function userCanCreateRisk() {
      return this.canCreateRiskByProjectId(this.projectId);
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    showScoringInfoBox: function showScoringInfoBox() {
      return this.getSettingValue('feature.scoring_info_box') && this.isERM && this.isLoaded && (this.currentUser.level_risk >= UserLevels.SUPER || this.currentUser.level_admin);
    },
    parentRisks: function parentRisks() {
      var parentRisks = this.risks.filter(function (risk) {
        return risk.children.length;
      });
      return parentRisks.map(function (risk) {
        return {
          value: risk.id,
          label: "".concat(risk.rno, ". ").concat(risk.title)
        };
      });
    },
    localStorageName: function localStorageName() {
      return "risks_overview_project".concat(this.projectId);
    },
    tableStateKey: function tableStateKey() {
      return "risks_overview_table_state_".concat(this.projectId);
    },
    scoringTitle: function scoringTitle() {
      return this.isERM && this.scoring ? this.scoring.version : '';
    },
    showRismaTable: function showRismaTable() {
      return this.viewSelected === viewTypes.COMPACT && !this.isERM;
    },
    columnSwitchVisible: function columnSwitchVisible() {
      return this.isERM && this.viewSelected === viewTypes.COMPACT;
    },
    hiddenColumnKeys: function hiddenColumnKeys() {
      return this.visibleColumns.filter(function (col) {
        return !col.visible;
      }).map(function (col) {
        return col.key;
      });
    },
    appliedFilters: function appliedFilters() {
      var _this$options$filterB, _this$options$filterB2;
      var result = [];
      if (this.options.search) result.push({
        key: 'search',
        title: this.translate.search
      });
      if ((_this$options$filterB = this.options.filterByTags) !== null && _this$options$filterB !== void 0 && _this$options$filterB.length) result.push({
        key: 'filterByTags',
        title: this.translate.tags
      });
      if (this.options.filterByParentRisk) result.push({
        key: 'filterByParentRisk',
        title: this.translate.parentRisk
      });
      if ((_this$options$filterB2 = this.options.filterByOrganisations) !== null && _this$options$filterB2 !== void 0 && _this$options$filterB2.length) result.push({
        key: 'filterByOrganisations',
        title: this.translate.organisations
      });
      return result;
    }
  }),
  watch: {
    options: {
      deep: true,
      handler: function handler() {
        localStorage.setItem(this.localStorageName, JSON.stringify(this.options));
        this.applyOptions();
      }
    },
    viewSelected: function viewSelected(newView) {
      if (newView === viewTypes.COMPACT && !this.isERM) {
        return;
      }
      if (!this.risksLoadedOnce) {
        this.loadRisks();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    var promises = [this.getInitiatives(), this.getRiskProjects()];
    if (this.isERM) {
      promises.push(this.getCurrentScoring());
      promises.push(this.getRiskTypes());
    }
    Promise.all(promises).then(function () {
      return _this.showRismaTable ? _this.getUnformattedRisks() : _this.loadRisks();
    }).then(function () {
      _this.isLoaded = true;
      _this.setVisibleColumns();
    }).catch(function (error) {
      console.log('Error on mounted() load:', error); // eslint-disable-line
    });
  },
  methods: {
    getCurrentScoring: function getCurrentScoring() {
      var _this2 = this;
      return _getCurrentScoring().then(function (scoring) {
        _this2.scoring = scoring;
      });
    },
    getDataControls: function getDataControls() {
      var _this3 = this;
      return _getDataControls().then(function (_ref) {
        var list = _ref.list;
        _this3.controls = list;
      });
    },
    getRiskTypes: function getRiskTypes() {
      var _this4 = this;
      return _getRiskTypes().then(function (_ref2) {
        var list = _ref2.list;
        _this4.riskTypes = list;
      });
    },
    getRisksBE: function getRisksBE(params) {
      var fullParams = params ? params + '&' : '?';
      fullParams += 'filters=includeSubrisks';
      return getRisks(this.projectId, this.periodId, fullParams);
    },
    getUnformattedRisks: function getUnformattedRisks() {
      var _this5 = this;
      return getRisks(this.projectId, this.periodId, 'filters=includeSubrisks').then(function (_ref3) {
        var list = _ref3.list;
        _this5.unformattedRisks = list;
      });
    },
    loadRisks: function loadRisks() {
      var _this6 = this;
      this.isLoaded = false;
      var requests = [this.getDataControls()];
      if (!this.unformattedRisks.length) {
        requests.push(this.getUnformattedRisks());
      }
      return Promise.all(requests).then(function () {
        _this6.risks = _this6.formatRisks(_this6.unformattedRisks, _this6.threats, _this6.levelOfThreats, _this6.vulnerabilities, _this6.probabilities, _this6.controls, _this6.initiatives);
        _this6.risksBackup = _toConsumableArray(_this6.risks);
        _this6.initStatesFromCookie();
        _this6.applyOptions();
        _this6.isLoaded = true;
        _this6.risksLoadedOnce = true;
      });
    },
    initStatesFromCookie: function initStatesFromCookie() {
      this.options = _objectSpread(_objectSpread({}, this.options), JSON.parse(localStorage.getItem(this.localStorageName) || '{}'));
    },
    toggleViews: function toggleViews(toggleState) {
      var _this7 = this;
      this.output.forEach(function (section) {
        _this7.$refs[section.title][0].changeShowState(toggleState);
      });
    },
    toggleRiskCreationModal: function toggleRiskCreationModal() {
      this.showRiskCreationModal = !this.showRiskCreationModal;
    },
    applyOptions: function applyOptions() {
      var output = [];
      var risks = _toConsumableArray(this.risksBackup);
      risks = filterRisksByOrg(risks, this.options.filterByOrganisations);
      risks = filterRisksByParentRisk(risks, this.options.filterByParentRisk);
      risks = filterRisksByTags(risks, this.options.filterByTags);
      risks = searchByTitleAndId(risks, this.options.search);
      output = groupBy(risks, this.options.groupBy, {
        users: this.activeUsers,
        organisations: this.organisations,
        threats: this.threats,
        vulnerabilities: this.vulnerabilities
      }, this.$trans);
      output = sortBy(output, this.options.sortBy, {
        users: this.activeUsers
      });
      this.output = output;
    },
    resetOptions: function resetOptions() {
      this.options = Object.assign({}, OPTION_DEFAULT);
    },
    handleFilterByArray: function handleFilterByArray(entity, selectedEntity, labelValue) {
      var entitySimplify = selectedEntity.map(function (item) {
        return _defineProperty({
          id: item.id
        }, labelValue, item[labelValue]);
      });
      this.options[entity] = entitySimplify ? [].concat(entitySimplify) : [];
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedRisk, index) {
      var updatedRiskIndex = this.output[index].list.findIndex(function (a) {
        return a.id == updatedRisk.id;
      });
      if (updatedRiskIndex === -1) return;
      this.output[index].list[updatedRiskIndex] = _objectSpread(_objectSpread({}, this.output[index].list[updatedRiskIndex]), updatedRisk);
    },
    deleteRiskFromList: function deleteRiskFromList(risk) {
      for (var i = 0; i < this.output.length; i++) {
        var listLength = this.output[i].list.length;
        this.output[i].list = this.output[i].list.filter(function (riskItem) {
          return riskItem.id !== risk.id;
        });
        if (listLength > this.output[i].list.length) {
          break;
        }
      }
      this.output = _toConsumableArray(this.output);
    },
    addRiskToList: function addRiskToList(risk) {
      var formattedRisk = this.formatRisk(risk, this.threats, this.levelOfThreats, this.vulnerabilities, this.probabilities, this.controls, this.initiatives);
      this.risks.push(formattedRisk);
      this.risksBackup = _toConsumableArray(this.risks);
      this.applyOptions();
    },
    handleCreateRisk: function handleCreateRisk(risk) {
      this.toggleRiskCreationModal();
      var url = risk.url || "/risk2/projects/".concat(risk.projectId, "/periods/").concat(risk.projectPeriodId, "/risk/").concat(risk.rno, "/description");
      this.addRiskToList(risk);
      this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        activity: getFieldsFromObjectForPreview(_objectSpread(_objectSpread({}, risk), {}, {
          url: url
        }))
      });
    },
    setVisibleColumns: function setVisibleColumns() {
      var _this$hiddenColumnInd;
      var excludeKeys = [!this.riskProject.riskApproval && 'approvalStatus'].filter(Boolean);
      var cols = getRiskVisibleProps(this.getSettingValue, this.projectId, this.currentUser).filter(function (_ref5) {
        var key = _ref5.key;
        return !excludeKeys.includes(key);
      });
      var hiddenColumns = ((_this$hiddenColumnInd = this.hiddenColumnIndexes) === null || _this$hiddenColumnInd === void 0 ? void 0 : _this$hiddenColumnInd.split(',')) || [];
      this.visibleColumns = cols.map(function (col, index) {
        return {
          index: index,
          visible: !hiddenColumns.includes(index + ''),
          label: col.text,
          key: col.key
        };
      });
    },
    changeVisibleColumn: function changeVisibleColumn(col) {
      this.visibleColumns[col.index] = _objectSpread(_objectSpread({}, col), {}, {
        visible: !col.visible
      });
      this.hiddenColumnIndexes = this.visibleColumns.filter(function (col) {
        return !col.visible;
      }).map(function (col) {
        return col.index;
      }).join(',');
    },
    removeFilter: function removeFilter(key) {
      switch (key) {
        case 'search':
          this.options[key] = '';
          break;
        case 'filterByTags':
        case 'filterByOrganisations':
          this.options[key] = [];
          break;
        case 'filterByParentRisk':
          this.options[key] = null;
      }
    }
  }
};