// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-e1e91940]  .error .select-selected-value div {
    --tw-border-opacity: 1;
    border-color: rgb(168 0 0 / var(--tw-border-opacity))
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
