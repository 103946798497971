function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { getAllQuestionsIncludingHidden } from '@/api/compliance/questionnaire';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'DataflowQuestionsAdmin',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable
  },
  props: {
    questionnaire: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      isLoading: false,
      questions: [],
      allQuestionTypes: QuestionTypes(this.$trans),
      listOfSingleSelectSlots: ['dataTypes', 'legalBasis', 'storagePeriod'],
      columnsMaxWidth: {
        number: '100px',
        question: '28%',
        dataTypes: '20%',
        legalBasis: '20%',
        storagePeriod: '20%'
      },
      translate: getTranslate['DataflowQuestionsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    filteredQuestions: function filteredQuestions() {
      return this.questions.filter(function (question) {
        return question.dataFlowDataType;
      });
    },
    columns: function columns() {
      var list = [this.translate.number, this.translate.question, this.translate.dataTypes, this.translate.legalBasis, this.translate.storagePeriod];
      return list;
    },
    dataset: function dataset() {
      var _this = this;
      return this.filteredQuestions.map(function (question) {
        var legalBasisQuestion = question.legalBasisQuestionId ? _this.questions.find(function (item) {
          return item.id === question.legalBasisQuestionId;
        }) : null;
        var data = {
          number: question.questionnumber,
          question: question.question,
          dataTypes: {
            options: _this.getOptionsList(question)
          },
          legalBasis: {
            options: legalBasisQuestion ? _this.getOptionsList(legalBasisQuestion) : []
          }
        };
        var retentionQuestion = question.retentionQuestionId ? _this.questions.find(function (item) {
          return item.id === question.retentionQuestionId;
        }) : null;
        data.storagePeriod = {
          options: retentionQuestion ? _this.getOptionsList(retentionQuestion) : []
        };
        return data;
      });
    }
  }),
  mounted: function mounted() {
    this.getQuestions();
  },
  methods: {
    getQuestions: function getQuestions() {
      var _this2 = this;
      this.isLoading = true;
      return getAllQuestionsIncludingHidden(this.questionnaire.id).then(function (_ref) {
        var questions = _ref.questions;
        return _this2.questions = questions;
      }).catch(function () {
        return catch403(_this2.$router, 'QuestionnaireAdmin');
      }).finally(function () {
        return _this2.isLoading = false;
      });
    },
    getOptionsList: function getOptionsList(question) {
      return question.optionDetails.map(function (_ref2) {
        var label = _ref2.label,
          id = _ref2.id;
        return {
          label: label,
          value: id
        };
      });
    }
  }
};