import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3 flex items-center"
};
var _hoisted_2 = {
  class: "mb-3 flex items-center"
};
var _hoisted_3 = {
  class: "mb-3 flex items-center"
};
var _hoisted_4 = {
  class: "mb-3 flex items-center"
};
var _hoisted_5 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", null, [$options.hasIAModule ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.followingAssetsWillBeCreated,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.companyTitle), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.dataControllers,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.dataControllerTitle), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.dataProcessors,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.dataProcessorTitle), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.systems,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.systemsTitle), 1 /* TEXT */)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.customIAGroupedByActivityType, function (group, id) {
    return _openBlock(), _createElementBlock("div", {
      key: id,
      class: "mb-3 flex items-center"
    }, [_createVNode(_component_risma_title, {
      title: group.activityTypeTitle + ':',
      type: "medium",
      class: "mr-2"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(group.items.join(', ')), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.createCompany + ':',
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.companyTitle), 1 /* TEXT */)]))]);
}