var _OBJ_TYPE_TO_MODULE;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { MODULES } from '@/constants/modules';
export var ObjectTypes = {
  COMPLIANCE_NODE: 'compliance-node',
  COMPLIANCE_NODE_LIMITED: 'compliance-node-limited',
  EXECUTION_INITIATIVE: 'execution-initiative',
  EXECUTION_INITIATIVE_LIMITED: 'execution-initiative-limited',
  ACCOUNT: 'account',
  ACTIVITY_TYPE: 'activityType',
  ACTIVITY_TYPE_FIELD: 'activity-type-field',
  FILE: 'file',
  INCIDENTS_INCIDENT_LIMITED: 'incidents-incident-limited',
  INCIDENTS_INCIDENT: 'incidents-incident',
  INFORMATION_ASSET: 'information-asset',
  INFORMATION_ASSET_LIMITED: 'information-asset-limited',
  ORGANISATION: 'organisation',
  ORGANISATION_GROUP: 'organisation-group',
  PROCESS: 'process',
  TAG: 'tag',
  USER: 'user',
  AWARENESS_REQUEST: 'awareness-request',
  AWARENESS_REQUEST_LIMITED: 'awareness-request-limited',
  AWARENESS_REQUEST_USER: 'awareness-request-user',
  AWARENESS_REQUEST_USER_LIMITED: 'awareness-request-user-limited',
  COMM_INBOX: 'comm-inbox',
  COMPANY: 'company',
  COMPANY_CONTACTS: 'company_contacts',
  COMPLIANCE_GAP_ANALYSIS: 'compliance-gap-analysis',
  COMPLIANCE_GAP_ARTICLE_LIMITED: 'compliance-gap-article-limited',
  COMPLIANCE_GAP_ARTICLE: 'compliance-gap-article',
  COMPLIANCE_GAP_SCHEMA: 'compliance-gap-schema',
  COMPLIANCE_PROCESS_TREE_NODE_LIMITED: 'compliance-process-tree-node-limited',
  COMPLIANCE_PROCESS_TREE_NODE: 'compliance-process-tree-node',
  COMPLIANCE_PROJECT: 'compliance-project',
  COMPLIANCE_PROJECT_LIMITED: 'compliance-project-limited',
  COMPLIANCE_QUESTION: 'compliance-question',
  COMPLIANCE_QUESTION_LIMITED: 'compliance-question-limited',
  COMPLIANCE_QUESTION_ANSWER: 'compliance-question-answer',
  COMPLIANCE_QUESTIONNAIRE: 'compliance-questionnaire',
  COMPLIANCE_QUESTION_OPTION: 'compliance-question-option',
  USER_PROJECT: 'user-project',
  COMPLIANCE_INFORMATION_ASSET_CONTROLLER: 'compliance-informationAsset-controller',
  COMPLIANCE_INFORMATION_ASSET_CONTROLLER_LIMITED: 'compliance-informationAsset-controller-limited',
  COMPLIANCE_INFORMATION_ASSET_PROCESSOR_REQUEST: 'compliance-informationAsset-processor-request',
  COMPLIANCE_INFORMATION_ASSET_PROCESSOR: 'compliance-informationAsset-processor',
  COMPLIANCE_INFORMATION_ASSET_PROCESSOR_LIMITED: 'compliance-informationAsset-processor-limited',
  COMPLIANCE_INFORMATION_ASSET_SYSTEM: 'compliance-informationAsset-system',
  COMPLIANCE_INFORMATION_ASSET_SYSTEM_LIMITED: 'compliance-informationAsset-system-limited',
  CONTRACT: 'contract',
  CONTRACT_LIMITED: 'contract-limited',
  CONTROLS_CONTROL: 'controls-control',
  CONTROLS_CONTROL_LIMITED: 'controls-control-limited',
  CONTROLS_FILE: 'controls-file',
  CONTROLS_REVIEW: 'controls-review',
  CONTROL_RISK: 'control-risk',
  CONTROL_DATA_REPORT: 'controls-data-report',
  CONTROL_REPORT: 'controls-report',
  CONTROL_RISK_TYPE: 'control-risk-type',
  CUSTOM_FIELDS_FIELD: 'customFields-field',
  CUSTOM_FIELDS_FIELD_COMBINED: 'customFields-fieldCombined',
  CUSTOM_FIELDS_OPTION: 'customFields-option',
  CUSTOM_FIELDS_VALUE: 'customFields-value',
  EXTERNAL_JIRA_ISSUE: 'external-jira-issue',
  EXTERNAL_JIRA_ISSUE_TYPE: 'external-jira-issue-type',
  EXTERNAL_JIRA_PROJECT: 'external-jira-project',
  EXTERNAL_JIRA_USER: 'external-jira-user',
  RISK_MANAGEMENT_LEVEL: 'risk-management-level',
  RISK_PROBABILITY_PROJECT: 'risk-probability-project',
  RISK_PROJECT: 'risk-project',
  RISK_PROJECT_PERIOD: 'risk-project-period',
  RISK_RISK: 'risk-risk',
  RISK_RISK_LIMITED: 'risk-risk-limited',
  RISK_APPROVAL: 'risk-approval',
  RISK_SCORING: 'risk-scoring',
  RISK_THREAT: 'risk-threat',
  RISK_THREATLEVEL: 'risk-threatlevel',
  RISK_VULNERABILITY: 'risk-vulnerability',
  USERS_LOGIN_ATTEMPTS: 'users-login-attempts',
  USERS_SECURITYGROUPS_ORGANISATIONS: 'users-securitygroups-organisations',
  USERS_SECURITYGROUPS_PROJECTS: 'users-securitygroups-projects',
  USERS_LOG: 'users-log',
  HOLIDAY: 'holiday',
  FRAMEWORK: 'framework'
};
export var OBJ_TYPE_TO_MODULE = (_OBJ_TYPE_TO_MODULE = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_OBJ_TYPE_TO_MODULE, ObjectTypes.COMPLIANCE_NODE, MODULES.COMPLIANCE), ObjectTypes.COMPLIANCE_NODE_LIMITED, MODULES.COMPLIANCE), ObjectTypes.CONTROLS_CONTROL, MODULES.CONTROLS), ObjectTypes.CONTROLS_CONTROL_LIMITED, MODULES.CONTROLS), ObjectTypes.RISK_RISK, MODULES.RISK), ObjectTypes.RISK_RISK_LIMITED, MODULES.RISK), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, MODULES.COMPLIANCE_PROCESS_TREE_NODE), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED, MODULES.COMPLIANCE_PROCESS_TREE_NODE), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, MODULES.COMPLIANCE_IA_CONTROLLER), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, MODULES.COMPLIANCE_IA_PROCESSOR), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_OBJ_TYPE_TO_MODULE, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, MODULES.COMPLIANCE_IA_SYSTEM), ObjectTypes.INFORMATION_ASSET, MODULES.COMPLIANCE_INFORMATION_ASSETS), ObjectTypes.INFORMATION_ASSET_LIMITED, MODULES.COMPLIANCE_INFORMATION_ASSETS), ObjectTypes.EXECUTION_INITIATIVE, MODULES.EXECUTION), ObjectTypes.EXECUTION_INITIATIVE_LIMITED, MODULES.EXECUTION), ObjectTypes.INCIDENTS_INCIDENT, MODULES.INCIDENTS), ObjectTypes.INCIDENTS_INCIDENT_LIMITED, MODULES.INCIDENTS), ObjectTypes.CONTRACT, MODULES.CONTRACTS), ObjectTypes.CONTRACT_LIMITED, MODULES.CONTRACTS), ObjectTypes.COMPANY, MODULES.COMPANY), _defineProperty(_defineProperty(_OBJ_TYPE_TO_MODULE, ObjectTypes.COMPLIANCE_GAP_SCHEMA, MODULES.COMPLIANCE_GAP_SCHEMA), ObjectTypes.COMPLIANCE_QUESTIONNAIRE, MODULES.COMPLIANCE_QUESTIONNAIRE));
export var ObjectTypeToUserField = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.EXECUTION_INITIATIVE, 'level_execution'), ObjectTypes.INCIDENTS_INCIDENT, 'level_incidents'), ObjectTypes.RISK_RISK, 'level_risk'), ObjectTypes.CONTROLS_CONTROL, 'level_controls'), ObjectTypes.CONTRACT, 'level_contracts'), ObjectTypes.INFORMATION_ASSET, 'level_information_assets'), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, 'level_process_library'), ObjectTypes.COMPANY, 'level_company');
export function getModuleFromObjType(objType) {
  var module = OBJ_TYPE_TO_MODULE[objType];
  if (!module) {
    throw new Error("Cannot find module for ".concat(objType));
  }
  return module;
}
export var ObjectTypesToIAExport = _defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'controller'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'processor'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'system');
var ObjectTypesOfObjectsUsingCustomRaci = [ObjectTypes.INFORMATION_ASSET];
export function isObjectUsingCustomRaci(objType) {
  return ObjectTypesOfObjectsUsingCustomRaci.includes(objType);
}
export var objTypeToHumanReadable = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Data processor'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR_LIMITED, 'Data processor'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'Systems'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM_LIMITED, 'Systems'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Data controller'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER_LIMITED, 'Data controller'), ObjectTypes.INFORMATION_ASSET, 'Information asset'), ObjectTypes.INFORMATION_ASSET_LIMITED, 'Information asset');