import { DataFlowFields } from '@/constants/compliance/WizardFlow';
export default {
  computed: {
    questionsById: function questionsById() {
      return Object.fromEntries(this.data.questions.map(function (question) {
        return [question.id, question];
      }));
    },
    typesByQuestion: function typesByQuestion() {
      var _this = this;
      return this.selectedOptions[DataFlowFields.CURRENT_TYPES_SELECTED].reduce(function (acc, cur) {
        var question = _this.questionsById[cur.questionId];
        var id = question.questionobject[_this.currentTypeField];
        if (!id) return acc;
        var questionNumber = question.questionnumber;
        acc[questionNumber] ? acc[questionNumber].dataTypes.push(cur) : acc[questionNumber] = {
          dataTypes: [cur],
          options: _this.getOptions(id)
        };
        return acc;
      }, {});
    }
  },
  methods: {
    getOptions: function getOptions(id) {
      var _this$questionsById$i;
      var options = (_this$questionsById$i = this.questionsById[id]) === null || _this$questionsById$i === void 0 ? void 0 : _this$questionsById$i.optionDetails;
      if (!options) return [];
      return options.map(function (option) {
        return {
          value: option.id,
          label: option.label,
          questionId: id
        };
      });
    },
    getSelectedOption: function getSelectedOption(options, id) {
      var _options$find = options.find(function (o) {
          return +o.value === +id;
        }),
        label = _options$find.label,
        value = _options$find.value,
        questionId = _options$find.questionId;
      return {
        id: value,
        text: label,
        questionId: questionId
      };
    },
    selectOption: function selectOption(options, id, uniqueId) {
      this.$emit('update:selected', {
        value: id && this.getSelectedOption(options, id),
        field: this.fieldSelected,
        additionalField: uniqueId
      });
    },
    selectAll: function selectAll(options, dataTypes, uniqueId) {
      var _this2 = this;
      var selectedOption = this.selectedOptions[this.fieldSelected][uniqueId];
      if (!selectedOption) return;
      var value = this.getSelectedOption(options, selectedOption);
      dataTypes.forEach(function (type) {
        _this2.$emit('update:selected', {
          value: value,
          field: _this2.fieldSelected,
          additionalField: type.uniqueId
        });
      });
    }
  }
};