function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useUserStore } from '@/Store/userStore';
import { MypageKeys } from '@/constants/mypage/MypageKeys';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { UserLevels } from '@/constants/UserLevels';
import { getReportTypeByURL, typeToAccessProperty } from '@/utils/report';
import { getInformationAssetsTitle } from '@/utils/InformationAssets';
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { isBelowSuperUser } from '@/utils/access';
export var MypageGlobalSettings = function MypageGlobalSettings(trans) {
  return [{
    id: 1,
    label: trans('Reorder sections'),
    visible: false,
    type: 'dragAndDrop'
  }, {
    id: 2,
    label: trans('Content visibility'),
    visible: true,
    type: 'contentVisibility'
  }, {
    id: 3,
    label: trans('Sorting'),
    visible: true,
    type: 'isSortingVisible'
  }];
};
export var MypageSettings = function MypageSettings(trans) {
  var _ref;
  var processLibraryTitle = getProcessLibraryTitle();
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, MypageKeys.SHARED_REPORTS, {
    label: trans('Shared reports'),
    visible: true,
    disablePreview: true,
    internalLinkEnabled: true,
    accessToRemove: function accessToRemove(user, activity) {
      if (user.is_admin) {
        return true;
      }
      if (activity.riskProjectsId) {
        return activity.userId === user.id && useUserStore().riskAccessLevelByProjectId(activity.riskProjectsId) >= UserLevels.SUPER;
      }
      return activity.userId === user.id && !isBelowSuperUser(user, typeToAccessProperty(activity.objType));
    },
    watermarkImage: function watermarkImage(activity) {
      var reportType = getReportTypeByURL(activity.reportUrl);
      return getWatermarkImageByType(reportType);
    }
  }), MypageKeys.MY_REPORTS, {
    label: trans('My reports'),
    visible: true,
    disablePreview: true,
    internalLinkEnabled: true,
    accessToRemove: true,
    watermarkImage: function watermarkImage(activity) {
      var reportType = getReportTypeByURL(activity.reportUrl);
      return getWatermarkImageByType(reportType);
    }
  }), MypageKeys.MY_RISKS, {
    activities: [],
    data: [],
    label: trans('My risks'),
    visible: true,
    watermarkImage: 'url("/assets/svg/ikon_risk.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 2,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 3,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 4,
        label: trans('Status'),
        value: SortFieldsCompatibility.STATUS
      }, {
        id: 5,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }, {
        id: 6,
        label: trans('Approval status'),
        value: SortFieldsCompatibility.APPROVED
      }, {
        id: 7,
        label: trans('Likelihood'),
        value: SortFieldsCompatibility.LIKE_HOOD_SCORE
      }, {
        id: 8,
        label: trans('Risk number'),
        value: SortFieldsCompatibility.RNO
      }]
    }
  }), MypageKeys.MY_INITIATIVES, {
    activities: [],
    data: [],
    label: trans('My actions'),
    visible: true,
    watermarkImage: 'url("/assets/svg/ikon_execution.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 3,
        label: trans('Deadline'),
        value: SortFieldsCompatibility.DEADLINE
      }, {
        id: 4,
        label: trans('Start'),
        value: SortFieldsCompatibility.START
      }, {
        id: 5,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 6,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 7,
        label: trans('Present status'),
        value: SortFieldsCompatibility.STATUS
      }, {
        id: 8,
        label: trans('Target'),
        value: SortFieldsCompatibility.TARGET
      }, {
        id: 9,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }]
    }
  }), MypageKeys.MY_PROCESS, {
    label: trans('My processes'),
    visible: true,
    watermarkImage: 'url("/assets/svg/icon_compliance.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 2,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 3,
        label: trans('Project'),
        value: SortFieldsCompatibility.PROJECT_TITLE
      }, {
        id: 4,
        label: trans('Answered questions'),
        value: SortFieldsCompatibility.QUEST_ANSWERS
      }, {
        id: 9,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }, {
        id: 10,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }]
    }
  }), MypageKeys.UNANSWERED_AWARENESS, {
    label: trans('My unanswered awareness'),
    visible: true,
    disablePreview: true,
    watermarkImage: 'url("/assets/svg/icon_compliance.svg")',
    internalLinkEnabled: true,
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 2,
        label: trans('Message'),
        value: SortFieldsCompatibility.MESSAGE
      }, {
        id: 3,
        label: trans('Date sent'),
        value: SortFieldsCompatibility.CREATED
      }]
    }
  }), MypageKeys.PROCESS_LIBRARY, {
    label: processLibraryTitle,
    visible: true,
    watermarkImage: 'url("/assets/svg/icon_compliance.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 2,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 3,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 5,
        label: trans('Accountable'),
        value: SortFieldsCompatibility.ACCOUNTABLE_USER_IDS
      }, {
        id: 6,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }]
    }
  }), MypageKeys.MY_INCIDENTS, {
    activities: [],
    data: [],
    label: trans('My incidents'),
    visible: true,
    watermarkImage: 'url("/assets/svg/ikon_incident.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE
      }, {
        id: 2,
        label: trans('Created'),
        value: SortFieldsCompatibility.CREATED_AT
      }, {
        id: 3,
        label: trans('Incident occurred'),
        value: SortFieldsCompatibility.INCID_HAPPEND_AT
      }, {
        id: 4,
        label: trans('Severity'),
        value: SortFieldsCompatibility.SERVITY
      }, {
        id: 6,
        label: trans('Accountable'),
        value: SortFieldsCompatibility.ACCOUNTABLE_USER_IDS
      }, {
        id: 7,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 8,
        label: trans('Workflow status'),
        value: SortFieldsCompatibility.WORKFLOW_STATUS
      }, {
        id: 9,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }, {
        id: 11,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }]
    }
  }), MypageKeys.INFORMATION_ASSETS, {
    label: getInformationAssetsTitle(),
    visible: true,
    watermarkImage: 'url("/assets/svg/icon_compliance.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE_IA
      }, {
        id: 2,
        label: trans('Type'),
        value: SortFieldsCompatibility.OBJ_TYPE_IA
      }, {
        id: 3,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }, {
        id: 4,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 5,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }]
    }
  }), MypageKeys.MY_CONTROLS, {
    activities: [],
    data: [],
    label: trans('My controls'),
    visible: true,
    watermarkImage: 'url("/assets/svg/ikon_controls.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE_IA
      }, {
        id: 2,
        label: trans('Deadline'),
        value: SortFieldsCompatibility.REVIEW_DEADLINE
      }, {
        id: 3,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 4,
        label: trans('Control number'),
        value: SortFieldsCompatibility.CPATH
      }, {
        id: 5,
        label: trans('Key control'),
        value: ControlTypeFields.KEY_CONTROL
      }, {
        id: 6,
        label: trans('Manual'),
        value: ControlTypeFields.KEY_AUTOMATIC_MANUAL
      }, {
        id: 7,
        label: trans('Preventive'),
        value: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE
      }, {
        id: 9,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 10,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }]
    }
  }), _defineProperty(_ref, MypageKeys.MY_CONTRACTS, {
    activities: [],
    data: [],
    label: trans('My contracts'),
    visible: true,
    watermarkImage: 'url("/assets/svg/ikon_contracts.svg")',
    sorting: {
      sortBy: '',
      sortOptions: [{
        id: 1,
        label: trans('Title'),
        value: SortFieldsCompatibility.TITLE_IA
      }, {
        id: 2,
        label: trans('Expiration date'),
        value: SortFieldsCompatibility.EXPIRATION_DATE
      }, {
        id: 3,
        label: trans('Tags'),
        value: SortFieldsCompatibility.TAGS
      }, {
        id: 4,
        label: trans('Organisations'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 5,
        label: trans('Description'),
        value: SortFieldsCompatibility.DESCRIPTION
      }, {
        id: 6,
        label: trans('Workflow status'),
        value: SortFieldsCompatibility.WORKFLOW_STATUS
      }]
    }
  });
};
export function getWatermarkImageByType(type) {
  switch (type) {
    case 'controls':
      return 'url("/assets/svg/ikon_controls.svg")';
    case 'risks':
      return 'url("/assets/svg/ikon_risk.svg")';
    case 'actions':
      return 'url("/assets/svg/ikon_execution.svg")';
    case 'contracts':
      return 'url("/assets/svg/ikon_contracts.svg")';
    case 'informationAssets':
    case 'compliance':
      return 'url("/assets/svg/icon_compliance.svg")';
    case 'incidents':
      return 'url("/assets/svg/ikon_incident.svg")';
    case 'processlibrary':
      return 'url("/assets/svg/ikon_proceslibrary.svg")';
    default:
      return null;
  }
}