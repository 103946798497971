export default {
  name: 'BadgeItem',
  introduction: 'box for displaying badges',
  description: 'This component is used to convey small pieces of information',
  token: "<badge-item title=\"this is a test tag\" />",
  props: {
    title: {
      type: String,
      required: true,
      note: 'the displayed text on the badgeItem'
    }
  }
};