import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "pl-3"
};
var _hoisted_2 = {
  key: 0,
  class: "consequence-table mb-2"
};
var _hoisted_3 = {
  key: 0,
  class: "thead-cell"
};
var _hoisted_4 = {
  class: "relative tbody-cell"
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  key: 1,
  class: "flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      readonly: $props.readonly
    }, "consequence-wrap"])
  }, [_createElementVNode("div", _hoisted_1, [$data.items.length ? (_openBlock(), _createElementBlock("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_cache[2] || (_cache[2] = _createElementVNode("th", null, " ", -1 /* HOISTED */)), $props.readonly ? (_openBlock(), _createElementBlock("th", _hoisted_3, [_createVNode(_component_risma_title, {
    title: "0",
    type: "medium"
  })])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columns, function (field, key) {
    return _openBlock(), _createElementBlock("th", {
      key: key,
      class: "thead-cell relative"
    }, [_createVNode(_component_risma_title, {
      title: field + '',
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), $options.isDeletableColumn(field) && !$props.readonly ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "text-red-50 stroke-2 cursor-pointer absolute inset-0 left-1/2 -ml-3 -top-6",
      icon: "x",
      onClick: function onClick($event) {
        return $options.removeColumn(field);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, function (item, key) {
    return _openBlock(), _createElementBlock("tr", {
      key: key
    }, [_createElementVNode("td", _hoisted_4, [!$props.readonly ? (_openBlock(), _createBlock(_component_input_field, {
      key: 0,
      modelValue: item.label,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return item.label = $event;
      },
      type: "text",
      onUpdated: $options.emitState
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onUpdated"])) : _createCommentVNode("v-if", true), $props.readonly ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true), item.deletable && !$props.readonly ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 2,
      class: "text-red-50 stroke-2 cursor-pointer absolute top-0.7rem -left-6",
      icon: "x",
      onClick: function onClick($event) {
        return $options.removeOption(item);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, function (child, childIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: childIndex,
        class: "tbody-cell"
      }, [!$props.readonly ? (_openBlock(), _createBlock(_component_input_field, {
        key: 0,
        modelValue: child.value,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return child.value = $event;
        },
        type: "text",
        onUpdated: $options.emitState
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onUpdated"])) : _createCommentVNode("v-if", true), $props.readonly ? (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass([{
          active: child.isActive,
          clickable: $props.clickable
        }, "readonly-cell-value"]),
        onClick: function onClick($event) {
          return $options.onLabelClick(item.children, child);
        }
      }, _toDisplayString(child.value), 11 /* TEXT, CLASS, PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), !$props.readonly ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", null, [!$data.maxRowsReached ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: "#",
    class: "flex",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.addOption && $options.addOption.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    class: "text-green-50 stroke-2",
    icon: "plus"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addRow), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [!$options.limitReached ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: "#",
    class: "flex",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return $options.addNewColumn && $options.addNewColumn.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    class: "text-green-50 stroke-2",
    icon: "plus"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addColumn), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */);
}