// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tinymce-box-content[data-v-02683c54] {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  overflow-wrap: anywhere;
}
.tinymce-box-content.open[data-v-02683c54] {
  width: 100%;
}
.tinymce-box-content[data-v-02683c54]  table tr,
.tinymce-box-content[data-v-02683c54]  table td {
  border: 1px solid #000;
}
.tinymce-box-content[data-v-02683c54]  ol,
.tinymce-box-content[data-v-02683c54]  ul {
  padding-left: 4rem;
}
.tinymce-box-content[data-v-02683c54]  ol li,
.tinymce-box-content[data-v-02683c54]  ul li {
  padding-left: 0.3em;
}
.tinymce-box-content[data-v-02683c54]  ol {
  list-style-type: decimal;
}
.tinymce-box-content[data-v-02683c54]  ul {
  list-style-type: disc;
}
.tinymce-box-content[data-v-02683c54]  ul ul {
  list-style-type: circle;
}
.tinymce-box-content[data-v-02683c54]  h1,
.tinymce-box-content[data-v-02683c54]  h2,
.tinymce-box-content[data-v-02683c54]  h3,
.tinymce-box-content[data-v-02683c54]  h4,
.tinymce-box-content[data-v-02683c54]  h5,
.tinymce-box-content[data-v-02683c54]  h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.tinymce-box-content[data-v-02683c54]  h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
.tinymce-box-content[data-v-02683c54]  h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.tinymce-box-content[data-v-02683c54]  h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.tinymce-box-content[data-v-02683c54]  h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.tinymce-box-content[data-v-02683c54]  h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.tinymce-box-content[data-v-02683c54]  h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
.tinymce-box-content[data-v-02683c54]  a {
  text-decoration: underline;
  cursor: pointer;
}
.tinymce-box-content[data-v-02683c54]  blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
