import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "position": "relative"
  }
};
var _hoisted_2 = {
  class: "tooltip-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tool_tip = _resolveComponent("tool-tip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_tool_tip, null, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("isn\\'t testing wonderful?!")]);
    }),
    _: 1 /* STABLE */
  })])]);
}