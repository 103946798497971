function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getContacts as _getContacts, addContact as _addContact, editContact as _editContact, deleteContact as _deleteContact } from '@/api/admin/companies';
import CompanyContactModal from '@/components/Organisms/Companies/CompanyContactModal';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'CompanyContacts',
  components: {
    CompanyContactModal: CompanyContactModal,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged', 'validationError', 'editContact'],
  data: function data() {
    return {
      isLoaded: false,
      contacts: [],
      showContactModal: false,
      showDeleteContactAlert: false,
      contactToEdit: {},
      contactToDelete: {},
      columnsMaxWidth: {
        delete: '30px'
      },
      translate: getTranslate['CompanyContacts']()
    };
  },
  computed: {
    columns: function columns() {
      var list = ['#', this.translate.name, this.translate.title, this.translate.email, this.translate.phone];
      if (!this.readOnly) {
        list.push('');
      }
      return list;
    },
    dataset: function dataset() {
      var _this = this;
      return this.contacts.map(function (item) {
        var data = {
          id: item.id,
          name: {
            name: item.name,
            id: item.id
          },
          title: item.title,
          email: item.email,
          phone: item.phone
        };
        if (!_this.readOnly) {
          data.delete = {
            id: item.id
          };
        }
        return data;
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getContacts().finally(function () {
      return _this2.isLoaded = true;
    });
  },
  methods: {
    getContacts: function getContacts() {
      var _this3 = this;
      return _getContacts(this.data.id).then(function (_ref) {
        var list = _ref.list;
        return _this3.contacts = list;
      }).catch(function (error) {
        _this3.$emit('validationError', 'error', error.fullError.message);
      });
    },
    addContact: function addContact(contactData) {
      var _this4 = this;
      _addContact(this.data.id, contactData).then(function (response) {
        _this4.contacts.push(response);
        _this4.$emit('editContact', response);
        _this4.toggleShowContactModal();
      });
    },
    editContact: function editContact(contactData) {
      var _this5 = this;
      _editContact(contactData).then(function (contact) {
        var contactIndex = _this5.contacts.findIndex(function (c) {
          return c.id == contact.id;
        });
        if (contactIndex !== -1) {
          _this5.contacts[contactIndex] = contact;
        }
        _this5.$emit('editContact', contact);
        _this5.toggleShowContactModal();
      });
    },
    deleteContact: function deleteContact() {
      var _this6 = this;
      _deleteContact(this.contactToDelete.id).then(function (response) {
        _this6.contacts = _this6.contacts.filter(function (contact) {
          return contact.id !== response.id;
        });
      }).catch(function () {
        _this6.$emit('validationError', 'error', 'Contact person can’t be deleted due to relation to existing contract');
      });
    },
    handleAddContact: function handleAddContact() {
      this.contactToEdit = {};
      this.toggleShowContactModal();
    },
    handleEditContact: function handleEditContact(id) {
      if (this.readOnly) return;
      var contact = this.contacts.find(function (contact) {
        return contact.id === id;
      });
      this.contactToEdit = _objectSpread({}, contact);
      this.showContactModal = true;
    },
    handleDeleteContact: function handleDeleteContact(id) {
      var _this7 = this;
      this.contactToDelete = this.contacts.find(function (contact) {
        return contact.id === id;
      });
      this.$confirm(this.translate.areYouSure, "".concat(this.translate.deleteContact, " ").concat(this.contactToDelete.name, "?"), function (res) {
        return res && _this7.deleteContact();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    handleSaveContact: function handleSaveContact(contactData) {
      if (!contactData.id) {
        this.addContact(contactData);
        return;
      }
      this.editContact(contactData);
    },
    toggleShowContactModal: function toggleShowContactModal() {
      this.showContactModal = !this.showContactModal;
    }
  }
};