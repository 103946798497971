// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-button[data-v-1c892dcc]  button {
    margin: 0px;
    width: 100%;
    justify-content: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
