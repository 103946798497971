function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "hidden"
};
var _hoisted_2 = {
  class: "whitespace-pre-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "risk-project-id": $props.projectId,
    "report-options": $props.reportOptions,
    onLoad: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.loadReportByReplacingQueryParams($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "risk-project-id", "report-options"]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": $data.pageLength,
    "filter-by-column": true,
    "disabled-filter-by-columns": $options.disabledFilterByColumns,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "column-filters-sorting": $data.columnFiltersSorting,
    "column-sorting": $data.columnSorting,
    "columns-meta": $data.columnsMeta,
    "columns-by-default": $data.columns,
    order: $data.orderDefault,
    "wrapper-height-sticky": 500,
    "state-local": $data.stateLocal,
    "additional-filter-options": $data.additionalFilterOptions,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onDismissModal: $options.onDismissModal
  }, _createSlots(_defineProperty(_defineProperty({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_preview_modal_link, {
        activity: item.activity,
        class: "hover:underline",
        onUpdated: $options.onEntityUpdated,
        onDismissModal: $options.onDismissModal
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            icon: "confidential",
            truncate: false,
            title: item.field,
            "title-attribute": item.hoverTitle,
            "display-icon": item.confidential
          }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"])];
    })
  }, $data.RiskBaseModelProps.THREAT_IDS, _withCtx(function (_ref2) {
    var item = _ref2.item;
    return [_createElementVNode("span", _hoisted_2, _toDisplayString((item === null || item === void 0 ? void 0 : item.field) || ''), 1 /* TEXT */)];
  })), "_", 2), [_renderList($data.linkedColumnsForPreview, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(linkCol, "_").concat(item.id)
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activityObj, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(activityObj.fieldText, "_").concat(index),
            class: "hover:underline",
            activity: activityObj.activity,
            onUpdated: $options.onEntityUpdated,
            onDismissModal: $options.onDismissModal
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                type: "small",
                truncate: false,
                title: activityObj.fieldText,
                "title-attribute": $options.getItemHoverTitle(activityObj.activity),
                "display-icon": activityObj.activity.confidential
              }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"]);
        }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", {
          key: linkCol
        }))];
      })
    };
  }), _renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref4) {
        var _item$field;
        var item = _ref4.item;
        return [item !== null && item !== void 0 && (_item$field = item.field) !== null && _item$field !== void 0 && _item$field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_traffic_light, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_1, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol, "_span")
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "page-length", "disabled-filter-by-columns", "decouple-filter-fields", "column-filters-sorting", "column-sorting", "columns-meta", "columns-by-default", "order", "state-local", "additional-filter-options", "onPageChange", "onOnStateChanged", "onDismissModal"])]);
}