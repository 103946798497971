import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
export default {
  name: '',
  components: {
    Checkboxes: Checkboxes
  },
  data: function data() {
    return {
      options: [{
        value: 1,
        label: 'Yes'
      }, {
        value: 2,
        label: 'No'
      }, {
        value: 3,
        label: 'Maybe yes, maybe no'
      }],
      disabledOptions: [{
        value: 1,
        label: 'Yes',
        disabled: true
      }, {
        value: 2,
        label: 'No',
        disabled: true
      }, {
        value: 3,
        label: 'Maybe yes, maybe no',
        disabled: true
      }],
      values: [2]
    };
  },
  methods: {
    handleChange: function handleChange(value) {
      console.log('handleChange of checkbox value', value); // eslint-disable-line
    }
  }
};