import { getFieldsFromObjectForPreview } from '@/utils/preview';
var handleText = function handleText(parent, children) {
  var str = '';
  if (children) {
    children.forEach(function (item) {
      return str += (item.title || item.name) + ', ';
    });
  }
  str += parent.title || parent.name;
  return str;
};
var _buildPath = function buildPath(node) {
  var parents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var parent = node.parent || node.parents && node.parents[0];
  if (parent) {
    parents.unshift(parent);
    return _buildPath(parent, parents);
  }
  return parents;
};
export { _buildPath as buildPath };
export var getHierarchicalList = function getHierarchicalList(node) {
  return handleText(node, _buildPath(node));
};
export function buildHierarchicalStructure(node) {
  node.id = node.id || node.nodeId;
  var structure = getFieldsFromObjectForPreview(node);
  var path = _buildPath(node);
  path.push(getFieldsFromObjectForPreview(node));
  if (path.length !== 0) {
    if (path.length == 1) {
      structure = path[0];
    } else {
      for (var i = path.length - 1; i >= 1; i--) {
        path[i - 1] = getFieldsFromObjectForPreview(path[i - 1]);
        path[i - 1].children = [path[i]];
      }
      structure = path[0];
    }
  }
  return structure;
}