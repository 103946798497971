import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "risma-tree-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_tree_branch = _resolveComponent("risma-tree-branch");
  var _component_vue_nestable = _resolveComponent("vue-nestable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_vue_nestable, {
    modelValue: $data.localValue,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.localValue = $event;
    }), $options.handleEsc],
    class: _normalizeClass(["tmp", {
      'invisible-layout': $data.removeDrag
    }]),
    hooks: {
      beforeMove: $options.checkMove
    },
    onChange: $options.onChangeData
  }, {
    default: _withCtx(function (_ref) {
      var item = _ref.item,
        isChild = _ref.isChild;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent($props.lockTree ? 'div' : 'vue-nestable-handle'), {
        item: item,
        "max-depth": 0
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_tree_branch, {
            "model-value": item,
            "is-child": isChild,
            "disable-item-menu": $props.disableItemMenu,
            "enable-add-button": $props.enableAddButton,
            onDragStart: $options.onMove
          }, null, 8 /* PROPS */, ["model-value", "is-child", "disable-item-menu", "enable-add-button", "onDragStart"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["item"]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "class", "hooks", "onChange", "onUpdate:modelValue"])]);
}