import { ObjectTypes } from '@/constants/ObjectTypes';
import { buildNavBar } from '@/menus/navbar';
import { sortAlphabetic } from '@/utils/sort';
export function projectsByUser(user, getSettings) {
  if (!user) return [];
  var list = user.projects;
  if (getSettings('app.module_risks2_enabled') !== 1) {
    list = list.filter(function (project) {
      return project.objType !== ObjectTypes.RISK_PROJECT;
    });
  }
  if (getSettings('app.module_compliance_enabled') !== 1) {
    list = list.filter(function (project) {
      return project.objType !== ObjectTypes.COMPLIANCE_PROJECT;
    });
  }
  return list;
}
export function getAllModules(settings) {
  var structure = buildNavBar(settings, null);
  var list = [structure.actions, structure.companies, structure.contracts, structure.controls, structure.policies, structure.incident, structure.assets, structure.risk].filter(function (module) {
    return module.rights;
  });
  return sortAlphabetic(list, 'title');
}