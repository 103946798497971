import i18n from '@/i18n';
export var getTranslate = {
  DataFlow: function DataFlow() {
    return {
      addANewDataFlow: i18n.t('Add a new data flow'),
      youHaveNoDataFlowsInThisProcess: i18n.t('You have no data flows in this process'),
      dataTypes: i18n.t('Data types'),
      thisInformationHasNotYetBeenAddedToAnyDat: i18n.t('This information has not yet been added to any data flows'),
      notApplicable: i18n.t('Not applicable'),
      enterNewHeadlineForSystems: i18n.t('Enter new headline for systems'),
      clickTheQuotAndQuot: i18n.t('Click the "+" in the upper left corner to add a new data flow'),
      dataFlowCreated: i18n.t('Data flow created'),
      dataFlowDeleted: i18n.t('Data flow deleted'),
      somethingWentWrong: i18n.t('Something went wrong!')
    };
  },
  DescriptionPage: function DescriptionPage() {
    return {
      anErrorOccurred: i18n.t('An error occurred'),
      tryAgain: i18n.t('Try again'),
      deleteNode: i18n.t('Delete node'),
      recoveryTimeObjective: i18n.t('Recovery Time Objective'),
      selectRto: i18n.t('Select RTO'),
      recoveryPointObjective: i18n.t('Recovery Point Objective'),
      selectRpo: i18n.t('Select RPO'),
      gapAnalysis: i18n.t('GAP analysis'),
      gapNumber: i18n.t('Gap number'),
      gapsSet: i18n.t('Gaps set'),
      gapSetAsNa: i18n.t('Gaps set as N/A'),
      name: i18n.t('Name'),
      noOfQuestionsAnswered: i18n.t('No. of questions answered'),
      lastActive: i18n.t('Last active'),
      answered: i18n.t('Answered'),
      notAnswered: i18n.t('Not answered'),
      na: i18n.t('N/A'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      andUnderlyingProcesses: i18n.t('and underlying processes'),
      andUnderlyingNodesPleaseNoteThatLinksToAn: i18n.t('and underlying nodes? Please note that links to and from this node will also be deleted. As well as any risk assessments.'),
      saveFailed: i18n.t('Save failed'),
      saveFailedYouMustBeResponsibleOrAccountabl: i18n.t('Save failed. You must be responsible or accountable to change a node.'),
      delete: i18n.t('Delete')
    };
  },
  GapPage: function GapPage() {
    return {
      anErrorOccurred: i18n.t('An error occurred'),
      thereWasAnErrorSavingGapNumber: i18n.t('There was an error saving gap number'),
      tryAgain: i18n.t('Try again'),
      cancel: i18n.t('Cancel'),
      relevantQuestions: i18n.t('Relevant questions'),
      allQuestions: i18n.t('All questions'),
      heatmaps: i18n.t('Heatmaps')
    };
  },
  NodePage: function NodePage() {
    return {
      cancel: i18n.t('Cancel'),
      thisActivityIsReadonlyAsYourUserIsConfigu: i18n.t('This activity is read-only as your user is configured to only have read-only access'),
      activityType: i18n.t('Activity type'),
      thisWillChangeActivityTypeAndPossiblyRemove: i18n.t('This will change activity type and possibly remove risk assessment, questionnaire or gap data. Are you sure?'),
      gapSchemaVersion: i18n.t('GAP schema version'),
      selectOne: i18n.t('Select one'),
      youHaveAlreadyAnsweredGapQuestionsOnThisNo: i18n.t('You have already answered gap questions on this node. Are you sure you want to delete the answers and change the gap schema?'),
      areYouSureYouWantToChangeTheGapSchema: i18n.t('Are you sure you want to change the gap schema?'),
      updateGapSchemaMessage: i18n.t('You have enabled a new questionnaire, it has a gap schema connected to it. Would you like to change the gap schema as well? NB: By replacing the gap schema, the existing one will be removed with all its data.'),
      couldNotDeleteNode: i18n.t('Could not delete node'),
      dataControllers: i18n.t('Data Controllers'),
      yourUserAccessLevelDoesntHavePermissionToEnter: i18n.t('Your user access level doesn’t have permission to enter the gap analysis'),
      sorryYouDontHaveAccess: i18n.t("Sorry, you don't have access to this page")
    };
  },
  QuestionnaireAssessmentPage: function QuestionnaireAssessmentPage() {
    return {
      answeredQuestions: i18n.t('Answered questions'),
      sortByScore: i18n.t('Sort by score'),
      answer: i18n.t('Answer'),
      assessment: i18n.t('Assessment'),
      theAnswersStatedInTheQuestionnaireHasSumUp: i18n.t('The answers stated in the questionnaire has sum up to a score of'),
      addLinks: i18n.t('Add links')
    };
  }
};