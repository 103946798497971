function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getContract as _getContract, editContract, deleteContract as _deleteContract } from '@/api/contracts/contracts';
import { getFiles, getFileById } from '@/api/risma/file';
import { getDocumentTypesAll } from '@/api/admin/documentTypes';
import { getQuestionnaireAssessments as _getQuestionnaireAssessments, getQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import Notification from '@/components/Molecules/Notification';
import RequiredFieldsNotification from '@/components/Molecules/RequiredFieldsNotification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import MultipleQuestionnaireSelector from '@/components/Molecules/QuestionnaireSelector/MultipleQuestionnaireSelector';
import { MODULES } from '@/constants/modules';
import { saveStatus } from '@/constants/SaveStatuses';
import { TabTypes, Tabs } from '@/constants/Tabs';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { AuditGenericData } from '@/constants/AuditGenericData';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { getParsedCfValues, getMissingRequiredFieldsLabels } from '@/utils/CustomFields';
import { isLockedContractUser } from '@/utils/access';
import { catch403 } from '@/utils/handleAPIErrors';
import { getCurrentYear, getMonthIndex, getYear } from '@/utils/date';
import { DelegationWellMixin } from '@/mixins/DelegationWellMixin';
export default {
  name: 'ContractEntry',
  description: 'Common page for show contract',
  token: '<contract-entry />',
  components: {
    DepWell: DepWell,
    DelegationWell: DelegationWell,
    Notification: Notification,
    RequiredFieldsNotification: RequiredFieldsNotification,
    ActivityTypeSelector: ActivityTypeSelector,
    RismaTitle: RismaTitle,
    MultipleQuestionnaireSelector: MultipleQuestionnaireSelector
  },
  mixins: [DelegationWellMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['updated', 'deleteItem', 'accessError', 'changeTab'],
  data: function data() {
    return {
      updateIdx: 0,
      isLoaded: false,
      contract: {},
      currentYear: getCurrentYear(),
      saveStatus: saveStatus.SAVED,
      updateTimer: 0,
      files: [],
      questionnaireType: QuestionnaireTypes.CONTRACT,
      auditQuestionnaires: [],
      questionnaires: [],
      auditRequests: [],
      auditAssessments: [],
      isDirty: false,
      isPageWithCustomUpdateDirty: false,
      documentTypes: [],
      notificationMessage: '',
      translate: getTranslate['ContractEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser', 'userHasCompaniesAccess'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    activityTypes: 'contractActivityTypes'
  })), {}, {
    enhancementContract: function enhancementContract() {
      return _objectSpread(_objectSpread({}, this.contract), {}, {
        files: this.files,
        auditQuestionnaires: this.auditQuestionnaires,
        auditRequests: this.auditRequests,
        auditAssessments: this.auditAssessments
      });
    },
    isPaymentsTabEnabled: function isPaymentsTabEnabled() {
      return !!this.getSettingValue('feature.contracts_finance_tab_enabled');
    },
    genericData: function genericData() {
      if (!this.contract || !this.contract.objType) return;
      return AuditGenericData[this.contract.objType];
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.contract.uniqueId || '',
        title: this.contract.title,
        editableId: !this.isReadOnly,
        editableTitle: !this.isReadOnly,
        filesList: this.files,
        trafficLightSettings: this.trafficLightSettings,
        moduleId: this.contract.id,
        module: MODULES.CONTRACTS,
        titleField: 'title',
        users: this.users,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        showCheckmark: false,
        idField: 'uniqueId',
        hasVersionButtons: true,
        singleFileLoad: true,
        attachmentHasPin: true,
        attachmentHasTitle: true,
        attachmentsFilesAndLinksSeparated: false,
        attachmentFileTypesEnabled: true,
        attachmentEditFileEnabled: false,
        fileTypeOptions: this.typeOptions,
        editableAttachments: !this.isReadOnly
      };
    },
    trafficLightSettings: function trafficLightSettings() {
      return {
        value: this.contract.trafficLight,
        lightType: 'trafficLights',
        field: 'trafficLight',
        readOnly: true
      };
    },
    tabs: function tabs() {
      var _this$contract;
      var result = [TabTypes.DESCRIPTION];
      var suffixResult = [TabTypes.RELATED_CONTRACTS, TabTypes.DOCUMENTS, TabTypes.HISTORY, TabTypes.AUDIT, TabTypes.LINKS, TabTypes.CHANGELOG];
      if ((_this$contract = this.contract) !== null && _this$contract !== void 0 && (_this$contract = _this$contract.questionnaires) !== null && _this$contract !== void 0 && _this$contract.length) {
        this.contract.questionnaires.forEach(function (questionnaire) {
          if (!questionnaire.tabLabel) return;
          result.push(_objectSpread(_objectSpread({}, Tabs[TabTypes.QUESTIONNAIRE]), {}, {
            url: questionnaire.url,
            slicedUrl: "".concat(Tabs[TabTypes.QUESTIONNAIRE].url, "/").concat(questionnaire.questionnaireId),
            label: questionnaire.tabLabel,
            tabKey: TabTypes.QUESTIONNAIRE
          }));
        });
      }
      if (this.isPaymentsTabEnabled) {
        result.push(TabTypes.PAYMENTS);
      }
      if (this.userHasCompaniesAccess) {
        result.push(TabTypes.CONTRACT_PARTY);
      }
      result.push.apply(result, suffixResult);
      return createTabsList(result, "/contracts/contract/".concat(this.id), getLinksLabel(this.contract));
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (type) {
        return +type.id === +_this.contract.activityTypeId;
      });
    },
    typeOptions: function typeOptions() {
      var _this2 = this;
      return this.documentTypes.map(function (type) {
        return {
          id: type.id,
          label: _this2.$trans(type.name)
        };
      });
    },
    isUserLocked: function isUserLocked() {
      return isLockedContractUser(this.currentUser);
    },
    isReadOnly: function isReadOnly() {
      return this.isUserLocked || !!this.contract.archived;
    },
    delegationData: function delegationData() {
      return {
        objType: this.contract.objType,
        organisationIds: this.contract.organisationIds || [],
        responsibleUserIds: this.contract.responsibleUserIds || [],
        accountableUserIds: this.contract.accountableUserIds || [],
        consultedUserIds: this.contract.consultedUserIds || [],
        informedUserIds: this.contract.informedUserIds || [],
        tagIds: this.contract.tags || [],
        module: MODULES.CONTRACTS,
        solutionIds: this.contract.solutionIds
      };
    },
    missingRequiredFields: function missingRequiredFields() {
      var _this$contract2;
      if (!this.isLoaded) return [];
      return getMissingRequiredFieldsLabels(this.currentActivityType, ((_this$contract2 = this.contract) === null || _this$contract2 === void 0 ? void 0 : _this$contract2.customFieldValuesParsed) || getParsedCfValues(this.contract));
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this3 = this;
      return Promise.all([this.getContract(), this.getAttachmentFiles(), this.getQuestionnairesList()]).then(function () {
        _this3.getAuditQuestionnaires();
        _this3.getAllRequests();
        _this3.getDocumentTypes();
      }).then(function () {
        _this3.isLoaded = true;
      }).catch(function () {
        if (_this3.isPreview) {
          _this3.$emit('accessError');
          return;
        }
        catch403(_this3.$router, 'MyContracts');
      });
    },
    getQuestionnairesList: function getQuestionnairesList() {
      var _this4 = this;
      return getQuestionnairesByQuestionnaireTypeId(this.questionnaireType).then(function (_ref) {
        var list = _ref.list;
        _this4.questionnaires = list.filter(function (item) {
          return item.selectable;
        }).map(function (item) {
          item.label = item.title;
          item.value = item.id;
          return item;
        });
      });
    },
    getAuditQuestionnaires: function getAuditQuestionnaires() {
      var _this5 = this;
      if (!this.genericData) {
        this.auditQuestionnaires = [];
        return;
      }
      return this.genericData.getAllQuestionnairesAPI(this.genericData.questionnaireType).then(function (response) {
        _this5.auditQuestionnaires = response;
      });
    },
    getAllRequests: function getAllRequests() {
      var _this6 = this;
      if (!this.genericData) {
        this.auditRequests = [];
        return;
      }
      return this.genericData.getAllRequestsAPI(MODULES.CONTRACTS, this.id).then(function (response) {
        _this6.auditRequests = response.list;
      }).then(function () {
        return _this6.getQuestionnaireAssessments();
      });
    },
    getQuestionnaireAssessments: function getQuestionnaireAssessments() {
      var _this$auditRequests$f,
        _this7 = this;
      var id = (_this$auditRequests$f = this.auditRequests.findLast(function (_ref2) {
        var answered = _ref2.answered;
        return answered;
      })) === null || _this$auditRequests$f === void 0 || (_this$auditRequests$f = _this$auditRequests$f.questionnaire) === null || _this$auditRequests$f === void 0 ? void 0 : _this$auditRequests$f.id;
      if (!id) return;
      return _getQuestionnaireAssessments(id).then(function (_ref3) {
        var list = _ref3.list;
        return _this7.auditAssessments = list;
      });
    },
    getAttachmentFiles: function getAttachmentFiles() {
      var _this8 = this;
      return getFiles({
        module: MODULES.CONTRACTS,
        moduleId: this.id
      }).then(function (response) {
        return _this8.files = _toConsumableArray(response.list);
      });
    },
    getContract: function getContract() {
      var _this9 = this;
      return _getContract(this.id).then(function (data) {
        _this9.contract = data;
      });
    },
    getDocumentTypes: function getDocumentTypes() {
      var _this10 = this;
      return getDocumentTypesAll().then(function (_ref4) {
        var list = _ref4.list;
        return _this10.documentTypes = list;
      });
    },
    handleUpdateForCustomPageUpdate: function handleUpdateForCustomPageUpdate(data) {
      this.isPageWithCustomUpdateDirty = data.value;
      if (data.value) {
        this.setUpdateTimer();
        if (data.isForced) {
          this.saveStatusClicked();
        }
      } else if (!this.isDirty) {
        this.saveStatus = saveStatus.SAVED;
      }
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this11 = this;
      getFileById(fileId).then(function (response) {
        var _response$docTypeIds;
        if ((_response$docTypeIds = response.docTypeIds) !== null && _response$docTypeIds !== void 0 && _response$docTypeIds.includes(DocumentTypesIds.MAIN)) {
          return _this11.getAttachmentFiles();
        }
        var index = _this11.files.findIndex(function (file) {
          return +file.id === response.id;
        });
        if (index !== -1) {
          _this11.files[index] = response;
        } else {
          _this11.files.push(response);
        }
      }).then(function () {
        return _this11.saveStatus = saveStatus.SAVED;
      });
    },
    contractPropertyChanged: function contractPropertyChanged(_ref5) {
      var property = _ref5.property,
        value = _ref5.value;
      this.isDirty = true;
      if (property === 'tagIds') {
        this.contract.tags = value;
      } else if (property === 'startDate') {
        this.contract[property] = value;
        if (getYear(value) === this.currentYear && !this.contract.paymentIntervalStartMonth) {
          this.contract.paymentIntervalStartMonth = getMonthIndex(value) + 1;
        }
      } else if (property === 'responsibleUserIds') {
        if (!value.length && this.contract.confidential) {
          this.contract.confidential = 0;
        }
        this.contract[property] = value;
      } else {
        this.contract[property] = value;
      }
      this.setUpdateTimer();
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.contractPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveUpdates: function saveUpdates() {
      var _this12 = this;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      var data = _objectSpread(_objectSpread({}, this.contract), {}, {
        customFieldValues: this.contract.customFieldValuesParsed
      });
      if (!data.customFieldValues) {
        delete data.customFieldValues;
      }
      editContract(data).then(function (response) {
        if (_this12.contract.confidential && !_this12.contract.responsibleUserIds.includes(_this12.currentUser.id)) {
          _this12.$router.push('/contracts/overview/all');
          return;
        }
        // update the contract if there were no new changes during the save time
        if (timerId === _this12.updateTimer) {
          _this12.contract = response;
          _this12.updateIdx += 1;
        }
        _this12.saveStatus = saveStatus.SAVED;
        _this12.isDirty = false;
        _this12.$emit('updated', _this12.contract);
      }).catch(function () {
        _this12.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    handleShowNotification: function handleShowNotification(_ref6) {
      var message = _ref6.message,
        _ref6$type = _ref6.type,
        type = _ref6$type === void 0 ? 'error' : _ref6$type;
      if (type === 'info') {
        this.notificationMessage = message;
        return;
      }
      this.$notify({
        title: this.$trans(message),
        type: type
      });
    },
    activityTypeChanged: function activityTypeChanged(value) {
      this.contractPropertyChanged({
        property: 'activityTypeId',
        value: value
      });
    },
    deleteContract: function deleteContract() {
      var _this13 = this;
      _deleteContract(this.id).then(function () {
        if (_this13.isPreview) {
          _this13.$emit('deleteItem', _this13.contract);
        } else {
          _this13.$router.replace('/contracts/overview/all');
        }
      });
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      var _this14 = this;
      this.getAttachmentFiles().then(function () {
        return _this14.saveStatus = saveStatus.SAVED;
      });
    },
    addQuestionnaire: function addQuestionnaire(id) {
      var questionnaire = this.questionnaires.find(function (item) {
        return +item.id === +id;
      });
      if (!questionnaire) return;
      this.isDirty = true;
      this.contractPropertyChanged({
        property: 'questionnaires',
        value: this.contract.questionnaires ? [].concat(_toConsumableArray(this.contract.questionnaires), [{
          questionnaireId: id
        }]) : [{
          questionnaireId: id
        }]
      });
    },
    removeQuestionnaire: function removeQuestionnaire(id) {
      this.contractPropertyChanged({
        property: 'questionnaires',
        value: this.contract.questionnaires.filter(function (q) {
          return q.questionnaireId != id;
        })
      });
    }
  }
};