import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex -mb-1.5px"
};
var _hoisted_2 = {
  class: "border border-gray-350 p-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    to: $options.baseControlLink + 'main'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.mainReport), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), _createVNode(_component_light_tab_item, {
    to: $options.baseControlLink + 'mothercontrols'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.motherControls), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), $options.dataReportIsVisible ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 0,
    to: $options.baseControlLink + 'data'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.dataReport), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.isFrameworksEnabled ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 1,
    to: $options.baseControlLink + 'framework'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.framework), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.changelogReportVisible ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 2,
    to: $options.baseControlLink + 'changelog'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.changelogReport), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)])]);
}