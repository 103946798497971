function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { clickOutside } from '@/utils/directives/clickOutside';
import { UserLevels } from '@/constants/UserLevels';
import { Colors } from '@/Colors';
import { ObjectTypes, ObjectTypeToUserField } from '@/constants/ObjectTypes';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'RismaModulesRightsPicker',
  introduction: 'module that helps with access levels for users',
  token: '<risma-modules-rights-picker :user="user" :field="field" @updateModule="$emit(`updateModule`, $event)"/>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: function _default() {},
      note: 'User object for the user to change permissions on'
    },
    field: {
      type: String,
      required: true,
      default: '',
      note: 'The name of the field that has that value (to emit it and so on)'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['updateModule', 'update:opened'],
  data: function data() {
    return {
      isModalOpened: false,
      userLevels: UserLevels,
      fieldToLocked: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypeToUserField[ObjectTypes.EXECUTION_INITIATIVE], 'level_e_locked'), ObjectTypeToUserField[ObjectTypes.RISK_RISK], 'level_r_locked'), ObjectTypeToUserField[ObjectTypes.CONTROLS_CONTROL], 'level_c_locked'), ObjectTypeToUserField[ObjectTypes.INCIDENTS_INCIDENT], 'level_incidents_locked'), ObjectTypeToUserField[ObjectTypes.CONTRACT], 'level_contracts_locked'), ObjectTypeToUserField[ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE], 'level_process_library_locked'),
      ObjectTypes: ObjectTypes,
      ObjectTypeToUserField: ObjectTypeToUserField,
      colors: Colors,
      translate: getTranslate['RismaModulesRightsPicker']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    currentLevelIcon: function currentLevelIcon() {
      return 'icon-risma-' + this.levelToIcon(this.user[this.field]);
    },
    allowAdmin: function allowAdmin() {
      return this.currentUser.level_admin === 1 || this.currentUser.is_admin === true;
    },
    isCompany: function isCompany() {
      return this.field === ObjectTypeToUserField[ObjectTypes.COMPANY];
    },
    isUserAdmin: function isUserAdmin() {
      return this.user.level_admin === 1 || this.user.is_admin === true;
    },
    isValueAdmin: function isValueAdmin() {
      return this.user[this.field] === UserLevels.ADMIN;
    },
    isLevelAccessBroken: function isLevelAccessBroken() {
      return this.isUserAdmin && !this.isValueAdmin;
    }
  }),
  methods: {
    closeModal: function closeModal() {
      if (this.isModalOpened) this.$emit('update:opened');
      this.isModalOpened = false;
    },
    toggleModal: function toggleModal(event) {
      if (this.readonly || this.isUserAdmin && this.isValueAdmin) return;
      if (!event.target.classList.contains('locked-item')) {
        this.isModalOpened = !this.isModalOpened;
        this.$emit('update:opened');
      }
    },
    levelToIcon: function levelToIcon(accessLevel) {
      var icons = ['noAccess', 'user', 'privUser', 'superUser', 'admin'];
      return icons[accessLevel];
    },
    setLevel: function setLevel(accessLevel, field) {
      if (!this.allowAdmin) {
        return;
      }
      this.$emit('updateModule', {
        id: this.user.id,
        field: field,
        value: accessLevel
      });
    }
  }
};