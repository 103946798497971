import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "p-2 pb-5"
};
var _hoisted_2 = {
  class: "flex items-baseline mb-1"
};
var _hoisted_3 = {
  class: "text-xl text-blue-250 hover:underline mr-1"
};
var _hoisted_4 = {
  class: "mb-2 flex"
};
var _hoisted_5 = {
  class: "flex items-center hover:underline"
};
var _hoisted_6 = {
  class: "font-bold"
};
var _hoisted_7 = {
  class: "mb-2"
};
var _hoisted_8 = {
  key: 0,
  class: "italic"
};
var _hoisted_9 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  return _openBlock(), _createElementBlock("tr", null, [_createElementVNode("td", _hoisted_1, [_createElementVNode("h5", _hoisted_2, [_createVNode(_component_preview_modal_link, {
    activity: $props.gap.node,
    title: $props.gap.node.url
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_3, _toDisplayString($props.gap.node.title), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "title"]), _createVNode(_component_risma_title, {
    title: $props.gap.node.organisation,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("h6", _hoisted_4, [_createVNode(_component_preview_modal_link, {
    activity: $options.gapAnalysisPreviewObj,
    "selected-route": $options.gapAnalysisPreviewObj.route,
    title: $options.gapAnalysisPreviewObj.url
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_5, [_createVNode(_component_traffic_light, {
        color: $options.gapColor.color,
        title: _ctx.$trans($options.gapColor.legend),
        class: "mr-2"
      }, null, 8 /* PROPS */, ["color", "title"]), _createElementVNode("p", _hoisted_6, _toDisplayString($props.gapArticle.article) + " " + _toDisplayString($props.gapArticle.articleTitle), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "selected-route", "title"])]), _createElementVNode("p", _hoisted_7, [_createElementVNode("span", {
    class: _normalizeClass(['__' + $props.gap.analysis_rag, "badge analysis_rag_number"])
  }, "   ", 2 /* CLASS */), _createTextVNode(" " + _toDisplayString($props.gap.analysis) + " ", 1 /* TEXT */), $props.gap.analysis === '' ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString($data.translate.thisHasNotBeenAnsweredYet), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("small", null, [_createTextVNode(_toDisplayString($data.translate.requirement) + ": ", 1 /* TEXT */), _createElementVNode("span", null, [_createTextVNode(_toDisplayString($props.gapArticle.requirement.substr(0, 200)) + " ", 1 /* TEXT */), $props.gapArticle.requirement.length > 200 ? (_openBlock(), _createElementBlock("span", _hoisted_9, "...")) : _createCommentVNode("v-if", true)])])]), $props.showRiskAssessment ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("td", null, _toDisplayString($props.gap.likelihood_gross) + " / " + _toDisplayString($props.gap.likelihood_net), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($props.gap.impact_gross) + " / " + _toDisplayString($props.gap.impact_net), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($props.gap.rpi_gross) + " / " + _toDisplayString($props.gap.rpi_net), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}