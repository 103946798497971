// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-list-light[data-v-19787644]:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(235 235 235 / var(--tw-bg-opacity));
}
.dark.tab-list-light[data-v-19787644]:after {
  height: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(190 190 190 / var(--tw-bg-opacity));
}
.dark.tab-list-light[data-v-19787644] .tab-item-active div {
  border-left-width: 1px;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(190 190 190 / var(--tw-border-opacity));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
