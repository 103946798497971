function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { ref } from 'vue';
import { capitalize } from '@/utils';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { getAllByType } from '@/api/compliance/informationAssets';
import { AssetsTypes, getModuleFromAssetType } from '@/constants/compliance/AssetsTypes';
import { InformationAssetsViews } from '@/constants/compliance/InformationAssetsViews';
import InformationAssetsExtendedView from '@/components/Pages/InformationAssets/InformationAssetsExtendedView';
import InformationAssetsSimpleView from '@/components/Pages/InformationAssets/InformationAssetsSimpleView';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Pagination from '@/components/Atoms/Pagination';
import RismaPagesize from '@/components/Atoms/RismaDatatable/RismaPagesize';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import InputField from '@/components/Atoms/Inputs/InputField';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { MODULES } from '@/constants/modules';
export default {
  name: 'InformationAssets',
  components: {
    InformationAssetsExtendedView: InformationAssetsExtendedView,
    InformationAssetsSimpleView: InformationAssetsSimpleView,
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    Pagination: Pagination,
    RismaPagesize: RismaPagesize,
    InformationAssetCreationModal: InformationAssetCreationModal,
    InputField: InputField
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.getData(to.params.typeId);
    next();
  },
  props: {
    onlyMy: {
      type: Boolean,
      default: false,
      note: 'If show only my IA'
    },
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    activityTypes: {
      type: Array,
      required: true
    }
  },
  setup: function setup() {
    var _history$state;
    var initialView = (_history$state = history.state) === null || _history$state === void 0 ? void 0 : _history$state.initialView;
    var pageView = initialView ? ref(initialView) : getLocalStorageComputed('information_assets_overview_view', InformationAssetsViews.EXTENDED);
    return {
      pageView: pageView
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      informationAssets: [],
      showCreateModal: false,
      currentItem: 0,
      totalAssets: 0,
      basePagingOptions: [10, 25, 50, 100],
      pageSize: 25,
      childrenCountOnTheList: 0,
      sortField: '',
      timer: 0,
      filterString: '',
      debounceTimeout: 1000,
      debounceTimer: null,
      translate: getTranslate['InformationAssets']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), {}, {
    title: function title() {
      var activityTypeName = this.activityType ? capitalize(this.activityType.label) : this.$trans(capitalize(this.typeId));
      return this.onlyMy ? "".concat(this.translate.my, " ").concat(activityTypeName) : "".concat(activityTypeName);
    },
    additionalPaginationText: function additionalPaginationText() {
      if (!this.childrenCountOnTheList || !this.showExtended) return '';
      return " (".concat(this.translate.and, " ").concat(this.childrenCountOnTheList, " ").concat(this.translate.underlying, " ").concat(this.$trans(capitalize(this.typeId)).toLowerCase(), ")");
    },
    activityType: function activityType() {
      var _this = this;
      return this.activityTypes.find(function (type) {
        return type.id == _this.typeId;
      });
    },
    pagingOptions: function pagingOptions() {
      return [].concat(_toConsumableArray(this.basePagingOptions), [{
        value: this.totalAssets,
        label: this.translate.showAll
      }]);
    },
    confirmExportMessage: function confirmExportMessage() {
      var partialExport = this.pageSize < this.totalAssets;
      if (!partialExport) return '';
      var exportedItems = this.totalAssets - this.currentItem > this.pageSize ? this.pageSize : this.totalAssets - this.currentItem;
      return "".concat(this.translate.areYouSureYouWantToExport, " ").concat(exportedItems, " ").concat(this.translate.itemsFrom, " ").concat(this.totalAssets, "?").concat(this.additionalPaginationText);
    },
    confirmPrintMessage: function confirmPrintMessage() {
      var partialExport = this.pageSize < this.totalAssets;
      if (!partialExport) return '';
      return "".concat(this.translate.areYouSureYouWantToPrint, " ").concat(this.pageSize, " ").concat(this.translate.itemsFrom, " ").concat(this.totalAssets, "?");
    },
    showExtended: function showExtended() {
      return this.pageView === InformationAssetsViews.EXTENDED;
    },
    canCreate: function canCreate() {
      return useUserStore().canCreateInformationAssets(this.module, this.typeId);
    },
    module: function module() {
      if (!isNaN(this.typeId)) {
        return MODULES.COMPLIANCE_INFORMATION_ASSETS;
      }
      return getModuleFromAssetType(this.typeId);
    }
  }),
  watch: {
    typeId: function typeId() {
      this.getData(this.typeId);
    }
  },
  mounted: function mounted() {
    this.getData(this.typeId);
  },
  methods: {
    backendSortRequest: function backendSortRequest(state) {
      return this.getInformationAssetsByType(this.typeId, state);
    },
    createIA: function createIA(data) {
      this.toggleCreateModal();
      this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        activity: getFieldsFromObjectForPreview(data)
      });
      this.getData(this.typeId);
    },
    getData: function getData(typeId) {
      var _this2 = this;
      this.isLoaded = false;
      if (this.currentItem != 0) this.currentItem = 0;
      this.getPageSizeLocalState(typeId);
      return this.getInformationAssetsByType(typeId).then(function () {
        _this2.isLoaded = true;
      });
    },
    getInformationAssetsByType: function getInformationAssetsByType(typeId, sortData) {
      var _this3 = this;
      var type = typeId > 0 ? AssetsTypes.INFORMATION_ASSETS_API : typeId;
      var filterSearchName = typeId > 0 ? 'title' : 'name';
      var sortString = '&sortField=';
      if (sortData) {
        var str = "".concat(filterSearchName, "&sortDir=").concat(sortData.dir);
        sortString += str;
        this.sortField = str;
      } else {
        sortString += filterSearchName;
      }
      var filters = "?data[]=all&limit=".concat(this.pageSize, "&start=").concat(this.currentItem);
      if (this.onlyMy) {
        filters += "&filters[responsible][]=".concat(this.currentUser.id);
      }
      if (this.filterString) {
        filters += "&filters[".concat(filterSearchName, "]=").concat(this.filterString);
      }
      if (typeId > 0) {
        filters += "&filters[activityType][]=".concat(typeId);
      }
      filters += "".concat(sortString);
      return getAllByType(type, filters).then(function (data) {
        _this3.totalAssets = data.total;
        _this3.adjustAllPerPage();
        _this3.informationAssets = data.list;
      });
    },
    adjustAllPerPage: function adjustAllPerPage() {
      if (![].concat(_toConsumableArray(this.basePagingOptions), [this.totalAssets]).includes(this.pageSize)) this.pageSize = this.totalAssets;
    },
    switchView: function switchView() {
      this.pageView = this.showExtended ? InformationAssetsViews.SIMPLE : InformationAssetsViews.EXTENDED;
      history.replaceState(_objectSpread(_objectSpread({}, history.state), {}, {
        initialView: ''
      }), '');
    },
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    pageChange: function pageChange(e) {
      if (e.mounted) return;
      this.currentItem = e.start;
      this.getInformationAssetsByType(this.typeId);
    },
    setPageSizeLocalState: function setPageSizeLocalState() {
      localStorage.setItem(this.getLocalStatePageSizeName(this.typeId), JSON.stringify(this.pageSize));
    },
    getPageSizeLocalState: function getPageSizeLocalState(typeId) {
      var localStateName = this.getLocalStatePageSizeName(typeId);
      if (!localStateName) {
        this.pageSize = 25;
        return;
      }
      var pageSize = JSON.parse(localStorage.getItem(localStateName));
      this.pageSize = pageSize || 25;
    },
    getLocalStatePageSizeName: function getLocalStatePageSizeName(typeId) {
      return "information_assets_".concat(typeId, "_page_size");
    },
    onSearch: function onSearch() {
      var _this4 = this;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(function () {
        _this4.getData(_this4.typeId);
      }, this.debounceTimeout);
    }
  }
};