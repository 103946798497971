function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAnonymousIncidentActivityType as _getAnonymousIncidentActivityType, createAnonymousIncident as _createAnonymousIncident } from '@/api/incident/incident';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import Notification from '@/components/Molecules/Notification';
import RequiredFieldsNotification from '@/components/Molecules/RequiredFieldsNotification';
import { getMissingRequiredFieldsLabels } from '@/utils/CustomFields';
import { getErrorMessage } from '@/utils/handleAPIErrors';
import { formatToUtcDateTime } from '@/utils/date';
import { MODULES } from '@/constants/modules';
export default {
  name: 'IncidentAnonymousCreationPage',
  components: {
    Notification: Notification,
    RequiredFieldsNotification: RequiredFieldsNotification,
    TrafficLightSelector: TrafficLightSelector,
    DateTimePicker: DateTimePicker,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    InputField: InputField,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ActivityCustomFields: ActivityCustomFields
  },
  data: function data() {
    return {
      title: '',
      description: '',
      incidentHappenedAt: null,
      severity: 0,
      defaultActivityType: null,
      errorMessage: '',
      titleError: '',
      isLoading: false,
      moduleName: MODULES.INCIDENTS,
      customFieldsParsed: null,
      translate: getTranslate['IncidentAnonymousCreationPage']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    descriptionInOptional: function descriptionInOptional() {
      var _this$defaultActivity;
      if (!this.defaultActivityType) return false;
      return (_this$defaultActivity = this.defaultActivityType) === null || _this$defaultActivity === void 0 || (_this$defaultActivity = _this$defaultActivity.optionalFields) === null || _this$defaultActivity === void 0 ? void 0 : _this$defaultActivity.includes('description');
    },
    severityInOptional: function severityInOptional() {
      var _this$defaultActivity2;
      if (!this.defaultActivityType) return false;
      return (_this$defaultActivity2 = this.defaultActivityType) === null || _this$defaultActivity2 === void 0 || (_this$defaultActivity2 = _this$defaultActivity2.optionalFields) === null || _this$defaultActivity2 === void 0 ? void 0 : _this$defaultActivity2.includes('severity');
    },
    isCreationSettingEnabled: function isCreationSettingEnabled() {
      return !!this.getSettingValue('feature.incident_anonymous_creation_enabled');
    },
    descriptionTextByRows: function descriptionTextByRows() {
      return [this.translate.welcomeToOurCreationPage, this.translate.ifYouCreateAnIncidentOnThisPage, this.translate.regardlessOfWhetherTheIncidentIsAn, this.translate.rememberThatMoreInformationYouProvide, this.translate.sinceYourReportingIsAnonymous];
    },
    missingRequiredFields: function missingRequiredFields() {
      return getMissingRequiredFieldsLabels(this.defaultActivityType, this.customFieldsParsed);
    },
    currentStatus: function currentStatus() {
      var _this$defaultActivity3;
      var statuses = (_this$defaultActivity3 = this.defaultActivityType) === null || _this$defaultActivity3 === void 0 || (_this$defaultActivity3 = _this$defaultActivity3.workflow) === null || _this$defaultActivity3 === void 0 ? void 0 : _this$defaultActivity3.workflowStatuses;
      if (!statuses) return;
      return statuses.find(function (_ref) {
        var isStartStatus = _ref.isStartStatus;
        return isStartStatus;
      }) || statuses[0];
    },
    listOfDisabledFields: function listOfDisabledFields() {
      var _this$currentStatus$f;
      if (!this.currentStatus) return [];
      return (_this$currentStatus$f = this.currentStatus.fieldOptions) === null || _this$currentStatus$f === void 0 ? void 0 : _this$currentStatus$f.filter(function (_ref2) {
        var isLocked = _ref2.isLocked;
        return isLocked;
      }).map(function (_ref3) {
        var fieldId = _ref3.fieldId;
        return fieldId;
      });
    },
    listOfHiddenFields: function listOfHiddenFields() {
      var _this$currentStatus$f2;
      if (!this.currentStatus) return [];
      return (_this$currentStatus$f2 = this.currentStatus.fieldOptions) === null || _this$currentStatus$f2 === void 0 ? void 0 : _this$currentStatus$f2.filter(function (_ref4) {
        var isHidden = _ref4.isHidden;
        return isHidden;
      }).map(function (_ref5) {
        var fieldId = _ref5.fieldId;
        return fieldId;
      });
    }
  }),
  mounted: function mounted() {
    this.getAnonymousIncidentActivityType();
  },
  methods: {
    getAnonymousIncidentActivityType: function getAnonymousIncidentActivityType() {
      var _this = this;
      this.isLoading = true;
      this.errorMessage = '';
      return _getAnonymousIncidentActivityType().then(function (activityType) {
        _this.defaultActivityType = activityType;
      }).catch(function (error) {
        _this.errorMessage = getErrorMessage(error) || _this.translate.noAccess;
      }).finally(function () {
        _this.isLoading = false;
      });
    },
    createAnonymousIncident: function createAnonymousIncident() {
      var _this2 = this;
      if (!this.validateItem()) return;
      var incidentData = {
        title: this.title,
        incidentHappenedAt: '',
        description: '',
        severity: ''
      };
      if (this.defaultActivityType.defaultQuestionnaireId) {
        incidentData.questionnaires = [{
          questionnaireId: this.defaultActivityType.defaultQuestionnaireId
        }];
      }
      if (this.customFieldsParsed) {
        incidentData.customFieldValues = this.customFieldsParsed;
      }
      if (this.incidentHappenedAt) {
        incidentData.incidentHappenedAt = formatToUtcDateTime(this.incidentHappenedAt);
      }
      if (this.descriptionInOptional) {
        incidentData.description = this.description;
      }
      if (this.severityInOptional) {
        incidentData.severity = this.severity;
      }
      this.isLoading = true;
      return _createAnonymousIncident(incidentData).then(function (incidentInfo) {
        _this2.$notify({
          title: [_this2.translate.yourActivityHasBeenCreated, incidentInfo.title]
        });
        _this2.resetFields();
      }).catch(function () {
        _this2.$notify({
          title: _this2.translate.somethingWentWrongWhileUploading,
          type: 'error'
        });
      }).finally(function () {
        _this2.isLoading = false;
      });
    },
    resetFields: function resetFields() {
      this.title = '';
      this.description = '';
      this.incidentHappenedAt = null;
      this.severity = 0;
    },
    validateItem: function validateItem() {
      this.titleError = '';
      if (!this.title.trim()) {
        this.titleError = this.translate.titleCannotBeEmpty;
      }
      return !this.titleError;
    }
  }
};