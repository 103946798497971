import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "notifications-modal overflow-y-auto overflow-x-hidden shadow-xl rounded-md bg-white absolute w-40rem p-2 right-0.7rem top-12 z-50 cursor-default max-h-90vh"
};
var _hoisted_2 = {
  class: "notifications-headline flex justify-between items-center m-4"
};
var _hoisted_3 = {
  class: "search-modal-header"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 0,
  class: "absolute w-2 h-2 bg-red-610 right-0.3rem top-0.3rem rounded-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    class: "text-21px",
    title: $data.translate.myNotifications
  }, null, 8 /* PROPS */, ["title"])]), $options.isUnreadNotificationsExist ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_button, {
    type: "save",
    text: $data.translate.markAllAsRead,
    disabled: !$options.isUnreadNotificationsExist,
    onClick: $options.handleMarkAllNotificationsAsRead
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notifications, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "notification-".concat(index),
      class: "notification-wrapper py-2 my-3 px-8 rounded-md hover:bg-gray-25 relative"
    }, [_createElementVNode("div", {
      class: "w-35rem flex items-center cursor-pointer",
      onClick: function onClick($event) {
        return $options.onNotificationLinkClick(item.id);
      }
    }, [item.regarding_url ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      class: "hover:underline w-inherit",
      truncate: true,
      title: item.message_regarding
    }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createBlock(_component_risma_title, {
      key: 1,
      class: "w-inherit",
      title: "".concat(item.notification_from, " > ").concat(item.notification_subject)
    }, null, 8 /* PROPS */, ["title"]))], 8 /* PROPS */, _hoisted_5), _createVNode(_component_risma_title, {
      class: "notification-description",
      type: "small",
      title: item.message_text
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
      title: $options.getDate(item.date_created),
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), !item.read ? (_openBlock(), _createElementBlock("span", _hoisted_6)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}