import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_sign_off_form = _resolveComponent("sign-off-form");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_modal, {
    header: $data.translate.sendReminder,
    "button-ok-text": $data.translate.send,
    "button-dismiss-text": $data.translate.cancel,
    "modal-fixed-width": "700px",
    onDismiss: $options.handleDismiss,
    onAccept: $options.sendReminder
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_sign_off_form, {
        dashboardTitle: $data.dashboardTitle,
        "onUpdate:dashboardTitle": _cache[0] || (_cache[0] = function ($event) {
          return $data.dashboardTitle = $event;
        }),
        comments: $data.comments,
        "onUpdate:comments": _cache[1] || (_cache[1] = function ($event) {
          return $data.comments = $event;
        }),
        "modal-type": "remind",
        readonly: false,
        "is-awareness-sent": !!$props.awareness.sent,
        users: $props.awareness.users,
        "activity-title": $props.activity.title,
        "validation-email": $data.sentError,
        "quiz-questionnaire-id": $props.awareness.quizQuestionnaireId,
        "onRemove:user": $options.removeUserFromSent,
        "onAdd:newEmail": $options.addNewEmail
      }, null, 8 /* PROPS */, ["dashboardTitle", "comments", "is-awareness-sent", "users", "activity-title", "validation-email", "quiz-questionnaire-id", "onRemove:user", "onAdd:newEmail"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onDismiss", "onAccept"])]);
}