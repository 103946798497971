import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "access_modal"
};
var _hoisted_2 = ["checked"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "rights-picker cursor-pointer",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.toggleModal($event);
    })
  }, [_createElementVNode("a", {
    class: _normalizeClass(["module-level-icon", [{
      forbidden: $props.readonly
    }, {
      'opacity-50': $data.isModalOpened
    }]])
  }, [_createElementVNode("span", {
    class: _normalizeClass("level ".concat($options.currentLevelIcon))
  }, null, 2 /* CLASS */), $props.user[$data.fieldToLocked[$props.field]] && !$props.user.is_admin && $props.user[$props.field] !== $data.userLevels.NONE ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "lock",
    width: "18",
    height: "18",
    "fill-class": "fill-blueMain",
    class: "text-white absolute top-1/2-4 left-4"
  })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $data.isModalOpened ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$options.isLevelAccessBroken ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.setLevel($data.userLevels.ADMIN, $props.field);
    })
  }, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "icon-risma-admin modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.admin), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.setLevel($data.userLevels.NONE, $props.field);
    })
  }, [_cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "icon-risma-noAccess modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.noAccess), 1 /* TEXT */)]), _createElementVNode("div", {
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.setLevel($data.userLevels.NORMAL, $props.field);
    })
  }, [_cache[9] || (_cache[9] = _createElementVNode("span", {
    class: "icon-risma-user modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.normal), 1 /* TEXT */)]), !$options.isCompany ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.setLevel($data.userLevels.PRIVILEGED, $props.field);
    })
  }, [_cache[10] || (_cache[10] = _createElementVNode("span", {
    class: "icon-risma-privUser modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.privileged), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.setLevel($data.userLevels.SUPER, $props.field);
    })
  }, [_cache[11] || (_cache[11] = _createElementVNode("span", {
    class: "icon-risma-superUser modal-icon"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.super), 1 /* TEXT */)]), $props.user[$props.field] !== $data.userLevels.NONE && $props.field !== $data.ObjectTypeToUserField[$data.ObjectTypes.INFORMATION_ASSET] && !$options.isCompany ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "locked-item",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.setLevel($props.user[$data.fieldToLocked[$props.field]] ? 0 : 1, $data.fieldToLocked[$props.field]);
    })
  }, [_createElementVNode("input", {
    checked: !!$props.user[$data.fieldToLocked[$props.field]],
    class: "locked-item",
    type: "checkbox"
  }, null, 8 /* PROPS */, _hoisted_2), _createTextVNode(" " + _toDisplayString($data.translate.locked), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeModal]]);
}