import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "container mx-auto"
};
var _hoisted_2 = {
  class: "flex justify-content-between"
};
var _hoisted_3 = {
  class: "flex flex-wrap mb-3"
};
var _hoisted_4 = {
  class: "mr-2"
};
var _hoisted_5 = {
  class: "mr-2"
};
var _hoisted_6 = {
  class: "ml-auto divide-y divide-slate-300"
};
var _hoisted_7 = {
  class: "text-2xl text-center"
};
var _hoisted_8 = {
  class: "flex flex-row"
};
var _hoisted_9 = {
  class: "text-sm"
};
var _hoisted_10 = {
  class: "text-gray-750 font-bold text-2xl text-center"
};
var _hoisted_11 = {
  key: 2,
  class: "flex flex-wrap justify-content-between"
};
var _hoisted_12 = {
  class: "text-center mb-1"
};
var _hoisted_13 = {
  key: 1,
  class: "p-4"
};
var _hoisted_14 = {
  key: 3,
  class: "min-w-15rem p-4"
};
var _hoisted_15 = {
  key: 0,
  class: "w-17rem mb-3"
};
var _hoisted_16 = {
  key: 1,
  class: "p-4"
};
var _hoisted_17 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$filteredCus;
  var _component_Notification = _resolveComponent("Notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_organisation_selector = _resolveComponent("organisation-selector");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.untrackedIncidentCount ? (_openBlock(), _createBlock(_component_Notification, {
    key: 0,
    type: "warning"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString("".concat($data.translate.thereAre, " ").concat($data.untrackedIncidentCount, " ").concat($data.translate.incidentsWithoutIncidentDateUs)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.headline,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_date_range_picker, {
    title: $data.translate.period,
    "delete-enabled": true,
    "start-date": $data.dateRange.startDate,
    "end-date": $data.dateRange.endDate,
    opens: "right",
    onChanged: $options.changeDatePicker
  }, null, 8 /* PROPS */, ["title", "start-date", "end-date", "onChanged"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_activity_type_selector, {
    modelValue: $data.activityTypeToFilter,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.activityTypeToFilter = $event;
    }),
    class: "min-w-210px",
    label: $data.translate.activityType,
    "reset-value-title": "All activity types",
    "activity-types": _ctx.activityTypes,
    onChanged: $options.onActivityTypeChange
  }, null, 8 /* PROPS */, ["modelValue", "label", "activity-types", "onChanged"])]), _createElementVNode("div", null, [_createVNode(_component_organisation_selector, {
    title: $data.translate.organisations,
    "available-options": $options.organisationOptions,
    "selected-options": $data.selectedOrganisations,
    placeholder: $data.translate.allOrganisations,
    "enable-select-all": false,
    "deselect-label": "",
    "select-label": "",
    class: "w-215px",
    onSelected: $options.onOrganisationsChange
  }, null, 8 /* PROPS */, ["title", "available-options", "selected-options", "placeholder", "onSelected"])])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("h2", _hoisted_7, _toDisplayString($data.translate.incidentsCount), 1 /* TEXT */), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.totalControl, function (total, index) {
    return _openBlock(), _createElementBlock("div", {
      key: total.label + index,
      class: "p-2"
    }, [_createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$trans(total.label)), 1 /* TEXT */), _createElementVNode("p", _hoisted_10, _toDisplayString(total.value), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])])]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.charts, function (chart, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "flex-grow w-full lg:w-1/2 mb-4 p-4"
    }, [_createElementVNode("p", _hoisted_12, [_createElementVNode("strong", null, _toDisplayString(_ctx.$trans(chart.title)), 1 /* TEXT */)]), chart.data.length ? (_openBlock(), _createBlock(_component_risma_bar_chart, {
      key: 0,
      labels: chart._labels,
      datasets: chart._datasets,
      height: _ctx.setHeight(chart._labels)
    }, null, 8 /* PROPS */, ["labels", "datasets", "height"])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("p", null, _toDisplayString($data.translate.noResultsFoundYouMayWantToWidenYourSearc), 1 /* TEXT */)]))]);
  }), 128 /* KEYED_FRAGMENT */))])), $options.hasNumericalChart && !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $data.translate.sumOfCustomFieldPerOrganisation,
    class: "text-center mb-4",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), (_$options$filteredCus = $options.filteredCustomFields) !== null && _$options$filteredCus !== void 0 && _$options$filteredCus.length ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_single_select, {
    modelValue: $data.currentCustomField,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.currentCustomField = $event;
    }), $options.generateCustomFieldChartData],
    title: $data.translate.customField,
    options: $options.filteredCustomFields,
    placeholder: $data.translate.selectCustomField
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"])])) : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString($data.translate.noResultsFoundYouMayWantToWidenYourSearc), 1 /* TEXT */)), $data.currentCustomField && $options.preparedNumericalChartData ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_risma_bar_chart, {
    labels: $options.preparedNumericalChartData.labels,
    datasets: $options.preparedNumericalChartData.datasets,
    options: $options.additionalUnitOptions,
    height: _ctx.setHeight($options.preparedNumericalChartData.labels)
  }, null, 8 /* PROPS */, ["labels", "datasets", "options", "height"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}