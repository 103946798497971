import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.changelogReport,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $data.translate.changelogReport,
    "state-key": $data.tableStateKey,
    "preselected-page-size": 25,
    "show-search-field": false,
    api: $options.getDataBE
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api"]))]);
}