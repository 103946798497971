function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
var getGeneralEmits = function getGeneralEmits() {
  var actionRights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var rights = _objectSpread({
    addSubNode: true,
    duplicateNode: true,
    duplicateWithSubNodes: true,
    duplicateNodesWithSubNodesAndData: true,
    deleteNode: true
  }, actionRights);
  return [{
    title: i18n.t('Add sub node'),
    icon: 'plus',
    emit: 'addSubNode',
    right: rights.addSubNode
  }, {
    title: i18n.t('Duplicate node'),
    icon: 'file',
    emit: 'duplicateNode',
    right: rights.duplicateNode
  }, {
    title: i18n.t('Duplicate node with sub nodes'),
    icon: 'file-plus',
    emit: 'duplicateNodeWithSubNodes',
    right: rights.duplicateWithSubNodes
  }, {
    title: i18n.t('Duplicate node with sub nodes and data'),
    icon: 'file-plus',
    emit: 'duplicateNodeWithSubNodesAndData',
    right: rights.duplicateNodesWithSubNodesAndData
  }, {
    title: i18n.t('Delete node'),
    icon: 'x',
    emit: 'deleteNode',
    right: rights.deleteNode
  }];
};
export var getContractPartyMiniTreeInternalEmits = function getContractPartyMiniTreeInternalEmits() {
  return [{
    title: i18n.t('Add subcompany'),
    icon: 'plus-circle',
    emit: 'addSubNode',
    class: 'text-xs text-rm-text hover:bg-rGrey-400 stroke-rm-text'
  }, {
    title: i18n.t('Remove as paying part'),
    altTitle: i18n.t('Set as paying part'),
    altTitleFlag: 'payingParty',
    altIcon: 'credit-card',
    icon: 'x',
    emit: 'edit:payingParty',
    class: 'text-rm-signal-red text-xs hover:bg-rGrey-400 stroke-signal-red',
    altClass: 'text-xs text-rm-text hover:bg-rGrey-400 stroke-rm-text'
  }, {
    title: i18n.t('Remove as signing party'),
    altTitle: i18n.t('Set as signing party'),
    altTitleFlag: 'signingParty',
    icon: 'x',
    altIcon: 'edit-3',
    emit: 'edit:signingParty',
    class: 'text-rm-signal-red text-xs hover:bg-rGrey-400 stroke-signal-red',
    altClass: 'text-xs text-rm-text hover:bg-rGrey-400 stroke-rm-text'
  }, {
    titleFunction: function titleFunction(path) {
      return path.length === 1 ? i18n.t('Remove internal contract party') : i18n.t('Remove subcompany');
    },
    icon: 'x',
    emit: 'deleteNode',
    class: 'text-rm-signal-red text-xs hover:bg-rGrey-400 stroke-signal-red'
  }];
};
export var getContractPartyMiniTreeExternalEmits = function getContractPartyMiniTreeExternalEmits() {
  return [{
    title: i18n.t('Add subcontractor'),
    icon: 'plus-circle',
    emit: 'addSubNode',
    class: 'text-xs text-rm-text hover:bg-rGrey-400 stroke-rm-text'
  }, {
    title: i18n.t('Remove as signing party'),
    altTitle: i18n.t('Set as signing party'),
    altTitleFlag: 'signingParty',
    icon: 'x',
    altIcon: 'edit-3',
    emit: 'edit:signingParty',
    class: 'text-rm-signal-red text-xs hover:bg-rm-signal-red--20 stroke-signal-red',
    altClass: 'text-xs text-rm-text hover:bg-rGrey-400 stroke-rm-text'
  }, {
    titleFunction: function titleFunction(path) {
      return path.length === 1 ? i18n.t('Remove external contract party') : i18n.t('Remove subcontractor');
    },
    icon: 'x',
    emit: 'deleteNode',
    class: 'text-rm-signal-red text-xs hover:bg-rGrey-400 stroke-signal-red'
  }];
};
export var getProcessMiniTreeEmits = function getProcessMiniTreeEmits() {
  return getGeneralEmits();
};
export var getComplianceMiniTreeEmits = function getComplianceMiniTreeEmits(projectType) {
  var reportRights = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var generalEmitRights = arguments.length > 2 ? arguments[2] : undefined;
  if (projectType === ProjectTypes.CSRD) {
    return reportRights ? [{
      title: i18n.t('Questionnaire report'),
      icon: 'list',
      emit: 'createArticleReport'
    }] : [];
  }
  if (projectType === ObjectTypes.FRAMEWORK) {
    return [{
      title: i18n.t('Create underlying framework'),
      icon: 'plus',
      maxLevel: 9,
      emit: 'addSubNode'
    }, {
      title: i18n.t('Disable') + ' ' + i18n.t('Framework'),
      altTitle: i18n.t('Enable') + ' ' + i18n.t('Framework'),
      altTitleFlag: 'enabled',
      parentEnabledOnDisabledCheck: true,
      //filters data that has parent disabled and are disabled
      icon: 'eye-off',
      altIcon: 'eye',
      emit: 'edit:enabled'
    }, {
      title: i18n.t('Edit framework title'),
      icon: 'edit',
      emit: 'edit:title'
    }, {
      title: i18n.t('Delete framework'),
      icon: 'x',
      emit: 'deleteNode',
      flagCheckField: 'builtin'
    }];
  }
  var generalEmits = getGeneralEmits(generalEmitRights).filter(function (item) {
    return item.right;
  });
  var result = _toConsumableArray(generalEmits);
  if (reportRights) {
    result.splice(1, 0, {
      title: i18n.t('Create Article 30 report'),
      icon: 'list',
      emit: 'createArticleReport'
    });
  }
  return result.filter(Boolean);
};
export var generateTreeMenuItemList = function generateTreeMenuItemList(settings, transFunction) {
  return [{
    rights: settings.hasLinks,
    click: {
      type: 'add link',
      data: {
        uniqueTreeId: settings.uniqueTreeId,
        linkType: settings.linkType
      }
    },
    icon: 'plus',
    text: transFunction('Add link')
  }, {
    rights: !settings.isLink && settings.updateNodeFunction,
    click: {
      type: 'update'
    },
    icon: 'edit-2',
    text: settings.updateNodeLabel || transFunction('Update node')
  }, {
    rights: !settings.isLink && !settings.disableAddAction && !settings.isProcessNode,
    click: {
      type: 'add'
    },
    icon: 'plus',
    text: settings.addSubNodeLabel || transFunction('Add sub node')
  }, {
    rights: !settings.isLink && settings.isProcessNode && settings.copy,
    click: {
      type: 'addProcessNode'
    },
    icon: 'plus',
    text: transFunction('Add sub node')
  }, {
    rights: !settings.isLink && settings.createReportOption,
    click: {
      type: 'report option'
    },
    icon: 'list',
    text: settings.isGdpr ? transFunction('Create Article 30 report') : transFunction('Questionnaire report')
  }, {
    rights: !settings.isLink && settings.showMemorandumReportLink,
    click: {
      type: 'memorandum report'
    },
    icon: 'list',
    text: transFunction('Memorandum report')
  }, {
    rights: !settings.isLink && settings.copy && settings.copySettings,
    click: {
      type: 'duplicate'
    },
    icon: 'file',
    text: transFunction('Duplicate node')
  }, {
    rights: !settings.isLink && settings.copy && settings.copySettings,
    click: {
      type: 'duplicate with sub items'
    },
    icon: 'file-plus',
    text: transFunction('Duplicate node with sub nodes')
  }, {
    rights: !settings.isLink && settings.copy && settings.copyData && settings.copySettings,
    click: {
      type: 'duplicate with sub items and data'
    },
    icon: 'file-plus',
    text: transFunction('Duplicate node with sub nodes and data')
  }, {
    rights: !settings.isLink && settings.deleteNodeOption,
    click: {
      type: 'delete'
    },
    icon: 'x',
    text: settings.deleteNodeLabel || transFunction('Delete node')
  }, {
    rights: !settings.isLink && settings.templateSubmenuItems && settings.templateSubmenuItems.length,
    isTemplateMenu: true,
    icon: 'plus-circle',
    text: transFunction('Add from template'),
    items: settings.templateSubmenuItems
  }];
};