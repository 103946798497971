import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.title,
    "button-ok-text": $data.translate.update,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    onAccept: $options.handleAccept,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.actionData, function (item, key) {
        return _openBlock(), _createBlock(_component_single_select, {
          key: "user_action_group_".concat(key),
          "model-value": $options.getValue(item),
          options: $options.getOptions(item),
          "un-sorted": true,
          "has-reset-button": item.overridden,
          title: item.title,
          class: "mb-4",
          onReset: function onReset($event) {
            return $options.setUpdatedElement(key, -1);
          },
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.setUpdatedElement(key, $event);
          }
        }, null, 8 /* PROPS */, ["model-value", "options", "has-reset-button", "title", "onReset", "onUpdate:modelValue"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}