function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { useCountriesStore } from '@/Store/countriesStore';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ContractPartyInternalSection from '@/components/Organisms/Contracts/ContractPartyInternalSection';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import LinksDepPreview from '@/components/Organisms/Links/LinksDepPreview';
import BadgesList from '@/components/Organisms/BadgesList';
import { getCompanyAssociationTypesMap, COMPANY_INTERNAL_PROPS } from '@/constants/companies/company';
import { filterArrayByListOfPropValues } from '@/utils/Utils';
import ContractRolesMixin from '@/mixins/ContractRolesMixin';
import { getTranslate } from './translate';
export default {
  name: 'ContractPartyInternalPreview',
  components: {
    ContractPartyInternalSection: ContractPartyInternalSection,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    TrafficLight: TrafficLight,
    PreviewModalLink: PreviewModalLink,
    LinksDepPreview: LinksDepPreview,
    BadgesList: BadgesList
  },
  mixins: [ContractRolesMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    company: {
      required: true,
      type: Object,
      note: 'data object'
    },
    contractParty: {
      required: true,
      type: Object,
      note: 'data object'
    },
    selectBoxOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    serviceProviderOptions: {
      required: true,
      type: Array
    },
    companyFunctions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    doraEnabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update', 'copy:section', 'updateFromPreview'],
  data: function data() {
    return {
      updatedCompany: null,
      sections: [],
      companyAssociationTypesById: getCompanyAssociationTypesMap(this.$trans),
      translate: getTranslate['ContractPartyInternalPreview']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useCountriesStore, ['countries'])), {}, {
    trafficLightColor: function trafficLightColor() {
      return this.getTrafficLightColorByObjectTypeAndNumber(this.company.objType, this.company.vatStatus);
    },
    mainInfo: function mainInfo() {
      var fieldMap = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, this.translate.vatNumber, this.company.vatNo || ''), this.translate.parentCompany, this.company.parentName || ''), this.translate.associationToParent, this.companyAssociationTypesById[this.company.companyType] || ''), this.translate.phone, this.company.phone || ''), this.translate.email, this.company.email || ''), this.translate.lei, this.company.lei || ''), this.translate.address, this.company.address || ''), this.translate.cityAndZipcode, this.cityAndZipcode), this.translate.contractRole, {
        component: 'BadgesList',
        props: {
          list: this.contractRoles
        }
      });
      return Object.entries(fieldMap);
    },
    cityAndZipcode: function cityAndZipcode() {
      return "".concat(this.company.city || '', " ").concat(this.company.zipCode || '');
    },
    sectionOptions: function sectionOptions() {
      return {
        serviceProviders: this.serviceProviderOptions,
        companyFunctions: this.companyFunctions,
        countries: this.countries,
        typeOfICTService: this.selectBoxOptions.typeOfICTService,
        binaryYesNo: this.selectBoxOptions.binaryYesNo,
        relianceLevel: this.selectBoxOptions.levelOfReliance,
        sensitivenessOfData: this.selectBoxOptions.sensitivenessOfData
      };
    },
    sectionsValid: function sectionsValid() {
      var _this = this;
      return this.sections.every(function (section) {
        var storageOfData = section.storageOfData;
        var skipFields = [];
        if (storageOfData === 0) {
          skipFields.push(COMPANY_INTERNAL_PROPS.DATA_LOCATION);
        }
        return Object.entries(section).every(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];
          return skipFields.includes(key) || _this.isValidValue(value);
        });
      });
    }
  }),
  watch: {
    contractParty: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.sections = this.prepareSections();
      }
    },
    companyFunctions: function companyFunctions() {
      this.sections = this.prepareSections();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    preparePureSection: function preparePureSection() {
      return {
        title: '',
        dataLocation: [],
        dataManagementLocation: [],
        dataSensitiveness: null,
        functionIds: [],
        relianceLevel: null,
        serviceProviders: [],
        storageOfData: 0,
        typeOfService: [],
        countryOfProvision: []
      };
    },
    prepareSections: function prepareSections() {
      var _this$contractParty,
        _this2 = this;
      if (!this.doraEnabled) return [];
      return (_this$contractParty = this.contractParty) === null || _this$contractParty === void 0 ? void 0 : _this$contractParty.internalSections.map(function (section) {
        var _ref3;
        return _ref3 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref3, COMPANY_INTERNAL_PROPS.TITLE, section.title), COMPANY_INTERNAL_PROPS.DATA_LOCATION, filterArrayByListOfPropValues(_this2.sectionOptions.countries, section.dataLocation, 'value')), COMPANY_INTERNAL_PROPS.DATA_MANAGEMENT_LOCATION, filterArrayByListOfPropValues(_this2.sectionOptions.countries, section.dataManagementLocation, 'value')), COMPANY_INTERNAL_PROPS.COUNTRY_OF_PROVISION, filterArrayByListOfPropValues(_this2.sectionOptions.countries, section.countryOfProvision, 'value')), COMPANY_INTERNAL_PROPS.DATA_SENSITIVENESS, section.dataSensitiveness), COMPANY_INTERNAL_PROPS.FUNCTION_IDS, filterArrayByListOfPropValues(_this2.sectionOptions.companyFunctions, section.functionIds, 'value')), "id", section.id), COMPANY_INTERNAL_PROPS.RELIANCE_LEVEL, section.relianceLevel), COMPANY_INTERNAL_PROPS.SERVICE_PROVIDERS, filterArrayByListOfPropValues(_this2.sectionOptions.serviceProviders, section.serviceProviders, 'value')), COMPANY_INTERNAL_PROPS.STORAGE_OF_DATA, section.storageOfData), _defineProperty(_ref3, COMPANY_INTERNAL_PROPS.TYPE_OF_SERVICE, filterArrayByListOfPropValues(_this2.selectBoxOptions.typeOfICTService, section.typeOfService, 'value'));
      });
    },
    addSection: function addSection() {
      this.sections.push(this.preparePureSection());
    },
    handleSectionUpdate: function handleSectionUpdate(section, index) {
      var _this$contractParty2;
      var updatedSection = this.prepareSectionsToSave(section, index);
      var internalSections = (_this$contractParty2 = this.contractParty) === null || _this$contractParty2 === void 0 ? void 0 : _this$contractParty2.internalSections;
      internalSections[index] = updatedSection;
      this.$emit('update', this.contractParty);
    },
    prepareSectionsToSave: function prepareSectionsToSave(section) {
      var updatedSection = _objectSpread({}, section);
      Object.keys(updatedSection).forEach(function (item) {
        if (updatedSection[item] && Array.isArray(updatedSection[item])) {
          updatedSection[item] = updatedSection[item].map(function (item) {
            return item.value;
          });
        }
      });
      return updatedSection;
    },
    handleDeleteSection: function handleDeleteSection(_ref4, index) {
      var _this3 = this;
      var _ref4$sectionName = _ref4.sectionName,
        sectionName = _ref4$sectionName === void 0 ? '' : _ref4$sectionName;
      this.$confirm(this.translate.deleteSection, this.translate.areYouSureYouWantToDelete(sectionName), function (res) {
        return res && _this3.deleteSection(index);
      });
    },
    deleteSection: function deleteSection(index) {
      var internalSections = _toConsumableArray(this.contractParty.internalSections);
      internalSections.splice(index, 1);
      this.contractParty.internalSections = internalSections;
      this.$emit('update', this.contractParty);
    },
    handleCopySection: function handleCopySection(index) {
      var _this$contractParty3;
      var internalSection = (_this$contractParty3 = this.contractParty) === null || _this$contractParty3 === void 0 ? void 0 : _this$contractParty3.internalSections[index];
      internalSection && this.$emit('copy:section', internalSection);
    },
    dismissPreview: function dismissPreview() {
      this.updatedCompany && this.$emit('updateFromPreview', this.updatedCompany);
      this.updatedCompany = null;
    },
    isValidValue: function isValidValue(value) {
      if (Array.isArray(value)) {
        return !!value.length;
      }
      if (typeof value === 'string') {
        return !!value.trim();
      }
      return value !== null;
    }
  })
};