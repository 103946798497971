function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import * as RiskAPI from '@/api/risk/risk';
import * as Utils from '@/utils/Utils';
import * as RisksUtils from '@/components/Pages/Risks/RisksUtils';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export var RiskListMixin = {
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      riskProject: {},
      period: null,
      risks: [],
      risksBackup: [],
      threats: [],
      levelOfThreats: [],
      vulnerabilities: [],
      probabilities: [],
      tagSelected: null
    };
  },
  computed: {
    periodId: function periodId() {
      return this.period ? this.period.id : null;
    },
    periodTitle: function periodTitle() {
      return this.period ? this.period.title : '';
    }
  },
  methods: {
    filterByTags: function filterByTags(value) {
      var result = [];
      this.risks = _toConsumableArray(this.risksBackup);
      this.tagSelected = value;
      if (value === null) {
        return;
      }
      this.risks.map(function (risk) {
        if (risk.tags) {
          if (risk.tags.find(function (tag) {
            return tag.id === value;
          })) {
            result.push(risk);
          }
        }
      });
      this.risks = result;
    },
    prepareLinkedEntities: function prepareLinkedEntities(ids, data) {
      return data.filter(function (item) {
        return ids.find(function (id) {
          return id === item.id;
        });
      });
    },
    modifyConsequenceRowScores: function modifyConsequenceRowScores(rows, period) {
      var _this = this;
      var result = [];
      if (rows && rows.length) {
        rows.map(function (row) {
          result.push(_objectSpread({
            _valueAsString: _this.period ? Utils.findLabelValue(row, _this.period.consequenceRows) : Utils.findLabelValue(row, period.consequenceRows)
          }, row));
        });
      }
      return result;
    },
    // Period sends not all the time, please note this when update parameters for this function
    formatRisks: function formatRisks(risks, threats, levelOfThreats, vulnerabilities, probabilities, controls, initiatives, period) {
      var _this2 = this;
      return risks.map(function (risk) {
        return _this2.formatRisk(risk, threats, levelOfThreats, vulnerabilities, probabilities, controls, initiatives, period);
      });
    },
    formatRisk: function formatRisk(risk, threats, levelOfThreats, vulnerabilities, probabilities, controls, initiatives, period) {
      var _linkedControls = risk.links && risk.links.control ? this.prepareLinkedEntities(risk.links.control, controls) : [];
      var _linkedInitiatives = risk.links && risk.links.execution ? this.prepareLinkedEntities(risk.links.execution, initiatives) : [];
      var _linkedActivitiesLength = _linkedControls.length + _linkedInitiatives.length;
      if (risk.userIds) {
        for (var _i = 0, _Object$entries = Object.entries(risk.userIds); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];
          if (!risk["".concat(key, "UserIds")]) {
            risk["".concat(key, "UserIds")] = risk.userIds[key] ? [+value] : [];
          }
        }
      }
      return _objectSpread(_objectSpread({
        _expanded: false,
        _threatValueAsString: Utils.getThreatsLabelWithCategories(risk[RiskBaseModelProps.THREAT_IDS], threats),
        _threatLevelValueAsString: Utils.findValueById(levelOfThreats, risk[RiskBaseModelProps.THREAT_LEVEL_VALUE], 'label'),
        _vulnerabilityValueAsString: Utils.findPropsByIds(risk[RiskBaseModelProps.VULNERABILITY_IDS], vulnerabilities, 'label'),
        _probabilityValueAsString: Utils.findValueById(probabilities, risk[RiskBaseModelProps.PROBABILITY_SCORE], 'label'),
        _linkedControls: _linkedControls,
        _linkedInitiatives: _linkedInitiatives,
        _linkedActivitiesLength: _linkedActivitiesLength
      }, risk), {}, {
        residualConsequenceRowScores: this.modifyConsequenceRowScores(risk.residualConsequenceRowScores, period)
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this3 = this;
      return new Promise(function (resolve, reject) {
        RiskAPI.getRiskProjects().then(function (_ref) {
          var list = _ref.list;
          _this3.riskProject = list.find(function (project) {
            return +project.id === +_this3.projectId;
          });
          if (_this3.riskProject && _this3.riskProject.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
            _this3.period = _this3.riskProject.projectPeriods.find(function (period) {
              return period.isCurrent;
            });
            _this3.threats = RisksUtils.formatThreats(_this3.riskProject.threats);
            _this3.levelOfThreats = RisksUtils.formatThreatLevels(_this3.period.threatLevels);
            _this3.vulnerabilities = RisksUtils.formatVulnerabilities(_this3.riskProject.vulnerabilities);
            _this3.probabilities = RisksUtils.formatProbabilities(_this3.period.probabilityRows);
          }
          resolve();
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getRisks: function getRisks(projectId, periodId, filter) {
      return new Promise(function (resolve, reject) {
        RiskAPI.getRisks(projectId, periodId, filter).then(function (_ref2) {
          var list = _ref2.list;
          return resolve(list);
        }).catch(function (error) {
          return reject(error);
        });
      });
    }
  }
};