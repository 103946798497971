import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field_select = _resolveComponent("input-field-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_input_field_select, {
    "input-value": $data.mockData[0].selectedValue,
    "option-value": $data.mockData[0].selectedUnit,
    options: $data.mockData[0].options,
    "input-placeholder": $data.mockData[0].defaultPlaceholder,
    onInputChange: _cache[0] || (_cache[0] = function ($event) {
      return $data.mockData[0].selectedValue = $event;
    }),
    onOptionChange: _cache[1] || (_cache[1] = function ($event) {
      return $data.mockData[0].selectedUnit = $event;
    })
  }, null, 8 /* PROPS */, ["input-value", "option-value", "options", "input-placeholder"]), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_input_field_select, {
    type: "number",
    "input-value": $data.mockData[1].selectedValue,
    "option-value": $data.mockData[1].selectedUnit,
    options: $data.mockData[1].options,
    "input-placeholder": $data.mockData[1].defaultPlaceholder,
    "option-placeholder": $data.mockData[1].defaultPlaceholderSelect,
    onInputChange: _cache[2] || (_cache[2] = function ($event) {
      return $data.mockData[1].selectedValue = $event;
    }),
    onOptionChange: _cache[3] || (_cache[3] = function ($event) {
      return $data.mockData[1].selectedUnit = $event;
    })
  }, null, 8 /* PROPS */, ["input-value", "option-value", "options", "input-placeholder", "option-placeholder"]), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_input_field_select, {
    type: "number",
    "input-value": $data.mockData[2].selectedValue,
    "option-value": $data.mockData[2].selectedUnit,
    options: $data.mockData[2].options,
    "input-placeholder": "Input enabled only if dollar is selected",
    "option-placeholder": $data.mockData[2].defaultPlaceholderSelect,
    "input-enabled": $data.mockData[2].selectedUnit === 'Dollar',
    "with-reset": true,
    onInputChange: _cache[4] || (_cache[4] = function ($event) {
      return $data.mockData[2].selectedValue = $event;
    }),
    onOptionChange: _cache[5] || (_cache[5] = function ($event) {
      return $data.mockData[2].selectedUnit = $event;
    })
  }, null, 8 /* PROPS */, ["input-value", "option-value", "options", "option-placeholder", "input-enabled"])]);
}