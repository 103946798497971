function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useRoute } from 'vue-router';
import { getAllTrees } from '@/api/compliance/processtree';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import { ProcessTreeReport, ProcessTreeThinReport } from '@/components/Pages/ProcessTree';
import { PROCESS_TREE_REPORT_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { ReportTypes } from '@/constants/reports';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ProcessTreeReportWrapper',
  components: {
    RismaTitle: RismaTitle,
    ProcessTreeReport: ProcessTreeReport,
    ProcessTreeThinReport: ProcessTreeThinReport,
    ViewSwitcher: ViewSwitcher
  },
  setup: function setup() {
    var _route$query, _JSON$parse;
    var route = useRoute();
    var query = (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.query;
    var type = query ? (_JSON$parse = JSON.parse(query)) === null || _JSON$parse === void 0 || (_JSON$parse = _JSON$parse.query) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.type : null;
    var typeToView = _defineProperty(_defineProperty({}, ReportTypes.PROCESS_LIBRARY_THIN, viewTypes.RISMA_TABLE), ReportTypes.PROCESS_LIBRARY, viewTypes.COMPACT);
    var viewSelected = getLocalStorageComputed('process_library_report_view', viewTypes.RISMA_TABLE);
    if (type) viewSelected.value = typeToView[type];
    return {
      viewSelected: viewSelected
    };
  },
  data: function data() {
    return {
      viewTypes: viewTypes,
      PROCESS_TREE_REPORT_VIEWS: PROCESS_TREE_REPORT_VIEWS,
      processTreeId: null,
      translate: {
        report: this.$trans('Report'),
        display: this.$trans('Display')
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    return getAllTrees().then(function (response) {
      return _this.processTreeId = response[0].id;
    });
  },
  methods: {
    updateView: function updateView() {
      this.$router.replace({
        query: null
      });
    }
  }
};