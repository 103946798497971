import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withKeys as _withKeys, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  ref: "select",
  class: "relative outline-none group"
};
var _hoisted_2 = ["tabindex"];
var _hoisted_3 = ["placeholder", "disabled"];
var _hoisted_4 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_vue_multiselect_tags = _resolveComponent("risma-vue-multiselect-tags");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_vue_multiselect_dropdown = _resolveComponent("risma-vue-multiselect-dropdown");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "wrapper",
    class: _normalizeClass(["min-h-11 max-h-[138px] w-full overflow-y-auto rounded-md border content-center transition duration-300", [$props.disabled ? 'cursor-default bg-rm-wisp' : 'cursor-pointer bg-rm-white', $options.selectorClasses]]),
    onMouseenter: _cache[14] || (_cache[14] = function ($event) {
      return $data.isBodyHovered = true;
    }),
    onMouseleave: _cache[15] || (_cache[15] = function ($event) {
      return $data.isBodyHovered = false;
    })
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex min-h-[42px] py-2 pl-4 items-center outline-none flex-wrap", [$options.showDeleteIcon ? 'pr-16' : 'pr-10', {
      'gap-y-2': $props.modelValue.length > 1
    }]]),
    onMousedown: _cache[12] || (_cache[12] = _withModifiers(function () {
      return $options.toggle && $options.toggle.apply($options, arguments);
    }, ["prevent"])),
    onKeyup: _cache[13] || (_cache[13] = _withKeys(function () {
      return $options.handleClose && $options.handleClose.apply($options, arguments);
    }, ["esc"]))
  }, [_createVNode(_component_risma_vue_multiselect_tags, {
    tags: $options.selectedTagList,
    tabindex: $props.tabindex,
    disabled: $props.disabled,
    "label-key": $props.labelKey,
    "locked-options": $props.lockedOptions,
    "track-by": $props.trackBy,
    "truncate-length": $props.truncateLength,
    "truncate-tag-text": $props.truncateTagText,
    onRemove: $options.handleRemoveElement
  }, {
    tag: _withCtx(function (_ref) {
      var option = _ref.option;
      return [_renderSlot(_ctx.$slots, "tag", {
        option: option
      })];
    }),
    tagLabel: _withCtx(function (_ref2) {
      var option = _ref2.option;
      return [_renderSlot(_ctx.$slots, "tagLabel", {
        option: option
      })];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["tags", "tabindex", "disabled", "label-key", "locked-options", "track-by", "truncate-length", "truncate-tag-text", "onRemove"]), $options.selectedElementsText ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "glob-h3-sr whitespace-nowrap text-rm-text cursor-pointer hover:underline",
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.handleTagsExpand && $options.handleTagsExpand.apply($options, arguments);
    }, ["prevent", "stop"]))
  }, _toDisplayString($options.selectedElementsText), 33 /* TEXT, NEED_HYDRATION */)) : _createCommentVNode("v-if", true), (_openBlock(), _createElementBlock("div", {
    ref: "wrapsearch",
    key: $data.currentInputFocusRefreshIndex,
    tabindex: $props.disabled ? undefined : $props.tabindex,
    class: _normalizeClass(["outline-none", [!$options.isInputShownAndHighlighted && $options.hasMaxVisibleTagsFeature && $options.hasSearch ? 'h-0 absolute' : 'mt-px', {
      'w-full': !$options.emptyPlaceholder
    }]]),
    onKeyup: [_cache[4] || (_cache[4] = _withKeys(function () {
      return $options.handleClose && $options.handleClose.apply($options, arguments);
    }, ["esc"])), _cache[7] || (_cache[7] = _withKeys(_withModifiers(function () {
      return $options.addFromPointer && $options.addFromPointer.apply($options, arguments);
    }, ["stop"]), ["enter"]))],
    onKeydown: [_cache[5] || (_cache[5] = _withKeys(_withModifiers(function () {
      return $options.moveDown && $options.moveDown.apply($options, arguments);
    }, ["prevent"]), ["down"])), _cache[6] || (_cache[6] = _withKeys(_withModifiers(function () {
      return $options.moveUp && $options.moveUp.apply($options, arguments);
    }, ["prevent"]), ["up"])), _cache[8] || (_cache[8] = _withKeys(_withModifiers(function () {
      return $options.removeLastElement && $options.removeLastElement.apply($options, arguments);
    }, ["stop"]), ["delete"])), _cache[9] || (_cache[9] = function () {
      return $options.onKeyDown && $options.onKeyDown.apply($options, arguments);
    })],
    onFocus: _cache[10] || (_cache[10] = function () {
      return $options.handleInputFocus && $options.handleInputFocus.apply($options, arguments);
    }),
    onBlur: _cache[11] || (_cache[11] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    })
  }, [$options.hasMaxVisibleTagsFeature && $options.hasSearch ? _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 0,
    ref: "search",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.searchValue = $event;
    }),
    type: "text",
    placeholder: $options.emptyPlaceholder ? '' : $props.placeholder,
    disabled: $props.disabled,
    class: _normalizeClass(["text-rm-text outline-none placeholder:text-rm-grey", [$options.widthInputClasses, {
      'glob-h3-sr': !$props.isOpen && !$props.modelValue.length
    }]]),
    onFocus: _cache[2] || (_cache[2] = function () {
      return $options.handleInputFocus && $options.handleInputFocus.apply($options, arguments);
    }),
    onBlur: _cache[3] || (_cache[3] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    })
  }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3)), [[_vModelText, $data.searchValue, void 0, {
    trim: true
  }]]) : $options.showPlaceholderText ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["glob-h3-sr", $props.disabled ? 'text-rm-urban' : 'text-rm-grey'])
  }, _toDisplayString($options.placeholderText), 3 /* TEXT, CLASS */)) : (_openBlock(), _createElementBlock("div", _hoisted_4))], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_2))], 34 /* CLASS, NEED_HYDRATION */)], 34 /* CLASS, NEED_HYDRATION */)), [[_directive_click_outside, $options.handleTagsCollapse]]), $options.showDeleteIcon ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "x",
    tabindex: $props.tabindex,
    class: "mr-2 text-rm-grey hover:text-rm-black hover:rounded-sm hover:bg-rm-grey-light active:text-rm-active-blue stroke-2 absolute top-3 right-9 transition duration-300 cursor-pointer",
    onMousedown: _withModifiers($options.handleReset, ["prevent", "stop"]),
    onKeyup: _withKeys(_withModifiers($options.handleReset, ["stop"]), ["enter"])
  }, null, 8 /* PROPS */, ["tabindex", "onMousedown", "onKeyup"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["flex active:text-rm-active-blue hover:text-rm-text hover:rounded-sm hover:bg-rm-grey-light absolute right-4 top-3 transition duration-300", $options.isIconActive ? 'text-rm-text' : 'text-rm-grey'])
  }, [!$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "chevron-down",
    class: _normalizeClass(["transform duration-500 stroke-2 outline-none w-5 h-5 cursor-pointer", {
      'rotate-180': $props.isOpen
    }]),
    onMousedown: _withModifiers($options.toggle, ["prevent", "stop"])
  }, null, 8 /* PROPS */, ["class", "onMousedown"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $options.showDisabledChevron ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["flex hover:text-rm-text absolute right-4 top-3 transition duration-300", $data.useWrapLineLogic ? 'text-rm-text' : 'text-rm-grey'])
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(["transform duration-500 stroke-2 outline-none w-5 h-5 cursor-pointer", {
      'rotate-180': $data.useWrapLineLogic
    }]),
    onMousedown: _cache[16] || (_cache[16] = _withModifiers(function ($event) {
      return $data.useWrapLineLogic = !$data.useWrapLineLogic;
    }, ["prevent", "stop"]))
  }, null, 8 /* PROPS */, ["class"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $props.isOpen ? (_openBlock(), _createBlock(_component_risma_vue_multiselect_dropdown, {
    key: 2,
    ref: "dropdown",
    "open-direction": $options.openDirectionComputed,
    "filtered-options": $options.filteredOptions,
    "is-empty": !$props.options.length,
    "current-pointer-index": $data.currentPointerIndex,
    "enable-select-all": $props.enableSelectAll && !$data.searchValue,
    "track-by": $props.trackBy,
    "label-key": $props.labelKey,
    "selected-options": $options.selectedOptionsTrackBy,
    "group-select": $props.groupSelect,
    "search-enabled": $options.hasSearch && !$options.hasMaxVisibleTagsFeature,
    "are-all-selected": $options.areAllSelected,
    "search-prop-value": $data.searchValue,
    "locked-options": $props.lockedOptions,
    "all-data-shown": $props.allDataLoaded,
    "is-lazy-loading-shown": $props.isLazyLoadingShown,
    onSelect: $options.handleSelectOption,
    onSelectAll: $options.handleSelectAllButton,
    onSearch: _cache[17] || (_cache[17] = function ($event) {
      return $data.searchValue = $event;
    }),
    onClose: $options.handleClose,
    onInputEvent: $options.handleSearchInputEvent,
    onHover: $options.handleHover,
    onOnMouseleave: $options.resetCurrentPointerIndex,
    onLoad: _cache[18] || (_cache[18] = function ($event) {
      return _ctx.$emit('load');
    }),
    onInputBlur: $options.onBlur
  }, {
    option: _withCtx(function (_ref3) {
      var option = _ref3.option;
      return [_renderSlot(_ctx.$slots, "option", {
        option: option
      })];
    }),
    label: _withCtx(function (_ref4) {
      var option = _ref4.option;
      return [_renderSlot(_ctx.$slots, "label", {
        option: option
      })];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["open-direction", "filtered-options", "is-empty", "current-pointer-index", "enable-select-all", "track-by", "label-key", "selected-options", "group-select", "search-enabled", "are-all-selected", "search-prop-value", "locked-options", "all-data-shown", "is-lazy-loading-shown", "onSelect", "onSelectAll", "onClose", "onInputEvent", "onHover", "onOnMouseleave", "onInputBlur"])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */);
}