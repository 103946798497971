// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr:not(:last-child) td[data-v-1f69e91e] {
  padding: 10px 0;
  border-bottom: 1px solid #BEBEBE;
}
tr:last-child td[data-v-1f69e91e] {
  padding-top: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
