import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "pagination"
};
var _hoisted_2 = {
  class: "pagination-info"
};
var _hoisted_3 = {
  class: "pagination-switch"
};
var _hoisted_4 = {
  class: "pagination-pages"
};
var _hoisted_5 = ["disabled"];
var _hoisted_6 = ["disabled", "onClick"];
var _hoisted_7 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.paginationText) + " " + _toDisplayString($options.paginationFilteredFromText) + _toDisplayString($props.additionalPropText), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [$props.showPrevNextButtons ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    disabled: $data.currentPage <= 1 || $options.pages === 0,
    class: _normalizeClass(["pagination-page label-button", $props.additionalClasses]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.changePage($data.currentPage - 1);
    })
  }, _toDisplayString($data.translate.previous), 11 /* TEXT, CLASS, PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.pages, function (page) {
    return _openBlock(), _createElementBlock(_Fragment, null, [$props.showPrevNextButtons && page === 2 && $options.pages > $data.step && $data.currentPage > $data.step - 1 ? (_openBlock(), _createElementBlock("span", {
      key: "dots-left".concat(page)
    }, " ... ")) : _createCommentVNode("v-if", true), $options.canBeShown(page) ? (_openBlock(), _createElementBlock("button", {
      key: "paginator".concat(page),
      disabled: page === $data.currentPage,
      class: _normalizeClass(["pagination-page", $props.additionalClasses]),
      onClick: function onClick($event) {
        return $options.changePage(page);
      }
    }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true), $props.showPrevNextButtons && page === $options.pages - 1 && $options.pages > $data.step && $data.currentPage + ($data.step - 2) < $options.pages ? (_openBlock(), _createElementBlock("span", {
      key: "dots-right".concat(page)
    }, " ... ")) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */)), $props.showPrevNextButtons ? (_openBlock(), _createElementBlock("button", {
    key: 1,
    disabled: $data.currentPage >= $options.pages || $options.pages === 0,
    class: _normalizeClass(["pagination-page label-button", $props.additionalClasses]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.changePage($data.currentPage + 1);
    })
  }, _toDisplayString($data.translate.next), 11 /* TEXT, CLASS, PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true)])])]);
}