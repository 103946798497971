// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hierarchical-list[data-v-10f899c1] {
  width: 100% !important;
}
.hierarchical-list[data-v-10f899c1]  .risma-title {
  display: inline-block;
}
.hierarchical-list[data-v-10f899c1]  div[class^=indent-] {
  background-color: #CCCCCC;
  width: 100% !important;
}
.hierarchical-list[data-v-10f899c1]  div[class^=indent-] > div .risma-title h3, .hierarchical-list[data-v-10f899c1]  div[class^=indent-] > div .risma-title h1, .hierarchical-list[data-v-10f899c1]  div[class^=indent-] > div .risma-title p {
  color: #FFFFFF;
  margin: 0;
}
.hierarchical-list[data-v-10f899c1]  div[class^=indent-] hr {
  margin: 0px;
  color: #BEBEBE;
}
.hierarchical-list[data-v-10f899c1]  div[class^=indent-] .risma-title {
  padding: 0.2rem;
}
.hierarchical-list[data-v-10f899c1]  .firstLevel {
  background-color: #a6a6a6 !important;
}
.hierarchical-list[data-v-10f899c1]  .firstLevel > div .risma-title h3, .hierarchical-list[data-v-10f899c1]  .firstLevel > div .risma-title h1, .hierarchical-list[data-v-10f899c1]  .firstLevel > div .risma-title p {
  color: #FFFFFF;
  margin: 0;
}
.hierarchical-list[data-v-10f899c1]  .lastLevels {
  background-color: #FFFFFF !important;
}
.hierarchical-list[data-v-10f899c1]  .lastLevels > div .risma-title h3, .hierarchical-list[data-v-10f899c1]  .lastLevels > div .risma-title h1, .hierarchical-list[data-v-10f899c1]  .lastLevels > div .risma-title p {
  color: #4D4D4D !important;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
