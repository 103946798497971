import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_quiz_question = _resolveComponent("quiz-question");
  var _component_draggable = _resolveComponent("draggable");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createNewQuizQuestionnaire,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onCreate,
    onDismiss: $options.handleDismiss
  }, {
    body: _withCtx(function () {
      var _$data$currentQuiz;
      return [_withDirectives(_createElementVNode("div", null, [$data.errorMessages.length ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.errorMessages = [];
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorMessages, function (error, idx) {
            return _openBlock(), _createElementBlock("li", {
              key: idx
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))])];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.currentQuiz.title,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.currentQuiz.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        type: "text",
        class: "mb-4 max-w-full"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), (_$data$currentQuiz = $data.currentQuiz) !== null && _$data$currentQuiz !== void 0 && (_$data$currentQuiz = _$data$currentQuiz.questions) !== null && _$data$currentQuiz !== void 0 && _$data$currentQuiz.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_draggable, _mergeProps({
        modelValue: $data.currentQuiz.questions,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.currentQuiz.questions = $event;
        }),
        tag: "div",
        "component-data": {
          type: 'transition'
        },
        "item-key": "questionNumber",
        handle: ".drag-icon-questions"
      }, $data.DragOptions), {
        item: _withCtx(function (_ref) {
          var element = _ref.element,
            index = _ref.index;
          return [_createVNode(_component_quiz_question, {
            question: element,
            class: "mb-4",
            "question-index": index + 1,
            "list-indexes": $data.alphabeticalList,
            "onUpdate:question": function onUpdateQuestion($event) {
              return $data.currentQuiz.questions[index] = $event;
            },
            "onDelete:question": function onDeleteQuestion($event) {
              return $options.deleteQuestion(index, $event);
            },
            onError: $options.handleQuizQuestionError
          }, null, 8 /* PROPS */, ["question", "question-index", "list-indexes", "onUpdate:question", "onDelete:question", "onError"])];
        }),
        _: 1 /* STABLE */
      }, 16 /* FULL_PROPS */, ["modelValue"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: "cursor-pointer bg-red flex items-center bg-blue-85 text-blue-375 w-full p-4 justify-center",
        onClick: _cache[3] || (_cache[3] = function () {
          return $options.addNewQuestion && $options.addNewQuestion.apply($options, arguments);
        })
      }, [_createElementVNode("span", null, _toDisplayString($data.translate.addNewQuestion), 1 /* TEXT */), _createVNode(_component_feather_icon, {
        icon: "plus"
      })])], 512 /* NEED_PATCH */), [[_vShow, !$props.loadState]]), $props.loadState ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0,
        class: "mt-4"
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"]);
}