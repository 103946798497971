// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-68cdcc52]  .static-table-container {
  width: 100%;
  padding: 0 15px;
}
[data-v-68cdcc52]  .static-table-container .static-table tr {
  border: none;
}
[data-v-68cdcc52]  .static-table-container .static-table tbody:after {
  content: "";
  display: block;
  height: 11px;
}
[data-v-68cdcc52]  .static-table-container .static-table .static-table-head-cell {
  padding: 8px;
  text-align: left;
  min-width: 120px;
}
[data-v-68cdcc52]  .static-table-container .static-table .static-table-head-cell:first-child,[data-v-68cdcc52]  .static-table-container .static-table .static-table-head-cell:nth-child(2),[data-v-68cdcc52]  .static-table-container .static-table .static-table-head-cell:nth-child(3) {
  min-width: 100px;
  min-height: 70px;
}
[data-v-68cdcc52]  .static-table-container .static-table .static-table-head-cell .risma-title > h3 {
  font-weight: 600;
  font-size: 16px;
  max-width: 100%;
}
[data-v-68cdcc52]  .static-table-container .static-table td {
  padding: 8px;
  background-color: #f5f5f2;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}
[data-v-68cdcc52]  .static-table-container .static-table td:first-child {
  border-left: 1px solid #CCCCCC;
}
[data-v-68cdcc52]  .static-table-container .static-table td:last-child {
  border-right: 1px solid #CCCCCC;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
[data-v-68cdcc52]  .static-table-container .static-table .risma-button-btn {
  font-size: 17px;
  padding: 5px 12px;
}
[data-v-68cdcc52]  .static-table-container .static-table .depending-on-traffic-light {
  padding-bottom: 7px;
}
[data-v-68cdcc52]  .static-table-container .static-table .depending-on-field {
  width: 250px;
}
.mypage-list-columns[data-v-68cdcc52] {
  display: flex;
  flex-wrap: wrap;
}
.mypage-list-columns[data-v-68cdcc52]  .preview {
  width: 33%;
  padding: 0 15px;
}
.mypage-list-columns[data-v-68cdcc52]  .preview .preview-cont {
  min-height: 170px;
}
.mypage-list-columns[data-v-68cdcc52]  .preview-wide {
  width: 100%;
}
.information-assets-list[data-v-68cdcc52] {
  display: flex;
  flex-wrap: wrap;
}
.information-assets-list-expanded[data-v-68cdcc52] {
  padding: 0 15px;
}
.information-assets-item-tiles[data-v-68cdcc52] {
  width: 33%;
  padding: 0 15px;
}
.information-assets-item-expanded[data-v-68cdcc52] {
  width: 100%;
}
[data-v-68cdcc52]  .static-table-switcher,[data-v-68cdcc52]  .static-table-wrapper {
  padding: 0 15px;
}
[data-v-68cdcc52]  .static-table-container {
  padding: 0;
}
[data-v-68cdcc52]  .static-table .static-table-head-cell {
  min-width: 250px;
  width: 250px;
}
[data-v-68cdcc52]  .static-table th {
  padding: 1rem 0 !important;
}
[data-v-68cdcc52]  .static-table td {
  padding: 14px 8px !important;
}
[data-v-68cdcc52]  .static-table td ul {
  margin-bottom: 0;
}
[data-v-68cdcc52]  .static-table td:nth-child(2)::first-letter {
  text-transform: uppercase;
}
[data-v-68cdcc52]  .static-table tbody:after {
  content: "";
  display: block;
  height: 11px;
}
[data-v-68cdcc52]  .static-table .medium {
  color: #999;
  font-weight: 600;
  font-size: 16px;
  height: 20px;
}
[data-v-68cdcc52]  .static-table .compact-title {
  margin: 0;
  color: #4385e0;
  background-color: transparent;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  padding-top: 1px;
}
[data-v-68cdcc52]  .static-table .compact-title-traffic {
  float: left;
  margin-right: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
