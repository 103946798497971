import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "mb-2"
};
var _hoisted_2 = {
  key: 0,
  class: "mb-8"
};
var _hoisted_3 = {
  class: "mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("p", _hoisted_1, _toDisplayString($data.translate.thisImprovedWizard), 1 /* TEXT */), $options.hasIAModule ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($data.translate.youCanCreateCompaniesAndAtTheSameTimeLinkThem($options.informationAssetsName)), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString($data.translate.companiesCanBeCreatedManuallyWhereYouEnter), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.toCreateMoreCompaniesYouWillHaveToRepeat), 1 /* TEXT */)])]);
}