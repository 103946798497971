import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "p-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.userGuide,
    type: "big",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), !$data.showUserGuideEdit ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "flex cursor-pointer mb-2 ml-2",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleUserGuideEdit && $options.toggleUserGuideEdit.apply($options, arguments);
    })
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.riskUserGuide,
    type: "medium",
    class: "mr-8"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "edit"
  })])) : _createCommentVNode("v-if", true), $data.showUserGuideEdit ? (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 1,
    type: "all",
    "initial-content": $data.userGuide.contentHtml,
    "read-only": !$options.isEditable,
    "always-open": true,
    class: "ml-2",
    onChanged: $options.contentChanged
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.title,
        type: "medium",
        class: "mb-1"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.userGuide.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.userGuide.name = $event;
        }),
        enabled: $options.isEditable,
        type: "text",
        class: "max-w-650px mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "enabled"]), _createVNode(_component_risma_title, {
        title: $data.translate.content,
        type: "medium",
        class: "mb-1"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["initial-content", "read-only", "onChanged"])) : _createCommentVNode("v-if", true), $options.isEditable && $data.showUserGuideEdit ? (_openBlock(), _createBlock(_component_buttons_row, {
    key: 2,
    data: $options.buttonsRowData,
    class: "mt-4 justify-end"
  }, null, 8 /* PROPS */, ["data"])) : _createCommentVNode("v-if", true), $options.isEditable ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "flex mt-2 ml-2 cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.userGuide.active = !$data.userGuide.active;
    })
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.enableUserGuide,
    type: "medium",
    class: "mr-8"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    "model-value": $data.userGuide.active,
    disabled: $data.isUpdating,
    onInput: $options.toggleUserGuideActive
  }, null, 8 /* PROPS */, ["model-value", "disabled", "onInput"])])) : _createCommentVNode("v-if", true)]);
}