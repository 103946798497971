import GapLogicInformationBoxQuestion from '@/components/Organisms/Compliance/GapLogicInformationBox/GapLogicInformationBoxQuestion';
export default {
  name: 'GapLogicInformationBox',
  introduction: 'a gap-logic-information-box for help, or info',
  description: 'Gap logic information box is a component that gives context to the user behind the logic for answers given in questionnaire, the gap traffic light set and which questions (and its answers) is a consequence for this gap.',
  token: '<gap-logic-information-box :rismaRules={} :rismaRulesQuestionAnswers={} :isAuto="true" />',
  components: {
    GapLogicInformationBoxQuestion: GapLogicInformationBoxQuestion
  },
  props: {
    rismaRules: {
      type: Array,
      required: true,
      note: 'Applied rules'
    },
    rismaRulesQuestionAnswers: {
      type: Array,
      required: true,
      note: 'Question answers'
    },
    isAuto: {
      type: Boolean,
      default: false,
      note: 'bool to determine is traffic light set automatically'
    }
  },
  data: function data() {
    return {
      collapsed: true
    };
  },
  computed: {
    questionAnswersGroups: function questionAnswersGroups() {
      var _this = this;
      var questionAnswers = {};
      this.rismaRulesQuestionAnswers.forEach(function (questionAnswer) {
        var questionAnswerOptions = Object.values(questionAnswer.answers_options);
        questionAnswerOptions.forEach(function (answer) {
          var rismaRules = _this.rismaRules.filter(function (rule) {
            var questionNumberMatched = rule.questionNumber == questionAnswer.questionnumber;
            var questionnaireMatched = rule.questionnaireId == questionAnswer.questionnaireId;
            var questionMatched = answer == rule.answerOption;
            return questionNumberMatched && questionMatched && questionnaireMatched;
          });
          var rismaRule = rismaRules.length ? rismaRules.reduce(function (acc, cur) {
            if (acc.ruleResultTrafficLight == 5 || cur.ruleResultTrafficLight == 5) {
              return acc.ruleResultTrafficLight < cur.ruleResultTrafficLight ? acc : cur;
            }
            return acc.ruleResultTrafficLight > cur.ruleResultTrafficLight ? acc : cur;
          }) : null;
          if (rismaRule) {
            var ruleAnswer = rismaRule.answerOption;
            questionAnswers[questionAnswer.questionnaireId] = questionAnswers[questionAnswer.questionnaireId] || {
              questionAnswers: [],
              questionnaire: questionAnswer.questionnaire
            };
            questionAnswers[questionAnswer.questionnaireId]['questionAnswers'].push({
              trafficlight: rismaRule.ruleResultTrafficLight,
              questionnumber: questionAnswer.questionnumber,
              question: questionAnswer.question,
              help: questionAnswer.help,
              questionAnswer: ruleAnswer
            });
          }
        });
      });
      return questionAnswers;
    },
    type: function type() {
      return this.isAuto ? 'Auto' : 'Manual';
    },
    typeDescription: function typeDescription() {
      return this.isAuto ? 'This traffic light has been automatically set based on the answers given to the following questions:' : 'This traffic light was automatically set, and has afterwards been set manually. The automated traffic light was based on the following questions:';
    },
    questionnaireCount: function questionnaireCount() {
      return Object.keys(this.questionAnswersGroups).length;
    }
  }
};