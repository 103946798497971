import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createSlots as _createSlots, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "nestable-list nestable-group"
};
var _hoisted_2 = {
  key: 0,
  class: "nestable-drag-layer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Placeholder = _resolveComponent("Placeholder");
  var _component_NestableItem = _resolveComponent("NestableItem");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['nestable', "nestable-".concat($props.group), $props.rtl ? 'nestable-rtl' : ''])
  }, [_createElementVNode("ol", _hoisted_1, [_createCommentVNode(" No items in list "), $options.listIsEmpty ? (_openBlock(), _createBlock(_component_Placeholder, {
    key: 0,
    options: $options.itemOptions
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "placeholder", {}, function () {
        return [_cache[0] || (_cache[0] = _createTextVNode("No content"))];
      })];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true), _createCommentVNode(" Render items "), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.modelValue, function (item, index) {
    return _openBlock(), _createBlock(_component_NestableItem, {
      key: item[$props.keyProp],
      index: index,
      item: item,
      options: $options.itemOptions
    }, _createSlots({
      _: 2 /* DYNAMIC */
    }, [_renderList(_ctx.$slots, function (_, name) {
      return {
        name: name,
        fn: _withCtx(function (slotData) {
          return [_renderSlot(_ctx.$slots, name, _mergeProps({
            ref_for: true
          }, slotData))];
        })
      };
    })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["index", "item", "options"]);
  }), 128 /* KEYED_FRAGMENT */))]), $data.dragItem ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("ol", {
    style: _normalizeStyle($options.listStyles),
    class: "nestable-list"
  }, [_createVNode(_component_NestableItem, {
    item: $data.dragItem,
    options: $options.itemOptions,
    "is-copy": true
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.$slots, function (_, name) {
    return {
      name: name,
      fn: _withCtx(function (slotData) {
        return [_renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["item", "options"])], 4 /* STYLE */)])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}