function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getInformationAssets } from '@/api/risma/data';
import { getAll as getControls } from '@/api/controls/controls';
import { getAll as getInitiatives } from '@/api/execution/initiative';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { ObjectTypes } from '@/constants/ObjectTypes';
export var RiskReportsMixin = {
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    projectId: {
      type: String,
      required: true,
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      risks: [],
      systems: [],
      controls: [],
      initiatives: [],
      decoupleFilterFields: [{
        key: 'risks'
      }, {
        key: 'systems'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, ['isSystemsEnabled'])), {}, {
    systemColumn: function systemColumn() {
      return this.isSystemsEnabled ? {
        systems: this.$trans('Systems')
      } : {};
    }
  }),
  methods: {
    getLinkedAssetsAndActivities: function getLinkedAssetsAndActivities() {
      return new Promise(function (resolve, reject) {
        Promise.all([getControls(), getInitiatives(), getInformationAssets()]).then(function (responce) {
          resolve(responce);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    handleGetLinkedAssetsAndActivities: function handleGetLinkedAssetsAndActivities(response) {
      this.controls = response[0].list;
      this.initiatives = response[1].list;
      this.systems = response[2].list.filter(function (item) {
        return item.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM;
      });
    },
    getFormattedRisks: function getFormattedRisks(risks) {
      var formattedTitleRisks = risks.map(function (risk) {
        return _objectSpread(_objectSpread({}, risk), {}, {
          formattedTitle: "".concat(risk.rno, ". ").concat(risk.title)
        });
      });
      return this.getFormattedField(formattedTitleRisks);
    },
    getFormattedControls: function getFormattedControls(risks, controlsAll) {
      var filteredControls = this.getFilteredActivities(risks, controlsAll, 'control');
      var formattedTitleControls = filteredControls.map(function (control) {
        return _objectSpread(_objectSpread({}, control), {}, {
          formattedTitle: "".concat(control.cpath, ". ").concat(control.title)
        });
      });
      return this.getFormattedField(formattedTitleControls);
    },
    getFormattedInitiatives: function getFormattedInitiatives(risks, initiativesAll) {
      var filteredInitiatives = this.getFilteredActivities(risks, initiativesAll, 'execution');
      var formattedTitleInitiatives = filteredInitiatives.map(function (initiative) {
        return _objectSpread(_objectSpread({}, initiative), {}, {
          formattedTitle: "".concat(initiative.ipath, ". ").concat(initiative.title)
        });
      });
      return this.getFormattedField(formattedTitleInitiatives);
    },
    getFormattedSystems: function getFormattedSystems(risks, systemsAll) {
      var filteredSystems = this.getFilteredActivities(risks, systemsAll, 'dataprocessor_system');
      var sortedSystems = filteredSystems.sort(function (a, b) {
        try {
          return +a.uniqueId - +b.uniqueId;
        } catch (_unused) {
          return undefined;
        }
      });
      var formattedTitleSystems = sortedSystems.map(function (system) {
        var prefix = system.uniqueId ? "".concat(system.uniqueId, ". ") : '';
        var formattedTitle = "".concat(prefix).concat(system.name);
        return _objectSpread(_objectSpread({}, system), {}, {
          formattedTitle: formattedTitle
        });
      });
      return this.getFormattedField(formattedTitleSystems);
    },
    getSystemsCell: function getSystemsCell(risks, systems) {
      if (this.isSystemsEnabled) {
        var systemsFormatted = this.getFormattedSystems(risks, systems);
        return {
          systems: systemsFormatted
        };
      }
      return {};
    },
    getFilteredActivities: function getFilteredActivities(risks, activities, linksName) {
      var activitiesIds = risks.reduce(function (activitiesIds, risk) {
        if (risk.linkedActivities && risk.linkedActivities[linksName]) {
          return activitiesIds.concat(risk.linkedActivities[linksName]);
        }
        if (risk.links && risk.links[linksName]) {
          return activitiesIds.concat(risk.links[linksName]);
        }
        return activitiesIds;
      }, []);
      return activities.filter(function (activity) {
        return activitiesIds.indexOf(activity.id) !== -1;
      });
    },
    getFormattedField: function getFormattedField(items) {
      return {
        activities: items,
        field: {},
        fieldText: items.map(function (item) {
          return item.formattedTitle;
        }).join(', ')
      };
    }
  }
};