import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex justify-between items-center mb-4"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 2
};
var _hoisted_4 = {
  class: "flex justify-between items-center"
};
var _hoisted_5 = {
  class: "flex justify-start"
};
var _hoisted_6 = {
  class: "flex items-center justify-start py-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light_modal = _resolveComponent("traffic-light-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_input_field = _resolveComponent("input-field");
  var _component_color_picker = _resolveComponent("color-picker");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [$data.isModalOpened ? (_openBlock(), _createBlock(_component_traffic_light_modal, {
    key: 0,
    modules: $options.modules,
    onCreate: $options.handleTrafficLightCreation,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.isModalOpened = false;
    })
  }, null, 8 /* PROPS */, ["modules", "onCreate"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.trafficLight,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_button, {
    text: $data.translate.create,
    type: "save",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.isModalOpened = true;
    })
  }, null, 8 /* PROPS */, ["text"])])]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.trafficLights, function (group, key, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "traffic_light_general_".concat(key),
      class: _normalizeClass(["mb-4", {
        'border-t border-gray-350': index !== 0
      }])
    }, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
      title: $options.getTrafficLightName(key),
      type: "medium",
      class: "my-4"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
      text: $data.translate.reset,
      type: "util",
      class: "ml-2",
      onClick: function onClick($event) {
        return $options.resetTrafficLightsByModule(key);
      }
    }, null, 8 /* PROPS */, ["text", "onClick"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
      title: $data.translate.trafficLightLabel,
      class: "w-3/10 mr-4",
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
      title: $data.translate.trafficLightColor,
      class: "w-3/10 mr-4",
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), key === $data.MODULES.COMPLIANCE_GAP ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      title: $data.translate.trafficLightTextColor,
      type: "medium",
      class: "w-3/10"
    }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.trafficLights[key], function (trafficLight, trafficKey) {
      return _openBlock(), _createElementBlock("div", {
        key: "traffic_light_element_".concat(trafficKey)
      }, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        "model-value": $data.capitalize(trafficLight.legend),
        class: "w-3/10 mr-4",
        maxlength: 30,
        onBlur: function onBlur($event) {
          return $options.updateTextOnTrafficLight($event, trafficLight);
        }
      }, null, 8 /* PROPS */, ["model-value", "onBlur"]), _createVNode(_component_color_picker, {
        color: trafficLight.color,
        class: "w-3/10 mr-4",
        "adaptive-width": true,
        onClose: function onClose($event) {
          return $options.updateTrafficLight(trafficLight, $event);
        }
      }, null, 8 /* PROPS */, ["color", "onClose"]), key === $data.MODULES.COMPLIANCE_GAP ? (_openBlock(), _createBlock(_component_color_picker, {
        key: 0,
        color: trafficLight.text_color,
        class: "w-3/10 mr-4",
        "adaptive-width": true,
        onClose: function onClose($event) {
          return $options.updateTrafficLight(trafficLight, $event, 'text_color');
        }
      }, null, 8 /* PROPS */, ["color", "onClose"])) : _createCommentVNode("v-if", true), $options.canBeDeleted(trafficLight) && !trafficLight.inUse ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "x",
        class: "cursor-pointer text-red-50",
        onClick: function onClick($event) {
          return $options.handleDeleteTrafficLight(trafficLight);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
    }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]))]);
}