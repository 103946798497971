// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-8ce9199e]  .matrix-inner {
  left: 0;
}
[data-v-8ce9199e]  .vertical svg {
  margin: auto;
}
[data-v-8ce9199e]  .horizontal-wrapper {
  padding-left: 3rem;
}
[data-v-8ce9199e]  .horizontal {
  left: 0;
  margin: auto;
}
[data-v-8ce9199e]  .horizontal svg {
  margin: auto;
}
[data-v-8ce9199e]  .axis-label.horizontal .labeltd {
  height: 5px;
}
[data-v-8ce9199e]  .flex-item {
  margin: 0 1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
