import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_static_table = _resolveComponent("static-table");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.uploadQuestionnaire,
        class: "mb-6"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.questionnaireTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.questionnaireTitle = $event;
        }),
        title: $data.translate.title,
        type: "text",
        placeholder: $data.translate.enterTitle,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
        modelValue: $data.questionnaireTabLabel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.questionnaireTabLabel = $event;
        }),
        title: $data.translate.tabLabel,
        type: "text",
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_risma_file_upload, {
        class: "mb-2 -mt-2",
        "show-uploaded-files": false,
        "single-file-load": true,
        onUpload: $options.uploadFiles
      }, null, 8 /* PROPS */, ["onUpload"]), _createVNode(_component_single_select, {
        modelValue: $data.questionnaireTypeId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.questionnaireTypeId = $event;
        }),
        title: $data.translate.questionnaireType,
        options: $data.questionnaireTypes,
        placeholder: $data.translate.selectQuestionnaireType,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.origin,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.origin = $event;
        }),
        title: $data.translate.origin,
        options: $data.origins,
        placeholder: $data.translate.selectOrigin,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), $data.csvVerified ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        type: "save",
        text: $data.translate.create,
        onClick: $options.saveQuestionnaire
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [$data.parsedQuestions && !$data.loading ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        "dataset-table": $data.parsedQuestions,
        columns: $options.columns,
        "columns-max-width": $data.columnsMaxWidth
      }, null, 8 /* PROPS */, ["dataset-table", "columns", "columns-max-width"])) : _createCommentVNode("v-if", true)]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}