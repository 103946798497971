function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions, mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { getControlByCpathAndId, controlsReview, uploadFormDataFiles, deleteFormDataFiles } from '@/api/controls/controls';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import AttachmentItem from '@/components/Atoms/Attachment/AttachmentItem';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import Notification from '@/components/Molecules/Notification';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { MODULES } from '@/constants/modules';
import { ControlActions } from '@/constants/ControlActions';
import { ActivityLinkModules } from '@/constants/ActivityLinkModules';
import * as Utils from '@/utils/Utils';
import * as formatDate from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
var ACTIONS = {
  PENDING: 'pending',
  APPROVE: 'approve',
  REJECT: 'reject'
};
export default {
  name: 'ControlsReview',
  description: 'Page for review controls',
  components: {
    Notification: Notification,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable,
    RismaFileUpload: RismaFileUpload,
    FeatherIcon: FeatherIcon,
    TextBox: TextBox,
    CollapsiblePanel: CollapsiblePanel,
    AttachmentItem: AttachmentItem,
    ActivityCustomFields: ActivityCustomFields,
    TrafficLight: TrafficLight,
    ButtonsRow: ButtonsRow
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    cpath: {
      type: [Number, String],
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    }
  },
  emits: ['update', 'dataUpdated', 'close', 'update:interactionDisabled'],
  data: function data() {
    return {
      isLoaded: false,
      control: {},
      dataset: [],
      stateLocal: 'controls-review',
      comment: '',
      uploadedFiles: [],
      notificationMessage: null,
      alertInformation: null,
      notificationType: 'error',
      interactionDisabled: false,
      uploadTimes: 0,
      showProgressBar: false,
      uploadingValue: 0,
      formData: new FormData(),
      filesToDelete: [],
      // needed for test
      readers: null,
      translate: getTranslate['ControlsReview']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, ['users'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), {}, {
    columns: function columns() {
      return {
        date: this.translate.date,
        action: this.translate.action,
        user: this.translate.user,
        commentsAndUrl: this.translate.commentsurl,
        attachments: this.translate.attachments,
        effectiveness: this.translate.effectivenessAssessment,
        effectivenessComment: this.translate.effectivenessAssessmentComment
      };
    },
    filteredReviews: function filteredReviews() {
      var _this$control$reviews, _this$control;
      return (_this$control$reviews = (_this$control = this.control) === null || _this$control === void 0 ? void 0 : _this$control.reviews.filter(function (review) {
        return review.action !== ACTIONS.PENDING;
      })) !== null && _this$control$reviews !== void 0 ? _this$control$reviews : [];
    },
    loadDatatables: function loadDatatables() {
      return this.isLoaded && this.filteredReviews.length;
    },
    title: function title() {
      return "".concat(this.translate.reviewsFor, " \"").concat(this.control.cpath, " ").concat(this.control.title, "\"");
    },
    latestReview: function latestReview() {
      var filteredReviewsLength = this.filteredReviews.length;
      return filteredReviewsLength && this.filteredReviews[filteredReviewsLength - 1];
    },
    information: function information() {
      if (this.control.rismaState == 2 || this.control.rismaState == 3) {
        return this.translate.thisReviewHasAlreadyBeenCompleted;
      }
      return '';
    },
    completedCommentsFormat: function completedCommentsFormat() {
      return this.control.completedCommentsFormat ? 'pre' : 'p';
    },
    rightActivityType: function rightActivityType() {
      var _this = this;
      if (!this.activityTypes.length && !this.control.activityTypeId) {
        return null;
      }
      var result = _objectSpread({}, this.activityTypes.find(function (item) {
        return item.id === _this.control.activityTypeId;
      }));
      if (result.fields && result.fields.length) {
        result.fields = result.fields.filter(function (field) {
          return field.activityLinkModule === ActivityLinkModules.CONTROL_COMPLETION;
        });
      }
      return result;
    },
    latestReviewCompletedEfficiencyColor: function latestReviewCompletedEfficiencyColor() {
      var _this$latestReview;
      return this.getTrafficLightColor((_this$latestReview = this.latestReview) === null || _this$latestReview === void 0 ? void 0 : _this$latestReview.completedEfficiency);
    },
    controlReviewCompletedEfficiencyColor: function controlReviewCompletedEfficiencyColor() {
      var _this$control2;
      return this.getTrafficLightColor((_this$control2 = this.control) === null || _this$control2 === void 0 ? void 0 : _this$control2.completedEfficiency);
    },
    buttonsRowData: function buttonsRowData() {
      var disabled = this.interactionDisabled;
      return [{
        text: this.translate.saveAndClose,
        disabled: disabled,
        type: 'util',
        clickEvent: this.saveReview,
        slotName: 'save'
      }, {
        text: this.translate.approve,
        disabled: disabled,
        type: 'save',
        clickEvent: this.approveReview
      }, {
        text: this.translate.reject,
        disabled: disabled,
        type: 'save',
        clickEvent: this.rejectReview
      }];
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    this.getControl().then(function () {
      _this2.createDataset();
      _this2.isLoaded = true;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getControlTrafficLightColorByNumber'])), {}, {
    getControl: function getControl() {
      var _this3 = this;
      return getControlByCpathAndId(this.cpath, this.id).then(function (data) {
        var _data$reviews;
        _this3.control = data;
        var pendingReview = data === null || data === void 0 || (_data$reviews = data.reviews) === null || _data$reviews === void 0 ? void 0 : _data$reviews.find(function (review) {
          return review.action === ACTIONS.PENDING;
        });
        _this3.comment = pendingReview === null || pendingReview === void 0 ? void 0 : pendingReview.comment;
        _this3.uploadedFiles = _this3.prepareFilesList(pendingReview === null || pendingReview === void 0 ? void 0 : pendingReview.files);
      });
    },
    handleRemoveAttachment: function handleRemoveAttachment(id) {
      var fileIndex = this.uploadedFiles.findIndex(function (file) {
        return file.id === id;
      });
      if (fileIndex === -1) return;
      if (!this.uploadedFiles[fileIndex].isCurrent) this.filesToDelete.push(id);
      this.uploadedFiles.splice(fileIndex, 1);
      this.formData.delete("files[".concat(id, "]"));
    },
    deleteFiles: function deleteFiles() {
      var _this4 = this;
      this.filesToDelete.forEach(function (id) {
        return deleteFormDataFiles(_this4.cpath, _this4.id, id);
      });
      this.filesToDelete = [];
    },
    findUserNameById: function findUserNameById(userId) {
      return Utils.findPropsByIds([userId], this.users, 'display_name');
    },
    createDataset: function createDataset() {
      var _this5 = this;
      this.filteredReviews.forEach(function (review) {
        var _trafficLight$label, _review$completedEffi;
        var trafficLight = review.completedEfficiency ? DatatableRenderer.prepareTrafficLightField(review.completedEfficiency, MODULES.CONTROLS) : '';
        _this5.dataset.push({
          date: {
            field: _this5.toLocalDateTime(review.created)
          },
          action: {
            field: _this5.createTranslatedAction(review.action)
          },
          user: {
            field: _this5.findUserNameById(review.userId)
          },
          commentsAndUrl: {
            field: {
              textBefore: review.comment || '',
              url: !review.url || review.url === 'http://' ? '' : review.url,
              title: review.urlTitle || review.url
            },
            fieldText: review.comment
          },
          attachments: {
            field: review.files ? review.files.map(function (file) {
              var isUrl = file.mimeType === 'text/uri-list';
              var data = {
                fileName: file.name,
                url: isUrl ? file.url : "/api/2.0/controls/files/".concat(file.id)
              };
              if (isUrl) data.target = '_blank';
              return data;
            }) : [],
            fieldText: review.files ? review.files.map(function (file) {
              return file.name;
            }).join(', ') : ''
          },
          effectiveness: {
            field: trafficLight,
            fieldText: (_trafficLight$label = trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.label) !== null && _trafficLight$label !== void 0 ? _trafficLight$label : ''
          },
          effectivenessComment: {
            field: (_review$completedEffi = review.completedEfficiencyComment) !== null && _review$completedEffi !== void 0 ? _review$completedEffi : ''
          }
        });
      });
      this.enhanceDataset(this.dataset, this.columns);
    },
    createTranslatedAction: function createTranslatedAction(action) {
      if (action === 'partially_completed') {
        return this.translate.partialCompletion;
      }
      return this.$trans(action);
    },
    toLocalDate: function toLocalDate(date) {
      return formatDate.toLocalDate(date);
    },
    toLocalDateTime: function toLocalDateTime(time) {
      return formatDate.toLocalDateTime(time);
    },
    prepareFilesList: function prepareFilesList() {
      var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return files.map(function (file) {
        return _objectSpread(_objectSpread({}, file), {}, {
          filename: file.name,
          url: "/api/2.0/controls/files/".concat(file.id)
        });
      });
    },
    setInteractionDisabled: function setInteractionDisabled(state) {
      this.interactionDisabled = state;
      this.$emit('update:interactionDisabled', state);
    },
    getUploadedFileId: function getUploadedFileId(index) {
      return index + '_uploaded_' + this.uploadTimes;
    },
    uploadFiles: function uploadFiles(files) {
      var _this6 = this;
      this.uploadTimes += 1;
      files.forEach(function (file, idx) {
        _this6.uploadedFiles.push(_objectSpread(_objectSpread({}, file), {}, {
          url: file.content,
          id: _this6.getUploadedFileId(idx),
          isCurrent: true
        }));
      });
      this.$emit('dataUpdated');
    },
    uploadFormDataFiles: function uploadFormDataFiles(formData) {
      if (!(formData instanceof FormData) || this.isFormDataEmpty(formData)) {
        this.notificationMessage = this.translate.theFileWasNotUploadedPleaseTryAgain;
        this.notificationType = 'error';
        return;
      }
      var idx = 0;
      var _iterator = _createForOfIteratorHelper(formData.values()),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var file = _step.value;
          this.formData.append("files[".concat(this.getUploadedFileId(idx), "]"), file, file.name);
          idx++;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    doSelectedAction: function doSelectedAction(action, callBackFn) {
      var _this7 = this;
      var data = {
        comment: this.comment
      };
      this.setInteractionDisabled(true);
      return controlsReview(this.cpath, this.id, action, data).then(function (response) {
        if (typeof response.objType !== 'undefined') {
          return response;
        }
        _this7.alertInformation = response.message;
      }).then(function (response) {
        var _response$recentRevie;
        if (_this7.filesToDelete.length) _this7.deleteFiles();
        var id = response === null || response === void 0 || (_response$recentRevie = response.recentReview) === null || _response$recentRevie === void 0 ? void 0 : _response$recentRevie.id;
        if (_this7.isFormDataEmpty(_this7.formData) || !id) {
          callBackFn();
          return;
        }
        _this7.showProgressBar = true;
        _this7.formData.append('reviewId', id);
        return uploadFormDataFiles(_this7.cpath, _this7.id, _this7.formData, _this7.showUploadProgress).then(function () {
          return callBackFn();
        }).finally(function () {
          _this7.formData = new FormData();
          _this7.showProgressBar = false;
          _this7.uploadingValue = 0;
          _this7.setInteractionDisabled(false);
        });
      }).catch(function (error) {
        _this7.notificationMessage = error;
        _this7.notificationType = 'error';
      });
    },
    showUploadProgress: function showUploadProgress(_ref) {
      var progress = _ref.progress;
      // so if its not fully loaded - it would be still seen that its not loaded (if file is pending for so long)
      this.uploadingValue = progress - progress * 0.01;
    },
    saveReview: function saveReview() {
      var _this8 = this;
      var callBackFn = function callBackFn() {
        return _this8.$emit('close');
      };
      return this.doSelectedAction(ACTIONS.PENDING, callBackFn);
    },
    approveReview: function approveReview() {
      var _this9 = this;
      var callBackFn = function callBackFn() {
        return _this9.$emit('update', {
          state: ControlActions.review
        });
      };
      return this.doSelectedAction(ACTIONS.APPROVE, callBackFn);
    },
    rejectReview: function rejectReview() {
      var _this10 = this;
      var callBackFn = function callBackFn() {
        return _this10.$emit('update', {
          state: ControlActions.reject
        });
      };
      return this.doSelectedAction(ACTIONS.REJECT, callBackFn);
    },
    commentUpdated: function commentUpdated(e) {
      this.$emit('dataUpdated');
      this.comment = e;
    },
    isFormDataEmpty: function isFormDataEmpty(formData) {
      return !formData.entries().next().value;
    },
    getTrafficLightColor: function getTrafficLightColor(number) {
      if (number === undefined || number === null) return null;
      return this.getControlTrafficLightColorByNumber(number);
    }
  })
};