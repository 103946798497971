import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_date_range_picker, {
    "start-date": $data.rangeStartDate,
    "end-date": $data.rangeEndDate,
    opens: $data.rangeOpens,
    onChanged: $options.changeDates,
    onDiscard: $options.onDateDiscard
  }, null, 8 /* PROPS */, ["start-date", "end-date", "opens", "onChanged", "onDiscard"])]);
}