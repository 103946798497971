import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue";
var _hoisted_1 = ["placeholder"];
var _hoisted_2 = {
  key: 0,
  class: "relative"
};
var _hoisted_3 = ["onClick", "onKeyup"];
var _hoisted_4 = {
  key: 0,
  class: "sticky -bottom-0.3rem flex items-center py-2 justify-between bg-white"
};
var _hoisted_5 = {
  class: "italic"
};
var _hoisted_6 = {
  key: 1,
  class: "py-1 italic text-gray-550"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_label, {
    title: $props.title
  }, null, 8 /* PROPS */, ["title"]), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "relative",
    onKeydown: _cache[4] || (_cache[4] = _withKeys(function () {
      return $options.keyDownOnSearch && $options.keyDownOnSearch.apply($options, arguments);
    }, ["down"])),
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $data.showList = !$data.showList;
    })
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.searchValue = $event;
    }),
    type: "text",
    placeholder: $props.searchPlaceholder,
    class: "w-full rounded-md py-2 pl-3 pr-10 outline-none border-2 focus:border-blue-550 border-gray-350 cursor-pointer focus:placeholder-transparent placeholder-italic",
    onInput: _cache[1] || (_cache[1] = function () {
      return $options.handleInput && $options.handleInput.apply($options, arguments);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelText, $data.searchValue, void 0, {
    trim: true
  }]]), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['stroke-3 text-blue-750 absolute top-0 bottom-0 h-full right-4 transform duration-500 cursor-pointer', {
      'rotate-180': $data.showList
    }])
  }, null, 8 /* PROPS */, ["class"]), _withDirectives(_createVNode(_component_loading_indicator_small, {
    class: "absolute h-full right-4"
  }, null, 512 /* NEED_PATCH */), [[_vShow, $data.isLoading]]), _withDirectives(_createElementVNode("div", {
    ref: "itemsList",
    class: "items-list absolute right-0 left-0 mt-1 py-1 px-2 overflow-auto rounded-md border-2 border-gray-35 bg-white z-10 overflow-x-hidden",
    onKeydown: [_cache[2] || (_cache[2] = _withKeys(_withModifiers(function () {
      return $options.focusNextItem && $options.focusNextItem.apply($options, arguments);
    }, ["stop"]), ["down"])), _cache[3] || (_cache[3] = _withKeys(_withModifiers(function () {
      return $options.focusPrevItem && $options.focusPrevItem.apply($options, arguments);
    }, ["stop"]), ["up"]))]
  }, [_createElementVNode("div", null, [$options.filteredItems && $options.filteredItems.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredItems, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: "item py-1 cursor-pointer focus:bg-gray-350 hover:bg-gray-350 break-words whitespace-pre-line",
      tabindex: "-1",
      onClick: _withModifiers(function ($event) {
        return $options.selected(item, index);
      }, ["stop"]),
      onKeyup: _withKeys(_withModifiers(function ($event) {
        return $options.selected(item, index);
      }, ["stop"]), ["enter"])
    }, [_renderSlot(_ctx.$slots, "displayedName", {
      item: item
    }, function () {
      return [_createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)];
    }, true)], 40 /* PROPS, NEED_HYDRATION */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */)), !$options.allDataShown ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($data.translation.scrollDownForMoreResults), 1 /* TEXT */), $data.isLazyLoading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($data.translation.noResultsMatchingTheSearchCriteria), 1 /* TEXT */))])], 544 /* NEED_HYDRATION, NEED_PATCH */), [[_vShow, $options.showItems]])], 32 /* NEED_HYDRATION */)), [[_directive_click_outside, $options.hideList]])]);
}