export var contact = {
  id: '1',
  name: 'Bruce Wayne',
  description: 'A fictional superhero appearing in American comic books',
  location: ' Gotham City',
  address: 'Wayne house',
  phone: '+1 800 000 000',
  regNo: 4221,
  countryCode: 'United Kingdom',
  countryTransferLegalBasisId: 1,
  contactName: 'Vasya Zolotov',
  email: 'test@i.test'
};