import { defineStore } from 'pinia';
export var useNotificationStore = defineStore('notificationStore', {
  state: function state() {
    return {
      notificationVisible: false,
      notificationHeader: '',
      notificationBody: '',
      notificationType: 'default',
      callback: null,
      options: {}
    };
  },
  actions: {
    handleUserChoice: function handleUserChoice(type) {
      var _this = this;
      return function (header, msg) {
        var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
        _this.notificationHeader = header;
        _this.notificationBody = msg;
        _this.notificationType = type;
        _this.callback = callback;
        _this.options = options;
        _this.handleNotificationEnabling();
      };
    },
    handleNotificationEnabling: function handleNotificationEnabling() {
      this.notificationVisible = true;
    },
    handleNotification: function handleNotification(payload) {
      if (this.callback) {
        this.callback(payload);
      }
      this.notificationVisible = false;
    }
  }
});