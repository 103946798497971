import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "py-2 px-4"
};
var _hoisted_2 = {
  class: "flex items-start justify-between relative"
};
var _hoisted_3 = {
  class: "mr-2"
};
var _hoisted_4 = {
  class: "font-bold cursor-pointer hover:underline"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  class: "flex"
};
var _hoisted_7 = {
  class: "flex pt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showCreateModal ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
    key: 0,
    type: $props.assetType,
    "data-processors": $props.iaList,
    onDismiss: $options.toggleCreateModal,
    onCreated: $options.emitNew
  }, null, 8 /* PROPS */, ["type", "data-processors", "onDismiss", "onCreated"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $props.title,
    class: "mb-5"
  }, null, 8 /* PROPS */, ["title"]), $props.showCreateButton ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "plus",
    height: "30",
    width: "30",
    class: "cursor-pointer",
    onClick: _withModifiers($options.toggleCreateModal, ["stop"])
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), _createElementVNode("ul", {
    style: _normalizeStyle($data.listStyles)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.iaList, function (ia) {
    return _openBlock(), _createElementBlock("li", {
      key: "ia-list-".concat(ia.id),
      class: "flex items-center mb-3"
    }, [_cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "w-2 h-2 self-start flex-shrink-0 bg-blue-750 rounded-full mr-4 mt-2"
    }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_preview_modal_link, {
      activity: ia,
      onUpdated: $options.handleUpdate,
      onDismissModal: function onDismissModal($event) {
        return $options.dismissPreviewModal(ia);
      }
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", _hoisted_4, _toDisplayString(ia.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"]), _createElementVNode("p", {
      class: "description-field",
      innerHTML: _ctx.$truncate(ia.description, 150)
    }, null, 8 /* PROPS */, _hoisted_5)])]);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.totalCountOf + $props.title,
    type: "medium",
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: ': ' + $props.iaList.length,
    type: "medium",
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"])])])]);
}