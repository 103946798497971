// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-modal-wrapper[data-v-2f368bd9] {
  position: fixed;
  left: 15%;
  top: 20%;
  z-index: 10;
  width: 75%;
  height: 75%;
  border-radius: 0.5rem;
}
[data-v-2f368bd9]  .my-controls-list .cards-wrapper h2 {
  margin-bottom: 8px;
  margin-left: 1rem;
  line-height: 40px;
  font-size: 27px;
  font-weight: 600;
}
[data-v-2f368bd9]  .my-controls-list .cards-wrapper h2 span {
  font-weight: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
