import { vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["disabled", "title", "type", "maxlength"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.editedTitle = $event;
    }),
    disabled: $props.disabled,
    class: _normalizeClass([$options.inputClasses, "box-content px-2 py-px border rounded font-medium leading-9"]),
    title: $data.editedTitle,
    type: $props.type,
    maxlength: $props.maxLength,
    onKeydown: _cache[1] || (_cache[1] = function () {
      return $options.validateNumber && $options.validateNumber.apply($options, arguments);
    }),
    onBlur: _cache[2] || (_cache[2] = function () {
      return $options.handleBlur && $options.handleBlur.apply($options, arguments);
    }),
    onInput: _cache[3] || (_cache[3] = function () {
      return $options.handleChange && $options.handleChange.apply($options, arguments);
    }),
    onChange: _cache[4] || (_cache[4] = function () {
      return $options.handleChangeLazy && $options.handleChangeLazy.apply($options, arguments);
    }),
    onKeyup: _cache[5] || (_cache[5] = _withKeys(_withModifiers(function () {
      return $options.blurInputOnEnter && $options.blurInputOnEnter.apply($options, arguments);
    }, ["stop"]), ["enter"])),
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)), [[_vModelDynamic, $data.editedTitle, void 0, {
    trim: true
  }]]);
}