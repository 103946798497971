import i18n from '@/i18n';
export var getTranslate = {
  CreateNewReport: function CreateNewReport() {
    return {
      generateNewReport: i18n.t('Generate new report'),
      registerNode: i18n.t('Register node'),
      selectRegisterNode: i18n.t('Select register node'),
      registerQuestions: i18n.t('Register questions'),
      preliminaryAnalysisNode: i18n.t('Preliminary analysis node'),
      selectPreliminaryAnalysisNode: i18n.t('Select preliminary analysis node'),
      preliminaryAnalysisGaps: i18n.t('Preliminary analysis gaps'),
      assessmentNode: i18n.t('Assessment node'),
      selectAssessmentNode: i18n.t('Select assessment node'),
      assessmentGaps: i18n.t('Assessment gaps'),
      importantOrCriticalNode: i18n.t('Important or critical node'),
      selectImportOrCriticalNode: i18n.t('Select import or critical node'),
      createNewReport: i18n.t('Create new report')
    };
  },
  ReportList: function ReportList() {
    return {
      existingReports: i18n.t('Existing reports'),
      created: i18n.t('Created'),
      createdBy: i18n.t('Created by'),
      updated: i18n.t('Updated'),
      updatedBy: i18n.t('Updated by'),
      addNew: i18n.t('Add new'),
      show: i18n.t('Show'),
      deleteReport: i18n.t('Delete report'),
      areYouSureYouWantToDeleteThisReport: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Report').toLowerCase(), "?")
    };
  },
  ShowReport: function ShowReport() {
    return {
      youHaveUnsavedChangesAreYouSureYouWishTo: i18n.t('You have unsaved changes. Are you sure you wish to discard them?'),
      theChangesYouHaveMadeMayNotHaveBeenSaved: i18n.t('The changes you have made may not have been saved'),
      thisReportIsNotSupportedByYourInternetExpl: i18n.t('This report is not supported by your Internet Explorer browser. Please update to a modern browser, like Chrome, Firefox, Safari, or Edge.'),
      save: i18n.t('Save'),
      exportToWord: i18n.t('Export to word'),
      tableOfContentsWillBeDisplayedHere: i18n.t('Table of contents will be displayed here'),
      report: i18n.t('Report'),
      saveAndLeave: i18n.t('Save and leave')
    };
  }
};