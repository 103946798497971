// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.branch-wrapper[data-v-51fd203b] {
  padding: 1rem 0;
}
.tree-rectangle[data-v-51fd203b] {
  width: 280px;
  height: 34px;
  background-color: rgb(249.4347826087, 249.4347826087, 247.7652173913);
  border: 1px solid #4D4D4D;
  border-radius: 0.4rem;
}
.tree-rectangle.all-except-first[data-v-51fd203b]::before {
  /* horizontal left arrow */
  content: "";
  position: absolute;
  left: -24px;
  border-top: 1px dotted #4D4D4D;
  width: 1.5rem;
}
.tree-rectangle.has-children[data-v-51fd203b]::after {
  content: "";
  position: absolute;
  right: -24px;
  border-top: 1px dotted #4D4D4D;
  width: 1.5rem;
}
.tree-rectangle.ghost[data-v-51fd203b] {
  background-color: rgba(70, 180, 195, 0.2);
  border: 2px dotted #4CB3C0;
}
.tree-rectangle.clone-branch[data-v-51fd203b] {
  border: 2px dashed #4D4D4D;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
