function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getControlDataReport } from '@/api/reports/reports';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { getControlsDataReportFields, getDisabledFiltersByColumns, ControlObjectProps, getDecoupleFilterFields, ControlsDataReportColumnsOrder, columnsMeta } from '@/constants/ControlsReportProps';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { ControlReportsMixin } from '@/components/Pages/Controls/ControlReportsMixin';
import { getCustomFieldsColsFromActivityTypes } from '@/utils/Utils';
export default {
  name: 'ControlDataReports',
  introduction: 'Control Data Reports',
  description: 'This is Page component for /controls2/reports/data',
  token: '<control-reports></control-reports>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaDatatable: RismaDatatable,
    SaveReport: SaveReport,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight
  },
  mixins: [ControlReportsMixin, RismaDatatableDefaultMixin],
  data: function data() {
    return {
      isLoaded: false,
      localStorageRangeKey: ControlLocalStorage.REPORTS_DATA_RANGE,
      stateLocal: ControlLocalStorage.DATA_REPORTS,
      columnsMeta: columnsMeta,
      decoupleFilterFields: getDecoupleFilterFields(),
      reportType: 'controls-data-report',
      ranges: getRangesWithNextThreeMonths(),
      translate: {
        dataReport: this.$trans('Data report'),
        none: this.$trans('None'),
        reportSaved: this.$trans('Report saved'),
        deadlineInterval: this.$trans('Deadline interval')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showOrganisationalGroupsSetting: function showOrganisationalGroupsSetting() {
      return !!this.getSettingValue('risma.organisational_groups');
    },
    showExpectedEvidenceSetting: function showExpectedEvidenceSetting() {
      return !!this.getSettingValue('risma.show_expected_evidence');
    },
    disabledFilterByColumns: function disabledFilterByColumns() {
      var disableFilterForCustomFieldTypes = [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD, CustomFieldTypes.TRAFFIC_LIGHT];
      var result = getDisabledFiltersByColumns();
      Object.entries(this.customFields).forEach(function (field) {
        var _field = _slicedToArray(field, 2),
          key = _field[0],
          value = _field[1];
        if (disableFilterForCustomFieldTypes.includes(value.fieldType)) {
          result.push(key);
        }
      });
      return result;
    },
    reportFields: function reportFields() {
      var result = getControlsDataReportFields();
      if (!this.showOrganisationalGroupsSetting) {
        delete result[ControlObjectProps.ORGANISATION_GROUPS];
      }
      if (!this.showExpectedEvidenceSetting) {
        delete result[ControlObjectProps.EXPECTED_EVIDENCE];
      }
      return result;
    },
    customFields: function customFields() {
      return getCustomFieldsColsFromActivityTypes(this.activityTypes, this.customUnitPrefix);
    },
    columns: function columns() {
      var _this = this;
      var translatedReportFields = {};
      var translatedCustomFields = {};
      Object.entries(this.reportFields).forEach(function (field) {
        var _field2 = _slicedToArray(field, 2),
          key = _field2[0],
          value = _field2[1];
        translatedReportFields[key] = _this.$trans(value);
      });
      Object.entries(this.customFields).forEach(function (field) {
        var _field3 = _slicedToArray(field, 2),
          key = _field3[0],
          value = _field3[1];
        translatedCustomFields[key] = _this.$trans(value.label);
      });
      return _objectSpread(_objectSpread({}, translatedReportFields), translatedCustomFields);
    },
    columnsOrder: function columnsOrder() {
      var result = ControlsDataReportColumnsOrder;
      if (!this.showOrganisationalGroupsSetting) {
        delete result[ControlObjectProps.ORGANISATION_GROUPS];
      }
      if (!this.showExpectedEvidenceSetting) {
        delete result[ControlObjectProps.EXPECTED_EVIDENCE];
      }
      return result;
    },
    trafficLightKeys: function trafficLightKeys() {
      var _this2 = this;
      var list = ['trafficLight'];
      Object.keys(this.customFields).forEach(function (fieldKey) {
        if (_this2.customFields[fieldKey].fieldType === CustomFieldTypes.TRAFFIC_LIGHT) {
          list.push(fieldKey);
        }
      });
      return list;
    }
  }),
  mounted: function mounted() {
    this.initDateRange();
    this.getData().then(this.loadDataFromQuery);
  },
  methods: {
    refreshTable: function refreshTable() {
      this.getDateRange();
      return this.getData();
    },
    getData: function getData() {
      var _this3 = this;
      this.isLoaded = false;
      var deadlines = {
        deadlineAfter: this.deadlineAfter,
        deadlineBefore: this.deadlineBefore
      };
      return getControlDataReport(deadlines).then(function (_ref) {
        var list = _ref.list;
        _this3.dataset = _this3.prepareDataset(list);
        _this3.enhanceDataset(_this3.dataset, _this3.columns);
      }).catch(function () {}).finally(function () {
        _this3.isLoaded = true;
      });
    },
    prepareDataset: function prepareDataset(controls) {
      var _this4 = this;
      var result = [];
      controls.forEach(function (control) {
        var _control$rowsData;
        var baseControl = _this4.prepareItemBaseFields(control);
        if (!((_control$rowsData = control.rowsData) !== null && _control$rowsData !== void 0 && _control$rowsData.length)) return;
        control.rowsData.forEach(function (row) {
          var rowFields = _this4.prepareRowDataFields(row);
          result.push(_objectSpread(_objectSpread({}, baseControl), rowFields));
        });
      });
      return result;
    },
    prepareRowDataFields: function prepareRowDataFields(row) {
      var resultFields = {};
      for (var key in this.customFields) {
        var id = key.split('-')[1];
        if (key.endsWith(this.customUnitPrefix.toLowerCase())) continue;
        var value = row[id] || '';
        if (this.customFields[key].fieldType === CustomFieldTypes.TRAFFIC_LIGHT) {
          value = DatatableRenderer.prepareTrafficLightField(value || 0);
        }
        resultFields[key] = {
          field: value
        };
      }
      return resultFields;
    }
  }
};