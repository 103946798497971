export var singleItem = {
  objType: 'risk-scoring',
  id: 1,
  status: 1,
  version: 'Q1 2017 CRNtest',
  previousId: 42,
  previousName: 'Q4 2016',
  likelihood_scores: 6,
  likelihood_values: {
    1: '<5 %',
    2: '5-20 %',
    3: '20-40 %',
    4: '40-60 %',
    5: '60-80 %',
    6: '>80 %'
  },
  likelihood_labels: [{
    id: 61,
    scoring_id: 7,
    description: 'Lav ',
    likelihood_number: '1'
  }, {
    id: 62,
    scoring_id: 7,
    description: 'Mindre',
    likelihood_number: '2'
  }, {
    id: 63,
    scoring_id: 7,
    description: 'Middel',
    likelihood_number: '3'
  }, {
    id: 64,
    scoring_id: 7,
    description: 'Mere',
    likelihood_number: '4'
  }, {
    id: 65,
    scoring_id: 7,
    description: 'Høj',
    likelihood_number: '5'
  }, {
    id: 66,
    scoring_id: 7,
    description: 'Mest',
    likelihood_number: '6'
  }],
  impact_label: 'Test label',
  impact_scores: 5,
  impact_scale: 'DKK',
  impact_values: {
    1: 'dummy'
  },
  risks: [],
  fields: [{
    objType: 'fields-field',
    id: 46,
    scoringId: 7,
    label: 'Konsekvens',
    fieldtype: 10,
    options: {
      1: {
        index: 1,
        id: 116,
        value: '1'
      },
      2: {
        index: 2,
        id: 117,
        value: '2'
      },
      3: {
        index: 3,
        id: 118,
        value: '3'
      },
      4: {
        index: 4,
        id: 119,
        value: '4'
      },
      5: {
        index: 5,
        id: 120,
        value: '5'
      }
    }
  }, {
    objType: 'fields-field',
    id: 47,
    scoringId: 7,
    label: 'Økonomisk tab',
    fieldtype: 1,
    options: {
      1: {
        index: 1,
        id: 121,
        value: 'under 100 kr. '
      },
      2: {
        index: 2,
        id: 122,
        value: '100 kr. '
      },
      3: {
        index: 3,
        id: 123,
        value: '1000 kr. '
      },
      4: {
        index: 4,
        id: 124,
        value: '10.000 kr'
      },
      5: {
        index: 5,
        id: 125,
        value: 'Over 10.000 kr. '
      }
    }
  }, {
    objType: 'fields-field',
    id: 48,
    scoringId: 7,
    label: 'Image tab',
    fieldtype: 1,
    options: {
      1: {
        index: 1,
        id: 126,
        value: 'Lille påvirkning'
      },
      2: {
        index: 2,
        id: 127,
        value: 'lille til middel'
      },
      3: {
        index: 3,
        id: 128,
        value: 'Middel påvirkning'
      },
      4: {
        index: 4,
        id: 129,
        value: 'Middel til major'
      },
      5: {
        index: 5,
        id: 130,
        value: 'Major påvirkning'
      }
    }
  }, {
    objType: 'fields-field',
    id: 49,
    scoringId: 7,
    label: 'Personlig sikkerhed',
    fieldtype: 1,
    options: {
      1: {
        index: 1,
        id: 131,
        value: 'Mindre personlig skade'
      },
      2: {
        index: 2,
        id: 132,
        value: 'op til 3 tilskadekommende'
      },
      3: {
        index: 3,
        id: 133,
        value: 'Op til 10 tilskadekommende'
      },
      4: {
        index: 4,
        id: 134,
        value: 'dInvaliditet'
      },
      5: {
        index: 5,
        id: 135,
        value: 'Dødsfald'
      }
    }
  }, {
    objType: 'fields-field',
    id: 50,
    scoringId: 7,
    label: 'Fritekst',
    fieldtype: 3
  }, {
    objType: 'fields-field',
    id: 51,
    scoringId: 7,
    label: 'Tekstlinje',
    fieldtype: 2
  }, {
    objType: 'fields-field',
    id: 52,
    scoringId: 7,
    label: 'Tendens',
    fieldtype: 5
  }, {
    objType: 'fields-field',
    id: 53,
    scoringId: 7,
    label: 'Traffiklys',
    fieldtype: 4
  }, {
    objType: 'fields-field',
    id: 54,
    scoringId: 7,
    label: 'Dropdown',
    fieldtype: 1,
    options: {
      1: {
        index: 1,
        id: 136,
        value: 'Valg 1'
      },
      2: {
        index: 2,
        id: 137,
        value: 'Valg 2'
      },
      3: {
        index: 3,
        id: 138,
        value: 'Valg 3'
      }
    }
  }],
  newRisks: [{
    objType: 'risk-risk-limited',
    id: 98,
    title: 'M1199 - organisation',
    scoringId: 7,
    rno: 21,
    primaryOwner: '1',
    url: '/risk/r/7/21'
  }, {
    objType: 'risk-risk-limited',
    id: 99,
    title: 'M1199 - bruger',
    scoringId: 7,
    rno: 22,
    primaryOwner: '50',
    url: '/risk/r/7/22'
  }, {
    objType: 'risk-risk-limited',
    id: 100,
    title: 'M1199 - daglig team',
    scoringId: 7,
    rno: 23,
    primaryOwner: '1',
    url: '/risk/r/7/23'
  }, {
    objType: 'risk-risk-limited',
    id: 101,
    title: 'Fritekst søgning',
    scoringId: 7,
    rno: 24,
    primaryOwner: '1',
    url: '/risk/r/7/24'
  }],
  closedRisks: [],
  movedRisks: [{
    objType: 'risk-risk-limited',
    id: 82,
    title: 'fald i salg',
    scoringId: 7,
    rno: 3,
    primaryOwner: '2',
    url: '/risk/r/7/3',
    rpiCurrent: 2,
    rpiPrevious: 4,
    rpiDelta: 2
  }, {
    objType: 'risk-risk-limited',
    id: 85,
    title: 'risiko 20',
    scoringId: 7,
    rno: 6,
    primaryOwner: '1',
    url: '/risk/r/7/6',
    rpiCurrent: 1,
    rpiPrevious: 2,
    rpiDelta: 1
  }, {
    objType: 'risk-risk-limited',
    id: 90,
    title: 'Risiko5',
    scoringId: 7,
    rno: 11,
    primaryOwner: '48',
    url: '/risk/r/7/11',
    rpiCurrent: 30,
    rpiPrevious: 12,
    rpiDelta: 18
  }, {
    objType: 'risk-risk-limited',
    id: 91,
    title: 'Risiko7',
    scoringId: 7,
    rno: 13,
    primaryOwner: '34',
    url: '/risk/r/7/13',
    rpiCurrent: 0,
    rpiPrevious: 6,
    rpiDelta: 6
  }, {
    objType: 'risk-risk-limited',
    id: 92,
    title: 'Risiko 8',
    scoringId: 7,
    rno: 14,
    primaryOwner: '17',
    url: '/risk/r/7/14',
    rpiCurrent: 0,
    rpiPrevious: 2,
    rpiDelta: 2
  }, {
    objType: 'risk-risk-limited',
    id: 93,
    title: 'Risiko 10',
    scoringId: 7,
    rno: 16,
    primaryOwner: '13',
    url: '/risk/r/7/16',
    rpiCurrent: 4,
    rpiPrevious: 2,
    rpiDelta: 2
  }, {
    objType: 'risk-risk-limited',
    id: 94,
    title: 'Risiko11',
    scoringId: 7,
    rno: 17,
    primaryOwner: '38',
    url: '/risk/r/7/17',
    rpiCurrent: 15,
    rpiPrevious: 10,
    rpiDelta: 5
  }, {
    objType: 'risk-risk-limited',
    id: 95,
    title: 'Risiko 12',
    scoringId: 7,
    rno: 18,
    primaryOwner: '12',
    url: '/risk/r/7/18',
    rpiCurrent: 0,
    rpiPrevious: 4,
    rpiDelta: 4
  }, {
    objType: 'risk-risk-limited',
    id: 96,
    title: 'Risiko 13',
    scoringId: 7,
    rno: 19,
    primaryOwner: '10',
    url: '/risk/r/7/19',
    rpiCurrent: 0,
    rpiPrevious: 3,
    rpiDelta: 3
  }],
  movedRisks2: [{
    objType: 'risk-risk-limited',
    id: 90,
    title: 'Risiko5',
    scoringId: 7,
    rno: 11,
    primaryOwner: '48',
    url: '/risk/r/7/11',
    rpiCurrent: 30,
    rpiPrevious: 12,
    rpiDelta: 18
  }]
};