function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import isEqual from 'lodash/isEqual';
export function removeDuplicates(array) {
  var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var leaveLastMatch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!array.length) {
    return [];
  }
  if (!leaveLastMatch) {
    var seen = new Set();
    return array.filter(function (item) {
      return seen.has(item[field]) ? false : seen.add(item[field]);
    });
  }
  return _toConsumableArray(new Map(array.map(function (item) {
    return [item[field], item];
  })).values());
}
export function areArraysEqual(arr1, arr2) {
  var arr1Length = arr1.length;
  var arr2Length = arr2.length;
  if (arr1Length !== arr2Length) {
    return false;
  }
  return isEqual(arr1, arr2);
}