import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex -mb-1.5px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    to: "/settings/bulk/switch"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.activities), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), $options.isPerformanceReportEnabled ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 0,
    to: "/settings/bulk/frameworkId"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.updateFrameworkIds), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_light_tab_item, {
    to: "/settings/bulk/organisation"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.assignOrganisation), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]);
}