import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { exportToXlsx, exportToCSV } from '@/utils/export/excel';
import { exportTypes } from '@/constants/exportTypes';
export default {
  name: 'RismaExcel',
  introduction: '',
  description: '',
  token: '<risma-excel :dataset-export="dataset" :columns="columns" />',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    datasetExport: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Dataset for printing'
    },
    columns: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'Columns for printing'
    },
    filename: {
      required: false,
      type: String,
      default: 'RISMAbusiness',
      note: 'Excel filename'
    },
    columnsTypes: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Columns with types other than string'
    },
    confirmMessage: {
      required: false,
      type: String,
      default: '',
      note: 'Confirmation popup message to display before exporting'
    },
    handler: {
      required: false,
      type: Function,
      default: null,
      note: 'Requires callback with Promise.resolve({dataset, columns, filename = ""})'
    },
    exportType: {
      required: false,
      type: String,
      default: exportTypes.XLSX,
      validator: function validator(val) {
        return [exportTypes.XLSX, exportTypes.CSV].includes(val);
      }
    }
  },
  emits: ['export'],
  methods: {
    confirmExport: function confirmExport() {
      var _this = this;
      if (this.handler) {
        this.callHandler();
        return;
      }
      if (this.confirmMessage) {
        this.$confirm('', this.confirmMessage, function (res) {
          return res && _this.exportData();
        });
        return;
      }
      this.exportData();
    },
    exportData: function exportData() {
      this.$emit('export', this.datasetExport.length);
      if (this.exportType === exportTypes.CSV) {
        return exportToCSV(this.datasetExport, this.columns, this.filename);
      }
      return exportToXlsx(this.datasetExport, this.columns, this.filename, this.columnsTypes);
    },
    callHandler: function callHandler() {
      var _this2 = this;
      this.handler().then(function (_ref) {
        var dataset = _ref.dataset,
          columns = _ref.columns,
          _ref$filename = _ref.filename,
          filename = _ref$filename === void 0 ? '' : _ref$filename;
        filename = filename || _this2.filename;
        exportToXlsx(dataset, columns, filename);
      });
    }
  }
};