import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex justify-end mb-3"
};
var _hoisted_2 = {
  class: "p-2"
};
var _hoisted_3 = {
  class: "text-center"
};
var _hoisted_4 = {
  class: "flex flex-col min-h-40px justify-center items-center"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 0,
  class: "text-sm"
};
var _hoisted_7 = {
  class: "flex justify-center"
};
var _hoisted_8 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_inline_select = _resolveComponent("inline-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_kpi_parameters = _resolveComponent("kpi-parameters");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createTextVNode(_toDisplayString($data.translate.sortBy) + ": ", 1 /* TEXT */), _createVNode(_component_inline_select, {
    modelValue: $setup.selectedSortingMethod,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.selectedSortingMethod = $event;
    }),
    class: "font-semibold ml-2",
    options: _ctx.sortingOptions
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createVNode(_component_static_table, {
    "dataset-table": $options.dataset,
    columns: $options.columns,
    "columns-max-width": $data.columnsMaxWidth,
    "columns-styles": $options.columnsStyles,
    "is-children-icon-shown": false,
    "item-props-to-show-children": $data.itemPropertyToShowChildren
  }, {
    kpi: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("div", {
        class: "flex flex-col min-w-200px",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"]))
      }, [_createVNode(_component_risma_title, {
        type: "medium",
        truncate: true,
        title: slotData.title,
        editable: true,
        class: "max-w-full",
        onTitleChangeLazy: function onTitleChangeLazy($event) {
          return $options.handleChangeData(slotData.id, 'title', $event);
        }
      }, null, 8 /* PROPS */, ["title", "onTitleChangeLazy"]), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(_component_tiny_mce_box, {
        key: "".concat(slotData.id, "-").concat(slotData.text),
        type: "comment",
        height: "200px",
        width: "300px",
        "initial-content": slotData.text,
        "debounce-timeout": _ctx.$debounceTimeout / 3,
        onChanged: function onChanged() {
          return $options.handleDescriptionChange(slotData, 'description', arguments.length <= 1 ? undefined : arguments[1]);
        }
      }, null, 8 /* PROPS */, ["initial-content", "debounce-timeout", "onChanged"]))])])];
    }),
    parameters: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
        icon: "chevron-down",
        class: _normalizeClass(['m-auto transform duration-500 stroke-2 text-gray-350 hover:text-rm-text cursor-pointer', {
          'rotate-180 text-rm-text': slotData.showChildren
        }]),
        onClick: function onClick($event) {
          return $options.toggleChildren(slotData.index);
        }
      }, null, 8 /* PROPS */, ["class", "onClick"])])];
    }),
    weight: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createElementVNode("div", {
        class: "flex items-center",
        onClick: _cache[2] || (_cache[2] = _withModifiers(function () {}, ["stop"]))
      }, [(_openBlock(), _createBlock(_component_input_field, {
        key: "weight-".concat(slotData.id),
        type: "number",
        min: 0,
        max: 1000,
        "normalize-fn": $options.weightNormalizeFn,
        class: "w-28",
        "model-value": Math.round(slotData.value),
        onBlur: function onBlur($event) {
          return $options.handleChangeWeight($event, slotData);
        }
      }, null, 8 /* PROPS */, ["normalize-fn", "model-value", "onBlur"])), _cache[4] || (_cache[4] = _createElementVNode("span", {
        class: "ml-2"
      }, "%", -1 /* HOISTED */))])];
    }),
    previewScore: _withCtx(function (_ref4) {
      var slotData = _ref4.slotData;
      return [_createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
        class: "border-2 border-transparent focus:outline-none focus-visible:border-blue-450 text-center",
        onClick: _withModifiers(function ($event) {
          return $options.showPreviewModal(slotData);
        }, ["stop"])
      }, [_createVNode(_component_feather_icon, {
        icon: slotData.isShown ? 'eye-off' : 'eye',
        class: "stroke-2 text-gray-350 cursor-pointer hover:text-rm-text"
      }, null, 8 /* PROPS */, ["icon"])], 8 /* PROPS */, _hoisted_5), slotData.isShown ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(slotData.value), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])];
    }),
    enable: _withCtx(function (_ref5) {
      var slotData = _ref5.slotData;
      return [_createElementVNode("div", {
        class: "flex justify-center",
        onClick: _cache[3] || (_cache[3] = _withModifiers(function () {}, ["stop"]))
      }, [_createVNode(_component_risma_toggle, {
        "model-value": slotData.value,
        onInput: function onInput($event) {
          return $options.handleChangeData(slotData.id, 'enabled', $event);
        }
      }, null, 8 /* PROPS */, ["model-value", "onInput"])])];
    }),
    delete: _withCtx(function (_ref6) {
      var slotData = _ref6.slotData;
      return [_createElementVNode("div", _hoisted_7, [_createElementVNode("button", {
        class: "focus:outline-none",
        onClick: _withModifiers(function ($event) {
          return _ctx.$emit('delete', slotData.id);
        }, ["stop"])
      }, [_createVNode(_component_feather_icon, {
        icon: "x",
        class: "stroke-2 text-red-50 cursor-pointer"
      })], 8 /* PROPS */, _hoisted_8)])];
    }),
    parametersView: _withCtx(function (_ref7) {
      var slotData = _ref7.slotData;
      return [_createVNode(_component_kpi_parameters, {
        metadata: slotData.metadata,
        values: slotData.values,
        onUpdate: function onUpdate($event) {
          return $options.handleUpdateKpiParameters(slotData.id, $event);
        }
      }, null, 8 /* PROPS */, ["metadata", "values", "onUpdate"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns", "columns-max-width", "columns-styles", "item-props-to-show-children"])]);
}