import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "alert alert-danger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  return _openBlock(), _createElementBlock("section", null, [$data.error !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($data.error), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([{
      readonly: $props.readonly
    }, "main-process-node-page"])
  }, [_createVNode(_component_tiny_mce_box, {
    headline: $data.translate.description,
    "read-only": $props.readonly,
    "initial-content": _ctx.description,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "read-only", "initial-content"]), $options.currentActivityType ? (_openBlock(), _createBlock(_component_activity_custom_fields, {
    key: 0,
    "activity-type": $options.currentActivityType,
    "custom-field-values": $props.data.customFieldValues || [],
    disabled: $props.readonly,
    class: "mt-4",
    onChanged: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'customFieldValuesParsed',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
}