import StaticTable from '@/components/Molecules/StaticTable';
import { defaultSorting } from '@/utils/sort';
import { getTranslate } from '@/components/Organisms/Compliance/DataFlow/translate';
export default {
  name: 'DataFlowDatatable',
  components: {
    StaticTable: StaticTable
  },
  props: {
    dataFlows: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    questions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      metaOptions: ['legalBasisOptionLabel', 'storagePeriodOptionLabel'],
      columnsMinWidth: {
        dataType: '50%',
        legalBasis: '50%'
      },
      translate: getTranslate['DataFlowDatatable']()
    };
  },
  computed: {
    questionsById: function questionsById() {
      return Object.fromEntries(this.questions.map(function (question) {
        return [question.id, question];
      }));
    },
    dataFlowMetaColumns: function dataFlowMetaColumns() {
      return [this.translate.dataType, this.translate.legalBasis, this.translate.storagePeriod];
    },
    dataFlowMetaDataset: function dataFlowMetaDataset() {
      var _this = this;
      var dataTypesMap = {};
      this.dataFlows.forEach(function (_ref) {
        var dataTypes = _ref.dataTypes;
        dataTypes.forEach(function (dataType) {
          var _question$questionobj, _question$questionobj2;
          var question = _this.questionsById[dataType.questionId];
          if (!question) return;
          var legalBasisQuestionId = (_question$questionobj = question.questionobject) === null || _question$questionobj === void 0 ? void 0 : _question$questionobj.legalBasisQuestionId;
          var storagePeriodQuestionId = (_question$questionobj2 = question.questionobject) === null || _question$questionobj2 === void 0 ? void 0 : _question$questionobj2.retentionQuestionId;
          if (!legalBasisQuestionId && !storagePeriodQuestionId) return;
          if (!dataTypesMap[dataType.id]) {
            dataTypesMap[dataType.id] = {
              dataType: dataType.text
            };
          }
          _this.metaOptions.forEach(function (option) {
            if (!dataTypesMap[dataType.id][option]) {
              dataTypesMap[dataType.id][option] = [];
            }
            if (dataType[option]) {
              dataTypesMap[dataType.id][option].push(dataType[option]);
            }
          });
        });
      });
      return Object.values(dataTypesMap).sort(defaultSorting('dataType'));
    }
  }
};