import i18n from '@/i18n';
export var getTranslate = {
  RiskAssessmentsIsms: function RiskAssessmentsIsms() {
    return {
      riskAssessment: i18n.t('Risk assessment'),
      thisIsTheRiskAssessmentFor: i18n.t('NB! This risk is the risk assessment for'),
      riskValuesMustBeSetFrom: i18n.t('Risk values must be set from the corresponding activity.'),
      underlyingRisksFor: i18n.t('Underlying risks for'),
      riskTitle: i18n.t('Risk title'),
      relevantThreatsFor: i18n.t('Relevant threats for'),
      threat: i18n.t('Threat')
    };
  },
  RiskReportDatatable: function RiskReportDatatable() {
    return {
      residualRisk: i18n.t('Residual risk'),
      title: i18n.t('Title'),
      activityType: i18n.t('Activity type'),
      organisation: i18n.t('Organisation'),
      description: i18n.t('Description'),
      gridStatus: i18n.t('Grid status'),
      threat: i18n.t('Threat'),
      comment: i18n.t('comment'),
      levelOfThreat: i18n.t('Level of threat'),
      vulnerability: i18n.t('Vulnerability'),
      linkedActions: i18n.t('Linked actions'),
      linkedControls: i18n.t('Linked controls'),
      linkedSystems: i18n.t('Linked systems'),
      linkedIncidents: i18n.t('Linked incidents'),
      linkedComplianceNodes: i18n.t('Linked compliance nodes'),
      tags: i18n.t('Tags'),
      parentRisk: i18n.t('Parent risk'),
      threatCategory: i18n.t('Threat category'),
      managementLevel: i18n.t('Management level'),
      riskStatus: i18n.t('Risk status'),
      likelihoodExposure: i18n.t('Likelihood') + ' (' + i18n.t('Exposure') + ')',
      likelihoodValue: i18n.t('Likelihood') + ' ' + i18n.t('value'),
      likelihoodComment: i18n.t('Likelihood comment'),
      impactValue: i18n.t('Impact') + ' ' + i18n.t('value'),
      impactComment: i18n.t('Impact comment'),
      status: i18n.t('Status'),
      previousImpact: i18n.t('Previous impact'),
      previousLikelihood: i18n.t('Previous likelihood'),
      riskGap: i18n.t('Risk gap'),
      likelihoodGross: i18n.t('Likelihood') + ' (' + i18n.t('Gross') + ')',
      impactGross: i18n.t('Impact') + ' (' + i18n.t('Gross') + ')',
      likelihoodTolerance: i18n.t('Likelihood') + ' (' + i18n.t('Tolerance') + ')',
      impactTolerance: i18n.t('Impact') + ' (' + i18n.t('Tolerance') + ')',
      calculationComment: i18n.t('Calculation comment'),
      confidential: i18n.t('Confidential'),
      approvalStatus: i18n.t('Approval status'),
      approvalDate: i18n.t('Approval date'),
      approvedBy: i18n.t('Approved by'),
      linked: i18n.t('Linked'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      primaryRiskOwner: i18n.t('Primary risk owner'),
      rpi: i18n.t('RPI'),
      inherentRisk: i18n.t('Inherent risk'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      closed: i18n.t('Closed'),
      open: i18n.t('Open'),
      approved: i18n.t('Approved'),
      notApproved: i18n.t('Not approved'),
      frameworks: i18n.t('Frameworks')
    };
  },
  RiskReportGrid: function RiskReportGrid() {
    return {
      legend: i18n.t('Legend'),
      displayPreviousPositionRelativeToThePeriod: i18n.t('Display previous position relative to the period'),
      riskReport: i18n.t('Risk report'),
      closed: i18n.t('Closed'),
      notUpdated: i18n.t('Not updated'),
      updated: i18n.t('Updated'),
      new: i18n.t('New'),
      notApproved: i18n.t('Not approved')
    };
  },
  ScoringInfoBox: function ScoringInfoBox() {
    return {
      changesSinceLastScoringPeriod: i18n.t('Changes since last scoring period'),
      newRisks: i18n.t('new risks'),
      newRisk: i18n.t('new risk'),
      movedRisks: i18n.t('moved risks'),
      movedRisk: i18n.t('moved risk'),
      closedRisks: i18n.t('closed risks'),
      closedRisk: i18n.t('closed risk'),
      increasedWith: i18n.t('increased with'),
      decreasedWith: i18n.t('decreased with')
    };
  }
};