import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = ["title"];
var _hoisted_4 = ["width"];
var _hoisted_5 = ["x1", "x2"];
var _hoisted_6 = {
  class: "flex"
};
var _hoisted_7 = ["title"];
var _hoisted_8 = {
  class: "flex"
};
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = ["width"];
var _hoisted_11 = ["x1", "x2"];
var _hoisted_12 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([$props.axis, "axis-label"])
  }, [_createElementVNode("div", _hoisted_1, [$props.axis === $data.GradientTypes.vertical ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", {
    class: "m-0 p-0 break-words",
    title: $props.mainLabel,
    style: _normalizeStyle($props.axisWidth && "width: ".concat($props.axisWidth, "px;"))
  }, _toDisplayString($props.mainLabel), 13 /* TEXT, STYLE, PROPS */, _hoisted_3), $props.showArrows ? (_openBlock(), _createElementBlock("svg", {
    key: 0,
    height: "20px",
    width: $props.axisWidth
  }, [_cache[0] || (_cache[0] = _createElementVNode("defs", null, [_createElementVNode("marker", {
    id: "arrow",
    markerWidth: "10",
    markerHeight: "10",
    refX: "0",
    refY: "3",
    orient: "auto",
    markerUnits: "strokeWidth",
    viewBox: "0 0 20 20"
  }, [_createElementVNode("path", {
    d: "M0,0 L0,6 L9,3 z",
    fill: "#000"
  })])], -1 /* HOISTED */)), _createElementVNode("line", {
    x1: "".concat($options.x1, "px"),
    x2: "".concat($options.x2, "px"),
    y1: "10",
    y2: "10",
    stroke: "#000",
    "stroke-width": "2",
    "marker-end": "url(#arrow)"
  }, null, 8 /* PROPS */, _hoisted_5)], 8 /* PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.truncatedLabels, function (val, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      style: _normalizeStyle($options.labelWidth),
      class: _normalizeClass(["labeltd overflow-hidden", $options.extendedSpaceForLabel ? 'h-3.2rem' : 'h-9']),
      title: $props.labels[idx].label
    }, _toDisplayString(val), 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_7);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_8, [$props.axis === $data.GradientTypes.horizontal ? (_openBlock(), _createElementBlock("div", _hoisted_9, [$props.showArrows ? (_openBlock(), _createElementBlock("svg", {
    key: 0,
    height: "20px",
    width: $props.axisWidth
  }, [_cache[1] || (_cache[1] = _createElementVNode("defs", null, [_createElementVNode("marker", {
    id: "arrow",
    markerWidth: "10",
    markerHeight: "10",
    refX: "0",
    refY: "3",
    orient: "auto",
    markerUnits: "strokeWidth",
    viewBox: "0 0 20 20"
  }, [_createElementVNode("path", {
    d: "M0,0 L0,6 L9,3 z",
    fill: "#000"
  })])], -1 /* HOISTED */)), _createElementVNode("line", {
    x1: "".concat($options.x1, "px"),
    x2: "".concat($options.x2, "px"),
    y1: "10",
    y2: "10",
    stroke: "#000",
    "stroke-width": "2",
    "marker-end": "url(#arrow)"
  }, null, 8 /* PROPS */, _hoisted_11)], 8 /* PROPS */, _hoisted_10)) : _createCommentVNode("v-if", true), _createElementVNode("p", {
    title: $props.mainLabel,
    style: _normalizeStyle($props.axisWidth && "width: ".concat($props.axisWidth, "px;")),
    class: "m-0 p-0 break-words"
  }, _toDisplayString($props.mainLabel), 13 /* TEXT, STYLE, PROPS */, _hoisted_12)])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */);
}