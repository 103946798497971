import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-2"
};
var _hoisted_2 = {
  class: "flex justify-end relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_pagesize = _resolveComponent("risma-pagesize");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_information_assets_simple_view = _resolveComponent("information-assets-simple-view");
  var _component_information_assets_extended_view = _resolveComponent("information-assets-extended-view");
  var _component_pagination = _resolveComponent("pagination");
  var _component_main_well = _resolveComponent("main-well");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.title
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.filterString,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.filterString = $event;
    }), $options.onSearch],
    placeholder: $data.translate.search,
    type: "text",
    class: "w-200px"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onUpdate:modelValue"])]), $data.isLoaded ? (_openBlock(), _createBlock(_component_main_well, {
    key: 0,
    class: "h-auto"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_2, [_createVNode(_component_risma_pagesize, {
        modelValue: $data.pageSize,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
          return $data.pageSize = $event;
        }), $options.setPageSizeLocalState],
        "paging-options": $options.pagingOptions,
        class: "mr-auto mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "paging-options", "onUpdate:modelValue"]), _createVNode(_component_feather_icon, {
        icon: "maximize-2",
        height: "30",
        width: "30",
        class: "cursor-pointer",
        onClick: $options.switchView
      }, null, 8 /* PROPS */, ["onClick"]), $options.canCreate ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "plus",
        height: "30",
        width: "30",
        class: "cursor-pointer",
        onClick: _withModifiers($options.toggleCreateModal, ["stop"])
      }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), $data.showCreateModal ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
        key: 1,
        type: $props.typeId,
        "data-processors": $data.informationAssets,
        "activity-type-id": $options.activityType && $options.activityType.id,
        "default-questionnaire-id": $options.activityType && $options.activityType.defaultQuestionnaireId || '',
        onDismiss: $options.toggleCreateModal,
        onCreated: $options.createIA
      }, null, 8 /* PROPS */, ["type", "data-processors", "activity-type-id", "default-questionnaire-id", "onDismiss", "onCreated"])) : _createCommentVNode("v-if", true)]), !$options.showExtended ? (_openBlock(), _createBlock(_component_information_assets_simple_view, {
        key: 0,
        "type-id": $props.typeId,
        "information-assets": $data.informationAssets,
        users: _ctx.users,
        "activity-type": $options.activityType,
        onRefreshData: _cache[2] || (_cache[2] = function ($event) {
          return $options.getData($props.typeId);
        }),
        onSearch: $options.onSearch
      }, null, 8 /* PROPS */, ["type-id", "information-assets", "users", "activity-type", "onSearch"])) : (_openBlock(), _createBlock(_component_information_assets_extended_view, {
        key: 1,
        "type-id": $props.typeId,
        "information-assets": $data.informationAssets,
        users: _ctx.users,
        "confirm-export-message": $options.confirmExportMessage,
        "confirm-print-message": $options.confirmPrintMessage,
        class: "mt-5",
        onRefreshData: _cache[3] || (_cache[3] = function ($event) {
          return $options.getData($props.typeId);
        }),
        onBackendSort: $options.backendSortRequest,
        "onUpdate:showChildren": _cache[4] || (_cache[4] = function ($event) {
          return $data.childrenCountOnTheList = $event;
        })
      }, null, 8 /* PROPS */, ["type-id", "information-assets", "users", "confirm-export-message", "confirm-print-message", "onBackendSort"])), _createVNode(_component_pagination, {
        total: $data.totalAssets,
        "per-page": $data.pageSize,
        "show-prev-next-buttons": true,
        "additional-prop-text": $options.additionalPaginationText,
        onChange: $options.pageChange
      }, null, 8 /* PROPS */, ["total", "per-page", "additional-prop-text", "onChange"])];
    }),
    _: 1 /* STABLE */
  })) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }))]);
}