function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { addKitchenChildren } from '@/constants/dev/helper';
import DevOverviewTabs from '@/constants/dev/overview/Tabs';
import { KitchenGeneralComponents } from '@/constants/dev/overview/KitchenGeneralComponents';
import * as GeneralComponents from '@/components/Pages/Dev/Overview/General';
import { KitchenGDPRComponents } from '@/constants/dev/overview/KitchenGDPRComponents';
import * as GDPRComponents from '@/components/Pages/Dev/Overview/GDPR';
import { KitchenISMSComponents } from '@/constants/dev/overview/KitchenISMSComponents';
import * as ISMSComponents from '@/components/Pages/Dev/Overview/ISMS';
export default [{
  path: '/dev/overview/general/',
  tabs: DevOverviewTabs,
  listComponents: KitchenGeneralComponents ? _toConsumableArray(KitchenGeneralComponents).sort() : [],
  children: addKitchenChildren(KitchenGeneralComponents || [], GeneralComponents, 'Overview')
}, {
  path: '/dev/overview/gdpr/',
  tabs: DevOverviewTabs,
  listComponents: KitchenGDPRComponents ? _toConsumableArray(KitchenGDPRComponents).sort() : [],
  children: addKitchenChildren(KitchenGDPRComponents || [], GDPRComponents, 'Overview')
}, {
  path: '/dev/overview/isms/',
  tabs: DevOverviewTabs,
  listComponents: KitchenISMSComponents ? _toConsumableArray(KitchenISMSComponents).sort() : [],
  children: addKitchenChildren(KitchenISMSComponents || [], ISMSComponents, 'Overview')
}];