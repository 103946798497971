import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "text-title-small mb-4"
};
var _hoisted_3 = {
  key: 2,
  class: "flex flex-col flex-grow"
};
var _hoisted_4 = {
  key: 0,
  class: "text-title-small"
};
var _hoisted_5 = {
  class: "flex flex-grow"
};
var _hoisted_6 = {
  class: "text-blue-750 m-auto text-6xl font-semibold"
};
var _hoisted_7 = {
  key: 3,
  class: "flex flex-grow"
};
var _hoisted_8 = {
  class: "text-blue-750 m-auto text-6xl font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_info_box = _resolveComponent("info-box");
  return _openBlock(), _createBlock(_component_info_box, {
    title: $options.infoBoxTitle,
    link: $options.infoBoxLink,
    class: "flex flex-col flex-grow"
  }, {
    default: _withCtx(function () {
      return [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : $options.isRiskAssessedSystem ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, [_createElementVNode("strong", null, _toDisplayString($options.riskAssessedSystems.length), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($data.translate.of) + " ", 1 /* TEXT */), _createElementVNode("strong", null, _toDisplayString($data.systems.length), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($data.translate.systemsHaveBeenRiskAssessed), 1 /* TEXT */)]), _createVNode(_component_risma_doughnut_chart, {
        class: "m-auto h-52",
        "chart-max-width": "200px",
        cover: false,
        datasets: $options.riskAssessedPieChart.datasets,
        "show-data-labels": true,
        "percentage-value": false,
        "use-default-export": true
      }, null, 8 /* PROPS */, ["datasets"])])) : $options.isNoRiskAssessed ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$options.riskApprovalEnabled ? (_openBlock(), _createElementBlock("p", _hoisted_4, [_createElementVNode("strong", null, _toDisplayString($data.approvedRisksNumber), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($data.translate.ofYourRisksAssessmentsHaveBeenApproved), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString($options.riskAssessedSystems.length), 1 /* TEXT */)])])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString($data.complianceNodesCount), 1 /* TEXT */)]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "link"]);
}