// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot-wrapper[data-v-0f4e7e4f] {
  display: inline-block;
}
.dot-wrapper .dot[data-v-0f4e7e4f] {
  height: 20px;
  width: 20px;
  margin: 0;
  border-radius: 50%;
  border: #FFFFFF solid 1px;
  text-align: center;
}
.dot-wrapper .hover-container[data-v-0f4e7e4f] {
  position: absolute;
  z-index: 1;
  padding: 0px 5px;
  min-width: 120px;
  background: #FFFFFF;
  border: 1px solid #000000;
  color: #000000;
  font-size: 0.7rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
