// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-details-associations[data-v-c7f6fb34]  .small {
  margin-bottom: 5px;
  font-weight: 600;
  color: #4D4D4D;
}
.control-details-associations[data-v-c7f6fb34]  .multiselect__single,
.control-details-associations[data-v-c7f6fb34]  .multiselect__input {
  font-size: 14px;
  font-style: italic;
  color: #4D4D4D;
}
.control-details-associations.inherited-from-mother[data-v-c7f6fb34]  .link-item-tag {
  white-space: nowrap;
}
.control-details-associations.inherited-from-mother[data-v-c7f6fb34]  .link-item-tag a {
  pointer-events: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
