import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/svg/ai_minus.svg';
var _hoisted_1 = {
  class: "min-h-[38px] flex justify-between px-4 bg-rm-dark-blue"
};
var _hoisted_2 = {
  class: "align-baseline flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "flex items-center"
  }, [_createElementVNode("h2", {
    class: "mr-2 glob-h2-m text-white glob-h2"
  }, "RISMA AI Partner")], -1 /* HOISTED */)), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default"), _createElementVNode("button", {
    tabindex: "3",
    class: "w-6 h-6 rounded hover:bg-opacity-20 hover:bg-rm-white ml-2 flex items-center justify-center",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('minimize');
    })
  }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
    src: _imports_0,
    width: "16",
    height: "16",
    alt: "ai minimize"
  }, null, -1 /* HOISTED */)]))])]);
}