// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field-container.error[data-v-2dd2876a]  .input-field input {
  border-color: #B03D00;
}
.input-field-container.error[data-v-2dd2876a]  .custom-select-inner {
  border-color: #B03D00;
}
.input-field-container.error[data-v-2dd2876a]  .select-selected-value > div {
  border-color: #B03D00;
}
.validation-error[data-v-2dd2876a] {
  color: #B03D00;
  margin: 0 0 0.5rem;
  padding: 4px 0 0;
  display: block;
}
.group-field-values .input-field-container[data-v-2dd2876a] {
  position: relative;
  margin: 0 0 0.5rem;
  padding-right: 1.5rem;
}
.group-field-values .input-field-container .remove-option[data-v-2dd2876a] {
  position: absolute;
  right: 2px;
  top: 11px;
  font-size: 18px;
  cursor: pointer;
}
.actions[data-v-2dd2876a] {
  display: flex;
  margin: 20px 0 0 0;
  justify-content: flex-end;
}
.actions[data-v-2dd2876a]  .risma-button-btn {
  display: block;
  align-content: center !important;
  min-width: 130px !important;
  font-size: 17px;
}
.items-group[data-v-2dd2876a] {
  margin: 0 0 1rem;
}
.items-group[data-v-2dd2876a]  .risma-title {
  margin: 0 0 5px;
}
.items-group[data-v-2dd2876a]  .risma-title h3 {
  font-size: 16px;
}
.table-cell[data-v-2dd2876a] {
  overflow-wrap: break-word;
  padding: 0.75rem;
  text-align: left;
  line-height: 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
