function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
export default {
  name: 'Consequence',
  introduction: '',
  description: '',
  token: '<consequence />',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField
  },
  mixins: [],
  props: {
    consequenceLevels: {
      type: Number,
      required: false,
      default: 0,
      note: 'Consequence levels'
    },
    consequenceRows: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Consequence rows'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Read values only (no Add row / Add column)'
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Support click on items? (in readonly state)'
    },
    rowKey: {
      type: String,
      required: false,
      default: 'impactColumns',
      note: 'needed for probability table that is the same but with likelihoodColumns'
    },
    identicalColumns: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'needed for consequence and probability so they would have same columns'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      deleteCallback: null,
      colors: Colors,
      columns: [1, 2, 3, 4],
      columnDeletableFrom: 3,
      maxColumns: 10,
      maxRows: 11,
      maxRowsReached: false,
      items: [{
        label: '',
        children: [],
        id: null,
        deletable: false
      }],
      timeout: null,
      translate: getTranslate['Consequence']()
    };
  },
  computed: {
    notApplicableLabel: function notApplicableLabel() {
      return this.translate.na;
    },
    limitReached: function limitReached() {
      return this.maxColumns <= this.columns.length;
    }
  },
  watch: {
    identicalColumns: {
      deep: true,
      handler: function handler(value) {
        this.prepareItemsFromIdentical(value);
      }
    },
    consequenceRows: {
      deep: true,
      handler: function handler() {
        if (this.identicalColumns.length) {
          this.columns = [];
          this.refreshData();
        }
      }
    }
  },
  beforeMount: function beforeMount() {
    this.refreshData();
  },
  methods: {
    prepareItemsFromIdentical: function prepareItemsFromIdentical(identical) {
      var _this = this;
      identical.forEach(function (item, index) {
        if (_this.items[index]) {
          if (item.index > _this.items[index].index) {
            _this.items.splice(index, 1);
          } else {
            _this.items[index].label = item.label;
          }
        } else {
          _this.addOption({
            index: item.index,
            label: item.label
          });
        }
      });
      if (this.items.length > identical.length) {
        this.items.splice(identical.length, this.items.length - identical.length);
      }
    },
    refreshData: function refreshData() {
      this.prepareData();
      if (!this.consequenceRows.length) {
        this.initDefaultChildren();
      }
      if (this.identicalColumns.length) {
        this.prepareItemsFromIdentical(this.identicalColumns);
      }
    },
    prepareData: function prepareData() {
      var _this2 = this;
      if (this.consequenceLevels) {
        this.columns = this.prepareColumns(this.consequenceLevels);
      }
      if (this.consequenceRows.length) {
        this.items = [];
        this.consequenceRows.map(function (row, index) {
          var options = Utils.getProperSelectedOptions(row[_this2.rowKey]);
          _this2.items.push({
            id: row.id,
            label: row.label,
            deletable: index > 0,
            index: row.index,
            children: _this2.populateExistChildren(options, row)
          });
        });
      }
    },
    populateExistChildren: function populateExistChildren(options, row) {
      var _this3 = this;
      var result = this.prepareChildren();
      result.map(function (item, index) {
        // Because we have Not applicable zero column
        if (_this3.readonly) {
          index -= 1;
        }
        if (item.column === row.selectedColumn) {
          item.isActive = true;
        }
        if (item.column !== 0) {
          if (options[index]) {
            item.value = options[index].label;
          }
        }
      });
      return result;
    },
    initDefaultChildren: function initDefaultChildren() {
      var _this4 = this;
      this.items.map(function (item) {
        item.children = _this4.prepareChildren();
      });
    },
    prepareChildren: function prepareChildren() {
      var result = this.columns.map(function (column) {
        return {
          column: column,
          value: '',
          isActive: false
        };
      });
      if (this.readonly) {
        result.unshift({
          column: 0,
          value: this.notApplicableLabel,
          isActive: false
        });
      }
      return result;
    },
    prepareColumns: function prepareColumns(n) {
      return Array(n).fill().map(function (item, index) {
        return index + 1;
      });
    },
    addOption: function addOption(optionalData) {
      if (this.items.length < this.maxRows) {
        var lastElem = this.items[this.items.length - 1];
        var newElem = JSON.parse(JSON.stringify(lastElem));
        if (optionalData) {
          newElem = _objectSpread(_objectSpread({}, newElem), optionalData);
        } else {
          newElem.index += 1;
        }
        newElem.deletable = true;
        delete newElem.id;
        this.items.push(newElem);
        this.emitState();
      }
      this.checkMaxRows();
    },
    checkMaxRows: function checkMaxRows() {
      if (this.items.length < this.maxRows) {
        this.maxRowsReached = false;
      } else {
        this.maxRowsReached = true;
      }
      this.$forceUpdate();
    },
    addNewColumn: function addNewColumn() {
      var lastElem = this.columns[this.columns.length - 1],
        nextElem = ++lastElem;
      if (lastElem <= this.maxColumns) {
        this.columns.push(nextElem);
        this.addNewChildren(nextElem);
      }
      this.emitState();
    },
    emitState: function emitState() {
      var _this5 = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        _this5.$emit('changed', {
          columns: _this5.columns.length,
          items: _this5.items
        });
      }, 100);
    },
    addNewChildren: function addNewChildren(val) {
      this.items.map(function (item) {
        item.children.push({
          column: val,
          value: ''
        });
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      this.deleteCallback();
      this.deleteCallback = null;
    },
    removeOption: function removeOption(item) {
      var _this6 = this;
      this.deleteCallback = function () {
        _this6.items.splice(_this6.items.indexOf(item), 1);
        _this6.checkMaxRows();
        _this6.emitState();
      };
      this.$confirm(this.translate.deleteRow, this.translate.areYouSureYouWantToDeleteThisRow, function (res) {
        return res && _this6.onDeleteConfirm();
      });
    },
    removeColumn: function removeColumn(val) {
      var _this7 = this;
      this.deleteCallback = function () {
        _this7.columns.splice(_this7.columns.indexOf(val), 1);
        _this7.setProperColumnIndexes();
        _this7.items.map(function (item) {
          item.children.map(function (child, childIndex) {
            if (child.column === val) {
              item.children.splice(childIndex, 1);
            }
          });
          _this7.setProperChildrenIndexes(item.children);
        });
        _this7.emitState();
      };
      this.$confirm(this.translate.deleteColumn, this.translate.areYouSureYouWantToDeleteThisColumn, function (res) {
        return res && _this7.onDeleteConfirm();
      });
    },
    setProperColumnIndexes: function setProperColumnIndexes() {
      this.columns = this.columns.map(function (item, index) {
        return index + 1;
      });
    },
    setProperChildrenIndexes: function setProperChildrenIndexes(data) {
      data.map(function (child, childIndex) {
        child.column = childIndex + 1;
      });
    },
    isDeletableColumn: function isDeletableColumn(elem) {
      return elem > this.columnDeletableFrom;
    },
    onLabelClick: function onLabelClick(children, currentChild) {
      if (!this.clickable) {
        return;
      }
      children.map(function (item) {
        item.isActive = false;
      });
      currentChild.isActive = true;
      this.emitState();
    }
  }
};