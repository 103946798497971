import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded rounded-l-none p-4"
};
var _hoisted_2 = {
  class: "flex flex-wrap justify-center lg:flex-nowrap mb-12 -mx-4"
};
var _hoisted_3 = {
  class: "px-4 w-650px"
};
var _hoisted_4 = {
  class: "px-4 lg:flex-grow w-650px min-h-375px"
};
var _hoisted_5 = {
  class: "overflow-x-scroll overflow-y-hidden"
};
var _hoisted_6 = {
  key: 1,
  class: "bg-gray-50 border border-gray-50"
};
var _hoisted_7 = {
  class: "block"
};
var _hoisted_8 = {
  colspan: "3",
  class: "h-10"
};
var _hoisted_9 = {
  colspan: "5",
  class: "h-10"
};
var _hoisted_10 = ["title"];
var _hoisted_11 = ["title"];
var _hoisted_12 = ["title"];
var _hoisted_13 = ["title"];
var _hoisted_14 = ["title"];
var _hoisted_15 = {
  class: "block overflow-x-hidden overflow-y-auto"
};
var _hoisted_16 = {
  class: "text-left"
};
var _hoisted_17 = {
  class: "bg-rm-urban text-white text-center"
};
var _hoisted_18 = {
  class: "bg-blue-250 text-white text-center"
};
var _hoisted_19 = {
  class: "bg-orange-50 text-white text-center"
};
var _hoisted_20 = ["title"];
var _hoisted_21 = {
  key: 2,
  class: "text-center font-semibold py-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_risma_selector_bar = _resolveComponent("risma-selector-bar");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.errorMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans(_ctx.errorMessage)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_risma_selector_bar, {
    key: 1,
    ref: "filter",
    "project-id": $props.projectId,
    selectors: $options.selectors,
    users: _ctx.users,
    orgs: _ctx.orgs,
    nodes: _ctx.nodes,
    tags: _ctx.tags,
    onListChange: _ctx.fetchData,
    onReset: _cache[1] || (_cache[1] = function ($event) {
      return $setup.checkboxState = false;
    })
  }, {
    footer: _withCtx(function () {
      return [_createVNode(_component_checkboxes, {
        options: $options.displayQuestionsCheckboxes,
        onUpdated: $options.updateCheckbox
      }, null, 8 /* PROPS */, ["options", "onUpdated"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["project-id", "selectors", "users", "orgs", "nodes", "tags", "onListChange"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h2", {
    class: _normalizeClass(["mb-6", $data.headingClasses])
  }, _toDisplayString($data.translate.questionsOverview), 3 /* TEXT, CLASS */), !_ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_doughnut_chart, {
    key: 1,
    datasets: $options.pieChartData.datasets,
    labels: $options.pieChartData.labels,
    "show-data-labels": true,
    "percentage-value": false
  }, null, 8 /* PROPS */, ["datasets", "labels"]))]), _createElementVNode("div", _hoisted_4, [_createElementVNode("h2", {
    class: _normalizeClass($data.headingClasses)
  }, _toDisplayString($data.translate.questionsPerHeadline), 3 /* TEXT, CLASS */), !_ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_bar_chart, {
    key: 1,
    labels: $options.stackedChartData.labels,
    datasets: $options.stackedChartData.datasets,
    "show-legend": true,
    "show-data-labels": true,
    "items-per-scroll": 5,
    "min-bar-length": 12,
    "legend-position": "bottom",
    type: "Stacked"
  }, null, 8 /* PROPS */, ["labels", "datasets"]))])]), _createElementVNode("h2", {
    class: _normalizeClass($data.headingClasses)
  }, _toDisplayString($data.translate.questionsByProcesses) + " (" + _toDisplayString($options.filteredNodes.length) + ") ", 3 /* TEXT, CLASS */), !_ctx.isLoading ? (_openBlock(), _createBlock(_component_input_field, {
    key: 2,
    modelValue: $data.searchField,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.searchField = $event;
    }),
    modelModifiers: {
      trim: true
    },
    placeholder: $data.translate.search,
    type: "search",
    class: "w-350px ml-auto mt-4"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_ctx.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("table", _hoisted_6, [_createElementVNode("thead", _hoisted_7, [_createElementVNode("tr", null, [_cache[3] || (_cache[3] = _createElementVNode("th", {
    class: "bg-transparent"
  }, null, -1 /* HOISTED */)), _createElementVNode("th", _hoisted_8, _toDisplayString($data.translate.numberOfAnswers), 1 /* TEXT */), _createElementVNode("th", _hoisted_9, _toDisplayString($data.translate.answersByHeadline), 1 /* TEXT */)]), _createElementVNode("tr", null, [_createElementVNode("th", {
    title: "".concat($data.translate.node, " & ").concat($data.translate.questionnaire),
    class: "align-top text-left bg-gray-350"
  }, [_createElementVNode("p", null, _toDisplayString($data.translate.node), 1 /* TEXT */), _createElementVNode("small", null, _toDisplayString($data.translate.questionnaire), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_10), _createElementVNode("th", {
    title: $data.translate.notApplicableQuestions,
    class: "align-top bg-rm-urban text-white text-center"
  }, _toDisplayString($data.translate.notApplicableQuestions), 9 /* TEXT, PROPS */, _hoisted_11), _createElementVNode("th", {
    title: $data.translate.answeredQuestions,
    class: "align-top bg-blue-250 text-white text-center"
  }, _toDisplayString($data.translate.answeredQuestions), 9 /* TEXT, PROPS */, _hoisted_12), _createElementVNode("th", {
    title: $data.translate.unansweredQuestions,
    class: "align-top bg-orange-50 text-white text-center"
  }, _toDisplayString($data.translate.unansweredQuestions), 9 /* TEXT, PROPS */, _hoisted_13), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.headlines, function (headline, index) {
    return _openBlock(), _createElementBlock("th", {
      key: index,
      title: headline.area,
      class: "align-top bg-gray-350"
    }, _toDisplayString(headline.area), 9 /* TEXT, PROPS */, _hoisted_14);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dashboardNodesSlice, function (node) {
    return _openBlock(), _createElementBlock("tr", {
      key: node.id
    }, [_createElementVNode("td", _hoisted_16, [_createVNode(_component_preview_modal_link, {
      activity: node,
      class: "mb-3",
      onDismissModal: $options.onDismissPreview
    }, {
      default: _withCtx(function () {
        return [!node.approved ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: "check-circle",
          height: "15",
          width: "15",
          class: "inline stroke-2"
        })) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(node.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"]), _createElementVNode("small", null, _toDisplayString(node.questionnaire_title), 1 /* TEXT */)]), _createElementVNode("td", _hoisted_17, _toDisplayString(node.not_applicable), 1 /* TEXT */), _createElementVNode("td", _hoisted_18, _toDisplayString(node.answered), 1 /* TEXT */), _createElementVNode("td", _hoisted_19, _toDisplayString(node.unanswered), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getHeadlineStatsForNode(node), function (stats, index) {
      return _openBlock(), _createElementBlock("td", {
        key: index,
        title: "".concat($data.translate.answeredQuestions, ": ").concat(stats.answered, ". ").concat($data.translate.unansweredQuestions, ": ").concat(stats.unanswered, "."),
        class: "text-center"
      }, _toDisplayString(stats.answered) + " / " + _toDisplayString(stats.unanswered), 9 /* TEXT, PROPS */, _hoisted_20);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])), !$options.dashboardNodesSlice.length && $data.searchField ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_pagination, {
    total: $options.filteredNodes.length,
    "per-page": 50,
    onChange: $options.handleShownList
  }, null, 8 /* PROPS */, ["total", "onChange"])])]);
}