import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [!$props.hidden ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["tool-tip", ["triangle-".concat($props.arrowDirection), $props.tooltipClasses]])
      }, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "triangle"
      }, null, -1 /* HOISTED */)), _renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  });
}