function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import * as ChangelogAPI from '@/api/risma/changelog';
import * as RiskApi from '@/api/risk/risk';
import { getCurrentScoring as _getCurrentScoring } from '@/api/risk/scoring';
import { getAllDomains, getAllGapSchemas } from '@/api/compliance/gapschema';
import { getCompanyCurrencies as _getCompanyCurrencies } from '@/api/risma/data';
import { getAllQuestionsIncludingHidden, getAllQuestionnaires as _getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getAllFlows as _getAllFlows } from '@/api/compliance/dataflow';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RiskUniversalMixin } from '@/components/Pages/Risks/RiskUniversalMixin';
import { capitalize as _capitalize } from '@/utils';
import * as Utils from '@/utils/Utils';
import { toLocalDateTimeFull, toLocalDate } from '@/utils/date';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import { RiskModuleTense } from '@/constants/risks/RiskModuleTense';
import { getModuleFromObjType, ObjectTypes } from '@/constants/ObjectTypes';
import { getTranslatables, ChangelogColumns } from '@/constants/datatable/Translatables';
import FieldRendererMapping from '@/constants/datatable/FieldRendererMapping';
import { MODULES } from '@/constants/modules';
import { getIntervalTitleByValue } from '@/constants/contracts/contract';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { saveStatus as _saveStatus } from '@/constants/SaveStatuses';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { gapFlags } from '@/constants/compliance/GapTypes';
import { questionTypesById } from '@/constants/compliance/QuestionTypes';
import { art46TransferBasisById } from '@/constants/art46TransferBasis';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { companyTypes, companyHierarchyTypes } from '@/constants/companies/ebaCompanies';
import { isFieldInOptionalFields, isDataFlowEnabled } from '@/utils/Utils';
export default {
  name: 'Changelog',
  components: {
    TrafficLight: TrafficLight,
    RismaDatatable: RismaDatatable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    HierarchicalList: HierarchicalList
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, RiskUniversalMixin],
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {},
      Note: 'The activity to fetch changelog for',
      validator: function validator(value) {
        return !!value.objType || !!value.moduleName;
      }
    },
    saveStatus: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['getActivityData'],
  data: function data() {
    return {
      title: 'Changelog',
      dataset: [],
      pageLoaded: false,
      slotDataFields: ['old_value', 'new_value'],
      fieldTypes: {
        TRAFFIC_LIGHT: 'trafficLight',
        HIERARCHICAL_LIST: 'hierarchicalList'
      },
      threatCategories: [],
      likelihoodValues: [],
      impact_other1_label: '',
      impact_other2_label: '',
      managementLevels: [],
      gapDomains: [],
      companies: [],
      isDirty: false,
      trafficLightFields: ['trafficLight', 'traffic_light', 'severity', 'analysis_rag', 'vat_status'],
      currencies: [],
      toLocalDateFields: [ChangelogColumns.START_DATE, ChangelogColumns.END_DATE, ChangelogColumns.RENEWAL_DATE, ChangelogColumns.BINDING_DATE],
      translate: getTranslate['Changelog'](),
      questionTypesById: questionTypesById(),
      questions: [],
      allGapSchemas: [],
      questionnaires: [],
      flows: [],
      art46TransferBasisById: art46TransferBasisById(this.$trans)
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUserStore, {
    currentUser: 'currentUser',
    userHasCompaniesAccess: 'userHasCompaniesAccess',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), mapState(useActivityTypesStore, {
    customActivities: 'complianceIACustomActivityTypes',
    activityTypes: 'activityTypes'
  })), {}, {
    complianceProject: function complianceProject() {
      if (this.module === MODULES.COMPLIANCE) {
        return this.currentUserComplianceProjectById(this.data.compliance_projects_id);
      }
      return {};
    },
    module: function module() {
      return this.data.objType ? getModuleFromObjType(this.data.objType) : this.data.moduleName;
    },
    preparedColumns: function preparedColumns() {
      return {
        type: this.translate.type,
        title: this.translate.title,
        old_value: this.translate.from,
        new_value: this.translate.to,
        show_user: this.translate.user,
        created: this.translate.date
      };
    },
    translatables: function translatables() {
      return getTranslatables(this.module);
    },
    cTable2DefaultTitle: function cTable2DefaultTitle() {
      var _ObjectTypes$EXECUTIO;
      return (_ObjectTypes$EXECUTIO = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.EXECUTION_INITIATIVE, 'Action'), ObjectTypes.RISK_RISK, 'Risk'), ObjectTypes.INCIDENTS_INCIDENT, 'Incident'), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, 'Process'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Controller'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'System'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Processor'), ObjectTypes.INFORMATION_ASSET, 'Asset'), ObjectTypes.CONTROLS_CONTROL, 'Control'), ObjectTypes.COMPLIANCE_NODE, 'Compliance'), _defineProperty(_defineProperty(_defineProperty(_ObjectTypes$EXECUTIO, ObjectTypes.CONTRACT, 'Contract'), ObjectTypes.COMPANY, 'Company'), ObjectTypes.COMPLIANCE_QUESTIONNAIRE, 'Questionnaire'))[this.data.objType];
    },
    filteredActivityTypes: function filteredActivityTypes() {
      var _this = this;
      return this.activityTypes.filter(function (activityType) {
        return activityType.module === _this.module;
      });
    },
    activityTypesNamesByIds: function activityTypesNamesByIds() {
      var entries = new Map(this.filteredActivityTypes.map(function (at) {
        return [at.id, at.label];
      }));
      return Object.fromEntries(entries);
    },
    currentContractActivityType: function currentContractActivityType() {
      var _this2 = this;
      return this.filteredActivityTypes.find(function (at) {
        return +at.id === +_this2.data.activityTypeId;
      }) || {};
    },
    path: function path() {
      return this.data.rno;
    },
    projectId: function projectId() {
      return this.data.projectId;
    },
    periodId: function periodId() {
      return this.data.projectPeriodId;
    },
    isRiskModule: function isRiskModule() {
      return this.module === MODULES.RISK;
    },
    isErmRiskProject: function isErmRiskProject() {
      var _this$data;
      return +((_this$data = this.data) === null || _this$data === void 0 || (_this$data = _this$data.project) === null || _this$data === void 0 ? void 0 : _this$data.type) === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    isInformationAssetsModule: function isInformationAssetsModule() {
      return [MODULES.COMPLIANCE_IA_SYSTEM, MODULES.COMPLIANCE_IA_PROCESSOR, MODULES.COMPLIANCE_IA_CONTROLLER, MODULES.COMPLIANCE_INFORMATION_ASSETS].includes(this.module);
    },
    questionnaireEnabled: function questionnaireEnabled() {
      return isFieldInOptionalFields(this.data.activityType, 'questionnaire');
    },
    dataFlowEnabled: function dataFlowEnabled() {
      return isDataFlowEnabled(this.complianceProject, this.getSettingValue, this.questionnaireEnabled);
    }
  }),
  watch: {
    saveStatus: function saveStatus(newValue, oldValue) {
      if (oldValue === _saveStatus.SAVING && newValue === _saveStatus.SAVED) {
        this.getChangelogData();
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    capitalize: function capitalize(value) {
      return _capitalize(value);
    },
    isNormalField: function isNormalField(field) {
      return _typeof(field) !== 'object';
    },
    getData: function getData() {
      var _this3 = this;
      var promises = [];
      if (this.isRiskModule) {
        promises.push(this.getRiskProjects(), this.getRiskTypes());
      }
      if (this.module === MODULES.COMPLIANCE_GAP_SCHEMA) {
        promises.push(this.getGapDomains());
      }
      if (this.module === MODULES.THREAT) {
        promises.push(this.getThreatCategories());
      }
      if (this.module === MODULES.COMPLIANCE_QUESTIONNAIRE) {
        promises.push(this.getQuestions());
      }
      if (this.module === MODULES.COMPLIANCE_QUESTIONNAIRE || this.module === MODULES.COMPLIANCE_IA_PROCESSOR) {
        promises.push(this.getGapSchemas());
      }
      if (this.module === MODULES.COMPANY) {
        promises.push(this.getCompanyCurrencies());
      }
      if (this.module === MODULES.COMPLIANCE_INFORMATION_ASSETS || this.module === MODULES.COMPLIANCE_IA_CONTROLLER || this.module === MODULES.COMPLIANCE_IA_SYSTEM || this.module === MODULES.COMPLIANCE_IA_PROCESSOR || this.module === MODULES.COMPLIANCE || this.module === MODULES.COMPLIANCE_QUESTIONNAIRE || this.module === MODULES.COMPLIANCE_QUESTIONNAIRE_DPM || this.module === MODULES.CONTRACTS || this.module === MODULES.RISK || this.module === MODULES.INCIDENTS) {
        promises.push(this.getAllQuestionnaires());
      }
      if (this.isInformationAssetsModule || this.module === MODULES.COMPANY) {
        promises.push(this.getCompanies());
      }
      if (this.dataFlowEnabled) {
        promises.push(this.getAllFlows());
      }
      return Promise.all(promises).then(function () {
        if (!_this3.isRiskModule) return Promise.resolve();
        var currentProject = _this3.riskProjects.find(function (pj) {
          return +pj.id === +_this3.projectId;
        });
        var addedPromises = [];
        if (+currentProject.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
          addedPromises.push(_this3.getCurrentScoring());
          addedPromises.push(_this3.getRiskManagementLevels());
        }
        return Promise.all(addedPromises);
      }).then(this.prepareRiskData).then(this.getChangelogData);
    },
    getCompanyCurrencies: function getCompanyCurrencies() {
      var _this4 = this;
      return _getCompanyCurrencies().then(function (_ref) {
        var list = _ref.list;
        return _this4.currencies = list;
      });
    },
    getThreatCategories: function getThreatCategories() {
      var _this5 = this;
      return RiskApi.getRiskThreatCategories().then(function (response) {
        _this5.threatCategories = response.list;
      });
    },
    getChangelogData: function getChangelogData() {
      var _this6 = this;
      this.pageLoaded = false;
      return this.handleFetchChangelogs(this.module, this.data.id).then(function (resp) {
        if (_this6.module === MODULES.EXECUTION) _this6.checkListForCreated(resp);
        _this6.dataset = resp;
        _this6.enhanceDataset(_this6.dataset, _this6.preparedColumns);
      }).finally(function () {
        return _this6.pageLoaded = true;
      });
    },
    getCurrentScoring: function getCurrentScoring() {
      var _this7 = this;
      return _getCurrentScoring().then(function (scoring) {
        _this7.likelihoodValues = scoring.likelihood_values;
        _this7.impact_other1_label = scoring.impactOther1Label;
        _this7.impact_other2_label = scoring.impactOther2Label;
      });
    },
    getRiskManagementLevels: function getRiskManagementLevels() {
      var _this8 = this;
      return RiskApi.getRisksManagementLevels().then(function (_ref2) {
        var list = _ref2.list;
        return _this8.managementLevels = list;
      });
    },
    getGapDomains: function getGapDomains() {
      var _this9 = this;
      return getAllDomains().then(function (_ref3) {
        var list = _ref3.list;
        return _this9.gapDomains = list;
      });
    },
    getQuestions: function getQuestions() {
      var _this10 = this;
      return getAllQuestionsIncludingHidden(this.$route.params.questionnaireId).then(function (_ref4) {
        var questions = _ref4.questions;
        return _this10.questions = questions;
      });
    },
    getGapSchemas: function getGapSchemas() {
      var _this11 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref5) {
        var list = _ref5.list;
        return _this11.allGapSchemas = list;
      });
    },
    getAllQuestionnaires: function getAllQuestionnaires() {
      var _this12 = this;
      return _getAllQuestionnaires().then(function (_ref6) {
        var list = _ref6.list;
        return _this12.questionnaires = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this13 = this;
      return _getCompanies().then(function (_ref7) {
        var list = _ref7.list;
        return _this13.companies = list;
      });
    },
    prepareRiskData: function prepareRiskData() {
      if (this.isRiskModule) {
        this.prepareData();
        this.afterPrepareData();
      }
    },
    handleFetchChangelogs: function handleFetchChangelogs(type, id) {
      var _this14 = this;
      return ChangelogAPI.getChangelog(type, id).then(function (_ref8) {
        var list = _ref8.list;
        return list.filter(function (row) {
          if (!_this14.userHasCompaniesAccess) {
            return row.column !== ChangelogColumns.COMPANY_ID && row.column !== ChangelogColumns.COMPANY_ID_COLUMN;
          }
          return true;
        }).map(function (row) {
          var type = _this14.getRowType(row);
          var title = _this14.translatablesWithRaci(row.column, row.old_value);
          var new_value = _this14.prepareValue(row, 'new_value');
          var old_value = _this14.prepareValue(row, 'old_value');
          var created = _this14.getRowCreated(row);
          return {
            type: {
              field: _this14.checkDefined(type)
            },
            title: {
              field: _this14.checkDefined(title)
            },
            old_value: {
              field: _this14.checkDefined(old_value)
            },
            new_value: {
              field: _this14.checkDefined(new_value)
            },
            show_user: {
              field: _this14.checkDefined(row.show_user)
            },
            created: {
              field: _this14.checkDefined(created)
            }
          };
        });
      });
    },
    getAllFlows: function getAllFlows() {
      var _this15 = this;
      return _getAllFlows(this.complianceProject.id, this.data.id).then(function (response) {
        _this15.flows = response.list;
      });
    },
    getRowType: function getRowType(row) {
      var cTable2 = row.ctable2 || this.cTable2DefaultTitle;
      var type = this.translatables[cTable2] || cTable2;
      if (cTable2 === MODULES.dataFlow) {
        type += " (".concat(row.cid2, ")");
      }
      if (cTable2 === MODULES.COMPLIANCE_QUESTIONS) {
        var _this$questions$find;
        var questionNumber = (_this$questions$find = this.questions.find(function (q) {
          return +q.id === +[row.cid2];
        })) === null || _this$questions$find === void 0 ? void 0 : _this$questions$find.questionnumber;
        type = questionNumber ? type + " ".concat(questionNumber) : this.translate.deletedQuestion;
      }
      if (cTable2 === MODULES.gapAnalysis && this.data.objType === ObjectTypes.COMPLIANCE_NODE) {
        type = this.getGapAnalysisType(type, row);
      }
      if (cTable2 === MODULES.RISK_THREAT_ASSESSMENT) {
        type = this.translate.threatAssessments;
      }
      if (row.module === MODULES.THREAT) {
        type = this.translate.threat;
      }
      return type;
    },
    getRowCreated: function getRowCreated(row) {
      return toLocalDateTimeFull(row.created);
    },
    checkListForCreated: function checkListForCreated(list) {
      var _this16 = this;
      var creator = this.users.find(function (user) {
        return user.id === _this16.data.createdBy;
      });
      var time = this.data.created;
      var item = {
        created: {
          field: toLocalDateTimeFull(time)
        },
        old_value: {
          field: ''
        },
        new_value: {
          field: ''
        },
        title: {
          field: this.translate.created
        },
        show_user: {
          field: creator && creator.display_name || ''
        },
        type: {
          field: this.cTable2DefaultTitle
        }
      };
      list.push(item);
    },
    prepareValue: function prepareValue(row, key) {
      var _this$currentContract;
      if (row.column === ChangelogColumns.COUNTRY_TRANSFER_LEGAL_BASIS_ID || row.column === ChangelogColumns.COUNTRY_TRANSFER_LEGAL_BASIS_ID_COLUMN) {
        return this.art46TransferBasisById[row[key]];
      }
      if (row.column.startsWith('likelihood') || row.column.startsWith('impact')) {
        return row[key] || '0';
      }
      if (row.column === ChangelogColumns.STATUS && row.module === MODULES.RISK) {
        return row[key] === '1' ? this.translate.closed : this.translate.open;
      }
      if (row.column === ChangelogColumns.TREE) {
        if (JSON.parse(row[key])) {
          return {
            field: JSON.parse(row[key]),
            fieldType: this.fieldTypes.HIERARCHICAL_LIST
          };
        }
      }
      if (row.column === ChangelogColumns.ACTIVITY_TYPE_ID_V1 || row.column === ChangelogColumns.ACTIVITY_TYPE_ID_V2) {
        var isPreparedValue = isNaN(row[key]);
        var value = isPreparedValue ? row[key] : this.activityTypesNamesByIds[row[key]];
        return value || '';
      }
      if (row.column === ChangelogColumns.PARENT_ID && this.module === MODULES.COMPLIANCE_IA_SYSTEM) {
        return Utils.findValueById(this.data.systems, +row[key], 'title');
      }
      if (row.column === 'parent_id' && this.module === MODULES.COMPANY) {
        return Utils.findValueById(this.companies, +row[key], 'name');
      }
      if (row.column === ChangelogColumns.ARCHIVED || row.column === ChangelogColumns.CONFIDENTIAL || row.column === ChangelogColumns.IS_ARTICLE_30 || row.column === ChangelogColumns.ALLOW_NEW_ANSWER_OPTION || row.column === ChangelogColumns.HIDDEN || row.column.startsWith('flag_')) {
        return row[key] && JSON.parse(row[key]) ? this.translate.yes : this.translate.no;
      }
      if (row.column === ChangelogColumns.AUTO_RENEW) {
        if (!row[key]) return this.translate.notSpecified;
        return parseInt(row[key]) ? this.translate.yes : this.translate.no;
      }
      if (row.column === ChangelogColumns.THREAT_CATEGORY_ID) {
        var _this$threatCategorie;
        return ((_this$threatCategorie = this.threatCategories.find(function (category) {
          return +category.id === +row[key];
        })) === null || _this$threatCategorie === void 0 ? void 0 : _this$threatCategorie.title) || row[key];
      }
      if (row.column === ChangelogColumns.SYNC_WITH_EXTERNAL || row.column === ChangelogColumns.SELECTABLE) {
        return +row[key] ? this.translate.enabled : this.translate.disabled;
      }
      if (row.column === ChangelogColumns.PAYMENT_INTERVAL) {
        return row[key] && getIntervalTitleByValue(row[key]);
      }
      if (row.column === ChangelogColumns.RISK_VULNERABILITIES_ID) {
        return Utils.findValueById(this.vulnerabilities, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.RISK_THREATS_ID) {
        return Utils.findValueById(this.threats, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.RISK_THREAT_LEVELS_ID) {
        return Utils.findValueById(this.levelOfThreats, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.VULNERABILITIES) {
        return Utils.findPropsByIds(row[key].split(','), this.vulnerabilities, 'label');
      }
      if (row.column === ChangelogColumns.THREATS) {
        return Utils.findPropsByIds(row[key].split(','), this.threats, 'label');
      }
      if (row.column === ChangelogColumns.DAILY_TEAM_USER) {
        return Utils.findPropsByIds(row[key].split(','), this.users, 'display_name');
      }
      if (row.column === ChangelogColumns.LIKELIHOOD_SCORE) {
        return this.likelihoodValues[row[key]];
      }
      if (row.column === ChangelogColumns.APPROVED_BY || row.column === ChangelogColumns.DAILY_OWNER || row.column === ChangelogColumns.PRIMARY_OWNER || row.column === ChangelogColumns.USER_ID) {
        return Utils.findValueById(this.users, +row[key], 'display_name');
      }
      if (row.column === ChangelogColumns.APPROVAL_STATUS) {
        return +row[key] ? this.translate.approved : this.translate.unapproved;
      }
      if (row.column === ChangelogColumns.APPROVAL_DATE || row.column === ChangelogColumns.UPDATED_AT || row.column === ChangelogColumns.GROSS_UPDATED) {
        return toLocalDateTimeFull(row[key]);
      }
      if (row.column === ChangelogColumns.RISK_TYPE) {
        return Utils.findValueById(this.riskTypes, +row[key], 'title');
      }
      if (row.column === ChangelogColumns.ORGANIZATIONAL_UNIT || row.column === ChangelogColumns.DAILY_TEAM_ORGANISATION) {
        return Utils.findPropsByIds(row[key].split(','), this.organisations, 'visible_name');
      }
      if (row.column === ChangelogColumns.MANAGEMENT_LEVEL) {
        return Utils.findValueById(this.managementLevels, +row[key], 'name');
      }
      if (row.column === ChangelogColumns.FREQUENCY) {
        return RismaControlsUtils.frequency2text(+row[key]) || row[key];
      }
      if (row.column === ChangelogColumns.DOMAIN_ID) {
        return Utils.findValueById(this.gapDomains, +row[key], 'label');
      }
      if (row.column === ChangelogColumns.FLAGS) {
        var _Object$values$find;
        return ((_Object$values$find = Object.values(gapFlags(this.$trans)).find(function (flag) {
          return flag.value === +row[key];
        })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.label) || '';
      }
      if (row.column.endsWith(": ".concat(ChangelogColumns.PINNED))) {
        return row[key] === '1' ? this.translate.pinned : this.translate.notPinned;
      }
      if (row.column === ChangelogColumns.INFORMATION_ASSETS_ID) {
        var _this$customActivitie, _this$customActivitie2;
        return (_this$customActivitie = (_this$customActivitie2 = this.customActivities.find(function (at) {
          return +at.id === +row[key];
        })) === null || _this$customActivitie2 === void 0 ? void 0 : _this$customActivitie2.label) !== null && _this$customActivitie !== void 0 ? _this$customActivitie : '';
      }
      if (row.column === ChangelogColumns.DEFAULT_GAP_SCHEMA || row.column === ChangelogColumns.GAP_SCHEMA_ID || row.column === ChangelogColumns.GAP_SCHEMA_ID_COLUMN) {
        var _this$allGapSchemas$f;
        return (_this$allGapSchemas$f = this.allGapSchemas.find(function (gap) {
          return +gap.id === +row[key];
        })) === null || _this$allGapSchemas$f === void 0 ? void 0 : _this$allGapSchemas$f.title;
      }
      if (row.column === ChangelogColumns.QUESTIONNAIRE_ID) {
        if (!row[key]) return '';
        var splitValues = row[key].split(',');
        if (splitValues.some(function (item) {
          return isNaN(item);
        })) return row[key];
        return Utils.findPropsByIds(splitValues, this.questionnaires, 'title');
      }
      if (this.module === MODULES.COMPLIANCE_QUESTIONNAIRE) {
        if (row.column === 'deleted' && key === 'new_value') return '';
        if (row.column === 'created' && key === 'old_value') return '';
      }
      if (this.module === MODULES.CONTRACTS && row.column === ChangelogColumns.WORKFLOW_STATUS_ID) {
        return row[key] || '';
      }
      if (this.toLocalDateFields.includes(row.column)) {
        return toLocalDate(row[key]);
      }
      if (row.column === 'dora_enabled') {
        return row[key] ? this.translate.include : '';
      }
      if (row.column === 'eba_total_assets_currency') {
        var _this$currencies$find;
        return ((_this$currencies$find = this.currencies.find(function (currency) {
          return currency.currencyCode === row[key];
        })) === null || _this$currencies$find === void 0 ? void 0 : _this$currencies$find.currencyName) || '';
      }
      if (row.column === 'eba_company_type') {
        return _capitalize(companyTypes[row[key]]);
      }
      if (row.column === 'eba_company_hierarchy') {
        return _capitalize(companyHierarchyTypes[row[key]]);
      }
      if (row.column === 'company_type') {
        return this.translate.companyType[row[key]] || '';
      }
      if (!row[key]) return '';
      var fieldRendererKey = row.dataType || row.column;
      var field = FieldRendererMapping[fieldRendererKey] ? FieldRendererMapping[fieldRendererKey](row[key]) : row[key];
      if (this.trafficLightFields.includes(fieldRendererKey) && _typeof(field) === 'object') {
        field.fieldType = this.fieldTypes.TRAFFIC_LIGHT;
        if (row.column === ChangelogColumns.VAT_STATUS) {
          field.trafficLightSuffix = ": ".concat(this.$trans(field.label));
        }
      }
      var customField = (_this$currentContract = this.currentContractActivityType) === null || _this$currentContract === void 0 || (_this$currentContract = _this$currentContract.fields) === null || _this$currentContract === void 0 ? void 0 : _this$currentContract.find(function (f) {
        return f.label === fieldRendererKey;
      });
      if (row.column === ChangelogColumns.TAGS && this.isErmRiskProject) {
        return Utils.findPropsByIds(row[key].split(','), this.tags, 'tag');
      }
      if (row.column === ChangelogColumns.QUESTION_TYPE) {
        return this.questionTypesById[row[key]];
      }
      if (row.column === ChangelogColumns.LEGAL_BASIS_QUESTION_ID) {
        var _this$questions$find2;
        return (_this$questions$find2 = this.questions.find(function (q) {
          return +q.id === +row[key];
        })) === null || _this$questions$find2 === void 0 ? void 0 : _this$questions$find2.questionnumber;
      }
      if (row.column === ChangelogColumns.DEPENDENCIES) {
        return Object.entries(JSON.parse(row[key])).reduce(function (acc, _ref9) {
          var _ref10 = _slicedToArray(_ref9, 2),
            key = _ref10[0],
            value = _ref10[1];
          return "".concat(acc ? acc + '<br>' : '').concat(key, ": ").concat(value.join(', '));
        }, '');
      }
      if (row.column === ChangelogColumns.COMPANY_ID || row.column === ChangelogColumns.COMPANY_ID_COLUMN) {
        var _this$companies$find;
        return ((_this$companies$find = this.companies.find(function (company) {
          return company.id === +row[key];
        })) === null || _this$companies$find === void 0 ? void 0 : _this$companies$find.name) || '';
      }
      if (customField && customField.fieldType === CustomFieldTypes.TREND) {
        if (this.translate.trend[row[key]] === undefined) return row[key];
        return this.translate.trend[row[key]];
      }

      // If string was set in the changelog without the space after comma
      if (typeof field === 'string' && field.indexOf(',') !== -1 && field.indexOf(', ') === -1) {
        return field.replaceAll(',', ', ');
      }
      if (row.column === ChangelogColumns.STORAGE_PERIOD_OPTION_ID) {
        return this.getDataFlowValue('storagePeriodOption', row[key]);
      }
      if (row.column === ChangelogColumns.LEGAL_BASIS_OPTION_ID) {
        return this.getDataFlowValue('legalBasisOption', row[key]);
      }
      return field;
    },
    getDataFlowValue: function getDataFlowValue(fieldName, value) {
      var idField = "".concat(fieldName, "Id");
      var labelField = "".concat(fieldName, "Label");
      var _iterator = _createForOfIteratorHelper(this.flows),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var flow = _step.value;
          var dataType = flow.dataTypes.find(function (dataType) {
            return dataType[idField] === Number(value);
          });
          if (dataType) {
            return dataType[labelField];
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return null;
    },
    checkDefined: function checkDefined(field) {
      return field ? field : '';
    },
    translatablesWithRaci: function translatablesWithRaci(column, oldValue) {
      if (column === ChangelogColumns.RESPONSIBLE) {
        return this.currentContractActivityType.responsibleLabel || this.responsibleTitle(this.isRACIUsed(this.data.objType));
      }
      if (column === ChangelogColumns.ACCOUNTABLE) {
        return this.currentContractActivityType.accountableLabel || this.accountableTitle(this.isRACIUsed(this.data.objType));
      }
      if (column === ChangelogColumns.CONSULTED) {
        return this.currentContractActivityType.consultedLabel || this.consultedTitle(this.isRACIUsed(this.data.objType));
      }
      if (column === ChangelogColumns.INFORMED) {
        return this.currentContractActivityType.informedLabel || this.informedTitle(this.isRACIUsed(this.data.objType));
      }
      if (column.startsWith('likelihood')) {
        var tense = RiskModuleTense[column.split('-')[1] - 1]; //risk_index + 1 saves to changelog
        return "".concat(this.translate.likelihood, " ").concat(tense ? "(".concat(this.$trans(tense), ")") : '');
      }
      if (column.startsWith('impact')) {
        if (column === 'impact_other1_score') {
          return this.impact_other1_label;
        }
        if (column === 'impact_other2_score') {
          return this.impact_other2_label;
        }
        var _tense = RiskModuleTense[column.split('-')[1] - 1]; //risk_index + 1 saves to changelog
        return "".concat(this.translate.impact, " ").concat(_tense ? "(".concat(this.$trans(_tense), ")") : '');
      }
      if (this.module === MODULES.COMPLIANCE && column === ChangelogColumns.DESCRIPTION) {
        return getProjectDescriptionLabel(this.complianceProject);
      }
      if (this.module === MODULES.COMPLIANCE && column === ChangelogColumns.STATUS) {
        return getProjectStatusLabel(this.complianceProject);
      }
      if (this.module === MODULES.COMPLIANCE && column === ChangelogColumns.PURPOSE) {
        return getProjectPurposeLabel(this.complianceProject);
      }
      if (this.module === MODULES.THREAT && column === ChangelogColumns.TITLE && !oldValue) {
        return this.translatables.created;
      }
      if (column === 'cpath' && this.module === MODULES.CONTROLS && this.data.isMothercontrol) {
        return this.translate.motherControlId;
      }
      return this.translatables[column] || column;
    },
    getGapAnalysisType: function getGapAnalysisType(title, row) {
      var styledTitle = "<div class=\"font-semibold\">".concat(row.title, "</div>");
      return title + '\n' + styledTitle;
    },
    handleDirty: function handleDirty(data) {
      if (this.data.id === data.id) {
        this.isDirty = true;
      }
    },
    updateFromPreview: function updateFromPreview() {
      if (this.isDirty) {
        this.$router.go(0);
      }
    }
  }
};