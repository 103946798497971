import { UserLevels } from '@/constants/UserLevels';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
export function calcExecutionRights(user, settings) {
  if (settings['app.module_execution_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_execution > UserLevels.NONE || user.is_admin;
}
export function calcControlsRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_controls_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_controls >= level || user.is_admin;
}
export function calcRiskRights(user, settings) {
  var _user$projects;
  var levelRisk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_risk_enabled'] !== 1 && settings['app.module_risks2_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_risk >= levelRisk || user.is_admin || ((_user$projects = user.projects) === null || _user$projects === void 0 || (_user$projects = _user$projects.risk) === null || _user$projects === void 0 ? void 0 : _user$projects.find(function (element) {
    return element.access_level > levelRisk;
  })) !== undefined;
}
export function calcComplianceRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_compliance_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.is_admin || user.projects.compliance.find(function (element) {
    return element.access_level >= level;
  }) !== undefined;
}
export function calcCsrdProjectRights(user, settings) {
  var _useComplianceProject;
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_compliance_enabled'] !== 1) return false;
  var csrdProjectId = (_useComplianceProject = useComplianceProjectsStore().getCsrdProject()) === null || _useComplianceProject === void 0 ? void 0 : _useComplianceProject.id;
  return !!(user.is_admin || user.projects.compliance.find(function (project) {
    return +project.id === +csrdProjectId && project.access_level >= level;
  }));
}
export function calcIncidentsRights(user, settings) {
  var levelIncidents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_incidents_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_incidents >= levelIncidents || user.is_admin;
}
export function calcCompanyRights(user, settings) {
  var levelCompany = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_companies_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_company >= levelCompany || user.is_admin;
}
export function calcContractRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['app.module_contracts_enabled'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_contracts >= level || user.is_admin;
}
export function checkForComplianceProjects(projects) {
  return projects.some(function (project) {
    return project.access_level > UserLevels.NONE;
  });
}
export function calcProcessLibraryRights(user, settings) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  if (settings['feature.process_tree'] !== 1) {
    return false;
  }
  if (!user) {
    // if user and his level not needed for calcRights
    return true;
  }
  return user.level_process_library >= level || user.is_admin;
}