import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  key: 3
};
var _hoisted_4 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        class: "mb-6",
        title: $data.translate.uploadGap
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.gapTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.gapTitle = $event;
        }),
        title: $data.translate.title,
        type: "text",
        class: "mb-2",
        placeholder: $data.translate.enterTitle,
        invalid: $data.invalidFields.gapTitle && !$data.gapTitle,
        "error-help-text": $data.invalidFields.gapTitle
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"]), _createVNode(_component_single_select, {
        modelValue: $data.origin,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.origin = $event;
        }),
        title: $data.translate.selectOrigin,
        options: $data.origins,
        placeholder: $data.translate.selectOrigin,
        "required-text": $data.invalidFields.origin,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "required-text"]), _createVNode(_component_single_select, {
        modelValue: $data.gapSchemaType,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.gapSchemaType = $event;
        }),
        title: $data.translate.gapSchemaType,
        options: $options.gapSchemaTypes,
        placeholder: $data.translate.chooseGapSchemaType,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), $data.gapSchemaType === null ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_single_select, {
        modelValue: $data.projectId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.projectId = $event;
        }),
        title: $data.translate.complianceProject,
        options: $data.projects,
        placeholder: $data.translate.selectComplianceProject,
        "required-text": $data.invalidFields.projectId,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "required-text"])])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.selectGapExcelOrCsvFile,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), $data.files.length === 0 ? (_openBlock(), _createBlock(_component_risma_file_upload, {
        key: 1,
        class: "-mt-4",
        onUpload: _cache[4] || (_cache[4] = function ($event) {
          return $data.files = $event;
        })
      })) : _createCommentVNode("v-if", true), $data.files.length > 0 ? (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString($data.files[0].filename), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.gapSchemaType === null ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.resetAllieRemoveExistingGapSchemasAnd,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
        modelValue: $data.updateAllNodes,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $data.updateAllNodes = $event;
        })
      }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("span", null, _toDisplayString($data.updateAllNodes ? 'Yes' : 'No'), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.previewDataset && $data.previewDataset.length ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 4,
        text: $data.translate.create,
        type: "save",
        onClick: $options.createGapSchema
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), !$data.previewDataset && $data.files.length > 0 ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 5,
        text: $data.translate.preview,
        type: "save",
        onClick: $options.loadPreviewTable
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [$data.previewDataset && !$data.loading ? (_openBlock(), _createBlock(_component_risma_datatable, {
        key: 0,
        "dataset-main": _ctx.datasetSlice,
        "dataset-total": $data.previewDataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        "columns-meta": $data.columnsMeta,
        "columns-by-default": $options.columns,
        "data-cell-styles": "align-top",
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, null, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "columns-meta", "columns-by-default", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 6
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}