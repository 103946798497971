import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'ContractsReportWrapper',
  components: {
    LightTabItem: LightTabItem
  },
  computed: {
    tabList: function tabList() {
      return [{
        url: '/contracts/reports/main',
        name: this.$trans('Main report')
      }, {
        url: '/contracts/reports/questionnaire',
        name: this.$trans('Questionnaire report')
      }];
    }
  }
};