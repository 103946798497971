function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useRiskProjectsStore } from '@/Store/riskProjectsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getTranslate } from './translate';
import { createRisk } from '@/api/risk/risk';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { userHasProjectAccess } from '@/utils/access';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { checkERMByProjectId } from '@/utils/risks/check';
import { findIdsInArray } from '@/utils/Utils';
import { removeDuplicates } from '@/utils/filterArray';
export default {
  name: 'RiskCreationModal',
  introduction: 'RiskCreationModal multiselect popup',
  description: 'RiskCreationModal with selector and list of selected items',
  token: '<risk-creation-modal />',
  components: {
    RismaSelect: RismaSelect,
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    FrameworksSelector: FrameworksSelector,
    SingleSelect: SingleSelect
  },
  mixins: [RaciTitleMixin],
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
    periodId: {
      type: [String, Number],
      required: false,
      default: null
    },
    parentRnoProp: {
      type: String,
      required: false,
      default: '',
      note: 'if element should have parent'
    },
    parentFrameworkIds: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    risks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create'],
  data: function data() {
    return {
      title: '',
      errorMessages: '',
      titleError: '',
      selectedFrameworks: [],
      isRismaSelectOpen: false,
      responsibleUserIds: [],
      createInProgress: false,
      parentRno: '',
      responsibleUserSelected: '',
      translate: getTranslate['RiskCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useActivityTypesStore, ['getActivityTypeById'])), {}, {
    defaultQuestionnaireId: function defaultQuestionnaireId() {
      var _this$currentProject, _this$getActivityType;
      if (!((_this$currentProject = this.currentProject) !== null && _this$currentProject !== void 0 && _this$currentProject.defaultActivityTypeId)) return null;
      return (_this$getActivityType = this.getActivityTypeById(this.currentProject.defaultActivityTypeId)) === null || _this$getActivityType === void 0 ? void 0 : _this$getActivityType.defaultQuestionnaireId;
    },
    parentRisks: function parentRisks() {
      return this.risks.filter(function (risk) {
        return !risk.parent;
      }).map(function (risk) {
        return {
          value: risk.rno,
          label: "".concat(risk.rno, ". ").concat(risk.title)
        };
      });
    },
    filteredUsers: function filteredUsers() {
      var _this = this;
      if (this.isERM) {
        return this.users.filter(function (user) {
          return user.level_risk >= UserLevels.NORMAL;
        });
      }
      return this.users.filter(function (user) {
        return userHasProjectAccess(user, _this.projectId, ObjectTypes.RISK_PROJECT) && user.level_risk >= UserLevels.NORMAL;
      });
    },
    preparedUserOptions: function preparedUserOptions() {
      return this.filteredUsers.map(function (item) {
        return {
          label: item.display_name,
          value: item.id
        };
      });
    },
    preselectedResponsibleUsers: function preselectedResponsibleUsers() {
      return [{
        label: this.currentUser.display_name,
        value: this.currentUser.id
      }];
    },
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    responsibleListTitle: function responsibleListTitle() {
      if (this.isERM) {
        return this.translate.dailyRiskOwner;
      }
      return this.$trans(this.responsibleTitle(true));
    },
    currentProject: function currentProject() {
      return this.riskProjectById(this.projectId);
    },
    currentProjectFramework: function currentProjectFramework() {
      var _this$currentProject2,
        _this2 = this;
      if (!((_this$currentProject2 = this.currentProject) !== null && _this$currentProject2 !== void 0 && _this$currentProject2.solutionId)) return null;
      return this.frameworks.find(function (framework) {
        return framework.id === _this2.currentProject.solutionId;
      });
    }
  }),
  mounted: function mounted() {
    var _this$parentFramework;
    this.responsibleUserIds = this.preselectedResponsibleUsers.map(function (item) {
      return item.value;
    });
    var framework = this.currentProjectFramework;
    this.selectedFrameworks = framework ? [framework] : [];
    if ((_this$parentFramework = this.parentFrameworkIds) !== null && _this$parentFramework !== void 0 && _this$parentFramework.length) {
      this.selectedFrameworks = removeDuplicates([].concat(_toConsumableArray(this.selectedFrameworks), _toConsumableArray(findIdsInArray(this.parentFrameworkIds, this.frameworks))));
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useRiskProjectsStore, {
    riskProjectById: 'getProjectById'
  })), {}, {
    updateFrameworks: function updateFrameworks(value) {
      var _this3 = this;
      var risk = this.risks.find(function (_ref) {
        var rno = _ref.rno;
        return value === rno;
      });
      var frameworkIds = risk.solutionIds;
      if (!(frameworkIds !== null && frameworkIds !== void 0 && frameworkIds.length)) return;
      if (this.currentProjectFramework && this.selectedFrameworks.find(function (item) {
        return item.id === _this3.currentProjectFramework.id;
      })) {
        this.selectedFrameworks = [this.currentProjectFramework];
      } else {
        this.selectedFrameworks = [];
      }
      this.selectedFrameworks = removeDuplicates([].concat(_toConsumableArray(this.selectedFrameworks), _toConsumableArray(findIdsInArray(frameworkIds, this.frameworks))));
    },
    handleOnSubmit: function handleOnSubmit() {
      var _this4 = this;
      var data = {
        title: this.title,
        solutionIds: this.selectedFrameworks.map(function (item) {
          return item.id;
        }),
        responsibleUserIds: this.responsibleUserIds,
        activityTypeId: this.currentProject.defaultActivityTypeId
      };
      if (this.parentRnoProp || this.parentRno) {
        data.parent = this.parentRnoProp || this.parentRno;
      }
      if (this.defaultQuestionnaireId) {
        data.questionnaires = [{
          questionnaireId: this.defaultQuestionnaireId
        }];
      }
      if (!this.handleValidation(data)) {
        return;
      }
      this.createInProgress = true;
      createRisk(this.projectId, this.periodId, data).then(function (response) {
        _this4.$emit('create', response);
      }).catch(function (error) {
        return _this4.errorMessages = error || error.error;
      }).finally(function () {
        return _this4.createInProgress = false;
      });
    },
    handleValidation: function handleValidation(data) {
      this.titleError = '';
      if (!data.title) {
        this.titleError = this.translate.titleCannotBeEmpty;
      }
      return !this.titleError;
    },
    onUserSelected: function onUserSelected(users) {
      this.responsibleUserIds = users.map(function (item) {
        return item.value;
      });
    },
    onSingleSelectUserSelected: function onSingleSelectUserSelected(id) {
      this.responsibleUserIds = [id];
    }
  })
};