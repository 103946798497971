// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-4a18db58]  .browser-default table tr,[data-v-4a18db58]  .browser-default table td {
  border: 1px solid #000;
}
[data-v-4a18db58]  .browser-default ol,[data-v-4a18db58]  .browser-default ul {
  padding-left: 4rem;
}
[data-v-4a18db58]  .browser-default ol li,[data-v-4a18db58]  .browser-default ul li {
  padding-left: 0.3em;
}
[data-v-4a18db58]  .browser-default ol {
  list-style-type: decimal;
}
[data-v-4a18db58]  .browser-default ul {
  list-style-type: disc;
}
[data-v-4a18db58]  .browser-default ul ul {
  list-style-type: circle;
}
[data-v-4a18db58]  .browser-default h1,[data-v-4a18db58]  .browser-default h2,[data-v-4a18db58]  .browser-default h3,[data-v-4a18db58]  .browser-default h4,[data-v-4a18db58]  .browser-default h5,[data-v-4a18db58]  .browser-default h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
[data-v-4a18db58]  .browser-default h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
[data-v-4a18db58]  .browser-default h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
[data-v-4a18db58]  .browser-default h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
[data-v-4a18db58]  .browser-default h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
[data-v-4a18db58]  .browser-default h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
[data-v-4a18db58]  .browser-default h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
[data-v-4a18db58]  .browser-default a {
  text-decoration: underline;
  cursor: pointer;
}
[data-v-4a18db58]  .browser-default blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
