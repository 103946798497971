function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: 'CustomAxisTable',
  components: {
    InputField: InputField,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  props: {
    axisRows: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Rows for the table'
    },
    tableSecondColumn: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    calculationType: {
      required: false,
      type: Number,
      default: 1,
      note: 'Calculation setup, 1 for average, 0 for max-value'
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    },
    finalAxisScore: {
      type: Number,
      default: -1,
      required: false,
      note: 'Axis value for particular spreadsheet'
    },
    editable: {
      type: Boolean,
      default: false,
      require: false,
      note: 'Make fields editable to set their value'
    },
    showColumn0: {
      type: Boolean,
      default: false,
      note: 'If we need to show column 0'
    },
    showResult: {
      type: Boolean,
      default: true,
      note: 'show result - this is the last row of table'
    }
  },
  emits: ['changed', 'change:rows', 'change:secondcolumn', 'change:calctype', 'change:field', 'update:calculationType'],
  data: function data() {
    return {
      cellCssStyles: 'text-center border border-solid border-gray-650 py-2 px-1 max-w-100px',
      rows: [],
      translate: getTranslate['CustomAxisTable']()
    };
  },
  computed: {
    rowLength: function rowLength() {
      if (!this.rows.length) {
        return 0;
      }
      return Object.keys(this.rows[0].options).length + 1;
    },
    notApplicableLabel: function notApplicableLabel() {
      return this.translate.na;
    },
    defaultSecondColumn: function defaultSecondColumn() {
      return ['', this.translate.weight, this.notApplicableLabel];
    },
    fullSecondColumn: function fullSecondColumn() {
      if (!this.tableSecondColumn.length) {
        return [];
      }
      var result = this.tableSecondColumn.map(function (item) {
        return item.value;
      });
      return [].concat(_toConsumableArray(this.defaultSecondColumn), _toConsumableArray(result));
    },
    getCalcSetupTitle: function getCalcSetupTitle() {
      if (this.calculationType === 1) {
        return this.translate.averageValue;
      }
      return this.translate.worstcaseValue;
    },
    calculationTypeOptions: function calculationTypeOptions() {
      return [{
        value: 1,
        label: this.translate.averageValue
      }, {
        value: 2,
        label: this.translate.worstcaseValue
      }];
    }
  },
  watch: {
    calculationType: function calculationType(value) {
      this.$emit('change:calctype', value);
    }
  },
  mounted: function mounted() {
    this.rows = this.axisRows.slice();
  },
  methods: {
    onLabelClick: function onLabelClick(index, value) {
      var oldValue = this.rows[index].value;
      // value can be null
      if (this.readOnly || +oldValue === +value) {
        return;
      }
      this.rows[index].value = value;
      this.$forceUpdate();
      this.$emit('changed', this.rows[index]);
    },
    emitChangeField: function emitChangeField(value, item, fieldType) {
      this.$emit('change:field', {
        id: item.id,
        value: value,
        fieldname: "".concat(fieldType, "_").concat(item.id)
      });
    },
    emitChangeFieldWeigth: function emitChangeFieldWeigth(value, item, fieldType) {
      this.$emit('change:field', {
        id: item.id,
        value: +value,
        fieldType: fieldType
      });
    }
  }
};