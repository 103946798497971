import i18n from '@/i18n';
export var getTranslate = {
  LinkCompany: function LinkCompany() {
    return {
      linkExistingCompany: i18n.t('Link existing company'),
      linkCompany: i18n.t('Link company'),
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      selectCompany: i18n.t('Select company'),
      nothingToShow: i18n.t('Nothing to show')
    };
  },
  LinkedCompany: function LinkedCompany() {
    return {
      companyInformation: i18n.t('Company information'),
      name: i18n.t('Name'),
      status: i18n.t('Status'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      countryCodeCountry: i18n.t('Country code/Country'),
      email: i18n.t('Email'),
      phone: i18n.t('Phone'),
      vatNumber: i18n.t('VAT number'),
      phoneNumber: i18n.t('Phone number'),
      unlinkCompany: i18n.t('Unlink company')
    };
  }
};