function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import UserSettings from '@/components/Pages/UserSettings/UserSettings';
import UserSettingsProfile from '@/components/Pages/UserSettings/UserSettingsProfile';
import UserSettingsPassword from '@/components/Pages/UserSettings/UserSettingsPassword';
import UserSettingsNotifications from '@/components/Pages/UserSettings/UserSettingsNotifications';
import OrganisationTree from '@/components/Pages/Admin/OrganisationTree';
import SecurityGroups from '@/components/Pages/Compliance/Admin/SecurityGroups';
import AdminControlPanel from '@/components/Pages/Admin/AdminControlPanel';
import AdminLoginsReport from '@/components/Pages/Admin/AdminLoginsReport';
import BulkUpdatesEntry from '@/components/Pages/Admin/BulkUpdatesEntry';
import AdminSwitchOrganisations from '@/components/Pages/Admin/AdminSwitchOrganisations';
import AdminSwitchFrameworks from '@/components/Pages/Admin/AdminSwitchFrameworks';
import AdminSwitchActivities from '@/components/Pages/Admin/AdminSwitchActivities';
import AdminUsersChangelog from '@/components/Pages/Admin/AdminUsersChangelog';
import AdminAccessChangelog from '@/components/Pages/Admin/AdminAccessChangelog';
import AdminSettingsChangelog from '@/components/Pages/Admin/AdminSettingsChangelog';
import AdminAuditReportsEntry from '@/components/Pages/Admin/AdminAuditReportsEntry';
import AdminControls from '@/components/Pages/Admin/AdminControls';
import RiskAdminPeriods from '@/components/Organisms/RiskAdmin/RiskAdminPeriods';
import AdminRisksThreatsVulnerability from '@/components/Pages/Admin/AdminRisksThreatsVulnerability';
import RiskControlPanel from '@/components/Pages/Admin/RiskControlPanel';
import AdminRiskHeatMap from '@/components/Pages/Admin/AdminRiskHeatMap';
import AdminRiskScoring from '@/components/Pages/Admin/AdminRiskScoring';
import RismaAdminEntry from '@/components/Pages/Admin/RismaAdminEntry';
import UserRightsAdmin from '@/components/Pages/Admin/UserRightsAdmin';
import UsersAnonymization from '@/components/Pages/Admin/UsersAnonymization';
import UserActionConfigurationEntry from '@/components/Pages/Admin/UserActionConfigurationEntry';
import UserActionConfiguration from '@/components/Pages/Admin/UserActionConfiguration';
import ChangelogReport from '@/components/Pages/Reports/ChangelogReport';
import EmailsAdmin from '@/components/Pages/Admin/EmailsAdmin';
import SiemControlPanel from '@/components/Pages/Admin/SiemControlPanel';
import AdminActivityTypes from '@/components/Pages/Admin/AdminActivityTypes';
import ActionControlPanel from '@/components/Pages/Admin/ActionControlPanel';
import ControlControlPanel from '@/components/Pages/Admin/ControlControlPanel';
import ContractsControlPanel from '@/components/Pages/Admin/ContractsControlPanel';
import AppControlPanel from '@/components/Pages/Admin/AppControlPanel';
import IncidentsControlPanel from '@/components/Pages/Admin/IncidentsControlPanel';
import IncidentsWorkflows from '@/components/Pages/Admin/IncidentsWorkflows';
import IncidentsWorkflowEdit from '@/components/Pages/Admin/IncidentsWorkflowEdit';
import IncidentsCustomWorkflowEdit from '@/components/Pages/Admin/IncidentsCustomWorkflowEdit';
import AdminTagsFieldWell from '@/components/Organisms/Admin/AdminTagsFieldWell';
import AdminNonWorkingDaysWell from '@/components/Organisms/Admin/AdminNonWorkingDaysWell';
import ProjectsAdmin from '@/components/Pages/Admin/ProjectsAdmin';
import ComplianceControlPanel from '@/components/Pages/Admin/ComplianceControlPanel';
import ComplianceAssetsSettings from '@/components/Pages/Admin/ComplianceAssetsSettings';
import ComplianceRiskAssessment from '@/components/Pages/Admin/ComplianceRiskAssessment';
import ProjectsNodeTemplates from '@/components/Pages/Admin/ProjectsNodeTemplates';
import QuestionnaireAdmin from '@/components/Pages/Admin/QuestionnaireAdmin';
import LiaTiaAdmin from '@/components/Pages/Admin/LiaTiaAdmin';
import GapSchemasAdmin from '@/components/Pages/Admin/GapSchemasAdmin';
import QuestionRules from '@/components/Pages/Admin/QuestionRules';
import RelevantQuestions from '@/components/Pages/Compliance/Admin/RelevantQuestions';
import WorkflowStatuses from '@/components/Pages/Admin/WorkflowStatuses';
import LookFeelPage from '@/components/Pages/UserSettings/LookFeelPage';
import LookFeelPageWrapper from '@/components/Pages/UserSettings/LookFeelPageWrapper';
import TrafficLightsLookFeelPage from '@/components/Pages/UserSettings/TrafficLightsLookFeelPage';
import DataImport from '@/components/Pages/Admin/DataImport';
import PreviewDataImport from '@/components/Pages/Admin/PreviewDataImport';
import RiskAdminPeriodDescription from '@/components/Organisms/RiskAdmin/RiskAdminPeriodDescription';
import ErmPeriodDescription from '@/components/Pages/Admin/ErmPeriodDescription';
import UserRightsTable from '@/components/Organisms/UserRightsTable';
import UserRightsExpanded from '@/components/Organisms/UserRightsExpanded';
import AppInvitations from '@/components/Pages/UserSettings/AppInvitations';
import AppInvitationsEntry from '@/components/Pages/Admin/AppInvitationsEntry';
import AdminSuggestedActivities from '@/components/Pages/Admin/SuggestedActivities/AdminSuggestedActivities';
import CloseYear from '@/components/Pages/Admin/CloseYear';
import AdminDocumentTypes from '@/components/Pages/Admin/AdminDocumentTypes';
import Frameworks from '@/components/Organisms/RismaAdmin/Frameworks';
import AdminAiPartner from '@/components/Pages/Admin/AdminAiPartner';
import AdminAiPartnerAccess from '@/components/Organisms/Admin/AdminAiPartnerAccess';
import { isAdmin, userHasLevelControls, userHasLevelRisk, userHasLevelExecution, featureFlagAccess, userHasLevelContracts, userHasLevelIncident, userHasAnyComplianceProjectAccess, userHasControlPanelAccess, userHasRiskProjectAccess, currentAuthMethodConfigurable } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import rismaadmin from '@/routes/rismaadmin';
import RiskAdminProjects from '@/components/Organisms/RiskAdmin/RiskAdminProjects';
import { MODULES } from '@/constants/modules';
export default (function () {
  var routes = [{
    path: '/settings',
    component: UserSettings,
    meta: {
      keyFunction: function keyFunction() {
        return '/settings';
      }
    },
    props: true,
    children: [{
      path: 'user',
      component: UserSettingsProfile,
      name: 'UserSettingsProfile'
    }, {
      path: 'change-password',
      component: UserSettingsPassword
    }, {
      path: 'notifications',
      component: UserSettingsNotifications
    }, {
      path: 'administration',
      component: UserRightsAdmin,
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: '',
        component: UserRightsTable,
        name: 'UserRightsTable'
      }, {
        path: 'users-view',
        component: UserRightsExpanded
      }]
    }, {
      path: 'anonymization',
      component: UsersAnonymization,
      meta: {
        userRightsFunction: function userRightsFunction() {
          return isAdmin() && featureFlagAccess('feature.anonymization_admin_enabled')();
        }
      }
    }, {
      path: 'requirements',
      component: UserActionConfigurationEntry,
      redirect: {
        name: 'UserActionConfiguration'
      },
      meta: {
        userRightsFunction: function userRightsFunction() {
          return isAdmin() && featureFlagAccess('feature.admin.action_requirements_enabled')();
        }
      },
      children: [{
        path: 'configuration',
        name: 'UserActionConfiguration',
        component: UserActionConfiguration
      }, {
        path: 'changelog',
        component: ChangelogReport,
        props: {
          module: MODULES.ACCESS_REQUIREMENTS
        }
      }]
    }, {
      path: 'emails',
      component: EmailsAdmin,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'siem',
      component: SiemControlPanel,
      meta: {
        userRightsFunction: function userRightsFunction() {
          return isAdmin() && featureFlagAccess('feature.admin.siem_enabled')();
        }
      }
    }, {
      path: 'controlpanel',
      component: AdminControlPanel,
      name: 'AdminControlPanel',
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasControlPanelAccess(UserLevels.SUPER);
        }
      }
    }, {
      path: 'controlsadmin',
      component: AdminControls,
      name: 'AdminControls',
      meta: {
        userRightsFunction: userHasLevelControls(UserLevels.SUPER)
      }
    }, {
      path: 'threatsvulnerability',
      component: AdminRisksThreatsVulnerability,
      name: 'AdminRisksThreatsVulnerability',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'ermadmin',
      component: AdminRiskScoring,
      name: SETTINGS_ROUTE_NAMES.erm_admin,
      props: function props(route) {
        return {
          errorScoring: route.params.errorScoring
        };
      },
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'ermadmin/scoring/:scoringId',
      component: ErmPeriodDescription,
      props: true,
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskadmin/periods/:projectId/:path',
      component: RiskAdminPeriodDescription,
      meta: {
        userRightsFunction: userHasRiskProjectAccess(UserLevels.SUPER)
      }
    }, {
      path: 'complianceprojects',
      component: ProjectsAdmin,
      name: 'ProjectsAdmin',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'complianceriskassessment',
      component: ComplianceRiskAssessment,
      name: 'ComplianceRiskAssessment',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'complianceassets',
      component: ComplianceAssetsSettings,
      name: 'ComplianceAssetsSettings',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'compliancecp',
      component: ComplianceControlPanel,
      name: 'ComplianceControlPanel',
      meta: {
        userRightsFunction: userHasAnyComplianceProjectAccess(UserLevels.SUPER)
      }
    }, {
      path: 'templatenodes',
      component: ProjectsNodeTemplates,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'compliancequestionnaires',
      name: 'QuestionnaireAdmin',
      component: QuestionnaireAdmin,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'contractquestionnaires',
      name: 'ContractQuestionnaireAdmin',
      component: QuestionnaireAdmin,
      props: {
        module: MODULES.CONTRACTS
      },
      meta: {
        userRightsFunction: function userRightsFunction() {
          return isAdmin() && featureFlagAccess('app.module_contracts_enabled')();
        }
      }
    }, {
      path: 'incidentquestionnaires',
      name: 'IncidentQuestionnaireAdmin',
      component: QuestionnaireAdmin,
      props: {
        module: MODULES.INCIDENTS
      },
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasLevelIncident(UserLevels.SUPER);
        }
      }
    }, {
      path: 'liatia',
      component: LiaTiaAdmin,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'gapschemas',
      component: GapSchemasAdmin,
      name: SETTINGS_ROUTE_NAMES.gapSchemas,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'rismarules',
      component: QuestionRules,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'relevantquestions',
      component: RelevantQuestions,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'importdata',
      component: DataImport,
      redirect: {
        name: 'PreviewDataImport',
        params: {
          module: MODULES.COMPLIANCE
        }
      },
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: ':module',
        component: PreviewDataImport,
        name: 'PreviewDataImport',
        props: function props(route) {
          return {
            routeModule: route.params.module || ''
          };
        }
      }]
    }, {
      path: 'organisations',
      component: OrganisationTree,
      name: 'OrganisationTree',
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'adsettings',
      component: SecurityGroups,
      meta: {
        userRightsFunction: function userRightsFunction() {
          return isAdmin() && currentAuthMethodConfigurable();
        }
      }
    }, {
      path: 'rismaadmin',
      component: RismaAdminEntry,
      meta: {
        userRightsFunction: isAdmin
      },
      children: _toConsumableArray(rismaadmin())
    }, {
      path: 'activitytypes',
      component: AdminActivityTypes,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'tags',
      component: AdminTagsFieldWell,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'nonworkingdays',
      component: AdminNonWorkingDaysWell,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'bulk',
      component: BulkUpdatesEntry,
      meta: {
        userRightsFunction: isAdmin
      },
      redirect: {
        name: 'AdminSwitchActivities'
      },
      children: [{
        path: 'switch',
        component: AdminSwitchActivities,
        name: 'AdminSwitchActivities',
        meta: {
          userRightsFunction: isAdmin
        }
      }, {
        path: 'frameworkId',
        component: AdminSwitchFrameworks,
        meta: {
          userRightsFunction: isAdmin && featureFlagAccess('feature.performance_report_enabled')
        }
      }, {
        path: 'organisation',
        component: AdminSwitchOrganisations,
        meta: {
          userRightsFunction: isAdmin
        }
      }]
    }, {
      path: 'reports',
      redirect: {
        name: 'AdminLoginsReport'
      },
      component: AdminAuditReportsEntry,
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: 'login',
        name: 'AdminLoginsReport',
        component: AdminLoginsReport
      }, {
        path: 'users',
        component: AdminUsersChangelog
      }, {
        path: 'settings',
        component: AdminSettingsChangelog
      }, {
        path: 'access',
        component: AdminAccessChangelog
      }]
    }, {
      path: 'customization',
      component: LookFeelPageWrapper,
      redirect: {
        name: 'LookFeelPage'
      },
      meta: {
        userRightsFunction: isAdmin
      },
      children: [{
        path: 'general',
        name: 'LookFeelPage',
        component: LookFeelPage
      }, {
        path: 'trafficlights',
        component: TrafficLightsLookFeelPage,
        meta: {
          userRightsFunction: featureFlagAccess('feature.admin.custom_traffic_light_enabled')
        }
      }]
    }, {
      path: 'actioncp',
      component: ActionControlPanel,
      name: 'ActionControlPanel',
      meta: {
        userRightsFunction: userHasLevelExecution(UserLevels.SUPER)
      }
    }, {
      path: 'controlcp',
      component: ControlControlPanel,
      meta: {
        userRightsFunction: userHasLevelControls(UserLevels.SUPER)
      }
    }, {
      path: 'documenttypes',
      component: AdminDocumentTypes
    }, {
      path: 'riskprojects',
      component: RiskAdminProjects,
      name: 'RiskAdminProjects',
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskproject/:projectId',
      component: RiskAdminPeriods,
      props: true,
      meta: {
        userRightsFunction: userHasRiskProjectAccess(UserLevels.SUPER)
      }
    }, {
      path: 'riskcp',
      component: RiskControlPanel,
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskheatmap',
      component: AdminRiskHeatMap,
      meta: {
        userRightsFunction: userHasLevelRisk(UserLevels.SUPER)
      }
    }, {
      path: 'riskquestionnaires',
      name: 'RiskQuestionnaireAdmin',
      component: QuestionnaireAdmin,
      props: {
        module: MODULES.RISK
      },
      meta: {
        userRightsFunction: function userRightsFunction() {
          return userHasLevelRisk(UserLevels.SUPER);
        }
      }
    }, {
      path: 'appinvitations',
      redirect: '/settings/appinvitations/incident',
      component: AppInvitationsEntry,
      props: true,
      meta: {
        userRightsFunction: featureFlagAccess('feature.app_settings_enabled')
      },
      name: 'AppInvitations',
      children: [{
        path: ':app',
        component: AppInvitations,
        props: true
      }]
    }, {
      path: 'appcontrolpanel',
      component: AppControlPanel,
      name: 'AppControlPanel',
      meta: {
        userRightsFunction: featureFlagAccess('feature.app_settings_enabled')
      }
    }, {
      path: 'contractscontrolpanel',
      component: ContractsControlPanel,
      name: 'ContractsControlPanel',
      meta: {
        userRightsFunction: userHasLevelContracts(UserLevels.SUPER)
      }
    }, {
      path: 'incidentscontrolpanel',
      component: IncidentsControlPanel,
      name: 'IncidentsControlPanel',
      meta: {
        userRightsFunction: userHasLevelIncident(UserLevels.SUPER)
      }
    }, {
      path: 'incidents/workflow',
      meta: {
        userRightsFunction: function userRightsFunction() {
          return isAdmin() && featureFlagAccess('feature.incidents.workflow')();
        }
      },
      children: [{
        path: '',
        component: IncidentsWorkflows,
        name: 'IncidentsWorkflows'
      }, {
        path: ':id/custom',
        component: IncidentsCustomWorkflowEdit,
        name: 'IncidentsCustomWorkflowEdit',
        props: true
      }, {
        path: ':id',
        component: IncidentsWorkflowEdit,
        name: 'IncidentsWorkflowEdit',
        props: true
      }]
    }, {
      path: 'workflowstatuses',
      component: WorkflowStatuses,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'suggestedactivities',
      component: AdminSuggestedActivities,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'closeYear',
      component: CloseYear,
      meta: {
        userRightsFunction: isAdmin
      }
    }, {
      path: 'framework',
      component: Frameworks,
      meta: {
        userRightsFunction: isAdmin && featureFlagAccess('feature.performance_report_enabled')
      }
    }, {
      path: 'aipartner',
      component: AdminAiPartner,
      redirect: {
        name: SETTINGS_ROUTE_NAMES.adminAiPartnerAccess
      },
      meta: {
        userRightsFunction: isAdmin && featureFlagAccess('feature.overall.ai_assistant_enabled')
      },
      children: [{
        path: 'access',
        component: AdminAiPartnerAccess,
        name: SETTINGS_ROUTE_NAMES.adminAiPartnerAccess,
        meta: {
          userRightsFunction: isAdmin
        }
      }]
    }]
  }];
  return routes;
});