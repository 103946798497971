// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table[data-v-7aedfdc3] {
  width: 80%;
  margin: 0 auto;
  table-layout: fixed;
}
table thead tr th[data-v-7aedfdc3] {
  font-size: 0.7rem;
  padding: 0.5rem;
  color: rgb(171.5, 171.5, 171.5);
}
table tbody tr[data-v-7aedfdc3]:nth-child(even) {
  background-color: rgb(222.5, 222.5, 222.5);
}
table tbody tr[data-v-7aedfdc3]:nth-child(odd) {
  background-color: #c5c5c5;
}
table tbody tr td[data-v-7aedfdc3] {
  font-size: 1rem;
  padding: 0.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
