import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "date-range-picker"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  class: "mr-auto"
};
var _hoisted_4 = {
  class: "mr-4 text-xs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_date_picker = _resolveComponent("date-picker");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    class: "cursor-pointer",
    onClick: $options.handleOpen
  }, null, 8 /* PROPS */, ["title", "onClick"])) : _createCommentVNode("v-if", true), _createVNode(_component_date_picker, {
    value: $data.value,
    open: $data.isOpenState,
    range: true,
    "value-type": "format",
    format: $props.dateFormat,
    lang: $data.locale,
    clearable: $props.clearValuesOnCancel,
    "append-to-body": $props.appendToBody,
    "popup-class": $props.popupClasses,
    placeholder: $props.dateFormat,
    separator: " - ",
    type: $props.type,
    onChange: $options.handleChange,
    onClear: $options.discardDate,
    onOpen: $options.handleOpen
  }, {
    "icon-calendar": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "calendar",
        class: "mx-2 stroke-3 text-blue-750 fill-none"
      })];
    }),
    sidebar: _withCtx(function (_ref) {
      var emit = _ref.emit;
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.ranges, function (rangeItem, index) {
        return _openBlock(), _createElementBlock("button", {
          key: rangeItem.text,
          class: _normalizeClass(["mx-btn-text mx-btn-shortcut", $options.activeRangeIndex === index ? $data.activeRangeStyles : '']),
          onClick: function onClick($event) {
            return $options.handleShortcutClick(rangeItem.range, emit);
          }
        }, _toDisplayString(rangeItem.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_2);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", {
        class: _normalizeClass(["mx-btn-text text-xs leading-6", $options.activeRangeIndex === -1 ? $data.activeRangeStyles : ''])
      }, _toDisplayString($data.translate.customRange), 3 /* TEXT, CLASS */)];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($options.customRangePreview), 1 /* TEXT */), _createElementVNode("button", {
        class: "mx-btn-text text-xs mr-4",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleCancel && $options.handleCancel.apply($options, arguments);
        })
      }, _toDisplayString($options.cancelLabelTranslated), 1 /* TEXT */), _createElementVNode("button", {
        class: "bg-blue-350 border border-blue-350 text-white hover:border-blue-650 hover:bg-blue-650 py-0.5 px-1 text-xs",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.handleApply && $options.handleApply.apply($options, arguments);
        })
      }, _toDisplayString($data.translate.apply), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["value", "open", "format", "lang", "clearable", "append-to-body", "popup-class", "placeholder", "type", "onChange", "onClear", "onOpen"])])), [[_directive_click_outside, $options.handleClickOutside]]);
}