import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "questionnaire-scoring-assessments"
};
var _hoisted_2 = {
  class: "questionnaire-items"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1,
  class: "questionnaire-table"
};
var _hoisted_5 = {
  class: "thead-cell"
};
var _hoisted_6 = {
  class: "thead-cell"
};
var _hoisted_7 = {
  class: "thead-cell"
};
var _hoisted_8 = {
  class: "thead-cell"
};
var _hoisted_9 = {
  class: "item-operator"
};
var _hoisted_10 = {
  class: "item-score option-field"
};
var _hoisted_11 = {
  class: "item-text option-field"
};
var _hoisted_12 = {
  class: "item-color option-field"
};
var _hoisted_13 = {
  class: "item-delete"
};
var _hoisted_14 = {
  class: "actions"
};
var _hoisted_15 = {
  class: "action-elem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_color_picker = _resolveComponent("color-picker");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.scoringAssessments,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [!$data.items.length ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($data.translate.noItems), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.items.length ? (_openBlock(), _createElementBlock("table", _hoisted_4, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.operator,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_6, [_createVNode(_component_risma_title, {
    title: $data.translate.score,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.assessmentText,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.color,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _cache[1] || (_cache[1] = _createElementVNode("th", null, null, -1 /* HOISTED */))])]), _createVNode(_TransitionGroup, {
    name: "list",
    tag: "tbody"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, function (item) {
        return _openBlock(), _createElementBlock("tr", {
          key: item._uniqueId,
          class: "list-unit"
        }, [_createElementVNode("td", _hoisted_9, [_createElementVNode("span", null, _toDisplayString($options.humanOperator(item.operator)), 1 /* TEXT */)]), _createElementVNode("td", _hoisted_10, [_createVNode(_component_input_field, {
          modelValue: item.score,
          "onUpdate:modelValue": [function ($event) {
            return item.score = $event;
          }, $options.onDataChanged],
          placeholder: $data.translate.score,
          maxlength: 10,
          type: "text",
          onBlur: $options.onBlur
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "onBlur"])]), _createElementVNode("td", _hoisted_11, [_createVNode(_component_input_field, {
          modelValue: item.assessmentText,
          "onUpdate:modelValue": [function ($event) {
            return item.assessmentText = $event;
          }, $options.onDataChanged],
          placeholder: $data.translate.assessmentText,
          maxlength: 500,
          title: item.assessmentText,
          type: "text"
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "title"])]), _createElementVNode("td", _hoisted_12, [_createVNode(_component_color_picker, {
          color: item.color,
          onInput: function onInput($event) {
            return $options.onColorChanged(item, $event);
          }
        }, null, 8 /* PROPS */, ["color", "onInput"])]), _createElementVNode("td", _hoisted_13, [_createVNode(_component_feather_icon, {
          color: $data.colors.system.warning,
          icon: "x",
          class: "cursor-pointer",
          onClick: function onClick($event) {
            return $options.removeOption(item);
          }
        }, null, 8 /* PROPS */, ["color", "onClick"])])]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("a", {
    href: "#",
    class: "add-option",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.addOption && $options.addOption.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    color: $data.colors.system.primaryDark,
    icon: "plus"
  }, null, 8 /* PROPS */, ["color"]), _createElementVNode("span", null, _toDisplayString($data.translate.addRow), 1 /* TEXT */)])])])]);
}