function buildPoliciesMenu(settings) {
  var menuStructure = [];
  menuStructure.push({
    url: '/compliance/processtree',
    title: 'Overview',
    exact: true,
    rights: settings['feature.process_tree'] === 1
  });
  menuStructure.push({
    url: '/compliance/processtree/report',
    title: 'Report',
    rights: settings['feature.process_tree_report'] === 1
  });
  menuStructure.push({
    url: settings['feature.compliance_process_tree.awareness_quiz'] ? '/awareness/dashboard/overview/all' : '/awareness/dashboard',
    title: 'Awareness',
    translatable: true,
    aliasesRegex: '/awareness/dashboard/*',
    rights: settings['feature.awareness_enabled'] === 1,
    checkActiveSubItem: function checkActiveSubItem(url) {
      return url.startsWith('/awareness/dashboard');
    }
  });
  return menuStructure;
}
export default buildPoliciesMenu;