import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-top"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$control;
  var _component_traffic_light = _resolveComponent("traffic-light");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.control.isMothercontrol ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    color: _ctx.getControlTrafficLightColorByNumber($props.control.trafficLight),
    class: "mr-1 mt-1 flex-none"
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createElementVNode("span", {
    class: _normalizeClass({
      'line-through': typeof $props.control.active !== 'undefined' && !$props.control.active
    })
  }, _toDisplayString("".concat(((_$props$control = $props.control) === null || _$props$control === void 0 ? void 0 : _$props$control.cpath) || '', " ").concat($props.control.title)), 3 /* TEXT, CLASS */)]);
}