// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataflow-modal[data-v-5c673742]  .modal-container {
  overflow: visible;
  width: 65vw !important;
}
.dataflow-modal[data-v-5c673742]  .invalid > div {
  border: 1px solid #B03D00;
}
.dataflow-modal[data-v-5c673742]  .well {
  min-height: 70vh;
}
.dataflow-modal[data-v-5c673742]  .well .noResult {
  position: absolute;
  top: 25vh;
  left: 38%;
  display: inline-block;
}
.dataflow-modal[data-v-5c673742]  .well .footer {
  position: absolute;
  bottom: 0;
}
.dataflow-modal[data-v-5c673742]  .well .footer .risma-title h3 {
  color: #B03D00;
}
.dataflow-modal[data-v-5c673742]  .well .footer .lists .risma-title {
  display: inline-block;
  color: #B03D00;
}
.dataflow-modal[data-v-5c673742]  .well .footer .lists .risma-title p {
  font-weight: bolder;
  margin-bottom: 0;
}
.dataflow-modal[data-v-5c673742]  .well .footer .lists .listItems {
  display: inline-block;
}
.dataflow-modal[data-v-5c673742]  .well .footer .lists .listItems span.listItem {
  margin-bottom: 1rem;
  display: inline-block;
  color: #B03D00;
  font-size: 14px;
  line-height: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
