import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { generateKey } from '@/utils/Utils';
import { getThreatAssessmentsReport } from '@/api/risk/risk';
export default {
  name: 'RiskReportAssessedThreats',
  components: {
    RismaTable: RismaTable
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      tableGeneratedKey: generateKey(),
      tableHeader: this.$trans('Threat Assessments')
    };
  },
  methods: {
    getDataBE: function getDataBE(params) {
      return getThreatAssessmentsReport(this.projectId, params);
    }
  }
};