import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex flex-col"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_static_table = _resolveComponent("static-table");
  return $options.dataFlowMetaDataset.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 0,
    "dataset-table": $options.dataFlowMetaDataset,
    columns: $options.dataFlowMetaColumns,
    "columns-min-width": $data.columnsMinWidth,
    theme: "zebra"
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList($data.metaOptions, function (metaOption) {
    return {
      name: metaOption,
      fn: _withCtx(function (_ref) {
        var slotData = _ref.slotData;
        return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotData, function (item, index) {
          return _openBlock(), _createElementBlock("div", {
            key: "".concat(metaOption, "_").concat(index),
            class: "py-2"
          }, _toDisplayString(item), 1 /* TEXT */);
        }), 128 /* KEYED_FRAGMENT */))])];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-table", "columns", "columns-min-width"])) : _createCommentVNode("v-if", true);
}