// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-view[data-v-a0c450da] {
  position: relative;
}
.search-field[data-v-a0c450da] {
  position: absolute;
  right: 0;
  top: -95px;
}
[data-v-a0c450da]  table.static-table tbody tr > td:last-child {
  text-align: right;
  padding-right: 5px;
}
[data-v-a0c450da]  table.static-table tbody tr td .fa {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
