import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "notification-pop-up flex fixed top-130px right-0 p-4 shadow-notification-pop-up rounded-l-lg bg-white z-110"
};
var _hoisted_2 = {
  tabindex: "0",
  class: "mr-6 focus:outline-none"
};
var _hoisted_3 = {
  class: "w-8 flex items-center justify-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_focus = _resolveDirective("focus");
  return _openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_Transition, {
    name: "slide"
  }, {
    default: _withCtx(function () {
      return [$data.showElement ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass([$options.stylesByType.borderColor, "min-w-3rem w-12 flex items-center border-l-4"])
      }, [_createVNode(_component_feather_icon, {
        icon: $options.stylesByType.icon,
        class: _normalizeClass([$options.stylesByType.iconColor, "m-auto stroke-2"])
      }, null, 8 /* PROPS */, ["icon", "class"])], 2 /* CLASS */), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])), [[_directive_focus, $props.focus]]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
        icon: "x",
        class: "cursor-pointer hover:text-gray-450",
        onClick: $options.dismiss
      }, null, 8 /* PROPS */, ["onClick"])])])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  })]);
}