// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-reset-styles[data-v-2684192f]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-2684192f]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-2684192f]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-2684192f]  li {
  padding-left: 0.3em;
}
.watermark-background[data-v-2684192f] {
  background-position: -85% 50%;
  background-size: 80%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
