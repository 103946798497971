function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import * as RiskApi from '@/api/risk/risk';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RiskAdminModal from '@/components/Organisms/RiskAdmin/RiskAdminModal';
import RiskThreatModal from '@/components/Organisms/Admin/ThreatAndVulnerability/RiskThreatModal';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import TabList from '@/components/Atoms/TabList';
import Changelog from '@/components/Molecules/Changelog';
import { RiskAdminPages } from '@/constants/risks/RiskAdminPages';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { defaultAxisLabels } from '@/constants/risks/RiskPeriodDefaultData';
import { MODULES } from '@/constants/modules';
import * as Utils from '@/utils/Utils';
import { sortAlphabetic } from '@/utils/sort';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'RiskAdminManagement',
  introduction: 'Risk Admin Management',
  description: 'Admin component for threat and vulnerability catalogues',
  token: '<risk-admin-management :page-name="threat"></risk-admin-management>',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    RiskAdminModal: RiskAdminModal,
    RiskThreatModal: RiskThreatModal,
    SingleSelect: SingleSelect,
    Checkboxes: Checkboxes,
    TabList: TabList,
    Changelog: Changelog
  },
  props: {
    pageName: {
      type: String,
      required: true,
      note: 'threat or vulnerability'
    },
    filteredRiskProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of risk projects, except ERM'
    },
    items: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of threats or vulnerabilities'
    },
    threatCategories: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of threat categories'
    }
  },
  emits: ['refreshRiskProjects', 'refresh:threats', 'create:category'],
  setup: function setup() {
    return {
      allExpanded: getLocalStorageComputed('risk_admin_management_threats_expanded', false, true)
    };
  },
  data: function data() {
    return {
      defaultAxisLabels: defaultAxisLabels,
      idToDelete: null,
      newEntity: '',
      newEntityValidation: null,
      maxlength: 255,
      cellCssStyles: 'text-left p-2 table-cell',
      bottomBorderedItemStyles: ' border-b border-gray-450',
      selectedProjectId: this.filteredRiskProjects[0].id,
      expandedArray: [],
      threadSelected: null,
      deleteMessageData: {},
      currentTab: 'catalogue',
      translate: getTranslate['RiskAdminManagement']()
    };
  },
  computed: {
    impactLevels: function impactLevels() {
      return Array.from({
        length: this.getRightLength('consequenceLevels') + 1
      }, function (v, k) {
        return {
          label: k.toString(),
          value: k
        };
      });
    },
    likelihoodLevels: function likelihoodLevels() {
      return Array.from({
        length: this.getRightLength('probabilityLevels') + 1
      }, function (v, k) {
        return {
          label: k.toString(),
          value: k
        };
      });
    },
    isVulnerability: function isVulnerability() {
      return this.pageName === RiskAdminPages.VULNERABILITY;
    },
    isThreat: function isThreat() {
      return this.pageName === RiskAdminPages.THREAT;
    },
    isThreatCatalogue: function isThreatCatalogue() {
      return this.items[0] && this.items[0].objType === ObjectTypes.RISK_THREAT;
    },
    selectedProjectIndex: function selectedProjectIndex() {
      var _this = this;
      if (!this.selectedProjectId) {
        return 0;
      }
      return this.riskProjects.findIndex(function (project) {
        return project.value === _this.selectedProjectId;
      });
    },
    currentPeriod: function currentPeriod() {
      var _this$filteredRiskPro;
      return (_this$filteredRiskPro = this.filteredRiskProjects[this.selectedProjectIndex]) === null || _this$filteredRiskPro === void 0 ? void 0 : _this$filteredRiskPro.projectPeriods.find(function (period) {
        return period.isCurrent;
      });
    },
    rightTitle: function rightTitle() {
      if (this.isThreat) {
        return this.translate.threat;
      }
      return this.translate.vulnerability;
    },
    dataset: function dataset() {
      var _this2 = this;
      var result = this.items.map(function (item) {
        return _this2.createDataItem(item);
      });
      sortAlphabetic(result, 'title');
      return result;
    },
    riskProjects: function riskProjects() {
      var _this3 = this;
      return JSON.parse(JSON.stringify(this.filteredRiskProjects)).map(function (project) {
        return {
          label: project.title,
          value: project.id,
          id: project.id,
          allSelected: !_this3.dataset.some(function (item) {
            return !item.projects.find(function (p) {
              return p.id === project.id;
            }).isActive;
          })
        };
      });
    },
    entityMainTitle: function entityMainTitle() {
      return this.isThreat ? this.translate.threatCatalogue : this.translate.vulnerabilityCatalogue;
    },
    entityMainDescription: function entityMainDescription() {
      if (this.isThreat) {
        return this.translate.theThreatCatalogueIsAGlobalCatalogueButCa;
      } else {
        return this.translate.theVulnerabilityCatalogueIsAGlobalCatalogue;
      }
    },
    importTitle: function importTitle() {
      return this.isThreat ? this.translate.importThreats : this.translate.importVulnerabilities;
    },
    entityPlaceholderText: function entityPlaceholderText() {
      return this.isThreat ? this.translate.typeThreatTitle : this.translate.typeVulnerabilityTitle;
    },
    createNewEntityButtonText: function createNewEntityButtonText() {
      return this.isThreat ? this.translate.createNewThreat : this.translate.createNewVulnerability;
    },
    tabs: function tabs() {
      return [{
        name: this.entityMainTitle,
        slotName: 'catalogue'
      }, {
        name: this.translate.changelog,
        slotName: 'changelog'
      }];
    },
    changelogData: function changelogData() {
      return {
        moduleName: MODULES.THREAT
      };
    }
  },
  watch: {
    items: {
      deep: true,
      handler: function handler() {
        this.recountExpandedArray();
      }
    }
  },
  mounted: function mounted() {
    this.createExpandArray();
  },
  methods: {
    expandAll: function expandAll() {
      var _this4 = this;
      this.allExpanded = !this.allExpanded;
      this.expandedArray.forEach(function (item) {
        return item.isExpanded = _this4.allExpanded;
      });
    },
    switchItem: function switchItem(array, key) {
      array[key].isExpanded = !array[key].isExpanded;
      this.resetAllExpanded();
    },
    resetAllExpanded: function resetAllExpanded() {
      this.allExpanded = this.expandedArray.some(function (item) {
        return item.isExpanded;
      });
    },
    createExpandArray: function createExpandArray() {
      var _this5 = this;
      this.$emit('refresh:threats', this.selectedProjectId);
      this.expandedArray = [];
      var items = _toConsumableArray(this.items);
      sortAlphabetic(items, 'title');
      items.forEach(function (item) {
        _this5.expandedArray.push({
          id: item.id,
          title: item.title,
          isExpanded: _this5.allExpanded
        });
      });
    },
    recountExpandedArray: function recountExpandedArray() {
      var _this6 = this;
      var items = _toConsumableArray(this.items);
      sortAlphabetic(items, 'title');
      items.forEach(function (item, key) {
        if (!_this6.expandedArray[key] || _this6.expandedArray[key].id !== item.id) {
          var foundIndex = _this6.expandedArray.findIndex(function (state) {
            return state.id === item.id;
          });
          if (foundIndex === -1) {
            _this6.expandedArray.splice(key, 0, {
              id: item.id,
              title: item.title,
              isExpanded: _this6.allExpanded
            });
          } else {
            _this6.expandedArray.splice(key, foundIndex - key);
          }
        }
      });
    },
    getConcequenceParameters: function getConcequenceParameters(field, selectedProjectIndex) {
      return field.projects[selectedProjectIndex].consequenceParameters;
    },
    getRightLength: function getRightLength(parameter) {
      var _this7 = this;
      var riskProject = this.filteredRiskProjects.find(function (project) {
        return project.id === _this7.selectedProjectId;
      });
      return riskProject.projectPeriods.find(function (period) {
        return period.id === riskProject.activePeriodId;
      })[parameter];
    },
    onChangeSelect: function onChangeSelect(data, parameter, field, project, value) {
      var rightAssessment = this.findRightAssessment(data.assessments, parameter);
      if (rightAssessment) {
        rightAssessment[field] = value;
      }
      this.updateField(data, project);
    },
    findRightAssessment: function findRightAssessment(assessments, parameter) {
      return assessments.find(function (item) {
        return parameter.label === item.label;
      });
    },
    getRightSelect: function getRightSelect(data, parameter, field) {
      var rightAssessment = this.findRightAssessment(data.assessments, parameter);
      return rightAssessment && rightAssessment[field] || 0;
    },
    createDataItem: function createDataItem(item) {
      var result = {
        title: item.title,
        id: item.id ? item.id : null,
        projects: this.prepareItemProjects(item.id),
        assessments: item.assessments,
        objType: item.objType
      };
      if (this.isThreat) {
        var _this$threatCategorie;
        result.categoryTitle = item.category ? (_this$threatCategorie = this.threatCategories.find(function (category) {
          return category.id === item.categoryId;
        })) === null || _this$threatCategorie === void 0 ? void 0 : _this$threatCategorie.title : '', result.categoryId = item.categoryId;
      }
      return result;
    },
    handleChangeParams: function handleChangeParams(field, parameter, project, isChecked) {
      if (isChecked) {
        field.assessments.push({
          label: parameter.label,
          threatId: field.id,
          impact: 0,
          likelihood: 0,
          projectId: project.id
        });
      } else {
        var index = field.assessments.findIndex(function (item) {
          return item.label === parameter.label;
        });
        if (index !== -1) {
          field.assessments.splice(index, 1);
        }
      }
      this.updateField(field, project);
    },
    prepareItemProjects: function prepareItemProjects(id) {
      var _this8 = this;
      var result = [];
      var riskKey = this.isThreat ? 'threats' : 'vulnerabilities';
      result = this.filteredRiskProjects.map(function (riskProject) {
        var item = {
          title: riskProject.title,
          type: riskProject.type,
          id: riskProject.id,
          isActive: !!riskProject[riskKey].find(function (item) {
            return item.id === id;
          })
        };
        if (_this8.isThreatCatalogue) {
          var rightPeriod = riskProject.projectPeriods.find(function (period) {
            return period.id === riskProject.activePeriodId;
          });
          if (rightPeriod) {
            item.consequenceParameters = rightPeriod.consequenceRows;
          }
        }
        return item;
      });
      return result;
    },
    onCreateEntity: function onCreateEntity() {
      return this.isThreat ? this.showEditModal() : this.createEntity();
    },
    createEntity: function createEntity() {
      var _this9 = this;
      if (typeof this.newEntity === 'string') {
        this.newEntity = this.newEntity.trim();
      }
      if (this.validate()) {
        var data = {
          projectId: this.selectedProjectId,
          title: Utils.setMaxlength(this.newEntity, this.maxlength)
        };
        var serviceName = this.isThreat ? 'createThreat' : 'createVulnerability';
        return RiskApi[serviceName](data).then(function () {
          _this9.eraseNewEntity();
          _this9.$emit('refreshRiskProjects');
        }).catch(function (err) {
          throw err;
        });
      }
    },
    handleChange: function handleChange(isActive, field, project) {
      var _this10 = this;
      project.isActive = isActive;
      this.updateField(field, project).then(function () {
        return _this10.$emit('refreshRiskProjects');
      });
    },
    updateField: function updateField(field, project) {
      if (!project.isActive) {
        return this.removeRiskAdminItem(project.id, field.id);
      }
      if (field.id) {
        return this.updateRiskAdminItem(project.id, field);
      }
    },
    updateRiskAdminItem: function updateRiskAdminItem(projectId, field) {
      var _this11 = this;
      var data = {
        projectId: projectId,
        title: field.title
      };
      if (this.isThreat) {
        data.assessments = field.assessments || [];
        return RiskApi.updateThreat(field.id, data).catch(function () {
          return _this11.$notify({
            title: _this11.translate.somethingWentWrong,
            type: 'error'
          });
        });
      }
      if (this.isVulnerability) {
        return RiskApi.updateVulnerability(field.id, data).catch(function () {
          return _this11.$notify({
            title: _this11.translate.somethingWentWrong,
            type: 'error'
          });
        });
      }
    },
    removeRiskAdminItem: function removeRiskAdminItem(projectId, itemId) {
      var data = {
        projectId: projectId
      };
      if (this.isThreat) {
        data.threatId = itemId;
      }
      if (this.isVulnerability) {
        data.vulnerabilityId = itemId;
      }
      return RiskApi.deleteThreatFromProject(data);
    },
    eraseNewEntity: function eraseNewEntity() {
      this.newEntity = null;
    },
    validate: function validate() {
      var result = true;
      this.resetValidation();
      if (!this.newEntity) {
        result = false;
        this.newEntityValidation = this.translate.pleaseFillOutThisField;
      }
      return result;
    },
    resetValidation: function resetValidation() {
      this.newEntityValidation = null;
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this12 = this;
      var serviceName = this.isThreat ? 'deleteThreat' : 'deleteVulnerability';
      return RiskApi[serviceName](this.idToDelete).then(function () {
        _this12.isThreat ? _this12.$emit('refresh:threats', _this12.selectedProjectId) : _this12.$emit('refreshRiskProjects');
      }).catch(function (e) {
        throw e;
      });
    },
    deleteEntity: function deleteEntity(fieldId, objType) {
      var _this13 = this;
      if (fieldId === 'all') {
        this.deleteMessageData = {
          body: this.translate.areYouSureYouWantToDeleteAllTheseItems,
          header: this.translate.deleteAll
        };
      }
      if (objType === ObjectTypes.RISK_THREAT) {
        var threat = this.translate.threat.toLowerCase();
        this.deleteMessageData = {
          body: "".concat(this.translate.areYouSureYouWantToDeleteThis, " ").concat(threat, "?"),
          header: this.translate.delete + ' ' + threat
        };
      }
      if (objType === ObjectTypes.RISK_VULNERABILITY) {
        var vulnerability = this.translate.vulnerability.toLowerCase();
        this.deleteMessageData = {
          body: "".concat(this.translate.areYouSureYouWantToDeleteThis, " ").concat(vulnerability, "?"),
          header: this.translate.delete + ' ' + vulnerability
        };
      }
      this.idToDelete = fieldId;
      this.$confirm(this.deleteMessageData.header, this.deleteMessageData.body, function (res) {
        return res && _this13.onDeleteConfirm();
      });
    },
    showImportPopup: function showImportPopup() {
      this.$refs.modal.beforeOpen();
      this.$refs.modal.toggleModal();
    },
    onModalUpdated: function onModalUpdated(data) {
      if (!(data !== null && data !== void 0 && data.length)) return;
      if (this.isThreat) {
        this.importThreats(data);
        return;
      }
      this.importVulnerabilities(data);
    },
    importThreats: function importThreats(data) {
      var _this14 = this;
      this.createCategories(data).then(function () {
        return _this14.createThreats(data);
      });
    },
    createCategories: function createCategories(data) {
      var _this15 = this;
      var promises = [];
      var categoriesNames = data.map(function (item) {
        return item.category;
      });
      var uniqueNames = Array.from(new Set(categoriesNames));
      var filteredNames = uniqueNames.filter(function (name) {
        return name !== undefined && !_this15.threatCategories.find(function (c) {
          return c.title === name;
        });
      });
      filteredNames.forEach(function (name) {
        var promise = RiskApi.createRiskThreatCategory({
          title: name
        }).then(function (response) {
          return _this15.$emit('create:category', response);
        });
        promises.push(promise);
      });
      return Promise.all(promises);
    },
    createThreats: function createThreats(data) {
      var _this16 = this;
      var promises = [];
      data.forEach(function (_ref) {
        var _this16$threatCategor;
        var title = _ref.title,
          category = _ref.category;
        var createData = {
          title: title
        };
        var categoryId = (_this16$threatCategor = _this16.threatCategories.find(function (c) {
          return c.title === category;
        })) === null || _this16$threatCategor === void 0 ? void 0 : _this16$threatCategor.id;
        if (categoryId !== undefined) createData.categoryId = categoryId;
        promises.push(RiskApi.createThreat(createData));
      });
      Promise.all(promises).then(function () {
        return _this16.$emit('refresh:threats', _this16.selectedProjectId);
      });
    },
    importVulnerabilities: function importVulnerabilities(data) {
      var _this17 = this;
      var promises = [];
      data.forEach(function (_ref2) {
        var title = _ref2.title;
        promises.push(RiskApi.createVulnerability({
          title: title
        }));
      });
      Promise.all(promises).then(function () {
        return _this17.$emit('refreshRiskProjects');
      });
    },
    handleSelectAll: function handleSelectAll(project) {
      var _this18 = this;
      var allSelected = !project.allSelected;
      var promises = [];
      JSON.parse(JSON.stringify(this.dataset)).forEach(function (item) {
        item.projects.forEach(function (p) {
          if (p.id !== project.id || p.isActive === allSelected) return;
          p.isActive = allSelected;
          promises.push(_this18.updateField(item, p));
        });
      });
      Promise.all(promises).then(function () {
        return _this18.$emit('refreshRiskProjects');
      });
    },
    showEditModal: function showEditModal() {
      var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        title: '',
        categoryId: null
      };
      this.categoryModalShown = true;
      this.threadSelected = field;
    },
    createThreat: function createThreat(data) {
      var _this19 = this;
      return RiskApi.createThreat(data).then(function () {
        _this19.$emit('refresh:threats', _this19.selectedProjectId);
        _this19.dismissThreatModal();
      });
    },
    updateThreat: function updateThreat(data) {
      var _this20 = this;
      var payload = _objectSpread(_objectSpread({}, data), {}, {
        projectId: this.selectedProjectId
      });
      return RiskApi.updateThreat(this.threadSelected.id, payload).then(function () {
        _this20.$emit('refresh:threats', _this20.selectedProjectId);
        _this20.dismissThreatModal();
      });
    },
    dismissThreatModal: function dismissThreatModal() {
      this.threadSelected = null;
    }
  }
};