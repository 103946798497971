import { UserLevels } from '@/constants/UserLevels';
import { CONTRACTS_OVERVIEW } from '@/constants/routes/contracts';
function buildContractsMenu(user) {
  var menuStructure = [];
  menuStructure.push({
    url: '/contracts/overview/my',
    title: 'My contracts',
    rights: true
  });
  menuStructure.push({
    url: '/contracts/overview/all',
    title: 'Overview',
    rights: true,
    checkActiveSubItem: function checkActiveSubItem(url) {
      return [CONTRACTS_OVERVIEW.EXPIRED, CONTRACTS_OVERVIEW.MY_COMPANIES, CONTRACTS_OVERVIEW.DPA, CONTRACTS_OVERVIEW.DOCUMENTS, CONTRACTS_OVERVIEW.CONTENT].some(function (urlItem) {
        return url.startsWith(urlItem);
      });
    }
  });
  menuStructure.push({
    url: '/contracts/reports',
    title: 'Reports',
    rights: user.level_contracts >= UserLevels.PRIVILEGED || user.is_admin
  });
  menuStructure.push({
    url: '/contracts/dashboard',
    title: 'Dashboard',
    rights: user.level_contracts >= UserLevels.PRIVILEGED || user.is_admin
  });
  return menuStructure;
}
export default buildContractsMenu;