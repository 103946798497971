import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "fixed right-10 bottom-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ai_chat_widget = _resolveComponent("ai-chat-widget");
  return _openBlock(), _createElementBlock("div", null, [!$data.isVisible ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isVisible = true;
    })
  }, "Open Ai Chat Widget")) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_ai_chat_widget, {
    "is-visible": $data.isVisible,
    "chat-history": $data.chatHistory,
    region: "DK",
    "onClear:history": _cache[1] || (_cache[1] = function ($event) {
      return $data.chatHistory = [];
    }),
    "onEnd:history": _cache[2] || (_cache[2] = function ($event) {
      return $data.isVisible = false;
    }),
    onMinimize: _cache[3] || (_cache[3] = function ($event) {
      return $data.isVisible = false;
    })
  }, null, 8 /* PROPS */, ["is-visible", "chat-history"])])]);
}