import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  key: 0,
  class: "text-red-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addNewKpi,
    "button-ok-text": $data.translate.createKpi,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "ok-button-disabled": $data.isButtonDisabled,
    onAccept: $options.handleCreateKpi,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
        modelValue: $data.data.kpiType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.data.kpiType = $event;
        }),
        title: "KPI",
        options: $options.kpiTypes,
        class: _normalizeClass({
          error: !$data.isFormValid && !$data.data.kpiType
        })
      }, null, 8 /* PROPS */, ["modelValue", "options", "class"])]), _createElementVNode("p", null, _toDisplayString(_ctx.$trans($options.selectedTypeDescription)), 1 /* TEXT */), !$data.isFormValid && !$data.data.kpiType ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($data.translate.pleaseSelectAKpi), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled", "onAccept"]);
}