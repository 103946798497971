function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "ml-6"
};
var _hoisted_2 = {
  key: 3,
  class: "min-h-40vh"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  class: "sections flex"
};
var _hoisted_5 = {
  class: "left-section mr-4 w-2/5 relative"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "right-section w-3/5 border-l-2 border-solid border-gray-350 flex justify-center"
};
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  key: 1
};
var _hoisted_10 = {
  key: 2
};
var _hoisted_11 = {
  key: 2,
  class: "text-center m-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_links_modal = _resolveComponent("links-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_selector_tags = _resolveComponent("selector-tags");
  var _component_action_gap = _resolveComponent("action-gap");
  var _component_links_dep_preview = _resolveComponent("links-dep-preview");
  var _component_question_preview_list = _resolveComponent("question-preview-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.hasTabs ? (_openBlock(), _createBlock(_component_tab_list, {
    key: 0,
    class: "w-2/5 flex mt-6 mb-6",
    tabs: $options.linkTabs,
    "background-class": "bg-zinc-50",
    onUpdate: $options.setCurrentTab
  }, null, 8 /* PROPS */, ["tabs", "onUpdate"])) : _createCommentVNode("v-if", true), $data.showLinksModal ? (_openBlock(), _createBlock(_component_links_modal, {
    key: 1,
    "projects-list": $data.projectsOptions,
    "links-options": $data.linksOptions,
    "preselected-links": $data.preselectedLinks,
    initiatives: $data.dataset[$data.MODULES.EXECUTION],
    onSelectProject: $options.setLinksOption,
    onUpdateLinks: $options.onUpdateLinks,
    onAddNewItem: $options.addNewItem,
    onDismiss: $options.toggleLinksModal
  }, null, 8 /* PROPS */, ["projects-list", "links-options", "preselected-links", "initiatives", "onSelectProject", "onUpdateLinks", "onAddNewItem", "onDismiss"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.currentTab === $data.tabTypes.LINKS && !$options.isReadOnlyAccess ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.addLink,
    type: "save",
    onClick: $options.toggleLinksModal
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), $options.sortedItems.size ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $options.listTitle,
    type: "big",
    class: "data-title underline pl-8 py-8 relative text-21px"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedItems, function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      linkModule = _ref2[0],
      linkValue = _ref2[1];
    return _openBlock(), _createElementBlock("div", {
      key: linkModule,
      class: "py-4 pl-12 pr-12 section-item relative"
    }, [_createVNode(_component_selector_tags, {
      module: linkValue.module || linkModule,
      title: linkValue.moduleTitle,
      tags: (linkValue === null || linkValue === void 0 ? void 0 : linkValue.items) || [],
      "has-traffic-lights": linkValue.hasTrafficLight,
      "has-delete-options": !!linkValue.hasDeleteOption,
      "selected-tag": $data.selectedTag.moduleTitle === linkValue.moduleTitle ? $data.selectedTag : {},
      onDelete: function onDelete($event) {
        return $options.deleteLink(linkModule, $event);
      },
      onClick: function onClick($event) {
        return _ctx.handlePreview($event, linkValue.moduleTitle);
      }
    }, null, 8 /* PROPS */, ["module", "title", "tags", "has-traffic-lights", "has-delete-options", "selected-tag", "onDelete", "onClick"])]);
  }), 128 /* KEYED_FRAGMENT */)), $options.availableActivitiesDiff && $data.currentTab === $data.tabTypes.LINKS ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString("".concat($options.availableActivitiesDiff, " ").concat($data.translate[$options.availableActivitiesDiff > 1 ? 'hiddenActivities' : 'hiddenActivity'])), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_7, [_ctx.isLoadingPreview ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0,
    "disable-centering": true
  })) : _createCommentVNode("v-if", true), _ctx.previewData.data !== null ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["w-4/5 h-fit sticky", $options.isPreview ? 'top-8' : 'top-36'])
  }, [_createElementVNode("div", null, [_ctx.previewData.type === $data.LinkTypes.MITIGATING_ACTIVITIES ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_action_gap, {
    data: _ctx.previewData.list,
    "gap-list": [_ctx.previewData.list.gapAnalysis]
  }, null, 8 /* PROPS */, ["data", "gap-list"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previewData.data, function (activity, idx) {
    return _openBlock(), _createBlock(_component_links_dep_preview, {
      key: idx,
      organisations: _ctx.organisations,
      users: _ctx.users,
      data: activity,
      scoring: $data.scoring
    }, null, 8 /* PROPS */, ["organisations", "users", "data", "scoring"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _ctx.previewData.type === $data.LinkTypes.GAP_ANALYSIS ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_links_dep_preview, {
    organisations: _ctx.organisations,
    users: _ctx.users,
    data: _ctx.previewData.data,
    scoring: $data.scoring
  }, null, 8 /* PROPS */, ["organisations", "users", "data", "scoring"]), _ctx.previewData.list !== null ? (_openBlock(), _createBlock(_component_action_gap, {
    key: 0,
    data: _ctx.previewData.list,
    "gap-list": _ctx.previewData.list.gapList,
    "read-only": !_ctx.previewData.data.url
  }, null, 8 /* PROPS */, ["data", "gap-list", "read-only"])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_links_dep_preview, {
    organisations: _ctx.organisations,
    users: _ctx.users,
    data: _ctx.previewData.data,
    scoring: $data.scoring
  }, null, 8 /* PROPS */, ["organisations", "users", "data", "scoring"]), _ctx.previewData.list !== null && $data.currentTab === $data.tabTypes.QUESTIONNAIRE ? (_openBlock(), _createBlock(_component_question_preview_list, {
    key: 0,
    data: _ctx.previewData.list
  }, null, 8 /* PROPS */, ["data"])) : _createCommentVNode("v-if", true)]))])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)])])])) : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($data.translate.youDontHaveAnyLinksToOrFromThisActivity), 1 /* TEXT */))]))]);
}