// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td[data-v-d038e426]:not(:first-child) {
  min-width: 100px;
  padding: 8px;
  line-height: 20px;
  text-align: center;
  vertical-align: top;
  border-top: 1px solid rgb(249.4347826087, 249.4347826087, 247.7652173913);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
