import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "glob-l1 text-rm-signal-grey-dark text-sm mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ai_partner_terms = _resolveComponent("ai-partner-terms");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.termsAndConditions,
    "button-ok-text": $data.translate.accept,
    "button-dismiss-text": $data.translate.cancel,
    "body-scroll-y": true,
    "ok-button-disabled": $data.okButtonDisabled,
    "detect-scroll-to-bottom": true,
    "show-buttons": false,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    "onScroll:bottomReached": _cache[3] || (_cache[3] = function ($event) {
      return $data.okButtonDisabled = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_ai_partner_terms, {
        ref: "aiPartnerTermsText"
      }, null, 512 /* NEED_PATCH */)];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("a", {
        href: "#",
        class: "group cursor-pointer hover:underline mt-4 hover:bg-rm-grey block w-fit rounded-sm active:bg-rm-active-blue--10",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleDownload && $options.handleDownload.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "download",
        width: 20,
        height: 20,
        class: "transition stroke-2 text-rm-grey group-hover:text-rm-text group-active:text-rm-active-blue"
      })]), $data.okButtonDisabled ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($data.translate.pleaseNoteThatTheAccept), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_buttons_row, {
        class: "flex justify-end mt-5",
        data: $options.buttonsRowData,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled"]);
}