import RismaCircle from '@/components/Atoms/RismaCircle';
import { getShortMonthNames } from '@/utils/date';
export default {
  name: 'MonthlyOverview',
  introduction: 'Monthly overview, displayed by using the RismaCircle component',
  description: 'This component displayed information grouped by month',
  token: '<monthly-overview :dataset-months=[1,2,15,...] />',
  components: {
    RismaCircle: RismaCircle
  },
  props: {
    monthsDefault: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    datasetMonths: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['active'],
  data: function data() {
    return {
      activeIndex: null
    };
  },
  computed: {
    preparedMonthsDefault: function preparedMonthsDefault() {
      if (this.monthsDefault.length) {
        return this.monthsDefault;
      }
      return getShortMonthNames();
    }
  },
  watch: {
    datasetMonths: {
      deep: true,
      handler: function handler() {
        this.activeIndex = null;
      }
    }
  },
  methods: {
    toggleCircle: function toggleCircle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
      this.$emit('active', this.activeIndex);
    }
  }
};