import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue";
import _imports_0 from '@/assets/img/legalHub.svg';
var _hoisted_1 = {
  class: "mypage max-w-screen-xxl xxl:mx-auto"
};
var _hoisted_2 = {
  class: "mx-auto"
};
var _hoisted_3 = {
  key: 2,
  class: "loading-box"
};
var _hoisted_4 = {
  key: 3,
  class: "mypage-nav flex"
};
var _hoisted_5 = {
  class: "flex-1 self-center"
};
var _hoisted_6 = {
  key: 0,
  href: "https://legalhub.plesner.com",
  target: "_blank",
  class: "mr-8"
};
var _hoisted_7 = {
  class: "column-switcher-text"
};
var _hoisted_8 = {
  key: 4
};
var _hoisted_9 = {
  key: 5,
  class: "mypage-content"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = ["onClick"];
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  key: 0,
  class: "absolute right-0 top-0 w-64 flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_review_modal = _resolveComponent("control-review-modal");
  var _component_complete_modal = _resolveComponent("complete-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  var _component_util_menu_with_toggle = _resolveComponent("util-menu-with-toggle");
  var _component_preview_list_current_activity = _resolveComponent("preview-list-current-activity");
  var _component_single_select = _resolveComponent("single-select");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.isReviewModalShow ? (_openBlock(), _createBlock(_component_control_review_modal, {
    key: 0,
    id: $data.selectedControl.id + '',
    cpath: $data.selectedControl.cpath,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return $data.isReviewModalShow = false;
    }),
    onUpdate: _cache[1] || (_cache[1] = function ($event) {
      return $options.controlCheck($data.selectedControl.id, $event);
    })
  }, null, 8 /* PROPS */, ["id", "cpath"])) : _createCommentVNode("v-if", true), $data.isLoaded && $data.isCompleteModalShow ? (_openBlock(), _createBlock(_component_complete_modal, {
    key: 1,
    id: $data.selectedControl.id,
    cpath: $data.selectedControl.cpath,
    onClose: _cache[2] || (_cache[2] = function ($event) {
      return $data.isCompleteModalShow = false;
    }),
    onUpdate: _cache[3] || (_cache[3] = function ($event) {
      return $options.controlCheck($data.selectedControl.id, $event);
    })
  }, null, 8 /* PROPS */, ["id", "cpath"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_loading_indicator_large)])) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    type: "big",
    title: $data.translate.welcome + ', ' + _ctx.currentUser.fullname
  }, null, 8 /* PROPS */, ["title"])]), _ctx.currentUser.legal_hub_access ? (_openBlock(), _createElementBlock("a", _hoisted_6, _cache[7] || (_cache[7] = [_createElementVNode("img", {
    src: _imports_0,
    alt: "legalHub logo",
    class: "w-64"
  }, null, -1 /* HOISTED */)]))) : _createCommentVNode("v-if", true), $options.isContentVisibilityVisible ? (_openBlock(), _createBlock(_component_risma_column_switcher, {
    key: 1,
    columns: $options.visibleContent,
    onChanged: $options.onChangeBlockVisibility
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        color: $data.colors.system.primaryDark,
        icon: "columns"
      }, null, 8 /* PROPS */, ["color"]), _createElementVNode("span", _hoisted_7, _toDisplayString($data.translate.contentVisibility), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "onChanged"])) : _createCommentVNode("v-if", true), _createVNode(_component_util_menu_with_toggle, {
    class: "self-center util-menu-toggle",
    columns: $data.globalSettingsList,
    onChanged: $options.changeSettingsPageVisibility
  }, null, 8 /* PROPS */, ["columns", "onChanged"])])) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_preview_list_current_activity, {
    activities: $options.currentAndRecentActivities,
    "deadline-filter-value": $setup.deadlineFilter,
    "deadline-filter-options": $options.deadlineFilterOptions,
    "recent-filter-options": $options.recentFilterOptions,
    "recent-filter-value": $setup.recentFilter,
    projects: $options.projectsList,
    onUpdateFromPreviewMode: $options.updateFromCurrentPreviewMode,
    onShowCompletionModal: $options.showCompletionModal,
    onShowReviewModal: $options.showReviewModal,
    onChangeDeadlineFilter: _cache[4] || (_cache[4] = function ($event) {
      return $setup.deadlineFilter = $event;
    }),
    onChangeRecentFilter: _cache[5] || (_cache[5] = function ($event) {
      return $setup.recentFilter = $event;
    })
  }, null, 8 /* PROPS */, ["activities", "deadline-filter-value", "deadline-filter-options", "recent-filter-options", "recent-filter-value", "projects", "onUpdateFromPreviewMode", "onShowCompletionModal", "onShowReviewModal"])])) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_draggable, _mergeProps({
    modelValue: $setup.contentOrder,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $setup.contentOrder = $event;
    })
  }, $data.dragOptions, {
    tag: "div",
    "component-data": {
      type: 'transition'
    },
    "item-key": "type",
    disabled: !$options.isDragDropVisible,
    handle: ".drag-icon"
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element;
      return [_createElementVNode("div", {
        class: _normalizeClass(['content-item', {
          'content-draggable': $options.isDragDropVisible
        }])
      }, [$data.dataset[element.type].activities && $data.dataset[element.type].activities.length && element.visible ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_preview_list_tile, {
        title: $data.dataset[element.type].label,
        "is-drag-drop-visible": $options.isDragDropVisible,
        activities: $data.dataset[element.type].activities,
        workflows: $data.dataset[element.type].workflows,
        "disable-preview": !!$data.dataset[element.type].disablePreview,
        "internal-link-enabled": !!$data.dataset[element.type].internalLinkEnabled,
        "cookie-hidden-name": "rismahider-my-page-".concat(element.type),
        "watermark-image": $data.dataset[element.type].watermarkImage,
        onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
          return $options.updateFromPreviewMode(element.type, $event);
        },
        onDeleteItem: function onDeleteItem($event) {
          return $options.deleteFromPreviewMode(element.type, $event);
        }
      }, _createSlots({
        filter: _withCtx(function (slotProps) {
          return [slotProps.isShowAll && $options.isSortingVisible ? (_openBlock(), _createElementBlock("div", _hoisted_12, [$data.dataset[element.type].sorting && $data.dataset[element.type].sorting.sortOptions && $data.dataset[element.type].sorting.sortOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_title, {
            title: $data.translate.sortBy,
            type: "medium",
            class: "whitespace-nowrap pr-4 text-blue-750",
            truncate: false
          }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
            placeholder: $data.translate.choose,
            options: $data.dataset[element.type].sorting && $data.dataset[element.type].sorting.sortOptions,
            "model-value": $data.dataset[element.type].sorting && $data.dataset[element.type].sorting.sortBy,
            "un-sorted": false,
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.sortList($event, element.type);
            }
          }, null, 8 /* PROPS */, ["placeholder", "options", "model-value", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)];
        }),
        _: 2 /* DYNAMIC */
      }, [element.type === $data.MypageKeys.MY_REPORTS || element.type === $data.MypageKeys.SHARED_REPORTS ? {
        name: "deleteButton",
        fn: _withCtx(function (slotProps) {
          return [(typeof $data.dataset[element.type].accessToRemove === 'function' ? $data.dataset[element.type].accessToRemove(_ctx.currentUser, slotProps.activity) : $data.dataset[element.type].accessToRemove) ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "cursor-pointer",
            onClick: function onClick($event) {
              return $options.handleDeleteConfirmation(slotProps.activity.id);
            }
          }, [_createVNode(_component_feather_icon, {
            icon: "x",
            class: "stroke-2"
          })], 8 /* PROPS */, _hoisted_11)) : _createCommentVNode("v-if", true)];
        }),
        key: "0"
      } : undefined, $options.isDragDropVisible ? {
        name: "draggable",
        fn: _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            icon: "move",
            class: "drag-icon text-blue-750 stroke-2 mr-4"
          })];
        }),
        key: "1"
      } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "is-drag-drop-visible", "activities", "workflows", "disable-preview", "internal-link-enabled", "cookie-hidden-name", "watermark-image", "onUpdateFromPreviewMode", "onDeleteItem"])])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue", "disabled"])])) : _createCommentVNode("v-if", true)])]);
}