// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-error[data-v-9f8470fc]  input,
.input-error[data-v-9f8470fc]  textarea,
.input-error[data-v-9f8470fc]  .multiselect__tags {
  border-color: #B03D00;
}
.validation-error[data-v-9f8470fc] {
  color: #B03D00;
  min-height: 20px;
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
