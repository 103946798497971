import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_download_link = _resolveComponent("download-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("p", null, "Download Link example:", -1 /* HOISTED */)), _createVNode(_component_download_link, {
    "file-src": $data.fileSrc,
    "file-name": 'Test file',
    "use-legacy": true
  }, null, 8 /* PROPS */, ["file-src"])]);
}