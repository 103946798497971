import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "py-1 text-rm-text text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : $options.dataset.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 1,
    "dataset-table": $options.dataset,
    columns: $options.columns
  }, {
    choose: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_radio_buttons, {
        "model-value": slotData.selected,
        options: $data.chooseOption,
        onUpdated: function onUpdated($event) {
          return $options.handleChange($event, slotData.idx);
        }
      }, null, 8 /* PROPS */, ["model-value", "options", "onUpdated"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($data.translate.theCompanyDoesntHaveAValidLei($options.companyName)), 1 /* TEXT */))]);
}