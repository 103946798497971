var _excluded = ["isAnsweredOnly", "selectedQuestions"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getIAReport } from '@/api/compliance/reports/questionnaire';
import { getAllQuestions } from '@/api/compliance/questionnaire';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Article30Table from '@/components/Pages/Compliance/Reports/PredefinedReports/article30/Table';
import QuestionSelection from '@/components/Pages/Compliance/Reports/PredefinedReports/QuestionSelection';
import QuestionnaireSelection from './QuestionnaireSelection';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { mapQuestionAnswers } from '@/components/Pages/Compliance/Reports/PredefinedReports/utils';
import { getAnsweredQuestionsByActivity } from '@/utils/reports/questionnaire';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ReportTypes } from '@/constants/reports';
import { MODULES } from '@/constants/modules';
import { VIEW_TYPES } from '@/constants/ViewTypes';
export default {
  name: 'QuestionnaireReport',
  components: {
    Article30Table: Article30Table,
    QuestionnaireSelection: QuestionnaireSelection,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    QuestionSelection: QuestionSelection,
    RismaButton: RismaButton
  },
  props: {
    savedReportOptions: {
      type: Object,
      default: null
    },
    module: {
      type: String,
      default: MODULES.COMPLIANCE_INFORMATION_ASSETS
    }
  },
  data: function data() {
    return {
      loading: false,
      showReport: false,
      rows: [],
      assets: [],
      selectedQuestions: [],
      questions: [],
      selectedAssets: [],
      error: '',
      report: null,
      selectedAssetType: null,
      selectedQuestionnaireId: null,
      reportTable: 'article30-table',
      savedReportType: ReportTypes.IA_QUESTIONNAIRE_AUDIT,
      reportOptions: {},
      isAnsweredOnly: false,
      noAvailableAssets: false,
      initialFilters: null,
      selectedTableView: null,
      localStateName: 'ia_questionnaire_report_table',
      translate: getTranslate['QuestionnaireReport']()
    };
  },
  computed: {
    isIA: function isIA() {
      return this.module === MODULES.COMPLIANCE_INFORMATION_ASSETS;
    },
    explanationText: function explanationText() {
      if (this.isIA) {
        return this.translate.startBySelectingIA;
      }
      return this.translate.startBySelecting;
    },
    nameColumnTitle: function nameColumnTitle() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.CONTROLLERS, this.translate.dataController), AssetsTypes.PROCESSORS, this.translate.dataProcessor), AssetsTypes.SYSTEMS, this.translate.system), AssetsTypes.INFORMATION_ASSETS_API, this.translate.informationAsset), MODULES.CONTRACTS, this.translate.contract)[this.selectedAssetType];
    }
  },
  created: function created() {
    if (this.savedReportOptions) {
      this.applySavedReportOptions();
    }
  },
  methods: {
    selectionChanged: function selectionChanged(selectedQuestionnaireId, assets, selectedAssetType) {
      var refreshReport = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      this.selectedAssetType = selectedAssetType;
      this.selectedQuestionnaireId = selectedQuestionnaireId;
      this.assets = assets;
      if (refreshReport) {
        this.selectedAssets = [];
        this.selectedQuestions = [];
        this.showReport = false;
        this.isAnsweredOnly = false;
      }
      selectedQuestionnaireId && this.handleFetchQuestions();
    },
    assetsSelected: function assetsSelected(selectedAssets) {
      this.noAvailableAssets = !selectedAssets;
      this.selectedAssets = selectedAssets ? selectedAssets.map(function (n) {
        return n.id;
      }) : [];
      this.showReport = false;
    },
    handleFetchQuestions: function handleFetchQuestions() {
      var _this = this;
      this.loading = true;
      return getAllQuestions(this.selectedQuestionnaireId).then(function (response) {
        _this.questions = response.questions;
        _this.loading = false;
        _this.$forceUpdate();
      }).then(function () {
        _this.$refs.questionSelection && _this.$refs.questionSelection.selectDefault();
      });
    },
    questionsSelected: function questionsSelected(selectedQuestions) {
      this.selectedQuestions = selectedQuestions;
    },
    handleCreateReport: function handleCreateReport() {
      var _this2 = this;
      if (this.noAvailableAssets) {
        this.showReport = true;
        return;
      }
      this.showReport = false;
      this.loading = true;
      this.error = '';
      return getIAReport(this.selectedQuestionnaireId, this.selectedQuestions, this.selectedAssets).then(function (resp) {
        var mapping = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.CONTROLLERS, 'controller_id'), AssetsTypes.PROCESSORS, 'processor_id'), AssetsTypes.SYSTEMS, 'system_id'), AssetsTypes.INFORMATION_ASSETS_API, 'information_asset_id'), MODULES.CONTRACTS, 'contract_id');
        var questions = _this2.isAnsweredOnly ? getAnsweredQuestionsByActivity(resp.questions, _this2.selectedAssets, mapping[_this2.selectedAssetType]) : resp.questions;
        _this2.rows = [];
        resp.entities.forEach(function (asset) {
          var parentsInfo = asset.parents && asset.parents.map(function (id) {
            return _this2.assets.find(function (item) {
              return item.id === id;
            });
          });
          var row = {
            name: asset.name || asset.title,
            parents: parentsInfo,
            id: asset.id,
            url: asset.url,
            objType: asset.objType,
            cells: mapQuestionAnswers(questions, resp.informationAssets, resp.answers[asset.id], resp.files, resp.processTreeNodes)
          };
          _this2.rows.push(row);
        });
        _this2.updateReportOptions();
        _this2.noAvailableAssets = !_this2.rows.length;
        _this2.error = '';
        _this2.showReport = true;
        _this2.loading = false;
      }).catch(function (resp) {
        _this2.showReport = false;
        _this2.loading = false;
        if (resp.response.error !== undefined) {
          _this2.error = _this2.$trans(resp.response.error);
        }
      });
    },
    setAnsweredOnly: function setAnsweredOnly(value) {
      this.isAnsweredOnly = value;
    },
    updateReportOptions: function updateReportOptions() {
      this.reportOptions = {
        selectedQuestionnaireId: this.selectedQuestionnaireId,
        selectedQuestions: this.selectedQuestions,
        selectedAssets: this.selectedAssets,
        isAnsweredOnly: this.isAnsweredOnly,
        selectedAssetType: this.selectedAssetType
      };
    },
    applySavedReportOptions: function applySavedReportOptions() {
      var _this3 = this;
      var _this$savedReportOpti = this.savedReportOptions,
        isAnsweredOnly = _this$savedReportOpti.isAnsweredOnly,
        selectedQuestions = _this$savedReportOpti.selectedQuestions,
        rest = _objectWithoutProperties(_this$savedReportOpti, _excluded);
      this.initialFilters = rest;
      this.isAnsweredOnly = isAnsweredOnly;
      this.selectedTableView = VIEW_TYPES.EXPANDED;
      this.$nextTick(function () {
        _this3.selectedQuestions = selectedQuestions;
        _this3.selectedQuestionnaireId = _this3.initialFilters.selectedQuestionnaireId;
        _this3.handleCreateReport();
      });
    }
  }
};