function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTrafficLights } from '@/Store/trafficLights';
import * as ControlsApi from '@/api/controls/controls';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaSplitButton from '@/components/Atoms/Buttons/RismaSplitButton';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import AttachmentItem from '@/components/Atoms/Attachment/AttachmentItem';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import Notification from '@/components/Molecules/Notification';
import AttachmentSection from '@/components/Molecules/AttachmentSection/AttachmentSection';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import StaticTable from '@/components/Molecules/StaticTable';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import { DateFormats } from '@/constants/DateFormats';
import { ActivityLinkModules } from '@/constants/ActivityLinkModules';
import * as Utils from '@/utils/Utils';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { getParsedCfValues } from '@/utils/CustomFields';
import { ControlsEfficiencyTypes } from '@/constants/ControlFields';
import { formatDateWithTimezone } from '@/utils/date';
import { ControlActions } from '@/constants/ControlActions';
import { MODULES } from '@/constants/modules';
var Protocols = {
  HTTP: 'http://',
  HTTPS: 'https://',
  EXTERNAL_DOC_MANAGEMENT_SYSTEM: 'f2p://'
};
export default {
  name: 'CompleteControl',
  introduction: 'A modal that shows latest completion information of control and handle complete functionality',
  description: 'This is a Molecule component for /controls2/controls/:id. Parent component: ControlDetails',
  token: '<complete-control :cpath="cpath" :id="id" />',
  components: {
    LinkItem: LinkItem,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    RismaSplitButton: RismaSplitButton,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    Notification: Notification,
    AttachmentSection: AttachmentSection,
    ActivityCustomFields: ActivityCustomFields,
    TextBox: TextBox,
    FeatherIcon: FeatherIcon,
    AttachmentItem: AttachmentItem,
    CollapsiblePanel: CollapsiblePanel,
    TrafficLightSelector: TrafficLightSelector,
    TrafficLight: TrafficLight,
    RismaLabel: RismaLabel,
    StaticTable: StaticTable
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      note: 'An id of a control'
    },
    cpath: {
      type: [Number, String],
      required: true,
      note: 'A cpath of a control'
    },
    isFilesDisable: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is files upload section disabled'
    }
  },
  emits: ['updateModalTitle', 'close', 'toggleDisableInteraction', 'update', 'dataUpdated'],
  data: function data() {
    return {
      showProgressBar: false,
      isLoaded: false,
      activeHistoryItem: {},
      control: {},
      completedComment: '',
      completedUrl: '',
      completedFilename: '',
      attachmentSectionIdx: 0,
      interactionDisabled: false,
      loadingFiles: {},
      uploadingMax: 0,
      uploadingValue: 0,
      notificationMessage: '',
      notificationType: 'error',
      completedEfficiency: null,
      completedEfficiencyComment: '',
      translate: getTranslate['CompleteControl']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    attachmentSizeLimitReadable: 'attachmentSizeLimitReadable'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), mapState(useActivityTypesStore, ['controlActivityTypesWithFilteredFields'])), {}, {
    activityTypes: function activityTypes() {
      return this.controlActivityTypesWithFilteredFields(function (field) {
        return field.activityLinkModule === ActivityLinkModules.CONTROL_COMPLETION;
      });
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (item) {
        return item.id === _this.control.activityTypeId;
      });
    },
    prevCompletions: function prevCompletions() {
      // Latest three completions
      return this.control.history.slice(0, 3);
    },
    reviewers: function reviewers() {
      var result = '';
      if (this.control.userIds && this.control.userIds.review) {
        result = Utils.findPropsByIds(this.control.userIds.review, this.users, 'display_name');
      }
      return result;
    },
    completeError: function completeError() {
      return this.translate.addAFileCommentOrLinkInclLinkTitleTo;
    },
    completePartiallyError: function completePartiallyError() {
      return this.translate.pleaseAddACommentToPerformACompletionWith;
    },
    emptyUrlField: function emptyUrlField() {
      return this.translate.pleaseFillLinkToFieldsCorrectly;
    },
    fileUploadingWarning: function fileUploadingWarning() {
      return this.translate.fileUploadInProgress;
    },
    isPartiallyFlowOn: function isPartiallyFlowOn() {
      return !!this.getSettingValue('risma.partially_completed_controls');
    },
    hasHistory: function hasHistory() {
      return this.control && this.control.history && this.control.history.length > 0;
    },
    hasReviews: function hasReviews() {
      return this.control && this.control.reviews && this.control.reviews.length > 0;
    },
    hasFeatureFormData: function hasFeatureFormData() {
      return !!this.getSettingValue('feature.form_data_upload');
    },
    showSkipButton: function showSkipButton() {
      var settingEnabled = this.getSettingValue('feature.skip_control_button');
      return !this.control.completed && settingEnabled;
    },
    filesList: function filesList() {
      var files = this.control.completedFiles || [];
      files.forEach(function (file) {
        file.filename = file.name;
        if (file.mimeType === 'text/uri-list') return;
        file.url = "/api/2.0/controls/files/".concat(file.id);
      });
      return files;
    },
    completedLinkFilled: function completedLinkFilled() {
      return this.completedUrl && this.completedUrl !== Protocols.HTTP && this.completedUrl !== Protocols.HTTPS && this.completedUrl !== Protocols.EXTERNAL_DOC_MANAGEMENT_SYSTEM || this.completedFilename;
    },
    efficiencyAssessmentVisible: function efficiencyAssessmentVisible() {
      var _this$currentActivity;
      return !!this.getSettingValue('feature.controls_efficiency_enabled') && !!((_this$currentActivity = this.currentActivityType) !== null && _this$currentActivity !== void 0 && _this$currentActivity.controlsEfficiencyType);
    },
    requiredFieldsEnabled: function requiredFieldsEnabled() {
      return !!this.getSettingValue('risma.mandatory_documentation');
    },
    isEfficiencyMandatory: function isEfficiencyMandatory() {
      var _this$currentActivity2;
      return this.efficiencyAssessmentVisible && !!((_this$currentActivity2 = this.currentActivityType) !== null && _this$currentActivity2 !== void 0 && _this$currentActivity2.controlsEfficiencyMandatory);
    },
    completedEfficiencyTrafficLights: function completedEfficiencyTrafficLights() {
      var _this$currentActivity3;
      var trafficLights = _toConsumableArray(this.getTrafficLightsForModule(MODULES.CONTROLS));
      if (((_this$currentActivity3 = this.currentActivityType) === null || _this$currentActivity3 === void 0 ? void 0 : _this$currentActivity3.controlsEfficiencyType) === ControlsEfficiencyTypes.efficiencyAssessment) {
        trafficLights.shift();
        return trafficLights;
      }
      return trafficLights;
    },
    efficiencyAssessmentsColumns: function efficiencyAssessmentsColumns() {
      return [this.translate.date, this.translate.user, this.translate.assessment, this.translate.comment];
    },
    firstEfficiencyAssessmentDate: function firstEfficiencyAssessmentDate() {
      var _this$efficiencyAsses;
      return ((_this$efficiencyAsses = this.efficiencyAssessmentsDataset[0]) === null || _this$efficiencyAsses === void 0 ? void 0 : _this$efficiencyAsses.date) || '';
    },
    efficiencyAssessmentsDataset: function efficiencyAssessmentsDataset() {
      var _this2 = this;
      if (!this.control.effectivenessData) return [];
      return this.control.effectivenessData.map(function (item) {
        var _this2$completedEffic;
        return {
          date: _this2.formattedDate(item.completed, _this2.control.deadlineTimezone, DateFormats.DATE_FORMAT),
          user: _this2.findUserNameById(item.completedUserid),
          assessment: {
            color: (_this2$completedEffic = _this2.completedEfficiencyTrafficLights.find(function (option) {
              return +option.number === +item.completedEfficiency;
            })) === null || _this2$completedEffic === void 0 ? void 0 : _this2$completedEffic.color
          },
          comment: item.completedEfficiencyComment
        };
      });
    }
  }),
  beforeMount: function beforeMount() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightsForModule'])), {}, {
    getData: function getData() {
      var _this3 = this;
      this.isLoaded = false;
      return this.getControlByCpath(this.cpath).then(function (data) {
        _this3.handleGetControlByCpath(data);
        _this3.$emit('updateModalTitle', "".concat(_this3.translate.completeControl, " \"").concat(_this3.cpath || '', " ").concat(data.title || '', "\""));
        _this3.isLoaded = true;
      });
    },
    close: function close() {
      this.$emit('close');
    },
    getControlByCpath: function getControlByCpath() {
      return ControlsApi.getControlByCpathAndId(this.cpath, this.id);
    },
    handleGetControlByCpath: function handleGetControlByCpath(data) {
      this.control = _objectSpread(_objectSpread({}, this.control), data);
      this.completedComment = this.control.completedComments ? this.control.completedComments : '';
      this.completedUrl = this.control.completedUrl || '';
      this.completedFilename = this.control.completedUrlTitle || '';
      this.completedEfficiency = this.getCompletedEfficiency();
      this.completedEfficiencyComment = this.getCompletedEfficiencyComment();
      if (this.control.history && this.control.history.length) {
        this.showFullHistory(this.control.history[0]);
      }
      if (this.control.reviews) {
        this.enhanceAndSortReviews(this.control.reviews);
      }
    },
    getCompletedEfficiency: function getCompletedEfficiency() {
      var _this$getLatestNonSki;
      var result = this.control.completedEfficiency !== null ? this.control.completedEfficiency : (_this$getLatestNonSki = this.getLatestNonSkippedHistoryEntry()) === null || _this$getLatestNonSki === void 0 ? void 0 : _this$getLatestNonSki.completedEfficiency;
      if (!this.completedEfficiencyTrafficLights.find(function (item) {
        return item.number === result;
      })) {
        result = this.completedEfficiencyTrafficLights[0].number;
      }
      return result;
    },
    getCompletedEfficiencyComment: function getCompletedEfficiencyComment() {
      var _this$getLatestNonSki2;
      if (this.control.completedEfficiencyComment) {
        return this.control.completedEfficiencyComment;
      }
      return ((_this$getLatestNonSki2 = this.getLatestNonSkippedHistoryEntry()) === null || _this$getLatestNonSki2 === void 0 ? void 0 : _this$getLatestNonSki2.completedEfficiencyComment) || '';
    },
    getLatestNonSkippedHistoryEntry: function getLatestNonSkippedHistoryEntry() {
      return (this.control.history || []).find(function (historyEntry) {
        return historyEntry.rismaState !== RismaControlsUtils.states.STATE_NOT_PERFORMED;
      });
    },
    isExistedAndFilled: function isExistedAndFilled(field) {
      return !!(field && field.trim() !== '');
    },
    isExpectedEvidenceShow: function isExpectedEvidenceShow(expectEvidence) {
      return this.getSettingValue('risma.show_expected_evidence') && this.isExistedAndFilled(expectEvidence);
    },
    formattedDate: function formattedDate(date, timezone) {
      var dataFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_TIME_FORMAT_FULL;
      return formatDateWithTimezone(date, timezone, dataFormat);
    },
    uploadLinkAsFile: function uploadLinkAsFile() {
      var _this4 = this;
      this.notificationMessage = '';
      if (!this.isLinkValid(true)) {
        this.setErrorMessage(this.emptyUrlField);
        return Promise.reject(new Error(this.emptyUrlField));
      }
      this.toggleDisableInteraction();
      return this.uploadSavedAttachments([{
        filename: this.completedFilename,
        content: this.getValidUrl(this.completedUrl),
        mimeType: 'text/uri-list'
      }]).then(function () {
        _this4.completedUrl = '';
        _this4.completedFilename = '';
        _this4.attachmentSectionIdx += 1;
      });
    },
    uploadSavedAttachment: function uploadSavedAttachment(attachment) {
      var _this5 = this;
      return new Promise(function (resolve, reject) {
        _this5.uploadFile(attachment, resolve, reject);
      });
    },
    uploadFile: function uploadFile(attachment, resolve, reject) {
      ControlsApi.completeControlNew(this.cpath, this.id, {
        addFile: _objectSpread({}, attachment)
      }, this.showUploadProgress.bind(this, attachment.filename)).then(function (data) {
        resolve(data);
      }).catch(function (error) {
        return reject(error);
      });
    },
    showUploadProgress: function showUploadProgress(name, progressEvent) {
      this.loadingFiles[name] = {
        uploadingMax: progressEvent.total,
        uploadingValue: progressEvent.loaded
      };
      this.uploadingMax = 0;
      this.uploadingValue = 0;
      for (var key in this.loadingFiles) {
        this.uploadingMax += this.loadingFiles[key].uploadingMax;
        this.uploadingValue += this.loadingFiles[key].uploadingValue;
      }
    },
    uploadSavedAttachments: function uploadSavedAttachments(attachments) {
      var _this6 = this;
      var uploadPromises = [];
      attachments.forEach(function (attachment) {
        uploadPromises.push(_this6.uploadSavedAttachment(attachment));
      });
      return Promise.all(uploadPromises).then(function (responses) {
        // uploadPromises are executed asynchronously
        // and that's why we need to search by the maximum number of files
        // instead of by last index in array
        var lastResponse = responses.reduce(function (prev, current) {
          return prev.completedFiles.length > current.completedFiles.length ? prev : current;
        });
        _this6.control.completedFiles = lastResponse.completedFiles;
      }).catch(function () {
        _this6.setErrorMessage(_this6.translate.uploadErrorFileMightBeTooLargeToUpload(_this6.attachmentSizeLimitReadable));
      }).finally(function () {
        _this6.showProgressBar = false;
        _this6.resetAttachments();
        _this6.toggleDisableInteraction();
      });
    },
    setErrorMessage: function setErrorMessage(error) {
      this.notificationType = 'error';
      this.notificationMessage = error;
    },
    handleAddedAttach: function handleAddedAttach(attachments) {
      this.setErrorMessage('');

      // checks attachment for exist, IE has an issue
      if (attachments) {
        if (Array.isArray(attachments)) {
          this.toggleDisableInteraction();
          this.resetAttachments();
          this.uploadSavedAttachments(attachments);
        }
      }
    },
    uploadFormDataFiles: function uploadFormDataFiles(formData) {
      var _this7 = this;
      this.showFileUploadingMessage('warning');
      this.toggleDisableInteraction();
      if (!(formData instanceof FormData) || !formData.entries().next().value) {
        //check if formData is empty, cause bug on the backend
        this.setErrorMessage(this.translate.theFileWasNotUploadedPleaseTryAgain);
        this.toggleDisableInteraction(false);
      } else {
        ControlsApi.uploadFormDataFiles(this.cpath, this.id, formData).then(function (data) {
          _this7.control.completedFiles = data.completedFiles;
          _this7.showFileUploadingMessage('success');
          _this7.toggleDisableInteraction();
        }).catch(function (error) {
          _this7.showFileUploadingMessage('error', error.response.data);
          _this7.toggleDisableInteraction();
        });
      }
    },
    showFileUploadingMessage: function showFileUploadingMessage(type, err) {
      this.notificationType = type;
      switch (type) {
        case 'warning':
          this.notificationMessage = this.translate.fileUploadInProgress;
          break;
        case 'success':
          this.notificationMessage = this.translate.filesUploadingCompleted;
          break;
        default:
          this.notificationMessage = "".concat(err.error, ": ").concat(err.message);
      }
    },
    toggleDisableInteraction: function toggleDisableInteraction() {
      var disableNotificationHandle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.interactionDisabled = !this.interactionDisabled;
      this.$emit('toggleDisableInteraction');
      if (disableNotificationHandle) {
        if (this.interactionDisabled && !this.hasFeatureFormData) {
          this.notificationType = 'warning';
          this.notificationMessage = this.fileUploadingWarning;
        } else {
          this.notificationMessage = '';
        }
      }
    },
    resetAttachments: function resetAttachments() {
      this.loadingFiles = {};
      this.uploadingMax = 0;
      this.uploadingValue = 0;
    },
    handleLinkUpdate: function handleLinkUpdate(_ref) {
      var filename = _ref.filename,
        url = _ref.url;
      this.$emit('dataUpdated');
      this.completedUrl = url;
      this.completedFilename = filename;
    },
    removeAttachment: function removeAttachment(id) {
      return ControlsApi.removeAttachment(id);
    },
    removeAttachmentLocally: function removeAttachmentLocally(id) {
      this.control.completedFiles = this.control.completedFiles.filter(function (file) {
        return file.id !== id;
      });
    },
    handleRemoveAttachment: function handleRemoveAttachment(id) {
      this.removeAttachment(id).then(this.removeAttachmentLocally(id));
    },
    getDataToComplete: function getDataToComplete(saveWithAction, isSave) {
      var _this$currentActivity4;
      var data = {
        comments: this.completedComment,
        attachmentUrl: '',
        attachmentUrlTitle: ''
      };
      var customFieldValues = this.control.customFieldValues.filter(function (cf) {
        return cf.model === ActivityLinkModules.CONTROL_COMPLETION;
      });
      if ((_this$currentActivity4 = this.currentActivityType) !== null && _this$currentActivity4 !== void 0 && _this$currentActivity4.controlsEfficiencyType) {
        data.completedEfficiency = this.completedEfficiency;
        data.completedEfficiencyComment = this.completedEfficiencyComment;
      }

      // CF support
      if (this.control.customFieldValuesParsed) {
        data.customFieldValues = this.control.customFieldValuesParsed;
      } else if (customFieldValues.length) {
        data.customFieldValues = getParsedCfValues({
          customFields: this.currentActivityType.fields,
          customFieldValues: customFieldValues
        });
      }
      if (isSave) {
        data.attachmentUrl = this.completedUrl;
        data.attachmentUrlTitle = this.completedFilename;
      } else {
        data.complete = true;
        if (saveWithAction) {
          data.partiallyCompleted = true;
        }
      }
      return data;
    },
    handleCompleteControl: function handleCompleteControl(saveWithAction, isSave) {
      var _this8 = this;
      if (!this.isValidToSave(saveWithAction, isSave)) return;
      var loadLinkPromise = Promise.resolve();
      if (!isSave && this.completedLinkFilled) {
        loadLinkPromise = this.uploadLinkAsFile();
      }
      return loadLinkPromise.then(function () {
        return _this8.completeControl(saveWithAction, isSave);
      }).catch(function () {});
    },
    completeControl: function completeControl(saveWithAction, isSave) {
      var _this9 = this;
      var data = this.getDataToComplete(saveWithAction, isSave);
      this.isLoaded = false;
      return ControlsApi.completeControlNew(this.cpath, this.id, data).then(function () {
        _this9.isLoaded = true;
        _this9.$emit('update', {
          state: ControlActions.complete,
          isSave: isSave,
          updatedControlId: _this9.id
        });
        _this9.close();
      }).catch(function (error) {
        _this9.setErrorMessage(error.response.data.error || error.response.data);
        _this9.isLoaded = true;
      });
    },
    isValidToSave: function isValidToSave(saveWithAction, isSave) {
      this.notificationMessage = '';
      if (saveWithAction) {
        if (!this.isExistedAndFilled(this.completedComment)) {
          this.setErrorMessage(this.completePartiallyError);
          return false;
        }
        return true;
      }
      if (!isSave) {
        if (this.requiredFieldsEnabled && !this.isRequiredFieldsValid()) {
          this.setErrorMessage(this.completeError);
          return false;
        }
        if (this.isEfficiencyMandatory && !this.completedEfficiencyComment) {
          this.setErrorMessage(this.translate.addACommentToControlEffectivenessAssessment);
          return false;
        }
      }
      if (!this.isLinkValid()) {
        this.setErrorMessage(this.emptyUrlField);
        return false;
      }
      return true;
    },
    isRequiredFieldsValid: function isRequiredFieldsValid() {
      var isCommentAdded = this.isExistedAndFilled(this.completedComment);
      var isLinkAdded = this.isLinkValid(true);
      var isAttachmentsAdded = this.control.completedFiles && this.control.completedFiles.length > 0;
      return isCommentAdded || isLinkAdded || isAttachmentsAdded;
    },
    isLinkValid: function isLinkValid() {
      var checkIfAdded = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return checkIfAdded ? this.completedFilename && this.completedUrl && this.completedUrl !== Protocols.HTTP && this.completedUrl !== Protocols.HTTPS : !this.completedFilename || this.completedUrl && this.completedUrl !== Protocols.HTTP && this.completedUrl !== Protocols.HTTPS;
    },
    handleSkipControl: function handleSkipControl() {
      var _this10 = this;
      this.isLoaded = false;
      var data = {
        comments: this.completedComment
      };
      this.notificationMessage = '';
      ControlsApi.skipControl(this.cpath, data).then(function () {
        _this10.isLoaded = true;
        _this10.$emit('update', {
          state: ControlActions.skip,
          isSave: false
        });
        _this10.close();
      }).catch(function (error) {
        _this10.setErrorMessage(_this10.$trans(error.response.data));
        _this10.isLoaded = true;
      });
    },
    showFullHistory: function showFullHistory(prevCompletion) {
      this.activeHistoryItem = _objectSpread({}, prevCompletion);
      this.enhanceAndSortReviews(this.activeHistoryItem.reviews);
    },
    enhanceAndSortReviews: function enhanceAndSortReviews(reviews) {
      var _this11 = this;
      reviews.map(function (item) {
        item._actionHuman = _this11.$trans(RismaControlsUtils.humanReadableAction(item.action));
      });
      reviews.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created);
      });
    },
    findUserNameById: function findUserNameById(userId) {
      return Utils.findPropsByIds([userId], this.users, 'display_name');
    },
    commentUpdated: function commentUpdated(e) {
      this.$emit('dataUpdated');
      this.completedComment = e;
    },
    onActivityCustomFieldsChanged: function onActivityCustomFieldsChanged(e) {
      this.$emit('dataUpdated');
      this.control.customFieldValuesParsed = e;
    },
    updateCompletedEfficiency: function updateCompletedEfficiency(value) {
      this.completedEfficiency = value;
    },
    updateCompletedEfficiencyComment: function updateCompletedEfficiencyComment(value) {
      this.completedEfficiencyComment = value;
    },
    getAttachmentLink: function getAttachmentLink(attachment) {
      return attachment.mimeType === 'text/uri-list' ? attachment.url : "/api/2.0/controls/files/".concat(attachment.id);
    },
    getValidUrl: function getValidUrl(url) {
      return url.startsWith(Protocols.HTTP) || url.startsWith(Protocols.HTTPS) || url.startsWith(Protocols.EXTERNAL_DOC_MANAGEMENT_SYSTEM) ? url : Protocols.HTTP + url;
    }
  })
};