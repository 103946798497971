import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "container mx-auto"
};
var _hoisted_2 = {
  class: "flex justify-content-between"
};
var _hoisted_3 = {
  key: 0,
  class: "w-80"
};
var _hoisted_4 = {
  class: "ml-auto divide-y divide-slate-300"
};
var _hoisted_5 = {
  class: "text-2xl text-center"
};
var _hoisted_6 = {
  class: "flex flex-row"
};
var _hoisted_7 = {
  class: "text-sm"
};
var _hoisted_8 = {
  class: "text-blue-250 font-bold text-2xl text-center"
};
var _hoisted_9 = {
  key: 0,
  class: "flex flex-wrap justify-content-between"
};
var _hoisted_10 = {
  class: "text-center mb-1"
};
var _hoisted_11 = {
  key: 1,
  class: "p-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.risksDashboard,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [!$options.isERM ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: $data.periodSelectedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.periodSelectedValue = $event;
    }),
    title: $data.translate.period,
    options: $options.periodsSelect,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("h2", _hoisted_5, _toDisplayString($data.translate.risksCount), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.totalControl, function (total, index) {
    return _openBlock(), _createElementBlock("div", {
      key: total.label + index,
      class: "p-2"
    }, [_createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$trans(total.label)), 1 /* TEXT */), _createElementVNode("p", _hoisted_8, _toDisplayString(total.value), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])])]), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.charts, function (chart, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "flex-grow w-full lg:w-1/2 mb-4 p-4"
    }, [_createElementVNode("p", _hoisted_10, [_createElementVNode("strong", null, _toDisplayString(_ctx.$trans(chart.title)), 1 /* TEXT */)]), chart.data.length ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent(chart.chartType)), {
      key: 0,
      height: $options.calculateHeights(chart),
      labels: chart._labels,
      datasets: chart._datasets
    }, null, 8 /* PROPS */, ["height", "labels", "datasets"])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("p", null, _toDisplayString($data.translate.noResultsFoundYouMayWantToWidenYourSearc), 1 /* TEXT */)]))]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}