import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "nestable-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_NestableItem = _resolveComponent("NestableItem", true);
  return _openBlock(), _createElementBlock("li", {
    class: _normalizeClass($options.itemClasses)
  }, [_createElementVNode("div", {
    class: "nestable-item-content",
    onMouseenter: _cache[0] || (_cache[0] = function () {
      return $options.onMouseEnter && $options.onMouseEnter.apply($options, arguments);
    }),
    onMouseleave: _cache[1] || (_cache[1] = function () {
      return $options.onMouseLeave && $options.onMouseLeave.apply($options, arguments);
    }),
    onMousemove: _cache[2] || (_cache[2] = function () {
      return $options.onMouseMove && $options.onMouseMove.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "default", {
    index: $props.index,
    item: $props.item,
    isChild: $props.isChild
  })], 32 /* NEED_HYDRATION */), $options.hasChildren ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item[$props.options.childrenProp], function (child, childIndex) {
    return _openBlock(), _createBlock(_component_NestableItem, {
      key: child[$options.keyProp],
      item: child,
      index: childIndex,
      options: $props.options,
      "is-copy": $props.isCopy,
      "is-child": ""
    }, _createSlots({
      _: 2 /* DYNAMIC */
    }, [_renderList(_ctx.$slots, function (_, name) {
      return {
        name: name,
        fn: _withCtx(function (slotData) {
          return [_renderSlot(_ctx.$slots, name, _mergeProps({
            ref_for: true
          }, slotData))];
        })
      };
    })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["item", "index", "options", "is-copy"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}