// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-compliance[data-v-26bb4544] {
  background-image: url(/assets/svg/icon_compliance.svg);
  background-size: 100%;
  background-position: -100px 50%;
  background-repeat: no-repeat;
}
[data-v-26bb4544]  .risma-title .big {
  font-size: 24px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
