import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-solid border-gray-350 bg-white px-4 pt-2 mb-4 h-fit"
};
var _hoisted_2 = {
  key: 0,
  class: "mb-4 items-baseline"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  key: 1,
  class: "flex mb-4 items-baseline"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 2,
  class: "flex mb-4 items-baseline"
};
var _hoisted_7 = {
  key: 3,
  class: "flex mb-4 items-baseline"
};
var _hoisted_8 = {
  key: 4,
  class: "flex mb-4 items-baseline"
};
var _hoisted_9 = {
  key: 5,
  class: "flex mb-4 items-baseline"
};
var _hoisted_10 = {
  key: 6,
  class: "flex mb-4 items-baseline"
};
var _hoisted_11 = {
  key: 7,
  class: "flex mb-4 items-baseline"
};
var _hoisted_12 = {
  key: 8,
  class: "flex mb-4 items-baseline"
};
var _hoisted_13 = {
  key: 9,
  class: "flex mb-4 items-baseline"
};
var _hoisted_14 = {
  key: 10,
  class: "flex items-center mb-4"
};
var _hoisted_15 = {
  key: 11,
  class: "flex mb-4 items-baseline"
};
var _hoisted_16 = ["innerHTML"];
var _hoisted_17 = {
  key: 12,
  class: "flex mb-4 items-baseline"
};
var _hoisted_18 = {
  key: 13,
  class: "flex mb-4 items-baseline"
};
var _hoisted_19 = {
  key: 14,
  class: "flex mb-4 items-baseline"
};
var _hoisted_20 = {
  key: 15,
  class: "flex mb-4 items-baseline"
};
var _hoisted_21 = {
  key: 16,
  class: "flex mb-4 items-baseline"
};
var _hoisted_22 = {
  key: 17,
  class: "flex mb-4 items-baseline"
};
var _hoisted_23 = {
  key: 18,
  class: "flex mb-4 items-baseline"
};
var _hoisted_24 = {
  key: 19,
  class: "flex mb-4 items-baseline"
};
var _hoisted_25 = {
  key: 20,
  class: "flex mb-4 items-baseline"
};
var _hoisted_26 = {
  key: 21,
  class: "flex mb-4 items-baseline"
};
var _hoisted_27 = {
  key: 22,
  class: "flex mb-4 items-baseline"
};
var _hoisted_28 = {
  key: 23,
  class: "flex mb-4 items-baseline"
};
var _hoisted_29 = {
  key: 24,
  class: "flex mb-4 items-baseline"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$data$contract;
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_badges_list = _resolveComponent("badges-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['flex items-center mb-4', {
      'line-through': !$options.lineThroughTitle
    }])
  }, [$options.trafficLightColor ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    class: "mr-2 flex-shrink-0",
    color: $options.trafficLightColor
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), $props.data.path ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    truncate: false,
    class: "mr-2",
    title: $props.data.path + ''
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), !$options.isPreview && $props.data.url ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 2,
    class: "overflow-hidden hover:underline",
    activity: $props.data,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return $data.updatedActivity = $event;
    }),
    onDismissModal: $options.dismissPreview
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        class: "mr-2",
        type: "big",
        icon: "confidential",
        title: $props.data.title,
        "title-attribute": $props.data.hoverTitle,
        "display-icon": $props.data.confidential
      }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "onDismissModal"])) : $props.data.url ? (_openBlock(), _createBlock(_component_router_link, {
    key: 3,
    class: "overflow-hidden hover:underline",
    to: $props.data.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        class: "mr-2",
        type: "big",
        icon: "confidential",
        title: $props.data.title,
        "title-attribute": $props.data.hoverTitle,
        "display-icon": $props.data.confidential
      }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createBlock(_component_risma_title_with_icon, {
    key: 4,
    class: "mr-2",
    type: "big",
    icon: "confidential",
    title: $props.data.title,
    "title-attribute": $props.data.hoverTitle,
    "display-icon": $props.data.confidential
  }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])), $props.data.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 5,
    icon: "check",
    height: "25",
    width: "25",
    class: "stroke-3 self-center"
  })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $props.data.description ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    type: "medium",
    truncate: false,
    title: $props.data.descriptionLabel || $data.translate.description
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "list-reset-styles",
    innerHTML: $props.data.description
  }, null, 8 /* PROPS */, _hoisted_3)])) : _createCommentVNode("v-if", true), $props.data.prStatus ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.presentStatus + ':'
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    innerHTML: $props.data.prStatus
  }, null, 8 /* PROPS */, _hoisted_5)])) : _createCommentVNode("v-if", true), $props.data.start ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.start + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.start
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.expirationDate ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.expirationDate + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.expirationDate
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.deadline && !$props.data.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.deadline + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.deadline
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.reviewDeadline && !$props.data.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.reviewDeadline + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.reviewDeadline
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.responsibleIds && $props.data.responsibleIds.length ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.responsible + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $options.usersResponsible
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.reviewIds && $props.data.reviewIds.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.reviewer + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $options.usersReview
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.organisationIds && $props.data.organisationIds.length ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.organisations + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $options.organisationsAssigned
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.incOccurred ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.incidentOccurred + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.incOccurred
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.severity !== undefined && $props.data.severity !== null ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.severity + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_traffic_light, {
    color: $options.severityColor
  }, null, 8 /* PROPS */, ["color"])])) : _createCommentVNode("v-if", true), $options.showStatus && $props.data.status ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.status + ':'
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "text-sm text-gray-750 list-reset-styles",
    innerHTML: $props.data.status
  }, null, 8 /* PROPS */, _hoisted_16)])) : _createCommentVNode("v-if", true), $props.data.impact ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2 items-baseline",
    truncate: false,
    title: $data.translate.impact + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $options.impactLabel
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.threat ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.threat + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.threat
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.threatLevel ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.levelOfThreat + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.threatLevel
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.vulnerability ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.vulnerability + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.vulnerability
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.probability ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.probability + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.probability
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.vatNo ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.vatNumber
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.vatNo
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.address ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.address
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.address
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.zipCode ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.zipcode
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.zipCode
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.city ? (_openBlock(), _createElementBlock("div", _hoisted_25, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.city
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.city
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.phone ? (_openBlock(), _createElementBlock("div", _hoisted_26, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.phone
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.phone
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.countryCode ? (_openBlock(), _createElementBlock("div", _hoisted_27, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.countryCodeCountry
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.countryCode
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.data.email ? (_openBlock(), _createElementBlock("div", _hoisted_28, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.email
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    class: "font-normal text-base-bg",
    title: $props.data.email
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), (_$props$data$contract = $props.data.contractRoles) !== null && _$props$data$contract !== void 0 && _$props$data$contract.length ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "mr-2",
    truncate: false,
    title: $data.translate.contractRole
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_badges_list, {
    list: $props.data.contractRoles
  }, null, 8 /* PROPS */, ["list"])])) : _createCommentVNode("v-if", true)]);
}