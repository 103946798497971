import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
export default {
  name: 'AdminAuditReportsEntry',
  components: {
    LightTabItem: LightTabItem,
    RismaTitle: RismaTitle
  },
  data: function data() {
    return {
      baseUrl: '/settings/reports/',
      translate: {
        auditReports: this.$trans('Audit reports'),
        loginReport: this.$trans('Login report'),
        usersChangelog: this.$trans('Users changelog'),
        settingsChangelog: this.$trans('Settings changelog'),
        dataAccessLog: this.$trans('Data access log')
      }
    };
  },
  computed: {
    tabList: function tabList() {
      return [{
        url: "".concat(this.baseUrl, "login"),
        name: this.translate.loginReport
      }, {
        url: "".concat(this.baseUrl, "users"),
        name: this.translate.usersChangelog
      }, {
        url: "".concat(this.baseUrl, "settings"),
        name: this.translate.settingsChangelog
      }, {
        url: "".concat(this.baseUrl, "access"),
        name: this.translate.dataAccessLog
      }];
    }
  }
};