import ContactCard from '@/components/Atoms/ContactCard/ContactCard';
export default {
  name: 'ContactCards',
  introduction: 'Wrapper for displaying Contact information in Compliance Reports',
  description: 'A wrapper that renders a collection of contact cards',
  token: '<contact-cards information-asset="[processors:[{}], systems:[{}], controllers: [{}], informationAssets: []]" />',
  components: {
    ContactCard: ContactCard
  },
  props: {
    informationAssets: {
      type: [Object, Array],
      required: true,
      note: 'Information assets to display in the report'
    },
    isCutVersion: {
      required: false,
      type: Boolean,
      default: false,
      note: 'sometimes some data should not be presented'
    }
  },
  emits: ['updateFromPreviewMode', 'dismissModal']
};