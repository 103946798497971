function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Doughnut as DoughnutChart } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartCenterPlugin from '@/utils/plugins/chart-js-center-plugin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import { htmlLegendPlugin } from '@/components/Molecules/Charts/chartPlugins';
export default {
  name: 'RismaDoughnutChart',
  introduction: 'simple DoughnutChart with using the vue-chartjs',
  description: '',
  token: "<risma-doughnut-chart\n      :data=\"chartData\"\n      :labels=\"chartLabels\"\n      :options=\"chartOptions\"\n      :colors=\"colors\"\n      @chartClicked=\"clickCh\"/> ",
  components: {
    DoughnutChart: DoughnutChart,
    RismaTitle: RismaTitle,
    ExportFrame: ExportFrame
  },
  props: {
    datasets: {
      required: true,
      type: Array,
      default: function _default() {},
      note: 'the data to create the component'
    },
    labels: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'labels that are shown'
    },
    options: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    titleType: {
      required: false,
      type: String,
      default: 'medium'
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    description: {
      required: false,
      type: String,
      default: ''
    },
    chartMaxWidth: {
      required: false,
      type: String,
      default: '100%'
    },
    chartMinWidth: {
      required: false,
      type: String,
      default: 'auto'
    },
    showDataLabels: {
      required: false,
      type: Boolean,
      default: false
    },
    dataLabelsColor: {
      required: false,
      type: String,
      default: 'white'
    },
    percentageValue: {
      required: false,
      type: Boolean,
      default: true
    },
    legendPosition: {
      required: false,
      type: String,
      default: 'bottom',
      validator: function validator(position) {
        return ['top', 'bottom', 'left', 'right'].includes(position);
      }
    },
    cover: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Should doughnut chart cover entire place?'
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    },
    isExportOnParent: {
      type: Boolean,
      required: false,
      default: false
    },
    customLegendEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    excelExportEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['chartClicked'],
  data: function data() {
    return {
      legendItems: [],
      customLabels: [],
      hiddenDatasetIndexes: new Map()
    };
  },
  computed: {
    defaultPlugins: function defaultPlugins() {
      var plugins = [ChartDataLabels, ChartCenterPlugin];
      if (this.customLegendEnabled) {
        plugins.push(htmlLegendPlugin);
      }
      return plugins;
    },
    isExcelExportAvailable: function isExcelExportAvailable() {
      return this.excelExportEnabled && this.datasets.length === 1 && this.labels.length;
    },
    chartData: function chartData() {
      var _this = this;
      return {
        labels: this.labels,
        datasets: this.datasets.map(function (dataset) {
          return _objectSpread(_objectSpread({}, dataset), {}, {
            rawData: dataset.data.slice(0),
            data: _this.roundToMinValue(dataset.data, _this.legendItems) // method to keep minimal values visible
          });
        })
      };
    },
    customLegendId: function customLegendId() {
      return Math.random().toString(20).substring(7);
    },
    defaultOptions: function defaultOptions() {
      var _this2 = this;
      var defaultPlugins = {
        datalabels: {
          labels: {
            value: {
              display: this.showDataLabels,
              color: this.dataLabelsColor,
              formatter: function formatter(value, ctx) {
                var rawValue = ctx.dataset.rawData[ctx.dataIndex];
                return value >= 3 ? rawValue : '';
              }
            }
          }
        },
        legend: {
          display: !this.customLegendEnabled,
          position: this.legendPosition,
          onClick: this.legendClickEvent
        },
        tooltip: {
          padding: 15,
          boxPadding: 10,
          callbacks: {
            label: function label(ctx) {
              var dataset = ctx.dataset,
                label = ctx.label,
                dataIndex = ctx.dataIndex;
              var curValue = dataset.rawData[dataIndex];
              if (_this2.percentageValue) {
                return curValue + '%';
              }
              var total = (dataset === null || dataset === void 0 ? void 0 : dataset.total) || dataset.rawData.reduce(function (total, val) {
                return total + +val;
              }, 0);
              return "".concat(label, ": ").concat((curValue / total * 100).toFixed(2), "% (").concat(curValue, ")");
            }
          },
          filter: function filter(item) {
            if (_this2.percentageValue) {
              return _this2.percentageValue && item.parsed && item.parsed !== 100;
            }
            return true;
          },
          // it shouldn't show items that have 0, they can be array with 1 or more elements
          enabled: function enabled(tooltip) {
            var _tooltip$tooltipItems, _tooltip$tooltipItems2;
            return !(((_tooltip$tooltipItems = tooltip.tooltipItems) === null || _tooltip$tooltipItems === void 0 ? void 0 : _tooltip$tooltipItems.length) > 1 || (_tooltip$tooltipItems2 = tooltip.tooltipItems) !== null && _tooltip$tooltipItems2 !== void 0 && _tooltip$tooltipItems2.every(function (item) {
              return item.formattedValue === '' || item.formattedValue === '0';
            }));
          }
        }
      };
      var defaultOptions = _objectSpread({
        onClick: this.handleClick,
        responsive: true,
        maintainAspectRatio: !this.cover,
        devicePixelRatio: 1,
        cutout: '35%'
      }, this.options);
      if (this.customLegendEnabled) {
        this.setHtmlPlugin(defaultPlugins);
      }
      defaultOptions.plugins = defaultPlugins;
      if (this.options && this.options.plugins) {
        Object.assign(defaultOptions.plugins, this.options.plugins || {});
      }
      return defaultOptions;
    }
  },
  watch: {
    labels: {
      handler: function handler() {
        var _this3 = this;
        if (!this.customLegendEnabled) return;
        this.customLabels = this.labels.map(function (label, index) {
          return {
            label: label,
            color: _this3.datasets[0].backgroundColor[index],
            hidden: false
          };
        });
        if (this.$refs.doughnut) {
          this.resetHiddenDataset();
        }
      },
      immediate: true
    }
  },
  methods: {
    legendClickEvent: function legendClickEvent(e, legendItem, legend) {
      //same logic but last line added
      legend.chart.toggleDataVisibility(legendItem.index);
      legend.chart.update();
      this.legendItems = legend.legendItems;
    },
    handleClick: function handleClick(_, event) {
      if (event.length === 1 && this.datasets[0].data[event[0].index]) {
        this.$emit('chartClicked', event[0].index);
      }
    },
    roundToMinValue: function roundToMinValue(data, totalArray) {
      var isFirstEnter = !totalArray.length;
      var total = data.reduce(function (acc, value, index) {
        return isFirstEnter ? acc + +value : totalArray[index].hidden ? acc : acc + +value;
      }, 0);
      return data.map(function (value, index) {
        return +value || +value && !isFirstEnter && !totalArray[index].hidden ? Math.max(+value / total * 100, 3) : 0;
      }); // 1 - each slice will have minimum 1% width and if hidden -> 0%
    },
    setHtmlPlugin: function setHtmlPlugin(defaultPlugins) {
      var self = this;
      defaultPlugins.htmlLegend = {
        containerId: this.customLegendId,
        callback: function callback(index) {
          self.customLabels[index].hidden = !self.customLabels[index].hidden;
          self.hiddenDatasetIndexes.set(index, self.customLabels[index].hidden);
        }
      };
    },
    resetHiddenDataset: function resetHiddenDataset() {
      var _iterator = _createForOfIteratorHelper(this.hiddenDatasetIndexes),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
            key = _step$value[0],
            value = _step$value[1];
          if (value) {
            this.$refs.doughnut.chartInstance.toggleDataVisibility(key);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.hiddenDatasetIndexes.clear();
    },
    excelExportFunction: function excelExportFunction() {
      var data = Object.assign({}, this.datasets[0].data || []);
      var columns = Object.assign({}, this.labels || []);
      return Promise.resolve({
        dataset: [data],
        columns: columns
      });
    }
  }
};