import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "relative z-10"
};
var _hoisted_2 = {
  class: "glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_3 = {
  key: 1,
  class: "absolute right-0 top-12 py-2 px-3 border rounded-md bg-white shadow-md"
};
var _hoisted_4 = {
  class: "w-max"
};
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$targetStatu, _$options$status;
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_modal = _resolveComponent("modal");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$data.showTransitionModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.transitionModalTitle,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.transition,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.doTransition,
    onDismiss: $options.closeModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.selectingNewResponsiblesForThisIncident), 1 /* TEXT */), _createVNode(_component_risma_select, {
        title: $data.translate.responsible,
        options: $options.responsibleOptions,
        "selected-options": $options.responsibleSelected,
        placeholder: $data.translate.assignIncidentToANewResponsible,
        "label-key": "display_name",
        class: "mt-3",
        onSelected: $options.onResponsibleSelected
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"]), _createVNode(_component_tiny_mce_box, {
        type: "mini",
        headline: $data.translate.comment,
        "initial-content": $data.transition.workflowStatusNote,
        "always-open": true,
        "debounce-timeout": 0,
        class: "mt-3",
        onChanged: _cache[0] || (_cache[0] = function ($event) {
          return $data.transition.workflowStatusNote = $event;
        })
      }, null, 8 /* PROPS */, ["headline", "initial-content"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.isDropdownOpen ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.targetStatuses, function (targetStatus) {
    return _openBlock(), _createElementBlock("div", {
      key: targetStatus.id,
      class: "flex items-center pb-3"
    }, [_createElementVNode("p", _hoisted_4, _toDisplayString($data.translate.transitionTo), 1 /* TEXT */), _createVNode(_component_feather_icon, {
      class: "mx-2 stroke-1",
      icon: "arrow-right"
    }), _createElementVNode("p", {
      class: "w-max py-1 px-2 border rounded-md font-semibold cursor-pointer",
      style: _normalizeStyle($options.getColorStyles(targetStatus)),
      onClick: function onClick($event) {
        return $options.transitionTo(targetStatus);
      }
    }, _toDisplayString(targetStatus.title), 13 /* TEXT, STYLE, PROPS */, _hoisted_5)]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", {
    class: _normalizeClass(["w-full pb-1 font-semibold cursor-pointer", (_$options$targetStatu = $options.targetStatuses) !== null && _$options$targetStatu !== void 0 && _$options$targetStatu.length ? 'pt-3 border-t' : ''])
  }, _toDisplayString($data.translate.viewWorkflow), 3 /* TEXT, CLASS */)])) : _createCommentVNode("v-if", true), _createElementVNode("p", {
    class: "flex items-center py-2 px-3 border rounded-md font-semibold cursor-pointer",
    style: _normalizeStyle($options.getColorStyles($options.status)),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.isDropdownOpen = !$data.isDropdownOpen;
    })
  }, [_createElementVNode("span", null, _toDisplayString(((_$options$status = $options.status) === null || _$options$status === void 0 ? void 0 : _$options$status.title) || 'Unknown/Deleted status'), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    class: "ml-2 stroke-2",
    icon: $data.isDropdownOpen ? 'chevron-down' : 'chevron-down'
  }, null, 8 /* PROPS */, ["icon"])], 4 /* STYLE */)])), [[_directive_click_outside, $options.closeDropdown]]);
}