// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-reset-styles[data-v-d7b9046a]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-d7b9046a]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-d7b9046a]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-d7b9046a]  li {
  padding-left: 0.3em;
}
.control-main-wrap[data-v-d7b9046a] {
  margin-bottom: 2rem;
}
.control-details-section[data-v-d7b9046a] {
  margin: 0 0 20px 0;
}
.control-details-toggler[data-v-d7b9046a] {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.control-details-description[data-v-d7b9046a],
.control-details-evidence[data-v-d7b9046a] {
  margin: 0 0 20px;
}
.control-details-description[data-v-d7b9046a]  .risma-title,
.control-details-evidence[data-v-d7b9046a]  .risma-title {
  margin: 0 0 0.5rem;
}
.traffic-light-interval.inherited-from-mother[data-v-d7b9046a]  .link-item-tag a {
  pointer-events: none;
}
.traffic-light-interval[data-v-d7b9046a]  .medium,
.activity-custom-fields-wrap[data-v-d7b9046a]  .medium,
.activity-custom-fields-wrap[data-v-d7b9046a] {
  overflow: visible;
}
.control-details-mother-attaches[data-v-d7b9046a]  .link-item-tag {
  display: inline;
}
.mothercontrol-description[data-v-d7b9046a]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.mothercontrol-description[data-v-d7b9046a]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.mothercontrol-description[data-v-d7b9046a]  li {
  padding-left: 0.3em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
