import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "card card-item"
};
var _hoisted_2 = {
  class: "node-header"
};
var _hoisted_3 = {
  class: "node-title"
};
var _hoisted_4 = {
  class: "progress-libraries-body"
};
var _hoisted_5 = {
  class: "progress-libraries-body-main"
};
var _hoisted_6 = {
  class: "progress-libraries-body-main-top"
};
var _hoisted_7 = {
  class: "progress-libraries-body-main-top-column"
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  class: "progress-libraries-body-side"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = {
  key: 1
};
var _hoisted_12 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_preview_modal_link, {
    activity: $props.node
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.node.title,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.description,
    class: "node-status-title",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "list-reset-styles",
    innerHTML: $props.node.description
  }, null, 8 /* PROPS */, _hoisted_8)])])]), _createElementVNode("div", _hoisted_9, [$props.node.responsibleUserIds.length ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.responsible,
    class: "node-status-title",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.responsible), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.node.accountableUserIds.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.accountable,
    class: "node-status-title",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.accountable), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.node.organisationIds.length ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    class: "node-status-title",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.organisations), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])])]);
}