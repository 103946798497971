import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import CreateInitiativeModal from '@/components/Organisms/CreateInitiativeModal';
import ControlCreationModal from '@/components/Molecules/Controls/ControlCreationModal';
import { MODULES, getModuleTitles } from '@/constants/modules';
import { create as _createInitiative } from '@/api/execution/initiative';
export default {
  name: 'LinksRecommendedModal',
  introduction: 'LinksRecommendedModal is used to update links for gap',
  description: 'LinksRecommendedModal is the modal from where the users can add mitigation activities',
  token: '<links-recommended-modal />',
  components: {
    Notification: Notification,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    CreateInitiativeModal: CreateInitiativeModal,
    ControlCreationModal: ControlCreationModal
  },
  props: {
    article: {
      type: Object,
      required: true,
      note: 'article info'
    },
    initiatives: {
      required: true,
      type: Array,
      note: 'List of all initiatives'
    }
  },
  emits: ['addRecommendedActivity', 'dismiss'],
  data: function data() {
    return {
      ModuleTitles: getModuleTitles(),
      notificationMessage: '',
      notificationType: 'success',
      showCreateControl: false,
      showCreateInitiative: false,
      preset: {
        title: '',
        description: '',
        solutionIds: []
      },
      translate: getTranslate['LinksRecommendedModal']()
    };
  },
  computed: {
    suggestedActivities: function suggestedActivities() {
      return this.article.suggestedActivities;
    },
    subTitle: function subTitle() {
      return this.translate.clickAddInOrderToApplyTheRecommendedActi;
    }
  },
  methods: {
    addRecommendedItem: function addRecommendedItem(module, activity) {
      this.preset.title = activity.activity_header;
      this.preset.description = activity.activity_description;
      this.preset.solutionIds = this.article.solutionIds || [];
      if (module === MODULES.CONTROLS) {
        this.showCreateControl = true;
      } else {
        this.showCreateInitiative = true;
      }
    },
    createControl: function createControl(newControl) {
      this.$emit('addRecommendedActivity', newControl);
      this.showNotificationAfterCreate(newControl.title);
      this.showCreateControl = false;
    },
    createInitiative: function createInitiative(newInitiative) {
      var _this = this;
      this.showCreateInitiative = false;
      _createInitiative(newInitiative).then(function (response) {
        _this.$emit('addRecommendedActivity', response);
        _this.showNotificationAfterCreate(response.title);
      });
    },
    showNotificationAfterCreate: function showNotificationAfterCreate(name) {
      this.handleShowNotify({
        type: 'success',
        message: name + ' ' + this.translate.added
      });
    },
    handleShowNotify: function handleShowNotify(_ref) {
      var type = _ref.type,
        message = _ref.message;
      this.notificationType = type;
      this.notificationMessage = message;
      setTimeout(this.handleDismissNotification, 3000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
    }
  }
};