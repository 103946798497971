import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer justify-end",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.openLinkCompanyModal && $options.openLinkCompanyModal.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-2 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.linkCompany), 1 /* TEXT */)]), $data.showLinkCompanyModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.linkExistingCompany,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    "modal-fixed-width": "600px",
    onAccept: $options.linkCompany,
    onDismiss: $options.closeLinkCompanyModal
  }, {
    body: _withCtx(function () {
      return [!$data.isLoading ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [$data.hasErrorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $data.hasErrorMessage = false;
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.selectCompany), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
        modelValue: $data.selectedCompanyId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.selectedCompanyId = $event;
        }),
        title: $data.translate.selectCompany,
        placeholder: $data.translate.selectCompany,
        options: $options.companiesOptions,
        "with-search": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"])], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 1,
        class: "mx-auto w-full"
      }))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}