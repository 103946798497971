import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { useTrafficLights } from '@/Store/trafficLights';
export function getItemGaps(gapList, gapArticleMap) {
  var trafficLightStore = useTrafficLights();
  return gapList.map(function (gap) {
    var gapArticle = gapArticleMap.get(gap.articleId);
    var gapColor = trafficLightStore.getGapLight(gap.analysis_rag);
    if (!gapArticle || !gapColor) return null;
    return {
      color: gapColor.color,
      activity: getFieldsFromObjectForPreview(gap),
      fieldText: "".concat(gap.nodeTitle, " - ").concat(gapArticle.article)
    };
  });
}