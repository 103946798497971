import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center justify-center flex-col"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_inline_select = _resolveComponent("inline-select");
  var _component_dynamic_tooltip = _resolveComponent("dynamic-tooltip");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_inline_select, {
    "model-value": $data.position,
    options: $data.positionOptions,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.position = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "options"]), _createElementVNode("div", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_dynamic_tooltip, {
    title: "RISMA new feature",
    position: $data.position
  }, {
    tooltipBody: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createElementVNode("div", {
        class: "w-52"
      }, "Discover our new AI feature", -1 /* HOISTED */)]);
    }),
    tooltipTrigger: _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createElementVNode("p", null, "First time opening", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["position"]), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_dynamic_tooltip, {
    title: "Some Test Title",
    "initial-mode": "secondary",
    position: $data.position
  }, {
    tooltipBody: _withCtx(function () {
      return _cache[3] || (_cache[3] = [_createElementVNode("div", {
        class: "w-52"
      }, " Discover our new AI feature that enhances your workflow with intelligent ", -1 /* HOISTED */)]);
    }),
    tooltipTrigger: _withCtx(function () {
      return _cache[4] || (_cache[4] = [_createElementVNode("p", null, "Hover ME!", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["position"]), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */))])]);
}