import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
import { getDataflowThirdPartiesTitle } from '@/utils/TitleFromSetting';
export default {
  name: 'DataFlowWizardStepSystems',
  components: {
    RismaSelect: RismaSelect,
    RismaTitle: RismaTitle,
    TextBox: TextBox
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    selectedOptions: {
      type: Object,
      required: true,
      note: 'selected options for all steps'
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      DataFlowFields: DataFlowFields,
      translate: {
        chooseThirdParties: "".concat(this.$trans('Choose'), " ").concat(getDataflowThirdPartiesTitle()),
        comments: this.$trans('Comments')
      }
    };
  }
};