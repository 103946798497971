function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useComplianceOverview } from '@/Store/complianceOverview';
import InfoBox from '@/components/Atoms/Containers/InfoBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
export default {
  name: 'ComplianceDistributionChart',
  description: 'General component that use in Compliance Overview page to show data as chart or just some data',
  components: {
    InfoBox: InfoBox,
    RismaBarChart: RismaBarChart,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      translate: {
        notAssessed: this.$trans('Not assessed'),
        distributionOfMaterial: this.$trans('Distribution of material disclosure requirements')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useComplianceOverview, ['materialityDistribution'])), {}, {
    labels: function labels() {
      return Object.keys(this.materialityDistribution);
    },
    datasets: function datasets() {
      return [{
        data: Object.values(this.materialityDistribution),
        backgroundColor: '#637994'
      }];
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.fetchOverviewDashboard(this.projectId).finally(function () {
      return _this.isLoaded = true;
    });
  },
  methods: _objectSpread({}, mapActions(useComplianceOverview, ['fetchOverviewDashboard']))
};