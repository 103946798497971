// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-select[data-v-c2105e16] {
  width: 100%;
  clear: both;
}
.groupName[data-v-c2105e16] {
  background-color: transparent !important;
  cursor: auto;
  color: #BEBEBE !important;
}
.custom-select-inner[data-v-c2105e16] {
  position: relative;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.custom-select-inner.expanded[data-v-c2105e16] {
  border-radius: 5px 5px 0 0;
}
.custom-select-inner.disabled[data-v-c2105e16] {
  color: #BEBEBE;
  background-color: #f3f3f3;
}
.custom-select-inner.disabled .select-selected[data-v-c2105e16] {
  cursor: not-allowed;
}
.custom-select-inner .single-select-reset[data-v-c2105e16] {
  position: absolute;
  top: 0;
  right: 2.5rem;
  height: 40px;
  width: 20px;
  font-size: 20px;
  color: #BEBEBE;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  transition: color 0.4s;
}
.custom-select-inner .single-select-reset[data-v-c2105e16]:hover {
  color: #4D4D4D;
}
.custom-select-inner .select-items[data-v-c2105e16] {
  border: 1px solid #BEBEBE;
  position: absolute;
  background-color: #FFFFFF;
  top: 100%;
  max-height: 30vh;
  overflow-y: auto;
  left: 0;
  right: 0;
  z-index: 9;
  overflow-x: hidden;
}
.custom-select-inner .select-items.select-hide[data-v-c2105e16] {
  display: none;
}
.custom-select-inner .select-items div.selected[data-v-c2105e16] {
  background-color: #f3f3f3;
}
.custom-select-inner .select-items div.selected[data-v-c2105e16]:hover {
  background-color: #637994;
  color: #FFFFFF;
}
.custom-select-inner .select-items div.disabled[data-v-c2105e16] {
  cursor: default;
  color: #4D4D4D;
  background: #dedede;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.custom-select-inner .select-items div.disabled[data-v-c2105e16]:hover {
  color: #4D4D4D;
  background: #dedede;
}
.custom-select-inner .select-selected[data-v-c2105e16] {
  min-height: 40px;
  overflow: hidden;
  cursor: pointer;
}
.custom-select-inner .select-selected .filter-options-input[data-v-c2105e16] {
  border-radius: 5px;
  color: #4D4D4D !important;
}
.custom-select-inner .select-selected.select-arrow-active[data-v-c2105e16]:after {
  transform: rotate(180deg);
}
.custom-select-inner .select-selected .select-selected-value[data-v-c2105e16] {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option[data-v-c2105e16] {
  overflow-wrap: break-word;
  white-space: pre-line;
}
.required .value-wrap[data-v-c2105e16] {
  border-color: #B03D00;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
