import i18n from '@/i18n';
export var getTranslate = {
  CompanyContacts: function CompanyContacts() {
    return {
      companyContacts: i18n.t('Company contacts'),
      deleteContact: i18n.t('Delete contact'),
      areYouSure: i18n.t('Are you sure?'),
      name: i18n.t('Name'),
      title: i18n.t('Title'),
      email: i18n.t('Email'),
      phone: i18n.t('Phone'),
      delete: i18n.t('Delete')
    };
  },
  CompanyDescription: function CompanyDescription() {
    return {
      links: i18n.t('Links'),
      save: i18n.t('Save'),
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      close: i18n.t('Close'),
      chooseDataProcessor: i18n.t('Choose data processor'),
      createNew: i18n.t('Create new'),
      vatNumber: i18n.t('VAT number'),
      invalidVatNumber: i18n.t('Invalid VAT number'),
      getData: i18n.t('Get data'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      phone: i18n.t('Phone'),
      countryCodeCountry: i18n.t('Country code/Country'),
      email: i18n.t('E-mail'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      description: i18n.t('Description'),
      linkDataProcessor: i18n.t('Link data processor'),
      assets: i18n.t('Assets'),
      name: i18n.t('Name'),
      type: i18n.t('Type'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      attach: i18n.t('Attach'),
      companyHasBeenLinked: i18n.t('Company has been linked'),
      getLEINumber: i18n.t('Get LEI number'),
      dataFetchedFromLEIRegister: i18n.t('Data fetched from LEI register'),
      leiNumberMustBeExactly20: i18n.t('LEI number must be exactly 20 alphanumeric characters'),
      includeInDora: i18n.t('Include in DORA registry'),
      doraRegistry: i18n.t('DORA registry'),
      companyType: i18n.t('Company type'),
      companyHierarchy: i18n.t('Company hierarchy'),
      branchCode: i18n.t('Branch code'),
      competentAuthority: i18n.t('Competent authority'),
      totalAssetsValue: i18n.t('Total assets value'),
      totalAssetsCurrency: i18n.t('Total assets currency'),
      parentCompany: i18n.t('Parent company'),
      chooseParentCompany: i18n.t('Choose parent company'),
      branch: i18n.t('Branch'),
      subsidiary: i18n.t('Subsidiary'),
      associationToParent: i18n.t('Association to parent company'),
      chooseAssociationToParent: i18n.t('Choose association to parent company'),
      chooseCompanyType: i18n.t('Choose company type'),
      chooseCompanyHierarchy: i18n.t('Choose company hierarchy')
    };
  }
};