import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
export default {
  name: 'RismaSelectKitchen',
  components: {
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon,
    Checkboxes: Checkboxes
  },
  data: function data() {
    return {
      RismaSelectOptions: [{
        id: '1',
        option: 'Hello'
      }, {
        id: '2',
        option: 'To'
      }, {
        id: '3',
        option: 'You'
      }, {
        id: '4',
        option: 'Me'
      }, {
        id: '5',
        option: 'And'
      }, {
        id: '6',
        option: 'Irene'
      }],
      RismaSelectOptionsSelected: [{
        id: '2',
        option: 'To'
      }],
      RismaSelectCustomlabel: [{
        id: 1,
        title: "It's first option",
        icon: 'arrow-left'
      }, {
        id: 2,
        title: "It's second option",
        icon: 'arrow-right'
      }],
      RismaSelectCustomlabelSelected: [],
      groupedOptions: [{
        language: 'Javascript',
        libs: [{
          name: 'Vue.js',
          category: 'Front-end'
        }, {
          name: 'Adonis',
          category: 'Backend'
        }]
      }, {
        language: 'Ruby',
        libs: [{
          name: 'Rails',
          category: 'Backend'
        }, {
          name: 'Sinatra',
          category: 'Backend'
        }]
      }, {
        language: 'Other',
        libs: [{
          name: 'Laravel',
          category: 'Backend'
        }, {
          name: 'Phoenix',
          category: 'Backend'
        }]
      }],
      groupedSelectedOptions: [],
      groupSelectable: false
    };
  }
};