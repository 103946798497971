function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import * as awarenessAPI from '@/api/compliance/awareness';
import RismaPieChart from '@/components/Molecules/Charts/RismaPieChart';
import { Colors } from '@/Colors';
import { AWARENESS_DASHBOARDS_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { toLocalDateTimeFull as _toLocalDateTimeFull } from '@/utils/date';
import { isLockedProcessLibrary } from '@/utils/access';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'AwarenessDashboard',
  token: '<awareness-dashboard />',
  components: {
    RismaPieChart: RismaPieChart,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ToggleMenu: ToggleMenu,
    RismaDatatable: RismaDatatable,
    ViewSwitcher: ViewSwitcher,
    FeatherIcon: FeatherIcon
  },
  mixins: [RismaDatatableDefaultMixin, ProcessLibraryTitleMixin],
  props: {
    dataList: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list from the awarenessEntry same api call (if quiz setting is on, othervise api call will be on this component)'
    },
    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  setup: function setup() {
    return {
      viewSelected: getLocalStorageComputed('awareness_dashboards_view', viewTypes.COMPACT)
    };
  },
  data: function data() {
    return {
      isLoaded: true,
      requests: {},
      awareness: {},
      colors: [Colors.notSet, Colors.answered, Colors.noGap],
      requestId: null,
      dataset: [],
      AWARENESS_DASHBOARDS_VIEWS: AWARENESS_DASHBOARDS_VIEWS,
      viewTypes: viewTypes,
      featherIconFields: ['send_reminder', 'delete'],
      translate: getTranslate['AwarenessDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['isAdmin', 'currentUser'])), {}, {
    isUserLocked: function isUserLocked() {
      return !this.isAdmin && isLockedProcessLibrary(this.currentUser);
    },
    columns: function columns() {
      var list = {
        title: this.translate.title,
        process_library_activity: "".concat(this.processLibraryTitle, " ").concat(this.translate.activity),
        sent_by: this.translate.sentBy,
        datetime: this.translate.sent,
        request_count_totally: this.translate.requestCountTotal,
        requests_read: this.translate.requestsRead,
        requests_approved: this.translate.requestsApproved
      };
      if (!this.isUserLocked) {
        list.send_reminder = this.translate.sendReminder;
      }
      if (this.isAdmin) {
        list.delete = this.translate.delete;
      }
      return list;
    },
    excelColumnsToFilter: function excelColumnsToFilter() {
      return ['send_reminder', 'delete'];
    },
    filterColumnsFn: function filterColumnsFn() {
      var _this = this;
      return {
        columns: function columns(items) {
          return items.filter(function (item) {
            return !_this.excelColumnsToFilter.includes(item.key);
          });
        }
      };
    }
  }),
  watch: {
    id: function id() {
      this.updateRequestData();
    }
  },
  mounted: function mounted() {
    var _this$dataList;
    if (!((_this$dataList = this.dataList) !== null && _this$dataList !== void 0 && _this$dataList.length)) {
      this.loadAll();
    } else {
      this.updateRequestData();
    }
  },
  methods: {
    isNormalField: function isNormalField(field) {
      return _typeof(field) !== 'object';
    },
    loadAll: function loadAll() {
      var _this2 = this;
      this.isLoaded = false;
      var params = '?data[]=all';
      return awarenessAPI.getAll(params).then(function (_ref) {
        var list = _ref.list;
        _this2.handleData(list);
        _this2.isLoaded = true;
      }).catch(function (e) {
        throw e;
      });
    },
    handleData: function handleData(list) {
      this.requests = this.formatRequests(list);
      this.dataset = this.prepareDataset();
      this.enhanceDataset(this.dataset, this.columns);
    },
    updateRequestData: function updateRequestData() {
      var _this3 = this;
      var list = this.id && this.id !== 'all' ? this.dataList.filter(function (item) {
        var _item$links, _item$links$complianc;
        return (item === null || item === void 0 || (_item$links = item.links) === null || _item$links === void 0 || (_item$links = _item$links.compliance_process_tree_node) === null || _item$links === void 0 ? void 0 : _item$links.length) && ((_item$links$complianc = item.links.compliance_process_tree_node[0]) === null || _item$links$complianc === void 0 ? void 0 : _item$links$complianc.id) === +_this3.id;
      }) : this.dataList;
      this.handleData(list);
    },
    prepareDataset: function prepareDataset() {
      var _this4 = this;
      return this.requests.map(function (request) {
        var list = {
          title: {
            field: request.title
          },
          process_library_activity: {
            field: _this4.prepareProcessLibraryActivity(request)
          },
          sent_by: {
            field: _this4.getUserNameById(request.sentBy)
          },
          datetime: {
            field: request.sentDateInLocalTime
          },
          request_count_totally: {
            field: request.users.length
          },
          requests_read: {
            field: request.filteredUsers.requestsRead.length
          },
          requests_approved: {
            field: request.filteredUsers.requestsSigned.length
          }
        };
        if (!_this4.isUserLocked) {
          list.send_reminder = {
            field: _this4.prepareSendReminderButton(request.id)
          };
        }
        if (_this4.isAdmin) {
          list.delete = {
            field: _this4.prepareDeleteButton(request.id)
          };
        }
        return list;
      });
    },
    getUserNameById: function getUserNameById(id) {
      var user = this.users.find(function (user) {
        return user.id == id;
      });
      return user ? user.display_name : '';
    },
    prepareProcessLibraryActivity: function prepareProcessLibraryActivity(request) {
      if (!request.links || !request.links.compliance_process_tree_node || !request.links.compliance_process_tree_node[0]) return '';
      if (request.links.compliance_process_tree_node[0].url) {
        return {
          url: request.links.compliance_process_tree_node[0].url,
          title: request.links.compliance_process_tree_node[0].title
        };
      }
      return request.links.compliance_process_tree_node[0].title || '';
    },
    prepareSendReminderButton: function prepareSendReminderButton(id) {
      return {
        icon: 'mail',
        isReminder: true,
        data: id
      };
    },
    prepareDeleteButton: function prepareDeleteButton(id) {
      return {
        icon: 'trash-2',
        additionalCss: 'text-red-50',
        data: id
      };
    },
    formatRequests: function formatRequests(requests) {
      var _this5 = this;
      return requests.map(function (r) {
        var requestsRead = r.users.filter(function (user) {
          return user.firstClick != null && user.signoff == null;
        });
        var requestsSigned = r.users.filter(function (user) {
          return user.signoff != null;
        });
        var requestsSent = r.users.filter(function (user) {
          return user.firstClick == null && user.signoff == null;
        });
        var requestsReadLength = requestsRead.length;
        var requestsSignedLength = requestsSigned.length;
        return _objectSpread({
          requestsRead: requestsReadLength,
          requestsSigned: requestsSignedLength,
          userFilter: null,
          filteredUsers: {
            requestsRead: requestsRead,
            requestsSigned: requestsSigned,
            requestsSent: requestsSent
          },
          labels: [_this5.translate.requestsSent, _this5.translate.requestsRead, _this5.translate.requestsApproved],
          data: [r.users.length - requestsReadLength - requestsSignedLength, requestsReadLength, requestsSignedLength],
          sentDateInLocalTime: _this5.toLocalDateTimeFull(r.sent)
        }, r);
      });
    },
    dataMenuOptions: function dataMenuOptions(requestId) {
      var _this6 = this;
      var result = [{
        id: 1,
        title: this.translate.sendReminder,
        icon: 'mail',
        action: function action() {
          _this6.showReminderConfirm(requestId);
        }
      }];
      if (this.isAdmin) {
        result.push({
          id: 2,
          style: {
            color: Colors.system.warning
          },
          title: this.translate.deleteDashboard,
          icon: 'trash-2',
          action: function action() {
            _this6.showDeleteConfirm(requestId);
          }
        });
      }
      return result;
    },
    showReminderConfirm: function showReminderConfirm(requestId) {
      var _this7 = this;
      this.requestId = requestId;
      this.$confirm(this.translate.sendReminder, this.translate.areYouSureYouWantToSendRemindersToTheUse, function (res) {
        return res && _this7.sendReminder();
      }, {
        buttonOkText: this.translate.send
      });
    },
    sendReminder: function sendReminder() {
      var _this8 = this;
      var data = {
        reminderMessage: null // 'Reminder...',
      };
      return awarenessAPI.sendReminder(this.requestId, data).then(function () {
        return _this8.$notify({
          title: _this8.translate.theReminderHasBeenSuccessfullySent
        });
      });
    },
    showDeleteConfirm: function showDeleteConfirm(requestId) {
      var _this9 = this;
      this.requestId = requestId;
      this.$confirm(this.translate.deleteDashboard, this.translate.areYouSureYouWantToDeleteThisDashboard, function (res) {
        return res && _this9.deleteDashboard();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    deleteDashboard: function deleteDashboard() {
      var _this10 = this;
      awarenessAPI.deleteRequest(this.requestId).then(function () {
        _this10.loadAll();
      });
      this.deleteModalVisible = false;
    },
    hideDeleteAlert: function hideDeleteAlert() {
      this.deleteModalVisible = false;
    },
    clickSlice: function clickSlice(sliceIndex, index) {
      var pieChart = this.requests.find(function (request) {
        return request.id === index;
      });
      switch (sliceIndex) {
        case 0:
          pieChart.userFilter = 'requestsSent';
          break;
        case 1:
          pieChart.userFilter = 'requestsRead';
          break;
        case 2:
          pieChart.userFilter = 'requestsSigned';
          break;
        default:
          pieChart.userFilter = null;
      }
    },
    filteredUsers: function filteredUsers(request) {
      if (!request.userFilter) return;
      var filter = request.userFilter;
      var result = request.filteredUsers[filter];
      return result;
    },
    getUserRequestStatus: function getUserRequestStatus(userRequest) {
      if (userRequest.lastError) {
        return this.translate.failed;
      }
      if (userRequest.sent == '1970-01-01 00:00:01') {
        return this.translate.unknown;
      }
      if (userRequest.sent) {
        return this.translate.sent;
      }
      return this.translate.pending;
    },
    toLocalDateTimeFull: function toLocalDateTimeFull(time) {
      return _toLocalDateTimeFull(time);
    }
  }
};