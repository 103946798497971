function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { Colors } from '@/Colors';
import * as Utils from '@/utils/Utils';
import PreviewRiskViewMixin from './PreviewRiskViewMixin';
import { RiskDegreeTitleMixin } from '@/mixins/RiskDegreeTitleMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewRiskCustomFields from '@/components/Atoms/Preview/PreviewRiskCustomFields';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { MODULES } from '@/constants/modules';
export default {
  name: 'PreviewRiskExpanded',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    PreviewRiskCustomFields: PreviewRiskCustomFields,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [PreviewRiskViewMixin, RiskDegreeTitleMixin],
  props: {
    risk: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    scoring: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    period: {
      type: Object,
      default: null,
      note: 'Project period data'
    },
    isRisk2: {
      type: Boolean,
      default: false
    },
    isShowLinkedItems: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateFromPreviewMode', 'deleteItem'],
  data: function data() {
    return {
      MODULES: MODULES,
      isRiskExpanded: false,
      Colors: Colors,
      cellCssStyles: 'p-2 border border-gray-610 w-1/3 text-left',
      updatedUnderlyingRisks: null,
      translate: getTranslate['PreviewRiskExpanded']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    areRisk2ParametersSet: function areRisk2ParametersSet() {
      return this.risk._threatValueAsString || this.risk._threatLevelValueAsString || this.risk._vulnerabilityValueAsString;
    },
    notSet: function notSet() {
      return this.translate.notSet;
    },
    hoverTitle: function hoverTitle() {
      return DatatableRenderer.getItemHoverTitle(this.risk);
    },
    foundCustomFields: function foundCustomFields() {
      var _this = this;
      if (!this.risk.customFieldValues) {
        return [];
      }
      return this.risk.customFieldValues.filter(function (field) {
        return _this.scoring.fields.find(function (item) {
          return item.id === field.fieldId && item.module !== MODULES.SCORING_ZAXIS && item.fieldWeight === -1 && _this.isRightField(field, _this.scoring);
        });
      });
    },
    riskImpactScore: function riskImpactScore() {
      var impactScore = this.risk[RiskBaseModelProps.IMPACT_SCORE] - 1;
      if (this.scoring.customImpactAxis > 0) {
        var values = Object.values(this.scoring[RiskBaseModelProps.CUSTOM_IMPACT].options);
        return "".concat(impactScore + 1, " (").concat(impactScore === -1 ? this.notSet : values[impactScore].value, ")");
      }
      return impactScore + 1;
    },
    riskLikelihoodScore: function riskLikelihoodScore() {
      var likeLihoodScore = this.risk[RiskBaseModelProps.LIKELIHOOD_SCORE] - 1;
      if (this.scoring.customLikelihoodAxis > 0) {
        var values = Object.values(this.scoring[RiskBaseModelProps.CUSTOM_LIKELIHOOD].options);
        return "".concat(likeLihoodScore + 1, " (").concat(likeLihoodScore === -1 ? this.notSet : values[likeLihoodScore].value, ")");
      }
      return likeLihoodScore + 1;
    },
    titleToggleLink: function titleToggleLink() {
      return this.isRiskExpanded ? this.translate.hideLinkedItems : this.translate.showLinkedItems;
    },
    scoringLikelihoodDescription: function scoringLikelihoodDescription() {
      if (!this.scoring.likelihood_labels[this.risk.likelihood_score - 1]) {
        throw new Error("Could not find likelihood label option for ".concat(this.risk.title));
      }
      return this.scoring.likelihood_labels[this.risk.likelihood_score - 1].description;
    },
    consequenceLabel: function consequenceLabel() {
      return getConsequenceLabel(this.period);
    },
    probabilityLabel: function probabilityLabel() {
      return getProbabilityLabel(this.period);
    },
    riskAssessmentRows: function riskAssessmentRows() {
      var _this$period,
        _this2 = this;
      if (!((_this$period = this.period) !== null && _this$period !== void 0 && (_this$period = _this$period.consequenceRows) !== null && _this$period !== void 0 && _this$period.length)) return [];
      return this.period.consequenceRows.map(function (item) {
        var _residualConsequenceR, _residualConsequenceR2, _residualProbabilityR, _residualProbabilityR2;
        var residualConsequenceRowScore = _this2.risk.residualConsequenceRowScores.find(function (row) {
          return row.rowId === item.id;
        });
        var residualProbabilityRowScore = _this2.risk.residualProbabilityRowScores.find(function (row) {
          return row.rowId === item.id;
        });
        return {
          label: item.label,
          residualConsequenceRowScore: "".concat((residualConsequenceRowScore === null || residualConsequenceRowScore === void 0 || (_residualConsequenceR = residualConsequenceRowScore.column) === null || _residualConsequenceR === void 0 ? void 0 : _residualConsequenceR.value) || 0, ": ").concat((residualConsequenceRowScore === null || residualConsequenceRowScore === void 0 || (_residualConsequenceR2 = residualConsequenceRowScore.column) === null || _residualConsequenceR2 === void 0 ? void 0 : _residualConsequenceR2.label) || _this2.translate.na),
          residualProbabilityRowScore: "".concat((residualProbabilityRowScore === null || residualProbabilityRowScore === void 0 || (_residualProbabilityR = residualProbabilityRowScore.column) === null || _residualProbabilityR === void 0 ? void 0 : _residualProbabilityR.value) || 0, ": ").concat((residualProbabilityRowScore === null || residualProbabilityRowScore === void 0 || (_residualProbabilityR2 = residualProbabilityRowScore.column) === null || _residualProbabilityR2 === void 0 ? void 0 : _residualProbabilityR2.label) || _this2.translate.na)
        };
      });
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    onLinkedItemUpdate: function onLinkedItemUpdate(_ref) {
      var module = _ref.module,
        item = _ref.item;
      var propsToModules = _defineProperty(_defineProperty({}, MODULES.CONTROLS, '_linkedControls'), MODULES.EXECUTION, '_linkedInitiatives');
      if (!propsToModules[module] || !this.risk[propsToModules[module]]) return;
      var items = JSON.parse(JSON.stringify(this.risk[propsToModules[module]]));
      var idx = items.findIndex(function (i) {
        return i.id === item.id;
      });
      if (idx !== -1) {
        items[idx] = item;
        this.$emit('updateFromPreviewMode', _objectSpread(_objectSpread({}, this.risk), {}, _defineProperty({}, propsToModules[module], items)));
      }
    },
    isRightField: function isRightField(field, scoring) {
      return field.fieldId !== scoring.customImpactAxis && field.fieldId !== scoring.customLikelihoodAxis;
    },
    truncateHtml: function truncateHtml(text, maxSymbols) {
      return Utils.truncateHtml(text, maxSymbols, {
        html: true,
        maxLines: 3
      });
    },
    updateUnderlyingRisk: function updateUnderlyingRisk(risk) {
      this.updatedUnderlyingRisks = risk;
    },
    dismissUnderlyingRiskPreview: function dismissUnderlyingRiskPreview() {
      var _this3 = this;
      if (this.updatedUnderlyingRisks) {
        var riskToUpdate = this.risk.children.find(function (risk) {
          return risk.id === _this3.updatedUnderlyingRisks.id;
        });
        riskToUpdate.title = this.updatedUnderlyingRisks.title;
        this.updatedUnderlyingRisks = null;
      }
    }
  })
};