function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getAllQuestionAnswersForNode } from '@/api/compliance/questionnaire';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
import { TabTypes } from '@/constants/Tabs';
import * as LinksTabUtils from '@/utils/linksTabUtils';
export var LinksTabHandlePreviewMixin = {
  data: function data() {
    return {
      isLoadingPreview: false,
      previewData: {
        data: null,
        list: null,
        type: null
      }
    };
  },
  methods: {
    handleQuestionnairePreview: function handleQuestionnairePreview(item) {
      var _this = this;
      this.isLoadingPreview = true;
      return getAllQuestionAnswersForNode(item.compliance_projects_id, item.id, item.questionnaireId).then(function (_ref) {
        var list = _ref.list;
        return _this.setPreviewQuestionnaire(list);
      });
    },
    handlePreview: function handlePreview(item, moduleTitle) {
      var _this2 = this;
      this.selectedTag = _objectSpread(_objectSpread({}, item), {}, {
        moduleTitle: moduleTitle
      });
      this.previewData = {
        data: null,
        list: null,
        type: 'default'
      };
      switch (this.currentTab) {
        case TabTypes.LINKS:
          this.handleLinkPreview(item);
          break;
        case TabTypes.QUESTIONNAIRE:
          this.handleQuestionnairePreview(item);
          this.handleLinkPreview(item);
          break;
        case TabTypes.GAPLINKS:
          if (item.mitigatingItems) {
            this.previewData.list = item;
            this.previewData.type = this.LinkTypes.MITIGATING_ACTIVITIES;
            this.previewData.data = [];
            item.mitigatingItems.forEach(function (item) {
              _this2.previewData.data.push(LinksTabUtils.preparePreviewData(item, _this2.workflows, _this2.currentUser, _this2.isAdmin, _this2.projects));
              _this2.isLoadingPreview = false;
            });
          }
          if (item.gapList) {
            this.previewData.list = item;
            this.previewData.type = this.LinkTypes.GAP_ANALYSIS;
            this.handleLinkPreview({
              id: item.nodeId || item.dataProcessorId,
              projectId: item.projectId,
              objType: item.objType,
              title: item.title
            });
            return;
          }
      }
    },
    handleLinkPreview: function handleLinkPreview(item) {
      var _this3 = this;
      this.isLoadingPreview = true;
      var apiPromise = this.getSingleItemPromise(item);
      var responseError = 'responseError';
      if (apiPromise) {
        apiPromise.then(function (response) {
          if (_this3.isResponseError(response)) {
            throw new Error(responseError);
          }
          _this3.previewData.data = LinksTabUtils.preparePreviewData(response, _this3.workflows, _this3.currentUser, _this3.isAdmin, _this3.projects);
          _this3.isLoadingPreview = false;
        }).catch(function (error) {
          _this3.isLoadingPreview = false;
          if (error.message === responseError) {
            var _item$path;
            var displayHeadline = ((_item$path = item.path) !== null && _item$path !== void 0 ? _item$path : '') + ' ' + item.title;
            _this3.$notify({
              title: "".concat(_this3.translate.youDontHaveRightsToSee, " ").concat(displayHeadline),
              type: 'error'
            });
            return;
          }
          _this3.previewData.data = LinksTabUtils.preparePreviewData(item, _this3.workflows, _this3.currentUser, _this3.isAdmin, _this3.projects);
        });
      } else {
        this.previewData.data = LinksTabUtils.preparePreviewData(item, this.workflows, this.currentUser, this.isAdmin, this.projects);
        this.isLoadingPreview = false;
      }
    },
    setPreviewQuestionnaire: function setPreviewQuestionnaire(questions) {
      var _this4 = this;
      this.previewData.list = [];
      questions.forEach(function (question) {
        var found = null;
        if (LinksTabUtils.isIA(_this4.module)) {
          var type = ComplianceControllers[_this4.data.objType];
          found = question.informationAssets[type].find(function (item) {
            return item.id === _this4.data.id;
          });
        } else {
          found = question.processLibrary.find(function (item) {
            return item.id === _this4.data.id;
          });
        }
        if (found) {
          _this4.previewData.list.push(question);
        }
      });
      this.isLoadingPreview = false;
    }
  }
};