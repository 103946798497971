import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = ["src", "alt"];
var _hoisted_4 = {
  class: "mb-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_data_flow_datatable = _resolveComponent("data-flow-datatable");
  return _openBlock(), _createElementBlock("div", null, [$data.linkTabs.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_tab_list, {
    class: "min-w-1/2 flex mt-6 mb-6",
    tabs: $data.linkTabs,
    "background-class": "bg-zinc-50",
    onUpdate: _cache[0] || (_cache[0] = function ($event) {
      return $data.currentTab = $event;
    })
  }, null, 8 /* PROPS */, ["tabs"])]), _createElementVNode("img", {
    class: "max-h-full m-auto py-5",
    src: $data.getDataFlowImageUrl($data.dataFlowData[$data.currentTab].projectId, $data.dataFlowData[$data.currentTab].nodeId, $options.additionalUrlParameters),
    alt: $data.dataFlowData[$data.currentTab].name
  }, null, 8 /* PROPS */, _hoisted_3), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dataFlowsByStakeholder, function (stakeholder) {
    return _openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
      truncate: false,
      title: stakeholder.name,
      class: "mb-1"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_data_flow_datatable, {
      "data-flows": stakeholder.dataFlows
    }, null, 8 /* PROPS */, ["data-flows"])]);
  }), 256 /* UNKEYED_FRAGMENT */))])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: _ctx.$trans('There’s no data'),
    type: "big"
  }, null, 8 /* PROPS */, ["title"]))]);
}