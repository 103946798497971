import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex -mb-1.5px"
};
var _hoisted_3 = {
  class: "border border-gray-350 p-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tabs, function (tab, index) {
    return _openBlock(), _createBlock(_component_light_tab_item, {
      key: index,
      to: tab.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(tab.label), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view)])]);
}