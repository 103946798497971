import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_read_more = _resolveComponent("read-more");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_read_more, {
    "more-title": "Read more",
    "less-title": "Read less",
    "max-chars": 50,
    text: $data.text
  }, null, 8 /* PROPS */, ["text"]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_read_more, {
    "more-title": "Show more",
    "less-title": "Show less",
    "max-chars": 250,
    text: $data.text
  }, null, 8 /* PROPS */, ["text"]), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_read_more, {
    "more-title": "Show more",
    "less-title": "Show less",
    "max-chars": 2500,
    text: $data.text
  }, null, 8 /* PROPS */, ["text"])]);
}