// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/flags-big.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag-icon {
  width: 40px;
  height: 30px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 0;
}

.flag-icon-gb {
  background-position: -80px 0px;
}

.flag-icon-pl {
  background-position: -80px -30px;
}

.flag-icon-no {
  background-position: -80px -60px;
}

.flag-icon-it {
  background-position: -40px -60px;
}

.flag-icon-fr {
  background-position: -40px -30px;
}

.flag-icon-de {
  background-position: -40px 0px;
}

.flag-icon-se {
  background-position: 0 -90px;
}

.flag-icon-es {
  background-position: 0 -60px;
}

.flag-icon-fi {
  background-position: 0 -30px;
}

.flag-icon-dk {
  background-position: 0 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
