import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'Breadcrumbs',
  description: 'Breadcrumbs',
  token: "<breadcrumbs :items=\"[{title:'test', url='#'}]\"/>",
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    items: {
      required: true,
      type: Array,
      note: 'Array of items to be displayed'
    },
    currentId: {
      required: false,
      type: Number,
      default: 0
    },
    splitter: {
      required: false,
      type: String,
      default: '/'
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    disableHoverLogic: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentIndex: function currentIndex() {
      var _this = this;
      return this.items.findIndex(function (item) {
        return item.id === _this.currentId;
      });
    }
  }
};