import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "mb-4"
};
var _hoisted_2 = {
  class: "relative"
};
var _hoisted_3 = {
  class: "flex border-2 border-gray-350 px-3 py-2 rounded-md w-48 static-table-switcher-label"
};
var _hoisted_4 = {
  class: "pl-1 text-blue-750"
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  key: 1,
  class: "flex flex-col items-center"
};
var _hoisted_9 = {
  key: 2
};
var _hoisted_10 = {
  key: 0,
  class: "flex flex-col items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$treeData;
  var _component_notification = _resolveComponent("notification");
  var _component_control_creation_modal = _resolveComponent("control-creation-modal");
  var _component_control_filters = _resolveComponent("control-filters");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_table = _resolveComponent("risma-table");
  var _component_tree = _resolveComponent("tree");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_controls_list_preview = _resolveComponent("controls-list-preview");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [$options.notificationObj ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $options.notificationObj.type
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.notificationObj.message), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $data.isCreationModalShow ? (_openBlock(), _createBlock(_component_control_creation_modal, {
    key: 1,
    "is-mother-mode": $data.isMotherCreationMode,
    onUpdate: $options.onNewControlCreate,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return $data.isCreationModalShow = false;
    })
  }, null, 8 /* PROPS */, ["is-mother-mode", "onUpdate"])) : _createCommentVNode("v-if", true), $options.showFilters && $data.isFiltersDataLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_control_filters, {
    modelValue: $data.filters,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.filters = $event;
    }),
    data: _ctx.filtersData,
    "state-local": $data.stateLocal,
    onResetFilters: $options.resetFilters
  }, null, 8 /* PROPS */, ["modelValue", "data", "state-local", "onResetFilters"])])])) : _createCommentVNode("v-if", true), _createVNode(_component_catalogue_header, {
    "view-type": $setup.currentView,
    "view-types": $data.CONTROLS_VIEWS,
    "is-overview-options-available": false,
    class: "mb-4",
    "onUpdate:viewType": $options.setView
  }, {
    actions: _withCtx(function () {
      return [_createVNode(_component_buttons_row, {
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["view-type", "view-types", "onUpdate:viewType"]), $options.columnSwitchVisible ? (_openBlock(), _createBlock(_component_risma_column_switcher, {
    key: 3,
    columns: $data.visibleColumns,
    "always-visible-columns": ['Title'],
    "always-visible-columns-key": "label",
    class: "pb-4 inline-flex",
    onChanged: $options.changeVisibleColumn
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_3, [_createVNode(_component_feather_icon, {
        icon: "columns",
        class: "text-blue-750"
      }), _createElementVNode("span", _hoisted_4, _toDisplayString($data.translate.columnVisibility), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "onChanged"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_5, [$setup.currentView === $data.ControlViews.RISMA_TABLE ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $data.translate.controls,
    "state-key": $data.tableStateKey,
    "show-search-field": true,
    api: $options.getControlsBE
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api"]))])) : $setup.currentView === $data.ControlViews.TREE ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_$data$treeData = $data.treeData) !== null && _$data$treeData !== void 0 && _$data$treeData.children ? (_openBlock(), _createBlock(_component_tree, {
    key: 0,
    "level-select": true,
    "tree-rights": false,
    "tree-data": $data.treeData,
    "do-move": false,
    "dataflow-toolbar": false,
    "tree-id": '999',
    "ability-to-add": false,
    "has-preview": true,
    "create-article30-report-option": false,
    "disable-item-menu": true,
    "disable-progress-shown": true,
    "lock-tree": true,
    "all-levels": $data.treeAllLevels,
    "show-levels-classes": "right-80 -top-90px",
    class: "relative"
  }, null, 8 /* PROPS */, ["tree-data", "all-levels"])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($data.translate.noControlsWarning), 1 /* TEXT */)]))])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.controlsGroupArray, function (group, idx) {
    return _openBlock(), _createBlock(_component_controls_list_preview, {
      key: group.label.replace(/\s/g, '') + idx,
      activities: group.activities,
      "filtered-organisations": _ctx.organisations,
      "group-label": group.label,
      "cookie-hidden-key": group.name + idx,
      "additional-fields": group.additionalFields,
      "view-type": $setup.currentView,
      "is-mother-controls": group.isMotherControls,
      "exclude-activity-visible-props": $options.hiddenColumnKeys,
      class: "mb-4",
      onReloadRootPage: $options.loadControls,
      onUpdateFromPreviewMode: $options.updateFromPreviewMode,
      onOnCompleteControl: $options.onCompleteControl,
      onDismissModal: $options.dismissPreviewMode
    }, null, 8 /* PROPS */, ["activities", "filtered-organisations", "group-label", "cookie-hidden-key", "additional-fields", "view-type", "is-mother-controls", "exclude-activity-visible-props", "onReloadRootPage", "onUpdateFromPreviewMode", "onOnCompleteControl", "onDismissModal"]);
  }), 128 /* KEYED_FRAGMENT */)), _ctx.noControls ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($data.translate.noControlsWarning), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]))]))])])]);
}