// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle[data-v-04c65116] {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  margin-right: 10px;
  border-radius: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
