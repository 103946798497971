function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import StaticTable from '@/components/Molecules/StaticTable';
import KpiParameters from '@/components/Organisms/PerformanceReport/KPIParameters';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import InlineSelect from '@/components/Atoms/Inputs/InlineSelect';
import { KpiSortingMixin } from '@/mixins/KpiSortingMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'KPIStaticTable',
  introduction: 'A static table, for KPI data',
  description: 'This component shows KPI data with <static-table> inside.',
  components: {
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    KpiParameters: KpiParameters,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    TinyMceBox: TinyMceBox,
    InlineSelect: InlineSelect
  },
  mixins: [KpiSortingMixin],
  props: {
    kpis: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    kpisPreviewScore: {
      type: Object,
      default: function _default() {}
    },
    metadata: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['delete', 'update', 'loadScore'],
  setup: function setup() {
    return {
      selectedSortingMethod: getLocalStorageComputed('kpi_static_table_sort', 'hw')
    };
  },
  data: function data() {
    return {
      columnsMaxWidth: {
        parameters: '150px',
        weight: '130px',
        previewScore: '130px',
        enable: '100px',
        '': '100px',
        delete: '100px'
      },
      itemPropertyToShowChildren: 'parameters',
      openSections: {},
      shownScore: {},
      changeTimers: {},
      titleLabel: 'title',
      tooltipsById: [],
      translate: getTranslate['KPIStaticTable']()
    };
  },
  computed: {
    metadataHashMap: function metadataHashMap() {
      return this.metadata.reduce(function (acc, curr) {
        acc[curr.name] = curr;
        return acc;
      }, {});
    },
    sortedKpis: function sortedKpis() {
      return _toConsumableArray(this.kpis).sort(this.sortingFunctions[this.selectedSortingMethod]);
    },
    dataset: function dataset() {
      return this.prepareDataset(this.sortedKpis, this.metadataHashMap, this.openSections);
    },
    columns: function columns() {
      return ['KPI', this.translate.kpiParameters, this.translate.weight, this.translate.previewScore, this.translate.enable, ''];
    },
    columnsStyles: function columnsStyles() {
      return {
        enable: 'text-align: center;',
        parameters: 'text-align: center;',
        previewScore: 'text-align: center;'
      };
    }
  },
  methods: {
    showPreviewModal: function showPreviewModal(row) {
      if (!row.isShown) {
        this.$emit('loadScore', row.id);
      }
      this.shownScore[row.id] = !row.isShown;
    },
    prepareDataset: function prepareDataset(kpis, metadataHashMap, openSections) {
      var _this = this;
      return kpis.map(function (item, index) {
        return {
          kpi: {
            title: item.title,
            text: item.description,
            id: item.id
          },
          parameters: {
            id: item.id,
            showChildren: openSections[item.id],
            index: index
          },
          weight: {
            id: item.id,
            value: +item.weight * 100
          },
          previewScore: {
            id: item.id,
            value: _this.getKpiScoreById(_this.kpisPreviewScore, item.id),
            isShown: !!_this.shownScore[item.id]
          },
          enable: {
            id: item.id,
            value: item.enabled
          },
          delete: {
            id: item.id
          },
          children: [{
            parametersView: {
              id: item.id,
              metadata: metadataHashMap[item.kpiType],
              values: item.params
            }
          }]
        };
      });
    },
    getKpiScoreById: function getKpiScoreById(kpi, id) {
      if (kpi[id] === undefined) return '';
      return kpi[id] ? "".concat(kpi[id].kpiValue.toFixed(2), "%") : this.translate.kpiDisabled;
    },
    toggleChildren: function toggleChildren(row) {
      this.openSections[this.dataset[row].parameters.id] = !this.openSections[this.dataset[row].parameters.id];
      this.openSections = _objectSpread({}, this.openSections);
    },
    handleDescriptionChange: function handleDescriptionChange(slotData, property, data) {
      this.handleUpdateKpi(slotData.id, _defineProperty({}, property, data));
    },
    handleChangeData: function handleChangeData(kpiId, propertyName, value) {
      var _this2 = this;
      clearTimeout(this.changeTimers[kpiId]);
      this.changeTimers[kpiId] = setTimeout(function () {
        if (propertyName === 'weight') {
          value = (value / 100).toFixed(2);
        }
        _this2.handleUpdateKpi(kpiId, _defineProperty({}, propertyName, value));
      }, this.$debounceTimeout / 3);
    },
    handleUpdateKpi: function handleUpdateKpi(kpiId, value) {
      this.$emit('update', kpiId, value);
    },
    handleUpdateKpiParameters: function handleUpdateKpiParameters(kpiId, value) {
      this.$emit('update', kpiId, {
        params: value
      });
    },
    weightNormalizeFn: function weightNormalizeFn(value) {
      if (+value <= 0) return 0;
      if (+value >= 1000) return 1000;
      return value;
    },
    handleChangeWeight: function handleChangeWeight(event, data) {
      if (data.value !== event) {
        return this.handleChangeData(data.id, 'weight', event);
      }
    }
  }
};