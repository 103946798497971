import { Radar } from 'vue-chartjs';
import { convertHexToRgba } from '@/utils/colors';
import { insertEmptyInArray } from '@/utils/insertEmptyInArray';
import { splitLabelToRows } from '@/components/Molecules/Charts/utils';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
export default {
  name: 'RadarChart',
  components: {
    Radar: Radar,
    ExportFrame: ExportFrame
  },
  introduction: 'Radar chart based on vue-chart3',
  description: 'Radar chart. Useful for visualising data spreading',
  token: " <radar-chart\n                :chart-data=\"Array of dataset objects\"\n                :colors=\"Array of dataset colors\"\n                :labels=\"Array of radar point labels\"\n                :legend-shown=\"if legend shown\"\n                :empty-points=\"points that should be displayed without label and data\"/>\n    ",
  props: {
    data: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: false,
      default: function _default() {
        return ['#ff6384'];
      }
    },
    labels: {
      type: Array,
      required: true
    },
    emptyPoints: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Numbers of points will be skipped'
    },
    range: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'The first number is center. The second number is outer border'
    },
    hoverTooltipFn: {
      type: Function,
      required: false,
      default: undefined,
      note: 'Function which modifies tooltip label on hover'
    },
    useDefaultExport: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    var _this = this;
    return {
      options: {
        scales: {
          r: {
            min: function min() {
              return _this.range[0];
            },
            max: function max() {
              return _this.range[1];
            },
            grid: {
              circular: true,
              color: '#ddd',
              lineWidth: function lineWidth(val) {
                return val.index === 3 ? 3 : 1;
              }
            },
            ticks: {
              display: false,
              count: 5
            },
            pointLabels: {
              callback: function callback(lab) {
                var charsToShow = 100;
                var charsToWrap = 20;
                return splitLabelToRows(lab, charsToShow, charsToWrap);
              }
            }
          }
        },
        plugins: {
          legend: {
            display: function display() {
              return _this.legendShown;
            }
          },
          tooltip: {
            callbacks: {
              title: function title() {
                return '';
              },
              label: this.hoverTooltipFn
            }
          }
        },
        devicePixelRatio: 1,
        maintainAspectRatio: true
      }
    };
  },
  computed: {
    chartData: function chartData() {
      var _this2 = this;
      var datasets = this.data.map(function (item, i) {
        var colorIndex = Math.min(i, _this2.colors.length - 1);
        return {
          label: item.label,
          data: insertEmptyInArray(item.data, _this2.emptyPoints),
          spanGaps: true,
          borderColor: _this2.colors[colorIndex],
          borderWidth: 1,
          backgroundColor: convertHexToRgba(_this2.colors[colorIndex], 0.2)
        };
      });
      return {
        labels: insertEmptyInArray(this.labels, this.emptyPoints),
        datasets: datasets
      };
    },
    legendShown: function legendShown() {
      return !this.$slots.legend;
    }
  }
};