import { getComplianceMy } from '@/api/compliance/tree';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { getWatermarkImageByType } from '@/constants/mypage/MypageSettings';
export default {
  name: 'MyComplianceNodes',
  deacription: 'The component is used in the compliance overview page (compliance/overview/{projectId})',
  components: {
    PreviewListTile: PreviewListTile,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      loaded: false,
      nodes: [],
      watermarkImage: getWatermarkImageByType('compliance'),
      title: this.$trans('My nodes')
    };
  },
  mounted: function mounted() {
    var _this = this;
    getComplianceMy().then(function (_ref) {
      var list = _ref.list;
      _this.nodes = list.filter(function (item) {
        return +item.project.id === +_this.projectId;
      });
    }).finally(function () {
      return _this.loaded = true;
    });
  },
  methods: {
    updateFromPreviewMode: function updateFromPreviewMode(node) {
      var nodeIndex = this.nodes.findIndex(function (node) {
        return node.id === node.id;
      });
      this.nodes[nodeIndex] = node;
    }
  }
};