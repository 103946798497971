import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "overlying-initiatives"
};
var _hoisted_2 = {
  key: 1,
  class: "underlying-initiatives"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_breadcrumbs = _resolveComponent("breadcrumbs");
  return _openBlock(), _createElementBlock("div", null, [$options.reversedVisibleBreadcrumb.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_breadcrumbs, {
    class: "mb-2",
    items: $options.reversedVisibleBreadcrumb,
    label: "".concat($data.translate.overlying, " ").concat($data.translate.actions)
  }, null, 8 /* PROPS */, ["items", "label"])])) : _createCommentVNode("v-if", true), $options.initiativeChildrenLength ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_breadcrumbs, {
    class: "mb-2",
    items: $options.underlyingBreadcrumb,
    splitter: "|",
    label: "".concat($data.translate.underlying, " ").concat($data.translate.actions)
  }, null, 8 /* PROPS */, ["items", "label"])])) : _createCommentVNode("v-if", true)]);
}