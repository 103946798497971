import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "text-blue-750 font-semibold flex justify-between"
};
var _hoisted_2 = {
  class: "mr-4"
};
var _hoisted_3 = {
  class: "whitespace-nowrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  return _openBlock(), _createBlock(_component_risma_column_switcher, {
    columns: $props.columns,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changed', $event);
    })
  }, {
    option: _withCtx(function (_ref) {
      var option = _ref.option;
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(option.label), 1 /* TEXT */), _createElementVNode("span", _hoisted_3, [_createTextVNode(_toDisplayString($data.translate.off) + " ", 1 /* TEXT */), _createVNode(_component_risma_toggle, {
        "model-value": option.visible
      }, null, 8 /* PROPS */, ["model-value"]), _createTextVNode(" " + _toDisplayString($data.translate.on), 1 /* TEXT */)])])];
    }),
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, function () {
        return [_createVNode(_component_feather_icon, {
          icon: "more-horizontal",
          class: "text-blue-750 stroke-3 cursor-pointer"
        })];
      }, true)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["columns"]);
}