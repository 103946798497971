import { defineStore } from 'pinia';
import { getComplianceProjects } from '@/api/risma/data';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
export var useComplianceProjectsStore = defineStore('complianceProjectsStore', {
  state: function state() {
    return {
      projects: []
    };
  },
  actions: {
    load: function load() {
      var _this = this;
      return getComplianceProjects().then(function (_ref) {
        var list = _ref.list;
        _this.projects = list;
      });
    },
    getProjectById: function getProjectById(id) {
      return this.projects.find(function (project) {
        return +project.id === +id;
      });
    },
    getCsrdProject: function getCsrdProject() {
      return this.projects.find(function (project) {
        return +project.type === +ProjectTypes.CSRD;
      });
    }
  }
});