function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getReportsVulnerabilities } from '@/api/risk/risk';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RiskReportsMixin } from '@/mixins/RiskReportsMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'RiskReportsVulnerabilities',
  description: 'Vulnerabilities tab for risk2 projects',
  token: '<risk-reports-vulnerability />',
  components: {
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    SaveReport: SaveReport
  },
  mixins: [RiskReportsMixin, RismaDatatableDefaultMixin, SaveReportMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      vulnerabilities: [],
      datasetBig: [],
      translate: getTranslate['RiskReportsVulnerabilities'](),
      reportType: ReportTypes.RISK_VULNERABILITIES,
      stateLocal: "".concat(ReportTypes.RISK_VULNERABILITIES, "-").concat(this.projectId),
      activitySlotNames: ['risks', 'initiatives', 'controls', 'systems']
    };
  },
  computed: {
    columnsBig: function columnsBig() {
      return _objectSpread(_objectSpread({
        vulnerability: this.translate.vulnerability,
        risks: this.translate.risks
      }, this.systemColumn), {}, {
        initiatives: this.translate.initiatives,
        controls: this.translate.controls
      });
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.handleGetVulnerabilities(this.projectId).then(function (response) {
      _this.vulnerabilities = response.list;
      _this.risks = response.risks || [];
      return _this.getLinkedAssetsAndActivities();
    }).then(function (response) {
      _this.handleGetLinkedAssetsAndActivities(response);
      _this.datasetBig = _this.formatDatasetBig(_this.vulnerabilities, _this.risks, _this.controls, _this.initiatives, _this.systems);
      _this.enhanceDataset(_this.datasetBig, _this.columnsBig);
      _this.isLoaded = true;
      _this.loadSavedReport();
    }).catch(function (error) {
      throw new Error(error);
    });
  },
  methods: {
    handleGetVulnerabilities: function handleGetVulnerabilities(projectId) {
      return new Promise(function (resolve, reject) {
        getReportsVulnerabilities(projectId).then(function (responce) {
          return resolve(responce);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    formatDatasetBig: function formatDatasetBig(vulnerabilities, risksAll, controlsAll, initiativesAll, systemsAll) {
      var _this2 = this;
      return vulnerabilities.map(function (vulnerability) {
        var risks = risksAll.filter(function (risk) {
          return risk.vulnerabilityIds.includes(vulnerability.id);
        });
        var risksFormatted = _this2.getFormattedRisks(risks);
        var controlsFormatted = _this2.getFormattedControls(risks, controlsAll);
        var initiativesFormatted = _this2.getFormattedInitiatives(risks, initiativesAll);
        return _objectSpread(_objectSpread({
          vulnerability: {
            field: vulnerability.title
          },
          risks: risksFormatted
        }, _this2.getSystemsCell(risks, systemsAll)), {}, {
          initiatives: initiativesFormatted,
          controls: controlsFormatted
        });
      });
    }
  }
};