export var importDatepickerLocale = function importDatepickerLocale(language) {
  switch (language) {
    case 'en':
      return import('vue-datepicker-next/locale/en');
    case 'da':
      return import('vue-datepicker-next/locale/da');
    case 'de':
      return import('vue-datepicker-next/locale/de');
    case 'es':
      return import('vue-datepicker-next/locale/es');
    case 'fi':
      return import('vue-datepicker-next/locale/fi');
    case 'fr':
      return import('vue-datepicker-next/locale/fr');
    case 'it':
      return import('vue-datepicker-next/locale/it');
    case 'nb':
      return import('vue-datepicker-next/locale/nb');
    case 'pl':
      return import('vue-datepicker-next/locale/pl');
    case 'sv':
      return import('vue-datepicker-next/locale/sv');
  }
};