import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center"
};
var _hoisted_2 = {
  class: "flex justify-between items-center mb-3"
};
var _hoisted_3 = {
  class: "ml-12"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_download_link = _resolveComponent("download-link");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.report.id ? $data.translate.editManagementReport : $data.translate.createManagementReport,
    "button-ok-text": $data.report.id ? $data.translate.save : $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return $data.showModal = false;
    }),
    onAccept: $options.onModalAccept
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.report.reporttitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.report.reporttitle = $event;
        }),
        modelModifiers: {
          trim: true
        },
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true,
        invalid: $data.showEmptyTitleError && !$data.report.reporttitle,
        "error-help-text": $data.showEmptyTitleError && !$data.report.reporttitle ? $data.translate.pleaseFillOutThisField : '',
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text"]), _createVNode(_component_risma_select, {
        title: $data.translate.nodes,
        options: $data.nodes,
        "selected-options": $data.report.selectedNodes,
        placeholder: $data.translate.nodes,
        "label-key": "title",
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $data.report.selectedNodes = $event;
        })
      }, {
        newOption: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_toggle, {
            "model-value": $data.includeChildNodesToggle,
            class: "mr-1",
            onInput: _cache[1] || (_cache[1] = function ($event) {
              return $data.includeChildNodesToggle = $event;
            })
          }, null, 8 /* PROPS */, ["model-value"]), _createElementVNode("span", null, _toDisplayString($data.translate.includeUnderlyingNodes), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.managementReport
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "plus",
    class: "text-blue-750 stroke-3 cursor-pointer",
    width: "40",
    height: "40",
    onClick: $options.openModal
  }, null, 8 /* PROPS */, ["onClick"])]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createBlock(_component_static_table, {
    key: 2,
    "dataset-table": $options.dataset,
    columns: $options.columns
  }, {
    downloadLatest: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_download_link, {
        "file-src": slotData,
        "file-name": "ManagementReport"
      }, null, 8 /* PROPS */, ["file-src"])])];
    }),
    edit: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [!slotData.closedAt ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        class: "ml-2 cursor-pointer text-blue-750 inline-block",
        icon: "edit",
        onClick: function onClick($event) {
          return $options.openModal(slotData);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock("div", _hoisted_4))];
    }),
    delete: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [!slotData.closedAt ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        class: "ml-2 cursor-pointer text-red-50 inline-block",
        icon: "trash-2",
        onClick: function onClick($event) {
          return $options.confirmDelete(slotData.id);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock("div", _hoisted_5))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"]))]);
}