import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import _imports_0 from '@/assets/svg/ai_clear_history.svg';
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 0,
  class: "min-w-[185px] absolute bg-rm-white rounded border border-rm-grey z-20 right-0 top-[170%] py-2"
};
var _hoisted_4 = {
  class: "region-name text-rm-text glob-l2 whitespace-nowrap"
};
var _hoisted_5 = {
  class: "pl-10 w-full"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = ["title"];
var _hoisted_8 = {
  class: "text-rm-text glob-l2 whitespace-nowrap"
};
var _hoisted_9 = ["title"];
var _hoisted_10 = {
  class: "text-rm-text glob-l2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
    class: "flex p-px pt-2.5 cursor-pointer rounded items-center overflow-hidden w-6 h-6 hover:bg-opacity-20 hover:bg-rm-white",
    tabindex: "2",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleMenu && $options.toggleMenu.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    "fill-class": "fill-rm-white",
    class: "ml-px text-rm-white stroke-2",
    icon: "more-horizontal"
  })])]), $data.isMenuVisible ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_createVNode(_component_collapsible_panel, null, {
    header: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "globe",
        width: "16",
        height: "16",
        class: "mr-1 stroke-2 text-rm-text inline-block"
      }), _createElementVNode("p", _hoisted_4, _toDisplayString($data.translate.changeRegion) + " (" + _toDisplayString($props.region) + ") ", 1 /* TEXT */)];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.regionsOptions, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.value,
          class: _normalizeClass(["glob-l2 py-1 flex items-center justify-between pl-4 hover:bg-rm-active-blue--6 rounded-l min-h-6", item.value === $props.region ? $data.activeRegionCSS : 'cursor-pointer']),
          onClick: _withModifiers(function ($event) {
            return $options.changeRegion(item.value);
          }, ["stop"])
        }, [_createElementVNode("div", null, _toDisplayString(item.name), 1 /* TEXT */), item.value === $props.region ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: "check",
          width: "16",
          height: "16",
          class: "stroke-2 text-rm-active-blue mr-4"
        })) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_6);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("li", {
    class: "flex flex-nowrap cursor-pointer items-center whitespace-nowrap relative py-2 px-4 hover:bg-rm-active-blue--6",
    title: $data.translate.clearHistory,
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.emitEvent('clear:history');
    })
  }, [_cache[3] || (_cache[3] = _createElementVNode("img", {
    src: _imports_0,
    width: "16",
    height: "16",
    class: "mr-1"
  }, null, -1 /* HOISTED */)), _createElementVNode("p", _hoisted_8, _toDisplayString($data.translate.clearHistory), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_7), _createElementVNode("li", {
    class: "flex flex-nowrap cursor-pointer items-center whitespace-nowrap relative py-2 px-4 hover:bg-rm-active-blue--6",
    title: $data.translate.endHistory,
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.emitEvent('end:history');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    width: "16",
    height: "16",
    class: "mr-1 stroke-2 text-rm-text inline-block"
  }), _createElementVNode("p", _hoisted_10, _toDisplayString($data.translate.endHistory), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_9)])])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeMenu]]);
}