import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_wizard_flow = _resolveComponent("wizard-flow");
  return _openBlock(), _createElementBlock("div", null, [$options.canBeOpened ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    title: $data.translate.createDataFlow,
    class: "cursor-pointer absolute right-0.8rem"
  }, [_createVNode(_component_feather_icon, {
    icon: "git-pull-request",
    width: "30",
    height: "30",
    class: "p-1 rounded-full border border-gray-35 transform scale-x-100 -scale-y-100 rotate-90",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isOpened = true;
    })
  })], 8 /* PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true), $data.isOpened ? (_openBlock(), _createBlock(_component_wizard_flow, {
    key: 1,
    items: $options.items,
    data: $data.data,
    "has-welcome-message": $setup.welcomePageState !== 'true',
    "selected-options": $data.selectedOptions,
    "load-state": $data.loadState,
    "parent-error-message": $data.errorMessage,
    "modal-title": $data.translate.createDataFlow,
    onAccept: $options.handleAccept,
    onDismiss: $options.handleDismiss,
    "onUpdate:selected": $options.updateSelected,
    "onUpdate:error": _cache[1] || (_cache[1] = function ($event) {
      return $data.errorMessage = '';
    })
  }, null, 8 /* PROPS */, ["items", "data", "has-welcome-message", "selected-options", "load-state", "parent-error-message", "modal-title", "onAccept", "onDismiss", "onUpdate:selected"])) : _createCommentVNode("v-if", true)]);
}