import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "tooltip-wrapper inline-block group relative"
};
var _hoisted_2 = {
  class: "tooltip__inner p-4 rounded-md shadow-tooltip"
};
var _hoisted_3 = {
  class: "flex justify-between items-center mb-2"
};
var _hoisted_4 = {
  key: 0,
  class: "glob-h3 flex-1"
};
var _hoisted_5 = {
  class: "glob-l1 max-w-max"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "tooltipTrigger", {}, undefined, true), $data.mode ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle($props.tooltipStyles),
    class: _normalizeClass("absolute tooltip__text ".concat($options.primaryMode ? 'visible opacity-100' : 'invisible opacity-0', " group-hover:visible group-hover:opacity-100 transition-opacity tooltip--").concat($props.position, " tooltip--").concat($data.mode, " z-10"))
  }, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "tooltip__arrow"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.primaryMode ? (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: "focus:outline-none hover:bg-trans-15 rounded-sm ml-auto",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.dismiss && $options.dismiss.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    width: "16",
    height: "16",
    class: "stroke-1"
  })])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "tooltipBody", {}, undefined, true)])])], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)]);
}