function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RiskAssessmentRow from '@/components/Molecules/Risk/RiskAssessmentRow';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import { getRiskAssessmentGuide } from '@/api/risma/data';
import { RiskBaseModelProps, scoresNameByField } from '@/constants/risks/RiskBaseModelProps';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { escapeHtml } from '@/utils';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'RiskAssessmentsIsms',
  components: {
    RismaTitle: RismaTitle,
    RiskAssessmentRow: RiskAssessmentRow,
    Notification: Notification,
    FeatherIcon: FeatherIcon,
    Modal: Modal
  },
  props: {
    activity: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current risk with row scoring data'
    },
    objType: {
      type: String,
      require: true,
      default: ''
    },
    currentRiskProject: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Current risk project'
    },
    period: {
      type: Object,
      required: false,
      default: function _default() {},
      note: ''
    },
    threats: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    customThreats: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    customThreatsEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    },
    inherentRisksEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    threatUpdateInProgress: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['changed', 'updateCustomThreat', 'updateUnderlyingRisk'],
  data: function data() {
    return {
      rowsData: this.setRowsData(this.period, this.activity),
      showModal: false,
      guideData: {
        name: '',
        contentHtml: ''
      },
      translate: getTranslate['RiskAssessmentsIsms']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasThreats: function hasThreats() {
      return this.currentRiskProject.threatsEnabled;
    },
    hasAccumulatedData: function hasAccumulatedData() {
      var _this$activity, _this$activity2;
      return ((_this$activity = this.activity) === null || _this$activity === void 0 || (_this$activity = _this$activity.children) === null || _this$activity === void 0 ? void 0 : _this$activity.length) && ((_this$activity2 = this.activity) === null || _this$activity2 === void 0 || (_this$activity2 = _this$activity2.project) === null || _this$activity2 === void 0 ? void 0 : _this$activity2.accumulatedRisks);
    },
    panelsStartExpanded: function panelsStartExpanded() {
      return !!this.getSettingValue('feature.risk_assessment_start_expanded');
    },
    riskAssessmentGroups: function riskAssessmentGroups() {
      var _this$period,
        _this = this;
      var groupsByRowIds = {};
      (((_this$period = this.period) === null || _this$period === void 0 ? void 0 : _this$period.consequenceRows) || []).map(function (row) {
        if (!groupsByRowIds[row.id]) groupsByRowIds[row.id] = [];
        if (_this.hasAccumulatedData) {
          groupsByRowIds[row.id].push({
            title: "".concat(_this.translate.underlyingRisksFor, " ").concat(row.label),
            tableHeadTitle: _this.translate.riskTitle,
            items: _this.underlyingRiskRows[row.id] || []
          });
        }
        if (_this.hasThreats) {
          groupsByRowIds[row.id].push({
            title: "".concat(_this.translate.relevantThreatsFor, " ").concat(row.label),
            tableHeadTitle: _this.translate.threat,
            items: _this.threatRows[row.id] || [],
            isCurrentRisk: true,
            isThreat: true
          });
        }
      });
      return groupsByRowIds;
    },
    threatRows: function threatRows() {
      var _this2 = this;
      var result = {};
      this.threats.forEach(function (threat) {
        var _this2$period;
        (((_this2$period = _this2.period) === null || _this2$period === void 0 ? void 0 : _this2$period.consequenceRows) || []).forEach(function (row) {
          var threatAssessment = threat.assessments.find(function (assessment) {
            return assessment.label === row.label;
          });
          if (!threatAssessment) return;
          var customThreat = _this2.customThreats.find(function (ct) {
            return +ct.threatId === +threat.id && +ct.riskAssessmentRowId === +row.id;
          });
          var resultObj = {
            label: threat.title,
            consequence: customThreat ? customThreat.impact : threatAssessment.impact,
            probability: customThreat ? customThreat.likelihood : threatAssessment.likelihood,
            id: threat.id,
            relevant: customThreat ? !!customThreat.relevant : true,
            customThreatId: customThreat ? customThreat.id : null
          };
          result[row.id] ? result[row.id].push(resultObj) : result[row.id] = [resultObj];
        });
      });
      return result;
    },
    underlyingRiskRows: function underlyingRiskRows() {
      var _this$activity3,
        _this3 = this;
      var result = {};
      (_this$activity3 = this.activity) === null || _this$activity3 === void 0 || _this$activity3.children.forEach(function (childRisk) {
        var _this3$period;
        (((_this3$period = _this3.period) === null || _this3$period === void 0 ? void 0 : _this3$period.consequenceRows) || []).forEach(function (row) {
          var residualConsequence = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES);
          var residualProbability = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES);
          var inherentConsequence = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES);
          var inherentProbability = _this3.getUnderlyingRiskRowValue(childRisk, row.id, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES);
          var resultObj = _objectSpread(_objectSpread({}, childRisk), {}, {
            residualConsequence: residualConsequence,
            residualProbability: residualProbability,
            inherentConsequence: inherentConsequence,
            inherentProbability: inherentProbability
          });
          result[row.id] ? result[row.id].push(resultObj) : result[row.id] = [resultObj];
        });
      });
      return result;
    },
    riskAssessmentsRows: function riskAssessmentsRows() {
      var _this$period2,
        _this4 = this;
      return (((_this$period2 = this.period) === null || _this$period2 === void 0 ? void 0 : _this$period2.consequenceRows) || []).map(function (item) {
        var probability = _this4.period.probabilityRows.find(function (probability) {
          return probability.id === item.id;
        });
        return {
          id: item.id,
          label: item.label,
          consequences: item.impactColumns.map(_this4.createItemOption),
          consequenceOrigin: item.impactOrigin,
          consequenceLabel: getConsequenceLabel(_this4.period),
          probabilityLabel: getProbabilityLabel(_this4.period),
          probabilities: probability.likelihoodColumns.map(_this4.createItemOption),
          probabilityOrigin: probability.likelihoodOrigin
        };
      });
    },
    relationNotificationTitle: function relationNotificationTitle() {
      var activityTitleMap = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.COMPLIANCE_NODE, 'Compliance node'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'System'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Controller'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Processor'), ObjectTypes.INFORMATION_ASSET, 'Custom Asset');
      return "".concat(this.translate.thisIsTheRiskAssessmentFor, " ").concat(activityTitleMap[this.activity.riskAssessmentActivity.objType], ":\n                 ").concat(this.activity.riskAssessmentActivity.title);
    },
    hasGuide: function hasGuide() {
      return this.guideData.active && escapeHtml(this.guideData.contentHtml).length;
    }
  }),
  mounted: function mounted() {
    var _this5 = this;
    getRiskAssessmentGuide(this.currentRiskProject.id).then(function (data) {
      return _this5.guideData = data;
    });
  },
  methods: {
    updateCustomThreat: function updateCustomThreat(data, rowId, updateList) {
      this.$emit('updateCustomThreat', data, rowId, updateList);
    },
    onValueChange: function onValueChange(event, type) {
      this.updateRowDataById(event.id, _defineProperty(_defineProperty({}, type, event.value), "".concat(type, "Comment"), event.comment));
      var result = this.prepareValueToSave(event, type);
      this.$emit('changed', _defineProperty({}, scoresNameByField[type], result));
    },
    prepareValueToSave: function prepareValueToSave(newValue, type) {
      var _this6 = this;
      return this.riskAssessmentsRows.map(function (item) {
        if (item.id === newValue.id) {
          delete newValue.id;
          newValue.riskAssessmentsRowId = item.id;
          return newValue;
        }
        var result = {
          riskAssessmentsRowId: item.id,
          label: item.label,
          value: _this6.rowsData[item.id][type],
          comment: _this6.rowsData[item.id]["".concat(type, "Comment")]
        };
        return result;
      });
    },
    updateRowDataById: function updateRowDataById(id, value) {
      this.rowsData[id] = _objectSpread(_objectSpread({}, this.rowsData[id]), value);
    },
    createItemOption: function createItemOption(item) {
      return {
        label: "".concat(item.value, ". ").concat(item.label),
        value: item.value,
        title: item.label
      };
    },
    setRowsData: function setRowsData(period, activity) {
      var _this7 = this;
      var result = {};
      period === null || period === void 0 || period.consequenceRows.forEach(function (item) {
        result[item.id] = _this7.getRowData(activity, item.id);
      });
      return result;
    },
    getRowData: function getRowData(activity, itemId) {
      var _this$getValueX = this.getValueX(activity[RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES], itemId, 'impactOriginFromRow'),
        inherentConsequence = _this$getValueX.value,
        inherentConsequenceOrigin = _this$getValueX.origin,
        inherentConsequenceComment = _this$getValueX.comment;
      var _this$getValueX2 = this.getValueX(activity[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES], itemId, 'impactOriginFromRow'),
        residualConsequence = _this$getValueX2.value,
        residualConsequenceOrigin = _this$getValueX2.origin,
        residualConsequenceComment = _this$getValueX2.comment;
      var _this$getValueX3 = this.getValueX(activity[RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES], itemId, 'likelihoodOriginFromRow'),
        inherentProbability = _this$getValueX3.value,
        inherentProbabilityOrigin = _this$getValueX3.origin,
        inherentProbabilityComment = _this$getValueX3.comment;
      var _this$getValueX4 = this.getValueX(activity[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES], itemId, 'likelihoodOriginFromRow'),
        residualProbability = _this$getValueX4.value,
        residualProbabilityOrigin = _this$getValueX4.origin,
        residualProbabilityComment = _this$getValueX4.comment;
      return {
        inherentConsequence: inherentConsequence,
        inherentConsequenceOrigin: inherentConsequenceOrigin,
        inherentProbability: inherentProbability,
        inherentProbabilityOrigin: inherentProbabilityOrigin,
        residualConsequence: residualConsequence,
        residualConsequenceOrigin: residualConsequenceOrigin,
        residualProbability: residualProbability,
        residualProbabilityOrigin: residualProbabilityOrigin,
        inherentConsequenceComment: inherentConsequenceComment,
        residualConsequenceComment: residualConsequenceComment,
        inherentProbabilityComment: inherentProbabilityComment,
        residualProbabilityComment: residualProbabilityComment
      };
    },
    getValueX: function getValueX(scores, itemId, name) {
      var _row$column$value, _row$column, _row$comment;
      if (!scores) return 0;
      var row = scores.find(function (score) {
        return score.rowId === itemId;
      });
      return {
        value: (_row$column$value = row === null || row === void 0 || (_row$column = row.column) === null || _row$column === void 0 ? void 0 : _row$column.value) !== null && _row$column$value !== void 0 ? _row$column$value : 0,
        origin: row ? row[name] : 0,
        comment: (_row$comment = row === null || row === void 0 ? void 0 : row.comment) !== null && _row$comment !== void 0 ? _row$comment : ''
      };
    },
    getUnderlyingRiskRowValue: function getUnderlyingRiskRowValue(childRisk, rowId, scoreType) {
      var _childRisk$scoreType, _score$column;
      if (!((_childRisk$scoreType = childRisk[scoreType]) !== null && _childRisk$scoreType !== void 0 && _childRisk$scoreType.length)) return null;
      var score = childRisk[scoreType].find(function (score) {
        return score.rowId === rowId;
      });
      return (score === null || score === void 0 || (_score$column = score.column) === null || _score$column === void 0 ? void 0 : _score$column.value) || null;
    }
  }
};