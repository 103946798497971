function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import { getControlsDashboardEfficiencyData, getSliceControls as _getSliceControls } from '@/api/controls/controls';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import ControlsDashboardFilters from '@/components/Pages/Controls/ControlsDashboardFilters';
import { StatusOverviewMixin } from '@/components/Pages/Controls/StatusOverviewMixin';
import { toLocalDateTimeFull } from '@/utils/date';
import { findPropsByIds } from '@/utils/Utils';
export default {
  name: 'ControlsEfficiencyDashboard',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    PaginatedTable: PaginatedTable,
    RismaTitleWithIcon: RismaTitleWithIcon,
    RismaDoughnutChart: RismaDoughnutChart,
    ControlsDashboardFilters: ControlsDashboardFilters
  },
  mixins: [StatusOverviewMixin],
  data: function data() {
    return {
      isLoaded: false,
      pieChartsStaticTable: {},
      multipleRangesData: [],
      datasets: {},
      tablesCache: {},
      translate: getTranslate['ControlsEfficiencyDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])),
  methods: {
    applyFilters: function applyFilters(searchData) {
      var _this = this;
      this.isLoaded = false;
      this.multipleRangesData = [];
      var multipleDateRanges = searchData.multipleDateRanges;
      delete searchData.multipleDateRanges;
      var promises = [];
      multipleDateRanges.forEach(function (range, index) {
        return promises.push(_this.getData(_objectSpread(_objectSpread({}, range), searchData), index));
      });
      return Promise.all(promises).then(function () {
        return _this.isLoaded = true;
      });
    },
    getData: function getData(searchData, index) {
      var _this2 = this;
      return getControlsDashboardEfficiencyData(searchData).then(function (_ref) {
        var charts = _ref.charts;
        _this2.multipleRangesData[index] = {
          title: "".concat(searchData.deadlineAfter || searchData.completedAfter, " - ").concat(searchData.deadlineBefore || searchData.completedBefore),
          charts: charts.map(function (chart) {
            return {
              title: chart.title,
              labels: chart.data.map(function (item) {
                return item.title;
              }),
              controlsUrl: chart.data.map(function (item) {
                return item.controlsUrl;
              }),
              datasets: [{
                data: chart.data.map(function (item) {
                  return item.value;
                }),
                backgroundColor: chart.data.map(function (_ref2) {
                  var color = _ref2.color;
                  return color;
                })
              }]
            };
          })
        };
        _this2.tablesCache = {};
      });
    },
    clickSlice: function clickSlice(rangeTitle, chart, sliceNumber) {
      var _this$pieChartsStatic,
        _this3 = this;
      var title = chart.labels[sliceNumber];
      var key = "".concat(rangeTitle, "-").concat(chart.title);
      if (!title || ((_this$pieChartsStatic = this.pieChartsStaticTable[key]) === null || _this$pieChartsStatic === void 0 ? void 0 : _this$pieChartsStatic.title) === title) {
        this.pieChartsStaticTable = {};
        return;
      }
      var cacheKey = "".concat(key, "-").concat(sliceNumber);
      var cachedTable = this.tablesCache[cacheKey];
      if (cachedTable) {
        this.setPieTable(key, title, cachedTable);
        return;
      }
      return this.getSliceControls(chart.controlsUrl[sliceNumber]).then(function (controls) {
        var table = _this3.prepareTableInfoForPie(controls);
        _this3.setPieTable(key, title, table);
        _this3.tablesCache[cacheKey] = table;
      });
    },
    setPieTable: function setPieTable(key, title, table) {
      this.pieChartsStaticTable = _defineProperty({}, key, {
        title: title,
        table: table
      });
    },
    getSliceControls: function getSliceControls(url) {
      var _this4 = this;
      return _getSliceControls(url).then(function (_ref3) {
        var list = _ref3.list;
        return list.map(function (control) {
          var _control$userIds;
          return _objectSpread(_objectSpread({}, control), {}, {
            text: "".concat(control.cpath, " ").concat(control.title, " (").concat(toLocalDateTimeFull(control.deadline), ")<br>").concat(_this4.translate.processes, ": ").concat(_this4.getProcessesNames(control.processes)),
            owners: findPropsByIds((_control$userIds = control.userIds) === null || _control$userIds === void 0 ? void 0 : _control$userIds.responsible, _this4.users, 'display_name'),
            organisations: findPropsByIds(control.organisationIds, _this4.organisations, 'visible_name')
          });
        });
      });
    },
    getProcessesNames: function getProcessesNames(processes) {
      if (!processes.length) return "<".concat(this.translate.none, ">");
      return processes.map(function (process) {
        return process.title;
      }).join(', ');
    },
    getPieChartTableSize: function getPieChartTableSize(datasetLength) {
      return datasetLength < 20 ? datasetLength : 20;
    }
  }
};