// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-menu[data-v-0705ea01] {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.toggle-menu[data-v-0705ea01]  .toggle-menu-modal {
  width: 400px;
}
.main-well-header[data-v-0705ea01] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-well-title[data-v-0705ea01] {
  display: flex;
  align-items: center;
}
.main-well-title-traffic[data-v-0705ea01] {
  margin-right: 10px;
}
.main-well-title-ipath[data-v-0705ea01] {
  margin-right: 25px;
  line-height: 45px;
  vertical-align: middle;
  font-size: 27px;
  font-weight: 300;
  color: #347480;
}
.main-well-title-name[data-v-0705ea01] {
  max-width: 550px;
}
.dates-title[data-v-0705ea01] {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #4D4D4D;
}
.numerical-section[data-v-0705ea01] {
  /*hidden till it's done*/
  display: none;
}
.numerical-header-wrap[data-v-0705ea01] {
  display: flex;
  width: 600px;
  margin-bottom: 8px;
}
.inherit-switch-wrap[data-v-0705ea01] {
  display: flex;
  align-items: center;
}
.inherit-switch-wrap .risma-switch-inherit[data-v-0705ea01] {
  margin-right: 5px;
}
.complete-percentage-wrap[data-v-0705ea01] {
  display: flex;
  align-items: center;
}
.complete-percentage-wrap .complete-percentage-sign[data-v-0705ea01] {
  position: relative;
  right: 45px;
  font-size: 15px;
}
.add-numerical-unit[data-v-0705ea01] {
  position: relative;
  margin-left: auto;
}
.add-numerical-unit-btn[data-v-0705ea01] {
  height: 24px;
}
.add-numerical-unit-btn i[data-v-0705ea01] {
  color: #4CB3C0;
}
.add-numerical-unit-pop-up[data-v-0705ea01] {
  position: absolute;
  top: -10px;
  left: -165px;
  z-index: 1;
  padding: 5px 10px;
  border: 2px solid #CCCCCC;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 7px 2px;
  background-color: #FFFFFF;
}
.add-numerical-unit-title[data-v-0705ea01] {
  display: flex;
  justify-content: center;
}
.add-numerical-unit-list[data-v-0705ea01] {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #347480;
}
.add-numerical-unit-list a[data-v-0705ea01]:hover {
  text-decoration: underline;
  cursor: pointer;
}
.numerical-items ul[data-v-0705ea01] {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.numerical-units-line[data-v-0705ea01] {
  display: flex;
}
.numerical-units-line .numerical-item-title-wrap[data-v-0705ea01] {
  display: flex;
  justify-content: flex-end;
  width: 220px;
  margin-right: 30px;
}
.numerical-units-line .numerical-item-title-date[data-v-0705ea01] {
  margin-left: 5px;
  color: #737373;
  font-style: italic;
  font-size: 80%;
  font-weight: 700;
}
.numerical-units-line .numerical-item-input[data-v-0705ea01] {
  width: 130px;
  margin-right: 30px;
}
.numerical-units-line .numerical-item-unit[data-v-0705ea01] {
  width: 110px;
  margin-right: 30px;
  font-weight: 700;
}
.numerical-units-line .numerical-item-remove[data-v-0705ea01] {
  margin-right: 60px;
  padding-top: 4px;
  cursor: pointer;
}
.numerical-units-line .numerical-item-total[data-v-0705ea01] {
  margin-right: 60px;
  font-weight: 700;
}
.numerical-units-line .numerical-item-total-number[data-v-0705ea01] {
  width: 60px;
  text-align: right;
  font-weight: 700;
}
.main-well-footer[data-v-0705ea01] {
  text-align: center;
}
.textarea-expandable[data-v-0705ea01] {
  background-image: url("/assets/svg/stripes_corner.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 9px 9px;
}
.textarea-expandable[data-v-0705ea01]::-webkit-resizer, .textarea-expandable[data-v-0705ea01]::-webkit-scrollbar-corner {
  background-image: url("/assets/svg/stripes_corner.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 9px 9px;
}
.textarea-not-expandable[data-v-0705ea01] {
  resize: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
