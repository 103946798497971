import { ObjectTypes } from '@/constants/ObjectTypes';
export function getFieldsFromObjectForPreview(item) {
  var result = {
    url: item.url,
    id: item.id || item.nodeId,
    objType: item.objType,
    title: item.title || item.name,
    confidential: item.confidential || false
  };
  if (item.accountableUserIds) {
    result.accountableUserIds = item.accountableUserIds;
  }
  if (item.responsibleUserIds) {
    result.responsibleUserIds = item.responsibleUserIds;
  }
  if (item.userIds) {
    result.userIds = item.userIds;
  }
  switch (item.objType) {
    case ObjectTypes.EXECUTION_INITIATIVE:
    case ObjectTypes.EXECUTION_INITIATIVE_LIMITED:
      result.slug = item.slug;
      break;
    case ObjectTypes.CONTROLS_CONTROL:
    case ObjectTypes.CONTROLS_CONTROL_LIMITED:
      result.cpath = item.cpath || item.path;
      break;
    case ObjectTypes.COMPLIANCE_NODE:
    case ObjectTypes.COMPLIANCE_NODE_LIMITED:
      result.id = item.id;
      result.compliance_projects_id = item.compliance_projects_id;
      break;
    case ObjectTypes.COMPLIANCE_GAP_ANALYSIS:
      result = {
        title: item.nodeTitle,
        url: item.nodeUrl,
        objType: ObjectTypes.COMPLIANCE_NODE,
        id: item.nodeId,
        compliance_projects_id: item.nodeProjectId
      };
      break;
    case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE:
    case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED:
      result.processTreeId = item.processTreeId;
      break;
    case ObjectTypes.RISK_RISK:
    case ObjectTypes.RISK_RISK_LIMITED:
      result.rno = item.rno;
      result.projectId = item.projectId;
      result.projectPeriodId = item.projectPeriodId;
      result.scoringId = item.scoringId;
  }
  return result;
}