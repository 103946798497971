import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  AddContactPersonModal: function AddContactPersonModal() {
    return {
      addContactPerson: i18n.t('Add contact person'),
      name: i18n.t('Name'),
      title: i18n.t('Title'),
      phone: i18n.t('Phone'),
      contractParty: i18n.t('Contract party')
    };
  },
  AddContractPartyModal: function AddContractPartyModal() {
    return {
      addContractParty: i18n.t('Add contract party'),
      toSearchInSwedishOrNorwegianRegisterUseFo: i18n.t('To search in Swedish, Norwegian or Finnish register, use following format: SE-12345678 / SE 12345678 or NO-12345678 / NO 12345678 or FI-12345678 / FI 12345678'),
      nameOrTaxNo: i18n.t('Name or tax no.'),
      search: i18n.t('Search'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      vatNumber: i18n.t('VAT number'),
      contractPartyAlreadyAttachedToTheCurrentCon: i18n.t('Contract party already attached to the current contract'),
      noResultsFound: i18n.t('No results found! Did you enter valid VAT number or company name?')
    };
  },
  ContractAdd: function ContractAdd() {
    return {
      newContract: i18n.t('New Contract'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      activityType: i18n.t('Activity type'),
      chooseActivityType: i18n.t('Choose activity type'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      createContract: i18n.t('Create contract'),
      enterTitle: getTitlePlaceholder()
    };
  },
  EditAttachment: function EditAttachment() {
    return {
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      pleaseFillLinkToFieldsCorrectly: i18n.t('Please fill Link to fields correctly'),
      title: i18n.t('Title'),
      type: i18n.t('Type'),
      chooseType: i18n.t('Choose type'),
      linkTo: i18n.t('Link to'),
      insertLink: i18n.t('Insert link'),
      insertLinkTitle: i18n.t('Insert link title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      enterTitle: getTitlePlaceholder()
    };
  },
  ContractsPayment: function ContractsPayment() {
    return {
      currency: i18n.t('Currency'),
      year: i18n.t('Year'),
      january: i18n.t('January'),
      february: i18n.t('February'),
      march: i18n.t('March'),
      april: i18n.t('April'),
      may: i18n.t('May'),
      june: i18n.t('June'),
      july: i18n.t('July'),
      august: i18n.t('August'),
      september: i18n.t('September'),
      october: i18n.t('October'),
      november: i18n.t('November'),
      december: i18n.t('December')
    };
  }
};