function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import * as DataAPI from '@/api/risma/data';
import { getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { getNodesForSelectBox } from '@/utils/format/Compliance';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { MODULES } from '@/constants/modules';
import * as Utils from '@/utils/Utils';
import { WrongModules } from '@/constants/WrongModules';
export var LinkedActivitiesForReportMixin = {
  data: function data() {
    return {
      riskProjects: [],
      linksDataset: {},
      processTreeId: null,
      fieldKeyPrefix: 'links-',
      linkedActivities: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isDataProcessorsEnabled', 'isSystemsEnabled', 'isDataControllersEnabled'])), {}, {
    isControlsEnabled: function isControlsEnabled() {
      return this.getSettingValue('app.module_controls_enabled') === 1 || this.getSettingValue('app.module_controls_enabled') === undefined;
    },
    isIncidentsEnabled: function isIncidentsEnabled() {
      return this.getSettingValue('app.module_incidents_enabled') === 1 || this.getSettingValue('app.module_incidents_enabled') === undefined;
    },
    activitiesSettings: function activitiesSettings() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.COMPLIANCE_IA_CONTROLLER, this.isDataControllersEnabled), MODULES.COMPLIANCE_IA_SYSTEM, this.isSystemsEnabled), MODULES.COMPLIANCE_IA_PROCESSOR, this.isDataProcessorsEnabled), MODULES.INCIDENTS, this.isIncidentsEnabled), MODULES.CONTROLS, this.isControlsEnabled);
    },
    linkedActivitiesColumns: function linkedActivitiesColumns() {
      var _ref2;
      return _ref2 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref2, MODULES.COMPANY, this.$trans('Linked companies')), MODULES.RISK, this.$trans('Linked risks')), MODULES.CONTROLS, this.$trans('Linked controls')), MODULES.INCIDENTS, this.$trans('Linked incidents')), MODULES.COMPLIANCE, this.$trans('Linked compliance nodes')), MODULES.EXECUTION, this.$trans('Linked initiatives')), MODULES.CONTRACTS, this.$trans('Linked contracts')), MODULES.COMPLIANCE_IA_SYSTEM, this.$trans('Linked systems')), MODULES.COMPLIANCE_IA_CONTROLLER, this.$trans('Linked data controllers')), MODULES.COMPLIANCE_IA_PROCESSOR, this.$trans('Linked Data processors')), _defineProperty(_defineProperty(_ref2, MODULES.COMPLIANCE_INFORMATION_ASSETS, this.$trans('Linked information assets')), MODULES.COMPLIANCE_PROCESS_TREE_NODE, "".concat(this.$trans('Linked'), " ").concat(getProcessLibraryTitle()));
    },
    linkedColumnsForPreview: function linkedColumnsForPreview() {
      var _this = this;
      return this.linkedActivities.map(function (moduleName) {
        return _this.getColumnKeyName(moduleName);
      });
    },
    decoupleLinkedActivitiesFilterFields: function decoupleLinkedActivitiesFilterFields() {
      var _this2 = this;
      return this.linkedActivities.map(function (moduleName) {
        return {
          key: _this2.getColumnKeyName(moduleName)
        };
      });
    },
    columnsMetaForLinkedActivities: function columnsMetaForLinkedActivities() {
      return [{
        targets: [this.getColumnKeyName(MODULES.COMPANY)],
        width: '250px'
      }];
    }
  }),
  methods: {
    getColumnKeyName: function getColumnKeyName(moduleName) {
      return this.fieldKeyPrefix + moduleName;
    },
    getLinkedActivitiesRequests: function getLinkedActivitiesRequests(activities) {
      var requests = {};
      var _iterator = _createForOfIteratorHelper(activities),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var activity = _step.value;
          var links = activity === null || activity === void 0 ? void 0 : activity.links;
          if (!links || Utils.isObjectEmpty(links)) continue;
          if (Object.keys(requests).length === Object.keys(this.linkedActivitiesColumns).length) {
            break;
          }
          for (var _i = 0, _Object$keys = Object.keys(links); _i < _Object$keys.length; _i++) {
            var moduleName = _Object$keys[_i];
            var isWrongModule = WrongModules.includes(moduleName) || !this.linkedActivitiesColumns[moduleName];
            if (!isWrongModule && !requests[moduleName]) {
              requests[moduleName] = this.getRequestByModule(moduleName);
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.linkedActivities = this.getAvailableLinkedActivities(Object.keys(requests));
      return Promise.all(Object.values(requests));
    },
    getAvailableLinkedActivities: function getAvailableLinkedActivities(modules) {
      var _this3 = this;
      return modules.filter(function (module) {
        return _this3.activitiesSettings[module] === undefined || _this3.activitiesSettings[module];
      });
    },
    getLinkedActivitiesColumns: function getLinkedActivitiesColumns() {
      var _this4 = this;
      var omitModules = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var columns = {};
      var filteredModules = this.linkedActivities.filter(function (moduleName) {
        return !omitModules.includes(moduleName);
      });
      filteredModules.forEach(function (module) {
        columns[_this4.getColumnKeyName(module)] = _this4.linkedActivitiesColumns[module];
      });
      return columns;
    },
    getRequestByModule: function getRequestByModule(module) {
      switch (module) {
        case MODULES.RISK:
          return this.getRisks();
        case MODULES.CONTROLS:
          return this.getControls();
        case MODULES.INCIDENTS:
          return this.getIncidents();
        case MODULES.COMPLIANCE:
          return this.getComplianceNodes();
        case MODULES.EXECUTION:
          return this.getInitiatives();
        case MODULES.CONTRACTS:
          return this.getContracts();
        case MODULES.COMPLIANCE_IA_SYSTEM:
          return this.getInformationAssetsSystems();
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          return this.getInformationAssetsProcessors();
        case MODULES.COMPLIANCE_INFORMATION_ASSETS:
          return this.getCustomInformationAssets();
        case MODULES.COMPLIANCE_IA_CONTROLLER:
          return this.getControllers();
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return this.getNodeElementAllData();
        case MODULES.COMPANY:
          return this.getCompanies();
      }
      return Promise.resolve();
    },
    getRisks: function getRisks() {
      var _this5 = this;
      return DataAPI.getRisks('?data[]=scores').then(function (_ref3) {
        var list = _ref3.list;
        return _this5.linksDataset[MODULES.RISK] = list;
      }).catch(function () {});
    },
    getControls: function getControls() {
      var _this6 = this;
      return DataAPI.getControls().then(function (_ref4) {
        var list = _ref4.list;
        return _this6.linksDataset[MODULES.CONTROLS] = list;
      }).catch(function () {});
    },
    getInitiatives: function getInitiatives() {
      var _this7 = this;
      return DataAPI.getInitiatives().then(function (_ref5) {
        var list = _ref5.list;
        return _this7.linksDataset[MODULES.EXECUTION] = list;
      }).catch(function () {});
    },
    getContracts: function getContracts() {
      var _this8 = this;
      return DataAPI.getContracts().then(function (_ref6) {
        var list = _ref6.list;
        return _this8.linksDataset[MODULES.CONTRACTS] = list;
      }).catch(function () {});
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return DataAPI.getAllTrees().then(this.setIdToProccessTreeIdAndGetNodes).catch(function () {});
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this9 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return DataAPI.getNodesByProcessTreeId(this.processTreeId).then(function (response) {
        var processLibraryNodes = [];
        var processTreeId = _this9.processTreeId;
        Utils.pushChildrenToParent(response, processLibraryNodes);
        _this9.linksDataset[MODULES.COMPLIANCE_PROCESS_TREE_NODE] = processLibraryNodes.map(function (node) {
          return _objectSpread(_objectSpread({}, node), {}, {
            processTreeId: processTreeId
          });
        });
      }).catch(function () {});
    },
    getRiskProjects: function getRiskProjects() {
      var _this10 = this;
      return _getRiskProjects().then(function (_ref7) {
        var list = _ref7.list;
        return _this10.riskProjects = list;
      }).catch(function () {});
    },
    getInformationAssetsSystems: function getInformationAssetsSystems() {
      var _this11 = this;
      return DataAPI.getInformationAssetsSystems().then(function (_ref8) {
        var list = _ref8.list;
        return _this11.linksDataset[MODULES.COMPLIANCE_IA_SYSTEM] = list;
      }).catch(function () {});
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this12 = this;
      return DataAPI.getInformationAssetsProcessors().then(function (_ref9) {
        var list = _ref9.list;
        return _this12.linksDataset[MODULES.COMPLIANCE_IA_PROCESSOR] = list;
      }).catch(function () {});
    },
    getControllers: function getControllers() {
      var _this13 = this;
      return DataAPI.getInformationAssetsControllers().then(function (_ref10) {
        var list = _ref10.list;
        return _this13.linksDataset[MODULES.COMPLIANCE_IA_CONTROLLER] = list;
      }).catch(function () {});
    },
    getCustomInformationAssets: function getCustomInformationAssets() {
      var _this14 = this;
      return DataAPI.getCustomInformationAssets().then(function (_ref11) {
        var list = _ref11.list;
        return _this14.linksDataset[MODULES.COMPLIANCE_INFORMATION_ASSETS] = list;
      }).catch(function () {});
    },
    getCompanies: function getCompanies() {
      var _this15 = this;
      return _getCompanies().then(function (_ref12) {
        var list = _ref12.list;
        return _this15.linksDataset[MODULES.COMPANY] = _this15.enhanceCompanies(list);
      }).catch(function () {});
    },
    enhanceCompanies: function enhanceCompanies(companies) {
      return companies.map(function (company) {
        return _objectSpread(_objectSpread({}, company), {}, {
          url: "/company/".concat(company.id)
        });
      });
    },
    getIncidents: function getIncidents() {
      var _this16 = this;
      return DataAPI.getIncidents().then(function (_ref13) {
        var list = _ref13.list;
        return _this16.linksDataset[MODULES.INCIDENTS] = list;
      }).catch(function () {});
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this17 = this;
      return getNodesForSelectBox().then(function (nodes) {
        return _this17.linksDataset[MODULES.COMPLIANCE] = nodes;
      }).catch(function () {});
    },
    getLinkedActivitiesFields: function getLinkedActivitiesFields(id, links) {
      var _this18 = this;
      var fields = {};
      this.linkedActivities.forEach(function (module) {
        var field = _this18.prepareLinkedActivitiesField(id, links, module);
        fields[_this18.getColumnKeyName(module)] = _objectSpread({
          field: field
        }, field);
      });
      return fields;
    },
    prepareLinkedActivitiesField: function prepareLinkedActivitiesField(id) {
      var links = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var key = arguments.length > 2 ? arguments[2] : undefined;
      var result = {
        activities: []
      };
      switch (key) {
        case MODULES.CONTROLS:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.CONTROLS], this.linksDataset[MODULES.CONTROLS] || [], 'path')), {}, {
            id: id
          });
          break;
        case MODULES.RISK:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[MODULES.RISK], this.linksDataset[MODULES.RISK] || [], 'rno', this.getRiskValues)), {}, {
            id: id
          });
          break;
        default:
          result = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links[key], this.linksDataset[key] || [])), {}, {
            id: id
          });
          break;
      }
      return result;
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    }
  }
};