// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-button[data-v-162d5980]  button {
  justify-content: center;
  width: 100%;
  font-size: 21px;
  margin: 0;
  padding: 5px;
  font-weight: 400;
}
.news-rect[data-v-162d5980] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.logo-image[data-v-162d5980] {
  width: 400px;
  height: 82px;
}
.dropdown-with-lines[data-v-162d5980]:before, .dropdown-with-lines[data-v-162d5980]:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.dropdown-with-lines[data-v-162d5980]:before {
  right: 0.5em;
  margin-left: -50%;
}
.dropdown-with-lines[data-v-162d5980]:after {
  left: 0.5em;
  margin-right: -50%;
}
.right-column[data-v-162d5980] {
  background-image: url(/assets/svg/gradient-stroke-01.svg);
  background-repeat: no-repeat;
  background-position-y: 60%;
  background-position-x: -30px;
  background-size: 110%;
}
.right-column .right-column-image[data-v-162d5980] {
  background-image: url(/assets/svg/CSRDSolution.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}
.track-text[data-v-162d5980] {
  font-weight: 400;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
