import { i18n } from '@/i18n';
export var CONTRACTS_TABLE_COLUMNS = function CONTRACTS_TABLE_COLUMNS() {
  var activityType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var responsibleLabel = (activityType === null || activityType === void 0 ? void 0 : activityType.responsibleLabel) || 'Responsible';
  return {
    contractNumber: i18n.t('Contract no.'),
    title: i18n.t('Title'),
    titlePreview: i18n.t('Title'),
    controls: i18n.t('Controls'),
    companies: i18n.t('Contract parties'),
    responsible: responsibleLabel,
    activityType: i18n.t('Activity type'),
    tags: i18n.t('Tags'),
    start: i18n.t('Start date'),
    end: i18n.t('Expiration date'),
    commitment: i18n.t('Commitment deadline'),
    amount: i18n.t('Amount'),
    currency: i18n.t('Currency'),
    status: i18n.t('Status'),
    starred: '',
    delete: ''
  };
};