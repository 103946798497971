function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { i18n } from '@/i18n';
import { SettingsNav } from '@/constants/menu/navigations';
import { MODULES } from '@/constants/modules';
export function userSettingsNav(user, settings, riskProjectsItems) {
  return [{
    groupTitle: i18n.t('Personal settings'),
    rights: true,
    groupItems: [{
      path: SettingsNav.user.path,
      title: i18n.t(SettingsNav.user.title)
    }, {
      path: SettingsNav.notifications.path,
      title: i18n.t(SettingsNav.notifications.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.general.title),
    rights: SettingsNav.controlPanel.rights(user, settings),
    groupItems: [{
      path: SettingsNav.userAdmin.path,
      title: i18n.t(SettingsNav.userAdmin.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.userActionRequirements.path,
      title: i18n.t(SettingsNav.userActionRequirements.title),
      rights: SettingsNav.general.rights(user) && settings['feature.admin.action_requirements_enabled'] === 1
    }, {
      path: SettingsNav.anonymization.path,
      title: i18n.t(SettingsNav.anonymization.title),
      rights: SettingsNav.general.rights(user) && settings['feature.anonymization_admin_enabled'] === 1
    }, {
      path: SettingsNav.emails.path,
      title: i18n.t(SettingsNav.emails.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.adsettings.path,
      title: i18n.t(SettingsNav.adsettings.title),
      rights: SettingsNav.adsettings.rights(user, settings)
    }, {
      path: SettingsNav.siemControlPanel.path,
      title: i18n.t(SettingsNav.siemControlPanel.title),
      rights: SettingsNav.general.rights(user) && settings['feature.admin.siem_enabled'] === 1
    }, {
      path: SettingsNav.organisations.path,
      title: i18n.t(SettingsNav.organisations.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.activityTypes.path,
      title: i18n.t(SettingsNav.activityTypes.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.tags.path,
      title: i18n.t(SettingsNav.tags.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.nonWorkingDays.path,
      title: i18n.t(SettingsNav.nonWorkingDays.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.lookFeel.path,
      title: i18n.t(SettingsNav.lookFeel.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.controlPanel.path,
      title: i18n.t(SettingsNav.controlPanel.title),
      rights: SettingsNav.controlPanel.rights(user, settings)
    }, {
      path: SettingsNav.auditReports.path,
      title: i18n.t(SettingsNav.auditReports.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.importData.path,
      title: i18n.t(SettingsNav.importData.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.bulkUpdate.path,
      title: i18n.t(SettingsNav.bulkUpdate.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.admin.path,
      title: i18n.t(SettingsNav.admin.title),
      rights: SettingsNav.general.rights(user) && settings['servername'] !== undefined
    }, {
      path: SettingsNav.framework.path,
      title: i18n.t(SettingsNav.framework.title),
      rights: SettingsNav.general.rights(user)
    }, {
      path: SettingsNav.aiPartner.path,
      title: i18n.t(SettingsNav.aiPartner.title),
      rights: SettingsNav.aiPartner.rights(user, settings)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.actionsSettings.title),
    rights: SettingsNav.actionsSettings.rights(user, settings),
    groupItems: [{
      path: SettingsNav.actionControlPanel.path,
      title: i18n.t(SettingsNav.actionControlPanel.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.contract.title),
    rights: SettingsNav.contractsControlPanel.rights(user, settings),
    groupItems: [{
      path: SettingsNav.contractsControlPanel.path,
      title: i18n.t(SettingsNav.contractsControlPanel.title),
      rights: SettingsNav.contractsControlPanel.rights(user, settings)
    }, {
      path: SettingsNav.documentTypes.path,
      title: i18n.t(SettingsNav.documentTypes.title)
    }, {
      path: SettingsNav.workflowStatuses.path,
      title: i18n.t(SettingsNav.workflowStatuses.title),
      rights: SettingsNav.workflowStatuses.rights(user, settings)
    }, {
      path: SettingsNav.contractQuestionnaires.path,
      title: i18n.t(SettingsNav.contractQuestionnaires.title),
      rights: SettingsNav.contract.rights(user, settings)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.controlsSettings.title),
    rights: SettingsNav.controlsSettings.rights(user, settings),
    groupItems: [{
      path: SettingsNav.controlsAdmin.path,
      title: i18n.t(SettingsNav.controlsAdmin.title)
    }, {
      path: SettingsNav.controlControlPanel.path,
      title: i18n.t(SettingsNav.controlControlPanel.title)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.complianceSettings.title),
    rights: SettingsNav.complianceControlPanel.rights(user, settings),
    groupItems: [{
      path: SettingsNav.complianceProjects.path,
      title: i18n.t(SettingsNav.complianceProjects.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceRiskAssessment.path,
      title: i18n.t(SettingsNav.complianceRiskAssessment.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceAssets.path,
      title: i18n.t(SettingsNav.complianceAssets.title),
      rights: SettingsNav.complianceAssets.rights(user, settings)
    }, {
      path: SettingsNav.complianceTemplateNodes.path,
      title: i18n.t(SettingsNav.complianceTemplateNodes.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceQuestionnaires.path,
      title: i18n.t(SettingsNav.complianceQuestionnaires.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceLiaTia.path,
      title: i18n.t(SettingsNav.complianceLiaTia.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceGapSchemas.path,
      title: i18n.t(SettingsNav.complianceGapSchemas.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceRismaRules.path,
      title: i18n.t(SettingsNav.complianceRismaRules.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceRelevantQuestions.path,
      title: i18n.t(SettingsNav.complianceRelevantQuestions.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.complianceControlPanel.path,
      title: i18n.t(SettingsNav.complianceControlPanel.title),
      rights: SettingsNav.complianceControlPanel.rights(user, settings)
    }, {
      path: SettingsNav.suggestedActivities.path,
      title: i18n.t(SettingsNav.suggestedActivities.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }, {
      path: SettingsNav.closeYear.path,
      title: i18n.t(SettingsNav.closeYear.title),
      rights: SettingsNav.complianceSettings.rights(user, settings)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.riskSettings.title),
    rights: SettingsNav.riskSettings.rights(user, settings) || !!SettingsNav.riskPeriod.rights(user, settings),
    groupItems: [{
      path: SettingsNav.riskProjects.path,
      title: i18n.t(SettingsNav.riskProjects.title),
      rights: SettingsNav.riskProjects.rights(user, settings)
    }].concat(_toConsumableArray(riskProjectsItems), [{
      path: SettingsNav.riskThreatsVulnerability.path,
      title: i18n.t(SettingsNav.riskThreatsVulnerability.title),
      rights: !!(settings['app.module_risks2_enabled'] && user.is_admin && user.projects[MODULES.RISK].length)
    }, {
      path: SettingsNav.riskControlPanel.path,
      title: i18n.t(SettingsNav.riskControlPanel.title),
      rights: SettingsNav.riskControlPanel.rights(user, settings)
    }, {
      path: SettingsNav.riskHeatMap.path,
      title: i18n.t(SettingsNav.riskHeatMap.title),
      rights: SettingsNav.riskHeatMap.rights(user, settings)
    }, {
      path: SettingsNav.riskQuestionnaire.path,
      title: i18n.t(SettingsNav.riskQuestionnaire.title),
      rights: settings['app.module_risks2_enabled'] && user.is_admin
    }])
  }, {
    groupTitle: i18n.t(SettingsNav.incident.title),
    rights: SettingsNav.incident.rights(user, settings),
    groupItems: [{
      path: SettingsNav.incidentsControlPanel.path,
      title: i18n.t(SettingsNav.incidentsControlPanel.title)
    }, {
      path: SettingsNav.incidentsWorkflows.path,
      title: i18n.t(SettingsNav.incidentsWorkflows.title),
      rights: SettingsNav.incidentsWorkflows.rights(user, settings)
    }, {
      path: SettingsNav.incidentsQuestionnaire.path,
      title: i18n.t(SettingsNav.incidentsQuestionnaire.title),
      rights: SettingsNav.incident.rights(user, settings)
    }]
  }, {
    groupTitle: i18n.t(SettingsNav.appSettings.title),
    rights: SettingsNav.appSettings.rights(user, settings),
    groupItems: [{
      path: SettingsNav.appInvitation.path,
      title: i18n.t(SettingsNav.appInvitation.title)
    }, {
      path: SettingsNav.appControlPanel.path,
      title: i18n.t(SettingsNav.appControlPanel.title)
    }]
  }];
}