import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_expansion_panel, null, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: "Title",
        type: "medium"
      })];
    }),
    content: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("div", null, " Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis blanditiis corrupti deserunt possimus sapiente, perferendis tempora magnam numquam facilis temporibus repudiandae corporis praesentium debitis voluptates totam vitae explicabo nobis. Praesentium. ", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  })]);
}