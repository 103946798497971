import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_input_field = _resolveComponent("input-field");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalHeader,
    "button-ok-text": $options.buttonText,
    "dismiss-button-disabled": $props.testButtonLoadState,
    "ok-button-disabled": $props.testButtonLoadState,
    "button-dismiss-text": $data.translate.testConnection,
    onAccept: $options.handleAccept,
    onDismiss: $options.handleDismiss
  }, {
    body: _withCtx(function () {
      return [$props.testButtonLoadState ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0,
        class: "my-32"
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.preparedData.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.preparedData.username = $event;
        }),
        class: "mb-2",
        title: $data.translate.username
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
        modelValue: $data.preparedData.emailAddress,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.preparedData.emailAddress = $event;
        }),
        class: "mb-2",
        title: $data.translate.email
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
        modelValue: $data.preparedData.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.preparedData.password = $event;
        }),
        class: "mb-2",
        title: $data.translate.password,
        type: "password"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
        modelValue: $data.preparedData.mailServerAddress,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.preparedData.mailServerAddress = $event;
        }),
        class: "mb-2",
        title: $data.translate.serverAddress
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
        modelValue: $data.preparedData.mailServerPort,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.preparedData.mailServerPort = $event;
        }),
        class: "mb-2",
        title: $data.translate.serverPort
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_input_field, {
        modelValue: $data.preparedData.service,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $data.preparedData.service = $event;
        }),
        class: "mb-2",
        title: $data.translate.service
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_checkboxes, {
        options: $options.checkboxOptions,
        "label-position": "left",
        class: "max-w-350px",
        "checkbox-size": 20,
        onUpdated: $options.onCheckboxChange
      }, null, 8 /* PROPS */, ["options", "onUpdated"])]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "dismiss-button-disabled", "ok-button-disabled", "button-dismiss-text", "onAccept", "onDismiss"]);
}