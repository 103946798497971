import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "notification flex items-center border border-transparent text-white bg-brand-500"
};
var _hoisted_2 = {
  class: "flex-grow text-center"
};
var _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, [_createTextVNode(_toDisplayString($data.translate.yourRismaSolutionHasBeenUpdatedToVersion) + " " + _toDisplayString($options.rismaVersion) + ".  ", 1 /* TEXT */), _createElementVNode("a", {
    href: $options.linkToReleaseNotes,
    target: "_blank",
    class: "underline"
  }, _toDisplayString($data.translate.readMoreHere) + ". ", 9 /* TEXT, PROPS */, _hoisted_3)]), _createVNode(_component_feather_icon, {
    icon: "x",
    class: "ml-4 stroke-2 cursor-pointer",
    onClick: $options.dismiss
  }, null, 8 /* PROPS */, ["onClick"])]);
}