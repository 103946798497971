import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_paginated_table = _resolveComponent("paginated-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_single_select, {
    modelValue: _ctx.paginationSelected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.paginationSelected = $event;
    }),
    options: _ctx.paginatedTableOptions,
    style: {
      "margin-bottom": "2rem"
    }
  }, null, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_paginated_table, {
    "dataset-table": $options.paginationData,
    columns: _ctx.paginatedColumns,
    onTrigger: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('table', $event);
    })
  }, null, 8 /* PROPS */, ["dataset-table", "columns"])]);
}