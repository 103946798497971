function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import ControlPanelSettings from '@/components/Organisms/Admin/ControlPanelSettings';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SETTINGS_ARRAY } from '@/constants/admin/Settings';
export default {
  name: 'AdminControlPanel',
  description: 'Page with general control panel section',
  token: '<admin-control-panel />',
  components: {
    ControlPanelSettings: ControlPanelSettings
  },
  mixins: [ProcessLibraryTitleMixin],
  emits: ['updateHeader'],
  data: function data() {
    return {
      translate: getTranslate['AdminControlPanel']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled'])), {}, {
    settingsGroups: function settingsGroups() {
      var settingsGroups = _defineProperty({}, 'Control panel', _objectSpread({}, SETTINGS_ARRAY['General']));
      if (this.getSettingValue('app.module_execution_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/actions/my'] = this.translate.myActions;
      }
      if (this.getSettingValue('app.module_controls_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/controls2/my'] = this.translate.myControls;
      }
      if (this.getSettingValue('app.module_risk_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/risk2/projects/1/my-risks'] = this.translate.myRisks + ' (ERM)';
      }
      if (this.getSettingValue('app.module_compliance_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/compliance'] = this.translate.compliance;
      }
      if (this.getSettingValue('feature.process_tree')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/compliance/processtree'] = this.processLibraryTitle;
      }
      if (this.getSettingValue('app.module_incidents_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/incident/list'] = this.translate.incidents;
      }
      if (this.getSettingValue('feature.performance_report_enabled')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/performance-report/dashboard'] = 'GRC Intelligence Center';
      }
      if (this.getSettingValue('feature.welcome_page_menu')) {
        settingsGroups['Control panel']['risma.landing_page']['dropdown']['/userguide'] = this.getSettingValue('app.user_guide_title');
      }
      if (this.isInformationAssetsDisabled) {
        delete settingsGroups['Control panel']['feature.asset_management_menu'];
      }
      return settingsGroups;
    }
  })
};