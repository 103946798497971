import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.error ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.error), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : !$data.error ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.overviewComponent), {
    "process-tree": $data.processTree
  }, {
    default: _withCtx(function () {
      return [$options.isLeftTreeProject ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $setup.showMiniTreeComponent ? $data.translate.defaultTree : $data.translate.detailedTree,
        type: "util",
        class: _normalizeClass(["mr-4", {
          'mt-4': !$setup.showMiniTreeComponent
        }]),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $setup.showMiniTreeComponent = !$setup.showMiniTreeComponent;
        })
      }, null, 8 /* PROPS */, ["text", "class"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["process-tree"]))])) : _createCommentVNode("v-if", true)]);
}