import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
var _hoisted_1 = {
  class: "flex justify-around items-start mb-8"
};
var _hoisted_2 = {
  key: 1,
  class: "text-3xl"
};
var _hoisted_3 = {
  key: 2,
  class: "mb-4"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "flex items-center justify-between -ml-2"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "mt-2 cursor-pointer flex items-center"
};
var _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.modalTitle,
    "show-buttons": false,
    type: "big",
    "dismiss-on-click-outside": false,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$props.parentErrorMessage || $data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: $options.handleErrorMessage
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($props.parentErrorMessage || $data.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          key: "wizard_flow_step_".concat(index),
          class: _normalizeClass(["text-center flex flex-col justify-center items-center relative", [{
            'arrow-left': index > 0
          }, {
            'arrow-right': index < $props.items.length - 1
          }, {
            'previous-complete': $data.activeIndex >= index
          }, {
            complete: $data.activeIndex > index
          }, {
            'right-spacing': item.icon === 'info'
          }]]),
          style: _normalizeStyle({
            width: "calc(100% / ".concat($props.items.length, ")")
          })
        }, [_createElementVNode("div", {
          class: _normalizeClass(["rounded-full w-60px h-60px flex items-center justify-center", [$data.activeIndex === index ? 'border-blue-350 text-blue-350 border-2' : $data.activeIndex > index ? 'border-2 border-green-330 text-green-330' : 'border-2 border-gray-350 text-gray-350', item.icon === 'info' ? 'border-none mb-3' : 'mb-4']]),
          style: _normalizeStyle(item.icon === 'info' ? {
            width: '65px',
            height: '65px'
          } : {})
        }, [item.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: item.icon,
          width: item.icon === 'info' ? 65 : 60,
          height: item.icon === 'info' ? 65 : 60,
          class: _normalizeClass(item.icon === 'info' ? '' : 'p-2')
        }, null, 8 /* PROPS */, ["icon", "width", "height", "class"])) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(index + 1), 1 /* TEXT */))], 6 /* CLASS, STYLE */), _createElementVNode("h2", {
          class: _normalizeClass(["text-base font-semibold w-full", [{
            'font-bold': $data.activeIndex === index
          }, $data.activeIndex === index ? 'text-blue-350' : $data.activeIndex > index ? 'text-green-330' : 'text-gray-350']])
        }, _toDisplayString(item.headline), 3 /* TEXT, CLASS */)], 6 /* CLASS, STYLE */);
      }), 128 /* KEYED_FRAGMENT */))]), $props.loadState ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [$props.items[$data.activeIndex].headline2 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
        class: "mb-1 text-lg",
        type: "medium",
        title: $props.items[$data.activeIndex].headline2
      }, null, 8 /* PROPS */, ["title"]), $props.items[$data.activeIndex].questionsString ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        class: "mb-2",
        type: "small",
        title: $props.items[$data.activeIndex].questionsString
      }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $props.items[$data.activeIndex].component ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.items[$data.activeIndex].component), {
        key: 1,
        data: $props.data,
        "selected-options": $props.selectedOptions,
        "onUpdate:selected": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:selected', $event);
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, ["data", "selected-options"])) : _createCommentVNode("v-if", true)])), _createElementVNode("div", _hoisted_5, [$props.items[$data.activeIndex].hasShowMessageAgain ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("label", _hoisted_7, [_createVNode(_component_checkboxes, {
        modelValue: $data.notShowThisModalAgain,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.notShowThisModalAgain = $event;
        }),
        options: [{
          value: true
        }]
      }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("span", null, _toDisplayString($data.translate.dontShowThisMessageAgain), 1 /* TEXT */)])])) : (_openBlock(), _createElementBlock("span", _hoisted_8)), _createVNode(_component_buttons_row, {
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}