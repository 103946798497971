function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { useRiskProjectsStore } from '@/Store/riskProjectsStore';
import { getTranslate } from '@/components/Organisms/Compliance/Admin/translate';
import { createSecurityGroup as createSecurityGroupAPI, updateSecurityGroup } from '@/api/compliance/admin';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import { adminLevels, accessLevels, accessLevelsBase, accessLevelsCompanies } from '@/constants/compliance/SecurityGroups';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'SecurityGroupsCreationModal',
  components: {
    SingleSelect: SingleSelect,
    Notification: Notification,
    InputField: InputField,
    Checkboxes: Checkboxes,
    Modal: Modal,
    FeatherIcon: FeatherIcon
  },
  props: {
    modalData: {
      type: Object,
      required: false,
      default: null,
      note: 'data that is used on update'
    }
  },
  emits: ['create', 'dismiss', 'update'],
  data: function data() {
    return {
      adminLevels: adminLevels(),
      accessLevels: accessLevels,
      accessLevelsBase: accessLevelsBase,
      accessLevelsCompanies: accessLevelsCompanies,
      errors: {
        objectId: '',
        name: ''
      },
      group: {
        name: '',
        objectId: '',
        levelAdmin: 0,
        levelControls: 0,
        levelExecution: 0,
        levelIncidents: 0,
        levelRisk: 0,
        levelContracts: 0,
        levelCompany: 0,
        levelInformationAssets: 0,
        levelProcessLibrary: 0,
        lockedFields: false,
        complianceProjects: [],
        riskProjects: []
      },
      errorMessage: '',
      translate: getTranslate['SecurityGroupsCreationModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    informationAssetsName: 'informationAssetsName',
    processLibraryName: 'processLibraryName'
  })), mapState(useComplianceProjectsStore, {
    complianceProjects: 'projects'
  })), mapState(useRiskProjectsStore, {
    riskProjects: 'projects'
  })), {}, {
    groupedFieldOptions: function groupedFieldOptions() {
      return [{
        checked: !!this.group.lockedFields,
        label: this.translate.lockedFields
      }];
    },
    modalHeadline: function modalHeadline() {
      return this.modalData ? this.translate.updateSecurityGroup : this.translate.createSecurityGroup;
    },
    riskProjectsOptions: function riskProjectsOptions() {
      var riskProjects = this.riskProjects.filter(function (project) {
        return project.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
      });
      return this.formatEntryProjects(riskProjects);
    },
    complianceProjectsOptions: function complianceProjectsOptions() {
      return this.formatEntryProjects(this.complianceProjects);
    },
    projectAccessSections: function projectAccessSections() {
      return {
        complianceProjects: {
          label: this.$trans('Create new compliance access'),
          options: this.complianceProjectsOptions
        },
        riskProjects: {
          label: this.$trans('Create new risk access'),
          options: this.riskProjectsOptions
        }
      };
    }
  }),
  mounted: function mounted() {
    if (this.modalData) {
      this.group = _objectSpread(_objectSpread({}, this.group), this.modalData);
    }
  },
  methods: {
    resetNewGroup: function resetNewGroup() {
      this.group = {
        name: '',
        objectId: '',
        levelAdmin: 0,
        levelControls: 0,
        levelExecution: 0,
        levelIncidents: 0,
        levelRisk: 0,
        levelContracts: 0,
        levelCompany: 0,
        levelInformationAssets: 0,
        levelProcessLibrary: 0,
        lockedFields: false
      };
    },
    createGroup: function createGroup(newGroup) {
      var _this = this;
      return createSecurityGroupAPI(newGroup).then(function () {
        return _this.$emit('create');
      }).catch(function (error) {
        return _this.errorMessage = error;
      });
    },
    updateGroup: function updateGroup(groupId, newGroup) {
      var _this2 = this;
      return updateSecurityGroup(groupId, newGroup).then(function (_ref) {
        var data = _ref.data;
        _this2.$emit('update', data);
      }).catch(function (error) {
        return _this2.errorMessage = error;
      });
    },
    validate: function validate() {
      this.errors = {
        name: this.group.name ? '' : this.translate.pleaseFillOutThisField,
        objectId: this.group.objectId ? '' : this.translate.pleaseFillOutThisField
      };
    },
    handleAccept: function handleAccept() {
      this.errorMessage = '';
      this.validate();
      if (Object.values(this.errors).every(function (error) {
        return !error;
      })) {
        var newGroup = _objectSpread(_objectSpread({}, this.group), {}, {
          lockedFields: this.group.lockedFields ? 1 : 0
        });
        this.modalData ? this.updateGroup(this.modalData.id, newGroup) : this.createGroup(newGroup);
      }
    },
    formatEntryProjects: function formatEntryProjects(list) {
      return list.map(function (project) {
        return {
          value: project.id,
          label: project.title
        };
      });
    },
    createProject: function createProject(type, projectId) {
      this.group[type].push({
        projectId: projectId,
        accessLevel: 1
      });
    },
    deleteProject: function deleteProject(type, index) {
      this.group[type].splice(index, 1);
    }
  }
};