import { Http } from '../index';
import { handleRequestResponse, throwError } from '../httpUtils';
import { flushCache } from '@/api/HttpCache';
function flushTreeCache(projectId) {
  flushCache("/compliance/projects/".concat(projectId, "/nodes"));
}
export function getById(projectId, nodeId) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/nodes/").concat(nodeId).concat(params)));
}
export function getAll(projectId) {
  return Http.get("/compliance/projects/".concat(projectId, "/nodes?format=flat")).then(function (response) {
    return response.data;
  }).catch(function (err) {
    if (err.response.data.error === 'No nodes found') {
      return err.response.data;
    } else {
      return throwError(err);
    }
  });
}
export function updateNodeByProjectIdAndNodeId(projectId, nodeId, params) {
  flushTreeCache(projectId);
  return handleRequestResponse(Http.patch("/compliance/projects/".concat(projectId, "/nodes/").concat(nodeId), params));
}
export function deleteNodeById(projectId, nodeId) {
  return handleRequestResponse(Http.delete("/compliance/projects/".concat(projectId, "/nodes/").concat(nodeId)));
}
export function getDeletedNodesByProject(projectId) {
  return Http.get("/compliance/projects/".concat(projectId, "/nodes?format=flat&filter[deleted]=1")).then(function (_ref) {
    var data = _ref.data;
    return data;
  });
}
export function getNodeVersions(nodeId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return handleRequestResponse(Http.get("/compliance/nodes/".concat(nodeId, "/versions").concat(params)));
}