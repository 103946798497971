function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getLogReports } from '@/api/admin/reports';
import { DateFormats } from '@/constants/DateFormats';
import { SortDirection } from '@/constants/SortDirection';
import { getFortnightDateRange, formatUtcToLocalDate } from '@/utils/date';
export default {
  components: {
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    RismaDatatable: RismaDatatable,
    RismaButton: RismaButton,
    RismaDateRangePicker: RismaDateRangePicker,
    SingleSelect: SingleSelect,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      loading: null,
      showReport: false,
      showNothingToShowMessage: false,
      dataset: [],
      selectedUser: null,
      dateRange: getFortnightDateRange(DateFormats.DATE_TIME_FORMAT_FULL),
      orderNew: [{
        index: 'loginTime',
        dir: SortDirection.DESC
      }],
      DateFormats: DateFormats,
      translate: getTranslate['AdminLoginsReport']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    allUsers: 'activeUsers'
  })), {}, {
    columns: function columns() {
      return {
        loginTime: this.translate.timestamp,
        username: this.translate.username,
        groups: this.translate.groups
      };
    },
    getOptionsUsers: function getOptionsUsers() {
      return this.allUsers.map(function (user, index) {
        return {
          value: index + '',
          label: user.username
        };
      });
    }
  }),
  methods: {
    createDataset: function createDataset(data) {
      var _this = this;
      var dataset = [];
      data.forEach(function (element) {
        dataset.push({
          loginTime: {
            field: formatUtcToLocalDate(element.loginTime, DateFormats.DATE_TIME_FORMAT_FULL)
          },
          username: {
            field: element.username
          },
          groups: _objectSpread({}, _this.getGroup(element.groups))
        });
      });
      return dataset;
    },
    getGroup: function getGroup(array) {
      var field = '';
      var data = {
        field: '',
        fieldText: ''
      };
      if (array.length) {
        field += '<ul>';
        array.forEach(function (el) {
          field += '<li>' + el.name + '</li>';
        });
        field += '</ul>';
        data = {
          field: field,
          fieldText: array.map(function (elem) {
            return elem.name;
          }).join(', ')
        };
      }
      return data;
    },
    setReports: function setReports(response) {
      this.dataset = this.createDataset(response.list);
      this.enhanceDataset(this.dataset, this.columns);
      this.setState('complete');
    },
    changedDates: function changedDates(e) {
      if (e) {
        this.dateRange.startDate = e.startDate;
        this.dateRange.endDate = e.endDate;
      }
    },
    onDateDiscard: function onDateDiscard() {
      this.dateRange.startDate = '';
      this.dateRange.endDate = '';
    },
    createReport: function createReport() {
      this.setState('loading');
      var filters = this.getFilters();
      getLogReports(filters).then(this.setReports);
    },
    getFilters: function getFilters() {
      var filters = {};
      if (this.dateRange.startDate && this.dateRange.endDate) {
        filters['dateAfter'] = this.dateRange.startDate;
        filters['dateBefore'] = this.dateRange.endDate;
      }
      if (this.selectedUser !== null) {
        filters['username'] = this.allUsers[this.selectedUser].username;
      }
      return filters;
    },
    setState: function setState(state) {
      switch (state) {
        case 'loading':
          this.showReport = false;
          this.loading = true;
          this.showNothingToShowMessage = false;
          break;
        case 'complete':
          this.showReport = true;
          this.loading = false;
          if (!this.datasetSlice.length) {
            this.showNothingToShowMessage = true;
          }
          break;
      }
    }
  }
};