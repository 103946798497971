// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.required button[data-v-2a3e560f] {
  padding-top: 0;
  padding-bottom: 0;
  border-width: 2px;
  border-style: solid;
  border-color: #B03D00;
  border-left: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
