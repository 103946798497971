import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
var ExpansionTypes = {
  DEFAULT: 'default',
  UTIL: 'util'
};
export default {
  name: 'ExpansionPanel',
  introduction: 'The expansion-panel component is useful for reducing vertical space with large amounts of information.',
  description: 'Use templates with v-slot:header and v-slot:content',
  token: '<expansion-panel>{slot:header}{slot:content}</expansion-panel>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    startExpanded: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If the panel should start as expanded'
    },
    type: {
      type: String,
      required: false,
      default: 'default',
      validator: function validator(t) {
        return Object.values(ExpansionTypes).includes(t);
      }
    }
  },
  data: function data() {
    return {
      completeIsExpanded: this.startExpanded,
      ExpansionTypes: ExpansionTypes
    };
  },
  computed: {
    classList: function classList() {
      return {
        default: this.type === ExpansionTypes.DEFAULT,
        expanded: this.completeIsExpanded,
        'border-rm-signal-grey-dark': this.completeIsExpanded && this.type === ExpansionTypes.DEFAULT,
        'border rounded-md border-rm-grey hover:border-rm-signal-grey-dark': this.type === ExpansionTypes.DEFAULT
      };
    }
  }
};