import { defineStore } from 'pinia';
export var useAIAssistant = defineStore('aiAssistant', {
  state: function state() {
    return {
      messageWithContext: null,
      aiChatShown: false
    };
  },
  actions: {
    addMessageWithContext: function addMessageWithContext(message) {
      this.aiChatShown = true;
      this.messageWithContext = message;
    },
    resetAiStore: function resetAiStore() {
      this.messageWithContext = null;
      this.aiChatShown = false;
    },
    openAIAssistant: function openAIAssistant() {
      this.aiChatShown = true;
    }
  }
});