import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_bar_chart = _resolveComponent("bar-chart");
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createBlock(_component_export_frame, {
    "use-export": $props.useDefaultExport
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", {
        style: _normalizeStyle($options.containerStyles)
      }, [(_openBlock(), _createBlock(_component_bar_chart, {
        ref: "barChart",
        key: $data.chartKey,
        data: $options.chartData,
        options: $options.defaultOptions,
        plugins: $options.pluginsInUse
      }, null, 8 /* PROPS */, ["data", "options", "plugins"]))], 4 /* STYLE */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["use-export"]);
}