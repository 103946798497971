import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getActivityType(id) {
  return handleRequestResponse(Http.get("/activitytypes/".concat(id)));
}
export function getAll(params) {
  var url = '/activitytypes';
  if (params) {
    url += params;
  }
  return handleRequestResponse(Http.get(url));
}
export function editActivityType(id, data) {
  return handleRequestResponse(Http.patch("/activitytypes/".concat(id), data));
}
export function createActivityType(data) {
  return handleRequestResponse(Http.post('/activitytypes', data));
}
export function deleteActivityType(id) {
  return handleRequestResponse(Http.delete("/activitytypes/".concat(id)));
}