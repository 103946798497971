import Matrix from '@/components/Molecules/Risk/Matrix';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { RiskGridHelperMixin } from '@/mixins/RiskGridHelperMixin';
import { getRisksFromIdArray } from '@/api/risk/risk';
import { getScoring } from '@/api/risk/scoring';
import { RiskMatrixDefaultSettings, GradientTypes } from '@/constants/admin/RiskMatrixSettings';
import { RiskColorName, StatusColorMapping } from '@/constants/risks/RisksReports';
export default {
  name: 'RiskGrid',
  introduction: 'A complete grid for risks with labels, colors and dots',
  description: 'The outmost risk matrix vue component',
  token: '<risk-grid :risk-grid-data="array"/>',
  components: {
    Matrix: Matrix,
    ButtonsRow: ButtonsRow
  },
  mixins: [RiskGridHelperMixin],
  props: {
    riskGridData: {
      note: 'This is an object with all the data needed for the risk matrix to work',
      type: Object,
      required: true
    },
    colorDirection: {
      note: 'Change the color arrangement. Can either be "diagonal", "vertical" or "horizontal"',
      type: String,
      default: GradientTypes.diagonal
    },
    scoringId: {
      type: Number,
      required: false,
      default: 0,
      note: 'Id of the scoring period. This sets the axis labels and values'
    },
    riskIds: {
      note: 'The risks to be shown. If set, the RiskGrid will load data via REST',
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    gridBoxSize: {
      type: Number,
      default: 100,
      note: 'The size of the individual boxes in the grid'
    },
    withoutZAxis: {
      type: Boolean,
      required: false,
      default: false,
      note: 'No Z Axis at all ? (In Risk Reports)'
    },
    showPreviousPositionLabel: {
      type: String,
      required: false,
      default: 'Show previous position'
    },
    periods: {
      type: Object,
      required: false,
      default: null
    },
    enableZoom: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Enable zoom buttons?'
    },
    zoomRange: {
      type: Array,
      required: false,
      default: function _default() {
        return [70, 140];
      },
      note: 'Min/max zoom range'
    },
    zoomStep: {
      type: Number,
      required: false,
      default: 10,
      note: 'Step for one zoom click (10%)'
    },
    enablePrevPosition: {
      type: Boolean,
      default: true
    },
    isGridClickable: {
      type: Boolean,
      required: false,
      default: true,
      note: 'show the check mark on the grid'
    },
    dotLabelHoverFunction: {
      type: Function,
      default: null,
      note: 'Function to generate label on hover'
    },
    startColor: {
      type: String,
      required: false,
      default: RiskMatrixDefaultSettings.startColor
    },
    endColor: {
      type: String,
      required: false,
      default: RiskMatrixDefaultSettings.endColor
    },
    customHeatmapOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    dotConnectorEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'show a lines connecting two points with the same id'
    },
    axisWithLabels: {
      type: Boolean,
      required: false,
      default: true,
      note: 'show labels on axis'
    },
    showLines: {
      type: Boolean,
      default: false
    },
    areDotsCenterAligned: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onPeriodChanged', 'onStateChanged', 'zoom', 'togglePreviousPosition'],
  data: function data() {
    return {
      checked: false,
      grid: [[{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}]],
      xLabels: [],
      yLabels: [],
      gridBoxSizeUser: this.gridBoxSize,
      currentZoomPercentage: 100,
      minZoomPercentage: this.zoomRange[0],
      maxZoomPercentage: this.zoomRange[1],
      translate: {
        reset: this.$trans('Reset')
      }
    };
  },
  computed: {
    computedLabels: function computedLabels() {
      return {
        xAxisLabel: this.riskGridData.xLabel,
        yAxisLabel: this.riskGridData.yLabel,
        xLabels: this.axisWithLabels ? this.xLabels : [],
        yLabels: this.axisWithLabels ? this.yLabels : []
      };
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: '-',
        type: 'util',
        buttonClasses: {
          _active: this.currentZoomPercentage === this.minZoomPercentage
        },
        clickEvent: this.decreaseSize
      }, {
        text: '+',
        type: 'util',
        buttonClasses: {
          _active: this.currentZoomPercentage === this.maxZoomPercentage
        },
        clickEvent: this.increaseSize
      }, {
        text: this.translate.reset,
        type: 'util',
        buttonClasses: {
          _active: this.currentZoomPercentage === 100
        },
        clickEvent: this.restoreSize
      }];
    }
  },
  watch: {
    'riskGridData.dots': {
      deep: true,
      handler: function handler() {
        this.loadRiskGrid();
      }
    },
    gridBoxSize: function gridBoxSize(val) {
      this.gridBoxSizeUser = val;
    }
  },
  mounted: function mounted() {
    this.loadRiskGrid();
  },
  methods: {
    loadRiskGrid: function loadRiskGrid() {
      var _this = this;
      this.yLabels = this.riskGridData.gridSizeY;
      this.xLabels = this.riskGridData.gridSizeX;
      if (this.colorDirection === GradientTypes.vertical) {
        this.yLabels = [{
          label: '1-3'
        }, {
          label: '4-6'
        }, {
          label: '8-12'
        }, {
          label: '15-16'
        }, {
          label: '20-25'
        }];
      }
      this.initiateGrid();

      // If risks were given to the RiskGrid as dots
      this.riskGridData.dots.forEach(function (dot) {
        if (_this.colorDirection === GradientTypes.vertical) {
          var xPos = dot.z;
          var yPos = _this.rpiToAxisPos(dot.rpi);
          if (xPos !== null && xPos !== 0 && yPos !== 0) {
            if (typeof _this.grid[yPos - 1] !== 'undefined' && typeof _this.grid[yPos - 1][xPos - 1] !== 'undefined') {
              _this.grid[yPos - 1][xPos - 1].push(dot);
            }
          }
        }
        if (_this.colorDirection === GradientTypes.diagonal) {
          if (typeof _this.grid[dot.y - 1] !== 'undefined' && typeof _this.grid[dot.y - 1][dot.x - 1] !== 'undefined') {
            _this.grid[dot.y - 1][dot.x - 1].push(dot);
          }
        }
      });

      // If risks were given to the RiskGrid as ids, they have to be loaded
      if (this.riskIds.length > 0) {
        getRisksFromIdArray(this.riskIds).then(function (data) {
          data.list.forEach(function (risk) {
            var dot = {
              id: risk.rno,
              title: risk.title,
              rpi: risk.scores.X * risk.scores.Y,
              scores: risk.scores,
              scoresPrevious: risk.scoresPrevious,
              xNow: risk.scores.X,
              yNow: risk.scores.Y,
              xBefore: risk.scoresPrevious.X,
              yBefore: risk.scoresPrevious.Y,
              gridStatus: risk.gridStatus,
              color: 'green'
            };
            if (!_this.withoutZAxis) {
              dot.color = _this.setDotColor(dot, _this.colorDirection);
            }
            if (risk.scores.Y <= _this.grid.length && risk.scores.Y !== 0) {
              if (risk.scores.X <= _this.grid[risk.scores.Y - 1].length && risk.scores.X !== 0) {
                if (_this.colorDirection === GradientTypes.vertical) {
                  dot.xBefore = risk.scoresPrevious.Z;
                  dot.yBefore = _this.rpiToAxisPos(risk.scoresPrevious.X * risk.scoresPrevious.Y);
                  dot.xNow = risk.z_axis_score;
                  dot.yNow = _this.rpiToAxisPos(dot.rpi);
                  if (dot.xNow !== null && dot.xNow !== 0 && dot.yNow !== 0) {
                    _this.grid[dot.yNow - 1][dot.xNow - 1].push(dot);
                  }
                } else {
                  if (risk.scores.Z !== null || _this.withoutZAxis) {
                    _this.grid[risk.scores.Y - 1][risk.scores.X - 1].push(dot);
                  }
                }
              }
            }
          });
          if (!_this.withoutZAxis) {
            getScoring(_this.scoringId).then(function (data) {
              var result = Object.keys(data.z_axis_values).map(function (key) {
                return data.z_axis_values[key];
              });
              for (var i = 0; i < result.length; i++) {
                _this.xLabels[i + 1] = {
                  index: i + 1,
                  label: result[i].value
                };
              }
            });
          }
        });
      }
    },
    setDotColor: function setDotColor(dot, colorDirection) {
      if (colorDirection === GradientTypes.diagonal) {
        switch (dot.scores.Z) {
          case 1:
            return RiskColorName.GREEN;
          case 2:
            return RiskColorName.LIGHTBLUE;
          case 3:
            return RiskColorName.YELLOW;
          case 4:
            return RiskColorName.RISMABLUE;
          case 5:
          case 6:
            return RiskColorName.RED;
        }
      } else {
        return StatusColorMapping[dot.gridStatus];
      }
    },
    increaseSize: function increaseSize() {
      if (this.currentZoomPercentage < this.maxZoomPercentage) {
        this.currentZoomPercentage += this.zoomStep;
        this.setSize();
      }
    },
    decreaseSize: function decreaseSize() {
      if (this.currentZoomPercentage > this.minZoomPercentage) {
        this.currentZoomPercentage -= this.zoomStep;
        this.setSize();
      }
    },
    setSize: function setSize() {
      this.gridBoxSizeUser = this.gridBoxSize * (this.currentZoomPercentage / 100);
      this.emitZoom();
    },
    restoreSize: function restoreSize() {
      this.currentZoomPercentage = 100;
      this.gridBoxSizeUser = this.gridBoxSize;
      this.emitZoom();
    },
    emitZoom: function emitZoom() {
      this.$emit('zoom', {
        zoomPercentage: this.currentZoomPercentage
      });
    }
  }
};