import { useSettingsStore } from '@/Store/settingsStore';
import i18n from '@/i18n';
import { capitalize } from '@/utils';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export function getInformationAssetsTitle() {
  var title = '';
  var ia = 'Information Assets';
  try {
    title = useSettingsStore().getValue('feature.information_assets_name') || ia;
  } catch (_unused) {
    return i18n.t(ia);
  }
  return title === ia ? i18n.t(title) : title;
}
export function getInformationAssetsType(iaData, type) {
  if (type === AssetsTypes.CUSTOM) {
    return iaData.activityType.label;
  }
  var str = capitalize(type);
  if (str === 'Companies') {
    return i18n.t('Company');
  }
  if (str.slice(-1) === 's') {
    str = str.slice(0, -1);
  }
  return i18n.t(str);
}