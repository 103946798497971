import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_quiz_questionnaire = _resolveComponent("quiz-questionnaire");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_button, {
    text: "Open",
    type: "util",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.hasQuizQuestionnaire = true;
    })
  }), $data.hasQuizQuestionnaire ? (_openBlock(), _createBlock(_component_quiz_questionnaire, {
    key: 0,
    quiz: $data.quiz,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.hasQuizQuestionnaire = false;
    }),
    "onCreate:quiz": _cache[2] || (_cache[2] = function ($event) {
      return $data.hasQuizQuestionnaire = false;
    })
  }, null, 8 /* PROPS */, ["quiz"])) : _createCommentVNode("v-if", true)]);
}