import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/svg/ai_attached.svg';
var _hoisted_1 = {
  key: 1,
  class: "flex flex-col whitespace-pre-line text-sm font-normal leading-5 break-words"
};
var _hoisted_2 = {
  key: 0,
  class: "flex items-center mb-4"
};
var _hoisted_3 = {
  class: "glob-l2 pl-4"
};
var _hoisted_4 = {
  class: "glob-l2-sb"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 1,
  class: "mt-2"
};
var _hoisted_7 = {
  key: 2,
  class: "ml-auto"
};
var _hoisted_8 = {
  key: 0,
  class: "glob-l1 whitespace-pre-line leading-5 inline-block rm-break-word"
};
var _hoisted_9 = {
  key: 3,
  class: "flex items-center mt-4"
};
var _hoisted_10 = ["title", "disabled"];
var _hoisted_11 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_chat_tag = _resolveComponent("chat-tag");
  var _component_typing_indicator = _resolveComponent("typing-indicator");
  var _component_markdown_renderer = _resolveComponent("markdown-renderer");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", {
      'justify-end': $options.isUserRole
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(['mt-4 p-4 w-fit relative', $options.styleByRole])
  }, [$props.isDeletable ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "group absolute rounded-full border bg-rm-white border-rm-grey -right-2 -top-2 cursor-pointer w-6 h-6 hover:border-rm-signal-red flex justify-center items-center",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('delete:message');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    height: "14",
    width: "14",
    class: "stroke-2 text-rm-signal-grey-dark group-hover:text-rm-signal-red"
  })])) : _createCommentVNode("v-if", true), $options.isCustomRole ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$props.source ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("img", {
    src: _imports_0,
    width: "18",
    height: "18"
  }, null, -1 /* HOISTED */)), _createElementVNode("p", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($options.sourceDetails.projectName) + ":", 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($options.sourceDetails.fieldName), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "glob-l1 whitespace-pre-line leading-5",
    innerHTML: $options.formattedMessage
  }, null, 8 /* PROPS */, _hoisted_5), $props.quickQuestions.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.quickQuestions, function (question) {
    return _openBlock(), _createBlock(_component_chat_tag, {
      key: question,
      class: "mr-2 mt-2",
      active: question.selected,
      onClick: function onClick($event) {
        return $options.handleQuickQuestionClick(question);
      }
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(question.label), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "onClick"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [!$options.formattedMessage ? (_openBlock(), _createBlock(_component_typing_indicator, {
    key: 0
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$options.isUserRole ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.message), 1 /* TEXT */)) : (_openBlock(), _createBlock(_component_markdown_renderer, {
    key: 1,
    class: "glob-l1 break-words text-inherit leading-5",
    markdown: $options.formattedMessage,
    onClick: $options.handleCitationClick
  }, null, 8 /* PROPS */, ["markdown", "onClick"]))], 64 /* STABLE_FRAGMENT */))])), $options.isSystemRole && $options.formattedMessage ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("button", {
    class: "rounded-lg text-token-text-secondary",
    title: $data.translate.copy,
    disabled: $data.isCopying,
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.copyToClipboard($props.message);
    })
  }, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-2", $options.copyIconStyles]),
    icon: $data.isCopying ? 'check' : 'copy'
  }, null, 8 /* PROPS */, ["class", "icon"])], 8 /* PROPS */, _hoisted_10), $props.reloadMessageEnabled ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "rounded-lg text-token-text-secondary ml-4",
    title: $data.translate.retry,
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('reload:message');
    })
  }, [_createVNode(_component_feather_icon, {
    class: "text-rm-grey hover:text-rm-signal-grey-dark stroke-2",
    icon: "rotate-cw"
  })], 8 /* PROPS */, _hoisted_11)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */);
}