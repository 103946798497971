import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "tree-wrap relative"
};
var _hoisted_2 = {
  id: "root",
  "data-tree": "1"
};
var _hoisted_3 = {
  key: 1,
  class: "mt-12"
};
var _hoisted_4 = {
  key: 0,
  class: "mr-8 min-w-15rem w-80"
};
var _hoisted_5 = {
  key: 1,
  class: "w-48 mr-12 xl:mr-56"
};
var _hoisted_6 = {
  key: 2,
  class: "mr-12 xl:mr-56 mt-4"
};
var _hoisted_7 = {
  class: "flex items-center"
};
var _hoisted_8 = {
  class: "mr-1"
};
var _hoisted_9 = {
  key: 0,
  class: "flex items-center"
};
var _hoisted_10 = {
  class: "mr-1"
};
var _hoisted_11 = {
  key: 0,
  class: "text-center noResults"
};
var _hoisted_12 = {
  key: 1,
  id: "loader"
};
var _hoisted_13 = {
  id: "tree-jquery"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_process_library_node_creation_modal = _resolveComponent("process-library-node-creation-modal");
  var _component_compliance_creation_modal = _resolveComponent("compliance-creation-modal");
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_save_image = _resolveComponent("save-image");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.treeTitle !== '' ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $props.treeTitle
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $data.showProcessNodeCreationModal ? (_openBlock(), _createBlock(_component_process_library_node_creation_modal, {
    key: 1,
    "tree-id": $props.treeKey,
    "parent-id": $data.parentId,
    "create-params": $data.params,
    "node-title": $data.newNodeTitle,
    "is-frameworks-hidden": $data.isFrameworksHidden,
    onCreate: $options.onNodeCreate,
    onDismiss: $options.dismissNodeCreateModal
  }, null, 8 /* PROPS */, ["tree-id", "parent-id", "create-params", "node-title", "is-frameworks-hidden", "onCreate", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showComplianceNodeCreationModal ? (_openBlock(), _createBlock(_component_compliance_creation_modal, {
    key: 2,
    "project-id": $props.projectId,
    "parent-id": $data.parentId,
    "preselected-frameworks": $data.preselectedFrameworks,
    "create-params": $data.params,
    "node-title": $data.newNodeTitle,
    "dpo-enabled": $props.isGdpr,
    onCreate: $options.onNodeCreate,
    onDismiss: $options.dismissNodeCreateModal
  }, null, 8 /* PROPS */, ["project-id", "parent-id", "preselected-frameworks", "create-params", "node-title", "dpo-enabled", "onCreate", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showEditNodeModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 3,
    header: $data.translate.enterTitle,
    type: "small",
    "show-buttons": true,
    "button-ok-text": $data.isCreateMode ? $data.translate.create : $data.translate.update,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onNodeEdit,
    onDismiss: $options.dismissEditNodeModal
  }, {
    body: _withCtx(function () {
      return [$data.showUpdateTitleEmptyNotification && !$data.updatedTitle ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.showUpdateTitleEmptyNotification = false;
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.translate.titleCannotBeEmpty), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.updatedTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.updatedTitle = $event;
        }),
        modelModifiers: {
          trim: true
        },
        "focus-on-mount": true,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-start", $props.treeRights ? 'justify-between' : 'justify-end'])
  }, [$props.treeRights ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $props.addNewNodeLabel || $data.translate.newNode,
    type: "save",
    class: "new-node-button mt-4",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.addNode($data.root.id);
    })
  }, null, 8 /* PROPS */, ["text"])) : (_openBlock(), _createElementBlock("span", _hoisted_3)), _createElementVNode("div", {
    class: _normalizeClass(["absolute right-0 flex items-start max-w-17/20", $props.showLevelsClasses])
  }, [$props.hasOrganisationsSelector ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_select, {
    title: $data.translate.organisations,
    options: _ctx.organisations,
    placeholder: $data.translate.noFiltersApplied,
    "selected-options": $data.selectedOrganisations,
    "label-key": "visible_name",
    onSelected: $options.setSelectedOrgs
  }, null, 8 /* PROPS */, ["title", "options", "placeholder", "selected-options", "onSelected"])])) : _createCommentVNode("v-if", true), $props.levelSelect ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_single_select, {
    title: $data.translate.showLevels,
    options: $props.allLevels,
    placeholder: $data.translate.visibleLevels,
    "onUpdate:modelValue": $options.changeLevel
  }, null, 8 /* PROPS */, ["title", "options", "placeholder", "onUpdate:modelValue"])])) : _createCommentVNode("v-if", true), !$props.disableProgressShown ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.progressShown,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_8, _toDisplayString($data.translate.question), 1 /* TEXT */), _createVNode(_component_risma_toggle, {
    class: "mr-1",
    onToggle: $options.toggleQG
  }, null, 8 /* PROPS */, ["onToggle"]), _createElementVNode("p", null, _toDisplayString($data.translate.gap), 1 /* TEXT */)]), $props.dataflowToolbar ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.nodesContainingDataFlow,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_10, _toDisplayString($data.translate.hide), 1 /* TEXT */), _createVNode(_component_risma_toggle, {
    class: "mr-1",
    onToggle: $options.toggleDataflow
  }, null, 8 /* PROPS */, ["onToggle"]), _createElementVNode("p", null, _toDisplayString($data.translate.show), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createVNode(_component_save_image, {
    class: "mt-4"
  })], 2 /* CLASS */)], 2 /* CLASS */), $options.noResults ? (_openBlock(), _createElementBlock("h3", _hoisted_11, _toDisplayString($data.translate.noNodesToShow), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_loading_indicator_large)])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    id: "mytree",
    class: _normalizeClass([$props.classNameToTree, {
      hidden: $data.loading === true,
      'dataflow-icon-shown': $data.dataflowIconShown
    }])
  }, [_createElementVNode("div", _hoisted_13, [_withDirectives(_createElementVNode("h3", null, _toDisplayString($data.translate.loading), 513 /* TEXT, NEED_PATCH */), [[_vShow, $data.loading]])])], 2 /* CLASS */)]), _cache[4] || (_cache[4] = _createElementVNode("canvas", {
    id: "canvas",
    width: "0",
    height: "0"
  }, null, -1 /* HOISTED */)), (_openBlock(), _createBlock(_component_preview_modal_link, {
    ref: "preview",
    key: $data.previewModalLinkKey,
    activity: $data.selectedActivity,
    "selected-route": $data.previewSelectedRoute,
    onUpdated: $options.updateFromPreviewModalLink,
    onDeleteItem: $options.deleteItem,
    onDismissModal: $options.onPreviewModalDismiss
  }, {
    default: _withCtx(function () {
      return _cache[3] || (_cache[3] = [_createElementVNode("span", null, null, -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "selected-route", "onUpdated", "onDeleteItem", "onDismissModal"]))]);
}