import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "mb-1 glob-h1"
};
var _hoisted_2 = {
  class: "mb-8 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_3 = {
  class: "underline"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  class: "w-350px mb-6"
};
var _hoisted_6 = {
  class: "glob-h3"
};
var _hoisted_7 = {
  class: "flex items-center"
};
var _hoisted_8 = {
  class: "glob-h3-sr"
};
var _hoisted_9 = {
  key: 0,
  class: "flex glob-h3-sr"
};
var _hoisted_10 = {
  class: ""
};
var _hoisted_11 = {
  key: 1
};
var _hoisted_12 = {
  class: "inline break-words"
};
var _hoisted_13 = {
  class: "underline"
};
var _hoisted_14 = {
  key: 1,
  class: "mr-1 contents"
};
var _hoisted_15 = {
  key: 0,
  class: "cursor-pointer w-full"
};
var _hoisted_16 = {
  key: 1
};
var _hoisted_17 = {
  key: 0,
  class: "cursor-pointer w-full"
};
var _hoisted_18 = {
  key: 1
};
var _hoisted_19 = {
  key: 1,
  class: "mt-6 flex items-center justify-end"
};
var _hoisted_20 = {
  class: "mr-4 glob-h3-sr text-rm-text"
};
var _hoisted_21 = {
  class: "flex items-center"
};
var _hoisted_22 = {
  class: "flex items-center"
};
var _hoisted_23 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_financial_definitions_edit_modal = _resolveComponent("financial-definitions-edit-modal");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_download_link = _resolveComponent("download-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _directive_tooltip = _resolveDirective("tooltip");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, _toDisplayString($data.translate.registerOfInformation), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, _toDisplayString($data.translate.chooseTheParentCompanyYourRegister), 1 /* TEXT */), $data.showDefinitionModal ? (_openBlock(), _createBlock(_component_financial_definitions_edit_modal, {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDefinitionModal = false;
    })
  })) : _createCommentVNode("v-if", true), $options.globalErrorDataset.length > 0 ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.globalErrorDataset, function (record, index) {
        return _openBlock(), _createElementBlock("div", {
          key: "dora_".concat(index),
          class: "flex"
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record, function (item, i) {
          return _openBlock(), _createElementBlock("div", {
            key: "str_".concat(i),
            class: "mr-1 mb-1"
          }, [item.objType ? (_openBlock(), _createBlock(_component_preview_modal_link, {
            key: 0,
            activity: item,
            onDismissModal: _cache[1] || (_cache[1] = function ($event) {
              return $options.getExtendedData(true);
            })
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])) : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(item), 1 /* TEXT */))]);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_single_select, {
    "model-value": $setup.selectedCompanyId,
    title: $data.translate.parentCompany,
    options: $options.companyOptions,
    "with-reset": true,
    placeholder: $data.translate.selectParentCompany,
    "onUpdate:modelValue": $options.uploadDoraReport
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "onUpdate:modelValue"])]), $setup.selectedCompanyId ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [$data.isLoaded ? (_openBlock(), _createBlock(_component_static_table, {
    key: 0,
    "dataset-table": $options.dataset,
    columns: $options.columns,
    class: "glob-l1 border border-rm-grey p-4 pb-2 rounded-md"
  }, {
    id: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("span", _hoisted_6, _toDisplayString(slotData), 1 /* TEXT */)];
    }),
    name: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(slotData.name), 1 /* TEXT */), slotData.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: slotData.icon,
        width: 18,
        height: 18,
        class: "ml-2 text-rm-text stroke-2 cursor-pointer",
        onClick: slotData.action
      }, null, 8 /* PROPS */, ["icon", "onClick"])) : _createCommentVNode("v-if", true)])];
    }),
    status: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [!slotData.length ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "bg-rm-signal-green rounded-full w-4 h-4 mr-2"
      }, null, -1 /* HOISTED */)), _createElementVNode("span", _hoisted_10, _toDisplayString($data.translate.ready), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotData, function (record, index) {
        return _openBlock(), _createElementBlock("div", {
          key: "dora_".concat(index),
          class: _normalizeClass(["flex glob-h3-sr", {
            'mb-2': index !== slotData.length - 1
          }])
        }, [_cache[4] || (_cache[4] = _createElementVNode("span", {
          class: "bg-rm-signal-red rounded-full min-w-4 w-4 h-4 mr-2"
        }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record, function (item, i) {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: "str_".concat(i)
          }, [item.objType ? (_openBlock(), _createBlock(_component_preview_modal_link, {
            key: 0,
            activity: item,
            class: "mr-1 contents",
            "selected-route": $options.getSelectedRoute(item.url),
            onDismissModal: _cache[2] || (_cache[2] = function ($event) {
              return $options.getExtendedData(true);
            })
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("span", _hoisted_13, _toDisplayString(item.title), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "selected-route"])) : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(item), 1 /* TEXT */))], 64 /* STABLE_FRAGMENT */);
        }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */))]))];
    }),
    excel: _withCtx(function (_ref4) {
      var slotData = _ref4.slotData;
      return [slotData ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_download_link, {
        class: "ml-2",
        "file-src": slotData.url,
        "use-content-disposition": true,
        disabled: !slotData.validated,
        title: $data.translate.downloadExcel
      }, null, 8 /* PROPS */, ["file-src", "disabled", "title"])])) : (_openBlock(), _createElementBlock("div", _hoisted_16))];
    }),
    csv: _withCtx(function (_ref5) {
      var slotData = _ref5.slotData;
      return [slotData ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_download_link, {
        class: "ml-2",
        "file-src": slotData.url,
        "use-content-disposition": true,
        disabled: !slotData.validated,
        title: $data.translate.downloadCsv
      }, null, 8 /* PROPS */, ["file-src", "disabled", "title"])])) : (_openBlock(), _createElementBlock("div", _hoisted_18))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"])) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, _toDisplayString($data.translate.downloadTheFullRegisterOfInformationReport), 1 /* TEXT */), _createVNode(_component_risma_button, {
    text: "",
    type: "save",
    class: "text-rm-white mr-2",
    "no-paddings": true
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_download_link, {
        class: "py-2.5 px-15px",
        "file-src": $options.getDownloadAllUrl('xlsx'),
        "use-content-disposition": true,
        title: $data.translate.downloadExcel
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_21, [_createVNode(_component_feather_icon, {
            icon: "download",
            class: "stroke-2 mr-2"
          }), _createElementVNode("span", null, _toDisplayString($data.translate.excel), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["file-src", "title"])];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risma_button, {
    text: "",
    type: "save",
    class: "text-rm-white mr-2",
    "no-paddings": true
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_download_link, {
        class: "py-2.5 px-15px",
        "file-src": $options.getDownloadAllUrl('xml'),
        "use-content-disposition": true,
        title: "".concat($data.translate.download, " FIONA XML")
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_22, [_createVNode(_component_feather_icon, {
            icon: "download",
            class: "stroke-2 mr-2"
          }), _cache[5] || (_cache[5] = _createElementVNode("span", null, "FIONA XML", -1 /* HOISTED */))])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["file-src", "title"])];
    }),
    _: 1 /* STABLE */
  }), _withDirectives((_openBlock(), _createBlock(_component_risma_button, {
    text: "",
    type: "save",
    "no-paddings": true,
    disabled: $options.downloadZipDisabled
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_download_link, {
        class: "py-2.5 px-15px",
        "file-src": $options.getDownloadAllUrl('zip'),
        "use-content-disposition": true,
        title: $data.translate.zip,
        disabled: $options.downloadZipDisabled
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_23, [_createVNode(_component_feather_icon, {
            icon: "download",
            class: "stroke-2 mr-2"
          }), _createElementVNode("span", null, _toDisplayString($data.translate.zip), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["file-src", "title", "disabled"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"])), [[_directive_tooltip, $options.downloadZipDisabled ? $data.translate.youMustResolveAllErrors : '', "bottom"]])])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}