import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getWorkflows() {
  var module = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get("admin/workflow".concat(module ? '?module=' + module : '')));
}
export function getWorkflowById(id) {
  return handleRequestResponse(Http.get("admin/workflow/".concat(id)));
}
export function createWorkflow(data) {
  return handleRequestResponse(Http.post('admin/workflow', data));
}
export function updateWorkflow(id, data) {
  return handleRequestResponse(Http.patch("admin/workflow/".concat(id), data));
}
export function deleteWorkflow(id, params) {
  return handleRequestResponse(Http.delete("admin/workflow/".concat(id).concat(params)));
}
export function getWorkflowStatuses() {
  return handleRequestResponse(Http.get('admin/workflowstatus'));
}
export function getWorkflowStatusesByModule(module) {
  var activityTypeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return handleRequestResponse(Http.get("admin/workflowstatus/".concat(module).concat(activityTypeId ? '?activityTypeId=' + activityTypeId : '')));
}
export function createWorkflowStatusByModule(module, data) {
  return handleRequestResponse(Http.post("admin/workflowstatus/".concat(module, "/status"), data));
}
export function updateWorkflowStatus(module, data) {
  return handleRequestResponse(Http.patch("admin/workflowstatus/".concat(module, "/status/").concat(data.id), data));
}
export function updateWorkflowStatusesByModule(module, data) {
  return handleRequestResponse(Http.patch("admin/workflowstatus/".concat(module), data));
}
export function deleteWorkflowStatusByModule(module, id, data) {
  return handleRequestResponse(Http.delete("admin/workflowstatus/".concat(module, "/status/").concat(id), {
    data: data
  }));
}