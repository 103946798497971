import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "card-wrapper"
};
var _hoisted_2 = {
  class: "card card-information-assets"
};
var _hoisted_3 = {
  class: "traffic-light"
};
var _hoisted_4 = {
  class: "asset-type"
};
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["traffic-light-wrapper ".concat($props.informationAsset.trafficLight === 1 ? 'red' : ''), "items-center"])
  }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_traffic_light, {
    color: $options.getTrafficLightColorByNumber
  }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("div", null, [_createVNode(_component_preview_modal_link, {
    activity: $props.informationAsset
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.informationAsset.name || $props.informationAsset.title
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])])], 2 /* CLASS */), _createElementVNode("div", _hoisted_4, _toDisplayString($props.assetType), 1 /* TEXT */), _createElementVNode("div", {
    class: "asset-description",
    innerHTML: $options.truncatedDescription
  }, null, 8 /* PROPS */, _hoisted_5)])]);
}