import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_feather_icon, {
    icon: "clock"
  }), _createVNode(_component_feather_icon, {
    width: "40",
    height: "40",
    icon: "user",
    class: "text-black"
  }), _createVNode(_component_feather_icon, {
    width: "60",
    height: "60",
    icon: "settings",
    class: "text-blue-750 stroke-2"
  })]);
}