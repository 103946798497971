// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper[data-v-1a67f295] {
  -ms-box-shadow: 0 12px 8px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 0 12px 8px 0 rgba(34, 60, 80, 0.2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
