import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded rounded-l-none p-4"
};
var _hoisted_2 = {
  class: "flex flex-wrap justify-center lg:flex-nowrap mb-12 -mx-4"
};
var _hoisted_3 = {
  class: "px-4 w-650px"
};
var _hoisted_4 = {
  class: "mb-4 text-white bg-blue-250 py-2 px-3"
};
var _hoisted_5 = {
  class: "px-4 lg:flex-grow w-650px min-h-375px"
};
var _hoisted_6 = {
  class: "mb-4 text-white bg-blue-250 py-2 px-3"
};
var _hoisted_7 = {
  key: 2,
  class: "mb-12"
};
var _hoisted_8 = {
  class: "flex items-center text-white bg-blue-250 mb-2 py-2 px-3"
};
var _hoisted_9 = {
  key: 3,
  class: "mb-4"
};
var _hoisted_10 = {
  class: "mb-4 text-white bg-blue-250 py-2 px-3"
};
var _hoisted_11 = {
  key: 4
};
var _hoisted_12 = {
  class: "flex items-center bg-blue-250"
};
var _hoisted_13 = {
  class: "flex-grow text-white py-2 px-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_selector_bar = _resolveComponent("risma-selector-bar");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_gap_table = _resolveComponent("gap-table");
  var _component_risma_excel = _resolveComponent("risma-excel");
  var _component_gap_summary_table = _resolveComponent("gap-summary-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.errorMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans(_ctx.errorMessage)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_risma_selector_bar, {
    key: 1,
    ref: "filter",
    "project-id": $props.projectId,
    selectors: $options.selectors,
    users: _ctx.users,
    orgs: _ctx.orgs,
    tags: _ctx.tags,
    nodes: _ctx.nodes,
    onListChange: $options.handleListChange
  }, null, 8 /* PROPS */, ["project-id", "selectors", "users", "orgs", "tags", "nodes", "onListChange"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h2", _hoisted_4, _toDisplayString($data.translate.gapOverview), 1 /* TEXT */), _ctx.loadingStats ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_doughnut_chart, {
    datasets: _ctx.gapOverviewData,
    labels: _ctx.gapOverviewLabels,
    "show-data-labels": true,
    "percentage-value": false,
    onChartClicked: _ctx.handleClickSlice
  }, null, 8 /* PROPS */, ["datasets", "labels", "onChartClicked"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h2", _hoisted_6, _toDisplayString($data.translate.overviewPrDomain), 1 /* TEXT */), _ctx.loadingStats ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_bar_chart, {
    key: 1,
    labels: _ctx.gapDomains.labels,
    datasets: _ctx.gapDomains.datasets,
    "show-legend": true,
    "show-data-labels": true,
    "items-per-scroll": 5,
    "min-bar-length": 5,
    type: "Stacked"
  }, null, 8 /* PROPS */, ["labels", "datasets"]))])]), _ctx.isTableShown ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("h2", _hoisted_8, [_createVNode(_component_traffic_light, {
    color: $data.sliceSelected.color,
    title: _ctx.$trans($data.sliceSelected.label),
    class: "mr-1"
  }, null, 8 /* PROPS */, ["color", "title"]), _createElementVNode("span", null, _toDisplayString(_ctx.$trans($data.sliceSelected.label)), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "x",
    class: "text-white stroke-3 cursor-pointer ml-auto",
    onClick: _ctx.clearSliceSelected
  }, null, 8 /* PROPS */, ["onClick"])]), !_ctx.loadingGaps ? (_openBlock(), _createBlock(_component_gap_table, {
    key: 0,
    "project-id": $props.projectId,
    gaps: _ctx.formattedGaps,
    "gap-article-map": _ctx.gapDashboardData.gapArticleMap,
    "show-risk-assessment": $options.enableGapRiskModule && $options.showRiskAssessment
  }, null, 8 /* PROPS */, ["project-id", "gaps", "gap-article-map", "show-risk-assessment"])) : _createCommentVNode("v-if", true), _ctx.loadingGaps ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.showGapArticleTypes && !_ctx.loadingStats ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("h2", _hoisted_10, _toDisplayString($data.translate.overviewPrGapType), 1 /* TEXT */), _createVNode(_component_risma_bar_chart, {
    labels: _ctx.gapTypesBarChart.labels,
    datasets: _ctx.gapTypesBarChart.datasets,
    "show-legend": false,
    "show-data-labels": true,
    "items-per-scroll": 10,
    "y-step-size": 1,
    type: "Stacked"
  }, null, 8 /* PROPS */, ["labels", "datasets"])])) : _createCommentVNode("v-if", true), !$props.stripped ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("h2", _hoisted_13, _toDisplayString($data.translate.generalGapSummary) + " (" + _toDisplayString($options.filteredNodes.length) + ") ", 1 /* TEXT */), _ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_risma_excel, {
    key: 0,
    columns: $options.exportColumns,
    "dataset-export": $options.exportDataset,
    filename: $data.exportFileName,
    class: "mr-3 pt-1 pr-3 rounded-md bg-blue-30"
  }, null, 8 /* PROPS */, ["columns", "dataset-export", "filename"])) : _createCommentVNode("v-if", true)]), !_ctx.isLoadedStaticData ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_gap_summary_table, {
    key: 1,
    columns: $options.gapSummaryColumns,
    "dataset-main": $options.gapSummaryDataset,
    onUpdateFromPreview: _cache[1] || (_cache[1] = function ($event) {
      return $data.updatedInPreview = true;
    }),
    onDismissPreviewModal: $options.onDismissPreview
  }, null, 8 /* PROPS */, ["columns", "dataset-main", "onDismissPreviewModal"]))])) : _createCommentVNode("v-if", true)]);
}