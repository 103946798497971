function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
import { MODULES } from '@/constants/modules';
export default {
  name: 'RismaStepProgressBar',
  introduction: 'RismaStepProgressBar shows progress of the auditflow',
  description: 'After users have send the questionnaire it is outmost important that the users can see the progress/status of the auditflow. ',
  token: '<risma-step-progress-bar :data="information"/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    data: {
      required: true,
      type: [Object, Array],
      note: 'needed information to render the element, it looks like data: { firstElement: { state: ``, titles: []} secondElement: {state: ``, titles: []} ...}. State can be "approved|wait|declined',
      default: function _default() {
        return {
          firstElement: {
            state: 'declined',
            titles: ['it`s empty']
          }
        };
      }
    }
  },
  data: function data() {
    return {
      colors: Colors
    };
  },
  computed: {
    incidentTrafficLights: function incidentTrafficLights() {
      return this.getTrafficLightsForModule(MODULES.INCIDENTS);
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightsForModule'])), {}, {
    getColor: function getColor(element) {
      if (element === 'approved') {
        return this.incidentTrafficLights[1].color;
      } else if (element === 'wait') {
        return this.incidentTrafficLights[0].color;
      } else if (element === 'declined') {
        return this.incidentTrafficLights[3].color;
      }
    },
    getIcon: function getIcon(element) {
      return element == 'approved' ? 'check-circle' : 'x-circle';
    }
  })
};