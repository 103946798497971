// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column-switcher[data-v-44f169ac] {
  position: relative;
}
.column-switcher-label[data-v-44f169ac] {
  cursor: pointer;
  font-size: 16px;
  transition: color 0.2s;
  padding-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.column-switcher-text[data-v-44f169ac] {
  padding-left: 4px;
}
.column-switcher-popup[data-v-44f169ac] {
  position: absolute;
  top: 25px;
  left: 0;
  width: 190px;
  margin-top: 3px;
  padding: 8px 8px 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: #31302f;
  overflow: hidden;
  z-index: 99;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
.column-switcher-popup .column-switcher-list[data-v-44f169ac] {
  list-style: none;
  margin: 0;
  padding: 0;
}
.column-switcher-popup li[data-v-44f169ac] {
  padding: 0.5em 1em;
  margin: 0 0 4px;
  color: #FFFFFF;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.column-switcher-popup li.removed[data-v-44f169ac] {
  background: #4D4D4D;
  text-decoration: line-through;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
