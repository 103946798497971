function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import ExpandedCard from '@/components/Molecules/Cards/ExpandedCard';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { RiskListMixin } from '@/components/Pages/Risks/RiskListMixin';
import { getDataControls } from '@/api/controls/controls';
import { getDailyTeamRisks as _getDailyTeamRisks } from '@/api/risk/risk';
import { getCurrentScoring } from '@/api/risk/scoring';
import { MODULES } from '@/constants/modules';
import { MY_RISKS_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { checkERMByProjectId } from '@/utils/risks/check';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'MyRisks',
  introduction: 'Information risk management / My risks',
  description: 'Information risk management / My risks',
  token: '<my-risks />',
  components: {
    RismaTitle: RismaTitle,
    ExpandedCard: ExpandedCard,
    Notification: Notification,
    PreviewListTile: PreviewListTile,
    CatalogueHeader: CatalogueHeader,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [CommonAssetsMixin, RiskListMixin],
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('my_risks_view_storage', viewTypes.EXPANDED)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      isLoadedError: false,
      activityTypes: [],
      risks: [],
      MODULES: MODULES,
      viewTypes: viewTypes,
      MY_RISKS_VIEWS: MY_RISKS_VIEWS,
      // ERM only
      scoring: {},
      scoringLoaded: false,
      dailyTeamRisks: [],
      sections: [],
      overviewOptionsState: false,
      isOverviewOptionsExpanded: false,
      translate: {
        thereWasAnErrorPleaseTryAgain: this.$trans('There was an error, please, try again'),
        myRisks: this.$trans('My risks'),
        dailyTeamRisks: this.$trans('Daily team risks')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    viewClass: function viewClass() {
      if (this.currentView == 'expanded') {
        return 'preview-wide';
      }
      if (this.currentView == 'tiles') {
        return 'preview';
      }
      if (this.currentView == 'compact') {
        return 'preview-compact';
      }
      return '';
    },
    periodOrScoringTitle: function periodOrScoringTitle() {
      if (this.isERM) {
        return 'ERM';
      }
      return this.periodTitle;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'risk');
    }
  }),
  watch: {
    $route: function $route(to, from) {
      var _this = this;
      if (to.name !== from.name) return;
      var period = this.riskProject.projectPeriods.find(function (period) {
        return period.isCurrent;
      });
      var periodId = period ? period.id : null;
      this.getRisks(this.projectId, periodId, 'filters[my]=1').then(function (risks) {
        _this.risks = _this.formatRisks(risks, _this.threats, _this.levelOfThreats, _this.vulnerabilities, _this.probabilities, _this.controls, _this.initiatives);
      });
      if (this.isERM) {
        this.getDailyTeamRisks().then(function (dailyTeamRisks) {
          _this.dailyTeamRisks = _this.formatRisks(dailyTeamRisks, _this.threats, _this.levelOfThreats, _this.vulnerabilities, _this.probabilities, _this.controls, _this.initiatives);
        });
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([this.getInitiatives(), this.getRiskProjects(), this.getScoringForERM()]).then(function () {
      return Promise.all([_this2.getRisks(_this2.projectId, _this2.periodId, 'filters[my]=1'), _this2.isERM ? _this2.getDailyTeamRisks() : Promise.resolve([])]);
    }).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        risks = _ref2[0],
        dailyTeamRisks = _ref2[1];
      return new Promise(function (resolve) {
        getDataControls().then(function (data) {
          _this2.controls = data.list;
          _this2.risks = _this2.formatRisks(risks, _this2.threats, _this2.levelOfThreats, _this2.vulnerabilities, _this2.probabilities, _this2.controls, _this2.initiatives);
          _this2.risksBackup = _toConsumableArray(_this2.risks);
          _this2.dailyTeamRisks = _this2.formatRisks(dailyTeamRisks, _this2.threats, _this2.levelOfThreats, _this2.vulnerabilities, _this2.probabilities, _this2.controls, _this2.initiatives);
          resolve();
        });
      });
    }).then(function () {
      _this2.isLoaded = true;
    }).catch(function () {
      _this2.isLoadedError = true;
    });
  },
  methods: {
    getDailyTeamRisks: function getDailyTeamRisks() {
      return new Promise(function (resolve, reject) {
        _getDailyTeamRisks().then(function (_ref3) {
          var list = _ref3.list;
          return resolve(list);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    getScoringForERM: function getScoringForERM() {
      var _this3 = this;
      return getCurrentScoring().then(function (scoring) {
        _this3.scoring = scoring;
        _this3.scoringLoaded = true;
      }).catch(function (error) {
        return new Error(error);
      });
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedRisk) {
      var updatedRiskIndex = this.risks.findIndex(function (risk) {
        return +risk.id === +updatedRisk.id;
      });
      if (updatedRiskIndex !== -1) {
        this.risks[updatedRiskIndex] = _objectSpread(_objectSpread({}, this.risks[updatedRiskIndex]), updatedRisk);
      }
      var updatedDailyTeamRiskIndex = this.dailyTeamRisks.findIndex(function (risk) {
        return +risk.id === +updatedRisk.id;
      });
      if (updatedDailyTeamRiskIndex !== -1) {
        var formatedDailyTeamRisk = this.formatRisk(updatedRisk, this.threats, this.levelOfThreats, this.vulnerabilities, this.probabilities, this.controls, this.initiatives);
        this.dailyTeamRisks[updatedDailyTeamRiskIndex] = _objectSpread(_objectSpread({}, this.dailyTeamRisks[updatedRiskIndex]), formatedDailyTeamRisk);
      }
    },
    deleteRiskFromList: function deleteRiskFromList(risk) {
      var riskIndex = this.risks.findIndex(function (riskItem) {
        return riskItem.id === risk.id;
      });
      this.risks.splice(riskIndex, 1);
    }
  }
};