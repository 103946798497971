import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
var _hoisted_2 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
var _hoisted_3 = {
  class: "grid grid-cols-3 gap-x-4 mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_label, {
    title: $options.ictServiceProviderLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleLabelClick('serviceProviders');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.functionIdentifierLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleLabelClick('companyFunctions');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.typeOfIctServicesLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.handleLabelClick('typeOfICTService');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_select, {
    ref: "serviceProviders",
    options: $props.options.serviceProviders,
    "selected-options": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.SERVICE_PROVIDERS],
    placeholder: $data.translate.ictServiceProvider,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.SERVICE_PROVIDERS, $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_risma_select, {
    ref: "companyFunctions",
    options: $props.options.companyFunctions,
    "selected-options": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.FUNCTION_IDS],
    placeholder: $data.translate.functionIdentifier,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.FUNCTION_IDS, $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_risma_select, {
    ref: "typeOfICTService",
    options: $props.options.typeOfICTService,
    "selected-options": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.TYPE_OF_SERVICE],
    placeholder: $data.translate.typeOfIctServices,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.TYPE_OF_SERVICE, $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_label, {
    title: $options.countryOfProvisionLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.handleLabelClick('countries');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.storageOfDataLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[7] || (_cache[7] = function ($event) {
      return $options.handleLabelClick('storageOfData');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.dataLocationLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[8] || (_cache[8] = function ($event) {
      return $options.handleLabelClick('dataLocation');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_select, {
    ref: "countries",
    options: $props.options.countries,
    "selected-options": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.COUNTRY_OF_PROVISION],
    placeholder: $data.translate.ictServicesDelivered,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    searchable: true,
    onSelected: _cache[9] || (_cache[9] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.COUNTRY_OF_PROVISION, $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_single_select, {
    ref: "storageOfData",
    "model-value": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.STORAGE_OF_DATA],
    disabled: $props.readOnly,
    options: $props.options.binaryYesNo,
    placeholder: $data.translate.storageOfData,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.STORAGE_OF_DATA, $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "placeholder"]), _createVNode(_component_risma_select, {
    ref: "dataLocation",
    options: $props.options.countries,
    "selected-options": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.DATA_LOCATION],
    placeholder: $data.translate.dataLocation,
    disabled: $props.readOnly || !$props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.STORAGE_OF_DATA],
    "error-message": $options.dataLocationError,
    class: _normalizeClass({
      '-mb-5': $options.dataLocationError
    }),
    "label-key": "label",
    "track-by": "value",
    searchable: true,
    onSelected: _cache[11] || (_cache[11] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.DATA_LOCATION, $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled", "error-message", "class"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_label, {
    title: $options.managementLocationLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[12] || (_cache[12] = function ($event) {
      return $options.handleLabelClick('dataManagementLocation');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.dataSensitivenessLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[13] || (_cache[13] = function ($event) {
      return $options.handleLabelClick('dataSensitiveness');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_label, {
    title: $options.relianceLevelLabel,
    class: _normalizeClass($options.labelStyles),
    onClick: _cache[14] || (_cache[14] = function ($event) {
      return $options.handleLabelClick('relianceLevel');
    })
  }, null, 8 /* PROPS */, ["title", "class"]), _createVNode(_component_risma_select, {
    ref: "dataManagementLocation",
    options: $props.options.countries,
    "selected-options": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.DATA_MANAGEMENT_LOCATION],
    placeholder: $data.translate.ictServiceProvider,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    searchable: true,
    onSelected: _cache[15] || (_cache[15] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.DATA_MANAGEMENT_LOCATION, $event);
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_single_select, {
    ref: "dataSensitiveness",
    "model-value": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.DATA_SENSITIVENESS],
    disabled: $props.readOnly,
    placeholder: $data.translate.dataSensitiveness,
    options: $props.options.sensitivenessOfData,
    "onUpdate:modelValue": _cache[16] || (_cache[16] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.DATA_SENSITIVENESS, $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "options"]), _createVNode(_component_single_select, {
    ref: "relianceLevel",
    "model-value": $props.selectedOptions[$data.COMPANY_INTERNAL_PROPS.RELIANCE_LEVEL],
    disabled: $props.readOnly,
    placeholder: $data.translate.relianceLevel,
    options: $props.options.relianceLevel,
    "onUpdate:modelValue": _cache[17] || (_cache[17] = function ($event) {
      return $options.handleUpdate($data.COMPANY_INTERNAL_PROPS.RELIANCE_LEVEL, $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "options"])])], 64 /* STABLE_FRAGMENT */);
}