function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import ConfidentialBox from '@/components/Molecules/ConfidentialBox';
import Notification from '@/components/Molecules/Notification';
import RequiredFieldsNotification from '@/components/Molecules/RequiredFieldsNotification';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import { MENU_OPTION_TYPES } from '@/components/Atoms/ToggleMenu/toggleMenuTypes';
import { getMissingRequiredFieldsLabels } from '@/utils/CustomFields';
import { generateComputed } from '@/utils/base/generateComputed';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { isFieldInOptionalFields } from '@/utils/Utils';
import { toLocalDateTime, formatToUtcDateTime } from '@/utils/date';
import { MODULES } from '@/constants/modules';
export default {
  name: 'IncidentDescription',
  components: {
    TinyMceBox: TinyMceBox,
    InputField: InputField,
    SingleSelect: SingleSelect,
    DateTimePicker: DateTimePicker,
    ActivityCustomFields: ActivityCustomFields,
    TrafficLightSelector: TrafficLightSelector,
    FrameworksSelector: FrameworksSelector,
    ConfidentialBox: ConfidentialBox,
    Notification: Notification,
    RequiredFieldsNotification: RequiredFieldsNotification,
    ToggleMenu: ToggleMenu,
    RismaTitle: RismaTitle,
    QuestionnaireStats: QuestionnaireStats
  },
  introduction: '',
  description: '',
  token: '<incident-description />',
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Incident object'
    },
    includeTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    isCreateMode: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    isUserLocked: {
      required: false,
      type: Boolean,
      default: false
    },
    titleErrorMessage: {
      required: false,
      type: String,
      default: ''
    },
    isCreateAttempted: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['open', 'close', 'propertyChanged'],
  data: function data() {
    return {
      isSubmitDisabled: false,
      frameworks: [],
      moduleName: MODULES.INCIDENTS,
      translate: getTranslate['IncidentDescription']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, ['enabledFrameworks'])), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useWorkflowStore, ['getWorkflow'])), generateComputed(['title', 'description', 'severity', 'archived'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    showStatusSelector: function showStatusSelector() {
      return !this.currentWorkflow.activityTypeId;
    },
    workflowStatusId: {
      get: function get() {
        return this.data['workflowStatusId'];
      },
      set: function set(value) {
        this.$emit('propertyChanged', {
          property: 'workflowStatusId',
          value: value
        });
      }
    },
    currentStatus: function currentStatus() {
      var _this = this;
      return this.stateList.find(function (_ref) {
        var id = _ref.id;
        return +id === +_this.workflowStatusId;
      });
    },
    disabledCustomFields: function disabledCustomFields() {
      var _this$currentStatus;
      return (_this$currentStatus = this.currentStatus) === null || _this$currentStatus === void 0 || (_this$currentStatus = _this$currentStatus.fieldOptions) === null || _this$currentStatus === void 0 ? void 0 : _this$currentStatus.filter(function (_ref2) {
        var isLocked = _ref2.isLocked;
        return isLocked;
      }).map(function (_ref3) {
        var fieldId = _ref3.fieldId;
        return fieldId;
      });
    },
    listOfHiddenFields: function listOfHiddenFields() {
      var _this$currentStatus2;
      return (_this$currentStatus2 = this.currentStatus) === null || _this$currentStatus2 === void 0 || (_this$currentStatus2 = _this$currentStatus2.fieldOptions) === null || _this$currentStatus2 === void 0 ? void 0 : _this$currentStatus2.filter(function (_ref4) {
        var isHidden = _ref4.isHidden;
        return isHidden;
      }).map(function (_ref5) {
        var fieldId = _ref5.fieldId;
        return fieldId;
      });
    },
    statusInstructions: function statusInstructions() {
      var _this$currentStatus3;
      return (_this$currentStatus3 = this.currentStatus) === null || _this$currentStatus3 === void 0 ? void 0 : _this$currentStatus3.instructions;
    },
    currentActivityType: function currentActivityType() {
      var _this2 = this;
      return this.activityTypes.find(function (item) {
        return item.id == _this2.data.activityTypeId;
      });
    },
    currentWorkflow: function currentWorkflow() {
      return this.getWorkflow(MODULES.INCIDENTS, this.currentActivityType.id);
    },
    incidentOccurred: {
      get: function get() {
        return this.data.incidentHappenedAt ? toLocalDateTime(this.data.incidentHappenedAt) : '';
      },
      set: function set(val) {
        var value = val ? formatToUtcDateTime(val) : '';
        this.$emit('propertyChanged', {
          property: 'incidentHappenedAt',
          value: value
        });
      }
    },
    confidentialityUserIds: function confidentialityUserIds() {
      var userIds = [];
      if (this.responsibleUsers.length) {
        userIds = userIds.concat(this.responsibleUsers);
      }
      if (this.accountableUsers.length) {
        userIds = userIds.concat(this.accountableUsers);
      }
      var uniqueUserIds = userIds.filter(function (v, i, a) {
        return a.indexOf(v) === i;
      });
      return uniqueUserIds;
    },
    confidentialityNames: function confidentialityNames() {
      if (this.confidentialityUserIds.length) {
        return this.confidentialityUserIds.map(function (user) {
          return user.display_name;
        }).join(', ');
      }
      return '';
    },
    responsibleUsers: function responsibleUsers() {
      return this.data.responsibleUserIds ? filterAsset(this.users, this.data.responsibleUserIds) : [];
    },
    accountableUsers: function accountableUsers() {
      return this.data.accountableUserIds ? filterAsset(this.users, this.data.accountableUserIds) : [];
    },
    confidentialReadOnly: function confidentialReadOnly() {
      var _this3 = this;
      return this.readOnly || !this.responsibleUsers.find(function (user) {
        return user.id === _this3.currentUser.id;
      });
    },
    archivedMessage: function archivedMessage() {
      return this.translate.thisIncidentIsArchivedAndCantBeEdited;
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var _this4 = this;
      return [{
        id: 1,
        title: this.archiveMenuItemName,
        type: MENU_OPTION_TYPES.DANGER,
        icon: 'archive',
        action: function action() {
          _this4.archived = !_this4.archived;
        }
      }];
    },
    archiveMenuItemName: function archiveMenuItemName() {
      return !this.archived ? this.translate.archiveIncident : this.translate.restoreIncident;
    },
    missingRequiredFields: function missingRequiredFields() {
      var _this$data;
      return getMissingRequiredFieldsLabels(this.currentActivityType, (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.customFieldValuesParsed);
    },
    stateList: function stateList() {
      var _this$getWorkflow;
      return (_this$getWorkflow = this.getWorkflow(this.moduleName, this.data.activityTypeId)) === null || _this$getWorkflow === void 0 ? void 0 : _this$getWorkflow.workflowStatuses.map(function (status) {
        return _objectSpread(_objectSpread({}, status), {}, {
          label: status.title,
          value: status.id
        });
      });
    }
  }),
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this5 = this;
      if (this.currentActivityType.solutionId && this.isCreateMode) {
        var activityFramework = this.enabledFrameworks.find(function (framework) {
          return framework.id === _this5.currentActivityType.solutionId;
        });
        this.onFrameworksChange([activityFramework]);
      }
    },
    onFrameworksChange: function onFrameworksChange(event) {
      this.frameworks = event;
      this.$emit('propertyChanged', {
        property: 'solutions',
        value: event
      });
    },
    isFieldInOptional: function isFieldInOptional(field) {
      return isFieldInOptionalFields(this.currentActivityType, field);
    }
  }
};