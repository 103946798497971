function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import { getDocumentTypes as _getDocumentTypes, updateDocumentType, createDocumentType, deleteDocumentType } from '@/api/admin/documentTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import DocumentTypeModal from '@/components/Molecules/Modal/DocumentTypeModal';
import { DocumentTypesIds } from '@/constants/contracts/DocumentTypes';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AdminDocumentTypes',
  description: 'Admin Document Types page',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    DocumentTypeModal: DocumentTypeModal
  },
  data: function data() {
    return {
      documentTypes: [],
      loading: true,
      showDocTypeModal: false,
      documentSelected: {},
      docTypeIdToDelete: null,
      docTypeNameToDelete: null,
      translate: getTranslate['AdminDocumentTypes']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.title, this.translate.edit, this.translate.delete];
    },
    documentTypesSorted: function documentTypesSorted() {
      return _toConsumableArray(this.documentTypes).sort(function (a, b) {
        return compareStrings(a.name, b.name);
      });
    },
    dataset: function dataset() {
      var typesWithLimitedActions = [DocumentTypesIds.MAIN, DocumentTypesIds.DPA];
      return this.documentTypesSorted.map(function (item) {
        var isLimited = typesWithLimitedActions.indexOf(item.id) !== -1;
        return {
          title: item.name,
          edit: {
            item: isLimited ? null : item
          },
          delete: {
            id: isLimited || item.inUse ? null : item.id,
            name: item.name
          }
        };
      });
    }
  },
  mounted: function mounted() {
    this.getDocumentTypes();
  },
  methods: {
    getDocumentTypes: function getDocumentTypes() {
      var _this = this;
      return _getDocumentTypes().then(function (_ref) {
        var list = _ref.list;
        _this.documentTypes = _this.modifyDocumentTypes(list);
      }).finally(function () {
        _this.loading = false;
      });
    },
    modifyDocumentTypes: function modifyDocumentTypes(docTypes) {
      var _this2 = this;
      return docTypes.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          name: _this2.$trans(item.name)
        });
      });
    },
    toggleEditModal: function toggleEditModal(docType) {
      this.showDocTypeModal = true;
      this.docTypeSelected = docType;
    },
    toggleCreateModal: function toggleCreateModal() {
      this.showDocTypeModal = true;
      this.docTypeSelected = {};
    },
    acceptDocTypeModal: function acceptDocTypeModal(data) {
      if (data.id) {
        return this.updateDocType(data);
      }
      return this.createDocType(data);
    },
    updateDocType: function updateDocType(data) {
      var _this3 = this;
      return updateDocumentType(data.id, {
        name: data.name
      }).then(function (response) {
        _this3.showSuccessPopup(_this3.translate.documentTypeUpdated, response.name);
        var docTypeIndex = _this3.documentTypes.findIndex(function (item) {
          return item.id === data.id;
        });
        _this3.documentTypes[docTypeIndex] = _objectSpread(_objectSpread({}, _this3.documentTypes[docTypeIndex]), response);
      }).catch(function (error) {
        var _error$response;
        _this3.$notify({
          title: _this3.$trans(error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error),
          type: 'error'
        });
      }).finally(function () {
        return _this3.dismissModal();
      });
    },
    createDocType: function createDocType(data) {
      var _this4 = this;
      return createDocumentType(data).then(function (response) {
        _this4.documentTypes.push(response);
        _this4.showSuccessPopup(_this4.translate.documentTypeCreated, response.name);
      }).catch(function (error) {
        var _error$response2;
        _this4.$notify({
          title: _this4.$trans(error === null || error === void 0 || (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error),
          type: 'error'
        });
      }).finally(function () {
        return _this4.dismissModal();
      });
    },
    toggleDeleteModal: function toggleDeleteModal(id, name) {
      var _this5 = this;
      this.docTypeIdToDelete = id;
      this.docTypeNameToDelete = name;
      this.$confirm(this.translate.deleteDocumentType, "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(this.docTypeNameToDelete, "?"), function (res) {
        return res ? _this5.handleAcceptAlert() : _this5.docTypeIdToDelete = null;
      }, {
        buttonOkText: this.translate.delete,
        buttonDismissText: this.translate.cancel
      });
    },
    dismissModal: function dismissModal() {
      this.showDocTypeModal = false;
      this.docTypeSelected = {};
    },
    handleAcceptAlert: function handleAcceptAlert() {
      var _this6 = this;
      return deleteDocumentType(this.docTypeIdToDelete).then(function () {
        _this6.showSuccessPopup(_this6.translate.documentTypeDeleted, _this6.docTypeNameToDelete);
        _this6.documentTypes = _this6.documentTypes.filter(function (item) {
          return item.id !== _this6.docTypeIdToDelete;
        });
        _this6.docTypeIdToDelete = null;
        _this6.docTypeNameToDelete = null;
      }).catch(function (error) {
        var _error$response3;
        _this6.$notify({
          title: _this6.$trans(error === null || error === void 0 || (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : _error$response3.error),
          type: 'error'
        });
      }).finally(function () {
        _this6.docTypeIdToDelete = null;
      });
    },
    showSuccessPopup: function showSuccessPopup(header, message) {
      this.$notify({
        title: [header, message]
      });
    }
  }
};