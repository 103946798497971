import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "report-table mb-2"
};
var _hoisted_2 = {
  colspan: "6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [$data.reports.length ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $data.translate.existingReports,
    type: "medium",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $data.reports.length ? (_openBlock(), _createElementBlock("table", _hoisted_1, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, _toDisplayString($data.translate.created), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.createdBy), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.updated), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.updatedBy), 1 /* TEXT */), _cache[1] || (_cache[1] = _createElementVNode("th", null, null, -1 /* HOISTED */)), _cache[2] || (_cache[2] = _createElementVNode("th", null, null, -1 /* HOISTED */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.reports, function (listedReport) {
    return _openBlock(), _createElementBlock("tr", {
      key: listedReport.id,
      class: _normalizeClass({
        'font-bold': +listedReport.id === +$props.reportId
      })
    }, [_createElementVNode("td", null, _toDisplayString($data.toLocalDateTime(listedReport.createdAt)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.getUserNameById(listedReport.createdBy)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($data.toLocalDateTime(listedReport.updatedAt)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.getUserNameById(listedReport.updatedBy)), 1 /* TEXT */), _createElementVNode("td", null, [_createVNode(_component_router_link, {
      to: {
        name: 'OutsourcingReportShow',
        params: {
          reportId: listedReport.id
        }
      }
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString($data.translate.show), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]), _createElementVNode("td", null, [_createVNode(_component_feather_icon, {
      icon: "x",
      class: "mr-2 text-red-50 stroke-2 cursor-pointer",
      onClick: function onClick($event) {
        return $options.deleteReport(listedReport.id);
      }
    }, null, 8 /* PROPS */, ["onClick"])])], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("tr", null, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_router_link, {
    to: {
      name: 'OutsourcingReportCreate'
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.addNew), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })])])])])) : _createCommentVNode("v-if", true), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        reports: $data.reports,
        onError: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('error', $event);
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, ["reports"]))];
    }),
    _: 1 /* STABLE */
  })]);
}