function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import OrganisationSelector from '@/components/Molecules/OrganisationSelector';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'ManagementReportEditModal',
  components: {
    RismaToggle: RismaToggle,
    OrganisationSelector: OrganisationSelector,
    Modal: Modal,
    RismaLabel: RismaLabel,
    InputField: InputField,
    Notification: Notification,
    SingleSelect: SingleSelect
  },
  props: {
    riskProject: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    reportData: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['dismiss', 'createReport', 'editReport'],
  data: function data() {
    return {
      draft: {},
      isRismaSelectOpen: false,
      errorMessages: [],
      translate: getTranslate['ManagementReportEditModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useOrganisationsStore, ['organisations'])), {}, {
    editMode: function editMode() {
      return this.reportData !== null;
    },
    relevantThreatsEnabled: function relevantThreatsEnabled() {
      var _this$riskProject;
      return !!((_this$riskProject = this.riskProject) !== null && _this$riskProject !== void 0 && _this$riskProject.threatsEnabled);
    },
    approvalOptions: function approvalOptions() {
      return [this.translate.filterRiskApproval, this.translate.onlyApprovedRisks, this.translate.onlyNotApprovedRisks].map(function (option, index) {
        return {
          value: index,
          label: option
        };
      });
    }
  }),
  mounted: function mounted() {
    this.draft = this.reportData || {
      title: '',
      organisations: [],
      approvalFilter: 0
    };
  },
  methods: {
    onModalAccept: function onModalAccept() {
      if (!this.validateDraft()) return;
      var draftData = _objectSpread(_objectSpread({}, this.draft), {}, {
        organisationIds: this.draft.organisations.map(function (org) {
          return org.id;
        })
      });
      var emit = this.editMode ? 'editReport' : 'createReport';
      this.$emit(emit, draftData);
    },
    validateDraft: function validateDraft() {
      var errorMessages = [];
      if (!this.draft.title) {
        errorMessages.push(this.translate.titleCannotBeEmpty);
      }
      this.errorMessages = errorMessages;
      return !errorMessages.length;
    }
  }
};