import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    draggable: "",
    class: "nestable-handle",
    onDragstart: _cache[0] || (_cache[0] = function () {
      return $options.dragstart && $options.dragstart.apply($options, arguments);
    }),
    onTouchstart: _cache[1] || (_cache[1] = function () {
      return $options.dragstart && $options.dragstart.apply($options, arguments);
    }),
    onTouchend: _cache[2] || (_cache[2] = function () {
      return $options.touchend && $options.touchend.apply($options, arguments);
    }),
    onTouchmove: _cache[3] || (_cache[3] = function () {
      return $options.touchmove && $options.touchmove.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "default")], 32 /* NEED_HYDRATION */);
}