import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "max-w-650px"
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "flex justify-between mb-3"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 1,
  class: "mb-4"
};
var _hoisted_8 = {
  class: "flex mb-3 justify-between"
};
var _hoisted_9 = {
  class: "flex justify-between mb-3"
};
var _hoisted_10 = {
  class: "flex justify-between mb-3"
};
var _hoisted_11 = {
  class: "flex justify-between mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_input_field = _resolveComponent("input-field");
  var _component_buttons_row = _resolveComponent("buttons-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.errorMessage = null;
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.siemControlPanel,
    type: "big",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.chooseAnExistingSIEMTransfer,
    type: "small",
    class: "mb-5"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: $data.selectedSiemTransferId,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedSiemTransferId = $event;
    }),
    class: "mb-2",
    title: $data.translate.chooseSIEMTransfer,
    placeholder: $data.translate.chooseSIEMTransfer,
    options: $options.transferOptions
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"]), _createVNode(_component_risma_button, {
    text: "",
    class: "flex items-center",
    type: "util",
    onClick: $options.createNewTransfer
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.createNewTransfer,
        type: "medium",
        class: "mr-2"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        class: "text-blue-750 stroke-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"])]), $data.siemTransfer ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.siemSetup,
    type: "big",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.provideTheNecessaryAuthenticationCredentials,
    truncate: false,
    type: "small",
    class: "mb-5"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.activateSiemTransfer,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    "model-value": !!$data.siemTransfer.active,
    onInput: _cache[2] || (_cache[2] = function ($event) {
      return $data.siemTransfer.active = +$event;
    })
  }, null, 8 /* PROPS */, ["model-value"])]), _createVNode(_component_single_select, {
    modelValue: $data.siemTransfer.connectionType,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.siemTransfer.connectionType = $event;
    }),
    class: "mb-3",
    title: $data.translate.connectionType,
    placeholder: $data.translate.connectionType,
    options: $options.connectionOptions
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"]), $options.showLoginFields ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_input_field, {
    modelValue: $data.siemTransfer.username,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.siemTransfer.username = $event;
    }),
    title: $data.translate.username,
    placeholder: $data.translate.username,
    invalid: $data.errorMessage && !$data.siemTransfer.username,
    type: "text",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"]), _createVNode(_component_input_field, {
    modelValue: $data.siemTransfer.password,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.siemTransfer.password = $event;
    }),
    title: $data.translate.password,
    placeholder: $data.translate.password,
    invalid: $data.errorMessage && !$data.siemTransfer.password,
    type: "password",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"]), _createVNode(_component_input_field, {
    modelValue: $data.siemTransfer.server,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.siemTransfer.server = $event;
    }),
    title: $data.translate.server,
    placeholder: $data.translate.server,
    invalid: $data.errorMessage && !$data.siemTransfer.server,
    type: "text",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"])])) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
    modelValue: $data.siemTransfer.path,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.siemTransfer.path = $event;
    }),
    title: $data.translate.path,
    placeholder: $data.pathHint,
    invalid: $data.errorMessage && !$data.siemTransfer.path,
    type: "text",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"]), $options.transferLatestLog ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.latestLogMessage,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", null, _toDisplayString($options.transferLatestLog.timestamp), 1 /* TEXT */), _createElementVNode("p", {
    class: _normalizeClass($options.transferLatestLog.status ? 'text-green-150' : 'text-red-50')
  }, _toDisplayString($options.transferLatestLog.status ? $data.translate.success : $data.translate.error), 3 /* TEXT, CLASS */)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.transferLatestLog.output, function (line, index) {
    return _openBlock(), _createElementBlock("p", {
      key: index
    }, _toDisplayString(line), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.logFiles,
    type: "big",
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.selectOneOrMoreLogsYouWishToExport,
    type: "small",
    class: "mb-5"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.successfulLogins,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    "model-value": !!$data.siemTransfer.enableSuccessfulLogins,
    onInput: _cache[8] || (_cache[8] = function ($event) {
      return $data.siemTransfer.enableSuccessfulLogins = +$event;
    })
  }, null, 8 /* PROPS */, ["model-value"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_title, {
    title: $data.translate.failedLogins,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    "model-value": !!$data.siemTransfer.enableFailedLogins,
    onInput: _cache[9] || (_cache[9] = function ($event) {
      return $data.siemTransfer.enableFailedLogins = +$event;
    })
  }, null, 8 /* PROPS */, ["model-value"])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.usersChangelog,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    "model-value": !!$data.siemTransfer.enableUserChangeLog,
    onInput: _cache[10] || (_cache[10] = function ($event) {
      return $data.siemTransfer.enableUserChangeLog = +$event;
    })
  }, null, 8 /* PROPS */, ["model-value"])]), _createVNode(_component_buttons_row, {
    class: "justify-between",
    data: $options.buttonsRowData
  }, null, 8 /* PROPS */, ["data"])])) : _createCommentVNode("v-if", true)]))]);
}