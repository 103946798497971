import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  key: 0,
  class: "flex items-center"
};
var _hoisted_3 = {
  key: 0,
  class: "text-red-50 font-semibold"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_switch = _resolveComponent("risma-switch");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.createComplianceProject,
        class: "mb-4"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.projectName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.projectName = $event;
        }),
        title: $data.translate.projectName,
        type: "text",
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_single_select, {
        modelValue: $data.projectType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.projectType = $event;
        }),
        title: $data.translate.projectType,
        options: $options.projectTypesOptions,
        placeholder: $data.translate.selectProjectType,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.questionnaireType,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.questionnaireType = $event;
        }),
        title: $data.translate.questionnaireType,
        options: $options.questionnaireTypesForSingleSelect,
        placeholder: $data.translate.selectQuestionnaireType,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
        modelValue: $data.defaultQuestionnaire,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.defaultQuestionnaire = $event;
        }),
        title: $data.translate.defaultQuestionnaire,
        options: $options.questionnairesOptions,
        placeholder: $data.translate.selectDefaultQuestionnaire,
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"]), _createVNode(_component_risma_button, {
        type: "save",
        text: "Save",
        class: "mb-6 w-fit",
        disabled: !$data.projectName.length || $data.projectType === null || $data.defaultQuestionnaire === null,
        onClick: $options.createComplianceProject
      }, null, 8 /* PROPS */, ["disabled", "onClick"]), _createVNode(_component_risma_title, {
        title: $data.translate.existingProjects,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_static_table, {
        "dataset-table": $options.dataset,
        columns: $options.columns,
        theme: $data.StaticTableThemes.zebra
      }, {
        showOverviewMenuItem: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [$options.projectTypesToShowOverviewPage.includes(slotData.type) ? (_openBlock(), _createBlock(_component_risma_switch, {
            key: 0,
            options: $options.switchOverviewPageOptions,
            "model-value": slotData.showOverviewMenuItem,
            "is-labels-show": true,
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.handleClickProjectSetting(slotData, {
                showOverviewMenuItem: slotData.showOverviewMenuItem ? 0 : 1
              }, 'showOverviewMenuItem');
            }
          }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("div", _hoisted_1))];
        }),
        display: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [$options.checkIfMiniTreeSwitchAvailable(slotData) || slotData.treeComponent === $data.treeComponentValues.MINI_TREE ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_switch, {
            options: $options.switchTreeComponentOptions,
            "model-value": slotData.treeComponent,
            "is-labels-show": true,
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.handleClickProjectSetting(slotData, {
                treeComponent: slotData.treeComponent === $data.treeComponentValues.TREE ? 2 : 1
              }, 'treeComponent');
            }
          }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"]), !$options.checkIfMiniTreeSwitchAvailable(slotData) ? (_openBlock(), _createElementBlock("span", _hoisted_3, " !!! ")) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_4))];
        }),
        toggleEnable: _withCtx(function (_ref3) {
          var slotData = _ref3.slotData;
          return [_createElementVNode("button", {
            class: "focus:outline-none",
            onClick: function onClick($event) {
              return $options.handleClickProjectSetting(slotData, {
                enabled: slotData.enabled ? 0 : 1
              }, 'enabled');
            }
          }, [slotData.enabled ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 0,
            class: "text-red-50 stroke-2 cursor-pointer",
            icon: "x"
          })) : (_openBlock(), _createBlock(_component_feather_icon, {
            key: 1,
            class: "stroke-2 cursor-pointer",
            icon: "plus"
          }))], 8 /* PROPS */, _hoisted_5)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns", "theme"])];
    }),
    _: 1 /* STABLE */
  });
}