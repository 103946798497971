// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-60af0e6f]  .radio-buttons span {
    font-weight: 400;
    font-style: normal;
    --tw-text-opacity: 1;
    color: rgb(45 55 72 / var(--tw-text-opacity))
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
