function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import InputField from '@/components/Atoms/Inputs/InputField';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import RiskModule from '@/components/Organisms/RiskModule';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import LinkedCompany from '@/components/Molecules/LinkedCompany/LinkedCompany';
import LinkCompany from '@/components/Molecules/LinkedCompany/LinkCompany';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RiskGrid from '@/components/Organisms/RiskGrid';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { generateComputed } from '@/utils/base/generateComputed';
import { MODULES } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { art46TransferBasis } from '@/constants/art46TransferBasis';
import { UserLevels } from '@/constants/UserLevels';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
import { InformationAssetsCommonMixin } from '../InformationAssetsCommonMixin';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import InformationAssetsRiskGridMixin from '@/components/Pages/InformationAssets/InformationAssetsRiskGridMixin';
import { LinkedCompanyMixin } from '@/mixins/LinkedCompanyMixin';
export default {
  name: 'InformationAssetsControllerDetails',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RiskModule: RiskModule,
    TinyMceBox: TinyMceBox,
    ActivityCustomFields: ActivityCustomFields,
    QuestionnaireStats: QuestionnaireStats,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinkedCompany: LinkedCompany,
    LinkCompany: LinkCompany
  },
  mixins: [InformationAssetsCommonMixin, ActivityTypeMixin, RiskAssessmentMixin, InformationAssetsRiskGridMixin, LinkedCompanyMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['propertyChanged', 'companyStatusChange'],
  data: function data() {
    return {
      type: AssetsTypes.CONTROLLERS,
      module: MODULES.COMPLIANCE_IA_CONTROLLER,
      pageLoaded: false,
      art46TransferBasisOptions: art46TransferBasis(this.$trans),
      translate: getTranslate['InformationAssetsControllerDetails']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser', 'userHasAccessLevel'])), generateComputed(['address', 'description', 'countryCode', 'email', 'phoneNumber', 'vatNo', 'regNo', 'companyId', 'countryTransferLegalBasisId'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    isUserAbleToEditCompanies: function isUserAbleToEditCompanies() {
      return this.userHasAccessLevel('level_company', UserLevels.SUPER);
    },
    ownerId: function ownerId() {
      return this.currentUser.id;
    },
    showRiskOnControllers: function showRiskOnControllers() {
      return !!this.getSettingValue('feature.risk_assessment_on_controllers');
    },
    riskProjectId: function riskProjectId() {
      return this.getSettingValue('feature.controllers_r_projects_id');
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var promises = [this.getRiskData(), this.getRisksData(), this.getCompany()];
      if (this.showRtoRpoOnAssets) {
        promises.push(this.getRtoFields(), this.getRpoFields());
      }
      Promise.all(promises).then(function () {
        return _this.pageLoaded = true;
      });
    }
  }
};