function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import ColorPicker from '@/components/Molecules/ColorPicker';
import { sortNumericByPropComplex } from '@/utils/sort';
import { Colors } from '@/Colors';
export default {
  name: 'QuestionnaireScoringAssessments',
  introduction: '',
  description: '',
  token: '<questionnaire-scoring-assessments />',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField,
    ColorPicker: ColorPicker
  },
  props: {
    questionnaire: {
      type: Object,
      required: true
    },
    assessments: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Saved assessments'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      itemToDelete: null,
      colors: Colors,
      items: [],
      // Get from questionnaire
      possibleCategoryTypes: {
        PERCENT: 'percent',
        VALUE: 'value'
      },
      possibleOperators: {
        GREATER_THAN_OR_EQUAL_TO: '>='
      },
      initialized: false,
      debounceTimer: null,
      debounceTimeout: 500,
      translate: getTranslate['QuestionnaireScoringAssessments']()
    };
  },
  beforeMount: function beforeMount() {
    this.populateItems();
    this.sortByValues(this.items);
  },
  methods: {
    onDataChanged: function onDataChanged() {
      var _this = this;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(function () {
        _this.$emit('changed', _this.prepareDataForEmit());
      }, this.debounceTimeout);
    },
    prepareDataForEmit: function prepareDataForEmit() {
      return {
        categories: this.items.map(function (item) {
          return {
            score: item.score,
            assessmentText: item.assessmentText,
            categoryType: item.categoryType,
            operator: item.operator,
            color: item.color,
            questionnaireId: item.questionnaireId
          };
        })
      };
    },
    addOption: function addOption() {
      var lastElem = this.items[this.items.length - 1];
      var newItem = lastElem ? JSON.parse(JSON.stringify(lastElem)) : this.createDefaultItem();
      if (lastElem) {
        newItem.id = null;
        newItem._uniqueId = this.uniqueId();
      }
      this.items.push(newItem);
    },
    createDefaultItem: function createDefaultItem() {
      return {
        id: null,
        _uniqueId: this.uniqueId(),
        questionnaireId: this.questionnaire.id,
        categoryType: this.possibleCategoryTypes.VALUE,
        operator: this.possibleOperators.GREATER_THAN_OR_EQUAL_TO,
        score: '0',
        assessmentText: '',
        color: ''
      };
    },
    uniqueId: function uniqueId() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'new';
      var today = new Date().getTime();
      return "unique-id-".concat(today, "-").concat(id);
    },
    populateItems: function populateItems() {
      var _this2 = this;
      this.items = _toConsumableArray(this.assessments).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          _uniqueId: _this2.uniqueId(item.id),
          score: item.score + '',
          categoryType: item.categoryType
        });
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      this.items.splice(this.items.indexOf(this.itemToDelete), 1);
      this.onDataChanged();
    },
    removeOption: function removeOption(item) {
      var _this3 = this;
      this.itemToDelete = item;
      this.$confirm(this.translate.deleteRow, this.translate.areYouSureYouWantToDeleteThisRow, function (res) {
        return res && _this3.onDeleteConfirm();
      });
    },
    onColorChanged: function onColorChanged(item, e) {
      if (item.color !== e) {
        item.color = e;
        this.onDataChanged();
      }
    },
    sortByValues: function sortByValues(data) {
      if (data && data.length) {
        sortNumericByPropComplex(data, 'score');
      }
    },
    onBlur: function onBlur() {
      this.sortByValues(this.items);
    },
    humanOperator: function humanOperator(value) {
      var result = this.translate.unknownOperator;
      if (value === this.possibleOperators.GREATER_THAN_OR_EQUAL_TO) {
        result = this.translate.from;
      }
      return result;
    }
  }
};