function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getQuestionnaireAssessments } from '@/api/compliance/questionnaire';
import { getNodesFlat } from '@/api/risma/data';
import { getCVRCompany } from '@/api/external/cvrapi';
import { getAllAudits, getAudit } from '@/api/compliance/audit';
import AttachmentSelector from '@/components/Organisms/AttachmentSelector/AttachmentSelector';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import RiskModule from '@/components/Organisms/RiskModule';
import RiskGrid from '@/components/Organisms/RiskGrid';
import ComplianceScoreTable from '@/components/Molecules/InformationAssets/ComplianceScoreTable';
import LinkedCompany from '@/components/Molecules/LinkedCompany/LinkedCompany';
import LinkCompany from '@/components/Molecules/LinkedCompany/LinkCompany';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import Notification from '@/components/Molecules/Notification';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import CvrModal from '@/components/Molecules/Modal/CvrModal';
import AssessmentScoring from '@/components/Organisms/Compliance/AssessmentScoring';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
import { InformationAssetsCommonMixin } from '../InformationAssetsCommonMixin';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import { LinkedCompanyMixin } from '@/mixins/LinkedCompanyMixin';
import InformationAssetsRiskGridMixin from '@/components/Pages/InformationAssets/InformationAssetsRiskGridMixin';
import { generateComputed } from '@/utils/base/generateComputed';
import { getCVRQueryParam } from '@/utils/format/companies';
import { MODULES } from '@/constants/modules';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { art46TransferBasis } from '@/constants/art46TransferBasis';
import { UserLevels } from '@/constants/UserLevels';
export default {
  name: 'InformationAssetsProcessorDetails',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RiskModule: RiskModule,
    AttachmentSelector: AttachmentSelector,
    ComplianceScoreTable: ComplianceScoreTable,
    ActivityCustomFields: ActivityCustomFields,
    TinyMceBox: TinyMceBox,
    RismaButton: RismaButton,
    CvrModal: CvrModal,
    Notification: Notification,
    QuestionnaireStats: QuestionnaireStats,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    LinkedCompany: LinkedCompany,
    LinkCompany: LinkCompany,
    AssessmentScoring: AssessmentScoring
  },
  mixins: [InformationAssetsCommonMixin, ActivityTypeMixin, RiskAssessmentMixin, InformationAssetsRiskGridMixin, LinkedCompanyMixin],
  props: {
    id: {
      required: true,
      type: [String, Number],
      note: 'Data processor id'
    },
    data: {
      required: true,
      type: Object
    }
  },
  emits: ['propertyChanged', 'companyStatusChange', 'silentUpdate:attachments'],
  data: function data() {
    return {
      pageLoaded: false,
      type: AssetsTypes.PROCESSORS,
      currentAssessments: [],
      assessmentIndicatorData: null,
      latestDpmAssessment: null,
      questionnaire: null,
      processors: {},
      module: MODULES.COMPLIANCE_IA_PROCESSOR,
      modalDefaultData: {
        name: '',
        address: '',
        phone: '',
        email: '',
        vatNo: ''
      },
      dataUpdatedKey: 0,
      modalData: {},
      showModal: false,
      errorMessage: '',
      showLinkCompanyModal: false,
      selectedCompanyId: null,
      titleField: 'title',
      art46TransferBasisOptions: art46TransferBasis(this.$trans),
      translate: getTranslate['InformationAssetsProcessorDetails']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser', 'userHasAccessLevel'])), generateComputed(['name', 'trafficLight', 'address', 'description', 'countryCode', 'email', 'phoneNumber', 'vatNo', 'regNo', 'contactName', 'companyId', 'countryTransferLegalBasisId'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    isUserAbleToEditCompanies: function isUserAbleToEditCompanies() {
      return this.userHasAccessLevel('level_company', UserLevels.SUPER);
    },
    ownerId: function ownerId() {
      return this.currentUser.id;
    },
    showRiskOnProcessors: function showRiskOnProcessors() {
      return !!this.getSettingValue('feature.risk_assessment_on_processors');
    },
    riskProjectId: function riskProjectId() {
      return this.getSettingValue('feature.processors_r_projects_id');
    },
    showNodesAndScores: function showNodesAndScores() {
      return this.getSettingValue('feature.ia_processor_risk_analysis_enabled');
    },
    noFieldAttachments: function noFieldAttachments() {
      return this.data.attachments.filter(function (item) {
        return !item.field;
      });
    },
    dpaFiles: function dpaFiles() {
      return this.data.attachments.filter(function (item) {
        return item.field === 'processingAgreeement';
      });
    }
  }),
  watch: {
    $route: function $route(to) {
      if (to.path.indexOf('/informationassets/processors') !== -1) {
        this.getData();
      }
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getComplianceNodes: function getComplianceNodes() {
      var _this = this;
      getNodesFlat(['questionnaireScore']).then(function (_ref) {
        var list = _ref.list;
        return _this.complianceNodes = list;
      });
    },
    getData: function getData() {
      var _this2 = this;
      this.pageLoaded = false;
      var promises = [this.getRiskData(), this.getRisksData(), this.getComplianceNodes(), this.getCompany()];
      if (this.getSettingValue('feature.data_processor_management')) {
        promises.push(this.getAllRequests());
      }
      if (this.showRtoRpoOnAssets) {
        promises.push(this.getRtoFields(), this.getRpoFields());
      }
      return Promise.all(promises).then(function () {
        return _this2.pageLoaded = true;
      });
    },
    getCurrentDpmAssessment: function getCurrentDpmAssessment(questionnaireId) {
      var _this3 = this;
      return getQuestionnaireAssessments(questionnaireId).then(function (_ref2) {
        var list = _ref2.list;
        _this3.latestDpmAssessment = {};
        _this3.latestDpmAssessment.assessmentIndicatorData = list;
      });
    },
    getAllRequests: function getAllRequests() {
      var _this4 = this;
      return getAllAudits(this.module, this.id).then(function (response) {
        var currentAudit = response.list.find(function (item) {
          return item.answered !== null;
        }); // .first();
        if (currentAudit) {
          return getAudit('compliance_ia_processor', _this4.id, currentAudit.id);
        }
      }).then(function (currentAudit) {
        if (currentAudit !== undefined) {
          _this4.getCurrentDpmAssessment(currentAudit.questionnaire.id, currentAudit.questionnaireScore.totalScore).then(function () {
            _this4.latestDpmAssessment = _objectSpread(_objectSpread({}, _this4.latestDpmAssessment), {}, {
              totalScore: currentAudit.questionnaireScore.totalScore,
              assessmentText: currentAudit.questionnaireScore.helpText.assessmentText,
              textColor: currentAudit.questionnaireScore.helpText.color,
              questionnaireTitle: currentAudit.questionnaire.title,
              userName: currentAudit.fullname,
              userEmail: currentAudit.email
            });
          });
        }
      });
    },
    scoreHelpTextStyle: function scoreHelpTextStyle() {
      if (!this.data && this.data.questionnaireScore) {
        return '';
      }
      return 'color: ' + this.data.questionnaireScore.helpText.color;
    },
    searchVatNo: function searchVatNo() {
      var _this5 = this;
      var params = getCVRQueryParam(this.countryCode);
      return getCVRCompany(this.vatNo, params).then(function (_ref3) {
        var list = _ref3.list;
        if (!Array.isArray(list) || list.length < 1) {
          throw {};
        }
        var company = list[0];
        var addressLine = company.address;
        if (company.zipCode || company.city) {
          addressLine += " ".concat(company.zipCode, " ").concat(company.city);
        }
        _this5.modalData = _objectSpread(_objectSpread(_objectSpread({}, _this5.modalDefaultData), company), {}, {
          vatNo: _this5.vatNo,
          address: addressLine
        });
        _this5.showModal = true;
      }).catch(function () {
        _this5.errorMessage = _this5.translate.noResultsFoundDidYouEnterValidVatNumber;
        setTimeout(function () {
          _this5.errorMessage = '';
        }, 5000);
      });
    },
    onModalSubmit: function onModalSubmit() {
      this.updateDataFromModal(this.modalData);
      this.resetModal();
    },
    updateDataFromModal: function updateDataFromModal(data) {
      if (data.name) {
        this.name = data.name;
      }
      this.address = data.address;
      this.phoneNumber = data.phone || '';
      this.email = data.email || '';
      this.dataUpdatedKey += 1;
    },
    resetModal: function resetModal() {
      this.showModal = false;
      this.modalData = _objectSpread({}, this.modalDefaultData);
    },
    handleFileChange: function handleFileChange(_ref4) {
      var files = _ref4.files;
      var attachments = [].concat(_toConsumableArray(this.noFieldAttachments), _toConsumableArray(files));
      this.$emit('silentUpdate:attachments', attachments);
    },
    companyFilter: function companyFilter(company) {
      var _company$processor;
      return !((_company$processor = company.processor) !== null && _company$processor !== void 0 && _company$processor.id);
    }
  }
};