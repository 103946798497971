import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "tooltip-wrapper relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tool_tip = _resolveComponent("tool-tip");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", {
    class: "tooltip-feather-wrapper",
    onMouseleave: _cache[1] || (_cache[1] = function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }),
    onMouseover: _cache[2] || (_cache[2] = function () {
      return $options.show && $options.show.apply($options, arguments);
    }),
    onClick: _cache[3] || (_cache[3] = function () {
      return $options.onTooltipClick && $options.onTooltipClick.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "tooltip-body", {
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.clicked = !$data.clicked;
    })
  }, function () {
    return [_createVNode(_component_feather_icon, {
      width: "18",
      height: "18",
      icon: $props.tooltipIcon,
      class: "stroke-3 text-blue-750 cursor-pointer"
    }, null, 8 /* PROPS */, ["icon"])];
  })], 32 /* NEED_HYDRATION */)), [[_directive_click_outside, $options.onClickOutside]]), _createVNode(_component_tool_tip, _mergeProps({
    hidden: $data.hidden && !$data.clicked
  }, _ctx.$props), {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["hidden"])]);
}