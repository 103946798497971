import { getTranslate } from './translate';
import { getAllArticlesByGapSchemaId, updateGapArticles, createGapArticle, deleteGapArticle, getAllDomains, createDomain } from '@/api/compliance/gapschema';
import StaticTable from '@/components/Molecules/StaticTable';
import GapSchemaArticleModal from '@/components/Molecules/Modal/GapSchemaArticleModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { SETTINGS_ROUTE_NAMES } from '@/constants/routes/settings';
import { escapeHtml } from '@/utils';
import { catch403 } from '@/utils/handleAPIErrors';
var EDIT_ERROR_MESSAGE = 'This article is used in an analysis and cannot be edited.';
var DELETE_ERROR_MESSAGE = 'This article is used in an analysis and cannot be deleted.';
export default {
  name: 'GapSchemasAdminArticles',
  introduction: 'Articles list',
  description: 'This component renders the table with articles list',
  token: '<gap-schemas-admin-articles></gap-schemas-admin-articles>',
  components: {
    GapSchemaArticleModal: GapSchemaArticleModal,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon
  },
  props: {
    gapSchemaId: {
      default: 0,
      type: [String, Number],
      note: ''
    }
  },
  data: function data() {
    return {
      idToDelete: null,
      articles: [],
      selectedArticle: {},
      showArticleModal: false,
      domains: [],
      listOfLineThroughSlots: ['article', 'articleTitle', 'requirement', 'domain'],
      translate: getTranslate['GapSchemasAdminArticles']()
    };
  },
  computed: {
    columns: function columns() {
      var columns = [this.translate.article, this.translate.articleTitle, this.translate.requirement, this.translate.domain, this.translate.options, ''];
      return columns;
    },
    formattedData: function formattedData() {
      return this.articles.map(function (article) {
        return {
          article: {
            text: escapeHtml(article.article),
            hidden: article.hidden
          },
          articleTitle: {
            text: escapeHtml(article.articleTitle),
            hidden: article.hidden
          },
          requirement: {
            text: escapeHtml(article.requirement),
            hidden: article.hidden
          },
          domain: {
            text: article.domain.label,
            hidden: article.hidden
          },
          options: {
            id: article.id,
            canBeDeleted: article.canBeDeleted,
            hidden: article.hidden
          }
        };
      });
    },
    confirmationMessage: function confirmationMessage() {
      var warning = this.translate.areYouSureYouWantToDeleteThisArticle;
      warning += '\n';
      warning += this.translate.allAnalysesAndLinksWillBeRemoved;
      warning += '\n';
      warning += this.translate.thisCannotBeUndone;
      return warning;
    }
  },
  mounted: function mounted() {
    this.getArticles();
    this.getDomains();
  },
  methods: {
    getArticles: function getArticles() {
      var _this = this;
      getAllArticlesByGapSchemaId(this.gapSchemaId, ['suggestedActivities']).then(function (_ref) {
        var list = _ref.list;
        _this.articles = list;
      }).catch(function () {
        catch403(_this.$router, SETTINGS_ROUTE_NAMES.gapSchemas);
      });
    },
    getDomains: function getDomains() {
      var _this2 = this;
      getAllDomains().then(function (_ref2) {
        var list = _ref2.list;
        _this2.domains = list.map(function (domain) {
          return {
            id: domain.id,
            label: domain.label
          };
        });
      });
    },
    toggleArticleModal: function toggleArticleModal(articleId) {
      if (articleId) {
        var article = this.articles.find(function (article) {
          return article.id == articleId;
        });
        this.selectedArticle = JSON.parse(JSON.stringify(article));
        this.selectedArticle.domain = this.setDomainData(this.selectedArticle.domain);
      } else {
        this.selectedArticle = {};
      }
      this.showArticleModal = !this.showArticleModal;
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      return deleteGapArticle(this.gapSchemaId, this.idToDelete).then(this.getArticles).catch(function () {
        return _this3.$notify({
          title: DELETE_ERROR_MESSAGE,
          type: 'error'
        });
      });
    },
    onDeleteArticle: function onDeleteArticle(id) {
      var _this4 = this;
      this.idToDelete = id;
      this.$confirm('', this.confirmationMessage, function (res) {
        return res && _this4.onDeleteConfirm();
      });
    },
    updateVisibility: function updateVisibility(id, data) {
      var _this5 = this;
      return updateGapArticles(this.gapSchemaId, id, data).then(function () {
        var index = _this5.articles.findIndex(function (article) {
          return article.id === id;
        });
        _this5.articles[index].hidden = data.hidden;
      }).catch(function () {
        return _this5.$notify({
          title: EDIT_ERROR_MESSAGE,
          type: 'error'
        });
      });
    },
    onSubmitArticleModal: function onSubmitArticleModal(formData) {
      var _this6 = this;
      var data = {
        article: formData.article,
        articleTitle: formData.articleTitle,
        requirement: formData.requirement,
        domainId: formData.domain.id,
        flags: formData.flags,
        attributeIds: formData.attributeIds || [],
        solutionIds: formData.solutionIds || []
      };
      if (this.selectedArticle && this.selectedArticle.id) {
        updateGapArticles(this.gapSchemaId, this.selectedArticle.id, data).then(this.getArticles).then(this.toggleArticleModal).catch(function () {
          return _this6.$notify({
            title: EDIT_ERROR_MESSAGE,
            type: 'error'
          });
        });
      } else {
        createGapArticle(this.gapSchemaId, data).then(this.getArticles).then(this.toggleArticleModal);
      }
    },
    setDomainData: function setDomainData(domain) {
      return {
        id: parseInt(domain.id),
        label: domain.label
      };
    },
    onCreateDomain: function onCreateDomain(label) {
      var _this7 = this;
      createDomain({
        label: label
      }).then(function (domain) {
        _this7.selectedArticle.domain = _this7.setDomainData(domain);
        return _this7.getDomains();
      });
    }
  }
};