import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mt-12 h-fit"
};
var _hoisted_2 = {
  class: "question-text mb-4"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 0,
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_help_text = _resolveComponent("help-text");
  return $props.data.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, function (question, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "border border-solid border-gray-350 bg-white p-8 mb-4"
    }, [_createVNode(_component_risma_title, {
      title: "".concat($data.translate.question, ": ").concat(question.questionnumber, "  ").concat(question.question),
      class: "text-base font-semibold",
      type: "medium",
      truncate: false
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_2, [_createVNode(_component_help_text, {
      text: question.help,
      class: "text-sm",
      "count-truncate": 260
    }, null, 8 /* PROPS */, ["text"])]), question.processLibrary.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
      title: "".concat(_ctx.processLibraryTitle, ": "),
      class: "text-base font-semibold mr-4",
      type: "medium",
      truncate: false
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
      title: $options.getList(question.processLibrary),
      truncate: false,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])])) : Object.values(question.informationAssets).some(function (item) {
      return item.length;
    }) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(question.informationAssets), function (ia) {
      return _openBlock(), _createElementBlock("div", {
        key: "ia-".concat(ia)
      }, [question.informationAssets[ia].length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: "".concat(_ctx.$trans($data.iaTitles[ia]), ": "),
        class: "text-base font-semibold mr-4",
        type: "medium",
        truncate: false
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        title: $options.getList(question.informationAssets[ia]),
        truncate: false,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true);
}