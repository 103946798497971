function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Colors } from '@/Colors';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { TrendOptions } from '@/constants/TrendOptions';
import { formatTime } from '@/utils/date';
import * as Utils from '@/utils/Utils';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { DateFormats } from '@/constants/DateFormats';
export var CustomUnitPrefix = 'Unit';
export function getValueOfCustomField(customField, customFieldValue) {
  var hasRowGroupActivated = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var value = {
    fieldType: customField.typeId
  };
  if (customField.typeId === CustomFieldTypes.SELECT) {
    var options = Utils.getProperSelectedOptions(customField.options);
    var option = options.find(function (option) {
      return option.id === customFieldValue.value;
    });
    value.field = option ? option.value : customFieldValue.value;
  }
  if (customField.typeId === CustomFieldTypes.SELECT_MULTI) {
    var selectedIds = customFieldValue.map(function (f) {
      return f.value;
    });
    var _options = Utils.getProperSelectedOptions(customField.options);
    var selectedOptions = _options.filter(function (option) {
      return selectedIds.includes(option.id);
    });
    value.field = selectedOptions.length ? selectedOptions.map(function (o) {
      return o.value;
    }).join(', ') : selectedIds.join(', ');
  }
  if (customField.typeId === CustomFieldTypes.TEXT_LINE || customField.typeId === CustomFieldTypes.TEXT_FIELD) {
    value.field = customFieldValue.value;
  }
  if (customField.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
    var field = DatatableRenderer.prepareTrafficLightField(+customFieldValue.value);
    value.field = field;
    value.fieldText = field.label;
    value.color = field.color;
  }
  if (customField.typeId === CustomFieldTypes.TREND) {
    var _option = TrendOptions().find(function (item) {
      return item.value === customFieldValue.value;
    });
    if (_option !== undefined) {
      value.field = _option.label;
    } else {
      value.field = '';
    }
  }
  if (customField.typeId === CustomFieldTypes.DATE_TIME) {
    value.field = formatTime(customFieldValue.value, DateFormats.DATE_TIME_FORMAT);
  }
  if (customField.typeId === CustomFieldTypes.NUMERICAL) {
    value.field = customFieldValue.value;
    value.unit = customField.unit;
  }
  if (customField.typeId === CustomFieldTypes.NUMERICAL_UNIT) {
    value.field = customField.unit;
  }
  if (customField.typeId === CustomFieldTypes.ROW_GROUP && hasRowGroupActivated) {
    value.data = [];
    value.fieldText = '';
    customFieldValue.rows.forEach(function (row, index) {
      var rowData = {};
      customField.children.forEach(function (child) {
        value.fieldText += "".concat(index, " - ");
        var data = getRightRowGroupFieldValue(child, row);
        data.textDescription = child.label;
        if (child.typeId === CustomFieldTypes.TRAFFIC_LIGHT) {
          data.fieldText = Colors.getTrafficlightByNumber(+data.fieldText || 0).label;
        }
        rowData[child.id] = data;
        value.fieldText += "".concat(child.label, ": ").concat(data.fieldText || data.field, "; ");
      });
      value.data.push(rowData);
    });
  }
  return value;
}
function getRightRowGroupFieldValue(field, fieldValues) {
  var data = fieldValues && fieldValues.find(function (cfv) {
    return cfv.fieldId === field.id;
  });
  if (!data) {
    return {
      field: ''
    };
  }
  return getValueOfCustomField(field, data, false, true);
}
export function getColumnLabelName(label) {
  return "cf-".concat(label.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g, ''));
}
export function setupSelectedValues(customFields, customFieldValues) {
  var result = [];
  customFields.forEach(function (cf) {
    var formattedCf = _objectSpread({}, cf);
    var cfValue = formattedCf.typeId === CustomFieldTypes.SELECT_MULTI ? customFieldValues.filter(function (cfVal) {
      return formattedCf.id === cfVal.fieldId;
    }) : customFieldValues.find(function (cfVal) {
      return formattedCf.id === cfVal.fieldId;
    }) || {
      value: null
    };
    if (formattedCf.typeId === CustomFieldTypes.ROW_GROUP) {
      formattedCf.selectedValue = '';
      formattedCf.selectedValues = (cfValue === null || cfValue === void 0 ? void 0 : cfValue.rows) || [];
    } else if (formattedCf.typeId === CustomFieldTypes.SELECT_MULTI) {
      formattedCf.selectedValue = cfValue.map(function (cfVal) {
        return cfVal.value;
      });
    } else if (formattedCf.typeId === CustomFieldTypes.SELECT) {
      var options = Utils.getProperSelectedOptions(formattedCf.options);
      var selectedOption = options.find(function (option) {
        return option.id === cfValue.value;
      });
      formattedCf.selectedValue = (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) || null;
    } else {
      formattedCf.selectedValue = cfValue.value;
    }
    result.push(formattedCf);
  });
  return result;
}
// use this in case you need parsed values when you don`t use ActivityCustomFields component
export function getParsedCfValues(_ref) {
  var customFields = _ref.customFields,
    customFieldValues = _ref.customFieldValues;
  if (!customFieldValues || !customFields) return {};
  return getParsedValuesOnCfChange(setupSelectedValues(customFields, customFieldValues));
}

// formatted cf includes selectedValue(s) in it. Return cfValues in {[id1]: value1, [id2]: value2} format
export function getParsedValuesOnCfChange(customFieldsFormatted) {
  var result = {};
  customFieldsFormatted.forEach(function (field) {
    result[field.id] = field.typeId === CustomFieldTypes.ROW_GROUP ? prepareRepeatableField(field) : getFormattedFieldValue(field);
  });
  return result;
}
function getFormattedFieldValue(field) {
  if (field.typeId !== CustomFieldTypes.SELECT) return field.selectedValue;
  var options = Array.isArray(field.options) ? field.options : Object.values(field.options);
  var option = options.find(function (op) {
    return op.value === field.selectedValue;
  });
  return option ? option.id : null;
}
function prepareRepeatableField(field) {
  var result = {};
  field.selectedValues.forEach(function (row, rowIdx) {
    result[rowIdx] = {};
    row.forEach(function (childField) {
      if (childField.selectedValue === undefined) return;
      result[rowIdx][childField.id] = getFormattedFieldValue(childField);
    });
  });
  return result;
}
export function customFieldHasValue(cfValue, type) {
  if (cfValue === undefined || cfValue === null) return false;
  if (type === 7) return +cfValue;
  if (type === 8) return cfValue.length;
  return cfValue;
}
export function getUniqueCustomFieldsForActivityTypes() {
  var activityTypes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return activityTypes.reduce(function (result, _ref2) {
    var fields = _ref2.fields;
    fields === null || fields === void 0 || fields.forEach(function (field) {
      var cfKey = getColumnLabelName(field.id);
      if (result[cfKey]) return;
      result[cfKey] = field;
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        result[cfKey + CustomUnitPrefix.toLowerCase()] = _objectSpread(_objectSpread({}, field), {}, {
          label: field.label + ' ' + CustomUnitPrefix,
          typeId: CustomFieldTypes.NUMERICAL_UNIT
        });
      }
    });
    return result;
  }, {});
}
export function getMissingRequiredFieldsLabels() {
  var _activityType$fields;
  var activityType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var cfValuesParsed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!(activityType !== null && activityType !== void 0 && (_activityType$fields = activityType.fields) !== null && _activityType$fields !== void 0 && _activityType$fields.length)) return [];
  return activityType.fields.filter(function (field) {
    var _ref3;
    if (!field.required) return false;
    var cfValue = (_ref3 = cfValuesParsed && cfValuesParsed[field.id]) !== null && _ref3 !== void 0 ? _ref3 : null;
    return !customFieldHasValue(cfValue, field.typeId);
  }).map(function (field) {
    return field.label;
  });
}