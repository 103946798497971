import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_gap_attributes_list = _resolveComponent("gap-attributes-list");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "info",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.notificationMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.attributesArr, function (attributeObj, idx) {
    var _$data$errors, _$data$errors2;
    return _openBlock(), _createElementBlock("div", {
      key: attributeObj.key
    }, [_createVNode(_component_gap_attributes_list, {
      class: "mb-5",
      category: attributeObj.category,
      labels: attributeObj.labels,
      "category-error": (_$data$errors = $data.errors) === null || _$data$errors === void 0 || (_$data$errors = _$data$errors.category) === null || _$data$errors === void 0 ? void 0 : _$data$errors[idx],
      "label-errors": (_$data$errors2 = $data.errors) === null || _$data$errors2 === void 0 || (_$data$errors2 = _$data$errors2.labels) === null || _$data$errors2 === void 0 ? void 0 : _$data$errors2[idx],
      onUpdateCategory: function onUpdateCategory($event) {
        return $options.onUpdateCategory($event, idx);
      },
      onUpdateLabels: function onUpdateLabels($event) {
        return $options.onUpdateLabels($event, idx);
      }
    }, null, 8 /* PROPS */, ["category", "labels", "category-error", "label-errors", "onUpdateCategory", "onUpdateLabels"])]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.onAttributeCreate && $options.onAttributeCreate.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-3 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.createNewAttribute), 1 /* TEXT */)])]), _createVNode(_component_risma_button, {
    text: $data.translate.save,
    type: "save",
    class: "ml-auto mr-4",
    onClick: $options.onSave
  }, null, 8 /* PROPS */, ["text", "onClick"])]);
}