import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("p", {
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createTextVNode(_toDisplayString($options.formattedString) + " ", 1 /* TEXT */), $props.text.length > $props.maxChars ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createElementVNode("div", {
    class: "read-more-link glob-l1 text-rm-signal-grey-dark hover:text-rm-text ml-2 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.triggerReadMore && $options.triggerReadMore.apply($options, arguments);
    }, ["stop"]))
  }, _toDisplayString($options.readMoreTitle), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}