import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
var _hoisted_1 = {
  class: "single-select"
};
var _hoisted_2 = {
  key: 0,
  class: "flex justify-between mb-1"
};
var _hoisted_3 = ["title"];
var _hoisted_4 = ["tabindex"];
var _hoisted_5 = {
  class: "glob-l1-sb"
};
var _hoisted_6 = ["tabindex"];
var _hoisted_7 = ["title"];
var _hoisted_8 = ["placeholder"];
var _hoisted_9 = {
  class: "absolute right-4 top-3 flex"
};
var _hoisted_10 = ["tabindex"];
var _hoisted_11 = ["value", "onClick", "onMouseenter"];
var _hoisted_12 = {
  class: "min-w-36 w-full"
};
var _hoisted_13 = {
  key: 0,
  class: "mr-4 ml-8 flex-grow h-0.5px bg-rm-grey"
};
var _hoisted_14 = {
  key: 2
};
var _hoisted_15 = {
  key: 0,
  class: "glob-h3-sr sticky flex items-center py-2 px-3 justify-between bg-white"
};
var _hoisted_16 = {
  class: "italic"
};
var _hoisted_17 = {
  key: 1,
  class: "py-2 px-3 italic"
};
var _hoisted_18 = {
  key: 1,
  class: "mt-1 glob-l1 text-rm-signal-red"
};
var _hoisted_19 = {
  key: 2,
  class: "mt-1 glob-l1 text-rm-signal-grey-dark"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$props.title || $props.hasResetButton ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$props.title ? (_openBlock(), _createElementBlock("h3", {
    key: 0,
    class: _normalizeClass(["glob-h3 text-rm-text", {
      'cursor-pointer': !$props.disabled
    }]),
    title: $props.title,
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.toggle && $options.toggle.apply($options, arguments);
    }, ["prevent"]))
  }, _toDisplayString($props.title), 43 /* TEXT, CLASS, PROPS, NEED_HYDRATION */, _hoisted_3)) : _createCommentVNode("v-if", true), $props.hasResetButton ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "text-gray-820 flex items-end cursor-pointer",
    tabindex: $props.disabled ? undefined : $props.tabindex,
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('reset');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "mr-2 stroke-2"
  }), _createElementVNode("div", _hoisted_5, _toDisplayString($data.translate.resetToDefault), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "newOption", {}, undefined, true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([$options.innerSelectCalculatedClasses, $options.borderCalculatedClasses]),
    tabindex: $props.disabled ? undefined : $props.tabindex,
    onFocus: _cache[9] || (_cache[9] = function () {
      return $options.handleFocus && $options.handleFocus.apply($options, arguments);
    }),
    onClick: _cache[10] || (_cache[10] = function () {
      return $options.handleClick && $options.handleClick.apply($options, arguments);
    }),
    onBlur: _cache[11] || (_cache[11] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    }),
    onKeydown: [_cache[12] || (_cache[12] = _withKeys(_withModifiers(function () {
      return $options.prevOption && $options.prevOption.apply($options, arguments);
    }, ["prevent"]), ["up"])), _cache[13] || (_cache[13] = _withKeys(_withModifiers(function () {
      return $options.nextOption && $options.nextOption.apply($options, arguments);
    }, ["prevent"]), ["down"])), _cache[14] || (_cache[14] = function () {
      return $options.onKeyDown && $options.onKeyDown.apply($options, arguments);
    })],
    onKeyup: _cache[15] || (_cache[15] = _withKeys(_withModifiers(function () {
      return $options.onEnterClick && $options.onEnterClick.apply($options, arguments);
    }, ["stop"]), ["enter"]))
  }, [_createElementVNode("div", {
    class: _normalizeClass(["group flex items-center justify-between relative w-full duration-300 transition", $props.disabled && $data.expanded ? [$options.borderCalculatedClasses, 'h-auto absolute duration-0 border'] : ''])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["w-full", {
      'px-4 py-3': $props.selectStyle === $data.selectStyles.DEFAULT
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center", "".concat(!$options.lockedForReset ? 'pr-14' : 'pr-9')])
  }, [_renderSlot(_ctx.$slots, "singleLabel", {
    option: $options.selectedValue
  }, function () {
    var _$options$selectedVal, _$options$selectedVal2;
    return [$options.selectedValue || $options.isPlaceholderInOptions ? (_openBlock(), _createElementBlock("p", {
      key: 0,
      class: _normalizeClass(["glob-h3-sr text-rm-text", [$props.disabled && $data.expanded ? 'whitespace-normal' : 'truncate']]),
      title: ((_$options$selectedVal = $options.selectedValue) === null || _$options$selectedVal === void 0 ? void 0 : _$options$selectedVal.label) || $props.placeholder
    }, _toDisplayString(((_$options$selectedVal2 = $options.selectedValue) === null || _$options$selectedVal2 === void 0 ? void 0 : _$options$selectedVal2.label) || $props.placeholder), 11 /* TEXT, CLASS, PROPS */, _hoisted_7)) : (_openBlock(), _createElementBlock("p", {
      key: 1,
      class: _normalizeClass(["glob-h3-sr truncate", $props.disabled ? 'text-rm-urban' : 'text-rm-grey'])
    }, _toDisplayString($props.disabled ? $data.translate.noDataAvailable : $props.placeholder), 3 /* TEXT, CLASS */))];
  }, true)], 2 /* CLASS */)], 2 /* CLASS */), $props.withSearch ? _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 0,
    ref: "filterString",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.filterString = $event;
    }),
    placeholder: $props.placeholder,
    type: "text",
    class: "rounded-md absolute outline-none h-full inset-0 w-full px-4 text-rm-text",
    onBlur: _cache[3] || (_cache[3] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    }),
    onInput: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('update:searchValue', $data.filterString);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8)), [[_vShow, $data.expanded], [_vModelText, $data.filterString]]) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_9, [!$options.lockedForReset ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["w-5 h-5 rounded-sm mr-2 group/icon cursor-pointer single-select-reset", {
      'active:bg-rm-active-blue--10 hover:bg-rm-grey-light': $props.selectStyle === $data.selectStyles.DEFAULT
    }]),
    tabindex: $props.tabindex,
    onClick: _cache[5] || (_cache[5] = _withModifiers(function () {
      return $options.reset && $options.reset.apply($options, arguments);
    }, ["stop", "prevent"])),
    onKeyup: _cache[6] || (_cache[6] = _withKeys(_withModifiers(function () {
      return $options.reset && $options.reset.apply($options, arguments);
    }, ["stop"]), ["enter"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    width: "20",
    height: "20",
    class: _normalizeClass(["transform duration-500 group-hover/icon:text-rm-text", $options.iconCalculatedClasses])
  }, null, 8 /* PROPS */, ["class"])], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_10)) : _createCommentVNode("v-if", true), !$props.disabled ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["w-5 h-5 rounded-sm group/icon cursor-pointer", {
      'active:bg-rm-active-blue--10 hover:bg-rm-grey-light': $props.selectStyle === $data.selectStyles.DEFAULT
    }]),
    tabindex: -1,
    onFocus: _cache[7] || (_cache[7] = _withModifiers(function () {}, ["stop"])),
    onClick: _cache[8] || (_cache[8] = _withModifiers(function () {
      return $options.onChevronClick && $options.onChevronClick.apply($options, arguments);
    }, ["stop"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    width: "20",
    height: "20",
    class: _normalizeClass(["transform duration-500 group-hover:text-rm-text", [{
      'rotate-180 text-rm-text': $data.expanded
    }, $options.iconCalculatedClasses]])
  }, null, 8 /* PROPS */, ["class"])], 34 /* CLASS, NEED_HYDRATION */)) : _createCommentVNode("v-if", true)])], 2 /* CLASS */), _createElementVNode("div", {
    ref: "selectItems",
    class: _normalizeClass(['border border-rm-grey', 'select-items rounded-md ', {
      'bottom-full mb-1': $options.openDirectionComputed === 'top'
    }, {
      'top-full mt-1': $options.openDirectionComputed === 'below'
    }, {
      'select-hide': !$data.expanded || $props.disabled
    }])
  }, [!$props.disabled ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.values, function (option, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: option.value,
      value: option.value,
      class: _normalizeClass(['option pointer px-4 py-2 cursor-pointer flex justify-between items-center', {
        'glob-l1 groupName text-rm-grey': option.$isGroup,
        'pl-8': option.groupName,
        'bg-rm-active-blue--6': $data.focusedElIdx === idx,
        disabled: option.$disabled
      }, option.value === $props.modelValue ? 'glob-h3 text-rm-active-blue bg-rm-active-blue--10' : 'glob-h3-sr text-rm-text']),
      onClick: _withModifiers(function ($event) {
        return $options.onOptionSelect(option);
      }, ["stop"]),
      onMouseenter: function onMouseenter($event) {
        return $data.focusedElIdx = idx;
      }
    }, [_createElementVNode("div", _hoisted_12, [_renderSlot(_ctx.$slots, "option", {
      option: option
    }, function () {
      return [_createTextVNode(_toDisplayString(option.label), 1 /* TEXT */)];
    }, true)]), option.$isGroup ? (_openBlock(), _createElementBlock("div", _hoisted_13)) : _createCommentVNode("v-if", true), option.value === $props.modelValue ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 1,
      icon: "check",
      width: "20",
      height: "20",
      class: "stroke-2 text-rm-active-blue min-w-5 ml-2"
    })) : (_openBlock(), _createElementBlock("span", _hoisted_14))], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_11);
  }), 128 /* KEYED_FRAGMENT */)), !$props.allDataShown ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("p", _hoisted_16, _toDisplayString($data.translate.scrollDownForMoreResults), 1 /* TEXT */), $props.isLazyLoadingShown ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), !$options.values.length ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString($props.withSearch && $data.filterString ? $data.translate.noResultsMatchingSearchCriteria : $data.translate.listIsEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_6), $options.showFieldRequired ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString($props.requiredText), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $props.disabled && $props.helpText ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString($props.helpText), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hide]]);
}