function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllByType } from '@/api/compliance/informationAssets';
import * as RiskApi from '@/api/risk/risk';
import { getRisks } from '@/api/risma/data';
import { searchNodes } from '@/api/compliance/reports/nodes';
import InfoBox from '@/components/Atoms/Containers/InfoBox';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { MODULES } from '@/constants/modules';
var STATS_TYPE = {
  noComplianceNodes: 'noComplianceNodes',
  noRiskAssessed: 'noRiskAssessed',
  riskAssessedSystemChart: 'riskAssessedSystemChart'
};
export default {
  name: 'ComplianceStatsWidget',
  description: 'General component that use in Compliance Overview page to show data as chart or just some data',
  components: {
    InfoBox: InfoBox,
    RismaDoughnutChart: RismaDoughnutChart,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    statsType: {
      type: String,
      default: STATS_TYPE.noComplianceNodes,
      validator: function validator(type) {
        return Object.values(STATS_TYPE).includes(type);
      }
    },
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      complianceNodesCount: 0,
      systems: [],
      riskAssessedPieColors: ['#ffc103', '#57aa74'],
      riskProjects: [],
      currentRiskProject: {},
      approvedRisksNumber: 0,
      translate: {
        riskAssessedSystems: this.$trans('Risk assessed systems'),
        of: this.$trans('of'),
        systemsHaveBeenRiskAssessed: this.$trans('systems have been risk assessed'),
        noOfRiskAssessments: this.$trans('No of risk assessments'),
        numberOfComplianceNodes: this.$trans('Number of compliance nodes'),
        ofYourRisksAssessmentsHaveBeenApproved: this.$trans('of your risks assessments have been approved')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isRiskAssessedSystem: function isRiskAssessedSystem() {
      return this.statsType === STATS_TYPE.riskAssessedSystemChart;
    },
    isNoRiskAssessed: function isNoRiskAssessed() {
      return this.statsType === STATS_TYPE.noRiskAssessed;
    },
    isNoComplianceNodes: function isNoComplianceNodes() {
      return this.statsType === STATS_TYPE.noComplianceNodes;
    },
    riskAssessedPieChart: function riskAssessedPieChart() {
      return {
        datasets: [{
          backgroundColor: this.riskAssessedPieColors,
          data: [this.systems.length - this.riskAssessedSystems.length, this.riskAssessedSystems.length]
        }]
      };
    },
    riskAssessedSystems: function riskAssessedSystems() {
      return this.systems.filter(function (system) {
        return system.links && system.links[MODULES.RISK_ASSESSMENT] && system.links[MODULES.RISK_ASSESSMENT].length;
      });
    },
    riskApprovalEnabled: function riskApprovalEnabled() {
      var _this$currentRiskProj;
      return (_this$currentRiskProj = this.currentRiskProject) === null || _this$currentRiskProj === void 0 ? void 0 : _this$currentRiskProj.riskApproval;
    },
    infoBoxTitle: function infoBoxTitle() {
      if (this.isRiskAssessedSystem) {
        return this.translate.riskAssessedSystems;
      }
      if (this.isNoRiskAssessed) {
        return this.translate.noOfRiskAssessments;
      }
      if (this.isNoComplianceNodes) {
        return this.translate.numberOfComplianceNodes;
      }
      return '';
    },
    infoBoxLink: function infoBoxLink() {
      if (this.isRiskAssessedSystem) {
        return '/compliance/informationassets/type/systems';
      }
      return '';
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var promises = [];
      if (this.isNoComplianceNodes) {
        promises.push(this.getNodes());
      }
      if (this.isRiskAssessedSystem || this.isNoRiskAssessed) {
        promises = [this.prepareSys(), this.getRiskProjects()];
      }
      return Promise.all(promises).finally(function () {
        return _this.isLoaded = true;
      });
    },
    prepareSys: function prepareSys() {
      var _this2 = this;
      return this.getIASystems().then(function () {
        var riskIds = _this2.riskAssessedSystems.reduce(function (acc, system) {
          acc.push.apply(acc, _toConsumableArray(system.links[MODULES.RISK_ASSESSMENT]));
          return acc;
        }, []);
        if (riskIds.length) {
          return _this2.getRiskRisks("?idList=".concat(riskIds.join(',')));
        }
      });
    },
    getNodes: function getNodes() {
      var _this3 = this;
      return searchNodes(this.projectId).then(function (_ref) {
        var list = _ref.list;
        return _this3.complianceNodesCount = list.length - 1;
      }); // first item is a root compliance
    },
    getIASystems: function getIASystems() {
      var _this4 = this;
      return getAllByType(AssetsTypes.SYSTEMS, '?data[]=links').then(function (_ref2) {
        var list = _ref2.list;
        return _this4.systems = list;
      });
    },
    getRiskRisks: function getRiskRisks(params) {
      var _this5 = this;
      return getRisks(params).then(function (_ref3) {
        var list = _ref3.list;
        var risksApproved = list.filter(function (item) {
          return item.approved;
        });
        _this5.approvedRisksNumber = risksApproved.length;
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this6 = this;
      return RiskApi.getRiskProjects().then(function (_ref4) {
        var list = _ref4.list;
        _this6.riskProjects = list;
        _this6.currentRiskProject = _this6.riskProjects.find(function (item) {
          return +item.id === +_this6.getSettingValue('feature.systems_r_projects_id');
        });
      }).catch(function (e) {
        throw e;
      });
    }
  }
};