import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, TransitionGroup as _TransitionGroup, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 2
};
var _hoisted_4 = {
  class: "mb-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tile_card = _resolveComponent("tile-card");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_expanded_card = _resolveComponent("expanded-card");
  var _component_compact_card = _resolveComponent("compact-card");
  return _openBlock(), _createElementBlock(_Fragment, null, [$props.viewType === $data.viewTypes.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_load_more_wrapper, {
    activities: $props.activities
  }, {
    default: _withCtx(function (_ref) {
      var activitiesLimited = _ref.activitiesLimited;
      return [_createVNode(_TransitionGroup, {
        name: "fade",
        tag: "div",
        class: "grid grid-cols-4 place-items-auto py-8"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (activity) {
            return _openBlock(), _createBlock(_component_tile_card, {
              key: activity.id,
              activity: activity,
              "watermark-image": "url('/assets/svg/ikon_controls.svg')",
              onOnCompleteControl: $options.onCompleteControl,
              onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
              onOnDismissModal: _cache[0] || (_cache[0] = function ($event) {
                return _ctx.$emit('onDismissModal');
              }),
              onReloadRootPage: _cache[1] || (_cache[1] = function ($event) {
                return _ctx.$emit('reloadRootPage');
              })
            }, {
              button: _withCtx(function (slotProps) {
                return [$options.showCompleteControlButton && !$options.isSignoffPeriodPending(slotProps.activity) ? (_openBlock(), _createBlock(_component_risma_button, {
                  key: 0,
                  text: $data.translate.completion,
                  type: "save",
                  onClick: function onClick($event) {
                    return _ctx.$emit('showCompleteModal', slotProps.activity);
                  }
                }, null, 8 /* PROPS */, ["text", "onClick"])) : $options.showReviewControlButton ? (_openBlock(), _createBlock(_component_risma_button, {
                  key: 1,
                  text: $data.translate.review,
                  type: "save",
                  onClick: function onClick($event) {
                    return _ctx.$emit('showReviewModal', slotProps.activity);
                  }
                }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)];
              }),
              footer: _withCtx(function (slotProps) {
                return [$options.showCompleteControlButton && $options.isSignoffPeriodPending(slotProps.activity) ? (_openBlock(), _createBlock(_component_risma_title, {
                  key: 0,
                  title: $data.translate.signoffPeriodPending,
                  class: "text-red-50",
                  type: "medium"
                }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onOnCompleteControl", "onUpdateFromPreviewMode"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activities"])])) : _createCommentVNode("v-if", true), $props.viewType === $data.viewTypes.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_load_more_wrapper, {
    activities: $props.activities
  }, {
    default: _withCtx(function (_ref2) {
      var activitiesLimited = _ref2.activitiesLimited;
      return [_createVNode(_TransitionGroup, {
        name: "fade",
        tag: "div"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (activity) {
            return _openBlock(), _createBlock(_component_expanded_card, {
              key: activity.id,
              activity: activity,
              users: _ctx.users,
              organisations: $props.filteredOrganisations,
              "additional-fields": $props.additionalFields,
              "watermark-image": "url('/assets/svg/ikon_controls.svg')",
              onOnCompleteControl: $options.onCompleteControl,
              onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
              onOnDismissModal: _cache[2] || (_cache[2] = function ($event) {
                return _ctx.$emit('onDismissModal');
              }),
              onDeleteItem: _cache[3] || (_cache[3] = function ($event) {
                return _ctx.$emit('deleteItem', $event);
              })
            }, {
              button: _withCtx(function (slotProps) {
                return [$options.showCompleteControlButton ? (_openBlock(), _createElementBlock(_Fragment, {
                  key: 0
                }, [!$options.isSignoffPeriodPending(slotProps.activity) ? (_openBlock(), _createBlock(_component_risma_button, {
                  key: 0,
                  text: $data.translate.completion,
                  type: "save",
                  onClick: function onClick($event) {
                    return _ctx.$emit('showCompleteModal', slotProps.activity);
                  }
                }, null, 8 /* PROPS */, ["text", "onClick"])) : (_openBlock(), _createBlock(_component_risma_title, {
                  key: 1,
                  title: $data.translate.signoffPeriodPending,
                  class: "text-red-50",
                  type: "medium"
                }, null, 8 /* PROPS */, ["title"]))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), $options.showReviewControlButton ? (_openBlock(), _createBlock(_component_risma_button, {
                  key: 1,
                  text: $data.translate.review,
                  type: "save",
                  onClick: function onClick($event) {
                    return _ctx.$emit('showReviewModal', slotProps.activity);
                  }
                }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "users", "organisations", "additional-fields", "onOnCompleteControl", "onUpdateFromPreviewMode"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activities"])])) : _createCommentVNode("v-if", true), $props.viewType === $data.viewTypes.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [$props.activities.length ? (_openBlock(), _createBlock(_component_compact_card, {
    key: 0,
    module: $data.MODULE_NAME,
    activities: $props.activities,
    users: _ctx.users,
    organisations: $props.filteredOrganisations,
    "control-button-type": $props.buttonType,
    class: "mb-8",
    "watermark-image": "url('/assets/svg/ikon_controls.svg')",
    onOnCompleteButtonClick: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('showCompleteModal', $event);
    }),
    onOnReviewButtonClick: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('showReviewModal', $event);
    }),
    onOnCompleteControl: $options.onCompleteControl,
    onUpdateFromPreviewMode: $options.onUpdateFromPreviewMode,
    onOnDismissModal: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('onDismissModal');
    }),
    onDeleteItem: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('deleteItem', $event);
    })
  }, null, 8 /* PROPS */, ["module", "activities", "users", "organisations", "control-button-type", "onOnCompleteControl", "onUpdateFromPreviewMode"])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}