function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { prepareDateTimezone, formatTimeToDateFormat } from '@/utils/date';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { DateFormats } from '@/constants/DateFormats';
export var TileCardMixin = {
  computed: {
    activityIdTitle: function activityIdTitle() {
      if (this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED) {
        return this.activity.ipath;
      }
      if (this.activity.objType === ObjectTypes.RISK_RISK) {
        return this.activity.rno;
      }
      if (this.activity.objType === ObjectTypes.INFORMATION_ASSET || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER || this.activity.objType === ObjectTypes.COMPLIANCE_NODE || this.activity.objType === ObjectTypes.COMPLIANCE_NODE_LIMITED || this.activity.objType === ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE || this.activity.reportUrl) {
        return '';
      }
      return this.activity.path;
    },
    showDeadline: function showDeadline() {
      return this.activity.objType && !this.activity.isMothercontrol && (this.activity.objType.startsWith(ObjectTypes.EXECUTION_INITIATIVE) || this.activity.objType.startsWith(ObjectTypes.CONTROLS_CONTROL));
    },
    showExpirationDate: function showExpirationDate() {
      return this.activity.objType === ObjectTypes.CONTRACT || this.activity.objType === ObjectTypes.CONTRACT_LIMITED;
    },
    expirationFormat: function expirationFormat() {
      var endDateTime = this.$trans('Not set');
      if (this.activity.endDate) {
        endDateTime = formatTimeToDateFormat(this.activity.endDate);
      }
      return endDateTime;
    },
    deadlineFormat: function deadlineFormat() {
      var timezone = this.activity.deadlineTimezone;
      if (timezone) {
        return prepareDateTimezone(this.activity.deadline, timezone, DateFormats.DATE_TIME_FORMAT);
      }
      return this.activity.deadline;
    },
    trafficLight: function trafficLight() {
      var _ref, _this$activity$traffi;
      var trafficLight = (_ref = (_this$activity$traffi = this.activity.traffic_light) !== null && _this$activity$traffi !== void 0 ? _this$activity$traffi : this.activity.trafficLight) !== null && _ref !== void 0 ? _ref : null;
      return this.activity.isMothercontrol ? null : trafficLight;
    },
    trafficLightColor: function trafficLightColor() {
      if (this.activity.objType === ObjectTypes.CONTROLS_CONTROL) {
        var trafficLightNumber = this.activity.isMothercontrol ? 0 : this.activity.trafficLight;
        return this.getControlTrafficLightColorByNumber(trafficLightNumber);
      }
      if (this.activity.objType === ObjectTypes.COMPLIANCE_NODE || this.activity.objType === ObjectTypes.COMPLIANCE_NODE_LIMITED) {
        return this.getTrafficLightColorByObjectTypeAndNumber(this.activity.objType, this.activity.traffic_light);
      }
      if (this.activity.objType === ObjectTypes.INCIDENTS_INCIDENT) {
        return this.getTrafficLightColorByObjectTypeAndNumber(this.activity.objType, this.activity.severity);
      }
      if (this.activity.objType === ObjectTypes.INFORMATION_ASSET || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM || this.activity.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED || this.activity.objType === ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE) {
        return this.getTrafficLightColorByObjectTypeAndNumber(this.activity.objType, this.activity.trafficLight);
      }
      return '';
    }
  },
  methods: _objectSpread({}, mapActions(useTrafficLights, ['getControlTrafficLightColorByNumber', 'getTrafficLightColorByObjectTypeAndNumber']))
};