// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expansion-panel.default[data-v-0fb7503a]:not(.expanded):hover {
  box-shadow: 0px 0px 0px 2px rgb(235, 235, 235);
}
.expansion-panel-header:hover .chevron[data-v-0fb7503a] {
  color: rgb(68, 68, 68);
}
.fade-enter-active[data-v-0fb7503a],
.fade-leave-active[data-v-0fb7503a] {
  transition: opacity 0.3s;
}
.fade-enter-from[data-v-0fb7503a],
.fade-leave-to[data-v-0fb7503a] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
