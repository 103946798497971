import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "mt-2 ml-4"
};
var _hoisted_2 = {
  key: 1,
  class: "mt-2 ml-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_add_contracts_modal = _resolveComponent("add-contracts-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_contract_generic_table = _resolveComponent("contract-generic-table");
  var _component_preview_list = _resolveComponent("preview-list");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [$data.showAddContractsModal ? (_openBlock(), _createBlock(_component_add_contracts_modal, {
    key: 0,
    contracts: $options.availableToChooseContracts,
    "preselected-contracts": $data.relatedContracts.byLink,
    onUpdate: $options.updateSelectedContracts,
    onCloseModal: $options.toggleAddContractsModal
  }, null, 8 /* PROPS */, ["contracts", "preselected-contracts", "onUpdate", "onCloseModal"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 2
  }, _renderList($data.relatedCompanies, function (relatedCompany) {
    return _openBlock(), _createBlock(_component_preview_list, {
      key: relatedCompany.id,
      title: $options.getRelatedCompanyTitle(relatedCompany.name),
      "cookie-hidden-name": "related-contracts-contract-".concat(relatedCompany.id),
      length: relatedCompany.contracts.length,
      "content-overflowing": true,
      class: "mb-8"
    }, {
      default: _withCtx(function () {
        return [relatedCompany !== null && relatedCompany !== void 0 && relatedCompany.contracts && relatedCompany.contracts.length ? (_openBlock(), _createBlock(_component_contract_generic_table, {
          key: 0,
          "input-data": $options.sortContractsByNo(relatedCompany.contracts),
          "hidden-columns": $options.relatedCompaniesHiddenColumns,
          "workflow-statuses": $data.workflowStatuses,
          "activity-type-id": $props.data.activityTypeId,
          contacts: $data.contacts,
          class: "mt-2"
        }, null, 8 /* PROPS */, ["input-data", "hidden-columns", "workflow-statuses", "activity-type-id", "contacts"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString($options.nothingToShowMessage), 1 /* TEXT */)]))];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "cookie-hidden-name", "length"]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_preview_list, {
    key: 3,
    title: $data.translate.otherRelatedContracts,
    "cookie-hidden-name": "other-contracts-contract-".concat($props.data.id),
    length: $data.relatedContracts.byLink.length
  }, _createSlots({
    default: _withCtx(function () {
      var _$data$relatedContrac;
      return [(_$data$relatedContrac = $data.relatedContracts) !== null && _$data$relatedContrac !== void 0 && _$data$relatedContrac.byLink && $data.relatedContracts.byLink.length ? (_openBlock(), _createBlock(_component_contract_generic_table, {
        key: 0,
        "input-data": $options.sortContractsByNo($data.relatedContracts.byLink),
        "hidden-columns": $options.otherRelatedHiddenColumns,
        companies: $data.companies,
        "workflow-statuses": $data.workflowStatuses,
        class: "mt-2",
        contacts: $data.contacts,
        onDelete: $options.deleteItem
      }, null, 8 /* PROPS */, ["input-data", "hidden-columns", "companies", "workflow-statuses", "contacts", "onDelete"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString($options.nothingToShowMessage), 1 /* TEXT */)]))];
    }),
    _: 2 /* DYNAMIC */
  }, [!$props.readOnly ? {
    name: "nav-buttons",
    fn: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        class: "ml-auto stroke-2 cursor-pointer",
        icon: "plus",
        height: "35",
        width: "35",
        onClick: $options.toggleAddContractsModal
      }, null, 8 /* PROPS */, ["onClick"])];
    }),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "cookie-hidden-name", "length"])) : _createCommentVNode("v-if", true)]);
}