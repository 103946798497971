function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTrafficLights } from '@/Store/trafficLights';
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import { Colors } from '@/Colors';
import { DateFormats } from '@/constants/DateFormats';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { MODULES } from '@/constants/modules';
import { userHasAccessByModule } from '@/utils/access';
import { getProperSelectedOptions } from '@/utils/Utils';
import { prepareDateTimezone } from '@/utils/date';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { Export, capitalize } from '@/utils';
import { CellTypes } from '@/utils/export/excel';
import { setupSelectedValues } from '@/utils/CustomFields';
var HTTP_USELESS_LINK = 'http://';
export default {
  name: 'ControlHistory',
  components: {
    LinkItem: LinkItem,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    ActivityCustomFields: ActivityCustomFields
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Control object'
    }
  },
  data: function data() {
    return {
      Colors: Colors,
      RismaControlsUtils: RismaControlsUtils,
      record: this.data.history && this.data.history.length && this.data.history[0],
      mainColumns: {
        title: this.$trans('Title'),
        light: this.$trans('Traffic light'),
        deadlineText: this.$trans('Deadline text')
      },
      translate: getTranslate['ControlHistory']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), {}, {
    commonColumns: function commonColumns() {
      var columns = {
        date: this.translate.date,
        action: this.translate.action,
        user: this.translate.user,
        attachments: this.translate.attachments,
        comment: this.translate.comment
      };
      if (this.assessmentsVisible) {
        columns.assessment = this.translate.efficiencyAssessment;
        columns.assessmentComment = this.translate.efficiencyAssessmentComment;
      }
      return columns;
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (item) {
        return item.id === _this.data.activityTypeId;
      });
    },
    assessmentsVisible: function assessmentsVisible() {
      var _this$currentActivity;
      return !!this.getSettingValue('feature.controls_efficiency_enabled') && !!((_this$currentActivity = this.currentActivityType) !== null && _this$currentActivity !== void 0 && _this$currentActivity.controlsEfficiencyType);
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getControlTrafficLightByNumber', 'getCustomFieldLegend'])), {}, {
    reverseReviewsOrder: function reverseReviewsOrder(reviews) {
      return reviews.slice().reverse();
    },
    getActivityByIdAndModule: function getActivityByIdAndModule(id) {
      var result = _objectSpread({}, this.activityTypes.find(function (item) {
        return item.id === id;
      }));
      return result;
    },
    getUserById: function getUserById(id) {
      var user = this.data.users.find(function (user) {
        return user.id == id;
      });
      return user ? user.display_name : '';
    },
    formatedDate: function formatedDate(date, timezone) {
      return prepareDateTimezone(date, timezone, DateFormats.DATE_TIME_FORMAT);
    },
    getTrafficlightByNumber: function getTrafficlightByNumber(number) {
      return this.getControlTrafficLightByNumber(number, true).color;
    },
    getTrafficLightLabelByNumber: function getTrafficLightLabelByNumber(number) {
      return capitalize(this.getControlTrafficLightByNumber(number, true).legend);
    },
    getBaseTrafficlightByNumber: function getBaseTrafficlightByNumber(number) {
      return this.getControlTrafficLightByNumber(number, false);
    },
    getTrafficTitle: function getTrafficTitle(title) {
      if (title === 'red') return this.translate.completedAfterDeadline;
      return this.translate.completedOnTime;
    },
    getFullTrafficTitle: function getFullTrafficTitle(title) {
      if (title === 'red') return this.translate.theControlWasCompletedAfterDeadline;
      return this.translate.theControlWasCompletedOnTime;
    },
    getHumanReadableAction: function getHumanReadableAction(action, userId) {
      if (action === 'control_not_performed' && userId) {
        return this.$trans(RismaControlsUtils.humanReadableAction('skipped'));
      }
      return this.$trans(RismaControlsUtils.humanReadableAction(action));
    },
    showCustomFields: function showCustomFields(record) {
      if (!this.activityTypes.length || !record.activityTypeId || !record.customFieldValues || !this.getActivityByIdAndModule(record.activityTypeId).fields.length) return;
      var result = false;
      record.customFieldValues.forEach(function (field) {
        if (field.model === MODULES.CONTROL_COMPLETION && (field.value || field.rows)) {
          result = true;
        }
      });
      return result;
    },
    handleExportToExcel: function handleExportToExcel() {
      var _this2 = this;
      var ws_data = [];
      this.data.history.forEach(function (record) {
        var _record$customFieldVa;
        var title = _this2.translate.deadline + ' ' + _this2.formatedDate(record.deadline, record.deadlineTimezone);
        ws_data.push({
          title: {
            v: title
          }
        });
        ws_data.push({
          light: {
            v: _this2.$trans(_this2.getTrafficLightLabelByNumber(record.trafficLight))
          },
          deadlineText: {
            v: _this2.getTrafficTitle(record.trafficLightText)
          }
        });
        var mainFields = {};
        for (var key in _this2.commonColumns) {
          mainFields[key] = {
            v: _this2.commonColumns[key]
          };
        }
        ws_data.push(mainFields);
        var reviews = _this2.prepareReviews(record.reviews, record.deadlineTimezone);
        ws_data = [].concat(_toConsumableArray(ws_data), _toConsumableArray(reviews));
        if (!((_record$customFieldVa = record.customFieldValues) !== null && _record$customFieldVa !== void 0 && _record$customFieldVa.length)) return;
        ws_data.push({
          title: {
            v: _this2.translate.customFields
          }
        });
        var activityType = _this2.getActivityByIdAndModule(record.activityTypeId);
        var preparedCfWithValues = _this2.prepareCfWithValues(activityType === null || activityType === void 0 ? void 0 : activityType.fields, record.customFieldValues);
        preparedCfWithValues.forEach(function (cfWithValue) {
          var userHasAccess = cfWithValue && userHasAccessByModule(_this2.currentUser, cfWithValue.module, cfWithValue.visibilityUserLevel);
          if (!userHasAccess) return;
          if (cfWithValue.typeId !== CustomFieldTypes.ROW_GROUP) {
            var _this2$getPreparedFie = _this2.getPreparedFieldValue(cfWithValue),
              type = _this2$getPreparedFie.type,
              value = _this2$getPreparedFie.value;
            ws_data.push({
              name: {
                v: cfWithValue.label
              },
              value: {
                v: value !== null && value !== void 0 ? value : '',
                t: type
              }
            });
          } else {
            var customFieldRows = Array.isArray(cfWithValue.selectedValues) ? cfWithValue.selectedValues : Object.values(cfWithValue.selectedValues);
            var columnLabels = {};
            var idRowOrders = [];
            cfWithValue.children.forEach(function (child) {
              idRowOrders.push(child.id);
              columnLabels['label' + child.id] = {
                v: child.label
              };
            });
            ws_data.push({
              name: {
                v: cfWithValue.label + ':'
              }
            });
            ws_data.push(columnLabels);
            customFieldRows.forEach(function (row) {
              var preparedValues = {};
              var preparedCfWithValues = _this2.prepareCfWithValues(cfWithValue.children, row);
              preparedCfWithValues.forEach(function (fieldValueR) {
                var _this2$getPreparedFie2 = _this2.getPreparedFieldValue(fieldValueR),
                  type = _this2$getPreparedFie2.type,
                  value = _this2$getPreparedFie2.value;
                preparedValues[fieldValueR.id] = {
                  value: value,
                  type: type
                };
              });
              var rowData = {};
              idRowOrders.forEach(function (item) {
                var _preparedValuesItem$v;
                var preparedValuesItem = preparedValues[item];
                rowData['label' + item] = {
                  v: (_preparedValuesItem$v = preparedValuesItem === null || preparedValuesItem === void 0 ? void 0 : preparedValuesItem.value) !== null && _preparedValuesItem$v !== void 0 ? _preparedValuesItem$v : '',
                  t: preparedValuesItem === null || preparedValuesItem === void 0 ? void 0 : preparedValuesItem.type
                };
              });
              ws_data.push(rowData);
            });
          }
        });
        ws_data.push([]); //empty line
      });
      Export.json2Excel(ws_data, [].concat(_toConsumableArray(Object.values(this.mainColumns)), _toConsumableArray(Object.values(this.commonColumns))));
    },
    prepareCfWithValues: function prepareCfWithValues(allFields, customFieldValues) {
      var cfValuesFieldsIds = customFieldValues.map(function (cf) {
        return cf.fieldId;
      });
      var filteredCf = allFields.filter(function (cf) {
        return cfValuesFieldsIds.includes(cf.id);
      });
      return setupSelectedValues(filteredCf, customFieldValues);
    },
    getPreparedFieldValue: function getPreparedFieldValue(cfWithValue) {
      var _options$find;
      var value = '';
      var type = '';
      var options = getProperSelectedOptions(cfWithValue === null || cfWithValue === void 0 ? void 0 : cfWithValue.options);
      switch (cfWithValue.typeId) {
        case CustomFieldTypes.SELECT_MULTI:
          value = options.filter(function (opt) {
            return cfWithValue.selectedValue.includes(opt.id);
          }).map(function (opt) {
            return opt.value;
          }).join(', ');
          break;
        case CustomFieldTypes.SELECT:
          value = ((_options$find = options.find(function (opt) {
            return opt.id === cfWithValue.selectedValue;
          })) === null || _options$find === void 0 ? void 0 : _options$find.value) || cfWithValue.selectedValue;
          break;
        case CustomFieldTypes.TRAFFIC_LIGHT:
          value = this.getCustomFieldLegend(cfWithValue.selectedValue);
          break;
        case CustomFieldTypes.NUMERICAL:
          value = cfWithValue.selectedValue;
          type = CellTypes.NUMBER;
          break;
        default:
          value = cfWithValue.selectedValue;
      }
      return {
        value: value,
        type: type
      };
    },
    prepareReviews: function prepareReviews(list, timezone) {
      var _this3 = this;
      var records = [];
      list.forEach(function (item) {
        var lines = [];
        var user = _this3.data.users.find(function (user) {
          return user.id == item.userId;
        });
        lines.push({
          date: {
            v: _this3.formatedDate(item.created, timezone)
          },
          action: {
            v: _this3.$trans(RismaControlsUtils.humanReadableAction(item.action))
          },
          user: {
            v: user ? user.display_name : item.userId
          },
          attachments: {
            v: item.urlTitle || ''
          },
          comment: {
            v: item.comment || ''
          }
        });
        if (_this3.assessmentsVisible && item.completedEfficiency !== undefined && item.completedEfficiency !== null) {
          var _this3$getBaseTraffic;
          lines[lines.length - 1].assessment = {
            v: _this3.$trans(capitalize(((_this3$getBaseTraffic = _this3.getBaseTrafficlightByNumber(item.completedEfficiency)) === null || _this3$getBaseTraffic === void 0 ? void 0 : _this3$getBaseTraffic.legend) || ''))
          };
          lines[lines.length - 1].assessmentComment = {
            v: item.completedEfficiencyComment
          };
        }
        if (item.files) {
          item.files.forEach(function (file, index) {
            var attachments = {
              v: file.name
            };
            if (!item.urlTitle && index === 0) {
              lines[lines.length - 1].attachments = attachments;
            } else {
              lines.push({
                date: {
                  v: ''
                },
                action: {
                  v: ''
                },
                user: {
                  v: ''
                },
                attachments: attachments,
                comment: {
                  v: ''
                }
              });
            }
          });
        }
        records = [].concat(_toConsumableArray(records), lines);
      });
      return records;
    },
    isCorrectUrl: function isCorrectUrl(url) {
      return url && url !== HTTP_USELESS_LINK;
    },
    getAttachmentLink: function getAttachmentLink(attachment) {
      return attachment.mimeType === 'text/uri-list' ? attachment.url : "/api/2.0/controls/files/".concat(attachment.id);
    }
  })
};