import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "underline cursor-pointer"
};
var _hoisted_2 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("span", _hoisted_1, [!$data.selectActive ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.activateSelect && $options.activateSelect.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createTextVNode(_toDisplayString($props.modelValue), 1 /* TEXT */)];
  })])) : _createCommentVNode("v-if", true), $data.selectActive ? _withDirectives((_openBlock(), _createElementBlock("select", {
    key: 1,
    ref: "select",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.filterValue = $event;
    }),
    onChange: _cache[2] || (_cache[2] = function () {
      return $options.deactivateSelect && $options.deactivateSelect.apply($options, arguments);
    }),
    onBlur: _cache[3] || (_cache[3] = function () {
      return $options.deactivateSelect && $options.deactivateSelect.apply($options, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, function (option) {
    return _openBlock(), _createElementBlock("option", {
      key: option,
      value: option
    }, _toDisplayString(option), 9 /* TEXT, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))], 544 /* NEED_HYDRATION, NEED_PATCH */)), [[_vModelSelect, $options.filterValue]]) : _createCommentVNode("v-if", true)]);
}