import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "grid lg:grid-cols-two-col lg:justify-around items-center lg:flex-row flex-col -my-16 -mx-6 lg:h-103vh"
};
var _hoisted_2 = {
  class: "w-full m-auto h-full flex flex-col row justify-center"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "max-w-500px mx-auto min-w-200px w-full"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = ["autocomplete"];
var _hoisted_7 = {
  class: "flex justify-between"
};
var _hoisted_8 = {
  class: "max-w-500px mx-auto"
};
var _hoisted_9 = ["innerHTML"];
var _hoisted_10 = {
  class: "max-w-500px mx-auto w-500px mb-4 lg:mb-0"
};
var _hoisted_11 = {
  class: "w-full h-full bg-gray-65 relative flex flex-col justify-center items-center right-column px-1/20"
};
var _hoisted_12 = {
  class: "flex items-center mb-8 mt-12"
};
var _hoisted_13 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_privacy_modal = _resolveComponent("privacy-modal");
  var _component_MfaLogin = _resolveComponent("MfaLogin");
  var _component_modal = _resolveComponent("modal");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_router_link = _resolveComponent("router-link");
  return $data.isMounted ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.showPrivacyModal ? (_openBlock(), _createBlock(_component_privacy_modal, {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showPrivacyModal = false;
    })
  })) : _createCommentVNode("v-if", true), $data.showMFAModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    "show-buttons": false,
    header: "",
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      $data.showMFAModal = false;
      $data.data.mfaToken = '';
      $data.errorFromBackend = '';
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_MfaLogin, {
        error: $data.errorFromBackend,
        onLogin: $options.handleMfaLogin
      }, null, 8 /* PROPS */, ["error", "onLogin"])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [$data.errorFromBackend && !$data.showMFAModal ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    class: "w-full-110 mx-auto",
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.errorFromBackend = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorFromBackend)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("img", {
    src: $options.logoSrc,
    class: "logo-image mx-auto mt-12"
  }, null, 8 /* PROPS */, _hoisted_3), _createVNode(_component_risma_title, {
    title: $data.translate.logInToYourAccount,
    truncate: false,
    type: "big",
    class: "text-center welcome-text mb-8"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [$options.showSingleSignon ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_button, {
    text: $data.translate.singleSignon,
    type: "save",
    "is-custom-button-size": true,
    class: "login-button flex justify-center w-full mb-4",
    onClick: $options.handleAzure
  }, {
    default: _withCtx(function () {
      return _cache[7] || (_cache[7] = [_createElementVNode("img", {
        class: "mr-2",
        style: {
          "vertical-align": "unset"
        },
        src: "/assets/svg/azure-white.svg",
        width: "18",
        height: "18",
        alt: "Active Directory login"
      }, null, -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"]), !$options.hideLoginFields ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "dropdown-with-lines py-4 overflow-hidden flex justify-center items-center cursor-pointer",
    "data-test-id": "login-switcher",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $setup.isDefaultLoginShown = !$setup.isDefaultLoginShown;
    })
  }, [_createVNode(_component_risma_title, {
    type: "small",
    class: "mr-2",
    title: $data.translate.orLogInWithUsername
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass({
      'rotate-180': $setup.isDefaultLoginShown
    })
  }, null, 8 /* PROPS */, ["class"])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), ($setup.isDefaultLoginShown || !$options.showSingleSignon) && !$options.hideLoginFields ? (_openBlock(), _createElementBlock("form", {
    key: 1,
    autocomplete: !$options.hasAutoComplete
  }, [_createVNode(_component_risma_title, {
    type: "medium",
    title: $data.translate.username,
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.data.username,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.data.username = $event;
    }),
    class: "mb-4",
    placeholder: $data.translate.enterUsername,
    autocomplete: "username",
    type: "text",
    onOnEnter: $options.moveToPassword
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onOnEnter"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    type: "medium",
    title: $data.translate.password,
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_router_link, {
    to: "/forgot-password",
    tabindex: "-1",
    class: "underline"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.forgotYourPassword), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createVNode(_component_input_field, {
    modelValue: $data.data.password,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.data.password = $event;
    }),
    class: "mb-4 hover:border-blue-650",
    autocomplete: "current-password",
    "input-id": "password-field",
    placeholder: $data.translate.enterPassword,
    type: "password",
    onOnEnter: $options.handleSubmit
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onOnEnter"]), _createVNode(_component_risma_button, {
    text: $data.translate.logIn,
    type: "save",
    "is-custom-button-size": true,
    class: _normalizeClass(["login-button flex justify-center w-full mb-6", {
      'mt-4': $options.showSingleSignon
    }]),
    title: $options.loginButtonMouseOver,
    onClick: $options.handleSubmit
  }, null, 8 /* PROPS */, ["text", "class", "title", "onClick"])], 8 /* PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", {
    class: "mb-2 text-justify",
    innerHTML: $options.disclaimerTitle
  }, null, 8 /* PROPS */, _hoisted_9)]), _createElementVNode("p", _hoisted_10, [_createTextVNode(" © 2014-" + _toDisplayString($options.getYear) + " RISMA Systems A/S | Version: " + _toDisplayString($options.rismaversion) + " ", 1 /* TEXT */), $options.privacyPolicy ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    class: "cursor-pointer hover:underline text-blue-750",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $data.showPrivacyModal = true;
    })
  }, " | " + _toDisplayString($data.translate.privacyPolicy), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.news,
    type: "medium",
    class: "mr-2 p-5-30 rounded-3xl bg-green-350 news-rect uppercase"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.csrdSolution
  }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_risma_title, {
    title: $data.translate.rightColumnSecondLineText,
    truncate: false,
    class: "text-center w-3/4 track-text"
  }, null, 8 /* PROPS */, ["title"]), _cache[8] || (_cache[8] = _createElementVNode("div", {
    class: "right-column-image h-500px w-full mb-8 mt-12"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", {
    innerHTML: $options.bottomLineText
  }, null, 8 /* PROPS */, _hoisted_13)])])) : _createCommentVNode("v-if", true);
}