import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative w-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dep_relations = _resolveComponent("dep-relations");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_dep_relations, {
    "local-storage-key": "kitchen-deprelations",
    tabs: $data.tabsDepRelations
  }, {
    delegation: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("text on tab delegation")]);
    }),
    details: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createTextVNode("text on tab details")]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["tabs"])]);
}