import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_compact_tab_item = _resolveComponent("compact-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_compact_tab_item, {
    link: "uri-to-navigate-to"
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("Text to be displayed")]);
    }),
    _: 1 /* STABLE */
  })]);
}