// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-status[data-v-5b61537a] {
  position: absolute;
  top: 1rem;
  right: 2rem;
  background-color: #BEBEBE;
  padding: 8px 10px;
  border-radius: 2px;
}
.save-status h3[data-v-5b61537a] {
  color: #4D4D4D;
  font-size: 130%;
  font-weight: 300;
  margin: 0;
  line-height: 25px;
  text-align: right;
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
