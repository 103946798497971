// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table[data-v-18ebf7f0] {
  background-color: rgb(249.4347826087, 249.4347826087, 247.7652173913);
  border: 1px solid rgb(249.4347826087, 249.4347826087, 247.7652173913);
}
table th[data-v-18ebf7f0],
table td[data-v-18ebf7f0] {
  border: 1px solid #FFFFFF;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  line-height: 14px;
  word-break: break-all;
  padding: 5px;
}
table th.gap-table-gap-cell[data-v-18ebf7f0] {
  vertical-align: top;
}
tr.gap-table-totals-row:hover td[data-v-18ebf7f0],
tr:hover td.gap-table-cell[data-v-18ebf7f0],
tr:hover td.gap-table-records-count[data-v-18ebf7f0] {
  background: #0e2954;
  color: #FFFFFF;
}
.dark-gray[data-v-18ebf7f0] {
  background: #737373;
  color: #FFFFFF;
}
.gap-table-cell[data-v-18ebf7f0] {
  text-align: left;
  border-right: 1px solid #737373;
  max-width: 280px;
  min-width: 280px;
  padding-left: 20px;
}
.gap-table-cell-records-count[data-v-18ebf7f0] {
  max-width: 170px;
  min-width: 170px;
  padding: 5px;
}
.gap-table-totals-row td[data-v-18ebf7f0] {
  background-color: rgb(249.4347826087, 249.4347826087, 247.7652173913);
  padding: 5px;
}
.gap-table-totals-cell[data-v-18ebf7f0]:first-child {
  text-align: left;
  padding-left: 20px;
}
.gap-table-gap-cell[data-v-18ebf7f0] {
  max-width: 95px;
  min-width: 95px;
  width: 95px;
  color: #FFFFFF;
}
.gap-table-records-count[data-v-18ebf7f0] {
  max-width: 170px;
  min-width: 170px;
  width: 170px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
