export var VIEW_TYPES = {
  TILES: 'tiles',
  COMPACT: 'compact',
  EXPANDED: 'expanded',
  INVERTED_COMPACT: 'inverted_compact',
  RISMA_TABLE: 'rismatable',
  TREE: 'tree',
  ROADMAP: 'roadmap',
  DATATABLE: 'datatable'
};
export var STANDARD_VIEWS = [{
  title: 'Tiles view',
  name: VIEW_TYPES.TILES
}, {
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Expanded view',
  name: VIEW_TYPES.EXPANDED
}];
export var INCIDENTS_VIEWS = [{
  title: 'Tiles view',
  name: VIEW_TYPES.TILES
}, {
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}];
export var CONTROLS_VIEWS = [].concat(STANDARD_VIEWS, [{
  title: 'Table view',
  name: VIEW_TYPES.RISMA_TABLE
}, {
  title: 'Tree view',
  name: VIEW_TYPES.TREE
}]);
export var MY_RISKS_VIEWS = [{
  title: 'Tiles view',
  name: VIEW_TYPES.TILES
}, {
  title: 'Expanded view',
  name: VIEW_TYPES.EXPANDED
}];
export var MY_ACTIONS_VIEWS = [{
  title: 'Tiles view',
  name: VIEW_TYPES.TILES
}, {
  title: 'Expanded view',
  name: VIEW_TYPES.EXPANDED
}, {
  title: 'Roadmap view',
  name: VIEW_TYPES.ROADMAP
}];
export var OVERALL_INITIATIVES_VIEWS = [{
  title: 'Tiles view',
  name: VIEW_TYPES.TILES
}, {
  title: 'Expanded view',
  name: VIEW_TYPES.EXPANDED
}, {
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Roadmap view',
  name: VIEW_TYPES.ROADMAP
}, {
  title: 'Tree view',
  name: VIEW_TYPES.TREE
}];
export var AWARENESS_DASHBOARDS_VIEWS = [{
  title: 'Chart view',
  name: VIEW_TYPES.TILES
}, {
  title: 'Table view',
  name: VIEW_TYPES.COMPACT
}];
export var OVERVIEW_CONTRACTS_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Datatable view',
  name: VIEW_TYPES.DATATABLE
}];
export var MINI_TREE_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Expanded view',
  name: VIEW_TYPES.EXPANDED
}];
export var DATAFLOW_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Table view',
  name: VIEW_TYPES.RISMA_TABLE
}];
export var ARTICLE_30_TABLE_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Inverted expanded view',
  name: VIEW_TYPES.INVERTED_COMPACT
}, {
  title: 'Expanded view',
  name: VIEW_TYPES.EXPANDED
}];
export var PROCESS_TREE_REPORT_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Table view',
  name: VIEW_TYPES.RISMA_TABLE
}];
export var COMPLIANCE_REPORT_GAP_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Table view',
  name: VIEW_TYPES.RISMA_TABLE
}];
export var NODES_REPORT_VIEWS = [{
  title: 'Compact view',
  name: VIEW_TYPES.COMPACT
}, {
  title: 'Table view',
  name: VIEW_TYPES.RISMA_TABLE
}];