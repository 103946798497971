function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { useRiskProjectsStore } from '@/Store/riskProjectsStore';
import { updateUser, changeUserActiveStatus, getUsers } from '@/api/admin/user';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import UserEditModal from '@/components/Molecules/UserEditModal';
import RismaUserRightsPicker from '@/components/Molecules/Compliance/RismaUserRightsPicker';
import RismaModulesRightsPicker from '@/components/Molecules/Compliance/RismaModulesRightsPicker';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import Modal from '@/components/Molecules/Modal/Modal';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { Colors } from '@/Colors';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes, ObjectTypeToUserField } from '@/constants/ObjectTypes';
import { SortDirection } from '@/constants/SortDirection';
import { TinyMceBoxTypes } from '@/utils/base/TinyMceBoxConfigs';
import { searchByFields } from '@/utils/search';
import { projectsByUser, getAllModules } from '@/utils/projectsByUser';
import { generateKey } from '@/utils/Utils';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { MODULES } from '@/constants/modules';
export default {
  name: 'UserRightsTable',
  introduction: 'Static table with users',
  description: 'This component shows available users with <static-table> inside. No props. Have this.$emit("error")',
  token: '<user-rights-table @error="setError"></user-rights-table>',
  components: {
    RismaToggle: RismaToggle,
    UserEditModal: UserEditModal,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaUserRightsPicker: RismaUserRightsPicker,
    RismaModulesRightsPicker: RismaModulesRightsPicker,
    PreviewModalLink: PreviewModalLink,
    RismaTable: RismaTable,
    Modal: Modal,
    TinyMceBox: TinyMceBox,
    Checkboxes: Checkboxes
  },
  mixins: [CommonAssetsMixin],
  emits: ['dismissModal', 'showNotification'],
  data: function data() {
    return {
      userChange: {},
      showAllUserEmailsModal: false,
      openModal: false,
      colors: Colors,
      type: '',
      filteringParameters: {
        search: '',
        showDeactivated: false
      },
      inputBaseStyles: 'border-2 border-gray-350 px-3 py-2 rounded-md min-h-40px transition duration-300 text-base text-gray-750 placeholder-italic placeholder-rm-urban',
      inputInteractionStyles: 'hover:border-gray-450 focus:outline-none focus:border-blue-450 disabled:text-rm-urban',
      sortBy: 'display_name',
      sortOrder: SortDirection.ASC,
      SortDirection: SortDirection,
      tableGeneratedKey: generateKey(),
      stateKey: 'users_rights_table_key',
      TinyMceBoxTypes: TinyMceBoxTypes,
      userEmails: '',
      currentOpened: {},
      fieldToLocked: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypeToUserField[ObjectTypes.EXECUTION_INITIATIVE], 'level_e_locked'), ObjectTypeToUserField[ObjectTypes.RISK_RISK], 'level_r_locked'), ObjectTypeToUserField[ObjectTypes.CONTROLS_CONTROL], 'level_c_locked'), ObjectTypeToUserField[ObjectTypes.INCIDENTS_INCIDENT], 'level_incidents_locked'), ObjectTypeToUserField[ObjectTypes.CONTRACT], 'level_contracts_locked'), ObjectTypeToUserField[ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE], 'level_process_library_locked'),
      translate: getTranslate['UserRightsTable']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    getAllSettings: 'getAllSettings'
  })), mapState(useUserStore, ['currentUser'])), mapState(useComplianceProjectsStore, {
    complianceProjectsStore: 'projects'
  })), mapState(useRiskProjectsStore, {
    riskProjectsStore: 'projects'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    copyEmailsMessage: function copyEmailsMessage() {
      return this.translate.copiedEmailsToClipboard + '(Ctrl+A, Ctrl+C)';
    },
    filteredProjects: function filteredProjects() {
      if (!this.currentUser || !this.currentUser.projects) return [];
      return this.getFilteredProjects(this.currentUser.projects);
    },
    staticColumns: function staticColumns() {
      var column = [{
        title: ''
      }, {
        title: ''
      }, {
        title: this.translate.username,
        sortingKey: 'display_name'
      }, {
        title: this.translate.fullName,
        sortingKey: 'fullname',
        style: {
          whiteSpace: 'nowrap'
        }
      }, {
        title: this.translate.initials,
        sortingKey: 'initials'
      }, {
        title: this.translate.email,
        sortingKey: 'email'
      }];
      if (this.isPerformanceReportEnabled) {
        column.push({
          title: 'GRC Intelligence Center',
          style: {
            marginTop: '-60px',
            width: '100px'
          }
        });
      }
      column.push({
        title: 'Legalhub'
      });
      return column;
    },
    moduleSettings: function moduleSettings() {
      return getAllModules(this.getAllSettings, null);
    },
    rightsTitles: function rightsTitles() {
      var list = [{
        title: 'Admin',
        style: {
          left: '3.4rem'
        },
        class: 'activity-header-cell'
      }];
      this.moduleSettings.forEach(function (item) {
        if (item.rights) {
          list.push({
            title: item.title,
            class: 'activity-header-cell'
          });
        }
      });
      return list;
    },
    moduleFields: function moduleFields() {
      var array = [];
      this.moduleSettings.forEach(function (item) {
        if (item.rights) {
          array.push(item.property);
        }
      });
      return array;
    },
    projects: function projects() {
      return projectsByUser(this.currentUser, this.getSettingValue);
    },
    filteredUsers: function filteredUsers() {
      return this.users.filter(function (_ref) {
        var anonymized = _ref.anonymized;
        return !anonymized;
      });
    },
    filteredUserList: function filteredUserList() {
      var users = _toConsumableArray(this.filteredUsers);
      if (this.filteringParameters.search.length) {
        var fields = ['display_name', 'email', 'fullname', 'initials', 'userLicenses'];
        users = searchByFields(users, fields, this.filteringParameters.search);
      }
      if (!this.filteringParameters.showDeactivated) {
        users = users.filter(function (user) {
          return user.active !== 0;
        });
      }
      return users;
    },
    sortedUserList: function sortedUserList() {
      var _this = this;
      if (this.sortBy) {
        return _toConsumableArray(this.filteredUserList).sort(function (a, b) {
          var fieldA = (a[_this.sortBy] || '').toLowerCase();
          var fieldB = (b[_this.sortBy] || '').toLowerCase();
          var sortValue = new Intl.Collator().compare(fieldA, fieldB);
          return _this.sortOrder === SortDirection.ASC ? sortValue : -sortValue;
        });
      }
      return this.filteredUserList;
    },
    projectColumns: function projectColumns() {
      return this.filteredProjects.map(function (project) {
        var icon = project.objType.startsWith(ObjectTypes.COMPLIANCE_PROJECT) ? 'icon_compliance' : 'ikon_risk';
        return {
          title: project.title,
          style: {
            background: "url(/assets/svg/".concat(icon, ".svg) 10% center / 25px 25px no-repeat"),
            padding: '.4rem 2rem .2rem 3rem'
          },
          class: 'activity-header-cell'
        };
      });
    },
    columns: function columns() {
      // column element has title, style properties
      return [].concat(_toConsumableArray(this.staticColumns), _toConsumableArray(this.projectColumns), _toConsumableArray(this.rightsTitles));
    },
    isPerformanceReportEnabled: function isPerformanceReportEnabled() {
      return this.getSettingValue('feature.performance_report_enabled');
    },
    isUserPreviewModalEnabled: function isUserPreviewModalEnabled() {
      return this.getSettingValue('feature.user_preview_modal_enable');
    },
    isUserRightsTableNewEnabled: function isUserRightsTableNewEnabled() {
      return this.getSettingValue('feature.thin_user_rights_table_enabled');
    }
  }),
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useUserStore, {
    setCurrentUser: 'set'
  })), mapActions(useUsersStore, {
    updateUsers: 'update'
  })), {}, {
    setOpened: function setOpened(columnIndex, rowIndex, type) {
      if (this.currentOpened.type === type && this.currentOpened.columnIndex === columnIndex && this.currentOpened.rowIndex === rowIndex) {
        this.currentOpened = {};
        return;
      }
      this.currentOpened = {
        columnIndex: columnIndex,
        rowIndex: rowIndex,
        type: type
      };
    },
    buildEmails: function buildEmails() {
      this.userEmails = this.users.filter(function (user) {
        return user.active;
      }).map(function (user) {
        return user.email;
      }).join('; ');
      this.showAllUserEmailsModal = true;
    },
    isAzureUser: function isAzureUser(user) {
      return user.ad_objectid && !this.getSettingValue('app.ignore_ad_permissions');
    },
    getUsersBE: function getUsersBE(params) {
      var _this2 = this;
      return getUsers(params).catch(function (error) {
        _this2.notifyError(error.fullError.message);
      });
    },
    updateUserModule: function updateUserModule(data) {
      var id = data.id,
        value = data.value,
        field = data.field;
      if (id === this.currentUser.id && field === 'all') {
        // you can`t remove rights from yourself
        value.target.checked = true;
        this.notifyError(this.translate.warningYouCannotPerformThisOperation);
        return;
      }
      this.updateUserFields(data);
    },
    updateUserFields: function updateUserFields(data) {
      var _this3 = this;
      var id = data.id,
        field = data.field,
        value = data.value;
      var user = _objectSpread({}, this.users.find(function (user) {
        return user.id === id;
      }));
      if (field === 'all') {
        user.level_admin = value.target.checked ? 1 : 0;
        user.is_admin = value.target.checked;
        this.moduleFields.forEach(function (mField) {
          user[mField] = value.target.checked ? UserLevels.ADMIN : UserLevels.NONE;
          var lockedField = _this3.fieldToLocked[mField];
          if (lockedField) user[lockedField] = 0;
        });
      } else if (value === UserLevels.ADMIN) {
        user[field] = value;
        var lockedField = this.fieldToLocked[field];
        if (lockedField) user[lockedField] = 0;
      } else {
        user[field] = value;
      }
      updateUser(user, user.id).then(function (user) {
        if (id === _this3.currentUser.id) {
          _this3.setCurrentUser(user);
        }
      }).catch(function (error) {
        return _this3.notifyError(error.response.error);
      });
    },
    getMaxIcon: function getMaxIcon(user) {
      var classTags = ['modal-icon icon-risma-noAccess', 'modal-icon icon-risma-user', 'modal-icon icon-risma-privUser', 'modal-icon icon-risma-superUser', 'modal-icon icon-risma-admin'];
      var max = Math.max(+user.level_execution, +user.level_risk, +user.level_controls, +user.level_incidents, +user.level_contracts, +user.level_information_assets);
      return classTags[max];
    },
    notifyError: function notifyError(error) {
      this.$emit('showNotification', {
        type: 'error',
        message: error
      });
    },
    toggleModal: function toggleModal(userId) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'update';
      this.openModal = !this.openModal;
      this.information = userId !== -1 ? this.users.find(function (user) {
        return user.id === userId;
      }) : undefined;
      this.type = type;
    },
    changeUser: function changeUser(updatedUser) {
      var user = this.users.find(function (user) {
        return user.id === updatedUser.id;
      });
      var completeUser = Object.assign({}, user, updatedUser);
      this.updateUsers(completeUser);
    },
    changeActiveStatus: function changeActiveStatus(userId) {
      var _this4 = this;
      if (userId === this.currentUser.id) {
        this.notifyError(this.translate.warningYouCannotPerformThisOperation);
        return;
      }
      this.userChange = this.users.find(function (user) {
        return user.id === userId;
      });
      this.$confirm(this.userChange.active ? this.translate.deactivateUser : this.translate.activateUser, "".concat(this.userChange.active ? this.translate.areYouSureYouWantToDeactivate : this.translate.areYouSureYouWantToActivate, " ").concat(this.userChange.display_name, " ?"), function (res) {
        return res && _this4.handleChangeActiveStatus();
      }, {
        buttonOkText: this.translate.confirm,
        buttonDismissText: this.translate.cancel
      });
    },
    handleChangeActiveStatus: function handleChangeActiveStatus() {
      var _this5 = this;
      var data = this.userChange.active ? {} : {
        undelete: 1
      };
      changeUserActiveStatus(this.userChange.id, data, this.userChange).then(function (response) {
        var message = _this5.$trans("User ".concat(response.display_name, " ").concat(response.active ? 'activated' : 'deactivated'));
        _this5.$emit('showNotification', {
          type: 'success',
          message: message
        });
        _this5.userChange = {};
      }).catch(function (error) {
        _this5.notifyError(error.response.error);
        _this5.userChange = {};
      });
    },
    sortByField: function sortByField(field) {
      if (field !== this.sortBy) {
        this.sortBy = field;
        this.sortOrder = SortDirection.ASC;
      } else {
        this.sortOrder = this.sortOrder === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      }
    },
    getFilteredProjects: function getFilteredProjects(userProjects) {
      if (!userProjects) return [];
      var userComplianceProjects = userProjects[MODULES.COMPLIANCE] || [];
      var userRiskProjects = userProjects[MODULES.RISK] || [];
      var complianceProjects = this.complianceProjectsStore.filter(function (project) {
        return userComplianceProjects.some(function (p) {
          return p.id === project.id;
        });
      }).map(function (project) {
        return _objectSpread(_objectSpread({}, project), userComplianceProjects.find(function (p) {
          return p.id === project.id;
        }));
      });
      var riskProjects = this.riskProjectsStore.filter(function (project) {
        return !project.isErm && userRiskProjects.some(function (p) {
          return p.id === project.id;
        });
      }).map(function (project) {
        return _objectSpread(_objectSpread({}, project), userRiskProjects.find(function (p) {
          return p.id === project.id;
        }));
      });
      return [].concat(_toConsumableArray(riskProjects), _toConsumableArray(complianceProjects));
    },
    notifyCreateUser: function notifyCreateUser(user) {
      if (this.isUserRightsTableNewEnabled) {
        this.$emit('showNotification', {
          type: 'success',
          message: user.display_name,
          header: "".concat(this.translate.user, " ").concat(this.translate.created),
          url: '/user/' + user.id
        });
        return;
      }
      this.$emit('showNotification', {
        type: 'success',
        message: "".concat(this.translate.user, " ").concat(user.display_name, " ").concat(this.translate.created)
      });
    }
  })
};