import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  Tree: function Tree() {
    return {
      deleteNode: i18n.t('Delete node'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      showLevels: i18n.t('Show levels'),
      visibleLevels: i18n.t('Visible levels'),
      progressShown: i18n.t('Progress shown'),
      nodesContainingDataFlow: i18n.t('Nodes containing data flow'),
      newNode: i18n.t('New node'),
      enterTitle: getTitlePlaceholder(),
      copyOf: i18n.t('Copy of'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      andUnderlyingNodesPleaseNoteThatLinksToAn: i18n.t('and underlying nodes? Please note that links to and from this node will also be deleted. As well as any risk assessments.'),
      areYouSureYouWouldLikeToMoveThisNode: i18n.t('Are you sure you would like to move this node?'),
      title: i18n.t('Title'),
      question: i18n.t('Question'),
      gap: i18n.t('Gap'),
      hide: i18n.t('Hide'),
      show: i18n.t('Show'),
      noNodesToShow: i18n.t('No nodes to show'),
      loading: i18n.t('Loading'),
      noFiltersApplied: i18n.t('No filters applied'),
      organisations: i18n.t('Organisations'),
      create: i18n.t('Create'),
      update: i18n.t('Update'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  },
  RismaTree: function RismaTree() {
    return {
      areYouSureYouWouldLikeToMoveThisNode: i18n.t('Are you sure you would like to move this node?'),
      cancel: i18n.t('Cancel')
    };
  },
  Pagination: function Pagination() {
    return {
      previous: i18n.t('Previous'),
      next: i18n.t('Next'),
      showingStartToEndOfTotalRecords: i18n.t('Showing _START_ to _END_ of _TOTAL_ records'),
      filteredFromMaxRecords: i18n.t('(filtered from _MAX_ records)')
    };
  }
};