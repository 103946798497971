function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { UserLevels, UserLevelsByValue } from '@/constants/UserLevels';
export default {
  name: 'UserActionModal',
  components: {
    Modal: Modal,
    SingleSelect: SingleSelect
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    defaultData: {
      required: true,
      type: Object
    },
    title: {
      type: String,
      required: true
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      actionData: {},
      errorMessages: [],
      userRights: [UserLevels.NORMAL, UserLevels.PRIVILEGED, UserLevels.SUPER, UserLevels.ADMIN],
      updatedElements: [],
      translate: {
        update: this.$trans('Update'),
        cancel: this.$trans('Cancel')
      }
    };
  },
  mounted: function mounted() {
    this.actionData = cloneDeep(this.data);
  },
  methods: {
    handleAccept: function handleAccept() {
      if (isEqual(this.actionData, this.data)) {
        this.$emit('dismiss');
        return;
      }
      this.$emit('accept', this.updatedElements);
    },
    setUpdatedElement: function setUpdatedElement(index, value) {
      var action = this.actionData[index];
      var foundElementIndex = this.updatedElements.findIndex(function (item) {
        return item.identifier === action.identifier;
      });
      action.accessLevel = value;
      action.overridden = value !== -1;
      if (foundElementIndex === -1) {
        this.updatedElements.push(action);
      } else {
        this.updatedElements[index] = action;
      }
    },
    getOptions: function getOptions(item) {
      var _this = this;
      var defaultItem = this.defaultData.find(function (defaultItem) {
        return defaultItem.identifier === item.identifier;
      });
      var defaultLabel = this.$trans(UserLevelsByValue[defaultItem.accessLevel]);
      return [{
        label: "System default (".concat(defaultLabel, ")"),
        value: -1
      }].concat(_toConsumableArray(this.userRights.map(function (item) {
        return {
          label: _this.$trans(UserLevelsByValue[item]),
          value: item
        };
      })));
    },
    getValue: function getValue(item) {
      if (item.overridden) {
        return item.accessLevel;
      }
      return -1;
    }
  }
};