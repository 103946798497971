function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { checkERMByProjectId } from '@/utils/risks/check';
var frameworks = {
  key: 'frameworks',
  text: 'Framework',
  default: true
};
export var ActionVisibleProps = function ActionVisibleProps(getSettingValue) {
  return [{
    key: '_custom-title',
    // Title with path & trafficLight (always visible)
    text: 'Title',
    default: true
  }, {
    key: 'status',
    text: 'Status',
    default: true
  }, {
    key: 'description',
    text: 'Description',
    default: true
  }, {
    key: 'archivedAt',
    text: 'Archived on',
    default: true
  }, {
    key: 'archivedBy',
    text: 'Archived by',
    default: true
  }, {
    key: 'target',
    text: 'Target'
  }, {
    key: 'comment',
    text: 'Comment'
  }, {
    key: 'start',
    text: 'Start date',
    default: true
  }, {
    key: 'deadline',
    text: 'Deadline',
    default: true
  }, {
    key: 'responsible',
    text: 'Responsible',
    default: true
  }, {
    key: 'organisations',
    text: 'Organisations'
  }, {
    key: 'tags',
    text: 'Tags'
  }, {
    key: 'dailyTeam',
    text: 'Daily team'
  }, {
    key: 'dependingOn',
    text: getSettingValue('risma.depending_on_name') || 'Depending on',
    default: true
  }, {
    key: 'activityType',
    text: 'Activity type',
    default: true
  }, {
    key: 'completionPercentage',
    text: 'Completion percentage'
  }
  // Not implemented yet
  // {
  //     key: 'budget',
  //     text: 'Budget'
  // },
  // {
  //     key: 'forbrug',
  //     text: 'Forbrug'
  // },
  // {
  //     key: 'forventet',
  //     text: 'Forventet antal timer'
  // },
  // {
  //     key: 'forbrugAntalTimer',
  //     text: 'Forbrug antal timer'
  // },
  ];
};
export var IncidentVisibleProps = [{
  key: 'activityType',
  text: 'Activity type',
  default: true
}, {
  key: '_custom-title',
  text: 'Title',
  default: true
}, {
  key: 'severity',
  text: 'Severity',
  default: true
}, {
  key: 'createdAt',
  text: 'Created',
  default: true
}, {
  key: 'incidentHappenedAt',
  text: 'Incident occurred',
  default: true
}, {
  key: 'incidentArchived',
  text: 'Incident archived',
  default: true
}, {
  key: 'rismaState',
  text: 'Status',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'accountableUserIds',
  text: 'Accountable',
  default: true
}, {
  key: 'responsibleUserIds',
  text: 'Responsible',
  default: true
}, {
  key: 'informedUserIds',
  text: 'Informed',
  default: true
}];
function getAdditionalColumns(getSettingValue) {
  var additionalColumns = [];
  if (getSettingValue('risma.show_key_control')) additionalColumns.push({
    key: ControlTypeFields.KEY_CONTROL,
    text: 'Key control'
  });
  if (getSettingValue('risma.show_automatic_manual')) additionalColumns.push({
    key: ControlTypeFields.KEY_AUTOMATIC_MANUAL,
    text: 'Manual vs automated'
  });
  if (getSettingValue('risma.show_preventive_detective')) additionalColumns.push({
    key: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE,
    text: 'Preventive vs detective'
  });
  if (getSettingValue('risma.show_high_complexity_low_complexity')) additionalColumns.push({
    key: ControlTypeFields.KEY_HIGH_LOW_COMPLEXITY,
    text: 'Complexity'
  });
  return additionalColumns;
}
function getBaseColumns(getSettingValue) {
  return [{
    key: '_custom-title',
    text: 'Title',
    default: true
  }, {
    key: 'description',
    text: 'Description',
    default: true
  }, {
    key: 'accounts',
    text: getSettingValue('risma.account_to_name') || 'Accounts',
    default: true
  }, {
    key: 'risks',
    text: getSettingValue('risma.risk_to_name') || 'Risk',
    default: true
  }, {
    key: 'processes',
    text: getSettingValue('risma.process_to_name') || 'Process',
    default: true
  }];
}
export function getMotherControlVisibleProps(getSettingValue) {
  var baseColumns = getBaseColumns(getSettingValue);
  var additionalColumns = getAdditionalColumns(getSettingValue);
  return [].concat(_toConsumableArray(baseColumns), _toConsumableArray(additionalColumns), [{
    key: 'frequency',
    text: 'Frequency'
  }, {
    key: 'tags',
    text: 'Tags',
    default: true
  }, frameworks]);
}
export function getControlVisibleProps(getSettingValue) {
  var baseColumns = getBaseColumns(getSettingValue);
  var additionalColumns = getAdditionalColumns(getSettingValue);
  return [].concat(_toConsumableArray(baseColumns), _toConsumableArray(additionalColumns), [{
    key: 'frequency',
    text: 'Frequency'
  }, {
    key: 'responsible',
    text: 'Responsible',
    default: true
  }, {
    key: 'reviewers',
    text: 'Reviewers'
  }, {
    key: 'escalation',
    text: 'Escalation'
  }, {
    key: 'organisations',
    text: 'Organisations'
  }, {
    key: 'controlDeadline',
    text: 'Deadline',
    default: true
  }, {
    key: 'reviewDeadline',
    text: 'Review deadline'
  }, {
    key: 'rismaState',
    text: 'Control status'
  }, {
    key: 'tags',
    text: 'Tags',
    default: true
  }, frameworks, {
    key: '_custom-button',
    text: 'Action',
    default: true
  }]);
}
export var RiskVisibleProps = [{
  key: '_custom-title',
  text: 'Title',
  default: true
}, {
  key: 'description',
  text: 'Description',
  default: true
}, {
  key: 'organisations',
  text: 'Organisations',
  default: true
}, {
  key: 'tags',
  text: 'Tags',
  default: true
}, {
  key: 'approvalStatus',
  text: 'Approved',
  default: true
}];
var getRiskERMAAdditionalProps = function getRiskERMAAdditionalProps(getSettingValue) {
  return [{
    key: 'dailyOwner',
    text: 'Daily risk owner',
    default: true
  }, {
    key: 'primaryOwner',
    text: 'Primary risk owner',
    default: true
  }, {
    key: 'likelihood',
    text: 'Likelihood',
    default: true
  }, {
    key: 'riskType',
    text: getSettingValue('risma.risktype_name'),
    default: true
  }];
};
var getRiskAdditionalProps = function getRiskAdditionalProps() {
  return [{
    key: 'responsibleUserIds',
    text: 'Responsible',
    default: true
  }, {
    key: 'threat',
    text: 'Threat',
    default: true
  }, {
    key: 'levelOfThreat',
    text: 'Level of threat',
    default: true
  }, {
    key: 'vulnerability',
    text: 'Vulnerability',
    default: true
  }];
};
export var getRiskVisibleProps = function getRiskVisibleProps(getSettingValue, projectId, currentUser) {
  var additionalProps = checkERMByProjectId(projectId, currentUser) ? getRiskERMAAdditionalProps(getSettingValue) : getRiskAdditionalProps();
  return [].concat(RiskVisibleProps, _toConsumableArray(additionalProps));
};