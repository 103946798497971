function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import i18n from '@/i18n';
import { UserLevels } from '@/constants/UserLevels';
import { useSettingsStore } from '@/Store/settingsStore';
var accessLevelMapping = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, UserLevels.NONE, 'NONE'), UserLevels.NORMAL, 'NORMAL'), UserLevels.PRIVILEGED, 'PRIVILEGED'), UserLevels.SUPER, 'SUPER'), UserLevels.ADMIN, 'ADMIN');
var createAccessLevels = function createAccessLevels(levels) {
  return levels.map(function (level) {
    return {
      value: level,
      label: accessLevelMapping[level]
    };
  });
};
var accessLevelsBase = createAccessLevels([UserLevels.NONE, UserLevels.NORMAL, UserLevels.PRIVILEGED, UserLevels.SUPER]);
var accessLevels = createAccessLevels([UserLevels.NONE, UserLevels.NORMAL, UserLevels.PRIVILEGED, UserLevels.SUPER, UserLevels.ADMIN]);
var accessLevelsCompanies = createAccessLevels([UserLevels.ADMIN, UserLevels.NONE, UserLevels.NORMAL, UserLevels.SUPER]);
var adminLevels = function adminLevels() {
  return [{
    value: 0,
    label: i18n.t('No')
  }, {
    value: 1,
    label: i18n.t('Yes')
  }];
};
var filteredColumns = function filteredColumns(excluded) {
  var settingsStore = useSettingsStore();
  var columns = [{
    columnName: 'Object Id',
    translatable: true
  }, {
    columnName: 'Name',
    translatable: true
  }, {
    columnName: 'Admin',
    translatable: true
  }, {
    columnName: 'Controls',
    translatable: false
  }, {
    columnName: 'Actions',
    translatable: false
  }, {
    columnName: 'Incidents',
    translatable: false
  }, {
    columnName: 'Risks',
    translatable: false
  }, {
    columnName: 'Contracts',
    translatable: false
  }, {
    columnName: 'Companies',
    translatable: true
  }, {
    columnName: settingsStore.informationAssetsName,
    translatable: false
  }, {
    columnName: settingsStore.processLibraryName,
    translatable: false
  }, {
    columnName: 'Locked fields',
    translatable: true
  }, {
    columnName: 'Projects',
    translatable: true
  }, {
    columnName: 'Edit',
    translatable: true
  }, {
    columnName: 'Delete',
    translatable: true
  }];
  var filteredColumns = columns.filter(function (column) {
    return !excluded.includes(column.columnName);
  });
  return filteredColumns.map(function (column) {
    return column.translatable ? i18n.t(column.columnName) : column.columnName;
  });
};
var columns = function columns() {
  return filteredColumns([]);
};
export { columns, adminLevels, accessLevelsBase, accessLevels, accessLevelsCompanies, accessLevelMapping };