function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { i18n } from '@/i18n';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
var TYPES = {
  INHERENT: 'inherent',
  RESIDUAL: 'residual'
};
export default {
  getPreviousScores: function getPreviousScores(risk, tableMatchLabels, consequence, probability) {
    var scores = {};
    if (risk.previousRisk && consequence && probability) {
      var _previousProbability$, _previousConsequence$;
      var projectPeriodId = risk.projectPeriodId,
        previousRisk = risk.previousRisk;
      var consequenceId = consequence.rowId;
      var probabilityId = probability.rowId;
      var previousProjectPeriodId = previousRisk.projectPeriodId;
      var labelByConcequence = tableMatchLabels[projectPeriodId] && tableMatchLabels[projectPeriodId][consequenceId];
      var labelByProbability = tableMatchLabels[projectPeriodId] && tableMatchLabels[projectPeriodId][probabilityId];
      var previousConsequence = labelByConcequence && previousRisk[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (consequence) {
        if (!tableMatchLabels[previousProjectPeriodId] && !tableMatchLabels[previousProjectPeriodId][consequence.rowId]) {
          return false;
        }
        return labelByConcequence === tableMatchLabels[previousProjectPeriodId][consequence.rowId];
      });
      var previousProbability = labelByProbability && previousRisk[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (probability) {
        if (!tableMatchLabels[previousProjectPeriodId] && !tableMatchLabels[previousProjectPeriodId][probability.rowId]) {
          return false;
        }
        return labelByProbability === tableMatchLabels[previousProjectPeriodId][probability.rowId];
      });
      scores = {
        x: previousProbability ? (_previousProbability$ = previousProbability.column) === null || _previousProbability$ === void 0 ? void 0 : _previousProbability$.value : 0,
        y: previousConsequence ? (_previousConsequence$ = previousConsequence.column) === null || _previousConsequence$ === void 0 ? void 0 : _previousConsequence$.value : 0
      };
    } else {
      scores = {
        x: 0,
        y: 0
      };
    }
    return scores;
  },
  extendRiskData: function extendRiskData(item, consequences, tableMatchLabels) {
    var _this = this;
    consequences.forEach(function (conseq) {
      var _foundResidualProbabi, _foundResidualConsequ, _foundInherentProbabi, _foundInherentConsequ;
      var foundResidualConsequence = item[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (item) {
        return item.rowId === conseq.id;
      });
      var foundResidualProbability = item[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (item) {
        return item.rowId === conseq.id;
      });
      var foundInherentConsequence = item[RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES].find(function (item) {
        return item.rowId === conseq.id;
      });
      var foundInherentProbability = item[RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES].find(function (item) {
        return item.rowId === conseq.id;
      });
      item._scores[conseq.id] = {};
      item._scores[conseq.id].scores = {
        x: (foundResidualProbability === null || foundResidualProbability === void 0 || (_foundResidualProbabi = foundResidualProbability.column) === null || _foundResidualProbabi === void 0 ? void 0 : _foundResidualProbabi.value) || 0,
        y: (foundResidualConsequence === null || foundResidualConsequence === void 0 || (_foundResidualConsequ = foundResidualConsequence.column) === null || _foundResidualConsequ === void 0 ? void 0 : _foundResidualConsequ.value) || 0,
        xInherent: (foundInherentProbability === null || foundInherentProbability === void 0 || (_foundInherentProbabi = foundInherentProbability.column) === null || _foundInherentProbabi === void 0 ? void 0 : _foundInherentProbabi.value) || 0,
        yInherent: (foundInherentConsequence === null || foundInherentConsequence === void 0 || (_foundInherentConsequ = foundInherentConsequence.column) === null || _foundInherentConsequ === void 0 ? void 0 : _foundInherentConsequ.value) || 0
      };
      item._scores[conseq.id].scoresPrevious = _this.getPreviousScores(item, tableMatchLabels, foundResidualConsequence, foundResidualProbability);
    });
  },
  invertRiskData: function invertRiskData(item, consequences) {
    consequences.forEach(function (conseq) {
      var scores = item._scores[conseq.id].scores;
      var scoresPrevious = item._scores[conseq.id].scoresPrevious;
      item._scores[conseq.id].scores = {
        x: scores.y,
        y: scores.x,
        xInherent: scores.yInherent,
        yInherent: scores.xInherent
      };
      item._scores[conseq.id].scoresPrevious = {
        x: scoresPrevious.y,
        y: scoresPrevious.x
      };
    });
  },
  createGridData: function createGridData(filters, currentPeriod) {
    return filters.riskAssessments.map(function (consequence) {
      var probability = currentPeriod.probabilityRows.find(function (item) {
        return item.id === consequence.id;
      });
      var gridSizeX = probability ? probability.likelihoodColumns.map(function (probability) {
        return {
          label: probability.label
        };
      }) : [];
      return {
        label: consequence.label,
        xLabel: "".concat(getProbabilityLabel(currentPeriod), ": ").concat(consequence.label),
        gridSizeX: gridSizeX,
        yLabel: "".concat(getConsequenceLabel(currentPeriod), ": ").concat(consequence.label),
        gridSizeY: consequence.impactColumns.map(function (item) {
          return {
            label: item.label
          };
        }),
        id: consequence.id
      };
    });
  },
  filterRiskBySelectedMatrix: function filterRiskBySelectedMatrix(risks, selectedMatrix, filters) {
    var matrixInverted = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    return risks.filter(function (item, index) {
      var riskAssessmentIds = [];
      selectedMatrix.forEach(function (selected) {
        filters.riskAssessments.forEach(function (assessment) {
          var probability = item[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (probability) {
            return probability.rowId === assessment.id;
          });
          var consequense = item[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (consequense) {
            return consequense.rowId === assessment.id;
          });
          var probabilitySelected = matrixInverted ? selected.y : selected.x;
          var consequenseSelected = matrixInverted ? selected.x : selected.y;
          if (consequense && probability && probability.column.value === probabilitySelected && consequense.column.value === consequenseSelected) {
            riskAssessmentIds.push(assessment.id);
          }
        });
      });
      if (riskAssessmentIds.length) {
        filters.riskAssessments.filter(function (consItem) {
          return !riskAssessmentIds.includes(consItem.id);
        }).forEach(function (consItem) {
          delete risks[index]._scores[consItem.id];
        });
      }
      return riskAssessmentIds.length;
    });
  }
};
export var getDotTitleOnHover = function getDotTitleOnHover(title, type) {
  var titleByType = {
    residual: 'Residual risk',
    inherent: 'Inherent risk'
  };
  return "".concat(i18n.t(titleByType[type]), " ").concat(title.toLowerCase());
};
var getRiskDotValue = function getRiskDotValue(risk, statusColor, riskAssessment, type, inherentRisksEnabled, matrixInverted) {
  var _ref, _ref2;
  var riskFields = _defineProperty(_defineProperty({}, TYPES.RESIDUAL, [RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES]), TYPES.INHERENT, [RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES]);
  var consequence = risk[riskFields[type][0]].find(function (item) {
    return item.rowId === riskAssessment.id;
  });
  var probability = risk[riskFields[type][1]].find(function (item) {
    return item.rowId === riskAssessment.id;
  });
  return {
    id: riskAssessment.id,
    color: statusColor[risk.gridStatus],
    title: inherentRisksEnabled ? getDotTitleOnHover(riskAssessment.label, type) : riskAssessment.label,
    label: riskAssessment.label[0],
    status: risk.status,
    x: ((_ref = matrixInverted ? consequence : probability) === null || _ref === void 0 || (_ref = _ref.column) === null || _ref === void 0 ? void 0 : _ref.value) || 0,
    y: ((_ref2 = matrixInverted ? probability : consequence) === null || _ref2 === void 0 || (_ref2 = _ref2.column) === null || _ref2 === void 0 ? void 0 : _ref2.value) || 0,
    xBefore: 0,
    yBefore: 0,
    type: type
  };
};
export var prepareRiskDots = function prepareRiskDots(risk, currentPeriod, statusColor, inherentRisksEnabled) {
  var matrixInverted = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return currentPeriod.consequenceRows.reduce(function (acc, consequence) {
    if (inherentRisksEnabled) {
      acc.push(getRiskDotValue(risk, statusColor, consequence, TYPES.INHERENT, inherentRisksEnabled, matrixInverted));
    }
    acc.push(getRiskDotValue(risk, statusColor, consequence, TYPES.RESIDUAL, inherentRisksEnabled, matrixInverted));
    return acc;
  }, []);
};