import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "relative min-h-66px"
};
var _hoisted_2 = ["title"];
var _hoisted_3 = {
  key: 1,
  class: "text-rm-urban"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_text_box = _resolveComponent("text-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return !$props.disabled ? (_openBlock(), _createBlock(_component_text_box, {
    key: 0,
    text: $props.field.selectedValue,
    title: $props.field.selectedValue,
    placeholder: $props.placeholder,
    disabled: $props.disabled,
    error: $props.field.required && !$props.field.selectedValue ? $data.translate.pleaseFillOutThisField : '',
    label: !$props.hideLabel ? $props.field.label : '',
    "min-height": 44,
    "max-height": 136,
    resize: $props.resize,
    "local-storage-key": $props.localStorageKey,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updated', $event);
    })
  }, null, 8 /* PROPS */, ["text", "title", "placeholder", "disabled", "error", "label", "resize", "local-storage-key"])) : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", {
    class: _normalizeClass(["mb-1", _ctx.smallTitle ? 'text-title-small text-gray-750' : 'glob-h3'])
  }, _toDisplayString($props.field.label), 3 /* TEXT, CLASS */), _createElementVNode("div", {
    ref: "disabledTextBox",
    class: _normalizeClass(["grid grid-cols-[auto_auto] gap-x-2 w-full p-2 pr-4 border rounded-md border-rm-grey bg-rm-wisp cursor-default", {
      'h-11 overflow-hidden': !$data.isTextBoxExpanded,
      'absolute max-h-[144px] overflow-auto drop-shadow z-10': $data.isTextBoxExpanded
    }])
  }, [$props.field.selectedValue ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    title: $props.field.selectedValue,
    class: "text-rm-text"
  }, _toDisplayString($props.field.selectedValue), 9 /* TEXT, PROPS */, _hoisted_2)) : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($data.translate.noDataAvailable), 1 /* TEXT */)), $data.showChevron ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 2,
    icon: "chevron-down",
    class: _normalizeClass(["sticky top-1 ml-auto mt-1 shrink-0 stroke-2 text-rm-grey hover:text-rm-text transform duration-500 cursor-pointer", {
      'rotate-180': $data.isTextBoxExpanded,
      'text-rm-text': $data.isTextBoxExpanded
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.isTextBoxExpanded = !$data.isTextBoxExpanded;
    })
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])), [[_directive_click_outside, $options.closeExpand]]);
}