import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  class: "w-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_delegation_modal = _resolveComponent("delegation-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.show = true;
    })
  }, "Show popup"), $data.show ? (_openBlock(), _createBlock(_component_delegation_modal, {
    key: 0,
    "options-list": $data.users,
    "preselected-items": $data.selectedUsers,
    onCloseDelegationModal: _cache[1] || (_cache[1] = function ($event) {
      return $data.show = false;
    })
  }, null, 8 /* PROPS */, ["options-list", "preselected-items"])) : _createCommentVNode("v-if", true), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Selected Ids:", -1 /* HOISTED */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedUsers, function (id) {
    return _openBlock(), _createElementBlock("span", {
      key: id
    }, _toDisplayString(id) + ",", 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))]);
}