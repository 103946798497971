import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "gaps-select"
};
var _hoisted_2 = ["title", "textContent"];
var _hoisted_3 = ["title", "textContent"];
var _hoisted_4 = ["onKeydown", "onMousedown"];
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  class: "border border-gray-450 rounded px-6 py-3"
};
var _hoisted_7 = {
  class: "flex justify-end mt-2 mb-4"
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = ["onClick"];
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
  class: "font-semibold mr-1"
};
var _hoisted_12 = {
  class: "hidden"
};
var _hoisted_13 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_vue_multiselect = _resolveComponent("vue-multiselect");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_report_search_form = _resolveComponent("report-search-form");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_gap_results = _resolveComponent("gap-results");
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_pagesize = _resolveComponent("risma-pagesize");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_report_search_form, {
    title: $data.translate.searchInGaps,
    "helper-text": $data.translate.hereYouCanSearchForGapsByTheGap,
    "nodes-filter-enabled": true,
    "scoring-toggle-enabled": false,
    "project-id": $props.projectId,
    "initial-filters": $data.initialFilters,
    onDoSearch: $options.onReportSearch,
    onClearQuery: $options.handleClearQuery
  }, {
    inputSlot: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.gaps,
        class: "mb-1 cursor-pointer",
        onMousedown: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return _ctx.$refs.gapsSelect.toggle();
        }, ["prevent", "stop"]))
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_vue_multiselect, {
        ref: "gapsSelect",
        modelValue: $data.selectedGAPS,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.selectedGAPS = $event;
        }),
        "deselect-label": $data.translate.pressEnterToRemove,
        "selected-label": $data.translate.selected,
        "select-label": $data.translate.pressEnterToSelect,
        "custom-label": $options.gapLabels,
        placeholder: $data.translate.gaps,
        options: _ctx.lights,
        multiple: true,
        "close-on-select": false,
        "track-by": "number",
        class: "border border-gray-450 rounded",
        onClose: _cache[2] || (_cache[2] = function ($event) {
          return $data.isExpanded = false;
        }),
        onOpen: _cache[3] || (_cache[3] = function ($event) {
          return $data.isExpanded = true;
        })
      }, {
        option: _withCtx(function (_ref) {
          var option = _ref.option;
          return [_createElementVNode("div", {
            class: "p-2",
            style: _normalizeStyle({
              backgroundColor: option.color,
              padding: '12px',
              color: option.text_color
            })
          }, [_renderSlot(_ctx.$slots, "tagCustom", {
            option: option
          }, function () {
            return [_createElementVNode("span", {
              title: option.legend,
              textContent: _toDisplayString(option.legend)
            }, null, 8 /* PROPS */, _hoisted_2)];
          }, true)], 4 /* STYLE */)];
        }),
        tag: _withCtx(function (_ref2) {
          var option = _ref2.option;
          return [_createElementVNode("span", {
            class: "multiselect__tag",
            style: _normalizeStyle({
              backgroundColor: option.color,
              color: option.text_color
            })
          }, [_renderSlot(_ctx.$slots, "tagCustom", {
            option: option
          }, function () {
            return [_createElementVNode("span", {
              title: option.label,
              textContent: _toDisplayString(option.legend)
            }, null, 8 /* PROPS */, _hoisted_3)];
          }, true), _createElementVNode("i", {
            "aria-hidden": "true",
            tabindex: "1",
            class: "multiselect__tag-icon",
            onKeydown: _withKeys(_withModifiers(function ($event) {
              return _ctx.$refs.gapsSelect.removeElement(option);
            }, ["prevent"]), ["enter"]),
            onMousedown: _withModifiers(function ($event) {
              return _ctx.$refs.gapsSelect.removeElement(option);
            }, ["prevent"])
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)], 4 /* STYLE */)];
        }),
        caret: _withCtx(function (_ref3) {
          var toggle = _ref3.toggle;
          return [_createVNode(_component_feather_icon, {
            icon: "chevron-down",
            class: _normalizeClass(["absolute right-3 top-10px stroke-3 transform duration-500", {
              'rotate-180': $data.isExpanded
            }]),
            onMousedown: _withModifiers(toggle, ["prevent", "stop"])
          }, null, 8 /* PROPS */, ["class", "onMousedown"])];
        }),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["modelValue", "deselect-label", "selected-label", "select-label", "custom-label", "placeholder", "options"])])];
    }),
    gapTypesSlot: _withCtx(function () {
      return [$options.showGapArticlesTypes ? (_openBlock(), _createElementBlock("div", _hoisted_5, [$options.enabledGapTypeMultiselect ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 0,
        title: $data.translate.gapTypes,
        "selected-options": $data.gapTypesSelected,
        placeholder: $data.translate.selectGapTypes,
        options: $options.gapTypesOptions,
        "label-key": "label",
        onSelected: _cache[4] || (_cache[4] = function ($event) {
          return $data.gapTypesSelected = $event;
        })
      }, null, 8 /* PROPS */, ["title", "selected-options", "placeholder", "options"])) : (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        modelValue: $data.gapTypeIdSelected,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $data.gapTypeIdSelected = $event;
        }),
        title: $data.translate.gapType,
        placeholder: $data.translate.selectGapType,
        options: $options.gapTypesOptions
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options"]))])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["title", "helper-text", "project-id", "initial-filters", "onDoSearch", "onClearQuery"]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.noResults ? (_openBlock(), _createBlock(_component_no_search_results, {
    key: 1,
    body: $data.searchError
  }, null, 8 /* PROPS */, ["body"])) : _createCommentVNode("v-if", true), _withDirectives(_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("a", {
    class: _normalizeClass([['view-item view-item-compact', {
      active: !$data.extendedView
    }], "cursor-pointer mr-3"]),
    onClick: _cache[6] || (_cache[6] = _withModifiers(function ($event) {
      return $data.extendedView = false;
    }, ["prevent"]))
  }, null, 2 /* CLASS */), _createElementVNode("a", {
    class: _normalizeClass([['view-item view-item-expanded', {
      active: $data.extendedView
    }], "cursor-pointer"]),
    onClick: _cache[7] || (_cache[7] = _withModifiers(function ($event) {
      return $data.extendedView = true;
    }, ["prevent"]))
  }, null, 2 /* CLASS */)]), !$data.extendedView ? (_openBlock(), _createBlock(_component_gap_results, {
    key: 0,
    dataset: $data.output,
    "show-risk-assessment": !!$data.project.enableGapRiskModule
  }, null, 8 /* PROPS */, ["dataset", "show-risk-assessment"])) : !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "compliance-projects-id": $props.projectId,
    "report-options": $setup.reportOptions,
    onLoad: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.loadReportByReplacingQueryParams($event);
    })
  }, null, 8 /* PROPS */, ["type", "state-local", "compliance-projects-id", "report-options"]), _createVNode(_component_risma_datatable, {
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "columns-meta": $data.columnsMeta,
    "always-visible-export-columns": $data.alwaysVisibleExportColumns,
    "columns-order": $options.columnsOrder,
    "page-length": _ctx.pageLength,
    "wrapper-height-sticky": 500,
    "is-gap": true,
    "do-resizing": true,
    "with-global-search": false,
    "state-local": "gap_report_table_formatting_".concat($props.projectId),
    paging: $data.showDefaultPagination,
    "show-pagination": $data.showDefaultPagination,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onDismissModal: $options.refreshTable,
    onBackendSort: $options.backendSortRequest
  }, _createSlots({
    excel: _withCtx(function (props) {
      return [_createElementVNode("div", {
        class: "flex text-blue-750 cursor-pointer mr-6 pl-2",
        onClick: function onClick($event) {
          return $options.onExcelExportClick(props.columnsToExport);
        }
      }, [_createVNode(_component_feather_icon, {
        class: "mr-1",
        icon: "download"
      }), _cache[11] || (_cache[11] = _createElementVNode("span", null, "Excel", -1 /* HOISTED */))], 8 /* PROPS */, _hoisted_9)];
    }),
    print: _withCtx(function (props) {
      return [_createElementVNode("div", {
        class: "flex text-blue-750 cursor-pointer mr-6",
        onClick: function onClick($event) {
          return $options.onPrintClick(props.columnsToExport);
        }
      }, [_createVNode(_component_feather_icon, {
        class: "mr-1",
        icon: "printer"
      }), _createElementVNode("span", null, _toDisplayString($data.translate.print), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_10)];
    }),
    attributes: _withCtx(function (_ref4) {
      var item = _ref4.item;
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.attributes, function (attributes, key) {
        return _openBlock(), _createElementBlock("div", {
          key: key,
          class: "flex flex-wrap items-center break-all"
        }, [_createElementVNode("p", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(key + ':'), 1 /* TEXT */)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attributes, function (attribute, idx) {
          return _openBlock(), _createElementBlock("span", {
            key: idx,
            class: "text-title-small text-gray-750"
          }, _toDisplayString(attribute + (attributes.length > idx + 1 ? ', ' : '')), 1 /* TEXT */);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    pageSize: _withCtx(function () {
      return [_createVNode(_component_risma_pagesize, {
        modelValue: _ctx.pageLength,
        "onUpdate:modelValue": [_cache[9] || (_cache[9] = function ($event) {
          return _ctx.pageLength = $event;
        }), $options.onPagesizeChange],
        "paging-options": $data.pagingOptions,
        class: "mr-auto"
      }, null, 8 /* PROPS */, ["modelValue", "paging-options", "onUpdate:modelValue"])];
    }),
    nodeTitle: _withCtx(function (_ref5) {
      var item = _ref5.item;
      return [_createVNode(_component_preview_modal_link, {
        activity: item.field.activity,
        onDismissModal: $options.refreshTable
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.field.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])];
    }),
    trafficLight: _withCtx(function (_ref6) {
      var item = _ref6.item;
      return [_createVNode(_component_traffic_light, {
        color: item.field.color
      }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_12, _toDisplayString(item.field.value) + "-" + _toDisplayString(_ctx.$trans(item.field.label)), 1 /* TEXT */)];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.slotVForFields, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref7) {
        var item = _ref7.item;
        return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (previewItem, key) {
          return _openBlock(), _createElementBlock("div", {
            key: "preview-item-slot-".concat(item.id, "-").concat(linkCol, "-").concat(key),
            class: "flex items-center"
          }, [previewItem.activity.trafficLight ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            class: "mr-1",
            color: previewItem.activity.trafficLight.color
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createVNode(_component_preview_modal_link, {
            class: _normalizeClass({
              'line-through': previewItem.lineThrough
            }),
            activity: previewItem.activity,
            onUpdateFromPreviewMode: _cache[10] || (_cache[10] = function ($event) {
              return $data.updated = true;
            }),
            onDismissModal: $options.dismissPreviewModal
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                type: "small",
                class: "hover:underline",
                truncate: false,
                title: previewItem.title,
                "title-attribute": $options.getItemHoverTitle(previewItem.activity),
                "display-icon": previewItem.activity.confidential
              }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"]), previewItem.showCheckmark ? (_openBlock(), _createBlock(_component_feather_icon, {
                key: 0,
                icon: "check",
                height: "20",
                width: "20",
                class: "ml-1 self-center"
              })) : _createCommentVNode("v-if", true)])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "activity", "onDismissModal"])]);
        }), 128 /* KEYED_FRAGMENT */))])];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "columns-meta", "always-visible-export-columns", "columns-order", "page-length", "state-local", "paging", "show-pagination", "disabled-filter-by-columns", "onPageChange", "onOnStateChanged", "onDismissModal", "onBackendSort"])])) : _createCommentVNode("v-if", true), !$data.showDefaultPagination ? (_openBlock(), _createBlock(_component_pagination, {
    key: 2,
    total: $data.totalOutput,
    "per-page": $data.pageSize,
    "show-prev-next-buttons": true,
    onChange: $options.pageChange
  }, null, 8 /* PROPS */, ["total", "per-page", "onChange"])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), [[_vShow, $data.output.length > 0 && !$data.loading]])]);
}