function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAll as getActions, getActionsTree, create as createActionChild, updateSingleInitiative } from '@/api/execution/initiative';
import Tree from '@/components/Atoms/Tree';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Pagination from '@/components/Atoms/Pagination';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import GanttChartActions from '@/components/Molecules/Charts/GanttChartActions';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import CreateInitiativeModal from '@/components/Organisms/CreateInitiativeModal';
import { MODULES } from '@/constants/modules';
import { OVERALL_INITIATIVES_VIEWS } from '@/constants/ViewTypes';
import { InitiativesViews } from '@/constants/InitiativesViews';
import { UserLevels } from '@/constants/UserLevels';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { isLockedActionUser as _isLockedActionUser } from '@/utils/access';
import { getAllChildrenIds } from '@/utils/Utils';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'ActionsList',
  description: 'This is Page component for /actions/actions',
  token: '<actions-list />',
  components: {
    CompactCard: CompactCard,
    Tree: Tree,
    PreviewListTile: PreviewListTile,
    RismaTitle: RismaTitle,
    CatalogueHeader: CatalogueHeader,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Pagination: Pagination,
    PreviewListExpanded: PreviewListExpanded,
    GanttChartActions: GanttChartActions,
    CreateInitiativeModal: CreateInitiativeModal
  },
  mixins: [CommonAssetsMixin],
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('actions_catalogue_view', OVERALL_INITIATIVES_VIEWS[0].name)
    };
  },
  data: function data() {
    return {
      MODULES: MODULES,
      OVERALL_INITIATIVES_VIEWS: OVERALL_INITIATIVES_VIEWS,
      InitiativesViews: InitiativesViews,
      overviewOptionsState: true,
      activities: [],
      treeId: 'initiatives_tree',
      treeTitle: '',
      isLoaded: false,
      treeData: {},
      newChild: {},
      updatedActionFromPreview: null,
      itemsPerPage: 25,
      isActivitiesLoaded: false,
      paginationPage: {
        start: 0,
        end: 0
      },
      nodeId: null,
      showActionCreationModal: false,
      translate: {
        yourActivityHasBeenCreated: this.$trans('Your activity has been created'),
        overview: this.$trans('Overview'),
        createOverallAction: this.$trans('Create overall action')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserIsAdmin: 'isAdmin'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), {}, {
    ganttStyles: function ganttStyles() {
      return {
        minHeight: this.activitiesSlice.length * 28 + 'px'
      };
    },
    isLockedActionUser: function isLockedActionUser() {
      return _isLockedActionUser(this.currentUser);
    },
    showCreateBtn: function showCreateBtn() {
      return this.currentUser.AccessLevels.Actions.canCreateTopLevelAction;
    },
    activitiesSlice: function activitiesSlice() {
      return this.activities.slice(this.paginationPage.start, this.paginationPage.end + 1);
    },
    userCanCreate: function userCanCreate() {
      return !this.isLockedActionUser && this.currentUser.AccessLevels.Actions.canCreate;
    },
    userHasLockAccess: function userHasLockAccess() {
      return !this.isLockedActionUser && this.currentUser.level_execution >= UserLevels.SUPER || this.currentUserIsAdmin;
    },
    compactDataset: function compactDataset() {
      var _this = this;
      return this.activities.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          dependingOn: DatatableRenderer.prepareLinks(filterAsset(_this.activities, item.dependingOn).map(function (item) {
            return {
              title: item.displayHeadline,
              link: item.url
            };
          }), _this)
        });
      });
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    var promise;
    if (this.$route.query.view) {
      this.currentView = this.$route.query.view;
    }
    if (this.currentView === this.InitiativesViews.TREE) {
      promise = this.handleGetActionsTree;
    } else {
      promise = this.handleGetActionsList;
    }
    promise().then(function () {
      return _this2.isLoaded = true;
    });
  },
  methods: {
    updateDisplayState: function updateDisplayState(state) {
      var _this3 = this;
      this.isLoaded = false;
      this.currentView = state;
      var promise;
      if (this.currentView === this.InitiativesViews.TREE) {
        promise = this.handleGetActionsTree();
      } else {
        promise = this.isActivitiesLoaded ? Promise.resolve() : this.handleGetActionsList();
      }
      promise.then(function () {
        return _this3.isLoaded = true;
      });
    },
    handleGetActionsList: function handleGetActionsList() {
      var _this4 = this;
      return getActions().then(function (_ref) {
        var list = _ref.list;
        _this4.activities = list;
        _this4.isActivitiesLoaded = true;
      });
    },
    handleGetActionsTree: function handleGetActionsTree() {
      var _this5 = this;
      return getActionsTree().then(function (response) {
        _this5.treeData = null;
        _this5.treeData = _this5.formatTree(response);
      });
    },
    formatTree: function formatTree(tree) {
      tree.children = this.formatTreeChildren(tree.children);
      return tree;
    },
    formatTreeChildren: function formatTreeChildren(children) {
      var _this6 = this;
      if (!children || !children.length) return [];
      children.map(function (child) {
        child = _this6.formatTreeChild(child);
        if (child.children && child.children.length) _this6.formatTreeChildren(child.children);
        return child;
      });
      return children;
    },
    formatTreeChild: function formatTreeChild(child) {
      child.traffic_light = child.trafficLight;
      child.objectType = child.objType;
      child.title = child.ipath + ' ' + child.title;
      child.ipath = child.ipath + '';
      return child;
    },
    addTreeChild: function addTreeChild(_ref2) {
      var _this7 = this;
      var title = _ref2.title,
        appendTo = _ref2.appendTo;
      createActionChild({
        title: title,
        parent: appendTo
      }).then(function (response) {
        _this7.appendChildToActivities(response, appendTo);
        _this7.newChild = _this7.formatTreeChild(response);
        _this7.appendChildToTreeData(_this7.newChild, appendTo);
        _this7.$notify({
          title: _this7.translate.yourActivityHasBeenCreated,
          activity: getFieldsFromObjectForPreview(response)
        });
      });
    },
    appendChildToActivities: function appendChildToActivities(child, parentId) {
      if (!this.isActivitiesLoaded) return;
      this.activities.push(child);
      var parent = this.activities.find(function (activity) {
        return activity.id == parentId;
      });
      parent.children ? parent.children.push(child) : parent.children = [child];
    },
    appendChildToTreeData: function appendChildToTreeData(child, parentId) {
      var parent = this.findItemById(this.treeData.children, parentId);
      if (!parent) return;
      parent.children.push(_objectSpread(_objectSpread({}, child), {}, {
        children: []
      }));
    },
    updateTreeChildren: function updateTreeChildren(child) {
      if (!this.treeData.children) return;

      // list is used so it could be added as link and can be reffered to it, wont work without it
      var found = [];
      this.findParentById(this.treeData, child.id, found);
      var _found$ = found[0],
        parent = _found$.parent,
        index = _found$.index;
      if (!parent) return;
      parent.children.splice(index, 1, _objectSpread(_objectSpread({}, this.formatTreeChild(child)), {}, {
        children: this.formatTreeChildren(child.children)
      }));
    },
    findParentById: function findParentById(parent, id, found) {
      var _this8 = this;
      if (found.length || !parent.children) return;
      parent.children.forEach(function (item, index) {
        if (item.id === id) {
          found.push({
            parent: parent,
            index: index
          });
          return;
        }
        _this8.findParentById(item, id, found);
      });
    },
    findItemById: function findItemById(children, id) {
      var childrenOfChildren = [];
      var childrenLength = children.length;
      for (var i = 0; i < childrenLength; i++) {
        if (children[i].id == id) return children[i];
        if (children[i].children) childrenOfChildren = [].concat(_toConsumableArray(childrenOfChildren), _toConsumableArray(children[i].children));
      }
      if (!childrenOfChildren.length) return;
      return this.findItemById(childrenOfChildren, id);
    },
    createInitiative: function createInitiative(data) {
      var _this9 = this;
      return createActionChild(data).catch(function (error) {
        _this9.$notify({
          title: error && error.error,
          type: 'error'
        });
      });
    },
    handleCreateAction: function handleCreateAction(newItem) {
      var _this10 = this;
      this.createInitiative(newItem).then(function (data) {
        var nodeId = _this10.nodeId;
        _this10.toggleActionCreationModal();
        _this10.activities.push(data);
        _this10.newChild = _this10.formatTreeChild(_objectSpread(_objectSpread({}, data), {}, {
          newOverallAction: !nodeId,
          underlyingId: nodeId
        }));
        if (nodeId) {
          if (_this10.treeData.children) _this10.addActionToTheTreeByNodeId(_this10.newChild, nodeId, _this10.treeData.children);
        } else {
          if (_this10.treeData.children) _this10.treeData.children.push(_this10.newChild);
        }
        _this10.$notify({
          title: _this10.translate.yourActivityHasBeenCreated,
          activity: getFieldsFromObjectForPreview(data)
        });
      });
    },
    handleShownList: function handleShownList(number) {
      this.paginationPage = number;
    },
    deleteAction: function deleteAction(action) {
      var actionAndChildrenIds = this.getActionAndChildrenIds(action.id);
      this.activities = this.activities.filter(function (activity) {
        return !actionAndChildrenIds.includes(+activity.id);
      });
      if (this.treeData.children) {
        this.treeData.children = this.removeActionFromTree(this.treeData.children, action.id);
      }
    },
    deleteActionFromTree: function deleteActionFromTree(_ref3) {
      var id = _ref3.id;
      this.treeData.children = this.removeActionFromTree(this.treeData.children, id);
      if (this.activities.length) {
        var actionAndChildrenIds = this.getActionAndChildrenIds(id);
        this.activities = this.activities.filter(function (activity) {
          return !actionAndChildrenIds.includes(+activity.id);
        });
      }
    },
    addActionToTheTreeByNodeId: function addActionToTheTreeByNodeId(action, nodeId) {
      var _this11 = this;
      var children = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      children.forEach(function (item) {
        if (item.id === nodeId) {
          if (item.children && item.children.length) {
            item.children.push(action);
          } else {
            item.children = [action];
          }
        }
        if (item.children && item.children.length) {
          _this11.addActionToTheTreeByNodeId(action, nodeId, item.children);
        }
      });
    },
    removeActionFromTree: function removeActionFromTree(children, id) {
      var _this12 = this;
      children.forEach(function (child) {
        if (child.children) child.children = _this12.removeActionFromTree(child.children, id);
      });
      return children.filter(function (child) {
        return child.id != id;
      });
    },
    getActionAndChildrenIds: function getActionAndChildrenIds(id) {
      var parent = this.activities.find(function (action) {
        return action.id == id;
      });
      return getAllChildrenIds(parent);
    },
    tryMove: function tryMove(params) {
      var _this13 = this;
      return updateSingleInitiative(params.child, params.options).then(function (response) {
        if (response.id) {
          _this13.$refs.tree && _this13.$refs.tree.reload();
        }
      });
    },
    reloadData: function reloadData() {
      var _this14 = this;
      Promise.resolve(this.handleGetActionsTree()).then(function () {
        return _this14.$refs.tree && _this14.$refs.tree.init();
      });
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedAction) {
      this.updatedActionFromPreview = updatedAction;
      this.updateTreeChildren(updatedAction);
      this.handleUpdateActionActivities(updatedAction);
    },
    onPreviewDismiss: function onPreviewDismiss() {
      if (!this.updatedActionFromPreview) return;
      if (this.updatedActionFromPreview.archived) {
        var idsToRemove = getAllChildrenIds(this.updatedActionFromPreview);
        this.activities = this.activities.filter(function (action) {
          return !idsToRemove.includes(action.id);
        });
      }
      this.updatedActionFromPreview = null;
    },
    handleUpdateActionActivities: function handleUpdateActionActivities(updatedAction) {
      var _this15 = this;
      var fullUpdate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var updatedActionIndex = this.activities.findIndex(function (a) {
        return a.id == updatedAction.id;
      });
      if (updatedActionIndex === -1) return;
      if (updatedAction.closed !== this.activities[updatedActionIndex].closed && updatedAction.children.length) {
        // children has objectType limited so it only needs closed to be changed
        updatedAction.children.forEach(function (action) {
          _this15.handleUpdateActionActivities(action, false);
        });
      }
      if (fullUpdate) {
        this.activities[updatedActionIndex] = updatedAction;
      } else {
        this.activities[updatedActionIndex].closed = updatedAction.closed;
      }
    },
    toggleActionCreationModal: function toggleActionCreationModal(nodeId) {
      this.nodeId = typeof nodeId === 'number' || typeof nodeId === 'string' ? +nodeId : null;
      this.showActionCreationModal = !this.showActionCreationModal;
    }
  }
};