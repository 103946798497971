function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanies as _getCompanies, getContacts as _getContacts } from '@/api/admin/companies';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import ContractGenericTable from '@/components/Pages/Contracts/ContractGenericTable';
import MainWell from '@/components/Atoms/Containers/MainWell';
import { TabTypes } from '@/constants/Tabs';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ContractHistory',
  description: 'This is the Page component for /contracts/contract/:id/history',
  token: '<contract-history :data="data"/>',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    ContractGenericTable: ContractGenericTable
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    }
  },
  data: function data() {
    return {
      hiddenColumns: ['controls', 'titlePreview', 'companies', 'delete', 'starred', 'tags', 'commitment', 'contractType'],
      TabTypes: TabTypes,
      showRelating: true,
      showOther: true,
      contracts: [],
      workflowStatuses: [],
      updated: false,
      companies: [],
      contacts: [],
      translate: {
        currentContract: this.$trans('Current contract'),
        contractHistory: this.$trans('Contract history'),
        theContractHasNotBeenRenewedAndHoldsTheref: this.$trans('The contract has not been renewed and holds therefore no history.')
      }
    };
  },
  computed: {
    historyData: function historyData() {
      return _toConsumableArray(this.data.history).reverse();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getData().catch(function (err) {
      return _this.$notify({
        title: err.response.error,
        type: 'error'
      });
    });
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.getWorkflowStatuses(), this.getContacts(), this.getCompanies()]);
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this2 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref) {
        var list = _ref.list;
        return _this2.workflowStatuses = list;
      });
    },
    getContacts: function getContacts() {
      var _this3 = this;
      return _getContacts().then(function (response) {
        _this3.contacts = response.list;
      });
    },
    getCompanies: function getCompanies() {
      var _this4 = this;
      return _getCompanies().then(function (_ref2) {
        var list = _ref2.list;
        _this4.companies = list;
      });
    }
  }
};