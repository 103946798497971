import { getTranslate } from './translate';
import ControlsReview from '@/components/Pages/Controls/ControlsReview';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'ControlReviewModal',
  components: {
    ControlsReview: ControlsReview,
    Modal: Modal
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      note: 'An id of a control'
    },
    cpath: {
      type: [Number, String],
      required: true,
      note: 'A cpath of a control'
    }
  },
  emits: ['close', 'update'],
  data: function data() {
    return {
      translate: getTranslate['ControlReviewModal'](),
      isUpdated: false,
      interactionDisabled: false
    };
  },
  methods: {
    onDismiss: function onDismiss() {
      var _this = this;
      if (this.interactionDisabled) return;
      if (this.isUpdated) {
        this.$confirm(this.translate.closeCompletionReviewWindow, this.translate.areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted, function (res) {
          return res && _this.$emit('close');
        });
        return;
      }
      this.$emit('close');
    },
    setInteractionDisabled: function setInteractionDisabled(state) {
      this.interactionDisabled = state;
    }
  }
};