function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import { getSelectableQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { getAllByType } from '@/api/compliance/informationAssets';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { MODULES } from '@/constants/modules';
export default {
  name: 'QuestionnaireSelection',
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect
  },
  mixins: [IATitleMixin],
  props: {
    initialFilters: {
      type: Object,
      default: null
    },
    isIA: {
      type: Boolean,
      default: true
    }
  },
  emits: ['assetsSelected', 'selectionChanged'],
  data: function data() {
    return {
      selectedQuestionnaireId: null,
      selectedAssetType: null,
      assets: [],
      questionnaires: [],
      questionnairesOptions: [],
      questions: [],
      assetsLoaded: true,
      selectedAssets: [],
      selectedOrganisations: [],
      selectedResponsible: [],
      translate: getTranslate['QuestionnaireSelection']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, ['organisations'])), {}, {
    usersOptions: function usersOptions() {
      return this.users.map(function (user) {
        return {
          id: user.id,
          label: user.display_name
        };
      });
    },
    organisationsOptions: function organisationsOptions() {
      return this.organisations.map(function (item) {
        return {
          id: item.id,
          label: item.name
        };
      });
    },
    assetsFilteredByQuestionnaire: function assetsFilteredByQuestionnaire() {
      var _this = this;
      if (!this.selectedQuestionnaireId) return [];
      return this.assets.filter(function (a) {
        return a.questionnaires.some(function (item) {
          return item.questionnaireId === _this.selectedQuestionnaireId;
        });
      });
    },
    availableResponsible: function availableResponsible() {
      var _this2 = this;
      var result = {};
      this.assetsFilteredByQuestionnaire.forEach(function (asset) {
        var _asset$responsibleUse;
        if (!(asset !== null && asset !== void 0 && (_asset$responsibleUse = asset.responsibleUserIds) !== null && _asset$responsibleUse !== void 0 && _asset$responsibleUse.length)) return;
        asset.responsibleUserIds.forEach(function (userId) {
          if (result[userId]) return;
          result[userId] = _this2.usersOptions.find(function (user) {
            return user.id === userId;
          });
        });
      });
      return Object.values(result);
    },
    availableOrganisations: function availableOrganisations() {
      var _this3 = this;
      var result = {};
      this.assetsFilteredByQuestionnaire.forEach(function (asset) {
        var _asset$organisationId;
        if (!(asset !== null && asset !== void 0 && (_asset$organisationId = asset.organisationIds) !== null && _asset$organisationId !== void 0 && _asset$organisationId.length)) return;
        asset.organisationIds.forEach(function (organisationId) {
          if (result[organisationId]) return;
          result[organisationId] = _this3.organisationsOptions.find(function (org) {
            return org.id === organisationId;
          });
        });
      });
      return Object.values(result);
    },
    availableAssetOptions: function availableAssetOptions() {
      var _this$selectedOrganis, _this$selectedRespons;
      var result = this.assetsFilteredByQuestionnaire;
      if ((_this$selectedOrganis = this.selectedOrganisations) !== null && _this$selectedOrganis !== void 0 && _this$selectedOrganis.length) {
        var selectedIds = this.selectedOrganisations.map(function (org) {
          return org.id;
        });
        result = result.filter(function (asset) {
          return asset.organisationIds.some(function (id) {
            return selectedIds.includes(id);
          });
        });
      }
      if ((_this$selectedRespons = this.selectedResponsible) !== null && _this$selectedRespons !== void 0 && _this$selectedRespons.length) {
        var _selectedIds = this.selectedResponsible.map(function (user) {
          return user.id;
        });
        result = result.filter(function (asset) {
          return asset.responsibleUserIds.some(function (id) {
            return _selectedIds.includes(id);
          });
        });
      }
      return result;
    },
    assetTypes: function assetTypes() {
      var assetTypes = [];
      if (this.getSettingValue('feature.enable_systems')) {
        assetTypes.push({
          value: AssetsTypes.SYSTEMS,
          label: this.translate.systems
        });
      }
      if (this.getSettingValue('feature.enable_data_processors')) {
        assetTypes.push({
          value: AssetsTypes.PROCESSORS,
          label: this.translate.dataProcessors
        });
      }
      if (this.getSettingValue('feature.enable_data_controllers')) {
        assetTypes.push({
          value: AssetsTypes.CONTROLLERS,
          label: this.translate.dataControllers
        });
      }
      if (this.currentUser.iaAccess) {
        assetTypes.push({
          value: AssetsTypes.INFORMATION_ASSETS_API,
          label: this.translate.customAssets
        });
      }
      return assetTypes;
    }
  }),
  watch: {
    questionnaires: {
      deep: true,
      handler: function handler() {
        if (this.questionnaires.length === 1) {
          this.selectedQuestionnaireId = this.questionnaires[0].id;
        }
      }
    },
    availableAssetOptions: function availableAssetOptions(newAssets) {
      var newAssetsIds = newAssets.map(function (asset) {
        return asset.id;
      });
      this.selectedAssets = this.selectedAssets.filter(function (asset) {
        return newAssetsIds.includes(asset.id);
      });
    },
    selectedQuestionnaireId: function selectedQuestionnaireId() {
      this.resetSelectors();
    },
    selectedOrganisations: function selectedOrganisations() {
      this.emitSelectedAssets();
    },
    selectedResponsible: function selectedResponsible() {
      this.emitSelectedAssets();
    },
    selectedAssets: function selectedAssets() {
      this.emitSelectedAssets();
    }
  },
  created: function created() {
    var _this4 = this;
    if (this.initialFilters && this.initialFilters.selectedAssetType) {
      this.selectedAssetType = this.initialFilters.selectedAssetType;
      this.selectedQuestionnaireId = this.initialFilters.selectedQuestionnaireId;
      this.fetchQuestionnaires(this.selectedAssetType).then(function (_ref) {
        var list = _ref.list;
        _this4.assets = _this4.processAssets(list, _this4.selectedAssetType);
        _this4.assetsLoaded = true;
        _this4.selectedAssets = _this4.availableAssetOptions.filter(function (asset) {
          return _this4.initialFilters.selectedAssets.includes(asset.id);
        });
        _this4.selectionChanged(false);
      });
    } else if (!this.isIA) {
      this.assetTypeSelected(MODULES.CONTRACTS);
    }
  },
  methods: {
    fetchQuestionnaires: function fetchQuestionnaires(selectedAssetType) {
      var mapping = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.SYSTEMS, QuestionnaireTypes.SYSTEM), AssetsTypes.PROCESSORS, QuestionnaireTypes.DATAPROCESSOR), AssetsTypes.CONTROLLERS, QuestionnaireTypes.DATACONTROLLER), AssetsTypes.INFORMATION_ASSETS_API, QuestionnaireTypes.IA_CUSTOM), MODULES.CONTRACTS, QuestionnaireTypes.CONTRACT);
      var params = '?data[]=questionnaire&data[]=users&data[]=organisations&filters[underlying]=include';
      this.getSelectableQuestionnaires(mapping[selectedAssetType]);
      if (this.isIA) {
        return getAllByType(selectedAssetType, params);
      }
      return Promise.resolve({
        list: []
      });
    },
    assetTypeSelected: function assetTypeSelected(selectedAssetType) {
      var _this5 = this;
      if (this.selectedAssetType == selectedAssetType) {
        return;
      }
      this.selectedAssetType = selectedAssetType;
      this.selectedQuestionnaireId = null;
      this.assetsLoaded = false;
      if (this.selectedAssetType) {
        return this.fetchQuestionnaires(this.selectedAssetType).then(function (_ref2) {
          var list = _ref2.list;
          _this5.assets = _this5.processAssets(list, _this5.selectedAssetType);
          _this5.assetsLoaded = true;
          _this5.selectionChanged();
        });
      }
    },
    questionnaireSelected: function questionnaireSelected(selectedQuestionnaireId) {
      if (this.selectedQuestionnaireId == selectedQuestionnaireId) {
        return;
      }
      this.selectedQuestionnaireId = selectedQuestionnaireId;
      if (this.selectedQuestionnaireId) {
        this.selectionChanged();
      }
    },
    processAssets: function processAssets(assets, type) {
      if (type !== AssetsTypes.SYSTEMS) {
        return assets;
      }
      return assets.map(function (asset) {
        var parents = asset.parentId ? [asset.parentId] : [];
        return _objectSpread(_objectSpread({}, asset), {}, {
          parents: parents
        });
      });
    },
    getSelectableQuestionnaires: function getSelectableQuestionnaires(questionnaireTypeId) {
      var _this6 = this;
      return getSelectableQuestionnairesByQuestionnaireTypeId(questionnaireTypeId).then(function (response) {
        _this6.questionnairesOptions = response.map(function (q) {
          return {
            value: q.id,
            label: q.title
          };
        });
      });
    },
    resetSelectors: function resetSelectors() {
      this.selectedAssets = [];
      this.selectedOrganisations = [];
      this.selectedResponsible = [];
      this.selectedQuestions = [];
    },
    emitSelectedAssets: function emitSelectedAssets() {
      var _this$selectedAssets, _this$selectedOrganis2, _this$selectedRespons2;
      if ((_this$selectedAssets = this.selectedAssets) !== null && _this$selectedAssets !== void 0 && _this$selectedAssets.length) {
        this.$emit('assetsSelected', this.selectedAssets);
        return;
      }
      if ((_this$selectedOrganis2 = this.selectedOrganisations) !== null && _this$selectedOrganis2 !== void 0 && _this$selectedOrganis2.length || (_this$selectedRespons2 = this.selectedResponsible) !== null && _this$selectedRespons2 !== void 0 && _this$selectedRespons2.length) {
        var _this$availableAssetO;
        var emitData = (_this$availableAssetO = this.availableAssetOptions) !== null && _this$availableAssetO !== void 0 && _this$availableAssetO.length ? this.availableAssetOptions : null;
        this.$emit('assetsSelected', emitData);
        return;
      }
      this.$emit('assetsSelected', []); // empty [] == all items
    },
    selectionChanged: function selectionChanged(refreshReport) {
      this.$emit('selectionChanged', this.selectedQuestionnaireId, this.assets, this.selectedAssetType, refreshReport);
    }
  }
};