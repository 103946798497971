import i18n from '@/i18n';
export var getTranslate = {
  ActionChangeDatesModal: function ActionChangeDatesModal() {
    return {
      moveActionAndUnderlyingDates: i18n.t('Move action and underlying dates'),
      clickThe: i18n.t('Click the'),
      toChangeDatesIndependently: i18n.t('to change dates independently.'),
      toChangeDatesSynchronously: i18n.t('to change dates synchronously.'),
      startDateCannotBeAfterDeadline: i18n.t('Start date cannot be after deadline'),
      original: i18n.t('Original'),
      start: i18n.t('Start'),
      deadline: i18n.t('Deadline'),
      new: i18n.t('New'),
      moveActions: i18n.t('Move actions'),
      byPressingMoveInitiativesYouWillMoveStartAndDeadline: i18n.t("By pressing 'Move actions' you will move the start and deadline of this and"),
      underlyingInitiatives: i18n.t('underlying initiatives'),
      byPressingMoveInitiativesYouWillMoveStart: i18n.t("By pressing 'Move actions' you will move the start of this and"),
      youWillAlsoMoveTheDeadline: i18n.t('You will also move the deadline'),
      daysForward: i18n.t('days forward'),
      daysBackward: i18n.t('days backward')
    };
  },
  ActionUpdateSequenceNumberModal: function ActionUpdateSequenceNumberModal() {
    return {
      pleaseOnlyTypeNewSequenceNumber: '(' + i18n.t('please only type new sequence number at the lowest level') + ')',
      newNumberForAction: i18n.t('New number for action'),
      newNumberMustConsistOnlyOfDigits: i18n.t('%s must consist only of digits.', [i18n.t('New number')]),
      newNumber: i18n.t('New number'),
      apply: i18n.t('Apply'),
      title: i18n.t('Title'),
      responsible: i18n.t('Responsible'),
      deadline: i18n.t('Deadline'),
      trafficLight: i18n.t('Traffic light'),
      cancel: i18n.t('Cancel'),
      somethingWentWrong: i18n.t('Something went wrong!'),
      pleaseCorrectTheErrorsAndTryAgain: i18n.t('Please correct the errors and try again.'),
      theFollowingActionsWillBeAffected: i18n.t('The following actions will be affected by your operation.'),
      insertNewNumber: i18n.t('Insert new number'),
      theNumberMustBeGreaterThan0: i18n.t('The number must be greater than 0'),
      theNumberAlreadyExistPleaseChooseAUniqueNumber: i18n.t('The number already exist. Please choose a unique number')
    };
  }
};