import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "my-4"
};
var _hoisted_2 = {
  class: "flex flex-wrap -mx-4 gap-y-6"
};
var _hoisted_3 = ["title", "textContent"];
var _hoisted_4 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selectors, function (selector, key) {
    var _$options$listOptions;
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: _normalizeClass("w-1/".concat($options.colNumber, " px-3"))
    }, [(_$options$listOptions = $options.listOptions[selector.list]) !== null && _$options$listOptions !== void 0 && _$options$listOptions.list && selector.list !== 'frameworks' ? (_openBlock(), _createBlock(_component_risma_select, {
      key: 0,
      ref_for: true,
      ref: selector.list,
      title: selector.label,
      class: _normalizeClass([selector.list, "mb-2"]),
      options: $options.listOptions[selector.list].list,
      "label-key": $options.listOptions[selector.list].display,
      "selected-options": $options.formatPreSelected(selector),
      placeholder: $data.translate.noFiltersApplied,
      "max-visible-tags": 7,
      "un-sorted": selector.list === 'gaps',
      "selected-on-top": selector.list !== 'gaps',
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.change($event, selector);
      },
      onInitialized: function onInitialized($event) {
        return $options.initialized(selector.preSelected, selector);
      }
    }, _createSlots({
      _: 2 /* DYNAMIC */
    }, [selector.list === 'gaps' ? {
      name: "optionCustom",
      fn: _withCtx(function (_ref) {
        var option = _ref.option;
        return [_createElementVNode("div", {
          class: "p-2",
          style: _normalizeStyle({
            backgroundColor: option.color,
            padding: '12px',
            color: option.text_color
          })
        }, [_renderSlot(_ctx.$slots, "tagCustom", {
          option: option
        }, function () {
          return [_createElementVNode("span", {
            title: option.label,
            textContent: _toDisplayString(option.label)
          }, null, 8 /* PROPS */, _hoisted_3)];
        }, true)], 4 /* STYLE */)];
      }),
      key: "0"
    } : undefined, selector.toggle ? {
      name: "newOption",
      fn: _withCtx(function () {
        return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_toggle, {
          "model-value": selector.toggle.option,
          class: "mr-1 switcher",
          onInput: selector.toggle.event
        }, null, 8 /* PROPS */, ["model-value", "onInput"]), _createElementVNode("span", null, _toDisplayString(selector.toggle.title), 1 /* TEXT */)])];
      }),
      key: "1"
    } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "class", "options", "label-key", "selected-options", "placeholder", "un-sorted", "selected-on-top", "onUpdate:modelValue", "onInitialized"])) : selector.list === 'frameworks' ? (_openBlock(), _createBlock(_component_frameworks_selector, {
      key: 1,
      title: selector.label,
      class: _normalizeClass([selector.list, "mb-2"]),
      "toggle-object": selector.toggle,
      "selected-options": $data.selectedFrameworks,
      onSelected: $options.selectFrameworks
    }, null, 8 /* PROPS */, ["title", "class", "toggle-object", "selected-options", "onSelected"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", {
    class: _normalizeClass(["w-1/".concat($options.colNumber, " px-3"), 'self-end mb-4'])
  }, [_createElementVNode("div", null, [_createElementVNode("p", {
    class: "inline-flex items-center cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.resetAll && $options.resetAll.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "stroke-2 mr-1",
    height: "18",
    width: "18"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.resetFilter), 1 /* TEXT */)])])], 2 /* CLASS */)]), _renderSlot(_ctx.$slots, "footer", {}, undefined, true)]);
}