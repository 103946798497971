import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "risma-select"
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = ["title"];
var _hoisted_4 = {
  key: 0,
  class: "glob-l1 text-rm-signal-red mt-1"
};
var _hoisted_5 = {
  key: 1,
  class: "glob-l1 text-rm-signal-grey-dark mt-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_vue_multiselect_pop_up = _resolveComponent("risma-vue-multiselect-pop-up");
  var _component_risma_vue_multiselect = _resolveComponent("risma-vue-multiselect");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.title ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    title: $props.title,
    class: _normalizeClass(["mb-1 text-rm-text", {
      'cursor-pointer': !$props.disabled,
      'glob-h3': !$props.titleType,
      'text-title-small': $props.titleType === 'small'
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.toggle && $options.toggle.apply($options, arguments);
    }, ["prevent"]))
  }, _toDisplayString($props.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "newOption", {}, function () {
    return [$props.addNewOptionLabel && !$props.disabled && $props.newOptionPosition === 'top' ? (_openBlock(), _createBlock(_component_risma_vue_multiselect_pop_up, {
      key: 0,
      "add-new-option-label": $props.addNewOptionLabel,
      class: "mt-1",
      position: $props.newOptionPosition,
      onTag: _cache[1] || (_cache[1] = function ($event) {
        return _ctx.$emit('tag', $event);
      })
    }, null, 8 /* PROPS */, ["add-new-option-label", "position"])) : _createCommentVNode("v-if", true)];
  })]), _createElementVNode("div", {
    class: _normalizeClass([{
      'cursor-not-allowed': $props.disabled
    }, "relative"])
  }, [_createVNode(_component_risma_vue_multiselect, {
    "model-value": $data.selected,
    options: $data.values,
    "track-by": $props.trackBy,
    "is-open": $data.isOpen,
    "label-key": $props.labelKey,
    "open-direction": $props.openDirection,
    disabled: $props.disabled,
    "enable-select-all": $props.enableSelectAll,
    "group-select": $props.groupSelect,
    "group-values": $props.groupValues,
    "group-label": $props.groupLabel,
    "confirm-select-all": $props.confirmSelectAll,
    placeholder: $props.placeholder,
    "max-visible-tags": $props.maxVisibleTags,
    "truncate-length": $props.truncateLength,
    "has-tag-wrap-new-line": $props.hasTagWrapNewLine,
    "has-error-message": !!$props.errorMessage,
    "close-on-select": $props.closeOnSelect,
    "locked-options": $props.lockedOptions,
    "search-fields": $props.searchFields,
    "all-data-loaded": $props.allDataShown,
    "is-lazy-loading-shown": $props.isLazyLoadingShown,
    "onUpdate:modelValue": $options.handleSelected,
    onClose: $options.handleClose,
    onOpen: $options.handleOpen,
    onLoad: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('load');
    }),
    "onUpdate:searchValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('update:searchValue', $event);
    })
  }, {
    tag: _withCtx(function (_ref) {
      var option = _ref.option;
      return [_renderSlot(_ctx.$slots, "tagCustom", {
        option: option
      })];
    }),
    option: _withCtx(function (_ref2) {
      var option = _ref2.option;
      return [_renderSlot(_ctx.$slots, "optionCustom", {
        option: option
      })];
    }),
    label: _withCtx(function (_ref3) {
      var option = _ref3.option;
      return [_renderSlot(_ctx.$slots, "labelCustom", {
        option: option
      })];
    }),
    tagLabel: _withCtx(function (_ref4) {
      var option = _ref4.option;
      return [_renderSlot(_ctx.$slots, "tagLabelCustom", {
        option: option
      })];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["model-value", "options", "track-by", "is-open", "label-key", "open-direction", "disabled", "enable-select-all", "group-select", "group-values", "group-label", "confirm-select-all", "placeholder", "max-visible-tags", "truncate-length", "has-tag-wrap-new-line", "has-error-message", "close-on-select", "locked-options", "search-fields", "all-data-loaded", "is-lazy-loading-shown", "onUpdate:modelValue", "onClose", "onOpen"]), $props.errorMessage && !$props.disabled ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.errorMessage), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $props.helpText ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($props.helpText), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $props.addNewOptionLabel && !$props.disabled && $props.newOptionPosition === 'bottom' ? (_openBlock(), _createBlock(_component_risma_vue_multiselect_pop_up, {
    key: 2,
    "add-new-option-label": $props.addNewOptionLabel,
    position: $props.newOptionPosition,
    class: "mt-1",
    onTag: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('tag', $event);
    })
  }, null, 8 /* PROPS */, ["add-new-option-label", "position"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)])), [[_directive_click_outside, $options.handleClose]]);
}