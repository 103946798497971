function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useComplianceOverview } from '@/Store/complianceOverview';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTable from '@/components/Molecules/StaticTable';
import TabList from '@/components/Atoms/TabList';
export default {
  name: 'MaterialityAssessment',
  description: 'General component that use in Compliance Overview page',
  components: {
    TabList: TabList,
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    PreviewModalLink: PreviewModalLink,
    StaticTable: StaticTable
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      translate: {
        headline: this.$trans('Materiality assessment')
      },
      columns: [this.$trans('Title'), this.$trans('Material'), this.$trans('Comment')],
      currentTabName: null,
      nodeWasChanged: false,
      columnsMaxWidth: {
        node: '500px',
        material: '100px',
        comment: '300px'
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useComplianceOverview, ['materialityOverview'])), {}, {
    dataset: function dataset() {
      var _this = this;
      var selectedNodes = this.materialityOverview.find(function (item) {
        return item.id === _this.currentTabName;
      });
      return selectedNodes ? this.prepareParentAndChildren(selectedNodes) : [];
    },
    tabs: function tabs() {
      return this.getTabs(this.materialityOverview);
    }
  }),
  created: function created() {
    this.init();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceOverview, ['fetchOverviewDashboard'])), {}, {
    init: function init() {
      var _this2 = this;
      return this.fetchOverviewDashboard(this.projectId).finally(function () {
        return _this2.isLoaded = true;
      });
    },
    prepareParentAndChildren: function prepareParentAndChildren(node) {
      var _this3 = this;
      var parentRow = this.prepareTableRow(node, true);
      var childrenRows = node.children.map(function (answer) {
        return _this3.prepareTableRow(answer, false);
      });
      var rows = [parentRow].concat(_toConsumableArray(childrenRows));
      return rows;
    },
    prepareTableRow: function prepareTableRow(node, isParentNode) {
      var data = {
        node: {
          title: node.title,
          isParentNode: isParentNode,
          activity: {
            objType: 'compliance-node',
            id: node.id,
            compliance_projects_id: +this.projectId,
            url: node.url
          }
        },
        material: '',
        comment: {
          comment: '',
          attachments: []
        }
      };
      if (!isParentNode) {
        var _node$attachments;
        data.material = node.material, data.comment = {
          comment: node.comment,
          attachments: ((_node$attachments = node.attachments) === null || _node$attachments === void 0 ? void 0 : _node$attachments.map(function (attachment) {
            return {
              name: attachment.filename,
              icon: attachment.url.startsWith('/api') ? 'file' : 'link'
            };
          })) || []
        };
      }
      return data;
    },
    onPreviewModalDismiss: function onPreviewModalDismiss() {
      if (this.nodeWasChanged) {
        this.init();
        this.nodeWasChanged = false;
      }
    },
    updateFromPreviewModalLink: function updateFromPreviewModalLink() {
      this.nodeWasChanged = true;
    },
    getTabs: function getTabs(materialityOverview) {
      var _this4 = this;
      return materialityOverview.map(function (item) {
        return {
          name: _this4.getShortTitle(item.title),
          slotName: item.id
        };
      });
    },
    setCurrentTab: function setCurrentTab(tabName) {
      this.currentTabName = tabName;
    },
    getShortTitle: function getShortTitle(title) {
      return title.split(' ').slice(0, 2).join(' ');
    }
  })
};