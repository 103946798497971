function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { CONFIRMATION_TYPES } from '@/components/Organisms/AiAssistant/utils';
import { getTranslate } from './translate';
import AiPartnerTerms from '@/components/Organisms/Admin/AiPartnerTerms';
export default {
  name: 'ChatConfirmation',
  components: {
    ButtonsRow: ButtonsRow,
    AiPartnerTerms: AiPartnerTerms
  },
  props: {
    confirmationType: {
      type: String,
      required: true
    },
    paragraphStyle: {
      type: String,
      default: 'glob-b1 text-rm-text px-6 text-center'
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      translate: getTranslate['ChatConfirmationDialog']()
    };
  },
  computed: {
    isAiTerms: function isAiTerms() {
      return this.confirmationType === CONFIRMATION_TYPES.AI_TERMS;
    },
    confirmationText: function confirmationText() {
      var messages = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, CONFIRMATION_TYPES.CLEAR, [this.translate.pleaseConfirmThatYouWantToClear, this.translate.thisWillPermanentlyErase]), CONFIRMATION_TYPES.RESET, ["".concat(this.translate.pleaseConfirmThatYouWantToDelete, " ").concat(this.translate.thisWillPermanentlyErase)]), CONFIRMATION_TYPES.REGION, [this.translate.youAreAboutToChangeRegion]), CONFIRMATION_TYPES.AI_TERMS, this.translate.aiGeneratedContentMayBe('<a class="text-rm-active-blue cursor-pointer" data-link="true">', '</a>'));
      return messages[this.confirmationType] || [];
    },
    cancelBtnLabel: function cancelBtnLabel() {
      var labelMap = _defineProperty({}, CONFIRMATION_TYPES.REGION, this.translate.no);
      return labelMap[this.confirmationType] || this.translate.cancel;
    },
    confirmBtnLabel: function confirmBtnLabel() {
      var labelMap = _defineProperty({}, CONFIRMATION_TYPES.REGION, this.translate.yes);
      return labelMap[this.confirmationType] || this.translate.confirm;
    },
    buttonsRowData: function buttonsRowData() {
      var confirmButton = {
        text: this.confirmBtnLabel,
        type: 'save',
        slotName: 'check',
        clickEvent: this.confirmChatFn
      };
      return this.isAiTerms ? [confirmButton] : [{
        text: this.cancelBtnLabel,
        type: 'util',
        clickEvent: this.cancelConfirmFn
      }, confirmButton];
    }
  },
  methods: {
    confirmChatFn: function confirmChatFn() {
      this.$emit('accept');
    },
    cancelConfirmFn: function cancelConfirmFn() {
      this.$emit('dismiss');
    },
    handleTermsClick: function handleTermsClick(event) {
      var dataLink = event === null || event === void 0 ? void 0 : event.target.getAttribute('data-link');
      if (dataLink) {
        this.$refs.aiPartnerTerms.download();
      }
    }
  }
};