import BadgeItem from '@/components/Atoms/BadgeItem';
export default {
  components: {
    BadgeItem: BadgeItem
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  }
};