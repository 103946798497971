import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["href", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  return $props.to && !$props.disabled ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    custom: "",
    to: $props.to
  }, {
    default: _withCtx(function (_ref) {
      var navigate = _ref.navigate,
        href = _ref.href,
        route = _ref.route,
        isActive = _ref.isActive,
        isExactActive = _ref.isExactActive;
      return [_createElementVNode("a", {
        href: href,
        class: _normalizeClass(["block p-2 border-solid relative", ($props.isExact ? isExactActive : $options.isSameRoute(route) || isActive) ? "router-link-exact-active border-l border-r border-gray-450 ".concat($props.backgroundClass, " font-semibold") : '']),
        onClick: navigate
      }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 10 /* CLASS, PROPS */, _hoisted_1)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["relative overflow-hidden", [$props.isCurrent && !$props.disabled ? "tab-item-active font-semibold z-2 ".concat($props.backgroundClass) : '', {
      'bg-gray-250': $props.disabled
    }]])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["p-2 border-solid h-full", [{
      'border-l-2 border-r-2 border-gray-250': $props.isCurrent || $props.disabled
    }, {
      'cursor-pointer': !$props.disabled
    }]])
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2 /* CLASS */)], 2 /* CLASS */));
}