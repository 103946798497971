function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { computed, shallowRef } from 'vue';
import { logNavigation } from '@/utils/navigationLog';
import { TabTypes, Tabs } from '@/constants/Tabs';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import DepHeader from '@/components/Organisms/DepHeader/DepHeader';
import DepRelations from '@/components/Organisms/DepRelations';
import { LoginGuardMixin } from '@/mixins/LoginGuardMixin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'DepWell',
  components: {
    MainWell: MainWell,
    DepHeader: DepHeader,
    DepRelations: DepRelations,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon
  },
  mixins: [LoginGuardMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  provide: function provide() {
    var _this = this;
    return {
      depRelationEnabled: computed(function () {
        return _this.depRelationsEnabled;
      })
    };
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    depHeaderProps: {
      type: Object,
      required: true
    },
    depRelationsTabs: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
    localStorageKey: {
      type: String,
      required: false,
      default: null
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    isRelationEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['saveStatusClicked', 'getActivityData', 'fileUpdate', 'changeTab', 'propertyChanged', 'updatingAttachments'],
  setup: function setup(props) {
    return {
      depRelationsExpanded: getLocalStorageComputed(props.localStorageKey, false, true)
    };
  },
  data: function data() {
    return {
      currentRoute: null,
      depRelationsEnabled: this.depRelationsExpanded,
      animationTimer: null
    };
  },
  computed: {
    previewComponentProps: function previewComponentProps() {
      if (!this.isPreview) return {};
      var parentProps = this.$parent.$props;
      var props = {};
      if (this.currentRoute && this.currentRoute.additionalPath && this.currentRoute.path.startsWith(Tabs[TabTypes.QUESTIONNAIRE].url)) {
        props = {
          questionnaireId: this.currentRoute.additionalPath
        };
      }
      if (this.currentRoute && this.currentRoute.additionalPath && this.currentRoute.path.startsWith(Tabs[TabTypes.GAPANALYSIS].url)) {
        props = {
          articleId: this.currentRoute.additionalPath
        };
      }
      if (this.depHeaderProps && this.depHeaderProps.module) {
        props.module = this.depHeaderProps.module;
      }
      return _objectSpread(_objectSpread({}, parentProps), props);
    },
    childRoutes: function childRoutes() {
      var _this$$router$options;
      if (!this.$router) {
        return [];
      }
      var parentComponentName = this.$parent.$options.name;
      var childrenComponents = (_this$$router$options = this.$router.options.routes.find(function (_ref) {
        var component = _ref.component,
          name = _ref.name;
        return (component === null || component === void 0 ? void 0 : component.name) === parentComponentName || name === parentComponentName;
      })) === null || _this$$router$options === void 0 ? void 0 : _this$$router$options.children;
      return childrenComponents || [];
    },
    // so we wont send it in props for 7+ components
    selectedRoute: function selectedRoute() {
      return this.$parent && this.$parent.$attrs['selected-route'] || '';
    },
    depHeaderProperties: function depHeaderProperties() {
      var _this2 = this;
      if (!this.isPreview || !this.currentRoute) {
        return this.depHeaderProps;
      }
      var currentPath = this.currentRoute.path;
      var tabs = this.depHeaderProps.tabs.map(function (tab) {
        var url, selected;
        if (tab.slicedUrl) {
          //if path with couple elements splitted by /
          url = tab.slicedUrl;
        } else {
          url = tab.url.slice(tab.url.lastIndexOf('/') + 1);
        }
        var splittedCurrentPath = currentPath.split('/');
        if (_this2.currentRoute.additionalPath) {
          //if path with couple elements splitted by /
          var splittedUrl = url.split('/');
          if (_this2.currentRoute.skipAdditionalPathCheck) {
            selected = splittedUrl[0] === splittedCurrentPath[0];
          } else {
            selected = splittedUrl[0] === splittedCurrentPath[0] && splittedUrl[1] + '' === _this2.currentRoute.additionalPath + '';
          }
        } else {
          selected = url.split(/[/?]+/)[0] === splittedCurrentPath[0];
        }
        return _objectSpread(_objectSpread({}, tab), {}, {
          url: url,
          selected: selected
        });
      });
      return _objectSpread(_objectSpread({}, this.depHeaderProps), {
        tabs: tabs
      });
    },
    filteredTabs: function filteredTabs() {
      if (!this.depRelationsTabs.length) return undefined;
      return this.depRelationsTabs.filter(function (tab) {
        return tab.isEnabled;
      });
    },
    iconName: function iconName() {
      return this.depRelationsExpanded ? 'chevron-right' : 'chevron-left';
    }
  },
  watch: {
    'depHeaderProps.tabs': {
      deep: true,
      handler: function handler() {
        var valueChanged = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var oldValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        if (valueChanged.length < oldValue.length && this.isPreview) {
          var selectedTab = this.depHeaderProperties.tabs.find(function (item) {
            return item.selected;
          });
          if (!selectedTab) {
            this.currentRoute = shallowRef(this.childRoutes[0]);
            this.$emit('changeTab', this.currentRoute.url);
          }
        }
      }
    },
    selectedRoute: function selectedRoute() {
      this.currentRoute = shallowRef(this.getCurrentRoute());
    }
  },
  mounted: function mounted() {
    this.currentRoute = shallowRef(this.getCurrentRoute());
  },
  methods: {
    getCurrentRoute: function getCurrentRoute() {
      var _this$selectedRoute,
        _this3 = this;
      if (!((_this$selectedRoute = this.selectedRoute) !== null && _this$selectedRoute !== void 0 && _this$selectedRoute.tab)) {
        return this.getFirstRoute();
      }
      if (this.selectedRoute.tab === TabTypes.DESCRIPTION) {
        return this.childRoutes[0];
      }
      if (this.selectedRoute.tab === TabTypes.QUESTIONNAIRE && this.item.questionnaireId) {
        return this.getQuestionnaireRoute();
      }
      if (this.selectedRoute.tab === TabTypes.GAPANALYSIS && this.item.gapSchemaId) {
        var currentRoute = this.childRoutes.find(function (item) {
          return item.path.includes(Tabs[TabTypes.GAPANALYSIS].url);
        });
        return currentRoute ? _objectSpread(_objectSpread({}, currentRoute), {}, {
          additionalPath: this.selectedRoute.id,
          skipAdditionalPathCheck: true
        }) : this.childRoutes[0];
      }
      return this.childRoutes.find(function (item) {
        return item.path === _this3.selectedRoute;
      }) || this.childRoutes[0];
    },
    getFirstRoute: function getFirstRoute() {
      var _this$item;
      if ((_this$item = this.item) !== null && _this$item !== void 0 && (_this$item = _this$item.url) !== null && _this$item !== void 0 && _this$item.includes(Tabs[TabTypes.QUESTIONNAIRE].url)) {
        return this.getQuestionnaireRoute();
      }
      return this.childRoutes[0];
    },
    getQuestionnaireRoute: function getQuestionnaireRoute() {
      var currentRoute = this.childRoutes.find(function (item) {
        return item.path.includes(Tabs[TabTypes.QUESTIONNAIRE].url);
      });
      return currentRoute ? _objectSpread(_objectSpread({}, currentRoute), {}, {
        additionalPath: this.item.questionnaireId
      }) : this.childRoutes[0];
    },
    propertyChanged: function propertyChanged(changedData) {
      this.$emit('propertyChanged', changedData);
    },
    changeTab: function changeTab(path) {
      var _this4 = this;
      this.loginGuard().then(function () {
        var fullPath = _this4.getFullPass(path);
        logNavigation(_this4.getFullPass(_this4.currentRoute.path), fullPath, true);
        var splittedPath = _this4.getSplittedPath(path);
        var currentRoute = _this4.childRoutes.find(function (route) {
          return route.path === splittedPath.pathes[0] || route.path.startsWith(splittedPath.pathes[0]);
        });
        _this4.currentRoute = shallowRef(currentRoute);
        if (splittedPath.pathes[1]) {
          //if path with couple elements splitted by /
          currentRoute.additionalPath = splittedPath.pathes[1];
        } else {
          currentRoute.additionalPath = null;
        }
        if (_this4.isPreview) _this4.$emit('changeTab', fullPath);
      });
    },
    getFullPass: function getFullPass(path) {
      var _this5 = this;
      var splittedPath = this.getSplittedPath(path);
      return this.depHeaderProps.tabs.find(function (tab) {
        var splittedTab = _this5.getSplittedPath(tab.url);
        var lastTabIdx = splittedTab.pathes.length - 1;
        return splittedTab.pathes[lastTabIdx] === splittedPath.pathes[0] || splittedTab.pathes[lastTabIdx - 1] === splittedPath.pathes[0]; //if path with couple elements splitted by /
      }).url;
    },
    getSplittedPath: function getSplittedPath(path) {
      var splittedPath = {
        pathes: path.split('/'),
        query: path.split('?')[1] || null
      };
      var lastPathIdx = splittedPath.pathes.length - 1;
      splittedPath.pathes[lastPathIdx] = splittedPath.pathes[lastPathIdx].match(/(:?\w+[-])*(:?\w*)/)[0]; // remove search query
      return splittedPath;
    },
    triggerAttachmentMethod: function triggerAttachmentMethod(methodName, args) {
      this.$refs.depHeader.$refs.attachment[methodName](args);
    },
    toggleDepRelations: function toggleDepRelations() {
      var _this6 = this;
      if (this.animationTimer) {
        clearTimeout(this.animationTimer);
      }
      var timer = this.depRelationsExpanded ? 500 : 40;
      this.depRelationsExpanded = !this.depRelationsExpanded;
      this.animationTimer = setTimeout(function () {
        _this6.animationTimer = null;
        _this6.depRelationsEnabled = _this6.depRelationsExpanded;
      }, timer);
      return;
    }
  }
};