import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative mb-1"
};
var _hoisted_2 = {
  key: 0,
  class: "multianswer-dropdown"
};
var _hoisted_3 = {
  key: 1,
  class: "multianswer-radiobuttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_checkboxes = _resolveComponent("checkboxes");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.viewType === $options.viewTypes.DROPDOWN ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.values.length > 0 ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    options: $data.values,
    "selected-options": $data.selected,
    disabled: $props.readOnly,
    "un-sorted": true,
    "label-key": "option",
    onSelected: $options.setAnswers,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      _ctx.$emit('forceUpdate');
      $data.isOpen = false;
    }),
    onOpen: _cache[1] || (_cache[1] = function ($event) {
      return $data.isOpen = true;
    }),
    onKeydown: _cache[2] || (_cache[2] = _withModifiers(function () {}, ["stop"]))
  }, null, 8 /* PROPS */, ["options", "selected-options", "disabled", "onSelected"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.viewType === $options.viewTypes.CHECKBOX ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$options.cbOptions.length ? (_openBlock(), _createBlock(_component_checkboxes, {
    key: 0,
    modelValue: $data.cbValue,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.cbValue = $event;
    }),
    options: $options.cbOptions,
    "truncate-labels": false,
    disabled: $props.readOnly,
    onUpdated: $options.setAnswerRadioButton
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled", "onUpdated"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}