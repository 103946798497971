import i18n from '@/i18n';
export var getTranslate = {
  GanttChartActions: function GanttChartActions() {
    return {
      sortBy: i18n.t('Sort by'),
      number: i18n.t('Number'),
      deadline: i18n.t('Deadline'),
      title: i18n.t('Title')
    };
  }
};