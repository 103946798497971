function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { sortNumericByPropComplex } from '@/utils/sort';
export var CustomFieldValidationMixin = {
  methods: {
    validate: function validate(field) {
      var result = true;
      var errors = {};
      if (!field.label) {
        result = false;
        errors.label = true;
      }
      if ((field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.SELECT || (field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.SELECT_MULTI) {
        if (!this.validSelectOptions(field === null || field === void 0 ? void 0 : field.options)) {
          result = false;
          this.selectOptionsIsValid = false;
          errors.options = true;
        }
      } else if ((field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.ROW_GROUP) {
        if (!field.children || !field.children.length) {
          result = false;
          errors.emptyField = true;
        }
      }
      if ((field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.NUMERICAL) {
        if (!field.unit.trim()) {
          result = false;
          errors.emptyUnitField = true;
        }
      }
      if (!(field !== null && field !== void 0 && field.typeId)) {
        result = false;
        errors.typeId = true;
      }
      if (result) {
        field.errors = {};
      } else {
        field.errors = errors;
      }
      return result;
    },
    validSelectOptions: function validSelectOptions(options) {
      var result = true;
      var resultMap = {};
      if (!(options !== null && options !== void 0 && options.length)) {
        return false;
      }
      options.forEach(function (item, idx) {
        delete item.invalid;
        delete item.duplicate;
        var trimmed = item.value.trim();
        if (!trimmed) {
          item.invalid = true;
          result = false;
          return;
        }
        if (resultMap[trimmed]) {
          item.duplicate = true;
          options[resultMap[trimmed] - 1].duplicate = true;
          result = false;
          return;
        }
        resultMap[trimmed] = idx + 1;
      });
      return result;
    },
    prepeareDataForSaving: function prepeareDataForSaving(field) {
      var data = {
        label: field.label,
        explanatoryText: field.explanatoryText,
        module: field.module
      };
      if (field.id) {
        data.fieldId = field.id;
      } else {
        data.typeId = field.typeId;
      }
      if (field.typeId === CustomFieldTypes.SELECT || (field === null || field === void 0 ? void 0 : field.typeId) === CustomFieldTypes.SELECT_MULTI) {
        data.optionObjects = field.options.map(function (item) {
          return {
            value: item.value,
            id: item.id
          };
        });
      }
      if (field.typeId === CustomFieldTypes.ROW_GROUP) {
        data.children = this.prepareIds(field.children);
      }
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        data.unit = field.unit;
      }
      return data;
    },
    prepareIds: function prepareIds(children) {
      var data = _toConsumableArray(children),
        result = {};
      sortNumericByPropComplex(data, 'order');

      // New format -> children: {1: 91, 2: 92}
      data = data.map(function (item) {
        result[item.order] = item.id;
      });
      return result;
    }
  }
};