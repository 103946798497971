function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InboxModal from '@/components/Molecules/Modal/InboxModal';
import { getAllEmailAccounts, createEmailAccount as _createEmailAccount, updateEmailAccount as _updateEmailAccount, testEmailAccount, deleteEmailAccount as _deleteEmailAccount } from '@/api/admin/inbox';
export default {
  name: 'Inbox',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    InboxModal: InboxModal
  },
  data: function data() {
    return {
      data: [],
      isLoaded: true,
      showModal: false,
      modalData: {},
      testButtonLoadState: false,
      order: ['username', 'email', 'servername', 'serverPort', 'service', 'tls', 'ssl', 'edit', 'delete'],
      translate: getTranslate['Inbox']()
    };
  },
  computed: {
    columns: function columns() {
      return [this.translate.username, this.translate.email, this.translate.serverName, this.translate.serverAddress, this.translate.service, 'TLS', 'SSL', this.translate.edit, this.translate.delete];
    },
    dataset: function dataset() {
      var _this = this;
      return this.data.map(function (item) {
        return {
          username: item.username,
          email: item.emailAddress,
          servername: item.mailServerAddress,
          serverPort: item.mailServerPort,
          service: item.service,
          tls: item.tls ? _this.translate.yes : _this.translate.no,
          ssl: item.ssl ? _this.translate.yes : _this.translate.no,
          edit: {
            type: 'icon',
            action: 'edit',
            icon: 'edit',
            id: item.id,
            styles: 'cursor-pointer stroke-2 text-blue-350'
          },
          delete: {
            type: 'icon',
            action: 'delete',
            icon: 'x',
            id: item.id,
            styles: 'cursor-pointer stroke-2 text-red-50'
          }
        };
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoaded = false;
      return getAllEmailAccounts().then(function (_ref) {
        var list = _ref.list;
        return _this2.data = list;
      }).catch(function (error) {
        return _this2.$notify({
          title: error.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this2.isLoaded = true;
      });
    },
    handleEvent: function handleEvent(data) {
      var _this3 = this;
      switch (data.action) {
        case 'edit':
          this.showModal = true;
          this.modalData = this.data.find(function (item) {
            return item.id === data.id;
          });
          return;
        case 'delete':
          this.$confirm('', this.translate.areYouSure, function (res) {
            return res && _this3.deleteEmailAccount(data.id);
          });
          return;
      }
    },
    deleteEmailAccount: function deleteEmailAccount(id) {
      var _this4 = this;
      this.isLoaded = false;
      return _deleteEmailAccount(id).then(function () {
        _this4.$notify({
          title: _this4.translate.emailRemoved
        });
        _this4.data = _this4.data.filter(function (item) {
          return item.id !== id;
        });
      }).catch(function (error) {
        return _this4.$notify({
          title: error.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this4.isLoaded = true;
      });
    },
    createNewMainAccount: function createNewMainAccount() {
      this.showModal = true;
    },
    handleAccept: function handleAccept(_ref2) {
      var type = _ref2.type,
        data = _ref2.data;
      if (type === 'update') return this.updateEmailAccount(data);
      return this.createEmailAccount(data);
    },
    createEmailAccount: function createEmailAccount(data) {
      var _this5 = this;
      return _createEmailAccount(data).then(function (response) {
        _this5.data.push(response);
        _this5.$notify({
          title: _this5.translate.emailHasBeenCreated
        });
        _this5.resetModalData();
      }).catch(function (error) {
        return _this5.$notify({
          title: error.response.error,
          type: 'error'
        });
      });
    },
    updateEmailAccount: function updateEmailAccount(data) {
      var _this6 = this;
      return _updateEmailAccount(data.id, data).then(function (response) {
        var index = _this6.data.findIndex(function (item) {
          return item.id === response.id;
        });
        _this6.data[index] = _objectSpread(_objectSpread({}, _this6.data[index]), response);
        _this6.$notify({
          title: _this6.translate.emailHasBeenUpdated
        });
        _this6.resetModalData();
      }).catch(function (error) {
        return _this6.$notify({
          title: error.response.error,
          type: 'error'
        });
      });
    },
    resetModalData: function resetModalData() {
      this.showModal = false;
      this.modalData = {};
    },
    testConnection: function testConnection(data) {
      var _this7 = this;
      this.testButtonLoadState = true;
      return testEmailAccount(data).then(function () {
        return _this7.$notify({
          title: _this7.translate.testConnectionSuccessfull
        });
      }).catch(function (error) {
        return _this7.$notify({
          title: error.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this7.testButtonLoadState = false;
      });
    }
  }
};