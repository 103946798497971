import Http, { HttpLegacy } from '@/api';
import { handleRequestResponse, downloadBlobAsFile } from '@/api/httpUtils';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'DownloadLink',
  introduction: 'DownloadLink is component for loading file by link with progress indicator',
  token: '<download-link :file-src="test/test.docx" :file-name="test.doc" />',
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    FeatherIcon: FeatherIcon
  },
  props: {
    fileSrc: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: false,
      default: 'Report'
    },
    useLegacy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      translate: {
        somethingWentWrong: this.$trans('Something went wrong!')
      }
    };
  },
  methods: {
    downloadFile: function downloadFile() {
      var _this = this;
      var base = this.useLegacy ? HttpLegacy : Http;
      this.loading = true;
      return handleRequestResponse(base.get(this.fileSrc, {
        responseType: 'blob'
      })).then(function (blob) {
        return downloadBlobAsFile(blob, _this.fileName);
      }).catch(function () {
        return _this.$notify({
          title: _this.translate.somethingWentWrong,
          type: 'error'
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};