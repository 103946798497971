import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex flex-col justify-between h-full"
};
var _hoisted_2 = {
  key: 0,
  class: "text-red-50"
};
var _hoisted_3 = {
  class: "mb-2 mt-4 flex items-baseline"
};
var _hoisted_4 = {
  class: "flex items-center justify-center"
};
var _hoisted_5 = {
  class: "flex items-center dates-wrap-start"
};
var _hoisted_6 = {
  class: "dates-title"
};
var _hoisted_7 = {
  class: "flex items-center dates-wrap-deadline"
};
var _hoisted_8 = {
  class: "dates-title"
};
var _hoisted_9 = {
  class: "mb-8 flex items-baseline"
};
var _hoisted_10 = {
  class: "flex justify-center items-center"
};
var _hoisted_11 = {
  class: "flex items-center dates-wrap-start"
};
var _hoisted_12 = {
  class: "dates-title"
};
var _hoisted_13 = {
  class: "flex items-center dates-wrap-deadline"
};
var _hoisted_14 = {
  class: "dates-title"
};
var _hoisted_15 = {
  class: "w-350px mb-2"
};
var _hoisted_16 = {
  key: 0
};
var _hoisted_17 = {
  class: "flex justify-between"
};
var _hoisted_18 = {
  class: "flex items-center"
};
var _hoisted_19 = {
  class: "mr-2"
};
var _hoisted_20 = {
  key: 0
};
var _hoisted_21 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_modal, {
    header: $data.translate.moveActionAndUnderlyingDates,
    "show-buttons": false,
    "body-full-height": true,
    "modal-fixed-height": "440px",
    onDismiss: $options.handleClose
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [$options.isStartAfterDeadline ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($data.translate.startDateCannotBeAfterDeadline), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.new,
        type: "medium",
        class: "w-24"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString($data.translate.start) + ":", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
        class: "ml-2 hover:underline",
        "model-value": $data.start,
        "plugin-options": $props.dateTimeOptions,
        onValueUpdated: $options.changeStartDate
      }, null, 8 /* PROPS */, ["model-value", "plugin-options", "onValueUpdated"])]), _createVNode(_component_feather_icon, {
        icon: $data.linked ? 'link' : 'link-2',
        class: "stroke-2 cursor-pointer mx-2",
        onClick: $options.toggleLinked
      }, null, 8 /* PROPS */, ["icon", "onClick"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString($data.translate.deadline) + ":", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
        class: "ml-2 hover:underline",
        "model-value": $data.deadline,
        "plugin-options": $props.dateTimeOptions,
        onValueUpdated: $options.changeDeadlineDate
      }, null, 8 /* PROPS */, ["model-value", "plugin-options", "onValueUpdated"])])])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
        title: $data.translate.original,
        type: "medium",
        class: "w-24"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, _toDisplayString($data.translate.start) + ":", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
        class: "ml-2",
        "model-value": $props.originalStart,
        "plugin-options": $props.dateTimeOptions,
        disabled: true
      }, null, 8 /* PROPS */, ["model-value", "plugin-options"])]), _createVNode(_component_feather_icon, {
        icon: "x",
        class: "stroke-2 mx-2"
      }), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString($data.translate.deadline) + ":", 1 /* TEXT */), _createVNode(_component_date_time_picker, {
        class: "ml-2",
        "model-value": $props.originalDeadline,
        "plugin-options": $props.dateTimeOptions,
        disabled: true
      }, null, 8 /* PROPS */, ["model-value", "plugin-options"])])])]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_15, _toDisplayString($options.changeDatesMessage), 1 /* TEXT */), !$data.linked ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString($options.deadlineChangeMessage), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("span", _hoisted_19, _toDisplayString($data.translate.clickThe), 1 /* TEXT */), _createVNode(_component_feather_icon, {
        icon: $data.linked ? 'link' : 'link-2',
        height: "16",
        width: "16",
        class: "mr-2 stroke-2"
      }, null, 8 /* PROPS */, ["icon"]), $data.linked ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString($data.translate.toChangeDatesIndependently), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString($data.translate.toChangeDatesSynchronously), 1 /* TEXT */))]), _createVNode(_component_risma_button, {
        text: $data.translate.moveActions,
        disabled: $options.isStartAfterDeadline,
        type: "save",
        class: "pull-right",
        size: "normal",
        onClick: $options.moveInitiatives
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"])]);
}