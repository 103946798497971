export function gapsToInitiatives(gapList) {
  var initiativeList = [];
  gapList.forEach(function (gap) {
    var _gap$links;
    if ((gap === null || gap === void 0 || (_gap$links = gap.links) === null || _gap$links === void 0 ? void 0 : _gap$links.execution) !== undefined) {
      gap.links.execution.forEach(function (initiative) {
        if (!isInList(initiative, initiativeList)) {
          initiativeList.push(initiative);
          initiativeList[initiativeList.length - 1].gap = findGaps(initiative, gapList);
        }
      });
    }
  });
  return initiativeList.filter(function (item) {
    return item !== null;
  });
}
export function gapsToControls(gapList) {
  var controlList = [];
  gapList.forEach(function (gap) {
    var _gap$links2;
    if ((gap === null || gap === void 0 || (_gap$links2 = gap.links) === null || _gap$links2 === void 0 ? void 0 : _gap$links2.control) !== undefined) {
      gap.links.control.forEach(function (control) {
        if (!isInList(control, controlList)) {
          controlList.push(control);
        }
      });
    }
  });
  return controlList.filter(function (item) {
    return item !== null;
  });
}
export function addGapsToControls(controls, gapList) {
  controls.forEach(function (control) {
    control.gap = findGaps(control, gapList);
  });
}
function isInList(activity, list) {
  var bool = false;
  if (activity === null) {
    return false;
  }
  list = list.filter(function (item) {
    return item !== null;
  });
  list.forEach(function (item) {
    if (item.ipath !== undefined && item.ipath === activity.ipath || item.id !== undefined && item.id === activity.id) {
      bool = true;
    }
  });
  return bool;
}
function findGaps(activity, gapList) {
  return gapList.filter(function (item) {
    var _item$links, _item$links2;
    var bool = false;
    if ((item === null || item === void 0 || (_item$links = item.links) === null || _item$links === void 0 ? void 0 : _item$links.execution) !== undefined) {
      item.links.execution.forEach(function (initiative) {
        if (initiative.ipath === activity.ipath) {
          bool = true;
        }
      });
    }
    if ((item === null || item === void 0 || (_item$links2 = item.links) === null || _item$links2 === void 0 ? void 0 : _item$links2.control) !== undefined) {
      item.links.control.forEach(function (control) {
        if (control.cpath === activity.cpath) {
          bool = true;
        }
      });
    }
    return bool;
  });
}
export function nullKeysToString(myObject) {
  Object.keys(myObject).map(function (key) {
    myObject[key] = myObject[key] === null ? '' : myObject[key];
  });
  return myObject;
}
export function getGapUrlForPreview(gap, projectId) {
  return "/compliance/".concat(projectId, "/edit/").concat(gap.nodeId, "/gap/").concat(gap.articleId);
}