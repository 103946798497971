import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "simple-view"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "mr-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_static_table = _resolveComponent("static-table");
  var _component_info_tool_tip = _resolveComponent("info-tool-tip");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_static_table, {
    "dataset-table": $options.dataset,
    columns: $options.columns,
    "hover-titles": $data.hoverTitles
  }, {
    name: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData.data,
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $data.updated = true;
        }),
        onDismissModal: $options.dismissPreviewModal
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(slotData.name), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])];
    }),
    actions: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [slotData.removable ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "x",
        class: "stroke-red stroke-3 cursor-pointer",
        onClick: function onClick($event) {
          return $options.deleteItem(slotData);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock("span", _hoisted_2))];
    }),
    trafficlight: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createVNode(_component_traffic_light, {
        color: slotData.color
      }, null, 8 /* PROPS */, ["color"])];
    }),
    processes: _withCtx(function (_ref4) {
      var _slotData$nodes;
      var slotData = _ref4.slotData;
      return [_withDirectives((_openBlock(), _createElementBlock("div", {
        ref: "helptooltip".concat(slotData.index),
        class: "flex"
      }, [_createElementVNode("span", _hoisted_3, _toDisplayString(slotData.count), 1 /* TEXT */), (_slotData$nodes = slotData.nodes) !== null && _slotData$nodes !== void 0 && _slotData$nodes.length ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "info",
        class: "stroke-3 text-blue-750 cursor-pointer",
        onClick: function onClick($event) {
          return _ctx.handleHelpCircleClick(slotData.nodes, slotData.index);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, function (evt) {
        return _ctx.handleClickOusideToolTip(slotData.index, evt);
      }]])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns", "hover-titles"]), _createVNode(_component_info_tool_tip, {
    "tool-tip-data": _ctx.currentToolTipData,
    onRefresh: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('refreshData');
    }),
    "onReset:toolTipData": _ctx.resetToolTip
  }, null, 8 /* PROPS */, ["tool-tip-data", "onReset:toolTipData"])]);
}