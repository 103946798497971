import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex flex-wrap justify-between"
};
var _hoisted_3 = {
  class: "flex justify-between w-full"
};
var _hoisted_4 = ["href"];
var _hoisted_5 = {
  class: "flex justify-center w-7/20"
};
var _hoisted_6 = {
  key: 0,
  class: "text-xs mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showSaveSuccess ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "success",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showSaveSuccess = false;
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.settingsAreSaved), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.translatedTips && $options.translatedTips.length ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "info",
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.tips = [];
    })
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.translatedTips, function (tip, idx) {
        return _openBlock(), _createElementBlock("p", {
          key: idx
        }, _toDisplayString(tip), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.translatedErrors && $options.translatedErrors.length ? (_openBlock(), _createBlock(_component_notification, {
    key: 2,
    type: "error",
    closeable: false
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.translatedErrors, function (error, idx) {
        return _openBlock(), _createElementBlock("p", {
          key: idx
        }, _toDisplayString(error), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.showSaveError ? (_openBlock(), _createBlock(_component_notification, {
    key: 3,
    type: "error",
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showSaveError = false;
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.somethingWentWrongWhileUploading), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.settings, function (group, name) {
    return _openBlock(), _createElementBlock("div", {
      key: name,
      class: "mb-5"
    }, [_createVNode(_component_risma_title, {
      title: _ctx.$trans(name),
      type: "big",
      class: "title"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, function (setting, key, id) {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: key
      }, [!setting.hiddenSetting || !_ctx.getSettingValue(setting.hiddenSetting) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["flex flex-grow-0 flex-col m-4 w-full lg:w-9/2", id % 2 == 0 ? '' : 'flex-end'])
      }, [_createElementVNode("div", _hoisted_3, [setting.link ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: setting.link,
        class: "self-center",
        target: "_blank"
      }, [_createVNode(_component_feather_icon, {
        icon: "help-circle",
        class: "stroke-3 text-blue-750"
      })], 8 /* PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: setting.translatableTitle ? setting.name : _ctx.$trans(setting.name),
        truncate: false,
        class: "w-1/2 my-auto mx-2",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_5, [setting.type === 'responsible' ? (_openBlock(), _createBlock(_component_single_select, {
        key: 0,
        modelValue: setting.selectedValue,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return setting.selectedValue = $event;
        },
        disabled: $options.isReadonly,
        "un-sorted": !setting.isSorted,
        "with-reset": !!setting.withReset,
        placeholder: $data.translate.notSet,
        options: $options.responsibleOptions
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "un-sorted", "with-reset", "placeholder", "options"])) : _createCommentVNode("v-if", true), setting.dropdown || setting.type === 'activityType' ? (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        modelValue: setting.selectedValue,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return setting.selectedValue = $event;
        },
        disabled: $options.isReadonly,
        "un-sorted": !setting.isSorted,
        "with-reset": !!setting.withReset,
        placeholder: $data.translate.notSet,
        options: setting.type === 'activityType' ? $options.filteredActivityTypes : $options.getSingleSelectArray(setting.dropdown, setting.translatable, setting.withReset)
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "un-sorted", "with-reset", "placeholder", "options"])) : setting.type === 'stringvalue' ? (_openBlock(), _createBlock(_component_input_field, {
        key: 2,
        modelValue: setting.selectedValue,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return setting.selectedValue = $event;
        },
        enabled: !$options.isReadonly,
        class: "w-full",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled"])) : setting.type === 'intvalue' ? (_openBlock(), _createBlock(_component_input_field, {
        key: 3,
        modelValue: setting.selectedValue,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return setting.selectedValue = $event;
        },
        class: "w-full",
        enabled: !$options.isReadonly,
        type: "number"
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled"])) : setting.type === "boolvalue" ? (_openBlock(), _createBlock(_component_risma_toggle, {
        key: 4,
        "model-value": !!setting.selectedValue,
        disabled: $options.isReadonly,
        onInput: function onInput($event) {
          return setting.selectedValue = !!$event;
        }
      }, null, 8 /* PROPS */, ["model-value", "disabled", "onInput"])) : _createCommentVNode("v-if", true)])]), setting.helpText ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$trans(setting.helpText)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
    }), 128 /* KEYED_FRAGMENT */))])]);
  }), 128 /* KEYED_FRAGMENT */)), !$options.isReadonly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 4,
    text: _ctx.$trans('Save settings'),
    class: "mt-6",
    type: "save",
    onClick: $options.saveSettings
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)]);
}