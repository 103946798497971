import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "user-edit-modal-kitchen"
};
var _hoisted_2 = {
  class: "update"
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_user_edit_modal = _resolveComponent("user-edit-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "create cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.toogleModal('create');
    })
  }, [_createVNode(_component_risma_title, {
    title: "Create New User"
  }), _createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: "plus",
    color: "green"
  })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: "Update User"
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.users, function (user) {
    return _openBlock(), _createElementBlock("div", {
      key: user.id,
      class: "update-elem",
      onClick: function onClick($event) {
        return $options.toogleModal('update', user);
      }
    }, [_createVNode(_component_risma_title, {
      title: user.display_name,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */))]), $data.opened ? (_openBlock(), _createBlock(_component_user_edit_modal, {
    key: 0,
    type: $data.type,
    information: $data.information,
    "all-users": $data.allUsers,
    "all-organisations": $data.allOrganisations,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $options.toogleModal('closed');
    }),
    onCreate: $options.createAction
  }, null, 8 /* PROPS */, ["type", "information", "all-users", "all-organisations", "onCreate"])) : _createCommentVNode("v-if", true)]);
}