function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { capitalize } from '@/utils';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import { IATypeViews } from '@/constants/compliance/InformationAssetsViews';
export default {
  name: 'InformationAssetsWrapper',
  components: {
    VerticalMenu: VerticalMenu
  },
  props: {
    onlyMy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      isLoaded: false,
      translate: getTranslate['InformationAssetsWrapper']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), {}, {
    menuItems: function menuItems() {
      var baseUrl = '/compliance/informationassets';
      var baseOnlyMyUrl = '/compliance/informationassets/my';
      var pathName = IATypeViews.OVERVIEW;
      var menuItems = [{
        title: this.translate.overview,
        path: "".concat(baseUrl, "/type/all")
      }, {
        title: this.translate.my + ' ' + (this.getSettingValue('feature.information_assets_name') || this.translate.assets),
        path: "".concat(baseOnlyMyUrl, "/type")
      }];
      if (this.getSettingValue('feature.enable_data_controllers') == undefined || this.getSettingValue('feature.enable_data_controllers')) {
        menuItems.push({
          title: this.translate.controllers,
          path: {
            name: IATypeViews.CONTROLLERS
          }
        });
      }
      if (this.getSettingValue('feature.enable_data_processors') == undefined || this.getSettingValue('feature.enable_data_processors')) {
        menuItems.push({
          title: this.translate.processors,
          path: {
            name: IATypeViews.PROCESSOR
          }
        });
      }
      if (this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems')) {
        menuItems.push({
          title: this.translate.systems,
          path: {
            name: IATypeViews.SYSTEMS
          }
        });
      }
      menuItems = menuItems.concat(this.activityTypes.map(function (item) {
        return {
          title: capitalize(item.label),
          path: {
            name: pathName,
            params: {
              typeId: item.id
            }
          }
        };
      }));
      return menuItems;
    }
  })
};