import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-1"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "max-w-350px my-2"
};
var _hoisted_4 = {
  class: "w-full"
};
var _hoisted_5 = {
  class: "table-cell"
};
var _hoisted_6 = {
  class: "table-cell"
};
var _hoisted_7 = {
  class: "table-cell"
};
var _hoisted_8 = {
  class: "table-cell"
};
var _hoisted_9 = {
  class: "table-cell"
};
var _hoisted_10 = {
  class: "table-cell"
};
var _hoisted_11 = {
  class: "table-cell"
};
var _hoisted_12 = ["onClick"];
var _hoisted_13 = {
  align: "center",
  class: "table-cell"
};
var _hoisted_14 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_admin_custom_fields_modal = _resolveComponent("admin-custom-fields-modal");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.customFields
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.createCustomField
      }, null, 8 /* PROPS */, ["onClick"])]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_label, {
        title: $data.translate.filterByModule
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_select, {
        options: $options.modulesOptions,
        "selected-options": $data.selectedModules,
        "max-visible-tags": -1,
        placeholder: $data.translate.noFiltersApplied,
        "label-key": "label",
        onSelected: _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedModules = $event;
        })
      }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder"])]), _createElementVNode("table", _hoisted_4, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_5, _toDisplayString($data.translate.title), 1 /* TEXT */), _createElementVNode("th", _hoisted_6, _toDisplayString($data.translate.fieldType), 1 /* TEXT */), _createElementVNode("th", _hoisted_7, _toDisplayString($data.translate.module), 1 /* TEXT */), _cache[1] || (_cache[1] = _createElementVNode("th", {
        class: "table-cell",
        width: "20"
      }, null, -1 /* HOISTED */)), _cache[2] || (_cache[2] = _createElementVNode("th", {
        class: "table-cell",
        width: "20"
      }, null, -1 /* HOISTED */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredFields, function (field, key) {
        return _openBlock(), _createElementBlock("tr", {
          key: key,
          class: _normalizeClass({
            inactive: field.disabled == 1
          })
        }, [_createElementVNode("td", _hoisted_8, _toDisplayString(field.label), 1 /* TEXT */), _createElementVNode("td", _hoisted_9, _toDisplayString(field.fieldtype_text), 1 /* TEXT */), _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.moduleTitles[field.module]), 1 /* TEXT */), _createElementVNode("td", _hoisted_11, [_createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.editCustomField(field.id);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          icon: "edit",
          class: "stroke-2 text-blue-350"
        })], 8 /* PROPS */, _hoisted_12)]), _createElementVNode("td", _hoisted_13, [!field.hasValues ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.deleteCustomField(field.id);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "stroke-2 text-red-50"
        })], 8 /* PROPS */, _hoisted_14)) : _createCommentVNode("v-if", true)])], 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */))])])])), _createVNode(_component_admin_custom_fields_modal, {
        ref: "modal",
        settings: $data.settings,
        "custom-fields": $data.fields,
        onCreated: $options.onCreated,
        onEdited: $options.onEdit
      }, null, 8 /* PROPS */, ["settings", "custom-fields", "onCreated", "onEdited"])];
    }),
    _: 1 /* STABLE */
  });
}