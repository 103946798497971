import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "scoring-axis-edit"
};
var _hoisted_2 = {
  class: "row"
};
var _hoisted_3 = {
  key: 0,
  class: "col-md-12"
};
var _hoisted_4 = ["disabled"];
var _hoisted_5 = {
  key: 0,
  class: "grid grid-cols-2 gap-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.useToggle ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.showForm = $event;
    }),
    type: "checkbox",
    value: "ok",
    checked: "checked",
    disabled: $props.readOnly,
    class: "mr-3"
  }, null, 8 /* PROPS */, _hoisted_4), [[_vModelCheckbox, $data.showForm]]), _createTextVNode(" " + _toDisplayString($data.translate.useZaxis), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), $data.showForm ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_component_input_field, {
    "model-value": $props.title || $data.vTitle,
    placeholder: $data.translate.axisTitle,
    class: "mb-4",
    enabled: !$props.readOnly,
    type: "text",
    "onUpdate:modelValue": $options.onTitleChange
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "enabled", "onUpdate:modelValue"]), _createVNode(_component_single_select, {
    "model-value": $props.scoringCount,
    disabled: $props.readOnly,
    placeholder: $data.translate.numberOfLevels,
    class: "mb-4",
    options: $options.singleSelectOptions,
    "onUpdate:modelValue": $options.onScoringCountChange
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "options", "onUpdate:modelValue"])]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredLabels, function (doc, key) {
    return _openBlock(), _createBlock(_component_input_field, {
      key: key,
      modelValue: doc.value,
      "onUpdate:modelValue": [function ($event) {
        return doc.value = $event;
      }, $options.update],
      type: "text",
      class: "mb-4",
      enabled: !$props.readOnly
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled"]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]);
}