function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import GroupsObserverMixin from '@/components/Molecules/VueNestable/GroupsObserverMixin';
export default {
  name: 'NestableItem',
  mixins: [GroupsObserverMixin],
  inject: ['listId', 'group', 'keyProp'],
  props: {
    item: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      required: false,
      default: null
    },
    isChild: {
      type: Boolean,
      required: false,
      default: false
    },
    isCopy: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      breakPoint: null,
      moveDown: false
    };
  },
  computed: {
    isDragging: function isDragging() {
      var dragItem = this.options.dragItem;
      return !this.isCopy && dragItem && dragItem[this.options.keyProp] === this.item[this.options.keyProp];
    },
    hasChildren: function hasChildren() {
      return this.item[this.options.childrenProp] && this.item[this.options.childrenProp].length > 0;
    },
    normalizedClassProp: function normalizedClassProp() {
      var classProp = this.item[this.options.classProp];

      // if the classprop is not set, return an empty array
      if (!classProp) return [];
      if (Array.isArray(classProp)) {
        return classProp;
      } else if ((typeof a === "undefined" ? "undefined" : _typeof(a)) === 'object') {
        return [classProp];
      } else {
        // String value
        return [classProp];
      }
    },
    itemClasses: function itemClasses() {
      var isDragging = this.isDragging ? ['is-dragging'] : [];
      return ["nestable-item".concat(this.isCopy ? '-copy' : ''), "nestable-item".concat(this.isCopy ? '-copy' : '', "-").concat(this.item[this.options.keyProp])].concat(isDragging, _toConsumableArray(this.normalizedClassProp));
    }
  },
  methods: {
    onMouseEnter: function onMouseEnter(event) {
      if (!this.options.dragItem) return;

      // if we don't know the direction the mouse is moving,
      // we can not calculate the offset at which we should trigger a swap
      // we we fallback to the old behavior
      if (!event.movementY) {
        return this.sendNotification(event);
      }

      // when the mouse enters the item we save the size of this item
      // is is to improve performance, so we do not recalculate the size on every move
      this.moveDown = event.movementY > 0;
      this.breakPoint = event.target.getBoundingClientRect().height / 2;
    },
    onMouseLeave: function onMouseLeave() {
      this.breakPoint = null;
    },
    onMouseMove: function onMouseMove(event) {
      // if we are not in a drag operation, we can discard the input
      if (!this.breakPoint) return;

      // calculate how much the mouse is away from the center
      var delta = event.offsetY - this.breakPoint;

      // if we have not reached the breakpoint, we can abort here
      if (this.moveDown && delta < this.breakPoint / 4) return;
      if (!this.moveDown && delta > -this.breakPoint / 4) return;
      this.sendNotification(event);
    },
    sendNotification: function sendNotification(event) {
      // reset the calculated breakpoint
      this.breakPoint = null;

      // and trigger the enter event
      var item = this.item || this.$parent.item;
      this.notifyMouseEnter(this.group, event, this.listId, item);
    }
  }
};