// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-table[data-v-0d47ec3d]  .static-table-head-cell:first-child {
  min-width: 110px;
}
.main-table[data-v-0d47ec3d]  .static-table-head-cell:nth-child(2) {
  min-width: 200px;
}
.main-table[data-v-0d47ec3d]  .static-table-head-cell:nth-child(4) {
  min-width: 200px;
}
.main-table[data-v-0d47ec3d]  .static-table-head-cell:nth-child(5) {
  min-width: 450px;
}
[data-v-0d47ec3d]  .static-table td {
  padding: 0.5rem;
  min-width: 120px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
