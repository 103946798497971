import { getDataflowThirdPartiesTitle, getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
export default {
  name: 'DataFlowWizardStep1',
  introduction: 'component is used to display WizardFlow step 1',
  description: 'This is the new data flow wizard. From here you fill out the questionnaire and map your systems, datatypes and data subjects at once',
  token: '<modal-wizard-example-step-1 />',
  data: function data() {
    return {
      translate: {
        thisIsNew: this.$trans('This is the data flow wizard. From here you can populate the questionnaire and at the same time specify %s, categories of data, legal basis, storage period and %s.', [getDataflowStakeholdersTitle().toLowerCase(), getDataflowThirdPartiesTitle().toLowerCase()]),
        nextStepIsTo: this.$trans('Next step is to choose the data subjects relevant for this processing activity.'),
        ifYouHaveMultipleAssets: this.$trans('If you have multiple data subjects you must run the wizard again for each category.')
      }
    };
  }
};