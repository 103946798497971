import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex items-center my-2"
};
var _hoisted_2 = {
  class: "mr-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($data.translate.showActivityIds), 1 /* TEXT */), _createVNode(_component_risma_toggle, {
        modelValue: $data.showIdsSwitchOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.showIdsSwitchOption = $event;
        }),
        class: "mr-1"
      }, null, 8 /* PROPS */, ["modelValue"])]), $data.dataset.length ? (_openBlock(), _createBlock(_component_risma_datatable, {
        key: 0,
        "dataset-main": _ctx.datasetSlice,
        "dataset-total": $data.dataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        "wrapper-height-sticky": 500,
        "columns-by-default": $options.columns,
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, null, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "columns-by-default", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}