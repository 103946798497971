import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, renderList as _renderList } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "w-full mt-4"
};
var _hoisted_3 = {
  class: "border-b border-blue-200"
};
var _hoisted_4 = {
  class: "pb-3 pl-3 text-left"
};
var _hoisted_5 = {
  key: 0,
  class: "pb-3 pl-3 text-left"
};
var _hoisted_6 = {
  class: "pb-3 pl-3 text-left"
};
var _hoisted_7 = {
  class: "pb-3 pl-3 text-left"
};
var _hoisted_8 = {
  class: "pb-3 pl-3 text-left"
};
var _hoisted_9 = {
  key: 0
};
var _hoisted_10 = {
  class: "p-3"
};
var _hoisted_11 = ["href", "download"];
var _hoisted_12 = {
  key: 0,
  class: "p-3"
};
var _hoisted_13 = {
  class: "p-3"
};
var _hoisted_14 = ["href", "download"];
var _hoisted_15 = {
  class: "p-3"
};
var _hoisted_16 = {
  class: "p-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.addMode ? $data.translate.addNewVersion : "".concat($data.translate.fileVersionsFor, " ").concat($props.file.title || $props.file.filename),
    "show-buttons": $props.addMode,
    "button-dismiss-text": $data.translate.cancel,
    "button-ok-text": $data.translate.apply,
    "ok-button-disabled": $props.isApplyDisabled,
    onDismiss: $options.dismissModal,
    onAccept: $options.onApply
  }, {
    body: _withCtx(function () {
      return [$props.addMode ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.errorMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_file_upload, {
        "single-file-load": true,
        "show-uploaded-files": !!$data.uploadedFile,
        class: "mb-3",
        onUpload: $options.onFileUpload,
        "onUpload:unsupported": $options.setExtensionError
      }, null, 8 /* PROPS */, ["show-uploaded-files", "onUpload", "onUpload:unsupported"]), _createVNode(_component_input_field, {
        modelValue: $data.fileTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.fileTitle = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        class: "mb-5",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $props.typeOptions.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_select, {
        title: $data.translate.type,
        options: $props.typeOptions,
        "selected-options": $data.selectedDocTypes,
        placeholder: $data.translate.chooseType,
        "label-key": "label",
        "track-by": "id",
        "un-sorted": true,
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $data.selectedDocTypes = $event;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _createElementVNode("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_3, [_createElementVNode("th", _hoisted_4, _toDisplayString($data.translate.title), 1 /* TEXT */), $props.typeOptions.length ? (_openBlock(), _createElementBlock("th", _hoisted_5, _toDisplayString($data.translate.type), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("th", _hoisted_6, _toDisplayString($data.translate.fileName), 1 /* TEXT */), _createElementVNode("th", _hoisted_7, _toDisplayString($data.translate.uploadDate), 1 /* TEXT */), _createElementVNode("th", _hoisted_8, _toDisplayString($data.translate.uploaderName), 1 /* TEXT */), _cache[3] || (_cache[3] = _createElementVNode("th", null, null, -1 /* HOISTED */)), _cache[4] || (_cache[4] = _createElementVNode("th", null, null, -1 /* HOISTED */))])]), $options.orderedRevisions ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.orderedRevisions, function (revision, index) {
        return _openBlock(), _createElementBlock("tr", {
          key: index
        }, [_createElementVNode("td", _hoisted_10, [_createElementVNode("a", {
          type: "download",
          target: "_self",
          href: revision.url || '',
          download: revision.filename
        }, _toDisplayString(revision.title), 9 /* TEXT, PROPS */, _hoisted_11)]), $props.typeOptions.length ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString($options.getDocTypeIdsValues(revision)), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("td", _hoisted_13, [_createElementVNode("a", {
          type: "download",
          target: "_self",
          href: revision.url || '',
          download: revision.filename
        }, _toDisplayString(revision.filename), 9 /* TEXT, PROPS */, _hoisted_14)]), _createElementVNode("td", _hoisted_15, _toDisplayString($options.toLocalTime(revision.created)), 1 /* TEXT */), _createElementVNode("td", _hoisted_16, _toDisplayString($options.getUserById(revision.owner)), 1 /* TEXT */), _createElementVNode("td", null, [index === 0 && revision.version != 1 && !$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: "x",
          class: "stroke-2 text-red-50 cursor-pointer",
          onClick: function onClick($event) {
            return $options.deleteRevision(revision.id);
          }
        }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "show-buttons", "button-dismiss-text", "button-ok-text", "ok-button-disabled", "onDismiss", "onAccept"]);
}