import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Transition as _Transition, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "collapsible-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["collapsible-panel-header flex items-center w-fit", {
      'cursor-pointer': !$props.disabled
    }]),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleCollapse && $options.toggleCollapse.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "header", {}, undefined, true), !$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "chevron-down",
    class: _normalizeClass(['transform duration-500 text-blue-750 stroke-2 ml-2 inline-block align-top', {
      'rotate-180': $setup.isExpanded
    }])
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createVNode(_Transition, _mergeProps({
    name: "accordion-item"
  }, _ctx.$attrs, {
    onEnter: $options.startTransition,
    onAfterEnter: $options.endTransition,
    onBeforeLeave: $options.startTransition,
    onAfterLeave: $options.endTransition
  }), {
    default: _withCtx(function () {
      return [$setup.isExpanded ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["collapsible-panel-content flex pb-4", {
          'overflow-hidden': !$props.overflowVisible
        }])
      }, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["onEnter", "onAfterEnter", "onBeforeLeave", "onAfterLeave"])]);
}