import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "text-center"
};
var _hoisted_2 = {
  key: 1,
  class: "flex"
};
var _hoisted_3 = {
  class: "flex-shrink-0 mr-10 max-h-650px overflow-y-auto"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "leading-5"
};
var _hoisted_6 = {
  class: "leading-5"
};
var _hoisted_7 = {
  class: "flex-grow min-w-0 overflow-x-scroll border border-gray-350 rounded-lg p-4"
};
var _hoisted_8 = {
  class: "flex justify-between mb-3"
};
var _hoisted_9 = {
  class: "flex items-center mb-5"
};
var _hoisted_10 = {
  class: "mb-6 w-full history-table"
};
var _hoisted_11 = {
  key: 0,
  class: "truncate"
};
var _hoisted_12 = ["href", "title"];
var _hoisted_13 = {
  key: 0
};
var _hoisted_14 = {
  key: 1
};
var _hoisted_15 = {
  key: 1,
  colspan: "2"
};
var _hoisted_16 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_link_item = _resolveComponent("link-item");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  return _openBlock(), _createElementBlock("div", null, [$props.data.history.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.theControlHasNoHistoryBecauseItHasNotBee), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.history, function (recordItem, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      style: _normalizeStyle("border-left-color: ".concat($options.getTrafficlightByNumber(recordItem.trafficLight), ";")),
      class: _normalizeClass([{
        'shadow-box-360': $data.record.id === recordItem.id
      }, "border border-gray-350 border-l-8 rounded-lg min-w-250px px-4 py-2 cursor-pointer"]),
      onClick: function onClick($event) {
        return $data.record = recordItem;
      }
    }, [_createVNode(_component_risma_title, {
      title: $options.getTrafficTitle(recordItem.trafficLightText),
      type: "medium",
      class: "leading-5"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_5, _toDisplayString($options.formatedDate(recordItem.deadline, recordItem.deadlineTimezone)), 1 /* TEXT */), _createElementVNode("p", _hoisted_6, _toDisplayString($options.getUserById(recordItem.completedUserid)), 1 /* TEXT */)], 14 /* CLASS, STYLE, PROPS */, _hoisted_4);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.deadline, " ").concat($options.formatedDate($data.record.deadline, $data.record.deadlineTimezone)),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    class: "stroke-2 cursor-pointer",
    icon: "download",
    onClick: $options.handleExportToExcel
  }, null, 8 /* PROPS */, ["onClick"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_traffic_light, {
    color: $options.getTrafficlightByNumber($data.record.trafficLight),
    class: "mr-2"
  }, null, 8 /* PROPS */, ["color"]), _createVNode(_component_risma_title, {
    title: $options.getFullTrafficTitle($data.record.trafficLightText),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("table", _hoisted_10, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.commonColumns, function (columnLabel, _, columnsIndex) {
    return _openBlock(), _createElementBlock("th", {
      key: columnLabel
    }, [_createVNode(_component_risma_title, {
      title: columnLabel,
      truncate: columnsIndex < 5,
      type: "medium"
    }, null, 8 /* PROPS */, ["title", "truncate"])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.reverseReviewsOrder($data.record.reviews), function (review, i) {
    var _$options$getBaseTraf;
    return _openBlock(), _createElementBlock("tr", {
      key: review.id,
      class: _normalizeClass(i % 2 ? 'bg-gray-75' : '')
    }, [_createElementVNode("td", null, _toDisplayString($options.formatedDate(review.created, $data.record.deadlineTimezone)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.getHumanReadableAction(review.action, review.userId)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.getUserById(review.userId)), 1 /* TEXT */), _createElementVNode("td", null, [$options.isCorrectUrl(review.url) ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("a", {
      target: "_blank",
      href: review.url,
      title: review.urlTitle || review.url,
      class: "hover:underline",
      rel: "noopener noreferrer"
    }, [_createVNode(_component_feather_icon, {
      icon: "link",
      width: "15",
      height: "15",
      class: "text-gray-610 stroke-2 inline-block"
    }), _createTextVNode(" " + _toDisplayString(review.urlTitle || review.url), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_12)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(review.files, function (file) {
      return _openBlock(), _createBlock(_component_link_item, {
        key: file.id,
        title: file.name,
        tooltip: file.name,
        icon: file.mimeType === 'text/uri-list' ? 'link' : 'file',
        link: $options.getAttachmentLink(file)
      }, null, 8 /* PROPS */, ["title", "tooltip", "icon", "link"]);
    }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("td", null, [$props.data.completedCommentsFormat ? (_openBlock(), _createElementBlock("pre", _hoisted_13, _toDisplayString(review.comment), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(review.comment), 1 /* TEXT */))]), $options.assessmentsVisible ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [review.completedEfficiency !== undefined && review.completedEfficiency !== null ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("td", null, [_createVNode(_component_traffic_light, {
      color: (_$options$getBaseTraf = $options.getBaseTrafficlightByNumber(review.completedEfficiency)) === null || _$options$getBaseTraf === void 0 ? void 0 : _$options$getBaseTraf.color
    }, null, 8 /* PROPS */, ["color"])]), _createElementVNode("td", null, [_createElementVNode("span", null, _toDisplayString(review.completedEfficiencyComment), 1 /* TEXT */)])], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("td", _hoisted_15))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))])]), $options.showCustomFields($data.record) ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.customFields,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_activity_custom_fields, {
    "activity-type": $options.getActivityByIdAndModule($data.record.activityTypeId),
    "custom-field-values": $data.record.customFieldValues,
    readonly: true
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values"])])) : _createCommentVNode("v-if", true)])]))]);
}