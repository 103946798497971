// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.editor-box-list[data-v-51762665]  ul {
  padding-left: 0.5em;
}
.editor-box-list[data-v-51762665]  ul li {
  list-style-type: none;
}
.editor-box-list[data-v-51762665]  ul li::before {
  content: "•";
  margin-right: 0.25rem;
}
.list-reset-styles[data-v-51762665]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-51762665]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-51762665]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-51762665]  li {
  padding-left: 0.3em;
}
[data-v-51762665]  .notification {
  margin: 15px 0;
}
.complete-control-body[data-v-51762665] {
  margin: -0.25rem 0 0 0;
}
.complete-control-body p[data-v-51762665] {
  margin-bottom: 0.1rem;
}
.complete-control-body[data-v-51762665]  .ql-container {
  height: 60px;
}
.complete-control-body .complete-control-section[data-v-51762665] {
  margin: 0 0 24px 0;
}
.complete-control-body .complete-control-section.complete-control-section-action[data-v-51762665] {
  margin: 40px 0 0 0;
}
.complete-control-body .complete-control-section .complete-control-section-body[data-v-51762665] {
  margin: 10px 0;
}
.complete-control-section-expand-content[data-v-51762665] {
  padding: 0.5rem;
}
.prev-completions-wrap.active .prev-completed-date[data-v-51762665],
.prev-completions-wrap.active .show-history-link[data-v-51762665] {
  font-weight: 600;
}
.prev-history-table[data-v-51762665],
.review-history-table[data-v-51762665] {
  table-layout: auto;
  width: 100%;
  margin: 0 0 1rem;
}
.prev-history-table th[data-v-51762665]  h3,
.review-history-table th[data-v-51762665]  h3 {
  font-size: 15px;
}
.prev-history-table th[data-v-51762665],
.prev-history-table td[data-v-51762665],
.review-history-table th[data-v-51762665],
.review-history-table td[data-v-51762665] {
  padding: 5px;
}
.prev-history-table .user-cell[data-v-51762665],
.prev-history-table .action-cell[data-v-51762665],
.prev-history-table .date-cell[data-v-51762665],
.prev-history-table .deadline-cell[data-v-51762665],
.review-history-table .user-cell[data-v-51762665],
.review-history-table .action-cell[data-v-51762665],
.review-history-table .date-cell[data-v-51762665],
.review-history-table .deadline-cell[data-v-51762665] {
  width: 120px;
}
.prev-history-table .comments-cell[data-v-51762665],
.prev-history-table .attachments-cell[data-v-51762665],
.review-history-table .comments-cell[data-v-51762665],
.review-history-table .attachments-cell[data-v-51762665] {
  width: 180px;
}
.prev-history-table .comments-cell[data-v-51762665]  .link-item,
.prev-history-table .attachments-cell[data-v-51762665]  .link-item,
.review-history-table .comments-cell[data-v-51762665]  .link-item,
.review-history-table .attachments-cell[data-v-51762665]  .link-item {
  max-width: 170px;
}
.prev-history-table td[data-v-51762665],
.review-history-table td[data-v-51762665] {
  border-top: 1px solid #CCCCCC;
  vertical-align: top;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
