import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-row py-2"
};
var _hoisted_2 = {
  class: "py-1"
};
var _hoisted_3 = ["src", "alt"];
var _hoisted_4 = {
  key: 1,
  class: "flex h-full"
};
var _hoisted_5 = ["src", "alt"];
var _hoisted_6 = ["href", "download"];
var _hoisted_7 = ["href"];
var _hoisted_8 = {
  class: "truncate"
};
var _hoisted_9 = {
  class: "flex mb-1"
};
var _hoisted_10 = {
  key: 0,
  class: "rounded-full bg-gray-610 w-2 h-2 my-auto mx-3"
};
var _hoisted_11 = {
  class: "flex w-250px"
};
var _hoisted_12 = {
  class: "flex flex-col w-1/2"
};
var _hoisted_13 = ["href", "download"];
var _hoisted_14 = ["href"];
var _hoisted_15 = {
  class: "flex flex-col w-1/2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["relative w-24 border border-gray-350 mr-4 text-center hover:border-blue-750", $options.canBeDownloaded ? 'h-24' : 'h-14'])
  }, [$options.isImage ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "h-full absolute left-0 right-0 m-auto object-cover",
    src: $props.file.url,
    alt: $props.file.filename
  }, null, 8 /* PROPS */, _hoisted_3)) : !$options.canBeDownloaded ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_feather_icon, {
    icon: "link",
    class: "m-auto text-gray-610 stroke-2"
  })])) : (_openBlock(), _createElementBlock("img", {
    key: 2,
    class: "max-h-full h-full w-full m-auto p-5",
    src: $options.fileIcon,
    alt: $props.file.filename
  }, null, 8 /* PROPS */, _hoisted_5)), $options.canBeDownloaded ? (_openBlock(), _createElementBlock("a", {
    key: 3,
    class: "absolute inset-0",
    href: $props.file.url,
    download: $props.file.filename,
    target: "_self",
    type: "download"
  }, null, 8 /* PROPS */, _hoisted_6)) : (_openBlock(), _createElementBlock("a", {
    key: 4,
    class: "absolute inset-0",
    href: $props.file.url,
    target: "_blank",
    type: "link",
    rel: "noopener noreferrer"
  }, null, 8 /* PROPS */, _hoisted_7))], 2 /* CLASS */)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $props.file.title || $props.file.filename,
    type: "medium",
    class: "mr-3 text-blue-750"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $options.user,
    class: "created-by",
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $props.file.created ? (_openBlock(), _createElementBlock("div", _hoisted_10)) : _createCommentVNode("v-if", true), $props.file.created ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    title: $options.fileCreatedTitle,
    "title-attribute": $options.fileCreatedTitleHTML,
    type: "small"
  }, null, 8 /* PROPS */, ["title", "title-attribute"])) : _createCommentVNode("v-if", true), $props.file.pinned ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(["flex ml-2", $props.readOnly ? 'cursor-default' : 'cursor-pointer']),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $props.readOnly ? null : _ctx.$emit('onUnpin', $props.file.id);
    })
  }, [_createVNode(_component_feather_icon, {
    class: "mr-1 stroke-2 text-green-150",
    icon: "award"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.unpin,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])], 2 /* CLASS */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [$options.canBeDownloaded ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    class: "flex mr-6 leading-5 my-1",
    href: $props.file.url,
    download: $props.file.filename,
    target: "_self",
    type: "download"
  }, [_createVNode(_component_feather_icon, {
    icon: "download",
    class: "transform text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.download,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_13)) : (_openBlock(), _createElementBlock("a", {
    key: 1,
    class: "flex mr-4 leading-5 my-1",
    href: $props.file.url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, [_createVNode(_component_feather_icon, {
    icon: "link",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.link,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_14)), $props.hasVersionButtons && !$props.readOnly && $options.canBeDownloaded ? (_openBlock(), _createElementBlock("button", {
    key: 2,
    class: "flex mr-3 leading-5 focus:outline-none my-1",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('onCreateNewVersion', $props.file.id);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.newVersion,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), !$props.readOnly && $options.isAttachmentEdible ? (_openBlock(), _createElementBlock("button", {
    key: 3,
    class: "flex mr-3 leading-5 focus:outline-none my-1",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('onAttachmentEdit', $props.file);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "edit",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.edit,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_15, [!$props.readOnly && !$props.hideRemoveIcon ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "flex leading-5 focus:outline-none my-1",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('onDelete', $props.file.id);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.delete,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.hasVersionButtons && $props.file.version != 1 && $options.canBeDownloaded ? (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: "flex leading-5 focus:outline-none my-1",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('onShowVersions', $props.file.id);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "copy",
    class: "text-gray-610 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: "".concat($data.translate.showVersions, " (").concat($props.file.version, ")"),
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])])])]);
}