import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "border-t-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.info ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "info",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.info = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.info), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.createDefaultKpis
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("table", null, [_createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dashboards, function (dashboard, index) {
        return _openBlock(), _createElementBlock("tr", {
          key: dashboard
        }, [_createElementVNode("td", null, _toDisplayString(dashboard), 1 /* TEXT */), _createElementVNode("td", null, [_createVNode(_component_risma_button, {
          text: $data.translate.createDefaultKpis,
          onClick: function onClick($event) {
            return $options.createKpis(index);
          }
        }, null, 8 /* PROPS */, ["text", "onClick"])])]);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("tr", _hoisted_1, [_createElementVNode("td", null, _toDisplayString($data.translate.all), 1 /* TEXT */), _createElementVNode("td", null, [_createVNode(_component_risma_button, {
        text: $data.translate.createDefaultKpis,
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $options.createKpis(-1);
        })
      }, null, 8 /* PROPS */, ["text"])])])])]), _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createElementVNode("b", null, _toDisplayString($data.translate.runKPIsHeader), 1 /* TEXT */), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_button, {
        text: $data.translate.runKPIs,
        type: "save",
        onClick: $options.runKpis
      }, null, 8 /* PROPS */, ["text", "onClick"])];
    }),
    _: 1 /* STABLE */
  });
}