import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { IATitles } from '@/constants/compliance/ComplianceControllers';
import { formatTimeToDateFormat, formatTimeToDateTimeFormat } from '@/utils/date';
import { ObjectTypes } from '@/constants/ObjectTypes';
var qt = QuestionTypes();
export function getAnswerOptions(question) {
  if (question.objType === ObjectTypes.COMPLIANCE_QUESTION_ANSWER) {
    switch (question.questiontype) {
      case qt.informationAsset.id:
      case qt.informationAssetSystems.id:
      case qt.informationAssetProcessors.id:
      case qt.informationAssetControllers.id:
      case qt.customInformationAsset.id:
        return prepareIaQuestionAnswer(question);
      case qt.processLibrary.id:
        return question.processLibrary.map(function (item) {
          return item.title;
        });
      case qt.date.id:
        return [formatTimeToDateFormat(question.dateTime)];
      case qt.dateTime.id:
        return [formatTimeToDateTimeFormat(question.dateTime)];
      case qt.attachment.id:
      case qt.attachmentAndFreeText.id:
        return question.files.map(function (file) {
          return file.filename;
        });
      case qt.freeText.id:
        return [question.freeText];
      default:
        return Object.values(question.answers_options);
    }
  }
  if (question.objType === ObjectTypes.COMPLIANCE_QUESTION) {
    return question.questionAnswers.filter(function (answer) {
      return answer.complianceQuestionoptionsId !== null;
    }).map(function (answer) {
      return question.options[answer.complianceQuestionoptionsId];
    });
  }
  return [];
}
function prepareIaQuestionAnswer(question) {
  var data = {};
  Object.keys(question.informationAssets).forEach(function (iaItem) {
    if (question.informationAssets[iaItem].length) {
      data[iaItem] = {
        title: IATitles[iaItem] || iaItem,
        items: question.informationAssets[iaItem].map(function (element) {
          return element.name || element.title;
        }).join(', ')
      };
    }
  });
  return data;
}
export function scrollToSelectedQuestion(refElements, isPreview) {
  var PADDING_TOP = isPreview ? 150 : 120;
  if (!(refElements !== null && refElements !== void 0 && refElements.length)) return;
  var questionElement = refElements[0].$el;
  if (!questionElement) return;
  var _questionElement$getB = questionElement.getBoundingClientRect(),
    distance = _questionElement$getB.top;
  var scrollContainer = isPreview ? document.querySelector('.preview-modal') : window;
  var currentPosition = (isPreview ? scrollContainer : document.documentElement).scrollTop;
  scrollContainer.scrollTo({
    top: currentPosition + distance - PADDING_TOP,
    behavior: 'smooth'
  });
}