import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_table = _resolveComponent("risma-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_table, {
        "state-key": "audit_reports_settings_changelog",
        api: $options.getSettingsChangelogBE
      }, null, 8 /* PROPS */, ["api"])];
    }),
    _: 1 /* STABLE */
  });
}