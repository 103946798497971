import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_edit_compliance_project_modal = _resolveComponent("edit-compliance-project-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showEditModal ? (_openBlock(), _createBlock(_component_edit_compliance_project_modal, {
    key: 0,
    project: $data.projectToUpdate,
    questionnaires: $options.questionnairesOptions,
    "gap-schemas": $options.gapSchemasOptions,
    frameworks: _ctx.frameworks,
    "activity-types": _ctx.activityTypes,
    onAccept: $options.updateProject,
    onDismiss: $options.dismissEditModal
  }, null, 8 /* PROPS */, ["project", "questionnaires", "gap-schemas", "frameworks", "activity-types", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.complianceProjectAdministration,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.projects.length ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        columns: $options.columns,
        "dataset-table": $options.dataset,
        theme: "zebra",
        "columns-styles": $options.columnsStyles,
        "is-headers-truncated": false
      }, {
        edit: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("button", {
            class: "focus:outline-none mr-5",
            onClick: function onClick($event) {
              return $options.openEditModal(slotData);
            }
          }, [_createVNode(_component_feather_icon, {
            icon: "edit",
            class: "stroke-2 text-blue-350",
            title: $data.translate.edit
          }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_2)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset-table", "columns-styles"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}