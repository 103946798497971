function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { defineAsyncComponent } from 'vue';
import { getAllTrees } from '@/api/compliance/processtree';
import Notification from '@/components/Molecules/Notification';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var MiniTree = defineAsyncComponent(function () {
  return import('@/components/Pages/ProcessTree/ProcessMiniTreePage');
});
var Tree = defineAsyncComponent(function () {
  return import('@/components/Pages/ProcessTree/ProcessTreePage');
});
export default {
  name: 'ProcessTreeOverview',
  components: {
    RismaButton: RismaButton,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  setup: function setup() {
    return {
      showMiniTreeComponent: getLocalStorageComputed("show_mini_tree_process_library", true, true)
    };
  },
  data: function data() {
    return {
      loading: false,
      processTree: null,
      error: '',
      translate: {
        defaultTree: this.$trans('Default tree'),
        detailedTree: this.$trans('Detailed tree'),
        noProcessesFound: this.$trans('No processes found')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isLeftTreeProject: function isLeftTreeProject() {
      return !!this.getSettingValue('feature.process_library_mini_tree_enabled');
    },
    overviewComponent: function overviewComponent() {
      return this.isLeftTreeProject ? MiniTree : Tree;
    }
  }),
  beforeMount: function beforeMount() {
    var _this = this;
    this.loading = true;
    getAllTrees().then(function (response) {
      if (response[0].id) {
        _this.processTree = response[0];
      } else {
        _this.error = _this.translate.noProcessesFound;
      }
    }).finally(function () {
      _this.loading = false;
    });
  }
};