function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useCountriesStore } from '@/Store/countriesStore';
import { useSettingsStore } from '@/Store/settingsStore';
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import MiniTree from '@/components/Molecules/MiniTree/MiniTree';
import AddContractPartyModalExtended from '@/components/Organisms/Contracts/AddContractPartyModalExtended';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import HorizontalResizeBox from '@/components/Atoms/Containers/HorizontalResizeBox';
import { getCompanies as _getCompanies, getContacts, addContact, getFunctions as _getFunctions, getSelectBoxOptions } from '@/api/admin/companies';
import { getCompanyCurrencies as _getCompanyCurrencies } from '@/api/risma/data';
import ContractPartyInternalPreview from '@/components/Organisms/Contracts/ContractPartyInternalPreview';
import ContractPartyExternalPreview from '@/components/Organisms/Contracts/ContractPartyExternalPreview';
import { CONTRACT_PARTY_TYPES, MODAL_TYPES } from '@/constants/contracts/contract';
import { getContractPartyMiniTreeInternalEmits, getContractPartyMiniTreeExternalEmits } from '@/utils/Compliance/TreeNodeEmits';
import { deCapitalize } from '@/utils/capitalize';
import { getFunctionIdentifier, getCompanyWithUrl } from '@/utils/format/companies';
import { extractInternalOptions, extractExternalOptions, SELECTBOX_OPTIONS_NAMES } from '@/components/Pages/Contracts/contractPartyUtils';
export default {
  name: 'ContractPartyExtended',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    AddContractPartyModalExtended: AddContractPartyModalExtended,
    MiniTree: MiniTree,
    FeatherIcon: FeatherIcon,
    HorizontalResizeBox: HorizontalResizeBox,
    ContractPartyInternalPreview: ContractPartyInternalPreview,
    ContractPartyExternalPreview: ContractPartyExternalPreview
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['update:immediately'],
  data: function data() {
    return {
      CONTRACT_PARTY_TYPES: CONTRACT_PARTY_TYPES,
      showExtendedModal: false,
      isLoaded: false,
      previewLoaded: false,
      companies: [],
      headerCssStyles: 'flex items-center justify-between border-b border-rm-grey pr-2',
      titleCssStyles: 'mr-2 my-2 ml-4 glob-h2 py-px',
      modalHeader: '',
      parentCompany: null,
      modalType: null,
      selectedCompany: null,
      selectedContractPartyType: null,
      selectedTreePath: [],
      selectBoxOptions: {},
      sectionToCopy: null,
      companyFunctionsMap: {},
      companyContactsMap: {},
      currencyOptions: [],
      treeStorageKey: 'contract_party',
      treeRefPrefix: 'miniTree',
      translate: getTranslate['ContractPartyExtended']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    externalTitle: function externalTitle() {
      return "".concat(this.translate.contractParty, " (").concat(this.translate.external, ")");
    },
    internalTitle: function internalTitle() {
      return "".concat(this.translate.contractParty, " (").concat(this.translate.internal, ")");
    },
    internalEmits: function internalEmits() {
      return getContractPartyMiniTreeInternalEmits();
    },
    externalEmits: function externalEmits() {
      return getContractPartyMiniTreeExternalEmits();
    },
    companyPartyAsPayingParty: function companyPartyAsPayingParty() {
      var company = this.data.contractParties.find(function (company) {
        return company.payingParty;
      });
      if (company) {
        var _this$getCompany;
        company.name = ((_this$getCompany = this.getCompany(company.companyId)) === null || _this$getCompany === void 0 ? void 0 : _this$getCompany.name) || '';
      }
      return company;
    },
    internalContractParties: function internalContractParties() {
      var _this$data$contractPa;
      return ((_this$data$contractPa = this.data.contractParties) === null || _this$data$contractPa === void 0 ? void 0 : _this$data$contractPa.filter(function (item) {
        return item.contractPartyType === CONTRACT_PARTY_TYPES.INTERNAL;
      })) || [];
    },
    externalContractParties: function externalContractParties() {
      var _this$data$contractPa2;
      return ((_this$data$contractPa2 = this.data.contractParties) === null || _this$data$contractPa2 === void 0 ? void 0 : _this$data$contractPa2.filter(function (item) {
        return item.contractPartyType === CONTRACT_PARTY_TYPES.EXTERNAL;
      })) || [];
    },
    serviceProviderOptions: function serviceProviderOptions() {
      var _this = this;
      return _toConsumableArray(new Map(this.externalContractParties.map(function (item) {
        return [item.companyId, item];
      })).values()).map(function (item) {
        var company = _this.getCompany(item.companyId);
        return {
          value: company.id,
          label: company.name
        };
      });
    },
    internalTreeData: function internalTreeData() {
      return this.getPartiesTree(this.internalContractParties);
    },
    externalTreeData: function externalTreeData() {
      return this.getPartiesTree(this.externalContractParties);
    },
    companyList: function companyList() {
      if (this.modalType === MODAL_TYPES.COPY) {
        return this.getChildCompaniesByTreePath(this.selectedTreePath, this.internalTreeData);
      }
      if (this.modalType === MODAL_TYPES.EXTERNAL) {
        return this.getAvailableExternalPartyCompanies();
      }
      var contractPartyIds = this.data.contractParties.map(function (_ref) {
        var companyId = _ref.companyId;
        return companyId;
      });
      return this.companies.filter(function (_ref2) {
        var id = _ref2.id;
        return !contractPartyIds.includes(id);
      });
    },
    isExternalPreview: function isExternalPreview() {
      return this.selectedContractPartyType === CONTRACT_PARTY_TYPES.EXTERNAL;
    },
    doraEnabled: function doraEnabled() {
      return !!this.data.doraEnabled;
    },
    internalSelectOptions: function internalSelectOptions() {
      if (!this.doraEnabled) return null;
      return extractInternalOptions(this.selectBoxOptions);
    },
    externalSelectOptions: function externalSelectOptions() {
      if (!this.doraEnabled) return null;
      return extractExternalOptions(this.selectBoxOptions);
    },
    showPreview: function showPreview() {
      if (this.doraEnabled) {
        return this.previewLoaded && this.selectedCompany;
      }
      return this.selectedCompany;
    }
  }),
  watch: {
    selectedContractPartyType: function selectedContractPartyType(_, oldValue) {
      if (!oldValue && !this.previewLoaded) {
        this.fetchDataForPreview();
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getCompanies().finally(function () {
      return _this2.isLoaded = true;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useCountriesStore, ['getCountries'])), {}, {
    handleContractPartiesCreation: function handleContractPartiesCreation(data) {
      var allParties = [].concat(_toConsumableArray(this.data.contractParties), _toConsumableArray(data));
      this.showExtendedModal = false;
      this.emitUpdate(allParties);
    },
    getPartiesTree: function getPartiesTree(list) {
      var _this3 = this;
      var treeData = {
        children: []
      };
      if (!this.companies.length) return treeData;
      var leftOver = []; // in case something was misaligned and couldn't be in the tree

      list.forEach(function (element) {
        _this3.setChild(element, treeData, leftOver, list);
      });
      if (leftOver.length) {
        var maximumIterations = leftOver.length;
        var count = 0;
        var _loop = function _loop() {
          var newLeftOver = [];
          leftOver.forEach(function (element) {
            return _this3.setChild(element, treeData, newLeftOver, list);
          });
          leftOver = newLeftOver;
          count++;
        };
        do {
          _loop();
        } while (leftOver.length && count < maximumIterations);
        if (leftOver.length) {
          throw new Error("Infinite loop on contract party, some data was corrupted - ".concat(leftOver.map(function (item) {
            return item.id;
          }).join(', ')));
        }
      }
      return treeData;
    },
    setChild: function setChild(element, treeData, leftOver, list) {
      var company = this.getCompany(element.companyId);
      if (!element.parentId) {
        treeData.children.push(this.getRightCompanyData(company, element));
      } else {
        var parentCompany = list.find(function (item) {
          return item.id === element.parentId;
        }) || {};
        var path = this.getPathById(parentCompany.id, treeData.children);
        if (!path.length) {
          leftOver.push(element);
        } else {
          var parent = this.getParentByPath(treeData.children, path);
          parent.children.push(this.getRightCompanyData(company, element));
        }
      }
    },
    getRightCompanyData: function getRightCompanyData(company, externalFields) {
      return {
        name: company.name,
        id: externalFields.id,
        externalFields: externalFields,
        contractPartyId: externalFields.id,
        payingParty: externalFields.payingParty,
        signingParty: externalFields.signingParty,
        usingParty: externalFields.usingParty,
        children: [],
        company: company
      };
    },
    getPathById: function getPathById(id, items) {
      var _this4 = this;
      var path = [];
      items.forEach(function (item, i) {
        if (item.id === id) {
          path.push(i);
        } else if (item.children) {
          var childrenPath = _this4.getPathById(id, item.children);
          if (childrenPath.length) {
            path = [].concat(_toConsumableArray(path), [i], _toConsumableArray(childrenPath));
          }
        }
      });
      return path;
    },
    getParentByPath: function getParentByPath(list, path) {
      var data = {};
      path.forEach(function (index) {
        data = list[index];
        list = list[index].children || [];
      });
      return data;
    },
    getCompany: function getCompany(id) {
      return this.companies.find(function (company) {
        return company.id === id;
      });
    },
    getCompanies: function getCompanies() {
      var _this5 = this;
      return _getCompanies().then(function (_ref3) {
        var list = _ref3.list;
        return _this5.companies = list;
      });
    },
    getFunctions: function getFunctions(id) {
      var _this6 = this;
      return _getFunctions(id).then(function (_ref4) {
        var list = _ref4.list;
        return _this6.companyFunctionsMap[id] = _this6.prepareCompanyFunctionOptions(list);
      });
    },
    prepareCompanyFunctionOptions: function prepareCompanyFunctionOptions(companyFunctions) {
      return companyFunctions.flatMap(function (_ref5) {
        var licensedActivities = _ref5.licensedActivities,
          name = _ref5.name,
          id = _ref5.id;
        return licensedActivities.map(function (item) {
          return {
            value: id,
            label: "".concat(name, " (").concat(getFunctionIdentifier(item === null || item === void 0 ? void 0 : item.functionIdentifier), ")")
          };
        });
      });
    },
    handleCreate: function handleCreate(type, treeNode) {
      this.modalType = type;
      this.showExtendedModal = true;
      this.parentCompany = this.createParentCompany(treeNode);
      if (!treeNode) {
        this.selectedTreePath = [];
      }
    },
    createParentCompany: function createParentCompany(treeNode) {
      if (!treeNode) return null;
      var company = treeNode.company,
        contractPartyId = treeNode.contractPartyId;
      var companyData = this.getCompany(company.id);
      return {
        id: company.id,
        name: companyData.name,
        contractPartyId: contractPartyId
      };
    },
    handleUpdatePayingParty: function handleUpdatePayingParty(treeNode) {
      var _this7 = this;
      if (!treeNode) return null;
      var _treeNode$company = treeNode.company,
        id = _treeNode$company.id,
        name = _treeNode$company.name;
      if (this.companyPartyAsPayingParty && this.companyPartyAsPayingParty.companyId !== id && !treeNode.payingParty) {
        return this.$confirm(this.translate.setCompanyAsNewPayingPart(name), this.translate.removeCurrentPayingPart(this.companyPartyAsPayingParty.name), function (res) {
          return res && _this7.changePayingParty(id);
        });
      }
      var index = this.data.contractParties.findIndex(function (element) {
        return element.companyId === id;
      });
      var data = cloneDeep(this.data.contractParties);
      data[index].payingParty = !data[index].payingParty;
      this.emitUpdate(data);
    },
    changePayingParty: function changePayingParty(id) {
      var contractParties = cloneDeep(this.data.contractParties);
      contractParties.forEach(function (item) {
        if (item.companyId === id) {
          item.payingParty = !item.payingParty;
        } else if (item.payingParty) {
          item.payingParty = false;
        }
      });
      this.emitUpdate(contractParties);
    },
    handleRemoveParty: function handleRemoveParty(treeNode) {
      var data = cloneDeep(this.data.contractParties);
      this.removeAllChildren(treeNode, data);
      this.emitUpdate(data);
      this.selectedCompany = null;
    },
    removeAllChildren: function removeAllChildren(treeNode, list) {
      var _treeNode$children,
        _this8 = this;
      var index = list.findIndex(function (element) {
        return element.id === treeNode.contractPartyId;
      });
      list.splice(index, 1);
      if (treeNode !== null && treeNode !== void 0 && (_treeNode$children = treeNode.children) !== null && _treeNode$children !== void 0 && _treeNode$children.length) {
        treeNode.children.forEach(function (child) {
          _this8.removeAllChildren(child, list);
        });
      }
    },
    emitUpdate: function emitUpdate(data) {
      this.$emit('update:immediately', {
        property: 'contractParties',
        value: data
      });
    },
    selectCompany: function selectCompany(path, type) {
      if (!path.length) return;
      var _ref6 = this.findTreeNodeByPath(path, type) || {},
        company = _ref6.company,
        contractPartyId = _ref6.id;
      this.resetSelectedPath(type, !!company);
      if (contractPartyId) {
        this.selectedCompany = this.preparePreviewDataByType(type, company, contractPartyId);
        this.selectedContractPartyType = type;
        this.selectedTreePath = path;
        this.makeAdditionalRequests(company.id, type);
      }
    },
    makeAdditionalRequests: function makeAdditionalRequests(id, type) {
      if (type === this.CONTRACT_PARTY_TYPES.INTERNAL && !this.companyFunctionsMap[id]) {
        this.getFunctions(id);
      }
      if (type === this.CONTRACT_PARTY_TYPES.EXTERNAL && !this.companyContactsMap[id]) {
        this.getCompanyContracts(id);
      }
    },
    getAllSelectboxOptions: function getAllSelectboxOptions(optionNames) {
      var _this9 = this;
      var requests = optionNames.map(function (name) {
        return _this9.getSelectboxOptions(name);
      });
      return Promise.all(requests);
    },
    getSelectboxOptions: function getSelectboxOptions(name) {
      var _this10 = this;
      return getSelectBoxOptions(name).then(function (_ref7) {
        var list = _ref7.list;
        _this10.selectBoxOptions[deCapitalize(name)] = list;
      });
    },
    fetchDataForPreview: function fetchDataForPreview() {
      var _this11 = this;
      return Promise.all([this.getAllSelectboxOptions(SELECTBOX_OPTIONS_NAMES), this.getCountries(), this.getCompanyCurrencies()]).finally(function () {
        _this11.previewLoaded = true;
      });
    },
    getCompanyCurrencies: function getCompanyCurrencies() {
      var _this12 = this;
      return _getCompanyCurrencies().then(function (_ref8) {
        var list = _ref8.list;
        _this12.currencyOptions = list.map(function (_ref9) {
          var currencyCode = _ref9.currencyCode,
            currencyName = _ref9.currencyName;
          return {
            value: currencyCode,
            label: currencyName
          };
        });
      });
    },
    preparePreviewDataByType: function preparePreviewDataByType(type, company, contractPartyId) {
      if (type === this.CONTRACT_PARTY_TYPES.EXTERNAL) {
        return this.buildExternalPreviewData(company, contractPartyId);
      }
      return this.buildInternalPreviewData(company, contractPartyId);
    },
    buildExternalPreviewData: function buildExternalPreviewData(company, contractPartyId) {
      var contractParty = this.externalContractParties.find(function (contractParty) {
        return contractParty.id === contractPartyId;
      });
      return {
        contractParty: contractParty,
        company: company
      };
    },
    buildInternalPreviewData: function buildInternalPreviewData(company, contractPartyId) {
      var contractParty = this.internalContractParties.find(function (contractParty) {
        return contractParty.id === contractPartyId;
      });
      if (company.parentId) {
        var parentCompany = this.getCompany(company.parentId);
        company.parentName = parentCompany.name;
      }
      var companyWithUrl = getCompanyWithUrl(company);
      company.title = company.name;
      company.url = "/company/".concat(company.id);
      return {
        company: companyWithUrl,
        contractParty: contractParty
      };
    },
    resetMapData: function resetMapData(id, type) {
      if (type === this.CONTRACT_PARTY_TYPES.EXTERNAL && this.companyContactsMap[id]) {
        delete this.companyContactsMap[id];
      }
    },
    getCompanyContracts: function getCompanyContracts(id) {
      var _this13 = this;
      return getContacts(id).then(function (_ref10) {
        var list = _ref10.list;
        _this13.companyContactsMap[id] = list;
      });
    },
    handleCreateContact: function handleCreateContact(companyId, contactData) {
      var _this14 = this;
      return addContact(companyId, contactData).then(function (response) {
        _this14.emitUpdateLinks([].concat(_toConsumableArray(_this14.data.links.company_contact || []), [response.id]));
        return _this14.getCompanyContracts(companyId);
      }).catch(function (error) {
        _this14.$notify({
          title: error,
          type: 'error'
        });
        throw error;
      });
    },
    emitUpdateLinks: function emitUpdateLinks(ids) {
      this.$emit('update:immediately', {
        property: 'links',
        value: _objectSpread(_objectSpread({}, this.data.links), {}, {
          company_contact: ids
        })
      });
    },
    findTreeNodeByPath: function findTreeNodeByPath(path, type) {
      var _contractParty;
      var contractParties = type === CONTRACT_PARTY_TYPES.INTERNAL ? this.internalTreeData : this.externalTreeData;
      var contractParty = contractParties.children;
      if (!((_contractParty = contractParty) !== null && _contractParty !== void 0 && _contractParty.length)) return;
      path.forEach(function (item, index) {
        if (index === path.length - 1) {
          contractParty = contractParty[item.index];
        } else {
          contractParty = contractParty[item.index].children;
        }
      });
      return contractParty;
    },
    resetSelectedPath: function resetSelectedPath(type, skip) {
      var _this15 = this;
      Object.values(CONTRACT_PARTY_TYPES).forEach(function (item) {
        var _this15$$refs;
        if (skip && item === type) return;
        (_this15$$refs = _this15.$refs["".concat(_this15.treeRefPrefix).concat(item)]) === null || _this15$$refs === void 0 || _this15$$refs.resetSelectedPath();
      });
    },
    handleUpdateSigningParty: function handleUpdateSigningParty(data) {
      this.updateContractPartyProperty(data.company.id, 'signingParty');
    },
    handleUpdateUsingParty: function handleUpdateUsingParty(data) {
      this.updateContractPartyProperty(data.company.id, 'usingParty');
    },
    updateContractPartyProperty: function updateContractPartyProperty(id, propertyName) {
      var index = this.data.contractParties.findIndex(function (element) {
        return element.companyId === id;
      });
      var data = cloneDeep(this.data.contractParties);
      data[index][propertyName] = !data[index][propertyName];
      this.emitUpdate(data);
    },
    handleContractPartyUpdate: function handleContractPartyUpdate(contractParty) {
      var index = this.data.contractParties.findIndex(function (element) {
        return element.id === contractParty.id;
      });
      var data = cloneDeep(this.data.contractParties);
      data[index] = contractParty;
      this.emitUpdate(data);
    },
    copySection: function copySection(section) {
      this.showExtendedModal = true;
      this.sectionToCopy = section;
      this.modalType = MODAL_TYPES.COPY;
      var treeNode = this.findTreeNodeByPath(this.selectedTreePath, this.CONTRACT_PARTY_TYPES.INTERNAL);
      this.parentCompany = this.createParentCompany(treeNode);
    },
    copySectionToSubcompanies: function copySectionToSubcompanies(companies) {
      var _this16 = this;
      var companyIds = companies.map(function (company) {
        return company.companyId;
      });
      var section = cloneDeep(this.sectionToCopy);
      section.functionIds = [];
      delete section.id;
      companyIds.forEach(function (id) {
        var contractParty = _this16.data.contractParties.find(function (item) {
          return item.companyId === id;
        });
        contractParty && contractParty.internalSections.push(section);
      });
      this.showExtendedModal = false;
      this.emitUpdate(this.data.contractParties);
    },
    getChildCompaniesByTreePath: function getChildCompaniesByTreePath(treePath, tree) {
      var _contractParty2;
      var contractParty = tree === null || tree === void 0 ? void 0 : tree.children;
      if (!((_contractParty2 = contractParty) !== null && _contractParty2 !== void 0 && _contractParty2.length)) return [];
      treePath.forEach(function (item) {
        return contractParty = contractParty[item.index].children;
      });
      return contractParty.map(function (_ref11) {
        var company = _ref11.company;
        return company;
      });
    },
    getCompaniesAlongPath: function getCompaniesAlongPath(treePath, tree) {
      var treeNodes = (tree === null || tree === void 0 ? void 0 : tree.children) || [];
      if (!treeNodes.length) return [];
      var companies = [];
      treePath.forEach(function (item) {
        companies.push(treeNodes[item.index].company);
        treeNodes = treeNodes[item.index].children;
      });
      return companies;
    },
    handleUpdateFromPreview: function handleUpdateFromPreview(company) {
      var companyIndex = this.companies.findIndex(function (item) {
        return item.id === company.id;
      });
      this.companies[companyIndex] = _objectSpread(_objectSpread({}, this.companies[companyIndex]), company);
      this.selectedCompany = this.preparePreviewDataByType(this.selectedContractPartyType, company, this.selectedCompany.contractParty.id);
      this.refreshMapData(company.id);
    },
    refreshMapData: function refreshMapData(companyId) {
      this.resetMapData(companyId, this.selectedContractPartyType);
      this.makeAdditionalRequests(companyId, this.selectedContractPartyType);
    },
    getAvailableExternalPartyCompanies: function getAvailableExternalPartyCompanies() {
      var _this$selectedTreePat;
      var unavailableCompanies = this.internalContractParties.map(function (_ref12) {
        var companyId = _ref12.companyId;
        return companyId;
      });
      if ((_this$selectedTreePat = this.selectedTreePath) !== null && _this$selectedTreePat !== void 0 && _this$selectedTreePat.length) {
        var companyIds = this.getCompaniesAlongPath(this.selectedTreePath, this.externalTreeData).concat(this.getChildCompaniesByTreePath(this.selectedTreePath, this.externalTreeData)).map(function (_ref13) {
          var id = _ref13.id;
          return id;
        });
        unavailableCompanies.push.apply(unavailableCompanies, _toConsumableArray(companyIds));
      } else {
        var _Object$values;
        var childCompanyIds = (_Object$values = Object.values(this.externalTreeData.children)) === null || _Object$values === void 0 ? void 0 : _Object$values.map(function (item) {
          return item.company.id;
        });
        unavailableCompanies.push.apply(unavailableCompanies, _toConsumableArray(childCompanyIds));
      }
      return this.companies.filter(function (_ref14) {
        var id = _ref14.id;
        return !unavailableCompanies.includes(id);
      });
    }
  })
};