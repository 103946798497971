import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_selector_tags = _resolveComponent("selector-tags");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_selector_tags, {
    module: $data.module,
    tags: $data.tags,
    "selected-tag": $data.selectedTag,
    class: "mb-4 w-500px",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedTag = $event;
    }),
    onDelete: _cache[1] || (_cache[1] = function ($event) {
      return $data.tags = $data.tags.filter(function (item) {
        return item.id !== $event;
      });
    })
  }, null, 8 /* PROPS */, ["module", "tags", "selected-tag"]), _cache[8] || (_cache[8] = _createTextVNode(" With traffic lights ")), _createVNode(_component_selector_tags, {
    module: $data.module,
    tags: $data.tags,
    "has-traffic-lights": true,
    "selected-tag": $data.selectedTag,
    class: "mb-4 w-500px",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.selectedTag = $event;
    }),
    onDelete: _cache[3] || (_cache[3] = function ($event) {
      return $data.tags = $data.tags.filter(function (item) {
        return item.id !== $event;
      });
    })
  }, null, 8 /* PROPS */, ["module", "tags", "selected-tag"]), _cache[9] || (_cache[9] = _createTextVNode(" Without delete ")), _createVNode(_component_selector_tags, {
    module: $data.module,
    tags: $data.tags,
    "has-delete-options": false,
    "selected-tag": $data.selectedTag,
    class: "mb-4 w-500px",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $data.selectedTag = $event;
    }),
    onDelete: _cache[5] || (_cache[5] = function ($event) {
      return $data.tags = $data.tags.filter(function (item) {
        return item.id !== $event;
      });
    })
  }, null, 8 /* PROPS */, ["module", "tags", "selected-tag"]), _cache[10] || (_cache[10] = _createTextVNode(" With title ")), _createVNode(_component_selector_tags, {
    module: $data.module,
    tags: $data.tags,
    title: "Custom title",
    "selected-tag": $data.selectedTag,
    class: "mb-4 mt-4 w-500px",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $data.selectedTag = $event;
    }),
    onDelete: _cache[7] || (_cache[7] = function ($event) {
      return $data.tags = $data.tags.filter(function (item) {
        return item.id !== $event;
      });
    })
  }, null, 8 /* PROPS */, ["module", "tags", "selected-tag"])], 64 /* STABLE_FRAGMENT */);
}