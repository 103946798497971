import { useFrameworksStore } from '@/Store/frameworksStore';
import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
import cloneDeep from 'lodash/cloneDeep';
export function getFrameworks() {
  return handleRequestResponse(Http.get('admin/solutions')).then(function (data) {
    useFrameworksStore().set(cloneDeep(data.list));
    return data;
  });
}
export function createFramework(newFramework) {
  return handleRequestResponse(Http.post('admin/solutions', newFramework)).then(function (data) {
    useFrameworksStore().addFramework(data);
    return data;
  });
}
export function updateFramework(framework) {
  return handleRequestResponse(Http.patch("admin/solutions/".concat(framework.id), framework)).then(function (data) {
    useFrameworksStore().updateFramework(data);
    return data;
  });
}
export function deleteFramework(frameworkId) {
  return handleRequestResponse(Http.delete("admin/solutions/".concat(frameworkId))).then(function () {
    useFrameworksStore().deleteFramework(frameworkId);
  });
}
export function runAutoDetectFrameworkActivities(frameworkName) {
  return handleRequestResponse(Http.post('rismaAdmin/autodetectsolutionactivities', {
    solutionName: frameworkName
  }));
}
export function runCreateDashboardAndKpisService(frameworkName) {
  return handleRequestResponse(Http.post('rismaAdmin/createdashboardandkpis', {
    solutionName: frameworkName
  }));
}