// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-item-enter-active[data-v-2585e498],
.accordion-item-leave-active[data-v-2585e498] {
  transition: height 0.3s ease;
}
.accordion-item-enter-from[data-v-2585e498],
.accordion-item-leave-to[data-v-2585e498] {
  height: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
