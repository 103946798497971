import AwarenessDetails from '@/components/Pages/Awareness/AwarenessDetails';
import AwarenessDashboard from '@/components/Pages/Awareness/AwarenessDashboard';
import AwarenessEntry from '@/components/Pages/Awareness/AwarenessEntry';
import AwarenessQuizOverview from '@/components/Pages/Awareness/AwarenessQuizOverview';
import { userHasPoliciesAccess } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
export default function getAwarenessRoutes() {
  return [{
    path: '/awareness/:activityType/:token',
    component: AwarenessDetails,
    props: true,
    meta: {
      skipLoginCheck: true
    }
  }, {
    path: '/awareness/dashboard',
    component: AwarenessEntry,
    props: true,
    meta: {
      keyFunction: function keyFunction() {
        return '/awareness/dashboard';
      },
      userRightsFunction: userHasPoliciesAccess(UserLevels.NORMAL)
    },
    redirect: {
      name: 'AwarenessDashboard'
    },
    children: [{
      path: '',
      component: AwarenessDashboard
    }, {
      path: 'overview/:id?',
      name: 'AwarenessDashboardd',
      props: function props(route) {
        return {
          id: route.params.id
        };
      },
      component: AwarenessDashboard
    }, {
      path: 'quiz',
      component: AwarenessQuizOverview
    }]
  }];
}