import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = ["src"];
var _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_p_d_f_document = _resolveComponent("p-d-f-document");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.previewFile,
    "show-buttons": false,
    "modal-fixed-width": $options.isPDF ? $props.pdfModalWidth : '',
    "modal-fixed-height": $options.isPDF ? $props.pdfModalHeight : '',
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [$options.isPDF ? (_openBlock(), _createBlock(_component_p_d_f_document, {
        key: 0,
        "src-pdf": $props.fileSrc
      }, null, 8 /* PROPS */, ["src-pdf"])) : _createCommentVNode("v-if", true), $options.isImage ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: $props.fileSrc,
        alt: "preview"
      }, null, 8 /* PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true), $props.downloadable ? (_openBlock(), _createElementBlock("a", {
        key: 2,
        class: "float-right",
        href: $props.fileSrc,
        download: ""
      }, [_createVNode(_component_feather_icon, {
        class: "m-1 float-right",
        color: $data.colors.system.lightGray2,
        icon: "download"
      }, null, 8 /* PROPS */, ["color"])], 8 /* PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "modal-fixed-width", "modal-fixed-height"]);
}