function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import cloneDeep from 'lodash/cloneDeep';
import { createQuestion, editQuestion } from '@/api/compliance/questions';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import IsoStandards from '@/constants/compliance/IsoStandards';
import DataFlowTypes from '@/constants/compliance/DataFlowTypes';
import OutsourcingTypes from '@/constants/compliance/OutsourcingTypes';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import OptionsList from '@/components/Molecules/OptionsList/OptionsList';
import HelpToolTip from '@/components/Molecules/HelpToolTip';
import QuestionDependencyEditor from '@/components/Molecules/Compliance/QuestionDependency/QuestionDependencyEditor';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import DPMTypes from '@/constants/compliance/DataProcessorManagement';
import { ProjectTypes, ForbiddenToUseIAForTypes } from '@/constants/compliance/ProjectTypes';
import { removeDuplicates } from '@/utils/filterArray';
import { comparePathStrings } from '@/utils/compare';
var QUESTION_NUMBER_VALID_PATTERNS = [/^[\da-z.]{1,15}$/i, /(?=.*?[^a-z])(?=.*?[^.])/i];
export default {
  name: 'QuestionCreation',
  introduction: 'Component which create a question',
  description: 'Component with form of question creation in modal box.',
  token: "\n    <question-creation\n      :questionnaire-id=\"1\"\n      :questionnaire-type=\"1\"\n      :questions=\"[]\" />",
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    RismaSwitch: RismaSwitch,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    InputField: InputField,
    OptionsList: OptionsList,
    HelpToolTip: HelpToolTip,
    QuestionDependencyEditor: QuestionDependencyEditor,
    FrameworksSelector: FrameworksSelector,
    TextBox: TextBox
  },
  mixins: [IATitleMixin],
  props: {
    inputData: {
      type: Object,
      required: false,
      default: null
    },
    questionnaireId: {
      type: Number,
      required: true
    },
    questionnaireType: {
      type: Number,
      required: true
    },
    questions: {
      type: [Object, Array],
      required: true
    },
    customAssetsOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'options for custom inf assets'
    },
    isCanceled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['created', 'canceled', 'dismiss'],
  data: function data() {
    var defaults = {
      id: null,
      questionnumber: null,
      headline: '',
      question: '',
      questiontype: 1,
      help: '',
      options: [],
      optionDetails: [],
      allowNewAnswerOption: 0,
      isArticle30: false,
      dataFlowStakeholder: false,
      dataFlowDataType: false,
      dataFlowSystem: false,
      user_created: 1,
      answerOptionInput: '',
      dependencies: {},
      outsourcingDueDilligence: false,
      outsourcingRiskAssessment: false,
      outsourcingFunctionDescription: false,
      outsourcingPreliminaryAnalysis: false,
      legalBasisQuestionId: null,
      retentionQuestionId: null,
      solutionIds: [],
      customValueList: null
    };
    return {
      defaults: defaults,
      showEmptyFieldsError: false,
      headlineSearch: '',
      saveButtonClickCounter: 0,
      data: _objectSpread({}, defaults),
      dataflowTypes: DataFlowTypes(this.$trans),
      outsourcingTypes: OutsourcingTypes(this.$trans),
      DPMTypes: DPMTypes(this.$trans),
      errors: [],
      initialData: null,
      selectedAsset: null,
      allQuestionTypes: QuestionTypes(this.$trans),
      isSaving: false,
      translate: getTranslate['QuestionCreation'](),
      callStackState: true,
      isRismaSelectOpen: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled', 'isSystemsEnabled', 'isDataControllersEnabled', 'isDataProcessorsEnabled', 'isIACustomEnabled'])), {}, {
    customListsOptions: function customListsOptions() {
      return [{
        label: this.translate.countryCodes,
        value: IsoStandards.iso3166
      }, {
        label: this.translate.currencyCodes,
        value: IsoStandards.iso4217
      }];
    },
    hasOverallFramework: function hasOverallFramework() {
      return this.getSettingValue('feature.overall.extended_framework');
    },
    isCustomListEnabled: function isCustomListEnabled() {
      return this.getSettingValue('feature.overall.questionnaire_custom_list_enabled');
    },
    selectedFrameworks: function selectedFrameworks() {
      var _this = this;
      return this.frameworks.filter(function (framework) {
        return _this.data.solutionIds.includes(framework.id);
      });
    },
    dependencyEditorIsEnabled: function dependencyEditorIsEnabled() {
      return this.getSettingValue('feature.dependency_editor') === 1;
    },
    headlines: function headlines() {
      var _this2 = this;
      var list = cloneDeep(this.questions);
      if (this.data.headline && !this.questions.find(function (question) {
        return question.headline === _this2.data.headline;
      })) list.push({
        headline: this.data.headline
      });
      return removeDuplicates(list, 'headline').map(function (item) {
        return {
          label: item.headline,
          value: item.headline
        };
      });
    },
    assetsOptions: function assetsOptions() {
      return this.customAssetsOptions.map(function (item) {
        return {
          value: item.id,
          label: item.label
        };
      });
    },
    selectedDataFlowTypes: function selectedDataFlowTypes() {
      var _this3 = this;
      return this.dataflowTypesFiltered.filter(function (v) {
        return _this3.data[v.key];
      });
    },
    selectedOutsourcingTypes: function selectedOutsourcingTypes() {
      var _this4 = this;
      return this.outsourcingTypes.filter(function (v) {
        return _this4.data[v.key];
      });
    },
    selectedDPMTypes: function selectedDPMTypes() {
      var _this5 = this;
      return this.DPMTypes.filter(function (v) {
        return _this5.data[v.key];
      });
    },
    isGDPR: function isGDPR() {
      return this.questionnaireType === ProjectTypes.GDPR;
    },
    isOutsourcing: function isOutsourcing() {
      return this.questionnaireType === ProjectTypes.OUTSOURCING;
    },
    isDataProcessor: function isDataProcessor() {
      return this.questionnaireType === ProjectTypes.DATAPROCESSOR;
    },
    isDPM: function isDPM() {
      return this.questionnaireType === ProjectTypes.DPM;
    },
    isIATypeSelected: function isIATypeSelected() {
      var _this6 = this;
      var questionTypes = [this.allQuestionTypes.informationAsset, this.allQuestionTypes.informationAssetSystems, this.allQuestionTypes.informationAssetProcessors, this.allQuestionTypes.informationAssetControllers];
      return questionTypes.some(function (v) {
        return v.id === _this6.data.questiontype;
      });
    },
    isDataflowAvailable: function isDataflowAvailable() {
      if (!this.isGDPR) return false;
      var isMultipleChoiceSelected = this.data.questiontype === this.allQuestionTypes.multipleChoice.id;
      return isMultipleChoiceSelected || !this.isInformationAssetsDisabled && this.isIATypeSelected;
    },
    dataflowTypesFiltered: function dataflowTypesFiltered() {
      if (!this.isIATypeSelected) return this.dataflowTypes;
      if (this.data.questiontype === this.allQuestionTypes.informationAssetSystems.id) {
        return this.dataflowTypes.filter(function (type) {
          return type.key === 'dataFlowSystem';
        });
      }
      var availableOptionsForIAQuestions = ['dataFlowStakeholder', 'dataFlowSystem'];
      return this.dataflowTypes.filter(function (type) {
        return availableOptionsForIAQuestions.includes(type.key);
      });
    },
    isArticle30: function isArticle30() {
      return !!this.data.isArticle30;
    },
    allowNewAnswerOption: function allowNewAnswerOption() {
      return !!this.data.allowNewAnswerOption;
    },
    iaQuestionTypesAvailable: function iaQuestionTypesAvailable() {
      var iaQuestionTypes = [];
      if (this.isSystemsEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.informationAssetSystems);
      }
      if (this.isDataControllersEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.informationAssetControllers);
      }
      if (this.isDataProcessorsEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.informationAssetProcessors);
      }
      if (this.isIACustomEnabled) {
        iaQuestionTypes.push(this.allQuestionTypes.customInformationAsset);
      }
      return iaQuestionTypes;
    },
    questionTypes: function questionTypes() {
      var questionTypes = [this.allQuestionTypes.singleChoice, this.allQuestionTypes.multipleChoice, this.allQuestionTypes.freeText, this.allQuestionTypes.attachment, this.allQuestionTypes.attachmentAndFreeText, this.allQuestionTypes.date, this.allQuestionTypes.dateTime];
      if (this.isCustomListEnabled) {
        questionTypes.push(this.allQuestionTypes.customLists);
      }
      if (this.isIAAllowedInQuestionnaire) {
        var _questionTypes;
        (_questionTypes = questionTypes).push.apply(_questionTypes, _toConsumableArray(this.iaQuestionTypesAvailable));
        questionTypes.push(this.allQuestionTypes.processLibrary);
        questionTypes.push(this.allQuestionTypes.informationAsset);
      }
      questionTypes = questionTypes.map(function (type) {
        return {
          label: type.option,
          value: type.id
        };
      });
      return questionTypes;
    },
    formData: function formData() {
      var options = [];
      if (this.data.optionDetails) {
        options = this.data.optionDetails.map(function (option) {
          return option.label;
        });
      }
      var data = {
        id: this.data.id,
        headline: this.data.headline,
        questionnumber: this.data.questionnumber,
        question: this.data.question,
        questiontype: this.data.questiontype,
        help: this.data.help,
        options: options,
        questionnaire_id: this.questionnaireId,
        user_created: this.data.user_created,
        isArticle30: this.data.isArticle30,
        allowNewAnswerOption: this.data.allowNewAnswerOption,
        dependencies: this.data.dependencies,
        informationAssetActivityType: this.data.informationAssetActivityType,
        solutionIds: this.data.solutionIds,
        customValueList: this.data.customValueList
      };
      if (this.isDataflowAvailable) {
        data = _objectSpread(_objectSpread({}, data), {}, {
          dataFlowStakeholder: this.data.dataFlowStakeholder,
          dataFlowDataType: this.data.dataFlowDataType,
          dataFlowSystem: this.data.dataFlowSystem,
          legalBasisQuestionId: this.data.legalBasisQuestionId,
          retentionQuestionId: this.data.retentionQuestionId
        });
      } else if (this.isOutsourcing) {
        data = _objectSpread(_objectSpread({}, data), {}, {
          outsourcingDueDilligence: this.data.outsourcingDueDilligence,
          outsourcingRiskAssessment: this.data.outsourcingRiskAssessment,
          outsourcingFunctionDescription: this.data.outsourcingFunctionDescription,
          outsourcingPreliminaryAnalysis: this.data.outsourcingPreliminaryAnalysis
        });
      }
      if (this.isDataProcessor || this.isDPM) {
        data = _objectSpread(_objectSpread({}, data), {}, {
          dpmAuditReport: this.data.dpmAuditReport,
          dpmRiskAssessment: this.data.dpmRiskAssessment
        });
      }
      return data;
    },
    isDirty: function isDirty() {
      return JSON.stringify(this.formData) !== JSON.stringify(this.initialData);
    },
    answerOptions: function answerOptions() {
      return [{
        value: true,
        name: this.translate.yes
      }, {
        value: false,
        name: this.translate.no
      }];
    },
    defaultSelectedOptions: function defaultSelectedOptions() {
      return [{
        value: true,
        name: this.translate.yes
      }, {
        value: false,
        name: this.translate.no
      }];
    },
    isIAAllowedInQuestionnaire: function isIAAllowedInQuestionnaire() {
      return this.questionnaireType && !ForbiddenToUseIAForTypes.includes(this.questionnaireType);
    },
    selectedDataFlowTypesKeys: function selectedDataFlowTypesKeys() {
      return this.selectedDataFlowTypes.map(function (_ref) {
        var key = _ref.key;
        return key;
      });
    },
    isDataFlowDataTypeSelected: function isDataFlowDataTypeSelected() {
      return this.selectedDataFlowTypesKeys.includes('dataFlowDataType');
    },
    questionOptions: function questionOptions() {
      var _this7 = this;
      var allowedQuestionTypes = [this.allQuestionTypes.singleChoice.id, this.allQuestionTypes.multipleChoice.id];
      return this.questions.filter(function (_ref2) {
        var id = _ref2.id,
          dataFlowDataType = _ref2.dataFlowDataType,
          questiontype = _ref2.questiontype,
          hidden = _ref2.hidden;
        return id !== _this7.inputData.id && !dataFlowDataType && allowedQuestionTypes.includes(questiontype) && !hidden;
      }).map(function (_ref3) {
        var questionnumber = _ref3.questionnumber,
          id = _ref3.id,
          question = _ref3.question;
        return {
          label: "".concat(questionnumber, " ").concat(question),
          value: id
        };
      });
    },
    isDataflowWizardEnabled: function isDataflowWizardEnabled() {
      return this.getSettingValue('feature.dataflow_wizard');
    },
    reportName: function reportName() {
      return this.isGDPR ? this.translate.article30Report : this.translate.questionnaireReport;
    },
    reportTooltip: function reportTooltip() {
      return this.isGDPR ? this.translate.byActivatingThisFunctionalityArticle30 : this.translate.byActivatingThisFunctionalityQuestionnaireReport;
    }
  }),
  watch: {
    isCanceled: function isCanceled(value) {
      if (value) {
        this.cancel();
      }
    }
  },
  beforeMount: function beforeMount() {
    this.data = _objectSpread(_objectSpread({}, this.data), cloneDeep(this.inputData));
    this.initialData = cloneDeep(this.formData);
  },
  methods: {
    updateHeadlineFromList: function updateHeadlineFromList(value) {
      this.data.headline = value;
      this.headlineSearch = '';
    },
    updateHeadlineWithSearchValue: function updateHeadlineWithSearchValue(value) {
      if (!value && this.headlineSearch) {
        this.data.headline = this.headlineSearch;
      }
      this.headlineSearch = '';
    },
    handleDataFlowChanged: function handleDataFlowChanged(state) {
      var _this8 = this;
      this.dataflowTypes.forEach(function (v) {
        _this8.data[v.key] = !!state && !!state.find(function (s) {
          return s.key === v.key;
        });
      });
      if (!this.data.dataFlowDataType) {
        this.data.legalBasisQuestionId = null;
        this.data.retentionQuestionId = null;
      }
    },
    handleQuestionTypeChange: function handleQuestionTypeChange(questiontype) {
      this.data = _objectSpread(_objectSpread({}, this.data), {}, {
        questiontype: questiontype,
        dataFlowStakeholder: false,
        dataFlowDataType: false,
        dataFlowSystem: false,
        legalBasisQuestionId: null,
        retentionQuestionId: null
      });
    },
    handleOutsourcingChanged: function handleOutsourcingChanged(state) {
      var _this9 = this;
      this.outsourcingTypes.forEach(function (v) {
        _this9.data[v.key] = !!state && !!state.find(function (s) {
          return s.key === v.key;
        });
      });
    },
    handleDPMFlagsChanged: function handleDPMFlagsChanged(state) {
      var _this10 = this;
      this.DPMTypes.forEach(function (v) {
        _this10.data[v.key] = !!state && !!state.find(function (s) {
          return s.key === v.key;
        });
      });
    },
    handleOnSubmit: function handleOnSubmit() {
      this.errors = [];
      this.saveButtonClickCounter++;
      if (this.handleValidation(this.formData)) {
        this.handleApiRequest(this.formData);
      }
    },
    handleValidation: function handleValidation(formData) {
      var _this11 = this;
      if (formData.headline.length < 1) {
        this.handlePushToErrors(this.translate.questionMustHaveAHeadline);
      }
      if (!formData.questionnumber || formData.questionnumber.length < 1) {
        this.handlePushToErrors(this.translate.questionMustHaveAQuestionNumber);
      }

      //questionnumber shouldn't point on itself, callstack exceed error
      if (formData.dependencies && this.isCallStackExceed(formData, this.questions)) {
        //sorting is the same like on questionDependencyEditor.vue
        var sortedDep = Object.keys(formData.dependencies).sort(function (a, b) {
          return comparePathStrings(a, b);
        });
        var index = sortedDep.findIndex(function (item) {
          return item === formData.questionnumber;
        });
        var prefix = index === -1 ? '' : ": row ".concat(index + 1);
        this.handlePushToErrors(this.translate.theQuestionIsInDependencyLoopWhichCanCauseErrors + prefix);
      }

      // Questionnumber should be in appropriate format
      if (formData.questionnumber && formData.questionnumber.length) {
        if (!this.questionNumberValid(formData.questionnumber)) {
          this.handlePushToErrors(this.translate.invalidQuestionNumberOnlyDigitsDotsAndLet);
        }
      }

      // The question should be longer then 1 char
      if (formData.question.length < 1) {
        this.handlePushToErrors(this.translate.questionMustHaveAText);
      }

      // If question type is 1 or 2 there should be at lease two answer options
      if (formData.questiontype === this.allQuestionTypes.singleChoice.id || formData.questiontype === this.allQuestionTypes.multipleChoice.id) {
        if (this.data.optionDetails.length <= 1) {
          this.handlePushToErrors(this.translate.youHaveToAddAtLeastTwoAnswerOptions);
        }
        if (this.data.optionDetails.some(function (option) {
          return !option.label;
        })) {
          this.handlePushToErrors(this.translate.questionAnswersCanNotBeBlank);
          this.showEmptyFieldsError = true;
        }
      }

      // Check if question number doesn't already exist
      this.questions.forEach(function (question) {
        if (formData.id != question.id && formData.questionnumber === question.questionnumber) {
          _this11.handlePushToErrors(_this11.translate.questionNumberAlreadyExists);
        }
      });
      if (formData.questiontype === this.allQuestionTypes.customLists.id && !formData.customValueList) {
        this.handlePushToErrors(this.translate.theCustomListMustBeSpecified);
      }
      return this.errors.length <= 0;
    },
    isCallStackExceed: function isCallStackExceed(question, questions) {
      var dependencies = Object.keys(question.dependencies);
      if (dependencies.includes(question.questionnumber)) return true;
      this.callStackState = true;
      var newQuestions = _toConsumableArray(questions);
      var prevIndex = newQuestions.findIndex(function (item) {
        return item.id === question.id;
      });
      if (prevIndex === -1) {
        newQuestions.push(question);
      } else {
        newQuestions.splice(prevIndex, 1, question);
      }
      return !this.checkForCallStackLoop(Object.keys(question.dependencies), newQuestions);
    },
    checkForCallStackLoop: function checkForCallStackLoop(dependencies, questions) {
      var _this12 = this;
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      dependencies.forEach(function (item) {
        if (data.includes(item)) {
          _this12.callStackState = false;
          return;
        }
        data.push(item);
        var question = questions.find(function (element) {
          return element.questionnumber === item;
        });
        if (!question) return;
        if (question.dependencies) {
          _this12.checkForCallStackLoop(Object.keys(question.dependencies), questions, data);
        }
      });
      return this.callStackState;
    },
    handlePushToErrors: function handlePushToErrors(message) {
      this.errors.push(message);
    },
    handleApiRequest: function handleApiRequest(formData) {
      var _this13 = this;
      this.isSaving = true;
      var promise = formData.id ? editQuestion : createQuestion;
      promise(formData).then(function (response) {
        _this13.$emit('created', response);
        _this13.handleResetToDefaultValues();
      }).catch(function (error) {
        _this13.handlePushToErrors(error.response.error);
        if (formData.id) {
          _this13.data.options = cloneDeep(_this13.inputData.options);
          _this13.data.optionDetails = cloneDeep(_this13.inputData.optionDetails);
        }
      }).finally(function () {
        return _this13.isSaving = false;
      });
    },
    handleResetToDefaultValues: function handleResetToDefaultValues() {
      var _this14 = this;
      Object.keys(this.defaults).forEach(function (propName) {
        _this14.data[propName] = _this14.defaults[propName];
      });
      this.errors = [];
    },
    onCancel: function onCancel() {
      var withReset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (withReset) {
        this.handleResetToDefaultValues();
        this.$emit('dismiss');
        return;
      }
      this.$emit('canceled');
    },
    cancel: function cancel() {
      var _this15 = this;
      if (this.isDirty) {
        this.$confirm('', this.translate.areYouSureYouWantToCancelTheEnteredInfor, function (res) {
          return _this15.onCancel(res);
        });
      } else {
        this.onCancel(true);
      }
    },
    questionNumberValid: function questionNumberValid(number) {
      for (var _i = 0, _QUESTION_NUMBER_VALI = QUESTION_NUMBER_VALID_PATTERNS; _i < _QUESTION_NUMBER_VALI.length; _i++) {
        var regex = _QUESTION_NUMBER_VALI[_i];
        if (!regex.test(number)) {
          return false;
        }
      }
      return true;
    },
    onQuestionChange: function onQuestionChange(e) {
      var answers = e.data.map(function (item) {
        return item.title;
      });
      if (answers.length) {
        if (!this.data.dependencies) {
          this.data.dependencies = {};
        }
        this.data.dependencies[e.key] = answers;
      } else if (this.data.dependencies && e.key in this.data.dependencies) {
        delete this.data.dependencies[e.key];
      }
    },
    onFrameworksSelected: function onFrameworksSelected(frameworks) {
      this.data.solutionIds = frameworks.map(function (_ref4) {
        var id = _ref4.id;
        return id;
      });
    }
  }
};