function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'QuestionnaireNavigation',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  props: {
    questions: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'questions to navigate on'
    },
    visibilityStorageKey: {
      type: String,
      required: false,
      default: 'questionnaire_navigation_hidden_columns',
      note: 'local storage key to store hidden columns'
    },
    processors: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'processors including/excluding underlying'
    }
  },
  emits: ['changeQuestion'],
  setup: function setup(props) {
    return {
      closedHeadlines: getLocalStorageComputed(props.visibilityStorageKey, [], true)
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    expandedQuestions: function expandedQuestions() {
      var _this = this;
      return this.questions.map(function (question, key) {
        return _objectSpread(_objectSpread({}, question), {}, {
          hasHeadline: _this.hasHeadline(question, key),
          isAnswered: _this.isAnswered(question),
          isClickable: !_this.showFullQuestionsList || question.answeredDependencies
        });
      });
    },
    includeSubprocessors: function includeSubprocessors() {
      return this.getSettingValue('feature.ia_questions_include_subprocessors') !== 0;
    },
    allProcessors: function allProcessors() {
      if (this.includeSubprocessors) {
        return this.processors;
      }
      return this.processors.filter(function (processor) {
        return !processor.parents.length;
      });
    },
    showFullQuestionsList: function showFullQuestionsList() {
      return this.getSettingValue('feature.show_full_list_of_questionnaires');
    }
  }),
  methods: {
    changeQuestion: function changeQuestion(id) {
      this.$emit('changeQuestion', id);
    },
    isAnswered: function isAnswered(question) {
      if (+question.questiontype === QuestionTypes().processLibrary.id) {
        return question.processLibrary && question.processLibrary.length > 0;
      }
      var answersLength = _typeof(question.answers_options) === 'object' ? Object.keys(question.answers_options).length : question.answers_options.length;
      return answersLength > 0 || !!question.dateTime || !!question.freeText || !!(question.files && question.files.length > 0) || this.hasIAAnswer(question);
    },
    hasIAAnswer: function hasIAAnswer(question) {
      var _this2 = this;
      if (!question.informationAssets) {
        return false;
      }
      var iaArray = Object.keys(question.informationAssets || {});
      var procIndex = iaArray.indexOf(AssetsTypes.PROCESSORS);
      if (this.includeSubprocessors || procIndex === -1) {
        return Object.values(question.informationAssets).some(function (item) {
          return item.length > 0;
        });
      }
      iaArray.splice(procIndex, 1);
      return question.informationAssets[AssetsTypes.PROCESSORS].length && question.informationAssets[AssetsTypes.PROCESSORS].some(function (item) {
        return _this2.allProcessors.find(function (processor) {
          return +processor.id === +item.id;
        });
      }) || iaArray.some(function (item) {
        return question.informationAssets[item].length > 0;
      });
    },
    handleClosedHeadlines: function handleClosedHeadlines(headline) {
      if (this.closedHeadlines.includes(headline)) {
        this.closedHeadlines = this.closedHeadlines.filter(function (h) {
          return h !== headline;
        });
        return;
      }
      this.closedHeadlines = [].concat(_toConsumableArray(this.closedHeadlines), [headline]);
    },
    hasHeadline: function hasHeadline(question, key) {
      return key - 1 === -1 || this.questions[key - 1].headline !== question.headline;
    }
  }
};