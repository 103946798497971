// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-reset-styles[data-v-671e060a]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-671e060a]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-671e060a]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-671e060a]  li {
  padding-left: 0.3em;
}
.dataFlowImage[data-v-671e060a] {
  width: 400px;
  height: auto;
}
th[data-v-671e060a]:first-child,
td[data-v-671e060a]:first-child {
  padding-left: 15px;
}
th[data-v-671e060a]:last-child,
td[data-v-671e060a]:last-child {
  padding-right: 15px;
}
td[data-v-671e060a] {
  vertical-align: top;
  padding: 5px;
}
th[data-v-671e060a] {
  min-width: 200px !important;
  vertical-align: top;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-bottom: 1px solid #737373 !important;
  line-height: 20px !important;
  padding: 5px 8px !important;
}
table td ul[data-v-671e060a] {
  padding-left: 20px;
}
tr[data-v-671e060a] > * {
  border: 1px solid #347480 !important;
}
.th-long-question[data-v-671e060a] {
  min-width: 350px !important;
}
tr td.hierarchical-list-data[data-v-671e060a] {
  font-weight: 600;
}
tr td.hierarchical-list-data[data-v-671e060a]  a:hover {
  text-decoration: underline;
}
tr td.hierarchical-list-data[data-v-671e060a]  .cursor-pointer:hover {
  text-decoration: underline;
}
[data-v-671e060a]  .col-dataFlow img {
  width: 400px;
  height: auto;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
