import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "color-picker-wrap"
};
var _hoisted_2 = {
  class: "input-group flex-nowrap color-picker flex relative"
};
var _hoisted_3 = {
  class: "color-picker-container h-auto py-0 px-2 flex items-center text-center bg-gray-80 border-l-0 border border-gray-450 rounded-r"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_input_field = _resolveComponent("input-field");
  var _component_chrome_picker = _resolveComponent("chrome-picker");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    class: "cursor-pointer mb-1",
    onClick: $options.showPicker
  }, null, 8 /* PROPS */, ["title", "onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
    ref: "input",
    modelValue: _ctx.colorValue,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return _ctx.colorValue = $event;
    }), $options.updateFromInput],
    enabled: !$props.readonly,
    maxlength: 7,
    type: "text",
    class: _normalizeClass({
      'w-full': $props.adaptiveWidth
    }),
    onFocus: $options.showPicker
  }, null, 8 /* PROPS */, ["modelValue", "enabled", "class", "onUpdate:modelValue", "onFocus"]), _createElementVNode("span", _hoisted_3, [_createElementVNode("span", {
    style: _normalizeStyle('background-color: ' + _ctx.colorValue),
    class: _normalizeClass(['inline-block w-4 h-4 bg-black', {
      'cursor-pointer': !$props.readonly
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return !$props.readonly && $options.togglePicker();
    })
  }, null, 6 /* CLASS, STYLE */), _ctx.displayPicker ? (_openBlock(), _createBlock(_component_chrome_picker, {
    key: 0,
    "model-value": _ctx.colors,
    "disable-fields": true,
    "disable-alpha": true,
    "onUpdate:modelValue": $options.updateFromPicker
  }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])])])), [[_directive_click_outside, $options.hidePicker]]);
}