function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import * as kpiApi from '@/api/reports/performance';
import PerformanceCard from '@/components/Molecules/Cards/PerformanceCard';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import RadarChart from '@/components/Molecules/Charts/RadarChart';
import SelectableLegend from '@/components/Molecules/SelectableLegend/SelectableLegend';
import KpiCard from '@/components/Molecules/KpiCard/KpiCard';
import InlineSelect from '@/components/Atoms/Inputs/InlineSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import KpiInsights from '@/components/Molecules/KpiInsights';
import KpiRelevantActivities from '@/components/Molecules/KpiCard/KpiRelevantActivities';
import { KpiSortingMixin } from '@/mixins/KpiSortingMixin';
import { insertEmptyInArray } from '@/utils/insertEmptyInArray';
import { KpiCardColors, projectStatusChartOptions, KpiRadarColors, getNewStatusDataObject, generateInsights } from '@/constants/performance/dashboard';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { formatTimeToDateFormat, getMonthName, getYear, getUnixTime } from '@/utils/date';
export default {
  name: 'PerformanceSolution',
  components: {
    KpiInsights: KpiInsights,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InlineSelect: InlineSelect,
    KpiCard: KpiCard,
    PerformanceCard: PerformanceCard,
    RismaDoughnutChart: RismaDoughnutChart,
    RadarChart: RadarChart,
    SelectableLegend: SelectableLegend,
    KpiRelevantActivities: KpiRelevantActivities
  },
  mixins: [KpiSortingMixin],
  props: {
    dashboardId: {
      type: String,
      required: true
    },
    dashboards: {
      type: Array,
      required: true,
      note: 'kpi dashboards array'
    },
    performanceData: {
      required: true,
      type: Array,
      note: 'define performance data using to build doughnut chars'
    }
  },
  setup: function setup() {
    return {
      selectedSortingMethod: getLocalStorageComputed('performance_solution_kpi_sort', 'hw')
    };
  },
  data: function data() {
    return {
      isLoading: false,
      dataIsAbsent: false,
      options: projectStatusChartOptions,
      combinedRadarDataset: [],
      kpiData: [],
      radarTooltipFn: function radarTooltipFn(value) {
        return "".concat(value.dataset.label, " (").concat(value.label, "): ").concat(value.formattedValue, "%");
      },
      translate: getTranslate['PerformanceSolution'](),
      xlScreenSize: 1280,
      isXlScreen: true
    };
  },
  computed: {
    groups: function groups() {
      if (!this.isXlScreen) return [this.sortedKpiCards];
      return this.sortedKpiCards.reduce(function (acc, current, index) {
        acc[index % 2].push(current);
        return acc;
      }, [[], []]);
    },
    sortedKpiCards: function sortedKpiCards() {
      return _toConsumableArray(this.kpiCards).sort(this.sortingFunctions[this.selectedSortingMethod]);
    },
    projectStatusData: function projectStatusData() {
      return getNewStatusDataObject(this.currentPerformanceData, {
        fontSize: 60
      });
    },
    kpiCards: function kpiCards() {
      return this.kpiData.reduce(function (acc, item, idx) {
        var colorsLength = KpiCardColors.length;
        if (item.todaysKpiData) {
          var _item$metaData;
          return [].concat(_toConsumableArray(acc), [{
            id: item.id,
            cardHeader: item.title,
            weight: +item.weight * 100,
            currentValue: item.todaysKpiData.matchedEntries,
            maxValue: item.todaysKpiData.totalEntries,
            kpiValue: parseInt(item.todaysKpiData.kpiValue),
            description: item.description,
            kpiDetails: (_item$metaData = item.metaData) === null || _item$metaData === void 0 ? void 0 : _item$metaData.kpiDetails,
            _color: idx >= colorsLength ? KpiCardColors[idx % colorsLength] : KpiCardColors[idx]
          }]);
        }
        return acc;
      }, []);
    },
    radarData: function radarData() {
      if (!this.kpiData.length) {
        return null;
      }
      var radarData = {
        labels: this.kpiData.map(function (item) {
          return item.title;
        }),
        datasets: [],
        colors: KpiRadarColors
      };
      var todayDataset = {
        label: this.translate.today,
        data: this.kpiData.reduce(function (acc, item) {
          if (!item.todaysKpiData) {
            return [].concat(_toConsumableArray(acc), [null]);
          }
          return [].concat(_toConsumableArray(acc), [parseFloat(item.todaysKpiData.kpiValue)]);
        }, [])
      };
      radarData.datasets.push(todayDataset);
      if (this.historicalDatasets.length) {
        radarData.datasets.push(this.historicalDatasets);
      }
      return radarData;
    },
    historicalDataAggregation: function historicalDataAggregation() {
      var historicalDataAggregation = {};
      this.kpiData.forEach(function (kpi) {
        kpi.historicalKpiData.forEach(function (historical) {
          var dateRecorded = formatTimeToDateFormat(historical.dateRecorded);
          var kpiValue = parseFloat(historical.kpiValue);
          if (!historicalDataAggregation[dateRecorded]) {
            historicalDataAggregation[dateRecorded] = [kpiValue];
          } else {
            historicalDataAggregation[dateRecorded].push(kpiValue);
          }
        });
      });
      return this.normalizeDataAggregation(historicalDataAggregation, this.kpiData);
    },
    historicalDatasets: function historicalDatasets() {
      var _this = this;
      return Object.keys(this.historicalDataAggregation).sort(function (prev, cur) {
        return getUnixTime(prev) - getUnixTime(cur);
      }).reduce(function (acc, date) {
        var label = "".concat(_this.$trans(getMonthName(date)), " ").concat(getYear(date));
        return [].concat(_toConsumableArray(acc), [{
          label: label.toUpperCase(),
          data: _this.historicalDataAggregation[date]
        }]);
      }, []);
    },
    currentDashboard: function currentDashboard() {
      var _this2 = this;
      return this.dashboards.find(function (dashboard) {
        return +dashboard.id === +_this2.dashboardId;
      });
    },
    currentPerformanceData: function currentPerformanceData() {
      var _this3 = this;
      return this.performanceData.find(function (item) {
        return +item.id === +_this3.dashboardId;
      });
    },
    recentMonthAverageData: function recentMonthAverageData() {
      if (this.historicalDatasets.length) {
        var recentDataset = this.historicalDatasets.slice(-1)[0].data;
        if (recentDataset && recentDataset.length) {
          var sum = recentDataset.reduce(function (acc, item) {
            return acc + item;
          }, 0);
          return sum / recentDataset.length;
        }
      }
      return 0;
    },
    kpiInsights: function kpiInsights() {
      var todaysValue = this.currentPerformanceData.data[0];
      return generateInsights({
        activeKpi: this.kpiData.length,
        monthlyScores: this.currentPerformanceData.data[0],
        isAbove: todaysValue >= this.recentMonthAverageData
      });
    }
  },
  watch: {
    dashboardId: function dashboardId() {
      this.getKpiData();
    }
  },
  created: function created() {
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted: function mounted() {
    this.getKpiData();
    this.isXlScreen = window.innerWidth >= this.xlScreenSize;
  },
  methods: {
    onResize: function onResize() {
      if (this.isXlScreen && window.innerWidth < this.xlScreenSize) {
        this.isXlScreen = false;
        return;
      }
      if (!this.isXlScreen && window.innerWidth >= this.xlScreenSize) {
        this.isXlScreen = true;
      }
    },
    getKpiData: function getKpiData() {
      var _this4 = this;
      if (!this.currentDashboard) {
        this.$router.go(-1);
        return;
      }
      this.dataIsAbsent = false;
      this.kpiData = [];
      this.isLoading = true;
      return kpiApi.getKpiData(this.currentDashboard.id).then(function (data) {
        var enabledKpis = data.kpis.filter(function (kpi) {
          return kpi.enabled;
        });
        _this4.isLoading = false;
        if (!enabledKpis.length) {
          _this4.dataIsAbsent = true;
          return;
        }
        _this4.kpiData = enabledKpis;
      });
    },
    normalizeDataAggregation: function normalizeDataAggregation(dataAggregation, kpiData) {
      var normalizedData = {};
      var _loop = function _loop(key) {
        var emptyPoints = [];
        kpiData.forEach(function (kpi, idx) {
          if (!kpi.historicalKpiData.find(function (item) {
            return formatTimeToDateFormat(item.dateRecorded) === key;
          })) {
            emptyPoints.push(idx);
          }
        });
        normalizedData[key] = _toConsumableArray(insertEmptyInArray(dataAggregation[key], emptyPoints));
      };
      for (var key in dataAggregation) {
        _loop(key);
      }
      return normalizedData;
    }
  }
};