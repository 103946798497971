import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: '',
  components: {
    SingleSelect: SingleSelect
  },
  data: function data() {
    return {
      SingleSelectOptions: [{
        value: '1',
        label: 'Hello'
      }, {
        value: '2',
        label: 'To'
      }, {
        value: '3',
        label: 'You'
      }, {
        value: '4',
        label: 'Myself'
      }, {
        value: '5',
        label: 'Say'
      }, {
        value: '6',
        label: 'My'
      }, {
        value: '7',
        label: 'Little'
      }, {
        value: '8',
        label: 'Friend'
      }],
      SingleSelectValue: '3',
      SingleSelectValue2: '',
      SingleSelectValue3: '',
      SingleSelectOptions3: [{
        label: 'Vegetables',
        $isGroup: true
      }, {
        value: '1',
        label: 'Potato',
        groupName: 'Vegetable'
      }, {
        value: '2',
        label: 'Cucumber',
        groupName: 'Vegetable'
      }, {
        value: '3',
        label: 'Carrots',
        groupName: 'Vegetable'
      }, {
        label: 'Fruits',
        $isGroup: true
      }, {
        value: '6',
        label: 'Apple',
        groupName: 'Fruit'
      }, {
        value: '7',
        label: 'Banana ',
        groupName: 'Fruit'
      }, {
        value: '8',
        label: 'Apricot',
        groupName: 'Fruit'
      }]
    };
  }
};