export var defaultProbabilityLabels = {
  CIA: ['Not relevant', 'Unlikely', 'Less likely', 'Likely', 'Expected'],
  FIT: ['Ikke relevant', 'Usandsynligt', 'Mindre sandsynligt', 'Sandsynligt', 'Forventet']
};
export var defaultConsequenceLabels = {
  CIA: ['Not relevant', 'Insignificant', 'Less serious', 'Very serious', 'Destructive'],
  FIT: ['Ikke relevant', 'Ubetydeligt', 'Mindre alvorligt', 'Meget alvorligt', 'Ødelæggende']
};
export var defaultFirstColumn = {
  CIA: ['Confidentiality', 'Integrity', 'Availability'],
  FIT: ['Fortrolighed', 'Integritet', 'Tilgængelighed']
};
export var defaultLevelOfThreatOptions = [{
  title: 'Low'
}, {
  title: 'Medium'
}, {
  title: 'High'
}];
export var defaultAxisLabels = {
  PROBABILITY: 'Probability',
  CONSEQUENCE: 'Consequence'
};