import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_export_frame, {
    class: "h-20 w-96 mb-2 border-2 flex items-center"
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("span", null, "some text to export (or could be anything)", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_export_frame, {
    "use-export": false,
    class: "h-20 w-96 border-2 flex items-center"
  }, {
    default: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createElementVNode("span", null, "Disabled", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  })], 64 /* STABLE_FRAGMENT */);
}