// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-bg-blue[data-v-29055356] {
  color: #FFFFFF;
  background-color: #0e2954;
}
table.colum-0-hidden th[data-v-29055356]:nth-of-type(3),
table.colum-0-hidden td[data-v-29055356]:nth-of-type(3) {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
