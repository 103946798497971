function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { getAllQuestionnaires as _getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getActivityType } from '@/api/risma/activityTypes';
import { getCustomFields, getOptionalFields } from '@/api/admin/admin';
import { MODULES } from '@/constants/modules';
import { AdminCustomFieldsAndActivityTypesMixin } from '@/components/Organisms/Admin/AdminCustomFieldsAndActivityTypesMixin';
import AdminActivityTypesModal from '@/components/Organisms/Admin/AdminActivityTypesModal';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { getDisabledModuleFromArrayBySetting } from '@/utils/format/getDisabledModuleFromArrayBySetting';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AdminActivityTypesWell',
  introduction: 'Well with activity types in /business/admin',
  description: 'Well with a list of activity types. Used together with AdminActivityTypesModal component.',
  token: '<admin-activity-types-well></admin-activity-types-well>',
  components: {
    AdminActivityTypesModal: AdminActivityTypesModal,
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect
  },
  mixins: [AdminCustomFieldsAndActivityTypesMixin],
  data: function data() {
    return {
      isLoaded: false,
      fields: {
        optionalFields: [],
        customFields: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.EXECUTION, []), MODULES.CONTROLS, []), MODULES.INCIDENTS, []), MODULES.COMPLIANCE_INFORMATION_ASSETS, []), MODULES.RISK, []), MODULES.COMPLIANCE_IA_SYSTEM, []), MODULES.COMPLIANCE_IA_PROCESSOR, []), MODULES.COMPLIANCE_IA_CONTROLLER, []), MODULES.COMPLIANCE, []), MODULES.CONTRACTS, [])
      },
      showModal: false,
      idToDelete: null,
      modalData: {},
      isEditMode: false,
      selectedModules: [],
      allQuestionnaires: [],
      translate: getTranslate['AdminActivityTypesWell']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['activityTypes'])), {}, {
    filteredIds: function filteredIds() {
      return [this.getSettingValue('feature.isms_rpo_field_id'), this.getSettingValue('feature.isms_rto_field_id')];
    },
    modulesOptions: function modulesOptions() {
      var _this = this;
      var uniqueModules = _toConsumableArray(new Set(this.activities.map(function (item) {
        return item.module;
      })));
      return uniqueModules.map(function (module) {
        return {
          label: _this.moduleTitles[module],
          id: module
        };
      }).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      });
    },
    sortedActivities: function sortedActivities() {
      var _this2 = this;
      return _toConsumableArray(this.activities).sort(function (a, b) {
        return compareStrings(a.label, b.label);
      }).sort(function (a, b) {
        return compareStrings(_this2.moduleTitles[a.module], _this2.moduleTitles[b.module]);
      });
    },
    filteredActivities: function filteredActivities() {
      if (!this.selectedModules.length) return this.sortedActivities;
      var moduleIds = this.selectedModules.map(function (item) {
        return item.id;
      });
      return this.sortedActivities.filter(function (activity) {
        return moduleIds.indexOf(activity.module) !== -1;
      });
    },
    activities: function activities() {
      var disabledModules = getDisabledModuleFromArrayBySetting(this.activityTypes.map(function (item) {
        return item.module;
      }), this.getSettingValue);
      return this.activityTypes.filter(function (item) {
        return !disabledModules.includes(item.module);
      });
    }
  }),
  mounted: function mounted() {
    this.getInitData();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useActivityTypesStore, {
    deleteActivityType: 'delete'
  })), mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    getInitData: function getInitData() {
      var _this3 = this;
      return Promise.all([this.getAllCustomFields(), this.getAllOptionalFields(), this.getAllQuestionnaires()]).then(function () {
        _this3.isLoaded = true;
      });
    },
    getAllCustomFields: function getAllCustomFields() {
      var _this4 = this;
      return getCustomFields().then(function (_ref) {
        var list = _ref.list;
        _this4.sortFieldsByModule(_this4.filterFields(list));
      }).catch(function (e) {
        throw e;
      });
    },
    getAllQuestionnaires: function getAllQuestionnaires() {
      var _this5 = this;
      return _getAllQuestionnaires().then(function (_ref2) {
        var list = _ref2.list;
        return _this5.allQuestionnaires = list;
      });
    },
    getAllOptionalFields: function getAllOptionalFields() {
      var _this6 = this;
      return getOptionalFields(this.getSettingValue).then(function (result) {
        var optionalFields = {};
        for (var prop in result) {
          if (Object.prototype.hasOwnProperty.call(result, prop)) {
            optionalFields[prop] = result[prop].map(function (option) {
              return Object.assign({}, option, {
                label: _this6.$trans(option.label)
              });
            });
          }
        }
        _this6.fields.optionalFields = optionalFields;
      }).catch(function (e) {
        throw e;
      });
    },
    filterFields: function filterFields(data) {
      var _this7 = this;
      return data.filter(function (item) {
        return item.module !== MODULES.RISK_PROJECT_PERIOD && !_this7.filteredIds.includes(item.id);
      });
    },
    sortFieldsByModule: function sortFieldsByModule(data) {
      var _this$fields$customFi,
        _this8 = this;
      this.fields.customFields = (_this$fields$customFi = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$fields$customFi, MODULES.EXECUTION, []), MODULES.CONTROLS, []), MODULES.INCIDENTS, []), MODULES.COMPLIANCE_INFORMATION_ASSETS, []), MODULES.RISK, []), MODULES.COMPLIANCE, []), MODULES.CONTRACTS, []), MODULES.COMPLIANCE_IA_SYSTEM, []), MODULES.COMPLIANCE_IA_PROCESSOR, []), MODULES.COMPLIANCE_IA_CONTROLLER, []), _defineProperty(_this$fields$customFi, MODULES.COMPLIANCE_PROCESS_TREE_NODE, []));
      data.forEach(function (item) {
        _this8.fields.customFields[item.module].push(item);
      });
    },
    createActivityType: function createActivityType(e) {
      var _this9 = this;
      e.preventDefault();
      this.getAllCustomFields().then(function () {
        _this9.showModal = true;
        _this9.isEditMode = false;
      });
    },
    editActivityType: function editActivityType(id) {
      var _this10 = this;
      var data = {};
      Promise.all([this.getAllCustomFields(), getActivityType(id).then(function (activity) {
        return data = activity;
      })]).then(function () {
        _this10.showModal = true;
        _this10.isEditMode = true;
        _this10.modalData = data;
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this11 = this;
      this.deleteActivityType(this.idToDelete).then(function () {
        _this11.removeActivityTypeById(_this11.idToDelete);
        _this11.showSuccessMessage(_this11.translate.activityTypeDeleted);
      }).catch(function (data) {
        if (data) {
          if (data.response && data.response.data && data.response.data.error) {
            _this11.showErrorMessage(data.response.data.error);
          }
          throw data;
        }
      });
    },
    confirmDeletionActivityType: function confirmDeletionActivityType(id) {
      var _this12 = this;
      this.$confirm('', this.translate.areYouSureYouWantToDeleteThisActivityType, function (res) {
        return res && _this12.onDeleteConfirm();
      });
      this.idToDelete = id;
    },
    removeActivityTypeById: function removeActivityTypeById(id) {
      var _this13 = this;
      this.activities.map(function (field, index) {
        if (field.id === id) {
          _this13.activities.splice(index, 1);
        }
      });
    },
    onCreated: function onCreated() {
      this.showSuccessMessage(this.translate.activityTypeCreated);
      this.getAllCustomFields();
      this.loadCurrentUser();
    },
    onEdit: function onEdit() {
      this.showSuccessMessage(this.translate.activityTypeWasSuccessfullyUpdated);
      this.getAllCustomFields();
    },
    showSuccessMessage: function showSuccessMessage(msg) {
      this.$notify({
        title: msg,
        type: 'success'
      });
    },
    showErrorMessage: function showErrorMessage(msg) {
      this.$notify({
        title: msg,
        type: 'error'
      });
    },
    resetModal: function resetModal() {
      this.showModal = false;
      this.modalData = {};
      this.type = 'create';
      this.module = '';
    }
  })
};