import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "radio-buttons"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = ["id", "value", "name", "checked", "disabled", "onClick", "onChange"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, function (option) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat(option.value).concat(option.label),
      class: _normalizeClass(["radio-buttons-item relative m-2", {
        disabled: $props.disabled
      }])
    }, [_createElementVNode("label", _hoisted_2, [_createElementVNode("input", {
      id: "randomGroupName".concat(option.value),
      value: option.value,
      name: $options.randomGroupName,
      checked: option.value == $data.prevValue,
      disabled: $props.disabled,
      type: "radio",
      class: "opacity-0 absolute",
      onClick: function onClick($event) {
        return $options.handleChange(option.value);
      },
      onChange: function onChange($event) {
        return _ctx.$emit('change', option.value);
      }
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), _createElementVNode("span", null, [_renderSlot(_ctx.$slots, "label", {
      label: option.label
    }, function () {
      return [_createTextVNode(_toDisplayString(option.label), 1 /* TEXT */)];
    }, true)])])], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]);
}