import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_single_select, {
    modelValue: $options.selectedNodeId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.selectedNodeId = $event;
    }),
    title: $data.translate.selectOutsourcingArrangement,
    options: $data.topLevelNodes,
    class: "select-top-level-node"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        class: "mt-4",
        onError: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$notify({
            title: $event,
            type: 'error'
          });
        })
      }, null, 32 /* NEED_HYDRATION */))];
    }),
    _: 1 /* STABLE */
  })]);
}