function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mb-4 border-b-2 border-gray-250"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_delegation_modal = _resolveComponent("delegation-modal");
  var _component_delegation_list = _resolveComponent("delegation-list");
  return $options.isEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.showModal ? (_openBlock(), _createBlock(_component_delegation_modal, {
    key: 0,
    "options-list": _ctx.enabledFrameworkIds,
    "preselected-items": _toConsumableArray($props.list),
    "modal-settings": $options.modalSettings,
    onUpdateDelegationItem: $options.onApplyChanges,
    onCloseDelegationModal: _cache[0] || (_cache[0] = function ($event) {
      return $data.showModal = false;
    }),
    onCreate: _cache[1] || (_cache[1] = function ($event) {
      return $data.showModal = true;
    })
  }, null, 8 /* PROPS */, ["options-list", "preselected-items", "modal-settings", "onUpdateDelegationItem"])) : _createCommentVNode("v-if", true), _createVNode(_component_delegation_list, {
    title: $data.translate.frameworkId,
    "read-only": $props.readOnly,
    list: $options.convertedSelectedFrameworkIds,
    "label-key": "title",
    onEdit: $options.editDelegationItem
  }, null, 8 /* PROPS */, ["title", "read-only", "list", "onEdit"])])) : _createCommentVNode("v-if", true);
}