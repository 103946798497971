import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
var pdfDoc = null;
var pdfPage = null;
var renderTask = null;
export default {
  name: 'PDFDocument',
  introduction: 'PDFDocument is component for parsing and rendering PDFs using pdfjs-dist',
  token: '<pdf-document :src-pdf="fileSrcPreview" />',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle
  },
  props: {
    srcPdf: {
      type: String,
      required: true,
      note: 'The scr pdf file'
    }
  },
  data: function data() {
    return {
      currentPage: 0,
      pageRendering: false,
      viewport: null,
      pageNumPending: null,
      isLoaded: false,
      resizingDelay: 200,
      resizingTimer: null,
      showErrorLoading: false,
      totalPages: 0,
      translate: {
        previous: this.$trans('Previous'),
        next: this.$trans('Next'),
        somethingWentWrong: this.$trans('Something went wrong!')
      }
    };
  },
  mounted: function mounted() {
    this.createPage();
    window.addEventListener('resize', this.initDraw);
  },
  beforeUnmount: function beforeUnmount() {
    this.destroyPage(pdfPage);
    window.removeEventListener('resize', this.initDraw);
  },
  methods: {
    createPage: function createPage() {
      var _this = this;
      // eslint-disable-next-line import/extensions
      return import('pdfjs-dist/webpack.mjs').then(function (pdfjsLib) {
        return pdfjsLib.getDocument(_this.srcPdf).promise.then(function (response) {
          pdfDoc = response;
          _this.totalPages = pdfDoc.numPages;
          _this.currentPage = 1;
          return _this.renderPage(_this.currentPage);
        });
      }).catch(function () {
        _this.isLoaded = true;
        _this.showErrorLoading = true;
      });
    },
    renderPage: function renderPage(num) {
      var _this2 = this;
      this.pageRendering = true;
      return pdfDoc.getPage(num).then(function (page) {
        pdfPage = page;
        _this2.drawPage();
      });
    },
    changeCurrentPage: function changeCurrentPage(pageNumber) {
      if (!pageNumber || pageNumber > this.totalPages) return;
      this.currentPage = pageNumber;
      if (this.pageRendering) {
        this.pageNumPending = pageNumber;
      } else {
        this.renderPage(this.currentPage);
      }
    },
    initDraw: function initDraw() {
      var _this3 = this;
      if (!this.currentPage) return;
      this.resizingTimer && clearTimeout(this.resizingTimer);
      this.resizingTimer = setTimeout(function () {
        _this3.currentPage && _this3.drawPage();
      }, this.resizingDelay);
    },
    drawPage: function drawPage() {
      var _this4 = this;
      var initialViewport = pdfPage.getViewport({
        scale: 1
      });
      var scale = this.$refs.container.clientWidth / initialViewport.width;
      this.viewport = pdfPage.getViewport({
        scale: scale
      });
      var canvas = this.$el.querySelector('canvas');
      canvas.height = this.viewport.height;
      canvas.width = this.viewport.width;
      var renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: this.viewport
      };
      renderTask = pdfPage.render(renderContext);
      renderTask.promise.then(function () {
        _this4.pageRendering = false;
        if (_this4.pageNumPending !== null) {
          _this4.renderPage(_this4.pageNumPending);
          _this4.pageNumPending = null;
        }
        _this4.isLoaded = true;
      }).catch(function () {
        _this4.isLoaded = true;
        _this4.showErrorLoading = true;
        _this4.destroyRenderTask();
      });
    },
    destroyRenderTask: function destroyRenderTask() {
      if (!renderTask) return;
      renderTask.cancel();
    },
    destroyPage: function destroyPage(page) {
      if (page) page._destroy();
      this.destroyRenderTask();
    }
  }
};