import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "awareness max-w-screen-xxl xxl:mx-auto"
};
var _hoisted_2 = {
  key: 2,
  class: "awareness-content"
};
var _hoisted_3 = {
  key: 0,
  class: "mb-4 text-blue-750"
};
var _hoisted_4 = {
  key: 1,
  class: "mb-4 text-blue-750"
};
var _hoisted_5 = {
  class: "mb-4"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = {
  class: "min-h-12rem p-3 border-2 border-gray-250 mb-4"
};
var _hoisted_8 = {
  class: "flex items-center mb-4"
};
var _hoisted_9 = {
  class: "mb-4"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_notification = _resolveComponent("notification");
  var _component_attachment_selector = _resolveComponent("attachment-selector");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.welcomeToRismaawareness,
    class: "main-component-title mb-4"
  }, null, 8 /* PROPS */, ["title"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.showErrorNotification ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "error",
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.isLoaded && $data.awareness.processNodes ? (_openBlock(), _createElementBlock("div", _hoisted_2, [!$data.awareness.signoff ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($data.translate.youHaveBeenAskedToSignOffThisDocument), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.awareness.signoff ? (_openBlock(), _createElementBlock("p", _hoisted_4, [_createElementVNode("b", null, _toDisplayString($data.translate.theDocumentHasAlreadyBeenSignedOffOn) + ": " + _toDisplayString($options.signoffDate), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("p", _hoisted_5, [_createElementVNode("i", null, [_createElementVNode("span", {
    innerHTML: $options.requestMessage
  }, null, 8 /* PROPS */, _hoisted_6)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.title,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "text-base font-semibold"
  }, ":", -1 /* HOISTED */)), _cache[2] || (_cache[2] = _createTextVNode("   ")), _createVNode(_component_risma_title, {
    title: $options.fullTitle,
    style: {
      "font-weight": "500"
    }
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.description,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    class: "readonly-description text-blue-750",
    innerHTML: $options.nodeData.description
  }, null, 8 /* PROPS */, _hoisted_10)]), _createVNode(_component_attachment_selector, {
    "attachments-existed": $options.attachments,
    "owner-id": 1,
    removable: false,
    "read-only": true,
    module: "process-library",
    "show-title": !!$options.attachments.length
  }, null, 8 /* PROPS */, ["attachments-existed", "show-title"])]), _createElementVNode("div", _hoisted_11, [!$data.awareness.signoff ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.yesIHaveReadAndIAccept,
    type: "save",
    class: "inline-block",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$confirm('', '', function (res) {
        return res && $options.onConfirm();
      });
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true)]);
}