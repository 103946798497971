// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-017263b0]  .col-risks,[data-v-017263b0]  .col-systems,[data-v-017263b0]  .col-initiatives,[data-v-017263b0]  .col-controls {
  font-weight: 600;
}
[data-v-017263b0]  .col-risks a:hover,[data-v-017263b0]  .col-systems a:hover,[data-v-017263b0]  .col-initiatives a:hover,[data-v-017263b0]  .col-controls a:hover {
  text-decoration: underline;
}
[data-v-017263b0]  .col-risks .cursor-pointer:hover,[data-v-017263b0]  .col-systems .cursor-pointer:hover,[data-v-017263b0]  .col-initiatives .cursor-pointer:hover,[data-v-017263b0]  .col-controls .cursor-pointer:hover {
  text-decoration: underline;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
