import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-6"
};
var _hoisted_2 = {
  class: "mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    class: "mb-5",
    title: $data.translate.closeReportingYear,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.whenYouCloseTheReportingYearForYourCsrd), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.allDisclosureRequirementsReportDraftFields), 1 /* TEXT */), _createElementVNode("p", _hoisted_1, _toDisplayString($data.translate.theFinalizedReportsWillServeAsAVersionedRecord), 1 /* TEXT */), _createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.importantOnceTheReportingYearIsClosed), 1 /* TEXT */), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.closeReportingYear,
    type: "save",
    class: "mt-6",
    onClick: $options.confirmClosing
  }, null, 8 /* PROPS */, ["text", "onClick"]))]);
}