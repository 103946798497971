// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-overview-actions[data-v-207032b6] {
  margin: 1rem 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.filter-wrap[data-v-207032b6] {
  display: flex;
}
.risks-empty[data-v-207032b6] {
  text-align: center;
}
.risks-empty[data-v-207032b6]  .risma-title > h3 {
  font-size: 27px;
  font-weight: 300;
  line-height: 1;
  padding-bottom: 10px;
  color: #347480;
}
.risks-empty .risks-empty-text[data-v-207032b6] {
  font-size: 16px;
  font-weight: 600;
  color: #4D4D4D;
}
.filter-wrap-view[data-v-207032b6]  .card-view-switcher {
  padding: 8px 0px;
}
.risk-list[data-v-207032b6] {
  display: flex;
  flex-wrap: wrap;
}
.risk-list[data-v-207032b6]  .preview-risk-mode-expanded {
  width: 100%;
}
.risk-list[data-v-207032b6]  .preview-risk-mode-compact {
  width: 100%;
}
.risk-list[data-v-207032b6]  .preview-risk-mode-tiles {
  width: 33%;
  padding: 0 1.5rem 1.5rem 0;
}
.risk-list[data-v-207032b6]  .preview-risk-mode-tiles:nth-child(3n) {
  padding-right: 0;
}
.risk-list .preview-risk-list-empty[data-v-207032b6] {
  width: 100%;
  padding: 1rem 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #4D4D4D;
  text-align: center;
}
.preview-risk-list-compact-header[data-v-207032b6] {
  display: flex;
  justify-content: flex-start;
  padding: 15px;
}
.compact-header-title[data-v-207032b6],
.compact-header-description[data-v-207032b6],
.compact-header-organisations[data-v-207032b6],
.compact-header-tags[data-v-207032b6],
.compact-header-approval-flow[data-v-207032b6] {
  color: #999;
  font-weight: 600;
  font-size: 16px;
  height: 20px;
}
.compact-header-title[data-v-207032b6] {
  min-width: 300px;
  margin-right: auto;
}
.compact-header-description[data-v-207032b6] {
  width: 250px;
  flex-shrink: 0;
}
.compact-header-organisations[data-v-207032b6] {
  width: 200px;
  flex-shrink: 0;
}
.compact-header-tags[data-v-207032b6] {
  width: 160px;
  flex-shrink: 0;
}
.compact-header-approval-flow[data-v-207032b6] {
  width: 160px;
  flex-shrink: 0;
}
.my-risks-list-tiles[data-v-207032b6] {
  display: flex;
  flex-wrap: wrap;
}
.my-risks-list-tiles[data-v-207032b6]  .preview-risk-mode-tiles {
  width: 33%;
  padding: 0 15px;
  margin-bottom: 24px;
}
[data-v-207032b6]  .static-table a {
  font-weight: 600;
}
[data-v-207032b6]  .static-table a:hover {
  text-decoration: underline;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
