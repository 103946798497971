import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_report_saving_modal = _resolveComponent("report-saving-modal");
  var _component_saved_reports = _resolveComponent("saved-reports");
  var _component_modal = _resolveComponent("modal");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_buttons_row = _resolveComponent("buttons-row");
  return _openBlock(), _createElementBlock("div", null, [$data.showSaveModal ? (_openBlock(), _createBlock(_component_report_saving_modal, {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showSaveModal = false;
    }),
    onSave: $options.saveReport
  }, null, 8 /* PROPS */, ["onSave"])) : _createCommentVNode("v-if", true), $data.showLoadModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.savedReports,
    "show-buttons": false,
    type: "big",
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showLoadModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_saved_reports, {
        type: $props.type,
        onLoad: $options.onLoadReport,
        onReportShared: $options.onReportShare,
        onReportDeleted: $options.onReportDelete
      }, null, 8 /* PROPS */, ["type", "onLoad", "onReportShared", "onReportDeleted"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])) : _createCommentVNode("v-if", true), _createVNode(_component_buttons_row, {
    data: $options.buttonsRowData
  }, {
    "button-folder": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "folder",
        class: "stroke-2 mr-1"
      })];
    }),
    "button-save": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "save",
        class: "stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])]);
}