function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getTranslate } from './translate';
import * as RiskApi from '@/api/risk/risk';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RiskAdminUserGuide from '@/components/Organisms/RiskAdmin/RiskAdminUserGuide';
import { RiskStatusText } from '@/constants/risks/RiskStatus';
import { isLockedRiskUser } from '@/utils/access';
export default {
  name: 'RiskAdminPeriods',
  token: '<risk-admin-periods></risk-admin-periods>',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RiskAdminUserGuide: RiskAdminUserGuide
  },
  props: {
    filteredRiskProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: ''
    },
    projectId: {
      type: [String, Number],
      required: true,
      note: ''
    }
  },
  emits: ['refreshRiskProjects'],
  data: function data() {
    return {
      idToDelete: null,
      cellCssStyles: 'text-left border-b border-gray-450 p-2',
      translate: getTranslate['RiskAdminPeriods']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isLocked: function isLocked() {
      return isLockedRiskUser(this.currentUser);
    },
    project: function project() {
      var _this = this;
      return this.filteredRiskProjects.find(function (project) {
        return +project.id === +_this.projectId;
      }) || {};
    },
    columns: function columns() {
      return [this.translate.title, this.translate.risks, this.translate.status];
    },
    dataset: function dataset() {
      if (!this.project.projectPeriods) return [];
      var periods = this.getCorrectOrder(this.project.projectPeriods);
      return this.prepareDataset(periods);
    },
    entityMainTitle: function entityMainTitle() {
      return "".concat(this.project.title, " ").concat(this.translate.periods);
    }
  }),
  methods: {
    getCorrectOrder: function getCorrectOrder(data) {
      var result = _toConsumableArray(data).reverse();
      result.map(function (item, index) {
        if (item.isCurrent) {
          result.splice(index, 1);
          result.unshift(item);
        }
      });
      return result;
    },
    prepareDataset: function prepareDataset(data) {
      var _this2 = this;
      return data.map(function (item) {
        return _this2.createDataItem(item);
      });
    },
    createDataItem: function createDataItem(item) {
      var result = {
        id: item.id,
        title: item.title,
        currentState: this.$trans(RiskStatusText[item.status] || ''),
        risks: item.riskCount,
        status: item.status,
        url: this.prepareDetailUrl(item.id)
      };
      return result;
    },
    prepareDetailUrl: function prepareDetailUrl(path) {
      return "/settings/riskadmin/periods/".concat(this.project.id, "/").concat(path);
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      return RiskApi.deletePeriod(this.project.id, this.idToDelete).then(function () {
        _this3.$emit('refreshRiskProjects');
      }).catch(function (e) {
        throw e;
      });
    },
    deleteEntity: function deleteEntity(id) {
      var _this4 = this;
      this.idToDelete = id;
      this.$confirm(this.translate.deleteRiskPeriod, this.translate.areYouSureYouWantToDeleteThisRiskPeriod, function (res) {
        return res && _this4.onDeleteConfirm();
      });
    }
  }
};