function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { toLocalDateTime } from '@/utils/date';
import { deleteReport, getReports } from '@/api/compliance/reports/outsourcing';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import * as Utils from '@/utils/Utils';
export default {
  name: 'ReportList',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    nodeId: {
      type: [Number, String],
      required: false,
      default: null
    },
    reportId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  emits: ['error'],
  data: function data() {
    return {
      idToDelete: null,
      toLocalDateTime: toLocalDateTime,
      reports: [],
      translate: getTranslate['ReportList']()
    };
  },
  computed: _objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.fetchReports()]);
    },
    fetchReports: function fetchReports() {
      var _this = this;
      getReports(this.projectId, this.nodeId).then(function (response) {
        _this.reports = response.list;
        var isListRoute = _this.$route.name === 'OutsourcingReportList';
        if (!response.list.length && isListRoute) _this.$router.push({
          name: 'OutsourcingReportCreate'
        });
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      deleteReport(this.projectId, this.nodeId, this.idToDelete).then(this.fetchReports);
    },
    deleteReport: function deleteReport(reportId) {
      var _this2 = this;
      this.idToDelete = reportId;
      this.$confirm(this.translate.deleteReport, this.translate.areYouSureYouWantToDeleteThisReport, function (res) {
        return res && _this2.onDeleteConfirm();
      });
    },
    getUserNameById: function getUserNameById(id) {
      return Utils.findPropById(id, this.users, 'display_name');
    }
  }
};