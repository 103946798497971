import { ObjectTypes } from '@/constants/ObjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { MODULES } from '@/constants/modules';
export function userHasAccessToComplianceProjects(user, settingValue) {
  var isComplianceOn = settingValue === 1;
  var userHasComplianceAccess = user.projects && user.projects.some(function (project) {
    return project.objType === ObjectTypes.COMPLIANCE_PROJECT && project.access_level > UserLevels.NONE;
  });
  return !!(isComplianceOn && userHasComplianceAccess);
}
export function isLockedControlUser(user) {
  return user.level_c_locked === 1;
}
export function isLockedRiskUser(user) {
  return user.level_r_locked === 1;
}
export function isLockedActionUser(user) {
  return user.level_e_locked === 1;
}
export function isLockedIncidentUser(user) {
  return user.level_incidents_locked === 1;
}
export function isLockedContractUser(user) {
  return user.level_contracts_locked === 1;
}
export function isLockedProcessLibrary(user) {
  var _user$AccessLevels;
  return !!(user !== null && user !== void 0 && (_user$AccessLevels = user.AccessLevels) !== null && _user$AccessLevels !== void 0 && (_user$AccessLevels = _user$AccessLevels.PolicyManagement) !== null && _user$AccessLevels !== void 0 && _user$AccessLevels.locked);
}
export function userCanCreateProcessLibrary(user) {
  var _user$AccessLevels2;
  return !!(user !== null && user !== void 0 && (_user$AccessLevels2 = user.AccessLevels) !== null && _user$AccessLevels2 !== void 0 && (_user$AccessLevels2 = _user$AccessLevels2.PolicyManagement) !== null && _user$AccessLevels2 !== void 0 && _user$AccessLevels2.canCreate);
}
export function userCanDeleteProcessLibrary(user) {
  var _user$AccessLevels3;
  return !!(user !== null && user !== void 0 && (_user$AccessLevels3 = user.AccessLevels) !== null && _user$AccessLevels3 !== void 0 && (_user$AccessLevels3 = _user$AccessLevels3.PolicyManagement) !== null && _user$AccessLevels3 !== void 0 && _user$AccessLevels3.canDelete);
}
export function isBelowSuperUser(user, levelType) {
  return user[levelType] < UserLevels.SUPER;
}
export function checkControlsOrgAccess(user) {
  var userLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : UserLevels.SUPER;
  return !!(user.level_controls >= userLevel || user.level_admin);
}
export function userHasProjectAccess(user, projectId, projectType) {
  var userLevel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : UserLevels.NORMAL;
  return user.projects && user.projects.some(function (project) {
    return +project.id === +projectId && project.objType === projectType && project.access_level >= userLevel;
  });
}
export function userHasSomeProjectAccess(user, projectType) {
  var userLevel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  return user.projects && user.projects.find(function (project) {
    return project.enabled && project.objType === projectType && project.access_level >= userLevel;
  });
}
export function userHasControlAccess(user, control) {
  if (checkControlsOrgAccess(user, UserLevels.SUPER)) return true;
  var accessProps = ['responsible', 'review', 'escalation'];
  var userIds = control.userIds,
    dailyTeamIds = control.dailyTeamIds;
  return dailyTeamIds.users.find(function (id) {
    return +id === +user.id;
  }) || accessProps.some(function (prop) {
    return userIds[prop].find(function (id) {
      return +id === user.id;
    });
  });
}
export function userHasAccessByModule(user, module) {
  var _user$AccessLevels4;
  var userLevel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : UserLevels.NORMAL;
  var projectId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  if (user.is_admin || user.level_admin) return true;
  switch (module) {
    case MODULES.EXECUTION:
      return user['level_execution'] >= userLevel;
    case MODULES.CONTROLS:
      return user['level_controls'] >= userLevel;
    case MODULES.INCIDENTS:
      return user['level_incidents'] >= userLevel;
    case MODULES.CONTRACTS:
      return user['level_contracts'] >= userLevel;
    case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
      return ((_user$AccessLevels4 = user.AccessLevels) === null || _user$AccessLevels4 === void 0 || (_user$AccessLevels4 = _user$AccessLevels4.PolicyManagement) === null || _user$AccessLevels4 === void 0 ? void 0 : _user$AccessLevels4.level) >= userLevel;
    case MODULES.COMPLIANCE:
      return userHasProjectAccess(user, projectId, ObjectTypes.COMPLIANCE_PROJECT, userLevel);
    case MODULES.RISK:
      return userHasProjectAccess(user, projectId, ObjectTypes.RISK_PROJECT, userLevel);
    case MODULES.COMPLIANCE_PROCESS_TREE:
      return user['level_process_library'] >= userLevel;
    case MODULES.COMPLIANCE_IA_SYSTEM:
    case MODULES.COMPLIANCE_IA_PROCESSOR:
    case MODULES.COMPLIANCE_IA_CONTROLLER:
    case MODULES.COMPLIANCE_INFORMATION_ASSETS:
      return user['level_information_assets'] >= userLevel;
    default:
      return false;
  }
}