function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "border-l-2 border-r-2 border-transparent text-gray-650"
};
var _hoisted_2 = {
  class: "p-3"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 2
};
var _hoisted_6 = {
  class: "text-base font-semibold"
};
var _hoisted_7 = {
  key: 3
};
var _hoisted_8 = {
  key: 4
};
var _hoisted_9 = {
  class: "whitespace-nowrap"
};
var _hoisted_10 = {
  key: 5
};
var _hoisted_11 = {
  key: 6
};
var _hoisted_12 = {
  key: 7
};
var _hoisted_13 = ["href"];
var _hoisted_14 = {
  key: 8
};
var _hoisted_15 = {
  key: 9
};
var _hoisted_16 = ["src"];
var _hoisted_17 = {
  key: 10
};
var _hoisted_18 = {
  key: 11
};
var _hoisted_19 = {
  key: 12
};
var _hoisted_20 = ["innerHTML"];
var _hoisted_21 = {
  key: 13
};
var _hoisted_22 = {
  key: 14
};
var _hoisted_23 = {
  key: 15
};
var _hoisted_24 = ["title"];
var _hoisted_25 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$cellData$cont, _$props$cellData$cont2;
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_hierarchical_list = _resolveComponent("hierarchical-list");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_RismaButton = _resolveComponent("RismaButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.TableCellsTypes.TEXT === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString((_$props$cellData$cont = $props.cellData.content) !== null && _$props$cellData$cont !== void 0 ? _$props$cellData$cont : ''), 1 /* TEXT */)])) : $data.TableCellsTypes.PREVIEW_MODAL === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_preview_modal_link, {
    class: "text-base font-semibold hover:underline",
    activity: $props.cellData.activity,
    onDismissModal: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        icon: "confidential",
        title: $props.cellData.content,
        truncate: false,
        "display-icon": $props.cellData.activity.confidential
      }, null, 8 /* PROPS */, ["title", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])])) : $data.TableCellsTypes.TITLE === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString($props.cellData.content), 1 /* TEXT */)])) : $data.TableCellsTypes.DEADLINE_DATE === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("span", {
    class: "py-1 px-3 rounded-large font-semibold whitespace-nowrap",
    style: _normalizeStyle({
      color: $options.getCellColor($props.cellData),
      backgroundColor: "".concat($options.getCellColor($props.cellData), "10")
    })
  }, _toDisplayString($props.cellData.content), 5 /* TEXT, STYLE */)])) : $data.TableCellsTypes.DATE === $props.type || $data.TableCellsTypes.DATETIME === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString($options.localDate), 1 /* TEXT */)])) : [$data.TableCellsTypes.LINKS, $data.TableCellsTypes.LINK].includes($props.type) ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cellData.content, function (link, idx) {
    return _openBlock(), _createBlock(_component_preview_modal_link, {
      key: link.id,
      class: "text-base font-semibold hover:underline",
      activity: link,
      onDismissModal: _cache[1] || (_cache[1] = function ($event) {
        return _ctx.$emit('dismissModal');
      })
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", null, _toDisplayString(link.title + (idx < $props.cellData.content.length - 1 ? ', ' : '')), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
  }), 128 /* KEYED_FRAGMENT */))])) : $data.TableCellsTypes.LIST === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cellData.content, function (listItem, idx) {
    return _openBlock(), _createElementBlock("span", {
      key: idx
    }, _toDisplayString(listItem + (idx < $props.cellData.content.length - 1 ? ', ' : '')), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])) : $data.TableCellsTypes.ATTACHMENTS === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cellData.content, function (attachment, idx) {
    return _openBlock(), _createElementBlock("a", {
      key: idx,
      href: attachment.url,
      download: "",
      target: "_blank"
    }, _toDisplayString(attachment.title + (idx < $props.cellData.content.length - 1 ? ', ' : '')), 9 /* TEXT, PROPS */, _hoisted_13);
  }), 128 /* KEYED_FRAGMENT */))])) : $data.TableCellsTypes.TRAFFIC_LIGHT === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_14, [$props.cellData.content !== '' ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    color: $options.getCellColor($props.cellData),
    icon: $props.cellData.icon
  }, null, 8 /* PROPS */, ["color", "icon"])) : _createCommentVNode("v-if", true)])) : $data.TableCellsTypes.DOCUMENT === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("img", {
    src: $options.getDocumentIcon($props.cellData.docType),
    width: "35",
    height: "42",
    class: "cursor-pointer"
  }, null, 8 /* PROPS */, _hoisted_16)])) : $data.TableCellsTypes.HIERARCHICAL_LIST === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_hierarchical_list, {
    node: $props.cellData.activity,
    "show-children": true,
    "toggle-children": false,
    "has-preview-mode": true
  }, null, 8 /* PROPS */, ["node"])])) : $data.TableCellsTypes.BOOL === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString($props.cellData.content ? $data.translate.yes : $data.translate.no), 1 /* TEXT */)) : $data.TableCellsTypes.HTML === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("div", {
    innerHTML: (_$props$cellData$cont2 = $props.cellData.content) !== null && _$props$cellData$cont2 !== void 0 ? _$props$cellData$cont2 : ''
  }, null, 8 /* PROPS */, _hoisted_20)])) : $data.TableCellsTypes.DELETE_BUTTON === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("div", null, [$props.cellData.deletable ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "x",
    class: "stroke-2 text-red-50 cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('delete', {
        id: $props.cellData.id,
        title: $props.cellData.title
      });
    })
  })) : _createCommentVNode("v-if", true)])])) : $data.TableCellsTypes.STAR === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-3 cursor-pointer", $props.cellData.content ? 'text-green-150' : 'text-gray-650']),
    icon: "star",
    height: "40",
    width: "40",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('starred', {
        id: $props.cellData.id,
        starred: !$props.cellData.content
      });
    })
  }, null, 8 /* PROPS */, ["class"])])) : $data.TableCellsTypes.BUTTON === $props.type ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("div", null, [_createVNode(_component_RismaButton, {
    text: $props.cellData.content,
    type: "save",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.handleBackendEvent($props.cellData);
    })
  }, null, 8 /* PROPS */, ["text"])])])) : $data.TableCellsTypes.ICON === $props.type ? (_openBlock(), _createElementBlock("div", {
    key: 16,
    title: $props.cellData.textContent || '',
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('iconClicked', {
        id: $props.cellData.id,
        data: $props.cellData.content,
        type: $data.TableCellsTypes.ICON
      });
    })
  }, [_createElementVNode("div", {
    class: "w-fit p-1",
    style: _normalizeStyle({
      backgroundColor: $props.cellData.backgroundColor
    })
  }, [_createElementVNode("div", {
    style: _normalizeStyle({
      maskImage: "url('/assets/svg/".concat($props.cellData.svgName, ".svg')"),
      maskRepeat: 'no-repeat',
      maskSize: 'contain',
      backgroundColor: $props.cellData.color
    }),
    class: "w-10 h-6"
  }, null, 4 /* STYLE */)], 4 /* STYLE */)], 8 /* PROPS */, _hoisted_24)) : $data.TableCellsTypes.FEATHER_ICON === $props.type ? (_openBlock(), _createElementBlock("div", {
    key: 17,
    title: $props.cellData.content || ''
  }, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-3", [$props.cellData.class, {
      'cursor-pointer': $props.cellData.clickable
    }]]),
    icon: $props.cellData.name,
    width: "25",
    height: "25",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $props.cellData.clickable && _ctx.$emit('iconClicked', _objectSpread(_objectSpread({}, $props.cellData), {}, {
        type: $data.TableCellsTypes.FEATHER_ICON
      }));
    })
  }, null, 8 /* PROPS */, ["class", "icon"])], 8 /* PROPS */, _hoisted_25)) : _createCommentVNode("v-if", true)])]);
}