import PercentageBox from '@/components/Atoms/PercentageBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
export default {
  name: 'KpiCard',
  introduction: 'Component to show KPI per framework',
  description: 'Shows KPI for specific framework. Has the link to external lists of activities',
  token: "\n            <kpi-card\n                card-header=\"Unanswered questionnaires\"\n                module-title=\"Compliance\"\n                :current-value=\"25\"\n                :max-value=\"100\"\n                :kpi-value=\"25\"\n                color=\"#ce6696\"\n                extended-url=\"/\"\n                :weight=\"300\">\n                <template #description>\n                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, quam!\n                </template>\n                <template #details>\n                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non, tenetur.\n                </template>\n            </kpi-card>\n",
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    PercentageBox: PercentageBox
  },
  props: {
    cardHeader: {
      type: String,
      required: true
    },
    currentValue: {
      type: Number,
      required: false,
      default: 0
    },
    maxValue: {
      type: Number,
      required: false,
      default: 0
    },
    weight: {
      type: [Number, String],
      required: false,
      default: 0
    },
    color: {
      type: String,
      required: false,
      default: Colors.notSet
    },
    extendedUrl: {
      type: String,
      required: false,
      default: ''
    },
    moduleTitle: {
      type: String,
      required: false,
      default: ''
    },
    kpiValue: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: function data() {
    return {
      translate: {
        listOfUnansweredProcessingActivities: this.$trans('List of unanswered processing activities'),
        ratio: this.$trans('Ratio'),
        of: this.$trans('of'),
        weight: this.$trans('Weight')
      }
    };
  },
  computed: {
    ratioHtml: function ratioHtml() {
      return "".concat(this.translate.ratio, ": ").concat(this.getWrappedValue(this.currentValue), " ").concat(this.translate.of, " ").concat(this.getWrappedValue(this.maxValue));
    },
    weightHtml: function weightHtml() {
      return this.translate.weight + ": ".concat(this.getWrappedValue(this.weight + '%'));
    }
  },
  methods: {
    getWrappedValue: function getWrappedValue(value) {
      return "<span class=\"font-semibold\">".concat(value, "</span>");
    }
  }
};