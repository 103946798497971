import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  ContractArchiveModal: function ContractArchiveModal() {
    return {
      confirm: i18n.t('Confirm'),
      cancel: i18n.t('Cancel'),
      stateTheReasonForStatusChange: i18n.t('State the reason for status change'),
      expirationDate: i18n.t('Expiration date'),
      archiving: i18n.t('Archiving'),
      restoring: i18n.t('Restoring'),
      rememberToChangeStatusBeforeArchivingContrac: i18n.t('Remember to change status before archiving contract'),
      rememberToChangeStatusBeforeRestoringContrac: i18n.t('Remember to change status before restoring contract')
    };
  },
  ContractOutsourcingModal: function ContractOutsourcingModal() {
    return {
      addOutsourcingArrangement: i18n.t('Add outsourcing arrangement'),
      cancel: i18n.t('Cancel'),
      add: i18n.t('Add'),
      outsourcingProject: i18n.t('Outsourcing project'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder()
    };
  },
  ContractRenewModal: function ContractRenewModal() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      newStatus: i18n.t('New status'),
      newContractAmount: i18n.t('New contract amount'),
      contractAmount: i18n.t('Contract amount'),
      startDate: i18n.t('Start date'),
      expirationDate: i18n.t('Expiration date'),
      commitmentDeadline: i18n.t('Commitment deadline'),
      renewalDeadline: i18n.t('Renewal deadline'),
      renew: i18n.t('Renew'),
      paymentInterval: i18n.t('Payment interval'),
      paymentStartMonth: i18n.t('Payment start month'),
      choose: i18n.t('Choose')
    };
  },
  CvrModal: function CvrModal() {
    return {
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      phone: i18n.t('Phone'),
      email: i18n.t('Email'),
      city: i18n.t('City'),
      zipCode: i18n.t('Zipcode'),
      vatNumber: i18n.t('VAT number'),
      companyStatus: i18n.t('Company status'),
      dataFetchedFromPublicRegister: i18n.t('Data fetched from public register'),
      thisIsThePublicDataThatWasFetched: i18n.t('This is the public data that was fetched on:')
    };
  },
  DocumentTypeModal: function DocumentTypeModal() {
    return {
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      updateDocumentType: i18n.t('Update document type'),
      addDocumentType: i18n.t('Add document type'),
      title: i18n.t('Title'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      create: i18n.t('Create'),
      theDocumentTypeWithThisNameAlreadyExists: i18n.t('The document type with this name already exists'),
      enterTitle: getTitlePlaceholder()
    };
  },
  DpmSendQuestionnaireModal: function DpmSendQuestionnaireModal() {
    return {
      sendQuestionnaire: i18n.t('Send questionnaire'),
      send: i18n.t('Send'),
      cancel: i18n.t('Cancel'),
      selectQuestionnaire: i18n.t('Select questionnaire'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      isAnInvalidEmailAddress: i18n.t(' is an invalid email address.'),
      questionnaire: i18n.t('Questionnaire'),
      whenYouSendThisRequestToTheSelectedUser: i18n.t('When you send this request to the selected user, the user will receive an e-mail with a link and your comment. The questionnaire will be presented, when they enter the link in the e-mail. The link will only be active for as long as you have defined. From there the user will be able to answer the questionnaire and finally send the questionnaire back to you, where you will be notified via. e-mail.'),
      recipientName: i18n.t('Recipient Name'),
      enterTheNameOftheRecipient: i18n.t('Enter the name of the recipient'),
      recipientCompanyName: i18n.t('Recipient Company name'),
      enterTheNameOfTheCompany: i18n.t('Enter the name of the company'),
      recipientEmail: i18n.t('Recipient e-mail'),
      enterTheEmailAddressOfTheRecipient: i18n.t('Enter the e-mail address of the recipient'),
      deadline: i18n.t('Deadline'),
      comments: i18n.t('Comments'),
      youMustSelectADateBetween: i18n.t('You must select a date between /today/ and /past/'),
      anyCommentsWillBeIncludedInTheEmail: i18n.t('Any comments will be included in the email to the selected user')
    };
  },
  EditDashboardModal: function EditDashboardModal() {
    return {
      renameDashboard: i18n.t('Rename dashboard'),
      rename: i18n.t('Rename'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder()
    };
  },
  EditProjectModal: function EditProjectModal() {
    return {
      updateProject: i18n.t('Edit project'),
      enableRiskApproval: i18n.t('Enable risk approval'),
      allowConfidentialRisks: i18n.t('Allow confidential risks'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      displayRelevantThreatsOnAssessments: i18n.t('Display relevant threats on assessments'),
      enableAccumulatedRisk: i18n.t('Enable accumulated risk'),
      enableInherentVsResidualRisk: i18n.t('Enable inherent vs. residual risk'),
      defaultActivityType: i18n.t('Default activity type'),
      notSet: i18n.t('Not set'),
      enableMatrixInverted: i18n.t('Invert matrix axes'),
      enterTitle: getTitlePlaceholder()
    };
  },
  EditComplianceProjectModal: function EditComplianceProjectModal() {
    return {
      editComplianceProject: i18n.t('Edit compliance project'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      questionnaire: i18n.t('Questionnaire'),
      gapSchemaName: i18n.t('Gap schema name'),
      frameworkId: i18n.t('Framework'),
      activityType: i18n.t('Activity type'),
      enableRecommendedActivitiesForGaps: i18n.t('Enable recommended activities for gaps'),
      enableHeatMapsInGapSchema: i18n.t('Enable heat maps in gap schema'),
      enableGapTypes: i18n.t('Enable gap types'),
      riskPrGap: i18n.t('Risk pr. gap'),
      selectOne: i18n.t('Select one'),
      renameTitleOverallDescription: i18n.t('Rename title "Overall description"'),
      renameTitlePurpose: i18n.t('Rename title "Purpose"'),
      renameTitleProcessStatus: i18n.t('Rename title "Process status"'),
      overallDescriptionCannotBeEmpty: i18n.t('Overall description cannot be empty'),
      purposeCannotBeEmpty: i18n.t('Purpose cannot be empty'),
      processStatusCannotBeEmpty: i18n.t('Process status cannot be empty'),
      overallDescription: i18n.t('Overall description'),
      purpose: i18n.t('Purpose'),
      processStatus: i18n.t('Process status'),
      enterTitle: getTitlePlaceholder()
    };
  },
  FieldGroupsModal: function FieldGroupsModal() {
    return {
      changeSorting: i18n.t('Change sorting'),
      notGrouped: i18n.t('Not grouped'),
      groupName: i18n.t('Group name'),
      save: i18n.t('Save'),
      addNewGroup: i18n.t('Add new group')
    };
  },
  GapSchemaArticleModal: function GapSchemaArticleModal() {
    return {
      editArticle: i18n.t('Edit Article'),
      addArticle: i18n.t('Add article'),
      article: i18n.t('Article'),
      typeHere: i18n.t('Type here'),
      articleTitle: i18n.t('Article Title'),
      requirement: i18n.t('Requirement'),
      domain: i18n.t('Domain'),
      pressEnterToRemove: i18n.t('Press enter to remove'),
      selected: i18n.t('Selected'),
      pressEnterToSelect: i18n.t('Press enter to select'),
      flags: i18n.t('Flags'),
      cancel: i18n.t('Cancel'),
      attributes: i18n.t('Attributes'),
      valueShouldBeUnique: i18n.t('Value should be unique'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      createNewAttribute: i18n.t('Create new attribute'),
      save: i18n.t('Save'),
      create: i18n.t('Create'),
      addOption: i18n.t('Add option'),
      add: i18n.t('Add'),
      domainName: i18n.t('Domain name'),
      theDomainNameAlreadyExist: i18n.t('The domain name already exist'),
      createNewDomain: i18n.t('Create new domain')
    };
  },
  PdfReportEditModal: function PdfReportEditModal() {
    return {
      saveReport: i18n.t('Save report'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder(),
      subtitle: i18n.t('Subtitle'),
      enterSubtitle: i18n.t('Enter subtitle'),
      chooseBaseline: i18n.t('Choose baseline'),
      chooseManagementLevels: i18n.t('Choose management levels'),
      chooseExtraColumnsForOverviewTable: i18n.t('Choose extra columns for overview table'),
      chooseOrganisationsForReport: i18n.t('Choose organisations for report'),
      includePage2ForEachRisk: i18n.t('Include page 2 for each risk?'),
      includeNonapprovedRisksInReport: i18n.t('Include non-approved risks in report?'),
      chooseBackgroundImage: i18n.t('Choose background image'),
      baseline: i18n.t('Baseline'),
      managementLevels: i18n.t('Management levels'),
      organisations: i18n.t('Organisations'),
      pleaseFillOutThis: i18n.t('Please fill out this'),
      pleaseSelectOne: i18n.t('Please select one'),
      createPDFReport: i18n.t('Create PDF report'),
      editPDFReport: i18n.t('Edit PDF report'),
      areYouSure: i18n.t('Are you sure?'),
      allDataWillBeLost: i18n.t('All data will be lost')
    };
  },
  RiskCreationModal: function RiskCreationModal() {
    return {
      createRisk: i18n.t('Create risk'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder(),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      parent: i18n.t('Parent'),
      chooseParent: i18n.t('Choose') + ' ' + i18n.t('Parent').toLowerCase(),
      users: i18n.t('Users')
    };
  },
  SearchModal: function SearchModal() {
    return {
      searchResults: i18n.t('Search results'),
      initiatives: i18n.t('Actions'),
      controls: i18n.t('Controls'),
      risks: i18n.t('Risks'),
      processes: i18n.t('Processes'),
      incidents: i18n.t('Incidents'),
      contracts: i18n.t('Contracts'),
      noResultsFoundYouMayWantToWidenYourSearch: i18n.t('No results found. You may want to widen your search criteria')
    };
  },
  TagCreationModal: function TagCreationModal() {
    return {
      renameTag: i18n.t('Rename tag'),
      createTag: i18n.t('Create tag'),
      create: i18n.t('Create'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder(),
      save: i18n.t('Save'),
      tagAlreadyExists: i18n.t('Tag already exists'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  },
  ManagementReportEditModal: function ManagementReportEditModal() {
    return {
      create: i18n.t('Create'),
      save: i18n.t('Save'),
      title: i18n.t('Title'),
      cancel: i18n.t('Cancel'),
      createManagementReport: i18n.t('Create management report'),
      editManagementReport: i18n.t('Edit management report'),
      enterTitle: getTitlePlaceholder(),
      organisations: i18n.t('Organisations'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      filterRiskApproval: i18n.t('Filter risk approval'),
      onlyApprovedRisks: i18n.t('Only approved risks'),
      onlyNotApprovedRisks: i18n.t('Only unapproved risks'),
      riskApproval: i18n.t('Risk approval'),
      includeRisks: i18n.t('Include risks'),
      onlyParentRisks: i18n.t('Only parent risks'),
      onlyChildUnderlyingRisks: i18n.t('Only child (underlying) risks'),
      bothParentAndChildRisks: i18n.t('Both parent and child risks'),
      includeRelevantThreats: i18n.t('Include relevant threats'),
      includeComments: i18n.t('Include comments')
    };
  },
  ComplianceCreationModal: function ComplianceCreationModal() {
    return {
      createComplianceNode: i18n.t('Create compliance node'),
      enterTitle: getTitlePlaceholder(),
      name: i18n.t('Name'),
      clickToSelectDataControllers: i18n.t('Click to select') + ' ' + i18n.t('Data controllers').toLowerCase(),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      couldNotCreateNode: i18n.t('Could not create node'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      dataControllers: i18n.t('Data Controllers'),
      dataControllerHint: i18n.t('Data controllers of the processing activity')
    };
  },
  DataTypeLegalBasisCreationModal: function DataTypeLegalBasisCreationModal() {
    return {
      dataType: i18n.t('Data type'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      noDataTypesSelected: i18n.t('No data types selected'),
      legalBasis: i18n.t('Legal basis'),
      save: i18n.t('Save'),
      stakeholder: i18n.t('Stakeholder'),
      editLegalBasisAndStoragePeriod: i18n.t('Edit legal basis and storage period'),
      selectOne: i18n.t('Select one'),
      pleaseFillBothFieldsCorrectly: i18n.t('Please fill both fields correctly'),
      youHaveNotConfiguredYourQuestionnaire: i18n.t('You have not configured your questionnaire to map datatypes with legal basis or storage period. This is done on the data type questions when editing questions.')
    };
  },
  InboxModal: function InboxModal() {
    return {
      username: i18n.t('Username'),
      email: i18n.t('Email'),
      password: i18n.t('Password'),
      serverAddress: i18n.t('Server address'),
      serverPort: i18n.t('Server port'),
      service: i18n.t('Service type'),
      testConnection: i18n.t('Test connection'),
      create: i18n.t('Create'),
      update: i18n.t('Update')
    };
  },
  TrafficLightModal: function TrafficLightModal() {
    return {
      create: i18n.t('Create'),
      trafficLight: i18n.t('Traffic light'),
      legend: i18n.t('Label'),
      chooseModule: i18n.t('Choose module'),
      chooseColor: i18n.t('Choose') + ' ' + i18n.t('Traffic light color').toLowerCase(),
      labelIsEmpty: i18n.t('Label is empty'),
      moduleIsEmpty: i18n.t('Module') + ' ' + i18n.t('is empty'),
      chooseTextColor: i18n.t('Choose traffic light text color'),
      dismiss: i18n.t('Dismiss')
    };
  }
};