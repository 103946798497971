import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "tooltip-wrap mb-3"
};
var _hoisted_3 = {
  class: "mb-3 border p-1 w-80"
};
var _hoisted_4 = {
  class: "mb-3 border p-1 w-80"
};
var _hoisted_5 = {
  class: "mb-3 border p-1 w-80"
};
var _hoisted_6 = {
  class: "mb-3 border p-1 w-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tool_tip = _resolveComponent("tool-tip");
  var _directive_tooltip = _resolveDirective("tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_tool_tip, null, {
    default: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createTextVNode("isn\\'t testing wonderful?!")]);
    }),
    _: 1 /* STABLE */
  })]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [_createTextVNode(" Just text with v-tooltip (top arrow by default) ")]))), [[_directive_tooltip]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [_createTextVNode("Just text with v-tooltip:bottom")]))), [[_directive_tooltip, void 0, "bottom"]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, _cache[4] || (_cache[4] = [_createTextVNode(" Just text with v-tooltip:bottom.wide ")]))), [[_directive_tooltip, void 0, "bottom", {
    wide: true
  }]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, _cache[5] || (_cache[5] = [_createTextVNode(" v-tooltip:bottom.wide=\"Regular tooltip\" with static content ")]))), [[_directive_tooltip, 'Regular tooltip', "bottom", {
    wide: true
  }]]), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "mb-3 border p-1 w-80 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.counter++;
    })
  }, _cache[6] || (_cache[6] = [_createTextVNode(" v-tooltip:bottom.wide=\"Regular tooltip\" with dynamic content ")]))), [[_directive_tooltip, 'Regular tooltip ' + $data.counter, "bottom", {
    wide: true
  }]])]);
}