export var companyTypes = {
  x12: 'Credit institutions',
  x599: 'Investment firms',
  x643: 'Central counterparties (CCPs)',
  x639: 'Asset management companies',
  x301: 'account information service providers',
  x302: 'electronic money institutions',
  x303: 'crypto-asset service providers',
  x304: 'central security depository',
  x305: 'trading venues',
  x306: 'trade repositories',
  x300: 'payment institution',
  x316: 'other financial entity',
  x315: 'securitisation repository',
  x314: 'crowdfunding service providers',
  x313: 'administrator of critical benchmarks',
  x312: 'credit rating agency',
  x311: 'institutions for occupational retirement provision',
  x320: 'insurance intermediaries, reinsurance intermediaries and ancillary insurance intermediaries',
  x309: 'insurance and reinsurance undertakings',
  x308: 'data reporting service providers',
  x307: 'managers of alternative investment funds',
  x318: 'Non-financial entity: Other than ICT intra-group service provider',
  x317: 'Non-financial entity: ICT intra-group service provider',
  x310: 'issuers of asset-referenced tokens'
};
export var companyHierarchyTypes = {
  x53: 'Ultimate parent',
  x551: 'Parent other than ultimate parent',
  x56: 'Subsidiary',
  x21: 'Entities other than entities of the group',
  x210: 'Outsourcing'
};