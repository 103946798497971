import { Http } from '../index';
import { enhanceIncidentVisibility } from '@/utils/incident';
import { handleRequestResponse } from '../httpUtils';
export function getMy() {
  return handleRequestResponse(Http.get('/incidents/my')).then(function (data) {
    return enhanceIncidentVisibility(data.list);
  });
}
export function getAll() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get('/incidents/incident' + params)).then(function (data) {
    return enhanceIncidentVisibility(data.list);
  });
}
export function getTable(params) {
  return handleRequestResponse(Http.get('incidents/incident?' + params));
}
export function getSingle(id) {
  return handleRequestResponse(Http.get("/incidents/incident/".concat(id))).then(function (data) {
    return enhanceIncidentVisibility(data);
  });
}
export function getArchived() {
  return handleRequestResponse(Http.get('/incidents/archive')).then(function (data) {
    return enhanceIncidentVisibility(data.list);
  });
}
export function create(incident) {
  return handleRequestResponse(Http.post('/incidents/incident', incident)).then(function (data) {
    return enhanceIncidentVisibility(data);
  });
}
export function update(incident) {
  return handleRequestResponse(Http.patch("/incidents/incident/".concat(incident.id), incident)).then(function (data) {
    return enhanceIncidentVisibility(data);
  });
}
export function remove(incidentId) {
  return handleRequestResponse(Http.delete("/incidents/incident/".concat(incidentId)));
}
export function getIncidentDashboardsOverview(queryString) {
  return handleRequestResponse(Http.get("/incidents/dashboards/overview?".concat(queryString))).then(function (data) {
    return enhanceIncidentVisibility(data);
  });
}
export function getAnonymousIncidentActivityType() {
  return handleRequestResponse(Http.get('/incidents/anonymous_activity_type'));
}
export function createAnonymousIncident(incident) {
  return handleRequestResponse(Http.post('/incidents/anonymous', incident));
}