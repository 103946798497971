import i18n from '@/i18n';
export var getTranslate = {
  RismaDateRangePicker: function RismaDateRangePicker() {
    return {
      apply: i18n.t('Apply'),
      customRange: i18n.t('Custom range')
    };
  },
  RismaFileUpload: function RismaFileUpload() {
    return {
      selectFile: i18n.t('Select file'),
      dragAndDropOr: i18n.t('Drag and drop or '),
      browse: i18n.t('browse'),
      weSupport: i18n.t('We support'),
      chooseFile: i18n.t('Choose file'),
      chooseFiles: i18n.t('Choose files'),
      noFileChosen: i18n.t('No file chosen'),
      files: i18n.t('Files'),
      uploadErrorFileMightBeTooLargeToUpload: function uploadErrorFileMightBeTooLargeToUpload(limit) {
        return i18n.t('Upload error. The file exceeds the upload limit of %s', [limit]);
      },
      pleaseChooseTheFilesWithCorrectFiletypes: i18n.t('Please choose a supported file type'),
      oneOfTheFilesYouAreTryingToUploadIsEmpty: i18n.t('One of the files you are trying to upload is empty. Please upload something more substantial.')
    };
  },
  RismaSelect: function RismaSelect() {
    return {
      warning: i18n.t('Warning'),
      youAreTryingToSelectAllItems: i18n.t('You are trying to select all items'),
      selectAll: i18n.t('Select all'),
      noElementsFoundConsiderChangingTheSearchQu: i18n.t('No elements found. Consider changing the search query.'),
      listIsEmpty: i18n.t('List is empty'),
      pressEnterToRemove: i18n.t('Press enter to remove'),
      pressEnterToSelect: i18n.t('Press enter to select'),
      selected: i18n.t('Selected'),
      areYouSureYouWantToAdd: i18n.t('Are you sure you want to add'),
      newItems: i18n.t('new items')
    };
  }
};