function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { updateApprovalStatus } from '@/api/risk/risk';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { toLocalDate } from '@/utils/date';
import * as Utils from '@/utils/Utils';
var SELECT_VALUES = {
  all: 'all',
  user: 'user',
  organisation: 'organisation'
};
export default {
  name: 'ApprovalStatusWell',
  token: '<approval-status-well />',
  components: {
    MainWell: MainWell,
    NoSearchResults: NoSearchResults,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable
  },
  props: {
    risks: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['approved'],
  data: function data() {
    return {
      mainFilter: '',
      selectedUser: '',
      selectedOrganisation: '',
      notificationType: 'error',
      timer: null,
      translate: getTranslate['ApprovalStatusWell']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    columns: function columns() {
      return [this.translate.title, this.translate.primaryOwner, this.translate.organisation, this.translate.approvalDate];
    },
    filteredRisks: function filteredRisks() {
      if (this.selectedUser) return this.filterRiskByUserId(this.risksApproved, this.selectedUser);
      if (this.selectedOrganisation) return this.filterRiskByOrganisationId(this.risksApproved, this.selectedOrganisation);
      if (this.mainFilter === SELECT_VALUES.all) return this.risksApproved;
      return [];
    },
    dataset: function dataset() {
      var _this = this;
      return this.filteredRisks.map(function (risk) {
        return {
          title: risk.title,
          primaryOwner: Utils.findValueById(_this.users, +risk[RiskBaseModelProps.USER_IDS][RiskBaseModelProps.ACCOUNTABLE], 'display_name'),
          organisation: Utils.findPropsByIds(risk[RiskBaseModelProps.ORGANISATION_IDS], _this.organisations, 'visible_name'),
          approvalDate: toLocalDate(risk.approvedDate)
        };
      });
    },
    isUsersSelectorShown: function isUsersSelectorShown() {
      return this.mainFilter === SELECT_VALUES.user;
    },
    isOrganisationsSelectorShown: function isOrganisationsSelectorShown() {
      return this.mainFilter === SELECT_VALUES.organisation;
    },
    showTable: function showTable() {
      if (this.mainFilter === SELECT_VALUES.all) return true;
      return this.mainFilter !== SELECT_VALUES.all && (this.selectedUser || this.selectedOrganisation);
    },
    options: function options() {
      return [{
        label: this.translate.all,
        value: SELECT_VALUES.all
      }, {
        label: this.translate.user,
        value: SELECT_VALUES.user
      }, {
        label: this.translate.organisation,
        value: SELECT_VALUES.organisation
      }];
    },
    usersOptions: function usersOptions() {
      return this.users.map(function (user) {
        return {
          label: user.display_name,
          value: user.id
        };
      });
    },
    organisationsOptions: function organisationsOptions() {
      return this.organisations.map(function (organisation) {
        return {
          label: organisation.name,
          value: organisation.id
        };
      });
    },
    risksApproved: function risksApproved() {
      return this.risks.filter(function (risk) {
        return risk.approved;
      });
    }
  }),
  methods: {
    filterRiskByUserId: function filterRiskByUserId(risks, userId) {
      return risks.filter(function (risk) {
        return +risk.userIds.accountable === +userId;
      });
    },
    filterRiskByOrganisationId: function filterRiskByOrganisationId(risks, organisationId) {
      var orgIdString = organisationId.toString();
      return risks.filter(function (risk) {
        return risk.organisationIds.indexOf(orgIdString) !== -1;
      });
    },
    handleClear: function handleClear() {
      var _this2 = this;
      var riskIds = this.filteredRisks.map(function (risk) {
        return risk.id;
      });
      updateApprovalStatus({
        riskIds: riskIds,
        approve: false
      }).then(function () {
        var successMessage = _this2.getSuccessNotification(_this2.filteredRisks.length);
        _this2.resetFilters();
        _this2.$notify({
          title: successMessage,
          type: 'success'
        });
        _this2.$emit('approved', riskIds);
      });
    },
    resetAdditionalFilter: function resetAdditionalFilter() {
      this.selectedUser = '';
      this.selectedOrganisation = '';
    },
    resetFilters: function resetFilters() {
      this.mainFilter = '';
      this.resetAdditionalFilter();
    },
    getSuccessNotification: function getSuccessNotification(number) {
      return "".concat(number, " ").concat(this.translate.risksHadTheirApprovalRemoved);
    }
  }
};