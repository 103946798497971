import QuickEditField from '@/components/Molecules/QuickEditField';
export default {
  name: '',
  components: {
    QuickEditField: QuickEditField
  },
  data: function data() {
    return {
      text: 'Some text'
    };
  },
  methods: {
    handleUpdate: function handleUpdate(event) {
      // eslint-disable-next-line
      console.log(event);
    }
  }
};