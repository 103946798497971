function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, renderList as _renderList } from "vue";
var _hoisted_1 = {
  class: "flex relative min-h-content"
};
var _hoisted_2 = {
  key: 1,
  class: "flex flex-col w-screen max-w-screen-xxl xxl:mx-auto min-w-0"
};
var _hoisted_3 = {
  class: "expand-icon right-0 mt-auto mb-2"
};
var _hoisted_4 = {
  key: 2,
  class: "top-25px min-h-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_dep_header = _resolveComponent("dep-header");
  var _component_router_view = _resolveComponent("router-view");
  var _component_main_well = _resolveComponent("main-well");
  var _component_dep_relations = _resolveComponent("dep-relations");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$props.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $props.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_dep_header, _mergeProps({
    ref: "depHeader"
  }, $options.depHeaderProperties, {
    onUpdatingAttachments: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('updatingAttachments');
    }),
    onChangeTrafficLight: _cache[2] || (_cache[2] = function ($event) {
      return $options.propertyChanged({
        property: $props.depHeaderProps.trafficLightSettings.field,
        value: $event
      });
    }),
    onChangeId: _cache[3] || (_cache[3] = function ($event) {
      return $options.propertyChanged({
        property: $props.depHeaderProps.idField,
        value: $event
      });
    }),
    onChangeTitle: _cache[4] || (_cache[4] = function ($event) {
      return $options.propertyChanged({
        property: $props.depHeaderProps.titleField,
        value: $event
      });
    }),
    onSaveStatusClicked: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('saveStatusClicked');
    }),
    onGetActivityData: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('getActivityData');
    }),
    onChangeTab: $options.changeTab,
    onFileUpdate: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('fileUpdate', $event);
    })
  }), _createSlots({
    "status-transition": _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "status-transition")];
    }),
    _: 2 /* DYNAMIC */
  }, [$props.isLoaded && $props.isRelationEnabled ? {
    name: "dep-relations-control",
    fn: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
        class: "rounded-full p-1 bg-rGrey-100 border border-rGrey-500 hover:border-gray-510 active:border-gray-510 focus:outline-none w-10 h-10",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.toggleDepRelations && $options.toggleDepRelations.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: $options.iconName,
        class: "text-rGrey-800 inline-block stroke-2 w-6 h-6"
      }, null, 8 /* PROPS */, ["icon"])])])];
    }),
    key: "0"
  } : undefined]), 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["onChangeTab"]), _createVNode(_component_main_well, {
    class: "!border-t-0"
  }, {
    default: _withCtx(function () {
      return [!$options.isPreview ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      }, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), _mergeProps({
            data: $props.item,
            "save-status": $props.depHeaderProps.saveStatus,
            "read-only": $props.readOnly
          }, _ctx.$attrs, {
            onPropertyChanged: $options.propertyChanged,
            onGetActivityData: _cache[8] || (_cache[8] = function ($event) {
              return _ctx.$emit('getActivityData');
            }),
            onSaveStatusClicked: _cache[9] || (_cache[9] = function ($event) {
              return _ctx.$emit('saveStatusClicked');
            }),
            onTriggerAttachmentMethod: $options.triggerAttachmentMethod
          }), null, 16 /* FULL_PROPS */, ["data", "save-status", "read-only", "onPropertyChanged", "onTriggerAttachmentMethod"]))];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $options.isPreview && $data.currentRoute ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.currentRoute.component), _mergeProps({
        key: 1,
        data: $props.item,
        "save-status": $props.depHeaderProps.saveStatus,
        "read-only": $props.readOnly
      }, _objectSpread(_objectSpread({}, _ctx.$attrs), $options.previewComponentProps), {
        onPropertyChanged: $options.propertyChanged,
        onSaveStatusClicked: _cache[10] || (_cache[10] = function ($event) {
          return _ctx.$emit('saveStatusClicked');
        }),
        onGetActivityData: _cache[11] || (_cache[11] = function ($event) {
          return _ctx.$emit('getActivityData');
        }),
        onTriggerAttachmentMethod: $options.triggerAttachmentMethod
      }), null, 16 /* FULL_PROPS */, ["data", "save-status", "read-only", "onPropertyChanged", "onTriggerAttachmentMethod"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true), $props.isLoaded && $props.isRelationEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_dep_relations, {
    class: "-mr-6 ml-6 h-full",
    tabs: $options.filteredTabs,
    "local-storage-key": $props.localStorageKey,
    "is-expanded": $setup.depRelationsExpanded
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList(_ctx.$slots, function (index, name) {
    return {
      name: name,
      fn: _withCtx(function () {
        return [_renderSlot(_ctx.$slots, name)];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["tabs", "local-storage-key", "is-expanded"])])) : _createCommentVNode("v-if", true)]);
}