// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-up[data-v-592ac4fc]  input {
  border: 1px solid #dc3545;
}
.notification-up[data-v-592ac4fc]  input::-moz-placeholder {
  color: #dc3545;
}
.notification-up[data-v-592ac4fc]  input::placeholder {
  color: #dc3545;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
