import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "row"
};
var _hoisted_2 = {
  class: "col-7"
};
var _hoisted_3 = {
  class: "flex mt-2 justify-start"
};
var _hoisted_4 = {
  class: "w-3/10 pr-8"
};
var _hoisted_5 = {
  class: "flex py-2"
};
var _hoisted_6 = {
  class: "pr-2 items-center"
};
var _hoisted_7 = {
  class: "risks-underlying-toggle-right"
};
var _hoisted_8 = {
  class: "validation-error text-red-50"
};
var _hoisted_9 = {
  class: "risk-additional-filters"
};
var _hoisted_10 = {
  key: 0,
  class: "additional-selectors"
};
var _hoisted_11 = {
  class: "flex flex-wrap justify-start"
};
var _hoisted_12 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_13 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_14 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_15 = {
  class: "flex flex-wrap justify-start"
};
var _hoisted_16 = {
  key: 0,
  class: "risk-additional-selector w-3/10"
};
var _hoisted_17 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_18 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_19 = {
  class: "flex flex-wrap justify-start"
};
var _hoisted_20 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_21 = {
  class: "risk-additional-selector w-3/10"
};
var _hoisted_22 = {
  class: "risk-additional-selector w-3/10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_buttons_row = _resolveComponent("buttons-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $props.selectedFilters.periodId,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $props.selectedFilters.periodId = $event;
    }), _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('changePeriod', $props.selectedFilters);
    })],
    class: "js-period",
    title: $data.translate.period,
    options: $props.options.periods,
    "un-sorted": $data.unSorted
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "un-sorted"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_toggle, {
    modelValue: $props.selectedFilters.includeUnderlyingRisks,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $props.selectedFilters.includeUnderlyingRisks = $event;
    }),
    class: "js-underlying-risks"
  }, null, 8 /* PROPS */, ["modelValue"])]), _createElementVNode("div", _hoisted_7, _toDisplayString($data.translate.includeUnderlyingRisks), 1 /* TEXT */)])]), _createElementVNode("div", {
    class: _normalizeClass([{
      error: $data.riskAssessmentsValidation
    }, "w-3/10 pr-8"])
  }, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.riskAssessments,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $props.selectedFilters.riskAssessments = $event;
    }),
    class: "js-riskAssessments",
    title: $data.translate.riskAssessment,
    options: $props.options.riskAssessments,
    "selected-options": $props.selectedFilters.riskAssessments,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectRiskAssessment,
    "label-key": "label"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"]), _createElementVNode("span", _hoisted_8, _toDisplayString($data.riskAssessmentsValidation), 1 /* TEXT */)], 2 /* CLASS */), _cache[14] || (_cache[14] = _createElementVNode("div", {
    class: "w-3/10"
  }, null, -1 /* HOISTED */))]), _createElementVNode("div", _hoisted_9, [_createElementVNode("a", {
    href: "#",
    class: "expand-additional-filters",
    onClick: _cache[4] || (_cache[4] = _withModifiers(function ($event) {
      return _ctx.additionalVisible = !_ctx.additionalVisible;
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['transform duration-500 text-blue-750 cursor-pointer stroke-3 mr inline-block', {
      'rotate-180': _ctx.additionalVisible
    }])
  }, null, 8 /* PROPS */, ["class"]), _createElementVNode("span", null, _toDisplayString(_ctx.expandLinkText), 1 /* TEXT */)]), _ctx.additionalVisible ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.threats,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $props.selectedFilters.threats = $event;
    }),
    class: "js-threats",
    title: $data.translate.threat,
    options: $props.options.threats,
    "selected-options": $props.selectedFilters.threats,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectThreat,
    "label-key": "label"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.levelOfThreats,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $props.selectedFilters.levelOfThreats = $event;
    }),
    class: "js-level-of-threats",
    title: $data.translate.levelOfThreat,
    options: $props.options.levelOfThreats,
    "selected-options": $props.selectedFilters.levelOfThreats,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectLevelOfThreat,
    "label-key": "label"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.vulnerabilities,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $props.selectedFilters.vulnerabilities = $event;
    }),
    class: "js-vulnerabilities",
    title: $data.translate.vulnerability,
    options: $props.options.vulnerabilities,
    "selected-options": $props.selectedFilters.vulnerabilities,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectVulnerability,
    "label-key": "label"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])])]), _createElementVNode("div", _hoisted_15, [_ctx.isSystemsEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.systems,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $props.selectedFilters.systems = $event;
    }),
    class: "js-systems",
    title: $data.translate.linkedSystems,
    options: $props.options.systems,
    "selected-options": $props.selectedFilters.systems,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectSystem,
    "label-key": "name"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_17, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.responsible,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $props.selectedFilters.responsible = $event;
    }),
    class: "js-responsible",
    title: $data.translate.responsible,
    options: $props.options.users,
    "selected-options": $props.selectedFilters.responsible,
    placeholder: $data.translate.selectResponsible,
    "label-key": "display_name"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "placeholder"])]), _createElementVNode("div", _hoisted_18, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.tags,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return $props.selectedFilters.tags = $event;
    }),
    class: "js-tags",
    title: $data.translate.tags,
    options: $props.options.tags,
    "selected-options": _ctx.tagsSelected,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectTags,
    "label-key": "tag"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.organisationsList,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
      return $props.selectedFilters.organisationsList = $event;
    }),
    class: "js-organisations",
    title: $data.translate.organisations,
    "selected-options": $props.selectedFilters.organisationsList,
    options: $props.options.organisationsList,
    "un-sorted": $data.unSorted,
    "label-key": "visible_name",
    placeholder: $data.translate.selectOrganisations
  }, null, 8 /* PROPS */, ["modelValue", "title", "selected-options", "options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_21, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.parentRisks,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
      return $props.selectedFilters.parentRisks = $event;
    }),
    class: "js-parent-risks",
    title: $data.translate.parentRisk,
    options: $props.options.parentRisks,
    "selected-options": $props.selectedFilters.parentRisks,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectParentRisk,
    "label-key": "title"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])]), _createElementVNode("div", _hoisted_22, [_createVNode(_component_risma_select, {
    modelValue: $props.selectedFilters.activityTypes,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = function ($event) {
      return $props.selectedFilters.activityTypes = $event;
    }),
    class: "js-activity-types",
    title: $data.translate.activityType,
    options: $props.options.activityTypes,
    "selected-options": $props.selectedFilters.activityTypes,
    "un-sorted": $data.unSorted,
    placeholder: $data.translate.selectActivityType,
    "label-key": "label"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "selected-options", "un-sorted", "placeholder"])])])])) : _createCommentVNode("v-if", true)]), _createVNode(_component_buttons_row, {
    class: "justify-end pb-4 border-b-2 border-gray-250",
    data: $options.buttonsRowData
  }, null, 8 /* PROPS */, ["data"])])]);
}