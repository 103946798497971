// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-report-wrap[data-v-0a51a5a1] {
  min-height: 600px;
}
.risk-description[data-v-0a51a5a1] {
  margin: 0 0 1rem;
}
.risk-additional-selectors[data-v-0a51a5a1] {
  display: flex;
  justify-content: space-between;
  margin: 0 0 2rem;
}
.risk-additional-selector[data-v-0a51a5a1] {
  width: 31%;
}
.risk-additional-selector[data-v-0a51a5a1]  .risma-title {
  margin: 0 0 5px;
}
.validation-error[data-v-0a51a5a1] {
  color: #B03D00;
  margin: 0 0 0.5rem;
  padding: 4px 0 0;
  display: block;
}
[data-v-0a51a5a1]  .error .multiselect__tags,[data-v-0a51a5a1]  .error .custom-select-inner {
  border-color: #B03D00;
}
.risk-actions[data-v-0a51a5a1] {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 1rem;
  margin: -2rem 0 2rem;
  border-bottom: 1px solid #BEBEBE;
}
.expand-additional-filters[data-v-0a51a5a1] {
  font-style: italic;
  font-weight: 600;
  text-decoration: underline;
}
.additional-selectors[data-v-0a51a5a1] {
  padding: 1rem 0;
}
.risks-underlying-toggle[data-v-0a51a5a1] {
  padding: 7px 0 0;
  margin-right: 1rem;
}
.risks-underlying-toggle .risks-underlying-toggle-center[data-v-0a51a5a1] {
  display: inline-block;
  float: left;
  padding-right: 4px;
}
[data-v-0a51a5a1]  .multiselect__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #212529;
  padding: 0;
  margin: 0;
}
.reset-filters[data-v-0a51a5a1] {
  margin-bottom: 16px;
  font-size: 16px;
  cursor: pointer;
  color: #347480;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
