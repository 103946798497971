// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-button[data-v-d7e2600e]  button {
  width: 100%;
  justify-content: center;
  margin: 0;
}
.bow-tie-gray[data-v-d7e2600e] {
  background-color: #c4c4c4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
