import i18n from '@/i18n';
export var getTranslate = {
  SupervisorControls: function SupervisorControls() {
    return {
      trafficLight: i18n.t('Traffic light'),
      controls: i18n.t('Controls'),
      deadline: i18n.t('Deadline'),
      noRecordsMatchingTheFilter: i18n.t('No records matching the filter'),
      controlNumber: i18n.t('Control number'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      gaps: i18n.t('Gaps'),
      organisation: i18n.t('Organisation'),
      escalation: i18n.t('Escalation'),
      responsible: i18n.t('Responsible'),
      reviewers: i18n.t('Reviewers'),
      frequency: i18n.t('Frequency'),
      reviewDeadline: i18n.t('Review deadline'),
      controlStatus: i18n.t('Control status'),
      completed: i18n.t('Completed'),
      pending: i18n.t('Pending'),
      rejected: i18n.t('Rejected'),
      awaitingReview: i18n.t('Awaiting review'),
      reviews: i18n.t('Reviews'),
      reviewCompleted: i18n.t('Review completed'),
      pendingReview: i18n.t('Pending review'),
      pendingControls: i18n.t('Pending controls'),
      greenTrafficLight: i18n.t('Green traffic light'),
      yellowTrafficLight: i18n.t('Yellow traffic light'),
      redTrafficLight: i18n.t('Red traffic light'),
      rejectedControls: i18n.t('Rejected controls'),
      completedControls: i18n.t('Completed controls'),
      completedOnTime: i18n.t('Completed on time'),
      completedAfterDeadline: i18n.t('Completed after deadline')
    };
  },
  SupervisorDashboard: function SupervisorDashboard() {
    return {
      nothingToShow: i18n.t('Nothing to show'),
      editYourSearchCriteriasAboveToViewSearchRe: i18n.t('Adjust your search criterias to view search results'),
      noteUserDoesNotHaveAccessToSeeControlsOr: i18n.t('Note! User does not have access to see controls or actions'),
      actions: i18n.t('Actions'),
      controls: i18n.t('Controls'),
      dpoDashboard: i18n.t('DPO dashboard'),
      supervisorDashboard: i18n.t('Supervisor dashboard'),
      gaps: i18n.t('Gaps'),
      nodes: i18n.t('Nodes'),
      includeUnderlyingNodes: i18n.t('Include underlying nodes'),
      organisations: i18n.t('Organisations'),
      gapOverview: i18n.t('GAP overview'),
      overviewPrDomain: i18n.t('Overview pr domain'),
      tags: i18n.t('Tags')
    };
  },
  SupervisorInitiatives: function SupervisorInitiatives() {
    return {
      noRecordsMatchingTheFilter: i18n.t('No records matching the filter'),
      trafficLight: i18n.t('Traffic light'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      gaps: i18n.t('Gaps'),
      organisations: i18n.t('Organisations'),
      responsible: i18n.t('Responsible'),
      presentStatus: i18n.t('Present status'),
      target: i18n.t('Target'),
      startdeadline: i18n.t('Start/Deadline'),
      dependingOn: i18n.t('Depending on'),
      completionPercentage: i18n.t('Completion percentage')
    };
  }
};