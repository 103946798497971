function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'DocumentTypeModal',
  description: 'Document Type Modal page',
  components: {
    InputField: InputField,
    Modal: Modal
  },
  props: {
    documentType: {
      type: Object,
      require: false,
      default: function _default() {
        return {};
      }
    },
    allDocumentTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'accept'],
  data: function data() {
    return {
      title: '',
      isTitleValid: true,
      translate: getTranslate['DocumentTypeModal']()
    };
  },
  computed: {
    creatingMode: function creatingMode() {
      var _this$documentType;
      return !((_this$documentType = this.documentType) !== null && _this$documentType !== void 0 && _this$documentType.id);
    },
    header: function header() {
      return this.creatingMode ? this.translate.addDocumentType : this.translate.updateDocumentType;
    },
    okButtonText: function okButtonText() {
      return this.creatingMode ? this.translate.create : this.translate.save;
    },
    existingNames: function existingNames() {
      return this.allDocumentTypes.map(function (type) {
        return type.name;
      });
    },
    nameExists: function nameExists() {
      return this.existingNames.includes(this.title);
    },
    titleError: function titleError() {
      if (!this.title) return this.translate.titleCannotBeEmpty;
      if (this.nameExists) return this.translate.theDocumentTypeWithThisNameAlreadyExists;
      return '';
    }
  },
  mounted: function mounted() {
    var _this$documentType2;
    this.title = ((_this$documentType2 = this.documentType) === null || _this$documentType2 === void 0 ? void 0 : _this$documentType2.name) || '';
  },
  methods: {
    acceptModal: function acceptModal() {
      if (!this.titleError) {
        this.$emit('accept', _objectSpread(_objectSpread({}, this.documentType), {}, {
          name: this.title
        }));
      } else {
        this.isTitleValid = false;
      }
    }
  }
};