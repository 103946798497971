import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import { getTranslate } from './translate';
import { COMPANY_INTERNAL_PROPS } from '@/constants/companies/company';
export default {
  name: 'InternalSectionOverview',
  components: {
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    RismaLabel: RismaLabel
  },
  props: {
    selectedOptions: {
      required: true,
      type: Object
    },
    options: {
      required: true,
      type: Object
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      COMPANY_INTERNAL_PROPS: COMPANY_INTERNAL_PROPS,
      translate: getTranslate['InternalSectionOverview']()
    };
  },
  computed: {
    ictServiceProviderLabel: function ictServiceProviderLabel() {
      return "".concat(this.translate.ictServiceProvider, " (02.02.0030)");
    },
    functionIdentifierLabel: function functionIdentifierLabel() {
      return "".concat(this.translate.functionName, " (").concat(this.translate.functionIdentifier, ") (02.02.0050)");
    },
    typeOfIctServicesLabel: function typeOfIctServicesLabel() {
      return "".concat(this.translate.typeOfIctServices, " (02.02.0060)");
    },
    storageOfDataLabel: function storageOfDataLabel() {
      return "".concat(this.translate.storageOfData, " (02.02.0140)");
    },
    dataLocationLabel: function dataLocationLabel() {
      return "".concat(this.translate.dataLocation, " (02.02.0150)");
    },
    countryOfProvisionLabel: function countryOfProvisionLabel() {
      return "".concat(this.translate.ictServicesDelivered, " (02.02.0130)");
    },
    managementLocationLabel: function managementLocationLabel() {
      return "".concat(this.translate.managementLocation, " (02.02.0160)");
    },
    dataSensitivenessLabel: function dataSensitivenessLabel() {
      return "".concat(this.translate.dataSensitiveness, " (02.02.0170)");
    },
    relianceLevelLabel: function relianceLevelLabel() {
      return "".concat(this.translate.relianceLevel, " (02.02.0180)");
    },
    labelStyles: function labelStyles() {
      return ['mb-1 self-end', {
        'cursor-pointer': !this.readOnly
      }];
    },
    dataLocationError: function dataLocationError() {
      var _this$selectedOptions;
      if (!this.selectedOptions[COMPANY_INTERNAL_PROPS.STORAGE_OF_DATA]) return '';
      return !((_this$selectedOptions = this.selectedOptions[COMPANY_INTERNAL_PROPS.DATA_LOCATION]) !== null && _this$selectedOptions !== void 0 && _this$selectedOptions.length) ? this.translate.pleaseFillOutThisField : '';
    }
  },
  methods: {
    handleUpdate: function handleUpdate(property, value) {
      this.selectedOptions[property] = value;
      if (property === COMPANY_INTERNAL_PROPS.STORAGE_OF_DATA && !value) {
        this.selectedOptions[COMPANY_INTERNAL_PROPS.DATA_LOCATION] = [];
      }
      this.$emit('update', this.selectedOptions);
    },
    handleLabelClick: function handleLabelClick(refName) {
      var _this$$refs$refName;
      if (this.readOnly) return;
      (_this$$refs$refName = this.$refs[refName]) === null || _this$$refs$refName === void 0 || _this$$refs$refName.toggle();
    }
  }
};