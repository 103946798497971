// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame[data-v-b330dd06] {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem 1rem;
  margin-bottom: 2rem;
}
.gross-values[data-v-b330dd06] {
  display: flex;
}
.gross-date[data-v-b330dd06] {
  margin-left: 5px;
  padding-top: 15px;
}
.vertical-axis[data-v-b330dd06] {
  margin-right: 30px;
  margin-left: auto;
  min-width: 200px;
}
.horizontal-axis[data-v-b330dd06] {
  min-width: 200px;
}
.tolerance-value[data-v-b330dd06] {
  display: flex;
}
.tolerance-gap-title[data-v-b330dd06] {
  position: absolute;
}
.tolerance-gap-table th[data-v-b330dd06] {
  text-align: center;
  padding: 12px;
  border-bottom: 1px solid #BEBEBE;
}
.tolerance-gap-table th[data-v-b330dd06]:first-child {
  border-bottom: none;
}
.tolerance-gap-table td[data-v-b330dd06] {
  width: 230px;
  padding: 12px;
}
.tolerance-gap-table tr:last-child td[data-v-b330dd06] {
  border-top: 1px solid #BEBEBE;
}
.tolerance-gap-table tr:last-child td[data-v-b330dd06]:first-child {
  border-top: none;
}
.gap-table-value[data-v-b330dd06] {
  text-align: center;
}
.gap-table-risk-gap[data-v-b330dd06] {
  text-align: right;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
