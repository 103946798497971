// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-leave-active[data-v-11d4186e],
.slide-enter-active[data-v-11d4186e] {
  transition: var(--transition-duration);
}
.slide-enter-from[data-v-11d4186e],
.slide-leave-to[data-v-11d4186e] {
  transform: translate(100%, 0);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
