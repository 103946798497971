function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getSingleRiskProject } from '@/api/risk/risk';
import RiskGrid from '@/components/Organisms/RiskGrid';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { RiskGridMixin } from '@/mixins/RiskGridMixin';
export default {
  name: 'GapSchemaHeatmaps',
  description: 'this component uses in /compliance/${nodeId}/edit/${projectId}/gap',
  components: {
    SingleSelect: SingleSelect,
    RiskGrid: RiskGrid,
    RismaTitle: RismaTitle
  },
  mixins: [RiskGridMixin],
  inject: {
    isPreview: {
      default: false
    },
    depRelationEnabled: {
      default: false
    }
  },
  props: {
    riskProjectId: {
      type: [String, Number],
      required: true,
      default: null,
      note: 'Risk project id'
    },
    period: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current period'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of threats'
    },
    risks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of risks'
    }
  },
  data: function data() {
    return {
      currentRiskProject: {},
      consequenceId: '',
      threatsDots: {},
      risksDots: {},
      threatColor: 'black',
      gridBoxSize: 50,
      resizeTimer: null,
      translate: getTranslate['GapSchemaHeatmaps']()
    };
  },
  computed: {
    periodSelected: function periodSelected() {
      return this.period;
    },
    isErm: function isErm() {
      return this.currentRiskProject.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    consequenceOptions: function consequenceOptions() {
      return (this.period.consequenceRows || []).map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    consequenceSelected: function consequenceSelected() {
      var _this = this;
      return (this.period.consequenceRows || []).find(function (item) {
        return item.id === _this.consequenceId;
      }) || {};
    },
    matrices: function matrices() {
      return [{
        title: this.translate.systems,
        data: _objectSpread(_objectSpread({}, this.getMatrix(this.risksDots[this.consequenceId])), {}, {
          colorCodes: this.colorCodes
        })
      }, {
        title: this.translate.threats,
        data: _objectSpread(_objectSpread({}, this.getMatrix(this.threatsDots[this.consequenceId])), {}, {
          colorCodes: _defineProperty({}, this.threatColor, 'background-color: #000')
        })
      }];
    }
  },
  watch: {
    depRelationEnabled: function depRelationEnabled() {
      this.setGridBoxSize();
    }
  },
  mounted: function mounted() {
    this.getCurrentRiskProject().then(this.prepareDots);
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    getCurrentRiskProject: function getCurrentRiskProject() {
      var _this2 = this;
      if (!this.riskProjectId) return Promise.resolve({});
      return getSingleRiskProject(this.riskProjectId).then(function (project) {
        _this2.currentRiskProject = project;
      });
    },
    prepareDots: function prepareDots() {
      this.consequenceId = this.consequenceOptions[0] ? this.consequenceOptions[0].value : '', this.threatsDots[this.consequenceId] = this.prepareThreatsDots(this.threats, this.consequenceSelected.label);
      this.risksDots[this.consequenceId] = this.prepareRiskDots(this.risks);
      this.setGridBoxSize();
    },
    onConsequenceChanged: function onConsequenceChanged() {
      if (!this.threatsDots[this.consequenceSelected.id]) {
        this.threatsDots[this.consequenceSelected.id] = this.prepareThreatsDots(this.threats, this.consequenceSelected.label);
      }
      if (!this.risksDots[this.consequenceSelected.id]) {
        this.risksDots[this.consequenceSelected.id] = this.prepareRiskDots(this.risks);
      }
    },
    onWindowResize: function onWindowResize() {
      var _this3 = this;
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      }
      this.resizeTimer = setTimeout(function () {
        _this3.setGridBoxSize();
        _this3.resizeTimer = null;
      }, 50);
    },
    prepareThreatsDots: function prepareThreatsDots(threats, consequenceLabel) {
      var _this4 = this;
      var dots = [];
      threats.forEach(function (threat) {
        if (threat.assessments) {
          var assessment = threat.assessments.find(function (assessment) {
            return assessment.label === consequenceLabel;
          });
          if (assessment) {
            dots.push(_this4.getThreatDotValue(threat, assessment, _this4.threatColor));
          }
        }
      });
      return dots;
    },
    getThreatDotValue: function getThreatDotValue(threat, assessment, color) {
      var x = assessment.likelihood;
      var y = assessment.impact;
      if (this.matrixInverted) {
        var _ref = [y, x];
        x = _ref[0];
        y = _ref[1];
      }
      return {
        id: threat.id,
        color: color,
        title: threat.title,
        x: x,
        y: y,
        xBefore: 0,
        yBefore: 0
      };
    },
    prepareRiskDots: function prepareRiskDots(risks) {
      var _this5 = this;
      return risks.map(function (risk) {
        return _this5.getRiskDotValue(risk, _this5.statusColor);
      });
    },
    getRiskDotValue: function getRiskDotValue(risk, colorConfig) {
      var _this6 = this,
        _probability,
        _consequence;
      var consequence, probability;
      if (Array.isArray(risk[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES])) {
        consequence = risk[RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES].find(function (item) {
          return item.rowId === _this6.consequenceSelected.id;
        });
      }
      if (Array.isArray(risk[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES])) {
        probability = risk[RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES].find(function (item) {
          return item.rowId === _this6.consequenceSelected.id;
        });
      }
      var x = ((_probability = probability) === null || _probability === void 0 ? void 0 : _probability.column.value) || 0;
      var y = ((_consequence = consequence) === null || _consequence === void 0 ? void 0 : _consequence.column.value) || 0;
      if (this.matrixInverted) {
        var _ref2 = [y, x];
        x = _ref2[0];
        y = _ref2[1];
      }
      return {
        id: risk.rno,
        color: colorConfig[risk.gridStatus],
        title: risk.title,
        status: risk.status,
        x: x,
        y: y,
        xBefore: 0,
        yBefore: 0
      };
    },
    setGridBoxSize: function setGridBoxSize() {
      var displayWidthRatio = 420;
      var basePercentageQualifier = 10;
      var dynamicPercentageQualifier = 40;
      var heatmapNormalizeRatio = Math.max((displayWidthRatio - this.$el.clientWidth) / displayWidthRatio, 0);
      var auxiliaryRatio = 1;
      this.gridBoxSize = Math.floor(auxiliaryRatio * this.$el.clientWidth / (this.gridSizeX + 1)) * (100 - basePercentageQualifier - dynamicPercentageQualifier * heatmapNormalizeRatio) / 100;
    },
    dotHoverFunction: function dotHoverFunction(dot) {
      return dot.title;
    }
  }
};