import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  key: 3,
  id: "questionnaire-admin"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "w-80 mb-3"
};
var _hoisted_4 = ["href", "data-id", "data-method", "onClick"];
var _hoisted_5 = ["href"];
var _hoisted_6 = {
  class: "min-w-150px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_template_list = _resolveComponent("template-list");
  return _openBlock(), _createElementBlock("div", null, [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.showTitleModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.titleModalHeader,
    type: "small",
    "show-buttons": true,
    "ok-button-disabled": !$data.newTitle,
    "button-ok-text": $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.doConfirm,
    onDismiss: $options.closePopups
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.newTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newTitle = $event;
        }),
        modelModifiers: {
          trim: true
        },
        "focus-on-mount": true,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "ok-button-disabled", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.questionnaires,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), !$props.module ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: $data.questionnaireType,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.questionnaireType = $event;
    }),
    title: $data.translate.chooseQuestionnaireType,
    options: $options.questionnaireTypes,
    "un-sorted": true,
    placeholder: $data.translate.selectOne
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])])])) : _createCommentVNode("v-if", true), $data.saveChangesError ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: false,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.saveChangesError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.formattedData.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 2,
    columns: $options.columns,
    "dataset-table": $options.formattedData
  }, _createSlots({
    defaultGapSchema: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_single_select, {
        "model-value": slotData.schemaId,
        options: slotData.options,
        "un-sorted": true,
        placeholder: $data.translate.selectOne,
        "with-reset": true,
        class: "max-w-220px",
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.addDefaultGapSchema($event, slotData.questionnaireId);
        }
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder", "onUpdate:modelValue"])];
    }),
    created: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", _hoisted_6, _toDisplayString(slotData.created), 1 /* TEXT */)];
    }),
    enabled: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createVNode(_component_checkboxes, {
        "checkbox-size": 20,
        disabled: slotData.isDisabled,
        options: slotData.isChecked,
        "center-checkbox": true,
        onUpdated: function onUpdated($event) {
          return $options.handleEvents(slotData);
        }
      }, null, 8 /* PROPS */, ["disabled", "options", "onUpdated"])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.slotNames.eventFields, function (eventSlotName) {
    return {
      name: eventSlotName,
      fn: _withCtx(function (_ref4) {
        var slotData = _ref4.slotData;
        return [slotData.disableEvent ? (_openBlock(), _createElementBlock("div", {
          key: "event_div_".concat(eventSlotName)
        }, _toDisplayString(slotData.text), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("a", {
          key: "event_a_".concat(eventSlotName),
          href: slotData.href,
          "data-id": slotData.dataId,
          "data-method": slotData.dataMethod,
          class: "pointer-cursor",
          onClick: function onClick($event) {
            return $options.handleEvents(slotData);
          }
        }, [slotData.hasIcon ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: slotData.icon
        }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(slotData.text), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_4))];
      })
    };
  }), _renderList($data.slotNames.linkFields, function (slotName) {
    return {
      name: slotName,
      fn: _withCtx(function (_ref5) {
        var slotData = _ref5.slotData;
        return [_createElementVNode("a", {
          href: slotData.url,
          class: "pointer-cursor"
        }, [_createVNode(_component_feather_icon, {
          icon: slotData.icon
        }, null, 8 /* PROPS */, ["icon"])], 8 /* PROPS */, _hoisted_5)];
      })
    };
  }), _renderList($data.slotNames.routerLinkFields, function (routerSlotName) {
    return {
      name: routerSlotName,
      fn: _withCtx(function (_ref6) {
        var slotData = _ref6.slotData;
        return [slotData.disableRouterLink ? (_openBlock(), _createElementBlock("div", {
          key: "router_div_".concat(routerSlotName)
        })) : (_openBlock(), _createBlock(_component_router_link, {
          key: "router_key_".concat(routerSlotName),
          to: slotData.url
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(slotData.text), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset-table"])) : $data.questionnaireType ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 3,
    title: $data.translate.thereAreNoQuestionnairesOfTheSelectedType,
    type: "medium",
    class: "empty-message"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), !$props.module ? (_openBlock(), _createBlock(_component_template_list, {
    key: 4,
    type: "questionnaire",
    templates: $data.templates,
    "selected-type": $data.questionnaireType,
    class: "mt-6",
    onCreated: $options.getQuestionnaires
  }, null, 8 /* PROPS */, ["templates", "selected-type", "onCreated"])) : _createCommentVNode("v-if", true)]))]);
}