function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import RiskOverview from '@/components/Pages/Risks/RiskOverview';
import MyRisks from '@/components/Pages/Risks/MyRisks';
import RiskEntry from '@/components/Pages/Risks/RiskEntry';
import RiskDescription from '@/components/Pages/Risks/RiskDescription';
import LinksTab from '@/components/Organisms/LinksTab';
import Changelog from '@/components/Molecules/Changelog';
import QuestionnairePage from '@/components/Pages/Compliance/NodeEdit/QuestionnairePage';
import RiskReportsEntry from '@/components/Pages/Risks/RiskReportsEntry';
import RiskReportsRisks from '@/components/Pages/Risks/RiskReports/RiskReportsRisks';
import RiskReportsThreats from '@/components/Pages/Risks/RiskReportsThreats';
import RiskReportsVulnerabilities from '@/components/Pages/Risks/RiskReportsVulnerabilities';
import RiskReportAssessedThreats from '@/components/Pages/Risks/RiskReportAssessedThreats';
import RisksDashboard from '@/components/Pages/Risks/RisksDashboard';
import { RiskBaseUrls } from '@/constants/risks/RiskBaseUrls';
import { MODULES } from '@/constants/modules';
import { userHasRiskProjectAccess, userHasERMRiskProjectAccess } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
import PdfManagementReport from '@/components/Pages/Risks/RiskReports/PdfManagementReport';
import ManagementReport from '@/components/Pages/Risks/RiskReports/ManagementReport';
import { getReportOptionsFromQuery } from '@/utils/report';
import { useSettingsStore } from '@/Store/settingsStore';
export default (function () {
  return [{
    path: "".concat(RiskBaseUrls.projects, "/:projectId/overview"),
    name: 'RiskOverview',
    component: RiskOverview,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    }
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/periods/:periodId/risk/:path"),
    component: RiskEntry,
    props: function props(route) {
      return _objectSpread(_objectSpread({}, route.params), {}, {
        scoringId: route.query.scoringId
      });
    },
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    },
    children: [{
      path: 'description',
      component: RiskDescription,
      props: function props(route) {
        return _objectSpread(_objectSpread({}, route.params), {}, {
          scoringId: route.query.scoringId
        });
      }
    }, {
      path: 'links',
      component: LinksTab,
      props: {
        module: MODULES.RISK
      }
    }, {
      path: 'changelog',
      component: Changelog
    }, {
      path: 'questionnaire/:questionnaireId',
      component: QuestionnairePage,
      props: function props(route) {
        return {
          id: route.params.id,
          pageType: 'risk',
          questionnaireId: route.params.questionnaireId
        };
      },
      meta: {
        keyFunction: function keyFunction($route) {
          return 'questionnaire/' + $route.params.questionnaireId;
        }
      }
    }]
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/dashboard"),
    component: RisksDashboard,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    }
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/my-risks"),
    name: 'MyRisks',
    component: MyRisks,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL)
    }
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/reports"),
    name: 'Reports',
    component: RiskReportsEntry,
    props: true,
    meta: {
      userRightsFunction: userHasRiskProjectAccess(UserLevels.NORMAL),
      keyFunction: function keyFunction($route) {
        return $route.path;
      }
    },
    redirect: {
      name: 'RiskReportsRisks'
    },
    children: [{
      path: 'risks',
      name: 'RiskReportsRisks',
      props: function props(route) {
        var savedReportOptions = getReportOptionsFromQuery(route.query);
        return _objectSpread(_objectSpread({}, route.params), {}, {
          savedReportOptions: savedReportOptions
        });
      },
      component: RiskReportsRisks
    }, {
      path: 'threats',
      props: true,
      component: RiskReportsThreats
    }, {
      path: 'vulnerabilities',
      props: true,
      component: RiskReportsVulnerabilities
    }, {
      path: 'threatassessments',
      props: true,
      component: RiskReportAssessedThreats,
      meta: {
        userRightsFunction: function userRightsFunction() {
          return useSettingsStore().getValue('feature.custom_risk_assessment_threats');
        }
      }
    }]
  }, {
    path: "".concat(RiskBaseUrls.projects, "/:projectId/management-reports"),
    name: 'ManagementReport',
    component: ManagementReport,
    props: true,
    meta: {
      userRightsFunction: function userRightsFunction(to) {
        return userHasRiskProjectAccess(UserLevels.SUPER)(to) && useSettingsStore().getValue('feature.risk2_management_report');
      }
    }
  }, {
    path: "".concat(RiskBaseUrls.main, "/reports/pdfmain"),
    name: 'PdfManagementReport',
    component: PdfManagementReport,
    meta: {
      userRightsFunction: userHasERMRiskProjectAccess(UserLevels.PRIVILEGED)
    }
  }];
});