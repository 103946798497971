// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consequence-wrap.readonly th[data-v-4b667bdd],
.consequence-wrap.readonly td[data-v-4b667bdd] {
  border-color: #737373;
}
.consequence-wrap.readonly thead th[data-v-4b667bdd] {
  background: #ddddde;
  width: 150px;
  border-bottom: 2px solid #4D4D4D;
}
.consequence-wrap.readonly thead th[data-v-4b667bdd]:first-child {
  border-right: 2px solid #4D4D4D;
}
.consequence-wrap.readonly tbody td[data-v-4b667bdd] {
  background: #FFFFFF;
  text-align: center;
}
.consequence-wrap.readonly tbody td[data-v-4b667bdd]:first-child {
  width: 210px;
  font-weight: bold;
  text-align: left;
  border-right: 2px solid #4D4D4D;
}
.consequence-wrap.readonly tbody tr:nth-child(2n) td[data-v-4b667bdd] {
  background: #ddddde;
}
.consequence-wrap.readonly .readonly-cell-value[data-v-4b667bdd] {
  display: inline-block;
  border-radius: 4px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100px;
}
.consequence-wrap.readonly .readonly-cell-value.clickable[data-v-4b667bdd] {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.consequence-wrap.readonly .readonly-cell-value.active[data-v-4b667bdd] {
  background: #0e2954;
  color: #FFFFFF;
}
.consequence-table[data-v-4b667bdd] {
  position: relative;
  width: 100%;
}
.consequence-table th[data-v-4b667bdd],
.consequence-table td[data-v-4b667bdd] {
  padding: 4px;
  border: 1px solid #4D4D4D;
  background: #f4f4f4;
}
.consequence-table .thead-cell[data-v-4b667bdd] {
  text-align: center;
}
.consequence-table .thead-cell[data-v-4b667bdd]  h3 {
  font-size: 16px;
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
  word-break: keep-all;
}
.consequence-table .thead-cell .remove-column[data-v-4b667bdd] {
  position: absolute;
  margin: -54px 0 0 -7px;
}
.consequence-table .tbody-cell[data-v-4b667bdd] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.consequence-table .tbody-cell[data-v-4b667bdd]:first-child {
  padding-left: 10px;
}
.consequence-table .tbody-cell[data-v-4b667bdd]  .input-field input {
  min-height: auto;
  font-size: 14px;
  padding: 5px 8px;
  font-style: italic;
}
.consequence-table .tbody-cell-label[data-v-4b667bdd] {
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
