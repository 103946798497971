import ToolTip from '@/components/Atoms/Containers/ToolTip';
export default {
  name: '',
  components: {
    ToolTip: ToolTip
  },
  data: function data() {
    return {
      counter: 1
    };
  }
};