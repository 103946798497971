import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "questionnaire-admin-scoring"
};
var _hoisted_2 = {
  class: "actions"
};
var _hoisted_3 = {
  key: 2,
  class: "scoring-content"
};
var _hoisted_4 = {
  class: "main-list"
};
var _hoisted_5 = {
  class: "item-name"
};
var _hoisted_6 = {
  class: "question-type"
};
var _hoisted_7 = {
  class: "item-options"
};
var _hoisted_8 = {
  class: "option-name"
};
var _hoisted_9 = {
  class: "option-field"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_save_button = _resolveComponent("save-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_questionnaire_scoring_assessments = _resolveComponent("questionnaire-scoring-assessments");
  var _component_input_field = _resolveComponent("input-field");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.complianceAdministration
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_button, {
        text: $data.translate.back,
        type: "util",
        onClick: $options.backToPrevPage
      }, null, 8 /* PROPS */, ["text", "onClick"])]), $data.saveStatus ? (_openBlock(), _createBlock(_component_save_button, {
        key: 0,
        "save-status": $data.saveStatus,
        onSaveStatusClicked: _cache[0] || (_cache[0] = function ($event) {
          return $options.updateFromSaveButton(100);
        })
      }, null, 8 /* PROPS */, ["save-status"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $options.mainTitle,
        class: "main-title"
      }, null, 8 /* PROPS */, ["title"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$options.currentQuestionnaire && $data.currentAssessments ? (_openBlock(), _createBlock(_component_questionnaire_scoring_assessments, {
        key: 0,
        questionnaire: $options.currentQuestionnaire,
        assessments: $data.currentAssessments,
        onChanged: $options.onAssessmentsChanged
      }, null, 8 /* PROPS */, ["questionnaire", "assessments", "onChanged"])) : _createCommentVNode("v-if", true), _createElementVNode("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questions, function (item) {
        return _openBlock(), _createElementBlock("li", {
          key: item.id
        }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
          title: item.questionnumber + ' ' + item.question,
          type: "medium"
        }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", _hoisted_6, " (" + _toDisplayString($options.readableType(item.questionType)) + ") ", 1 /* TEXT */)]), _createElementVNode("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.optionDetails, function (option) {
          return _openBlock(), _createElementBlock("li", {
            key: option.id,
            class: "item-option"
          }, [_createElementVNode("div", _hoisted_8, _toDisplayString(option.label), 1 /* TEXT */), _createElementVNode("div", _hoisted_9, [_createVNode(_component_input_field, {
            modelValue: option.score,
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return option.score = $event;
            },
            maxlength: $data.maxlength,
            type: "text"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "maxlength"])])]);
        }), 128 /* KEYED_FRAGMENT */))])]);
      }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]);
}