export function getUsersFromIds(idList, users) {
  return filterAsset(users, idList);
}
export function getOrganisationsFromIds(idList, organisations) {
  return filterAsset(organisations, idList);
}
export function getInitiativesFromIds(idList, initiatives) {
  return filterAsset(initiatives, idList);
}
export function filterAsset(assetsList) {
  var selectedValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return assetsList.filter(function (asset) {
    var idAsInt = parseInt(asset.id);
    var idAsString = asset.id.toString();
    return selectedValues.indexOf(idAsInt) !== -1 || selectedValues.indexOf(idAsString) !== -1;
  });
}