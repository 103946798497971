import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
var BASE_URL = '/contracts/dashboard';
export function getSummaryContracts() {
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/summary")));
}
export function getStatusContracts() {
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/status")));
}
export function getRenewalContracts(data) {
  var params = data ? '?' + data : '';
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/renewal").concat(params)));
}
export function getExpiringContracts(data) {
  var params = data ? '?' + data : '';
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/expiring").concat(params)));
}
export function getCommitmentContracts(data) {
  var params = data ? '?' + data : '';
  return handleRequestResponse(Http.get("".concat(BASE_URL, "/commitment").concat(params)));
}
export function getContractsPayments(year, currency, id) {
  var url = "".concat(BASE_URL, "/payments?year=").concat(year, "&currency=").concat(currency);
  if (id) url += "&contractId=".concat(id);
  return handleRequestResponse(Http.get(url));
}