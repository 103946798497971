function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import i18n from '@/i18n';
import { calculateStartOfDateAsDate, calculateStartDateBeforeTodayAsDate, calculateEndDateAsDate, calculatePastEndDateAsDate, calculateFutureEndDateByMonthsAsDate } from '@/utils/date';
export var getBaseRanges = function getBaseRanges() {
  return [{
    text: i18n.t('Today'),
    range: [calculateStartOfDateAsDate('day'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last week'),
    range: [calculateStartDateBeforeTodayAsDate(6, 'days', 'day'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('This month'),
    range: [calculateStartOfDateAsDate('month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last month'),
    range: [calculateStartDateBeforeTodayAsDate(1, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last 3 months'),
    range: [calculateStartDateBeforeTodayAsDate(3, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last 6 months'),
    range: [calculateStartDateBeforeTodayAsDate(6, 'month', 'month'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('This year'),
    range: [calculateStartOfDateAsDate('year'), calculateEndDateAsDate('year')]
  }, {
    text: i18n.t('Year-to-date'),
    range: [calculateStartOfDateAsDate('year'), calculateEndDateAsDate('day')]
  }, {
    text: i18n.t('Last year'),
    range: [calculateStartDateBeforeTodayAsDate(1, 'year', 'year'), calculatePastEndDateAsDate(1, 'year', 'year')]
  }];
};
export var getRangesWithNextThreeMonths = function getRangesWithNextThreeMonths() {
  var baseRange = getBaseRanges();
  return [].concat(_toConsumableArray(baseRange), [{
    text: i18n.t('Next 3 months'),
    range: [calculateStartOfDateAsDate('day'), calculateFutureEndDateByMonthsAsDate(3)]
  }]);
};
export var DATES_LIMIT_RANGE = {
  START: new Date(0),
  END: new Date('2038-01-19 03:14:07Z')
};