import QuizQuestionnaire from '@/components/Organisms/Compliance/QuizQuestionnaire';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  components: {
    QuizQuestionnaire: QuizQuestionnaire,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      hasQuizQuestionnaire: false,
      quiz: {
        title: this.$trans('Questionnaire') + ' #1',
        questions: []
      }
    };
  }
};