function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import { Colors } from '@/Colors';
import { mapState, mapActions } from 'pinia';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { MODULES } from '@/constants/modules';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import Modal from '@/components/Molecules/Modal/Modal';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import OptionalFieldsTable from '@/components/Molecules/Admin/OptionalFieldsTable';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { IATitleMixin } from '@/mixins/IATitleMixin';
import { AdminTypesModalMixin } from '@/components/Organisms/Admin/AdminTypesModalMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { AdminCustomFieldsAndActivityTypesMixin } from '@/components/Organisms/Admin/AdminCustomFieldsAndActivityTypesMixin';
import { sortArrayOfObjectsByArrayOfValues } from '@/utils/sort';
import { getCustomFieldTypeText as _getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
import { DragOptions } from '@/constants/DragOptions';
import { ControlsEfficiencyTypes } from '@/constants/ControlFields';
import { UserLevels } from '@/constants/UserLevels';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
var customStates = {
  DONT_SHOW: 'dont-show',
  ACTIVE: 'active',
  REQUIRED: 'required',
  CONTROL: 'control',
  CONTROL_COMPLETION: 'control-completion'
};
export default {
  name: 'AdminActivityTypesModal',
  introduction: 'Modal with activity types in /business/admin',
  description: 'Modal for creation & edit activity types. Used together with AdminActivityTypesWell component',
  token: '<admin-activity-types-modal></admin-activity-types-modal>',
  components: {
    RismaToggle: RismaToggle,
    Modal: Modal,
    InputField: InputField,
    SingleSelect: SingleSelect,
    ButtonsRow: ButtonsRow,
    RismaTitle: RismaTitle,
    Checkboxes: Checkboxes,
    RadioButtons: RadioButtons,
    FrameworksSelector: FrameworksSelector,
    CollapsiblePanel: CollapsiblePanel,
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon,
    OptionalFieldsTable: OptionalFieldsTable,
    FeatherIcon: FeatherIcon,
    RismaSelect: RismaSelect
  },
  mixins: [AdminTypesModalMixin, IATitleMixin, ProcessLibraryTitleMixin, AdminCustomFieldsAndActivityTypesMixin],
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {},
      note: 'All activity types'
    },
    fields: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Optional and custom fields by module'
    },
    allQuestionnaires: {
      type: Array,
      required: false,
      default: function _default() {},
      note: 'All questionnaires for different modules'
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false
    },
    module: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['edited', 'created'],
  data: function data() {
    var _values;
    return {
      id: null,
      values: (_values = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_values, MODULES.EXECUTION, {
        optional: ['title'],
        custom: {}
      }), MODULES.INCIDENTS, {
        optional: [],
        custom: {}
      }), MODULES.COMPLIANCE, {
        optional: [],
        custom: {}
      }), MODULES.CONTROLS, {
        optional: [],
        custom: {}
      }), MODULES.RISK, {
        optional: [],
        custom: {}
      }), MODULES.COMPLIANCE_INFORMATION_ASSETS, {
        optional: [],
        custom: {}
      }), MODULES.COMPLIANCE_IA_CONTROLLER, {
        optional: [],
        custom: {}
      }), MODULES.COMPLIANCE_IA_PROCESSOR, {
        optional: [],
        custom: {}
      }), MODULES.COMPLIANCE_IA_SYSTEM, {
        optional: [],
        custom: {}
      }), MODULES.CONTRACTS, {
        optional: [],
        custom: {}
      }), _defineProperty(_values, MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
        optional: [],
        custom: {}
      })),
      controlsFieldsOptions: [{
        value: customStates.DONT_SHOW
      }, {
        value: customStates.CONTROL
      }, {
        value: customStates.CONTROL_COMPLETION
      }],
      DragOptions: DragOptions,
      colors: Colors,
      showModal: false,
      labelName: '',
      labelNameValidation: null,
      moduleType: '',
      defaultQuestionnaire: null,
      MODULES: MODULES,
      modulesToQuestionnaireType: _defineProperty(_defineProperty(_defineProperty({}, MODULES.INCIDENTS, QuestionnaireTypes.INCIDENT), MODULES.RISK, QuestionnaireTypes.RISK), MODULES.CONTRACTS, QuestionnaireTypes.CONTRACT),
      delegationLabels: {
        responsible: '',
        accountable: '',
        consulted: '',
        informed: ''
      },
      customStates: customStates,
      selectedFrameworkId: null,
      activityTypeTitleLength: 50,
      controlsEfficiencyType: ControlsEfficiencyTypes.default,
      ControlsEfficiencyTypes: ControlsEfficiencyTypes,
      controlsEfficiencyMandatory: false,
      incidentResponsibleUsers: [],
      translate: getTranslate['AdminActivityTypesModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, {
    frameworkIds: 'enabledFrameworks'
  })), mapState(useUsersStore, ['activeUsersWithLevelAccess'])), mapState(useFrameworksStore, {
    frameworkIds: 'enabledFrameworks'
  })), {}, {
    efficiencyAssessmentEnabled: function efficiencyAssessmentEnabled() {
      return !!this.getSettingValue('feature.controls_efficiency_enabled');
    },
    showCustomFields: function showCustomFields() {
      return this.moduleType === MODULES.INCIDENTS || this.moduleType === MODULES.RISK || this.moduleType === MODULES.COMPLIANCE_INFORMATION_ASSETS || this.moduleType === MODULES.COMPLIANCE_IA_CONTROLLER || this.moduleType === MODULES.COMPLIANCE_IA_PROCESSOR || this.moduleType === MODULES.COMPLIANCE_IA_SYSTEM || this.moduleType === MODULES.CONTRACTS || this.moduleType === MODULES.COMPLIANCE_PROCESS_TREE_NODE || this.moduleType === MODULES.EXECUTION || this.moduleType === MODULES.COMPLIANCE;
    },
    showDefaultQuestionnaireSelect: function showDefaultQuestionnaireSelect() {
      return this.moduleType === MODULES.INCIDENTS || this.moduleType === MODULES.RISK || this.moduleType === MODULES.CONTRACTS;
    },
    questionnaireOptions: function questionnaireOptions() {
      if (!this.showDefaultQuestionnaireSelect) return [];
      var typeId = this.modulesToQuestionnaireType[this.moduleType];
      return this.allQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === typeId;
      }).map(function (_ref) {
        var title = _ref.title,
          id = _ref.id;
        return {
          label: title,
          value: id
        };
      });
    },
    delegationLabelsDefault: function delegationLabelsDefault() {
      return {
        responsible: 'Responsible',
        accountable: 'Accountable',
        consulted: 'Consulted',
        informed: 'Informed'
      };
    },
    isContractModule: function isContractModule() {
      return this.moduleType === MODULES.CONTRACTS;
    },
    optionalFieldsFiltered: function optionalFieldsFiltered() {
      return this.fields.optionalFields[this.moduleType].filter(function (field) {
        return !field.hidden;
      });
    },
    customFieldsOptions: function customFieldsOptions() {
      var options = [{
        value: customStates.DONT_SHOW
      }, {
        value: customStates.ACTIVE
      }];
      if (this.hasRequiredCustomFields) options.push({
        value: customStates.REQUIRED
      });
      return options;
    },
    customFieldsIdsByModuleType: function customFieldsIdsByModuleType() {
      return this.fields.customFields[this.moduleType].map(function (item) {
        return item.id;
      });
    },
    controlsEfficiencyOptions: function controlsEfficiencyOptions() {
      return [{
        label: this.translate.disabled,
        value: ControlsEfficiencyTypes.default
      }, {
        label: this.translate.efficiencyAssessmentRed,
        value: ControlsEfficiencyTypes.efficiencyAssessment
      }, {
        label: this.translate.extendedEfficiencyAssessment,
        value: ControlsEfficiencyTypes.extendedEfficiencyAssessment
      }];
    },
    visibleToOptions: function visibleToOptions() {
      return [{
        label: this.translate.normalUser,
        value: 1
      }, {
        label: this.translate.privilegedUser,
        value: 2
      }, {
        label: this.translate.super,
        value: 3
      }, {
        label: this.translate.admin,
        value: 4
      }];
    },
    incidentResponsibleOptions: function incidentResponsibleOptions() {
      return this.activeUsersWithLevelAccess('level_incidents', UserLevels.NORMAL);
    },
    modalHeader: function modalHeader() {
      return this.isEditMode ? this.translate.updateActivityType : this.translate.createActivityType;
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.cancel,
        type: 'util',
        clickEvent: this.toggleModal
      }, {
        text: this.isEditMode ? this.translate.save : this.translate.create,
        type: 'save',
        clickEvent: this.isEditMode ? this.editActivity : this.createActivity
      }];
    },
    hasRequiredCustomFields: function hasRequiredCustomFields() {
      return this.moduleType === MODULES.CONTRACTS || this.moduleType === MODULES.INCIDENTS;
    }
  }),
  mounted: function mounted() {
    this.isEditMode ? this.onEditOpen(this.data) : this.onCreateOpen(this.module);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useActivityTypesStore, {
    editActivityType: 'update',
    createActivityType: 'add'
  })), {}, {
    onCreateOpen: function onCreateOpen(module) {
      this.beforeOpen();
      this.id = null;
      this.moduleType = module || this.moduleTypesOptions[0].value;
      this.controlsEfficiencyType = ControlsEfficiencyTypes.default;
      this.controlsEfficiencyMandatory = false;
      this.fillAllOptionalFields();
    },
    onEditOpen: function onEditOpen(data) {
      if (typeof this.values[data.module] == 'undefined') {
        alert('unknown module');
        return;
      }
      this.beforeOpen();
      if (data && Object.keys(data).length) {
        this.id = data.id;
        this.labelName = data.label;
        this.moduleType = data.module;
        this.defaultQuestionnaire = data.defaultQuestionnaireId;
        this.controlsEfficiencyType = data.controlsEfficiencyType;
        this.controlsEfficiencyMandatory = data.controlsEfficiencyMandatory;
        this.selectedFrameworkId = data.solutionId || null;
        this.delegationLabels.responsible = data.responsibleLabel || this.delegationLabels.responsible;
        this.delegationLabels.accountable = data.accountableLabel || this.delegationLabels.accountable;
        this.delegationLabels.consulted = data.consultedLabel || this.delegationLabels.consulted;
        this.delegationLabels.informed = data.informedLabel || this.delegationLabels.informed;
      }
      if (data.module === MODULES.INCIDENTS) {
        this.incidentResponsibleUsers = this.incidentResponsibleOptions.filter(function (_ref2) {
          var _data$responsibleUser;
          var id = _ref2.id;
          return (_data$responsibleUser = data.responsibleUsers) === null || _data$responsibleUser === void 0 ? void 0 : _data$responsibleUser.includes(id);
        });
      }
      if (data.module === MODULES.CONTROLS) {
        this.fillControlData(data);
        return;
      }
      this.fillActivityData(data);
      this.fillCustomFieldsData(data);
    },
    sortCustomFieldsByFields: function sortCustomFieldsByFields(customFields, fields) {
      var ids = fields.map(function (item) {
        return item.id;
      });
      return sortArrayOfObjectsByArrayOfValues(customFields, ids, 'id');
    },
    fillControlData: function fillControlData(data) {
      var _this = this;
      this.fields.customFields[MODULES.CONTROLS] = this.sortCustomFieldsByFields(this.fields.customFields[MODULES.CONTROLS], data.fields);
      this.fields.customFields[MODULES.CONTROLS].forEach(function (field) {
        var element = data.fields.find(function (element) {
          return element.id === field.id;
        });
        if (!element || !element.activityLinkModule) {
          _this.values[MODULES.CONTROLS].custom[field.id] = {
            activityLinkModule: customStates.DONT_SHOW,
            visibilityUserLevel: null
          };
          return;
        }
        _this.values[MODULES.CONTROLS].custom[field.id] = {
          activityLinkModule: element.activityLinkModule,
          visibilityUserLevel: element.visibilityUserLevel
        };
      });
    },
    fillCustomFieldsData: function fillCustomFieldsData(data) {
      var _this2 = this;
      this.fields.customFields[data.module] = this.sortCustomFieldsByFields(this.fields.customFields[data.module], data.fields);
      this.fields.customFields[data.module].forEach(function (field) {
        var element = data.fields.find(function (element) {
          return element.id === field.id;
        });
        if (!element) {
          _this2.values[data.module].custom[field.id] = {
            activityLinkModule: customStates.DONT_SHOW
          };
          return;
        }
        if (element.required && _this2.hasRequiredCustomFields) {
          _this2.values[data.module].custom[field.id] = {
            activityLinkModule: customStates.REQUIRED
          };
          return;
        }
        _this2.values[data.module].custom[field.id] = {
          activityLinkModule: customStates.ACTIVE
        };
      });
    },
    fillActivityData: function fillActivityData(data) {
      if (data.module === MODULES.CONTRACTS) {
        this.fields.optionalFields[data.module] = sortArrayOfObjectsByArrayOfValues(this.fields.optionalFields[data.module], data.optionalFields, 'value');
      }
      this.values[data.module].optional = data.optionalFields;
    },
    fillAllOptionalFields: function fillAllOptionalFields() {
      var _this3 = this;
      if (!this.fields.optionalFields) return;
      Object.keys(this.fields.optionalFields).forEach(function (moduleType) {
        _this3.values[moduleType].optional = _this3.fields.optionalFields[moduleType].map(function (item) {
          return item.value;
        });
      });
    },
    onChangeModuleType: function onChangeModuleType(e) {
      if (e) {
        this.moduleType = e;
      }
    },
    createActivity: function createActivity() {
      var _this4 = this;
      if (!this.validate() || this.lockButtons) return;
      this.lockButtons = true;
      var data = this.prepareData();
      return this.createActivityType(data).then(function () {
        return _this4.$emit('created');
      }).finally(function () {
        _this4.toggleModal();
        _this4.lockButtons = false;
      });
    },
    editActivity: function editActivity() {
      var _this5 = this;
      if (!this.validate() || this.lockButtons) return;
      this.lockButtons = true;
      var data = this.prepareData();
      this.editActivityType(this.id, data).then(function () {
        return _this5.$emit('edited');
      }).finally(function () {
        _this5.toggleModal();
        _this5.lockButtons = false;
      });
    },
    prepareData: function prepareData() {
      var customFields;
      switch (this.moduleType) {
        case MODULES.CONTROLS:
          customFields = this.convertControlCustomFields();
          break;
        default:
          customFields = this.convertCustomFields();
      }
      var customLabels = !this.isContractModule ? {} : {
        responsibleLabel: this.delegationLabels.responsible,
        accountableLabel: this.delegationLabels.accountable,
        consultedLabel: this.delegationLabels.consulted,
        informedLabel: this.delegationLabels.informed
      };
      var incidentResponsibleUserIds = this.moduleType !== MODULES.INCIDENTS ? {} : {
        responsibleUsers: this.incidentResponsibleUsers.map(function (_ref3) {
          var id = _ref3.id;
          return id;
        })
      };
      var framework = this.moduleType === MODULES.INCIDENTS ? {
        solutionId: this.selectedFrameworkId || null
      } : {};
      var requiredFields = this.convertRequiredFields();
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        label: this.labelName,
        module: this.moduleType,
        optionalFields: this.values[this.moduleType].optional,
        defaultQuestionnaireId: this.defaultQuestionnaire
      }, incidentResponsibleUserIds), customLabels), framework), {}, {
        customFields: customFields,
        requiredFields: requiredFields,
        controlsEfficiencyType: this.controlsEfficiencyType,
        controlsEfficiencyMandatory: this.controlsEfficiencyMandatory
      });
    },
    convertControlCustomFields: function convertControlCustomFields() {
      var _this6 = this;
      var result = [];
      var controlCustomFields = this.values[MODULES.CONTROLS].custom;
      Object.keys(this.values[MODULES.CONTROLS].custom).sort(function (a, b) {
        return _this6.customFieldsIdsByModuleType.indexOf(+a) - _this6.customFieldsIdsByModuleType.indexOf(+b);
      }).forEach(function (key) {
        var _controlCustomFields$;
        var activityLinkModule = ((_controlCustomFields$ = controlCustomFields[key]) === null || _controlCustomFields$ === void 0 ? void 0 : _controlCustomFields$.activityLinkModule) === customStates.DONT_SHOW ? '' : controlCustomFields[key].activityLinkModule;
        var visibilityUserLevel = controlCustomFields[key].visibilityUserLevel || 1;
        result.push({
          id: +key,
          activityLinkModule: activityLinkModule,
          visibilityUserLevel: visibilityUserLevel
        });
      });
      return result;
    },
    convertCustomFields: function convertCustomFields() {
      var _this7 = this;
      return Object.entries(this.values[this.moduleType].custom).sort(function (a, b) {
        return _this7.customFieldsIdsByModuleType.indexOf(+a[0]) - _this7.customFieldsIdsByModuleType.indexOf(+b[0]);
      }).reduce(function (acc, _ref4) {
        var _ref5 = _slicedToArray(_ref4, 2),
          id = _ref5[0],
          value = _ref5[1];
        if (value.activityLinkModule === customStates.ACTIVE || value.activityLinkModule === customStates.REQUIRED) {
          acc.push({
            id: +id
          });
        }
        return acc;
      }, []);
    },
    convertRequiredFields: function convertRequiredFields() {
      if (!this.hasRequiredCustomFields) return [];
      return Object.entries(this.values[this.moduleType].custom).filter(function (item) {
        return item[1].activityLinkModule === customStates.REQUIRED;
      }).map(function (_ref6) {
        var _ref7 = _slicedToArray(_ref6, 1),
          id = _ref7[0];
        return +id;
      });
    },
    validate: function validate() {
      var result = true;
      this.resetValidation();
      if (!this.labelName) {
        result = false;
        this.labelNameValidation = this.translate.pleaseFillOutThisField;
      }
      if (this.labelName.length > this.activityTypeTitleLength) {
        result = false;
        this.labelNameValidation = this.translate.maxLengthIs50Characters;
      }
      var isLabelExist = this.activityTypes.find(this.labelExistCheck);
      if (isLabelExist) {
        result = false;
        this.labelNameValidation = this.translate.theActivityTypeWithThisTitleAlreadyExists;
      }
      return result;
    },
    labelExistCheck: function labelExistCheck(activityType) {
      return activityType.module === this.moduleType && activityType.label === this.labelName && activityType.id !== this.id;
    },
    clearFields: function clearFields() {
      this.labelName = '';
      this.moduleType = '';
      this.values.execution.optional = ['title'];
      this.values.compliance.optional = [];
      this.incidentResponsibleUsers = [];
      Object.values(MODULES).map(this.resetCustomFieldsValuesByModule);
      this.resetDelegationLabels();
    },
    resetCustomFieldsValuesByModule: function resetCustomFieldsValuesByModule(moduleName) {
      var _this$fields$customFi,
        _this8 = this;
      (_this$fields$customFi = this.fields.customFields[moduleName]) === null || _this$fields$customFi === void 0 || _this$fields$customFi.forEach(function (field) {
        _this8.values[moduleName].custom[field.id] = {
          activityLinkModule: customStates.DONT_SHOW
        };
        if (moduleName === MODULES.CONTROLS) {
          _this8.values[moduleName].custom[field.id].visibilityUserLevel = null;
        }
      });
    },
    resetDelegationLabels: function resetDelegationLabels() {
      this.delegationLabels = _objectSpread({}, this.delegationLabelsDefault);
    },
    resetValidation: function resetValidation() {
      this.labelNameValidation = null;
    },
    handleBlur: function handleBlur(event) {
      if (!this.delegationLabels[event]) {
        this.delegationLabels[event] = this.delegationLabelsDefault[event];
      }
    },
    getCustomFieldTypeText: function getCustomFieldTypeText(fieldType) {
      return _getCustomFieldTypeText(fieldType);
    },
    onControlFieldsChange: function onControlFieldsChange(event, customFieldId) {
      if (event === customStates.DONT_SHOW) {
        this.values[MODULES.CONTROLS].custom[customFieldId].visibilityUserLevel = null;
      } else if (!this.values[MODULES.CONTROLS].custom[customFieldId].visibilityUserLevel) {
        this.values[MODULES.CONTROLS].custom[customFieldId].visibilityUserLevel = 1;
      }
    },
    applyVisibilityToAll: function applyVisibilityToAll(visibilityUserLevel) {
      var _this9 = this;
      Object.values(this.values.control.custom).forEach(function (cf) {
        cf.visibilityUserLevel = cf.activityLinkModule === _this9.customStates.DONT_SHOW ? null : visibilityUserLevel;
      });
    }
  })
};