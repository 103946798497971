function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "custom-fields-wrapper z-1"
};
var _hoisted_2 = {
  class: "mb-8 custom-fields-container"
};
var _hoisted_3 = {
  class: "absolute right-0 activity-field-label z-1"
};
var _hoisted_4 = {
  key: 0,
  class: "activity-field-component-inner"
};
var _hoisted_5 = {
  class: "activity-field-component-inner-header"
};
var _hoisted_6 = {
  class: "activity-field-component-inner-content"
};
var _hoisted_7 = {
  key: 0,
  class: "activity-field-value"
};
var _hoisted_8 = {
  key: 1,
  class: "activity-field-simple-container"
};
var _hoisted_9 = {
  key: 2,
  class: "activity-field-simple-container"
};
var _hoisted_10 = {
  key: 3,
  class: "numerical-type"
};
var _hoisted_11 = {
  key: 4,
  class: "activity-field-simple-container"
};
var _hoisted_12 = {
  key: 5
};
var _hoisted_13 = {
  key: 1,
  class: "activity-field-component-inner"
};
var _hoisted_14 = {
  key: 3,
  class: "numerical-type"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_input_field_select = _resolveComponent("input-field-select");
  var _component_static_table = _resolveComponent("static-table");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_custom_field_text_box = _resolveComponent("custom-field-text-box");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_repeatable_fields = _resolveComponent("repeatable-fields");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredFields, function (field, key) {
    var _field$selectedValue;
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: _normalizeClass([['field-type-' + field.typeId, {
        readonly: $props.readonly,
        'col-span-3': field.typeId === $data.CustomFieldTypes.ROW_GROUP,
        'flex-grow': $props.growingFields
      }], "activity-field relative"])
    }, [_createElementVNode("div", _hoisted_3, [field.explanatoryText ? (_openBlock(), _createBlock(_component_help_tool_tip, {
      key: 0,
      "react-on-click": true,
      class: "help-tool-tip self-center pb-1"
    }, {
      "tooltip-body": _withCtx(function () {
        return _toConsumableArray(_cache[0] || (_cache[0] = [_createElementVNode("div", {
          class: _normalizeClass(['flex items-center justify-center w-5 h-5 border-2 rounded-full', 'border-blue-750 text-center leading-5 font-semibold cursor-pointer'])
        }, " i ", -1 /* HOISTED */)]));
      }),
      default: _withCtx(function () {
        return [_createElementVNode("span", null, _toDisplayString(field.explanatoryText), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", {
      class: _normalizeClass(['w-full', 'field-type-' + field.typeId, {
        readonly: $props.readonly
      }])
    }, [$props.readonly ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(field.label) + ":", 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [$options.isSimpleComponent(field) ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(field.selectedValue), 1 /* TEXT */)) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.SELECT_MULTI ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_ctx.getMultiSelectSelectedValues(field)), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TRAFFIC_LIGHT ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_traffic_light, {
      color: $options.findTrafficColor(field.selectedValue)
    }, null, 8 /* PROPS */, ["color"])])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.NUMERICAL ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_input_field_select, {
      "input-placeholder": _ctx.defaultPlaceholder,
      "input-value": field.selectedValue,
      "input-enabled": false,
      type: "number",
      "option-enabled": false,
      "option-value": 1,
      options: _ctx.getNumericalOptions(field.unit),
      onInputChange: function onInputChange($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["input-placeholder", "input-value", "options", "onInputChange"])])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.ROW_GROUP ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_static_table, {
      columns: $options.prepareColumns(field),
      "dataset-table": $options.prepareDataset(field)
    }, null, 8 /* PROPS */, ["columns", "dataset-table"])])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.DATE_TIME ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(field.selectedValue), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), !$props.readonly ? (_openBlock(), _createElementBlock("div", _hoisted_13, [field.typeId === $data.CustomFieldTypes.SELECT ? (_openBlock(), _createBlock(_component_single_select, {
      key: 0,
      options: _ctx.getSelectOptions(field.options),
      "model-value": field.selectedValue,
      placeholder: _ctx.defaultPlaceholderSelect,
      disabled: $options.isFieldDisabled(field.id),
      "un-sorted": true,
      "with-reset": !$props.disabled,
      "with-search": true,
      title: field.label,
      "required-text": field.required ? $data.translate.pleaseFillOutThisField : '',
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["options", "model-value", "placeholder", "disabled", "with-reset", "title", "required-text", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.SELECT_MULTI ? (_openBlock(), _createBlock(_component_risma_select, {
      key: 1,
      title: field.label,
      options: Object.values(field.options),
      placeholder: _ctx.defaultPlaceholderSelect,
      disabled: $options.isFieldDisabled(field.id),
      "un-sorted": true,
      "selected-options": _ctx.getMultiSelectSelected(field),
      "error-message": field.required && !((_field$selectedValue = field.selectedValue) !== null && _field$selectedValue !== void 0 && _field$selectedValue.length) ? $data.translate.pleaseFillOutThisField : '',
      "label-key": "value",
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["title", "options", "placeholder", "disabled", "selected-options", "error-message", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TEXT_LINE ? (_openBlock(), _createBlock(_component_input_field, {
      key: 2,
      "model-value": field.selectedValue,
      placeholder: _ctx.defaultPlaceholder,
      enabled: !$options.isFieldDisabled(field.id),
      title: field.label,
      "error-help-text": field.required && !field.selectedValue ? $data.translate.pleaseFillOutThisField : '',
      type: "text",
      invalid: field.required && !field.selectedValue,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["model-value", "placeholder", "enabled", "title", "error-help-text", "invalid", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.NUMERICAL ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_input_field_select, {
      "input-placeholder": _ctx.defaultPlaceholder,
      "input-value": field.selectedValue,
      "input-enabled": !$props.disabled,
      type: "number",
      title: field.label,
      invalid: field.required && !field.selectedValue,
      "error-help-text": field.required && !field.selectedValue ? $data.translate.pleaseFillOutThisField : '',
      "option-enabled": false,
      "option-value": 1,
      options: _ctx.getNumericalOptions(field.unit),
      onInputChange: function onInputChange($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["input-placeholder", "input-value", "input-enabled", "title", "invalid", "error-help-text", "options", "onInputChange"])])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TEXT_FIELD ? (_openBlock(), _createBlock(_component_custom_field_text_box, {
      key: 4,
      field: field,
      placeholder: _ctx.defaultPlaceholder,
      disabled: $options.isFieldDisabled(field.id),
      "local-storage-key": "custom-field-text-box-".concat($props.activityType.id, "-").concat(field.id),
      onUpdated: function onUpdated($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["field", "placeholder", "disabled", "local-storage-key", "onUpdated"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TRAFFIC_LIGHT ? (_openBlock(), _createBlock(_component_traffic_light_selector, {
      key: 5,
      modelValue: field.selectedValue,
      "onUpdate:modelValue": [function ($event) {
        return field.selectedValue = $event;
      }, function ($event) {
        return _ctx.onChange(field, $event);
      }],
      class: "mr-5",
      "error-help-text": field.required && !field.selectedValue ? $data.translate.pleaseSelectTrafficLight : '',
      "read-only": $options.isFieldDisabled(field.id),
      title: field.label
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "error-help-text", "read-only", "title"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TREND ? (_openBlock(), _createBlock(_component_single_select, {
      key: 6,
      options: $data.trendOptions,
      "model-value": field.selectedValue,
      placeholder: _ctx.defaultPlaceholderSelect,
      disabled: $options.isFieldDisabled(field.id),
      "with-reset": !$props.disabled,
      title: field.label,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["options", "model-value", "placeholder", "disabled", "with-reset", "title", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.ROW_GROUP ? (_openBlock(), _createBlock(_component_repeatable_fields, {
      key: 7,
      id: field.id,
      data: field,
      "custom-field-values": $data.filteredCustomFieldValues,
      "all-fields": $data.allFields,
      disabled: $props.disabled,
      onChanged: function onChanged($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["id", "data", "custom-field-values", "all-fields", "disabled", "onChanged"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.DATE_TIME ? (_openBlock(), _createBlock(_component_date_time_picker, {
      key: 8,
      "plugin-options": $props.dateOptions,
      "model-value": field.selectedValue,
      disabled: $options.isFieldDisabled(field.id),
      title: field.label,
      "error-text": field.required && !field.selectedValue ? $data.translate.pleaseFillOutThisField : '',
      "delete-enabled": true,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return _ctx.onChange(field, $event);
      }
    }, null, 8 /* PROPS */, ["plugin-options", "model-value", "disabled", "title", "error-text", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))])]);
}