// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-b329101a]  .card {
  padding: 16px;
  min-height: 175px;
  z-index: 2;
  position: relative;
  background-color: #f3f3f3;
  border: 1px solid #BEBEBE;
  margin-bottom: 30px;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
}
[data-v-b329101a]  .card .trafficlight_placer {
  line-height: 40px;
}
[data-v-b329101a]  .card .header-placer {
  display: inline-block;
  margin-left: 25px;
}
.static-table-container[data-v-b329101a] {
  padding: 0 15px;
}
.processes-tiles-list[data-v-b329101a] {
  display: flex;
  flex-wrap: wrap;
}
.processes-tiles-item[data-v-b329101a] {
  width: 33%;
  padding: 0 15px;
}
.processes-tiles-item .preview-cont[data-v-b329101a] {
  min-height: 175px;
}
[data-v-b329101a]  .static-table th {
  padding: 1rem 0;
}
[data-v-b329101a]  .static-table td {
  padding: 14px 8px;
  background-color: #f5f5f2;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}
[data-v-b329101a]  .static-table td ul {
  margin-bottom: 0;
}
[data-v-b329101a]  .static-table td:last-child {
  border-right: 1px solid #CCCCCC;
}
[data-v-b329101a]  .static-table td:first-child {
  border-left: 1px solid #CCCCCC;
}
[data-v-b329101a]  .static-table tbody:after {
  content: "";
  display: block;
  height: 11px;
}
[data-v-b329101a]  .static-table .medium {
  color: #999;
  font-weight: 600;
  font-size: 16px;
  height: 20px;
}
[data-v-b329101a]  .static-table .compact-title {
  margin: 0;
  color: #4385e0;
  background-color: transparent;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  padding-top: 1px;
}
[data-v-b329101a]  .static-table .compact-title-traffic {
  float: left;
  margin-right: 5px;
}
.processes-expanded-item[data-v-b329101a]  .card {
  min-height: auto;
}
.processes-expanded-item[data-v-b329101a]  .subtitle {
  padding-left: 1rem;
}
.processes-expanded-item[data-v-b329101a]  .header-placer {
  display: flex;
  align-items: baseline;
}
.processes-expanded-item[data-v-b329101a]  .risma-progress-bar {
  padding-top: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
