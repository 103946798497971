// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risma-step-progress-bar[data-v-0bb1ac38] {
  display: flex;
  width: 100%;
}
.risma-step-progress-bar .working-element[data-v-0bb1ac38] {
  flex-grow: 1;
  width: 33%;
}
.risma-step-progress-bar .working-element .icon-element[data-v-0bb1ac38] {
  display: flex;
  position: relative;
}
.risma-step-progress-bar .working-element .icon-element .risma-progress-bar-total[data-v-0bb1ac38] {
  background: #737373;
  height: 6px;
  width: 50%;
  position: absolute;
  top: 33px;
  z-index: 1;
}
.risma-step-progress-bar .working-element .icon-element .move-right-last[data-v-0bb1ac38] {
  right: calc(16.6666666667% + 30px + 10px);
}
.risma-step-progress-bar .working-element .icon-element .move-right[data-v-0bb1ac38] {
  right: calc(50% + 30px + 10px);
}
.risma-step-progress-bar .working-element .icon-element .move-left[data-v-0bb1ac38] {
  left: calc(50% + 30px + 10px);
}
.risma-step-progress-bar .working-element .icon-element .move-left-first[data-v-0bb1ac38] {
  left: calc(16.6666666667% + 30px + 10px);
}
.risma-step-progress-bar .working-element .text-element[data-v-0bb1ac38] {
  display: flex;
  flex-direction: column;
}
.risma-step-progress-bar .working-element .text-element .text-center[data-v-0bb1ac38] {
  text-align: center;
}
[data-v-0bb1ac38]  .risma-title {
  width: 90%;
  margin: 0 auto;
}
[data-v-0bb1ac38]  .small {
  font-size: 17px;
  margin-bottom: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
