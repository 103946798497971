// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gap-logic-information-box[data-v-5d3ccf61] {
  background: #BEBEBE;
  margin-left: 1rem;
  z-index: 5;
}
.gap-logic-information-box .triangle-left[data-v-5d3ccf61] {
  position: absolute;
  top: 4px;
  left: -15px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 20px solid #BEBEBE;
}
.gap-logic-information-box .box-title[data-v-5d3ccf61] {
  cursor: pointer;
}
.gap-logic-information-box[data-v-5d3ccf61]  .risma-title {
  width: auto !important;
}
.gap-logic-information-box[data-v-5d3ccf61]  .risma-title p {
  padding: 0;
  margin: 0;
  border: 0;
  color: #FFFFFF;
  font-weight: bolder;
}
.gap-logic-information-box.is-auto[data-v-5d3ccf61] {
  background: #4CB3C0;
  border-color: #4CB3C0;
}
.gap-logic-information-box.is-auto .triangle-left[data-v-5d3ccf61] {
  border-right-color: #4CB3C0;
}
.gap-logic-information-box span[data-v-5d3ccf61] {
  color: #FFFFFF;
  font-size: 14px;
}
.gap-logic-information-box span.type[data-v-5d3ccf61] {
  font-weight: bolder;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
