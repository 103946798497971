function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { RiskReportsFiltersMixin } from '@/components/Molecules/Risk/RiskReports/RiskReportsFiltersMixin';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'RiskReportsFilters',
  description: 'Filters section for risk reports',
  token: '<risk-reports-filters />',
  components: {
    RismaToggle: RismaToggle,
    ButtonsRow: ButtonsRow,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    FeatherIcon: FeatherIcon
  },
  mixins: [RiskReportsFiltersMixin],
  props: {
    options: {
      required: true,
      type: Object,
      note: 'Object with options for selectors'
    },
    selectedFilters: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Object with selected filters values'
    }
  },
  emits: ['changePeriod', 'create'],
  data: function data() {
    return {
      unSorted: true,
      selectedValues: _objectSpread({
        includeUnderlyingRisks: false
      }, this.selectedFilters),
      riskAssessmentsValidation: '',
      riskAssessmentPreviousMapping: {},
      translate: getTranslate['RiskReportsFilters']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, ['isSystemsEnabled'])), {}, {
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.resetFilter,
        type: 'util',
        clickEvent: this.handleResetFilters
      }, {
        text: this.translate.createReport,
        type: 'save',
        clickEvent: this.handleCreateReport
      }];
    }
  }),
  watch: {
    'options.riskAssessments': function optionsRiskAssessments(newList) {
      var _this = this;
      if (this.selectedFilters.riskAssessments) {
        // Mapping current risk assessment IDs with those from the previous report period
        this.riskAssessmentPreviousMapping = {};
        this.selectedFilters.riskAssessments = this.selectedFilters.riskAssessments.map(function (assessment) {
          var matchedRiskAssessment = newList.find(function (newAssessment) {
            return newAssessment.label === assessment.label;
          });
          if (matchedRiskAssessment) _this.riskAssessmentPreviousMapping[matchedRiskAssessment.id] = assessment.id;
          return matchedRiskAssessment;
        }).filter(Boolean);
      }
    }
  },
  methods: {
    validate: function validate() {
      var result = true;
      this.riskAssessmentsValidation = '';
      if (!(this.selectedFilters.riskAssessments && this.selectedFilters.riskAssessments.length)) {
        result = false;
        this.riskAssessmentsValidation = this.translate.pleaseSelectOne;
      }
      return result;
    },
    handleCreateReport: function handleCreateReport() {
      if (!this.validate()) return;
      this.$emit('create', this.selectedFilters, this.riskAssessmentPreviousMapping);
    }
  }
};