import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "risk-grid-display"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_matrix = _resolveComponent("matrix");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_matrix, {
    matrix: $data.grid,
    "color-codes": $props.riskGridData.colorCodes,
    "matrix-color": $props.colorDirection,
    labels: $options.computedLabels,
    size: $props.gridBoxSize,
    "is-matrix-clickable": $props.isMatrixClickable,
    "enable-prev-position": false,
    "show-arrows": $props.showArrows,
    "start-color": $props.startColor,
    "end-color": $props.endColor,
    "custom-heatmap-options": $props.customHeatmapOptions,
    onOnStateChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('onStateChanged', $event);
    })
  }, null, 8 /* PROPS */, ["matrix", "color-codes", "matrix-color", "labels", "size", "is-matrix-clickable", "show-arrows", "start-color", "end-color", "custom-heatmap-options"])]);
}