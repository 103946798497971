import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { saveStatus as SaveStatuses } from '@/constants/SaveStatuses';
var SCROLL_LIMIT = 110;
export default {
  name: 'SaveButton',
  description: 'Save button which represents current save status. Subscribed to scroll event',
  token: '<save-button save-status="Saved"  @saveStatusClicked="fn" />',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    saveStatus: {
      type: String,
      required: true,
      note: 'Current save status'
    }
  },
  emits: ['saveStatusClicked'],
  data: function data() {
    return {
      scrollY: 0,
      SaveStatuses: SaveStatuses
    };
  },
  computed: {
    isActiveClass: function isActiveClass() {
      return this.saveStatus !== SaveStatuses.SAVED;
    },
    iconSrc: function iconSrc() {
      if (this.saveStatus === SaveStatuses.NOT_SAVED) return '/assets/svg/icon-alert-triangle-solid.svg';
      return '/assets/svg/icon-checkmark-circle.svg';
    },
    autoSaveClass: function autoSaveClass() {
      return {
        'absolute top-0 right-16 z-1': true,
        'flex items-center': true,
        'w-max px-4 py-7px border border-rGrey-500 rounded': true,
        'bg-rGrey-100': true,
        'cursor-pointer': this.isActiveClass,
        'cursor-default': !this.isActiveClass,
        scroll: this.isScrolled
      };
    },
    isScrolled: function isScrolled() {
      return this.scrollY > SCROLL_LIMIT;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      return window.addEventListener('scroll', _this.onScrollFn);
    });
  },
  unmounted: function unmounted() {
    window.removeEventListener('scroll', this.onScrollFn);
  },
  methods: {
    onScrollFn: function onScrollFn() {
      this.scrollY = document.documentElement.scrollTop || document.body.scrollTop;
    }
  }
};