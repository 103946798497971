// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-table[data-v-59f9b4ac] {
  width: 500px;
}
.report-table th[data-v-59f9b4ac] {
  padding: 5px 5px 5px 25px;
}
.report-table td[data-v-59f9b4ac] {
  padding: 5px 5px 5px 25px;
  border-top: 1px solid #BEBEBE;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
