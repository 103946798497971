import { stripRecognisedHTMLOnly } from '@/utils';
import { TitleTypes } from '@/constants/TitleTypes';
import { getTextSize } from '@/utils/textSize';
export default {
  name: 'RismaEditableTitle',
  description: 'Title that can be editable',
  token: '<risma-editable-title title="Main Title" @titleChange="fn" @titleChangeLazy="fn" />',
  props: {
    title: {
      required: true,
      type: String,
      note: 'The text that should be titled'
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
      note: 'A boolean to determine if the title, should be editable'
    },
    truncate: {
      required: false,
      default: true,
      type: Boolean,
      note: 'A boolean to determine if the title should be truncated'
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      required: false,
      type: Number,
      default: 255
    },
    minInputWidth: {
      required: false,
      type: Number,
      default: 22
    },
    isId: {
      required: false,
      type: Boolean,
      default: false
    },
    type: {
      required: false,
      type: String,
      default: 'text'
    }
  },
  emits: ['click', 'titleChangeLazy', 'titleChange'],
  data: function data() {
    return {
      editedTitle: '',
      lastValidTitle: '',
      timeout: 0,
      TitleTypes: TitleTypes
    };
  },
  computed: {
    inputClasses: function inputClasses() {
      return {
        truncate: this.truncate,
        'hover:border-dashed hover:bg-rGrey-100 hover:border-rGrey-600 focus:bg-white': !this.disabled,
        'focus:outline-none focus:border-solid focus:shadow-title-grey cursor-pointer': !this.disabled,
        'border-rRed-500 focus:border-rRed-500': !this.isEditedTitleValid,
        'border-transparent focus:border-brand-500': this.isEditedTitleValid,
        'text-2xl text-black border-transparent bg-transparent': !this.isId,
        'text-xl text-rGrey-800 text-center border-rGrey-500 bg-rGrey-100': this.isId
      };
    },
    isEmpty: function isEmpty() {
      return this.isText ? this.validEditedTitle.trim() === '' : false;
    },
    isText: function isText() {
      return this.type === 'text';
    },
    isEditedTitleValid: function isEditedTitleValid() {
      return this.validEditedTitle === this.editedTitle;
    },
    validEditedTitle: function validEditedTitle() {
      if (this.isText) return stripRecognisedHTMLOnly(this.editedTitle);
      return this.editedTitle;
    },
    font: function font() {
      return this.isId ? '500 20px Inter' : '500 24px Inter';
    },
    trimmedTitle: function trimmedTitle() {
      return this.title.trim();
    }
  },
  watch: {
    title: function title() {
      this.editedTitle = this.trimmedTitle;
    }
  },
  created: function created() {
    this.editedTitle = this.trimmedTitle;
    this.lastValidTitle = this.trimmedTitle;
  },
  mounted: function mounted() {
    this.setInputWidth();
  },
  updated: function updated() {
    this.setInputWidth();
  },
  methods: {
    setInputWidth: function setInputWidth() {
      var input = this.$el;
      if (input !== null) {
        var padding = 15;
        var calcWidth = Math.max(this.minInputWidth, Math.ceil(getTextSize(input.value, this.font) + padding));
        input.style.width = calcWidth + 'px';
      }
    },
    handleChange: function handleChange() {
      var _this = this;
      clearTimeout(this.timeout);
      if (!this.isEditedTitleValid) return;
      this.timeout = setTimeout(function () {
        if (!_this.allowEmpty) {
          if (!_this.isEmpty) {
            _this.lastValidTitle = _this.editedTitle;
            _this.$emit('titleChange', _this.editedTitle);
          }
        } else {
          _this.$emit('titleChange', _this.editedTitle);
        }
      }, 300);
    },
    handleChangeLazy: function handleChangeLazy() {
      if (!this.isEditedTitleValid) return;
      if (this.editedTitle === '' && !this.allowEmpty) this.editedTitle = this.lastValidTitle;
      this.$emit('titleChangeLazy', this.editedTitle);
    },
    handleBlur: function handleBlur() {
      if (!this.isEditedTitleValid) {
        if (!this.allowEmpty && this.isEmpty) {
          this.editedTitle = this.lastValidTitle;
        } else {
          this.editedTitle = this.validEditedTitle;
        }
        this.$emit('titleChangeLazy', this.editedTitle);
        this.$emit('titleChange', this.editedTitle);
      }
    },
    validateNumber: function validateNumber(evt) {
      if (this.type !== 'number') return;
      var charCode = evt.keyCode || evt.key.charCodeAt(0);
      if (charCode > 57) evt.preventDefault();
    }
  }
};