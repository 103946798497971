import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { searchGaps } from '@/api/compliance/reports/gap';
import { ReportTypes } from '@/constants/reports';
export default {
  name: 'GapReportThin',
  description: 'RismaTable view type of the compliance gap report',
  components: {
    RismaTable: RismaTable
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true,
      note: 'Id of current project'
    },
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      stateLocal: 'compliance-gap-thin-table-key',
      reportType: ReportTypes.COMPLIANCE_GAP_THIN
    };
  },
  methods: {
    getTableReport: function getTableReport(params) {
      return searchGaps(this.projectId, this.filters, 0, null, null, null, params);
    }
  }
};