import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["rows", "cols", "placeholder", "maxlength", "disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.editedText = $event;
    }),
    rows: $props.rows,
    cols: $props.cols,
    placeholder: $props.placeholder,
    maxlength: $props.maxLength,
    disabled: !$props.enabled,
    class: _normalizeClass(["block p-2 pr-12 text-base text-left border outline-none focus:border-blue-750", "".concat($props.enabled ? 'text-gray-750 bg-white' : 'cursor-not-allowed text-gray-450 bg-gray-75 bg-blue')]),
    onInput: _cache[1] || (_cache[1] = function () {
      return $options.pushEvent && $options.pushEvent.apply($options, arguments);
    }),
    onBlur: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('blur', $data.editedText);
    }),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
  }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)), [[_vModelText, $data.editedText]]);
}