function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { getTranslate } from './translate';
export default {
  name: 'GapSummaryTable',
  introduction: 'A gap summary table',
  description: "Field gaps in the datasetMain represent the values of cells with gaps. The order of items in the array matches these orders:\n     [NOT_SET, NO_GAP, LOW_GAP, MEDIUM_GAP, HIGH_GAP, GAP_NA]. The columns representing gap cells are hardcoded.",
  token: "<gap-summary-table :datasetMain=\"testData\" :columns=\"testColumns\" @updateFromPreview='' @dismissPreviewModal='' />\n        testData:[\n            {\n                node: {\n                    title: 'TestItem1',\n                    activity: {[activity data]}\n                },\n                questionnaire?: 'questionnaire string',\n                total: 55,\n                gaps: [5, 20, 10, 21, 10, 0]\n            }\n        }\n        ]\n        testColumns: [Node, Questionnaire(s)?, Total]",
  components: {
    PreviewModalLink: PreviewModalLink
  },
  props: {
    columns: {
      required: true,
      type: Array,
      note: 'An array with column names as strings'
    },
    datasetMain: {
      required: true,
      type: Array,
      note: 'An array with objects, that represents the data which should be displayed, see structure in a view'
    },
    totalsRowEnabled: {
      required: false,
      type: Boolean,
      default: true,
      note: "This row in the table shows the total calculation for the Gaps and Total columns, and it's the last one"
    },
    subheadEnabled: {
      required: false,
      type: Boolean,
      default: true,
      note: "Show or hide the 'Gap indicator' subhead"
    }
  },
  emits: ['updateFromPreview', 'dismissPreviewModal'],
  data: function data() {
    return {
      translate: getTranslate['GapSummaryTable']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTrafficLights, ['gapTrafficLights'])), {}, {
    gapColumns: function gapColumns() {
      var _this = this;
      return this.gapTrafficLights.map(function (light) {
        return _this.$trans(light.legend);
      });
    },
    totals: function totals() {
      var _this$datasetMain$;
      if (this.totalsRowEnabled && (_this$datasetMain$ = this.datasetMain[0]) !== null && _this$datasetMain$ !== void 0 && _this$datasetMain$.gaps) {
        var additionalCol = 1;
        var numCols = this.datasetMain[0].gaps.length + additionalCol;
        var totals = new Array(numCols).fill(0);
        this.datasetMain.forEach(function (row) {
          var _row$gaps;
          totals[0] += row.total;
          (_row$gaps = row.gaps) === null || _row$gaps === void 0 || _row$gaps.forEach(function (gapValue, index) {
            totals[index + additionalCol] += +gapValue;
          });
        });
        return totals;
      }
      return [];
    },
    questionnaireColumnExist: function questionnaireColumnExist() {
      return this.datasetMain[0] && Object.prototype.hasOwnProperty.call(this.datasetMain[0], 'questionnaire');
    }
  })
};