import { getTranslate } from './translate';
import InputField from '@/components/Atoms/Inputs/InputField';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'RiskManagementModal',
  introduction: 'Modal for creating/updating, contains an InputField',
  token: '<risk-management-modal :generic-data="{}" />',
  components: {
    InputField: InputField,
    Modal: Modal,
    RismaButton: RismaButton,
    Notification: Notification
  },
  props: {
    data: {
      type: Object,
      require: false,
      default: function _default() {
        return {};
      }
    },
    genericData: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: '',
      validator: function validator(value) {
        var isPropValid = [value.creationHeader, value.updateHeader].every(function (item) {
          return typeof item === 'string' && item;
        });
        var isFnValid = [value.updateItemAPI, value.createItemAPI].every(function (item) {
          return typeof item === 'function';
        });
        return isPropValid && isFnValid;
      }
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      newTitle: '',
      errorMessage: '',
      translate: getTranslate['RiskManagementModal']()
    };
  },
  computed: {
    header: function header() {
      return this.data.id ? this.genericData.updateHeader : this.genericData.creationHeader;
    },
    isNewTitleValid: function isNewTitleValid() {
      return !!this.newTitle && this.newTitle !== this.data.title;
    }
  },
  mounted: function mounted() {
    this.newTitle = this.data.title;
  },
  methods: {
    createNewTitle: function createNewTitle() {
      var _this = this;
      return this.genericData.createItemAPI(this.newTitle).then(function () {
        _this.$emit('dismiss');
      }).catch(function (error) {
        return _this.handleError(error.response);
      });
    },
    updateValue: function updateValue() {
      var _this2 = this;
      return this.genericData.updateItemAPI(this.newTitle, this.data.id).then(function () {
        _this2.$emit('dismiss');
      }).catch(function (error) {
        return _this2.handleError(error.response);
      });
    },
    onSave: function onSave() {
      if (this.data.id) {
        this.updateValue();
      } else {
        this.createNewTitle();
      }
    },
    handleError: function handleError(error) {
      this.errorMessage = this.$trans(error);
    },
    resetErrorMessage: function resetErrorMessage() {
      this.errorMessage = '';
    }
  }
};