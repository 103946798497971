import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "gap"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = ["title"];
var _hoisted_4 = {
  class: "glob-b1 text-rm-text whitespace-pre-wrap mb-6"
};
var _hoisted_5 = {
  key: 0,
  class: "mb-6"
};
var _hoisted_6 = {
  class: "mb-1"
};
var _hoisted_7 = ["title"];
var _hoisted_8 = {
  class: "relative z-10"
};
var _hoisted_9 = ["title"];
var _hoisted_10 = {
  class: "flex items-center mb-2"
};
var _hoisted_11 = {
  key: 0,
  class: "flex items-center relative"
};
var _hoisted_12 = {
  class: "flex mb-2"
};
var _hoisted_13 = {
  class: "mb-6"
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = ["title"];
var _hoisted_16 = {
  class: "w-full mb-1"
};
var _hoisted_17 = {
  class: "mb-6"
};
var _hoisted_18 = {
  key: 0,
  class: "flex justify-between flex-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$article;
  var _component_links_modal = _resolveComponent("links-modal");
  var _component_impact_assessment_modal = _resolveComponent("impact-assessment-modal");
  var _component_links_recommended_modal = _resolveComponent("links-recommended-modal");
  var _component_ai_clarify_sender = _resolveComponent("ai-clarify-sender");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_framework_overall = _resolveComponent("framework-overall");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_gap_logic_information_box = _resolveComponent("gap-logic-information-box");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_text_box = _resolveComponent("text-box");
  var _component_read_more = _resolveComponent("read-more");
  var _component_risk_module = _resolveComponent("risk-module");
  var _component_attributes_list = _resolveComponent("attributes-list");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_selector_tags = _resolveComponent("selector-tags");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showLinksModal ? (_openBlock(), _createBlock(_component_links_modal, {
    key: 0,
    "projects-list": $props.projectsOptions,
    "links-options": $options.linksOptions,
    "preselected-links": $data.preselectedLinks,
    initiatives: $props.initiativesForCreateInitiative,
    onSelectProject: $options.setLinksOption,
    onUpdateLinks: $options.changeLinks,
    onAddNewItem: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('addNewItem', $event);
    }),
    onDismiss: $options.toggleLinksModal
  }, null, 8 /* PROPS */, ["projects-list", "links-options", "preselected-links", "initiatives", "onSelectProject", "onUpdateLinks", "onDismiss"])) : _createCommentVNode("v-if", true), $data.assessmentQuestionnaire && $data.showImpactAssessmentModal ? (_openBlock(), _createBlock(_component_impact_assessment_modal, {
    key: 1,
    "questionnaire-assessment": $data.assessmentQuestionnaire,
    "gap-article": $props.gap.article.article,
    onDismiss: $options.toggleImpactAssessmentModal,
    onAccept: $options.acceptAssessmentModal
  }, null, 8 /* PROPS */, ["questionnaire-assessment", "gap-article", "onDismiss", "onAccept"])) : _createCommentVNode("v-if", true), $data.showRecommendedLinksModal ? (_openBlock(), _createBlock(_component_links_recommended_modal, {
    key: 2,
    article: $props.gap.article,
    initiatives: $options.recommendedActivities,
    onAddRecommendedActivity: $options.addRecommendedActivity,
    onDismiss: $options.toggleLinksRecommendedModal
  }, null, 8 /* PROPS */, ["article", "initiatives", "onAddRecommendedActivity", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("h1", {
    class: _normalizeClass(["glob-h1 text-rm-text inline align-middle", {
      'cursor-pointer': $options.showFullGapsList
    }]),
    title: $options.title,
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.setCurrentGapIndex && $options.setCurrentGapIndex.apply($options, arguments);
    })
  }, _toDisplayString($options.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_3), $options.showAiClarifySender && $props.article.requirement ? (_openBlock(), _createBlock(_component_ai_clarify_sender, {
    key: 0,
    context: "".concat($options.title, ". ").concat($props.article.requirement),
    source: "".concat($props.currentProject.title, " > ").concat($props.data.title, " > Gap question ").concat($props.article.article)
  }, null, 8 /* PROPS */, ["context", "source"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_4, _toDisplayString($props.article.requirement), 1 /* TEXT */), $options.showGapArticleTypes ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("h3", {
    class: "glob-h3",
    title: $data.translate.gapTypes
  }, _toDisplayString($options.enabledGapTypeMultiselect ? $data.translate.gapTypes : $data.translate.gapType), 9 /* TEXT, PROPS */, _hoisted_7)]), $options.enabledGapTypeMultiselect ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    "selected-options": $options.gapTypesSelected,
    placeholder: $data.translate.selectGapTypes,
    disabled: $props.readOnly || $options.isTrafficLightGreen,
    options: $options.gapTypesOptions,
    "un-sorted": true,
    "data-test-id": "gap-types-multi-select",
    "label-key": "label",
    "track-by": "value",
    onSelected: $options.changeTypesMultiple
  }, null, 8 /* PROPS */, ["selected-options", "placeholder", "disabled", "options", "onSelected"])) : (_openBlock(), _createBlock(_component_single_select, {
    key: 1,
    modelValue: $options.gapTypeIdSelected,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
      return $options.gapTypeIdSelected = $event;
    }), $options.changeTypeSingle],
    placeholder: $data.translate.selectGapType,
    disabled: $props.readOnly || $options.isTrafficLightGreen,
    options: $options.gapTypesOptions,
    "un-sorted": true,
    "data-test-id": "gap-types-single-select"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "disabled", "options", "onUpdate:modelValue"]))])) : _createCommentVNode("v-if", true), $options.hasOverallFramework ? (_openBlock(), _createBlock(_component_framework_overall, {
    key: 1,
    class: "mb-6",
    "framework-ids": ((_$props$article = $props.article) === null || _$props$article === void 0 ? void 0 : _$props$article.solutionIds) || []
  }, null, 8 /* PROPS */, ["framework-ids"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [_withDirectives((_openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(['relative', $props.readOnly ? 'cursor-not-allowed' : 'cursor-pointer'])
  }, [_createElementVNode("h3", {
    class: "glob-h3 mb-2",
    title: $data.translate.analysis
  }, _toDisplayString($data.translate.analysis), 9 /* TEXT, PROPS */, _hoisted_9), _createElementVNode("div", _hoisted_10, [_createVNode(_component_traffic_light_selector, {
    "model-value": $props.gap.analysis_rag,
    "read-only": $props.readOnly,
    "light-type": $data.trafficLightType,
    "onUpdate:modelValue": $options.changeLight
  }, null, 8 /* PROPS */, ["model-value", "read-only", "light-type", "onUpdate:modelValue"]), $options.showGapLogicInformationBox ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_gap_logic_information_box, {
    "is-auto": !!$props.gap.valueSetByRismaRules,
    "risma-rules": $props.gap.rismaRules,
    "risma-rules-question-answers": $props.gap.rismaRulesQuestionAnswers
  }, null, 8 /* PROPS */, ["is-auto", "risma-rules", "risma-rules-question-answers"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.hasQuestionnaires, function (aq, idx) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: idx
    }, [aq.mustHaveAssessment && aq.gap_article_id === $props.article.id ? (_openBlock(), _createBlock(_component_risma_button, {
      key: 0,
      text: $options.getTIALIAButtonText(aq.type),
      disabled: $props.readOnly,
      class: "mr-2 impact-assessment-button",
      type: "danger",
      onClick: function onClick($event) {
        return $options.toggleImpactAssessmentModal(aq);
      }
    }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */), _createElementVNode("div", _hoisted_13, [$data.editAnalys ? (_openBlock(), _createBlock(_component_text_box, {
    key: 0,
    ref: "refAnalys",
    placeholder: $data.translate.typeHere + '...',
    text: $props.gap.analysis,
    resize: $data.ResizeOptions.NONE,
    onUpdated: $options.setChangeAnalysisTimer,
    onBlur: $options.changeAnalysis
  }, null, 8 /* PROPS */, ["placeholder", "text", "resize", "onUpdated", "onBlur"])) : (_openBlock(), _createBlock(_component_read_more, {
    key: 1,
    class: "glob-b1 text-rm-text",
    text: $options.analysisForReadMore,
    "max-chars": 450,
    onClick: _cache[3] || (_cache[3] = _withModifiers(function () {
      return $options.toggleTextBox('editAnalys', 'refAnalys');
    }, ["stop"]))
  }, null, 8 /* PROPS */, ["text"]))]), $options.showRiskAssessment ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("h2", {
    class: "glob-h2 mb-2",
    title: $data.translate.riskAssessment
  }, _toDisplayString($data.translate.riskAssessment), 9 /* TEXT, PROPS */, _hoisted_15), _createElementVNode("div", _hoisted_16, [$options.gapRisk && $options.enableGapRiskModule ? (_openBlock(), _createBlock(_component_risk_module, {
    key: 0,
    risks: $options.gapRisk,
    class: "align-top",
    "read-only": $props.readOnly,
    onImpactUpdate: $options.changeRisk,
    onLikelihoodUpdate: $options.changeRisk
  }, null, 8 /* PROPS */, ["risks", "read-only", "onImpactUpdate", "onLikelihoodUpdate"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_17, [$data.editRiskAssessment ? (_openBlock(), _createBlock(_component_text_box, {
    key: 0,
    ref: "refRiskAssessment",
    placeholder: $data.translate.typeHere + '...',
    text: $props.gap.analysisControlsInitiatives,
    resize: $data.ResizeOptions.NONE,
    onUpdated: $options.setChangeModuleAnalysisTimer,
    onBlur: $options.changeModuleAnalysis
  }, null, 8 /* PROPS */, ["placeholder", "text", "resize", "onUpdated", "onBlur"])) : (_openBlock(), _createBlock(_component_read_more, {
    key: 1,
    class: "glob-b1 text-rm-text",
    text: $options.analysisControlsInitiativesForReadMore,
    onClick: _cache[4] || (_cache[4] = _withModifiers(function () {
      return $options.toggleTextBox('editRiskAssessment', 'refRiskAssessment');
    }, ["stop"]))
  }, null, 8 /* PROPS */, ["text"]))])])) : _createCommentVNode("v-if", true), _createVNode(_component_attributes_list, {
    "attributes-map": $props.article.attributes
  }, null, 8 /* PROPS */, ["attributes-map"])])), [[_directive_click_outside, $options.clickOutsideBox]])])]), _createElementVNode("div", null, [_cache[7] || (_cache[7] = _createElementVNode("hr", {
    class: "my-4 bg-black border-solid border-b border-gray-620"
  }, null, -1 /* HOISTED */)), !$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("button", {
    class: "btn-link",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.toggleLinksModal();
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "btn-link__icon"
  }), _createTextVNode(" " + _toDisplayString($data.translate.addMitigationActivities), 1 /* TEXT */)]), $options.recommendedActivitiesExist ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "btn-link",
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $options.toggleLinksRecommendedModal();
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "btn-link__icon"
  }), _createTextVNode(" " + _toDisplayString($data.translate.addRecommendedActivities), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($options.filteredLinks), function (linkModule, index) {
    return _openBlock(), _createElementBlock(_Fragment, null, [$options.links[linkModule].some(function (item) {
      return item;
    }) ? (_openBlock(), _createElementBlock("div", {
      key: linkModule + index,
      class: "py-4 section-item relative"
    }, [_createVNode(_component_selector_tags, {
      module: linkModule,
      tags: $options.links[linkModule],
      title: _ctx.$trans($data.moduleTitles[linkModule]),
      "has-traffic-lights": $options.checkTrafficLightFunction(linkModule),
      "has-delete-options": !$props.readOnly,
      onDelete: function onDelete($event) {
        return $options.deleteLink(linkModule, $event);
      },
      onClick: $options.handleMitigationActivities
    }, null, 8 /* PROPS */, ["module", "tags", "title", "has-traffic-lights", "has-delete-options", "onDelete", "onClick"])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))]), _createVNode(_component_preview_modal_link, {
    ref: "previewModal",
    activity: $data.selectedActivity,
    onUpdated: $options.updateFromPreviewModalLink
  }, {
    default: _withCtx(function () {
      return _cache[8] || (_cache[8] = [_createElementVNode("span", null, null, -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "onUpdated"])]);
}