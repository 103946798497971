import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { getTopLevelNodes } from '@/api/compliance/reports/outsourcing';
export default {
  name: 'SelectTopLevelNode',
  components: {
    SingleSelect: SingleSelect
  },
  provide: function provide() {
    var _this = this;
    return {
      $topLevelNodeTitle: function $topLevelNodeTitle() {
        return _this.selectedNodeTitle;
      }
    };
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    nodeId: {
      type: [Number, String],
      default: null,
      required: false
    }
  },
  data: function data() {
    return {
      topLevelNodes: [],
      translate: {
        selectOutsourcingArrangement: this.$trans('Select Outsourcing arrangement')
      }
    };
  },
  computed: {
    selectedNodeId: {
      get: function get() {
        return +this.nodeId;
      },
      set: function set(value) {
        this.$router.push({
          name: 'OutsourcingReportList',
          params: {
            projectId: this.projectId,
            nodeId: value
          }
        });
      }
    },
    selectedNodeTitle: function selectedNodeTitle() {
      var _this2 = this;
      var topLevelNode = this.topLevelNodes.find(function (node) {
        return node.value === _this2.selectedNodeId;
      });
      if (!topLevelNode) {
        return '';
      }
      return topLevelNode.label;
    }
  },
  mounted: function mounted() {
    this.fetchNodes();
  },
  methods: {
    fetchNodes: function fetchNodes() {
      var _this3 = this;
      getTopLevelNodes(this.projectId).then(function (_ref) {
        var list = _ref.list;
        _this3.topLevelNodes = list;
      });
    }
  }
};