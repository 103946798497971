function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getTranslate } from './translate';
import { getSwitchUserData } from '@/api/admin/bulk';
import StaticTable from '@/components/Molecules/StaticTable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { BulkUpdateMixin } from '@/mixins/BulkUpdateMixin';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { MODULES } from '@/constants/modules';
import { getModuleFromObjType } from '@/constants/ObjectTypes';
export default {
  name: 'UsersActivitiesList',
  description: 'Component for displaying activities user is involved in',
  token: '<users-activities-list :compliance-projects="[]" :risk-projects="[]"/>',
  components: {
    PreviewModalLink: PreviewModalLink,
    StaticTable: StaticTable,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [RaciTitleMixin, BulkUpdateMixin],
  props: {
    selectedUserId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      isLoading: false,
      activities: [],
      StaticTableThemes: StaticTableThemes,
      translate: getTranslate['UsersActivitiesList']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    getActivityTypes: 'multipleActivityTypes'
  })), {}, {
    activityTypes: function activityTypes() {
      return this.getActivityTypes(MODULES.CONTRACTS, MODULES.INCIDENTS).flat();
    },
    allModulesNames: function allModulesNames() {
      return _toConsumableArray(new Set(this.allModules.map(function (item) {
        return item.fullName;
      })));
    },
    columns: function columns() {
      return [this.translate.module, this.translate.title, this.translate.assignedAs];
    },
    dataset: function dataset() {
      var _this = this;
      return this.activities.map(function (activity) {
        return {
          module: activity.moduleTitle,
          title: activity,
          attachedAs: _this.getAttachedLine(activity)
        };
      });
    }
  }),
  watch: {
    selectedUserId: function selectedUserId() {
      this.getUserData();
    }
  },
  methods: {
    getUserData: function getUserData() {
      var _this2 = this;
      this.isLoading = true;
      return getSwitchUserData(this.allModulesNames, this.selectedUserId).then(function (_ref) {
        var list = _ref.list;
        var filteredList = _this2.filterBySelectedModules(list, _this2.activityTypes);
        _this2.activities = _this2.enhanceData(filteredList);
        _this2.isLoading = false;
      });
    },
    updateFromPreviewModal: function updateFromPreviewModal(activity) {
      var _this3 = this;
      var index = this.activities.findIndex(function (a) {
        return _this3.isSameActivity(activity, a);
      });
      var updatedActivity = _objectSpread(_objectSpread({}, this.activities[index]), {}, {
        title: activity.title,
        name: activity.name,
        displayHeadline: activity.displayHeadline,
        responsibleUserIds: activity.responsibleUserIds,
        accountableUserIds: activity.accountableUserIds,
        consultedUserIds: activity.consultedUserIds,
        informedUserIds: activity.informedUserIds,
        responsible: activity.responsible,
        escalation: activity.escalation,
        review: activity.review
      });
      this.activities.splice(index, 1, updatedActivity);
    },
    deleteFromPreviewModal: function deleteFromPreviewModal(activity) {
      var _this4 = this;
      this.activities = this.activities.filter(function (a) {
        return !_this4.isSameActivity(activity, a);
      });
    },
    isSameActivity: function isSameActivity(activity1, activity2) {
      return +activity1.id === +activity2.id && getModuleFromObjType(activity1.objType) === getModuleFromObjType(activity2.objType);
    }
  }
};