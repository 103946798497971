// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionnaire-scoring-assessments[data-v-1569de92] {
  position: relative;
  margin: 0 0 2rem;
}
.questionnaire-table[data-v-1569de92] {
  margin-top: 0.5rem;
}
.questionnaire-table thead th[data-v-1569de92] {
  padding: 5px 5px 0;
}
.questionnaire-table thead[data-v-1569de92]  .risma-title h3 {
  font-size: 15px;
}
.questionnaire-table td[data-v-1569de92] {
  width: 130px;
  padding: 5px;
}
.questionnaire-table .item-color[data-v-1569de92] {
  width: 150px;
}
.questionnaire-table .item-text[data-v-1569de92] {
  width: 400px;
}
.questionnaire-table .item-operator[data-v-1569de92] {
  width: 90px;
}
.questionnaire-table .item-delete[data-v-1569de92] {
  width: 30px;
}

/* Transitions */
.list-unit[data-v-1569de92] {
  transition: all 0.3s;
}
.list-move[data-v-1569de92] {
  transition: all 0.3s;
}
.list-enter-from[data-v-1569de92],
.list-leave-to[data-v-1569de92] {
  opacity: 0;
}
.thead-cell[data-v-1569de92] {
  text-align: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
