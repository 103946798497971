import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "single-asset-choice relative"
};
var _hoisted_2 = {
  key: 0,
  class: "absolute top-0 bottom-0 right-0 left-0 z-10 cursor-not-allowed"
};
var _hoisted_3 = {
  key: 2,
  class: "w-3/4 mt-3"
};
var _hoisted_4 = {
  class: "font-semibold mr-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("v-if", true), $data.values.length > 0 ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 1,
    options: $options.nodesOptions,
    "selected-options": $options.nodesSelected,
    disable: $props.readOnly,
    "label-key": "option",
    onSelected: $options.emitChanges,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "disable", "onSelected"])) : _createCommentVNode("v-if", true), $options.onlyMyProccessLibraryChoices && $options.selectedByOthers.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($options.selectedByOthersDisplay), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString($data.translate.isAreAlreadyAddedByADifferentUser) + ".", 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.youCantSeeOrEditTheseProcessLibraryBecauseYourUserRightInhibitsYouToInteractWithIt) + ". ", 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}