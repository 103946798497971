function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getCompanyIdsInArray } from '@/utils/format/companies';
import { CONTRACT_PARTY_TYPES } from '@/constants/contracts/contract';
import { MODULES } from '@/constants/modules';
import { getTextSize } from '@/utils/textSize';
export var ContractPartiesOverviewMixin = {
  data: function data() {
    return {
      maxExternalParties: 120,
      maxInternalParties: 120
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isExtendedParties: function isExtendedParties() {
      return this.getSettingValue('feature.contracts.extended_parties');
    },
    columnsExtendedWithPartiesMeta: function columnsExtendedWithPartiesMeta() {
      return [].concat(_toConsumableArray(this.columnsMeta), [{
        targets: ['externalParties'],
        width: Math.ceil(this.maxExternalParties) + 'px'
      }, {
        targets: ['internalParties'],
        width: Math.ceil(this.maxInternalParties) + 'px'
      }]);
    }
  }),
  methods: {
    getCompaniesDataByIds: function getCompaniesDataByIds(ids) {
      if (!this.userHasCompaniesAccess) return [];
      return getCompanyIdsInArray(ids, this.companies);
    },
    getCompaniesData: function getCompaniesData(contract) {
      if (this.isExtendedParties) {
        return {
          external: this.getCompaniesDataByIds(this.getCompanyIds(contract, CONTRACT_PARTY_TYPES.EXTERNAL)),
          internal: this.getCompaniesDataByIds(this.getCompanyIds(contract, CONTRACT_PARTY_TYPES.INTERNAL))
        };
      }
      return this.getCompaniesDataByIds(this.getCompanyIds(contract));
    },
    getCompanyIds: function getCompanyIds(contract, type) {
      var _contract$links;
      if (this.isExtendedParties) {
        var list = contract.contractParties || [];
        if (type) {
          list = list.filter(function (_ref) {
            var contractPartyType = _ref.contractPartyType;
            return contractPartyType === type;
          });
        }
        return list.map(function (party) {
          return party.companyId;
        });
      }
      return (_contract$links = contract.links) === null || _contract$links === void 0 ? void 0 : _contract$links[MODULES.COMPANY];
    },
    afterOnStateChanged: function afterOnStateChanged() {
      var _this = this;
      if (!this.isExtendedParties) return;
      this.maxExternalParties = 120;
      this.maxInternalParties = 120;
      this.datasetSlice.forEach(function (item) {
        var _item$externalParties, _item$internalParties;
        item === null || item === void 0 || (_item$externalParties = item.externalParties) === null || _item$externalParties === void 0 || _item$externalParties.field.forEach(function (party) {
          _this.maxExternalParties = Math.max(_this.maxExternalParties, getTextSize(party.name, '16px Inter'));
        });
        item === null || item === void 0 || (_item$internalParties = item.internalParties) === null || _item$internalParties === void 0 || _item$internalParties.field.forEach(function (party) {
          _this.maxInternalParties = Math.max(_this.maxInternalParties, getTextSize(party.name, '16px Inter'));
        });
      });
    }
  }
};