function _objectDestructuringEmpty(t) { if (null == t) throw new TypeError("Cannot destructure " + t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'CompactTitleDataComponent',
  description: 'compact is used to show component taken from prepareCompactTitleData data',
  components: {
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    slotData: {
      required: true,
      type: Object,
      note: 'slot Data'
    }
  },
  computed: {
    attrs: function attrs() {
      var _ref = this.$attrs || {},
        listeners = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
      delete listeners.class;
      return listeners;
    },
    titleClass: function titleClass() {
      var _this$slotData$active;
      return ((_this$slotData$active = this.slotData.active) !== null && _this$slotData$active !== void 0 ? _this$slotData$active : true) ? '' : 'line-through';
    },
    isControl: function isControl() {
      return this.slotData.objType === ObjectTypes.CONTROLS_CONTROL || this.slotData.objType === ObjectTypes.CONTROLS_CONTROL_LIMITED;
    },
    isAction: function isAction() {
      return this.slotData.objType === ObjectTypes.EXECUTION_INITIATIVE || this.slotData.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED;
    }
  }
};