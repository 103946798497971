import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "m-0 bg-gray-125 overflow-auto max-h-80"
};
var _hoisted_2 = {
  key: 1,
  class: "m-0 bg-gray-125 overflow-auto max-h-80"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_checkboxes = _resolveComponent("checkboxes");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_select, {
    options: $data.RismaSelectOptions,
    "selected-options": $data.RismaSelectOptionsSelected,
    "label-key": "option",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $data.RismaSelectOptionsSelected = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options"]), _cache[4] || (_cache[4] = _createElementVNode("span", null, "Output:", -1 /* HOISTED */)), $data.RismaSelectOptionsSelected.length ? (_openBlock(), _createElementBlock("pre", _hoisted_1, [_createElementVNode("code", null, _toDisplayString($data.RismaSelectOptionsSelected), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[6] || (_cache[6] = _createElementVNode("p", null, "RismaSelect without 'SelectAll' feature, just add `:enable-select-all=\"false\"`", -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    "enable-select-all": false,
    options: $data.RismaSelectOptions,
    "selected-options": [],
    "label-key": "option",
    placeholder: _ctx.$trans("It's a placeholder")
  }, null, 8 /* PROPS */, ["options", "placeholder"]), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[8] || (_cache[8] = _createElementVNode("p", null, "RismaSelect with custom markup to option by slot", -1 /* HOISTED */)), _createVNode(_component_risma_select, {
    "enable-select-all": false,
    options: $data.RismaSelectCustomlabel,
    "selected-options": $data.RismaSelectCustomlabelSelected,
    "label-key": "title",
    placeholder: _ctx.$trans("It's a placeholder"),
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.RismaSelectCustomlabelSelected = $event;
    })
  }, {
    optionCustom: _withCtx(function (props) {
      return [_createElementVNode("span", null, _toDisplayString(props.option.title), 1 /* TEXT */), _createVNode(_component_feather_icon, {
        icon: props.option.icon,
        class: "stroke-3 inline"
      }, null, 8 /* PROPS */, ["icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["options", "selected-options", "placeholder"]), _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[10] || (_cache[10] = _createElementVNode("p", null, "RismaSelect with options grouped", -1 /* HOISTED */)), _createVNode(_component_checkboxes, {
    options: {
      label: 'Clickable Group Selection',
      checked: false
    },
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return $data.groupSelectable = $event.isChecked;
    })
  }), _createVNode(_component_risma_select, {
    options: $data.groupedOptions,
    "selected-options": $data.groupedSelectedOptions,
    "label-key": "name",
    "enable-select-all": false,
    "group-values": "libs",
    "group-label": "language",
    "group-select": $data.groupSelectable,
    "track-by": "name",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $data.groupedSelectedOptions = $event;
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "group-select"]), _cache[11] || (_cache[11] = _createElementVNode("span", null, "Output:", -1 /* HOISTED */)), $data.groupedSelectedOptions.length ? (_openBlock(), _createElementBlock("pre", _hoisted_2, [_createElementVNode("code", null, _toDisplayString($data.groupedSelectedOptions), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}