function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import StaticTable from '@/components/Molecules/StaticTable';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { searchByFields } from '@/utils/search';
import { compareStrings } from '@/utils/compare';
import { escapeHtml } from '@/utils';
import { addCompany } from '@/api/admin/companies';
import { getCVRCompany } from '@/api/external/cvrapi';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { TitleTypes } from '@/constants/TitleTypes';
import { COUNTRIES_BY_COUNTRY_CODE } from '@/constants/admin/countries';
import { getVatNoAndCountryCodeAsObj, getCVRQueryParam } from '@/utils/format/companies';
export default {
  name: 'AddContractPartyModal',
  description: 'Modal for adding contract party',
  token: '<add-contact-person-modal />',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    InputField: InputField,
    StaticTable: StaticTable,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    NoSearchResults: NoSearchResults,
    Notification: Notification,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  props: {
    allCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Needed for external button'
    },
    linkedCompanies: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['addNewCompany', 'addInternalCompany', 'dismiss'],
  data: function data() {
    return {
      headerType: TitleTypes.BIG,
      searchValue: '',
      externalResults: [],
      internalResults: [],
      errorMessage: '',
      loading: false,
      searchTimeout: null,
      StaticTableThemes: StaticTableThemes,
      translate: getTranslate['AddContractPartyModal']()
    };
  },
  computed: {
    internalSearch: function internalSearch() {
      var columns = this.columns.slice(0, this.columns.length - 1);
      return {
        title: '',
        columns: columns,
        dataset: this.prepareDataset(this.internalResults, false)
      };
    },
    sortedCompanies: function sortedCompanies() {
      return _toConsumableArray(this.allCompanies).sort(function (a, b) {
        return compareStrings(a.name, b.name, false, 'en');
      });
    },
    externalSearch: function externalSearch() {
      return {
        title: this.getSearchResultsTitle(this.searchValue),
        columns: this.columns,
        dataset: this.prepareDataset(this.externalResults, true),
        isExternal: true,
        errorMessage: this.errorMessage
      };
    },
    searchResults: function searchResults() {
      var result = [];
      var showExternal = this.externalSearch.dataset.length;
      if (this.internalSearch.dataset.length) {
        result.push(this.internalSearch);
      }
      if (showExternal) {
        result.push(this.externalSearch);
      }
      return result;
    },
    searchNotification: function searchNotification() {
      return this.sortedCompanies.length ? undefined : '';
    },
    columns: function columns() {
      return [this.translate.name, this.translate.address, this.translate.city, this.translate.vatNumber, ''];
    }
  },
  watch: {
    searchValue: function searchValue() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(this.handleSearch, this.$debounceTimeout / 3); // 1
    }
  },
  beforeUnmount: function beforeUnmount() {
    clearTimeout(this.searchTimeout);
  },
  methods: {
    handleCompanyCreation: function handleCompanyCreation(externalCompany) {
      var _this = this;
      addCompany(externalCompany).then(function (company) {
        _this.$emit('addNewCompany', company);
      }).catch(function (error) {
        var _error$fullError;
        return _this.errorMessage = error === null || error === void 0 || (_error$fullError = error.fullError) === null || _error$fullError === void 0 ? void 0 : _error$fullError.message;
      });
    },
    handleSearch: function handleSearch() {
      var _this2 = this;
      clearTimeout(this.searchTimeout);
      if (!this.searchValue.length) {
        this.externalResults = [];
        this.internalResults = [];
        return;
      }
      var _getVatNoAndCountryCo = getVatNoAndCountryCodeAsObj(this.searchValue),
        countryCode = _getVatNoAndCountryCo.countryCode,
        vatNo = _getVatNoAndCountryCo.vatNo;
      this.setInternalResults();
      this.errorMessage = '';
      this.loading = true;
      return getCVRCompany(vatNo, getCVRQueryParam(countryCode)).then(function (_ref) {
        var list = _ref.list;
        return _this2.setExternalResult(list);
      }).catch(function () {
        var _this2$internalSearch;
        if (!((_this2$internalSearch = _this2.internalSearch) !== null && _this2$internalSearch !== void 0 && _this2$internalSearch.dataset.length)) {
          _this2.errorMessage = _this2.translate.noResultsFound;
        }
      }).finally(function () {
        _this2.loading = false;
      });
    },
    setExternalResult: function setExternalResult() {
      var _this3 = this;
      var companies = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var result = [];
      companies.forEach(function (company) {
        if (!_this3.allCompanies.find(function (item) {
          return item.vatNo === company.vatNo;
        })) {
          result.push(company);
        }
      });
      this.externalResults = result;
    },
    prepareDataset: function prepareDataset(array, isExternal) {
      var _this4 = this;
      return array.map(function (item) {
        var result = {
          name: escapeHtml(item.name) || '',
          address: escapeHtml(item.address) || '',
          city: escapeHtml(item.city) || '',
          vatNo: item.vatNo || ''
        };
        if (isExternal) {
          result.add = {
            item: item
          };
        } else {
          result.additionalRowClasses = _this4.linkedCompanies.includes(item.id) ? 'line-through cursor-not-allowed' : '';
        }
        return result;
      });
    },
    handleRowClick: function handleRowClick(rowIndex, item) {
      if (item.isExternal || this.linkedCompanies.includes(this.internalResults[rowIndex].id)) return;
      this.$emit('addInternalCompany', this.internalResults[rowIndex].id);
    },
    getSearchResultsTitle: function getSearchResultsTitle(searchValue) {
      if (!searchValue) return '';
      var obj = getVatNoAndCountryCodeAsObj(searchValue);
      var countryName = COUNTRIES_BY_COUNTRY_CODE[obj.countryCode];
      if (!countryName) {
        return '';
      }
      return this.$trans("Search results from ".concat(countryName, " register"));
    },
    setInternalResults: function setInternalResults() {
      var fields = ['email', 'vatNo', 'name', 'address'];
      this.internalResults = searchByFields(this.sortedCompanies, fields, this.searchValue);
    }
  }
};