import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "breadcrumbs flex flex-wrap glob-b1"
};
var _hoisted_2 = {
  key: 0,
  class: "mr-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.label ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.label) + ":", 1 /* TEXT */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "left mb-1"
    }, [item.url ? (_openBlock(), _createBlock(_component_router_link, {
      key: 0,
      class: _normalizeClass(["hover:underline hover:text-rBlack-500", [$options.currentIndex === index || $props.disableHoverLogic ? 'text-rBlack-500' : 'text-rGrey-700']]),
      to: item.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "to"])) : (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: _normalizeClass([$options.currentIndex === index || $props.disableHoverLogic ? 'text-rBlack-500' : 'text-rGrey-700', {
        'line-through': $options.currentIndex !== index && !$props.disableHoverLogic
      }])
    }, _toDisplayString(item.title), 3 /* TEXT, CLASS */)), item.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 2,
      icon: "check",
      class: "stroke-2 inline-block ml-1"
    })) : _createCommentVNode("v-if", true), index + 1 < $props.items.length ? (_openBlock(), _createElementBlock("span", {
      key: 3,
      class: _normalizeClass(["mx-2", $props.disableHoverLogic ? 'text-rBlack-500' : 'text-rGrey-700'])
    }, _toDisplayString($props.splitter), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]);
}