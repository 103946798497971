import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  key: 2,
  class: "flex"
};
var _hoisted_3 = {
  class: "w-3/10 mr-12"
};
var _hoisted_4 = {
  class: "flex-grow"
};
var _hoisted_5 = {
  key: 1,
  class: "min-h-220px pt-6 pb-4"
};
var _hoisted_6 = {
  key: 2
};
var _hoisted_7 = {
  key: 3,
  class: "mb-4"
};
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  class: "v-else"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_dpm_send_questionnaire_modal = _resolveComponent("dpm-send-questionnaire-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_risma_step_progress_bar = _resolveComponent("risma-step-progress-bar");
  var _component_dpm_action_modal = _resolveComponent("dpm-action-modal");
  var _component_questionnaire = _resolveComponent("questionnaire");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    class: "mb-2"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString($data.notificationError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.showQuestionnaireModal ? (_openBlock(), _createBlock(_component_dpm_send_questionnaire_modal, {
    key: 1,
    "audit-questionnaires": $options.modalQuestionnaires,
    "prefill-company": $props.data.name || $props.data.title,
    "prefill-email": $props.data.email,
    "prefill-name": $props.data.contactName,
    onUpdate: $options.createNewDpmRequest,
    onDismiss: $options.dismissQuestionnaireModal
  }, null, 8 /* PROPS */, ["audit-questionnaires", "prefill-company", "prefill-email", "prefill-name", "onUpdate", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.auditTitle,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), !$props.readOnly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.startNewAudit,
    type: "save",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showQuestionnaireModal = true;
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true)]), $options.existingAudits.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.auditList,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_vertical_menu, {
    items: $data.menuItems,
    "selected-id": $data.currentAudit.id,
    removable: !$props.readOnly && !$props.data.archived,
    onChangeItem: $options.loadAudit,
    onRemoveItem: $options.showDeleteAlert
  }, null, 8 /* PROPS */, ["items", "selected-id", "removable", "onChangeItem", "onRemoveItem"])]), _createElementVNode("div", _hoisted_4, [$data.progressData.firstItem ? (_openBlock(), _createBlock(_component_risma_step_progress_bar, {
    key: 0,
    data: $data.progressData
  }, null, 8 /* PROPS */, ["data"])) : _createCommentVNode("v-if", true), $options.showActionPanel ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.actions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), $data.progressData.secondItem.state === 'declined' ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.extendDeadline,
    type: "save",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.showActionModal('extendDeadline');
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), $data.progressData.thirdItem.state === 'approved' && $data.currentAudit.moreInfoRequested === 0 ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.askForMoreInfo,
    type: "save",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.showActionModal('askForMoreInfo');
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), $data.currentAudit.moreInfoRequested === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("i", null, _toDisplayString($data.translate.nbYouHaveAskedForMoreInfoOnThisQuestionn), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.isModalOpened ? (_openBlock(), _createBlock(_component_dpm_action_modal, {
    key: 3,
    message: $data.currentAudit.message,
    header: $options.dpmActionGroup[$data.dpmActionType].header,
    "main-text": $options.dpmActionGroup[$data.dpmActionType].mainText,
    onAcceptModal: $options.dpmActionGroup[$data.dpmActionType].accept,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return $data.isModalOpened = false;
    })
  }, null, 8 /* PROPS */, ["message", "header", "main-text", "onAcceptModal"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $options.existingAudits.length === 0 && $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_7, [$props.readOnly ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("p", _hoisted_9, _toDisplayString($data.translate.youHaveNotCreatedAnyAuditsYetClickTheBut), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.questions && $data.isLoaded ? (_openBlock(), _createBlock(_component_questionnaire, {
    key: 4,
    questionnaire: $options.dataQuestionnaire,
    "information-assets": $data.informationAssets,
    "local-storage-key": "ia_processor_dpm_".concat($props.id, "_").concat($options.dataQuestionnaire.id),
    questions: $data.questions,
    "need-save-button": false,
    "read-only": true,
    title: $props.data.title || ''
  }, null, 8 /* PROPS */, ["questionnaire", "information-assets", "local-storage-key", "questions", "title"])) : _createCommentVNode("v-if", true)]);
}