import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "py-10"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  class: "text-title-small"
};
var _hoisted_5 = {
  class: "text-title-small"
};
var _hoisted_6 = {
  key: 0,
  class: "border-b-2 border-gray-20"
};
var _hoisted_7 = {
  key: 1,
  class: "text-center py-20 border-b-2 border-gray-20"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_text_selector = _resolveComponent("text-selector");
  var _component_activity_feed_card = _resolveComponent("activity-feed-card");
  var _component_risma_carousel = _resolveComponent("risma-carousel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.currentActivitiesTitle
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, [_createTextVNode(_toDisplayString($data.translate.activitiesThatHaveADeadlineWithin) + " ", 1 /* TEXT */), _createVNode(_component_text_selector, {
    modelValue: $options.computedDeadlineFilterValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.computedDeadlineFilterValue = $event;
    }),
    options: $props.deadlineFilterOptions
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.computedDeadlineFilterValue) + " " + _toDisplayString($data.translate.days), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "options"])])]), _createElementVNode("span", _hoisted_5, [_createTextVNode(_toDisplayString($data.translate.andNewActivitiesAssignedToYouInTheLast) + " ", 1 /* TEXT */), _createVNode(_component_text_selector, {
    modelValue: $options.computedRecentFilterValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.computedRecentFilterValue = $event;
    }),
    options: $props.recentFilterOptions
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.computedRecentFilterValue) + " " + _toDisplayString($data.translate.days), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "options"])]), $props.activities.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_carousel, {
    class: "mx-auto mt-10",
    list: $props.activities
  }, {
    default: _withCtx(function (_ref) {
      var slide = _ref.slide;
      return [_createVNode(_component_activity_feed_card, _mergeProps({
        activity: slide,
        projects: $props.projects,
        class: "px-4"
      }, _ctx.$attrs), null, 16 /* FULL_PROPS */, ["activity", "projects"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["list"])])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    type: "medium",
    class: "italic",
    title: $data.translate.fantasticYouHaveNoOutstandingActivitiesFor
  }, null, 8 /* PROPS */, ["title"])]))]);
}