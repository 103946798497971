import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "dt-wrapper"
};
var _hoisted_2 = {
  class: "btn-group",
  role: "group"
};
var _hoisted_3 = {
  id: "tableContainer"
};
var _hoisted_4 = {
  id: "table",
  class: "table"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = ["title"];
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
    class: "btn btn-transparent mr-2",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleExportToExcel();
    })
  }, [_cache[2] || (_cache[2] = _createElementVNode("i", {
    class: "fa fa-download"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.exportToExcel), 1 /* TEXT */)]), _createElementVNode("button", {
    class: "btn btn-transparent",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.handlePrintTable();
    })
  }, [_cache[3] || (_cache[3] = _createElementVNode("i", {
    class: "fa fa-print"
  }, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.printTheTable), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("table", _hoisted_4, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, _toDisplayString($data.translate.annexA), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.description), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.maturityLevel), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rows[0].cells, function (question, index) {
    return _openBlock(), _createElementBlock("th", {
      key: index
    }, _toDisplayString("".concat(question.number, ". ").concat(question.question)), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("th", null, _toDisplayString($data.translate.analysis), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.riskAssessment), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.linkedProcesses), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.linkedControls), 1 /* TEXT */), _createElementVNode("th", null, _toDisplayString($data.translate.linkedInitiatives), 1 /* TEXT */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($props.rows), function (key) {
    return _openBlock(), _createElementBlock("tr", {
      key: key
    }, [_createElementVNode("td", null, [_createVNode(_component_router_link, {
      to: "/compliance/edit/".concat($props.rows[key].nodeId)
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString($props.rows[key].title), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]), _createElementVNode("td", {
      class: "list-reset-styles",
      innerHTML: $props.rows[key].description
    }, null, 8 /* PROPS */, _hoisted_5), _createElementVNode("td", null, [_createVNode(_component_traffic_light, {
      color: $options.getTrafficLightColor($props.rows[key])
    }, null, 8 /* PROPS */, ["color"])]), _createCommentVNode(" Questions "), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rows[key].cells, function (cell, index) {
      return _openBlock(), _createElementBlock("td", {
        key: index,
        title: "".concat(cell.number, ") ").concat(cell.question)
      }, [cell.answers ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cell.answers, function (answer, i) {
        return _openBlock(), _createElementBlock("li", {
          key: i,
          innerHTML: answer
        }, null, 8 /* PROPS */, _hoisted_8);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_6);
    }), 128 /* KEYED_FRAGMENT */)), _createCommentVNode(" GAP info "), _createElementVNode("td", null, _toDisplayString($options.getGapAnalysis($props.rows[key])), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.getGapControlAndInitiativeAnalysis($props.rows[key])), 1 /* TEXT */), _createCommentVNode(" Linked activities "), _createElementVNode("td", null, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getLinkedProcesses($props.rows[key]), function (process, index) {
      return _openBlock(), _createElementBlock("li", {
        key: index
      }, [_createVNode(_component_router_link, {
        to: "".concat(process.link)
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(process.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
    }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("td", null, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getLinkedControls($props.rows[key]), function (process, index) {
      return _openBlock(), _createElementBlock("li", {
        key: index
      }, [_createVNode(_component_router_link, {
        to: "".concat(process.link)
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(process.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
    }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("td", null, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getLinkedInitiatives($props.rows[key]), function (process, index) {
      return _openBlock(), _createElementBlock("li", {
        key: index
      }, [_createVNode(_component_router_link, {
        to: "".concat(process.link)
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(process.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
    }), 128 /* KEYED_FRAGMENT */))])])]);
  }), 128 /* KEYED_FRAGMENT */))])])])]);
}