function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { getTranslate } from './translate';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { createReport as _createReport, getSuggestedDataForTopLevelNode } from '@/api/compliance/reports/outsourcing';
export default {
  name: 'CreateNewReport',
  components: {
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect
  },
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    nodeId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  emits: ['error'],
  data: function data() {
    return {
      loading: false,
      registerNodeId: null,
      selectedRegisterQuestions: [],
      preliminaryAnalysisNodeId: null,
      selectedPreliminaryAnalysisGapArticles: [],
      assessmentNodeId: null,
      selectedAssessmentNodeGapArticles: [],
      importantOrCriticalNodeId: null,
      topLevelNodes: [],
      topLevelNode: null,
      details: {},
      translate: getTranslate['CreateNewReport']()
    };
  },
  computed: {
    childrenNodesForSelect: function childrenNodesForSelect() {
      if (!this.details.childrenNodes) {
        return [];
      }
      return this.details.childrenNodes.map(function (childNode) {
        return {
          value: childNode.id,
          label: childNode.title
        };
      });
    },
    childrenNodeMap: function childrenNodeMap() {
      var map = new Map();
      if (!this.details.childrenNodes) {
        return map;
      }
      this.details.childrenNodes.forEach(function (child) {
        map.set(child.id, child);
      });
      return map;
    },
    preliminaryAnalysisNodeGapArticles: function preliminaryAnalysisNodeGapArticles() {
      return this.getGapsByNodeId(this.preliminaryAnalysisNodeId);
    },
    assessmentNodeGapArticles: function assessmentNodeGapArticles() {
      return this.getGapsByNodeId(this.assessmentNodeId);
    },
    registerQuestions: function registerQuestions() {
      return this.getQuestionsByNodeId(this.registerNodeId);
    },
    registerNodeQuestionIds: function registerNodeQuestionIds() {
      return this.selectedRegisterQuestions.map(function (question) {
        return question.id;
      });
    },
    preliminaryAnalysisNodeGapIds: function preliminaryAnalysisNodeGapIds() {
      return this.selectedPreliminaryAnalysisGapArticles.map(function (gapArticle) {
        return gapArticle.id;
      });
    },
    assessmentNodeGapIds: function assessmentNodeGapIds() {
      return this.selectedAssessmentNodeGapArticles.map(function (gapArticle) {
        return gapArticle.id;
      });
    },
    blocksQuery: function blocksQuery() {
      var nodeId = this.nodeId,
        registerNodeId = this.registerNodeId,
        registerNodeQuestionIds = this.registerNodeQuestionIds,
        preliminaryAnalysisNodeGapIds = this.preliminaryAnalysisNodeGapIds,
        preliminaryAnalysisNodeId = this.preliminaryAnalysisNodeId,
        assessmentNodeId = this.assessmentNodeId,
        assessmentNodeGapIds = this.assessmentNodeGapIds,
        importantOrCriticalNodeId = this.importantOrCriticalNodeId;
      return {
        topNodeId: nodeId,
        registerNodeId: registerNodeId,
        registerNodeQuestionIds: registerNodeQuestionIds,
        preliminaryAnalysisNodeId: preliminaryAnalysisNodeId,
        preliminaryAnalysisNodeGapIds: preliminaryAnalysisNodeGapIds,
        assessmentNodeId: assessmentNodeId,
        assessmentNodeGapIds: assessmentNodeGapIds,
        importantOrCriticalNodeId: importantOrCriticalNodeId
      };
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.loading = true;
    this.fetchTopLevelNode().then(function () {
      return _this.loading = false;
    }).catch(function (error) {
      if (error.response && error.response.error) {
        _this.$emit('error', error.response.error);
      }
    });
  },
  methods: {
    fetchTopLevelNode: function fetchTopLevelNode() {
      var _this2 = this;
      return getSuggestedDataForTopLevelNode(this.projectId, this.nodeId).then(function (response) {
        _this2.details = response;
        _this2.registerNodeId = response.registerNodeId;
        _this2.selectedRegisterQuestions = _this2.registerQuestions.filter(function (question) {
          return response.registerQuestionIds.indexOf(question.id) !== -1;
        });
        _this2.preliminaryAnalysisNodeId = response.preliminaryAnalysisNodeId;
        _this2.selectedPreliminaryAnalysisGapArticles = _this2.preliminaryAnalysisNodeGapArticles.filter(function (gap) {
          return response.preliminaryAnalysisGapIds.indexOf(gap.id) !== -1;
        });
        _this2.assessmentNodeId = response.assessmentNodeId;
        _this2.selectedAssessmentNodeGapArticles = _this2.assessmentNodeGapArticles.filter(function (gap) {
          return response.assessmentNodeGapIds.indexOf(gap.id) !== -1;
        });
        _this2.importantOrCriticalNodeId = +response.importantOrCriticalNodeId;
      });
    },
    getGapsByNodeId: function getGapsByNodeId(nodeId) {
      return this.getQuestionsOrGapsByNodeId(nodeId, true);
    },
    getQuestionsByNodeId: function getQuestionsByNodeId(nodeId) {
      return this.getQuestionsOrGapsByNodeId(nodeId, false);
    },
    getQuestionsOrGapsByNodeId: function getQuestionsOrGapsByNodeId(nodeId, isGap) {
      var _ref = isGap ? ['gapSchemaId', 'gapArticles'] : ['questionnaireId', 'questions'],
        _ref2 = _slicedToArray(_ref, 2),
        idProperty = _ref2[0],
        dataProperty = _ref2[1];
      var node = this.childrenNodeMap.get(nodeId);
      if (!node || !node[idProperty]) {
        return [];
      }
      var id = node[idProperty];
      return this.details[dataProperty][id] || [];
    },
    questionLabelFunction: function questionLabelFunction(question) {
      if (question.id === '0') {
        return question.question; // Select all hack
      }
      return question.questionnumber + ' ' + question.question;
    },
    gapLabelFunction: function gapLabelFunction(gap) {
      if (gap.id === '0') {
        return gap.article; // Select all hack
      }
      return gap.article + ' ' + gap.articleTitle;
    },
    createReport: function createReport() {
      var _this3 = this;
      _createReport(this.projectId, this.nodeId, this.blocksQuery).then(function (report) {
        _this3.$router.push({
          name: 'OutsourcingReportShow',
          params: {
            reportId: report.id
          }
        });
      });
    }
  }
};