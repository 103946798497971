function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import { RelatedOptionalFields } from '@/constants/OptionalFields';
export default {
  name: 'OptionalFieldsTable',
  components: {
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon,
    CollapsiblePanel: CollapsiblePanel,
    RismaTitle: RismaTitle,
    RadioButtons: RadioButtons
  },
  props: {
    modelValue: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of active optional fields'
    },
    optionalFields: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      fieldsStatus: {
        dontShow: 'dont-show',
        active: 'active'
      },
      translate: getTranslate['OptionalFieldsTable']()
    };
  },
  computed: {
    optionalFieldsOptions: function optionalFieldsOptions() {
      return [{
        value: this.fieldsStatus.dontShow
      }, {
        value: this.fieldsStatus.active
      }];
    },
    radioBtnValuesMap: function radioBtnValuesMap() {
      var _this = this;
      return this.optionalFields.reduce(function (acc, current) {
        if (_this.modelValue.indexOf(current.value) === -1) {
          acc[current.value] = _this.fieldsStatus.dontShow;
        } else {
          acc[current.value] = _this.fieldsStatus.active;
        }
        return acc;
      }, {});
    },
    radioBtnDisabledMap: function radioBtnDisabledMap() {
      var _this2 = this;
      var result = {};
      Object.keys(this.radioBtnValuesMap).forEach(function (optionFieldName) {
        if (_this2.radioBtnValuesMap[optionFieldName] === _this2.fieldsStatus.dontShow && RelatedOptionalFields[optionFieldName]) {
          result[RelatedOptionalFields[optionFieldName]] = true;
        }
      });
      return result;
    }
  },
  methods: {
    onChangeRadio: function onChangeRadio(event, optionFieldName) {
      var _this3 = this;
      if (event === this.radioBtnValuesMap[optionFieldName]) return;
      if (optionFieldName in RelatedOptionalFields && event === this.fieldsStatus.dontShow) {
        this.radioBtnValuesMap[RelatedOptionalFields[optionFieldName]] = event;
      }
      this.radioBtnValuesMap[optionFieldName] = event;
      var activeFields = _toConsumableArray(this.modelValue);
      activeFields = this.optionalFields.reduce(function (acc, current) {
        if (_this3.radioBtnValuesMap[current.value] === _this3.fieldsStatus.active) {
          acc.push(current.value);
        }
        return acc;
      }, []);
      this.$emit('update:modelValue', activeFields);
    },
    moveItem: function moveItem() {
      var _this4 = this;
      var result = [];
      this.optionalFields.forEach(function (item) {
        if (_this4.modelValue.indexOf(item.value) !== -1) {
          result.push(item.value);
        }
      });
      this.$emit('update:modelValue', result);
    }
  }
};