function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ObjectTypes } from '@/constants/ObjectTypes';
export var QuestionnaireTypes = {
  GDPR: 1,
  ISO27001: 2,
  ISO9001: 3,
  AML: 4,
  ABC: 5,
  PATENT: 6,
  GENERIC: 7,
  OUTSOURCING: 11,
  DATACONTROLLER: 101,
  DATACONTROLLER_AUDIT: 109,
  DATAPROCESSOR: 102,
  SYSTEM: 103,
  SYSTEM_AUDIT: 108,
  SUBPROCESSOR: 104,
  DPM: 105,
  IA_CUSTOM: 106,
  IAAUDIT: 110,
  RISK: 201,
  INCIDENT: 202,
  CONTRACT: 203
};
export var QuestionnaireTypesAudit = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 109), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 105), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 108), ObjectTypes.CONTRACT, 107), ObjectTypes.INFORMATION_ASSET, 110);