function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { getTranslate } from './translate';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ColorPicker from '@/components/Molecules/ColorPicker';
import TrafficLightModal from '@/components/Molecules/Modal/TrafficLightModal';
import InputField from '@/components/Atoms/Inputs/InputField';
import { getAllTrafficLights, updateTrafficLight, createTrafficLight as _createTrafficLight, deleteTrafficLight } from '@/api/admin/trafficLights';
import { getDefaultTrafficLights } from '@/Colors';
import { capitalize } from '@/utils';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { cacheItem } from '@/routes/storeCache';
export default {
  name: 'TrafficLightsLookFeelPage',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    ColorPicker: ColorPicker,
    RismaButton: RismaButton,
    TrafficLightModal: TrafficLightModal,
    FeatherIcon: FeatherIcon,
    InputField: InputField
  },
  data: function data() {
    return {
      trafficLights: {},
      isLoaded: false,
      capitalize: capitalize,
      MODULES: MODULES,
      isModalOpened: false,
      defaultTrafficLights: getDefaultTrafficLights(),
      textColorField: '#FFF',
      translate: getTranslate['TrafficLightsLookFeelPage']()
    };
  },
  computed: {
    modules: function modules() {
      return Object.keys(this.trafficLights);
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, {
    loadTrafficLights: 'load'
  })), {}, {
    canBeDeleted: function canBeDeleted(_ref) {
      var module = _ref.module,
        number = _ref.number;
      return module === MODULES.INCIDENTS || !this.defaultTrafficLights[module].find(function (element) {
        return element.number === number;
      });
    },
    getTrafficLightName: function getTrafficLightName(trafficLight) {
      if (trafficLight === MODULES.COMPLIANCE_GAP) return 'Gaps';
      return capitalize(trafficLight);
    },
    getData: function getData() {
      var _this = this;
      this.isLoaded = false;
      return getAllTrafficLights().then(function (data) {
        delete data._debug;
        _this.trafficLights = data;
        _this.sortTrafficLights();
        _this.isLoaded = true;
      });
    },
    sortTrafficLights: function sortTrafficLights() {
      var _this2 = this;
      var keys = Object.keys(this.trafficLights);
      keys.forEach(function (key) {
        if (key === ObjectTypes.COMPANY) {
          delete _this2.trafficLights[key];
          return;
        }
        _this2.trafficLights[key] = _this2.trafficLights[key].sort(function (a, b) {
          return a.number - b.number;
        });
      });
    },
    resetTrafficLightsByModule: function resetTrafficLightsByModule(module) {
      var _this3 = this;
      var list = this.trafficLights[module];
      var promises = [];
      list.forEach(function (element) {
        var trafficLightUpdate = _this3.defaultTrafficLights[element.module].find(function (item) {
          return item.number === element.number;
        });
        if (trafficLightUpdate && (element.color.toLowerCase() !== trafficLightUpdate.color.toLowerCase() || element.legend.toLowerCase() !== trafficLightUpdate.legend.toLowerCase())) {
          element.color = trafficLightUpdate.color;
          element.legend = trafficLightUpdate.legend;
          promises.push(_this3.patchTrafficLight(element));
        }
        if (!trafficLightUpdate && module !== 'default') {
          promises.push(_this3.deleteTrafficLightAPI(element));
        }
      });
      if (!promises.length) return;
      Promise.all(promises).then(function () {
        _this3.handleUpdateTrafficLightResult("".concat(_this3.translate.trafficLight, " ").concat(_this3.getTrafficLightName(module), " ").concat(_this3.translate.updated));
      });
    },
    handleUpdateTrafficLightResult: function handleUpdateTrafficLightResult(text) {
      this.$notify({
        title: text
      });
      this.refreshTrafficLight();
    },
    updateTrafficLight: function updateTrafficLight(trafficLight, color) {
      var _this4 = this;
      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'color';
      var data = this.trafficLights[trafficLight.module].find(function (item) {
        return item.id === trafficLight.id;
      });
      if (data[field] === color) return;
      data[field] = color;
      if (field === 'text_color') {
        data.textColor = color;
      }
      return this.patchTrafficLight(data).then(function (response) {
        _this4.handleUpdateTrafficLightResult("".concat(_this4.getTrafficLightName(response.module), " - ").concat(capitalize(response.legend), " ").concat(_this4.translate.updated));
      });
    },
    handleTrafficLightCreation: function handleTrafficLightCreation(data) {
      var _this5 = this;
      return this.createTrafficLight(data).then(function () {
        return _this5.isModalOpened = false;
      }).catch(function (error) {
        return _this5.$notify({
          title: error.response.error,
          type: 'error'
        });
      });
    },
    refreshTrafficLight: function refreshTrafficLight() {
      var _this6 = this;
      cacheItem('trafficlights', function () {
        return _this6.loadTrafficLights();
      }, 180);
    },
    patchTrafficLight: function patchTrafficLight(data) {
      return updateTrafficLight(data);
    },
    handleDeleteTrafficLight: function handleDeleteTrafficLight(data) {
      var _this7 = this;
      this.$confirm('', this.translate.areYouSure, function (res) {
        return res && _this7.deleteTrafficLight(data);
      });
    },
    deleteTrafficLightAPI: function deleteTrafficLightAPI(data) {
      var _this8 = this;
      return deleteTrafficLight(data.id).then(function () {
        _this8.trafficLights[data.module] = _this8.trafficLights[data.module].filter(function (item) {
          return item.id !== data.id;
        });
      });
    },
    deleteTrafficLight: function deleteTrafficLight(data) {
      var _this9 = this;
      return this.deleteTrafficLightAPI(data).then(function () {
        _this9.handleUpdateTrafficLightResult("".concat(_this9.getTrafficLightName(data.module), " - ").concat(capitalize(data.legend), " ").concat(_this9.translate.deleted));
      }).catch(function (error) {
        return _this9.$notify({
          title: error.response.error,
          type: 'error'
        });
      });
    },
    createTrafficLight: function createTrafficLight(data) {
      var _this10 = this;
      var list = this.trafficLights[data.module].map(function (item) {
        return item.number;
      });
      data.number = Math.max.apply(Math, _toConsumableArray(list)) + 1;
      return _createTrafficLight(data).then(function (response) {
        _this10.trafficLights[data.module].push(response);
        _this10.handleUpdateTrafficLightResult("".concat(_this10.getTrafficLightName(response.module), " - ").concat(capitalize(response.legend), " ").concat(_this10.translate.created));
      });
    },
    updateTextOnTrafficLight: function updateTextOnTrafficLight(data, trafficLight) {
      var _this11 = this;
      if (data === trafficLight.legend) return;
      trafficLight.legend = data;
      this.patchTrafficLight(trafficLight).then(function (response) {
        _this11.handleUpdateTrafficLightResult("".concat(_this11.getTrafficLightName(response.module), " - ").concat(capitalize(response.legend), " ").concat(_this11.translate.updated));
      });
    },
    updateTextColor: function updateTextColor(text) {
      this.textColorField = text;
    }
  })
};