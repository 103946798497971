// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-6c86bc3a]  thead {
  border-bottom: 2px solid rgb(235.9, 235.9, 235.9);
}
[data-v-6c86bc3a]  thead th {
  padding-bottom: 0.375rem;
}
[data-v-6c86bc3a]  tr {
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
