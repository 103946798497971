// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-select[data-v-c2105e16] {
  width: 100%;
  clear: both;
}
.groupName[data-v-c2105e16] {
  background-color: transparent !important;
  cursor: auto;
  color: #BEBEBE !important;
}
.custom-select-inner[data-v-c2105e16] {
  position: relative;
  border-radius: 5px;
}
.custom-select-inner .select-items[data-v-c2105e16] {
  position: absolute;
  background-color: #FFFFFF;
  top: 100%;
  max-height: 30vh;
  overflow-y: auto;
  left: 0;
  right: 0;
  z-index: 9;
  overflow-x: hidden;
}
.custom-select-inner .select-items.select-hide[data-v-c2105e16] {
  display: none;
}
.option[data-v-c2105e16] {
  overflow-wrap: break-word;
  white-space: pre-line;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
