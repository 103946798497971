// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-up[data-v-594fb838] {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 15px;
  z-index: 9;
  background: #f5f5f2;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
  border-radius: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
