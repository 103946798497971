import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "m-auto break-words w-full"
};
var _hoisted_2 = {
  class: "m-auto break-words w-full"
};
var _hoisted_3 = {
  class: "m-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("li", {
    class: "tab-item align-bottom flex",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('click');
    })
  }, [!$props.link ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['tab-link', {
      disabled: $props.disabled
    }])
  }, [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2 /* CLASS */)) : !$options.isPreview ? (_openBlock(), _createBlock(_component_router_link, {
    key: 1,
    to: $props.link,
    class: _normalizeClass(['tab-link', {
      disabled: $props.disabled
    }])
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["to", "class"])) : (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(['tab-link cursor-pointer', {
      disabled: $props.disabled
    }, {
      active: $props.selected
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return _ctx.$emit('changeTab', $props.link);
    }, ["prevent"]))
  }, [_createElementVNode("span", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2 /* CLASS */))]);
}