function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { getAll as _getAll } from '@/api/compliance/awareness';
import { removeDuplicates } from '@/utils/filterArray';
export default {
  name: 'AwarenessEntry',
  components: {
    VerticalMenu: VerticalMenu,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      isLoaded: false,
      requests: [],
      translate: {
        overview: this.$trans('Overview'),
        quizQuestionnaire: this.$trans('Quiz questionnaire')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasQuiz: function hasQuiz() {
      return this.getSettingValue('feature.compliance_process_tree.awareness_quiz');
    },
    menuItems: function menuItems() {
      if (!this.hasQuiz) return [];
      var list = [{
        path: '/awareness/dashboard/overview/all',
        title: this.translate.overview
      }];
      if (this.requests.length) {
        var nodes = this.requests.map(function (item) {
          var _item$links;
          if (!(item !== null && item !== void 0 && (_item$links = item.links) !== null && _item$links !== void 0 && (_item$links = _item$links.compliance_process_tree_node) !== null && _item$links !== void 0 && _item$links.length)) return null;
          var _item$links$complianc = item.links.compliance_process_tree_node[0],
            title = _item$links$complianc.title,
            id = _item$links$complianc.id;
          return {
            title: title,
            id: id
          };
        });
        nodes = nodes.filter(function (item) {
          return item;
        });
        nodes = removeDuplicates(nodes, 'id');
        nodes.forEach(function (item) {
          list.push({
            path: "/awareness/dashboard/overview/".concat(item.id),
            title: item.title
          });
        });
      }
      return list;
    },
    bottomItems: function bottomItems() {
      return [{
        url: '/awareness/dashboard/quiz',
        title: this.translate.quizQuestionnaire
      }];
    }
  }),
  mounted: function mounted() {
    if (this.hasQuiz) this.getAll();else this.isLoaded = true;
  },
  methods: {
    getAll: function getAll() {
      var _this = this;
      this.isLoaded = false;
      return _getAll('?data[]=all').then(function (_ref) {
        var list = _ref.list;
        return _this.requests = list;
      }).finally(function () {
        return _this.isLoaded = true;
      });
    }
  }
};