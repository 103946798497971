// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-fields-wrapper[data-v-ac49c39a] {
  container: custom-fields-wrapper/inline-size;
}
.custom-fields-container[data-v-ac49c39a] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@container custom-fields-wrapper (inline-size > 570px) {
.custom-fields-container[data-v-ac49c39a] {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
}
}
.activity-field.repeatable-field[data-v-ac49c39a] {
  width: 100%;
}
.activity-field.field-type-3 textarea[data-v-ac49c39a] {
  width: 100%;
  max-width: 100%;
}
.activity-field .activity-field-component-inner[data-v-ac49c39a] {
  width: auto;
}
.activity-field .split-button[data-v-ac49c39a] {
  margin-top: 22px;
}
.activity-field .split-button[data-v-ac49c39a]  button {
  cursor: default;
  background: #637994;
  min-height: 44px;
}
.activity-field.readonly[data-v-ac49c39a] {
  margin: 0 0 10px 0;
  padding: 0 10px 0 0;
}
.activity-field.readonly .activity-field-label[data-v-ac49c39a] {
  display: none;
}
.activity-field.readonly .activity-field-component:not(.field-type-11) .activity-field-component-inner[data-v-ac49c39a] {
  display: flex;
  align-items: center;
}
.activity-field.readonly .activity-field-component.field-type-11 .activity-field-component-inner-header[data-v-ac49c39a] {
  margin-bottom: 0.5rem;
}
.activity-field.readonly .activity-field-component-inner-header[data-v-ac49c39a] {
  margin-right: 5px;
}
.activity-field[data-v-ac49c39a]  {
  /* Components */
}
.activity-field[data-v-ac49c39a]  .traffic-light {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #dedede;
  margin-left: 5px;
}
.activity-field[data-v-ac49c39a]  .mx-input-wrapper {
  min-width: auto;
}
.activity-field-component[data-v-ac49c39a]:not(.field-type-11):not(.readonly) {
  max-width: calc(33.3333333333% - 30px); /* Emulate col-4 flex */
}
.activity-field-component-inner-content[data-v-ac49c39a] {
  overflow: auto;
}
.activity-field-component-inner-content .activity-field-value[data-v-ac49c39a] {
  word-break: break-word;
  word-wrap: break-word;
}
.activity-field-component-inner-content .activity-field-simple-container svg[data-v-ac49c39a] {
  top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
