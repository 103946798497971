import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  QuizQuestionnaire: function QuizQuestionnaire() {
    return {
      createNewQuizQuestionnaire: i18n.t('Create new quiz questionnaire'),
      addNewQuestion: i18n.t('Add new question'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      question: i18n.t('Question'),
      areYouSureYouWantToCancelTheEnteredInfor: i18n.t('Are you sure you want to cancel? The entered information will not be saved'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this question? The entered information will not be saved'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      questionListCannotBeEmpty: i18n.t('Question list cannot be empty'),
      questionDescriptionCannotBeEmpty: i18n.t('Question description cannot be empty'),
      questionOptionCannotBeEmpty: i18n.t('Question option cannot be empty'),
      eachQuestionHaveToHave: i18n.t('Each question have to have more than one question option'),
      title: i18n.t('Title'),
      enterTitle: getTitlePlaceholder()
    };
  },
  QuestionCreation: function QuestionCreation() {
    return {
      headline: i18n.t('Headline'),
      ifYouAllowCreationOfNewAnswers: i18n.t('If you allow creation of new answers, you will be able to add new answer options within the questionnaire.'),
      article30Report: i18n.t('Article 30-report'),
      questionnaireReport: i18n.t('Questionnaire-report'),
      byActivatingThisFunctionalityArticle30: i18n.t('By activating this functionality, the current question will be default selected whenever you create an Article 30-report'),
      byActivatingThisFunctionalityQuestionnaireReport: i18n.t('By activating this functionality, the current question will be default selected whenever you create a Questionnaire-report'),
      theAnswersProvidedFromTheCurrentLinkedToDataFlows: i18n.t('The answers provided from the current question can be linked to the parameter(s) used to map Data Flows. You can select one or more parameters which will display the given answers.'),
      theAnswersProvidedFromTheCurrentLinkedToOutcoursingReport: i18n.t('The answers provided from the current question can be linked to the parameter(s) used to map Outcourcing report. You can select one or more parameters which will display the given answers.'),
      theAnswersProvidedFromTheCurrentAssistRiskAssessment: i18n.t('The answers provided from the current question are used to assist with risk assessment of data processors.'),
      save: i18n.t('Save'),
      create: i18n.t('Create'),
      typeHere: i18n.t('Type here'),
      questionNumber: i18n.t('Question number'),
      questionText: i18n.t('Question text'),
      questionType: i18n.t('Question type'),
      chooseCustom: i18n.t('Choose custom'),
      helpText: i18n.t('Help text'),
      questionSettings: i18n.t('Question settings'),
      allowCreationOfNewAnswers: i18n.t('Allow creation of new answers'),
      allowAnswerOptionsToBeIncludedInDataFlow: i18n.t('Allow answer options to be included in Data Flow'),
      select: i18n.t('Select'),
      allowAnswerOptionsToBeIncludedInOutsourcing: i18n.t('Allow answer options to be included in Outsourcing'),
      allowAnswerOptionsToBeIncludedInDataProces: i18n.t('Allow answer options to be included in data processor management'),
      cancel: i18n.t('Cancel'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      questionAnswersCanNotBeBlank: i18n.t('Question answers, can not be blank'),
      questionMustHaveAHeadline: i18n.t('Question must have a headline'),
      questionMustHaveAQuestionNumber: i18n.t('Question must have a question number'),
      invalidQuestionNumberOnlyDigitsDotsAndLet: i18n.t('Invalid question number. Only digits, dots and letters are allowed. Max. 15 characters'),
      questionMustHaveAText: i18n.t('Question must have a text'),
      youHaveToAddAtLeastTwoAnswerOptions: i18n.t('You have to add at least two answer options'),
      questionNumberAlreadyExists: i18n.t('Question number already exists'),
      areYouSureYouWantToCancelTheEnteredInfor: i18n.t('Are you sure you want to cancel? The entered information will not be saved'),
      theQuestionIsInDependencyLoopWhichCanCauseErrors: i18n.t('The question is in dependency loop which can cause errors'),
      dataFlowSettings: i18n.t('Data flow settings'),
      linkDataTypeToLegalBasisQuestion: i18n.t('Link data type to legal basis question'),
      linkedQuestion: i18n.t('Linked question'),
      linkDataTypeToRetentionQuestion: i18n.t('Link data types to questions about storage period'),
      theAnswersProvidedFromTheCurrentQuestionStorage: i18n.t('The answers provided from the current question can be linked to the answers from storage periode question (Art. 5 GDPR)'),
      theAnswersProvidedFromTheCurrentQuestionLegalBasis: i18n.t('The answers provided from the current question can be linked to the answers from legal basis question (Art. 6 GDPR)')
    };
  },
  ComplianceMiniTree: function ComplianceMiniTree() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      anErrorOccurred: i18n.t('An error occurred'),
      linkedActions: i18n.t('Linked actions'),
      linkedControls: i18n.t('Linked controls'),
      copyOf: i18n.t('Copy of'),
      deleteNode: i18n.t('Delete node'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      andUnderlyingProcesses: i18n.t('and underlying processes'),
      couldNotDeleteNode: i18n.t('Could not delete node'),
      addSubNode: i18n.t('Add sub node'),
      createArticle30Report: i18n.t('Create Article 30 report'),
      questionnaireReport: i18n.t('Questionnaire report'),
      duplicateNode: i18n.t('Duplicate node'),
      duplicateNodeWithSubNodes: i18n.t('Duplicate node with sub nodes'),
      duplicateNodeWithSubNodesAndData: i18n.t('Duplicate node with sub nodes and data')
    };
  },
  DataFlowRow: function DataFlowRow() {
    return {
      addNew: i18n.t('Add new'),
      storagePeriod: i18n.t('Storage period'),
      editLegalBasisAndStoragePeriod: i18n.t('Edit legal basis and storage period'),
      legalBasis: i18n.t('Legal basis'),
      confirmationNeeded: i18n.t('Confirmation needed'),
      confirm: i18n.t('Confirm'),
      delete: i18n.t('Delete'),
      deleteDataFlow: i18n.t('Delete data flow'),
      areYouSureYouWantToDeleteThisDataFlow: i18n.t('Are you sure you want to delete this data flow?'),
      areYouSureYouWantToDeleteThisDataFlowWit: i18n.t('Are you sure you want to delete this data flow with its connected data types and'),
      dataTypes: i18n.t('Data types'),
      dataType: i18n.t('Data type'),
      comments: i18n.t('Comments'),
      changeDirectionsOfDataflow: i18n.t('Change directions of dataflow'),
      createANewDataFlowFromThisStakeholder: i18n.t('Create a new data flow from this stakeholder'),
      deleteTheEntireDataFlow: i18n.t('Delete the entire data flow'),
      updateTitle: i18n.t('Update title'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      warning: i18n.t('Warning'),
      ok: i18n.t('Ok'),
      enterNewHeadlineForSystems: i18n.t('Enter new headline for systems'),
      changingTheDirectionOfTheDataflowOrAdding: i18n.t('Changing the direction of the dataflow or adding/removing datatypes or stakeholders could mean that the dataflow will be merged with other dataflows. Do you wish to continue?'),
      enterTitle: getTitlePlaceholder()
    };
  },
  GapSummaryTable: function GapSummaryTable() {
    return {
      gapIndicator: i18n.t('Gap indicator'),
      businessArea: i18n.t('Node'),
      questionnaire: i18n.t('Questionnaire(s)'),
      totalNumberOfAssessments: i18n.t('Total number of assessments'),
      notSet: i18n.t('Not set'),
      noGap: i18n.t('No Gap'),
      gapLow: i18n.t('Gap Low'),
      gapMedium: i18n.t('Gap Medium'),
      gapHigh: i18n.t('Gap High'),
      na: i18n.t('N/A'),
      nodes: i18n.t('Nodes'),
      totals: i18n.t('Totals')
    };
  },
  AssessmentScoring: function AssessmentScoring() {
    return {
      latestAudit: i18n.t('Latest audit'),
      score: i18n.t('Score'),
      assessmentText: i18n.t('Assessment text'),
      questionnaire: i18n.t('Questionnaire'),
      auditSentTo: i18n.t('Audit sent to')
    };
  }
};