export var hexToHsl = function hexToHsl(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);
  r /= 255, g /= 255, b /= 255;
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  s = Math.round(s * 100);
  l = Math.round(l * 100);
  h = Math.round(360 * h);
  return {
    h: h,
    s: s,
    l: l
  };
};
export var convertHexToRgba = function convertHexToRgba(hexCode) {
  var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var hex = hexCode.replace('#', '');
  if (hex.length === 3) {
    hex = "".concat(hex[0]).concat(hex[0]).concat(hex[1]).concat(hex[1]).concat(hex[2]).concat(hex[2]);
  }
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }
  return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(opacity, ")");
};
var hexToRGB = function hexToRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};
export var getCustomMultiShadow = function getCustomMultiShadow(color, coef1, coef2) {
  var offsetX = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var offsetY = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var _hexToRGB = hexToRGB(color),
    r = _hexToRGB.r,
    g = _hexToRGB.g,
    b = _hexToRGB.b;
  var coef3 = 0.005;
  var result = 'box-shadow: ';
  for (var i = 1; i < 33; i++) {
    result += "".concat(offsetX * i, "px ").concat(offsetY, "px 0 0 rgba(").concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(coef1, ")").concat(i === 32 ? ';' : ', ');
    coef2 += coef3;
    coef1 *= coef2;
  }
  return result;
};