import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, function (item, index) {
    return _openBlock(), _createBlock(_component_risma_button, {
      key: "risma-button_".concat(index),
      text: item.text,
      title: item.title,
      func: item.func,
      type: item.type,
      "is-custom-button-size": $props.isCustomButtonSize,
      class: _normalizeClass([item.buttonClasses || '', {
        'mr-2': index !== $props.data.length - 1
      }]),
      disabled: item.disabled,
      onClick: function onClick($event) {
        return item.clickEvent($event);
      }
    }, {
      default: _withCtx(function () {
        return [_renderSlot(_ctx.$slots, "button-".concat(item.slotName ? item.slotName : index))];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text", "title", "func", "type", "is-custom-button-size", "class", "disabled", "onClick"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}