// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-select.high[data-v-187e45de]  .select-selected {
  background-color: #f25050;
  color: #FFFFFF;
}
.result-select.medium[data-v-187e45de]  .select-selected {
  background-color: #fad039;
}
.result-select.low[data-v-187e45de]  .select-selected {
  background-color: #4385e0;
  color: #FFFFFF;
}
.result-select.no-gap[data-v-187e45de]  .select-selected {
  background-color: #4bbf5e;
}
.result-select.na[data-v-187e45de]  .select-selected {
  background-color: #dee2e6;
}
.result-select[data-v-187e45de]  .option[value*=HIGH] {
  background-color: #f25050 !important;
}
.result-select[data-v-187e45de]  .option[value*=MEDIUM] {
  background-color: #fad039 !important;
}
.result-select[data-v-187e45de]  .option[value*=LOW] {
  background-color: #4385e0 !important;
}
.result-select[data-v-187e45de]  .option[value*=NOGAP] {
  background-color: #4bbf5e !important;
}
.result-select[data-v-187e45de]  .option[value*=NA] {
  background-color: #dee2e6 !important;
}
.fade-enter-active[data-v-187e45de],
.fade-leave-active[data-v-187e45de] {
  transition: opacity 0.3s;
}
.fade-enter-from[data-v-187e45de],
.fade-leave-to[data-v-187e45de] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
