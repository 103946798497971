import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "w-80"
};
var _hoisted_2 = {
  class: "ml-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_searchable_selector = _resolveComponent("searchable-selector");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_searchable_selector, {
    title: 'Add new users',
    "search-placeholder": 'Search users',
    api: $options.apiMock,
    "selected-items-ids": $options.selectedItemsIds,
    "items-number-per-load": 10,
    "selected-filter-fields": ['label'],
    total: $data.items.length,
    class: "mb-6",
    onSelected: $options.selected
  }, null, 8 /* PROPS */, ["api", "selected-items-ids", "total", "onSelected"]), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createTextVNode(" Selected items: ")), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedItems, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.id
    }, _toDisplayString(item.label) + ",", 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])]);
}