// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-e3feb70c]  .risma-toggle {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
}
[data-v-e3feb70c]  div.column-switcher-popup {
  width: auto;
  right: 7px;
  top: 6px;
  left: auto;
  padding: 0;
  border: 2px solid #BEBEBE;
  background: #FFFFFF;
  border-radius: 0;
  z-index: 20;
  white-space: nowrap;
  min-width: 100%;
}
[data-v-e3feb70c]  .column-switcher-list li.removed,[data-v-e3feb70c]  .column-switcher-list li {
  margin-bottom: 0;
  background: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
  padding: 0.5em 7px;
  color: #0e2954;
  font-weight: 600;
}
div.column-switcher[data-v-e3feb70c] {
  align-self: flex-end;
  padding: 0;
  border-radius: 4px;
  margin: 0;
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
