import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "activity-type-selector"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_single_select, {
    title: $props.label,
    options: $data.options,
    "model-value": $props.modelValue,
    placeholder: $options.activityTypePlaceholder,
    "un-sorted": $data.unSorted,
    disabled: $props.disabled,
    "with-reset": !$props.disabled && $props.canBeReset && $data.selectedValue !== null && $data.selectedValue !== -1,
    "onUpdate:modelValue": $options.onChangeType
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "un-sorted", "disabled", "with-reset", "onUpdate:modelValue"])]);
}