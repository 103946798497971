function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 0,
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_mini_tree_branch = _resolveComponent("mini-tree-branch", true);
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["my-2 relative mini-tree-branch vertical-arrow-last ml-5", [{
      'vertical-arrow-last-selected': $props.parentId === $options.lastIdInSelectedChain || $options.beforeSelected && !$options.isSelected || $props.searchEnabled
    }, {
      'vertical-arrow-last-headless': $options.level > 0 && $options.index === 0 && $options.hasChildren
    }]])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center cursor-pointer relative", [{
      'hori-arrow': $options.level > 0 && $options.index === 0
    }, {
      'hori-arrow hori-arrow-on-hidden-children': !$options.hasChildren && !($options.level > 0 && $options.index === 0)
    }, {
      'hori-arrow-selected': $options.isElementHighlighted || $options.beforeSelected && $options.index === 0
    }]]),
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.handleClick && $options.handleClick.apply($options, arguments);
    })
  }, [$options.hasChildren ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['transform duration-500 stroke-2 mr-2 icon-position', $options.isElementHighlighted || $options.beforeSelected ? 'icon-blue' : 'icon-gray', {
      'rotate-90': $options.isOpened
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return _ctx.$emit('update:opened', $props.item);
    }, ["stop"]))
  }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([[$options.isElementHighlighted ? 'text-blue-750 font-semibold' : 'text-neutral-500', {
      'bg-blue-60 rounded-md': $options.lastIdInSelectedChain === $props.item.id
    }, {
      'vertical-arrow-first-selected': $options.beforeSelected || $props.parentId === $options.lastIdInSelectedChain || $props.searchEnabled
    }, {
      'first-child-vertical-arrow': $options.index === 0 && $options.level > 0
    }, {
      'vertical-arrow-first': $options.index > 0 || $options.level > 0
    }, {
      'vertical-arrow-first-hidden': $options.index === 0
    }, {
      'line-through': $props.item.enabled === false
    }], "w-fit ml-1 flex items-center p-1"]),
    title: $props.item.title
  }, [_createElementVNode("span", null, _toDisplayString($props.item.title), 1 /* TEXT */), $options.hasToggleMenu ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    class: "ml-4 font-normal",
    position: "fixed",
    "menu-style": "minimal",
    options: $options.nodeActions
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_1)], 2 /* CLASS */), $options.hasChildren && $options.isOpened ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.children, function (data, ind) {
    return _openBlock(), _createBlock(_component_mini_tree_branch, _mergeProps({
      key: "mini-tree-branch-".concat(data.id, "-").concat(ind),
      path: [].concat(_toConsumableArray($props.path), [ind]),
      "selected-path": $props.selectedPath,
      "opened-array": $props.openedArray,
      item: data,
      "parent-id": $props.item.id,
      "parent-enabled": $props.item.enabled,
      "node-emits": $props.nodeEmits,
      "search-enabled": $props.searchEnabled,
      ref_for: true
    }, _ctx.$attrs, {
      "onUpdate:selected": _cache[2] || (_cache[2] = function ($event) {
        return _ctx.$emit('update:selected', [{
          id: $props.item.id,
          index: $options.index
        }].concat(_toConsumableArray($event)));
      }),
      "onEdit:title": _cache[3] || (_cache[3] = function ($event) {
        return _ctx.$emit('edit:title', $event);
      }),
      "onEdit:enabled": _cache[4] || (_cache[4] = function ($event) {
        return _ctx.$emit('edit:enabled', $event);
      }),
      "onUpdate:opened": _cache[5] || (_cache[5] = function ($event) {
        return _ctx.$emit('update:opened', $event);
      }),
      onAddSubNode: _cache[6] || (_cache[6] = function ($event) {
        return _ctx.$emit('addSubNode', $event);
      })
    }), null, 16 /* FULL_PROPS */, ["path", "selected-path", "opened-array", "item", "parent-id", "parent-enabled", "node-emits", "search-enabled"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}