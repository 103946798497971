import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "modal-header flex mb-5 items-start"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ai_assistant_icon = _resolveComponent("ai-assistant-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_markdown_renderer = _resolveComponent("markdown-renderer");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.title || $data.translate.citation,
    "show-buttons": false,
    "body-scroll-y": true,
    "modal-fixed-width": "688px",
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ai_assistant_icon, {
        width: "28",
        height: "24",
        "fill-color": "rgb(0, 172, 239)",
        class: "mr-4 mt-1"
      }), _createVNode(_component_risma_title, {
        title: $props.title || $data.translate.citation,
        type: "big",
        truncate: false,
        class: "w-4/5 flex-1 break-words pr-4"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("button", {
        class: "focus:outline-none",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('dismiss');
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "x",
        width: "25",
        height: "25",
        class: "stroke-3 text-gray-750 hover:text-gray-550 mt-1"
      })])]), _cache[2] || (_cache[2] = _createElementVNode("hr", {
        class: "border-solid border-b border-rm-grey-light"
      }, null, -1 /* HOISTED */))];
    }),
    body: _withCtx(function () {
      return [_createVNode(_component_markdown_renderer, {
        class: "mt-5 glob-l1 break-words mx-auto",
        markdown: $props.citation
      }, null, 8 /* PROPS */, ["markdown"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}