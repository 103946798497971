function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useUserStore } from '@/Store/userStore';
import * as ProcessTreeApi from '@/api/compliance/processtree';
import { getControls as _getControls, getInitiatives as _getInitiatives, getNodes, getRisks as _getRisks, getInformationAssetsSystems, getInformationAssetsProcessors as _getInformationAssetsProcessors, getInformationAssetsControllers as _getInformationAssetsControllers, getIncidents as _getIncidents, getContracts as _getContracts, getCustomInformationAssets } from '@/api/risma/data';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { mapToCommaListWithLinks } from '@/utils/format/mapTo';
import { prepareDateTimezone } from '@/utils/date';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { formatNodes } from '@/utils/format/Compliance';
import * as Utils from '@/utils/Utils';
import { SortDirection } from '@/constants/SortDirection';
import { ReportTypes } from '@/constants/reports';
import { DateFormats } from '@/constants/DateFormats';
export default {
  name: 'ProcessTreeReport',
  introduction: 'Process Tree Reports',
  description: 'This is Page component for /compliance/processtree/report/',
  token: '<process-tree-report></process-tree-report>',
  components: {
    RismaDatatable: RismaDatatable,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    HierarchicalList: HierarchicalList,
    TrafficLight: TrafficLight,
    SaveReport: SaveReport,
    PreviewModalLink: PreviewModalLink
  },
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, SaveReportMixin],
  props: {
    processTreeId: {
      type: [String, Number],
      required: true
    }
  },
  data: function data() {
    return {
      currentUser: {},
      isLoaded: false,
      isIE: false,
      pageLength: 50,
      order: [{
        index: 'title',
        dir: SortDirection.DESC
      }],
      dataset: [],
      dataTableContainerSelector: '.processtree-datatable',
      dataTableScrollBodySelector: '.table-wrapper-body',
      decoupleFilterFields: [{
        key: 'userIds.responsible'
      }, {
        key: 'userIds.accountable'
      }, {
        key: 'userIds.consulted'
      }, {
        key: 'userIds.informed'
      }, {
        key: 'organisations'
      }, {
        key: 'systems'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }, {
        key: 'risks'
      }, {
        key: 'nodes'
      }, {
        key: 'companies'
      }, {
        key: 'tags'
      }, {
        key: 'processLibraryNodes'
      }, {
        key: 'attachedFiles'
      }, {
        key: 'contracts'
      }, {
        key: 'processors'
      }, {
        key: 'controllers'
      }, {
        key: 'customIA'
      }, {
        key: 'incidents'
      }, {
        key: 'frameworks'
      }],
      disabledFilterByColumns: ['trafficLight', 'description'],
      initiatives: [],
      controls: [],
      systems: [],
      processLibraryNodes: [],
      nodes: [],
      risks: [],
      companies: [],
      contracts: [],
      customIA: [],
      processors: [],
      controllers: [],
      incidents: [],
      isLinkUpdated: false,
      columnsMeta: [{
        targets: ['title'],
        width: '400px'
      }, {
        targets: ['description'],
        width: '600px'
      }, {
        targets: ['systems', 'initiatives', 'controls', 'nodes', 'risks', 'companies', 'contracts', 'customIA', 'processors', 'controllers', 'incidents', 'processLibraryNodes'],
        visible: false
      }],
      reportNodes: [],
      slotLinkFields: ['systems', 'initiatives', 'controls', 'processLibraryNodes', 'nodes', 'risks', 'companies', 'contracts', 'customIA', 'processors', 'controllers', 'incidents'],
      cfColumns: {},
      customFieldsById: {},
      updatedItem: null,
      updatedGovernanceReview: null,
      reportType: ReportTypes.PROCESS_LIBRARY,
      datatableUploadState: false,
      stateLocal: 'process_library_report_table_state',
      translate: getTranslate['ProcessTreeReport']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    processNodeActivityTypes: 'processNodeActivityTypes'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useUserStore, ['userHasCompaniesAccess'])), {}, {
    columns: function columns() {
      var result = {
        id: this.translate.id,
        trafficLight: this.translate.trafficLight,
        title: this.translate.title,
        description: this.translate.description,
        activityType: this.translate.activityType,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisation,
        tags: this.translate.tags,
        governanceReview: this.translate.governanceReview,
        completionDate: this.translate.governanceReviewLastCompleted,
        nextReviewDate: this.translate.nextGovernanceReviewDate,
        attachedFiles: this.translate.attachedFiles,
        controllers: this.translate.linkedDataControllers,
        processors: this.translate.linkedProcessors,
        systems: this.translate.linkedSystems,
        customIA: this.translate.linkedInformationAssets,
        initiatives: this.translate.linkedInitiatives,
        controls: this.translate.linkedControls,
        risks: this.translate.linkedRisks,
        nodes: this.translate.linkedComplianceNodes,
        contracts: this.translate.linkedContracts,
        companies: this.translate.linkedCompanies,
        incidents: this.translate.linkedIncidents,
        frameworks: this.translate.frameworks
      };
      if (!this.userHasCompaniesAccess) {
        delete result.companies;
      }
      if (this.allowProcessLibraryLinks) {
        result.processLibraryNodes = "".concat(this.translate.linked, " ").concat(this.processLibraryTitle);
      }
      return _objectSpread(_objectSpread({}, result), this.cfColumns);
    },
    allowProcessLibraryLinks: function allowProcessLibraryLinks() {
      return this.getSettingValue('feature.allow_links_between_process_library_nodes') === 1;
    },
    headline: function headline() {
      return this.translate.report;
    }
  }),
  created: function created() {
    var _this = this;
    this.getData().then(function () {
      return _this.loadSavedReport();
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightByObjectTypeAndNumber'])), {}, {
    getInitiatives: function getInitiatives() {
      var _this2 = this;
      return _getInitiatives().then(function (_ref) {
        var list = _ref.list;
        return _this2.initiatives = list;
      });
    },
    getControls: function getControls() {
      var _this3 = this;
      return _getControls().then(function (_ref2) {
        var list = _ref2.list;
        return _this3.controls = list;
      });
    },
    getSystems: function getSystems() {
      var _this4 = this;
      return getInformationAssetsSystems().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.systems = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this5 = this;
      return _getInformationAssetsProcessors().then(function (_ref4) {
        var list = _ref4.list;
        return _this5.processors = list;
      });
    },
    getInformationAssetsControllers: function getInformationAssetsControllers() {
      var _this6 = this;
      return _getInformationAssetsControllers().then(function (_ref5) {
        var list = _ref5.list;
        return _this6.controllers = list;
      });
    },
    getRisks: function getRisks() {
      var _this7 = this;
      return _getRisks().then(function (_ref6) {
        var list = _ref6.list;
        return _this7.risks = list;
      });
    },
    getCustomIA: function getCustomIA() {
      var _this8 = this;
      return getCustomInformationAssets().then(function (_ref7) {
        var list = _ref7.list;
        return _this8.customIA = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this9 = this;
      return _getCompanies().then(function (_ref8) {
        var list = _ref8.list;
        return _this9.companies = list;
      });
    },
    getContracts: function getContracts() {
      var _this10 = this;
      return _getContracts().then(function (_ref9) {
        var list = _ref9.list;
        return _this10.contracts = list;
      });
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this11 = this;
      return getNodes([], false).then(function (_ref10) {
        var list = _ref10.list;
        var nodesFlat = [];
        list.forEach(function (compliance) {
          var nodesWithFormattedTitle = formatNodes(compliance.children);
          Utils.pushChildrenToParent({
            children: nodesWithFormattedTitle
          }, nodesFlat);
        });
        _this11.nodes = nodesFlat;
      });
    },
    getIncidents: function getIncidents() {
      var _this12 = this;
      return _getIncidents().then(function (_ref11) {
        var list = _ref11.list;
        return _this12.incidents = list;
      });
    },
    getTreeData: function getTreeData() {
      var _this13 = this;
      this.isLoaded = false;
      this.dataset = [];
      return ProcessTreeApi.getReportById(this.processTreeId).then(function (_ref12) {
        var list = _ref12.list,
          allProcesses = _ref12.allProcesses;
        _this13.processLibraryNodes = allProcesses;
        _this13.reportNodes = list;
      }).catch(function (error) {
        _this13.isLoaded = true;
        throw error;
      });
    },
    updateLinkedFromPreview: function updateLinkedFromPreview(data, field) {
      var index = this[field].findIndex(function (item) {
        return item.id === data.id;
      });
      if (index === -1) return;
      this[field].splice(index, 1, data);
      this.isLinkUpdated = true;
    },
    onLinkModalDismiss: function onLinkModalDismiss() {
      if (!this.isLinkUpdated) return;
      this.updateDatasetAndEnhance();
      this.isLinkUpdated = false;
    },
    getData: function getData() {
      var _this14 = this;
      var data = this.getLocalStorageList();
      var promises = [];
      if (data !== null && data !== void 0 && data.columns) {
        data.columns.forEach(function (column) {
          if (column.visible) {
            var api = _this14.getApiByKey(column.key);
            if (api) promises.push(api);
          }
        });
      }
      return Promise.all([this.getTreeData()].concat(promises)).then(function () {
        _this14.prepareCustomFieldOptions(_this14.reportNodes);
        _this14.dataset = _this14.prepareDataset(_this14.reportNodes, _this14.processLibraryNodes);
        _this14.enhanceDataset(_this14.dataset, _this14.columns);
        _this14.isLoaded = true;
      });
    },
    getLocalStorageList: function getLocalStorageList() {
      return JSON.parse(localStorage.getItem(this.stateLocal));
    },
    onVisibilityChange: function onVisibilityChange(_ref13) {
      var _this$key,
        _this15 = this;
      var key = _ref13.key,
        visible = _ref13.visible;
      if (!visible) return;

      //processlibrary has different key, check if not empty - not needed api call
      if ((_this$key = this[key]) !== null && _this$key !== void 0 && _this$key.length) return;
      var api = this.getApiByKey(key);
      // if its not a field for the api call
      if (!api) return;
      this.datatableUploadState = true;
      return api.then(function () {
        _this15.dataset = _this15.prepareDataset(_this15.reportNodes, _this15.processLibraryNodes);
        _this15.enhanceDataset(_this15.dataset, _this15.columns);
        _this15.datatableUploadState = false;
      });
    },
    getApiByKey: function getApiByKey(key) {
      switch (key) {
        case 'controllers':
          return this.getInformationAssetsControllers();
        case 'processors':
          return this.getInformationAssetsProcessors();
        case 'customIA':
          return this.getCustomIA();
        case 'risks':
          return this.getRisks();
        case 'nodes':
          return this.getComplianceNodes();
        case 'companies':
          return this.getCompanies();
        case 'incidents':
          return this.getIncidents();
        case 'contracts':
          return this.getContracts();
        case 'systems':
          return this.getSystems();
        case 'controls':
        case 'governanceReview':
        case 'nextReviewDate':
        case 'completionDate':
          return this.getControls();
        case 'initiatives':
          return this.getInitiatives();
        default:
          return '';
      }
    },
    prepareDataset: function prepareDataset(list, allProcesses) {
      var _this16 = this;
      return list.map(function (element) {
        var _element$links, _element$links2, _element$links3, _element$links4, _element$links5, _element$links6, _element$links7, _element$links8, _element$links9, _element$links10, _element$links11, _element$links12;
        element.organisationIds = element.organisationIds.map(function (id) {
          return parseInt(id, 10);
        });
        var trafficLight = DatatableRenderer.prepareTrafficLightField(element.trafficLight);
        var governanceReview = (_element$links = element.links) !== null && _element$links !== void 0 && _element$links.review ? _this16.controls.find(function (control) {
          return +control.id === +element.links.review[0];
        }) : null;
        var fields = {
          id: {
            field: element.uniqueId || ''
          },
          trafficLight: {
            field: trafficLight,
            fieldText: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.label) || '',
            color: (trafficLight === null || trafficLight === void 0 ? void 0 : trafficLight.color) || ''
          },
          title: {
            field: {
              node: _this16.buildHierarchicalStructure(element, allProcesses)
            },
            fieldText: element.title
          },
          description: {
            field: element.description
          },
          activityType: {
            field: _this16.getActivityTypeName(element.activityTypeId)
          },
          responsible: {
            field: _this16.mapToCommaList(_this16.filterUsers(element.responsibleUserIds), 'display_name')
          },
          accountable: {
            field: _this16.mapToCommaList(_this16.filterUsers(element.accountableUserIds), 'display_name')
          },
          consulted: {
            field: _this16.mapToCommaList(_this16.filterUsers(element.consultedUserIds), 'display_name')
          },
          informed: {
            field: _this16.mapToCommaList(_this16.filterUsers(element.informedUserIds), 'display_name')
          },
          organisations: {
            field: _this16.mapToCommaList(_this16.organisations.filter(function (o) {
              return element.organisationIds.indexOf(o.id) !== -1;
            }), 'visible_name')
          },
          tags: {
            field: _this16.mapToCommaList(_this16.tags.filter(function (t) {
              return element.tagIds.includes(t.id);
            }), 'tag')
          },
          governanceReview: {
            activity: governanceReview,
            item: {
              id: element.id,
              links: element.links
            },
            fieldText: governanceReview ? governanceReview === null || governanceReview === void 0 ? void 0 : governanceReview.title : ''
          },
          completionDate: {
            field: _this16.formatDate(governanceReview, 'latestCompletion')
          },
          nextReviewDate: {
            field: _this16.formatDate(governanceReview, 'deadline')
          },
          attachedFiles: {
            field: mapToCommaListWithLinks(element.attachments, 'url', 'filename', 'id', false),
            fieldText: _this16.mapToCommaList(element.attachments, 'filename')
          },
          controllers: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links2 = element.links) === null || _element$links2 === void 0 ? void 0 : _element$links2.compliance_ia_controller, _this16.controllers)),
          processors: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links3 = element.links) === null || _element$links3 === void 0 ? void 0 : _element$links3.compliance_ia_processor, _this16.processors)),
          systems: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links4 = element.links) === null || _element$links4 === void 0 ? void 0 : _element$links4.compliance_ia_system, _this16.systems)),
          customIA: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links5 = element.links) === null || _element$links5 === void 0 ? void 0 : _element$links5.information_assets, _this16.customIA)),
          initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links6 = element.links) === null || _element$links6 === void 0 ? void 0 : _element$links6.execution, _this16.initiatives, 'path')),
          controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links7 = element.links) === null || _element$links7 === void 0 ? void 0 : _element$links7.control, _this16.controls, 'path')),
          risks: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links8 = element.links) === null || _element$links8 === void 0 ? void 0 : _element$links8.risk, _this16.risks)),
          nodes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links9 = element.links) === null || _element$links9 === void 0 ? void 0 : _element$links9.compliance, _this16.nodes)),
          contracts: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links10 = element.links) === null || _element$links10 === void 0 ? void 0 : _element$links10.contracts, _this16.contracts)),
          companies: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links11 = element.links) === null || _element$links11 === void 0 ? void 0 : _element$links11.company, _this16.companies, '', null, 'name', function (company) {
            return '/company/' + company.id;
          })),
          incidents: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links12 = element.links) === null || _element$links12 === void 0 ? void 0 : _element$links12.incidents, _this16.incidents)),
          frameworks: {
            field: Utils.findPropsByIds(element.solutionIds, _this16.frameworks, 'displayName')
          }
        };
        if (!_this16.userHasCompaniesAccess) {
          delete fields.companies;
        }
        if (_this16.allowProcessLibraryLinks) {
          var _element$links13;
          fields.processLibraryNodes = _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink((_element$links13 = element.links) === null || _element$links13 === void 0 ? void 0 : _element$links13.compliance_process_tree_node, Object.values(_this16.processLibraryNodes)));
        }
        return _objectSpread(_objectSpread({}, fields), _this16.customFieldsById[element.id]);
      });
    },
    onDismissModal: function onDismissModal() {
      if (!this.updatedItem) return;
      var id = this.updatedItem.id;
      var idx = this.reportNodes.findIndex(function (node) {
        return node.id === id;
      });
      if (idx === -1) return;
      var limitedNode = this.getLimitedObject(this.updatedItem);
      this.reportNodes[idx] = Object.assign(this.reportNodes[idx], this.updatedItem);
      this.prepareCustomFieldOptions(this.reportNodes);
      this.processLibraryNodes[id] = Object.assign(this.processLibraryNodes[id], limitedNode);
      this.dataset = this.prepareDataset(this.reportNodes, this.processLibraryNodes);
      this.enhanceDataset(this.dataset, this.columns);
      this.updatedItem = null;
    },
    buildHierarchicalStructure: function buildHierarchicalStructure(node, allProcesses) {
      var structure = {
        title: node.title,
        url: node.url,
        objType: node.objType,
        id: node.id,
        processTreeId: node.processTreeId
      };
      node.ancestorIds && node.ancestorIds.reverse().forEach(function (id) {
        var parent = allProcesses[parseInt(id, 10)];
        var parentStructure = {
          title: parent.title,
          url: parent.url,
          objType: parent.objType,
          id: parent.id,
          processTreeId: parent.processTreeId
        };
        parentStructure.children = [_objectSpread({}, structure)];
        structure = JSON.parse(JSON.stringify(parentStructure));
      });
      return structure;
    },
    mapToCommaList: function mapToCommaList(arr) {
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
      return arr && arr.length > 0 ? arr.map(function (obj) {
        return obj[field];
      }).join(', ') : '';
    },
    filterUsers: function filterUsers(userIds) {
      return this.users.filter(function (user) {
        return userIds.indexOf(user.id) !== -1;
      });
    },
    formatDate: function formatDate(review, field) {
      if (!(review !== null && review !== void 0 && review[field])) return '';
      return prepareDateTimezone(review[field], review.deadlineTimezone, DateFormats.DATE_TIME_FORMAT);
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions(list) {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(list, this.processNodeActivityTypes),
        cfByEntities = _this$getPropertiesWi.cfByEntities,
        cfColumns = _this$getPropertiesWi.cfColumns,
        cfDisabledColumns = _this$getPropertiesWi.cfDisabledColumns;
      this.cfColumns = cfColumns;
      this.customFieldsById = cfByEntities;
      this.disabledFilterByColumns = this.disabledFilterByColumns.concat(cfDisabledColumns);
    },
    getActivityTypeName: function getActivityTypeName(id) {
      if (!id) return '';
      var activityType = this.processNodeActivityTypes.find(function (at) {
        return +at.id === +id;
      });
      if (!activityType) return '';
      return activityType.label;
    },
    getLimitedObject: function getLimitedObject(node) {
      return {
        id: node.id,
        processTreeId: node.processTreeId,
        title: node.title,
        trafficLight: node.trafficLight,
        links: node.links
      };
    },
    updateGovernanceReview: function updateGovernanceReview(govReview, updatedItem) {
      this.updatedGovernanceReview = govReview;
      this.updatedItem = updatedItem;
      this.updateControlInControlsList(govReview);
      this.updateItemLinksReview(govReview.id);
    },
    updateGovReviewFromItemPreview: function updateGovReviewFromItemPreview(govReview) {
      this.updateControlInControlsList(govReview);
      this.updateItemLinksReview(govReview.id);
    },
    updateItemLinksReview: function updateItemLinksReview(id) {
      var _this$updatedItem;
      if ((_this$updatedItem = this.updatedItem) !== null && _this$updatedItem !== void 0 && _this$updatedItem.links && id) {
        this.updatedItem.links.review = [+id];
      }
    },
    onDismissGovReviewModal: function onDismissGovReviewModal(updatedItem) {
      if (!this.updatedGovernanceReview) return;
      var idx = this.reportNodes.findIndex(function (node) {
        return node.id === updatedItem.id;
      });
      this.reportNodes[idx] = Object.assign(this.reportNodes[idx], {
        links: updatedItem.links
      });
      this.updateControlInControlsList(this.updatedGovernanceReview);
      this.updateDatasetAndEnhance();
      this.clearUpdatedItems();
    },
    updateDatasetAndEnhance: function updateDatasetAndEnhance() {
      this.dataset = this.prepareDataset(this.reportNodes, this.processLibraryNodes);
      this.enhanceDataset(this.dataset, this.columns);
    },
    clearUpdatedItems: function clearUpdatedItems() {
      this.updatedGovernanceReview = null;
      this.updatedItem = null;
    },
    updateControlInControlsList: function updateControlInControlsList(newControl) {
      var controlToUpdate = this.controls.find(function (control) {
        return +control.id === +newControl.id;
      });
      if (controlToUpdate) {
        controlToUpdate.title = newControl.title;
        controlToUpdate.latestCompletion = newControl.latestCompletion;
        controlToUpdate.deadline = newControl.deadline;
      } else {
        this.controls.push(newControl);
      }
    }
  })
};