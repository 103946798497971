import { Http } from '../index';
import * as HttpUtils from '../httpUtils';

/**
 * @typedef {(
 *  'compliance_ia_controller' |
 *  'compliance_ia_processor' |
 *  'compliance_ia_system' |
 *  'information_assets' |
 *  'contracts'
 * )} AuditableModuleName
 */

/**
 * Create new audit
 *
 * @param {AuditableModuleName} module
 * @param activityId
 * @param data
 * @returns {Promise<AxiosResponse | string>}
 */
export function createNewAudit(module, activityId, data) {
  return Http.post("compliance/audit/".concat(module, "/").concat(activityId, "/request"), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Get all audits for a given activity
 *
 * @param {AuditableModuleName} module
 * @param activityId
 * @returns {Promise<AxiosResponse<T> | string>}
 */
export function getAllAudits(module, activityId) {
  return HttpUtils.handleRequestResponse(Http.get("compliance/audit/".concat(module, "/").concat(activityId, "/request")));
}

/**
 * Get a single audit
 * @param {AuditableModuleName} module
 * @param activityId
 * @param auditId
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getAudit(module, activityId, auditId) {
  return HttpUtils.handleRequestResponse(Http.get("compliance/audit/".concat(module, "/").concat(activityId, "/request/").concat(auditId)));
}

/**
 * Update deadline and message for specific audit
 * @param {AuditableModuleName} module
 * @param activityId
 * @param requestId
 * @param deadline
 * @param message
 * @returns {Promise<T | string>}
 */
export function updateAuditDeadline(module, activityId, requestId, deadline, message) {
  var data = {
    deadline: deadline,
    message: message
  };
  return Http.patch("compliance/audit/".concat(module, "/").concat(activityId, "/request/").concat(requestId), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}
export function deleteAudit(module, activityId, requestId) {
  return HttpUtils.handleRequestResponse(Http.delete("compliance/audit/".concat(module, "/").concat(activityId, "/request/").concat(requestId)));
}