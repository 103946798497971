// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node[data-v-49bb040a] {
  min-height: 40px;
  display: block;
  padding: 8px;
  margin-bottom: 3px;
  font-size: 15px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #BEBEBE;
  background: #FFFFFF;
  text-align: left;
  color: #4D4D4D;
  font-family: inherit;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
