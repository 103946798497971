// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-3.use[1]!../../../../../node_modules/vue-datepicker-next/index.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mx-datepicker-main {
  font-family: Inter, sans-serif;
}
.mx-datepicker-sidebar {
  width: auto;
  display: flex;
  flex-direction: column;
}
.mx-datepicker-sidebar button {
  font-size: 12px;
}
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 140px;
}
.mx-input {
  height: auto;
  line-height: 28px;
}
.mx-datepicker-range,
.mx-input-wrapper {
  width: 100%;
  min-width: 230px;
}
.mx-icon-calendar svg {
  fill: none;
}
.mx-calendar-content .cell.active {
  color: #fff !important;
  background-color: #1284e7 !important;
}
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  color: #73879c !important;
  background-color: #dbedfb !important;
}
.mx-calendar-content .cell:hover {
  color: #73879c !important;
  background-color: #f3f9fe !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
