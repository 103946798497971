// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-wrapper[data-v-0b365e86] {
  display: flex;
  margin-bottom: 1rem;
}
.select-wrapper .choose-result[data-v-0b365e86] {
  width: 200px;
}
.select-wrapper .select-item[data-v-0b365e86] {
  width: 287px;
  margin: 1rem;
}
.select-wrapper .select-mode[data-v-0b365e86] {
  margin: 1rem 60px 1rem auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
