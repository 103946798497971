// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table[data-v-35b4d476] {
  table-layout: auto;
  width: 100%;
}
.hidden[data-v-35b4d476] {
  display: none;
}
.static-table-switcher[data-v-35b4d476] {
  position: relative;
}
.static-table-switcher-label[data-v-35b4d476] {
  cursor: pointer;
  color: #347480;
  font-size: 16px;
  transition: color 0.2s;
  padding-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.zebra tbody[data-v-35b4d476]:nth-child(odd) {
  background-color: #ebebeb;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
