import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col"
};
var _hoisted_2 = {
  class: "grid grid-cols-2 gap-y-2 my-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createNewRiskMatrixAxis,
    "show-buttons": false,
    onDismiss: $options.dismissModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        type: "small",
        title: $data.translate.pleaseCompleteThisFormToCreateANewTableba
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        type: "text",
        class: "leading-6"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_risma_label, {
        title: $data.translate.numberOfScores
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
        "model-value": $data.scoresNumber,
        options: $options.scoresNumberOptions,
        "un-sorted": false,
        placeholder: $data.translate.select,
        class: "p-0"
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder"]), _createVNode(_component_risma_label, {
        title: $data.translate.parametersInTable
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
        "model-value": $data.paramInTable,
        options: $options.paramInTableOptions,
        "un-sorted": false,
        placeholder: $data.translate.select,
        class: "p-0"
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder"])]), _createVNode(_component_risma_button, {
        disabled: !$options.isValid,
        text: $data.translate.create,
        type: "save",
        class: "ml-auto",
        onClick: $options.createMatrix
      }, null, 8 /* PROPS */, ["disabled", "text", "onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"]);
}