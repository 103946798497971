function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import * as DashboardAPI from '@/api/contracts/dashboard';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import InfoBox from '@/components/Atoms/Containers/InfoBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import YearWheelSelector from '@/components/Molecules/YearWheelSelector';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ContractGenericTable from '@/components/Pages/Contracts/ContractGenericTable';
import { ContractGenericTableMixins } from '@/components/Pages/Contracts/ContractGenericTableMixins';
import { compareDates } from '@/utils/compare';
import { CONTRACT_STATUSES, COMMITMENT_STATUS } from '@/constants/contracts/contract';
import { SUMMERIES_PROPS } from '@/constants/contracts/dashboard';
import { MODULES } from '@/constants/modules';
import { capitalize } from '@/utils/capitalize';
import { getMonthsNames, getCurrentYear } from '@/utils/date';
import { Colors } from '@/Colors';
export default {
  name: 'ContractsDashboard',
  introduction: 'Contracts Dashboard page',
  description: 'This page shows the dashboards for contracts',
  components: {
    ContractGenericTable: ContractGenericTable,
    FeatherIcon: FeatherIcon,
    InfoBox: InfoBox,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    RismaTitle: RismaTitle,
    LightTabItem: LightTabItem,
    RismaDoughnutChart: RismaDoughnutChart,
    YearWheelSelector: YearWheelSelector
  },
  mixins: [ContractGenericTableMixins],
  data: function data() {
    return {
      topPanels: {},
      monthIndex: null,
      statuses: CONTRACT_STATUSES,
      pieColors: [Colors.system.blueMain, Colors.greenTea, Colors.red, Colors.dullGreen, Colors.lightRed, Colors.yellow, Colors.lightYellow, Colors.system.lightGray, Colors.system.lightGray2, Colors.system.blueGray, Colors.system.blueGrayActive, Colors.system.primaryLight, Colors.system.primaryDark],
      chartDatasets: [],
      chartLabels: [],
      monthlyDataset: _defineProperty(_defineProperty(_defineProperty({}, CONTRACT_STATUSES.renewal, {}), CONTRACT_STATUSES.expiring, {}), COMMITMENT_STATUS, {}),
      isLoaded: false,
      renewal: [],
      expiring: [],
      commitment: [],
      contracts: [],
      workflowStatuses: [],
      companies: [],
      translate: getTranslate['ContractsDashboard'](),
      COMMITMENT_STATUS: COMMITMENT_STATUS,
      currentYear: getCurrentYear(),
      isYearWheelShown: false,
      isNewYearDataLoaded: true,
      status: null
    };
  },
  computed: {
    months: function months() {
      return getMonthsNames();
    },
    tableTitle: function tableTitle() {
      return this.months[this.monthIndex] ? "".concat(this.$trans("".concat(capitalize(this.status), " in")), " ").concat(this.months[this.monthIndex]) : '';
    },
    sortedContracts: function sortedContracts() {
      return _toConsumableArray(this.contracts).sort(function (a, b) {
        return compareDates(a.endDate, b.endDate);
      });
    }
  },
  watch: {
    $route: function $route(to) {
      this.setYearWheelShown(to);
    }
  },
  beforeMount: function beforeMount() {
    this.initTopPanels();
  },
  mounted: function mounted() {
    var _this = this;
    this.setYearWheelShown(this.$route);
    Promise.all([this.getExpiringContracts(), this.getRenewalContracts(), this.getCommitmentContracts(), this.getWorkflowStatuses(), this.getStatusContracts(), this.getSummaryContracts(), this.getCompanies()]).finally(function () {
      _this.isLoaded = true;
    });
  },
  methods: {
    setYearWheelShown: function setYearWheelShown(route) {
      this.isYearWheelShown = !route.path.includes('payment');
    },
    handleUpdateDataByYear: function handleUpdateDataByYear() {
      var _this2 = this;
      this.isNewYearDataLoaded = false;
      Promise.all([this.getExpiringContracts(), this.getRenewalContracts(), this.getCommitmentContracts()]).finally(function () {
        return _this2.isNewYearDataLoaded = true;
      });
    },
    initTopPanels: function initTopPanels() {
      this.topPanels = _defineProperty(_defineProperty(_defineProperty({}, SUMMERIES_PROPS.contracts, {
        title: this.translate.contracts,
        value: 0,
        icon: 'file-text',
        iconStyle: 'text-blue-750'
      }), SUMMERIES_PROPS.annual_cost, {
        title: this.translate.annualCost,
        value: 0,
        icon: 'dollar-sign',
        iconStyle: 'text-green-410 '
      }), SUMMERIES_PROPS.expiring, {
        title: this.translate.expirationOfContractsWithin30Days,
        value: 0,
        icon: 'clock',
        iconStyle: 'text-red-50'
      });
    },
    getCompanies: function getCompanies() {
      var _this3 = this;
      return _getCompanies().then(function (_ref) {
        var list = _ref.list;
        return _this3.companies = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this4 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref2) {
        var list = _ref2.list;
        _this4.workflowStatuses = list;
      });
    },
    setStatusAndMonth: function setStatusAndMonth(status, month, year) {
      this.status = status;
      this.monthIndex = month;
      this.contracts = this[status]["".concat(year, "-").concat(month + 1)] || [];
    },
    getSummaryContracts: function getSummaryContracts() {
      var _this5 = this;
      return DashboardAPI.getSummaryContracts().then(function (response) {
        var summaries = response.summaries;
        Object.keys(_this5.topPanels).forEach(function (item) {
          _this5.topPanels[item].value = summaries[item];
        });
      });
    },
    getStatusContracts: function getStatusContracts() {
      var _this6 = this;
      return DashboardAPI.getStatusContracts().then(function (response) {
        var status = response.status;
        var statuses = Object.keys(status);
        var data = statuses.map(function (item) {
          return status[item].length;
        });
        var backgroundColor = _this6.pieColors;
        _this6.chartLabels = statuses;
        _this6.chartDatasets = [{
          data: data,
          backgroundColor: backgroundColor
        }];
      });
    },
    getRenewalContracts: function getRenewalContracts() {
      var _this7 = this;
      return DashboardAPI.getRenewalContracts("when=".concat(this.currentYear, "-01-01")).then(function (response) {
        var renewing = response.renewing;
        _this7.renewal = renewing;
        _this7.prepareMonthlyDatasetByStatus(renewing, CONTRACT_STATUSES.renewal);
      });
    },
    getExpiringContracts: function getExpiringContracts() {
      var _this8 = this;
      return DashboardAPI.getExpiringContracts("when=".concat(this.currentYear, "-01-01")).then(function (response) {
        var expiring = response.expiring;
        _this8.expiring = expiring;
        _this8.prepareMonthlyDatasetByStatus(expiring, CONTRACT_STATUSES.expiring);
      });
    },
    getCommitmentContracts: function getCommitmentContracts() {
      var _this9 = this;
      return DashboardAPI.getCommitmentContracts("when=".concat(this.currentYear, "-01-01")).then(function (_ref3) {
        var commitment = _ref3.commitment;
        _this9.commitment = commitment;
        _this9.prepareMonthlyDatasetByStatus(commitment, COMMITMENT_STATUS);
      });
    },
    prepareMonthlyDatasetByStatus: function prepareMonthlyDatasetByStatus(data, status) {
      for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        var _key$split = key.split('-'),
          _key$split2 = _slicedToArray(_key$split, 2),
          year = _key$split2[0],
          month = _key$split2[1];
        if (!this.monthlyDataset[status][year]) {
          this.monthlyDataset[status][year] = Array(12).fill(0);
        }
        this.monthlyDataset[status][year][month - 1] = value.length;
      }
    },
    changeTab: function changeTab() {
      this.contracts = [];
      this.monthIndex = null;
    },
    setContracts: function setContracts(contracts, monthIndex) {
      this.contracts = contracts;
      this.status = 'Payment';
      this.monthIndex = monthIndex;
    }
  }
};