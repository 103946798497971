var _excluded = ["tags"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import GovernanceReviewModal from '@/components/Organisms/Compliance/GovernanceReview/GovernanceReviewModal';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { Timezones } from '@/constants/Timezones';
import { DateFormats } from '@/constants/DateFormats';
import { getTZGuess, getCurrentDateWithMove, formatDateWithTimezone } from '@/utils/date';
export default {
  name: 'GovernanceReviewSection',
  components: {
    TrafficLight: TrafficLight,
    GovernanceReviewModal: GovernanceReviewModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink
  },
  introduction: '',
  description: '',
  token: '<governance-review-section />',
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    review: {
      required: true,
      type: Object,
      note: 'The review object'
    },
    projectName: {
      type: String,
      required: true,
      note: 'Name of current project. Needed to make locked tag'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is the functionality disabled'
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      showModal: false,
      reviewClone: {},
      translate: getTranslate['GovernanceReviewSection']()
    };
  },
  computed: {
    latestCompletionUser: function latestCompletionUser() {
      var _this = this;
      var user = '';
      if (this.reviewClone.latestCompletionUserId !== undefined) {
        user = this.users.find(function (user) {
          return user.id === _this.reviewClone.latestCompletionUserId;
        });
      }
      return user ? user.display_name : '';
    },
    showReviewEditButton: function showReviewEditButton() {
      return !Object.keys(this.reviewClone).length || this.reviewClone.canEditReview;
    }
  },
  watch: {
    review: function review() {
      this.reviewClone = Object.assign({}, this.review);
      this.prepareReview();
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    getData: function getData() {
      this.reviewClone = Object.assign({}, this.review);
      this.prepareReview();
    },
    emitReview: function emitReview(event) {
      this.showModal = false;
      this.$emit('update', this.packData(event));
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    formatDate: function formatDate(date, tz) {
      return formatDateWithTimezone(date.split('+')[0], tz, DateFormats.DATE_FORMAT);
    },
    getTimezone: function getTimezone() {
      var tzGuess = getTZGuess();
      var timezone = Timezones.find(function (timezone) {
        return timezone.value === tzGuess;
      });
      return timezone ? tzGuess : 'UTC';
    },
    packData: function packData(_ref) {
      var _ref$tags = _ref.tags,
        tags = _ref$tags === void 0 ? [] : _ref$tags,
        review = _objectWithoutProperties(_ref, _excluded);
      var packedReview = _objectSpread(_objectSpread({}, review), {}, {
        deadlineTimezone: review.deadlineTimezone || this.getTimezone(),
        deadlineTZ: review.deadlineTimezone || this.getTimezone(),
        deadlineBeforeAfter: 'after',
        isMotherControl: 0,
        tagIds: tags.map(function (tag) {
          return tag.id;
        })
      });
      if (typeof packedReview.frequency === 'string') {
        delete packedReview.frequency;
      }
      return packedReview;
    },
    prepareReview: function prepareReview() {
      if (Object.keys(this.review).length > 0) {
        this.reviewClone.userIds.responsible = this.reviewClone.userIds.responsible.map(function (u) {
          return parseInt(u);
        });
        this.reviewClone.userIds.escalation = this.reviewClone.userIds.escalation.map(function (u) {
          return parseInt(u);
        });
        this.reviewClone.userIds.review = this.reviewClone.userIds.review.map(function (u) {
          return parseInt(u);
        });
        this.reviewClone.deadline = formatDateWithTimezone(this.reviewClone.deadline, this.reviewClone.deadlineTimezone, DateFormats.DATE_TIME_FORMAT_FULL);
        this.reviewClone.reviewDeadline = this.reviewClone.userIds.review.length > 0 ? formatDateWithTimezone(this.reviewClone.reviewDeadline, this.reviewClone.deadlineTimezone, DateFormats.DATE_TIME_FORMAT_FULL) : getCurrentDateWithMove(7, 'day');
      }
    },
    showPreview: function showPreview() {
      var _this2 = this;
      if (!this.reviewClone.canEditReview) return;
      if (this.isPreview) {
        this.$router.push(this.reviewClone.url);
      } else {
        this.$nextTick(function () {
          _this2.$refs.preview.showModal(_this2.reviewClone);
        });
      }
    },
    updateFromPreview: function updateFromPreview(activity) {
      this.reviewClone = _objectSpread(_objectSpread({}, this.reviewClone), activity);
    }
  })
};