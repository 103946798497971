var _settingNamesByModule;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { MODULES } from '@/constants/modules';
export var settingNamesByModule = (_settingNamesByModule = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_settingNamesByModule, MODULES.EXECUTION, ['app.module_execution_enabled']), MODULES.INCIDENTS, ['app.module_incidents_enabled']), MODULES.COMPLIANCE, ['app.module_compliance_enabled']), MODULES.CONTROLS, ['app.module_controls_enabled']), MODULES.RISK, ['app.module_risk_enabled', 'app.module_risks2_enabled']), MODULES.COMPLIANCE_INFORMATION_ASSETS, ['app.module_compliance_enabled', 'feature.enable_ia_custom']), MODULES.COMPLIANCE_IA_CONTROLLER, ['app.module_compliance_enabled', 'feature.enable_data_controllers']), MODULES.COMPLIANCE_IA_PROCESSOR, ['app.module_compliance_enabled', 'feature.enable_data_processors']), MODULES.COMPLIANCE_IA_SYSTEM, ['feature.enable_systems', 'app.module_compliance_enabled']), MODULES.CONTRACTS, ['app.module_contracts_enabled']), _defineProperty(_settingNamesByModule, MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
  // sometimes its as policies and its not related to compliance, sometimes its compliance
  main: ['feature.process_tree'],
  secondary: ['app.module_compliance_enabled']
}));