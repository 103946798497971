import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'AppInvitationsEntry',
  components: {
    LightTabItem: LightTabItem
  },
  data: function data() {
    return {
      translate: {
        incidentApp: this.$trans('Incident app'),
        controlsApp: this.$trans('Controls app')
      }
    };
  },
  computed: {
    tabs: function tabs() {
      return [{
        label: this.translate.incidentApp,
        url: '/settings/appinvitations/incident'
      }, {
        label: this.translate.controlsApp,
        url: '/settings/appinvitations/controls'
      }];
    }
  }
};