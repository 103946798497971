import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  ProcessNodeAwareness: function ProcessNodeAwareness() {
    return {
      requestsList: i18n.t('Requests list'),
      requestDocumentSignoff: i18n.t('Request document sign-off'),
      sendReminder: i18n.t('Send reminder'),
      thisRequestHasNotBeenSentClickTheButtonT: i18n.t('This request has not been sent! Click the button to edit or send request to the user list.'),
      requestSignoff: i18n.t('Request sign-off'),
      anErrorOccurred: i18n.t('An error occurred'),
      sent: i18n.t('Sent'),
      draft: i18n.t('Draft'),
      status: i18n.t('Status'),
      goToAwarenessAndMonitorWhenTheUsersHave: i18n.t('Go to "Awareness" and monitor when the users have confirmed your request.'),
      duplicateEmailAddress: i18n.t('Duplicate email address'),
      pleaseStateOneOrMoreEmailsInThisField: i18n.t('Please state one or more e-mails in this field'),
      pleaseCheckEmailAddresses: i18n.t('Please, check email addresses'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      atLeastUserIsRequired: i18n.t('At least 1 user is required'),
      theReminderHasBeenSuccessfullySent: i18n.t('The reminder has been successfully sent'),
      theRequestHasBeenSuccessfullySent: i18n.t('The request has been successfully sent'),
      nothingToShow: i18n.t('Nothing to show'),
      newAwarenessCampaign: i18n.t('New awareness campaign')
    };
  },
  ProcessTreePage: function ProcessTreePage() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      noProcessesFound: i18n.t('No processes found'),
      errorDuringCreation: i18n.t('Error during creation'),
      couldNotDeleteNode: i18n.t('Could not delete node')
    };
  },
  ProcessTreeReport: function ProcessTreeReport() {
    return {
      id: i18n.t('ID'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      activityType: i18n.t('Activity type'),
      organisation: i18n.t('Organisation'),
      linkedSystems: i18n.t('Linked systems'),
      linkedInitiatives: i18n.t('Linked initiatives'),
      linkedControls: i18n.t('Linked controls'),
      linkedRisks: i18n.t('Linked risks'),
      linkedComplianceNodes: i18n.t('Linked compliance nodes'),
      linkedCompanies: i18n.t('Linked companies'),
      linkedContracts: i18n.t('Linked contracts'),
      linkedInformationAssets: i18n.t('Linked information assets'),
      linkedIncidents: i18n.t('Linked incidents'),
      linkedDataControllers: i18n.t('Linked data controllers'),
      linkedProcessors: i18n.t('Linked processors'),
      tags: i18n.t('Tags'),
      governanceReview: i18n.t('Governance review'),
      governanceReviewLastCompleted: i18n.t('Governance review last completed'),
      nextGovernanceReviewDate: i18n.t('Next governance review date'),
      attachedFiles: i18n.t('Attached files'),
      linked: i18n.t('Linked'),
      report: i18n.t('Report'),
      noProcessesFound: i18n.t('No processes found'),
      trafficLight: i18n.t('Traffic light'),
      reportSaved: i18n.t('Report saved'),
      frameworks: i18n.t('Frameworks')
    };
  },
  ProcessMiniTreePage: function ProcessMiniTreePage() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      enterTitle: getTitlePlaceholder(),
      couldNotCreateNode: i18n.t('Could not create node'),
      anErrorOccurred: i18n.t('An error occurred'),
      linkedActions: i18n.t('Linked actions'),
      linkedControls: i18n.t('Linked controls'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      copyOf: i18n.t('Copy of'),
      deleteNode: i18n.t('Delete node'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      andUnderlyingProcesses: i18n.t('and underlying processes'),
      couldNotDeleteNode: i18n.t('Could not delete node'),
      addSubNode: i18n.t('Add sub node'),
      createArticle30Report: i18n.t('Create Article 30 report'),
      questionnaireReport: i18n.t('Questionnaire report'),
      duplicateNode: i18n.t('Duplicate node'),
      duplicateNodeWithSubNodes: i18n.t('Duplicate node with sub nodes'),
      duplicateNodeWithSubNodesAndData: i18n.t('Duplicate node with sub nodes and data')
    };
  }
};