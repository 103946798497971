import { useSettingsStore } from '@/Store/settingsStore';
import i18n from '@/i18n';
export function getTitleFromSetting(setting) {
  return useSettingsStore().getValue(setting) || '';
}
export function getProcessLibraryTitle() {
  return getTranslatedTitleFromSetting('feature.process_library_name', 'Process Library');
}
export function getDataflowThirdPartiesTitle() {
  return getTranslatedTitleFromSetting('feature.data_flow_systems_name', 'Third parties');
}
export function getDataflowStakeholdersTitle() {
  return getTranslatedTitleFromSetting('feature.data_flow_stakeholders_name', 'Data subjects');
}
function getTranslatedTitleFromSetting(setting, defaultTitle) {
  var title = '';
  try {
    title = useSettingsStore().getValue(setting) || defaultTitle;
  } catch (_unused) {
    return i18n.t(defaultTitle);
  }
  return title === defaultTitle ? i18n.t(title) : title;
}