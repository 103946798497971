function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { getTranslate } from '@/components/Organisms/Contracts/translate';
import { getContractsPayments as _getContractsPayments } from '@/api/contracts/dashboard';
import { getContracts as _getContracts } from '@/api/contracts/contracts';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import { Colors } from '@/Colors';
import currency from '@/constants/contracts/currency';
import cloneDeep from 'lodash/cloneDeep';
export default {
  name: 'ContractsPayment',
  components: {
    SingleSelect: SingleSelect,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaBarChart: RismaBarChart
  },
  emits: ['setContracts'],
  data: function data() {
    return {
      isLoading: false,
      contracts: [],
      labels: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
      currency: currency[0].value,
      year: null,
      currencyOptions: currency,
      summary: {},
      indexSelected: null,
      currentYear: new Date().getFullYear(),
      translate: getTranslate['ContractsPayment']()
    };
  },
  computed: {
    labelsTranslated: function labelsTranslated() {
      var _this = this;
      return this.labels.map(function (label) {
        return _this.translate[label];
      });
    },
    shortLabels: function shortLabels() {
      return this.labelsTranslated.map(function (label) {
        return label.slice(0, 3);
      });
    },
    yearOptions: function yearOptions() {
      var _this2 = this;
      return new Array(6).fill().map(function (_, index) {
        var value = _this2.currentYear + index;
        return {
          label: "".concat(value),
          value: value
        };
      });
    },
    selectedMonths: function selectedMonths() {
      var _this$summary$this$ye;
      return (_this$summary$this$ye = this.summary[this.year]) === null || _this$summary$this$ye === void 0 ? void 0 : _this$summary$this$ye[this.currency];
    },
    datasets: function datasets() {
      var _this$selectedMonths,
        _this3 = this;
      if (!((_this$selectedMonths = this.selectedMonths) !== null && _this$selectedMonths !== void 0 && _this$selectedMonths.length)) return [];
      return this.summary[this.year][this.currency].map(function (item, index) {
        var data = new Array(12).fill(null);
        data[index] = item.total;
        var borderWidth = _this3.indexSelected === index ? 2 : 0;
        return {
          data: data,
          borderWidth: borderWidth,
          borderColor: Colors.system.black,
          backgroundColor: Colors.dullGreen2
        };
      });
    }
  },
  mounted: function mounted() {
    this.setInitialValues();
    this.getData();
  },
  methods: {
    setInitialValues: function setInitialValues() {
      var _this4 = this;
      this.year = this.currentYear;
      var emptyCurrency = Object.fromEntries(currency.map(function (_ref) {
        var value = _ref.value;
        return [value, []];
      }));
      this.yearOptions.forEach(function (_ref2) {
        var label = _ref2.label;
        _this4.summary[label] = cloneDeep(emptyCurrency);
      });
    },
    getData: function getData() {
      var _this5 = this;
      this.isLoading = true;
      Promise.all([this.getContracts(), this.getContractsPayments(this.year, this.currency)]).then(function () {
        return _this5.isLoading = false;
      });
    },
    getContracts: function getContracts() {
      var _this6 = this;
      return _getContracts().then(function (_ref3) {
        var list = _ref3.list;
        return _this6.contracts = list;
      });
    },
    getContractsPayments: function getContractsPayments(year, currency) {
      var _this7 = this;
      return _getContractsPayments(year, currency).then(function (_ref4) {
        var months = _ref4.months;
        _this7.summary[year][currency] = Object.values(months);
      });
    },
    setContracts: function setContracts(_ref5) {
      var _this$selectedMonths$;
      var _ref6 = _slicedToArray(_ref5, 1),
        e = _ref6[0];
      if (e === undefined) return;
      this.indexSelected = e.index;
      var contractIds = ((_this$selectedMonths$ = this.selectedMonths[this.indexSelected]) === null || _this$selectedMonths$ === void 0 ? void 0 : _this$selectedMonths$.contractIds) || [];
      var contracts = this.contracts.filter(function (_ref7) {
        var id = _ref7.id;
        return contractIds.includes(id);
      });
      this.$emit('setContracts', contracts, this.indexSelected);
    },
    refreshChart: function refreshChart() {
      var _this8 = this;
      if (this.selectedMonths.length) {
        this.refreshDatatable();
        return;
      }
      this.isLoading = true;
      this.getContractsPayments(this.year, this.currency).then(function () {
        _this8.refreshDatatable();
        _this8.isLoading = false;
      });
    },
    refreshDatatable: function refreshDatatable() {
      this.setContracts([{
        index: this.indexSelected
      }]);
    }
  }
};