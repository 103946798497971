import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.frameworkReport,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "state-key": $data.stateKey,
    "show-search-field": false,
    api: $options.getControlFrameworksBE,
    onDismissModal: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, null, 8 /* PROPS */, ["state-key", "api"]))]);
}