import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mb-4"
};
var _hoisted_2 = {
  class: "flex flex-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_selector = _resolveComponent("selector");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tag_item = _resolveComponent("tag-item");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_selector, {
    title: "",
    items: $props.data[$data.DataFlowFields.DATA_TYPE_OPTIONS],
    "search-placeholder": $data.translate.chooseDataTypes,
    "search-fields": ['text'],
    class: "mb-6",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:selected', {
        value: $event,
        field: $data.DataFlowFields.CURRENT_TYPES_SELECTED
      });
    })
  }, {
    displayedName: _withCtx(function (slotProps) {
      return [_createTextVNode(_toDisplayString(slotProps.item.text), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["items", "search-placeholder"]), $props.selectedOptions[$data.DataFlowFields.CURRENT_TYPES_SELECTED].length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.addedDataTypes,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selectedOptions[$data.DataFlowFields.CURRENT_TYPES_SELECTED], function (item, index) {
    return _openBlock(), _createBlock(_component_tag_item, {
      key: "selected-data-type-".concat(index),
      class: "flex items-center !w-fit !mr-4",
      title: item.text,
      onClose: function onClose($event) {
        return _ctx.$emit('update:selected', {
          value: item,
          field: $data.DataFlowFields.CURRENT_TYPES_SELECTED
        });
      }
    }, null, 8 /* PROPS */, ["title", "onClose"]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]);
}