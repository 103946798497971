function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { patchUtilSettings } from '@/api/risma/settings';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import { cacheItem } from '@/routes/storeCache';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'ControlPanelSettings',
  description: 'Universal section for control panel settings',
  token: '<control-panel-settings>',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    InputField: InputField,
    RismaButton: RismaButton,
    Notification: Notification
  },
  props: {
    settingsGroups: {
      type: Object,
      required: true,
      note: 'Object with all the settings'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    responsibleUsers: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['updateHeader'],
  data: function data() {
    return {
      showSaveSuccess: false,
      showSaveError: false,
      tips: [],
      errors: [],
      settings: {},
      translate: getTranslate['ControlPanelSettings']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['isAdmin'])), {}, {
    isReadonly: function isReadonly() {
      return !this.isAdmin;
    },
    responsibleOptions: function responsibleOptions() {
      return this.responsibleUsers.map(function (user) {
        return {
          label: user.display_name,
          value: user.id
        };
      });
    },
    filteredActivityTypes: function filteredActivityTypes() {
      return this.activityTypes.map(function (item) {
        return {
          label: item.label,
          value: item.id
        };
      });
    },
    translatedTips: function translatedTips() {
      var _this = this;
      return this.tips.map(function (tip) {
        var _tip$minValue;
        var name = _this.$trans(tip.name);
        var message = _this.translate.mustBeThan(tip.maxValue ? _this.translate.lower : _this.translate.higher).toLowerCase();
        return "".concat(name, " ").concat(message, " ").concat((_tip$minValue = tip.minValue) !== null && _tip$minValue !== void 0 ? _tip$minValue : tip.maxValue);
      });
    },
    translatedErrors: function translatedErrors() {
      var _this2 = this;
      return this.errors.map(function (error) {
        return _this2.$trans(error);
      });
    }
  }),
  mounted: function mounted() {
    this.prepareSettings();
    var _this$getPreparedData = this.getPreparedData(this.settings),
      data = _this$getPreparedData.data;
    this.validateData(data);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    prepareSettings: function prepareSettings() {
      this.settings = cloneDeep(this.settingsGroups);
      for (var group in this.settings) {
        var settingsGroup = this.settings[group];
        for (var settingName in settingsGroup) {
          if (this.getSettingValue(settingName) !== undefined) {
            if (settingsGroup[settingName].type === 'intvalue' && settingsGroup[settingName].dropdown) {
              var _this$getSettingValue;
              this.settings[group][settingName].selectedValue = (_this$getSettingValue = this.getSettingValue(settingName)) === null || _this$getSettingValue === void 0 ? void 0 : _this$getSettingValue.toString();
            } else {
              this.settings[group][settingName].selectedValue = this.getSettingValue(settingName);
            }
          } else {
            this.settings[group][settingName].selectedValue = this.settings[group][settingName].default;
          }
        }
      }
    },
    getSingleSelectArray: function getSingleSelectArray(elements) {
      var translatable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var hasResetElement = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var allSet = [];
      if (hasResetElement) {
        allSet.push({
          value: '',
          label: this.translate.notSet
        });
      }
      for (var index in elements) {
        allSet.push({
          value: index,
          label: translatable ? elements[index] : this.$trans(elements[index])
        });
      }
      return allSet;
    },
    saveSettings: function saveSettings() {
      var _this3 = this;
      var _this$getPreparedData2 = this.getPreparedData(this.settings),
        data = _this$getPreparedData2.data,
        updateHeader = _this$getPreparedData2.updateHeader;
      this.showSaveError = false;
      this.showSaveSuccess = false;
      if (!this.validateData(data)) return;
      var dataToSaveInSettings = {};
      Object.keys(data).forEach(function (key) {
        dataToSaveInSettings[key] = data[key].value;
      });
      var promises = [patchUtilSettings(dataToSaveInSettings)];
      Promise.all(promises).then(function () {
        _this3.showSaveSuccess = true;
        _this3.$scrollTo(document.body, 100);
        if (updateHeader) {
          _this3.$emit('updateHeader');
        }
      }).then(function () {
        cacheItem('user', function () {
          return _this3.loadCurrentUser();
        }, 180);
        cacheItem('settings', function () {
          return _this3.loadSettings();
        }, 180);
      }).catch(function () {
        return _this3.showSaveError = true;
      });
    },
    getPreparedData: function getPreparedData(settings) {
      var _this4 = this;
      var data = {},
        updateHeader = false;
      this.tips = [];
      Object.entries(settings).forEach(function (groupEntry) {
        var _groupEntry = _slicedToArray(groupEntry, 2),
          groupKey = _groupEntry[0],
          settingsGroup = _groupEntry[1];
        Object.entries(settingsGroup).forEach(function (setting) {
          var _setting = _slicedToArray(setting, 2),
            key = _setting[0],
            value = _setting[1];
          var type = value.type,
            selectedValue = value.selectedValue,
            defaultValue = value.default,
            notEmpty = value.notEmpty,
            name = value.name;
          if (type == 'boolvalue') {
            selectedValue = selectedValue ? 1 : 0;
          } else {
            if (notEmpty && !selectedValue) {
              selectedValue = _this4.settingsGroups[groupKey][key].default;
            }
            if (type === 'intvalue' && value.minValue !== undefined && +selectedValue < +value.minValue) {
              selectedValue = value.minValue;
              _this4.tips.push({
                name: name,
                minValue: value.minValue - 1
              });
            }
            if (type === 'intvalue' && value.maxValue !== undefined && +selectedValue > +value.maxValue) {
              selectedValue = value.maxValue;
              _this4.tips.push({
                name: name,
                maxValue: value.maxValue + 1
              });
            }
          }
          if (value.updateHeader) {
            updateHeader = true;
          }
          settingsGroup[key].selectedValue = selectedValue;
          data[key] = {
            default: defaultValue,
            value: selectedValue,
            settingField: value.settingField || ''
          };
          if (value.rules) {
            data[key].rules = value.rules;
          }
        });
      });
      return {
        data: data,
        updateHeader: updateHeader
      };
    },
    validateData: function validateData(data) {
      var _Object$values,
        _this5 = this;
      this.errors = [];
      (_Object$values = Object.values(data)) === null || _Object$values === void 0 || _Object$values.forEach(function (setting) {
        if (!setting.rules || setting.value === setting.default) return;
        setting.rules.forEach(function (rule) {
          var _data$rule$setting;
          var ruleValue = (_data$rule$setting = data[rule.setting]) === null || _data$rule$setting === void 0 ? void 0 : _data$rule$setting.value;
          if (!rule.rule(ruleValue)) {
            _this5.errors.push(rule.warningMessage);
          }
        });
      });
      return !this.errors.length;
    }
  })
};