import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.archivedModalHeader,
    "button-ok-text": $data.translate.confirm,
    "button-dismiss-text": $data.translate.cancel,
    "ok-button-disabled": !$options.isValidNote,
    onAccept: $options.handleAccept,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_single_select, {
        modelValue: $data.statusId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.statusId = $event;
        }),
        title: $options.selectStatusTitle,
        options: $options.statusOptions,
        "un-sorted": true,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_tiny_mce_box, {
        type: "mini",
        headline: $data.translate.stateTheReasonForStatusChange,
        "editor-wrapper-class": "mt-1",
        "initial-content": $data.workflowStatusNote,
        "always-open": true,
        class: "mb-3",
        onChanged: _cache[1] || (_cache[1] = function ($event) {
          return $data.workflowStatusNote = $event;
        })
      }, null, 8 /* PROPS */, ["headline", "initial-content"]), _createElementVNode("div", null, [_createVNode(_component_date_time_picker, {
        modelValue: $data.expirationDate,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.expirationDate = $event;
        }),
        title: $data.translate.expirationDate,
        "plugin-options": $options.datePickerOptions,
        "use-locale-format": true,
        "delete-enabled": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled", "onAccept"]);
}