import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "ml-auto cursor-pointer h-10"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "ml-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_download_link = _resolveComponent("download-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_list = _resolveComponent("preview-list");
  var _component_management_report_edit_modal = _resolveComponent("management-report-edit-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_preview_list, {
    hideable: false,
    title: $data.translate.managementReporting
  }, {
    "nav-buttons": _withCtx(function () {
      return [!$options.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_feather_icon, {
        class: "text-blue-750 stroke-3 h-full w-10",
        icon: "plus",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $data.editModalShown = true;
        })
      })])) : _createCommentVNode("v-if", true)];
    }),
    default: _withCtx(function () {
      return [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_static_table, {
        "dataset-table": $options.dataset,
        columns: $options.columns,
        "filter-by-column": true
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("div", null, _toDisplayString(slotData), 1 /* TEXT */)];
        }),
        download: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_download_link, {
            "file-src": slotData,
            "file-name": $data.fileName
          }, null, 8 /* PROPS */, ["file-src", "file-name"])])];
        }),
        edit: _withCtx(function (_ref3) {
          var slotData = _ref3.slotData;
          return [_createVNode(_component_feather_icon, {
            class: "ml-2 cursor-pointer text-blue-750 inline-block",
            icon: "edit",
            onClick: function onClick($event) {
              return $options.editReport(slotData.report);
            }
          }, null, 8 /* PROPS */, ["onClick"])];
        }),
        delete: _withCtx(function (_ref4) {
          var slotData = _ref4.slotData;
          return [_createVNode(_component_feather_icon, {
            class: "ml-2 cursor-pointer text-red-50 inline-block",
            icon: "trash-2",
            onClick: function onClick($event) {
              return $options.handleDeleteConfirmation(slotData);
            }
          }, null, 8 /* PROPS */, ["onClick"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns"]), !$options.dataset.length ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 0,
        title: $data.translate.nothingToShow,
        class: "flex justify-center mt-5",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]), $data.editModalShown ? (_openBlock(), _createBlock(_component_management_report_edit_modal, {
    key: 0,
    "risk-project": $options.currentRiskProject,
    "report-data": $data.reportToEdit,
    onCreateReport: $options.createManagementReport,
    onEditReport: $options.editManagementReport,
    onDismiss: $options.closeEditModal
  }, null, 8 /* PROPS */, ["risk-project", "report-data", "onCreateReport", "onEditReport", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}