// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk[data-v-3d95d5f6] {
  display: flex;
  position: relative;
}
.risk-notification[data-v-3d95d5f6]  ul {
  padding: 0 0 0 17px;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
