function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions, mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getNotifications as _getNotifications, updateNotificationMessage as _updateNotificationMessage, getNotificationPreview as _getNotificationPreview } from '@/api/admin/notifications';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import Modal from '@/components/Molecules/Modal/Modal';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { UserNotificationSettings } from '@/constants/UserNotificationSettings';
export default {
  name: 'EmailsAdmin',
  components: {
    InputField: InputField,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    Modal: Modal,
    TextBox: TextBox,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  description: 'Page for customizing email messages',
  token: '<emails-admin />',
  data: function data() {
    return {
      notificationPreview: '',
      logoReplacement: '',
      replaceFeatureName: 'feature.email_top_name_header',
      notifications: [],
      showModal: false,
      showPreview: false,
      isPreviewLoaded: false,
      editedNotification: null,
      timer: 0,
      translate: getTranslate['EmailsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    notificationSettings: function notificationSettings() {
      return UserNotificationSettings(this.getSettingValue);
    },
    editableNotifications: function editableNotifications() {
      return this.enhanceNotifications(this.notificationSettings, this.notifications);
    },
    columns: function columns() {
      return [this.translate.rismaModule, this.translate.emailTrigger, this.translate.emailTemplateText];
    },
    dataset: function dataset() {
      var _this = this;
      return this.editableNotifications.map(function (item) {
        return {
          rismaModule: item.module,
          emailTrigger: _this.$trans(item.notification),
          emailTemplateText: item
        };
      });
    }
  }),
  mounted: function mounted() {
    this.getNotifications();
    this.getLogoReplacement();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, ['updateSettings'])), {}, {
    getNotifications: function getNotifications() {
      var _this2 = this;
      return _getNotifications().then(function (_ref) {
        var list = _ref.list;
        return _this2.notifications = list;
      });
    },
    getLogoReplacement: function getLogoReplacement() {
      var _this$getSettingValue;
      this.logoReplacement = (_this$getSettingValue = this.getSettingValue(this.replaceFeatureName)) !== null && _this$getSettingValue !== void 0 ? _this$getSettingValue : '';
    },
    enhanceNotifications: function enhanceNotifications(notifications, editableNotifications) {
      return editableNotifications.map(function (notification) {
        var _notifications$find;
        var setting = (_notifications$find = notifications.find(function (_ref2) {
          var id = _ref2.id;
          return id === notification.notificationId;
        })) !== null && _notifications$find !== void 0 ? _notifications$find : {};
        return _objectSpread(_objectSpread({}, notification), setting);
      });
    },
    replaceLogo: function replaceLogo(text) {
      return this.updateSettings(_defineProperty({}, this.replaceFeatureName, text));
    },
    openTemplate: function openTemplate(notification) {
      var _this3 = this;
      this.editedNotification = _objectSpread({}, notification);
      this.getNotificationPreview().then(function () {
        return _this3.showModal = true;
      });
    },
    getNotificationPreview: function getNotificationPreview() {
      var _this4 = this;
      this.isPreviewLoaded = false;
      return _getNotificationPreview(this.editedNotification.notificationId).then(function (data) {
        _this4.notificationPreview = data;
        _this4.isPreviewLoaded = true;
      });
    },
    updateMessage: function updateMessage(message) {
      this.isPreviewLoaded = false;
      this.editedNotification.message = message;
      clearTimeout(this.timer);
      this.timer = setTimeout(this.updatePreview, this.$debounceTimeout);
    },
    updatePreview: function updatePreview() {
      return this.updateNotificationMessage().then(this.getNotificationPreview);
    },
    onDismiss: function onDismiss() {
      var _this5 = this;
      if (!this.isPreviewLoaded) {
        this.$confirm(this.translate.closeWindow, this.translate.areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted, function (res) {
          return res && _this5.closeModal();
        });
        return;
      }
      this.closeModal();
    },
    closeModal: function closeModal() {
      clearTimeout(this.timer);
      this.editedNotification = null;
      this.showModal = false;
    },
    updateNotificationMessage: function updateNotificationMessage() {
      var _this6 = this;
      var _this$editedNotificat = this.editedNotification,
        id = _this$editedNotificat.id,
        message = _this$editedNotificat.message;
      return _updateNotificationMessage(id, message).then(function () {
        return _this6.updateNotification(id, message);
      });
    },
    updateNotification: function updateNotification(id, message) {
      var index = this.notifications.findIndex(function (_ref3) {
        var notificationId = _ref3.notificationId;
        return notificationId === id;
      });
      this.notifications[index].message = message;
    },
    resizeFrame: function resizeFrame(e) {
      e.target.style.height = e.target.contentDocument.body.scrollHeight + 'px';
      e.target.style.width = e.target.contentDocument.body.scrollWidth + 'px';
    }
  })
};