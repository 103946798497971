function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import QuestionSelection from '@/components/Pages/Compliance/Reports/PredefinedReports/QuestionSelection';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import Article30Table from '@/components/Pages/Compliance/Reports/PredefinedReports/article30/Table';
import { getSelectableQuestionnairesByQuestionnaireTypeId, getAllQuestions } from '@/api/compliance/questionnaire';
import { getAllProcessors } from '@/api/compliance/informationAssets';
import { getAnsweredAudits } from '@/api/compliance/reports/questionnaire';
import { mapQuestionAnswers } from '@/components/Pages/Compliance/Reports/PredefinedReports/utils';
import { QuestionnaireTypesAudit } from '@/constants/compliance/QuestionnaireTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { ReportTypes } from '@/constants/reports';
import { VIEW_TYPES } from '@/constants/ViewTypes';
export default {
  name: 'IAAnsweredProcessorAudits',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect,
    QuestionSelection: QuestionSelection,
    RismaButton: RismaButton,
    Article30Table: Article30Table,
    Notification: Notification
  },
  props: {
    savedReportOptions: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      updatedFromPreview: false,
      isLoaded: false,
      questionnaires: [],
      processors: [],
      selectedQuestionnaireTypeId: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR],
      selectedQuestionnaireId: null,
      questions: [],
      selectedQuestions: [],
      selectedProcessorIds: [],
      selectedProcessors: [],
      rows: [],
      showReport: false,
      showQuestions: false,
      showSelectProcessors: true,
      errorFromBackend: '',
      selectedTableView: null,
      savedReportType: ReportTypes.IA_ANSWERED_AUDIT,
      reportOptions: {},
      ObjectTypes: ObjectTypes,
      QuestionnaireTypesAudit: QuestionnaireTypesAudit,
      localStateName: 'ia_answered_processor_audits_table',
      translate: getTranslate['IAAnsweredProcessorAudits']()
    };
  },
  computed: {
    nameColumnTitle: function nameColumnTitle() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER], this.translate.dataController), QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR], this.translate.dataProcessor), QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM], this.translate.system), QuestionnaireTypesAudit[ObjectTypes.CONTRACT], this.translate.contract), QuestionnaireTypesAudit[ObjectTypes.INFORMATION_ASSET], this.translate.informationAsset)[this.selectedQuestionnaireTypeId];
    },
    questionnairesOptions: function questionnairesOptions() {
      if (!this.questionnaires) return [];
      return this.questionnaires.map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    filteredProcessors: function filteredProcessors() {
      if (!this.selectedQuestionnaireId) return [];
      return this.processors;
      // TO ADD: should be filtered by selectedQuestionnaireId
      // return this.processors.filter(processor => processor.questionnaireId === this.selectedQuestionnaireId);
    },
    processorsOptions: function processorsOptions() {
      return this.filteredProcessors.map(function (processor) {
        return {
          id: processor.id,
          title: processor.name
        };
      });
    },
    questionnaireTypesOptions: function questionnaireTypesOptions() {
      return [{
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM],
        label: this.translate.systems
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR],
        label: this.translate.dataProcessors
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER],
        label: this.translate.dataControllers
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.INFORMATION_ASSET],
        label: this.translate.customAssets
      }, {
        value: this.QuestionnaireTypesAudit[this.ObjectTypes.CONTRACT],
        label: this.translate.contracts
      }];
    }
  },
  mounted: function mounted() {
    if (this.savedReportOptions) {
      this.selectedQuestionnaireTypeId = this.savedReportOptions.selectedQuestionnaireTypeId;
    }
    this.getAll();
  },
  methods: {
    getAll: function getAll() {
      var _this = this;
      return getSelectableQuestionnairesByQuestionnaireTypeId(this.selectedQuestionnaireTypeId).then(function (response) {
        return _this.questionnaires = response;
      }).then(getAllProcessors).then(function (response) {
        _this.processors = response.list;
        _this.isLoaded = true;
        if (_this.savedReportOptions) {
          _this.applySavedReportOptions();
        }
      });
    },
    onQuestionnaireTypeSelect: function onQuestionnaireTypeSelect(id) {
      var _this2 = this;
      this.selectedQuestionnaireTypeId = id;
      this.selectedQuestionnaireId = null;
      this.selectedProcessorIds = [];
      this.selectedQuestions = [];
      this.showSelectProcessors = false;
      this.selectedQuestions = [];
      this.showReport = false;
      return getSelectableQuestionnairesByQuestionnaireTypeId(id).then(function (response) {
        return _this2.questionnaires = response;
      }).then(getAllProcessors).then(function (response) {
        if (id === _this2.QuestionnaireTypesAudit[_this2.ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR]) {
          _this2.processors = response.list;
          _this2.showSelectProcessors = true;
        }
      });
    },
    onQuestionnaireSelect: function onQuestionnaireSelect(id) {
      if (this.selectedQuestionnaireId === id) return;
      this.selectedQuestionnaireId = id;
      this.selectedQuestions = [];
      this.getQuestions(id);
    },
    getQuestions: function getQuestions(id) {
      var _this3 = this;
      this.showQuestions = false;
      getAllQuestions(id).then(function (response) {
        _this3.questions = response.questions;
        _this3.showQuestions = true;
      });
    },
    onSelectProcessors: function onSelectProcessors(processors) {
      this.selectedProcessorIds = processors.map(function (item) {
        return item.id;
      });
    },
    onQuestionsSelect: function onQuestionsSelect(selectedQuestions) {
      this.selectedQuestions = selectedQuestions;
    },
    onCreateReport: function onCreateReport() {
      var _this4 = this;
      this.showReport = false;
      getAnsweredAudits(this.selectedQuestionnaireId, this.selectedQuestions, this.selectedProcessorIds, 'processorIds').then(function (_ref) {
        var data = _ref.data;
        _this4.createRows(data);
        _this4.showReport = true;
        _this4.errorFromBackend = '';
        _this4.updateReportOptions();
      }).catch(function (error) {
        return _this4.errorFromBackend = error.response.error;
      });
    },
    createRows: function createRows(data) {
      var result = [];
      data.entities.forEach(function (item) {
        if (!item.answered) return;
        var row = _objectSpread(_objectSpread({}, item.activity), {}, {
          children: [],
          parents: [],
          fullname: item.fullname,
          email: item.email,
          send: item.created,
          answered: item.answered,
          firstClick: item.firstClick,
          requestExpired: item.requestExpired,
          cells: mapQuestionAnswers(data.questions, data.informationAssets, data.answers[item.id], data.files)
        });
        result.push(row);
      });
      this.rows = result;
    },
    updateReportOptions: function updateReportOptions() {
      this.reportOptions = {
        selectedQuestionnaireId: this.selectedQuestionnaireId,
        selectedQuestions: this.selectedQuestions,
        selectedProcessorIds: this.selectedProcessorIds,
        selectedQuestionnaireTypeId: this.selectedQuestionnaireTypeId
      };
    },
    applySavedReportOptions: function applySavedReportOptions() {
      var _this5 = this;
      this.selectedTableView = VIEW_TYPES.EXPANDED;
      this.onQuestionnaireSelect(this.savedReportOptions.selectedQuestionnaireId);
      this.selectedQuestions = this.savedReportOptions.selectedQuestions;
      this.selectedProcessors = this.processors.filter(function (processor) {
        return _this5.savedReportOptions.selectedProcessorIds.includes(processor.id);
      });
      this.onCreateReport();
    },
    onDismissModal: function onDismissModal() {
      if (!this.updatedFromPreview) return;
      this.updatedFromPreview = false;
      return this.onCreateReport();
    }
  }
};