import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "risma-step-progress-bar"
};
var _hoisted_2 = {
  class: "icon-element justify-center group-first:justify-start group-first:ml-[38%] group-last:justify-end group-last:mr-[38%]"
};
var _hoisted_3 = {
  class: "text-element"
};
var _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, function (item, _, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "working-element group"
    }, [_createElementVNode("div", _hoisted_2, [index > 0 ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["risma-progress-bar-total", index === Object.keys($props.data).length - 1 ? 'move-right-last' : 'move-right'])
    }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createVNode(_component_feather_icon, {
      icon: $options.getIcon(item.state),
      color: $options.getColor(item.state),
      height: "70",
      width: "60",
      class: "stroke-3"
    }, null, 8 /* PROPS */, ["icon", "color"]), index < Object.keys($props.data).length - 1 ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([index === 0 ? 'move-left-first' : 'move-left', "risma-progress-bar-total"])
    }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.titles, function (element, elementIndex) {
      return _openBlock(), _createElementBlock("div", {
        key: elementIndex,
        class: _normalizeClass({
          'text-center': item.titles.length == 1
        })
      }, [_createElementVNode("p", {
        innerHTML: element
      }, null, 8 /* PROPS */, _hoisted_4)], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}