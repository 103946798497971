import { Http } from '../../index';
import { handleRequestResponse } from '../../httpUtils';
export function getManagementReportByProjectId(id) {
  return handleRequestResponse(Http.get("/compliance/projects/".concat(id, "/report/managementreport")));
}
export function createManagementReport(projectId, data) {
  return handleRequestResponse(Http.post("/compliance/projects/".concat(projectId, "/report/managementreport"), data));
}
export function updateManagementReport(projectId, id, data) {
  return handleRequestResponse(Http.patch("/compliance/projects/".concat(projectId, "/report/managementreport/").concat(id), data));
}
export function deleteManagementReportById(projectId, id) {
  return handleRequestResponse(Http.delete("/compliance/projects/".concat(projectId, "/report/managementreport/").concat(id)));
}
export function closeTheYear() {
  return handleRequestResponse(Http.patch("/admin/compliance/closeyear"));
}