import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("tr", null, [_cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1 /* HOISTED */)), _createElementVNode("td", null, [_createElementVNode("div", {
    class: _normalizeClass($props.cellClasses)
  }, _toDisplayString($props.title), 3 /* TEXT, CLASS */)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.rightsList, function (item, index) {
    return _openBlock(), _createElementBlock("td", {
      key: "row_of_rights_".concat(index)
    }, [_createElementVNode("div", {
      class: _normalizeClass($props.cellClasses)
    }, [item >= $props.accessLevel ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "check",
      width: "25",
      height: "25",
      class: _normalizeClass($options.featherIconClasses.check)
    }, null, 8 /* PROPS */, ["class"])) : (_openBlock(), _createBlock(_component_feather_icon, {
      key: 1,
      icon: "x",
      width: "25",
      height: "25",
      class: _normalizeClass($options.featherIconClasses.x)
    }, null, 8 /* PROPS */, ["class"]))], 2 /* CLASS */)]);
  }), 128 /* KEYED_FRAGMENT */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.userIcons, function (icon, iconIndex) {
    return _openBlock(), _createElementBlock("td", {
      key: "row_of_rights_user_".concat(iconIndex)
    }, [_createVNode(_component_feather_icon, {
      icon: icon.icon,
      width: "25",
      height: "25",
      class: _normalizeClass(icon.iconClasses)
    }, null, 8 /* PROPS */, ["icon", "class"])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}