function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useUserStore } from '@/Store/userStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getProcesses as _getProcesses, getControlRisks as _getControlRisks, getAllMotherControls } from '@/api/controls/controls';
import { getAccounts as _getAccounts, getOrganisationsAndFilterByUserAccess } from '@/api/risma/data';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import MultipleDateRange from '@/components/Molecules/MultipleDateRange';
import { UserLevels } from '@/constants/UserLevels';
import { sortAlphabetic } from '@/utils/sort';
import { getAccountsWithChildren } from '@/utils/controls/accounts';
import { getAssociationName } from '@/utils/controls/associations';
import { toPureDateFormat, getDateBeforeToday } from '@/utils/date';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: 'ControlsDashboardFilters',
  components: {
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    RismaDateRangePicker: RismaDateRangePicker,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    MultipleDateRange: MultipleDateRange
  },
  props: {
    localStorageKey: {
      type: String,
      required: false,
      default: 'controls_dashboard_filters'
    },
    useMultipleDateRanges: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['applyFilters', 'applyFiltersOnLoad'],
  setup: function setup(props) {
    return {
      localStorageData: getLocalStorageComputed(props.localStorageKey, null, true),
      showFilters: getLocalStorageComputed("".concat(props.localStorageKey, "_show_filters"), false, true)
    };
  },
  data: function data() {
    return {
      dateRange: {
        dateStart: toPureDateFormat(getDateBeforeToday(2, 'week')),
        dateEnd: toPureDateFormat(new Date())
      },
      searchData: {
        responsible: [],
        reviewer: [],
        organisationIds: [],
        activityTypeIds: [],
        processIds: [],
        accountIds: [],
        riskIds: [],
        tagIds: [],
        motherControlId: null
      },
      dateFilterSelected: 0,
      organisations: [],
      processes: [],
      accounts: [],
      risks: [],
      motherControls: [],
      multipleDateRanges: [{
        dateStart: toPureDateFormat(getDateBeforeToday(2, 'week')),
        dateEnd: toPureDateFormat(new Date())
      }],
      isLoaded: false,
      translate: getTranslate['ControlsDashboardFilters']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, ['activeUsersWithLevelAccess'])), mapState(useActivityTypesStore, {
    activityTypes: 'controlActivityTypes'
  })), mapState(useTagsStore, ['tags'])), mapState(useUserStore, ['currentUser'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    usersWithControlAccess: function usersWithControlAccess() {
      return this.activeUsersWithLevelAccess('level_controls', UserLevels.NORMAL);
    },
    processesName: function processesName() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    accountTitle: function accountTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    showSwitchTitle: function showSwitchTitle() {
      var action = this.showFilters ? this.translate.hide : this.translate.show;
      return "".concat(action, " ").concat(this.translate.filters.toLowerCase());
    },
    motherControlOptions: function motherControlOptions() {
      return this.motherControls.map(function (control) {
        return {
          label: "".concat(control.cpath, " ").concat(control.title),
          value: +control.cpath
        };
      });
    },
    dateFilterOptions: function dateFilterOptions() {
      return [{
        value: 0,
        label: this.translate.deadlinedDate
      }, {
        value: 1,
        label: this.translate.completedDate
      }];
    },
    accountsWithChildren: function accountsWithChildren() {
      return getAccountsWithChildren(this.accounts);
    },
    dateParams: function dateParams() {
      return {
        start: this.dateFilterSelected ? 'completedAfter' : 'deadlineAfter',
        end: this.dateFilterSelected ? 'completedBefore' : 'deadlineBefore'
      };
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      _this.getValuesFromStorage();
      _this.$emit('applyFiltersOnLoad', _this.getSearchData());
      _this.isLoaded = true;
    });
  },
  methods: {
    getData: function getData() {
      return Promise.all([this.getProcesses(), this.getAccounts(), this.getControlRisks(), this.getOrganisations(), this.getMotherControls()]);
    },
    getProcesses: function getProcesses() {
      var _this2 = this;
      return _getProcesses().then(function (processes) {
        _this2.processes = sortAlphabetic(processes.list, 'title');
        _this2.processes.unshift({
          id: 0,
          title: _this2.translate.controlsWithNoProcesSet
        });
      });
    },
    getMotherControls: function getMotherControls() {
      var _this3 = this;
      return getAllMotherControls().then(function (_ref) {
        var list = _ref.list;
        return _this3.motherControls = list;
      });
    },
    getAccounts: function getAccounts() {
      var _this4 = this;
      return _getAccounts().then(function (_ref2) {
        var list = _ref2.list;
        return _this4.accounts = list;
      });
    },
    getControlRisks: function getControlRisks() {
      var _this5 = this;
      return _getControlRisks().then(function (_ref3) {
        var list = _ref3.list;
        return _this5.risks = list;
      });
    },
    getOrganisations: function getOrganisations() {
      var _this6 = this;
      var filterByUserAccess = this.currentUser.level_controls === UserLevels.PRIVILEGED;
      return getOrganisationsAndFilterByUserAccess(filterByUserAccess).then(function (_ref4) {
        var list = _ref4.list;
        return _this6.organisations = list;
      });
    },
    changeDatePicker: function changeDatePicker(event) {
      if (!event) return;
      this.dateRange.dateStart = event.startDate;
      this.dateRange.dateEnd = event.endDate;
    },
    onDateDiscard: function onDateDiscard() {
      this.changeDatePicker({
        startDate: '',
        endDate: ''
      });
    },
    applyFilters: function applyFilters() {
      var searchData = this.getSearchData();
      this.localStorageData = searchData;
      this.$emit('applyFilters', searchData);
    },
    getSearchData: function getSearchData() {
      var _this7 = this;
      var searchData = {
        active: 1
      };
      var _this$dateRange = this.dateRange,
        dateStart = _this$dateRange.dateStart,
        dateEnd = _this$dateRange.dateEnd;
      if (this.useMultipleDateRanges) {
        searchData.multipleDateRanges = this.multipleDateRanges.map(function (_ref5) {
          var dateStart = _ref5.dateStart,
            dateEnd = _ref5.dateEnd;
          return _defineProperty(_defineProperty({}, _this7.dateParams.start, dateStart), _this7.dateParams.end, dateEnd);
        });
      } else if (dateEnd && dateStart) {
        searchData[this.dateParams.start] = dateStart;
        searchData[this.dateParams.end] = dateEnd;
      }
      Object.entries(this.searchData).forEach(function (_ref7) {
        var _ref8 = _slicedToArray(_ref7, 2),
          key = _ref8[0],
          selectedValue = _ref8[1];
        var isArray = Array.isArray(selectedValue);
        if (!selectedValue || isArray && !selectedValue.length) return;
        searchData[key] = isArray ? selectedValue.map(function (value) {
          return value.id;
        }) : selectedValue;
      });
      return searchData;
    },
    getValuesFromStorage: function getValuesFromStorage() {
      if (!this.localStorageData) return;
      var _this$localStorageDat = this.localStorageData,
        deadlineAfter = _this$localStorageDat.deadlineAfter,
        deadlineBefore = _this$localStorageDat.deadlineBefore,
        completedAfter = _this$localStorageDat.completedAfter,
        completedBefore = _this$localStorageDat.completedBefore,
        responsible = _this$localStorageDat.responsible,
        reviewer = _this$localStorageDat.reviewer,
        organisationIds = _this$localStorageDat.organisationIds,
        activityTypeIds = _this$localStorageDat.activityTypeIds,
        processIds = _this$localStorageDat.processIds,
        accountIds = _this$localStorageDat.accountIds,
        riskIds = _this$localStorageDat.riskIds,
        tagIds = _this$localStorageDat.tagIds,
        multipleDateRanges = _this$localStorageDat.multipleDateRanges,
        motherControlId = _this$localStorageDat.motherControlId,
        solutionId = _this$localStorageDat.solutionId;
      try {
        if (deadlineAfter && deadlineBefore) {
          this.dateRange.dateStart = toPureDateFormat(deadlineAfter);
          this.dateRange.dateEnd = toPureDateFormat(deadlineBefore);
          this.dateFilterSelected = 0;
        }
        if (completedAfter && completedBefore) {
          this.dateRange.dateStart = toPureDateFormat(completedAfter);
          this.dateRange.dateEnd = toPureDateFormat(completedBefore);
          this.dateFilterSelected = 1;
        }
        if (this.useMultipleDateRanges) {
          var _multipleDateRanges$;
          this.multipleDateRanges = multipleDateRanges.map(function (_ref9) {
            var deadlineAfter = _ref9.deadlineAfter,
              deadlineBefore = _ref9.deadlineBefore,
              completedAfter = _ref9.completedAfter,
              completedBefore = _ref9.completedBefore;
            var dateStart = toPureDateFormat(deadlineAfter !== null && deadlineAfter !== void 0 ? deadlineAfter : completedAfter);
            var dateEnd = toPureDateFormat(deadlineBefore !== null && deadlineBefore !== void 0 ? deadlineBefore : completedBefore);
            return {
              dateStart: dateStart,
              dateEnd: dateEnd
            };
          });
          if ((_multipleDateRanges$ = multipleDateRanges[0]) !== null && _multipleDateRanges$ !== void 0 && _multipleDateRanges$.completedAfter) this.dateFilterSelected = 1;
        }
        this.searchData = {
          motherControlId: motherControlId || null,
          responsible: this.filterByIds(this.usersWithControlAccess, responsible),
          reviewer: this.filterByIds(this.usersWithControlAccess, reviewer),
          organisationIds: this.filterByIds(this.organisations, organisationIds),
          activityTypeIds: this.filterByIds(this.activityTypes, activityTypeIds),
          processIds: this.filterByIds(this.processes, processIds),
          accountIds: this.filterByIds(this.accountsWithChildren, accountIds),
          riskIds: this.filterByIds(this.risks, riskIds),
          tagIds: this.filterByIds(this.tags, tagIds),
          solutionId: this.filterByIds(this.frameworks, solutionId)
        };
      } catch (_unused) {
        this.resetFilter();
      }
    },
    filterByIds: function filterByIds(array, ids) {
      if (!ids) return [];
      return array.filter(function (item) {
        return ids.includes(item.id);
      });
    },
    resetFilter: function resetFilter() {
      this.localStorageData = null;
      this.searchData = {
        responsible: [],
        reviewer: [],
        organisationIds: [],
        activityTypeIds: [],
        processIds: [],
        accountIds: [],
        riskIds: [],
        tagIds: [],
        motherControlId: null,
        solutionId: []
      };
      this.dateFilterSelected = 0;
      this.applyFilters();
    }
  }
};