import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, withCtx as _withCtx, vShow as _vShow, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex bg-inherit"
};
var _hoisted_2 = {
  key: 2,
  class: "mr-2"
};
var _hoisted_3 = {
  key: 3,
  class: "p-2"
};
var _hoisted_4 = ["placeholder"];
var _hoisted_5 = {
  class: "px-6 pt-6 pb-2"
};
var _hoisted_6 = {
  key: 0,
  class: "px-6 py-2 border-t border-rGrey-400"
};
var _hoisted_7 = {
  class: "px-6 py-2 border-t border-rGrey-400"
};
var _hoisted_8 = ["href"];
var _hoisted_9 = {
  class: "px-6 py-2 border-t border-rGrey-400"
};
var _hoisted_10 = {
  class: "block h-8 min-w-168px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
  href: "/logout"
};
var _hoisted_11 = {
  key: 1,
  class: "px-6 pt-2 border-t border-rGrey-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ai_button_with_tooltip = _resolveComponent("ai-button-with-tooltip");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notifications_modal = _resolveComponent("notifications-modal");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_search_modal = _resolveComponent("search-modal");
  var _component_router_link = _resolveComponent("router-link");
  var _directive_click_outside = _resolveDirective("click-outside");
  return $data.loaded ? _withDirectives((_openBlock(), _createElementBlock("nav", _hoisted_1, [_ctx.aiAssistantEnabled ? (_openBlock(), _createBlock(_component_ai_button_with_tooltip, {
    key: 0,
    mode: $options.tooltipMode,
    "tooltip-styles": {
      right: '-8px'
    },
    "icon-type": "primary",
    "onClick:icon": _ctx.openAIAssistant,
    "onClose:tooltip": _cache[0] || (_cache[0] = function ($event) {
      return $setup.aiNewFeature = false;
    })
  }, null, 8 /* PROPS */, ["mode", "onClick:icon"])) : _createCommentVNode("v-if", true), !$props.oldVersion ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: "https://support.rismasystems.com/",
    target: "_blank",
    rel: "noopener noreferrer",
    class: _normalizeClass(["flex w-8 h-8 mr-2 rounded hover:bg-trans-15", $options.hoverTextColor])
  }, [_createVNode(_component_feather_icon, {
    icon: "help-circle",
    height: "19",
    width: "19",
    class: "text-inherit stroke-2 m-auto"
  })], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$props.oldVersion ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["relative flex w-8 h-8 rounded hover:bg-trans-15 hover:cursor-pointer", $options.hoverTextColor]),
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.toggleNotifications && $options.toggleNotifications.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "bell",
    height: "20",
    width: "20",
    class: "text-inherit stroke-2 m-auto"
  }), $options.unreadNotificationsCount ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass(["absolute flex items-center justify-center h-4 bg-rRed-500 right-0 top-0 rounded-full font-semibold text-0.6rem text-white", $options.unreadNotificationsCount > 9 ? 'w-1.35rem' : 'w-4'])
  }, _toDisplayString($options.unreadNotificationsCount), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $data.showNotifications ? (_openBlock(), _createBlock(_component_notifications_modal, {
    key: 0,
    notifications: $props.notifications,
    onToggle: $options.toggleNotifications,
    onClickNotification: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('clickNotification', $event);
    }),
    onMarkAllNotificationsAsRead: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('markAllNotificationsAsRead');
    })
  }, null, 8 /* PROPS */, ["notifications", "onToggle"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hideNotifications]]) : _createCommentVNode("v-if", true), $props.oldVersion ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
    onClick: _cache[4] || (_cache[4] = function () {
      return $options.emitSearch && $options.emitSearch.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "search",
    width: "34",
    height: "34",
    class: "text-inherit stroke-2 inline-block m-auto"
  })])])) : _createCommentVNode("v-if", true), !$props.oldVersion ? (_openBlock(), _createElementBlock("div", {
    key: 4,
    class: _normalizeClass(['relative', $data.isSearchShown ? 'min-w-240px mx-1' : 'box-content w-8'])
  }, [$data.isSearchShown ? _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 0,
    ref: "globalSearch",
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.searchField = $event;
    }),
    placeholder: "".concat($options.translate.search, "..."),
    type: "text",
    class: "w-full h-8 min-w-240px absolute top-0 left-0 pl-3 pr-8 rounded-20px outline-none focus:shadow-search text-0.9rem font-medium text-black placeholder:text-rGrey-700 placeholder:font-medium placeholder:text-0.9rem",
    onKeyup: _cache[6] || (_cache[6] = _withKeys(function () {
      return $options.onSearch && $options.onSearch.apply($options, arguments);
    }, ["enter"]))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)), [[_vModelText, $data.searchField]]) : _createCommentVNode("v-if", true), !$data.isSearchLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 1,
    class: _normalizeClass(["absolute top-0.4rem right-0.5rem z-10", $data.isSearchShown ? 'text-blue-750' : 'text-white'])
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true), $data.isSearchShown && $data.isSearchLoaded ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 2,
    icon: "x",
    width: "16",
    height: "16",
    class: "absolute top-0 bottom-0 my-auto right-0.7rem stroke-2 text-rGrey-700 cursor-pointer hover:text-black",
    onClick: $options.hide
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([$options.hoverTextColor, "".concat($data.isSearchLoaded ? 'bg-inherit' : 'bg-transparent'), 'flex w-8 h-8 rounded hover:text-white hover:bg-trans-15 hover:cursor-pointer', $data.isSearchShown && 'w-4 h-4 right-0 top-auto mr-2 ']),
    onClick: _cache[7] || (_cache[7] = function () {
      return $options.showSearchBar && $options.showSearchBar.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "search",
    width: "21",
    height: "21",
    class: "text-inherit stroke-2 m-auto"
  })], 2 /* CLASS */), $data.showSearchModal ? (_openBlock(), _createBlock(_component_search_modal, {
    key: 3,
    data: $data.searchDataset,
    scoring: $props.scoring,
    users: $props.users,
    organisations: $props.organisations,
    "current-user": _ctx.currentUser,
    "activity-types": $props.activityTypes,
    "workflow-statuses": $data.workflowStatuses,
    onToggle: $options.toggleSearch
  }, null, 8 /* PROPS */, ["data", "scoring", "users", "organisations", "current-user", "activity-types", "workflow-statuses", "onToggle"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $options.isPerformanceReportEnabled ? (_openBlock(), _createElementBlock("div", {
    key: 5,
    class: _normalizeClass(["flex w-8 h-8 ml-2 rounded hover:bg-trans-15", $options.hoverTextColor])
  }, [_createVNode(_component_router_link, {
    to: "/performance-report",
    class: "m-auto"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "bar-chart",
        width: "23",
        height: "23",
        class: "text-inherit stroke-2"
      })];
    }),
    _: 1 /* STABLE */
  })], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _cache[11] || (_cache[11] = _createElementVNode("div", {
    class: "self-center w-px h-4 mx-4 bg-trans-15"
  }, null, -1 /* HOISTED */)), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass(["flex w-8 h-8 rounded hover:bg-trans-15 hover:cursor-pointer", $options.hoverTextColor]),
    onClick: _cache[8] || (_cache[8] = function () {
      return $options.toggleUserSection && $options.toggleUserSection.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "settings",
    width: "18",
    height: "18",
    class: "text-inherit stroke-2 m-auto"
  })], 2 /* CLASS */), [[_vShow, $options.showAvatar || !$props.oldVersion]]), _withDirectives(_createElementVNode("div", {
    class: "cursor-pointer",
    onClick: _cache[9] || (_cache[9] = function () {
      return $options.toggleUserSection && $options.toggleUserSection.apply($options, arguments);
    })
  }, _toDisplayString($options.getAvatar), 513 /* TEXT, NEED_PATCH */), [[_vShow, !$options.showAvatar && $props.oldVersion]]), _withDirectives(_createElementVNode("div", {
    class: "user-section absolute z-10 right-4 top-full -mt-2px pb-6 rounded whitespace-nowrap bg-rWhite-500 shadow-drop-down text-sm font-medium text-rBlack-500",
    onClick: _cache[10] || (_cache[10] = function () {
      return $options.toggleUserSection && $options.toggleUserSection.apply($options, arguments);
    })
  }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_router_link, {
    to: {
      name: $data.SettingsNav.personal.routerName
    },
    class: "block h-8 min-w-168px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.translate.personalSettings), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), $options.showSettingsMenuItems ? (_openBlock(), _createElementBlock("div", _hoisted_6, [$options.settingsRights.general ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    class: "block h-8 min-w-168px mb-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.general.rights($data.user) ? $data.SettingsNav.general.routerName : $data.SettingsNav.controlPanel.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.general), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.settingsRights.actions ? (_openBlock(), _createBlock(_component_router_link, {
    key: 1,
    class: "block h-8 min-w-168px my-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.actionsSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.actionsSettings), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.settingsRights.contracts ? (_openBlock(), _createBlock(_component_router_link, {
    key: 2,
    class: "block h-8 min-w-168px my-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.contract.rights($data.user, $data.settings) ? $data.SettingsNav.contract.routerName : $data.SettingsNav.contractsControlPanel.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.contract), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.settingsRights.controls ? (_openBlock(), _createBlock(_component_router_link, {
    key: 3,
    class: "block h-8 min-w-168px my-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.controlsSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.controlsSettings), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.settingsRights.compliance ? (_openBlock(), _createBlock(_component_router_link, {
    key: 4,
    class: "block h-8 min-w-168px my-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.complianceSettings.rights($data.user, $data.settings) ? $data.SettingsNav.complianceSettings.routerName : $data.SettingsNav.complianceControlPanel.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.complianceSettings), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $data.SettingsNav.riskSettings.rights($data.user, $data.settings) || $data.SettingsNav.riskPeriod.rights($data.user, $data.settings) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 5,
    class: "block h-8 min-w-168px my-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: $data.SettingsNav.riskSettings.rights($data.user, $data.settings) ? {
      name: $data.SettingsNav.riskProjects.routerName
    } : $options.riskPeriodUrl
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.riskSettings), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.settingsRights.incident ? (_openBlock(), _createBlock(_component_router_link, {
    key: 6,
    class: "block h-8 min-w-168px my-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.incident.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.incident), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), $options.settingsRights.app ? (_openBlock(), _createBlock(_component_router_link, {
    key: 7,
    class: "block h-8 min-w-168px mt-px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.appSettings.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.menuTitles.appSettings), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("a", {
    href: $options.whatsNewLink,
    target: "_blank",
    class: "block h-8 min-w-168px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8"
  }, _toDisplayString($options.translate.whatsNew), 9 /* TEXT, PROPS */, _hoisted_8)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("a", _hoisted_10, _toDisplayString($options.translate.logout), 1 /* TEXT */)]), $data.settings['servername'] && $data.user.is_admin ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_router_link, {
    class: "block h-8 min-w-168px pl-2 pr-6 rounded hover:bg-rGrey-400 leading-8",
    to: {
      name: $data.SettingsNav.admin.routerName
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.SettingsNav.admin.title)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */), [[_vShow, $data.isUserSectionExpanded]])])), [[_directive_click_outside, $options.hide]]) : _createCommentVNode("v-if", true);
}