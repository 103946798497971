import i18n from '@/i18n';
export var getTranslate = {
  LinksDepPreview: function LinksDepPreview() {
    return {
      description: i18n.t('Description'),
      start: i18n.t('Start date'),
      presentStatus: i18n.t('Status'),
      expirationDate: i18n.t('Expiration date'),
      deadline: i18n.t('Deadline'),
      reviewDeadline: i18n.t('Review deadline'),
      responsible: i18n.t('Responsible'),
      reviewer: i18n.t('Reviewer'),
      organisations: i18n.t('Organisations'),
      incidentOccurred: i18n.t('Incident occurred'),
      severity: i18n.t('Severity'),
      status: i18n.t('Status'),
      impact: i18n.t('Impact'),
      threat: i18n.t('Threat'),
      levelOfThreat: i18n.t('Level of threat'),
      vulnerability: i18n.t('Vulnerability'),
      probability: i18n.t('Probability'),
      vatNumber: i18n.t('VAT number'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      phone: i18n.t('Phone'),
      countryCodeCountry: i18n.t('Country code/Country'),
      email: i18n.t('Email'),
      contractRole: i18n.t('Contract role')
    };
  },
  LinksModal: function LinksModal() {
    return {
      links: i18n.t('Links'),
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      selectProjectOrActivity: i18n.t('Select project or activity'),
      choose: i18n.t('Choose'),
      createNew: i18n.t('Create new'),
      select: i18n.t('Select'),
      clickToSelect: i18n.t('Click to select'),
      addedLinks: i18n.t('Added links')
    };
  },
  LinksRecommendedModal: function LinksRecommendedModal() {
    return {
      addRecommendedActivities: i18n.t('Add recommended activities'),
      close: i18n.t('Close'),
      recommendedActivitiesForGapArticle: i18n.t('Recommended activities for gap article'),
      add: i18n.t('Add'),
      noRecommendedActivitiesAvailable: i18n.t('No recommended activities available'),
      clickAddInOrderToApplyTheRecommendedActi: i18n.t('Click "Add" in order to apply the recommended activity to your list of mitigation activities'),
      added: i18n.t('added')
    };
  }
};