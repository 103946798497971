// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-selector[data-v-c4349a0e]  .modal-container {
  padding: 0;
}
.attachment-selector[data-v-c4349a0e]  button.util {
  margin: 0;
}
.attachment-selector[data-v-c4349a0e]  .file .risma-button {
  top: 1.5rem;
}
.attachment-selector-files-wrap[data-v-c4349a0e] {
  width: 65%;
}
.attachment-selector-files-wrap.no-title[data-v-c4349a0e] {
  flex-grow: 1;
}
.attachment-selector-item[data-v-c4349a0e] {
  margin: 3px 0 0 0;
}
.attachment-selector-item[data-v-c4349a0e]:first-child {
  margin-top: 0;
}
.attachment-selector-remove[data-v-c4349a0e] {
  cursor: pointer;
}
.attachment-selector-file[data-v-c4349a0e] {
  display: inline-flex;
  padding: 3px 20px;
  margin: 3px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #FFFFFF;
  background: #347480;
  border-radius: 3px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
