import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "mr-3"
};
var _hoisted_3 = {
  class: "level-of-threat-modal"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "validation-error"
};
var _hoisted_6 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_7 = {
  key: 1,
  class: "validation-error"
};
var _hoisted_8 = {
  key: 0,
  class: "mb-3"
};
var _hoisted_9 = {
  class: "flex items-center -mr-6"
};
var _hoisted_10 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_11 = {
  key: 0,
  class: "input-field-container add-option-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $props.activity.threatLevelsLabel,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($data.translate.dropdownWithValues), 1 /* TEXT */), _createElementVNode("a", {
    href: "#",
    class: "flex",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.editLevel && $options.editLevel.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    class: "text-green-50 stroke-2 mr-1",
    icon: $options.featherIcon
  }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("span", null, _toDisplayString($options.threatText), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_3, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalTitle,
    "show-buttons": $data.showButtons,
    onDismiss: _ctx.toggleModal
  }, {
    body: _withCtx(function () {
      return [$data.notificationError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        ref: "notifError",
        class: "notification-wrap",
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationError), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _createElementVNode("fieldset", null, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass([{
          error: $data.labelNameValidation
        }, "input-field-container"])
      }, [_createVNode(_component_input_field, {
        title: $data.translate.title,
        "model-value": $data.labelName,
        placeholder: $data.translate.enterTitle,
        enabled: $options.isOpen || $options.isDraft,
        type: "text",
        "onUpdate:modelValue": _ctx.onChangeLabel
      }, null, 8 /* PROPS */, ["title", "model-value", "placeholder", "enabled", "onUpdate:modelValue"])], 2 /* CLASS */), _createElementVNode("span", _hoisted_5, _toDisplayString($data.labelNameValidation), 1 /* TEXT */)]), _createElementVNode("div", {
        class: _normalizeClass([{
          disabled: $data.isEditMode
        }, "mb-3"])
      }, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
        title: $data.translate.fieldType,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", null, [_createElementVNode("div", {
        class: _normalizeClass([{
          error: $data.selectOptionsValidation && !$data.selectOptions.length || $data.fieldTypeValidation
        }, "input-field-container"])
      }, [_createVNode(_component_single_select, {
        options: $data.fieldTypes,
        "model-value": $data.fieldType,
        disabled: $data.isEditMode,
        placeholder: $data.translate.chooseAFieldType
      }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "placeholder"])], 2 /* CLASS */), !$data.selectOptions.length ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($data.selectOptionsValidation), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.fieldTypeValidation ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($data.fieldTypeValidation), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])], 2 /* CLASS */), $data.fieldType === $data.customFieldTypes.SELECT ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
        title: $data.translate.dropdownOptions,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectOptions, function (item, key) {
        return _openBlock(), _createElementBlock("div", {
          key: key,
          class: _normalizeClass([{
            error: item.invalid
          }, "input-field-container mb-2"])
        }, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_input_field, {
          modelValue: item.title,
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return item.title = $event;
          },
          enabled: !$options.isOpen && !$options.isPeriodClosed,
          placeholder: $data.translate.enterDropdownOption,
          class: "flex-grow mr-2",
          type: "text"
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled", "placeholder"]), !$options.isOpen && !$options.isPeriodClosed ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          class: "text-red-50 stroke-2 cursor-pointer",
          icon: "x",
          onClick: function onClick($event) {
            return $options.removeOption(item);
          }
        }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), item.invalid ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($data.selectOptionsValidation), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */)), !$options.isOpen && !$options.isPeriodClosed ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("a", {
        href: "#",
        class: "flex",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.addOption && $options.addOption.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        class: "text-green-50 stroke-2",
        icon: "plus"
      }), _createElementVNode("span", null, _toDisplayString($data.translate.addOption), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), _createVNode(_component_buttons_row, {
        class: "actions",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "show-buttons", "onDismiss"])) : _createCommentVNode("v-if", true)])]);
}