import i18n from '@/i18n';
export var getTranslate = {
  CardIncidents: function CardIncidents() {
    return {
      incidentOccurred: i18n.t('Incident occurred'),
      incidentArchived: i18n.t('Incident archived'),
      status: i18n.t('Status'),
      notSet: i18n.t('Not set'),
      created: i18n.t('Created'),
      accountable: i18n.t('Accountable'),
      description: i18n.t('Description')
    };
  },
  CardProcessLibrary: function CardProcessLibrary() {
    return {
      description: i18n.t('Description'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      organisations: i18n.t('Organisations')
    };
  }
};