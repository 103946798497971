import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "relative rounded-md cursor-default"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  class: "whitespace-nowrap text-sm"
};
var _hoisted_4 = {
  key: 2,
  class: "text-rm-signal-red glob-l1 block mt-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    class: _normalizeClass(["w-fit mb-1", $props.readOnly ? 'cursor-default' : 'cursor-pointer']),
    onClick: $options.toggleToolTip
  }, null, 8 /* PROPS */, ["title", "class", "onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["h-6 w-6 justify-center items-center flex", {
      'rounded bg-rm-signal-red--20': $props.errorHelpText && !$props.readOnly
    }])
  }, [_createVNode(_component_traffic_light, {
    color: $options.trafficLight.color,
    icon: $options.trafficLight.icon,
    title: $options.colorTitle,
    "read-only": $props.readOnly,
    size: $props.size,
    class: _normalizeClass(["tf-light", {
      '!border-rm-signal-red': $props.errorHelpText && !$props.readOnly
    }]),
    onClick: $options.toggleToolTip
  }, null, 8 /* PROPS */, ["color", "icon", "title", "read-only", "size", "class", "onClick"])], 2 /* CLASS */), $data.showSelector && $options.trafficLightsData.length ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["absolute z-100 mt-2 rounded shadow-traffic-drop bg-white", $options.showLabels ? 'p-2' : 'p-3'])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.trafficLightsData, function (light) {
    return _openBlock(), _createElementBlock("div", {
      key: light.number,
      class: _normalizeClass(["flex items-center cursor-pointer", {
        'mb-3 last:mb-0': !$options.showLabels,
        'h-8 mb-0.5 last:mb-0 pr-26px rounded hover:bg-rGrey-200': $options.showLabels
      }]),
      onClick: function onClick($event) {
        return $options.handleSelect(light.number);
      }
    }, [_createVNode(_component_traffic_light, {
      color: light.color,
      icon: light.icon,
      "is-active": light.number === $props.modelValue,
      "read-only": $props.readOnly,
      class: _normalizeClass([{
        'mx-2': $options.showLabels
      }, "tf-light"]),
      size: $props.size
    }, null, 8 /* PROPS */, ["color", "icon", "is-active", "read-only", "class", "size"]), $options.showLabels ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$trans(light.legend)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $props.errorHelpText && !$props.readOnly ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.errorHelpText), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hideTooltip]]);
}