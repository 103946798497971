import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  class: "question-rules"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  key: 1,
  class: "select-wrapper"
};
var _hoisted_5 = {
  class: "select-item"
};
var _hoisted_6 = {
  class: "select-item"
};
var _hoisted_7 = {
  class: "select-item"
};
var _hoisted_8 = {
  class: "select-item choose-result"
};
var _hoisted_9 = {
  class: "add-button-wrap"
};
var _hoisted_10 = {
  key: 2,
  class: "parsed-rules"
};
var _hoisted_11 = {
  class: "parsed-rule-content"
};
var _hoisted_12 = ["onClick"];
var _hoisted_13 = {
  class: "button-wrap mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_Notification = _resolveComponent("Notification");
  var _component_project_questionnaire_gap_selector = _resolveComponent("project-questionnaire-gap-selector");
  var _component_text_box = _resolveComponent("text-box");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.rismaLogic,
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [$data.error ? (_openBlock(), _createBlock(_component_Notification, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            innerHTML: $data.error
          }, null, 8 /* PROPS */, _hoisted_2)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [$data.success ? (_openBlock(), _createBlock(_component_Notification, {
        key: 0,
        type: "success"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            innerHTML: $data.success
          }, null, 8 /* PROPS */, _hoisted_3)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_project_questionnaire_gap_selector, {
    "questionnaire-type-id": _ctx.selectedQuestionnaireTypeId,
    "gap-schema-id": _ctx.gapSchemaId,
    "questionnaire-id": _ctx.questionnaireId,
    "questionnaire-types": _ctx.allQuestionnaireTypes,
    questionnaires: _ctx.allQuestionnaires,
    "gap-schemas": _ctx.allGapSchemas,
    "show-text": _ctx.showText,
    "onUpdate:questionnaireTypeId": _cache[0] || (_cache[0] = function (questionnaireTypeId) {
      return _ctx.selectedQuestionnaireTypeId = questionnaireTypeId;
    }),
    "onUpdate:questionnaireId": _cache[1] || (_cache[1] = function (newQuestionnaireId) {
      return _ctx.questionnaireId = newQuestionnaireId;
    }),
    "onUpdate:gapSchemaId": _cache[2] || (_cache[2] = function (newGapSchemaId) {
      return _ctx.gapSchemaId = newGapSchemaId;
    }),
    "onUpdate:showText": _cache[3] || (_cache[3] = function (newShowText) {
      return _ctx.showText = newShowText;
    })
  }, null, 8 /* PROPS */, ["questionnaire-type-id", "gap-schema-id", "questionnaire-id", "questionnaire-types", "questionnaires", "gap-schemas", "show-text"]), _ctx.showRulesTextMode ? (_openBlock(), _createBlock(_component_text_box, {
    key: 0,
    rows: 15,
    text: $data.rules,
    class: "w-full",
    onUpdated: _cache[4] || (_cache[4] = function ($event) {
      return $data.rules = $event;
    })
  }, null, 8 /* PROPS */, ["text"])) : _createCommentVNode("v-if", true), !_ctx.showRulesTextMode ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [$data.questionsLoaded ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: _ctx.questionId,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.questionId = $event;
    }),
    title: $data.translate.chooseQuestion,
    options: $options.allQuestions,
    "un-sorted": true,
    placeholder: $data.translate.chooseQuestion
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [$data.questionsLoaded ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: $data.answer,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.answer = $event;
    }),
    title: $data.translate.chooseAnswer,
    options: $options.allAnswers,
    placeholder: $data.translate.chooseAnswer
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_7, [_ctx.allGapArticles ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: _ctx.articleId,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return _ctx.articleId = $event;
    }),
    title: $data.translate.chooseGapArticle,
    options: _ctx.allGapArticles,
    "un-sorted": true,
    placeholder: $data.translate.chooseGapArticle
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_single_select, {
    modelValue: $data.result,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.result = $event;
    }),
    title: $data.translate.chooseResult,
    options: $data.trafficOptions,
    "un-sorted": true,
    class: _normalizeClass([$options.classResultSelect, "result-select"]),
    placeholder: $data.translate.chooseResult
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "class", "placeholder"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_button, {
    text: $data.translate.add,
    type: "save",
    onClick: $options.addRule
  }, null, 8 /* PROPS */, ["text", "onClick"])])])) : _createCommentVNode("v-if", true), !_ctx.showRulesTextMode ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.parsedRules, function (rule, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "parsed-rule flex"
    }, [_createElementVNode("span", _hoisted_11, _toDisplayString(rule), 1 /* TEXT */), _createElementVNode("span", {
      onClick: function onClick($event) {
        return $options.handleRemoveRule(key);
      }
    }, [_createVNode(_component_feather_icon, {
      icon: "x",
      class: "ml-3 text-red stroke-2 cursor-pointer"
    })], 8 /* PROPS */, _hoisted_12)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_button, {
    text: $data.translate.saveAndApply,
    type: "save",
    onClick: $options.postRules
  }, null, 8 /* PROPS */, ["text", "onClick"])])]);
}