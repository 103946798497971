function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { cacheItem } from '@/routes/storeCache';
import { getActionsTree as getInitiatives, deleteAction } from '@/api/execution/initiative';
import * as ContractsApi from '@/api/contracts/contracts';
import * as ControlsApi from '@/api/controls/controls';
import { createRiskProject } from '@/api/risk/risk';
import { setControlsRecent } from '@/api/controls/controls';
import { deleteUser as _deleteUser } from '@/api/rismaadmin/user';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TreeList from '@/components/Atoms/TreeList';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { comparePathStrings } from '@/utils/compare';
import { MODULES } from '@/constants/modules';
var ModelTypes = {
  FLAT: 'flat',
  TREE: 'tree'
};
export default {
  name: 'DangerZone',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    TreeList: TreeList,
    Checkboxes: Checkboxes
  },
  data: function data() {
    return {
      loading: true,
      ModelTypes: ModelTypes,
      massDelete: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.CONTROLS, {
        label: 'controls',
        entities: [],
        checkboxOptions: [],
        model: {
          id: 'cpath',
          visibleId: 'cpath',
          type: ModelTypes.FLAT
        }
      }), MODULES.MOTHER_CONTROLS, {
        label: 'mother controls',
        entities: [],
        checkboxOptions: [],
        model: {
          id: 'cpath',
          visibleId: 'cpath',
          type: ModelTypes.FLAT
        }
      }), MODULES.EXECUTION, {
        label: 'actions',
        entities: [],
        checkboxOptions: [],
        model: {
          id: 'slug',
          visibleId: 'ipath',
          title: 'title',
          type: ModelTypes.TREE
        }
      }), MODULES.CONTRACTS, {
        label: 'contracts',
        entities: [],
        checkboxOptions: [],
        model: {
          id: 'id',
          visibleId: 'uniqueId',
          type: ModelTypes.FLAT
        }
      }),
      toggleObjects: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.CONTROLS, {}), MODULES.CONTRACTS, {}), MODULES.MOTHER_CONTROLS, {}), MODULES.EXECUTION, {}),
      deleteFunctions: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.CONTROLS, ControlsApi.deleteControl), MODULES.CONTRACTS, ContractsApi.deleteContract), MODULES.MOTHER_CONTROLS, ControlsApi.deleteControl), MODULES.EXECUTION, deleteAction),
      loadFunctions: _defineProperty({}, MODULES.EXECUTION, this.getActions),
      usersData: {
        deleteUserId: null,
        sendToId: null
      },
      showSection: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.CONTROLS, true), MODULES.CONTRACTS, true), MODULES.EXECUTION, false), "users", true),
      translate: getTranslate['DangerZone'](),
      treeEntitiesToDelete: _defineProperty({}, MODULES.EXECUTION, [])
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    allUsers: 'allUsers'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    flatEntitiesToDelete: function flatEntitiesToDelete() {
      var _this = this;
      return Object.keys(this.toggleObjects).reduce(function (acc, cur) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, cur, Object.keys(_this.toggleObjects[cur]).filter(function (key) {
          return _this.toggleObjects[cur][key];
        })));
      }, {});
    },
    deleteUserConfirmMessage: function deleteUserConfirmMessage() {
      return "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(this.deleteUserName, " ").concat(this.translate.permanently, "?");
    },
    deleteUserName: function deleteUserName() {
      var _this2 = this;
      if (!this.usersData.deleteUserId) {
        return '';
      }
      return this.allUsers.find(function (user) {
        return user.id === _this2.usersData.deleteUserId;
      }).display_name;
    },
    allUsersObject: function allUsersObject() {
      var _this3 = this;
      var list = this.allUsers.map(function (user) {
        return {
          value: user.id,
          label: user.display_name + (user.active ? '' : ' (inactive) ')
        };
      });
      return {
        deleteList: list.filter(function (user) {
          return user.value !== _this3.currentUser.id;
        }),
        sendToList: this.usersData.deleteUserId ? list.filter(function (user) {
          return user.value !== _this3.usersData.deleteUserId;
        }) : list
      };
    }
  }),
  mounted: function mounted() {
    var _this4 = this;
    Promise.all([this.getControls(), this.getContracts(), this.getMotherControls(), this.getActions()]).then(function () {
      return _this4.loading = false;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    getContracts: function getContracts() {
      var _this5 = this;
      return ContractsApi.getContractsReport().then(function (_ref) {
        var list = _ref.list;
        return _this5.initEntities(list, MODULES.CONTRACTS);
      });
    },
    getControls: function getControls() {
      var _this6 = this;
      return ControlsApi.getAll().then(function (_ref2) {
        var list = _ref2.list;
        return _this6.initEntities(list, MODULES.CONTROLS);
      });
    },
    getMotherControls: function getMotherControls() {
      var _this7 = this;
      return ControlsApi.getAllMotherControls().then(function (_ref3) {
        var list = _ref3.list;
        return _this7.initEntities(list, MODULES.MOTHER_CONTROLS);
      });
    },
    getActions: function getActions() {
      var _this8 = this;
      return getInitiatives().then(function (_ref4) {
        var children = _ref4.children;
        return _this8.initEntities(children, MODULES.EXECUTION, false);
      });
    },
    initEntities: function initEntities(itemsList, key) {
      var sortItems = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var modelId = this.massDelete[key].model.visibleId;
      var sortedItems = sortItems ? this.sortItems(itemsList, modelId) : itemsList;
      var checkboxOptions = this.getCheckboxOptions(sortedItems, this.massDelete[key].model);
      this.massDelete[key].entities = sortedItems;
      this.massDelete[key].checkboxOptions = checkboxOptions;
      this.setAllValuesTo(key);
    },
    deleteEntities: function deleteEntities(key) {
      var _this9 = this;
      var isTreeType = this.massDelete[key].model.type === ModelTypes.TREE;
      var arrayOfIds = isTreeType ? this.treeEntitiesToDelete[key] : this.flatEntitiesToDelete[key];
      var promises = arrayOfIds.map(function (id) {
        return _this9.deleteFunctions[key](id);
      });
      Promise.all(promises).then(function (response) {
        if (isTreeType) {
          _this9.loadFunctions[key]();
          _this9.treeEntitiesToDelete[key] = [];
          return;
        }
        var deletedIds = response.map(function (item) {
          return +item.id;
        });
        var entities = _this9.massDelete[key].entities.filter(function (item) {
          return !deletedIds.includes(+item.id);
        });
        _this9.initEntities(entities, key, false);
      }).catch(function (error) {
        _this9.$notify({
          title: "Server failed with this message: ".concat(error.message || error.error || error.response.error),
          type: 'error'
        });
      });
    },
    deleteUser: function deleteUser() {
      var _this10 = this;
      return _deleteUser(this.usersData.deleteUserId, this.usersData.sendToId).then(function () {
        cacheItem('users', function () {
          return _this10.loadUsers();
        }, 180);
        _this10.usersData = {
          deleteUserId: null,
          sendToId: null
        };
      }).catch(function (error) {
        return _this10.$notify({
          title: error.response.error,
          type: 'error'
        });
      });
    },
    copyRisks: function copyRisks() {
      var _this11 = this;
      return createRiskProject({
        fromRismaAdmin: true
      }).then(function (response) {
        _this11.$notify({
          title: "Server response: ".concat(response.message),
          type: 'success'
        });
      }).catch(function (response) {
        _this11.$notify({
          title: "Server failed with this message: ".concat(response.message),
          type: 'error'
        });
      });
    },
    resetControls: function resetControls() {
      var _this12 = this;
      return setControlsRecent({
        fromRismaAdmin: true
      }).then(function (response) {
        _this12.$notify({
          title: "Server response: ".concat(response.message),
          type: 'success'
        });
      }).catch(function (response) {
        _this12.$notify({
          title: "Server failed with this message: ".concat(response.message),
          type: 'error'
        });
      });
    },
    setAllValuesTo: function setAllValuesTo(key) {
      var _this13 = this;
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var modelType = this.massDelete[key].model.type;
      var checkboxOptions = this.massDelete[key].checkboxOptions;
      this.toggleObjects[key] = {};
      checkboxOptions.forEach(function (option) {
        return _this13.setToggleObject(key, option, value);
      });
      if (modelType === ModelTypes.TREE) {
        this.treeEntitiesToDelete[key] = value ? checkboxOptions.map(function (option) {
          return option.value;
        }) : [];
      }
      if (modelType === ModelTypes.FLAT) {
        this.massDelete[key].checkboxOptions = checkboxOptions.map(function (option) {
          return _objectSpread(_objectSpread({}, option), {}, {
            checked: value
          });
        });
      }
    },
    setToggleObject: function setToggleObject(key, option, value) {
      var _this14 = this;
      this.toggleObjects[key][option.value] = value;
      if (option.children && option.children.length) {
        option.children.forEach(function (item) {
          _this14.setToggleObject(key, item, value);
        });
      }
    },
    onTreeItemCheck: function onTreeItemCheck(key, checkboxOption, value) {
      var _this15 = this;
      var allSelectedIds = this.getAllTreeItemChildrenValues(checkboxOption);
      allSelectedIds.forEach(function (id) {
        return _this15.toggleObjects[key][id] = value;
      });
      this.treeEntitiesToDelete[key] = this.treeEntitiesToDelete[key].filter(function (id) {
        return !allSelectedIds.includes(id);
      });
      if (value) {
        this.treeEntitiesToDelete[key].push(checkboxOption.value);
      }
    },
    getCheckboxOptions: function getCheckboxOptions(entities, model) {
      var _this16 = this;
      return entities.map(function (entity) {
        var result = _this16.getCheckboxOption(entity, model);
        if (entity.children) {
          result.children = _this16.getCheckboxOptions(entity.children, model);
        }
        return result;
      });
    },
    getCheckboxOption: function getCheckboxOption(entity, _ref5) {
      var _entity$visibleId;
      var id = _ref5.id,
        visibleId = _ref5.visibleId;
      var checked = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return {
        value: entity[id],
        label: (_entity$visibleId = entity[visibleId]) !== null && _entity$visibleId !== void 0 && _entity$visibleId.trim() ? entity[visibleId] + '.' + entity.title : entity.title,
        checked: checked
      };
    },
    getAllTreeItemChildrenValues: function getAllTreeItemChildrenValues(root) {
      var _ref6,
        _this17 = this;
      return (_ref6 = [root.value]).concat.apply(_ref6, _toConsumableArray((root.children || []).map(function (child) {
        return _this17.getAllTreeItemChildrenValues(child);
      })));
    },
    sortItems: function sortItems(items, sortBy) {
      return _toConsumableArray(items).sort(function (a, b) {
        return comparePathStrings(a[sortBy], b[sortBy]);
      });
    }
  })
};