// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.axis-label[data-v-0f8f7d59] {
  text-align: center;
}
.vertical[data-v-0f8f7d59] {
  transform-origin: bottom left;
  transform: rotate(-90deg);
}
.horizontal[data-v-0f8f7d59] {
  position: relative;
  left: 116px;
  width: -moz-fit-content;
  width: fit-content;
}
.labeltd[data-v-0f8f7d59] {
  padding: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
