import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_wizard_flow = _resolveComponent("wizard-flow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_button, {
    text: $data.translate.createCompany,
    type: "save",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isOpened = true;
    })
  }, null, 8 /* PROPS */, ["text"]), $data.isOpened ? (_openBlock(), _createBlock(_component_wizard_flow, {
    key: 0,
    items: $options.items,
    data: $data.data,
    "has-welcome-message": !$setup.welcomePageState,
    "selected-options": $data.selectedOptions,
    "load-state": $data.loadState,
    "parent-error-message": '',
    "modal-title": $data.translate.companyWizard,
    onAccept: $options.handleAccept,
    onDismiss: $options.handleDismiss,
    "onUpdate:selected": $options.updateSelected,
    "onUpdate:error": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.errorMessage = '';
    })
  }, null, 8 /* PROPS */, ["items", "data", "has-welcome-message", "selected-options", "load-state", "modal-title", "onAccept", "onDismiss", "onUpdate:selected"])) : _createCommentVNode("v-if", true)]);
}