import { getTranslate } from './translate';
import { getLEICompanyByName } from '@/api/external/lei';
import StaticTable from '@/components/Molecules/StaticTable';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { CompanyWizardFields } from '@/constants/compliance/WizardFlow';
export default {
  name: 'CompanyWizardStepLEI',
  introduction: '',
  token: '<company-wizard-step-lei />',
  components: {
    StaticTable: StaticTable,
    RadioButtons: RadioButtons,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    }
  },
  emits: ['update:selected', 'noSearchResult'],
  data: function data() {
    return {
      lookupItems: [],
      selectedLeiIdx: null,
      chooseOption: [{
        value: true,
        label: ''
      }],
      loading: false,
      CompanyWizardFields: CompanyWizardFields,
      translate: getTranslate['CompanyWizardStepLEI']()
    };
  },
  computed: {
    dataset: function dataset() {
      var _this = this;
      return this.lookupItems.map(function (companyInfo, idx) {
        return {
          choose: {
            selected: _this.selectedLeiIdx === idx,
            idx: idx
          },
          companyName: companyInfo.name,
          lei: companyInfo.lei,
          countryCode: companyInfo.country
        };
      });
    },
    columns: function columns() {
      return [this.translate.choose, this.translate.title, this.translate.lei, this.translate.country];
    },
    companyName: function companyName() {
      if (this.selectedOptions[CompanyWizardFields.IS_VAT_CREATION_MODE]) {
        var companyIdx = this.selectedOptions[CompanyWizardFields.SELECTED_COMPANY_IDX];
        var company = this.selectedOptions[CompanyWizardFields.FOUND_COMPANIES][companyIdx];
        return company.name;
      } else {
        return this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT].name;
      }
    }
  },
  mounted: function mounted() {
    this.getLeiOptionsByCompanyName();
  },
  methods: {
    getLeiOptionsByCompanyName: function getLeiOptionsByCompanyName() {
      var _this$selectedOptions,
        _this2 = this;
      if (this.companyName !== ((_this$selectedOptions = this.selectedOptions[CompanyWizardFields.FOUND_LEI]) === null || _this$selectedOptions === void 0 ? void 0 : _this$selectedOptions.usedCompanyName)) {
        this.loading = true;
        return getLEICompanyByName(this.companyName).then(function (_ref) {
          var list = _ref.list;
          _this2.lookupItems = list;
          _this2.$emit('update:selected', {
            value: {
              usedCompanyName: _this2.companyName,
              leiList: _this2.lookupItems.slice(0)
            },
            field: CompanyWizardFields.FOUND_LEI
          });
          _this2.$emit('update:selected', {
            value: null,
            field: CompanyWizardFields.LEI
          });
        }).finally(function () {
          return _this2.loading = false;
        });
      } else {
        this.lookupItems = this.selectedOptions[CompanyWizardFields.FOUND_LEI].leiList;
        this.findSelectedLei();
      }
    },
    findSelectedLei: function findSelectedLei() {
      var _this3 = this;
      if (!this.selectedOptions[CompanyWizardFields.LEI]) return;
      this.selectedLeiIdx = this.lookupItems.findIndex(function (item) {
        return item.lei === _this3.selectedOptions[CompanyWizardFields.LEI];
      });
    },
    handleChange: function handleChange(updatedValue, idx) {
      this.selectedLeiIdx = updatedValue.value ? idx : null;
      this.$emit('update:selected', {
        value: this.selectedLeiIdx !== null ? this.lookupItems[this.selectedLeiIdx].lei : null,
        field: CompanyWizardFields.LEI
      });
    }
  }
};