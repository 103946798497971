export default {
  name: 'AiAssistantIcon',
  props: {
    fillColor: {
      type: String,
      required: false,
      default: '#fff'
    },
    titleAttrEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};