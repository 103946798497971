import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex mb-4"
};
var _hoisted_2 = {
  class: "mr-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_questionnaire = _resolveComponent("questionnaire");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    class: "mb-8",
    type: "big",
    title: "RISMA Systems"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.rismaFirstTitle,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.whenYouAreFinishedFillingOutTheQuestionnair,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    truncate: false,
    title: $data.translate.yourAnswersAreAutomaticallySavedAfterAFewS,
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), $props.comment ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    truncate: false,
    title: $props.comment,
    type: "small",
    class: "mt-4 italic"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)]), !$data.questionnaireSubmitted ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.submit,
    type: "save",
    class: "mt-auto ml-auto",
    onClick: $options.onPreSubmit
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_main_well, {
    key: 1
  }, {
    default: _withCtx(function () {
      return [$options.questions && $options.questions.length && $data.informationAssets !== null && !$data.questionnaireSubmitted ? (_openBlock(), _createBlock(_component_questionnaire, {
        key: 0,
        questionnaire: $props.questionnaire,
        questions: $options.questions,
        "local-storage-key": "dpm_audit_".concat($props.activity.id, "_questionnaire").concat($props.questionnaire.id),
        "information-assets": $data.informationAssets,
        "node-element": $data.nodeElement,
        title: $options.defaultTitle || '',
        onUpdate: $options.prepareAndUpdateQuestion
      }, null, 8 /* PROPS */, ["questionnaire", "questions", "local-storage-key", "information-assets", "node-element", "title", "onUpdate"])) : _createCommentVNode("v-if", true), $data.questionnaireSubmitted ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 1,
        title: $data.translate.questionnaireSubmitted,
        type: "medium",
        class: "px-3 py-5 text-center"
      }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]);
}