import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "break-words flex flex-wrap"
};
var _hoisted_2 = {
  class: "text-neutral-500 mr-2"
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($data.translate.showLevels) + " :", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.generatedLevels, function (item) {
    return _openBlock(), _createElementBlock("span", {
      key: "max_level_".concat(item.value),
      class: _normalizeClass(["cursor-pointer border rounded-full justify-center inline-flex items-center align-middle mr-1 min-w-28px min-h-[28px]", item.value === $props.modelValue ? 'text-blue-750 border-blue-750 before:content-[\'\'] before:pb-1/1' : 'text-neutral-500 border-transparent']),
      onClick: function onClick($event) {
        return _ctx.$emit('update:modelValue', item.value);
      }
    }, _toDisplayString(item.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */))]);
}