import { getDataflowStakeholdersTitle, getDataflowThirdPartiesTitle } from '@/utils/TitleFromSetting';
var DataFlowTypes = function DataFlowTypes($trans) {
  return [{
    key: 'dataFlowStakeholder',
    title: getDataflowStakeholdersTitle()
  }, {
    key: 'dataFlowDataType',
    title: $trans('Data type')
  }, {
    key: 'dataFlowSystem',
    title: getDataflowThirdPartiesTitle()
  }];
};
export default DataFlowTypes;