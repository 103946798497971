import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_single_select = _resolveComponent("single-select");
  var _component_ai_terms_of_service_modal = _resolveComponent("ai-terms-of-service-modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("p", _hoisted_1, _toDisplayString($data.translate.introductionText), 1 /* TEXT */), _createVNode(_component_checkboxes, {
    modelValue: $data.isAiEnabled,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.isAiEnabled = $event;
    }),
    options: $data.checkboxOptions,
    class: "mb-8 -ml-2",
    onUpdated: $options.handleAiAccess
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createVNode(_component_single_select, {
    modelValue: $data.selectedRegion,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.selectedRegion = $event;
    }), $options.handleChangeRegion],
    title: $data.translate.regionTitle,
    disabled: !_ctx.aiTermsAccepted,
    options: $options.regionOptions,
    class: "max-w-300px"
  }, null, 8 /* PROPS */, ["modelValue", "title", "disabled", "options", "onUpdate:modelValue"]), $data.showTermsOfServices ? (_openBlock(), _createBlock(_component_ai_terms_of_service_modal, {
    key: 0,
    onAccept: $options.acceptTermsOfServices,
    onDismiss: $options.dismissTermsOfServices
  }, null, 8 /* PROPS */, ["onAccept", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}