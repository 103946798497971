// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compact-layout[data-v-47e190cc]  .static-table {
  table-layout: fixed;
}
.compact-layout[data-v-47e190cc]  .static-table th:last-child {
  width: 50px;
}
.tiles-wrapper[data-v-47e190cc] {
  grid-auto-rows: 1fr; /*tailwind is supported (they said), but its not working even with config*/
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
