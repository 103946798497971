function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { getTranslate } from '@/components/Pages/Admin/translate';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
export default {
  name: 'QuestionsAdminWrapper',
  components: {
    RismaTitle: RismaTitle,
    LightTabItem: LightTabItem,
    RismaButton: RismaButton
  },
  props: {
    questionnaireId: {
      type: [String, Number],
      default: null,
      note: 'questionnaire Id'
    },
    questionnaireTypeId: {
      type: [String, Number],
      default: null,
      note: 'default value for questionnaireType'
    }
  },
  data: function data() {
    return {
      allQuestionnaires: [],
      translate: getTranslate['QuestionsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    questionnaire: function questionnaire() {
      var _this = this;
      return this.allQuestionnaires.find(function (questionnaire) {
        return +questionnaire.id === +_this.questionnaireId;
      });
    },
    hasDataFlow: function hasDataFlow() {
      return this.getSettingValue('feature.admin.dataflow_overview_enabled') && +this.questionnaireTypeId === ProjectTypes.GDPR;
    }
  }),
  mounted: function mounted() {
    this.getQuestionnaires();
  },
  methods: {
    backToPrevPage: function backToPrevPage() {
      var moduleUrl = this.getUrlByQuestionnaireTypeId(this.questionnaireTypeId);
      this.$router.push("/settings/".concat(moduleUrl, "?questionnaireType=").concat(this.questionnaireTypeId));
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this2 = this;
      return getAllQuestionnaires().then(function (_ref) {
        var list = _ref.list;
        return _this2.allQuestionnaires = list;
      });
    },
    getUrlByQuestionnaireTypeId: function getUrlByQuestionnaireTypeId(id) {
      switch (+id) {
        case QuestionnaireTypes.INCIDENT:
          return 'incidentquestionnaires';
        case QuestionnaireTypes.RISK:
          return 'riskquestionnaires';
        case QuestionnaireTypes.CONTRACT:
          return 'contractquestionnaires';
        default:
          return 'compliancequestionnaires';
      }
    }
  }
};