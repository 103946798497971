// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-wrapper input[data-v-baec7dfc] {
  -webkit-appearance: none;
  border-radius: 3px;
  border: 1px solid #807c79;
  background: white;
}
.checkbox-wrapper input[data-v-baec7dfc]:checked {
  background-color: #2182fd;
  border: 1px solid #2182fd;
}
.checkbox-wrapper input:checked + .check-icon[data-v-baec7dfc] {
  opacity: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
