function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import i18n from '@/i18n';
import { purifyItemField } from '@/utils/Utils';
import { isHexColor } from '@/utils/regex';
import { capitalize } from '@/utils';
import { useTrafficLights } from '@/Store/trafficLights';

/**
 * Generate validated data that is ready to be exported
 * @param dataset Dataset from RismaDatatable component
 * @param columnsToExport Set of columns that should be exported
 * @param isGap determins if gap is the initiator, necessary for traffic light fields
 */
export var prepareDatasetToExport = function prepareDatasetToExport(dataset) {
  var columnsToExport = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var isGap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var result = [];
  dataset.forEach(function (item) {
    var row = {};
    for (var key in columnsToExport) {
      if (item[key] !== undefined) {
        var field = item[key].field;
        var isTrafficLight = field && typeof field === 'string' && (field.indexOf('class="traffic-light"') !== -1 || field.indexOf('trafficlight') !== -1);
        isTrafficLight = key === 'trafficLight' || isTrafficLight || field && _typeof(field) === 'object' && field.color;
        if (isTrafficLight) {
          var _item$key;
          var value = purifyTrafficLightField(item[key], isGap);
          row[key] = (_item$key = item[key]) !== null && _item$key !== void 0 && (_item$key = _item$key.field) !== null && _item$key !== void 0 && _item$key.trafficLightSuffix ? value + item[key].field.trafficLightSuffix : value;
          continue;
        }
        row[key] = purifyItemField(item[key], false, false);
      }
    }
    result.push(row);
  });
  return result;
};
function purifyTrafficLightField(item, isGap) {
  var _item$field3;
  var trafficLightsStore = useTrafficLights();
  if (isGap) {
    var _item$field, _item$field2;
    var label = (_item$field = item.field) === null || _item$field === void 0 ? void 0 : _item$field.label;
    return label ? i18n.t(label) : i18n.t(trafficLightsStore.getGapLegend((_item$field2 = item.field) === null || _item$field2 === void 0 ? void 0 : _item$field2.value));
  }
  if (item.fieldText && !isHexColor(item.fieldText)) return i18n.t(item.fieldText);
  var value = (_item$field3 = item.field) === null || _item$field3 === void 0 ? void 0 : _item$field3.color;
  if (!value) return '';
  if (isHexColor(value)) {
    return i18n.t(capitalize(trafficLightsStore.getTrafficLabelForModuleByColor(null, value)));
  }
  if (!isNaN(+value)) {
    return i18n.t(capitalize(trafficLightsStore.getTrafficLightLegendForModule(null, value)));
  }
  return '';
}