function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import StaticTable from '@/components/Molecules/StaticTable';
import GapTypesList from '@/components/Pages/Admin/GapTypesList';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import InfoToolTip from '@/components/Molecules/InfoToolTip';
import { InfoToolTipMixin } from '@/mixins/InfoToolTipMixin';
import { getAllGapSchemas, updateGapSchema, copyGapSchema, deleteGapSchema } from '@/api/compliance/gapschema';
import { getAll as getAllComplianceProjects } from '@/api/compliance/projects';
import { getAvailableGapSchemasTypes as _getGapSchemasTypes } from '@/utils/gapSchemas';
import { getAllQuestionnaireTemplates } from '@/api/compliance/questionnaire';
import TemplateList from '@/components/Pages/Admin/TemplateList';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'GapSchemasAdmin',
  introduction: 'Gap Schema Admin',
  description: 'This component renders the table with gaps schema list',
  token: '<gap-schemas-admin></gap-schemas-admin>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    TemplateList: TemplateList,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    GapTypesList: GapTypesList,
    Modal: Modal,
    InputField: InputField,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Checkboxes: Checkboxes,
    InfoToolTip: InfoToolTip
  },
  mixins: [InfoToolTipMixin],
  data: function data() {
    return {
      loading: false,
      gapSchemaType: null,
      projectIdBySelectedType: null,
      allGapSchemaTypes: [],
      projects: [],
      allGapSchema: [],
      templates: [],
      eventFields: ['title', 'copy', 'delete'],
      translate: getTranslate['GapSchemasAdmin'](),
      showTitleModal: false,
      newTitle: '',
      bufferFunction: null,
      currentToolTipData: null,
      selectedActivity: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    csrdProjectOptions: function csrdProjectOptions() {
      return this.projects.filter(function (project) {
        return project.type === ProjectTypes.CSRD;
      }).map(function (project) {
        return {
          label: project.title,
          value: project.id
        };
      });
    },
    isCSRD: function isCSRD() {
      return this.gapSchemaType === ProjectTypes.CSRD;
    },
    columns: function columns() {
      var columns = [this.translate.name, this.translate.gapCount, this.translate.processCount, this.translate.activated, this.translate.copy, this.translate.delete];
      if (this.getSettingValue('feature.gap_schemas_article_editor')) {
        columns.splice(4, 0, this.translate.edit);
      }
      return columns;
    },
    formattedData: function formattedData() {
      var _this = this;
      var format = [];
      this.gapSchemas.forEach(function (gapSchema, index) {
        var obj = {
          title: {
            disableEvent: gapSchema.user_id === null,
            dataId: gapSchema.id,
            dataMethod: 'title',
            param2: 'title',
            param3: false,
            param4: gapSchema.id,
            param5: gapSchema.title,
            text: gapSchema.title,
            removeIcon: true
          },
          questionCount: gapSchema.gapCount,
          usage: {
            count: gapSchema.nodeUsageCount,
            nodes: gapSchema.nodes,
            index: index
          },
          enabled: {
            isChecked: gapSchema.selectable == 1,
            isDisabled: gapSchema.nodeUsageCount > 0,
            dataId: gapSchema.id,
            param3: !gapSchema.selectable,
            param4: gapSchema.id,
            param5: gapSchema.title
          }
        };
        if (_this.getSettingValue('feature.gap_schemas_article_editor')) {
          obj.edit = {
            url: "/compliance/admin/gapschemas/".concat(_this.gapSchemaType, "/").concat(gapSchema.id, "/articles")
          };
        }
        obj.copy = {
          dataId: gapSchema.id,
          dataMethod: 'copy',
          param2: 'copy',
          param3: false,
          param4: gapSchema.id,
          param5: gapSchema.title,
          icon: 'copy',
          text: ''
        };
        obj.delete = {
          disableEvent: gapSchema.nodeUsageCount > 0,
          dataId: gapSchema.id,
          dataMethod: 'delete',
          param2: 'delete',
          param3: false,
          param4: gapSchema.id,
          param5: null,
          icon: 'trash',
          text: ''
        };
        obj.download = {
          url: "/api/2.0/compliance/gapschema/".concat(gapSchema.id)
        };
        format.push(obj);
      });
      return format;
    },
    gapSchemaTypes: function gapSchemaTypes() {
      var _this2 = this;
      var gapSchemaIds = this.allGapSchema.map(function (gapSchema) {
        return gapSchema.gapSchemaType;
      });
      var templateTypeIds = this.templates.map(function (template) {
        return template.type;
      });
      var allTypeIds = [].concat(_toConsumableArray(gapSchemaIds), _toConsumableArray(templateTypeIds));
      return this.allGapSchemaTypes.filter(function (type) {
        return allTypeIds.indexOf(type.id) !== -1;
      }).map(function (gapSchema) {
        return {
          label: _this2.translateGapSchemaType(gapSchema.type),
          value: gapSchema.id
        };
      });
    },
    gapSchemas: function gapSchemas() {
      var _this3 = this;
      return this.allGapSchema.filter(function (schema) {
        return schema.gapSchemaType == _this3.gapSchemaType;
      });
    },
    projectBySelectedType: function projectBySelectedType() {
      var _this4 = this;
      if (!this.projectIdBySelectedType) return null;
      return this.projects.find(function (project) {
        return project.id === _this4.projectIdBySelectedType;
      });
    },
    showGapArticleTypesForSelectedProject: function showGapArticleTypesForSelectedProject() {
      return !!(this.projectBySelectedType && this.projectBySelectedType.showGapArticleTypes);
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'gap');
    }
  }),
  watch: {
    gapSchemaType: function gapSchemaType(_gapSchemaType) {
      this.projectIdBySelectedType = _gapSchemaType ? this.projects.find(function (project) {
        return project.type === _gapSchemaType;
      }).id : null;
      this.$router.push({
        query: {
          gapSchemaType: _gapSchemaType
        }
      });
    }
  },
  beforeMount: function beforeMount() {
    var _this5 = this;
    this.loading = true;
    this.resetToolTip();
    Promise.all([this.getQuestionnaireTemplates(), this.getGapSchemasTypes(), this.getGapSchemas(), this.getComplianceProjects()]).then(function () {
      _this5.gapSchemaType = _this5.$route.query && _this5.$route.query.gapSchemaType ? +_this5.$route.query.gapSchemaType : 0;
      _this5.loading = false;
    });
  },
  methods: {
    handleEvents: function handleEvents(eventValue) {
      var _this6 = this;
      var _eventValue = _slicedToArray(eventValue, 4),
        action = _eventValue[0],
        selectable = _eventValue[1],
        gapSchemaId = _eventValue[2],
        currentTitle = _eventValue[3];
      this.newTitle = currentTitle;
      switch (action) {
        case 'enabled':
          updateGapSchema(gapSchemaId, {
            selectable: selectable
          }).then(this.getGapSchemas);
          return;
        case 'delete':
          this.bufferFunction = function () {
            return deleteGapSchema(gapSchemaId).then(_this6.getGapSchemas).catch(function (e) {
              var errorMessage = e.response.error || e.response;
              _this6.$notify({
                title: errorMessage,
                type: 'error'
              });
            });
          };
          this.$confirm(this.translate.deleteGapSchema, this.translate.areYouSureYouWantToDeleteThisGapSchema, function (res) {
            return res && _this6.doConfirm();
          });
          return;
        case 'copy':
          this.bufferFunction = function (title) {
            return copyGapSchema(gapSchemaId, title).then(_this6.getGapSchemas);
          };
          this.showTitleModal = true;
          return;
        case 'title':
          this.bufferFunction = function (title) {
            return updateGapSchema(gapSchemaId, {
              title: title
            }).then(_this6.getGapSchemas);
          };
          this.showTitleModal = true;
          return;
      }
    },
    doConfirm: function doConfirm() {
      this.bufferFunction(this.newTitle);
      this.closePopups();
    },
    closePopups: function closePopups() {
      this.newTitle = '';
      this.showTitleModal = false;
      this.bufferFunction = null;
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this7 = this;
      return getAllComplianceProjects().then(function (_ref) {
        var list = _ref.list;
        _this7.projects = list;
      });
    },
    getGapSchemasTypes: function getGapSchemasTypes() {
      var _this8 = this;
      return _getGapSchemasTypes().then(function (_ref2) {
        var list = _ref2.list;
        _this8.allGapSchemaTypes = list;
      });
    },
    getGapSchemas: function getGapSchemas() {
      var _this9 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref3) {
        var list = _ref3.list;
        _this9.allGapSchema = list;
      });
    },
    getQuestionnaireTemplates: function getQuestionnaireTemplates() {
      var _this10 = this;
      return getAllQuestionnaireTemplates('gap').then(function (_ref4) {
        var list = _ref4.list;
        _this10.templates = list;
      });
    },
    translateGapSchemaType: function translateGapSchemaType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    }
  }
};