function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { DateFormats } from '@/constants/DateFormats';
import { getFortnightDateRange } from '@/utils/date';
import { generateKey } from '@/utils/Utils';
import { getAccessReportChangelog } from '@/api/admin/reports';
export default {
  name: 'AdminAccessChangelog',
  components: {
    MainWell: MainWell,
    RismaButton: RismaButton,
    RismaDateRangePicker: RismaDateRangePicker,
    SingleSelect: SingleSelect,
    RismaTable: RismaTable
  },
  data: function data() {
    return {
      selectedUser: null,
      dateRange: getFortnightDateRange(DateFormats.DATE_TIME_FORMAT_FULL),
      showReport: false,
      DateFormats: DateFormats,
      tableGeneratedKey: generateKey(),
      translate: getTranslate['AdminAccessChangelog']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    allUsers: 'activeUsers'
  })), {}, {
    optionUsers: function optionUsers() {
      return this.allUsers.map(function (user, index) {
        return {
          value: index + '',
          label: user.username
        };
      });
    }
  }),
  methods: {
    getAccessChangelogAPI: function getAccessChangelogAPI(params) {
      return getAccessReportChangelog(this.getFilters(), params);
    },
    changedDates: function changedDates(e) {
      if (e) {
        this.dateRange.startDate = e.startDate;
        this.dateRange.endDate = e.endDate;
      }
    },
    createReport: function createReport() {
      var _this = this;
      this.showReport = true;
      this.$nextTick(function () {
        return _this.$refs.tableData.getData();
      });
    },
    getFilters: function getFilters() {
      var filters = {};
      filters['startDate'] = this.dateRange.startDate;
      filters['endDate'] = this.dateRange.endDate;
      if (this.selectedUser !== null) {
        filters['username'] = this.allUsers[this.selectedUser].username;
      }
      return {
        filters: filters
      };
    }
  }
};