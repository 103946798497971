// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risma-circle:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  top: -6px;
  left: -6px;
  padding: 4px;
  border: 2px solid;
  border-color: inherit;
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
  box-sizing: content-box;
}
.risma-circle.active:after,
.risma-circle:hover:after {
  transform: scale(1);
  opacity: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
