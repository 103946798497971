import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_cache[0] || (_cache[0] = _createElementVNode("h2", null, "Horizontal bar chart", -1 /* HOISTED */)), _createVNode(_component_risma_bar_chart, {
    labels: $data.horizontalBarData.labels,
    datasets: $data.horizontalBarData.datasets
  }, null, 8 /* PROPS */, ["labels", "datasets"])]), _createElementVNode("div", null, [_cache[1] || (_cache[1] = _createElementVNode("h2", null, "Stacked bar chart", -1 /* HOISTED */)), _createVNode(_component_risma_bar_chart, {
    labels: $data.stackedBarData.labels,
    datasets: $data.stackedBarData.datasets,
    "show-legend": true,
    "show-data-labels": true,
    type: "Stacked"
  }, null, 8 /* PROPS */, ["labels", "datasets"])])]);
}