import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  class: "w-full"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tag_creation_modal = _resolveComponent("tag-creation-modal");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: _ctx.$trans("Tags")
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.toggleCreateModal
      }, null, 8 /* PROPS */, ["onClick"])]), _createElementVNode("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
        class: _normalizeClass($data.tableCellClasses)
      }, _toDisplayString($data.translate.title), 3 /* TEXT, CLASS */), _createElementVNode("th", {
        class: _normalizeClass($data.tableCellClasses),
        width: "20"
      }, null, 2 /* CLASS */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, function (item) {
        return _openBlock(), _createElementBlock("tr", {
          key: item.id
        }, [_createElementVNode("td", {
          class: _normalizeClass($data.tableCellClasses)
        }, [_createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.toggleUpdateSelectedModal(item.id);
          }, ["prevent"])
        }, _toDisplayString(item.tag), 9 /* TEXT, PROPS */, _hoisted_3)], 2 /* CLASS */), _createElementVNode("td", {
          class: _normalizeClass($data.tableCellClasses)
        }, [_createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.handleDeletionNotification(item.id);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "stroke-2 text-red-50"
        })], 8 /* PROPS */, _hoisted_4)], 2 /* CLASS */)]);
      }), 128 /* KEYED_FRAGMENT */))])]), $data.showCreateModal ? (_openBlock(), _createBlock(_component_tag_creation_modal, {
        key: 0,
        tags: _ctx.tags,
        onCreate: $options.onCreateTag,
        onDismiss: $options.toggleModal
      }, null, 8 /* PROPS */, ["tags", "onCreate", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showModifyModal ? (_openBlock(), _createBlock(_component_tag_creation_modal, {
        key: 1,
        tags: _ctx.tags,
        "current-tag-id": +$data.selectedItemTag,
        onUpdate: $options.onModifyTag,
        onDismiss: $options.toggleModal
      }, null, 8 /* PROPS */, ["tags", "current-tag-id", "onUpdate", "onDismiss"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}