function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaProgressBar from '@/components/Atoms/ProgressBar/RismaProgressBar';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { TileCardMixin } from '@/mixins/TileCardMixin';
import { ObjectTypes } from '@/constants/ObjectTypes';
import * as formatDate from '@/utils/date';
import { getReportTypeByURL, getReportSubTypeByObjType } from '@/utils/report';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getUsersFromIds } from '@/utils/format/objectsFromIds';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
import { viewTypes as InitiativeViews } from '@/constants/ListViewType';
import { Colors } from '@/Colors';
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { truncateHtml } from '@/utils/Utils';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'TileCard',
  introduction: 'Tile view of an activity',
  description: 'Shows a shadowed box of one activity with description, e.g. a control',
  token: '<tile-card :activity="activity"></tile-card>',
  components: {
    FeatherIcon: FeatherIcon,
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    RismaProgressBar: RismaProgressBar,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [TileCardMixin],
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Activity object'
    },
    watermarkImage: {
      type: String,
      required: false,
      default: 'none',
      note: 'Url of background watermark image'
    },
    disablePreview: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if it has PreviewModalLink'
    },
    internalLinkEnabled: {
      required: false,
      type: Boolean,
      default: false,
      note: 'If it has link as title. Has lower than PreviewModalLink priority'
    },
    isApprovalFlowEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    activityStatus: {
      type: String,
      required: false,
      default: '',
      note: 'Determine status for activity'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of all activity types'
    }
  },
  emits: ['onCompleteControl', 'updateFromPreviewMode', 'onDismissModal', 'deleteItem', 'reloadRootPage'],
  data: function data() {
    return {
      Colors: Colors,
      InitiativeViews: InitiativeViews,
      objectTypes: ObjectTypes,
      translate: getTranslate['TileCard']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    title: function title() {
      if (this.activity.displayHeadline) {
        return this.activity.displayHeadline;
      }
      if (this.activity.title === undefined) {
        return this.activity.request.title;
      }
      return (this.activityIdTitle ? this.activityIdTitle + ' ' : '') + this.activity.title;
    },
    hoverTitle: function hoverTitle() {
      if (this.activity.confidential) {
        return DatatableRenderer.getItemHoverTitle(this.activity);
      }
      return this.title;
    },
    activityUrl: function activityUrl() {
      if (this.activity.request) {
        return '/' + this.activity.url.split('/').slice(3).join('/');
      }
      return this.activity.url || this.activity.reportUrl;
    },
    showStatus: function showStatus() {
      return !this.isRisk || checkERMByProjectId(this.activity.projectId, this.currentUser);
    },
    createdWithLabel: function createdWithLabel() {
      return "".concat(this.translate.created, ": ").concat(formatDate.toLocalDateTime(this.activity.createdAt));
    },
    occurredWithLabel: function occurredWithLabel() {
      return "".concat(this.translate.incidentOccurred, ": ").concat(formatDate.toLocalDateTime(this.activity.incidentHappenedAt) || this.translate.notSet);
    },
    accountableWithLabel: function accountableWithLabel() {
      var users = getUsersFromIds(this.activity.accountableUserIds, this.users);
      if (!users.length) return '';
      return "".concat(this.translate.accountable, ": ").concat(users.map(function (user) {
        return user.display_name;
      }).join(', '));
    },
    archivedWithLabel: function archivedWithLabel() {
      if (!this.activity.archived) return null;
      return "".concat(this.translate.incidentArchived, ": ").concat(formatDate.toLocalDateTime(this.activity.updatedAt));
    },
    expirationDateWithLabel: function expirationDateWithLabel() {
      var endDateTime = this.translate.notSet;
      if (this.activity.endDate) {
        endDateTime = formatDate.formatTimeToDateFormat(this.activity.endDate);
      }
      return "".concat(this.translate.expirationDate, ": ").concat(endDateTime);
    },
    isControl: function isControl() {
      return this.activity.objType === ObjectTypes.CONTROLS_CONTROL || this.activity.objType === ObjectTypes.CONTROLS_CONTROL_LIMITED;
    },
    isIncident: function isIncident() {
      return this.activity.objType === ObjectTypes.INCIDENTS_INCIDENT;
    },
    isContract: function isContract() {
      return this.activity.objType === ObjectTypes.CONTRACT;
    },
    isRisk: function isRisk() {
      return this.activity.objType === ObjectTypes.RISK_RISK || this.activity.objType === ObjectTypes.RISK_RISK_LIMITED;
    },
    isAction: function isAction() {
      return this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE || this.activity.objType === ObjectTypes.EXECUTION_INITIATIVE_LIMITED;
    },
    reportType: function reportType() {
      if (!this.activity.reportUrl) return '';
      var reportType = getReportTypeByURL(this.activity.reportUrl);
      var subTitle = getReportSubTypeByObjType(this.activity.objType || '');
      if (reportType === 'processlibrary') {
        return "RISMA".concat(getProcessLibraryTitle());
      }
      return "RISMA".concat(reportType).concat(subTitle ? ' - ' + this.$trans(subTitle) : '');
    },
    status: function status() {
      return this.activity.status || this.activityStatus;
    },
    statusTruncated: function statusTruncated() {
      return truncateHtml(this.status, 40, {
        html: true,
        maxLines: 3
      });
    },
    assetType: function assetType() {
      return ComplianceControllers[this.activity.objType];
    },
    projectTitle: function projectTitle() {
      if (!this.activity.project) return '';
      return this.activity.project.title == 'ERM' ? 'Enterprise risk management' : this.activity.project.title;
    },
    activityTitleLineThrough: function activityTitleLineThrough() {
      return this.isControl && !this.activity.active || this.isRisk && this.activity.closed;
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (at) {
        return +at.id === +_this.activity.activityType;
      });
    },
    descriptionTruncated: function descriptionTruncated() {
      return truncateHtml(this.activity.description, 40, {
        html: true,
        maxLines: 3
      });
    },
    questionsCountLabel: function questionsCountLabel() {
      return "".concat(this.activity.values.questions_answered, " / ").concat(this.activity.values.questions_count, " ").concat(this.translate.questionsAnswered);
    }
  }),
  methods: {
    showOptionalField: function showOptionalField(field) {
      var _this$currentActivity;
      if (!this.isAction || !this.currentActivityType) return true;
      return (_this$currentActivity = this.currentActivityType.optionalFields) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.find(function (item) {
        return item === field;
      });
    }
  }
};