import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "table-fixed w-full"
};
var _hoisted_2 = {
  class: "min-w-150px text-left w-40"
};
var _hoisted_3 = {
  class: "min-w-300px text-left"
};
var _hoisted_4 = {
  key: 1,
  class: "add-new-dependency"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.questionDependency,
    type: "medium",
    class: "mb-1"
  }, null, 8 /* PROPS */, ["title"]), $data.dependencies.length ? (_openBlock(), _createElementBlock("table", _hoisted_1, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.questions,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.answerOptions,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])]), _cache[1] || (_cache[1] = _createElementVNode("th", {
    class: "w-8"
  }, " ", -1 /* HOISTED */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dependencies, function (item) {
    return _openBlock(), _createElementBlock("tr", {
      key: item.key
    }, [_createElementVNode("td", null, [_createVNode(_component_single_select, {
      "model-value": item.key,
      options: $options.questionsSelect.filter(function (question) {
        return !$options.selectedDependencyKeys.includes(question.value) || item.key === question.value;
      }),
      "un-sorted": true,
      disabled: item.isDisabled,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onQuestionChanged(item, $event);
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "onUpdate:modelValue"])]), _createElementVNode("td", null, [_createVNode(_component_risma_select, {
      options: $options.prepareOptions(item.key),
      "selected-options": $options.prepareSelectedOptions(item.key, item.values),
      "un-sorted": true,
      "track-by": "key",
      "label-key": "title",
      onSelected: function onSelected($event) {
        return $options.onSelectAnswers($event, item.key);
      }
    }, null, 8 /* PROPS */, ["options", "selected-options", "onSelected"])]), _createElementVNode("td", null, [_createVNode(_component_feather_icon, {
      icon: "x",
      class: "stroke-2 cursor-pointer text-red-50",
      onClick: _withModifiers(function ($event) {
        return $options.deleteDependency(item);
      }, ["prevent"])
    }, null, 8 /* PROPS */, ["onClick"])])]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), $options.hasOptions ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("a", {
    href: "#",
    class: "add-option",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.addOption && $options.addOption.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "inline-block stroke-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addNewDependency), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]);
}