import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    "show-dismiss-button": false,
    header: $options.header,
    "button-ok-text": $props.currentTagId ? $data.translate.save : $data.translate.create,
    "dismiss-on-click-outside": false,
    onAccept: $options.handleAccept,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.modalErrorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: $options.resetErrors
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.modalErrorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.updatedTagName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.updatedTagName = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true,
        invalid: !!$data.titleError,
        "error-help-text": $data.titleError,
        maxlength: 75,
        type: "text",
        onUpdated: $options.resetErrors
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid", "error-help-text", "onUpdated"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "onAccept"]);
}