// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-3f184687]  .browser-default table tr,[data-v-3f184687]  .browser-default table td {
  border: 1px solid #000;
}
[data-v-3f184687]  .browser-default ol,[data-v-3f184687]  .browser-default ul {
  padding-left: 4rem;
}
[data-v-3f184687]  .browser-default ol li,[data-v-3f184687]  .browser-default ul li {
  padding-left: 0.3em;
}
[data-v-3f184687]  .browser-default ol {
  list-style-type: decimal;
}
[data-v-3f184687]  .browser-default ul {
  list-style-type: disc;
}
[data-v-3f184687]  .browser-default ul ul {
  list-style-type: circle;
}
[data-v-3f184687]  .browser-default h1,[data-v-3f184687]  .browser-default h2,[data-v-3f184687]  .browser-default h3,[data-v-3f184687]  .browser-default h4,[data-v-3f184687]  .browser-default h5,[data-v-3f184687]  .browser-default h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
[data-v-3f184687]  .browser-default h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
[data-v-3f184687]  .browser-default h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
[data-v-3f184687]  .browser-default h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
[data-v-3f184687]  .browser-default h4 {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
[data-v-3f184687]  .browser-default h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
[data-v-3f184687]  .browser-default h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
[data-v-3f184687]  .browser-default a {
  text-decoration: underline;
  cursor: pointer;
}
[data-v-3f184687]  .browser-default blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}
.risma-table-wrapper[data-v-3f184687] {
  min-height: 650px;
}
.resizer[data-v-3f184687] {
  width: 6px;
  cursor: col-resize;
}
table[data-v-3f184687] {
  border-spacing: 0;
}
thead[data-v-3f184687] {
  box-shadow: 0 1px 0 0 rgb(221, 221, 221);
}
.bg-cultured[data-v-3f184687],
tr[data-v-3f184687]:nth-child(n+1) {
  background-color: inherit;
}
.bg-cultured[data-v-3f184687],
tr[data-v-3f184687]:nth-child(2n+1) {
  background-color: #eff0f3;
}
td[data-v-3f184687]:last-child,
th[data-v-3f184687]:last-child {
  border-right: none;
}
td[data-v-3f184687]:first-child,
th[data-v-3f184687]:first-child {
  border-left: none;
}
.th-sorted[data-v-3f184687],
.th-selected[data-v-3f184687] {
  background-color: #cbccd0;
}
.search-field[data-v-3f184687]  {
  width: 230px;
}
.search-field[data-v-3f184687]  input {
  border-width: 1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
