import { watch } from 'vue';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  components: {
    LightTabItem: LightTabItem
  },
  props: {
    tabs: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'tab list'
    },
    defaultValue: {
      required: false,
      type: String,
      default: '',
      note: 'If default name is not the first one in tabs array'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: '',
      note: 'Name of localStorage key'
    },
    backgroundClass: {
      type: String,
      required: false,
      note: 'parent element background color',
      default: 'bg-white'
    },
    theme: {
      type: String,
      required: false,
      note: 'theme for tab list - set a borders color',
      validator: function validator(theme) {
        return theme === 'dark' || theme === '';
      },
      default: ''
    }
  },
  emits: ['update'],
  setup: function setup(props, _ref) {
    var _props$tabs$;
    var emit = _ref.emit;
    var defaultValue = props.defaultValue || ((_props$tabs$ = props.tabs[0]) === null || _props$tabs$ === void 0 ? void 0 : _props$tabs$.slotName) || '';
    var currentTab = getLocalStorageComputed(props.localStorageKey, defaultValue);
    function setCurrentTab(tab) {
      if (!tab || currentTab.value === tab) return;
      currentTab.value = tab;
      emit('update', tab);
    }
    function isNameInTabs(slotName, tabs) {
      return tabs.find(function (tab) {
        return tab.slotName === slotName;
      });
    }
    watch(currentTab, function (newValue) {
      var _props$tabs$2;
      if (!isNameInTabs(newValue, props.tabs)) setCurrentTab((_props$tabs$2 = props.tabs[0]) === null || _props$tabs$2 === void 0 ? void 0 : _props$tabs$2.slotName);
    }, {
      immediate: true
    });
    watch(function () {
      return props.tabs;
    }, function (newValue) {
      var _newValue$;
      if (!isNameInTabs(currentTab.value, newValue)) setCurrentTab((_newValue$ = newValue[0]) === null || _newValue$ === void 0 ? void 0 : _newValue$.slotName);
    });
    return {
      setCurrentTab: setCurrentTab,
      currentTab: currentTab
    };
  },
  mounted: function mounted() {
    this.$emit('update', this.currentTab);
  }
};