import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createBlock(_resolveDynamicComponent($options.overviewComponent), {
    "project-id": $props.projectId
  }, {
    default: _withCtx(function () {
      return [$options.showSwitcher ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $setup.showMiniTreeComponent ? $data.translate.defaultTree : $data.translate.detailedTree,
        type: "util",
        class: _normalizeClass(["mr-4", {
          'mt-4': !$setup.showMiniTreeComponent
        }]),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $setup.showMiniTreeComponent = !$setup.showMiniTreeComponent;
        })
      }, null, 8 /* PROPS */, ["text", "class"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["project-id"]);
}