import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_data_flow_legal_basis_and_storage_period = _resolveComponent("data-flow-legal-basis-and-storage-period");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.editLegalBasisAndStoragePeriod,
    "button-ok-text": $options.filteredFlows.length ? $data.translate.save : $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    type: "big",
    onAccept: $options.onModalAccept,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [$options.showEmptyMessage ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($data.translate.youHaveNotConfiguredYourQuestionnaire), 1 /* TEXT */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredFlows, function (subFlow, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: "mb-4"
        }, [$props.questions.length ? (_openBlock(), _createBlock(_component_data_flow_legal_basis_and_storage_period, {
          key: 0,
          data: {
            questionsById: $options.questionsById
          },
          "selected-options": $data.selectedOptionsByFlow[subFlow.id],
          "extended-with-storage-period": $options.extendedWithStoragePeriod,
          "onUpdate:selected": function onUpdateSelected($event) {
            return $options.updateSelected($event, subFlow.id);
          }
        }, null, 8 /* PROPS */, ["data", "selected-options", "extended-with-storage-period", "onUpdate:selected"])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}