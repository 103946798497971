function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { getInformationAssetsTitle } from '@/utils/InformationAssets';
export var MODULES = {
  dataFlow: 'data_flow',
  gapAnalysis: 'gap_analysis',
  RISK: 'risk',
  RISK_ASSESSMENT: 'risk_assessment',
  THREAT: 'threat',
  RISK_THREAT_ASSESSMENT: 'risk_threat_assessment',
  COMPLIANCE_PROCESS_TREE: 'compliance_process_tree',
  COMPLIANCE_INFORMATION_ASSETS: 'information_assets',
  REVIEW: 'review',
  COMPLIANCE: 'compliance',
  COMPLIANCE_QUESTION: 'compliance_question',
  EXECUTION: 'execution',
  AWARENESS: 'awareness',
  INCIDENTS: 'incidents',
  COMPLIANCE_IA_SYSTEM: 'compliance_ia_system',
  CONTROL_COMPLETION: 'control-completion',
  SCORING_ZAXIS: 'scoring-zaxis',
  COMPLIANCE_IA_PROCESSOR: 'compliance_ia_processor',
  COMPLIANCE_PROCESS_TREE_NODE: 'compliance_process_tree_node',
  RISK_PROJECT_PERIOD: 'risk_project_period',
  COMPLIANCE_GAP: 'compliance_gap',
  COMPLIANCE_GAP_SCHEMA: 'compliance_gap_schema',
  COMPLIANCE_IA_PROCESSOR_DPM: 'compliance_ia_processor_dpm',
  COMPLIANCE_IA_CONTROLLER: 'compliance_ia_controller',
  CONTROLS: 'control',
  MOTHER_CONTROLS: 'mother_controls',
  CONTRACTS: 'contracts',
  COMPANY_CONTACT: 'company_contact',
  COMPANY: 'company',
  USERS: 'users',
  COMPLIANCE_QUESTIONNAIRE: 'compliance_questionnaire',
  COMPLIANCE_QUESTIONNAIRE_DPM: 'compliance_questionnaire_dpm_requests',
  COMPLIANCE_QUESTIONS: 'compliance_questions',
  ACCESS_REQUIREMENTS: 'access_requirements',
  FRAMEWORK: 'framework'
};
export var getModuleTitles = function getModuleTitles() {
  var _ref;
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, MODULES.COMPLIANCE, 'Compliance'), MODULES.COMPLIANCE_IA_CONTROLLER, 'Data controllers'), MODULES.COMPLIANCE_IA_PROCESSOR, 'Data processors'), MODULES.COMPLIANCE_IA_SYSTEM, 'Systems'), MODULES.COMPLIANCE_PROCESS_TREE_NODE, getProcessLibraryTitle()), MODULES.COMPLIANCE_PROCESS_TREE, getProcessLibraryTitle()), MODULES.CONTROLS, 'Controls'), MODULES.EXECUTION, 'Actions'), MODULES.RISK, 'Risks'), MODULES.INCIDENTS, 'Incidents'), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, getInformationAssetsTitle()), MODULES.CONTRACTS, 'Contracts'), MODULES.AWARENESS, 'Awareness'), MODULES.COMPLIANCE_QUESTION, 'Audits'), MODULES.RISK_ASSESSMENT, 'Risk assessment'), MODULES.COMPANY, 'Company');
};
export var itemTitleByModule = function itemTitleByModule($trans) {
  var _ref2;
  return _ref2 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref2, MODULES.COMPLIANCE, $trans('Compliance')), MODULES.COMPLIANCE_IA_CONTROLLER, $trans('Data controller')), MODULES.COMPLIANCE_IA_PROCESSOR, $trans('Data processor')), MODULES.COMPLIANCE_IA_SYSTEM, $trans('System')), MODULES.COMPLIANCE_PROCESS_TREE_NODE, $trans('Process library node')), MODULES.CONTROLS, $trans('Control')), MODULES.EXECUTION, $trans('Action')), MODULES.RISK, $trans('Risk')), MODULES.INCIDENTS, $trans('Incident')), MODULES.COMPLIANCE_INFORMATION_ASSETS, $trans('Information asset')), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref2, MODULES.CONTRACTS, $trans('Contract')), MODULES.AWARENESS, $trans('Awareness')), MODULES.COMPLIANCE_QUESTION, $trans('Audit')), MODULES.RISK_ASSESSMENT, $trans('Risk assessment')), MODULES.COMPANY, $trans('Company'));
};
export var TRAFFIC_LIGHT_MODULES = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.COMPANY, MODULES.COMPANY), MODULES.INCIDENTS, MODULES.INCIDENTS), "gaps", MODULES.COMPLIANCE_GAP), "default", 'default');