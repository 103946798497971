import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  return !$data.loaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_preview_list_tile, {
    key: 1,
    title: $data.title,
    activities: $data.nodes,
    "internal-link-enabled": true,
    "watermark-image": $data.watermarkImage,
    "cookie-hidden-name": "compliance-overview-my-compliance-".concat($props.projectId),
    onUpdateFromPreviewMode: $options.updateFromPreviewMode
  }, null, 8 /* PROPS */, ["title", "activities", "watermark-image", "cookie-hidden-name", "onUpdateFromPreviewMode"]));
}