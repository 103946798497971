function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var COMPANY_ASSOCIATION_TYPES = {
  BRANCH: 1,
  SUB: 2
};
export var getCompanyAssociationTypesMap = function getCompanyAssociationTypesMap(trans) {
  return _defineProperty(_defineProperty({}, COMPANY_ASSOCIATION_TYPES.BRANCH, trans('Branch')), COMPANY_ASSOCIATION_TYPES.SUB, trans('Subsidiary'));
};
export var COMPANY_INTERNAL_PROPS = {
  TITLE: 'title',
  DATA_LOCATION: 'dataLocation',
  DATA_MANAGEMENT_LOCATION: 'dataManagementLocation',
  COUNTRY_OF_PROVISION: 'countryOfProvision',
  DATA_SENSITIVENESS: 'dataSensitiveness',
  FUNCTION_IDS: 'functionIds',
  RELIANCE_LEVEL: 'relianceLevel',
  SERVICE_PROVIDERS: 'serviceProviders',
  STORAGE_OF_DATA: 'storageOfData',
  TYPE_OF_SERVICE: 'typeOfService'
};