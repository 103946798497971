import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex my-3"
};
var _hoisted_2 = {
  class: "mr-5 flex"
};
var _hoisted_3 = {
  class: "bg-slate-200 flex-grow mr-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_BarChart = _resolveComponent("BarChart");
  var _component_export_frame = _resolveComponent("export-frame");
  return _openBlock(), _createElementBlock("div", null, [$props.itemsPerScroll ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_feather_icon, {
    icon: "arrow-left",
    class: "mr-2 h-full w-8 bg-slate-200 cursor-pointer",
    onClick: $options.showPrevSection
  }, null, 8 /* PROPS */, ["onClick"]), _createVNode(_component_feather_icon, {
    icon: "arrow-right",
    class: "h-full w-8 bg-slate-200 cursor-pointer",
    onClick: $options.showNextSection
  }, null, 8 /* PROPS */, ["onClick"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
    class: "bg-gray-510 transition-all relative inline-block h-full",
    style: _normalizeStyle($options.scrollStyles)
  }, null, 4 /* STYLE */)]), _createElementVNode("div", {
    class: "bg-slate-200 flex w-32 items-center cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showAll = !$data.showAll;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: $data.showAll ? 'zoom-out' : 'zoom-in',
    class: "mr-1 w-8"
  }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("p", null, _toDisplayString($options.zoomTitle), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createVNode(_component_export_frame, {
    "use-export": _ctx.useDefaultExport,
    "excel-export-function": _ctx.isExcelExportAvailable ? _ctx.excelExportFunction : null
  }, {
    default: _withCtx(function () {
      return [(_openBlock(), _createBlock(_component_BarChart, {
        ref: "chart",
        key: $data.chartKey,
        data: $options.dataToShow,
        options: $options.barChartOptions,
        plugins: $options.pluginsInUse,
        height: $props.height
      }, null, 8 /* PROPS */, ["data", "options", "plugins", "height"]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["use-export", "excel-export-function"])]);
}