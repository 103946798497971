function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import * as tagsAPI from '@/api/tags/tags';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TagCreationModal from '@/components/Molecules/Modal/TagCreationModal';
import { Colors } from '@/Colors';
export default {
  name: 'AdminTagsFieldWell',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    TagCreationModal: TagCreationModal
  },
  data: function data() {
    return {
      color: Colors,
      tagCreationName: '',
      showCreateModal: false,
      showModifyModal: false,
      deleteModal: false,
      selectedItemTag: '',
      modalErrorMessage: '',
      tableCellClasses: 'px-3 py-2 border-b-2 border-slate-200 text-left break-words',
      translate: getTranslate['AdminTagsFieldWell']()
    };
  },
  computed: _objectSpread({}, mapState(useTagsStore, ['tags'])),
  methods: _objectSpread(_objectSpread({}, mapActions(useTagsStore, {
    removeFromStore: 'deleteTag'
  })), {}, {
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = true;
      this.showModifyModal = false;
    },
    toggleUpdateSelectedModal: function toggleUpdateSelectedModal(tagId) {
      this.selectedItemTag = tagId;
      this.showModifyModal = true;
      this.showCreateModal = false;
    },
    handleDeletionNotification: function handleDeletionNotification(tagId) {
      var _this = this;
      this.selectedItemTag = tagId;
      this.toggleModal();
      this.$confirm(this.translate.deleteTag, this.translate.areYouSureYouWantToDeleteThisTag, function (res) {
        return res ? _this.deleteTag(_this.selectedItemTag) : _this.selectedItemTag = '';
      });
    },
    toggleModal: function toggleModal() {
      this.showCreateModal = false;
      this.showModifyModal = false;
    },
    onCreateTag: function onCreateTag() {
      this.toggleModal();
      this.$notify({
        title: this.translate.tagHasBeenCreated
      });
    },
    onModifyTag: function onModifyTag() {
      this.toggleModal();
      this.$notify({
        title: this.translate.tagHasBeenUpdated
      });
    },
    deleteTag: function deleteTag(tagId) {
      var _this2 = this;
      tagsAPI.remove(tagId).then(function (response) {
        return _this2.handleDeleteTagSuccess(response, tagId);
      }).catch(this.handleDeleteTagFailure);
    },
    handleDeleteTagSuccess: function handleDeleteTagSuccess(response, tagId) {
      if (response === 'OK') {
        this.removeFromStore(tagId);
      }
      this.$notify({
        title: this.translate.tagHasBeenDeleted
      });
    },
    handleDeleteTagFailure: function handleDeleteTagFailure(error) {
      this.$notify({
        title: error.response.error,
        type: 'error'
      });
    }
  })
};