function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  class: "hover:underline"
};
var _hoisted_4 = {
  class: "glob-l1 mr-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "flex mb-4 text-rm-text w-fit cursor-pointer items-center",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isOpened = !$data.isOpened;
    })
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.companyInformation,
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass([{
      'rotate-180 ': $data.isOpened
    }])
  }, null, 8 /* PROPS */, ["class"])]), $data.isOpened ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.name + ': ',
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [$options.isPreview ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: $options.companyUrl
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.company.name), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 1,
    activity: _objectSpread(_objectSpread({}, $props.company), {}, {
      url: $options.companyUrl
    }),
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:company', $event);
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", null, _toDisplayString($props.company.name), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"]))])]), $options.companyTrafficLight ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_risma_title, {
    title: $data.translate.status + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString(_ctx.$trans($options.companyTrafficLight)) + " ", 1 /* TEXT */), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1 /* HOISTED */))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.address + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.address) + " ", 1 /* TEXT */), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_title, {
    title: $data.translate.zipcode + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.zipCode) + " ", 1 /* TEXT */), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_title, {
    title: $data.translate.city + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.city) + " ", 1 /* TEXT */), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_title, {
    title: $data.translate.countryCodeCountry + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.countryCode) + " ", 1 /* TEXT */), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_title, {
    title: $data.translate.email + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.email) + " ", 1 /* TEXT */), _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_title, {
    title: $data.translate.phone + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.phone) + " ", 1 /* TEXT */), _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_title, {
    title: $data.translate.vatNumber + ': ',
    type: "medium",
    class: "inline"
  }, null, 8 /* PROPS */, ["title"]), _createTextVNode(" " + _toDisplayString($props.company.vatNo) + " ", 1 /* TEXT */), _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1 /* HOISTED */))]), !$props.readOnly ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "mt-2 flex items-center cursor-pointer text-rm-signal-grey-dark hover:underline group w-fit",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('unlink');
    })
  }, [_createElementVNode("span", _hoisted_4, _toDisplayString($data.translate.unlinkCompany), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "x-circle",
    class: "group-hover:text-rm-signal-red"
  })])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}