import { Http, HttpLegacy } from '../index';
import { handleRequestResponse } from '../httpUtils';
import { getPromise } from '@/api/HttpCache';
var SCORING_URL = 'risks/scoring';
export function getScoring(id) {
  return handleRequestResponse(Http.get("".concat(SCORING_URL, "/").concat(id)));
}
export function updateScoring(id, params) {
  return handleRequestResponse(Http.patch("".concat(SCORING_URL, "/").concat(id), params));
}
export function getCurrentScoring() {
  return getPromise("".concat(SCORING_URL, "/current"));
}
export function getAllScorings() {
  return getPromise(SCORING_URL);
}
export function createScoring(data) {
  return handleRequestResponse(Http.post(SCORING_URL, data));
}
export function deleteScoring(id) {
  return handleRequestResponse(Http.delete("".concat(SCORING_URL, "/").concat(id)));
}
export function createTableCalculation(data) {
  return handleRequestResponse(HttpLegacy.post('/api/combinedfields/createwithsubfields', data));
}
export function getTableCalculationById(scoringId, tableId) {
  return handleRequestResponse(HttpLegacy.get("/api/combinedfields/listfields/".concat(scoringId, "/").concat(tableId)));
}
export function updateScoringTableValue(data) {
  return handleRequestResponse(HttpLegacy.post('/api/risk/updatescoringtablevalue', data));
}
export function updateTableCalculation(tableId, calculationId) {
  return handleRequestResponse(HttpLegacy.get("/api/risk/updatetablecalculation/".concat(tableId, "/").concat(calculationId)));
}
export function updateScoringTableFieldWeight(scoringId, data) {
  return handleRequestResponse(Http.patch("/risks/scoring/".concat(scoringId, "/fieldweight"), data));
}