function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { copyQuestionnaire, enableQuestionnaire, changeFieldOnQuestionnaire, deleteQuestionnaire, getAllQuestionnaires, getAllQuestionnaireTemplates } from '@/api/compliance/questionnaire';
import { getAllGapSchemas } from '@/api/compliance/gapschema';
import { getAll as getAllProjects } from '@/api/compliance/projects';
import { getAvailableQuestionnaireTypes } from '@/utils/questionnaires';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import StaticTable from '@/components/Molecules/StaticTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TemplateList from '@/components/Pages/Admin/TemplateList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
import { questionnaireAdminFields } from '@/constants/compliance/ComplianceAdmin';
import { MODULES } from '@/constants/modules';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { toLocalDateTime } from '@/utils/date';
export default {
  name: 'QuestionnaireAdmin',
  introduction: '',
  description: '',
  token: '<questionnaire-admin></questionnaire-admin>',
  components: {
    TemplateList: TemplateList,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Notification: Notification,
    Modal: Modal,
    InputField: InputField,
    Checkboxes: Checkboxes
  },
  props: {
    module: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      projects: [],
      questionnaireType: '',
      allQuestionnaireTypes: [],
      allQuestionnaires: [],
      templates: [],
      slotNames: {
        eventFields: [questionnaireAdminFields.TITLE, questionnaireAdminFields.TAB_LABEL, questionnaireAdminFields.COPY, questionnaireAdminFields.DELETE],
        linkFields: [questionnaireAdminFields.DOWNLOAD_CSV, questionnaireAdminFields.DOWNLOAD_EXCEL],
        routerLinkFields: [questionnaireAdminFields.EDIT, questionnaireAdminFields.SCORING]
      },
      allGapSchemas: [],
      hiddenTypes: [QuestionnaireTypes.RISK, QuestionnaireTypes.INCIDENT, QuestionnaireTypes.CONTRACT],
      isLoading: false,
      saveChangesError: '',
      translate: getTranslate['QuestionnaireAdmin'](),
      showTitleModal: false,
      titleModalHeader: '',
      newTitle: '',
      bufferFunction: null
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, {
    customIAModules: 'complianceIACustomActivityTypes'
  })), {}, {
    columns: function columns() {
      return [this.translate.name, this.translate.tabLabel, this.availableGapSchemasLength ? this.translate.defaultGapSchema : null, this.translate.questionCount, this.nodeCountTitle(), this.translate.date, this.translate.activated, this.translate.questions, this.translate.scoring, this.translate.copy, this.translate.delete, '', ''].filter(function (item) {
        return item !== null;
      });
    },
    questionnaireTypes: function questionnaireTypes() {
      var _this = this;
      var questionnaireTypeIds = this.allQuestionnaires.map(function (questionnaire) {
        return questionnaire.questionnaireType;
      });
      var templateTypeIds = this.templates.map(function (template) {
        return template.type;
      });
      var allTypeIds = [].concat(_toConsumableArray(questionnaireTypeIds), _toConsumableArray(templateTypeIds));
      return this.allQuestionnaireTypes.filter(function (questionnaireType) {
        return allTypeIds.indexOf(questionnaireType.id) !== -1 && !_this.hiddenTypes.includes(questionnaireType.id);
      }).map(function (item) {
        return {
          label: _this.translateQuestionnaireType(item.type),
          value: item.id
        };
      });
    },
    questionnaires: function questionnaires() {
      var _this2 = this;
      return this.allQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType == _this2.questionnaireType;
      });
    },
    gapSchemasBytype: function gapSchemasBytype() {
      var result = {};
      this.allGapSchemas.forEach(function (schema) {
        var type = schema.gapSchemaType;
        var option = {
          value: schema.id,
          label: schema.title
        };
        if (!result[type]) {
          result[type] = [];
        }
        result[type].push(option);
      });
      return result;
    },
    formattedData: function formattedData() {
      var _this3 = this;
      var format = [];
      this.questionnaires.forEach(function (questionnaire) {
        var _obj;
        var obj = (_obj = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_obj, questionnaireAdminFields.TITLE, {
          disableEvent: questionnaire.readonly,
          href: '#questionnaire-admin',
          dataId: questionnaire.id,
          dataMethod: 'title',
          action: 'title',
          id: questionnaire.id,
          currentTitle: questionnaire.title,
          text: questionnaire.title
        }), questionnaireAdminFields.TAB_LABEL, {
          href: '#questionnaire-admin',
          dataId: questionnaire.id,
          dataMethod: 'title',
          action: 'tabLabel',
          id: questionnaire.id,
          currentTitle: questionnaire.tabLabel,
          text: questionnaire.tabLabel
        }), questionnaireAdminFields.DEFAULT_GAP_SCHEMA, {
          schemaId: questionnaire.defaultGapSchemaId,
          questionnaireId: questionnaire.id,
          options: _this3.gapSchemasBytype[questionnaire.questionnaireType]
        }), questionnaireAdminFields.QUESTION_COUNT, questionnaire.questionCount), questionnaireAdminFields.USAGE, questionnaire.nodeUsageCount), questionnaireAdminFields.CREATED, {
          created: toLocalDateTime(questionnaire.created)
        }), questionnaireAdminFields.ENABLED, {
          action: 'enabled',
          isChecked: questionnaire.selectable == 1,
          isDisabled: questionnaire.inUse,
          dataId: questionnaire.id,
          selectable: !questionnaire.selectable,
          id: questionnaire.id,
          currentTitle: questionnaire.title
        }), questionnaireAdminFields.EDIT, {
          disableRouterLink: questionnaire.readonly,
          url: "/admin/questionnaires/".concat(_this3.questionnaireType, "/").concat(questionnaire.id, "/questions"),
          text: _this3.translate.edit
        }), questionnaireAdminFields.SCORING, {
          url: {
            path: "/admin/questionnaires/".concat(questionnaire.id, "/scoring"),
            query: {
              questionnaireType: _this3.questionnaireType
            }
          },
          text: _this3.translate.scoring
        }), questionnaireAdminFields.COPY, {
          href: '#',
          dataId: questionnaire.id,
          dataMethod: 'copy',
          action: 'copy',
          id: questionnaire.id,
          currentTitle: questionnaire.title,
          text: '',
          hasIcon: true,
          icon: 'copy'
        }), _defineProperty(_defineProperty(_defineProperty(_obj, questionnaireAdminFields.DELETE, {
          disableEvent: questionnaire.inUse,
          href: '#',
          dataId: questionnaire.id,
          dataMethod: 'delete',
          action: 'delete',
          id: questionnaire.id,
          currentTitle: null,
          hasIcon: true,
          icon: 'trash',
          text: ''
        }), questionnaireAdminFields.DOWNLOAD_CSV, {
          url: "/api/2.0/compliance/questionnaire/".concat(questionnaire.id, "/download?format=csv"),
          icon: 'file-text'
        }), questionnaireAdminFields.DOWNLOAD_EXCEL, {
          url: "/api/2.0/compliance/questionnaire/".concat(questionnaire.id, "/download?format=excel"),
          icon: 'download'
        }));
        if (!_this3.availableGapSchemasLength) {
          delete obj[questionnaireAdminFields.DEFAULT_GAP_SCHEMA];
        }
        format.push(obj);
      });
      return format;
    },
    availableGapSchemasLength: function availableGapSchemasLength() {
      var _this$gapSchemasBytyp;
      return ((_this$gapSchemasBytyp = this.gapSchemasBytype) === null || _this$gapSchemasBytyp === void 0 || (_this$gapSchemasBytyp = _this$gapSchemasBytyp[this.questionnaireType]) === null || _this$gapSchemasBytyp === void 0 ? void 0 : _this$gapSchemasBytyp.length) || 0;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'questionnaire');
    }
  }),
  watch: {
    questionnaireType: function questionnaireType(_questionnaireType) {
      this.$router.push({
        query: {
          questionnaireType: _questionnaireType
        }
      });
      this.saveChangesError = '';
    },
    module: function module() {
      this.preselectQuestionnaireType();
    }
  },
  mounted: function mounted() {
    this.preselectQuestionnaireType();
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this4 = this;
      var promises = [this.getQuestionnaireTemplates(), this.getQuestionnaireTypes(), this.getQuestionnaires(), this.getGapSchemas(), this.getProjectsWithDefaultQuestionnaires()];
      this.isLoading = true;
      return Promise.all(promises).finally(function () {
        _this4.isLoading = false;
      });
    },
    preselectQuestionnaireType: function preselectQuestionnaireType() {
      if (this.module) {
        var questionnaireId = this.getQuestionnaireTypeByModule(this.module);
        if (questionnaireId) {
          this.questionnaireType = questionnaireId;
        }
      } else {
        this.questionnaireType = this.$route.query && this.$route.query.questionnaireType ? +this.$route.query.questionnaireType : 0;
      }
    },
    getQuestionnaireTypeByModule: function getQuestionnaireTypeByModule(module) {
      switch (module) {
        case MODULES.CONTRACTS:
          return QuestionnaireTypes.CONTRACT;
        case MODULES.INCIDENTS:
          return QuestionnaireTypes.INCIDENT;
        case MODULES.RISK:
          return QuestionnaireTypes.RISK;
        default:
          return '';
      }
    },
    getProjectsWithDefaultQuestionnaires: function getProjectsWithDefaultQuestionnaires() {
      var _this5 = this;
      return getAllProjects(true, 'data[]=defaultQuestionnaireId').then(function (_ref) {
        var list = _ref.list;
        return _this5.projects = list;
      });
    },
    getQuestionnaireTypes: function getQuestionnaireTypes() {
      var _this6 = this;
      return getAvailableQuestionnaireTypes().then(function (list) {
        return _this6.allQuestionnaireTypes = list;
      });
    },
    getQuestionnaires: function getQuestionnaires() {
      var _this7 = this;
      return getAllQuestionnaires().then(function (_ref2) {
        var list = _ref2.list;
        return _this7.allQuestionnaires = list;
      });
    },
    getQuestionnaireTemplates: function getQuestionnaireTemplates() {
      var _this8 = this;
      return getAllQuestionnaireTemplates('questionnaire').then(function (_ref3) {
        var list = _ref3.list;
        return _this8.templates = list;
      });
    },
    handleEvents: function handleEvents(_ref4, event) {
      var _this9 = this;
      var action = _ref4.action,
        id = _ref4.id,
        currentTitle = _ref4.currentTitle,
        selectable = _ref4.selectable;
      this.saveChangesError = '';
      this.newTitle = currentTitle;
      switch (action) {
        case 'enabled':
          this.onQuestionnaireEnableDisable(id, currentTitle, selectable, event);
          return;
        case 'delete':
          this.bufferFunction = function () {
            return deleteQuestionnaire(id).then(_this9.getQuestionnaires).catch(function () {
              _this9.saveChangesError = _this9.translate.somethingWentWrongWhileDeleting;
            });
          };
          this.$confirm(this.translate.deleteQuestionnaire, this.translate.areYouSureYouWantToDeleteThisQuestionnaire, function (res) {
            return res && _this9.doConfirm();
          });
          return;
        case 'copy':
          this.titleModalHeader = "".concat(this.translate.enterNewTitle, ":");
          this.bufferFunction = function (title) {
            return copyQuestionnaire(id, title).then(_this9.getQuestionnaires);
          };
          this.showTitleModal = true;
          return;
        case 'title':
          this.titleModalHeader = "".concat(this.translate.enterNewTitle, ":");
          this.bufferFunction = function (title) {
            return changeFieldOnQuestionnaire(id, title).then(_this9.getQuestionnaires);
          };
          this.showTitleModal = true;
          return;
        case 'tabLabel':
          this.titleModalHeader = "".concat(this.translate.enterNewTabLabel, ":");
          this.bufferFunction = function (title) {
            return changeFieldOnQuestionnaire(id, title, 'tabLabel').then(_this9.getQuestionnaires);
          };
          this.showTitleModal = true;
          return;
      }
    },
    doConfirm: function doConfirm() {
      this.bufferFunction(this.newTitle);
      this.closePopups();
    },
    closePopups: function closePopups() {
      this.newTitle = '';
      this.showTitleModal = false;
      this.bufferFunction = null;
    },
    onQuestionnaireEnableDisable: function onQuestionnaireEnableDisable(id, currentTitle, selectable) {
      var usedAsDefaultForProject = this.isQuestionnaireUsedAsDefault(id);
      if (usedAsDefaultForProject && selectable === false) {
        this.saveChangesError = this.translate.theQuestionnaireCantBeDisabledErrorMessage;
        return;
      }
      return enableQuestionnaire(id, currentTitle, selectable).then(this.getQuestionnaires);
    },
    isQuestionnaireUsedAsDefault: function isQuestionnaireUsedAsDefault(questionnaireId) {
      switch (this.questionnaireType) {
        case QuestionnaireTypes.DATAPROCESSOR:
          return +this.getSettingValue('feature.processors_default_questionnaire_id') === +questionnaireId;
        case QuestionnaireTypes.SYSTEM:
          return +this.getSettingValue('feature.systems_default_questionnaire_id') === +questionnaireId;
        case QuestionnaireTypes.DATACONTROLLER:
          return +this.getSettingValue('feature.controllers_default_questionnaire_id') === +questionnaireId;
        case QuestionnaireTypes.IA_CUSTOM:
          return this.customIAModules.find(function (module) {
            return module.defaultQuestionnaireId === questionnaireId;
          });
        default:
          return this.projects.find(function (project) {
            return project.defaultQuestionnaireId === questionnaireId;
          });
      }
    },
    translateQuestionnaireType: function translateQuestionnaireType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    },
    nodeCountTitle: function nodeCountTitle() {
      switch (this.questionnaireType) {
        case QuestionnaireTypes.DATAPROCESSOR:
          return this.translate.dataProcessorCount;
        case QuestionnaireTypes.SYSTEM:
          return this.translate.systemCount;
        case QuestionnaireTypes.DATACONTROLLER:
          return this.translate.controllersCount;
        case QuestionnaireTypes.DPM:
          return this.translate.dpmAuditCount;
        case QuestionnaireTypes.IAAUDIT:
          return this.translate.auditCount;
        case QuestionnaireTypes.CONTRACT:
          return this.translate.contractCount;
        case QuestionnaireTypes.INCIDENT:
          return this.translate.incidentCount;
        case QuestionnaireTypes.RISK:
          return this.translate.riskCount;
        default:
          return this.translate.processCount;
      }
    },
    getGapSchemas: function getGapSchemas() {
      var _this10 = this;
      return getAllGapSchemas('data[]=all').then(function (_ref5) {
        var list = _ref5.list;
        _this10.allGapSchemas = list;
      });
    },
    addDefaultGapSchema: function addDefaultGapSchema(gapSchemaId, questionnaireId) {
      return changeFieldOnQuestionnaire(questionnaireId, gapSchemaId, 'defaultGapSchemaId').then(this.getQuestionnaires);
    }
  }
};