import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'TrafficLight',
  components: {
    FeatherIcon: FeatherIcon
  },
  description: '',
  token: '<traffic-light color="#57aa74" stroke="#57aa74"/>',
  props: {
    color: {
      type: String,
      required: true,
      note: 'the color'
    },
    title: {
      type: String,
      note: 'a title which will be shown on hover',
      default: ''
    },
    size: {
      type: String,
      default: 'default',
      note: 'the width and the height of the traffic light'
    },
    isBan: {
      type: Boolean,
      default: false,
      note: 'Set this to true for N/A gaps'
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    dimension: function dimension() {
      switch (this.size) {
        case 'small':
          return 12;
        case 'big':
          return 20;
        default:
          return 16;
      }
    }
  }
};