import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_multiple_date_range = _resolveComponent("multiple-date-range");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_multiple_date_range, {
    class: "py-3",
    "initial-ranges": [{
      dateStart: '2023-08-29',
      dateEnd: '2023-08-29'
    }],
    "local-storage-key": "multiple-date-range-kitchen-key",
    onRangesChanged: _cache[0] || (_cache[0] = function ($event) {
      return $data.ranges = $event;
    })
  }), $data.ranges.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", null, "Date ranges:", -1 /* HOISTED */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.ranges, function (range) {
    return _openBlock(), _createElementBlock("div", null, _toDisplayString(range), 1 /* TEXT */);
  }), 256 /* UNKEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}