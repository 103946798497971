function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { TabTypes } from '@/constants/Tabs';
import { getGapUrlForPreview } from '@/utils/format/gapListToActivityList';
export default {
  name: 'GapTableRow',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  introduction: '',
  description: '',
  token: '<gap-table-row></gap-table-row>',
  props: {
    gap: {
      required: true,
      type: Object,
      note: 'A gap object. needs to have a node property, with the associated node item'
    },
    gapArticle: {
      required: true,
      type: Object,
      node: 'A gap article object'
    },
    showRiskAssessment: {
      type: Boolean,
      required: true,
      default: true,
      note: 'defines if risk assessment info should be displayed'
    },
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Project id from route'
    }
  },
  data: function data() {
    return {
      translate: {
        thisHasNotBeenAnsweredYet: this.$trans('This has not been answered yet'),
        requirement: this.$trans('Requirement')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTrafficLights, ['gapTrafficLights'])), {}, {
    gapColor: function gapColor() {
      return this.gapTrafficLights[this.gap.analysis_rag];
    },
    gapAnalysisPreviewObj: function gapAnalysisPreviewObj() {
      return _objectSpread(_objectSpread({}, this.gap._data), {}, {
        compliance_projects_id: +this.projectId,
        url: getGapUrlForPreview(this.gap._data, this.projectId),
        route: {
          tab: TabTypes.GAPANALYSIS,
          id: this.gap._data.articleId
        }
      });
    }
  })
};