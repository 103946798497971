// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table th[data-v-5250f77d],
table td[data-v-5250f77d] {
  border: 1px solid #FFFFFF;
  font-size: 12px;
  line-height: 12px;
  word-break: break-all;
  max-width: 78px;
  min-width: 78px;
  padding: 5px;
}
table th[data-v-5250f77d]:first-child,
table td[data-v-5250f77d]:first-child {
  min-width: 250px;
  max-width: 250px;
}
table tr:hover td[data-v-5250f77d] {
  background: #347480;
  color: #FFFFFF;
}
table tr:hover td a[data-v-5250f77d] {
  color: #FFFFFF;
}
[data-v-5250f77d]  .export-drawing-shape {
  width: 45px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
