import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "user-rights-admin w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        onShowNotification: $options.handleShowNotification
      }, null, 40 /* PROPS, NEED_HYDRATION */, ["onShowNotification"]))];
    }),
    _: 1 /* STABLE */
  })]);
}