import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_delegation_well = _resolveComponent("delegation-well");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_governance_review_section = _resolveComponent("governance-review-section");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [$options.isUserLocked ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: false,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.thisActivityIsReadonlyAsYourUserIsConfigu), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $options.isLoaded,
    item: $data.processNode,
    "dep-header-props": $options.depHeaderProps,
    readonly: $options.isUserLocked,
    "local-storage-key": "relations-process-node",
    onUpdatingAttachments: $options.updatingAttachments,
    onFileUpdate: $options.onFileUpdate,
    onPropertyChanged: $options.processNodePropertyChanged,
    onSaveStatusClicked: $options.saveStatusClicked,
    onGetActivityData: $options.getActivityData,
    onChangeTab: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    })
  }, {
    delegation: _withCtx(function () {
      return [$data.processNode ? (_openBlock(), _createBlock(_component_delegation_well, {
        key: 0,
        "read-only": $options.isUserLocked,
        "model-value": $options.processNodeFormatted,
        organisations: _ctx.organisations,
        onPropertyChanged: $options.saveChangesImmediately
      }, null, 8 /* PROPS */, ["read-only", "model-value", "organisations", "onPropertyChanged"])) : _createCommentVNode("v-if", true)];
    }),
    details: _withCtx(function () {
      return [$data.processNode && _ctx.processNodeActivityTypes.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_activity_type_selector, {
        modelValue: $data.processNode.activityTypeId,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return $data.processNode.activityTypeId = $event;
        }), _cache[1] || (_cache[1] = function ($event) {
          return $options.processNodePropertyChanged({
            property: 'activityTypeId',
            value: $event
          });
        })],
        disabled: $options.isUserLocked,
        "activity-types": _ctx.processNodeActivityTypes,
        "confirm-alert-message": $data.translate.areYouSure,
        label: $data.translate.activityType,
        class: "mb-5"
      }, null, 8 /* PROPS */, ["modelValue", "disabled", "activity-types", "confirm-alert-message", "label"])])) : _createCommentVNode("v-if", true), _createVNode(_component_governance_review_section, {
        review: _ctx.review,
        "project-name": _ctx.processLibraryTitle,
        users: _ctx.users,
        disabled: $options.isUserLocked,
        organisations: _ctx.organisations,
        onUpdate: $options.handleChangeReview
      }, null, 8 /* PROPS */, ["review", "project-name", "users", "disabled", "organisations", "onUpdate"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-loaded", "item", "dep-header-props", "readonly", "onUpdatingAttachments", "onFileUpdate", "onPropertyChanged", "onSaveStatusClicked", "onGetActivityData"])]);
}