import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  ref: "toggleMenu",
  class: "relative"
};
var _hoisted_2 = ["title", "onClick"];
var _hoisted_3 = {
  class: "glob-h3-sr"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["group/toggleMenu dot-wrap flex justify-center p-px cursor-pointer active:bg-rm-active-blue--10 items-center hover:bg-rm-grey-light", [$options.buttonStylesByType, $props.buttonStyles]]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.toggleVisibility && $options.toggleVisibility.apply($options, arguments);
    }, ["stop"]))
  }, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-2 group-hover/toggleMenu:text-rm-text group-active/toggleMenu:text-rm-active-blue w-6 h-6", $data.isVisible ? 'text-rm-active-blue' : $props.buttonIconColor]),
    icon: "more-horizontal"
  }, null, 8 /* PROPS */, ["class"])], 2 /* CLASS */), $data.isVisible ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass([$props.position, 'toggle-menu-modal rounded-md border border-rm-grey py-2']),
    style: _normalizeStyle($options.menuPosition)
  }, [_createElementVNode("ul", {
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.closeMenu && $options.closeMenu.apply($options, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.visibleOptions, function (option) {
    return _openBlock(), _createElementBlock("li", {
      key: option.id,
      class: _normalizeClass(["flex cursor-pointer items-center py-2 hover:bg-rm-active-blue--6 px-4", option.type === $data.MENU_OPTION_TYPES.DANGER ? 'text-rm-signal-red' : 'text-rm-text']),
      style: _normalizeStyle([option.style]),
      title: option.title,
      onClick: option.action
    }, [option.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "mr-2 stroke-2",
      icon: option.icon
    }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_3, _toDisplayString(option.title), 1 /* TEXT */)], 14 /* CLASS, STYLE, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))])], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeMenu]]);
}