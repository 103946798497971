import { defineStore } from 'pinia';
import { getCountries as _getCountries } from '@/api/risma/data';
export var useCountriesStore = defineStore('countriesStore', {
  state: function state() {
    return {
      countries: [],
      countriesFetched: false
    };
  },
  actions: {
    getCountries: function getCountries() {
      var _this = this;
      if (this.countriesFetched) {
        return Promise.resolve();
      }
      return _getCountries().then(function (_ref) {
        var list = _ref.list;
        _this.countries = list.map(function (item) {
          return {
            label: item.countryCode,
            value: item.countryCode
          };
        });
      }).finally(function () {
        _this.countriesFetched = true;
      });
    }
  }
});