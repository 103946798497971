// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-a38bb9e6]  .static-table-wrapper {
  overflow: visible;
}
[data-v-a38bb9e6]  .static-table {
  table-layout: fixed;
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
