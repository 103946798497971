function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getRiskTypes as _getRiskTypes, createRiskType as _createRiskType, updateRiskType as _updateRiskType, deleteRiskType as _deleteRiskType } from '@/api/risk/risk';
import RiskManagement from '@/components/Organisms/RiskAdmin/RiskManagement';
export default {
  name: 'RiskTypesWell',
  token: '<risk-types-well />',
  components: {
    RiskManagement: RiskManagement
  },
  props: {
    risks: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      riskTypes: [],
      translate: {
        type: this.$trans('Type'),
        risks: this.$trans('Risks')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    riskTypeLabel: function riskTypeLabel() {
      return this.getSettingValue('risma.risktype_name');
    },
    dataset: function dataset() {
      var _this = this;
      return this.riskTypes.map(function (riskType) {
        var _this$risksHash;
        return {
          title: riskType.title,
          risks: ((_this$risksHash = _this.risksHash) === null || _this$risksHash === void 0 ? void 0 : _this$risksHash[riskType.id]) || 0,
          edit: {
            id: riskType.id,
            title: riskType.title
          },
          delete: {
            id: riskType.id,
            title: riskType.title
          }
        };
      });
    },
    risksHash: function risksHash() {
      var obj = {};
      this.risks.forEach(function (risk) {
        if (!obj[risk.riskTypeId]) {
          obj[risk.riskTypeId] = 0;
        }
        obj[risk.riskTypeId] += 1;
      });
      return obj;
    },
    columns: function columns() {
      return [this.translate.type, this.translate.risks, '', ''];
    }
  }),
  mounted: function mounted() {
    this.getRiskTypes();
  },
  methods: {
    getRiskTypes: function getRiskTypes() {
      var _this2 = this;
      return _getRiskTypes().then(function (_ref) {
        var list = _ref.list;
        return _this2.riskTypes = list;
      });
    },
    createRiskType: function createRiskType(title) {
      var _this3 = this;
      return _createRiskType({
        title: title
      }).then(function (response) {
        _this3.riskTypes.push(response);
      });
    },
    updateRiskType: function updateRiskType(title, id) {
      var _this4 = this;
      return _updateRiskType(id, {
        title: title
      }).then(function (response) {
        var index = _this4.riskTypes.findIndex(function (riskType) {
          return riskType.id === id;
        });
        _this4.riskTypes[index] = response;
        _this4.riskTypes = _toConsumableArray(_this4.riskTypes);
      });
    },
    deleteRiskType: function deleteRiskType(id) {
      var _this5 = this;
      return _deleteRiskType(id).then(function () {
        _this5.riskTypes = _this5.riskTypes.filter(function (riskType) {
          return riskType.id !== id;
        });
      });
    }
  }
};