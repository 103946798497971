import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_kpi_card = _resolveComponent("kpi-card");
  return _openBlock(), _createBlock(_component_kpi_card, {
    "card-header": "Unanswered questionnaires",
    "module-title": "Compliance",
    "current-value": 25,
    "max-value": 100,
    color: "#ce6696",
    "extended-url": "/",
    weight: 300
  }, {
    description: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias aliquam amet dolore enim eos esse eveniet fuga fugit ipsam magnam molestiae mollitia, natus nemo nihil nostrum obcaecati odit, officia qui quisquam reiciendis repudiandae rerum sapiente sint soluta velit voluptates. Adipisci architecto aut corporis cupiditate dolor earum id incidunt laudantium nam numquam, repellat ullam velit, voluptatem voluptates voluptatum? ")]);
    }),
    details: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createTextVNode(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid assumenda autem beatae dolor dolorem ex laboriosam maxime mollitia nihil omnis possimus, provident similique unde! Alias consequuntur magnam molestias nulla pariatur provident vel. Blanditiis consequuntur delectus, eum excepturi incidunt libero molestias numquam quidem? Ad cum enim eveniet nesciunt nostrum veniam. Accusantium alias aliquam animi asperiores autem corporis debitis dignissimos distinctio dolor. ")]);
    }),
    _: 1 /* STABLE */
  });
}