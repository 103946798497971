import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_text_selector = _resolveComponent("text-selector");
  return _openBlock(), _createBlock(_component_text_selector, {
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.value = $event;
    }),
    options: [1, 2, 3]
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(" Click here to change " + _toDisplayString($data.value), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]);
}