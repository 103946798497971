function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import i18n from '@/i18n';
import { sortAlphabetic } from '@/utils/sort';
import { DataFlowOptionTypes } from '@/constants/compliance/WizardFlow';
import { getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
var getElementData = function getElementData(item, groupName) {
  return {
    value: item.uniqueId,
    label: item.text,
    groupName: groupName
  };
};
var getAdditionalTextByType = function getAdditionalTextByType(type) {
  switch (type) {
    case DataFlowOptionTypes.QUESTION_OPTION:
      return getDataflowStakeholdersTitle();
    case DataFlowOptionTypes.DATAPROCESSOR_PROCESSOR:
      return i18n.t('Data processors');
    case DataFlowOptionTypes.DATAPROCESSOR_CONTROLLER:
      return i18n.t('Data controllers');
    case DataFlowOptionTypes.DATAPROCESSOR_SYSTEM:
      return i18n.t('Systems');
    default:
      throw new Error('Could not find option type for step 2');
  }
};
export var prepareGroupedStakeholdersOptions = function prepareGroupedStakeholdersOptions(list) {
  var groupedOptions = list.reduce(function (acc, item) {
    if (!acc[item.type]) {
      acc[item.type] = {
        group: {
          label: getAdditionalTextByType(item.type),
          $isGroup: true
        },
        items: []
      };
    }
    acc[item.type].items.push(getElementData(item, acc[item.type].group.label));
    return acc;
  }, {});
  return Object.values(groupedOptions).sort(function (a, b) {
    var _a$group, _b$group;
    return a === null || a === void 0 || (_a$group = a.group) === null || _a$group === void 0 ? void 0 : _a$group.label.localeCompare(b === null || b === void 0 || (_b$group = b.group) === null || _b$group === void 0 ? void 0 : _b$group.label);
  }).flatMap(function (option) {
    return [option.group].concat(_toConsumableArray(sortAlphabetic(option.items, 'label')));
  });
};
export var prepareGroupedSystemsOptions = function prepareGroupedSystemsOptions(list) {
  var groupedOptions = list.reduce(function (acc, item) {
    if (!acc[item.type]) {
      acc[item.type] = {
        label: getAdditionalTextByType(item.type),
        items: []
      };
    }
    acc[item.type].items.push(_objectSpread({}, item));
    return acc;
  }, {});
  return Object.values(groupedOptions).sort(function (a, b) {
    var _a$label;
    return a === null || a === void 0 || (_a$label = a.label) === null || _a$label === void 0 ? void 0 : _a$label.localeCompare(b === null || b === void 0 ? void 0 : b.label);
  }).map(function (option) {
    return _objectSpread(_objectSpread({}, option), {}, {
      items: sortAlphabetic(option.items, 'text')
    });
  });
};