import { ColorPickerColors, RowNumberByColor } from '@/constants/risks/RisksHeatMapColors';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RiskHeatMapSelector',
  description: 'Heat-map with possibility to select colors',
  token: '<risk-heat-map-selector :cells-dataset="dataset"/>',
  directives: {
    clickOutside: clickOutside
  },
  props: {
    cellsDataset: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of arrays of cells'
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Possibility to edit'
    }
  },
  emits: ['setColor'],
  data: function data() {
    return {
      ColorPickerColors: ColorPickerColors,
      selectedCellId: null,
      selectedColumn: null,
      selectedRow: null,
      RowNumberByColor: RowNumberByColor
    };
  },
  methods: {
    openColorSelector: function openColorSelector(rowIndex, colIndex) {
      if (this.isReadonly) return;
      this.selectedColumn = colIndex;
      this.selectedRow = rowIndex;
    },
    closeColorSelector: function closeColorSelector() {
      this.selectedColumn = null;
      this.selectedRow = null;
    },
    setColor: function setColor(value, color) {
      this.closeColorSelector();
      this.$emit('setColor', value, color);
    }
  }
};