function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getMyInitiatives as _getMyInitiatives, getMyDailyInitiatives as _getMyDailyInitiatives } from '@/api/execution/initiative';
import { MY_ACTIONS_VIEWS } from '@/constants/ViewTypes';
import { InitiativesViews } from '@/constants/InitiativesViews';
import { compareDates, comparePathStrings } from '@/utils/compare';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import GanttChartActions from '@/components/Molecules/Charts/GanttChartActions';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getAllChildrenIds } from '@/utils/Utils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'MyActions',
  introduction: 'List of my actions.',
  description: 'This is Page component for /actions/my/',
  token: '<my-actions></my-actions>',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    Notification: Notification,
    PreviewListTile: PreviewListTile,
    CatalogueHeader: CatalogueHeader,
    GanttChartActions: GanttChartActions,
    PreviewListExpanded: PreviewListExpanded
  },
  mixins: [CommonAssetsMixin],
  props: {
    isHeaderVisible: {
      required: false,
      type: Boolean,
      default: true,
      note: 'Display sorting and diplay view or not'
    },
    isOnlyMyInitiatives: {
      required: false,
      type: Boolean,
      default: false,
      note: 'to display only my actions, not groups'
    },
    viewMode: {
      required: false,
      type: String,
      default: '',
      note: 'if parent component have viewTypes and want to handle this component by them'
    }
  },
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('my_actions_display_view', InitiativesViews.TILES),
      sortBy: getLocalStorageComputed('my_actions_sort_by', 'ipath'),
      overviewOptionsState: getLocalStorageComputed('my_actions_overview', false, true)
    };
  },
  data: function data() {
    return {
      isLoaded: false,
      unSorted: true,
      MY_ACTIONS_VIEWS: MY_ACTIONS_VIEWS,
      InitiativesViews: InitiativesViews,
      updatedActionFromPreview: null,
      initiativesGroups: {},
      translate: getTranslate['MyActions']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), {}, {
    currentUserId: function currentUserId() {
      return this.currentUser.id;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'action');
    },
    sortOptions: function sortOptions() {
      return [{
        id: 1,
        label: this.translate.actionsNumber,
        value: 'ipath'
      }, {
        id: 2,
        label: this.translate.deadline,
        value: 'deadline'
      }];
    }
  }),
  watch: {
    viewMode: function viewMode() {
      this.setView(this.viewMode);
    }
  },
  beforeMount: function beforeMount() {
    this.initInitiativeGroups();
  },
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      _this.setSettings();
      _this.isLoaded = true;
    });
  },
  methods: {
    initInitiativeGroups: function initInitiativeGroups() {
      this.initiativesGroups = {
        myInitiatives: {
          id: 1,
          name: 'action',
          label: 'My actions',
          activities: [],
          hidden: false,
          view: this.currentView,
          cookieName: 'rismahider-my-initiatives'
        },
        myDailyTeamInitiatives: {
          id: 2,
          name: 'action',
          label: 'My daily team actions',
          activities: [],
          hidden: false,
          view: this.currentView,
          cookieName: 'rismahider-my-daily-initiatives'
        }
      };
    },
    setSettings: function setSettings() {
      var viewType = this.viewMode.length ? this.viewMode : this.currentView;
      this.setView(viewType);
      this.sortInitiatives(this.sortBy);
    },
    getData: function getData() {
      return Promise.all([this.getMyInitiatives(), this.getMyDailyInitiatives()]);
    },
    getMyInitiatives: function getMyInitiatives() {
      var _this2 = this;
      return _getMyInitiatives().then(function (_ref) {
        var list = _ref.list;
        _this2.populateMyInitiatives(list);
      });
    },
    getMyDailyInitiatives: function getMyDailyInitiatives() {
      var _this3 = this;
      if (!this.isOnlyMyInitiatives) {
        return _getMyDailyInitiatives().then(function (_ref2) {
          var list = _ref2.list;
          _this3.populateMyDailyTeamInitiatives(list);
        });
      }
    },
    populateMyInitiatives: function populateMyInitiatives(list) {
      this.initiativesGroups.myInitiatives.activities = list;
    },
    populateMyDailyTeamInitiatives: function populateMyDailyTeamInitiatives(list) {
      this.initiativesGroups.myDailyTeamInitiatives.activities = list;
    },
    sortInitiatives: function sortInitiatives(value) {
      var compareFn;
      if (value === 'ipath') {
        compareFn = comparePathStrings;
      } else if (value === 'deadline') {
        compareFn = compareDates;
      }
      this.sortBy = value;
      for (var key in this.initiativesGroups) {
        this.initiativesGroups[key].activities.sort(function (a, b) {
          return compareFn(a[value], b[value]);
        });
      }
    },
    setView: function setView(viewType) {
      this.currentView = viewType;
      this.updateActionsView();
    },
    updateActionsView: function updateActionsView() {
      for (var key in this.initiativesGroups) {
        this.initiativesGroups[key].view = this.currentView;
      }
    },
    updateFromPreviewMode: function updateFromPreviewMode(updatedAction, groupId) {
      var _Object$values$find;
      this.updatedActionFromPreview = updatedAction;
      var activities = (_Object$values$find = Object.values(this.initiativesGroups).find(function (group) {
        return group.id === groupId;
      })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.activities;
      if (!(activities !== null && activities !== void 0 && activities.length)) return;
      var updatedActionIndex = activities.findIndex(function (a) {
        return a.id == updatedAction.id;
      });
      if (updatedActionIndex === -1) return;
      activities[updatedActionIndex] = updatedAction;
    },
    onPreviewDismiss: function onPreviewDismiss() {
      if (!this.updatedActionFromPreview) return;
      if (this.updatedActionFromPreview.archived) {
        var idsToRemove = getAllChildrenIds(this.updatedActionFromPreview);
        Object.values(this.initiativesGroups).forEach(function (group) {
          group.activities = group.activities.filter(function (action) {
            return !idsToRemove.includes(action.id);
          });
        });
      }
      this.updatedActionFromPreview = null;
    }
  }
};