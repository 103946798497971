function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import RiskGrid from '@/components/Organisms/RiskGrid';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewRiskList from '@/components/Molecules/Risk/PreviewRiskList';
import RiskReportDatatable from './RiskReportDatatable';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
import { RiskListMixin } from '@/components/Pages/Risks/RiskListMixin';
import { ControlViews } from '@/constants/ControlViews';
import { RiskColorName, StatusColorMapping } from '@/constants/risks/RisksReports';
import { getDotTitleOnHover } from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
var InherentType = 1;
var ResidualType = 2;
export default {
  name: 'RiskReportGrid',
  introduction: '',
  description: '',
  token: '<risk-report-grid />',
  components: {
    RiskGrid: RiskGrid,
    RismaTitle: RismaTitle,
    PreviewRiskList: PreviewRiskList,
    RiskReportDatatable: RiskReportDatatable,
    RismaToggle: RismaToggle,
    ExportFrame: ExportFrame
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    },
    currentPeriod: {
      required: true,
      type: Object,
      note: 'Current risk period'
    },
    isCurrentPeriodSelected: {
      type: Boolean,
      required: false,
      default: true,
      note: 'If it is current period or scoring selected'
    },
    consequenceList: {
      required: true,
      type: Array,
      note: 'List of selected consequences'
    },
    risks: {
      required: true,
      type: Array,
      note: 'Array of risks (from /risks/reports)'
    },
    periods: {
      required: true,
      type: Array,
      note: 'Array of periods'
    },
    comparePeriod: {
      required: false,
      type: Object,
      default: null
    },
    previousPeriods: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    riskApprovalEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is Risk Approval feature enabled?'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'list of all selectable organisations'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk threats'
    },
    levelOfThreats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Level of threats'
    },
    vulnerabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    probabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    controls: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All controls, uses for linked activities'
    },
    initiatives: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All initiatives, uses for linked activities'
    },
    systems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All systems, uses for linked assets'
    },
    processLibraryNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All process library nodes, uses for linked assets'
    },
    complianceNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All compliance nodes, uses for linked assets'
    },
    incidents: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All incidents, uses for linked assets'
    },
    tags: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'tags to preview risk list to choose from'
    },
    matrixData: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of matrix data'
    },
    isErm: {
      type: Boolean,
      required: false,
      default: false
    },
    managementLevels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'managementLevels list'
    },
    riskTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All riskTypes, uses in risk table'
    },
    startColor: {
      type: String,
      required: true
    },
    endColor: {
      type: String,
      required: true
    },
    parentRisks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All parent risks'
    },
    customHeatmapOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    inherentRisksEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is inherent vs. residual risk enabled'
    },
    reportOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    showPreviousPositionArray: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['onPeriodChanged', 'dismissModal', 'updateFromPreviewMode', 'fetchSavedReport', 'togglePreviousPosition'],
  data: function data() {
    return {
      gridBoxSize: 80,
      riskGridData: {
        checked: false,
        colorCodes: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskColorName.GREEN, 'background-color: hsl(161, 100%, 34%)'), RiskColorName.LIGHTBLUE, 'background-color: hsl(187, 100%, 39%)'), RiskColorName.RISMABLUE, 'background-color: hsl(190, 100%, 28%)'), RiskColorName.RED, 'background-color: hsl(18, 98%, 40%)')
      },
      enableZoom: true,
      zoomRange: [80, 260],
      zoomStep: 20,
      controlViews: ControlViews,
      customFieldsDefault: {},
      statusColor: _objectSpread({}, StatusColorMapping),
      showInherentMatrixIds: {},
      matrixState: null,
      translate: getTranslate['RiskReportGrid']()
    };
  },
  computed: {
    filteredRisks: function filteredRisks() {
      if (!this.matrixState) return [];
      var result = [];
      var selectedMatrix = this.findSelected(this.matrixState);
      if (selectedMatrix.length) {
        this.risks.forEach(function (element) {
          return selectedMatrix.find(function (item) {
            return item.id == element.rno;
          }) ? result.push(element) : '';
        });
      }
      return result;
    },
    risksSelectedSections: function risksSelectedSections() {
      if (!this.matrixState || !this.filteredRisks.length) {
        return [{
          title: '',
          list: []
        }];
      }
      var risksSelected = RiskListMixin.methods.formatRisks(this.filteredRisks, this.threats, this.levelOfThreats, this.vulnerabilities, this.probabilities, this.controls, this.initiatives, this.currentPeriod);
      return [{
        title: '',
        list: risksSelected
      }];
    },
    labelNames: function labelNames() {
      return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskColorName.GREEN, this.translate.closed), RiskColorName.LIGHTBLUE, this.translate.notUpdated), RiskColorName.RISMABLUE, this.translate.updated), RiskColorName.RED, this.translate.new), RiskColorName.GREY, this.translate.notApproved);
    },
    preparedRiskGridData: function preparedRiskGridData() {
      var _this = this;
      return this.matrixData.map(function (matrix) {
        return _this.prepareRiskGridData(matrix);
      });
    }
  },
  mounted: function mounted() {
    this.updateInitSettings();
  },
  methods: {
    onShowInherentMatrixIds: function onShowInherentMatrixIds(matrixIndex) {
      this.$emit('togglePreviousPosition', {
        event: false,
        index: matrixIndex
      });
    },
    handleTogglePreviousPosition: function handleTogglePreviousPosition(evt, matrixIndex, id) {
      this.$emit('togglePreviousPosition', {
        event: evt,
        index: matrixIndex
      });
      this.showInherentMatrixIds[id] = false;
    },
    updateInitSettings: function updateInitSettings() {
      if (this.riskApprovalEnabled) {
        this.riskGridData.colorCodes[RiskColorName.GREY] = 'background-color: hsl(0, 0%, 60%)';
      } else {
        this.statusColor.not_approved = this.statusColor.updated;
      }
    },
    prevPeriods: function prevPeriods(consequenceId) {
      return {
        selected: this.comparePeriod[consequenceId] ? this.comparePeriod[consequenceId].id : this.currentPeriod.id,
        options: _toConsumableArray(this.previousPeriods)
      };
    },
    createConsequenceLabels: function createConsequenceLabels(consequenceId) {
      var result = [];
      var currentConsequenceRow = this.currentPeriod.consequenceRows.find(function (item) {
        return item.id === consequenceId;
      });
      if (currentConsequenceRow && currentConsequenceRow.options) {
        result = Object.values(currentConsequenceRow.options).map(function (item) {
          return {
            label: item.value
          };
        });
      }
      return result;
    },
    createProbabilityLabels: function createProbabilityLabels() {
      return this.currentPeriod.probabilityLabels.map(function (item) {
        return {
          label: item
        };
      });
    },
    prepareDots: function prepareDots(gridId) {
      var _this2 = this;
      var dots = [];
      this.risks.forEach(function (risk) {
        dots.push.apply(dots, _toConsumableArray(_this2.setDotValue(risk, gridId)));
      });
      return dots;
    },
    setDotValue: function setDotValue(risk, gridId) {
      var dotData = {
        id: risk.rno,
        color: this.statusColor[risk.gridStatus],
        status: risk.status,
        xBefore: risk._scores[gridId] && risk._scores[gridId].scoresPrevious.x,
        yBefore: risk._scores[gridId] && risk._scores[gridId].scoresPrevious.y
      };
      var result = [_objectSpread(_objectSpread({}, dotData), {}, {
        type: ResidualType,
        title: this.inherentRisksEnabled ? getDotTitleOnHover(risk.title, 'residual') : risk.title,
        x: risk._scores[gridId] && risk._scores[gridId].scores.x,
        y: risk._scores[gridId] && risk._scores[gridId].scores.y
      })];
      if (this.showInherentMatrixIds[gridId]) {
        result.push(_objectSpread(_objectSpread({}, dotData), {}, {
          type: InherentType,
          title: this.inherentRisksEnabled ? getDotTitleOnHover(risk.title, 'inherent') : risk.title,
          x: risk._scores[gridId] && risk._scores[gridId].scores.xInherent,
          y: risk._scores[gridId] && risk._scores[gridId].scores.yInherent
        }));
      }
      return result;
    },
    findSelected: function findSelected(matrix) {
      var result = [];
      matrix.forEach(function (row) {
        row.forEach(function (cell) {
          if (cell && cell.length && cell[0].isActive) {
            result = result.concat(cell.slice(1));
          }
        });
      });
      return result;
    },
    prepareRiskGridData: function prepareRiskGridData(data) {
      return _objectSpread(_objectSpread(_objectSpread({}, this.riskGridData), data), {}, {
        dots: this.prepareDots(data.id)
      });
    }
  }
};