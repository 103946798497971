function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ControlAddDeadlineModal from '@/components/Molecules/Controls/ControlAddDeadlineModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import * as ControlsAPI from '@/api/controls/controls';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { DateFormats } from '@/constants/DateFormats';
import { setFrequencies } from '@/utils/controls/utils';
import { getDateOptions } from '@/utils/date/getDateOptions';
import { compareDates } from '@/utils/compare';
import { MODULES } from '@/constants/modules';
import { getCurrentYear, getCurrentMonthIndex, calculateDifference, getMonthIndex as _getMonthIndex, formatCurrentDateWithCustomValues, formatTimeToDateTimeFormat, formatCurrentDateTime } from '@/utils/date';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
var AFTER_SELECTOR_FROM_VALUE = 'after';
var AFTER_SELECTOR_BEFORE_VALUE = 'before';
var FREQUENCY_API_KEY_DEADLINE = 'deadline';
var FREQUENCY_API_KEY_STARTDATE = 'startdate';
var FREQUENCY_API_KEY_DEADLINE_DAYS = 'deadlineDays';
var FREQUENCY_API_KEY_DEADLINE_BEFORE_AFTER = 'deadlineBeforeAfter';
var FREQUENCY_API_KEY_FREQ = 'freq';
var FREQUENCY_API_KEY_CONTROL_ID = 'controlId';
var FREQUENCY_API_KEY_TIMEZONE = 'timezone';
var MAX_WORKING_DAYS = 28;
export default {
  name: 'ControlFrequency',
  introduction: 'A component for manipulations with Control Frequency.',
  description: 'This is a Molecule component for /controls2/controls/:id. Parent component: ControlDetails',
  token: '<control-frequency :control="control"></control-frequency>',
  components: {
    RismaToggle: RismaToggle,
    SingleSelect: SingleSelect,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    ControlAddDeadlineModal: ControlAddDeadlineModal,
    FeatherIcon: FeatherIcon
  },
  props: {
    control: {
      type: Object,
      required: true,
      note: 'Main control data object.'
    },
    withoutPreview: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This prop is needed mainly for jest testing'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is could be values changes'
    },
    isDaughter: {
      type: Boolean,
      required: true,
      note: 'Is daughter control?'
    },
    reviewDaysSettingDefaultValue: {
      type: [Number, String],
      required: true,
      note: 'The default value for review days setting'
    }
  },
  emits: ['turnYellowChanged', 'changed', 'toggleInnerModal', 'updateControlDeadline', 'updateReviewOffset'],
  data: function data() {
    return {
      isLoading: false,
      storeFrequencyObj: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FREQUENCY_API_KEY_DEADLINE, ''), FREQUENCY_API_KEY_STARTDATE, ''), FREQUENCY_API_KEY_DEADLINE_DAYS, null), FREQUENCY_API_KEY_DEADLINE_BEFORE_AFTER, ''), FREQUENCY_API_KEY_FREQ, null), FREQUENCY_API_KEY_CONTROL_ID, null), FREQUENCY_API_KEY_TIMEZONE, null),
      deadlines: [],
      dateFormat: DateFormats.DATE_FORMAT,
      deadline: null,
      startDate: null,
      calculatedDeadline: false,
      frequencySelectedOption: {},
      workingDefaultDay: {
        id: 0,
        value: 0
      },
      workingDays: [],
      workingSelectedDay: {},
      unSorted: true,
      afterOptions: [],
      afterSelectedOption: {},
      trafficLightIntervalAsString: '',
      trafficLightIntervalValueSelected: '',
      trafficLightIntervalOptions: [],
      translate: getTranslate['ControlFrequency'](),
      showAddDeadlineModal: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    changeFrequencyMessage: function changeFrequencyMessage() {
      return [this.translate.youAreInTheProcessOfDeletingDeadlineDate, this.translate.toContinuePressOk];
    },
    allOptions: function allOptions() {
      return setFrequencies(this.getSettingValue, MODULES.CONTROLS);
    },
    isCustomMode: function isCustomMode() {
      return this.control.frequency === RismaControlsUtils.frequencies.FREQ_CUSTOM;
    },
    optionsFiltered: function optionsFiltered() {
      return this.allOptions.filter(function (item) {
        return item.value === RismaControlsUtils.frequencies.FREQ_MONTHLY || item.value === RismaControlsUtils.frequencies.FREQ_QUARTERLY;
      });
    },
    frequencyOptions: function frequencyOptions() {
      return this.control.calculatedDeadline ? this.optionsFiltered : this.allOptions;
    },
    showFrequencyPreview: function showFrequencyPreview() {
      return (this.control.isMothercontrol !== 1 || this.control.frequency === RismaControlsUtils.frequencies.FREQ_CUSTOM) && this.control.frequency !== RismaControlsUtils.frequencies.FREQ_ONE_TIME;
    },
    deadlinesToDisplay: function deadlinesToDisplay() {
      var deadlines = this.deadlines.slice(0).map(function (dl) {
        dl.deadline = formatTimeToDateTimeFormat(dl.deadline);
        return dl;
      });
      if (this.control.frequency !== RismaControlsUtils.frequencies.FREQ_CUSTOM) {
        deadlines.unshift({
          deadline: this.control.deadline
        });
      }
      return deadlines.sort(function (date1, date2) {
        return compareDates(date1.deadline, date2.deadline);
      });
    },
    controlHasReviewer: function controlHasReviewer() {
      var _this$control$userIds;
      return !!((_this$control$userIds = this.control.userIds) !== null && _this$control$userIds !== void 0 && _this$control$userIds.review.length);
    },
    isFrequencySelectDisabled: function isFrequencySelectDisabled() {
      return this.disabled || this.isCustomMode && !!this.deadlines.length;
    },
    optionsDaysBetweenDeadlineAndReview: function optionsDaysBetweenDeadlineAndReview() {
      var _this$control$motherc;
      var options = getDateOptions('day', 'days', this.$trans);
      var defaultValue = this.reviewDaysSettingDefaultValue;
      if ((_this$control$motherc = this.control.mothercontrol) !== null && _this$control$motherc !== void 0 && _this$control$motherc.reviewOffset) {
        defaultValue = this.control.mothercontrol.reviewOffset;
      }
      return [{
        value: null,
        label: "".concat(this.$trans('Default'), " (").concat(defaultValue, " ").concat(defaultValue > 1 ? this.$trans('days') : this.$trans('day'), ")")
      }, {
        value: 0,
        label: '0 ' + this.$trans('days')
      }].concat(_toConsumableArray(options));
    }
  }),
  watch: {
    'control.deadline': function controlDeadline() {
      this.updateStoreFrequency();
      this.getFrequencyPreview();
    }
  },
  mounted: function mounted() {
    this.getAll();
  },
  methods: {
    getAll: function getAll() {
      this.initTrafficLightTurnsYellow();
      this.workingDays = this.prepareWorkingDays(MAX_WORKING_DAYS);
      this.afterOptions = this.prepareAfterOptions();

      // Default 'Daily' when empty
      if (!this.control.frequency) {
        this.control.frequency = RismaControlsUtils.frequencies.FREQ_DAILY;
      }
      this.getAllSelectedInitial();
      this.updateStoreFrequency();
      this.getFrequencyPreview();
    },
    initTrafficLightTurnsYellow: function initTrafficLightTurnsYellow() {
      this.fillTrafficLightIntervalOptions();
      this.setTrafficLightIntervalValueSelected();
      var selected = this.getTrafficLightIntervalOptionSelected();
      this.trafficLightIntervalAsString = selected.label;
    },
    getTrafficLightIntervalOptionSelected: function getTrafficLightIntervalOptionSelected() {
      var _this = this;
      var selected = this.trafficLightIntervalOptions.filter(function (item) {
        return item.value === _this.control.turnYellowDaysBeforeDeadLine;
      })[0];
      return selected ? selected : {};
    },
    setTrafficLightIntervalValueSelected: function setTrafficLightIntervalValueSelected() {
      var selected = this.getTrafficLightIntervalOptionSelected();
      this.trafficLightIntervalValueSelected = selected.value || '';
    },
    fillTrafficLightIntervalOptions: function fillTrafficLightIntervalOptions() {
      this.trafficLightIntervalOptions = getDateOptions('day before deadline', 'days before deadline', this.$trans);
    },
    onSelectTrafficLight: function onSelectTrafficLight(selectedValue) {
      this.trafficLightIntervalValueSelected = selectedValue ? selectedValue : 1;
      this.$emit('turnYellowChanged', selectedValue);
    },
    onSelectReviewOffset: function onSelectReviewOffset(selectedValue) {
      this.$emit('updateReviewOffset', {
        reviewOffset: selectedValue
      });
    },
    getAllSelectedInitial: function getAllSelectedInitial() {
      this.frequencySelectedOption = this.findFrequencySelected(this.control.frequency);
      this.calculatedDeadline = this.isCalcDeadlineActive();
      this.workingSelectedDay = this.findWorkingSelected();
      this.afterSelectedOption = this.findAfterSelected();
    },
    getFrequencyPreview: function getFrequencyPreview() {
      return this.makeApiCall(this.storeFrequencyObj);
    },
    updateStoreFrequency: function updateStoreFrequency() {
      this.deadline = this.findDeadline();
      this.startDate = this.findStartDate(this.calculatedDeadline);
      this.storeFrequencyObj = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FREQUENCY_API_KEY_DEADLINE, this.deadline), FREQUENCY_API_KEY_STARTDATE, this.startDate), FREQUENCY_API_KEY_DEADLINE_DAYS, this.workingSelectedDay.value), FREQUENCY_API_KEY_DEADLINE_BEFORE_AFTER, this.afterSelectedOption.value), FREQUENCY_API_KEY_FREQ, this.frequencySelectedOption.value), FREQUENCY_API_KEY_CONTROL_ID, this.control.id), FREQUENCY_API_KEY_TIMEZONE, this.control.deadlineTimezone);
    },
    handleCalculateAfterUpdates: function handleCalculateAfterUpdates() {
      var _this2 = this;
      this.updateStoreFrequency();
      this.getFrequencyPreview().then(function () {
        return _this2.baseChange();
      });
    },
    makeApiCall: function makeApiCall(obj) {
      var _this3 = this;
      if (!obj) {
        return;
      }
      this.isLoading = true;
      return ControlsAPI.getDeadlines(_objectSpread(_objectSpread({}, obj), {}, {
        format: 'object'
      })).then(function (data) {
        _this3.deadlines = data.list;
      }).finally(function () {
        _this3.isLoading = false;
      });
    },
    onCalculateDeadlineChange: function onCalculateDeadlineChange(data) {
      var _this4 = this;
      var frequencyHasToBeUpdated = this.control.frequency !== RismaControlsUtils.frequencies.FREQ_MONTHLY && this.control.frequency !== RismaControlsUtils.frequencies.FREQ_QUARTERLY;

      // If frequency !== Monthly or Quarterly
      if (data && frequencyHasToBeUpdated) {
        this.$emit('toggleInnerModal', true);
        this.$confirm('', this.translate.areYouSureYouWantTo, function (res) {
          if (res) _this4.handleCalculateDeadlineChangeLastSteps(data, true);
          _this4.$emit('toggleInnerModal', false);
        });
      } else {
        this.handleCalculateDeadlineChangeLastSteps(data);
      }
    },
    handleCalculateDeadlineChangeLastSteps: function handleCalculateDeadlineChangeLastSteps(data, setDefaultFrequency) {
      if (setDefaultFrequency) this.setDefaultFrequency();
      this.control.calculatedDeadline = data;

      // Recalculates
      this.workingSelectedDay = this.findWorkingSelected(true);
      this.afterSelectedOption = this.findAfterSelected(true);
      this.updateStoreFrequency();
      this.getFrequencyPreview();
      this.baseChange();
    },
    findFrequencySelected: function findFrequencySelected(frequency) {
      return this.allOptions.filter(function (item) {
        return item.value === frequency;
      })[0];
    },
    findDeadline: function findDeadline() {
      return this.control.deadline;
    },
    findStartDate: function findStartDate(calculated) {
      var result = this.control.startdate; // Default from Control model

      if (calculated) {
        var currentYear = getCurrentYear();
        var month = this.getMonthIndex();
        var initialDate = 1;
        result = formatCurrentDateWithCustomValues(currentYear, month, initialDate, DateFormats.DATE_TIME_FORMAT_FULL);
      }
      return result;
    },
    findWorkingSelected: function findWorkingSelected(calculateChanged) {
      var _this5 = this;
      var result = [];
      if (this.calculatedDeadline) {
        result = Object.assign({}, this.workingDays[0]);
        if (calculateChanged) {
          return result;
        }

        // If present in Control
        if (this.control.deadlineDays) {
          result = this.workingDays.filter(function (item) {
            return item.value === _this5.control.deadlineDays;
          })[0];
        }
      } else {
        // Always restore to workingDefaultDay.value === 0;
        result = Object.assign({}, this.workingDefaultDay);
      }
      return result;
    },
    findAfterSelected: function findAfterSelected(calculateChanged) {
      var _this6 = this;
      var defaultOption = Object.assign({}, this.afterOptions[1]);
      var result = defaultOption; // Default: before

      if (calculateChanged) {
        return result;
      }
      result = this.afterOptions.filter(function (item) {
        return item.value === _this6.control.deadlineBeforeAfter;
      })[0];

      // Fallback if this.control.deadlineBeforeAfter === null
      if (!result) {
        result = defaultOption;
      }
      return result;
    },
    getMonthIndex: function getMonthIndex(calculateChanged) {
      var monthIndex = getCurrentMonthIndex();
      if (!calculateChanged) {
        if (this.calculatedDeadline) {
          monthIndex = _getMonthIndex(this.control.startdate);
        }
      }
      return monthIndex;
    },
    setDefaultFrequency: function setDefaultFrequency() {
      this.control.frequency = RismaControlsUtils.frequencies.FREQ_MONTHLY;
      this.frequencySelectedOption = this.findFrequencySelected(this.control.frequency);
    },
    isCalcDeadlineActive: function isCalcDeadlineActive() {
      return this.control.calculatedDeadline;
    },
    prepareWorkingDays: function prepareWorkingDays(n) {
      return Array.from(new Array(n), function (val, index) {
        return index + 1;
      }).map(function (item) {
        return {
          label: item + '',
          value: item
        };
      });
    },
    prepareAfterOptions: function prepareAfterOptions() {
      return [{
        id: 1,
        label: this.translate.fromTheBeginningOfTheMonth,
        value: AFTER_SELECTOR_FROM_VALUE
      }, {
        id: 2,
        label: this.translate.beforeTheEndOfTheMonth,
        value: AFTER_SELECTOR_BEFORE_VALUE
      }];
    },
    onSelectFrequency: function onSelectFrequency(changed) {
      var selected = this.frequencyOptions.find(function (option) {
        return option.value === changed;
      });
      if (selected) {
        this.control.frequency = changed;
        this.frequencySelectedOption = selected;
      } else {
        this.setDefaultFrequency();
      }
      this.updateStoreFrequency();
      this.getFrequencyPreview();
      this.baseChange();
    },
    onSelectWorkingDay: function onSelectWorkingDay(changed) {
      var selected = this.workingDays.find(function (option) {
        return option.value === changed;
      });
      if (selected) {
        this.workingSelectedDay = selected;
      } else {
        this.workingSelectedDay = this.workingDays[0];
      }
      this.handleCalculateAfterUpdates();
    },
    onSelectAfter: function onSelectAfter(changed) {
      var selected = this.afterOptions.find(function (option) {
        return option.value === changed;
      });
      if (selected) {
        this.afterSelectedOption = selected;
      }
      this.handleCalculateAfterUpdates();
    },
    baseChange: function baseChange() {
      var data = {
        calculatedDeadline: this.calculatedDeadline,
        deadlineDays: this.workingSelectedDay.value,
        deadlineBeforeAfter: this.afterSelectedOption.value,
        frequency: this.frequencySelectedOption.value,
        startdate: this.startDate
      };
      this.$emit('changed', data);
    },
    onCustomDeadlineDelete: function onCustomDeadlineDelete(id) {
      var _this7 = this;
      if (this.deadlines.length > 1) {
        this.deleteDeadlines([id]);
      } else {
        this.$confirm(this.translate.warning, this.changeFrequencyMessage, function (res) {
          return res && _this7.changeFrequencyToOneTime();
        }, {
          buttonOkText: this.translate.ok,
          buttonDismissText: this.translate.cancel
        });
        this.$emit('toggleInnerModal', true);
      }
    },
    onDeadlinesChange: function onDeadlinesChange(newDeadlineDates) {
      var deadlineIds = [];
      var newDeadlinesToCreate = [];
      var deadlinesToDelete = [];
      if (this.deadlines.length && !newDeadlineDates.length) {
        this.toggleChangeFrequencyModal();
        return;
      }
      newDeadlineDates.forEach(function (obj) {
        obj.id ? deadlineIds.push(obj.id) : newDeadlinesToCreate.push(obj.deadline);
      });
      this.deadlines.forEach(function (obj) {
        if (!deadlineIds.includes(obj.id)) {
          deadlinesToDelete.push(obj.id);
        }
      });
      if (newDeadlinesToCreate.length) {
        this.createDeadlines(newDeadlinesToCreate);
      }
      if (deadlinesToDelete.length) {
        this.deleteDeadlines(deadlinesToDelete);
      }
    },
    createDeadlines: function createDeadlines(deadlines) {
      var _this8 = this;
      var promises = [];
      deadlines.forEach(function (deadline) {
        promises.push(_this8.createDeadline(deadline));
      });
      return Promise.all(promises).then(function (newDeadlines) {
        var _this8$deadlines;
        (_this8$deadlines = _this8.deadlines).push.apply(_this8$deadlines, _toConsumableArray(newDeadlines));
        _this8.$nextTick(function () {
          _this8.afterDeadlinesChange();
        });
      });
    },
    createDeadline: function createDeadline(deadline) {
      return ControlsAPI.createCustomDeadline({
        controlId: this.control.id,
        deadline: new Date(deadline).toUTCString()
      });
    },
    deleteDeadlines: function deleteDeadlines(idsToDelete) {
      var _this9 = this;
      var promises = [];
      idsToDelete.forEach(function (id) {
        promises.push(_this9.deleteDeadline(id));
      });
      return Promise.all(promises).then(function () {
        _this9.deadlines = _this9.deadlines.filter(function (_ref) {
          var id = _ref.id;
          return !idsToDelete.includes(id);
        });
        _this9.$nextTick(function () {
          _this9.afterDeadlinesChange();
        });
      });
    },
    afterDeadlinesChange: function afterDeadlinesChange() {
      if (!this.deadlines.length) return;
      var firstDeadline = this.deadlinesToDisplay[0].deadline;
      if (calculateDifference(firstDeadline, this.control.deadline) !== 0) {
        this.$emit('updateControlDeadline', firstDeadline);
      }
    },
    changeFrequencyToOneTime: function changeFrequencyToOneTime() {
      if (this.deadlines.length) {
        this.deleteAllCustomDeadlines();
      }
      this.onSelectFrequency(RismaControlsUtils.frequencies.FREQ_ONE_TIME);
      this.$emit('updateControlDeadline', formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT));
      this.$emit('toggleInnerModal', false);
    },
    deleteAllCustomDeadlines: function deleteAllCustomDeadlines() {
      var deadlinesToDelete = [];
      this.deadlines.forEach(function (obj) {
        deadlinesToDelete.push(obj.id);
      });
      this.deleteDeadlines(deadlinesToDelete);
    },
    deleteDeadline: function deleteDeadline(id) {
      return ControlsAPI.deleteCustomDeadline(id);
    },
    toggleAddDeadlineModal: function toggleAddDeadlineModal() {
      this.showAddDeadlineModal = !this.showAddDeadlineModal;
      this.$emit('toggleInnerModal', this.showAddDeadlineModal);
    }
  }
};