import { getNodeVersions as _getNodeVersions } from '@/api/compliance/node';
import Modal from '@/components/Molecules/Modal/Modal';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import ExpansionPanel from '@/components/Atoms/ExpansionPanel/ExpansionPanel';
import { toLocalDate } from '@/utils/date';
export default {
  name: 'ComplianceCsrdVersions',
  introduction: 'ComplianceCsrdVersions to display previous versions for csrd nodes',
  token: '<compliance-csrd-versions />',
  components: {
    Modal: Modal,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ExpansionPanel: ExpansionPanel
  },
  props: {
    node: {
      type: Object,
      required: true,
      default: null
    },
    statusLabel: {
      type: String,
      required: false,
      default: 'Report drafts'
    }
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      isLoading: false,
      showVersionsModal: false,
      nodeVersions: [],
      translate: {
        previous: this.$trans('Previous'),
        listIsEmpty: this.$trans('List is empty'),
        for: this.$trans('for')
      }
    };
  },
  computed: {
    modalHeader: function modalHeader() {
      return this.statusTitle + " ".concat(this.translate.for, " ") + this.node.title;
    },
    statusTitle: function statusTitle() {
      return this.translate.previous + ' ' + this.statusLabel.toLocaleLowerCase();
    }
  },
  methods: {
    openModal: function openModal() {
      this.showVersionsModal = true;
      if (this.nodeVersions.length) return;
      this.getNodeVersions();
    },
    getNodeVersions: function getNodeVersions() {
      var _this = this;
      this.isLoading = true;
      return _getNodeVersions(this.node.id).then(function (_ref) {
        var list = _ref.list;
        _this.nodeVersions = list;
      }).finally(function () {
        return _this.isLoading = false;
      });
    },
    formatVersionLabel: function formatVersionLabel(label) {
      return toLocalDate(label);
    }
  }
};