var _RowNumberByColor;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var COLORS = {
  GREEN_1: '#7DDE70',
  GREEN_2: '#61C256',
  GREEN_3: '#43A63C',
  GREEN_4: '#228B22',
  YELLOW_1: '#EFF25F',
  YELLOW_2: '#ECEC37',
  YELLOW_3: '#FFE141',
  YELLOW_4: '#FDDA25',
  ORANGE_1: '#ECB937',
  ORANGE_2: '#FD9125',
  ORANGE_3: '#FF8000',
  ORANGE_4: '#FF6B00',
  RED_1: '#E11717',
  RED_2: '#CE2D2D',
  RED_3: '#C11414',
  RED_4: '#A11111',
  BLUE_1: '#9EE3F9',
  BLUE_2: '#9ED3F9',
  BLUE_3: '#9EC2F9',
  BLUE_4: '#2D7EF6'
};
export var DefaultHeatMapColors = [COLORS.GREEN_4, COLORS.GREEN_3, COLORS.GREEN_2, COLORS.GREEN_1, COLORS.YELLOW_1, COLORS.YELLOW_2, COLORS.YELLOW_4, COLORS.ORANGE_2, COLORS.ORANGE_4, COLORS.RED_2, COLORS.RED_4];
export var ColorPickerColors = [[COLORS.GREEN_1, COLORS.YELLOW_1, COLORS.ORANGE_1, COLORS.RED_1, COLORS.BLUE_1], [COLORS.GREEN_2, COLORS.YELLOW_2, COLORS.ORANGE_2, COLORS.RED_2, COLORS.BLUE_2], [COLORS.GREEN_3, COLORS.YELLOW_3, COLORS.ORANGE_3, COLORS.RED_3, COLORS.BLUE_3], [COLORS.GREEN_4, COLORS.YELLOW_4, COLORS.ORANGE_4, COLORS.RED_4, COLORS.BLUE_4]];
export var RowNumberByColor = (_RowNumberByColor = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_RowNumberByColor, COLORS.GREEN_1, '1'), COLORS.GREEN_2, '2'), COLORS.GREEN_3, '3'), COLORS.GREEN_4, '4'), COLORS.YELLOW_1, '1'), COLORS.YELLOW_2, '2'), COLORS.YELLOW_3, '3'), COLORS.YELLOW_4, '4'), COLORS.ORANGE_1, '1'), COLORS.ORANGE_2, '2'), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_RowNumberByColor, COLORS.ORANGE_3, '3'), COLORS.ORANGE_4, '4'), COLORS.RED_1, '1'), COLORS.RED_2, '2'), COLORS.RED_3, '3'), COLORS.RED_4, '4'), COLORS.BLUE_1, '1'), COLORS.BLUE_2, '2'), COLORS.BLUE_3, '3'), COLORS.BLUE_4, '4'));