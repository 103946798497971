import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_node = _resolveComponent("node");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_node, null, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("p", null, "This is a node", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  })]);
}