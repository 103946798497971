function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = ["onClick"];
var _hoisted_2 = {
  key: 0,
  class: "inline-flex flex-wrap justify-center"
};
var _hoisted_3 = {
  class: "hidden absolute bg-white p border border-black z-20 text-black text-0.7rem min-w-110px group-hover:block"
};
var _hoisted_4 = {
  key: 1,
  class: "check-icon-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_dot = _resolveComponent("dot");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_dot_connector = _resolveComponent("dot-connector");
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($options.gridStyle),
    class: _normalizeClass([{
      'flex-container-checked': $props.checked
    }, "grid table-fixed flex-container relative"])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.reverseGrid, function (row, rowKey) {
    return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, function (column, colKey) {
      return _openBlock(), _createElementBlock("div", {
        key: colKey + 'x' + rowKey,
        style: _normalizeStyle(_objectSpread({
          'background-color': $options.colorMap(rowKey, colKey)
        }, $options.cellStyle)),
        class: "cell flex-item relative text-center font-bold m-auto text-white",
        onClick: function onClick($event) {
          return $options.onClickCell(rowKey, colKey);
        }
      }, [!$props.checked ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column, function (dot, key) {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: "".concat(key, "-column")
        }, [$options.isDotVisible(key) ? (_openBlock(), _createBlock(_component_dot, {
          key: 0,
          dot: dot,
          "data-id": dot.id,
          "data-type": dot.type,
          "color-codes": $props.colorCodes,
          "dot-label-hover-function": $props.dotLabelHoverFunction
        }, null, 8 /* PROPS */, ["dot", "data-id", "data-type", "color-codes", "dot-label-hover-function"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
      }), 128 /* KEYED_FRAGMENT */)), $options.isCellsOverflow(column) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle("width: ".concat($data.dotSize, "px")),
        class: "show-more-dots relative group cursor-pointer text-center inline-block m-0"
      }, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "...", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column, function (dot, key) {
        return _openBlock(), _createElementBlock("div", {
          key: "".concat(key, "-text")
        }, [!$options.isDotVisible(key) ? (_openBlock(), _createElementBlock("div", {
          key: key
        }, [_createTextVNode(_toDisplayString($props.dotLabelHoverFunction ? $props.dotLabelHoverFunction(dot) : "".concat(dot.id, " ").concat(dot.title)) + " ", 1 /* TEXT */), _createVNode(_component_dot, {
          class: "invisible absolute top-0 left-0",
          dot: dot,
          "data-id": dot.id,
          "data-type": dot.type,
          "color-codes": $props.colorCodes,
          "dot-label-hover-function": $props.dotLabelHoverFunction
        }, null, 8 /* PROPS */, ["dot", "data-id", "data-type", "color-codes", "dot-label-hover-function"])])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */))])], 4 /* STYLE */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $data.stateGrid[rowKey] && $data.stateGrid[rowKey][colKey] && $data.stateGrid[rowKey][colKey].length && $props.isGridClickable ? (_openBlock(), _createElementBlock("div", _hoisted_4, [$data.stateGrid[rowKey][colKey][0].isActive ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        style: _normalizeStyle($options.checkIconMove),
        class: "stroke-3 absolute",
        icon: "check"
      }, null, 8 /* PROPS */, ["style"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 12 /* STYLE, PROPS */, _hoisted_1);
    }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */)), $props.dotConnectorEnabled ? (_openBlock(), _createBlock(_component_dot_connector, {
    key: 0,
    class: "absolute top-0",
    "dots-dom-elements": $data.dotsDomElements,
    width: $options.gridSize.width,
    height: $options.gridSize.height
  }, null, 8 /* PROPS */, ["dots-dom-elements", "width", "height"])) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */);
}