import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import { MENU_OPTION_TYPES, MENU_BUTTON_TYPES } from '@/components/Atoms/ToggleMenu/toggleMenuTypes';
export default {
  components: {
    ToggleMenu: ToggleMenu
  },
  data: function data() {
    return {
      toggleMenuOptions: [{
        icon: 'plus',
        title: 'Add new',
        action: function action() {
          return alert('plus button clicked');
        }
      }, {
        title: 'disabled element',
        hidden: true
      }, {
        title: 'title without an icon',
        action: function action() {
          return alert('"title without an icon" clicked');
        }
      }, {
        icon: 'x',
        title: 'Delete item',
        type: MENU_OPTION_TYPES.DANGER,
        action: function action() {
          return alert('x button clicked');
        }
      }],
      MENU_BUTTON_TYPES: MENU_BUTTON_TYPES
    };
  }
};