import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-5 pt-3 pb-6 border-2 border-gray-350 rounded-md"
};
var _hoisted_2 = {
  class: "flex justify-between mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.title
  }, null, 8 /* PROPS */, ["title"]), $options.canCreate ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "plus",
    height: "30",
    width: "30",
    class: "cursor-pointer stroke-2 text-blue-750",
    onClick: _withModifiers($options.toggleCreateModal, ["stop"])
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), $data.showCreateModal ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
    key: 0,
    type: $props.typeId,
    "activity-type-id": $options.activityType && $options.activityType.id,
    "default-questionnaire-id": $options.activityType && $options.activityType.defaultQuestionnaireId || '',
    onDismiss: $options.toggleCreateModal,
    onCreated: $options.createIA
  }, null, 8 /* PROPS */, ["type", "activity-type-id", "default-questionnaire-id", "onDismiss", "onCreated"])) : _createCommentVNode("v-if", true), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $data.translate.processors,
    "state-key": $options.tableStateKey,
    "show-search-field": true,
    api: $options.getDataBE,
    onDelete: function onDelete() {}
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api"]))]);
}