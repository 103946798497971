import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "main-well"
};
var _hoisted_2 = {
  class: "main-well-content"
};
var _hoisted_3 = {
  class: "mb-8 w-1/4 pr-5"
};
var _hoisted_4 = {
  key: 0,
  class: "mb-8"
};
var _hoisted_5 = {
  class: "status-section-wrap"
};
var _hoisted_6 = {
  key: 1,
  class: "mb-8"
};
var _hoisted_7 = {
  class: "description-section-wrap"
};
var _hoisted_8 = {
  key: 2,
  class: "mb-8"
};
var _hoisted_9 = {
  class: "target-section-wrap"
};
var _hoisted_10 = {
  key: 3,
  class: "mb-8 numerical-section"
};
var _hoisted_11 = {
  key: 0,
  class: "numerical-header-wrap"
};
var _hoisted_12 = {
  class: "add-numerical-unit"
};
var _hoisted_13 = {
  key: 0,
  class: "add-numerical-unit-pop-up"
};
var _hoisted_14 = {
  class: "add-numerical-unit-list"
};
var _hoisted_15 = {
  key: 0
};
var _hoisted_16 = {
  key: 1
};
var _hoisted_17 = {
  key: 2
};
var _hoisted_18 = {
  key: 3
};
var _hoisted_19 = {
  class: "numerical-items"
};
var _hoisted_20 = {
  key: 0,
  class: "numerical-units-line"
};
var _hoisted_21 = {
  class: "numerical-item-title-wrap"
};
var _hoisted_22 = {
  class: "numerical-item-title-date"
};
var _hoisted_23 = {
  class: "numerical-item-unit"
};
var _hoisted_24 = {
  class: "numerical-item-total"
};
var _hoisted_25 = {
  class: "numerical-item-total-number"
};
var _hoisted_26 = {
  class: "inherit-switch-wrap"
};
var _hoisted_27 = {
  key: 4,
  class: "mb-8 project-status-section"
};
var _hoisted_28 = {
  class: "flex items-center w-1/4 pr-1.5"
};
var _hoisted_29 = {
  key: 5,
  class: "mb-8"
};
var _hoisted_30 = {
  class: "comments-section-wrap"
};
var _hoisted_31 = {
  key: 6
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_action_change_dates_modal = _resolveComponent("action-change-dates-modal");
  var _component_action_update_sequence_number_modal = _resolveComponent("action-update-sequence-number-modal");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_notification = _resolveComponent("notification");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_attachment_selector = _resolveComponent("attachment-selector");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [$data.showDatesModal ? (_openBlock(), _createBlock(_component_action_change_dates_modal, {
    key: 0,
    "original-start": $props.data.start,
    "original-deadline": $props.data.deadline,
    "date-time-options": $data.dateTimeOptions,
    "initiative-id": $props.data.id,
    "children-number": $props.data.children.length,
    onMoveInitiatives: $options.moveInitiatives,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDatesModal = false;
    })
  }, null, 8 /* PROPS */, ["original-start", "original-deadline", "date-time-options", "initiative-id", "children-number", "onMoveInitiatives"])) : _createCommentVNode("v-if", true), $data.showSequenceNumberModal ? (_openBlock(), _createBlock(_component_action_update_sequence_number_modal, {
    key: 1,
    initiative: $props.data,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showSequenceNumberModal = false;
    })
  }, null, 8 /* PROPS */, ["initiative"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [$options.showToggleMenu && !$options.isUserLocked ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    options: $options.toggleMenuOptions,
    class: "toggle-menu"
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true), _createCommentVNode(" Transfer notifications up, to ActionEntry.vue when ready "), _createElementVNode("div", null, [false ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: true,
    class: "notification",
    type: "warning"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.exceedsTheDeadlineOfTheOverlyingInitiative), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), false ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    closeable: true,
    class: "notification",
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.warningAndUnderlyingInitiativeIsBehindSchedule), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_date_time_picker, {
    "model-value": _ctx.start,
    "plugin-options": $data.dateTimeOptions,
    disabled: $options.inputsDisabled,
    title: $data.translate.startDate,
    class: "mb-4",
    onValueUpdated: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.start = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "plugin-options", "disabled", "title"]), _createVNode(_component_date_time_picker, {
    "model-value": _ctx.deadline,
    "plugin-options": $data.dateTimeOptions,
    disabled: $options.inputsDisabled,
    title: $data.translate.deadline,
    onValueUpdated: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.deadline = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "plugin-options", "disabled", "title"])]), $options.isFieldInOptionalFields('status') ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_tiny_mce_box, {
    "editor-wrapper-class": "mt-1",
    headline: $data.translate.presentStatus,
    height: 150,
    "always-open": true,
    "initial-content": _ctx.status,
    "read-only": $options.inputsDisabled,
    type: $data.TinyMceBoxTypes.COMMENT,
    onChanged: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.status = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only", "type"])]), _createVNode(_component_attachment_selector, {
    "attachments-existed": $data.attachments.status,
    "owner-id": 1,
    "module-id": $props.data.id,
    removable: !$options.inputsDisabled,
    "read-only": $options.inputsDisabled,
    multiple: true,
    "show-title": false,
    field: "status",
    module: "execution",
    class: "mt-2",
    onUpdatedFiles: $options.handleFileChange
  }, null, 8 /* PROPS */, ["attachments-existed", "module-id", "removable", "read-only", "onUpdatedFiles"])])) : _createCommentVNode("v-if", true), $options.isFieldInOptionalFields('description') ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_tiny_mce_box, {
    "editor-wrapper-class": "mt-1",
    headline: $data.translate.description,
    height: 150,
    "always-open": true,
    "initial-content": _ctx.description,
    "read-only": $options.inputsDisabled,
    type: $data.TinyMceBoxTypes.COMMENT,
    onChanged: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.description = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only", "type"])]), _createVNode(_component_attachment_selector, {
    "attachments-existed": $data.attachments.description,
    "owner-id": 1,
    "module-id": $props.data.id,
    removable: !$options.inputsDisabled,
    "read-only": $options.inputsDisabled,
    multiple: true,
    "show-title": false,
    field: "description",
    module: "execution",
    class: "mt-2",
    onUpdatedFiles: $options.handleFileChange
  }, null, 8 /* PROPS */, ["attachments-existed", "module-id", "removable", "read-only", "onUpdatedFiles"])])) : _createCommentVNode("v-if", true), $options.isFieldInOptionalFields('target') ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_tiny_mce_box, {
    "editor-wrapper-class": "mt-1",
    headline: $data.translate.target,
    height: 150,
    "always-open": true,
    "initial-content": _ctx.target,
    "read-only": $options.inputsDisabled,
    type: $data.TinyMceBoxTypes.COMMENT,
    onChanged: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.target = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only", "type"])]), _createVNode(_component_attachment_selector, {
    "attachments-existed": $data.attachments.target,
    "owner-id": 1,
    "module-id": $props.data.id,
    removable: !$options.inputsDisabled,
    "read-only": $options.inputsDisabled,
    multiple: true,
    "show-title": false,
    field: "target",
    module: "execution",
    class: "mt-2",
    onUpdatedFiles: $options.handleFileChange
  }, null, 8 /* PROPS */, ["attachments-existed", "module-id", "removable", "read-only", "onUpdatedFiles"])])) : _createCommentVNode("v-if", true), $options.isFieldInOptionalFields('numerical_units') ? (_openBlock(), _createElementBlock("div", _hoisted_10, [!$options.inputsDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.numericalUnits,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_12, [$data.showAddNumericalUnitPopUp ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $data.translate.addNumericalUnit,
    class: "add-numerical-unit-title",
    type: "small"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("ul", _hoisted_14, [!$data.numericalUnits[0].show ? (_openBlock(), _createElementBlock("li", _hoisted_15, [_createElementVNode("a", {
    href: "",
    onClick: _cache[7] || (_cache[7] = _withModifiers(function ($event) {
      return $options.toggleShowNumericalUnit(0);
    }, ["prevent", "stop"]))
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: "lock",
    color: "Colors.primaryDark"
  }), _createTextVNode(" " + _toDisplayString($data.translate.budget), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), !$data.numericalUnits[1].show ? (_openBlock(), _createElementBlock("li", _hoisted_16, [_createElementVNode("a", {
    href: "",
    onClick: _cache[8] || (_cache[8] = _withModifiers(function ($event) {
      return $options.toggleShowNumericalUnit(1);
    }, ["prevent", "stop"]))
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: "lock",
    color: "Colors.primaryDark"
  }), _createTextVNode(" " + _toDisplayString($data.translate.forbrug), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), !$data.numericalUnits[2].show ? (_openBlock(), _createElementBlock("li", _hoisted_17, [_createElementVNode("a", {
    href: "",
    onClick: _cache[9] || (_cache[9] = _withModifiers(function ($event) {
      return $options.toggleShowNumericalUnit(2);
    }, ["prevent", "stop"]))
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: "lock",
    color: "Colors.primaryDark"
  }), _createTextVNode(" " + _toDisplayString($data.translate.forventetAntalTimer), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), !$data.numericalUnits[3].show ? (_openBlock(), _createElementBlock("li", _hoisted_18, [_createElementVNode("a", {
    href: "",
    onClick: _cache[10] || (_cache[10] = _withModifiers(function ($event) {
      return $options.toggleShowNumericalUnit(3);
    }, ["prevent", "stop"]))
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: "lock",
    color: "Colors.primaryDark"
  }), _createTextVNode(" " + _toDisplayString($data.translate.forbrugAntalTimer), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)])])), [[_directive_click_outside, $options.toggleShowAddNumericalUnitPopUp]]) : _createCommentVNode("v-if", true), _createElementVNode("a", {
    href: "",
    class: "add-numerical-unit-btn btn btn-attachments",
    onClick: _cache[11] || (_cache[11] = _withModifiers(function () {
      return $options.toggleShowAddNumericalUnitPopUp && $options.toggleShowAddNumericalUnitPopUp.apply($options, arguments);
    }, ["prevent", "stop"]))
  }, [_createVNode(_component_feather_icon, {
    class: "inline-block",
    icon: "more-horizontal",
    color: "Colors.answered"
  })])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_19, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.numericalUnits, function (unit, key) {
    return _openBlock(), _createElementBlock("li", {
      key: key
    }, [$data.numericalUnits[key].show ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_risma_title, {
      title: _ctx.$trans(unit.title),
      type: "small"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", _hoisted_22, " (" + _toDisplayString(unit.date) + ") ", 1 /* TEXT */)]), _createVNode(_component_input_field, {
      enabled: !$options.inputsDisabled,
      class: "numerical-item-input",
      type: "text",
      placeholder: "2500"
    }, null, 8 /* PROPS */, ["enabled"]), _createElementVNode("span", _hoisted_23, _toDisplayString(unit.unit), 1 /* TEXT */), !$options.inputsDisabled ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "numerical-item-remove",
      icon: "x",
      color: "Colors.high",
      onClick: function onClick($event) {
        return $options.toggleShowNumericalUnit(key);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_24, _toDisplayString($data.translate.totalIncludingUnderlying) + ": ", 1 /* TEXT */), _createElementVNode("span", _hoisted_25, _toDisplayString(unit.total), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("div", _hoisted_26, [_createVNode(_component_risma_toggle, {
    modelValue: $data.inherited,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
      return $data.inherited = $event;
    }),
    disabled: $options.inputsDisabled,
    class: "risma-switch-inherit"
  }, null, 8 /* PROPS */, ["modelValue", "disabled"]), _createVNode(_component_risma_title, {
    title: $data.translate.underlyingLevelsInheritAboveNumericalUnits,
    class: "risma-title-inherit",
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])])) : _createCommentVNode("v-if", true), $options.isFieldInOptionalFields('project_status') ? (_openBlock(), _createElementBlock("div", _hoisted_27, [_createVNode(_component_risma_title, {
    title: $data.translate.projectStatus,
    type: "medium",
    class: "cursor-pointer",
    onClick: $options.focusOnPercentageInput
  }, null, 8 /* PROPS */, ["title", "onClick"]), _createElementVNode("div", _hoisted_28, [_createElementVNode("p", {
    class: "cursor-pointer mr-4 whitespace-nowrap",
    onClick: _cache[13] || (_cache[13] = function () {
      return $options.focusOnPercentageInput && $options.focusOnPercentageInput.apply($options, arguments);
    })
  }, _toDisplayString($data.translate.completionPercentage), 1 /* TEXT */), _createVNode(_component_input_field, {
    ref: "completionPercentageInput",
    modelValue: _ctx.completionPercentage,
    "onUpdate:modelValue": _cache[14] || (_cache[14] = function ($event) {
      return _ctx.completionPercentage = $event;
    }),
    enabled: !$options.inputsDisabled,
    "show-percentage": true,
    max: "100",
    min: "0",
    step: "1",
    type: "number",
    class: "min-w-[100px] flex-1",
    onBlur: $options.onCompletePercentageBlur
  }, null, 8 /* PROPS */, ["modelValue", "enabled", "onBlur"])])])) : _createCommentVNode("v-if", true), $options.isFieldInOptionalFields('comment') ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, [_createVNode(_component_tiny_mce_box, {
    "editor-wrapper-class": "mt-1",
    headline: $data.translate.otherComments,
    height: 150,
    "always-open": true,
    "initial-content": _ctx.comment,
    "read-only": $options.inputsDisabled,
    type: $data.TinyMceBoxTypes.COMMENT,
    onChanged: _cache[15] || (_cache[15] = function ($event) {
      return _ctx.comment = $event;
    })
  }, null, 8 /* PROPS */, ["headline", "initial-content", "read-only", "type"])]), _createVNode(_component_attachment_selector, {
    "attachments-existed": $data.attachments.comment,
    "owner-id": 1,
    "module-id": $props.data.id,
    removable: !$options.inputsDisabled,
    "read-only": $options.inputsDisabled,
    multiple: true,
    "show-title": false,
    field: "comment",
    module: "execution",
    class: "mt-2",
    onUpdatedFiles: $options.handleFileChange
  }, null, 8 /* PROPS */, ["attachments-existed", "module-id", "removable", "read-only", "onUpdatedFiles"])])) : _createCommentVNode("v-if", true), $options.currentActivityType ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createVNode(_component_activity_custom_fields, {
    "activity-type": $options.currentActivityType,
    "custom-field-values": $props.data.customFieldValues,
    disabled: $options.inputsDisabled,
    onChanged: _cache[16] || (_cache[16] = function ($event) {
      return _ctx.$emit('propertyChanged', {
        property: 'customFieldValuesParsed',
        value: $event
      });
    })
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "disabled"])])) : _createCommentVNode("v-if", true)])])]);
}