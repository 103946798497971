import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = ["onClick"];
var _hoisted_2 = {
  class: "flex items-center justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_date_picker = _resolveComponent("date-picker");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "date-range-picker",
    style: _normalizeStyle($options.useExtendedWidth ? {
      maxWidth: 'unset'
    } : '')
  }, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    class: "cursor-pointer mb-1",
    onClick: $options.handleOpen
  }, null, 8 /* PROPS */, ["title", "onClick"])) : _createCommentVNode("v-if", true), _createVNode(_component_date_picker, {
    value: $data.value,
    open: $data.isOpenState,
    range: true,
    "value-type": "format",
    format: $props.dateFormat,
    lang: $data.locale,
    "append-to-body": false,
    "popup-class": $props.popupClasses,
    disabled: $props.disabled,
    placeholder: $options.placeholder,
    separator: " - ",
    type: $props.type,
    class: _normalizeClass(['group border rounded-md outline', $options.calculatedBorderClasses, {
      'tabular-nums': $data.isOpenState,
      isOpenState: $data.isOpenState
    }, $props.dateRangeClasses]),
    style: _normalizeStyle($options.useExtendedWidth ? {
      maxWidth: 'unset'
    } : ''),
    onChange: $options.handleChange,
    onClear: $options.discardDate,
    onOpen: $options.handleOpen
  }, {
    "icon-calendar": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "calendar",
        width: "18",
        height: "18",
        class: _normalizeClass(['mx-2 stroke-2 group-hover:text-rm-text rm-icon active:!text-rm-active-blue', {
          'text-rm-text': $data.isOpenState
        }]),
        onClick: $options.onCalendarClick
      }, null, 8 /* PROPS */, ["class", "onClick"])];
    }),
    sidebar: _withCtx(function (_ref) {
      var emit = _ref.emit;
      return [_createElementVNode("div", {
        class: _normalizeClass(["mx-btn-shortcut no-hover text-rm-text", $options.activeRangeIndex === -1 ? $data.activeRangeStyles : 'glob-l2'])
      }, _toDisplayString($data.translate.customRange), 3 /* TEXT, CLASS */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.ranges, function (rangeItem, index) {
        return _openBlock(), _createElementBlock("div", {
          key: rangeItem.text,
          class: _normalizeClass(["mx-btn-shortcut text-rm-text cursor-pointer", $options.activeRangeIndex === index ? $data.activeRangeStyles : 'glob-l2']),
          onClick: function onClick($event) {
            return $options.handleShortcutClick(rangeItem.range, emit);
          }
        }, _toDisplayString(rangeItem.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_1);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    footer: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_feather_icon, {
        tabindex: "0",
        icon: "trash-2",
        class: "rm-icon fill-none",
        onClick: $options.handleDiscard,
        onKeydown: _withKeys($options.handleDiscard, ["enter"])
      }, null, 8 /* PROPS */, ["onClick", "onKeydown"]), _createElementVNode("div", null, [_createElementVNode("button", {
        class: "mr-2.5 glob-l1 text-rm-text mx-cancel-btn",
        tabindex: "0",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleCancel && $options.handleCancel.apply($options, arguments);
        })
      }, _toDisplayString($data.translate.cancel), 1 /* TEXT */), _createElementVNode("button", {
        class: "bg-rm-active-blue text-white rounded-md hover:bg-blue-650 py-1 px-4 glob-l1-sb",
        tabindex: "0",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.handleApply && $options.handleApply.apply($options, arguments);
        })
      }, _toDisplayString($data.translate.apply), 1 /* TEXT */)])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["value", "open", "format", "lang", "popup-class", "disabled", "placeholder", "type", "class", "style", "onChange", "onClear", "onOpen"])], 4 /* STYLE */)), [[_directive_click_outside, $options.handleClickOutside]]);
}