import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mb-4 flex"
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  key: 0,
  class: "w-full"
};
var _hoisted_4 = {
  key: 1,
  class: "flex justify-center mt-8 mb-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_inbox_modal = _resolveComponent("inbox-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.showModal ? (_openBlock(), _createBlock(_component_inbox_modal, {
        key: 0,
        "modal-data": $data.modalData,
        "test-button-load-state": $data.testButtonLoadState,
        onAccept: $options.handleAccept,
        onTestConnection: $options.testConnection,
        onDismiss: $options.resetModalData
      }, null, 8 /* PROPS */, ["modal-data", "test-button-load-state", "onAccept", "onTestConnection", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.imap
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "ml-auto stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.createNewMainAccount
      }, null, 8 /* PROPS */, ["onClick"])]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.data.length ? (_openBlock(), _createElementBlock("table", _hoisted_3, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (item, index) {
        return _openBlock(), _createElementBlock("th", {
          key: "inbox_column_".concat(index),
          class: "px-3 py-2 border-b-2 border-slate-200 text-left break-words"
        }, _toDisplayString(item), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dataset, function (account) {
        return _openBlock(), _createElementBlock("tr", {
          key: account.id
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.order, function (item, index) {
          var _account$item;
          return _openBlock(), _createElementBlock("td", {
            key: "inbox_".concat(account.id, "_").concat(index),
            class: "px-3 py-2 border-b-2 border-slate-200 text-left break-words"
          }, [((_account$item = account[item]) === null || _account$item === void 0 ? void 0 : _account$item.type) === 'icon' ? (_openBlock(), _createBlock(_component_feather_icon, {
            key: 0,
            icon: account[item].icon,
            class: _normalizeClass(account[item].styles || ''),
            onClick: function onClick($event) {
              return $options.handleEvent(account[item]);
            }
          }, null, 8 /* PROPS */, ["icon", "class", "onClick"])) : (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createTextVNode(_toDisplayString(account[item] || ''), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */))]);
        }), 128 /* KEYED_FRAGMENT */))]);
      }), 128 /* KEYED_FRAGMENT */))])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */))]))];
    }),
    _: 1 /* STABLE */
  });
}