function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { generateComputed } from '@/utils/base/generateComputed';
import { isEmailValid as _isEmailValid, isPasswordValid as _isPasswordValid } from '@/utils/validations';
import { passwordErrorMessage as _passwordErrorMessage, getPasswordLength } from '@/utils/notifications';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaOrgSelector from '@/components/Molecules/OrganisationSelector';
var CHECKBOX_OPTION_VALUE = {
  GRC: 'performance_report_access',
  Legalhub: 'legal_hub_access',
  API: 'api_user'
};
export default {
  name: 'UserDescription',
  introduction: '',
  description: '',
  token: '',
  components: {
    InputField: InputField,
    RismaOrgSelector: RismaOrgSelector,
    RismaButton: RismaButton,
    Checkboxes: Checkboxes
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'User obj'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      showDeactivateAlert: false,
      translate: getTranslate['UserDescription']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), generateComputed(['username', 'password', 'initials', 'email', 'phone'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    checkboxOptions: function checkboxOptions() {
      var list = [{
        checked: !!this.data.api_user,
        value: CHECKBOX_OPTION_VALUE.API,
        label: this.translate.apiUser
      }];
      if (this.isPerformanceReportEnabled) {
        list.unshift({
          checked: !!this.data.performance_report_access,
          value: CHECKBOX_OPTION_VALUE.GRC,
          label: 'GRC Intelligence Center'
        }, {
          checked: !!this.data.legal_hub_access,
          value: CHECKBOX_OPTION_VALUE.Legalhub,
          label: 'Legalhub'
        });
      }
      return list;
    },
    selectedOrganisations: function selectedOrganisations() {
      var _this = this;
      if (!this.data.organisations) return [];
      var result = [];
      this.data.organisations.forEach(function (orgId) {
        var selectedOrg = _this.organisations.find(function (item) {
          return item.id === orgId;
        });
        if (selectedOrg) {
          result.push(selectedOrg);
        }
      });
      return result;
    },
    activeStatusText: function activeStatusText() {
      var result = '';
      if (this.data.active) {
        result += this.translate.areYouSureYouWantToDeactivate;
      } else {
        result += this.translate.areYouSureYouWantToActivate;
      }
      return "".concat(result, " ").concat(this.data.display_name, " ?");
    },
    isEmailValid: function isEmailValid() {
      return this.email && _isEmailValid(this.email);
    },
    isPasswordValid: function isPasswordValid() {
      return !this.password || _isPasswordValid(this.password, this.passwordSettingLength);
    },
    passwordSettingLength: function passwordSettingLength() {
      return getPasswordLength(this.data.api_user, this.getSettingValue('feature.api_user_min_password_length'), this.getSettingValue('feature.enterprise_security'));
    },
    passwordErrorMessage: function passwordErrorMessage() {
      return _passwordErrorMessage(this.getSettingValue('feature.enterprise_security'), this.passwordSettingLength);
    },
    isPerformanceReportEnabled: function isPerformanceReportEnabled() {
      return this.getSettingValue('feature.performance_report_enabled');
    }
  }),
  methods: {
    onOrganisationsChange: function onOrganisationsChange(newOrganisationsList) {
      var ids = newOrganisationsList.map(function (org) {
        return org.id;
      });
      this.$emit('propertyChanged', {
        property: 'organisations',
        value: ids
      });
    },
    onCheckboxChange: function onCheckboxChange(_ref) {
      var value = _ref.value,
        isChecked = _ref.isChecked;
      this.$emit('propertyChanged', {
        property: value,
        value: isChecked ? 1 : 0
      });
    },
    onActiveStatusChange: function onActiveStatusChange() {
      this.showDeactivateAlert = false;
      this.$emit('propertyChanged', {
        property: 'active',
        value: !this.data.active
      });
    },
    onDisableMfaClick: function onDisableMfaClick() {
      this.$emit('propertyChanged', {
        property: 'mfa_type',
        value: 0
      });
    },
    handleDeactivationConfirm: function handleDeactivationConfirm() {
      var _this2 = this;
      this.$confirm(this.data.active ? this.translate.deactivateUser : this.translate.activateUser, this.activeStatusText, function (res) {
        return res && _this2.onActiveStatusChange();
      }, {
        buttonOkText: this.translate.confirm,
        buttonDismissText: this.translate.cancel
      });
    }
  }
};