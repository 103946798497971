function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useFrameworksStore } from '@/Store/frameworksStore';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
export default {
  name: 'FrameworksSelector',
  introduction: 'A label and an frameworks selector',
  description: 'Molecule for choosing frameworks',
  token: '<frameworks-selector :available-options="rismaOrgSelectorUsers"\n' + '        :selected-options="rismaOrgSelectorSelectedOptions"\n' + '        title="Responsible (R)"></frameworks-selector>',
  components: {
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    RismaToggle: RismaToggle
  },
  props: {
    title: {
      default: 'Framework',
      required: false,
      type: String,
      note: 'the title above the selector'
    },
    selectedOptions: {
      required: false,
      default: null,
      type: [Array, Number],
      note: 'the selected elements in the selector'
    },
    placeholder: {
      required: false,
      default: 'Select framework',
      type: String,
      note: 'Default placeholder'
    },
    openDirection: {
      required: false,
      default: '',
      type: String,
      note: 'Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Disable the selector'
    },
    toggleObject: {
      type: Object,
      default: null
    }
  },
  emits: ['close', 'open', 'selected', 'update:modelValue'],
  computed: _objectSpread(_objectSpread({}, mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    modelValue: function modelValue() {
      return this.selectedOptions || (this.multiple ? [] : null);
    },
    translatedTitle: function translatedTitle() {
      return this.$trans(this.title);
    },
    translatedPlaceholder: function translatedPlaceholder() {
      return this.$trans(this.placeholder);
    },
    options: function options() {
      var frameworks = this.frameworks || [];
      return this.multiple ? frameworks : frameworks.map(function (framework) {
        return {
          label: framework.displayName,
          value: framework.id
        };
      });
    }
  })
};