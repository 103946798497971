export var ProjectTypes = {
  GDPR: 1,
  ISO27001: 2,
  ISO9001: 3,
  AML: 4,
  ABC: 5,
  PATENT: 6,
  GENERIC: 7,
  OUTSOURCING: 11,
  ESG: 15,
  FINANCIAL_CONTROLS: 16,
  ENTERPRISE_RISK_MANAGEMENT: 17,
  CONTROL_SELF_ASSESSMENT: 18,
  GDPR_NY: 19,
  CSRD: 20,
  DORA_REGULATION: 21,
  DORA_CRITICAL_FUNCTIONS: 22,
  DATAPROCESSOR: 102,
  DPM: 105,
  CONTRACTS_AUDIT: 107,
  SYSTEMS_AUDIT: 108,
  DATA_CONTROLLER_AUDIT: 109,
  INFORMATION_ASSETS_AUDIT: 110,
  DPM_RISK_ASSESSMENT: 200
};
export var InnerContentQuestionTypes = {
  INFORMATION_ASSETS_SYSTEMS: 9,
  INFORMATION_ASSETS_PROCESSORS: 10,
  INFORMATION_ASSETS_CONTROLLER: 11,
  PROCESS_LIBRARY: 12,
  INFORMATION_ASSETS_CUSTOM: 13
};
export var ForbiddenToUseIAForTypes = [ProjectTypes.CONTRACTS_AUDIT, ProjectTypes.INFORMATION_ASSETS_AUDIT, ProjectTypes.DPM, ProjectTypes.DATA_CONTROLLER_AUDIT, ProjectTypes.SYSTEMS_AUDIT];