import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "modal-alert-wrap"
};
var _hoisted_2 = {
  class: "mt-2"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_modal, {
    header: $options.headerText,
    "header-truncate": false,
    "header-type": $props.headerType,
    "button-ok-text": $options.okText,
    "button-dismiss-text": $options.dismissText,
    type: $props.type,
    onAccept: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('accept', $event);
    }),
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [!Array.isArray($options.bodyText) ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        innerHTML: $options.bodyText
      }, null, 8 /* PROPS */, _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.bodyText, function (message, idx) {
        return _openBlock(), _createElementBlock("p", {
          key: idx,
          innerHTML: message
        }, null, 8 /* PROPS */, _hoisted_5);
      }), 128 /* KEYED_FRAGMENT */))])), $props.alertType === $data.AlertTypes.PROMPT ? (_openBlock(), _createBlock(_component_input_field, {
        key: 2,
        "model-value": $props.modelValue,
        type: "text",
        "focus-on-mount": "",
        maxlength: "100",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:modelValue', $event);
        })
      }, null, 8 /* PROPS */, ["model-value"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "header-type", "button-ok-text", "button-dismiss-text", "type"])]);
}