import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "print-label flex text-blue-750"
};
var _hoisted_2 = {
  class: "print-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: "risma-print relative",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.confirmExport && $options.confirmExport.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_feather_icon, {
      icon: "printer"
    }), _createElementVNode("span", _hoisted_2, _toDisplayString($options.printButtonLabel), 1 /* TEXT */)])];
  }, true)]);
}