import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "flex flex-row flex-nowrap"
};
var _hoisted_2 = ["title", "innerHTML"];
var _hoisted_3 = ["title", "innerHTML"];
var _hoisted_4 = ["title", "innerHTML"];
var _hoisted_5 = ["colspan", "title"];
var _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("tbody", {
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click', $event);
    })
  }, [_createElementVNode("tr", {
    class: _normalizeClass({
      empty: $options.isEmpty
    })
  }, [$options.isHasChildren ? (_openBlock(), _createElementBlock("td", {
    key: 0,
    class: _normalizeClass({
      hidden: $options.isCellHidden(0)
    })
  }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, $options.firstColData.key, {
    slotData: $options.firstColData.value
  }, function () {
    return [_createElementVNode("div", null, [_createElementVNode("span", {
      class: _normalizeClass(["glob-b1 text-rm-text", {
        titleCol: $options.isTitleKey($options.firstColData.key)
      }]),
      title: $props.additionalRowClassesText || $options.firstColData.key,
      innerHTML: $options.firstColData.value
    }, null, 10 /* CLASS, PROPS */, _hoisted_2)])];
  }, true), $props.isChildrenIconShown ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: $options.showChildren ? 'chevron-up' : 'chevron-down',
    class: "text-blue-750 cursor-pointer stroke-3",
    onClick: $options.toggleChildren
  }, null, 8 /* PROPS */, ["icon", "onClick"])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$options.isHasChildren ? (_openBlock(), _createElementBlock("td", {
    key: 1,
    style: _normalizeStyle($options.getCellStyles($options.firstColData.key)),
    class: _normalizeClass({
      hidden: $options.isCellHidden(0)
    })
  }, [_renderSlot(_ctx.$slots, $options.firstColData.key, {
    slotData: $options.firstColData.value
  }, function () {
    return [_createElementVNode("div", null, [_createElementVNode("span", {
      class: _normalizeClass(["glob-b1 text-rm-text", {
        titleCol: $options.isTitleKey($options.firstColData.key)
      }]),
      title: $props.additionalRowClassesText || $options.firstColData.key,
      innerHTML: $options.firstColData.value
    }, null, 10 /* CLASS, PROPS */, _hoisted_3)])];
  }, true)], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item, function (val, key, index) {
    return _openBlock(), _createElementBlock(_Fragment, null, [key !== 'children' && index !== 0 ? (_openBlock(), _createElementBlock("td", {
      key: index,
      style: _normalizeStyle($options.getCellStyles(key)),
      class: _normalizeClass({
        hidden: $options.isCellHidden(index)
      })
    }, [_renderSlot(_ctx.$slots, key, {
      slotData: val
    }, function () {
      return [_createElementVNode("div", null, [_createElementVNode("span", {
        class: _normalizeClass(["glob-b1 text-rm-text", {
          titleCol: $options.isTitleKey(key)
        }]),
        title: $options.getHoverTitle(key, val),
        innerHTML: val
      }, null, 10 /* CLASS, PROPS */, _hoisted_4)])];
    }, true)], 6 /* CLASS, STYLE */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))], 2 /* CLASS */), $options.showChildren ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.item.children, function (child, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index,
      class: "child"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child, function (v, k, chIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: k,
        colspan: Object.keys(child).length === 1 ? $options.columnsNumber : 0,
        style: _normalizeStyle($options.getCellStyles(k)),
        class: _normalizeClass({
          hidden: $options.isCellHidden(chIndex)
        }),
        title: $options.getHoverTitle(k, v)
      }, [_renderSlot(_ctx.$slots, k, {
        slotData: v
      }, function () {
        return [_createElementVNode("div", {
          innerHTML: v
        }, null, 8 /* PROPS */, _hoisted_6)];
      }, true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_5);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}