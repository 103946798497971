import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'ActionReportsEntry',
  description: 'Common page for action reports',
  token: '<action-reports-entry />',
  components: {
    LightTabItem: LightTabItem
  },
  data: function data() {
    return {
      translate: {
        mainReport: this.$trans('Main report'),
        dataReport: this.$trans('Data report'),
        motherControls: this.$trans('Mother controls'),
        changelogReport: this.$trans('Changelog report')
      }
    };
  },
  computed: {
    baseControlLink: function baseControlLink() {
      return '/actions/reports/';
    }
  }
};