import i18n from '@/i18n';
import { getMonthsNames } from '@/utils/date';
import { capitalizeWords } from '@/utils/capitalize';
export var CONTRACT_STATUSES = {
  expiring: 'expiring',
  renewal: 'renewal',
  DRAFT: 'Draft',
  APPROVED: 'Approved',
  EXPIRED: 'Expired',
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  IN_REVIEW: 'In Review',
  IN_RENEWAL: 'In Renewal'
};
export var COMMITMENT_STATUS = 'commitment';
export var getIntervalOptions = function getIntervalOptions() {
  return [{
    label: i18n.t('Monthly'),
    value: 1
  }, {
    label: i18n.t('Quarterly'),
    value: 3
  }, {
    label: i18n.t('Biannually'),
    value: 6
  }, {
    label: i18n.t('Annually'),
    value: 12
  }];
};
export var getMonthOptions = function getMonthOptions() {
  var monthNames = getMonthsNames();
  return monthNames.map(function (monthName, idx) {
    return {
      label: capitalizeWords(monthName),
      value: idx + 1
    };
  });
};
export var getIntervalTitleByValue = function getIntervalTitleByValue(value) {
  var intervals = getIntervalOptions();
  var interval = intervals.find(function (curInterval) {
    return +curInterval.value === +value;
  });
  return interval ? interval.label : '';
};