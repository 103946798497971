import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center max-w-full w-fit mb-0 min-h-6 px-2 py-0.5 border rounded-md glob-l1-m bg-rm-active-blue--10 text-rm-active-blue border-rm-active-blue"
};
var _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    title: $props.title,
    class: "truncate"
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_2)]);
}