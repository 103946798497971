function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import * as ReportsApi from '@/api/reports/reports';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { isBelowSuperUser } from '@/utils/access';
import { typeToAccessProperty } from '@/utils/report';
import { ReportTypes as reportTypes } from '@/constants/reports';
export default {
  name: 'SavedReports',
  introduction: 'Saved reports (content inside a modal)',
  description: 'This is a Molecule component for Reports',
  token: '<saved-reports />',
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon
  },
  props: {
    type: {
      required: true,
      type: String,
      note: 'Type from report-schema.json (controls-report or controls-data-report)'
    },
    projectId: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'It is intended for retrieving reports based on the project id and is used in the context of risks and compliance.'
    }
  },
  emits: ['load', 'reportShared', 'reportDeleted'],
  data: function data() {
    return {
      currentDeleteItem: null,
      defaultReports: [],
      allReports: [],
      translate: getTranslate['SavedReports']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin'])), {}, {
    defaultReportsTitle: function defaultReportsTitle() {
      return this.translate.defaultReports;
    },
    isAnyReportAvailable: function isAnyReportAvailable() {
      return this.reportGroups.some(function (group) {
        return group.reports.length;
      }) || this.defaultReports.length;
    },
    reportGroups: function reportGroups() {
      return [{
        title: this.translate.sharedReports,
        reports: this.sharedReports,
        access: !isBelowSuperUser(this.currentUser, typeToAccessProperty(this.type))
      }, {
        title: this.translate.myReports,
        reports: this.myReports,
        shareOption: true,
        access: true
      }];
    },
    sharedReports: function sharedReports() {
      return this.allReports.filter(function (report) {
        return report.shared;
      });
    },
    myReports: function myReports() {
      return this.allReports.filter(function (report) {
        return !report.shared;
      });
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      if (this.type === reportTypes.CONTROLS) {
        this.prepareDefaultReports();
      }
      return this.prepareReports(this.type, this.projectId);
    },
    prepareReports: function prepareReports(type, projectId) {
      var _this = this;
      var params = "?types[]=".concat(type);
      if (projectId) {
        params += "&filters[projectId]=".concat(projectId);
      }
      return ReportsApi.getReports(params).then(function (_ref) {
        var list = _ref.list;
        _this.allReports = _toConsumableArray(list);
      });
    },
    prepareDefaultReports: function prepareDefaultReports() {
      var statuses = "^".concat(this.translate.completed, "$|^").concat(this.translate.controlRejected, "$|^").concat(this.translate.pendingReview, "$");
      this.defaultReports = [{
        title: this.translate.completedControls,
        comment: this.translate.thisReportOnlyShowsTheCompletedControls,
        query: {
          filters: {
            status: statuses
          }
        }
      }, {
        title: this.translate.completedControlsPastTheDeadline,
        comment: this.translate.thisReportOnlyShowsControlsThatWasCompleted,
        query: {
          filters: {
            status: statuses,
            trafficLight: '1'
          }
        }
      }, {
        title: this.translate.pendingControlsPastTheDeadline,
        comment: this.translate.thisReportOnlyShowsTheControlsThatArePast,
        query: {
          filters: {
            status: "^".concat(this.translate.pending, "$"),
            trafficLight: '1'
          }
        }
      }];
    },
    goToReport: function goToReport(data) {
      this.$emit('load', data);
    },
    deleteReport: function deleteReport() {
      var _this2 = this;
      if (this.currentDeleteItem) {
        return ReportsApi.deleteReport(this.currentDeleteItem.id).then(function () {
          _this2.currentDeleteItem = null;
          _this2.getData();
          _this2.$emit('reportDeleted');
        });
      }
    },
    editReport: function editReport(report) {
      var _this3 = this;
      return ReportsApi.editReport(report.id, {
        shared: true
      }).then(function (updatedReport) {
        var updatedReportIdx = _this3.allReports.findIndex(function (rep) {
          return rep.id === report.id;
        });
        if (updatedReportIdx !== -1) {
          _this3.allReports[updatedReportIdx] = updatedReport;
        }
        _this3.$emit('reportShared');
      });
    },
    tryToDelete: function tryToDelete(item) {
      var _this4 = this;
      this.currentDeleteItem = item;
      this.$confirm(this.translate.deleteReport, this.translate.theSavedReportWillBeDeleted, function (res) {
        return res && _this4.deleteReport();
      }, {
        buttonOkText: this.translate.delete
      });
    }
  }
};