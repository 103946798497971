function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
export default {
  name: 'DotConnector',
  description: 'a canvas that draws lines connecting dots',
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    dotsDomElements: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'array of dots elements'
    }
  },
  data: function data() {
    return {
      ctx: null,
      dotsMap: {}
    };
  },
  computed: {
    circleRadius: function circleRadius() {
      var _this$dotsDomElements;
      return ((_this$dotsDomElements = this.dotsDomElements[0]) === null || _this$dotsDomElements === void 0 ? void 0 : _this$dotsDomElements.offsetWidth) / 2 || 0;
    },
    watchParameters: function watchParameters() {
      return {
        width: this.width,
        height: this.height,
        dotsDomElements: this.dotsDomElements
      };
    }
  },
  watch: {
    watchParameters: function watchParameters() {
      var _this = this;
      this.$nextTick(function () {
        _this.init();
      });
    }
  },
  mounted: function mounted() {
    this.ctx = this.$el.getContext('2d');
    this.init();
  },
  methods: {
    init: function init() {
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.dotsMap = this.prepareDotMap(this.dotsDomElements);
      this.drawLines(this.dotsMap);
    },
    prepareDotMap: function prepareDotMap(dotsDomElements) {
      var dotsMap = {};
      dotsDomElements.forEach(function (element) {
        var dotId = element.getAttribute('data-id');
        if (!dotsMap[dotId]) {
          dotsMap[dotId] = [];
        }
        dotsMap[dotId].push(element);
      });
      return dotsMap;
    },
    drawLines: function drawLines(dotsMap) {
      var _this2 = this;
      Object.values(dotsMap).forEach(function (dots) {
        if (dots.length !== 2) return;
        var _this2$sortDots = _this2.sortDots(dots),
          _this2$sortDots2 = _slicedToArray(_this2$sortDots, 2),
          fromElement = _this2$sortDots2[0],
          toElement = _this2$sortDots2[1];
        var _this2$getLineCoordin = _this2.getLineCoordinates(fromElement, toElement),
          _this2$getLineCoordin2 = _slicedToArray(_this2$getLineCoordin, 4),
          fromX = _this2$getLineCoordin2[0],
          fromY = _this2$getLineCoordin2[1],
          toX = _this2$getLineCoordin2[2],
          toY = _this2$getLineCoordin2[3];
        _this2.addArrowToCanvas(_this2.ctx, fromX, fromY, toX, toY);
      });
    },
    getLineCoordinates: function getLineCoordinates(fromElement, toElement) {
      var _this$getElementCoord = this.getElementCoordinates(fromElement),
        _this$getElementCoord2 = _slicedToArray(_this$getElementCoord, 2),
        fromX = _this$getElementCoord2[0],
        fromY = _this$getElementCoord2[1];
      var _this$getElementCoord3 = this.getElementCoordinates(toElement),
        _this$getElementCoord4 = _slicedToArray(_this$getElementCoord3, 2),
        toX = _this$getElementCoord4[0],
        toY = _this$getElementCoord4[1];
      return [fromX, fromY, toX, toY];
    },
    getElementCoordinates: function getElementCoordinates(element) {
      if (!element.offsetParent) {
        return this.getHiddenDotsCoordinates(element);
      }
      return this.getDotCoordinates(element);
    },
    getDotCoordinates: function getDotCoordinates(element) {
      var x = element.offsetParent.offsetLeft + element.offsetLeft;
      var y = element.offsetParent.offsetTop + element.offsetTop;
      x += element.offsetWidth / 2;
      y += element.offsetHeight / 2;
      return [x, y];
    },
    getHiddenDotsCoordinates: function getHiddenDotsCoordinates(element) {
      var showMoreElement = element.closest('.show-more-dots');
      if (!showMoreElement) return [];
      var x = showMoreElement.offsetParent.offsetLeft + showMoreElement.offsetLeft + showMoreElement.offsetWidth / 2;
      var y = showMoreElement.offsetParent.offsetTop + showMoreElement.offsetTop + showMoreElement.offsetTop / 2;
      return [x, y];
    },
    addArrowToCanvas: function addArrowToCanvas(context, fromX, fromY, toX, toY) {
      var angle = Math.atan2(toY - fromY, toX - fromX);
      var xStartPoint = fromX + this.circleRadius * Math.cos(angle);
      var yStartPoint = fromY + this.circleRadius * Math.sin(angle);
      var xEndPoint = toX - this.circleRadius * Math.cos(angle);
      var yEndPoint = toY - this.circleRadius * Math.sin(angle);
      this.addLineToCanvas(context, xStartPoint, yStartPoint, xEndPoint, yEndPoint);
      this.addArrowHeadToCanvas(context, xEndPoint, yEndPoint, angle);
    },
    addLineToCanvas: function addLineToCanvas(context, xStartPoint, yStartPoint, xEndPoint, yEndPoint) {
      context.beginPath();
      context.moveTo(xStartPoint, yStartPoint);
      context.lineTo(xEndPoint, yEndPoint);
      context.stroke();
    },
    addArrowHeadToCanvas: function addArrowHeadToCanvas(context, xEndPoint, yEndPoint, angle) {
      var arrowHeadLength = 9;
      var arrowHeadWidthCoefficient = 11;
      context.beginPath();
      context.moveTo(xEndPoint, yEndPoint);
      context.lineTo(xEndPoint - arrowHeadLength * Math.cos(angle - Math.PI / arrowHeadWidthCoefficient), yEndPoint - arrowHeadLength * Math.sin(angle - Math.PI / arrowHeadWidthCoefficient));
      context.lineTo(xEndPoint - arrowHeadLength * Math.cos(angle + Math.PI / arrowHeadWidthCoefficient), yEndPoint - arrowHeadLength * Math.sin(angle + Math.PI / arrowHeadWidthCoefficient));
      context.lineTo(xEndPoint, yEndPoint);
      context.fillStyle = '#000000';
      context.fill();
      context.stroke();
    },
    sortDots: function sortDots(dots) {
      return _toConsumableArray(dots).sort(function (a, b) {
        return a.getAttribute('data-type') > b.getAttribute('data-type') ? 1 : -1;
      });
    }
  }
};