import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_horizontal_resize_box = _resolveComponent("horizontal-resize-box");
  return _openBlock(), _createBlock(_component_horizontal_resize_box, {
    "local-storage-key": "kitchensink-resize-box",
    class: "h-20"
  }, {
    leftColumn: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("span", null, "Text on the left", -1 /* HOISTED */)]);
    }),
    rightColumn: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createElementVNode("span", null, "Text on the right", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  });
}