// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-2b553183]  .static-table th:first-child,[data-v-2b553183]  .static-table th:last-child,[data-v-2b553183]  .static-table td:last-child,[data-v-2b553183]  .static-table td:first-child {
  text-align: center;
}
[data-v-2b553183]  .static-table th:first-child {
  width: 80px;
}
[data-v-2b553183]  .static-table th:last-child {
  width: 210px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
