function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import debounce from 'lodash/debounce';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaEditableTitle from '@/components/Atoms/Titles/RismaEditableTitle';
import TabItem from '@/components/Atoms/TabItem/TabItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SaveButton from '@/components/Atoms/Buttons/SaveButton';
import TrafficLightSelector from '@/components/Organisms/Selectors/TrafficLightSelector';
import Tabs from '@/components/Molecules/Tabs/Tabs';
import Attachment from '@/components/Organisms/Attachment/Attachment';
import { MODULES } from '@/constants/modules';
import { getCustomMultiShadow } from '@/utils/colors';
export default {
  name: 'DepHeader',
  introduction: 'Header of Data Entry Page',
  description: 'The component displays basic information of the entity',
  token: "<dep-header\n              :id=\"44\"\n              :editableId=\"true\"\n              :title=\"Entity title\"\n              :editableTitle=\"true\"\n              :tabs=[\n                { url: '', label: this.translate.home, icon: 'home' },\n                { url: '', label: this.translate.links, icon: 'link' }]\n              :files-list=[] />",
  components: {
    Attachment: Attachment,
    FeatherIcon: FeatherIcon,
    RismaEditableTitle: RismaEditableTitle,
    SaveButton: SaveButton,
    TabItem: TabItem,
    Tabs: Tabs,
    TrafficLightSelector: TrafficLightSelector
  },
  inject: {
    depRelationEnabled: {
      default: false
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Entity id'
    },
    idMaxLength: {
      required: false,
      type: Number,
      default: 255
    },
    editableId: {
      required: false,
      default: false,
      type: Boolean,
      note: 'A boolean to determine if the id, should be editable'
    },
    title: {
      type: String,
      required: true,
      default: '',
      note: 'Entity title'
    },
    editableTitle: {
      required: false,
      default: false,
      type: Boolean,
      note: 'A boolean to determine if the title, should be editable'
    },
    trafficLightSettings: {
      required: false,
      default: function _default() {
        return {};
      },
      type: Object,
      note: 'Settings for traffic light'
    },
    tabs: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Available tabs for this entity'
    },
    filesList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of files'
    },
    saveStatus: {
      type: String,
      required: false,
      default: '',
      note: 'if saveStatus is empty, the saveStatus will be hidden'
    },
    module: {
      type: String,
      required: false,
      default: '',
      note: 'A name of module, for example, "control" or "risk"'
    },
    moduleId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'An id of one of the entities of modules. For example, an cpath of the specific control'
    },
    editableAttachments: {
      required: false,
      default: true,
      type: Boolean,
      note: 'if attachments are editable. Can only be downloaded'
    },
    showCheckmark: {
      required: false,
      default: false,
      type: Boolean,
      note: 'If to show checkmark after title'
    },
    lineThroughTitle: {
      required: false,
      default: false,
      type: Boolean,
      note: 'If the title has strikethrough line'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Attachments loading only one file, on reload will replace the file'
    },
    attachmentHasPin: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Attachments has pin/unpin functionality'
    },
    attachmentHasTitle: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Attachments has title field'
    },
    fileTypeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    isAttachmentEnabled: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Attachments enabled'
    },
    attachmentsFilesAndLinksSeparated: {
      type: Boolean,
      default: true,
      required: false,
      note: 'In Attachments add attachment and add link are shown separately'
    },
    attachmentFileTypesEnabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'In Attachments show selector with file types'
    },
    attachmentEditFileEnabled: {
      type: Boolean,
      default: true,
      required: false,
      note: 'In Attachments show edit button on files'
    },
    allowEmptyTitle: {
      type: Boolean,
      default: false,
      required: false
    },
    allowEmptyId: {
      type: Boolean,
      default: true,
      required: false
    },
    idType: {
      required: false,
      type: String,
      default: 'text'
    }
  },
  emits: ['saveStatusClicked', 'changeTrafficLight', 'changeId', 'changeTitle', 'getActivityData', 'fileUpdate', 'updatingAttachments'],
  data: function data() {
    return {
      translate: {
        home: this.$trans('Home'),
        links: this.$trans('Links')
      },
      showTabsScroll: false,
      currentScrollPosition: 0,
      scrollLeft: 0,
      isMaxScrolled: false,
      handleDebouncedScroll: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    displayIdInTitle: function displayIdInTitle() {
      switch (this.module) {
        case MODULES.COMPLIANCE:
          return !!this.getSettingValue('feature.show_node_id');
        case MODULES.INCIDENTS:
          return !!this.getSettingValue('feature.show_incident_id');
        case MODULES.USERS:
        case MODULES.COMPANY:
          return false;
        default:
          return true;
      }
    },
    arrowShadows: function arrowShadows() {
      var color = '#ffffff';
      return {
        left: getCustomMultiShadow(color, 0.45, 0.82, 1),
        right: getCustomMultiShadow(color, 0.45, 0.82, -1)
      };
    }
  }),
  watch: {
    depRelationEnabled: function depRelationEnabled() {
      this.handleDebouncedScroll();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.handleDebouncedScroll = debounce(this.checkTabsScroll, 100);
    this.$nextTick(function () {
      window.addEventListener('resize', _this.handleDebouncedScroll);
      _this.$refs.tabs.addEventListener('scroll', _this.handleDebouncedScroll);
      _this.handleDebouncedScroll();
    });
  },
  methods: {
    checkTabsScroll: function checkTabsScroll() {
      var _this2 = this;
      if (!this.$refs.tabs) return;
      this.$nextTick(function () {
        _this2.showTabsScroll = _this2.$refs.tabs.scrollWidth > _this2.$refs.tabs.offsetWidth;
        _this2.scrollLeft = _this2.$refs.tabs.scrollLeft;
        _this2.isMaxScrolled = _this2.$refs.tabs.scrollLeft + _this2.$refs.tabs.clientWidth === _this2.$refs.tabs.scrollWidth;
      });
    },
    scrollTabs: function scrollTabs(value) {
      this.$refs.tabs.scrollLeft += value;
    }
  }
};