function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import get from 'lodash/get';
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import * as Utils from '@/utils/Utils';
import { getOrganisationsAndFilterByUserAccess, getAccounts as _getAccounts, getProcesses as _getProcesses, getControlRisks as _getControlRisks } from '@/api/risma/data';
import { ControlViews } from '@/constants/ControlViews';
import { UserLevels } from '@/constants/UserLevels';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { getAssociationName } from '@/utils/controls/associations';
import { getAccountsWithChildren } from '@/utils/controls/accounts';
import { sortAlphabetic } from '@/utils/sort';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { checkControlsOrgAccess } from '@/utils/access';
import { isDateBetween, calculateUTCStartOfDate, calculateUTCEndOfDateWithMove } from '@/utils/date';
export var controlsListBaseMixin = {
  data: function data() {
    return {
      controls: [],
      isLoaded: false,
      genericError: null,
      filterByUserAccess: null,
      organisations: [],
      accounts: [],
      risks: [],
      processes: [],
      displayViewLocalStateKeys: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    filtersData: function filtersData() {
      var mothers = this.motherControls.map(function (control) {
        return {
          label: "".concat(control.cpath, " ").concat(control.title),
          value: +control.cpath,
          cpath: control.cpath
        };
      });
      var result = {
        organisations: this.organisations,
        tags: this.tags,
        accounts: this.accounts,
        risks: this.risks,
        processes: this.processes,
        frameworks: this.frameworks,
        mother: sortAlphabetic(mothers, 'label'),
        deadline: [{
          label: this.$trans('No filter'),
          value: null
        }, {
          label: this.$trans('Today'),
          value: 0
        }, {
          label: this.$trans('Next 2 days'),
          value: 2
        }, {
          label: this.$trans('Next 5 days'),
          value: 5
        }, {
          label: this.$trans('Next 7 days'),
          value: 7
        }, {
          label: this.$trans('Next 2 weeks'),
          value: 14
        }, {
          label: this.$trans('Next month'),
          value: 31
        }, {
          label: this.$trans('Next quarter'),
          value: 90
        }, {
          label: this.$trans('Next 6 months'),
          value: 183
        }, {
          label: this.$trans('Next 12 months'),
          value: 365
        }]
      };
      if (this.isControlCatalogue) {
        result = _objectSpread(_objectSpread({}, result), {}, {
          users: this.users.filter(function (user) {
            return checkControlsOrgAccess(user, UserLevels.NORMAL);
          }),
          showDeactivated: true,
          groupByOptions: [{
            label: this.$trans('Not set'),
            value: null
          }, {
            label: this.$trans('Mother controls'),
            value: 'mothercontrolId'
          }, {
            label: this.$trans('Tags'),
            value: 'tags'
          }, {
            label: this.$trans('Responsible'),
            value: 'responsible'
          }, {
            label: this.$trans('Reviewer'),
            value: 'review'
          }, {
            label: this.$trans('Organisations'),
            value: 'organisationIds'
          }, {
            label: this.accountsTitle,
            value: SortFieldsCompatibility.ACCOUNTS
          }, {
            label: this.processesTitle,
            value: SortFieldsCompatibility.PROCESSES
          }, {
            label: this.risksTitle,
            value: SortFieldsCompatibility.RISKS
          }],
          sortByOptions: this.sortOptions
        });
      }
      return result;
    },
    accountsTitle: function accountsTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    processesTitle: function processesTitle() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    sortOptions: function sortOptions() {
      var result = [{
        id: 1,
        label: this.$trans('Control number'),
        value: SortFieldsCompatibility.CPATH
      }];
      if (this.getSettingValue('risma.show_key_control')) {
        result.push({
          id: 2,
          label: this.$trans('Key control'),
          value: ControlTypeFields.KEY_CONTROL
        });
      }
      if (this.getSettingValue('risma.show_automatic_manual')) {
        result.push({
          id: 3,
          label: this.$trans('Manual'),
          value: ControlTypeFields.KEY_AUTOMATIC_MANUAL
        });
      }
      if (this.getSettingValue('risma.show_preventive_detective')) {
        result.push({
          id: 4,
          label: this.$trans('Preventive'),
          value: ControlTypeFields.KEY_PREVENTIVE_DETECTIVE
        });
      }
      var defaultOptions = [{
        id: 5,
        label: this.$trans('Responsible'),
        value: SortFieldsCompatibility.USER_IDS_RESPONSIBLE
      }, {
        id: 6,
        label: this.$trans('Organisation'),
        value: SortFieldsCompatibility.ORG_IDS
      }, {
        id: 7,
        label: this.$trans('Deadline'),
        value: SortFieldsCompatibility.DEADLINE
      }, {
        id: 8,
        label: this.accountsTitle,
        value: SortFieldsCompatibility.ACCOUNTS
      }, {
        id: 9,
        label: this.processesTitle,
        value: SortFieldsCompatibility.PROCESSES
      }, {
        id: 10,
        label: this.risksTitle,
        value: SortFieldsCompatibility.RISKS
      }];
      result = [].concat(_toConsumableArray(result), defaultOptions);
      return result;
    },
    noControls: function noControls() {
      var result = true;
      for (var key in this.controlsGroup) {
        if (this.controlsGroup[key].activities.length) {
          result = false;
        }
      }
      return result;
    }
  }),
  methods: {
    getOrgs: function getOrgs() {
      var _this = this;
      this.filterByUserAccess = !checkControlsOrgAccess(this.currentUser);
      return getOrganisationsAndFilterByUserAccess(this.filterByUserAccess).then(function (_ref) {
        var list = _ref.list;
        _this.organisations = sortAlphabetic(list, 'visible_name');
      });
    },
    getAccounts: function getAccounts() {
      var _this2 = this;
      return _getAccounts().then(function (_ref2) {
        var list = _ref2.list;
        return _this2.accounts = getAccountsWithChildren(list, false);
      });
    },
    getProcesses: function getProcesses() {
      var _this3 = this;
      return _getProcesses().then(function (_ref3) {
        var list = _ref3.list;
        return _this3.processes = list;
      });
    },
    getControlRisks: function getControlRisks() {
      var _this4 = this;
      return _getControlRisks().then(function (_ref4) {
        var list = _ref4.list;
        return _this4.risks = list;
      });
    },
    appendAdditionalOrgs: function appendAdditionalOrgs(data) {
      this.organisations = Utils.removeDuplicates(this.organisations.concat(data));
      this.organisations = sortAlphabetic(this.organisations, 'visible_name');

      // For normal users we don't need orgs without controls (assigned to them)
      if (this.currentUser.level_controls < UserLevels.PRIVILEGED) {
        this.organisations = RismaControlsUtils.findOrgsInControlsOnly(this.controls, this.organisations);
      }
    },
    setCompability: function setCompability(isControlCatalogue) {
      // Compatibility with Risma1 switcher
      if (this.currentView === 'datatable') {
        this.currentView = ControlViews.COMPACT;
      }
      if (!isControlCatalogue && this.currentView === ControlViews.TREE) {
        this.currentView = ControlViews.EXPANDED;
      }
    },
    getEntityValue: function getEntityValue(item, key, data, propName) {
      var names = Utils.findPropsByIds(get(item, key), data, propName);
      return names.split(',');
    },
    controlPassFilterByDeadlineCriteria: function controlPassFilterByDeadlineCriteria(control) {
      var startOfToday = calculateUTCStartOfDate();
      var deadlineCutoff = calculateUTCEndOfDateWithMove(this.filters.deadline);
      return isDateBetween(control.deadline, startOfToday, deadlineCutoff);
    },
    controlPassFilterByAssociationCriteria: function controlPassFilterByAssociationCriteria(control, listName, list) {
      var _control$listName;
      return (_control$listName = control[listName]) === null || _control$listName === void 0 ? void 0 : _control$listName.some(function (item) {
        return list.find(function (element) {
          return element.id === item.id;
        });
      });
    },
    controlPassFilterBySearchCriteria: function controlPassFilterBySearchCriteria(control) {
      return this.searchByEntity("".concat(control.cpath, " ").concat(control.title));
    },
    controlPassFilterByOrganizationCriteria: function controlPassFilterByOrganizationCriteria(control) {
      return Utils.filterArrayByListOfPropValues(this.filters.organisations, control.organisationIds).length;
    },
    controlPassFilterByFrameworksCriteria: function controlPassFilterByFrameworksCriteria(control) {
      return Utils.filterArrayByListOfPropValues(this.filters.frameworks, control.solutionIds).length;
    },
    controlPassFilterByTagsCriteria: function controlPassFilterByTagsCriteria(control) {
      var tagIds = control.tagIds || control.tags && control.tags.map(function (tag) {
        return tag.id;
      });
      return Utils.filterArrayByListOfPropValues(this.filters.tags, tagIds).length;
    },
    controlPassFilterByResponsibleCriteria: function controlPassFilterByResponsibleCriteria(control) {
      var responsibleIds = control.userIds && control.userIds.responsible && control.userIds.responsible.map(function (id) {
        return +id;
      });
      return Utils.filterArrayByListOfPropValues(this.filters.responsible, responsibleIds).length;
    },
    searchByEntity: function searchByEntity(item) {
      return item.toLowerCase().trim().indexOf(this.filters.search.toLowerCase().trim()) !== -1;
    }
  }
};