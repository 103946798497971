import { clickOutside } from '@/utils/directives/clickOutside';
import { sortAlphabetic } from '@/utils/sort';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
export default {
  name: 'SingleSelect',
  introduction: 'for when the user is typing',
  description: 'This is a simple input field that emits an "updated"-event when the value changes',
  token: "\n<single-select :options=\"options\" :modelValue=\"selectedOption\" :disabled=\"false\" :placeholder=\"translate.choose\"/>\nconst options = [\n    {value: 1, label:'Hello'},\n    {value: 2, label:'To'},\n    {value: 3, label:'You'}\n];\nlet selectedOption = 2;",
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaLabel: RismaLabel
  },
  props: {
    options: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Available options. Each option is an object {value: Boolean|Number|String, label: String}'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is disabled'
    },
    placeholder: {
      type: String,
      default: '',
      note: 'Placeholder'
    },
    modelValue: {
      required: false,
      default: null,
      type: [String, Boolean, Number],
      note: 'v-model. Selected option'
    },
    withSearch: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Add possibility to filter options'
    },
    withReset: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Add possibility to reset chosen option'
    },
    unSorted: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Do not sort items by label - keep in the order that was given'
    },
    chevronCustomLook: {
      required: false,
      default: 'text-blue-750 stroke-3',
      type: String,
      note: 'use it to customize chevron color, width etc.'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    hasResetButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'reset'],
  data: function data() {
    return {
      expanded: false,
      filterString: '',
      translate: {
        choose: this.$trans('Choose'),
        noResultsMatchingSearchCriteria: this.$trans('No results matching the search criteria'),
        listIsEmpty: this.$trans('List is empty'),
        resetToDefault: this.$trans('Reset to default')
      }
    };
  },
  computed: {
    values: function values() {
      var _this = this;
      var options = this.options.slice(0);
      if (this.filterString) {
        options = options.filter(function (o) {
          return o.label.toLowerCase().includes(_this.filterString.toLowerCase());
        });
      }
      return this.unSorted ? options : sortAlphabetic(options, 'label');
    },
    selectedValue: function selectedValue() {
      var _this2 = this;
      return this.values.find(function (o) {
        return o.value === _this2.modelValue;
      });
    },
    selectedIndex: function selectedIndex() {
      var _this3 = this;
      return this.values.findIndex(function (o) {
        return o.value === _this3.modelValue;
      });
    },
    lockedForReset: function lockedForReset() {
      return !this.withReset || !this.modelValue || this.disabled;
    },
    isPlaceholderInOptions: function isPlaceholderInOptions() {
      var _this4 = this;
      return this.values.some(function (option) {
        return option.label === _this4.placeholder;
      });
    }
  },
  methods: {
    reset: function reset() {
      this.$emit('update:modelValue', null);
    },
    focusSelected: function focusSelected(value) {
      var selectedEl = null;
      if (value === null) {
        //selectAll and 'not selected' value can be null
        selectedEl = this.$refs.selectItems.children[0];
      } else {
        selectedEl = this.$refs.selectItems.querySelector("[value=\"".concat(value, "\"]"));
      }
      this.$refs.selectItems.scrollTop = selectedEl.offsetTop;
    },
    onClick: function onClick(option) {
      if (this.disabled) {
        return;
      }
      this.$emit('update:modelValue', option.value);
      this.hide();
    },
    nextOption: function nextOption() {
      if (!this.values.length) return;
      var value;
      if (this.selectedIndex === this.values.length - 1) {
        value = this.values[0].value;
      } else {
        value = this.values[this.selectedIndex + 1].value;
      }
      this.$emit('update:modelValue', value);
      this.focusSelected(value);
    },
    prevOption: function prevOption() {
      if (!this.values.length) return;
      var value;
      if (this.selectedIndex === 0 || this.selectedIndex === -1) {
        value = this.values[this.values.length - 1].value;
      } else {
        value = this.values[this.selectedIndex - 1].value;
      }
      this.$emit('update:modelValue', value);
      this.focusSelected(value);
    },
    hide: function hide() {
      this.expanded = false;
    },
    show: function show() {
      var _this5 = this;
      if (this.disabled) {
        return;
      }
      this.expanded = true;
      this.$nextTick(function () {
        _this5.$refs.filterString && _this5.$refs.filterString.focus();
      });
    },
    toggle: function toggle() {
      var _this6 = this;
      if (this.disabled) {
        return false;
      }
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.$nextTick(function () {
          _this6.$refs.filterString && _this6.$refs.filterString.focus();
        });
      }
    }
  }
};