function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import draggable from 'vuedraggable';
import InputField from '@/components/Atoms/Inputs/InputField';
import AddOptionButton from '@/components/Atoms/Buttons/AddOptionButton';
import OverlayDeleteButton from '@/components/Atoms/Buttons/OverlayDeleteButton';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import { DragOptions } from '@/constants/DragOptions';
export default {
  name: 'OptionsList',
  components: {
    InputField: InputField,
    draggable: draggable,
    AddOptionButton: AddOptionButton,
    OverlayDeleteButton: OverlayDeleteButton,
    DragAndDropIcon: DragAndDropIcon
  },
  introduction: 'Editable list of options',
  description: 'This component allows add and delete items from list',
  token: '<options-list options="[]" label="Answer options" />',
  props: {
    placeholder: {
      required: false,
      type: String,
      default: '',
      note: 'Placeholder text'
    },
    predefinedOptions: {
      required: false,
      default: function _default() {
        return [];
      },
      type: Array,
      note: 'Array of predefined values'
    },
    draggable: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Is drag and drop enable'
    },
    labelProperty: {
      required: false,
      type: String,
      default: 'label'
    },
    cantBeDeletedProperty: {
      required: false,
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmOnDelete: {
      required: false,
      type: Object,
      default: null
    },
    showEmptyFieldsError: {
      required: false,
      type: Boolean,
      default: false
    },
    showUniqueFieldsError: {
      required: false,
      type: Boolean,
      default: false
    },
    saveButtonClickCounter: {
      required: false,
      type: Number,
      default: 0,
      note: 'required to not display errors below newly created items before user make another save button click'
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['onOptionsChange'],
  data: function data() {
    return {
      options: [],
      dragOptions: DragOptions,
      itemToDragIdx: null,
      timer: null,
      delay: 250,
      hasFocusOnMount: false,
      translate: {
        answerOptions: this.$trans('Answer options'),
        pleaseFillOutThisField: this.$trans('Please fill out this field.'),
        valueShouldBeUnique: this.$trans('Value should be unique')
      }
    };
  },
  computed: {
    uniqueFieldsIdxError: function uniqueFieldsIdxError() {
      var _this = this;
      if (!this.showUniqueFieldsError) return null;
      var result = {};
      var optionsMap = {};
      this.options.forEach(function (option, idx) {
        var label = option[_this.labelProperty];
        if (!label) return;
        if (optionsMap[label] === undefined) {
          optionsMap[label] = idx;
          return;
        }
        result[idx] = true;
        result[optionsMap[label]] = true;
      });
      return result;
    }
  },
  watch: {
    saveButtonClickCounter: function saveButtonClickCounter() {
      this.options = this.options.map(function (option) {
        option.hideError = false;
        return option;
      });
    }
  },
  mounted: function mounted() {
    this.options = this.predefinedOptions.length ? this.predefinedOptions.slice() : [_defineProperty({}, this.labelProperty, '')];
  },
  methods: {
    handleAddOption: function handleAddOption() {
      this.options.push(_defineProperty(_defineProperty({}, this.labelProperty, ''), "hideError", true));
      this.hasFocusOnMount = true;
      this.$emit('onOptionsChange', this.options);
    },
    onOptionChange: function onOptionChange(value, index) {
      var _this2 = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this2.options[index][_this2.labelProperty] = value;
        _this2.$emit('onOptionsChange', _this2.options);
      }, this.delay);
    },
    handleDeleteOption: function handleDeleteOption(idx) {
      var _this3 = this;
      if (this.confirmOnDelete) {
        this.$confirm(this.confirmOnDelete.title, this.confirmOnDelete.body, function (res) {
          return res && _this3.deleteOption(idx);
        });
        return;
      }
      this.deleteOption(idx);
    },
    getInputCalculatedClasses: function getInputCalculatedClasses(element, index) {
      var emptyFieldError = this.showEmptyFieldsError && !element.hideError && !element[this.labelProperty];
      var uniqueFieldError = this.showUniqueFieldsError && !element.hideError && this.uniqueFieldsIdxError[index];
      return emptyFieldError || uniqueFieldError ? ' border-rm-signal-red hover:border-rm-signal-red focus:border-rm-signal-red option-list-error' : '';
    },
    deleteOption: function deleteOption(idx) {
      this.options.splice(idx, 1);
      this.$emit('onOptionsChange', this.options);
    }
  }
};