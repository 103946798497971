import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
var LabelPositions = {
  LEFT: 'left',
  RIGHT: 'right'
};
export default {
  name: 'Checkboxes',
  introduction: 'Input with type=checkbox',
  description: 'This is a input with type=checkbox that emits an "input"-event when the value changes. Options can have revert parameter, this way if its not in values/v-model -> it would be checked',
  token: "\n<checkboxes\n    v-model=\"values\"\n    :options=\"options\"\n    @input=\"handleChange(checkedOptions)\" />\n\n    const values = [2];\n    const options = [{value: 1, label: 'Yes'}, {value: 2, label: 'No'}];",
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    options: {
      type: [Boolean, Object, Array],
      required: true,
      default: function _default() {
        return [];
      },
      note: 'A list of items or single object {value: 1, label: "label"} or just checked value: true/false'
    },
    modelValue: {
      type: Array,
      required: false,
      default: null,
      note: 'V-model value, array of values'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determine if all checkboxes should be disabled'
    },
    labelPosition: {
      type: String,
      required: false,
      default: 'right',
      validator: function validator(value) {
        return Object.values(LabelPositions).includes(value);
      }
    },
    checkIcon: {
      type: String,
      required: false,
      default: 'check'
    },
    checkboxSize: {
      type: [Number, String],
      required: false,
      default: 25,
      note: 'Defines checkbox`s width and height'
    },
    centerCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    truncateLabels: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['updated', 'update:modelValue'],
  data: function data() {
    return {
      LabelPositions: LabelPositions
    };
  },
  computed: {
    checkboxOptions: function checkboxOptions() {
      if (Array.isArray(this.options)) return this.options;
      if (typeof this.options === 'boolean') return [this.getCheckboxOption(this.options)];
      return [this.options];
    },
    checkboxStyles: function checkboxStyles() {
      return {
        'min-width': this.checkboxSize + 'px',
        'min-height': this.checkboxSize + 'px'
      };
    },
    checkboxClasses: function checkboxClasses() {
      return this.labelPosition === LabelPositions.RIGHT ? 'flex-row-reverse' : '';
    }
  },
  methods: {
    handleChange: function handleChange(value, isChecked) {
      if (this.modelValue === null) {
        this.$emit('updated', {
          value: value,
          isChecked: isChecked
        });
        return;
      }
      var valuesUpdated = this.getCheckedValues(value);
      var optionsUpdated = this.getCheckedOptions(valuesUpdated);
      this.$emit('updated', optionsUpdated);
      this.$emit('update:modelValue', valuesUpdated);
    },
    getCheckedOptions: function getCheckedOptions(valuesUpdated) {
      var checkedOptions = this.checkboxOptions.filter(function (option) {
        return valuesUpdated.indexOf(option.value) !== -1;
      });
      return checkedOptions.map(function (item) {
        return Object.assign({}, item);
      });
    },
    getCheckedValues: function getCheckedValues(valueChanged) {
      var result = this.modelValue.slice();
      var indexOfCheckedValue = this.modelValue.indexOf(valueChanged);
      var isValueChecked = indexOfCheckedValue !== -1;
      if (isValueChecked) {
        result.splice(indexOfCheckedValue, 1);
      } else {
        result.push(valueChanged);
      }
      result.sort();
      return result;
    },
    getCheckboxOption: function getCheckboxOption(checked) {
      return {
        value: 1,
        checked: checked
      };
    }
  }
};