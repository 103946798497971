function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { getTranslate } from './translate';
import { getDeletedNodesByProject as _getDeletedNodesByProject } from '@/api/compliance/node';
import MainWell from '@/components/Atoms/Containers/MainWell';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { toLocalDateTimeFull } from '@/utils/date';
import { compareDates } from '@/utils/compare';
export default {
  name: 'DeletedNodes',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    RismaTitle: RismaTitle
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      loading: false,
      nodes: [],
      dataset: [],
      deletedComplianceChangelog: [],
      translate: getTranslate['DeletedNodes']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    allUsers: 'allUsers'
  })), mapState(useUserStore, ['complianceProjects'])), {}, {
    userNamesById: function userNamesById() {
      return this.allUsers.reduce(function (acc, cur) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, cur.id, cur.display_name));
      }, {});
    },
    columns: function columns() {
      return {
        project: this.translate.project,
        id: this.translate.id,
        tree_left: this.translate.treeLeft,
        tree_right: this.translate.treeRight,
        title: this.translate.title,
        user: this.translate.user,
        date: this.translate.date,
        noNodesToShow: this.translate.noNodesToShow
      };
    },
    projectsNamesById: function projectsNamesById() {
      return this.complianceProjects.reduce(function (acc, cur) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, cur.id, cur.title));
      }, {});
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.loading = true;
    this.getAllDeletedNodes().then(this.prepareData).catch(function () {
      return _this.$notify({
        title: _this.translate.somethingWentWrong,
        type: 'error'
      });
    }).finally(function () {
      return _this.loading = false;
    });
  },
  methods: {
    getAllDeletedNodes: function getAllDeletedNodes() {
      var _this2 = this;
      var promises = [];
      this.complianceProjects.forEach(function (_ref) {
        var id = _ref.id;
        return promises.push(_this2.getDeletedNodesByProject(id));
      });
      return Promise.all(promises);
    },
    getDeletedNodesByProject: function getDeletedNodesByProject(id) {
      var _this3 = this;
      return _getDeletedNodesByProject(id).then(function (_ref2) {
        var list = _ref2.list;
        return _this3.nodes = _this3.nodes.concat(list);
      });
    },
    prepareData: function prepareData() {
      this.prepareDataset();
      this.enhanceDataset(this.dataset, this.columns);
    },
    prepareDataset: function prepareDataset() {
      var _this4 = this;
      this.dataset = this.nodes.map(function (node) {
        var _this4$userNamesById$;
        return {
          project: {
            field: _this4.projectsNamesById[node.compliance_projects_id]
          },
          id: {
            field: node.id
          },
          tree_left: {
            field: node.treeLeft
          },
          tree_right: {
            field: node.treeRight
          },
          title: {
            field: node.title
          },
          user: {
            field: (_this4$userNamesById$ = _this4.userNamesById[node.deletedBy]) !== null && _this4$userNamesById$ !== void 0 ? _this4$userNamesById$ : ''
          },
          date: {
            field: toLocalDateTimeFull(node.deletedAt)
          }
        };
      }).sort(function (a, b) {
        return compareDates(a.date.field, b.date.field, true);
      });
    }
  }
};