import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "text-red-50 font-semibold"
};
var _hoisted_2 = {
  key: 1,
  class: "text-red-50 font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_text_box = _resolveComponent("text-box");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $props.header,
    "button-ok-text": $data.translate.send,
    "button-dismiss-text": $data.translate.dismiss,
    onAccept: $options.onAccept,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString($props.mainText), 1 /* TEXT */), _createVNode(_component_date_time_picker, {
        title: $data.translate.chooseNewDeadline,
        "model-value": $data.data.expires,
        "plugin-options": $data.dateOptions,
        "disabled-date": $options.disabledDate,
        onValueUpdated: $options.updateDataExpires
      }, null, 8 /* PROPS */, ["title", "model-value", "plugin-options", "disabled-date", "onValueUpdated"]), !$data.isDateValid ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($data.translate.chooseNewDeadline), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_risma_label, {
        class: "pt-3",
        title: $data.translate.message
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
        class: "w-full",
        text: $data.data.message,
        onUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $data.data.message = $event;
        })
      }, null, 8 /* PROPS */, ["text"]), !$data.isMessageValid ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($data.translate.pleaseFillOutThis), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}