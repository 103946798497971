import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "z-10 relative"
};
var _hoisted_2 = {
  key: 0,
  class: "flex mb-3 pl-1"
};
var _hoisted_3 = {
  class: "flex mr-4 pt-3 pb-2 pl-4 pr-5 rounded-md cursor-pointer saved-button"
};
var _hoisted_4 = {
  class: "flex mr-4 pt-3 pb-2 pl-4 pr-5 rounded-md cursor-pointer text-white font-semibold save-report-button"
};
var _hoisted_5 = {
  class: "flex items-center"
};
var _hoisted_6 = {
  class: "flex mb-6"
};
var _hoisted_7 = {
  class: "flex items-center mr-4 pr-4 rounded-md cursor-pointer"
};
var _hoisted_8 = {
  class: "flex items-center mr-4 px-4 rounded-md cursor-pointer"
};
var _hoisted_9 = {
  class: "mr-2"
};
var _hoisted_10 = {
  key: 0,
  class: "absolute top-full left-0 px-4 py-3 bg-white z-2 column-visibility"
};
var _hoisted_11 = {
  class: "mb-2"
};
var _hoisted_12 = {
  class: "flex cursor-pointer items-center"
};
var _hoisted_13 = ["checked"];
var _hoisted_14 = {
  class: "pl-3"
};
var _hoisted_15 = ["value"];
var _hoisted_16 = {
  key: 1,
  checked: "",
  disabled: "",
  type: "checkbox"
};
var _hoisted_17 = {
  class: "pl-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_risma_excel = _resolveComponent("risma-excel");
  var _component_risma_print = _resolveComponent("risma-print");
  var _component_risma_pagesize = _resolveComponent("risma-pagesize");
  var _component_input_field = _resolveComponent("input-field");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showTopBar ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_feather_icon, {
    icon: "folder",
    class: "stroke-2 mr-2 saved-icon"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.savedReports), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_feather_icon, {
    icon: "folder",
    class: "stroke-2 mr-2 save-report-icon"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.saveCurrentReport), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.showDeactivatedControls,
    type: "small",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
    modelValue: $data.showDeactivatedOption,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.showDeactivatedOption = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue"])]), _createVNode(_component_risma_date_range_picker, {
    "start-date": "2022-03-26",
    "end-date": "2022-03-26",
    class: "self-center ml-auto cursor-pointer"
  })])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_excel, {
    handler: $props.exportHandler,
    class: "mr-3"
  }, null, 8 /* PROPS */, ["handler"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_print, {
    "head-title": $props.printTitle,
    handler: $props.exportHandler,
    class: "mr-3"
  }, null, 8 /* PROPS */, ["head-title", "handler"])]), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "flex items-center relative mr-4 px-4 rounded-md cursor-pointer",
    onClick: _cache[4] || (_cache[4] = _withModifiers(function () {
      return $options.displayColumnsVisibility && $options.displayColumnsVisibility.apply($options, arguments);
    }, ["stop"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "columns",
    width: "16",
    height: "16",
    class: "mr-3"
  }), _createElementVNode("span", _hoisted_9, _toDisplayString($data.translate.columnVisibility), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    height: "16",
    width: "16"
  }), $data.showColumnVisibility ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("label", _hoisted_12, [_createElementVNode("input", {
    checked: $options.isAllChecked,
    type: "checkbox",
    onChange: _cache[1] || (_cache[1] = function () {
      return $options.selectDeselectAll && $options.selectDeselectAll.apply($options, arguments);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13), _createElementVNode("span", _hoisted_14, _toDisplayString($data.translate.selectdeselectAll), 1 /* TEXT */)])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, function (column) {
    return _openBlock(), _createElementBlock("div", {
      key: column.key,
      class: "py-1.5"
    }, [_createElementVNode("div", null, [_createElementVNode("label", {
      class: _normalizeClass(["flex items-center", !column.static ? 'cursor-pointer' : 'opacity-50'])
    }, [!column.static ? _withDirectives((_openBlock(), _createElementBlock("input", {
      key: 0,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
        return $data.visibleColumns = $event;
      }),
      value: column.key,
      type: "checkbox",
      onChange: _cache[3] || (_cache[3] = function () {
        return $options.changeVisibleColumns && $options.changeVisibleColumns.apply($options, arguments);
      })
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_15)), [[_vModelCheckbox, $data.visibleColumns]]) : (_openBlock(), _createElementBlock("input", _hoisted_16)), _createElementVNode("span", _hoisted_17, _toDisplayString(column.label), 1 /* TEXT */)], 2 /* CLASS */)])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.closeColumnVisibility]]), _createVNode(_component_risma_pagesize, {
    modelValue: $data.selectedPageSize,
    "onUpdate:modelValue": [_cache[5] || (_cache[5] = function ($event) {
      return $data.selectedPageSize = $event;
    }), $options.onPageSizeChange],
    "paging-options": $props.pagingOptions,
    class: "flex items-center mr-8"
  }, null, 8 /* PROPS */, ["modelValue", "paging-options", "onUpdate:modelValue"]), _createElementVNode("div", {
    class: "flex items-center px-4 rounded-md cursor-pointer",
    onClick: _cache[6] || (_cache[6] = function () {
      return $options.resetFormating && $options.resetFormating.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    width: "16",
    height: "16",
    class: "mr-1 stroke-2 text-gray-450"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.resetFormatting), 1 /* TEXT */)]), $props.showSearchField ? (_openBlock(), _createBlock(_component_input_field, {
    key: 0,
    "model-value": $props.searchString,
    type: "text",
    placeholder: "".concat($data.translate.search, "..."),
    class: "ml-auto search-field",
    onUpdated: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('onGlobalSearch', $event.trim());
    })
  }, null, 8 /* PROPS */, ["model-value", "placeholder"])) : _createCommentVNode("v-if", true)])]);
}