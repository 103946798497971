import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "mb-3 flex gap-4"
};
var _hoisted_8 = {
  class: "mb-3 flex gap-4"
};
var _hoisted_9 = {
  class: "mb-3"
};
var _hoisted_10 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalHeader,
    "button-ok-text": $data.translate.saveReport,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "ok-button-disabled": $data.isOkButtonDisabled,
    "accept-on-enter": false,
    onDismiss: $options.handleDismiss,
    onAccept: $options.initReportSaving
  }, {
    body: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorMessages, function (errorMessage, i) {
        return _openBlock(), _createBlock(_component_notification, {
          key: i,
          closeable: false,
          type: "error"
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(errorMessage), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.draft.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.draft.title = $event;
        }),
        title: $data.translate.title,
        "pre-text": $data.draft.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "pre-text", "placeholder"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $data.draft.subTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.draft.subTitle = $event;
        }),
        title: $data.translate.subtitle,
        "pre-text": $data.draft.subTitle,
        placeholder: $data.translate.enterSubtitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "pre-text", "placeholder"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_select, {
        title: $data.translate.chooseBaseline,
        "selected-options": $options.preSelectedOptions.currentScoring,
        options: $options.getSelectOptions($data.openScorings, 'version'),
        multiple: false,
        "allow-empty": false,
        "track-by": "value",
        "close-on-select": true,
        "selected-on-top": false,
        "un-sorted": true,
        "label-key": "label",
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $options.updateDraft('baselineScoringId', $event[0].value);
        })
      }, null, 8 /* PROPS */, ["title", "selected-options", "options"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_select, {
        title: $data.translate.chooseManagementLevels,
        "selected-options": $options.preSelectedOptions.managementLevelIds,
        options: $options.getSelectOptions($data.managementLevels, 'name'),
        "label-key": "label",
        "track-by": "value",
        onSelected: _cache[3] || (_cache[3] = function ($event) {
          return $options.updateDraft('managementLevelIds', $event);
        })
      }, null, 8 /* PROPS */, ["title", "selected-options", "options"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_select, {
        title: $data.translate.chooseExtraColumnsForOverviewTable,
        options: $options.getSelectOptions($options.allExtraColumns, 'label'),
        "selected-options": $options.preSelectedOptions.extraColumns,
        "label-key": "label",
        "track-by": "value",
        onSelected: _cache[4] || (_cache[4] = function ($event) {
          return $options.updateDraft('fieldIds', $event);
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_select, {
        title: $data.translate.chooseOrganisationsForReport,
        options: $options.getSelectOptions(_ctx.organisations, 'visible_name'),
        "selected-options": $options.preSelectedOptions.organisations,
        "label-key": "label",
        "track-by": "value",
        onSelected: _cache[5] || (_cache[5] = function ($event) {
          return $options.updateDraft('organisationIds', $event);
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_label, {
        title: $data.translate.includePage2ForEachRisk
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
        "model-value": !!$data.draft.page2,
        onInput: _cache[6] || (_cache[6] = function ($event) {
          return $options.updateDraft('page2', +$event);
        })
      }, null, 8 /* PROPS */, ["model-value"])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_label, {
        title: $data.translate.includeNonapprovedRisksInReport
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
        "model-value": !!$data.draft.includeUnapprovedRisks,
        onInput: _cache[7] || (_cache[7] = function ($event) {
          return $options.updateDraft('includeUnapprovedRisks', +$event);
        })
      }, null, 8 /* PROPS */, ["model-value"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "inline-flex items-center mt-4 cursor-pointer",
        onClick: _cache[8] || (_cache[8] = function () {
          return $options.initFileHandling && $options.initFileHandling.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        class: "mr-2",
        icon: "upload"
      }), _createVNode(_component_risma_label, {
        class: "cursor-pointer",
        title: $data.translate.chooseBackgroundImage
      }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createVNode(_component_risma_file_upload, {
        ref: "fileUploadItem",
        "custom-attachments-allowed": $data.ImagesFormats,
        "single-file-load": true,
        onUpload: $options.handleLoadFile
      }, null, 8 /* PROPS */, ["custom-attachments-allowed", "onUpload"]), [[_vShow, false]])]), $data.imgSrc ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "mt-2 w-5/12",
        src: $data.imgSrc,
        alt: "background"
      }, null, 8 /* PROPS */, _hoisted_10)) : _createCommentVNode("v-if", true)])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "ok-button-disabled", "onDismiss", "onAccept"]);
}