var cachedItems = {};
var _cacheItem = function cacheItem(name, _callback, timeout) {
  return _callback().then(function () {
    if (cachedItems[name]) {
      clearTimeout(cachedItems[name].timeout);
    }
    cachedItems[name] = {
      cacheValid: true,
      callback: function callback() {
        return _cacheItem(name, _callback, timeout);
      }
    };
    cachedItems[name].timeout = setTimeout(function () {
      cachedItems[name].cacheValid = false;
    }, timeout * 1000);
  });
};
export { _cacheItem as cacheItem };
export var refreshExpiredCaches = function refreshExpiredCaches() {
  var promises = [];
  Object.values(cachedItems).forEach(function (value) {
    if (!value.cacheValid) {
      promises.push(value.callback());
    }
  });
  return Promise.all(promises);
};