// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.module-title[data-v-f7baefc2]:after {
  content: "";
  height: 1px;
  width: calc(100% - 90px);
  bottom: 12px;
  left: 0;
  position: absolute;
  display: block;
  --tw-bg-opacity: 1;
  background-color: rgb(151 151 151 / var(--tw-bg-opacity));
}
[data-v-f7baefc2]  .modal-container {
  max-height: 90vh;
  overflow-y: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
