// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-probability-assessment[data-v-3f9f07e2] {
  position: relative;
}
.main-title[data-v-3f9f07e2] {
  margin: 0 0 0.5rem;
}
.main-title[data-v-3f9f07e2]  h1 {
  font-size: 21px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
