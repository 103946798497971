import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full mb-4 p-4"
};
var _hoisted_2 = {
  class: "flex justify-between items-center mb-2"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  class: "ml-auto font-bold"
};
var _hoisted_5 = {
  class: "ml-auto font-bold"
};
var _hoisted_6 = {
  key: 0,
  class: "ml-auto font-bold"
};
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = {
  class: "flex items-center"
};
var _hoisted_9 = {
  key: 1,
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$incident$acco;
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.incident.severity ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    class: "mr-2",
    color: $options.trafficLightColor
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), $props.previewModal ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 1,
    ref: "preview",
    activity: $props.incident,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDismissModal: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('onDismissModal');
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        icon: "confidential",
        class: "font-semibold hover:underline",
        type: "medium",
        title: "".concat($props.incident.path, ". ").concat($props.incident.title),
        "title-attribute": $options.hoverTitle,
        "display-icon": $props.incident.confidential
      }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])) : (_openBlock(), _createBlock(_component_router_link, {
    key: 2,
    to: $props.incident.url,
    class: "incidents-list-link"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        icon: "confidential",
        class: "font-semibold hover:underline",
        type: "medium",
        title: "".concat($props.incident.path, ". ").concat($props.incident.title),
        "title-attribute": $options.hoverTitle,
        "display-icon": $props.incident.confidential
      }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]))]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_4, _toDisplayString($options.createdWithLabel), 1 /* TEXT */), _createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.incidentOccurred + ': ' + $options.date), 1 /* TEXT */), $options.archiveDate ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($data.translate.incidentArchived + ': ' + $options.archiveDate), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), $props.incident.description && $props.incident.description.replace(/<[^>]*>?/gm, '').length ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "mb-2 description-field",
    innerHTML: $options.truncateHtml($props.incident.description, 100)
  }, null, 8 /* PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.status + ': ',
    class: "mr-2",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.status), 1 /* TEXT */)]), (_$props$incident$acco = $props.incident.accountableUserIds) !== null && _$props$incident$acco !== void 0 && _$props$incident$acco.length ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.accountable + ': ',
    class: "mr-2",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.accountable), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}