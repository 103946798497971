function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import 'regenerator-runtime/runtime'; //regenerator runtime fix related to i18n (and maybe another modules)
import { createApp } from 'vue';
import { createPinia, storeToRefs } from 'pinia';
import vueScrollto from 'vue-scrollto';
import { Chart, registerables } from 'chart.js';
import chanel from './chanel.vue';
/* eslint-disable import/extensions */
import '@/assets/css/chanel.css';
import '@/assets/css/flags.css';
/* eslint-enable */
import i18n, { loadi18nMessages } from '@/i18n';
import * as Utils from '@/utils/Utils';
import { useFakeMethods } from '@/api';
import { humioEvent } from '@/api/humio';
import { getRouter } from '@/routes';
import { cacheItem } from '@/routes/storeCache';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useNotificationStore } from '@/Store/notificationStore';
import { useNotificationPopUpStore } from '@/Store/notificationPopUpStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useWorkflowStore } from '@/Store/workflowStore';
Chart.register.apply(Chart, _toConsumableArray(registerables));
chanel.router = getRouter();
var pinia = createPinia();
var app = createApp(chanel);
app.use(pinia);
var settingsStore = useSettingsStore();
var _storeToRefs = storeToRefs(settingsStore),
  settings = _storeToRefs.settings;
var userStore = useUserStore();
var notificationStore = useNotificationStore();
var notificationPopUpStore = useNotificationPopUpStore();
Promise.all([userStore.loadLoginInfo().then(function (loginInfo) {
  if (loginInfo.userIsLoggedIn) {
    return Promise.all([userStore.load(), useFrameworksStore().load(), useTagsStore().load(), cacheItem('users', function () {
      return useUsersStore().load();
    }, 180), cacheItem('organisations', function () {
      return useOrganisationsStore().load();
    }, 180), cacheItem('activityTypes', function () {
      return useActivityTypesStore().load();
    }, 180), useTrafficLights().load(), useWorkflowStore().load()]);
  }
}), cacheItem('settings', function () {
  return settingsStore.load();
}, 180), loadi18nMessages()]).then(function () {
  if (settings.value['app.fake_http_methods']) {
    useFakeMethods();
  }
  app.use(getRouter()).use(vueScrollto);
  app.config.globalProperties.$trans = function (string, values) {
    return i18n.t(string, values);
  };
  app.config.globalProperties.$truncate = function (string, length) {
    return Utils.truncate(string, length);
  };
  app.config.globalProperties.$sanitize = function (text) {
    return Utils.sanitize(text);
  };
  app.config.globalProperties.$debounceTimeout = 3000;
  app.config.globalProperties.$autoSaveDebounceTime = 3000;
  app.config.unwrapInjectedRef = true;
  app.config.warnHandler = function (msg, vm, trace) {
    //warnings are only in dev (they are not in prod)
    var name = vm ? vm.$options.name : 'N/A';
    // eslint-disable-next-line no-console
    console.error(msg, trace, name);
    humioEvent('error', name, msg + trace);
  };
  app.config.errorHandler = function (err, vm) {
    var name = vm ? vm.$options.name : 'N/A';
    // eslint-disable-next-line no-console
    console.error(err);
    humioEvent('error', name, err.stack);
  };
  app.mount('#risma');
  app.config.globalProperties.$alert = notificationStore.handleUserChoice('default');
  app.config.globalProperties.$confirm = notificationStore.handleUserChoice('confirm');
  app.config.globalProperties.$prompt = notificationStore.handleUserChoice('prompt');
  app.config.globalProperties.$notify = notificationPopUpStore.handleInstantNotification;
});