import i18n from '@/i18n';
export var getTranslate = {
  RiskDescription: function RiskDescription() {
    return {
      description: i18n.t('Description'),
      thisRiskIs: i18n.t('This risk is'),
      unapproved: i18n.t('Unapproved'),
      on: i18n.t('on'),
      showApprovalHistory: i18n.t('Show approval history'),
      status: i18n.t('Status'),
      customFields: i18n.t('Custom fields'),
      approveRisk: i18n.t('Approve risk'),
      unapproveRisk: i18n.t('Unapprove risk'),
      deleteRisk: i18n.t('Delete risk'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      copyRisk: i18n.t('Copy risk'),
      copy: i18n.t('Copy'),
      closeRisk: i18n.t('Close risk'),
      close: i18n.t('Close'),
      closeRiskWithChildren: i18n.t('Close risk with children'),
      openRiskWithChildren: i18n.t('Open risk with children'),
      open: i18n.t('Open'),
      approvalHistory: i18n.t('Approval history'),
      thisRiskHasNotPreviouslyBeenApproved: i18n.t('This risk has not previously been approved'),
      user: i18n.t('User'),
      date: i18n.t('Date'),
      inherited: i18n.t('Inherited'),
      approved: i18n.t('Approved'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      areYouSureYouWantToCopy: i18n.t('Are you sure you want to copy'),
      areYouSureYouWantToClose: i18n.t('Are you sure you want to close'),
      andItsChildren: i18n.t("and it's children"),
      areYouSureYouWantToOpen: i18n.t('Are you sure you want to open'),
      reopenRisk: i18n.t('Re-open risk'),
      closeRiskWithUnderlyingRisks: i18n.t('Close risk with underlying risks'),
      reopenRiskWithUnderlyingRisks: i18n.t('Re-open risk with underlying risks'),
      copyRiskWithUnderlyingRisks: i18n.t('Copy risk with underlying risks'),
      riskNotFound: i18n.t('Risk not found'),
      thereWasAnError: i18n.t('There was an error'),
      createUnderlyingRisk: i18n.t('Create underlying risk'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created')
    };
  },
  RiskEntry: function RiskEntry() {
    return {
      thisActivityIsReadonlyAsYourUserIsConfigu: i18n.t('This activity is read-only as your user is configured to only have read-only access'),
      activityType: i18n.t('Activity type'),
      noActivityType: i18n.t('No activity type'),
      parentRisk: i18n.t('Parent risk'),
      parentRiskIsHidden: i18n.t('Parent risk is hidden'),
      choose: i18n.t('Choose'),
      underlyingRisks: i18n.t('Underlying risks'),
      riskIsClosed: i18n.t('Risk is closed'),
      riskIsClosedPleaseReopenBeforeEditing: i18n.t('Risk is closed. Please re-open before editing'),
      here: i18n.t('Here'),
      clickSToAccessTheActiveRisk: function clickSToAccessTheActiveRisk(url) {
        return i18n.t('Click %s to access the active risk', [url]);
      },
      riskNotFound: i18n.t('Risk not found'),
      riskNumberNotNumeric: i18n.t('Risk number not numeric'),
      period: i18n.t('Period')
    };
  },
  RiskOverview: function RiskOverview() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      riskOverview: i18n.t('Risk overview'),
      createNewRisk: i18n.t('Create new risk'),
      nothingToShow: i18n.t('Nothing to show'),
      columnVisibility: i18n.t('Column visibility'),
      appliedFilters: i18n.t('Applied filters'),
      search: i18n.t('Search'),
      tags: i18n.t('Tags'),
      parentRisk: i18n.t('Parent risk'),
      organisations: i18n.t('Organisations'),
      theSelectedSearchCriteriaHaveNoResultsToDi: i18n.t('The selected search criteria have no results to display')
    };
  },
  RiskReportsThreats: function RiskReportsThreats() {
    return {
      riskAssessment: i18n.t('Risk assessment'),
      choose: i18n.t('Choose'),
      threatReport: i18n.t('Threat report'),
      threat: i18n.t('Threat'),
      risks: i18n.t('Risks'),
      systems: i18n.t('Systems'),
      actions: i18n.t('Actions'),
      controls: i18n.t('Controls'),
      threats: i18n.t('Threats')
    };
  },
  RiskReportsVulnerabilities: function RiskReportsVulnerabilities() {
    return {
      vulnerabilityReport: i18n.t('Vulnerability report'),
      vulnerability: i18n.t('Vulnerability'),
      risks: i18n.t('Risks'),
      systems: i18n.t('Systems'),
      initiatives: i18n.t('Initiatives'),
      controls: i18n.t('Controls')
    };
  },
  RisksDashboard: function RisksDashboard() {
    return {
      risksDashboard: i18n.t('Risks Dashboard'),
      period: i18n.t('Period'),
      risksCount: i18n.t('Risks count'),
      noResultsFoundYouMayWantToWidenYourSearc: i18n.t('No results found. You may want to widen your search criteria')
    };
  },
  RiskReportsEntry: function RiskReportsEntry() {
    return {
      risks: i18n.t('Risks'),
      threats: i18n.t('Threats'),
      assessedThreats: i18n.t('Threat Assessments'),
      vulnerabilities: i18n.t('Vulnerabilities')
    };
  }
};