import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "w-1/2 mr-4"
};
var _hoisted_3 = {
  key: 0,
  class: "w-1/2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_color_picker = _resolveComponent("color-picker");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.trafficLight,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.dismiss,
    "dismiss-on-click-outside": false,
    onAccept: $options.handleCreate,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [$data.errorMessages.length ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorMessages, function (error) {
            return _openBlock(), _createElementBlock("div", {
              key: error
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.trafficLight.legend,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.trafficLight.legend = $event;
        }),
        class: "mb-4",
        title: $data.translate.legend
      }, null, 8 /* PROPS */, ["modelValue", "title"]), _createVNode(_component_single_select, {
        modelValue: $data.trafficLight.module,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.trafficLight.module = $event;
        }),
        options: $options.moduleOptions,
        class: "mb-4",
        title: $data.translate.chooseModule
      }, null, 8 /* PROPS */, ["modelValue", "options", "title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.chooseColor
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_color_picker, {
        color: $data.trafficLight.color,
        class: "mb-4",
        "adaptive-width": true,
        onInput: _cache[2] || (_cache[2] = function ($event) {
          return $data.trafficLight.color = $event;
        })
      }, null, 8 /* PROPS */, ["color"])]), $options.isGap ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
        type: "medium",
        title: $data.translate.chooseTextColor
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_color_picker, {
        color: $data.trafficLight.textColor,
        class: "mb-4",
        "adaptive-width": true,
        onInput: _cache[3] || (_cache[3] = function ($event) {
          return $data.trafficLight.textColor = $event;
        })
      }, null, 8 /* PROPS */, ["color"])])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}