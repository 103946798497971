function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewRiskExpanded from '@/components/Atoms/Preview/PreviewRiskExpanded';
import MyRisksList from '@/components/Molecules/Risk/MyRisksList';
import MyProcessLibraryList from '@/components/Molecules/ProcessLibrary/MyProcessLibraryList';
import MyProcessesList from '@/components/Molecules/Processes/MyProcessesList';
import MyInformationAssetsList from '@/components/Molecules/InformationAssets/MyInformationAssetsList';
import PreviewListExpanded from '@/components/Molecules/PreviewList/PreviewListExpanded';
import TrapFocusBoundary from '@/components/Atoms/Containers/TrapFocusBoundary';
import { disableScrolling, enableScrolling, getScrollPosition } from '@/utils/setScrolling';
export default {
  name: 'SearchModal',
  introduction: 'SearchModal is the modal to show search results',
  description: 'this component is used on user-menu component',
  token: "<search-modal\n            v-if=\"showSearchModal\"\n            @toggle=\"toggleSearch\"/>",
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    MainWell: MainWell,
    PreviewRiskExpanded: PreviewRiskExpanded,
    MyRisksList: MyRisksList,
    MyProcessLibraryList: MyProcessLibraryList,
    MyProcessesList: MyProcessesList,
    MyInformationAssetsList: MyInformationAssetsList,
    PreviewListExpanded: PreviewListExpanded,
    TrapFocusBoundary: TrapFocusBoundary
  },
  props: {
    data: {
      required: false,
      type: Object,
      default: function _default() {},
      note: 'search data'
    },
    scoring: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Scoring period for ERM risks'
    },
    organisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of users'
    },
    currentUser: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'current user'
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of activity types (systems)'
    },
    workflowStatuses: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of workflow statuses for contracts'
    }
  },
  emits: ['toggle'],
  data: function data() {
    return {
      oneLineItems: 'flex items-center',
      scrollPosition: 0,
      translate: getTranslate['SearchModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['executionActivityTypes'])), {}, {
    searchResultLength: function searchResultLength() {
      var _this = this;
      var sum = 0;
      Object.values(this.loopData).forEach(function (iterationItem) {
        if (_this.data[iterationItem.dataProperty] && _this.data[iterationItem.dataProperty].length) {
          sum += _this.data[iterationItem.dataProperty].length;
        }
      });
      return sum;
    },
    loopData: function loopData() {
      return {
        initiatives: {
          dataProperty: 'initiatives',
          component: 'PreviewListExpanded',
          props: {
            title: this.translate.initiatives,
            activities: this.data.initiatives || [],
            headerOffset: '0',
            organisations: this.organisations,
            users: this.users,
            activityTypes: this.executionActivityTypes
          }
        },
        controls: {
          dataProperty: 'controls',
          component: 'PreviewListExpanded',
          props: {
            activities: this.data.controls || [],
            title: this.translate.controls,
            headerOffset: '0',
            organisations: this.organisations,
            users: this.users
          }
        },
        risks: {
          dataProperty: 'risks',
          component: 'MyRisksList',
          props: {
            scoring: this.scoring,
            risks: this.data.risks || [],
            title: this.translate.risks
          }
        },
        processTreeNodes: {
          dataProperty: 'processTreeNodes',
          component: 'MyProcessLibraryList',
          props: {
            user: this.currentUser,
            processTreeNodes: this.data.processTreeNodes || [],
            organisations: this.organisations,
            users: this.users
          }
        },
        processes: {
          dataProperty: 'processes',
          component: 'MyProcessesList',
          props: {
            title: this.translate.processes,
            processes: this.data.processes || []
          }
        },
        informationAssets: {
          dataProperty: 'informationAssets',
          component: 'MyInformationAssetsList',
          props: {
            informationAssets: this.data.informationAssets || [],
            activityTypes: this.activityTypes
          }
        },
        incidents: {
          dataProperty: 'incidents',
          component: 'PreviewListExpanded',
          props: {
            title: this.translate.incidents,
            activities: this.data.incidents || [],
            headerOffset: '0',
            organisations: this.organisations,
            users: this.users
          }
        },
        contracts: {
          dataProperty: 'contracts',
          component: 'PreviewListExpanded',
          props: {
            title: this.translate.contracts,
            activities: this.data.contracts || [],
            watermarkImage: "url('/assets/svg/ikon_contracts.svg')",
            workflowStatuses: this.workflowStatuses,
            headerOffset: '0',
            organisations: this.organisations,
            users: this.users
          }
        }
      };
    }
  }),
  watch: {
    $route: function $route() {
      this.emitToggle();
    }
  },
  mounted: function mounted() {
    this.scrollPosition = getScrollPosition();
    disableScrolling(this.scrollPosition);
  },
  unmounted: function unmounted() {
    enableScrolling(this.scrollPosition);
  },
  methods: {
    emitToggle: function emitToggle() {
      this.$emit('toggle');
    }
  }
};