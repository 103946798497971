function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import * as Utils from '@/utils/Utils';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { formatDateToFullFormat } from '@/utils/date';
import { getParsedValuesOnCfChange } from '@/utils/CustomFields';
export var CustomFieldsBaseMixin = {
  data: function data() {
    return {
      defaultPlaceholder: this.$trans('Type here') + '...',
      defaultPlaceholderSelect: this.$trans('Choose'),
      maxlength: 500,
      maxlengthForTextFields: 10000
    };
  },
  methods: {
    getSelectOptions: function getSelectOptions(options) {
      var result = [];
      options = Utils.getProperSelectedOptions(options);
      if (options && options.length) {
        result = options.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            label: item.value
          });
        });
      }
      return result;
    },
    getMultiSelectSelected: function getMultiSelectSelected(field) {
      if (!field.selectedValue) return [];
      return Object.values(field.options || {}).filter(function (option) {
        return field.selectedValue.includes(option.id);
      });
    },
    getMultiSelectSelectedValues: function getMultiSelectSelectedValues(field) {
      var _this$getMultiSelectS;
      return (_this$getMultiSelectS = this.getMultiSelectSelected(field)) === null || _this$getMultiSelectS === void 0 ? void 0 : _this$getMultiSelectS.map(function (cf) {
        return cf.value;
      }).join(', ');
    },
    getNumericalOptions: function getNumericalOptions(value) {
      return [{
        value: 1,
        label: value
      }];
    },
    onChange: function onChange(field, value) {
      var _this = this;
      if (field.typeId === CustomFieldTypes.ROW_GROUP) {
        var formattedValue = value.map(function (row) {
          return row.map(function (field) {
            return _objectSpread(_objectSpread({}, field), {}, {
              selectedValue: field.selectedValue !== undefined ? _this.getFormattedValue(field, field.selectedValue) : null
            });
          });
        });
        field.selectedValues = formattedValue;
      } else {
        field.selectedValue = this.getFormattedValue(field, value);
      }
      var idxOfChangedField = this.allFields.findIndex(function (f) {
        return f.id === field.id;
      });
      this.allFields[idxOfChangedField] = field;
      this.$emit('changed', getParsedValuesOnCfChange(this.allFields));
    },
    getFormattedValue: function getFormattedValue(field, value) {
      var formattedValue = '';
      switch (field.typeId) {
        case CustomFieldTypes.DATE_TIME:
          formattedValue = value ? formatDateToFullFormat(value) : null;
          break;
        case CustomFieldTypes.TEXT_FIELD:
          formattedValue = this.setMaxLength(value, this.maxlengthForTextFields);
          break;
        case CustomFieldTypes.SELECT_MULTI:
          formattedValue = value.length ? value.map(function (val) {
            return val.id;
          }) : null;
          break;
        default:
          formattedValue = Utils.hasValue(value) ? this.setMaxLength(value, this.maxlength) : '';
      }
      return formattedValue;
    },
    setMaxLength: function setMaxLength(e) {
      var maxLengthRestriction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.maxlength;
      return Utils.setMaxlength(e, maxLengthRestriction);
    }
  }
};