import * as RiskApi from '@/api/risk/risk';
import RiskAdminManagement from '@/components/Organisms/RiskAdmin/RiskAdminManagement';
import { RiskAdminPages } from '@/constants/risks/RiskAdminPages';
export default {
  name: 'AdminRisksThreatsVulnerability',
  description: 'Includes risk project settings, threat and vulnerability catalogues',
  token: '<admin-risks-threats-vulnerability />',
  components: {
    RiskAdminManagement: RiskAdminManagement
  },
  props: {
    filteredRiskProjects: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk projects except ERM'
    }
  },
  emits: ['refreshRiskProjects'],
  data: function data() {
    return {
      RiskAdminPages: RiskAdminPages,
      threats: [],
      vulnerabilities: [],
      threatCategories: []
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      this.$emit('refreshRiskProjects');
      return Promise.all([this.getVulnerabilities(), this.getThreatCategories()]);
    },
    getThreats: function getThreats(projectId) {
      var _this = this;
      return RiskApi.getThreats(projectId, 'includeUnrelated=true&data[]=all').then(function (_ref) {
        var list = _ref.list;
        _this.threats = list;
      }).catch(function (e) {
        throw e;
      });
    },
    getVulnerabilities: function getVulnerabilities() {
      var _this2 = this;
      return RiskApi.getVulnerabilities().then(function (_ref2) {
        var list = _ref2.list;
        _this2.vulnerabilities = list;
      }).catch(function (e) {
        throw e;
      });
    },
    getThreatCategories: function getThreatCategories() {
      var _this3 = this;
      return RiskApi.getRiskThreatCategories().then(function (response) {
        _this3.threatCategories = response.list;
      });
    },
    handleCreateCategory: function handleCreateCategory(category) {
      this.threatCategories.push(category);
    }
  }
};