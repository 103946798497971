function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { destroy, getAllByType, getAllLightWeightProcessors } from '@/api/compliance/informationAssets';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { InformationAssetsFuseMixin } from '@/components/Pages/InformationAssets/InformationAssetsFuseMixin';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import Notification from '@/components/Molecules/Notification';
import InfoToolTip from '@/components/Molecules/InfoToolTip';
import { InformationAssetsViews, IATypeViews, IATypeToComponentName } from '@/constants/compliance/InformationAssetsViews';
import { AssetsTypes, getModuleFromAssetType } from '@/constants/compliance/AssetsTypes';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { InformationAssetsDeleteMixin } from '@/components/Pages/InformationAssets/InformationAssetsDeleteMixin';
import { InformationasstesOverviewMixin } from '@/mixins/InformationAssetsOverview';
import { InfoToolTipMixin } from '@/mixins/InfoToolTipMixin';
import { clickOutside } from '@/utils/directives/clickOutside';
var PAGE_SIZE = 25;
export default {
  name: 'IAOverview',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    InputField: InputField,
    PaginatedTable: PaginatedTable,
    FeatherIcon: FeatherIcon,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    InformationAssetCreationModal: InformationAssetCreationModal,
    Notification: Notification,
    InfoToolTip: InfoToolTip
  },
  mixins: [InformationAssetsFuseMixin, InformationasstesOverviewMixin, InformationAssetsDeleteMixin, InfoToolTipMixin],
  props: {
    onlyMy: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      new: null,
      filterString: '',
      isLoaded: false,
      updated: false,
      controllers: {},
      processors: {},
      allProcessors: {},
      systems: {},
      customIA: [],
      createModal: -1,
      getFunctionsByType: {
        controllers: this.getControllers,
        processors: this.getProcessors,
        systems: this.getSystems
      },
      AssetsTypes: AssetsTypes,
      nonCustomAssetsTypes: [AssetsTypes.CONTROLLERS, AssetsTypes.PROCESSORS, AssetsTypes.SYSTEMS],
      debounceTimeout: 1000,
      debounceTimer: null,
      timer: 0,
      PAGE_SIZE: PAGE_SIZE,
      translate: getTranslate['InformationAssetsOverview']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    activityTypes: 'complianceIACustomActivityTypes'
  })), {}, {
    data: function data() {
      return this.filterByObjTypes({
        controllers: this.controllers,
        processors: this.processors,
        allProcessors: this.allProcessors,
        systems: this.systems,
        customIA: this.customIA
      }, this.activityTypes);
    },
    dataset: function dataset() {
      var _this = this;
      var dataset = [];
      this.data.forEach(function (section) {
        var data = _this.filterString && !_this.nonCustomAssetsTypes.includes(section.type) ? _this.getFilteredInformationAssets(section.list, _this.filterString) : section.list;
        dataset.push({
          id: section.id,
          list: _this.formatDataIA(data, section.type),
          total: section.total
        });
      });
      return dataset;
    },
    myIaExtention: function myIaExtention() {
      return this.onlyMy ? '-my' : '';
    },
    showAllChildren: function showAllChildren() {
      return !!this.filterString.length;
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'information asset');
    } // wellStyles() {
    // return this.data.length === 1
    //     ? 'min-w-3/10 max-w-full'
    //     : this.data.length === 2
    //       ? 'w-49/100'
    //       : 'w-8/25';
    // },
  }),
  watch: {
    onlyMy: function onlyMy() {
      this.getData();
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData(skipCustom) {
      var _this2 = this;
      this.isLoaded = false;
      var customIAParams = this.onlyMy ? "&filters[responsible][]=".concat(this.currentUser.id) : '';
      var promises = [this.getAllProcessors()];
      if (!skipCustom) promises.push(this.getCustomInformationAssets(customIAParams));
      Promise.all(promises).then(function () {
        _this2.isLoaded = true;
      });
    },
    getControllers: function getControllers(additionalParams) {
      var _this3 = this;
      var parameters = "?data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.CONTROLLERS, parameters).then(function (_ref) {
        var list = _ref.list,
          total = _ref.total;
        _this3.controllers = {
          list: list,
          total: total
        };
      });
    },
    getProcessors: function getProcessors(additionalParams) {
      var _this4 = this;
      var parameters = "?data[]=relations&data[]=systems&data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.PROCESSORS, parameters).then(function (_ref2) {
        var list = _ref2.list,
          total = _ref2.total;
        _this4.processors = {
          list: list,
          total: total
        };
      });
    },
    getAllProcessors: function getAllProcessors() {
      var _this5 = this;
      return getAllLightWeightProcessors().then(function (_ref3) {
        var list = _ref3.list,
          total = _ref3.total;
        _this5.allProcessors = {
          list: list,
          total: total
        };
      });
    },
    getSystems: function getSystems(additionalParams) {
      var _this6 = this;
      var parameters = "?data[]=relations&data[]=processors&data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.SYSTEMS, parameters).then(function (_ref4) {
        var list = _ref4.list,
          total = _ref4.total;
        _this6.systems = {
          list: list,
          total: total
        };
      });
    },
    getCustomInformationAssets: function getCustomInformationAssets(additionalParams) {
      var _this7 = this;
      var parameters = "?data[]=questionnaire_associations".concat(additionalParams);
      return getAllByType(AssetsTypes.INFORMATION_ASSETS_API, parameters).then(function (_ref5) {
        var list = _ref5.list;
        _this7.customIA = list;
      });
    },
    update: function update(data) {
      this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        activity: getFieldsFromObjectForPreview(data)
      });
      this.getData();
      this.dismissCreateModal();
      this.$emit('update');
    },
    capitalize: function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    deleteItem: function deleteItem(item) {
      var _this8 = this;
      this.deleteIA = item;
      this.$confirm(this.deleteHeader, this.deleteBody, function (res) {
        return res ? _this8.handleDelete() : _this8.deleteIA = null;
      }, {
        buttonOkText: this.translate.delete,
        buttonDismissText: this.translate.cancel
      });
    },
    handleDelete: function handleDelete() {
      var _this9 = this;
      return destroy(this.deleteIA.objType, this.deleteIA.id).then(function () {
        _this9.deleteIA = null;
        _this9.getData();
        _this9.$emit('update');
      });
    },
    getExtendedViewLink: function getExtendedViewLink(section) {
      var state = {
        initialView: InformationAssetsViews.EXTENDED
      };
      var params = {
        typeId: section.activityTypeId || section.type
      };
      if (this.onlyMy) {
        return {
          name: IATypeViews.MY,
          params: params,
          state: state
        };
      }
      if (IATypeToComponentName[section.type]) {
        return {
          name: IATypeToComponentName[section.type],
          state: state
        };
      }
      return {
        name: IATypeViews.OVERVIEW,
        params: params,
        state: state
      };
    },
    dismissCreateModal: function dismissCreateModal() {
      this.createModal = -1;
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updated) {
        this.getData();
        this.updated = false;
      }
    },
    changePage: function changePage(e, type) {
      if (!this.getFunctionsByType[type]) return;
      var additionalParams = "&limit=".concat(this.PAGE_SIZE, "&start=").concat(e.start);
      if (this.filterString) additionalParams += "&filters[name]=".concat(this.filterString);
      additionalParams = this.onlyMy ? "".concat(additionalParams, "&filters[responsible][]=").concat(this.currentUser.id) : additionalParams;
      this.getFunctionsByType[type](additionalParams);
    },
    onSearch: function onSearch() {
      var _this10 = this;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(function () {
        _this10.getData(true);
      }, this.debounceTimeout);
    },
    canCreate: function canCreate(type, activityTypeId) {
      var module = getModuleFromAssetType(type);
      return useUserStore().canCreateInformationAssets(module, activityTypeId);
    }
  }
};