import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass("preview-risk preview-risk-mode-".concat($props.mode))
  }, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.currentTypeView), {
    risk: $props.risk,
    organisations: $props.organisations,
    scoring: $props.scoring,
    "is-risk2": $props.isRisk2,
    "is-approval-flow-enabled": $props.isApprovalFlowEnabled,
    "is-show-linked-items": $props.isShowLinkedItems,
    onUpdateFromPreviewMode: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, ["risk", "organisations", "scoring", "is-risk2", "is-approval-flow-enabled", "is-show-linked-items"]))], 2 /* CLASS */);
}