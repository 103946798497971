import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_label, {
    title: $props.label,
    class: "leading-19px"
  }, null, 8 /* PROPS */, ["title"]), !$data.isEditMode && !$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "edit",
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isEditMode = true;
    })
  })) : _createCommentVNode("v-if", true)]), $data.isEditMode ? (_openBlock(), _createBlock(_component_input_field, {
    key: 0,
    "focus-on-mount": true,
    "pre-text": $data.initialText,
    maxlength: $props.maxlength,
    class: "mt-1",
    "onUpdate:modelValue": $options.updateText,
    onBlur: $options.handleUpdate,
    onOnEnter: $options.handleUpdate,
    onOnEsc: $options.cancelEdit
  }, null, 8 /* PROPS */, ["pre-text", "maxlength", "onUpdate:modelValue", "onBlur", "onOnEnter", "onOnEsc"])) : (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: _normalizeClass(['break-words leading-19px cursor-pointer', {
      'mt-1': !!$data.updatedText
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.isEditMode = true;
    })
  }, _toDisplayString($data.updatedText), 3 /* TEXT, CLASS */))]);
}