import { defineStore } from 'pinia';
import { getFrameworks } from '@/api/risma/data';
import cloneDeep from 'lodash/cloneDeep';
import { getAllDescendantsIds } from '@/utils/Utils';
export var useFrameworksStore = defineStore('frameworksStore', {
  state: function state() {
    return {
      frameworks: []
    };
  },
  getters: {
    enabledFrameworks: function enabledFrameworks() {
      return this.frameworks.filter(function (s) {
        return s.enabled;
      });
    },
    rootFrameworks: function rootFrameworks() {
      return this.enabledFrameworks.filter(function (framework) {
        return !framework.parentId;
      });
    },
    descendantsByRootFramework: function descendantsByRootFramework() {
      return this.rootFrameworks.reduce(function (acc, cur) {
        acc[cur.id] = getAllDescendantsIds(cur.children);
        return acc;
      }, {});
    }
  },
  actions: {
    load: function load() {
      var _this = this;
      return getFrameworks().then(function (_ref) {
        var list = _ref.list;
        _this.frameworks = list;
      });
    },
    addFramework: function addFramework(framework) {
      this.frameworks.push(framework);
    },
    updateFramework: function updateFramework(framework) {
      this.frameworks = this.frameworks.map(function (s) {
        return +s.id === +framework.id ? framework : s;
      });
    },
    deleteFramework: function deleteFramework(frameworkId) {
      this.frameworks = this.frameworks.filter(function (s) {
        return +s.id !== frameworkId;
      });
    },
    set: function set(frameworks) {
      this.frameworks = cloneDeep(frameworks);
    }
  }
});