import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "py-6 relative"
};
var _hoisted_2 = {
  class: "min-h-feedcard relative p-2 rounded-lg shadow-modal-sm border border-blue-750 flex flex-col"
};
var _hoisted_3 = {
  class: "absolute -top-6 left-0 right-0"
};
var _hoisted_4 = {
  class: "bg-blue-750 w-10 h-10 rounded-full mx-auto shadow-xl"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "text-center text-xs text-blue-750"
};
var _hoisted_7 = {
  class: "absolute right-4"
};
var _hoisted_8 = {
  class: "font-semibold"
};
var _hoisted_9 = {
  class: "flex flex-col flex-grow justify-between"
};
var _hoisted_10 = {
  class: "text-center my-4 h-12 overflow-hidden"
};
var _hoisted_11 = {
  class: "text-gray-610"
};
var _hoisted_12 = {
  key: 2
};
var _hoisted_13 = {
  class: "mx-3 flex flex-col justify-between"
};
var _hoisted_14 = {
  class: "h-20"
};
var _hoisted_15 = ["innerHTML"];
var _hoisted_16 = {
  key: 0,
  class: "flex absolute bottom-0.7rem overflow-hidden w-11/12"
};
var _hoisted_17 = {
  key: 1,
  class: "flex absolute bottom-0.7rem overflow-hidden w-11/12"
};
var _hoisted_18 = {
  key: 2,
  class: "flex absolute bottom-0.7rem overflow-hidden w-11/12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
    src: $options.iconPath
  }, null, 8 /* PROPS */, _hoisted_5)]), _createElementVNode("p", _hoisted_6, _toDisplayString($options.moduleTitle), 1 /* TEXT */)]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_preview_modal_link, {
    activity: $props.activity,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    })
  }, null, 8 /* PROPS */, ["activity"])]), _createElementVNode("div", {
    class: _normalizeClass(['flex items-center mx-3', !_ctx.activityIdTitle && _ctx.trafficLight === null ? 'p-3' : ''])
  }, [_ctx.trafficLight !== null ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    color: _ctx.trafficLightColor,
    class: _normalizeClass(['mr-1', {
      'mt-2 mb-1': !_ctx.activityIdTitle
    }]),
    size: "small"
  }, null, 8 /* PROPS */, ["color", "class"])) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.activityIdTitle), 1 /* TEXT */)], 2 /* CLASS */), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_router_link, {
    to: $props.activity.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        type: "medium",
        class: "text-blue-750",
        truncate: false,
        "title-attribute": $props.activity.title,
        title: $options.title
      }, null, 8 /* PROPS */, ["title-attribute", "title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), _createElementVNode("div", {
    class: _normalizeClass(['mx-2 border-gray-510 border-b-2 text-center font-semibold mb-4 text-xs', {
      'pt-4': !$options.daysToDeadline
    }])
  }, [_createElementVNode("span", _hoisted_11, _toDisplayString($options.daysToDeadline), 1 /* TEXT */), $options.controlCanBeCompleted ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    class: "text-blue-750 cursor-pointer",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.showCompletionModal && $options.showCompletionModal.apply($options, arguments);
    })
  }, " – " + _toDisplayString($data.translate.initiateCompletion), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.controlCanBeReviewed ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    class: "text-blue-750 cursor-pointer",
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.showReviewModal && $options.showReviewModal.apply($options, arguments);
    })
  }, " – " + _toDisplayString($data.translate.initiateReview), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.isContract ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_risma_title, {
    type: "small",
    title: _ctx.$trans($props.activity.workflowTitle)
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [$options.description ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    type: "small",
    class: "text-blue-750 font-semibold",
    title: $data.translate.description
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "h-12 overflow-hidden list-reset-styles",
    innerHTML: $options.description
  }, null, 8 /* PROPS */, _hoisted_15)]), _ctx.showDeadline ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_risma_title, {
    type: "small",
    class: "font-semibold text-blue-750 pr-2",
    title: $data.translate.deadline + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    title: _ctx.deadlineFormat
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _ctx.showExpirationDate ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_risma_title, {
    type: "small",
    class: "font-semibold text-blue-750 pr-2",
    title: $data.translate.expirationDate + ':'
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    type: "small",
    title: _ctx.expirationFormat
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $options.approvedStatus ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: $options.approvedStatus,
    class: "font-semibold text-blue-750",
    type: "small"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])])])])]);
}