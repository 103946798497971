function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { cacheItem } from '@/routes/storeCache';
import { getTranslate } from './translate';
import * as OrganisationApi from '@/api/admin/organisations';
import Tree from '@/components/Atoms/Tree';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import { AdminNotifications } from '@/constants/admin/AdminNotifications';
import { buildOrganisationTree, getOrganisationLevel, setDisableAddAction, insertOrganisationToTree } from '@/utils/organisations/buildTree';
import { compareStrings } from '@/utils/compare';
import { getLinksModalSettings } from '@/constants/links/LinksModalSettings';
import { RismaSupport } from '@/constants/admin/RismaSupport';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'OrganisationTree',
  components: {
    Notification: Notification,
    Tree: Tree,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Checkboxes: Checkboxes
  },
  setup: function setup() {
    return {
      isSorted: getLocalStorageComputed('org_tree_sort', false, true)
    };
  },
  data: function data() {
    return {
      treeId: 'organisation_tree',
      treeData: {},
      newChild: {},
      notificationError: '',
      successTimer: null,
      timeOut: 0,
      organisationsListTree: [],
      updateTimer: 0,
      organisationsListFlat: [],
      modalSettings: getLinksModalSettings(),
      RismaSupport: RismaSupport,
      isLoaded: false,
      treeKey: 0,
      // fix, so each inc it would remount <tree component, its super slot without this functionality,
      translate: getTranslate['OrganisationTree']()
    };
  },
  mounted: function mounted() {
    this.getOrganisationTree();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), {}, {
    getOrganisationTree: function getOrganisationTree() {
      var _this = this;
      return OrganisationApi.getAllOrganisations().then(function (response) {
        _this.organisationsListFlat = JSON.parse(JSON.stringify(response.list));
        _this.organisationsListTree = buildOrganisationTree(response.list);
        _this.setTreeData(_this.organisationsListTree);
      }).catch(function () {
        return _this.treeData = {};
      }).finally(function () {
        return _this.isLoaded = true;
      });
    },
    addChild: function addChild(node) {
      var _this2 = this;
      var params = {
        name: node.title
      };
      if (node.appendTo > 0) {
        params.parent = node.appendTo;
      }
      OrganisationApi.createOrganisation(params).then(function (response) {
        var orgLevel = getOrganisationLevel(response);
        setDisableAddAction(response, orgLevel);
        var newNode = response;
        newNode.title = response.name;
        newNode.checkaccess = true;
        newNode.children = [];
        _this2.organisationsListFlat.push(newNode);
        if (node.appendTo > 0) {
          _this2.onAddNewChild(newNode);
        } else {
          _this2.onAddNewChildToRoot(newNode);
        }
        _this2.setNotification(_this2.translate.theOrganisationHasBeenCreated, 'success', newNode.title);
      }).catch(function (error) {
        var _this2$$refs;
        _this2.setNotification(_this2.$trans(AdminNotifications.CREATE_ORGANISATION_ERROR));
        if ((_this2$$refs = _this2.$refs) !== null && _this2$$refs !== void 0 && _this2$$refs.tree) _this2.$refs.tree.loading = false;
        throw error;
      });
    },
    onAddNewChild: function onAddNewChild(newNode) {
      var _parent$children;
      var sibling = {};
      var parent = this.getParentOrg(newNode, this.treeData.children);
      if (!((_parent$children = parent.children) !== null && _parent$children !== void 0 && _parent$children.length) || !this.isSorted) {
        parent.children = [newNode];
        return this.newChild = newNode;
      }
      insertOrganisationToTree(newNode, parent.children, 0, parent.children.length - 1, sibling);
      this.treeKey += 1;
    },
    onAddNewChildToRoot: function onAddNewChildToRoot(newNode) {
      var sibling = {};
      if (this.isSorted) {
        insertOrganisationToTree(newNode, this.treeData.children, 0, this.treeData.children.length - 1, sibling);
      } else {
        sibling.id = this.treeData.children[this.treeData.children.length - 1].id;
        this.treeData.children.push(newNode);
      }
      this.treeKey += 1;
    },
    updateNode: function updateNode(params) {
      var _this3 = this;
      return OrganisationApi.updateOrganisational(params.nodeId, {
        name: params.title
      }).then(function (response) {
        var sibling = {};
        var parent = _this3.getParentOrg(response, _this3.treeData.children) || _this3.treeData;
        var oldNodeIndex = parent.children.findIndex(function (item) {
          return item.id !== response.id;
        });
        var node = _objectSpread(_objectSpread(_objectSpread({}, parent.children[oldNodeIndex - 1]), response), {}, {
          title: response.name
        });
        _this3.updateOrgListFlat(response);
        if (node && node.id && _this3.isSorted) {
          parent.children.splice(oldNodeIndex - 1, 1);
          insertOrganisationToTree(node, parent.children, 0, parent.children.length - 1, sibling);
          if (sibling.id) {
            return _this3.$refs.tree.moveNode(node.id, sibling.id);
          } else {
            return _this3.$refs.tree.moveNode(node.id, parent.children[0].id, 'before');
          }
        } else {
          parent.children.splice(oldNodeIndex - 1, 1, node);
        }
      }).catch(function (error) {
        var _this3$$refs;
        _this3.setNotification(_this3.$trans(AdminNotifications.CREATE_ORGANISATION_ERROR));
        if ((_this3$$refs = _this3.$refs) !== null && _this3$$refs !== void 0 && _this3$$refs.tree) _this3.$refs.tree.loading = false;
        throw error;
      });
    },
    getOrganisationActivities: function getOrganisationActivities(id) {
      return OrganisationApi.getOrganisationActivities(id);
    },
    getDeleteErrorMessage: function getDeleteErrorMessage(activities) {
      var _this4 = this;
      var message = '';
      Object.keys(activities).forEach(function (modal) {
        if (_this4.modalSettings[modal] && activities[modal].length) {
          message += "\n\n".concat(_this4.modalSettings[modal].label, ":\n");
          var modalList = activities[modal].map(function (item) {
            return "".concat(item.path ? item.path + '-' : '', " ").concat(item.title);
          });
          message += "".concat(modalList.join('\n'));
        }
      });
      var result = "".concat(this.translate.thisOrganisationHaveBeenRemovedForAllActivi, ": ").concat(message, "\n\n                ").concat(this.translate.theOrganisationCannotBeRemovedAutomatically, "\n                ").concat(this.translate.pleaseContactRismaSupportForAssistance, ": ") + "<a class=\"hover:underline\" href=\"mailto:".concat(this.RismaSupport.email, "\">").concat(this.RismaSupport.email, "</a> / <a class=\"hover:underline\" href=\"tel:").concat(this.RismaSupport.tel, "\">").concat(this.RismaSupport.telTitle, "</a>");
      return result;
    },
    deleteOrganisation: function deleteOrganisation(_ref) {
      var _this5 = this;
      var nodeId = _ref.nodeId;
      this.notificationError = '';
      return OrganisationApi.deleteOrganisation(nodeId).then(function (response) {
        var parentOrg = _this5.getParentOrg(response, _this5.treeData.children) || _this5.treeData;
        parentOrg.children = parentOrg.children.filter(function (node) {
          return node.id !== nodeId;
        });
        _this5.organisationsListFlat = _this5.organisationsListFlat.filter(function (node) {
          return node.id !== nodeId;
        });
        _this5.$notify({
          title: _this5.translate.theOrganisationWasSuccessfullyDeleted,
          type: 'success'
        });
        cacheItem('users', function () {
          return _this5.loadUsers();
        }, 180);
      }).catch(function (error) {
        if (error.response.error === AdminNotifications.SERVER_DELETE_ERROR) {
          _this5.notificationError = _this5.$trans(AdminNotifications.DELETE_ORGANISATION_ERROR);
        } else if (error.response.error === AdminNotifications.SERVER_DELETE_ORG_ERROR) {
          _this5.getErrorNotificationByActivities(nodeId).then(function (message) {
            return _this5.notificationError = message;
          });
        } else {
          _this5.notificationError = _this5.$trans(AdminNotifications.DELETE_ORGANISATION_IN_USE_ERROR);
        }
        window.scrollTo(0, 0);
        return Promise.reject(error.response.error);
      });
    },
    getErrorNotificationByActivities: function getErrorNotificationByActivities(orgId) {
      var _this6 = this;
      return this.getOrganisationActivities(orgId).then(function (organisation) {
        return _this6.getDeleteErrorMessage(organisation);
      });
    },
    updateOrgListFlat: function updateOrgListFlat(organisation) {
      var _this7 = this;
      var oldNode = {};
      this.organisationsListFlat.forEach(function (item, index) {
        if (+item.id === +organisation.id) {
          oldNode = _objectSpread({}, item);
          _this7.organisationsListFlat[index] = _objectSpread(_objectSpread(_objectSpread({}, item), organisation), {}, {
            title: organisation.name
          });
        }
        if (oldNode.visible_name) {
          if (item.visible_name.startsWith(oldNode.visible_name)) {
            item.visible_name = item.visible_name.replace(oldNode.visible_name, organisation.visible_name);
            item.visible_name.split(' > ').forEach(function (name, index) {
              return item["level".concat(index + 1)] = name;
            });
          }
        }
      });
    },
    getParentOrg: function getParentOrg(org, orgArray) {
      var names = org.visible_name.split(' > ');
      names.splice(-1);
      var parent;
      names.forEach(function (name, index) {
        parent = orgArray.find(function (item) {
          return item.name === name;
        });
        if (parent) {
          if (names[index + 1]) {
            orgArray = parent.children;
          }
        }
      });
      return parent;
    },
    addChildOnNode: function addChildOnNode() {
      this.$refs.tree.openCreateNodeModal();
      var treeContainer = this.$el.querySelector('#tree-jquery');
      treeContainer.scrollTop = treeContainer.scrollHeight;
    },
    hideErrorNotification: function hideErrorNotification() {
      var _this8 = this;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(function () {
        return _this8.notificationError = '';
      }, 3000);
    },
    sortByAlphabet: function sortByAlphabet(event) {
      this.isSorted = event.isChecked;
      this.setUpdateTimer();
    },
    setUpdateTimer: function setUpdateTimer() {
      var _this9 = this;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(function () {
        _this9.organisationsListTree = buildOrganisationTree(_this9.organisationsListFlat);
        _this9.setTreeData(_this9.organisationsListTree);
      }, 300);
    },
    sortTree: function sortTree(array) {
      var _this10 = this;
      if (!array.length) return;
      array.forEach(function (item) {
        if (item.children && item.children.length) {
          item.children = _this10.sortTree(item.children);
        }
      });
      array.sort(function (a, b) {
        return compareStrings(a.name.toLowerCase(), b.name.toLowerCase());
      });
      return array;
    },
    setTreeData: function setTreeData(list) {
      var newList = _toConsumableArray(list);
      if (this.isSorted) {
        this.sortTree(newList);
      }
      this.treeData = {
        children: newList
      };
    },
    setNotification: function setNotification(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      var organisationName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      this.$notify({
        title: [message, organisationName],
        type: type
      });
    }
  })
};