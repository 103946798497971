import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_links_box = _resolveComponent("links-box");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_links_box, {
    title: "Lorem Ipsum",
    links: $data.links
  }, null, 8 /* PROPS */, ["links"]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_links_box, {
    links: $data.links
  }, null, 8 /* PROPS */, ["links"]), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_links_box, {
    title: "Empty list",
    links: []
  })]);
}