function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import { getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
import { prepareGroupedStakeholdersOptions, prepareGroupedSystemsOptions } from '@/utils/dataFlowUtils';
export default {
  name: 'DataflowModal',
  introduction: 'A modal, that handles dataflows',
  description: 'this organism is derived from the modal molecule.',
  token: '<dataflow-modal/>',
  components: {
    RismaSelect: RismaSelect,
    RismaSwitch: RismaSwitch,
    SingleSelect: SingleSelect,
    Modal: Modal,
    TextBox: TextBox
  },
  props: {
    projectId: {
      required: true,
      type: Number,
      note: 'ID of current project'
    },
    nodeId: {
      required: true,
      type: Number,
      note: 'ID of current node'
    },
    stakeholders: {
      type: Array,
      required: true,
      note: 'Stakeholders'
    },
    dataTypes: {
      type: Array,
      required: true,
      note: 'Data types'
    },
    systems: {
      type: Array,
      required: true,
      note: 'Systems'
    },
    systemsTitle: {
      type: String,
      required: false,
      default: 'Systems',
      note: 'Systems title'
    }
  },
  emits: ['toggleModal', 'createFlow'],
  data: function data() {
    return {
      showModal: false,
      invalid: false,
      selectedDirection: 'deliver',
      directions: [],
      selectedStakeholderId: null,
      selected: {
        dataTypes: [],
        systems: []
      },
      dataFlowStakeholdersTitle: getDataflowStakeholdersTitle(),
      comments: '',
      translate: getTranslate['DataflowModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isCommentsEnabled: function isCommentsEnabled() {
      return this.getSettingValue('feature.data_flow_comments');
    },
    stakeholderOptions: function stakeholderOptions() {
      return prepareGroupedStakeholdersOptions(this.stakeholders);
    },
    systemsOptions: function systemsOptions() {
      return prepareGroupedSystemsOptions(this.systems);
    }
  }),
  beforeMount: function beforeMount() {
    this.initDirections();
  },
  methods: {
    initDirections: function initDirections() {
      this.directions = [{
        name: this.translate.receives,
        value: 'receive'
      }, {
        name: this.translate.delivers,
        value: 'deliver'
      }];
    },
    handleSelected: function handleSelected(field, e) {
      this.selected[field] = e ? e : [];
    },
    selectStakeholder: function selectStakeholder(stakeholderId) {
      this.selectedStakeholderId = stakeholderId;
      this.invalid = false;
    },
    formatSelected: function formatSelected() {
      var _this = this;
      return {
        stakeholder: this.stakeholders.find(function (s) {
          return s.uniqueId === _this.selectedStakeholderId;
        }),
        direction: this.selectedDirection,
        dataTypes: this.selected.dataTypes,
        systems: this.selected.systems,
        comments: this.comments
      };
    },
    addDataflow: function addDataflow() {
      this.invalid = false;
      if (!this.selectedStakeholderId) {
        this.invalid = true;
        return false;
      }
      this.$emit('createFlow', this.formatSelected());
    },
    handleDismiss: function handleDismiss() {
      var _this2 = this;
      this.$confirm(this.translate.areYouSure, this.translate.ifYouDeleteThisDraftTheEnteredDataWillNo, function (res) {
        return res && _this2.$emit('toggleModal');
      });
    },
    acceptAlert: function acceptAlert() {
      this.$emit('toggleModal');
    }
  }
};