import { getTranslate } from './translate';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import { DateFormats } from '@/constants/DateFormats';
import { isDateAfter, formatTimeToDateFormat, getCurrentDateWithMove } from '@/utils/date';
export default {
  name: 'DpmActionModal',
  introduction: 'Modal with fields Deadline and Message',
  description: 'The component is used in the AuditOverview',
  components: {
    DateTimePicker: DateTimePicker,
    TextBox: TextBox,
    Modal: Modal,
    RismaLabel: RismaLabel
  },
  props: {
    header: {
      type: String,
      require: false,
      default: 'modal header'
    },
    mainText: {
      type: String,
      require: false,
      default: 'modal main paragraph'
    },
    message: {
      type: String,
      require: false,
      default: 'default message value'
    }
  },
  emits: ['dismiss', 'acceptModal'],
  data: function data() {
    return {
      dateOptions: {
        format: DateFormats.DATE_FORMAT,
        widgetPositioning: {
          vertical: 'bottom',
          horizontal: 'auto'
        },
        showTodayButton: false
      },
      data: {
        expires: getCurrentDateWithMove(1, 'day'),
        message: this.message
      },
      isDateValid: true,
      isMessageValid: true,
      isSent: false,
      translate: getTranslate['DpmActionModal']()
    };
  },
  methods: {
    updateDataExpires: function updateDataExpires(date) {
      this.data.expires = formatTimeToDateFormat(date);
    },
    onAccept: function onAccept() {
      this.isDateValid = true;
      this.isMessageValid = true;
      if (this.isSent) return;
      if (this.data.message.trim() === '') {
        this.isMessageValid = false;
      }
      if (!this.isDateAfterToday(this.data.expires)) {
        this.isDateValid = false;
      }
      if (this.isDateValid && this.isMessageValid) {
        this.$emit('acceptModal', this.data);
        this.isSent = true;
      }
    },
    isDateAfterToday: function isDateAfterToday(date) {
      return isDateAfter(date);
    },
    disabledDate: function disabledDate(date) {
      return !this.isDateAfterToday(date);
    }
  }
};