import { clickOutside } from '@/utils/directives/clickOutside';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'Selector',
  introduction: 'Multiselect element',
  description: 'Search field and dropdown list of items to select',
  token: '<selector/>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    items: {
      type: Array,
      required: true,
      note: 'All items to select from'
    },
    title: {
      type: String,
      required: false,
      default: 'Add new item',
      note: 'Title'
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Search on items',
      note: 'Placeholder for search field'
    },
    searchFields: {
      type: Array,
      required: false,
      default: function _default() {
        return ['title'];
      },
      note: 'Item fields to search from'
    },
    changeFocusOnHover: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Focus changes if user hover on selectable items'
    },
    resetValueFeature: {
      type: Boolean,
      required: false,
      default: true
    },
    searchValueProp: {
      type: String,
      required: false,
      default: ''
    },
    isValid: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['selected', 'update:searchValueProp', 'toggleSelect'],
  data: function data() {
    return {
      searchValue: '',
      focusedItemIndex: 0,
      showList: false,
      translate: {
        noResultsMatchingSearchCriteria: this.$trans('No results matching the search criteria'),
        listIsEmpty: this.$trans('List is empty')
      }
    };
  },
  computed: {
    filteredItems: function filteredItems() {
      var _this = this;
      return this.items.filter(function (item) {
        return _this.itemIsSearched(item);
      });
    }
  },
  watch: {
    focusedItemIndex: function focusedItemIndex(newIndex) {
      this.setFocusOnItem(newIndex);
    },
    items: {
      deep: true,
      handler: function handler(newItems) {
        var _this2 = this;
        if (newItems.length) {
          this.$nextTick(function () {
            _this2.focusOnItemSelect();
          });
        }
      }
    },
    searchValueProp: function searchValueProp(value) {
      this.searchValue = value;
    },
    showList: function showList() {
      this.$emit('toggleSelect', this.showList);
    }
  },
  mounted: function mounted() {
    this.searchValue = this.searchValueProp;
  },
  methods: {
    handleInput: function handleInput() {
      this.$emit('update:searchValueProp', this.searchValue);
      this.showList = true;
    },
    itemIsSearched: function itemIsSearched(item) {
      var searchInput = this.searchValue.toLowerCase();
      return this.searchFields.find(function (field) {
        var itemField = item[field];
        if (!itemField) return;
        return itemField.toString().toLowerCase().includes(searchInput);
      });
    },
    hideList: function hideList() {
      this.showList = false;
      if (this.resetValueFeature) {
        this.searchValue = '';
      }
    },
    keyDownOnSearch: function keyDownOnSearch() {
      var _this3 = this;
      if (!this.filteredItems.length) return;
      this.showList = true;
      this.$nextTick(function () {
        return _this3.setFocusOnItem(0);
      });
    },
    focusOnItemSelect: function focusOnItemSelect() {
      if (this.focusedItemIndex === 0) {
        this.setFocusOnItem(0);
      } else {
        this.focusPrevItem();
      }
    },
    focusNextItem: function focusNextItem() {
      if (!this.filteredItems.length) return;
      this.focusedItemIndex += 1;
      if (this.focusedItemIndex >= this.filteredItems.length) {
        this.focusedItemIndex = 0;
      }
    },
    focusPrevItem: function focusPrevItem() {
      if (!this.filteredItems.length) return;
      this.focusedItemIndex -= 1;
      if (this.focusedItemIndex < 0) {
        this.focusedItemIndex = this.filteredItems.length - 1;
      }
    },
    setFocusOnItem: function setFocusOnItem(index) {
      var items = Array.from(this.$el.querySelectorAll('.item'));
      if (items[index]) {
        items[index].focus();
      }
    },
    selected: function selected(item, index) {
      var _this4 = this;
      this.focusedItemIndex = index;
      this.$emit('selected', item);
      this.$nextTick(function () {
        if (_this4.filteredItems.length === 0 || !_this4.resetValueFeature) {
          _this4.hideList();
          return;
        }
      });
    }
  }
};