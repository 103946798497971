function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import createFocusTrap from '@/utils/createFocusTrap';
export default {
  name: 'TrapFocusBoundary',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    groupSelectors: {
      type: String,
      required: false,
      default: '',
      note: 'Selectors of containers which should be grouped into one trap module.'
    }
  },
  data: function data() {
    return {
      trapContext: null,
      domObserverList: [],
      observerConfig: {
        childList: true,
        subtree: true
      },
      updatingDelay: 500,
      updatingTimer: null,
      groupedElements: []
    };
  },
  watch: {
    groupSelectors: function groupSelectors() {
      this.disconnect();
      this.trapContext.remove();
      this.connect();
    }
  },
  beforeUnmount: function beforeUnmount() {
    this.disconnect();
    this.trapContext.remove();
  },
  mounted: function mounted() {
    var _this = this;
    this.$watch('active', function (value) {
      if (value) {
        _this.connect();
      } else {
        _this.disconnect();
      }
    }, {
      immediate: true
    });
  },
  methods: {
    findTrappingElements: function findTrappingElements() {
      var trapElements = [];
      if (this.groupSelectors) {
        var elements = document.querySelectorAll(this.groupSelectors);
        if (elements) {
          trapElements.push.apply(trapElements, _toConsumableArray(elements));
        }
      }
      trapElements.push(this.$el);
      this.groupedElements = trapElements;
    },
    updateTrapping: function updateTrapping() {
      var _this2 = this;
      this.updatingTimer && clearTimeout(this.updatingTimer);
      this.updatingTimer = setTimeout(function () {
        _this2.trapContext.update();
        _this2.updatingTimer = null;
      }, this.updatingDelay);
    },
    disconnect: function disconnect() {
      this.trapContext && this.trapContext.deactivate();
      this.domObserverList.forEach(function (observer) {
        observer.disconnect();
      });
    },
    connect: function connect() {
      this.findTrappingElements();
      this.trapContext = createFocusTrap(document.body, this.groupedElements);
      this.trapContext.activate();
      this.updateObservers();
    },
    updateObservers: function updateObservers() {
      this.prepareObserverList();
      this.activateObservers();
    },
    prepareObserverList: function prepareObserverList() {
      this.domObserverList = new Array(this.groupedElements.length).fill(new MutationObserver(this.updateTrapping));
    },
    activateObservers: function activateObservers() {
      var _this3 = this;
      this.domObserverList.forEach(function (observer, i) {
        observer.observe(_this3.groupedElements[i], _this3.observerConfig);
      });
    }
  }
};