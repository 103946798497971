function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "max-w-screen-xxl mx-auto"
};
var _hoisted_2 = {
  class: "flex flex-col"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  key: 0,
  class: "min-h-12rem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pageStructure, function (row, index) {
    return _openBlock(), _createElementBlock("div", {
      key: 'row-' + index
    }, [row.title ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      title: _ctx.$trans(row.title),
      class: "mb-2"
    }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.components, function (component, componentIndex) {
      return _openBlock(), _createElementBlock("div", {
        key: 'column-' + componentIndex,
        class: _normalizeClass(["flex flex-col mb-4", [{
          'pr-6': row.components.length - 1 !== componentIndex,
          'pl-6': componentIndex !== 0
        }]]),
        style: _normalizeStyle({
          width: (component.width || 100 / row.components.length) + '%'
        })
      }, [component.name === 'Empty' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toConsumableArray(_cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "border border-gray-350 px-4 py-2 h-full"
      }, null, -1 /* HOISTED */)])))) : (_openBlock(), _createBlock(_resolveDynamicComponent(component.name), _mergeProps({
        key: 1,
        ref_for: true
      }, component.props), null, 16 /* FULL_PROPS */))], 6 /* CLASS, STYLE */);
    }), 128 /* KEYED_FRAGMENT */))])]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}