import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = ["for"];
var _hoisted_3 = ["id", "placeholder", "maxlength", "disabled"];
var _hoisted_4 = {
  key: 1,
  class: "absolute z-1 flex justify-between w-full mt-1"
};
var _hoisted_5 = {
  key: 0,
  class: "glob-l1 text-rm-signal-red"
};
var _hoisted_6 = {
  key: 1,
  class: "text-right ml-auto glob-l2 text-rm-signal-grey-dark"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.label ? (_openBlock(), _createElementBlock("label", {
    key: 0,
    for: $options.id,
    class: _normalizeClass(["block mb-1 glob-h3 text-rm-text w-fit", {
      'cursor-pointer': !$props.disabled
    }])
  }, _toDisplayString($props.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true), _withDirectives(_createElementVNode("textarea", {
    id: $options.id,
    ref: "textarea",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.editedText = $event;
    }),
    placeholder: $props.placeholder,
    maxlength: $props.maxLength,
    disabled: $props.disabled,
    class: _normalizeClass($options.textareaClasses),
    style: _normalizeStyle({
      minHeight: $props.minHeight + 'px'
    }),
    onInput: _cache[1] || (_cache[1] = function () {
      return $options.pushEvent && $options.pushEvent.apply($options, arguments);
    }),
    onBlur: _cache[2] || (_cache[2] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    }),
    onFocus: _cache[3] || (_cache[3] = function () {
      return $options.onFocus && $options.onFocus.apply($options, arguments);
    }),
    onKeyup: _cache[4] || (_cache[4] = _withKeys(_withModifiers(function () {}, ["stop"]), ["enter"]))
  }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_3), [[_vModelText, $data.editedText]]), $props.error || $data.editedText.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [$props.error ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.error), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.isFocused && $data.editedText.length ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($options.counter), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}