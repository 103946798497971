import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risk_admin_management = _resolveComponent("risk-admin-management");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.filteredRiskProjects.length ? (_openBlock(), _createBlock(_component_risk_admin_management, {
    key: 0,
    "page-name": $data.RiskAdminPages.THREAT,
    "filtered-risk-projects": $props.filteredRiskProjects,
    items: $data.threats,
    "threat-categories": $data.threatCategories,
    class: "relative z-10",
    "onRefresh:threats": $options.getThreats,
    onRefreshRiskProjects: $options.getData,
    "onCreate:category": $options.handleCreateCategory
  }, null, 8 /* PROPS */, ["page-name", "filtered-risk-projects", "items", "threat-categories", "onRefresh:threats", "onRefreshRiskProjects", "onCreate:category"])) : _createCommentVNode("v-if", true), $props.filteredRiskProjects.length ? (_openBlock(), _createBlock(_component_risk_admin_management, {
    key: 1,
    "page-name": $data.RiskAdminPages.VULNERABILITY,
    "filtered-risk-projects": $props.filteredRiskProjects,
    items: $data.vulnerabilities,
    onRefreshRiskProjects: $options.getData
  }, null, 8 /* PROPS */, ["page-name", "filtered-risk-projects", "items", "onRefreshRiskProjects"])) : _createCommentVNode("v-if", true)]);
}