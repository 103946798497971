// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch.disabled[data-v-cf1008fa] {
  cursor: not-allowed;
}
.switch.disabled .switch-body[data-v-cf1008fa] {
  border-color: #0e2954;
  background-color: rgba(190, 190, 190, 0.5);
}
.switch.disabled .switch-body .switch-icon[data-v-cf1008fa] {
  opacity: 0.3;
}
.switch[data-v-cf1008fa] {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.switch-label[data-v-cf1008fa] {
  font-size: 14px/16px;
  color: #737373;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.switch-body[data-v-cf1008fa] {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  border: 1px solid #0e2954;
  border-radius: 8px;
  transition: all 0.2s ease;
}
.switch--labels .switch-body[data-v-cf1008fa] {
  margin-right: 10px;
}
.switch--between.switch--labels .switch-body[data-v-cf1008fa] {
  margin-left: 10px;
}
.switch--between.switch--labels .switch-left[data-v-cf1008fa] {
  font-weight: bold;
}
.switch--between.switch--labels .switch-right[data-v-cf1008fa] {
  font-weight: normal;
}
.switch--between.switch--labels.switch--on .switch-left[data-v-cf1008fa] {
  font-weight: normal;
}
.switch--between.switch--labels.switch--on .switch-right[data-v-cf1008fa] {
  font-weight: bold;
}
.switch-icon[data-v-cf1008fa] {
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 3px;
  border: 1px solid #0e2954;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.switch-icon-switch[data-v-cf1008fa] {
  background-color: #0e2954;
}
.switch--on .switch-icon[data-v-cf1008fa] {
  background-color: #0e2954;
  left: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
