import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "mb-8 relative"
};
var _hoisted_3 = {
  class: "absolute right-0 top-3 italic text-blue750 max-w-1/2"
};
var _hoisted_4 = {
  class: "mb-8"
};
var _hoisted_5 = {
  class: "mb-8"
};
var _hoisted_6 = {
  class: "h-full border-l-4 border-green-150 pl-4"
};
var _hoisted_7 = {
  class: "mb-4"
};
var _hoisted_8 = {
  class: "mb-4"
};
var _hoisted_9 = {
  key: 0,
  class: "py-2"
};
var _hoisted_10 = {
  key: 1,
  class: "py-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($data.translate.synchronisationRequires), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $data.translate.synchroniseViaMicrosoftGraph,
    type: "medium",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_checkboxes, {
    modelValue: $data.userSynchronisation,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.userSynchronisation = $event;
    }),
    options: $options.userSynchronisationOptions,
    disabled: !$props.credentialsValid
  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.whenTheUserHasBeenDisabledRemotely,
    type: "medium",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_radio_buttons, {
    modelValue: $data.selectedDeactivationAction,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedDeactivationAction = $event;
    }),
    options: $options.deactivationActionOptions,
    "no-unchecked-value": true
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.userCreation,
    type: "medium",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_radio_buttons, {
    modelValue: $data.selectedUserCreationAction,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.selectedUserCreationAction = $event;
    }),
    options: $options.userCreationActions,
    "no-unchecked-value": true
  }, null, 8 /* PROPS */, ["modelValue", "options"])]), _createVNode(_component_risma_button, {
    text: $data.translate.testConnection,
    type: "util",
    disabled: !$options.readyForSync,
    onClick: $options.checkConnection
  }, {
    default: _withCtx(function () {
      return [$data.checkingConnection ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "onClick"]), _createVNode(_component_risma_button, {
    ref: "saveSettings",
    text: $data.translate.saveSettings,
    type: "save",
    class: "m-auto mt-8",
    onClick: $options.saveSettings
  }, null, 8 /* PROPS */, ["text", "onClick"]), $data.connectionSuccessResults ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.connectionOk,
    "show-buttons": false,
    "body-full-height": true,
    type: "big",
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return $data.connectionSuccessResults = null;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_6, [_createElementVNode("h3", _hoisted_7, _toDisplayString($options.resultHeadline), 1 /* TEXT */), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.connectionSuccessResults, function (action) {
        return _openBlock(), _createElementBlock("div", {
          key: action.title,
          class: "py-2"
        }, [_createVNode(_component_collapsible_panel, null, {
          header: _withCtx(function () {
            return [_createVNode(_component_risma_title, {
              title: action.title,
              type: "small",
              class: "min-w-150px font-semibold"
            }, null, 8 /* PROPS */, ["title"])];
          }),
          content: _withCtx(function () {
            return [action.users ? (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(action.users, function (user) {
              return _openBlock(), _createElementBlock("div", {
                key: user
              }, _toDisplayString(user.fullname) + " - " + _toDisplayString(user.email), 1 /* TEXT */);
            }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), action.messages ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(action.messages, function (message) {
              return _openBlock(), _createElementBlock("div", {
                key: message
              }, _toDisplayString(message), 1 /* TEXT */);
            }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]);
      }), 128 /* KEYED_FRAGMENT */))])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])) : _createCommentVNode("v-if", true)]);
}