function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import * as Utils from '@/utils/Utils';
export default {
  name: 'AttachmentSection',
  introduction: 'A section that handles attachments',
  description: 'Section for upload attachments (files / title & url), uses inside AttachmentModal component.',
  token: '<attachment-section @input="handlerFn(attachment)" />',
  components: {
    InputField: InputField,
    RismaFileUpload: RismaFileUpload,
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon
  },
  props: {
    linkTitlePredefined: {
      type: String,
      required: false,
      note: 'Predefined title of a link',
      default: ''
    },
    linkUrlPredefined: {
      type: String,
      required: false,
      note: 'Predefined url of a link',
      default: ''
    },
    multipleLinks: {
      type: Boolean,
      required: false,
      default: false
    },
    showEmptyFieldsError: {
      type: Boolean,
      required: false,
      default: false
    },
    uploadingValue: {
      type: Number,
      required: false,
      default: 0,
      note: 'uploaded value for progress bar'
    },
    uploadingMax: {
      type: Number,
      required: false,
      default: 0,
      note: 'total value to be loaded for progress bar'
    },
    isFilesDisabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is files upload section disabled'
    },
    showProgressBar: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['input', 'update', 'uploadFiles', 'startLoading'],
  data: function data() {
    return {
      updateTimeout: 300,
      timer: 0,
      links: [],
      maxlength: 2000,
      // limit for urls,
      fileUploadKey: 1,
      translate: getTranslate['AttachmentSection']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    useFormData: function useFormData() {
      return !!this.getSettingValue('feature.form_data_upload');
    }
  }),
  created: function created() {
    this.addItemToLinks(this.linkUrlPredefined, this.linkTitlePredefined);
  },
  methods: {
    uploadFiles: function uploadFiles(eventName, data) {
      this.fileUploadKey += 1;
      this.$emit(eventName, data);
    },
    addItemToLinks: function addItemToLinks() {
      var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.links.push({
        url: url,
        filename: filename
      });
      this.updateLinksData();
    },
    removeItemFromLinks: function removeItemFromLinks(idx) {
      this.links.splice(idx, 1);
      this.updateLinksData();
    },
    prepareLinksData: function prepareLinksData() {
      var _this = this;
      var result = [];
      this.links.forEach(function (link, idx) {
        var url = link.url.length >= _this.maxlength ? _this.setMaxlength(link.url) : link.url;
        var filename = link.filename.length >= _this.maxlength ? _this.setMaxlength(link.filename) : link.filename;
        _this.links[idx] = {
          url: url,
          filename: filename
        };
        result.push({
          url: url,
          filename: filename
        });
      });
      return this.multipleLinks ? result : result[0];
    },
    updateLinksData: function updateLinksData() {
      var _this2 = this;
      var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this2.$emit('update', _this2.prepareLinksData());
      }, timeout);
    },
    setMaxlength: function setMaxlength(e) {
      return Utils.setMaxlength(e, this.maxlength);
    }
  }
};