import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "preview preview-process-tree-node"
};
var _hoisted_2 = {
  class: "preview-cont"
};
var _hoisted_3 = {
  class: "preview-header"
};
var _hoisted_4 = {
  key: 0,
  class: "preview-body"
};
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_router_link, {
    to: $props.node.url
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.node.title
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), $props.node.description && $props.node.description.trim() !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.description, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "list-reset-styles",
    innerHTML: _ctx.$truncate($props.node.description, 125)
  }, null, 8 /* PROPS */, _hoisted_5)])) : _createCommentVNode("v-if", true)])]);
}