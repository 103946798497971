import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 22 22",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _cache[0] || (_cache[0] = [_createElementVNode("rect", {
    x: "4",
    y: "4",
    width: "14",
    height: "14",
    rx: "7",
    stroke: "currentColor"
  }, null, -1 /* HOISTED */), _createElementVNode("path", {
    d: "M1 11L21 11",
    stroke: "currentColor",
    "stroke-linecap": "round"
  }, null, -1 /* HOISTED */), _createElementVNode("path", {
    d: "M11 21L11 1",
    stroke: "currentColor",
    "stroke-linecap": "round"
  }, null, -1 /* HOISTED */)]), 8 /* PROPS */, _hoisted_1))]);
}