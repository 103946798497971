import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  class: "flex w-3/10 items-baseline"
};
var _hoisted_3 = {
  class: "flex w-3/10 items-baseline"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_preview_modal_link, {
    activity: $props.activity,
    class: "w-3/10 hover:underline"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $props.activity.title,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.status, ":"),
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($options.getWorkflowLabelById($props.activity.workflowStatusId)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.expirationDate, ":"),
    type: "medium",
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, _toDisplayString($options.getEndDate($props.activity.endDate)), 1 /* TEXT */)])]);
}