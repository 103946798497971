import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "questionnaire-page w-100 h-100"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_notification = _resolveComponent("notification");
  var _component_questionnaire = _resolveComponent("questionnaire");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.errorDetails ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.errorDetails = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", {
        innerHTML: $data.errorDetails
      }, null, 8 /* PROPS */, _hoisted_3)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $data.questions.length && $data.informationAssets !== null && $options.rightQuestionnaire ? (_openBlock(), _createBlock(_component_questionnaire, {
    key: 1,
    title: $data.item.title,
    questionnaire: $options.rightQuestionnaire,
    questions: $data.questions,
    "local-storage-key": "".concat($options.pageType, "_").concat($props.data.id, "_").concat($props.questionnaireId),
    "information-assets": $data.informationAssets,
    "my-information-assets-by-type": $data.myInformationAssetsByType,
    "activity-types": _ctx.activityTypes,
    "node-element": $data.nodeElement,
    "read-only": !!$options.currentProject.locked,
    "show-export-as-csv-button": $options.showExportAsCsvButton,
    onUpdate: $options.prepareAndUpdateQuestion,
    onNewAsset: $options.handleNewAsset,
    onForceUpdate: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('saveStatusClicked');
    }),
    onExport: _cache[2] || (_cache[2] = function ($event) {
      return $options.logReport($event.count, $event.type);
    })
  }, null, 8 /* PROPS */, ["title", "questionnaire", "questions", "local-storage-key", "information-assets", "my-information-assets-by-type", "activity-types", "node-element", "read-only", "show-export-as-csv-button", "onUpdate", "onNewAsset"])) : _createCommentVNode("v-if", true), _cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "flow-root"
  }, null, -1 /* HOISTED */))])) : _createCommentVNode("v-if", true)]);
}