import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "pt-6 module-title relative"
};
var _hoisted_2 = {
  class: "flex flex-col"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  key: 3,
  class: "mt-4 flex justify-center font-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_control_creation_modal = _resolveComponent("control-creation-modal");
  var _component_create_initiative_modal = _resolveComponent("create-initiative-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addRecommendedActivities,
    "show-ok-button": false,
    "button-dismiss-text": $data.translate.close,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: $data.notificationType
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $data.showCreateControl ? (_openBlock(), _createBlock(_component_control_creation_modal, {
        key: 1,
        "show-all-fields": true,
        "show-associations": true,
        attributes: $props.article.attributes || {},
        preset: $data.preset,
        "mother-control-preselected-match": $props.article.article,
        onUpdate: $options.createControl,
        onClose: _cache[0] || (_cache[0] = function ($event) {
          return $data.showCreateControl = false;
        })
      }, null, 8 /* PROPS */, ["attributes", "preset", "mother-control-preselected-match", "onUpdate"])) : _createCommentVNode("v-if", true), $data.showCreateInitiative ? (_openBlock(), _createBlock(_component_create_initiative_modal, {
        key: 2,
        initiatives: $props.initiatives,
        preset: $data.preset,
        onDismiss: _cache[1] || (_cache[1] = function ($event) {
          return $data.showCreateInitiative = false;
        }),
        onAccept: $options.createInitiative
      }, null, 8 /* PROPS */, ["initiatives", "preset", "onAccept"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.recommendedActivitiesForGapArticle + ' ' + $props.article.article,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
        title: $options.subTitle,
        type: "small",
        class: "italic"
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($options.suggestedActivities), function (module) {
        return _openBlock(), _createElementBlock("div", {
          key: 'recommended' + module
        }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
          title: $data.ModuleTitles[module],
          class: "ml-5 relative bg-white px-2 z-10 inline-block text-blue-750 font-semibold",
          type: "small"
        }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.suggestedActivities[module].suggested_activities, function (activity) {
          return _openBlock(), _createElementBlock("div", {
            key: activity.id + 'activity',
            class: "pt-4 flex justify-between"
          }, [_createVNode(_component_risma_title, {
            title: activity.activity_header,
            class: "rounded-large border border-gray-550 px-2 py-1 bg-gray-75 mr-4",
            type: "small"
          }, null, 8 /* PROPS */, ["title"]), _createElementVNode("button", {
            class: "flex-none bg-blue-750 text-white rounded-full px-3 py-1 self-center focus:outline-none focus:border-blue-750",
            onClick: function onClick($event) {
              return $options.addRecommendedItem(module, activity);
            }
          }, [_createTextVNode(_toDisplayString($data.translate.add) + " ", 1 /* TEXT */), _createVNode(_component_feather_icon, {
            icon: "arrow-right",
            width: "20",
            height: "20",
            class: "text-white stroke-3 inline-block"
          })], 8 /* PROPS */, _hoisted_3)]);
        }), 128 /* KEYED_FRAGMENT */))]), _createVNode(_component_risma_title, {
          title: $options.suggestedActivities[module].description,
          type: "small",
          truncate: false,
          class: "mt-4"
        }, null, 8 /* PROPS */, ["title"])]);
      }), 128 /* KEYED_FRAGMENT */)), !Object.keys($options.suggestedActivities).length ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($data.translate.noRecommendedActivitiesAvailable), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text"]);
}