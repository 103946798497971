import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ReleaseNotification from '@/components/Molecules/ReleaseNotification';
export default {
  name: '',
  components: {
    RismaButton: RismaButton,
    ReleaseNotification: ReleaseNotification
  },
  data: function data() {
    return {
      showReleaseNotifications: true
    };
  },
  methods: {
    dismissVersionPanel: function dismissVersionPanel() {
      this.showReleaseNotifications = false;
    }
  }
};