import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "text-center"
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  class: "flex items-center mb-5"
};
var _hoisted_4 = {
  class: "mr-10"
};
var _hoisted_5 = {
  class: "flex items-center"
};
var _hoisted_6 = {
  class: "flex items-center mb-3"
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  key: 3,
  class: "text-center"
};
var _hoisted_9 = {
  key: 1,
  class: "mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$getActivityTypeB;
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_main_well = _resolveComponent("main-well");
  var _component_incident_description = _resolveComponent("incident-description");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [!$data.pageLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.pageLoaded && !$options.hasActivityTypes ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.youHaveNoActivityTypesToCreateIncidentsFro
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_title, {
    title: $data.translate.adminCanCreateActivityTypesInAdministration,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $data.pageLoaded && $data.incidents && $options.hasActivityTypes ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.errors.length > 0 ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, function (error, key) {
        return _openBlock(), _createElementBlock("p", {
          key: key,
          style: {
            "margin-bottom": "0.2rem"
          }
        }, _toDisplayString(error), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: $data.filterString,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.filterString = $event;
    }),
    placeholder: $data.translate.search,
    type: "text",
    class: "self-end mr-auto mb-0"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_select, {
    title: $data.translate.status,
    "enable-select-all": true,
    options: $options.wsOptions,
    "selected-options": $setup.selectedStatus,
    "un-sorted": true,
    placeholder: $data.translate.noFilter,
    "label-key": "label",
    "track-by": "value",
    class: "w-64",
    onSelected: $options.setStatusFilter
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.display,
    type: "medium",
    class: "mr-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_view_switcher, {
    view: $setup.currentView,
    "onUpdate:view": _cache[1] || (_cache[1] = function ($event) {
      return $setup.currentView = $event;
    }),
    views: $data.INCIDENTS_VIEWS
  }, null, 8 /* PROPS */, ["view", "views"])])]), _createElementVNode("div", {
    class: _normalizeClass({
      'tiles-wrapper grid grid-cols-incident-tiles gap-4': $setup.currentView === $data.VIEWS.TILES
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedIncidents, function (group, activityTypeId) {
    return _openBlock(), _createElementBlock("div", {
      key: activityTypeId,
      class: "mb-4"
    }, [_createVNode(_component_main_well, {
      class: _normalizeClass({
        'h-full': $setup.currentView === $data.VIEWS.TILES
      })
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("header", _hoisted_6, [_createVNode(_component_risma_title, {
          title: group.activityType.label,
          class: "mr-2"
        }, null, 8 /* PROPS */, ["title"]), $setup.currentView === $data.VIEWS.COMPACT ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "cursor-pointer flex items-center pt-1",
          onClick: function onClick($event) {
            return $options.toggleVisibility(activityTypeId);
          }
        }, [_createVNode(_component_feather_icon, {
          icon: "chevron-down",
          width: "30",
          height: "30",
          class: _normalizeClass(['transform duration-500 mr-1', {
            'rotate-180': $options.showCurrentTable(activityTypeId)
          }])
        }, null, 8 /* PROPS */, ["class"]), _createElementVNode("span", null, _toDisplayString($options.showCurrentTable(activityTypeId) ? $data.translate.hide : $data.translate.show), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true), +activityTypeId ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: "/incident/type/".concat(activityTypeId, "/expanded"),
          class: "ml-auto mr-3"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_feather_icon, {
              icon: "maximize-2",
              width: "25",
              height: "25",
              class: "text-blue-750 stroke-2"
            })];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])) : _createCommentVNode("v-if", true), $options.canUserCreateIncident(activityTypeId) ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 2,
          icon: "plus",
          width: "34",
          height: "34",
          class: "text-blue-750 stroke-2 cursor-pointer",
          onClick: function onClick($event) {
            return $options.showCreateNewIncidentModal(activityTypeId);
          }
        }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), $options.showCurrentTable(activityTypeId) ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.currentTable), {
          key: 0,
          "hover-titles": $data.hoverTitles,
          "dataset-table": group.activities,
          columns: group.columns,
          class: _normalizeClass([{
            'compact-layout': $setup.currentView === $data.VIEWS.COMPACT
          }, {
            'h-1/1-46px': $setup.currentView === $data.VIEWS.TILES
          }]),
          "columns-max-width": $options.columnsMaxWidth,
          "preserve-page-on-update": true,
          onTrigger: $options.onDeleteIncident
        }, {
          severity: _withCtx(function (_ref) {
            var slotData = _ref.slotData;
            return [slotData && slotData.color ? (_openBlock(), _createBlock(_component_traffic_light, {
              key: 0,
              class: "ml-5",
              color: slotData.color
            }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)];
          }),
          name: _withCtx(function (_ref2) {
            var slotData = _ref2.slotData;
            return [slotData ? (_openBlock(), _createBlock(_component_preview_modal_link, {
              key: 0,
              activity: slotData,
              onUpdated: $options.updateFromPreviewModal,
              onDismissModal: $options.dismissPreviewMode
            }, {
              default: _withCtx(function () {
                return [_createVNode(_component_risma_title_with_icon, {
                  class: "font-semibold hover:underline truncate",
                  icon: "confidential",
                  title: slotData.title,
                  "title-attribute": slotData.hoverTitle,
                  "display-icon": slotData.confidential
                }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"])) : _createCommentVNode("v-if", true)];
          }),
          actions: _withCtx(function (_ref3) {
            var slotData = _ref3.slotData;
            return [slotData ? (_openBlock(), _createBlock(_component_feather_icon, {
              key: 0,
              icon: "x",
              class: "cursor-pointer text-red-50 stroke-2 ml-auto",
              onClick: function onClick($event) {
                return $options.onDeleteIncident(slotData.id);
              }
            }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)];
          }),
          _: 2 /* DYNAMIC */
        }, 1064 /* PROPS, NEED_HYDRATION, DYNAMIC_SLOTS */, ["hover-titles", "dataset-table", "columns", "class", "columns-max-width", "onTrigger"])) : _createCommentVNode("v-if", true)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"])]);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)])) : _createCommentVNode("v-if", true), $data.pageLoaded && $options.hasActivityTypes && !Object.keys($options.groupedIncidents).length ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.theSelectedSearchCriteriaHaveNoResults), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.newIncident ? (_openBlock(), _createBlock(_component_modal, {
    key: 4,
    header: $data.translate.create + ' ' + ((_ctx$getActivityTypeB = _ctx.getActivityTypeById($data.newIncident.activityTypeId)) === null || _ctx$getActivityTypeB === void 0 ? void 0 : _ctx$getActivityTypeB.label),
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "ok-button-disabled": $data.creatingIncident,
    "dismiss-button-disabled": $data.creatingIncident,
    onDismiss: $options.dismissNewIncident,
    onAccept: $options.createIncident
  }, {
    body: _withCtx(function () {
      var _$options$newIncident;
      return [$data.createErrors.length > 0 ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.createErrors, function (error, key) {
            return _openBlock(), _createElementBlock("p", {
              key: key,
              style: {
                "margin-bottom": "0.2rem"
              }
            }, _toDisplayString(error), 1 /* TEXT */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_incident_description, {
        data: $data.newIncident,
        "include-title": true,
        "is-create-mode": true,
        "title-error-message": $data.titleError,
        "is-create-attempted": $data.isCreateAttempted,
        onPropertyChanged: _cache[2] || (_cache[2] = function (_ref4) {
          var property = _ref4.property,
            value = _ref4.value;
          return $data.newIncident[property] = value;
        }),
        onOpen: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[4] || (_cache[4] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["data", "title-error-message", "is-create-attempted"]), (_$options$newIncident = $options.newIncidentResponsibleUsers) !== null && _$options$newIncident !== void 0 && _$options$newIncident.length ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString($options.responsibleNotification), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "ok-button-disabled", "dismiss-button-disabled", "onDismiss", "onAccept"])) : _createCommentVNode("v-if", true)]);
}