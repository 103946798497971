function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { checkUserSyncConnection } from '@/api/admin/admin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import Modal from '@/components/Molecules/Modal/Modal';
import { getTranslate } from './translate';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
export default {
  name: 'UserSynchronisation',
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    Checkboxes: Checkboxes,
    RadioButtons: RadioButtons,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    Modal: Modal,
    CollapsiblePanel: CollapsiblePanel
  },
  props: {
    credentialsValid: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selectedDeactivationAction: 'off',
      selectedUserCreationAction: null,
      userSynchronisation: [],
      translate: getTranslate['UserSynchronisation'](),
      checkingConnection: false,
      titles: [],
      connectionSuccessResults: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    deactivationActionOptions: function deactivationActionOptions() {
      return [{
        value: 'off',
        label: this.translate.doNothing
      }, {
        value: 'deactivate',
        label: this.translate.deactivateKeepUserData
      }, {
        value: 'anonymize_partial',
        label: this.translate.deactivateAnonymizeAllUserDataExceptUserInitials
      }, {
        value: 'anonymize_full',
        label: this.translate.deactivateAnonymizeAllUserData
      }];
    },
    userCreationActions: function userCreationActions() {
      return [{
        value: 'off',
        label: this.translate.dontCreateUsers
      }, {
        value: 'groups',
        label: this.translate.createUsersAssignedToSecurityGroupsConfiguredInRisma
      }];
    },
    userSynchronisationOptions: function userSynchronisationOptions() {
      return [{
        value: 'graph',
        label: this.translate.activateUserSynchronisation
      }];
    },
    resultHeadline: function resultHeadline() {
      var _this$connectionSucce;
      if (((_this$connectionSucce = this.connectionSuccessResults) === null || _this$connectionSucce === void 0 ? void 0 : _this$connectionSucce.length) === 0) {
        return this.translate.connectionSuccessFulButNoChange;
      }
      return this.translate.theSynchronisationWillTakePlace;
    },
    readyForSync: function readyForSync() {
      return !this.checkingConnection && this.credentialsValid && this.selectedDeactivationAction && this.selectedUserCreationAction && !this.isDirty;
    },
    initialState: function initialState() {
      return {
        userSynchronisation: this.getSettingValue('app.user_sync.type'),
        deactivationAction: this.getSettingValue('app.user_sync.deactivate_type'),
        userCreationAction: this.getSettingValue('app.user_sync.creation')
      };
    },
    isDirty: function isDirty() {
      return this.selectedDeactivationAction !== this.initialState.deactivationAction || this.selectedUserCreationAction !== this.initialState.userCreationAction || (this.userSynchronisation[0] || null) !== this.initialState.userSynchronisation;
    }
  }),
  watch: {
    credentialsValid: function credentialsValid(isValid) {
      if (!isValid) {
        this.userSynchronisation = [];
        this.saveSettings();
      }
    }
  },
  created: function created() {
    this.initialiseForm();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, ['updateSettings'])), {}, {
    initialiseForm: function initialiseForm() {
      this.userSynchronisation = this.initialState.userSynchronisation ? [this.initialState.userSynchronisation] : [];
      this.selectedDeactivationAction = this.initialState.deactivationAction;
      this.selectedUserCreationAction = this.initialState.userCreationAction;
    },
    saveSettings: function saveSettings() {
      var _this = this;
      return this.updateSettings({
        'app.user_sync.type': this.userSynchronisation[0] || null,
        'app.user_sync.deactivate_type': this.selectedDeactivationAction,
        'app.user_sync.creation': this.selectedUserCreationAction
      }).then(function () {
        _this.$notify({
          title: _this.translate.settingsAreSaved
        });
      }).catch(function () {
        _this.$notify({
          title: _this.translate.somethingWentWrongWhileUploading
        });
      });
    },
    checkConnection: function checkConnection() {
      var _this2 = this;
      this.checkingConnection = true;
      return checkUserSyncConnection({
        dryRun: true
      }).then(function (response) {
        return _this2.connectionSuccessResults = _this2.prepareConnectionSuccessData(response);
      }).catch(function (error) {
        var _error$response;
        _this2.$notify({
          title: (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this2.checkingConnection = false;
      });
    },
    prepareConnectionSuccessData: function prepareConnectionSuccessData(data) {
      var _this3 = this;
      var created = data.created,
        disabled = data.disabled,
        updated = data.updated,
        warnings = data.warnings,
        errors = data.errors;
      var result = [];
      var addActionResult = function addActionResult(action, users) {
        if (users && users.length) {
          var userCountLabel = users.length === 1 ? _this3.translate.user : _this3.translate.users;
          result.push({
            title: "".concat(_this3.translate[action], " (").concat(users.length, " ").concat(userCountLabel.toLowerCase(), ")"),
            users: users
          });
        }
      };
      addActionResult('created', created);
      addActionResult('updated', updated);
      addActionResult('deactivated', disabled);
      var addAlertResult = function addAlertResult(type, messages) {
        if (messages !== null && messages !== void 0 && messages.length) {
          result.push({
            title: "".concat(_this3.translate[type], " (").concat(messages.length, ")"),
            messages: messages
          });
        }
      };
      addAlertResult('warnings', warnings);
      addAlertResult('errors', errors);
      return result;
    }
  })
};