import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import StaticTable from '@/components/Molecules/StaticTable';
import RiskManagementModal from '@/components/Organisms/RiskAdmin/RiskManagementModal';
export default {
  name: 'RiskManagement',
  token: '<risk-management />',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RiskManagementModal: RiskManagementModal,
    StaticTable: StaticTable
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    dataset: {
      type: Array,
      require: true,
      default: function _default() {
        return [];
      }
    },
    createItemFn: {
      type: Function,
      require: true,
      default: function _default() {
        return Promise.resolve();
      }
    },
    updateItemFn: {
      type: Function,
      require: true,
      default: function _default() {
        return Promise.resolve();
      }
    }
  },
  emits: ['delete'],
  data: function data() {
    return {
      showModal: false,
      selectedItem: {},
      translate: getTranslate['RiskManagement']()
    };
  },
  computed: {
    confirmMessage: function confirmMessage() {
      return "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(this.selectedItem.title);
    },
    genericData: function genericData() {
      return {
        creationHeader: "".concat(this.translate.create, " ").concat(this.title),
        updateHeader: "".concat(this.translate.update, " ").concat(this.title),
        createItemAPI: this.createItemFn,
        updateItemAPI: this.updateItemFn
      };
    }
  },
  methods: {
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    selectItem: function selectItem(value) {
      this.selectedItem = value;
      this.toggleModal();
    },
    dismissModal: function dismissModal() {
      this.selectedItem = {};
      this.toggleModal();
    },
    dismissConfirmation: function dismissConfirmation() {
      this.selectedItem = {};
    },
    openConfirmation: function openConfirmation(value) {
      var _this = this;
      this.selectedItem = value;
      this.$confirm(this.translate.areYouSure, this.confirmMessage, function (res) {
        return res ? _this.deleteItem() : _this.dismissConfirmation();
      }, {
        buttonOkText: 'Delete'
      });
    },
    deleteItem: function deleteItem() {
      this.$emit('delete', this.selectedItem.id);
      this.dismissConfirmation();
    }
  }
};