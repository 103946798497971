// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p[data-v-51b5165a] {
  margin: 0;
}
span.isShowFull[data-v-51b5165a] {
  white-space: pre-wrap;
}
.help-text-action[data-v-51b5165a] {
  cursor: pointer;
  color: #347480;
}
.help-text-action[data-v-51b5165a]:hover {
  color: #4CB3C0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
