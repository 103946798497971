var _excluded = ["id"];
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { getAllGapSchemas } from '@/api/compliance/gapschema';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getAll, updateProject as _updateProject } from '@/api/compliance/projects';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import MainWell from '@/components/Atoms/Containers/MainWell';
import EditComplianceProjectModal from '@/components/Molecules/Modal/EditComplianceProjectModal';
import StaticTable from '@/components/Molecules/StaticTable';
import { ProjectsTab } from '@/constants/compliance/Admin';
import * as Utils from '@/utils/Utils';
import { getTranslate } from './translate';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
export default {
  name: 'ProjectsAdmin',
  introduction: 'Compliance Project Admin',
  description: 'This component renders the table with compliance projects list',
  token: '<projects-admin />',
  components: {
    EditComplianceProjectModal: EditComplianceProjectModal,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    MainWell: MainWell
  },
  data: function data() {
    return {
      questionnaires: {},
      gapSchemas: {},
      projects: [],
      projectToUpdate: {},
      showEditModal: false,
      translate: getTranslate['ProjectsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    activityTypes: 'complianceActivityTypes'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    columnsStyles: function columnsStyles() {
      return {
        gapHeatmaps: 'width: 10%;',
        recommendedQuestions: 'width: 10%;',
        enableGapRiskModule: 'width: 10%'
      };
    },
    columns: function columns() {
      return [this.translate.title, this.translate.questionnaire, this.translate.gapSchemaName, this.translate.frameworkId, this.translate.activityType, this.translate.overallDescriptionTitle, this.translate.purposeTitle, this.translate.processStatusTitle, this.translate.enableRecommendedActivitiesForGaps, this.translate.enableHeatMapsInGapSchema, this.translate.enableGapTypes, this.translate.riskPrGap, ''];
    },
    dataset: function dataset() {
      var _this = this;
      return this.projects.map(function (project) {
        return {
          title: project.title,
          questionnaire: _this.getQuestionnaireByProjectQuestionnaireType(project),
          gapSchemaName: Utils.findValueById(_this.gapSchemas[project.type] || [], project.defaultGapSchemaId, 'label'),
          solutionId: Utils.findPropsByIds(project.solutionIds, _this.frameworkIds, 'label'),
          activityTypes: Utils.findValueById(_this.activityTypes, project.defaultActivityTypeId, 'label'),
          descriptionLabel: getProjectDescriptionLabel(project),
          purposeLabel: getProjectPurposeLabel(project),
          statusLabel: getProjectStatusLabel(project),
          recommendedQuestions: project.recommended_questions ? _this.translate.activated : _this.translate.deactivated,
          gapHeatmaps: project.gap_heatmaps ? _this.translate.activated : _this.translate.deactivated,
          showGapArticleTypes: project.showGapArticleTypes ? _this.translate.activated : _this.translate.deactivated,
          enableGapRiskModule: project.enableGapRiskModule ? _this.translate.activated : _this.translate.deactivated,
          edit: project
        };
      });
    },
    frameworkIds: function frameworkIds() {
      return this.frameworks.map(function (framework) {
        return {
          value: framework.id,
          label: framework.title,
          id: framework.id
        };
      });
    },
    questionnairesOptions: function questionnairesOptions() {
      return this.questionnaires[this.projectToUpdate[ProjectsTab.GUESTIONNAIRETYPE]] || [];
    },
    gapSchemasOptions: function gapSchemasOptions() {
      return this.gapSchemas[this.projectToUpdate.type] || [];
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([getAllQuestionnaires(), getAllGapSchemas(), getAll(true, 'data[]=defaultQuestionnaireId&data[]=defaultGapSchemaId&data[]=defaultActivityTypeId')]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
        questionnaires = _ref2[0],
        gapSchemas = _ref2[1],
        projects = _ref2[2];
      _this2.questionnaires = _this2.createHashForOptions(questionnaires.list, ProjectsTab.GUESTIONNAIRETYPE);
      _this2.gapSchemas = _this2.createHashForOptions(gapSchemas.list, ProjectsTab.GAPSCHEMATYPE);
      _this2.projects = projects.list;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    createHashForOptions: function createHashForOptions(array, hashKey) {
      var hashObj = {};
      array.forEach(function (item) {
        if (!item.selectable) return;
        var type = item[hashKey];
        var option = {
          value: item.id,
          label: item.title,
          id: item.id
        };
        if (hashObj[type]) {
          return hashObj[type].push(option);
        }
        return hashObj[type] = [option];
      });
      return hashObj;
    },
    openEditModal: function openEditModal(project) {
      this.setProjectToUpdate(project);
      this.toggleEditModal();
    },
    dismissEditModal: function dismissEditModal() {
      this.projectToUpdate = {};
      this.toggleEditModal();
    },
    updateProject: function updateProject(project) {
      var _this3 = this;
      this.toggleEditModal();
      var _this$prepareProjectT = this.prepareProjectToSave(project),
        id = _this$prepareProjectT.id,
        dataToSave = _objectWithoutProperties(_this$prepareProjectT, _excluded);
      return _updateProject(id, dataToSave).then(function (updatedProject) {
        var idx = _this3.projects.findIndex(function (p) {
          return +p.id === +updatedProject.id;
        });
        if (idx === -1) return;
        _this3.projects[idx] = updatedProject;
        _this3.loadCurrentUser();
        _this3.$notify({
          title: _this3.translate.settingsAreSaved
        });
      });
    },
    toggleEditModal: function toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    prepareProjectToSave: function prepareProjectToSave(project) {
      return {
        id: project.id,
        title: project.title,
        defaultQuestionnaireId: project.questionnaireId,
        defaultGapSchemaId: project.gapSchemaId,
        solutionIds: project.solutionIds,
        defaultActivityTypeId: project.activityTypeId,
        descriptionLabel: project.descriptionLabel,
        purposeLabel: project.purposeLabel,
        statusLabel: project.statusLabel,
        recommended_questions: project.recommendedQuestions ? 1 : 0,
        gap_heatmaps: project.gapHeatmaps ? 1 : 0,
        showGapArticleTypes: project.showGapArticleTypes,
        enableGapRiskModule: project.enableGapRiskModule ? 1 : 0,
        showRiskAssessment: project.enableGapRiskModule ? 1 : 0
      };
    },
    setProjectToUpdate: function setProjectToUpdate(project) {
      this.projectToUpdate = _defineProperty({
        id: project.id,
        title: project.title,
        questionnaireId: project.defaultQuestionnaireId,
        gapSchemaId: project.defaultGapSchemaId,
        solutionIds: project.solutionIds,
        activityTypeId: project.defaultActivityTypeId,
        descriptionLabel: getProjectDescriptionLabel(project),
        purposeLabel: getProjectPurposeLabel(project),
        statusLabel: getProjectStatusLabel(project),
        recommendedQuestions: project.recommended_questions,
        gapHeatmaps: project.gap_heatmaps,
        showGapArticleTypes: project.showGapArticleTypes,
        enableGapRiskModule: project.enableGapRiskModule,
        type: project.type
      }, ProjectsTab.GUESTIONNAIRETYPE, project[ProjectsTab.GUESTIONNAIRETYPE]);
    },
    getQuestionnaireByProjectQuestionnaireType: function getQuestionnaireByProjectQuestionnaireType(project) {
      if (!this.questionnaires[project[ProjectsTab.GUESTIONNAIRETYPE]]) {
        return '';
      }
      return Utils.findValueById(this.questionnaires[project[ProjectsTab.GUESTIONNAIRETYPE]], project.defaultQuestionnaireId, 'label');
    }
  })
};