import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "input-field-select flex items-center numerical-select-type relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_input_field = _resolveComponent("input-field");
  var _component_input_field_select_dropdown = _resolveComponent("input-field-select-dropdown");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    for: $options.inputId,
    class: "mb-1"
  }, null, 8 /* PROPS */, ["title", "for"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
    ref: "input",
    class: "w-full border-none h-full",
    placeholder: $props.inputPlaceholder,
    "model-value": $props.inputValue,
    enabled: $props.inputEnabled,
    maxlength: $props.maxlength,
    type: $props.type,
    tabindex: $props.tabindex,
    invalid: $props.invalid,
    "input-id": $options.inputId,
    "enable-currency-template": $props.enableCurrencyTemplate,
    "is-active-prop": $data.isFocused,
    "allowed-symbols-regex": $props.allowedSymbolsRegex,
    "error-help-text": $props.errorHelpText,
    "disabled-placeholder": $data.translate.notSet,
    "with-dropdown": false,
    onFocus: _cache[0] || (_cache[0] = function ($event) {
      return $data.isFocused = true;
    }),
    onBlur: _cache[1] || (_cache[1] = function ($event) {
      return $data.isFocused = false;
    }),
    onError: _cache[2] || (_cache[2] = function ($event) {
      return $data.isErrorOccured = $event;
    }),
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('inputChange', $event);
    }),
    onOnEnter: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('onEnter');
    })
  }, null, 8 /* PROPS */, ["placeholder", "model-value", "enabled", "maxlength", "type", "tabindex", "invalid", "input-id", "enable-currency-template", "is-active-prop", "allowed-symbols-regex", "error-help-text", "disabled-placeholder"]), _createVNode(_component_input_field_select_dropdown, {
    "model-value": $props.optionValue,
    options: $props.options,
    disabled: !$props.optionEnabled,
    "is-focused": $data.isFocused,
    tabindex: $props.tabindex,
    "is-error-occured": $data.isErrorOccured,
    "predefined-value": !$props.optionEnabled && $props.inputEnabled,
    placeholder: $props.optionPlaceholder,
    "with-search": $props.withSearch,
    class: "z-2",
    onActive: $options.handleDropdownFocus,
    "onUpdate:modelValue": $options.handleOptionChange
  }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "is-focused", "tabindex", "is-error-occured", "predefined-value", "placeholder", "with-search", "onActive", "onUpdate:modelValue"])])]);
}