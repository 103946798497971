function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { Colors } from '@/Colors';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
export default {
  name: 'CardInformationAssets',
  introduction: '',
  description: '',
  token: '<card-information-assets/>',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    informationAsset: {
      required: true,
      type: Object
    },
    truncateLength: {
      required: false,
      type: Number,
      default: 0
    },
    assetType: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      Colors: Colors
    };
  },
  computed: {
    assetTypeUrl: function assetTypeUrl() {
      return ComplianceControllers[this.informationAsset.objType] || 'informationassets';
    },
    truncatedDescription: function truncatedDescription() {
      if (this.truncateLength) {
        return this.$truncate(this.informationAsset.description, this.truncateLength);
      }
      return this.informationAsset.description;
    },
    getTrafficLightColorByNumber: function getTrafficLightColorByNumber() {
      return this.getTrafficLightByObjectTypeAndNumber(this.informationAsset.objType, this.informationAsset.trafficLight).color;
    }
  },
  methods: _objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightByObjectTypeAndNumber']))
};