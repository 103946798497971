import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "pt-2"
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = ["title"];
var _hoisted_4 = {
  class: "p-1"
};
var _hoisted_5 = {
  key: 0,
  class: "p-1"
};
var _hoisted_6 = ["checked"];
var _hoisted_7 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  return _openBlock(), _createElementBlock("div", null, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.selectQuestions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", {
    title: $data.translate.clickToShowhide,
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleCollapse && $options.toggleCollapse.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-up",
    class: _normalizeClass(['stroke-3 text-blue-750 transform duration-500', {
      'rotate-180': $data.isCollapsed
    }])
  }, null, 8 /* PROPS */, ["class"])], 8 /* PROPS */, _hoisted_3)]), _createElementVNode("p", null, _toDisplayString($props.isGDPR ? $data.translate.selectWhichQuestionsBelowThatYouWouldLikeToIncludeInArticle30 : $data.translate.selectWhichQuestionsBelowThatYouWouldLikeToIncludeInQuestionnaire), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.whenYouAreDoneClickTheCreateReportButtonToShowTheReport), 1 /* TEXT */), _createElementVNode("ul", {
    class: "my-4 p-3 overflow-y-scroll list-none border border-gray-450 rounded",
    style: _normalizeStyle($data.isCollapsed ? 'height: 100px' : 'height: 350px')
  }, [_createElementVNode("li", _hoisted_4, [_createVNode(_component_radio_buttons, {
    "model-value": $data.selectedQuestionsOption,
    options: $options.selectedQuestionsOptions,
    onUpdated: $options.handleQuestionOptionChange
  }, null, 8 /* PROPS */, ["model-value", "options", "onUpdated"])]), _cache[3] || (_cache[3] = _createElementVNode("li", {
    class: "my-2 border-b border-gray-450"
  }, null, -1 /* HOISTED */)), $props.isAnsweredOnly !== undefined ? (_openBlock(), _createElementBlock("li", _hoisted_5, [_createElementVNode("label", null, [_createElementVNode("input", {
    type: "checkbox",
    checked: $props.isAnsweredOnly,
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.toggleAnsweredQuestions && $options.toggleAnsweredQuestions.apply($options, arguments);
    })
  }, null, 8 /* PROPS */, _hoisted_6), _createTextVNode(" " + _toDisplayString($data.translate.onlyDisplayAnsweredQuestions), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _cache[4] || (_cache[4] = _createElementVNode("li", {
    class: "my-2 border-b border-gray-450"
  }, null, -1 /* HOISTED */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($options.groupedQuestions), function (questionnaireId) {
    return _openBlock(), _createElementBlock(_Fragment, null, [$options.groupedQuestionnaires[questionnaireId] !== undefined ? (_openBlock(), _createElementBlock("li", {
      key: 'questionnaire_id' + questionnaireId,
      class: "my-4 p-1 border-b border-gray-450 text-xl text-blue-750"
    }, [_createElementVNode("h4", null, _toDisplayString($options.groupedQuestionnaires[questionnaireId].title), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedQuestions[questionnaireId], function (question) {
      return _openBlock(), _createElementBlock("li", {
        key: 'question_id' + question.id,
        class: "p-1"
      }, [_createElementVNode("label", {
        class: _normalizeClass({
          'font-bold': question.isArticle30
        })
      }, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.selectedQuestions = $event;
        }),
        value: question.id,
        type: "checkbox"
      }, null, 8 /* PROPS */, _hoisted_7), [[_vModelCheckbox, $data.selectedQuestions]]), _createTextVNode(" " + _toDisplayString(question.questionnumber) + ". " + _toDisplayString(question.question), 1 /* TEXT */)], 2 /* CLASS */)]);
    }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))], 4 /* STYLE */)])]);
}