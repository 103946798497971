import { getTranslate } from '@/components/Molecules/translate';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'RequiredFieldsNotification',
  components: {
    Notification: Notification
  },
  token: '<required-fields-notification :current-activity-type="{}" :custom-field-values="[]"/>',
  props: {
    missingRequiredFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      translate: getTranslate['RequiredFieldsNotification']()
    };
  }
};