import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_link_item = _resolveComponent("link-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_link_item, {
    title: "Attached link with delete option",
    link: "https://google.dk",
    class: "mb-1",
    tooltip: "Link"
  }, {
    end: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "x",
        color: "white"
      })];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_link_item, {
    title: "Linked file",
    icon: "file",
    link: "/api/2.0/files/2?download",
    class: "mb-1"
  }, {
    preview: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "hover:underline mr-2 cursor-pointer"
      }, "Show preview", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_link_item, {
    title: "Link attached",
    link: "https://translate.google.com/",
    icon: "link",
    class: "mb-1"
  }), _createVNode(_component_link_item, {
    "is-tag": true,
    title: "Example of a tag"
  })]);
}