import i18n from '@/i18n';
export var getTranslate = {
  SamlAdmin: function SamlAdmin() {
    return {
      futureCertificateExpirationDate: i18n.t('Future certificate expiration date'),
      certificateExpirationDate: i18n.t('Certificate expiration date'),
      createNewCertificate: i18n.t('Create new certificate'),
      createFutureCertificate: i18n.t('Create future certificate'),
      save: i18n.t('Save'),
      edit: i18n.t('Edit'),
      deleteFutureCertificate: i18n.t('Delete future certificate'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      appId: i18n.t('App id'),
      privateKeyCreated: i18n.t('Private key created'),
      rollOverToTheFutureCertificate: i18n.t('Roll over to the future certificate'),
      authenticationMode: i18n.t('Authentication mode'),
      linkToMetaData: i18n.t('Link to metadata')
    };
  }
};