function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllByType, create, updateByType } from '@/api/compliance/informationAssets';
import { getCompanies, addCompany } from '@/api/admin/companies';
import { capitalize } from '@/utils';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import WizardFlow from '@/components/Molecules/WizardFlow';
import CompanyWizardStepWelcome from '@/components/Organisms/Companies/CompanyWizardStepWelcome';
import CompanyWizardStepCompanyCreation from '@/components/Organisms/Companies/CompanyWizardStepCompanyCreation';
import CompanyWizardStepLEI from '@/components/Organisms/Companies/CompanyWizardStepLEI';
import CompanyWizardStepAssets from '@/components/Organisms/Companies/CompanyWizardStepAssets';
import CompanyWizardStepConfirmation from '@/components/Organisms/Companies/CompanyWizardStepConfirmation';
import { CompanyWizardFields, AddAssetActions } from '@/constants/compliance/WizardFlow';
import { isEmailValid, isValidVatNo as _isValidVatNo } from '@/utils/validations';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getCompanyWithUrl } from '@/utils/format/companies';
export default {
  name: 'CompanyWizardWrapper',
  description: 'Wrapper component for company wizard flow that contains api requests, data converter, WizardFlow and button',
  components: {
    RismaButton: RismaButton,
    WizardFlow: WizardFlow
  },
  emits: ['refresh'],
  setup: function setup() {
    return {
      welcomePageState: getLocalStorageComputed('company_wizard_flow_welcome_disabled', false)
    };
  },
  data: function data() {
    return {
      data: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.PROCESSORS, []), AssetsTypes.CONTROLLERS, []), AssetsTypes.SYSTEMS, []), AssetsTypes.INFORMATION_ASSETS_API, []),
      selectedOptions: {},
      loadState: false,
      vatInUseError: '',
      assetStepError: '',
      processorsFlatList: [],
      isOpened: false,
      translate: getTranslate['CompanyWizardWrapper']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    hasIAModule: function hasIAModule() {
      return this.currentUser.iaAccess;
    },
    informationAssetsName: function informationAssetsName() {
      return this.getSettingValue('feature.information_assets_name') || this.translate.assets;
    },
    isValidCompanyEmail: function isValidCompanyEmail() {
      var email = this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT].email;
      if (!email) return true;
      return isEmailValid(email);
    },
    isValidVatNo: function isValidVatNo() {
      var _this$selectedOptions = this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT],
        vatNo = _this$selectedOptions.vatNo,
        countryCode = _this$selectedOptions.countryCode;
      if (!vatNo || !countryCode) return true;
      return _isValidVatNo(vatNo, countryCode);
    },
    companyStepErrorMessage: function companyStepErrorMessage() {
      if (this.selectedOptions[CompanyWizardFields.IS_VAT_CREATION_MODE]) {
        if (this.selectedOptions[CompanyWizardFields.SELECTED_COMPANY_IDX] !== null) return '';
        return this.selectedOptions[CompanyWizardFields.FOUND_COMPANIES].length ? this.translate.pleaseSelectACompanyFromTheList : this.translate.searchAfterCompanyBeforeProceeding;
      }
      var _this$selectedOptions2 = this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT],
        vatNo = _this$selectedOptions2.vatNo,
        countryCode = _this$selectedOptions2.countryCode,
        name = _this$selectedOptions2.name;
      if (!name) return this.translate.titleCannotBeEmpty;
      if (vatNo && !countryCode) return this.translate.aCompanyWithAVATNumberMustHaveACountryCode;
      if (!this.isValidVatNo) return this.translate.invalidVatNumber;
      if (!this.isValidCompanyEmail) return this.translate.invalidEmailAddress;
      return '';
    },
    companyStepLock: function companyStepLock() {
      if (this.companyStepErrorMessage) return true;
      if (!this.selectedOptions[CompanyWizardFields.IS_VAT_CREATION_MODE] && !this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT].vatNo) return false;
      return this.checkIfCompanyExist;
    },
    items: function items() {
      var list = [{
        headline: this.translate.welcome,
        headline2: this.translate.welcome,
        component: CompanyWizardStepWelcome,
        hasShowMessageAgain: true
      }, {
        headline: this.translate.company,
        headline2: this.translate.createCompany,
        component: CompanyWizardStepCompanyCreation,
        isLockedNextStep: this.companyStepLock,
        lockMessage: this.companyStepErrorMessage || this.vatInUseError
      }, {
        headline: this.translate.lei,
        headline2: this.translate.addLei,
        component: CompanyWizardStepLEI
      }];
      if (this.hasIAModule) {
        list.push({
          headline: this.informationAssetsName,
          headline2: this.translate.addAssets,
          component: CompanyWizardStepAssets,
          isLockedNextStep: this.assetsStepValidation,
          lockMessage: this.assetStepError
        });
      }
      list.push({
        headline: this.translate.confirmation,
        component: CompanyWizardStepConfirmation
      });
      return list;
    },
    assetOptions: function assetOptions() {
      return [AssetsTypes.PROCESSORS, AssetsTypes.CONTROLLERS, AssetsTypes.INFORMATION_ASSETS_API, AssetsTypes.SYSTEMS];
    }
  }),
  watch: {
    isOpened: function isOpened() {
      if (this.isOpened) this.getData();
    }
  },
  mounted: function mounted() {
    this.initSelectedOptions();
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var promises = this.assetOptions.map(function (option) {
        return _this.getDataByAssetType(option);
      });
      return Promise.all(promises).catch(function () {});
    },
    createCompany: function createCompany(data) {
      return addCompany(data);
    },
    updateCompanyForIAByType: function updateCompanyForIAByType(type, assetId, companyId) {
      var _this2 = this;
      return updateByType(type, assetId, {
        companyId: companyId
      }).catch(function (error) {
        _this2.errorNotify(error, type);
      });
    },
    createIAAndLinkToCompany: function createIAAndLinkToCompany(type, data, companyId) {
      var _this3 = this;
      return create(type, data).then(function (_ref) {
        var data = _ref.data;
        return _this3.updateCompanyForIAByType(type, data.id, companyId);
      }).catch(function (error) {
        _this3.errorNotify(error, type);
      });
    },
    errorNotify: function errorNotify(error) {
      var _error$response, _error$response2;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var responseError = (_error$response = error.response) !== null && _error$response !== void 0 && _error$response.error ? this.$trans((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error) : this.translate.somethingWentWrong;
      var errorMessage = type ? capitalize(type) + ': ' + responseError : responseError;
      this.$notify({
        type: 'error',
        title: errorMessage
      });
    },
    handleAccept: function handleAccept(_ref2) {
      var _this4 = this;
      var messageState = _ref2.messageState;
      this.handleUpdateStorage(messageState);
      this.loadState = true;
      return this.createCompany(this.getCompanyToCreate()).then(function (company) {
        var promises = [];
        _this4.assetOptions.forEach(function (option) {
          var asset = _this4.selectedOptions[option];
          var isArray = Array.isArray(asset);
          if (!isArray) {
            promises.push(_this4.handleAssetByItsAction(option, asset, company));
          } else {
            asset.forEach(function (atItem) {
              return promises.push(_this4.handleAssetByItsAction(option, atItem, company));
            });
          }
        });
        return Promise.all(promises).then(function () {
          _this4.$notify({
            title: _this4.translate.yourActivityHasBeenCreated,
            activity: getFieldsFromObjectForPreview(getCompanyWithUrl(company))
          });
          _this4.$emit('refresh');
        }).finally(function () {
          _this4.closeAll();
        });
      }).catch(function (error) {
        _this4.errorNotify(error);
        _this4.loadState = false;
      });
    },
    handleAssetByItsAction: function handleAssetByItsAction(type, asset, company) {
      if (asset.action === AddAssetActions.NONE) return;
      if (asset.action === AddAssetActions.CHOOSE_EXISTING) {
        return this.updateCompanyForIAByType(type, asset.id, company.id);
      }
      var title = asset.title || company.name;
      var data = _objectSpread(_objectSpread({}, asset), {}, {
        title: title,
        name: title,
        responsibleUserIds: [this.currentUser.id]
      });
      return this.createIAAndLinkToCompany(type, data, company.id);
    },
    updateCompanyLinkToInformationAssets: function updateCompanyLinkToInformationAssets(assetType, assetsIds, companyId) {
      var _this5 = this;
      var promises = [];
      assetsIds.forEach(function (assetId) {
        promises.push(_this5.updateCompanyForIAByType(assetType, assetId, companyId));
      });
      return Promise.all(promises);
    },
    getDataByAssetType: function getDataByAssetType(assetType) {
      var _this6 = this;
      return getAllByType(assetType).then(function (_ref3) {
        var list = _ref3.list;
        var flatList = _this6.getItemsFlat(list);
        if (assetType === AssetsTypes.PROCESSORS) {
          _this6.processorsFlatList = flatList;
        }
        _this6.data[assetType] = flatList.filter(function (item) {
          return !item.companyId;
        });
      });
    },
    checkIfCompanyExist: function checkIfCompanyExist() {
      var _this7 = this;
      this.vatInUseError = '';
      var company = this.getCompanyToCreate();
      if (!company.vatNo) return true;
      var query = 'q=' + company.vatNo;
      this.loadState = true;
      return getCompanies(query).then(function (_ref4) {
        var list = _ref4.list;
        var companyExist = list.some(function (cmp) {
          return cmp.vatNo === company.vatNo && cmp.countryCode === company.countryCode;
        });
        if (companyExist) {
          _this7.vatInUseError = _this7.translate.aCompanyWithVatAlreadyExists(company.vatNo);
        }
        return !companyExist;
      }).catch(function (error) {
        _this7.$notify({
          title: error.fullError.message,
          type: 'error'
        });
        throw error;
      }).finally(function () {
        return _this7.loadState = false;
      });
    },
    assetsStepValidation: function assetsStepValidation() {
      var _this8 = this;
      this.assetStepError = '';
      var processor = this.selectedOptions[AssetsTypes.PROCESSORS];
      var controller = this.selectedOptions[AssetsTypes.CONTROLLERS];
      var customIA = this.selectedOptions[AssetsTypes.INFORMATION_ASSETS_API];
      if (processor.action === AddAssetActions.CHOOSE_EXISTING && !processor.id) {
        this.assetStepError = this.translate.chooseExisting(this.translate.dataProcessor);
        return false;
      }
      if (processor.action === AddAssetActions.CREATE_NEW && this.processorsFlatList.find(function (item) {
        var _this8$selectedOption;
        return item.name === ((_this8$selectedOption = _this8.selectedOptions) === null || _this8$selectedOption === void 0 || (_this8$selectedOption = _this8$selectedOption.companyObject) === null || _this8$selectedOption === void 0 ? void 0 : _this8$selectedOption.name);
      })) {
        this.assetStepError = capitalize(this.translate.dataProcessor) + ' ' + this.translate.alreadyExists;
        return false;
      }
      if (controller.action === AddAssetActions.CHOOSE_EXISTING && !controller.id) {
        this.assetStepError = this.translate.chooseExisting(this.translate.dataController);
        return false;
      }
      customIA.find(function (at) {
        if ((at.action === AddAssetActions.CREATE_NEW || at.action === AddAssetActions.CHOOSE_EXISTING) && !at.activityTypeId) {
          _this8.assetStepError = _this8.translate.chooseExisting(_this8.translate.customIAType);
          return true;
        }
        if (at.action === AddAssetActions.CHOOSE_EXISTING && !at.id) {
          _this8.assetStepError = _this8.translate.chooseExisting(_this8.translate.customInformationAssets);
          return true;
        }
      });
      this.assetStepError = this.assetStepError || this.systemsValidation();
      return !this.assetStepError;
    },
    systemsValidation: function systemsValidation() {
      var _this9 = this;
      var errorMessage = '';
      this.selectedOptions[AssetsTypes.SYSTEMS].find(function (system) {
        if (system.action === AddAssetActions.CHOOSE_EXISTING && !system.id) {
          errorMessage = _this9.translate.chooseExisting(_this9.translate.systems.toLowerCase());
          return true;
        }
        if (system.action === AddAssetActions.CREATE_NEW && !system.title.trim()) {
          errorMessage = _this9.translate.systems + ': ' + _this9.translate.titleCannotBeEmpty;
          return true;
        }
      });
      return errorMessage;
    },
    handleUpdateStorage: function handleUpdateStorage(value) {
      this.welcomePageState = value;
    },
    updateSelected: function updateSelected(_ref5) {
      var value = _ref5.value,
        field = _ref5.field;
      if (!Object.hasOwn(this.selectedOptions, field)) return;
      this.selectedOptions[field] = value;
    },
    handleDismiss: function handleDismiss() {
      var _this10 = this;
      this.$confirm(this.translate.areYouSure, this.translate.ifYouDeleteThisDraftTheEnteredDataWillNo, function (res) {
        return res && _this10.closeAll();
      });
    },
    closeAll: function closeAll() {
      this.initSelectedOptions();
      this.loadState = false;
      this.isOpened = false;
    },
    getItemsFlat: function getItemsFlat(items) {
      return items.reduce(function (list, item) {
        var _item$childrenObjects;
        list.push(item);
        if ((_item$childrenObjects = item.childrenObjects) !== null && _item$childrenObjects !== void 0 && _item$childrenObjects.length) {
          list.push.apply(list, _toConsumableArray(item.childrenObjects));
        }
        return list;
      }, []);
    },
    getCompanyToCreate: function getCompanyToCreate() {
      if (this.selectedOptions[CompanyWizardFields.IS_VAT_CREATION_MODE]) {
        var idx = this.selectedOptions[CompanyWizardFields.SELECTED_COMPANY_IDX];
        var company = this.selectedOptions[CompanyWizardFields.FOUND_COMPANIES][idx];
        var countryCode = company.countryCode.toUpperCase();
        return _objectSpread(_objectSpread({}, company), {}, {
          countryCode: countryCode,
          lei: this.selectedOptions[CompanyWizardFields.LEI]
        });
      }
      return _objectSpread(_objectSpread({}, this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT]), {}, {
        lei: this.selectedOptions[CompanyWizardFields.LEI]
      });
    },
    initSelectedOptions: function initSelectedOptions() {
      var _this$selectedOptions3;
      this.selectedOptions = (_this$selectedOptions3 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$selectedOptions3, CompanyWizardFields.COMPANY_OBJECT, {}), CompanyWizardFields.SELECTED_COMPANY_IDX, null), CompanyWizardFields.SEARCH_FIELD_VALUE, ''), CompanyWizardFields.FOUND_COMPANIES, []), CompanyWizardFields.IS_VAT_CREATION_MODE, false), CompanyWizardFields.FOUND_LEI, {
        usedCompanyName: null,
        leiList: []
      }), CompanyWizardFields.LEI, null), AssetsTypes.PROCESSORS, {
        action: AddAssetActions.NONE,
        id: null
      }), AssetsTypes.CONTROLLERS, {
        action: AddAssetActions.NONE,
        id: null
      }), AssetsTypes.INFORMATION_ASSETS_API, [{
        action: AddAssetActions.NONE,
        activityTypeId: null,
        id: null
      }]), _defineProperty(_this$selectedOptions3, AssetsTypes.SYSTEMS, [{
        action: AddAssetActions.NONE,
        title: '',
        id: null
      }]));
    }
  }
};