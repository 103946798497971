function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import debounce from 'lodash/debounce';
import { getSecurityGroups } from '@/api/compliance/admin';
import { sendEmail } from '@/api/rismaadmin/email';
import { getDatabaseSize as _getDatabaseSize } from '@/api/rismaadmin/database';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
import { getTranslate } from '@/components/Pages/Admin/translate';
export default {
  name: 'Rismaadmin',
  components: {
    MainWell: MainWell,
    RismaButton: RismaButton,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle
  },
  data: function data() {
    return {
      email: '',
      loginLog: [],
      tableThema: StaticTableThemes.zebra,
      humioLoginFeatureName: 'feature.humio_logging',
      handleHumioLoginDebounce: null,
      translate: getTranslate['RismaAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    emailSetting: function emailSetting() {
      return this.getSettingValue('risma.override_recipient_email');
    },
    rismaversion: function rismaversion() {
      return this.getSettingValue('app.version');
    },
    siteurl: function siteurl() {
      return this.getSettingValue('app.url');
    },
    phpversion: function phpversion() {
      return this.getSettingValue('phpversion');
    },
    databaseUsername: function databaseUsername() {
      return this.getSettingValue('databaseusername');
    },
    databaseName: function databaseName() {
      return this.getSettingValue('databasename');
    },
    serverName: function serverName() {
      return this.getSettingValue('servername');
    },
    samlCertificateExpiration: function samlCertificateExpiration() {
      return this.getSettingValue('samlCertificateExpiration');
    },
    databaseVersion: function databaseVersion() {
      return this.getSettingValue('databaseversion');
    },
    databasehost: function databasehost() {
      return this.getSettingValue('databasehost');
    },
    wkhtmltopdfversion: function wkhtmltopdfversion() {
      return this.getSettingValue('wkhtmltopdfversion');
    },
    dbAttachmentsDisabled: function dbAttachmentsDisabled() {
      return this.getSettingValue('app.db_attachments_disabled');
    },
    minioHost: function minioHost() {
      return this.getSettingValue('minio_host');
    },
    overrideRecipientEmail: function overrideRecipientEmail() {
      return this.getSettingValue('risma.override_recipient_email');
    },
    humioLoginValue: function humioLoginValue() {
      var settingValue = this.getSettingValue(this.humioLoginFeatureName);
      return settingValue === undefined || settingValue === 1;
    },
    maxUpload: function maxUpload() {
      return this.getSettingValue('max_attachment_size_readable');
    },
    columns: function columns() {
      return [this.translate.username, this.translate.login, this.translate.groups];
    },
    dataset: function dataset() {
      return this.loginLog.map(function (_ref) {
        var username = _ref.username,
          loginTime = _ref.loginTime,
          groups = _ref.groups;
        return {
          username: username,
          loginTime: loginTime,
          groups: groups.map(function (item) {
            return item.name;
          }).join(', ')
        };
      });
    }
  }),
  mounted: function mounted() {
    var _this = this;
    getSecurityGroups().then(function (_ref2) {
      var log = _ref2.log;
      return _this.loginLog = log;
    });
    this.email = this.emailSetting;
    this.handleHumioLoginDebounce = debounce(this.handleHumioLoginChange, 500);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, ['updateSettings'])), {}, {
    sendTestEmail: function sendTestEmail() {
      var _this2 = this;
      sendEmail(this.email).then(function () {
        return _this2.$notify({
          title: _this2.translate.emailHasBeenSent
        });
      }).catch(function () {
        return _this2.$notify({
          title: [_this2.translate.somethingWentWrong, _this2.translate.tryAgainOrIfTheIssue],
          type: 'error'
        });
      });
    },
    getDatabaseSize: function getDatabaseSize() {
      var _this3 = this;
      _getDatabaseSize().then(function (data) {
        alert(data.databaseSize + ' MB in database');
        if (_this3.dbAttachmentsDisabled) {
          alert(data.minioSize + ' MB in minio');
        }
      });
    },
    handleHumioLoginChange: function handleHumioLoginChange(value) {
      var _this4 = this;
      var newValue = value ? 1 : 0;
      return this.updateSettings(_defineProperty({}, this.humioLoginFeatureName, newValue)).then(function () {
        return _this4.$notify({
          title: _this4.translate.changesSaved
        });
      }).catch(function () {
        return _this4.$notify({
          title: _this4.translate.somethingWentWrongWhileUploading,
          type: 'error'
        });
      });
    }
  })
};