import { stripHtml } from 'string-strip-html';
export function stripRecognisedHTMLOnly(string) {
  return stripHtml(string, {
    stripRecognisedHTMLOnly: true
  }).result;
}
export function escapeHtml(text) {
  if (!text) {
    return '';
  }
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };
  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
}
export function formatLists(doc) {
  var orderedLists = doc.querySelectorAll('ol');
  orderedLists.forEach(function (ol) {
    var listItems = ol.querySelectorAll('li');
    listItems.forEach(function (li, index) {
      var itemNumber = index + 1;
      var listItemText = li.textContent.trim();
      var formattedText = "".concat(itemNumber, ". ").concat(listItemText);
      li.replaceWith(document.createTextNode(formattedText));
    });
  });
  var unorderedLists = doc.querySelectorAll('ul');
  unorderedLists.forEach(function (ul) {
    var listItems = ul.querySelectorAll('li');
    listItems.forEach(function (li) {
      var listItemText = li.textContent.trim();
      var formattedText = "* ".concat(listItemText);
      li.replaceWith(document.createTextNode(formattedText));
    });
  });
}
export function formatHtmlString(string) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(string, 'text/html');
  formatLists(doc);
  return doc.body.innerHTML;
}
export function stripHtmlString(string) {
  var formattedHtml = formatHtmlString(string);
  return stripHtml(formattedHtml).result;
}