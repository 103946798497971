function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import VueNestableHandle from '@/components/Molecules/VueNestable/VueNestableHandle';
import VueNestable from '@/components/Molecules/VueNestable/VueNestable';
import RismaTreeBranch from '@/components/Atoms/RismaTreeBranch';
export default {
  name: 'RismaTree',
  introduction: 'RismaTree VueNestable component',
  description: 'This is a component written on vue.js VueNestable node module',
  token: "<risma-tree\n        v-if=\"treeData !== null\"\n        v-bind=\"$props\"\n        :tree-data=\"treeData.children\" />",
  components: {
    RismaTreeBranch: RismaTreeBranch,
    VueNestable: VueNestable,
    VueNestableHandle: VueNestableHandle
  },
  props: {
    projectId: {
      required: false,
      type: [String, Number],
      default: 1 // change after full implementation
    },
    treeData: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'structured tree data, !IMPORTANT: nodes should have children, if don`t -> children = [], needed for drag and drop'
    },
    disableItemMenu: {
      type: Boolean,
      default: false,
      required: false
    },
    enableAddButton: {
      type: Boolean,
      default: false,
      required: false
    },
    lockTree: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['move'],
  data: function data() {
    return {
      localValue: _toConsumableArray(this.treeData),
      children: [],
      startDrag: false,
      makeClone: false,
      removeDrag: true,
      wholeData: [],
      movedData: [],
      clonePath: null,
      isEscape: false,
      itemToMoveId: null,
      dragOptions: null,
      translate: getTranslate['RismaTree']()
    };
  },
  watch: {
    treeData: {
      deep: true,
      handler: function handler() {
        this.localValue = JSON.parse(JSON.stringify(this.treeData));
      }
    }
  },
  mounted: function mounted() {
    // @keydown && @keypress .esc is not working
    document.addEventListener('keydown', this.escapeFunction);
  },
  beforeUnmount: function beforeUnmount() {
    document.removeEventListener('keydown', this.escapeFunction);
  },
  methods: {
    handleEsc: function handleEsc() {
      if (!this.isEscape) {
        return;
      }
      this.localValue = JSON.parse(JSON.stringify(this.wholeData));
      this.resetMoveData();
      this.isEscape = false;
    },
    escapeFunction: function escapeFunction(event) {
      if (event.key === 'Escape' && this.wholeData.length && this.startDrag) {
        this.isEscape = true;
      }
    },
    checkMove: function checkMove(a) {
      if (!this.startDrag) {
        return false;
      }
      if (this.clonePath) {
        //if its clone`s child
        if (JSON.stringify(this.clonePath) === JSON.stringify(a.pathTo.slice(0, this.clonePath.length))) {
          return false;
        }

        // if its near clone
        if (this.comparePathes(this.clonePath, a.pathTo, 0, 1)) {
          return false;
        }
      }

      // create clone on first available cloning
      if (this.makeClone) {
        var path = this.getPathById(a.dragItem.id, this.localValue);
        if (this.comparePathes(path, a.pathTo, 0, 1)) {
          return false;
        }
        this.clonePath = path;
        this.createClone(a.dragItem, path[path.length - 1], path);
        this.makeClone = false;
      }
    },
    comparePathes: function comparePathes(pathA, pathB, min, max) {
      var cloneLastIndex = pathA[pathA.length - 1];
      var cloneSliced = pathA.slice(0, -1);
      var toLastIndex = pathB[pathB.length - 1];
      var toArraySliced = pathB.slice(0, -1);
      return JSON.stringify(cloneSliced) === JSON.stringify(toArraySliced) && toLastIndex - cloneLastIndex <= max && toLastIndex - cloneLastIndex >= min;
    },
    getPathById: function getPathById(id, items) {
      var _this = this;
      var path = [];
      items.every(function (item, i) {
        if (item.id === id) {
          path.push(i);
        } else if (item.children) {
          var childrenPath = _this.getPathById(id, item.children);
          if (childrenPath.length) {
            path = path.concat(i).concat(childrenPath);
          }
        }
        return path.length === 0;
      });
      return path;
    },
    onMove: function onMove(a) {
      if (!this.startDrag && !this.lockTree) {
        this.wholeData = JSON.parse(JSON.stringify(this.localValue));
        this.children = a.children;
        this.removeDrag = false;
        a.isGhost = true;
        a.children = [];
        this.makeClone = true;
      }
      this.startDrag = true;
    },
    createClone: function createClone(a, index, path) {
      var parent = this.getElementParentByPath(path);
      parent.children.splice(index + 1, 0, _objectSpread(_objectSpread({}, a), {}, {
        id: a.id + '_isClone',
        children: this.expandCloneChildren(this.children),
        isClone: true,
        isGhost: false
      }));
    },
    expandCloneChildren: function expandCloneChildren(list) {
      var _this2 = this;
      return list.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          id: item.id + '_isClone',
          children: _this2.expandCloneChildren(item.children),
          isClone: true,
          isGhost: false
        });
      });
    },
    removeClone: function removeClone(path, list) {
      var parent = this.getElementParentByPath(path, list);
      parent.children.splice(path[path.length - 1], 1);
    },
    onChangeData: function onChangeData(a, optionsDrag) {
      var _this3 = this;
      if (!this.startDrag) {
        return;
      }
      a.isGhost = false;
      a.children = this.children;
      if (!optionsDrag.pathTo) {
        this.resetMoveData();
        return;
      }
      this.movedData = JSON.parse(JSON.stringify(optionsDrag.items));
      this.itemToMoveId = a.id;
      this.dragOptions = optionsDrag;
      this.$confirm('', this.translate.areYouSureYouWouldLikeToMoveThisNode, function (res) {
        return res && _this3.onChangeConfirm();
      });
      this.localValue = _toConsumableArray(this.wholeData);
      this.resetMoveData();
    },
    onChangeConfirm: function onChangeConfirm() {
      var options = this.getRightOptions(this.dragOptions.pathTo);
      if (this.clonePath) {
        // if displaced by future place
        var lastIndex = this.dragOptions.pathTo[this.dragOptions.pathTo.length - 1];
        if (JSON.stringify(this.dragOptions.pathTo.slice(0, -1)) === JSON.stringify(this.clonePath.slice(0, this.dragOptions.pathTo.length - 1)) && lastIndex <= this.clonePath[this.dragOptions.pathTo.length - 1]) {
          this.clonePath[this.dragOptions.pathTo.length - 1]++;
        }
        this.removeClone(this.clonePath, this.movedData);
      }
      this.$emit('move', {
        child: this.itemToMoveId,
        options: options,
        newTreeData: this.movedData
      });
    },
    getElementParentByPath: function getElementParentByPath(path) {
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.localValue;
      var value = {
        children: list
      };
      if (path.length === 1) {
        return value;
      }
      path.forEach(function (item, index) {
        if (index !== path.length - 1) {
          value = value.children[item];
        }
      });
      return value;
    },
    resetMoveData: function resetMoveData() {
      this.startDrag = false;
      this.removeDrag = true;
      this.children = [];
      this.wholeData = [];
      this.clonePath = null;
    },
    getRightOptions: function getRightOptions(path) {
      var parent = this.getElementParentByPath(path);
      var index = path[path.length - 1];
      var options = {};
      if (parent.children.length === 1) {
        options.appendTo = parent.id;
      } else if (index > 0) {
        options.insertAfter = parent.children[index - 1].id;
      } else {
        options.insertBefore = parent.children[index + 1].id;
      }
      return options;
    }
  }
};