import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "ml-auto flex items-center"
};
var _hoisted_3 = {
  key: 0,
  class: "w-fit"
};
var _hoisted_4 = ["title"];
var _hoisted_5 = ["title"];
var _hoisted_6 = ["title"];
var _hoisted_7 = ["title"];
var _hoisted_8 = {
  key: 3
};
var _hoisted_9 = ["onClick"];
var _hoisted_10 = {
  key: 1
};
var _hoisted_11 = {
  key: 1,
  class: "text-center mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_add_contact_person_modal = _resolveComponent("add-contact-person-modal");
  var _component_company_contact_modal = _resolveComponent("company-contact-modal");
  var _component_create_company = _resolveComponent("create-company");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_list = _resolveComponent("preview-list");
  var _component_main_well = _resolveComponent("main-well");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.selectedGroup === $data.MODULES.COMPANY_CONTACT ? (_openBlock(), _createBlock(_component_add_contact_person_modal, {
    key: 0,
    users: $options.availableContacts,
    companies: $data.dataset[$data.MODULES.COMPANY],
    onDismiss: $options.closeModal,
    onAdd: _cache[0] || (_cache[0] = function ($event) {
      return $options.addItem($event, $data.MODULES.COMPANY_CONTACT);
    })
  }, null, 8 /* PROPS */, ["users", "companies", "onDismiss"])) : _createCommentVNode("v-if", true), $data.showContactModal ? (_openBlock(), _createBlock(_component_company_contact_modal, {
    key: 1,
    "companies-options": $options.contractsOptions,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showContactModal = false;
    }),
    onSave: $options.handleCreateContact
  }, null, 8 /* PROPS */, ["companies-options", "onSave"])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedData, function (item, type) {
    return _openBlock(), _createElementBlock("div", {
      key: item.title,
      class: "mb-4"
    }, [_createVNode(_component_main_well, {
      class: "h-auto"
    }, {
      default: _withCtx(function () {
        return [_createVNode(_component_preview_list, {
          length: "",
          title: _ctx.$trans(item.title),
          "cookie-hidden-name": "risma-contract-party-".concat(type)
        }, _createSlots({
          default: _withCtx(function () {
            return [item.dataset.length ? (_openBlock(), _createBlock(_component_static_table, {
              key: 0,
              "dataset-table": item.dataset,
              columns: item.columns
            }, {
              name: _withCtx(function (_ref) {
                var slotData = _ref.slotData;
                return [slotData.activity && _ctx.userHasCompaniesAccess ? (_openBlock(), _createElementBlock("div", _hoisted_3, [!$options.isPreview ? (_openBlock(), _createBlock(_component_preview_modal_link, {
                  key: 0,
                  activity: slotData.activity
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("span", {
                      title: slotData.activity.name,
                      class: "hover:underline"
                    }, _toDisplayString(slotData.activity.name), 9 /* TEXT, PROPS */, _hoisted_4)];
                  }),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])) : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: slotData.activity.url
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("span", {
                      title: slotData.activity.name,
                      class: "hover:underline"
                    }, _toDisplayString(slotData.activity.name), 9 /* TEXT, PROPS */, _hoisted_5)];
                  }),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))])) : typeof slotData === 'string' ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  title: slotData
                }, _toDisplayString(slotData), 9 /* TEXT, PROPS */, _hoisted_6)) : !_ctx.isAdmin ? (_openBlock(), _createElementBlock("span", {
                  key: 2,
                  title: slotData.activity.name
                }, _toDisplayString(slotData.activity.name), 9 /* TEXT, PROPS */, _hoisted_7)) : (_openBlock(), _createElementBlock("div", _hoisted_8))];
              }),
              delete: _withCtx(function (_ref2) {
                var slotData = _ref2.slotData;
                return [!$props.readOnly ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "focus:outline-none mr-5",
                  onClick: function onClick($event) {
                    return $options.deleteItem(slotData.id, slotData.type);
                  }
                }, [_createVNode(_component_feather_icon, {
                  class: "text-red-50 stroke-2 cursor-pointer",
                  icon: "x",
                  title: $data.translate.delete
                }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_9)) : (_openBlock(), _createElementBlock("div", _hoisted_10))];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-table", "columns"])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
              type: "small",
              title: $options.nothingToShowMessage(type)
            }, null, 8 /* PROPS */, ["title"])]))];
          }),
          _: 2 /* DYNAMIC */
        }, [!$props.readOnly && item.isAddBtnExist && $options.showCreateCompanyButton ? {
          name: "nav-buttons",
          fn: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_2, [type === $data.MODULES.COMPANY ? (_openBlock(), _createBlock(_component_create_company, {
              key: 0,
              "creation-mode": "add",
              "all-companies": $data.dataset[$data.MODULES.COMPANY],
              "linked-companies": $props.data.links[$data.MODULES.COMPANY],
              onCreateCompany: $options.addNewCompany,
              onAddInternalCompany: _cache[2] || (_cache[2] = function ($event) {
                return $options.addItem($event, $data.MODULES.COMPANY);
              })
            }, null, 8 /* PROPS */, ["all-companies", "linked-companies", "onCreateCompany"])) : (_openBlock(), _createBlock(_component_feather_icon, {
              key: 1,
              icon: "plus",
              width: "34",
              height: "34",
              class: "text-blue-750 stroke-2 cursor-pointer",
              onClick: _withModifiers(function ($event) {
                return $options.toggleCreateModal(type);
              }, ["stop"])
            }, null, 8 /* PROPS */, ["onClick"]))])];
          }),
          key: "0"
        } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "cookie-hidden-name"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)]);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }));
}