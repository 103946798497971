function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getAllQuestionsIncludingHidden } from '@/api/compliance/questionnaire';
import { updateQuestion, deleteQuestion, editQuestion } from '@/api/compliance/questions';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import Modal from '@/components/Molecules/Modal/Modal';
import QuestionCreation from '@/components/Organisms/Compliance/QuestionCreation';
import { getTranslatables } from '@/constants/datatable/Translatables';
import { actionTypes, complianceAdminFields } from '@/constants/compliance/ComplianceAdmin';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'QuestionsAdmin',
  components: {
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    Modal: Modal,
    QuestionCreation: QuestionCreation
  },
  props: {
    questionnaireId: {
      type: [String, Number],
      default: null,
      note: 'questionnaire Id'
    },
    questionnaireTypeId: {
      type: [String, Number],
      default: null,
      note: 'default value for questionnaireType'
    },
    questionnaire: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      idToDelete: null,
      questions: [],
      editQuestionModalShown: false,
      questionData: {},
      listOfLineThroughSlots: [complianceAdminFields.HEADLINE, complianceAdminFields.QUESTION_NUMBER, complianceAdminFields.QUESTION],
      listOfEventSlots: [complianceAdminFields.ACTION_HIDE, complianceAdminFields.ACTION_EDIT, complianceAdminFields.ACTION_DELETE],
      isCanceled: false,
      isLoading: false,
      translate: getTranslate['QuestionsAdmin']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useActivityTypesStore, {
    allCustomActivities: 'complianceIACustomActivityTypes'
  })), {}, {
    columns: function columns() {
      var _this$questionnaire;
      var col = [this.translate.headline, this.translate.questionNumber, this.translate.question];
      if (!!((_this$questionnaire = this.questionnaire) !== null && _this$questionnaire !== void 0 && _this$questionnaire.readonly) === false) {
        col.push(this.translate.options);
        col.push('');
      }
      return col;
    },
    formattedData: function formattedData() {
      var _this = this;
      var format = [];
      if (this.questions !== null) {
        this.questions.forEach(function (question) {
          var obj = _this.formatQuestion(question);
          format.push(obj);
        });
      }
      return format;
    },
    modalTitle: function modalTitle() {
      return this.questionData.id ? this.translate.editQuestion : this.translate.createQuestion;
    }
  }),
  watch: {
    questionnaireId: function questionnaireId() {
      this.getQuestions();
    }
  },
  mounted: function mounted() {
    this.getQuestions();
  },
  methods: {
    getQuestions: function getQuestions() {
      var _this2 = this;
      var scrollPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.isLoading = true;
      return getAllQuestionsIncludingHidden(this.questionnaireId).then(function (_ref) {
        var questions = _ref.questions;
        return _this2.questions = questions;
      }).catch(function () {
        catch403(_this2.$router, 'QuestionnaireAdmin');
      }).finally(function () {
        _this2.isLoading = false;
        if (!scrollPosition || !element) return;
        _this2.$nextTick(function () {
          element.scrollTop = scrollPosition || 0;
        });
      });
    },
    getScrollParams: function getScrollParams() {
      var element = document.getElementsByClassName('scrolling-component')[0];
      var scrollPosition = element === null || element === void 0 ? void 0 : element.scrollTop;
      return {
        element: element,
        scrollPosition: scrollPosition
      };
    },
    updateDataflowForConnectedQuestions: function updateDataflowForConnectedQuestions(id, questions) {
      var promises = [];
      questions.forEach(function (item) {
        var newData = cloneDeep(item);
        var changed = false;
        if (item.legalBasisQuestionId === id) {
          newData.legalBasisQuestionId = null;
          changed = true;
        }
        if (item.retentionQuestionId === id) {
          newData.retentionQuestionId = null;
          changed = true;
        }
        if (changed) promises.push(editQuestion(newData));
      });
      if (!promises.length) Promise.resolve();
      return Promise.all(promises);
    },
    handleTriggerEvent: function handleTriggerEvent(_ref2) {
      var _this3 = this;
      var action = _ref2.action,
        id = _ref2.id,
        showHide = _ref2.showHide;
      if (this.isLoading) return;
      var params;
      switch (action) {
        case actionTypes.HIDE:
          this.isLoading = true;
          params = this.getScrollParams();
          return updateQuestion(id, null, showHide).then(function () {
            if (showHide) {
              return _this3.updateDataflowForConnectedQuestions(id, _this3.questions).then(function () {
                return _this3.getQuestions(params.scrollPosition, params.element);
              });
            }
            _this3.getQuestions(params.scrollPosition, params.element);
          }).finally(function () {
            return _this3.isLoading = false;
          });
        case actionTypes.EDIT:
          this.questionData = this.questions.find(function (question) {
            return question.id === id;
          });
          this.questionData.options = Object.values(this.questionData.options);
          this.editQuestionModalShown = true;
          break;
        case actionTypes.DELETE:
          this.$confirm(this.translate.deleteQuestion, this.translate.areYouSureYouWantToDeleteThisQuestion, function (res) {
            return res && _this3.onDeleteConfirm();
          });
          this.idToDelete = id;
      }
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this4 = this;
      this.isLoading = true;
      var params = this.getScrollParams();
      return deleteQuestion(this.idToDelete).then(function () {
        return _this4.getQuestions(params.scrollPosition, params.element);
      }).catch(function (_ref3) {
        var response = _ref3.response;
        _this4.$notify({
          title: response,
          type: 'error'
        });
        _this4.isLoading = false;
      });
    },
    formatQuestion: function formatQuestion(question) {
      var _this$questionnaire2;
      var newQuestion = _defineProperty(_defineProperty(_defineProperty({}, complianceAdminFields.HEADLINE, {
        text: question.headline,
        isLineThroughRemoved: question.hidden === false
      }), complianceAdminFields.QUESTION_NUMBER, {
        isLineThroughRemoved: question.hidden === false,
        text: "".concat(question.questionnumber, " ").concat(question.user_created === 1 ? '*' : '')
      }), complianceAdminFields.QUESTION, {
        isLineThroughRemoved: question.hidden === false,
        text: question.question
      });
      if (!!((_this$questionnaire2 = this.questionnaire) !== null && _this$questionnaire2 !== void 0 && _this$questionnaire2.readonly) === false) {
        /*eslint-disable */
        newQuestion[complianceAdminFields.ACTION_HIDE] = {
          action: actionTypes.HIDE,
          id: question.id,
          showHide: question.hidden === false ? true : false,
          icon: question.hidden === false ? 'eye-off' : 'eye',
          iconClasses: 'inline-block',
          text: question.hidden === false ? this.translate.hideQuestion : this.translate.showQuestion
        };
        newQuestion[complianceAdminFields.ACTION_EDIT] = {
          action: actionTypes.EDIT,
          id: question.id,
          showHide: null,
          icon: 'edit',
          iconClasses: 'inline-block',
          text: this.translate.editQuestion
        };
        newQuestion[complianceAdminFields.ACTION_DELETE] = {
          action: actionTypes.DELETE,
          id: question.id,
          showHide: null,
          icon: 'x',
          iconClasses: 'stroke-2 text-red-50',
          text: ''
        };
      }
      return newQuestion;
    },
    toggleModal: function toggleModal() {
      this.editQuestionModalShown = !this.editQuestionModalShown;
      this.questionData = {};
      this.isCanceled = false;
    },
    update: function update() {
      this.editQuestionModalShown = false;
      this.questionData = {};
      var params = this.getScrollParams();
      this.getQuestions(params.scrollPosition, params.element);
    },
    translateQuestionnaireType: function translateQuestionnaireType(type) {
      return this.$trans(getTranslatables('questionnaireTypes')[type] || type);
    },
    abortHandler: function abortHandler() {
      this.isCanceled = true;
    }
  }
};