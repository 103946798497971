function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import isEqual from 'lodash/isEqual';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { generateKey } from '@/utils/Utils';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { viewTypes as miniTreeViewTypes } from '@/constants/ListViewType';
import { MINI_TREE_VIEWS } from '@/constants/ViewTypes';
export var MiniTreeMixin = {
  data: function data() {
    return {
      searchEnabled: false,
      loading: false,
      isMiniTreeLoaded: false,
      treeData: {
        children: []
      },
      expandedCardsList: [],
      treeId: null,
      hasExpandedView: true,
      tableGeneratedKey: generateKey(),
      selectedPath: [],
      params: {},
      parentId: null,
      miniTreeViewTypes: miniTreeViewTypes,
      lastAppliedFilters: '',
      viewTypes: MINI_TREE_VIEWS,
      newNodeTitle: '',
      showCreateModal: false,
      nodeIdToDelete: null,
      error: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    nodeIdSelected: function nodeIdSelected() {
      var _this$selectedPath;
      return (_this$selectedPath = this.selectedPath[this.selectedPath.length - 1]) === null || _this$selectedPath === void 0 ? void 0 : _this$selectedPath.id;
    },
    breadcrumbsTitle: function breadcrumbsTitle() {
      var _this = this;
      var ids = this.selectedPath.map(function (item) {
        return item.id;
      });
      var _ids$reduce = ids.reduce(function (prev, id) {
          var children = prev.children || _this.treeData.children;
          if (!children) return {
            title: ''
          };
          var child = children === null || children === void 0 ? void 0 : children.find(function (c) {
            return c.id === id;
          });
          var prefix = prev !== null && prev !== void 0 && prev.title ? ' > ' : '';
          var title = "".concat((prev === null || prev === void 0 ? void 0 : prev.title) || '').concat(prefix).concat((child === null || child === void 0 ? void 0 : child.title) || '');
          return {
            children: child === null || child === void 0 ? void 0 : child.children,
            title: title
          };
        }, {
          title: ''
        }),
        title = _ids$reduce.title;
      return title;
    }
  }),
  methods: {
    selectPath: function selectPath(path) {
      if (isEqual(this.selectedPath, path)) return;
      this.selectedPath = path;
      this.loadTreeNodeContent();
    },
    displayCreateModal: function displayCreateModal(parentId) {
      var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var copyWithChildren = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var copyData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      this.parentId = parentId || this.treeId;
      this.params = {};
      if (node) {
        var _this$selectedPath2;
        var uniquePrefix = '';
        if (this.isNodeIdInName) {
          uniquePrefix = (node.uniqueId || node.id || '') + ' ';
        }
        this.newNodeTitle = "".concat(this.translate.copyOf, " ").concat(node.title.replace(uniquePrefix, ''));
        this.params = {
          copyFrom: node.id,
          insertCopyAfter: node.id,
          copyWithChildren: copyWithChildren,
          copyData: copyData
        };
        this.parentId = (_this$selectedPath2 = this.selectedPath[this.selectedPath.length - 2]) === null || _this$selectedPath2 === void 0 ? void 0 : _this$selectedPath2.id;
      }
      this.showCreateModal = true;
    },
    onNodeCreate: function onNodeCreate(newNode) {
      var parent = this.getBranchByParentId(this.parentId);
      if (!parent.children) {
        parent.children = [];
      }
      if (!this.searchEnabled) {
        this.appendChildToParent(parent, newNode, this.params.insertCopyAfter || null);
      }
      this.$notify({
        title: this.$trans('Your activity has been created'),
        activity: getFieldsFromObjectForPreview(newNode)
      });
      this.loadTreeNodeContent();
      this.dismissCreateModal();
    },
    getBranchByParentId: function getBranchByParentId(parentId) {
      if (!parentId || parentId === this.treeId || !this.selectedPath.length) return this.treeData;
      var firstTreeItem = this.treeData.children[this.selectedPath[0].index];
      return this.findBranch(firstTreeItem, parentId);
    },
    findBranch: function findBranch(root, id) {
      var _this2 = this;
      if (+root.id === +id) return root;
      if (!root.children.length) return null;
      var result = null;
      root.children.forEach(function (child) {
        if (result) return;
        result = _this2.findBranch(child, id);
      });
      return result;
    },
    appendChildToParent: function appendChildToParent(parent, child) {
      var insertAfterId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      if (insertAfterId) {
        var insertIdx = parent.children.findIndex(function (c) {
          return c.id === insertAfterId;
        }) + 1;
        parent.children.splice(insertIdx, 0, child);
        return;
      }
      parent.children.push(child);
    },
    removeChildFromParent: function removeChildFromParent(parentId, childId) {
      var parent = this.getBranchByParentId(parentId);
      if (!parent || !parent.children) return;
      parent.children = parent.children.filter(function (child) {
        return child.id !== childId;
      });
    },
    updateFromExpandedView: function updateFromExpandedView() {
      if (this.viewTypeActive === miniTreeViewTypes.COMPACT) {
        this.updateTable();
      } else {
        this.hasExpandedView && this.getDataForExtendedView();
      }
      this.getTreeData(this.lastAppliedFilters);
    },
    loadTreeNodeContent: function loadTreeNodeContent() {
      if (!this.selectedPath.length) return;
      if (this.viewTypeActive === miniTreeViewTypes.COMPACT) {
        this.updateTable();
      } else {
        this.hasExpandedView && this.getDataForExtendedView();
      }
    },
    formatActivities: function formatActivities(nodes) {
      var _this3 = this;
      if (!nodes.length) return [];
      var mainNode = nodes[0];
      var subNodes = (mainNode === null || mainNode === void 0 ? void 0 : mainNode.children) || [];
      return [mainNode].concat(_toConsumableArray(subNodes)).map(function (node) {
        return _this3.formatActivity(node);
      });
    },
    onNodeDelete: function onNodeDelete(node) {
      var _this$selectedPath3,
        _this4 = this;
      this.parentId = (_this$selectedPath3 = this.selectedPath[this.selectedPath.length - 2]) === null || _this$selectedPath3 === void 0 ? void 0 : _this$selectedPath3.id;
      this.nodeIdToDelete = node.id;
      this.$confirm(this.translate.deleteNode + '?', this.translate.areYouSureYouWantToDelete + ' "' + node.title + '" ' + this.translate.andUnderlyingProcesses + '?', function (res) {
        return res && _this4.deleteNode();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    deleteNode: function deleteNode() {
      var _this5 = this;
      return this.deleteNodeById(this.projectId, this.nodeIdToDelete).then(function (response) {
        if (typeof response.headers !== 'undefined') {
          _this5.error = _this5.translate.couldNotDeleteNode;
          return;
        }
        _this5.removeChildFromParent(_this5.parentId, _this5.nodeIdToDelete);
        _this5.loadTreeNodeContent();
      }).catch(function () {
        _this5.error = _this5.translate.couldNotDeleteNode;
      });
    },
    dismissCreateModal: function dismissCreateModal() {
      this.newNodeTitle = '';
      this.showCreateModal = false;
    },
    updateTable: function updateTable() {
      this.tableGeneratedKey = generateKey();
    }
  }
};