import { Colors } from '@/Colors';

// needed for settings page look and feel and also top navigation
export var ColorSettings = {
  settingsBgColor: {
    settingName: 'app.menu_color',
    title: 'Main navigation bar - background:',
    color: Colors['risma-500']
  },
  settingsTextColor: {
    settingName: 'app.menu_text_color',
    title: 'Main navigation bar - text color:',
    color: Colors['rGrey-400']
  },
  settingsSubBgColor: {
    settingName: 'app.submenu_color',
    title: 'Sub navigation bar - background:',
    key: 'settingsSubBgColor',
    color: Colors['rWhite-500']
  },
  settingsSubTextColor: {
    settingName: 'app.submenu_text_color',
    title: 'Sub navigation bar - text color:',
    color: Colors.system.black
  },
  settingsSubActiveBgColor: {
    settingName: 'app.submenu_active_color',
    title: 'Sub navigation bar - background for selected item:',
    color: Colors['rGrey-400']
  },
  settingsSubActiveTextColor: {
    settingName: 'app.submenu_text_active_color',
    title: 'Sub navigation bar - text color for selected item:',
    color: Colors.system.blueMain
  }
};
export var LogoSettings = {
  CUSTOM: '/api/2.0/images/logo',
  DEFAULT: '/assets/svg/logo.svg',
  DEFAULT_WITH_RISMA: '/assets/svg/logo-white.svg',
  DEFAULT_WITH_BLUE_TEXT: '/assets/svg/logo-blue-text.svg',
  SETTING: 'app.custom_logo'
};
export var FaviconSettings = {
  DEFAULT: '/favicon.png',
  SETTING: 'app.overall.custom_favicon_id'
};
export var SiteNameSettings = {
  DEFAULT: 'RISMA',
  DEFAULT_TITLE: 'RISMAbusiness',
  SETTING: 'app.overall.site_name'
};