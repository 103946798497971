import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.createRisk,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "ok-button-disabled": $data.createInProgress,
    "dismiss-button-disabled": $data.createInProgress,
    onAccept: $options.handleOnSubmit,
    onDismiss: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [!!$data.errorMessages ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.errorMessages), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.title = $event;
        }),
        title: $data.translate.title,
        invalid: !!$data.titleError,
        "error-help-text": $data.titleError,
        "focus-on-mount": true,
        "pre-text": $data.title,
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "error-help-text", "pre-text", "placeholder"])]), _createElementVNode("div", _hoisted_2, [!$options.isERM ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 0,
        title: $options.responsibleListTitle,
        options: $options.preparedUserOptions,
        "selected-options": $options.preselectedResponsibleUsers,
        placeholder: $data.translate.users,
        multiple: !$options.isERM,
        "track-by": "value",
        "label-key": "label",
        onSelected: $options.onUserSelected,
        onOpen: _cache[1] || (_cache[1] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[2] || (_cache[2] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "multiple", "onSelected"])) : (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        options: $options.preparedUserOptions,
        title: $options.responsibleListTitle,
        "with-search": true,
        "model-value": $data.responsibleUserIds[0],
        "onUpdate:modelValue": $options.onSingleSelectUserSelected,
        onExpanded: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = $event;
        })
      }, null, 8 /* PROPS */, ["options", "title", "model-value", "onUpdate:modelValue"]))]), !$props.parentRnoProp && !$options.isERM ? (_openBlock(), _createBlock(_component_single_select, {
        key: 1,
        modelValue: $data.parentRno,
        "onUpdate:modelValue": [_cache[4] || (_cache[4] = function ($event) {
          return $data.parentRno = $event;
        }), $options.updateFrameworks],
        title: $data.translate.parent,
        placeholder: $data.translate.chooseParent,
        class: "mb-3",
        options: $options.parentRisks
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "options", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createVNode(_component_frameworks_selector, {
        "selected-options": $data.selectedFrameworks,
        onSelected: _cache[5] || (_cache[5] = function ($event) {
          return $data.selectedFrameworks = $event;
        }),
        onOpen: _cache[6] || (_cache[6] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[7] || (_cache[7] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["selected-options"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "ok-button-disabled", "dismiss-button-disabled", "onAccept"]);
}