import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex items-center mb-3 pl-2 flex-wrap"
};
var _hoisted_2 = {
  class: "mr-3"
};
var _hoisted_3 = {
  class: "mr-5"
};
var _hoisted_4 = {
  ref: "table",
  class: "min-w-full m-0 p-0 border-separate bg-inherit"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "whitespace-nowrap"
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = ["onMousedown"];
var _hoisted_9 = {
  class: "m-0 p-0 border-0"
};
var _hoisted_10 = {
  key: 0
};
var _hoisted_11 = ["colspan"];
var _hoisted_12 = {
  key: 2
};
var _hoisted_13 = ["colspan"];
var _hoisted_14 = {
  class: "font-semibold inline-block text-center sticky left-1/2 -translate-x-1/2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$columnsWith, _$data$openColumnMenu2, _$props$columns$$data, _$props$columns$$data2, _$props$columns$$data3, _$props$columns$$data4;
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_draggable = _resolveComponent("draggable");
  var _component_risma_table_column_menu = _resolveComponent("risma-table-column-menu");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_table_cell = _resolveComponent("risma-table-cell");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [(_$options$columnsWith = $options.columnsWithSelectedFilters) !== null && _$options$columnsWith !== void 0 && _$options$columnsWith.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($data.translate.columnsWithAppliedFilters) + ":", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columnsWithSelectedFilters, function (col) {
    return _openBlock(), _createElementBlock("div", {
      key: col.key,
      class: "flex items-center px-3 py-2 mr-2 mb-2 rounded-md bg-blue-750 text-white"
    }, [_createElementVNode("p", _hoisted_3, _toDisplayString(col.label), 1 /* TEXT */), _createVNode(_component_feather_icon, {
      icon: "x",
      width: "16",
      height: "16",
      class: "stroke-2 text-white cursor-pointer",
      onClick: function onClick($event) {
        return $options.deselectAllFiltersFor(col.key, !!col.filterFreeText);
      }
    }, null, 8 /* PROPS */, ["onClick"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    ref: "rismaTableWrapper",
    class: "risma-table-wrapper overflow-x-auto relative z-1",
    style: _normalizeStyle({
      height: $options.heightStyle
    })
  }, [_createElementVNode("table", _hoisted_4, [_createElementVNode("thead", {
    class: "m-0 p-0 border-0 sticky top-0 z-2",
    style: _normalizeStyle({
      height: $data.tableHeaderHeight
    })
  }, [_createVNode(_component_draggable, {
    "model-value": $props.columns,
    "component-data": {
      name: 'table-columns',
      tag: 'tr'
    },
    filter: ".non-draggable",
    "prevent-on-filter": true,
    "item-key": "id",
    tag: "tr",
    class: "m-0 p-0 border-0",
    animation: 400,
    onChange: $options.columnsChange,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:columns', $props.columns);
    })
  }, {
    item: _withCtx(function (_ref) {
      var _element$filters, _$data$openColumnMenu;
      var element = _ref.element,
        index = _ref.index;
      return [element.visible || element.static ? (_openBlock(), _createElementBlock("th", {
        key: 0,
        style: _normalizeStyle($options.getHeaderStyles(element)),
        class: _normalizeClass(["relative m-0 pl-3 pr-8 py-2 border-r-2 bg-cultured border-white text-left text-gray-650 font-semibold w-auto", [{
          'cursor-pointer': !element.static && (element.sortable || ((_element$filters = element.filters) === null || _element$filters === void 0 ? void 0 : _element$filters.length) || element.filterApi),
          'non-draggable': element.static,
          'th-selected': ((_$data$openColumnMenu = $data.openColumnMenu) === null || _$data$openColumnMenu === void 0 ? void 0 : _$data$openColumnMenu.key) === element.key,
          'th-sorted': element.sorted,
          'sticky left-0 z-2': element.sticky
        }, $options.getHeaderClasses(element)]]),
        onClick: function onClick($event) {
          return $options.displayColumnMenu($event, element, index);
        }
      }, [_createElementVNode("span", _hoisted_6, _toDisplayString(element.label), 1 /* TEXT */), element.sorted ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute right-2 top-1/2 transform -translate-y-1/2 w-6 h-6",
        onClick: _withModifiers(function ($event) {
          return $options.onSortIconClick(element, index);
        }, ["stop"])
      }, [element.sortDirection === $data.SortDirection.ASC ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "arrow-up",
        class: "w-full stroke-2"
      })) : (_openBlock(), _createBlock(_component_feather_icon, {
        key: 1,
        icon: "arrow-down",
        class: "w-full stroke-2"
      }))], 8 /* PROPS */, _hoisted_7)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: "resizer absolute top-0 bottom-0 right-0",
        onMousedown: _withModifiers(function ($event) {
          return $options.onResizerMousedown($event, index);
        }, ["prevent"]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"]))
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8)], 14 /* CLASS, STYLE, PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "onChange"])], 4 /* STYLE */), _createElementVNode("tbody", _hoisted_9, [_withDirectives(_createElementVNode("tr", {
    class: "sticky h-0 z-2",
    style: _normalizeStyle({
      top: ((_$data$openColumnMenu2 = $data.openColumnMenu) === null || _$data$openColumnMenu2 === void 0 ? void 0 : _$data$openColumnMenu2.offsetTop) || 0
    })
  }, [$data.openColumnMenu && $data.openColumnMenu.key ? _withDirectives((_openBlock(), _createBlock(_component_risma_table_column_menu, {
    key: 0,
    class: "absolute",
    style: _normalizeStyle({
      left: $data.openColumnMenu.offsetLeft
    }),
    "column-index": $data.openColumnMenu.colIndex,
    sortable: $props.columns[$data.openColumnMenu.colIndex].sortable,
    filters: ((_$props$columns$$data = $props.columns[$data.openColumnMenu.colIndex]) === null || _$props$columns$$data === void 0 ? void 0 : _$props$columns$$data.filters) || [],
    "filter-api": ((_$props$columns$$data2 = $props.columns[$data.openColumnMenu.colIndex]) === null || _$props$columns$$data2 === void 0 ? void 0 : _$props$columns$$data2.filterApi) || null,
    "filter-free-text": ((_$props$columns$$data3 = $props.columns[$data.openColumnMenu.colIndex]) === null || _$props$columns$$data3 === void 0 ? void 0 : _$props$columns$$data3.filterFreeText) || null,
    "additional-option-label": $options.columnMenuAdditionalOption,
    "additional-option-value": $props.underlyingCheckboxValues[$data.openColumnMenu.filterName] || false,
    "dates-filter": ((_$props$columns$$data4 = $props.columns[$data.openColumnMenu.colIndex]) === null || _$props$columns$$data4 === void 0 ? void 0 : _$props$columns$$data4.datesFilter) || null,
    onFiltersChange: _cache[2] || (_cache[2] = function ($event) {
      return $options.onFiltersChange({
        filters: $event,
        columnKey: $data.openColumnMenu.key
      });
    }),
    onAdditionalOptionChange: _cache[3] || (_cache[3] = function ($event) {
      return $options.onFiltersChange({
        filters: $event.filters,
        columnKey: $data.openColumnMenu.key,
        isUnderlyingLogic: true,
        underlyingLogicValue: $event.value
      });
    }),
    onDeselectAllFilters: _cache[4] || (_cache[4] = function ($event) {
      return $options.deselectAllFiltersFor($data.openColumnMenu.key);
    }),
    onSortByColumn: $options.sortByColumn
  }, null, 8 /* PROPS */, ["style", "column-index", "sortable", "filters", "filter-api", "filter-free-text", "additional-option-label", "additional-option-value", "dates-filter", "onSortByColumn"])), [[_directive_click_outside, $options.closeColumnMenu]]) : _createCommentVNode("v-if", true)], 4 /* STYLE */), [[_vShow, $data.openColumnMenu && $data.openColumnMenu.key]]), $options.tableDataLoading ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [_createElementVNode("td", {
    colspan: $props.columns.length,
    class: "pt-4 relative"
  }, [_createVNode(_component_loading_indicator_large, {
    class: "my-12 inline-block sticky left-1/2 -translate-x-1/2"
  })], 8 /* PROPS */, _hoisted_11)])) : $props.datasetMain.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($props.datasetMain, function (row, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index,
      name: "table-columns",
      class: "m-0 p-0 border-0 browser-default"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, function (cell, key) {
      return _openBlock(), _createElementBlock("td", {
        key: "".concat(key, "_").concat(index),
        class: _normalizeClass(["align-top", $options.stickyColumnsKeys.includes(key) ? 'sticky left-0 z-1 bg-inherit' : ''])
      }, [_createVNode(_component_risma_table_cell, {
        "cell-data": cell,
        type: cell.type,
        onError: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$emit('error', $event);
        }),
        onReload: _cache[6] || (_cache[6] = function ($event) {
          return _ctx.$emit('reload');
        }),
        onDismissModal: _cache[7] || (_cache[7] = function ($event) {
          return _ctx.$emit('dismissModal');
        }),
        onDelete: _cache[8] || (_cache[8] = function ($event) {
          return _ctx.$emit('delete', $event);
        }),
        onStarred: _cache[9] || (_cache[9] = function ($event) {
          return _ctx.$emit('starred', $event);
        }),
        onIconClicked: _cache[10] || (_cache[10] = function ($event) {
          return _ctx.$emit('iconClicked', $event);
        }),
        "onUpdate:loading": _cache[11] || (_cache[11] = function ($event) {
          return _ctx.$emit('update:loading', $event);
        })
      }, null, 8 /* PROPS */, ["cell-data", "type"])], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("tr", _hoisted_12, [_createElementVNode("td", {
    colspan: $props.columns.length,
    class: "pt-4 relative"
  }, [_createElementVNode("span", _hoisted_14, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_13)]))])], 512 /* NEED_PATCH */)], 4 /* STYLE */)]);
}