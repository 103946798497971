// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numerical-select-type[data-v-487ba57d]  input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 44px;
}
.numerical-select-type[data-v-487ba57d]  .risma-split-button button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -5px;
}
.numerical-select-type[data-v-487ba57d]  .numerical-select {
  margin-left: -5px;
  width: -moz-min-content;
  width: min-content;
}
.numerical-select-type[data-v-487ba57d]  .numerical-select .select-label-box {
  background-color: #637994;
  border-radius: 0px 6px 6px 0px;
  min-height: 44px;
  min-width: 120px;
}
.numerical-select-type[data-v-487ba57d]  .numerical-select .option {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}
.split-button[data-v-487ba57d]  button {
  cursor: default;
  background: #637994;
  min-height: 44px;
}
[data-v-487ba57d]  .value-wrap {
  border: none;
  padding: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
