import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  class: "w-full"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  key: 1,
  class: "mt-1"
};
var _hoisted_6 = {
  class: "mb-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_modal = _resolveComponent("modal");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.nonWorkingDays
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.toggleModal
      }, null, 8 /* PROPS */, ["onClick"])]), _createElementVNode("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
        class: _normalizeClass($data.tableCellClasses)
      }, _toDisplayString($data.translate.title), 3 /* TEXT, CLASS */), _createElementVNode("th", {
        class: _normalizeClass($data.tableCellClasses)
      }, _toDisplayString($data.translate.date), 3 /* TEXT, CLASS */), _createElementVNode("th", {
        class: _normalizeClass($data.tableCellClasses),
        width: "20"
      }, null, 2 /* CLASS */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, function (item) {
        return _openBlock(), _createElementBlock("tr", {
          key: item.id
        }, [_createElementVNode("td", {
          class: _normalizeClass($data.tableCellClasses)
        }, _toDisplayString(item.name), 3 /* TEXT, CLASS */), _createElementVNode("td", {
          class: _normalizeClass($data.tableCellClasses)
        }, _toDisplayString($options.getValidDate(item.holidayDate)), 3 /* TEXT, CLASS */), _createElementVNode("td", {
          class: _normalizeClass($data.tableCellClasses)
        }, [_createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.handleDeleteNotification(item);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "stroke-2 text-red-50"
        })], 8 /* PROPS */, _hoisted_3)], 2 /* CLASS */)]);
      }), 128 /* KEYED_FRAGMENT */))])]), $data.showModal ? (_openBlock(), _createBlock(_component_modal, {
        key: 0,
        "show-dismiss-button": $data.showDismissButton,
        "button-ok-text": $options.buttonOkText,
        "dismiss-on-click-outside": false,
        header: $data.translate.create,
        "button-dismiss-text": $data.translate.noKeepDeadlines,
        onAccept: _cache[0] || (_cache[0] = function ($event) {
          return $options.createNewItem(true);
        }),
        onDismiss: $options.handleDismiss
      }, {
        body: _withCtx(function () {
          return [$data.showModalError ? (_openBlock(), _createBlock(_component_notification, {
            key: 0,
            type: "error",
            onDismiss: $options.toggleModalError
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.$trans($data.modalErrorMessage)), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_input_field, {
            title: $data.translate.title,
            placeholder: $data.translate.enterTitle,
            invalid: $data.notValidName,
            "focus-on-mount": true,
            maxlength: 150,
            type: "text",
            "onUpdate:modelValue": $options.updateCreationName
          }, null, 8 /* PROPS */, ["title", "placeholder", "invalid", "onUpdate:modelValue"])]), _createVNode(_component_date_time_picker, {
            title: $data.translate.choose,
            "model-value": $options.getValidDate(),
            "plugin-options": $data.dateOptions,
            "disabled-date": $options.disabledDate,
            onValueUpdated: $options.updateHolidayDate
          }, null, 8 /* PROPS */, ["title", "model-value", "plugin-options", "disabled-date", "onValueUpdated"]), $data.createListOfDeadlines ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
            title: $data.translate.theFollowingControlsHaveDeadlineOnANonwork + ':',
            type: "medium"
          }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.deadlinesList, function (item) {
            return _openBlock(), _createBlock(_component_risma_title, {
              key: item.id,
              title: $options.getDeadlineTitle(item),
              type: "small"
            }, null, 8 /* PROPS */, ["title"]);
          }), 128 /* KEYED_FRAGMENT */))]), _createVNode(_component_risma_title, {
            title: $data.translate.doYouWantToMoveTheControlsToTheNextAvail,
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["show-dismiss-button", "button-ok-text", "header", "button-dismiss-text", "onDismiss"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}