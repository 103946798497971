// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-image[data-v-5fe18823] {
  height: 24px;
}
.sublist .router-link-active[data-v-5fe18823] {
  background-color: #dee2e6;
}
.submenu .router-link-active[data-v-5fe18823] {
  background-color: var(--settingsSubActiveBgColor) !important;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  color: var(--settingsSubActiveTextColor) !important;
  font-weight: 600;
}
.submenu[data-v-5fe18823] {
  border-bottom-color: #dee2e6;
}
.main-nav-link button[data-v-5fe18823] {
  letter-spacing: 0.5px;
  color: var(--settingsTextColor);
}
.main-nav-link button[data-v-5fe18823]:hover {
  color: #fff;
}
.main-nav-link button:hover svg[data-v-5fe18823] {
  color: #00acef;
}
.main-nav-link button.custom-color[data-v-5fe18823]:hover {
  color: var(--settingsTextColor);
}
.nav-bar[data-v-5fe18823] {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.nav-bar[data-v-5fe18823]::-webkit-scrollbar {
  display: none;
}
.top-border[data-v-5fe18823]:after {
  content: "";
  position: absolute;
  top: -12px;
  width: calc(100% + 1px);
  height: 1px;
  background-color: #dee2e6;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
