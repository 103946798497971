export var ControlValidationMessages = {
  PLEASE_CORRECT_THE_ERRORS: 'Please correct the errors and try again.',
  DISCONNECT_BEFORE_DELETE_MOTHER: 'You need to delete the daughter controls to be able to deactivate or delete the mother control.',
  TITLE_IS_REQUIRED: 'Title cannot be empty',
  REVIEW_DEADLINE_AFTER_CONTROL_DEADLINE: 'Check that review deadline is after control deadline.',
  RESPONSIBLE_IS_REQUIRED: 'Responsible is required',
  ORGANISATIONS_IS_REQUIRED: 'At least one organisation is required',
  MAX_REVIEWERS: 'Max reviewers on controls',
  CHANGES_MAY_NOT_BE_SAVED: 'The changes you made may not have been saved',
  SOME_ERROR_OCCURRED: 'There was an error',
  NO_CONTROLS: 'You either have no controls related to you or you need to edit your filtering criteria above to view controls',
  TAG_DUBLICATE: 'Tag already exists',
  SHOULD_HAVE_LIMIT_40_CHARS: 'Should have a character limit of around 40 characters.',
  SHOULD_HAVE_LIMIT_100_CHARS: 'Should have a character limit of around 100 characters.',
  CONSEQUENCE_PARAMETER_ERROR: 'The consequence parameter cannot be empty'
};