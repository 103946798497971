import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { SortDirection } from '@/constants/SortDirection';
import RismaTableColumnFilters from '@/components/Molecules/RismaTable/RismaTableColumnFilters';
export default {
  name: 'RismaTableColumnMenu',
  description: 'Risma table column modal with filter and sorting data',
  token: '<risma-table-column-menu :sortable="true" :filters="[]" column-index="1" />',
  components: {
    RismaTableColumnFilters: RismaTableColumnFilters,
    FeatherIcon: FeatherIcon
  },
  props: {
    columnIndex: {
      type: [String, Number],
      required: true
    },
    sortable: {
      type: Boolean,
      required: false,
      default: true
    },
    datesFilter: {
      type: Object,
      required: false,
      default: null
    },
    filters: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    filterApi: {
      type: Object,
      required: false,
      default: null
    },
    filterFreeText: {
      type: Object,
      required: false,
      default: null
    },
    additionalOptionLabel: {
      type: String,
      default: '',
      note: 'used for include underlying frameworks'
    },
    additionalOptionValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeColumnMenu', 'sortByColumn', 'filtersChange', 'deselectAllFilters', 'additionalOptionChange'],
  data: function data() {
    return {
      SortDirection: SortDirection,
      translate: getTranslate['RismaTableColumnMenu']()
    };
  },
  methods: {
    onOutsideClick: function onOutsideClick() {
      this.$emit('closeColumnMenu');
    },
    onSortClick: function onSortClick(sortDirection) {
      this.$emit('sortByColumn', {
        index: this.columnIndex,
        direction: sortDirection
      });
    }
  }
};