import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, renderSlot as _renderSlot } from "vue";
var _hoisted_1 = {
  class: "expanded-card"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  key: 0,
  class: "pr-2"
};
var _hoisted_4 = {
  class: "flex-grow"
};
var _hoisted_5 = {
  class: "flex items-center"
};
var _hoisted_6 = {
  class: "text-sm font-bold"
};
var _hoisted_7 = {
  class: "flex mt-4"
};
var _hoisted_8 = {
  class: "flex w-2/3 justify-between"
};
var _hoisted_9 = {
  key: 0,
  class: "w-1/3 pr-8"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  key: 1,
  class: "w-1/3 pr-8"
};
var _hoisted_12 = {
  class: "activity-description"
};
var _hoisted_13 = ["innerHTML"];
var _hoisted_14 = {
  key: 2,
  class: "w-1/3 pr-8"
};
var _hoisted_15 = ["innerHTML"];
var _hoisted_16 = {
  class: "flex w-1/3 pl-2 border-l-2"
};
var _hoisted_17 = {
  class: "flex-1"
};
var _hoisted_18 = {
  class: "mb-4"
};
var _hoisted_19 = {
  class: "mb-4"
};
var _hoisted_20 = {
  class: "flex-1"
};
var _hoisted_21 = {
  class: "flex-grow"
};
var _hoisted_22 = {
  class: "flex items-center mb-4"
};
var _hoisted_23 = {
  key: 1
};
var _hoisted_24 = {
  class: "flex"
};
var _hoisted_25 = {
  class: "flex-shrink-0 w-full-110 max-w-650px mr-6"
};
var _hoisted_26 = {
  class: "activity-description"
};
var _hoisted_27 = ["innerHTML"];
var _hoisted_28 = {
  key: 0
};
var _hoisted_29 = {
  class: "mb-1 whitespace-normal"
};
var _hoisted_30 = {
  key: 1,
  class: "mb-1"
};
var _hoisted_31 = {
  key: 2,
  class: "mb-1"
};
var _hoisted_32 = {
  key: 3,
  class: "mb-1"
};
var _hoisted_33 = {
  class: "ml-auto flex flex-col"
};
var _hoisted_34 = {
  key: 0,
  class: "flex items-baseline justify-end"
};
var _hoisted_35 = {
  key: 1,
  class: "flex items-baseline justify-end"
};
var _hoisted_36 = {
  key: 2,
  class: "flex items-baseline justify-end"
};
var _hoisted_37 = {
  class: "mt-auto ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_risk_expanded = _resolveComponent("preview-risk-expanded");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_card_incidents = _resolveComponent("card-incidents");
  var _component_card_contract = _resolveComponent("card-contract");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.activity.objType === $data.ObjectTypes.RISK_RISK ? (_openBlock(), _createBlock(_component_preview_risk_expanded, {
    key: 0,
    risk: $props.activity,
    organisations: $props.organisations,
    scoring: $props.scoring,
    period: $props.period,
    "is-risk2": !$options.isERM,
    "is-approval-flow-enabled": $props.isApprovalFlowEnabled,
    "is-show-linked-items": true,
    onUpdateFromPreviewMode: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDeleteItem: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('deleteItem', $props.activity);
    })
  }, null, 8 /* PROPS */, ["risk", "organisations", "scoring", "period", "is-risk2", "is-approval-flow-enabled"])) : _createCommentVNode("v-if", true), $props.activity.objType === $data.ObjectTypes.EXECUTION_INITIATIVE ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "shadow-lg border-2 min-h-12rem p-4 my-4 bg-gray-75 bg-no-repeat text-sm watermark-background",
    style: _normalizeStyle({
      backgroundImage: $props.watermarkImage
    })
  }, [_createElementVNode("div", _hoisted_2, [$props.activity.trafficLight !== undefined && $props.activity.trafficLight !== null ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_traffic_light, {
    color: _ctx.getTrafficLightColorByObjectTypeAndNumber($props.activity.objType, $props.activity.trafficLight)
  }, null, 8 /* PROPS */, ["color"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [$props.previewMode ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 0,
    activity: $props.activity,
    class: "hover:underline",
    onUpdated: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDismissModal: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('onDismissModal');
    }),
    onDeleteItem: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('deleteItem', $props.activity);
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_5, [$props.activity.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "check",
        class: "mr-2 stroke-2"
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: "".concat($props.activity.ipath, ". ").concat($props.activity.title),
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])) : (_openBlock(), _createBlock(_component_router_link, {
    key: 1,
    to: $props.activity.url,
    class: "flex items-center font-semibold hover:underline"
  }, {
    default: _withCtx(function () {
      return [$props.activity.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "check",
        class: "mr-2 stroke-2"
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: "".concat($props.activity.ipath, ". ").concat($props.activity.title),
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", null, _toDisplayString($data.translate.start + ': ' + ($props.activity.start || $data.translate.notSet)), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.deadline + ': ' + ($props.activity.deadline || $data.translate.notSet)), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [$options.showOptionalField('status') ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.status,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, [_createElementVNode("span", {
    class: "list-reset-styles",
    innerHTML: _ctx.$truncate($props.activity.status, 250)
  }, null, 8 /* PROPS */, _hoisted_10)])])) : _createCommentVNode("v-if", true), $options.showOptionalField('description') ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.description,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_12, [_createElementVNode("span", {
    class: "list-reset-styles",
    innerHTML: $options.descriptionTruncated
  }, null, 8 /* PROPS */, _hoisted_13)])])) : _createCommentVNode("v-if", true), $options.showOptionalField('target') ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_risma_title, {
    title: $data.translate.target,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, [_createElementVNode("span", {
    class: "list-reset-styles",
    innerHTML: _ctx.$truncate($props.activity.target, 250)
  }, null, 8 /* PROPS */, _hoisted_15)])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createVNode(_component_risma_title, {
    title: $data.translate.responsible,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.usersResponsible), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_19, [_createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.organisationsAssigned), 1 /* TEXT */)]), _createElementVNode("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.activityType,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.activityTypeAssigned), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.tags,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($options.tagsAssigned), 1 /* TEXT */)])])])])], 4 /* STYLE */)) : _createCommentVNode("v-if", true), $props.activity.objType === $data.ObjectTypes.CONTROLS_CONTROL || $props.activity.isMothercontrol ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "flex shadow-lg border-2 min-h-12rem p-4 my-4 bg-gray-75 bg-no-repeat text-sm watermark-background",
    style: _normalizeStyle({
      backgroundImage: $props.watermarkImage
    })
  }, [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [!$props.activity.isMothercontrol ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    color: $options.controlTrafficLightColor,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createVNode(_component_preview_modal_link, _mergeProps({
    activity: $props.activity
  }, _ctx.$attrs, {
    onUpdated: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDismissModal: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.$emit('onDismissModal');
    }),
    onDeleteItem: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('deleteItem', $event);
    })
  }), {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        class: "font-semibold hover:underline",
        icon: "confidential",
        type: "medium",
        title: $props.activity.cpath + ' ' + $props.activity.title,
        "title-class": $props.activity.active ? '' : 'line-through',
        "title-attribute": $options.hoverTitle,
        "display-icon": $props.activity.confidential
      }, null, 8 /* PROPS */, ["title", "title-class", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["activity"]), !$props.activity.active && !$props.activity.isMotherControl ? (_openBlock(), _createElementBlock("div", _hoisted_23, "  " + _toDisplayString($data.translate.controlDeactivated), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createVNode(_component_risma_title, {
    title: $data.translate.description,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_26, [_createElementVNode("span", {
    class: "list-reset-styles",
    innerHTML: $options.descriptionTruncated
  }, null, 8 /* PROPS */, _hoisted_27)])]), _createElementVNode("div", null, [!$props.activity.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_28, [_createVNode(_component_risma_title, {
    title: $data.translate.organisations,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_29, _toDisplayString($options.organisationsAssigned), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $options.daughterControls ? (_openBlock(), _createElementBlock("div", _hoisted_30, [_createVNode(_component_risma_title, {
    title: $data.translate.daughterControls,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.daughterControls), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), !$props.activity.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createVNode(_component_risma_title, {
    title: $data.translate.responsible,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.usersResponsible), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.additionalFields.indexOf($data.ControlFields.REVIEWERS) !== -1 ? (_openBlock(), _createElementBlock("div", _hoisted_32, [_createVNode(_component_risma_title, {
    title: $data.translate.reviewer,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.usersReviewers), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.frameworks,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.frameworkTitles), 1 /* TEXT */)])])])]), _createElementVNode("div", _hoisted_33, [$props.activity.active && !$props.activity.isMothercontrol ? (_openBlock(), _createElementBlock("div", _hoisted_34, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.deadline, ":"),
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.deadlineFormated), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.additionalFields.indexOf($data.ControlFields.COMPLETED) !== -1 ? (_openBlock(), _createElementBlock("div", _hoisted_35, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.completed, ":"),
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.completedFormated), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.additionalFields.indexOf($data.ControlFields.REVIEW_DEADLINE) !== -1 ? (_openBlock(), _createElementBlock("div", _hoisted_36, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.reviewDeadline, ":"),
    type: "medium",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.reviewDeadlineFormated), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_37, [_renderSlot(_ctx.$slots, "button", {
    activity: $props.activity
  }, undefined, true)])])], 4 /* STYLE */)) : _createCommentVNode("v-if", true), $props.activity.objType === $data.ObjectTypes.INCIDENTS_INCIDENT || $props.activity.objType === $data.ObjectTypes.INCIDENTS_INCIDENT_LIMITED ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "shadow-lg border-2 min-h-12rem p-4 my-4 bg-gray-75 bg-no-repeat text-sm watermark-background",
    style: _normalizeStyle({
      backgroundImage: $props.watermarkImage
    })
  }, [_createVNode(_component_card_incidents, {
    incident: $props.activity,
    "preview-modal": true,
    onUpdateFromPreviewMode: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onOnDismissModal: _cache[9] || (_cache[9] = function ($event) {
      return _ctx.$emit('onDismissModal');
    })
  }, null, 8 /* PROPS */, ["incident"])], 4 /* STYLE */)) : _createCommentVNode("v-if", true), $props.activity.objType === $data.ObjectTypes.CONTRACT || $props.activity.objType === $data.ObjectTypes.CONTRACT_LIMITED ? (_openBlock(), _createElementBlock("div", {
    key: 4,
    class: "shadow-lg border-2 min-h-12rem p-4 my-4 bg-gray-75 bg-no-repeat text-sm watermark-background",
    style: _normalizeStyle({
      backgroundImage: $props.watermarkImage
    })
  }, [_createVNode(_component_card_contract, {
    activity: $props.activity,
    "workflow-statuses": $props.workflowStatuses
  }, null, 8 /* PROPS */, ["activity", "workflow-statuses"])], 4 /* STYLE */)) : _createCommentVNode("v-if", true)]);
}