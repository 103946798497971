function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getManagementReportByProjectId, createManagementReport as _createManagementReport, updateManagementReport as _updateManagementReport, deleteManagementReportById } from '@/api/compliance/reports/managementReport';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import DownloadLink from '@/components/Atoms/DownloadLink/DownloadLink';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import * as formatDate from '@/utils/date';
import { findPropById } from '@/utils/Utils';
import { getTranslate } from '@/components/Pages/Compliance/Reports/translate';
export default {
  name: 'ComplianceManagementReport',
  description: 'Compliance management report',
  token: '<compliance-management-report :projectId="projectId"/>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    DownloadLink: DownloadLink,
    Modal: Modal,
    InputField: InputField
  },
  props: {
    projectId: {
      required: true,
      type: [String, Number],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      isLoading: true,
      managementReports: [],
      isCreateMode: false,
      showModal: false,
      report: {
        title: '',
        id: null,
        closed: false
      },
      translate: getTranslate['ComplianceManagementReport']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, ['activeUsers'])), {}, {
    dataset: function dataset() {
      var _this = this;
      return this.managementReports.map(function (report) {
        return {
          title: report.title,
          createdBy: findPropById(report.createdBy, _this.activeUsers, 'display_name'),
          latestRevision: formatDate.toLocalDateTime(report.updatedAt),
          downloadLatest: _this.getDownloadLink(report.id),
          edit: report,
          delete: report.id
        };
      });
    },
    columns: function columns() {
      return [this.translate.title, this.translate.createdBy, this.translate.latestRevision, this.translate.downloadLatest, this.translate.edit, this.translate.delete];
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoading = true;
      getManagementReportByProjectId(this.projectId).then(function (_ref) {
        var list = _ref.list;
        _this2.managementReports = list;
        _this2.isLoading = false;
      });
    },
    createManagementReport: function createManagementReport() {
      this.isLoading = true;
      return _createManagementReport(this.projectId, {
        title: this.report.title,
        description: ''
      });
    },
    updateManagementReport: function updateManagementReport() {
      this.isLoading = true;
      return _updateManagementReport(this.projectId, this.report.id, {
        title: this.report.title,
        closed: !!this.report.closed
      });
    },
    confirmDelete: function confirmDelete(id) {
      var _this3 = this;
      this.$confirm(this.translate.delete, this.translate.areYouSure, function (res) {
        return res && _this3.deleteManagementReport(id);
      });
    },
    deleteManagementReport: function deleteManagementReport(id) {
      var _this4 = this;
      this.isLoading = true;
      return deleteManagementReportById(this.projectId, id).then(this.getData).finally(function () {
        return _this4.isLoading = false;
      });
    },
    getDownloadLink: function getDownloadLink(reportId) {
      return "compliance/projects/".concat(this.projectId, "/report/managementreport/").concat(reportId, "/download");
    },
    openModal: function openModal(_ref2) {
      var title = _ref2.title,
        id = _ref2.id,
        closed = _ref2.closed;
      this.showModal = true;
      this.report = {
        id: id,
        title: title,
        closed: closed
      };
    },
    onModalAccept: function onModalAccept() {
      var _this5 = this;
      this.showModal = false;
      var promise = this.report.id ? this.updateManagementReport : this.createManagementReport;
      return promise().then(this.getData).finally(function () {
        return _this5.isLoading = false;
      });
    }
  }
};