import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_single_select = _resolveComponent("single-select");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : $options.dataset.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 1,
    columns: $options.columns,
    "dataset-table": $options.dataset,
    "columns-max-width": $data.columnsMaxWidth
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList($data.listOfSingleSelectSlots, function (slotName) {
    return {
      name: slotName,
      fn: _withCtx(function (_ref) {
        var slotData = _ref.slotData;
        return [_createVNode(_component_single_select, {
          options: slotData.options,
          placeholder: slotData.options.length ? $data.translate.answerOptions : $data.translate.listIsEmpty,
          class: "max-w-full-30"
        }, null, 8 /* PROPS */, ["options", "placeholder"])];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset-table", "columns-max-width"])) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */))]);
}