function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { CustomFieldTypes, getCustomFieldTypeText } from '@/constants/CustomFieldTypes';
import { AdminTypesModalMixin } from '@/components/Organisms/Admin/AdminTypesModalMixin';
import { RiskStatus } from '@/constants/risks/RiskStatus';
import { Colors } from '@/Colors';
export default {
  name: 'LevelOfThreat',
  introduction: '',
  description: '',
  token: '<level-of-threat></level-of-threat>',
  components: {
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    Notification: Notification,
    ButtonsRow: ButtonsRow
  },
  mixins: [AdminTypesModalMixin],
  props: {
    activity: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current activity data'
    },
    defaultOptions: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      },
      note: 'Default options of level of threats'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      itemToDelete: null,
      id: null,
      isEditMode: true,
      colors: Colors,
      customFieldTypes: CustomFieldTypes,
      showModal: false,
      showButtons: false,
      notificationError: null,
      notificationSuccess: null,
      labelName: '',
      labelNameValidation: null,
      selectOptionsValidation: null,
      selectOptions: [],
      fieldTypeValidation: null,
      fieldType: '',
      fieldTypes: this.prepareFieldTypes(),
      translate: getTranslate['LevelOfThreat']()
    };
  },
  computed: {
    isPeriodClosed: function isPeriodClosed() {
      return this.activity.status === RiskStatus.CLOSED;
    },
    defaultSelectOptions: function defaultSelectOptions() {
      if (this.isOpen || this.isPeriodClosed) return this.activity.threatLevels || [];
      return this.activity.threatLevels.length ? _toConsumableArray(this.activity.threatLevels) : _toConsumableArray(this.defaultOptions);
    },
    isOpen: function isOpen() {
      return this.activity.status === RiskStatus.OPEN;
    },
    modalTitle: function modalTitle() {
      return this.translate.editField;
    },
    isDraft: function isDraft() {
      return this.activity.status === RiskStatus.DRAFT;
    },
    featherIcon: function featherIcon() {
      if (this.activity.status && this.activity.status === RiskStatus.CLOSED) {
        return 'eye';
      }
      return 'edit';
    },
    threatText: function threatText() {
      if (this.activity.status && this.activity.status === RiskStatus.CLOSED) {
        return this.translate.view;
      }
      return this.translate.edit;
    },
    buttonsRowData: function buttonsRowData() {
      var list = [{
        text: this.translate.cancel,
        type: 'util',
        clickEvent: this.toggleModal
      }];
      if (this.isEditMode && !this.isPeriodClosed) {
        list.push({
          text: this.translate.save,
          type: 'save',
          clickEvent: this.saveField
        });
      }
      return list;
    }
  },
  methods: {
    editLevel: function editLevel() {
      this.onEditOpen({
        label: this.activity.threatLevelsLabel,
        typeId: this.customFieldTypes.SELECT,
        options: this.defaultSelectOptions
      });
      this.toggleModal();
    },
    onEditOpen: function onEditOpen(data) {
      this.beforeOpen();
      this.isEditMode = true;
      if (data) {
        this.labelName = data.label;
        this.fieldType = data.typeId;
        if (data.options) {
          this.selectOptions = data.options;
        }
      }
    },
    clearFields: function clearFields() {
      this.labelName = '';
      this.fieldType = '';
      this.selectOptions = [];
      this.notificationError = '';
    },
    addOption: function addOption(e) {
      if (e) {
        e.preventDefault();
      }
      this.selectOptions.push({
        title: ''
      });
    },
    onDeleteConfirm: function onDeleteConfirm() {
      this.selectOptions.splice(this.selectOptions.indexOf(this.itemToDelete), 1);
      this.validate();
    },
    removeOption: function removeOption(item) {
      var _this = this;
      this.itemToDelete = item;
      this.$confirm(this.translate.deleteLevelOfThreat, this.translate.areYouSureYouWantToDeleteThisLevelOfThreat, function (res) {
        return res && _this.onDeleteConfirm();
      });
    },
    validate: function validate() {
      var result = true;
      this.resetValidation();
      if (!this.labelName) {
        result = false;
        this.labelNameValidation = this.translate.pleaseFillOutThisField;
      }
      if (this.fieldType === this.customFieldTypes.SELECT) {
        if (!this.validSelectOptions()) {
          result = false;
          this.selectOptionsValidation = this.translate.enterAnOption;
        }
      }
      if (!this.fieldType) {
        result = false;
        this.fieldTypeValidation = this.translate.chooseAFieldType;
      }
      return result;
    },
    validSelectOptions: function validSelectOptions() {
      var result = true;
      if (!this.selectOptions.length) {
        result = false;
      } else {
        this.selectOptions.map(function (item) {
          if (!item.title) {
            item.invalid = true;
            result = false;
          } else {
            delete item.invalid;
          }
        });
      }
      return result;
    },
    saveField: function saveField() {
      this.toggleModal();
      this.$emit('changed', {
        labelName: this.labelName,
        options: _toConsumableArray(this.selectOptions)
      });
    },
    prepareFieldTypes: function prepareFieldTypes() {
      var result = [];
      for (var key in CustomFieldTypes) {
        result.push({
          label: this.$trans(getCustomFieldTypeText(CustomFieldTypes[key])),
          value: CustomFieldTypes[key]
        });
      }
      return result;
    }
  }
};