import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("p", null, [_createVNode(_component_risma_column_switcher, {
    columns: $data.columns,
    onChanged: $options.onChangeVisibility
  }, null, 8 /* PROPS */, ["columns", "onChanged"])]), _createElementVNode("p", null, [_createVNode(_component_risma_column_switcher, {
    columns: $data.columns,
    onChanged: $options.onChangeVisibility
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        class: "inline-block",
        color: $data.colors.system.primaryDark,
        icon: "more-horizontal"
      }, null, 8 /* PROPS */, ["color"]), _cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "column-switcher-text"
      }, "Some title", -1 /* HOISTED */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "onChanged"])])]);
}