function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Modal from '@/components/Molecules/Modal/Modal';
import Notification from '@/components/Molecules/Notification';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { toLocalDateTimeFull } from '@/utils/date';
export default {
  name: 'AttachmentVersionsModal',
  introduction: 'A modal, that handles new file versions of attachments',
  description: "Allows to add new versions of files and to manage(review/delete) all the file's versions",
  token: '<attachment-versions-modal :add-mode="true" :file-id="1" />',
  components: {
    RismaFileUpload: RismaFileUpload,
    InputField: InputField,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    Notification: Notification,
    RismaSelect: RismaSelect
  },
  props: {
    addMode: {
      type: Boolean,
      required: true,
      note: 'True: if open modal for adding new file version. False: if only for versions review'
    },
    file: {
      type: Object,
      required: true,
      note: 'File data'
    },
    typeOptions: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'The list of options for file types if any'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: "If attachments are readonly and can't be deleted"
    },
    isApplyDisabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['dismiss', 'upload', 'onDelete'],
  data: function data() {
    return {
      fileTitle: '',
      errorMessage: '',
      uploadedFile: null,
      selectedDocTypes: [],
      translate: getTranslate['AttachmentVersionsModal']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useUserStore, ['currentUser'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    attachmentsAllowed: function attachmentsAllowed() {
      return Object.keys(this.getSettingValue('risma.attachments_allowed') || {}).join(', ');
    },
    orderedRevisions: function orderedRevisions() {
      if (!this.file.revisions || !this.file.revisions.length) return;
      return _toConsumableArray(this.file.revisions).reverse();
    },
    filesForUpload: function filesForUpload() {
      var result = _objectSpread(_objectSpread({}, this.uploadedFile), {}, {
        newVersionOf: this.file.id,
        title: this.fileTitle,
        owner: this.currentUser.id
      });
      if (this.typeOptions && this.typeOptions.length) {
        result.docTypeIds = this.selectedDocTypes.map(function (item) {
          return item.id;
        });
      }
      return [result];
    }
  }),
  mounted: function mounted() {
    this.fileTitle = this.file.title || '';
    this.generateSelectedDocTypes(this.file.docTypeIds);
  },
  methods: {
    dismissModal: function dismissModal() {
      this.$emit('dismiss');
    },
    toLocalTime: function toLocalTime(date) {
      return toLocalDateTimeFull(date);
    },
    generateSelectedDocTypes: function generateSelectedDocTypes(ids) {
      var _this = this;
      this.selectedDocTypes = ids.map(function (item) {
        var docType = _this.typeOptions.find(function (type) {
          return +type.id === +item;
        });
        return {
          id: docType.id,
          label: docType.label
        };
      });
    },
    getDocTypeIdsValues: function getDocTypeIdsValues(document) {
      var _this2 = this;
      return document.docTypeIds.map(function (id) {
        var _this2$typeOptions$fi;
        return (_this2$typeOptions$fi = _this2.typeOptions.find(function (type) {
          return +type.id === +id;
        })) === null || _this2$typeOptions$fi === void 0 ? void 0 : _this2$typeOptions$fi.label;
      }).join(', ');
    },
    onFileUpload: function onFileUpload(files) {
      this.errorMessage = '';
      if (files.length > 1) {
        this.uploadedFile = null;
        this.errorMessage = "".concat(this.translate.uploadIsLimitedToOneFileAtATime);
        return;
      }
      this.uploadedFile = files[0];
    },
    setExtensionError: function setExtensionError() {
      this.errorMessage = "".concat(this.translate.invalidFileTypePleaseUploadAnyOfTheseFile, ": ").concat(this.attachmentsAllowed);
    },
    onApply: function onApply() {
      this.errorMessage = '';
      if (!this.uploadedFile) {
        this.errorMessage = this.translate.pleaseSelectAFile;
        return;
      }
      if (!this.fileTitle) {
        this.errorMessage = this.translate.titleCannotBeEmpty;
        return;
      }
      if (!Object.keys(this.uploadedFile).length) {
        return this.dismissModal();
      }
      this.$emit('upload', this.filesForUpload);
    },
    deleteRevision: function deleteRevision(id) {
      this.$emit('onDelete', id);
    },
    getUserById: function getUserById(id) {
      var user = this.users.find(function (user) {
        return user.id == id;
      });
      return user ? user.display_name : '';
    }
  }
};