// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-cont[data-v-b7da8798] {
  position: relative;
  padding: 15px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #CCCCCC;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
  z-index: 2;
}
.preview-cont[data-v-b7da8798]:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: -85% 50%;
  background-size: 80%;
  opacity: 0.7;
}
.preview-header[data-v-b7da8798] {
  display: flex;
  align-items: center;
}
.preview-header .preview-header-trafficlight[data-v-b7da8798] {
  margin: 0 5px 0 0;
}
.preview-header .preview-header-trafficlight[data-v-b7da8798]  svg {
  top: 0;
}
.preview-header .preview-header-title[data-v-b7da8798] {
  overflow: hidden;
}
.preview-header .preview-header-title[data-v-b7da8798]  .big {
  font-size: 24px;
}
.preview.red[data-v-b7da8798]  .big {
  color: #f25050;
}
.preview.red .preview-header-title[data-v-b7da8798]  a:hover {
  color: #f25050;
}
.preview-footer[data-v-b7da8798] {
  padding-top: 5px;
}
.preview-footer[data-v-b7da8798]  .small {
  margin: 0;
}
.preview-cont[data-v-b7da8798]:before {
  background-image: url("/assets/svg/ikon_risk.svg");
}
.preview-cont .preview-header[data-v-b7da8798] {
  justify-content: space-between;
}
.preview-cont .preview-header .preview-header-flow-status[data-v-b7da8798] {
  position: absolute;
  top: 15px;
  right: 15px;
  text-align: right;
}
.preview-cont .preview-header .preview-header-flow-status[data-v-b7da8798]  h3.medium {
  font-weight: bold;
  color: #f25050;
}
.preview-cont .preview-header .preview-header-flow-status.is-approved[data-v-b7da8798]  h3.medium {
  color: #4D4D4D;
}
.preview-cont.preview-risk-closed[data-v-b7da8798]  .big {
  text-decoration: line-through !important;
}
.preview-cont .preview-description[data-v-b7da8798] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.preview-cont .preview-description[data-v-b7da8798]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.preview-cont .preview-description[data-v-b7da8798]  ul ul {
  list-style-type: circle;
}
.preview-cont .preview-description[data-v-b7da8798]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.preview-cont .preview-description[data-v-b7da8798]  li {
  padding-left: 0.3em;
}
.preview-cont .toggle-linked[data-v-b7da8798] {
  color: #4D4D4D;
  font-style: italic;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none !important;
}
.preview-cont .toggle-linked + .preview-body[data-v-b7da8798] {
  padding-top: 1rem;
}
.preview-cont .preview-body[data-v-b7da8798] {
  display: flex;
}
.preview-cont .preview-body .preview-body-column[data-v-b7da8798] {
  width: 50%;
  padding-right: 15px;
}
.preview-cont .preview-body .preview-body-column[data-v-b7da8798]:nth-of-type(2) {
  padding: 0 15px;
  border-left: 1px solid #CCCCCC;
}
.preview-cont .preview-body .preview-body-section[data-v-b7da8798] {
  margin: 0 0 20px 0;
}
.preview-cont .preview-body .preview-body-section.highlight[data-v-b7da8798] {
  color: #737373;
}
.preview-cont .preview-body .preview-body-entity.as-flex[data-v-b7da8798] {
  display: flex;
}
.preview-cont .preview-body .preview-body-entity.as-flex .preview-body-entity-inner[data-v-b7da8798] {
  width: 50%;
}
.preview-cont .preview-body .preview-body-entity-inner[data-v-b7da8798] {
  display: inline-block;
  vertical-align: top;
}
.preview-cont .preview-body .preview-body-entity-inner[data-v-b7da8798]:first-child {
  margin-right: 1rem;
}
.preview-cont .preview-body-footer-section[data-v-b7da8798] {
  display: inline-block;
  vertical-align: middle;
  padding: 0 2px 0 0;
}
.preview-cont .traffic-light[data-v-b7da8798] {
  width: 13px;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid transparent;
  margin-right: 5px;
}
.preview-cont .preview-body-wrap[data-v-b7da8798] {
  display: flex;
  flex-wrap: wrap;
}
.preview-cont .preview-body-wrap > div[data-v-b7da8798] {
  width: 50%;
}
.preview-risk-closed[data-v-b7da8798]  .big {
  text-decoration: line-through !important;
}
.fade-enter-active[data-v-b7da8798],
.fade-leave-active[data-v-b7da8798] {
  transition: opacity 0.3s;
}
.fade-enter-from[data-v-b7da8798],
.fade-leave-to[data-v-b7da8798] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
