export var FileFormats = {
  csv: 'csv',
  doc: 'doc',
  docx: 'docx',
  pdf: 'pdf',
  pptx: 'pptx',
  tif: 'tif',
  tiff: 'tiff',
  txt: 'txt',
  xls: 'xls',
  xlsx: 'xlsx',
  msg: 'msg',
  image: 'image',
  link: 'link',
  gif: 'gif',
  jpeg: 'jpeg',
  jpg: 'jpg',
  png: 'png'
};
export var ImagesFormats = [FileFormats.tif, FileFormats.tiff, FileFormats.gif, FileFormats.jpeg, FileFormats.jpg, FileFormats.png];
export var HiddenIconFormats = [FileFormats.msg, FileFormats.image];