function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { getWorkflows, createWorkflow as _createWorkflow } from '@/api/admin/workflow';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import { saveStatus as saveStatuses } from '@/constants/SaveStatuses';
import { MODULES } from '@/constants/modules';
import { useWorkflowStore } from '@/Store/workflowStore';
export default {
  name: 'IncidentsWorkflows',
  description: 'Define and configure an workflow by defining a range of statuses and how they interact',
  token: '<incidents-workflow />',
  components: {
    RismaButton: RismaButton,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    SingleSelect: SingleSelect,
    InputField: InputField,
    LoadingIndicatorSmall: LoadingIndicatorSmall
  },
  data: function data() {
    return {
      saveStatus: saveStatuses.SAVED,
      saveStatuses: saveStatuses,
      isOkButtonPressed: false,
      showWorkflowModal: false,
      editedWorkflowData: {
        title: '',
        activityTypeId: null
      },
      workflows: [],
      columnsStyles: {
        validate: 'text-align: center;'
      },
      columnsMaxWidth: {
        workflow: '40%'
      },
      translate: getTranslate['IncidentsWorkflows']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['incidentActivityTypes'])), mapState(useUserStore, ['isAdmin'])), {}, {
    readOnly: function readOnly() {
      return !this.isAdmin;
    },
    columns: function columns() {
      return [this.translate.workflow, this.translate.activityType, this.translate.status, this.translate.validated, this.translate.copy];
    },
    dataset: function dataset() {
      var _this = this;
      return this.workflows.map(function (workflow) {
        var _workflow$activityTyp;
        return {
          workflow: workflow,
          activityType: ((_workflow$activityTyp = workflow.activityType) === null || _workflow$activityTyp === void 0 ? void 0 : _workflow$activityTyp.label) || _this.translate.all,
          status: _this.translate.active,
          validate: true,
          copy: ''
        };
      });
    },
    isWorkflowTitleInvalid: function isWorkflowTitleInvalid() {
      return this.isOkButtonPressed && !this.editedWorkflowData.title;
    },
    workflowsActivityTypesIds: function workflowsActivityTypesIds() {
      return this.workflows.map(function (_ref) {
        var activityType = _ref.activityType;
        return activityType === null || activityType === void 0 ? void 0 : activityType.id;
      }).filter(Boolean);
    },
    activityTypesOptions: function activityTypesOptions() {
      var _this2 = this;
      return this.incidentActivityTypes.filter(function (_ref2) {
        var id = _ref2.id;
        return !_this2.workflowsActivityTypesIds.includes(id);
      }).map(function (_ref3) {
        var id = _ref3.id,
          label = _ref3.label;
        return {
          value: id,
          label: label
        };
      });
    }
  }),
  mounted: function mounted() {
    var _this3 = this;
    getWorkflows(MODULES.INCIDENTS).then(function (_ref4) {
      var list = _ref4.list;
      return _this3.workflows = list;
    });
  },
  methods: {
    openWorkflowModal: function openWorkflowModal() {
      var _this$activityTypesOp;
      this.editedWorkflowData = {
        title: '',
        activityTypeId: (_this$activityTypesOp = this.activityTypesOptions) === null || _this$activityTypesOp === void 0 || (_this$activityTypesOp = _this$activityTypesOp[0]) === null || _this$activityTypesOp === void 0 ? void 0 : _this$activityTypesOp.value
      };
      this.showWorkflowModal = true;
      this.isOkButtonPressed = false;
    },
    createWorkflow: function createWorkflow() {
      var _this4 = this;
      if (!this.editedWorkflowData.title) {
        this.isOkButtonPressed = true;
        return;
      }
      this.saveStatus = saveStatuses.SAVING;
      this.showWorkflowModal = false;
      _createWorkflow(this.editedWorkflowData).then(function (workflow) {
        _this4.workflows.push(workflow);
        _this4.$notify({
          title: "".concat(_this4.translate.workflow, " ").concat(_this4.translate.created)
        });
      }).then(useWorkflowStore().load).catch(function (err) {
        return _this4.showError(err);
      }).finally(function () {
        _this4.saveStatus = saveStatuses.SAVED;
        _this4.isOkButtonPressed = false;
      });
    },
    editWorkflow: function editWorkflow(id, activityType) {
      var name = activityType ? 'IncidentsCustomWorkflowEdit' : 'IncidentsWorkflowEdit';
      this.$router.push({
        name: name,
        params: {
          id: id
        }
      });
    },
    showError: function showError(err) {
      var _err$response$error, _err$response;
      this.$notify({
        title: (_err$response$error = err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) !== null && _err$response$error !== void 0 ? _err$response$error : this.translate.anErrorOccurred,
        type: 'error'
      });
    }
  }
};