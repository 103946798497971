function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Selector from '@/components/Atoms/Inputs/Selector';
import SearchableSelector from '@/components/Atoms/Inputs/SearchableSelector';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import { compareStrings } from '@/utils/compare';
import { sortAlphabetic } from '@/utils/sort';
import { checkERMByProjectId } from '@/utils/risks/check';
import { MODULES } from '@/constants/modules';
import { getUsersWithoutTimeout } from '@/api/risma/data';
export default {
  name: 'DelegationModal',
  introduction: 'DelegationModal multiselect popup',
  description: 'DelegationModal with  selector and list of selected items',
  token: '<delegation-modal :options-list=[] :preselected-items=[] />',
  components: {
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    RismaTitle: RismaTitle,
    SearchableSelector: SearchableSelector
  },
  props: {
    optionsList: {
      type: Array,
      required: true,
      note: 'List of all delegation options to choose from (users, organisations)'
    },
    delegationListLabel: {
      type: String,
      required: false,
      default: '',
      note: 'Display delegation list label that initiated modal for editing'
    },
    preselectedItems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected delegation items'
    },
    modalSettings: {
      type: Object,
      required: false,
      default: function _default() {
        return DelegationModalSettings.users;
      },
      note: 'Fields settings for delegation modal'
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Can multiple entities be assigned'
    },
    showAddItem: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Display add item button or not'
    },
    withReset: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Display remove icon next to selected items'
    },
    accessRights: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    skipApi: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['updateDelegationItem', 'closeDelegationModal', 'create', 'update:optionsList'],
  data: function data() {
    return {
      selectedItemIds: [],
      isSelectExpanded: false,
      DelegationTypes: DelegationTypes,
      translate: getTranslate['DelegationModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    userAPIAdditionalParameters: function userAPIAdditionalParameters() {
      return {
        filters: this.getRightFiltersByAccess(this.accessRights)
      };
    },
    sortedOptionsList: function sortedOptionsList() {
      var _this = this;
      return _toConsumableArray(this.optionsList).sort(function (a, b) {
        return compareStrings(a[_this.modalSettings.labelKey], b[_this.modalSettings.labelKey]);
      });
    },
    selectedItems: function selectedItems() {
      var _this2 = this;
      return this.sortedOptionsList.filter(function (item) {
        return _this2.selectedItemIds.includes(item.id);
      });
    },
    selectableItems: function selectableItems() {
      var _this3 = this;
      return this.sortedOptionsList.filter(function (item) {
        return !_this3.selectedItemIds.includes(item.id);
      });
    },
    optionsLabel: function optionsLabel() {
      return this.delegationListLabel || this.$trans(this.modalSettings.label);
    },
    isCurrentUserSelected: function isCurrentUserSelected() {
      return this.selectedItemIds.includes(+this.currentUser.id);
    },
    showAssignToMeButton: function showAssignToMeButton() {
      return this.modalSettings.enableAssignToMe && !this.isCurrentUserSelected;
    }
  }),
  // so it would send selectedItemIds before searchableSelector is mounted;
  created: function created() {
    this.selectedItemIds = this.preselectedItems;
  },
  methods: {
    getRightFiltersByAccess: function getRightFiltersByAccess(data) {
      var module = data.module,
        projectId = data.projectId;
      switch (module) {
        case MODULES.RISK:
          return checkERMByProjectId(projectId, this.currentUser) ? {
            hasAccessToModule: module
          } : {
            hasAccessToRiskProject: projectId
          };
        case MODULES.COMPLIANCE:
          return {
            hasAccessToComplianceProject: projectId
          };
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return {
            hasAccessToProcessLibrary: projectId
          };
        default:
          return {
            hasAccessToModule: module
          };
      }
    },
    selected: function selected(item) {
      var updateOptionsList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.isMultiple) {
        this.selectedItemIds.push(item.id);
      } else {
        this.selectedItemIds = [item.id];
      }
      if (updateOptionsList) {
        this.$emit('update:optionsList', item);
      }
    },
    getUsersAPI: function getUsersAPI(params) {
      return getUsersWithoutTimeout(params).then(function (response) {
        response.list = sortAlphabetic(response.list, 'display_name');
        return Promise.resolve(response);
      });
    },
    deselect: function deselect(item) {
      this.selectedItemIds = this.selectedItemIds.filter(function (selectedId) {
        return item.id !== selectedId;
      });
    },
    openCreationModal: function openCreationModal() {
      this.$emit('create');
    },
    assignToMe: function assignToMe() {
      this.selected(this.currentUser, true);
    },
    toggleSelect: function toggleSelect(isExpanded) {
      this.isSelectExpanded = isExpanded;
    }
  }
};