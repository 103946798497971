function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "cursor-pointer"
};
var _hoisted_2 = {
  class: "preview-modal shadow-blured-black flex flex-col bg-white z-100 fixed top-145px right-0 overflow-y-auto max-h-screen mx-auto w-screen bottom-4 xl:right-10 xl:w-80vw xl:max-h-9/10 p-8 pt-2"
};
var _hoisted_3 = {
  class: "flex justify-end items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification = _resolveComponent("notification");
  var _component_trap_focus_boundary = _resolveComponent("trap-focus-boundary");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return $options.showModal && $options.showModal.apply($options, arguments);
    }, ["stop"]))
  }, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createVNode(_component_feather_icon, {
      icon: "external-link"
    })];
  })]), $data.modalVisible ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: "body"
  }, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "fixed inset-0 z-100"
  }, null, -1 /* HOISTED */)), _createVNode(_component_trap_focus_boundary, {
    "group-selectors": $data.trapFocusSelector
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [!$data.accessErrorMessage ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: $options.fullViewUrl
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "flex items-center hover:bg-gray-250 p-3 rounded-large",
            onClick: _cache[1] || (_cache[1] = function () {
              return $options.dismissModal && $options.dismissModal.apply($options, arguments);
            })
          }, [_createVNode(_component_risma_title, {
            class: "mr-2",
            type: "medium",
            title: $data.translate.goToFullView
          }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
            icon: "maximize-2",
            width: "25",
            height: "25"
          })])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), _createVNode(_component_feather_icon, {
        class: "float-right stroke-2 m-4 cursor-pointer",
        icon: "x",
        width: "35",
        height: "35",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.dismissModal({
            path: []
          });
        })
      })]), $data.accessErrorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "info",
        closeable: false
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString($data.accessErrorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : (_openBlock(), _createBlock(_resolveDynamicComponent($data.component.component), _mergeProps({
        key: 1,
        "selected-route": $data.activeRoute
      }, _objectSpread(_objectSpread({}, $options.attrs), $data.component.params), {
        onDeleteItem: $options.deleteItem,
        onAccessError: $options.showAccessError,
        onUrlChanged: $options.onUrlChange,
        onChangeTab: $options.changeTab,
        onSetActiveRoute: $options.setActiveRoute
      }), null, 16 /* FULL_PROPS */, ["selected-route", "onDeleteItem", "onAccessError", "onUrlChanged", "onChangeTab", "onSetActiveRoute"]))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["group-selectors"])])) : _createCommentVNode("v-if", true)]);
}