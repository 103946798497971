function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import emitter from 'tiny-emitter/instance';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getAllMotherControls, getMyControls as _getMyControls } from '@/api/controls/controls';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { getSortFunction } from '@/utils/sort';
import { check403AccessError } from '@/utils/handleAPIErrors';
import { controlsListBaseMixin } from '@/components/Pages/Controls/controlsListBaseMixin';
import { deletionControlMixin } from '@/components/Pages/Controls/deletionControlMixin';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { ControlFields } from '@/constants/ControlFields';
import { ControlMyKeys } from '@/constants/ControlMyKeys';
import CompleteModal from '@/components/Molecules/Controls/CompleteModal';
import ControlReviewModal from '@/components/Molecules/Controls/ControlReviewModal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import CatalogueHeader from '@/components/Molecules/CatalogueHeader/CatalogueHeader';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import ControlFilters from '@/components/Organisms/Controls/ControlFilters';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import ControlsList from '@/components/Organisms/Controls/ControlsList';
import { STANDARD_VIEWS } from '@/constants/ViewTypes';
import { viewTypes } from '@/constants/ListViewType';
import { MODULES } from '@/constants/modules';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
import { CONTROL_ACTIONS_MESSAGES } from '@/constants/ControlActions';
import { ControlViews } from '@/constants/ControlViews';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
var MODULE_NAME = MODULES.CONTROLS;
export default {
  name: 'MyControlsWrapper',
  introduction: 'wrapper for list of my controls.',
  description: 'This is Page component for /controls2/my/',
  token: '<my-controls-wrapper></my-controls-wrapper>',
  components: {
    CatalogueHeader: CatalogueHeader,
    ControlReviewModal: ControlReviewModal,
    CompleteModal: CompleteModal,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Notification: Notification,
    ControlFilters: ControlFilters,
    VerticalMenu: VerticalMenu,
    ControlsList: ControlsList
  },
  mixins: [controlsListBaseMixin, deletionControlMixin],
  props: {
    viewMode: {
      required: false,
      type: String,
      default: '',
      note: 'if parent component have viewTypes and want to handle this component by them'
    },
    currentRouteGroup: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['updateFromPreviewMode', 'deleteItem'],
  setup: function setup() {
    return {
      currentView: getLocalStorageComputed('my_controls_display_view', ControlViews.EXPANDED)
    };
  },
  data: function data() {
    return {
      STANDARD_VIEWS: STANDARD_VIEWS,
      viewTypes: viewTypes,
      ControlButtonTypes: ControlButtonTypes,
      MODULE_NAME: MODULE_NAME,
      stateLocal: ControlLocalStorage.MY_CONTROLS_FILTERS,
      currentControlCpath: null,
      currentControlId: null,
      isCompleteModalShow: false,
      isReviewModalShow: false,
      isControlCatalogue: false,
      allMyControls: [],
      motherControls: [],
      filters: {
        organisations: [],
        tags: [],
        accounts: [],
        processes: [],
        frameworks: [],
        risks: [],
        mother: null,
        search: '',
        deadline: null,
        showOverviewOptions: false
      },
      updatedControlFromPreview: null,
      translate: getTranslate['MyControlsWrapper']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    notificationObj: function notificationObj() {
      if (this.detailLoadedError) {
        return {
          type: 'error',
          message: this.detailLoadedError
        };
      }
      if (this.genericError) {
        return {
          type: 'error',
          message: this.genericError
        };
      }
      if (this.isControlDeletedSuccess) {
        return {
          type: 'success',
          message: this.translate.controlDeleted
        };
      }
      return null;
    },
    sortedControls: function sortedControls() {
      var _this = this;
      var result = {};
      var sortFunctions = getSortFunction({
        sortBy: SortFieldsCompatibility.DEADLINE
      });
      Object.keys(this.allMyControls).forEach(function (key) {
        var _this$allMyControls$k;
        var sortedControls = ((_this$allMyControls$k = _this.allMyControls[key]) === null || _this$allMyControls$k === void 0 ? void 0 : _this$allMyControls$k.list) || [];
        sortedControls.sort(sortFunctions);
        result[key] = sortedControls;
      });
      return result;
    },
    filteredAndSortedControls: function filteredAndSortedControls() {
      var _this2 = this;
      var result = {};
      var _this$filters = this.filters,
        mother = _this$filters.mother,
        deadline = _this$filters.deadline,
        search = _this$filters.search,
        organisations = _this$filters.organisations,
        tags = _this$filters.tags,
        accounts = _this$filters.accounts,
        processes = _this$filters.processes,
        risks = _this$filters.risks,
        frameworks = _this$filters.frameworks;
      Object.keys(this.sortedControls).forEach(function (key) {
        var itemsToFilter = _this2.sortedControls[key] || [];
        result[key] = itemsToFilter.filter(function (control) {
          var filterPass = true;
          if (mother && control.mothercontrolId != mother) {
            return false;
          }
          if (deadline !== null) {
            filterPass = filterPass && _this2.controlPassFilterByDeadlineCriteria(control);
          }
          if (search) {
            filterPass = filterPass && _this2.controlPassFilterBySearchCriteria(control);
          }
          if (accounts !== null && accounts !== void 0 && accounts.length) {
            filterPass = filterPass && _this2.controlPassFilterByAssociationCriteria(control, 'accounts', accounts);
          }
          if (processes !== null && processes !== void 0 && processes.length) {
            filterPass = filterPass && _this2.controlPassFilterByAssociationCriteria(control, 'processes', processes);
          }
          if (risks !== null && risks !== void 0 && risks.length) {
            filterPass = filterPass && _this2.controlPassFilterByAssociationCriteria(control, 'risks', risks);
          }
          if (organisations.length) {
            filterPass = filterPass && _this2.controlPassFilterByOrganizationCriteria(control);
          }
          if (tags.length) {
            filterPass = filterPass && _this2.controlPassFilterByTagsCriteria(control);
          }
          if (_this2.filtersData.showDeactivated && !_this2.filters.showDeactivated) {
            filterPass = filterPass && control.active;
          }
          if (_this2.filtersData.users && _this2.filters.responsible.length) {
            filterPass = filterPass && _this2.controlPassFilterByResponsibleCriteria(control);
          }
          if (frameworks.length) {
            filterPass = filterPass && _this2.controlPassFilterByFrameworksCriteria(control);
          }
          return filterPass;
        });
      });
      return result;
    },
    controlsGroup: function controlsGroup() {
      var result = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ControlMyKeys.MY_CONTROLS, {
        key: ControlMyKeys.MY_CONTROLS,
        name: MODULE_NAME,
        label: this.translate.myControls,
        activities: this.filteredAndSortedControls[ControlMyKeys.MY_CONTROLS] || [],
        hidden: false,
        path: '/controls2/my',
        buttonType: ControlButtonTypes.COMPLETE_CONTROL,
        additionalFields: [ControlFields.REVIEWERS],
        immutableVisibilityColumns: ['Title', 'Action']
      }), ControlMyKeys.MY_REVIEWS, {
        key: ControlMyKeys.MY_REVIEWS,
        name: MODULE_NAME,
        label: this.translate.myReviews,
        activities: this.filteredAndSortedControls[ControlMyKeys.MY_REVIEWS] || [],
        hidden: false,
        path: '/controls2/my/' + ControlMyKeys.MY_REVIEWS,
        buttonType: ControlButtonTypes.REVIEW_CONTROL,
        additionalFields: [ControlFields.REVIEWERS, ControlFields.COMPLETED, ControlFields.REVIEW_DEADLINE, ControlFields.RESPONSIBLE],
        immutableVisibilityColumns: ['Title', 'Action']
      }), ControlMyKeys.IN_REVIEW, {
        key: ControlMyKeys.IN_REVIEW,
        name: MODULE_NAME,
        label: this.translate.myControlsBeingReviewed,
        activities: this.filteredAndSortedControls[ControlMyKeys.IN_REVIEW] || [],
        hidden: false,
        path: '/controls2/my/' + ControlMyKeys.IN_REVIEW,
        additionalFields: [ControlFields.REVIEWERS, ControlFields.COMPLETED, ControlFields.REVIEW_DEADLINE],
        immutableVisibilityColumns: ['Title', 'Action']
      }), ControlMyKeys.REJECTED, {
        key: ControlMyKeys.REJECTED,
        name: MODULE_NAME,
        label: this.translate.myRejectedControls,
        activities: this.filteredAndSortedControls[ControlMyKeys.REJECTED] || [],
        hidden: false,
        path: '/controls2/my/' + ControlMyKeys.REJECTED,
        buttonType: ControlButtonTypes.COMPLETE_CONTROL_AGAIN,
        immutableVisibilityColumns: ['Title', 'Action']
      }), ControlMyKeys.OVERDUE, {
        key: ControlMyKeys.OVERDUE,
        name: MODULE_NAME,
        label: this.translate.myOverdueControls,
        activities: this.filteredAndSortedControls[ControlMyKeys.OVERDUE] || [],
        hidden: false,
        path: '/controls2/my/' + ControlMyKeys.OVERDUE,
        buttonType: ControlButtonTypes.COMPLETE_CONTROL,
        additionalFields: [ControlFields.REVIEWERS],
        immutableVisibilityColumns: ['Title', 'Action']
      }), ControlMyKeys.DAILY_TEAM, {
        key: ControlMyKeys.DAILY_TEAM,
        name: MODULE_NAME,
        label: this.translate.myDailyTeamControls,
        activities: this.filteredAndSortedControls[ControlMyKeys.DAILY_TEAM] || [],
        hidden: false,
        path: '/controls2/my/' + ControlMyKeys.DAILY_TEAM,
        buttonType: ControlButtonTypes.COMPLETE_CONTROL,
        immutableVisibilityColumns: ['Title', 'Action']
      }), ControlMyKeys.OTHER, {
        key: ControlMyKeys.OTHER,
        name: MODULE_NAME,
        label: this.translate.othersControls,
        activities: this.filteredAndSortedControls[ControlMyKeys.OTHER] || [],
        hidden: false,
        path: '/controls2/my/' + ControlMyKeys.OTHER,
        buttonType: ControlButtonTypes.COMPLETE_CONTROL,
        additionalFields: [ControlFields.RESPONSIBLE, ControlFields.REVIEWERS],
        immutableVisibilityColumns: ['Title', 'Action']
      });
      if (!this.getSettingValue('risma.show_controls_under_review')) {
        delete result[ControlMyKeys.IN_REVIEW];
      }
      return result;
    },
    // allMyControls -> sortedControls -> filteredAndSortedControls ->controlsGroup
    currentGroup: function currentGroup() {
      return this.currentRouteGroup ? this.controlsGroup[this.currentRouteGroup] || null : this.controlsGroup[ControlMyKeys.MY_CONTROLS];
    },
    detailLoadedError: function detailLoadedError() {
      return check403AccessError(this.$route, 'control');
    },
    menuItems: function menuItems() {
      return Object.values(this.controlsGroup).map(function (item) {
        return {
          title: "".concat(item.label, " (").concat(item.activities.length, ")"),
          path: item.path
        };
      });
    },
    noControls: function noControls() {
      return !(this.currentGroup && this.currentGroup.activities.length);
    }
  }),
  watch: {
    viewMode: function viewMode() {
      this.currentView = this.viewMode;
    }
  },
  mounted: function mounted() {
    var keys = Object.values(this.controlsGroup).map(function (item) {
      return item.key;
    });
    if (this.currentRouteGroup && !keys.includes(this.currentRouteGroup)) {
      this.$router.push(this.controlsGroup[ControlMyKeys.MY_CONTROLS].path);
    }
    this.loadData();
  },
  methods: {
    controlUpdate: function controlUpdate(id, _ref) {
      var _this3 = this;
      var state = _ref.state,
        _ref$isSave = _ref.isSave,
        isSave = _ref$isSave === void 0 ? false : _ref$isSave;
      var hideNotification = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var searchFieldValue = this.filters.search;
      var hasNotification = !isSave && !hideNotification;
      if (hasNotification) {
        var control = this.controls.find(function (element) {
          return element.id === +id;
        });
        if (!control) return;
        this.$notify({
          title: this.translate.success + '!',
          urlTitle: this.$trans(CONTROL_ACTIONS_MESSAGES[state]),
          url: control.url
        });
      }
      this.loadData().then(function () {
        return _this3.filters.search = searchFieldValue;
      });
    },
    loadData: function loadData() {
      var _this4 = this;
      this.isLoaded = false;
      this.isReviewModalShow = false;
      return Promise.all([this.getOrgs(), this.getAccounts(), this.getProcesses(), this.getControlRisks(), this.getMotherControls(), this.getMyControls()]).then(function () {
        _this4.setCompability();
        setTimeout(function () {
          emitter.emit('emit-scroll');
        }, 800);
      }).catch(function () {
        _this4.genericError = _this4.$trans(ControlValidationMessages.SOME_ERROR_OCCURRED);
      }).finally(function () {
        _this4.isLoaded = true;
        if (_this4.$route.query.successDeletion) {
          _this4.handleDeletedControlSuccess();
        }
      });
    },
    getMotherControls: function getMotherControls() {
      var _this5 = this;
      return getAllMotherControls().then(function (_ref2) {
        var list = _ref2.list;
        return _this5.motherControls = list;
      });
    },
    getMyControls: function getMyControls() {
      var _this6 = this;
      var params = '?data[]=organisationIds&data[]=associations';
      return _getMyControls(params).then(function (data) {
        _this6.allMyControls = data;
        _this6.controls = _this6.getOnlyControls(data);
        if (!_this6.filterByUserAccess) return;
        return _this6.addMissingOrgs(_this6.controls);
      });
    },
    addMissingOrgs: function addMissingOrgs(controls) {
      var _this7 = this;
      return RismaControlsUtils.addMissingOrgs(controls).then(function (data) {
        if (data.length) {
          _this7.appendAdditionalOrgs(data);
        }
      });
    },
    showCompleteModal: function showCompleteModal(event) {
      this.currentControlCpath = event.cpath;
      this.currentControlId = event.id;
      this.isCompleteModalShow = true;
    },
    showReviewModal: function showReviewModal(event) {
      this.currentControlCpath = event.cpath;
      this.currentControlId = event.id;
      this.isReviewModalShow = true;
    },
    getOnlyControls: function getOnlyControls(groupedData) {
      var result = [];
      Object.keys(groupedData).forEach(function (key) {
        var _groupedData$key;
        if ((_groupedData$key = groupedData[key]) !== null && _groupedData$key !== void 0 && _groupedData$key.list) {
          result = result.concat(groupedData[key].list);
        }
      });
      return result;
    },
    resetFilters: function resetFilters() {
      this.filters = {
        organisations: [],
        tags: [],
        accounts: [],
        processes: [],
        frameworks: [],
        risks: [],
        mother: null,
        search: '',
        deadline: null,
        showOverviewOptions: true
      };
    },
    updateFromPreviewMode: function updateFromPreviewMode() {
      var _this$allMyControls$g;
      if (!this.updatedControlFromPreview) return;
      var groupKey = this.currentGroup.key;
      var updatedList = ((_this$allMyControls$g = this.allMyControls[groupKey]) === null || _this$allMyControls$g === void 0 ? void 0 : _this$allMyControls$g.list) || [];
      if (!(updatedList !== null && updatedList !== void 0 && updatedList.length)) return;
      var id = this.updatedControlFromPreview.updatedControlId || this.updatedControlFromPreview.id;
      var updatedControlIdx = updatedList.findIndex(function (a) {
        return a.id === id;
      });
      if (updatedControlIdx === -1) return;
      updatedList[updatedControlIdx] = this.updatedControlFromPreview;
      this.allMyControls[groupKey] = {
        list: updatedList,
        count: updatedList.length
      };
      this.updatedControlFromPreview = null;
    }
  }
};