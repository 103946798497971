import DynamicTooltip from '@/components/Molecules/DynamicTooltip';
import InlineSelect from '@/components/Atoms/Inputs/InlineSelect';
import { TOOLTIP_POSITIONS } from '@/constants/Tooltips';
export default {
  name: '',
  components: {
    DynamicTooltip: DynamicTooltip,
    InlineSelect: InlineSelect
  },
  data: function data() {
    return {
      positionOptions: Object.values(TOOLTIP_POSITIONS).map(function (item) {
        return {
          label: item,
          value: item
        };
      }),
      position: TOOLTIP_POSITIONS.RIGHT
    };
  }
};