import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_card_information_assets = _resolveComponent("card-information-assets");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.informationAssets.length,
    title: _ctx.informationAssetsTitle(),
    class: "information_assets",
    "cookie-hidden-name": "information_assets",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_load_more_wrapper, {
        activities: $props.informationAssets,
        class: "`information-assets-list information-assets-list-${viewMode}`"
      }, {
        default: _withCtx(function (_ref) {
          var activitiesLimited = _ref.activitiesLimited;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (informationAsset, index) {
            return _openBlock(), _createElementBlock("div", {
              key: "preview-info-asset".concat(index),
              class: _normalizeClass("preview information-assets-item-".concat($props.viewMode))
            }, [_createVNode(_component_card_information_assets, {
              "truncate-length": $options.truncateLength,
              "information-asset": informationAsset,
              "asset-type": $options.getAssetType(informationAsset)
            }, null, 8 /* PROPS */, ["truncate-length", "information-asset", "asset-type"])], 2 /* CLASS */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["activities"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}