import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex flex-wrap mb-3"
};
var _hoisted_3 = {
  class: "mr-6 w-350px"
};
var _hoisted_4 = {
  class: "mr-6 min-w-250px w-1/4 h-5.5rem"
};
var _hoisted_5 = {
  class: "mr-6 min-w-250px w-1/4 h-5.5rem"
};
var _hoisted_6 = {
  class: "flex items-center mb-6"
};
var _hoisted_7 = ["href", "download", "title"];
var _hoisted_8 = ["href", "download", "title"];
var _hoisted_9 = {
  key: 1,
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: $data.searchField,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.searchField = $event;
    }),
    title: $options.searchTitle,
    placeholder: $data.translate.search,
    type: "text",
    class: "mb-px"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_select, {
    title: $data.translate.contractStatus,
    options: $options.preparedContractStatuses,
    "selected-options": $data.selectedContractStatuses,
    "un-sorted": true,
    placeholder: $data.translate.select,
    "label-key": "label",
    onSelected: $options.selectContractStatus
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_select, {
    title: $data.translate.documentTypes,
    options: $options.fileTypes,
    "selected-options": $data.selectedFileTypes,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "label-key": "label",
    onSelected: $options.selectFileTypes
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"])]), _createVNode(_component_risma_button, {
    text: $data.translate.search,
    type: "save",
    class: "mr-2 flex items-center",
    onClick: $options.doSearch
  }, null, 8 /* PROPS */, ["text", "onClick"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_button, {
    text: $data.translate.resetFilter,
    type: "util",
    class: "text-right",
    onClick: $options.resetFilter
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        class: "mr-1 stroke-2",
        icon: "rotate-ccw",
        height: "18",
        width: "18"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])]), $data.isTableDataLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : $data.searchClicked ? (_openBlock(), _createBlock(_component_main_well, {
    key: 1,
    class: "h-auto"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.documents,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["title"]), $data.files.length > 0 ? (_openBlock(), _createBlock(_component_static_table, {
        key: 0,
        columns: $options.columns,
        "dataset-table": $options.dataset,
        "hover-titles": {
          pin: null
        }
      }, {
        title: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createElementVNode("a", {
            href: slotData.url,
            download: slotData.filename,
            title: slotData.filename,
            target: "_self",
            type: "download",
            class: "whitespace-normal flex"
          }, _toDisplayString(slotData.title), 9 /* TEXT, PROPS */, _hoisted_7)];
        }),
        download: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [_createElementVNode("a", {
            class: "flex cursor-pointer hover:underline w-16",
            href: slotData.url,
            download: slotData.filename,
            title: slotData.filename,
            target: "_self",
            type: "download"
          }, [_createVNode(_component_feather_icon, {
            icon: "download",
            class: "text-blue-750 stroke-2"
          })], 8 /* PROPS */, _hoisted_8)];
        }),
        contract: _withCtx(function (_ref3) {
          var slotData = _ref3.slotData;
          return [_createVNode(_component_preview_modal_link, {
            activity: slotData.activity,
            onDismissModal: _cache[1] || (_cache[1] = function ($event) {
              return _ctx.$emit('dismissModal');
            })
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                title: slotData.activity.title,
                "title-attribute": slotData.hoverTitle,
                "display-icon": slotData.activity.confidential
              }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset-table"])) : _createCommentVNode("v-if", true), !$data.files.length ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
        title: $data.translate.nothingToShow,
        type: "big"
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.theSelectedSearchCriteriaHaveNoResultsToDi), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  }));
}