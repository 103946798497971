function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { deCapitalize } from '@/utils/capitalize';
export var INTERNAL_SELECTBOX_OPTIONS_NAMES = ['TypeOfICTService', 'BinaryYesNo', 'SensitivenessOfData', 'LevelOfReliance'];
export var EXTERNAL_SELECTBOX_OPTIONS_NAMES = ['TypeOfICTService', 'BinaryYesNo', 'YesNoNotAssessed', 'PossibilityOfReintegration', 'SubstitutabilityOfICTProvider', 'SubstitutabilityReason', 'ImpactOfDiscontinuation'];
export var SELECTBOX_OPTIONS_NAMES = _toConsumableArray(new Set([].concat(INTERNAL_SELECTBOX_OPTIONS_NAMES, EXTERNAL_SELECTBOX_OPTIONS_NAMES)));
export var extractOptions = function extractOptions(optionKeys, sourceObj) {
  return optionKeys.reduce(function (acc, key) {
    acc[key] = sourceObj[key];
    return acc;
  }, {});
};
export var extractInternalOptions = function extractInternalOptions(sourceObj) {
  var optionsNames = INTERNAL_SELECTBOX_OPTIONS_NAMES.map(function (item) {
    return deCapitalize(item);
  });
  return extractOptions(optionsNames, sourceObj);
};
export var extractExternalOptions = function extractExternalOptions(sourceObj) {
  var optionsNames = EXTERNAL_SELECTBOX_OPTIONS_NAMES.map(function (item) {
    return deCapitalize(item);
  });
  return extractOptions(optionsNames, sourceObj);
};