import { marked } from 'marked';
var renderer = new marked.Renderer();
renderer.link = function (_ref) {
  var href = _ref.href,
    text = _ref.text;
  var target = '_blank';
  var rel = 'noopener noreferrer';
  return "<a href=\"".concat(href, "\" target=\"").concat(target, "\" rel=\"").concat(rel, "\">").concat(text, "</a>");
};
export default {
  name: 'MarkdownRenderer',
  introduction: 'Component to render markdown. This component was created to render responses from Azure Ai that are displayed in the AiAssistant component',
  description: 'For markdown styling, use the classes with tailwind plugin https://github.com/tailwindlabs/tailwindcss-typography/blob/master/README.md',
  token: '<markdown-renderer :markdown="markdown" />',
  props: {
    markdown: {
      type: String,
      required: true
    }
  },
  computed: {
    compiledMarkdown: function compiledMarkdown() {
      return marked(this.markdown, {
        renderer: renderer
      });
    }
  }
};