import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_carousel = _resolveComponent("risma-carousel");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_carousel, {
    class: "mx-auto",
    list: $data.list
  }, {
    default: _withCtx(function (_ref) {
      var slide = _ref.slide;
      return [_createElementVNode("div", {
        class: _normalizeClass(slide.style)
      }, _toDisplayString(slide.text), 3 /* TEXT, CLASS */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["list"])]);
}