export default {
  name: 'UserRightsAdmin',
  introduction: 'admin items for both risks and compliance with admin rights',
  description: '',
  token: '<user-rights-admin />',
  methods: {
    handleShowNotification: function handleShowNotification(notification) {
      notification.url ? this.$notify({
        title: notification.header || '',
        url: notification.url,
        urlTitle: notification.message || '',
        type: notification.type
      }) : this.$notify({
        title: [notification.header || '', notification.message || ''],
        type: notification.type
      });
    }
  }
};