// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-admin-types[data-v-d98374aa] {
  width: 100%;
}
.table-admin-types th[data-v-d98374aa] {
  padding: 4px 10px 4px 10px;
  border-bottom: 1pt solid #BEBEBE;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  text-align: center;
}
.table-admin-types th.thead-empty-cell[data-v-d98374aa] {
  width: 210px;
}
.table-admin-types th.thead-small[data-v-d98374aa] {
  width: 150px;
}
.table-admin-types th[data-v-d98374aa]  p {
  margin-bottom: 5px;
}
.table-admin-types .table-row-custom-field td[data-v-d98374aa] {
  padding: 10px 10px;
  border-bottom: 1pt solid #BEBEBE;
}
.table-admin-types .table-row-custom-field .table-radio-buttons[data-v-d98374aa] {
  padding: 10px 0 10px 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
