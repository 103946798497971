import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_preview_list, {
    length: 8,
    title: "The title of list",
    "cookie-hidden-name": "kitchensink_previewlist"
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("Put any list here")]);
    }),
    _: 1 /* STABLE */
  })]);
}