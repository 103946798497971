function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
export default {
  name: 'ButtonsRow',
  components: {
    RismaButton: RismaButton
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: function _default() {
        return [{
          text: '',
          // the text that should appear on the buttons, if text in array is missing -> element will be filtered
          title: '',
          // Mouse-over title for buttons
          type: '',
          // The type of the button. This is used to control styling, can be Array if multiple elements should have different types
          func: '',
          // Corresponds to a button tags normal type prop
          disabled: '',
          // Bool to determine if the button should be disabled or not, can be Array if some buttons should be disabled
          buttonClasses: '',
          // Button classes applied for each button individually
          clickEvent: '',
          // click events, looks like the best option when elements can be hidden
          slotName: '' // slotName, used to show elements name is button-${slotName}, if not used -> button-${index}
        }];
      }
    },
    isCustomButtonSize: {
      required: false,
      type: Boolean,
      default: false,
      note: 'if button needs custom size (w-fit disables override by classes, only css styles, without this prop'
    }
  },
  emits: ['click'],
  methods: {
    buttonClicked: function buttonClicked(index, e) {
      if (this.data[index].disabled) return;
      this.$emit('click', _objectSpread(_objectSpread({}, e), {}, {
        index: index
      }));
    }
  }
};