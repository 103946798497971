// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-settings-profile[data-v-7f902bdd] {
  display: block;
  width: 100%;
}
.user-settings-profile a[data-v-7f902bdd] {
  text-decoration: none;
}
.user-settings-profile .user-info[data-v-7f902bdd] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-settings-profile .user-info .user-initials[data-v-7f902bdd]  p {
  margin-bottom: 0;
  font-weight: 700;
}
.user-settings-profile .user-info[data-v-7f902bdd]  .big {
  line-height: 32px;
}
.user-settings-profile .user-info-wrapper[data-v-7f902bdd] {
  display: flex;
  margin-bottom: 0.5rem;
}
.user-settings-profile .user-info p[data-v-7f902bdd] {
  margin: 0;
}
.user-settings-profile .user-setting-avatar-file-input[data-v-7f902bdd] {
  display: none;
}
.user-settings-profile .user-settings-language[data-v-7f902bdd] {
  position: relative;
  max-width: 450px;
}
.user-settings-profile .user-settings-language .flag-icon-wrap[data-v-7f902bdd] {
  display: flex;
}
.user-settings-profile .user-settings-language .flag-label[data-v-7f902bdd] {
  padding-left: 20px;
  line-height: 30px;
}
.user-settings-profile .user-settings-language .user-settings-language-modal[data-v-7f902bdd] {
  position: absolute;
  top: -20px;
  left: 40px;
  display: flex;
  flex-wrap: wrap;
  width: 82px;
  padding: 10px;
  border: 1px solid #737373;
  border-radius: 6px;
  background-color: #FFFFFF;
}
.user-settings-profile .user-settings-language .flag-big-wrap[data-v-7f902bdd] {
  padding: 5px;
}
.user-settings-profile .user-avatar[data-v-7f902bdd] {
  margin-right: 30px;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.user-settings-profile .user-avatar .user-avatar-abbr[data-v-7f902bdd] {
  font-size: 50px;
  width: 84px;
  height: 84px;
  line-height: 80px;
  font-weight: bold;
  color: #347480;
  margin: 0;
  border: 2px solid #4CB3C0;
  border-radius: 100%;
}
.user-settings-profile .reset-password[data-v-7f902bdd] {
  padding: 20px;
  background: #ebebeb;
  border-radius: 4px;
  font-size: 16px;
  display: inline-block;
}
.user-settings-profile .organisation ~ .organisation[data-v-7f902bdd]:not(:empty):before {
  content: ", ";
}
.configuration-key[data-v-7f902bdd] {
  top: 20px;
}
.angled-border[data-v-7f902bdd] {
  fill: none;
  stroke: #000000;
  stroke-width: 3px;
  stroke-dasharray: 50%;
  stroke-dashoffset: 25%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
