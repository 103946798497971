function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { getTranslate } from './translate';
import { Colors } from '@/Colors';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { comparePathStrings } from '@/utils/compare';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { customizeColumnsWithGapsFn, customizeDatasetWithGapsFn } from '@/components/Atoms/RismaDatatable/ExportUtils';
import { getItemGaps } from './SupervisorUtils';
import { prepareDateTimezone } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
import { MODULES } from '@/constants/modules';
export default {
  name: 'SupervisorControls',
  introduction: 'Supervisor controls dashboard',
  description: 'This sub-page shows the supervisor controls dashboards',
  token: '<supervisor-controls></supervisor-controls>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaDoughnutChart: RismaDoughnutChart,
    StaticTable: StaticTable,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    controls: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true,
      note: 'list of all selectable users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'list of all selectable organisations'
    },
    gapArticleMap: {
      type: Map,
      required: true,
      note: 'A list of gap articles'
    }
  },
  emits: ['logReport', 'dismissModal'],
  data: function data() {
    return {
      colors: [Colors.noGap, Colors.high, Colors.low, Colors.medium],
      colorsPending: [Colors.noGap, Colors.medium, Colors.high, Colors.notSet],
      colorsCompleted: [Colors.noGap, Colors.high],
      showDataControlstitle: '',
      showDataControls: [],
      columnSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpathObject
      }],
      showEmptyText: false,
      translate: getTranslate['SupervisorControls']()
    };
  },
  computed: {
    columns: function columns() {
      return {
        trafficlight: this.translate.trafficLight,
        cpath: this.translate.controlNumber,
        title: this.translate.title,
        description: this.translate.description,
        gaps: this.translate.gaps,
        organisation: this.translate.organisation,
        escalation: this.translate.escalation,
        responsible: this.translate.responsible,
        reviewers: this.translate.reviewers,
        frequency: this.translate.frequency,
        deadline: this.translate.deadline,
        review_deadline: this.translate.reviewDeadline,
        control_status: this.translate.controlStatus
      };
    },
    tableData: function tableData() {
      var _this = this;
      return this.controls.map(function (control) {
        var trafficLight = DatatableRenderer.prepareTrafficLightField(control.trafficLight, MODULES.CONTROLS);
        return {
          trafficlight: {
            field: trafficLight,
            fieldText: trafficLight.label
          },
          cpath: {
            field: control.cpath
          },
          title: {
            activity: getFieldsFromObjectForPreview(control),
            fieldText: control.title
          },
          description: {
            field: control.description
          },
          gaps: {
            activities: getItemGaps(control.gap, _this.gapArticleMap)
          },
          organisation: {
            field: Utils.findPropsByIds(control.organisationIds, _this.organisations, 'visible_name')
          },
          escalation: {
            field: Utils.findPropsByIds(control.userIds.escalation, _this.users, 'display_name')
          },
          responsible: {
            field: Utils.findPropsByIds(control.userIds.responsible, _this.users, 'display_name')
          },
          reviewers: {
            field: Utils.findPropsByIds(control.userIds.review, _this.users, 'display_name')
          },
          frequency: {
            field: _this.$trans(RismaControlsUtils.frequency2text(control.frequency))
          },
          deadline: {
            field: prepareDateTimezone(control.deadline, control.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
          },
          review_deadline: {
            field: prepareDateTimezone(control.reviewDeadline, control.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
          },
          control_status: {
            field: _this.$trans(RismaControlsUtils.state2text(control.rismaState))
          }
        };
      });
    },
    chartData: function chartData() {
      return {
        controls_status: {
          title: this.translate.controlStatus,
          labels: [this.translate.completed, this.translate.pending + '/' + this.translate.rejected, this.translate.awaitingReview],
          datasets: [{
            backgroundColor: this.colors,
            data: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW).length]
          }],
          controls: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW)]
        },
        controls_reviews: {
          title: this.translate.reviews,
          labels: [this.translate.reviewCompleted, this.translate.rejected, this.translate.pendingReview],
          datasets: [{
            backgroundColor: this.colors,
            data: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_REVIEW_REJECTED).length, RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW).length]
          }],
          controls: [RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_REVIEW_REJECTED), RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_READY_FOR_REVIEW)]
        },
        controls_pending: {
          title: this.translate.pendingControls,
          labels: [this.translate.greenTrafficLight, this.translate.yellowTrafficLight, this.translate.redTrafficLight, this.translate.rejectedControls],
          datasets: [{
            backgroundColor: this.colorsPending,
            data: [RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.green).length, RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.yellow).length, RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.red).length, RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.states.STATE_REVIEW_REJECTED).length]
          }],
          controls: [RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.green), RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.yellow), RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.trafficLights.red), RismaControlsUtils.filterControlsByTrafficLight(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_PENDING), RismaControlsUtils.states.STATE_REVIEW_REJECTED)]
        },
        controls_completed: {
          title: this.translate.completedControls,
          labels: [this.translate.completedOnTime, this.translate.completedAfterDeadline],
          datasets: [{
            backgroundColor: this.colorsCompleted,
            data: [RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'ok').length, RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'overdue').length]
          }],
          controls: [RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'ok'), RismaControlsUtils.filterControlsByCompletion(RismaControlsUtils.filterControlsByStatus(this.controls, RismaControlsUtils.states.STATE_COMPLETED), 'overdue')]
        }
      };
    },
    customExport: function customExport() {
      return {
        columns: customizeColumnsWithGapsFn,
        dataset: customizeDatasetWithGapsFn(this.gapArticleMap)
      };
    }
  },
  watch: {
    tableData: {
      deep: true,
      handler: function handler() {
        this.enhanceDataset(this.tableData, this.columns);
      }
    }
  },
  mounted: function mounted() {
    this.enhanceDataset(this.tableData, this.columns);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber'])), {}, {
    showData: function showData(item) {
      var _item$controls,
        _this2 = this;
      var segment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.showEmptyText = false;
      if ((_item$controls = item.controls) !== null && _item$controls !== void 0 && _item$controls.length) {
        this.showDataControlstitle = item.title;
        this.showDataControls = [];
        item.controls[segment].forEach(function (control) {
          _this2.showDataControls.push({
            trafficlight: {
              color: _this2.getTrafficLightColorByObjectTypeAndNumber(control.objType, control.trafficLight)
            },
            title: {
              data: getFieldsFromObjectForPreview(control),
              title: control.title,
              cpath: control.cpath
            },
            deadline: prepareDateTimezone(control.deadline, control.deadlineTimezone, DateFormats.DATE_TIME_FORMAT)
          });
        });
      } else {
        this.showDataControls = [];
        this.showEmptyText = true;
      }
    },
    sortingByCpathObject: function sortingByCpathObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        return comparePathStrings(x, y);
      });
    }
  })
};