import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  key: 0,
  class: "inline-block rounded-full p-1 mr-1 bg-blue-750 text-white"
};
var _hoisted_3 = {
  key: 0,
  class: "inline-block rounded-full p-1 mr-1 bg-blue-750 text-white"
};
var _hoisted_4 = {
  key: 3,
  class: "flex justify-end"
};
var _hoisted_5 = {
  class: "py-2 font-bold text-gray-650"
};
var _hoisted_6 = {
  class: "mr-2"
};
var _hoisted_7 = {
  key: 0,
  class: "inline-block rounded-full p-1 bg-blue-750 text-white"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_searchable_selector = _resolveComponent("searchable-selector");
  var _component_selector = _resolveComponent("selector");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: _ctx.$trans($props.modalSettings.title),
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    onAccept: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('updateDelegationItem', $data.selectedItemIds);
    }),
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('closeDelegationModal');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$props.showAddItem ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "absolute right-4 flex cursor-pointer",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.openCreationModal && $options.openCreationModal.apply($options, arguments);
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "plus"
      }), _createVNode(_component_risma_title, {
        title: $data.translate.createNew,
        type: "small"
      }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), $props.modalSettings.type === $data.DelegationTypes.users && !$props.skipApi ? (_openBlock(), _createBlock(_component_searchable_selector, {
        key: 1,
        title: $options.optionsLabel,
        "selected-items-ids": $data.selectedItemIds,
        "search-placeholder": _ctx.$trans($props.modalSettings.searchPlaceholder),
        api: $options.getUsersAPI,
        "selected-filter-fields": $props.modalSettings.searchFields,
        "change-focus-on-hover": false,
        "additional-parameters": $options.userAPIAdditionalParameters,
        onSelected: _cache[1] || (_cache[1] = function ($event) {
          return $options.selected($event, true);
        }),
        onToggleSelect: $options.toggleSelect
      }, {
        displayedName: _withCtx(function (slotProps) {
          return [_createElementVNode("p", null, [$props.modalSettings.shortTitleField && slotProps.item[$props.modalSettings.shortTitleField] ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.item[$props.modalSettings.shortTitleField]), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(slotProps.item[$props.modalSettings.titleField]), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title", "selected-items-ids", "search-placeholder", "api", "selected-filter-fields", "additional-parameters", "onToggleSelect"])) : (_openBlock(), _createBlock(_component_selector, {
        key: 2,
        items: $options.selectableItems,
        title: $options.optionsLabel,
        "search-placeholder": _ctx.$trans($props.modalSettings.searchPlaceholder),
        "search-fields": $props.modalSettings.searchFields,
        "change-focus-on-hover": false,
        onSelected: $options.selected,
        onToggleSelect: $options.toggleSelect
      }, {
        displayedName: _withCtx(function (slotProps) {
          return [_createElementVNode("p", null, [$props.modalSettings.shortTitleField && slotProps.item[$props.modalSettings.shortTitleField] ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(slotProps.item[$props.modalSettings.shortTitleField]), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(slotProps.item[$props.modalSettings.titleField]), 1 /* TEXT */)])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["items", "title", "search-placeholder", "search-fields", "onSelected", "onToggleSelect"])), $options.showAssignToMeButton ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("span", {
        class: "cursor-pointer text-blue-350 hover:text-blue-750 font-semibold",
        onClick: _cache[2] || (_cache[2] = function () {
          return $options.assignToMe && $options.assignToMe.apply($options, arguments);
        })
      }, _toDisplayString($data.translate.assignToMe), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: _normalizeClass(["mt-6 pt-3 border-t border-gray-450 transition-all", $data.isSelectExpanded ? 'min-h-160px' : 'min-h-5rem'])
      }, [_createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$trans($props.modalSettings.subtitle)), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectedItems, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "flex items-baseline px-1 py-1 rounded-md cursor-default hover:bg-gray-350"
        }, [_createElementVNode("p", _hoisted_6, [$props.modalSettings.shortTitleField && item[$props.modalSettings.shortTitleField] ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item[$props.modalSettings.shortTitleField]), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(item[$props.modalSettings.titleField]), 1 /* TEXT */)]), $props.withReset ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: "x",
          width: "12",
          height: "12",
          class: _normalizeClass('stroke-gray stroke-3 hover:stroke-red-50 cursor-pointer'),
          onClick: function onClick($event) {
            return $options.deselect(item);
          }
        }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"]);
}