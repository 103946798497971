import { UserLevels } from '@/constants/UserLevels';
import { MenuKeys } from '@/constants/menu/MenuKeys';
function buildControlsMenu(user) {
  var menuStructure = {
    main: [],
    admin: []
  };
  menuStructure.main.push({
    url: '/controls2/my',
    title: 'My controls',
    rights: true,
    // Add access check
    menuKey: MenuKeys.myControls,
    checkActiveSubItem: function checkActiveSubItem(url) {
      return url.startsWith('/controls2/my');
    }
  });
  menuStructure.main.push({
    url: '/controls2/controls',
    title: 'Overview',
    rights: true,
    // Add access check
    menuKey: MenuKeys.controls
  });
  menuStructure.main.push({
    url: '/controls2/reports',
    title: 'Reports',
    rights: true,
    // Add access check
    menuKey: MenuKeys.reports
  });
  var annualOverviewUrl = '/controls2/dashboards/annual-overview';
  var dashboardUrl = '/controls2/dashboards';
  menuStructure.main.push({
    url: dashboardUrl,
    title: 'Dashboards',
    rights: user.level_controls >= UserLevels.NORMAL,
    menuKey: MenuKeys.dashboards,
    children: [{
      url: dashboardUrl,
      title: 'Dashboard',
      exact: true,
      rights: user.level_controls > UserLevels.NORMAL,
      menuKey: MenuKeys.statusOverview
    }, {
      url: annualOverviewUrl,
      title: 'Annual overview',
      exact: true,
      rights: user.level_controls >= UserLevels.NORMAL,
      menuKey: MenuKeys.annualOverview,
      checkActiveSubItem: function checkActiveSubItem(url) {
        return url.startsWith(annualOverviewUrl);
      }
    }]
  });
  return menuStructure;
}
export default buildControlsMenu;