function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import * as Utils from '@/utils/Utils';
import { sortByIndex } from '@/utils/sort';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getProbabilityLabel, getConsequenceLabel } from '@/utils/risks/riskPeriod';
import { toLocalDateTime } from '@/utils/date';
import { MODULES } from '@/constants/modules';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { RiskBaseModelProps, columnsTypeByScoresName } from '@/constants/risks/RiskBaseModelProps';
import { ControlViews } from '@/constants/ControlViews';
import { RiskCookies } from '@/constants/risks/RiskCookies';
import { RiskStatusLabel } from '@/constants/risks/RisksReports';
import { SortDirection } from '@/constants/SortDirection';
import { ReportTypes } from '@/constants/reports';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { RiskDegreeTitleMixin } from '@/mixins/RiskDegreeTitleMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
export default {
  name: 'RiskReportDatatable',
  introduction: '',
  description: '',
  token: '<risk-report-datatable />',
  components: {
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    RismaTitleWithIcon: RismaTitleWithIcon,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, RaciTitleMixin, ProcessLibraryTitleMixin, RiskDegreeTitleMixin, SaveReportMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    },
    currentPeriod: {
      required: true,
      type: Object,
      note: 'Current risk period'
    },
    isCurrentPeriodSelected: {
      type: Boolean,
      required: false,
      default: true,
      note: 'If it is current period or scoring selected'
    },
    consequenceList: {
      required: true,
      type: Array,
      note: 'List of selected consequences'
    },
    risks: {
      required: true,
      type: Array,
      note: 'Array of risks (from /risks/reports)'
    },
    riskApprovalEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is Risk Approval feature enabled?'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risma users'
    },
    organisations: {
      type: Array,
      required: true,
      note: 'list of all selectable organisations'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk threats'
    },
    levelOfThreats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Level of threats'
    },
    vulnerabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    probabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    controls: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All controls, uses for linked activities'
    },
    initiatives: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All initiatives, uses for linked activities'
    },
    systems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All systems, uses for linked assets'
    },
    processLibraryNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All process library nodes, uses for linked assets'
    },
    complianceNodes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All compliance nodes, uses for linked assets'
    },
    incidents: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All incidents, uses for linked assets'
    },
    tags: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'tags to preview risk list to choose from'
    },
    managementLevels: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'managementLevels list'
    },
    isErm: {
      type: Boolean,
      required: false,
      default: false
    },
    riskTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All riskTypes, uses in risk table'
    },
    parentRisks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'All parent risks'
    },
    inherentRisksEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is inherent vs. residual risk enabled'
    },
    reportOptions: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['dismissModal', 'updated'],
  data: function data() {
    return {
      dataset: [],
      columns: {},
      linkedColumnsForPreview: ['linkedInitiatives', 'linkedControls', 'linkedSystems', 'linkedProcessLibrary', 'linkedComplianceNodes', 'linkedIncidents'],
      decoupleFilterFields: [{
        key: RiskBaseModelProps.RESPONSIBLE_USER_IDS
      }, {
        key: RiskBaseModelProps.ACCOUNTABLE_USER_IDS
      }, {
        key: RiskBaseModelProps.CONSULTED_USER_IDS
      }, {
        key: RiskBaseModelProps.INFORMED_USER_IDS
      }, {
        key: RiskBaseModelProps.ORGANISATION_IDS
      }, {
        key: RiskBaseModelProps.TAGS
      }, {
        key: 'linkedInitiatives'
      }, {
        key: 'linkedControls'
      }, {
        key: 'linkedSystems'
      }, {
        key: 'linkedProcessLibrary'
      }, {
        key: 'linkedComplianceNodes'
      }, {
        key: 'linkedIncidents'
      }, {
        key: 'frameworks'
      }],
      pageLength: 50,
      risksSelectedSections: [{
        title: '',
        list: []
      }],
      controlViews: ControlViews,
      columnFiltersSorting: [{
        key: 'id',
        sortingFunc: this.sortingById
      }],
      columnSorting: [{
        key: 'id',
        sortingFunc: this.sortingByIdObject
      }],
      orderDefault: [{
        index: 'id',
        dir: SortDirection.ASC
      }],
      customFieldsDefault: {},
      RiskCookies: RiskCookies,
      columnsMeta: [{
        targets: ['id'],
        width: '60px'
      }, {
        targets: ['title', 'organisationIds', 'attachments', 'parent', 'threatIds', 'threatComment', 'threatLevelValue', 'threatLevelComment', 'vulnerabilityIds', 'vulnerabilityComment'],
        width: '250px'
      }, {
        targets: ['activityType', 'responsible'],
        width: '180px'
      }, {
        targets: ['description'],
        width: '600px'
      }, {
        targets: ['linkedInitiatives', 'linkedControls', 'linkedSystems', 'linkedProcessLibrary', 'linkedComplianceNodes', 'linkedIncidents'],
        width: '400px'
      }, {
        targets: [RiskBaseModelProps.GRID_STATUS],
        width: '150px'
      }],
      RiskBaseModelProps: RiskBaseModelProps,
      reportType: ReportTypes.RISK,
      stateLocal: "".concat(RiskCookies.RISK_REPORT_DATATABLE, "-").concat(this.projectId),
      translate: getTranslate['RiskReportDatatable'](),
      updated: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    isSystemsEnabled: 'isSystemsEnabled'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'riskActivityTypes'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    consequenceName: function consequenceName() {
      return this.inherentRisksEnabled ? "".concat(this.translate.residualRisk, " - ").concat(this.consequenceLabel.toLowerCase()) : this.consequenceLabel;
    },
    probabilityName: function probabilityName() {
      return this.inherentRisksEnabled ? "".concat(this.translate.residualRisk, " - ").concat(this.probabilityLabel.toLowerCase()) : this.probabilityLabel;
    },
    disabledFilterByColumns: function disabledFilterByColumns() {
      var rowCommentsColumns = Object.keys(this.columns).filter(function (column) {
        return column.includes('-row-comment-');
      });
      return ['description', RiskBaseModelProps.THREAT_COMMENT, RiskBaseModelProps.THREAT_LEVEL_COMMENT, RiskBaseModelProps.VULNERABILITY_COMMENT, RiskBaseModelProps.PROBABILITY_COMMENT, RiskBaseModelProps.CONSEQUENCE_COMMENT].concat(_toConsumableArray(rowCommentsColumns));
    },
    additionalColumnsErm: function additionalColumnsErm() {
      var _this = this,
        _objectSpread2;
      var riskCalculationCols = {};
      var toleranceCols = {};
      var impactOther1Cols = {};
      var impactOther2Cols = {};
      var customImpactCols = {};
      if (this.riskCalculationsEnabled) {
        riskCalculationCols = _defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.RISK_GAP, this.translate.riskGap), RiskBaseModelProps.GROSS_LIKELIHOOD, this.translate.likelihoodGross), RiskBaseModelProps.GROSS_IMPACT, this.translate.impactGross);
        if (!this.hideTolerance) {
          toleranceCols = _defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.TOLERANCE_LIKELIHOOD, this.translate.likelihoodTolerance), RiskBaseModelProps.TOLERANCE_IMPACT, this.translate.impactTolerance), RiskBaseModelProps.CALCULATION_COMMENT, this.translate.calculationComment);
        }
        if (this.currentPeriod.impactOther1InUse) {
          impactOther1Cols = _defineProperty(_defineProperty({}, RiskBaseModelProps.IMPACT_OTHER1_SCORE, this.currentPeriod.impactOther1Label), RiskBaseModelProps.IMPACT_OTHER1_COMMENT, "".concat(this.currentPeriod.impactOther1Label, " ").concat(this.translate.comment));
        }
        if (this.currentPeriod.impactOther2InUse) {
          impactOther2Cols = _defineProperty(_defineProperty({}, RiskBaseModelProps.IMPACT_OTHER2_SCORE, this.currentPeriod.impactOther2Label), RiskBaseModelProps.IMPACT_OTHER2_COMMENT, "".concat(this.currentPeriod.impactOther2Label, " ").concat(this.translate.comment));
        }
        if (this.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT]) {
          this.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT].combinedFields.forEach(function (row) {
            customImpactCols[_this.prepareCILabel(row.id)] = row.label;
          });
        }
      }
      return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread((_objectSpread2 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_objectSpread2, RiskBaseModelProps.ACCOUNTABLE_USER_IDS, this.translate.primaryRiskOwner), RiskBaseModelProps.MANAGEMENT_LEVEL_ID, this.translate.managementLevel), RiskBaseModelProps.STATUS, this.translate.riskStatus), "likelihoodExposure", this.translate.likelihoodExposure), RiskBaseModelProps.LIKELIHOOD_SCORE, this.currentPeriod.likelihood_label || this.translate.likelihoodValue), RiskBaseModelProps.LIKELIHOOD_COMMENT, this.currentPeriod.likelihood_label ? "".concat(this.currentPeriod.likelihood_label, " ").concat(this.translate.comment) : this.translate.likelihoodComment), RiskBaseModelProps.IMPACT_SCORE, this.currentPeriod.impact_label || this.translate.impactValue), RiskBaseModelProps.IMPACT_COMMENT, this.currentPeriod.impact_label ? "".concat(this.currentPeriod.impact_label, " ").concat(this.translate.comment) : this.translate.impactComment), "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.IMPACT_SCORE), this.translate.previousImpact), "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.LIKELIHOOD_SCORE), this.translate.previousLikelihood), _defineProperty(_defineProperty(_objectSpread2, RiskBaseModelProps.RISK_TYPE_ID, this.getSettingValue('risma.risktype_name')), RiskBaseModelProps.RPI, this.riskDegreeTitle)), riskCalculationCols), toleranceCols), impactOther1Cols), impactOther2Cols), customImpactCols);
    },
    additionalColumnsNotErm: function additionalColumnsNotErm() {
      var _ref;
      return _ref = {
        activityType: this.translate.activityType,
        confidential: this.translate.confidential
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, RiskBaseModelProps.PARENT, this.translate.parentRisk), RiskBaseModelProps.THREAT_IDS, this.translate.threat), "threatCategory", this.translate.threatCategory), RiskBaseModelProps.THREAT_COMMENT, this.translate.threat + ' ' + this.translate.comment), RiskBaseModelProps.THREAT_LEVEL_VALUE, this.translate.levelOfThreat), RiskBaseModelProps.THREAT_LEVEL_COMMENT, this.translate.levelOfThreat + ' ' + this.translate.comment), RiskBaseModelProps.VULNERABILITY_IDS, this.translate.vulnerability), RiskBaseModelProps.VULNERABILITY_COMMENT, this.translate.vulnerability + ' ' + this.translate.comment), RiskBaseModelProps.CONSEQUENCE_COMMENT, this.consequenceLabel + ' ' + this.translate.comment), RiskBaseModelProps.ACCOUNTABLE_USER_IDS, this.accountableTitle()), _defineProperty(_defineProperty(_ref, RiskBaseModelProps.CONSULTED_USER_IDS, this.consultedTitle()), RiskBaseModelProps.INFORMED_USER_IDS, this.informedTitle());
    },
    riskCalculationsEnabled: function riskCalculationsEnabled() {
      return this.getSettingValue('risma.risk_calculations_enabled');
    },
    hideTolerance: function hideTolerance() {
      return this.getSettingValue('feature.risk_hide_tolerance');
    },
    consequenceLabel: function consequenceLabel() {
      return getConsequenceLabel(this.currentPeriod);
    },
    probabilityLabel: function probabilityLabel() {
      return getProbabilityLabel(this.currentPeriod);
    }
  }),
  beforeMount: function beforeMount() {
    this.initColumns();
  },
  mounted: function mounted() {
    this.dataset = this.prepareDataset(this.risks);
    this.enhanceDataset(this.dataset, this.columns);
    this.loadSavedReport();
  },
  methods: {
    initColumns: function initColumns() {
      var _this2 = this;
      var rpiCols = {};
      var riskApprovalCols = {};
      var consequenceCols = {};
      var inherentRiskCols = {};
      var additionalCols = this.isErm ? this.additionalColumnsErm : this.additionalColumnsNotErm;
      var customFieldCols = this.isErm ? this.getErmCustomFieldCols(this.currentPeriod) : this.getCustomFieldsByRisks(this.risks);
      if (!this.isErm) {
        var _this$currentPeriod, _this$currentPeriod2;
        this.consequenceList.forEach(function (cons) {
          rpiCols["rpi".concat(cons.id)] = "".concat(_this2.translate.rpi, " ").concat(_this2.consequenceLabel, ": ").concat(cons.label);
        });
        ((_this$currentPeriod = this.currentPeriod) === null || _this$currentPeriod === void 0 ? void 0 : _this$currentPeriod.consequenceRows) && this.currentPeriod.consequenceRows.forEach(function (item) {
          consequenceCols[_this2.prepareCRLabel(item.id)] = "".concat(_this2.consequenceName, ": ").concat(item.label);
          consequenceCols[_this2.prepareCRComment(item.id)] = "".concat(_this2.consequenceName, ": ").concat(item.label, " ").concat(_this2.translate.comment);
          if (!_this2.inherentRisksEnabled) return;
          var inherentConsequenceName = "".concat(_this2.translate.inherentRisk, " - ").concat(_this2.consequenceLabel, ": ").concat(item.label);
          inherentRiskCols[_this2.prepareInherentCRLabel(item.id)] = inherentConsequenceName;
          inherentRiskCols[_this2.prepareInherentCRComment(item.id)] = "".concat(inherentConsequenceName, ": ").concat(item.label, " ").concat(_this2.translate.comment);
        });
        ((_this$currentPeriod2 = this.currentPeriod) === null || _this$currentPeriod2 === void 0 ? void 0 : _this$currentPeriod2.probabilityRows) && this.currentPeriod.probabilityRows.forEach(function (item) {
          consequenceCols[_this2.preparePRLabel(item.id)] = "".concat(_this2.probabilityName, ": ").concat(item.label);
          consequenceCols[_this2.preparePRComment(item.id)] = "".concat(_this2.probabilityName, ": ").concat(item.label, " ").concat(_this2.translate.comment);
          if (!_this2.inherentRisksEnabled) return;
          var inherentProbabilityName = "".concat(_this2.translate.inherentRisk, " - ").concat(_this2.probabilityLabel, ": ").concat(item.label);
          inherentRiskCols[_this2.prepareInherentPRLabel(item.id)] = inherentProbabilityName;
          inherentRiskCols[_this2.prepareInherentPRComment(item.id)] = "".concat(inherentProbabilityName, ": ").concat(item.label, " ").concat(_this2.translate.comment);
        });
      }
      if (this.riskApprovalEnabled) {
        riskApprovalCols = _defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.APPROVAL_STATUS, this.translate.approvalStatus), RiskBaseModelProps.APPROVED_BY_USER_ID, this.translate.approvedBy), RiskBaseModelProps.APPROVAL_DATE, this.translate.approvalDate);
        this.decoupleFilterFields.push({
          key: RiskBaseModelProps.APPROVAL_STATUS
        });
      }
      var result = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_defineProperty(_defineProperty({
        id: '#',
        title: this.translate.title,
        open_closed: this.translate.status
      }, RiskBaseModelProps.RESPONSIBLE_USER_IDS, this.isErm ? this.translate.dailyRiskOwner : this.responsibleTitle()), RiskBaseModelProps.ORGANISATION_IDS, this.translate.organisation), rpiCols), {}, _defineProperty({
        description: this.translate.description,
        frameworks: this.translate.frameworks
      }, RiskBaseModelProps.GRID_STATUS, this.translate.gridStatus), additionalCols), {}, _defineProperty({
        linkedInitiatives: this.translate.linkedActions,
        linkedControls: this.translate.linkedControls,
        linkedSystems: this.translate.linkedSystems,
        linkedProcessLibrary: "".concat(this.translate.linked, " ").concat(this.processLibraryTitle),
        linkedComplianceNodes: this.translate.linkedComplianceNodes,
        linkedIncidents: this.translate.linkedIncidents
      }, RiskBaseModelProps.TAGS, this.translate.tags), customFieldCols), riskApprovalCols), consequenceCols), inherentRiskCols);
      if (!this.isSystemsEnabled) {
        delete result.linkedSystems;
      }
      this.columns = result;
    },
    onEntityUpdated: function onEntityUpdated() {
      this.updated = true;
      this.$emit('updated');
    },
    onDismissModal: function onDismissModal() {
      this.$emit('dismissModal', this.updated);
      this.updated = false;
    },
    sortingById: function sortingById(data) {
      return sortByIndex(data);
    },
    sortingByIdObject: function sortingByIdObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        return x - y;
      });
    },
    getAllCustomFields: function getAllCustomFields(risks) {
      var customFields = [];
      risks.map(function (risk) {
        if (!risk.customFieldValues.length) return;
        customFields.push.apply(customFields, _toConsumableArray(risk.customFields));
      });
      return customFields;
    },
    getErmCustomFieldCols: function getErmCustomFieldCols(currentPeriod) {
      var result = {};
      currentPeriod.fields.forEach(function (field) {
        if (field.module !== MODULES.SCORING_ZAXIS && field.fieldWeight === -1 && field.id !== currentPeriod.customImpactAxis) {
          result['p-' + field.label] = field.label;
        }
      });
      return result;
    },
    getCustomFieldsByRisks: function getCustomFieldsByRisks(risks) {
      var _this3 = this;
      var result = {};
      var extendedColumns = this.getAllCustomFields(risks);
      var extendedColumnsUniqueById = _toConsumableArray(new Map(extendedColumns.map(function (item) {
        return [item.id, item];
      })).values());
      extendedColumnsUniqueById.forEach(function (customField) {
        var customFieldLabel = _this3.getColumnLabelName(customField.id);
        result[customFieldLabel] = customField.label;
        _this3.customFieldsDefault[customFieldLabel] = {
          field: '',
          fieldType: customField.fieldtype
        };
        if (customField.typeId === CustomFieldTypes.NUMERICAL) {
          var unitLabel = _this3.getColumnLabelName(customField.id + _this3.customUnitPrefix);
          result[unitLabel] = customField.label + _this3.customUnitPrefix;
          _this3.customFieldsDefault[unitLabel] = {
            field: '',
            fieldType: customField.fieldtype
          };
        }
        _this3.extendFilterOptions(customField.typeId, customFieldLabel);
      });
      return result;
    },
    setCustomFieldsColumns: function setCustomFieldsColumns(customFields) {
      var _this4 = this;
      customFields.forEach(function (customField) {
        var customFieldLabel = _this4.getColumnLabelName(customField.id);
        _this4.columns[customFieldLabel] = customField.label;
        _this4.customFieldsDefault[customFieldLabel] = {
          field: '',
          fieldType: customField.fieldtype
        };
        if (customField.typeId === CustomFieldTypes.NUMERICAL) {
          var unitLabel = _this4.getColumnLabelName(customField.id + _this4.customUnitPrefix);
          _this4.columns[unitLabel] = customField.label + _this4.customUnitPrefix;
          _this4.customFieldsDefault[unitLabel] = {
            field: '',
            fieldType: customField.fieldtype
          };
        }
        _this4.extendFilterOptions(customField.typeId, customFieldLabel);
      });
    },
    getColumnLabelName: function getColumnLabelName(id) {
      return "cf-".concat(id).toLowerCase();
    },
    extendFilterOptions: function extendFilterOptions(customFieldTypeId, label) {
      var fieldTypeIdsAvoidCustomFilter = [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD];
      if (fieldTypeIdsAvoidCustomFilter.includes(customFieldTypeId)) {
        this.disabledFilterByColumns.push(label);
      } else {
        this.decoupleFilterFields.push({
          key: label
        });
      }
    },
    prepareLabel: function prepareLabel(string, id) {
      return "".concat(string, "-").concat(id);
    },
    prepareCILabel: function prepareCILabel(id) {
      return this.prepareLabel('_custom-impact', id);
    },
    prepareCRLabel: function prepareCRLabel(id) {
      return this.prepareLabel('_consequence-row', id);
    },
    prepareCRComment: function prepareCRComment(id) {
      return this.prepareLabel('_consequence-row-comment', id);
    },
    preparePRLabel: function preparePRLabel(id) {
      return this.prepareLabel('_probability-row', id);
    },
    preparePRComment: function preparePRComment(id) {
      return this.prepareLabel('_probability-row-comment', id);
    },
    prepareInherentCRLabel: function prepareInherentCRLabel(id) {
      return this.prepareLabel('_inherent-consequence-row', id);
    },
    prepareInherentCRComment: function prepareInherentCRComment(id) {
      return this.prepareLabel('_inherent-consequence-row-comment', id);
    },
    prepareInherentPRLabel: function prepareInherentPRLabel(id) {
      return this.prepareLabel('_inherent-probability-row', id);
    },
    prepareInherentPRComment: function prepareInherentPRComment(id) {
      return this.prepareLabel('_inherent-probability-row-comment', id);
    },
    prepareDataset: function prepareDataset(data) {
      var _this5 = this;
      return data.map(function (item) {
        var rpiFields = {};
        var responsibleUserId = item[RiskBaseModelProps.USER_IDS] && item[RiskBaseModelProps.USER_IDS][RiskBaseModelProps.RESPONSIBLE];
        var accountableUserId = item[RiskBaseModelProps.USER_IDS] && item[RiskBaseModelProps.USER_IDS][RiskBaseModelProps.ACCOUNTABLE];
        !_this5.isErm && _this5.currentPeriod.consequenceRows.forEach(function (consequence) {
          rpiFields["rpi".concat(consequence.id)] = {
            field: _this5.calculateRpi(item, consequence.id)
          };
        });
        var customFieldsValue = {};
        if (_this5.isErm) {
          _this5.currentPeriod.fields.forEach(function (field) {
            if (field.module !== MODULES.SCORING_ZAXIS && field.fieldWeight === -1 && field.id !== _this5.currentPeriod.customImpactAxis) {
              _this5.prepareCustomFieldValue(customFieldsValue, item.customFieldValues, field, 'p-' + field.label);
            }
          });
        } else {
          item.customFields && item.customFields.forEach(function (customField) {
            var customFieldLabel = _this5.getColumnLabelName(customField.id);
            _this5.prepareCustomFieldValue(customFieldsValue, item.customFieldValues, customField, customFieldLabel);
          });
        }
        var customFields = _objectSpread(_objectSpread({}, _this5.customFieldsDefault), customFieldsValue);
        var obj = _objectSpread(_objectSpread(_defineProperty(_defineProperty({
          id: {
            field: item.rno || ''
          },
          title: {
            activity: getFieldsFromObjectForPreview(item),
            field: item.title,
            hoverTitle: DatatableRenderer.getItemHoverTitle(item),
            confidential: item.confidential
          },
          open_closed: {
            field: _this5.$trans(!item.closed && _this5.isCurrentPeriodSelected ? 'Open' : 'Closed')
          }
        }, RiskBaseModelProps.RESPONSIBLE_USER_IDS, {
          field: _this5.isErm ? Utils.findValueById(_this5.users, +responsibleUserId, 'display_name') : Utils.findPropsByIds(item[RiskBaseModelProps.RESPONSIBLE_USER_IDS], _this5.users, 'display_name')
        }), RiskBaseModelProps.ORGANISATION_IDS, {
          field: Utils.findPropsByIds(item[RiskBaseModelProps.ORGANISATION_IDS], _this5.organisations, 'visible_name')
        }), rpiFields), {}, _defineProperty({
          description: {
            field: item.description || ''
          },
          frameworks: {
            field: Utils.findPropsByIds(item.solutionIds, _this5.frameworks, 'displayName')
          }
        }, RiskBaseModelProps.GRID_STATUS, {
          field: _this5.$trans(RiskStatusLabel[item.gridStatus]) || ''
        }));
        if (!_this5.isErm) {
          var _this5$activityTypes$;
          obj.activityType = {
            field: ((_this5$activityTypes$ = _this5.activityTypes.find(function (at) {
              return at.id === item.activityTypeId;
            })) === null || _this5$activityTypes$ === void 0 ? void 0 : _this5$activityTypes$.label) || ''
          };
          obj.confidential = {
            field: item.confidential ? _this5.translate.yes : _this5.translate.no
          };
        }
        if (_this5.isErm) {
          var _objectSpread8;
          obj = _objectSpread(_objectSpread({}, obj), {}, (_objectSpread8 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_objectSpread8, RiskBaseModelProps.ACCOUNTABLE_USER_IDS, {
            field: Utils.findValueById(_this5.users, +accountableUserId, 'display_name')
          }), RiskBaseModelProps.MANAGEMENT_LEVEL_ID, {
            field: Utils.findValueById(_this5.managementLevels, item[RiskBaseModelProps.MANAGEMENT_LEVEL_ID], 'name')
          }), RiskBaseModelProps.STATUS, {
            field: Utils.removeHtmlTags(item[RiskBaseModelProps.STATUS] || '')
          }), "likelihoodExposure", {
            field: _this5.findLikelihood(_this5.currentPeriod, item[RiskBaseModelProps.LIKELIHOOD_SCORE])
          }), RiskBaseModelProps.LIKELIHOOD_SCORE, {
            field: item[RiskBaseModelProps.LIKELIHOOD_SCORE] || ''
          }), RiskBaseModelProps.LIKELIHOOD_COMMENT, {
            field: Utils.removeHtmlTags(item[RiskBaseModelProps.LIKELIHOOD_COMMENT] || '')
          }), RiskBaseModelProps.IMPACT_SCORE, {
            field: item[RiskBaseModelProps.IMPACT_SCORE] || ''
          }), RiskBaseModelProps.IMPACT_COMMENT, {
            field: Utils.removeHtmlTags(item[RiskBaseModelProps.IMPACT_COMMENT] || '')
          }), "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.IMPACT_SCORE), {
            field: item[RiskBaseModelProps.PREVIOUS_RISK] ? _this5.findImpact(_this5.currentPeriod, item[RiskBaseModelProps.PREVIOUS_RISK][RiskBaseModelProps.IMPACT_SCORE]) : ''
          }), "".concat(RiskBaseModelProps.PREVIOUS_RISK, "_").concat(RiskBaseModelProps.LIKELIHOOD_SCORE), {
            field: item[RiskBaseModelProps.PREVIOUS_RISK] ? _this5.findLikelihood(_this5.currentPeriod, item[RiskBaseModelProps.PREVIOUS_RISK][RiskBaseModelProps.LIKELIHOOD_SCORE]) : ''
          }), _defineProperty(_defineProperty(_objectSpread8, RiskBaseModelProps.RISK_TYPE_ID, {
            field: Utils.findValueById(_this5.riskTypes, item[RiskBaseModelProps.RISK_TYPE_ID], 'title')
          }), RiskBaseModelProps.RPI, {
            field: item[RiskBaseModelProps.RPI] || ''
          })));
          if (_this5.riskCalculationsEnabled) {
            obj = _objectSpread(_objectSpread({}, obj), {}, _defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.RISK_GAP, {
              field: item.riskCalculations[RiskBaseModelProps.RISK_GAP] || ''
            }), RiskBaseModelProps.GROSS_LIKELIHOOD, {
              field: _this5.findLikelihood(_this5.currentPeriod, item.riskCalculations[RiskBaseModelProps.GROSS_LIKELIHOOD])
            }), RiskBaseModelProps.GROSS_IMPACT, {
              field: _this5.findImpact(_this5.currentPeriod, item.riskCalculations[RiskBaseModelProps.GROSS_IMPACT])
            }));
            if (!_this5.hideTolerance) {
              obj = _objectSpread(_objectSpread({}, obj), {}, _defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.TOLERANCE_LIKELIHOOD, {
                field: _this5.findLikelihood(_this5.currentPeriod, item.riskCalculations[RiskBaseModelProps.TOLERANCE_LIKELIHOOD])
              }), RiskBaseModelProps.TOLERANCE_IMPACT, {
                field: _this5.findImpact(_this5.currentPeriod, item.riskCalculations[RiskBaseModelProps.TOLERANCE_IMPACT])
              }), RiskBaseModelProps.CALCULATION_COMMENT, {
                field: item.riskCalculations[RiskBaseModelProps.CALCULATION_COMMENT] || ''
              }));
            }
          }
          if (_this5.currentPeriod.impactOther1InUse) {
            var field = item[RiskBaseModelProps.IMPACT_OTHER1_SCORE] || '';
            if (_this5.currentPeriod.impactOther1Labels[item[RiskBaseModelProps.IMPACT_OTHER1_SCORE]]) {
              field += " (".concat(_this5.currentPeriod.impactOther1Labels[item[RiskBaseModelProps.IMPACT_OTHER1_SCORE]], ")");
            }
            obj[RiskBaseModelProps.IMPACT_OTHER1_SCORE] = {
              field: field
            };
            obj[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] = {
              field: Utils.removeHtmlTags(item[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] || '')
            };
          }
          if (_this5.currentPeriod.impactOther2InUse) {
            var _field = item[RiskBaseModelProps.IMPACT_OTHER2_SCORE] || '';
            if (_this5.currentPeriod.impactOther2Labels[item[RiskBaseModelProps.IMPACT_OTHER2_SCORE]]) {
              _field += " (".concat(_this5.currentPeriod.impactOther2Labels[item[RiskBaseModelProps.IMPACT_OTHER2_SCORE]], ")");
            }
            obj[RiskBaseModelProps.IMPACT_OTHER2_SCORE] = {
              field: _field
            };
            obj[RiskBaseModelProps.IMPACT_OTHER2_COMMENT] = {
              field: Utils.removeHtmlTags(item[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] || '')
            };
          }
          if (_this5.isErm && _this5.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT]) {
            _this5.currentPeriod[RiskBaseModelProps.CUSTOM_IMPACT].combinedFields.forEach(function (row) {
              var result = item.customFieldValues.find(function (field) {
                return field.fieldId === row.id;
              });
              obj[_this5.prepareCILabel(row.id)] = {
                field: result ? result.fieldValue : ''
              };
            });
          }
        } else {
          var _Utils$filterArrayByL, _objectSpread11;
          var threats = Utils.filterArrayByListOfPropValues(_this5.threats, item[RiskBaseModelProps.THREAT_IDS]);
          obj = _objectSpread(_objectSpread({}, obj), {}, (_objectSpread11 = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_objectSpread11, RiskBaseModelProps.PARENT, {
            field: item.parent ? (_Utils$filterArrayByL = Utils.filterArrayByListOfPropValues(_this5.parentRisks, [+item.parent], 'rno')[0]) === null || _Utils$filterArrayByL === void 0 ? void 0 : _Utils$filterArrayByL.title : ''
          }), RiskBaseModelProps.THREAT_IDS, {
            field: threats.map(function (item) {
              return item.title;
            }).join(', ')
          }), "threatCategory", {
            field: _this5.getCategoriesThreats(threats)
          }), RiskBaseModelProps.THREAT_COMMENT, {
            field: item[RiskBaseModelProps.THREAT_COMMENT] || ''
          }), RiskBaseModelProps.THREAT_LEVEL_VALUE, {
            field: Utils.findValueById(_this5.levelOfThreats, item[RiskBaseModelProps.THREAT_LEVEL_VALUE], 'label')
          }), RiskBaseModelProps.THREAT_LEVEL_COMMENT, {
            field: item[RiskBaseModelProps.THREAT_LEVEL_COMMENT] || ''
          }), RiskBaseModelProps.VULNERABILITY_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.VULNERABILITY_IDS], _this5.vulnerabilities, 'label')
          }), RiskBaseModelProps.VULNERABILITY_COMMENT, {
            field: item[RiskBaseModelProps.VULNERABILITY_COMMENT] || ''
          }), RiskBaseModelProps.CONSEQUENCE_COMMENT, {
            field: item[RiskBaseModelProps.CONSEQUENCE_COMMENT] || ''
          }), RiskBaseModelProps.ACCOUNTABLE_USER_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.ACCOUNTABLE_USER_IDS], _this5.users, 'display_name')
          }), _defineProperty(_defineProperty(_objectSpread11, RiskBaseModelProps.CONSULTED_USER_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.CONSULTED_USER_IDS], _this5.users, 'display_name')
          }), RiskBaseModelProps.INFORMED_USER_IDS, {
            field: Utils.findPropsByIds(item[RiskBaseModelProps.INFORMED_USER_IDS], _this5.users, 'display_name')
          })));
        }
        obj = _objectSpread(_objectSpread(_objectSpread({}, obj), {}, {
          linkedInitiatives: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.execution, _this5.initiatives, 'path')), {}, {
            id: obj.id
          }),
          linkedControls: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.control, _this5.controls, 'path')), {}, {
            id: obj.id
          })
        }, _this5.getIACells(item.links, obj.id)), {}, _defineProperty({
          linkedProcessLibrary: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.compliance_process_tree_node, _this5.processLibraryNodes)), {}, {
            id: obj.id
          }),
          linkedComplianceNodes: _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.compliance, _this5.complianceNodes)), {}, {
            id: obj.id
          }),
          linkedIncidents: _objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(item.links.incidents, _this5.incidents))
        }, RiskBaseModelProps.TAGS, {
          field: item[RiskBaseModelProps.TAGS].map(function (tagItem) {
            return tagItem.tag;
          }).join(', ')
        }), customFields);
        if (_this5.riskApprovalEnabled) {
          obj[RiskBaseModelProps.APPROVAL_STATUS] = {
            field: item[RiskBaseModelProps.APPROVAL_STATUS] ? _this5.translate.approved : _this5.translate.notApproved
          };
          obj[RiskBaseModelProps.APPROVED_BY_USER_ID] = {
            field: Utils.findValueById(_this5.users, item[RiskBaseModelProps.APPROVED_BY_USER_ID], 'display_name')
          };
          obj[RiskBaseModelProps.APPROVAL_DATE] = {
            field: toLocalDateTime(item[RiskBaseModelProps.APPROVAL_DATE])
          };
        }

        // Values given to the row-headline
        if (!_this5.isErm) {
          _this5.currentPeriod.consequenceRows.map(function (row) {
            var rowData = _this5.getRowData(item, row, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES);
            obj[_this5.prepareCRLabel(row.id)] = rowData.label;
            obj[_this5.prepareCRComment(row.id)] = rowData.comment;
          });
          _this5.currentPeriod.probabilityRows.map(function (row) {
            var rowData = _this5.getRowData(item, row, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES);
            obj[_this5.preparePRLabel(row.id)] = rowData.label;
            obj[_this5.preparePRComment(row.id)] = rowData.comment;
          });
        }
        if (_this5.inherentRisksEnabled) {
          _this5.currentPeriod.consequenceRows.map(function (row) {
            var rowData = _this5.getRowData(item, row, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES);
            obj[_this5.prepareInherentCRLabel(row.id)] = rowData.label;
            obj[_this5.prepareInherentCRComment(row.id)] = rowData.comment;
          });
          _this5.currentPeriod.probabilityRows.map(function (row) {
            var rowData = _this5.getRowData(item, row, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES);
            obj[_this5.prepareInherentPRLabel(row.id)] = rowData.label;
            obj[_this5.prepareInherentPRComment(row.id)] = rowData.comment;
          });
        }
        return obj;
      });
    },
    getIACells: function getIACells(links, objId) {
      var row = {};
      if (this.isSystemsEnabled) {
        row.linkedSystems = _objectSpread(_objectSpread({}, DatatableRenderer.getLinkedItemsAsPreview(links.compliance_ia_system, this.systems)), {}, {
          id: objId
        });
      }
      return row;
    },
    getRowData: function getRowData(item, row, rowScoresType) {
      var _entity$column$label, _entity$column, _entity$comment, _entity$column2;
      var columnsType = columnsTypeByScoresName[rowScoresType];
      var entity = item[rowScoresType].find(function (r) {
        return r.rowId === row.id;
      });
      var label = (_entity$column$label = entity === null || entity === void 0 || (_entity$column = entity.column) === null || _entity$column === void 0 ? void 0 : _entity$column.label) !== null && _entity$column$label !== void 0 ? _entity$column$label : '';
      var comment = (_entity$comment = entity === null || entity === void 0 ? void 0 : entity.comment) !== null && _entity$comment !== void 0 ? _entity$comment : '';
      if (entity !== null && entity !== void 0 && (_entity$column2 = entity.column) !== null && _entity$column2 !== void 0 && _entity$column2.inherited) {
        var _row$columnsType$find;
        label = label || ((_row$columnsType$find = row[columnsType].find(function (col) {
          return col.value === entity.column.value;
        })) === null || _row$columnsType$find === void 0 ? void 0 : _row$columnsType$find.label) || '';
      }
      return {
        label: {
          field: label
        },
        comment: {
          field: comment
        }
      };
    },
    calculateRpi: function calculateRpi(item, _calculateRpi) {
      // Probability * current Consequence value
      var scores = item._scores && item._scores[_calculateRpi] ? item._scores[_calculateRpi].scores : null;
      return scores ? scores.x * scores.y : '';
      // return item._scores && item._scores[calculateRpi] ? item._scores[calculateRpi].scores.x * item._scores[calculateRpi].scores.y : '';
    },
    findLikelihood: function findLikelihood(currentPeriod, value) {
      if (!value) return '';
      var likelihoodLabel = currentPeriod.likelihood_labels.find(function (labelItem) {
        return labelItem.likelihood_number == value;
      }) || {};
      var likelihoodValue = currentPeriod.likelihood_values ? currentPeriod.likelihood_values[value] : '';
      return "".concat(likelihoodValue, " (").concat(likelihoodLabel.description, ")");
    },
    findImpact: function findImpact(currentPeriod, value) {
      if (!value) return '';
      return currentPeriod.impact_values[value];
    },
    getCategoriesThreats: function getCategoriesThreats(threats) {
      return threats.reduce(function (acc, threat) {
        var _threat$category;
        var categoryTitle = threat === null || threat === void 0 || (_threat$category = threat.category) === null || _threat$category === void 0 ? void 0 : _threat$category.title;
        if (categoryTitle) {
          acc += (acc ? ', ' : '') + categoryTitle;
        }
        return acc;
      }, '');
    },
    getItemHoverTitle: function getItemHoverTitle(item) {
      return DatatableRenderer.getItemHoverTitle(item);
    }
  }
};