function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import LinksBox from '@/components/Molecules/LinksBox';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'ComplianceReportsBox',
  introduction: 'ComplianceReportsBox - displays a list of reports in Overview page',
  description: "This component displays a list of reports based on the type of the compliance project. \n        It is utilized as a separate box on the Compliance Overview Page",
  components: {
    LinksBox: LinksBox
  },
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      translate: {
        reports: this.$trans('Reports'),
        viewTheCurrentStatusOfYourSoa: this.$trans('View the current status of your SoA'),
        threats: this.$trans('Threats'),
        aHeatmapOfAllAssessedThreats: this.$trans('A heatmap of all assessed threats'),
        completeStatusListOfMitigatedControls: this.$trans('Complete status list of mitigated controls'),
        nodes: this.$trans('Nodes'),
        nodeReport: this.$trans('Node report'),
        gapReport: this.$trans('Gap report'),
        gapDashboard: 'Gap dashboard'
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    reports: function reports() {
      if (this.currentProject.type === ProjectTypes.ISO27001) {
        return this.getIsoReports();
      } else if (this.currentProject.type === ProjectTypes.GDPR) {
        return this.getGdprReports();
      } else if (this.currentProject.type === ProjectTypes.CSRD) {
        return this.getCsrdReports();
      }
      return [];
    }
  }),
  methods: {
    getIsoReports: function getIsoReports() {
      var reports = [];
      reports.push({
        title: 'SoA',
        subtitle: this.translate.viewTheCurrentStatusOfYourSoa,
        url: "/compliance/reports/".concat(this.projectId, "/gap")
      });
      var isThreatValueShown = this.getSettingValue('feature.risk_assessment_on_systems') === 1 && this.getSettingValue('feature.systems_r_projects_id') && !checkERMByProjectId(this.getSettingValue('feature.systems_r_projects_id'), this.currentUser);
      if (isThreatValueShown) {
        reports.push({
          title: this.translate.threats,
          subtitle: this.translate.aHeatmapOfAllAssessedThreats,
          url: "/risk2/projects/".concat(this.getSettingValue('feature.systems_r_projects_id'), "/reports/threats")
        });
      }
      reports.push({
        title: 'Controls',
        subtitle: this.translate.completeStatusListOfMitigatedControls,
        url: '/controls2/reports/main'
      });
      return reports;
    },
    getGdprReports: function getGdprReports() {
      var _this = this;
      var reports = [];
      reports.push({
        title: this.translate.nodes,
        url: "/compliance/reports/".concat(this.projectId, "/nodes")
      });
      if (this.currentProject.reports) {
        Object.keys(this.currentProject.reports).forEach(function (item) {
          reports.push({
            title: _this.$trans(_this.currentProject.reports[item].header),
            url: "/compliance/reports/".concat(_this.projectId, "/").concat(item)
          });
        });
      }
      return reports;
    },
    getCsrdReports: function getCsrdReports() {
      return [{
        title: this.translate.nodeReport,
        url: "/compliance/reports/".concat(this.projectId, "/nodes")
      }, {
        title: this.translate.gapDashboard,
        url: "/compliance/dashboard/".concat(this.projectId, "/gap")
      }, {
        title: this.translate.gapReport,
        url: "/compliance/reports/".concat(this.projectId, "/gap")
      }];
    }
  }
};