import { Http } from '../index';
import { flushCache, getPromise, getPromiseWithCacheTimeout } from '@/api/HttpCache';
import { handleRequestResponse, objectToQueryString, generateIdsQueryString, getByUrl } from '../httpUtils';
var usersUrl = 'data/users';
var inactiveUsersParameter = '?filters[]=inactive';
export function getOrganisations() {
  return getPromiseWithCacheTimeout('data/organisations', 180);
}
var getUsersUrl = function getUsersUrl(includeInactive) {
  return includeInactive ? "".concat(usersUrl).concat(inactiveUsersParameter) : usersUrl;
};
export function getUsers() {
  var includeInactive = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var url = getUsersUrl(includeInactive);
  return getPromiseWithCacheTimeout(url + params, 0);
}
export function flushGetUsersCache() {
  var includeInactive = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var url = getUsersUrl(includeInactive);
  flushCache(url);
}
export function getUsersWithoutTimeout() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var params = Object.keys(data).length ? '?' + objectToQueryString(data) : '';
  return handleRequestResponse(Http.get(usersUrl + params));
}
export function getUserById(user_id) {
  return getPromiseWithCacheTimeout('data/users/' + user_id, 180);
}
export function getInitiatives() {
  var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 180;
  return getPromiseWithCacheTimeout('data/initiatives', cacheTime);
}
export function flushInitiativesCache() {
  return flushCache('data/initiatives');
}
export function getAccounts() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return getPromiseWithCacheTimeout("data/accounts".concat(params), 180);
}
export function getProcesses() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return getPromiseWithCacheTimeout("data/processes".concat(params), 180);
}
export function getControlRisks() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return getPromiseWithCacheTimeout("controls/risks".concat(params), 180);
}
export function flushControlAssociationsCache() {
  flushCache('controls/risks?data[]=usage');
  flushCache('data/processes?data[]=usage');
  flushCache('data/accounts?data[]=usage');
}
export function getControls() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return getPromiseWithCacheTimeout('data/controls' + params, 180);
}
export function flushControlsCache() {
  return flushCache('data/controls');
}
export function getRisks() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var cacheTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 180;
  return getPromiseWithCacheTimeout('data/risks' + params, cacheTime);
}
export function getRiskProjects() {
  return getPromiseWithCacheTimeout('data/risks/project', 180);
}
export function flushRisksCache() {
  return flushCache('data/risks');
}
export function getInformationAssets() {
  return getPromise('data/compliance/informationAssets');
}
export function getInformationAssetsSystems() {
  var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 180;
  return getPromiseWithCacheTimeout('data/compliance/informationAssets/systems', cacheTime);
}
export function flushSystemsCache() {
  return flushCache('data/compliance/informationAssets/systems');
}
export function getInformationAssetsProcessors() {
  var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  return getPromiseWithCacheTimeout('data/compliance/informationAssets/processors', cacheTime);
}
export function getInformationAssetsControllers() {
  var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  return getPromiseWithCacheTimeout('data/compliance/informationAssets/controllers', cacheTime);
}
export function getCustomInformationAssets() {
  var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
  return getPromiseWithCacheTimeout('data/compliance/informationAssets/custom', cacheTime);
}
export function getAllTrees() {
  return getPromise('data/compliance/processtree');
}
export function getNodesByProcessTreeId(processTreeId) {
  var cacheTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  return getPromiseWithCacheTimeout('data/compliance/processtree/' + processTreeId + '/nodes', cacheTime);
}
export function getNodes() {
  var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 180;
  return getPromiseWithCacheTimeout('data/compliance/nodes', cacheTime);
}
export function getNodesFlat() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var includeRootInFlat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var queryString = objectToQueryString({
    data: data
  });
  return getPromise("data/compliance/nodes?format=flat&includeRootInFlat=".concat(includeRootInFlat, "&").concat(queryString));
}
export function getComplianceProjects() {
  return getPromise('data/compliance/projects');
}
export function getSingleComplianceProject(id) {
  return getComplianceProjects().then(function (response) {
    return response.list.find(function (project) {
      return parseInt(project.id) === parseInt(id);
    });
  });
}

/**
 * Get orgs with or without user rights filter
 */
export function getOrganisationsAndFilterByUserAccess(filterByUserAccess) {
  var filter = '';
  // If admin or superuser, dont use filter on organisations
  if (filterByUserAccess) {
    filter = '?filter[]=onlyOrganisationsWithUserAccess';
  }
  return getPromiseWithCacheTimeout('data/organisations' + filter, 180);
}
export function setUserProjectLevel(params) {
  return handleRequestResponse(Http.patch('data/usersprojects', params));
}
export function getCurrentUser() {
  var flush = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  if (flush) {
    flushCache('data/users/me');
  }
  return getUserById('me');
}
export function getLoginInfo() {
  return getByUrl('login');
}
export function getOrganisationalGroups() {
  return handleRequestResponse(Http.get('/data/organisationgroups'));
}
export function getAllWorkflows() {
  return handleRequestResponse(Http.get("/data/workflow"));
}
export function getWorkflowStatusesByModule(module) {
  return handleRequestResponse(Http.get("/data/workflowstatus/".concat(module)));
}
export function getFrameworks() {
  return handleRequestResponse(Http.get('/data/solutions'));
}
export function getTiaLiaSettings() {
  return handleRequestResponse(Http.get('/data/compliance/tialia'));
}
export function getContracts() {
  var includeArchived = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var ids = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var urlBase = '/data/contracts';
  var query = '';
  if (includeArchived) {
    query += '?includeArchived=true';
  }
  if (ids && ids.length) {
    query += query ? '&' : '?';
    query += generateIdsQueryString(ids);
  }
  return handleRequestResponse(Http.get(urlBase + query));
}
export function getIncidents() {
  return handleRequestResponse(Http.get('/data/incidents'));
}
export function getAllScorings() {
  return handleRequestResponse(Http.get('/data/risks/scoring'));
}
export function getCustomisationTrafficLights() {
  return handleRequestResponse(Http.get('/data/trafficlights'));
}
export function getRiskAssessmentGuide(projectId) {
  return handleRequestResponse(Http.get("/data/welcomepages/project/".concat(projectId)));
}
export function getCompanyCurrencies() {
  return handleRequestResponse(Http.get('data/currencies'));
}
export function getCountries() {
  return handleRequestResponse(Http.get('/data/countries'));
}