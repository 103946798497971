function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex items-center mb-4"
};
var _hoisted_3 = {
  class: "grid grid-cols-3 gap-4"
};
var _hoisted_4 = {
  class: "glob-h3"
};
var _hoisted_5 = {
  class: "glob-l1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_contract_party_internal_section = _resolveComponent("contract-party-internal-section");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return $props.company ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent($options.isPreview ? 'router-link' : 'preview-modal-link'), _mergeProps($options.isPreview ? {
    to: $props.company.url
  } : {
    activity: $props.company
  }, {
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return $data.updatedCompany = $event;
    }),
    onDismissModal: $options.dismissPreview
  }), {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [$options.trafficLightColor ? (_openBlock(), _createBlock(_component_traffic_light, {
        key: 0,
        class: "mr-2 flex-shrink-0",
        color: $options.trafficLightColor
      }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        type: "big",
        truncate: false,
        title: $props.company.name
      }, null, 8 /* PROPS */, ["title"])])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["onDismissModal"])), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.mainInfo, function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      label = _ref2[0],
      value = _ref2[1];
    return _openBlock(), _createElementBlock("div", {
      key: label
    }, [_createElementVNode("h3", _hoisted_4, _toDisplayString(label), 1 /* TEXT */), _createElementVNode("span", _hoisted_5, _toDisplayString(value), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))]), $props.internalSectionsEnabled ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.sections, function (section, index) {
    return _openBlock(), _createBlock(_component_contract_party_internal_section, {
      key: section.id || section.title,
      section: section,
      options: $options.sectionOptions,
      onUpdate: function onUpdate($event) {
        return $options.handleSectionUpdate($event, index);
      },
      onDelete: function onDelete($event) {
        return $options.handleDeleteSection($event, index);
      },
      onCopy: function onCopy($event) {
        return $options.handleCopySection(index);
      }
    }, null, 8 /* PROPS */, ["section", "options", "onUpdate", "onDelete", "onCopy"]);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_risma_button, {
    disabled: !$options.sectionsValid,
    text: $data.translate.addSection + ' (B_02.02)',
    title: $options.sectionsValid ? '' : $data.translate.pleaseFillOutAllFields,
    class: "mt-4",
    type: "util",
    onClick: $options.addSection
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        class: "mr-2 stroke-rm-text focus:outline-none stroke-2",
        icon: "plus-circle"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled", "text", "title", "onClick"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}