import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "position": "relative",
    "height": "10rem"
  }
};
var _hoisted_2 = {
  class: "menuItem"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_pop_up_menu = _resolveComponent("pop-up-menu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_pop_up_menu, null, {
    menu: _withCtx(function () {
      return [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "menuItem"
      }, "item1", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_feather_icon, {
        class: "inline-block mr-2",
        icon: "credit-card",
        color: "black"
      })])];
    }),
    _: 1 /* STABLE */
  })]);
}