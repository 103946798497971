function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import * as Utils from '@/utils/Utils';
export default {
  name: 'RiskProbabilityAssessment',
  introduction: 'Risk Probability Assesment on DEP',
  description: 'Risk probability wrapper on DEP',
  token: "<risk-probability-assessment\n        :activity=\"activity\"\n        :threats=\"threats\"\n        :level-of-threats=\"levelOfThreats\"\n        :vulnerabilities=\"vulnerabilities\"\n        :probabilities=\"probabilities\"\n     />",
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    TinyMceBox: TinyMceBox,
    RismaSelect: RismaSelect
  },
  props: {
    activity: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current activity data'
    },
    threats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk threats'
    },
    levelOfThreats: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Level of threats'
    },
    vulnerabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    probabilities: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Risk vulnerabilities'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is the functionality disabled'
    },
    threatLevelTitle: {
      type: String,
      required: false,
      default: 'Level of threat'
    },
    threatShown: {
      type: Boolean,
      default: true,
      note: 'if the threat shown'
    },
    threatLevelShown: {
      type: Boolean,
      default: true,
      note: 'If the level of threat shown'
    },
    vulnerabilityShown: {
      type: Boolean,
      default: true,
      note: 'Is the vulnerability show'
    }
  },
  emits: ['changed'],
  data: function data() {
    return {
      threatsSelected: [],
      levelOfThreatSelected: null,
      vulnerabilitiesSelected: [],
      probabilitySelected: null,
      RiskBaseModelProps: RiskBaseModelProps,
      comments: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.THREAT_COMMENT, this.activity[RiskBaseModelProps.THREAT_COMMENT]), RiskBaseModelProps.THREAT_LEVEL_COMMENT, this.activity[RiskBaseModelProps.THREAT_LEVEL_COMMENT]), RiskBaseModelProps.VULNERABILITY_COMMENT, this.activity[RiskBaseModelProps.VULNERABILITY_COMMENT]), RiskBaseModelProps.PROBABILITY_COMMENT, this.activity[RiskBaseModelProps.PROBABILITY_COMMENT]),
      unSorted: true,
      timeout: null,
      maxlength: 999,
      translate: getTranslate['RiskProbabilityAssessment']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return this.getSettingValue('feature.risk_comments_field_on_axis');
    },
    isThreatListDisabled: function isThreatListDisabled() {
      return this.disabled || !this.threats.length;
    },
    isLevelOfThreatsListDisabled: function isLevelOfThreatsListDisabled() {
      return this.disabled || !this.levelOfThreats.length;
    },
    isVulnerabilitiesListDisabled: function isVulnerabilitiesListDisabled() {
      return this.disabled || !this.vulnerabilities.length;
    },
    threatLevelPlaceholder: function threatLevelPlaceholder() {
      return this.translate.select + ' ' + this.threatLevelTitle;
    },
    isTitleShown: function isTitleShown() {
      return this.threatShown || this.threatLevelShown || this.vulnerabilityShown;
    }
  }),
  beforeMount: function beforeMount() {
    this.getSelected();
  },
  methods: {
    getSelected: function getSelected() {
      var _this = this;
      this.threatsSelected = this.threats.filter(function (threat) {
        var _this$activity$RiskBa;
        return (_this$activity$RiskBa = _this.activity[RiskBaseModelProps.THREAT_IDS]) === null || _this$activity$RiskBa === void 0 ? void 0 : _this$activity$RiskBa.includes(threat.id);
      });
      this.levelOfThreatSelected = this.findValue(this.levelOfThreats, RiskBaseModelProps.THREAT_LEVEL_VALUE);
      this.vulnerabilitiesSelected = this.vulnerabilities.filter(function (vulnerability) {
        var _this$activity$RiskBa2;
        return (_this$activity$RiskBa2 = _this.activity[RiskBaseModelProps.VULNERABILITY_IDS]) === null || _this$activity$RiskBa2 === void 0 ? void 0 : _this$activity$RiskBa2.includes(vulnerability.id);
      });
      this.probabilitySelected = this.findValue(this.probabilities, RiskBaseModelProps.PROBABILITY_SCORE);
    },
    findValue: function findValue(data, key) {
      var _this2 = this;
      var result = data.find(function (item) {
        return item.id === _this2.activity[key];
      });
      if (result) {
        return result.value;
      }
    },
    onSelectorChanged: function onSelectorChanged() {
      this.emitState();
    },
    onCommentChanged: function onCommentChanged(key, e) {
      this.comments[key] = Utils.setMaxlength(e, this.maxlength);
      this.emitState();
    },
    onTreatSelect: function onTreatSelect(threats) {
      this.threatsSelected = threats;
      this.emitState();
    },
    onVulnerabilitySelect: function onVulnerabilitySelect(vulnerability) {
      this.vulnerabilitiesSelected = vulnerability;
      this.emitState();
    },
    emitState: function emitState() {
      var _this3 = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        _this3.$emit('changed', _objectSpread(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.THREAT_IDS, _this3.threatsSelected.map(function (threat) {
          return threat.id;
        })), RiskBaseModelProps.VULNERABILITY_IDS, _this3.vulnerabilitiesSelected.map(function (vulnerability) {
          return vulnerability.id;
        })), "threatLevelId", _this3.levelOfThreatSelected), RiskBaseModelProps.PROBABILITY_SCORE, _this3.probabilitySelected), _this3.comments));
      }, 100);
    }
  }
};