function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaVueMultiselectDropdown from '@/components/Atoms/Inputs/RismaVueMultiselect/RismaVueMultiselectDropdown';
import RismaVueMultiselectTags from '@/components/Atoms/Inputs/RismaVueMultiselect/RismaVueMultiselectTags';
import { getTextSize } from '@/utils/textSize';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaVueMultiselect',
  components: {
    FeatherIcon: FeatherIcon,
    RismaVueMultiselectDropdown: RismaVueMultiselectDropdown,
    RismaVueMultiselectTags: RismaVueMultiselectTags
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    modelValue: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    searchable: {
      type: Boolean,
      default: true,
      required: false,
      note: 'if element has search panel'
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
      note: 'Key to compare options by'
    },
    labelKey: {
      type: String,
      required: true,
      note: 'The key to create the labels by'
    },
    maxVisibleTags: {
      type: Number,
      required: false,
      default: 6
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Set to true to disable switch'
    },
    openDirection: {
      required: false,
      default: '',
      type: String,
      note: 'Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom'
    },
    enableSelectAll: {
      type: Boolean,
      default: true,
      note: 'Boolean to determine if show `Select All` feature in select'
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: false,
      note: 'group-select is used to define if selecting the group label should select/unselect all values in the group, or do nothing.'
    },
    groupValues: {
      type: String,
      required: false,
      default: '',
      note: 'group-values should point to the group’s option list.'
    },
    groupLabel: {
      type: String,
      required: false,
      default: '',
      note: 'The key to create the labels by'
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
      note: 'Label that is visible when no options are selected'
    },
    confirmSelectAll: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If show confirmation popup on `Select All`'
    },
    truncateLength: {
      type: Number,
      required: false,
      default: 200
    },
    hasTagsInOneLine: {
      type: Boolean,
      required: false,
      default: true
    },
    hasErrorMessage: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
      note: 'Boolean to determine if the select should close when an option is selected'
    },
    tabindex: {
      type: [Number, String],
      required: false,
      default: 0,
      note: 'index for tabbing'
    },
    lockedOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    searchFields: {
      type: Array,
      default: null
    },
    allDataLoaded: {
      type: Boolean,
      default: true
    },
    isLazyLoadingShown: {
      type: Boolean,
      default: false
    },
    truncateTagText: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: ['open', 'close', 'update:modelValue', 'update:searchValue', 'load'],
  data: function data() {
    return {
      isFocused: false,
      tabWasPressed: false,
      searchValue: '',
      maxHeight: 300,
      preferredOpenDirection: '',
      selectorWidth: 0,
      useWrapLineLogic: false,
      maximumElementsShown: 0,
      currentPointerIndex: -2,
      currentInputFocusRefreshIndex: 0,
      isPointerFeatureEnabled: false,
      isBodyHovered: false,
      translate: getTranslate['RismaVueMultiselect']()
    };
  },
  computed: {
    hasMaxVisibleTagsFeature: function hasMaxVisibleTagsFeature() {
      return this.maxVisibleTags !== 0 && this.maxVisibleTags !== -1;
    },
    emptyPlaceholder: function emptyPlaceholder() {
      return this.isOpen || this.modelValue.length;
    },
    openDirectionComputed: function openDirectionComputed() {
      if (this.openDirection === 'top' || this.openDirection === 'above' || this.preferredOpenDirection === 'top') return 'top';
      return 'below';
    },
    searchFieldsComputed: function searchFieldsComputed() {
      return this.searchFields ? this.searchFields : [this.labelKey];
    },
    selectorClasses: function selectorClasses() {
      if (this.disabled) return 'border-rm-grey';
      if (this.hasErrorMessage) {
        return 'border-rm-signal-red focus-within:outline-rm-signal-red--20 focus-within:outline-2 focus-within:outline';
      }
      return this.isOpen ? 'border-rm-active-blue outline-rm-active-blue--20 outline' : 'border-rm-grey group-hover:border-rm-signal-grey-dark group-hover:outline group-hover:outline-rm-grey-light focus:border-rm-active-blue';
    },
    showDisabledChevron: function showDisabledChevron() {
      if (!this.disabled) return false;
      return this.selectedElementsText || this.useWrapLineLogic;
    },
    hasTagsInOneLineComputed: function hasTagsInOneLineComputed() {
      return this.disabled || this.hasTagsInOneLine;
    },
    hasSearch: function hasSearch() {
      return this.searchable && !this.hasGroups && !this.disabled;
    },
    hasGroups: function hasGroups() {
      return this.groupValues !== '' && this.groupLabel !== '';
    },
    widthInputClasses: function widthInputClasses() {
      if (this.isInputShownAndHighlighted) return 'w-full';
      return 'w-0 h-0';
    },
    isInputShownAndHighlighted: function isInputShownAndHighlighted() {
      return this.isFocused || this.placeholder && !this.modelValue.length;
    },
    moreTextTemplate: function moreTextTemplate() {
      var maxLength = "".concat(this.maxVisibleTags - 1).length;
      var dummyNumber = '0'.repeat(maxLength);
      var text = "+ _N_ ".concat(this.translate.more);
      var width = getTextSize(text.replace('_N_', dummyNumber), '400 16px Inter');
      return {
        text: text,
        width: width
      };
    },
    showPlaceholderText: function showPlaceholderText() {
      return this.placeholderText && !this.modelValue.length;
    },
    placeholderText: function placeholderText() {
      return this.disabled ? this.translate.noDataAvailable : this.placeholder;
    },
    showDeleteIcon: function showDeleteIcon() {
      return this.modelValue.length > 1 && !this.disabled;
    },
    filteredOptions: function filteredOptions() {
      var _this = this;
      if (this.hasGroups) {
        var options = [];
        this.options.forEach(function (optionList, index) {
          options.push(_defineProperty(_defineProperty(_defineProperty({}, _this.labelKey, optionList[_this.groupLabel]), "isGroup", true), "groupIndex", index));
          options.push.apply(options, _toConsumableArray(optionList[_this.groupValues]));
        });
        return options;
      }
      if (!this.searchable) return this.options;
      return this.filteredOptionsBySearchValue;
    },
    filteredOptionsBySearchValue: function filteredOptionsBySearchValue() {
      var _this2 = this;
      var searchValue = this.searchValue.toLowerCase().trim();
      if (!searchValue) return this.options;
      return this.options.filter(function (option) {
        return _this2.searchFieldsComputed.some(function (field) {
          return option[field] && option[field].toLowerCase().includes(searchValue);
        });
      });
    },
    cleanOptions: function cleanOptions() {
      var _this3 = this;
      return this.hasGroups ? this.options.reduce(function (acc, cur) {
        return acc.concat(cur[_this3.groupValues]);
      }, []) : this.options;
    },
    selectedTagList: function selectedTagList() {
      if (this.useWrapLineLogic) return this.modelValue;
      if (this.maxVisibleTags < this.modelValue.length) return [];
      if (this.hasTagsInOneLineComputed) return this.modelValue.slice(0, this.maximumElementsShown);
      return this.modelValue;
    },
    selectedElementsText: function selectedElementsText() {
      if (!this.modelValue.length || this.useWrapLineLogic) return '';
      if (this.hasTagsInOneLineComputed && this.selectedTagList.length) {
        var text = this.moreTextTemplate.text;
        var amount = this.modelValue.length - this.selectedTagList.length;
        if (!amount) return '';
        return text.replace('_N_', amount);
      }
      if (!this.hasMaxVisibleTagsFeature || this.maxVisibleTags < this.modelValue.length || this.hasTagsInOneLineComputed && !this.selectedTagList.length) {
        return "".concat(this.modelValue.length, " ").concat(this.translate.selected);
      }
      if (this.maxVisibleTags > this.modelValue.length) return '';
      return '';
    },
    allElementLengths: function allElementLengths() {
      var _this4 = this;
      var spacingInsideTag = this.disabled ? 18 : 35;
      var marginRight = 8;
      return this.modelValue.map(function (item) {
        return getTextSize(item[_this4.labelKey], '500 14px Inter') + spacingInsideTag + marginRight;
      });
    },
    selectedOptionsTrackBy: function selectedOptionsTrackBy() {
      var _this5 = this;
      return this.modelValue.map(function (item) {
        return item[_this5.trackBy];
      });
    },
    areAllSelected: function areAllSelected() {
      var _this6 = this;
      var options = this.hasGroups ? this.cleanOptions : this.options;
      return options.every(function (option) {
        return _this6.selectedOptionsTrackBy.includes(option[_this6.trackBy]);
      });
    },
    isIconActive: function isIconActive() {
      return this.isOpen || this.isBodyHovered;
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function handler() {
        this.calculatePossibleAmountOfTags(this.selectorWidth, this.allElementLengths);
      }
    },
    disabled: function disabled() {
      this.calculateElementWidth();
    },
    searchValue: function searchValue() {
      this.setPointerOnFirstElement();
      this.$emit('update:searchValue', this.searchValue);
    }
  },
  mounted: function mounted() {
    var _this7 = this;
    document.fonts.ready.then(function () {
      if (_this7.hasTagsInOneLineComputed) {
        window.addEventListener('resize', _this7.calculateElementWidth);
        _this7.calculateElementWidth();
      }
    });
    this.resetCurrentPointerIndex();
  },
  unmounted: function unmounted() {
    if (this.hasTagsInOneLineComputed) {
      window.removeEventListener('resize', this.calculateElementWidth);
    }
  },
  methods: {
    handleOpen: function handleOpen() {
      var _this8 = this;
      this.tabWasPressed = false;
      if (!this.openDirection) this.adjustPosition();
      this.setPointerOnFirstElement();
      this.$emit('open');
      this.$nextTick(function () {
        _this8.handleTagsExpand();
        if (!_this8.hasSearch) {
          _this8.$refs.wrapsearch.focus();
          return;
        }
        if (_this8.hasMaxVisibleTagsFeature) {
          _this8.$refs.search.focus();
          _this8.scrollToSearch();
        }
      });
    },
    scrollToSearch: function scrollToSearch() {
      var _this9 = this;
      this.$nextTick(function () {
        var wrapper = _this9.$refs.wrapper;
        if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
      });
    },
    resetCurrentPointerIndex: function resetCurrentPointerIndex() {
      this.currentPointerIndex = this.hasGroups || this.enableSelectAll ? -2 : -1;
    },
    handleClose: function handleClose() {
      if (!this.isOpen) return;
      this.resetCurrentPointerIndex();
      this.currentInputFocusRefreshIndex++;
      this.resetSearchValue();
      this.$emit('close');
    },
    toggle: function toggle() {
      if (this.disabled) return;
      this.isOpen ? this.handleClose() : this.handleOpen();
    },
    handleRemoveElement: function handleRemoveElement(index) {
      var list = cloneDeep(this.modelValue);
      list.splice(index, 1);
      this.$emit('update:modelValue', list);
    },
    handleSelectAllButton: function handleSelectAllButton() {
      if (this.areAllSelected) {
        this.deselectAll();
      } else {
        if (this.confirmSelectAll) return this.showSelectAllAlert();
        this.selectAll();
      }
      if (this.closeOnSelect) this.handleClose();
    },
    handleSelectOption: function handleSelectOption(_ref) {
      var option = _ref.option,
        index = _ref.index;
      if (!option || option.isGroup && !this.groupSelect) return;
      this.currentPointerIndex = index;
      option.isGroup ? this.selectGroup(option) : this.selectOption(option);
      this.resetSearchValue();
      this.scrollToSearch();
      if (this.closeOnSelect) this.handleClose();
    },
    selectOption: function selectOption(option) {
      var _this10 = this;
      var list = cloneDeep(this.modelValue);
      if (this.selectedOptionsTrackBy.includes(option[this.trackBy])) {
        list = list.filter(function (item) {
          return item[_this10.trackBy] !== option[_this10.trackBy];
        });
      } else {
        list.push(option);
      }
      this.emitModelValue(list);
    },
    emitModelValue: function emitModelValue(modelValue) {
      this.$emit('update:modelValue', modelValue);
    },
    deselectAll: function deselectAll() {
      this.currentPointerIndex = -1;
      this.emitModelValue([]);
    },
    selectAll: function selectAll() {
      this.currentPointerIndex = -1;
      this.emitModelValue(this.hasGroups ? this.cleanOptions : this.options);
    },
    selectGroup: function selectGroup(group) {
      var _this11 = this;
      var options = this.options[group.groupIndex][this.groupValues];
      var allGroupOptionsSelected = options.every(function (option) {
        return _this11.selectedOptionsTrackBy.includes(option[_this11.trackBy]);
      });
      var list = cloneDeep(this.modelValue);
      if (allGroupOptionsSelected) {
        options.forEach(function (option) {
          list = list.filter(function (item) {
            return item[_this11.trackBy] !== option[_this11.trackBy];
          });
        });
      } else {
        options.forEach(function (option) {
          var index = list.findIndex(function (item) {
            return item[_this11.trackBy] === option[_this11.trackBy];
          });
          if (index === -1) {
            list.push(option);
          }
        });
      }
      this.emitModelValue(list);
    },
    showSelectAllAlert: function showSelectAllAlert() {
      var _this12 = this;
      var numberOfItemsToAdd = this.options.length - this.modelValue.length;
      this.alertBody = "".concat(this.translate.areYouSureYouWantToAdd, "\n                ").concat(numberOfItemsToAdd, " ").concat(this.translate.newItems, "?");
      this.$confirm(this.translate.warning + '! ' + this.translate.youAreTryingToSelectAllItems, this.alertBody, function (res) {
        return res && _this12.selectAll();
      }, {
        buttonOkText: this.translate.selectAll
      });
    },
    adjustPosition: function adjustPosition() {
      var select = this.$refs.select;
      if (!select) return;
      var bountingObject = select.getBoundingClientRect();
      var spaceAbove = bountingObject.top;
      var spaceBelow = window.innerHeight - bountingObject.bottom;
      var emptyElementHeight = 160;
      var height = !this.options.length ? emptyElementHeight : this.maxHeight;
      var hasEnoughSpaceBelow = spaceBelow > height;
      if (hasEnoughSpaceBelow || spaceBelow > spaceAbove) {
        this.preferredOpenDirection = 'below';
      } else {
        this.preferredOpenDirection = 'top';
      }
    },
    calculateElementWidth: function calculateElementWidth() {
      var select = this.$refs.select;
      if (!select) return;
      var spacingInside = this.showDeleteIcon ? 82 : 58;
      this.selectorWidth = Math.floor(select.getBoundingClientRect().width) - spacingInside;
      this.calculatePossibleAmountOfTags(this.selectorWidth, this.allElementLengths);
    },
    calculatePossibleAmountOfTags: function calculatePossibleAmountOfTags(possibleContentWidth, elementLengths) {
      if (!elementLengths.length) return;
      if (elementLengths.length === 1 && elementLengths[0] >= possibleContentWidth) {
        this.maximumElementsShown = 1;
        return;
      }
      possibleContentWidth -= this.moreTextTemplate.width;
      var index = 0;
      while (possibleContentWidth > 0 && index <= elementLengths.length) {
        possibleContentWidth -= elementLengths[index];
        index += 1;
      }
      if (index === elementLengths.length && Math.abs(possibleContentWidth) < this.moreTextTemplate.width) {
        index += 1;
      }
      this.maximumElementsShown = index ? index - 1 : index;
    },
    handleTagsExpand: function handleTagsExpand() {
      this.useWrapLineLogic = true;
    },
    handleTagsCollapse: function handleTagsCollapse() {
      this.useWrapLineLogic = false;
      this.calculateElementWidth();
    },
    handleInputFocus: function handleInputFocus() {
      this.isFocused = true;
      this.handleOpen();
    },
    onBlur: function onBlur() {
      this.isFocused = false;
      if (this.tabWasPressed) {
        this.handleClose();
      }
      this.resetSearchValue();
    },
    onKeyDown: function onKeyDown(evt) {
      if (!this.isOpen) return;
      var charCode = evt.keyCode || evt.key.charCodeAt(0);
      this.tabWasPressed = charCode === 9;
    },
    addFromPointer: function addFromPointer() {
      if (this.currentPointerIndex === -1 && this.searchValue.length) return;
      if (this.currentPointerIndex === -1) {
        this.handleSelectAllButton();
        return;
      }
      var option = this.filteredOptions[this.currentPointerIndex];
      this.handleSelectOption({
        option: option,
        index: this.currentPointerIndex
      });
    },
    handleReset: function handleReset() {
      this.deselectAll();
      this.onBlur();
    },
    removeLastElement: function removeLastElement() {
      if (!this.modelValue.length || this.searchValue.length) return;
      var list = cloneDeep(this.modelValue).slice(0, -1);
      this.emitModelValue(list);
    },
    moveDown: function moveDown() {
      if (!this.isOpen || !this.filteredOptions.length) return;
      if (this.filteredOptions.length - 1 === this.currentPointerIndex) {
        this.setPointerOnFirstElement();
        this.focusSelected(this.currentPointerIndex);
        this.handleIndexGroupSkip('moveDown');
        return;
      }
      this.currentPointerIndex += 1;
      this.focusSelected(this.currentPointerIndex);
      this.handleIndexGroupSkip('moveDown');
    },
    isCurrentPointIsGroup: function isCurrentPointIsGroup(index) {
      var _this$filteredOptions;
      return (_this$filteredOptions = this.filteredOptions[index]) === null || _this$filteredOptions === void 0 ? void 0 : _this$filteredOptions.isGroup;
    },
    handleIndexGroupSkip: function handleIndexGroupSkip(state) {
      if (this.isCurrentPointIsGroup(this.currentPointerIndex) && !this.groupSelect) {
        this[state]();
      }
    },
    moveUp: function moveUp() {
      if (!this.isOpen || !this.filteredOptions.length) return;
      var minimalValue = this.enableSelectAll && !this.searchValue ? 0 : 1;
      if (this.currentPointerIndex < minimalValue) {
        this.currentPointerIndex = this.filteredOptions.length - 1;
        this.focusSelected(this.currentPointerIndex);
        this.handleIndexGroupSkip('moveUp');
        return;
      }
      this.currentPointerIndex -= 1;
      this.focusSelected(this.currentPointerIndex);
      this.handleIndexGroupSkip('moveUp');
    },
    setPointerOnFirstElement: function setPointerOnFirstElement() {
      this.currentPointerIndex = this.enableSelectAll && !this.searchValue ? -1 : 0;
    },
    focusSelected: function focusSelected(index) {
      this.$refs.dropdown.focusSelected(index);
    },
    handleSearchInputEvent: function handleSearchInputEvent(type) {
      switch (type) {
        case 'up':
          return this.moveUp();
        case 'down':
          return this.moveDown();
        case 'enter':
          return this.addFromPointer();
        case 'delete':
          return this.removeLastElement();
      }
    },
    handleHover: function handleHover(index) {
      this.currentPointerIndex = index;
    },
    resetSearchValue: function resetSearchValue() {
      this.searchValue = '';
    }
  }
};