/**
 * isValidUrl
 * @param {String} url - Non-empty string
 */
export function isValidUrl(url) {
  var urlRegExp = /^([a-z0-9]+:\/\/www\.|[a-z0-9]+:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*(:[0-9]{1,5})?(?!\/\/)(\/.*)?$/gim;
  return urlRegExp.test(url);
}
export function isDomainValid(domain) {
  return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(domain);
}
export function isEndpointValid(endpoint) {
  var regExp = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\/$/g);
  return regExp.test(endpoint);
}
export function isValidLocalPath(path) {
  var regExp = /(^(file:(\/\/\/?))?(?:([a-zA-Z]:)?|(\\\\|\/\/)[\w.]+(\\|\/)[\w.$]+)((\\|\/)|(\\\\|\/\/))(?:[\w ]+(\\|\/))*\w([\w. ])+[.][a-zA-Z]+$)/;
  return regExp.test(path);
}
export function isValidAsUrlAndLocalPath(link) {
  return isValidUrl(link) || isValidLocalPath(link);
}

/**
 * isValidString checks that string non-empty
 * @param {String} string - String
 */
export function isValidString(string) {
  var result = false;
  if (typeof string !== 'string') {
    return result;
  }
  if (string.trim() === '') {
    return result;
  }
  return true;
}
export function isFileAllowed(file, attachmentsAllowed) {
  var allowed = false;
  Object.keys(attachmentsAllowed).forEach(function (ext) {
    var parts = file.filename.split('.');
    var fileExt = parts[parts.length - 1];
    if (fileExt.toLowerCase() === ext.toLowerCase()) {
      allowed = true;
    }
  });
  return allowed;
}
export function isTextAreaFilled(textPassed) {
  var text = textPassed ? textPassed.replace(/↵/g, '') : '';
  return text.trim() !== '';
}

/**
 * Enterprise security password validation
 *
 * @param password
 * @returns {boolean}
 */
export function isPasswordValid(password) {
  var passwordLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 14;
  var regExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{".concat(passwordLength, ",})"));
  return regExp.test(password);
}
export function isEmailValid(email) {
  var emailRegExp = /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"[^"]+")@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([A-Za-z0-9-]+\.)+[A-Za-z]{2,}))$/;
  return emailRegExp.test(email.trim());
}
export function isOnlyLettersNumbersSpacesDashes(str) {
  return /^[A-Za-z0-9 -]*$/.test(str);
}
export function isSupportedCountry(countryCode) {
  var _countryCode;
  countryCode = (_countryCode = countryCode) === null || _countryCode === void 0 ? void 0 : _countryCode.toLowerCase();
  var supportedCountries = ['dk', 'fi', 'se', 'no'];
  return supportedCountries.includes(countryCode);
}
export function isValidVatNo(vatNo, countryCode) {
  var _countryCode2;
  var isSupported = isSupportedCountry(countryCode);
  if (!vatNo || !isSupported) {
    return true;
  }
  countryCode = (_countryCode2 = countryCode) === null || _countryCode2 === void 0 ? void 0 : _countryCode2.toLowerCase();
  var regexes = {
    dk: /^[0-9]{8}$/,
    de: /^[0-9]{9}$/,
    fi: /^[0-9]{7}-?[0-9]$/,
    se: /^[0-9]{10}(0\d)?$/,
    no: /^[0-9]{9}(MVA)?$/i
  };
  return regexes[countryCode].test(vatNo);
}
export function isValidNumber(string, groupSeparator) {
  var decimalSeparator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';
  var groupRegex = "((\\".concat(groupSeparator, "\\d{3})|\\d)*");
  var decimalRegex = "(\\".concat(decimalSeparator, "\\d+)?");
  var regex = new RegExp("^-?\\d{1,3}".concat(groupRegex).concat(decimalRegex, "?$"));
  return regex.test(string);
}