import { Http } from '../index';
import { checkHttpStatus, throwError, handleRequestResponse, getTooLongError } from '../httpUtils';
import { getPromiseWithCacheTimeout } from '../HttpCache';
export function getGapDashboard(projectId, filter) {
  var filterParam = filter ? '&' + filter : '';
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/dashboard/gap?").concat(filterParam)));
}
export function getQuestionDashboard(projectId, filter) {
  return Http.get("/compliance/projects/".concat(projectId, "/dashboard/questions?").concat(filter)).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function getOverviewDashboard(projectId) {
  return getPromiseWithCacheTimeout("/compliance/projects/".concat(projectId, "/dashboard/overview"));
}
export function getDashboardReport(projectId) {
  var filterString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var queryErrorMessage = getTooLongError(filterString);
  if (queryErrorMessage) return Promise.reject(queryErrorMessage);
  return handleRequestResponse(Http.get("/compliance/projects/".concat(projectId, "/report/gap").concat(filterString)));
}