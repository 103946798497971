function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { toLocalDate } from '@/utils/date';
import * as Utils from '@/utils/Utils';
import { UserLevelsByValue } from '@/constants/UserLevels';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { CommonAssetsMixin } from '@/mixins/CommonAssetsMixin';
import { projectsByUser } from '@/utils/projectsByUser';
export default {
  name: 'UserRightsExpanded',
  introduction: 'Rismadatatable with users',
  description: 'This component shows available users with <risma-datatable> inside. Expanded view',
  token: '<user-rights-expanded />',
  components: {
    RismaButton: RismaButton,
    RismaDatatable: RismaDatatable
  },
  mixins: [CommonAssetsMixin, RismaDatatableDefaultMixin],
  data: function data() {
    return {
      columns: [],
      dataset: [],
      fuseSearchOptions: {
        threshold: 0.1,
        location: 0,
        distance: 1000
      },
      decoupleFilterFields: [{
        key: 'activeUsers'
      }, {
        key: 'locked'
      }, {
        key: 'incidents'
      }, {
        key: 'controls'
      }, {
        key: 'risk'
      }, {
        key: 'actions'
      }],
      disabledFilterByColumns: ['email', 'username', 'fullname', 'initials', 'previousLogin', 'organisationsRead', 'organisations'],
      translate: getTranslate['UserRightsExpanded']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    processLibraryName: 'processLibraryName'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    isMfaFeatureEnabled: function isMfaFeatureEnabled() {
      return this.getSettingValue('feature.google_mfa_enabled');
    },
    preparedColumns: function preparedColumns() {
      var optionalColumns = {};
      if (this.isMfaFeatureEnabled) optionalColumns.mfaType = this.translate.mfaStatus;
      return _objectSpread(_objectSpread({
        email: this.translate.email,
        username: this.translate.username,
        fullname: this.translate.fullName,
        initials: this.translate.initials,
        actions: this.translate.rismaactions,
        risk: this.translate.rismarisk,
        controls: this.translate.rismacontrols,
        incidents: this.translate.rismaincidents,
        contracts: this.translate.contracts,
        processLibrary: this.processLibraryName
      }, this.preparedProjectColumns), {}, {
        locked: this.translate.locked,
        previousLogin: this.translate.previousLogin,
        organisations: this.translate.organisations,
        organisationsRead: this.translate.organisations + this.translate.readonly,
        activeUsers: this.translate.activity
      }, optionalColumns);
    },
    preparedProjectColumns: function preparedProjectColumns() {
      var projectsColumns = {};
      var projects = projectsByUser(this.currentUser, this.getSettingValue);
      var _iterator = _createForOfIteratorHelper(projects),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var project = _step.value;
          projectsColumns[this.getKeyForProject(project)] = project.title;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return projectsColumns;
    },
    filteredUsers: function filteredUsers() {
      return this.users.filter(function (_ref) {
        var anonymized = _ref.anonymized;
        return !anonymized;
      });
    }
  }),
  mounted: function mounted() {
    this.dataset = this.prepareDataset(this.filteredUsers);
    this.enhanceDataset(this.dataset, this.preparedColumns);
  },
  methods: {
    backToPrevPage: function backToPrevPage() {
      this.$router.push('/settings/administration');
    },
    getKeyForProject: function getKeyForProject(project) {
      if (project.objType === ObjectTypes.RISK_PROJECT) {
        return 'r-' + project.id;
      }
      if (project.objType === ObjectTypes.COMPLIANCE_PROJECT) {
        return 'c-' + project.id;
      }
    },
    prepareDataset: function prepareDataset(users) {
      var _this = this;
      var accessText = ['No access', 'Normal', 'Privileged', 'Super', 'Admin'];
      var lockedFields = {
        level_e_locked: 'RISMAactions',
        level_r_locked: 'RISMArisk',
        level_c_locked: 'RISMAcontrols',
        level_incidents_locked: 'RISMAincidents',
        level_process_library_locked: 'RISMAcompliance',
        level_contracts_locked: this.translate.contracts
      };
      var dataset = users.map(function (user) {
        var lockedText = [];
        Object.keys(lockedFields).forEach(function (field) {
          if (user[field]) {
            lockedText.push(lockedFields[field]);
          }
        });
        var projectAccessLevels = {};
        var projects = projectsByUser(user, _this.getSettingValue);
        projects.forEach(function (project) {
          var key = _this.getKeyForProject(project);
          projectAccessLevels[key] = {
            field: _this.$trans(UserLevelsByValue[project.access_level])
          };
        });
        var optionalFields = {};
        if (_this.isMfaFeatureEnabled) optionalFields.mfaType = {
          field: user.mfa_type ? _this.translate.enabled : _this.translate.disabled
        };
        return _objectSpread(_objectSpread({
          email: {
            field: user.email || ''
          },
          username: {
            field: user.username || ''
          },
          fullname: {
            field: user.fullname || ''
          },
          initials: {
            field: user.initials || ''
          },
          actions: {
            field: _this.$trans(accessText[user.level_execution])
          },
          risk: {
            field: _this.$trans(accessText[user.level_risk])
          },
          controls: {
            field: _this.$trans(accessText[user.level_controls])
          },
          incidents: {
            field: _this.$trans(accessText[user.level_incidents])
          },
          contracts: {
            field: _this.$trans(accessText[user.level_contracts])
          },
          processLibrary: {
            field: _this.$trans(accessText[user.level_process_library])
          }
        }, projectAccessLevels), {}, {
          locked: {
            field: lockedText.length ? lockedText.join(', ') : _this.translate.none
          },
          previousLogin: {
            field: toLocalDate(user.previous_login)
          },
          organisations: {
            field: Utils.findPropsByIds(user.organisations, _this.organisations, 'visible_name')
          },
          organisationsRead: {
            field: Utils.findPropsByIds(user.organisationsReadonly, _this.organisations, 'visible_name')
          },
          activeUsers: {
            field: user.active ? _this.translate.active : _this.translate.inactive
          }
        }, optionalFields);
      });
      return dataset;
    }
  }
};