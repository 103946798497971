function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getDocumentTypesAll } from '@/api/admin/documentTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { toLocalDateTimeFull } from '@/utils/date';
import { SortDirection } from '@/constants/SortDirection';
import { compareDates } from '@/utils/compare';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
export default {
  name: 'ContractDocuments',
  description: 'This is the Page component for /contracts/contract/:id/documents',
  token: '<contract-documents data="" />',
  components: {
    FeatherIcon: FeatherIcon,
    PreviewList: PreviewList,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['triggerAttachmentMethod'],
  data: function data() {
    return {
      documentTypes: [],
      disabledFilterByColumns: ['date', 'pinned', 'showVersions', 'newVersion', 'edit', 'download', 'delete'],
      disabledSortByColumns: ['pinned', 'showVersions', 'newVersion', 'edit', 'download', 'delete'],
      defaultOrder: [{
        index: 'date',
        dir: SortDirection.DESC
      }],
      columnsMeta: [{
        targets: ['pinned', 'showVersions', 'newVersion', 'edit', 'delete', 'download'],
        width: '25px'
      }, {
        targets: ['docTitle'],
        width: '250px'
      }],
      translate: getTranslate['ContractDocuments']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), {}, {
    documents: function documents() {
      return this.data.files.filter(function (attachment) {
        return attachment.mimeType;
      });
    },
    documentsByDate: function documentsByDate() {
      return _toConsumableArray(this.documents).sort(function (a, b) {
        return compareDates(a.created, b.created);
      });
    },
    columns: function columns() {
      return {
        docTitle: this.translate.title,
        uploader: this.translate.uploaderName,
        date: this.translate.uploadDate,
        type: this.translate.type,
        version: this.translate.versions,
        pinned: '',
        showVersions: '',
        newVersion: '',
        edit: '',
        download: '',
        delete: ''
      };
    },
    dataset: function dataset() {
      var _this = this;
      return this.documentsByDate.map(function (document) {
        var typeFieldTextString = '';
        var typeString = '';
        _this.documentTypes.forEach(function (docType) {
          if (document.docTypeIds.includes(docType.id)) {
            typeFieldTextString += docType.name + ', ';
            typeString += "<span class=\"whitespace-pre-line\">".concat(docType.name, ",</span> ");
          }
        });
        if (typeString) {
          var lastTagRegEx = /,<\/span>\s$/gim;
          typeString = typeString.replace(lastTagRegEx, '</span>');
        } else {
          typeString = _this.translate.notSpecified;
          typeFieldTextString = _this.translate.notSpecified;
        }
        return {
          docTitle: {
            title: document.title || '',
            filename: document.filename,
            url: document.url,
            fieldText: document.title
          },
          uploader: {
            field: _this.getUserById(document.owner)
          },
          date: {
            field: toLocalDateTimeFull(document.created)
          },
          type: {
            field: typeString,
            fieldText: typeFieldTextString
          },
          version: {
            field: document.version
          },
          pinned: {
            data: document
          },
          showVersions: {
            data: document.id
          },
          newVersion: {
            data: document.id
          },
          edit: {
            data: document
          },
          download: {
            data: document
          },
          delete: {
            data: document
          }
        };
      });
    }
  }),
  watch: {
    dataset: function dataset() {
      this.enhanceDataset(this.dataset, this.columns);
    }
  },
  mounted: function mounted() {
    this.enhanceDataset(this.dataset, this.columns);
    this.getDocumentTypes();
  },
  methods: {
    getDocumentTypes: function getDocumentTypes() {
      var _this2 = this;
      return getDocumentTypesAll().then(function (_ref) {
        var list = _ref.list;
        _this2.documentTypes = list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            name: _this2.$trans(item.name)
          });
        });
      });
    },
    toggleCreateModal: function toggleCreateModal() {
      if (this.readOnly) {
        return;
      }
      this.$emit('triggerAttachmentMethod', 'toggleModal');
    },
    deleteFile: function deleteFile(id) {
      this.$emit('triggerAttachmentMethod', 'openConfirmModal', id);
    },
    showVersions: function showVersions(fileId) {
      this.$emit('triggerAttachmentMethod', 'onShowVersions', fileId);
    },
    addNewVersion: function addNewVersion(fileId) {
      this.$emit('triggerAttachmentMethod', 'onCreateNewVersion', fileId);
    },
    onEditFile: function onEditFile(file) {
      this.$emit('triggerAttachmentMethod', 'openEditModal', file);
    },
    togglePin: function togglePin(id) {
      this.$emit('triggerAttachmentMethod', 'togglePin', id);
    },
    getUserById: function getUserById(id) {
      var _this$users$find;
      return ((_this$users$find = this.users.find(function (user) {
        return user.id == id;
      })) === null || _this$users$find === void 0 ? void 0 : _this$users$find.display_name) || '';
    }
  }
};