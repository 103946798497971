import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, withKeys as _withKeys, resolveDirective as _resolveDirective } from "vue";
var _hoisted_1 = ["placeholder"];
var _hoisted_2 = ["onMouseenter", "onClick", "onKeyup"];
var _hoisted_3 = {
  key: 0,
  class: "py-1 px-2 italic text-gray-550"
};
var _hoisted_4 = {
  key: 1,
  class: "py-1 px-2 italic text-gray-550"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "font-bold text-gray-650 cursor-pointer",
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return $data.showList = !$data.showList;
    }, ["stop"]))
  }, _toDisplayString($props.title), 33 /* TEXT, NEED_HYDRATION */)) : _createCommentVNode("v-if", true), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "relative",
    onKeyup: _cache[5] || (_cache[5] = _withKeys(function () {
      return $options.keyDownOnSearch && $options.keyDownOnSearch.apply($options, arguments);
    }, ["down"])),
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $data.showList = !$data.showList;
    })
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.searchValue = $event;
    }),
    type: "text",
    placeholder: $props.searchPlaceholder,
    class: _normalizeClass(["w-full mb-1 rounded-md py-2 pl-3 pr-8 outline-none border-2 focus:border-blue-550 cursor-pointer focus:placeholder-transparent placeholder-italic", $props.isValid ? 'border-gray-350' : 'border-red-50']),
    onInput: _cache[2] || (_cache[2] = function () {
      return $options.handleInput && $options.handleInput.apply($options, arguments);
    })
  }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelText, $data.searchValue, void 0, {
    trim: true
  }]]), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['stroke-3 text-blue-750 absolute top-0 bottom-0 h-full right-4 transform duration-500 cursor-pointer', {
      'rotate-180': $data.showList
    }])
  }, null, 8 /* PROPS */, ["class"]), $data.showList ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "items-list absolute right-0 left-0 py-1 px-1 overflow-auto rounded-md border-2 border-gray-35 bg-white z-10 overflow-x-hidden",
    onKeyup: [_cache[3] || (_cache[3] = _withKeys(_withModifiers(function () {
      return $options.focusNextItem && $options.focusNextItem.apply($options, arguments);
    }, ["stop"]), ["down"])), _cache[4] || (_cache[4] = _withKeys(_withModifiers(function () {
      return $options.focusPrevItem && $options.focusPrevItem.apply($options, arguments);
    }, ["stop"]), ["up"]))]
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredItems, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: "item py-1 px-2 cursor-pointer focus:bg-gray-350 hover:bg-gray-350 break-words whitespace-pre-line",
      tabindex: "-1",
      onMouseenter: function onMouseenter($event) {
        return $props.changeFocusOnHover && $options.setFocusOnItem(index);
      },
      onClick: _withModifiers(function ($event) {
        return $options.selected(item, index);
      }, ["stop"]),
      onKeyup: _withKeys(_withModifiers(function ($event) {
        return $options.selected(item, index);
      }, ["stop"]), ["enter"])
    }, [_renderSlot(_ctx.$slots, "displayedName", {
      item: item
    }, undefined, true)], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */)), $props.items.length && !$options.filteredItems.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.translate.noResultsMatchingSearchCriteria), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$props.items.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 32 /* NEED_HYDRATION */)) : _createCommentVNode("v-if", true)], 32 /* NEED_HYDRATION */)), [[_directive_click_outside, $options.hideList]])]);
}