import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
var typeColorMap = ['warning', 'error', 'info', 'success'];
export default {
  name: 'Notification',
  components: {
    FeatherIcon: FeatherIcon
  },
  introduction: 'can notify the user',
  description: 'This container is used to notify the user with important information, this can be a warning, an error, a success message or plain information',
  token: '<notification type="error" :closeable="false">Warning! AI is coming...</notification>',
  props: {
    type: {
      type: String,
      required: true,
      validator: function validator(value) {
        return typeColorMap.indexOf(value) !== -1;
      },
      note: 'the type of message, uses for colors of a notifiction. Possibility values: "warning", "error", "success" or "info"'
    },
    closeable: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Added an icon "close" to a notification'
    }
  },
  emits: ['dismiss'],
  methods: {
    dismiss: function dismiss() {
      var _this = this;
      this.$el.classList.add('fadeOut');
      setTimeout(function () {
        if (_this.$el) {
          _this.$el.classList.add('dismiss');
        }
      }, 500);
      this.$emit('dismiss');
    },
    resetDismiss: function resetDismiss() {
      this.$el.classList.remove('fadeOut');
      this.$el.classList.remove('dismiss');
    }
  }
};