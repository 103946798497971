export default {
  name: 'ReadMore',
  introduction: 'Read More / Read Less component',
  description: 'Custom read more string',
  token: '<read-more more-title="Show more" less-title="show less" :text=""/>',
  props: {
    moreTitle: {
      type: String,
      required: false,
      default: 'Show more',
      note: 'Title for read more string'
    },
    lessTitle: {
      type: String,
      required: false,
      default: 'Show less',
      note: 'Title for read less string'
    },
    text: {
      type: String,
      required: true,
      note: 'Used to limit the text value to generate a read more text'
    },
    maxChars: {
      type: Number,
      required: false,
      default: 150,
      note: 'Title for read less string'
    }
  },
  emits: ['click'],
  data: function data() {
    return {
      isReadMore: false
    };
  },
  computed: {
    formattedString: function formattedString() {
      var string = this.text;
      if (!this.isReadMore && this.text.length > this.maxChars) {
        string = string.slice(0, this.maxChars) + '...';
      }
      return string;
    },
    readMoreTitle: function readMoreTitle() {
      return this.isReadMore ? this.lessTitle : this.moreTitle;
    }
  },
  methods: {
    triggerReadMore: function triggerReadMore() {
      this.isReadMore = !this.isReadMore;
    }
  }
};