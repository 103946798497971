import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import { DateFormats } from '@/constants/DateFormats';
export default {
  name: '',
  components: {
    DateTimePicker: DateTimePicker
  },
  data: function data() {
    return {
      valueErrorMessage: null,
      dateTimepicker1: '2020-06-11 16:30',
      dateTimepicker2: '2017-06-01 00:00',
      dateTimepicker3: new Date(),
      dateTimepickerOptions1: {
        defaultDate: new Date()
      },
      dateTimepickerOptions2: {
        defaultDate: new Date(),
        format: DateFormats.DATE_FORMAT
      },
      dateTimepickerOptions3: {
        format: DateFormats.DATE_FORMAT,
        widgetPositioning: {
          horizontal: 'right',
          vertical: 'top'
        }
      },
      dateTimepickerOptions4: {
        format: DateFormats.DATE_FORMAT
      }
    };
  }
};