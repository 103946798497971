// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/access-types/icon_noAccess.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/access-types/icon_user.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/access-types/icon_privUser.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/access-types/icon_superUser.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/img/access-types/icon_supervisor.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/img/access-types/icon_admin.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../assets/img/access-types/icon_lock.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-d1f1d4e4]  .checkbox-wrapper {
  margin: 0;
}
.user-rights-table thead[data-v-d1f1d4e4] {
  box-shadow: 0px 1px 0px #807c79;
}
.user-rights-table td[data-v-d1f1d4e4],
.user-rights-table th[data-v-d1f1d4e4] {
  min-width: 100px;
  width: 100px;
  height: 40px;
}
.user-rights-table th[data-v-d1f1d4e4] {
  font-weight: normal;
  font-size: 14px;
}
.user-rights-table th[data-v-d1f1d4e4]:first-child,
.user-rights-table td[data-v-d1f1d4e4]:first-child {
  min-width: 270px;
}
.user-rights-table tr:first-child td[data-v-d1f1d4e4] {
  padding-top: 16px;
}
.user-rights-table tbody[data-v-d1f1d4e4]:not(:last-child) {
  border-bottom: 1px solid #807c79;
}
.user-rights-table tbody:not(:last-child) tr:last-child td[data-v-d1f1d4e4] {
  height: 60px;
  padding-bottom: 1rem;
}
.icon[data-v-d1f1d4e4] {
  display: block;
  width: 35px;
  height: 35px;
  background-size: 35px;
  background-position: center;
  background-repeat: no-repeat;
}
.no-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.normal-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.privileged-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.super-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
.supervisor-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
.admin-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
.locked-access-icon[data-v-d1f1d4e4] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
