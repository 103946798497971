import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "mb-3 flex items-center"
};
var _hoisted_4 = {
  class: "mr-3 w-full"
};
var _hoisted_5 = {
  class: "w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, "Last query: " + _toDisplayString($data.query), 1 /* TEXT */), _createElementVNode("p", null, "Number of calls: " + _toDisplayString($data.numberOfCalls), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_2, [_cache[4] || (_cache[4] = _createElementVNode("h2", {
    class: "mb-3"
  }, "Use predefined report", -1 /* HOISTED */)), _createVNode(_component_single_select, {
    "model-value": $data.selectedReport,
    options: $options.predefinedReports,
    "with-reset": false,
    "un-sorted": true,
    class: "mb-3",
    "onUpdate:modelValue": $options.onReportSelect
  }, null, 8 /* PROPS */, ["model-value", "options", "onUpdate:modelValue"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("p", {
    class: "mb-3"
  }, "Or paste your own api ( ex. /companies/company ):", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    modelValue: $data.ownApi,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.ownApi = $event;
    }),
    modelModifiers: {
      trim: true
    },
    "focus-on-mount": true,
    invalid: $data.ownApiError && !!$data.ownApi,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "invalid"])]), _createElementVNode("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("p", {
    class: "mb-3"
  }, " Additional query params ( ex. filters[vat_status_as_text_translated][]=4 ): ", -1 /* HOISTED */)), _createVNode(_component_input_field, {
    modelValue: $data.additionalQueryParams,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.additionalQueryParams = $event;
    }),
    modelModifiers: {
      trim: true
    },
    "focus-on-mount": true,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue"])])]), _createVNode(_component_risma_button, {
    text: "Search",
    type: "save",
    onClick: $options.onSearchButtonClick
  }, null, 8 /* PROPS */, ["onClick"])]), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": "Test",
    "state-key": $data.stateKey,
    "show-search-field": true,
    api: $options.selectedApi,
    onStarred: $options.onDataStarred,
    onIconClicked: $options.onIconClicked
  }, null, 8 /* PROPS */, ["state-key", "api", "onStarred", "onIconClicked"]))]);
}