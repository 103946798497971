function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import get from 'lodash/get';
import * as Utils from '@/utils/Utils';
import { compareDates, comparePathStrings, compareStrings, compareNumbers } from '@/utils/compare';
import { ControlTypeFields } from '@/constants/ControlTypeFields';
import { SortFieldsCompatibility } from '@/constants/SortFieldsCompatibility';
export function getSortFunction(_ref) {
  var sortBy = _ref.sortBy,
    users = _ref.users,
    organisations = _ref.organisations,
    activityTypes = _ref.activityTypes,
    tags = _ref.tags,
    workflowStatuses = _ref.workflowStatuses;
  switch (sortBy) {
    case SortFieldsCompatibility.CPATH:
      return sortByPathStrings(sortBy);
    case SortFieldsCompatibility.RNO:
    case SortFieldsCompatibility.APPROVED:
      return sortNumbers(sortBy);
    case ControlTypeFields.KEY_CONTROL:
    case ControlTypeFields.KEY_PREVENTIVE_DETECTIVE:
      return defaultSortingDesc(sortBy);
    case SortFieldsCompatibility.PROJECT_TITLE:
    case SortFieldsCompatibility.QUEST_ANSWERS:
      return sortByPathProperty(sortBy);
    case SortFieldsCompatibility.USER_IDS_RESPONSIBLE:
    case SortFieldsCompatibility.ACCOUNTABLE_USER_IDS:
    case SortFieldsCompatibility.RESPONSIBLE_USER_IDS:
      return sortByEntity({
        key: sortBy,
        data: users,
        propName: 'display_name'
      });
    case SortFieldsCompatibility.TAGS:
      return sortByEntity({
        key: sortBy,
        data: tags,
        propName: 'tag'
      });
    case SortFieldsCompatibility.WORKFLOW_STATUS:
      return sortByEntity({
        key: sortBy,
        data: workflowStatuses,
        propName: 'title'
      });
    case SortFieldsCompatibility.ORG_IDS:
      return sortByEntity({
        key: sortBy,
        data: organisations,
        propName: 'visible_name'
      });
    case SortFieldsCompatibility.TITLE_IA:
      return sortRismaTitleIA(sortBy);
    case SortFieldsCompatibility.OBJ_TYPE_IA:
      return sortAssetTypes({
        sortBy: sortBy,
        activityTypes: activityTypes
      });
    case SortFieldsCompatibility.REVIEW_DEADLINE:
    case SortFieldsCompatibility.START:
    case SortFieldsCompatibility.CREATED_AT:
    case SortFieldsCompatibility.DEADLINE:
      return sortByDateAsc(sortBy);
    case SortFieldsCompatibility.INCID_HAPPEND_AT:
    case SortFieldsCompatibility.INCID_ARCHIVED_AT:
      return sortByDateDesc(sortBy);
    case SortFieldsCompatibility.DESCRIPTION:
      return defaultSortingWithHtmlClear(sortBy);
    case SortFieldsCompatibility.ACCOUNTS:
    case SortFieldsCompatibility.RISKS:
    case SortFieldsCompatibility.PROCESSES:
      return sortAssociations(sortBy);
    default:
      return defaultSorting(sortBy);
  }
}
function sortAssetTypes(_ref2) {
  var sortBy = _ref2.sortBy,
    activityTypes = _ref2.activityTypes;
  return function (a, b) {
    var nameA = getAssetType({
      data: a,
      sortBy: sortBy,
      activityTypes: activityTypes
    });
    var nameB = getAssetType({
      data: b,
      sortBy: sortBy,
      activityTypes: activityTypes
    });
    return compareStrings(nameA, nameB);
  };
}
function sortAssociations(sortBy) {
  return function (a, b) {
    var nameA = prepareAssocitionName(a[sortBy]);
    var nameB = prepareAssocitionName(b[sortBy]);
    return nameA && nameB ? compareStrings(nameA, nameB) : !nameA - !nameB;
  };
}
function prepareAssocitionName(item) {
  var name = item.map(function (association) {
    return association.title;
  });
  return name !== null && name !== void 0 && name.length ? name.join(', ').toUpperCase() : '';
}
function getAssetType(_ref3) {
  var data = _ref3.data,
    activityTypes = _ref3.activityTypes,
    sortBy = _ref3.sortBy;
  if (data.activityTypeId) {
    return activityTypes.find(function (item) {
      return item.id === data.activityTypeId;
    }).label;
  }
  var string = data[sortBy].split('-');
  return string[string.length - 1];
}
function sortRismaTitleIA() {
  return function (a, b) {
    return compareStrings(a.title || a.name, b.title || b.name, false);
  };
}
function sortByPathStrings(sortBy) {
  return function (a, b) {
    return comparePathStrings(a[sortBy], b[sortBy]);
  };
}
function sortByPathProperty(sortBy) {
  return function (a, b) {
    return compareStrings(get(a, sortBy), get(b, sortBy), true);
  };
}
export function defaultSorting(sortBy) {
  var reverse = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function (a, b) {
    return compareStrings(a[sortBy], b[sortBy], reverse);
  };
}
function defaultSortingWithHtmlClear(sortBy) {
  var reverse = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function (a, b) {
    return compareStrings(a[sortBy] ? Utils.removeHtmlTags(a[sortBy]) : '', Utils.removeHtmlTags(b[sortBy] ? b[sortBy] : ''), reverse);
  };
}
function defaultSortingDesc(sortBy) {
  return defaultSorting(sortBy, true);
}
function sortByEntity(options) {
  return function (a, b) {
    var nameA = getEntityValue(a, options.key, options.data, options.propName)[0].toUpperCase();
    var nameB = getEntityValue(b, options.key, options.data, options.propName)[0].toUpperCase();
    return compareStrings(nameA, nameB);
  };
}
function sortByDateAsc(sortBy) {
  return function (a, b) {
    return compareDates(a[sortBy], b[sortBy], false);
  };
}
function sortByDateDesc(sortBy) {
  return function (a, b) {
    return compareDates(a[sortBy], b[sortBy], true);
  };
}
function getEntityValue(item, key, data, propName) {
  var ids = get(item, key);
  var names = Utils.findPropsByIds(Array.isArray(ids) ? ids : [ids], data, propName);
  return names.split(',');
}
export function sortNumbers(sortBy) {
  return function (a, b) {
    return compareNumbers(a[sortBy], b[sortBy]);
  };
}
export function sortArrayOfObjectsByArrayOfValues(arrayToSort, sortByArray) {
  var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'key';
  return _toConsumableArray(arrayToSort).sort(function (a, b) {
    var aIndex = sortByArray.indexOf(a[key]);
    var bIndex = sortByArray.indexOf(b[key]);
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });
}
export function sortArrayByAnotherArray(originalArray, sortOrder) {
  if (sortOrder.length > originalArray.length) throw Error('sortArrayByAnotherArray: originalArray can not be bigger than sortOrder');
  var sorter = function sorter(a, b) {
    if (sortOrder.indexOf(a) === -1) return 1;
    if (sortOrder.indexOf(b) === -1) return -1;
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  };
  return originalArray.sort(sorter);
}
export function sortControlsByCpath(controls) {
  return _toConsumableArray(controls).sort(function (a, b) {
    return comparePathStrings(a.cpath, b.cpath);
  });
}
export function sortAlphabetic(items, prop) {
  var reverse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var locales = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'en';
  return items.sort(function (a, b) {
    return compareStrings(a[prop], b[prop], reverse, locales);
  });
}
export function sortNumericByProp(items, prop) {
  var reversed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return items.sort(function (a, b) {
    return reversed ? b[prop] - a[prop] : a[prop] - b[prop];
  });
}
export function sortNumericByPropComplex(items, prop) {
  return items.sort(function (a, b) {
    if (!a[prop]) return 1;
    if (!b[prop]) return -1;
    return a[prop] - b[prop];
  });
}
export function sortByIndex(data, key) {
  return data.sort(function (a, b) {
    if (key) {
      a = a[key];
      b = b[key];
    }
    return comparePathStrings(a, b);
  });
}

/**
 *
 * @param data
 * @param {Array} sorts
 * @returns {*}
 */
export function sortByArrayOfFunctions(data, sorts) {
  var ascending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return data.sort(function (a, b) {
    for (var i = 0; i < sorts.length; i++) {
      var sortFunction = sorts[i];
      var compareResult = sortFunction(a, b);
      if (compareResult !== 0) {
        return compareResult * (ascending ? 1 : -1);
      }
    }
    return 0;
  });
}