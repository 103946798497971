function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getCVRCompany } from '@/api/external/cvrapi';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import InputField from '@/components/Atoms/Inputs/InputField';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import StaticTable from '@/components/Molecules/StaticTable';
import Notification from '@/components/Molecules/Notification';
import { VatCountryCode } from '@/constants/admin/VatCountryCode';
import { CompanyWizardFields, errorFields } from '@/constants/compliance/WizardFlow';
import { getCVRQueryParam } from '@/utils/format/companies';
export default {
  name: 'CompanyWizardStepCompanyCreation',
  introduction: 'component is used to create company in CompanyWizard manually or via vat number',
  token: '<company-wizard-step-company-creation />',
  components: {
    InputField: InputField,
    SingleSelect: SingleSelect,
    StaticTable: StaticTable,
    Notification: Notification,
    RismaSwitch: RismaSwitch
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    },
    errorMessage: {
      type: Object,
      default: function _default() {
        return {
          field: null,
          text: ''
        };
      }
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      CompanyWizardFields: CompanyWizardFields,
      countryCode: VatCountryCode.DK.value,
      foundCompanies: [],
      selectedCompanyIndex: null,
      errorFields: errorFields,
      manualData: {},
      debounceTimeout: 500,
      timer: null,
      createCompanyTimer: null,
      createCompanyErrorMessage: '',
      fieldMaxSymbols: 150,
      translate: getTranslate['CompanyWizardStepCompanyCreation']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isManualOnly: function isManualOnly() {
      return !!this.getSettingValue('app.company.vatlookup_disabled');
    },
    countryCodeOptions: function countryCodeOptions() {
      return Object.values(VatCountryCode).map(function (_ref) {
        var value = _ref.value;
        return {
          value: value,
          label: value
        };
      });
    },
    searchFieldLabel: function searchFieldLabel() {
      return this.isCountryCodeSE ? this.translate.searchingByNameIsNotAvailableForSeCompani : "".concat(this.translate.vatNumber, " ").concat(this.translate.orCompanyName);
    },
    isCountryCodeSE: function isCountryCodeSE() {
      return this.countryCode === VatCountryCode.SE.value;
    },
    companyDataset: function companyDataset() {
      return this.foundCompanies.map(function (item, index) {
        return {
          selected: index,
          name: item.name,
          address: item.address,
          city: item.city,
          vatNumber: item.vatNo
        };
      });
    },
    companyColumns: function companyColumns() {
      return [this.translate.choose, this.translate.title, this.translate.address, this.translate.city, this.translate.vatNumber];
    },
    switchOptions: function switchOptions() {
      return [{
        value: false
      }, {
        value: true
      }];
    },
    countryCodeRequiredText: function countryCodeRequiredText() {
      if (!this.manualData.vatNo || this.errorMessage.field !== errorFields.COUNTRY) return null;
      return this.errorMessage.text || '';
    }
  }),
  watch: {
    manualData: {
      deep: true,
      handler: function handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.onManualFieldChange, this.debounceTimeout);
      }
    },
    foundCompanies: function foundCompanies(newValue) {
      this.onFieldChange(newValue, CompanyWizardFields.FOUND_COMPANIES);
    },
    selectedCompanyIndex: function selectedCompanyIndex(newValue) {
      this.onFieldChange(newValue, CompanyWizardFields.SELECTED_COMPANY_IDX);
    }
  },
  beforeMount: function beforeMount() {
    var _this$selectedOptions;
    this.manualData = this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT] || {};
    this.foundCompanies = this.selectedOptions[CompanyWizardFields.FOUND_COMPANIES] || [];
    this.selectedCompanyIndex = (_this$selectedOptions = this.selectedOptions[CompanyWizardFields.SELECTED_COMPANY_IDX]) !== null && _this$selectedOptions !== void 0 ? _this$selectedOptions : null;
  },
  methods: {
    resetSearchResults: function resetSearchResults() {
      this.foundCompanies = [];
      this.selectedCompanyIndex = null;
    },
    searchCompany: function searchCompany() {
      var _this = this;
      if (!this.selectedOptions[CompanyWizardFields.SEARCH_FIELD_VALUE]) return;
      this.resetSearchResults();
      var params = getCVRQueryParam(this.countryCode);
      getCVRCompany(this.selectedOptions[CompanyWizardFields.SEARCH_FIELD_VALUE], params).then(function (_ref2) {
        var list = _ref2.list;
        _this.foundCompanies = list;
      }).catch(function (error) {
        if (error.status === 404) {
          _this.showCreateCompanyErrorNotification(_this.translate.noCompaniesFound);
        } else {
          _this.showCreateCompanyErrorNotification(_this.translate.anErrorOccurred);
        }
      });
    },
    showCreateCompanyErrorNotification: function showCreateCompanyErrorNotification(message) {
      var _this2 = this;
      this.createCompanyErrorMessage = message;
      clearTimeout(this.createCompanyTimer);
      this.createCompanyTimer = setTimeout(function () {
        _this2.createCompanyErrorMessage = '';
      }, this.$debounceTimeout);
    },
    onManualFieldChange: function onManualFieldChange() {
      this.onFieldChange(this.manualData, CompanyWizardFields.COMPANY_OBJECT);
    },
    onFieldChange: function onFieldChange(value, field) {
      this.$emit('update:selected', {
        value: value,
        field: field
      });
    }
  }
};