function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useWorkflowStore } from '@/Store/workflowStore';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { getUsersFromIds } from '@/utils/format/objectsFromIds';
import * as Utils from '@/utils/Utils';
import * as formatDate from '@/utils/date';
import { Colors } from '@/Colors';
import { MODULES } from '@/constants/modules';
export default {
  name: 'CardIncidents',
  components: {
    TrafficLight: TrafficLight,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  props: {
    incident: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'incident'
    },
    previewModal: {
      type: Boolean,
      required: false,
      default: false,
      note: 'To use PreviewModalLink instead of the router-link'
    }
  },
  emits: ['updateFromPreviewMode', 'onDismissModal'],
  data: function data() {
    return {
      colors: Colors,
      translate: getTranslate['CardIncidents']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useWorkflowStore, ['getActivityStatusTitle'])), {}, {
    hoverTitle: function hoverTitle() {
      if (this.incident.confidential) {
        return DatatableRenderer.getItemHoverTitle(this.incident);
      }
      return this.incident.title;
    },
    status: function status() {
      return this.getActivityStatusTitle(this.incident, MODULES.INCIDENTS);
    },
    createdWithLabel: function createdWithLabel() {
      return "".concat(this.translate.created, ": ").concat(formatDate.toLocalDateTime(this.incident.createdAt));
    },
    date: function date() {
      return formatDate.toLocalDateTime(this.incident.incidentHappenedAt) || this.translate.notSet;
    },
    archiveDate: function archiveDate() {
      if (!this.incident.archived) return null;
      return formatDate.toLocalDateTime(this.incident.updatedAt);
    },
    accountable: function accountable() {
      var users = getUsersFromIds(this.incident.accountableUserIds, this.users);
      if (!users.length) return '';
      return users.map(function (user) {
        return user.display_name;
      }).join(', ');
    },
    trafficLightColor: function trafficLightColor() {
      return this.getTrafficLightByObjectTypeAndNumber(this.incident.objType, this.incident.severity).color;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightByObjectTypeAndNumber'])), {}, {
    truncateHtml: function truncateHtml(text, maxSymbols) {
      return Utils.truncateHtml(text, maxSymbols, {
        html: true,
        maxLines: 3
      });
    }
  })
};