function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { addKitchenChildren } from '@/constants/dev/helper';
import KitchenSinkTabs from '@/constants/dev/kitchensink/Tabs';
import { KitchenAtomComponents } from '@/constants/dev/kitchensink/KitchenAtomComponents';
import * as AtomComponents from '@/components/Pages/Dev/KitchenSink/atoms';
import { KitchenMoleculeComponents } from '@/constants/dev/kitchensink/KitchenMoleculeComponents';
import * as MoleculeComponents from '@/components/Pages/Dev/KitchenSink/molecules';
import { KitchenOrganismComponents } from '@/constants/dev/kitchensink/KitchenOrganismComponents';
import * as OrganismComponents from '@/components/Pages/Dev/KitchenSink/organisms';
import { KitchenDesignComponents } from '@/constants/dev/kitchensink/KitchenDesignComponents';
import * as DesignComponents from '@/components/Pages/Dev/KitchenSink/design';
export default [{
  path: '/dev/kitchensink/atoms/',
  tabs: KitchenSinkTabs,
  listComponents: _toConsumableArray(KitchenAtomComponents).sort(),
  children: addKitchenChildren(KitchenAtomComponents, AtomComponents)
}, {
  path: '/dev/kitchensink/molecules/',
  tabs: KitchenSinkTabs,
  listComponents: _toConsumableArray(KitchenMoleculeComponents).sort(),
  children: addKitchenChildren(KitchenMoleculeComponents, MoleculeComponents)
}, {
  path: '/dev/kitchensink/organisms/',
  tabs: KitchenSinkTabs,
  listComponents: _toConsumableArray(KitchenOrganismComponents).sort(),
  children: addKitchenChildren(KitchenOrganismComponents, OrganismComponents)
}, {
  path: '/dev/kitchensink/design/',
  tabs: KitchenSinkTabs,
  listComponents: _toConsumableArray(KitchenDesignComponents).sort(),
  children: addKitchenChildren(KitchenDesignComponents, DesignComponents)
}];