import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
import { calcIncidentsRights } from '@/menus/quicknav';
function buildIncidentsMenu(settings, user) {
  var menuStructure = {
    main: [],
    admin: []
  };
  if (settings['feature.incidents.quick_create']) {
    menuStructure.main.push({
      title: 'Create incident',
      rights: true,
      isButton: true,
      icon: 'plus',
      action: 'openCreateIncident'
    });
  }
  menuStructure.main.push({
    url: '/incident/my',
    title: 'My incidents',
    rights: true,
    menuKey: MenuKeys.myIncidents,
    checkActiveSubItem: function checkActiveSubItem(url) {
      return url.startsWith('/incident/my');
    }
  }, {
    url: '/incident/list',
    title: 'Overview',
    rights: true,
    menuKey: MenuKeys.incidents
  }, {
    url: '/incident/archived',
    title: 'Archived incidents',
    rights: calcIncidentsRights(user, settings, UserLevels.SUPER),
    menuKey: MenuKeys.incidentsArchived
  }, {
    url: '/incident/report',
    title: 'Reports',
    exact: true,
    rights: true,
    menuKey: MenuKeys.reports
  }, {
    url: '/incident/dashboard',
    title: 'Dashboard',
    rights: true
  });
  return menuStructure;
}
export default buildIncidentsMenu;