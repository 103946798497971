function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getSiemSetups as _getSiemSetups, createSiemSetup, deleteSiemSetup as _deleteSiemSetup, patchSiemSetup } from '@/api/admin/admin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Notification from '@/components/Molecules/Notification';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { SiemConnectionLabels, SiemConnectionTypes } from '@/constants/admin/Siem';
import { toLocalDateTime } from '@/utils/date';
export default {
  name: 'SiemControlPanel',
  components: {
    InputField: InputField,
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Notification: Notification,
    ButtonsRow: ButtonsRow
  },
  token: '<siem-control-page />',
  data: function data() {
    return {
      loading: false,
      selectedSiemTransferId: null,
      siemTransfer: null,
      errorMessage: null,
      pathHint: '/data/export/risma/logs',
      siemSetups: [],
      translate: getTranslate['SiemControlPanel']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    onPremEnabled: function onPremEnabled() {
      return !!this.getSettingValue('feature.admin.siem_onprem_enabled');
    },
    transferLatestLog: function transferLatestLog() {
      var _this$siemTransfer;
      var latestLog = (_this$siemTransfer = this.siemTransfer) === null || _this$siemTransfer === void 0 ? void 0 : _this$siemTransfer.latestLog;
      if (!latestLog) return null;
      return {
        timestamp: toLocalDateTime(latestLog.createdAt),
        status: latestLog.success,
        output: latestLog.output.split('\n')
      };
    },
    transferOptions: function transferOptions() {
      var setups = this.siemSetups;
      if (!this.onPremEnabled) {
        setups = setups.filter(function (setup) {
          return setup.connectionType !== SiemConnectionTypes.LOCAL;
        });
      }
      return setups.map(function (setup) {
        return {
          value: setup.id,
          label: "".concat(SiemConnectionLabels[setup.connectionType], ": ").concat(setup.server || setup.path)
        };
      });
    },
    connectionOptions: function connectionOptions() {
      var options = [{
        value: SiemConnectionTypes.SFTP,
        label: SiemConnectionLabels[SiemConnectionTypes.SFTP]
      }];
      if (this.onPremEnabled) {
        options.push({
          value: SiemConnectionTypes.LOCAL,
          label: SiemConnectionLabels[SiemConnectionTypes.LOCAL]
        });
      }
      return options;
    },
    showLoginFields: function showLoginFields() {
      return this.siemTransfer.connectionType && this.siemTransfer.connectionType === SiemConnectionTypes.SFTP;
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.selectedSiemTransferId ? this.translate.delete : this.translate.cancel,
        type: 'danger',
        clickEvent: this.onDeleteSiemSetting
      }, {
        text: this.selectedSiemTransferId ? this.translate.save : this.translate.create,
        type: 'save',
        clickEvent: this.saveSiemSetting
      }];
    }
  }),
  watch: {
    selectedSiemTransferId: function selectedSiemTransferId(selectedId) {
      if (!selectedId) return;
      var setup = this.siemSetups.find(function (setup) {
        return +setup.id === +selectedId;
      });
      this.siemTransfer = setup ? _objectSpread({}, setup) : null;
    }
  },
  beforeMount: function beforeMount() {
    this.getSiemSetups();
  },
  methods: {
    getSiemSetups: function getSiemSetups() {
      var _this = this;
      this.loading = true;
      return _getSiemSetups().then(function (_ref) {
        var list = _ref.list;
        _this.siemSetups = list;
      }).finally(function () {
        return _this.loading = false;
      });
    },
    createNewTransfer: function createNewTransfer() {
      var _this$connectionOptio;
      var connectionType = ((_this$connectionOptio = this.connectionOptions[0]) === null || _this$connectionOptio === void 0 ? void 0 : _this$connectionOptio.value) || null;
      this.selectedSiemTransferId = null;
      this.siemTransfer = {
        active: 0,
        connectionType: connectionType,
        username: '',
        server: '',
        password: '',
        path: '',
        enableSuccessfulLogins: 0,
        enableFailedLogins: 0,
        enableUserChangeLog: 0
      };
    },
    onDeleteSiemSetting: function onDeleteSiemSetting() {
      var _this2 = this;
      if (!this.selectedSiemTransferId) {
        this.siemTransfer = null;
        return;
      }
      this.$confirm(this.translate.deleteSiemSetup, this.translate.areYouSureYouWantToDeleteThisSiem, function (res) {
        return res && _this2.deleteSiemSetup();
      });
    },
    deleteSiemSetup: function deleteSiemSetup() {
      var _this3 = this;
      this.loading = true;
      return _deleteSiemSetup(this.selectedSiemTransferId).then(function () {
        _this3.siemSetups = _this3.siemSetups.filter(function (setup) {
          return setup.id !== _this3.selectedSiemTransferId;
        });
        _this3.selectedSiemTransferId = null;
        _this3.siemTransfer = null;
        _this3.$notify({
          title: _this3.translate.theSiemSetupIsDeleted
        });
      }).finally(function () {
        _this3.loading = false;
      });
    },
    saveSiemSetting: function saveSiemSetting() {
      var _this4 = this;
      if (!this.validateSetup()) return;
      this.loading = true;
      var payload = _objectSpread({}, this.siemTransfer);
      if (!this.showLoginFields) {
        delete payload.username;
        delete payload.password;
        delete payload.server;
      }
      if (this.selectedSiemTransferId) {
        return patchSiemSetup(this.selectedSiemTransferId, payload).then(function (updatedTransfer) {
          var idx = _this4.siemSetups.findIndex(function (setup) {
            return setup.id === _this4.selectedSiemTransferId;
          });
          _this4.siemSetups[idx] = updatedTransfer;
          _this4.siemTransfer = updatedTransfer;
          _this4.$notify({
            title: _this4.translate.siemSetupIsUpdated
          });
        }).finally(function () {
          return _this4.loading = false;
        });
      }
      return createSiemSetup(payload).then(function (newSetup) {
        _this4.selectedSiemTransferId = newSetup.id;
        _this4.siemSetups.push(newSetup);
        _this4.$notify({
          title: _this4.translate.siemSetupIsCreated
        });
      }).finally(function () {
        return _this4.loading = false;
      });
    },
    validateSetup: function validateSetup() {
      this.errorMessage = null;
      if (!this.siemTransfer.path) {
        this.errorMessage = this.translate.pleaseFillOutAllRequiredFields;
        return false;
      }
      if (this.showLoginFields) {
        if (!this.siemTransfer.username || !this.siemTransfer.password || !this.siemTransfer.server) {
          this.errorMessage = this.translate.pleaseFillOutAllRequiredFields;
          return false;
        }
      }
      var PathRegex = /^\/([a-zA-Z0-9_ -]+\/{0,1})*/g;
      var matchResult = this.siemTransfer.path.match(PathRegex);
      var atLeastOneEnabled = [this.siemTransfer.enableSuccessfulLogins, this.siemTransfer.enableFailedLogins, this.siemTransfer.enableUserChangeLog];
      if (!atLeastOneEnabled.includes(1)) {
        this.errorMessage = this.translate.pleaseSelectAtLeastOneLogFileYouWithToExport;
        return false;
      }
      if (!matchResult || matchResult[0].length !== this.siemTransfer.path.length) {
        this.errorMessage = this.translate.pleaseCorrectTheErrorsInThePathString;
        return false;
      }
      return true;
    }
  }
};