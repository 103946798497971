function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useAIAssistant } from '@/Store/aiAssistantStore';
import emitter from 'tiny-emitter/instance';
import AiButtonWithTooltip from '@/components/Organisms/AiAssistant/AiButtonWithTooltip';
import NotificationsModal from '@/components/Molecules/Modal/NotificationsModal';
import { getUserById, getWorkflowStatusesByModule } from '@/api/risma/data';
import getUtilSettings from '@/api/risma/settings';
import { getAllActionMainReport } from '@/api/execution/initiative';
import { getControlsReports } from '@/api/controls/controls';
import { getReports as getRiskReports } from '@/api/risk/risk';
import { getAll as getIA } from '@/api/compliance/informationAssets';
import * as ProcessTreeApi from '@/api/compliance/processtree';
import { searchReports } from '@/api/compliance/reports/nodes';
import { getAll as getIncidents } from '@/api/incident/incident';
import { getContracts } from '@/api/contracts/contracts';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SearchModal from '@/components/Molecules/Modal/SearchModal';
import { SettingsNav } from '@/constants/menu/navigations';
import { UserLevels } from '@/constants/UserLevels';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { ColorSettings } from '@/constants/admin/CustomizationSettings';
import { userHasSomeProjectAccess } from '@/utils/access';
import { removeDuplicates } from '@/utils/filterArray';
import { clickOutside } from '@/utils/directives/clickOutside';
import { getReleaseNotesLink } from '@/utils/rismaVersion';
import { useMainStore } from '@/Store/mainStore';
import { TOOLTIP_MODES } from '@/constants/Tooltips';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'UserMenu',
  description: 'User menu placed in the top right corner of the chanel menu bar',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    FeatherIcon: FeatherIcon,
    NotificationsModal: NotificationsModal,
    SearchModal: SearchModal,
    AiButtonWithTooltip: AiButtonWithTooltip
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
      note: 'the id to get all the data about user'
    },
    oldVersion: {
      type: Boolean,
      required: false,
      default: true,
      note: 'User-menu contains three icons, uses font awesome icons'
    },
    notifications: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of notifications that user has'
    },
    scoring: {
      type: Object,
      default: function _default() {},
      note: 'scoring for search modal'
    },
    organisations: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Risma organisations for search modal'
    },
    users: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of users for search modal'
    },
    activityTypes: {
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'Array of activity types for search modal (systems)'
    },
    colorSettings: {
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Look and feel color settings'
    }
  },
  emits: ['markAllNotificationsAsRead', 'clickNotification', 'showSearchChanged'],
  setup: function setup() {
    return {
      aiNewFeature: getLocalStorageComputed('ai-new-feature', true, true)
    };
  },
  data: function data() {
    return {
      isUserSectionExpanded: false,
      isSearchToggled: false,
      user: {},
      loaded: false,
      settings: {},
      avatarChanged: false,
      now: '',
      isSearchShown: false,
      searchField: '',
      isSearchLoaded: true,
      showNotifications: false,
      showSearchModal: false,
      searchDataset: {},
      SettingsNav: SettingsNav,
      workflowStatuses: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    aiAssistantEnabled: 'aiAssistantEnabled'
  })), mapState(useUserStore, {
    usersComplianceProjects: 'complianceProjects',
    currentUser: 'currentUser',
    userHasAccessLevel: 'userHasAccessLevel'
  })), {}, {
    whatsNewLink: function whatsNewLink() {
      return getReleaseNotesLink(this.getSettingValue('app.version'));
    },
    menuTitles: function menuTitles() {
      return {
        general: this.$trans(SettingsNav.general.title),
        actionsSettings: this.$trans(SettingsNav.actionsSettings.title),
        contract: this.$trans(SettingsNav.contract.title),
        controlsSettings: this.$trans(SettingsNav.controlsSettings.title),
        complianceSettings: this.$trans(SettingsNav.complianceSettings.title),
        riskSettings: this.$trans(SettingsNav.riskSettings.title),
        incident: this.$trans(SettingsNav.incident.title),
        appSettings: this.$trans(SettingsNav.appSettings.title)
      };
    },
    riskPeriodUrl: function riskPeriodUrl() {
      var project = userHasSomeProjectAccess(this.currentUser, ObjectTypes.RISK_PROJECT, UserLevels.SUPER);
      if (!project) return '';
      return "".concat(SettingsNav.riskPeriod.path, "/").concat(project.id);
    },
    getIcon: function getIcon() {
      if (!this.showAvatar) return '';
      var append = '';
      if (this.now !== '') {
        append = "?".concat(this.now);
      }
      return "/api/2.0/data/userprofileimages/".concat(this.user.id).concat(append);
    },
    getAvatar: function getAvatar() {
      var text = this.user.fullname.toUpperCase().split(' ');
      return text[1] ? text[0][0] + text[1][0] : text[0][0];
    },
    showAvatar: function showAvatar() {
      return this.user.userHasProfileImage || this.avatarChanged;
    },
    unreadNotificationsCount: function unreadNotificationsCount() {
      var count = 0;
      if (this.notifications && this.notifications.length) {
        count = this.notifications.reduce(function (counter, notification) {
          counter += +!notification.read;
          return counter;
        }, 0);
      }
      return count;
    },
    isPerformanceReportEnabled: function isPerformanceReportEnabled() {
      return !!this.currentUser.performance_report_access && !!this.getSettingValue('feature.performance_report_enabled');
    },
    settingsRights: function settingsRights() {
      return {
        general: SettingsNav.controlPanel.rights(this.user, this.settings),
        actions: SettingsNav.actionsSettings.rights(this.user, this.settings),
        contracts: SettingsNav.contractsControlPanel.rights(this.user, this.settings),
        controls: SettingsNav.controlsSettings.rights(this.user, this.settings),
        compliance: SettingsNav.complianceControlPanel.rights(this.user, this.settings),
        risk: SettingsNav.riskSettings.rights(this.user, this.settings),
        incident: SettingsNav.incident.rights(this.user, this.settings),
        app: SettingsNav.appSettings.rights(this.user, this.settings)
      };
    },
    showSettingsMenuItems: function showSettingsMenuItems() {
      return Object.values(this.settingsRights).some(function (item) {
        return !!item;
      });
    },
    translate: function translate() {
      return {
        search: this.$trans('Search'),
        personalSettings: this.$trans('Personal settings'),
        whatsNew: this.$trans("What's new"),
        logout: this.$trans('Logout')
      };
    },
    hoverTextColor: function hoverTextColor() {
      return this.colorSettings['--settingsTextColor'] !== ColorSettings.settingsTextColor.default ? '' : 'hover:text-white';
    },
    tooltipMode: function tooltipMode() {
      return this.aiNewFeature ? TOOLTIP_MODES.PRIMARY : TOOLTIP_MODES.SECONDARY;
    }
  }),
  watch: {
    isSearchShown: function isSearchShown() {
      this.$emit('showSearchChanged');
    },
    $route: function $route(newValue, oldValue) {
      if (newValue.fullPath !== oldValue.fullPath && oldValue.fullPath !== '/') {
        this.aiNewFeature = false;
      }
    }
  },
  mounted: function mounted() {
    var promises = [getUtilSettings().then(this.setSettings), getUserById(this.id).then(this.setUser)];
    return Promise.all(promises).then(this.setMenuLoaded);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useMainStore, ['closeAllPreviews'])), mapActions(useAIAssistant, ['openAIAssistant'])), {}, {
    toggleNotifications: function toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    toggleUserSection: function toggleUserSection() {
      this.isUserSectionExpanded = !this.isUserSectionExpanded;
    },
    hide: function hide() {
      this.isUserSectionExpanded = false;
      this.isSearchShown = false;
      this.searchField = '';
    },
    hideNotifications: function hideNotifications() {
      this.showNotifications = false;
    },
    emitSearch: function emitSearch() {
      emitter.emit('user-search');
    },
    showSearchBar: function showSearchBar() {
      var _this = this;
      this.isSearchShown = true;
      if (this.searchField && this.isSearchShown) {
        this.onSearch();
      } else {
        this.$nextTick(function () {
          return _this.$refs.globalSearch.focus();
        });
      }
    },
    onSearch: function onSearch() {
      var _this2 = this;
      if (this.searchField.length < 3 || !this.isSearchLoaded) {
        return;
      }
      this.isSearchLoaded = false;
      this.searchRequest(this.searchField).then(function () {
        _this2.isSearchLoaded = true;
        _this2.searchField = '';
        _this2.isSearchShown = false;
        _this2.closeAllPreviews();
        _this2.showSearchModal = true;
      }).catch(function () {
        _this2.isSearchLoaded = true;
      });
    },
    searchRequest: function searchRequest(searchText) {
      this.searchDataset = {};
      var promises = [this.handleGetComplianceNodes(searchText)];
      if (this.userHasAccessLevel('level_risk', UserLevels.NORMAL)) promises.push(this.handleGetRisks(searchText));
      if (this.userHasAccessLevel('level_execution', UserLevels.NORMAL)) promises.push(this.handleGetActions(searchText));
      if (this.userHasAccessLevel('level_controls', UserLevels.NORMAL)) promises.push(this.handleGetControls(searchText));
      if (this.userHasAccessLevel('level_process_library', UserLevels.NORMAL)) promises.push(this.handleGetProcessNodes(searchText));
      if (this.userHasAccessLevel('level_information_assets', UserLevels.NORMAL)) promises.push(this.handleGetIA(searchText));
      if (this.userHasAccessLevel('level_incidents', UserLevels.NORMAL)) promises.push(this.handleGetIncidents(searchText));
      if (this.userHasAccessLevel('level_contracts', UserLevels.NORMAL)) {
        promises.push(this.handleGetContracts(searchText), this.getWorkflowStatuses());
      }
      return Promise.all(promises);
    },
    handleGetIncidents: function handleGetIncidents(searchText) {
      var _this3 = this;
      return getIncidents().then(function (response) {
        var searchBy = ['title'];
        _this3.searchDataset.incidents = _this3.getFilteredByProps(response, searchText, searchBy);
      });
    },
    handleGetRisks: function handleGetRisks(searchText) {
      var _this4 = this;
      var params = "?filters[titleLike]=".concat(searchText, "&data[]=customFields");
      return getRiskReports(params).then(function (response) {
        return _this4.searchDataset.risks = removeDuplicates(response.list, 'url');
      });
    },
    handleGetActions: function handleGetActions(searchText) {
      var _this5 = this;
      var params = "?filters[titleLike]=".concat(searchText);
      return getAllActionMainReport(params).then(function (response) {
        return _this5.searchDataset.initiatives = response.list;
      });
    },
    handleGetProcessNodes: function handleGetProcessNodes(searchText) {
      var _this6 = this;
      var params = "&filters[titleLike]=".concat(searchText);
      return ProcessTreeApi.getAllTrees().then(function (response) {
        if (!(response[0] && response[0].id)) {
          return Promise.resolve({
            list: []
          });
        }
        return ProcessTreeApi.getReportById(response[0].id, params).then(function (_ref) {
          var list = _ref.list;
          _this6.searchDataset.processTreeNodes = list;
        });
      });
    },
    handleGetComplianceNodes: function handleGetComplianceNodes(searchText) {
      var _this7 = this;
      var promises = [];
      this.searchDataset.processes = [];
      var filteredComplianceUsersProjects = this.usersComplianceProjects.filter(function (project) {
        return project.access_level > UserLevels.NONE;
      });
      filteredComplianceUsersProjects.forEach(function (project) {
        promises.push(_this7.getComplianceNodesForOneProject(searchText, project));
      });
      return Promise.all(promises);
    },
    getComplianceNodesForOneProject: function getComplianceNodesForOneProject(searchText, project) {
      var _this8 = this;
      return searchReports(project.id, "?filters[title]=".concat(searchText)).then(function (_ref2) {
        var list = _ref2.list;
        var expandedListWithProjects = list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            project: {
              title: project.title
            }
          });
        });
        _this8.searchDataset.processes = _this8.searchDataset.processes.concat(expandedListWithProjects);
      });
    },
    handleGetIA: function handleGetIA(searchText) {
      var _this9 = this;
      return getIA().then(function (_ref3) {
        var list = _ref3.list;
        var searchBy = ['title'];
        _this9.searchDataset.informationAssets = _this9.getFilteredByProps(list, searchText, searchBy);
      });
    },
    getFilteredByProps: function getFilteredByProps(items, searchText) {
      var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ['title'];
      return items.filter(function (item) {
        var lowerSearchText = searchText.toLowerCase();
        return props.some(function (property) {
          return item[property] && item[property].toLowerCase().includes(lowerSearchText);
        });
      });
    },
    handleGetControls: function handleGetControls(searchText) {
      var _this10 = this;
      var params = "?filters[titleLike]=".concat(searchText, "&data[]=organisationIds");
      return getControlsReports(params).then(function (response) {
        return _this10.searchDataset.controls = removeDuplicates(response.list, 'url');
      });
    },
    handleGetContracts: function handleGetContracts(searchText) {
      var _this11 = this;
      return getContracts().then(function (_ref4) {
        var list = _ref4.list;
        var searchBy = ['title', 'uniqueId'];
        _this11.searchDataset.contracts = _this11.getFilteredByProps(list, searchText, searchBy);
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this12 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref5) {
        var list = _ref5.list;
        _this12.workflowStatuses = list;
      });
    },
    toggleSearch: function toggleSearch() {
      this.showSearchModal = !this.showSearchModal;
    },
    setSettings: function setSettings(response) {
      var _this13 = this;
      response.list.forEach(function (setting) {
        return _this13.settings[setting.settingname] = setting.value;
      });
    },
    setUser: function setUser(response) {
      this.user = response;
    },
    setMenuLoaded: function setMenuLoaded() {
      this.loaded = true;
    }
  })
};