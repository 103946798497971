function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import { formatNodes } from '@/utils/format/Compliance';
export default {
  name: 'ProcessLibraryChoice',
  introduction: 'ProcessLibraryChoice is a RismaSelect component',
  description: 'Ability to select multiple values from a drop-down to get links with other nodes',
  components: {
    RismaSelect: RismaSelect
  },
  props: {
    nodeElement: {
      required: true,
      type: [Object, Array],
      note: 'A list of all nodes',
      default: function _default() {
        return {};
      }
    },
    selected: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'a list of selected nodes'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'is be read-only'
    }
  },
  emits: ['forceUpdate', 'answered'],
  data: function data() {
    return {
      selectedOptions: [],
      values: [],
      onlyMyValues: [],
      translate: getTranslate['ProcessLibraryChoice']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    onlyMyProccessLibraryChoices: function onlyMyProccessLibraryChoices() {
      return this.getSettingValue('feature.pl_nodes_limit_access_in_question');
    },
    myProcessLibraryIds: function myProcessLibraryIds() {
      return this.onlyMyValues.map(function (node) {
        return node.id;
      });
    },
    selectedByOthers: function selectedByOthers() {
      var _this = this;
      if (!this.onlyMyProccessLibraryChoices) return [];
      return this.selected.filter(function (item) {
        return !_this.myProcessLibraryIds.includes(item.id);
      });
    },
    selectedByOthersDisplay: function selectedByOthersDisplay() {
      return this.selectedByOthers.map(function (item) {
        return item.title;
      }).join(', ');
    },
    nodesOptions: function nodesOptions() {
      var _this2 = this;
      if (!this.onlyMyProccessLibraryChoices) return this.values;
      return this.values.filter(function (item) {
        return _this2.myProcessLibraryIds.includes(item.id);
      });
    },
    nodesSelected: function nodesSelected() {
      var _this3 = this;
      if (!this.onlyMyProccessLibraryChoices) return this.selectedOptions;
      return this.selectedOptions.filter(function (item) {
        return _this3.myProcessLibraryIds.includes(item.id);
      });
    }
  }),
  created: function created() {
    this.getAllNodesInValues(this.nodeElement);
    this.choosePreselected();
  },
  methods: {
    //to get throught all children from Policies Root Node and dont push Root node title
    getAllNodesInValues: function getAllNodesInValues(nodeObject) {
      var _this4 = this;
      if (!nodeObject.children) return;
      formatNodes(nodeObject.children).forEach(function (element) {
        _this4.getInsideObjectNodes(element);
      });
    },
    getInsideObjectNodes: function getInsideObjectNodes(nodeObject) {
      var _this5 = this;
      this.pushElement(nodeObject.formattedTitle, nodeObject.id, this.onlyMyProccessLibraryChoices && nodeObject.checkaccess);
      if (nodeObject.children) {
        nodeObject.children.forEach(function (element) {
          _this5.getInsideObjectNodes(element);
        });
      }
    },
    pushElement: function pushElement(option, id, access) {
      var object = {
        id: id,
        option: option
      };
      if (access) {
        this.onlyMyValues.push(object);
      }
      this.values.push(object);
    },
    emitChanges: function emitChanges(newVal) {
      var node = {};
      var newValWithSelectedByOthers = [].concat(_toConsumableArray(newVal), _toConsumableArray(this.selectedByOthers));
      if (newValWithSelectedByOthers !== null) {
        newValWithSelectedByOthers.forEach(function (v) {
          node[v.id] = v.option;
        });
      }
      this.$emit('answered', node);
    },
    choosePreselected: function choosePreselected() {
      var selected = this.selected.map(function (s) {
        return +s.id;
      });
      this.selectedOptions = this.values.filter(function (v) {
        return selected.includes(+v.id);
      });
    }
  }
};