import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = ["id", "type", "placeholder", "required", "disabled", "maxlength", "max", "min", "autocomplete", "step", "tabindex", "accept", "title"];
var _hoisted_3 = {
  key: 1,
  class: "absolute top-3 right-4 translate-y-[-2px]"
};
var _hoisted_4 = {
  key: 0,
  class: "text-rm-signal-red"
};
var _hoisted_5 = {
  key: 1,
  class: "text-rm-signal-grey-dark"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  var _directive_focus = _resolveDirective("focus");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    for: $options.id,
    class: _normalizeClass(["mb-1 w-fit", {
      'cursor-pointer': $props.enabled
    }])
  }, null, 8 /* PROPS */, ["title", "for", "class"])) : _createCommentVNode("v-if", true), !$props.enabled ? _withDirectives((_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["grid grid-cols-[auto_auto] gap-x-2 w-full min-h-11 max-h-[144px] overflow-y-auto px-4 py-2 border rounded-md border-rm-grey bg-rm-wisp", {
      'absolute z-2 shadow-rm-grey--20': $data.showDropdown
    }]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"]))
  }, [_createElementVNode("p", {
    ref: "disabledBox",
    class: _normalizeClass(["py-px glob-b1 text-rm-text cursor-default", {
      'text-rm-text': $data.rawText,
      'text-rm-urban': !$data.rawText,
      truncate: !$data.showDropdown
    }]),
    style: {
      "word-break": "break-word"
    },
    title: $data.rawText
  }, _toDisplayString($options.disabledText), 11 /* TEXT, CLASS, PROPS */, _hoisted_1), $data.isTruncated ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "chevron-down",
    class: _normalizeClass(["sticky top-1 stroke-2 text-rm-grey hover:text-rm-text transform duration-500 cursor-pointer", {
      'rotate-180 text-rm-text': $data.showDropdown
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDropdown = !$data.showDropdown;
    })
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)), [[_directive_click_outside, $options.closeDropdown]]) : (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(["input-field relative", {
      'h-11 flex items-center': $props.isStaticHeight
    }])
  }, [_withDirectives(_createElementVNode("input", {
    id: $options.id,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.rawText = $event;
    }),
    type: $props.type,
    placeholder: $props.placeholder,
    required: $props.required,
    disabled: !$props.enabled,
    maxlength: $props.maxlength,
    max: $props.max,
    min: $props.min,
    autocomplete: $props.autocomplete,
    step: $props.step,
    class: _normalizeClass([$options.baseStyles, $options.interactionStyles, $props.externalClasses]),
    style: _normalizeStyle($props.externalStyles),
    tabindex: $props.tabindex,
    accept: $props.accept,
    title: $props.type !== 'password' ? $data.rawText : '',
    onKeypress: _cache[3] || (_cache[3] = function () {
      return $options.onKeyPress && $options.onKeyPress.apply($options, arguments);
    }),
    onInput: _cache[4] || (_cache[4] = function () {
      return $options.emit && $options.emit.apply($options, arguments);
    }),
    onFocus: _cache[5] || (_cache[5] = function () {
      return $options.onFocus && $options.onFocus.apply($options, arguments);
    }),
    onClick: _cache[6] || (_cache[6] = function () {
      return $options.onClick && $options.onClick.apply($options, arguments);
    }),
    onBlur: _cache[7] || (_cache[7] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    }),
    onKeyup: [_cache[8] || (_cache[8] = _withKeys(_withModifiers(function () {
      return $options.onEnter && $options.onEnter.apply($options, arguments);
    }, ["stop"]), ["enter"])), _cache[9] || (_cache[9] = _withKeys(function () {
      return $options.onEsc && $options.onEsc.apply($options, arguments);
    }, ["esc"]))]
  }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_2), [[_vModelDynamic, $data.rawText, void 0, {
    trim: true
  }], [_directive_focus, $props.focusOnMount]]), $props.enabled && $data.rawText && $props.withReset ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "x",
    tabindex: $props.tabindex,
    class: _normalizeClass(["cursor-pointer text-rm-grey hover:text-rm-black hover:rounded-sm hover:bg-rm-grey-light active:text-rm-active-blue stroke-2 absolute top-3", $props.showPercentage ? 'right-8' : 'right-4']),
    onMousedown: _withModifiers($options.onClearIconClick, ["prevent", "stop"]),
    onKeyup: _withKeys(_withModifiers($options.onClearIconClick, ["stop"]), ["enter"])
  }, null, 8 /* PROPS */, ["tabindex", "class", "onMousedown", "onKeyup"])) : _createCommentVNode("v-if", true), $props.showPercentage ? (_openBlock(), _createElementBlock("span", _hoisted_3, "%")) : _createCommentVNode("v-if", true), $options.showCounterLimit ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    ref: "counterLimit",
    class: "absolute right-0 glob-l2 mt-1 text-rm-signal-grey-dark"
  }, _toDisplayString($data.rawText.length) + "/" + _toDisplayString($props.maxlength), 513 /* TEXT, NEED_PATCH */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)), $options.showHelpTextContainer ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "glob-l1 mt-1",
    style: _normalizeStyle($options.helpTextExtraStyles)
  }, [$props.errorHelpText && $props.invalid ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.errorHelpText), 1 /* TEXT */)) : $props.helpText ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.helpText), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 4 /* STYLE */)) : _createCommentVNode("v-if", true)]);
}