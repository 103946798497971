function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TagItem from '@/components/Atoms/Containers/TagItem';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import * as Utils from '@/utils/Utils';
import { getAssociationName } from '@/utils/controls/associations';
export default {
  name: 'ControlFilters',
  description: '',
  token: '<control-filters />',
  components: {
    RismaToggle: RismaToggle,
    RismaTitle: RismaTitle,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    TagItem: TagItem,
    FrameworksSelector: FrameworksSelector
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    stateLocal: {
      type: String,
      required: false,
      default: ''
    },
    headerStyles: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue', 'groupControls', 'resetFilters'],
  data: function data() {
    return {
      searchTimeout: null,
      translate: getTranslate['ControlFilters']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showOverviewOptionsTitle: function showOverviewOptionsTitle() {
      return this.$trans("".concat(this.modelValue.showOverviewOptions ? 'Hide' : 'Show', " overview options"));
    },
    accountTitle: function accountTitle() {
      return getAssociationName(this.getSettingValue('risma.account_to_name'), 'Accounts');
    },
    processesTitle: function processesTitle() {
      return getAssociationName(this.getSettingValue('risma.process_to_name'), 'Processes');
    },
    risksTitle: function risksTitle() {
      return getAssociationName(this.getSettingValue('risma.risk_to_name'), 'Control risks');
    },
    appliedFilters: function appliedFilters() {
      var _this$modelValue$acti, _this$modelValue$orga, _this$modelValue$tags, _this$modelValue$resp, _this$modelValue$acco, _this$modelValue$proc, _this$modelValue$fram, _this$modelValue$risk;
      var result = [];
      if ((_this$modelValue$acti = this.modelValue.activityTypes) !== null && _this$modelValue$acti !== void 0 && _this$modelValue$acti.length) result.push({
        key: 'activityTypes',
        title: this.translate.activityType
      });
      if (this.modelValue.deadline !== null) result.push({
        key: 'deadline',
        title: this.translate.deadline
      });
      if ((_this$modelValue$orga = this.modelValue.organisations) !== null && _this$modelValue$orga !== void 0 && _this$modelValue$orga.length) result.push({
        key: 'organisations',
        title: this.translate.organisations
      });
      if (this.modelValue.mother) result.push({
        key: 'mother',
        title: this.translate.motherControls
      });
      if ((_this$modelValue$tags = this.modelValue.tags) !== null && _this$modelValue$tags !== void 0 && _this$modelValue$tags.length) result.push({
        key: 'tags',
        title: this.translate.tags
      });
      if ((_this$modelValue$resp = this.modelValue.responsible) !== null && _this$modelValue$resp !== void 0 && _this$modelValue$resp.length) result.push({
        key: 'responsible',
        title: this.translate.responsible
      });
      if ((_this$modelValue$acco = this.modelValue.accounts) !== null && _this$modelValue$acco !== void 0 && _this$modelValue$acco.length) result.push({
        key: 'accounts',
        title: this.accountTitle
      });
      if ((_this$modelValue$proc = this.modelValue.processes) !== null && _this$modelValue$proc !== void 0 && _this$modelValue$proc.length) result.push({
        key: 'processes',
        title: this.processesTitle
      });
      if ((_this$modelValue$fram = this.modelValue.frameworks) !== null && _this$modelValue$fram !== void 0 && _this$modelValue$fram.length) result.push({
        key: 'frameworks',
        title: this.translate.framework
      });
      if ((_this$modelValue$risk = this.modelValue.risks) !== null && _this$modelValue$risk !== void 0 && _this$modelValue$risk.length) result.push({
        key: 'risks',
        title: this.risksTitle
      });
      if (this.modelValue.search) result.push({
        key: 'search',
        title: this.translate.search
      });
      if (this.modelValue.showDeactivated) result.push({
        key: 'showDeactivated',
        title: this.translate.showDeactivatedControls
      });
      return result;
    },
    showAppliedFilters: function showAppliedFilters() {
      return this.appliedFilters.length && !this.modelValue.showOverviewOptions;
    }
  }),
  mounted: function mounted() {
    this.setFiltersFromState();
  },
  methods: {
    filterByEntity: function filterByEntity(e, entity) {
      var _this = this;
      this.$emit('update:modelValue', _objectSpread(_objectSpread({}, this.modelValue), {}, _defineProperty({}, entity, e)));
      this.$nextTick(function () {
        _this.saveState();
      });
    },
    prepareState: function prepareState() {
      var _this$data$accounts, _this$modelValue$acco2, _this$data$processes, _this$modelValue$proc2, _this$data$frameworks, _this$modelValue$fram2, _this$data$risks, _this$modelValue$risk2;
      var result = {
        organisations: this.modelValue.organisations.map(function (item) {
          return item.id;
        }),
        tags: this.modelValue.tags.map(function (item) {
          return item.id;
        }),
        mother: this.modelValue.mother,
        showOverviewOptions: this.modelValue.showOverviewOptions
      };
      if (this.data.activityTypes) result.activityTypes = this.modelValue.activityTypes.map(function (item) {
        return item.id;
      });
      if (this.data.users) result.responsible = this.modelValue.responsible.map(function (item) {
        return item.id;
      });
      if (this.data.showDeactivated) result.showDeactivated = this.modelValue.showDeactivated;
      if (this.data.deadline !== null) result.deadline = this.modelValue.deadline;
      if (this.data.groupByOptions) result.groupBy = this.modelValue.groupBy;
      if (this.data.sortByOptions) result.sortBy = this.modelValue.sortBy;
      if ((_this$data$accounts = this.data.accounts) !== null && _this$data$accounts !== void 0 && _this$data$accounts.length) result.accounts = ((_this$modelValue$acco2 = this.modelValue.accounts) === null || _this$modelValue$acco2 === void 0 ? void 0 : _this$modelValue$acco2.map(function (item) {
        return item.id;
      })) || [];
      if ((_this$data$processes = this.data.processes) !== null && _this$data$processes !== void 0 && _this$data$processes.length) result.processes = ((_this$modelValue$proc2 = this.modelValue.processes) === null || _this$modelValue$proc2 === void 0 ? void 0 : _this$modelValue$proc2.map(function (item) {
        return item.id;
      })) || [];
      if ((_this$data$frameworks = this.data.frameworks) !== null && _this$data$frameworks !== void 0 && _this$data$frameworks.length) result.frameworks = ((_this$modelValue$fram2 = this.modelValue.frameworks) === null || _this$modelValue$fram2 === void 0 ? void 0 : _this$modelValue$fram2.map(function (item) {
        return item.id;
      })) || [];
      if ((_this$data$risks = this.data.risks) !== null && _this$data$risks !== void 0 && _this$data$risks.length) result.risks = ((_this$modelValue$risk2 = this.modelValue.risks) === null || _this$modelValue$risk2 === void 0 ? void 0 : _this$modelValue$risk2.map(function (item) {
        return item.id;
      })) || [];
      return JSON.stringify(result);
    },
    saveState: function saveState() {
      var data = this.prepareState();
      if (this.stateLocal) {
        localStorage.setItem(this.stateLocal, data);
      }
    },
    setFiltersFromState: function setFiltersFromState() {
      var _state$activityTypes, _state$frameworks;
      var state = JSON.parse(localStorage.getItem(this.stateLocal));
      if (!state) return;
      var result = {
        activityTypes: [],
        organisations: [],
        tags: [],
        mother: null,
        responsible: [],
        search: '',
        deadline: null,
        showDeactivated: false,
        groupBy: null,
        sortBy: null,
        showOverviewOptions: false,
        processes: [],
        frameworks: []
      };
      if (state.organisations && state.organisations.length) result.organisations = Utils.filterArrayByListOfPropValues(this.data.organisations, state.organisations);
      if ((_state$activityTypes = state.activityTypes) !== null && _state$activityTypes !== void 0 && _state$activityTypes.length) result.activityTypes = Utils.filterArrayByListOfPropValues(this.data.activityTypes, state.activityTypes);
      if (state.responsible && state.responsible.length) result.responsible = Utils.filterArrayByListOfPropValues(this.data.users, state.responsible);
      if (state.tags && state.tags.length) result.tags = Utils.filterArrayByListOfPropValues(this.data.tags, state.tags);
      if (state.mother) result.mother = state.mother;
      if (state.showDeactivated) result.showDeactivated = state.showDeactivated;
      if (state.deadline !== null) result.deadline = state.deadline;
      if (state.groupBy) result.groupBy = state.groupBy;
      if (state.sortBy) result.sortBy = state.sortBy;
      if (state.showOverviewOptions) result.showOverviewOptions = state.showOverviewOptions;
      if (state.accounts && state.accounts.length) result.accounts = Utils.filterArrayByListOfPropValues(this.data.accounts, state.accounts);
      if (state.processes && state.processes.length) result.processes = Utils.filterArrayByListOfPropValues(this.data.processes, state.processes);
      if ((_state$frameworks = state.frameworks) !== null && _state$frameworks !== void 0 && _state$frameworks.length) result.frameworks = Utils.filterArrayByListOfPropValues(this.data.frameworks, state.frameworks);
      if (state.risks && state.risks.length) result.risks = Utils.filterArrayByListOfPropValues(this.data.risks, state.risks);
      this.$emit('update:modelValue', result);
      this.$emit('groupControls');
    },
    groupBy: function groupBy(e) {
      this.filterByEntity(e, 'groupBy');
      this.$emit('groupControls');
    },
    resetFilters: function resetFilters() {
      var _this2 = this;
      this.$emit('resetFilters');
      this.$nextTick(function () {
        _this2.saveState();
      });
    },
    handleSearch: function handleSearch(value) {
      var _this3 = this;
      this.searchTimeout && clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        _this3.modelValue.search = value;
      }, 200);
    },
    removeFilter: function removeFilter(key) {
      switch (key) {
        case 'organisations':
        case 'tags':
        case 'responsible':
        case 'accounts':
        case 'processes':
        case 'risks':
        case 'activityTypes':
        case 'frameworks':
          this.modelValue[key] = [];
          break;
        case 'mother':
        case 'deadline':
          this.modelValue[key] = null;
          break;
        case 'search':
          this.modelValue[key] = '';
          break;
        case 'showDeactivated':
          this.modelValue[key] = false;
      }
      this.saveState();
    }
  }
};