function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { AddAssetActions } from '@/constants/compliance/WizardFlow';
export default {
  name: 'CompanyWizardStepAssets',
  introduction: '',
  token: '<company-wizard-step-company-assets />',
  components: {
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon,
    InputField: InputField
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      AddAssetActions: AddAssetActions,
      AssetsTypes: AssetsTypes,
      optionValue: null,
      translate: getTranslate['CompanyWizardStepAssets']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['complianceIACustomActivityTypes'])), {}, {
    addIAOptionAvailable: function addIAOptionAvailable() {
      return !this.selectedOptions[AssetsTypes.INFORMATION_ASSETS_API].some(function (asset) {
        if (asset.action === AddAssetActions.CHOOSE_EXISTING) {
          return !asset.activityTypeId || !asset.id;
        }
        if (asset.action === AddAssetActions.CREATE_NEW) {
          return !asset.activityTypeId;
        }
        return true;
      });
    },
    addSystemOptionAvailable: function addSystemOptionAvailable() {
      return !this.selectedOptions[AssetsTypes.SYSTEMS].some(function (asset) {
        if (asset.action === AddAssetActions.CHOOSE_EXISTING) {
          return !asset.id;
        }
        if (asset.action === AddAssetActions.CREATE_NEW) {
          return !asset.title;
        }
        return true;
      });
    },
    addAssetOptions: function addAssetOptions() {
      return [{
        value: AddAssetActions.NONE,
        label: this.translate.none
      }, {
        value: AddAssetActions.CHOOSE_EXISTING,
        label: this.translate.chooseExisting
      }, {
        value: AddAssetActions.CREATE_NEW,
        label: this.translate.createNew
      }];
    },
    systemsOptions: function systemsOptions() {
      return this.data[AssetsTypes.SYSTEMS].map(function (_ref) {
        var id = _ref.id,
          title = _ref.title;
        return {
          value: id,
          label: title
        };
      });
    },
    processorsOptions: function processorsOptions() {
      return this.data[AssetsTypes.PROCESSORS].map(function (_ref2) {
        var id = _ref2.id,
          title = _ref2.title;
        return {
          value: id,
          label: title
        };
      });
    },
    controllersOptions: function controllersOptions() {
      return this.data[AssetsTypes.CONTROLLERS].map(function (_ref3) {
        var id = _ref3.id,
          title = _ref3.title;
        return {
          value: id,
          label: title
        };
      });
    },
    customIAActivityTypesOptions: function customIAActivityTypesOptions() {
      return this.complianceIACustomActivityTypes.map(function (_ref4) {
        var id = _ref4.id,
          label = _ref4.label;
        return {
          value: id,
          label: label
        };
      });
    },
    selectedSystemIds: function selectedSystemIds() {
      return this.selectedOptions[AssetsTypes.SYSTEMS].map(function (system) {
        return system.id;
      }).filter(Boolean);
    },
    selectedIAIds: function selectedIAIds() {
      return this.selectedOptions[AssetsTypes.INFORMATION_ASSETS_API].map(function (ia) {
        return ia.id;
      }).filter(Boolean);
    }
  }),
  methods: {
    onRowOptionChange: function onRowOptionChange(value, property, idx, assetType) {
      var updatedField = _objectSpread(_objectSpread({}, this.selectedOptions[assetType][idx]), {}, _defineProperty({}, property, value));
      if (property !== 'id') {
        updatedField.id = null;
      }
      this.selectedOptions[assetType][idx] = updatedField;
      this.$emit('update:selected', {
        value: this.selectedOptions[assetType],
        field: assetType
      });
    },
    onOptionChange: function onOptionChange(value, field, property) {
      var updatedField = _objectSpread(_objectSpread({}, this.selectedOptions[field]), {}, _defineProperty({}, property, value));
      this.$emit('update:selected', {
        value: updatedField,
        field: field
      });
    },
    getIAOptionsByActivityType: function getIAOptionsByActivityType(activityTypeId) {
      var _this = this;
      var selectedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      if (!activityTypeId) return null;
      return this.data[AssetsTypes.INFORMATION_ASSETS_API].filter(function (asset) {
        return selectedId === asset.id || asset.activityTypeId === activityTypeId && !_this.selectedIAIds.includes(asset.id);
      }).map(function (_ref5) {
        var id = _ref5.id,
          title = _ref5.title;
        return {
          value: id,
          label: title
        };
      });
    },
    getSystemOptions: function getSystemOptions() {
      var _this2 = this;
      var selectedId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.data[AssetsTypes.SYSTEMS].filter(function (system) {
        return selectedId === system.id || !_this2.selectedSystemIds.includes(system.id);
      }).map(function (_ref6) {
        var id = _ref6.id,
          title = _ref6.title;
        return {
          value: id,
          label: title
        };
      });
    },
    addNewRow: function addNewRow(addOptionAvailable, assetType) {
      if (!addOptionAvailable) return;
      var value = this.selectedOptions[assetType].slice();
      var item = {
        action: AddAssetActions.NONE,
        id: null
      };
      if (assetType === AssetsTypes.SYSTEMS) {
        item.title = '';
      }
      if (assetType === AssetsTypes.INFORMATION_ASSETS_API) {
        item.activityTypeId = null;
      }
      value.push(item);
      this.$emit('update:selected', {
        value: value,
        field: assetType
      });
    }
  }
};