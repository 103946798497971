// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label[data-v-f3810d22] {
  background-color: rgba(115, 115, 115, 0.2);
  padding: 5px 15px;
  border-radius: 1.5rem;
}
.divider[data-v-f3810d22] {
  width: 96%;
  height: 2px;
  background-color: #ebebeb;
}
.description[data-v-f3810d22] {
  color: #807c79;
}
.full_link[data-v-f3810d22] {
  color: #0e2954;
  font-size: 1.3rem;
}
.full_link svg[data-v-f3810d22] {
  width: auto;
  height: 1.5em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
