import i18n from '@/i18n';
export var getTranslate = {
  ReportDataflow: function ReportDataflow() {
    return {
      direction: i18n.t('Direction'),
      dataType: i18n.t('Data type'),
      title: i18n.t('title'),
      comments: i18n.t('Comments'),
      process: i18n.t('Process'),
      organisation: i18n.t('Organisation'),
      deliversData: i18n.t('Delivers data'),
      receivesData: i18n.t('Receives data'),
      systems: i18n.t('Systems'),
      tags: i18n.t('Tags'),
      legalBasis: i18n.t('Legal basis'),
      storagePeriod: i18n.t('Storage period')
    };
  },
  ReportSearchForm: function ReportSearchForm() {
    return {
      resetFilter: i18n.t('Reset filter'),
      node: i18n.t('Node'),
      nodes: i18n.t('Nodes'),
      includeUnderlyingNodes: i18n.t('Include underlying nodes'),
      organisation: i18n.t('Organisation'),
      organisations: i18n.t('Organisations'),
      users: i18n.t('Users'),
      includeScoringInReportWillAffectPerformance: i18n.t('Include scoring in report (will affect performance of report)'),
      allNodes: i18n.t('All nodes'),
      tags: i18n.t('Tags'),
      attributes: i18n.t('Attributes')
    };
  },
  ReportTabs: function ReportTabs() {
    return {
      nodes: i18n.t('Nodes'),
      gap: i18n.t('Gap'),
      dataFlow: i18n.t('Data flow'),
      memorandum: i18n.t('Memorandum report')
    };
  },
  ReportDataflowWrapper: function ReportDataflowWrapper() {
    return {
      display: i18n.t('Display')
    };
  },
  ComplianceManagementReport: function ComplianceManagementReport() {
    return {
      managementReport: i18n.t('Management reporting'),
      title: i18n.t('Title'),
      createdBy: i18n.t('Created by'),
      latestRevision: i18n.t('Latest revision'),
      downloadLatest: i18n.t('Download latest'),
      edit: i18n.t('Edit'),
      delete: i18n.t('Delete'),
      areYouSure: i18n.t('Are you sure'),
      createManagementReport: i18n.t('Create management report'),
      editManagementReport: i18n.t('Edit management report'),
      create: i18n.t('Create'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      enterTitle: i18n.t('Enter title'),
      nodes: i18n.t('Nodes'),
      includeUnderlyingNodes: i18n.t('Incl. underlying nodes'),
      closed: i18n.t('Closed'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.')
    };
  }
};