import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.editComplianceProject,
    "dismiss-on-click-outside": false,
    "button-ok-text": $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    type: "large",
    onAccept: $options.submitModal,
    onDismiss: _cache[8] || (_cache[8] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $props.project.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $props.project.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        class: _normalizeClass({
          'input-error': $data.errors.title
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $props.project.questionnaireId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $props.project.questionnaireId = $event;
        }),
        title: $data.translate.questionnaire,
        options: $props.questionnaires,
        placeholder: $data.translate.selectOne
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        modelValue: $props.project.gapSchemaId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $props.project.gapSchemaId = $event;
        }),
        title: $data.translate.gapSchemaName,
        options: $props.gapSchemas,
        placeholder: $data.translate.selectOne
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), _createVNode(_component_frameworks_selector, {
        "selected-options": $options.selectedFrameworks,
        class: "mb-3",
        onSelected: $options.setFrameworkId
      }, null, 8 /* PROPS */, ["selected-options", "onSelected"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_activity_type_selector, {
        modelValue: $props.project.activityTypeId,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $props.project.activityTypeId = $event;
        }),
        label: $data.translate.activityType,
        "activity-types": $props.activityTypes
      }, null, 8 /* PROPS */, ["modelValue", "label", "activity-types"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
        modelValue: $props.project.descriptionLabel,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $props.project.descriptionLabel = $event;
        }),
        title: $data.translate.renameTitleOverallDescription,
        placeholder: $data.translate.renameTitleOverallDescription,
        class: _normalizeClass({
          'input-error': $data.errors.descriptionLabel
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
        modelValue: $props.project.purposeLabel,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $props.project.purposeLabel = $event;
        }),
        title: $data.translate.renameTitlePurpose,
        placeholder: $data.translate.renameTitlePurpose,
        class: _normalizeClass({
          'input-error': $data.errors.purposeLabel
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
        modelValue: $props.project.statusLabel,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return $props.project.statusLabel = $event;
        }),
        title: $data.translate.renameTitleProcessStatus,
        placeholder: $data.translate.renameTitleProcessStatus,
        class: _normalizeClass({
          'input-error': $data.errors.statusLabel
        }),
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "class"])]), _createElementVNode("div", null, [_createVNode(_component_checkboxes, {
        modelValue: $options.checkBoxValues,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return $options.checkBoxValues = $event;
        }),
        "label-position": "left",
        "checkbox-size": 20,
        options: $options.checkBoxFields
      }, null, 8 /* PROPS */, ["modelValue", "options"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"]);
}