function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var RiskStatus = {
  DRAFT: 0,
  OPEN: 1,
  CLOSED: 2
};
export var ErmRiskStatus = {
  NEW: 0,
  OPEN: 1,
  EDITING: 2,
  CLOSED: 10
};
export var ErmRiskStatusText = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ErmRiskStatus.NEW, 'New'), ErmRiskStatus.OPEN, 'Open'), ErmRiskStatus.EDITING, 'Editing'), ErmRiskStatus.CLOSED, 'Closed');
export var RiskStatusText = _defineProperty(_defineProperty(_defineProperty({}, RiskStatus.OPEN, 'Open'), RiskStatus.DRAFT, 'Draft'), RiskStatus.CLOSED, 'Closed');