function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { TRAFFIC_LIGHT_MODULES } from '@/constants/modules';
import { TrendOptions } from '@/constants/TrendOptions';
export default {
  name: 'PreviewRiskCustomFields',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon
  },
  props: {
    riskFields: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    scoringFields: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      translate: {
        customRiskFields: this.$trans('Custom risk fields'),
        notSet: this.$trans('Not set')
      }
    };
  },
  computed: {
    notSetMessage: function notSetMessage() {
      return this.translate.notSet;
    },
    fields: function fields() {
      var _this = this;
      var fields = [];
      this.riskFields.forEach(function (rfield) {
        var scoringField = _this.scoringFields.find(function (sfield) {
          return sfield.id === rfield.fieldId;
        });
        if (rfield.fieldType === _this.customFieldTypes.TEXT_FIELD) {
          rfield.fieldValue = rfield.fieldValue.trim();
        }
        fields.push(Object.assign({}, rfield, {
          fieldType: scoringField.fieldtype
        }));
      });
      return fields;
    },
    customFieldTypes: function customFieldTypes() {
      return CustomFieldTypes;
    },
    trendMapping: function trendMapping() {
      return TrendOptions().reduce(function (acc, item) {
        acc[item.value] = item;
        return acc;
      }, {});
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['findLightForModuleByNumber'])), {}, {
    getTrafficLightColor: function getTrafficLightColor(n) {
      return this.findLightForModuleByNumber(TRAFFIC_LIGHT_MODULES.default, n);
    },
    formatTenders: function formatTenders(value) {
      if (value === 1) {
        return 'arrow-down-right';
      } else if (value === 2) {
        return 'arrow-right';
      } else if (value === 3) {
        return 'arrow-up-right';
      }
    }
  })
};