import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "border border-gray-450 border-t-0 p-4"
};
var _hoisted_4 = ["title"];
var _hoisted_5 = {
  class: "flex flex-col pt-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.headline,
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_tab_list, {
    class: "flex mt-6",
    tabs: $options.tabs,
    theme: "dark",
    onUpdate: $options.setCurrentTab
  }, null, 8 /* PROPS */, ["tabs", "onUpdate"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_static_table, {
    "dataset-table": $options.dataset,
    columns: $data.columns,
    theme: "zebra",
    "columns-max-width": $data.columnsMaxWidth
  }, {
    node: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_preview_modal_link, {
        activity: slotData.activity,
        onUpdated: $options.updateFromPreviewModalLink,
        onDismissModal: $options.onPreviewModalDismiss
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("p", {
            title: slotData.title,
            class: _normalizeClass(["hover:underline", slotData.isParentNode ? 'font-bold' : 'pl-8'])
          }, _toDisplayString(slotData.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_4)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"])];
    }),
    comment: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(slotData.comment), 1 /* TEXT */), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotData.attachments, function (attachment) {
        return _openBlock(), _createElementBlock("div", {
          key: attachment.id,
          class: "flex py-1"
        }, [_createVNode(_component_feather_icon, {
          icon: attachment.icon,
          class: "mr-4 stroke-2"
        }, null, 8 /* PROPS */, ["icon"]), _createTextVNode(" " + _toDisplayString(attachment.name), 1 /* TEXT */)]);
      }), 128 /* KEYED_FRAGMENT */))])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns", "columns-max-width"])])]))]);
}