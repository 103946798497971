export default [{
  link: '/dev/kitchensink/atoms',
  title: 'Atoms'
}, {
  link: '/dev/kitchensink/molecules',
  title: 'Molecules'
}, {
  link: '/dev/kitchensink/organisms',
  title: 'Organisms'
}, {
  link: '/dev/kitchensink/design',
  title: 'Design'
}];