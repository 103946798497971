// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[data-v-5f5f3d21]::-ms-clear {
  display: none;
}
.hover-not-focus[data-v-5f5f3d21]:hover:not(:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
  outline-color: rgb(235, 235, 235);
}
input[type=search][data-v-5f5f3d21] {
  outline-offset: 0px;
}
input[type=search][data-v-5f5f3d21]::-webkit-search-decoration,
input[type=search][data-v-5f5f3d21]::-webkit-search-cancel-button,
input[type=search][data-v-5f5f3d21]::-webkit-search-results-button,
input[type=search][data-v-5f5f3d21]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
