function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "kitchen-component-footer"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 2
};
var _hoisted_6 = {
  key: 3
};
var _hoisted_7 = {
  key: 4
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_code_highlight = _resolveComponent("code-highlight");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createCommentVNode(" Example code snippet "), _createVNode(_component_code_highlight, {
    code: $props.component.token || '',
    desc: $props.component.description || ''
  }, null, 8 /* PROPS */, ["code", "desc"]), _createCommentVNode(" Properties description "), $props.component.props ? (_openBlock(), _createElementBlock("table", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, [_createTextVNode(" Name "), _createElementVNode("span", {
    class: "required"
  }, "(required)")]), _createElementVNode("th", null, "Type"), _createElementVNode("th", null, "Default"), _createElementVNode("th", null, "Notes")])], -1 /* HOISTED */)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.component.props, function (prop, k) {
    return _openBlock(), _createElementBlock("tr", {
      key: k
    }, [_createElementVNode("td", null, [_createElementVNode("span", {
      class: _normalizeClass([{
        required: prop.required
      }])
    }, _toDisplayString(k), 3 /* TEXT, CLASS */)]), typeof prop.type === 'function' ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(prop.type.name), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _typeof(prop.type) === 'object' ? (_openBlock(), _createElementBlock("td", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prop.type, function (type, key) {
      return _openBlock(), _createElementBlock("span", {
        key: key
      }, _toDisplayString(type.name) + "   ", 1 /* TEXT */);
    }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), prop.default != undefined && typeof prop.default !== 'function' ? (_openBlock(), _createElementBlock("td", _hoisted_5, _toDisplayString(prop.default), 1 /* TEXT */)) : _createCommentVNode("v-if", true), prop.default != undefined && typeof prop.default === 'function' ? (_openBlock(), _createElementBlock("td", _hoisted_6, _toDisplayString(prop.default()), 1 /* TEXT */)) : _createCommentVNode("v-if", true), prop.default == undefined ? (_openBlock(), _createElementBlock("td", _hoisted_7, "Undefined")) : _createCommentVNode("v-if", true), _createElementVNode("td", null, _toDisplayString(prop.note), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]);
}