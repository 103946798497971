// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot-wrap[data-v-9739890c] {
  transition: background-color 0.3s;
}
.toggle-menu-modal[data-v-9739890c] {
  background: #FFFFFF;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.06);
  z-index: 20;
}
.toggle-menu-modal ul li[data-v-9739890c] {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
