import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
import { MENU_OPTION_TYPES, MENU_BUTTON_TYPES } from '@/components/Atoms/ToggleMenu/toggleMenuTypes';
export default {
  name: 'ToggleMenu',
  components: {
    FeatherIcon: FeatherIcon
  },
  introduction: 'Toggler with menu items.',
  description: 'Flexible toggle menu component. You can find it here: /controls2/controls/1',
  token: '<toggle-menu :options="dataEntryMenuOptions"></toggle-menu>',
  directives: {
    clickOutside: clickOutside
  },
  props: {
    options: {
      type: Array,
      required: true,
      note: 'Array of menu items'
    },
    position: {
      type: String,
      required: false,
      default: 'absolute',
      validator: function validator(value) {
        return ['absolute', 'fixed'].includes(value);
      }
    },
    side: {
      type: String,
      required: false,
      default: 'right',
      validator: function validator(value) {
        return ['left', 'right'].includes(value);
      }
    },
    buttonType: {
      type: String,
      required: false,
      default: MENU_BUTTON_TYPES.PRIMARY,
      validator: function validator(value) {
        return Object.values(MENU_BUTTON_TYPES).includes(value);
      },
      note: ''
    },
    buttonStyles: {
      type: String,
      required: false,
      default: '',
      note: 'Set the size of the button that triggers the menu'
    },
    buttonIconColor: {
      type: String,
      required: false,
      default: 'text-rm-grey',
      note: 'For the mini tree, we use a different default color to better align with the MiniTree design'
    }
  },
  data: function data() {
    return {
      isVisible: false,
      positionLeft: 'auto',
      positionTop: '0px',
      positionRight: '0px',
      MENU_OPTION_TYPES: MENU_OPTION_TYPES
    };
  },
  computed: {
    visibleOptions: function visibleOptions() {
      return this.options.filter(function (option) {
        return !option.hidden;
      });
    },
    isLeftSide: function isLeftSide() {
      return this.side === 'left';
    },
    buttonStylesByType: function buttonStylesByType() {
      if (this.buttonType === MENU_BUTTON_TYPES.SECONDARY) {
        return this.buttonSecondaryStyle;
      }
      return "w-8 h-8 rounded-sm ".concat(this.isVisible ? 'bg-rm-active-blue--10' : '');
    },
    buttonSecondaryStyle: function buttonSecondaryStyle() {
      var activeStyles = this.isVisible ? 'border-rm-active-blue bg-rm-active-blue--10' : 'border-rm-grey bg-rm-white';
      return "w-11 h-11 rounded-md border active:border-rm-active-blue hover:border-rm-grey ".concat(activeStyles);
    },
    menuPosition: function menuPosition() {
      return {
        left: this.positionLeft,
        top: this.positionTop,
        right: this.positionRight
      };
    }
  },
  methods: {
    toggleVisibility: function toggleVisibility(event) {
      if (this.position === 'fixed') {
        var bottomMargin = 4;
        var rect = event.target.getBoundingClientRect();
        var positionTop = rect.top;
        this.positionLeft = rect.left + 'px';
        this.positionTop = positionTop + rect.height + bottomMargin + 'px';
        this.positionRight = 'auto';
      }
      if (this.position === 'absolute') {
        this.positionLeft = this.isLeftSide ? '0px' : 'auto';
        this.positionTop = 'calc(100% + 4px)';
        this.positionRight = this.isLeftSide ? 'auto' : '0px';
      }
      this.isVisible = !this.isVisible;
    },
    closeMenu: function closeMenu() {
      this.isVisible = false;
    }
  }
};