import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  class: "flex justify-between rounded-md border border-blue-750 mb-1 p-1"
};
var _hoisted_3 = {
  class: "flex items-center"
};
var _hoisted_4 = {
  class: "flex bg-indigo-200 border-roun rounded-md border border-blue-750 mb-1 p-1 items-center"
};
var _hoisted_5 = {
  key: 0,
  class: "py-2 overflow-hidden"
};
var _hoisted_6 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_draggable = _resolveComponent("draggable");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.changeSorting,
    "show-buttons": false,
    onDismiss: $options.dismissModal
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_draggable, {
        list: $options.tasksGroupNested,
        handle: ".drag-icon",
        "empty-insert-threshold": 500,
        onChange: $options.moveGroup
      }, {
        item: _withCtx(function (_ref) {
          var group = _ref.element;
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, [_createVNode(_component_feather_icon, {
            icon: "move",
            class: "drag-icon text-blue-750 stroke-2 mr-4"
          }), _createTextVNode(" " + _toDisplayString(group.name || $data.translate.notGrouped), 1 /* TEXT */)])]), _createVNode(_component_draggable, {
            list: group.tasks,
            group: {
              name: 'groupItems'
            },
            handle: ".drag-nested-icon",
            onChange: function onChange($event) {
              return $options.onMoveFields(group.id, group.tasks || []);
            },
            onEnd: $options.moveFields
          }, {
            item: _withCtx(function (_ref2) {
              var groupItem = _ref2.element;
              return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_feather_icon, {
                icon: "move",
                class: "drag-nested-icon text-blue-750 stroke-2 mr-4"
              }), _createTextVNode(" " + _toDisplayString(groupItem.label), 1 /* TEXT */)])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["list", "onChange", "onEnd"])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["list", "onChange"]), _createVNode(_Transition, {
        name: "expand",
        onEnter: $options.startTransition,
        onAfterEnter: $options.endTransition,
        onBeforeLeave: $options.startTransition,
        onAfterLeave: $options.endTransition
      }, {
        default: _withCtx(function () {
          return [$data.showAddGroup ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_input_field, {
            modelValue: $data.groupName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.groupName = $event;
            }),
            placeholder: $data.translate.groupName,
            type: "text"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createVNode(_component_risma_button, {
            text: $data.translate.save,
            disabled: !$data.groupName,
            type: "save",
            onClick: $options.addGroup
          }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onEnter", "onAfterEnter", "onBeforeLeave", "onAfterLeave"]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_button, {
        text: $data.translate.addNewGroup,
        type: "save",
        onClick: $options.showAddGroupBlock
      }, null, 8 /* PROPS */, ["text", "onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"]);
}