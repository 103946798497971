function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getNodes, getSingleComplianceProject } from '@/api/risma/data';
export function formatNodes(nodes) {
  var parentTitle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var parentIds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var withChildren = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var formattedNodes = [];
  var formattedTitle = '';
  nodes.forEach(function (node) {
    var newNode = _objectSpread({}, node); // Use cloned object to avoid mutating original input

    if (parentTitle !== '') {
      formattedTitle = parentTitle + ' > ' + newNode.title;
    } else {
      formattedTitle = newNode.title;
    }
    newNode.formattedTitle = formattedTitle;
    newNode.parentIds = parentIds;
    var children = newNode.children || false;
    if (!withChildren) delete newNode.children;
    formattedNodes.push(newNode);
    if (children) {
      var newParentIds = [].concat(_toConsumableArray(parentIds), [newNode.id]);
      formattedNodes = [].concat(_toConsumableArray(formattedNodes), _toConsumableArray(formatNodes(children, formattedTitle, newParentIds, withChildren)));
    }
  });
  return formattedNodes;
}
export function getNodesForSelectBox() {
  var options = [];
  var nodesPromise = getNodes();
  var promises = [];
  return nodesPromise.then(function (response) {
    response.list.forEach(function (treeRoot) {
      promises.push(getSingleComplianceProject(treeRoot.compliance_projects_id).then(function (project) {
        var formattedNodes = formatNodes(treeRoot.children);
        formattedNodes.forEach(function (node) {
          options.push({
            id: node.id,
            linkParam: node.url || '',
            title: project.title + ': ' + node.formattedTitle,
            url: node.url || '',
            objType: node.objType || '',
            compliance_projects_id: node.compliance_projects_id || ''
          });
        });
      }));
    });
    return Promise.all(promises).then(function () {
      return options;
    });
  });
}
export function getHierarchicalListTitle(activity) {
  var name = activity.title;
  while (activity.children.length) {
    name += ' > ';
    activity = activity.children[0];
    name += activity.title;
  }
  return name;
}