import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex -mb-1.5px"
};
var _hoisted_2 = {
  class: "border border-gray-350 p-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    to: $options.baseControlLink + 'main'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.mainReport), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), _createVNode(_component_light_tab_item, {
    to: $options.baseControlLink + 'changelog'
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.changelogReport), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)])]);
}