import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import CardCompliance from '@/components/Organisms/General/Cards/CardCompliance';
import { viewTypes } from '@/constants/ListViewType';
export default {
  name: 'MyProcesses',
  token: '<my-processes-list />',
  components: {
    PreviewList: PreviewList,
    CardCompliance: CardCompliance,
    LoadMoreWrapper: LoadMoreWrapper
  },
  props: {
    viewMode: {
      required: false,
      type: String,
      default: ''
    },
    visibleProps: {
      required: false,
      type: Array,
      default: function _default() {
        return [{
          key: 'title',
          text: 'Title'
        }, {
          key: 'description',
          text: 'Description'
        }, {
          key: 'project',
          text: 'Project'
        }, {
          key: 'values',
          text: 'Questions Answered'
        }];
      }
    },
    processes: {
      required: true,
      type: Array
    },
    title: {
      required: false,
      type: String,
      default: 'My processes',
      note: 'list`s title'
    }
  },
  emits: ['changeShowState'],
  data: function data() {
    return {
      viewTypes: viewTypes
    };
  }
};