import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex -mb-1.5px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    class: "mb-4",
    title: $data.translate.aiPartner
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tabList, function (item, index) {
    return _openBlock(), _createBlock(_component_light_tab_item, {
      key: "tab-list-".concat(index),
      "background-class": "bg-zinc-50 z-10",
      to: item.url
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
  }), 128 /* KEYED_FRAGMENT */))]), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_router_view)];
    }),
    _: 1 /* STABLE */
  })]);
}