import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'TagItem',
  components: {
    FeatherIcon: FeatherIcon
  },
  introduction: 'box for displaying tags',
  description: 'This component is used to display tags of all sort. the component can be infused with a displayed title, a tag/url and a module that the tag is a part of ie. file, compliance, risk, execution...',
  token: "<tag-item title=\"this is a test tag\">\n                <feather-icon style=\"float: right;\" icon=\"times\" color=\"white\"/>\n            </tag-item>",
  props: {
    title: {
      type: String,
      required: true,
      note: 'the displayed text on the tagItem'
    }
  },
  emits: ['close']
};