import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_confidential_box = _resolveComponent("confidential-box");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_confidential_box, {
    confidential: $data.value,
    class: "mt-2 mb-4",
    "confidentiality-names": _ctx.currentUser.display_name,
    onChanged: _cache[0] || (_cache[0] = function ($event) {
      return $data.value = $event;
    })
  }, null, 8 /* PROPS */, ["confidential", "confidentiality-names"]), _cache[3] || (_cache[3] = _createTextVNode(" Locked ")), _createVNode(_component_confidential_box, {
    confidential: false,
    class: "mt-2 mb-4",
    "read-only": true,
    "confidentiality-names": _ctx.currentUser.display_name,
    onChanged: _cache[1] || (_cache[1] = function ($event) {
      return $data.value = $event;
    })
  }, null, 8 /* PROPS */, ["confidentiality-names"]), _cache[4] || (_cache[4] = _createTextVNode(" Updated text ")), _createVNode(_component_confidential_box, {
    confidential: $data.value,
    class: "mt-2 mb-4",
    "confidentiality-names": _ctx.currentUser.display_name,
    title: "new text",
    onChanged: _cache[2] || (_cache[2] = function ($event) {
      return $data.value = $event;
    })
  }, null, 8 /* PROPS */, ["confidential", "confidentiality-names"])], 64 /* STABLE_FRAGMENT */);
}