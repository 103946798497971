// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-component-title[data-v-738d5248] {
  margin: 0 0 1rem;
}
.preview-request[data-v-738d5248] {
  width: 600px;
  margin-bottom: 2em;
}
.preview-request .chart-item[data-v-738d5248] {
  margin: auto;
}
.preview-request .chart-item[data-v-738d5248]  #pie-chart {
  margin: auto;
}
.header[data-v-738d5248] {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin: 0 0 20px;
  position: relative;
}
.header .header-toggle-menu[data-v-738d5248] {
  margin-left: auto;
}
.header[data-v-738d5248]  .toggle-menu-modal {
  width: auto;
}
.header-title[data-v-738d5248] {
  display: flex;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
