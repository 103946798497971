import currency from '@/constants/contracts/currency';
import { getContractDateOptions } from '@/utils/date/getDateOptions';
function getCurrency() {
  var data = {};
  currency.forEach(function (item) {
    data[item.value] = item.label;
  });
  return data;
}
function getDate() {
  var options = getContractDateOptions();
  var result = {};
  options.forEach(function (item) {
    result[item.value] = item.label;
  });
  return result;
}
export var SETTINGS_ARRAY = {
  General: {
    'risma.firstlanguage': {
      type: 'stringvalue',
      name: 'Default language',
      default: 'en_GB',
      dropdown: {
        en_GB: 'English',
        da_DK: 'Danish',
        de_DE: 'German',
        nb_NO: 'Norwegian',
        sv_SE: 'Swedish',
        fi_FI: 'Finnish',
        fr_FR: 'French',
        es_ES: 'Spanish',
        it_IT: 'Italian',
        pl_PL: 'Polish'
      },
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#defaultlanguage'
    },
    'app.company_name': {
      type: 'stringvalue',
      name: 'Name of your company to show in external communication from RISMA',
      default: '',
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#nameyourcompany'
    },
    'risma.organisational_groups': {
      type: 'boolvalue',
      name: 'Enable organisational groups',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#organisationalgroups'
    },
    'risma.user_show': {
      type: 'stringvalue',
      name: 'Show users as',
      default: 'username',
      dropdown: {
        username: 'Username',
        fullname: 'Full name',
        initials: 'Initials'
      },
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#showusersas'
    },
    'risma.landing_page': {
      type: 'stringvalue',
      name: 'First page after login',
      default: '/mypage',
      dropdown: {
        '/mypage': 'MyPage'
      },
      translatable: true,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#pageafterlogin'
    },
    'risma.max_password_age': {
      type: 'intvalue',
      name: 'Max days before users must change password',
      default: 90,
      minValue: 14,
      maxValue: 365,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#daysbeforechangepassword'
    },
    'risma.max_visible_users_orgs': {
      type: 'intvalue',
      name: 'Max number of visible items in users- and organisations selectors',
      default: 10,
      minValue: 1,
      maxValue: 30,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#numberofvisibleitems'
    },
    db_sessions_lifetime: {
      type: 'intvalue',
      name: 'Number of minutes that a user can be inactive before logged out',
      default: 240,
      minValue: 30,
      maxValue: 1440,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#inactiveminutesbeforelogout'
    },
    'feature.asset_management_menu': {
      type: 'boolvalue',
      name: 'Show information assets menu item in top menu',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#showinformationassets',
      updateHeader: true
    },
    'feature.welcome_page_menu': {
      type: 'boolvalue',
      name: 'Show welcome page in main navigation bar',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#showwelcomebar',
      updateHeader: true
    },
    'feature.only_admin_can_disable_mfa': {
      type: 'boolvalue',
      name: 'Only admin can disable MFA',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#admindisablemfa',
      updateHeader: true
    },
    'feature.kpi_no_entries_kpi_value': {
      type: 'intvalue',
      name: 'What value should a kpi show if no entries match the criteria (0 out of 0)',
      default: '100',
      link: 'https://support.rismasystems.com/how-to-adjust-general-functions#noentrieskpi',
      dropdown: {
        100: '100%',
        0: '0%'
      }
    }
  },
  RISMAcontrols: {
    'risma.unlock_description': {
      type: 'boolvalue',
      name: 'Unlock description on locked fields',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#unlockdescription'
    },
    'app.module_controls_review_days': {
      type: 'intvalue',
      name: 'Number of days between deadline and review deadline',
      default: 7,
      minValue: 0,
      maxValue: 180,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#daystoreviewdeadline'
    },
    'risma.show_controls_under_review': {
      type: 'boolvalue',
      name: 'Show "Controls being reviewed"',
      default: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#showcontrolsunderreview'
    },
    'risma.show_expected_evidence': {
      type: 'boolvalue',
      name: 'Show field "expected evidence"',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#expectedevidence'
    },
    'risma.mandatory_documentation': {
      type: 'boolvalue',
      name: 'Make documentation mandatory on control completion',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#mandatorydocumentation'
    },
    'risma.partially_completed_controls': {
      type: 'boolvalue',
      name: 'Enable button for "Completed with actions"',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#completedwithactions'
    },
    'feature.skip_control_button': {
      type: 'boolvalue',
      name: 'Enable button for "Skip"',
      default: false,
      link: 'https://support.rismasystems.com/how-to-complete-a-contol#Skipcontrol'
    },
    'feature.repeatable_fields': {
      type: 'boolvalue',
      name: 'Repeatable custom fields. Each field has many values, e.g. for samples',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#repeatablefield'
    },
    'feature.complete_modal_wide': {
      type: 'boolvalue',
      name: 'Enable wide version of Controls completion window',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#enablewideview'
    },
    'risma.signoff_period': {
      type: 'boolvalue',
      name: 'Add signoff period to controls, starting from 1st of month',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#signoff1month'
    },
    'risma.signoff_period_traffic_light': {
      type: 'boolvalue',
      name: 'Add signoff period to controls, starting from yellow trafficlight',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#signofTL',
      helpText: 'NB: Only for controls with a monthly frequency or lower'
    },
    'risma.account_to_name': {
      type: 'stringvalue',
      name: 'Change "Account" to a different name',
      default: 'Account',
      notEmpty: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#renameaccount'
    },
    'risma.process_to_name': {
      type: 'stringvalue',
      name: 'Change "Process" to a different name',
      default: 'Process',
      notEmpty: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#renameprocess'
    },
    'risma.risk_to_name': {
      type: 'stringvalue',
      name: 'Change "Risk" to a different name',
      default: 'Risk',
      notEmpty: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#renamerisk'
    },
    'risma.show_key_control': {
      type: 'boolvalue',
      name: 'Show "Key control" in Control description',
      default: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#keycontrol'
    },
    'risma.show_automatic_manual': {
      type: 'boolvalue',
      name: 'Show "Automatic/Manual" in Control description',
      default: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#automaticmanual'
    },
    'risma.show_preventive_detective': {
      type: 'boolvalue',
      name: 'Show "Detective/Preventive" in Control description',
      default: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#detectivepreventive'
    },
    'risma.show_high_complexity_low_complexity': {
      type: 'boolvalue',
      name: 'Show "High/Low complexity" in Control description',
      default: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#complexity'
    },
    'feature.controls_confidential_enabled': {
      type: 'boolvalue',
      name: 'Allow confidential controls',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#confidentialcontrols'
    },
    'feature.controls_overdue_completed_color': {
      type: 'stringvalue',
      name: 'Trafficlight color for overdue, but completed controls',
      default: '#A80000',
      dropdown: {
        '#A80000': 'Red',
        '#3CDFFF': 'Blue',
        '#FF6D0A': 'Orange',
        '#9300FF': 'Purple'
      },
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#trafficlightcontrols'
    },
    'feature.controls_default_activity_type_id': {
      type: 'activityType',
      default: null,
      name: 'Default activity type (regular control)',
      isSorted: true,
      withReset: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#defaultactivitytypecontrol'
    },
    'feature.mother_controls_default_activity_type_id': {
      default: null,
      type: 'activityType',
      name: 'Default activity type (mother control)',
      isSorted: true,
      withReset: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls#defaultactivitytype'
    }
  },
  RISMAactions: {
    'risma.traffic_light_automatic': {
      type: 'boolvalue',
      name: 'Enable automatic traffic lights for initiatives',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls-1#automatictrafficlights'
    },
    'risma.depending_on_name': {
      type: 'stringvalue',
      name: 'Change "Depending on" to a different name',
      default: 'Depending on',
      notEmpty: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls-1#changedependingon'
    },
    'risma.precursor_to_name': {
      type: 'stringvalue',
      name: 'Change "Precursor to" to a different name',
      default: 'Precursor to',
      notEmpty: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls-1#renameprecursor'
    },
    'risma.first_template': {
      type: 'intvalue',
      name: 'Actions above this number are templates',
      default: '500',
      minValue: 1,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls-1#numbertemplates'
    },
    'feature.actions_default_activity_type_id': {
      type: 'activityType',
      default: null,
      name: 'Default activity type',
      isSorted: true,
      withReset: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-controls-1#defaultactivitytype'
    }
  },
  RISMArisk: {
    'risma.risktype_name': {
      type: 'stringvalue',
      name: 'Change "Risk Type" to a different name',
      notEmpty: true,
      default: 'Risk type',
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#renamerisktype'
    },
    'risma.risk_calculations_enabled': {
      type: 'boolvalue',
      name: 'Enable risk calculations',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#riskcalculations'
    },
    'feature.risk_comments_field_on_axis': {
      type: 'boolvalue',
      name: 'Automatic expanded comment fields',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#expandedcomments'
    },
    'risma.risk_calculation_formula': {
      type: 'intvalue',
      name: 'Risk grade calculation formula',
      default: '0',
      dropdown: {
        0: '(I*I)/2*L (Impact as horizontal/X-axis)',
        1: '(I*I)/2*L (Impact as vertical/Y-axis)',
        2: 'RPI'
      },
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#riskcalculationformula'
    },
    'risma.riskapproval_enabled': {
      type: 'boolvalue',
      name: 'Enable risk approval for Enterprise risk management',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#riskapprovalERM'
    },
    'feature.risk_hide_tolerance': {
      type: 'boolvalue',
      name: 'Hide tolerance values and gap for Enterprise risk management',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#hidetolerancevalues'
    },
    'risma.risk_degree_name': {
      type: 'stringvalue',
      name: 'Change "Risk degree" to a different name',
      notEmpty: true,
      default: 'Risk degree',
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#riskdegreenames'
    },
    'feature.risk_assessment_start_expanded': {
      type: 'boolvalue',
      name: 'Automatic expanded consequence parameters',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#expandedconsequenceparameters'
    },
    'feature.show_risk_links_with_levels': {
      type: 'boolvalue',
      name: 'Show risk values on risk links',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#riskvalueslinks'
    },
    'feature.custom_risk_assessment_threats': {
      type: 'boolvalue',
      name: 'Enable custom risk assessment threats',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-actions#customriskthreats'
    }
  },
  RISMAcompliance: {
    'feature.breadcrumbs': {
      type: 'boolvalue',
      name: 'Enable "breadcrumbs" on top of node edit',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoenablebreadcrumbs'
    },
    'feature.dataflow': {
      type: 'boolvalue',
      name: 'Enable data flow tab on node edit',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoenabledataflowonnode'
    },
    'feature.data_flow_comments': {
      type: 'boolvalue',
      name: 'Allow comments on data flow',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoallowcommentsondataflow'
    },
    'risma.single_choice_as_dropdown': {
      type: 'boolvalue',
      name: 'Questionnaire: Show single-select questions as dropdown',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoshowsingleselectquestions'
    },
    'risma.multiple_choice_as_checkbox': {
      type: 'boolvalue',
      name: 'Questionnaire: Show multiple-choice questions as checkboxes',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoshowmultiplechoicequestions'
    },
    'feature.ia_questions_include_subprocessors': {
      type: 'boolvalue',
      name: 'Questionnaire: Include subprocessors as options',
      default: true,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoincludesubprocessorsasoptions'
    },
    'feature.raci_names_responsible': {
      type: 'stringvalue',
      name: 'Change RACI "Responsible" to a different name',
      notEmpty: true,
      default: 'Responsible',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangeracinames'
    },
    'feature.raci_names_accountable': {
      type: 'stringvalue',
      name: 'Change RACI "Accountable" to a different name',
      notEmpty: true,
      default: 'Accountable',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangeracinames'
    },
    'feature.raci_names_consulted': {
      type: 'stringvalue',
      name: 'Change RACI "Consulted" to a different name',
      notEmpty: true,
      default: 'Consulted',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangeracinames'
    },
    'feature.raci_names_informed': {
      type: 'stringvalue',
      name: 'Change RACI "Informed" to a different name',
      notEmpty: true,
      default: 'Informed',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangeracinames'
    },
    'feature.add_assets_from_questionnaire': {
      type: 'boolvalue',
      name: 'Create new assets from questionnaire',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Createnewassetsfromquestionnaire'
    },
    'feature.show_full_list_of_questionnaires': {
      type: 'boolvalue',
      name: 'Display questionnaire as a list',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Displayquestionnaireasalist'
    },
    'feature.data_flow_systems_name': {
      type: 'stringvalue',
      name: 'Change data flow category “Third parties” to a different name',
      notEmpty: true,
      default: 'Third parties',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangesystemstoadifferentterm'
    },
    'feature.show_full_list_of_gaps': {
      type: 'boolvalue',
      name: 'Display gaps as a list',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Displaygapsasalist'
    },
    'feature.show_node_id': {
      type: 'boolvalue',
      name: 'Show node id near title',
      default: false,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoshownodeidneartitle'
    },
    'feature.data_flow_stakeholders_name': {
      type: 'stringvalue',
      name: 'Change data flow category “Data subjects” to a different name',
      notEmpty: true,
      default: 'Data subjects',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangedataflowcategory'
    }
  },
  'Information Assets': {
    'feature.information_assets_name': {
      type: 'stringvalue',
      name: 'Change "Information Assets" to a different name',
      notEmpty: true,
      default: 'Information Assets',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangeiatoadifferentterm'
    },
    'feature.enable_data_controllers': {
      type: 'boolvalue',
      name: 'Enable data controllers',
      default: true,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoenabledataprocessorscontrollerssystems'
    },
    'feature.enable_data_processors': {
      type: 'boolvalue',
      name: 'Enable data processors',
      default: true,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoenabledataprocessorscontrollerssystems'
    },
    'feature.enable_systems': {
      type: 'boolvalue',
      name: 'Enable systems',
      default: true,
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtoenabledataprocessorscontrollerssystems'
    }
  },
  'Process Library': {
    'feature.process_library_name': {
      type: 'stringvalue',
      name: 'Change "Process Library" to a different name',
      notEmpty: true,
      default: 'Process Library',
      link: 'https://support.rismasystems.com/enable-breadcrumbs#Howtochangepltoadifferentterm'
    },
    'feature.process_library_default_activity_type_id': {
      default: 0,
      type: 'activityType',
      name: 'Default activity type',
      withReset: true,
      isSorted: true,
      link: ' https://support.rismasystems.com/compliance-control-panel#HowtosetDefaultactivitytype '
    }
  },
  Contracts: {
    'feature.contract_id_sequence': {
      type: 'intvalue',
      name: 'Contract id start number',
      default: 1,
      minValue: 1,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-contracts#idstartnumber'
    },
    'feature.contracts_default_currency': {
      type: 'intvalue',
      name: 'Default currency',
      default: '',
      dropdown: getCurrency(),
      translatable: true,
      withReset: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-contracts#defaultcurrency'
    },
    'feature.contracts_confidential_enabled': {
      type: 'boolvalue',
      name: 'Allow confidential contracts',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-contracts#confidentialcontracts'
    },
    'feature.contracts_default_notification_days': {
      type: 'intvalue',
      name: 'Default notification is sent out',
      default: '',
      withReset: true,
      translatable: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-contracts#defaultnotification',
      dropdown: getDate()
    }
  },
  App: {
    'app.app_token_valid_number_days': {
      type: 'intvalue',
      name: 'Login token valid for number of days',
      default: 90,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-apps',
      minValue: 0,
      maxValue: 365
    }
  },
  Incidents: {
    'feature.show_incident_id': {
      type: 'boolvalue',
      name: 'Show incident id near title',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-incidents#showincidentid'
    },
    'feature.incident_confidential_enabled': {
      type: 'boolvalue',
      name: 'Allow confidential incidents',
      default: false,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-incidents#confidentialincidents'
    },
    'feature.incident_anonymous_creation_enabled': {
      type: 'boolvalue',
      name: 'Activate anonymous incident creation',
      default: 0,
      rules: [{
        setting: 'feature.incident_anonymous_activity_type_id',
        rule: function rule(settingVal) {
          return settingVal !== null;
        },
        warningMessage: 'Before activating the anonymous incident flag, make sure to select a default activity type'
      }],
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-incidents'
    },
    'feature.incident_anonymous_activity_type_id': {
      type: 'activityType',
      default: null,
      name: 'Default activity type for anonymous incidents',
      withReset: true,
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-incidents'
    },
    'feature.incidents.no_responsible': {
      type: 'boolvalue',
      default: false,
      name: 'Enable incidents without an owner',
      link: 'https://support.rismasystems.com/how-to-adjust-functionalities-for-incidents#incidents-without-an-owner'
    }
  }
};
export var getContractUpdatedSettings = function getContractUpdatedSettings() {
  SETTINGS_ARRAY.Contracts['feature.contracts_default_notification_days'].dropdown = getDate();
  return SETTINGS_ARRAY;
};