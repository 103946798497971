import SelectorTags from '@/components/Molecules/SelectorTags';
import { MODULES } from '@/constants/modules';
export default {
  components: {
    SelectorTags: SelectorTags
  },
  data: function data() {
    return {
      module: MODULES.COMPLIANCE_IA_SYSTEM,
      tags: [{
        objType: 'compliance-informationAsset-system',
        id: 1,
        title: 'System1',
        trafficLight: 1
      }, {
        objType: 'compliance-informationAsset-system',
        id: 2,
        title: 'System2',
        trafficLight: 0
      }, {
        objType: 'compliance-informationAsset-system',
        id: 17,
        title: 'SystemWithQuestionnaireAndAnswers',
        trafficLight: 0
      }, {
        objType: 'compliance-informationAsset-system',
        id: 22,
        title: 'Test System1',
        trafficLight: 2
      }],
      selectedTag: {}
    };
  }
};