import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "py-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_monthly_overview = _resolveComponent("monthly-overview");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_monthly_overview, {
    "dataset-months": $options.dataset,
    onActive: $options.setActiveMonth
  }, null, 8 /* PROPS */, ["dataset-months", "onActive"])]);
}