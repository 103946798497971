import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "controls-column-left mr-20"
};
var _hoisted_3 = {
  class: "controls-column-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_admin_controls_well = _resolveComponent("admin-controls-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    class: "mb-5",
    title: $data.translate.controlsAdministration,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_admin_controls_well, {
    title: $options.accountName,
    items: $data.accounts,
    loading: $data.loadingAccounts,
    "is-accounts-well": true,
    onCreateItem: $options.createNewAccount,
    onDeleteItem: $options.deleteAccount,
    onEditItem: $options.editAccount
  }, null, 8 /* PROPS */, ["title", "items", "loading", "onCreateItem", "onDeleteItem", "onEditItem"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_admin_controls_well, {
    title: $options.processName,
    items: $data.processes,
    loading: $data.loadingProcesses,
    class: "mb-10",
    onCreateItem: $options.createNewProcess,
    onDeleteItem: $options.deleteProcess,
    onEditItem: $options.editProcess
  }, null, 8 /* PROPS */, ["title", "items", "loading", "onCreateItem", "onDeleteItem", "onEditItem"]), _createVNode(_component_admin_controls_well, {
    title: $options.riskName,
    items: $data.risks,
    loading: $data.loadingRisks,
    "risk-types": $options.riskTypesForSingleSelect,
    class: "mb-10",
    onCreateItem: $options.createNewRisk,
    onDeleteItem: $options.deleteRisk,
    onEditItem: $options.editRisk
  }, null, 8 /* PROPS */, ["title", "items", "loading", "risk-types", "onCreateItem", "onDeleteItem", "onEditItem"]), _createVNode(_component_admin_controls_well, {
    title: $data.translate.controlRiskType,
    items: $options.getControlRiskTypesExtended,
    loading: $data.loadingControlRiskTypes,
    onCreateItem: $options.createNewControlRiskType,
    onDeleteItem: $options.deleteControlRiskType,
    onEditItem: $options.editControlRiskType
  }, null, 8 /* PROPS */, ["title", "items", "loading", "onCreateItem", "onDeleteItem", "onEditItem"])])])]);
}