import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "border border-gray-450 rounded rounded-tl-none px-6 py-3 mb-10"
};
var _hoisted_2 = {
  class: "flex mb-5"
};
var _hoisted_3 = {
  key: 0,
  class: "w-1/2"
};
var _hoisted_4 = {
  class: "font-bold"
};
var _hoisted_5 = {
  key: 0,
  class: "w-8/25"
};
var _hoisted_6 = {
  class: "flex items-baseline"
};
var _hoisted_7 = {
  key: 0,
  class: "w-8/25"
};
var _hoisted_8 = {
  key: 0,
  class: "w-8/25 mb-4"
};
var _hoisted_9 = {
  key: 1,
  class: "flex items-baseline mb-4"
};
var _hoisted_10 = {
  key: 0,
  class: "flex items-baseline"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("form", {
    onSubmit: _cache[9] || (_cache[9] = _withModifiers(function () {
      return $options.handleSearch && $options.handleSearch.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_risma_title, {
    type: "big",
    title: $props.title
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [$props.helperText ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.helperText), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "flex ml-auto justify-end items-center text-blue-750 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleResetFilters && $options.handleResetFilters.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    class: "mr-1 stroke-2",
    icon: "rotate-ccw",
    height: "18",
    width: "18"
  }), _createElementVNode("span", _hoisted_4, _toDisplayString($data.translate.resetFilter), 1 /* TEXT */)])]), _createElementVNode("div", {
    class: _normalizeClass(!$props.nodesFilterEnabled ? 'flex justify-between' : '')
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex justify-between mb-3", !$props.nodesFilterEnabled ? 'w-49/100' : ''])
  }, [_createElementVNode("div", {
    class: _normalizeClass(!$props.nodesFilterEnabled ? 'w-12/25' : 'w-8/25')
  }, [_renderSlot(_ctx.$slots, "inputSlot", {
    handleSearch: $options.handleSearch
  })], 2 /* CLASS */), $props.nodesFilterEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_select, {
    title: $data.translate.node,
    options: $data.nodes,
    "selected-options": $data.selectedNodes,
    placeholder: $data.translate.nodes,
    "label-key": "formattedTitle",
    class: "mb-2",
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedNodes = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_toggle, {
    modelValue: $data.includeChildNodes,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.includeChildNodes = $event;
    }),
    class: "mr-1 switcher"
  }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("span", null, _toDisplayString($data.translate.includeUnderlyingNodes), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(!$props.nodesFilterEnabled ? 'w-12/25' : 'w-8/25')
  }, [_createVNode(_component_risma_select, {
    title: $data.translate.tags,
    options: _ctx.tags,
    "selected-options": $data.selectedTags,
    placeholder: $data.translate.tags,
    "label-key": "tag",
    onSelected: _cache[3] || (_cache[3] = function ($event) {
      return $data.selectedTags = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])], 2 /* CLASS */)], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass(["flex justify-between mb-4", !$props.nodesFilterEnabled ? 'w-49/100' : ''])
  }, [_createElementVNode("div", {
    class: _normalizeClass(!$props.nodesFilterEnabled ? 'w-12/25' : 'w-8/25')
  }, [_createVNode(_component_risma_select, {
    title: $data.translate.organisation,
    options: _ctx.organisations,
    "selected-options": $data.selectedOrganisations,
    placeholder: $data.translate.organisations,
    "label-key": "visible_name",
    onSelected: _cache[4] || (_cache[4] = function ($event) {
      return $data.selectedOrganisations = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass(!$props.nodesFilterEnabled ? 'w-12/25' : 'w-8/25')
  }, [_createVNode(_component_risma_select, {
    title: _ctx.responsibleTitle(true),
    options: $options.filteredUsers,
    "selected-options": $data.selectedUsers,
    placeholder: $data.translate.users,
    "label-key": "display_name",
    onSelected: _cache[5] || (_cache[5] = function ($event) {
      return $data.selectedUsers = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])], 2 /* CLASS */), $props.nodesFilterEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_select, {
    title: $data.translate.attributes,
    options: $options.attributesOptions,
    "selected-options": $data.selectedAttributes,
    placeholder: $data.translate.attributes,
    "label-key": "title",
    onSelected: _cache[6] || (_cache[6] = function ($event) {
      return $data.selectedAttributes = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */), $props.nodesFilterEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_renderSlot(_ctx.$slots, "gapTypesSlot")])) : _createCommentVNode("v-if", true), $props.scoringToggleEnabled || $props.hasLiaTiaFilter && $options.radioButtonsOptions.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [$props.scoringToggleEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_toggle, {
    modelValue: $data.scoringIncluded,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.scoringIncluded = $event;
    }),
    class: "mr-1 switcher"
  }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("span", null, _toDisplayString($data.translate.includeScoringInReportWillAffectPerformance), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.hasLiaTiaFilter && $options.radioButtonsOptions.length > 1 ? (_openBlock(), _createBlock(_component_radio_buttons, {
    key: 1,
    modelValue: $data.selectedRadio,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.selectedRadio = $event;
    }),
    class: "flex ml-12",
    options: $options.radioButtonsOptions
  }, null, 8 /* PROPS */, ["modelValue", "options"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: _ctx.$trans($props.searchBtnText),
    type: "save",
    class: "text-right",
    onClick: $options.handleSearch
  }, null, 8 /* PROPS */, ["text", "onClick"])])], 32 /* NEED_HYDRATION */)]);
}