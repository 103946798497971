function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import MainWell from '@/components/Atoms/Containers/MainWell';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import TabList from '@/components/Atoms/TabList';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { getTranslate } from './translate';
import { getAvailableGapSchemas, getAvailableProjects, getAvailableQuestionnaires, getSyncDatabaseStatus, getSyncedGapSchemas, getSyncedProjects, getSyncedQuestionnaires, synchroniseProjects } from '@/api/rismaadmin/sync';
import { patchUtilSettings } from '@/api/risma/settings';
import { cacheItem } from '@/routes/storeCache';
var CONNECTION_STATUS_SUCCESS = 'OK';
export default {
  name: 'SyncAdmin',
  components: {
    RismaButton: RismaButton,
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    TabList: TabList,
    RismaTable: RismaTable,
    MainWell: MainWell,
    InputField: InputField,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      confirmationMessage: '',
      connectionStatus: '',
      translate: getTranslate['SyncAdmin'](),
      currentTab: '',
      availableProjects: null,
      selectedTargetProject: null,
      selectedSourceProject: null,
      tabNames: {
        questionnaire: 'questionnaire',
        gapSchema: 'gapSchema',
        project: 'project'
      },
      dbName: '',
      loading: false,
      statusLoaded: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    tabs: function tabs() {
      return [{
        name: this.translate.questionnaires,
        slotName: this.tabNames.questionnaire
      }, {
        name: this.translate.syncedGapSchemas,
        slotName: this.tabNames.gapSchema
      }, {
        name: this.translate.syncedProjects,
        slotName: this.tabNames.project
      }];
    },
    sourceProjects: function sourceProjects() {
      var _this$availableProjec;
      if (!((_this$availableProjec = this.availableProjects) !== null && _this$availableProjec !== void 0 && _this$availableProjec.sourceProjects)) {
        return [];
      }
      return this.availableProjects.sourceProjects.map(function (project) {
        return {
          label: project.title,
          value: project.id
        };
      });
    },
    targetProjects: function targetProjects() {
      var _this$availableProjec2;
      if (!((_this$availableProjec2 = this.availableProjects) !== null && _this$availableProjec2 !== void 0 && _this$availableProjec2.targetProjects)) {
        return [];
      }
      return this.availableProjects.targetProjects.map(function (project) {
        return {
          label: project.title,
          value: project.id
        };
      });
    },
    connectionStatusIsSuccessful: function connectionStatusIsSuccessful() {
      return this.connectionStatus === CONNECTION_STATUS_SUCCESS;
    }
  }),
  mounted: function mounted() {
    this.currentTab = this.tabNames.questionnaire;
    this.dbName = this.getSettingValue('app.sync_db_name');
    this.updateDBStatus();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    handleDBStatusClick: function handleDBStatusClick() {
      var _this = this;
      var regexp = new RegExp('^risma_[a-z0-9]*_risma_[a-z]{2,3}$');
      if (!this.dbName || regexp.test(this.dbName)) {
        this.updateDBSetting().then(this.updateDBStatus).finally(function () {
          return _this.setNotification('Template DB updated', 'success');
        });
      } else {
        this.setNotification('Must be on the form risma_{subdomain}_risma_{top_domain} fx: risma_csrdmaster_risma_eu', 'error');
      }
    },
    updateDBSetting: function updateDBSetting() {
      var _this2 = this;
      return patchUtilSettings({
        'app.sync_db_name': this.dbName
      }).then(function () {
        return cacheItem('settings', function () {
          return _this2.loadSettings();
        }, 180);
      });
    },
    updateDBStatus: function updateDBStatus() {
      var _this3 = this;
      return getSyncDatabaseStatus().then(function (response) {
        _this3.connectionStatus = response;
        _this3.statusLoaded = true;
        _this3.getAvailableProjects();
      });
    },
    setNotification: function setNotification(notification) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      this.$notify({
        title: notification,
        type: type
      });
    },
    getSyncedQuestionnaireData: function getSyncedQuestionnaireData(params) {
      var _this4 = this;
      return this.getSyncedData('syncedQuestionnaires', params).catch(function (err) {
        var _err$response;
        _this4.setNotification(err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error);
        throw err;
      });
    },
    getAvailableQuestionnaireData: function getAvailableQuestionnaireData(params) {
      var _this5 = this;
      return this.getSyncedData('availableQuestionnaires', params).catch(function (err) {
        var _err$response2;
        _this5.setNotification(err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.error);
        throw err;
      });
    },
    getAvailableGapSchemas: function getAvailableGapSchemas(params) {
      var _this6 = this;
      return this.getSyncedData('availableGapSchemas', params).catch(function (err) {
        var _err$response3;
        _this6.setNotification(err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.error);
        throw err;
      });
    },
    getSyncedGapSchemas: function getSyncedGapSchemas(params) {
      var _this7 = this;
      return this.getSyncedData('syncedGapSchemas', params).catch(function (err) {
        var _err$response4;
        _this7.setNotification(err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.error);
        throw err;
      });
    },
    getSyncedProjects: function getSyncedProjects(params) {
      var _this8 = this;
      return this.getSyncedData('syncedProjects', params).catch(function (err) {
        var _err$response5;
        _this8.setNotification(err === null || err === void 0 || (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.error);
        throw err;
      });
    },
    getAvailableProjects: function getAvailableProjects() {
      var _this9 = this;
      this.getSyncedData('availableProjects').then(function (response) {
        _this9.availableProjects = response;
      });
    },
    onSynchronizeConfirm: function onSynchronizeConfirm() {
      var _this10 = this;
      this.loading = true;
      synchroniseProjects({
        sourceProjectId: this.selectedSourceProject,
        targetProjectId: this.selectedTargetProject
      }).then(function (response) {
        _this10.setNotification(response, 'success');
        _this10.reloadProjectTable();
      }).catch(function (err) {
        _this10.setNotification(err.response.error);
      }).finally(function () {
        return _this10.loading = false;
      });
    },
    synchroniseProject: function synchroniseProject() {
      var _this11 = this;
      if (!this.selectedTargetProject || !this.selectedSourceProject) {
        this.setNotification('You must select two projects');
        return;
      }
      var sourceProjects = this.availableProjects.sourceProjects;
      var targetProjects = this.availableProjects.targetProjects;
      var sourceProject = sourceProjects.find(function (project) {
        return project.id === _this11.selectedSourceProject;
      });
      var targetProject = targetProjects.find(function (project) {
        return project.id === _this11.selectedTargetProject;
      });
      this.confirmationMessage = "Are you sure you want to synchronise ".concat(sourceProject.title, " to ").concat(targetProject.title, "?\n\n            ").concat(targetProject.nodes_count, " nodes will be replaced with ").concat(sourceProject.nodes_count, " from the template!\n            ");
      this.$confirm('', this.confirmationMessage, function (res) {
        return res && _this11.onSynchronizeConfirm();
      });
    },
    getSyncedData: function getSyncedData(entity, params) {
      if (!this.connectionStatusIsSuccessful) return Promise.resolve([]);
      var entities = {
        syncedQuestionnaires: getSyncedQuestionnaires(params),
        availableQuestionnaires: getAvailableQuestionnaires(params),
        availableGapSchemas: getAvailableGapSchemas(params),
        syncedGapSchemas: getSyncedGapSchemas(params),
        availableProjects: getAvailableProjects(),
        syncedProjects: getSyncedProjects(params)
      };
      return entities[entity];
    },
    reloadQuestionnaireTables: function reloadQuestionnaireTables() {
      this.$refs.syncedQuestionnaires.getData();
      this.$refs.availableQuestionnaires.getData();
    },
    reloadGapTables: function reloadGapTables() {
      this.$refs.syncedGapSchemas.getData();
      this.$refs.availableGapSchemas.getData();
    },
    reloadProjectTable: function reloadProjectTable() {
      this.$refs.syncedProjects.getData();
    }
  })
};