import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import QuizQuestionnaire from '@/components/Organisms/Compliance/QuizQuestionnaire';
import { getQuizQuestionnaires, createQuizQuestionnaire, deleteQuizQuestionnaire, getQuizQuestionnaireById, updateQuizQuestionnaire } from '@/api/compliance/quiz';
import { generateKey } from '@/utils/Utils';
export default {
  components: {
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    RismaTable: RismaTable,
    QuizQuestionnaire: QuizQuestionnaire,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  data: function data() {
    return {
      stateKey: 'awareness_quiz_questionnaire',
      tableGeneratedKey: generateKey(),
      currentIndex: 1,
      quiz: {},
      isModalOpened: false,
      isLoaded: true,
      modalLoadState: false,
      translate: getTranslate['AwarenessQuizOverview']()
    };
  },
  computed: {
    quizQuestionnaireTitle: function quizQuestionnaireTitle() {
      return this.translate.questionnaire + ' #' + this.currentIndex;
    }
  },
  methods: {
    handleIconClick: function handleIconClick(_ref) {
      var _this = this;
      var id = _ref.id;
      return getQuizQuestionnaireById(id).then(function (response) {
        _this.quiz = response;
        _this.isModalOpened = true;
      });
    },
    updateCurrentQuiz: function updateCurrentQuiz(quiz) {
      var _this2 = this;
      this.modalLoadState = true;
      this.dismissModal();
      return updateQuizQuestionnaire(quiz.id, quiz).then(function () {
        _this2.$refs.quizTable.getData();
        _this2.$notify({
          title: _this2.translate.questionnaireUpdated
        });
      }).catch(function (error) {
        return _this2.$notify({
          title: error,
          type: 'error'
        });
      }).finally(function () {
        return _this2.modalLoadState = false;
      });
    },
    getQuizQuestionnairesBE: function getQuizQuestionnairesBE(params) {
      var _this3 = this;
      this.isLoaded = false;
      return getQuizQuestionnaires(params).then(function (response) {
        _this3.isLoaded = true;
        _this3.currentIndex = response.data.length + 1;
        return Promise.resolve(response);
      });
    },
    handleOpenQuizQuestionnaireModal: function handleOpenQuizQuestionnaireModal() {
      this.isModalOpened = true;
      this.quiz = {
        title: this.quizQuestionnaireTitle,
        questions: []
      };
    },
    dismissModal: function dismissModal() {
      this.isModalOpened = false;
      this.quiz = {};
    },
    createNewQuiz: function createNewQuiz(data) {
      var _this4 = this;
      this.modalLoadState = true;
      return createQuizQuestionnaire(data).then(function () {
        _this4.dismissModal();
        _this4.$refs.quizTable.getData();
        _this4.$notify({
          title: _this4.translate.questionnaireUploaded
        });
      }).catch(function (error) {
        return _this4.$notify({
          title: error,
          type: 'error'
        });
      }).finally(function () {
        return _this4.modalLoadState = false;
      });
    },
    onDelete: function onDelete(_ref2) {
      var _this5 = this;
      var title = _ref2.title,
        id = _ref2.id;
      this.isLoaded = false;
      return deleteQuizQuestionnaire(id).then(function () {
        _this5.$notify({
          title: title + ' ' + _this5.translate.wasSuccessfullyDeleted
        });
        _this5.$refs.quizTable.getData();
      }).catch(function (error) {
        return _this5.$notify({
          title: error,
          type: 'error'
        });
      });
    },
    handleDeleteQuiz: function handleDeleteQuiz(data) {
      var _this6 = this;
      this.$confirm('', "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(data.title, "?"), function (res) {
        return res && _this6.onDelete(data);
      });
    }
  }
};