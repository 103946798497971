import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_controls_review = _resolveComponent("controls-review");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: "",
    "show-buttons": false,
    "dismiss-button-disabled": $data.interactionDisabled,
    "modal-fixed-width": "70vw",
    onDismiss: $options.onDismiss
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_controls_review, {
        id: $props.id,
        cpath: $props.cpath,
        "onUpdate:interactionDisabled": $options.setInteractionDisabled,
        onDataUpdated: _cache[0] || (_cache[0] = function ($event) {
          return $data.isUpdated = true;
        }),
        onUpdate: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('update', $event);
        }),
        onClose: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.$emit('close');
        })
      }, null, 8 /* PROPS */, ["id", "cpath", "onUpdate:interactionDisabled"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dismiss-button-disabled", "onDismiss"]);
}