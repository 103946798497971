// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-enter-active[data-v-3d93d6ec],
.slide-leave-active[data-v-3d93d6ec] {
  transition: transform 1.2s ease-in-out;
}
.slide-enter-from[data-v-3d93d6ec],
.slide-leave-to[data-v-3d93d6ec] {
  transform: translateX(100%);
}
[data-v-3d93d6ec]  a {
  --tw-text-opacity: 1;
  color: rgb(139 139 139 / var(--tw-text-opacity));
}
[data-v-3d93d6ec]  a:hover:not(.disabled-hover) {
  text-decoration: underline;
  --tw-text-opacity: 1;
  color: rgb(47 82 200 / var(--tw-text-opacity));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
