import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "container mx-auto"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1,
  class: ""
};
var _hoisted_6 = {
  key: 2
};
var _hoisted_7 = {
  key: 3
};
var _hoisted_8 = {
  key: 4,
  class: "relavent"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_create_initiative_modal = _resolveComponent("create-initiative-modal");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_preview_list_expanded = _resolveComponent("preview-list-expanded");
  var _component_compact_card = _resolveComponent("compact-card");
  var _component_tree = _resolveComponent("tree");
  var _component_gantt_chart_actions = _resolveComponent("gantt-chart-actions");
  var _component_pagination = _resolveComponent("pagination");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.overview,
    type: "big",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), $data.showActionCreationModal ? (_openBlock(), _createBlock(_component_create_initiative_modal, {
    key: 0,
    preset: {
      parentId: $data.nodeId || -1
    },
    onAccept: $options.handleCreateAction,
    onDismiss: $options.toggleActionCreationModal
  }, null, 8 /* PROPS */, ["preset", "onAccept", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_catalogue_header, {
    "view-type": $setup.currentView,
    "view-types": $data.OVERALL_INITIATIVES_VIEWS,
    "is-overview-options-available": false,
    "overview-options-state": $data.overviewOptionsState,
    "onUpdate:viewType": $options.updateDisplayState,
    "onUpdate:overviewoption": _cache[0] || (_cache[0] = function (state) {
      return $data.overviewOptionsState = state;
    })
  }, {
    actions: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [$options.showCreateBtn ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $data.translate.createOverallAction,
        type: "save",
        onClick: $options.toggleActionCreationModal
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["view-type", "view-types", "overview-options-state", "onUpdate:viewType"]), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$setup.currentView === $data.InitiativesViews.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_preview_list_tile, {
    activities: $data.activities,
    "cookie-hidden-name": "actions-list-tiles",
    "watermark-image": "url('/assets/svg/ikon_execution.svg')",
    "activity-types": _ctx.activityTypes,
    onDeleteItem: $options.deleteAction,
    onUpdateFromPreviewMode: $options.updateFromPreviewMode,
    onOnDismissModal: $options.onPreviewDismiss
  }, null, 8 /* PROPS */, ["activities", "activity-types", "onDeleteItem", "onUpdateFromPreviewMode", "onOnDismissModal"])])) : _createCommentVNode("v-if", true), $setup.currentView === $data.InitiativesViews.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_preview_list_expanded, {
    "preview-mode": true,
    activities: $data.activities,
    "cookie-hidden-name": "actions-list-expanded",
    tags: _ctx.tags,
    users: _ctx.activeUsers,
    organisations: _ctx.organisations,
    "activity-types": _ctx.activityTypes,
    "watermark-image": "url('/assets/svg/ikon_execution.svg')",
    onUpdateFromPreviewMode: $options.updateFromPreviewMode,
    onOnDismissModal: $options.onPreviewDismiss,
    onDeleteItem: $options.deleteAction
  }, null, 8 /* PROPS */, ["activities", "tags", "users", "organisations", "activity-types", "onUpdateFromPreviewMode", "onOnDismissModal", "onDeleteItem"])])) : _createCommentVNode("v-if", true), $setup.currentView === $data.InitiativesViews.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_compact_card, {
    module: $data.MODULES.EXECUTION,
    activities: $options.compactDataset,
    users: _ctx.activeUsers,
    organisations: _ctx.organisations,
    "activity-types": _ctx.activityTypes,
    "watermark-image": "url('/assets/svg/ikon_execution.svg')",
    onUpdated: $options.updateFromPreviewMode,
    onOnDismissModal: $options.onPreviewDismiss,
    onDeleteItem: $options.deleteAction
  }, null, 8 /* PROPS */, ["module", "activities", "users", "organisations", "activity-types", "onUpdated", "onOnDismissModal", "onDeleteItem"])])) : _createCommentVNode("v-if", true), $setup.currentView === $data.InitiativesViews.TREE ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_tree, {
    ref: "tree",
    "tree-id": $data.treeId,
    "tree-title": $data.treeTitle,
    "level-select": true,
    "dataflow-toolbar": false,
    "tree-data": $data.treeData,
    "do-move": false,
    "tree-rights": false,
    "lock-tree": !$options.userHasLockAccess,
    "disable-item-menu": true,
    "disable-progress-shown": true,
    "enable-add-button": $options.userCanCreate,
    "new-child": $data.newChild,
    "has-underlying-modal-feature": true,
    "class-name-to-tree": "actions-tree",
    class: "initiatives-tree",
    "show-levels-classes": "right-80 -top-75px",
    onUpdateFromPreviewMode: $options.updateFromPreviewMode,
    onAddChild: $options.addTreeChild,
    onAddUnderlyingFromModal: $options.toggleActionCreationModal,
    onMove: $options.tryMove,
    onReload: $options.reloadData,
    onDeleteItem: $options.deleteActionFromTree
  }, null, 8 /* PROPS */, ["tree-id", "tree-title", "tree-data", "lock-tree", "enable-add-button", "new-child", "onUpdateFromPreviewMode", "onAddChild", "onAddUnderlyingFromModal", "onMove", "onReload", "onDeleteItem"])])) : _createCommentVNode("v-if", true), $setup.currentView === $data.InitiativesViews.ROADMAP ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", {
    class: "py-8",
    style: _normalizeStyle($options.ganttStyles)
  }, [$options.activitiesSlice.length ? (_openBlock(), _createBlock(_component_gantt_chart_actions, {
    key: 0,
    "has-preview": true,
    activities: $options.activitiesSlice,
    "is-sorting-visible": false,
    onUpdateFromPreviewMode: _cache[1] || (_cache[1] = function ($event) {
      return $data.updatedActionFromPreview = $event;
    }),
    onDismissModal: $options.onPreviewDismiss
  }, null, 8 /* PROPS */, ["activities", "onDismissModal"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */)])) : _createCommentVNode("v-if", true), $setup.currentView === $data.InitiativesViews.ROADMAP ? (_openBlock(), _createBlock(_component_pagination, {
    key: 5,
    total: $data.activities.length,
    "per-page": $data.itemsPerPage,
    "show-prev-next-buttons": true,
    onChange: $options.handleShownList,
    onTotalChanged: $options.handleShownList
  }, null, 8 /* PROPS */, ["total", "per-page", "onChange", "onTotalChanged"])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2,
    class: "mt-25vh"
  }))]);
}