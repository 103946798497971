function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var TinyMceBoxTypes = {
  COMMENT: 'comment'
};
export var configs = _defineProperty(_defineProperty({
  mini: {
    toolbar: 'bold italic underline strikethrough link | undo redo',
    plugins: ['link'],
    menubar: false
  },
  default: {
    toolbar: 'bold italic underline strikethrough | bullist numlist link | undo redo',
    plugins: ['advlist', 'link', 'lists'],
    menubar: false
  },
  expanded: {
    toolbar: 'image | bold italic | bullist numlist link | alignleft aligncenter alignright alignjustify | undo redo',
    plugins: ['advlist', 'code', 'link', 'lists', 'table'],
    menubar: false
  }
}, TinyMceBoxTypes.COMMENT, {
  toolbar: [],
  plugins: [],
  menubar: false,
  text_patterns: [],
  maxSymbolsPreview: 70
}), "all", {
  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
  quickbars_insert_toolbar: false,
  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist link | forecolor backcolor removeformat | fullscreen preview | ltr rtl | code',
  menubar: 'file edit format tools table tc help',
  plugins: ['preview', 'importcss', 'searchreplace', 'autolink', 'autosave', 'save', 'directionality', 'visualblocks', 'visualchars', 'fullscreen', 'image', 'link', 'media', 'code', 'table', 'charmap', 'nonbreaking', 'anchor', 'insertdatetime', 'advlist', 'lists', 'wordcount', 'help', 'charmap', 'quickbars']
});
export var configKeys = {};
Object.keys(configs).forEach(function (key) {
  configKeys[key.toUpperCase()] = key;
});