function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { getManagementReportByProjectId as _getManagementReportByProjectId, createManagementReport as _createManagementReport, updateManagementReport as _updateManagementReport, deleteManagementReportById } from '@/api/compliance/reports/managementReport';
import { getTreeByProjectId } from '@/api/compliance/tree';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import StaticTable from '@/components/Molecules/StaticTable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import DownloadLink from '@/components/Atoms/DownloadLink/DownloadLink';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import * as formatDate from '@/utils/date';
import { findPropById, findPropsByIds, getAllChildrenIds } from '@/utils/Utils';
import { formatNodes } from '@/utils/format/Compliance';
import { getTranslate } from '@/components/Pages/Compliance/Reports/translate';
export default {
  name: 'ComplianceManagementReport',
  description: 'Compliance management report',
  token: '<compliance-management-report :projectId="projectId"/>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    DownloadLink: DownloadLink,
    Modal: Modal,
    InputField: InputField,
    RismaSelect: RismaSelect,
    RismaToggle: RismaToggle
  },
  props: {
    projectId: {
      required: true,
      type: [String, Number],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      isLoading: true,
      managementReports: [],
      nodes: [],
      isCreateMode: false,
      showModal: false,
      includeChildNodesToggle: false,
      report: {
        reporttitle: '',
        reportcomment: '',
        id: null,
        closed: false,
        selectedNodes: []
      },
      showEmptyTitleError: false,
      translate: getTranslate['ComplianceManagementReport']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, ['activeUsers'])), {}, {
    dataset: function dataset() {
      var _this = this;
      return this.managementReports.map(function (report) {
        return {
          title: report.reporttitle,
          createdBy: findPropById(report.userId, _this.activeUsers, 'display_name'),
          latestRevision: formatDate.toLocalDateTime(report.updatedAt),
          nodes: findPropsByIds(report.nodeList, _this.nodes, 'title'),
          downloadLatest: _this.getDownloadLink(report.id),
          edit: report,
          delete: report,
          closed: report.closedAt ? _this.translate.yes : _this.translate.no
        };
      });
    },
    columns: function columns() {
      return [this.translate.title, this.translate.createdBy, this.translate.latestRevision, this.translate.nodes, this.translate.downloadLatest, this.translate.edit, this.translate.delete, this.translate.closed];
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoading = true;
      Promise.all([this.getManagementReportByProjectId(), this.getNodes()]).catch(function (err) {
        return _this2.$notify({
          title: err.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this2.isLoading = false;
      });
    },
    getManagementReportByProjectId: function getManagementReportByProjectId() {
      var _this3 = this;
      return _getManagementReportByProjectId(this.projectId).then(function (_ref) {
        var list = _ref.list;
        _this3.managementReports = list;
      });
    },
    getNodes: function getNodes() {
      var _this4 = this;
      return getTreeByProjectId(this.projectId).then(function (_ref2) {
        var children = _ref2.children;
        _this4.nodes = formatNodes(children, '', [], true);
      });
    },
    createManagementReport: function createManagementReport() {
      this.isLoading = true;
      return _createManagementReport(this.projectId, this.prepareReportData());
    },
    updateManagementReport: function updateManagementReport() {
      this.isLoading = true;
      return _updateManagementReport(this.projectId, this.report.id, _objectSpread(_objectSpread({}, this.prepareReportData()), {}, {
        closed: !!this.report.closed
      }));
    },
    prepareReportData: function prepareReportData() {
      var nodeList = [];
      if (this.includeChildNodesToggle) {
        nodeList = _toConsumableArray(new Set(this.report.selectedNodes.flatMap(function (node) {
          return getAllChildrenIds(node);
        })));
      } else {
        nodeList = this.report.selectedNodes.map(function (_ref3) {
          var id = _ref3.id;
          return id;
        });
      }
      return {
        reporttitle: this.report.reporttitle,
        reportcomment: this.report.reportcomment,
        nodeList: nodeList,
        description: ''
      };
    },
    confirmDelete: function confirmDelete(id) {
      var _this5 = this;
      this.$confirm(this.translate.delete, this.translate.areYouSure, function (res) {
        return res && _this5.deleteManagementReport(id);
      });
    },
    deleteManagementReport: function deleteManagementReport(id) {
      var _this6 = this;
      this.isLoading = true;
      return deleteManagementReportById(this.projectId, id).then(this.getData).catch(function (err) {
        return _this6.$notify({
          title: err.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this6.isLoading = false;
      });
    },
    getDownloadLink: function getDownloadLink(reportId) {
      return "compliance/projects/".concat(this.projectId, "/report/managementreport/").concat(reportId, "/download");
    },
    openModal: function openModal(_ref4) {
      var id = _ref4.id,
        reporttitle = _ref4.reporttitle,
        _ref4$reportcomment = _ref4.reportcomment,
        reportcomment = _ref4$reportcomment === void 0 ? '' : _ref4$reportcomment,
        closed = _ref4.closed,
        _ref4$nodeList = _ref4.nodeList,
        nodeList = _ref4$nodeList === void 0 ? [] : _ref4$nodeList;
      this.showModal = true;
      var selectedNodes = this.nodes.filter(function (_ref5) {
        var id = _ref5.id;
        return nodeList.includes(id);
      });
      this.report = {
        id: id,
        reporttitle: reporttitle,
        reportcomment: reportcomment,
        closed: closed,
        selectedNodes: selectedNodes
      };
    },
    onModalAccept: function onModalAccept() {
      var _this7 = this;
      if (!this.report.reporttitle) {
        this.showEmptyTitleError = true;
        return;
      }
      this.showModal = false;
      this.showEmptyTitleError = false;
      var promise = this.report.id ? this.updateManagementReport : this.createManagementReport;
      return promise().then(this.getData).catch(function (err) {
        return _this7.$notify({
          title: err.response.error,
          type: 'error'
        });
      }).finally(function () {
        return _this7.isLoading = false;
      });
    }
  }
};