function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { Tabs } from '@/constants/Tabs';
import i18n from '@/i18n';
export var createTabsList = function createTabsList(arr, baseUrl) {
  var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return arr.map(function (item) {
    if (_typeof(item) === 'object') {
      return item;
    }
    var tab = _objectSpread({}, Tabs[item]);
    var additionalOptions = {};
    if (item in extraOptions) additionalOptions = extraOptions[item];
    return Object.assign(tab, _objectSpread({
      label: i18n.t(tab.label),
      url: "".concat(baseUrl, "/").concat(tab.url),
      tabKey: item
    }, additionalOptions));
  });
};
export var getSelectedRoute = function getSelectedRoute(url) {
  if (url) {
    var splittedUrl = url.split('/');
    var TabUrls = Object.keys(Tabs).map(function (tab) {
      return Tabs[tab].url;
    });
    var firstPart = splittedUrl[splittedUrl.length - 1];
    var secondPart = splittedUrl[splittedUrl.length - 2];
    if (firstPart && TabUrls.indexOf(firstPart) !== -1) {
      return {
        tab: firstPart
      };
    }
    if (secondPart && TabUrls.indexOf(secondPart) !== -1) {
      return {
        tab: secondPart
      };
    }
  }
  return null;
};