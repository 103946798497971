function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { createFlow as _createFlow, deleteFlow as _deleteFlow, getAllFlows as _getAllFlows, getAllOptions as _getAllOptions } from '@/api/compliance/dataflow';
import { getAllQuestionAnswersForNode } from '@/api/compliance/questionnaire';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import SaveDataflowAsImage from '@/components/Molecules/SaveDataflowAsImage';
import DataFlowRow from '@/components/Organisms/Compliance/DataFlowRow';
import DataflowModal from '@/components/Organisms/DataflowModal';
import { compareStrings } from '@/utils/compare';
import { removeDuplicates } from '@/utils/filterArray';
import { getDataflowThirdPartiesTitle, getDataflowStakeholdersTitle } from '@/utils/TitleFromSetting';
export default {
  name: 'DataFlow',
  introduction: '',
  description: '',
  token: '<data-flow />',
  components: {
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    DataflowModal: DataflowModal,
    DataFlowRow: DataFlowRow,
    SaveDataflowAsImage: SaveDataflowAsImage
  },
  props: {
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    }
  },
  data: function data() {
    return {
      flows: [],
      options: null,
      questions: [],
      loaded: false,
      showModal: false,
      dataFlowStakeholdersTitle: getDataflowStakeholdersTitle(),
      dataFlowSystemsTitle: getDataflowThirdPartiesTitle(),
      translate: getTranslate['DataFlow']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById'
  })), {}, {
    irrelevantDataTypes: function irrelevantDataTypes() {
      return ['Not applicable', 'Ikke relevant', 'Ikke gjeldende', this.translate.notApplicable];
    },
    projectId: function projectId() {
      return this.data.compliance_projects_id;
    },
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    nodeId: function nodeId() {
      return this.data.id;
    },
    missingStakeholders: function missingStakeholders() {
      var stakeholdersInUse = removeDuplicates(this.flows.map(function (_ref) {
        var stakeholder = _ref.stakeholder;
        return stakeholder;
      }), 'uniqueId').map(function (stakeholder) {
        return stakeholder.uniqueId;
      });
      return this.options.stakeholders.filter(function (stakeholder) {
        return !stakeholdersInUse.includes(stakeholder.uniqueId);
      });
    },
    missingDataTypes: function missingDataTypes() {
      var dataTypesInUse = removeDuplicates(this.flows.flatMap(function (_ref2) {
        var dataTypes = _ref2.dataTypes;
        return dataTypes;
      }), 'uniqueId').map(function (dataType) {
        return dataType.uniqueId;
      });
      return this.options.dataTypes.filter(function (dataType) {
        return !dataTypesInUse.includes(dataType.uniqueId);
      });
    },
    missingSystems: function missingSystems() {
      var systemsInUse = removeDuplicates(this.flows.flatMap(function (_ref3) {
        var systems = _ref3.systems;
        return systems;
      }), 'uniqueId').map(function (system) {
        return system.uniqueId;
      });
      return this.options.systems.filter(function (system) {
        return !systemsInUse.includes(system.uniqueId);
      });
    },
    flowsGroupedByStakeholder: function flowsGroupedByStakeholder() {
      var _this = this;
      var result = [];
      var sortedFlows = _toConsumableArray(this.flows).sort(function (a, b) {
        return compareStrings(a.stakeholder.text, b.stakeholder.text);
      });
      sortedFlows.forEach(function (flow) {
        var findFlowPredicate = function findFlowPredicate(curFlow) {
          return flow.stakeholder.uniqueId === curFlow.stakeholder.uniqueId && flow.direction === curFlow.direction;
        };
        var addedFlow = result.find(findFlowPredicate);
        if (addedFlow) {
          addedFlow.subFlows.push(flow);
        } else {
          result.push(_this.formatFlow(flow));
        }
      });
      return result;
    }
  }),
  mounted: function mounted() {
    var _this$data$questionna,
      _this2 = this;
    Promise.all([this.getAllFlows(), this.getAllOptions(), this.getQuestionnaire((_this$data$questionna = this.data.questionnaires) === null || _this$data$questionna === void 0 || (_this$data$questionna = _this$data$questionna[0]) === null || _this$data$questionna === void 0 ? void 0 : _this$data$questionna.questionnaireId)]).then(function () {
      return _this2.loaded = true;
    });
  },
  methods: {
    getAllFlows: function getAllFlows() {
      var _this3 = this;
      return _getAllFlows(this.projectId, this.nodeId).then(function (response) {
        _this3.flows = response.list;
      });
    },
    getAllOptions: function getAllOptions() {
      var _this4 = this;
      return _getAllOptions(this.projectId, this.nodeId).then(function (response) {
        _this4.options = _objectSpread(_objectSpread({}, response), {}, {
          stakeholders: removeDuplicates(response.stakeholders || [], 'uniqueId'),
          systems: removeDuplicates(response.systems || [], 'uniqueId'),
          dataTypes: response.dataTypes.filter(function (o) {
            return !_this4.irrelevantDataTypes.includes(o.text);
          })
        });
      });
    },
    getQuestionnaire: function getQuestionnaire(id) {
      var _this5 = this;
      if (!id) return;
      return getAllQuestionAnswersForNode(this.projectId, this.nodeId, id).then(function (_ref4) {
        var list = _ref4.list;
        return _this5.questions = list;
      });
    },
    createFlow: function createFlow(newFlow, showNotification) {
      var _this6 = this;
      return _createFlow(this.projectId, this.nodeId, newFlow).then(function (_ref5) {
        var data = _ref5.data;
        _this6.flows.push(data);
        if (showNotification) _this6.$notify({
          title: _this6.translate.dataFlowCreated
        });
        if (_this6.showModal) _this6.toggleModal();
      }).catch(function () {
        return _this6.$notify({
          title: _this6.translate.somethingWentWrong,
          type: 'error'
        });
      });
    },
    updateFlow: function updateFlow(updatedFlow) {
      var idx = this.flows.findIndex(function (flow) {
        return +flow.id === +updatedFlow.id;
      });
      if (idx === -1) return;
      this.flows[idx] = updatedFlow;
    },
    deleteFlow: function deleteFlow(index) {
      var _this7 = this;
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var idsToDelete = id ? [id] : this.flowsGroupedByStakeholder[index].subFlows.map(function (subFlow) {
        return subFlow.id;
      });
      var promises = [];
      idsToDelete.forEach(function (flowId) {
        return promises.push(_deleteFlow(_this7.projectId, _this7.nodeId, flowId));
      });
      Promise.all(promises).then(function () {
        return _this7.$notify({
          title: _this7.translate.dataFlowDeleted
        });
      }).catch(function () {
        return _this7.$notify({
          title: _this7.translate.somethingWentWrong,
          type: 'error'
        });
      });
      this.flows = this.flows.filter(function (flow) {
        return !idsToDelete.includes(flow.id);
      });
    },
    addDataFlowToStakeholder: function addDataFlowToStakeholder(flowId) {
      var flow = this.flows.find(function (flow) {
        return +flow.id === +flowId;
      });
      var newFlow = _objectSpread(_objectSpread({}, flow), {}, {
        dataTypes: [],
        systems: [],
        systemsTitle: (flow === null || flow === void 0 ? void 0 : flow.systemsTitle) || this.dataFlowSystemsTitle,
        comments: ''
      });
      this.createFlow(newFlow);
    },
    formatFlow: function formatFlow(flow) {
      return {
        id: flow.id,
        systemsTitle: flow.systemsTitle,
        stakeholder: flow.stakeholder,
        direction: flow.direction,
        subFlows: [flow]
      };
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    }
  }
};