import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_bar_chart = _resolveComponent("risma-bar-chart");
  var _component_info_box = _resolveComponent("info-box");
  return _openBlock(), _createBlock(_component_info_box, {
    title: $data.translate.materiality,
    class: "flex flex-col flex-grow"
  }, {
    default: _withCtx(function () {
      return [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.distributionOfMaterial,
        class: "mb-2 px-6"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_bar_chart, {
        height: 250,
        labels: $options.labels,
        datasets: $options.datasets,
        "y-step-size": 1,
        type: "Stacked"
      }, null, 8 /* PROPS */, ["labels", "datasets"])]))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]);
}