function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineStore } from 'pinia';
import { getAllWorkflows } from '@/api/risma/data';
import { getWorkflowStatusTitle } from '@/constants/admin/WorkflowStatuses';
import { createWorkflowStatusByModule as _createWorkflowStatusByModule, deleteWorkflowStatusByModule as _deleteWorkflowStatusByModule } from '@/api/admin/workflow';
export var useWorkflowStore = defineStore('workflowStore', {
  state: function state() {
    return {
      workflows: []
    };
  },
  getters: {
    workflowsByModule: function workflowsByModule() {
      var _this = this;
      return function (module) {
        return _this.workflows.filter(function (workflow) {
          return workflow.module === module;
        });
      };
    },
    defaultWorkflowByModule: function defaultWorkflowByModule() {
      var _this2 = this;
      return function (module) {
        return _this2.workflowsByModule(module).find(function (workflow) {
          return workflow.activityTypeId === null;
        });
      };
    },
    getWorkflow: function getWorkflow() {
      var _this3 = this;
      return function (module, activityTypeId) {
        var activityTypeWorkflow = _this3.workflowsByModule(module).find(function (workflow) {
          return +workflow.activityTypeId === +activityTypeId;
        });
        return activityTypeWorkflow || _this3.defaultWorkflowByModule(module);
      };
    },
    getAllStatuses: function getAllStatuses() {
      var _this4 = this;
      return function (module) {
        var workflows = _this4.workflowsByModule(module);
        return workflows.map(function (w) {
          return w.workflowStatuses;
        }).flat().map(function (i) {
          return _objectSpread(_objectSpread({}, i), {}, {
            label: i.title,
            value: i.id
          });
        }).sort(function (a, b) {
          return a.id - b.id;
        });
      };
    },
    getActivityStatusTitle: function getActivityStatusTitle() {
      var _this5 = this;
      return function (activity, module) {
        var allStatuses = _this5.getAllStatuses(module);
        var ws = allStatuses.find(function (st) {
          return +st.id === +activity.workflowStatusId;
        });
        return (ws === null || ws === void 0 ? void 0 : ws.title) || (ws === null || ws === void 0 ? void 0 : ws.label) || 'Unknown/Deleted status';
      };
    }
  },
  actions: {
    load: function load() {
      var _this6 = this;
      return getAllWorkflows().then(function (_ref) {
        var list = _ref.list;
        _this6.workflows = list;
        _this6.workflows.forEach(function (wf) {
          if (wf.activityTypeId) return;
          wf.workflowStatuses = wf.workflowStatuses.map(function (ws) {
            return _objectSpread(_objectSpread({}, ws), {}, {
              title: getWorkflowStatusTitle(ws.title, wf.module)
            });
          });
        });
      });
    },
    deleteWorkflowStatusByModule: function deleteWorkflowStatusByModule(module, id, data) {
      var _this7 = this;
      return _deleteWorkflowStatusByModule(module, id, data).then(function () {
        _this7.workflows = _this7.workflows.map(function (workflow) {
          if (workflow.module !== module) return workflow;
          return _objectSpread(_objectSpread({}, workflow), {}, {
            workflowStatuses: workflow.workflowStatuses.filter(function (workflowStatus) {
              return +workflowStatus.id !== +id;
            })
          });
        });
      });
    },
    createWorkflowStatusByModule: function createWorkflowStatusByModule(module, data) {
      var _this8 = this;
      return _createWorkflowStatusByModule(module, data).then(function (status) {
        _this8.workflows = _this8.workflows.map(function (workflow) {
          if (+workflow.id !== +data.workflowId) return workflow;
          return _objectSpread(_objectSpread({}, workflow), {}, {
            workflowStatuses: [].concat(_toConsumableArray(workflow.workflowStatuses), [status])
          });
        });
      });
    }
  }
});