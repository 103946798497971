// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-link[data-v-0f8ea57c] {
  --tw-border-opacity: 1;
  border-color: rgb(14 41 84 / var(--tw-border-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(14 41 84 / var(--tw-text-opacity));
}
.btn-link[data-v-0f8ea57c]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.btn-link[data-v-0f8ea57c]:hover {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.btn-link__icon[data-v-0f8ea57c] {
  display: inline-block;
  stroke-width: 3;
  vertical-align: text-bottom;
  --tw-text-opacity: 1;
  color: rgb(14 41 84 / var(--tw-text-opacity));
}
[data-v-0f8ea57c]  .tag-item {
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(230 240 255 / var(--tw-bg-opacity));
  padding: 0.25rem;
}
[data-v-0f8ea57c]  .tag-item:last-child {
  margin-bottom: 0px;
}
[data-v-0f8ea57c]  .tag-item .delete-btn {
  margin-left: auto;
  stroke-width: 3;
}
.impact-assessment-button[data-v-0f8ea57c]  button {
  width: 7rem;
  height: 2rem;
  border-radius: 0.4rem;
  justify-content: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
