import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-5"
};
var _hoisted_2 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_3 = {
  class: "mb-5"
};
var _hoisted_4 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_5 = {
  class: "mb-5"
};
var _hoisted_6 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_7 = {
  class: "mb-5"
};
var _hoisted_8 = {
  key: 0,
  class: "pl-3 text-sm text-red-50"
};
var _hoisted_9 = {
  class: "mb-5"
};
var _hoisted_10 = {
  class: "mb-5"
};
var _hoisted_11 = {
  class: "pb-6 flex items-center justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_org_selector = _resolveComponent("risma-org-selector");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
    modelValue: _ctx.username,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.username = $event;
    }),
    title: $data.translate.username,
    invalid: !_ctx.username,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "invalid"]), !_ctx.username ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($data.translate.pleaseFillOutThisField), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
    modelValue: _ctx.password,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.password = $event;
    }),
    title: $data.translate.password,
    invalid: !$options.isPasswordValid,
    type: "password"
  }, null, 8 /* PROPS */, ["modelValue", "title", "invalid"]), !$options.isPasswordValid ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($options.passwordErrorMessage), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
    modelValue: _ctx.initials,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.initials = $event;
    }),
    title: $data.translate.initials,
    invalid: !_ctx.initials,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "invalid"]), !_ctx.initials ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($data.translate.pleaseFillOutThisField), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
    modelValue: _ctx.email,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.email = $event;
    }),
    title: $data.translate.email,
    invalid: !$options.isEmailValid,
    type: "email"
  }, null, 8 /* PROPS */, ["modelValue", "title", "invalid"]), !$options.isEmailValid ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($data.translate.invalidEmailAddress), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_org_selector, {
    title: $data.translate.organisation,
    "available-options": _ctx.organisations,
    "selected-options": $options.selectedOrganisations,
    "open-direction": "top",
    onSelected: $options.onOrganisationsChange
  }, null, 8 /* PROPS */, ["title", "available-options", "selected-options", "onSelected"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_checkboxes, {
    "checkbox-size": 20,
    "label-position": "left",
    class: "max-w-350px",
    options: $options.checkboxOptions,
    disabled: !$props.data.active,
    onUpdated: $options.onCheckboxChange
  }, null, 8 /* PROPS */, ["options", "disabled", "onUpdated"])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, [_ctx.getSettingValue('feature.google_mfa_enabled') && $props.data.mfa_type ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.disableMfaForUser,
    type: "util",
    onClick: $options.onDisableMfaClick
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_risma_button, {
    text: $props.data.active ? $data.translate.deactivate : $data.translate.activate,
    type: $props.data.active ? 'danger' : 'save',
    onClick: $options.handleDeactivationConfirm
  }, null, 8 /* PROPS */, ["text", "type", "onClick"])])]);
}