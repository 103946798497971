function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import cloneDeep from 'lodash/cloneDeep';
import dot from '@/components/Atoms/RiskMatrix/Dot';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import DotConnector from '@/components/Atoms/RiskMatrix/DotConnector';
import { hexToHsl } from '@/utils/colors';
import { GradientTypes } from '@/constants/admin/RiskMatrixSettings';
export default {
  name: 'Grid',
  components: {
    dot: dot,
    FeatherIcon: FeatherIcon,
    DotConnector: DotConnector
  },
  props: {
    grid: {
      note: 'Contains a 2D array',
      type: Array,
      required: true
    },
    checked: {
      note: 'Contains True or False',
      type: Boolean,
      required: true
    },
    colorCodes: {
      note: 'Contains the color codes for the dots in an object array',
      type: Object,
      required: true
    },
    colorArrangement: {
      type: String,
      required: true,
      note: 'How the colors of tiles should be placed'
    },
    boxSize: {
      type: Number,
      required: true,
      note: 'size of boxes in the grid'
    },
    isGridClickable: {
      type: Boolean,
      required: false,
      default: true,
      note: 'show or not check mark on the grid'
    },
    dotLabelHoverFunction: {
      type: Function,
      default: null,
      note: 'Function to generate label on hover'
    },
    startColor: {
      type: String,
      required: true
    },
    endColor: {
      type: String,
      required: true
    },
    customHeatmapOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    dotConnectorEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'show a lines connecting two points with the same id'
    }
  },
  emits: ['display', 'cell', 'dot', 'onStateChanged'],
  data: function data() {
    return {
      gridBoxSize: this.boxSize,
      dotSize: 20,
      stateGrid: [],
      dotsDomElements: []
    };
  },
  computed: {
    reverseGrid: function reverseGrid() {
      return this.grid.slice(0).reverse();
    },
    checkIconMove: function checkIconMove() {
      var halfIconSize = this.iconSize == '1' ? 7 : 21;
      return {
        top: this.cellSize / 2 - halfIconSize + 'px',
        left: this.cellSize / 2 - halfIconSize + 'px'
      };
    },
    iconSize: function iconSize() {
      // if size becomes bigger -> make checked icon bigger
      return this.gridBoxSize > 127 ? '3' : '1';
    },
    gridStyle: function gridStyle() {
      var _this$gridSize = this.gridSize,
        height = _this$gridSize.height,
        width = _this$gridSize.width;
      return {
        height: height + 'px',
        width: width + 'px'
      };
    },
    gridSize: function gridSize() {
      return {
        height: this.numberOfRows * this.boxSize,
        width: this.numberOfRows ? this.numberOfColumns * this.boxSize : '0'
      };
    },
    numberofFields: function numberofFields() {
      return this.numberOfColumns * this.numberOfRows;
    },
    cellSize: function cellSize() {
      var borderWidth = 2;
      return this.gridBoxSize - borderWidth;
    },
    maxCellDots: function maxCellDots() {
      var maxDotAxis = Math.floor(this.cellSize / this.dotSize);
      return maxDotAxis * maxDotAxis;
    },
    cellStyle: function cellStyle() {
      return {
        height: "".concat(this.cellSize, "px"),
        width: "".concat(this.cellSize, "px")
      };
    },
    startColorHsl: function startColorHsl() {
      return hexToHsl(this.startColor);
    },
    endColorHsl: function endColorHsl() {
      return hexToHsl(this.endColor);
    },
    numberOfRows: function numberOfRows() {
      return this.grid.length;
    },
    numberOfColumns: function numberOfColumns() {
      return this.grid[0].length;
    },
    customHeatmapColors: function customHeatmapColors() {
      var colors = _toConsumableArray(this.customHeatmapOptions).sort(function (a, b) {
        return a.number - b.number;
      }).map(function (option) {
        return "#".concat(option.hexCode);
      });
      var rows = [];
      for (var row = 0; row < this.numberOfRows; row++) {
        var cells = [];
        for (var col = 0; col < this.numberOfColumns; col++) {
          cells.push(colors[col + row * this.numberOfColumns]);
        }
        rows.unshift(cells);
      }
      return rows;
    }
  },
  watch: {
    grid: {
      handler: function handler() {
        if (this.checked) {
          this.setStateGrid();
        }
        if (this.dotConnectorEnabled) {
          this.$nextTick(function () {
            this.setDotsDomElements();
          });
        }
      },
      deep: true
    },
    boxSize: {
      handler: function handler() {
        this.gridBoxSize = this.boxSize;
      }
    }
  },
  mounted: function mounted() {
    this.dotConnectorEnabled && this.setDotsDomElements();
    this.$emit('display');
  },
  methods: {
    getCell: function getCell(x, y) {
      var rowLength = this.numberOfColumns;
      var rowNumber = this.numberOfRows;
      var cellRow = this.$el.querySelectorAll(".cell:nth-child(n+".concat(rowLength * (rowNumber - y) + 1, "):nth-child(-n+").concat(rowLength * (rowNumber - y + 1), ")"));
      this.$emit('cell', cellRow[x - 1]);
    },
    getDOMDot: function getDOMDot(id) {
      var domDot = this.$el.querySelector(".dot-wrapper[data-id='".concat(id, "']"));
      if (domDot) {
        this.$emit('dot', domDot);
      } else {
        this.$emit('dot', null);
      }
    },
    getLocationAndMaxValue: function getLocationAndMaxValue(row, column) {
      var location = (column + 1) * (this.numberOfRows - row);
      var max = this.numberofFields;
      return {
        location: location,
        max: max
      };
    },
    getComponentHsl: function getComponentHsl(hslProp, start, end, location, max) {
      return Math.floor(Math.abs(start[hslProp] + location * (end[hslProp] - start[hslProp]) / max));
    },
    getHslValue: function getHslValue(start, end, location, max) {
      var _this = this;
      var _map = ['h', 's', 'l'].map(function (type) {
          return _this.getComponentHsl(type, start, end, location, max);
        }),
        _map2 = _slicedToArray(_map, 3),
        h = _map2[0],
        s = _map2[1],
        l = _map2[2];
      return "hsl( ".concat(h, ",").concat(s, "%,").concat(l, "%)");
    },
    colorMap: function colorMap(row, column) {
      if (this.customHeatmapOptions.length) return this.customHeatmapColors[row][column];
      if (this.colorArrangement === GradientTypes.diagonal) {
        var _this$getLocationAndM = this.getLocationAndMaxValue(row, column),
          location = _this$getLocationAndM.location,
          max = _this$getLocationAndM.max;
        return this.getHslValue(this.startColorHsl, this.endColorHsl, location, max);
      } else if (this.colorArrangement === GradientTypes.horizontal) {
        return this.getHslValue(this.endColorHsl, this.startColorHsl, row, this.numberOfRows - 1);
      } else if (this.colorArrangement === GradientTypes.vertical) {
        return this.getHslValue(this.startColorHsl, this.endColorHsl, column, this.numberOfColumns - 1);
      }
    },
    setStateGrid: function setStateGrid() {
      this.stateGrid = cloneDeep(this.reverseGrid);
    },
    onClickCell: function onClickCell(rowKey, cellKey) {
      if (!this.stateGrid.length) {
        this.setStateGrid();
      }
      var currentCell = this.stateGrid[rowKey][cellKey];
      if (!currentCell.length || currentCell[0].id) {
        //if cell have  dots inside it or nothing then we should add new el to [0] to it (for dividing dots from checked icons)
        currentCell.unshift({
          isActive: true
        });
      } else {
        currentCell[0].isActive = !currentCell[0].isActive;
      }
      this.$emit('onStateChanged', cloneDeep(this.stateGrid).reverse());
    },
    isCellsOverflow: function isCellsOverflow(data) {
      return data.length >= this.maxCellDots;
    },
    isDotVisible: function isDotVisible(index) {
      return ++index < this.maxCellDots;
    },
    setDotsDomElements: function setDotsDomElements() {
      this.dotsDomElements = _toConsumableArray(this.$el.querySelectorAll('.dot-wrapper[data-id]'));
    }
  }
};