// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-4a77554b]  .hidden {
  display: none;
}
[data-v-4a77554b]  ul,[data-v-4a77554b]  li {
  padding-left: 0px !important;
}
.risma-select[data-v-4a77554b]  .max-number-selected {
  margin-right: 0.5rem;
}
.risma-select.tag-clickable[data-v-4a77554b]  .multiselect__tag:hover {
  cursor: pointer;
  background: #637994 !important;
}
[data-v-4a77554b]  .multiselect {
  border-radius: 5px;
  border: 1px solid #BEBEBE;
}
[data-v-4a77554b]  .multiselect__tag.multiselect__tag-gray {
  background: #637994 !important;
}
[data-v-4a77554b]  .multiselect__tag .multiselect__tag-icon:hover {
  background: inherit !important;
}
[data-v-4a77554b]  .multiselect__tag .multiselect__tag-icon:after {
  color: inherit !important;
  font-size: 17px;
}
[data-v-4a77554b]  .multiselect__placeholder {
  color: inherit;
}
[data-v-4a77554b]  .multiselect__option {
  overflow-wrap: break-word;
  white-space: pre-line;
}
[data-v-4a77554b]  .multiselect__option--highlight {
  background: #637994 !important;
}
[data-v-4a77554b]  .multiselect__option--highlight:hover {
  background: #637994 !important;
}
[data-v-4a77554b]  .multiselect__option--highlight:after {
  height: 100%;
  background: #637994 !important;
  display: flex;
  align-items: center;
}
[data-v-4a77554b]  .multiselect__option.multiselect__option--highlight.multiselect__option--selected {
  background: #0e2954 !important;
}
[data-v-4a77554b]  .multiselect__option.multiselect__option--highlight.multiselect__option--selected:after {
  background: #0e2954 !important;
}
[data-v-4a77554b]  .multiselect__option--selected:after {
  background-color: #FFFFFF;
}
[data-v-4a77554b]  .multiselect__option.multiselect__option--disabled {
  background-color: transparent;
  cursor: not-allowed;
  color: #637994;
  font-weight: 600;
}
[data-v-4a77554b]  .multiselect__content {
  width: 100%;
}
[data-v-4a77554b]  .multiselect__option--group.multiselect__option--disabled,[data-v-4a77554b]  .multiselect__option--group {
  background-color: transparent !important;
  color: #BEBEBE !important;
}
.required[data-v-4a77554b]  .multiselect {
  border-color: #B03D00;
  border-width: 2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
