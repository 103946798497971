// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[data-v-3832f3e3]::-webkit-outer-spin-button,
input[data-v-3832f3e3]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number][data-v-3832f3e3] {
  -moz-appearance: textfield;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
