// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-6e41f910]  .static-table-container {
  width: 100%;
  padding: 0 15px;
}
[data-v-6e41f910]  .static-table-container .static-table tr {
  border: none;
}
[data-v-6e41f910]  .static-table-container .static-table tbody:after {
  content: "";
  display: block;
  height: 11px;
}
[data-v-6e41f910]  .static-table-container .static-table .static-table-head-cell {
  padding: 8px;
  text-align: left;
  min-width: 120px;
}
[data-v-6e41f910]  .static-table-container .static-table .static-table-head-cell:first-child,[data-v-6e41f910]  .static-table-container .static-table .static-table-head-cell:nth-child(2),[data-v-6e41f910]  .static-table-container .static-table .static-table-head-cell:nth-child(3) {
  min-width: 100px;
  min-height: 70px;
}
[data-v-6e41f910]  .static-table-container .static-table .static-table-head-cell .risma-title > h3 {
  font-weight: 600;
  font-size: 16px;
  max-width: 100%;
}
[data-v-6e41f910]  .static-table-container .static-table td {
  padding: 8px;
  background-color: #f5f5f2;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}
[data-v-6e41f910]  .static-table-container .static-table td:first-child {
  border-left: 1px solid #CCCCCC;
}
[data-v-6e41f910]  .static-table-container .static-table td:last-child {
  border-right: 1px solid #CCCCCC;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
[data-v-6e41f910]  .static-table-container .static-table .risma-button-btn {
  font-size: 17px;
  padding: 5px 12px;
}
[data-v-6e41f910]  .static-table-container .static-table .depending-on-traffic-light {
  padding-bottom: 7px;
}
[data-v-6e41f910]  .static-table-container .static-table .depending-on-field {
  width: 250px;
}
.mypage-list-columns[data-v-6e41f910] {
  display: flex;
  flex-wrap: wrap;
}
.mypage-list-columns[data-v-6e41f910]  .preview {
  width: 33%;
  padding: 0 15px;
}
.mypage-list-columns[data-v-6e41f910]  .preview .preview-cont {
  min-height: 170px;
}
.mypage-list-columns[data-v-6e41f910]  .preview-wide {
  width: 100%;
}
.mypage-list.mypage-list-risks[data-v-6e41f910] {
  padding: 0 15px;
}
.mypage-list.my-risks-list-tiles[data-v-6e41f910] {
  padding: 0;
}
.mypage-list.mypage-list-process-library[data-v-6e41f910]  .preview {
  padding-bottom: 15px;
}
.mypage-list.mypage-list-processes .preview[data-v-6e41f910] {
  width: 33%;
  padding: 0 15px;
}
.mypage-list.mypage-list-processes .preview .preview-cont[data-v-6e41f910] {
  min-height: 175px;
}
.mypage-list.mypage-list-processes[data-v-6e41f910]  .card {
  padding: 17px;
  min-height: 175px;
  z-index: 2;
  position: relative;
  background-color: #f3f3f3;
  border: 1px solid #BEBEBE;
  margin-bottom: 30px;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
}
.mypage-list.mypage-list-processes[data-v-6e41f910]  .card .trafficlight_placer {
  line-height: 40px;
}
.mypage-list.mypage-list-processes[data-v-6e41f910]  .card .header-placer {
  display: inline-block;
  margin-left: 25px;
}
.mypage-list .mypage-list.mypage-list-information-assets[data-v-6e41f910]  .preview {
  padding-bottom: 15px;
}
.my-risks-list-tiles[data-v-6e41f910] {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.my-risks-list-tiles[data-v-6e41f910]  .preview-risk-mode-tiles {
  width: 33%;
  padding: 0 15px;
  margin-bottom: 24px;
}
[data-v-6e41f910]  .static-table-container .risk-closed {
  text-decoration: line-through !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
