function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import draggable from 'vuedraggable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import QuizQuestion from '@/components/Molecules/Questionnaire/QuizQuestion';
import Modal from '@/components/Molecules/Modal/Modal';
import { DragOptions } from '@/constants/DragOptions';
export default {
  name: 'QuizQuestionnaire',
  components: {
    QuizQuestion: QuizQuestion,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    draggable: draggable,
    Notification: Notification,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    InputField: InputField
  },
  props: {
    quiz: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    loadState: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['dismiss', 'create:quiz', 'update:quiz'],
  data: function data() {
    return {
      currentQuiz: {},
      startQuiz: {},
      questionTemplate: {
        headline: '',
        options: [],
        question: '',
        questionType: 1,
        questionNumber: ''
      },
      errorMessages: [],
      DragOptions: DragOptions,
      alphabeticalList: new Array(26).fill(1).map(function (_, i) {
        return String.fromCharCode(65 + i);
      }),
      //creates alphabetical list for question answers
      translate: getTranslate['QuizQuestionnaire']()
    };
  },
  computed: {
    isDirty: function isDirty() {
      return !isEqual(this.startQuiz, this.currentQuiz);
    }
  },
  beforeMount: function beforeMount() {
    var _this$currentQuiz$que;
    this.currentQuiz = cloneDeep(this.quiz);
    if (!((_this$currentQuiz$que = this.currentQuiz.questions) !== null && _this$currentQuiz$que !== void 0 && _this$currentQuiz$que.length)) {
      this.addNewQuestion();
    }
  },
  mounted: function mounted() {
    //beforeMount logic doesn't properly work with cloneDeep and isEqual - they wont be eequal even you've changed nothing and only question was added before cloning
    this.startQuiz = cloneDeep(this.currentQuiz);
  },
  methods: {
    handleQuizQuestionError: function handleQuizQuestionError(error) {
      this.errorMessages = [];
      this.errorMessages.push(error);
    },
    addNewQuestion: function addNewQuestion() {
      this.currentQuiz.questions.push(_objectSpread(_objectSpread({}, cloneDeep(this.questionTemplate)), {}, {
        questionNumber: this.currentQuiz.questions.length + 1 + ''
      }));
    },
    onCreate: function onCreate() {
      this.checkValidation();
      if (this.errorMessages.length) return;
      if (this.currentQuiz.id) {
        this.updateNumbers();
        this.$emit('update:quiz', this.currentQuiz);
      } else {
        this.$emit('create:quiz', this.currentQuiz);
      }
    },
    handleDismiss: function handleDismiss() {
      var _this = this;
      if (this.isDirty) this.$confirm('', this.translate.areYouSureYouWantToCancelTheEnteredInfor, function (res) {
        return res && _this.$emit('dismiss');
      });else this.$emit('dismiss');
    },
    checkValidation: function checkValidation() {
      this.errorMessages = [];
      this.checkTitleEmpty();
      this.checkEmptyQuestionsLength();
      this.checkEmptyDescriptions();
      this.checkQuestionOptionLength();
      this.checkQuestionOptionEmpty();
    },
    updateNumbers: function updateNumbers() {
      this.currentQuiz.questions.forEach(function (item, index) {
        return item.questionNumber = index + 1 + '';
      });
    },
    checkEmptyQuestionsLength: function checkEmptyQuestionsLength() {
      if (!this.currentQuiz.questions.length) this.errorMessages.push(this.translate.questionListCannotBeEmpty);
    },
    checkEmptyDescriptions: function checkEmptyDescriptions() {
      if (this.currentQuiz.questions.some(function (question) {
        return !question.question.length;
      })) this.errorMessages.push(this.translate.questionDescriptionCannotBeEmpty);
    },
    checkTitleEmpty: function checkTitleEmpty() {
      if (!this.currentQuiz.title.length) this.errorMessages.push(this.translate.titleCannotBeEmpty);
    },
    checkQuestionOptionEmpty: function checkQuestionOptionEmpty() {
      if (this.currentQuiz.questions.some(function (question) {
        return question.options.some(function (option) {
          return !option.label.length;
        });
      })) this.errorMessages.push(this.translate.questionOptionCannotBeEmpty);
    },
    checkQuestionOptionLength: function checkQuestionOptionLength() {
      if (this.currentQuiz.questions.some(function (question) {
        return question.options.length < 2;
      })) this.errorMessages.push(this.translate.eachQuestionHaveToHave);
    },
    deleteQuestion: function deleteQuestion(index, isDirty) {
      var _this2 = this;
      if (isDirty) this.$confirm('', this.translate.areYouSureYouWantToDeleteThis, function (res) {
        return res && _this2.currentQuiz.questions.splice(index, 1);
      });else this.currentQuiz.questions.splice(index, 1);
    }
  }
};