function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Colors } from '@/Colors';
import i18n from '@/i18n';
export var KpiChartColors = [['rgb(88, 8, 133)', '#decee7'], ['rgb(230, 91, 155)', '#fdeff5'], ['rgb(17, 204, 140)', '#e7faf4'], ['rgb(103, 47, 195)', '#f0eaf9'], ['rgb(254, 147, 112)', '#fff4f1'], ['rgb(0, 193, 190)', '#e6f9f9'], ['rgb(254, 217, 47)', '#fffbea'], ['rgb(0, 172, 233)', '#e6f7fd']];
export var KpiCardColors = ['#e65b9b', '#11cc8c', '#672fc3', '#00c1be', '#feD92f', '#00ace9'];
export var KpiRadarColors = [Colors.greenTea, Colors.blueLight];
export var complianceChartOptions = {
  cutout: '20%',
  borderRadius: {
    innerStart: 1,
    outerStart: 1,
    innerEnd: 360,
    outerEnd: 360
  },
  borderWidth: 3,
  plugins: {
    tooltip: {
      padding: 15,
      boxPadding: 10,
      filter: function filter(ctx) {
        return ctx.datasetIndex && ctx.parsed && ctx.parsed !== 100;
      },
      callbacks: {
        label: function label(ctx) {
          var _ctx$dataset$rawData;
          if (!((_ctx$dataset$rawData = ctx.dataset.rawData) !== null && _ctx$dataset$rawData !== void 0 && _ctx$dataset$rawData.length)) {
            return "".concat(ctx.dataset.title, ": ").concat(ctx.raw, "%");
          }
          return "".concat(ctx.dataset.title, ": ").concat(ctx.dataset.rawData[ctx.dataIndex], "%");
        }
      }
    },
    center: {
      text: '',
      color: '',
      fontStyle: 'bold',
      borderRadius: 5
    }
  }
};
export var projectProgressChartOptions = {
  maintainAspectRatio: false,
  fill: false,
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
      ticks: {
        stepSize: 20
      }
    },
    x: {
      offset: true,
      ticks: {
        color: Colors.lilacGray,
        font: {
          size: 14,
          weight: '600',
          family: 'Inter, sans-serif'
        }
      }
    }
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        pointStyle: 'circle',
        usePointStyle: true,
        padding: 15
      }
    },
    tooltip: {
      padding: 15,
      boxPadding: 10
    }
  }
};
export var projectStatusChartOptions = {
  cutout: '80%',
  borderRadius: {
    innerStart: 1,
    outerStart: 1,
    innerEnd: 360,
    outerEnd: 360
  },
  plugins: {
    center: {
      fontStyle: 'bold',
      padding: 10,
      borderRadius: 2,
      text: '',
      color: '',
      fillColor: '',
      borderColor: ''
    }
  }
};
export var metricData = {
  data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
  backgroundColor: [Colors.system.white],
  rotation: 18,
  datalabels: {
    labels: {
      display: true
    },
    color: Colors.system.black,
    display: function display(ctx) {
      var idx = ctx.dataIndex;
      return !((idx - 1) % 2);
    },
    formatter: function formatter(value, ctx) {
      var label = value * (ctx.dataIndex + 1);
      return label === 100 ? 0 : label;
    }
  },
  hoverBackgroundColor: Colors.system.white,
  hoverBorderColor: Colors.system.white,
  hoverBorderWidth: 0
};
export var getNewStatusDataObject = function getNewStatusDataObject(data, _ref) {
  var _ref$fontSize = _ref.fontSize,
    fontSize = _ref$fontSize === void 0 ? 25 : _ref$fontSize;
  var newDataObj = _objectSpread(_objectSpread({}, data), {}, {
    datasets: [{
      data: data.data,
      backgroundColor: data.backgroundColor
    }],
    options: JSON.parse(JSON.stringify(projectStatusChartOptions))
  });
  newDataObj.options.plugins.center.maxFontSize = fontSize;
  newDataObj.options.plugins.center.text = data.data[0] + '%' || '';
  newDataObj.options.plugins.center.color = data.backgroundColor[0] || '';
  newDataObj.options.plugins.center.borderColor = data.backgroundColor[0] || '';
  newDataObj.options.plugins.center.fillColor = data.backgroundColor[1] || '';
  return newDataObj;
};
export var generateInsights = function generateInsights(_ref2) {
  var activeKpi = _ref2.activeKpi,
    monthlyScores = _ref2.monthlyScores,
    isAbove = _ref2.isAbove;
  var isAboveText = i18n.t(isAbove ? 'above' : 'below');
  return [{
    description: i18n.t('The compliance score is based on %s active KPIs', [activeKpi]),
    icon: 'shield',
    color: '#00acef'
  }, {
    description: i18n.t('The score for the last 30 days was <strong>%s</strong>%', [monthlyScores]),
    icon: 'award',
    color: '#ffd731'
  }, {
    description: i18n.t('The current score is %s the average of the previous 30 days', [isAboveText]),
    icon: 'trending-up',
    color: '#00dbb9'
  }];
};