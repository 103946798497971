import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "font-semibold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_dep_well = _resolveComponent("dep-well");
  return _openBlock(), _createElementBlock("div", null, [$options.statusNotificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString($data.translate.beAwareThatThisCompanyHasStatus), 1 /* TEXT */), _createElementVNode("span", _hoisted_1, _toDisplayString($options.statusNotificationMessage), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_dep_well, {
    "is-loaded": $data.isLoaded,
    item: $data.company,
    "dep-header-props": $options.depHeaderProps,
    "is-relation-enabled": false,
    "read-only": !$options.isUserAbleToEdit,
    "local-storage-key": "company-entry",
    onChangeTab: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeTab', $event);
    }),
    onPropertyChanged: $options.companyPropertyChanged,
    onEditContact: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('editContact', $event);
    }),
    onSaveStatusClicked: $options.saveStatusClicked,
    onValidationError: $options.handleShowNotification,
    onRefreshCompany: $options.getCompany
  }, null, 8 /* PROPS */, ["is-loaded", "item", "dep-header-props", "read-only", "onPropertyChanged", "onSaveStatusClicked", "onValidationError", "onRefreshCompany"])]);
}