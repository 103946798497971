import { getCompanies as _getCompanies } from '@/api/admin/companies';
import Modal from '@/components/Molecules/Modal/Modal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import Notification from '@/components/Molecules/Notification';
import { getTranslate } from './translate';
export default {
  name: 'LinkCompany',
  components: {
    SingleSelect: SingleSelect,
    Modal: Modal,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    Notification: Notification
  },
  props: {
    companyFilter: {
      type: Function,
      default: null
    },
    vatNo: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['link'],
  data: function data() {
    return {
      isLoading: false,
      companies: [],
      hasErrorMessage: false,
      selectedCompanyId: null,
      showLinkCompanyModal: false,
      translate: getTranslate['LinkCompany']()
    };
  },
  computed: {
    companiesOptions: function companiesOptions() {
      return this.companies.map(function (company) {
        return {
          label: company.name,
          value: company.id
        };
      });
    }
  },
  methods: {
    getCompanies: function getCompanies() {
      var _this = this;
      return _getCompanies().then(function (_ref) {
        var list = _ref.list;
        return _this.companies = _this.filterCompanies(list);
      });
    },
    filterCompanies: function filterCompanies(companies) {
      if (!this.companyFilter) return companies;
      return companies.filter(this.companyFilter);
    },
    openLinkCompanyModal: function openLinkCompanyModal() {
      var _this2 = this;
      this.showLinkCompanyModal = true;
      this.isLoading = true;
      this.getCompanies().then(function () {
        return _this2.suggestCompanyByVatNo();
      }).finally(function () {
        return _this2.isLoading = false;
      });
    },
    suggestCompanyByVatNo: function suggestCompanyByVatNo() {
      var _this3 = this;
      var matchingCompany = this.companies.find(function (company) {
        return company.vatNo === _this3.vatNo;
      });
      if (!matchingCompany) return;
      this.selectedCompanyId = matchingCompany.id;
    },
    closeLinkCompanyModal: function closeLinkCompanyModal() {
      this.showLinkCompanyModal = false;
      this.selectedCompanyId = null;
    },
    linkCompany: function linkCompany() {
      var _this4 = this;
      if (!this.selectedCompanyId) {
        this.hasErrorMessage = true;
        return;
      }
      var selectedCompany = this.companies.find(function (company) {
        return company.id === _this4.selectedCompanyId;
      });
      this.$emit('link', selectedCompany);
      this.closeLinkCompanyModal();
    }
  }
};