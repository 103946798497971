// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/access-types/icon_noAccess.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/access-types/icon_user.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/access-types/icon_privUser.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/access-types/icon_superUser.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/img/access-types/icon_admin.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.user-rights-table .tables[data-v-4cc90616] {
  padding-top: 7rem;
}
.user-rights-table .tables table[data-v-4cc90616] {
  border-collapse: separate;
}
.user-rights-table .tables td:first-child img[data-v-4cc90616] {
  display: block;
  margin-right: 5px;
}
.user-rights-table .tables .admin-cells[data-v-4cc90616] {
  border-left: 2rem solid #FFFFFF !important;
  padding: 0.2rem 0.5rem !important;
  width: 0 !important;
}
.user-rights-table .tables .admin-cells input[data-v-4cc90616] {
  cursor: pointer;
}
.user-rights-table .tables .admin-cells input[data-v-4cc90616]:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.user-rights-table .tables td.activity-cell[data-v-4cc90616] {
  width: 2rem;
  height: 2rem;
  padding: 0.2rem;
  position: relative;
}
.user-rights-table .tables td.activity-cell > div[data-v-4cc90616] {
  position: relative;
}
.user-rights-table .tables td.activity-cell a[data-v-4cc90616] {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.user-rights-table .tables td.activity-cell a.forbidden[data-v-4cc90616] {
  opacity: 0.4;
  cursor: not-allowed;
}
.user-rights-table .tables input[data-v-4cc90616]:not(.checkbox) {
  height: 20px;
  width: 20px;
  border: 1px solid #FFFFFF;
  position: relative;
  -webkit-appearance: none;
}
.user-rights-table .tables input[data-v-4cc90616]:not(.checkbox):checked:after {
  color: #FFFFFF;
  content: "✔";
  display: flex;
  font-size: 20px;
  justify-content: center;
  position: absolute;
  top: -5px;
}
.user-rights-table .tables th:first-child div[data-v-4cc90616] {
  width: 2rem;
}
.user-rights-table .tables th.activity-header-cell[data-v-4cc90616] {
  width: 2rem;
  position: sticky;
  top: 180px;
  z-index: 10;
}
.user-rights-table .tables th.activity-header-cell[data-v-4cc90616]::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 37px;
  height: 11rem;
  transform-origin: bottom left;
  transform: skew(-30deg);
  background: #0e2954;
  border-right: 1px solid #BEBEBE;
  border-bottom: 1px solid #FFFFFF;
}
.user-rights-table .tables th.activity-header-cell div[data-v-4cc90616] {
  position: absolute;
  height: 2rem;
  width: 11.7rem;
  padding: 0.2rem 1rem 0.2rem 2rem;
  z-index: 1;
  left: 1.4rem;
  top: 0;
  transform-origin: left;
  transform: rotate(-60deg);
}
.user-rights-table .tables th.activity-header-cell div[data-v-4cc90616]  h2 {
  color: #FFFFFF !important;
  overflow: hidden;
}
.user-rights-table .tables .modal-icon[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .modal-icon {
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.user-rights-table .tables .icon-risma-superUser-yellow[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .icon-risma-superUser-yellow {
  /*change color from blueMain to yellow -> #FFBF00*/
  filter: brightness(400%) sepia(100%) saturate(500%) contrast(2) !important;
}
.user-rights-table .tables .icon-risma-noAccess[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .icon-risma-noAccess {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
}
.user-rights-table .tables .icon-risma-user[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .icon-risma-user {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center;
}
.user-rights-table .tables .icon-risma-privUser[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .icon-risma-privUser {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) no-repeat center;
}
.user-rights-table .tables .icon-risma-superUser[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .icon-risma-superUser {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) no-repeat center;
}
.user-rights-table .tables .icon-risma-admin[data-v-4cc90616],
.user-rights-table .tables[data-v-4cc90616]  .icon-risma-admin {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) no-repeat center;
}
.user-rights-table .tables[data-v-4cc90616]  .level {
  width: 25px;
  height: 27px;
  font-size: 21px;
  text-align: center;
  line-height: 35px;
  color: #FFFFFF;
  display: block;
  margin: 3px;
  filter: brightness(0) invert(1); /* change any color to white */
}
.user-rights-table .tables[data-v-4cc90616]  .access_modal {
  width: 13rem;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  position: absolute;
  right: 1.6rem;
  z-index: 99;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
}
.user-rights-table .tables[data-v-4cc90616]  .access_modal div {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  margin-bottom: 2px;
}
.user-rights-table .tables[data-v-4cc90616]  .access_modal div:hover {
  border-bottom: 2px;
  border-color: #0e2954;
  border-style: solid;
  margin-bottom: 0;
}
.user-rights-table .tables[data-v-4cc90616]  .access_modal div span {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  color: #0e2954;
}
.user-rights-table .tables[data-v-4cc90616]  .hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
