// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-49a48d1b]  td:last-child {
  padding: 2px;
}
[data-v-49a48d1b]  th:last-child {
  padding-left: 17px;
}
[data-v-49a48d1b]  .risma-button button {
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
