// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.excel-label[data-v-b2cd4438] {
  cursor: pointer;
  font-size: 16px;
  transition: color 0.2s;
  padding-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.excel-text[data-v-b2cd4438] {
  padding-left: 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
