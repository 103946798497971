import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  key: 0,
  class: "text-center mt-5"
};
var _hoisted_3 = {
  key: 1,
  class: "p-12"
};
var _hoisted_4 = {
  class: "2lg:flex"
};
var _hoisted_5 = {
  class: "mt-5"
};
var _hoisted_6 = {
  class: "text-gray-710 font-semibold uppercase pb-1"
};
var _hoisted_7 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_kpi_insights = _resolveComponent("kpi-insights");
  var _component_performance_card = _resolveComponent("performance-card");
  var _component_selectable_legend = _resolveComponent("selectable-legend");
  var _component_radar_chart = _resolveComponent("radar-chart");
  var _component_inline_select = _resolveComponent("inline-select");
  var _component_kpi_relevant_activities = _resolveComponent("kpi-relevant-activities");
  var _component_kpi_card = _resolveComponent("kpi-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.dataIsAbsent ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.theresNoData,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString($data.translate.eitherThereArentAnyKpisEnabledOrAnySolu), 1 /* TEXT */)])) : $data.kpiData.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_performance_card, {
    class: "2lg:mr-10 2lg:w-5/12 flex-shrink-0 2lg:min-w-370px",
    "overridden-overlay-classes": "border border-gray-350 bg-white",
    title: $options.currentDashboard.title || ''
  }, {
    content: _withCtx(function () {
      return [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_risma_doughnut_chart, {
        key: 1,
        class: "mx-1",
        datasets: $options.projectStatusData.datasets,
        options: $options.projectStatusData.options,
        "use-default-export": false
      }, null, 8 /* PROPS */, ["datasets", "options"])), _createVNode(_component_kpi_insights, {
        class: "mt-5",
        insights: $options.kpiInsights
      }, null, 8 /* PROPS */, ["insights"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]), _createVNode(_component_performance_card, {
    class: "flex-auto",
    "overridden-overlay-classes": "border border-gray-350 bg-white flex items-center",
    "is-export-on-parent": true,
    "overridden-export-frame-classes": "flex items-center justify-center relative",
    title: $data.translate.kpiPerformance
  }, {
    content: _withCtx(function () {
      return [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : $options.radarData ? (_openBlock(), _createBlock(_component_radar_chart, {
        key: 1,
        data: $data.combinedRadarDataset,
        colors: $options.radarData.colors,
        labels: $options.radarData.labels,
        "hover-tooltip-fn": $data.radarTooltipFn,
        range: [0, 100],
        "use-default-export": false
      }, {
        legend: _withCtx(function (_ref) {
          var appliedColors = _ref.appliedColors;
          return [_createVNode(_component_selectable_legend, {
            modelValue: $data.combinedRadarDataset,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.combinedRadarDataset = $event;
            }),
            class: "gap-20 py-4",
            "total-dataset": $options.radarData.datasets,
            "applied-colors": appliedColors
          }, null, 8 /* PROPS */, ["modelValue", "total-dataset", "applied-colors"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data", "colors", "labels", "hover-tooltip-fn"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_performance_card, {
    class: "p-0",
    "overridden-overlay-classes": "p-0"
  }, {
    title: _withCtx(function () {
      return [_createElementVNode("h3", _hoisted_6, [_createElementVNode("span", {
        title: $options.currentDashboard.title,
        class: "inline-block max-w-17/20 align-bottom truncate"
      }, _toDisplayString($options.currentDashboard.title), 9 /* TEXT, PROPS */, _hoisted_7), _cache[2] || (_cache[2] = _createTextVNode(" KPI "))])];
    }),
    menu: _withCtx(function () {
      return [_createElementVNode("div", null, [_createTextVNode(_toDisplayString($data.translate.sortBy) + ": ", 1 /* TEXT */), _createVNode(_component_inline_select, {
        modelValue: $setup.selectedSortingMethod,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $setup.selectedSortingMethod = $event;
        }),
        class: "font-semibold ml-2",
        options: _ctx.sortingOptions
      }, null, 8 /* PROPS */, ["modelValue", "options"])])];
    }),
    content: _withCtx(function () {
      return [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createBlock(_TransitionGroup, {
        key: 1,
        class: "flex flex-wrap justify-between pt-10",
        name: "kpi-list",
        tag: "div"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groups, function (group, index) {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-full xl:w-12/25 transition-all duration-1000 opacity-100"
            }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, function (card) {
              return _openBlock(), _createBlock(_component_kpi_card, {
                key: card.id,
                class: "mb-4",
                "card-header": _ctx.$trans(card.cardHeader),
                color: card._color,
                "module-title": card.moduleTitle,
                weight: card.weight,
                "kpi-value": card.kpiValue,
                "current-value": card.currentValue,
                "max-value": card.maxValue
              }, {
                description: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(_ctx.$trans(card.description)), 1 /* TEXT */)];
                }),
                details: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(card.details), 1 /* TEXT */)];
                }),
                relevantActivities: _withCtx(function () {
                  return [card.kpiDetails ? (_openBlock(), _createBlock(_component_kpi_relevant_activities, {
                    key: 0,
                    "kpi-id": card.id
                  }, null, 8 /* PROPS */, ["kpi-id"])) : _createCommentVNode("v-if", true)];
                }),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["card-header", "color", "module-title", "weight", "kpi-value", "current-value", "max-value"]);
            }), 128 /* KEYED_FRAGMENT */))]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }))];
    }),
    _: 1 /* STABLE */
  })])])) : _createCommentVNode("v-if", true)]);
}