import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
import DataFlowWizardStepMixin from './DataFlowWizardStepMixin';
export default {
  name: 'DataFlowWizardStepLegalBasis',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    FeatherIcon: FeatherIcon
  },
  mixins: [DataFlowWizardStepMixin],
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      DataFlowFields: DataFlowFields,
      currentTypeField: 'legalBasisQuestionId',
      fieldSelected: DataFlowFields.CURRENT_BASIS_SELECTED,
      translate: getTranslate['DataFlowWizardStepLegalBasis']()
    };
  }
};