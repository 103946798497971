import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  class: "flex flex-col"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$frameworkIds;
  var _component_breadcrumbs = _resolveComponent("breadcrumbs");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  return (_$props$frameworkIds = $props.frameworkIds) !== null && _$props$frameworkIds !== void 0 && _$props$frameworkIds.length ? (_openBlock(), _createBlock(_component_collapsible_panel, {
    key: 0,
    "local-storage-key": "framework_overall_collapsible"
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("h3", {
        class: "glob-h3 text-rm-text mb-1",
        title: $data.translate.framework
      }, _toDisplayString($data.translate.framework) + " (" + _toDisplayString($options.frameworkLists.length) + ") ", 9 /* TEXT, PROPS */, _hoisted_1)];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.frameworkLists, function (item, index) {
        return _openBlock(), _createBlock(_component_breadcrumbs, {
          key: "list_framework_".concat(index),
          class: "text-rm-text",
          items: item,
          "disable-hover-logic": true
        }, null, 8 /* PROPS */, ["items"]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true);
}