function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { getStatusOverviewControlNew } from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import { DateFormats } from '@/constants/DateFormats';
import { ControlsSettings, ChartsKey } from '@/constants/controls/ControlsDashboard';
import { StatusOverviewMixin } from '@/components/Pages/Controls/StatusOverviewMixin';
import { getFormattedDateBeforeToday, formatCurrentTime } from '@/utils/date';
export default {
  name: 'ControlsOverviewDashboard',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    RismaBarChart: RismaBarChart
  },
  mixins: [StatusOverviewMixin],
  data: function data() {
    return {
      topStatus: {},
      isLoaded: false,
      charts: [ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE, ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE, ChartsKey.STATUS_PR_PROCESS],
      datasets: JSON.parse(JSON.stringify(ControlsSettings().overviewCharts)),
      dateRange: {
        startDate: getFormattedDateBeforeToday(10, 'year', DateFormats.DATE_FORMAT),
        endDate: formatCurrentTime(DateFormats.DATE_FORMAT)
      },
      translate: getTranslate['ControlsOverviewDashboard']()
    };
  },
  computed: {
    totalControls: function totalControls() {
      var _this = this;
      return Object.keys(this.topStatus).map(function (item) {
        return {
          label: item,
          value: _this.topStatus[item]
        };
      });
    }
  },
  mounted: function mounted() {
    this.getStatusOverviewControl();
  },
  methods: {
    getStatusOverviewControl: function getStatusOverviewControl() {
      var _this2 = this;
      var searchData = {
        dateStart: this.dateRange.startDate,
        dateEnd: this.dateRange.endDate
      };
      getStatusOverviewControlNew(searchData).then(function (_ref) {
        var topStatus = _ref.topStatus,
          charts = _ref.charts;
        _this2.topStatus = topStatus;
        var chartData = _defineProperty(_defineProperty(_defineProperty({}, ChartsKey.STATUS_PR_PROCESS, charts[0].data), ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE, charts[1].data), ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE, charts[2].data);
        _this2.prepareDatasets(chartData);
        _this2.isLoaded = true;
      });
    },
    prepareDatasets: function prepareDatasets(data) {
      var _this3 = this;
      Object.keys(data).forEach(function (key) {
        if (typeof data[key] === 'string') return _this3.datasets[key].empty = true;
        switch (key) {
          case ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE:
          case ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE:
            {
              var _this3$prepareChartDa = _this3.prepareChartData(key, data, 'employee', 'total'),
                datasets = _this3$prepareChartDa.datasets,
                labels = _this3$prepareChartDa.labels;
              _this3.datasets[key].type = 'columnChart';
              _this3.datasets[key].datasets = datasets;
              _this3.datasets[key].labels = labels;
              _this3.datasets[key].chartHeight = _this3.getChartHeight(_this3.datasets[key].labels.length);
              break;
            }
          case ChartsKey.STATUS_PR_PROCESS:
            {
              var _this3$prepareStacked = _this3.prepareStackedBarChartData(key, data),
                _datasets = _this3$prepareStacked.datasets,
                _labels = _this3$prepareStacked.labels;
              _this3.datasets[key].type = 'stackedBarChart';
              _this3.datasets[key].datasets = _datasets;
              _this3.datasets[key].labels = _labels;
              break;
            }
        }
      });
    },
    prepareStackedBarChartData: function prepareStackedBarChartData(key, chartData) {
      var datasets = [];
      var labels = chartData[key].map(function (item) {
        return item.process;
      });
      this.datasets[key].graphsInfo.forEach(function (_ref2) {
        var color = _ref2.color,
          field = _ref2.field,
          title = _ref2.title;
        var data = [];
        var dataLabel = '';
        var backgroundColor = '';
        chartData[key].forEach(function (item) {
          data.push(item[field]);
          backgroundColor = color;
          dataLabel = title;
        });
        datasets.push({
          data: data,
          backgroundColor: backgroundColor,
          label: dataLabel
        });
      });
      return {
        datasets: datasets,
        labels: labels
      };
    },
    getChartHeight: function getChartHeight(itemCount) {
      var calculatedHeight = itemCount * 30 + 30;
      var minHeight = 400;
      return Math.max(minHeight, calculatedHeight);
    }
  }
};