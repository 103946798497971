import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_catalogue_header, {
    "view-type": $data.viewType,
    "view-types": $data.viewTypes,
    "overview-options-state": $data.overviewOptionsState,
    "onUpdate:viewType": $options.updateDisplayState,
    "onUpdate:overviewoption": $options.updateOverviewOptions
  }, null, 8 /* PROPS */, ["view-type", "view-types", "overview-options-state", "onUpdate:viewType", "onUpdate:overviewoption"]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_catalogue_header, {
    "view-type": $data.viewType,
    "view-types": $data.viewTypes,
    "is-overview-options-available": false,
    "onUpdate:viewType": $options.updateDisplayState,
    "onUpdate:overviewoption": $options.updateOverviewOptions
  }, null, 8 /* PROPS */, ["view-type", "view-types", "onUpdate:viewType", "onUpdate:overviewoption"])]);
}