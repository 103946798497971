import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  class: "preview-header"
};
var _hoisted_2 = {
  class: "preview-header-title"
};
var _hoisted_3 = {
  class: "preview-body"
};
var _hoisted_4 = {
  key: 0,
  class: "preview-body-section"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  class: "preview-footer"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  key: 1
};
var _hoisted_9 = {
  class: "preview-body-footer-section"
};
var _hoisted_10 = {
  class: "preview-body-footer-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['preview-cont preview-risk-tiles', {
      'preview-risk-closed': !$props.risk.open
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_preview_modal_link, {
    activity: $props.risk,
    class: "font-semibold hover:underline",
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDeleteItem: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('deleteItem', $props.risk);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title_with_icon, {
        class: "font-semibold hover:underline",
        icon: "confidential",
        type: "big",
        title: $props.risk.displayHeadline,
        "title-class": $props.risk.closed ? 'line-through' : '',
        "title-attribute": $options.hoverTitle,
        "display-icon": !!$props.risk.confidential
      }, null, 8 /* PROPS */, ["title", "title-class", "title-attribute", "display-icon"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])])]), _createElementVNode("div", _hoisted_3, [$props.risk.description && $props.risk.description !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.description, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "preview-description",
    innerHTML: $options.truncateHtml($props.risk.description, 255)
  }, null, 8 /* PROPS */, _hoisted_5)])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_6, [_ctx.isApprovalFlowEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: "".concat($props.risk.approved ? $data.translate.approved : $data.translate.notApproved),
    class: _normalizeClass({
      'title-not-approved': !$props.risk.approved
    }),
    type: "medium"
  }, null, 8 /* PROPS */, ["title", "class"])])) : _createCommentVNode("v-if", true), $props.risk.tags && $props.risk.tags.length ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.tags + ':',
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.tags), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */);
}