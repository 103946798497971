import WelcomePage from '@/components/Pages/WelcomePage/WelcomePage';
import ChangePassword from '@/components/Pages/WelcomePage/ChangePassword';
export default (function () {
  return [{
    path: '/',
    component: WelcomePage,
    meta: {
      skipLoginCheck: true
    }
  }, {
    path: '/change-password/:userId',
    component: ChangePassword,
    props: true,
    meta: {
      skipLoginCheck: true
    }
  }];
});