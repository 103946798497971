import i18n from '@/i18n';
export var getTranslate = {
  UserDescription: function UserDescription() {
    return {
      disableMfaForUser: i18n.t('Disable MFA for user'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      username: i18n.t('Username'),
      initials: i18n.t('Initials'),
      password: i18n.t('Password'),
      email: i18n.t('Email'),
      organisation: i18n.t('Organisation'),
      deactivate: i18n.t('Deactivate'),
      activate: i18n.t('Activate'),
      areYouSureYouWantToDeactivate: i18n.t('Are you sure you want to deactivate'),
      areYouSureYouWantToActivate: i18n.t('Are you sure you want to activate'),
      confirm: i18n.t('Confirm'),
      cancel: i18n.t('Cancel'),
      deactivateUser: i18n.t('Deactivate user'),
      activateUser: i18n.t('Activate user'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      apiUser: i18n.t('API user')
    };
  },
  UserEntry: function UserEntry() {
    return {
      beAwareThatThisUserHasBeenDeactivated: i18n.t('Be aware that this user has been deactivated'),
      userDoesntExist: i18n.t('User does not exist'),
      anErrorOccurred: i18n.t('An error occurred'),
      toProtectAPIaccount: i18n.t('To protect API account, please change your password to something that is at least _amount_ characters long')
    };
  }
};