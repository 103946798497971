import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ToolTip from '@/components/Atoms/Containers/ToolTip';
import { sortAlphabetic } from '@/utils/sort';
export default {
  name: 'DelegationList',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    ToolTip: ToolTip
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    labelKey: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    maxVisibleTags: {
      required: false,
      type: Number,
      default: -1,
      note: 'the max number of selected tags to be displayed. After the number has been reached the select will display: "{number} selected", instead of tags. if prop is -1 it will display all'
    },
    isUser: {
      required: false,
      type: Boolean,
      default: false,
      note: 'user has hover functionality to show user information'
    }
  },
  emits: ['edit'],
  data: function data() {
    return {
      hoverElementIdx: null,
      translate: getTranslate['DelegationList']()
    };
  },
  computed: {
    isTruncateList: function isTruncateList() {
      return this.sortedList.length > this.maxVisibleTags && this.maxVisibleTags !== -1;
    },
    sortedList: function sortedList() {
      return sortAlphabetic(this.list, this.labelKey);
    }
  }
};