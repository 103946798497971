import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'ChatInputField',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    messagesLength: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  emits: ['onSubmit'],
  data: function data() {
    return {
      text: '',
      focused: false
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return this.disabled || this.text.trim() === '';
    }
  },
  methods: {
    handleInput: function handleInput(event) {
      this.text = event.target.value;
    },
    handleSubmit: function handleSubmit() {
      if (this.isDisabled) return;
      if (this.text.trim().length > 0) {
        this.$emit('onSubmit', this.text);
        this.text = '';
      }
    },
    handleKeyDown: function handleKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey && !this.disabled) {
        event.preventDefault();
        this.handleSubmit();
      }
    }
  }
};