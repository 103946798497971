import i18n from '@/i18n';
export var getTranslate = {
  GapDashboard: function GapDashboard() {
    return {
      gapOverview: i18n.t('GAP overview'),
      overviewPrDomain: i18n.t('Overview pr domain'),
      generalGapSummary: i18n.t('General GAP summary'),
      gapIndicator: i18n.t('Gap indicator'),
      businessArea: i18n.t('Node'),
      questionnaire: i18n.t('Questionnaire(s)'),
      totalNumberOfAssessments: i18n.t('Total number of assessments'),
      notSet: i18n.t('Not set'),
      noGap: i18n.t('No Gap'),
      gapLow: i18n.t('Gap Low'),
      gapMedium: i18n.t('Gap Medium'),
      gapHigh: i18n.t('Gap High'),
      na: i18n.t('N/A'),
      nodes: i18n.t('Nodes'),
      includeUnderlyingNodes: i18n.t('Incl. underlying nodes'),
      organisations: i18n.t('Organisations'),
      tags: i18n.t('Tags'),
      overviewPrGapType: i18n.t('Overview pr gap types'),
      totals: i18n.t('Totals'),
      includeUnderlyingFrameworks: i18n.t('Incl. underlying framework')
    };
  },
  QuestionDashboard: function QuestionDashboard() {
    return {
      questionsByProcesses: i18n.t('Questions by processes'),
      node: i18n.t('Node'),
      questionnaire: i18n.t('Questionnaire(s)'),
      notApplicableQuestions: i18n.t('Not applicable questions'),
      answeredQuestions: i18n.t('Answered questions'),
      unansweredQuestions: i18n.t('Unanswered questions'),
      nodes: i18n.t('Nodes'),
      includeUnderlyingNodes: i18n.t('Incl. underlying nodes'),
      organisations: i18n.t('Organisations'),
      questionsOverview: i18n.t('Questions overview'),
      questionsPerHeadline: i18n.t('Questions per headline'),
      numberOfAnswers: i18n.t('Number of answers'),
      answersByHeadline: i18n.t('Answers by headline'),
      tags: i18n.t('Tags'),
      search: i18n.t('Search'),
      noRecordsMatching: i18n.t('No records matching'),
      displayOnlyDefaultQuestions: i18n.t('Display only default questions'),
      includeUnderlyingFrameworks: i18n.t('Incl. underlying framework')
    };
  }
};