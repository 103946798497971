import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "NoSearchResults"
};
var _hoisted_2 = {
  class: "panel-title"
};
var _hoisted_3 = {
  class: "panel-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h4", null, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, _toDisplayString(_ctx.$trans($props.body)), 1 /* TEXT */)])]);
}