function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import SearchableSelector from '@/components/Atoms/Inputs/SearchableSelector';
import { mockUsers } from '@/test/data/user';
export default {
  components: {
    SearchableSelector: SearchableSelector
  },
  data: function data() {
    return {
      selectedItems: [],
      items: _toConsumableArray(mockUsers)
    };
  },
  computed: {
    selectedItemsIds: function selectedItemsIds() {
      return this.selectedItems.map(function (item) {
        return item.id;
      });
    }
  },
  methods: {
    selected: function selected(item) {
      this.selectedItems.push(item);
    },
    apiMock: function apiMock(params) {
      var filteredItems = this.items.filter(function (item) {
        return item.label.toLowerCase().includes(params.q.toLowerCase());
      });
      var slicedItems = filteredItems.slice(params.start, params.start + params.limit);
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve({
            list: slicedItems,
            count: filteredItems.length
          });
        }, 1000);
      });
    }
  }
};