import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getQuizQuestionnaires(params) {
  return handleRequestResponse(Http.get('quiz/questionnaire?' + params));
}
export function getQuizQuestionnaireById(id) {
  return handleRequestResponse(Http.get('quiz/questionnaire/' + id));
}
export function createQuizQuestionnaire(data) {
  return handleRequestResponse(Http.post('quiz/questionnaire', data));
}
export function updateQuizQuestionnaire(id, data) {
  return handleRequestResponse(Http.patch('quiz/questionnaire/' + id, data));
}
export function deleteQuizQuestionnaire(id) {
  return handleRequestResponse(Http.delete('quiz/questionnaire/' + id));
}
export function createQuestionByQuestionnaireId(id, question) {
  return handleRequestResponse(Http.post("quiz/questionnaire/".concat(id, "/question"), question));
}
export function updateQuestionByQuestionnaireId(id, question) {
  return handleRequestResponse(Http.patch("quiz/questionnaire/".concat(id, "/question/").concat(question.id), question));
}
export function deleteQuestion(quizId, questionId) {
  return handleRequestResponse(Http.delete("quiz/questionnaire/".concat(quizId, "/question/").concat(questionId)));
}