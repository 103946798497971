import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "single-asset-choice relative"
};
var _hoisted_2 = {
  key: 1,
  class: "w-3/4 mt-3"
};
var _hoisted_3 = {
  class: "font-semibold mr-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.values.length > 0 ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    options: $options.nodesOptions,
    "selected-options": $options.nodesSelected,
    disabled: $props.readOnly,
    "label-key": "option",
    onSelected: $options.emitChanges,
    onClose: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('forceUpdate');
    })
  }, null, 8 /* PROPS */, ["options", "selected-options", "disabled", "onSelected"])) : _createCommentVNode("v-if", true), $options.onlyMyProccessLibraryChoices && $options.selectedByOthers.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($options.selectedByOthersDisplay), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString($data.translate.isAreAlreadyAddedByADifferentUser) + ".", 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.youCantSeeOrEditTheseProcessLibraryBecauseYourUserRightInhibitsYouToInteractWithIt) + ". ", 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}