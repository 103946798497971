import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex justify-between items-stretch font-semibold min-h-4rem"
};
var _hoisted_2 = {
  class: "w-2/3"
};
var _hoisted_3 = {
  class: "flex justify-center w-1/3"
};
var _hoisted_4 = {
  key: 0,
  class: "flex w-full justify-end items-center mb-4"
};
var _hoisted_5 = {
  class: "flex w-full justify-end"
};
var _hoisted_6 = {
  class: "mr-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_view_switcher = _resolveComponent("view-switcher");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "actions")]), _createElementVNode("div", _hoisted_3, [$props.isOverviewOptionsAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
    class: "mr-2 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function (state) {
      return _ctx.$emit('update:overviewoption', !$props.overviewOptionsState);
    })
  }, [_createElementVNode("span", null, _toDisplayString($options.overviewOptionsTitle), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: $options.overviewOptionsIcon,
    class: _normalizeClass(['ml-2 stroke-blue stroke-3 inline-block transform duration-500', {
      'rotate-180': $props.overviewOptionsState
    }])
  }, null, 8 /* PROPS */, ["icon", "class"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString($data.translate.display), 1 /* TEXT */), _createVNode(_component_view_switcher, {
    view: $props.viewType,
    views: $props.viewTypes,
    "onUpdate:view": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:viewType', $event);
    })
  }, null, 8 /* PROPS */, ["view", "views"])])])]);
}