function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { getControlsReports, getAllMotherControls, getProcesses as _getProcesses } from '@/api/controls/controls';
import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import ControlYearWheelDashboard from '@/components/Organisms/Controls/dashboards/ControlYearWheelDashboard';
import { Colors } from '@/Colors';
import { ControlsAnnualOverviewType, ControlStatusesUI } from '@/constants/controls/ControlsDashboard';
import { DateFormats } from '@/constants/DateFormats';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { getDataQueryStringFromArray, getFiltersQueryString } from '@/api/httpUtils';
import { sortByIndex } from '@/utils/sort';
import { prepareMonthsByYears } from '@/utils/format/templateWrappers';
import { getYearAfterAddingYears, formatCurrentDateTime, isDateBefore, formatTime, formatTimeUtc } from '@/utils/date';
import { capitalize } from '@/utils';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ControlAnnualOverviewWrapper',
  components: {
    LightTabItem: LightTabItem,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ControlYearWheelDashboard: ControlYearWheelDashboard
  },
  props: {
    group: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      loading: false,
      filterFrequencies: [RismaControlsUtils.frequencies.FREQ_MONTHLY, RismaControlsUtils.frequencies.FREQ_QUARTERLY, RismaControlsUtils.frequencies.FREQ_BIANNUALLY, RismaControlsUtils.frequencies.FREQ_ANNUALLY, RismaControlsUtils.frequencies.FREQ_TWO_YEARS, RismaControlsUtils.frequencies.FREQ_THREE_YEARS, RismaControlsUtils.frequencies.FREQ_FOUR_YEARS, RismaControlsUtils.frequencies.FREQ_FIVE_YEARS, RismaControlsUtils.frequencies.FREQ_CUSTOM],
      processes: [],
      controls: [],
      motherControls: [],
      updatedControl: null,
      updatedControlId: null,
      lastYear: getYearAfterAddingYears(3),
      baseLink: '/controls2/dashboards/annual-overview/',
      translate: getTranslate['ControlAnnualOverviewWrapper']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['controlActivityTypes'])), {}, {
    activityTypeIdsWithEnabledEfficiency: function activityTypeIdsWithEnabledEfficiency() {
      return this.controlActivityTypes.filter(function (activityType) {
        return activityType.controlsEfficiencyType;
      }).map(function (activityType) {
        return activityType.id;
      });
    },
    availableGroups: function availableGroups() {
      var result = [ControlsAnnualOverviewType.COMPLETION];
      if (this.controlsEfficiencyEnabled) {
        result.push(ControlsAnnualOverviewType.EFFICIENCY);
      }
      return result;
    },
    controlsEfficiencyEnabled: function controlsEfficiencyEnabled() {
      return !!this.getSettingValue('feature.controls_efficiency_enabled');
    },
    efficiencySelected: function efficiencySelected() {
      return this.group === ControlsAnnualOverviewType.EFFICIENCY;
    },
    selectedLegend: function selectedLegend() {
      return this.legends[this.group] || this.legends[ControlsAnnualOverviewType.COMPLETION];
    },
    legends: function legends() {
      var _this = this;
      return _defineProperty(_defineProperty({}, ControlsAnnualOverviewType.COMPLETION, [{
        label: this.translate.completedOnTime,
        color: Colors.noGap,
        value: ControlStatusesUI.COMPLETED_BEFORE
      }, {
        label: this.translate.completedAfterDeadline,
        color: this.getControlTrafficLightColorByNumber(1, true),
        value: ControlStatusesUI.COMPLETED_AFTER
      }, {
        label: this.translate.controlNotPerformed,
        color: Colors.high,
        value: ControlStatusesUI.NOT_PERFORMED
      }, {
        label: this.translate.notCompletedYet,
        color: Colors.system.lightGray,
        value: ControlStatusesUI.NOT_COMPLETED_YET
      }, {
        label: this.translate.awaitingReview,
        color: Colors.noReview,
        value: ControlStatusesUI.AWAITING_REVIEW
      }]), ControlsAnnualOverviewType.EFFICIENCY, this.getTrafficLightsForModule(MODULES.CONTROLS).map(function (light) {
        return {
          label: capitalize(_this.$trans(light.legend)) + ' ' + _this.translate.assessment.toLowerCase(),
          color: light.color,
          value: light.number
        };
      }));
    },
    dashboardDataset: function dashboardDataset() {
      var _this2 = this;
      var controls = this.group === ControlsAnnualOverviewType.EFFICIENCY ? this.controls.filter(function (control) {
        return _this2.activityTypeIdsWithEnabledEfficiency.includes(control.activityTypeId);
      }) : this.controls;
      return controls.map(function (control) {
        return _this2.prepareDashboardItem(control, _this2.group);
      });
    },
    allYears: function allYears() {
      var result = [];
      for (var i = this.firstYear; i <= this.lastYear; i++) {
        result.push(i);
      }
      return result;
    },
    firstYear: function firstYear() {
      var dates = [formatCurrentDateTime(DateFormats.DATE_YEAR_FORMAT)];
      this.controls.forEach(function (control) {
        var _control$history;
        dates.push(control.deadline);
        (_control$history = control.history) === null || _control$history === void 0 || _control$history.map(function (itemHistory) {
          dates.push(itemHistory.deadline);
        });
      });
      var minDate = dates.reduce(function (prev, current) {
        return isDateBefore(prev, current) ? prev : current;
      });
      return +formatTime(minDate, DateFormats.DATE_YEAR_FORMAT);
    }
  }),
  beforeMount: function beforeMount() {
    if (!this.availableGroups.includes(this.group)) {
      this.$router.push({
        name: 'ControlAnnualOverviewWrapper',
        params: {
          group: ControlsAnnualOverviewType.COMPLETION
        }
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.loading = true;
    Promise.all([this.getMotherControls(), this.getProcesses(), this.getAllControls()]).catch(function (e) {
      var _e$response;
      _this3.$notify({
        title: ((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.error) || _this3.translate.anErrorOccurred,
        type: 'error'
      });
    }).finally(function () {
      _this3.loading = false;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getControlTrafficLightColorByNumber', 'getTrafficLightsForModule'])), {}, {
    getProcesses: function getProcesses() {
      var _this4 = this;
      return _getProcesses().then(function (_ref2) {
        var list = _ref2.list;
        return _this4.processes = list;
      });
    },
    getAllControls: function getAllControls() {
      var _this5 = this;
      var params = this.prepareParams();
      return getControlsReports(params).then(function (_ref3) {
        var list = _ref3.list;
        _this5.controls = sortByIndex(list, 'cpath');
      });
    },
    getMotherControls: function getMotherControls() {
      var _this6 = this;
      return getAllMotherControls().then(function (_ref4) {
        var list = _ref4.list;
        return _this6.motherControls = list;
      });
    },
    updateControlByCpath: function updateControlByCpath(cpath) {
      var _this7 = this;
      var params = this.prepareParams() + "&filters[cpath][]=".concat(cpath);
      this.loading = true;
      return getControlsReports(params).then(function (_ref5) {
        var list = _ref5.list;
        var control = list[0];
        var index = _this7.controls.findIndex(function (control) {
          return control.id === _this7.updatedControlId;
        });
        if (index === -1) return;
        _this7.controls[index] = control;
      }).finally(function () {
        _this7.loading = false;
        _this7.updatedControlId = null;
        _this7.updatedControl = null;
      });
    },
    onDismissModal: function onDismissModal() {
      if (!this.updatedControl) return;
      return this.updateControlByCpath(this.updatedControl.cpath);
    },
    prepareParams: function prepareParams() {
      var requiredProps = ['organisationIds', 'tags', 'futureDeadlines', 'history', 'reviews', 'associations'];
      var result = '?' + getDataQueryStringFromArray(requiredProps);
      result += '&' + getFiltersQueryString('states', [RismaControlsUtils.states.STATE_PENDING]);
      result += '&' + getFiltersQueryString('frequencies', this.filterFrequencies);
      result += "&deadlineEndDate=".concat(this.lastYear, "-12-31");
      return result;
    },
    prepareDashboardItem: function prepareDashboardItem(control, overviewType) {
      var _control$frequencyInf,
        _this8 = this,
        _control$history2;
      var result = prepareMonthsByYears(this.allYears, {
        title: control.displayHeadline || control.cpath + ' ' + control.title,
        id: control.id
      });

      // future items
      (_control$frequencyInf = control.frequencyInfo) === null || _control$frequencyInf === void 0 || (_control$frequencyInf = _control$frequencyInf.futureDeadlines) === null || _control$frequencyInf === void 0 || _control$frequencyInf.forEach(function (futureDeadline) {
        var monthObj = _this8.findMonthByDate(result, futureDeadline);
        monthObj.hoverTitle = _this8.getItemHoverTitle(monthObj, futureDeadline);
        monthObj.legendValue = ControlStatusesUI.NOT_COMPLETED_YET;
      });

      // history
      (_control$history2 = control.history) === null || _control$history2 === void 0 || _control$history2.forEach(function (historyItem) {
        var monthObj = _this8.findMonthByDate(result, historyItem.deadline);
        monthObj.hoverTitle = _this8.getItemHoverTitle(monthObj, historyItem.deadline, historyItem.completed);
        monthObj.legendValue = _this8.getHistoryLegendValue(historyItem, overviewType);
      });

      // pending control
      var monthObj = this.findMonthByDate(result, control.deadline);
      monthObj.hoverTitle = this.getItemHoverTitle(monthObj, control.deadline, control.completed);
      monthObj.legendValue = this.getPendingControlLegendValue(control, overviewType);
      return {
        control: control,
        months: Object.values(result).reduce(function (res, monthsByYear) {
          return [].concat(_toConsumableArray(res), _toConsumableArray(monthsByYear));
        }, [])
      };
    },
    getHistoryLegendValue: function getHistoryLegendValue(historyItem, overviewType) {
      if (overviewType === ControlsAnnualOverviewType.EFFICIENCY) {
        return historyItem.completed_efficiency;
      }
      var legendValue = ControlStatusesUI.NOT_PERFORMED;
      if (historyItem.completed && historyItem.risma_state !== RismaControlsUtils.states.STATE_NOT_PERFORMED) {
        legendValue = isDateBefore(historyItem.completed, historyItem.deadline) ? ControlStatusesUI.COMPLETED_BEFORE : ControlStatusesUI.COMPLETED_AFTER;
      }
      var notReviewedStates = [RismaControlsUtils.states.STATE_READY_FOR_REVIEW, RismaControlsUtils.states.STATE_NOT_REVIEWED, RismaControlsUtils.states.STATE_REVIEW_NOT_PERFORMED];
      if (notReviewedStates.includes(historyItem.risma_state)) {
        legendValue = ControlStatusesUI.AWAITING_REVIEW;
      }
      return legendValue;
    },
    getPendingControlLegendValue: function getPendingControlLegendValue(control, overviewType) {
      if (overviewType === ControlsAnnualOverviewType.EFFICIENCY) {
        return control.completedEfficiency || ControlStatusesUI.NOT_COMPLETED_YET;
      }
      var legendValue = ControlStatusesUI.NOT_COMPLETED_YET;
      if (control.completed) {
        legendValue = isDateBefore(control.completed, control.deadline) ? ControlStatusesUI.COMPLETED_BEFORE : ControlStatusesUI.COMPLETED_AFTER;
      }
      return legendValue;
    },
    findMonthByDate: function findMonthByDate(yearsList, date) {
      var _yearsList$year;
      var month = formatTimeUtc(date, DateFormats.DATE_FORMAT_M);
      var year = formatTimeUtc(date, DateFormats.DATE_YEAR_FORMAT);
      return ((_yearsList$year = yearsList[year]) === null || _yearsList$year === void 0 ? void 0 : _yearsList$year.find(function (item) {
        return +item.month === +month;
      })) || {};
    },
    getItemHoverTitle: function getItemHoverTitle(item, deadlineDate) {
      var completedDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var base = item.title + ' (' + item.id + ')\n';
      var deadline = deadlineDate ? 'Deadline=' + deadlineDate.substring(0, 10) : '';
      var completed = completedDate ? ' Completed=' + completedDate.substring(0, 10) : '';
      return base + deadline + completed;
    }
  })
};