// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-risk-list-body[data-v-187f0ae2] {
  display: flex;
  flex-wrap: wrap;
}
.preview-risk-list-body[data-v-187f0ae2]  .preview-risk-mode-expanded {
  width: 100%;
}
.preview-risk-list-body[data-v-187f0ae2]  .preview-risk-mode-compact {
  width: 100%;
}
.preview-risk-list-body[data-v-187f0ae2]  .preview-risk-mode-tiles {
  width: 33%;
  padding: 0 1.5rem 1.5rem 0;
}
.preview-risk-list-body[data-v-187f0ae2]  .preview-risk-mode-tiles:nth-child(3n) {
  padding-right: 0;
}
.preview-risk-list-body .preview-risk-list-empty[data-v-187f0ae2] {
  width: 100%;
  padding: 1rem 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #4D4D4D;
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
