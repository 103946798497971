export default {
  name: 'ChatTag',
  props: {
    active: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    extraStyles: function extraStyles() {
      return this.active ? 'text-rm-white bg-rm-active-blue' : 'text-rm-active-blue bg-rm-active-blue--10 hover:bg-rm-white';
    }
  }
};