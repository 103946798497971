function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import tinymce from 'tinymce';
import 'tinymce/skins/ui/oxide/skin.min.css';
import Editor from '@tinymce/tinymce-vue';

// pluging that are used in specific types of TinyMceBox moved to different chunks: such as link, code, table, advlist, lists and paste
var plugins = {
  preview: function preview() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/preview');
  },
  importcss: function importcss() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/importcss');
  },
  searchreplace: function searchreplace() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/searchreplace');
  },
  autolink: function autolink() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/autolink');
  },
  autosave: function autosave() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/autosave');
  },
  save: function save() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/save');
  },
  directionality: function directionality() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/directionality');
  },
  visualblocks: function visualblocks() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/visualblocks');
  },
  visualchars: function visualchars() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/visualchars');
  },
  fullscreen: function fullscreen() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/fullscreen');
  },
  image: function image() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/image');
  },
  link: function link() {
    return import(/*webpackChunkName: 'tinymce-plugins-link'*/'tinymce/plugins/link');
  },
  media: function media() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/media');
  },
  code: function code() {
    return import(/*webpackChunkName: 'tinymce-plugins-code'*/'tinymce/plugins/code');
  },
  table: function table() {
    return import(/*webpackChunkName: 'tinymce-plugins-table'*/'tinymce/plugins/table');
  },
  charmap: function charmap() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/charmap');
  },
  nonbreaking: function nonbreaking() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/nonbreaking');
  },
  anchor: function anchor() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/anchor');
  },
  insertdatetime: function insertdatetime() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/insertdatetime');
  },
  advlist: function advlist() {
    return import(/*webpackChunkName: 'tinymce-plugins-advlist'*/'tinymce/plugins/advlist');
  },
  lists: function lists() {
    return import(/*webpackChunkName: 'tinymce-plugins-lists'*/'tinymce/plugins/lists');
  },
  wordcount: function wordcount() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/wordcount');
  },
  help: function help() {
    var langFile = tinymce.util.I18n.getCode() + '.js';
    var defaultLangFile = 'en.js';
    import(/*webpackChunkName: 'tinymce-plugins-help'*/'tinymce/plugins/help');
    import(/*webpackChunkName: 'tinymce-plugins-help-languages'*/'tinymce/plugins/help/js/i18n/keynav/' + langFile).catch(function () {
      return import(/*webpackChunkName: 'tinymce-plugins-help-languages'*/'tinymce/plugins/help/js/i18n/keynav/' + defaultLangFile);
    });
  },
  quickbars: function quickbars() {
    return import(/*webpackChunkName: 'tinymce-plugins'*/'tinymce/plugins/quickbars');
  }
};
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import AiClarifySender from '@/components/Organisms/AiAssistant/AiClarifySender';
import { getCurrentInstance } from 'vue';
import { removeHtmlTags } from '@/utils/Utils';
import { clickOutside } from '@/utils/directives/clickOutside';
import { configs, configKeys, TinyMceBoxTypes } from '@/utils/base/TinyMceBoxConfigs';
import { htmlDecode } from '@/utils/export/excel';
export default {
  name: 'TinyMceBox',
  components: {
    Editor: Editor,
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon,
    ButtonsRow: ButtonsRow,
    AiClarifySender: AiClarifySender
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    initialContent: {
      type: String,
      required: false,
      default: '',
      note: 'Content to appear in the textarea from start'
    },
    config: {
      type: Object,
      required: false,
      default: null,
      note: ''
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Whether to instantiate the editor to read-only mode'
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
      note: 'Width of the editor box'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 300,
      note: 'Height of the editor box'
    },
    showFullContentState: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determines if data should be shortened'
    },
    showControlButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determines if edit/cancel/save buttons will be displayed'
    },
    maxSymbolsPreview: {
      type: Number,
      required: false,
      default: null,
      note: 'Determines maximum symbols that will be displayed on preview show'
    },
    maxRowsPreview: {
      type: Number,
      required: false,
      default: 4,
      note: 'Determines maximum rows that will be displayed on preview show. Set null to show preview content without paragraphs'
    },
    alwaysOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'default',
      validator: function validator(type) {
        return Object.keys(configs).includes(type);
      },
      note: 'Configs with predefined toolbar and plugin sets'
    },
    editorWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    debounceTimeout: {
      type: Number,
      require: false,
      default: 200
    },
    headline: {
      type: String,
      required: false,
      default: ''
    },
    focusOnMount: {
      type: Boolean,
      required: false,
      default: false
    },
    aiSource: {
      type: String,
      required: false,
      default: null,
      note: 'Source of ai context'
    }
  },
  emits: ['changed', 'saved', 'canceled'],
  data: function data() {
    return {
      timer: 0,
      isEditMode: false,
      isFullContentShown: false,
      lastSavedChanges: '',
      content: '',
      importedPlugins: [],
      configs: configs,
      id: null,
      isEditorInit: false,
      tinymceModulesLoaded: false,
      translate: getTranslate['TinyMceBox']()
    };
  },
  computed: {
    init: function init() {
      var _this = this;
      var vm = this;
      return _objectSpread({
        license_key: 'gpl',
        height: this.height,
        width: this.width,
        skin: false,
        content_css: false,
        paste_block_drop: true,
        setup: function setup(editor) {
          editor.on('init', function () {
            if (_this.type === TinyMceBoxTypes.COMMENT) {
              vm.overrideShortcuts(editor);
            }
            if (_this.focusOnMount) {
              vm.focusEditor();
            }
          });
        },
        init_instance_callback: function init_instance_callback(editor) {
          editor.on('OpenWindow', function () {
            var dialog = document.querySelector('.tox-dialog');
            _this.replaceTextNode(dialog);
          });
          vm.isEditorInit = true;
        },
        highlight_on_focus: false,
        relative_urls: false,
        link_target_list: false,
        link_assume_external_targets: 'https',
        extended_valid_elements: 'a[href|target=_blank]',
        paste_data_images: false,
        paste_preprocess: function paste_preprocess(_, args) {
          var allowedTags = ['a', 'b', 'i', 'u', 'ul', 'li', 'p', 'br', 'ol'];
          var tagsToRegexp = allowedTags.reduce(function (result, tag) {
            return result += "(".concat(tag, ")|");
          }, '');
          if (tagsToRegexp) {
            tagsToRegexp = tagsToRegexp.slice(0, -1);
          }
          var regexp = new RegExp("<(?!/?(".concat(tagsToRegexp, ")).+?>"), 'gm');
          var strippedContent = vm.removeAttributes(args.content.replace(regexp, ''));
          args.content = strippedContent;
        },
        branding: false,
        promotion: false,
        anchor_top: false,
        anchor_bottom: false,
        link_title: false,
        content_style: this.contentStyle
      }, this.selectedConfig);
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.cancel,
        type: 'util',
        clickEvent: this.cancelChanges
      }, {
        text: this.translate.save,
        type: 'save',
        clickEvent: this.saveChanges
      }];
    },
    contentStyle: function contentStyle() {
      var font = "@font-face {\n                font-family: 'Inter';\n                font-display: swap;\n                src: url('".concat(window.location.origin, "/assets/fonts/Inter-Regular.ttf') format('truetype');\n            }");
      var styles = "".concat(font, " html { font-family: Inter, sans-serif; } p { margin: 0 } ol, ul { padding-left: 4rem; }");
      return styles;
    },
    decodedContent: function decodedContent() {
      return removeHtmlTags(htmlDecode(this.content)).trim();
    },
    firstLinesOfDecodedContent: function firstLinesOfDecodedContent() {
      if (!this.maxRowsPreview || this.showFullContentState) return '';
      var result = this.decodedContent.slice(0, this.maxSymbolsToShow).split('\n').slice(0, this.maxRowsPreview);
      var resultLength = result.reduce(function (len, cur) {
        return len + cur.length;
      }, 0);
      return this.decodedContent.length > resultLength + result.length - 1 ? '<p>' + result.join('</p><p>') + '...</p>' : '';
    },
    maxSymbolsToShow: function maxSymbolsToShow() {
      var _this$selectedConfig;
      return this.maxSymbolsPreview || ((_this$selectedConfig = this.selectedConfig) === null || _this$selectedConfig === void 0 ? void 0 : _this$selectedConfig.maxSymbolsPreview) || 500;
    },
    showExpansionLabel: function showExpansionLabel() {
      if (this.showFullContentState || !this.decodedContent) return false;
      return this.decodedContent.length > this.maxSymbolsToShow || !!this.firstLinesOfDecodedContent;
    },
    selectedConfig: function selectedConfig() {
      return this.configs[this.type];
    },
    editor: function editor() {
      if (this.tinymceModulesLoaded) {
        return tinymce.get(this.id);
      }
      return null;
    },
    textData: function textData() {
      if (!this.content) return '';
      return removeHtmlTags(this.content).trim();
    },
    showPreviewData: function showPreviewData() {
      return (!this.isEditMode || this.readOnly) && !this.alwaysOpen;
    },
    shortenedData: function shortenedData() {
      if (!this.showPreviewData) return '';
      var result = this.content;
      if (this.showExpansionLabel && !this.isFullContentShown) {
        result = this.firstLinesOfDecodedContent || this.decodedContent.slice(0, this.maxSymbolsToShow) + '... ';
      }
      return result;
    },
    showEditButton: function showEditButton() {
      return this.headline && !this.readOnly && !this.showControlButtons && !this.alwaysOpen && !this.isEditMode && this.type !== configKeys.COMMENT;
    },
    showEditText: function showEditText() {
      return !this.readOnly && !this.showControlButtons && !this.decodedContent && !this.showEditButton;
    },
    expansionLabel: function expansionLabel() {
      if (this.showExpansionLabel) {
        return this.isFullContentShown ? this.translate.clickToShowLess : this.translate.clickToShowAll;
      }
      if (this.showEditText) {
        return this.translate.addComment;
      }
      return '';
    }
  },
  watch: {
    isEditMode: function isEditMode(editMode) {
      if (!this.isEditorInit || !editMode) return;
      this.focusEditor();
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    Promise.all([this.loadTinymcePlugins(), this.loadTinymce()]).then(function () {
      return _this2.tinymceModulesLoaded = true;
    });
  },
  mounted: function mounted() {
    this.content = this.initialContent;
    this.lastSavedChanges = this.initialContent;
    this.id = 'tinymce_' + getCurrentInstance().uid.toString();
  },
  methods: {
    loadTinymce: function loadTinymce() {
      return Promise.all([import(/*webpackChunkName: 'tinymce-themes'*/'tinymce/themes/silver'), import(/*webpackChunkName: 'tinymce-icons'*/'tinymce/icons/default'), import(/*webpackChunkName: 'tinymce-models'*/'tinymce/models/dom')]);
    },
    loadTinymcePlugins: function loadTinymcePlugins() {
      if (!this.selectedConfig.plugins || !this.selectedConfig.plugins.length) {
        return Promise.resolve();
      }
      return Promise.all(this.selectedConfig.plugins.map(function (plugin) {
        return plugins[plugin]();
      }));
    },
    overrideShortcuts: function overrideShortcuts(editor) {
      var shortcuts = editor.shortcuts.shortcuts;
      if (!shortcuts) return;
      try {
        shortcuts['66,meta'].func = function () {}; //ctrl+b
        shortcuts['73,meta'].func = function () {}; //ctrl+i
        shortcuts['85,meta'].func = function () {}; //ctrl+u
      } catch (_unused) {
        return;
      }
    },
    onChange: function onChange(rawData) {
      var _this3 = this;
      if (!this.isEditMode && !this.alwaysOpen) return;
      if (!this.textData) {
        rawData = '';
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        if (!_this3.showControlButtons) {
          _this3.lastSavedChanges = rawData;
        }
        _this3.$emit('changed', rawData, _this3.textData);
      }, this.debounceTimeout);
    },
    onOutsideClick: function onOutsideClick(event) {
      if (this.showControlButtons) return;
      if (!event.target.closest('.tox')) {
        this.isEditMode = false;
      }
    },
    onExpansionLabelClick: function onExpansionLabelClick() {
      if (!this.textData && !this.readOnly) {
        this.isEditMode = true;
        return;
      }
      this.isFullContentShown = !this.isFullContentShown;
    },
    onContentClick: function onContentClick() {
      if (this.showControlButtons || this.readOnly) return;
      this.isEditMode = true;
    },
    saveChanges: function saveChanges() {
      this.lastSavedChanges = this.content;
      this.isEditMode = false;
      this.$emit('saved', this.content, this.textData);
    },
    cancelChanges: function cancelChanges() {
      var _this4 = this;
      clearTimeout(this.timer);
      this.content = this.lastSavedChanges;
      this.$emit('canceled', this.content, this.textData);
      this.$nextTick(function () {
        _this4.isEditMode = false;
      });
    },
    toggleTinyEditor: function toggleTinyEditor() {
      if (this.readOnly) return;
      this.isEditMode = !this.isEditMode;
    },
    replaceTextNode: function replaceTextNode(node) {
      var _this5 = this;
      node.childNodes.forEach(function (childNode) {
        if (childNode.nodeType === 3) {
          if (childNode.nodeValue) {
            childNode.nodeValue = _this5.$trans(childNode.nodeValue);
          }
        } else {
          _this5.replaceTextNode(childNode);
        }
      });
    },
    // removes data-* attributes and unnecessary lines
    removeAttributes: function removeAttributes(text) {
      var element = document.createElement('div');
      element.innerHTML = text;
      this.removeAttributesRecursive(element);
      return element.innerHTML;
    },
    removeAttributesRecursive: function removeAttributesRecursive(element) {
      var _this6 = this;
      var dataset = element.dataset;
      for (var key in dataset) {
        element.removeAttribute('data-' + key.split(/(?=[A-Z])/).join('-').toLowerCase());
        element.removeAttribute('aria-setsize');
      }
      element.childNodes.forEach(function (item) {
        return _this6.removeAttributesRecursive(item);
      });
    },
    focusEditor: function focusEditor() {
      var _this7 = this;
      this.$nextTick(function () {
        var _this7$editor, _this7$editor2, _this7$editor3, _this7$editor4;
        (_this7$editor = _this7.editor) === null || _this7$editor === void 0 || _this7$editor.focus();
        (_this7$editor2 = _this7.editor) === null || _this7$editor2 === void 0 || _this7$editor2.selection.select((_this7$editor3 = _this7.editor) === null || _this7$editor3 === void 0 ? void 0 : _this7$editor3.getBody(), true);
        (_this7$editor4 = _this7.editor) === null || _this7$editor4 === void 0 || _this7$editor4.selection.collapse(false);
      });
    }
  }
};