import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "toggle relative"
};
var _hoisted_2 = ["disabled"];
var _hoisted_3 = {
  key: 0,
  class: "ml-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["risma-toggle flex items-center", {
      disabled: $props.disabled
    }])
  }, [_createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.checkedValue = $event;
    }),
    disabled: $props.disabled,
    type: "checkbox",
    class: _normalizeClass(["absolute w-full h-full opacity-0 z-10", $props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'])
  }, null, 10 /* CLASS, PROPS */, _hoisted_2), [[_vModelCheckbox, $options.checkedValue]]), _cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "toggle-control"
  }, null, -1 /* HOISTED */))]), $props.label ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}