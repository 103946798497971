export var Timezones = [{
  value: 'Pacific/Samoa',
  text: '(UTC-11:00) Pacific/Samoa',
  textShort: 'Pacific/Samoa'
}, {
  value: 'Pacific/Honolulu',
  text: '(UTC-10:00) Pacific/Honolulu',
  textShort: 'Pacific/Honolulu'
}, {
  value: 'America/Anchorage',
  text: '(UTC-09:00) America/Alaska',
  textShort: 'America/Alaska'
}, {
  value: 'America/Los_Angeles',
  text: '(UTC-08:00) America/Los Angeles',
  textShort: 'America/Los Angeles'
}, {
  value: 'America/Phoenix',
  text: '(UTC-07:00) America/Arizona',
  textShort: 'America/Arizona'
}, {
  value: 'America/Managua',
  text: '(UTC-06:00) America/Managua',
  textShort: 'America/Managua'
}, {
  value: 'America/Bogota',
  text: '(UTC-05:00) America/Bogota',
  textShort: 'America/Bogota'
}, {
  value: 'Canada/Atlantic',
  text: '(UTC-04:00) Canada/Atlantic',
  textShort: 'Canada/Atlantic'
}, {
  value: 'America/Caracas',
  text: '(UTC-04:30) America/Caracas',
  textShort: 'America/Caracas'
}, {
  value: 'America/La_Paz',
  text: '(UTC-04:00) America/La Paz',
  textShort: 'America/La Paz'
}, {
  value: 'America/Santiago',
  text: '(UTC-04:00) America/Santiago',
  textShort: 'America/Santiago'
}, {
  value: 'Canada/Newfoundland',
  text: '(UTC-03:30) Canada/Newfoundland',
  textShort: 'Canada/Newfoundland'
}, {
  value: 'America/Sao_Paulo',
  text: '(UTC-03:00) America/Sao Paulo',
  textShort: 'America/Sao Paulo'
}, {
  value: 'America/Nuuk',
  text: '(UTC-02:00) America/Nuuk',
  textShort: 'America/Nuuk'
}, {
  value: 'Atlantic/Azores',
  text: '(UTC-01:00) Atlantic/Azores',
  textShort: 'Atlantic/Azores'
}, {
  value: 'Europe/London',
  text: '(UTC+00:00) Europe/London',
  textShort: 'Europe/London'
}, {
  value: 'Etc/Greenwich',
  text: '(UTC+00:00) Greenwich / Dublin',
  textShort: 'Greenwich / Dublin'
}, {
  value: 'UTC',
  text: '(UTC+00:00) UTC',
  textShort: 'UTC'
}, {
  value: 'Europe/Amsterdam',
  text: '(UTC+01:00) Europe/Amsterdam',
  textShort: 'Europe/Amsterdam'
}, {
  value: 'Europe/Copenhagen',
  text: '(UTC+01:00) Europe/Copenhagen',
  textShort: 'Europe/Copenhagen'
}, {
  value: 'Europe/Berlin',
  text: '(UTC+01:00) Europe/Berlin',
  textShort: 'Europe/Berlin'
}, {
  value: 'Europe/Vienna',
  text: '(UTC+01:00) Europe/Vienna',
  textShort: 'Europe/Vienna'
}, {
  value: 'Europe/Brussels',
  text: '(UTC+01:00) Europe/Brussels',
  textShort: 'Europe/Brussels'
}, {
  value: 'Europe/Madrid',
  text: '(UTC+01:00) Europe/Madrid',
  textShort: 'Europe/Madrid'
}, {
  value: 'Europe/Paris',
  text: '(UTC+01:00) Europe/Paris',
  textShort: 'Europe/Paris'
}, {
  value: 'Europe/Athens',
  text: '(UTC+02:00) Europe/Athens',
  textShort: 'Europe/Athens'
}, {
  value: 'Europe/Helsinki',
  text: '(UTC+02:00) Europe/Helsinki',
  textShort: 'Europe/Helsinki'
}, {
  value: 'Asia/Baghdad',
  text: '(UTC+03:00) Asia/Baghdad',
  textShort: 'Asia/Baghdad'
}, {
  value: 'Asia/Tehran',
  text: '(UTC+03:30) Asia/Tehran',
  textShort: 'Asia/Tehran'
}, {
  value: 'Asia/Muscat',
  text: '(UTC+04:00) Asia/Muscat',
  textShort: 'Asia/Muscat'
}, {
  value: 'Asia/Kabul',
  text: '(UTC+04:30) Asia/Kabul',
  textShort: 'Asia/Kabul'
}, {
  value: 'Asia/Karachi',
  text: '(UTC+05:00) Asia/Karachi',
  textShort: 'Asia/Karachi'
}, {
  value: 'Asia/Calcutta',
  text: '(UTC+05:30) Asia/Calcutta',
  textShort: 'Asia/Calcutta'
}, {
  value: 'Asia/Kolkata',
  text: '(UTC+05:30) Asia/Kolkata',
  textShort: 'Asia/Kolkata'
}, {
  value: 'Asia/Almaty',
  text: '(UTC+06:00) Asia/Almaty',
  textShort: 'Asia/Almaty'
}, {
  value: 'Asia/Rangoon',
  text: '(UTC+06:30) Asia/Rangoon',
  textShort: 'Asia/Rangoon'
}, {
  value: 'Asia/Bangkok',
  text: '(UTC+07:00) Asia/Bangkok',
  textShort: 'Asia/Bangkok'
}, {
  value: 'Asia/Hong_Kong',
  text: '(UTC+08:00) Asia/Hong Kong',
  textShort: 'Asia/Hong Kong'
}, {
  value: 'Asia/Taipei',
  text: '(UTC+08:00) Asia/Taipei',
  textShort: 'Asia/Taipei'
}, {
  value: 'Asia/Chongqing',
  text: '(UTC+08:00) Asia/Chongqing',
  textShort: 'Asia/Chongqing'
}, {
  value: 'Asia/Kuala_Lumpur',
  text: '(UTC+08:00) Asia/Kuala_Lumpur',
  textShort: 'Asia/Kuala_Lumpur'
}, {
  value: 'Asia/Singapore',
  text: '(UTC+08:00) Asia/Singapore',
  textShort: 'Asia/Singapore'
}, {
  value: 'Asia/Irkutsk',
  text: '(UTC+09:00) Asia/Irkutsk',
  textShort: 'Asia/Irkutsk'
}, {
  value: 'Asia/Tokyo',
  text: '(UTC+09:00) Asia/Tokyo',
  textShort: 'Asia/Tokyo'
}, {
  value: 'Australia/Adelaide',
  text: '(UTC+09:30) Australia/Adelaide',
  textShort: 'Australia/Adelaide'
}, {
  value: 'Australia/Darwin',
  text: '(UTC+09:30) Australia/Darwin',
  textShort: 'Australia/Darwin'
}, {
  value: 'Australia/Brisbane',
  text: '(UTC+10:00) Australia/Brisbane',
  textShort: 'Australia/Brisbane'
}, {
  value: 'Australia/Sydney',
  text: '(UTC+10:00) Australia/Sydney',
  textShort: 'Australia/Sydney'
}, {
  value: 'Pacific/Auckland',
  text: '(UTC+12:00) Pacific/Auckland',
  textShort: 'Pacific/Auckland'
}];