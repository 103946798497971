function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { getAll as _getAllProjects, getProjectTypes as _getProjectTypes, updateProject } from '@/api/compliance/projects';
import { createComplianceProject as _createComplianceProject } from '@/api/rismaadmin/complianceProject';
import { getAllQuestionnaires as _getAllQuestionnaires } from '@/api/compliance/questionnaire';
import { getAvailableQuestionnaireTypes } from '@/utils/questionnaires';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import StaticTable from '@/components/Molecules/StaticTable';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSwitch from '@/components/Atoms/Inputs/RismaSwitch';
import { StaticTableThemes } from '@/constants/StaticTableThemes';
export default {
  name: 'CreateComplianceProject',
  components: {
    SingleSelect: SingleSelect,
    MainWell: MainWell,
    RismaButton: RismaButton,
    InputField: InputField,
    RismaTitle: RismaTitle,
    StaticTable: StaticTable,
    FeatherIcon: FeatherIcon,
    RismaSwitch: RismaSwitch
  },
  data: function data() {
    return {
      questionnaires: [],
      origin: [],
      questionnaireType: null,
      questionnaireTypes: [],
      defaultQuestionnaire: null,
      projectType: null,
      projectTypes: [],
      projectName: '',
      projects: [],
      StaticTableThemes: StaticTableThemes,
      treeComponentValues: {
        TREE: 'default',
        MINI_TREE: 'leftTree'
      },
      treeComponentValidTypes: [ProjectTypes.FINANCIAL_CONTROLS, ProjectTypes.ENTERPRISE_RISK_MANAGEMENT, ProjectTypes.CONTROL_SELF_ASSESSMENT, ProjectTypes.GDPR_NY, ProjectTypes.DORA_CRITICAL_FUNCTIONS],
      treeComponentSettingProperties: ['treeShowActions', 'treeShowControls', 'treeShowPolicies', 'treeShowPolicies', 'treeShowSystems'],
      translate: getTranslate['CreateComplianceProject']()
    };
  },
  computed: {
    questionnaireTypesForSingleSelect: function questionnaireTypesForSingleSelect() {
      return this.questionnaireTypes.map(function (item) {
        return {
          value: item.id,
          label: item.type
        };
      });
    },
    projectTypesToShowOverviewPage: function projectTypesToShowOverviewPage() {
      return [ProjectTypes.GDPR, ProjectTypes.ISO27001, ProjectTypes.CSRD];
    },
    switchTreeComponentOptions: function switchTreeComponentOptions() {
      return [{
        name: this.translate.default,
        value: this.treeComponentValues.TREE
      }, {
        name: this.translate.detailed,
        value: this.treeComponentValues.MINI_TREE
      }];
    },
    switchOverviewPageOptions: function switchOverviewPageOptions() {
      return [{
        name: this.translate.yes,
        value: true
      }, {
        name: this.translate.no,
        value: false
      }];
    },
    columns: function columns() {
      return [this.translate.name, this.translate.projectType, this.translate.questionnaireType, this.translate.defaultQuestionnaire, this.translate.showOverviewPage, this.translate.treeView, this.translate.enabledisable];
    },
    dataset: function dataset() {
      var _this = this;
      return this.projects.map(function (item) {
        return {
          title: item.title,
          projectType: _this.getProjectTypeById(item.type),
          questionnaireType: _this.getQuestionnaireTypeById(item.questionnaireType),
          defaultQuestionnaire: _this.getDefaultQuestionnaireById(item.defaultQuestionnaireId),
          showOverviewMenuItem: item,
          display: item,
          toggleEnable: item
        };
      });
    },
    questionnairesOptions: function questionnairesOptions() {
      var _this2 = this;
      return this.questionnaires.filter(function (questionnaire) {
        return +questionnaire.questionnaireType === +_this2.questionnaireType;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    projectTypesOptions: function projectTypesOptions() {
      return this.projectTypes.map(function (item) {
        return {
          value: item.id,
          label: item.type
        };
      });
    }
  },
  mounted: function mounted() {
    this.getAllProjects();
    this.getProjectTypes();
    this.getQuestionnaireTypes();
    this.getAllQuestionnaires();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    getAllProjects: function getAllProjects() {
      var _this3 = this;
      return _getAllProjects(true, 'data[]=defaultQuestionnaireId&filters[]=includeDisabled').then(function (_ref) {
        var list = _ref.list;
        _this3.projects = list;
      });
    },
    getProjectTypes: function getProjectTypes() {
      var _this4 = this;
      return _getProjectTypes().then(function (_ref2) {
        var list = _ref2.list;
        _this4.projectTypes = list;
      });
    },
    getQuestionnaireTypes: function getQuestionnaireTypes() {
      var _this5 = this;
      return getAvailableQuestionnaireTypes().then(function (list) {
        return _this5.questionnaireTypes = list;
      });
    },
    getAllQuestionnaires: function getAllQuestionnaires() {
      var _this6 = this;
      return _getAllQuestionnaires().then(function (_ref3) {
        var list = _ref3.list;
        _this6.questionnaires = list;
      });
    },
    createComplianceProject: function createComplianceProject() {
      var _this7 = this;
      if (!this.defaultQuestionnaire) {
        this.$notify({
          title: this.translate.pleaseSelectADefaultQuestionnaire,
          type: 'error'
        });
        return;
      }
      var projectObject = {
        projectName: this.projectName,
        projectType: this.projectType,
        defaultQuestionnaire: this.defaultQuestionnaire,
        questionnaireType: this.questionnaireType
      };
      return _createComplianceProject(projectObject).then(function () {
        _this7.$notify({
          title: _this7.translate.projectCreated,
          type: 'success'
        });
        _this7.resetFields();
        _this7.loadUsers();
        _this7.loadCurrentUser();
      }).then(this.getAllProjects).catch(function (error) {
        _this7.$notify({
          title: error,
          type: 'error'
        });
      });
    },
    resetFields: function resetFields() {
      this.projectName = '';
      this.projectType = null;
      this.defaultQuestionnaire = null;
      this.questionnaireType = null;
    },
    handleClickProjectSetting: function handleClickProjectSetting(project, data, field) {
      var _this8 = this;
      updateProject(project.id, data).then(function (response) {
        var project = _this8.projects.find(function (project) {
          return +project.id === +response.id;
        });
        if (project) project[field] = response[field];
        if (field === 'enabled') {
          _this8.loadUsers();
        }
        _this8.loadCurrentUser();
      });
    },
    getQuestionnaireTypeById: function getQuestionnaireTypeById(id) {
      var _this$questionnaireTy;
      return (_this$questionnaireTy = this.questionnaireTypes.find(function (type) {
        return +type.id === +id;
      })) === null || _this$questionnaireTy === void 0 ? void 0 : _this$questionnaireTy.type;
    },
    getDefaultQuestionnaireById: function getDefaultQuestionnaireById(id) {
      var _this$questionnaires$;
      return (_this$questionnaires$ = this.questionnaires.find(function (questionnaire) {
        return +questionnaire.id === +id;
      })) === null || _this$questionnaires$ === void 0 ? void 0 : _this$questionnaires$.title;
    },
    getProjectTypeById: function getProjectTypeById(id) {
      var _this$projectTypes$fi;
      return (_this$projectTypes$fi = this.projectTypes.find(function (project) {
        return +project.id === +id;
      })) === null || _this$projectTypes$fi === void 0 ? void 0 : _this$projectTypes$fi.type;
    },
    checkIfMiniTreeSwitchAvailable: function checkIfMiniTreeSwitchAvailable(project) {
      return !(this.treeComponentValidTypes.includes(project.type) || this.treeComponentSettingProperties.some(function (property) {
        return project[property];
      }));
    }
  })
};