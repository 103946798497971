function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "m-6 w-full bg-white flex flex-col"
};
var _hoisted_2 = {
  class: "flex flex-wrap"
};
var _hoisted_3 = {
  class: "pt-4 px-6"
};
var _hoisted_4 = {
  class: "flex justify-end items-center relative"
};
var _hoisted_5 = {
  class: "absolute top-8 right-10px"
};
var _hoisted_6 = {
  class: "flex"
};
var _hoisted_7 = {
  key: 1,
  class: "pt-8"
};
var _hoisted_8 = {
  key: 1,
  class: "text-center mb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item_new = _resolveComponent("tab-item-new");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_popup = _resolveComponent("risma-popup");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_kpi_static_table = _resolveComponent("kpi-static-table");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_kpi_creation_modal = _resolveComponent("kpi-creation-modal");
  var _component_edit_dashboard_modal = _resolveComponent("edit-dashboard-modal");
  var _component_alert = _resolveComponent("alert");
  var _component_dashboard_creation_modal = _resolveComponent("dashboard-creation-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tabs, function (tab, index) {
    return _openBlock(), _createBlock(_component_tab_item_new, {
      key: index,
      link: '/performance-report/settings/' + tab.dashboardId,
      style: _normalizeStyle($options.tabItemStyle)
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(tab.label), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["link", "style"]);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_tab_item_new, {
    class: "cursor-pointer",
    style: _normalizeStyle({
      width: $data.addButtonWidth
    }),
    onClick: $options.toggleDashboardCreationModal
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style", "onClick"])])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_button, {
    text: $data.translate.addNewKpi,
    type: "save",
    onClick: $options.toggleKpiCreationModal
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus-circle",
        "fill-class": "fill-white",
        class: "mr-2 stroke-2 text-blue-350"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_feather_icon, {
    class: "ml-3 stroke-2 cursor-pointer",
    icon: "settings",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return $data.dashboardSettingsEnabled = true;
    }, ["stop"]))
  }), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_popup, {
    class: "min-w-15rem",
    "is-hidden": !$data.dashboardSettingsEnabled,
    onHide: _cache[3] || (_cache[3] = function ($event) {
      return $data.dashboardSettingsEnabled = false;
    })
  }, {
    default: _withCtx(function () {
      return [$options.isDashboardEditable ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "flex text-blue-750 cursor-pointer",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $data.renamingModalShown = true;
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "edit-3",
        class: "mr-2"
      }), _createTextVNode(" " + _toDisplayString($data.translate.rename), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.visibilityOptions, function (value, key) {
        return _openBlock(), _createElementBlock("div", {
          key: key,
          class: "flex text-blue-750 justify-between"
        }, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_feather_icon, {
          class: "mr-2",
          icon: "eye"
        }), _createTextVNode(" " + _toDisplayString(value.label), 1 /* TEXT */)]), _createVNode(_component_risma_toggle, {
          "model-value": value.state,
          onInput: function onInput($event) {
            return $options.editCurrentDashboard(_defineProperty({}, key, $event));
          }
        }, null, 8 /* PROPS */, ["model-value", "onInput"])]);
      }), 128 /* KEYED_FRAGMENT */)), $options.isDashboardEditable ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "flex cursor-pointer text-red-50",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $data.dashboardRemovingAlertShown = true;
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "trash-2",
        class: "stroke-2 mr-2"
      }), _createTextVNode(" " + _toDisplayString($data.translate.deleteDashboard), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-hidden"])])]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_7, [$options.kpisById.length ? (_openBlock(), _createBlock(_component_kpi_static_table, {
    key: $props.dashboardId,
    kpis: $options.kpisById,
    metadata: $data.metadata,
    "kpis-preview-score": $data.kpiPreviewScore,
    onLoadScore: $options.getCalculation,
    onUpdate: $options.updateKpi,
    onDelete: $options.handleDeleteKpi
  }, null, 8 /* PROPS */, ["kpis", "metadata", "kpis-preview-score", "onLoadScore", "onUpdate", "onDelete"])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_risma_title, {
    type: "small",
    title: $options.nothingToShowTitle
  }, null, 8 /* PROPS */, ["title"])]))]))]), $data.isKpiCreationModalShown ? (_openBlock(), _createBlock(_component_kpi_creation_modal, {
    key: 0,
    "dashboard-id": $props.dashboardId,
    metadata: $data.metadata,
    onAdded: $options.handleNewKpi,
    onDismiss: $options.toggleKpiCreationModal
  }, null, 8 /* PROPS */, ["dashboard-id", "metadata", "onAdded", "onDismiss"])) : _createCommentVNode("v-if", true), $data.renamingModalShown ? (_openBlock(), _createBlock(_component_edit_dashboard_modal, {
    key: 1,
    "current-title": $options.currentDashboard.title,
    "title-char-limit": 70,
    onClick: _cache[4] || (_cache[4] = _withModifiers(function () {}, ["stop"])),
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.renamingModalShown = false;
    }),
    onAccept: $options.editDashboardTitle
  }, null, 8 /* PROPS */, ["current-title", "onAccept"])) : _createCommentVNode("v-if", true), $data.dashboardRemovingAlertShown ? (_openBlock(), _createBlock(_component_alert, {
    key: 2,
    type: "small",
    header: $data.translate.confirmation,
    body: $options.alertBody,
    "button-ok-text": $data.translate.ok,
    "button-dismiss-text": $data.translate.cancel,
    onClick: _cache[6] || (_cache[6] = _withModifiers(function () {}, ["stop"])),
    onDismiss: _cache[7] || (_cache[7] = function ($event) {
      return $data.dashboardRemovingAlertShown = false;
    }),
    onAccept: $options.removeCurrentDashboard
  }, null, 8 /* PROPS */, ["header", "body", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), $data.isDashboardCreationModalShown ? (_openBlock(), _createBlock(_component_dashboard_creation_modal, {
    key: 3,
    "title-char-limit": 70,
    onCreate: $options.onDashboardCreation,
    onDismiss: $options.toggleDashboardCreationModal
  }, null, 8 /* PROPS */, ["onCreate", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}