function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { COMPANIES_TABLE_COLUMNS } from '@/constants/companies/companiesColumns';
import { sortAlphabetic } from '@/utils/sort';
import { getCompanyWithUrl as _getCompanyWithUrl } from '@/utils/format/companies';
export default {
  name: 'CompanyGenericTable',
  introduction: 'generic component to show table for companies',
  components: {
    PreviewModalLink: PreviewModalLink,
    StaticTable: StaticTable,
    RismaTitle: RismaTitle
  },
  props: {
    companies: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of companies'
    },
    hiddenColumns: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of columns to hide'
    }
  },
  emits: ['update', 'dismiss'],
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['isAdmin'])), {}, {
    columns: function columns() {
      var _this = this;
      var columns = _objectSpread({}, COMPANIES_TABLE_COLUMNS);
      this.hiddenColumns.forEach(function (column) {
        return delete columns[column];
      });
      return Object.values(columns).map(function (column) {
        return _this.$trans(column);
      });
    },
    sortedCompanies: function sortedCompanies() {
      return sortAlphabetic(this.companies, 'name');
    },
    dataset: function dataset() {
      var _this2 = this;
      return this.sortedCompanies.map(function (company) {
        var result = {
          name: {
            company: company
          },
          address: company.address || '',
          city: company.city || '',
          zipCode: company.zipCode || '',
          constryCode: company.countryCode || '',
          vatNo: company.vatNo || '',
          lei: company.lei
        };
        _this2.hiddenColumns.forEach(function (column) {
          return delete result[column];
        });
        return result;
      });
    }
  }),
  methods: {
    getCompanyWithUrl: function getCompanyWithUrl(company) {
      return _getCompanyWithUrl(company);
    }
  }
};