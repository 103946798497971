function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import { updateNodeByProjectIdAndNodeId } from '@/api/compliance/node';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RiskAssessmentsIsms from '@/components/Organisms/Risk/RiskAssessmentsIsms';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import QuestionnaireStats from '@/components/Molecules/Compliance/QuestionnaireStats';
import RiskGrid from '@/components/Organisms/RiskGrid';
import WizardFlowWrapper from '@/components/Organisms/WizardFlowWrapper';
import ComplianceCsrdVersions from '@/components/Molecules/Modal/ComplianceCsrdVersions';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { UserLevels } from '@/constants/UserLevels';
import { Colors } from '@/Colors';
import { NodeFields } from '@/constants/compliance/NodeFields';
import { MODULES } from '@/constants/modules';
import { toLocalDate } from '@/utils/date';
import { generateComputed } from '@/utils/base/generateComputed';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel } from '@/utils/Compliance/complianceProject';
import { prepareRiskDots } from '@/components/Pages/Risks/RiskReports/RiskReportUtilsRisk';
import { RiskAssessmentMixin } from '@/mixins/RiskAssessmentMixin';
import { RiskGridMixin } from '@/mixins/RiskGridMixin';
import { ActivityTypeMixin } from '@/mixins/ActivityTypeMixin';
export default {
  name: 'DescriptionPage',
  introduction: '',
  description: '',
  token: '<description-page></description-page>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaDoughnutChart: RismaDoughnutChart,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    TinyMceBox: TinyMceBox,
    ActivityCustomFields: ActivityCustomFields,
    RiskAssessmentsIsms: RiskAssessmentsIsms,
    SingleSelect: SingleSelect,
    QuestionnaireStats: QuestionnaireStats,
    RiskGrid: RiskGrid,
    WizardFlowWrapper: WizardFlowWrapper,
    ComplianceCsrdVersions: ComplianceCsrdVersions
  },
  mixins: [RiskAssessmentMixin, RiskGridMixin, ActivityTypeMixin],
  props: {
    nodeId: {
      required: true,
      type: [String, Number],
      note: 'The node that needs to be edited'
    },
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    },
    projectId: {
      type: Number,
      required: true,
      note: 'project id for the current project'
    }
  },
  emits: ['deleteNode', 'questionnaire', 'propertyChanged'],
  data: function data() {
    return {
      UserLevels: UserLevels,
      updateTimer: 0,
      retryAction: null,
      alertBody: '',
      module: MODULES.COMPLIANCE,
      pieSliceColors: [Colors.answered, Colors.unanswered, Colors.na],
      toLocalDate: toLocalDate,
      isRiskDataLoaded: false,
      gridBoxSize: 45,
      translate: getTranslate['DescriptionPage'](),
      NodeFields: NodeFields
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    aiAssistantEnabled: 'aiAssistantEnabled'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, {
    currentUserComplianceProjectById: 'complianceProjectById',
    currentUser: 'currentUser'
  })), generateComputed(['description', 'purpose', 'status'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    currentProject: function currentProject() {
      return _objectSpread(_objectSpread({}, this.currentUserComplianceProjectById(this.projectId)), this.currentProjectData);
    },
    userHasAccessToGapAnalysis: function userHasAccessToGapAnalysis() {
      return this.data.currentUserRights.canViewGaps;
    },
    showWizardFlow: function showWizardFlow() {
      return this.hasWizardFlowModal && this.data.questionnaires.length && !this.currentProject.locked;
    },
    hasWizardFlowModal: function hasWizardFlowModal() {
      return this.getSettingValue('feature.dataflow_wizard');
    },
    isManagementReportEnabled: function isManagementReportEnabled() {
      return this.getSettingValue('feature.compliance.compliance_management_report');
    },
    isCSRD: function isCSRD() {
      var _this$currentProject;
      return ((_this$currentProject = this.currentProject) === null || _this$currentProject === void 0 ? void 0 : _this$currentProject.type) === ProjectTypes.CSRD;
    },
    canDeleteNode: function canDeleteNode() {
      return this.data.currentUserRights.canDelete;
    },
    showPurpose: function showPurpose() {
      return this.isFieldInOptionalFields('process_purpose') || !this.hasActivityTypeWithOptionalFields;
    },
    showStatus: function showStatus() {
      return this.isFieldInOptionalFields('process_status') || !this.hasActivityTypeWithOptionalFields;
    },
    showQuestionnaireStats: function showQuestionnaireStats() {
      var _this$data;
      return (this.isFieldInOptionalFields('questionnaire') || !this.hasActivityTypeWithOptionalFields) && ((_this$data = this.data) === null || _this$data === void 0 || (_this$data = _this$data.questionnaires) === null || _this$data === void 0 ? void 0 : _this$data.length);
    },
    showGapAnalysis: function showGapAnalysis() {
      var _this$currentActivity;
      if (((_this$currentActivity = this.currentActivityType) === null || _this$currentActivity === void 0 || (_this$currentActivity = _this$currentActivity.optionalFields) === null || _this$currentActivity === void 0 ? void 0 : _this$currentActivity.indexOf('gap_schema')) === -1) return false;
      return this.userHasAccessToGapAnalysis && this.data.gapSchemaId;
    },
    hasActivityTypeWithOptionalFields: function hasActivityTypeWithOptionalFields() {
      var _this$currentActivity2;
      return (_this$currentActivity2 = this.currentActivityType) === null || _this$currentActivity2 === void 0 ? void 0 : _this$currentActivity2.optionalFields;
    },
    gapAnalysisChartData: function gapAnalysisChartData() {
      var labels = [this.translate.answered, this.translate.notAnswered, this.translate.na];
      var data = [this.data.values.gap_records_answered, this.data.values.gap_records_count - this.data.values.gap_records_answered - this.data.values.gap_records_na, this.data.values.gap_records_na];
      return {
        datasets: [{
          data: data,
          backgroundColor: this.pieSliceColors
        }],
        labels: labels
      };
    },
    riskProjectId: function riskProjectId() {
      return this.currentProject.riskProjectId || null;
    },
    showRiskAssessment: function showRiskAssessment() {
      if (!this.getSettingValue('feature.compliance_nodes_risk_assessment')) return false;
      return this.hasActivityTypeWithOptionalFields && this.isFieldInOptionalFields('risk_assessment');
    },
    rtoRpoReadOnly: function rtoRpoReadOnly() {
      return !!this.getSettingValue('feature.nodes_inherit_rpo_rto_from_systems') || this.currentProject.locked;
    },
    showRtoRpo: function showRtoRpo() {
      return this.getSettingValue('feature.compliance_nodes_risk_assessment') && this.hasActivityTypeWithOptionalFields && this.isFieldInOptionalFields('rto_rpo');
    },
    matrix: function matrix() {
      var dots = prepareRiskDots(this.risk, this.currentPeriod, this.statusColor, this.inherentRisksEnabled, this.matrixInverted);
      return this.getMatrix(dots);
    },
    descriptionLabel: function descriptionLabel() {
      return getProjectDescriptionLabel(this.currentProject);
    },
    purposeLabel: function purposeLabel() {
      return getProjectPurposeLabel(this.currentProject);
    },
    statusLabel: function statusLabel() {
      return getProjectStatusLabel(this.currentProject);
    },
    aiSourceForDescription: function aiSourceForDescription() {
      if (!this.isCSRD || !this.aiAssistantEnabled) return null;
      return "".concat(this.currentProject.title, " > ").concat(this.data.title, " > ").concat(this.descriptionLabel);
    },
    aiSourceForPurpose: function aiSourceForPurpose() {
      if (!this.isCSRD || !this.aiAssistantEnabled) return null;
      return "".concat(this.currentProject.title, " > ").concat(this.data.title, " > ").concat(this.purposeLabel);
    },
    currentProjectData: function currentProjectData() {
      return this.complianceProjectById(this.projectId);
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), {}, {
    getData: function getData() {
      var _this = this;
      this.getRtoFields();
      this.getRpoFields();
      this.getRisksData().then(function () {
        return _this.isRiskDataLoaded = true;
      });
    },
    confirmDelete: function confirmDelete() {
      var _this2 = this;
      this.deleteAlertMessage = this.translate.areYouSureYouWantToDelete + ' "' + this.data.title + '" ' + this.translate.andUnderlyingNodesPleaseNoteThatLinksToAn;
      this.$confirm(this.translate.deleteNode + '?', this.deleteAlertMessage, function (res) {
        return res && _this2.deleteNode();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    deleteNode: function deleteNode() {
      this.$emit('deleteNode');
    },
    updateItem: function updateItem() {
      var _this3 = this;
      clearTimeout(this.updateTimer);
      this.alertBody = '';
      this.retryAction = null;
      return updateNodeByProjectIdAndNodeId(this.projectId, this.data.id, this.data).then(function (_ref) {
        var questionnaire = _ref.questionnaire,
          activityType = _ref.activityType,
          values = _ref.values;
        if (questionnaire && questionnaire.id !== _this3.data.questionnaireId) {
          _this3.$emit('questionnaire', questionnaire);
          _this3.data.questionnaire = questionnaire;
          _this3.data.values = values;
        }
        if (activityType && _this3.data.activityType) {
          if (activityType.id !== _this3.data.activityType.id) {
            _this3.data.activityType = activityType;
          }
        } else {
          _this3.data.activityType = activityType;
        }
      }).catch(function () {
        if (_this3.getSettingValue('feature.compliance_raci_all_can_edit')) {
          _this3.alertBody = _this3.translate.saveFailed;
        } else {
          _this3.alertBody = _this3.translate.saveFailedYouMustBeResponsibleOrAccountabl;
        }
        _this3.retryAction = function () {
          return _this3.updateItem();
        };
        _this3.$confirm(_this3.translate.anErrorOccurred, _this3.alertBody, function (res) {
          return res && _this3.retryAction;
        }, {
          buttonOkText: _this3.translate.tryAgain
        });
      });
    },
    dotHoverFunction: function dotHoverFunction(dot) {
      return dot.title;
    },
    isFieldReadyOnly: function isFieldReadyOnly(field) {
      var _this$currentProject$;
      return !!this.currentProject.locked || ((_this$currentProject$ = this.currentProject.lockedFields) === null || _this$currentProject$ === void 0 ? void 0 : _this$currentProject$.includes(field));
    }
  })
};