import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "pb-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_gap_summary_table = _resolveComponent("gap-summary-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_gap_summary_table, {
    columns: ['Node', 'Questionnaire(s)', 'Total number of assessments'],
    "dataset-main": $data.dataset1
  }, null, 8 /* PROPS */, ["dataset-main"])]), _createElementVNode("div", null, [_createVNode(_component_gap_summary_table, {
    columns: ['Node', 'Total number of something'],
    "dataset-main": $data.dataset2,
    "totals-row-enabled": false
  }, null, 8 /* PROPS */, ["dataset-main"])])]);
}