function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { sheet_to_array_buffer, handleSave } from './index';
import { getIsoDate, isDateValid } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
import { stripHtmlString } from '@/utils/escapeHtml';
export var CellTypes = {
  TEXT: 's',
  BOOLEAN: 'b',
  ERROR: 'e',
  NUMBER: 'n',
  DATE: 'd',
  STUB: 'z',
  GENERAL: ''
};
var SSF = {
  14: 'm/d/yy',
  22: 'm/d/yy h:mm'
};
/**
 * Save a excel file by the content of a table
 *
 * All <ul> with children <li> will be by a ; (semicolon) when saved to the excel file
 *
 * Please note that only function exported is toExcel. The magic will happen from here
 *
 * EXAMPLE:
 *
 * import {Export} form'@/utils
 * or
 * import {toExcel} form'@/utils/export
 * ...
 * Export.toExcel('myTableId')
 * Export.toExcel('myTableId', 'MyOwnFilename', 'MyOwnSheetName')
 *
 * @param id Required! The CSS ID of the table you would like to export
 * @param filename - default RISMAbusiness - The filename you would like to save the file as.
 * @param sheetName - default RISMAbusiness - The name of the sheet in the Excel file
 * @param addDateToFilename - default true - Should the date be appended to the filename
 * @returns {*}
 */
export function toExcel(htmlContent) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'RISMAbusiness';
  var sheetName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'RISMAbusiness';
  var addDateToFilename = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return export_table_to_excel(htmlContent, getFilename(filename, addDateToFilename), sheetName);
}
export function toExcelRaw(id) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'RISMAbusiness';
  var sheetName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'RISMAbusiness';
  var addDateToFilename = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return export_table_to_excel(document.getElementById(id).cloneNode(true), getFilename(filename, addDateToFilename), sheetName);
}

/**
 * Export json to Excel
 *
 * @param {*} data - json
 * @param filename - default RISMAbusiness - The filename you would like to save the file as.
 * @param sheetName - default RISMAbusiness - The name of the sheet in the Excel file
 * @param addDateToFilename - default true - Should the date be appended to the filename
 */
export function json2Excel(data) {
  var columns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'RISMAbusiness';
  var sheetName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'RISMAbusiness';
  var addDateToFilename = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  filename = getFilename(filename, addDateToFilename) + '.xlsx';
  import('xlsx').then(function (XLSX) {
    var max_character_number_to_show = 75;
    var min_characher_number_to_show = 10;
    var date_characher_number_to_show = 20;
    var cols = columns || Object.values(data[0]).map(function (header) {
      return header.v;
    });
    var worksheet = XLSX.utils.json_to_sheet(data, {
      cellDates: true,
      raw: true
    });
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    /* fix headers */
    if (!cols.length) {
      delete_row(worksheet, 0, XLSX);
    } else {
      XLSX.utils.sheet_add_aoa(worksheet, [cols], {
        origin: 'A1'
      });
    }
    /* calculate column width */
    var wsColsSettings = [];
    data.forEach(function (row) {
      var colIndex = 0;
      Object.values(row).forEach(function (_ref) {
        var v = _ref.v,
          t = _ref.t;
        var rowLength;
        if (t === CellTypes.DATE) {
          rowLength = date_characher_number_to_show;
        } else {
          rowLength = (v === null || v === void 0 ? void 0 : v.length) || 0;
        }
        if (!wsColsSettings[colIndex]) {
          wsColsSettings[colIndex] = {
            wch: min_characher_number_to_show
          };
        }
        wsColsSettings[colIndex] = {
          wch: Math.min(Math.max(rowLength, wsColsSettings[colIndex].wch), max_character_number_to_show)
        };
        colIndex++;
      });
    });
    worksheet['!cols'] = wsColsSettings;
    XLSX.writeFile(workbook, filename, {
      compression: true,
      cellStyles: true,
      cellDates: true
    });
  });
}
export function exportToCSV(dataset, columns, filename) {
  var resultDataset = [Object.values(columns)];
  dataset.forEach(function (item) {
    var dataItem = Object.keys(columns).map(function (columnKey) {
      return item[columnKey] || '';
    });
    resultDataset.push(dataItem);
  });
  json2CSV(resultDataset, filename);
}
export function exportToXlsx(dataset, columns, filename, columnsTypes) {
  var resultDataset = prepareDataset(dataset, columnsTypes);
  var resultColumns = Object.values(columns);
  json2Excel(resultDataset, resultColumns, filename);
}
/**
 * Generate the data out of html
 * @param html The html nodes of the table
 * @param filename Filename that the file will be saved as
 * @param sheetName - The name of the sheet in the workbook
 * @param fileType - default XLSX - The filetype you would like to export to
 */
function export_table_to_excel(html, filename, sheetName) {
  var fileType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'xlsx';
  var filenameAndType = "".concat(filename, ".").concat(fileType);
  return import('xlsx').then(function (XLSX) {
    var wb = XLSX.utils.table_to_book(html, {
      sheet: sheetName,
      raw: true
    });
    var ws = XLSX.write(wb, {
      bookType: fileType,
      type: 'binary'
    });
    var blob = new Blob([sheet_to_array_buffer(ws)], {
      type: 'application/octet-stream'
    });
    return handleSave(filenameAndType, blob);
  });
}
function json2CSV(data) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'RISMAbusiness';
  var fileType = 'csv';
  var filenameAndType = "".concat(filename, ".").concat(fileType);
  return import('xlsx').then(function (XLSX) {
    var ws = XLSX.utils.aoa_to_sheet(data);
    var csv = XLSX.utils.sheet_to_csv(ws);
    var blob = new Blob([sheet_to_array_buffer(csv)], {
      type: 'application/octet-stream'
    });
    return handleSave(filenameAndType, blob);
  });
}
function getFilename(filename, addDate) {
  var Filename = addDate === true ? "RISMAbusiness ".concat(filename, " - ").concat(getIsoDate()) : filename;
  return Filename.replace(/\s/g, '_');
}
function detectDateFormatForElsx(date) {
  var possibleFormats = [DateFormats.DATE_TIME_FORMAT, DateFormats.DATE_TIME_FORMAT_FULL, DateFormats.DATE_TIME_Z_FORMAT];
  var dateFormat = null;
  possibleFormats.forEach(function (format) {
    if (isDateValid(date, format, true)) {
      dateFormat = format;
    }
  });
  return dateFormat;
}
function prepareDataset(data) {
  var colTypes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = [];
  var max_cell_length = 32766;
  var columnsTypes = _objectSpread({}, colTypes);
  data.forEach(function (row) {
    var preparedRow = {};
    Object.entries(row).map(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        value = _ref3[1];
      value = value == undefined ? '' : value.toString();
      value = value ? value.slice(0, max_cell_length) + '' : '';
      preparedRow[key] = {
        v: decodeHtmlForExcel(value)
      };
      if (!columnsTypes[key]) {
        if (value.indexOf(', ') !== -1) {
          columnsTypes[key] = CellTypes.TEXT;
        } else if (!value.match(/^\d+$/gm) && value.match(/^[0-9]+([\s:-][0-9]+)*$/gm)) {
          columnsTypes[key] = CellTypes.DATE;
        }
      }
      if (columnsTypes[key]) {
        if (columnsTypes[key] === CellTypes.DATE) {
          var dateFormat = detectDateFormatForElsx(preparedRow[key].v);
          switch (dateFormat) {
            case DateFormats.DATE_TIME_FORMAT:
            case DateFormats.DATE_TIME_FORMAT_FULL:
            case DateFormats.DATE_TIME_Z_FORMAT:
              preparedRow[key].z = SSF[22];
              break;
            default:
              preparedRow[key].z = SSF[14];
          }
        } else {
          preparedRow[key].t = columnsTypes[key];
        }
      }
    });
    result.push(preparedRow);
  });
  return result;
}
export function decodeHtmlForExcel() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (!value) return '';
  return stripHtmlString(value);
}

// decode html special characters to user view (&oslash - ø, &aring - å, etc.)
export function htmlDecode() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (!value) return '';
  value = value.replaceAll('&amp;', '&');
  return $('<textarea/>').html(value).text();
}
function delete_row(ws, row_index, XLSX) {
  var range = XLSX.utils.decode_range(ws['!ref']);
  var encodeCell = function encodeCell(r, c) {
    return XLSX.utils.encode_cell({
      r: r,
      c: c
    });
  };
  for (var R = row_index; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      ws[encodeCell(R, C)] = ws[encodeCell(R + 1, C)];
    }
  }
  range.e.r--;
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e);
}