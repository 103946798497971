function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import { getSecurityGroups as _getSecurityGroups, updateSecurityGroup as _updateSecurityGroup, deleteSecurityGroup } from '@/api/compliance/admin';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TabList from '@/components/Atoms/TabList';
import StaticTable from '@/components/Molecules/StaticTable';
import AdSettings from '@/components/Organisms/Compliance/Admin/AdSettings';
import SecurityGroupsCreationModal from '@/components/Organisms/Compliance/Admin/SecurityGroups/SecurityGroupsCreationModal';
import UserSynchronisation from '@/components/Organisms/Compliance/Admin/UserSynchronisation';
import { columns as _columns, accessLevelMapping } from '@/constants/compliance/SecurityGroups';
import { typesAccessProjects } from '@/constants/compliance/TypesAccessProjects';
export default {
  name: 'SecurityGroups',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    SecurityGroupsCreationModal: SecurityGroupsCreationModal,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    AdSettings: AdSettings,
    FeatherIcon: FeatherIcon,
    TabList: TabList,
    UserSynchronisation: UserSynchronisation,
    StaticTable: StaticTable
  },
  data: function data() {
    return {
      cellClasses: 'w-7/100 mr-1',
      isLoading: true,
      groups: [],
      modalData: {},
      currentTab: 'access',
      initialClientSecret: null,
      pageIsLoaded: false,
      showCreationModal: false,
      translate: getTranslate['SecurityGroups'](),
      typesAccessProjects: typesAccessProjects
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isUserSynchronisationEnabled: function isUserSynchronisationEnabled() {
      return this.getSettingValue('feature.user_sync.enabled');
    },
    columns: function columns() {
      return _toConsumableArray(_columns());
    },
    tabs: function tabs() {
      return [{
        name: 'Access',
        slotName: 'access'
      }, {
        name: this.translate.userSynchronisation,
        slotName: 'microsoftGraph'
      }];
    },
    credentialsValid: function credentialsValid() {
      return !!this.getSettingValue('app.oidc.tenant_id') && !!this.getSettingValue('app.oidc.client_id') && !!this.initialClientSecret;
    },
    dataset: function dataset() {
      var _this = this;
      return this.groups.map(function (group, index) {
        return {
          objectId: group.objectId,
          name: group.name,
          levelAdmin: group.levelAdmin ? _this.translate.yes : _this.translate.no,
          levelControls: accessLevelMapping[group.levelControls],
          levelExecution: accessLevelMapping[group.levelExecution],
          levelIncidents: accessLevelMapping[group.levelIncidents],
          levelRisk: accessLevelMapping[group.levelRisk],
          levelContracts: accessLevelMapping[group.levelContracts],
          levelCompany: accessLevelMapping[group.levelCompany],
          levelInformationAssets: accessLevelMapping[group.levelInformationAssets],
          levelProcessLibrary: accessLevelMapping[group.levelProcessLibrary],
          lockedFields: group.lockedFields,
          access: {
            projects: {
              compliance: {
                title: 'Compliance',
                projects: group.complianceProjects.map(function (project) {
                  return {
                    projectName: project.complianceProjectsTitle,
                    access: accessLevelMapping[project.accessLevel]
                  };
                })
              },
              risk: {
                title: 'Risk',
                projects: group.riskProjects.map(function (project) {
                  return {
                    projectName: project.riskProjectsTitle,
                    access: accessLevelMapping[project.accessLevel]
                  };
                })
              }
            },
            isEmpty: !(group.complianceProjects.length || group.riskProjects.length)
          },
          edit: {
            index: index
          },
          delete: {
            id: group.id
          }
        };
      });
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    this.getSecurityGroups().finally(function () {
      _this2.pageIsLoaded = true;
    });
  },
  methods: {
    getSecurityGroups: function getSecurityGroups(skipSpinner) {
      var _this3 = this;
      if (!skipSpinner) {
        this.isLoading = true;
      }
      return _getSecurityGroups().then(function (_ref) {
        var groups = _ref.groups,
          _ref$settings = _ref.settings,
          settings = _ref$settings === void 0 ? {} : _ref$settings;
        _this3.groups = _this3.formatSecurityGroups(groups);
        _this3.initialClientSecret = settings['app.oidc.client_secret'] || '';
      }).finally(function () {
        return _this3.isLoading = false;
      });
    },
    updateSecurityGroup: function updateSecurityGroup(group) {
      return _updateSecurityGroup(group.id, group);
    },
    getNumber: function getNumber(value) {
      return value ? value : 0;
    },
    formatSecurityGroup: function formatSecurityGroup(group) {
      var groupFormatted = {
        id: group.id,
        name: group.name,
        objectId: group.objectId,
        levelAdmin: this.getNumber(group.levelAdmin),
        levelControls: this.getNumber(group.levelControls),
        levelExecution: this.getNumber(group.levelExecution),
        levelIncidents: this.getNumber(group.levelIncidents),
        levelRisk: this.getNumber(group.levelRisk),
        levelContracts: this.getNumber(group.levelContracts),
        levelCompany: this.getNumber(group.levelCompany),
        levelInformationAssets: this.getNumber(group.levelInformationAssets),
        levelProcessLibrary: this.getNumber(group.levelProcessLibrary),
        lockedFields: !!group.lockedFields,
        complianceProjects: [],
        riskProjects: [],
        updated: false,
        isProjectsShown: false
      };
      group.projects.forEach(function (project) {
        if (project.complianceProjectsId) {
          groupFormatted.complianceProjects.push(Object.assign({}, project));
        } else if (project.riskProjectsId) {
          groupFormatted.riskProjects.push(Object.assign({}, project));
        }
      });
      return groupFormatted;
    },
    formatSecurityGroups: function formatSecurityGroups(groups) {
      var _this4 = this;
      return groups.map(function (group) {
        return _this4.formatSecurityGroup(group);
      });
    },
    deleteGroup: function deleteGroup(id) {
      var _this5 = this;
      this.$confirm('', this.translate.areYouSureYouWantToDeleteThisSecurityGroup, function (res) {
        return res && _this5.onDeleteConfirm(id);
      });
    },
    onDeleteConfirm: function onDeleteConfirm(id) {
      var _this6 = this;
      return deleteSecurityGroup(id).then(function () {
        _this6.groups = _this6.groups.filter(function (item) {
          return item.id !== id;
        });
        _this6.$notify({
          title: _this6.translate.securityGroupHasBeenDeleted
        });
      }).catch(function (error) {
        return _this6.$notify({
          title: _this6.$trans(error.response.error),
          type: 'error'
        });
      });
    },
    handleGroupCreation: function handleGroupCreation() {
      this.showCreationModal = false;
      this.getSecurityGroups();
    },
    handleGroupUpdate: function handleGroupUpdate(group) {
      var indexGroup = this.groups.findIndex(function (item) {
        return +item.id === +group.id;
      });
      this.groups[indexGroup] = this.formatSecurityGroup(group);
      this.showCreationModal = false;
      this.selectedGroup = null;
    },
    openUpdateModal: function openUpdateModal(index) {
      this.selectedGroup = this.groups[index];
      this.showCreationModal = true;
    },
    handleDismissCreation: function handleDismissCreation() {
      this.showCreationModal = false;
      this.selectedGroup = null;
    }
  }
};