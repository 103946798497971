import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "select-wrapper"
};
var _hoisted_2 = {
  class: "select-item"
};
var _hoisted_3 = {
  class: "select-item"
};
var _hoisted_4 = {
  class: "add-button-wrap"
};
var _hoisted_5 = {
  class: "parsed-rules"
};
var _hoisted_6 = {
  class: "parsed-rule-content"
};
var _hoisted_7 = {
  class: "button-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_project_questionnaire_gap_selector = _resolveComponent("project-questionnaire-gap-selector");
  var _component_text_box = _resolveComponent("text-box");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.relevantQuestions,
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_project_questionnaire_gap_selector, {
    "questionnaire-type-id": _ctx.selectedQuestionnaireTypeId,
    "gap-schema-id": _ctx.gapSchemaId,
    "questionnaire-id": _ctx.questionnaireId,
    "questionnaire-types": _ctx.allQuestionnaireTypes,
    questionnaires: _ctx.allQuestionnaires,
    "gap-schemas": _ctx.allGapSchemas,
    "show-text": _ctx.showText,
    "onUpdate:questionnaireTypeId": _cache[0] || (_cache[0] = function (questionnaireTypeId) {
      return _ctx.selectedQuestionnaireTypeId = questionnaireTypeId;
    }),
    "onUpdate:questionnaireId": _cache[1] || (_cache[1] = function (newQuestionnaireId) {
      return _ctx.questionnaireId = newQuestionnaireId;
    }),
    "onUpdate:gapSchemaId": _cache[2] || (_cache[2] = function (newGapSchemaId) {
      return _ctx.gapSchemaId = newGapSchemaId;
    }),
    "onUpdate:showText": _cache[3] || (_cache[3] = function (newShowText) {
      return _ctx.showText = newShowText;
    })
  }, null, 8 /* PROPS */, ["questionnaire-type-id", "gap-schema-id", "questionnaire-id", "questionnaire-types", "questionnaires", "gap-schemas", "show-text"]), _ctx.showText ? (_openBlock(), _createBlock(_component_text_box, {
    key: 0,
    rows: 15,
    text: $data.relevantQuestionText,
    class: "w-full",
    onUpdated: $options.validate
  }, null, 8 /* PROPS */, ["text", "onUpdated"])) : _createCommentVNode("v-if", true), !_ctx.showRulesTextMode ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.allGapArticles ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    modelValue: _ctx.articleId,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return _ctx.articleId = $event;
    }),
    title: $data.translate.chooseGapArticle,
    options: _ctx.allGapArticles,
    "un-sorted": true,
    placeholder: "Choose gap article"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: _ctx.questionId,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.questionId = $event;
    }),
    title: $data.translate.chooseQuestion,
    options: _ctx.questions.map(function (question) {
      return {
        value: question.id,
        label: question.questionnumber
      };
    }),
    "un-sorted": true,
    placeholder: "Choose question"
  }, null, 8 /* PROPS */, ["modelValue", "title", "options"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_button, {
    text: $data.translate.add,
    type: "save",
    onClick: $options.addQuestion
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        color: "#FFF"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.relevantQuestionsGrouped, function (questionList, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "parsed-rule"
    }, [_createElementVNode("span", _hoisted_6, _toDisplayString(key) + ": " + _toDisplayString($options.questionListToComma(questionList)), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_button, {
    text: $data.translate.save,
    disabled: !$options.buttonActive,
    type: "save",
    onClick: $options.saveQuestions
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "check",
        color: "#FFF"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "onClick"])])]);
}