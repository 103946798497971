import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "px-4 py-2 m-2"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_compact_title_data_component = _resolveComponent("compact-title-data-component");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_static_table = _resolveComponent("static-table");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.activities.length ? (_openBlock(), _createBlock(_component_load_more_wrapper, {
    key: 0,
    activities: $options.dataset
  }, {
    default: _withCtx(function (_ref) {
      var activitiesLimited = _ref.activitiesLimited;
      return [_createVNode(_component_static_table, {
        "visibility-switch": $props.visibilitySwitch,
        "visibility-storage-name": $options.getVisibilityStorageName(),
        "dataset-table": activitiesLimited,
        columns: $options.columns,
        "prevent-full-page-updates": true,
        "visibility-hidden-options": ['Title', 'Action'],
        "visibility-default-columns": $options.visibilityDefaultColumns,
        "watermark-image": $options.getWatermarkImageUrl(),
        "is-headers-truncated": $data.isHeadersTruncated,
        class: "compact-card-table",
        onUpdateFromPreviewMode: _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('updateFromPreviewMode', $event);
        }),
        onOnDismissModal: _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$emit('onDismissModal');
        }),
        onDeleteItem: _cache[6] || (_cache[6] = function ($event) {
          return _ctx.$emit('deleteItem', $event);
        })
      }, {
        "_custom-title": _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [$props.module === $data.MODULES.INCIDENTS || $props.module === $data.MODULES.RISK ? (_openBlock(), _createBlock(_component_preview_modal_link, {
            key: 0,
            activity: slotData.data,
            onUpdated: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.$emit('updateFromPreviewMode', $event);
            }),
            onDismissModal: _cache[1] || (_cache[1] = function ($event) {
              return _ctx.$emit('onDismissModal');
            }),
            onDeleteItem: function onDeleteItem($event) {
              return _ctx.$emit('deleteItem', slotData.data);
            }
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                title: slotData.data.tableDisplayTitle,
                "title-class": slotData.data.closed ? 'line-through' : '',
                "title-attribute": slotData.data.hoverTitle,
                "display-icon": slotData.data.confidential
              }, null, 8 /* PROPS */, ["title", "title-class", "title-attribute", "display-icon"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDeleteItem"])) : $props.module === $data.MODULES.EXECUTION || $props.module === $data.MODULES.CONTROLS ? (_openBlock(), _createBlock(_component_compact_title_data_component, _mergeProps({
            key: 1,
            "slot-data": slotData
          }, _ctx.$attrs, {
            onUpdated: _cache[2] || (_cache[2] = function ($event) {
              return _ctx.$emit('updateFromPreviewMode', $event);
            }),
            onDismissModal: _cache[3] || (_cache[3] = function ($event) {
              return _ctx.$emit('onDismissModal');
            }),
            onDeleteItem: function onDeleteItem($event) {
              return _ctx.$emit('deleteItem', slotData);
            }
          }), null, 16 /* FULL_PROPS */, ["slot-data", "onDeleteItem"])) : (_openBlock(), _createBlock(_component_router_link, {
            key: 2,
            to: slotData.url
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(slotData.path) + ". " + _toDisplayString(_ctx.activity.title), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))];
        }),
        "_custom-button": _withCtx(function (_ref3) {
          var slotData = _ref3.slotData;
          return [!$props.controlButtonType ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : slotData.customButton ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_button, {
            text: slotData.text,
            "data-control-id": slotData.id,
            "data-control-cpath": slotData.cpath,
            "data-action": "actionControl",
            "data-action-type": slotData.actionType,
            "data-complete-again": slotData.completeAgain,
            type: "save",
            onClick: function onClick($event) {
              return $options.onCustomButtonClick(slotData);
            }
          }, null, 8 /* PROPS */, ["text", "data-control-id", "data-control-cpath", "data-action-type", "data-complete-again", "onClick"])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_button, {
            text: slotData.text,
            type: "util",
            disabled: "disabled"
          }, null, 8 /* PROPS */, ["text"])]))];
        }),
        severity: _withCtx(function (_ref4) {
          var slotData = _ref4.slotData;
          return [slotData ? (_openBlock(), _createBlock(_component_traffic_light, {
            key: 0,
            color: slotData.color
          }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["visibility-switch", "visibility-storage-name", "dataset-table", "columns", "visibility-default-columns", "watermark-image", "is-headers-truncated"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activities"])) : _createCommentVNode("v-if", true)]);
}