// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kitchensink-wrap[data-v-34fd0042] {
  position: relative;
}
.kitchen-fast-navigation[data-v-34fd0042] {
  position: absolute;
  margin: -28px 0 0 0;
  right: 20px;
  font-size: 18px;
}
.kitchen-fast-navigation > a[data-v-34fd0042] {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.kitchen-fast-navigation > a[data-v-34fd0042]:first-child {
  margin-right: 8px;
}
.router-links[data-v-34fd0042] {
  padding: 0;
  list-style: none;
  margin: 0 0 1rem;
  display: flex;
  flex-wrap: wrap;
}
.router-links[data-v-34fd0042]  li {
  font-size: 15px;
  margin: 4px 5px 4px 0;
  width: 200px;
  display: inline-block;
}
.router-links[data-v-34fd0042]  li a {
  text-decoration: none !important;
  border-bottom: 1px dashed transparent;
}
.router-links[data-v-34fd0042]  li a.router-link-active {
  border-color: #347480;
  font-weight: 700;
}
section[data-v-34fd0042] {
  background-color: #f8f8f8;
  padding: 0.5rem;
  border: 1px solid #c5c5c5;
}
section[data-v-34fd0042]:not(:first-child) {
  margin-top: 0.5rem;
}
[data-v-34fd0042]  .kitchen-component-footer .required {
  border-bottom: 0.1rem solid #B03D00;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
