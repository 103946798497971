import { i18n } from '@/i18n';
export var passwordErrorMessage = function passwordErrorMessage(isEnterpriseSecurity) {
  var passwordMaxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 14;
  if (isEnterpriseSecurity) {
    var text = i18n.t('Password must be at least 14 characters') + '. ' + i18n.t('Password must contain at least 1 upper case letter, 1 lower case letter, 1 number and 1 special character') + ' (#?!@$%^&*-)';
    if (passwordMaxLength !== 14) return text.replace('14', passwordMaxLength);
    return text;
  }
  return i18n.t('Password must be at least 14 characters').replace('14', passwordMaxLength);
};
export var getPasswordLength = function getPasswordLength(hasNewFlag, length, isEnterpriseSecurity) {
  if (hasNewFlag) return length || 44;
  return isEnterpriseSecurity ? 14 : 8;
};