// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldset[data-v-da8020cc] {
  border: 1px solid #BEBEBE;
  padding: 0.35em 1.5em 1rem 1.5rem;
  margin: 0 0 1.5rem;
}
.fieldset .fieldset_legend[data-v-da8020cc] {
  width: auto;
  font-size: 19px;
  padding: 0 4px;
  margin: 0 0 0.5rem;
  color: #4D4D4D;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
