import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "p-4 border border-gray-350 w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_admin_bulk_tab_items = _resolveComponent("admin-bulk-tab-items");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_admin_bulk_tab_items), _createElementVNode("div", _hoisted_2, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0,
    class: "my-12"
  })) : (_openBlock(), _createBlock(_component_router_view, {
    key: 1,
    "compliance-projects": $data.complianceProjects,
    "risk-projects": $data.riskProjects
  }, null, 8 /* PROPS */, ["compliance-projects", "risk-projects"]))])]);
}