import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex mb-2 items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_save_report = _resolveComponent("save-report");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.dataReport,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_save_report, {
    type: $data.reportType,
    "state-local": $data.stateLocal,
    "report-options": _ctx.dateRangeReportOptions,
    onLoad: _ctx.onLoadReport
  }, null, 8 /* PROPS */, ["type", "state-local", "report-options", "onLoad"]), _ctx.initRange.start && _ctx.initRange.end ? (_openBlock(), _createBlock(_component_risma_date_range_picker, {
    key: 0,
    ref: "rismaDateRangePicker",
    "start-date": _ctx.initRange.start,
    "end-date": _ctx.initRange.end,
    "local-storage-key": $data.localStorageRangeKey,
    tooltip: $data.translate.deadlineInterval,
    ranges: $data.ranges,
    class: "ml-auto",
    onChanged: _ctx.changedDates
  }, null, 8 /* PROPS */, ["start-date", "end-date", "local-storage-key", "tooltip", "ranges", "onChanged"])) : _createCommentVNode("v-if", true)]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    ref: "reportsDataTable",
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": _ctx.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": _ctx.pageLength,
    "wrapper-height-sticky": 550,
    "filter-by-column": true,
    "state-local": $data.stateLocal,
    "column-filters-sorting": _ctx.columnFiltersSorting,
    "column-sorting": _ctx.columnSorting,
    "columns-by-default": $options.columns,
    "disabled-filter-by-columns": $options.disabledFilterByColumns,
    "columns-meta": $data.columnsMeta,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "columns-order": $options.columnsOrder,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_preview_modal_link, {
        class: _normalizeClass([{
          'line-through': !item.isActive
        }, "hover:underline"]),
        activity: item.activity,
        onDismissModal: _ctx.previewModalDismissed
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.fieldText), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "activity", "onDismissModal"])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($options.trafficLightKeys, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [item.field.color ? (_openBlock(), _createBlock(_component_traffic_light, {
          key: linkCol,
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "page-length", "state-local", "column-filters-sorting", "column-sorting", "columns-by-default", "disabled-filter-by-columns", "columns-meta", "decouple-filter-fields", "columns-order", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)]);
}