function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import i18n from '@/i18n';
export var filterKeysMap = {
  threat: 'threats',
  levelOfThreats: 'levelOfThreat',
  tags: 'tag',
  linkedSystems: 'systems',
  managementLevelIds: 'managementLevel',
  managementLevels: 'managementLevel',
  riskTypes: 'riskType',
  approval: 'approvalStatus',
  organisationGroups: 'organisationGroup',
  organisationsList: 'organisationIds',
  topRisks: 'topRPI',
  parentRisks: 'parentRiskId',
  activityTypes: 'activityTypeIds'
};
export var filtersIdentifierMap = {
  parentRisks: 'rno'
};
export var wrongCustomFields = {
  'Z-axis': 'zAxis',
  Impact: 'impact'
};
export var filterOptionsErm = function filterOptionsErm() {
  return {
    status: [{
      label: i18n.t('All'),
      value: 'all',
      id: 'all'
    }, {
      label: i18n.t('Open'),
      value: '0',
      id: '0'
    }, {
      label: i18n.t('Closed'),
      value: '1',
      id: '1'
    }],
    approval: [{
      label: i18n.t('Not approved'),
      value: '0',
      id: '0'
    }, {
      label: i18n.t('Approved'),
      value: '1',
      id: '1'
    }],
    updated: [{
      label: i18n.t('All'),
      value: 'all',
      id: 'all'
    }, {
      label: i18n.t('Not updated'),
      value: '0',
      id: '0'
    }, {
      label: i18n.t('Updated'),
      value: '1',
      id: '1'
    }],
    topRisks: [{
      label: i18n.t('All'),
      value: 'all',
      id: 'all'
    }, {
      label: i18n.t('Top 10 RPI'),
      value: '10',
      id: '10'
    }, {
      label: i18n.t('Top 15 RPI'),
      value: '15',
      id: '15'
    }]
  };
};
export var getOptionsFromScoring = function getOptionsFromScoring(scoring, label) {
  var result = [{
    label: scoring["".concat(label, "_label")],
    value: "".concat(label, "_label")
  }];
  var num = 1;
  while (scoring["".concat(label, "Other").concat(num, "Label")]) {
    result.push({
      label: scoring["".concat(label, "Other").concat(num, "Label")],
      value: "".concat(label, "Other").concat(num, "Label")
    });
    num++;
  }
  return result;
};
export var prepareReportOptions = function prepareReportOptions(filterObjValues, riskStateMatrix) {
  var result = {};
  Object.entries(filterObjValues).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (Array.isArray(value)) {
      result[key] = value.map(function (item) {
        return item.id || item;
      });
    } else {
      result[key] = value;
    }
  });
  return _objectSpread(_objectSpread({}, result), {}, {
    matrix: riskStateMatrix
  });
};
export var getFiltersFromReportOptions = function getFiltersFromReportOptions(storageValues, optionsObj) {
  var period = storageValues && optionsObj.periods.find(function (period) {
    return period.id === storageValues.periodId;
  });
  if (!storageValues || !storageValues.periodId || !period) return {};
  var filters = {};
  var matrix = storageValues.matrix;
  Object.entries(storageValues).forEach(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    if (Array.isArray(value)) {
      var isUsers = ['dailyOwner', 'primaryOwner', 'responsible'].indexOf(key) !== -1;
      var optionsList = isUsers ? optionsObj.users : optionsObj[key] || [];
      filters[key] = optionsList.filter(function (item) {
        return value.indexOf(item.id) !== -1;
      });
    } else {
      filters[key] = value;
    }
  });
  return {
    filters: filters,
    matrix: matrix
  };
};