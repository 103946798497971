// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification[data-v-0ca25e08] {
  padding: 5px 15px;
  opacity: 1;
  transition: all 0.5s;
}
.notification.fadeOut[data-v-0ca25e08] {
  opacity: 0;
}
.notification.dismiss[data-v-0ca25e08] {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
