function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex relative"
};
var _hoisted_2 = {
  class: "w-2/5 lg:pr-10 xxl:pr-20"
};
var _hoisted_3 = {
  class: "sticky top-130px min-w-0 h-70vh rounded border-solid border border-gray-350 overflow-auto"
};
var _hoisted_4 = {
  class: "ml-3 px-4 text-2xl text-blue-750 font-semibold truncate w-full"
};
var _hoisted_5 = {
  class: "flex flex-col relative w-3/5 pl-0 pr-6 pt-2"
};
var _hoisted_6 = {
  class: "flex justify-end items-center"
};
var _hoisted_7 = {
  class: "mr-2 hover:underline"
};
var _hoisted_8 = {
  class: "text-blue-750"
};
var _hoisted_9 = {
  key: 1,
  class: "navigate-arrows flex justify-between mt-6 relative"
};
var _hoisted_10 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_questionnaire_navigation = _resolveComponent("questionnaire-navigation");
  var _component_notification = _resolveComponent("notification");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_excel = _resolveComponent("risma-excel");
  var _component_question = _resolveComponent("question");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("fieldset", _hoisted_3, [_createElementVNode("legend", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */), _createVNode(_component_questionnaire_navigation, {
    questions: $options.expandedVisibleQuestions,
    "visibility-storage-key": "questionnaire_navigation_hidden_".concat($props.questionnaire && $props.questionnaire.questionnaireType || $props.questionnaire.questionnaireId || 0, "_").concat($props.questionnaire && $props.questionnaire.id || 0),
    processors: $options.informationAssetsByType[$data.AssetsTypes.PROCESSORS] || [],
    onChangeQuestion: $options.changeQuestion
  }, null, 8 /* PROPS */, ["questions", "visibility-storage-key", "processors", "onChangeQuestion"])])]), _createElementVNode("div", _hoisted_5, [$data.errors.length !== 0 ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, function (error, key) {
        return _openBlock(), _createElementBlock("p", {
          key: key,
          class: "m-2"
        }, _toDisplayString(error), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [$options.showFullQuestionsList && $options.showNotApplicableToggle ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "flex items-center cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showNonApplicable = !$data.showNonApplicable;
    })
  }, [_createVNode(_component_feather_icon, {
    class: "mr-1 stroke-2",
    icon: $data.showNonApplicable ? 'eye' : 'eye-off',
    height: "18",
    width: "18"
  }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("p", _hoisted_7, _toDisplayString($data.showNonApplicable ? $data.translate.hideNonApplicable : $data.translate.showNonApplicable), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.showExportAsCsvButton ? (_openBlock(), _createBlock(_component_risma_excel, {
    key: 1,
    columns: $options.csvColumns,
    "dataset-export": $options.csvDatasetToExport,
    "export-type": $data.exportTypes.CSV,
    onExport: _cache[1] || (_cache[1] = function ($event) {
      return $options.emitExport($data.ReportLogTypes.EXCEL, $event);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_button, {
        text: "",
        class: "flex items-center"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            icon: "download",
            class: "mr-2 text-blue-750",
            height: "25",
            width: "25"
          }), _createElementVNode("span", _hoisted_8, _toDisplayString($data.translate.exportAsCSV), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset-export", "export-type"])) : _createCommentVNode("v-if", true)]), !$options.showFullQuestionsList ? (_openBlock(), _createElementBlock("div", _hoisted_9, [$data.questionIndex > $options.indexOfFirstAnsweredDependenciesQuestion ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "navigate-arrow z-10 js-navigate-arrow flex cursor-pointer absolute -left-12 rounded-full focus:outline-none",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.setQuestionIndex(-1);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    class: "stroke-2",
    height: "40",
    width: "40"
  })])) : _createCommentVNode("v-if", true), $data.questionIndex < $options.indexOfLastAnsweredDependenciesQuestion ? (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: "navigate-arrow z-10 js-navigate-arrow flex cursor-pointer absolute right-0 rounded-full focus:outline-none",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.setQuestionIndex(1);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-right",
    class: "stroke-2",
    width: "40",
    height: "40"
  })])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questionsList, function (question) {
    return _openBlock(), _createElementBlock("div", {
      id: question.id,
      key: question.id,
      class: "relative mb-20"
    }, [_createVNode(_component_question, {
      question: question,
      "node-element": $props.nodeElement,
      "information-assets": $options.informationAssetsByType,
      "my-information-assets-by-type": $props.myInformationAssetsByType,
      "activity-types": $props.activityTypes,
      "show-headline": question.showHeadline,
      "read-only": $props.readOnly,
      onUpdate: $options.update,
      onNewAsset: function onNewAsset($event) {
        return _ctx.$emit('newAsset', _objectSpread(_objectSpread({}, $event), {}, {
          questionId: question.id
        }));
      },
      onForceUpdate: _cache[4] || (_cache[4] = function ($event) {
        return _ctx.$emit('forceUpdate');
      })
    }, null, 8 /* PROPS */, ["question", "node-element", "information-assets", "my-information-assets-by-type", "activity-types", "show-headline", "read-only", "onUpdate", "onNewAsset"])], 8 /* PROPS */, _hoisted_10);
  }), 128 /* KEYED_FRAGMENT */))])]);
}