// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-alert-wrap[data-v-29b428d0]  .modal-header {
  padding: 5px 0 0 0;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-header .risma-title > h1 {
  color: #737373 !important;
  padding-bottom: 2px;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-body {
  margin: 0 0 10px;
  padding: 0;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-body p {
  color: #737373;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-footer {
  padding: 0;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-wrapper {
  vertical-align: top;
  padding-top: 138px;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-container {
  width: 23vw; /* From Design Specification */
  min-width: 600px;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-container .risma-button-btn.util {
  opacity: 0.5;
  transition: opacity 0.3s;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-container .risma-button-btn.util:hover {
  opacity: 1;
}
.modal-alert-wrap[data-v-29b428d0]  .modal-container .risma-button.normal .risma-button-btn {
  min-width: 120px;
  font-size: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
