import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAllWelcomePages() {
  return handleRequestResponse(Http.get('/admin/welcomepages'));
}
export function createWelcomePage(contentHtml, name) {
  var active = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var rProjectsId = arguments.length > 3 ? arguments[3] : undefined;
  return handleRequestResponse(Http.post('/admin/welcomepages', {
    contentHtml: contentHtml,
    name: name,
    rProjectsId: rProjectsId,
    active: active
  }));
}
export function updateWelcomePage(id, contentHtml, name) {
  var active = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var rProjectsId = arguments.length > 4 ? arguments[4] : undefined;
  return handleRequestResponse(Http.patch("/admin/welcomepages/".concat(id), {
    contentHtml: contentHtml,
    name: name,
    rProjectsId: rProjectsId,
    active: active
  }));
}