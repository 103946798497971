function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import { getAllQuestionsForProject } from '@/api/compliance/questionnaire';
import { getTreeByProjectId } from '@/api/compliance/tree';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { getSingle } from '@/api/compliance/projects';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import QuestionSelection from './QuestionSelection';
import Node from '@/models/compliance/Node';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { Colors } from '@/Colors';
import { removeDuplicates } from '@/utils/filterArray';
export default {
  name: 'QuestionnaireSelection',
  components: {
    QuestionSelection: QuestionSelection,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    RismaSelect: RismaSelect,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaButton: RismaButton,
    FeatherIcon: FeatherIcon
  },
  props: {
    questionnaires: {
      type: Array,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    },
    initialFilters: {
      type: Object,
      default: null
    }
  },
  emits: ['hideReport', 'createReport'],
  data: function data() {
    return {
      selectedQuestionnaireId: null,
      selectedQuestionnaires: [],
      questions: [],
      selectedQuestions: [],
      loading: false,
      isCollapsed: false,
      nodes: null,
      nodesLoaded: false,
      projectType: null,
      selectedNodes: [],
      selectedOrganisations: [],
      localStorageKey: "questionnaire_report_selection_".concat(this.projectId),
      Colors: Colors,
      showControllersAndProcessors: true,
      includeDataFlow: false,
      includeRaciEmails: false,
      includeHelpText: false,
      isAnsweredOnly: false,
      translate: getTranslate['QuestionnaireSelection']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, ['isInformationAssetsDisabled'])), mapState(useOrganisationsStore, ['organisations'])), {}, {
    organisationsOptions: function organisationsOptions() {
      return this.organisations.map(function (item) {
        return {
          id: item.id,
          label: item.name
        };
      });
    },
    questionnairesOptions: function questionnairesOptions() {
      var questionnaires = this.questionnaires.filter(function (q) {
        return q.selectable === 1;
      });
      return questionnaires.map(function (q) {
        return {
          value: q.id,
          label: q.title
        };
      });
    },
    availableOrganisations: function availableOrganisations() {
      var _this = this;
      var result = {};
      this.formattedNodes.forEach(function (node) {
        var _node$organisationIds;
        if (!(node !== null && node !== void 0 && (_node$organisationIds = node.organisationIds) !== null && _node$organisationIds !== void 0 && _node$organisationIds.length)) return;
        node.organisationIds.forEach(function (organisationId) {
          if (result[organisationId]) return;
          result[organisationId] = _this.organisationsOptions.find(function (org) {
            return org.id === organisationId;
          });
        });
      });
      return Object.values(result);
    },
    availableNodesOptions: function availableNodesOptions() {
      var _this$selectedOrganis;
      var result = this.formattedNodes;
      if ((_this$selectedOrganis = this.selectedOrganisations) !== null && _this$selectedOrganis !== void 0 && _this$selectedOrganis.length) {
        var selectedIds = this.selectedOrganisations.map(function (org) {
          return org.id;
        });
        result = result.filter(function (node) {
          return node.organisationIds.some(function (id) {
            return selectedIds.includes(id);
          });
        });
      }
      return result;
    },
    isGDPR: function isGDPR() {
      return this.projectType === ProjectTypes.GDPR;
    },
    formattedNodes: function formattedNodes() {
      if (this.selectedQuestionnaires.length === 0 || !this.nodes) {
        return [];
      }
      return this.formatNodes(this.nodes, this.selectedQuestionnaires);
    },
    selectNodesTitle: function selectNodesTitle() {
      return this.translate.selectNodes + ' (' + this.translate.noteThatUnderlyingNodesAreAlsoIncludedInTh + ')';
    }
  }),
  watch: {
    questionnaires: {
      deep: true,
      handler: function handler() {
        if (this.questionnaires.length === 1) {
          this.selectedQuestionnaireId = this.questionnaires[0].id;
        }
      }
    },
    availableNodesOptions: function availableNodesOptions(newOptions) {
      var newNodesIds = newOptions.map(function (node) {
        return node.id;
      });
      this.selectedNodes = this.selectedNodes.filter(function (node) {
        return newNodesIds.includes(node.id);
      });
    },
    availableOrganisations: function availableOrganisations(newOptions) {
      var newOrgsIds = newOptions.map(function (org) {
        return org.id;
      });
      this.selectedOrganisations = this.selectedOrganisations.filter(function (org) {
        return newOrgsIds.includes(org.id);
      });
    },
    initialFilters: function initialFilters() {
      if (this.initialFilters) {
        return this.setInitialOptions();
      }
    }
  },
  created: function created() {
    var _this2 = this;
    this.getProjectData();
    var promises = [this.getTreeData()];
    var storageData;
    if (!this.$route.query.fromNode && !this.initialFilters) {
      storageData = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (storageData) {
        var _storageData$showCont, _storageData$includeD, _storageData$includeR, _storageData$includeH, _storageData$isAnswer;
        this.selectedQuestionnaires = removeDuplicates(storageData.selectedQuestionnaires, 'value');
        this.showControllersAndProcessors = (_storageData$showCont = storageData.showControllersAndProcessors) !== null && _storageData$showCont !== void 0 ? _storageData$showCont : this.showControllersAndProcessors;
        this.includeDataFlow = (_storageData$includeD = storageData.includeDataFlow) !== null && _storageData$includeD !== void 0 ? _storageData$includeD : this.includeDataFlow;
        this.includeRaciEmails = (_storageData$includeR = storageData.includeRaciEmails) !== null && _storageData$includeR !== void 0 ? _storageData$includeR : this.includeRaciEmails;
        this.includeHelpText = (_storageData$includeH = storageData.includeHelpText) !== null && _storageData$includeH !== void 0 ? _storageData$includeH : this.includeHelpText;
        this.isAnsweredOnly = (_storageData$isAnswer = storageData.isAnsweredOnly) !== null && _storageData$isAnswer !== void 0 ? _storageData$isAnswer : this.isAnsweredOnly;
        promises.push(this.handleFetchQuestions());
      }
    }
    Promise.all(promises).then(function () {
      if (!storageData || _this2.initialFilters || _this2.$route.query.fromNode) return;
      _this2.$nextTick(function () {
        _this2.selectedQuestions = storageData.selectedQuestions;
        _this2.selectedNodes = storageData.selectedNodes;
        _this2.selectedOrganisations = storageData.selectedOrganisations || [];
        _this2.$refs.questionSelection && _this2.$refs.questionSelection.setSelectedQuestions(storageData.selectedQuestions);
      });
    });
  },
  methods: {
    setInitialOptions: function setInitialOptions() {
      var _this3 = this,
        _this$initialFilters$,
        _this$initialFilters$2,
        _this$initialFilters$3,
        _this$initialFilters$4,
        _this$initialFilters$5;
      this.selectedQuestionnaires = this.initialFilters.questionnaireIds.map(function (item) {
        return _this3.questionnairesOptions.find(function (questionnaire) {
          return questionnaire.value === item;
        });
      });
      this.showControllersAndProcessors = (_this$initialFilters$ = this.initialFilters.showControllersAndProcessors) !== null && _this$initialFilters$ !== void 0 ? _this$initialFilters$ : this.showControllersAndProcessors;
      this.includeDataFlow = (_this$initialFilters$2 = this.initialFilters.includeDataFlow) !== null && _this$initialFilters$2 !== void 0 ? _this$initialFilters$2 : this.includeDataFlow;
      this.includeRaciEmails = (_this$initialFilters$3 = this.initialFilters.includeRaciEmails) !== null && _this$initialFilters$3 !== void 0 ? _this$initialFilters$3 : this.includeRaciEmails;
      this.includeHelpText = (_this$initialFilters$4 = this.initialFilters.includeHelpText) !== null && _this$initialFilters$4 !== void 0 ? _this$initialFilters$4 : this.includeHelpText;
      this.isAnsweredOnly = (_this$initialFilters$5 = this.initialFilters.isAnsweredOnly) !== null && _this$initialFilters$5 !== void 0 ? _this$initialFilters$5 : this.isAnsweredOnly;
      this.selectedOrganisations = this.initialFilters.organisationIds.map(function (id) {
        return _this3.organisationsOptions.find(function (organisation) {
          return organisation.id === id;
        });
      });
      this.selectedNodes = this.initialFilters.nodeIds.map(function (nodeId) {
        return _this3.availableNodesOptions.find(function (node) {
          return node.id === nodeId;
        });
      }) || this.selectedNodes;
      return this.handleFetchQuestions().then(function () {
        _this3.selectedQuestions = _this3.initialFilters.questionNumbers;
        _this3.$refs.questionSelection && _this3.$refs.questionSelection.setSelectedQuestions(_this3.initialFilters.questionNumbers);
        _this3.createReport(false);
      });
    },
    questionsSelected: function questionsSelected(selectedQuestions) {
      this.selectedQuestions = selectedQuestions;
    },
    getProjectData: function getProjectData() {
      var _this4 = this;
      return getSingle(this.projectId).then(function (response) {
        _this4.projectType = response.type;
      });
    },
    getTreeData: function getTreeData() {
      var _this5 = this;
      return getTreeByProjectId(this.projectId, '?data[]=questionnaire').then(function (response) {
        if (response.children) {
          _this5.nodes = response.children;
          if (_this5.$route.query.fromNode) {
            _this5.selectQuestionnairesFromNodeId(_this5.$route.query.fromNode);
          }
        }
        if (_this5.initialFilters) {
          return _this5.setInitialOptions();
        }
      }).then(function () {
        return _this5.nodesLoaded = true;
      });
    },
    selectQuestionnairesFromNodeId: function selectQuestionnairesFromNodeId(nodeId) {
      var _this6 = this;
      var nodeJSON = this.findNode(this.nodes, nodeId);
      if (!nodeJSON) {
        return;
      }
      var node = new Node(nodeJSON);
      var questionnaireIds = node.getQuestionnaireIdsFromTree();
      if (questionnaireIds.length === 0) {
        return;
      }
      questionnaireIds.forEach(function (questionnaireId) {
        var questionnaireOption = _this6.questionnairesOptions.find(function (option) {
          return option.value == questionnaireId;
        });
        _this6.selectedQuestionnaires.push(questionnaireOption);
      });
      this.$nextTick(function () {
        _this6.selectedNodes.push(node);
        _this6.handleFetchQuestions().then(function () {
          _this6.$nextTick(function () {
            if (_this6.$refs.questionSelection) {
              _this6.$refs.questionSelection.selectDefault();
              if (_this6.$refs.questionSelection.selectedQuestions.length > 0) {
                _this6.$nextTick(function () {
                  _this6.handleCreateReport();
                });
              }
            }
          });
        });
      });
    },
    handleFetchQuestions: function handleFetchQuestions() {
      var _this7 = this;
      this.loading = true;
      this.isCollapsed = false;
      this.$emit('hideReport');
      this.questions = [];
      this.selectedQuestions = [];
      var promises = [];
      this.selectedQuestionnaires.forEach(function (selectedQuestionnaire) {
        promises.push(getAllQuestionsForProject(_this7.projectId, selectedQuestionnaire.value).then(function (response) {
          _this7.questions = [].concat(_toConsumableArray(_this7.questions), _toConsumableArray(response.questions));
        }));
      });
      return Promise.all(promises).then(function () {
        return _this7.loading = false;
      });
    },
    handleCreateReport: function handleCreateReport() {
      localStorage.setItem(this.localStorageKey, JSON.stringify({
        selectedQuestionnaires: this.selectedQuestionnaires,
        selectedQuestions: this.selectedQuestions,
        selectedNodes: this.selectedNodes,
        selectedOrganisations: this.selectedOrganisations,
        showControllersAndProcessors: this.showControllersAndProcessors,
        includeDataFlow: this.includeDataFlow,
        includeRaciEmails: this.includeRaciEmails,
        includeHelpText: this.includeHelpText,
        isAnsweredOnly: this.isAnsweredOnly
      }));
      this.createReport();
    },
    createReport: function createReport() {
      var resetQuery = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.isCollapsed = true;
      var questionnaireIds = this.selectedQuestionnaires.map(function (questionnaire) {
        return questionnaire.value;
      });
      var selectedNodes = this.selectedNodes.length ? this.selectedNodes : this.availableNodesOptions;
      this.$emit('createReport', {
        questionnaireIds: questionnaireIds,
        questionNumbers: this.selectedQuestions,
        nodeIds: selectedNodes.map(function (item) {
          return item.id;
        }),
        showControllersAndProcessors: this.showControllersAndProcessors,
        includeDataFlow: this.includeDataFlow,
        includeRaciEmails: this.includeRaciEmails,
        isAnsweredOnly: this.isAnsweredOnly,
        includeHelpText: this.includeHelpText,
        organisationIds: this.selectedOrganisations.map(function (item) {
          return item.id;
        }),
        resetQuery: resetQuery
      });
    },
    findNode: function findNode(nodes, nodeId) {
      var node = null;
      for (var i = 0; i < nodes.length; i++) {
        if (nodes[i].id == nodeId) {
          node = nodes[i];
          break;
        } else {
          node = this.findNode(nodes[i].children, nodeId);
          if (node) {
            break;
          }
        }
      }
      return node;
    },
    formatNodes: function formatNodes(nodes, questionnaires) {
      var _this8 = this;
      var parentTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var formattedNodes = [];
      var formattedTitle = '';
      nodes.forEach(function (node) {
        if (parentTitle !== '') {
          formattedTitle = parentTitle + ' > ' + node.title;
        } else {
          formattedTitle = node.title;
        }
        var questionnaireMatches = questionnaires.some(function (questionnaire) {
          return node.questionnaires.some(function (item) {
            return questionnaire.value === item.questionnaireId;
          });
        });
        if (questionnaireMatches) {
          formattedNodes.push({
            id: node.id,
            title: formattedTitle,
            organisationIds: node.organisationIds
          });
        }
        if (node.children) {
          formattedNodes = formattedNodes.concat(_this8.formatNodes(node.children, questionnaires, formattedTitle));
        }
      });
      return formattedNodes;
    },
    handleQuestionnairesSelected: function handleQuestionnairesSelected(event) {
      var _this9 = this;
      this.selectedQuestionnaires = event || [];
      if (this.selectedQuestionnaires.length > 0) {
        this.handleFetchQuestions().then(function () {
          _this9.isAnsweredOnly = false;
          _this9.$nextTick(function () {
            return _this9.$refs.questionSelection && _this9.$refs.questionSelection.selectDefault();
          });
        });
      }
    },
    resetFilters: function resetFilters() {
      this.selectedQuestionnaires = [];
      this.selectedQuestions = [];
      this.selectedNodes = [];
      this.selectedOrganisations = [];
      localStorage.removeItem(this.localStorageKey);
    },
    setAnsweredOnly: function setAnsweredOnly(value) {
      this.isAnsweredOnly = value;
    }
  }
};