import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_button, {
    text: "Show normal",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.showPopUpFunction('normal');
    })
  }), _createVNode(_component_risma_button, {
    text: "Show tiny text",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.showPopUpFunction('small');
    })
  }), _createVNode(_component_risma_button, {
    text: "Show large text",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.showPopUpFunction('large');
    })
  }), _createVNode(_component_risma_button, {
    text: "Show super large text",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $options.showPopUpFunction('superLarge');
    })
  }), $data.notificationData ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 0,
    type: $data.notificationData.type,
    onDismiss: $options.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationData.title), 1 /* TEXT */), _createVNode(_component_router_link, {
        to: "/dev/kitchensink/molecules"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.notificationData.url), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: "Show error",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.showPopUpFunction('error');
    })
  })]);
}