import i18n from '@/i18n';
export var getTranslate = {
  DataFlowDatatable: function DataFlowDatatable() {
    return {
      dataType: i18n.t('Data type'),
      legalBasis: i18n.t('Legal basis'),
      storagePeriod: i18n.t('Storage period')
    };
  },
  DataFlowLegalBasisAndStoragePeriod: function DataFlowLegalBasisAndStoragePeriod() {
    return {
      chooseStoragePeriod: i18n.t('Choose storage period'),
      applyStoragePeriodToAll: i18n.t('Apply storage period to all'),
      storagePeriod: i18n.t('Storage period'),
      legalBasis: i18n.t('Legal basis'),
      chooseLegalBasis: i18n.t('Choose legal basis'),
      dataType: i18n.t('Data type'),
      applyLegalBasisToAll: i18n.t('Apply legal basis to all'),
      question: i18n.t('Question'),
      chooseLegalBasisBehindDataTypes: i18n.t('Choose legal basis behind data types')
    };
  },
  DataFlowWizardStepLegalBasis: function DataFlowWizardStepLegalBasis() {
    var legalBasis = i18n.t('The legal basis');
    return {
      chooseLegalBasis: i18n.t('Choose legal basis'),
      dataType: i18n.t('Data type'),
      applyLegalBasisToAll: i18n.t('Apply legal basis to all'),
      question: i18n.t('Question'),
      chooseLegalBasisBehindDataTypes: i18n.t('Choose legal basis behind data types'),
      legalBasisHasntBeenConfigured: i18n.t('[s1] hasn’t been configured, but you’re still able to continue and fill in the data later.').replace('[s1]', legalBasis),
      contactYourAdministratorToConfigure: i18n.t('Contact your administrator to configure [s] in the questionnaires setting.').replace('[s]', legalBasis.toLowerCase())
    };
  },
  DataFlowWizardStepRetention: function DataFlowWizardStepRetention() {
    var storagePeriod = i18n.t('The storage period');
    return {
      chooseDataStoragePeriod: i18n.t('Choose storage period'),
      dataType: i18n.t('Data type'),
      applyStoragePeriodToAll: i18n.t('Apply storage period to all'),
      question: i18n.t('Question'),
      chooseLegalBasisBehindDataTypes: i18n.t('Choose storage period behind data types'),
      storagePeriodHasntBeenConfigured: i18n.t('[s1] hasn’t been configured, but you’re still able to continue and fill in the data later.').replace('[s1]', storagePeriod),
      contactYourAdministratorToConfigure: i18n.t('Contact your administrator to configure [s] in the questionnaires setting.').replace('[s]', storagePeriod.toLowerCase())
    };
  }
};