function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { cacheItem } from '@/routes/storeCache';
import { patchUtilSettings } from '@/api/risma/settings';
import { getAllQuestionnaires } from '@/api/compliance/questionnaire';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { MODULES } from '@/constants/modules';
import { QuestionnaireTypes } from '@/constants/compliance/QuestionnaireTypes';
export default {
  name: 'ComplianceAssetsSettings',
  description: 'Page with compliance assets settings',
  token: '<compliance-assets-settings />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      data: {
        'feature.systems_default_questionnaire_id': null,
        'feature.controllers_default_questionnaire_id': null,
        'feature.processors_default_questionnaire_id': null,
        'feature.systems_default_activity_type_id': null,
        'feature.data_controllers_default_activity_type_id': null,
        'feature.data_processors_default_activity_type_id': null
      },
      questionnaires: [],
      questionnaireTypes: [],
      translate: getTranslate['ComplianceAssetsSettings']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    customInformationAssetsModules: 'complianceIACustomActivityTypes',
    getActivityTypesByModule: 'getActivityTypesByModule'
  })), {}, {
    settingsData: function settingsData() {
      return [{
        name: 'System',
        questionnaireId: 'feature.systems_default_questionnaire_id',
        questionnairesOptions: this.systemQuestionnaireOptions,
        defaultActivityType: 'feature.systems_default_activity_type_id',
        activityTypeOptions: this.getActivityTypeOptions(this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_SYSTEM))
      }, {
        name: 'Controllers',
        questionnaireId: 'feature.controllers_default_questionnaire_id',
        questionnairesOptions: this.controllersQuestionnaireOptions,
        defaultActivityType: 'feature.data_controllers_default_activity_type_id',
        activityTypeOptions: this.getActivityTypeOptions(this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_CONTROLLER))
      }, {
        name: 'Processors',
        questionnaireId: 'feature.processors_default_questionnaire_id',
        questionnairesOptions: this.processorsQuestionnaireOptions,
        defaultActivityType: 'feature.data_processors_default_activity_type_id',
        activityTypeOptions: this.getActivityTypeOptions(this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_PROCESSOR))
      }];
    },
    availableQuestionnaires: function availableQuestionnaires() {
      return this.questionnaires.filter(function (questionnaire) {
        return questionnaire.selectable;
      });
    },
    systemQuestionnaireOptions: function systemQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.SYSTEM;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    controllersQuestionnaireOptions: function controllersQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.DATACONTROLLER;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    processorsQuestionnaireOptions: function processorsQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.DATAPROCESSOR || questionnaire.questionnaireType === QuestionnaireTypes.SUBPROCESSOR;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    },
    customQuestionnaireOptions: function customQuestionnaireOptions() {
      return this.availableQuestionnaires.filter(function (questionnaire) {
        return questionnaire.questionnaireType === QuestionnaireTypes.IA_CUSTOM;
      }).map(function (questionnaire) {
        return {
          value: questionnaire.id,
          label: questionnaire.title
        };
      });
    }
  }),
  mounted: function mounted() {
    this.getValuesFromSettings();
    this.getQuestionnaires();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), mapActions(useActivityTypesStore, {
    editActivityType: 'update'
  })), {}, {
    getQuestionnaires: function getQuestionnaires() {
      var _this = this;
      return getAllQuestionnaires().then(function (_ref) {
        var list = _ref.list;
        return _this.questionnaires = list;
      });
    },
    getValuesFromSettings: function getValuesFromSettings() {
      var _this2 = this;
      Object.keys(this.data).forEach(function (key) {
        return _this2.addSettingToData(_this2.getSettingValue(key), key);
      });
    },
    getActivityTypeOptions: function getActivityTypeOptions(activityTypes) {
      return activityTypes.map(function (item) {
        return {
          value: item.id,
          label: item.label
        };
      });
    },
    addSettingToData: function addSettingToData(value, name) {
      if (value === undefined) return;
      this.data[name] = value;
    },
    saveSettings: function saveSettings() {
      var _this3 = this;
      return Promise.all([this.patchSettings(), this.saveSettingsForCustomIA()]).then(function () {
        _this3.$notify({
          title: _this3.translate.settingsAreSaved,
          type: 'success'
        });
      }).catch(function (error) {
        _this3.$notify({
          title: _this3.$trans(error.response.error),
          type: 'error'
        });
      });
    },
    patchSettings: function patchSettings() {
      var _this4 = this;
      return patchUtilSettings(this.data).then(function () {
        return cacheItem('settings', function () {
          return _this4.loadSettings();
        }, 180);
      });
    },
    saveSettingsForCustomIA: function saveSettingsForCustomIA() {
      var _this5 = this;
      var promises = [];
      this.customInformationAssetsModules.forEach(function (module) {
        var data = {
          defaultQuestionnaireId: module.defaultQuestionnaireId
        };
        promises.push(_this5.editActivityType(module.id, data));
      });
      return Promise.all(promises);
    }
  })
};