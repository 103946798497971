import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Transition as _Transition } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "mx-auto"
};
var _hoisted_2 = {
  key: 1,
  class: "flex flex-wrap -mx-6"
};
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_controls_dashboard_filters = _resolveComponent("controls-dashboard-filters");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_paginated_table = _resolveComponent("paginated-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.efficiency,
    type: "big",
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_controls_dashboard_filters, {
    "local-storage-key": "controls_dashboard_efficiency",
    "use-multiple-date-ranges": true,
    onApplyFilters: $options.applyFilters,
    onApplyFiltersOnLoad: $options.applyFilters
  }, null, 8 /* PROPS */, ["onApplyFilters", "onApplyFiltersOnLoad"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($data.multipleRangesData, function (item, i) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat(item.title, "-").concat(i),
      class: "mb-12"
    }, [_createVNode(_component_risma_title, {
      title: item.title,
      class: "mb-6"
    }, null, 8 /* PROPS */, ["title"]), !item.charts.length ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($data.translate.noResultsFoundYouMayWantToWidenYourSearc), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.charts, function (chart, j) {
      return _openBlock(), _createElementBlock("div", {
        key: "".concat(chart.title, "-").concat(j),
        class: "w-full xl:w-1/2 2xl:w-8/25 xxxl:w-6/25 mb-5 px-6"
      }, [chart.datasets ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_risma_title, {
        title: chart.title,
        class: "mb-4 text-center"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_doughnut_chart, {
        datasets: chart.datasets,
        labels: chart.labels,
        "show-data-labels": true,
        "percentage-value": false,
        onChartClicked: function onChartClicked($event) {
          return $options.clickSlice(item.title, chart, $event);
        }
      }, null, 8 /* PROPS */, ["datasets", "labels", "onChartClicked"]), _createVNode(_Transition, {
        name: "fade"
      }, {
        default: _withCtx(function () {
          return [$data.pieChartsStaticTable["".concat(item.title, "-").concat(chart.title)] ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("strong", null, _toDisplayString($data.translate.controls) + ": " + _toDisplayString(_ctx.$trans($data.pieChartsStaticTable["".concat(item.title, "-").concat(chart.title)].title)), 1 /* TEXT */), (_openBlock(), _createBlock(_component_paginated_table, {
            key: $data.pieChartsStaticTable["".concat(item.title, "-").concat(chart.title)].title,
            columns: $data.pieChartsStaticTable["".concat(item.title, "-").concat(chart.title)].table.columns,
            "dataset-table": $data.pieChartsStaticTable["".concat(item.title, "-").concat(chart.title)].table.dataset,
            "page-size": $options.getPieChartTableSize($data.pieChartsStaticTable["".concat(item.title, "-").concat(chart.title)].table.dataset.length)
          }, {
            text: _withCtx(function (_ref) {
              var slotData = _ref.slotData;
              return [_createVNode(_component_router_link, {
                to: slotData.url || ''
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_risma_title_with_icon, {
                    icon: "confidential",
                    title: slotData.title,
                    "title-attribute": slotData.titleParam,
                    "title-is-raw-html": true,
                    "display-icon": slotData.confidential
                  }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
                }),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset-table", "page-size"]))])) : _createCommentVNode("v-if", true)];
        }),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */))]))]);
  }), 128 /* KEYED_FRAGMENT */))]);
}