// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-erm-assessment[data-v-6d959e12] {
  position: relative;
}
.risk-additional-selector[data-v-6d959e12] {
  width: 48%;
}
.risk-additional-selector[data-v-6d959e12]  .risma-title {
  margin: 0 0 5px;
}
.risk-additional-selector[data-v-6d959e12]  .single-select {
  margin: 0 0 4px;
}
.risk-additional-selector[data-v-6d959e12]  .ql-container {
  height: 119px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
