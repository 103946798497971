function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
export var ExtendedViewCustomFieldsMixin = {
  data: function data() {
    return {};
  },
  computed: {
    customFields: function customFields() {
      var customFields = [].concat.apply([], this.informationAssets.map(function (ia) {
        return ia.customFields;
      }));
      return _toConsumableArray(new Map(customFields.map(function (item) {
        return [item.id, item];
      })).values());
    },
    customFieldsColumns: function customFieldsColumns() {
      var _this = this;
      var result = {};
      this.customFields.forEach(function (cf) {
        result[_this.getColumnLabelName(cf.id, cf.label)] = cf.label;
        if (cf.typeId === CustomFieldTypes.NUMERICAL) {
          var unitLabel = _this.getColumnLabelName(cf.id, cf.label + _this.customUnitPrefix);
          result[unitLabel] = cf.label + _this.customUnitPrefix;
        }
      });
      return result;
    }
  },
  methods: {
    getColumnLabelName: function getColumnLabelName(id, label) {
      return "cf-".concat(id, "-").concat(label.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''));
    },
    getCustomFieldsData: function getCustomFieldsData(element) {
      var _this2 = this;
      var result = {};
      this.customFields.forEach(function (cf) {
        var customFieldLabel = _this2.getColumnLabelName(cf.id, cf.label);
        _this2.prepareCustomFieldValue(result, element.customFieldValues, cf, customFieldLabel);
      });
      return result;
    }
  }
};