// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-button[data-v-0d28cfb3] {
  position: static;
}
.tab-item:hover .label-extra[data-v-0d28cfb3] {
  --tw-bg-opacity: 1;
  background-color: rgb(222 226 230 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(52 58 64 / var(--tw-text-opacity));
}
.tab-item .active .label-extra[data-v-0d28cfb3] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 172 239 / var(--tw-text-opacity));
}
.tab-item:hover .active .label-extra[data-v-0d28cfb3] {
  --tw-bg-opacity: 1;
  background-color: rgb(222 226 230 / var(--tw-bg-opacity));
}
.tab-item:hover .disabled .label-extra[data-v-0d28cfb3] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(173 181 189 / var(--tw-text-opacity));
}
.tab-bar[data-v-0d28cfb3] {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.tab-bar[data-v-0d28cfb3]::-webkit-scrollbar {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
