import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex items-center mb-4 justify-end"
};
var _hoisted_2 = {
  class: "min-w-150px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_gantt_chart_js = _resolveComponent("gantt-chart-js");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", null, [$props.isSortingVisible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.sortBy,
    type: "small",
    truncate: false,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
    modelValue: $setup.sortKey,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.sortKey = $event;
    }),
    options: $options.sortKeys,
    placeholder: $data.translate.sortBy,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])])])) : _createCommentVNode("v-if", true), _createVNode(_component_gantt_chart_js, {
    datasets: $options.chartData.data,
    labels: $options.chartData.labels,
    height: $options.ganttChartHeight,
    "x-axis-click-enabled": true,
    "onClick:xAxis": $options.showPreviewModal
  }, null, 8 /* PROPS */, ["datasets", "labels", "height", "onClick:xAxis"]), _createVNode(_component_preview_modal_link, {
    ref: "preview",
    activity: $options.selectedActivity,
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('updateFromPreviewMode', $event);
    }),
    onDismissModal: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, {
    default: _withCtx(function () {
      return _cache[3] || (_cache[3] = [_createElementVNode("span", null, null, -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity"])]);
}