// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-869dc600]::-webkit-input-placeholder {
  font-style: italic;
}
[data-v-869dc600]  .input-field ::-webkit-input-placeholder {
  font-style: italic;
}
.dashboard-title-wrap[data-v-869dc600] {
  display: flex;
  align-items: center;
}
.dashboard-title-wrap .additional-title[data-v-869dc600] {
  font-style: italic;
  font-size: 12px;
  margin-left: 5px;
}
.input-field-container.error textarea[data-v-869dc600],
.input-field-container.error[data-v-869dc600]  input {
  border-color: #B03D00;
}
.validation-error[data-v-869dc600] {
  color: #B03D00;
  margin: 0 0 0.5rem;
  padding: 4px 0 0;
  display: block;
}
.input-validation-error[data-v-869dc600]  input {
  border-color: #B03D00;
}
.sign-off-box[data-v-869dc600] {
  margin: 0 0 1.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
