import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mb-4"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = ["onMouseover"];
var _hoisted_5 = {
  class: "inline-grid"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tool_tip = _resolveComponent("tool-tip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['flex items-center', $props.readOnly ? '' : 'cursor-pointer']),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $props.readOnly ? null : _ctx.$emit('edit');
    })
  }, [_createVNode(_component_risma_title, {
    class: "w-11/12",
    type: "medium",
    title: $props.title
  }, null, 8 /* PROPS */, ["title"]), !$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "edit"
  })) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $options.isTruncateList ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($options.sortedList.length) + " " + _toDisplayString($data.translate.selected), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedList, function (item, index) {
    return _openBlock(), _createElementBlock("li", {
      key: item[$props.labelKey]
    }, [_createElementVNode("span", {
      class: "relative",
      onMouseleave: _cache[1] || (_cache[1] = function ($event) {
        return $data.hoverElementIdx = null;
      }),
      onMouseover: function onMouseover($event) {
        return $data.hoverElementIdx = index;
      }
    }, [_createTextVNode(_toDisplayString(item[$props.labelKey]) + " ", 1 /* TEXT */), $props.isUser ? (_openBlock(), _createBlock(_component_tool_tip, {
      key: 0,
      hidden: $data.hoverElementIdx !== index,
      "arrow-direction": "right"
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, [_createElementVNode("b", null, _toDisplayString($data.translate.username), 1 /* TEXT */), _createTextVNode(" : " + _toDisplayString(item[$props.labelKey]), 1 /* TEXT */)]), _createElementVNode("span", null, [_createElementVNode("b", null, _toDisplayString($data.translate.fullName), 1 /* TEXT */), _createTextVNode(" : " + _toDisplayString(item.fullname), 1 /* TEXT */)]), _createElementVNode("span", null, [_createElementVNode("b", null, _toDisplayString($data.translate.initials), 1 /* TEXT */), _createTextVNode(" : " + _toDisplayString(item.initials), 1 /* TEXT */)]), _createElementVNode("span", null, [_createElementVNode("b", null, _toDisplayString($data.translate.email), 1 /* TEXT */), _createTextVNode(" : " + _toDisplayString(item.email), 1 /* TEXT */)])])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["hidden"])) : _createCommentVNode("v-if", true)], 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)]);
  }), 128 /* KEYED_FRAGMENT */))]))]);
}