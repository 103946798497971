function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { sortByIndex } from '@/utils/sort';
import * as ControlsApi from '@/api/controls/controls';
import * as DataAPI from '@/api/risma/data';
export var CommonAssetsMixin = {
  data: function data() {
    return {
      controls: [],
      initiatives: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useOrganisationsStore, {
    organisations: 'organisationsSortedByVisibleName'
  })), mapState(useUsersStore, ['activeUsers', 'users'])), mapState(useTagsStore, ['tags'])),
  methods: {
    getInitiatives: function getInitiatives() {
      var _this = this;
      return DataAPI.getActions().then(function (_ref) {
        var list = _ref.list;
        return _this.initiatives = sortByIndex(list, 'ipath');
      });
    },
    getControlsList: function getControlsList() {
      var _this2 = this;
      var activeControls = [],
        inactiveControls = [];
      var activeControlsPromise = ControlsApi.getAll('?data[]=organisationIds').then(function (_ref2) {
        var list = _ref2.list;
        activeControls = list;
      });
      var inactiveControlsPromise = ControlsApi.getAll('?data[]=organisationIds&filter[]=inactive').then(function (_ref3) {
        var list = _ref3.list;
        inactiveControls = list;
      });
      return Promise.all([activeControlsPromise, inactiveControlsPromise]).then(function () {
        return _this2.controls = sortByIndex([].concat(_toConsumableArray(activeControls), _toConsumableArray(inactiveControls)), 'path');
      });
    },
    getControlsFromData: function getControlsFromData() {
      var _this3 = this;
      return ControlsApi.getDataControls().then(function (_ref4) {
        var list = _ref4.list;
        _this3.controls = sortByIndex(list, 'path');
      });
    }
  }
};