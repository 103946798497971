import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { formatTimeToDateFormat } from '@/utils/date';
export default {
  name: 'CardContract',
  description: "Card to display contract's data",
  token: '<card-contract :activity="activity"></card-contract>',
  components: {
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Risk object'
    },
    workflowStatuses: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'A list of workflow statuses for contracts'
    }
  },
  data: function data() {
    return {
      translate: {
        status: this.$trans('Status'),
        expirationDate: this.$trans('Expiration date')
      }
    };
  },
  methods: {
    getWorkflowLabelById: function getWorkflowLabelById(id) {
      var status = this.workflowStatuses.find(function (status) {
        return +status.id === +id;
      });
      return status ? status.title : '';
    },
    getEndDate: function getEndDate(date) {
      return date ? formatTimeToDateFormat(date) : '';
    }
  }
};