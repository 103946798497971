function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import { capitalize } from '@/utils';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { getAllByType } from '@/api/compliance/informationAssets';
import { AssetsTypes, getModuleFromAssetType } from '@/constants/compliance/AssetsTypes';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { generateKey } from '@/utils/Utils';
import { MODULES } from '@/constants/modules';
export default {
  name: 'InformationAssetsNew',
  description: 'Component to display info about all IA: processors, systems, controllers and custom ia',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    InformationAssetCreationModal: InformationAssetCreationModal,
    RismaTable: RismaTable
  },
  props: {
    onlyMy: {
      type: Boolean,
      default: false,
      note: 'If show only my IA'
    },
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    activityTypes: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      showCreateModal: false,
      timer: 0,
      debounceTimeout: 1000,
      tableGeneratedKey: generateKey(),
      translate: getTranslate['InformationAssets']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), {}, {
    tableStateKey: function tableStateKey() {
      return 'companies_table_' + this.typeId;
    },
    title: function title() {
      var activityTypeName = this.activityType ? capitalize(this.activityType.label) : this.$trans(capitalize(this.typeId));
      return this.onlyMy ? "".concat(this.translate.my, " ").concat(activityTypeName) : "".concat(activityTypeName);
    },
    activityType: function activityType() {
      var _this = this;
      return this.activityTypes.find(function (type) {
        return type.id == _this.typeId;
      });
    },
    canCreate: function canCreate() {
      return useUserStore().canCreateInformationAssets(this.module, this.typeId);
    },
    module: function module() {
      if (!isNaN(this.typeId)) {
        return MODULES.COMPLIANCE_INFORMATION_ASSETS;
      }
      return getModuleFromAssetType(this.typeId);
    }
  }),
  watch: {
    typeId: function typeId() {
      this.refreshTable();
    }
  },
  methods: {
    getDataBE: function getDataBE(query) {
      var type = this.typeId > 0 ? AssetsTypes.INFORMATION_ASSETS_API : this.typeId;
      var q = this.onlyMy ? "?filters[responsible][]=".concat(this.currentUser.id) : '?';
      q += '&filters[underlying]=include&';
      if (this.typeId > 0) {
        q += "filters[activityType]=".concat(this.typeId, "&");
      }
      return getAllByType(type, q + query);
    },
    createIA: function createIA(data) {
      this.toggleCreateModal();
      this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        activity: getFieldsFromObjectForPreview(data)
      });
      this.refreshTable();
    },
    toggleCreateModal: function toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    refreshTable: function refreshTable() {
      this.tableGeneratedKey = generateKey();
    }
  }
};