export var ReportTypes = {
  CONTROLS: 'controls-report',
  CONTROLS_DATA: 'controls-data-report',
  CONTRACTS: 'contracts-report',
  ACTIONS: 'actions-report',
  COMPLIANCE_NODE: 'compliance-nodes-report',
  COMPLIANCE_NODE_THIN: 'compliance-nodes-report-thin',
  COMPLIANCE_GAP: 'compliance-gap-report',
  COMPLIANCE_GAP_THIN: 'compliance-gap-report-thin',
  COMPLIANCE_ARTICLE30: 'compliance-article30-report',
  COMPLIANCE_QUESTIONNAIRE: 'compliance-questionnaire-report',
  COMPLIANCE_DATAFLOW: 'compliance-dataflow-report',
  RISK: 'risk-report',
  RISK_THREATS: 'risk-threats-report',
  RISK_VULNERABILITIES: 'risk-vulnerabilities-report',
  PROCESS_LIBRARY: 'compliance-process-tree-report',
  PROCESS_LIBRARY_THIN: 'compliance-process-tree-report-thin',
  INCIDENTS: 'incidents-report',
  INCIDENTS_THIN: 'incidents-report-thin',
  IA_ANSWERED_AUDIT: 'information-assets-answered-audits-report',
  IA_QUESTIONNAIRE_AUDIT: 'information-assets-questionnaire-report'
};
export var ReportLogTypes = {
  SCREEN: 'Screen',
  EXCEL: 'Excel',
  PRINT: 'Print',
  WORD: 'Word'
};