// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-report-grid-wrap[data-v-1679a553] {
  position: relative;
  margin: 0 0 2rem;
}
.risk-report-grid-wrap .risk-grid-labels[data-v-1679a553] {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
}
.risk-report-grid-wrap .risk-grid-labels > li[data-v-1679a553] {
  margin-right: 1rem;
}
.risk-report-grid-wrap .risk-grid-labels .label-circle[data-v-1679a553] {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 4px;
  background: #BEBEBE;
}
.risk-report-grid-wrap[data-v-1679a553]  .static-tabs {
  margin-top: 1rem;
}
.risk-report-grid-wrap[data-v-1679a553]  .static-tab-item {
  margin: 0 2px 0 0;
}
.risk-report-grid-wrap[data-v-1679a553]  .zoom-actions {
  margin-left: 96px;
}
.risk-report-grid-wrap[data-v-1679a553]  .labeltd {
  font-size: 12px;
}
.col-risk-table[data-v-1679a553] {
  position: relative;
}
.info[data-v-1679a553] {
  width: 90%;
  margin: 0 auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
