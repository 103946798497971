import { getTranslate } from './translate';
import FieldSet from '@/components/Atoms/FieldSet/FieldSet';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaOrgSelector from '@/components/Molecules/OrganisationSelector';
export default {
  name: 'RiskFilters',
  introduction: 'Fieldset of filters for ISMS risks',
  description: 'Fieldset of filters for ISMS risks',
  token: '<risk-filters />',
  components: {
    FieldSet: FieldSet,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect,
    RismaOrgSelector: RismaOrgSelector,
    InputField: InputField
  },
  props: {
    organisations: {
      type: Array,
      required: true
    },
    organisationsSelected: {
      type: Array,
      required: true
    },
    parentRisks: {
      type: Array,
      required: true
    },
    parentRiskSelected: {
      required: true,
      validator: function validator(prop) {
        return typeof prop === 'number' || prop === null;
      }
    },
    tags: {
      type: Array,
      required: true
    },
    tagsSelected: {
      required: true,
      type: Array
    },
    search: {
      type: String,
      required: true
    }
  },
  emits: ['update-orgs', 'update-prisk', 'update-tag', 'update-search'],
  data: function data() {
    return {
      translate: getTranslate['RiskFilters']()
    };
  }
};