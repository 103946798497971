import InputField from '@/components/Atoms/Inputs/InputField';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
export default {
  name: 'QuickEditField',
  description: "The QuickEditField component provides inline editing functionality for text,\n        allowing users to quickly edit text content directly on the page without navigating to\n        a separate form. It toggles between displaying the original text and an input field for\n        editing, triggered by clicking an edit icon. Changes are saved upon pressing Enter or\n        cancelled upon pressing Escape",
  token: "<quick-edit-field\n            :label=\"Lorem ipsum dolor sit amet\"\n            :pre-text=\"some text\"\n            :disabled=\"false\"\n            @update=\"handleUpdate\" />",
  components: {
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    RismaLabel: RismaLabel
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    preText: {
      type: String,
      required: false,
      default: '',
      note: 'The initial text content to be displayed and edited'
    },
    maxlength: {
      required: false,
      type: [Number, String],
      default: 255,
      note: 'maximum of characters in the input field'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Determines whether the editing functionality is disabled'
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      initialText: this.preText,
      updatedText: this.preText,
      isEditMode: false
    };
  },
  watch: {
    preText: function preText(newText) {
      this.initialText = newText;
      this.updatedText = newText;
    }
  },
  methods: {
    updateText: function updateText(value) {
      this.updatedText = value;
    },
    handleUpdate: function handleUpdate(event) {
      if (this.updatedText !== this.initialText) {
        this.$emit('update', event);
        this.initialText = event;
      }
      this.isEditMode = false;
    },
    cancelEdit: function cancelEdit() {
      this.updatedText = this.initialText;
      this.isEditMode = false;
    }
  }
};