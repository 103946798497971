function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { MODULES, getModuleTitles } from '@/constants/modules';
import { buildNavBar } from '@/menus/navbar';
export var getLinksModalSettings = function getLinksModalSettings() {
  var _user$AccessLevels, _user$AccessLevels2, _user$AccessLevels3, _ref;
  var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var user = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var navBar = buildNavBar(settings, user);
  var ModuleTitles = getModuleTitles();
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, MODULES.EXECUTION, {
    label: ModuleTitles[MODULES.EXECUTION],
    value: MODULES.EXECUTION,
    rights: navBar.actions.rights,
    showAddItemButton: user === null || user === void 0 || (_user$AccessLevels = user.AccessLevels) === null || _user$AccessLevels === void 0 || (_user$AccessLevels = _user$AccessLevels.Actions) === null || _user$AccessLevels === void 0 ? void 0 : _user$AccessLevels.canCreate
  }), MODULES.CONTRACTS, {
    label: ModuleTitles[MODULES.CONTRACTS],
    value: MODULES.CONTRACTS,
    rights: navBar.contracts.rights
  }), MODULES.CONTROLS, {
    label: ModuleTitles[MODULES.CONTROLS],
    value: MODULES.CONTROLS,
    rights: navBar.controls.rights,
    showAddItemButton: user === null || user === void 0 || (_user$AccessLevels2 = user.AccessLevels) === null || _user$AccessLevels2 === void 0 || (_user$AccessLevels2 = _user$AccessLevels2.Controls) === null || _user$AccessLevels2 === void 0 ? void 0 : _user$AccessLevels2.canCreate
  }), MODULES.RISK, {
    label: ModuleTitles[MODULES.RISK],
    value: MODULES.RISK,
    rights: navBar.risk.rights
  }), MODULES.COMPLIANCE, {
    label: ModuleTitles[MODULES.COMPLIANCE],
    value: MODULES.COMPLIANCE,
    rights: navBar.compliance.rights
  }), MODULES.INCIDENTS, {
    label: ModuleTitles[MODULES.INCIDENTS],
    value: MODULES.INCIDENTS,
    rights: navBar.incident.rights
  }), MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
    label: ModuleTitles[MODULES.COMPLIANCE_PROCESS_TREE_NODE],
    value: MODULES.COMPLIANCE_PROCESS_TREE_NODE,
    rights: (user === null || user === void 0 || (_user$AccessLevels3 = user.AccessLevels) === null || _user$AccessLevels3 === void 0 || (_user$AccessLevels3 = _user$AccessLevels3.PolicyManagement) === null || _user$AccessLevels3 === void 0 ? void 0 : _user$AccessLevels3.level) || (user === null || user === void 0 ? void 0 : user.is_admin)
  }), MODULES.COMPLIANCE_IA_SYSTEM, {
    label: ModuleTitles[MODULES.COMPLIANCE_IA_SYSTEM],
    value: MODULES.COMPLIANCE_IA_SYSTEM,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true && (settings['feature.enable_systems'] === undefined || settings['feature.enable_systems'])
  }), MODULES.COMPLIANCE_IA_PROCESSOR, {
    label: ModuleTitles[MODULES.COMPLIANCE_IA_PROCESSOR],
    value: MODULES.COMPLIANCE_IA_PROCESSOR,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true && (settings['feature.enable_data_processors'] === undefined || settings['feature.enable_data_processors'])
  }), MODULES.COMPLIANCE_IA_CONTROLLER, {
    label: ModuleTitles[MODULES.COMPLIANCE_IA_CONTROLLER],
    value: MODULES.COMPLIANCE_IA_CONTROLLER,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true && (settings['feature.enable_data_controllers'] === undefined || settings['feature.enable_data_controllers'])
  }), _defineProperty(_defineProperty(_defineProperty(_ref, MODULES.COMPLIANCE_INFORMATION_ASSETS, {
    label: ModuleTitles[MODULES.COMPLIANCE_INFORMATION_ASSETS],
    value: MODULES.COMPLIANCE_INFORMATION_ASSETS,
    rights: (user === null || user === void 0 ? void 0 : user.iaAccess) === true
  }), MODULES.RISK_ASSESSMENT, {
    label: ModuleTitles[MODULES.RISK_ASSESSMENT],
    value: MODULES.RISK_ASSESSMENT,
    rights: true
  }), MODULES.COMPANY, {
    label: ModuleTitles[MODULES.COMPANY],
    value: MODULES.COMPANY,
    rights: (user === null || user === void 0 ? void 0 : user.level_company) || (user === null || user === void 0 ? void 0 : user.is_admin)
  });
};
export var getProjectRiskAssessmentLinksTab = function getProjectRiskAssessmentLinksTab() {
  var projects = getLinksModalSettings();
  return projects[MODULES.RISK_ASSESSMENT];
};
export var getProjectsOptionsLinksTab = function getProjectsOptionsLinksTab(settings, user) {
  var projects = getLinksModalSettings(settings, user);
  return [projects[MODULES.CONTRACTS], projects[MODULES.EXECUTION], projects[MODULES.CONTROLS], projects[MODULES.RISK], projects[MODULES.COMPLIANCE_PROCESS_TREE_NODE], projects[MODULES.COMPLIANCE_IA_SYSTEM], projects[MODULES.COMPLIANCE_IA_CONTROLLER], projects[MODULES.COMPLIANCE_IA_PROCESSOR], projects[MODULES.COMPLIANCE], projects[MODULES.INCIDENTS], projects[MODULES.COMPLIANCE_INFORMATION_ASSETS], projects[MODULES.COMPANY]];
};
export var getGapProjectsOptions = function getGapProjectsOptions(settings, user) {
  var projects = getLinksModalSettings(settings, user);
  var data = [projects[MODULES.EXECUTION], projects[MODULES.CONTROLS], projects[MODULES.COMPLIANCE_PROCESS_TREE_NODE]];
  if (settings['app.module_risks2_enabled']) {
    data.push(projects[MODULES.RISK]);
  }
  return data;
};
export var getAssessmentProjectsOptions = function getAssessmentProjectsOptions(settings, user) {
  var projects = getLinksModalSettings(settings, user);
  return [projects[MODULES.EXECUTION], projects[MODULES.CONTROLS]];
};
export var GovernanceReviewOptionsLinksTab = {
  label: 'Governance review',
  value: 'governanceReviewLinks'
};
export var RiskAssessmentActivityLinksTab = {
  label: 'Risk assessed activity',
  value: 'riskAssessmentActivity'
};