import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "border p-1 bg-white"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  class: "py-1 transition duration-500 transition duration-300 ease-in-out"
};
var _hoisted_4 = ["data-gapId", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_traffic_light = _resolveComponent("traffic-light");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.gapListByDomain, function (domain, index) {
    return _openBlock(), _createElementBlock("div", {
      key: domain.id + '' + index,
      class: "cursor-pointer relative bg-white overflow-hidden"
    }, [$props.showDomain ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "flex",
      onClick: function onClick($event) {
        return $options.toggleGap(index);
      }
    }, [_createVNode(_component_risma_title, {
      title: domain.label,
      type: "medium",
      class: "inline-block max-w-full-25"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
      icon: "chevron-down",
      class: _normalizeClass(['inline-block stroke-3 text-blue-750 transform duration-500 align-baseline ml-1', {
        'rotate-180': $data.indexExpandedView === index
      }])
    }, null, 8 /* PROPS */, ["class"])], 8 /* PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true), _createVNode(_Transition, {
      name: "accordion-item",
      onEnter: $options.startTransition,
      onAfterEnter: $options.endTransition,
      onBeforeLeave: $options.startTransition,
      onAfterLeave: $options.endTransition
    }, {
      default: _withCtx(function () {
        return [$data.indexExpandedView === index ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(domain._gaps, function (gap) {
          return _openBlock(), _createElementBlock("p", {
            key: gap.id,
            "data-gapId": gap.article.id,
            class: "truncate flex items-center",
            onClick: function onClick($event) {
              return _ctx.$emit('selectGap', gap.articleId);
            }
          }, [_createVNode(_component_traffic_light, {
            color: _ctx.gapColors[gap.analysis_rag || 0].color,
            size: "small",
            class: "shrink-0"
          }, null, 8 /* PROPS */, ["color"]), _createVNode(_component_risma_title, {
            class: _normalizeClass(['text-blue-750 cursor-pointer inline ml-2 font-normal', {
              linethrough: gap.analysis_rag == 5
            }, {
              'font-semibold': $props.gapActiveId === gap.articleId
            }]),
            title: $options.getGapTitleForSidebar(gap),
            type: "small"
          }, null, 8 /* PROPS */, ["class", "title"])], 8 /* PROPS */, _hoisted_4);
        }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onEnter", "onAfterEnter", "onBeforeLeave", "onAfterLeave"])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}