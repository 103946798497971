import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "text-center"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_gantt_chart_actions = _resolveComponent("gantt-chart-actions");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_gantt_chart_actions, {
    activities: $props.initiatives,
    "local-storage-key": "DPO-dash-gantt-sort",
    onDismissModal: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('dismissModal');
    })
  }, null, 8 /* PROPS */, ["activities"]), $props.initiatives.length == 0 ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($data.translate.noRecordsMatchingTheFilter), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.dataset.length ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 1,
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $options.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "page-length": 50,
    "wrapper-height-sticky": 500,
    "custom-export": $options.customExport,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged,
    onExport: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('logReport', $event);
    })
  }, {
    trafficlight: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_traffic_light, {
        color: item.field.color
      }, null, 8 /* PROPS */, ["color"])];
    }),
    title: _withCtx(function (_ref2) {
      var item = _ref2.item;
      return [_createVNode(_component_preview_modal_link, {
        class: "hover:underline",
        activity: item.activity,
        onDismissModal: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.fieldText), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])];
    }),
    depending: _withCtx(function (_ref3) {
      var item = _ref3.item;
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (data, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createVNode(_component_router_link, {
          to: data.url
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(data.title), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    gaps: _withCtx(function (_ref4) {
      var item = _ref4.item;
      return [item.activities && item.activities.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activities, function (activity, index) {
        return _openBlock(), _createBlock(_component_preview_modal_link, {
          key: "".concat(activity.fieldText, "_").concat(index),
          class: "hover:underline",
          activity: activity.activity,
          onDismissModal: _cache[2] || (_cache[2] = function ($event) {
            return _ctx.$emit('dismissModal');
          })
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_traffic_light, {
              class: "flex-shrink-0 mt-0.5 mr-1",
              color: activity.color
            }, null, 8 /* PROPS */, ["color"]), _createElementVNode("span", null, _toDisplayString(activity.fieldText), 1 /* TEXT */)])];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
      }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_4))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "custom-export", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)]);
}