// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification[data-v-55c4f9e5] {
  position: relative;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.4s;
}
.notification.with-close[data-v-55c4f9e5] {
  padding-right: 45px;
}
.notification.fadeOut[data-v-55c4f9e5] {
  opacity: 0;
}
.notification.dismiss[data-v-55c4f9e5] {
  display: none;
}
.notification .risma-icon[data-v-55c4f9e5] {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 20px;
  opacity: 0.2;
  text-shadow: 0 1px 0 #FFFFFF;
  cursor: pointer;
  transition: opacity 0.4s;
}
.notification .risma-icon[data-v-55c4f9e5]:hover {
  opacity: 0.6;
}
.notification.warning[data-v-55c4f9e5] {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.notification.warning a[data-v-55c4f9e5] {
  color: #66512c;
}
.notification.error[data-v-55c4f9e5] {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.notification.error a[data-v-55c4f9e5] {
  color: #843534;
}
.notification.info[data-v-55c4f9e5] {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.notification.info a[data-v-55c4f9e5] {
  color: #245269;
}
.notification.success[data-v-55c4f9e5] {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.notification.success a[data-v-55c4f9e5] {
  color: #2b542c;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
