function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import { RiskReportsFiltersMixin } from '@/components/Molecules/Risk/RiskReports/RiskReportsFiltersMixin';
import { wrongCustomFields } from '@/components/Pages/Risks/RiskReports/RiskReportUtils';
export default {
  name: 'RiskReportsFiltersErm',
  description: 'Filters section for risk reports(erm)',
  token: '<risk-reports-filters-erm />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    ButtonsRow: ButtonsRow,
    SingleSelect: SingleSelect,
    RismaSelect: RismaSelect
  },
  mixins: [RiskReportsFiltersMixin],
  props: {
    options: {
      required: true,
      type: Object,
      note: 'Object with options for selectors'
    },
    selectedFilters: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'Object with selected filters values'
    },
    currentPeriod: {
      required: true,
      type: Object
    }
  },
  emits: ['changePeriod', 'create'],
  data: function data() {
    return {
      unSorted: true,
      xAxisValidation: '',
      managementLevelsValidation: '',
      translate: getTranslate['RiskReportsFiltersErm']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    riskTypeLabel: function riskTypeLabel() {
      return this.getSettingValue('risma.risktype_name');
    },
    customFields: function customFields() {
      var SPECIAL_TYPE = 10;
      return this.currentPeriod.fields.filter(function (field) {
        return field.fieldtype === SPECIAL_TYPE && field.options;
      });
    },
    buttonsRowData: function buttonsRowData() {
      return [{
        text: this.translate.resetFilter,
        type: 'util',
        clickEvent: this.handleResetFilters
      }, {
        text: this.translate.createReport,
        type: 'save',
        clickEvent: this.handleCreateReport
      }];
    }
  }),
  watch: {
    customFields: {
      handler: function handler(newValue, oldValue) {
        var _this = this;
        if (!(newValue !== null && newValue !== void 0 && newValue.length) || !(oldValue !== null && oldValue !== void 0 && oldValue.length)) {
          this.selectedFilters.customFields = {};
          return;
        }
        var _loop = function _loop(cfLabel) {
          var _oldValue$find, _Object$values$find;
          var matchingCustomField = newValue.find(function (item) {
            return item.label === cfLabel;
          });
          if (!(matchingCustomField !== null && matchingCustomField !== void 0 && matchingCustomField.options)) {
            delete _this.selectedFilters.customFields[cfLabel];
            return 1; // continue
          }
          // Set custom fields that match the labels of the previous report
          var previousOptions = ((_oldValue$find = oldValue.find(function (item) {
            return item.label === cfLabel;
          })) === null || _oldValue$find === void 0 ? void 0 : _oldValue$find.options) || {};
          var previousValueLabel = (_Object$values$find = Object.values(previousOptions).find(function (option) {
            return +option.id === +_this.selectedFilters.customFields[cfLabel];
          })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.value;
          var newCustomField = Object.values(matchingCustomField.options).find(function (option) {
            return option.value === previousValueLabel;
          });
          _this.setCustomFields(matchingCustomField, newCustomField.id + '');
        };
        for (var cfLabel in this.selectedFilters.customFields) {
          if (_loop(cfLabel)) continue;
        }
      }
    },
    'options.impact': function optionsImpact() {
      this.updateAxis('xAxis', this.options.impact);
    },
    'options.likelihood': function optionsLikelihood() {
      this.updateAxis('yAxis', this.options.likelihood);
    }
  },
  methods: {
    updateAxis: function updateAxis(axisName, options) {
      var _this2 = this;
      if (!this.selectedFilters[axisName]) {
        return;
      }
      var axisExistInNewList = options.find(function (item) {
        return item.value === _this2.selectedFilters[axisName];
      });
      if (!axisExistInNewList) {
        var _options$;
        this.selectedFilters.yAxis = ((_options$ = options[0]) === null || _options$ === void 0 ? void 0 : _options$.value) || '';
      }
    },
    prepareOptionsList: function prepareOptionsList(options) {
      return Object.values(options).reduce(function (acc, curr) {
        acc.push(_objectSpread(_objectSpread({}, curr), {}, {
          label: curr.value,
          value: curr.id + ''
        }));
        return acc;
      }, [{
        label: this.translate.all,
        value: 'all',
        id: 'all'
      }]);
    },
    setCustomFields: function setCustomFields(field, value) {
      var fieldsValue = {};
      if (wrongCustomFields[field.label]) {
        fieldsValue[field.label] = value;
      } else {
        fieldsValue[field.id] = value;
      }
      this.selectedFilters.customFields = _objectSpread(_objectSpread({}, this.selectedFilters.customFields), fieldsValue);
    },
    validate: function validate() {
      var result = true;
      this.xAxisValidation = '';
      this.managementLevelsValidation = '';
      if (!this.selectedFilters.xAxis) {
        result = false;
        this.xAxisValidation = this.translate.pleaseSelectOne;
      }
      if (!this.selectedFilters.managementLevels || !(this.selectedFilters.managementLevels && this.selectedFilters.managementLevels.length)) {
        result = false;
        this.managementLevelsValidation = this.translate.pleaseSelectOne;
      }
      return result;
    },
    handleCreateReport: function handleCreateReport() {
      if (!this.validate()) return;
      this.$emit('create', this.selectedFilters);
    }
  }
};