// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-reset-styles[data-v-41b4b7f3]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-41b4b7f3]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-41b4b7f3]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-41b4b7f3]  li {
  padding-left: 0.3em;
}
.preview-cont[data-v-41b4b7f3] {
  position: relative;
  padding: 15px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #CCCCCC;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
  z-index: 2;
}
.preview-cont[data-v-41b4b7f3]:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: -85% 50%;
  background-size: 80%;
  opacity: 0.7;
}
.preview-header[data-v-41b4b7f3] {
  display: flex;
  align-items: center;
}
.preview-header .preview-header-trafficlight[data-v-41b4b7f3] {
  margin: 0 5px 0 0;
}
.preview-header .preview-header-trafficlight[data-v-41b4b7f3]  svg {
  top: 0;
}
.preview-header .preview-header-title[data-v-41b4b7f3] {
  overflow: hidden;
}
.preview-header .preview-header-title[data-v-41b4b7f3]  .big {
  font-size: 24px;
}
.preview.red[data-v-41b4b7f3]  .big {
  color: #f25050;
}
.preview.red .preview-header-title[data-v-41b4b7f3]  a:hover {
  color: #f25050;
}
.preview-footer[data-v-41b4b7f3] {
  padding-top: 5px;
}
.preview-footer[data-v-41b4b7f3]  .small {
  margin: 0;
}
.preview-process-tree-node .preview-cont[data-v-41b4b7f3] {
  display: block;
}
.preview-process-tree-node .preview-cont[data-v-41b4b7f3]:before {
  background-image: url("/assets/svg/icon_compliance.svg");
}
.preview-process-tree-node .preview-header[data-v-41b4b7f3]  a {
  max-width: 100%;
}
.preview-process-tree-node .preview-header[data-v-41b4b7f3]  .big {
  font-size: 24px;
}
.preview-process-tree-node .preview-body[data-v-41b4b7f3] {
  margin: 15px 0 0 0;
  word-wrap: break-word;
  max-height: 80px;
  overflow: hidden;
}
.preview-process-tree-node .preview-body > div[data-v-41b4b7f3] {
  margin: 0;
}
.preview-process-tree-node .preview-body p[data-v-41b4b7f3] {
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
