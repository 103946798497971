// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-options-toggle[data-v-564c1ac8] {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem 0;
  font-size: 16px;
  color: #347480;
  cursor: pointer;
}
.list-options-actions[data-v-564c1ac8] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 1rem 0;
  font-size: 16px;
  color: #347480;
}
.list-options-actions .list-options-reset[data-v-564c1ac8] {
  margin: 0 0 0 25px;
  cursor: pointer;
}
.list-options-actions .list-options-toggleview[data-v-564c1ac8] {
  cursor: pointer;
}
.fade-enter-active[data-v-564c1ac8],
.fade-leave-active[data-v-564c1ac8] {
  transition: opacity 0.25s ease-out;
}
.fade-enter-from[data-v-564c1ac8],
.fade-leave-to[data-v-564c1ac8] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
