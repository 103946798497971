import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["title", "tabindex"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex rounded-full border", {
      'is-ban border-rRed-600': $props.isBan,
      'hover:border-2 cursor-pointer focus:shadow-traffic-light': !$props.readOnly,
      'border-trans-200': !$props.isBan
    }]),
    title: $props.title,
    tabindex: $props.readOnly ? false : 0,
    style: _normalizeStyle({
      width: $options.dimension + 'px',
      height: $options.dimension + 'px',
      backgroundColor: $props.color
    }),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click');
    })
  }, [$props.isActive ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "check",
    width: "12",
    height: "12",
    class: "z-1 m-auto text-black stroke-2"
  })) : _createCommentVNode("v-if", true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_1);
}