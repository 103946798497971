import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import * as holidaysAPI from '@/api/admin/holidays';
import { toLocalDate } from '@/utils/date';
import { ModalDismissTypes } from '@/constants/ModalDismissTypes';
import { DateFormats } from '@/constants/DateFormats';
import { Colors } from '@/Colors';
export default {
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    InputField: InputField,
    Notification: Notification,
    Modal: Modal,
    DateTimePicker: DateTimePicker,
    FeatherIcon: FeatherIcon
  },
  data: function data() {
    return {
      items: [],
      color: Colors,
      showModal: false,
      selectedItemToDelete: '',
      showModalError: false,
      modalErrorMessage: '',
      creationDate: '',
      creationName: '',
      dateOptions: {
        format: DateFormats.DATE_FORMAT,
        showClear: false,
        widgetPositioning: {
          vertical: 'bottom',
          horizontal: 'auto'
        }
      },
      lockButton: false,
      notValidName: false,
      createListOfDeadlines: false,
      deadlinesList: [],
      showDismissButton: false,
      validateToRequest: true,
      tableCellClasses: 'px-3 py-2 border-b-2 border-slate-200 text-left break-words',
      translate: getTranslate['AdminNonWorkingDaysWell']()
    };
  },
  computed: {
    buttonOkText: function buttonOkText() {
      return this.createListOfDeadlines ? this.translate.yesMoveDeadlines : this.translate.create;
    },
    pastTenYearsFromNow: function pastTenYearsFromNow() {
      return new Date().setFullYear(new Date().getFullYear() - 10);
    },
    tenYearsFromNow: function tenYearsFromNow() {
      return new Date().setFullYear(new Date().getFullYear() + 10);
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      holidaysAPI.get().then(function (_ref) {
        var list = _ref.list;
        return _this.items = list;
      });
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
      this.creationName = '';
      this.creationDate = '';
      this.validateToRequest = true;
      this.createListOfDeadlines = false;
      this.showDismissButton = false;
      this.showModalError = false;
    },
    handleDismiss: function handleDismiss(pressedElement) {
      if (pressedElement === ModalDismissTypes.ICON) {
        this.toggleModal();
      } else if (pressedElement === ModalDismissTypes.BUTTON) {
        this.createNewItem();
      }
    },
    toggleModalError: function toggleModalError() {
      this.showModalError = !this.showModalError;
    },
    updateCreationName: function updateCreationName(name) {
      this.creationName = name;
    },
    updateHolidayDate: function updateHolidayDate(date) {
      if (this.createListOfDeadlines) {
        this.createListOfDeadlines = false;
        this.showDismissButton = false;
      }
      this.creationDate = this.getValidDate(date);
      this.validateToRequest = true;
    },
    isCreationNamevalid: function isCreationNamevalid() {
      var result = false;
      if (this.creationName.trim() !== '') {
        result = true;
      }
      return result;
    },
    createNewItem: function createNewItem() {
      var moveDeadlineControls = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (this.lockButton) {
        return;
      }
      if (!this.creationDate.length) {
        this.creationDate = this.getValidDate();
      }
      if (!this.isCreationNamevalid()) {
        this.handleCreateFailure({
          response: this.translate.enterTitle
        });
        this.notValidName = true;
        return;
      }
      var data = {
        name: this.creationName,
        holidayDate: this.creationDate,
        validate: this.validateToRequest,
        move: moveDeadlineControls
      };
      this.notValidName = false;
      this.lockButton = true;
      holidaysAPI.create(data).then(this.handleCreateSuccess).catch(this.handleCreateFailure);
    },
    handleCreateSuccess: function handleCreateSuccess(response) {
      this.lockButton = false;
      if (response.count) {
        this.handleCreatingDeadlinesList(response.list);
        return;
      }
      if (response.count === 0) {
        this.validateToRequest = false;
        this.createNewItem();
        return;
      }
      this.$notify({
        title: [this.translate.nonWorkingDayCreated, response.name]
      });
      this.items.push(response);
      this.showModalError = false;
      this.toggleModal();
    },
    handleCreatingDeadlinesList: function handleCreatingDeadlinesList(controls) {
      this.deadlinesList = controls;
      this.createListOfDeadlines = true;
      this.showDismissButton = true;
      this.validateToRequest = false;
    },
    getDeadlineTitle: function getDeadlineTitle(item) {
      return item.cpath + '. ' + item.title;
    },
    handleCreateFailure: function handleCreateFailure(error) {
      this.showModalError = true;
      this.modalErrorMessage = error.response;
      this.lockButton = false;
    },
    deleteItem: function deleteItem(item) {
      var _this2 = this;
      var id = item.id;
      holidaysAPI.remove(id).then(function (response) {
        return _this2.handleDeleteSuccess(response, id);
      }).catch(this.handleDeleteFailure);
    },
    handleDeleteSuccess: function handleDeleteSuccess(response, id) {
      if (response == 'OK') {
        var deletedItem = this.items.find(function (item) {
          return item.id === id;
        });
        this.$notify({
          title: [this.translate.nonWorkingDayDeleted, deletedItem.name]
        });
        this.items = this.items.filter(function (item) {
          return item.id != id;
        });
      }
      this.selectedItemToDelete = '';
    },
    handleDeleteFailure: function handleDeleteFailure(error) {
      this.selectedItemToDelete = '';
      this.$notify({
        title: error.error,
        type: 'error'
      });
    },
    disabledDate: function disabledDate(date) {
      return date < this.pastTenYearsFromNow || date > this.tenYearsFromNow;
    },
    getValidDate: function getValidDate(item) {
      if (!item) return toLocalDate(new Date());
      return toLocalDate(item);
    },
    handleDeleteNotification: function handleDeleteNotification(item) {
      var _this3 = this;
      this.selectedItemToDelete = item;
      this.$confirm(this.translate.deleteNonWorkingDay, "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(this.selectedItemToDelete.name, "?"), function (res) {
        return res ? _this3.deleteItem(_this3.selectedItemToDelete) : _this3.selectedItemToDelete = '';
      });
    }
  }
};