function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "repeatable-fields"
};
var _hoisted_2 = {
  class: "custom-fields-wrap"
};
var _hoisted_3 = {
  class: "cf-row-number"
};
var _hoisted_4 = {
  class: "flex flex-wrap"
};
var _hoisted_5 = {
  class: "cf-component-label flex"
};
var _hoisted_6 = ["onUpdate:modelValue", "placeholder", "maxlength", "disabled", "onFocus", "onInput"];
var _hoisted_7 = {
  key: 6
};
var _hoisted_8 = {
  key: 0,
  class: "cf-row-actions"
};
var _hoisted_9 = ["onClick"];
var _hoisted_10 = {
  key: 0,
  class: "mt-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_help_tool_tip = _resolveComponent("help-tool-tip");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_input_field_select = _resolveComponent("input-field-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.entityGroups, function (entity, rowNumber) {
    return _openBlock(), _createElementBlock("div", {
      key: rowNumber,
      class: "cf-row"
    }, [_createElementVNode("div", _hoisted_3, _toDisplayString(+rowNumber + 1), 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entity, function (field, key) {
      return _openBlock(), _createElementBlock("div", {
        key: key,
        class: _normalizeClass([_defineProperty({
          expanded: $data.currentTextarea === field
        }, 'field-type-' + field.typeId, true), "cf-component"])
      }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        truncate: false,
        title: field.label,
        type: "small",
        class: "mr-0.5"
      }, null, 8 /* PROPS */, ["title"]), field.explanatoryText ? (_openBlock(), _createBlock(_component_help_tool_tip, {
        key: 0,
        "react-on-click": true,
        class: "help-tool-tip self-center pb-1"
      }, {
        "tooltip-body": _withCtx(function () {
          return _toConsumableArray(_cache[2] || (_cache[2] = [_createElementVNode("div", {
            class: "w-4 h-4 border rounded-full border-blue-750 text-center leading-4 cursor-pointer"
          }, " i ", -1 /* HOISTED */)]));
        }),
        default: _withCtx(function () {
          return [_createElementVNode("span", null, _toDisplayString(field.explanatoryText), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true)]), field.typeId === $data.CustomFieldTypes.SELECT ? (_openBlock(), _createBlock(_component_single_select, {
        key: 0,
        options: _ctx.getSelectOptions(field.options),
        "model-value": field.selectedValue,
        placeholder: _ctx.defaultPlaceholderSelect,
        disabled: $props.disabled,
        "with-reset": true,
        "with-search": true,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.onChangeRepeatableField(field, $event, rowNumber);
        }
      }, null, 8 /* PROPS */, ["options", "model-value", "placeholder", "disabled", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.SELECT_MULTI ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 1,
        options: Object.values(field.options),
        placeholder: _ctx.defaultPlaceholderSelect,
        disabled: $props.disabled,
        "un-sorted": true,
        "selected-options": field.selectedValue || [],
        "label-key": "value",
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.onChangeRepeatableField(field, $event, rowNumber);
        }
      }, null, 8 /* PROPS */, ["options", "placeholder", "disabled", "selected-options", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TEXT_LINE ? (_openBlock(), _createBlock(_component_input_field, {
        key: 2,
        "model-value": field.selectedValue,
        enabled: !$props.disabled,
        placeholder: _ctx.defaultPlaceholder,
        type: "text",
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $options.onChangeRepeatableField(field, $event, rowNumber);
        }
      }, null, 8 /* PROPS */, ["model-value", "enabled", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TEXT_FIELD ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
        key: 3,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return field.selectedValue = $event;
        },
        placeholder: _ctx.defaultPlaceholder,
        maxlength: _ctx.maxlength,
        disabled: $props.disabled,
        class: _normalizeClass([{
          expanded: $data.currentTextarea === field
        }, "form-control repeatable-textarea"]),
        required: "",
        onFocus: function onFocus($event) {
          return $options.toggleTextareaSize(field);
        },
        onBlur: _cache[0] || (_cache[0] = function ($event) {
          return $options.toggleTextareaSize(null);
        }),
        onInput: function onInput($event) {
          return $options.onChangeRepeatableField(field, $event.target.value, rowNumber);
        }
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_6)), [[_vModelText, field.selectedValue]]) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.TRAFFIC_LIGHT ? (_openBlock(), _createBlock(_component_traffic_light_selector, {
        key: "".concat(rowNumber, "_").concat(field.selectedValue),
        modelValue: field.selectedValue,
        "onUpdate:modelValue": [function ($event) {
          return field.selectedValue = $event;
        }, function ($event) {
          return $options.onChangeRepeatableField(field, $event, rowNumber);
        }],
        "read-only": $props.disabled,
        class: "mb-2"
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "read-only"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.DATE_TIME ? (_openBlock(), _createBlock(_component_date_time_picker, {
        key: 5,
        disabled: $props.disabled,
        "model-value": field.selectedValue,
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return _ctx.onDateTimeChange(field, $event);
        }
      }, null, 8 /* PROPS */, ["disabled", "model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), field.typeId === $data.CustomFieldTypes.NUMERICAL ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_input_field_select, {
        type: "number",
        "input-value": field.selectedValue,
        "input-enabled": !$props.disabled,
        "input-placeholder": _ctx.defaultPlaceholder,
        options: _ctx.getNumericalOptions(field.unit),
        onInputChange: function onInputChange($event) {
          return $options.onChangeRepeatableField(field, $event, rowNumber);
        }
      }, null, 8 /* PROPS */, ["input-value", "input-enabled", "input-placeholder", "options", "onInputChange"])])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))]), !$props.disabled ? (_openBlock(), _createElementBlock("div", _hoisted_8, [entity ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: "cursor-pointer",
      onClick: function onClick($event) {
        return $options.onDelete(rowNumber, $event);
      }
    }, [_createVNode(_component_feather_icon, {
      icon: "x",
      class: "text-red-50 stroke-2 ml-4"
    })], 8 /* PROPS */, _hoisted_9)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))]), !$props.disabled ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("span", {
    class: "flex cursor-pointer",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.addRowToEntityGroups && $options.addRowToEntityGroups.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-blue inline-block stroke-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addRow), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]);
}