// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:checked + span[data-v-3939c397]:before {
  --tw-border-opacity: 1;
  border-color: rgb(33 130 253 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(33 130 253 / var(--tw-bg-opacity));
}
input:checked + span[data-v-3939c397]:after {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 242 / var(--tw-bg-opacity));
}
.radio-buttons-item span[data-v-3939c397] {
  padding: 0 0 0 calc(20px + 5px);
  min-height: 20px;
  line-height: 20px;
  position: relative;
  margin: 0px;
  cursor: pointer;
  font-weight: 700;
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(127 124 120 / var(--tw-text-opacity));
}
.radio-buttons-item span[data-v-3939c397]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(68 68 68 / var(--tw-border-opacity));
}
.radio-buttons-item span[data-v-3939c397]:after {
  content: "";
  position: absolute;
  top: calc(20px / 2);
  margin-top: calc(20px / -4);
  margin-left: calc(20px / -4);
  left: calc(20px / 2);
  width: calc(20px / 2);
  height: calc(20px / 2);
  border-radius: 100%;
  background: transparent;
  transition: all 0.3s ease;
}
.radio-buttons-item.disabled span[data-v-3939c397] {
  cursor: not-allowed;
}
.radio-buttons-item.disabled span[data-v-3939c397]:before {
  cursor: not-allowed;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
