import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex -mb-1.5px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [!$options.isERM ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_light_tab_item, {
    to: "".concat($options.baseLink, "risks")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.risks), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), _createVNode(_component_light_tab_item, {
    to: "".concat($options.baseLink, "threats")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.threats), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), $options.hasAssessmentThreats ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 0,
    to: "".concat($options.baseLink, "threatassessments")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.assessedThreats), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true), _createVNode(_component_light_tab_item, {
    to: "".concat($options.baseLink, "vulnerabilities")
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.vulnerabilities), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass({
      'p-4 border border-gray-450': !$options.isERM
    })
  }, [_createVNode(_component_router_view, {
    "project-id": $props.projectId
  }, null, 8 /* PROPS */, ["project-id"])], 2 /* CLASS */)]);
}