import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Teleport as _Teleport } from "vue";
var _hoisted_1 = {
  class: "search-modal-body ml-8"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0,
  class: "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_main_well = _resolveComponent("main-well");
  var _component_trap_focus_boundary = _resolveComponent("trap-focus-boundary");
  return _openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_component_trap_focus_boundary, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_main_well, {
        class: "search-modal-wrapper cursor-default overflow-auto text-black"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: _normalizeClass(['search-modal-header justify-between m-4', $data.oneLineItems])
          }, [_createVNode(_component_risma_title, {
            title: "".concat($data.translate.searchResults, " (").concat($options.searchResultLength, "):")
          }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
            class: "cursor-pointer",
            icon: "x",
            width: "30",
            height: "30",
            onClick: $options.emitToggle
          }, null, 8 /* PROPS */, ["onClick"])], 2 /* CLASS */), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.loopData, function (item, index) {
            return _openBlock(), _createElementBlock("div", {
              key: "list-wrapper-".concat(index),
              class: "list-wrapper"
            }, [$props.data[item.dataProperty] && $props.data[item.dataProperty].length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(), _createBlock(_resolveDynamicComponent(item.component), _mergeProps({
              "view-mode": "expanded",
              organisations: $props.organisations,
              "preview-mode": true,
              users: $props.users,
              ref_for: true
            }, item.props), null, 16 /* FULL_PROPS */, ["organisations", "users"]))])) : _createCommentVNode("v-if", true)]);
          }), 128 /* KEYED_FRAGMENT */))]), !$options.searchResultLength ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.translate.noResultsFoundYouMayWantToWidenYourSearch), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })]);
}