import emitter from 'tiny-emitter/instance'; //event bus

import { clickOutside } from '@/utils/directives/clickOutside';
import { generateTreeMenuItemList } from '@/utils/Compliance/TreeNodeEmits';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'TreeItemMenu',
  introduction: 'Tree dot menu for items in Tree.vue tree. ',
  token: '<tree-item-menu :node-id="1" :menu-items-settings="{}"/>',
  components: {
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    nodeId: {
      type: Number,
      required: true,
      note: 'Id of the tree node'
    },
    menuItemsSettings: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Settings for creating menu'
    },
    transFunction: {
      type: Function,
      required: false,
      default: null,
      note: 'translate function for vue tree, callstacktrace exceed (due a lot of bindings)'
    }
  },
  emits: ['actionClicked'],
  data: function data() {
    return {
      showMenu: false,
      showAddFromTemplateSubmenu: false
    };
  },
  computed: {
    rightTransFunction: function rightTransFunction() {
      return this.transFunction || this.$trans;
    },
    menuItems: function menuItems() {
      if (!this.menuItemsSettings || !Object.keys(this.menuItemsSettings)) return [];
      var list = generateTreeMenuItemList(this.menuItemsSettings, this.rightTransFunction);
      return list.filter(function (item) {
        return item.rights;
      });
    }
  },
  methods: {
    menuItemClicked: function menuItemClicked(action, data) {
      this.closeMenu();
      this.$emit('actionClicked', this.nodeId, action, data);
      emitter.emit('actionClicked', this.nodeId, action, data);
    },
    openMenu: function openMenu() {
      this.showMenu = true;
    },
    closeMenu: function closeMenu() {
      this.showMenu = false;
      this.showAddFromTemplateSubmenu = false;
    },
    toggleAddFromTemplateSubmenu: function toggleAddFromTemplateSubmenu() {
      this.showAddFromTemplateSubmenu = !this.showAddFromTemplateSubmenu;
    }
  }
};