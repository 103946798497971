import { Http } from '../index';
import { handleRequestResponse, objectToQueryString } from '../httpUtils';
export function getAllGapSchemasByProjectId(projectId) {
  var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var url = "/compliance/projects/".concat(projectId, "/gapschema").concat(query);
  return handleRequestResponse(Http.get(url));
}
export function getAllGapSchemasByGapSchemaType(gapSchemaType) {
  var includeArticleDetails = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var query = includeArticleDetails ? '&data[]=articleDetails' : '';
  var url = "/compliance/gapschema?gapSchemaType=".concat(gapSchemaType).concat(query);
  return handleRequestResponse(Http.get(url));
}
export function getGapSchemasTypes() {
  return handleRequestResponse(Http.get('/compliance/gapschematype'));
}
export function getAllGapSchemas() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get("/compliance/gapschema".concat(params ? "?".concat(params) : '')));
}
export function getGapSchemaById(id) {
  return handleRequestResponse(Http.get("/compliance/gapschema/".concat(id)));
}
export function updateGapSchema(gapSchemaId, data) {
  return handleRequestResponse(Http.patch("/compliance/gapschema/".concat(gapSchemaId), data));
}

/**
 * Copy from another gap schema
 *
 * @param gapSchemaId
 * @param title
 * @returns {Promise<T | string>}
 */
export function copyGapSchema(gapSchemaId, title) {
  var data = {
    title: title,
    copyFromGapSchemaId: gapSchemaId
  };
  return handleRequestResponse(Http.post('/compliance/gapschema', data));
}

/**
 * Delete gap schema - if possible
 *
 * @param gapSchemaId
 * @returns {Promise<T | string>}
 */
export function deleteGapSchema(gapSchemaId) {
  return handleRequestResponse(Http.delete("/compliance/gapschema/".concat(gapSchemaId)));
}
export function getAllArticlesByGapSchemaId(gapSchemaId) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var dataQuery = objectToQueryString({
    data: data
  });
  return handleRequestResponse(Http.get("/compliance/gapschema/".concat(gapSchemaId, "/gaparticle?").concat(dataQuery)));
}
export function updateGapArticles(gapSchemaId, gaparticleId, data) {
  return handleRequestResponse(Http.patch("/compliance/gapschema/".concat(gapSchemaId, "/gaparticle/").concat(gaparticleId), data));
}
export function createGapArticle(gapSchemaId, data) {
  return handleRequestResponse(Http.post("/compliance/gapschema/".concat(gapSchemaId, "/gaparticle"), data));
}
export function deleteGapArticle(gapSchemaId, gapArticleId) {
  return handleRequestResponse(Http.delete("/compliance/gapschema/".concat(gapSchemaId, "/gaparticle/").concat(gapArticleId)));
}
export function getAllDomains() {
  return handleRequestResponse(Http.get('/compliance/gapdomain'));
}
export function createDomain(data) {
  return handleRequestResponse(Http.post('/compliance/gapdomain', data));
}