function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useCountriesStore } from '@/Store/countriesStore';
import { useUserStore } from '@/Store/userStore';
import { getAllProcessors, updateByType } from '@/api/compliance/informationAssets';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { getCVRCompany } from '@/api/external/cvrapi';
import { getCompanyCurrencies as _getCompanyCurrencies } from '@/api/risma/data';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import CompanyLinkModal from '@/components/Molecules/Companies/CompanyLinkModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InformationAssetCreationModal from '@/components/Molecules/InformationAssets/InformationAssetCreationModal';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import CvrModal from '@/components/Molecules/Modal/CvrModal';
import Modal from '@/components/Molecules/Modal/Modal';
import CompanyWizardStepLEI from '@/components/Organisms/Companies/CompanyWizardStepLEI';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { LEI_INPUT_PROPS } from '@/constants/companies/companiesColumns';
import { CompanyWizardFields } from '@/constants/compliance/WizardFlow';
import { companyTypes, companyHierarchyTypes } from '@/constants/companies/ebaCompanies';
import { VatCountryCode } from '@/constants/admin/VatCountryCode';
import { VAT_STATUSES } from '@/constants/companies/vatStatuses';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { objTypeToHumanReadable, ObjectTypes, OBJ_TYPE_TO_MODULE } from '@/constants/ObjectTypes';
import { getCVRQueryParam } from '@/utils/format/companies';
import { generateComputed } from '@/utils/base/generateComputed';
import { isEmailValid, isValidVatNo as _isValidVatNo, isSupportedCountry } from '@/utils/validations';
import { compareStrings } from '@/utils/compare';
import { capitalize } from '@/utils';
export default {
  name: 'CompanyDescription',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    TinyMceBox: TinyMceBox,
    CompanyLinkModal: CompanyLinkModal,
    FeatherIcon: FeatherIcon,
    InformationAssetCreationModal: InformationAssetCreationModal,
    PreviewModalLink: PreviewModalLink,
    CvrModal: CvrModal,
    Modal: Modal,
    CompanyWizardStepLEI: CompanyWizardStepLEI,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    ButtonsRow: ButtonsRow,
    SingleSelect: SingleSelect,
    Checkboxes: Checkboxes,
    InputFieldSelect: InputFieldSelect,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Company object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged', 'validationError', 'refreshCompany'],
  data: function data() {
    return {
      isLoaded: false,
      showIALinkModal: false,
      showLeiSelectModal: false,
      showAssetCreationModal: false,
      leiNumberToSave: null,
      leiFailedToLoad: null,
      createAssetType: '',
      dataProcessors: [],
      selectedDataProcessorId: null,
      objTypeToHumanReadable: objTypeToHumanReadable,
      modalCVRData: {},
      modalCVRDefaultData: {
        name: '',
        address: '',
        phone: '',
        email: '',
        vatNo: '',
        lei: ''
      },
      companies: [],
      currencies: [],
      updatedAssetFromPreview: null,
      showCVRModal: false,
      translate: getTranslate['CompanyDescription'](),
      fieldMaxSymbols: 150,
      LEI_INPUT_PROPS: LEI_INPUT_PROPS,
      CompanyWizardFields: CompanyWizardFields
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, generateComputed(['address', 'zipCode', 'city', 'countryCode', 'phone', 'vatNo', 'lei', 'doraEnabled', 'ebaCompanyType', 'ebaCompanyHierarchy', 'ebaBranchCode', 'ebaCompetentAuthority', 'ebaTotalAssetsValue', 'ebaTotalAssetsCurrency', 'parentId', 'companyType'], {
    object: 'data',
    event: 'propertyChanged'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useActivityTypesStore, ['complianceIACustomActivityTypes'])), mapState(useCountriesStore, ['countries'])), mapState(useUserStore, ['currentUser'])), {}, {
    informationAssetsName: function informationAssetsName() {
      return this.getSettingValue('feature.information_assets_name') || this.$trans('Assets');
    },
    isDoraVisible: function isDoraVisible() {
      return this.getSettingValue('feature.company.dora_enable');
    },
    doraEnabledFlag: function doraEnabledFlag() {
      return this.doraEnabled ? [this.doraEnabled] : [];
    },
    parentOptions: function parentOptions() {
      var _this = this;
      return this.companies.filter(function (company) {
        return company.id !== _this.data.id && !company.parentId && company.vatStatus !== VAT_STATUSES.TERMINATED.value;
      }).map(function (company) {
        return {
          value: company.id,
          label: company.name
        };
      });
    },
    companyTypeOptions: function companyTypeOptions() {
      return [{
        value: 1,
        label: this.translate.branch
      }, {
        value: 2,
        label: this.translate.subsidiary
      }];
    },
    doraOptions: function doraOptions() {
      return [{
        value: 1,
        label: this.translate.includeInDora
      }];
    },
    countryCodeOptions: function countryCodeOptions() {
      return Object.values(VatCountryCode).map(function (_ref) {
        var value = _ref.value;
        return {
          value: value,
          label: value
        };
      });
    },
    ebaCurrencyOptions: function ebaCurrencyOptions() {
      return this.currencies.map(function (_ref2) {
        var currencyCode = _ref2.currencyCode;
        return {
          value: currencyCode,
          label: currencyCode
        };
      });
    },
    ebaCompanyTypeOptions: function ebaCompanyTypeOptions() {
      return Object.entries(companyTypes).map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          value = _ref4[0],
          label = _ref4[1];
        return {
          value: value,
          label: capitalize(label)
        };
      });
    },
    companyHierarchyOptions: function companyHierarchyOptions() {
      return Object.entries(companyHierarchyTypes).map(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
          value = _ref6[0],
          label = _ref6[1];
        return {
          value: value,
          label: capitalize(label)
        };
      });
    },
    hasIAModule: function hasIAModule() {
      return this.currentUser.iaAccess;
    },
    attachIATitle: function attachIATitle() {
      return this.translate.attach + ' ' + this.informationAssetsName;
    },
    isValidCompanyEmail: function isValidCompanyEmail() {
      if (!this.data.email) return true;
      return isEmailValid(this.data.email);
    },
    isValidVatNo: function isValidVatNo() {
      return _isValidVatNo(this.data.vatNo, this.countryCode);
    },
    isGetInfoDisabled: function isGetInfoDisabled() {
      return this.readOnly || !this.data.vatNo || !this.isValidVatNo || !isSupportedCountry(this.countryCode);
    },
    assets: function assets() {
      var _this2 = this;
      if (!this.data.connectedInformationAssets) return [];
      var result = [];
      Object.values(this.data.connectedInformationAssets || {}).forEach(function (assets) {
        var _result;
        if (!assets.length) return;
        (_result = result).push.apply(_result, _toConsumableArray(assets));
      });
      result = result.map(function (asset) {
        return _objectSpread(_objectSpread({}, asset), {}, {
          moduleTitle: _this2.getAssetModuleTitle(asset)
        });
      });
      return result.sort(function (a1, a2) {
        return compareStrings(a1.moduleTitle, a2.moduleTitle);
      });
    },
    isValidLEI: function isValidLEI() {
      return !this.lei || this.lei.length === 20;
    },
    buttonsRowData: function buttonsRowData() {
      var list = [{
        text: this.leiFailedToLoad ? this.translate.close : this.translate.cancel,
        type: 'util',
        clickEvent: this.onLeiSelectModalDismiss
      }];
      if (!this.leiFailedToLoad) {
        list.push({
          text: this.translate.save,
          type: 'save',
          clickEvent: this.onLeiSave
        });
      }
      return list;
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useCountriesStore, ['getCountries'])), {}, {
    getData: function getData() {
      var _this3 = this;
      this.isLoaded = false;
      return Promise.all([this.getProcessors(), this.getCountries(), this.getCompanyCurrencies(), this.getCompanies()]).finally(function () {
        return _this3.isLoaded = true;
      });
    },
    getCompanyCurrencies: function getCompanyCurrencies() {
      var _this4 = this;
      return _getCompanyCurrencies().then(function (_ref7) {
        var list = _ref7.list;
        return _this4.currencies = list;
      });
    },
    handleDoraChange: function handleDoraChange() {
      this.doraEnabled = !this.doraEnabled;
    },
    handleParentSelection: function handleParentSelection(value) {
      if (this.parentId === value) return;
      this.parentId = value;
      this.companyType = null;
    },
    getCompanies: function getCompanies() {
      var _this5 = this;
      return _getCompanies().then(function (_ref8) {
        var list = _ref8.list;
        return _this5.companies = list;
      });
    },
    getProcessors: function getProcessors() {
      var _this6 = this;
      return getAllProcessors().then(function (_ref9) {
        var list = _ref9.list;
        return _this6.dataProcessors = _this6.getDataProcessorsFlat(list);
      });
    },
    getDataProcessorsFlat: function getDataProcessorsFlat(processors) {
      return processors.reduce(function (list, processor) {
        var _processor$childrenOb;
        list.push(processor);
        if ((_processor$childrenOb = processor.childrenObjects) !== null && _processor$childrenOb !== void 0 && _processor$childrenOb.length) list.push.apply(list, _toConsumableArray(processor.childrenObjects));
        return list;
      }, []);
    },
    changeDescription: function changeDescription(value) {
      this.$emit('propertyChanged', {
        property: 'description',
        value: value
      });
    },
    updateEmail: function updateEmail(value) {
      this.$emit('propertyChanged', {
        property: 'email',
        value: value
      });
    },
    handleGetInfo: function handleGetInfo() {
      var _this7 = this;
      if (this.readOnly) return;
      var params = getCVRQueryParam(this.countryCode);
      return getCVRCompany(this.vatNo, params).then(function (_ref10) {
        var list = _ref10.list;
        return list[0];
      }).then(function (company) {
        _this7.modalCVRData = _objectSpread(_objectSpread(_objectSpread({}, _this7.modalCVRDefaultData), {}, {
          vatNo: _this7.vatNo,
          zipCode: company.zipcode
        }, company), {}, {
          lei: company.lei || _this7.data.lei
        });
        _this7.showCVRModal = true;
      }).catch(function () {
        _this7.$emit('validationError', 'error', 'No results found! Did you enter valid VAT number?');
      });
    },
    populateInfoFromCVR: function populateInfoFromCVR(info) {
      var _this8 = this;
      var fields = ['name', 'address', 'zipCode', 'city', 'phone', 'email', 'lei'];
      fields.forEach(function (field) {
        if (!info[field]) return;
        _this8.$emit('propertyChanged', {
          property: field,
          value: info[field]
        });
      });
    },
    onShowAssetCreationModal: function onShowAssetCreationModal(assetType) {
      this.createAssetType = assetType;
      this.showIALinkModal = false;
      this.showAssetCreationModal = true;
    },
    dismissModal: function dismissModal() {
      this.selectedDataProcessorId = null;
      this.showIALinkModal = false;
    },
    onNewAssetCreated: function onNewAssetCreated(data) {
      var _this9 = this;
      this.showAssetCreationModal = false;
      this.updateCompanyLinkToInformationAssets(this.createAssetType, [data.id], this.data.id).then(function () {
        _this9.$emit('refreshCompany');
      });
    },
    unlinkAssetFromPreviewList: function unlinkAssetFromPreviewList(data) {
      var _this10 = this;
      var assetType = this.getAssetTypeByObjectType(data.objType);
      return updateByType(assetType, data.id, {
        companyId: null
      }).then(function (data) {
        _this10.$emit('refreshCompany');
        return data;
      });
    },
    updateCompanyForIAByType: function updateCompanyForIAByType(type, assetId, companyId) {
      return updateByType(type, assetId, {
        companyId: companyId
      });
    },
    updateCompanyLinkToInformationAssets: function updateCompanyLinkToInformationAssets(assetType, assetsIds, companyId) {
      var _this11 = this;
      var promises = [];
      assetsIds.forEach(function (assetId) {
        promises.push(_this11.updateCompanyForIAByType(assetType, assetId, companyId));
      });
      return Promise.all(promises);
    },
    onUpdateIALinks: function onUpdateIALinks(_ref11) {
      var _this12 = this;
      var assetType = _ref11.assetType,
        assetsIds = _ref11.assetsIds,
        module = _ref11.module,
        activityTypeId = _ref11.activityTypeId;
      var idsToLink = [];
      var idsToDelink = [];
      var promises = [];
      var linkedItems = this.data.connectedInformationAssets[module] || [];
      var linkedItemsIds = [];
      if (activityTypeId) {
        linkedItemsIds = linkedItems.filter(function (item) {
          return +item.activityTypeId === +activityTypeId;
        }).map(function (item) {
          return item.id;
        });
      } else {
        linkedItemsIds = linkedItems.map(function (item) {
          return item.id;
        });
      }
      linkedItemsIds.forEach(function (linkedId) {
        if (!assetsIds.includes(linkedId)) {
          idsToDelink.push(linkedId);
        }
      });
      assetsIds.forEach(function (assetId) {
        if (!linkedItemsIds.includes(assetId)) {
          idsToLink.push(assetId);
        }
      });
      if (idsToLink && idsToLink.length) {
        promises.push(this.updateCompanyLinkToInformationAssets(assetType, idsToLink, this.data.id));
      }
      if (idsToDelink && idsToDelink.length) {
        promises.push(this.updateCompanyLinkToInformationAssets(assetType, idsToDelink, null));
      }
      return Promise.all(promises).then(function () {
        if (idsToLink.length) {
          _this12.$notify({
            title: _this12.translate.companyHasBeenLinked
          });
        }
        _this12.dismissModal();
        _this12.$emit('refreshCompany');
      });
    },
    updateAsset: function updateAsset() {
      var _this13 = this;
      if (!this.updatedAssetFromPreview) return;
      var updatedAssetModule = OBJ_TYPE_TO_MODULE[this.updatedAssetFromPreview.objType];
      var assets = this.data.connectedInformationAssets[updatedAssetModule];
      var idx = assets.findIndex(function (asset) {
        return asset.id === _this13.updatedAssetFromPreview.id;
      });
      if (idx !== -1) {
        if (this.updatedAssetFromPreview.companyId !== this.data.id) {
          this.data.connectedInformationAssets[updatedAssetModule].splice(idx, 1); // if asset was unlinked in preview
        } else {
          this.data.connectedInformationAssets[updatedAssetModule][idx] = this.updatedAssetFromPreview;
        }
      }
      this.updatedAssetFromPreview = null;
    },
    resetCVRModal: function resetCVRModal() {
      this.showCVRModal = false;
      this.modalCVRData = _objectSpread({}, this.modalCVRDefaultData);
    },
    onCVRModalSubmit: function onCVRModalSubmit() {
      this.populateInfoFromCVR(this.modalCVRData);
      this.resetCVRModal();
    },
    getAssetTypeByObjectType: function getAssetTypeByObjectType(objType) {
      if (objType.startsWith(ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR)) {
        return AssetsTypes.PROCESSORS;
      }
      if (objType.startsWith(ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER)) {
        return AssetsTypes.CONTROLLERS;
      }
      if (objType.startsWith(ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM)) {
        return AssetsTypes.SYSTEMS;
      }
      if (objType.startsWith(ObjectTypes.INFORMATION_ASSET)) {
        return AssetsTypes.INFORMATION_ASSETS_API;
      }
      return null;
    },
    getAssetModuleTitle: function getAssetModuleTitle(asset) {
      if (asset.objType === ObjectTypes.INFORMATION_ASSET && asset.activityTypeId) {
        var _this$complianceIACus;
        return ((_this$complianceIACus = this.complianceIACustomActivityTypes.find(function (type) {
          return type.id === asset.activityTypeId;
        })) === null || _this$complianceIACus === void 0 ? void 0 : _this$complianceIACus.label) || '';
      }
      return this.$trans(objTypeToHumanReadable[asset.objType]);
    },
    onLeiSelectModalDismiss: function onLeiSelectModalDismiss() {
      this.showLeiSelectModal = false;
      this.leiNumberToSave = null;
      this.leiFailedToLoad = null;
    },
    onLeiSave: function onLeiSave() {
      if (this.leiNumberToSave) {
        this.lei = this.leiNumberToSave;
      }
      this.showLeiSelectModal = false;
      this.leiFailedToLoad = null;
    },
    onLeiUpdated: function onLeiUpdated(_ref12) {
      var field = _ref12.field,
        value = _ref12.value;
      if (field === CompanyWizardFields.LEI) {
        this.leiNumberToSave = value;
      }
      if (field === CompanyWizardFields.FOUND_LEI) {
        this.leiFailedToLoad = !value.leiList.length;
      }
    }
  })
};