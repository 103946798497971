function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import cloneDeep from 'lodash/cloneDeep';
import { getValueOfCustomField, getColumnLabelName, getUniqueCustomFieldsForActivityTypes } from '@/utils/CustomFields';
import RismaDataSetHelperClass from '@/components/Atoms/RismaDatatable/RismaDataSetHelperClass';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { capitalize } from '@/utils';
import { isHexColor } from '@/utils/regex';
export var RismaDatatableDefaultMixin = {
  data: function data() {
    return {
      datasetSlice: [],
      datasetSliceTotal: [],
      datasetHelper: {},
      pageLength: 10,
      usePagination: true,
      fuseSearchOptions: {
        threshold: 0.2,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [],
        ignoreLocation: true
      },
      hasBackendSorting: false,
      customUnitPrefix: 'Unit',
      fieldTypeIdsAvoidCustomFilter: [CustomFieldTypes.TEXT_LINE, CustomFieldTypes.TEXT_FIELD]
    };
  },
  emits: ['backendSort'],
  computed: {
    trafficLightRismaDatatableFields: function trafficLightRismaDatatableFields() {
      var _this = this;
      if (!this.dataset || !this.dataset.length) return [];
      var list = ['trafficLight', 'severity', 'completedEfficiency'];
      Object.keys(this.dataset[0]).forEach(function (item) {
        var _this$dataset$0$item;
        if (((_this$dataset$0$item = _this.dataset[0][item]) === null || _this$dataset$0$item === void 0 ? void 0 : _this$dataset$0$item.fieldType) === CustomFieldTypes.TRAFFIC_LIGHT) {
          list.push(item);
        }
      });
      return list;
    },
    dateRangeReportOptions: function dateRangeReportOptions() {
      return {
        dateRange: [this.initRange.start, this.initRange.end]
      };
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLabelForModuleByColor', 'getTrafficLightLegendForModule'])), {}, {
    prepareFilterForTrafficLight: function prepareFilterForTrafficLight(value, columnKey) {
      var trafficLightKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var moduleName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var trafficLightFields = trafficLightKeys !== null && trafficLightKeys !== void 0 ? trafficLightKeys : this.trafficLightRismaDatatableFields;
      var result = value;
      if (trafficLightFields.includes(columnKey) && value) {
        if (isHexColor(value)) {
          result = this.$trans(capitalize(this.getTrafficLabelForModuleByColor(moduleName, value)));
        }
        if (!isNaN(+value)) {
          result = this.$trans(capitalize(this.getTrafficLightLegendForModule(moduleName, value)));
        }
      }
      return result;
    },
    enhanceDataset: function enhanceDataset(dataset, columns) {
      this.extendDatasetWithLabels(dataset, columns);
      this.fuseSearchOptions.keys = this.prepareFuseKeys(columns);
      this.prepareNestedOptions();
      if (!this.usePagination) {
        this.pageLength = this.dataset.length;
      }
      this.datasetHelper = new RismaDataSetHelperClass(dataset, this.fuseSearchOptions).pagesize(this.pageLength);
      this.datasetSlice = this.datasetHelper.data;
      this.datasetSliceTotal = _toConsumableArray(dataset);
    },
    prepareNestedOptions: function prepareNestedOptions() {
      var _this$fuseSearchOptio,
        _this2 = this;
      if (!((_this$fuseSearchOptio = this.fuseSearchOptions) !== null && _this$fuseSearchOptio !== void 0 && (_this$fuseSearchOptio = _this$fuseSearchOptio.nested) !== null && _this$fuseSearchOptio !== void 0 && _this$fuseSearchOptio.length)) return;
      this.fuseSearchOptions.nested.forEach(function (item, index) {
        var optionsClone = cloneDeep(_this2.fuseSearchOptions);
        delete optionsClone['nested'];
        delete optionsClone['keys'];
        _this2.fuseSearchOptions.nested[index] = _objectSpread(_objectSpread(_objectSpread({}, optionsClone), item.options), {}, {
          keys: _this2.prepareFuseKeys(item.keys)
        });
        _this2.fuseSearchOptions.keys = _this2.fuseSearchOptions.keys.filter(function (key) {
          return !_this2.fuseSearchOptions.nested[index].keys.includes(key);
        });
      });
    },
    onPageChange: function onPageChange(indexes) {
      this.datasetSlice = this.datasetHelper.page(indexes.currentPage).data;
      if (typeof this.afterOnStateChanged === 'function') {
        this.afterOnStateChanged();
      }
    },
    onStateChanged: function onStateChanged(data) {
      var value = '';
      if (!data.state) {
        return;
      }
      if (data.state.search && data.state.search.search) {
        value = data.state.search.search.trim();
      }

      // This more actual for Reports (when prepareDataTable waits before ajax complete)
      if (!this.datasetHelper.pagesize) {
        return;
      }
      this.datasetSlice = this.datasetHelper.pagesize(data.state.pageLength).page(1).columns(data.state.columns).search(value).resetSortBy() // Support only one sort per time ?
      .sortBy(data.state.columns, this.hasBackendSorting).data;
      this.pageLength = data.state.pageLength;
      this.datasetSliceTotal = this.datasetHelper.dataTotal;
      if (typeof this.afterOnStateChanged === 'function') {
        this.afterOnStateChanged();
      }
    },
    backendSort: function backendSort(state) {
      this.firstInit = false;
      this.$emit('backendSort', state);
    },
    prepareFuseKeys: function prepareFuseKeys(columns) {
      var _ref;
      var result = columns;
      if (!Array.isArray(columns)) result = Object.keys(columns);
      result = result.map(function (item) {
        var updatedItem = item.replace(/\./g, '-');
        return ["".concat(updatedItem, ".field"), "".concat(updatedItem, ".fieldText")];
      });
      return (_ref = []).concat.apply(_ref, _toConsumableArray(result));
    },
    extendDatasetWithLabels: function extendDatasetWithLabels(data, columns) {
      return data.map(function (item) {
        for (var key in item) {
          if (!item[key]) continue;
          item[key].label = columns[key];
        }
      });
    },
    getPropertiesWithCustomFields: function getPropertiesWithCustomFields(entities, activityTypes) {
      var _this3 = this;
      var uniqueCfMap = getUniqueCustomFieldsForActivityTypes(activityTypes);
      var cfColumns = {};
      var cfDisabledColumns = [];
      var cfByEntities = {};
      Object.entries(uniqueCfMap).forEach(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          cfKey = _ref3[0],
          cfValue = _ref3[1];
        cfColumns[cfKey] = cfValue.label;
        if (_this3.fieldTypeIdsAvoidCustomFilter.includes(cfValue.typeId)) {
          cfDisabledColumns.push(cfKey);
        }
      });
      entities.forEach(function (entity) {
        var cfByEntity = {};
        Object.entries(uniqueCfMap).forEach(function (_ref4) {
          var _ref5 = _slicedToArray(_ref4, 2),
            cfKey = _ref5[0],
            cf = _ref5[1];
          cfByEntity[cfKey] = _this3.prepareCfForEntity(cf, entity.customFieldValues);
        });
        cfByEntities[entity.id] = cfByEntity;
      });
      return {
        cfByEntities: cfByEntities,
        cfColumns: cfColumns,
        cfDisabledColumns: cfDisabledColumns
      };
    },
    prepareCfForEntity: function prepareCfForEntity(cf, cfValues) {
      var cfValueByField = cf.typeId !== CustomFieldTypes.SELECT_MULTI ? cfValues.find(function (_ref6) {
        var fieldId = _ref6.fieldId;
        return fieldId === cf.id;
      }) : cfValues.filter(function (_ref7) {
        var fieldId = _ref7.fieldId;
        return fieldId === cf.id;
      });
      if (cfValueByField !== null && cfValueByField !== void 0 && cfValueByField.value || cfValueByField !== null && cfValueByField !== void 0 && cfValueByField.length) {
        return _objectSpread({
          label: cf.label,
          fieldType: cf.typeId
        }, getValueOfCustomField(cf, cfValueByField));
      }
      return {
        label: cf.label,
        fieldType: cf.typeId,
        field: ''
      };
    },
    prepareCustomFieldValue: function prepareCustomFieldValue(valuesToChange, customFieldValues, field) {
      var fieldLabel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var hasRowGroupActivated = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var customFieldLabel = fieldLabel || getColumnLabelName(field.id);
      var cfValueByField = field.typeId !== CustomFieldTypes.SELECT_MULTI ? customFieldValues.find(function (_ref8) {
        var fieldId = _ref8.fieldId;
        return fieldId === field.id;
      }) : customFieldValues.filter(function (_ref9) {
        var fieldId = _ref9.fieldId;
        return fieldId === field.id;
      });
      var valueOfCustomField = cfValueByField !== null && cfValueByField !== void 0 && cfValueByField.value || cfValueByField !== null && cfValueByField !== void 0 && cfValueByField.rows || cfValueByField !== null && cfValueByField !== void 0 && cfValueByField.length ? getValueOfCustomField(field, cfValueByField, hasRowGroupActivated) : {
        fieldType: field.fieldType,
        field: ''
      };
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        var unitLabel = fieldLabel ? fieldLabel + this.customUnitPrefix.toLowerCase() : getColumnLabelName(field.id + this.customUnitPrefix);
        valuesToChange[customFieldLabel] = {
          field: valueOfCustomField.field
        };
        valuesToChange[unitLabel] = {
          field: valueOfCustomField.unit
        };
      } else {
        valuesToChange[customFieldLabel] = valueOfCustomField;
      }
      return valueOfCustomField;
    },
    prepareListField: function prepareListField(attributes) {
      var result = '';
      attributes.forEach(function (attr) {
        result += "<p class='mb-2'>".concat(attr, "</p>");
      });
      return {
        field: result
      };
    }
  })
};