import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex mb-1"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "max-w-350px my-2"
};
var _hoisted_4 = {
  class: "w-full"
};
var _hoisted_5 = {
  class: "table-cell"
};
var _hoisted_6 = {
  class: "table-cell"
};
var _hoisted_7 = {
  class: "table-cell"
};
var _hoisted_8 = {
  class: "table-cell"
};
var _hoisted_9 = {
  class: "table-cell"
};
var _hoisted_10 = ["href", "title"];
var _hoisted_11 = {
  class: "table-cell"
};
var _hoisted_12 = ["onClick"];
var _hoisted_13 = {
  class: "table-cell"
};
var _hoisted_14 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_admin_activity_types_modal = _resolveComponent("admin-activity-types-modal");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full"
  }, {
    default: _withCtx(function () {
      var _$data$selectedModule;
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.activityTypes
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "plus",
        height: "35",
        width: "35",
        class: "ml-auto stroke-3 text-blue-350 cursor-pointer",
        onClick: $options.createActivityType
      }, null, 8 /* PROPS */, ["onClick"])]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_select, {
        title: $data.translate.filterByModule,
        options: $options.modulesOptions,
        "selected-options": $data.selectedModules,
        "max-visible-tags": -1,
        placeholder: $data.translate.noFiltersApplied,
        "label-key": "label",
        onSelected: _cache[0] || (_cache[0] = function ($event) {
          return $data.selectedModules = $event;
        })
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])]), _createElementVNode("table", _hoisted_4, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_5, _toDisplayString($data.translate.title), 1 /* TEXT */), _createElementVNode("th", _hoisted_6, _toDisplayString($data.translate.module), 1 /* TEXT */), _cache[1] || (_cache[1] = _createElementVNode("th", {
        class: "table-cell",
        width: "20"
      }, null, -1 /* HOISTED */)), _cache[2] || (_cache[2] = _createElementVNode("th", {
        class: "table-cell",
        width: "20"
      }, null, -1 /* HOISTED */)), _cache[3] || (_cache[3] = _createElementVNode("th", {
        class: "table-cell",
        width: "20"
      }, null, -1 /* HOISTED */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredActivities, function (activity) {
        return _openBlock(), _createElementBlock("tr", {
          key: activity.id
        }, [_createElementVNode("td", _hoisted_7, _toDisplayString(activity.label), 1 /* TEXT */), _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.moduleTitles[activity.module]), 1 /* TEXT */), _createElementVNode("td", _hoisted_9, [_createElementVNode("a", {
          href: "/api/2.0/admin/activitytype/download/".concat(activity.id),
          title: $data.translate.download,
          download: ""
        }, [_createVNode(_component_feather_icon, {
          icon: "download",
          class: "stroke-2 text-rm-text"
        })], 8 /* PROPS */, _hoisted_10)]), _createElementVNode("td", _hoisted_11, [_createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.editActivityType(activity.id);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          icon: "edit",
          class: "stroke-2 text-blue-350"
        })], 8 /* PROPS */, _hoisted_12)]), _createElementVNode("td", _hoisted_13, [!activity.inUse ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _withModifiers(function ($event) {
            return $options.confirmDeletionActivityType(activity.id);
          }, ["prevent"])
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "stroke-2 text-red-50"
        })], 8 /* PROPS */, _hoisted_14)) : _createCommentVNode("v-if", true)])]);
      }), 128 /* KEYED_FRAGMENT */))])])])), $data.showModal ? (_openBlock(), _createBlock(_component_admin_activity_types_modal, {
        key: 2,
        data: $data.modalData,
        "activity-types": $options.activities,
        fields: $data.fields,
        module: ((_$data$selectedModule = $data.selectedModules[0]) === null || _$data$selectedModule === void 0 ? void 0 : _$data$selectedModule.id) || null,
        "is-edit-mode": $data.isEditMode,
        onCreated: $options.onCreated,
        onEdited: $options.onEdit,
        onToggle: $options.resetModal
      }, null, 8 /* PROPS */, ["data", "activity-types", "fields", "module", "is-edit-mode", "onCreated", "onEdited", "onToggle"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}