function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { MODULES } from '@/constants/modules';
export var UserLevels = {
  NONE: 0,
  NORMAL: 1,
  PRIVILEGED: 2,
  SUPER: 3,
  SUPERVISOR: '3++',
  ADMIN: 4
};
export var LockLevels = {
  LOCKED: 1,
  UNLOCKED: 0
};
export var UserLevelsByValue = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, UserLevels.NONE, 'None'), UserLevels.NORMAL, 'Normal'), UserLevels.PRIVILEGED, 'Privileged'), UserLevels.SUPER, 'Super'), UserLevels.ADMIN, 'Admin');
export var UserAccessFieldByModule = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.EXECUTION, 'level_execution'), MODULES.CONTROLS, 'level_controls'), MODULES.COMPLIANCE_PROCESS_TREE, 'level_process_library'), MODULES.COMPLIANCE_IA_SYSTEM, 'level_information_assets'), MODULES.COMPLIANCE_IA_CONTROLLER, 'level_information_assets'), MODULES.COMPLIANCE_IA_PROCESSOR, 'level_information_assets'), MODULES.RISK, 'level_risk'), MODULES.INCIDENTS, 'level_incidents'), MODULES.COMPLIANCE_INFORMATION_ASSETS, 'level_information_assets');