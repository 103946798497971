function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { focus } from '@/utils/directives/focus';
import { Colors } from '@/Colors';
import { RiskAdminPages } from '@/constants/risks/RiskAdminPages';
export default {
  name: 'RiskAdminModal',
  introduction: 'Risk Admin Modal',
  description: 'Admin modal component (mass import for threat or vulnerabilities))',
  token: '<risk-admin-modal></risk-admin-modal>',
  directives: {
    focus: focus
  },
  components: {
    RismaButton: RismaButton,
    Modal: Modal
  },
  props: {
    pageName: {
      type: String,
      required: true,
      note: 'threat or vulnerability'
    }
  },
  emits: ['created'],
  data: function data() {
    return {
      colors: Colors,
      showModal: false,
      showButtons: false,
      rowData: '',
      rows: 20,
      cols: 50,
      validation: null,
      translate: getTranslate['RiskAdminModal']()
    };
  },
  computed: {
    modalHeaderTitle: function modalHeaderTitle() {
      return this.pageName === RiskAdminPages.THREAT ? this.translate.importThreats : this.translate.importVulnerabilities;
    },
    modalHeaderDescription: function modalHeaderDescription() {
      if (this.pageName === RiskAdminPages.THREAT) {
        return this.translate.youCanMassImportAListIntoTheBelowFieldThreats;
      } else {
        return this.translate.youCanMassImportAListIntoTheBelowFieldVulnerabilities;
      }
    }
  },
  methods: {
    beforeOpen: function beforeOpen() {
      this.rowData = '';
      this.resetValidation();
    },
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    cancelCreation: function cancelCreation() {
      this.toggleModal();
    },
    onInput: function onInput() {
      if (this.validation) {
        this.validation = null;
      }
    },
    create: function create() {
      var _this = this;
      var data = this.purifyData(this.rowData.split('\n'));
      data = data.map(function (item) {
        if (_this.pageName === RiskAdminPages.THREAT && item.includes(' > ')) {
          var _item$split = item.split(' > '),
            _item$split2 = _slicedToArray(_item$split, 2),
            category = _item$split2[0],
            title = _item$split2[1];
          return {
            category: category,
            title: title
          };
        }
        return {
          title: item
        };
      });
      if (this.validate(data)) {
        this.$emit('created', data);
        this.toggleModal();
      }
    },
    purifyData: function purifyData(data) {
      var result = _toConsumableArray(data).map(function (item) {
        return item.trim();
      });
      result = result.filter(function (item) {
        return item;
      });
      return Array.from(new Set(result));
    },
    resetValidation: function resetValidation() {
      this.validation = null;
    },
    validate: function validate(data) {
      var result = true;
      this.resetValidation();
      if (!data.length) {
        result = false;
        this.validation = this.translate.pleaseFillOutThisField;
      }
      return result;
    }
  }
};