// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaps-select[data-v-3889ef88]  .multiselect__tag {
  color: #FFFFFF;
}
.gaps-select[data-v-3889ef88]  .multiselect__placeholder {
  color: inherit;
}
.gaps-select[data-v-3889ef88]  ul > li > span,
.gaps-select[data-v-3889ef88]  ul > li > span:after {
  color: #FFFFFF;
  background-color: transparent;
}
.gaps-select[data-v-3889ef88]  ul > li > span.multiselect__option {
  padding: 0;
}
.view-item[data-v-3889ef88] {
  width: 22px;
  height: 22px;
}
.view-item.view-item-compact[data-v-3889ef88] {
  background-image: url("/assets/svg/compact_passive.svg");
}
.view-item.view-item-compact[data-v-3889ef88]:hover, .view-item.view-item-compact.active[data-v-3889ef88] {
  background-image: url("/assets/svg/compact_active.svg");
}
.view-item.view-item-expanded[data-v-3889ef88] {
  background-image: url("/assets/svg/expanded_passive.svg");
}
.view-item.view-item-expanded[data-v-3889ef88]:hover, .view-item.view-item-expanded.active[data-v-3889ef88] {
  background-image: url("/assets/svg/expanded_active.svg");
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
