import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "inline-block align-middle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.downloadFile && $options.downloadFile.apply($options, arguments);
    })
  }, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createVNode(_component_feather_icon, {
      class: _normalizeClass(!$props.disabled ? 'rm-icon' : 'rm-icon-disabled'),
      width: "24",
      height: "24",
      icon: "download"
    }, null, 8 /* PROPS */, ["class"])];
  })]))]);
}