function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import DelegationModal from '@/components/Molecules/DelegationModal';
import DelegationList from '@/components/Atoms/DelegationList/DelegationList';
export default {
  name: 'FrameworkIdRelationDetails',
  components: {
    DelegationModal: DelegationModal,
    DelegationList: DelegationList
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      showModal: false,
      translate: {
        frameworkId: this.$trans('Framework')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, {
    frameworkIds: 'frameworks',
    enabledFrameworkIds: 'enabledFrameworks'
  })), {}, {
    modalSettings: function modalSettings() {
      return DelegationModalSettings[DelegationTypes.solutionId];
    },
    convertedSelectedFrameworkIds: function convertedSelectedFrameworkIds() {
      var _this = this;
      if (!this.frameworkIds || !this.frameworkIds.length) return [];
      var result = [];
      this.list.forEach(function (id) {
        var framework = _this.frameworkIds.find(function (item) {
          return item.id === id;
        });
        if (framework) {
          result.push({
            id: id,
            title: framework.displayName
          });
        }
      });
      return result;
    },
    isEnabled: function isEnabled() {
      return this.getSettingValue('feature.performance_report_enabled') > 0;
    }
  }),
  methods: {
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    editDelegationItem: function editDelegationItem() {
      this.showModal = true;
    },
    onApplyChanges: function onApplyChanges(value) {
      this.$emit('propertyChanged', {
        property: 'solutionIds',
        value: value
      });
      this.showModal = false;
    }
  }
};