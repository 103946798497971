import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 0,
  class: "border-separate"
};
var _hoisted_3 = {
  class: "my-4"
};
var _hoisted_4 = {
  class: "text-center"
};
var _hoisted_5 = {
  class: "text-center"
};
var _hoisted_6 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_notification = _resolveComponent("notification");
  var _component_modal = _resolveComponent("modal");
  return $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.liaAndTiaSettings
  }, null, 8 /* PROPS */, ["title"]), $data.settingRows.length ? (_openBlock(), _createElementBlock("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (item) {
    return _openBlock(), _createElementBlock("th", {
      key: "col-".concat(item),
      class: "px-4"
    }, [_createVNode(_component_risma_title, {
      title: item,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.settingRows, function (item, idx) {
    return _openBlock(), _createElementBlock("tr", {
      key: "lia-tia-generic-row-".concat(item.type)
    }, [_createElementVNode("td", _hoisted_4, [_createVNode(_component_feather_icon, {
      class: "text-red-50 cursor-pointer stroke-2",
      icon: "x",
      onClick: function onClick($event) {
        return $options.deleteRow(idx);
      }
    }, null, 8 /* PROPS */, ["onClick"])]), _createElementVNode("td", _hoisted_5, _toDisplayString(item.title), 1 /* TEXT */), _createElementVNode("td", null, [_createVNode(_component_risma_toggle, {
      "model-value": item.enabled,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.checkEquality(item.enabled, $event, function (newValue) {
          return $options.onEnabledChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])]), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      "model-value": item.questionnaireId,
      options: $options.questionnaires[item.projectType],
      "un-sorted": true,
      placeholder: $data.translate.selectOne,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.checkEquality(item.questionnaireId, $event, function (newValue) {
          return $options.onQuestionnaireChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("td", {
      style: _normalizeStyle($data.tdStyles)
    }, [_createVNode(_component_single_select, {
      "model-value": item.questionId,
      options: item.questionOptionsSelect,
      disabled: !item.questionnaireId,
      "un-sorted": true,
      placeholder: $data.translate.selectOne,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.checkEquality(item.questionId, $event, function (newValue) {
          return $options.onQuestionChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "placeholder", "onUpdate:modelValue"])], 4 /* STYLE */), _createElementVNode("td", {
      style: _normalizeStyle($data.tdStyles)
    }, [_createVNode(_component_risma_select, {
      options: item.questionAnswers || [],
      "selected-options": item.questionAnswersSelected,
      placeholder: $data.translate.select,
      disabled: !item.questionId,
      "label-key": "label",
      onSelected: function onSelected($event) {
        return $options.checkEquality(item.questionAnswersSelected, $event, function (newValue) {
          return $options.onQuestionAnswerChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["options", "selected-options", "placeholder", "disabled", "onSelected"])], 4 /* STYLE */), _createElementVNode("td", {
      style: _normalizeStyle($data.tdStyles)
    }, [_createVNode(_component_single_select, {
      "model-value": item.gapSchemaId,
      options: $data.allGapSchemas[item.projectType],
      disabled: !$data.allGapSchemas[item.projectType],
      "un-sorted": true,
      placeholder: $data.allGapSchemas[item.projectType] ? $data.translate.selectOne : $data.translate.noGapSchemaAvailable,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.checkEquality(item.gapSchemaId, $event, function (newValue) {
          return $options.onGapSchemaChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "placeholder", "onUpdate:modelValue"])], 4 /* STYLE */), _createElementVNode("td", {
      style: _normalizeStyle($data.tdStyles)
    }, [_createVNode(_component_single_select, {
      "model-value": item.gapArticleId,
      options: item.gapArticleOptions,
      disabled: !item.gapSchemaId,
      "un-sorted": true,
      placeholder: item.gapSchemaId ? $data.translate.selectOne : $data.translate.noGapSchemaAvailable,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.checkEquality(item.gapArticleId, $event, function (newValue) {
          return $options.onGapArticleChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "placeholder", "onUpdate:modelValue"])], 4 /* STYLE */), _createElementVNode("td", null, [_createVNode(_component_single_select, {
      "model-value": item.assessmentQuestionnaireId,
      options: $options.questionnaires[item.projectType],
      "un-sorted": true,
      placeholder: $data.translate.selectOne,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.checkEquality(item.assessmentQuestionnaireId, $event, function (newValue) {
          return $options.onAssessmentChange(newValue, idx);
        });
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "placeholder", "onUpdate:modelValue"])])]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), $options.typesAvailableForCreation.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_button, {
    text: $data.translate.newSetting,
    type: "util",
    class: "mt-4",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.showCreationModal = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "cursor-pointer mr-2 stroke-2 text-blue-750"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"])])) : _createCommentVNode("v-if", true), $data.showCreationModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 2,
    type: "large",
    header: $data.translate.createNewLiatiaSetting,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    onAccept: $options.createNewSetting,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showCreationModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.createSettingErrors ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("p", null, _toDisplayString($data.createSettingErrors), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_createVNode(_component_risma_title, {
        title: $data.translate.createNew,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
        "model-value": $data.newSettingType,
        options: $options.typesAvailableForCreation,
        "un-sorted": true,
        placeholder: $data.translate.selectOne,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.newSettingType = $event;
        })
      }, null, 8 /* PROPS */, ["model-value", "options", "placeholder"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true);
}