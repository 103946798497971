import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "max-w-screen-xxl xxl:mx-auto"
};
var _hoisted_2 = {
  class: "mx-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString(_ctx.$trans($data.notificationMessage)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createBlock(_component_tiny_mce_box, {
    key: 2,
    height: "60vh",
    type: "all",
    "editor-wrapper-class": "max-w-screen-lg mx-auto",
    "initial-content": $data.userGuideContent,
    "read-only": !_ctx.isAdmin,
    "show-control-buttons": true,
    "show-full-content-state": true,
    onSaved: $options.saveContent
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $options.userGuideTitle || 'User guide',
        editable: _ctx.isAdmin,
        type: "big",
        class: "max-w-650px",
        onTitleChangeLazy: $options.onTitleChange
      }, null, 8 /* PROPS */, ["title", "editable", "onTitleChangeLazy"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["initial-content", "read-only", "onSaved"]))])]);
}