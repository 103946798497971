import { ReportTypes as reportTypes, ReportLogTypes } from '@/constants/reports';
export var getReportTypeByURL = function getReportTypeByURL(reportUrl) {
  if (reportUrl.indexOf('/controls/') !== -1 || reportUrl.indexOf('/controls2/') !== -1) {
    return 'controls';
  }
  if (reportUrl.indexOf('/risk/') !== -1 || reportUrl.indexOf('/risk2/') !== -1) {
    return 'risks';
  }
  if (reportUrl.indexOf('/actions/') !== -1 || reportUrl.indexOf('/execution/') !== -1) {
    return 'actions';
  }
  if (reportUrl.indexOf('/contracts/') !== -1) {
    return 'contracts';
  }

  //compliance and process library have /compliance/ in url
  if (reportUrl.indexOf('/compliance/reports/') !== -1) {
    return 'compliance';
  }
  if (reportUrl.indexOf('/incident/') !== -1) {
    return 'incidents';
  }
  if (reportUrl.indexOf('/processtree/') !== -1) {
    return 'processlibrary';
  }
  if (reportUrl.indexOf('/compliance/informationassets/') !== -1) {
    return 'informationAssets';
  }
  return '';
};
export var getReportSubTypeByObjType = function getReportSubTypeByObjType(type) {
  switch (type) {
    case reportTypes.COMPLIANCE_NODE:
      return 'Node';
    case reportTypes.COMPLIANCE_GAP:
      return 'Gap';
    case reportTypes.COMPLIANCE_ARTICLE30:
      return 'Article 30';
    case reportTypes.IA_QUESTIONNAIRE_AUDIT:
    case reportTypes.COMPLIANCE_QUESTIONNAIRE:
      return 'Questionnaire';
    case reportTypes.COMPLIANCE_DATAFLOW:
      return 'Data flow';
    case reportTypes.RISK_THREATS:
      return 'Threats';
    case reportTypes.RISK_VULNERABILITIES:
      return 'Vulnerabilities';
    case reportTypes.IA_ANSWERED_AUDIT:
      return 'Answered audits';
    default:
      return '';
  }
};
export var typeToAccessProperty = function typeToAccessProperty(type) {
  switch (type) {
    case reportTypes.CONTROLS_DATA:
    case reportTypes.CONTROLS:
      return 'level_controls';
    case reportTypes.CONTRACTS:
      return 'level_contracts';
    case reportTypes.ACTIONS:
      return 'level_execution';
    case reportTypes.INCIDENTS:
      return 'level_incidents';
    default:
      return '';
  }
};
export var getReportOptionsFromQuery = function getReportOptionsFromQuery() {
  var _queryValue$query;
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var queryValue = query.query ? JSON.parse(query.query) : {};
  return ((_queryValue$query = queryValue.query) === null || _queryValue$query === void 0 ? void 0 : _queryValue$query.reportOptions) || null;
};
export var prepareReportLogData = function prepareReportLogData(module, count, location) {
  var searchCriteria = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var outputFormat = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  return {
    module: module,
    count: count,
    location: Array.isArray(location) ? location.join(' - ') : location,
    outputFormat: outputFormat || ReportLogTypes.SCREEN,
    searchCriteria: JSON.stringify(searchCriteria)
  };
};