function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { getKpiPreviewScoreById } from '@/api/kpi/kpi';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'KpiRelevantActivities',
  introduction: "The component is used to display Activities that are/aren't compliant with KPI",
  description: 'Uses a CollapsiblePanel inside',
  token: '<kpi-relevant-activities kpi-id="1" />',
  components: {
    CollapsiblePanel: CollapsiblePanel,
    RismaTitle: RismaTitle,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    kpiId: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      matchedEntries: {
        title: this.$trans('Activities that are compliant with KPI'),
        activities: []
      },
      notMatchedEntries: {
        title: this.$trans("Activities that aren't compliant with KPI"),
        activities: []
      },
      loaded: false
    };
  },
  computed: _objectSpread({}, mapState(useActivityTypesStore, {
    activityTypesById: 'getActivityTypeById'
  })),
  methods: {
    togglePanel: function togglePanel(isOpen) {
      if (isOpen) {
        this.getCalculation(this.kpiId);
      }
    },
    getCalculation: function getCalculation(id) {
      var _this = this;
      this.loaded = false;
      return getKpiPreviewScoreById(id, '?data[]=allEntries').then(function (response) {
        if (Array.isArray(response.matchedEntries)) {
          _this.matchedEntries.activities = _this.prepareActivitiesList(response.matchedEntries);
          var notMatchedEntries = _this.prepareNotMatchedEntiries(response.totalEntries, response.matchedEntries);
          _this.notMatchedEntries.activities = _this.prepareActivitiesList(notMatchedEntries);
        }
      }).finally(function () {
        _this.loaded = true;
      });
    },
    prepareActivitiesList: function prepareActivitiesList(activities) {
      var _this2 = this;
      return activities.map(function (activity) {
        return {
          id: activity.id,
          uniqueId: activity.uniqueId,
          url: activity.url,
          title: _this2.userFriendlyName(activity),
          objType: activity.objType,
          objTypeName: _this2.userFriendlyActivityName(activity),
          activity: activity
        };
      });
    },
    prepareNotMatchedEntiries: function prepareNotMatchedEntiries(totalEntries, matchedEntries) {
      if (!Array.isArray(matchedEntries)) return [];
      if (totalEntries.length === matchedEntries.length) return [];
      var notMatched = totalEntries;

      // Filter all those activities from notMatched, which are also present in matched
      // Make sure to only remove one non-match for each match, thus the return false
      matchedEntries.forEach(function (match) {
        notMatched.every(function (noMatch, index) {
          if (noMatch.id === match.id && noMatch.objType === match.objType) {
            notMatched.splice(index, 1);
            return false;
          }
          return true;
        });
      });
      return notMatched;
    },
    updatedFromPreview: function updatedFromPreview(event, activity) {
      activity.activity.title = event.title;
      activity.title = this.userFriendlyName(activity.activity);
      activity.uniqueId = event.uniqueId;
    },
    userFriendlyName: function userFriendlyName(activity) {
      if (activity.objType === ObjectTypes.CONTROLS_CONTROL) {
        return activity.cpath + ' ' + activity.title + '. ' + this.$trans('Deadline') + ': ' + activity.deadlineUtc.substring(0, 10);
      }
      return activity.title || activity.name;
    },
    userFriendlyActivityName: function userFriendlyActivityName(activity) {
      var objType = activity.objType;
      if (objType === ObjectTypes.COMPLIANCE_NODE) {
        return this.$trans('Node') + ': ';
      }
      if (objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM) {
        return this.$trans('System') + ': ';
      }
      if (objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR) {
        return this.$trans('Processor') + ': ';
      }
      if (objType === ObjectTypes.INFORMATION_ASSET) {
        var activityType = this.activityTypesById(activity.activityTypeId);
        return activityType.label + ': ';
      }
      return '';
    }
  }
};