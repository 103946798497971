function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { useTrafficLights } from '@/Store/trafficLights';
var red = '#c6060f',
  yellow = '#ffc103',
  green = '#57aa74';
var blue1 = '#3a8d96',
  blue2 = '#003d46',
  blue3 = '#003dff';
export var ChartsKey = {
  CONTROL_STATUS: 'control_status',
  OVERALL_STATUS: 'overall_status',
  OVERDUE_CONTROLS_PR_EMPLOYEE: 'overdue_controls_pr_employee',
  OVERALL_REVIEWS: 'overall_reviews',
  OVERALL_COMPLETED: 'overall_completed',
  OVERDUE_REVIEWS_PR_EMPLOYEE: 'overdue_reviews_pr_employee',
  STATUS_PR_PROCESS: 'status_pr_process'
};
var graphsInfo = function graphsInfo() {
  var trafficLightsStore = useTrafficLights();
  var baseGaps = [{
    fieldName: 'red',
    number: 1
  }, {
    fieldName: 'yellow',
    number: 2
  }, {
    fieldName: 'green',
    number: 3
  }].map(function (item) {
    var _trafficLightsStore$g = trafficLightsStore.getControlTrafficLightByNumber(item.number),
      color = _trafficLightsStore$g.color,
      legend = _trafficLightsStore$g.legend;
    return {
      color: color,
      field: "".concat(item.fieldName, "_traffic_light"),
      title: "".concat(legend, " traffic light")
    };
  });
  return [].concat(_toConsumableArray(baseGaps), [{
    color: 'blue',
    field: 'controls_rejected',
    title: 'Rejected controls'
  }]);
};
export var ControlsSettings = function ControlsSettings() {
  return {
    pieTableColumn: [{
      key: 'index',
      text: ''
    }, {
      key: 'text',
      text: ''
    }, {
      key: 'owners',
      text: ''
    }, {
      key: 'organisations',
      text: ''
    }],
    charts: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ChartsKey.CONTROL_STATUS, {
      title: 'Control status',
      colors: [green, yellow, blue1, blue2, blue3, red]
    }), ChartsKey.OVERALL_STATUS, {
      title: 'Pending controls',
      colors: [green, yellow, red, blue1]
    }), ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE, {
      title: 'Overdue controls per employee',
      colors: [red]
    }), ChartsKey.OVERALL_REVIEWS, {
      title: 'Reviews'
    }), ChartsKey.OVERALL_COMPLETED, {
      title: 'Completed controls'
    }), ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE, {
      title: 'Overdue reviews per employee',
      colors: [red]
    }), ChartsKey.STATUS_PR_PROCESS, {
      title: 'Pending controls per process',
      graphsInfo: graphsInfo()
    }),
    overviewCharts: _defineProperty(_defineProperty(_defineProperty({}, ChartsKey.OVERDUE_CONTROLS_PR_EMPLOYEE, {
      title: 'Overdue controls per employee',
      colors: [red]
    }), ChartsKey.OVERDUE_REVIEWS_PR_EMPLOYEE, {
      title: 'Overdue reviews per employee',
      colors: [red]
    }), ChartsKey.STATUS_PR_PROCESS, {
      title: 'Pending controls per process',
      graphsInfo: graphsInfo()
    }),
    completionCharts: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ChartsKey.CONTROL_STATUS, {
      title: 'Control status',
      colors: [green, yellow, blue1, blue2, blue3, red]
    }), ChartsKey.OVERALL_STATUS, {
      title: 'Pending controls',
      colors: [green, yellow, red, blue1]
    }), ChartsKey.OVERALL_REVIEWS, {
      title: 'Reviews'
    }), ChartsKey.OVERALL_COMPLETED, {
      title: 'Completed controls'
    })
  };
};
export var ControlsStates = {
  UPCOMING: 'upcoming',
  EXCEEDED: 'exceeded'
};
export var ControlStatusesUI = {
  COMPLETED_BEFORE: 'completed-before',
  COMPLETED_AFTER: 'completed-after',
  NOT_COMPLETED_YET: 'not-completed-yet',
  NOT_PERFORMED: 'not-performed',
  AWAITING_REVIEW: 'awaiting-review'
};
export var ControlsAnnualOverviewType = {
  COMPLETION: 'completion',
  EFFICIENCY: 'efficiency'
};