// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.router-link-exact-active[data-v-421bd7de]:before,
.tab-item-active[data-v-421bd7de]:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: #00dbb9;
  z-index: 2;
}
.router-link-exact-active[data-v-421bd7de]:before {
  left: -1px;
  right: -1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
