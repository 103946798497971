function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
export function updateUser(data, id) {
  return handleRequestResponse(Http.patch("admin/users/user/".concat(id), data)).then(function (user) {
    useUsersStore().update(user);
    useUserStore().updateUser(user);
    return user;
  });
}
export function createNewUser(data) {
  return handleRequestResponse(Http.post('admin/users/user', data)).then(function (data) {
    useUsersStore().add(data);
    return data;
  });
}
export function changeUserActiveStatus(id, data) {
  var previousData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return handleRequestResponse(Http.delete("admin/users/user/".concat(id), {
    data: data
  })).then(function (data) {
    var finalData = _objectSpread(_objectSpread({}, previousData), data);
    useUsersStore().update(finalData);
    return finalData;
  });
}
export function getUsers(query) {
  return handleRequestResponse(Http.get('admin/users/user' + (query ? "?".concat(query) : '')));
}
export function anonymizeUser(id, data) {
  return handleRequestResponse(Http.delete("admin/users/user/".concat(id), {
    data: data
  })).then(function () {
    return useUsersStore().load();
  });
}