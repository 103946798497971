import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "flex items-center justify-center mb-3"
};
var _hoisted_3 = {
  key: 1,
  class: "w-full"
};
var _hoisted_4 = {
  class: "border-b"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_year_wheel_selector = _resolveComponent("year-wheel-selector");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_year_wheel_selector, {
    "model-value": $props.year,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('update:year', $event);
    })
  }, null, 8 /* PROPS */, ["model-value"])]), $props.loadState ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("table", _hoisted_3, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.translatedMonths, function (column, index) {
    return _openBlock(), _createElementBlock("th", {
      key: "".concat(column, "_").concat(index),
      class: "text-center"
    }, [_createVNode(_component_risma_title, {
      type: "medium",
      title: column
    }, null, 8 /* PROPS */, ["title"])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.preparedData, function (item, index) {
    return _openBlock(), _createElementBlock("td", {
      key: "".concat(item, "_").concat(index),
      class: "text-center w-7/100"
    }, _toDisplayString(item), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])])]))]);
}