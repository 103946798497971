import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = ["title"];
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, {
    class: "h-auto"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.dataProcessorAgreements,
        class: "mb-3"
      }, null, 8 /* PROPS */, ["title"]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_risma_datatable, {
        key: 1,
        ref: "datatable",
        class: "contracts-dpa-table",
        "dataset-main": _ctx.datasetSlice,
        "dataset-total": $data.dataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        paging: true,
        "page-length": _ctx.pageLength,
        "wrapper-height-sticky": 400,
        "filter-by-column": true,
        "state-local": $data.stateLocal,
        "disabled-filter-by-columns": $data.disabledFilterByColumns,
        "decouple-filter-fields": $data.decoupleFilterFields,
        "columns-meta": $data.columnsMeta,
        "columns-by-default": $options.columns,
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, {
        title: _withCtx(function (_ref) {
          var item = _ref.item;
          return [_createVNode(_component_preview_modal_link, {
            activity: item.activity,
            onDismissModal: $options.onPreviewDismiss
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_risma_title_with_icon, {
                icon: "confidential",
                title: item.fieldText,
                "title-attribute": item.hoverTitle,
                "display-icon": item.activity.confidential
              }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])];
        }),
        companies: _withCtx(function (_ref2) {
          var _item$field;
          var item = _ref2.item;
          return [(_item$field = item.field) !== null && _item$field !== void 0 && _item$field.length ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(item.field, function (activity, key) {
            return _openBlock(), _createBlock(_component_preview_modal_link, {
              key: "company_".concat(key),
              class: "w-fit",
              activity: activity
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("span", {
                  title: activity.name,
                  class: "hover:underline"
                }, _toDisplayString(activity.name), 9 /* TEXT, PROPS */, _hoisted_1)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"]);
          }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock("div", _hoisted_2))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "page-length", "state-local", "disabled-filter-by-columns", "decouple-filter-fields", "columns-meta", "columns-by-default", "onPageChange", "onOnStateChanged"]))];
    }),
    _: 1 /* STABLE */
  })]);
}