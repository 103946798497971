function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import draggable from 'vuedraggable';
import { getMyRisks, getRiskProjects as _getRiskProjects } from '@/api/risk/risk';
import { getComplianceProjects as _getComplianceProjects, getWorkflowStatusesByModule } from '@/api/risma/data';
import { getComplianceMy } from '@/api/compliance/tree';
import { getAllTrees, getMyTreeByProjectId } from '@/api/compliance/processtree';
import { getUtilReports } from '@/api/risma/settings';
import { getMyInitiatives } from '@/api/execution/initiative';
import { getMyControls } from '@/api/controls/controls';
import { deleteReport } from '@/api/reports/reports';
import { getMyContracts } from '@/api/contracts/contracts';
import { getMy as getMyInformationAssets } from '@/api/compliance/informationAssets';
import { getMy as getMyIncidents } from '@/api/incident/incident';
import { getRecentActivities, getCurrentActivities } from '@/api/risma/activities';
import * as awarenessAPI from '@/api/compliance/awareness';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaColumnSwitcher from '@/components/Atoms/RismaColumnSwitcher/RismaColumnSwitcher';
import UtilMenuWithToggle from '@/components/Atoms/UtilMenuWithToggle/UtilMenuWithToggle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import ControlReviewModal from '@/components/Molecules/Controls/ControlReviewModal';
import PreviewListCurrentActivity from '@/components/Molecules/PreviewList/PreviewListCurrentActivity';
import CompleteModal from '@/components/Molecules/Controls/CompleteModal';
import PreviewListTile from '@/components/Molecules/PreviewList/PreviewListTile';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { Colors } from '@/Colors';
import { MypageKeys, myPageKeyByObjectType, getCurrentActivities as getAvailableActivities } from '@/constants/mypage/MypageKeys';
import { MypageSettings, MypageGlobalSettings } from '@/constants/mypage/MypageSettings';
import { viewTypes } from '@/constants/ListViewType';
import { DragOptions } from '@/constants/DragOptions';
import { MODULES } from '@/constants/modules';
import { getSortFunction, sortByArrayOfFunctions, sortNumericByProp } from '@/utils/sort';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import { CONTROL_ACTIONS_MESSAGES } from '@/constants/ControlActions';
import { UserLevels } from '@/constants/UserLevels';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'Mypage',
  token: '<my-page />',
  components: {
    draggable: draggable,
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    RismaColumnSwitcher: RismaColumnSwitcher,
    UtilMenuWithToggle: UtilMenuWithToggle,
    PreviewListCurrentActivity: PreviewListCurrentActivity,
    CompleteModal: CompleteModal,
    PreviewListTile: PreviewListTile,
    SingleSelect: SingleSelect,
    ControlReviewModal: ControlReviewModal
  },
  setup: function setup() {
    return {
      contentOrder: getLocalStorageComputed('my_page_content_order', [], true),
      deadlineFilter: getLocalStorageComputed('my_page_activities_by_dealine', 7, true),
      recentFilter: getLocalStorageComputed('my_page_activities_by_creation', 7, true),
      myPageGlobalSettings: getLocalStorageComputed('my_page_global_settings', [], true),
      myPageSortOrder: getLocalStorageComputed('my_page_sort_order', [], true)
    };
  },
  data: function data() {
    return {
      dataset: {},
      reports: [],
      isFewerShow: true,
      controls: [],
      viewSelected: viewTypes.TILES,
      contracts: [],
      contractWorkflows: [],
      risks: [],
      riskProjects: [],
      complianceProjects: [],
      scoring: {},
      initiatives: [],
      processes: [],
      informationAssets: [],
      informationAssetsDataSet: [],
      processTreeNodes: [],
      incidents: [],
      isLoaded: false,
      requests: [],
      colors: Colors,
      globalSettingsList: MypageGlobalSettings(this.$trans),
      dragOptions: DragOptions,
      currentActivities: [],
      recentActivities: [],
      isReviewModalShow: false,
      isCompleteModalShow: false,
      reportIdToDelete: null,
      selectedControl: {},
      MypageKeys: MypageKeys,
      availableActivities: getAvailableActivities(),
      translate: getTranslate['Mypage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useSettingsStore, ['isInformationAssetsDisabled'])), mapState(useUserStore, ['currentUser', 'userHasAccessLevel'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useActivityTypesStore, ['activityTypes'])), mapState(useTagsStore, ['tags'])), mapState(useWorkflowStore, ['getAllStatuses'])), {}, {
    allIncidentsWsFlat: function allIncidentsWsFlat() {
      return this.getAllStatuses(MODULES.INCIDENTS);
    },
    visibleContent: function visibleContent() {
      var _this = this;
      return this.contentOrder.filter(function (item) {
        var _this$dataset$item$ty;
        if ((_this$dataset$item$ty = _this.dataset[item.type].data) !== null && _this$dataset$item$ty !== void 0 && _this$dataset$item$ty.length) {
          item.label = _this.dataset[item.type].label;
          return true;
        }
        return false;
      });
    },
    isRisksAvailable: function isRisksAvailable() {
      return this.userHasAccessLevel('level_risk', UserLevels.NORMAL) && (this.getSettingValue('app.module_risk_enabled') || this.getSettingValue('app.module_risks2_enabled'));
    },
    isControlsAvailable: function isControlsAvailable() {
      return this.userHasAccessLevel('level_controls', UserLevels.NORMAL) && this.getSettingValue('app.module_controls_enabled') && !this.getSettingValue('app.module_controls_hidden');
    },
    isInitiativesAvailable: function isInitiativesAvailable() {
      return this.userHasAccessLevel('level_execution', UserLevels.NORMAL) && this.getSettingValue('app.module_execution_enabled');
    },
    isIncidentAvailable: function isIncidentAvailable() {
      return this.userHasAccessLevel('level_incidents', UserLevels.NORMAL) && this.getSettingValue('app.module_incidents_enabled');
    },
    isContractsAvailable: function isContractsAvailable() {
      return this.getSettingValue('app.module_contracts_enabled') && this.userHasAccessLevel('level_contracts', UserLevels.NORMAL);
    },
    isProcessTreeAvailable: function isProcessTreeAvailable() {
      return this.getSettingValue('feature.process_tree') && (this.currentUser.AccessLevels.PolicyManagement.level > UserLevels.NONE || this.currentUser.is_admin);
    },
    isComplianceAvailable: function isComplianceAvailable() {
      return this.getSettingValue('app.module_compliance_enabled') && (this.currentUser.is_admin || this.currentUser.projects.find(function (element) {
        return element.access_level > UserLevels.NONE && element.objType == ObjectTypes.COMPLIANCE_PROJECT;
      }) !== undefined);
    },
    isInformationAssetsAvailable: function isInformationAssetsAvailable() {
      return this.currentUser.iaAccess && !this.isInformationAssetsDisabled;
    },
    isDragDropVisible: function isDragDropVisible() {
      return this.globalSettingsList.find(function (_ref) {
        var type = _ref.type;
        return type === 'dragAndDrop';
      }).visible;
    },
    isSortingVisible: function isSortingVisible() {
      return this.globalSettingsList.find(function (_ref2) {
        var type = _ref2.type;
        return type === 'isSortingVisible';
      }).visible;
    },
    isContentVisibilityVisible: function isContentVisibilityVisible() {
      return this.globalSettingsList.find(function (_ref3) {
        var type = _ref3.type;
        return type === 'contentVisibility';
      }).visible;
    },
    deadlineFilterOptions: function deadlineFilterOptions() {
      return [7, 14, 30];
    },
    recentFilterOptions: function recentFilterOptions() {
      return [7, 14, 30, 60, 90, 365];
    },
    currentAndRecentActivities: function currentAndRecentActivities() {
      var _this2 = this;
      var activities = [].concat(_toConsumableArray(this.currentActivities), _toConsumableArray(this.recentActivities)).filter(function (activity) {
        return Object.keys(_this2.availableActivities).includes(activity.objType);
      });
      activities.forEach(function (activity) {
        if (activity.objType === ObjectTypes.CONTRACT_LIMITED || activity.objType === ObjectTypes.CONTRACT) {
          activity.workflowTitle = _this2.getContractWorkflowTitle(activity.workflowStatusId);
        }
      });
      return activities;
    },
    projectsList: function projectsList() {
      return [].concat(_toConsumableArray(this.riskProjects), _toConsumableArray(this.complianceProjects));
    }
  }),
  watch: {
    deadlineFilter: function deadlineFilter() {
      this.handleGetCurrentActivities();
    },
    recentFilter: function recentFilter() {
      this.handleGetRecentActivities();
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.dataset = _objectSpread({}, MypageSettings(this.$trans));
    this.handleGetCurrentActivities();
    this.handleGetRecentActivities();
    this.setGlobalSettings();
    var requestsList = [this.handleGetReports(), this.handleGetAwarenessRequests()];
    if (this.isRisksAvailable) {
      requestsList.push(this.getRiskProjects());
    }
    if (this.isControlsAvailable) {
      requestsList.push(this.handleGetMyControls());
    }
    if (this.isInitiativesAvailable) {
      requestsList.push(this.handleGetMyInitiatives());
    }
    if (this.isIncidentAvailable) {
      requestsList.push(this.handleGetIncidents());
    }
    if (this.isContractsAvailable) {
      requestsList.push(this.handleGetMyContracts());
    }
    if (this.isInformationAssetsAvailable) {
      requestsList.push(this.handleGetInformationAssets());
    }
    if (this.isProcessTreeAvailable) {
      requestsList.push(this.handleGetProcesseTreeNode());
    }
    if (this.isComplianceAvailable) {
      requestsList.push(this.getComplianceProjects());
      requestsList.push(this.handleGetProcesses());
    }
    Promise.all(requestsList).then(function () {
      _this3.contentOrder = _this3.setContentOrdering();
      _this3.updateSort();
    }).finally(function () {
      return _this3.isLoaded = true;
    });
  },
  methods: {
    controlCheck: function controlCheck(id, _ref4) {
      var state = _ref4.state,
        _ref4$isSave = _ref4.isSave,
        isSave = _ref4$isSave === void 0 ? false : _ref4$isSave;
      if (!isSave) {
        var control = this.currentActivities.find(function (element) {
          return element.id === +id && element.objType === ObjectTypes.CONTROLS_CONTROL;
        });
        if (!control) return;
        if (!control.url) {
          this.$notify({
            title: [this.translate.success, this.$trans(CONTROL_ACTIONS_MESSAGES[state])]
          });
        } else {
          this.$notify({
            title: this.translate.success,
            urlTitle: this.$trans(CONTROL_ACTIONS_MESSAGES[state]),
            url: control.url
          });
        }
      }
      this.updateCurrentActivitiesList();
    },
    updateCurrentActivitiesList: function updateCurrentActivitiesList() {
      this.isReviewModalShow = false;
      this.handleGetRecentActivities();
      this.handleGetCurrentActivities();
    },
    showCompletionModal: function showCompletionModal(control) {
      this.selectedControl = control;
      this.isCompleteModalShow = true;
    },
    showReviewModal: function showReviewModal(control) {
      this.selectedControl = control;
      this.isReviewModalShow = true;
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this4 = this;
      return _getComplianceProjects().then(function (compliance) {
        _this4.complianceProjects = compliance.list;
      });
    },
    getUpdatedContentOrdering: function getUpdatedContentOrdering(order) {
      var _this5 = this;
      // if something new was added
      Object.keys(this.dataset).forEach(function (element, index) {
        if (!order.find(function (item) {
          return element == item.type;
        })) {
          var newItem = {
            visible: _this5.dataset[element].visible,
            type: element
          };
          order.splice(index, 0, newItem);
        }
      });
      //if something was deleted from the list
      order.forEach(function (element, index) {
        if (!Object.keys(_this5.dataset).find(function (item) {
          return element.type == item && _this5.dataset[item].data;
        })) {
          order.splice(index, 1);
        }
      });
      return order;
    },
    setContentOrdering: function setContentOrdering() {
      var _this$contentOrder,
        _this6 = this;
      if ((_this$contentOrder = this.contentOrder) !== null && _this$contentOrder !== void 0 && _this$contentOrder.length) {
        return this.getUpdatedContentOrdering(this.contentOrder);
      }
      return Object.keys(this.dataset).map(function (item) {
        return {
          visible: _this6.dataset[item].visible,
          type: item
        };
      });
    },
    handleGetIncidents: function handleGetIncidents() {
      var _this7 = this;
      return getMyIncidents().then(function () {
        var incidents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        _this7.incidents = incidents;
        _this7.updateDatasetByType(MypageKeys.MY_INCIDENTS, _this7.incidents, _this7.allIncidentsWsFlat);
      });
    },
    handleGetAwarenessRequests: function handleGetAwarenessRequests() {
      var _this8 = this;
      return awarenessAPI.getMyAwareness().then(function (data) {
        _this8.requests = data.list;
        _this8.updateDatasetByType(MypageKeys.UNANSWERED_AWARENESS, _this8.requests);
      });
    },
    handleGetInformationAssets: function handleGetInformationAssets() {
      var _this9 = this;
      return getMyInformationAssets().then(function (response) {
        _this9.informationAssets = response.list;
        _this9.updateDatasetByType(MypageKeys.INFORMATION_ASSETS, _this9.informationAssets);
      });
    },
    handleGetProcesseTreeNode: function handleGetProcesseTreeNode() {
      var _this10 = this;
      return getAllTrees().then(function (trees) {
        if (trees[0] && trees[0].id) {
          return getMyTreeByProjectId(trees[0].id);
        } else {
          return {
            list: []
          };
        }
      }).then(function (_ref5) {
        var list = _ref5.list;
        _this10.processTreeNodes = list;
        _this10.updateDatasetByType(MypageKeys.PROCESS_LIBRARY, list);
      });
    },
    handleRemoveReport: function handleRemoveReport() {
      var _this11 = this;
      if (!this.reportIdToDelete) return;
      deleteReport(this.reportIdToDelete).then(function () {
        _this11.$notify({
          title: _this11.translate.theReportWasSuccessfullyDeleted
        });
        _this11.reportIdToDelete = null;
        return _this11.handleGetReports();
      }).then(function () {
        return _this11.dataset = _objectSpread({}, _this11.dataset);
      }).catch(function (error) {
        return _this11.$notify({
          title: error,
          type: error
        });
      });
    },
    handleGetRecentActivities: function handleGetRecentActivities() {
      var _this12 = this;
      return getRecentActivities(this.recentFilter).then(function (list) {
        _this12.recentActivities = _this12.prepareRecentActivity(list);
      });
    },
    handleGetCurrentActivities: function handleGetCurrentActivities() {
      var _this13 = this;
      return getCurrentActivities(this.deadlineFilter).then(function (list) {
        _this13.currentActivities = list;
      });
    },
    prepareRecentActivity: function prepareRecentActivity(list) {
      return list.reduce(function (accumulator, currentValue) {
        accumulator.push(_objectSpread(_objectSpread({}, currentValue), currentValue.activity));
        return accumulator;
      }, []);
    },
    sortActivities: function sortActivities(list, propertyName) {
      return list.sort(function (a, b) {
        return a[propertyName].localeCompare(b[propertyName], undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
    },
    handleGetReports: function handleGetReports() {
      var _this14 = this;
      return getUtilReports().then(function (reports) {
        var sharedReports = [];
        var myReports = [];
        sortNumericByProp(reports.list, 'id', true);
        reports.list.forEach(function (report) {
          report.shared ? sharedReports.push(report) : myReports.push(report);
        });
        return {
          sharedReports: sharedReports,
          myReports: myReports
        };
      }).then(function (reports) {
        _this14.reports = reports;
        _this14.updateDatasetByType(MypageKeys.MY_REPORTS, _this14.reports.myReports);
        _this14.updateDatasetByType(MypageKeys.SHARED_REPORTS, _this14.reports.sharedReports);
      });
    },
    handleGetMyControls: function handleGetMyControls() {
      var _this15 = this;
      var params = '?data[]=organisationIds&data[]=associations';
      return getMyControls(params).then(function (controls) {
        _this15.controls = _this15.sortActivities(controls.responsible.list, 'cpath');
        _this15.updateDatasetByType(MypageKeys.MY_CONTROLS, _this15.controls);
      });
    },
    handleGetMyContracts: function handleGetMyContracts() {
      var _this16 = this;
      return getMyContracts().then(function (response) {
        _this16.contracts = response.list;
      }).then(function () {
        return getWorkflowStatusesByModule(MODULES.CONTRACTS);
      }).then(function (workflows) {
        _this16.contractWorkflows = workflows.list;
        _this16.updateDatasetByType(MypageKeys.MY_CONTRACTS, _this16.contracts, workflows.list);
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this17 = this;
      return _getRiskProjects().then(function (_ref6) {
        var list = _ref6.list;
        _this17.riskProjects = list;
      }).then(function () {
        return _this17.handleGetMyRisk();
      }).then(function (risks) {
        var filteredRisks = _this17.filterRisksByProjects(risks.list);
        _this17.risks = filteredRisks.sort(function (a, b) {
          return a.projectId - b.projectId || a.rno - b.rno;
        });
        _this17.scoring = risks.scoring;
        _this17.updateDatasetByType(MypageKeys.MY_RISKS, _this17.risks);
      });
    },
    filterRisksByProjects: function filterRisksByProjects(risks) {
      var _this18 = this;
      return risks.filter(function (risk) {
        return _this18.riskProjects.find(function (project) {
          return +project.id === +risk.projectId;
        });
      });
    },
    handleGetMyRisk: function handleGetMyRisk() {
      return new Promise(function (resolve, reject) {
        getMyRisks().then(function (risks) {
          return resolve(risks);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    handleGetMyInitiatives: function handleGetMyInitiatives() {
      var _this19 = this;
      return getMyInitiatives().then(function (initiatives) {
        _this19.initiatives = _this19.sortActivities(initiatives.list, 'ipath');
        _this19.updateDatasetByType(MypageKeys.MY_INITIATIVES, _this19.initiatives);
      });
    },
    getProcessesByResponsible: function getProcessesByResponsible(list, userId) {
      return list.filter(function (process) {
        return process.responsibleUserIds.some(function (resUserId) {
          return resUserId === userId;
        });
      });
    },
    handleGetProcesses: function handleGetProcesses() {
      var _this20 = this;
      return new Promise(function (resolve, reject) {
        var isUserHasAccess = _this20.currentUser.projects.some(function (project) {
          return project.objType === ObjectTypes.COMPLIANCE_PROJECT && project.access_level > UserLevels.NONE;
        });
        if (isUserHasAccess) {
          getComplianceMy().then(function (response) {
            return resolve(response.list);
          }).catch(function (error) {
            return reject(error);
          });
        } else {
          resolve([]);
        }
      }).then(function (processesAll) {
        _this20.processes = _this20.getProcessesByResponsible(processesAll, _this20.currentUser.id);
        _this20.updateDatasetByType(MypageKeys.MY_PROCESS, _this20.processes);
      });
    },
    setGlobalSettings: function setGlobalSettings() {
      var _this21 = this;
      this.globalSettingsList.forEach(function (item) {
        item.visible = _this21.myPageGlobalSettings.indexOf(item.id) === -1;
      });
    },
    updateDatasetByType: function updateDatasetByType(type, data) {
      var workflows = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      this.dataset[type].data = Object.assign(data);
      this.dataset[type].activities = Object.assign(data);
      this.dataset[type].workflows = workflows;
    },
    onChangeBlockVisibility: function onChangeBlockVisibility(column) {
      column.visible = !column.visible;
    },
    changeSettingsPageVisibility: function changeSettingsPageVisibility(navItem) {
      var hiddenItem = [];
      navItem.visible = !navItem.visible;
      this.globalSettingsList.map(function (item) {
        if (!item.visible) {
          hiddenItem.push(item.id);
        }
      });
      this.myPageGlobalSettings = hiddenItem;
    },
    sortList: function sortList(sortBy, type) {
      this.dataset[type].sorting.sortBy = sortBy;
      if (this.dataset[type].activities) {
        var sortFunction = getSortFunction({
          sortBy: sortBy,
          users: this.users,
          organisations: this.organisations,
          activityTypes: this.activityTypes,
          tags: this.tags,
          workflowStatuses: this.dataset[type].workflows
        });
        sortByArrayOfFunctions(this.dataset[type].activities, [sortFunction]);
      }
      this.setSortStorage();
    },
    setSortStorage: function setSortStorage() {
      var _this22 = this;
      var sortArray = Object.keys(this.dataset).map(function (item) {
        return {
          sorting: _this22.dataset[item].sorting && _this22.dataset[item].sorting.sortBy ? _this22.dataset[item].sorting.sortBy : '',
          type: item
        };
      });
      this.myPageSortOrder = sortArray;
    },
    updateSort: function updateSort() {
      var _this23 = this;
      this.myPageSortOrder.forEach(function (item) {
        var _item$sorting;
        if ((_item$sorting = item.sorting) !== null && _item$sorting !== void 0 && _item$sorting.length) {
          _this23.sortList(item.sorting, item.type);
        }
      });
    },
    updateFromCurrentPreviewMode: function updateFromCurrentPreviewMode(data) {
      var type = myPageKeyByObjectType[data.objType];
      if (type) this.updateFromPreviewMode(type, data);
    },
    updateFromPreviewMode: function updateFromPreviewMode(type, data) {
      var activityIndex = this.dataset[type].activities.findIndex(function (item) {
        return item.id === data.id;
      });
      if (type === MypageKeys.MY_INITIATIVES && data.closed !== this.dataset[type].activities[activityIndex].closed) this.handleActionChildrenCloseChange(type, data, data.closed);
      this.dataset[type].activities[activityIndex] = _objectSpread(_objectSpread({}, this.dataset[type].activities[activityIndex]), data);
      this.updateCurrentActivitiesList();
    },
    handleActionChildrenCloseChange: function handleActionChildrenCloseChange(type, data, state) {
      var _this24 = this;
      if (!(data !== null && data !== void 0 && data.children.length)) return;
      data.children.forEach(function (child) {
        var activityIndex = _this24.dataset[type].activities.findIndex(function (item) {
          return item.id === data.id;
        });
        if (activityIndex !== -1) _this24.dataset[type].activities[activityIndex].closed = state;
        _this24.handleActionChildrenCloseChange(type, child, state);
      });
    },
    deleteFromPreviewMode: function deleteFromPreviewMode(type, data) {
      var _data$children,
        _this25 = this;
      this.dataset[type].activities = this.dataset[type].activities.filter(function (item) {
        return item.id !== data.id;
      });
      if (type === MypageKeys.MY_INITIATIVES && data !== null && data !== void 0 && (_data$children = data.children) !== null && _data$children !== void 0 && _data$children.length) {
        data.children.forEach(function (child) {
          _this25.deleteFromPreviewMode(type, child);
        });
      }
    },
    getContractWorkflowTitle: function getContractWorkflowTitle(id) {
      var workflow = this.contractWorkflows.find(function (workflow) {
        return +workflow.id === +id;
      });
      return workflow ? workflow.title : '';
    },
    handleDeleteConfirmation: function handleDeleteConfirmation(id) {
      var _this26 = this;
      this.reportIdToDelete = id;
      this.$confirm(this.translate.deleteReport, this.translate.theSavedReportWillBeDeleted, function (res) {
        if (res) {
          _this26.handleRemoveReport();
        } else {
          _this26.reportIdToDelete = null;
        }
      }, {
        buttonOkText: this.translate.delete
      });
    }
  }
};