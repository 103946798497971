// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-menu[data-v-d6d55962] {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
[data-v-d6d55962]  .matrix-inner {
  left: 0;
}
[data-v-d6d55962]  .vertical svg {
  margin: auto;
}
[data-v-d6d55962]  .horizontal-wrapper {
  padding-left: 3rem;
}
[data-v-d6d55962]  .horizontal {
  left: 0;
  margin: auto;
}
[data-v-d6d55962]  .horizontal svg {
  margin: auto;
}
[data-v-d6d55962]  .axis-label.horizontal .labeltd {
  height: 5px;
}
[data-v-d6d55962]  .flex-item {
  margin: 0 1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
