import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "mb-6 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_3 = {
  key: 0,
  class: "text-red-50"
};
var _hoisted_4 = {
  class: "mt-6 mb-1 glob-h3 text-rm-text"
};
var _hoisted_5 = {
  class: "mb-3 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_6 = {
  class: "flex items-center mb-6"
};
var _hoisted_7 = {
  class: "glob-h1 text-rm-text"
};
var _hoisted_8 = {
  key: 3,
  class: "w-full"
};
var _hoisted_9 = {
  class: "border-b-2 border-gray-160"
};
var _hoisted_10 = {
  class: "pb-3 text-left glob-h3 text-rm-text"
};
var _hoisted_11 = {
  key: 0,
  class: "w-24 pb-3 glob-h3 text-rm-text"
};
var _hoisted_12 = {
  class: "py-3"
};
var _hoisted_13 = {
  class: "flex items-center"
};
var _hoisted_14 = ["onClick"];
var _hoisted_15 = {
  key: 0,
  class: "py-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  var _component_input_field = _resolveComponent("input-field");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showDeleteModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.deleteStatus,
    "button-ok-text": $data.translate.delete,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: _cache[1] || (_cache[1] = function ($event) {
      return $options.deleteStatus($data.deleteStatusData);
    }),
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showDeleteModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("p", _hoisted_2, _toDisplayString($data.translate.youAreTryingToDeleteStatus($data.deleteStatusData.title, $data.deleteStatusData.usageCount)), 1 /* TEXT */), _createVNode(_component_single_select, {
        modelValue: $data.moveToState,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.moveToState = $event;
        }),
        title: $data.translate.status,
        options: $data.moveToStatuses,
        placeholder: $data.translate.selectAStatus
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text"])) : _createCommentVNode("v-if", true), $data.showStatusModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.statusSettings,
    "button-ok-text": $data.isCreateMode ? $data.translate.create : $data.translate.save,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.onStatusSettingsChange,
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.showStatusModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.editedStatusData.title,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.editedStatusData.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        placeholder: $data.translate.enterTitle,
        invalid: $options.isStatusTitleInvalid,
        maxlength: 50,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "invalid"]), $options.isStatusTitleInvalid ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString("".concat($data.translate.title, " ").concat($data.translate.isMandatory)), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("h3", _hoisted_4, _toDisplayString($data.translate.instructions), 1 /* TEXT */), _createElementVNode("p", _hoisted_5, _toDisplayString($data.translate.theInstructionsWillBeVisibleOnTheActivity), 1 /* TEXT */), _createVNode(_component_tiny_mce_box, {
        type: "mini",
        "initial-content": $data.editedStatusData.instructions,
        "always-open": true,
        "debounce-timeout": 0,
        onChanged: _cache[4] || (_cache[4] = function ($event) {
          return $data.editedStatusData.instructions = $event;
        })
      }, null, 8 /* PROPS */, ["initial-content"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    width: "30",
    height: "30",
    class: "mr-2 stroke-2 text-rm-text cursor-pointer",
    onClick: $options.goBack
  }, null, 8 /* PROPS */, ["onClick"]), _createElementVNode("h1", _hoisted_7, _toDisplayString($data.translate.defaultIncidentWorkflow), 1 /* TEXT */), !$options.readOnly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.addStatus,
    type: "save",
    class: "add-button ml-auto",
    disabled: $data.saveStatus === $data.saveStatuses.SAVING,
    onClick: $options.addStatus
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        width: "18",
        height: "18",
        class: "mr-2 stroke-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "onClick"])) : _createCommentVNode("v-if", true)]), $data.saveStatus === $data.saveStatuses.SAVING ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 2
  })) : (_openBlock(), _createElementBlock("table", _hoisted_8, [_createElementVNode("thead", _hoisted_9, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_10, _toDisplayString($data.translate.statusTitle), 1 /* TEXT */), !$options.readOnly ? (_openBlock(), _createElementBlock("th", _hoisted_11, _toDisplayString($data.translate.delete), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _createVNode(_component_draggable, _mergeProps({
    modelValue: $data.workflowStatuses,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.workflowStatuses = $event;
    }),
    tag: "tbody",
    "item-key": "id",
    "component-data": {
      type: 'transition'
    },
    handle: ".drag-icon"
  }, $data.DragOptions, {
    disabled: $options.readOnly,
    onChange: $options.statusMoved
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element;
      return [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_12, [_createElementVNode("div", _hoisted_13, [!$options.readOnly ? (_openBlock(), _createBlock(_component_drag_and_drop_icon, {
        key: 0,
        class: "drag-icon mr-4 cursor-pointer"
      })) : _createCommentVNode("v-if", true), _createElementVNode("span", {
        class: _normalizeClass(["glob-b1 text-rm-text", {
          'cursor-pointer': !$options.readOnly
        }]),
        onClick: function onClick($event) {
          return $options.editStatus(element);
        }
      }, _toDisplayString(element.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_14)])]), !$options.readOnly ? (_openBlock(), _createElementBlock("td", _hoisted_15, [_createElementVNode("div", null, [_createVNode(_component_feather_icon, {
        icon: "x",
        width: "24",
        height: "24",
        class: "m-auto stroke-2 text-red-50 cursor-pointer",
        onClick: function onClick($event) {
          return $options.openDeleteModal(element);
        }
      }, null, 8 /* PROPS */, ["onClick"])])])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue", "disabled", "onChange"])]))]);
}