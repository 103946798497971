// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachments.open .files-number[data-v-918bad4e] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 172 239 / var(--tw-text-opacity));
}
.attachments:hover .files-number[data-v-918bad4e] {
  --tw-bg-opacity: 1;
  background-color: rgb(222 226 230 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(52 58 64 / var(--tw-text-opacity));
}
.attachments.open:hover .files-number[data-v-918bad4e] {
  --tw-text-opacity: 1;
  color: rgb(0 172 239 / var(--tw-text-opacity));
}
.attachment-button[data-v-918bad4e]::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #dee2e6;
  z-index: 2;
}
.attachment-button.open[data-v-918bad4e]::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #00acef;
  z-index: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
