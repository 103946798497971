import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
var _hoisted_2 = {
  style: {
    "margin": "0 0 1rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_pagination, {
    total: 49,
    "per-page": 10
  })]), _createElementVNode("div", null, [_cache[0] || (_cache[0] = _createElementVNode("div", null, [_createElementVNode("b", null, "With Previous/Next buttons:")], -1 /* HOISTED */)), _createVNode(_component_pagination, {
    total: 999,
    "per-page": 50,
    "show-prev-next-buttons": true
  })])]);
}