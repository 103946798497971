import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "pt-2 px-4"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_editable_title = _resolveComponent("risma-editable-title");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_internal_section_overview = _resolveComponent("internal-section-overview");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  return _openBlock(), _createBlock(_component_expansion_panel, {
    class: "mt-4",
    "start-expanded": !$props.section.id
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_editable_title, {
        ref: "titleRef",
        title: $props.section[$data.COMPANY_INTERNAL_PROPS.TITLE],
        truncate: true,
        class: "!text-lg !font-normal max-w-17/20 min-w-24 -ml-2",
        placeholder: "Title",
        "allow-empty": true,
        "custom-font": "400 18px Inter",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"])),
        onTitleChange: $options.updateSectionTitle,
        onBlur: $options.commitUpdate
      }, null, 8 /* PROPS */, ["title", "onTitleChange", "onBlur"])];
    }),
    "header-menu": _withCtx(function () {
      return [!$props.readOnly ? (_openBlock(), _createBlock(_component_toggle_menu, {
        key: 0,
        position: "absolute",
        options: $options.toggleMenuOptions
      }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$data.selectedView === $data.VIEWS.overview ? (_openBlock(), _createBlock(_component_internal_section_overview, {
        key: 0,
        "selected-options": $props.section,
        options: $props.options,
        "read-only": $props.readOnly,
        onUpdate: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('update', $event);
        })
      }, null, 8 /* PROPS */, ["selected-options", "options", "read-only"])) : (_openBlock(), _createElementBlock("div", _hoisted_2))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["start-expanded"]);
}