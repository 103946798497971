import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  class: "mb-3"
};
var _hoisted_3 = {
  key: 0,
  class: "relative"
};
var _hoisted_4 = ["srcdoc"];
var _hoisted_5 = {
  class: "flex items-center pt-9 border-t-2 border-t-gray-350"
};
var _hoisted_6 = {
  href: "https://support.rismasystems.com/controlpanelgeneral#email",
  class: "mr-4",
  target: "_blank"
};
var _hoisted_7 = {
  class: "-mt-2 text-xs text-blue-750"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_text_box = _resolveComponent("text-box");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_modal = _resolveComponent("modal");
  var _component_static_table = _resolveComponent("static-table");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.editEmailText,
    "show-buttons": false,
    onDismiss: $options.onDismiss
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, _toDisplayString($data.translate.thePreviewOfTheEmaillTemplateWillUpdateAutomaticallyAsTextIsAdded), 1 /* TEXT */), _createVNode(_component_risma_title, {
        title: $data.translate.customTextForEmail,
        type: "medium",
        class: "mb-2"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
        text: $data.editedNotification.message,
        "max-length": 1000,
        class: "w-full",
        onUpdated: $options.updateMessage
      }, null, 8 /* PROPS */, ["text", "onUpdated"]), _createElementVNode("div", {
        class: "flex items-center my-3 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $data.showPreview = !$data.showPreview;
        })
      }, [_createVNode(_component_risma_title, {
        title: $data.translate.previewEmailTemplate,
        type: "medium",
        class: "mr-2"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
        icon: "chevron-down",
        class: _normalizeClass(["stroke-3", ['transform duration-500', {
          'rotate-180': $data.showPreview
        }]])
      }, null, 8 /* PROPS */, ["class"])]), $data.showPreview ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "absolute h-full w-full"
      }, null, -1 /* HOISTED */)), !$data.isPreviewLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("iframe", {
        key: 1,
        width: "100%",
        height: "100%",
        srcdoc: $data.isPreviewLoaded ? $data.notificationPreview : '',
        onLoad: _cache[1] || (_cache[1] = function () {
          return $options.resizeFrame && $options.resizeFrame.apply($options, arguments);
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4))])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
    title: $data.translate.emails,
    type: "big",
    class: "mb-4"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_static_table, {
    "dataset-table": $options.dataset,
    columns: $options.columns,
    class: "mb-8"
  }, {
    emailTemplateText: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createVNode(_component_feather_icon, {
        icon: "edit",
        class: "stroke-2 text-blue-350 cursor-pointer",
        onClick: function onClick($event) {
          return $options.openTemplate(slotData);
        }
      }, null, 8 /* PROPS */, ["onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("a", _hoisted_6, [_createVNode(_component_feather_icon, {
    icon: "help-circle",
    class: "stroke-3 text-blue-750"
  })]), _createVNode(_component_risma_title, {
    title: "".concat($data.translate.replaceRismaLogoWithText, ":"),
    type: "medium",
    class: "mr-10"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
    modelValue: $data.logoReplacement,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
      return $data.logoReplacement = $event;
    }), $options.replaceLogo],
    type: "text",
    class: "w-350px"
  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])]), _createElementVNode("p", _hoisted_7, _toDisplayString($data.translate.nbOnlyForAuditAndAwarenessEmails), 1 /* TEXT */)]);
}