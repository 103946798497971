import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex performance-container"
};
var _hoisted_2 = {
  class: "py-6"
};
var _hoisted_3 = {
  key: 0,
  class: "flex flex-grow w-3/4 bg-blue-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_vertical_menu, {
    items: $data.topLinks,
    "exact-match": true,
    class: "flex-grow min-w-210px"
  }, null, 8 /* PROPS */, ["items"]), _cache[0] || (_cache[0] = _createElementVNode("hr", {
    class: "my-2 border-b bg-gray-450 border-gray-450 mr-8"
  }, null, -1 /* HOISTED */)), _createVNode(_component_vertical_menu, {
    items: $options.dashboardLinks,
    class: "flex-grow min-w-210px max-w-350px"
  }, null, 8 /* PROPS */, ["items"]), _cache[1] || (_cache[1] = _createElementVNode("hr", {
    class: "my-2 border-b bg-gray-450 border-gray-450 mr-8"
  }, null, -1 /* HOISTED */)), _createVNode(_component_vertical_menu, {
    items: $options.settingLink,
    class: "flex-grow min-w-210px"
  }, null, 8 /* PROPS */, ["items"])]), $data.dashboards.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.$route.fullPath,
    dashboards: $data.dashboards,
    "performance-data": $data.performanceData,
    "performance-line-data": $data.performanceLineData,
    "monthly-daily-value": $setup.dailyMonthlyState,
    onProgressTypeChanged: $options.handleProgressTypeChanged,
    onCreateDashboard: $options.handleDashboardCreation,
    onDashboardsUpdated: $options.getData
  }, null, 8 /* PROPS */, ["dashboards", "performance-data", "performance-line-data", "monthly-daily-value", "onProgressTypeChanged", "onCreateDashboard", "onDashboardsUpdated"]))])) : _createCommentVNode("v-if", true)]);
}