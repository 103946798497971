import { getTranslate } from './translate';
import SignOffForm from '@/components/Organisms/SignOffForm';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'AwarenessReminderModal',
  introduction: 'A modal, that handles reminder sign-off feature',
  description: 'Awareness reminder modal in Compliance Process library',
  token: '<awareness-reminder-modal :activity="activity" :users="users" :awareness="awareness" @sendReminder="sendReminder" />',
  components: {
    Modal: Modal,
    SignOffForm: SignOffForm
  },
  props: {
    activity: {
      type: Object,
      required: true,
      note: 'Main node data object'
    },
    users: {
      type: Array,
      required: true,
      note: 'List of users'
    },
    awareness: {
      type: Object,
      required: true,
      note: 'An awareness object to show. Send an empty object if a new object needs to be created'
    }
  },
  emits: ['dismiss', 'changed', 'sendReminder'],
  data: function data() {
    return {
      comments: '',
      dashboardTitle: null,
      sentError: '',
      isDirty: false,
      translate: getTranslate['AwarenessReminderModal']()
    };
  },
  mounted: function mounted() {
    this.setAwareness();
  },
  methods: {
    addNewEmail: function addNewEmail(newEmail) {
      var _this = this;
      this.sentError = '';
      if (this.awareness.users.find(function (item) {
        return item.email === newEmail;
      })) {
        this.sentError = "".concat(this.translate.duplicateEmailAddress, ": ").concat(newEmail);
        setTimeout(function () {
          return _this.sentError = '';
        }, 5000);
      } else {
        this.awareness.users.push({
          email: newEmail,
          fullname: '',
          signoff: null
        });
      }
      this.isDirty = true;
    },
    removeUserFromSent: function removeUserFromSent(user) {
      var _this2 = this;
      var field = user.id ? 'id' : 'email';
      if (this.awareness.users.length === 1 && this.awareness.users[0][field] === user[field]) {
        this.sentError = "".concat(this.translate.atLeastUserIsRequired);
        setTimeout(function () {
          return _this2.sentError = '';
        }, 5000);
        return;
      }
      var rightIndex = this.awareness.users.findIndex(function (item) {
        return item.id === user[field];
      });
      if (rightIndex !== -1) {
        this.awareness.users.splice(rightIndex, 1);
        this.isDirty = true;
      }
    },
    setAwareness: function setAwareness() {
      this.comments = this.awareness.message || '';
      this.dashboardTitle = this.awareness.title || null;
    },
    sendReminder: function sendReminder() {
      this.$emit('sendReminder', this.comments, this.awareness.id, this.awareness.users, this.awareness.quizQuestionnaireId);
    },
    handleDismiss: function handleDismiss() {
      var _this3 = this;
      if (this.isDirty) {
        this.$confirm(this.translate.areYouSure, this.translate.areYouSureYouWantToCloseItTheEnteredInf, function (res) {
          return res && _this3.$emit('dismiss');
        });
      } else {
        this.$emit('dismiss');
      }
    }
  }
};