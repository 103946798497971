import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center"
};
var _hoisted_2 = {
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tag_item = _resolveComponent("tag-item");
  var _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
    id: $data.uid,
    modelValue: $data.optionInput,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.optionInput = $event;
    }),
    title: $data.translate.answerOptions,
    placeholder: $props.placeholder,
    type: "text",
    class: "flex-grow mr-3"
  }, null, 8 /* PROPS */, ["id", "modelValue", "title", "placeholder"]), _createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-3 cursor-pointer",
    onClick: $options.handleAddOption
  }, null, 8 /* PROPS */, ["onClick"])]), _createVNode(_component_draggable, _mergeProps({
    list: $props.options,
    disabled: !$props.draggable,
    "item-key": "label",
    tag: "div",
    "component-data": {
      type: 'transition'
    }
  }, $data.dragOptions, {
    handle: ".drag-icon"
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element;
      return [_createElementVNode("div", _hoisted_2, [$props.draggable ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "move",
        class: "drag-icon stroke-2 cursor-move text-white absolute z-2 left-0.3rem top-1/2 transform -translate-y-1/2"
      })) : _createCommentVNode("v-if", true), _createVNode(_component_tag_item, {
        title: element.label,
        class: _normalizeClass({
          'tag-item-padding': $props.draggable
        }),
        onClose: function onClose($event) {
          return $options.handleDeleteOption(element.label);
        }
      }, null, 8 /* PROPS */, ["title", "class", "onClose"])])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["list", "disabled"])]);
}