import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "risma-pagesize"
};
var _hoisted_2 = ["value"];
var _hoisted_3 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString($options.perPageLabel[0]), 1 /* TEXT */), _createElementVNode("select", {
    class: "bg-inherit",
    value: $props.modelValue,
    onChange: _cache[0] || (_cache[0] = function () {
      return $options.emitChanges && $options.emitChanges.apply($options, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pagingOptions, function (option, index) {
    return _openBlock(), _createElementBlock("option", {
      key: index,
      value: option.value || option
    }, _toDisplayString(option.label || option), 9 /* TEXT, PROPS */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */))], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), _createElementVNode("span", null, _toDisplayString($options.perPageLabel[1]), 1 /* TEXT */)]);
}