import { getTranslate } from './translate';
import { Export } from '@/utils';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import { getHtmlContent } from '@/utils/export';

/**
 * Component for displaying a table with the Article 30 questions and question answers
 */
export default {
  name: 'SoaTable',
  components: {
    TrafficLight: TrafficLight
  },
  props: {
    rows: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      translate: getTranslate['Table']()
    };
  },
  methods: {
    /**
     * Method for exporting the table data to excel
     */
    handleExportToExcel: function handleExportToExcel() {
      var htmlContent = getHtmlContent('table');
      return Export.toExcel(htmlContent);
    },
    /**
     * Method for printing the table data
     */
    handlePrintTable: function handlePrintTable() {
      return Export.toPrint(document.getElementById('tableContainer').innerHTML);
    },
    getTrafficLightColor: function getTrafficLightColor(row) {
      // SOA nodes should only have 1 gap
      if (row.gapanalysis !== undefined && row.gapanalysis.length === 1) {
        return row.gapanalysis[0].traffic_light_color;
      }
      return '';
    },
    getGapAnalysis: function getGapAnalysis(row) {
      // SOA nodes should only have 1 gap
      if (row.gapanalysis !== undefined && row.gapanalysis.length === 1) {
        return row.gapanalysis[0].analysis;
      }
      return '';
    },
    getGapControlAndInitiativeAnalysis: function getGapControlAndInitiativeAnalysis(row) {
      // SOA nodes should only have 1 gap
      if (row.gapanalysis !== undefined && row.gapanalysis.length === 1) {
        return row.gapanalysis[0].analysisControlsInitiatives;
      }
      return '';
    },
    getLinkedProcesses: function getLinkedProcesses(row) {
      return row.linkedActivities.compliance_process_tree;
    },
    getLinkedControls: function getLinkedControls(row) {
      return row.linkedActivities.control;
    },
    getLinkedInitiatives: function getLinkedInitiatives(row) {
      return row.linkedActivities.execution;
    }
  }
};