import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  key: 0,
  class: "flex -mb-1.5px sticky left-6 w-350px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_light_tab_item = _resolveComponent("light-tab-item");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_control_year_wheel_dashboard = _resolveComponent("control-year-wheel-dashboard");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.availableGroups.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_light_tab_item, {
    to: $data.baseLink + 'completion',
    "is-exact": true
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.completion), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]), $options.controlsEfficiencyEnabled ? (_openBlock(), _createBlock(_component_light_tab_item, {
    key: 0,
    to: $data.baseLink + 'efficiency',
    "is-exact": true
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.efficiency), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass({
      'border border-gray-350 p-3 overflow-auto': $options.availableGroups.length > 1
    })
  }, [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_control_year_wheel_dashboard, {
    key: 1,
    "dashboard-dataset": $options.dashboardDataset,
    "mother-controls": $data.motherControls,
    processes: $data.processes,
    "first-year": $options.firstYear,
    "last-year": $data.lastYear,
    legends: $options.selectedLegend,
    "download-url-extension": $options.efficiencySelected ? 'effectiveness' : '',
    onOpenPreview: _cache[0] || (_cache[0] = function ($event) {
      return $data.updatedControlId = $event;
    }),
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return $data.updatedControl = $event;
    }),
    onDismissModal: $options.onDismissModal
  }, null, 8 /* PROPS */, ["dashboard-dataset", "mother-controls", "processes", "first-year", "last-year", "legends", "download-url-extension", "onDismissModal"]))], 2 /* CLASS */)]);
}