import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'LinkItem',
  introduction: 'box for displaying links',
  description: 'This component is used to display linked items',
  token: '<link-item title="this is a test link" link="https://google.dk">',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    link: {
      type: String,
      required: true,
      note: 'The url that should be used'
    },
    title: {
      type: String,
      required: true,
      note: 'the displayed text on the linkItem'
    },
    tooltip: {
      type: String,
      required: false,
      default: '',
      note: 'Tooltip if needed'
    },
    isTag: {
      type: Boolean,
      default: false,
      note: 'If link item is a tag'
    },
    icon: {
      type: String,
      default: '',
      note: 'Feather icon before the link item'
    }
  },
  methods: {
    onBoxClick: function onBoxClick() {
      if (this.$refs.clickableLink) {
        this.$refs.clickableLink.click();
      }
    }
  }
};