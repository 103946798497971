import { getSettingsChangelog } from '@/api/admin/reports';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
export default {
  components: {
    MainWell: MainWell,
    RismaTable: RismaTable
  },
  methods: {
    getSettingsChangelogBE: function getSettingsChangelogBE(params) {
      var _this = this;
      return getSettingsChangelog("?".concat(params)).catch(function (err) {
        var _err$response;
        _this.$notify({
          title: err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error,
          type: 'error'
        });
        throw err;
      });
    }
  }
};