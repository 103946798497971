import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import { DateFormats } from '@/constants/DateFormats';
dayjs.extend(utc);
dayjs.extend(minMax);
export function calculateDifference(dateA, dateB, unitTime) {
  return dayjs(dateA).diff(dayjs(dateB), unitTime);
}
export function calculateUTCDifference(dateA, dateB) {
  return dayjs.utc(dateA).diff(dayjs.utc(dateB));
}
export function calculateDifferenceAsDays(dateA, dateB) {
  return calculateDifference(dateA, dateB, 'days');
}
export function getDateBeforeDate(date, amount, unit) {
  return dayjs(date).subtract(amount, unit);
}
export function getDateBeforeToday(amount, unit) {
  return dayjs().subtract(amount, unit);
}
export function getFormattedDateBeforeToday(amount, unit) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_FORMAT;
  return dayjs().subtract(amount, unit).format(format);
}
export function getFormattedUTCDateBeforeToday(amount, unit) {
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DateFormats.DATE_FORMAT;
  return dayjs.utc().subtract(amount, unit).format(format);
}
export function calculateUTCStartOfDate(date) {
  var startUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';
  var format = arguments.length > 2 ? arguments[2] : undefined;
  return dayjs.utc(date).startOf(startUnit).format(format);
}
export function calculateUTCEndOfDate(date) {
  var startUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';
  var format = arguments.length > 2 ? arguments[2] : undefined;
  return dayjs.utc(date).endOf(startUnit).format(format);
}
export function calculateUTCEndOfDateWithMove(moveNumber) {
  var moveUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';
  var date = arguments.length > 2 ? arguments[2] : undefined;
  var startUnit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'day';
  var format = arguments.length > 4 ? arguments[4] : undefined;
  return dayjs.utc(date).add(moveNumber, moveUnit).endOf(startUnit).format(format);
}
export function calculateStartOfDateAsDate(startUnit) {
  return dayjs().startOf(startUnit).toDate();
}
export function calculateStartDateBeforeTodayAsDate(value, unit, startUnit) {
  return dayjs().subtract(value, unit).startOf(startUnit).toDate();
}
export function calculatePastEndDateAsDate(value, unit, endUnit) {
  return dayjs().subtract(value, unit).endOf(endUnit).toDate();
}
export function calculateEndDateAsDate(endUnit) {
  return dayjs().endOf(endUnit).toDate();
}
export function calculateFutureEndDateByMonthsAsDate(months) {
  return dayjs().add(months, 'month').endOf('month').toDate();
}
export function getStartOfDateUnitFormatted(unit) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DateFormats.DATE_TIME_FORMAT_FULL;
  return dayjs().startOf(unit).format(format);
}
export function getEndOfDateUnitFormatted(unit) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DateFormats.DATE_TIME_FORMAT_FULL;
  return dayjs().endOf(unit).format(format);
}
export function getFortnightDateRange(format) {
  return {
    startDate: getFormattedDateBeforeToday(2, 'week', format),
    endDate: dayjs().format(format)
  };
}
export function getMaxDate(days) {
  var daysInstances = days.map(function (date) {
    return dayjs(date);
  });
  return dayjs.max(daysInstances);
}
export function getMinDate() {
  var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var daysInstances = days.map(function (date) {
    return dayjs(date);
  });
  return dayjs.min(daysInstances);
}