import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "attachment-selector flex justify-between"
};
var _hoisted_2 = {
  class: "flex items-center w-3/10 min-w-[52px]"
};
var _hoisted_3 = {
  class: "mr-2"
};
var _hoisted_4 = {
  class: "attachment-selector-files"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_FilePreviewModal = _resolveComponent("FilePreviewModal");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_link_item = _resolveComponent("link-item");
  var _component_attachment_modal = _resolveComponent("attachment-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.previewFile ? (_openBlock(), _createBlock(_component_FilePreviewModal, {
    key: 0,
    "file-src": $data.previewFile.fileSrc,
    "file-src-type": $data.previewFile.fileType,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.previewFile = null;
    })
  }, null, 8 /* PROPS */, ["file-src", "file-src-type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString($data.translate.attachments), 1 /* TEXT */), !$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "paperclip",
    class: "transform rotate-135 text-gray-610 stroke-2 cursor-pointer",
    onClick: _withModifiers($options.toggleModal, ["prevent"])
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", {
    class: _normalizeClass([{
      'no-title': !$props.showTitle
    }, "attachment-selector-files-wrap"])
  }, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.files, function (file) {
    return _openBlock(), _createElementBlock("div", {
      key: file.id,
      class: "attachment-selector-item"
    }, [_createVNode(_component_link_item, {
      link: file.url,
      title: file.title || file.filename,
      tooltip: file.title || file.filename,
      icon: file.mimeType ? 'file' : 'link'
    }, {
      preview: _withCtx(function () {
        return [file.mimeType === 'application/pdf' || file.mimeType && file.mimeType.substring(0, 5) === 'image' ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "hover:underline text-center mr-2",
          onClick: function onClick($event) {
            return $options.selectFilePreview(file);
          }
        }, _toDisplayString($data.translate.showPreview), 9 /* TEXT, PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)];
      }),
      end: _withCtx(function () {
        return [$props.removable ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "attachment-selector-remove",
          onClick: function onClick($event) {
            return $options.remove(file.id);
          }
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "text-white stroke-2 mr-1"
        })], 8 /* PROPS */, _hoisted_6)) : _createCommentVNode("v-if", true)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["link", "title", "tooltip", "icon"])]);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */), $data.isModalOpened ? (_openBlock(), _createBlock(_component_attachment_modal, {
    key: 1,
    "attach-file-error": _ctx.attachFileError,
    "attach-file-error-message": _ctx.attachFileErrorMessage,
    "show-footer-buttons": false,
    multiple: $props.multiple,
    "is-uploading": $data.isUploading,
    "uploading-value": $data.uploadingValue,
    onHideAttachFileError: _ctx.resetAttachFileError,
    onDismiss: $options.toggleModal,
    onUpload: $options.handleAttachmentsNew
  }, null, 8 /* PROPS */, ["attach-file-error", "attach-file-error-message", "multiple", "is-uploading", "uploading-value", "onHideAttachFileError", "onDismiss", "onUpload"])) : _createCommentVNode("v-if", true)]);
}