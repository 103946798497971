function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getProcessLibraryTitle } from '@/utils/TitleFromSetting';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { useSettingsStore } from '@/Store/settingsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
export var getCustomIAModules = function getCustomIAModules() {
  if (!useSettingsStore().isIACustomEnabled) {
    return {};
  }
  var customModules = useActivityTypesStore().complianceIACustomActivityTypes;
  var modulesToExport = {};
  customModules.forEach(function (module) {
    modulesToExport['information-assets-' + module.id] = {
      name: module.label,
      objType: ObjectTypes.INFORMATION_ASSET_LIMITED,
      activityTypeId: module.id,
      defaultLanguage: true
    };
  });
  return modulesToExport;
};
export var getUserActivityModules = function getUserActivityModules() {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.COMPLIANCE, {
    name: 'Compliance',
    objType: ObjectTypes.COMPLIANCE_NODE_LIMITED
  }), MODULES.COMPLIANCE_IA_CONTROLLER, {
    name: 'Data controllers',
    objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER
  }), MODULES.COMPLIANCE_IA_PROCESSOR, {
    name: 'Data processors',
    objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR
  }), MODULES.COMPLIANCE_IA_SYSTEM, {
    name: 'Systems',
    objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM
  }), MODULES.COMPLIANCE_PROCESS_TREE_NODE, {
    name: getProcessLibraryTitle(),
    objType: ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED,
    defaultLanguage: true
  }), MODULES.CONTROLS, {
    name: 'Controls',
    objType: ObjectTypes.CONTROLS_CONTROL_LIMITED
  }), MODULES.EXECUTION, {
    name: 'Actions',
    objType: ObjectTypes.EXECUTION_INITIATIVE_LIMITED
  }), MODULES.RISK, {
    name: 'Risk',
    objType: ObjectTypes.RISK_RISK_LIMITED
  }), MODULES.CONTRACTS, {
    name: 'Contracts',
    objType: ObjectTypes.CONTRACT_LIMITED
  }), MODULES.INCIDENTS, {
    name: 'Incidents',
    objType: ObjectTypes.INCIDENTS_INCIDENT_LIMITED
  });
};