import { Http } from '../index';
import * as HttpUtils from '../httpUtils';
export function resubmitRequest(requestId, deadline, message) {
  var data = {
    message: message,
    deadline: deadline
  };
  return Http.patch("dpm/resubmit/".concat(requestId), data).then(function (response) {
    return HttpUtils.checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return HttpUtils.throwError(err);
  });
}

/**
 * Load audit data by token
 *
 * @param token
 * @returns {Promise<T>}
 */
export function getByToken(token) {
  var url = "dpm/audit/".concat(token);
  return HttpUtils.handleRequestResponse(Http.get(url));
}

/**
 * Save one answer to a question
 *
 * @param token
 * @param questionAnswer
 * @returns {Promise<T | string>}
 */
export function submitQuestionAnswer(token, questionAnswer) {
  var url = "dpm/auditquestionanswer/".concat(token);
  return HttpUtils.handleRequestResponse(Http.patch(url, questionAnswer));
}

/**
 * Submit questionnaire and close audit request
 *
 * @param token
 * @param questions
 * @returns {Promise<T | string>}
 */
export function submitAudit(token, questions) {
  var url = "dpm/audit/".concat(token);
  return HttpUtils.handleRequestResponse(Http.post(url, questions));
}