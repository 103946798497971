import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "gap-logic-information-box rounded-md relative min-h-6"
};
var _hoisted_2 = {
  class: "glob-l1"
};
var _hoisted_3 = {
  key: 0,
  class: "type"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 0,
  class: "text-white mt-4 underline font-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_gap_logic_information_box_question = _resolveComponent("gap-logic-information-box-question");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "triangle-left"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", {
    class: _normalizeClass(["py-1 px-2 bg-inherit rounded-md", {
      'w-29rem absolute': !$data.collapsed,
      'is-auto': $props.isAuto
    }])
  }, [_createElementVNode("div", {
    class: "box-title",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.collapsed = !$data.collapsed;
    })
  }, [_createElementVNode("p", _hoisted_2, _toDisplayString($options.type), 1 /* TEXT */), !$data.collapsed ? (_openBlock(), _createElementBlock("span", _hoisted_3, ":")) : _createCommentVNode("v-if", true), !$data.collapsed ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$trans($options.typeDescription)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), !$data.collapsed ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.questionAnswersGroups, function (questionAnswerGroup, questionnaireIndex) {
    return _openBlock(), _createElementBlock("div", {
      key: questionnaireIndex
    }, [$options.questionnaireCount > 1 ? (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(questionAnswerGroup.questionnaire.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionAnswerGroup['questionAnswers'], function (questionAnswer, index) {
      return _openBlock(), _createBlock(_component_gap_logic_information_box_question, {
        key: questionnaireIndex + '-' + index,
        question: questionAnswer
      }, null, 8 /* PROPS */, ["question"]);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
}