import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "security-groups-project-item"
};
var _hoisted_2 = {
  class: "security-groups-project-item"
};
var _hoisted_3 = {
  class: "security-groups-project-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.projectsGrouped, function (projectType) {
    return _openBlock(), _createElementBlock("div", {
      key: projectType.headline,
      class: "security-group-projects"
    }, [projectType.projects.length > 0 ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      type: "medium",
      title: projectType.headline
    }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectType.projects, function (project, i) {
      return _openBlock(), _createElementBlock("div", {
        key: "security-project-headline-".concat(i),
        class: "security-groups-project"
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
        enabled: false,
        "model-value": project.projectTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["model-value"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
        disabled: true,
        options: $data.accessLevels,
        "model-value": project.accessLevel
      }, null, 8 /* PROPS */, ["options", "model-value"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_button, {
        text: "".concat($data.translate.update),
        type: "util",
        onClick: function onClick($event) {
          return $options.handleUpdate(project);
        }
      }, null, 8 /* PROPS */, ["text", "onClick"])])]);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */);
}