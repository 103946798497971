import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getSamlData() {
  return handleRequestResponse(Http.get('rismaAdmin/saml'));
}
export function createNewCertificate() {
  return handleRequestResponse(Http.post('rismaAdmin/saml', {
    action: 'generateKeyAndCert'
  }));
}
export function createFutureCertificate() {
  return handleRequestResponse(Http.post('rismaAdmin/saml', {
    action: 'prepareFutureCert'
  }));
}
export function rolloverCertificate() {
  return handleRequestResponse(Http.post('rismaAdmin/saml', {
    action: 'rollover'
  }));
}
export function deleteFutureCertificate() {
  return handleRequestResponse(Http.post('rismaAdmin/saml', {
    action: 'deleteFutureCert'
  }));
}