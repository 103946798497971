function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Line as LineChart } from 'vue-chartjs';
import { RismaVueChartMixin } from './RismaVueChartMixin';
import ExportFrame from '@/components/Atoms/Containers/ExportFrame';
export default {
  name: 'RismaLineChart',
  components: {
    LineChart: LineChart,
    ExportFrame: ExportFrame
  },
  mixins: [RismaVueChartMixin],
  props: {
    localStorageKey: {
      required: false,
      type: String,
      default: '',
      note: 'determine local storage key to safe state of chart'
    }
  },
  computed: {
    lineChartData: function lineChartData() {
      var storedState = localStorage.getItem(this.localStorageKey);
      var result = {
        labels: this.chartData.labels,
        datasets: this.chartData.datasets.map(function (dataset) {
          return _objectSpread({}, dataset);
        })
      };
      if (storedState) {
        storedState = JSON.parse(storedState);
        Array.isArray(storedState) && storedState.forEach(function (state) {
          if (!state.visible) {
            var idx = result.datasets.findIndex(function (item) {
              return item.label === state.label;
            });
            if (idx !== -1) {
              result.datasets[idx].hidden = true;
            }
          }
        });
      }
      return result;
    },
    lineChartOptions: function lineChartOptions() {
      var _this = this;
      var defaultOptions = this.chartOptions;
      if (this.localStorageKey) {
        defaultOptions.plugins.legend.onClick = function (_, legendItem, legend) {
          var index = legendItem.datasetIndex;
          var ci = legend.chart;
          var itemsList = [];
          ci.isDatasetVisible(index) ? ci.hide(index) : ci.show(index);
          legendItem.hidden = !legendItem.hidden;
          ci._metasets.forEach(function (item) {
            itemsList.push({
              label: item.label,
              visible: item.visible
            });
          });
          localStorage.setItem(_this.localStorageKey, JSON.stringify(itemsList));
        };
      }
      return defaultOptions;
    }
  }
};