import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaSplitButton',
  introduction: 'split buttons',
  description: 'A split button with the risma styling.',
  token: "<risma-split-button text=\"Save\" type=\"save\" @click=\"fn\">\n                <feather-icon icon=\"save\" />\n                <template v-slot:dropContent />\n            </risma-split-button>",
  components: {
    FeatherIcon: FeatherIcon
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    text: {
      type: String,
      required: true,
      note: 'the text that should appear on the button'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Bool to determine if the button should be disabled or not.'
    },
    disabledDropdown: {
      type: Boolean,
      default: false,
      note: 'Bool to determine if the dropdown should be disabled or not.'
    },
    verticalDropdownPosition: {
      type: String,
      default: 'bottom',
      validator: function validator(type) {
        return type === 'top' || type === 'bottom';
      },
      note: 'Vertical position of dropdown'
    },
    noHover: {
      type: Boolean,
      default: false,
      note: 'Set it to true if main button shouldn`t have hover effect'
    }
  },
  emits: ['click'],
  data: function data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    verticalPosition: function verticalPosition() {
      return this.verticalDropdownPosition === 'top' ? 'bottom-full' : 'top-full';
    },
    buttonStyle: function buttonStyle() {
      var str = "h-11 self-center flex items-center focus:outline-none active:outline-none\n                bg-blue-350 text-white px-15px font-semibold align-middle\n                whitespace-nowrap text-center border-none shadow-none";
      return str;
    },
    isSlotEmpty: function isSlotEmpty() {
      if (!this.$slots.dropContent) {
        return true;
      }
      var list = this.$slots.dropContent();
      return list.some(function (content) {
        return !!content.tag;
      });
    }
  },
  methods: {
    toggleDropdown: function toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hide: function hide() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    }
  }
};