import { getTranslate } from './translate';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
import { SortDirection } from '@/constants/SortDirection';
export default {
  name: 'DataTableColumnFilter',
  introduction: 'Modal for column filtering',
  description: 'Column filtering component for DataTable',
  token: '<data-table-column-filter></data-table-column-filter>',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    filterData: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    filterOptions: {
      required: false,
      type: Object,
      default: function _default() {}
    },
    additionalOption: {
      type: Object,
      default: null,
      note: 'used for include underlying frameworks'
    }
  },
  emits: ['sort', 'clickOutside', 'filterOptionSearch', 'filterOptionSelect', 'deselectAll', 'additionalOptionChange'],
  data: function data() {
    return {
      sortDir: SortDirection.ASC,
      columnIndex: null,
      positionTop: 0,
      positionLeft: null,
      positionRight: null,
      theadCell: null,
      filterText: '',
      filteringDisabled: false,
      sortingDisabled: false,
      listMaxHeight: '',
      translate: getTranslate['DataTableColumnFilter']()
    };
  },
  computed: {
    sortLabel: function sortLabel() {
      var result;
      if (this.sortDir === SortDirection.ASC) {
        result = 'Sort ascending';
      } else {
        result = 'Sort descending';
      }
      return this.$trans(result);
    },
    iconSort: function iconSort() {
      return this.sortDir === SortDirection.DESC ? 'chevron-down' : 'chevron-up';
    },
    filteringDisabledLabel: function filteringDisabledLabel() {
      return this.translate.filteringNotPossibleForThisColumn;
    },
    showNoResultsLabel: function showNoResultsLabel() {
      return !this.filteringDisabled && !this.filterOptions.some(function (option) {
        return !option.isHidden;
      });
    }
  },
  watch: {
    filterData: function filterData(newData) {
      this.updateData(newData);
    }
  },
  mounted: function mounted() {
    this.updateData(this.filterData);
  },
  methods: {
    sortColumn: function sortColumn() {
      this.toggleSort();
      this.$emit('sort', {
        index: this.columnIndex,
        dir: this.sortDir
      });
    },
    toggleSort: function toggleSort() {
      if (this.sortDir === SortDirection.ASC) {
        this.sortDir = SortDirection.DESC;
      } else {
        this.sortDir = SortDirection.ASC;
      }
    },
    onFilterReset: function onFilterReset() {
      this.filterText = '';
      this.$refs.filterInput.focus();
      this.$emit('filterOptionSearch', {
        value: '',
        key: this.columnIndex
      });
    },
    updateData: function updateData(data) {
      this.columnIndex = data.index;
      if (data.target) {
        this.updatePosition(data.target);
      }
      this.filteringDisabled = data.disabledFilterBy;
      this.sortingDisabled = data.disabledSorting;
    },
    setHeight: function setHeight(height) {
      var itemHeight = 160;
      var windowHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
      this.listMaxHeight = windowHeight - height - itemHeight + 'px';
    },
    updatePosition: function updatePosition(theadCell) {
      var filterWidth = this.$el.clientWidth || 250;
      var windowWidth = window.innerWidth;
      var rect = theadCell === null || theadCell === void 0 ? void 0 : theadCell.getBoundingClientRect();
      if (!rect) {
        return;
      }
      this.theadCell = theadCell;
      var isFilterOutOfTheWindow = rect.left + filterWidth > windowWidth;
      this.positionLeft = isFilterOutOfTheWindow ? 'auto' : rect.left + 'px';
      this.setTopPosition();
      window.addEventListener('scroll', this.setTopPosition);
      this.positionRight = isFilterOutOfTheWindow ? '0' : 'auto';
      this.setHeight(rect.bottom);
    },
    handleClickOutside: function handleClickOutside(event) {
      if (this.isVisible && !event.target.hasAttribute('data-column-index')) {
        window.removeEventListener('scroll', this.setTopPosition);
        this.$emit('clickOutside');
      }
      this.filterText = '';
    },
    setTopPosition: function setTopPosition() {
      var _this$theadCell;
      this.positionTop = ((_this$theadCell = this.theadCell) === null || _this$theadCell === void 0 || (_this$theadCell = _this$theadCell.getBoundingClientRect()) === null || _this$theadCell === void 0 ? void 0 : _this$theadCell.bottom) || 0;
    },
    additionalOptionChange: function additionalOptionChange(isChecked) {
      this.$emit('additionalOptionChange', {
        key: this.columnIndex,
        value: isChecked
      });
    }
  }
};