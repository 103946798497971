function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { uploadNewQuestionnaire, getQuestionTypes } from '@/api/rismaadmin/questionnaire';
import { getAvailableQuestionnaireTypes } from '@/utils/questionnaires';
import { ForbidenToUseIAForTypes, InnerContentQuestionTypes } from '@/constants/compliance/ProjectTypes';
import { getTranslate } from './translate';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import StaticTable from '@/components/Molecules/StaticTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaFileUpload from '@/components/Atoms/Inputs/RismaFileUpload';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
export default {
  name: 'Uploadquestionnaire',
  components: {
    MainWell: MainWell,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    StaticTable: StaticTable,
    RismaButton: RismaButton,
    InputField: InputField,
    SingleSelect: SingleSelect,
    RismaFileUpload: RismaFileUpload,
    RismaTitle: RismaTitle
  },
  data: function data() {
    return {
      origins: [{
        value: 'Customer',
        label: 'Customer'
      }, {
        value: 'Plesner',
        label: 'Plesner'
      }],
      InnerContentQuestionTypesIds: Object.values(InnerContentQuestionTypes),
      questionTypes: [],
      questionnaireTypes: [],
      questionnaireTypeId: null,
      origin: null,
      parsedQuestions: null,
      questionnaireTitle: '',
      files: null,
      csvVerified: false,
      loading: false,
      questionnaireTabLabel: 'Questionnaire',
      columnsMaxWidth: {
        question: '300px',
        frameworks: '200px'
      },
      translate: getTranslate['UploadQuestionnaire']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useFrameworksStore, ['enabledFrameworks'])), {}, {
    columns: function columns() {
      return [this.translate.ordering, this.translate.questionNumber, this.translate.headline, this.translate.question, this.translate.questionDependency, this.translate.questionType, this.translate.questionTypeId, this.translate.flags, this.translate.questionnaireReport, this.translate.status, this.translate.frameworks];
    },
    innerContentQuestions: function innerContentQuestions() {
      var _this = this;
      if (!this.parsedQuestions.length) return [];
      var result = [];
      this.parsedQuestions.forEach(function (question) {
        if (result.includes(question.questionType)) return;
        if (_this.InnerContentQuestionTypesIds.includes(question.questionTypeId)) {
          result.push(question.questionType);
        }
      });
      return result;
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    getAvailableQuestionnaireTypes().then(function (list) {
      _this2.questionnaireTypes = list.map(function (qt) {
        return {
          value: qt.id,
          label: qt.type
        };
      });
    });
    getQuestionTypes().then(function (response) {
      _this2.questionTypes = response.data.list;
    });
  },
  methods: {
    saveQuestionnaire: function saveQuestionnaire() {
      var _this3 = this;
      if (this.handleValidation() && this.files) {
        var createQuestionnaireObject = this.files[0];
        createQuestionnaireObject.questionnaireTitle = this.questionnaireTitle;
        createQuestionnaireObject.questionnaireTabLabel = this.questionnaireTabLabel;
        createQuestionnaireObject.origin = this.origin;
        createQuestionnaireObject.questionnaireType = this.questionnaireTypeId;
        createQuestionnaireObject.createQuestionnaire = true;
        this.loading = true;
        uploadNewQuestionnaire(createQuestionnaireObject).then(function () {
          _this3.loading = false;
          _this3.$notify({
            title: _this3.translate.questionnaireUploaded,
            type: 'success'
          });
        }).catch(function (error) {
          var _error$response;
          _this3.loading = false;
          _this3.$notify({
            title: (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.error,
            type: 'error'
          });
        });
      }
    },
    handleValidation: function handleValidation() {
      var error = [];
      if (!this.questionnaireTitle) {
        error.push(this.translate.pleaseStateTheQuestionnaireTitle);
      }
      if (!this.questionnaireTabLabel) {
        error.push(this.translate.pleaseSelectQuestionnaireTabLabel);
      }
      if (!this.questionnaireTypeId) {
        error.push(this.translate.pleaseSelectQuestionnaireType);
      }
      if (!this.origin) {
        error.push(this.translate.pleaseSelectQuestionnaireOrigin);
      }
      if (ForbidenToUseIAForTypes.includes(this.questionnaireTypeId) && this.innerContentQuestions.length) {
        error.push(this.translate.youCantUseNextQuestionTypes(this.innerContentQuestions.join(', ')));
      }
      this.$notify({
        title: error,
        type: 'error'
      });
      return !error.length;
    },
    uploadFiles: function uploadFiles(files) {
      var _this4 = this;
      var fileObject = files[files.length - 1];
      if (!fileObject) {
        return;
      }
      this.files = files;
      this.loading = true;
      return uploadNewQuestionnaire(fileObject).then(function (response) {
        var _response$title;
        _this4.loading = false;
        if (!response.list.length) {
          _this4.csvVerified = false;
          _this4.parsedQuestions = null;
          _this4.$notify({
            title: _this4.translate.fileIsEmptyPleaseUploadSomethingMoreSubsta,
            type: 'error'
          });
          return;
        }
        // Header values
        if ((_response$title = response.title) !== null && _response$title !== void 0 && _response$title.trim()) {
          _this4.questionnaireTitle = response.title;
        }
        var questionnaireType = _this4.questionnaireTypes.find(function (qtype) {
          return qtype.label == response.type;
        });
        if (questionnaireType) {
          _this4.questionnaireTypeId = questionnaireType.value;
        }
        if (response.origin && _this4.origins.some(function (item) {
          return item.value === response.origin;
        })) {
          _this4.origin = response.origin;
        }
        // Questions
        var parsedQuestions = response.list;
        var formattedParsedQuestions = [];
        parsedQuestions.forEach(function (question) {
          var questionTypeObject = _this4.questionTypes.find(function (questionType) {
            return questionType.id == question.questiontype;
          });
          formattedParsedQuestions.push({
            orderBy: question.orderBy,
            questionNumber: question.questionnumber,
            headline: question.headline,
            question: question.question,
            dependency: question.dependency,
            questionType: questionTypeObject.type,
            questionTypeId: questionTypeObject.id,
            flags: question.flags,
            questionnaireReport: question.isArticle30,
            status: question.status,
            frameworks: _this4.prepareFrameworksList(question.frameworks)
          });
        });
        _this4.parsedQuestions = formattedParsedQuestions;
        _this4.csvVerified = !response.hasErrors;
        if (!_this4.csvVerified) {
          _this4.$notify({
            title: _this4.translate.theQuestionnaireContainsErrors,
            type: 'error'
          });
        }
      }).catch(function (error) {
        _this4.loading = false;
        _this4.parsedQuestions = null;
        _this4.csvVerified = false;
        if (error.status === 500) {
          var _error$response2;
          _this4.$notify({
            title: "".concat(_this4.translate.fileContainsErrorsPleaseCheckTheContentAnd, " (").concat(((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error) || '', ")"),
            type: 'error'
          });
          return;
        }
        if (error.response === undefined) {
          _this4.$notify({
            title: error,
            type: 'error'
          });
        } else {
          var errorMessage = typeof error.response == 'string' ? error.response : error.response.error + ' - ' + error.response.message;
          _this4.$notify({
            title: "Error during upload: ".concat(errorMessage),
            type: 'error'
          });
        }
      });
    },
    prepareFrameworksList: function prepareFrameworksList(ids) {
      var names = this.enabledFrameworks.filter(function (_ref) {
        var id = _ref.id;
        return ids.includes(id);
      }).map(function (_ref2) {
        var displayName = _ref2.displayName;
        return displayName;
      });
      return names.reduce(function (acc, cur) {
        return acc + "<p class='mb-2'>".concat(cur, "</p>");
      }, '');
    }
  }
};