import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("li", {
    class: "static-tab-item",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.setActive && $options.setActive.apply($options, arguments);
    })
  }, [_createElementVNode("a", {
    class: _normalizeClass({
      'link-active': $props.active
    }),
    href: $props.to
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 10 /* CLASS, PROPS */, _hoisted_1)]);
}