import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "dnd-area-text"
};
var _hoisted_3 = {
  key: 1,
  class: "flex items-center p-2 text-sm"
};
var _hoisted_4 = ["multiple"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_progress_bar = _resolveComponent("progress-bar");
  var _component_files_list = _resolveComponent("files-list");
  return _openBlock(), _createElementBlock("div", null, [!$props.nativeDesign ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $data.translate.selectFile,
    class: "mt-4",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["dnd-area mb-2 bg-white border border-solid border-gray-450 rounded-lg", {
      'bg-gray-150 border-blue-50': $data.isDragOver,
      'flex justify-center items-center h-10': !$props.nativeDesign
    }])
  }, [!$props.nativeDesign ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, [_createTextVNode(_toDisplayString($data.translate.dragAndDropOr) + " ", 1 /* TEXT */), _createElementVNode("span", {
    class: "text-blue-450 cursor-pointer underline",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.clickInput && $options.clickInput.apply($options, arguments);
    })
  }, _toDisplayString($data.translate.browse), 1 /* TEXT */)])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
    class: "border border-gray-610 hover:bg-gray-350 rounded-sm py-1 px-2 bg-gray-250 cursor-pointer",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.clickInput && $options.clickInput.apply($options, arguments);
    })
  }, _toDisplayString($props.singleFileLoad ? $data.translate.chooseFile : $data.translate.chooseFiles), 1 /* TEXT */), _createVNode(_component_risma_title, {
    title: $options.filesLabel,
    type: "small",
    class: "ml-1"
  }, null, 8 /* PROPS */, ["title"])]))], 2 /* CLASS */), _createElementVNode("input", {
    ref: "fileInput",
    class: "input-load hidden",
    multiple: !$props.singleFileLoad,
    type: "file",
    onChange: _cache[2] || (_cache[2] = function (e) {
      return $options.handleLoad(e.target.files);
    }),
    onClick: _cache[3] || (_cache[3] = function (e) {
      return e.target.value = null;
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4), $data.uploading && $props.showProgressBar ? (_openBlock(), _createBlock(_component_progress_bar, {
    key: 1,
    value: $data.loadProgress
  }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), $props.showExtensionsTitle && !$data.existingFiles.length ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 2,
    class: "text-center text-gray-550",
    title: $options.supportExtensionTitle,
    truncate: false,
    type: "small"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), $props.showUploadedFiles ? (_openBlock(), _createBlock(_component_files_list, {
    key: 3,
    files: $data.existingFiles,
    onDelete: $options.deleteFile
  }, null, 8 /* PROPS */, ["files", "onDelete"])) : _createCommentVNode("v-if", true)]);
}