import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
var _hoisted_2 = {
  class: "py-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_tab_item = _resolveComponent("tab-item");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tab_item_new = _resolveComponent("tab-item-new");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_tab_item, {
    link: "uri-to-navigate-to",
    selected: true
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", null, [_createVNode(_component_feather_icon, {
        icon: "home",
        class: _normalizeClass(['text-blue-750 inline-block stroke-2'])
      }), _cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "pl-1 align-middle"
      }, "Text to be displayed", -1 /* HOISTED */))])];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    type: "medium",
    title: "TabItemNew component:"
  })]), _createVNode(_component_tab_item_new, {
    link: "/dev/kitchensink/atoms/tab-item",
    selected: true
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", null, [_createVNode(_component_feather_icon, {
        icon: "home",
        class: _normalizeClass(['text-blue-750 inline-block stroke-2'])
      }), _cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "pl-1 align-middle"
      }, "Text to be displayed", -1 /* HOISTED */))])];
    }),
    _: 1 /* STABLE */
  }), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_tab_item_new, {
    link: "/dev/kitchensink/molecules",
    selected: true
  }, {
    default: _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createElementVNode("span", {
        class: "pl-1 align-middle"
      }, "Text to be displayed", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_tab_item_new, {
    link: "/dev/kitchensink/molecules",
    disabled: true
  }, {
    default: _withCtx(function () {
      return _cache[3] || (_cache[3] = [_createElementVNode("span", {
        class: "pl-1 align-middle"
      }, "Text to be displayed", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  })]);
}