import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { getCompanies } from '@/api/admin/companies';
import { getTableContracts } from '@/api/contracts/contracts';
import { getAll } from '@/api/controls/controls';
import { getByUrl } from '@/api/httpUtils';
import { getData } from '@/test/data/tableData';
import { generateKey } from '@/utils/Utils';
export default {
  name: '',
  components: {
    RismaTable: RismaTable,
    SingleSelect: SingleSelect,
    InputField: InputField,
    RismaButton: RismaButton
  },
  data: function data() {
    return {
      selectedReport: 0,
      query: '',
      ownApi: '',
      additionalQueryParams: '',
      numberOfCalls: 0,
      ownApiError: false,
      stateKey: 'kitchensink_table_0',
      tableGeneratedKey: generateKey()
    };
  },
  computed: {
    predefinedReports: function predefinedReports() {
      return [{
        label: 'Mock BE(default)',
        value: 0
      }, {
        label: 'Companies report',
        value: 1
      }, {
        label: 'Contracts report',
        value: 2
      }, {
        label: 'Controls report',
        value: 3
      }];
    },
    predefinedApiCalls: function predefinedApiCalls() {
      var _this = this;
      return [this.apiMock, function (params) {
        return _this.getBEReport(params, getCompanies);
      }, function (params) {
        return _this.getBEReport(params, getTableContracts);
      }, function (params) {
        return _this.getBEReport('?' + params, getAll);
      }];
    },
    selectedApi: function selectedApi() {
      if (this.ownApi) return this.getCustomReport;
      if (!this.predefinedApiCalls[this.selectedReport]) return this.apiMock;
      return this.predefinedApiCalls[this.selectedReport];
    }
  },
  methods: {
    apiMock: function apiMock(params) {
      var fullQuery = params;
      if (this.additionalQueryParams) {
        fullQuery += '&' + this.additionalQueryParams;
      }
      this.query = fullQuery;
      this.numberOfCalls++;
      return new Promise(function (resolve) {
        return resolve(getData(fullQuery));
      });
    },
    getBEReport: function getBEReport(params, api) {
      var _this2 = this;
      var fullQuery = params;
      if (this.additionalQueryParams) {
        fullQuery += '&' + this.additionalQueryParams;
      }
      this.query = fullQuery;
      this.numberOfCalls++;
      return api(fullQuery).catch(function (error) {
        _this2.$notify({
          title: error.fullError.message,
          type: 'error'
        });
        throw error;
      });
    },
    getCustomReport: function getCustomReport(params) {
      var _this3 = this;
      this.ownApiError = false;
      var fullQuery = params;
      if (this.additionalQueryParams) {
        fullQuery += '&' + this.additionalQueryParams;
      }
      this.query = fullQuery;
      this.numberOfCalls++;
      return getByUrl(this.ownApi + '?' + fullQuery).catch(function () {
        _this3.ownApiError = true;
        _this3.$notify({
          title: 'Something went wrong, please check api that you use',
          type: 'error'
        });
      });
    },
    onReportSelect: function onReportSelect(selectedReportIdx) {
      this.ownApi = '';
      this.ownApiError = false;
      this.selectedReport = selectedReportIdx;
      this.stateKey = 'kitchensink_table_' + selectedReportIdx;
      this.tableGeneratedKey = generateKey();
    },
    onDataStarred: function onDataStarred(_ref) {
      var id = _ref.id,
        starred = _ref.starred;
      alert('Item with id ' + id + ' should be starred:' + starred);
    },
    onIconClicked: function onIconClicked(_ref2) {
      var id = _ref2.id,
        data = _ref2.data;
      alert('Item with id ' + id + '. Data: ' + data);
    },
    onSearchButtonClick: function onSearchButtonClick() {
      this.stateKey = '';
      this.tableGeneratedKey = generateKey();
    }
  }
};