function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import Notification from '@/components/Molecules/Notification';
import PrivacyModal from '@/components/Molecules/Modal/PrivacyModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { login } from '@/api/login/login';
import { loginFailTypes } from '@/constants/loginFailTypes';
import { LogoSettings } from '@/constants/admin/CustomizationSettings';
import { humioEvent } from '@/api/humio';
import Modal from '@/components/Molecules/Modal/Modal';
import MfaLogin from '@/components/Pages/MFA/MfaLogin';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'WelcomePage',
  components: {
    MfaLogin: MfaLogin,
    Modal: Modal,
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    PrivacyModal: PrivacyModal,
    Notification: Notification,
    FeatherIcon: FeatherIcon
  },
  setup: function setup() {
    return {
      isDefaultLoginShown: getLocalStorageComputed('welcome_page_login_shown_key', false, true)
    };
  },
  data: function data() {
    return {
      data: {
        username: '',
        password: '',
        mfaToken: ''
      },
      showPrivacyModal: false,
      isMounted: false,
      errorFromBackend: '',
      showMFAModal: false,
      translate: getTranslate['WelcomePage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    isOIDC: function isOIDC() {
      return this.authMode === 'oidc';
    },
    isSAML: function isSAML() {
      return this.authMode === 'saml';
    },
    hideLoginFields: function hideLoginFields() {
      return this.isOIDC && this.getSettingValue('feature.hide_normal_login_fields');
    },
    showSingleSignon: function showSingleSignon() {
      return this.isOIDC || this.isSAML;
    },
    singleSignonUrl: function singleSignonUrl() {
      if (this.isOIDC) {
        return '/adconnect';
      }
      if (this.isSAML) {
        return '/saml/login';
      }
      return '';
    },
    logoSrc: function logoSrc() {
      return this.customersLogo || LogoSettings.DEFAULT_WITH_BLUE_TEXT;
    },
    rismaversion: function rismaversion() {
      return this.getSettingValue('app.version');
    },
    customersLogo: function customersLogo() {
      return this.getSettingValue('app.customer_logo') ? '/assets/customers/' + this.getSettingValue('app.customer_logo') : null;
    },
    hasAutoComplete: function hasAutoComplete() {
      return this.getSettingValue('feature.enterprise_security');
    },
    privacyPolicy: function privacyPolicy() {
      return this.getSettingValue('feature.privacy_policy');
    },
    authMode: function authMode() {
      return this.getSettingValue('app.authentication_mode');
    },
    getYear: function getYear() {
      var date = new Date();
      return date.getFullYear();
    },
    disclaimerTitle: function disclaimerTitle() {
      return "<b>".concat(this.translate.disclaimer, "</b>: ").concat(this.translate.rismasSystemsAndTheContentsIncorporatedIn, "<br><br>").concat(this.translate.byLoggingIntoAndUsingRISMAsSystemsYouAgree);
    },
    bottomLineText: function bottomLineText() {
      var url = "<a class='cursor-pointer uppercase hover:underline' target='_blank' rel='noopener noreferrer' href='https://support.rismasystems.com/new-csrd-solution'>".concat(this.translate.here, "</a>");
      return this.translate.clickAClasscursorpointerTargetblank(url);
    },
    loginButtonMouseOver: function loginButtonMouseOver() {
      if (this.getSettingValue('servername')) {
        return 'servername = ' + this.getSettingValue('servername');
      } else {
        return this.translate.logIn;
      }
    }
  }),
  mounted: function mounted() {
    if (this.currentUser && this.currentUser.id) {
      this.handleRedirect();
    } else {
      this.isMounted = true;
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      var username = this.data.username;
      var password = this.data.password;
      var mfaToken = this.data.mfaToken;
      this.errorFromBackend = '';
      login(username, password, mfaToken).then(function (response) {
        return _this.handleRedirect(response);
      }).catch(function (_ref) {
        var response = _ref.response;
        if (response.failType === loginFailTypes.LOGIN_FAIL_PASSWORD_EXPIRED) {
          window.location.href = "/change-password/".concat(response.userId);
        } else if (response.failType === loginFailTypes.LOGIN_FAIL_MISSING_MFA_TOKEN) {
          _this.showMFAModal = true;
          _this.data.mfaToken = '';
        } else {
          _this.errorFromBackend = response.error;
        }
      });
    },
    handleRedirect: function handleRedirect() {
      this.handleScreenHumio();
      var urlParams = new URLSearchParams(window.location.search);
      //shouldn`t be router.push -> need to update some missing data, that was already uploaded, skipLoginCheck page
      window.location.href = urlParams.get('returnUrl') || this.getSettingValue('risma.landing_page') || '/mypage';
    },
    handleScreenHumio: function handleScreenHumio() {
      var _window = window,
        screen = _window.screen,
        innerHeight = _window.innerHeight,
        innerWidth = _window.innerWidth,
        outerHeight = _window.outerHeight,
        outerWidth = _window.outerWidth;
      var availHeight = screen.availHeight,
        availLeft = screen.availLeft,
        availTop = screen.availTop,
        availWidth = screen.availWidth,
        colorDepth = screen.colorDepth,
        height = screen.height,
        width = screen.width;
      var data = {
        innerHeight: innerHeight,
        innerWidth: innerWidth,
        outerHeight: outerHeight,
        outerWidth: outerWidth,
        screen: {
          availHeight: availHeight,
          availLeft: availLeft,
          availTop: availTop,
          availWidth: availWidth,
          colorDepth: colorDepth,
          height: height,
          width: width
        }
      };
      return humioEvent('screenStatistics', '', data);
    },
    handleAzure: function handleAzure() {
      var openedWindow = window.open(this.singleSignonUrl, '', 'width=500,height=500');
      var interval = setInterval(function () {
        if (openedWindow.closed) {
          clearInterval(interval);
          window.location.reload();
        }
      }, 100);
    },
    moveToPassword: function moveToPassword() {
      document.getElementById('password-field').focus();
    },
    handleMfaLogin: function handleMfaLogin(token) {
      this.data.mfaToken = token;
      this.handleSubmit();
    },
    closeMfaModal: function closeMfaModal() {
      this.showMFAModal = false;
      this.data.mfaToken = '';
      this.errorFromBackend = '';
    }
  }
};