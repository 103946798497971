import cloneDeep from 'lodash/cloneDeep';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import { getModuleTitles, MODULES } from '@/constants/modules';
import { frameworkModules } from '@/constants/admin/FrameworkProjects';
import { getFrameworks as _getFrameworks } from '@/api/admin/frameworks';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
export default {
  name: 'FrameworksTable',
  components: {
    RismaToggle: RismaToggle,
    RismaDatatable: RismaDatatable,
    MainWell: MainWell
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      showIdsSwitchOption: false,
      modules: {},
      dataset: [],
      fullFrameworkList: [],
      translate: {
        showActivityIds: this.$trans('Show activity ids'),
        frameworkModule: this.$trans('Framework / Module')
      }
    };
  },
  computed: {
    columns: function columns() {
      var _this = this;
      var columns = {
        solutionModule: this.translate.frameworkModule
      };
      Object.keys(this.modules).forEach(function (moduleKey) {
        columns[moduleKey] = _this.modules[moduleKey];
      });
      return columns;
    }
  },
  watch: {
    showIdsSwitchOption: function showIdsSwitchOption() {
      this.updateDataset();
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoading = true;
      return this.getFrameworks().then(function () {
        _this2.getFrameworkModuleTitles();
        _this2.updateDataset();
        _this2.isLoading = false;
      });
    },
    getFrameworks: function getFrameworks() {
      var _this3 = this;
      return _getFrameworks().then(function (_ref) {
        var list = _ref.list;
        _this3.fullFrameworkList = cloneDeep(list);
      });
    },
    getFrameworkModuleTitles: function getFrameworkModuleTitles() {
      var _this4 = this;
      var modules = frameworkModules();
      var allModulesTitles = getModuleTitles();
      modules.forEach(function (module) {
        _this4.modules[module] = allModulesTitles[module];
      });
    },
    updateDataset: function updateDataset() {
      this.prepareDataset();
      this.enhanceDataset(this.dataset, this.columns);
    },
    prepareDataset: function prepareDataset() {
      var _this5 = this;
      this.dataset = this.fullFrameworkList.map(function (item) {
        var datasetItem = {
          solutionModule: {
            field: item.title
          }
        };
        Object.keys(_this5.modules).forEach(function (moduleKey) {
          var _item$usage;
          var idsArr = ((_item$usage = item.usage) === null || _item$usage === void 0 || (_item$usage = _item$usage.modules) === null || _item$usage === void 0 ? void 0 : _item$usage[moduleKey]) || [];
          if (moduleKey === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
            var _item$usage2, _item$usage3, _item$usage4, _item$usage5;
            idsArr = idsArr.concat(((_item$usage2 = item.usage) === null || _item$usage2 === void 0 || (_item$usage2 = _item$usage2.modules) === null || _item$usage2 === void 0 ? void 0 : _item$usage2.complianceIaSystem) || []);
            idsArr = idsArr.concat(((_item$usage3 = item.usage) === null || _item$usage3 === void 0 || (_item$usage3 = _item$usage3.modules) === null || _item$usage3 === void 0 ? void 0 : _item$usage3.complianceIaProcessor) || []);
            idsArr = idsArr.concat(((_item$usage4 = item.usage) === null || _item$usage4 === void 0 || (_item$usage4 = _item$usage4.modules) === null || _item$usage4 === void 0 ? void 0 : _item$usage4.complianceIaController) || []);
            idsArr = idsArr.concat(((_item$usage5 = item.usage) === null || _item$usage5 === void 0 || (_item$usage5 = _item$usage5.modules) === null || _item$usage5 === void 0 ? void 0 : _item$usage5.informationAssets) || []);
          }
          if (moduleKey === MODULES.COMPLIANCE_PROCESS_TREE_NODE) {
            var _item$usage6;
            idsArr = ((_item$usage6 = item.usage) === null || _item$usage6 === void 0 || (_item$usage6 = _item$usage6.modules) === null || _item$usage6 === void 0 ? void 0 : _item$usage6.complianceProcessTreeNode) || [];
          }
          datasetItem[moduleKey] = {
            field: _this5.showIdsSwitchOption ? idsArr : idsArr.length || ''
          };
        });
        return datasetItem;
      });
    }
  }
};