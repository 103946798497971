import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex justify-between -mx-2"
};
var _hoisted_2 = {
  class: "w-1/3 text-left mx-2"
};
var _hoisted_3 = {
  class: "text-center w-1/3 mx-2"
};
var _hoisted_4 = {
  class: "w-1/3 text-right mx-2"
};
var _hoisted_5 = {
  class: "flex justify-between divide-x divide-gray-450"
};
var _hoisted_6 = {
  class: "js-ellipses-box lg:w-2/5 sm:w-1/3 flex content-center flex-row-reverse items-center px-2"
};
var _hoisted_7 = {
  class: "truncate"
};
var _hoisted_8 = {
  class: "font-semibold"
};
var _hoisted_9 = {
  class: "lg:w-1/5 sm:w-1/3"
};
var _hoisted_10 = {
  class: "js-ellipses-box lg:w-2/5 sm:w-1/3 flex content-center items-center px-2"
};
var _hoisted_11 = {
  class: "truncate"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_bow_tie_ellipse = _resolveComponent("bow-tie-ellipse");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.riskCauses,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.addCause,
    type: "save",
    "is-custom-button-size": true,
    class: "max-w-220px top-button mr-auto"
  }, null, 8 /* PROPS */, ["text"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.incidents,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.addLink,
    type: "save",
    "is-custom-button-size": true,
    class: "max-w-220px top-button m-auto"
  }, null, 8 /* PROPS */, ["text"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    title: $data.translate.riskConsequences,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.addConsequences,
    type: "save",
    "is-custom-button-size": true,
    class: "max-w-220px top-button ml-auto"
  }, null, 8 /* PROPS */, ["text"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.causesGrid, function (columns, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "column-".concat(index),
      class: _normalizeClass({
        'flex flex-wrap': $options.causesGrid.length === 1
      })
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns, function (cause, causeIndex) {
      return _openBlock(), _createBlock(_component_bow_tie_ellipse, {
        key: "cell-".concat(causeIndex),
        "bow-styles": "border-".concat(cause.color, " h-12 w-32")
      }, {
        toolTip: _withCtx(function () {
          return [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, _toDisplayString(cause.controls && cause.controls.join(', ')), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString(cause.description), 1 /* TEXT */)])];
        }),
        default: _withCtx(function () {
          return [_createElementVNode("div", null, _toDisplayString(cause.controls && cause.controls.length), 1 /* TEXT */), _createElementVNode("div", _hoisted_7, _toDisplayString(cause.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bow-styles"]);
    }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.incidents, function (incident, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createElementVNode("div", {
      class: _normalizeClass("bow-tie-gray p-4 mx-4 my-6 text-xl font-semibold text-center\n              border-2 border-".concat(incident.color, " rounded-lg truncate"))
    }, _toDisplayString(incident.title), 3 /* TEXT, CLASS */)]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.consequencesGrid, function (columns, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "column-".concat(index),
      class: _normalizeClass({
        'flex flex-wrap': $options.consequencesGrid.length === 1
      })
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns, function (consequence, consequenceIndex) {
      return _openBlock(), _createBlock(_component_bow_tie_ellipse, {
        key: "cell-".concat(consequenceIndex),
        "bow-styles": "border-".concat(consequence.color, " h-12 w-32")
      }, {
        toolTip: _withCtx(function () {
          return [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(consequence.description), 1 /* TEXT */)])];
        }),
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_11, _toDisplayString(consequence.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bow-styles"]);
    }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))])])]);
}