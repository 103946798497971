import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "flex justify-end mb-4 absolute top-4 right-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_gap = _resolveComponent("gap");
  var _component_gap_report_thin = _resolveComponent("gap-report-thin");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.display,
    type: "medium",
    class: "mr-3"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_view_switcher, {
    view: $setup.viewSelected,
    "onUpdate:view": [_cache[0] || (_cache[0] = function ($event) {
      return $setup.viewSelected = $event;
    }), $options.updateView],
    views: $data.COMPLIANCE_REPORT_GAP_VIEWS
  }, null, 8 /* PROPS */, ["view", "views", "onUpdate:view"])])]), $setup.viewSelected === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_gap, {
    key: 0,
    "project-id": $props.projectId,
    "saved-report-options": $props.savedReportOptions
  }, null, 8 /* PROPS */, ["project-id", "saved-report-options"])) : _createCommentVNode("v-if", true), $setup.viewSelected === $data.viewTypes.RISMA_TABLE ? (_openBlock(), _createBlock(_component_gap_report_thin, {
    key: 1,
    "project-id": $props.projectId,
    "saved-report-options": $props.savedReportOptions
  }, null, 8 /* PROPS */, ["project-id", "saved-report-options"])) : _createCommentVNode("v-if", true)]);
}