import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "mr-2 pr-px"
};
var _hoisted_2 = {
  key: 0,
  class: "px-16 pb-16 text-blue-750"
};
var _hoisted_3 = {
  class: "flex flex-wrap justify-center mb-8"
};
var _hoisted_4 = {
  class: "font-bold text-title-small leading-6"
};
var _hoisted_5 = {
  class: "h-5 relative rounded-l-large rounded-r-large bg-slate-200"
};
var _hoisted_6 = ["title"];
var _hoisted_7 = {
  key: 0,
  class: "text-center whitespace-nowrap font-bold"
};
var _hoisted_8 = {
  key: 1,
  class: "text-center whitespace-nowrap font-bold text-title-small leading-6 bg-gray-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.showTimeline ? '' : 'mb-4')
  }, [_createElementVNode("div", {
    class: "flex items-center w-fit cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleTimeline && $options.toggleTimeline.apply($options, arguments);
    })
  }, [_createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$trans($setup.showTimeline ? 'Hide timeline' : 'Show timeline')), 1 /* TEXT */), _createVNode(_component_feather_icon, {
    icon: $setup.showTimeline ? 'chevron-up' : 'chevron-down',
    height: "20",
    width: "20",
    class: "pl-px stroke-2"
  }, null, 8 /* PROPS */, ["icon"])]), _createVNode(_Transition, {
    name: "slide-fade"
  }, {
    default: _withCtx(function () {
      return [$setup.showTimeline ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dates, function (date) {
        return _openBlock(), _createElementBlock("div", {
          key: date.label,
          class: "flex mr-8 last:mr-0"
        }, [_createElementVNode("div", {
          class: _normalizeClass([date.bgColor, "w-5 h-5 mr-1 rounded-full"])
        }, null, 2 /* CLASS */), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$trans(date.label)), 1 /* TEXT */)]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.timelineDates, function (date, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: _normalizeClass(["w-fit absolute transform -translate-x-1/2", $options.getDatesStyles(date)]),
          style: _normalizeStyle("left: ".concat(date.onTimeLine, "%")),
          title: date.title
        }, [date.formatted ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [!date.isBell ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [date.showDateTitle ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(date.formatted), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_feather_icon, {
          icon: "check-circle",
          height: "30",
          width: "30",
          class: _normalizeClass(["stroke-2 bg-white rounded-full ml-auto mr-auto", date.textColor])
        }, null, 8 /* PROPS */, ["class"]), date.showDateTitle ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(date.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createElementVNode("div", {
          class: _normalizeClass(["w-0.5 ml-auto mr-auto bg-gray-610", $options.getBellHeight(date.id)])
        }, null, 2 /* CLASS */), _createVNode(_component_feather_icon, {
          icon: "bell",
          height: "18",
          width: "18",
          class: _normalizeClass(["stroke-2 ml-auto mr-auto", date.textColor]),
          "fill-class": "fill-current"
        }, null, 8 /* PROPS */, ["class"])], 64 /* STABLE_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_6);
      }), 128 /* KEYED_FRAGMENT */)), $options.nowOnTimeline > 0 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "h-full rounded-l-large rounded-r-large bg-blue-750",
        style: _normalizeStyle("width: ".concat($options.nowOnTimeline, "%"))
      }, null, 4 /* STYLE */)) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })], 2 /* CLASS */);
}