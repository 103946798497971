function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getSelectableQuestionnairesByQuestionnaireTypeId } from '@/api/compliance/questionnaire';
import { resubmitRequest } from '@/api/compliance/dpm';
import { createNewAudit, getAllAudits, updateAuditDeadline } from '@/api/compliance/audit';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { QuestionnaireTypesAudit } from '@/constants/compliance/QuestionnaireTypes';
export var AuditGenericData = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, {
  titleSuffix: 'Audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateAuditDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, {
  titleSuffix: 'Data Processor Management',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateAuditDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, {
  titleSuffix: 'Audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateAuditDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), ObjectTypes.CONTRACT, {
  titleSuffix: 'Audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.CONTRACT],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateAuditDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
}), ObjectTypes.INFORMATION_ASSET, {
  titleSuffix: 'Send audit',
  questionnaireType: QuestionnaireTypesAudit[ObjectTypes.INFORMATION_ASSET],
  getAllQuestionnairesAPI: getSelectableQuestionnairesByQuestionnaireTypeId,
  createNewRequestAPI: createNewAudit,
  extendDeadlineAPI: updateAuditDeadline,
  askForMoreInfoAPI: resubmitRequest,
  getAllRequestsAPI: getAllAudits
});