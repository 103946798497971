function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useWorkflowStore } from '@/Store/workflowStore';
import { getAll as getIncidents } from '@/api/incident/incident';
import { getControls, getInitiatives, getRisks, getNodesFlat } from '@/api/risma/data';
import { getRiskProjects } from '@/api/risk/risk';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { ProcessLibraryTitleMixin } from '@/mixins/ProcessLibraryTitleMixin';
import { getUsersFromIds, getOrganisationsFromIds } from '@/utils/format/objectsFromIds';
import { getColumnLabelName } from '@/utils/CustomFields';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import * as formatDate from '@/utils/date';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { INCIDENT } from '@/constants/routes/incident';
import * as Utils from '@/utils/Utils';
import { MODULES } from '@/constants/modules';
export default {
  name: 'IncidentTypeExpanded',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle,
    RismaDatatable: RismaDatatable,
    TrafficLight: TrafficLight,
    RismaTitleWithIcon: RismaTitleWithIcon,
    PreviewModalLink: PreviewModalLink,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, ProcessLibraryTitleMixin],
  token: '<incident-type-expanded :typeId="3"></incident-type><script>',
  props: {
    typeId: {
      required: true,
      type: [Number, String],
      note: 'The id of the type that needs expanding'
    }
  },
  data: function data() {
    return {
      loading: false,
      incidents: null,
      controls: null,
      initiatives: null,
      risks: null,
      processes: null,
      riskProjects: [],
      decoupleFilterFields: [{
        key: 'organisations'
      }, {
        key: 'responsible'
      }, {
        key: 'accountable'
      }, {
        key: 'consulted'
      }, {
        key: 'informed'
      }, {
        key: 'initiatives'
      }, {
        key: 'controls'
      }, {
        key: 'risks'
      }, {
        key: 'processes'
      }, {
        key: 'frameworks'
      }],
      disabledFilterByColumns: ['activity', 'description'],
      columnsMeta: [{
        targets: ['risks'],
        width: '600px'
      }, {
        targets: ['id'],
        width: '50px'
      }],
      slotLinkFields: ['initiatives', 'controls', 'risks', 'processes'],
      isLinkUpdated: false,
      customFieldsByIncidentId: {},
      cfColumns: {},
      dataset: [],
      workflowStatuses: [],
      translate: getTranslate['IncidentTypeExpanded']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useActivityTypesStore, {
    activityTypes: 'incidentActivityTypes'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useWorkflowStore, ['getActivityStatusTitle'])), {}, {
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (x) {
        return x.id === parseInt(_this.typeId);
      });
    },
    severityIncluded: function severityIncluded() {
      return Utils.isFieldInOptionalFields(this.currentActivityType, 'severity');
    },
    descriptionIncluded: function descriptionIncluded() {
      return Utils.isFieldInOptionalFields(this.currentActivityType, 'description');
    },
    columns: function columns() {
      var columns = {
        activity: this.translate.activityType
      };
      if (this.severityIncluded) {
        columns.severity = this.translate.severity;
      }
      if (this.showIdFeatureEnabled) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          id: this.translate.id
        });
      }
      columns = _objectSpread(_objectSpread({}, columns), {}, {
        title: this.translate.title,
        description: this.descriptionIncluded ? this.translate.description : undefined,
        status: this.translate.status,
        confidential: this.translate.confidential,
        created: this.translate.created,
        incidentOccurred: this.translate.incidentOccurred,
        responsible: this.responsibleTitle(true),
        accountable: this.accountableTitle(true),
        consulted: this.consultedTitle(true),
        informed: this.informedTitle(true),
        organisations: this.translate.organisations,
        frameworks: this.translate.frameworks,
        initiatives: this.translate.linkedInitiatives,
        controls: this.translate.linkedControls,
        risks: this.translate.linkedRisks,
        processes: "".concat(this.translate.linked, " ").concat(this.processLibraryTitle)
      }, this.cfColumns);
      return columns;
    },
    columnsAsTrafficLight: function columnsAsTrafficLight() {
      var _this$currentActivity;
      if (!((_this$currentActivity = this.currentActivityType) !== null && _this$currentActivity !== void 0 && (_this$currentActivity = _this$currentActivity.fields) !== null && _this$currentActivity !== void 0 && _this$currentActivity.length)) return [];
      return this.currentActivityType.fields.filter(function (field) {
        return field.typeId === CustomFieldTypes.TRAFFIC_LIGHT;
      }).map(function (field) {
        return getColumnLabelName(field.id);
      });
    },
    baseLocationName: function baseLocationName() {
      return INCIDENT.incidentList;
    },
    showIdFeatureEnabled: function showIdFeatureEnabled() {
      return this.getSettingValue('feature.show_incident_id');
    }
  }),
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.loading = true;
      return Promise.all([getIncidents().then(function (response) {
        return _this2.incidents = response.filter(function (x) {
          return x.activityTypeId === parseInt(_this2.typeId);
        });
      }), getControls().then(function (_ref) {
        var list = _ref.list;
        return _this2.controls = list;
      }), getInitiatives().then(function (_ref2) {
        var list = _ref2.list;
        return _this2.initiatives = list;
      }), getRisks('?data[]=scores').then(function (_ref3) {
        var list = _ref3.list;
        return _this2.risks = list;
      }), getNodesFlat().then(function (_ref4) {
        var list = _ref4.list;
        return _this2.processes = list;
      }), getRiskProjects().then(function (_ref5) {
        var list = _ref5.list;
        return _this2.riskProjects = list;
      })]).then(function () {
        _this2.prepareCustomFieldOptions();
        _this2.dataset = _this2.prepareDataset();
        _this2.enhanceDataset(_this2.dataset, _this2.columns);
        _this2.loading = false;
      });
    },
    prepareCustomFieldOptions: function prepareCustomFieldOptions() {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(this.incidents, [this.currentActivityType]),
        cfByEntities = _this$getPropertiesWi.cfByEntities,
        cfColumns = _this$getPropertiesWi.cfColumns,
        cfDisabledColumns = _this$getPropertiesWi.cfDisabledColumns;
      this.cfColumns = cfColumns;
      this.customFieldsByIncidentId = cfByEntities;
      this.disabledFilterByColumns = this.disabledFilterByColumns.concat(cfDisabledColumns);
    },
    updateLinkedFromPreview: function updateLinkedFromPreview(data, field) {
      var index = this[field].findIndex(function (item) {
        return item.id === data.id;
      });
      if (index === -1) return;
      this[field].splice(index, 1, data);
      this.isLinkUpdated = true;
    },
    onLinkModalDismiss: function onLinkModalDismiss() {
      if (!this.isLinkUpdated) return;
      this.dataset = this.prepareDataset();
      this.enhanceDataset(this.dataset, this.columns);
      this.isLinkUpdated = false;
    },
    prepareDataset: function prepareDataset() {
      var _this3 = this;
      return this.incidents.map(function (x) {
        var incidentOccurred = formatDate.toLocalDateTime(x.incidentHappenedAt) || _this3.translate.notSet;
        var severityField = x.severity !== null ? DatatableRenderer.prepareTrafficLightField(x.severity, MODULES.INCIDENTS) : undefined;
        var dataset = {
          activity: {
            field: _this3.currentActivityType ? _this3.currentActivityType.label : ''
          }
        };
        if (severityField) {
          dataset.severity = {
            field: severityField,
            fieldText: (severityField === null || severityField === void 0 ? void 0 : severityField.label) || '',
            color: (severityField === null || severityField === void 0 ? void 0 : severityField.color) || ''
          };
        }
        if (_this3.showIdFeatureEnabled) {
          dataset = _objectSpread(_objectSpread({}, dataset), {}, {
            id: {
              field: x.id
            }
          });
        }
        dataset = _objectSpread(_objectSpread({}, dataset), {}, {
          title: {
            field: {
              url: "/incident/".concat(x.id, "/description"),
              title: x.title,
              confidential: x.confidential,
              hoverTitle: DatatableRenderer.getItemHoverTitle(x)
            },
            fieldText: x.title
          },
          description: x.description !== null ? {
            field: x.description
          } : undefined,
          status: {
            field: _this3.getActivityStatusTitle(x, MODULES.INCIDENTS)
          },
          confidential: {
            field: x.confidential ? _this3.translate.yes : _this3.translate.no
          },
          created: {
            field: formatDate.toLocalDateTime(x.createdAt)
          },
          incidentOccurred: {
            field: incidentOccurred
          },
          responsible: {
            field: getUsersFromIds(x.responsibleUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          accountable: {
            field: getUsersFromIds(x.accountableUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          consulted: {
            field: getUsersFromIds(x.consultedUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          informed: {
            field: getUsersFromIds(x.informedUserIds, _this3.users).map(function (u) {
              return u.display_name;
            }).join(', ')
          },
          organisations: {
            field: getOrganisationsFromIds(x.organisationIds, _this3.organisations).map(function (o) {
              return o.visible_name;
            }).join(', ')
          },
          frameworks: {
            field: Utils.findPropsByIds(x.solutionIds, _this3.frameworks, 'displayName')
          },
          initiatives: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.execution, _this3.initiatives, 'path')),
          controls: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.control, _this3.controls, 'path')),
          risks: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.risk, _this3.risks, 'rno', _this3.getRiskValues)),
          processes: _objectSpread({}, DatatableRenderer.getLinkedItemsAsRouterLink(x.links.compliance_process_tree_node, _this3.processes))
        }, _this3.customFieldsByIncidentId[x.id]);
        return dataset;
      });
    },
    getRiskValues: function getRiskValues(risk) {
      if (this.getSettingValue('feature.show_risk_links_with_levels') !== 1) {
        return '';
      }
      return DatatableRenderer.getRiskValues(risk, this.riskProjects);
    }
  }
};