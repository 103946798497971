import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, renderList as _renderList, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "flex justify-between items-center"
};
var _hoisted_2 = {
  key: 0,
  class: "p-2 bg-blue-50"
};
var _hoisted_3 = {
  key: 1,
  class: "p-2 bg-blue-50"
};
var _hoisted_4 = {
  key: 2,
  class: "mt-4 scrolling-component"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 3,
  class: "mt-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$questionnaire;
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_question_creation = _resolveComponent("question-creation");
  var _component_modal = _resolveComponent("modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [!!((_$props$questionnaire = $props.questionnaire) !== null && _$props$questionnaire !== void 0 && _$props$questionnaire.readonly) === false ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($data.translate.thisIsUserDefinedQuestionnaire), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.translate.thisIsOneOfRismasStandardQuestionnaires), 1 /* TEXT */)), $props.questionnaireId !== null && $props.questionnaire ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 2,
    text: $data.translate.addQuestion,
    type: "util",
    onClick: $options.toggleModal
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "inline-block stroke-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)]), $props.questionnaireId && $props.questionnaire && $data.editQuestionModalShown ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalTitle,
    "show-buttons": false,
    type: "big",
    onDismiss: $options.abortHandler
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_question_creation, {
        "questionnaire-id": +$props.questionnaireId,
        "questionnaire-type": $props.questionnaire.questionnaireType,
        questions: $data.questions,
        "input-data": $data.questionData,
        "custom-assets-options": _ctx.allCustomActivities,
        "is-canceled": $data.isCanceled,
        onCreated: $options.update,
        onCanceled: _cache[0] || (_cache[0] = function ($event) {
          return $data.isCanceled = false;
        }),
        onDismiss: $options.toggleModal
      }, null, 8 /* PROPS */, ["questionnaire-id", "questionnaire-type", "questions", "input-data", "custom-assets-options", "is-canceled", "onCreated", "onDismiss"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"])) : _createCommentVNode("v-if", true), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1,
    class: "mt-16"
  })) : _createCommentVNode("v-if", true), $props.questionnaireId !== 'null' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_withDirectives(_createVNode(_component_static_table, {
    columns: $options.columns,
    "dataset-table": $options.formattedData
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [_renderList($data.listOfLineThroughSlots, function (slotName) {
    return {
      name: slotName,
      fn: _withCtx(function (_ref) {
        var slotData = _ref.slotData;
        return [_createElementVNode("span", {
          class: _normalizeClass({
            'line-through': !slotData.isLineThroughRemoved
          })
        }, _toDisplayString(slotData.text), 3 /* TEXT, CLASS */)];
      })
    };
  }), _renderList($data.listOfEventSlots, function (eventSlotName) {
    return {
      name: eventSlotName,
      fn: _withCtx(function (_ref2) {
        var slotData = _ref2.slotData;
        return [_createElementVNode("a", {
          href: "#",
          class: _normalizeClass({
            'cursor-not-allowed': $data.isLoading
          }),
          onClick: function onClick($event) {
            return $options.handleTriggerEvent(slotData);
          }
        }, [_createVNode(_component_feather_icon, {
          class: _normalizeClass(slotData.iconClasses),
          icon: slotData.icon
        }, null, 8 /* PROPS */, ["class", "icon"]), _createTextVNode(" " + _toDisplayString(slotData.text), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_5)];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset-table"]), [[_vShow, !$data.isLoading]])])) : _createCommentVNode("v-if", true), $props.questionnaireId === 'null' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("h5", null, _toDisplayString($data.translate.noQuestionnaireIsSelected), 1 /* TEXT */), _createElementVNode("p", null, _toDisplayString($data.translate.pleaseSelectAQuestionnaireToContinue), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]);
}