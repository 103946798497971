import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import { Colors } from '@/Colors';
export default {
  name: '',
  components: {
    RismaSelectorBar: RismaSelectorBar
  },
  data: function data() {
    return {
      rismaSelectorBarData: [{
        list: 'gaps',
        label: 'Gaps',
        preSelected: [{
          id: Colors.compliance_gap[3].number,
          label: Colors.compliance_gap[3].label,
          color: Colors.compliance_gap[3].color
        }, {
          id: Colors.compliance_gap[4].number,
          label: Colors.compliance_gap[4].label,
          color: Colors.compliance_gap[4].color
        }]
      }],
      rismaSelectorBarProjectId: 0
    };
  }
};