import i18n from '@/i18n';
export var getTranslate = {
  ControlDetails: function ControlDetails() {
    return {
      areYouSureTheControlInclExecutedControls: i18n.t('Are you sure? The Control incl. executed controls will be permanently deleted'),
      delete: i18n.t('Delete'),
      controlDeadline: i18n.t('Control deadline'),
      deadline: i18n.t('Deadline'),
      timezone: i18n.t('Timezone'),
      reviewDeadline: i18n.t('Review deadline'),
      daughterControlDeadlineSettings: i18n.t('Daughter control deadline settings'),
      frequency: i18n.t('Frequency'),
      trafficLightTurnsYellow: i18n.t('Traffic light turns yellow'),
      description: i18n.t('Description'),
      expectedEvidence: i18n.t('Expected evidence'),
      completion: i18n.t('Completion'),
      signoffPeriodPending: i18n.t('Signoff period pending'),
      descriptionFromMotherControl: i18n.t('Description from Mother Control'),
      expectedEvidenceFromMotherControl: i18n.t('Expected evidence from Mother Control'),
      attachmentsFromMotherControl: i18n.t('Attachments from Mother Control'),
      changedFields: i18n.t('Changed fields'),
      createNewDaughterControl: i18n.t('Create new daughter control'),
      copyMotherControlWithDaughterControls: i18n.t('Copy mother control with daughter controls'),
      sendToJira: i18n.t('Send to Jira'),
      controlNotFound: i18n.t('Control not found'),
      youDoNotHavePermissionToViewThisControl: i18n.t('You do not have permission to view this control'),
      controlCopied: i18n.t('Control copied'),
      deactivateControl: i18n.t('Deactivate control'),
      reopenControl: i18n.t('Re-open control')
    };
  },
  ControlEntry: function ControlEntry() {
    return {
      framework: i18n.t('Framework'),
      success: i18n.t('Success'),
      motherControl: i18n.t('Mother Control'),
      thisIsAMotherControlItHasNoResponsibleO: i18n.t('This is a mother control. It has no responsible, organisation, reviewer or any of the regular control settings. In stead, these settings are set on the daughter controls.'),
      daughterControls: i18n.t('Daughter controls'),
      createNewDaughterControl: i18n.t('Create new daughter control'),
      controlHasNoOwnerMessage1: i18n.t('This control has no responsible users!'),
      doYouWantToResetAndUpdateTagsForDaughterControls: i18n.t('Do you want to reset and update tags for daughter controls?'),
      controlHasNoOwnerMessage2: i18n.t('This means that it cannot be completed and no one will receive notifications about its deadline being overdue. You should assign this control to a new responsible or close it!'),
      theNumberHasToBeUnique: i18n.t('The number has to be unique. The number is already in use.'),
      confirmationNeeded: i18n.t('Confirmation needed'),
      theChangesToTheMotherControlsNumberWillChangeAll: i18n.t('The changes to the mother control’s number will change all daughter controls number, and the all historical data will inherent the new number.'),
      anErrorOccurred: i18n.t('An error occurred')
    };
  },
  ControlHistory: function ControlHistory() {
    return {
      nothingToShow: i18n.t('Nothing to show'),
      theControlHasNoHistoryBecauseItHasNotBee: i18n.t('The control has no history, because it has not been completed yet'),
      deadline: i18n.t('Deadline'),
      date: i18n.t('Date'),
      action: i18n.t('Action'),
      user: i18n.t('User'),
      attachments: i18n.t('Attachments'),
      comment: i18n.t('Comment'),
      customFields: i18n.t('Custom fields'),
      completedAfterDeadline: i18n.t('Completed after deadline'),
      completedOnTime: i18n.t('Completed on time'),
      theControlWasCompletedAfterDeadline: i18n.t('The control was completed after deadline'),
      theControlWasCompletedOnTime: i18n.t('The control was completed on time'),
      efficiencyAssessment: i18n.t('Effectiveness assessment'),
      efficiencyAssessmentComment: i18n.t('Effectiveness assessment comment')
    };
  },
  ControlReports: function ControlReports() {
    return {
      mainReport: i18n.t('Main report'),
      showDeactivatedControls: i18n.t('Show deactivated controls'),
      displayDeactivatedMotherControls: i18n.t('Display deactivated mother controls'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      automatic: i18n.t('Automatic'),
      manual: i18n.t('Manual'),
      preventive: i18n.t('Preventive'),
      detective: i18n.t('Detective'),
      high: i18n.t('High'),
      low: i18n.t('Low'),
      none: i18n.t('None'),
      date: i18n.t('Date'),
      action: i18n.t('Action'),
      user: i18n.t('User'),
      comments: i18n.t('Comments'),
      urlattachments: i18n.t('URL & attachments'),
      newDeadline: i18n.t('New deadline'),
      motherControls: i18n.t('Mother controls'),
      id: i18n.t('ID'),
      title: i18n.t('Title'),
      daughterControls: i18n.t('Daughter controls'),
      description: i18n.t('Description'),
      frameworks: i18n.t('Framework'),
      tags: i18n.t('Tags'),
      gaps: i18n.t('gaps')
    };
  },
  ControlsWrapper: function ControlsWrapper() {
    return {
      controls: i18n.t('Controls'),
      columnVisibility: i18n.t('Column visibility'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      createControl: i18n.t('Create control'),
      createMotherControl: i18n.t('Create Mother Control'),
      noTags: i18n.t('No tags'),
      noMotherControls: i18n.t('No mother controls'),
      noReviewer: i18n.t('No reviewer'),
      noResponsible: i18n.t('No responsible'),
      controlDeleted: i18n.t('Control deleted'),
      nothingToShow: i18n.t('Nothing to show'),
      no: i18n.t('No'),
      motherControls: i18n.t('Mother controls'),
      allControls: i18n.t('All controls'),
      controlsWithoutOwners: i18n.t('Controls without owners'),
      noControlsWarning: i18n.t('You either have no controls related to you or you need to edit your filtering criteria above to view controls')
    };
  },
  ControlsReview: function ControlsReview() {
    return {
      controlCompletedBy: i18n.t('Control completed by'),
      on: i18n.t('on'),
      comment: i18n.t('Comment'),
      notSet: i18n.t('Not set'),
      expectedEvidence: i18n.t('Expected evidence'),
      completed: i18n.t('Completed'),
      reviewDeadline: i18n.t('Review deadline'),
      optionallyAddComment: i18n.t('Optionally add comment'),
      addAttachment: i18n.t('Add attachment'),
      cancel: i18n.t('Cancel'),
      submit: i18n.t('Submit'),
      approve: i18n.t('Approve'),
      or: i18n.t('or'),
      reject: i18n.t('Reject'),
      reviewHistory: i18n.t('Review history'),
      controlHasNoReviewHistory: i18n.t('Control has no review history.'),
      customFields: i18n.t('Custom fields'),
      date: i18n.t('Date'),
      action: i18n.t('Action'),
      user: i18n.t('User'),
      commentsurl: i18n.t('Comments & URL'),
      attachments: i18n.t('Attachments'),
      reviewsFor: i18n.t('Reviews for'),
      thisReviewHasAlreadyBeenCompleted: i18n.t('This review has already been completed'),
      partialCompletion: i18n.t('Partial completion'),
      theFileWasNotUploadedPleaseTryAgain: i18n.t('The file was not uploaded. Please try again.'),
      saveAndClose: i18n.t('Save and close'),
      effectivenessAssessment: i18n.t('Effectiveness assessment'),
      effectivenessAssessmentComment: i18n.t('Effectiveness assessment comment')
    };
  },
  ControlsOverviewDashboard: function ControlsOverviewDashboard() {
    return {
      overview: i18n.t('Overview'),
      controlsCount: i18n.t('Controls count'),
      nothingToShow: i18n.t('Nothing to show')
    };
  },
  ControlsCompletionDashboard: function ControlsCompletionDashboard() {
    return {
      completion: i18n.t('Completion'),
      filter: i18n.t('Filter'),
      resetFilter: i18n.t('Reset filter'),
      nothingToShow: i18n.t('Nothing to show'),
      organisations: i18n.t('Organisations'),
      select: i18n.t('Select'),
      choosePeriod: i18n.t('Choose period'),
      apply: i18n.t('Apply'),
      noResultsFoundYouMayWantToWidenYourSearc: i18n.t('No results found. You may want to widen your search criteria'),
      controls: i18n.t('Controls'),
      processes: i18n.t('Processes'),
      controlsWithNoProcesSet: i18n.t('Controls without processes'),
      none: i18n.t('None')
    };
  },
  ControlsEfficiencyDashboard: function ControlsEfficiencyDashboard() {
    return {
      completion: i18n.t('Completion'),
      filter: i18n.t('Filter'),
      resetFilter: i18n.t('Reset filter'),
      nothingToShow: i18n.t('Nothing to show'),
      organisations: i18n.t('Organisations'),
      select: i18n.t('Select'),
      choosePeriod: i18n.t('Choose period'),
      apply: i18n.t('Apply'),
      noResultsFoundYouMayWantToWidenYourSearc: i18n.t('No results found. You may want to widen your search criteria'),
      controls: i18n.t('Controls'),
      processes: i18n.t('Processes'),
      controlsWithNoProcesSet: i18n.t('Controls without processes'),
      none: i18n.t('None'),
      efficiency: i18n.t('Effectiveness')
    };
  },
  MyControlsWrapper: function MyControlsWrapper() {
    return {
      success: i18n.t('Success'),
      completion: i18n.t('Completion'),
      review: i18n.t('Review'),
      signoffPeriodPending: i18n.t('Signoff period pending'),
      myControls: i18n.t('My controls'),
      myReviews: i18n.t('My reviews'),
      myControlsBeingReviewed: i18n.t('My controls being reviewed'),
      myRejectedControls: i18n.t('My rejected controls'),
      myOverdueControls: i18n.t('My overdue controls'),
      myDailyTeamControls: i18n.t('My daily team controls'),
      othersControls: i18n.t('Others controls'),
      controlDeleted: i18n.t('Control deleted'),
      nothingToShow: i18n.t('Nothing to show'),
      noControlsWarning: i18n.t('You either have no controls related to you or you need to edit your filtering criteria above to view controls')
    };
  },
  ControlsDashboardFilters: function ControlsDashboardFilters() {
    return {
      filters: i18n.t('Filters'),
      appliedFilters: i18n.t('Applied filters'),
      show: i18n.t('Show'),
      hide: i18n.t('Hide'),
      apply: i18n.t('Apply'),
      choosePeriod: i18n.t('Choose period'),
      dateFilter: i18n.t('Date filter'),
      completedDate: i18n.t('Completed date'),
      deadlinedDate: i18n.t('Deadline date'),
      select: i18n.t('Select'),
      responsible: i18n.t('Responsible'),
      organisations: i18n.t('Organisations'),
      activityType: i18n.t('Activity type'),
      controlsWithNoProcesSet: i18n.t('Controls without processes'),
      tags: i18n.t('Tags'),
      resetFilter: i18n.t('Reset filter'),
      reviewer: i18n.t('Reviewer'),
      motherControls: i18n.t('Mother Control'),
      frameworks: i18n.t('Frameworks'),
      includeUnderlying: i18n.t('Incl. underlying framework')
    };
  },
  ControlAnnualOverviewWrapper: function ControlAnnualOverviewWrapper() {
    return {
      completion: i18n.t('Completion'),
      efficiency: i18n.t('Effectiveness'),
      anErrorOccurred: i18n.t('An error occurred'),
      completedOnTime: i18n.t('Completed on time'),
      completedAfterDeadline: i18n.t('Completed after deadline'),
      controlNotPerformed: i18n.t('Control not performed'),
      notCompletedYet: i18n.t('Not completed yet'),
      awaitingReview: i18n.t('Awaiting review'),
      assessment: i18n.t('Assessment')
    };
  }
};