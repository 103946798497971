import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = ["data-is-menu-opened"];
var _hoisted_2 = {
  key: 0,
  class: "absolute z-2 w-17rem -right-20px top-0.7rem bg-white border border-gray-450"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  key: 0,
  class: "absolute left-full top-0 max-w-full w-max bg-white border border-gray-450"
};
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return $options.menuItems.length ? _withDirectives((_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "text-xs",
    "data-is-menu-opened": $data.showMenu
  }, [_createVNode(_component_feather_icon, {
    icon: "more-horizontal",
    class: "text-blue-750 stroke-1 h-full",
    onClick: $options.openMenu
  }, null, 8 /* PROPS */, ["onClick"]), $data.showMenu ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.menuItems, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: "menu_item_".concat(index),
      class: _normalizeClass(["flex items-center p-1.5 hover:bg-blue-250", {
        relative: item.isTemplateMenu
      }]),
      onClick: function onClick($event) {
        return item.isTemplateMenu ? $options.toggleAddFromTemplateSubmenu() : $options.menuItemClicked(item.click.type, item.click.data);
      }
    }, [_createVNode(_component_feather_icon, {
      icon: item.icon,
      class: "stroke-blue stroke-2 mr-1"
    }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("span", {
      class: _normalizeClass({
        'with-submenu': item.isTemplateMenu
      })
    }, _toDisplayString(item.text), 3 /* TEXT, CLASS */), item.isTemplateMenu ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createVNode(_component_feather_icon, {
      icon: $data.showAddFromTemplateSubmenu ? 'chevron-left' : 'chevron-right',
      class: "stroke-blue stroke-1 ml-auto"
    }, null, 8 /* PROPS */, ["icon"]), $data.showAddFromTemplateSubmenu ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menuItemsSettings.templateSubmenuItems, function (subItem, subIndex) {
      return _openBlock(), _createElementBlock("div", {
        key: subIndex,
        class: "p-1.5 hover:bg-blue-250",
        onClick: function onClick($event) {
          return $options.menuItemClicked('add from template', subItem.id);
        }
      }, _toDisplayString(subItem.name), 9 /* TEXT, PROPS */, _hoisted_5);
    }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_3);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_1)), [[_directive_click_outside, $options.closeMenu]]) : _createCommentVNode("v-if", true);
}