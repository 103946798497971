function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { create } from '@/api/compliance/informationAssets';
import Modal from '@/components/Molecules/Modal/Modal';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import InputField from '@/components/Atoms/Inputs/InputField';
import FrameworksSelector from '@/components/Molecules/FrameworksSelector';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { sortAlphabetic } from '@/utils/sort';
export default {
  name: 'InformationAssetCreationModal',
  description: 'Component for creating information assets',
  token: '<information-asset-creation-modal/>',
  components: {
    Modal: Modal,
    InputField: InputField,
    FrameworksSelector: FrameworksSelector,
    SingleSelect: SingleSelect,
    Notification: Notification,
    Checkboxes: Checkboxes
  },
  props: {
    type: {
      type: [Number, String],
      required: true,
      note: 'The type of information asset. Can be either'
    },
    activityTypeId: {
      type: Number,
      required: false,
      default: null,
      note: 'Activity type id'
    },
    dataProcessors: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of data processors'
    },
    defaultQuestionnaireId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'Custom IA default quesitonnaire id'
    }
  },
  emits: ['dismiss', 'created'],
  data: function data() {
    return {
      AssetsTypes: AssetsTypes,
      newInformationAsset: {
        title: '',
        solutions: [],
        parentId: 0
      },
      assetTypeNames: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.CONTROLLERS, 'controller'), AssetsTypes.PROCESSORS, 'processor'), AssetsTypes.SYSTEMS, 'system'), AssetsTypes.INFORMATION_ASSETS, 'information asset'),
      settingValues: _defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.SYSTEMS, 'feature.systems_default_questionnaire_id'), AssetsTypes.PROCESSORS, 'feature.processors_default_questionnaire_id'), AssetsTypes.CONTROLLERS, 'feature.controllers_default_questionnaire_id'),
      errorNotification: '',
      timer: null,
      translate: getTranslate['InformationAssetCreationModal'](),
      isCreating: false,
      isRismaSelectOpen: false,
      organisationsInheritanceChecked: true
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingsValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    filteredProcessors: function filteredProcessors() {
      var result = this.dataProcessors.filter(function (item) {
        var _item$parents;
        return item.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR && !((_item$parents = item.parents) !== null && _item$parents !== void 0 && _item$parents.length);
      });
      sortAlphabetic(result, 'name');
      return result.map(function (item) {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    modalHeader: function modalHeader() {
      var type = this.assetTypeNames[this.type] || this.assetTypeNames[AssetsTypes.INFORMATION_ASSETS];
      return "".concat(this.translate.new, " ").concat(this.$trans(type));
    },
    isCustomInformationAsset: function isCustomInformationAsset() {
      return ![AssetsTypes.CONTROLLERS, AssetsTypes.PROCESSORS, AssetsTypes.SYSTEMS].includes(this.type);
    },
    defaultActivityTypeByModule: function defaultActivityTypeByModule() {
      return _defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.CONTROLLERS, this.getSettingsValue('feature.data_controllers_default_activity_type_id')), AssetsTypes.PROCESSORS, this.getSettingsValue('feature.data_processors_default_activity_type_id')), AssetsTypes.SYSTEMS, this.getSettingsValue('feature.systems_default_activity_type_id'));
    },
    organisationsInheritanceEnabled: function organisationsInheritanceEnabled() {
      return this.getSettingsValue('feature.compliance.org_inherit_from_user');
    },
    inheritOrgCheckbox: function inheritOrgCheckbox() {
      return {
        checked: true,
        label: this.translate.addAssociatedOrganisations
      };
    }
  }),
  methods: {
    onCreate: function onCreate() {
      var _this = this;
      if (!this.validateNewInformationAsset()) return;
      this.isCreating = true;
      var data = this.getData();
      var type = this.isCustomInformationAsset ? 'informationAssets' : this.type;
      create(type, data).then(function (_ref2) {
        var data = _ref2.data;
        _this.$emit('created', data);
      }).catch(function (error) {
        var _error$response;
        if ((_error$response = error.response) !== null && _error$response !== void 0 && _error$response.error) {
          var _error$response2;
          _this.errorNotification = _this.$trans(((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.error) || error);
          _this.timer && clearTimeout(_this.timer);
          _this.timer = setTimeout(function () {
            _this.errorNotification = '';
          }, _this.$debounceTimeout);
        }
      }).finally(function () {
        _this.isCreating = false;
      });
    },
    validateNewInformationAsset: function validateNewInformationAsset() {
      if (!this.newInformationAsset.title.trim()) {
        this.errorNotification = this.translate.titleCannotBeEmpty;
        return false;
      }
      this.errorNotification = '';
      return true;
    },
    getData: function getData() {
      var _this$newInformationA;
      var responsibleUserIds = [this.currentUser.id];
      var solutionIds = (_this$newInformationA = this.newInformationAsset.solutions) === null || _this$newInformationA === void 0 ? void 0 : _this$newInformationA.map(function (s) {
        return s.id;
      });
      var organisations = this.currentUser.organisations;
      var data = {
        responsibleUserIds: responsibleUserIds,
        solutionIds: solutionIds,
        name: this.newInformationAsset.title
      };
      var defaultActivityTypeId = this.defaultActivityTypeByModule[this.type];
      if (defaultActivityTypeId) {
        data.activityTypeId = defaultActivityTypeId;
      }
      var questionnaireId = this.isCustomInformationAsset ? this.defaultQuestionnaireId : this.getSettingsValue(this.settingValues[this.type]);
      if (questionnaireId) {
        var param = this.type === AssetsTypes.PROCESSORS ? 'questionnaire_id' : 'questionnaireId';
        data[param] = questionnaireId;
      }
      if (this.isCustomInformationAsset) {
        data.activityTypeId = this.activityTypeId;
        data.title = this.newInformationAsset.title;
        data.questionnaires = questionnaireId ? [{
          questionnaireId: questionnaireId
        }] : [];
      } else {
        data.parentId = this.newInformationAsset.parentId;
        data.name = this.newInformationAsset.title;
      }
      if (this.organisationsInheritanceChecked && this.organisationsInheritanceEnabled) {
        data.organisationIds = organisations || [];
      }
      return data;
    }
  }
};