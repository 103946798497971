import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import * as Utils from '@/utils/Utils';
import { Colors } from '@/Colors';
export default {
  components: {
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink
  },
  props: {
    node: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'node'
    },
    allOrganisations: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all organisations'
    },
    users: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      },
      note: 'List of all users'
    }
  },
  data: function data() {
    return {
      colors: Colors,
      translate: getTranslate['CardProcessLibrary']()
    };
  },
  computed: {
    informed: function informed() {
      return Utils.findPropsByIds(this.node.informedUserIds, this.users, 'display_name');
    },
    consulted: function consulted() {
      return Utils.findPropsByIds(this.node.consultedUserIds, this.users, 'display_name');
    },
    accountable: function accountable() {
      return Utils.findPropsByIds(this.node.accountableUserIds, this.users, 'display_name');
    },
    responsible: function responsible() {
      return Utils.findPropsByIds(this.node.responsibleUserIds, this.users, 'display_name');
    },
    organisations: function organisations() {
      return Utils.findPropsByIds(this.node.organisationIds, this.allOrganisations, 'visible_name');
    }
  }
};