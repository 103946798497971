function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';

// Mixins
import { ControlDetailsCreateMixin } from '@/components/Pages/Controls/ControlDetailsCreateMixin';

// Atoms
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';

// Molecules
import Timezone from '@/components/Molecules/Timezone/Timezone';
import CompleteModal from '@/components/Molecules/Controls/CompleteModal';
import ControlFrequency from '@/components/Molecules/Controls/ControlFrequency';
import ControlTraffic from '@/components/Molecules/Controls/ControlTraffic';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import ControlCreationModal from '@/components/Molecules/Controls/ControlCreationModal';
import ConfidentialBox from '@/components/Molecules/ConfidentialBox';

// Organisms
import SendToJiraModal from '@/components/Organisms/SendToJiraModal';

// Utils and API
import * as Utils from '@/utils/Utils';
import { RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { isSignoffPeriodPending as _isSignoffPeriodPending } from '@/utils/controls/utils';
import * as ControlsApi from '@/api/controls/controls';
import * as DataAPI from '@/api/risma/data';

// Constants
import { DateFormats } from '@/constants/DateFormats';
import { ControlKinds } from '@/constants/ControlKinds';
import { ControlValidationMessages } from '@/constants/ControlValidationMessages';
import { Timezones } from '@/constants/Timezones';
import { ActivityLinkModules } from '@/constants/ActivityLinkModules';
import { Colors } from '@/Colors';
import { MODULES } from '@/constants/modules';
import ControlFieldsCompatibility from '@/constants/ControlFieldsCompatibility';
import { generateComputed } from '@/utils/base/generateComputed';
import { saveStatus } from '@/constants/SaveStatuses';
import { sortNumericByProp } from '@/utils/sort';
import { checkControlsOrgAccess, isLockedControlUser as _isLockedControlUser } from '@/utils/access';
import { clickOutside } from '@/utils/directives/clickOutside';
import { filterAsset } from '@/utils/format/objectsFromIds';
import { UserLevels } from '@/constants/UserLevels';
import { getEndOfDateUnitFormatted, isDateValid, formatTimeUtc, formatDateWithTimezone, addTimeAndFormatDate, formatTimeToDateTimeFormat } from '@/utils/date';
export default {
  name: 'ControlDetails',
  introduction: 'A parent component for other control details components.',
  description: 'This is Page component for /controls2/controls/:id',
  token: '<control-details></control-details>',
  components: {
    ConfidentialBox: ConfidentialBox,
    SendToJiraModal: SendToJiraModal,
    DateTimePicker: DateTimePicker,
    ToggleMenu: ToggleMenu,
    TinyMceBox: TinyMceBox,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    ControlFrequency: ControlFrequency,
    ControlTraffic: ControlTraffic,
    Timezone: Timezone,
    LinkItem: LinkItem,
    FeatherIcon: FeatherIcon,
    CompleteModal: CompleteModal,
    ActivityCustomFields: ActivityCustomFields,
    ControlCreationModal: ControlCreationModal
  },
  directives: {
    clickOutside: clickOutside
  },
  mixins: [ControlDetailsCreateMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.prevpage = from.fullPath;
    });
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Control object'
    },
    saveStatus: {
      type: String,
      required: false,
      default: saveStatus.SAVED
    }
  },
  emits: ['onCompleteControl', 'propertyChanged', 'showActiveStateNotify', 'notify'],
  data: function data() {
    return {
      confirmationMessage: '',
      confirmCallback: null,
      isLoaded: false,
      control: {},
      // Main control data model
      controlKind: null,
      // More: ControlKinds.js constant
      controls: [],
      // All controls
      riskRisks: [],
      dateFormat: DateFormats.DATE_TIME_FORMAT,
      dateTimeOptions: {
        displayFormat: DateFormats.DATE_TIME_FORMAT,
        format: DateFormats.DATE_TIME_FORMAT,
        defaultDate: getEndOfDateUnitFormatted('day'),
        showClear: false
      },
      chosenTimezone: {},
      attachments: [],
      prevpage: '',
      isCompleteModalShow: false,
      showSendToJira: false,
      MODULES: MODULES,
      saveStatusValues: saveStatus,
      showDeadlineModal: false,
      isCreationModalShow: false,
      translate: getTranslate['ControlDetails'](),
      isInnerModalShown: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), mapState(useOrganisationsStore, {
    organisations: 'organisationsSortedByVisibleName'
  })), mapState(useActivityTypesStore, ['controlActivityTypesWithFilteredFields'])), generateComputed(['description', 'expectedEvidence', 'customFieldValues', 'customFieldValuesParsed', 'turnYellowDaysBeforeDeadLine', 'createRemoteTickets'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    activityTypes: function activityTypes() {
      return this.controlActivityTypesWithFilteredFields(function (field) {
        return field.activityLinkModule === ActivityLinkModules.CONTROL;
      });
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.activityTypes.find(function (item) {
        return item.id === _this.data.activityTypeId;
      });
    },
    startPage: function startPage() {
      if (this.data.startPage && this.data.startPage.path) {
        return this.data.startPage.path;
      }
      return null;
    },
    selectedFrequencyLabel: function selectedFrequencyLabel() {
      if (this.data.frequency) {
        return this.$trans(RismaControlsUtils.frequency2text(this.data.frequency));
      }
      return '';
    },
    selectedTrafficLightIntervalLabel: function selectedTrafficLightIntervalLabel() {
      if (this.data.turnYellowDaysBeforeDeadLine) {
        return RismaControlsUtils.getTrafficLightTurnsYellowLabel(this.data.turnYellowDaysBeforeDeadLine, this.$trans);
      }
      return '';
    },
    isEvidenceVisibleFlag: function isEvidenceVisibleFlag() {
      return this.isEvidenceVisible();
    },
    cpath: function cpath() {
      return this.data.cpath;
    },
    isDaughter: function isDaughter() {
      return this.controlKind === ControlKinds.DAUGHTER;
    },
    hasDaughter: function hasDaughter() {
      return this.data.daughterControls && this.data.daughterControls.length > 0;
    },
    isLockedControlUser: function isLockedControlUser() {
      return _isLockedControlUser(this.currentUser);
    },
    inputsDisabled: function inputsDisabled() {
      return !this.data.active || this.isLockedControlUser;
    },
    descriptionReadOnly: function descriptionReadOnly() {
      if (!this.data.active) {
        return true;
      }
      if (this.isLockedControlUser) {
        return !this.getSettingValue('risma.unlock_description');
      }
      return false;
    },
    reviewDeadlineVisible: function reviewDeadlineVisible() {
      return this.data.userIds.review && this.data.userIds.review.length && !this.data.isMothercontrol;
    },
    attachmentsExisted: function attachmentsExisted() {
      return this.data.attachments.map(function (attachment) {
        if (!attachment.module && !attachment.mimeType && !attachment.size) {
          attachment.module = 'link';
        } else if (!attachment.module) {
          attachment.module = 'file';
        }
        return Object.assign({}, attachment);
      });
    },
    motherDescriptionTitle: function motherDescriptionTitle() {
      return this.translate.descriptionFromMotherControl;
    },
    motherExpectedEvidenceTitle: function motherExpectedEvidenceTitle() {
      return this.translate.expectedEvidenceFromMotherControl;
    },
    motherAttachmentsTitle: function motherAttachmentsTitle() {
      return this.translate.attachmentsFromMotherControl;
    },
    isMotherExpectedEvidenceShow: function isMotherExpectedEvidenceShow() {
      return this.data.mothercontrol && this.data.mothercontrol.expectedEvidence !== '' ? true : false;
    },
    isMotherAttachmentsShow: function isMotherAttachmentsShow() {
      return this.data.mothercontrol && this.data.mothercontrol.attachments.length > 0;
    },
    isSignoffPeriodPending: function isSignoffPeriodPending() {
      return _isSignoffPeriodPending(this.data.signoffStart, this.data.frequency, this.data.trafficLight, this.getSettingValue);
    },
    formatChangedFields: function formatChangedFields() {
      var result = this.translate.changedFields + ': ';
      for (var key in this.data) {
        var field = typeof ControlFieldsCompatibility[key] !== 'undefined' ? this.$trans(ControlFieldsCompatibility[key]) : key;
        result += field + ', ';
      }
      return result.slice(0, -2);
    },
    motherControlStateAlert: function motherControlStateAlert() {
      var action = this.data.active ? 'deactivating' : 're-opening';
      return this.$trans("Are you sure you want to proceed with ".concat(action, " the mother control and its daughter controls?"));
    },
    showChangeActiveStateControlButton: function showChangeActiveStateControlButton() {
      if (this.controlKind === ControlKinds.STANDARD) return true;
      return this.isDaughter && this.data.mothercontrol && this.data.mothercontrol.active;
    },
    dataEntryMenuOptions: function dataEntryMenuOptions() {
      var _this2 = this;
      var result = [{
        id: 2,
        title: this.$trans(this.getMenuCopyTitle(this.controlKind, ControlKinds)),
        icon: 'file-plus',
        action: function action() {
          var copyFunc = function copyFunc() {
            _this2.isLoaded = false;
            _this2.copyControl(false);
          };
          if (_this2.saveStatus !== saveStatus.SAVED) {
            _this2.confirmationMessage = _this2.$trans(ControlValidationMessages.CHANGES_MAY_NOT_BE_SAVED);
            _this2.shouldBeConfirmed(copyFunc);
          } else {
            copyFunc();
          }
        }
      }];
      if (this.showChangeActiveStateControlButton) {
        result.push({
          id: 3,
          title: this.data.active ? this.translate.deactivateControl : this.translate.reopenControl,
          icon: 'x',
          style: {
            color: Colors.system.warning
          },
          action: function action() {
            var newState = _this2.data.active ? 0 : 1;
            _this2.$emit('propertyChanged', {
              property: 'active',
              value: newState
            });
            _this2.$emit('showActiveStateNotify', newState);
          }
        });
      }
      if (this.controlKind === ControlKinds.MOTHER) {
        if (!this.inputsDisabled) {
          result.unshift({
            id: 1,
            title: this.translate.createNewDaughterControl,
            icon: 'plus',
            action: function action() {
              return _this2.isCreationModalShow = true;
            }
          });
        }
        result.push({
          id: 5,
          title: this.translate.copyMotherControlWithDaughterControls,
          icon: 'file-plus',
          action: function action() {
            var copyFunc = function copyFunc() {
              _this2.isLoaded = false;
              _this2.copyControl(true);
            };
            if (_this2.saveStatus !== saveStatus.SAVED) {
              _this2.confirmationMessage = _this2.$trans(ControlValidationMessages.CHANGES_MAY_NOT_BE_SAVED);
              _this2.shouldBeConfirmed(copyFunc);
            } else {
              copyFunc();
            }
          }
        });
        result.push({
          id: 6,
          title: this.$trans(this.data.active ? 'Deactivate mother control' : 'Re-open mother control'),
          icon: 'x',
          style: {
            color: Colors.system.warning
          },
          action: function action() {
            return _this2.$confirm(_this2.$trans(_this2.data.active ? 'Deactivate mother control' : 'Re-open mother control'), _this2.motherControlStateAlert, function (res) {
              return res && _this2.changeMotherControlActiveState();
            }, {
              buttonOkText: _this2.$trans(_this2.data.active ? 'Deactivate' : 'Re-open')
            });
          }
        });
      }
      if (this.currentUser.AccessLevels.Controls.canDelete) {
        result.push({
          id: 4,
          title: this.$trans(this.getMenuDeleteTitle(this.controlKind, ControlKinds)),
          icon: 'trash-2',
          style: {
            color: Colors.system.warning
          },
          action: function action() {
            if (_this2.hasDaughter || _this2.data.countDeactivatedDaughterControls > 0) {
              _this2.$emit('notify', {
                type: 'error',
                message: _this2.$trans(ControlValidationMessages.DISCONNECT_BEFORE_DELETE_MOTHER)
              });
            } else {
              _this2.confirmationMessage = _this2.translate.areYouSureTheControlInclExecutedControls;
              _this2.shouldBeConfirmed(_this2.deleteControl);
            }
          }
        });
      }
      if (this.getSettingValue('feature.send_to_jira')) {
        result.push({
          id: 5,
          title: this.translate.sendToJira,
          icon: 'share',
          action: function action() {
            _this2.showSendToJira = true;
          }
        });
      }
      return result;
    },
    confidentialityUsers: function confidentialityUsers() {
      var users = [];
      if (this.responsibleUsers.length) {
        users = users.concat(this.responsibleUsers);
      }
      if (this.reviewers.length) {
        users = users.concat(this.reviewers);
      }
      var uniqueUsersById = _toConsumableArray(new Map(users.map(function (item) {
        return [item.id, item];
      })).values());
      return uniqueUsersById;
    },
    confidentialityNames: function confidentialityNames() {
      if (this.confidentialityUsers.length) {
        return this.confidentialityUsers.map(function (user) {
          return user.display_name;
        }).join(', ');
      }
      return '';
    },
    responsibleUsers: function responsibleUsers() {
      var _this$data$userIds;
      return (_this$data$userIds = this.data.userIds) !== null && _this$data$userIds !== void 0 && _this$data$userIds.responsible ? filterAsset(this.users, this.data.userIds.responsible) : [];
    },
    reviewers: function reviewers() {
      var _this$data$userIds2;
      return (_this$data$userIds2 = this.data.userIds) !== null && _this$data$userIds2 !== void 0 && _this$data$userIds2.review ? filterAsset(this.users, this.data.userIds.review) : [];
    },
    confidentialReadOnly: function confidentialReadOnly() {
      var _this3 = this;
      return this.inputsDisabled || !this.responsibleUsers.find(function (user) {
        return user.id === _this3.currentUser.id;
      });
    },
    reviewDaysSettingDefaultValue: function reviewDaysSettingDefaultValue() {
      var _this$getSettingValue;
      return (_this$getSettingValue = this.getSettingValue('app.module_controls_review_days')) !== null && _this$getSettingValue !== void 0 ? _this$getSettingValue : 7;
    },
    reviewOffset: function reviewOffset() {
      var _ref, _this$data$reviewOffs, _this$data$mothercont;
      return (_ref = (_this$data$reviewOffs = this.data.reviewOffset) !== null && _this$data$reviewOffs !== void 0 ? _this$data$reviewOffs : (_this$data$mothercont = this.data.mothercontrol) === null || _this$data$mothercont === void 0 ? void 0 : _this$data$mothercont.reviewOffset) !== null && _ref !== void 0 ? _ref : this.reviewDaysSettingDefaultValue;
    },
    currentDate: function currentDate() {
      return formatTimeUtc(undefined, DateFormats.DATE_TIME_FORMAT);
    }
  }),
  watch: {
    cpath: function cpath() {
      this.determineControlKind();
    }
  },
  mounted: function mounted() {
    this.getAllData();
  },
  methods: {
    getAllData: function getAllData() {
      this.isLoaded = false;
      this.afterGetControl(this.data);
    },
    afterGetControl: function afterGetControl() {
      var _this4 = this;
      try {
        // this.handleControlTimezoneProper();

        this.determineControlKind();
        if (!this.data.active) {
          this.$emit('showActiveStateNotify', false);
        }

        // Load important assets
        this.loadOtherData().then(function () {
          _this4.isLoaded = true;
        });
      } catch (e) {
        this.isLoaded = true;
        this.prepareClientError(e);
      }
    },
    prepareClientError: function prepareClientError(e) {
      if (e && e.message) {
        this.$emit('notify', {
          type: 'error',
          message: e.message,
          details: e.stack
        });
      }
    },
    loadOtherData: function loadOtherData() {
      return Promise.all([this.getRiskRisks()]);
    },
    getRiskRisks: function getRiskRisks() {
      var _this5 = this;
      return DataAPI.getRisks().then(function (_ref2) {
        var list = _ref2.list;
        _this5.riskRisks = RismaControlsUtils.cloneEntities(list, RismaControlsUtils.addTitleWithRno);
        sortNumericByProp(_this5.riskRisks, 'rno');
      });
    },
    setDeadline: function setDeadline(deadline) {
      var date = deadline || this.currentDate;
      this.updateControlDateField(date, 'deadline', true);
    },
    determineControlKind: function determineControlKind() {
      if (this.data.isMothercontrol === 1) {
        this.controlKind = ControlKinds.MOTHER;
      } else if (this.data.mothercontrolId > 0) {
        this.controlKind = ControlKinds.DAUGHTER;
      } else {
        this.controlKind = ControlKinds.STANDARD;
      }
    },
    isEvidenceVisible: function isEvidenceVisible() {
      return this.getSettingValue('risma.show_expected_evidence') === 1;
    },
    addTimeOffset: function addTimeOffset(date, field) {
      if (isDateValid(date)) {
        this.data[field] = formatDateWithTimezone(date, this.chosenTimezone.value);
      }
    },
    updateControlDateField: function updateControlDateField(date, field, updateReview) {
      if (isDateValid(date)) {
        if (updateReview) {
          this.updateReviewDeadlinePlusDays(date, 'reviewDeadline');
        }
        var value = formatTimeToDateTimeFormat(date);
        this.$emit('propertyChanged', {
          property: field,
          value: value
        });
      }
    },
    updateReviewDeadlinePlusDays: function updateReviewDeadlinePlusDays(date, field) {
      var value = addTimeAndFormatDate(date, this.reviewOffset, 'days', this.dateFormat);
      this.$emit('propertyChanged', {
        property: field,
        value: value
      });
    },
    moveToNewControl: function moveToNewControl(data) {
      var _this6 = this;
      if (checkControlsOrgAccess(this.currentUser, UserLevels.SUPER) || data.userIds.responsible.find(function (id) {
        return +id === +_this6.currentUser.id;
      })) {
        return this.$router.push({
          path: data.url
        });
      }
      var message = this.translate.controlNotFound + ' / ' + this.translate.youDoNotHavePermissionToViewThisControl;
      this.$emit('notify', {
        message: message,
        type: 'error'
      });
    },
    deleteControl: function deleteControl() {
      var _this7 = this;
      this.isLoaded = false;
      return ControlsApi.deleteControl(this.cpath).then(function () {
        _this7.isLoaded = true;
        _this7.isDeleted = true;
        if (_this7.isPreview) {
          _this7.$router.go(0);
        } else if (_this7.startPage && _this7.startPage !== '/') {
          _this7.$router.push(_this7.startPage);
        } else {
          _this7.$router.push('/controls2/controls');
        }
      }).catch(function (error) {
        _this7.isLoaded = true;
        _this7.showErrorNotification(error);
      });
    },
    timezoneOnSelect: function timezoneOnSelect(e) {
      if (e) {
        this.chosenTimezone = Utils.findTimeOffset(Timezones, e);
        var data = {
          deadlineTimezone: e,
          deadline: this.data.deadline
        };
        if (this.data.reviewDeadline) {
          data.reviewDeadline = this.data.reviewDeadline;
        }
        this.$emit('propertyChanged', data);
      }
    },
    showErrorNotification: function showErrorNotification(response) {
      var notifyData = {
        type: 'error'
      };
      if (response && response.error) {
        notifyData.message = response.error;
      } else {
        notifyData.message = this.$trans(ControlValidationMessages.SOME_ERROR_OCCURRED);
      }
      this.$emit('notify', notifyData);
    },
    onAlertConfirm: function onAlertConfirm() {
      this.confirmCallback();
      this.confirmCallback = null;
    },
    shouldBeConfirmed: function shouldBeConfirmed(callback) {
      var _this8 = this;
      this.confirmCallback = callback;
      this.$confirm('', this.confirmationMessage, function (res) {
        return res && _this8.onAlertConfirm();
      });
    },
    controlFrequencyChanged: function controlFrequencyChanged(event) {
      if (event) this.$emit('propertyChanged', event);
    },
    updateReviewOffset: function updateReviewOffset(valueObj) {
      this.data.reviewOffset = valueObj.reviewOffset;
      this.updateReviewDeadlinePlusDays(this.data.deadline, 'reviewDeadline');
      this.$emit('propertyChanged', valueObj);
    },
    updatedAttachments: function updatedAttachments(files) {
      this.attachments = files;
    },
    handleCompleteControl: function handleCompleteControl() {
      this.isCompleteModalShow = true;
    },
    closeDeadlineModal: function closeDeadlineModal() {
      var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (this.isInnerModalShown) return;
      if (e === null || !e.composedPath().includes(this.$refs.deadlineModal)) {
        this.showDeadlineModal = false;
      }
    },
    changeMotherControlActiveState: function changeMotherControlActiveState() {
      var newState = this.data.active ? 0 : 1;
      this.$emit('propertyChanged', {
        property: 'active',
        value: newState
      });
      this.$emit('showActiveStateNotify', newState);
    },
    copyControl: function copyControl(withDaughters) {
      var _this9 = this;
      ControlsApi.copy(this.cpath, withDaughters).then(function (response) {
        var copiedControlCpath = response.data.cpath;
        _this9.$emit('notify', {
          type: 'success',
          message: _this9.translate.controlCopied
        });
        _this9.$router.push({
          path: "/controls2/controls/".concat(copiedControlCpath, "/description")
        });
      }).catch(function (error) {
        _this9.isLoaded = true;
        _this9.showErrorNotification(error);
      });
    }
  }
};