function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { MODULES } from '@/constants/modules';
export var AssetsTypes = {
  CONTROLLERS: 'controllers',
  PROCESSORS: 'processors',
  SYSTEMS: 'systems',
  INFORMATION_ASSETS: 'information_asset',
  INFORMATION_ASSETS_API: 'informationAssets',
  DATA_PROCESSOR_MANAGEMENT: 'dpm',
  CUSTOM: 'custom',
  LIA_NODES: 'lia_nodes',
  TIA_NODES: 'tia_nodes',
  TIA_PROC: 'tia_proc'
};
export function getModuleFromAssetType(assetType) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.CONTROLLERS, MODULES.COMPLIANCE_IA_CONTROLLER), AssetsTypes.PROCESSORS, MODULES.COMPLIANCE_IA_PROCESSOR), AssetsTypes.SYSTEMS, MODULES.COMPLIANCE_IA_SYSTEM), AssetsTypes.INFORMATION_ASSETS, MODULES.COMPLIANCE_INFORMATION_ASSETS)[assetType];
}