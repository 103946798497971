export default {
  name: 'TargetIcon',
  token: '<target-icon />',
  props: {
    width: {
      type: [String, Number],
      default: '20',
      note: 'the width of the icon'
    },
    height: {
      type: [String, Number],
      default: '20',
      note: 'the height of the icon'
    }
  }
};