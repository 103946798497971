import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-500px"
};
var _hoisted_2 = {
  class: "flex mb-4 justify-between"
};
var _hoisted_3 = {
  class: "flex mb-4 justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("h3", null, "Toggle menu with the 'default' button type:", -1 /* HOISTED */)), _createVNode(_component_toggle_menu, {
    options: $data.toggleMenuOptions
  }, null, 8 /* PROPS */, ["options"])]), _createElementVNode("div", _hoisted_3, [_cache[1] || (_cache[1] = _createElementVNode("h3", null, "Toggle menu with the 'compact' button type:", -1 /* HOISTED */)), _createVNode(_component_toggle_menu, {
    options: $data.toggleMenuOptions,
    "button-type": $data.MENU_BUTTON_TYPES.SECONDARY
  }, null, 8 /* PROPS */, ["options", "button-type"])])]);
}