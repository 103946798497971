import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withKeys as _withKeys, resolveDirective as _resolveDirective } from "vue";
var _hoisted_1 = {
  class: "single-select"
};
var _hoisted_2 = {
  key: 0,
  class: "flex justify-between mb-1"
};
var _hoisted_3 = {
  class: "glob-l1-sb"
};
var _hoisted_4 = ["tabindex"];
var _hoisted_5 = ["tabindex"];
var _hoisted_6 = {
  class: "select-selected-value"
};
var _hoisted_7 = {
  key: 1,
  class: "italic text-rBlack-500 truncate block"
};
var _hoisted_8 = ["placeholder"];
var _hoisted_9 = ["value", "onClick"];
var _hoisted_10 = {
  key: 0,
  class: "py-2 px-3 italic"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$props.title || $props.hasResetButton ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    class: "cursor-pointer",
    onClick: $options.toggle
  }, null, 8 /* PROPS */, ["title", "onClick"])) : _createCommentVNode("v-if", true), $props.hasResetButton ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "text-gray-820 flex items-end cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('reset');
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "rotate-ccw",
    class: "mr-2 stroke-2"
  }), _createElementVNode("div", _hoisted_3, _toDisplayString($data.translate.resetToDefault), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    tabindex: $props.disabled ? undefined : $props.tabindex,
    onFocus: _cache[6] || (_cache[6] = function () {
      return $options.show && $options.show.apply($options, arguments);
    }),
    onBlur: _cache[7] || (_cache[7] = function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }),
    onKeydown: [_cache[8] || (_cache[8] = _withKeys(_withModifiers(function () {
      return $options.prevOption && $options.prevOption.apply($options, arguments);
    }, ["prevent"]), ["up"])), _cache[9] || (_cache[9] = _withKeys(_withModifiers(function () {
      return $options.nextOption && $options.nextOption.apply($options, arguments);
    }, ["prevent"]), ["down"]))],
    onKeyup: _cache[10] || (_cache[10] = _withKeys(_withModifiers(function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }, ["stop"]), ["enter"]))
  }, [_createElementVNode("div", {
    class: _normalizeClass(['custom-select-inner', {
      expanded: $data.expanded,
      disabled: $props.disabled,
      'value-not-selected': !$options.selectedValue
    }])
  }, [!$options.lockedForReset ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "single-select-reset",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return $options.reset && $options.reset.apply($options, arguments);
    }, ["prevent"]))
  }, " × ")) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(['select-selected', {
      'select-arrow-active': $data.expanded
    }]),
    tabindex: $props.disabled ? undefined : $props.tabindex,
    onFocus: _cache[4] || (_cache[4] = _withModifiers(function () {}, ["stop"])),
    onClick: _cache[5] || (_cache[5] = function () {
      return $options.toggle && $options.toggle.apply($options, arguments);
    })
  }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
    class: _normalizeClass(["value-wrap border-2 border-gray-350 pl-3 py-2 rounded-md min-h-40px", "".concat(!$options.lockedForReset ? 'pr-14' : 'pr-9')])
  }, [_renderSlot(_ctx.$slots, "singleLabel", {
    option: $options.selectedValue
  }, function () {
    return [$options.selectedValue || $options.isPlaceholderInOptions ? (_openBlock(), _createBlock(_component_risma_title, {
      key: 0,
      title: $options.selectedValue ? $options.selectedValue.label : $props.placeholder,
      truncate: true,
      type: "small",
      style: {
        "font-size": "1rem"
      }
    }, null, 8 /* PROPS */, ["title"])) : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.placeholder), 1 /* TEXT */))];
  }, true)], 2 /* CLASS */)]), $props.withSearch ? _withDirectives((_openBlock(), _createElementBlock("input", {
    key: 0,
    ref: "filterString",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.filterString = $event;
    }),
    placeholder: $props.placeholder,
    type: "text",
    class: "filter-options-input absolute h-full outline-none inset-0 w-full px-4 border-2 border-gray-350 focus:border-blue-550",
    onFocus: _cache[3] || (_cache[3] = function () {
      return $options.show && $options.show.apply($options, arguments);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8)), [[_vShow, $data.expanded], [_vModelText, $data.filterString]]) : _createCommentVNode("v-if", true), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass([$props.chevronCustomLook, 'absolute top-0 bottom-0 h-full right-4 transform duration-500', {
      'rotate-180': $data.expanded
    }])
  }, null, 8 /* PROPS */, ["class"])], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_5), _createElementVNode("div", {
    ref: "selectItems",
    class: _normalizeClass(['select-items mt-1 rounded-md ', {
      'select-hide': !$data.expanded
    }])
  }, [!$props.disabled ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.values, function (option) {
    return _openBlock(), _createElementBlock("div", {
      key: option.value,
      value: option.value,
      class: _normalizeClass(['option pointer py-2 px-3 cursor-pointer hover:bg-gray-350', {
        selected: option.value === $props.modelValue
      }, {
        disabled: option.$disabled
      }, {
        'glob-l1 groupName': option.$isGroup
      }]),
      onClick: function onClick($event) {
        return $options.onClick(option);
      }
    }, [_renderSlot(_ctx.$slots, "option", {
      option: option
    }, function () {
      return [_createTextVNode(_toDisplayString(option.label), 1 /* TEXT */)];
    }, true)], 10 /* CLASS, PROPS */, _hoisted_9);
  }), 128 /* KEYED_FRAGMENT */)), !$options.values.length ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.withSearch && $data.filterString ? $data.translate.noResultsMatchingSearchCriteria : $data.translate.listIsEmpty), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */)], 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)])), [[_directive_click_outside, $options.hide]]);
}