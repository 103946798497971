// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader[data-v-7068d714] {
  width: 16px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, rgb(115, 115, 115) 92%, transparent) 33% 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1-7068d714 2s steps(4) infinite;
  background-size: 30% 100%;
}
@keyframes l1-7068d714 {
to {
    clip-path: inset(0 -34% 0 0);
}
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
