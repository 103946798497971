import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  class: "flex items-center mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notification = _resolveComponent("notification");
  var _component_risk_assessment_row = _resolveComponent("risk-assessment-row");
  return _openBlock(), _createElementBlock("div", null, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.guideData.name,
    "show-buttons": false,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "html-content-element",
        innerHTML: $data.guideData.contentHtml
      }, null, 8 /* PROPS */, _hoisted_1)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.riskAssessment,
    type: "big",
    class: "mr-6"
  }, null, 8 /* PROPS */, ["title"]), $options.hasGuide ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "info",
    class: "cursor-pointer text-blue-750",
    width: "24",
    height: "24",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.showModal = true;
    })
  })) : _createCommentVNode("v-if", true)]), $props.activity.riskAssessmentRelation && $props.objType === $props.activity.objType ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, [_createElementVNode("b", null, _toDisplayString($options.relationNotificationTitle), 1 /* TEXT */)]), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.riskValuesMustBeSetFrom), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.riskAssessmentsRows, function (row) {
    return _openBlock(), _createBlock(_component_risk_assessment_row, {
      key: 'consequence' + row.id,
      class: "mb-4",
      "obj-type": $props.objType,
      "row-data": $data.rowsData[row.id],
      row: row,
      "risk-assessment-groups": $options.riskAssessmentGroups[row.id],
      threats: $props.threats,
      "custom-threats-enabled": $props.customThreatsEnabled,
      "inherent-risks-enabled": $props.inherentRisksEnabled,
      "read-only": $props.readOnly || $props.activity.riskAssessmentRelation && $props.objType === $props.activity.objType,
      "start-expanded": $options.panelsStartExpanded,
      "threat-update-in-progress": $props.threatUpdateInProgress,
      onUpdateUnderlyingRisk: _cache[2] || (_cache[2] = function ($event) {
        return _ctx.$emit('updateUnderlyingRisk', $event);
      }),
      onOnValueChange: $options.onValueChange,
      onUpdateCustomThreat: $options.updateCustomThreat
    }, null, 8 /* PROPS */, ["obj-type", "row-data", "row", "risk-assessment-groups", "threats", "custom-threats-enabled", "inherent-risks-enabled", "read-only", "start-expanded", "threat-update-in-progress", "onOnValueChange", "onUpdateCustomThreat"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}