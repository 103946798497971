function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import Http from '@/api';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import { handleRequestResponse, getFiltersQueryString } from '@/api/httpUtils';
import { getRangesWithNextThreeMonths } from '@/constants/dates/daterangepicker';
import { isDateValid } from '@/utils/date';
import { removeDuplicates } from '@/utils/filterArray';
export default {
  name: 'RismaTableColumnFilters',
  components: {
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    InputField: InputField,
    RismaDateRangePicker: RismaDateRangePicker
  },
  inject: {
    tableDataLoading: {
      default: false
    }
  },
  props: {
    columnIndex: {
      type: [String, Number],
      required: true
    },
    filters: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    filterApi: {
      type: Object,
      required: false,
      default: null
    },
    filterFreeText: {
      type: Object,
      required: false,
      default: null
    },
    datesFilter: {
      type: Object,
      required: false,
      default: null
    },
    additionalOptionLabel: {
      type: String,
      default: '',
      note: 'used for include underlying frameworks'
    },
    additionalOptionValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['filtersChange', 'deselectAllFilters', 'additionalOptionChange'],
  data: function data() {
    return {
      searchString: '',
      filtersFromApi: [],
      isLoading: false,
      loadLimit: 20,
      loadStart: 0,
      lastLoadedItemsNumber: 0,
      filtersChangeTimeout: 400,
      debounceTimeout: null,
      locallyCheckedValues: [],
      ranges: null,
      dateRange: {
        before: '',
        after: ''
      },
      translate: getTranslate['RismaTableColumnMenu']()
    };
  },
  computed: {
    sortedFilters: function sortedFilters() {
      var _this = this;
      return _toConsumableArray(this.filteredFilters).sort(function (a, b) {
        var aChecked = _this.checkedValues.includes(a.value);
        var bChecked = _this.checkedValues.includes(b.value);
        return bChecked - aChecked;
      });
    },
    filteredFilters: function filteredFilters() {
      return removeDuplicates(this.availableFilters, 'value');
    },
    availableFilters: function availableFilters() {
      var _this2 = this;
      if (this.filterApi) {
        return this.filtersFromApi;
      }
      if (!this.searchString) return this.filters;
      return this.filters.filter(function (filterItem) {
        return filterItem.active || filterItem.label.toLowerCase().indexOf(_this2.searchString.toLowerCase()) !== -1;
      });
    },
    showLoadMoreButton: function showLoadMoreButton() {
      return this.lastLoadedItemsNumber === this.loadLimit;
    },
    hideSearchField: function hideSearchField() {
      var _this$filters;
      return !(this.filterApi || (_this$filters = this.filters) !== null && _this$filters !== void 0 && _this$filters.length || this.filterFreeText);
    },
    filterApiUrl: function filterApiUrl() {
      if (!this.filterApi || !this.filterApi.url) return null;
      var _this$filterApi = this.filterApi,
        activeValues = _this$filterApi.activeValues,
        url = _this$filterApi.url,
        urlParams = _this$filterApi.urlParams,
        searchField = _this$filterApi.searchField,
        valueField = _this$filterApi.valueField;
      var excludesQuery = '';
      if (!this.searchString) {
        excludesQuery = getFiltersQueryString(valueField, activeValues, false);
      }
      var searchParams = new URLSearchParams(excludesQuery || '');
      urlParams && Object.entries(urlParams).forEach(function (entry) {
        var _entry = _slicedToArray(entry, 2),
          key = _entry[0],
          val = _entry[1];
        searchParams.append(key, val);
      });
      searchParams.append('limit', this.loadLimit);
      searchParams.append('start', this.loadStart);
      searchParams.append(searchField, this.searchString);
      return url + '?' + searchParams.toString();
    },
    checkedValues: function checkedValues() {
      var _this$filterApi2;
      if (!this.filters && !this.filterApi) return [];
      if ((_this$filterApi2 = this.filterApi) !== null && _this$filterApi2 !== void 0 && _this$filterApi2.activeValues) {
        return this.filterApi.activeValues;
      }
      return this.filters.filter(function (filterItem) {
        return filterItem.active;
      }).map(function (_ref) {
        var value = _ref.value;
        return value;
      });
    }
  },
  watch: {
    searchString: function searchString() {
      var _this3 = this;
      if (this.filterApi) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.initFiltersWithApi, 200);
      }
      if (this.filterFreeText) {
        if (this.filterFreeText.searchString === this.searchString) {
          return;
        }
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(function () {
          return _this3.$emit('filtersChange', _this3.searchString);
        }, 500);
      }
    }
  },
  beforeMount: function beforeMount() {
    if (this.datesFilter) {
      var _this$datesFilter = this.datesFilter,
        before = _this$datesFilter.before,
        after = _this$datesFilter.after;
      if (isDateValid(before) && isDateValid(after)) {
        this.dateRange.before = before;
        this.dateRange.after = after;
      }
      this.ranges = getRangesWithNextThreeMonths();
    }
  },
  mounted: function mounted() {
    if (this.filterApi) {
      this.initFiltersWithApi();
    }
    if (this.filterFreeText) {
      this.searchString = this.filterFreeText.searchString;
    }
    this.locallyCheckedValues = this.checkedValues.slice(0);
  },
  methods: {
    initFiltersWithApi: function initFiltersWithApi() {
      var _this4 = this;
      this.isLoading = true;
      this.loadStart = 0;
      var promises = [this.getFiltersFromApi()];
      if (!this.searchString) {
        promises.push(this.getSelectedFiltersFromApi());
      }
      Promise.all(promises).then(function () {
        for (var _len = arguments.length, filters = new Array(_len), _key = 0; _key < _len; _key++) {
          filters[_key] = arguments[_key];
        }
        _this4.filtersFromApi = _toConsumableArray(filters.flat(2));
        _this4.isLoading = false;
      });
    },
    onDatePickerChange: function onDatePickerChange(event) {
      if (!event) return;
      this.dateRange.after = event.startDate;
      this.dateRange.before = event.endDate;
      this.$emit('filtersChange', [this.dateRange.after, this.dateRange.before]);
    },
    onLoadMoreClick: function onLoadMoreClick() {
      var _this5 = this;
      var loadAll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.loadStart += this.loadLimit;
      this.isLoading = true;
      this.loadLimit = loadAll ? 10000 : 20;
      return this.getFiltersFromApi().then(function (filters) {
        var _this5$filtersFromApi;
        (_this5$filtersFromApi = _this5.filtersFromApi).push.apply(_this5$filtersFromApi, _toConsumableArray(filters));
        _this5.isLoading = false;
      });
    },
    getSelectedFiltersFromApi: function getSelectedFiltersFromApi() {
      var _this6 = this;
      if (!this.filterApi || this.filterApi.activeValues.length === 0) {
        return Promise.resolve([]);
      }
      var url = this.filterApi.url;
      var query = '?' + getFiltersQueryString(this.filterApi.valueField, this.filterApi.activeValues);
      var urlParams = this.filterApi.urlParams;
      urlParams && Object.entries(urlParams).forEach(function (entry) {
        var _entry2 = _slicedToArray(entry, 2),
          key = _entry2[0],
          val = _entry2[1];
        query += "&".concat(key, "=").concat(val);
      });
      return handleRequestResponse(Http.get(url + query)).then(function (response) {
        if (!(response !== null && response !== void 0 && response.list)) return [];
        return _this6.mapListToFilters(response.list);
      });
    },
    getFiltersFromApi: function getFiltersFromApi() {
      var _this7 = this;
      if (!this.filterApiUrl) {
        return Promise.resolve([]);
      }
      return handleRequestResponse(Http.get(this.filterApiUrl)).then(function (response) {
        if (!(response !== null && response !== void 0 && response.list)) return [];
        _this7.lastLoadedItemsNumber = response.count || 0;
        return _this7.mapListToFilters(response.list);
      });
    },
    onAdditionalOptionChange: function onAdditionalOptionChange(isChecked) {
      this.$emit('additionalOptionChange', {
        filters: this.checkedValues,
        value: isChecked
      });
    },
    onFiltersChange: function onFiltersChange(value, event) {
      var _this8 = this;
      if (this.tableDataLoading) {
        event.preventDefault();
        return;
      }
      if (event.target.checked) {
        this.locallyCheckedValues.push(value);
      } else {
        this.locallyCheckedValues = this.locallyCheckedValues.filter(function (v) {
          return v !== value;
        });
      }
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(function () {
        return _this8.$emit('filtersChange', _this8.locallyCheckedValues);
      }, this.filtersChangeTimeout);
    },
    deselectAllFilters: function deselectAllFilters() {
      if (this.tableDataLoading) return;
      this.locallyCheckedValues = [];
      this.$emit('filtersChange', []);
    },
    mapListToFilters: function mapListToFilters(list) {
      var _this$filterApi3 = this.filterApi,
        labelField = _this$filterApi3.labelField,
        valueField = _this$filterApi3.valueField;
      return list.map(function (item) {
        return {
          label: item[labelField],
          value: item[valueField]
        };
      });
    }
  }
};