function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Http } from '../../index';
import { handleRequestResponse, throwError, checkHttpStatus } from '../../httpUtils';
export function getIAReport(questionnaireId, questionIds, ids) {
  var url = "/questionnaire/report?questionnaireId=".concat(questionnaireId, "&questionIds=").concat(questionIds);
  return handleRequestResponse(Http.get(url, {
    params: {
      ids: ids
    }
  }));
}
export function getAuditFollowup() {
  return Http.get('/compliance/informationAssets/report/audit').then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function getAnsweredAudits(questionnaireId, questionIds, ids, idField) {
  var params = _defineProperty({}, idField, ids);
  return Http.get("/dpm/reports/questionnaire?questionnaireId=".concat(questionnaireId, "&questionIds=").concat(questionIds), {
    params: params
  }).then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response;
  }).catch(function (err) {
    return throwError(err);
  });
}