import { getTranslate } from './translate';
import GapTableRow from '../../../Molecules/Compliance/Dashboard/GapTableRow';
import Pagination from '@/components/Atoms/Pagination';
export default {
  name: 'GapTable',
  components: {
    GapTableRow: GapTableRow,
    Pagination: Pagination
  },
  introduction: '',
  description: '',
  token: '<gap-table></gap-table>',
  props: {
    gaps: {
      required: true,
      type: Array,
      note: 'A list of gap objects.'
    },
    gapArticleMap: {
      required: true,
      type: Map,
      note: 'A list of gap articles'
    },
    showRiskAssessment: {
      type: Boolean,
      required: true,
      default: true,
      note: 'defines if risk assessment info should be displayed'
    },
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Project id from route'
    }
  },
  data: function data() {
    var pageSize = 100;
    return {
      pageSize: pageSize,
      gapsSlice: this.gaps.slice(0, pageSize),
      translate: getTranslate['GapTable']()
    };
  },
  watch: {
    gaps: {
      deep: true,
      handler: function handler() {
        this.gapsSlice = this.gaps.slice(0, this.pageSize);
      }
    }
  },
  methods: {
    handleShownList: function handleShownList(indexes) {
      this.gapsSlice = this.gaps.slice(indexes.start, indexes.end + 1);
    }
  }
};