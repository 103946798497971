import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { MODULES } from '@/constants/modules';
var FieldRendererMapping = {
  risma_state: DatatableRenderer.incidentState,
  workflow_status_id: DatatableRenderer.incidentStatus,
  trafficLight: DatatableRenderer.prepareTrafficLightField,
  traffic_light: DatatableRenderer.prepareTrafficLightField,
  severity: function severity(val) {
    return DatatableRenderer.prepareTrafficLightField(val, MODULES.INCIDENTS);
  },
  incident_happened_at: DatatableRenderer.datetime,
  deadline_utc: DatatableRenderer.datetime,
  review_deadline: DatatableRenderer.datetime,
  create_remote_tickets: DatatableRenderer.getRemoteTicketStateName,
  analysis_rag: DatatableRenderer.prepareGapColorField,
  vat_status: function vat_status(val) {
    return DatatableRenderer.prepareTrafficLightField(val, MODULES.COMPANY);
  }
};
export default FieldRendererMapping;