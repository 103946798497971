import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "my-6"
};
var _hoisted_2 = {
  class: "mb-6"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  class: "mb-6"
};
var _hoisted_6 = {
  class: "flex mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_static_table = _resolveComponent("static-table");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return [_cache[8] || (_cache[8] = _createElementVNode("p", null, "Here you can upload Questionnaires, create compliance projects and upload gaps.", -1 /* HOISTED */)), _cache[9] || (_cache[9] = _createElementVNode("p", null, "If you are creating a compliance project you need to follow these steps:", -1 /* HOISTED */)), _cache[10] || (_cache[10] = _createElementVNode("ol", null, [_createElementVNode("li", null, " Upload questionnaire (Unless you need to reuse a questionnaire already in the solution "), _createElementVNode("li", null, "Create the compliance project selecting the newly uploaded questionnaire"), _createElementVNode("li", null, " Upload the gap and choose the newly created project and select the option to override existing gaps ")], -1 /* HOISTED */)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        type: "medium",
        title: "Setup info"
      }), _createElementVNode("ul", null, [_createElementVNode("li", null, "RISMA version: " + _toDisplayString($options.rismaversion), 1 /* TEXT */), _createElementVNode("li", null, "Site url: " + _toDisplayString($options.siteurl), 1 /* TEXT */), _createElementVNode("li", null, "PHP Version: " + _toDisplayString($options.phpversion), 1 /* TEXT */), _createElementVNode("li", null, "Database name: " + _toDisplayString($options.databaseName), 1 /* TEXT */), _createElementVNode("li", null, "Database username: " + _toDisplayString($options.databaseUsername), 1 /* TEXT */), _createElementVNode("li", null, "Database version: " + _toDisplayString($options.databaseVersion), 1 /* TEXT */), _createElementVNode("li", null, "Database host: " + _toDisplayString($options.databasehost), 1 /* TEXT */), _createElementVNode("li", null, "wkhtmltopdf version: " + _toDisplayString($options.wkhtmltopdfversion), 1 /* TEXT */), _createElementVNode("li", null, "Server name: " + _toDisplayString($options.serverName), 1 /* TEXT */), _createElementVNode("li", null, "Max file upload size: " + _toDisplayString($options.maxUpload), 1 /* TEXT */), _createElementVNode("li", null, "SAML cert expires: " + _toDisplayString($options.samlCertificateExpiration), 1 /* TEXT */), _cache[3] || (_cache[3] = _createElementVNode("li", null, [_createElementVNode("a", {
        class: "hover:underline",
        href: "/risma2/swagger/index.html?url=/risma2/swagger.json"
      }, " API Documentation ")], -1 /* HOISTED */))])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        type: "medium",
        title: "Minio setup"
      }), $options.dbAttachmentsDisabled ? (_openBlock(), _createElementBlock("span", _hoisted_3, "MINIO sync ACTIVATED!")) : (_openBlock(), _createElementBlock("span", _hoisted_4, "MINIO sync not activated!")), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" app.db_attachments_disabled: " + _toDisplayString($options.dbAttachmentsDisabled) + " ", 1 /* TEXT */), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" minio_host: " + _toDisplayString($options.minioHost) + " ", 1 /* TEXT */), _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createVNode(_component_risma_button, {
        text: "Get database sizes",
        type: "save",
        class: "mt-4",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.getDatabaseSize();
        })
      })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
        type: "medium",
        title: "E-mail setup"
      }), _createTextVNode(" Override e-mail set to: " + _toDisplayString($options.overrideRecipientEmail) + " ", 1 /* TEXT */), _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.email = $event;
        }),
        name: "email",
        size: "75",
        class: "border border-gray-350"
      }, null, 512 /* NEED_PATCH */), [[_vModelText, $data.email]]), _createVNode(_component_risma_button, {
        text: "Send test e-mail",
        type: "save",
        class: "mt-4",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.sendTestEmail();
        })
      })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
        type: "medium",
        title: "Enable Humio logging",
        class: "mr-4"
      }), _createVNode(_component_risma_toggle, {
        "model-value": $options.humioLoginValue,
        "onUpdate:modelValue": $data.handleHumioLoginDebounce
      }, null, 8 /* PROPS */, ["model-value", "onUpdate:modelValue"])]), _createVNode(_component_risma_title, {
        type: "medium",
        title: "Latest logins",
        class: "mb-2"
      }), _createVNode(_component_static_table, {
        columns: $options.columns,
        "dataset-table": $options.dataset,
        theme: $data.tableThema
      }, {
        groups: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotData.groups, function (group) {
            return _openBlock(), _createElementBlock("div", null, _toDisplayString(group), 1 /* TEXT */);
          }), 256 /* UNKEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["columns", "dataset-table", "theme"])];
    }),
    _: 1 /* STABLE */
  })]);
}