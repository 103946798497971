import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [$props.multiple ? (_openBlock(), _createBlock(_component_risma_select, {
    key: 0,
    title: $options.translatedTitle,
    options: $options.options,
    multiple: $props.multiple,
    "selected-options": $options.modelValue,
    placeholder: $options.translatedPlaceholder,
    "open-direction": $props.openDirection,
    disabled: $props.disabled,
    "label-key": "displayName",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('selected', $event);
    }),
    onOpen: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('open');
    }),
    onClose: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('close');
    })
  }, _createSlots({
    _: 2 /* DYNAMIC */
  }, [$props.toggleObject ? {
    name: "newOption",
    fn: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_toggle, {
        "model-value": $props.toggleObject.option,
        class: "mr-1 switcher",
        onInput: $props.toggleObject.event
      }, null, 8 /* PROPS */, ["model-value", "onInput"]), _createElementVNode("span", null, _toDisplayString($props.toggleObject.title), 1 /* TEXT */)])];
    }),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "options", "multiple", "selected-options", "placeholder", "open-direction", "disabled"])) : (_openBlock(), _createBlock(_component_single_select, {
    key: 1,
    title: $options.translatedTitle,
    "model-value": $options.modelValue,
    options: $options.options,
    "with-reset": true,
    "un-sorted": true,
    placeholder: $options.translatedPlaceholder,
    disabled: $props.disabled,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('selected', $event);
    })
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "disabled"]))]);
}