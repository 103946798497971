import { getTranslate } from './translate';
import { saveReport as _saveReport } from '@/api/reports/reports';
import ReportSavingModal from '@/components/Molecules/Reports/ReportSavingModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import SavedReports from '@/components/Molecules/Reports/SavedReports';
import ButtonsRow from '@/components/Molecules/ButtonsRow';
export default {
  name: 'RismaTableReport',
  components: {
    ReportSavingModal: ReportSavingModal,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    SavedReports: SavedReports,
    ButtonsRow: ButtonsRow
  },
  props: {
    type: {
      required: true,
      type: String,
      note: 'Type from report-schema.json'
    },
    params: {
      required: false,
      type: String,
      default: '',
      note: 'Current table parameters'
    },
    complianceProjectId: {
      type: [Number, String],
      default: null,
      note: 'Compliance project id that corresponds to this report'
    },
    reportOptions: {
      type: Object,
      default: null,
      note: 'Report options that will be saved along with the report'
    }
  },
  emits: ['loadReport'],
  data: function data() {
    return {
      showSaveModal: false,
      showLoadModal: false,
      translate: getTranslate['RismaTableReport']()
    };
  },
  computed: {
    buttonsRowData: function buttonsRowData() {
      var _this = this;
      return [{
        text: this.translate.savedReports,
        type: 'util',
        clickEvent: function clickEvent() {
          return _this.showLoadModal = true;
        },
        slotName: 'folder'
      }, {
        text: this.translate.saveCurrentReport,
        type: 'util',
        clickEvent: function clickEvent() {
          return _this.showSaveModal = true;
        },
        slotName: 'save'
      }];
    }
  },
  methods: {
    saveReport: function saveReport(title) {
      var _this2 = this;
      var comment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.showSaveModal = false;
      var data = {
        query: {
          type: this.type,
          title: title,
          comment: comment,
          server: {
            endpoint: '/utils/reports'
          },
          params: this.params,
          reportOptions: this.reportOptions
        }
      };
      if (this.complianceProjectId) {
        data.query.complianceProjectsId = this.complianceProjectId;
      }
      return _saveReport(data).then(function () {
        _this2.$notify({
          title: _this2.translate.reportCreated
        });
      });
    },
    onLoadReport: function onLoadReport(_ref) {
      var query = _ref.query;
      this.$emit('loadReport', query.params);
      this.showLoadModal = false;
    },
    onReportShare: function onReportShare() {
      this.$notify({
        title: this.translate.reportShared
      });
    },
    onReportDelete: function onReportDelete() {
      this.$notify({
        title: this.translate.theReportWasSuccessfullyDeleted
      });
    }
  }
};