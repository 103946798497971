import { MenuKeys } from '@/constants/menu/MenuKeys';
import { UserLevels } from '@/constants/UserLevels';
import { userHasLevelExecution } from '@/routes/auth';
function buildActionsMenu(settings) {
  var menuStructure = {
    main: [],
    admin: []
  };
  menuStructure.main.push({
    url: '/actions/my',
    title: 'My actions',
    rights: true,
    menuKey: MenuKeys.myInitiatives
  });
  menuStructure.main.push({
    url: '/actions/actions',
    title: 'Overview',
    rights: userHasLevelExecution(UserLevels.NORMAL)(),
    menuKey: MenuKeys.initiatives
  });
  menuStructure.main.push({
    url: '/execution/report',
    title: 'Gantt/roadmap report',
    rights: true,
    menuKey: MenuKeys.reports
  });
  menuStructure.main.push({
    url: '/actions/archive',
    title: 'Archived actions',
    rights: !!settings['feature.archived_actions_enabled']
  });
  menuStructure.main.push({
    url: '/actions/reports',
    title: 'Main report',
    rights: true
  });
  menuStructure.main.push({
    url: '/actions/dashboard',
    title: 'Dashboards',
    rights: true
  });
  return menuStructure;
}
export default buildActionsMenu;