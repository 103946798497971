import ControlTitle from '@/components/Atoms/Titles/ControlTitle';
export default {
  components: {
    ControlTitle: ControlTitle
  },
  data: function data() {
    return {
      testControls: [{
        title: 'test 1',
        cpath: '1.1',
        id: 1,
        trafficLight: 1
      }, {
        title: 'test 2',
        cpath: '1.3',
        trafficLight: 2,
        id: 2,
        active: false
      }, {
        title: 'test 4',
        cpath: '1.4',
        trafficLight: 3,
        id: 3,
        active: true
      }]
    };
  }
};