function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useUsersStore } from '@/Store/usersStore';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { FileFormats, HiddenIconFormats } from '@/constants/FileFormats';
import { toLocalDateTimeFull, getDateDurationWithToday } from '@/utils/date';
export default {
  name: 'AttachmentItem',
  introduction: 'Item attachment uses in AttachmentDropdown',
  description: 'The component displays file information',
  token: '<attachment-item :file={} />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    file: {
      type: Object,
      required: true,
      note: 'file info'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If attachments are readonly and can be downloaded'
    },
    hasVersionButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'if item has version buttons (process library should have them)'
    },
    hideRemoveIcon: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If you need hide delete icon to implement it somewhere else'
    },
    hideEditIcon: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If you need hide edit icon to implement it somewhere else'
    },
    editFileEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'show edit button if attachment is not a link'
    }
  },
  emits: ['onUnpin', 'onCreateNewVersion', 'onAttachmentEdit', 'onShowVersions', 'onDelete'],
  data: function data() {
    return {
      translate: getTranslate['AttachmentItem']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), mapState(useUsersStore, ['users'])), {}, {
    isUriType: function isUriType() {
      return this.file.mimeType && this.file.mimeType === 'text/uri-list';
    },
    fileIcon: function fileIcon() {
      var fileExtension = this.file.mimeType && (this.isUriType ? FileFormats.link : this.file.filename.split('.').pop());
      var icon = !HiddenIconFormats.includes(fileExtension) && FileFormats[fileExtension];
      /* only strict imports required https://esbuild.github.io/api/#non-analyzable-imports */
      switch (icon) {
        case 'csv':
          return require('@/assets/img/file-format/csv.svg');
        case 'doc':
          return require('@/assets/img/file-format/doc.svg');
        case 'docx':
          return require('@/assets/img/file-format/docx.svg');
        case 'pdf':
          return require('@/assets/img/file-format/pdf.svg');
        case 'pptx':
          return require('@/assets/img/file-format/pptx.svg');
        case 'tif':
          return require('@/assets/img/file-format/tif.svg');
        case 'tiff':
          return require('@/assets/img/file-format/tiff.svg');
        case 'txt':
          return require('@/assets/img/file-format/txt.svg');
        case 'xls':
          return require('@/assets/img/file-format/xls.svg');
        case 'xlsx':
          return require('@/assets/img/file-format/xlsx.svg');
        case 'link':
          return require('@/assets/img/file-format/link.svg');
        default:
          return require('@/assets/img/file-format/blank.svg');
      }
    },
    user: function user() {
      var _this = this;
      if (this.file.owner === this.currentUser.id) {
        return this.translate.byYou;
      }
      var user = this.users.find(function (user) {
        return user.id === _this.file.owner;
      });
      if (user) {
        return "".concat(user.fullname);
      }
      return '';
    },
    canBeDownloaded: function canBeDownloaded() {
      if (!this.file.url || this.isUriType) {
        return false;
      }
      return this.file.url.endsWith('?download') || !this.file.url.toLowerCase().startsWith('http') && !this.file.url.toLowerCase().startsWith('f2p://');
    },
    isImage: function isImage() {
      return this.file.mimeType && this.file.mimeType.startsWith('image');
    },
    fileCreatedTitle: function fileCreatedTitle() {
      var _getDateDurationWithT = getDateDurationWithToday(this.file.created),
        value = _getDateDurationWithT.value,
        type = _getDateDurationWithT.type;
      var typeValue = type ? this.$trans(type) + ' ' : '';
      return "".concat(value, " ").concat(typeValue).concat(this.translate.ago);
    },
    fileCreatedTitleHTML: function fileCreatedTitleHTML() {
      return toLocalDateTimeFull(this.file.created);
    },
    isAttachmentEdible: function isAttachmentEdible() {
      if (this.hideEditIcon) return false;
      return this.canBeDownloaded ? this.editFileEnabled : !this.hideEditIcon;
    }
  })
};