function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getAll as _getControls, getAllMotherControls } from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import { getTranslate } from '@/components/Pages/Controls/translate';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { ControlLocalStorage } from '@/constants/ControlLocalStorage';
import { findPropsByIds } from '@/utils/Utils';
import { compareNumbers } from '@/utils/compare';
import { sortByIndex } from '@/utils/sort';
export default {
  name: 'MotherControlReports',
  components: {
    RismaTitle: RismaTitle,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    RismaToggle: RismaToggle,
    SaveReport: SaveReport
  },
  mixins: [RismaDatatableDefaultMixin, SaveReportMixin],
  setup: function setup() {
    return {
      showDeactivated: getLocalStorageComputed('show_deactivated_mother_report', false, true)
    };
  },
  data: function data() {
    return {
      dataset: [],
      motherControlsByPath: [],
      isLoaded: false,
      toggleDeactivatedTimer: null,
      stateLocal: ControlLocalStorage.MOTHER_REPORTS,
      disabledFilterByColumns: ['description'],
      columnsMeta: [{
        targets: ['cpath'],
        width: '50px'
      }],
      decoupleFilterFields: [{
        key: 'tags'
      }, {
        key: 'solutions'
      }, {
        key: 'daughterControls'
      }],
      columnFiltersSorting: [{
        key: 'cpath',
        sortingFunc: compareNumbers
      }, {
        key: 'daughterControls',
        sortingFunc: sortByIndex
      }],
      reportType: 'mother-controls-report',
      translate: getTranslate['ControlReports']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), mapState(useTagsStore, ['tags'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    userHasMotherAccess: function userHasMotherAccess() {
      return this.currentUser.AccessLevels.Controls.canCreateMotherControl;
    },
    columns: function columns() {
      return {
        cpath: this.translate.id,
        title: this.translate.title,
        daughterControls: this.translate.daughterControls,
        description: this.translate.description,
        solutions: this.translate.frameworks,
        tags: this.translate.tags
      };
    }
  }),
  mounted: function mounted() {
    var _this = this;
    this.getData().then(function () {
      return _this.loadDataFromQuery();
    });
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.isLoaded = false;
      return this.getControls().then(this.getMotherControls).then(function () {
        return _this2.isLoaded = true;
      });
    },
    getControls: function getControls() {
      var _this3 = this;
      return _getControls('?filter[]=inactive&filter[]=pending').then(function (_ref) {
        var list = _ref.list;
        return _this3.motherControlsByPath = _this3.prepareMotherControls(list);
      });
    },
    prepareMotherControls: function prepareMotherControls(controls) {
      var motherControls = {};
      controls.forEach(function (control) {
        if (!control.mothercontrolId) return;
        if (!motherControls[control.mothercontrolId]) motherControls[control.mothercontrolId] = [];
        motherControls[control.mothercontrolId].push(control);
      });
      return motherControls;
    },
    getMotherControls: function getMotherControls() {
      var _this4 = this;
      var motherParams = this.showDeactivated ? '?includeInactive=true' : '';
      return getAllMotherControls(motherParams).then(function (_ref2) {
        var list = _ref2.list;
        var enhancedMotherControls = _this4.getEnhancedMotherControls(list);
        _this4.dataset = _this4.prepareDataset(enhancedMotherControls);
        _this4.enhanceDataset(_this4.dataset, _this4.columns);
      });
    },
    getEnhancedMotherControls: function getEnhancedMotherControls(list) {
      var _this5 = this;
      return list.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          daughterControls: _this5.getSortedDaughterControls(_this5.motherControlsByPath[item.path])
        });
      });
    },
    prepareDataset: function prepareDataset(controls) {
      var _this6 = this;
      return controls.map(function (control) {
        var _control$daughterCont;
        var daughterControls = ((_control$daughterCont = control.daughterControls) === null || _control$daughterCont === void 0 ? void 0 : _control$daughterCont.map(function (c) {
          return c.cpath + ' ' + c.title;
        }).join(', ')) || '';
        return {
          cpath: {
            field: control.cpath
          },
          title: {
            activity: control,
            field: control.title
          },
          description: {
            field: control.description
          },
          daughterControls: {
            controls: control.daughterControls,
            field: daughterControls
          },
          solutions: {
            field: findPropsByIds(control.solutionIds, _this6.frameworks, 'displayName')
          },
          tags: {
            field: findPropsByIds(control.tagIds, _this6.tags, 'tag')
          }
        };
      });
    },
    getSortedDaughterControls: function getSortedDaughterControls(controls) {
      return controls && sortByIndex(_toConsumableArray(controls), 'path');
    },
    toggleDeactivated: function toggleDeactivated() {
      var _this7 = this;
      clearTimeout(this.toggleDeactivatedTimer);
      this.toggleDeactivatedTimer = setTimeout(function () {
        _this7.getData();
      }, 300);
    },
    loadDataFromQuery: function loadDataFromQuery() {
      var _this$$route$query,
        _this8 = this;
      var query = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.query;
      if (!query) return;
      this.$nextTick(function () {
        _this8.applySettingsFromLoadedReport(JSON.parse(query));
      });
    }
  }
};