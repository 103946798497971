import { Http } from '../index';
import { handleRequestResponse, objectToQueryString } from '../httpUtils';
export function getLogReports() {
  var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var queryString = objectToQueryString({
    filters: filters
  });
  return handleRequestResponse(Http.get("admin/reports/userlogins?".concat(queryString)));
}
export function getUsersChangelog() {
  return handleRequestResponse(Http.get('changelog/users'));
}
export function getSettingsChangelog() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return handleRequestResponse(Http.get("changelog/changelog/settings".concat(params)));
}
export function getAccessReportChangelog(filters, params) {
  return handleRequestResponse(Http.get("/admin/reports_log?".concat(objectToQueryString(filters), "&").concat(params)));
}