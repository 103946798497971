import { subtractTimeAndFormatDate, addTimeAndFormatDate, calculateDifferenceAsDays } from '@/utils/date';
import { DateFormats } from '@/constants/DateFormats';
var MAIN_DOT_RADIUS = 10;
var Y_COORDINATE = 55;
var SMALL_DOT_RADIUS = 4;
var CANVAS_PADDING_RIGHT = 10;
var BACKGROUND_COLOR = 'lightgrey';
var INDICATOR_COLOR = 'darkblue';
var DOT_COLOR = 'white';
function drawDot(ctx, x, y, mainDotRadius, mainDotColor, smallDotRadius, smallDotColor, strokeColor) {
  var endingAngle = Math.PI / 180 * 360;
  ctx.beginPath();
  ctx.fillStyle = mainDotColor;
  ctx.arc(x, y, mainDotRadius, 0, endingAngle, false);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
  ctx.fill();
  ctx.beginPath();
  ctx.fillStyle = smallDotColor;
  ctx.arc(x, y, smallDotRadius, 0, endingAngle, false);
  ctx.fill();
}
function drawIndicator(ctx, x, y, width, color) {
  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.roundRect(x, y, width, 10, 10);
  ctx.fill();
}
function drawZoomIndicator(ctx, left, leftDotPosition, rightDotPosition) {
  var chartAreaWidth = getChartAreaWidth(ctx.canvas, left);
  var indicatorYCoordinate = Y_COORDINATE - MAIN_DOT_RADIUS / 2;
  drawIndicator(ctx, left, indicatorYCoordinate, chartAreaWidth, BACKGROUND_COLOR);
  drawIndicator(ctx, leftDotPosition, indicatorYCoordinate, rightDotPosition - leftDotPosition, INDICATOR_COLOR);
  drawDot(ctx, leftDotPosition, Y_COORDINATE, MAIN_DOT_RADIUS, DOT_COLOR, SMALL_DOT_RADIUS, INDICATOR_COLOR);
  drawDot(ctx, rightDotPosition, Y_COORDINATE, MAIN_DOT_RADIUS, DOT_COLOR, SMALL_DOT_RADIUS, INDICATOR_COLOR);
}
function setCursorOnDots(canvas, _ref, leftDotPosition, rightDotPosition) {
  var x = _ref.x,
    y = _ref.y;
  var isCursorOverDotFromX = x > rightDotPosition - MAIN_DOT_RADIUS && x < rightDotPosition + MAIN_DOT_RADIUS || x > leftDotPosition - MAIN_DOT_RADIUS && x < leftDotPosition + MAIN_DOT_RADIUS;
  var isCursorOverDotFromY = y > Y_COORDINATE - MAIN_DOT_RADIUS && y < Y_COORDINATE + MAIN_DOT_RADIUS;
  if (isCursorOverDotFromX && isCursorOverDotFromY) {
    canvas.style.cursor = 'pointer';
  } else {
    canvas.style.cursor = 'default';
  }
}
function getChartAreaWidth(canvas, leftOffset) {
  var width = parseFloat(canvas.style.width);
  return width - CANVAS_PADDING_RIGHT - leftOffset;
}
export var zoomRangeSlider = function zoomRangeSlider(startDate, endDate) {
  var dragInProgress = false;
  var leftDotPosition = null;
  var rightDotPosition = null;
  var isLeftDotMoving = null;
  var isRightDotMoving = null;
  var daysPerPixel = null;
  var calculationTimeUnit = 'days';
  var durationInDays = calculateDifferenceAsDays(endDate, startDate);
  var hideSlider = false;
  return {
    id: 'zoomRangeSlider',
    afterDatasetDraw: function afterDatasetDraw(chart) {
      var ctx = chart.ctx,
        left = chart.chartArea.left;
      var chartAreaWidth = getChartAreaWidth(ctx.canvas, 0);
      daysPerPixel = durationInDays / chartAreaWidth;
      hideSlider = daysPerPixel < 1;
      leftDotPosition = leftDotPosition || left;
      rightDotPosition = !rightDotPosition || chartAreaWidth < rightDotPosition ? chartAreaWidth - MAIN_DOT_RADIUS : rightDotPosition;
      !hideSlider && drawZoomIndicator(ctx, left, leftDotPosition, rightDotPosition, hideSlider);
    },
    afterUpdate: function afterUpdate(chart) {
      if (hideSlider) {
        chart.options.scales.x.min = startDate;
        chart.options.scales.x.max = endDate;
        return;
      }
      var ctx = chart.ctx,
        left = chart.chartArea.left;
      var chartAreaWidth = getChartAreaWidth(ctx.canvas, left);
      if (isLeftDotMoving) {
        var min = leftDotPosition > 0 ? Math.floor((leftDotPosition - left) * daysPerPixel) : 0;
        chart.options.scales.x.min = addTimeAndFormatDate(startDate, min, calculationTimeUnit, DateFormats.DATE_FORMAT);
      }
      if (isRightDotMoving) {
        var max = Math.floor((rightDotPosition > 0 ? chartAreaWidth + left - rightDotPosition : chartAreaWidth + left) * daysPerPixel);
        chart.options.scales.x.max = subtractTimeAndFormatDate(endDate, max, calculationTimeUnit);
      }
    },
    afterEvent: function afterEvent(chart, args) {
      if (hideSlider) {
        return;
      }
      var ctx = chart.ctx,
        canvas = chart.canvas,
        left = chart.chartArea.left;
      if (args.event.type === 'mousedown') {
        isLeftDotMoving = args.event.x > leftDotPosition - MAIN_DOT_RADIUS && args.event.x < leftDotPosition + MAIN_DOT_RADIUS && args.event.y < Y_COORDINATE + MAIN_DOT_RADIUS && args.event.y > Y_COORDINATE - MAIN_DOT_RADIUS;
        isRightDotMoving = args.event.x > rightDotPosition - MAIN_DOT_RADIUS && args.event.x < rightDotPosition + MAIN_DOT_RADIUS && args.event.y < Y_COORDINATE + MAIN_DOT_RADIUS && args.event.y > Y_COORDINATE - MAIN_DOT_RADIUS;
        dragInProgress = true;
      }
      if (args.event.type === 'mouseout' || args.event.type === 'mouseup') {
        dragInProgress = false;
        isLeftDotMoving = false;
        isRightDotMoving = false;
      }
      if (args.event.type === 'mousemove' && dragInProgress && !args.inChartArea) {
        if (isLeftDotMoving) {
          args.changed = true;
          leftDotPosition = args.event.x < left ? left : args.event.x > rightDotPosition - MAIN_DOT_RADIUS * 2 ? leftDotPosition : args.event.x;
          chart.update(undefined);
        } else if (isRightDotMoving) {
          args.changed = true;
          var area = getChartAreaWidth(ctx.canvas, 0) - CANVAS_PADDING_RIGHT;
          rightDotPosition = args.event.x < leftDotPosition + MAIN_DOT_RADIUS * 2 ? rightDotPosition : args.event.x > area - MAIN_DOT_RADIUS * 2 ? area : args.event.x;
          chart.update(undefined);
        }
      }
      if (args.event.type === 'mousemove' && !args.inChartArea) {
        setCursorOnDots(canvas, args.event, leftDotPosition, rightDotPosition);
      }
    }
  };
};