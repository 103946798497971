import i18n from '@/i18n';
export var getTranslate = {
  AttachmentSection: function AttachmentSection() {
    return {
      linkTo: i18n.t('Link to'),
      insertLinkToFileOrDestination: i18n.t('Insert link to file or destination'),
      insertLinkTitle: i18n.t('Insert link title'),
      addLink: i18n.t('Add link')
    };
  }
};