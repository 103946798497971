import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "col-2"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1,
  class: "flex"
};
var _hoisted_5 = {
  class: "w-1/3 mr-12"
};
var _hoisted_6 = {
  class: "flex-grow w-2/3"
};
var _hoisted_7 = {
  key: 0,
  class: "flex h-500px"
};
var _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$currentAwarene;
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_notification = _resolveComponent("notification");
  var _component_sign_off_form = _resolveComponent("sign-off-form");
  var _component_awareness_reminder_modal = _resolveComponent("awareness-reminder-modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [!$props.readonly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $options.createButtonTitle,
    class: "pull-right -ml-1.3rem",
    type: "secondary",
    onClick: $options.openSignOffNewModal
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "ml-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), $props.readonly && !$options.menuItems.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)]), $data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_loading_indicator_large)])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [$options.menuItems.length ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $data.translate.requestsList,
    class: "my-2",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), _createVNode(_component_vertical_menu, {
    items: $options.menuItems,
    "selected-id": $data.currentAwareness.id,
    onChangeItem: $options.changeMenuItem
  }, null, 8 /* PROPS */, ["items", "selected-id", "onChangeItem"])]), _createElementVNode("div", _hoisted_6, [$data.loadUpdateState ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_loading_indicator_large)])) : (_$data$currentAwarene = $data.currentAwareness) !== null && _$data$currentAwarene !== void 0 && _$data$currentAwarene.id || $options.isNew ? (_openBlock(), _createElementBlock("div", _hoisted_8, [!$props.readonly ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    title: $options.currentHeader,
    type: "big"
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true), !$props.readonly && $data.currentAwareness.sent === null ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.thisRequestHasNotBeenSentClickTheButtonT), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_sign_off_form, {
    readonly: $props.readonly,
    "modal-type": $options.modalType,
    "activity-title": $props.data.title,
    users: $data.currentAwareness.users,
    "dashboard-title": $data.currentAwareness.title,
    comments: $data.currentAwareness.message,
    "validation-users": $data.validationUsers,
    "validation-dashboard": $data.validationDashboard,
    "validation-email": $data.signOffError,
    "is-awareness-sent": !!$data.currentAwareness.sent,
    "quiz-questionnaire-id": $data.currentAwareness.quizQuestionnaireId,
    "onAdd:newEmail": $options.addNewEmail,
    "onRemove:user": $options.removeUser,
    "onUpdate:usersRow": _cache[0] || (_cache[0] = function ($event) {
      return $data.usersRow = $event;
    }),
    "onUpdate:comments": _cache[1] || (_cache[1] = function ($event) {
      return $data.currentAwareness.message = $event;
    }),
    "onUpdate:dashboardTitle": _cache[2] || (_cache[2] = function ($event) {
      return $data.currentAwareness.title = $event;
    }),
    "onUpdate:quizQuestionnaireId": _cache[3] || (_cache[3] = function ($event) {
      return $data.currentAwareness.quizQuestionnaireId = $event;
    })
  }, null, 8 /* PROPS */, ["readonly", "modal-type", "activity-title", "users", "dashboard-title", "comments", "validation-users", "validation-dashboard", "validation-email", "is-awareness-sent", "quiz-questionnaire-id", "onAdd:newEmail", "onRemove:user"]), !$props.readonly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 2,
    text: $options.currentHeader,
    type: "save",
    onClick: $options.handleSignOffButton
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])])), $data.isSignOffModalOpen ? (_openBlock(), _createBlock(_component_awareness_reminder_modal, {
    key: 2,
    activity: $props.data,
    users: _ctx.usersWithPoliciesAccess,
    awareness: $data.awarenessToUpdate,
    onDismiss: _cache[4] || (_cache[4] = function ($event) {
      return $data.isSignOffModalOpen = false;
    }),
    onSendReminder: $options.sendSignOffReminder
  }, null, 8 /* PROPS */, ["activity", "users", "awareness", "onSendReminder"])) : _createCommentVNode("v-if", true)]);
}