import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  key: 0,
  class: "text-gray-450 pt-0 px-4 pb-4 cursor-default text-center"
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(['py-0.5 px-2 min-h-6 flex items-center rounded-md glob-l1 text-rm-grey border border-rm-grey cursor-pointer', 'hover:text-rm-signal-grey-dark hover:border-rm-signal-grey-dark hover:outline hover:outline-1']),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.openModal && $options.openModal.apply($options, arguments);
    })
  }, _toDisplayString($options.statusTitle), 1 /* TEXT */), $data.showVersionsModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalHeader,
    "show-ok-button": false,
    "show-dismiss-button": false,
    "body-scroll-y": true,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showVersionsModal = false;
    })
  }, {
    body: _withCtx(function () {
      var _$data$nodeVersions;
      return [_createElementVNode("div", null, [$data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [!((_$data$nodeVersions = $data.nodeVersions) !== null && _$data$nodeVersions !== void 0 && _$data$nodeVersions.length) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString($data.translate.listIsEmpty), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.nodeVersions, function (version) {
        return _openBlock(), _createBlock(_component_expansion_panel, {
          key: version.id,
          class: "mb-3"
        }, {
          header: _withCtx(function () {
            return [_createElementVNode("span", null, _toDisplayString($options.formatVersionLabel(version.closedAt)), 1 /* TEXT */)];
          }),
          content: _withCtx(function () {
            return [_createElementVNode("div", {
              class: "pl-4 pr-3 pb-3 browser-default",
              innerHTML: version.status
            }, null, 8 /* PROPS */, _hoisted_4)];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */))]))]))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])) : _createCommentVNode("v-if", true)]);
}