// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-button[data-v-7f8a06d6]  button {
  line-height: 18px;
}
.static-table[data-v-7f8a06d6]  th {
  padding-bottom: 12px;
}
.static-table[data-v-7f8a06d6]  th h2 {
  font-weight: 600;
  line-height: 18px;
}
.static-table[data-v-7f8a06d6]  td {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #444444;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
