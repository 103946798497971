import AxisLabel from '@/components/Atoms/RiskMatrix/AxisLabel';
import Grid from './Grid';
import GridLines from '@/components/Atoms/RiskMatrix/GridLines';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: 'Matrix',
  components: {
    RismaTitle: RismaTitle,
    RismaToggle: RismaToggle,
    GridLines: GridLines,
    Grid: Grid,
    AxisLabel: AxisLabel,
    SingleSelect: SingleSelect
  },
  props: {
    matrix: {
      note: 'Contains a 2D array used for creating the grid',
      type: Array,
      required: true
    },
    labels: {
      note: 'Contains an Object that contains two Strings with the names of the axis and two arrays with the labels',
      type: Object,
      required: true
    },
    checked: {
      note: 'Contains a true or false to decide if show lines checkbox is set in the grid',
      type: Boolean,
      default: false
    },
    colorCodes: {
      note: 'Contains the color codes for the status of the dots',
      type: Object,
      required: true
    },
    matrixColor: {
      type: String,
      required: true,
      note: 'Color arrangement of grid'
    },
    size: {
      type: Number,
      required: true,
      note: 'size of grid'
    },
    enablePrevPosition: {
      type: Boolean,
      default: true
    },
    showArrows: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show x and y arrows?'
    },
    showPreviousPositionLabel: {
      type: String,
      required: false,
      default: 'Show previous position'
    },
    periods: {
      type: Object,
      required: false,
      default: null
    },
    isMatrixClickable: {
      type: Boolean,
      required: false,
      default: true,
      note: 'show the check mark on the grid'
    },
    dotLabelHoverFunction: {
      type: Function,
      default: null,
      note: 'Function to generate label on hover'
    },
    startColor: {
      type: String,
      required: true
    },
    endColor: {
      type: String,
      required: true
    },
    customHeatmapOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    dotConnectorEnabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'show a lines connecting two points with the same id'
    },
    showLines: {
      type: Boolean,
      default: false
    },
    areDotsCenterAligned: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onStateChanged', 'onPeriodChanged', 'togglePreviousPosition'],
  data: function data() {
    return {
      display: false,
      arrowFraction: 2 / 3
    };
  },
  computed: {
    axisWidthX: function axisWidthX() {
      var _this$matrix;
      if (!((_this$matrix = this.matrix) !== null && _this$matrix !== void 0 && _this$matrix.length)) return 0;
      return this.size * this.matrix[0].length;
    },
    axisWidthY: function axisWidthY() {
      var _this$matrix2;
      if (!((_this$matrix2 = this.matrix) !== null && _this$matrix2 !== void 0 && _this$matrix2.length)) return 0;
      return this.size * this.matrix.length;
    },
    arrowWidthX: function arrowWidthX() {
      return this.arrowFraction * this.axisWidthX;
    },
    arrowWidthY: function arrowWidthY() {
      return this.arrowFraction * this.axisWidthY;
    }
  },
  methods: {
    onPeriodChange: function onPeriodChange(event) {
      this.$emit('onPeriodChanged', event);
    }
  }
};