import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "relative flex"
};
var _hoisted_2 = {
  class: "flex flex-1 flex-col justify-between"
};
var _hoisted_3 = {
  class: "min-h-220px flex flex-col pt-3"
};
var _hoisted_4 = {
  class: "flex"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_notification = _resolveComponent("notification");
  var _component_static_table = _resolveComponent("static-table");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addContractParty,
    "show-buttons": false,
    onDismiss: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $data.translate.addContractParty,
        type: $data.headerType,
        class: "flex-1"
      }, null, 8 /* PROPS */, ["title", "type"]), $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
        key: 0
      })) : _createCommentVNode("v-if", true)]), _createElementVNode("button", {
        class: "focus:outline-none",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('dismiss');
        })
      }, [_createVNode(_component_feather_icon, {
        icon: "x",
        width: "25",
        height: "25",
        class: "stroke-3 text-gray-750 hover:text-rm-urban"
      })])])];
    }),
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_input_field, {
        modelValue: $data.searchValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.searchValue = $event;
        }),
        modelModifiers: {
          trim: true
        },
        placeholder: $data.translate.nameOrTaxNo,
        type: "text",
        class: "flex-1 mr-0.3rem",
        "help-text": $data.translate.toSearchInSwedishOrNorwegianRegisterUseFo,
        invalid: !!$data.searchFieldError,
        "error-help-text": $data.searchFieldError,
        onOnEnter: $options.handleSearch
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "help-text", "invalid", "error-help-text", "onOnEnter"]), _createVNode(_component_risma_button, {
        text: $data.translate.search,
        type: "save",
        onClick: $options.handleSearch
      }, null, 8 /* PROPS */, ["text", "onClick"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.searchResults, function (group, index) {
        return _openBlock(), _createElementBlock("div", {
          key: "search-result-".concat(index),
          class: "my-2"
        }, [_createVNode(_component_risma_title, {
          title: group.title,
          type: "medium",
          class: "mb-2"
        }, null, 8 /* PROPS */, ["title"]), group.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
          key: 0,
          type: "error",
          onDismiss: _cache[2] || (_cache[2] = function ($event) {
            return $data.errorMessage = '';
          })
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(group.errorMessage), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true), group.dataset.length ? (_openBlock(), _createBlock(_component_static_table, {
          key: 1,
          "dataset-table": group.dataset,
          columns: group.columns,
          theme: $data.StaticTableThemes.zebra,
          "is-row-clickable": !group.isExternal,
          "additional-row-classes-text": $data.translate.contractPartyAlreadyAttachedToTheCurrentCon,
          "onClick:row": function onClickRow($event) {
            return $options.handleRowClick($event, group, group.dataset);
          }
        }, {
          add: _withCtx(function (_ref) {
            var slotData = _ref.slotData;
            return [group.isExternal ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "focus:outline-none",
              onClick: function onClick($event) {
                return $options.handleCompanyCreation(slotData.item);
              }
            }, [_createVNode(_component_feather_icon, {
              class: "text-blue-350 stroke-2 cursor-pointer",
              icon: "plus-circle"
            })], 8 /* PROPS */, _hoisted_5)) : (_openBlock(), _createElementBlock("div", _hoisted_6))];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-table", "columns", "theme", "is-row-clickable", "additional-row-classes-text", "onClick:row"])) : _createCommentVNode("v-if", true)]);
      }), 128 /* KEYED_FRAGMENT */)), !$options.searchResults.length ? (_openBlock(), _createBlock(_component_no_search_results, {
        key: 0,
        body: $options.searchNotification,
        class: "m-auto"
      }, null, 8 /* PROPS */, ["body"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]);
}