import Selector from '@/components/Atoms/Inputs/Selector';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TagItem from '@/components/Atoms/Containers/TagItem';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
export default {
  name: 'DataFlowWizardStepDataType',
  components: {
    Selector: Selector,
    RismaTitle: RismaTitle,
    TagItem: TagItem
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    }
  },
  emits: ['update:selected'],
  data: function data() {
    return {
      currentSelected: [],
      DataFlowFields: DataFlowFields,
      translate: {
        chooseDataTypes: this.$trans('Choose data types'),
        dataType: this.$trans('Data type'),
        addedDataTypes: this.$trans('Added data types')
      }
    };
  }
};