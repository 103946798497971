import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'PrivacyModal',
  components: {
    Modal: Modal,
    RismaTitle: RismaTitle
  },
  emits: ['dismiss'],
  data: function data() {
    return {
      data: [{
        header: 'Purpose',
        list: ['RISMA implements organizational and technical measures to keep all data safe. RISMA has no control over the personal data being typed into the solution by the customers', 'RISMA processes personal data about customers, collaborators, suppliers and partners, such as contact person in the company', 'The purpose of the data processing is to be able to cooperate with the company.']
      }, {
        header: 'Categories of personal data',
        list: ['We process contact information on the registered person when needed for support reasons.']
      }, {
        header: 'Legal basis for the processing',
        list: ['We process your personal data on the following legal basis: Article 6.1.f (Legitimate interest). It is expected that we can contact relevant people, when we are in collaboration with them.']
      }, {
        header: 'Recipients',
        list: ['We do not share your personal information.']
      }, {
        header: 'Data retention',
        list: ['We will keep the personal data for as long as the collaboration exists.']
      }, {
        header: 'Cookie policy',
        list: ['This website uses cookies. We use cookies for keeping the user logged in (session cookie) and to personalise content. We do not store any 3rd party cookies.', 'You consent to our cookies if you continue to use this site.', "Cookies are small text files that can be used by websites to make a user's experience more efficient."]
      }],
      translate: {
        privacyPolicy: this.$trans('Privacy Policy'),
        ok: this.$trans('Ok')
      }
    };
  }
};