// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-field[data-v-1b34ce9c]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.description-field[data-v-1b34ce9c]  ul ul {
  list-style-type: circle;
}
.description-field[data-v-1b34ce9c]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.description-field[data-v-1b34ce9c]  li {
  padding-left: 0.3em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
