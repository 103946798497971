var _Colors;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ObjectTypes } from '@/constants/ObjectTypes';
import { useSettingsStore } from '@/Store/settingsStore';
import { MODULES } from '@/constants/modules';
import { capitalizeWords, capitalize } from '@/utils/capitalize';
var cachedOverdueControlColor = null;
var getOverdueControlColor = function getOverdueControlColor() {
  if (cachedOverdueControlColor) return cachedOverdueControlColor;
  var color = useSettingsStore().settings['feature.controls_overdue_completed_color'] || '#A80000';
  cachedOverdueControlColor = {
    color: color,
    label: Colors.getTrafficLabelByColor(color)
  };
  return cachedOverdueControlColor;
};
export var Colors = (_Colors = {
  high: '#B03D00',
  medium: '#ffc103',
  low: '#007c87',
  notSet: '#807a77',
  noGap: '#57aa74',
  noReview: '#38747f',
  na: '#bbbbbb',
  white: '#ffffff',
  'rGrey-400': '#DEE2E6',
  'rGrey-500': '#ced4da',
  'risma-500': '#002e61',
  'rWhite-500': '#fafbfc',
  'rRed-600': '#c64141',
  answered: '#4cb3c0',
  unanswered: '#B03D00',
  trafficLightStroke: '#dedede',
  primaryGreen: '#347382',
  noTrafficLight: '#aaa',
  blueLight: '#4cb3c0',
  red: '#a80000',
  yellow: '#ffbf00',
  lightYellow: '#F7F59A',
  dullGreen: '#5aa774',
  dullGreen2: '#5aa744',
  greenTea: '#AFDE9A',
  lilacGray: '#79A1D2',
  lightRed: '#E47F7F'
}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_Colors, 'brand-500', '#00acef'), "system", {
  white: '#ffffff',
  black: '#000000',
  BG: '#F4F4F4',
  lightGray: '#BEBEBE',
  lightGray2: '#807c79',
  blueGray: '#637994',
  blueGrayActive: '#afd7ed',
  gray: '#737373',
  darkGray: '#4D4D4D',
  primaryLight: '#4CB3C0',
  primaryDark: '#347480',
  warning: '#B03D00',
  blueMain: '#0e2954',
  red: '#a80000' // tailwind red-50
}), "trafficLights", [{
  number: 0,
  color: '#dee2e6',
  label: 'grey'
}, {
  number: 1,
  color: '#f25050',
  label: 'red'
}, {
  number: 2,
  color: '#fad039',
  label: 'yellow'
}, {
  number: 3,
  color: '#4bbf5e',
  label: 'green'
}]), "incidentSeverity", [{
  number: 0,
  color: '#dee2e6',
  label: 'grey'
}, {
  number: 1,
  color: '#4bbf5e',
  label: 'green'
}, {
  number: 2,
  color: '#fad039',
  label: 'yellow'
}, {
  number: 3,
  color: '#f25050',
  label: 'red'
}]), MODULES.COMPLIANCE_GAP, [{
  number: 0,
  color: '#adb5bd',
  label: 'Not set'
}, {
  number: 1,
  color: '#4bbf5e',
  label: 'No Gap'
}, {
  number: 2,
  color: '#4385e0',
  label: 'Gap Low'
}, {
  number: 3,
  color: '#fad039',
  label: 'Gap Medium'
}, {
  number: 4,
  color: '#f25050',
  label: 'Gap High'
}, {
  number: 5,
  color: '#dee2e6',
  label: 'Gap N/A'
}]), ObjectTypes.COMPANY, [{
  number: 0,
  color: '#dee2e6',
  label: 'Unknown'
}, {
  number: 1,
  color: '#4bbf5e',
  label: 'Normal'
}, {
  number: 2,
  color: '#fad039',
  label: 'Under bankruptcy'
}, {
  number: 3,
  color: '#fad039',
  label: 'During termination'
}, {
  number: 4,
  color: '#f25050',
  label: 'Bankrupt'
}, {
  number: 5,
  color: '#f25050',
  label: 'Terminated'
}]), "trafficlightColor", function trafficlightColor(number) {
  var safe = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!this.trafficLights[number] && safe) {
    return this.trafficLights[0];
  }
  return this.trafficLights[number];
}), "getControlTrafficLightByNumber", function getControlTrafficLightByNumber(number) {
  if (+number === 1) {
    return _objectSpread({
      number: 1
    }, getOverdueControlColor());
  }
  if (!this.trafficLights[number]) {
    return {
      number: 0,
      color: '#BEBEBE',
      label: 'grey'
    };
  }
  return this.trafficLights[number];
}), "findIncidentSeverity", function findIncidentSeverity(number) {
  if (!this.incidentSeverity[number]) {
    return {
      number: 0,
      color: '#BEBEBE',
      label: 'grey'
    };
  }
  return this.incidentSeverity[number];
}), "getTrafficlightByNumber", function getTrafficlightByNumber(number) {
  return this.trafficLights.filter(function (trafficLight) {
    return trafficLight.number === parseInt(number, 10);
  })[0];
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_Colors, "getTrafficlightByLabel", function getTrafficlightByLabel(label) {
  return this.trafficLights.find(function (trafficLight) {
    return trafficLight.label === label;
  });
}), "getCompanyTrafficlightByNumber", function getCompanyTrafficlightByNumber(number) {
  return this[ObjectTypes.COMPANY].find(function (trafficLight) {
    return trafficLight.number === number;
  });
}), "gapColor", function gapColor(number) {
  if (!this[MODULES.COMPLIANCE_GAP][number]) {
    return this[MODULES.COMPLIANCE_GAP][0];
  }
  return this[MODULES.COMPLIANCE_GAP][number];
}), "findGapByLabel", function findGapByLabel(label) {
  return this[MODULES.COMPLIANCE_GAP].filter(function (item) {
    return item.label === label;
  })[0];
}), "getTrafficLightColor", function getTrafficLightColor(item) {
  if (+item.id === 0) return;
  if (item.objType === ObjectTypes.INCIDENTS_INCIDENT || item.objType === ObjectTypes.INCIDENTS_INCIDENT_LIMITED) {
    return this.findIncidentSeverity(item.severity).color;
  }
  if (item.objType === ObjectTypes.COMPANY) return this.getCompanyTrafficlightByNumber(item.vatStatus).color;
  if (item.trafficLight) return this.getTrafficlightByNumber(item.trafficLight).color;
  if (item.trafficLightText) return this.getTrafficlightByLabel(item.trafficLightText).color;
  return this.getTrafficlightByNumber(0).color;
}), "getTrafficLabelByColor", function getTrafficLabelByColor(color) {
  if (!color) return 'grey';
  var colors = {
    '#A80000': 'red',
    '#F25050': 'red',
    '#3CDFFF': 'blue',
    '#FF6D0A': 'orange',
    '#9300FF': 'purple',
    '#BEBEBE': 'grey',
    '#DEE2E6': 'grey',
    '#FFBF00': 'yellow',
    '#FAD039': 'yellow',
    '#5AA744': 'green',
    '#4BBF5E': 'green'
  };
  return (colors === null || colors === void 0 ? void 0 : colors[color.toUpperCase()]) || 'grey';
}));
export var COLOR_LABEL_MAPPING = {
  '#A80000': 'red',
  '#3CDFFF': 'blue',
  '#FF6D0A': 'orange',
  '#9300FF': 'purple',
  '#BEBEBE': 'grey',
  '#FFBF00': 'yellow',
  '#5AA744': 'green'
};
export var getDefaultTrafficLights = function getDefaultTrafficLights() {
  var modulesMapping = _defineProperty(_defineProperty(_defineProperty({
    default: Colors.trafficLights
  }, MODULES.COMPLIANCE_GAP, Colors[MODULES.COMPLIANCE_GAP]), ObjectTypes.COMPANY, Colors[ObjectTypes.COMPANY]), MODULES.INCIDENTS, Colors.incidentSeverity);
  Object.keys(modulesMapping).forEach(function (key) {
    modulesMapping[key] = modulesMapping[key].map(function (item, index) {
      return _objectSpread(_objectSpread({}, item), {}, {
        id: index,
        legend: item.label
      });
    });
  });
  return modulesMapping;
};
export var prepareTrafficLights = function prepareTrafficLights(trafficLights) {
  var trafficLightsCopy = _toConsumableArray(trafficLights);
  trafficLightsCopy.sort(function (a, b) {
    return a.number - b.number;
  });
  trafficLightsCopy.forEach(function (trafficLight) {
    if (trafficLight.legend === 'Not set' || trafficLight.legend === 'not set') {
      trafficLight.legend = 'Not set';
    } else if (trafficLight.legend === 'gap n/a') {
      trafficLight.legend = 'Gap N/A';
    } else if (trafficLight.module === 'company') {
      trafficLight.legend = capitalize(trafficLight.legend);
    } else trafficLight.legend = capitalizeWords(trafficLight.legend);
  });
  return trafficLightsCopy;
};