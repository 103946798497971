import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "col-2"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "mb-8 glob-h2 text-rm-dark-blue"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1,
  class: "flex"
};
var _hoisted_6 = {
  class: "w-1/4 pr-4"
};
var _hoisted_7 = {
  class: "mb-8 glob-h2 text-rm-dark-blue block"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  class: "w-full flex flex-col break-words leading-6"
};
var _hoisted_10 = {
  class: "flex-grow w-2/3 pl-2"
};
var _hoisted_11 = {
  key: 0,
  class: "flex h-500px"
};
var _hoisted_12 = {
  key: 1
};
var _hoisted_13 = {
  key: 0,
  class: "glob-h2 text-rm-dark-blue block mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$data$currentAwarene;
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_notification = _resolveComponent("notification");
  var _component_sign_off_form = _resolveComponent("sign-off-form");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_awareness_reminder_modal = _resolveComponent("awareness-reminder-modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [$props.readonly && !$options.menuItems.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.translate.nothingToShow), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), $data.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_loading_indicator_large)])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString($data.translate.requestsList), 1 /* TEXT */), !$props.readonly ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: "text-rm-text flex items-center hover:underline mb-4",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.openSignOffNewModal && $options.openSignOffNewModal.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus-circle",
    class: "glob-h3 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($options.createButtonTitle), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.menuItems, function (item) {
    return _openBlock(), _createElementBlock("button", {
      key: item.id,
      class: _normalizeClass(["block w-full-4 -ml-4 p-4 text-left cursor-pointer hover:bg-gray-250 group rounded", {
        'bg-gray-250': item.id === $data.currentAwareness.id
      }]),
      onClick: function onClick($event) {
        return $options.gotToMenuItem(item);
      }
    }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("span", {
      class: _normalizeClass(["glob-h3 mb-2", item.id === $data.currentAwareness.id ? 'text-rm-black' : 'text-rm-text'])
    }, _toDisplayString(item.title.text), 3 /* TEXT, CLASS */), _createElementVNode("span", {
      class: _normalizeClass(["glob-l1", item.id === $data.currentAwareness.id ? 'text-rm-black' : 'text-rm-signal-grey-dark'])
    }, _toDisplayString(item.title.status), 3 /* TEXT, CLASS */)])], 10 /* CLASS, PROPS */, _hoisted_8);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_10, [$data.loadUpdateState ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_loading_indicator_large)])) : (_$data$currentAwarene = $data.currentAwareness) !== null && _$data$currentAwarene !== void 0 && _$data$currentAwarene.id || $options.isNew ? (_openBlock(), _createElementBlock("div", _hoisted_12, [!$props.readonly ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString($options.currentHeader), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$props.readonly && $data.currentAwareness.sent === null ? (_openBlock(), _createBlock(_component_notification, {
    key: 1,
    type: "info"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.translate.thisRequestHasNotBeenSentClickTheButtonT), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createVNode(_component_sign_off_form, {
    readonly: $props.readonly,
    "modal-type": $options.modalType,
    "activity-title": $props.data.title,
    users: $data.currentAwareness.users,
    "dashboard-title": $data.currentAwareness.title,
    comments: $data.currentAwareness.message,
    "validation-users": $data.validationUsers,
    "validation-dashboard": $data.validationDashboard,
    "validation-email": $data.signOffError,
    "is-awareness-sent": !!$data.currentAwareness.sent,
    "quiz-questionnaire-id": $data.currentAwareness.quizQuestionnaireId,
    "onAdd:newEmail": $options.addNewEmail,
    "onRemove:user": $options.removeUser,
    "onUpdate:usersRow": _cache[1] || (_cache[1] = function ($event) {
      return $data.usersRow = $event;
    }),
    "onUpdate:comments": _cache[2] || (_cache[2] = function ($event) {
      return $data.currentAwareness.message = $event;
    }),
    "onUpdate:dashboardTitle": _cache[3] || (_cache[3] = function ($event) {
      return $data.currentAwareness.title = $event;
    }),
    "onUpdate:quizQuestionnaireId": _cache[4] || (_cache[4] = function ($event) {
      return $data.currentAwareness.quizQuestionnaireId = $event;
    })
  }, null, 8 /* PROPS */, ["readonly", "modal-type", "activity-title", "users", "dashboard-title", "comments", "validation-users", "validation-dashboard", "validation-email", "is-awareness-sent", "quiz-questionnaire-id", "onAdd:newEmail", "onRemove:user"]), !$props.readonly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 2,
    text: $options.currentHeader,
    type: "save",
    onClick: $options.handleSignOffButton
  }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)])])), $data.isSignOffModalOpen ? (_openBlock(), _createBlock(_component_awareness_reminder_modal, {
    key: 2,
    activity: $props.data,
    users: _ctx.usersWithPoliciesAccess,
    awareness: $data.awarenessToUpdate,
    onDismiss: _cache[5] || (_cache[5] = function ($event) {
      return $data.isSignOffModalOpen = false;
    }),
    onSendReminder: $options.sendSignOffReminder
  }, null, 8 /* PROPS */, ["activity", "users", "awareness", "onSendReminder"])) : _createCommentVNode("v-if", true)]);
}