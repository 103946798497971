import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  CompanyWizardStepWelcome: function CompanyWizardStepWelcome() {
    return {
      welcome: i18n.t('Welcome'),
      assets: i18n.t('Assets'),
      youCanCreateCompaniesAndAtTheSameTimeLinkThem: function youCanCreateCompaniesAndAtTheSameTimeLinkThem(iaTitle) {
        return i18n.t('You can create companies and at the same time link them to existing %s or create new. The %s will share master data of the company.', [iaTitle, iaTitle]);
      },
      companiesCanBeCreatedManuallyWhereYouEnter: i18n.t('Companies can be created manually where you enter all the master data yourself or you can create the company through the Company register, which will automatically provide you with the master data.'),
      toCreateMoreCompaniesYouWillHaveToRepeat: i18n.t('To create more companies, you will have to repeat this process for each company.')
    };
  },
  CompanyContactModal: function CompanyContactModal() {
    return {
      editCompanyContact: i18n.t('Edit company contact'),
      addCompanyContact: i18n.t('Add company contact'),
      name: i18n.t('Name'),
      nameCannotBeEmpty: i18n.t('Name cannot be empty'),
      email: i18n.t('E-mail'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      phone: i18n.t('Phone'),
      title: i18n.t('Title'),
      companies: i18n.t('Companies'),
      selectCompany: i18n.t('Select company'),
      pleaseSelectCompany: i18n.t('Please select company'),
      save: i18n.t('Save'),
      enterTitle: getTitlePlaceholder()
    };
  },
  CompanyWizardStepCompanyCreation: function CompanyWizardStepCompanyCreation() {
    return {
      choose: i18n.t('Choose'),
      manually: i18n.t('Manually'),
      viaVatNumber: i18n.t('via VAT number'),
      title: i18n.t('Title'),
      countryCode: i18n.t('Country code/Country'),
      address: i18n.t('Address'),
      phone: i18n.t('Phone'),
      email: i18n.t('E-mail'),
      city: i18n.t('City'),
      zipCode: i18n.t('Zipcode'),
      vatNumber: i18n.t('VAT number'),
      pressEnterToStartSearch: i18n.t('Press enter to start search'),
      searchingByNameIsNotAvailableForSeCompani: '(' + i18n.t('Searching by name is not available for SE companies') + ')',
      orCompanyName: i18n.t('or company name'),
      chooseTheCompanyYouWishToCreate: i18n.t('Choose the company you wish to create'),
      noCompaniesFound: i18n.t('No companies found'),
      anErrorOccurred: i18n.t('An error occurred'),
      search: i18n.t('Search')
    };
  },
  CompanyWizardStepAssets: function CompanyWizardStepAssets() {
    return {
      dataProcessor: i18n.t('Data processor'),
      youCantAddMoreAssets: i18n.t("You can't add more assets until all fields in the previous row are filled"),
      dataController: i18n.t('Data controller'),
      systems: i18n.t('Systems'),
      title: i18n.t('Title'),
      newSystem: i18n.t('New system'),
      customInformationAssets: i18n.t('Custom information assets'),
      none: i18n.t('None'),
      chooseExisting: i18n.t('Choose existing'),
      customIAType: i18n.t('Custom information assets type'),
      select: i18n.t('Select'),
      createNew: i18n.t('Create new'),
      add: i18n.t('Add'),
      chooseExistingProcessor: i18n.t('Choose existing') + ' ' + i18n.t('processor'),
      chooseExistingController: i18n.t('Choose existing') + ' ' + i18n.t('controller'),
      chooseExistingSystem: i18n.t('Choose existing') + ' ' + i18n.t('system'),
      chooseExistingIA: i18n.t('Choose existing') + ' ' + i18n.t('Information asset').toLowerCase(),
      enterTitle: getTitlePlaceholder()
    };
  },
  CompanyWizardStepConfirmation: function CompanyWizardStepConfirmation() {
    return {
      followingAssetsWillBeCreated: i18n.t('Following assets will be created and/or linked to company') + ':',
      dataProcessors: i18n.t('Data processors') + ':',
      dataControllers: i18n.t('Data controllers') + ':',
      systems: i18n.t('Systems') + ':',
      createCompany: i18n.t('Create company')
    };
  },
  CompanyWizardWrapper: function CompanyWizardWrapper() {
    return {
      lei: i18n.t('LEI'),
      addLei: i18n.t('Add LEI'),
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      somethingWentWrong: i18n.t('Something went wrong!'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      addAssets: i18n.t('Add assets'),
      createCompany: i18n.t('Create company'),
      company: i18n.t('Company'),
      assets: i18n.t('Assets'),
      confirmation: i18n.t('Confirmation'),
      welcome: i18n.t('Welcome'),
      areYouSure: i18n.t('Are you sure?'),
      ifYouDeleteThisDraftTheEnteredDataWillNo: i18n.t('If you delete this draft, the entered data will not be saved.'),
      companyWizard: i18n.t('Company wizard'),
      invalidVatNumber: i18n.t('Invalid VAT number'),
      invalidEmailAddress: i18n.t('Invalid e-mail address'),
      aCompanyWithAVATNumberMustHaveACountryCode: i18n.t('A company with a VAT number must have a country code'),
      dataProcessor: i18n.t('Data processor').toLowerCase(),
      systems: i18n.t('Systems').toLowerCase(),
      dataController: i18n.t('Data controller').toLowerCase(),
      customInformationAssets: i18n.t('Custom information assets').toLowerCase(),
      customIAType: i18n.t('Custom information assets type').toLowerCase(),
      chooseExisting: function chooseExisting(module) {
        return i18n.t("Choose existing %s or select 'None' to continue", [module]);
      },
      pleaseSelectACompanyFromTheList: i18n.t('Please select a company from the list to proceed'),
      aCompanyWithVatAlreadyExists: function aCompanyWithVatAlreadyExists(vat) {
        return i18n.t("A company with the VAT number '%s' already exists. Please update existing company", [vat]);
      },
      searchAfterCompanyBeforeProceeding: i18n.t('Search after company before proceeding to next step'),
      alreadyExists: i18n.t('already exists')
    };
  },
  CompanyWizardStepLEI: function CompanyWizardStepLEI() {
    return {
      choose: i18n.t('Choose'),
      title: i18n.t('Title'),
      lei: 'LEI',
      country: i18n.t('Country'),
      theCompanyDoesntHaveAValidLei: function theCompanyDoesntHaveAValidLei(companyName) {
        return i18n.t("The company '%s' doesn’t have a valid LEI", [companyName]);
      }
    };
  },
  CompanyFunctionModal: function CompanyFunctionModal() {
    return {
      editFunction: i18n.t('Edit function'),
      addFunction: i18n.t('Add function'),
      save: i18n.t('Save'),
      cancel: i18n.t('Cancel'),
      name: i18n.t('Name'),
      lastAssessmentDate: i18n.t('Last assessment date'),
      rto: i18n.t('RTO'),
      rpo: i18n.t('RPO'),
      rtoInHours: i18n.t('RTO in hours'),
      rpoInHours: i18n.t('RPO in hours'),
      licensedActivity: i18n.t('Licensed activity'),
      impactOfDiscontinuing: i18n.t('Impact of discontinuing'),
      reasonForCriticality: i18n.t('Reason for criticality'),
      criticalOrImportant: i18n.t('Critical or important'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty')
    };
  }
};