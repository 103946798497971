import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_static_tab_item = _resolveComponent("static-tab-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_static_tab_item, {
    to: "#static-tab"
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode("This is a tab")]);
    }),
    _: 1 /* STABLE */
  })]);
}