// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.italic[data-v-8ec5c5b2] {
  font-style: italic;
}
.mb[data-v-8ec5c5b2] {
  margin-bottom: 20px;
}
.mb[data-v-8ec5c5b2]  .input-field input::-moz-placeholder {
  font-style: italic;
}
.mb[data-v-8ec5c5b2]  .input-field input::placeholder,
.mb[data-v-8ec5c5b2]  .select-selected,
.mb[data-v-8ec5c5b2]  .select-items,
.mb[data-v-8ec5c5b2]  textarea {
  font-style: italic;
}
.mb[data-v-8ec5c5b2]  .notification {
  background-color: inherit;
  border: none;
  padding: 0px;
}
.mb[data-v-8ec5c5b2]  input,
.mb[data-v-8ec5c5b2]  textarea {
  text-indent: 9px;
}
.notification-up[data-v-8ec5c5b2]  .notification {
  padding: 0;
  margin-bottom: -1px;
}
.notification-up[data-v-8ec5c5b2]  .custom-select-inner {
  border: 1px solid #dc3545;
}
.notification-up[data-v-8ec5c5b2]  input {
  border: 1px solid #dc3545;
}
.notification-up.mb[data-v-8ec5c5b2] {
  margin-bottom: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
