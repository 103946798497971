import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import Questionnaire from '@/components/Organisms/Compliance/questionnaire';
import * as dpmAPI from '@/api/compliance/dpm';
export default {
  name: 'DpmAuditQuestionnaire',
  description: 'Simply html form that the recipient can use to answer the questionnaire',
  token: "<dpm-audit-questionnaire\n      v-if=\"questionnaire !== null\"\n      :questionnaire=\"questionnaire\"\n      :activity=\"testActivity\"\n      comment=\"some text in comments need to be rendered to someone. Just the text. Answer my questions please\"\n      @handleSubmit=\"something\"/>",
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    MainWell: MainWell,
    Questionnaire: Questionnaire,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  props: {
    activity: {
      require: true,
      type: Object,
      default: null,
      note: 'The Activity object, could be data processor, contract, system og data controller'
    },
    questionnaire: {
      require: true,
      type: [Object, Array],
      default: function _default() {
        return {};
      },
      note: 'The questionnaire selected from when the sender sends out the questionnaire'
    },
    dpmRequest: {
      require: true,
      type: Object,
      default: function _default() {
        return {};
      },
      note: 'The data processor management request'
    },
    token: {
      require: true,
      type: String,
      default: ''
    },
    comment: {
      require: false,
      type: String,
      default: '',
      note: 'Some comments to the questionnaire'
    }
  },
  emits: ['handleSubmit', 'updated'],
  data: function data() {
    return {
      informationAssets: [],
      processTreeId: null,
      nodeElement: {},
      isLoaded: false,
      lastUpdatedQuestion: null,
      questionnaireSubmitted: false,
      questionTimers: {},
      translate: getTranslate['DpmAuditQuestionnaire']()
    };
  },
  computed: {
    rismaFirstTitle: function rismaFirstTitle() {
      var title = this.translate.youHaveBeenAskedToAnswerAQuestionnaireFor + this.defaultTitle;
      var expiresFormatted = this.dpmRequest && this.dpmRequest.expires ? this.dpmRequest.expires.substring(0, 10) : '';
      return title + '. ' + this.translate.thisLinkExpiresOnThe + expiresFormatted + '.';
    },
    defaultTitle: function defaultTitle() {
      return this.activity.name || this.activity.title || this.translate.noTitleYet;
    },
    questions: function questions() {
      if (this.questionnaire) {
        return this.questionnaire.questionAnswers;
      }
      return [];
    }
  },
  mounted: function mounted() {
    this.isLoaded = true;
  },
  methods: {
    onPreSubmit: function onPreSubmit() {
      var _this = this;
      this.lastUpdatedQuestion && this.updateQuestion(this.lastUpdatedQuestion);
      this.$confirm(this.translate.submitQuestionnaire, this.translate.areYouSureYouWatnToSubmitTheQuestionnaire, function (res) {
        return res && _this.onSubmit();
      }, {
        buttonOkText: this.translate.submit
      });
    },
    onSubmit: function onSubmit() {
      this.questionnaireSubmitted = true;
      this.$emit('handleSubmit', this.questions);
    },
    prepareAndUpdateQuestion: function prepareAndUpdateQuestion(question) {
      var preparedQuestion = this.prepareQuestionForUpdate(question);
      if (this.questions) {
        var index = this.questions.findIndex(function (elem) {
          return elem.id == preparedQuestion.id;
        });
        this.questions[index] = preparedQuestion;
        this.lastUpdatedQuestion = preparedQuestion;
      }
      this.updateQuestion(preparedQuestion, this.$debounceTimeout);
    },
    updateQuestion: function updateQuestion(question) {
      var _this2 = this;
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var id = question.id;
      if (Object.hasOwnProperty.call(this.questionTimers, id)) {
        clearTimeout(this.questionTimers[id]);
      }
      this.questionTimers[id] = setTimeout(function () {
        return dpmAPI.submitQuestionAnswer(_this2.token, question).then(function () {
          return _this2.$emit('updated', question);
        }).catch(function (error) {
          return console.log(error);
        }) // eslint-disable-line
        .finally(function () {
          return delete _this2.questionTimers[id];
        });
      }, timeout);
    },
    prepareQuestionForUpdate: function prepareQuestionForUpdate(question) {
      if (question.questiontype === 12) {
        question.processLibraryId = [];
        // Check for empty answers_options
        if (Object.keys(question.answers_options)[0] !== 'null') {
          Object.keys(question.answers_options).forEach(function (element) {
            question.processLibraryId.push(element);
          });
        }
        delete question.answers_options;
      }
      return question;
    }
  }
};