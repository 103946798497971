import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_2 = {
  key: 1,
  class: "validation-error"
};
var _hoisted_3 = {
  key: 2,
  class: "validation-error"
};
var _hoisted_4 = {
  key: 0,
  class: "items-group group-field-values"
};
var _hoisted_5 = {
  class: "custom-field-group-title"
};
var _hoisted_6 = {
  class: "custom-field-group-content"
};
var _hoisted_7 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_8 = {
  key: 1,
  class: "validation-error"
};
var _hoisted_9 = {
  key: 0,
  class: "input-field-container add-option-container"
};
var _hoisted_10 = {
  key: 1,
  class: "items-group choose-custom-fields"
};
var _hoisted_11 = {
  class: "custom-field-group-title"
};
var _hoisted_12 = {
  key: 0,
  class: "validation-error"
};
var _hoisted_13 = {
  key: 2,
  class: "items-group choose-custom-fields"
};
var _hoisted_14 = {
  class: "table-choose-custom-fields"
};
var _hoisted_15 = {
  class: "table-cell"
};
var _hoisted_16 = {
  class: "table-cell"
};
var _hoisted_17 = {
  key: 0,
  class: "table-cell"
};
var _hoisted_18 = {
  class: "table-cell"
};
var _hoisted_19 = ["onUpdate:modelValue", "value", "onChange"];
var _hoisted_20 = {
  class: "pl-6"
};
var _hoisted_21 = {
  class: "table-cell"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("fieldset", null, [_createElementVNode("div", {
    class: _normalizeClass([{
      disabled: $props.disabled
    }, "items-group"])
  }, [_createElementVNode("div", {
    class: _normalizeClass([{
      error: $options.errors.options && !($props.field.options && $props.field.options.length) || $options.errors.typeId || $options.errors.emptyField
    }, "input-field-container"])
  }, [_createVNode(_component_single_select, {
    options: $options.fieldTypesAll,
    "model-value": $options.fieldType,
    disabled: $props.disabled,
    title: $data.translate.fieldType,
    placeholder: $data.translate.chooseAFieldType,
    "onUpdate:modelValue": $options.onChangeFieldType
  }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "title", "placeholder", "onUpdate:modelValue"])], 2 /* CLASS */), !($props.field.options && $props.field.options.length) && $options.errors.options ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($data.translate.enterAnOption), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.errors.typeId ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($data.translate.chooseAFieldType), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $options.errors.emptyField ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($data.translate.chooseCustomFields), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), $options.fieldType === $data.customFieldTypes.SELECT || $options.fieldType === $data.customFieldTypes.SELECT_MULTI ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.dropdownOptions,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.field.options, function (item, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key
    }, [_createElementVNode("p", {
      class: _normalizeClass([{
        error: item.invalid || item.duplicate
      }, "input-field-container"])
    }, [_createVNode(_component_input_field, {
      modelValue: item.value,
      "onUpdate:modelValue": [function ($event) {
        return item.value = $event;
      }, $options.updateDropdownOption],
      placeholder: $data.translate.enterDropdownOption,
      enabled: !$props.readOnly,
      type: "text"
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "enabled"]), !item.hasValue && !$props.readOnly ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "absolute right-0 top-0 h-full cursor-pointer stroke-3 text-red-50",
      onClick: function onClick($event) {
        return $options.removeOption(item);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */), item.duplicate ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($data.translate.valueShouldBeUnique), 1 /* TEXT */)) : _createCommentVNode("v-if", true), item.invalid ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($data.translate.enterAnOption), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), !$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("a", {
    href: "#",
    class: "add-option flex",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.addOption && $options.addOption.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-3 text-blue-350 mr-4"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addOption), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $options.fieldType === $data.customFieldTypes.NUMERICAL ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
    title: $data.translate.unit,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", {
    class: _normalizeClass([{
      error: $options.errors.emptyUnitField
    }, "input-field-container"])
  }, [_createVNode(_component_input_field, {
    modelValue: $props.field.unit,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $props.field.unit = $event;
    }), $options.updateUnit],
    type: "text",
    placeholder: $data.translate.unit,
    maxlength: 20
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onUpdate:modelValue"])], 2 /* CLASS */), $options.errors.emptyUnitField ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($data.translate.pleaseFillOutThisField), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), $options.fieldType === $data.customFieldTypes.ROW_GROUP && $props.moduleType === $data.MODULES.CONTROLS ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("table", _hoisted_14, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_15, [_createVNode(_component_risma_title, {
    title: $data.translate.chooseCustomFields,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.fieldType,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), $options.hasActiveField ? (_openBlock(), _createElementBlock("th", _hoisted_17, [_createVNode(_component_risma_title, {
    title: $data.translate.chooseOrder,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.customFieldsFiltered, function (customField, key) {
    return _openBlock(), _createElementBlock("tr", {
      key: key
    }, [_createElementVNode("td", _hoisted_18, [_createElementVNode("label", null, [_withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return customField.isActive = $event;
      },
      value: customField.isActive,
      type: "checkbox",
      class: "float-left",
      onChange: function onChange($event) {
        return $options.setChosenCustomFieldActive(customField, $event);
      }
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_19), [[_vModelCheckbox, customField.isActive]]), _createElementVNode("div", _hoisted_20, _toDisplayString(customField.label), 1 /* TEXT */)])]), _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.$trans(customField.fieldtype_text)), 1 /* TEXT */), _createElementVNode("td", null, [customField.isActive ? (_openBlock(), _createBlock(_component_single_select, {
      key: 0,
      options: _ctx.orders,
      "un-sorted": $data.unSorted,
      "model-value": customField.order,
      class: "p-0",
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onChangeChosenCustomFieldOrder(customField, $event);
      }
    }, null, 8 /* PROPS */, ["options", "un-sorted", "model-value", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true)]);
}