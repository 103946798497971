import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("h5", null, "Slot to Main Well", -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  })]);
}