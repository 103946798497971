function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import * as RiskApi from '@/api/risk/risk';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import { userHasProjectAccess } from '@/utils/access';
import { userSettingsNav } from './userSettingsNav';
import { SettingsNav } from '@/constants/menu/navigations';
import { UserLevels } from '@/constants/UserLevels';
import { ObjectTypes } from '@/constants/ObjectTypes';
var ENTERPRISE_RISK_MANAGEMENT_TYPE = 1;
export default {
  name: 'UserSettings',
  introduction: 'Wrapper for user settings pages',
  description: 'Wrapper with menu for user settings pages: profile, notifications',
  token: '<user-settings />',
  components: {
    VerticalMenu: VerticalMenu
  },
  emits: ['updateHeader'],
  data: function data() {
    return {
      allNavItems: [],
      riskProjects: [],
      openedGroups: [],
      translate: {
        period: this.$trans('period')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), {}, {
    servername: function servername() {
      return this.getSettingValue('servername');
    },
    settings: function settings() {
      return {
        'app.module_execution_enabled': this.getSettingValue('app.module_execution_enabled'),
        'app.module_controls_enabled': this.getSettingValue('app.module_controls_enabled'),
        'app.module_risk_enabled': this.getSettingValue('app.module_risk_enabled'),
        'app.module_compliance_enabled': this.getSettingValue('app.module_compliance_enabled'),
        'app.module_risks2_enabled': this.getSettingValue('app.module_risks2_enabled'),
        'app.module_isms_enabled': this.getSettingValue('app.module_isms_enabled'),
        'app.module_contracts_enabled': this.getSettingValue('app.module_contracts_enabled'),
        'app.module_incidents_enabled': this.getSettingValue('app.module_incidents_enabled'),
        'feature.admin_erm_period': this.getSettingValue('feature.admin_erm_period'),
        servername: this.getSettingValue('servername'),
        'feature.app_settings_enabled': this.getSettingValue('feature.app_settings_enabled'),
        'feature.disable_information_assets': this.getSettingValue('feature.disable_information_assets'),
        'feature.anonymization_admin_enabled': this.getSettingValue('feature.anonymization_admin_enabled'),
        'app.authentication_mode': this.getSettingValue('app.authentication_mode'),
        'feature.admin.siem_enabled': this.getSettingValue('feature.admin.siem_enabled'),
        'feature.admin.action_requirements_enabled': this.getSettingValue('feature.admin.action_requirements_enabled'),
        'feature.incidents.workflow': this.getSettingValue('feature.incidents.workflow'),
        'feature.overall.ai_assistant_enabled': this.getSettingValue('feature.overall.ai_assistant_enabled')
      };
    },
    navItemLists: function navItemLists() {
      return this.allNavItems.filter(function (item) {
        return item.rights === true;
      }).map(function (item) {
        var _item$groupItems;
        if (!((_item$groupItems = item.groupItems) !== null && _item$groupItems !== void 0 && _item$groupItems.length)) return item;
        var groupItems = item.groupItems.filter(function (groupItem) {
          return !Object.keys(groupItem).includes('rights') || groupItem.rights === true;
        });
        return _objectSpread(_objectSpread({}, item), {}, {
          groupItems: groupItems
        });
      });
    },
    filteredRiskProjects: function filteredRiskProjects() {
      return this.riskProjects.filter(function (item) {
        return item.type !== ENTERPRISE_RISK_MANAGEMENT_TYPE;
      });
    },
    ermRiskProject: function ermRiskProject() {
      return this.riskProjects.find(function (item) {
        return item.type === ENTERPRISE_RISK_MANAGEMENT_TYPE;
      });
    }
  }),
  watch: {
    $route: function $route() {
      this.defineOpenedGroup();
      this.$scrollTo(document.body, 100);
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      this.getRiskProjects().then(function () {
        _this.prepareNavigation();
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this2 = this;
      return RiskApi.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return _this2.riskProjects = list;
      }).catch(function (e) {
        throw e;
      });
    },
    prepareNavigation: function prepareNavigation() {
      this.getAllNavItems();
      this.defineOpenedGroup();
    },
    getAllNavItems: function getAllNavItems() {
      var riskProjectsItems = this.getRiskProjectsItems();
      this.allNavItems = userSettingsNav(this.currentUser, this.settings, riskProjectsItems);
    },
    defineOpenedGroup: function defineOpenedGroup() {
      var path = this.$route.path;
      if (path[path.length - 1] === '/') {
        path = path.slice(0, -1);
      }
      var group = this.allNavItems.find(function (group) {
        return group.groupItems.find(function (item) {
          return item.path === path;
        });
      });
      if (group) {
        this.openedGroups = [group.groupTitle];
      }
    },
    getRiskProjectsItems: function getRiskProjectsItems() {
      var _this3 = this;
      var items = _toConsumableArray(this.filteredRiskProjects.filter(function (item) {
        return item.enabled;
      }).map(function (project) {
        return {
          title: "".concat(project.title, " ").concat(_this3.translate.period),
          path: "/settings/riskproject/".concat(project.id),
          rights: userHasProjectAccess(_this3.currentUser, project.id, ObjectTypes.RISK_PROJECT, UserLevels.SUPER)
        };
      }));
      if (this.ermRiskProject) {
        items.unshift({
          path: this.settings['feature.admin_erm_period'] === 1 ? '/settings/ermadmin' : SettingsNav.riskSettingsERM.path,
          title: this.ermRiskProject ? "".concat(this.ermRiskProject.title, " ").concat(this.translate.period) : '',
          rights: this.currentUser.is_admin && this.settings['app.module_risk_enabled'] === 1
        });
      }
      return items;
    }
  }
};