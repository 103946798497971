// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saved-button[data-v-cfae3a9c] {
  background-color: rgba(239, 239, 239, 0.5);
}
.save-report-button[data-v-cfae3a9c] {
  background-color: #00acef;
}
.saved-icon[data-v-cfae3a9c] {
  color: #aaaaaa;
  fill: #aaaaaa;
}
.save-report-icon[data-v-cfae3a9c] {
  fill: #ffffff;
}
.search-field[data-v-cfae3a9c]  {
  width: 230px;
}
.search-field[data-v-cfae3a9c]  input {
  border-width: 1px;
}
.column-visibility[data-v-cfae3a9c] {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
