import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  class: "relative flex flex-col flex-grow"
};
var _hoisted_2 = {
  class: "relative flex flex-col flex-grow bg-rm-white border-rm-white"
};
var _hoisted_3 = {
  class: "relative"
};
var _hoisted_4 = {
  key: 0,
  class: "glob-l2 text-rm-signal-grey-dark text-center px-14 pt-4 leading-5"
};
var _hoisted_5 = {
  key: 0,
  class: "absolute inset-0"
};
var _hoisted_6 = {
  class: "glob-l2 text-rm-grey mx-2 mb-2 text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_chat_ellipsis_menu = _resolveComponent("chat-ellipsis-menu");
  var _component_chat_header = _resolveComponent("chat-header");
  var _component_chat_confirmation_dialog = _resolveComponent("chat-confirmation-dialog");
  var _component_chat_message = _resolveComponent("chat-message");
  var _component_chat_input_field = _resolveComponent("chat-input-field");
  var _component_citation_modal = _resolveComponent("citation-modal");
  return _openBlock(), _createElementBlock("div", {
    ref: "chatWidget",
    class: "relative w-full min-w-[544px] max-w-[800px] min-h-[368px] bg-rm-white border rounded-md z-15 overflow-hidden flex flex-col shadow-ai-shadow",
    style: _normalizeStyle("width: ".concat($data.width, "px;"))
  }, [!$data.isResizing ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("div", {
    class: "absolute w-full h-2 left-0 top-0 cursor-ns-resize",
    onMousedown: _cache[0] || (_cache[0] = function ($event) {
      return $options.initializeResize(true);
    })
  }, null, 32 /* NEED_HYDRATION */), _createElementVNode("div", {
    class: "absolute w-2 h-2 left-0 top-0 cursor-nw-resize",
    onMousedown: _cache[1] || (_cache[1] = function ($event) {
      return $options.initializeResize(false);
    })
  }, null, 32 /* NEED_HYDRATION */)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _createVNode(_component_chat_header, {
    ref: "chatHeader",
    onMinimize: $options.minimizeChat
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_chat_ellipsis_menu, {
        class: "ml-auto mr-2",
        region: $props.region,
        "onChange:region": $options.handleChangeRegion,
        "onEnd:history": $options.handleConfirmation,
        "onClear:history": $options.handleClearChatConfirmation
      }, null, 8 /* PROPS */, ["region", "onChange:region", "onEnd:history", "onClear:history"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onMinimize"]), _createElementVNode("div", _hoisted_1, [_withDirectives(_createVNode(_component_chat_confirmation_dialog, {
    class: "absolute inset-0",
    "confirmation-type": $data.confirmationType,
    onAccept: $options.handleAcceptConfirmation,
    onDismiss: $options.handleDismissConfirmation
  }, null, 8 /* PROPS */, ["confirmation-type", "onAccept", "onDismiss"]), [[_vShow, $data.isConfirmationVisible]]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    ref: "chatContent",
    class: "max-h-[70vh] min-h-[268px] overflow-y-auto flex-grow pt-4 px-4 content-end",
    style: _normalizeStyle("height: ".concat($data.height, "px;"))
  }, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.chatHistory, function (message, index) {
    var _$props$chatHistory;
    return _openBlock(), _createElementBlock(_Fragment, {
      key: message.message
    }, [message.isGeneralMessage ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(message.message), 1 /* TEXT */)) : (_openBlock(), _createBlock(_component_chat_message, {
      key: 1,
      sender: message.sender,
      source: message.source,
      "quick-questions": message.quickQuestions,
      message: message.message,
      context: message.context,
      citations: message === null || message === void 0 ? void 0 : message.citations,
      "reload-message-enabled": $options.canBeReloaded(message.id),
      "is-deletable": $options.canBeDeleted(message),
      class: _normalizeClass($props.chatHistory[index].sender === ((_$props$chatHistory = $props.chatHistory[index - 1]) === null || _$props$chatHistory === void 0 ? void 0 : _$props$chatHistory.sender) ? '-mt-2' : ''),
      "onAdd:quickQuestion": $options.submitChat,
      "onOpen:citation": $options.setActiveCitation,
      "onReload:message": function onReloadMessage($event) {
        return _ctx.$emit('reload:message', message.id);
      },
      "onDelete:message": function onDeleteMessage($event) {
        return _ctx.$emit('delete:message', index);
      }
    }, null, 8 /* PROPS */, ["sender", "source", "quick-questions", "message", "context", "citations", "reload-message-enabled", "is-deletable", "class", "onAdd:quickQuestion", "onOpen:citation", "onReload:message", "onDelete:message"]))], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */)), $props.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_5)) : _createCommentVNode("v-if", true)])], 4 /* STYLE */)]), _createVNode(_component_chat_input_field, {
    ref: "chatInput",
    class: "mt-4 flex-grow",
    placeholder: $options.inputPlaceholder,
    disabled: $props.isLoading,
    onOnSubmit: $options.handleSubmit
  }, null, 8 /* PROPS */, ["placeholder", "disabled", "onOnSubmit"]), _createElementVNode("p", _hoisted_6, _toDisplayString($data.translate.aiGeneratedContentMayBe), 1 /* TEXT */)]), $data.activeCitation ? (_openBlock(), _createBlock(_component_citation_modal, {
    key: 1,
    citation: $data.activeCitation.content,
    title: $data.activeCitation.title,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.activeCitation = null;
    })
  }, null, 8 /* PROPS */, ["citation", "title"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */);
}