import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "attachment-selector"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  class: "attachment-selector-files"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_FilePreviewModal = _resolveComponent("FilePreviewModal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_link_item = _resolveComponent("link-item");
  var _component_attachment_modal = _resolveComponent("attachment-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.previewFile ? (_openBlock(), _createBlock(_component_FilePreviewModal, {
    key: 0,
    "file-src": $data.previewFile.fileSrc,
    "file-src-type": $data.previewFile.fileType,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.previewFile = null;
    })
  }, null, 8 /* PROPS */, ["file-src", "file-src-type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass([{
      'no-title': !$props.showTitle
    }, "attachment-selector-title flex justify-between"])
  }, [$props.showTitle ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    editable: false,
    title: $options.title,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), !$props.readOnly ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "px-2 pt-2 cursor-pointer",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return $options.toggleModal && $options.toggleModal.apply($options, arguments);
    }, ["prevent"]))
  }, [_createVNode(_component_feather_icon, {
    icon: "paperclip",
    class: "transform rotate-135 text-gray-610 stroke-2"
  })])) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass([{
      'no-title': !$props.showTitle
    }, "attachment-selector-files-wrap"])
  }, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.files, function (file) {
    return _openBlock(), _createElementBlock("div", {
      key: file.id,
      class: "attachment-selector-item"
    }, [_createVNode(_component_link_item, {
      link: file.url,
      title: file.title || file.filename,
      tooltip: file.title || file.filename,
      icon: file.mimeType ? 'file' : 'link'
    }, {
      preview: _withCtx(function () {
        return [file.mimeType === 'application/pdf' || file.mimeType && file.mimeType.substring(0, 5) === 'image' ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "hover:underline text-center mr-2",
          onClick: function onClick($event) {
            return $options.selectFilePreview(file);
          }
        }, _toDisplayString($data.translate.showPreview), 9 /* TEXT, PROPS */, _hoisted_4)) : _createCommentVNode("v-if", true)];
      }),
      end: _withCtx(function () {
        return [$props.removable ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "attachment-selector-remove",
          onClick: function onClick($event) {
            return $options.remove(file.id);
          }
        }, [_createVNode(_component_feather_icon, {
          icon: "x",
          class: "text-white stroke-2 mr-1"
        })], 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["link", "title", "tooltip", "icon"])]);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */), $data.isModalOpened ? (_openBlock(), _createBlock(_component_attachment_modal, {
    key: 1,
    "attach-file-error": _ctx.attachFileError,
    "attach-file-error-message": _ctx.attachFileErrorMessage,
    "show-footer-buttons": false,
    multiple: $props.multiple,
    "is-uploading": $data.isUploading,
    "uploading-value": $data.uploadingValue,
    onHideAttachFileError: _ctx.resetAttachFileError,
    onDismiss: $options.toggleModal,
    onUpload: $options.handleAttachmentsNew
  }, null, 8 /* PROPS */, ["attach-file-error", "attach-file-error-message", "multiple", "is-uploading", "uploading-value", "onHideAttachFileError", "onDismiss", "onUpload"])) : _createCommentVNode("v-if", true)]);
}