import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: _ctx.$trans($options.editModalHeader),
    "show-buttons": true,
    "button-ok-text": $data.translate.apply,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.editFile,
    onDismiss: $options.dismissEdit
  }, {
    body: _withCtx(function () {
      return [!$data.isValid ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.isValid = true;
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($options.errorMessage), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$options.isLink ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_input_field, {
        modelValue: $data.editedFile.title,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.editedFile.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        type: "text",
        class: "mb-3"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), $props.fileTypesEnabled ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 0,
        title: $data.translate.type,
        options: $props.typeOptions,
        "selected-options": $data.selectedDocTypes,
        placeholder: $data.translate.chooseType,
        "label-key": "label",
        "un-sorted": true,
        class: "mb-3",
        onSelected: $options.selectDocTypes
      }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "onSelected"])) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_input_field, {
        modelValue: $data.editedFile.url,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.editedFile.url = $event;
        }),
        title: $data.translate.linkTo,
        placeholder: $data.translate.insertLink + ' (https, http, ftp, etc.)',
        class: "mb-3",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
        modelValue: $data.editedFile.filename,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.editedFile.filename = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.insertLinkTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])]))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept", "onDismiss"]);
}