import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_single_select, {
    options: $data.options,
    "model-value": $data.selected.value,
    disabled: $props.disabled,
    "onUpdate:modelValue": $options.onSelect
  }, {
    singleLabel: _withCtx(function (_ref) {
      var option = _ref.option;
      return [_createElementVNode("div", null, _toDisplayString(option ? _ctx.$truncate(option.label, 22) : _ctx.placeholder), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["options", "model-value", "disabled", "onUpdate:modelValue"])]);
}