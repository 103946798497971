function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import { getTranslate } from './translate';
import GanttChartActions from '@/components/Molecules/Charts/GanttChartActions';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { getInitiativesFromIds } from '@/utils/format/objectsFromIds';
import { getItemGaps } from './SupervisorUtils';
import { customizeColumnsWithGapsFn, customizeDatasetWithGapsFn } from '@/components/Atoms/RismaDatatable/ExportUtils';
import * as DataAPI from '@/api/risma/data';
export default {
  name: 'SupervisorInitiatives',
  introduction: 'supervisor initiatives dashboard',
  description: 'this sub-page show the supervisor initiatives dashboards',
  token: '<supervisor-initiatives></supervisor-initiatives>',
  components: {
    RismaDatatable: RismaDatatable,
    PreviewModalLink: PreviewModalLink,
    GanttChartActions: GanttChartActions,
    TrafficLight: TrafficLight
  },
  mixins: [RismaDatatableDefaultMixin],
  props: {
    initiatives: {
      required: true,
      type: Array
    },
    allInitiatives: {
      required: true,
      type: Array
    },
    gapArticleMap: {
      type: Map,
      required: true,
      note: 'A list of gap articles'
    }
  },
  emits: ['logReport', 'dismissModal'],
  data: function data() {
    return {
      translate: getTranslate['SupervisorInitiatives']()
    };
  },
  computed: {
    columns: function columns() {
      return {
        trafficlight: this.translate.trafficLight,
        number: '#',
        title: this.translate.title,
        description: this.translate.description,
        gaps: this.translate.gaps,
        organisation: this.translate.organisations,
        responsible: this.translate.responsible,
        status: this.translate.presentStatus,
        target: this.translate.target,
        deadline: this.translate.startdeadline,
        depending: this.translate.dependingOn,
        percentage: this.translate.completionPercentage
      };
    },
    dataset: function dataset() {
      var _this = this;
      return this.initiatives.map(function (initiative) {
        var objectList = getInitiativesFromIds(initiative.dependingOn, _this.allInitiatives);
        var trafficLight = _this.getTrafficLightByObjectTypeAndNumber(initiative.objType, initiative.trafficLight);
        return {
          trafficlight: {
            field: {
              color: trafficLight.color
            },
            fieldText: trafficLight.legend
          },
          number: {
            field: initiative.ipath
          },
          title: {
            activity: getFieldsFromObjectForPreview(initiative),
            fieldText: initiative.title
          },
          description: {
            field: initiative.description
          },
          gaps: {
            activities: getItemGaps(initiative.gap, _this.gapArticleMap),
            rawGaps: initiative.gap
          },
          organisation: {
            field: initiative.organisationIds.map(function (org) {
              return org.visible_name;
            }).join(', ')
          },
          responsible: {
            field: initiative.userIds.responsible.map(function (user) {
              return user.display_name;
            }).join(', ')
          },
          status: {
            field: initiative.status
          },
          target: {
            field: initiative.target
          },
          deadline: {
            field: initiative.start + '<b>/</b>' + initiative.deadline
          },
          depending: {
            field: objectList.map(function (initiative) {
              return {
                url: initiative.url,
                title: "".concat(initiative.ipath, " - ").concat(initiative.title)
              };
            }),
            fieldText: objectList.map(function (initiative) {
              return "".concat(initiative.ipath, " - ").concat(initiative.title);
            }).join(', ')
          },
          percentage: {
            field: initiative.completionPercentage + '%'
          }
        };
      });
    },
    customExport: function customExport() {
      return {
        columns: customizeColumnsWithGapsFn,
        dataset: customizeDatasetWithGapsFn(this.gapArticleMap)
      };
    }
  },
  mounted: function mounted() {
    this.enhanceDataset(this.dataset, this.columns);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightByObjectTypeAndNumber'])), {}, {
    getAllInitiatives: function getAllInitiatives() {
      var _this2 = this;
      return DataAPI.getInitiatives().then(function (_ref) {
        var list = _ref.list;
        return _this2.allInitiatives = list;
      });
    }
  })
};