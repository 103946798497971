function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import CompanyWizardWrapper from '@/components/Organisms/Companies/CompanyWizardWrapper';
import Notification from '@/components/Molecules/Notification';
import NoSearchResults from '@/components/Pages/Compliance/Reports/NoSearchResults';
import ContractPartyGenericTable from './ContractPartyGenericTable';
import { MODAL_TYPES, CONTRACT_PARTY_OPTIONS } from '@/constants/contracts/contract';
import { COMPANY_ASSOCIATION_TYPES } from '@/constants/companies/company';
import { searchByFields } from '@/utils/search';
import { escapeHtml } from '@/utils';
import { compareStrings } from '@/utils/compare';
export default {
  name: 'AddContractPartyModalExtended',
  components: {
    Modal: Modal,
    InputField: InputField,
    RismaButton: RismaButton,
    CompanyWizardWrapper: CompanyWizardWrapper,
    Notification: Notification,
    NoSearchResults: NoSearchResults,
    ContractPartyGenericTable: ContractPartyGenericTable
  },
  props: {
    allCompanies: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Needed for external button'
    },
    type: {
      type: Number,
      default: MODAL_TYPES.INTERNAL,
      validator: function validator(type) {
        return Object.values(MODAL_TYPES).indexOf(type) !== -1;
      }
    },
    parentCompany: {
      type: Object,
      default: null,
      note: 'if selected component should be children'
    },
    payingPartEnabled: {
      type: Boolean,
      default: false,
      note: 'Enabled the Paying part column for external type'
    }
  },
  emits: ['dismiss', 'event:wizard', 'create:contractParties', 'refresh:companies', 'copy'],
  data: function data() {
    return {
      searchValue: '',
      results: [],
      dataset: {
        all: [],
        branch: [],
        sub: []
      },
      errorText: '',
      isSearchProceeded: false,
      translate: getTranslate['AddContractPartyModalExtended']()
    };
  },
  computed: {
    sortedCompanies: function sortedCompanies() {
      return _toConsumableArray(this.allCompanies).sort(function (a, b) {
        return compareStrings(a.name, b.name, false, 'en');
      });
    },
    columns: function columns() {
      var baseColumns = [this.translate.name, this.translate.city, this.translate.vatNumber];
      var typeSpecificColumns = _defineProperty(_defineProperty(_defineProperty({}, MODAL_TYPES.EXTERNAL, [this.translate.signee]), MODAL_TYPES.INTERNAL, [this.translate.signee, this.translate.usingPart]), MODAL_TYPES.COPY, [this.translate.address]);
      if (this.payingPartEnabled) {
        typeSpecificColumns[MODAL_TYPES.INTERNAL].splice(1, 0, this.translate.payingPart);
      }
      return [].concat(baseColumns, _toConsumableArray(typeSpecificColumns[this.type]), [this.translate.choose]);
    },
    isBranchSubLogic: function isBranchSubLogic() {
      return this.type === MODAL_TYPES.INTERNAL && this.parentCompany;
    },
    isCopyMode: function isCopyMode() {
      return this.type === MODAL_TYPES.COPY;
    },
    noResultsMessage: function noResultsMessage() {
      if (Object.values(this.dataset).every(function (list) {
        return !list.length;
      })) {
        return "".concat(this.translate.doesNotHaveAnyBranches(this.parentCompany.name));
      }
      return '';
    },
    noSubcompaniesMessage: function noSubcompaniesMessage() {
      var _this$parentCompany;
      return "".concat(this.translate.doesNotHaveSubcompanies((_this$parentCompany = this.parentCompany) === null || _this$parentCompany === void 0 ? void 0 : _this$parentCompany.name));
    },
    modalHeader: function modalHeader() {
      if (this.type === MODAL_TYPES.INTERNAL) {
        var _this$parentCompany2;
        return this.parentCompany ? "".concat(this.translate.addSub, " ").concat(((_this$parentCompany2 = this.parentCompany) === null || _this$parentCompany2 === void 0 ? void 0 : _this$parentCompany2.name) || '') : "".concat(this.translate.addContractParty, " (").concat(this.translate.internal, ")");
      }
      if (this.type === MODAL_TYPES.COPY) {
        return this.translate.copyDataToSubcompany;
      }
      if (this.parentCompany) {
        return this.translate.addSubcontractor;
      }
      return "".concat(this.translate.addContractParty, " (").concat(this.translate.external, ")");
    }
  },
  watch: {
    allCompanies: function allCompanies() {
      this.searchValue && this.handleSearch();
    }
  },
  mounted: function mounted() {
    if (this.isBranchSubLogic) {
      this.initializeBranchSubData();
    }
    if (this.isCopyMode) {
      this.dataset.all = this.prepareDataset(this.allCompanies);
    }
  },
  methods: {
    initializeBranchSubData: function initializeBranchSubData() {
      var BRANCH = COMPANY_ASSOCIATION_TYPES.BRANCH,
        SUB = COMPANY_ASSOCIATION_TYPES.SUB;
      var parentId = this.parentCompany.id;
      var branchList = this.allCompanies.filter(function (_ref) {
        var companyType = _ref.companyType,
          id = _ref.parentId;
        return companyType === BRANCH && id === parentId;
      });
      var subList = this.allCompanies.filter(function (_ref2) {
        var companyType = _ref2.companyType,
          id = _ref2.parentId;
        return companyType === SUB && id === parentId;
      });
      this.dataset.branch = this.prepareDataset(branchList);
      this.dataset.sub = this.prepareDataset(subList);
    },
    handleSearch: function handleSearch() {
      this.isSearchProceeded = true;
      this.setResults();
      this.dataset.all = this.prepareDataset(this.results);
    },
    prepareDataset: function prepareDataset(array) {
      var _this = this;
      var mapCommonFields = function mapCommonFields(item) {
        return {
          name: escapeHtml(item.name) || '',
          city: escapeHtml(item.city) || '',
          vatNo: item.vatNo || ''
        };
      };
      var additionalFields = _defineProperty(_defineProperty(_defineProperty({}, MODAL_TYPES.EXTERNAL, function (_ref3) {
        var index = _ref3.index;
        return {
          signingParty: {
            value: [],
            index: index
          }
        };
      }), MODAL_TYPES.INTERNAL, function (_ref4) {
        var index = _ref4.index;
        return _objectSpread(_objectSpread({
          signingParty: {
            value: [],
            index: index
          }
        }, _this.payingPartEnabled ? {
          payingParty: {
            value: false,
            index: index
          }
        } : {}), {}, {
          usingParty: {
            value: [],
            index: index
          }
        });
      }), MODAL_TYPES.COPY, function (_ref5) {
        var item = _ref5.item;
        return {
          address: escapeHtml(item.address) || ''
        };
      });
      return array.map(function (item, index) {
        var _additionalFields$_th;
        return _objectSpread(_objectSpread(_objectSpread({}, mapCommonFields(item, index)), ((_additionalFields$_th = additionalFields[_this.type]) === null || _additionalFields$_th === void 0 ? void 0 : _additionalFields$_th.call(additionalFields, {
          item: item,
          index: index
        })) || {}), {}, {
          checkbox: {
            index: index,
            value: [],
            id: item.id
          }
        });
      });
    },
    setResults: function setResults() {
      var fields = ['email', 'vatNo', 'name', 'address'];
      this.results = searchByFields(this.sortedCompanies, fields, this.searchValue);
    },
    updatePayingParty: function updatePayingParty(list, _ref6) {
      var _this2 = this;
      var index = _ref6.index,
        value = _ref6.value;
      if (value) {
        Object.keys(this.dataset).forEach(function (key) {
          _this2.dataset[key].forEach(function (row) {
            if (row.payingParty) row.payingParty.value = false;
          });
        });
      }
      if (this.dataset[list][index].payingParty) {
        this.dataset[list][index].payingParty.value = value;
        this.autoChooseForCompany(list, index);
      }
    },
    updateCheckbox: function updateCheckbox(list, _ref7) {
      var index = _ref7.index,
        value = _ref7.value,
        fieldName = _ref7.fieldName;
      this.errorText = '';
      this.dataset[list][index][fieldName].value = value;
      if (fieldName !== CONTRACT_PARTY_OPTIONS.SELECTED) {
        this.autoChooseForCompany(list, index);
      }
    },
    autoChooseForCompany: function autoChooseForCompany(list, index) {
      var _changedCompany$CONTR, _changedCompany$CONTR2, _changedCompany$CONTR3;
      var changedCompany = this.dataset[list][index];
      var isOneOptionSelected = ((_changedCompany$CONTR = changedCompany[CONTRACT_PARTY_OPTIONS.PAYING_PARTY]) === null || _changedCompany$CONTR === void 0 ? void 0 : _changedCompany$CONTR.value) || ((_changedCompany$CONTR2 = changedCompany[CONTRACT_PARTY_OPTIONS.SIGNING_PARTY]) === null || _changedCompany$CONTR2 === void 0 ? void 0 : _changedCompany$CONTR2.value.length) || ((_changedCompany$CONTR3 = changedCompany[CONTRACT_PARTY_OPTIONS.USING_PARTY]) === null || _changedCompany$CONTR3 === void 0 ? void 0 : _changedCompany$CONTR3.value.length);
      changedCompany[CONTRACT_PARTY_OPTIONS.SELECTED].value = isOneOptionSelected ? [1] : [];
    },
    validateData: function validateData(lists) {
      var _this3 = this;
      this.errorText = '';
      if (lists.every(function (listName) {
        return _this3.dataset[listName].every(function (item) {
          return !item.checkbox.value.length;
        });
      })) {
        this.errorText = this.translate.pleaseSelectOne;
      }
      return !this.errorText;
    },
    prepareDataForAccept: function prepareDataForAccept() {
      var _this4 = this;
      var lists = !this.isBranchSubLogic || this.isCopyMode ? ['all'] : ['branch', 'sub'];
      if (!this.validateData(lists)) return;
      var data = [];
      lists.forEach(function (listName) {
        var list = _this4.dataset[listName].filter(function (item) {
          return item.checkbox.value.length;
        }).map(function (item) {
          return _objectSpread({}, _this4.generateContractPartyFieldsByType(item, _this4.type));
        });
        data.push.apply(data, _toConsumableArray(list));
      });
      var eventName = this.type === MODAL_TYPES.COPY ? 'copy' : 'create:contractParties';
      this.$emit(eventName, data);
    },
    generateContractPartyFieldsByType: function generateContractPartyFieldsByType(item, type) {
      var _this$parentCompany3;
      if (type === MODAL_TYPES.COPY) {
        return {
          companyId: item.checkbox.id
        };
      }
      var commonFields = {
        companyId: item.checkbox.id,
        contractPartyType: this.type,
        signingParty: !!item.signingParty.value.length,
        parentId: ((_this$parentCompany3 = this.parentCompany) === null || _this$parentCompany3 === void 0 ? void 0 : _this$parentCompany3.contractPartyId) || null
      };
      return _objectSpread(_objectSpread({}, commonFields), type === MODAL_TYPES.INTERNAL ? _objectSpread(_objectSpread({}, this.payingPartEnabled ? {
        payingParty: item.payingParty.value ? 1 : 0
      } : {}), {}, {
        usingParty: !!item.usingParty.value.length
      }) : {});
    }
  }
};