function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { createRiskProject as _createRiskProject, updateRiskProject, getAllRiskProjects, deletePeriod as _deletePeriod } from '@/api/risk/risk';
import { getAllScorings, deleteScoring as _deleteScoring } from '@/api/risk/scoring';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import MainWell from '@/components/Atoms/Containers/MainWell';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'CreateRiskProject',
  components: {
    FeatherIcon: FeatherIcon,
    SingleSelect: SingleSelect,
    MainWell: MainWell,
    RismaButton: RismaButton,
    InputField: InputField,
    RismaTitle: RismaTitle,
    Modal: Modal
  },
  data: function data() {
    return {
      projectType: null,
      riskProjects: [],
      projectTypes: [{
        value: 1,
        label: 'Enterprise risk management'
      }, {
        value: 2,
        label: 'ISMS risk / Outsourcing'
      }, {
        value: 3,
        label: 'Article 32'
      }],
      projectName: '',
      translate: getTranslate['CreateRiskProject'](),
      showCreateModal: false,
      showDeletePeriodModal: false,
      isLocked: false,
      periodToDelete: {}
    };
  },
  computed: {
    ermProjectId: function ermProjectId() {
      var _this = this;
      var ermProject = this.riskProjects.find(function (project) {
        return _this.isErm(project);
      });
      return ermProject === null || ermProject === void 0 ? void 0 : ermProject.id;
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useUsersStore, {
    loadUsers: 'load'
  })), mapActions(useUserStore, {
    loadCurrentUser: 'load'
  })), {}, {
    getData: function getData() {
      var _this2 = this;
      Promise.all([getAllScorings(), this.getProjects()]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
          scorings = _ref2[0];
        return _this2.setErmPeriods(scorings.list);
      });
    },
    getProjects: function getProjects() {
      var _this3 = this;
      return getAllRiskProjects().then(function (_ref3) {
        var list = _ref3.list;
        return _this3.riskProjects = list;
      });
    },
    createRiskProject: function createRiskProject() {
      var _this4 = this;
      this.isLocked = true;
      var projectObject = {
        projectName: this.projectName,
        projectType: this.projectType
      };
      return _createRiskProject(projectObject).then(function () {
        _this4.getData();
        _this4.$notify({
          title: _this4.translate.riskProjectCreated
        });
        _this4.loadUsers();
        _this4.loadCurrentUser();
      }).catch(function (error) {
        _this4.$notify({
          title: error,
          type: 'error'
        });
      }).finally(function () {
        return _this4.dismissCreateModal();
      });
    },
    setErmPeriods: function setErmPeriods(periods) {
      var _this5 = this;
      var ermProject = this.riskProjects.find(function (project) {
        return _this5.isErm(project);
      });
      if (!ermProject) return;
      ermProject.projectPeriods = periods;
    },
    toggleProject: function toggleProject(projectId, enabled) {
      var _this6 = this;
      var data = {
        projectId: projectId,
        enabled: enabled
      };
      updateRiskProject(projectId, data).then(function (project) {
        _this6.loadUsers();
        _this6.loadCurrentUser();
        return _this6.updateProject(project);
      });
    },
    updateProject: function updateProject(project) {
      var index = this.riskProjects.findIndex(function (p) {
        return +p.id === +project.id;
      });
      if (!this.riskProjects[index]) return;
      this.riskProjects[index].enabled = project.enabled;
      this.$notify({
        title: this.translate.riskProjectEnabledDisabled
      });
    },
    onDeletePeriod: function onDeletePeriod(id, project) {
      this.periodToDelete = {
        id: id,
        project: project
      };
      this.showDeletePeriodModal = true;
    },
    handleDeletePeriod: function handleDeletePeriod() {
      this.showDeletePeriodModal = false;
      this.isErm(this.periodToDelete.project) ? this.deleteScoring(this.periodToDelete.id) : this.deletePeriod(this.periodToDelete.project.id, this.periodToDelete.id);
      this.periodToDelete = {};
    },
    deleteScoring: function deleteScoring(id) {
      var _this7 = this;
      return _deleteScoring(id).then(function () {
        _this7.removePeriodFromList(_this7.ermProjectId, id);
        _this7.$notify({
          title: _this7.translate.riskPeriodDeleted
        });
      }).catch(function () {
        _this7.$notify({
          title: _this7.translate.youCannotDeleteThisPeriodAsItContainsDataThatIsStillInUse,
          type: 'error'
        });
      });
    },
    deletePeriod: function deletePeriod(projectId, id) {
      var _this8 = this;
      return _deletePeriod(projectId, id).then(function () {
        _this8.removePeriodFromList(projectId, id);
        _this8.$notify({
          title: _this8.translate.riskPeriodDeleted
        });
      }).catch(function () {
        _this8.$notify({
          title: _this8.translate.youCannotDeleteThisPeriodAsItContainsDataThatIsStillInUse,
          type: 'error'
        });
      });
    },
    removePeriodFromList: function removePeriodFromList(projectId, id) {
      var project = this.riskProjects.find(function (project) {
        return project.id === projectId;
      });
      if (!project) return;
      project.projectPeriods = project.projectPeriods.filter(function (period) {
        return period.id !== id;
      });
    },
    isErm: function isErm(project) {
      return project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
    },
    dismissCreateModal: function dismissCreateModal() {
      this.showCreateModal = false;
      this.projectName = '';
      this.isLocked = false;
    },
    statusToText: function statusToText(projectType, periodStatus) {
      if (projectType === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) {
        switch (periodStatus) {
          case 1:
            return this.translate.open;
          case 2:
            return this.translate.draft;
          case 10:
            return this.translate.closed;
        }
      } else {
        switch (periodStatus) {
          case 0:
            return this.translate.draft;
          case 1:
            return this.translate.open;
          case 2:
            return this.translate.closed;
        }
      }
      return projectType + 'X';
    }
  })
};