import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "grid grid-cols-3 gap-4 items-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  var _component_input_field = _resolveComponent("input-field");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_select, {
    title: $options.typeOfIctServicesLabel,
    options: $props.options.typeOfServices,
    "selected-options": $props.data.typeOfServices,
    placeholder: $data.translate.typeOfIctServices,
    disabled: $props.readOnly,
    "label-key": "label",
    "track-by": "value",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleUpdate('typeOfServices', $event);
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder", "disabled"]), _createVNode(_component_single_select, {
    "model-value": $props.data.substitutabilityOfICTProvider,
    disabled: $props.readOnly,
    title: $options.ictTppSubstitutabilityLabel,
    options: $props.options.substitutabilityOfICTProvider,
    placeholder: $data.translate.ictTppSubstitutability,
    "with-reset": true,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.handleUpdate('substitutabilityOfICTProvider', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
    "model-value": $props.data.substitutabilityReason,
    disabled: $props.readOnly,
    title: $options.reasonForSubstitutabilityLabel,
    options: $props.options.substitutabilityReason,
    placeholder: $data.translate.reasonForSubstitutability,
    "with-reset": true,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $options.handleUpdate('substitutabilityReason', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "title", "options", "placeholder"]), _createVNode(_component_date_time_picker, {
    modelValue: $props.data.lastAudit,
    "onUpdate:modelValue": [_cache[3] || (_cache[3] = function ($event) {
      return $props.data.lastAudit = $event;
    }), _cache[4] || (_cache[4] = function ($event) {
      return $options.handleUpdate('lastAudit', $event);
    })],
    title: $options.dateOfLastAuditLabel,
    "plugin-options": $options.pickerConfig,
    disabled: $props.readOnly,
    "delete-enabled": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "plugin-options", "disabled"]), _createVNode(_component_single_select, {
    "model-value": $props.data.existenceOfExitPlan,
    disabled: $props.readOnly,
    title: $options.exitPlanLabel,
    options: $props.options.existenceOfExitPlan,
    placeholder: $data.translate.exitPlan,
    "with-reset": true,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $options.handleUpdate('existenceOfExitPlan', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
    "model-value": $props.data.possibilityOfReintegration,
    disabled: $props.readOnly,
    title: $options.reintegrationServiceLabel,
    options: $props.options.possibilityOfReintegration,
    placeholder: $data.translate.reintegrationService,
    "with-reset": true,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $options.handleUpdate('possibilityOfReintegration', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "title", "options", "placeholder"]), _createVNode(_component_single_select, {
    "model-value": $props.data.impactOfDiscontinuation,
    disabled: $props.readOnly,
    placeholder: $data.translate.impactOfDiscontinuing,
    title: $options.impactOfDiscontinuingLabel,
    options: $props.options.impactOfDiscontinuation,
    "with-reset": true,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $options.handleUpdate('impactOfDiscontinuation', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "title", "options"]), _createVNode(_component_single_select, {
    "model-value": $props.data.alternativeIctTppIdentified,
    disabled: $props.readOnly,
    placeholder: $data.translate.alternativeIctTpp,
    title: $options.alternativeIctTppLabel,
    options: $props.options.alternativeIctTppIdentified,
    "with-reset": true,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $options.handleUpdate('alternativeIctTppIdentified', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "disabled", "placeholder", "title", "options"]), _createVNode(_component_input_field, {
    "model-value": $props.data.identificationOfAlternativeIctTpp,
    title: $options.identificationOfAlternativeIctTppLabel,
    placeholder: $data.translate.alternativeIctTppOther,
    enabled: !$props.readOnly,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $options.handleInputUpdate('identificationOfAlternativeIctTpp', $event);
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "placeholder", "enabled"])]);
}