// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled .toggle-control[data-v-7a5befa3] {
  background-color: rgba(190, 190, 190, 0.5);
}
.disabled .toggle-control[data-v-7a5befa3]:after {
  opacity: 0.3;
}
.toggle-control[data-v-7a5befa3] {
  position: relative;
  display: block;
  width: 30px;
  height: 16px;
  border: 1px solid #0e2954;
  border-radius: 8px;
  transition: all 0.2s ease;
}
.toggle-control[data-v-7a5befa3]:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 3px;
  border: 1px solid #0e2954;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.2s ease;
}
input:checked ~ .toggle-control[data-v-7a5befa3]:after {
  left: 15px;
  background-color: #0e2954;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
