function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getRisksDashboardsOverview as _getRisksDashboardsOverview, getRiskProjects } from '@/api/risk/risk';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import RismaLineChart from '@/components/Molecules/Charts/RismaLineChart';
import { RiskStatus } from '@/constants/risks/RiskStatus';
import { RismaHorisontalBarChartMixin } from '@/mixins/RismaHorisontalBarChartMixin';
import { checkERMByProjectId } from '@/utils/risks/check';
export default {
  name: 'RisksDashboard',
  introduction: 'Risk Dashboard page component',
  description: 'Complex page component, /risk2/projects/{n}/dashboard',
  token: '<risk-dashboard />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaLineChart: RismaLineChart,
    RismaBarChart: RismaBarChart
  },
  mixins: [RismaHorisontalBarChartMixin],
  props: {
    projectId: {
      required: true,
      type: [Number, String],
      note: 'Id of the Risk Project'
    }
  },
  data: function data() {
    return {
      charts: [],
      topStatus: {},
      periods: [],
      periodSelectedValue: null,
      isLoaded: false,
      translate: getTranslate['RisksDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    isERM: function isERM() {
      return checkERMByProjectId(this.projectId, this.currentUser);
    },
    totalControl: function totalControl() {
      var _this = this;
      return Object.keys(this.topStatus).map(function (item) {
        return {
          label: item,
          value: _this.topStatus[item]
        };
      });
    },
    periodsSelect: function periodsSelect() {
      return this.periods.map(function (item) {
        return {
          label: item.title,
          value: item.id
        };
      });
    }
  }),
  watch: {
    periodSelectedValue: function periodSelectedValue() {
      this.getRisksDashboardsOverview(this.projectId, this.periodSelectedValue);
    },
    $route: function $route() {
      var _this2 = this;
      this.getData().then(function () {
        return _this2.isLoaded = true;
      });
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getData().then(function () {
      return _this3.isLoaded = true;
    });
  },
  methods: {
    getComponent: function getComponent(type) {
      if (type === 'bar') {
        return 'RismaBarChart';
      } else if (type === 'line') {
        return 'RismaLineChart';
      }
      return '';
    },
    getData: function getData() {
      if (this.isERM) {
        return this.getRisksDashboardsOverview(this.projectId);
      }
      return this.getRisksProjects();
    },
    getRisksDashboardsOverview: function getRisksDashboardsOverview(projectId, periodId) {
      var _this4 = this;
      return _getRisksDashboardsOverview(projectId, periodId).then(function (_ref) {
        var charts = _ref.charts,
          topStatus = _ref.topStatus;
        _this4.charts = _this4.prepareChartsData(charts);
        _this4.topStatus = topStatus;
      });
    },
    getRisksProjects: function getRisksProjects() {
      var _this5 = this;
      return getRiskProjects().then(function (_ref2) {
        var list = _ref2.list;
        var currentRiskProject = list.find(function (item) {
          return +item.id === +_this5.projectId;
        });
        _this5.periods = currentRiskProject.projectPeriods.filter(function (period) {
          return period.status !== RiskStatus.DRAFT;
        });
        _this5.periodSelectedValue = _this5.periods.find(function (item) {
          return item.isCurrent;
        }).id;
      });
    },
    // doesn't break RismaLineChart - it doesn't have height parameter
    calculateHeights: function calculateHeights(chart) {
      var minBarLength = 25;
      var length = chart._labels.length;
      var defaultHeight = 400;
      return Math.max(defaultHeight, minBarLength * length);
    }
  }
};