function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import * as Cookies from 'tiny-cookie';
import clip from 'text-clipper';
import { CustomFieldTypes } from '@/constants/CustomFieldTypes';
import { getColumnLabelName } from '@/utils/CustomFields';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
export function truncate(text, stop, clamp) {
  if (text && text.length > 0) {
    text = text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  }
  return text;
}
export function getQueryStringAsObject() {
  var _URLSearchParams;
  var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var result = {};
  var entries = ((_URLSearchParams = new URLSearchParams(query)) === null || _URLSearchParams === void 0 ? void 0 : _URLSearchParams.entries()) || {};
  var _iterator = _createForOfIteratorHelper(entries),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
        key = _step$value[0],
        value = _step$value[1];
      result[key] = value;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return result;
}
export function truncateHtml(htmlString, stop, settings) {
  return clip(htmlString, stop, settings);
}
export function sanitize(text) {
  return text.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#039;');
}
export function pushChildrenToParent(rootNode, data, childCallback, childLevel) {
  if (rootNode && rootNode.children) {
    if (typeof childLevel === 'number') {
      childLevel += 1;
    }
    rootNode.children.forEach(function (child) {
      if (child) {
        data.push(child);
        if (typeof childCallback === 'function') {
          childCallback(child, childLevel);
        }
        if (child.children) {
          pushChildrenToParent(child, data, childCallback, childLevel);
        }
      }
    });
  }
}
export function getLanguage() {
  var locale = Cookies.get('risma_lang');
  locale = locale ? locale.split('_')[0] : 'en'; // For jest default: 'en'
  return locale;
}
export function hasValue(value) {
  return value || value === 0 || value === '';
}
export function findTimeOffset(timezones, value) {
  return timezones.find(function (item) {
    return item.value === value;
  });
}
export function getProperSelectedOptions() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var result = options;

  // UI fallback to array
  if (!Array.isArray(options) && _typeof(options) === 'object' && options !== null) {
    result = Object.values(options);
  }
  return result;
}
export function isIE() {
  var ua = navigator.userAgent;

  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
}
export function findPropById(id, data, prop) {
  var result = '';
  if (id !== undefined && id !== null) {
    var value = data.find(function (item) {
      return +item.id === +id;
    });
    result = value ? value[prop] : result;
  }
  return result;
}
export function getListOfPropsByIds(ids, data, prop) {
  if (!(ids !== null && ids !== void 0 && ids.length)) return [];
  return ids.reduce(function (acc, cur) {
    var element = data.find(function (_ref) {
      var id = _ref.id;
      return +id === +cur;
    });
    if (element !== null && element !== void 0 && element[prop]) acc.push(element[prop]);
    return acc;
  }, []);
}
export function findPropsByIds(ids, data, prop) {
  var delimiter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ', ';
  return getListOfPropsByIds(ids, data, prop).join(delimiter);
}
export function findPropsByIdsSorted(ids, data, prop) {
  var delimiter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ', ';
  return _toConsumableArray(getListOfPropsByIds(ids, data, prop)).sort().join(delimiter);
}
export function findIdsInArray(ids, data) {
  if (!ids) return [];
  return ids.reduce(function (arr, item) {
    var element = data.find(function (company) {
      return company.id === item;
    });
    if (element) arr.push(element);
    return arr;
  }, []);
}
export function findProps(data, prop) {
  var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ', ';
  var result = [];
  if (data && data.length) {
    data.map(function (item) {
      result.push(item[prop]);
    });
  }
  result = result.join(delimiter);
  return result;
}
export function removeDuplicates(data) {
  var obj = {};
  var result = [];
  for (var i = 0, len = data.length; i < len; i++) {
    obj[data[i].id] = data[i];
  }
  for (var key in obj) {
    result.push(obj[key]);
  }
  return result;
}
export function removeHtmlTags(data) {
  return data.replace(/&nbsp;|<\/?[^>]+(>|$)/g, '').trim();
}
export function purifyItemField(item, toLowerCase) {
  var htmlToText = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var val = getCorrectRowField(item);
  var newLineState = '&newLineWrapper';
  val = val.replace(new RegExp('</div>', 'g'), "</div>".concat(newLineState)); //removeHTMLTags would remove \n and \r
  val = val.replace(new RegExp('\\n', 'g'), newLineState);
  val = val.replace(new RegExp('&nbsp;', 'g'), ' ');
  if (htmlToText) {
    val = removeHtmlTags(val);
  }
  val = val.replace(new RegExp(newLineState, 'g'), ' \r\n');
  val = val.trim();
  if (toLowerCase) {
    val = val.toLowerCase();
  }
  return val;
}
export function getCorrectRowField(item) {
  var _result;
  var result = Object.keys(item).includes('fieldText') ? item.fieldText : item.field;
  result = (_result = result) !== null && _result !== void 0 ? _result : '';
  if (result && _typeof(result) === 'object') {
    if (Array.isArray(result)) {
      result = result.map(function (item) {
        if (_typeof(item) === 'object') {
          return getRowDataAsObject(item);
        }
        if (typeof item === 'string' || typeof item === 'number') {
          return item;
        }
      }).join(',');
    } else {
      result = Object.hasOwnProperty.call(result, 'activities') ? getRowDataAsObject(result.activities, 'textField') : getRowDataAsObject(result);
    }
  }
  result = result.toString().trim();
  return result;
}
export function getRowDataAsObject(rowData) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'title';
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ',';
  if (Array.isArray(rowData)) {
    rowData = rowData.map(function (item) {
      return item[key];
    }).filter(function (item) {
      return item === 0 || !!item;
    }).join(separator);
  } else {
    rowData = rowData[key] || '';
  }
  return rowData;
}
export function getOnlyUniqueAndExist(data) {
  var result = Array.from(new Set(data));
  return result.filter(function (el) {
    return el;
  });
}
export function setMaxlength(e, maxlength) {
  var result = e;
  if (typeof result === 'string') {
    result = result.substring(0, maxlength);
  }
  return result;
}
export function getBase64Content(e) {
  return e.target.result ? e.target.result.substring(e.target.result.indexOf('base64,') + 7) : '';
}
export function findValueById(data, id, key) {
  var result = data.find(function (item) {
    return item.id === id;
  });
  if (result) {
    return result[key];
  }
  return '';
}
export function findLabelValue(currentItem, rows) {
  var result = '';
  rows.map(function (item) {
    if (item.id === currentItem.id) {
      if (currentItem.value > 0 && item.options[currentItem.value]) {
        result = item.options[currentItem.value].value;
      }
    }
  });
  return result;
}
export function filterByIAAccess(users) {
  return users.filter(function (item) {
    return item.iaAccess;
  });
}
export function addHyphensBeforeTitle(item, n) {
  var symbolsToAdd = '--',
    hyphenPart = symbolsToAdd;
  if (typeof n === 'number') {
    try {
      while (n) {
        hyphenPart += symbolsToAdd;
        n--;
      }
    } catch (e) {
      // nothing
    }
  }
  if (item.title) {
    item.title = "".concat(hyphenPart, " ").concat(item.title);
  }
}
export function filterArrayByListOfPropValues(itemsList, listOfPropValues) {
  var prop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  if (!itemsList || !itemsList.length || !listOfPropValues || !listOfPropValues.length) return [];
  return itemsList.filter(function (item) {
    return listOfPropValues.includes(item[prop]);
  });
}
export function isSomePropsIncludedInFilterList(filterList, propsList) {
  var prop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  if (!(filterList !== null && filterList !== void 0 && filterList.length)) return true;
  if (!(propsList !== null && propsList !== void 0 && propsList.length)) return false;
  return filterList.some(function (item) {
    return propsList.includes(item[prop]);
  });
}
export function isIncludedInFrameworksList(frameworks, propsList) {
  var includeUnderlying = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!(frameworks !== null && frameworks !== void 0 && frameworks.length)) return true;
  if (!(propsList !== null && propsList !== void 0 && propsList.length)) return false;
  var propsSet = new Set(propsList);
  if (!includeUnderlying) {
    return frameworks.some(function (framework) {
      return propsSet.has(framework.id);
    });
  }
  function checkUnderlyingList(framework) {
    var _framework$children;
    if (propsSet.has(framework.id)) {
      return true;
    }
    return ((_framework$children = framework.children) === null || _framework$children === void 0 ? void 0 : _framework$children.some(function (subFramework) {
      return checkUnderlyingList(subFramework);
    })) || false;
  }
  return frameworks.some(function (item) {
    return checkUnderlyingList(item);
  });
}
export function getUserLanguage(user) {
  var _user$lang;
  return user === null || user === void 0 || (_user$lang = user.lang) === null || _user$lang === void 0 ? void 0 : _user$lang.split('_')[0];
}
export function findListByIds() {
  var ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return ids.map(function (item) {
    return list.find(function (l) {
      return l.id === item;
    });
  }).filter(Boolean);
}
export function getThreatLabelWithCategory(threat) {
  var _threat$category;
  var categoryTitle = threat === null || threat === void 0 || (_threat$category = threat.category) === null || _threat$category === void 0 ? void 0 : _threat$category.title;
  if (categoryTitle) {
    return "".concat(categoryTitle, " > ").concat(threat.title, "\n");
  } else {
    return "".concat(threat.title, "\n");
  }
}
export function getThreatsLabelWithCategories(threatIds, threats) {
  var threatsFilteredById = threats.filter(function (threat) {
    return threatIds.indexOf(threat.id) !== -1;
  });
  var label = '';
  threatsFilteredById.forEach(function (threat) {
    label += getThreatLabelWithCategory(threat);
  });
  return label;
}
export function isFieldInOptionalFields(activityType, field) {
  var _activityType$optiona;
  if (!activityType) return true;
  return !!((_activityType$optiona = activityType.optionalFields) !== null && _activityType$optiona !== void 0 && _activityType$optiona.find(function (item) {
    return item == field;
  }));
}
export function isDataFlowEnabled(project, getSettingValue, questionnaireEnabled) {
  if (!project) return false;
  return +project.type === ProjectTypes.GDPR && getSettingValue('feature.dataflow') && questionnaireEnabled;
}
export function isHexColor(str) {
  var regex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/g;
  return regex.test(str);
}

// key => userIds.responsible
export function getDeep(item, key) {
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '-';
  if (!key.length) return null;
  var keyArr = key.split(separator);
  var result = item;
  var currentKeyIdx = 0;
  while (keyArr.length > currentKeyIdx && result[keyArr[currentKeyIdx]]) {
    result = result[keyArr[currentKeyIdx]];
    currentKeyIdx++;
  }
  return keyArr.length === currentKeyIdx ? result : null;
}
export function prepareLink(url, title) {
  var result = "<a href=\"".concat(url, "\" class=\"block\" target=\"_blank\"");
  var isAbsolutePath = url.startsWith('https://') || url.startsWith('http://') || url.startsWith('f2p://');
  if (isAbsolutePath) {
    result += ' rel="noopener noreferrer"';
  }
  result += ">".concat(title, "</a>");
  return result;
}
export function prepareInactiveHtml(data) {
  return data ? "<span class=\"text-rm-urban\">".concat(data, "</span>") : '';
}
export var getCustomFieldsColsFromActivityTypes = function getCustomFieldsColsFromActivityTypes(activityTypes) {
  var unitPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Unit';
  if (!activityTypes || !activityTypes.length) return {};
  var result = {};
  var fieldsIdsAdded = [];
  activityTypes.forEach(function (_ref2) {
    var fields = _ref2.fields,
      deleted = _ref2.deleted;
    if (deleted || !fields || !fields.length) return;
    fields.forEach(function (field) {
      var isFieldAlreadyAdded = fieldsIdsAdded.indexOf(field.id) !== -1;
      var isRowType = field.typeId === CustomFieldTypes.ROW_GROUP;
      if (isFieldAlreadyAdded || isRowType) return;
      var label = getColumnLabelName(field.id);
      result[label] = {
        label: field.label,
        fieldType: field.typeId
      };
      if (field.typeId === CustomFieldTypes.NUMERICAL) {
        var unityLabel = getColumnLabelName(field.id + unitPrefix);
        result[unityLabel] = {
          label: field.label + ' ' + unitPrefix,
          field: field.unit
        };
      }
      fieldsIdsAdded.push(field.id);
    });
  });
  return result;
};
export function generateKey() {
  return Math.random().toString(20);
}
export function checkStringIsInt(num) {
  var parsedValue = parseInt(num);
  var sameLength = parsedValue.toString().length === (num === null || num === void 0 ? void 0 : num.toString().length);
  return !isNaN(parsedValue) && sameLength && Number.isInteger(parsedValue);
}
export function getAllChildrenIds(item) {
  var _item$children;
  var result = [+item.id];
  (_item$children = item.children) === null || _item$children === void 0 || _item$children.forEach(function (child) {
    result.push.apply(result, _toConsumableArray(getAllChildrenIds(child)));
  });
  return result;
}
export function formatCurrency(value) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'en';
  var formattedValue = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
  return formattedValue === 'NaN' ? '' : formattedValue;
}
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export function throttle(cb) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var shouldWait = false;
  var waitingArgs = false;
  var timeoutId = null;
  var _timeoutFn = function timeoutFn() {
    if (waitingArgs) {
      cb.apply(void 0, _toConsumableArray(waitingArgs));
      waitingArgs = false;
      timeoutId = setTimeout(_timeoutFn, delay);
    } else {
      shouldWait = false;
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  var throttled = function throttled() {
    for (var _len = arguments.length, arg = new Array(_len), _key = 0; _key < _len; _key++) {
      arg[_key] = arguments[_key];
    }
    if (shouldWait) {
      waitingArgs = arg;
      return;
    }
    cb.apply(void 0, arg);
    shouldWait = true;
    timeoutId = setTimeout(_timeoutFn, delay);
  };
  throttled.flush = function () {
    if (waitingArgs) {
      clearTimeout(timeoutId);
      cb.apply(void 0, _toConsumableArray(waitingArgs));
      timeoutId = null;
      waitingArgs = false;
      shouldWait = false;
    }
  };
  return throttled;
}
export function hasCommonElements(array1, array2) {
  return array1.some(function (item) {
    return array2.includes(item);
  });
}
export function getAllDescendantsIds() {
  var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var result = [];
  children.forEach(function (child) {
    result.push(child.id);
    result.push.apply(result, _toConsumableArray(getAllDescendantsIds(child.children)));
  });
  return result;
}
export function getFrameworkAndNestedIds(frameworks) {
  var ids = [];
  function getChildrenIds(framework) {
    var _framework$children2;
    ids.push(framework.id);
    if ((_framework$children2 = framework.children) !== null && _framework$children2 !== void 0 && _framework$children2.length) {
      framework.children.forEach(function (item) {
        return getChildrenIds(item);
      });
    }
  }
  var filteredFrameworks = filterTopLevelFrameworks(frameworks);
  filteredFrameworks.forEach(function (framework) {
    return getChildrenIds(framework);
  });
  return ids;
}
export function filterTopLevelFrameworks(frameworks) {
  var ancestors = frameworks.map(function (framework) {
    return framework.ancestors;
  });
  return frameworks.filter(function (framework) {
    var parts = framework.ancestors.split(',');
    for (var i = 1; i < parts.length; i++) {
      var parent = parts.slice(0, i).join(',');
      if (ancestors.includes(parent)) {
        return false;
      }
    }
    return true;
  });
}
export function getAiTermsPdfSrc(currentUser) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var locale = getUserLanguage(currentUser) === 'da' ? 'da' : 'en';
  return "".concat(window.location.protocol, "//").concat(window.location.hostname, "/risma2/ai/termsAndConditions").concat(prefix, "-").concat(locale, ".pdf");
}