// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionnaire-admin-scoring[data-v-0750ea70] {
  position: relative;
}
.actions[data-v-0750ea70] {
  margin: 0 0 0.5rem;
}
.actions[data-v-0750ea70]  .risma-button-btn {
  min-width: 0 !important;
}
.scoring-content[data-v-0750ea70] {
  padding: 1rem 0 0;
}
.main-list[data-v-0750ea70] {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main-list .item-name[data-v-0750ea70] {
  margin: 0 0 0.5rem;
}
.main-list .item-name[data-v-0750ea70]  .risma-title {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.main-list .item-name .question-type[data-v-0750ea70] {
  color: #737373;
}
.main-list li[data-v-0750ea70] {
  margin: 0 0 2rem;
}
.main-list .item-options[data-v-0750ea70] {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 0 0 0 2rem;
}
.main-list .item-options .item-option[data-v-0750ea70] {
  display: flex;
  margin: 0 0 0.5rem;
  align-items: center;
}
.main-list .item-options .option-name[data-v-0750ea70] {
  width: 200px;
  margin-right: 10px;
}
.main-list .item-options .option-field[data-v-0750ea70] {
  width: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
