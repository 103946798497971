function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
import { clickOutside } from '@/utils/directives/clickOutside';
import { exportTypes, getExportFn } from '@/constants/exportTypes';
export default {
  name: 'ExportFrame',
  description: 'Component is used to export html structure to JPEG, PDF, etc.',
  token: '<export-frame><BarChart/></export-frame>',
  components: {
    FeatherIcon: FeatherIcon,
    RismaExcel: RismaExcel
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    useExport: {
      required: false,
      type: Boolean,
      default: true
    },
    wrapperClass: {
      required: false,
      type: String,
      default: ''
    },
    isExportOnParent: {
      type: Boolean,
      required: false,
      default: false
    },
    excelExportFunction: {
      type: Function,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      downloadIconClasses: "flex items-center\n                justify-center p-1 absolute right-0 top-0 z-10\n                border border-2 transition rounded-full cursor-pointer",
      showExportList: false,
      exportTypes: exportTypes,
      exportOptions: [exportTypes.PDF, exportTypes.PNG, exportTypes.JPEG],
      isHover: false
    };
  },
  computed: {
    isExcelExportAvailable: function isExcelExportAvailable() {
      return !!this.excelExportFunction;
    }
  },
  mounted: function mounted() {
    var _this$$refs$root;
    if (!this.useExport) return;
    if (((_this$$refs$root = this.$refs.root) === null || _this$$refs$root === void 0 ? void 0 : _this$$refs$root.children.length) > 1) {
      throw new Error('ExportFrame requires exactly one child');
    }
  },
  methods: {
    exportFrame: function exportFrame(type) {
      this.getCanvas().then(function (canvas) {
        return getExportFn(canvas)(type);
      });
    },
    getCanvas: function getCanvas() {
      var root = this.$refs.root;
      var canvas = this.findClosestCanvas(root);
      if (!canvas) {
        return import(/*webpackChunkName: 'html2canvas'*/'html2canvas').then(function (_ref) {
          var html2canvas = _ref.default;
          canvas = html2canvas(root, {
            scale: 1.5,
            onclone: function onclone(documentClone) {
              var boxWithShadow = documentClone.querySelector('.pdf-box-shadow-fix');
              // workaround to remove box-shadow from box as html2canvas doesn't support box-shadow
              if (boxWithShadow) {
                boxWithShadow.style.boxShadow = 'none';
              }
            }
          });
          return canvas;
        });
      }
      return new Promise(function (resolve) {
        return resolve(canvas);
      });
    },
    filterNonEmptyNodes: function filterNonEmptyNodes(nodes) {
      var COMMENT_NODE = 8;
      var TEXT_NODE = 3;
      return _toConsumableArray(nodes).filter(function (node) {
        return node.nodeType === COMMENT_NODE ? false : node.nodeType !== TEXT_NODE || node.nodeValue;
      });
    },
    findClosestCanvas: function findClosestCanvas(root) {
      var filteredRoot = this.filterNonEmptyNodes(root.childNodes);
      if (filteredRoot.length > 1) return null;
      var currentNode = filteredRoot[0];
      var canvas = null;
      if (currentNode.nodeName === 'CANVAS') {
        return currentNode;
      }
      var childNodes = this.filterNonEmptyNodes(currentNode.childNodes);
      while (childNodes && childNodes.length === 1) {
        currentNode = currentNode.firstChild;
        if (!currentNode.childNodes.length && currentNode.nodeName === 'CANVAS') {
          canvas = currentNode;
        }
        childNodes = this.filterNonEmptyNodes(currentNode.childNodes);
      }
      return canvas;
    },
    closeExportMenu: function closeExportMenu() {
      this.showExportList = false;
    }
  }
};