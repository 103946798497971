function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useTagsStore } from '@/Store/tagsStore';
import { getControlsReports } from '@/api/controls/controls';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { generateKey, getQueryStringAsObject, findPropsByIds, hasCommonElements } from '@/utils/Utils';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { generateFiltersQueryString } from '@/api/httpUtils';
import { RismaStatesToFilterProps, RismaControlsUtils } from '@/utils/RismaControlsUtils';
import { compareStrings } from '@/utils/compare';
import { TableCellsTypes } from '@/constants/TableCellsTypes';
import { SortDirection } from '@/constants/SortDirection';
export default {
  name: 'ControlFrameworkReport',
  introduction: 'Control framework report',
  description: 'This is Page component for /controls2/reports/framework',
  token: '<control-framework-report />',
  components: {
    RismaTitle: RismaTitle,
    RismaTable: RismaTable,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  emits: ['dismissModal'],
  data: function data() {
    return {
      tableGeneratedKey: generateKey(),
      stateKey: 'controls_framework_report',
      isLoading: false,
      reportData: [],
      total: null,
      queryAsObject: {},
      lastUsedQueryParams: {},
      translate: {
        frameworkReport: this.$trans('Framework report'),
        id: this.$trans('ID'),
        title: this.$trans('Title'),
        motherControl: this.$trans('Mother Control'),
        tags: this.$trans('Tags'),
        yes: this.$trans('Yes'),
        no: this.$trans('No')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useFrameworksStore, ['rootFrameworks', 'descendantsByRootFramework'])), mapState(useTagsStore, ['tags'])), {}, {
    filterProps: function filterProps() {
      var result = _objectSpread({}, RismaStatesToFilterProps);
      delete result[RismaControlsUtils.states.STATE_PENDING];
      return generateFiltersQueryString({
        excludeStates: Object.values(result)
      });
    }
  }),
  mounted: function mounted() {
    this.getControlsReport();
  },
  methods: {
    getControlsReport: function getControlsReport() {
      var _this = this;
      this.isLoading = true;
      return getControlsReports('?' + this.filterProps).then(function (_ref) {
        var count = _ref.count,
          list = _ref.list;
        _this.total = count;
        _this.reportData = _this.prepareRismaTableReportData(list);
      }).finally(function () {
        return _this.isLoading = false;
      });
    },
    getControlFrameworksBE: function getControlFrameworksBE(params) {
      if (!params.includes('sortField')) params += '&sortField=cpath&sortOrder=ASC';
      var queryAsObject = getQueryStringAsObject(params);
      this.queryAsObject = queryAsObject;
      return Promise.resolve({
        headers: this.getRismaTableHeadersData(),
        options: {
          count: this.total,
          limit: +queryAsObject.limit,
          offset: +queryAsObject.start
        },
        data: this.getRismaTableReportData(queryAsObject)
      });
    },
    getRismaTableReportData: function getRismaTableReportData(queryAsObject) {
      var start = +queryAsObject.start;
      var limit = +queryAsObject.limit;
      if (this.lastUsedQueryParams.sortField !== queryAsObject.sortField || this.lastUsedQueryParams.sortOrder !== queryAsObject.sortOrder) {
        var sortField = queryAsObject.sortField || 'title';
        this.reportData.sort(function (a, b) {
          return compareStrings(a[sortField].content, b[sortField].content);
        });
        if (queryAsObject.sortOrder === SortDirection.DESC) {
          this.reportData.reverse();
        }
        this.lastUsedQueryParams = queryAsObject;
      }
      if (!start && !limit) return this.reportData;
      return this.reportData.slice(start, start + limit);
    },
    prepareRismaTableReportData: function prepareRismaTableReportData(controls) {
      var _this2 = this;
      var result = [];
      controls.forEach(function (control) {
        var motherControl = control.mothercontrol || null;
        var preparedItem = {
          cpath: {
            type: TableCellsTypes.TEXT,
            content: control.cpath
          },
          title: {
            type: TableCellsTypes.PREVIEW_MODAL,
            content: control.title,
            activity: getFieldsFromObjectForPreview(control)
          },
          motherControlTitle: {
            type: motherControl ? TableCellsTypes.PREVIEW_MODAL : TableCellsTypes.TITLE,
            content: (motherControl === null || motherControl === void 0 ? void 0 : motherControl.displayHeadline) || (motherControl === null || motherControl === void 0 ? void 0 : motherControl.title) || '',
            activity: motherControl && getFieldsFromObjectForPreview(motherControl)
          },
          tags: {
            type: TableCellsTypes.TEXT,
            content: findPropsByIds(control.tagIds, _this2.tags, 'tag')
          }
        };
        _this2.rootFrameworks.forEach(function (framework) {
          var descendants = _this2.descendantsByRootFramework[framework.id];
          var presentForControl = hasCommonElements(control.solutionIds, [framework.id].concat(_toConsumableArray(descendants)));
          preparedItem['framework_' + framework.id] = _this2.prepareFrameworkDataItem(presentForControl);
        });
        result.push(preparedItem);
      });
      return result;
    },
    getRismaTableHeadersData: function getRismaTableHeadersData() {
      var _this3 = this;
      var result = {
        cpath: this.prepareHeaderItem(this.translate.id, 'cpath', TableCellsTypes.TEXT),
        title: this.prepareHeaderItem(this.translate.title, 'title', TableCellsTypes.PREVIEW_MODAL, {
          styles: {
            'min-width': '250px'
          }
        }),
        motherControlTitle: this.prepareHeaderItem(this.translate.motherControl, 'motherControlTitle', TableCellsTypes.PREVIEW_MODAL, {
          styles: {
            'min-width': '250px'
          }
        }),
        tags: this.prepareHeaderItem(this.translate.tags, 'tags', TableCellsTypes.TEXT)
      };
      this.rootFrameworks.forEach(function (framework) {
        result['framework_' + framework.id] = _this3.prepareFrameworkHeader(framework);
      });
      return result;
    },
    prepareFrameworkDataItem: function prepareFrameworkDataItem() {
      var withCheckMark = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return {
        type: TableCellsTypes.FEATHER_ICON,
        name: withCheckMark ? 'check-circle' : '',
        content: withCheckMark ? 'X' : '',
        class: 'text-green-150 stroke-3 w-full'
      };
    },
    prepareHeaderItem: function prepareHeaderItem(label, fieldName, type) {
      var props = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return _objectSpread({
        label: label,
        fieldName: fieldName,
        fieldType: type,
        sortable: true,
        sorted: this.queryAsObject.sortField === fieldName,
        sortDirection: this.queryAsObject.sortField === fieldName ? this.queryAsObject.sortOrder : 'ASC',
        static: false,
        sticky: false,
        filters: [],
        filterName: fieldName,
        filterApi: null,
        filterFreeText: null,
        classes: [],
        styles: {}
      }, props);
    },
    prepareFrameworkHeader: function prepareFrameworkHeader(framework) {
      var key = 'framework_' + framework.id;
      return this.prepareHeaderItem(framework.title, key, TableCellsTypes.FEATHER_ICON);
    }
  }
};