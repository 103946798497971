import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import { clickOutside } from '@/utils/directives/clickOutside';
import { getRegionsOptions } from '@/components/Organisms/AiAssistant/utils';
export default {
  name: 'ChatEllipsisMenu',
  components: {
    FeatherIcon: FeatherIcon,
    CollapsiblePanel: CollapsiblePanel
  },
  introduction: 'Toggler with menu items.',
  description: 'Flexible toggle menu component',
  directives: {
    clickOutside: clickOutside
  },
  props: {
    region: {
      type: String,
      required: true
    }
  },
  emits: ['clear:history', 'end:history', 'change:region'],
  data: function data() {
    return {
      isMenuVisible: false,
      regionsOptions: getRegionsOptions(),
      translate: {
        clearHistory: this.$trans('Clear chat'),
        endHistory: this.$trans('Close AI Partner'),
        changeRegion: this.$trans('Change region')
      },
      activeRegionCSS: 'bg-rm-active-blue--10 text-rm-active-blue glob-l2-sb'
    };
  },
  methods: {
    toggleMenu: function toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    closeMenu: function closeMenu() {
      this.isMenuVisible = false;
    },
    changeRegion: function changeRegion(region) {
      if (region !== this.region) {
        this.$emit('change:region', region);
        this.closeMenu();
      }
    },
    emitEvent: function emitEvent(eventName) {
      this.$emit(eventName);
      this.closeMenu();
    }
  }
};