import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "px-5 pt-3 pb-6 border-2 border-gray-350 rounded-md"
};
var _hoisted_2 = {
  class: "flex justify-between mb-3 items-center"
};
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_create_company = _resolveComponent("create-company");
  var _component_company_wizard_wrapper = _resolveComponent("company-wizard-wrapper");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", {
    key: "bankruptcy-".concat($props.isBankruptcy),
    class: "w-full"
  }, [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: $options.tableHeader
  }, null, 8 /* PROPS */, ["title"]), !$props.isBankruptcy && $options.isSuperUser ? (_openBlock(), _createElementBlock("div", _hoisted_3, [!$options.companyWizardEnabled ? (_openBlock(), _createBlock(_component_create_company, {
    key: 0,
    onShowNotification: $options.showNotification,
    onCreateCompany: $options.createCompany
  }, null, 8 /* PROPS */, ["onShowNotification", "onCreateCompany"])) : $options.companyWizardEnabled ? (_openBlock(), _createBlock(_component_company_wizard_wrapper, {
    key: 1,
    onRefresh: $options.refreshCompanyTable
  }, null, 8 /* PROPS */, ["onRefresh"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $options.tableHeader,
    "state-key": $options.companiesStateKey,
    "show-search-field": true,
    api: $options.getCompaniesBE,
    onDelete: $options.handleDeleteCompany,
    onDismissModal: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('updateBankruptcyCount');
    })
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api", "onDelete"]))])])]);
}