import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "flex mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [$data.isError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorMessage)), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (column) {
    return _openBlock(), _createBlock(_component_risma_title, {
      key: column,
      title: column,
      type: "medium",
      class: "w-7/100 first:w-1/5 mr-1 last:mr-0"
    }, null, 8 /* PROPS */, ["title"]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_input_field, {
    modelValue: $data.group.objectId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.group.objectId = $event;
    }),
    placeholder: $data.translate.objectId,
    class: "w-1/5 mr-1",
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.group.name,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.group.name = $event;
    }),
    placeholder: $data.translate.name,
    type: "text",
    class: _normalizeClass($data.cellClasses)
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "class"]), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelAdmin,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.group.levelAdmin = $event;
    }),
    options: $data.adminLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelControls,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.group.levelControls = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelExecution,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.group.levelExecution = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelIncidents,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.group.levelIncidents = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelRisk,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.group.levelRisk = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelContracts,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.group.levelContracts = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelCompany,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.group.levelCompany = $event;
    }),
    options: $data.accessLevelsCompanies
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelInformationAssets,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.group.levelInformationAssets = $event;
    }),
    options: $data.accessLevelsBase
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass($data.cellClasses)
  }, [_createVNode(_component_single_select, {
    modelValue: $data.group.levelProcessLibrary,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return $data.group.levelProcessLibrary = $event;
    }),
    options: $data.accessLevels
  }, null, 8 /* PROPS */, ["modelValue", "options"])], 2 /* CLASS */), _createVNode(_component_checkboxes, {
    "checkbox-size": 20,
    options: !!$data.group.lockedFields,
    "center-checkbox": true,
    class: "w-7/100",
    onUpdated: _cache[11] || (_cache[11] = function ($event) {
      return $data.group.lockedFields = $event.isChecked;
    })
  }, null, 8 /* PROPS */, ["options"])]), _createVNode(_component_risma_button, {
    text: $data.translate.create,
    type: "save",
    class: "ml-auto mt-2",
    onClick: $options.create
  }, null, 8 /* PROPS */, ["text", "onClick"])]);
}