import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = ["src"];
var _hoisted_2 = {
  class: "pl-2 text-sm font-medium leading-6 text-rGrey-800"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.autoSaveClass),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('saveStatusClicked');
    })
  }, [$props.saveStatus === $data.SaveStatuses.SAVING ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    width: "16",
    height: "16",
    icon: "loader",
    class: "stroke-2"
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: $options.iconSrc,
    width: 16,
    height: 16
  }, null, 8 /* PROPS */, _hoisted_1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$trans($props.saveStatus)), 1 /* TEXT */)], 2 /* CLASS */);
}