import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_panel_settings = _resolveComponent("control-panel-settings");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_control_panel_settings, {
    "settings-groups": $options.settingsGroups,
    class: "mb-10"
  }, null, 8 /* PROPS */, ["settings-groups"])]);
}