function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaLabel from '@/components/Atoms/Titles/RismaLabel';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
export default {
  name: 'ScoringOtherParameters',
  token: '<scoring-other-parameters />',
  description: 'This component is used on the Risk(ERM) Period Description page',
  components: {
    InputField: InputField,
    RismaLabel: RismaLabel,
    RismaTitle: RismaTitle,
    RadioButtons: RadioButtons
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    },
    label: {
      type: String,
      require: true,
      default: ''
    },
    labels: {
      type: Object,
      require: false,
      default: function _default() {}
    },
    inUse: {
      type: Boolean,
      require: true,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['change'],
  data: function data() {
    return {
      selectedOption: this.inUse ? 1 : 0,
      translate: {
        doYouWantToUseOtherParameters: this.$trans('Do you want to use other parameters?'),
        no: this.$trans('No'),
        yes: this.$trans('Yes')
      }
    };
  },
  computed: {
    options: function options() {
      return [{
        value: 0,
        label: this.translate.no
      }, {
        value: 1,
        label: this.translate.yes
      }];
    }
  },
  methods: {
    changeInUsed: function changeInUsed(value) {
      this.$emit('change', {
        key: 'inUse',
        value: !!value
      });
    },
    changeLabel: function changeLabel(value) {
      this.$emit('change', {
        key: 'label',
        value: value
      });
    },
    changeLabels: function changeLabels(value, key) {
      var labels = _objectSpread(_objectSpread({}, this.labels), {}, _defineProperty({}, key, value));
      this.$emit('change', {
        key: 'labels',
        value: labels
      });
    }
  }
};