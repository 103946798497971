import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  key: 0,
  class: "flex justify-between items-center pt-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.addQuestionnaire,
    "button-ok-text": $data.translate.add,
    "button-dismiss-text": $data.translate.close,
    onDismiss: $options.toggleModal,
    onAccept: $options.addQuestionnaire
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_single_select, {
        modelValue: $data.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.value = $event;
        }),
        options: $options.formattedQuestionnaireOptions,
        placeholder: $data.translate.choose
      }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onDismiss", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.questionnaireVersion,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), !$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "plus",
    height: "25",
    width: "25",
    class: "stroke-2 cursor-pointer",
    onClick: $options.toggleModal
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selectedQuestionnaires, function (questionnaire) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: questionnaire.questionnaireId
    }, [$options.availableQuestionnairesMap[questionnaire.questionnaireId] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString($options.availableQuestionnairesMap[questionnaire.questionnaireId].title), 1 /* TEXT */), !$props.disabled ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "flex-shrink-0 ml-1 pr-1 text-red-50 stroke-2 cursor-pointer",
      onClick: function onClick($event) {
        return $options.showRemoveQuestionnaireAlert(questionnaire.questionnaireId);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))]);
}