function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { TableCellsTypes } from '@/constants/TableCellsTypes';
import { SortDirection } from '@/constants/SortDirection';
import { compareStrings, compareDates } from '@/utils/compare';
import { formatToUtcDateTime } from '@/utils/date';
var headers = {
  text: {
    label: 'Text example',
    fieldName: 'text',
    fieldType: TableCellsTypes.TEXT,
    sortable: true,
    sorted: true,
    sortDirection: SortDirection.ASC,
    filters: []
  },
  name: {
    label: 'Title example',
    fieldName: 'name',
    fieldType: TableCellsTypes.TITLE,
    sortable: true,
    sorted: false,
    filters: [],
    sticky: true
  },
  deadline: {
    label: 'Deadline example',
    fieldName: 'deadline',
    fieldType: TableCellsTypes.DEADLINE_DATE,
    sortable: true,
    sorted: false,
    filterName: 'deadline',
    datesFilter: {
      before: '',
      after: ''
    },
    filters: []
  },
  date: {
    label: 'Date example',
    fieldName: 'date',
    fieldType: TableCellsTypes.DATE,
    sortable: true,
    sorted: false,
    filterName: 'date',
    datesFilter: {
      before: '',
      after: ''
    },
    filters: []
  },
  traffic: {
    label: 'Traffic light example',
    fieldName: 'traffic',
    fieldType: TableCellsTypes.TRAFFIC_LIGHT,
    sortable: true,
    sorted: false,
    filterName: 'traffic',
    filters: [{
      label: 'Red',
      value: '1',
      active: false
    }, {
      label: 'Yellow',
      value: '2',
      active: false
    }, {
      label: 'Green',
      value: '3',
      active: false
    }]
  },
  traffic2: {
    label: 'Traffic light example ( with sortable = false )',
    fieldName: 'traffic',
    fieldType: TableCellsTypes.TRAFFIC_LIGHT,
    sortable: false,
    sorted: false,
    filterName: 'traffic2',
    filters: [{
      label: 'Red',
      value: '1',
      active: false
    }, {
      label: 'Yellow',
      value: '2',
      active: false
    }, {
      label: 'Green',
      value: '3',
      active: false
    }]
  },
  document: {
    label: 'Document example',
    fieldName: 'document',
    fieldType: TableCellsTypes.DOCUMENT,
    sortable: false,
    sorted: false,
    filters: []
  },
  html: {
    label: 'Html example',
    fieldName: 'html',
    fieldType: TableCellsTypes.HTML,
    sortable: true,
    sorted: false,
    filters: []
  },
  links: {
    label: 'Links example',
    fieldName: 'links',
    fieldType: TableCellsTypes.LINKS,
    sortable: true,
    sorted: false,
    filterName: 'links',
    filters: [{
      label: 'Initiative 1',
      value: '43',
      active: false
    }, {
      label: 'Initiative 2',
      value: '89',
      active: false
    }]
  },
  attachments: {
    label: 'Attachments example',
    fieldName: 'attachments',
    fieldType: TableCellsTypes.ATTACHMENTS,
    sortable: true,
    sorted: false,
    filters: []
  },
  list: {
    label: 'List example',
    fieldName: 'list',
    fieldType: TableCellsTypes.LIST,
    sortable: true,
    sorted: false,
    filterName: 'list',
    filters: [{
      label: 'list item 1',
      value: 'list item 1',
      active: false
    }, {
      label: 'list item 2',
      value: 'list item 2',
      active: false
    }, {
      label: 'list item 3',
      value: 'list item 3',
      active: false
    }]
  },
  bool: {
    label: 'Bool example',
    fieldName: 'bool',
    fieldType: TableCellsTypes.BOOL,
    sortable: false,
    sorted: false,
    filters: []
  },
  deleteButton: {
    label: 'Delete example',
    fieldName: 'deleteButton',
    fieldType: TableCellsTypes.DELETE_BUTTON,
    sortable: false,
    sorted: false,
    filters: []
  },
  selected: {
    label: 'Starred',
    fieldName: 'selected',
    fieldType: TableCellsTypes.STAR,
    sortable: true,
    sorted: false,
    filters: []
  },
  svgIcon: {
    label: 'Svg icon',
    fieldName: 'svgIcon',
    fieldType: TableCellsTypes.ICON,
    sortable: false,
    sorted: false,
    filters: []
  },
  featherIcon: {
    label: 'Feather icon',
    fieldName: 'featherIcon',
    fieldType: TableCellsTypes.ICON,
    sortable: false,
    sorted: false,
    filters: []
  }
};
export var getData = function getData(query) {
  var params = query.endsWith('&') ? parseQuery(query.slice(0, -1)) : parseQuery(query);
  var itemsNumber = params.limit === 'all' ? 20 : params.limit || 10;
  var data = [];
  var localHeaders = JSON.parse(JSON.stringify(headers));
  for (var i = 0; i < itemsNumber; i++) {
    data.push(generateItem(i));
  }
  if (params.sortField && params.sortOrder) {
    var prop = params.sortField;
    var reversed = params.sortOrder === SortDirection.ASC;
    switch (prop) {
      case 'deadline':
      case 'date':
        {
          data.sort(function (a, b) {
            return compareDates(a[prop].content, b[prop].content, reversed);
          });
          break;
        }
      case 'traffic2':
      case 'traffic':
        {
          data.sort(function (a, b) {
            return (a[prop].content - b[prop].content) * (reversed ? 1 : -1);
          });
          break;
        }
      default:
        {
          data.sort(function (a, b) {
            return compareStrings(a[prop].content, b[prop].content, reversed);
          });
        }
    }
    localHeaders.text.sorted = false;
    localHeaders[prop].sorted = true;
    localHeaders[prop].sortDirection = params.sortOrder;
  }
  if (params.filters) {
    var _loop = function _loop(filterKey) {
      var elements = params.filters[filterKey];
      elements.forEach(function (filterValue) {
        localHeaders[filterKey].filters = localHeaders[filterKey].filters.map(function (initialFilter) {
          if (initialFilter.value == filterValue) {
            initialFilter.active = true;
          }
          return initialFilter;
        });
      });
    };
    for (var filterKey in params.filters) {
      _loop(filterKey);
    }
  }
  return {
    data: data,
    headers: localHeaders,
    options: {
      count: 300,
      limit: itemsNumber,
      offset: params.start
    }
  };
};
var generateItem = function generateItem(idx) {
  var generatedObj = {
    text: {
      type: TableCellsTypes.TEXT,
      content: 'Text ' + idx
    },
    name: {
      type: TableCellsTypes.TITLE,
      content: 'Name' + idx
    },
    deadline: {
      type: TableCellsTypes.DEADLINE_DATE,
      content: randomDate(),
      color: randomColor()
    },
    date: {
      type: TableCellsTypes.DATE,
      content: randomDate()
    },
    traffic: {
      type: TableCellsTypes.TRAFFIC_LIGHT,
      content: idx % 3 + 1
    },
    traffic2: {
      type: TableCellsTypes.TRAFFIC_LIGHT,
      content: idx % 3 + 1
    },
    document: {
      type: TableCellsTypes.DOCUMENT,
      docType: idx % 4
    },
    html: {
      type: TableCellsTypes.HTML,
      content: '<b><i>Hello world</i></b> ' + idx
    },
    links: {
      type: TableCellsTypes.LINKS,
      content: [{
        id: 43,
        objType: 'execution-initiative',
        title: 'Initiative 1',
        url: '/actions/i/-/1-initiativ/43'
      }, {
        id: 89,
        objType: 'execution-initiative',
        title: 'Initiative 2',
        url: '/actions/i/-/2-3-processor-initiative/89'
      }]
    },
    attachments: {
      type: TableCellsTypes.ATTACHMENTS,
      content: [{
        title: 'Google',
        url: 'https://google.com'
      }, {
        title: 'Youtube',
        url: 'https://www.youtube.com/watch?v=EGLxVI84evM&ab_channel=JAZZ%26BLUES'
      }, {
        title: 'Test',
        url: 'https://test.com'
      }]
    },
    list: {
      type: TableCellsTypes.LIST,
      content: ['list item 1', 'list item 2', 'list item 3']
    },
    deleteButton: {
      type: TableCellsTypes.DELETE_BUTTON,
      deletable: true,
      id: 1 + idx
    },
    bool: {
      type: TableCellsTypes.BOOL,
      content: idx % 2 === 0
    },
    selected: {
      type: TableCellsTypes.STAR,
      content: false,
      id: 1 + idx
    },
    svgIcon: _objectSpread({
      type: TableCellsTypes.ICON,
      id: 1 + idx,
      content: "svg icon ".concat(1 + idx)
    }, svgIcons[Math.abs(idx % 3)]),
    featherIcon: _objectSpread({
      type: TableCellsTypes.ICON,
      id: 1 + idx,
      content: "feather icon ".concat(1 + idx)
    }, featherIcons[Math.abs(idx % 4)])
  };
  return generatedObj;
};
var randomDate = function randomDate() {
  var start = new Date(2012, 0, 1);
  var end = new Date();
  return formatToUtcDateTime(new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())));
};
var randomColor = function randomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};
var parseQuery = function parseQuery(query) {
  var result = JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  result.filters = {};
  var queryParams = query.split('&');
  queryParams.forEach(function (queryParam) {
    var match = _toConsumableArray(queryParam.matchAll(/filters\[(\w+)\]\[\]=(.+)/g))[0];
    if (!match) return;
    var filterKey = match[1];
    var filterValue = match[2];
    if (!result.filters[filterKey]) {
      result.filters[filterKey] = [];
    }
    result.filters[filterKey].push(filterValue);
  });
  return result;
};
var svgIcons = [{
  svgName: 'icon_user',
  color: '#012454'
}, {
  svgName: 'icon_user',
  color: '#fff',
  backgroundColor: '#012454'
}, {
  svgName: 'icon_noAccess',
  color: '#e53e3e'
}];
var featherIcons = [{
  name: 'check-circle',
  class: 'text-green-150'
}, {
  name: 'x-circle',
  class: 'text-red-50'
}, {
  name: 'bell',
  class: 'text-blue-510'
}, {
  name: 'bell',
  class: 'text-purple-450 fill-current'
}];