import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_vertical_menu, {
    class: "vertical-menu",
    items: $options.menuItems
  }, null, 8 /* PROPS */, ["items"]), _createVNode(_component_router_view, {
    class: "router-view overflow-auto w-full"
  }, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        "activity-types": _ctx.activityTypes
      }, null, 8 /* PROPS */, ["activity-types"]))];
    }),
    _: 1 /* STABLE */
  })])]);
}