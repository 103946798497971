import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "flex items-center mb-3"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "flex items-end mb-3"
};
var _hoisted_4 = {
  class: "mr-3"
};
var _hoisted_5 = {
  class: "flex-grow"
};
var _hoisted_6 = {
  class: "mb-2"
};
var _hoisted_7 = ["value"];
var _hoisted_8 = {
  key: 2
};
var _hoisted_9 = {
  class: "grid grid-cols-3 gap-y-3 gap-4 mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_switch = _resolveComponent("risma-switch");
  var _component_notification = _resolveComponent("notification");
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [!$options.isManualOnly ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", null, _toDisplayString($data.translate.manually), 1 /* TEXT */), _createVNode(_component_risma_switch, {
    "model-value": $props.selectedOptions[$data.CompanyWizardFields.IS_VAT_CREATION_MODE],
    options: $options.switchOptions,
    "is-labels-show": true,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.onFieldChange(!!$event, $data.CompanyWizardFields.IS_VAT_CREATION_MODE);
    })
  }, null, 8 /* PROPS */, ["model-value", "options"]), _createElementVNode("p", null, _toDisplayString($data.translate.viaVatNumber), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.selectedOptions[$data.CompanyWizardFields.IS_VAT_CREATION_MODE] && !$options.isManualOnly ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.createCompanyErrorMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.createCompanyErrorMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    modelValue: $data.countryCode,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = function ($event) {
      return $data.countryCode = $event;
    }), $options.resetSearchResults],
    title: $data.translate.countryCode,
    options: $options.countryCodeOptions
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_input_field, {
    "model-value": $props.selectedOptions[$data.CompanyWizardFields.SEARCH_FIELD_VALUE],
    placeholder: $data.translate.pressEnterToStartSearch,
    "focus-on-mount": true,
    title: $options.searchFieldLabel,
    type: "text",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $options.onFieldChange($event, $data.CompanyWizardFields.SEARCH_FIELD_VALUE);
    }),
    onOnEnter: $options.searchCompany
  }, null, 8 /* PROPS */, ["model-value", "placeholder", "title", "onOnEnter"])]), _createVNode(_component_risma_button, {
    text: $data.translate.search,
    disabled: $data.isLoading,
    type: "save",
    class: "ml-4",
    onClick: $options.searchCompany
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : _createCommentVNode("v-if", true), $options.companyDataset.length ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [_createElementVNode("p", _hoisted_6, _toDisplayString($data.translate.chooseTheCompanyYouWishToCreate), 1 /* TEXT */), _createVNode(_component_static_table, {
    "dataset-table": $options.companyDataset,
    columns: $options.companyColumns
  }, {
    selected: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.selectedCompanyIndex = $event;
        }),
        value: slotData,
        type: "radio",
        class: "cursor-pointer"
      }, null, 8 /* PROPS */, _hoisted_7), [[_vModelRadio, $data.selectedCompanyIndex]])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_input_field, {
    modelValue: $data.manualData.name,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.manualData.name = $event;
    }),
    title: $data.translate.title,
    placeholder: $data.translate.title,
    "error-help-text": $props.errorMessage[$data.errorFields.TITLE],
    invalid: !!$props.errorMessage[$data.errorFields.TITLE],
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "error-help-text", "invalid"]), _createVNode(_component_input_field, {
    modelValue: $data.manualData.vatNo,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.manualData.vatNo = $event;
    }),
    title: $data.translate.vatNumber,
    placeholder: $data.translate.vatNumber,
    "is-static-height": true,
    "error-help-text": $props.errorMessage[$data.errorFields.VATNO],
    invalid: !!$props.errorMessage[$data.errorFields.VATNO],
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "error-help-text", "invalid"]), _createVNode(_component_single_select, {
    "model-value": $data.manualData.countryCode,
    "with-search": true,
    "with-reset": true,
    title: $data.translate.countryCode,
    options: $props.data.countries,
    placeholder: $data.translate.countryCode,
    "required-text": $options.countryCodeRequiredText,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.manualData.countryCode = $event;
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "required-text"]), _createVNode(_component_input_field, {
    modelValue: $data.manualData.address,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.manualData.address = $event;
    }),
    title: $data.translate.address,
    placeholder: $data.translate.address,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.manualData.zipCode,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.manualData.zipCode = $event;
    }),
    title: $data.translate.zipCode,
    placeholder: $data.translate.zipCode,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.manualData.city,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.manualData.city = $event;
    }),
    title: $data.translate.city,
    placeholder: $data.translate.city,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.manualData.phone,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
      return $data.manualData.phone = $event;
    }),
    title: $data.translate.phone,
    placeholder: $data.translate.phone,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_input_field, {
    modelValue: $data.manualData.email,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
      return $data.manualData.email = $event;
    }),
    title: $data.translate.email,
    "error-help-text": $props.errorMessage[$data.errorFields.EMAIL],
    invalid: !!$props.errorMessage[$data.errorFields.EMAIL],
    placeholder: $data.translate.email,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "title", "error-help-text", "invalid", "placeholder"])])]))]);
}