// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ghost[data-v-3921097c] {
  background: rgba(74, 170, 187, 0.5);
  border: 3px dashed rgba(86, 185, 204, 0.5);
}
.ghost .drag-icon[data-v-3921097c] {
  top: 18px;
}
.ghost[data-v-3921097c]:active {
  cursor: -o-grabbing !important;
  cursor: -ms-grabbing !important;
  cursor: grabbing !important;
}
[data-v-3921097c]  .drag-icon {
  cursor: -o-grab !important;
  cursor: -ms-grab !important;
  cursor: grab !important;
}
[data-v-3921097c]  .drag-icon:active {
  cursor: -o-grabbing !important;
  cursor: -ms-grabbing !important;
  cursor: grabbing !important;
}
.column-switcher[data-v-3921097c] {
  align-self: center !important;
  padding-right: 10px;
  padding: 6px 10px 5px 0;
  border-radius: 4px;
  border: 1px solid #347480;
  margin-right: 30px;
}
[data-v-3921097c]  .util-menu-toggle .column-switcher-label {
  padding-left: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
