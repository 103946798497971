function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import { generateComputed } from '@/utils/base/generateComputed';
export default {
  name: 'ProcessNodePage',
  components: {
    TinyMceBox: TinyMceBox,
    ActivityCustomFields: ActivityCustomFields
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'Process node object'
    },
    processTreeId: {
      required: true,
      type: [String, Number, Boolean],
      note: 'processTreeId from route'
    },
    processNodeId: {
      required: true,
      type: [String, Number, Boolean],
      note: 'processNoteId from route'
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Readonly (via Awareness email)'
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      error: '',
      translate: {
        description: this.$trans('Description')
      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['processNodeActivityTypes'])), generateComputed(['description'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.processNodeActivityTypes.find(function (item) {
        return +item.id === +_this.data.activityTypeId;
      });
    }
  })
};