import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "max-w-300px py-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_quick_edit_field = _resolveComponent("quick-edit-field");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_quick_edit_field, {
    label: "Lorem ipsum dolor sit amet",
    "pre-text": $data.text,
    onUpdate: $options.handleUpdate
  }, null, 8 /* PROPS */, ["pre-text", "onUpdate"]), _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Disabled component", -1 /* HOISTED */)), _createVNode(_component_quick_edit_field, {
    label: "Lorem ipsum dolor sit amet",
    "pre-text": $data.text,
    disabled: true,
    onUpdate: $options.handleUpdate
  }, null, 8 /* PROPS */, ["pre-text", "onUpdate"])]);
}