function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Timezones } from '@/constants/Timezones';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
export default {
  name: 'Timezone',
  introduction: 'Timezone clickable component',
  description: 'Output clickable element (value) with a nested popup',
  token: '<timezone :model-value="value" @onSelect="fn"></timezone>',
  components: {
    SingleSelect: SingleSelect
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      note: 'Choosed timezone string value'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['onSelect'],
  data: function data() {
    return {
      options: [],
      selected: {}
    };
  },
  created: function created() {
    this.options = this.prepareTimezones(Timezones);
    this.selected = this.findSelected(this.modelValue);
  },
  methods: {
    findSelected: function findSelected(value) {
      var result = '';
      if (value) {
        var option = this.options.find(function (option) {
          return value === option.value;
        });
        if (option) {
          result = option;
        }
      }
      return result;
    },
    prepareTimezones: function prepareTimezones(timezones) {
      var result = [];
      if (timezones && timezones.length) {
        timezones.map(function (item) {
          result.push(_objectSpread({
            label: item.text
          }, item));
        });
      }
      return result;
    },
    onSelect: function onSelect(e) {
      this.selected = this.findSelected(e);
      this.$emit('onSelect', e);
    }
  }
};