import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  GapTypesList: function GapTypesList() {
    return {
      gapTypeOptions: i18n.t('Gap type options'),
      addGapTypeOption: i18n.t('Add gap type option'),
      areYouSure: i18n.t('Are you sure?'),
      saveGapTypes: i18n.t('Save gap types'),
      changesSaved: i18n.t('Changes saved'),
      somethingWentWrong: i18n.t('Something went wrong while updating'),
      cancel: i18n.t('Cancel'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDeleteThisGapType: i18n.t('Are you sure you want to delete this gap type? It will be removed as an option, even if it has been selected')
    };
  },
  AdminControlPanel: function AdminControlPanel() {
    return {
      myActions: i18n.t('My actions'),
      myControls: i18n.t('My controls'),
      myRisks: i18n.t('My risks'),
      compliance: i18n.t('Compliance'),
      incidents: i18n.t('Incidents')
    };
  },
  AdminControls: function AdminControls() {
    return {
      controlsAdministration: i18n.t('Controls administration'),
      controlRiskType: i18n.t('Control risk type'),
      account: i18n.t('Account'),
      process: i18n.t('Process'),
      risk: i18n.t('Risk'),
      cannotDeleteRiskTypesThatAreInUse: i18n.t('Cannot delete risk types that are in use'),
      itemWasSuccessfullyDeleted: i18n.t('item was successfully deleted'),
      wasSuccessfullyCreated: i18n.t('was successfully created'),
      wasSuccessfullyUpdated: i18n.t('was successfully updated')
    };
  },
  AdminDocumentTypes: function AdminDocumentTypes() {
    return {
      documentTypesAdministration: i18n.t('Document types administration'),
      documentTypes: i18n.t('Document types'),
      delete: i18n.t('Delete'),
      edit: i18n.t('Edit'),
      cancel: i18n.t('Cancel'),
      areYouSure: i18n.t('Are you sure?'),
      title: i18n.t('Title'),
      documentTypeCreated: i18n.t('Document type created'),
      documentTypeUpdated: i18n.t('Document type updated'),
      documentTypeDeleted: i18n.t('Document type deleted'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      deleteDocumentType: i18n.t('Delete document type')
    };
  },
  AdminLoginsReport: function AdminLoginsReport() {
    return {
      filterByUsername: i18n.t('Filter by Username'),
      chooseUser: i18n.t('Choose user'),
      filterByDateRange: i18n.t('Filter by Date Range'),
      createReport: i18n.t('Create report'),
      nothingToShow: i18n.t('Nothing to show'),
      enterYourSearchCriteriaAboveToViewSearchRe: i18n.t('Enter your search criteria above to view search results'),
      timestamp: i18n.t('Timestamp'),
      username: i18n.t('Username'),
      groups: i18n.t('Groups')
    };
  },
  AdminRiskHeatMap: function AdminRiskHeatMap() {
    return {
      areYouSureYouWantToResetToDefaultColors: i18n.t('Are you sure you want to reset to default colors?'),
      areYouSure: i18n.t('Are you sure?'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      riskMatrixSettings: i18n.t('Risk matrix settings'),
      selectProject: i18n.t('Select project'),
      enableCustomHeatmapForThisProject: i18n.t('Enable custom heat-map for this project'),
      toChangeColorInRiskMatrixPleaseClickOnOne: i18n.t('To change color in risk matrix please click on one of the squares'),
      resetOptions: i18n.t('Reset options'),
      saveSettings: i18n.t('Save settings')
    };
  },
  AdminSettingsChangelog: function AdminSettingsChangelog() {
    return {
      nothingToShow: i18n.t('Nothing to show'),
      setting: i18n.t('Setting'),
      from: i18n.t('From'),
      to: i18n.t('To'),
      date: i18n.t('Date'),
      changedBy: i18n.t('Changed by')
    };
  },
  AdminSwitchActivities: function AdminSwitchActivities() {
    return {
      switchActivitiesFromOneUserToAnother: i18n.t('Switch activities from one user to another'),
      activitiesWereAssigned: i18n.t('Activities were assigned'),
      chooseModules: i18n.t('Choose modules'),
      chooseUserToUnassignActivitiesFrom: i18n.t('Choose user to unassign activities from'),
      chooseUserToAssignActivitiesTo: i18n.t('Choose user to assign activities to'),
      submit: i18n.t('Submit'),
      selectdeselectAllActivities: i18n.t('Select/deselect all activities'),
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      attachedAs: i18n.t('Attached as'),
      noRecordsMatching: i18n.t('No records matching'),
      pendingReview: i18n.t('Pending review'),
      reviewRejected: i18n.t('Review rejected'),
      dailyTeam: i18n.t('Daily team'),
      responsible: i18n.t('Responsible'),
      notifications: i18n.t('Notifications'),
      dailyTeamusers: i18n.t('Daily team - Users'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      primaryRiskOwner: i18n.t('Primary risk owner'),
      removeUser: i18n.t('Remove user'),
      userWasRemovedFromTheActivity: i18n.t('User was removed from the activity')
    };
  },
  AdminSwitchOrganisations: function AdminSwitchOrganisations() {
    return {
      switchActivitiesFromOneOrganisationToAnother: i18n.t('Switch activities from one organisation to another'),
      activitiesWereAssigned: i18n.t('Activities were assigned'),
      chooseModules: i18n.t('Choose modules'),
      chooseOrganisationToUnassignActivitiesFrom: i18n.t('Choose organisation to unassign activities from'),
      chooseOrganisationToAssignActivitiesTo: i18n.t('Choose organisation to assign activities to'),
      submit: i18n.t('Submit'),
      selectdeselectAllActivities: i18n.t('Select/deselect all activities'),
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      noRecordsMatching: i18n.t('No records matching')
    };
  },
  AdminSwitchFrameworks: function AdminSwitchFrameworks() {
    return {
      updateFrameworkIdsOnActivities: i18n.t('Update frameworks on activities'),
      activitiesWereAssigned: i18n.t('Activities were assigned'),
      chooseModules: i18n.t('Choose modules'),
      onlyShowActivitiesWithoutFrameworkIds: i18n.t('Only show activities without frameworks'),
      selectFrameworksToBeAssigned: i18n.t('Select frameworks to be assigned to activities'),
      submit: i18n.t('Submit'),
      selectdeselectAllActivities: i18n.t('Select/deselect all activities'),
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      frameworkId: i18n.t('Framework'),
      noRecordsMatching: i18n.t('No records matching'),
      frameworksWasSuccessfullyUpdated: i18n.t('Frameworks') + ' ' + i18n.t('were successfully updated')
    };
  },
  AdminUsersChangelog: function AdminUsersChangelog() {
    return {
      nothingToShow: i18n.t('Nothing to show'),
      user: i18n.t('User'),
      fieldName: i18n.t('Field name'),
      from: i18n.t('From'),
      to: i18n.t('To'),
      date: i18n.t('Date'),
      changedBy: i18n.t('Changed by'),
      unknownProject: i18n.t('Unknown project'),
      passwordWasChanged: i18n.t('Password was changed'),
      adminAccessLevel: i18n.t('Admin access level'),
      nonAdminAccessLevel: i18n.t('Non admin access level'),
      enabled: i18n.t('Enabled'),
      disabled: i18n.t('Disabled'),
      yes: i18n.t('Yes'),
      no: i18n.t('No'),
      locked: i18n.t('Locked'),
      unlocked: i18n.t('Unlocked')
    };
  },
  Companies: function Companies() {
    return {
      yourActivityHasBeenCreated: i18n.t('Your activity has been created'),
      deleteCompany: i18n.t('Delete company'),
      areYouSure: i18n.t('Are you sure?'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      deleteContact: i18n.t('Delete contact'),
      companiesAdministration: i18n.t('Companies administration'),
      search: i18n.t('Search'),
      companies: i18n.t('Companies'),
      bankruptcyList: i18n.t('Bankruptcy list'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      zipcode: i18n.t('Zipcode'),
      countryCode: i18n.t('Country code/Country'),
      vatNumber: i18n.t('VAT number')
    };
  },
  QuestionsAdmin: function QuestionsAdmin() {
    return {
      back: i18n.t('Back'),
      customQuestions: i18n.t('Custom questions'),
      questions: i18n.t('Questions'),
      changelog: i18n.t('Changelog'),
      dataFlowOverview: i18n.t('Data flow overview'),
      addQuestion: i18n.t('Add question'),
      unexpectedError: i18n.t('Unexpected error'),
      headline: i18n.t('Headline'),
      questionNumber: i18n.t('Question number'),
      question: i18n.t('Question'),
      options: i18n.t('Options'),
      hideQuestion: i18n.t('Hide question'),
      showQuestion: i18n.t('Show question'),
      editQuestion: i18n.t('Edit question'),
      thisIsUserDefinedQuestionnaire: i18n.t('This is a user defined questionnaire. You can edit question texts and hide questions in the questionnaire.'),
      thisIsOneOfRismasStandardQuestionnaires: i18n.t("This is one of RISMA's standard questionnaires. If you want to change the question texts or hide questions, please make your own copy from the questionnaires tab above."),
      noQuestionnaireIsSelected: i18n.t('No questionnaire is selected'),
      pleaseSelectAQuestionnaireToContinue: i18n.t('Please select a questionnaire to continue'),
      createQuestion: i18n.t('Create question'),
      deleteQuestion: i18n.t('Delete') + ' ' + i18n.t('Question').toLowerCase(),
      areYouSureYouWantToDeleteThisQuestion: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Question').toLowerCase(), "?")
    };
  },
  ComplianceAssetsSettings: function ComplianceAssetsSettings() {
    return {
      assets: i18n.t('Assets'),
      name: i18n.t('Name'),
      defaultQuestionnaire: i18n.t('Default questionnaire'),
      selectDefaultQuestionnaire: i18n.t('Select default questionnaire'),
      settingsAreSaved: i18n.t('Settings are saved'),
      notSet: i18n.t('Not set'),
      defaultActivityType: i18n.t('Default activity type')
    };
  },
  ComplianceRiskAssessment: function ComplianceRiskAssessment() {
    return {
      riskAssessmentOnAssets: i18n.t('Risk Assessment on assets'),
      name: i18n.t('Name'),
      enableRiskAssessment: i18n.t('Enable risk assessment'),
      risksCreatedIn: i18n.t('Risks created in'),
      noRiskProject: i18n.t('No risk project'),
      riskAssessmentOnNodes: i18n.t('Risk assessment on nodes'),
      risksAreCreatedIn: i18n.t('Risks are created in'),
      rtoAndRpoFields: i18n.t('RTO and RPO fields'),
      pleaseEnterAValue: i18n.t('Please enter a value'),
      addValue: i18n.t('Add value'),
      settingsAreSaved: i18n.t('Settings are saved'),
      enterAnOption: i18n.t('Enter an option')
    };
  },
  ErmPeriodDescription: function ErmPeriodDescription() {
    return {
      period: i18n.t('Period'),
      title: i18n.t('Title'),
      thePeriodShouldConsistOfOnlyLettersAndNumb: i18n.t('The period should consist of only letters and numbers'),
      maxLengthIs30Characters: i18n.t('Max length is 30 characters'),
      otherXaxisParameters: i18n.t('Other X-axis parameters'),
      openThisPeriodAndCloseThePrevious: i18n.t('Open this period and close the previous.'),
      back: i18n.t('Back'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDeleteThis: i18n.t('Are you sure you want to delete this') + '?',
      saveAndOpen: i18n.t('Save and open'),
      save: i18n.t('Save'),
      otherParameters: i18n.t('Other parameters'),
      masterDataSaved: i18n.t('Master data saved.')
    };
  },
  OrganisationTree: function OrganisationTree() {
    return {
      organisations: i18n.t('Organisations'),
      addNew: i18n.t('Add new'),
      sortInAlphabeticalOrder: i18n.t('Sort in alphabetical order'),
      areYouSureYouWantToDeleteThisOrganisation: i18n.t('Are you sure you want to delete this organisation?'),
      deleteOrganisation: i18n.t('Delete organisation'),
      updateOrganisation: i18n.t('Update organisation'),
      addUnderlyingOrganisation: i18n.t('Add underlying organisation'),
      thisOrganisationHaveBeenRemovedForAllActivi: i18n.t('This organisation have been removed for all activities except following'),
      theOrganisationCannotBeRemovedAutomatically: i18n.t('The organisation cannot be removed automatically.'),
      pleaseContactRismaSupportForAssistance: i18n.t('Please contact RISMA support for assistance'),
      theOrganisationWasSuccessfullyDeleted: i18n.t('The organisation was successfully deleted'),
      theOrganisationHasBeenCreated: i18n.t('The organisation has been created')
    };
  },
  QuestionnaireAdmin: function QuestionnaireAdmin() {
    return {
      questionnaires: i18n.t('Questionnaires'),
      chooseQuestionnaireType: i18n.t('Choose questionnaire type'),
      selectOne: i18n.t('Select one'),
      thereAreNoQuestionnairesOfTheSelectedType: i18n.t('There are no questionnaires of the selected type'),
      name: i18n.t('Name'),
      tabLabel: i18n.t('Tab label'),
      defaultGapSchema: i18n.t('Default gap schema'),
      questionCount: i18n.t('Question count'),
      activated: i18n.t('Activated'),
      questions: i18n.t('Questions'),
      scoring: i18n.t('Scoring'),
      copy: i18n.t('Copy'),
      delete: i18n.t('Delete'),
      edit: i18n.t('Edit'),
      dataProcessorCount: i18n.t('Data Processor count'),
      systemCount: i18n.t('System count'),
      controllersCount: i18n.t('Controllers count'),
      dpmAuditCount: i18n.t('DPM Audit count'),
      auditCount: i18n.t('Audit count'),
      processCount: i18n.t('Process count'),
      contractCount: i18n.t('Contract count'),
      theQuestionnaireCantBeDisabledErrorMessage: i18n.t('The questionnaire can’t be disabled because it is used as default questionnaire for this module. Remove it as default questionnaire to deactivate it.'),
      somethingWentWrongWhileDeleting: i18n.t('Something went wrong while deleting'),
      cancel: i18n.t('Cancel'),
      ok: i18n.t('Ok'),
      date: i18n.t('Date'),
      areYouSure: i18n.t('Are you sure?'),
      enterNewTitle: i18n.t('Enter new title'),
      enterNewTabLabel: i18n.t('Enter new tab label'),
      deleteQuestionnaire: i18n.t('Delete') + ' ' + i18n.t('Questionnaire').toLowerCase(),
      areYouSureYouWantToDeleteThisQuestionnaire: "".concat(i18n.t('Are you sure you want to delete this'), " ").concat(i18n.t('Questionnaire').toLowerCase(), "?"),
      incidentCount: i18n.t('Incident count'),
      riskCount: i18n.t('Risk count')
    };
  },
  WorkflowStatuses: function WorkflowStatuses() {
    return {
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      deleteWorkflowStatus: i18n.t('Delete workflow status'),
      delete: i18n.t('Delete'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      order: i18n.t('Order'),
      create: i18n.t('Create'),
      workflowStatusesAdministration: i18n.t('Workflow statuses administration'),
      statuses: i18n.t('Statuses'),
      titleHaveToBeUnique: i18n.t('The title must be unique'),
      created: i18n.t('created'),
      updated: i18n.t('updated'),
      deleted: i18n.t('deleted'),
      workflowStatus: i18n.t('Workflow status'),
      chooseModule: i18n.t('Choose module'),
      createStatus: i18n.t('Create status'),
      anErrorOccurred: i18n.t('An error occurred'),
      pleaseFillOutTheTitle: i18n.t('Please fill out the title'),
      workflowStatuses: i18n.t('Workflow statuses'),
      finalStatus: i18n.t('Final status')
    };
  },
  GapSchemasAdmin: function GapSchemasAdmin() {
    return {
      gapSchemas: i18n.t('Gap schemas'),
      chooseGapSchemaType: i18n.t('Choose gap schema type'),
      selectOne: i18n.t('Select one'),
      thereAreNoGapSchemasOfTheSelectedType: i18n.t('There are no gap schemas of the selected type'),
      name: i18n.t('Name'),
      gapCount: i18n.t('Gap count'),
      processCount: i18n.t('Process count'),
      activated: i18n.t('Activated'),
      copy: i18n.t('Copy'),
      delete: i18n.t('Delete'),
      edit: i18n.t('Edit'),
      ok: i18n.t('Ok'),
      cancel: i18n.t('Cancel'),
      enterNewTitle: i18n.t('Enter new title'),
      deleteGapSchema: i18n.t('Delete') + ' ' + i18n.t('Gap schema').toLowerCase(),
      areYouSureYouWantToDeleteThisGapSchema: "".concat(i18n.t('Are you sure you want to delete this gap schema'), "?"),
      gapTypes: i18n.t('Gap types'),
      chooseProject: i18n.t('Choose project'),
      gapTypesAreDeactivatedForThisProject: i18n.t('Gap types are deactivated for this project')
    };
  },
  GapSchemasAdminArticles: function GapSchemasAdminArticles() {
    return {
      complianceAdministration: i18n.t('Compliance administration'),
      back: i18n.t('Back'),
      articles: i18n.t('Articles'),
      editArticle: i18n.t('Edit Article'),
      deleteArticle: i18n.t('Delete Article'),
      article: i18n.t('Article'),
      articleTitle: i18n.t('Article Title'),
      requirement: i18n.t('Requirement'),
      domain: i18n.t('Domain'),
      options: i18n.t('Options'),
      showGap: i18n.t('Show gap'),
      hideGap: i18n.t('Hide gap'),
      areYouSureYouWantToDeleteThisArticle: i18n.t('Are you sure you want to delete this article?'),
      allAnalysesAndLinksWillBeRemoved: i18n.t('All analyses and links will be removed.'),
      thisCannotBeUndone: i18n.t('This cannot be undone.'),
      addArticle: i18n.t('Add article')
    };
  },
  LiaTiaAdmin: function LiaTiaAdmin() {
    return {
      liaAndTiaSettings: i18n.t('LIA and TIA settings'),
      selectOne: i18n.t('Select one'),
      select: i18n.t('Select'),
      noGapSchemaAvailable: i18n.t('No gap schema available'),
      newSetting: i18n.t('New setting'),
      createNewLiatiaSetting: i18n.t('Create new LIA/TIA setting'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      createNew: i18n.t('Create new'),
      title: i18n.t('Title'),
      enabled: i18n.t('Enabled'),
      chooseQuestionnaire: i18n.t('Choose questionnaire'),
      chooseQuestion: i18n.t('Choose question'),
      chooseQuestionAnswers: i18n.t('Choose question answers'),
      chooseGapSchema: i18n.t('Choose gap schema'),
      chooseGapArticle: i18n.t('Choose gap article'),
      liatiaQuestionnaire: i18n.t('LIA/TIA questionnaire'),
      pleaseSelectAProjectToContinue: i18n.t('Please select a project to continue')
    };
  },
  ProjectsAdmin: function ProjectsAdmin() {
    return {
      selectOne: i18n.t('Select one'),
      select: i18n.t('Select'),
      title: i18n.t('Title'),
      questionnaire: i18n.t('Questionnaire'),
      gapSchemaName: i18n.t('Gap schema name'),
      frameworkId: i18n.t('Framework'),
      activityType: i18n.t('Activity type'),
      enableRecommendedActivitiesForGaps: i18n.t('Enable recommended activities for gaps'),
      enableHeatMapsInGapSchema: i18n.t('Enable heat maps in gap schema'),
      enableGapTypes: i18n.t('Enable gap types'),
      riskPrGap: i18n.t('Risk pr. gap'),
      complianceProjectAdministration: i18n.t('Compliance projects'),
      settingsAreSaved: i18n.t('Settings are saved'),
      activated: i18n.t('Activated'),
      deactivated: i18n.t('Deactivated'),
      overallDescriptionTitle: i18n.t('Overall description title'),
      purposeTitle: i18n.t('Purpose title'),
      processStatusTitle: i18n.t('Process status title'),
      sorting: i18n.t('Sorting')
    };
  },
  ProjectsNodeTemplates: function ProjectsNodeTemplates() {
    return {
      templateNodes: i18n.t('Template nodes'),
      selectOne: i18n.t('Select one'),
      title: i18n.t('Title'),
      enable: i18n.t('Enable'),
      selectTemplateNode: i18n.t('Select template node'),
      forEachComplianceProjectYouCanSelect: i18n.t('For each compliance project, you can select an existing node (including subnodes) as the default template node(s). This template node serves as a starting point for creating new nodes in the project.'),
      theNodeWillContainAllRelevantData: i18n.t('The node will contain all relevant data and configurations, allowing you to efficiently create new nodes by simply selecting the template node from the compliance overview.You can add nodes by clicking the three dots on the node and selecting "Add from template"'),
      templateNodeUpdated: i18n.t('Template node updated'),
      somethingWentWrong: i18n.t('Something went wrong while updating')
    };
  },
  QuestionnaireScoringAssessments: function QuestionnaireScoringAssessments() {
    return {
      scoringAssessments: i18n.t('Scoring assessments'),
      noItems: i18n.t('No items'),
      operator: i18n.t('Operator'),
      score: i18n.t('Score'),
      assessmentText: i18n.t('Assessment text'),
      color: i18n.t('Color'),
      addRow: i18n.t('Add row'),
      areYouSure: i18n.t('Are you sure?'),
      unknownOperator: i18n.t('Unknown operator'),
      from: i18n.t('From'),
      deleteRow: i18n.t('Delete row'),
      areYouSureYouWantToDeleteThisRow: i18n.t('Are you sure you want to delete this row?')
    };
  },
  QuestionRules: function QuestionRules() {
    return {
      rismaLogic: i18n.t('RISMA logic'),
      chooseQuestion: i18n.t('Choose question'),
      chooseAnswer: i18n.t('Choose answer'),
      chooseGapArticle: i18n.t('Choose gap article'),
      chooseResult: i18n.t('Choose result'),
      add: i18n.t('Add'),
      saveAndApply: i18n.t('Save and apply'),
      youMustSelectBothAQuestionnaireAndAGapSch: i18n.t('You must select both a questionnaire and a gap schema'),
      saved: i18n.t('Saved'),
      validationError: i18n.t('Validation error'),
      youMustSelectAllQuestionAnswerGapArticle: i18n.t('You must select all: question, answer, gap article and result')
    };
  },
  TemplateList: function TemplateList() {
    return {
      templates: i18n.t('Templates'),
      create: i18n.t('Create'),
      thereAreNoTemplatesOfTheSelectedType: i18n.t('There are no templates of the selected type'),
      name: i18n.t('Name'),
      type: i18n.t('Type'),
      tabLabel: i18n.t('Tab label'),
      questionCount: i18n.t('Question count'),
      date: i18n.t('Date'),
      gapCount: i18n.t('Gap count')
    };
  },
  DataImport: function DataImport() {
    return {
      target: i18n.t('Target'),
      parent: i18n.t('Parent'),
      start: i18n.t('Start date'),
      completion: i18n.t('Completion'),
      commentField: i18n.t('Comment'),
      importStatus: i18n.t('Import status'),
      activityType: i18n.t('Activity type'),
      contractNo: i18n.t('Contract no.'),
      title: i18n.t('Title'),
      description: i18n.t('Description'),
      status: i18n.t('Status'),
      startDate: i18n.t('Start date'),
      endDate: i18n.t('End date'),
      contractAmount: i18n.t('Contract amount'),
      contractAmountCurrency: i18n.t('Contract amount currency'),
      renewalAmount: i18n.t('Renewal amount'),
      renewalAmountCurrency: i18n.t('Renewal amount currency'),
      renewalDeadline: i18n.t('Renewal deadline'),
      commitmentDeadline: i18n.t('Commitment deadline'),
      contractVersion: i18n.t('Contract version'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      organisations: i18n.t('Organisations'),
      companies: i18n.t('Companies'),
      contacts: i18n.t('Contacts'),
      frameworks: i18n.t('Frameworks'),
      type: i18n.t('Type'),
      uniqueId: i18n.t('Unique id'),
      trafficLight: i18n.t('Traffic light'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      countryCodeCountry: i18n.t('Country code/Country'),
      phoneNumber: i18n.t('Phone number'),
      vatNumber: i18n.t('VAT number'),
      regNumber: i18n.t('Reg. number'),
      contactName: i18n.t('Contact name'),
      email: i18n.t('Email'),
      questionnaire: i18n.t('Questionnaire'),
      purpose: i18n.t('Purpose'),
      location: i18n.t('Location'),
      tags: i18n.t('Tags'),
      accounts: i18n.t('Accounts'),
      processes: i18n.t('Processes'),
      risks: i18n.t('ControlRisks'),
      keyControl: i18n.t('Key control'),
      automaticManual: i18n.t('Automatic') + '/' + i18n.t('Manual'),
      detectivePreventive: i18n.t('Detective') + '/' + i18n.t('Preventive'),
      highLowComplexity: i18n.t('High') + '/' + i18n.t('Low complexity'),
      dataImport: i18n.t('Data import'),
      informationAssetsImport: i18n.t('Information assets import'),
      contractImport: i18n.t('Contract import'),
      invalidFileTypePleaseUploadAnyOfTheseFile: i18n.t('Invalid file type. Please upload any of these file types'),
      riskProjectName: i18n.t('Risk project'),
      riskNumber: i18n.t('Risk number'),
      primaryOwner: i18n.t('Primary owner'),
      likelihood: i18n.t('Likelihood'),
      impact: i18n.t('Impact'),
      organisation: i18n.t('Organisation'),
      riskImport: i18n.t('Risk import'),
      actionsImport: i18n.t('Actions import'),
      gapAnalysisImport: i18n.t('GAP analysis import'),
      complianceNodeImport: i18n.t('Compliance node import'),
      controlsImport: i18n.t('Controls import'),
      deadline: i18n.t('Deadline'),
      frequency: i18n.t('Frequency'),
      isMotherControl: i18n.t('Is mother control'),
      motherControl: i18n.t('Mother Control'),
      reviewer: i18n.t('Reviewer'),
      framework: i18n.t('Framework'),
      company: i18n.t('Companies'),
      level: i18n.t('Level'),
      rootLevel: i18n.t('Root level'),
      comment: i18n.t('comment'),
      lei: i18n.t('LEI')
    };
  },
  PreviewDataImport: function PreviewDataImport() {
    return {
      actionsImport: i18n.t('Actions import'),
      gapAnalysisImport: i18n.t('GAP analysis import'),
      complianceNodeImport: i18n.t('Compliance node import'),
      controlsImport: i18n.t('Controls import'),
      contractImport: i18n.t('Contract import'),
      seeTemplateForExampleDataWithColumnHeadings: i18n.t('See template for example data with column headings. The basic structure of the sheet must not be changed!'),
      uploadXlsxFile: i18n.t('Upload XLSX file'),
      uploadCSVFile: i18n.t('Upload CSV file'),
      downloadExcelExampleHere: i18n.t('Download Excel example here'),
      informationAssetsImport: i18n.t('Information assets import'),
      riskImport: i18n.t('Risk import'),
      preview: i18n.t('Preview'),
      import: i18n.t('Import'),
      pleaseCorrectTheErrorsInTheSubmittedImport: i18n.t('Please correct the errors in the submitted import'),
      frameworkImport: i18n.t('Framework') + ' ' + i18n.t('Import').toLowerCase(),
      companiesImport: i18n.t('Companies') + ' ' + i18n.t('Import').toLowerCase(),
      selectProject: i18n.t('Select project')
    };
  },
  GapSchemasAdminAttributes: function GapSchemasAdminAttributes() {
    return {
      valueShouldBeUnique: i18n.t('Value should be unique'),
      save: i18n.t('Save'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.'),
      attributesAreSaved: i18n.t('Attributes are saved'),
      createNewAttribute: i18n.t('Create new attribute'),
      unexpectedError: i18n.t('Unexpected error'),
      noChangesToSave: i18n.t('No changes to save')
    };
  },
  RismaAdmin: function RismaAdmin() {
    return {
      username: i18n.t('Username'),
      login: i18n.t('Login'),
      groups: i18n.t('Groups'),
      emailHasBeenSent: i18n.t('E-mail has been sent.'),
      somethingWentWrong: i18n.t('Something went wrong!'),
      tryAgainOrIfTheIssue: i18n.t('Try again or if this issue persists please contact Risma support.'),
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while updating'),
      changesSaved: i18n.t('Changes saved')
    };
  },
  UsersAnonymization: function UsersAnonymization() {
    return {
      userAnonymization: i18n.t('User anonymization'),
      users: i18n.t('Users'),
      selectUser: i18n.t('Select user'),
      chooseAction: i18n.t('choose action'),
      deactivateKeepUserData: i18n.t('Deactivate and keep user data'),
      deactivateAnonymizeAllUserDataExceptUserInitials: i18n.t('Deactivate and anonymize all user data except user initials'),
      deactivateAnonymizeAllUserData: i18n.t('Deactivate and anonymize all user data'),
      anonymizeAllUserDataExceptUserInitials: i18n.t('Anonymize all user data except user initials'),
      anonymizeAllUserData: i18n.t('Anonymize all user data'),
      areYouSureYouWantToContinue: i18n.t('Are you sure you want to continue?'),
      submit: i18n.t('Submit'),
      userDeactivated: i18n.t('User deactivated'),
      allUserIdentifiableDataAnonymizedExceptUserInitials: i18n.t('All user identifiable data anonymized except user initials'),
      allUserIdentifiableDataAnonymized: i18n.t('All user identifiable data anonymized'),
      youCanDeactivateAnonymizeOrDoBothAtTheSameTime: i18n.t("You can deactivate, anonymize, or do both at the same time. The options available depends on the user's status. For example, if a user is already deactivated, you can only anonymize them. Additionally, some anonymized users may still have their initials visible in RISMA for auditing- or compliance purposes.")
    };
  },
  UsersActivitiesList: function UsersActivitiesList() {
    return {
      module: i18n.t('Module'),
      title: i18n.t('Title'),
      assignedAs: i18n.t('Assigned as'),
      dailyTeam: i18n.t('Daily team'),
      responsible: i18n.t('Responsible'),
      accountable: i18n.t('Accountable'),
      consulted: i18n.t('Consulted'),
      informed: i18n.t('Informed'),
      notifications: i18n.t('Notifications'),
      dailyTeamusers: i18n.t('Daily team - Users'),
      dailyRiskOwner: i18n.t('Daily risk owner'),
      primaryRiskOwner: i18n.t('Primary risk owner')
    };
  },
  EmailsAdmin: function EmailsAdmin() {
    return {
      emails: i18n.t('E-mails'),
      rismaModule: i18n.t('RISMA module'),
      emailTrigger: i18n.t('E-mail trigger'),
      emailTemplateText: i18n.t('E-mail template text'),
      replaceRismaLogoWithText: i18n.t('Replace RISMA logo with text'),
      editEmailText: i18n.t('Edit e-mail text'),
      thePreviewOfTheEmaillTemplateWillUpdateAutomaticallyAsTextIsAdded: i18n.t('The preview of the e-mail template will update automatically as text is added'),
      customTextForEmail: i18n.t('Custom text for e-mail'),
      previewEmailTemplate: i18n.t('Preview e-mail template'),
      updatePreview: i18n.t('Update preview'),
      closeWindow: i18n.t('Close window'),
      areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted: i18n.t('Are you sure you wish to close the window. All entered data will be deleted.'),
      nbOnlyForAuditAndAwarenessEmails: i18n.t('NB: Only for audit- and awareness e-mails')
    };
  },
  SiemControlPanel: function SiemControlPanel() {
    return {
      theSiemSetupIsDeleted: i18n.t('The SIEM setup is deleted'),
      create: i18n.t('Create'),
      server: i18n.t('Server'),
      pleaseFillOutAllRequiredFields: i18n.t('Please fill out all required fields'),
      success: i18n.t('Success'),
      error: i18n.t('Error'),
      cancel: i18n.t('Cancel'),
      delete: i18n.t('Delete'),
      save: i18n.t('Save'),
      logFiles: i18n.t('Log files'),
      lastStatus: i18n.t('Last status'),
      latestLogMessage: i18n.t('Latest log message'),
      path: i18n.t('Path'),
      connectionType: i18n.t('Connection type'),
      activateSiemTransfer: i18n.t('Activate SIEM transfer'),
      createNewTransfer: i18n.t('Create new transfer'),
      chooseAnExistingSIEMTransfer: i18n.t('Choose an existing SIEM transfer from the list or create a new one.'),
      chooseSIEMTransfer: i18n.t('Choose SIEM transfer'),
      siemSetup: i18n.t('SIEM setup'),
      username: i18n.t('Username'),
      password: i18n.t('Password'),
      successfulLogins: i18n.t('Successful logins'),
      failedLogins: i18n.t('Failed logins'),
      usersChangelog: i18n.t('User changelog'),
      siemControlPanel: i18n.t('SIEM control panel'),
      selectOneOrMoreLogsYouWishToExport: i18n.t('Select one or more logs you wish to export to your external SIEM system.'),
      provideTheNecessaryAuthenticationCredentials: i18n.t('Provide the necessary authentication credentials, including username, password and so on. Data will be transfered once a day.'),
      pleaseCorrectTheErrorsInThePathString: i18n.t('Please correct errors in the path string. Valid symbols: a-zA-Z0-9_ -. Example of a valid path "/data/export/risma/logs"'),
      pleaseSelectAtLeastOneLogFileYouWithToExport: i18n.t('Select at least one log file you wish to export'),
      siemSetupIsCreated: i18n.t('SIEM setup is created'),
      siemSetupIsUpdated: i18n.t('SIEM setup is updated'),
      deleteSiemSetup: i18n.t('Delete SIEM setup'),
      areYouSureYouWantToDeleteThisSiem: i18n.t('Are you sure you want to delete this SIEM setup?'),
      pleaseFillOutThisField: i18n.t('Please fill out this field.')
    };
  },
  AdminAccessChangelog: function AdminAccessChangelog() {
    return {
      filterByUsername: i18n.t('Filter by Username'),
      chooseUser: i18n.t('Choose user'),
      filterByDateRange: i18n.t('Filter by Date Range'),
      createReport: i18n.t('Create report')
    };
  },
  DataflowQuestionsAdmin: function DataflowQuestionsAdmin() {
    return {
      number: i18n.t('Number'),
      question: i18n.t('Question'),
      dataTypes: i18n.t('Data types'),
      legalBasis: i18n.t('Legal basis'),
      storagePeriod: i18n.t('Storage period'),
      answerOptions: i18n.t('Answer options'),
      listIsEmpty: i18n.t('List is empty')
    };
  },
  UserActionConfiguration: function UserActionConfiguration() {
    return {
      privileged: i18n.t('Privileged'),
      super: i18n.t('Super'),
      administrator: i18n.t('Administrator'),
      create: i18n.t('Create'),
      delete: i18n.t('Delete'),
      activity: i18n.t('activity'),
      createMotherControl: i18n.t('Create Mother Control'),
      userActionsConfigurationUpdated: i18n.t('User actions configuration') + ' ' + i18n.t('updated'),
      onTheUserActionsConfigurationsPage: i18n.t('On the User actions configurations page, you can view and configure the access rights granted to different user access levels – such as normal-, privileged-, super-, and administrator users – within each module.'),
      noteThatAccessRightsAreFixedPerAccessLevel: i18n.t('Note that access rights are fixed per access level and apply to all users at that user access level, with no option for individual customization.')
    };
  },
  IncidentsWorkflows: function IncidentsWorkflows() {
    return {
      incidentsWorkflows: i18n.t('Incidents workflows'),
      createWorkflow: i18n.t('Create workflow'),
      defaultIncidentWorkflow: i18n.t('Default incident workflow'),
      all: i18n.t('All'),
      active: i18n.t('Active'),
      workflow: i18n.t('Workflow'),
      activityType: i18n.t('Activity type'),
      status: i18n.t('Status'),
      validated: i18n.t('Validated'),
      copy: i18n.t('Copy'),
      create: i18n.t('Create'),
      title: i18n.t('Title'),
      created: i18n.t('created'),
      isMandatory: i18n.t('is mandatory'),
      anErrorOccurred: i18n.t('An error occurred'),
      createAndEditYourOwnWorkflowsForIncidents: i18n.t("Create and edit your own workflows for incidents. The default incident workflow will apply to all activity types that don\u2019t have their own workflow. The default incident workflow can be configured in any way you want by adding additional statuses, but transitions are not part of it.")
    };
  },
  IncidentsWorkflowEdit: function IncidentsWorkflowEdit() {
    return {
      statusSettings: i18n.t('Status settings'),
      create: i18n.t('Create'),
      save: i18n.t('Save'),
      title: i18n.t('Title'),
      instructions: i18n.t('Instructions'),
      theInstructionsWillBeVisibleOnTheActivity: i18n.t('The instructions will be visible on the activity when it’s in the relevant status'),
      cancel: i18n.t('Cancel'),
      defaultIncidentWorkflow: i18n.t('Default incident workflow'),
      addStatus: i18n.t('Add status'),
      statusTitle: i18n.t('Status title'),
      delete: i18n.t('Delete'),
      deleteStatus: i18n.t('Delete status'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      status: i18n.t('Status'),
      selectAStatus: i18n.t('Select a status'),
      workflowStatus: i18n.t('Workflow status'),
      created: i18n.t('created'),
      updated: i18n.t('updated'),
      deleted: i18n.t('deleted'),
      isMandatory: i18n.t('is mandatory'),
      anErrorOccurred: i18n.t('An error occurred'),
      youAreTryingToDeleteStatus: function youAreTryingToDeleteStatus(title, number) {
        return i18n.t('You are trying to delete status %s. There are %s incidents pending in this status. To proceed, select a new status to transition these incidents to and click “Delete” in order to complete the action.', [title, number]);
      },
      enterTitle: getTitlePlaceholder()
    };
  },
  IncidentsCustomWorkflowEdit: function IncidentsCustomWorkflowEdit() {
    return {
      statusSettings: i18n.t('Status settings'),
      create: i18n.t('Create'),
      save: i18n.t('Save'),
      title: i18n.t('Title'),
      instructions: i18n.t('Instructions'),
      theInstructionsWillBeVisibleOnTheActivity: i18n.t('The instructions will be visible on the activity when it’s in the relevant status'),
      cancel: i18n.t('Cancel'),
      addStatus: i18n.t('Add status'),
      statusTitle: i18n.t('Status title'),
      delete: i18n.t('Delete'),
      deleteStatus: i18n.t('Delete status'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      status: i18n.t('Status'),
      selectAStatus: i18n.t('Select a status'),
      workflowStatus: i18n.t('Workflow status'),
      created: i18n.t('created'),
      updated: i18n.t('updated'),
      deleted: i18n.t('deleted'),
      isMandatory: i18n.t('is mandatory'),
      'Invalid color': i18n.t('Invalid color'),
      anErrorOccurred: i18n.t('An error occurred'),
      youAreTryingToDeleteStatus: function youAreTryingToDeleteStatus(title, number) {
        return i18n.t('You are trying to delete status %s. There are %s incidents pending in this status. To proceed, select a new status to transition these incidents to and click “Delete” in order to complete the action.', [title, number]);
      },
      isStartStatus: i18n.t('Is start status'),
      statusColor: i18n.t('Status color'),
      transitions: i18n.t('Transitions'),
      selectTheStatusesItCanTransitionTo: i18n.t('Select the statuses it can transition to'),
      defaultResponsible: i18n.t('Default responsible'),
      activeFields: i18n.t('Active fields'),
      selectTheFieldsThatCanBeEditedIn: i18n.t('Select the fields that can be edited in by the responsible(s) whenever incidents are in this status'),
      selectThePersonsThatWillDefaultBeSelectedAsResponsibles: i18n.t('Select the person(s) that will default be selected as responsibles'),
      fieldType: i18n.t('Field type'),
      active: i18n.t('Active'),
      editable: i18n.t('Editable'),
      hide: i18n.t('Hide'),
      enterTitle: getTitlePlaceholder()
    };
  },
  CloseYear: function CloseYear() {
    return {
      closeReportingYear: i18n.t('Close reporting year'),
      theReportingYearIsClosed: i18n.t('The reporting year is closed'),
      areYouSure: i18n.t('Are you sure'),
      whenYouCloseTheReportingYearForYourCsrd: i18n.t('When you close the reporting year for your CSRD project all ongoing and open sustainability reports will be finalized and locked to prevent further modifications.'),
      allDisclosureRequirementsReportDraftFields: i18n.t('All Disclosure Requirements "Report draft" fields will be cleared and integrated into the finalized reports as part of the reporting framework requirements.'),
      theFinalizedReportsWillServeAsAVersionedRecord: i18n.t('The finalized reports will serve as a versioned record of the sustainability disclosures for the reporting period, preserving their content for compliance and reference.'),
      importantOnceTheReportingYearIsClosed: i18n.t('Important: Once the reporting year is closed, no additional updates or changes can be made to the reports. Ensure all disclosure requirements and data are accurate and complete before proceeding.')
    };
  }
};