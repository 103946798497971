function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Selector from '@/components/Atoms/Inputs/Selector';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import ControlCreationModal from '@/components/Molecules/Controls/ControlCreationModal';
import CreateInitiativeModal from '@/components/Organisms/CreateInitiativeModal';
import { Colors } from '@/Colors';
import { getModuleTitles, MODULES } from '@/constants/modules';
import { create as createInitiative } from '@/api/execution/initiative';
import { comparePathStrings, compareStrings } from '@/utils/compare';
export default {
  name: 'LinksModal',
  introduction: 'LinkModal is used to update links',
  description: 'LinksModal with  selector and list of selected items',
  token: '<links-modal :projects-list=[] :links-options=[] :preselected-items=[] />',
  components: {
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    Selector: Selector,
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    TrafficLight: TrafficLight,
    ControlCreationModal: ControlCreationModal,
    CreateInitiativeModal: CreateInitiativeModal
  },
  props: {
    projectsList: {
      type: Array,
      required: true,
      note: 'List of all available projects'
    },
    linksOptions: {
      type: Array,
      required: true,
      note: 'List of links activities'
    },
    preselectedLinks: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected delegation items'
    },
    initiatives: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'List of all initiatives'
    },
    disabledProjectSelect: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If project selector should be disabled'
    },
    projectSelectVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    preselectedProject: {
      type: String,
      required: false,
      default: ''
    },
    titleForModal: {
      type: String,
      required: false,
      default: ''
    },
    titleForSelectedActivities: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['selectProject', 'updateLinks', 'addNewItem', 'dismiss'],
  data: function data() {
    return {
      selectedLinks: [],
      selectedProject: [],
      project: null,
      isControlCreationModalShow: false,
      isCreationInitiativeModalShow: false,
      Colors: Colors,
      MODULES: MODULES,
      ModuleTitles: getModuleTitles(),
      additionalSearchFields: _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, MODULES.CONTROLS, ['cpath']), MODULES.EXECUTION, ['ipath']), MODULES.RISK, ['rno']), MODULES.COMPANY, ['name']),
      translate: getTranslate['LinksModal']()
    };
  },
  computed: {
    modalTitle: function modalTitle() {
      return this.titleForModal || this.translate.links;
    },
    addedLinksTitle: function addedLinksTitle() {
      return this.titleForSelectedActivities || this.translate.addedLinks;
    },
    filteredProjectsList: function filteredProjectsList() {
      return this.projectsList.filter(function (item) {
        return item.rights;
      });
    },
    selectableItems: function selectableItems() {
      var _this = this;
      return this.linksOptions.filter(function (item) {
        return !_this.selectedLinks.find(function (selected) {
          return selected.id == item.id;
        });
      });
    },
    filteredInitiatives: function filteredInitiatives() {
      return this.initiatives.filter(function (item) {
        return !item.closed;
      });
    },
    sortedSelectableItems: function sortedSelectableItems() {
      var items = this.selectableItems.slice();
      switch (this.project) {
        case MODULES.EXECUTION:
          return this.sortByPath(items, 'ipath');
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return this.sortByTitle(items, 'formattedTitle');
        case MODULES.CONTRACTS:
          return this.sortByTitle(items, 'title');
        default:
          return items;
      }
    },
    linkLabel: function linkLabel() {
      var _this2 = this;
      var _this$project$split = this.project.split('-'),
        _this$project$split2 = _slicedToArray(_this$project$split, 1),
        module = _this$project$split2[0];
      if (module === MODULES.COMPLIANCE_INFORMATION_ASSETS) {
        return this.filteredProjectsList.find(function (item) {
          return item.value === _this2.project;
        }).label;
      }
      return this.$trans(this.ModuleTitles[module]).toLowerCase();
    },
    selectedLinkIds: function selectedLinkIds() {
      return this.selectedLinks.map(function (_ref) {
        var id = _ref.id;
        return id;
      });
    },
    showAddItemButton: function showAddItemButton() {
      var _this$projectsList$fi,
        _this3 = this;
      return this.project && ((_this$projectsList$fi = this.projectsList.find(function (project) {
        return project.value === _this3.project;
      })) === null || _this$projectsList$fi === void 0 ? void 0 : _this$projectsList$fi.showAddItemButton) || false;
    },
    searchFields: function searchFields() {
      var additionalFields = this.additionalSearchFields[this.project] || [];
      return ['title'].concat(_toConsumableArray(additionalFields));
    }
  },
  watch: {
    preselectedLinks: {
      deep: true,
      immediate: true,
      handler: function handler(value) {
        this.selectedLinks = value;
      }
    }
  },
  created: function created() {
    this.project = this.preselectedProject;
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColor'])), {}, {
    selectedLink: function selectedLink(item) {
      this.selectedLinks.push(item);
    },
    deselect: function deselect(item) {
      this.selectedLinks = this.selectedLinks.filter(function (selectedItem) {
        return selectedItem.id != item.id;
      });
    },
    selectProject: function selectProject(project) {
      this.project = project;
      this.$emit('selectProject', this.project);
    },
    updateLinks: function updateLinks(module) {
      if (!module) {
        this.$emit('dismiss');
        return;
      }
      this.$emit('updateLinks', {
        module: module,
        links: this.selectedLinkIds
      });
    },
    openCreationModal: function openCreationModal(project) {
      if (project == MODULES.CONTROLS) this.isControlCreationModalShow = true;
      if (project == MODULES.EXECUTION) this.isCreationInitiativeModalShow = true;
    },
    addControl: function addControl(newControl) {
      this.$emit('addNewItem', newControl);
      this.selectedLinks.push(newControl);
    },
    addInitiative: function addInitiative(newInitiative) {
      var _this4 = this;
      createInitiative(newInitiative).then(function (response) {
        response.id = +response.id;
        _this4.selectedLinks.push(response);
        _this4.$emit('addNewItem', response);
      });
      this.isCreationInitiativeModalShow = false;
    },
    sortByPath: function sortByPath(data, key) {
      if (data[0] && data[0][key]) {
        return data.sort(function (a, b) {
          return comparePathStrings(a[key], b[key]);
        });
      }
      return data;
    },
    sortByTitle: function sortByTitle(data, key) {
      if (data[0] && data[0][key]) {
        return data.sort(function (a, b) {
          return compareStrings(a[key], b[key]);
        });
      }
      return data;
    },
    getLineThrough: function getLineThrough(item) {
      return this.project === MODULES.CONTROLS && !item.active || this.project === MODULES.RISK && item.closed;
    }
  })
};