import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_split_button = _resolveComponent("risma-split-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_split_button, {
    text: "Save",
    class: "mr-4"
  }, {
    dropContent: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("ul", null, [_createElementVNode("li", null, "one"), _createElementVNode("li", null, "two"), _createElementVNode("li", null, "three")], -1 /* HOISTED */)]);
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "save",
        class: "mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risma_split_button, {
    disabled: true,
    "vertical-dropdown-position": "top",
    text: "(s)disabled",
    class: "mr-4"
  }, {
    dropContent: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createElementVNode("ul", null, [_createElementVNode("li", null, "one"), _createElementVNode("li", null, "two"), _createElementVNode("li", null, "three")], -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_risma_split_button, {
    disabled: true,
    "disabled-dropdown": true,
    text: "(c)disabled"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "alert-circle",
        class: "mr-2"
      })];
    }),
    _: 1 /* STABLE */
  })]);
}