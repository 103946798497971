function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
export default {
  name: 'Pagination',
  introduction: 'Show pagination for arrays',
  description: "Pagination gets length of array as total property and amount of items per page.\n        When the component mounted it emit an event \"change\" with object of indexes.\n        The object contains {start: Number, end: Number} indexes of begin / end extraction from full array.\n        For example, you have an array [11] and 5 items per page. If user click on second page the component emit\n        the event with {start: 5, end: 10}.\n        Don't slice an origin array, use filtred result - https://vuejs.org/v2/guide/list.html#Displaying-Filtered-Sorted-Results",
  token: "<pagination :total=\"Number\" :per-page=\"Number\" @change=\"handleShownList\" />\n        function handleShownList(indexes) {\n            return originArray.slice(indexes.start, indexes.end + 1);\n        }",
  props: {
    total: {
      type: Number,
      required: true,
      note: 'Number of amount items. For example, array.length'
    },
    perPage: {
      type: Number,
      required: true,
      note: 'How many items for per page'
    },
    maxLength: {
      required: false,
      type: Number,
      default: 0,
      note: 'Max length (filtered from)'
    },
    showPrevNextButtons: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show Previous/Next buttons?'
    },
    stateSession: {
      type: String,
      default: '',
      note: 'The name of a key in sessionStorage'
    },
    additionalClasses: {
      type: String,
      default: '',
      note: 'Additional classes'
    },
    activePageNumber: {
      type: Number,
      default: 1
    },
    additionalPropText: {
      type: String,
      default: ''
    }
  },
  emits: ['totalChanged', 'change'],
  data: function data() {
    return {
      currentPage: this.activePageNumber,
      step: 4,
      translate: getTranslate['Pagination']()
    };
  },
  computed: {
    pages: function pages() {
      return Math.ceil(this.total / this.perPage);
    },
    // Index starts from 0
    showItemsStartIndex: function showItemsStartIndex() {
      return this.currentPage * this.perPage - this.perPage;
    },
    // Index ends with length of array - 1
    showItemsEndIndex: function showItemsEndIndex() {
      var possibilityEndItems = this.perPage * this.currentPage;
      return (possibilityEndItems > this.total ? this.total : possibilityEndItems) - 1;
    },
    paginationText: function paginationText() {
      var result = '';
      var start = 0;
      var end = 0;
      var total = 0;
      if (this.pages >= 1) {
        start = this.showItemsStartIndex + 1;
        end = this.showItemsEndIndex + 1;
        total = this.total;
      }
      result = this.translate.showingStartToEndOfTotalRecords.replace('_START_', start).replace('_END_', end).replace('_TOTAL_', total);
      return result;
    },
    paginationFilteredFromText: function paginationFilteredFromText() {
      var result = '';
      if (!this.maxLength) {
        return result;
      }
      if (this.maxLength > this.total) {
        result = this.translate.filteredFromMaxRecords.replace('_MAX_', this.maxLength);
      }
      return result;
    }
  },
  watch: {
    activePageNumber: function activePageNumber(value) {
      if (this.currentPage != value) {
        this.currentPage = value;
      }
    },
    perPage: function perPage() {
      this.currentPage = 1;
      this.$emit('change', {
        start: this.showItemsStartIndex,
        end: this.showItemsEndIndex,
        currentPage: this.currentPage
      });
    },
    total: function total() {
      this.changePage(1);
      this.$emit('totalChanged', {
        start: this.showItemsStartIndex,
        end: this.showItemsEndIndex
      });
    }
  },
  mounted: function mounted() {
    this.$emit('change', {
      start: this.showItemsStartIndex,
      end: this.showItemsEndIndex,
      currentPage: this.currentPage,
      mounted: true
    });
    this.setFromStorage();
  },
  methods: {
    setFromStorage: function setFromStorage() {
      if (!this.stateSession) return;
      var savedSession = JSON.parse(sessionStorage.getItem(this.stateSession));
      if (savedSession !== null && savedSession !== void 0 && savedSession.page) this.changePage(+savedSession.page);
    },
    changePage: function changePage(page) {
      if (page < 1 || page > this.pages) {
        return;
      }
      this.currentPage = page;
      this.$emit('change', {
        start: this.showItemsStartIndex,
        end: this.showItemsEndIndex,
        currentPage: this.currentPage
      });
      this.saveToStorage();
    },
    saveToStorage: function saveToStorage() {
      if (!this.stateSession) return;
      var savedSession = JSON.parse(sessionStorage.getItem(this.stateSession)) || {};
      sessionStorage.setItem(this.stateSession, JSON.stringify(_objectSpread(_objectSpread({}, savedSession), {}, {
        page: this.currentPage
      })));
    },
    resetPage: function resetPage() {
      if (this.stateSession) {
        this.setFromStorage();
        return;
      }
      this.currentPage = 1;
    },
    canBeShown: function canBeShown(page) {
      var result = true;
      if (!this.showPrevNextButtons) {
        return result;
      }
      if (this.pages > this.step && page !== 1 && page !== this.pages) {
        if (page === this.currentPage) {
          result = true;
        } else result = page === this.currentPage - 1 || page === this.currentPage + 1;
      }
      return result;
    }
  }
};