import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["id", "type", "placeholder", "required", "disabled", "maxlength", "max", "min", "autocomplete", "step", "tabindex", "accept"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_label = _resolveComponent("risma-label");
  var _directive_focus = _resolveDirective("focus");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    for: $options.id,
    class: "cursor-pointer"
  }, null, 8 /* PROPS */, ["title", "for"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["input-field", {
      'h-11 flex items-center': $props.isStaticHeight
    }])
  }, [_withDirectives(_createElementVNode("input", {
    id: $options.id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.rawText = $event;
    }),
    type: $props.type,
    placeholder: $props.placeholder,
    required: $props.required,
    disabled: !$props.enabled,
    maxlength: $props.maxlength,
    max: $props.max,
    min: $props.min,
    autocomplete: $props.autocomplete,
    step: $props.step,
    class: _normalizeClass([$options.baseStyles, $options.interactionStyles]),
    style: _normalizeStyle($props.externalStyles),
    tabindex: $props.tabindex,
    accept: $props.accept,
    onKeypress: _cache[1] || (_cache[1] = function () {
      return $options.onKeyPress && $options.onKeyPress.apply($options, arguments);
    }),
    onInput: _cache[2] || (_cache[2] = function () {
      return $options.emit && $options.emit.apply($options, arguments);
    }),
    onFocus: _cache[3] || (_cache[3] = function () {
      return $options.onFocus && $options.onFocus.apply($options, arguments);
    }),
    onClick: _cache[4] || (_cache[4] = function () {
      return $options.onClick && $options.onClick.apply($options, arguments);
    }),
    onBlur: _cache[5] || (_cache[5] = function () {
      return $options.onBlur && $options.onBlur.apply($options, arguments);
    }),
    onKeyup: [_cache[6] || (_cache[6] = _withKeys(function () {
      return $options.onEnter && $options.onEnter.apply($options, arguments);
    }, ["enter"])), _cache[7] || (_cache[7] = _withKeys(function () {
      return $options.onEsc && $options.onEsc.apply($options, arguments);
    }, ["esc"]))]
  }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelDynamic, $data.rawText, void 0, {
    trim: true
  }], [_directive_focus, $props.focusOnMount]])], 2 /* CLASS */)]);
}