function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import cloneDeep from 'lodash/cloneDeep';
import { createRisk, getReports, getRiskProjects, getThreats, updateRisk } from '@/api/risk/risk';
import { getCustomField } from '@/api/admin/admin';
import { createCustomThreat as _createCustomThreat, getCustomThreats as _getCustomThreats, updateCustomThreat as _updateCustomThreat } from '@/api/risma/riskAssessments';
import { checkERMByProjectId } from '@/utils/risks/check';
import { MODULES } from '@/constants/modules';
import { RiskProjects } from '@/constants/risks/RiskProjects';
import { compareStrings } from '@/utils/compare';
import * as Utils from '@/utils/Utils';
import { sortAlphabetic } from '@/utils/sort';
export var RiskAssessmentMixin = {
  emits: ['getActivityData', 'saveStatusClicked', 'propertyChanged', 'update:riskAssessmentRisk'],
  data: function data() {
    return {
      risk: {},
      currentPeriod: null,
      threatsByRiskLabels: [],
      rtoCustomFieldOptions: [],
      rpoCustomFieldOptions: [],
      customThreats: [],
      currentRiskProject: {},
      periodSelected: null,
      threatUpdateInProgress: false,
      isCreatingRisk: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    rpoFieldId: function rpoFieldId() {
      return this.getSettingValue('feature.isms_rpo_field_id');
    },
    rtoFieldId: function rtoFieldId() {
      return this.getSettingValue('feature.isms_rto_field_id');
    },
    showRiskAssessment: function showRiskAssessment() {
      return !this.getSettingValue('feature.hide_ia_risk_assessments');
    },
    showRtoRpoOnAssets: function showRtoRpoOnAssets() {
      return !!this.getSettingValue('feature.show_rto_rpo_on_assets');
    },
    displayRtoRpoOptions: function displayRtoRpoOptions() {
      return this.showRtoRpoOnAssets && this.rtoOptions && this.rtoOptions.length;
    },
    rtoOptions: function rtoOptions() {
      return this.rtoCustomFieldOptions.filter(function (option) {
        return option.value;
      }).map(function (option) {
        return {
          value: option.id,
          label: option.value
        };
      });
    },
    rtoValue: function rtoValue() {
      var _this = this;
      if (!this.data.customFieldValues) return null;
      var valueObject = this.data.customFieldValues.find(function (f) {
        return +f.fieldId === +_this.rtoFieldId;
      });
      if (valueObject) return valueObject.value;
      return null;
    },
    rpoOptions: function rpoOptions() {
      return this.rpoCustomFieldOptions.filter(function (option) {
        return option.value;
      }).map(function (option) {
        return {
          value: option.id,
          label: option.value
        };
      });
    },
    rpoValue: function rpoValue() {
      var _this2 = this;
      if (!this.data.customFieldValues) return null;
      var valueObject = this.data.customFieldValues.find(function (f) {
        return +f.fieldId === +_this2.rpoFieldId;
      });
      if (valueObject) return valueObject.value;
      return null;
    },
    customThreatsEnabled: function customThreatsEnabled() {
      return !!this.getSettingValue('feature.custom_risk_assessment_threats');
    },
    threatsSortedByCategories: function threatsSortedByCategories() {
      var threats = _toConsumableArray(this.threatsByRiskLabels);
      sortAlphabetic(threats, 'title', true);
      return threats.sort(function (a, b) {
        var _a$category, _b$category;
        return !a.category ? -1 : !b.category ? 1 : compareStrings((_a$category = a.category) === null || _a$category === void 0 ? void 0 : _a$category.title, (_b$category = b.category) === null || _b$category === void 0 ? void 0 : _b$category.title);
      });
    },
    threatsWithCategoryLabel: function threatsWithCategoryLabel() {
      var threats = cloneDeep(this.threatsSortedByCategories);
      threats.forEach(function (threat) {
        threat.title = Utils.getThreatLabelWithCategory(threat);
      });
      return threats;
    },
    inherentRisksEnabled: function inherentRisksEnabled() {
      var _this$currentRiskProj;
      return (_this$currentRiskProj = this.currentRiskProject) === null || _this$currentRiskProj === void 0 ? void 0 : _this$currentRiskProj.inherentRisksEnabled;
    },
    isErm: function isErm() {
      return checkERMByProjectId(this.riskProjectId, this.currentUser);
    },
    riskRelevantToProject: function riskRelevantToProject() {
      var _this$risk;
      return this.riskProjectId && ((_this$risk = this.risk) === null || _this$risk === void 0 ? void 0 : _this$risk.projectId) === this.riskProjectId;
    }
  }),
  methods: {
    getRisksData: function getRisksData() {
      var _this3 = this;
      var promises = [];
      if (this.riskProjectId && !this.isErm) {
        promises.push(this.getCurrentRiskProject().then(function (riskProject) {
          if (!riskProject) return Promise.resolve();
          _this3.currentRiskProject = riskProject;
          _this3.periodSelected = _this3.currentPeriod = riskProject.projectPeriods.find(function (item) {
            return item.isCurrent;
          });
          return _this3.getDataForRiskAssessments();
        }));
      }
      return Promise.all(promises);
    },
    getDataForRiskAssessments: function getDataForRiskAssessments() {
      var _this4 = this;
      var promises = [];
      if (this.data.links && this.data.links[MODULES.RISK_ASSESSMENT] && this.data.links[MODULES.RISK_ASSESSMENT][0]) {
        promises.push(this.getRiskAssessment(this.data.links[MODULES.RISK_ASSESSMENT][0]));
      }
      return Promise.all(promises).then(function () {
        var consequenceRowsLabels = _this4.currentPeriod.consequenceRows.map(function (item) {
          return item.label;
        });
        return _this4.getThreatsByRiskLabels(consequenceRowsLabels);
      }).then(this.getCustomThreats);
    },
    getCurrentRiskProject: function getCurrentRiskProject() {
      var _this5 = this;
      return getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        return list.filter(function (item) {
          return +item.type !== RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type;
        }).find(function (item) {
          return +item.id === +_this5.riskProjectId;
        });
      });
    },
    getRiskAssessment: function getRiskAssessment(id) {
      var _this6 = this;
      if (!id) return Promise.resolve();
      var params = "?filters[id]=".concat(id, "&filters[underlyingrisks]=include&data[]=riskAssessmentRelation");
      return getReports(params).then(function (_ref2) {
        var list = _ref2.list;
        _this6.risk = list.length ? list[0] : {};
        _this6.$emit('update:riskAssessmentRisk', _this6.risk);
      });
    },
    getThreatsByRiskLabels: function getThreatsByRiskLabels() {
      var _this7 = this;
      var labelsList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var params = labelsList.join('&label[]=');
      return getThreats(this.riskProjectId, 'data[]=all&label[]=' + params).then(function (_ref3) {
        var list = _ref3.list;
        return _this7.threatsByRiskLabels = list;
      });
    },
    getCustomThreats: function getCustomThreats() {
      var _this$risk2,
        _this8 = this;
      if (!this.customThreatsEnabled || !((_this$risk2 = this.risk) !== null && _this$risk2 !== void 0 && _this$risk2.rno) || !this.riskRelevantToProject) return;
      return _getCustomThreats(this.riskProjectId, this.currentPeriod.id, this.risk.rno).then(function (_ref4) {
        var list = _ref4.list;
        return _this8.customThreats = list;
      }).catch(function () {});
    },
    onUpdateCustomThreat: function onUpdateCustomThreat(data, rowId) {
      var _this$risk3,
        _this9 = this;
      this.threatUpdateInProgress = true;
      var promise = (_this$risk3 = this.risk) !== null && _this$risk3 !== void 0 && _this$risk3.rno ? Promise.resolve() : this.createRiskAssessment();
      return promise.then(function () {
        return _this9.handleUpdateCustomThreat(data, rowId);
      }).finally(function () {
        return _this9.threatUpdateInProgress = false;
      });
    },
    handleUpdateCustomThreat: function handleUpdateCustomThreat(data, rowId) {
      var promise = data.customThreatId ? this.updateCustomThreat : this.createCustomThreat;
      return promise(data, rowId).then(this.updateCustomThreatsList);
    },
    updateCustomThreat: function updateCustomThreat(data) {
      var updateData = {
        impact: data.impact === undefined ? data.consequence : data.impact,
        likelihood: data.likelihood === undefined ? data.probability : data.likelihood,
        relevant: +data.relevant
      };
      return _updateCustomThreat(this.riskProjectId, this.currentPeriod.id, this.risk.rno, data.customThreatId, updateData);
    },
    createCustomThreat: function createCustomThreat(data, rowId) {
      var createData = {
        riskAssessmentRowId: rowId,
        threatId: data.id,
        impact: data.impact === undefined ? data.consequence : data.impact,
        likelihood: data.likelihood === undefined ? data.probability : data.likelihood,
        relevant: +data.relevant
      };
      return _createCustomThreat(this.riskProjectId, this.currentPeriod.id, this.risk.rno, createData);
    },
    updateCustomThreatsList: function updateCustomThreatsList(threat) {
      var index = this.customThreats.findIndex(function (t) {
        return t.id === threat.id;
      });
      var customThreat = index === -1 ? this.customThreats.length : index;
      this.customThreats[customThreat] = threat;
    },
    onRiskAssesmentsChange: function onRiskAssesmentsChange(value) {
      var _this$risk4;
      if ((_this$risk4 = this.risk) !== null && _this$risk4 !== void 0 && _this$risk4.rno) {
        this.updateRiskAssessment(value);
        return;
      }
      this.createRiskAssessment(value);
    },
    createRiskAssessment: function createRiskAssessment(value) {
      var _this10 = this;
      this.isCreatingRisk = true;
      this.$emit('saveStatusClicked');
      var data = _objectSpread({
        title: this.data.title,
        riskAssessmentLink: {
          module: this.module,
          id: this.data.id
        },
        activityTypeId: this.currentRiskProject.defaultActivityTypeId,
        description: this.data.description,
        responsibleUserIds: this.data.responsibleUserIds,
        informedUserIds: this.data.informedUserIds,
        consultedUserIds: this.data.consultedUserIds,
        accountableUserIds: this.data.accountableUserIds,
        organisationIds: this.data.organisationIds
      }, value);
      return createRisk(this.riskProjectId, this.currentPeriod.id, data).then(function (risk) {
        _this10.risk = risk;
        _this10.$emit('getActivityData');
        _this10.$emit('update:riskAssessmentRisk', risk);
      }).catch(function (error) {
        return _this10.$alert('Risk create error - please reload page', error.response);
      }).finally(function () {
        return _this10.isCreatingRisk = false;
      });
    },
    updateRiskAssessment: function updateRiskAssessment(value) {
      var _this11 = this;
      return updateRisk(this.riskProjectId, this.currentPeriod.id, this.risk.rno, value).then(function (risk) {
        _this11.risk = risk;
        _this11.$emit('update:riskAssessmentRisk', _this11.risk);
      });
    },
    getRtoFields: function getRtoFields() {
      var _this12 = this;
      if (!this.rtoFieldId) return Promise.resolve();
      return getCustomField(this.rtoFieldId).then(function (_ref5) {
        var options = _ref5.options;
        return _this12.rtoCustomFieldOptions = Object.values(options);
      });
    },
    getRpoFields: function getRpoFields() {
      var _this13 = this;
      if (!this.rpoFieldId) return Promise.resolve();
      return getCustomField(this.rpoFieldId).then(function (_ref6) {
        var options = _ref6.options;
        return _this13.rpoCustomFieldOptions = Object.values(options);
      });
    },
    selectRecoveryValue: function selectRecoveryValue(value, id) {
      var fieldValue = {
        value: value,
        fieldId: id
      };
      var customFieldValues = this.data.customFieldValues.map(function (v) {
        if (+v.fieldId === +id) return fieldValue;
        return v;
      });
      if (!customFieldValues.find(function (item) {
        return +item.fieldId === +id;
      })) customFieldValues.push(fieldValue);
      this.$emit('propertyChanged', {
        property: 'customFieldValues',
        value: customFieldValues
      });
    }
  }
};