// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-enter-active[data-v-0c4184be],
.fade-leave-active[data-v-0c4184be] {
  transition: opacity 0.3s;
}
.fade-enter-from[data-v-0c4184be],
.fade-leave-to[data-v-0c4184be] {
  opacity: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
