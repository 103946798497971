import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "py-3"
};
var _hoisted_2 = {
  class: "mt-4"
};
var _hoisted_3 = {
  class: "mt-4"
};
var _hoisted_4 = {
  key: 0,
  class: "mt-4 flex gap-4"
};
var _hoisted_5 = {
  class: "mt-4 flex gap-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_input_field = _resolveComponent("input-field");
  var _component_organisation_selector = _resolveComponent("organisation-selector");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.editMode ? $data.translate.editManagementReport : $data.translate.createManagementReport,
    "button-ok-text": $options.editMode ? $data.translate.save : $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    "dismiss-on-click-outside": false,
    "accept-on-enter": !$data.isRismaSelectOpen,
    "dismiss-on-esc-key": false,
    onDismiss: _cache[7] || (_cache[7] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: $options.onModalAccept
  }, {
    body: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errorMessages, function (errorMessage, i) {
        return _openBlock(), _createBlock(_component_notification, {
          key: i,
          closeable: false,
          type: "error"
        }, {
          default: _withCtx(function () {
            return [_createTextVNode(_toDisplayString(errorMessage), 1 /* TEXT */)];
          }),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_risma_label, {
        title: $data.translate.title
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.draft.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.draft.title = $event;
        }),
        "pre-text": $data.draft.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true
      }, null, 8 /* PROPS */, ["modelValue", "pre-text", "placeholder"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_label, {
        title: $data.translate.organisations
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_organisation_selector, {
        "available-options": _ctx.organisations,
        "selected-options": $data.draft.organisations,
        onSelected: _cache[1] || (_cache[1] = function ($event) {
          return $data.draft.organisations = $event;
        }),
        onOpen: _cache[2] || (_cache[2] = function ($event) {
          return $data.isRismaSelectOpen = true;
        }),
        onClose: _cache[3] || (_cache[3] = function ($event) {
          return $data.isRismaSelectOpen = false;
        })
      }, null, 8 /* PROPS */, ["available-options", "selected-options"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        modelValue: $data.draft.approvalFilter,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.draft.approvalFilter = $event;
        }),
        title: $data.translate.riskApproval,
        options: $options.approvalOptions,
        placeholder: $data.translate.filterRiskApproval,
        "with-reset": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])]), $options.relevantThreatsEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_label, {
        title: $data.translate.includeRelevantThreats
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
        "model-value": !!$data.draft.includeRelevantThreats,
        onInput: _cache[5] || (_cache[5] = function ($event) {
          return $data.draft.includeRelevantThreats = +$event;
        })
      }, null, 8 /* PROPS */, ["model-value"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_label, {
        title: $data.translate.includeComments
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_toggle, {
        "model-value": !!$data.draft.includeComments,
        onInput: _cache[6] || (_cache[6] = function ($event) {
          return $data.draft.includeComments = +$event;
        })
      }, null, 8 /* PROPS */, ["model-value"])])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "accept-on-enter", "onAccept"]);
}