import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "date-time-picker-wrap"
};
var _hoisted_2 = {
  class: "w-64"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3"
};
var _hoisted_5 = {
  class: "mb-3"
};
var _hoisted_6 = {
  class: "mb-3"
};
var _hoisted_7 = {
  class: "mb-3"
};
var _hoisted_8 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_date_time_picker = _resolveComponent("date-time-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_date_time_picker, {
    modelValue: $data.dateTimepicker1,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.dateTimepicker1 = $event;
    }),
    "delete-enabled": true,
    "plugin-options": $data.dateTimepickerOptions1,
    title: "Date time picker with help text",
    "help-text": "test help text",
    "model-value": "2017-06-01 16:00"
  }, null, 8 /* PROPS */, ["modelValue", "plugin-options"]), _createTextVNode(" " + _toDisplayString($data.dateTimepicker1), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_date_time_picker, {
    title: "Disabled date time picker",
    disabled: true,
    "plugin-options": $data.dateTimepickerOptions2
  }, null, 8 /* PROPS */, ["plugin-options"]), _createTextVNode(" " + _toDisplayString($data.dateTimepicker2), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_date_time_picker, {
    title: "Disabled date time picker(empty)",
    "model-value": null,
    disabled: true,
    "plugin-options": $data.dateTimepickerOptions3
  }, null, 8 /* PROPS */, ["plugin-options"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_date_time_picker, {
    modelValue: $data.valueErrorMessage,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.valueErrorMessage = $event;
    }),
    title: "Error message when its empty",
    "delete-enabled": true,
    "plugin-options": $data.dateTimepickerOptions4,
    "error-text": !$data.valueErrorMessage ? 'Please select some value' : ''
  }, null, 8 /* PROPS */, ["modelValue", "plugin-options", "error-text"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_date_time_picker, {
    modelValue: $data.dateTimepicker2,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.dateTimepicker2 = $event;
    }),
    title: "Date time picker 2(delete icon disabled)",
    "delete-enabled": false,
    "plugin-options": $data.dateTimepickerOptions2
  }, null, 8 /* PROPS */, ["modelValue", "plugin-options"]), _createTextVNode(" " + _toDisplayString($data.dateTimepicker2), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_date_time_picker, {
    modelValue: $data.dateTimepicker3,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.dateTimepicker3 = $event;
    }),
    title: "Date time picker 3",
    "plugin-options": $data.dateTimepickerOptions3
  }, null, 8 /* PROPS */, ["modelValue", "plugin-options"]), _createTextVNode(" " + _toDisplayString($data.dateTimepicker3), 1 /* TEXT */)])])]);
}