// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p[data-v-49bf9481],
.single-select[data-v-49bf9481] {
  display: inline-block;
}
p[data-v-49bf9481] {
  margin: 0;
  line-height: 40px;
}
.single-select[data-v-49bf9481] {
  width: 4rem;
  border: none;
  flex: none;
}
[data-v-49bf9481]  .select-selected-value div {
  border: none;
}
[data-v-49bf9481]  .custom-select-inner {
  background: transparent;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
