// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-content-element[data-v-6af8df78] {
  all: revert;
}
.html-content-element[data-v-6af8df78]  * {
  all: revert;
}
.html-content-element[data-v-6af8df78]  td {
  border: 1px solid #000000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
