import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["placeholder"];
var _hoisted_2 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("form", {
    class: "w-full",
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(function () {
      return $options.handleSubmit && $options.handleSubmit.apply($options, arguments);
    }, ["prevent"]))
  }, [_createElementVNode("div", {
    class: _normalizeClass(["flex mx-4 border pl-4 pr-2 rounded mb-2", $data.focused ? 'border-rm-active-blue outline outline-rm-active-blue--20' : 'border-rm-grey'])
  }, [_withDirectives(_createElementVNode("textarea", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.text = $event;
    }),
    tabindex: "1",
    rows: "1",
    class: "glob-l1 w-full m-0 py-3.5 resize-none bg-transparent max-h-[7.5rem] min-h-40px rounded-md transition duration-300 text-gray-750 placeholder-rm-grey outline-none focus:border-blue-750 auto-resize placeholder-shown:text-ellipsis placeholder:truncate",
    placeholder: $props.placeholder,
    maxLength: "2000",
    onFocus: _cache[1] || (_cache[1] = function ($event) {
      return $data.focused = true;
    }),
    onBlur: _cache[2] || (_cache[2] = function ($event) {
      return $data.focused = false;
    }),
    onInput: _cache[3] || (_cache[3] = function () {
      return $options.handleInput && $options.handleInput.apply($options, arguments);
    }),
    onKeydown: _cache[4] || (_cache[4] = function () {
      return $options.handleKeyDown && $options.handleKeyDown.apply($options, arguments);
    })
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelText, $data.text]]), _createElementVNode("button", {
    type: "submit",
    disabled: $options.isDisabled,
    class: "flex-none w-7 h-7 focus:outline-none focus:shadow-outline group ml-2.5 my-2 self-start rounded-full disabled:bg-rm-grey bg-rm-active-blue hover:bg-rm-active-blue-light"
  }, [_createVNode(_component_feather_icon, {
    width: "15",
    height: "15",
    class: "stroke-2 text-rm-white m-auto pr-px box-content",
    icon: "send"
  })], 8 /* PROPS */, _hoisted_2)], 2 /* CLASS */)], 32 /* NEED_HYDRATION */);
}