import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between items-center mb-3"
};
var _hoisted_2 = {
  class: "ml-12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_download_link = _resolveComponent("download-link");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", null, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.report.id ? $data.translate.editManagementReport : $data.translate.createManagementReport,
    "button-ok-text": $data.report.id ? $data.translate.save : $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showModal = false;
    }),
    onAccept: $options.onModalAccept
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.report.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.report.title = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.managementReport
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "plus",
    class: "text-blue-750 stroke-3 cursor-pointer",
    width: "40",
    height: "40",
    onClick: $options.openModal
  }, null, 8 /* PROPS */, ["onClick"])]), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : (_openBlock(), _createBlock(_component_static_table, {
    key: 2,
    "dataset-table": $options.dataset,
    columns: $options.columns
  }, {
    downloadLatest: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_download_link, {
        "file-src": slotData,
        "file-name": "ManagementReport"
      }, null, 8 /* PROPS */, ["file-src"])])];
    }),
    edit: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createVNode(_component_feather_icon, {
        class: "ml-2 cursor-pointer text-blue-750 inline-block",
        icon: "edit",
        onClick: function onClick($event) {
          return $options.openModal(slotData);
        }
      }, null, 8 /* PROPS */, ["onClick"])];
    }),
    delete: _withCtx(function (_ref3) {
      var slotData = _ref3.slotData;
      return [_createVNode(_component_feather_icon, {
        class: "ml-2 cursor-pointer text-red-50 inline-block",
        icon: "trash-2",
        onClick: function onClick($event) {
          return $options.confirmDelete(slotData);
        }
      }, null, 8 /* PROPS */, ["onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-table", "columns"]))]);
}