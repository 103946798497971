// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs .nav-link[data-v-32e58223] {
  background: #347480;
}

/* Specific styles */
.saveStatus[data-v-32e58223] {
  position: absolute;
  top: 1rem;
  right: 2rem;
  background-color: rgb(230, 230, 230);
  padding: 8px 10px;
  border-radius: 2px;
}
.saveStatus.scroll[data-v-32e58223] {
  position: fixed;
  top: 8rem;
  right: 2rem;
  z-index: 90;
}
.preventOverlap .saveStatus.scroll[data-v-32e58223] {
  right: 4rem !important;
}
.saveStatus.narrow.scroll[data-v-32e58223] {
  right: 44.5rem !important;
}
.preventOverlap .saveStatus.narrow.scroll[data-v-32e58223] {
  right: 39.5rem !important;
}
.wrapper.scroll[data-v-32e58223] {
  position: fixed;
  top: 8rem;
}
.trafficlight[data-v-32e58223] {
  margin-bottom: -0.1rem;
}
h3.compliance-edit--node-title[data-v-32e58223] {
  width: 20vw;
  font-size: 190%;
  margin-bottom: 20px;
}
.main-nav ul > li.complianceProject[data-v-32e58223] {
  margin-left: 100px;
}
.main-nav ul > li.complianceProject ~ .complianceProject[data-v-32e58223] {
  margin-left: inherit;
}
.col-traffic-light[data-v-32e58223] {
  float: left;
  max-width: 5%;
}
.col-traffic-light .traffic-light-color[data-v-32e58223] {
  cursor: pointer;
  line-height: 46px;
}
.new-node-button[data-v-32e58223] {
  font-size: 15px;
}
[data-v-32e58223]  a.node-title {
  font-weight: 600;
}
[data-v-32e58223]  a.node-title .jqtree-title:hover {
  text-decoration: underline;
}
#loader[data-v-32e58223] {
  padding-top: 25vh;
}
[data-v-32e58223]  #mytree li.jqtree-selected * {
  outline: none !important;
}
[data-v-32e58223]  #root .risma-button {
  max-width: 13rem;
  display: inline-block;
  cursor: pointer;
}
[data-v-32e58223]  #mytreebutton > i:before {
  color: #4D4D4D !important;
}
#tree-jquery ul[data-v-32e58223] {
  width: 300px;
}
@keyframes blinker-32e58223 {
50% {
    opacity: 0.2;
}
}
.main-content[data-v-32e58223] {
  width: 95%;
}
.main-content.preventOverlap[data-v-32e58223] {
  margin-left: 225px;
  width: calc(100% - 225px) !important;
}
.main-content.slimnav-margin[data-v-32e58223] {
  margin-left: 70px;
  width: calc(100% - 70px) !important;
}
#mytree[data-v-32e58223] {
  font-size: 10px;
}
[data-v-32e58223]::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}
#move-initiative-modal label[data-v-32e58223] {
  cursor: pointer;
}
[data-v-32e58223]  #mytree .dataflow-icon {
  display: none;
}
[data-v-32e58223]  #mytree.dataflow-icon-shown .dataflow-icon {
  transform: rotate(90deg) scale(1, -1);
  display: inline-block;
  right: 55px;
}
[data-v-32e58223]  #mytree .traffic {
  height: 1em;
  width: 1em;
  background-color: #ced4da;
  border: 1px solid #4D4D4D;
  border-radius: 0.6em;
  display: inline-block;
  margin-right: 0.6em;
  position: relative;
  top: -0.5em;
}
#mytree[data-v-32e58223] {
  position: relative;
  overflow: inherit;
  width: 100%;
  margin-top: 2em;
}
[data-v-32e58223]  #mytree .form-inline {
  position: relative;
  top: 0.2em;
  margin: auto 3.2em auto auto;
}
[data-v-32e58223]  #mytree .form-inline:nth-child(2) {
  top: 0;
}
[data-v-32e58223]  #mytree .form-inline .form-control {
  margin-left: 1em;
}
[data-v-32e58223]  #mytree .jqtree-element {
  position: relative;
  line-height: 2em;
  /*font-size: 1.2em;*/
  display: block;
  height: 3.4em;
  padding: 0.6em 3.2em 0.6em 0.6em;
  background-color: rgb(249.4347826087, 249.4347826087, 247.7652173913);
  border: 1px solid #4D4D4D;
  margin: 0.6em 0;
  box-shadow: 0 0 0.8em rgba(0, 0, 0, 0.06);
  border-radius: 0.4em;
}
[data-v-32e58223]  #mytree .jqtree-element.closed {
  border-color: #737373;
}
[data-v-32e58223]  #mytree .jqtree-element.closed[class*=prog] {
  background-image: none;
}
[data-v-32e58223]  #mytree .jqtree-element.closed .jqtree-title,
#mytree .jqtree-element.closed .jqtree-title.jqtree-title-folder[data-v-32e58223] {
  text-decoration: line-through;
  color: #737373;
}
[data-v-32e58223]  #mytree .jqtree-element.closed::before {
  content: "\\f023";
  font-family: FontAwesome;
  font-size: 1.4em;
  color: #BEBEBE;
  top: -0.5em;
  left: 0.2em;
  background-color: transparent;
  border: none;
}
[data-v-32e58223]  #mytree .jqtree-element .jqtree-title,
#mytree .jqtree-element .jqtree-title-folder[data-v-32e58223] {
  margin-left: 0.4em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  width: 16em;
  font-size: 1.2em;
  display: inline-block;
  font-family: "Inter", sans-serif;
}
[data-v-32e58223]  #mytree.dataflow-icon-shown .jqtree-element .jqtree-title,
#mytree.dataflow-icon-shown .jqtree-element .jqtree-title-folder[data-v-32e58223] {
  width: 15em;
}
[data-v-32e58223]  #mytree .leafNodeCounter i {
  margin-right: 10px;
}
[data-v-32e58223]  #mytree .jqtree-element .jqtree-addchild {
  display: block;
  position: absolute;
  left: 23em;
  top: 0.5em;
  height: 2.5em;
  width: 3em;
  margin: 0;
  overflow: hidden;
}
[data-v-32e58223]  #mytree .jqtree-element .jqtree-addchild::before {
  content: "+";
  color: #000000;
  font-size: 2.2em;
  line-height: 1em;
  padding: 0.5em;
  position: relative;
  cursor: pointer;
}
[data-v-32e58223]  #mytree div[class*=prog] {
  background-image: linear-gradient(to right, rgba(70, 180, 195, 0.1), rgba(70, 180, 195, 0.25));
  background-repeat: no-repeat;
  background-size: 0 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog100 {
  background-size: 101% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog95 {
  background-size: 95% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog90 {
  background-size: 90% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog85 {
  background-size: 85% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog80 {
  background-size: 80% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog75 {
  background-size: 75% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog70 {
  background-size: 70% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog65 {
  background-size: 65% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog60 {
  background-size: 60% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog55 {
  background-size: 55% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog50 {
  background-size: 50% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog45 {
  background-size: 45% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog40 {
  background-size: 40% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog35 {
  background-size: 35% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog30 {
  background-size: 30% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog25 {
  background-size: 25% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog20 {
  background-size: 20% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog15 {
  background-size: 15% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog10 {
  background-size: 10% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog5 {
  background-size: 5% 100%;
}
[data-v-32e58223]  #mytree div[class*=prog].prog0 {
  background-size: 0 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap] {
  background-image: linear-gradient(to right, rgba(70, 180, 145, 0.1), rgba(70, 180, 65, 0.25)) !important;
  background-repeat: no-repeat;
  background-size: 0 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap100 {
  background-size: 101% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap95 {
  background-size: 95% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap90 {
  background-size: 90% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap85 {
  background-size: 85% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap80 {
  background-size: 80% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap75 {
  background-size: 75% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap70 {
  background-size: 70% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap65 {
  background-size: 65% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap60 {
  background-size: 60% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap55 {
  background-size: 55% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap50 {
  background-size: 50% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap45 {
  background-size: 45% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap40 {
  background-size: 40% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap35 {
  background-size: 35% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap30 {
  background-size: 30% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap25 {
  background-size: 25% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap20 {
  background-size: 20% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap15 {
  background-size: 15% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap10 {
  background-size: 10% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap5 {
  background-size: 5% 100%;
}
[data-v-32e58223]  #mytree div[class*=progGap].progGap0 {
  background-size: 0 100%;
}
[data-v-32e58223]  #mytree a.critical {
  border: 1px solid #4CB3C0;
  background-color: #4CB3C0;
  color: #FFFFFF;
}
[data-v-32e58223]  #mytree a.critical span.period {
  display: block;
  font-size: 1em;
  margin-left: 2em;
  border: none;
  padding: 0;
}
[data-v-32e58223]  #mytree ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
[data-v-32e58223]  #mytree li ul {
  display: block;
}
[data-v-32e58223]  #mytree li {
  display: block;
  margin-bottom: 4.8em;
  width: 28em;
}
[data-v-32e58223]  #mytree li::before {
  content: "";
}
[data-v-32e58223]  #mytree li.drop-on,
#mytree li:last-child.drop-on[data-v-32e58223] {
  height: 3.4em;
}
[data-v-32e58223]  #mytree li.drop-on div.jqtree-element,
#mytree li:last-child.drop-on div.jqtree-element[data-v-32e58223] {
  overflow: visible;
}
[data-v-32e58223]  #mytree li.drop-on div.jqtree-element::after,
#mytree li:last-child.drop-on div.jqtree-element[data-v-32e58223]::after {
  content: "";
  width: 5em;
  height: 1em;
  display: block;
  position: relative;
  left: 27.3em;
  top: -2.7em;
  border-bottom: 2px dotted #4CB3C0;
  animation: blinker-32e58223 1s linear infinite;
}
[data-v-32e58223]  #mytree li li {
  margin-bottom: 0;
  border: none;
}
[data-v-32e58223]  #mytree li li::before {
  content: "";
  display: block;
  position: absolute;
  width: 2.4em;
  height: 1.7em;
  left: -2.6em;
  border-bottom: 1px dotted #000000;
}
[data-v-32e58223]  #mytree li li:last-child {
  position: relative;
  margin-bottom: 3.2em;
}
[data-v-32e58223]  #mytree li li:last-child::after {
  background-color: white;
  content: "";
  display: block;
  position: absolute;
  width: 2.3em;
  height: -o-calc(100% - 3.3em);
  height: calc(100% - 3.3em);
  left: -3.6em;
  top: 1.7em;
  border: none;
}
[data-v-32e58223]  #mytree .jqtree_common li.drop-on::after,
#mytree .jqtree_common li:last-child.drop-on[data-v-32e58223]::after {
  background-color: rgba(70, 180, 195, 0.2);
  content: "";
  display: block;
  height: 3.4em;
  width: -o-calc(28em - 4px);
  width: calc(28em - 4px);
  border: 2px dotted #4CB3C0;
  left: 33em;
  top: -4em;
  position: relative;
  border-radius: 0.4em;
  animation: blinker-32e58223 1s linear infinite;
}
[data-v-32e58223]  #mytree li li:last-child::before {
  left: -2.6em;
}
[data-v-32e58223]  #mytree li li:only-child {
  margin-bottom: 3.2em;
}
[data-v-32e58223]  #mytree ul ul {
  position: relative;
  left: 27em;
  top: -2em;
  margin-bottom: -4em;
  margin-left: 6em;
}
[data-v-32e58223]  #mytree ul ul::before {
  content: "";
  position: absolute;
  top: -0.3em;
  left: -4.9em;
  border-right: 1px dotted #000000;
  border-top: 1px dotted #000000;
  width: 2.3em;
  height: -o-calc(100% - 1.4em);
  height: calc(100% - 1.4em);
}
[data-v-32e58223]  #mytree .jqtree-cantdrop,
#mytree .jqtree-disabled[data-v-32e58223] {
  opacity: 0.6;
  color: #737373;
  border-color: #737373;
}

/* :deep() #mytree .jqtree-cantdrop.traffic.green::before, #mytree .jqtree-cantdrop.traffic.red::before, #mytree .jqtree-cantdrop.traffic.yellow::before, #mytree .jqtree-cantdrop.traffic::before, #mytree .jqtree-disabled.traffic.green::before, #mytree .jqtree-disabled.traffic.red::before, #mytree .jqtree-disabled.traffic.yellow::before, #mytree .jqtree-disabled.traffic::before {
        border: 1px dotted #827878;
        background-color: transparent
    }

    :deep() #mytree .jqtree-cantdrop.traffic.closed::before, #mytree .jqtree-disabled.traffic.closed::before {
        border: none
    }*/
[data-v-32e58223]  #mytree .jqtree-cantdrop.prog100,
#mytree .jqtree-cantdrop.prog20[data-v-32e58223],
#mytree .jqtree-cantdrop.prog40[data-v-32e58223],
#mytree .jqtree-cantdrop.prog60[data-v-32e58223],
#mytree .jqtree-cantdrop.prog80[data-v-32e58223],
#mytree .jqtree-disabled.prog100[data-v-32e58223],
#mytree .jqtree-disabled.prog20[data-v-32e58223],
#mytree .jqtree-disabled.prog40[data-v-32e58223],
#mytree .jqtree-disabled.prog60[data-v-32e58223],
#mytree .jqtree-disabled.prog80[data-v-32e58223] {
  background: none;
}
[data-v-32e58223]  #mytree .jqtree-cantdrop .jqtree-title,
#mytree .jqtree-cantdrop .jqtree-title-folder[data-v-32e58223],
#mytree .jqtree-disabled .jqtree-title[data-v-32e58223],
#mytree .jqtree-disabled .jqtree-title-folder[data-v-32e58223] {
  cursor: not-allowed;
}
[data-v-32e58223]  #mytree .jqtree-dragging {
  opacity: 0.4;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: -o-calc(28em - 4px);
  width: calc(28em - 4px);
  line-height: 2em;
  font-size: 1.2em;
  height: 3.4em;
  padding: 0.6em 3.2em 0.6em 0.6em;
  background-color: #BEBEBE;
  border: 2px solid #737373;
  margin: 0.6em 0;
  box-shadow: 0 0 0.8em rgba(77, 77, 77, 0.4);
  border-radius: 0.4em;
}
[data-v-32e58223]  #mytree li.jqtree-ghost:last-child {
  margin-bottom: 7.2em;
}
[data-v-32e58223]  #mytree li.jqtree-ghost::after,[data-v-32e58223]  #mytree li.jqtree-ghost:last-child::after {
  background-color: rgba(70, 180, 195, 0.2);
  content: "";
  display: block;
  height: 3.2em;
  width: -o-calc(100% - 2px);
  width: calc(100% - 2px);
  border: 2px dotted #4CB3C0;
  border-radius: 0.4em;
  animation: blinker-32e58223 1s linear infinite;
}
[data-v-32e58223]  #mytree li.jqtree-ghost:last-child::after {
  left: 0;
  top: 0;
}
[data-v-32e58223]  #mytree li.jqtree-ghost:last-child::before {
  left: -2.6em;
  border-left: 2px dotted #4CB3C0;
  top: -2em;
  height: 3.8em;
  animation: blinker-32e58223 1s linear infinite;
}
[data-v-32e58223]  #mytree li.jqtree-ghost::before {
  left: -2.4em;
  border-bottom: 2px dotted #4CB3C0;
  animation: blinker-32e58223 1s linear infinite;
}
[data-v-32e58223]  #mytree li.jqtree-moving div {
  border: 1px dashed #737373;
  background-color: #FFFFFF;
}
[data-v-32e58223]  #mytree li.jqtree-moving div::before {
  border: #BEBEBE;
}
[data-v-32e58223]  #mytree li.jqtree-moving div div[class*=prog] {
  background-image: none;
}
[data-v-32e58223]  #mytree li.jqtree-moving div li::before {
  background-color: #f0f;
  border-color: #737373;
}
[data-v-32e58223]  #mytree li.jqtree-moving a,
#mytree li.jqtree-moving span[data-v-32e58223] {
  color: #BEBEBE;
}
[data-v-32e58223]  #mytree li.jqtree-moving .jqtree-title {
  outline: none;
}
[data-v-32e58223]  #mytree li.jqtree-moving .traffic.green::before,
#mytree li.jqtree-moving .traffic.red[data-v-32e58223]::before,
#mytree li.jqtree-moving .traffic.yellow[data-v-32e58223]::before,
#mytree li.jqtree-moving .traffic[data-v-32e58223]::before {
  border: 1px dotted #BEBEBE;
  background-color: transparent;
}
[data-v-32e58223]  #mytree li.jqtree-moving div.prog100,
#mytree li.jqtree-moving div.prog20[data-v-32e58223],
#mytree li.jqtree-moving div.prog40[data-v-32e58223],
#mytree li.jqtree-moving div.prog60[data-v-32e58223],
#mytree li.jqtree-moving div.prog80[data-v-32e58223] {
  background: none;
}
[data-v-32e58223]  #mytree li.jqtree-moving a.jqtree-toggler {
  display: none;
}
[data-v-32e58223]  #mytree a.jqtree-toggler {
  display: block;
  position: absolute;
  cursor: pointer;
  left: 26em;
  top: 0.7em;
  height: 2.5em;
  width: 2em;
  margin: 0;
  overflow: hidden;
}
[data-v-32e58223]  #mytree a.jqtree-toggler:hover {
  text-decoration: none !important;
}
[data-v-32e58223]  #mytree .folder-icon {
  color: #737373;
  vertical-align: top;
}
#tree-jquery[data-v-32e58223] {
  padding-bottom: 8em;
  /*    transform: scale(0.7);*/
}
[data-v-32e58223]  #mytree ul.jqtree-tree li.jqtree-closed > ul.jqtree_common {
  display: none !important;
}
[data-v-32e58223]  #mytree ul.jqtree-tree li > ul.jqtree_common:empty {
  display: none;
}
#toggle-levels[data-v-32e58223] {
  position: absolute;
  top: 3em;
  right: 23em;
  font-size: 1.5em;
  font-weight: bold;
}
#show-tree-level-compliance[data-v-32e58223] {
  display: inline-block;
  width: auto;
  margin-left: 10px;
}
h3.noResults[data-v-32e58223] {
  margin-top: 25vh;
  font-size: 2em;
  font-weight: bold;
}
[data-v-32e58223]  div.delete {
  color: darkred;
}
#canvas[data-v-32e58223] {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
