import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, Transition as _Transition } from "vue";
var _hoisted_1 = {
  class: "ad-settings"
};
var _hoisted_2 = {
  class: "flex mt-3 items-center"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "flex mb-3 items-center"
};
var _hoisted_5 = {
  class: "min-w-250px mr-3"
};
var _hoisted_6 = {
  class: "flex mb-3 items-center"
};
var _hoisted_7 = {
  class: "min-w-250px mr-3"
};
var _hoisted_8 = {
  class: "flex mb-3 items-center"
};
var _hoisted_9 = {
  class: "min-w-250px mr-3"
};
var _hoisted_10 = {
  class: "flex-grow max-w-500px"
};
var _hoisted_11 = {
  class: "flex mb-3 items-center"
};
var _hoisted_12 = {
  class: "min-w-250px mr-3"
};
var _hoisted_13 = {
  class: "flex-grow max-w-500px"
};
var _hoisted_14 = {
  key: 0,
  class: "text-sm text-yellow-610 py-1"
};
var _hoisted_15 = {
  class: "flex mb-3 items-center"
};
var _hoisted_16 = {
  class: "min-w-250px mr-3"
};
var _hoisted_17 = {
  class: "flex-grow max-w-500px"
};
var _hoisted_18 = {
  class: "flex mb-3 items-center"
};
var _hoisted_19 = {
  class: "min-w-250px mr-3"
};
var _hoisted_20 = {
  class: "flex-grow max-w-500px"
};
var _hoisted_21 = {
  class: "flex"
};
var _hoisted_22 = {
  key: 0,
  class: "text-sm text-yellow-610 py-1"
};
var _hoisted_23 = {
  class: "flex mb-3 items-center"
};
var _hoisted_24 = {
  class: "min-w-250px mr-3"
};
var _hoisted_25 = {
  class: "flex-grow max-w-500px"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.isSubmitSuccessNotificationShown ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "success"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString($data.translate.settingsAreSaved), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [$data.errors.length ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    closeable: false,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, function (error, i) {
        return _openBlock(), _createElementBlock("p", {
          key: i
        }, _toDisplayString(error.error), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.activateAdLogin,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", null, [_createVNode(_component_checkboxes, {
    "checkbox-size": 20,
    options: $data.adAuthenticationMode,
    onUpdated: _cache[0] || (_cache[0] = function ($event) {
      return $options.handleChange('adAuthenticationMode', $event.isChecked ? 'oidc' : 'local');
    })
  }, null, 8 /* PROPS */, ["options"])])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.useSecurityGroups,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", null, [_createVNode(_component_checkboxes, {
    "checkbox-size": 20,
    options: !$data.ignoreAdPermissions,
    onUpdated: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleChange('ignoreAdPermissions', !$event.isChecked);
    })
  }, null, 8 /* PROPS */, ["options"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: "Tenant ID",
    type: "medium"
  })]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_input_field, {
    modelValue: $data.tenantId,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.tenantId = $event;
    }),
    placeholder: $data.translate.enterTenantId,
    type: "text",
    onUpdated: _cache[3] || (_cache[3] = function (value) {
      return $options.handleChange('tenantId', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_title, {
    title: $data.translate.endpoint,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_input_field, {
    modelValue: $data.endpoint,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.endpoint = $event;
    }),
    placeholder: $data.translate.enterAnEndpoint,
    type: "text",
    onUpdated: _cache[5] || (_cache[5] = function (value) {
      return $options.handleChange('endpoint', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"]), !$options.isPreferredEndpoint ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString($data.translate.clientUrlForAzureAdShouldBeFormat), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_risma_title, {
    title: $data.translate.clientId,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_input_field, {
    modelValue: $data.clientId,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
      return $data.clientId = $event;
    }),
    placeholder: $data.translate.enterAClientId,
    type: "text",
    onUpdated: _cache[7] || (_cache[7] = function (value) {
      return $options.handleChange('clientId', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_risma_title, {
    title: "Client secret value",
    type: "medium"
  })]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_input_field, {
    ref: "clientSecretInput",
    modelValue: $data.clientSecret,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
      return $data.clientSecret = $event;
    }),
    placeholder: "Enter a client secret value",
    type: "text",
    class: "flex-grow",
    enabled: !$data.isClientSecretDisabled
  }, null, 8 /* PROPS */, ["modelValue", "enabled"]), $data.isClientSecretDisabled ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: "edit",
    class: "cursor-pointer stroke-2 text-blue-750 ml-4 h-10 hover:opacity-75",
    onClick: $options.editClientSecret
  }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    class: "cursor-pointer stroke-2 text-red-50 h-10 ml-4 hover:opacity-75",
    onClick: $options.cancelUpdateClientSecret
  }, null, 8 /* PROPS */, ["onClick"]), _createVNode(_component_feather_icon, {
    icon: "check",
    class: "cursor-pointer stroke-2 text-green-50 h-10 ml-4 hover:opacity-75",
    onClick: $options.saveClientSecret
  }, null, 8 /* PROPS */, ["onClick"])], 64 /* STABLE_FRAGMENT */))]), _createVNode(_Transition, null, {
    default: _withCtx(function () {
      return [!$options.isClientSecretValid ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString($data.translate.theEnteredSecretHasTheFormatOfAnObjectId), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })])]), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createVNode(_component_risma_title, {
    title: $data.translate.advancedEmailMapping,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_25, [_createVNode(_component_input_field, {
    modelValue: $data.appOidcEmail,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.appOidcEmail = $event;
    }),
    placeholder: $data.translate.enterAnEmailMappingSetToUpnForDefault,
    type: "text",
    onUpdated: _cache[10] || (_cache[10] = function (value) {
      return $options.handleChange('appOidcEmail', value);
    })
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])]), _createElementVNode("div", null, [_createVNode(_component_risma_button, {
    text: $data.translate.update,
    type: "save",
    class: "mt-2",
    disabled: $data.isSubmitBtnDisabled,
    onClick: $options.update
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])]);
}