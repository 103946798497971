import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_contact_card = _resolveComponent("contact-card");
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.informationAssets, function (type, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(type, function (asset, index) {
      return _openBlock(), _createElementBlock("div", {
        key: index
      }, [_createVNode(_component_contact_card, {
        type: key,
        info: asset,
        "is-cut-version": $props.isCutVersion,
        class: "mb-2",
        onUpdateFromPreviewMode: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('updateFromPreviewMode', $event);
        }),
        onDismissModal: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('dismissModal');
        })
      }, null, 8 /* PROPS */, ["type", "info", "is-cut-version"])]);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))]);
}