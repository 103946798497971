import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "vertical-menu-item flex"
};
var _hoisted_2 = {
  class: "flex-grow w-full"
};
var _hoisted_3 = {
  class: "flex"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  class: "flex"
};
var _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.item.color ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    style: _normalizeStyle({
      backgroundColor: $props.item.color
    }),
    class: "vertical-menu-item-marker"
  }, null, 4 /* STYLE */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [!$options.isPreview ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    exact: $props.exactMatch,
    to: $props.item.routeName ? {
      name: $props.item.routeName
    } : $props.item.path,
    class: "vertical-menu-link"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_3, [$props.item.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: $props.item.icon,
        style: {
          minWidth: '20px'
        },
        class: "transform stroke-2 mr-2"
      }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
        class: "w-full break-words",
        innerHTML: $props.item.title
      }, null, 8 /* PROPS */, _hoisted_4)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["exact", "to"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(['vertical-menu-link cursor-pointer', {
      'font-semibold bg-gray-250': $props.selectedId === $props.item.id
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeItem', $props.item.id);
    })
  }, [_createElementVNode("span", _hoisted_5, [$props.item.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: $props.item.icon,
    style: {
      minWidth: '20px'
    },
    class: "transform stroke-2 mr-2"
  }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "w-full break-words",
    innerHTML: $props.item.title
  }, null, 8 /* PROPS */, _hoisted_6)])], 2 /* CLASS */))]), $props.removable ? (_openBlock(), _createElementBlock("span", {
    key: 1,
    class: "flex p-4 cursor-pointer",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.remove($props.item.id);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "x",
    class: "transform text-red-50 stroke-2 m-auto"
  })])) : _createCommentVNode("v-if", true)]);
}