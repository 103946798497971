function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import { getQuestionDashboard } from '@/api/compliance/dashboard';
import Pagination from '@/components/Atoms/Pagination';
import InputField from '@/components/Atoms/Inputs/InputField';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaBarChart from '@/components/Molecules/Charts/RismaBarChart';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSelectorBar from '@/components/Molecules/RismaSelectorBar';
import Notification from '@/components/Molecules/Notification';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import { ComplianceDashboardMixin } from '@/components/Pages/Compliance/ComplianceDashboardMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaSelectorBarMixin } from '@/mixins/RismaSelectorBarMixin';
import { Colors } from '@/Colors';
export default {
  name: 'QuestionDashboard',
  components: {
    Pagination: Pagination,
    InputField: InputField,
    RismaBarChart: RismaBarChart,
    RismaDoughnutChart: RismaDoughnutChart,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    FeatherIcon: FeatherIcon,
    RismaSelectorBar: RismaSelectorBar,
    Notification: Notification,
    PreviewModalLink: PreviewModalLink,
    Checkboxes: Checkboxes
  },
  mixins: [RaciTitleMixin, RismaSelectorBarMixin, ComplianceDashboardMixin],
  props: {
    projectId: {
      type: [String, Number],
      required: true
    }
  },
  setup: function setup(props) {
    return {
      checkboxState: getLocalStorageComputed("question_dashboard_filters_".concat(props.projectId, "_checkbox"), 'false')
    };
  },
  data: function data() {
    return {
      dashboardNodes: [],
      headlines: [],
      overview: [],
      questionsByHeadline: [],
      searchField: '',
      paginationIndexes: null,
      headingClasses: 'text-white bg-blue-250 py-2 px-3',
      pieColors: [Colors.unanswered, Colors.answered, Colors.na],
      localStorageKey: "question_dashboard_filters_".concat(this.projectId),
      latestFilterString: '',
      translate: getTranslate['QuestionDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    displayQuestionsCheckboxes: function displayQuestionsCheckboxes() {
      return {
        checked: this.checkboxState === 'true',
        label: this.translate.displayOnlyDefaultQuestions
      };
    },
    questionsDomain: function questionsDomain() {
      return {
        answered: this.translate.answeredQuestions,
        unanswered: this.translate.unansweredQuestions
      };
    },
    selectors: function selectors() {
      return [{
        list: 'nodes',
        label: this.translate.nodes,
        preSelected: this.predefinedNodesFilter,
        toggle: {
          title: this.translate.includeUnderlyingNodes,
          option: this.includeChildToggleOption,
          event: this.onIncludeChildNodesToggle
        }
      }, {
        list: 'organisations',
        label: this.translate.organisations,
        preSelected: this.predefinedOrganisationsFilter
      }, {
        list: 'users',
        label: this.responsibleTitle(true),
        preSelected: this.predefinedUsersFilter
      }, {
        list: 'tags',
        label: this.translate.tags,
        preSelected: this.predefinedTagsFilter
      }, {
        list: 'frameworks',
        label: this.translate.frameworks,
        preSelected: this.predefinedFrameworksFilter,
        toggle: {
          title: this.translate.includeUnderlyingFrameworks,
          option: this.includeFrameworkChildToggleOption,
          event: this.onIncludeFrameworksChildNodesToggle
        }
      }];
    },
    pieChartData: function pieChartData() {
      var _this = this;
      var labels = [];
      var data = [];
      var backgroundColor = [];
      var total = 0;
      this.overview.forEach(function (entry) {
        if (entry.title.toLowerCase() === 'questions total') return;
        labels.push(_this.$trans(entry.title + ' questions'));
        data.push(entry.value);
        backgroundColor.push(_this.pieColors[data.length - 1]);
        total += +entry.value;
      });
      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgroundColor,
          total: total
        }]
      };
    },
    stackedChartData: function stackedChartData() {
      var _this2 = this;
      var data = {
        labels: this.questionsByHeadline.map(function (question) {
          return question.area;
        }),
        datasets: []
      };
      Object.keys(this.questionsDomain).forEach(function (key) {
        var datasetObject = {
          label: _this2.questionsDomain[key],
          backgroundColor: Colors[key],
          data: []
        };
        _this2.questionsByHeadline.forEach(function (question) {
          datasetObject.data.push(question[key] || 0);
        });
        data.datasets.push(datasetObject);
      });
      return data;
    },
    filteredNodes: function filteredNodes() {
      if (this.searchField) {
        var searchFieldLowerCase = this.searchField.toLowerCase();
        return this.dashboardNodes.filter(function (node) {
          return node.title.toLowerCase().includes(searchFieldLowerCase) || node.questionnaire_title.toLowerCase().includes(searchFieldLowerCase);
        });
      }
      return this.dashboardNodes;
    },
    dashboardNodesSlice: function dashboardNodesSlice() {
      return this.paginationIndexes ? this.filteredNodes.slice(this.paginationIndexes.start, this.paginationIndexes.end + 1) : [];
    }
  }),
  mounted: function mounted() {
    localStorage.setItem("compliance-dashboard-tab-".concat(this.projectId), 'questions');
  },
  methods: {
    updateCheckbox: function updateCheckbox(_ref) {
      var isChecked = _ref.isChecked;
      this.checkboxState = isChecked + '';
      return this.loadDashboardData(this.latestFilterString);
    },
    loadDashboardData: function loadDashboardData(filterString) {
      var _this3 = this;
      this.isLoading = true;
      this.latestFilterString = filterString;
      var nodesFilterString = 'filter[onlyActivityTypesWithQuestionnaire]=1';
      if (filterString) nodesFilterString += '&' + filterString;
      if (this.displayQuestionsCheckboxes.checked) nodesFilterString += '&filter[article30]=1';
      return getQuestionDashboard(this.projectId, nodesFilterString).then(function (response) {
        if (_this3.currentFilter !== filterString) return; // Filter has changed since the promise was made.
        var _response$data = response.data,
          overview = _response$data.overview,
          headlines = _response$data.headlines,
          nodes = _response$data.nodes,
          questionsByHeadline = _response$data.questionsByHeadline;
        _this3.overview = overview;
        _this3.headlines = headlines;
        _this3.dashboardNodes = nodes;
        _this3.questionsByHeadline = questionsByHeadline;
        _this3.isLoading = false;
      }).catch(function (error) {
        return _this3.handleDashboardError(error);
      });
    },
    getHeadlineStatsForNode: function getHeadlineStatsForNode(node) {
      var stats = [];
      this.headlines.forEach(function (headline) {
        var nodeStats = node.headline_stats[headline.area] || {};
        stats.push(_objectSpread({
          answered: 0,
          unanswered: 0
        }, nodeStats));
      });
      return stats;
    },
    onDismissPreview: function onDismissPreview() {
      this.fetchData(localStorage.getItem(this.localStorageKey));
    },
    handleShownList: function handleShownList(indexes) {
      this.paginationIndexes = indexes;
    }
  }
};