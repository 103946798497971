import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_feather_icon, {
    icon: "chevron-left",
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.setYear(-1);
    })
  }), _createVNode(_component_risma_title, {
    class: "mx-4",
    title: $props.modelValue + ''
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "chevron-right",
    class: "cursor-pointer",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.setYear(1);
    })
  })]);
}