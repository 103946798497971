// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table[data-v-bd3a79ac] {
  border-spacing: 1rem;
}
.w-40-rem[data-v-bd3a79ac] {
  width: 40rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
