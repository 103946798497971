import i18n from '@/i18n';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
var generalEmits = [{
  title: i18n.t('Add sub node'),
  icon: 'plus',
  emit: 'addSubNode'
}, {
  title: i18n.t('Duplicate node'),
  icon: 'file',
  emit: 'duplicateNode'
}, {
  title: i18n.t('Duplicate node with sub nodes'),
  icon: 'file-plus',
  emit: 'duplicateNodeWithSubNodes'
}, {
  title: i18n.t('Duplicate node with sub nodes and data'),
  icon: 'file-plus',
  emit: 'duplicateNodeWithSubNodesAndData'
}, {
  title: i18n.t('Delete node'),
  icon: 'x',
  emit: 'deleteNode'
}];
export var getProcessMiniTreeEmits = function getProcessMiniTreeEmits() {
  return generalEmits;
};
export var getComplianceMiniTreeEmits = function getComplianceMiniTreeEmits(projectType) {
  var reportRights = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (projectType === ProjectTypes.CSRD) {
    return reportRights ? [{
      title: i18n.t('Questionnaire report'),
      icon: 'list',
      emit: 'createArticleReport'
    }] : [];
  }
  if (projectType === ObjectTypes.FRAMEWORK) {
    return [{
      title: i18n.t('Create underlying framework'),
      icon: 'plus',
      maxLevel: 9,
      emit: 'addSubNode'
    }, {
      title: i18n.t('Disable') + ' ' + i18n.t('Framework'),
      altTitle: i18n.t('Enable') + ' ' + i18n.t('Framework'),
      altTitleFlag: 'enabled',
      flagCheckField: 'inUse',
      parentEnabledOnDisabledCheck: true,
      //filters data that has parent disabled and are disabled
      icon: 'eye-off',
      altIcon: 'eye',
      emit: 'edit:enabled'
    }, {
      title: i18n.t('Edit framework title'),
      icon: 'edit',
      emit: 'edit:title'
    }, {
      title: i18n.t('Delete framework'),
      icon: 'x',
      emit: 'deleteNode',
      flagCheckField: 'builtin'
    }];
  }
  var result = [].concat(generalEmits);
  if (reportRights) {
    result.splice(1, 0, {
      title: i18n.t('Create Article 30 report'),
      icon: 'list',
      emit: 'createArticleReport'
    });
  }
  return result.filter(Boolean);
};
export var generateTreeMenuItemList = function generateTreeMenuItemList(settings, transFunction) {
  return [{
    rights: settings.hasLinks,
    click: {
      type: 'add link',
      data: {
        uniqueTreeId: settings.uniqueTreeId,
        linkType: settings.linkType
      }
    },
    icon: 'plus',
    text: transFunction('Add link')
  }, {
    rights: !settings.isLink && settings.updateNodeFunction,
    click: {
      type: 'update'
    },
    icon: 'edit-2',
    text: settings.updateNodeLabel || transFunction('Update node')
  }, {
    rights: !settings.isLink && !settings.disableAddAction && !settings.isProcessNode,
    click: {
      type: 'add'
    },
    icon: 'plus',
    text: settings.addSubNodeLabel || transFunction('Add sub node')
  }, {
    rights: !settings.isLink && settings.isProcessNode && settings.copy,
    click: {
      type: 'addProcessNode'
    },
    icon: 'plus',
    text: transFunction('Add sub node')
  }, {
    rights: !settings.isLink && settings.createReportOption,
    click: {
      type: 'report option'
    },
    icon: 'list',
    text: settings.isGdpr ? transFunction('Create Article 30 report') : transFunction('Questionnaire report')
  }, {
    rights: !settings.isLink && settings.showMemorandumReportLink,
    click: {
      type: 'memorandum report'
    },
    icon: 'list',
    text: transFunction('Memorandum report')
  }, {
    rights: !settings.isLink && settings.copy && settings.copySettings,
    click: {
      type: 'duplicate'
    },
    icon: 'file',
    text: transFunction('Duplicate node')
  }, {
    rights: !settings.isLink && settings.copy && settings.copySettings,
    click: {
      type: 'duplicate with sub items'
    },
    icon: 'file-plus',
    text: transFunction('Duplicate node with sub nodes')
  }, {
    rights: !settings.isLink && settings.copy && settings.copyData && settings.copySettings,
    click: {
      type: 'duplicate with sub items and data'
    },
    icon: 'file-plus',
    text: transFunction('Duplicate node with sub nodes and data')
  }, {
    rights: !settings.isLink && settings.deleteNodeOption,
    click: {
      type: 'delete'
    },
    icon: 'x',
    text: settings.deleteNodeLabel || transFunction('Delete node')
  }, {
    rights: !settings.isLink && settings.templateSubmenuItems && settings.templateSubmenuItems.length,
    isTemplateMenu: true,
    icon: 'plus-circle',
    text: transFunction('Add from template'),
    items: settings.templateSubmenuItems
  }];
};