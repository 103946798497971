import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  key: 0,
  class: "absolute top-0 bottom-0 right-0 left-0 z-10 cursor-not-allowed"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_asset_choice = _resolveComponent("single-asset-choice");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("v-if", true), _createVNode(_component_single_asset_choice, {
    type: $data.AssetsTypes.PROCESSORS,
    selected: $props.selectedAssets.processors,
    "read-only": $props.readOnly || !_ctx.isDataProcessorsEnabled,
    assets: $props.assets.processors,
    partial: true,
    onNewAsset: _cache[0] || (_cache[0] = function ($event) {
      return $options.addNewAsset($event.data, $event.type);
    }),
    onForceUpdate: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('forceUpdate');
    }),
    onAnswered: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('answered', $event);
    })
  }, null, 8 /* PROPS */, ["type", "selected", "read-only", "assets"]), _createVNode(_component_single_asset_choice, {
    type: $data.AssetsTypes.SYSTEMS,
    selected: $props.selectedAssets.systems,
    "read-only": $props.readOnly || !_ctx.isSystemsEnabled,
    assets: $props.assets.systems,
    partial: true,
    onNewAsset: _cache[3] || (_cache[3] = function ($event) {
      return $options.addNewAsset($event.data, $event.type);
    }),
    onForceUpdate: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('forceUpdate');
    }),
    onAnswered: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('answered', $event);
    })
  }, null, 8 /* PROPS */, ["type", "selected", "read-only", "assets"])]);
}