function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import CustomAxisTable from '@/components/Molecules/Risk/CustomAxisTable';
import { RiskBaseModelProps } from '@/constants/risks/RiskBaseModelProps';
import * as Utils from '@/utils/Utils';
export default {
  name: 'RiskErmAssesment',
  introduction: 'Risk assessment on ERM DEP',
  description: 'Risk assessment wrapper on DEP',
  token: "<risk-erm-assessment\n        :risk=\"activity\"\n        :scoring=\"scoring\"\n     />",
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    TinyMceBox: TinyMceBox,
    CustomAxisTable: CustomAxisTable
  },
  props: {
    risk: {
      type: Object,
      required: false,
      default: function _default() {},
      note: 'Current risk data'
    },
    scoring: {
      type: Object,
      required: true,
      default: function _default() {},
      note: 'Scoring data'
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    }
  },
  emits: ['updateCustom', 'changed'],
  data: function data() {
    return {
      RiskBaseModelProps: RiskBaseModelProps,
      comments: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.LIKELIHOOD_COMMENT, this.risk[RiskBaseModelProps.LIKELIHOOD_COMMENT] || ''), RiskBaseModelProps.IMPACT_COMMENT, this.risk[RiskBaseModelProps.IMPACT_COMMENT] || ''), RiskBaseModelProps.Z_AXIS_COMMENT, this.risk[RiskBaseModelProps.Z_AXIS_COMMENT] || ''), RiskBaseModelProps.IMPACT_OTHER1_COMMENT, this.risk[RiskBaseModelProps.IMPACT_OTHER1_COMMENT] || ''), RiskBaseModelProps.IMPACT_OTHER2_COMMENT, this.risk[RiskBaseModelProps.IMPACT_OTHER2_COMMENT] || ''),
      maxlength: 999,
      translate: getTranslate['RiskErmAssesment']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    probabilitiesOptions: function probabilitiesOptions() {
      var _this = this;
      return this.scoring.likelihood_labels.map(function (item) {
        return {
          label: item.likelihood_number + ': ' + _this.scoring.likelihood_values[item.likelihood_number * 1] + (item.description !== '' ? ' (' + item.description + ')' : ''),
          value: item.likelihood_number * 1
        };
      });
    },
    impactOptions: function impactOptions() {
      var options = [];
      Object.values(this.scoring.impact_labels).forEach(function (item, index) {
        options.push({
          label: index + 1 + ': ' + item,
          value: index + 1
        });
      });
      return options;
    },
    impactOther1Options: function impactOther1Options() {
      var options = [];
      Object.values(this.scoring.impactOther1Labels).forEach(function (item, index) {
        options.push({
          label: item,
          value: index + 1
        });
      });
      return options;
    },
    impactOther2Options: function impactOther2Options() {
      var options = [];
      Object.values(this.scoring.impactOther2Labels).forEach(function (item, index) {
        options.push({
          label: item,
          value: index + 1
        });
      });
      return options;
    },
    zAxisOptions: function zAxisOptions() {
      var options = [];
      if (this.scoring.z_axis_values === undefined) {
        return options;
      }
      Object.values(this.scoring.z_axis_values).forEach(function (item, index) {
        options.push({
          label: index + 1 + ': ' + item.value,
          value: index + 1
        });
      });
      return options;
    },
    riskOptions: function riskOptions() {
      return {
        likelihood_score: this.risk.likelihood_score,
        impact_score: this.risk.impact_score,
        impact_other1_score: this.risk.impact_other1_score,
        impact_other2_score: this.risk.impact_other2_score,
        z_axis_score: this.risk.z_axis_score
      };
    },
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return !!this.getSettingValue('feature.risk_comments_field_on_axis');
    }
  }),
  methods: {
    onCommentChanged: function onCommentChanged(key, e) {
      this.comments[key] = Utils.setMaxlength(e, this.maxlength);
      this.$emit('changed', {
        property: key,
        value: e
      });
    },
    onStateChanged: function onStateChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.$emit('changed', e);
    },
    getAxisRows: function getAxisRows(data) {
      var _this2 = this;
      data.forEach(function (item) {
        var res = _this2.risk.customFieldValues.find(function (field) {
          return field.fieldId === item.id;
        });
        item.value = res !== undefined ? res.value : 0;
      });
      return data;
    }
  }
};