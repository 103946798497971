import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "table-admin-types"
};
var _hoisted_2 = {
  class: "thead-small"
};
var _hoisted_3 = {
  class: "thead-small"
};
var _hoisted_4 = {
  class: "table-row-custom-field"
};
var _hoisted_5 = {
  class: "font-semibold"
};
var _hoisted_6 = {
  colspan: "2",
  class: "table-radio-buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_draggable = _resolveComponent("draggable");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_collapsible_panel, {
    class: "items-group"
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.optionalFields,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("table", _hoisted_1, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_cache[0] || (_cache[0] = _createElementVNode("th", null, null, -1 /* HOISTED */)), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
        title: $data.translate.title,
        truncate: false,
        type: "small",
        class: "text-left"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_2, [_createVNode(_component_risma_title, {
        title: $data.translate.dontShow,
        truncate: false,
        type: "small",
        class: "text-left"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_3, [_createVNode(_component_risma_title, {
        title: $data.translate.active,
        type: "small",
        class: "text-left"
      }, null, 8 /* PROPS */, ["title"])])])]), _createVNode(_component_draggable, {
        list: $props.optionalFields,
        tag: "tbody",
        "component-data": {
          type: 'transition'
        },
        handle: ".drag-icon",
        "item-key": "id",
        onChange: $options.moveItem
      }, {
        item: _withCtx(function (_ref) {
          var element = _ref.element;
          return [_createElementVNode("tr", _hoisted_4, [_createElementVNode("td", null, [_createVNode(_component_drag_and_drop_icon, {
            class: "drag-icon"
          })]), _createElementVNode("td", _hoisted_5, _toDisplayString(element.label), 1 /* TEXT */), _createElementVNode("td", _hoisted_6, [_createVNode(_component_radio_buttons, {
            "model-value": $options.radioBtnValuesMap[element.value],
            class: "grid grid-cols-2",
            disabled: $options.radioBtnDisabledMap[element.value],
            "no-unchecked-value": true,
            options: $options.optionalFieldsOptions,
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              return $options.onChangeRadio($event, element.value);
            }
          }, null, 8 /* PROPS */, ["model-value", "disabled", "options", "onUpdate:modelValue"])])])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["list", "onChange"])])];
    }),
    _: 1 /* STABLE */
  })]);
}