import i18n from '@/i18n';
import { getTitlePlaceholder } from '@/utils/repetativeTranslations';
export var getTranslate = {
  AddContactPersonModal: function AddContactPersonModal() {
    return {
      addContactPerson: i18n.t('Add contact person'),
      name: i18n.t('Name'),
      title: i18n.t('Title'),
      phone: i18n.t('Phone'),
      contractParty: i18n.t('Contract party'),
      choose: i18n.t('Choose')
    };
  },
  AddContractPartyModal: function AddContractPartyModal() {
    return {
      addContractParty: i18n.t('Add contract party'),
      toSearchInSwedishOrNorwegianRegisterUseFo: i18n.t('To search in Swedish, Norwegian or Finnish register, use following format: SE-12345678 / SE 12345678 or NO-12345678 / NO 12345678 or FI-12345678 / FI 12345678'),
      nameOrTaxNo: i18n.t('Name or tax no.'),
      search: i18n.t('Search'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      vatNumber: i18n.t('VAT number'),
      contractPartyAlreadyAttachedToTheCurrentCon: i18n.t('Contract party already attached to the current contract'),
      noResultsFound: i18n.t('No results found! Did you enter valid VAT number or company name?'),
      searchFieldIsEmpty: i18n.t('Search field is empty. Please enter a search term.')
    };
  },
  ContractAdd: function ContractAdd() {
    return {
      newContract: i18n.t('New Contract'),
      create: i18n.t('Create'),
      cancel: i18n.t('Cancel'),
      title: i18n.t('Title'),
      activityType: i18n.t('Activity type'),
      choose: i18n.t('Choose'),
      chooseActivityType: i18n.t('Choose activity type'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      createContract: i18n.t('Create contract'),
      contractType: i18n.t('Contract type'),
      enterTitle: getTitlePlaceholder()
    };
  },
  EditAttachment: function EditAttachment() {
    return {
      apply: i18n.t('Apply'),
      cancel: i18n.t('Cancel'),
      pleaseFillLinkToFieldsCorrectly: i18n.t('Please fill Link to fields correctly'),
      title: i18n.t('Title'),
      type: i18n.t('Type'),
      chooseType: i18n.t('Choose type'),
      linkTo: i18n.t('Link to'),
      insertLink: i18n.t('Insert link'),
      insertLinkTitle: i18n.t('Insert link title'),
      titleCannotBeEmpty: i18n.t('Title cannot be empty'),
      enterTitle: getTitlePlaceholder()
    };
  },
  ContractsPayment: function ContractsPayment() {
    return {
      currency: i18n.t('Currency'),
      year: i18n.t('Year'),
      january: i18n.t('January'),
      february: i18n.t('February'),
      march: i18n.t('March'),
      april: i18n.t('April'),
      may: i18n.t('May'),
      june: i18n.t('June'),
      july: i18n.t('July'),
      august: i18n.t('August'),
      september: i18n.t('September'),
      october: i18n.t('October'),
      november: i18n.t('November'),
      december: i18n.t('December')
    };
  },
  FinancialDefinitionsEditModal: function FinancialDefinitionsEditModal() {
    return {
      cancel: i18n.t('Cancel'),
      save: i18n.t('Save'),
      typeOfContractualArrangement: i18n.t('Type of contractual arrangement'),
      sensitivenessOfTheDatStored: i18n.t('Sensitiveness of the data stored by the ICT third-party service provider'),
      impactOfDiscontinuingTheFunction: i18n.t('Impact of discontinuing the function'),
      substitutabilityOfTheIct: i18n.t('Substitutability of the ICT third-party service provider'),
      possibilityOfReintegrationOf: i18n.t('Possibility of reintegration of the contracted ICT service'),
      impactOfDiscontinuingTheIctServices: i18n.t('Impact of discontinuing the ICT services'),
      definitionsFromEntitiesMakingUse: i18n.t('Definitions from entities making use of the ICT Services'),
      standalone: i18n.t('Standalone'),
      overarching: i18n.t('Overarching'),
      isSubsequentOrAssociated: i18n.t('Subsequent or associated'),
      low: i18n.t('Low'),
      medium: i18n.t('Medium'),
      high: i18n.t('High'),
      notSubstitutable: i18n.t('Not substitutable'),
      highlyComplexSubstitutability: i18n.t('Highly complex substitutability'),
      mediumComplexityInTerms: i18n.t('Medium complexity in terms of substitutability'),
      easilySubstitutable: i18n.t('Easily substitutable'),
      easy: i18n.t('Easy'),
      difficult: i18n.t('Difficult'),
      highly: i18n.t('Highly complex')
    };
  },
  AddContractPartyModalExtended: function AddContractPartyModalExtended() {
    return {
      companyName: i18n.t('Company name'),
      search: i18n.t('Search'),
      name: i18n.t('Name'),
      address: i18n.t('Address'),
      city: i18n.t('City'),
      vatNumber: i18n.t('Vat number'),
      add: i18n.t('Add'),
      noResultsFound: i18n.t('No results found'),
      clickHere: i18n.t('Click here'),
      ifYouWish: i18n.t('if you wish to create a new company'),
      pleaseSelectOne: i18n.t('Please select one') + ' ' + i18n.t('Contract party').toLowerCase(),
      branches: i18n.t('branches'),
      subsidiaries: i18n.t('subsidiaries'),
      addContractParty: i18n.t('Add contract party'),
      addSub: i18n.t('Add subcompany for'),
      addSubcompanies: i18n.t('Add subcompanies'),
      addSubcontractor: i18n.t('Add subcontractor'),
      external: i18n.t('External'),
      internal: i18n.t('Internal'),
      doesNotHaveAnyBranches: function doesNotHaveAnyBranches(companyName) {
        return i18n.t('%s does not have any branches or subsidiaries to include in the contract', [companyName]);
      },
      copyDataToSubcompany: i18n.t('Copy data to subcompany'),
      doesNotHaveSubcompanies: function doesNotHaveSubcompanies(companyName) {
        return i18n.t('%s does not have any subcompanies to copy this section', [companyName]);
      },
      signee: i18n.t('Signee'),
      usingPart: i18n.t('Using part'),
      payingPart: i18n.t('Paying part'),
      choose: i18n.t('Choose')
    };
  },
  ContractPartyInternalPreview: function ContractPartyInternalPreview() {
    return {
      vatNumber: i18n.t('VAT number'),
      parentCompany: i18n.t('Parent company'),
      associationToParent: i18n.t('Relation to parent company'),
      phone: i18n.t('Phone'),
      email: i18n.t('Email'),
      lei: i18n.t('LEI'),
      address: i18n.t('Address'),
      zipcode: i18n.t('Zipcode'),
      city: i18n.t('City'),
      countryCodeCountry: i18n.t('Country code/Country'),
      addSection: i18n.t('Add section'),
      deleteSection: i18n.t('Delete section'),
      areYouSureYouWantToDelete: function areYouSureYouWantToDelete(sectionName) {
        return i18n.t('Are you sure you want to delete %s section?', ["<b>".concat(sectionName, "</b>")]);
      },
      pleaseFillOutAllFields: i18n.t('Please fill out all fields before adding additional sections')
    };
  },
  InternalSectionOverview: function InternalSectionOverview() {
    return {
      ictServiceProvider: i18n.t('ICT Service provider'),
      functionIdentifier: i18n.t('Function identifier'),
      functionName: i18n.t('Function name'),
      typeOfIctServices: i18n.t('Type of ICT services'),
      ictServicesDelivered: i18n.t('The ICT services delivered from'),
      storageOfData: i18n.t('Storage of data'),
      dataLocation: i18n.t('Data location'),
      managementLocation: i18n.t('Data management location'),
      dataSensitiveness: i18n.t('Data sensitiveness'),
      relianceLevel: i18n.t('Reliance level')
    };
  },
  ContractPartyInternalSection: function ContractPartyInternalSection() {
    return {
      addSection: i18n.t('Add section'),
      copyToSubcompany: i18n.t('Copy to subcompany'),
      seeAsList: i18n.t('See as list'),
      backToOverview: i18n.t('Back to overview'),
      deleteSection: i18n.t('Delete section')
    };
  },
  ContractPartyExternalPreview: function ContractPartyExternalPreview() {
    return {
      name: i18n.t('Name'),
      title: i18n.t('Title'),
      phone: i18n.t('Phone'),
      email: i18n.t('E-mail'),
      contacts: i18n.t('Contacts'),
      areYouSureYouWantToRemoveThisActivity: "".concat(i18n.t('Are you sure you want to remove this'), " ").concat(i18n.t('Activity').toLowerCase(), "?"),
      nothingToShow: i18n.t('Nothing to show'),
      addContactPerson: i18n.t('Add contact person'),
      delete: i18n.t('Delete')
    };
  },
  ExternalSectionOverview: function ExternalSectionOverview() {
    return {
      typeOfIctServices: i18n.t('Type of ICT services'),
      ictTppSubstitutability: i18n.t('ICT TPP substitutability'),
      reasonForSubstitutability: i18n.t('Reason for substitutability of ICT TPP'),
      dateOfLastAudit: i18n.t('Date of last audit'),
      exitPlan: i18n.t('Exit plan'),
      reintegrationService: i18n.t('Reintegration of ICT service'),
      impactOfDiscontinuing: i18n.t('Impact of discontinuing the ICT services'),
      alternativeIctTpp: i18n.t('Alternative ICT TPP'),
      alternativeIctTppOther: i18n.t('Alternative ICT TPP - Other')
    };
  },
  ContractPartyExternalSection: function ContractPartyExternalSection() {
    return {
      assessmentOfTheIctServices: i18n.t('Assessment of the ICT services')
    };
  }
};