// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field-container.error textarea[data-v-d0bc083c],
.input-field-container.error[data-v-d0bc083c]  input {
  border-color: #B03D00;
}
.validation-error[data-v-d0bc083c] {
  color: #B03D00;
  margin: 0 0 0.5rem;
  padding: 4px 0 0;
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
