import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "flex flex-col"
};
var _hoisted_3 = {
  class: "flex-grow"
};
var _hoisted_4 = {
  key: 0,
  class: "self-end"
};
var _hoisted_5 = ["max", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [!$props.isFilesDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_component_risma_file_upload, {
    key: $data.fileUploadKey,
    "show-progress-bar": false,
    "show-uploaded-files": false,
    "use-form-data": $options.useFormData,
    onInput: _cache[0] || (_cache[0] = function ($event) {
      return $options.useFormData || $options.uploadFiles('input', $event);
    }),
    onUploadFiles: _cache[1] || (_cache[1] = function ($event) {
      return $options.useFormData && $options.uploadFiles('uploadFiles', $event);
    }),
    onStartLoading: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('startLoading');
    })
  }, null, 8 /* PROPS */, ["use-form-data"]))])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.links, function (link, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      class: _normalizeClass([{
        'mt-5': idx !== 0
      }, "flex items-center"])
    }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_input_field, {
      modelValue: link.url,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return link.url = $event;
      },
      placeholder: $data.translate.insertLinkToFileOrDestination + ' (http, https, ftp...)',
      title: $data.translate.linkTo,
      invalid: $props.showEmptyFieldsError && !link.url,
      maxlength: 1000,
      type: "url",
      onUpdated: _cache[3] || (_cache[3] = function ($event) {
        return $options.updateLinksData($data.updateTimeout);
      }),
      onBlur: $options.updateLinksData
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "title", "invalid", "onBlur"]), _createVNode(_component_input_field, {
      modelValue: link.filename,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return link.filename = $event;
      },
      placeholder: $data.translate.insertLinkTitle,
      invalid: $props.showEmptyFieldsError && !link.filename,
      class: "mt-2",
      type: "text",
      onUpdated: _cache[4] || (_cache[4] = function ($event) {
        return $options.updateLinksData($data.updateTimeout);
      }),
      onBlur: $options.updateLinksData
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "invalid", "onBlur"])]), idx !== 0 ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "stroke-2 -mr-6 mt-3 text-red-50 cursor-pointer",
      onClick: function onClick($event) {
        return $options.removeItemFromLinks(idx);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */)), $props.multipleLinks ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_risma_button, {
    text: $data.translate.addLink,
    type: "util",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.addItemToLinks('');
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        class: "stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"])])) : _createCommentVNode("v-if", true)]), $props.showProgressBar ? (_openBlock(), _createElementBlock("progress", {
    key: 1,
    max: $props.uploadingMax,
    value: $props.uploadingValue,
    class: "w-full"
  }, null, 8 /* PROPS */, _hoisted_5)) : _createCommentVNode("v-if", true)]);
}