import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "bg-inherit"
};
var _hoisted_2 = {
  class: "flex justify-between items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$dateColumn$, _$options$dateColumn$2;
  var _component_risma_table_report = _resolveComponent("risma-table-report");
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_risma_table_header = _resolveComponent("risma-table-header");
  var _component_risma_table_body = _resolveComponent("risma-table-body");
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [$props.reportType ? (_openBlock(), _createBlock(_component_risma_table_report, {
    key: 0,
    type: $props.reportType,
    params: $setup.latestSavedQueryParams,
    "compliance-project-id": $props.complianceProjectId,
    "report-options": $props.reportOptions,
    onLoadReport: $options.loadReport
  }, null, 8 /* PROPS */, ["type", "params", "compliance-project-id", "report-options", "onLoadReport"])) : _createCommentVNode("v-if", true)]), $options.dateColumn ? (_openBlock(), _createBlock(_component_risma_date_range_picker, {
    key: 0,
    "start-date": (_$options$dateColumn$ = $options.dateColumn.dateRangeHeaderFilter) === null || _$options$dateColumn$ === void 0 ? void 0 : _$options$dateColumn$.after,
    "end-date": (_$options$dateColumn$2 = $options.dateColumn.dateRangeHeaderFilter) === null || _$options$dateColumn$2 === void 0 ? void 0 : _$options$dateColumn$2.before,
    "local-storage-key": $options.datesRangeLocalStorageKey,
    ranges: $data.ranges,
    onChanged: $options.changedDates
  }, null, 8 /* PROPS */, ["start-date", "end-date", "local-storage-key", "ranges", "onChanged"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_risma_table_header, {
    columns: $data.columnsSetting,
    "paging-options": $props.pagingOptions,
    "preselected-page-size": $data.tableState.limit,
    "print-title": $props.printTitle,
    "show-search-field": $props.showSearchField,
    "export-handler": $options.exportHandler,
    "search-string": $data.tableState.searchString + '',
    onChangeVisibleColumns: $options.changeVisibleColumns,
    onPageSizeChange: $options.onPageSizeChange,
    onResetFormating: $options.resetFormating,
    onOnGlobalSearch: $options.onGlobalSearch
  }, null, 8 /* PROPS */, ["columns", "paging-options", "preselected-page-size", "print-title", "show-search-field", "export-handler", "search-string", "onChangeVisibleColumns", "onPageSizeChange", "onResetFormating", "onOnGlobalSearch"]), _createVNode(_component_risma_table_body, {
    class: "risma-table-body",
    columns: $data.columnsSetting,
    "dataset-main": $options.datasetToShow,
    "body-offset": $data.bodyOffset,
    "body-height": $props.bodyHeight,
    "underlying-checkbox-values": $data.underlyingCheckboxValues,
    onError: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('error', $event);
    }),
    onReload: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('reload');
    }),
    onColumnsChange: $options.onColumnsChange,
    onSortByColumn: $options.onSortByColumnEvt,
    onFiltersChange: $options.onFiltersChange,
    onColumnResize: $options.onColumnResize,
    onDismissModal: $options.onModalDismiss,
    onDelete: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('delete', $event);
    }),
    onStarred: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.$emit('starred', $event);
    }),
    onIconClicked: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$emit('iconClicked', $event);
    }),
    "onUpdate:loading": _cache[5] || (_cache[5] = function ($event) {
      return _ctx.$emit('update:loading', $event);
    })
  }, null, 8 /* PROPS */, ["columns", "dataset-main", "body-offset", "body-height", "underlying-checkbox-values", "onColumnsChange", "onSortByColumn", "onFiltersChange", "onColumnResize", "onDismissModal"]), $options.showPagination ? (_openBlock(), _createBlock(_component_pagination, {
    key: 0,
    total: $data.paginationInfo.count,
    "per-page": $data.tableState.limit,
    "show-prev-next-buttons": true,
    "active-page-number": $options.activePaginationPage,
    "additional-classes": "alt-design",
    class: "risma-table-pagination",
    onChange: $options.changePage
  }, null, 8 /* PROPS */, ["total", "per-page", "active-page-number", "onChange"])) : _createCommentVNode("v-if", true)]);
}