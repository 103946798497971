import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
var _hoisted_1 = {
  class: "px-4 pt-4 pb-5 bg-white border border-gray"
};
var _hoisted_2 = {
  class: "header flex justify-between items-start mb-3"
};
var _hoisted_3 = {
  class: "flex overflow-hidden"
};
var _hoisted_4 = {
  class: "ml-3 overflow-hidden flex-1"
};
var _hoisted_5 = {
  class: "flex"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = {
  key: 0,
  class: "label flex justify-center content-center font-semibold"
};
var _hoisted_9 = {
  class: "body mt-5"
};
var _hoisted_10 = {
  class: "description"
};
var _hoisted_11 = {
  class: "details mt-3"
};
var _hoisted_12 = {
  class: "details mt-3"
};
var _hoisted_13 = {
  key: 0,
  class: "full_link font-semibold text-right"
};
var _hoisted_14 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_percentage_box = _resolveComponent("percentage-box");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_percentage_box, {
    color: $props.color,
    class: "w-16 h-16"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.kpiValue) + "%", 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["color"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    class: "text-blue-750 pb-2",
    type: "medium",
    truncate: true,
    title: $props.cardHeader
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: "min-w-150px mr-4",
    innerHTML: $options.weightHtml
  }, null, 8 /* PROPS */, _hoisted_6), _createElementVNode("div", {
    innerHTML: $options.ratioHtml
  }, null, 8 /* PROPS */, _hoisted_7)])])]), $props.moduleTitle ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.moduleTitle), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "divider mx-auto"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_renderSlot(_ctx.$slots, "description", {}, undefined, true)]), _createElementVNode("div", _hoisted_11, [_renderSlot(_ctx.$slots, "details", {}, undefined, true)]), _createElementVNode("div", _hoisted_12, [_renderSlot(_ctx.$slots, "relevantActivities", {}, undefined, true)]), $props.extendedUrl ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("a", {
    href: $props.extendedUrl
  }, [_createTextVNode(_toDisplayString($data.translate.listOfUnansweredProcessingActivities) + " ", 1 /* TEXT */), _createVNode(_component_feather_icon, {
    class: "inline-block",
    "stroke-width": "5",
    icon: "arrow-right"
  })], 8 /* PROPS */, _hoisted_14)])) : _createCommentVNode("v-if", true)])]);
}