import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mt-3 grid grid-cols-3 gap-4"
};
var _hoisted_2 = {
  class: "mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field_select = _resolveComponent("input-field-select");
  var _component_single_select = _resolveComponent("single-select");
  var _component_year_wheel_table = _resolveComponent("year-wheel-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.paymentOverview,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field_select, {
    title: $data.translate.amount,
    "input-value": _ctx.contractAmount,
    "option-value": _ctx.contractAmountCurrency,
    "input-placeholder": $data.translate.amount,
    options: $data.currencyOptions,
    "input-enabled": !$props.readOnly,
    "option-enabled": !$props.readOnly,
    "un-sorted": true,
    "enable-currency-template": true,
    class: "mb-2",
    onInputChange: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.contractAmount = $event;
    }),
    onOptionChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.contractAmountCurrency = $event;
    })
  }, null, 8 /* PROPS */, ["title", "input-value", "option-value", "input-placeholder", "options", "input-enabled", "option-enabled"]), _createVNode(_component_single_select, {
    modelValue: _ctx.paymentInterval,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.paymentInterval = $event;
    }),
    title: $data.translate.paymentInterval,
    placeholder: $data.translate.choose,
    disabled: $props.readOnly,
    options: $data.paymentIntervalOptions,
    "un-sorted": true,
    "with-reset": !$props.readOnly
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "disabled", "options", "with-reset"]), _createVNode(_component_single_select, {
    modelValue: _ctx.paymentIntervalStartMonth,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.paymentIntervalStartMonth = $event;
    }),
    title: $data.translate.paymentStartMonth,
    placeholder: $data.translate.choose,
    disabled: $props.readOnly,
    options: $data.paymentMonthOptions,
    "un-sorted": true
  }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder", "disabled", "options"])]), _createElementVNode("div", _hoisted_2, [$data.payments && _ctx.paymentInterval ? (_openBlock(), _createBlock(_component_year_wheel_table, {
    key: 0,
    payments: $data.payments,
    year: $data.currentYear,
    "load-state": $data.paymentLoading,
    "onUpdate:year": $options.handleYearUpdate
  }, null, 8 /* PROPS */, ["payments", "year", "load-state", "onUpdate:year"])) : _createCommentVNode("v-if", true)])]);
}