function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { addContact, getCompanies as _getCompanies, getContacts as _getContacts } from '@/api/admin/companies';
import MainWell from '@/components/Atoms/Containers/MainWell';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import StaticTable from '@/components/Molecules/StaticTable';
import AddContactPersonModal from '@/components/Organisms/Contracts/AddContactPersonModal';
import CompanyContactModal from '@/components/Organisms/Companies/CompanyContactModal';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import CreateCompany from '@/components/Molecules/Companies/CreateCompany';
import { escapeHtml } from '@/utils';
import { compareStrings } from '@/utils/compare';
import { getCompanyWithUrl } from '@/utils/format/companies';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { MODULES } from '@/constants/modules';
import { LEI_INPUT_PROPS } from '@/constants/companies/companiesColumns';
export default {
  name: 'ContractParty',
  description: 'This is the Page component for /contracts/contract/:id/contractParty',
  token: '<contract-party data="" />',
  components: {
    MainWell: MainWell,
    FeatherIcon: FeatherIcon,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    PreviewList: PreviewList,
    StaticTable: StaticTable,
    AddContactPersonModal: AddContactPersonModal,
    RismaTitle: RismaTitle,
    CompanyContactModal: CompanyContactModal,
    PreviewModalLink: PreviewModalLink,
    CreateCompany: CreateCompany
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      isLoaded: false,
      MODULES: MODULES,
      basicGroupedData: {},
      selectedGroup: '',
      dataset: _defineProperty(_defineProperty({}, MODULES.COMPANY, []), MODULES.COMPANY_CONTACT, []),
      companies: [],
      showContactModal: false,
      translate: getTranslate['ContractParty']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin', 'userHasCompaniesAccess'])), {}, {
    groupedData: function groupedData() {
      var _this = this;
      if (!this.isLoaded) return {};
      var groups = _objectSpread({}, this.basicGroupedData);
      Object.keys(this.basicGroupedData).forEach(function (module) {
        groups[module].dataset = _this.prepareData(module, _this.data.links[module], _this.dataset[module]).sort(function (a, b) {
          return compareStrings(a.name, b.name, false, 'en');
        });
      });
      return groups;
    },
    availableContacts: function availableContacts() {
      var linkedCompanies = this.data.links[MODULES.COMPANY] || [];
      var linkedContacts = this.data.links[MODULES.COMPANY_CONTACT] || [];
      var allContacts = this.dataset[MODULES.COMPANY_CONTACT];
      if (!linkedCompanies || !linkedCompanies.length) return [];
      return allContacts.filter(function (_ref) {
        var companyId = _ref.companyId,
          id = _ref.id;
        return linkedCompanies.includes(companyId) && !linkedContacts.includes(id);
      });
    },
    contractsOptions: function contractsOptions() {
      var _this2 = this;
      return this.data.links[MODULES.COMPANY].reduce(function (acc, companyId) {
        var company = _this2.dataset[MODULES.COMPANY].find(function (company) {
          return +company.id === +companyId;
        });
        if (company) {
          acc.push({
            value: company.id,
            label: company.name
          });
          return acc;
        }
        return acc;
      }, []);
    },
    showCreateCompanyButton: function showCreateCompanyButton() {
      return this.currentUser.AccessLevels.Companies.canCreate;
    }
  }),
  beforeMount: function beforeMount() {
    this.initBasicGroupedData();
  },
  mounted: function mounted() {
    var _this3 = this;
    this.getAllData().then(function () {
      _this3.isLoaded = true;
    });
  },
  methods: {
    initBasicGroupedData: function initBasicGroupedData() {
      this.basicGroupedData = _defineProperty(_defineProperty({}, MODULES.COMPANY, {
        title: 'Contract party',
        columns: [this.translate.name, this.translate.address, this.translate.city, this.translate.zipcode, this.translate.phone, this.translate.vatNumber, LEI_INPUT_PROPS.title, ''],
        isAddBtnExist: true
      }), MODULES.COMPANY_CONTACT, {
        title: 'Contacts',
        columns: [this.translate.name, this.translate.title, this.translate.phone, this.translate.email],
        isAddBtnExist: true
      });
    },
    nothingToShowMessage: function nothingToShowMessage(type) {
      if (type === MODULES.COMPANY) {
        return this.translate.nothingToShow;
      }
      if (type === MODULES.COMPANY_CONTACT) {
        if (!this.availableContacts.length) {
          if (!this.groupedData[MODULES.COMPANY].dataset.length) {
            this.basicGroupedData[MODULES.COMPANY_CONTACT].isAddBtnExist = false;
            return this.translate.youNeedToAddContractPartyBeforeAddingConta;
          } else {
            this.basicGroupedData[MODULES.COMPANY_CONTACT].isAddBtnExist = true;
            return this.translate.selectedContractPartyDoesntHaveAnyAvailable;
          }
        }
        this.basicGroupedData[MODULES.COMPANY_CONTACT].isAddBtnExist = true;
        return this.translate.nothingToShow;
      }
      return '';
    },
    getAllData: function getAllData() {
      return Promise.all([this.getCompanies(), this.getContacts()]);
    },
    getLinkItems: function getLinkItems() {
      var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return items.map(function (item) {
        return list.find(function (l) {
          return l.id === item;
        });
      }).filter(Boolean);
    },
    getContacts: function getContacts() {
      var _this4 = this;
      return _getContacts().then(function (response) {
        _this4.dataset[MODULES.COMPANY_CONTACT] = response.list;
      }).catch(function () {
        return _this4.dataset[MODULES.COMPANY_CONTACT] = [];
      });
    },
    getCompanies: function getCompanies() {
      var _this5 = this;
      return _getCompanies().then(function (response) {
        _this5.dataset[MODULES.COMPANY] = response.list;
      }).catch(function () {
        return _this5.dataset[MODULES.COMPANY] = [];
      });
    },
    addNewCompany: function addNewCompany(company) {
      this.isPreview ? this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        url: "/company/".concat(company.id),
        urlTitle: company.name
      }) : this.$notify({
        title: this.translate.yourActivityHasBeenCreated,
        activity: getFieldsFromObjectForPreview(company)
      });
      this.dataset[MODULES.COMPANY].push(company);
      this.addItem(company.id, MODULES.COMPANY);
    },
    prepareData: function prepareData(module, list, dataset) {
      if (list !== null && list !== void 0 && list.length) {
        var linksItems = this.getLinkItems(list, dataset);
        return this.prepareDatasetByModule(module, linksItems);
      }
      return [];
    },
    prepareDatasetByModule: function prepareDatasetByModule(module, list) {
      switch (module) {
        case MODULES.COMPANY_CONTACT:
          return this.prepareContactsDataset(list);
        case MODULES.COMPANY:
          return this.prepareContractPartyDataset(list);
      }
    },
    prepareContractPartyDataset: function prepareContractPartyDataset(list) {
      return list.map(function (item) {
        return {
          name: {
            activity: getCompanyWithUrl(item)
          },
          address: escapeHtml(item.address) || '',
          city: escapeHtml(item.city) || '',
          zCode: escapeHtml(item.zipCode) || '',
          phone: escapeHtml(item.phone) || '',
          vatNo: escapeHtml(item.vatNo) || '',
          lei: item.lei,
          delete: {
            id: item.id,
            type: MODULES.COMPANY
          }
        };
      });
    },
    prepareContactsDataset: function prepareContactsDataset(list) {
      return list.map(function (item) {
        return {
          name: item.name,
          title: escapeHtml(item.title),
          phone: escapeHtml(item.phone),
          email: item.email,
          delete: {
            id: item.id,
            type: MODULES.COMPANY_CONTACT
          }
        };
      });
    },
    toggleCreateModal: function toggleCreateModal(type) {
      if (this.readOnly) {
        return;
      }
      if (!this.availableContacts.length && type === MODULES.COMPANY_CONTACT) {
        this.showContactModal = true;
        return;
      }
      this.selectedGroup = type;
    },
    closeModal: function closeModal() {
      this.selectedGroup = '';
      this.showContactModal = false;
    },
    addItem: function addItem(id, group) {
      var ids = this.data.links[group] || [];
      var linksValue = [].concat(_toConsumableArray(ids), [id]).sort(function (a, b) {
        return a - b;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, group, linksValue)));
      this.closeModal();
    },
    deleteItem: function deleteItem(itemId, objectType) {
      var _this6 = this;
      this.$confirm('', this.translate.areYouSureYouWantToRemoveThisActivity, function (res) {
        return res && _this6.onDeleteItemConfirm(itemId, objectType);
      });
    },
    onDeleteItemConfirm: function onDeleteItemConfirm(itemId, objectType) {
      var links = this.data.links[objectType].filter(function (item) {
        return item !== itemId;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, _defineProperty({}, objectType, links)));
    },
    emitUpdateLinks: function emitUpdateLinks(value) {
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
    },
    handleCreateContact: function handleCreateContact(contactData, companyId) {
      var _this7 = this;
      return addContact(companyId, contactData).then(function (response) {
        var company = _this7.dataset[MODULES.COMPANY].find(function (company) {
          return company.id === companyId;
        });
        response.companyId = companyId;
        company.links[MODULES.COMPANY_CONTACT] = company.links[MODULES.COMPANY_CONTACT] || [];
        company.links[MODULES.COMPANY_CONTACT].push(response.id);
        _this7.dataset[MODULES.COMPANY_CONTACT].push(response);
        _this7.addItem(response.id, MODULES.COMPANY_CONTACT);
      }).catch(function (error) {
        _this7.$notify({
          title: error,
          type: 'error'
        });
        _this7.closeModal();
      });
    }
  }
};