import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex-1 flex flex-col"
};
var _hoisted_2 = {
  class: "flex"
};
var _hoisted_3 = {
  class: "w-80 px-4"
};
var _hoisted_4 = {
  class: "w-80 px-4"
};
var _hoisted_5 = {
  class: "ml-auto mt-4"
};
var _hoisted_6 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_suggested_activities_cell = _resolveComponent("suggested-activities-cell");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_suggested_activities_modal = _resolveComponent("suggested-activities-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.suggestedActivities,
    class: "mb-3"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
    modelValue: $data.gapSchemaType,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.gapSchemaType = $event;
    }), $options.selectGapSchemaType],
    title: $data.translate.gapSchemaType,
    options: $options.gapSchemaTypesOptions,
    "un-sorted": true,
    placeholder: $data.translate.chooseGapSchemaType
  }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
    title: $data.translate.gapSchema,
    "model-value": $data.gapSchema,
    options: $options.gapSchemasOptions,
    "un-sorted": true,
    placeholder: $data.translate.chooseGapSchema,
    "onUpdate:modelValue": $options.selectGapSchema
  }, null, 8 /* PROPS */, ["title", "model-value", "options", "placeholder", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_5, [$data.gapSchemaType && $data.gapSchema ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    text: $data.translate.addNew,
    type: "util",
    onClick: $options.openCreateModal
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "plus",
        height: "30",
        width: "30",
        class: "cursor-pointer"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true)])]), $data.gapSchemaType && $data.gapSchema ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("hr", {
    class: "mx-4 my-6"
  }, null, -1 /* HOISTED */)), _createVNode(_component_risma_datatable, {
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $options.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "media-print-css": $data.mediaPrintCss,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, {
    suggestedActions: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createVNode(_component_suggested_activities_cell, {
        activities: item.activities,
        onDelete: function onDelete($event) {
          return $options.handleDelete($event, $data.MODULES.EXECUTION, item.id);
        },
        onUpdate: function onUpdate() {
          for (var _len = arguments.length, arg = new Array(_len), _key = 0; _key < _len; _key++) {
            arg[_key] = arguments[_key];
          }
          return $options.handleChangeData(arg, $data.MODULES.EXECUTION, item.id);
        }
      }, null, 8 /* PROPS */, ["activities", "onDelete", "onUpdate"])];
    }),
    suggestedControls: _withCtx(function (_ref2) {
      var item = _ref2.item;
      return [_createVNode(_component_suggested_activities_cell, {
        activities: item.activities,
        onDelete: function onDelete($event) {
          return $options.handleDelete($event, $data.MODULES.CONTROLS, item.id);
        },
        onUpdate: function onUpdate() {
          for (var _len2 = arguments.length, arg = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            arg[_key2] = arguments[_key2];
          }
          return $options.handleChangeData(arg, $data.MODULES.CONTROLS, item.id);
        }
      }, null, 8 /* PROPS */, ["activities", "onDelete", "onUpdate"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "media-print-css", "onPageChange", "onOnStateChanged"])])) : _createCommentVNode("v-if", true), $data.showCreateModal ? (_openBlock(), _createBlock(_component_suggested_activities_modal, {
    key: 1,
    articles: $data.articles,
    onAccept: $options.updateGapArticles,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.showCreateModal = false;
    })
  }, null, 8 /* PROPS */, ["articles", "onAccept"])) : _createCommentVNode("v-if", true)]);
}