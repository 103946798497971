function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import Gap from '@/components/Organisms/Compliance/Gaps/Gap';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TabList from '@/components/Atoms/TabList';
import StackedBarChart from '@/components/Molecules/Charts/StackedBarChart';
import GapSchemaQuestions from '@/components/Molecules/Compliance/Gap/GapSchemaQuestions';
import GapSchemaNavigation from '@/components/Molecules/Compliance/Gap/GapSchemaNavigation';
import GapSchemaHeatmaps from '@/components/Organisms/Compliance/Gaps/GapSchemaHeatmaps';
import * as Gaps from '@/api/compliance/gaps';
import * as Control from '@/api/controls/controls';
import * as Execution from '@/api/execution/initiative';
import * as ProcessLibrary from '@/api/compliance/processtree';
import * as RiskApi from '@/api/risk/risk';
import * as DataAPI from '@/api/risma/data';
import { getAllArticlesByGapSchemaId } from '@/api/compliance/gapschema';
import { getRiskAssessmentIdsList } from '@/api/compliance/informationAssets';
import { getQuestionnaireAssessments_TIA_LIA } from '@/api/compliance/questionnaire';
import { saveStatus } from '@/constants/SaveStatuses';
import { getGapProjectsOptions } from '@/constants/links/LinksModalSettings';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { MODULES } from '@/constants/modules';
import { TABS_SLOT_NAMES } from '@/constants/compliance/GapAnalysis';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getByUrl } from '@/api/httpUtils';
import { sortByIndex, sortNumericByProp } from '@/utils/sort';
import { pushChildrenToParent } from '@/utils/Utils';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'GapPage',
  components: {
    GapSchemaQuestions: GapSchemaQuestions,
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    Gap: Gap,
    StackedBarChart: StackedBarChart,
    GapSchemaNavigation: GapSchemaNavigation,
    FeatherIcon: FeatherIcon,
    TabList: TabList,
    GapSchemaHeatmaps: GapSchemaHeatmaps
  },
  introduction: '',
  description: '',
  token: '<gap-page></gap-page>',
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
      note: 'Current node object'
    },
    articleId: {
      required: false,
      type: [String, Number],
      default: null
    }
  },
  emits: ['propertyChanged', 'updateValue'],
  setup: function setup(props) {
    return {
      currentGapArticleId: getLocalStorageComputed("gap_".concat(props.data.compliance_projects_id, "_").concat(props.data.id), 0, true)
    };
  },
  data: function data() {
    return {
      gaps: null,
      questions: [],
      controls: [],
      initiatives: [],
      allInitiatives: [],
      processes: [],
      risks: [],
      saveStatus: saveStatus.SAVED,
      failedGap: null,
      gapsInitialized: 0,
      articles: null,
      loaded: false,
      labels: [],
      colors: [],
      filters: [],
      projects: [],
      threats: [],
      currentGapIndex: 0,
      riskProjectId: 2,
      questionSwitchOptions: [{
        name: 'Automatic',
        state: true
      }, {
        name: 'Manual',
        state: false
      }],
      currentTab: TABS_SLOT_NAMES.relevantQuestions,
      activePeriod: null,
      risksReport: [],
      systems: [],
      TABS_SLOT_NAMES: TABS_SLOT_NAMES,
      hasQuestionnaires: null,
      translate: getTranslate['GapPage']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, {
    currentUser: 'currentUser',
    currentUserComplianceProjectById: 'complianceProjectById'
  })), mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    allSettings: 'settings'
  })), mapState(useTrafficLights, {
    gapColors: 'gapTrafficLights'
  })), {}, {
    showFullGapsList: function showFullGapsList() {
      return this.getSettingValue('feature.show_full_list_of_gaps');
    },
    filterActivitiesByTag: function filterActivitiesByTag() {
      return this.getSettingValue('feature.filter_gap_initiatives_and_controls_by_tag');
    },
    projectsOptions: function projectsOptions() {
      return getGapProjectsOptions(this.allSettings, this.currentUser);
    },
    projectId: function projectId() {
      return this.data.compliance_projects_id || null;
    },
    currentProject: function currentProject() {
      return this.currentUserComplianceProjectById(this.projectId);
    },
    currentGap: function currentGap() {
      return this.filteredGaps[this.currentGapIndex];
    },
    visibleGaps: function visibleGaps() {
      if (this.showFullGapsList) {
        return this.filteredGaps;
      }
      return [this.currentGap];
    },
    barData: function barData() {
      var barData = Array(this.colors.length).fill(0);
      this.gapsWithArticles.forEach(function (el) {
        return barData[el.analysis_rag || 0]++;
      });
      return barData;
    },
    filteredGaps: function filteredGaps() {
      var _this = this;
      if (!this.filters.length) {
        return this.gapsWithArticles;
      }
      return this.gapsWithArticles.filter(function (gap) {
        var _gap$analysis_rag;
        var rang = (_gap$analysis_rag = gap.analysis_rag) !== null && _gap$analysis_rag !== void 0 ? _gap$analysis_rag : 0;
        return _this.filters.indexOf(rang) !== -1;
      });
    },
    filteredQuestions: function filteredQuestions() {
      return this.questions.filter(function (question) {
        return Object.keys(question.answers_options).length > 0 || question.freeText || question.informationAssets && Object.values(question.informationAssets).some(function (item) {
          return item.length;
        }) || question.processLibrary && question.processLibrary.length || question.dateTime || question.files && question.files.length;
      });
    },
    gapsWithArticles: function gapsWithArticles() {
      var _this2 = this;
      if (!this.articles || !Array.isArray(this.gaps)) {
        return [];
      }
      return this.articles.map(function (article) {
        var _gap$articleId;
        var gap = _this2.getGapFromArticleId(article.id);
        gap.articleId = (_gap$articleId = gap.articleId) !== null && _gap$articleId !== void 0 ? _gap$articleId : article.id;
        gap.article = article;
        return gap;
      });
    },
    complianceProjectTagName: function complianceProjectTagName() {
      return this.currentProject ? this.currentProject.tag : '';
    },
    isRelevantQuestionActive: function isRelevantQuestionActive() {
      return this.currentTab === this.TABS_SLOT_NAMES.relevantQuestions;
    },
    questionsLists: function questionsLists() {
      return this.isRelevantQuestionActive ? this.relevantQuestions : this.filteredQuestions;
    },
    relevantQuestions: function relevantQuestions() {
      var relevantQuestions = this.currentGap.article.relevantQuestionIds || [];
      if (relevantQuestions.length) {
        return this.filteredQuestions.filter(function (question) {
          return relevantQuestions.indexOf(question.id) !== -1;
        });
      }
      return [];
    },
    gapStats: function gapStats() {
      var totalQuestions = this.barData.reduce(function (total, value) {
        return total + value;
      }, 0);
      var questionsNA = this.barData[5];
      var questionNotSet = this.barData[0];
      var questionAnswered = totalQuestions - questionsNA - questionNotSet;
      return {
        gap_records_na: questionsNA,
        gap_records_answered: questionAnswered,
        percentage_gap_answered: totalQuestions > 0 ? Math.ceil(questionAnswered / totalQuestions * 100 / 5) * 5 : 0
      };
    },
    hasHeatmaps: function hasHeatmaps() {
      return !!this.getSettingValue('feature.risk_assessment_on_systems') && this.currentProject.gap_heatmaps;
    },
    tabsList: function tabsList() {
      var tabs = [{
        name: this.translate.allQuestions,
        slotName: TABS_SLOT_NAMES.questions
      }];
      if (this.relevantQuestions.length) {
        tabs.unshift({
          name: this.translate.relevantQuestions,
          slotName: TABS_SLOT_NAMES.relevantQuestions
        });
      }
      if (this.hasHeatmaps) {
        tabs.push({
          name: this.translate.heatmaps,
          slotName: TABS_SLOT_NAMES.heatmaps
        });
      }
      return tabs;
    },
    questionsClassObject: function questionsClassObject() {
      var topOffset = this.isPreview ? 'top-0' : 'top-145px';
      return _defineProperty(_defineProperty({}, "sticky ".concat(topOffset, " self-start"), this.showFullGapsList), 'min-w-35rem0', this.hasHeatmaps);
    },
    gapsClassObject: function gapsClassObject() {
      var topOffset = this.isPreview ? 'top-0' : 'top-145px';
      return _defineProperty({}, "sticky ".concat(topOffset, " self-start"), this.showFullGapsList);
    },
    scrolledElement: function scrolledElement() {
      var previewModal = document.querySelector('.preview-modal');
      return this.isPreview ? previewModal : document;
    }
  }),
  watch: {
    currentGapIndex: function currentGapIndex() {
      if (this.currentGap) {
        this.currentGapArticleId = this.currentGap.articleId;
        if (!this.isPreview) {
          this.$router.replace({
            params: {
              articleId: this.currentGap.articleId
            }
          });
        }
      }
    },
    articleId: function articleId() {
      if (this.articleId && this.articleId !== this.currentGap.articleId) {
        this.setCurrentGapIndex(+this.articleId);
      }
    }
  },
  mounted: function mounted() {
    this.loadData();
    this.createBarData();
    this.addScrollListener();
  },
  beforeUnmount: function beforeUnmount() {
    this.removeScrollListener();
  },
  methods: {
    onScrollFn: function onScrollFn() {
      for (var i = 0; i < this.visibleGaps.length; i++) {
        var _gapElement$$el;
        var gap = this.visibleGaps[i];
        var gapElement = this.$refs["gap-".concat(gap.articleId)] && this.$refs["gap-".concat(gap.articleId)][0];
        var elememtBounding = gapElement === null || gapElement === void 0 || (_gapElement$$el = gapElement.$el) === null || _gapElement$$el === void 0 ? void 0 : _gapElement$$el.getBoundingClientRect();
        if (!elememtBounding) return;
        if (elememtBounding.top > 150 || elememtBounding.bottom > 150) {
          this.currentGapIndex = i;
          break;
        }
      }
    },
    addScrollListener: function addScrollListener() {
      if (this.showFullGapsList) {
        this.scrolledElement.addEventListener('scroll', this.onScrollFn);
      }
    },
    removeScrollListener: function removeScrollListener() {
      if (this.showFullGapsList) {
        this.scrolledElement.removeEventListener('scroll', this.onScrollFn);
      }
    },
    setCurrentGapIndex: function setCurrentGapIndex(id) {
      if (!this.filteredGaps.length) return;
      var currentGapIndex = this.filteredGaps.findIndex(function (item) {
        return +item.articleId === +id;
      }) || 0;
      this.currentGapIndex = currentGapIndex !== -1 ? currentGapIndex : 0;
      if (this.showFullGapsList) this.scrollToCurrentGap(id);
    },
    scrollToCurrentGap: function scrollToCurrentGap(id) {
      this.removeScrollListener();
      var gap = $(".gap[data-gapid=\"".concat(id, "\"]"));
      if (this.isPreview) {
        var previewModal = $('.preview-modal');
        previewModal.animate({
          scrollTop: gap.offset().top - 130 + previewModal.scrollTop()
        }, 1000, this.addScrollListener);
      } else {
        $([document.documentElement, document.body]).animate({
          scrollTop: gap.offset().top - 120
        }, 1000, this.addScrollListener);
      }
    },
    createBarData: function createBarData() {
      var _this3 = this;
      this.gapColors.forEach(function (element) {
        _this3.labels.push(element.label);
        _this3.colors.push(element.color);
      });
    },
    loadData: function loadData() {
      var _this4 = this,
        _this$data$questionna;
      this.loaded = false;
      var promises = [this.getGapArticles(), getByUrl(this.data.apiUrl + '/gapanalysis').then(function (response) {
        return _this4.gaps = response.list;
      }), this.getControls(), this.getInitiatives(), this.getProcessLibraryNodes(), this.getRisks(), this.getThreats(), this.getProjects(), this.getQuestionnaireAssessments()];
      if ((_this$data$questionna = this.data.questionnaires) !== null && _this$data$questionna !== void 0 && _this$data$questionna.length) {
        this.data.questionnaires.forEach(function (questionnaire) {
          promises.push(getByUrl(_this4.data.apiUrl + '/questionanswers/' + questionnaire.questionnaireId).then(function (response) {
            var _this4$questions;
            return (_this4$questions = _this4.questions).push.apply(_this4$questions, _toConsumableArray(response.list));
          }));
        });
      }
      return Promise.all(promises).then(function () {
        _this4.getSystemRiskReport();
        _this4.loaded = true;
        if (_this4.articleId) {
          _this4.$nextTick(function () {
            _this4.setCurrentGapIndex(+_this4.articleId);
          });
        }
      });
    },
    getProcessTreeId: function getProcessTreeId() {
      return ProcessLibrary.getAllTrees().then(function (trees) {
        if (!(trees[0] && trees[0].id)) {
          return null;
        }
        return trees[0].id;
      });
    },
    getProcessLibraryNodes: function getProcessLibraryNodes() {
      var _this5 = this;
      if (!this.checkAccessByProjectOption(MODULES.COMPLIANCE_PROCESS_TREE_NODE)) return Promise.resolve();
      return this.getProcessTreeId().then(function (treeId) {
        if (!treeId) return Promise.resolve();
        return DataAPI.getNodesByProcessTreeId(treeId, 0).then(function (rootNode) {
          var processLibraryNodes = [];
          pushChildrenToParent({
            children: rootNode.children
          }, processLibraryNodes);
          _this5.processes = processLibraryNodes;
        });
      });
    },
    getControls: function getControls() {
      var _this6 = this;
      if (!this.checkAccessByProjectOption(MODULES.CONTROLS)) return Promise.resolve();
      var promise = this.filterActivitiesByTag ? Control.getAllWithTagsFilter(this.complianceProjectTagName) : DataAPI.getControls();
      return promise.then(function (response) {
        return _this6.controls = sortByIndex(response.list, 'cpath');
      });
    },
    getRisks: function getRisks() {
      var _this7 = this;
      if (!this.checkAccessByProjectOption(MODULES.RISK)) return Promise.resolve();
      return DataAPI.getRisks().then(function (_ref3) {
        var list = _ref3.list;
        return _this7.risks = sortNumericByProp(list, 'rno');
      });
    },
    getInitiatives: function getInitiatives() {
      var _this8 = this;
      if (!this.checkAccessByProjectOption(MODULES.EXECUTION)) return Promise.resolve();
      var promises = [DataAPI.getInitiatives(), this.filterActivitiesByTag && Execution.getAllWithTagsFilter(this.complianceProjectTagName)].filter(Boolean);
      return Promise.all(promises).then(function (_ref4) {
        var _ref5 = _slicedToArray(_ref4, 2),
          _ref5$ = _ref5[0],
          actions = _ref5$ === void 0 ? {} : _ref5$,
          _ref5$2 = _ref5[1],
          filteredByTag = _ref5$2 === void 0 ? {} : _ref5$2;
        var allInitiatives = sortByIndex(actions.list || [], 'path');
        _this8.allInitiatives = allInitiatives;
        _this8.initiatives = _this8.filterActivitiesByTag ? (filteredByTag === null || filteredByTag === void 0 ? void 0 : filteredByTag.list) || [] : allInitiatives.slice(0);
      });
    },
    getGapArticles: function getGapArticles() {
      var _this9 = this;
      var data = ['relevantQuestionIds', 'suggestedActivities'];
      var gapSchemaId = this.data.gapSchemaId;
      return getAllArticlesByGapSchemaId(gapSchemaId, data).then(function (_ref6) {
        var list = _ref6.list;
        _this9.articles = list.filter(function (_ref7) {
          var hidden = _ref7.hidden;
          return !hidden;
        });
      });
    },
    liaTiaForce: function liaTiaForce(data) {
      if (!this.data.questionnaires.find(function (item) {
        return item.questionnaireId === data.questionnaireId;
      })) {
        this.$emit('propertyChanged', {
          property: 'questionnaires',
          value: [].concat(_toConsumableArray(this.data.questionnaires), [data])
        });
      }
    },
    getReportRisk: function getReportRisk() {
      var params = "?filters[projectId]=".concat(this.riskProjectId, "&filters[projectPeriodId]=").concat(this.activePeriod.id, "&data[]=customFields&data[]=comparePeriod&data[]=linkedActivities&data[]=linkedAssets&filters[comparePeriodId]=").concat(this.activePeriod.previousId, "&filters[underlyingrisks]=include");
      return RiskApi.getReports(params);
    },
    getIASystems: function getIASystems() {
      return getRiskAssessmentIdsList(AssetsTypes.SYSTEMS, '?data[]=links');
    },
    getSystemRiskReport: function getSystemRiskReport() {
      var _this10 = this;
      return Promise.all([this.getReportRisk(), this.getIASystems()]).then(function (_ref8) {
        var _ref9 = _slicedToArray(_ref8, 2),
          risks = _ref9[0],
          ids = _ref9[1];
        _this10.risksReport = risks.list.filter(function (risk) {
          return ids.indexOf(risk.id) !== -1;
        });
      });
    },
    getProjects: function getProjects() {
      var _this11 = this;
      return RiskApi.getRiskProjects().then(function (response) {
        _this11.activePeriod = _this11.getActivePeriod(response.list);
        response.list.forEach(function (project) {
          _this11.projects.push({
            label: project.title,
            value: project.id
          });
        });
      });
    },
    getQuestionnaireAssessments: function getQuestionnaireAssessments() {
      var _this12 = this;
      getQuestionnaireAssessments_TIA_LIA(this.data.apiUrl).then(function (response) {
        _this12.hasQuestionnaires = response.list;
      });
    },
    tryAgain: function tryAgain() {
      this.failedGap && this.updateGap(this.failedGap);
    },
    changeStatusToNotSaved: function changeStatusToNotSaved() {
      this.saveStatus = saveStatus.NOT_SAVED;
    },
    updateGap: function updateGap(gap, changes) {
      var _this13 = this;
      var gapPayload = JSON.parse(JSON.stringify(_objectSpread(_objectSpread({}, gap), changes)));
      if (!changes.links && gapPayload.links) {
        Object.keys(gapPayload.links).forEach(function (title) {
          gapPayload.links[title] = gapPayload.links[title].map(function (_ref10) {
            var id = _ref10.id;
            return id;
          });
        });
      }
      this.saveStatus = saveStatus.SAVING;
      this.failedGap = null;
      return Gaps.updateGap(gapPayload, this.data.apiUrl).then(function (response) {
        var updatedGap;
        if (gap.id) {
          updatedGap = _this13.findGapById(gap.id);
          Object.assign(updatedGap, response);
        } else {
          updatedGap = response;
          _this13.gaps.push(updatedGap);
        }
        _this13.saveStatus = saveStatus.SAVED;
        _this13.$emit('updateValue', {
          property: 'values',
          value: _objectSpread(_objectSpread({}, _this13.data.values), _this13.gapStats)
        });
        return updatedGap;
      }).catch(function () {
        _this13.failedGap = gap;
        _this13.$confirm(_this13.translate.anErrorOccurred, _this13.translate.thereWasAnErrorSavingGapNumber + ': ' + _this13.failedGap.id + '.', function (res) {
          return res && _this13.tryAgain();
        }, {
          buttonOkText: _this13.translate.tryAgain,
          buttonDismissText: _this13.translate.cancel
        });
        _this13.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    findGapById: function findGapById(id) {
      return this.gaps.filter(function (gap) {
        return gap.id === id;
      })[0];
    },
    getGapFromArticleId: function getGapFromArticleId(articleId) {
      if (!Array.isArray(this.gaps)) {
        return {};
      }
      return this.gaps.find(function (gap) {
        return gap.articleId === articleId;
      }) || {};
    },
    barClick: function barClick(e) {
      this.currentGapIndex = 0;
      this.filters.indexOf(e) == -1 ? this.filters.push(e) : this.filters.splice(this.filters.indexOf(e), 1);
    },
    addNewItem: function addNewItem(item) {
      switch (item.objType) {
        case ObjectTypes.CONTROLS_CONTROL:
          this.controls.push(item);
          break;
        case ObjectTypes.EXECUTION_INITIATIVE:
          this.allInitiatives.push(item);
          this.allInitiatives = sortByIndex(this.allInitiatives, 'path');
          break;
      }
    },
    getThreats: function getThreats() {
      var _this14 = this;
      return RiskApi.getThreats(this.riskProjectId, 'data[]=all').then(function (_ref11) {
        var list = _ref11.list;
        _this14.threats = list;
      });
    },
    getActivePeriod: function getActivePeriod(projects) {
      var _this15 = this;
      var project = projects.find(function (project) {
        return project.id === _this15.riskProjectId;
      });
      if (project) {
        return project.projectPeriods.find(function (period) {
          return period.id === project.activePeriodId;
        });
      }
      return {};
    },
    checkAccessByProjectOption: function checkAccessByProjectOption(module) {
      return !!this.projectsOptions.find(function (option) {
        return option.value === module && option.rights;
      });
    }
  }
};