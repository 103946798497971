function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { getTranslate } from './translate';
import { getContracts as _getContracts, getContract } from '@/api/contracts/contracts';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getCompanies as _getCompanies, getContacts as _getContacts } from '@/api/admin/companies';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import ContractGenericTable from '@/components/Pages/Contracts/ContractGenericTable';
import AddContractsModal from '@/components/Molecules/Contracts/AddContractsModal';
import PreviewList from '@/components/Molecules/PreviewList/PreviewList';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { compareStrings } from '@/utils/compare';
import { MODULES } from '@/constants/modules';
export default {
  name: 'ContractRelatedContracts',
  description: 'This is the Page component for /contracts/contract/:id/related',
  token: '<contract-related-contracts :data="data"/>',
  components: {
    FeatherIcon: FeatherIcon,
    ContractGenericTable: ContractGenericTable,
    AddContractsModal: AddContractsModal,
    PreviewList: PreviewList,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['propertyChanged'],
  data: function data() {
    return {
      isLoaded: false,
      showAddContractsModal: false,
      contracts: [],
      workflowStatuses: [],
      relatedCompanies: [],
      relatedContracts: {
        byCompany: [],
        byLink: []
      },
      companies: [],
      contacts: [],
      translate: getTranslate['ContractRelatedContracts']()
    };
  },
  computed: {
    otherRelatedHiddenColumns: function otherRelatedHiddenColumns() {
      var result = ['controls', 'starred', 'commitment', 'contractType'];
      this.isPreview ? result.push('titlePreview') : result.push('title');
      return result;
    },
    relatedCompaniesHiddenColumns: function relatedCompaniesHiddenColumns() {
      var result = ['controls', 'starred', 'commitment', 'delete', 'companies', 'contractType'];
      this.isPreview ? result.push('titlePreview') : result.push('title');
      return result;
    },
    nothingToShowMessage: function nothingToShowMessage() {
      return this.translate.nothingToShow;
    },
    availableToChooseContracts: function availableToChooseContracts() {
      var _this = this;
      return this.contracts.filter(function (contract) {
        return contract.id !== _this.data.id;
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      return Promise.all([this.getContracts().then(this.getRelatedData), this.getWorkflowStatuses(), this.getCompanies(), this.getContacts()]).then(function () {
        _this2.getRelatedCompanies();
        _this2.isLoaded = true;
      });
    },
    getRelatedData: function getRelatedData() {
      var _this3 = this;
      return getContract(this.data.id, '?data[]=related').then(function (_ref) {
        var related = _ref.related;
        return _this3.setRelated(related);
      });
    },
    setRelated: function setRelated(related) {
      related.byLink = this.addLinks(related.byLink);
      this.relatedContracts = related;
    },
    getContracts: function getContracts() {
      var _this4 = this;
      var IncludeArchived = true;
      return _getContracts(IncludeArchived).then(function (_ref2) {
        var list = _ref2.list;
        return _this4.contracts = list;
      });
    },
    getWorkflowStatuses: function getWorkflowStatuses() {
      var _this5 = this;
      return getWorkflowStatusesByModule(MODULES.CONTRACTS).then(function (_ref3) {
        var list = _ref3.list;
        return _this5.workflowStatuses = list;
      });
    },
    getCompanies: function getCompanies() {
      var _this6 = this;
      return _getCompanies().then(function (_ref4) {
        var list = _ref4.list;
        _this6.companies = list;
      });
    },
    getRelatedCompanies: function getRelatedCompanies() {
      var _this7 = this;
      this.relatedCompanies = [];
      if (this.relatedContracts.byCompany) {
        Object.entries(this.relatedContracts.byCompany).forEach(function (_ref5) {
          var _ref6 = _slicedToArray(_ref5, 2),
            companyId = _ref6[0],
            contracts = _ref6[1];
          var relatedCompany = _this7.companies.find(function (company) {
            return +companyId === +company.id;
          });
          if (relatedCompany) {
            _this7.relatedCompanies.push({
              name: relatedCompany.name,
              id: relatedCompany.id,
              contracts: _this7.enhanceContractsWithContractParties(contracts)
            });
          }
        });
      }
      if (this.relatedContracts.byLink) {
        this.relatedContracts.byLink = this.enhanceContractsWithContractParties(this.relatedContracts.byLink);
      }
    },
    enhanceContractsWithContractParties: function enhanceContractsWithContractParties(contracts) {
      var _this8 = this;
      return contracts.map(function (contract) {
        var _this8$contracts$find;
        return _objectSpread(_objectSpread({}, contract), {}, {
          contractParties: ((_this8$contracts$find = _this8.contracts.find(function (_ref7) {
            var id = _ref7.id;
            return +id === +contract.id;
          })) === null || _this8$contracts$find === void 0 ? void 0 : _this8$contracts$find.contractParties) || []
        });
      });
    },
    getRelatedCompanyTitle: function getRelatedCompanyTitle(companyName) {
      if (companyName) {
        return "".concat(this.translate.relatedContractsFor, " ").concat(companyName);
      }
      return this.translate.relatedContracts;
    },
    toggleAddContractsModal: function toggleAddContractsModal() {
      if (this.readOnly) {
        return;
      }
      this.showAddContractsModal = !this.showAddContractsModal;
    },
    updateSelectedContracts: function updateSelectedContracts(contracts) {
      this.relatedContracts.byLink = contracts;
      this.addContracts(contracts);
      this.toggleAddContractsModal();
    },
    addContracts: function addContracts(contracts) {
      var ids = contracts.map(function (contract) {
        return contract.id;
      }) || [];
      var linksValue = ids.sort(function (a, b) {
        return a - b;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, {
        contracts: linksValue
      }));
    },
    deleteItem: function deleteItem(itemId) {
      var _this9 = this;
      this.$confirm('', this.translate.areYouSureYouWantToRemoveThisActivity, function (res) {
        return res && _this9.onDeleteItemConfirm(itemId);
      });
    },
    onDeleteItemConfirm: function onDeleteItemConfirm(itemId) {
      var links = this.data.links.contracts.filter(function (item) {
        return item !== itemId;
      });
      this.relatedContracts.byLink = this.relatedContracts.byLink.filter(function (item) {
        return item.id !== itemId;
      });
      this.emitUpdateLinks(_objectSpread(_objectSpread({}, this.data.links), {}, {
        contracts: links
      }));
    },
    emitUpdateLinks: function emitUpdateLinks(value) {
      this.$emit('propertyChanged', {
        property: 'links',
        value: value
      });
    },
    addLinks: function addLinks(contracts) {
      var _this10 = this;
      return contracts.map(function (contract) {
        var _this10$contracts$fin;
        return _objectSpread(_objectSpread({}, contract), {}, {
          links: (_this10$contracts$fin = _this10.contracts.find(function (c) {
            return +c.id === +contract.id;
          })) === null || _this10$contracts$fin === void 0 ? void 0 : _this10$contracts$fin.links
        });
      });
    },
    sortContractsByNo: function sortContractsByNo(contracts) {
      return _toConsumableArray(contracts).sort(function (a, b) {
        return compareStrings(a.uniqueId, b.uniqueId, false, 'en');
      });
    },
    getContacts: function getContacts() {
      var _this11 = this;
      return _getContacts().then(function (response) {
        _this11.contacts = response.list;
      });
    }
  }
};