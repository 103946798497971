function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineStore } from 'pinia';
import { getAllWorkflows } from '@/api/risma/data';
import { i18n } from '@/i18n';
export var useWorkflowStore = defineStore('workflowStore', {
  state: function state() {
    return {
      workflows: []
    };
  },
  getters: {
    workflowsByModule: function workflowsByModule() {
      var _this = this;
      return function (module) {
        return _this.workflows.filter(function (workflow) {
          return workflow.module === module;
        });
      };
    },
    defaultWorkflowByModule: function defaultWorkflowByModule() {
      var _this2 = this;
      return function (module) {
        return _this2.workflowsByModule(module).find(function (workflow) {
          return workflow.activityTypeId === null;
        });
      };
    },
    getWorkflow: function getWorkflow() {
      var _this3 = this;
      return function (module, activityTypeId) {
        var activityTypeWorkflow = _this3.workflowsByModule(module).find(function (workflow) {
          return +workflow.activityTypeId === +activityTypeId;
        });
        return activityTypeWorkflow || _this3.defaultWorkflowByModule(module);
      };
    },
    getAllStatuses: function getAllStatuses() {
      var _this4 = this;
      return function (module) {
        var workflows = _this4.workflowsByModule(module);
        return workflows.map(function (w) {
          return w.workflowStatuses;
        }).flat().map(function (i) {
          return _objectSpread(_objectSpread({}, i), {}, {
            label: i.title,
            value: i.id
          });
        }).sort(function (a, b) {
          return a.id - b.id;
        });
      };
    },
    getActivityStatusTitle: function getActivityStatusTitle() {
      var _this5 = this;
      return function (activity, module) {
        var allStatuses = _this5.getAllStatuses(module);
        var ws = allStatuses.find(function (st) {
          return +st.id === +activity.workflowStatusId;
        });
        return (ws === null || ws === void 0 ? void 0 : ws.title) || (ws === null || ws === void 0 ? void 0 : ws.label) || 'Unknown/Deleted status';
      };
    }
  },
  actions: {
    load: function load() {
      var _this6 = this;
      return getAllWorkflows().then(function (_ref) {
        var list = _ref.list;
        _this6.workflows = list;
        _this6.workflows.forEach(function (wf) {
          if (wf.activityTypeId) return;
          wf.workflowStatuses = wf.workflowStatuses.map(function (ws) {
            return _objectSpread(_objectSpread({}, ws), {}, {
              title: i18n.t(ws.title)
            });
          });
        });
      });
    }
  }
});