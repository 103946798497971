import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $data.tableHeader,
    "state-key": "assessed_threats",
    api: $options.getDataBE
  }, null, 8 /* PROPS */, ["print-title", "api"]))]);
}