function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import { RiskDegreeTitleMixin } from '@/mixins/RiskDegreeTitleMixin';
import { toLocalDateTimeFull } from '@/utils/date';
export default {
  name: 'NetGross',
  introduction: 'Component for risk2 RiskDescription',
  token: '<net-gross :risk-calculations="{}"/>',
  components: {
    TinyMceBox: TinyMceBox,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  mixins: [RiskDegreeTitleMixin],
  props: {
    risk: {
      type: Object,
      required: true,
      note: 'Risk data'
    },
    scoring: {
      type: Object,
      default: function _default() {}
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Is read only mode switch on'
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      data: {},
      localData: {},
      translate: getTranslate['NetGross']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    keepCommentFieldsExpanded: function keepCommentFieldsExpanded() {
      return !!this.getSettingValue('feature.risk_comments_field_on_axis');
    },
    grossUpdatedUTCToLocal: function grossUpdatedUTCToLocal() {
      if (!this.data.grossUpdated) return '';
      return toLocalDateTimeFull(this.data.grossUpdated);
    },
    impactLabel: function impactLabel() {
      return this.scoring.impact_label || this.translate.impact;
    },
    likelihoodLabel: function likelihoodLabel() {
      return this.scoring.likelihood_label || this.translate.likelihood;
    },
    riskCalculationsEnabled: function riskCalculationsEnabled() {
      return this.risk.riskCalculations !== undefined;
    },
    impactOptions: function impactOptions() {
      if (!this.scoring.impact_values) return [];
      var result = [{
        value: 0,
        label: this.translate.notSet
      }];
      for (var key in this.scoring.impact_values) {
        var label = this.scoring.impact_values[key];
        result.push({
          value: +key,
          label: key + ': ' + label
        });
      }
      return result;
    },
    likelihoodOptions: function likelihoodOptions() {
      var _this = this;
      if (!this.scoring.likelihood_labels) return [];
      var result = [{
        value: 0,
        label: this.translate.notSet
      }];
      this.scoring.likelihood_labels.forEach(function (value) {
        result.push({
          value: +value.likelihood_number,
          label: value.likelihood_number + ': ' + "".concat(_this.scoring.likelihood_values[value.likelihood_number], " ").concat(value.description)
        });
      });
      return result;
    },
    formulaExplanationTolerance: function formulaExplanationTolerance() {
      if (this.getSettingValue('risma.risk_calculation_formula') === 0) {
        return ': (' + this.data.toleranceImpact + '×' + this.data.toleranceImpact + ')/2 × ' + this.data.toleranceLikelihood;
      }
      if (this.getSettingValue('risma.risk_calculation_formula') === 1) {
        return ': (' + this.data.toleranceLikelihood + '×' + this.data.toleranceLikelihood + ')/2 × ' + this.data.toleranceImpact;
      }
      return ': ' + this.data.toleranceLikelihood + ' × ' + this.data.toleranceImpact;
    },
    formulaExplanationExposure: function formulaExplanationExposure() {
      if (this.getSettingValue('risma.risk_calculation_formula') === 0) {
        return ': (' + this.data.impact_score + '×' + this.data.impact_score + ')/2 × ' + this.data.likelihood_score;
      }
      if (this.getSettingValue('risma.risk_calculation_formula') === 1) {
        return ': (' + this.data.likelihood_score + '×' + this.data.likelihood_score + ')/2 × ' + this.data.impact_score;
      }
      return ': ' + this.data.likelihood_score + ' × ' + this.data.impact_score;
    },
    hideTolerance: function hideTolerance() {
      return this.getSettingValue('feature.risk_hide_tolerance');
    }
  }),
  watch: {
    risk: function risk() {
      this.updateCalculations();
    }
  },
  beforeMount: function beforeMount() {
    this.updateCalculations();
  },
  methods: {
    updateCalculations: function updateCalculations() {
      this.data = _objectSpread(_objectSpread({}, this.risk.riskCalculations), {}, {
        impact_score: this.risk.impact_score,
        likelihood_score: this.risk.likelihood_score,
        rpi: this.risk.rpi
      });
      this.localData = _objectSpread({}, this.data);
    },
    onCommentChanged: function onCommentChanged(comment) {
      this.localData.calculationsComment = comment;
      this.update();
    },
    update: function update() {
      this.$emit('update', {
        riskCalculations: this.localData
      });
    }
  }
};