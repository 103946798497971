import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "flex flex-col"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.header,
    "show-buttons": false,
    "button-dismiss-text": $data.translate.cancel,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        type: "error",
        onDismiss: $options.resetErrorMessage
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorMessage)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onDismiss"])) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.newTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.newTitle = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"]), _createVNode(_component_risma_button, {
        text: $data.translate.save,
        type: "save",
        disabled: !$options.isNewTitleValid,
        class: "inline-block ml-auto mt-4",
        onClick: $options.onSave
      }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text"]);
}