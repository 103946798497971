import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue";
var _hoisted_1 = {
  class: "inline-grid"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$toolTipData, _$props$toolTipData2, _$props$toolTipData3;
  var _component_tool_tip = _resolveComponent("tool-tip");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [(_$props$toolTipData = $props.toolTipData) !== null && _$props$toolTipData !== void 0 && _$props$toolTipData.isToolTipShown ? (_openBlock(), _createBlock(_component_tool_tip, {
    key: 0,
    style: _normalizeStyle({
      top: (_$props$toolTipData2 = $props.toolTipData) !== null && _$props$toolTipData2 !== void 0 && _$props$toolTipData2.toolTipPosition ? $props.toolTipData.toolTipPosition.top : 0,
      left: (_$props$toolTipData3 = $props.toolTipData) !== null && _$props$toolTipData3 !== void 0 && _$props$toolTipData3.toolTipPosition ? $props.toolTipData.toolTipPosition.left : 0
    }),
    class: "tool-tip"
  }, {
    default: _withCtx(function () {
      var _$props$toolTipData4;
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_$props$toolTipData4 = $props.toolTipData) === null || _$props$toolTipData4 === void 0 ? void 0 : _$props$toolTipData4.nodes, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "hover:underline cursor-pointer",
          onClick: _withModifiers(function ($event) {
            return $options.selectNode(item);
          }, ["prevent"])
        }, _toDisplayString(item.title), 9 /* TEXT, PROPS */, _hoisted_2);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"])) : _createCommentVNode("v-if", true)])), $data.selectedActivity ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 0,
    ref: "preview",
    activity: $data.selectedActivity,
    onDismissModal: $options.onModalDismiss
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createElementVNode("span", null, null, -1 /* HOISTED */)]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "onDismissModal"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}