import TabList from '@/components/Atoms/TabList';
import { TabsDepRelations } from '@/constants/Tabs';
export default {
  name: 'DepRelations',
  introduction: 'This is the right part of each edit page.',
  description: 'This sidebar components presents tabs sourced via slots',
  token: '<dep-relations local-storage-key="" :tabs="[]"> <div v-slot:description> smth </div> <div v-slot:details> smth </div> </dep-relations>',
  components: {
    TabList: TabList
  },
  props: {
    localStorageKey: {
      type: String,
      required: false,
      default: 'dep_relations',
      note: 'Name of localStorage key'
    },
    tabs: {
      type: Array,
      default: TabsDepRelations,
      note: 'Tabs list'
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['changedViewMode'],
  data: function data() {
    return {
      currentTab: this.tabs[0].slotName,
      currentTabKey: '_tab',
      transitionDuration: 400,
      animationTimer: null
    };
  },
  computed: {
    tabsTranslated: function tabsTranslated() {
      var _this = this;
      return this.tabs.map(function (tab) {
        return Object.assign({}, tab, {
          name: _this.$trans(tab.name)
        });
      });
    },
    transitionVars: function transitionVars() {
      return {
        '--transition-duration': this.transitionDuration + 'ms'
      };
    }
  }
};