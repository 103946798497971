import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { getCurrentYear } from '@/utils/date';
export default {
  name: 'YearWheelSelector',
  description: 'component that shows a year that can be increment or decrement',
  components: {
    FeatherIcon: FeatherIcon,
    RismaTitle: RismaTitle
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
      default: getCurrentYear()
    }
  },
  emits: ['update:modelValue'],
  methods: {
    setYear: function setYear(number) {
      this.$emit('update:modelValue', this.modelValue + number);
    }
  }
};