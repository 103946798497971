import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_gantt_chart_js = _resolveComponent("gantt-chart-js");
  return _openBlock(), _createBlock(_component_gantt_chart_js, {
    datasets: $data.chartData.datasets,
    labels: $data.chartData.labels,
    "start-date": $data.startDate,
    "x-axis-click-enabled": true,
    "onClick:xAxis": $options.handleClick
  }, null, 8 /* PROPS */, ["datasets", "labels", "start-date", "onClick:xAxis"]);
}