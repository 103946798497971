// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.security-groups-modal .security-groups-modal-section[data-v-08f60508] {
  margin: 15px 0 0 0;
}
.security-groups-modal[data-v-08f60508]  .modal-container.large {
  overflow: visible;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
