// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ol[data-v-1f653958] {
  counter-reset: item;
}
ol li[data-v-1f653958] {
  counter-increment: item;
}
ol li[data-v-1f653958]:before {
  display: block;
  position: absolute;
  right: 0;
  margin-right: 100%;
  content: counters(item, ".") ".";
  padding-right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
