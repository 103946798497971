// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom-actions[data-v-1b5778fb] {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.zoom-actions .current-zoom[data-v-1b5778fb] {
  margin-right: 5px;
  font-size: 16px;
}
.zoom-actions[data-v-1b5778fb]  .risma-button._active {
  cursor: default;
}
.zoom-actions[data-v-1b5778fb]  .risma-button._active .risma-button-btn {
  cursor: default;
  background: #ddddde;
}
.zoom-actions[data-v-1b5778fb]  .risma-button:hover:not(._active) .risma-button-btn {
  background: #4CB3C0;
}
.zoom-actions[data-v-1b5778fb]  .risma-button .risma-button-btn {
  min-width: 0 !important;
  font-size: 17px;
  padding: 4px 10px !important;
  transition: all 0.3s;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
