import { userHasLevelContracts, userHasLevelControls } from '@/routes/auth';
import { UserLevels } from '@/constants/UserLevels';
export var UserNotificationSettings = function UserNotificationSettings(getSettingValue) {
  var contractRights = getSettingValue('app.module_contracts_enabled') && userHasLevelContracts(UserLevels.NORMAL)();
  var controlRights = userHasLevelControls(UserLevels.NORMAL)();
  return [{
    id: 1,
    module: 'All',
    rights: true,
    notification: 'When added as responsible'
  }, {
    id: 2,
    module: 'All',
    rights: true,
    notification: 'When added on daily team'
  }, {
    id: 7,
    module: 'RISMAcontrols',
    rights: controlRights,
    notification: 'When a control is ready for you to review'
  }, {
    id: 10,
    module: 'RISMAcontrols',
    rights: controlRights,
    notification: 'Control review is approved'
  }, {
    id: 21,
    module: 'RISMAcontrols',
    rights: controlRights,
    notification: 'Control review is rejected'
  }, {
    id: 23,
    module: 'Contracts',
    rights: contractRights,
    notification: 'Notification upon expiration date'
  }, {
    id: 24,
    module: 'Contracts',
    rights: contractRights,
    notification: 'N days before expiration date'
  }, {
    id: 25,
    module: 'Contracts',
    rights: contractRights,
    notification: 'N days before renewal deadline'
  }, {
    id: 26,
    module: 'Contracts',
    rights: contractRights,
    notification: 'Commitment deadline notification'
  }, {
    id: 27,
    module: 'Contracts',
    rights: contractRights,
    notification: 'N days before commitment deadline'
  }].filter(function (setting) {
    return setting.rights;
  });
};