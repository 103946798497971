import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_pie_chart = _resolveComponent("risma-pie-chart");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_pie_chart, {
    data: $data.chartData,
    options: $data.chartOptions,
    width: 1000,
    height: 500,
    labels: $data.chartLabels,
    colors: $data.colors,
    onChartClicked: $options.clickCh
  }, null, 8 /* PROPS */, ["data", "options", "labels", "colors", "onChartClicked"])]);
}