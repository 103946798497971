import RismaAdmin from '@/components/Pages/Admin/RismaAdmin';
import UploadQuestionnaire from '@/components/Organisms/RismaAdmin/UploadQuestionnaire';
import CreateComplianceProject from '@/components/Organisms/RismaAdmin/CreateComplianceProject';
import CreateRiskProject from '@/components/Organisms/RismaAdmin/CreateRiskProject';
import UploadGap from '@/components/Organisms/RismaAdmin/UploadGap';
import EmailSetup from '@/components/Organisms/RismaAdmin/EmailSetup';
import DangerZone from '@/components/Organisms/RismaAdmin/DangerZone';
import Inbox from '@/components/Organisms/RismaAdmin/Inbox';
import SyncAdmin from '@/components/Organisms/RismaAdmin/SyncAdmin';
import DeletedNodes from '@/components/Organisms/RismaAdmin/DeletedNodes';
import FrameworksTable from '@/components/Organisms/RismaAdmin/FrameworksTable';
import { isAdmin } from '@/routes/auth';
import Kpis from '@/components/Organisms/RismaAdmin/Kpis';
import SamlAdmin from '@/components/Pages/Admin/RismaAdmin/SamlAdmin';
export default (function () {
  var routes = [{
    path: '',
    name: 'RismaAdminEntry',
    redirect: {
      name: 'RismaAdminGuide'
    },
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'guide',
    name: 'RismaAdminGuide',
    component: RismaAdmin,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'uploadquestionnaire',
    name: 'UploadQuestionnaire',
    component: UploadQuestionnaire,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'createcomplianceproject',
    name: 'CreateComplianceProject',
    component: CreateComplianceProject,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'createriskproject',
    name: 'CreateRiskProject',
    component: CreateRiskProject,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'uploadgap',
    name: 'UploadGap',
    component: UploadGap,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'emailsetup',
    name: 'EmailSetup',
    component: EmailSetup,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'dangerzone',
    name: 'DangerZone',
    component: DangerZone,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'inbox',
    name: 'Inbox',
    component: Inbox,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'kpis',
    name: 'Kpis',
    component: Kpis,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'sync',
    name: 'SyncAdmin',
    component: SyncAdmin,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'saml',
    name: 'Saml',
    component: SamlAdmin,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'deletednodes',
    name: 'DeletedNodes',
    component: DeletedNodes,
    meta: {
      userRightsFunction: isAdmin
    }
  }, {
    path: 'framework',
    name: 'FrameworksTable',
    component: FrameworksTable,
    meta: {
      userRightsFunction: isAdmin
    }
  }];
  return routes;
});