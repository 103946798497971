import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_mini_tree = _resolveComponent("mini-tree");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_mini_tree, {
    title: "Mini Tree",
    branch: $data.branch,
    onSelect: _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedNode = $event;
    })
  }, {
    nodeContent: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.selectedNode), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["branch"])]);
}