function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import Question from '@/components/Molecules/Questionnaire/Question';
import Notification from '@/components/Molecules/Notification';
import QuestionnaireNavigation from '@/components/Molecules/Compliance/QuestionnaireNavigation';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import { questionHasSomeDependencyChosen } from '@/utils/questionnaires';
import { getAnswerOptions } from '@/utils/questionnaire';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { exportTypes } from '@/constants/exportTypes';
import { ReportLogTypes } from '@/constants/reports';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
export default {
  name: 'Questionnaire',
  components: {
    Notification: Notification,
    Question: Question,
    QuestionnaireNavigation: QuestionnaireNavigation,
    FeatherIcon: FeatherIcon,
    RismaExcel: RismaExcel,
    RismaButton: RismaButton
  },
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    questionnaire: {
      required: true,
      type: [Object, Array],
      note: 'Questionnaire data'
    },
    questions: {
      type: Array,
      required: true
    },
    informationAssets: {
      type: Array,
      required: true
    },
    myInformationAssetsByType: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false
    },
    activityTypes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of custom information assets activity types'
    },
    nodeElement: {
      type: Object,
      default: function _default() {},
      required: false
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Questionnaire is be read-only'
    },
    title: {
      required: true,
      type: String,
      default: '',
      note: 'questionnaire title'
    },
    localStorageKey: {
      type: String,
      default: '',
      required: false,
      note: 'local storage for number'
    },
    showExportAsCsvButton: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['forceUpdate', 'update', 'newAsset', 'export'],
  setup: function setup(props) {
    return {
      currentQuestionId: getLocalStorageComputed("questionnaire_".concat(props.localStorageKey), 0, true)
    };
  },
  data: function data() {
    return {
      ReportLogTypes: ReportLogTypes,
      exportTypes: exportTypes,
      errors: [],
      questionIndex: 0,
      AssetsTypes: AssetsTypes,
      showNonApplicable: false,
      translate: {
        exportAsCSV: this.$trans('Export as CSV'),
        questionNumber: this.$trans('Question number'),
        question: this.$trans('Question'),
        answer: this.$trans('Answer'),
        showNonApplicable: this.$trans('Show non applicable'),
        hideNonApplicable: this.$trans('Hide non applicable'),
        questionWithQuestionumber: this.$trans('Question with questionumber:'),
        doesNotExistLogicIsIncorrectOn: this.$trans(', does not exist. Logic is incorrect on')
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    activeQuestions: function activeQuestions() {
      return this.expandedVisibleQuestions.filter(function (q) {
        return q.answeredDependencies;
      });
    },
    answerOptionsByQuestions: function answerOptionsByQuestions() {
      return this.activeQuestions.reduce(function (res, question) {
        var _Object$values$;
        var answers = getAnswerOptions(question);
        res[question.id] = Array.isArray(answers) ? answers.join(', ') : (_Object$values$ = Object.values(answers)[0]) === null || _Object$values$ === void 0 ? void 0 : _Object$values$.items;
        return res;
      }, {});
    },
    csvColumns: function csvColumns() {
      if (!this.showExportAsCsvButton) return {};
      return {
        questionNumber: this.translate.questionNumber,
        question: this.translate.question,
        answer: this.translate.answer
      };
    },
    csvDatasetToExport: function csvDatasetToExport() {
      var _this = this;
      if (!this.showExportAsCsvButton) return [];
      return this.activeQuestions.map(function (question) {
        return {
          questionNumber: question.questionnumber,
          question: question.question,
          answer: _this.answerOptionsByQuestions[question.id] || ''
        };
      });
    },
    visibleQuestions: function visibleQuestions() {
      if (this.questions == null) {
        return [];
      }
      return this.questions.filter(function (q) {
        return !q.hidden;
      });
    },
    expandedVisibleQuestions: function expandedVisibleQuestions() {
      var _this2 = this;
      return this.visibleQuestions.map(function (question, idx) {
        var result = _objectSpread(_objectSpread({}, question), {}, {
          answeredDependencies: _this2.answeredDependencies(question),
          showHeadline: true
        });
        if (_this2.showFullQuestionsList && idx !== 0) {
          var prevQuestion = _this2.visibleQuestions[idx - 1];
          result.showHeadline = prevQuestion && prevQuestion.headline !== question.headline;
        }
        return result;
      });
    },
    filteredAnsweredDependenciesQuestions: function filteredAnsweredDependenciesQuestions() {
      return this.expandedVisibleQuestions.filter(function (q) {
        return q.answeredDependencies;
      });
    },
    indexOfFirstAnsweredDependenciesQuestion: function indexOfFirstAnsweredDependenciesQuestion() {
      var arrayLength = this.expandedVisibleQuestions.length;
      return this.expandedVisibleQuestions.reduce(function (result, value, index) {
        if (result < arrayLength) return result;
        if (value.answeredDependencies) return index;
        return arrayLength;
      }, arrayLength);
    },
    indexOfLastAnsweredDependenciesQuestion: function indexOfLastAnsweredDependenciesQuestion() {
      return this.expandedVisibleQuestions.reduce(function (result, value, index) {
        if (value.answeredDependencies) return index;
        if (result >= 0) return result;
        return -1;
      }, -1);
    },
    questionMap: function questionMap() {
      var map = this.questions.map(function (question) {
        return [question.questionnumber, question];
      });
      return new Map(map);
    },
    questionsList: function questionsList() {
      var _this$expandedVisible;
      if (!((_this$expandedVisible = this.expandedVisibleQuestions) !== null && _this$expandedVisible !== void 0 && _this$expandedVisible.length)) {
        return [];
      }
      if (this.showFullQuestionsList) {
        return this.showNonApplicable ? this.expandedVisibleQuestions : this.filteredAnsweredDependenciesQuestions;
      }
      var element = this.expandedVisibleQuestions[this.questionIndex];
      if (element) {
        return [element];
      }
      return [this.expandedVisibleQuestions[0]];
    },
    showFullQuestionsList: function showFullQuestionsList() {
      return this.getSettingValue('feature.show_full_list_of_questionnaires');
    },
    informationAssetsByType: function informationAssetsByType() {
      return this.sortInformationAssetsByType(this.informationAssets);
    },
    showNotApplicableToggle: function showNotApplicableToggle() {
      return this.getSettingValue('feature.show_not_applicable_toggle');
    }
  }),
  watch: {
    questionIndex: function questionIndex() {
      if (this.questionIndex !== -1) {
        this.currentQuestionId = this.visibleQuestions[this.questionIndex].id;
      }
    }
  },
  mounted: function mounted() {
    this.getQuestionsErrors();
    if (this.currentQuestionId && !this.showFullQuestionsList) {
      this.changeQuestion(this.currentQuestionId);
    }
    !this.showFullQuestionsList && window.addEventListener('keydown', this.onKeyDown);
  },
  unmounted: function unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    getQuestionsErrors: function getQuestionsErrors() {
      var _this3 = this;
      var errors = [];
      this.questions.forEach(function (question) {
        if (question.dependencies === null) return;
        Object.keys(question.dependencies).forEach(function (number) {
          var dependantQuestion = _this3.questionMap.get(number);
          if (!dependantQuestion || !Object.keys(dependantQuestion).length) {
            errors.push(_this3.getErrorMessage(number, question.questionnumber));
          }
        });
      });
      this.errors = errors;
    },
    getErrorMessage: function getErrorMessage(number, questionNumber) {
      return "".concat(this.translate.questionWithQuestionumber, " ").concat(number).concat(this.translate.doesNotExistLogicIsIncorrectOn, " ").concat(questionNumber);
    },
    changeQuestion: function changeQuestion(id) {
      this.questionIndex = this.visibleQuestions.findIndex(function (item) {
        return item.id === id;
      });
      if (!this.showFullQuestionsList) return;
      var question = $(".question[question-id=\"".concat(id, "\"]"));
      if (!question.length) return;
      if (this.isPreview) {
        var previewModal = $('.preview-modal');
        previewModal.animate({
          scrollTop: question.offset().top - 140 + previewModal.scrollTop()
        }, 1000);
      } else {
        $('body, html').animate({
          scrollTop: question.offset().top - 130
        }, 1000);
      }
    },
    enchanceValueWithName: function enchanceValueWithName(value) {
      if (!value.name) value.name = value.title;
      return value;
    },
    answeredDependencies: function answeredDependencies(question) {
      return questionHasSomeDependencyChosen(question, this.questionMap);
    },
    update: function update(question) {
      this.$emit('update', question);
    },
    onKeyDown: function onKeyDown(event) {
      var isRadioBtn = event.target.tagName === 'INPUT' && event.target.type === 'radio';
      var isNavigationTrigger = isRadioBtn || event.target.tagName === 'BODY' || event.target.closest('button.js-navigate-arrow');
      if (isNavigationTrigger) {
        var _event$path;
        if ((_event$path = event.path) !== null && _event$path !== void 0 && _event$path.find(function (element) {
          var _element$className;
          return element === null || element === void 0 || (_element$className = element.className) === null || _element$className === void 0 ? void 0 : _element$className.includes('newChoice');
        })) return;
        if (event.key === 'ArrowRight' && this.questionIndex < this.indexOfLastAnsweredDependenciesQuestion) {
          this.setQuestionIndex(+1);
        }
        if (event.key === 'ArrowLeft' && this.questionIndex > this.indexOfFirstAnsweredDependenciesQuestion) {
          this.setQuestionIndex(-1);
        }
        if (event.key === 'ArrowRight' && this.questionIndex === this.indexOfLastAnsweredDependenciesQuestion || event.key === 'ArrowLeft' && this.questionIndex === this.indexOfFirstAnsweredDependenciesQuestion) {
          return event.preventDefault();
        }
      }
    },
    sortInformationAssetsByType: function sortInformationAssetsByType(assets) {
      var _this4 = this;
      var items = assets || [];
      var result = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AssetsTypes.PROCESSORS, []), AssetsTypes.SYSTEMS, []), AssetsTypes.CONTROLLERS, []), AssetsTypes.CUSTOM, []);
      items.forEach(function (value) {
        if (value.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR) {
          result[AssetsTypes.PROCESSORS].push(value);
        } else if (value.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM) {
          result[AssetsTypes.SYSTEMS].push(value);
        } else if (value.objType === ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER) {
          result[AssetsTypes.CONTROLLERS].push(value);
        } else if (value.objType.startsWith(ObjectTypes.INFORMATION_ASSET)) {
          value = _this4.enchanceValueWithName(value);
          result[AssetsTypes.CUSTOM].push(value);
        }
      });
      return result;
    },
    setQuestionIndex: function setQuestionIndex(value) {
      var newValue = this.questionIndex + value;
      var newQuestion = this.expandedVisibleQuestions[newValue];
      if (!newQuestion) return;
      if (newQuestion.answeredDependencies) {
        this.questionIndex = newValue;
        return;
      }
      this.setQuestionIndex(value > 0 ? ++value : --value);
    },
    emitExport: function emitExport(type, count) {
      this.$emit('export', {
        type: type,
        count: count
      });
    }
  }
};