function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import VLazyImage from 'v-lazy-image';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { useComplianceProjectsStore } from '@/Store/complianceProjectsStore';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import ContactCards from '../ContactCards';
import HierarchicalList from '@/components/Molecules/HierarchicalList';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import Modal from '@/components/Molecules/Modal/Modal';
import FilePreviewModal from '@/components/Molecules/FilePreviewModal/FilePreviewModal';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaExcel from '@/components/Atoms/Excel/RismaExcel';
import RismaPrint from '@/components/Atoms/Print/RismaPrint';
import ViewSwitcher from '@/components/Molecules/ViewSwitcher';
import { ModalDismissTypes } from '@/constants/ModalDismissTypes';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { ReportLogTypes } from '@/constants/reports';
import { ProjectTypes } from '@/constants/compliance/ProjectTypes';
import { ARTICLE_30_TABLE_VIEWS, VIEW_TYPES } from '@/constants/ViewTypes';
import { removeHtmlTags, findPropsByIds } from '@/utils/Utils';
import { getHierarchicalList, buildHierarchicalStructure } from '@/utils/format/hierarchicalList';
import { getInformationAssetsType } from '@/utils/InformationAssets';
import { getProjectDescriptionLabel, getProjectPurposeLabel, getProjectStatusLabel, getDPOLabel } from '@/utils/Compliance/complianceProject';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { RaciTitleMixin } from '@/mixins/RaciTitleMixin';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import { MODULES } from '@/constants/modules';
export default {
  name: 'Article30Table',
  description: 'Component for displaying a table with the Article 30 and IA questions and question answers',
  components: {
    FilePreviewModal: FilePreviewModal,
    HierarchicalList: HierarchicalList,
    ContactCards: ContactCards,
    RismaButton: RismaButton,
    RismaDatatable: RismaDatatable,
    Modal: Modal,
    VLazyImage: VLazyImage,
    SaveReport: SaveReport,
    RismaExcel: RismaExcel,
    RismaPrint: RismaPrint,
    ViewSwitcher: ViewSwitcher
  },
  mixins: [RaciTitleMixin, RismaDatatableDefaultMixin, SaveReportMixin],
  props: {
    projectId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'The ID of the project'
    },
    rows: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: 'article30',
      note: 'Table mode'
    },
    reportUrl: {
      type: String,
      required: false,
      default: ''
    },
    reportOptions: {
      type: Object,
      default: null,
      note: 'Report options use to save the report'
    },
    reportType: {
      type: String,
      required: true,
      note: 'The type of report to display'
    },
    showControllersAndProcessors: {
      type: Boolean,
      required: false,
      default: false
    },
    includeDataFlow: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show data flow column'
    },
    isAnsweredOnly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Show only answered questions'
    },
    includeRaciEmails: {
      type: Boolean,
      required: false,
      default: false,
      note: "Show RACI's emails columns"
    },
    tableViewOnLoad: {
      type: String,
      required: false,
      default: ''
    },
    includeHelpText: {
      type: Boolean,
      required: false,
      default: false
    },
    stateLocal: {
      type: String,
      default: '',
      note: 'The name of a key in localStorage to save rismadatatable state'
    },
    nameColumnTitle: {
      type: String,
      default: getTranslate['node']().node,
      note: "The title on the name's column"
    }
  },
  emits: ['export', 'updated', 'dismissModal', 'setSelectedView', 'updateFromContactCards', 'dismissContractCardModal'],
  data: function data() {
    return {
      ReportLogTypes: ReportLogTypes,
      wordModal: false,
      previewFile: null,
      buildHierarchicalList: buildHierarchicalStructure,
      questionsKeyPrefix: 'q',
      viewType: VIEW_TYPES.COMPACT,
      VIEW_TYPES: VIEW_TYPES,
      ARTICLE_30_TABLE_VIEWS: ARTICLE_30_TABLE_VIEWS,
      translate: getTranslate['Table']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    reportOptionsExtended: function reportOptionsExtended() {
      return _objectSpread(_objectSpread({}, this.reportOptions), {}, {
        isShortUrl: true
      });
    },
    isArticle30: function isArticle30() {
      return this.mode === 'article30';
    },
    hasOverallFramework: function hasOverallFramework() {
      return this.getSettingValue('feature.overall.extended_framework');
    },
    isProcessorAudit: function isProcessorAudit() {
      return this.mode === 'processorAudit';
    },
    noDataFlowMessage: function noDataFlowMessage() {
      return this.translate.youHaveNoDataFlowsInThisProcess;
    },
    includeSubprocessors: function includeSubprocessors() {
      return this.getSettingValue('feature.ia_questions_include_subprocessors') !== 0;
    },
    descriptionLabel: function descriptionLabel() {
      return getProjectDescriptionLabel(this.currentProject);
    },
    purposeLabel: function purposeLabel() {
      return getProjectPurposeLabel(this.currentProject);
    },
    dpoLabel: function dpoLabel() {
      return getDPOLabel();
    },
    isGDPR: function isGDPR() {
      var _this$currentProject;
      return ((_this$currentProject = this.currentProject) === null || _this$currentProject === void 0 ? void 0 : _this$currentProject.type) === ProjectTypes.GDPR;
    },
    article30Columns: function article30Columns() {
      if (!this.isArticle30) return {};
      var columns = {
        description: this.descriptionLabel,
        purpose: this.purposeLabel,
        status: getProjectStatusLabel(this.currentProject),
        tags: this.translate.tags,
        questionnaire: this.translate.questionnaire
      };
      if (this.viewType === VIEW_TYPES.EXPANDED) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          responsibleUsers: this.translatablesWithRaci('responsible'),
          accountableUsers: this.translatablesWithRaci('accountable'),
          consultedUsers: this.translatablesWithRaci('consulted'),
          informedUsers: this.translatablesWithRaci('informed'),
          organisations: this.translate.organisations
        });
      }
      if (this.viewType === VIEW_TYPES.EXPANDED && this.includeRaciEmails) {
        columns = _objectSpread(_objectSpread({}, columns), {}, {
          responsibleEmails: this.translatablesWithRaci('responsible') + ' e-mails',
          accountableEmails: this.translatablesWithRaci('accountable') + ' e-mails',
          consultedEmails: this.translatablesWithRaci('consulted') + ' e-mails',
          informedEmails: this.translatablesWithRaci('informed') + ' e-mails'
        });
      }
      if (this.showControllersAndProcessors) {
        columns.dataProcessors = this.translate.dataProcessors;
        columns.dataControllers = this.translate.dataControllers;
      }
      if (this.isGDPR) {
        columns.dpo = this.dpoLabel;
      }
      if (this.includeDataFlow) {
        columns.dataFlow = this.translate.dataFlow;
      }
      return columns;
    },
    processorAuditColumns: function processorAuditColumns() {
      if (!this.isProcessorAudit) return {};
      return {
        name: this.translate.name,
        email: this.translate.email,
        sent: this.translate.sent,
        questionnaireOpen: this.translate.questionnaireOpened,
        questionnaireAnswered: this.translate.questionnaireAnswered
      };
    },
    invertedColumns: function invertedColumns() {
      var result = {
        0: ''
      };
      this.rows.forEach(function (row) {
        result["".concat(row.id, "-").concat(row.questionnaireId)] = '';
      });
      return result;
    },
    columns: function columns() {
      var _this = this;
      var columns = _objectSpread(_objectSpread({
        node: this.nameColumnTitle
      }, this.processorAuditColumns), this.article30Columns);
      this.rows[0].cells.forEach(function (c, i) {
        var uniqueKey = _this.questionsKeyPrefix + i;
        if (_this.includeHelpText) {
          columns[uniqueKey] = "".concat(c.number, ". ").concat(c.question, " ").concat(c.helpText ? "(".concat(c.helpText, ")") : '');
        } else {
          columns[uniqueKey] = c.number + '. ' + c.question;
        }
      });
      return columns;
    },
    columnsMeta: function columnsMeta() {
      var result = [{
        targets: ['description'],
        width: '600px'
      }];
      if (this.includeHelpText) {
        result.push({
          targets: this.uniqueKeys,
          width: '300px'
        });
      }
      return result;
    },
    datasetExport: function datasetExport() {
      return this.getDatasetToExport(this.dataset);
    },
    invertedDatasetToExport: function invertedDatasetToExport() {
      return this.getDatasetToExport(this.invertedDataset);
    },
    invertedDataset: function invertedDataset() {
      var _this2 = this;
      var dataset = Object.entries(this.columns).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        var result = {
          0: {
            field: value
          }
        };
        _this2.dataset.forEach(function (item) {
          result["".concat(item.node.id, "-").concat(item.node.questionnaireId)] = item[key];
        });
        return result;
      });
      return dataset;
    },
    dataset: function dataset() {
      var _this3 = this;
      var dataset = this.rows.map(function (node) {
        var data = {
          node: {
            id: node.id,
            questionnaireId: node.questionnaireId,
            field: {
              hierarchicalList: buildHierarchicalStructure(node)
            },
            fieldText: getHierarchicalList(node)
          }
        };
        if (_this3.isProcessorAudit) {
          data = _objectSpread(_objectSpread({}, data), _this3.prepareProcessorAuditData(node));
        }
        if (_this3.isArticle30) {
          data = _objectSpread(_objectSpread({}, data), _this3.prepareArticle30Data(node));
        }
        node.cells.forEach(function (cell, i) {
          var uniqueKey = _this3.questionsKeyPrefix + i;
          var field = '';
          if (cell.answers && cell.answers.length) {
            field += '<ul>';
            Object.keys(cell.answers).forEach(function (key) {
              field += "<li>".concat(cell.answers[key], "</li>");
            });
            field += '</ul>';
          }
          data[uniqueKey] = {
            isQuestionAnswer: true,
            field: {
              value: field,
              id: node.id
            },
            fieldText: removeHtmlTags(cell.answers.join(', '))
          };
          if (_this3.hasValues(cell.informationAssets)) {
            data[uniqueKey].field = _objectSpread(_objectSpread({}, data[uniqueKey].field), {}, {
              data: _this3.prepareIA(cell.informationAssets)
            });
            data[uniqueKey].fieldText = _this3.formatIaFieldText(cell.informationAssets);
          }
        });
        return data;
      });
      if (this.hasOverallFramework) {
        var frameworkRow = {};
        Object.keys(dataset[0]).forEach(function (key) {
          if (key === 'node') {
            frameworkRow[key] = {
              field: _this3.translate.frameworks
            };
          } else if (!_this3.uniqueKeys.includes(key)) {
            frameworkRow[key] = {
              field: ''
            };
          }
        });
        this.rows[0].cells.forEach(function (cell, i) {
          var uniqueKey = _this3.questionsKeyPrefix + i;
          frameworkRow[uniqueKey] = {
            field: findPropsByIds(cell.solutionIds, _this3.frameworks, 'displayName'),
            isFramework: true
          };
        });
        dataset.unshift(frameworkRow);
      }
      return dataset;
    },
    datatableDataByType: function datatableDataByType() {
      if (this.viewType === VIEW_TYPES.INVERTED_COMPACT) {
        return {
          columns: this.invertedColumns,
          dataset: this.invertedDataset
        };
      }
      return {
        columns: this.columns,
        dataset: this.dataset
      };
    },
    uniqueKeys: function uniqueKeys() {
      var _this4 = this;
      return this.rows[0].cells.map(function (c, i) {
        return _this4.questionsKeyPrefix + i;
      });
    },
    currentProject: function currentProject() {
      return this.complianceProjectById(this.projectId);
    }
  }),
  watch: {
    viewType: function viewType() {
      if (this.viewType === VIEW_TYPES.EXPANDED) {
        this.enhanceDataset(this.dataset, this.columns);
        this.loadSavedReport();
      }
      this.$emit('setSelectedView', this.viewType);
    }
  },
  mounted: function mounted() {
    var _this5 = this;
    if (this.tableViewOnLoad && ARTICLE_30_TABLE_VIEWS.some(function (view) {
      return view.name === _this5.tableViewOnLoad;
    })) {
      this.viewType = this.tableViewOnLoad;
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions(useComplianceProjectsStore, {
    complianceProjectById: 'getProjectById'
  })), mapActions(useTrafficLights, ['getTrafficLightLegendForModule'])), {}, {
    prepareIA: function prepareIA(dataObject) {
      if (this.includeSubprocessors) {
        return dataObject;
      }
      return _objectSpread(_objectSpread({}, dataObject), {}, _defineProperty({}, AssetsTypes.PROCESSORS, dataObject[AssetsTypes.PROCESSORS] ? dataObject[AssetsTypes.PROCESSORS].filter(function (processor) {
        return !processor.parents.length;
      }) : []));
    },
    getDataProcessorsField: function getDataProcessorsField() {
      var processors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return {
        hasContactData: processors === null || processors === void 0 ? void 0 : processors.length,
        field: '',
        data: {
          processors: processors
        },
        fieldText: this.formatIaFieldText({
          processors: processors
        })
      };
    },
    hasValues: function hasValues(item) {
      var iaArray = Object.keys(item || {});
      var procIndex = iaArray.indexOf(AssetsTypes.PROCESSORS);
      if (this.includeSubprocessors || procIndex === -1) {
        return item ? Object.values(item).some(function (a) {
          return a.length > 0;
        }) : false;
      }
      iaArray.splice(procIndex, 1);
      return item[AssetsTypes.PROCESSORS].length && item[AssetsTypes.PROCESSORS].some(function (item) {
        return !item.parents.length;
      }) || iaArray.some(function (iaType) {
        return item[iaType].length > 0;
      });
    },
    setPreviewFile: function setPreviewFile(imageUrl) {
      this.previewFile = {
        fileSrc: imageUrl,
        fileType: 'image'
      };
    },
    formatIaFieldText: function formatIaFieldText(informationAssets) {
      var _this6 = this;
      var result = '';
      var keys = Object.keys(informationAssets);
      keys.forEach(function (key) {
        if (!informationAssets[key].length) return;
        informationAssets[key].forEach(function (ia) {
          result += removeHtmlTags(_this6.iaFieldCardText(ia, key)) + '\n';
        });
      });
      return result;
    },
    iaFieldCardText: function iaFieldCardText(ia, type) {
      var activeField = ia.companyInfo || ia;
      var config = {
        title: {
          value: ia.name ? ia.name : ia.title
        },
        type: {
          label: this.translate.type,
          value: getInformationAssetsType(ia, type)
        },
        description: {
          label: this.translate.description,
          value: ia.description
        },
        companyName: {
          label: this.translate.companyName,
          value: ia.companyInfo ? activeField.name : ''
        },
        status: {
          label: this.translate.status,
          value: ia.companyInfo ? this.getTrafficLightLegendForModule(MODULES.COMPANY, activeField.vatStatus) : ''
        },
        companyDescription: {
          label: this.translate.companyDescription,
          value: ia.companyInfo ? activeField.description : ''
        },
        address: {
          label: this.translate.address,
          value: activeField.address
        },
        regNo: {
          label: this.translate.regNumber,
          value: activeField.regNo
        },
        countryCode: {
          label: this.translate.countryCodeCountry,
          value: activeField.countryCode
        },
        vatNo: {
          label: this.translate.vatNumber,
          value: activeField.vatNo
        },
        email: {
          label: this.translate.email,
          value: activeField.email
        }
      };
      return Object.values(config).filter(function (item) {
        return item.value;
      }).map(function (item) {
        return "".concat(item.label ? item.label + ': ' : '').concat(item.value);
      }).join('\n');
    },
    fnDrawCallback: function fnDrawCallback(e) {
      if (!e) {
        return;
      }
      var container = e.nTableWrapper;
      var windowHeight = window.innerHeight;
      var topWrapperHeight = document.querySelector('.topwrapper').offsetHeight;
      var mainContentTopMargin = parseInt(getComputedStyle(document.querySelector('.main-content')).marginTop, 10);
      var scrollHeadHeight = container.querySelector('.dataTables_scrollHead').offsetHeight;
      var dataTableFilter = container.querySelector('.dataTables_filter').offsetHeight;
      var footerInfo = container.querySelector('.dataTables_info').offsetHeight;
      var height = windowHeight - topWrapperHeight - mainContentTopMargin;
      height = height - scrollHeadHeight - dataTableFilter - footerInfo;
      if (height > 0) {
        container.querySelector('.dataTables_scrollBody').style.maxHeight = height + 'px';
      }
    },
    // It is needed to add delegation elements to DataTable.vue
    addNodeToTheData: function addNodeToTheData(node, name, data) {
      var field = '';
      if (node[name]) {
        field += '<ul>';
        node[name].forEach(function (el) {
          field += '<li>' + el + '</li>';
        });
        field += '</ul>';
        data[name] = {
          field: field,
          fieldText: node[name].join(', ')
        };
      }
    },
    /**
     * Method for exporting the table data to word
     */
    handleExportToWordButton: function handleExportToWordButton(clickedButton) {
      var url = '/api/2.0' + this.reportUrl + '&format=ooxml';
      if (clickedButton === ModalDismissTypes.ICON) {
        // if close icon clicked
        this.toogleExportToWordModal();
        return;
      } else if (clickedButton === ModalDismissTypes.BUTTON) {
        //if no clicked (for yes clickedButton == undefined)
        url += '&includeNodesWithoutAnswers=true';
      }
      url += '&includeDataFlow=' + (this.includeDataFlow ? 'true' : 'false');
      url += '&onlyAnsweredQuestions=' + (this.isAnsweredOnly ? 'true' : 'false');
      window.open(url, 'download');
      this.toogleExportToWordModal();
    },
    emitExport: function emitExport(type, count) {
      this.$emit('export', {
        type: type,
        count: count
      });
    },
    replaceHtml: function replaceHtml(string) {
      return string.replace(/div/g, 'section');
    },
    translatablesWithRaci: function translatablesWithRaci(column) {
      if (column == 'responsible') {
        return this.responsibleTitle(true);
      }
      if (column == 'accountable') {
        return this.accountableTitle(true);
      }
      if (column == 'consulted') {
        return this.consultedTitle(true);
      }
      if (column == 'informed') {
        return this.informedTitle(true);
      }
      return this.translatables[column] || column;
    },
    getLinkToRoute: function getLinkToRoute(answer) {
      var startOfTheLink = answer.indexOf('to=') + 4;
      var endOfTheLink = answer.indexOf('"', startOfTheLink);
      var cut = answer.slice(startOfTheLink, endOfTheLink);
      return cut;
    },
    getTitleToRoute: function getTitleToRoute(answer) {
      var startOfTheTitle = answer.indexOf('>') + 1;
      var endOfTheTitle = answer.indexOf('</router-link');
      var cut = answer.slice(startOfTheTitle, endOfTheTitle);
      return cut;
    },
    toogleExportToWordModal: function toogleExportToWordModal() {
      this.wordModal = !this.wordModal;
    },
    prepareProcessorAuditData: function prepareProcessorAuditData(node) {
      var questionnaireOpenMessage = node.requestExpired ? this.translate.deadlineExceeded : node.firstClick.substring(0, 10);
      var questionnaireAnsweredMessage = node.requestExpired ? this.translate.questionnaireAnswerNotReceived : node.answered.substring(0, 10);
      return {
        name: {
          field: node.fullname,
          fieldText: node.fullname
        },
        email: {
          field: node.email,
          fieldText: node.email
        },
        sent: {
          field: node.send.substring(0, 10),
          fieldText: node.send.substring(0, 10)
        },
        questionnaireOpen: {
          field: questionnaireOpenMessage,
          fieldText: questionnaireOpenMessage
        },
        questionnaireAnswered: {
          field: questionnaireAnsweredMessage,
          fieldText: questionnaireAnsweredMessage
        }
      };
    },
    prepareArticle30Data: function prepareArticle30Data(node) {
      var _this7 = this;
      var result = {
        description: {
          field: node.description,
          fieldText: removeHtmlTags(node.description),
          isHtml: true
        },
        purpose: {
          field: node.purpose,
          fieldText: removeHtmlTags(node.purpose),
          isHtml: true
        },
        status: {
          field: node.status,
          fieldText: removeHtmlTags(node.status),
          isHtml: true
        },
        tags: {
          field: node.tags
        },
        questionnaire: {
          field: node.questionnaire
        }
      };
      if (this.viewType === VIEW_TYPES.EXPANDED) {
        var props = this.includeRaciEmails ? ['responsibleUsers', 'responsibleEmails', 'accountableUsers', 'accountableEmails', 'consultedUsers', 'consultedEmails', 'informedUsers', 'informedEmails', 'organisations'] : ['responsibleUsers', 'accountableUsers', 'consultedUsers', 'informedUsers', 'organisations'];
        props.forEach(function (prop) {
          return _this7.addNodeToTheData(node, prop, result);
        });
      }
      if (this.showControllersAndProcessors) {
        result.dataProcessors = this.getDataProcessorsField(node.dataProcessors);
        result.dataControllers = {
          hasContactData: node.dataControllers && node.dataControllers.length,
          field: '',
          data: {
            controllers: node.dataControllers
          },
          fieldText: this.formatIaFieldText({
            controllers: node.dataControllers || []
          })
        };
      }
      if (this.isGDPR) {
        result.dpo = {
          field: node.dpoName || ''
        };
      }
      if (this.includeDataFlow) {
        result.dataFlow = {
          field: node.dataFlowUrl ? '' : this.noDataFlowMessage,
          fieldText: node.dataFlowUrl ? this.translate.yes : this.translate.no,
          imageUrl: node.dataFlowUrl
        };
      }
      return result;
    },
    getDatasetToExport: function getDatasetToExport(dataset) {
      return dataset.map(function (item) {
        var result = {};
        Object.entries(item).forEach(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            key = _ref4[0],
            value = _ref4[1];
          result[key] = Object.hasOwn(value, 'fieldText') ? value.fieldText : value.field;
        });
        return result;
      });
    }
  })
};