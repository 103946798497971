// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th[data-v-2244f71d] {
  text-align: left;
}
.history-table[data-v-2244f71d] {
  table-layout: fixed;
}
.history-table th[data-v-2244f71d]:nth-child(n+6) {
  width: 18%;
}
.history-table td[data-v-2244f71d] {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
th[data-v-2244f71d],
td[data-v-2244f71d] {
  padding: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
