import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import Notification from '@/components/Molecules/Notification';
import { createKpis as _createKpis, runKpis as _runKpis, getDashboards } from '@/api/rismaadmin/kpi';
export default {
  name: 'Kpis',
  components: {
    Notification: Notification,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    MainWell: MainWell
  },
  data: function data() {
    return {
      dashboards: null,
      info: '',
      translate: {
        createDefaultKpis: this.$trans('Create default KPIs'),
        runKPIs: this.$trans('Run all enabled KPIs'),
        runKPIsHeader: this.$trans('Run KPIs'),
        all: this.$trans('All')
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    getDashboards().then(function (_ref) {
      var list = _ref.list;
      return _this.dashboards = list;
    });
  },
  methods: {
    createKpis: function createKpis(index) {
      var _this2 = this;
      this.info = '';
      _createKpis(index).then(function (response) {
        return _this2.info = response;
      });
    },
    runKpis: function runKpis() {
      var _this3 = this;
      this.info = 'Kpis run started...';
      return _runKpis().then(function (response) {
        return _this3.info = response;
      }).catch(function (error) {
        _this3.$notify({
          title: error.response.error,
          type: 'error'
        });
        _this3.info = '';
      });
    }
  }
};