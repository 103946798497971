import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
  class: "preview-risk-customfields"
};
var _hoisted_2 = {
  class: "preview-risk-customfields-section"
};
var _hoisted_3 = {
  key: 0,
  class: "ml-2"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.customRiskFields, ":"),
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.fields, function (field) {
    return _openBlock(), _createElementBlock("p", {
      key: field.id,
      class: "preview-risk-customfield flex items-center"
    }, [field.fieldType === $options.customFieldTypes.TRAFFIC_LIGHT ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("b", null, _toDisplayString(field.fieldLabel), 1 /* TEXT */), _cache[0] || (_cache[0] = _createTextVNode(" : ")), field.fieldValue === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($options.notSetMessage), 1 /* TEXT */)) : (_openBlock(), _createBlock(_component_traffic_light, {
      key: 1,
      color: $options.getTrafficLightColor(field.fieldValue).color,
      class: "ml-2"
    }, null, 8 /* PROPS */, ["color"]))], 64 /* STABLE_FRAGMENT */)) : field.fieldType === $options.customFieldTypes.TREND ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createElementVNode("b", null, _toDisplayString(field.fieldLabel || field.fieldValue), 1 /* TEXT */), _cache[1] || (_cache[1] = _createTextVNode(" : ")), field.value === 0 ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createTextVNode(_toDisplayString($options.notSetMessage), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [field.fieldValue ? (_openBlock(), _createElementBlock("span", _hoisted_4, "  " + _toDisplayString($options.trendMapping[field.fieldValue].label), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_5, [_createVNode(_component_feather_icon, {
      icon: $options.formatTenders(field.value)
    }, null, 8 /* PROPS */, ["icon"])]))], 64 /* STABLE_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 2
    }, [_createElementVNode("b", null, _toDisplayString(field.fieldLabel), 1 /* TEXT */), _createTextVNode(" : " + _toDisplayString(field.fieldValue || $options.notSetMessage), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}