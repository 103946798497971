import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  key: 1
};
var _hoisted_2 = {
  class: "flex justify-between"
};
var _hoisted_3 = {
  class: "hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$currentActi;
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return $data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: ((_$options$currentActi = $options.currentActivityType) === null || _$options$currentActi === void 0 ? void 0 : _$options$currentActi.label) || ''
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_router_link, {
    to: {
      name: $options.baseLocationName
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "minimize-2",
        class: "text-blue-750 stroke-2",
        height: "30",
        width: "30"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])]), $data.dataset.length ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 0,
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "decouple-filter-fields": $data.decoupleFilterFields,
    "disabled-filter-by-columns": $data.disabledFilterByColumns,
    "filter-by-column": true,
    "wrapper-height-sticky": 350,
    "columns-meta": $data.columnsMeta,
    "state-local": "incident_type_expanded_state",
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    title: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_createElementVNode("p", null, [_createVNode(_component_router_link, {
        to: item.field.url
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_risma_title_with_icon, {
            class: "font-semibold hover:underline",
            icon: "confidential",
            title: item.field.title,
            "title-attribute": item.field.hoverTitle,
            "display-icon": item.field.confidential
          }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.slotLinkFields, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.field, function (linkItem, index) {
          return _openBlock(), _createBlock(_component_preview_modal_link, {
            key: "".concat(linkCol, "_").concat(index),
            activity: linkItem.activity,
            class: "hover:underline",
            onUpdated: function onUpdated($event) {
              return $options.updateLinkedFromPreview($event, linkCol);
            },
            onDismissModal: $options.onLinkModalDismiss
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", null, _toDisplayString(linkItem.title) + _toDisplayString(index === item.field.length - 1 ? '' : ','), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onUpdated", "onDismissModal"]);
        }), 128 /* KEYED_FRAGMENT */))])];
      })
    };
  }), _renderList(_ctx.trafficLightRismaDatatableFields, function (trafficLightCol) {
    return {
      name: trafficLightCol,
      fn: _withCtx(function (_ref3) {
        var item = _ref3.item;
        return [item.field.color ? (_openBlock(), _createElementBlock("div", {
          key: "".concat(trafficLightCol)
        }, [_createVNode(_component_traffic_light, {
          color: item.field.color
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_3, _toDisplayString(item.field.value) + "-" + _toDisplayString(item.field.label), 1 /* TEXT */)])) : (_openBlock(), _createElementBlock("span", {
          key: "".concat(trafficLightCol, "_span")
        }))];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "decouple-filter-fields", "disabled-filter-by-columns", "columns-meta", "onPageChange", "onOnStateChanged"])) : (_openBlock(), _createBlock(_component_risma_title, {
    key: 1,
    class: "text-center mt-12",
    type: "medium",
    title: $data.translate.nothingToShow
  }, null, 8 /* PROPS */, ["title"]))]));
}