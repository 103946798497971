// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-risk-compact[data-v-03a9eb10] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 15px;
  padding-top: 5px;
  margin-bottom: 24px;
  border: 1px solid #CCCCCC;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
  z-index: 2;
  background-repeat: no-repeat;
  background-position: -85% 50%;
  background-size: 80%;
  background-image: url("/assets/svg/ikon_risk_opacity_07.svg");
}
.preview-risk-compact .preview-title[data-v-03a9eb10] {
  min-width: 300px;
  padding-right: 50px;
  margin-right: auto;
  overflow: hidden;
  vertical-align: center;
}
.preview-risk-compact .preview-title[data-v-03a9eb10]  .big {
  font-size: 24px;
}
.preview-risk-compact .preview-description[data-v-03a9eb10] {
  width: 250px;
  padding-right: 50px;
  padding-top: 10px;
  flex-shrink: 0;
}
.preview-risk-compact .preview-description[data-v-03a9eb10]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.preview-risk-compact .preview-description[data-v-03a9eb10]  ul ul {
  list-style-type: circle;
}
.preview-risk-compact .preview-description[data-v-03a9eb10]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.preview-risk-compact .preview-description[data-v-03a9eb10]  li {
  padding-left: 0.3em;
}
.preview-risk-compact .preview-organisations[data-v-03a9eb10] {
  width: 200px;
  padding-right: 50px;
  padding-top: 10px;
  flex-shrink: 0;
}
.preview-risk-compact .preview-tags[data-v-03a9eb10] {
  width: 160px;
  padding-right: 10px;
  padding-top: 10px;
  flex-shrink: 0;
}
.preview-risk-compact .preview-approval-flow[data-v-03a9eb10] {
  width: 160px;
  padding-right: 10px;
  padding-top: 10px;
  flex-shrink: 0;
}
.preview-risk-compact .preview-approval-flow[data-v-03a9eb10]  h3.medium {
  font-weight: 400;
  color: #f25050;
}
.preview-risk-compact .preview-approval-flow.is-approved[data-v-03a9eb10]  h3.medium {
  color: #4D4D4D;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
