import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_editable_title = _resolveComponent("risma-editable-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_editable_title, {
    title: "333",
    "min-input-width": 10,
    "is-id": true,
    class: "mr-2"
  }), _createVNode(_component_risma_editable_title, {
    title: "Test title"
  })]);
}