import i18n from '@/i18n';
export var getTranslate = {
  NewChoice: function NewChoice() {
    return {
      addNewAnswer: i18n.t('Add new answer'),
      newAnswer: i18n.t('New answer'),
      add: i18n.t('Add'),
      youCantSubmitABlankAnswer: i18n.t("You can't submit a blank answer"),
      answerAlreadyExists: i18n.t('Answer already exists')
    };
  },
  SingleAssetChoice: function SingleAssetChoice() {
    return {
      isAreAlreadyAddedByADifferentUser: i18n.t('is/are already added by a different user'),
      youCantSeeOrEditTheseAssetBecauseYourUserRightInhibitsYouToInteractWithIt: i18n.t("You can't see or edit these asset(s) because your user right inhibits you to interact with it")
    };
  },
  ProcessLibraryChoice: function ProcessLibraryChoice() {
    return {
      isAreAlreadyAddedByADifferentUser: i18n.t('is/are already added by a different user'),
      youCantSeeOrEditTheseProcessLibraryBecauseYourUserRightInhibitsYouToInteractWithIt: i18n.t("You can't see or edit these process library nodes because your user right inhibits you to interact with it")
    };
  },
  AttachmentChoice: function AttachmentChoice() {
    return {
      somethingWentWrongWhileUploading: i18n.t('Something went wrong while uploading'),
      youNeedToGiveTheLinkAnUrl: i18n.t('You need to give the link an URL'),
      youNeedToGiveTheLinkAName: i18n.t('You need to give the link a name'),
      areYouSureYouWantToDelete: i18n.t('Are you sure you want to delete'),
      delete: i18n.t('Delete'),
      deleteFile: i18n.t('Delete file')
    };
  },
  QuizQuestion: function QuizQuestion() {
    return {
      question: i18n.t('Question'),
      addNewQuestionOption: i18n.t('Add new question option'),
      insertQuestionDescription: i18n.t('Insert question description'),
      enterQuestionOption: i18n.t('Enter question answer option'),
      correctAnswer: i18n.t('Correct answer'),
      selectCorrectAnswer: i18n.t('Select correct answer'),
      notPossibleToDeleteLastOption: i18n.t('It’s not possible to delete the last option')
    };
  }
};