import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  id: "questionnaireSelection",
  class: "my-5"
};
var _hoisted_2 = {
  class: "grid grid-cols-3 gap-x-6 gap-y-4"
};
var _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.isIA ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    title: "".concat(_ctx.informationAssetsTitle(), " ").concat($data.translate.type),
    options: $options.assetTypes,
    "model-value": $data.selectedAssetType,
    "un-sorted": true,
    placeholder: $data.translate.chooseTypes,
    "onUpdate:modelValue": $options.assetTypeSelected
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
    title: $data.translate.questionnaire,
    options: $data.questionnairesOptions,
    "model-value": $data.selectedQuestionnaireId,
    "un-sorted": true,
    placeholder: $data.translate.selectAQuestionnaire,
    "onUpdate:modelValue": $options.questionnaireSelected
  }, null, 8 /* PROPS */, ["title", "options", "model-value", "placeholder", "onUpdate:modelValue"]), $props.isIA ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : _createCommentVNode("v-if", true), $data.assetsLoaded && $props.isIA ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [_createVNode(_component_risma_select, {
    title: _ctx.informationAssetsTitle(),
    options: $options.availableAssetOptions,
    "selected-options": $data.selectedAssets,
    placeholder: $data.translate.selectSpecificAssets,
    "label-key": "title",
    onSelected: _cache[0] || (_cache[0] = function ($event) {
      return $data.selectedAssets = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"]), _createVNode(_component_risma_select, {
    title: $data.translate.organisation,
    options: $options.availableOrganisations,
    "selected-options": $data.selectedOrganisations,
    placeholder: $data.translate.selectOrganisations,
    "label-key": "label",
    onSelected: _cache[1] || (_cache[1] = function ($event) {
      return $data.selectedOrganisations = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"]), _createVNode(_component_risma_select, {
    title: $data.translate.responsible,
    options: $options.availableResponsible,
    "selected-options": $data.selectedResponsible,
    placeholder: $data.translate.selectResponsible,
    "label-key": "label",
    onSelected: _cache[2] || (_cache[2] = function ($event) {
      return $data.selectedResponsible = $event;
    })
  }, null, 8 /* PROPS */, ["title", "options", "selected-options", "placeholder"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]), !$data.assetsLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 0
  })) : _createCommentVNode("v-if", true)]);
}