// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li[data-v-b2601976] {
  display: inline;
}
li a[data-v-b2601976] {
  display: inline-block;
  color: #FFFFFF;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  background: #0e2954;
  height: 3rem;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 30px;
  padding: 0.5em 1em;
  margin: 0;
  text-decoration: none;
}
li a[data-v-b2601976]:hover {
  border-color: #BEBEBE #BEBEBE #737373;
}
li a.link-active[data-v-b2601976] {
  background-color: #637994;
  height: 4rem;
  line-height: 3rem;
  font-size: 1.6rem;
  vertical-align: bottom;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
