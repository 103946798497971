import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "overflow-auto max-h-200px"
};
var _hoisted_3 = {
  class: "px-4 w-1/2"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "control-saved-report-item-descr pr-4 w-1/2"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "overflow-auto max-h-200px"
};
var _hoisted_8 = {
  class: "flex relative border border-slate-300 rounded-md w-4/5 p-1 mr-3"
};
var _hoisted_9 = {
  class: "px-4 w-1/2"
};
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
  class: "control-saved-report-item-descr w-1/2 flex items-center"
};
var _hoisted_12 = {
  class: "flex-grow pr-4"
};
var _hoisted_13 = {
  key: 0
};
var _hoisted_14 = ["onClick"];
var _hoisted_15 = {
  key: 1,
  class: "mt-3 text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [$data.defaultReports.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $options.defaultReportsTitle,
    class: "mt-4 mb-2",
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.defaultReports, function (item, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "relative mb-1 flex"
    }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
      class: "text-blue-750 font-semibold cursor-pointer hover:underline",
      onClick: function onClick($event) {
        return $options.goToReport(item);
      }
    }, _toDisplayString(item.title), 9 /* TEXT, PROPS */, _hoisted_4)]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.comment), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.reportGroups, function (report, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx
    }, [report.reports.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_risma_title, {
      title: report.title,
      class: "mt-4 mb-2",
      type: "medium"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(report.reports, function (item, key) {
      return _openBlock(), _createElementBlock("div", {
        key: key,
        class: "flex items-center mb-1"
      }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("span", {
        class: "text-blue-750 font-semibold cursor-pointer hover:underline",
        onClick: function onClick($event) {
          return $options.goToReport(item);
        }
      }, _toDisplayString(item.title), 9 /* TEXT, PROPS */, _hoisted_10)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("p", _hoisted_12, _toDisplayString(item.comment), 1 /* TEXT */), report.access && (_ctx.isAdmin || _ctx.currentUser.id === item.userId) ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_feather_icon, {
        "data-test-id": "delete-report-icon",
        icon: "x",
        class: "stroke-3 text-orange-50 mx-2 cursor-pointer",
        onClick: function onClick($event) {
          return $options.tryToDelete(item);
        }
      }, null, 8 /* PROPS */, ["onClick"])])) : _createCommentVNode("v-if", true)])]), report.shareOption ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "cursor-pointer flex items-center",
        onClick: function onClick($event) {
          return $options.editReport(item);
        }
      }, [_createVNode(_component_feather_icon, {
        icon: "share",
        class: "stroke-2 text-blue-750 mx-2"
      }), _createVNode(_component_risma_title, {
        title: $data.translate.shareWithAll,
        type: "small"
      }, null, 8 /* PROPS */, ["title"])], 8 /* PROPS */, _hoisted_14)) : _createCommentVNode("v-if", true)]);
    }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), !$options.isAnyReportAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString($data.translate.noSavedReports), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]);
}