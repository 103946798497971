import i18n from '@/i18n';
export var getTranslate = {
  RismaVueMultiselect: function RismaVueMultiselect() {
    return {
      search: i18n.t('Search'),
      areYouSureYouWantToAdd: i18n.t('Are you sure you want to add'),
      newItems: i18n.t('new items'),
      warning: i18n.t('Warning'),
      youAreTryingToSelectAllItems: i18n.t('You are trying to select all items'),
      selected: i18n.t('selected'),
      more: i18n.t('more'),
      noDataAvailable: i18n.t('No data available')
    };
  },
  RismaVueMultiselectDropdown: function RismaVueMultiselectDropdown() {
    return {
      selectAll: i18n.t('Select all'),
      deselectAll: i18n.t('Deselect all'),
      listIsEmpty: i18n.t('List is empty'),
      noElementsFoundConsiderChangingTheSearchQu: i18n.t('No elements found. Consider changing the search query.'),
      search: i18n.t('Search'),
      scrollDownForMoreResults: i18n.t('Scroll down for more results...')
    };
  }
};