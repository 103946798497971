import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_activity_type_selector = _resolveComponent("activity-type-selector");
  return _openBlock(), _createElementBlock("div", null, [_cache[4] || (_cache[4] = _createTextVNode(" With minimal properties ")), _createVNode(_component_activity_type_selector, {
    modelValue: $data.values[0],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.values[0] = $event;
    }),
    class: "mt-4 mb-2 max-w-350px",
    "activity-types": _ctx.activityTypes
  }, null, 8 /* PROPS */, ["modelValue", "activity-types"]), _cache[5] || (_cache[5] = _createTextVNode(" Locked ")), _createVNode(_component_activity_type_selector, {
    modelValue: $data.values[1],
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.values[1] = $event;
    }),
    class: "mb-2 max-w-350px",
    disabled: true,
    "activity-types": _ctx.activityTypes
  }, null, 8 /* PROPS */, ["modelValue", "activity-types"]), _cache[6] || (_cache[6] = _createTextVNode(" Cannot be reseted ")), _createVNode(_component_activity_type_selector, {
    modelValue: $data.values[2],
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.values[2] = $event;
    }),
    class: "mb-2 max-w-350px",
    "can-be-reset": false,
    "activity-types": _ctx.activityTypes
  }, null, 8 /* PROPS */, ["modelValue", "activity-types"]), _cache[7] || (_cache[7] = _createTextVNode(" With alert on change ")), _createVNode(_component_activity_type_selector, {
    modelValue: $data.values[3],
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.values[3] = $event;
    }),
    class: "mb-2 max-w-350px",
    "confirm-alert-message": "Alert message",
    "activity-types": _ctx.activityTypes
  }, null, 8 /* PROPS */, ["modelValue", "activity-types"])]);
}