function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import LoadMoreWrapper from '@/components/Molecules/LoadMoreWrapper';
import ExpandedCard from '@/components/Molecules/Cards/ExpandedCard';
import TileCard from '@/components/Molecules/Cards/TileCard';
import CompactCard from '@/components/Molecules/Cards/CompactCard';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { ControlMyKeys } from '@/constants/ControlMyKeys';
import { viewTypes } from '@/constants/ListViewType';
import { ControlButtonTypes } from '@/constants/ControlButtonTypes';
import { isSignoffPeriodPending as _isSignoffPeriodPending } from '@/utils/controls/utils';
import { MODULES } from '@/constants/modules';
var MODULE_NAME = MODULES.CONTROLS;
export default {
  name: 'ControlsList',
  components: {
    LoadMoreWrapper: LoadMoreWrapper,
    ExpandedCard: ExpandedCard,
    TileCard: TileCard,
    CompactCard: CompactCard,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle
  },
  props: {
    activities: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    filteredOrganisations: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    buttonType: {
      type: String,
      required: false,
      default: null,
      validator: function validator(type) {
        return [ControlButtonTypes.COMPLETE_CONTROL_AGAIN, ControlButtonTypes.REVIEW_CONTROL, ControlButtonTypes.COMPLETE_CONTROL].includes(type);
      }
    },
    additionalFields: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    currentGroup: {
      type: String,
      required: false,
      default: ControlMyKeys.MY_CONTROLS
    },
    viewType: {
      type: String,
      required: false,
      default: viewTypes.TILES,
      validator: function validator(type) {
        return [viewTypes.TILES, viewTypes.EXPANDED, viewTypes.COMPACT].includes(type);
      }
    }
  },
  emits: ['showCompleteModal', 'onDismissModal', 'showReviewModal', 'deleteItem', 'updateFromPreviewMode', 'completeFromPreviewMode', 'reloadRootPage'],
  data: function data() {
    return {
      ControlButtonTypes: ControlButtonTypes,
      viewTypes: viewTypes,
      MODULE_NAME: MODULE_NAME,
      ControlMyKeys: ControlMyKeys,
      translate: getTranslate['ControlsList']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showCompleteControlButton: function showCompleteControlButton() {
      return this.buttonType === ControlButtonTypes.COMPLETE_CONTROL || this.buttonType === ControlButtonTypes.COMPLETE_CONTROL_AGAIN;
    },
    showReviewControlButton: function showReviewControlButton() {
      return this.buttonType === ControlButtonTypes.REVIEW_CONTROL;
    }
  }),
  methods: {
    isSignoffPeriodPending: function isSignoffPeriodPending(activity) {
      return _isSignoffPeriodPending(activity.signoffStart, activity.frequency, activity.trafficLight, this.getSettingValue);
    },
    onCompleteControl: function onCompleteControl($event) {
      this.$emit('completeFromPreviewMode', $event);
    },
    onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
      this.$emit('updateFromPreviewMode', $event);
    }
  }
};