import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "container mx-auto"
};
var _hoisted_2 = {
  key: 1,
  class: "initiatives-header"
};
var _hoisted_3 = {
  class: "pr-6 py-6"
};
var _hoisted_4 = {
  class: "w-215px mr-4"
};
var _hoisted_5 = {
  key: 2,
  class: "mt-6"
};
var _hoisted_6 = {
  key: 3
};
var _hoisted_7 = {
  key: 4
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  var _component_single_select = _resolveComponent("single-select");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_preview_list_expanded = _resolveComponent("preview-list-expanded");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_gantt_chart_actions = _resolveComponent("gantt-chart-actions");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $props.isHeaderVisible ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_catalogue_header, {
    "view-type": $setup.currentView,
    "view-types": $data.MY_ACTIONS_VIEWS,
    "is-overview-options-available": true,
    "overview-options-state": $setup.overviewOptionsState,
    "onUpdate:viewType": $options.setView,
    "onUpdate:overviewoption": _cache[0] || (_cache[0] = function ($event) {
      return $setup.overviewOptionsState = $event;
    })
  }, null, 8 /* PROPS */, ["view-type", "view-types", "overview-options-state", "onUpdate:viewType"]), _createVNode(_Transition, {
    name: "fade",
    persisted: ""
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
        title: $data.translate.sortBy,
        placeholder: $data.translate.sortBy,
        options: $options.sortOptions,
        "model-value": $setup.sortBy,
        "un-sorted": $data.unSorted,
        "onUpdate:modelValue": $options.sortInitiatives
      }, null, 8 /* PROPS */, ["title", "placeholder", "options", "model-value", "un-sorted", "onUpdate:modelValue"])])], 512 /* NEED_PATCH */), [[_vShow, $setup.overviewOptionsState]])];
    }),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true), $data.isLoaded && $setup.currentView === $data.InitiativesViews.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.isOnlyMyInitiatives ? [$data.initiativesGroups.myInitiatives] : $data.initiativesGroups, function (group, index) {
    return _openBlock(), _createBlock(_component_preview_list_tile, {
      key: index,
      activities: group.activities,
      title: _ctx.$trans(group.label),
      "cookie-hidden-name": group.cookieName,
      "activity-types": _ctx.activityTypes,
      "watermark-image": "url('/assets/svg/ikon_execution.svg')",
      onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
        return $options.updateFromPreviewMode($event, group.id);
      },
      onOnDismissModal: $options.onPreviewDismiss
    }, null, 8 /* PROPS */, ["activities", "title", "cookie-hidden-name", "activity-types", "onUpdateFromPreviewMode", "onOnDismissModal"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $data.isLoaded && $setup.currentView === $data.InitiativesViews.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.initiativesGroups, function (group, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "mb-8"
    }, [_createVNode(_component_preview_list_expanded, {
      "preview-mode": true,
      title: _ctx.$trans(group.label),
      activities: group.activities,
      "cookie-hidden-name": group.cookieName + 'expanded',
      users: _ctx.activeUsers,
      organisations: _ctx.organisations,
      "activity-types": _ctx.activityTypes,
      "watermark-image": "url('/assets/svg/ikon_execution.svg')",
      onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
        return $options.updateFromPreviewMode($event, group.id);
      },
      onOnDismissModal: $options.onPreviewDismiss
    }, null, 8 /* PROPS */, ["title", "activities", "cookie-hidden-name", "users", "organisations", "activity-types", "onUpdateFromPreviewMode", "onOnDismissModal"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $data.isLoaded && $setup.currentView === $data.InitiativesViews.ROADMAP ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.initiativesGroups, function (group, key) {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: "mb-8"
    }, [_createVNode(_component_risma_title, {
      title: group.label,
      type: "big"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_gantt_chart_actions, {
      "has-preview": true,
      activities: group.activities,
      "is-sorting-visible": false,
      "period-cookie": "my-actions-gantt",
      onUpdateFromPreviewMode: _cache[1] || (_cache[1] = function ($event) {
        return $data.updatedActionFromPreview = $event;
      }),
      onDismissModal: $options.onPreviewDismiss
    }, null, 8 /* PROPS */, ["activities", "onDismissModal"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}