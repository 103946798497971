import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = ["value"];
var _hoisted_2 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("select", {
    value: $props.modelValue,
    class: "bg-transparent inline-block cursor-pointer",
    style: _normalizeStyle($props.optionStyles),
    onInput: _cache[0] || (_cache[0] = function () {
      return $options.onInput && $options.onInput.apply($options, arguments);
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, function (item, idx) {
    return _openBlock(), _createElementBlock("option", {
      key: item[$props.valueField] || idx,
      style: _normalizeStyle($props.optionStyles),
      value: item[$props.valueField] || idx
    }, _toDisplayString(item.label), 13 /* TEXT, STYLE, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1);
}