// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cf-row[data-v-038a6308] {
  display: flex;
  margin: 0 0 5px;
  position: relative;
}
.cf-row[data-v-038a6308]:nth-child(odd) {
  background: #ebebeb;
}
.cf-row .cf-row-number[data-v-038a6308] {
  align-self: flex-start;
  width: 40px;
  padding-right: 15px;
  padding-top: 15px;
  text-align: right;
}
.cf-row .cf-row-actions[data-v-038a6308] {
  display: flex;
  align-items: center;
}
.cf-component[data-v-038a6308] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 180px;
  padding: 5px;
  font-size: 14px;
  vertical-align: top;
}
.cf-component[data-v-038a6308]:first-child {
  padding-left: 0;
}
.cf-component.field-type-3 textarea[data-v-038a6308] {
  width: 100%;
}
.cf-component.field-type-6[data-v-038a6308] {
  width: 250px;
}
.cf-component.field-type-7[data-v-038a6308] {
  width: 250px;
}
.cf-component.field-type-4[data-v-038a6308]  .traffic-light-selector {
  padding-top: 7px;
  padding-left: 3px;
}
.cf-component[data-v-038a6308]  .select-selected {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.cf-component[data-v-038a6308]  .risma-title p {
  margin: 0 0 5px;
  font-weight: 600;
}
.repeatable-textarea[data-v-038a6308] {
  width: 100%;
  max-width: 100%;
  height: 80px;
  min-height: 41px;
  resize: both;
  white-space: normal;
  background-image: url("/assets/svg/stripes_corner.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 9px 9px;
}
.repeatable-textarea[data-v-038a6308]::-webkit-resizer, .repeatable-textarea[data-v-038a6308]::-webkit-scrollbar-corner {
  background-image: url("/assets/svg/stripes_corner.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 9px 9px;
}
.repeatable-textarea.expanded[data-v-038a6308] {
  position: relative;
  z-index: 1;
}
.repeatable-textarea.form-control[data-v-038a6308] {
  font-style: normal !important;
  color: #4D4D4D !important;
  font-family: inherit !important;
  font-size: 15px !important;
}
.repeatable-textarea.form-control[data-v-038a6308]:disabled {
  color: #BEBEBE !important;
  border: 1px solid #BEBEBE !important;
  background-color: #f3f3f3 !important;
}
.help-tool-tip[data-v-038a6308]  .tool-tip {
  min-width: 400px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
