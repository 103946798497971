function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import $ from 'jquery';
import { Http } from '@/api/index';
import { i18n } from '@/i18n';

/**
 * Check the status of the api response
 * @param response
 * @returns {*}
 */
export function checkHttpStatus(response) {
  var status = (response === null || response === void 0 ? void 0 : response.status) || null;
  if (status >= 400 && status <= 499) throw new Error('Client made a fuckup');
  if (status >= 500 && status <= 599) throw new Error('Server made a fuckup');
  return response;
}

/**
 * Throw an Error if the response fails
 * If status code is 401 the user will be redirected to '/' to have a login prompt displayed
 * @param error
 * @returns {string}
 */
export function throwError(error) {
  var skip401Check = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (error && error.response) {
    // Redirect to login page
    if (error.response.status === 401 && !skip401Check) {
      return window.location.href = '/?returnUrl=' + window.location.pathname + '&reason=throwError401';
    }
    throw {
      status: error.response.status,
      statusText: error.response.statusText,
      response: error.response.data,
      fullError: error
    };
  }
  throw error;
}
export function objectToQueryString(object) {
  return $.param(object);
}
export function getDataQueryString(value) {
  return "data[]=".concat(value);
}
export function getDataQueryStringFromArray(array) {
  return array.reduce(function (acc, item) {
    if (acc) acc += '&';
    acc += getDataQueryString(item);
    return acc;
  }, '');
}
export function jsonToRismaFilterQueryString(json) {
  return '?' + Object.keys(json).map(function (key) {
    return 'filters[' + encodeURIComponent(key) + ']=' + encodeURIComponent(json[key]);
  }).join('&');
}
export function getByUrlAndParams(url, params) {
  var queryString = objectToQueryString(params);
  return getByUrl("".concat(url, "?").concat(queryString));
}
export function getByUrl(url) {
  return Http.get(url).then(function (response) {
    return response.data;
  }).catch(function (err) {
    return throwError(err);
  });
}
export function generateDataQueryString(dataItems) {
  var result = dataItems.map(getDataQueryString).join('&');
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}
export function generateIdsQueryString(ids) {
  var result = ids.map(function (id) {
    return getQueryStringByKey('ids', id);
  }).join('&');
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}
export function generateFiltersQueryString(entries) {
  var result = '';
  Object.entries(entries).forEach(function (item) {
    var _item = _slicedToArray(item, 2),
      key = _item[0],
      value = _item[1];
    result += getFiltersQueryString(key, value) + '&';
  });
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}
export function getFiltersQueryString(key, value) {
  var include = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var filterKey = include ? 'filters' : 'excludes';
  if (typeof value === 'string' || typeof value === 'number') {
    return "".concat(filterKey, "[").concat(key, "]=").concat(value);
  }
  if (Array.isArray(value)) {
    var result = value.map(function (item) {
      return "".concat(filterKey, "[").concat(key, "][]=").concat(item);
    }).join('&');
    if (result.endsWith('&')) {
      result = result.slice(0, -1);
    }
    return result;
  }
  return '';
}
export function getQueryStringByKey(key, value) {
  return "".concat(key, "[]=").concat(value);
}
export function handleRequestResponse(promise) {
  var skip401Check = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return promise.then(function (response) {
    return checkHttpStatus(response);
  }).then(function (response) {
    return response.data;
  }).catch(function (err) {
    return throwError(err, skip401Check);
  });
}
export function downloadBlobAsFile(blob) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'doc';
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}
export function getTooLongError(query) {
  if ((query === null || query === void 0 ? void 0 : query.length) > 65000) {
    return i18n.t('Too much data. Please narrow your search');
  }
  return false;
}