import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "py-4 px-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_external_section_overview = _resolveComponent("external-section-overview");
  var _component_expansion_panel = _resolveComponent("expansion-panel");
  return _openBlock(), _createBlock(_component_expansion_panel, {
    "start-expanded": true
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.assessmentOfTheIctServices,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_external_section_overview, {
        data: $props.section,
        options: $props.options,
        onUpdate: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update', $event);
        })
      }, null, 8 /* PROPS */, ["data", "options"])])];
    }),
    _: 1 /* STABLE */
  });
}