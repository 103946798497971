import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-col"
};
var _hoisted_2 = {
  class: "p-6"
};
var _hoisted_3 = {
  class: "p-6"
};
var _hoisted_4 = {
  class: "p-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_compliance_general_list = _resolveComponent("compliance-general-list");
  return _openBlock(), _createElementBlock("div", null, [_cache[2] || (_cache[2] = _createElementVNode("p", null, "Lia nodes, Tia nodes, Processors list example for project 1", -1 /* HOISTED */)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_compliance_general_list, {
    "project-id": $data.projectId,
    "asset-type": $data.AssetsTypes.TIA_NODES
  }, null, 8 /* PROPS */, ["project-id", "asset-type"])]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_compliance_general_list, {
    "project-id": $data.projectId,
    "asset-type": $data.AssetsTypes.LIA_NODES
  }, null, 8 /* PROPS */, ["project-id", "asset-type"])]), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_4, [_createVNode(_component_compliance_general_list, {
    "project-id": $data.projectId,
    "asset-type": $data.AssetsTypes.PROCESSORS
  }, null, 8 /* PROPS */, ["project-id", "asset-type"])])])]);
}