import { getDataflowReport } from '@/api/compliance/reports/dataflows';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
export default {
  name: 'DataflowThinReport',
  description: 'Thin Dataflow report',
  token: '<dataflow-thin-report project-id="1"/>',
  components: {
    RismaTable: RismaTable
  },
  props: {
    projectId: {
      required: true,
      type: [String, Number],
      note: 'ID of current project'
    }
  },
  data: function data() {
    return {
      stateLocal: 'dataflow-report-risma-table-key'
    };
  },
  methods: {
    getDataflowsBE: function getDataflowsBE(params) {
      return getDataflowReport(this.projectId, "?".concat(params));
    }
  }
};