function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import { sortAlphabetic } from '@/utils/sort';
import { ObjectTypes } from '@/constants/ObjectTypes';
export var InformationasstesOverviewMixin = {
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    systemsEnabled: function systemsEnabled() {
      return this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems');
    }
  }),
  methods: {
    filterByObjTypes: function filterByObjTypes(informationAssets, activityTypes) {
      var iaListByObjType = [];
      var MAX_CONST_ID = 3;
      if (this.getSettingValue('feature.enable_data_controllers') == undefined || this.getSettingValue('feature.enable_data_controllers')) {
        var _informationAssets$co, _informationAssets$co2;
        iaListByObjType.push({
          id: 1,
          label: AssetsTypes.CONTROLLERS,
          type: AssetsTypes.CONTROLLERS,
          objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER,
          list: ((_informationAssets$co = informationAssets.controllers) === null || _informationAssets$co === void 0 ? void 0 : _informationAssets$co.list) || [],
          total: ((_informationAssets$co2 = informationAssets.controllers) === null || _informationAssets$co2 === void 0 ? void 0 : _informationAssets$co2.total) || 0
        });
      }
      if (this.getSettingValue('feature.enable_data_processors') == undefined || this.getSettingValue('feature.enable_data_processors')) {
        var _informationAssets$pr, _informationAssets$al, _informationAssets$pr2;
        iaListByObjType.push({
          id: 2,
          label: AssetsTypes.PROCESSORS,
          type: AssetsTypes.PROCESSORS,
          objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR,
          list: ((_informationAssets$pr = informationAssets.processors) === null || _informationAssets$pr === void 0 ? void 0 : _informationAssets$pr.list) || [],
          fullList: ((_informationAssets$al = informationAssets.allProcessors) === null || _informationAssets$al === void 0 ? void 0 : _informationAssets$al.list) || [],
          total: ((_informationAssets$pr2 = informationAssets.processors) === null || _informationAssets$pr2 === void 0 ? void 0 : _informationAssets$pr2.total) || 0
        });
      }
      if (this.systemsEnabled) {
        var _informationAssets$sy, _informationAssets$sy2;
        iaListByObjType.push({
          id: MAX_CONST_ID,
          label: AssetsTypes.SYSTEMS,
          type: AssetsTypes.SYSTEMS,
          objType: ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM,
          list: ((_informationAssets$sy = informationAssets.systems) === null || _informationAssets$sy === void 0 ? void 0 : _informationAssets$sy.list) || [],
          total: ((_informationAssets$sy2 = informationAssets.systems) === null || _informationAssets$sy2 === void 0 ? void 0 : _informationAssets$sy2.total) || 0
        });
      }
      if (activityTypes && activityTypes.length) {
        var activityTypesLength = 1;
        activityTypes.forEach(function (activityType) {
          var _informationAssets$cu;
          iaListByObjType.push({
            id: MAX_CONST_ID + activityTypesLength,
            label: activityType.label,
            type: AssetsTypes.INFORMATION_ASSETS,
            objType: activityType.module,
            activityTypeId: activityType.id,
            list: ((_informationAssets$cu = informationAssets.customIA) === null || _informationAssets$cu === void 0 ? void 0 : _informationAssets$cu.filter(function (ia) {
              return +ia.activityTypeId === +activityType.id;
            })) || [],
            defaultQuestionnaireId: activityType.defaultQuestionnaireId || ''
          });
          activityTypesLength++;
        });
      }
      return iaListByObjType;
    },
    updateFilteredList: function updateFilteredList(filteredList) {
      this.activityTypes.forEach(function (item) {
        return filteredList[item.label] = [];
      });
    },
    formatColumnsIA: function formatColumnsIA(objType) {
      var columns = [];
      if (objType === AssetsTypes.PROCESSORS) {
        columns = ['', this.$trans('ID'), this.$trans('Name')];
        if (this.systemsEnabled) {
          columns.push(this.$trans('Systems'));
        }
        columns.push(this.$trans('Processes'));
      } else if (objType === AssetsTypes.SYSTEMS) {
        columns = ['', this.$trans('ID'), this.$trans('Name'), this.$trans('Procs.'), this.$trans('Processes')];
      } else if (objType === AssetsTypes.CONTROLLERS) {
        columns = ['', this.$trans('ID'), this.$trans('Name'), this.$trans('Processes')];
      } else if (objType === AssetsTypes.INFORMATION_ASSETS) {
        columns = ['', this.$trans('Name'), this.$trans('Processes')];
      }
      columns.push('');
      return columns;
    },
    getProcessorItem: function getProcessorItem(item, objType) {
      var _this = this;
      var item_data = {
        trafficlight: DatatableRenderer.prepareTrafficLightField(item.trafficLight, item.objType),
        id: item.uniqueId,
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name
        },
        systems: item.systems ? item.systems.length : 0,
        processes: item.complianceNodeCount,
        actions: {
          disableAction: item.complianceNodeCount > 0 || !item.currentUserRights.canDelete,
          objType: objType,
          id: item.id
        },
        children: []
      };
      if (!this.systemsEnabled) {
        delete item_data.systems;
      }
      if (item.children.length > 0) {
        var childrenList = sortAlphabetic(item.childrenObjects, 'name');
        childrenList.forEach(function (child) {
          var childItem = {
            trafficlight: DatatableRenderer.prepareTrafficLightField(child.trafficLight, item.objType),
            id: child.uniqueId,
            name: {
              data: getFieldsFromObjectForPreview(child),
              name: child.name
            },
            systems: child.systems ? child.systems.length : 0,
            processes: child.complianceNodeCount,
            actions: {
              disableAction: child.complianceNodeCount > 0 || !child.currentUserRights.canDelete,
              objType: objType,
              id: child.id
            }
          };
          if (!_this.systemsEnabled) {
            delete childItem.systems;
          }
          item_data.children.push(childItem);
        });
      } else {
        delete item_data.children;
      }
      return item_data;
    },
    getSystemItem: function getSystemItem(item, objType, index) {
      var itemData = {
        trafficlight: DatatableRenderer.prepareTrafficLightField(item.trafficLight, item.objType),
        id: item.uniqueId,
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name
        },
        processors: item.processors.length,
        processes: {
          count: item.complianceNodeCount || 0,
          nodes: item.complianceNodes,
          index: index + 1
        },
        actions: {
          disableAction: item.complianceNodeCount > 0 || item.children && item.children.length > 0 || !item.currentUserRights.canDelete,
          objType: objType,
          id: item.id
        },
        children: []
      };
      if (item.childrenObjects && item.childrenObjects.length) {
        var childrenList = sortAlphabetic(item.childrenObjects, 'name');
        childrenList.forEach(function (child, childIndex) {
          itemData.children.push({
            trafficlight: DatatableRenderer.prepareTrafficLightField(child.trafficLight, item.objType),
            id: child.uniqueId,
            name: {
              data: getFieldsFromObjectForPreview(child),
              name: child.name
            },
            processors: child.processors.length,
            processes: {
              count: child.complianceNodeCount || 0,
              nodes: child.complianceNodes,
              index: "".concat(index + 1, "_").concat(childIndex)
            },
            actions: {
              disableAction: child.complianceNodeCount > 0 || !child.currentUserRights.canDelete,
              objType: objType,
              id: child.id
            }
          });
        });
      }
      if (itemData.children.length === 0) {
        delete itemData.children;
      }
      return itemData;
    },
    getControllerItem: function getControllerItem(item, objType) {
      var itemData = {
        trafficlight: DatatableRenderer.prepareTrafficLightField(item.trafficLight, item.objType),
        id: item.uniqueId,
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name
        },
        processes: item.complianceNodeCount > 0 ? item.complianceNodeCount : 0,
        actions: {
          disableAction: item.complianceNodeCount > 0 || !item.currentUserRights.canDelete,
          objType: objType,
          id: item.id
        }
      };
      return itemData;
    },
    getInformationAssetItem: function getInformationAssetItem(item, index) {
      var _this$activityTypes$f;
      return {
        trafficlight: DatatableRenderer.prepareTrafficLightField(item.trafficLight, item.objType),
        name: {
          data: getFieldsFromObjectForPreview(item),
          name: item.name || item.title
        },
        processes: {
          count: item.complianceNodeCount || 0,
          nodes: item.complianceNodes,
          index: index + 1
        },
        actions: {
          disableAction: item.complianceNodeCount > 0 || !item.currentUserRights.canDelete,
          objType: 'informationAssets',
          id: item.id,
          deleteLabel: ((_this$activityTypes$f = this.activityTypes.find(function (activityType) {
            return activityType.id === item.activityTypeId;
          })) === null || _this$activityTypes$f === void 0 ? void 0 : _this$activityTypes$f.label) || ''
        }
      };
    },
    formatDataIA: function formatDataIA(items, objType) {
      var _this2 = this;
      var data = [];
      items.forEach(function (item, index) {
        if (objType === AssetsTypes.PROCESSORS) {
          data.push(_this2.getProcessorItem(item, objType));
        } else if (objType === AssetsTypes.SYSTEMS) {
          data.push(_this2.getSystemItem(item, objType, index));
        } else if (objType === AssetsTypes.CONTROLLERS) {
          data.push(_this2.getControllerItem(item, objType));
        } else if (objType === AssetsTypes.INFORMATION_ASSETS) {
          data.push(_this2.getInformationAssetItem(item, index));
        }
      });
      return data.filter(Boolean);
    },
    getActivityTypeByProperty: function getActivityTypeByProperty(propertyName, value) {
      return this.activityTypes.find(function (type) {
        return type[propertyName] === value;
      });
    },
    getObjectType: function getObjectType(label) {
      var activityType = this.getActivityTypeByProperty('label', label);
      if (activityType) {
        return "informationAssets/".concat(activityType.id);
      }
      return label;
    }
  }
};