import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "max-w-lg mb-4"
};
var _hoisted_2 = {
  key: 0,
  class: "max-w-lg"
};
var _hoisted_3 = {
  class: "flex flex-row justify-between"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = {
  class: "max-w-lg mb-4"
};
var _hoisted_7 = {
  key: 2,
  class: "max-w-lg"
};
var _hoisted_8 = {
  class: "flex flex-row justify-between"
};
var _hoisted_9 = {
  class: "mb-4 flex items-center"
};
var _hoisted_10 = {
  key: 3
};
var _hoisted_11 = {
  key: 4,
  class: "mb-4"
};
var _hoisted_12 = {
  class: "control-group col-12"
};
var _hoisted_13 = {
  class: "max-w-lg"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_input_field = _resolveComponent("input-field");
  var _component_custom_axis_table = _resolveComponent("custom-axis-table");
  var _component_scoring_axis_edit_new = _resolveComponent("scoring-axis-edit-new");
  var _component_risk_grid_display = _resolveComponent("risk-grid-display");
  var _component_creation_matrix_axis_modal = _resolveComponent("creation-matrix-axis-modal");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full mb-6"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
        title: $data.translate.riskMatrixAxisParameters
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_single_select, {
        title: $data.translate.verticalAxisLikelihood,
        "model-value": $data.likelihoodScores,
        options: $data.likelihoodOptions,
        "un-sorted": true,
        disabled: $props.readOnly,
        placeholder: $data.translate.select,
        "onUpdate:modelValue": $options.changeLikelihood
      }, null, 8 /* PROPS */, ["title", "model-value", "options", "disabled", "placeholder", "onUpdate:modelValue"])]), $data.likelihoodScores != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_label, {
        title: $data.translate.scoresForLikelihood
      }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [!$options.likelihoodPercentageDisabled ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.likelihoodMax, function (label, index) {
        return _openBlock(), _createElementBlock("p", {
          key: "likelihood_max-".concat(index),
          class: "mb-4 flex items-center"
        }, [_createVNode(_component_input_field, {
          "model-value": label,
          enabled: !$props.readOnly,
          type: "number",
          min: "0",
          max: "100",
          step: "1",
          class: "max-w-xl",
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.onAxisChange('likelihoodMax', index, $event);
          }
        }, null, 8 /* PROPS */, ["model-value", "enabled", "onUpdate:modelValue"]), _cache[8] || (_cache[8] = _createElementVNode("span", {
          class: "ml-1"
        }, "%", -1 /* HOISTED */))]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.likelihoodLabels, function (label, index) {
        return _openBlock(), _createElementBlock("p", {
          key: "likelihood_label-".concat(index),
          class: "mb-4"
        }, [_createVNode(_component_input_field, {
          modelValue: label.description,
          "onUpdate:modelValue": [function ($event) {
            return label.description = $event;
          }, function ($event) {
            return $options.onLabelsAxisChange(index, label);
          }],
          enabled: !$props.readOnly,
          type: "text",
          class: "max-w-xl"
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "enabled"])]);
      }), 128 /* KEYED_FRAGMENT */))])])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: $data.translate.likelihood,
        type: "medium",
        class: "mb-2"
      }, null, 8 /* PROPS */, ["title"]), $props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD] ? (_openBlock(), _createBlock(_component_custom_axis_table, {
        key: 0,
        "axis-rows": $options.getAxisRows($props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD].combinedFields),
        "table-second-column": $options.tableSecondColumnLikelihood,
        "calculation-type": $props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD].calculationType || 1,
        editable: true,
        "read-only": $props.readOnly,
        "show-column-0": false,
        "show-result": false,
        "onChange:field": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('change:table', $event);
        }),
        "onChange:calctype": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('change:calculation', $data.RiskBaseModelProps.CUSTOM_LIKELIHOOD, $props.scoring[$data.RiskBaseModelProps.CUSTOM_LIKELIHOOD].id, $event);
        })
      }, null, 8 /* PROPS */, ["axis-rows", "table-second-column", "calculation-type", "read-only"])) : _createCommentVNode("v-if", true)])), _createElementVNode("div", _hoisted_6, [_createVNode(_component_single_select, {
        modelValue: $data.impactScores,
        "onUpdate:modelValue": [_cache[2] || (_cache[2] = function ($event) {
          return $data.impactScores = $event;
        }), $options.changeImpact],
        title: $data.translate.horizontalAxisImpact,
        options: $data.impactOptions,
        "un-sorted": true,
        disabled: $props.readOnly,
        placeholder: "Select"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "disabled", "onUpdate:modelValue"])]), $data.impactScores !== 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, [_createVNode(_component_input_field, {
        modelValue: $props.scoring.impact_scale,
        "onUpdate:modelValue": [_cache[3] || (_cache[3] = function ($event) {
          return $props.scoring.impact_scale = $event;
        }), _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('change', 'impact_scale', $event);
        })],
        title: $data.translate.financialImpactUnit,
        enabled: !$props.readOnly,
        placeholder: $data.translate.denominationAndCurrencyExMioUsd,
        type: "text",
        class: "max-w-xl"
      }, null, 8 /* PROPS */, ["modelValue", "title", "enabled", "placeholder"])]), _createElementVNode("div", null, [_createVNode(_component_risma_label, {
        title: $data.translate.financialImpact
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.impactMax, function (label, index) {
        return _openBlock(), _createElementBlock("p", {
          key: "impact_label-".concat(index),
          class: "mb-4"
        }, [_createVNode(_component_input_field, {
          "model-value": label,
          placeholder: "".concat($data.translate.maxForScore, " ").concat(index + 1, "?"),
          enabled: !$props.readOnly,
          type: "text",
          class: "max-w-xl",
          "onUpdate:modelValue": function onUpdateModelValue($event) {
            return $options.onAxisChange('impactMax', index, $event);
          }
        }, null, 8 /* PROPS */, ["model-value", "placeholder", "enabled", "onUpdate:modelValue"])]);
      }), 128 /* KEYED_FRAGMENT */))])])])) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_risma_title, {
        title: $data.translate.impact,
        type: "medium",
        class: "mb-2"
      }, null, 8 /* PROPS */, ["title"]), $props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT] ? (_openBlock(), _createBlock(_component_custom_axis_table, {
        key: 0,
        "axis-rows": $options.getAxisRows($props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].combinedFields),
        "table-second-column": $options.tableSecondColumnImpact,
        "calculation-type": $props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].calculationType || 1,
        editable: true,
        "read-only": $props.readOnly,
        "show-column-0": false,
        "show-result": false,
        "onChange:field": _cache[5] || (_cache[5] = function ($event) {
          return _ctx.$emit('change:table', $event);
        }),
        "onChange:calctype": _cache[6] || (_cache[6] = function ($event) {
          return _ctx.$emit('change:calculation', $data.RiskBaseModelProps.CUSTOM_IMPACT, $props.scoring[$data.RiskBaseModelProps.CUSTOM_IMPACT].id, $event);
        }),
        "onUpdate:calculationType": _cache[7] || (_cache[7] = function ($event) {
          return _ctx.$emit('update:calculationType', $event);
        })
      }, null, 8 /* PROPS */, ["axis-rows", "table-second-column", "calculation-type", "read-only"])) : _createCommentVNode("v-if", true)])), $options.riskZAxixSettings ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_risma_label, {
        title: $data.translate.thirdAxisZaxis
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_scoring_axis_edit_new, {
        "use-toggle": true,
        "scoring-id": $props.scoring.id,
        title: $props.scoring.z_axis_name,
        "scoring-axis": "Z",
        "scoring-labels": $props.scoring.z_axis_values,
        "scoring-count": $props.scoring.z_axis_scores,
        "read-only": $props.readOnly,
        onChanged: $options.onScoringAxisChange
      }, null, 8 /* PROPS */, ["scoring-id", "title", "scoring-labels", "scoring-count", "read-only", "onChanged"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_13, [_createVNode(_component_risma_title, {
        title: $data.translate.riskMatrixPreview
      }, null, 8 /* PROPS */, ["title"]), $data.riskGridShow ? (_openBlock(), _createBlock(_component_risk_grid_display, {
        key: 0,
        "risk-grid-data": $options.riskGridData,
        "grid-box-size": 60,
        class: "max-w-lg"
      }, null, 8 /* PROPS */, ["risk-grid-data"])) : _createCommentVNode("v-if", true)]), $data.showCreateModal ? (_openBlock(), _createBlock(_component_creation_matrix_axis_modal, {
        key: 5,
        "scoring-id": $props.scoring.id,
        axis: $data.axis,
        onCreate: $options.createTable,
        onDismiss: $options.dismissModal
      }, null, 8 /* PROPS */, ["scoring-id", "axis", "onCreate", "onDismiss"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  });
}