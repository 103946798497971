function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import update from 'immutability-helper';
import NestableItem from '@/components/Molecules/VueNestable/NestableItem';
import Placeholder from '@/components/Molecules/VueNestable/Placeholder';
import CallsHooksMixin from '@/components/Molecules/VueNestable/CallsHooksMixin';
import NestableHelpersMixin from '@/components/Molecules/VueNestable/NestableHelpersMixin';
import GroupsObserverMixin from '@/components/Molecules/VueNestable/GroupsObserverMixin';
import { closest, getOffsetRect, getTransformProps, listWithChildren } from '@/components/Molecules/VueNestable/utils';
export default {
  name: 'VueNestable',
  components: {
    NestableItem: NestableItem,
    Placeholder: Placeholder
  },
  mixins: [NestableHelpersMixin, GroupsObserverMixin, CallsHooksMixin],
  provide: function provide() {
    return {
      listId: this.listId,
      group: this.group,
      keyProp: this.keyProp,
      onDragEnd: this.onDragEnd
    };
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    threshold: {
      type: Number,
      required: false,
      default: 30
    },
    maxDepth: {
      type: Number,
      required: false,
      default: 10
    },
    keyProp: {
      type: String,
      required: false,
      default: 'id'
    },
    classProp: {
      type: String,
      required: false,
      default: null
    },
    group: {
      type: [String, Number],
      required: false,
      default: function _default() {
        return Math.random().toString(36).slice(2);
      }
    },
    childrenProp: {
      type: String,
      required: false,
      default: 'children'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    hooks: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    rtl: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  data: function data() {
    return {
      itemsOld: null,
      // revert to copy in case of cancelling drag
      dragItem: null,
      mouse: {
        last: {
          x: 0
        },
        shift: {
          x: 0
        }
      },
      el: null,
      elCopyStyles: null,
      isDirty: false,
      collapsedGroups: [],
      listId: Math.random().toString(36).slice(2)
    };
  },
  computed: {
    listIsEmpty: function listIsEmpty() {
      return this.modelValue.length === 0;
    },
    itemOptions: function itemOptions() {
      return {
        dragItem: this.dragItem,
        keyProp: this.keyProp,
        classProp: this.classProp,
        childrenProp: this.childrenProp
      };
    },
    listStyles: function listStyles() {
      var el = document.querySelector('.nestable-' + this.group + ' .nestable-item-' + this.dragItem[this.keyProp]);
      var listStyles = {};
      if (el) {
        listStyles.width = "".concat(el.clientWidth, "px");
      }
      if (this.elCopyStyles) {
        listStyles = _objectSpread(_objectSpread({}, listStyles), this.elCopyStyles);
      }
      return listStyles;
    }
  },
  created: function created() {
    var items = listWithChildren(this.modelValue, this.childrenProp);
    this.$emit('update:modelValue', items);
    this.isDirty = false;
    this.registerNestable(this);
  },
  beforeUnmount: function beforeUnmount() {
    this.stopTrackMouse();
  },
  methods: {
    startTrackMouse: function startTrackMouse() {
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onDragEnd);
      document.addEventListener('touchend', this.onDragEnd);
      document.addEventListener('touchcancel', this.onDragEnd);
      document.addEventListener('keydown', this.onKeyDown);
    },
    stopTrackMouse: function stopTrackMouse() {
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onDragEnd);
      document.removeEventListener('touchend', this.onDragEnd);
      document.removeEventListener('touchcancel', this.onDragEnd);
      document.removeEventListener('keydown', this.onKeyDown);
      this.elCopyStyles = null;
    },
    onDragStart: function onDragStart(event, item) {
      var _this = this;
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.el = closest(event.target, '.nestable-item');
      this.startTrackMouse();
      this.dragItem = item;
      this.itemsOld = this.modelValue;

      // Trigger a mouseMove event to update the ghost item with the mouse
      // position
      this.$nextTick(function () {
        _this.onMouseMove(event);
      });
    },
    onDragEnd: function onDragEnd(event, isCancel) {
      event && event.preventDefault();
      this.stopTrackMouse();
      this.el = null;
      isCancel ? this.dragRevert() : this.dragApply();
    },
    onKeyDown: function onKeyDown(event) {
      if (event.which === 27) {
        // ESC
        this.onDragEnd(null, true);
      }
    },
    getXandYFromEvent: function getXandYFromEvent(event) {
      var clientX = event.clientX,
        clientY = event.clientY;

      // get touch event
      var targetTouches = event.targetTouches;

      // if there is a touch event, use this
      if (targetTouches) {
        var touch = targetTouches[0];
        clientX = touch.clientX;
        clientY = touch.clientY;

        // we rely on the mouseenter event to track if a node should be moved
        // since this event does not exist, we need to simulate it.
        var _event = new Event('mouseenter');
        var element = document.elementFromPoint(clientX, clientY);
        var touchElement = element && (element.closest('.nestable-item-content') || element.closest('.nestable-list-empty'));
        if (touchElement) touchElement.dispatchEvent(_event);
      }
      return {
        clientX: clientX,
        clientY: clientY
      };
    },
    onMouseMove: function onMouseMove(event) {
      event && event.preventDefault();
      var _this$getXandYFromEve = this.getXandYFromEvent(event),
        clientX = _this$getXandYFromEve.clientX,
        clientY = _this$getXandYFromEve.clientY;

      // initialize the initial mouse positoin on the first drag operation
      if (this.mouse.last.x === 0) {
        this.mouse.last.x = clientX;
      }
      var transformProps = getTransformProps(clientX, clientY);

      // In some cases the drag-layer might not be at the top left of the window,
      // we need to find, where it acually is, and incorperate the position into the calculation.
      var elDragLayer = document.querySelector('.nestable-' + this.group + ' .nestable-drag-layer');
      if (!elDragLayer) return;
      var _elDragLayer$getBound = elDragLayer.getBoundingClientRect(),
        dragLayerTop = _elDragLayer$getBound.top,
        dragLayerLeft = _elDragLayer$getBound.left;
      var elCopy = document.querySelector('.nestable-' + this.group + ' .nestable-drag-layer > .nestable-list');
      if (!this.elCopyStyles) {
        var offset = getOffsetRect(this.el);
        this.elCopyStyles = _objectSpread({
          marginTop: "".concat(offset.top - clientY - dragLayerTop, "px"),
          marginLeft: "".concat(offset.left - clientX - dragLayerLeft, "px")
        }, transformProps);
      } else {
        this.elCopyStyles = _objectSpread(_objectSpread({}, this.elCopyStyles), transformProps);
        if (elCopy) {
          for (var key in transformProps) {
            if (Object.prototype.hasOwnProperty.call(transformProps, key)) {
              elCopy.style[key] = transformProps[key];
            }
          }
        }
        var diffX = this.rtl ? this.mouse.last.x - clientX : clientX - this.mouse.last.x;
        if (diffX >= 0 && this.mouse.shift.x >= 0 || diffX <= 0 && this.mouse.shift.x <= 0) {
          this.mouse.shift.x += diffX;
        } else {
          this.mouse.shift.x = 0;
        }
        this.mouse.last.x = clientX;
        if (Math.abs(this.mouse.shift.x) > this.threshold) {
          if (this.mouse.shift.x > 0) {
            this.tryIncreaseDepth(this.dragItem);
          } else {
            this.tryDecreaseDepth(this.dragItem);
          }
          this.mouse.shift.x = 0;
        }
      }
    },
    moveItem: function moveItem(_ref) {
      var dragItem = _ref.dragItem,
        pathFrom = _ref.pathFrom,
        pathTo = _ref.pathTo;
      // the remove action might affect the next position,
      // so update next coordinates accordingly
      var realPathTo = this.getRealNextPath(pathFrom, pathTo);
      var removePath = this.getSplicePath(pathFrom, {
        numToRemove: 1,
        childrenProp: this.childrenProp
      });
      var insertPath = this.getSplicePath(realPathTo, {
        numToRemove: 0,
        itemsToInsert: [dragItem],
        childrenProp: this.childrenProp
      });
      if (!this.hook('beforeMove', {
        dragItem: dragItem,
        pathFrom: pathFrom,
        pathTo: realPathTo
      })) return;
      var items = this.modelValue;
      items = update(items, removePath);
      items = update(items, insertPath);
      this.isDirty = true;
      this.pathTo = realPathTo;
      this.$emit('update:modelValue', items);
    },
    tryIncreaseDepth: function tryIncreaseDepth(dragItem) {
      var pathFrom = this.getPathById(dragItem[this.keyProp]);
      var itemIndex = pathFrom[pathFrom.length - 1];
      var newDepth = pathFrom.length + this.getItemDepth(dragItem);

      // has previous sibling and isn't at max depth
      if (itemIndex > 0 && newDepth <= this.maxDepth) {
        var prevSibling = this.getItemByPath(pathFrom.slice(0, -1).concat(itemIndex - 1));

        // previous sibling is not collapsed
        if (prevSibling[this.childrenProp] && (!prevSibling[this.childrenProp].length || !this.isCollapsed(prevSibling))) {
          var pathTo = pathFrom.slice(0, -1).concat(itemIndex - 1).concat(prevSibling[this.childrenProp].length);
          this.moveItem({
            dragItem: dragItem,
            pathFrom: pathFrom,
            pathTo: pathTo
          });
        }
      }
    },
    tryDecreaseDepth: function tryDecreaseDepth(dragItem) {
      var pathFrom = this.getPathById(dragItem[this.keyProp]);
      var itemIndex = pathFrom[pathFrom.length - 1];

      // has parent
      if (pathFrom.length > 1) {
        var parent = this.getItemByPath(pathFrom.slice(0, -1));

        // is last (by order) item in array
        if (itemIndex + 1 === parent[this.childrenProp].length) {
          var pathTo = pathFrom.slice(0, -1);
          pathTo[pathTo.length - 1] += 1;
          this.moveItem({
            dragItem: dragItem,
            pathFrom: pathFrom,
            pathTo: pathTo
          });
        }
      }
    },
    onMouseEnter: function onMouseEnter(event, eventList, item) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      var dragItem = this.dragItem;

      // In some cases, this event fires after the drag operation was already
      // completed, in which case we can ignore it
      if (!dragItem) return;

      // if the event does not have a valid item that belongs to this list, ignore it
      if (item !== null && dragItem[this.keyProp] === item[this.keyProp]) return;

      // calculate the path the item is comming from
      var pathFrom = this.getPathById(dragItem[this.keyProp]);

      // if the event is not emitted from this list and the item was not removed from this list,
      // we can ignore this event
      if (eventList !== this.listId && pathFrom.length === 0) return;
      var pathTo;
      // if we are dragging to an empty list, we need to remove
      // the item from the origin list and append it to the start of the new list
      if (item === null) {
        pathTo = pathFrom.length > 0 ? [] : [0];
      } else {
        pathTo = this.getPathById(item[this.keyProp]);
      }

      // if the move to the new depth is greater than max depth,
      // don't move
      var newDepth = this.getRealNextPath(pathFrom, pathTo).length + (this.getItemDepth(dragItem) - 1);
      if (newDepth > this.maxDepth) {
        return;
      }

      // if collapsed by default
      // and move last (by count) child
      // remove parent node from list of open nodes
      var collapseProps = {};
      if (this.collapsed && pathFrom.length > 1) {
        var parent = this.getItemByPath(pathFrom.slice(0, -1));
        if (parent[this.childrenProp].length === 1) {
          collapseProps = this.onToggleCollapse(parent, true);
        }
      }
      this.moveItem({
        dragItem: dragItem,
        pathFrom: pathFrom,
        pathTo: pathTo
      }, collapseProps);
    },
    isCollapsed: function isCollapsed(item) {
      return !!(this.collapsedGroups.indexOf(item[this.keyProp]) > -1 ^ this.collapsed);
    },
    dragApply: function dragApply() {
      this.$emit('change', this.dragItem, {
        items: this.modelValue,
        pathTo: this.pathTo
      });
      this.pathTo = null;
      this.itemsOld = null;
      this.dragItem = null;
      this.isDirty = false;
    },
    dragRevert: function dragRevert() {
      this.$emit('update:modelValue', this.itemsOld);
      this.pathTo = null;
      this.itemsOld = null;
      this.dragItem = null;
      this.isDirty = false;
    }
  }
};