function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { ref } from 'vue';
import { getLocalStorageComputed } from '@/compositions/LocalStorageComposition';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaDateRangePicker from '@/components/Atoms/Inputs/RismaDateRangePicker';
import { DateFormats } from '@/constants/DateFormats';
import { formatCurrentTime } from '@/utils/date';
export default {
  name: 'MultipleDateRange',
  description: 'Component that can create/remove multiple date ranges',
  token: '<multiple-date-range />',
  components: {
    FeatherIcon: FeatherIcon,
    RismaDateRangePicker: RismaDateRangePicker
  },
  props: {
    initialRanges: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'Array of initial date ranges'
    },
    localStorageKey: {
      type: String,
      required: false,
      default: '',
      note: 'Name of localStorage key'
    },
    minRangesNumber: {
      type: Number,
      required: false,
      default: 1,
      note: 'Minimum number of date ranges'
    }
  },
  emits: ['rangesChanged'],
  data: function data() {
    return {
      defaultRange: {
        dateStart: null,
        dateEnd: null
      },
      uid: null,
      ranges: []
    };
  },
  beforeMount: function beforeMount() {
    var defaultRange = {
      dateStart: formatCurrentTime(DateFormats.DATE_FORMAT),
      dateEnd: formatCurrentTime(DateFormats.DATE_FORMAT)
    };
    var uid = ref(1);
    var initialRanges = this.initialRanges.length ? this.initialRanges : [defaultRange];
    var ranges = this.localStorageKey ? getLocalStorageComputed(this.localStorageKey, initialRanges, true) : ref(initialRanges);
    ranges.value = ranges.value.map(function (range) {
      return _objectSpread(_objectSpread({}, range), {}, {
        id: uid.value++
      });
    });
    this.defaultRange = defaultRange;
    this.uid = uid;
    this.ranges = ranges;
    this.rangesChanged();
  },
  methods: {
    onCreateRange: function onCreateRange() {
      var _this = this;
      this.ranges = [].concat(_toConsumableArray(this.ranges), [_objectSpread(_objectSpread({}, cloneDeep(this.defaultRange)), {}, {
        id: this.uid++
      })]);
      this.rangesChanged();
      this.$nextTick(function () {
        var _this$$refs$ranges;
        if ((_this$$refs$ranges = _this.$refs.ranges[_this.$refs.ranges.length - 1]) !== null && _this$$refs$ranges !== void 0 && _this$$refs$ranges.handleOpen) {
          _this.$refs.ranges[_this.$refs.ranges.length - 1].handleOpen();
        }
      });
    },
    changedDates: function changedDates(id, _ref) {
      var startDate = _ref.startDate,
        endDate = _ref.endDate;
      this.ranges = this.ranges.map(function (range) {
        if (range.id !== id) return range;
        return _objectSpread(_objectSpread({}, range), {}, {
          dateStart: startDate,
          dateEnd: endDate
        });
      });
      this.rangesChanged();
    },
    removeRange: function removeRange(id) {
      this.ranges = this.ranges.filter(function (range) {
        return range.id !== id;
      });
      this.rangesChanged();
    },
    rangesChanged: function rangesChanged() {
      this.$emit('rangesChanged', this.ranges);
    }
  }
};