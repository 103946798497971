// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field-container.error[data-v-d51dd5c8]  .input-field input {
  border-color: #B03D00;
}
.input-field-container.error[data-v-d51dd5c8]  .custom-select-inner {
  border-color: #B03D00;
}
.input-field-container.error[data-v-d51dd5c8]  .select-selected-value > div {
  border-color: #B03D00;
}
.validation-error[data-v-d51dd5c8] {
  color: #B03D00;
  margin: 0 0 0.5rem;
  padding: 4px 0 0;
  display: block;
}
.group-field-values .input-field-container[data-v-d51dd5c8] {
  position: relative;
  margin: 0 0 0.5rem;
  padding-right: 1.5rem;
}
.group-field-values .input-field-container .remove-option[data-v-d51dd5c8] {
  position: absolute;
  right: 2px;
  top: 11px;
  font-size: 18px;
  cursor: pointer;
}
.actions[data-v-d51dd5c8] {
  display: flex;
  margin: 20px 0 0 0;
  justify-content: flex-end;
}
.actions[data-v-d51dd5c8]  .risma-button-btn {
  display: block;
  align-content: center !important;
  min-width: 130px !important;
  font-size: 17px;
}
.items-group[data-v-d51dd5c8] {
  margin: 0 0 1rem;
}
.items-group[data-v-d51dd5c8]  .risma-title {
  margin: 0 0 5px;
}
.items-group[data-v-d51dd5c8]  .risma-title h3 {
  font-size: 16px;
}
.table-admin-types[data-v-d51dd5c8] {
  width: 100%;
}
.table-admin-types th[data-v-d51dd5c8] {
  padding: 4px 10px 4px 10px;
  border-bottom: 1pt solid #BEBEBE;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  text-align: center;
}
.table-admin-types th.thead-empty-cell[data-v-d51dd5c8] {
  width: 210px;
}
.table-admin-types th.thead-small[data-v-d51dd5c8] {
  width: 150px;
}
.table-admin-types th[data-v-d51dd5c8]  p {
  margin-bottom: 5px;
}
.table-admin-types .table-row-custom-field td[data-v-d51dd5c8] {
  padding: 10px 10px;
  border-bottom: 1pt solid #BEBEBE;
}
.table-admin-types .table-row-custom-field .table-radio-buttons[data-v-d51dd5c8] {
  padding: 10px 0 10px 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
