function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import { changePassword as _changePassword } from '@/api/risma/userSettings';
export default {
  name: 'UserSettingsPassword',
  components: {
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    InputField: InputField,
    Notification: Notification
  },
  data: function data() {
    return {
      passwordData: {
        passwordCurrent: '',
        passwordNew: '',
        passwordNewRepeat: ''
      },
      notificationMessages: [],
      hasNotification: false,
      notificationType: 'error',
      translate: getTranslate['UserSettingsPassword']()
    };
  },
  computed: _objectSpread({}, mapState(useUserStore, ['currentUser'])),
  methods: {
    changePassword: function changePassword() {
      var _this = this;
      return _changePassword(this.currentUser.id, this.passwordData).then(function (response) {
        return _this.prepareSuccess(response);
      }).catch(function (error) {
        return _this.prepareError(error);
      });
    },
    prepareSuccess: function prepareSuccess(response) {
      var _this2 = this;
      this.hasNotification = true;
      this.notificationType = 'success';
      this.notificationMessages = [response.data];
      setTimeout(function () {
        _this2.hasNotification = false;
      }, 4000);
    },
    prepareError: function prepareError(error) {
      var _this3 = this;
      this.hasNotification = true;
      this.notificationType = 'error';
      if (error.response.error.startsWith('[')) {
        var errors = JSON.parse(error.response.error);
        this.notificationMessages = errors.map(function (error) {
          return _this3.$trans(error.charAt(0).toUpperCase() + error.slice(1));
        });
      } else {
        this.notificationMessages = [error.response.error];
      }
    }
  }
};