function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import DataFlowLegalBasisAndStoragePeriod from '@/components/Organisms/Compliance/DataFlow/DataFlowLegalBasisAndStoragePeriod';
import { DataFlowFields } from '@/constants/compliance/WizardFlow';
export default {
  name: 'DataTypeLegalBasisCreationModal',
  components: {
    Modal: Modal,
    DataFlowLegalBasisAndStoragePeriod: DataFlowLegalBasisAndStoragePeriod
  },
  props: {
    flows: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    questions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['accept', 'dismiss'],
  data: function data() {
    return {
      localFlows: [],
      selectedOptionsByFlow: {},
      translate: getTranslate['DataTypeLegalBasisCreationModal']()
    };
  },
  computed: {
    questionsById: function questionsById() {
      return Object.fromEntries(this.questions.map(function (question) {
        return [question.id, question];
      }));
    },
    filteredFlows: function filteredFlows() {
      return this.localFlows.filter(function (flow) {
        return flow.dataTypes.length;
      });
    },
    extendedWithStoragePeriod: function extendedWithStoragePeriod() {
      return true; // place for feature flag
    },
    showEmptyMessage: function showEmptyMessage() {
      var _this = this;
      return !this.filteredFlows.map(function (_ref) {
        var dataTypes = _ref.dataTypes;
        return dataTypes;
      }).flat().filter(function (_ref2) {
        var questionId = _ref2.questionId;
        var _this$questionsById$q = _this.questionsById[questionId].questionobject,
          legalBasisQuestionId = _this$questionsById$q.legalBasisQuestionId,
          retentionQuestionId = _this$questionsById$q.retentionQuestionId;
        return legalBasisQuestionId || retentionQuestionId && _this.extendedWithStoragePeriod;
      }).length;
    }
  },
  beforeMount: function beforeMount() {
    this.localFlows = cloneDeep(this.flows);
    this.setInitialValues();
  },
  methods: {
    setInitialValues: function setInitialValues() {
      var _this2 = this;
      this.filteredFlows.forEach(function (flow) {
        _this2.selectedOptionsByFlow[flow.id] = _defineProperty(_defineProperty(_defineProperty({}, DataFlowFields.CURRENT_TYPES_SELECTED, flow.dataTypes), DataFlowFields.CURRENT_BASIS_SELECTED, {}), DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED, {});
        flow.dataTypes.forEach(function (type) {
          var legalBasisOptionId = type.legalBasisOptionId,
            storagePeriodOptionId = type.storagePeriodOptionId,
            uniqueId = type.uniqueId;
          if (legalBasisOptionId) {
            _this2.selectedOptionsByFlow[flow.id][DataFlowFields.CURRENT_BASIS_SELECTED][uniqueId] = legalBasisOptionId + '';
          }
          if (storagePeriodOptionId) {
            _this2.selectedOptionsByFlow[flow.id][DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED][uniqueId] = storagePeriodOptionId + '';
          }
        });
      });
    },
    updateSelected: function updateSelected(_ref3, subFlowId) {
      var value = _ref3.value,
        uniqueId = _ref3.uniqueId,
        field = _ref3.field;
      var index = this.localFlows.findIndex(function (f) {
        return f.id === subFlowId;
      });
      var dataTypeIdx = this.localFlows[index].dataTypes.findIndex(function (type) {
        return type.uniqueId === uniqueId;
      });
      var dataType = _objectSpread({}, this.localFlows[index].dataTypes[dataTypeIdx]);
      if (field === DataFlowFields.CURRENT_BASIS_SELECTED) {
        dataType = _objectSpread(_objectSpread({}, dataType), {}, {
          legalBasisOptionId: value && +value.id,
          legalBasisOptionLabel: value && value.text
        });
      }
      if (field === DataFlowFields.CURRENT_STORAGE_PERIOD_SELECTED) {
        dataType = _objectSpread(_objectSpread({}, dataType), {}, {
          storagePeriodOptionId: value && +value.id,
          storagePeriodOptionLabel: value && value.text
        });
      }
      this.localFlows[index].dataTypes[dataTypeIdx] = dataType;
      if (value !== null && value !== void 0 && value.id) {
        this.selectedOptionsByFlow[subFlowId][field][uniqueId] = value.id;
      } else {
        delete this.selectedOptionsByFlow[subFlowId][field][uniqueId];
      }
    },
    onModalAccept: function onModalAccept() {
      this.$emit('accept', this.localFlows);
    }
  }
};