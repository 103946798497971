function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUserStore } from '@/Store/userStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { setLocaleLanguage } from '@/i18n';
import * as Cookie from 'tiny-cookie';
import { updateUser } from '@/api/admin/user';
import Modal from '@/components/Molecules/Modal/Modal';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { SETTINGS_ARRAY } from '@/constants/admin/Settings';
import { UserMfaTypes } from '@/constants/UserMfaTypes';
import { setGlobalDateLocale } from '@/utils/date';
export default {
  name: 'UserSettingsProfile',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    Modal: Modal,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  introduction: 'Current user settings',
  description: 'Current user settings: language, password, avatart',
  token: '<user-settings-profile />',
  emits: ['refreshMenu'],
  data: function data() {
    return {
      showTwoFactorModal: false,
      qrLoading: false,
      qrImage: '',
      qrCode: '',
      now: '',
      mfaTypes: UserMfaTypes,
      translate: getTranslate['UserSettingsProfile']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser', 'isAdmin', 'isMfaEnabled', 'userLanguage'])), mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useOrganisationsStore, ['organisations'])), {}, {
    isMfaFeatureEnabled: function isMfaFeatureEnabled() {
      return this.getSettingValue('feature.google_mfa_enabled');
    },
    onlyAdminAbleToDisableMfa: function onlyAdminAbleToDisableMfa() {
      return this.getSettingValue('feature.only_admin_can_disable_mfa');
    },
    isUserHaveRightsToDisableMfa: function isUserHaveRightsToDisableMfa() {
      return this.isMfaFeatureEnabled && (!this.onlyAdminAbleToDisableMfa || this.isAdmin);
    },
    language: function language() {
      var _this = this;
      var languageSettings = SETTINGS_ARRAY['General']['risma.firstlanguage'];
      if (!this.currentUser.lang) return languageSettings['default'];
      return Object.keys(languageSettings['dropdown']).find(function (item) {
        return item === _this.currentUser.lang;
      });
    },
    languages: function languages() {
      var _this2 = this;
      var languagesSettings = SETTINGS_ARRAY['General']['risma.firstlanguage']['dropdown'];
      return Object.keys(languagesSettings).map(function (language) {
        return {
          label: languagesSettings[language],
          img: language.slice(-2).toLowerCase(),
          value: language,
          location: _this2.location
        };
      });
    },
    location: function location() {
      return window.location.href.toString().split(window.location.host)[1];
    },
    organisationsList: function organisationsList() {
      var _this3 = this;
      if (!this.organisations.length) {
        return [];
      }
      var list = this.currentUser.organisations.map(function (orgId) {
        var organisation = _this3.organisations.find(function (item) {
          return item.id === orgId;
        });
        if (!organisation) {
          return null;
        }
        return {
          name: organisation.name,
          id: organisation.id
        };
      });
      return list.filter(function (item) {
        return item !== null;
      });
    },
    qrCodeFormatted: function qrCodeFormatted() {
      return this.qrCode.split('').map(function (n, i) {
        return !(i % 4) ? ' ' + n : n;
      }).join('').trim();
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useUserStore, {
    setCurrentUser: 'set'
  })), {}, {
    updateLanguage: function updateLanguage(value) {
      var _this4 = this;
      updateUser({
        lang: value
      }, this.currentUser.id).then(function (response) {
        _this4.setCurrentUser(response);
        Cookie.set('risma_lang', value, {
          expires: 365,
          samesite: 'strict'
        });
      }).then(function () {
        return Promise.all([setLocaleLanguage(value), setGlobalDateLocale(_this4.userLanguage)]);
      }).then(function () {
        _this4.$emit('refreshMenu');
        _this4.translate = getTranslate['UserSettingsProfile']();
      });
    },
    toggleTwoFactorModal: function toggleTwoFactorModal() {
      this.showTwoFactorModal = !this.showTwoFactorModal;
    },
    enableMfa: function enableMfa() {
      var _this5 = this;
      this.toggleTwoFactorModal();
      this.qrLoading = true;
      return updateUser({
        mfa_type: this.mfaTypes.MFA_ENABLED
      }, this.currentUser.id).then(function (user) {
        _this5.qrImage = user.mfa.qr_image;
        _this5.qrCode = user.mfa.key;
        _this5.qrLoading = false;
      });
    },
    disableMfa: function disableMfa() {
      var _this6 = this;
      if (!this.isUserHaveRightsToDisableMfa && !this.showTwoFactorModal) return;
      return updateUser({
        mfa_type: this.mfaTypes.MFA_DISABLED
      }, this.currentUser.id).then(function () {
        if (_this6.showTwoFactorModal) {
          _this6.toggleTwoFactorModal();
        }
      });
    }
  })
};