// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

Original highlight.js style (c) Ivan Sagalaev <maniac@softwaremaniacs.org>

*/
.hljs[data-v-76b37fec] {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #F0F0F0;
}

/* Base color: saturation 0; */
.hljs[data-v-76b37fec],
.hljs-subst[data-v-76b37fec] {
  color: #444;
}
.hljs-comment[data-v-76b37fec] {
  color: #888888;
}
.hljs-keyword[data-v-76b37fec],
.hljs-attribute[data-v-76b37fec],
.hljs-selector-tag[data-v-76b37fec],
.hljs-meta-keyword[data-v-76b37fec],
.hljs-doctag[data-v-76b37fec],
.hljs-name[data-v-76b37fec] {
  font-weight: bold;
}

/* User color: hue: 0 */
.hljs-type[data-v-76b37fec],
.hljs-string[data-v-76b37fec],
.hljs-number[data-v-76b37fec],
.hljs-selector-id[data-v-76b37fec],
.hljs-selector-class[data-v-76b37fec],
.hljs-quote[data-v-76b37fec],
.hljs-template-tag[data-v-76b37fec],
.hljs-deletion[data-v-76b37fec] {
  color: #880000;
}
.hljs-title[data-v-76b37fec],
.hljs-section[data-v-76b37fec] {
  color: #880000;
  font-weight: bold;
}
.hljs-regexp[data-v-76b37fec],
.hljs-symbol[data-v-76b37fec],
.hljs-variable[data-v-76b37fec],
.hljs-template-variable[data-v-76b37fec],
.hljs-link[data-v-76b37fec],
.hljs-selector-attr[data-v-76b37fec],
.hljs-selector-pseudo[data-v-76b37fec] {
  color: #BC6060;
}

/* Language color: hue: 90; */
.hljs-literal[data-v-76b37fec] {
  color: #78A960;
}
.hljs-built_in[data-v-76b37fec],
.hljs-bullet[data-v-76b37fec],
.hljs-code[data-v-76b37fec],
.hljs-addition[data-v-76b37fec] {
  color: #397300;
}

/* Meta color: hue: 200 */
.hljs-meta[data-v-76b37fec] {
  color: #1f7199;
}
.hljs-meta-string[data-v-76b37fec] {
  color: #4d99bf;
}

/* Misc effects */
.hljs-emphasis[data-v-76b37fec] {
  font-style: italic;
}
.hljs-strong[data-v-76b37fec] {
  font-weight: bold;
}
pre[data-v-76b37fec] {
  width: 50%;
  white-space: pre-wrap;
}
.codeDesc[data-v-76b37fec] {
  width: 50%;
  background-color: transparent;
  border-right: 1px solid #c5c5c5;
  padding: 0.5rem;
  white-space: normal;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.highlight[data-v-76b37fec] {
  display: flex;
  margin-top: 1rem;
  flex-flow: row nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
