function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'MiniTreeBranch',
  components: {
    ToggleMenu: ToggleMenu
  },
  props: {
    item: {
      required: true,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    path: {
      required: true,
      type: Array
    },
    selectedPath: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    parentId: {
      required: false,
      type: [String, Number],
      default: 0
    },
    searchEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    openedArray: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    nodeEmits: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    parentEnabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:selected', 'update:opened', 'update:path', 'edit:enabled', 'edit:title', 'addSubNode'],
  computed: {
    skipAccessCheck: function skipAccessCheck() {
      return this.item.objType === ObjectTypes.FRAMEWORK;
    },
    hasToggleMenu: function hasToggleMenu() {
      var _this$nodeActions;
      return (this.item.accessible || this.item.checkaccess || this.skipAccessCheck) && this.item.id === this.lastIdInSelectedChain && ((_this$nodeActions = this.nodeActions) === null || _this$nodeActions === void 0 ? void 0 : _this$nodeActions.length);
    },
    nodeActions: function nodeActions() {
      var _this = this;
      if (!this.nodeEmits.length) return [];
      return this.nodeEmits.filter(function (item) {
        var maxLevelCheck = !item.maxLevel || item.maxLevel >= _this.path.length;
        var flagCheck = item.flagCheckField ? !_this.item[item.flagCheckField] : true;
        var parentEnabledCheck = item.parentEnabledOnDisabledCheck && !_this.item.enabled ? _this.parentEnabled : true;
        return maxLevelCheck && flagCheck && parentEnabledCheck;
      }).map(function (emitData) {
        return _objectSpread(_objectSpread({}, emitData), {}, {
          title: emitData.altTitleFlag ? _this.item[emitData.altTitleFlag] ? emitData.title : emitData.altTitle : emitData.title,
          icon: emitData.altTitleFlag ? _this.item[emitData.altTitleFlag] ? emitData.icon : emitData.altIcon : emitData.icon,
          action: function action() {
            return _this.$emit(emitData.emit, _this.item);
          }
        });
      });
    },
    hasChildren: function hasChildren() {
      var _this$item;
      return (_this$item = this.item) === null || _this$item === void 0 || (_this$item = _this$item.children) === null || _this$item === void 0 ? void 0 : _this$item.length;
    },
    icon: function icon() {
      if (!this.hasChildren) {
        return 'frown';
      }
      return 'fast-forward';
    },
    isElementHighlighted: function isElementHighlighted() {
      return this.isSelected || this.parentId === this.lastIdInSelectedChain || this.searchEnabled;
    },
    level: function level() {
      return this.path.length - 1;
    },
    index: function index() {
      return this.path.slice(-1)[0];
    },
    isSelected: function isSelected() {
      var _this$selectedPath$th;
      if (!this.selectedPath.length) return;
      return ((_this$selectedPath$th = this.selectedPath[this.level]) === null || _this$selectedPath$th === void 0 ? void 0 : _this$selectedPath$th.id) === this.item.id;
    },
    isOpened: function isOpened() {
      return this.openedArray.includes(this.item.id);
    },
    beforeSelected: function beforeSelected() {
      var _this$selectedPath$th2;
      if (!this.selectedPath.length) return;
      var initialPath = this.path.slice(0, this.level);
      var initialSelectedPath = this.selectedPath.map(function (_ref) {
        var index = _ref.index;
        return index;
      }).slice(0, this.level);
      var isInThePath = JSON.stringify(initialPath) === JSON.stringify(initialSelectedPath);
      return isInThePath && ((_this$selectedPath$th2 = this.selectedPath[this.level]) === null || _this$selectedPath$th2 === void 0 ? void 0 : _this$selectedPath$th2.index) >= this.index;
    },
    lastIdInSelectedChain: function lastIdInSelectedChain() {
      if (!this.selectedPath.length) return null;
      return this.selectedPath[this.selectedPath.length - 1].id;
    }
  },
  mounted: function mounted() {
    // check if element's path was changed due to filtering/removing node
    var checkPath = this.selectedPath.map(function (item) {
      return item.index;
    });
    if (this.item.id === this.lastIdInSelectedChain && JSON.stringify(checkPath) !== JSON.stringify(this.path)) {
      this.$emit('update:selected', [{
        id: this.item.id,
        index: this.index
      }]);
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit('update:selected', [{
        id: this.item.id,
        index: this.index
      }]);
    }
  }
};