import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ai_button_with_tooltip = _resolveComponent("ai-button-with-tooltip");
  return _openBlock(), _createBlock(_component_ai_button_with_tooltip, {
    mode: "secondary",
    position: "right",
    "onClick:icon": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.addMessageWithContext({
        context: $options.strippedContext,
        source: $props.source
      });
    })
  });
}