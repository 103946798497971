function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import * as DataAPI from '@/api/risma/data';
import { getContract } from '@/api/contracts/contracts';
import { getAllForNode } from '@/api/compliance/gaps';
import { getWorkflowStatusesByModule } from '@/api/risma/data';
import { getSingleInitiative } from '@/api/execution/initiative';
import { getRiskData, getRiskProjects, getReports } from '@/api/risk/risk';
import { getAll as _getIncidents } from '@/api/incident/incident';
import { getControlById } from '@/api/controls/controls';
import { getCurrentScoring } from '@/api/risk/scoring';
import { getNodeById } from '@/api/compliance/processtree';
import { getSingleByType } from '@/api/compliance/informationAssets';
import { getByUrl } from '@/api/httpUtils';
import { getById as getComplianceNode } from '@/api/compliance/node';
import { getCompanies as _getCompanies } from '@/api/admin/companies';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
import { ComplianceControllers } from '@/constants/compliance/ComplianceControllers';
import { TabTypes } from '@/constants/Tabs';
import { comparePathStrings } from '@/utils/compare';
import { formatNodes } from '@/utils/format/Compliance';
import * as LinksTabUtils from '@/utils/linksTabUtils';
import * as Utils from '@/utils/Utils';
export var LinksTabGetDataMixin = {
  data: function data() {
    return {
      processTreeId: 0,
      complianceNodes: []
    };
  },
  computed: {
    activeComplianceNodesIds: function activeComplianceNodesIds() {
      return this.complianceNodes.map(function (_ref) {
        var id = _ref.id;
        return id;
      });
    },
    complianceUserHasAccessToGap: function complianceUserHasAccessToGap() {
      return this.module == MODULES.COMPLIANCE && this.showGapByProjectId(this.data.compliance_projects_id);
    }
  },
  methods: {
    getPromise: function getPromise(link) {
      var getAllItems = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (this.disabledOptions[link]) {
        return;
      }
      if (getAllItems && !this.loadedModulesMap.includes(link)) {
        this.loadedModulesMap.push(link);
      }
      switch (link) {
        case MODULES.CONTRACTS:
          // right now only contracts could preload only necessary items
          return this.getContracts(getAllItems);
        // but if user opens links modal he wants to see all of them
        case MODULES.COMPANY:
          return this.getCompanies();
        case MODULES.COMPLIANCE:
          return this.getComplianceNodes();
        case MODULES.CONTROLS:
          return this.getControls();
        case MODULES.EXECUTION:
          return this.getActions();
        case MODULES.RISK:
          return this.getRisks();
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return this.getNodeElementAllData();
        case MODULES.COMPLIANCE_IA_SYSTEM:
          return this.getInformationAssetsSystems();
        case MODULES.COMPLIANCE_IA_PROCESSOR:
          return this.getInformationAssetsProcessors();
        case MODULES.COMPLIANCE_IA_CONTROLLER:
          return this.getInformationAssetsControllers();
        case MODULES.INCIDENTS:
          return Promise.all([this.getIncidents(), this.getIncidentStatuses()]);
        case MODULES.COMPLIANCE_INFORMATION_ASSETS:
          return this.getCustomInformationAssets();
        case MODULES.RISK_ASSESSMENT:
          return this.getRiskAssessment();
      }
    },
    preloadSelectedItems: function preloadSelectedItems() {
      var _this = this;
      var modulesToLoad = Object.keys(this.links);
      var promises = [];
      if (this.data.linksFromGaps) {
        modulesToLoad = _toConsumableArray(new Set([].concat(_toConsumableArray(Object.keys(this.data.linksFromGaps)), _toConsumableArray(modulesToLoad))));
      }
      if (this.governanceReviewLinkId && !Object.keys(this.links).includes(this.governanceReviewLinkModule)) {
        modulesToLoad.push(this.governanceReviewLinkModule);
      }
      modulesToLoad.forEach(function (module) {
        if (_this.disabledOptions[module]) return;
        var getAllItems = module !== MODULES.CONTRACTS;
        promises.push(_this.getPromise(module, getAllItems));
      });
      return Promise.all(promises);
    },
    getActions: function getActions() {
      var _this2 = this;
      return DataAPI.getInitiatives().then(function (_ref2) {
        var list = _ref2.list;
        _this2.dataset[MODULES.EXECUTION] = list;
      });
    },
    getRisks: function getRisks() {
      var _this3 = this;
      return DataAPI.getRisks().then(function (_ref3) {
        var list = _ref3.list;
        var riskProjectsIds = _this3.projects.filter(function (project) {
          return project.objType === ObjectTypes.RISK_PROJECT;
        }).map(function (project) {
          return +project.id;
        });
        var risks = list.filter(function (risk) {
          return riskProjectsIds.includes(+risk.projectId);
        });
        var additionalContent = _this3.prepareDatasetByAdditionalContent(risks, MODULES.RISK, 'compliance_projects_id', 'projectId');
        Object.keys(additionalContent).forEach(function (key) {
          _this3.dataset[key] = additionalContent[key];
        });
      });
    },
    getControls: function getControls() {
      var _this4 = this;
      return DataAPI.getControls().then(function (_ref4) {
        var list = _ref4.list;
        _this4.controls = list;
        var controlsFiltered = list.filter(function (control) {
          return control.active && !control.isGovernanceReview;
        });
        var controls = controlsFiltered.sort(function (a, b) {
          return comparePathStrings(a.path, b.path);
        });
        _this4.dataset[MODULES.CONTROLS] = controls;
      });
    },
    getComplianceNodes: function getComplianceNodes() {
      var _this5 = this;
      return DataAPI.getNodes([], false).then(function (_ref5) {
        var list = _ref5.list;
        var nodesFlat = [];
        list.forEach(function (compliance) {
          var nodesWithFormattedTitle = formatNodes(compliance.children);
          Utils.pushChildrenToParent({
            children: nodesWithFormattedTitle
          }, nodesFlat);
        });
        nodesFlat = _this5.formatListToDataset(nodesFlat);
        _this5.complianceNodes = nodesFlat;
        var additionalContent = _this5.prepareDatasetByAdditionalContent(nodesFlat, MODULES.COMPLIANCE, 'compliance_projects_id', 'projectId');
        Object.keys(additionalContent).forEach(function (key) {
          _this5.dataset[key] = additionalContent[key];
        });
      });
    },
    getInformationAssetsSystems: function getInformationAssetsSystems() {
      var _this6 = this;
      return DataAPI.getInformationAssetsSystems().then(function (_ref6) {
        var list = _ref6.list;
        _this6.dataset[MODULES.COMPLIANCE_IA_SYSTEM] = list;
      });
    },
    getInformationAssetsProcessors: function getInformationAssetsProcessors() {
      var _this7 = this;
      return DataAPI.getInformationAssetsProcessors().then(function (_ref7) {
        var list = _ref7.list;
        _this7.dataset[MODULES.COMPLIANCE_IA_PROCESSOR] = list;
      });
    },
    getInformationAssetsControllers: function getInformationAssetsControllers() {
      var _this8 = this;
      return DataAPI.getInformationAssetsControllers().then(function (_ref8) {
        var list = _ref8.list;
        _this8.dataset[MODULES.COMPLIANCE_IA_CONTROLLER] = list;
      });
    },
    getCustomInformationAssets: function getCustomInformationAssets() {
      var _this9 = this;
      return DataAPI.getCustomInformationAssets().then(function (_ref9) {
        var customIA = _ref9.list;
        var previewActivityList = _this9.filterByObjTypes({
          customIA: customIA
        }, _this9.activityTypes).filter(function (item) {
          return item.list.length;
        });
        var preparedIAList = LinksTabUtils.prepareIAObject(customIA, previewActivityList);
        var additionalContent = _this9.prepareDatasetByAdditionalContent(preparedIAList, MODULES.COMPLIANCE_INFORMATION_ASSETS, 'activity_id');
        Object.keys(additionalContent).forEach(function (key) {
          _this9.dataset[key] = additionalContent[key];
        });
        _this9.dataset[MODULES.COMPLIANCE_INFORMATION_ASSETS] = preparedIAList;
        _this9.activityList = previewActivityList;
      }).catch(function () {});
    },
    getContracts: function getContracts() {
      var _this10 = this;
      var getAllItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var ids = null;
      if (!getAllItems) {
        ids = this.links[MODULES.CONTRACTS];
      }
      return DataAPI.getContracts(true, ids).then(function (_ref10) {
        var list = _ref10.list;
        return _this10.dataset[MODULES.CONTRACTS] = list;
      }).catch(function () {});
    },
    getCompanies: function getCompanies() {
      var _this11 = this;
      return _getCompanies().then(function (_ref11) {
        var list = _ref11.list;
        return _this11.dataset[MODULES.COMPANY] = list;
      });
    },
    getRiskAssessment: function getRiskAssessment() {
      var _this12 = this;
      var id = this.data.links[MODULES.RISK_ASSESSMENT][0];
      var params = "?filters[id]=".concat(id);
      return getReports(params).then(function (_ref12) {
        var list = _ref12.list;
        return _this12.dataset[MODULES.RISK_ASSESSMENT] = list || [];
      }).catch(function () {});
    },
    getWorkflowStatuses: function getWorkflowStatuses(module) {
      var _this13 = this;
      return getWorkflowStatusesByModule(module).then(function (_ref13) {
        var list = _ref13.list;
        return _this13.workflows[module] = list;
      }).catch(function () {});
    },
    getIncidentStatuses: function getIncidentStatuses() {
      this.workflows[MODULES.INCIDENTS] = this.getAllStatuses(MODULES.INCIDENTS);
      return Promise.resolve(true);
    },
    getIncidents: function getIncidents() {
      var _this14 = this;
      //doestn`t have analogue in dataapi
      return _getIncidents().then(function (response) {
        return _this14.dataset[MODULES.INCIDENTS] = response;
      });
    },
    getNodeElementAllData: function getNodeElementAllData() {
      return DataAPI.getAllTrees().then(this.setIdToProccessTreeIdAndGetNodes);
    },
    getGapAnalysisWithMitigatingActions: function getGapAnalysisWithMitigatingActions() {
      var query = '?filters[]=onlyItemsWithLinks&data[]=article';
      var projectId = this.data.compliance_projects_id;
      var nodeId = this.data.id;
      return getAllForNode(projectId, nodeId, query);
    },
    setIdToProccessTreeIdAndGetNodes: function setIdToProccessTreeIdAndGetNodes(response) {
      var _this15 = this;
      if (response[0].id !== null) {
        this.processTreeId = response[0].id;
      }
      return DataAPI.getNodesByProcessTreeId(this.processTreeId).then(function (rootNode) {
        var processLibraryNodes = [];
        var nodesWithFormattedTitle = formatNodes(rootNode.children);
        Utils.pushChildrenToParent({
          children: nodesWithFormattedTitle
        }, processLibraryNodes);
        _this15.dataset[MODULES.COMPLIANCE_PROCESS_TREE_NODE] = processLibraryNodes;
      });
    },
    getProjects: function getProjects() {
      var _this16 = this;
      var promises = [Promise.all([DataAPI.getComplianceProjects(), getRiskProjects()]).then(function (_ref14) {
        var _ref15 = _slicedToArray(_ref14, 2),
          compliance = _ref15[0],
          risk = _ref15[1];
        _this16.projects = [].concat(_toConsumableArray(compliance.list), _toConsumableArray(risk.list));
      })];
      if (this.currentUser.iaAccess || this.isAdmin) {
        promises.push(this.getCustomInformationAssets());
      }
      return Promise.all(promises);
    },
    getScoringForERM: function getScoringForERM() {
      var _this17 = this;
      return getCurrentScoring().then(function (scoring) {
        return _this17.scoring = scoring;
      });
    },
    getSingleItemPromise: function getSingleItemPromise(item) {
      switch (item.objType) {
        case ObjectTypes.EXECUTION_INITIATIVE_LIMITED:
          return getSingleInitiative(item.slug);
        case ObjectTypes.RISK_RISK_LIMITED:
          return getRiskData(item.projectId, item.projectPeriodId, item.rno, '', true);
        case ObjectTypes.CONTROLS_CONTROL_LIMITED:
          return getControlById(item.cpath);
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM:
        case ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR:
        case ObjectTypes.INFORMATION_ASSET_LIMITED:
          return getSingleByType(ComplianceControllers[item.objType], item.id);
        case ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE_LIMITED:
          return getNodeById(this.processTreeId, item.id);
        case ObjectTypes.CONTRACT_LIMITED:
          return getContract(item.id);
        case ObjectTypes.COMPLIANCE_NODE:
        case ObjectTypes.COMPLIANCE_NODE_LIMITED:
          return getComplianceNode(item.projectId || item.compliance_projects_id, item.id, '?data[]');
        default:
          return null;
      }
    },
    loadAdditionalData: function loadAdditionalData() {
      var _this18 = this;
      var promises = [];
      if (this.complianceUserHasAccessToGap) {
        promises.push(this.getGapAnalysisWithMitigatingActions().then(function (_ref16) {
          var list = _ref16.list;
          _this18.anotherLinks[TabTypes.GAPLINKS] = LinksTabUtils.prepareMitigatingActions(list.filter(function (item) {
            return !item.article.hidden;
          }), _this18.allProjectOptions, _this18.data);
        }));
      }
      if (this.data.gapListUrl) {
        promises.push(this.getGapLinksData());
      }
      if (this.data.nodeListUrl) {
        promises.push(getByUrl(this.data.nodeListUrl).then(function (_ref17) {
          var list = _ref17.list;
          return LinksTabUtils.prepareQuestionnaireData(list, _this18.anotherLinks);
        }));
      }
      return Promise.all(promises);
    },
    getGapLinksData: function getGapLinksData() {
      var _this19 = this;
      var compliancePromise = !this.loadedModulesMap.includes(MODULES.COMPLIANCE) ? this.getComplianceNodes() : Promise.resolve();
      return compliancePromise.then(function () {
        return getByUrl(_this19.data.gapListUrl);
      }).then(function (_ref18) {
        var list = _ref18.list;
        return _this19.anotherLinks[TabTypes.GAPLINKS] = LinksTabUtils.prepareGapData(_this19.getFilteredNodes(list), _this19.allProjectOptions);
      });
    },
    getFilteredNodes: function getFilteredNodes(nodes) {
      var _this20 = this;
      return nodes.filter(function (node) {
        return _this20.activeComplianceNodesIds.includes(node.nodeId) && !(node !== null && node !== void 0 && node.article.hidden);
      });
    },
    isResponseError: function isResponseError(response) {
      if (response.error || response.fullError || response.status === 403) return true;
      if (response.data && response.data.error) return true;
      if (response.response && response.response.error) return true;
      return false;
    }
  }
};