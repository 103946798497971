// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risks-my[data-v-3847b94c] {
  position: relative;
}
.risks-my .risks-main-title[data-v-3847b94c] {
  position: absolute;
  top: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
