import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  class: "text-left mt-3"
};
var _hoisted_3 = {
  class: "border-b border-gray-350"
};
var _hoisted_4 = {
  class: "w-200px p-2 pl-0"
};
var _hoisted_5 = {
  class: "p-2 pl-0"
};
var _hoisted_6 = {
  class: "p-2 pl-0"
};
var _hoisted_7 = {
  class: "p-2 pl-0"
};
var _hoisted_8 = {
  class: "p-2 pl-0"
};
var _hoisted_9 = {
  class: "p-2 pl-0"
};
var _hoisted_10 = {
  class: "mt-4 flex justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    title: $data.translate.chooseHowYouWantToReceiveNotifications
  }, null, 8 /* PROPS */, ["title"]), $data.isUserLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_3, [_createElementVNode("th", _hoisted_4, _toDisplayString($data.translate.rismaModule), 1 /* TEXT */), _createElementVNode("th", _hoisted_5, _toDisplayString($data.translate.notification), 1 /* TEXT */), _createElementVNode("th", _hoisted_6, _toDisplayString($data.translate.email), 1 /* TEXT */)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.notifications, function (notification, key) {
    return _openBlock(), _createElementBlock("tr", {
      key: key,
      class: "border-b border-gray-350"
    }, [_createElementVNode("td", _hoisted_7, _toDisplayString(notification.module), 1 /* TEXT */), _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.$trans(notification.notification)), 1 /* TEXT */), _createElementVNode("td", _hoisted_9, [_createVNode(_component_checkboxes, {
      "checkbox-size": 20,
      options: notification.isSet,
      "center-checkbox": true,
      onUpdated: function onUpdated($event) {
        return notification.isSet = $event.isChecked;
      }
    }, null, 8 /* PROPS */, ["options", "onUpdated"])])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_10, [_createVNode(_component_risma_button, {
    text: $data.translate.save,
    type: "save",
    onClick: $options.save
  }, null, 8 /* PROPS */, ["text", "onClick"])])]);
}