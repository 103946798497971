import { tabbable } from 'tabbable';
import { generateKey } from '@/utils/Utils';
var activateFocusTrap = function activateFocusTrap() {
  var cachedTraps = [];
  return function (root, trapContainers) {
    // for correct overriding behavior with several focus traps
    cachedTraps.forEach(function (trapContext) {
      trapContext.deactivate();
    });
    var activated = false;
    var tabbableElements = getTrappingElements(root, trapContainers);
    var newTrapContext = {
      id: generateKey(),
      activate: function activate() {
        !activated && tabbableElements.forEach(function (el) {
          if (!el.dataset.tabValue) {
            var initialTabValue = el.getAttribute('tabindex');
            initialTabValue && el.setAttribute('data-tabvalue', initialTabValue);
          }
          el.setAttribute('tabindex', '-1');
        });
        activated = true;
      },
      deactivate: function deactivate() {
        activated && tabbableElements.forEach(function (el) {
          var tabvalue = el.dataset.tabvalue;
          tabvalue ? el.setAttribute('tabindex', tabvalue) : el.removeAttribute('tabindex');
        });
        activated = false;
      },
      update: function update() {
        if (activated) {
          this.deactivate();
          tabbableElements = getTrappingElements(root, trapContainers);
          this.activate();
        }
      },
      remove: function remove() {
        var _this = this;
        if (activated) {
          this.deactivate();
        }
        cachedTraps = cachedTraps.filter(function (obj) {
          return obj.id !== _this.id;
        });
        cachedTraps.length && cachedTraps[cachedTraps.length - 1].activate();
      }
    };
    cachedTraps.push(newTrapContext);
    return newTrapContext;
  };
};
var createFocusTrap = activateFocusTrap();
export default createFocusTrap;
var getTrappingElements = function getTrappingElements(root, trapContainers) {
  return tabbable(root, {
    displayCheck: 'none'
  }).filter(function (el) {
    return el.getAttribute('tabindex') !== '-1' && !trapContainers.some(function (container) {
      return container.contains(el);
    });
  });
};