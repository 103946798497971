import TreeList from '@/components/Atoms/TreeList';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
export default {
  components: {
    TreeList: TreeList,
    TrafficLight: TrafficLight
  },
  data: function data() {
    return {
      treeData: {
        title: 'Root',
        color: 'green',
        children: [{
          title: 'children 1',
          color: 'blue',
          children: [{
            title: 'children 1.1'
          }]
        }, {
          title: 'children 2',
          color: 'yellow',
          children: [{
            title: 'children 2.1',
            color: 'black',
            children: [{
              title: 'children 2.1.1'
            }]
          }, {
            title: 'children 2.2',
            color: 'black'
          }]
        }]
      }
    };
  }
};