// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-up-menu[data-v-68d316f3] {
  position: absolute;
  width: 23em;
  z-index: 9999;
  border: 1px solid #BEBEBE;
  background: #FFFFFF;
  font-size: 12px;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
}
.pop-up-menu[data-v-68d316f3]  .menuItem {
  padding: 3px;
}
.pop-up-menu[data-v-68d316f3]  .menuItem:hover {
  background-color: #347480;
  cursor: pointer;
  color: #FFFFFF !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
