function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FilesList from '@/components/Atoms/FilesList';
import ProgressBar from '@/components/Atoms/ProgressBar/ProgressBar';
import { getBase64Content } from '@/utils/Utils';
import { FileFormats } from '@/constants/FileFormats';
export default {
  name: 'RismaFileUpload',
  introduction: 'FileUploader with progress bar',
  description: 'This is a FileUploader input field and progress bar, InputField & LoadingIndicatorSmall components.',
  token: '<risma-file-upload @upload="fileUploaded"/>',
  components: {
    RismaTitle: RismaTitle,
    FilesList: FilesList,
    ProgressBar: ProgressBar
  },
  props: {
    showUploadedFiles: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show list of uploaded files'
    },
    showProgressBar: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Show progress bar'
    },
    useFormData: {
      type: Boolean,
      default: false,
      required: false,
      note: 'If to use formData format for files'
    },
    singleFileLoad: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Loading only one file, on reload will replace the file'
    },
    nativeDesign: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Use it to make design similar to native input type file'
    },
    showExtensionsTitle: {
      type: Boolean,
      default: true,
      required: false
    },
    useFileSizeLimit: {
      type: Boolean,
      default: true,
      required: false
    },
    customAttachmentsAllowed: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: false,
      note: 'List of attachments types that are allowed'
    },
    useRawFileContent: {
      type: Boolean,
      default: null,
      required: false
    }
  },
  emits: ['input', 'upload', 'uploadFiles', 'startLoading', 'upload:supported', 'upload:unsupported', 'upload:overLimit', 'upload:emptyFile'],
  data: function data() {
    return {
      uploading: false,
      loadProgress: 1,
      isDragOver: false,
      existingFiles: [],
      translate: getTranslate['RismaFileUpload']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue',
    attachmentSizeLimit: 'attachmentSizeLimit',
    attachmentSizeLimitReadable: 'attachmentSizeLimitReadable'
  })), {}, {
    attachmentsAllowed: function attachmentsAllowed() {
      var settingValue = this.getSettingValue('risma.attachments_allowed');
      if (!settingValue) return '';
      var attachmentsAllowed = Object.keys(settingValue);
      if (this.customAttachmentsAllowed.length) {
        return this.customAttachmentsAllowed.filter(function (attachment) {
          return attachmentsAllowed.includes(attachment);
        }).join(', ');
      }
      return attachmentsAllowed.join(', ');
    },
    filesLabel: function filesLabel() {
      var filesLength = this.existingFiles.length;
      return !filesLength ? this.translate.noFileChosen : filesLength > 1 ? "".concat(filesLength, " ").concat(this.translate.files) : this.existingFiles[0].filename;
    },
    supportExtensionTitle: function supportExtensionTitle() {
      return "".concat(this.translate.weSupport, " ").concat(this.attachmentsAllowed);
    }
  }),
  mounted: function mounted() {
    var dndArea = this.$el.querySelector('.dnd-area');
    dndArea.addEventListener('dragenter', this.toggleModalContainerMask);
    dndArea.addEventListener('dragover', this.dragoverDummy);
    dndArea.addEventListener('dragleave', this.toggleModalContainerMask);
    dndArea.addEventListener('drop', this.attachFilesOnDrop);
  },
  beforeUnmount: function beforeUnmount() {
    var dndArea = this.$el.querySelector('.dnd-area');
    dndArea.removeEventListener('dragenter', this.toggleModalContainerMask);
    dndArea.removeEventListener('dragover', this.dragoverDummy);
    dndArea.removeEventListener('dragleave', this.toggleModalContainerMask);
    dndArea.removeEventListener('drop', this.attachFilesOnDrop);
  },
  methods: {
    toggleModalContainerMask: function toggleModalContainerMask(e) {
      this.isDragOver = !this.isDragOver;
      e.preventDefault();
    },
    dragoverDummy: function dragoverDummy(e) {
      e.preventDefault();
    },
    attachFilesOnDrop: function attachFilesOnDrop(e) {
      this.toggleModalContainerMask(e);
      var files = e.dataTransfer.files;
      this.handleLoad(files);
    },
    handleLoad: function handleLoad(files) {
      if (this.nativeDesign) {
        this.existingFiles = [];
      }
      if (this.useFileSizeLimit && !this.checkForFilesSize(files)) {
        this.$notify({
          title: this.translate.uploadErrorFileMightBeTooLargeToUpload(this.attachmentSizeLimitReadable),
          type: 'error'
        });
        this.$emit('upload:overLimit');
        return;
      }
      if (!this.checkForEmptyFiles(files)) {
        this.$notify({
          title: this.translate.oneOfTheFilesYouAreTryingToUploadIsEmpty,
          type: 'error'
        });
        this.$emit('upload:emptyFile');
        return;
      }
      if (!this.checkForFilesExtension(files)) {
        this.$notify({
          title: this.translate.pleaseChooseTheFilesWithCorrectFiletypes,
          type: 'error'
        });
        this.$emit('upload:unsupported');
        return;
      }
      this.loadFile(files);
    },
    clickInput: function clickInput() {
      this.$el.querySelector('.input-load').click();
    },
    addFilesToFormData: function addFilesToFormData(eTargetFiles) {
      var formData = new FormData();
      for (var i = 0; i < eTargetFiles.length; i++) {
        var file = eTargetFiles[i];
        formData.append("files[".concat(i, "]"), file, file.name);
      }
      this.$emit('uploadFiles', formData);
    },
    loadFile: function loadFile(eTargetFiles) {
      var _this = this;
      var numOfFiles = Array.from(eTargetFiles).length;
      if (numOfFiles === 0) {
        this.uploading = false;
        return;
      }
      this.$emit('startLoading');
      this.uploading = true;
      var files = [];
      var loadedFiles = 0;
      Array.from(eTargetFiles).forEach(function (file) {
        var fileExtension = file.name.split('.').pop();
        var fileObj = {
          filename: file.name,
          size: file.size
        };
        if (file.type !== '') {
          fileObj['mimeType'] = file.type;
        } else if (fileExtension === FileFormats.csv) {
          fileObj['mimeType'] = 'text/csv';
        } else if (fileExtension === FileFormats.msg) {
          fileObj['mimeType'] = 'application/vnd.bbf.usp.msg';
        } else {
          fileObj['mimeType'] = '';
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = function () {
          _this.loadProgress = 1;
        };
        reader.onload = function (e) {
          fileObj['content'] = _this.useRawFileContent ? e.target.result : getBase64Content(e);
          files.push(fileObj);
        };
        reader.onloadend = function () {
          setTimeout(function () {
            _this.uploading = false;
          }, 500);
          loadedFiles++;
          if (loadedFiles !== numOfFiles) return;
          _this.existingFiles = _this.singleFileLoad ? files : _this.existingFiles.concat(files);
          _this.$emit('upload', _this.existingFiles);
          _this.$emit('input', files);
          if (_this.useFormData) {
            _this.addFilesToFormData(eTargetFiles);
          }
        };
        reader.onprogress = function (e) {
          if (e.lengthComputable) {
            _this.loadProgress = e.loaded / e.total * 100;
          }
        };
      });
    },
    deleteFile: function deleteFile(index) {
      this.existingFiles.splice(index, 1);
      this.$refs.fileInput.value = null;
      this.$emit('upload', this.existingFiles);
    },
    checkForFilesExtension: function checkForFilesExtension(files) {
      var _this2 = this;
      return !Array.from(files).some(function (file) {
        var fileExtension = file.name.split('.').pop();
        return fileExtension !== undefined && _this2.attachmentsAllowed.indexOf(fileExtension.toLowerCase()) === -1;
      });
    },
    checkForFilesSize: function checkForFilesSize(files) {
      var _this3 = this;
      return !Array.from(files).some(function (file) {
        return file.size > _this3.attachmentSizeLimit;
      });
    },
    checkForEmptyFiles: function checkForEmptyFiles(files) {
      return !Array.from(files).some(function (file) {
        return file.size === 0;
      });
    }
  }
};