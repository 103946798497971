// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-155e4776]  td:first-child {
  width: 200px;
  margin-left: 0;
}
.docTitle[data-v-155e4776] {
  overflow-wrap: anywhere !important;
}
[data-v-155e4776]  td {
  max-width: 200px;
  margin-left: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
