import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "max-h-500px overflow-y-auto"
};
var _hoisted_2 = {
  class: "w-full"
};
var _hoisted_3 = {
  class: "text-left pl-2"
};
var _hoisted_4 = {
  class: "font-normal"
};
var _hoisted_5 = {
  class: "font-normal"
};
var _hoisted_6 = {
  class: "font-normal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_gap_table_row = _resolveComponent("gap-table-row");
  var _component_pagination = _resolveComponent("pagination");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_3, _toDisplayString($data.translate.gapInformation), 1 /* TEXT */), $props.showRiskAssessment ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createElementVNode("th", null, [_createTextVNode(_toDisplayString($data.translate.likelihood) + " ", 1 /* TEXT */), _createElementVNode("small", _hoisted_4, [_cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.beforeafter), 1 /* TEXT */)])]), _createElementVNode("th", null, [_createTextVNode(_toDisplayString($data.translate.impact) + " ", 1 /* TEXT */), _createElementVNode("small", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.beforeafter), 1 /* TEXT */)])]), _createElementVNode("th", null, [_createTextVNode(_toDisplayString($data.translate.rpi) + " ", 1 /* TEXT */), _createElementVNode("small", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.beforeafter), 1 /* TEXT */)])])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.gapsSlice, function (gap, index) {
    return _openBlock(), _createBlock(_component_gap_table_row, {
      key: index,
      gap: gap,
      "show-risk-assessment": $props.showRiskAssessment,
      "gap-article": $props.gapArticleMap.get(gap.articleId),
      "project-id": $props.projectId
    }, null, 8 /* PROPS */, ["gap", "show-risk-assessment", "gap-article", "project-id"]);
  }), 128 /* KEYED_FRAGMENT */))])])]), _createVNode(_component_pagination, {
    total: $props.gaps.length,
    "per-page": $data.pageSize,
    onChange: $options.handleShownList
  }, null, 8 /* PROPS */, ["total", "per-page", "onChange"])]);
}