import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_traffic_light_selector, {
    modelValue: $data.values[0],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.values[0] = $event;
    }),
    "light-type": "incidents"
  }, null, 8 /* PROPS */, ["modelValue"]), _cache[2] || (_cache[2] = _createTextVNode(" Ready only ")), _createVNode(_component_traffic_light_selector, {
    modelValue: $data.values[1],
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.values[1] = $event;
    }),
    "read-only": true,
    "light-type": "incidents"
  }, null, 8 /* PROPS */, ["modelValue"])], 64 /* STABLE_FRAGMENT */);
}