import { defineComponent, render, h } from 'vue';
import ToolTip from '@/components/Atoms/Containers/ToolTip';
import { generateKey } from '@/utils/Utils';
var instanceCache = {};
export var tooltip = {
  mounted: function mounted(el, binding) {
    createTooltip(el, binding);
  },
  updated: function updated(el, binding) {
    var _getBindingContent;
    var id = el.dataset.tooltipId;
    var instance = instanceCache[id];
    var value = (_getBindingContent = getBindingContent(binding)) !== null && _getBindingContent !== void 0 ? _getBindingContent : el.innerText;
    if (!(instance !== null && instance !== void 0 && instance.content)) {
      createTooltip(el, binding);
      return;
    }
    if (instance.content !== value) {
      clearWrapper(el);
      createTooltip(el, binding);
    }
  },
  beforeUnmount: function beforeUnmount(el) {
    clearWrapper(el);
  }
};
function createTooltip(el, binding) {
  var _getBindingContent2;
  var content = (_getBindingContent2 = getBindingContent(binding)) !== null && _getBindingContent2 !== void 0 ? _getBindingContent2 : el.innerText;
  if (!content) return; // to not create tooltip with empty content;

  var tooltip = defineTooltip(binding, content);
  var id = generateKey();
  var wrapper = document.createElement('div');
  wrapper.style.display = 'none';
  el.dataset.tooltipId = id;
  instanceCache[id] = {
    content: content,
    wrapper: wrapper
  };
  render(h(tooltip), wrapper);
  prepareDOMElContainer(el, binding);
  el.appendChild(wrapper);
}
function defineTooltip(binding, content) {
  return defineComponent({
    setup: function setup() {
      return function () {
        var _binding$arg, _binding$modifiers;
        return h(ToolTip, {
          arrowDirection: (_binding$arg = binding.arg) !== null && _binding$arg !== void 0 ? _binding$arg : 'top',
          tooltipClasses: (_binding$modifiers = binding.modifiers) !== null && _binding$modifiers !== void 0 && _binding$modifiers.wide ? 'w-max' : ''
        }, {
          default: function _default() {
            return h('div', content);
          }
        });
      };
    }
  });
}
function getBindingContent(binding) {
  var _binding$value;
  return ((_binding$value = binding.value) === null || _binding$value === void 0 ? void 0 : _binding$value.content) || binding.value;
}
function clearWrapper(el) {
  var id = el.dataset.tooltipId;
  var instance = instanceCache[id];
  if (instance !== null && instance !== void 0 && instance.wrapper) {
    render(null, instance.wrapper);
    instance.wrapper.remove();
  }
  delete instanceCache[id];
  el.removeEventListener('mouseenter', onMouseEnter);
  el.removeEventListener('mouseleave', onMouseLeave);
  el.removeEventListener('click', onMouseClick);
}
function prepareDOMElContainer(el, binding) {
  var _binding$modifiers2;
  var isElStatic = window.getComputedStyle(el).position === 'static';
  if (isElStatic) {
    el.style.position = 'relative';
  }
  if ((_binding$modifiers2 = binding.modifiers) !== null && _binding$modifiers2 !== void 0 && _binding$modifiers2.click) {
    el.addEventListener('click', onMouseClick);
  } else {
    el.addEventListener('mouseenter', onMouseEnter);
    el.addEventListener('mouseleave', onMouseLeave);
  }
}
function onMouseEnter(event) {
  var id = event.target.dataset.tooltipId;
  var wrapper = instanceCache[id].wrapper;
  wrapper.style.display = 'block';
}
function onMouseClick(event) {
  event.stopPropagation();
  var id = event.target.dataset.tooltipId;
  var wrapper = instanceCache[id].wrapper;
  wrapper.style.display = wrapper.style.display === 'none' ? 'block' : 'none';
  document.addEventListener('click', function () {
    return wrapper.style.display = 'none';
  }, {
    once: true
  });
}
function onMouseLeave(event) {
  var id = event.target.dataset.tooltipId;
  var wrapper = instanceCache[id].wrapper;
  wrapper.style.display = 'none';
}