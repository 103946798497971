function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getRisks as _getRisks } from '@/api/risk/risk';
import RiskPeriodsWell from '@/components/Organisms/RiskAdmin/RiskPeriodsWell';
import RiskTypesWell from '@/components/Organisms/RiskAdmin/RiskTypesWell';
import ManagementLevelsWell from '@/components/Organisms/RiskAdmin/ManagementLevelsWell';
import ApprovalStatusWell from '@/components/Organisms/RiskAdmin/ApprovalStatusWell';
import Notification from '@/components/Molecules/Notification';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export default {
  name: 'AdminRiskScoring',
  introduction: 'ERM admin settings component',
  description: 'Includes risk periods, risk types, management levels and approval status wells',
  token: '<admin-risk-scoring />',
  components: {
    RiskPeriodsWell: RiskPeriodsWell,
    RiskTypesWell: RiskTypesWell,
    ManagementLevelsWell: ManagementLevelsWell,
    ApprovalStatusWell: ApprovalStatusWell,
    Notification: Notification
  },
  props: {
    errorScoring: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loaded: false,
      riskTypesRisksHash: {},
      risks: [],
      errorMessage: this.errorScoring || ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    showApprovalStatusWell: function showApprovalStatusWell() {
      return this.getSettingValue('risma.riskapproval_enabled');
    }
  }),
  mounted: function mounted() {
    this.getRisks();
  },
  methods: {
    getRisks: function getRisks() {
      var _this = this;
      return _getRisks(RiskProjects.ENTERPRISE_RISK_MANAGEMENT.id, null).then(function (_ref) {
        var list = _ref.list;
        _this.risks = list;
        _this.loaded = true;
      }).catch(function (error) {
        return error;
      });
    },
    updateApprovedRisks: function updateApprovedRisks(ids) {
      var _this2 = this;
      ids.forEach(function (id) {
        var risk = _this2.risks.find(function (risk) {
          return risk.id === id;
        });
        risk.approved = false;
      });
      this.risks = _toConsumableArray(this.risks);
    }
  }
};