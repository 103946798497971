// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-space[data-v-15e8acb0] {
  margin-top: 1rem;
}
.incident-entry-actions[data-v-15e8acb0] {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
}
.help-tool-tip[data-v-15e8acb0]  .tool-tip {
  min-width: 400px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
