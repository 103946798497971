import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'DrapAndDropIcon',
  introduction: 'Icon for drag and drop',
  token: '<drag-and-drop-icon />',
  components: {
    FeatherIcon: FeatherIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};