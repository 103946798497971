import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 4,
  class: "w-48 ml-2 uppercase inline-block truncate text-title-small"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  return !$props.modelValue.hidden ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "branch-wrapper",
    onDragstart: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('dragStart', $props.modelValue);
    })
  }, [_createElementVNode("div", {
    class: _normalizeClass(["tree-rectangle flex items-center relative", [{
      'all-except-first': $props.isChild
    }, {
      'has-children': $options.hasChildren
    }, {
      ghost: $props.modelValue.isGhost
    }, {
      'clone-branch': $props.modelValue.isClone
    }]])
  }, [$options.trafficLightColor ? (_openBlock(), _createBlock(_component_traffic_light, {
    key: 0,
    class: "ml-1",
    color: $options.trafficLightColor.color
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), $props.modelValue.objType === $data.objectTypes.ORGANISATION ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 1,
    class: "ml-1 stroke-2 text-blue-750",
    icon: "folder"
  })) : _createCommentVNode("v-if", true), $props.modelValue.closed ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 2,
    class: "ml-1 stroke-2 text-blue-750",
    icon: "check"
  })) : _createCommentVNode("v-if", true), $props.modelValue.url ? (_openBlock(), _createBlock(_component_router_link, {
    key: 3,
    class: "w-48 ml-2 uppercase inline-block truncate text-title-small",
    to: $props.modelValue.url
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.rightTitle), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($options.rightTitle), 1 /* TEXT */)), !$props.disableItemMenu ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 5,
    icon: "more-horizontal",
    class: "cursor-pointer absolute left-56"
  })) : _createCommentVNode("v-if", true), $props.enableAddButton ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 6,
    icon: "plus",
    class: "cursor-pointer absolute left-56 stroke-2 text-blue-750"
  })) : _createCommentVNode("v-if", true), $options.hasChildren ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 7,
    icon: "chevron-up",
    class: _normalizeClass(['transform duration-500 absolute top-18/100 left-15.5rem cursor-pointer text-blue-750 stroke-2', {
      'rotate-90': $data.isOpened
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.isOpened = !$data.isOpened;
    })
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 32 /* NEED_HYDRATION */)) : _createCommentVNode("v-if", true);
}