// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-gsb .risk-gsb-content[data-v-8f2dc382] {
  display: flex;
  flex-wrap: wrap;
}
.risk-gsb .risk-gsb-content .risk-gsb-section[data-v-8f2dc382] {
  margin: 0 12px 12px 12px;
  min-width: 220px;
}
.risk-gsb .risk-gsb-content .risk-gsb-section[data-v-8f2dc382]  h3.medium {
  font-weight: 600;
}
.risk-gsb .risk-gsb-content .risk-gsb-section[data-v-8f2dc382]  .single-select .single-select-reset {
  z-index: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
