import i18n from '@/i18n';
import { defineStore } from 'pinia';
import { getOverviewDashboard } from '@/api/compliance/dashboard';
import { useNotificationPopUpStore } from '@/Store/notificationPopUpStore';
export var useComplianceOverview = defineStore('complianceOverview', {
  state: function state() {
    return {
      materialityOverview: [],
      materialityDistribution: {},
      gapOverview: []
    };
  },
  actions: {
    fetchOverviewDashboard: function fetchOverviewDashboard(projectId) {
      var _this = this;
      return getOverviewDashboard(projectId).then(function (_ref) {
        var materialityOverview = _ref.materialityOverview,
          materialityDistribution = _ref.materialityDistribution,
          gapOverview = _ref.gapOverview;
        _this.materialityOverview = materialityOverview;
        _this.materialityDistribution = materialityDistribution;
        _this.gapOverview = gapOverview;
      }).catch(function (error) {
        var notificationPopUpStore = useNotificationPopUpStore();
        notificationPopUpStore.handleInstantNotification({
          title: i18n.t('Something went wrong!'),
          type: 'error'
        });
        throw error;
      });
    }
  }
});