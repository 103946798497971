function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export var RiskBaseModelProps = {
  THREAT_COMMENT: 'threatComment',
  THREAT_LEVEL_COMMENT: 'threatLevelComment',
  VULNERABILITY_COMMENT: 'vulnerabilityComment',
  PROBABILITY_COMMENT: 'probabilityComment',
  THREAT_VALUE: 'threatValue',
  THREAT_LEVEL_VALUE: 'threatLevelValue',
  VULNERABILITY_VALUE: 'vulnerabilityValue',
  THREAT_IDS: 'threatIds',
  VULNERABILITY_IDS: 'vulnerabilityIds',
  PROBABILITY_SCORE: 'probabilityScore',
  CONSEQUENCE_COMMENT: 'consequenceComment',
  INHERENT_CONSEQUENCE: 'inherentConsequence',
  INHERENT_PROBABILITY: 'inherentProbability',
  RESIDUAL_CONSEQUENCE: 'residualConsequence',
  RESIDUAL_PROBABILITY: 'residualProbability',
  INHERENT_CONSEQUENCE_ROW_SCORES: 'inherentConsequenceRowScores',
  INHERENT_PROBABILITY_ROW_SCORES: 'inherentProbabilityRowScores',
  RESIDUAL_CONSEQUENCE_ROW_SCORES: 'residualConsequenceRowScores',
  RESIDUAL_PROBABILITY_ROW_SCORES: 'residualProbabilityRowScores',
  ACCOUNTABLE_USER_IDS: 'accountableUserIds',
  CONSULTED_USER_IDS: 'consultedUserIds',
  INFORMED_USER_IDS: 'informedUserIds',
  RESPONSIBLE_USER_IDS: 'responsibleUserIds',
  ORGANISATION_IDS: 'organisationIds',
  TAGS: 'tags',
  APPROVAL_STATUS: 'approved',
  APPROVAL_DATE: 'approvedDate',
  APPROVED_BY_USER_ID: 'approvedByUserId',
  LIKELIHOOD_COMMENT: 'likelihood_comment',
  IMPACT_COMMENT: 'impact_comment',
  Z_AXIS_COMMENT: 'z_axis_comment',
  CUSTOM_IMPACT: 'customImpact',
  CUSTOM_LIKELIHOOD: 'customLikelihood',
  IMPACT_SCORE: 'impact_score',
  IMPACT_OTHER1_SCORE: 'impact_other1_score',
  IMPACT_OTHER2_SCORE: 'impact_other2_score',
  IMPACT_OTHER1_COMMENT: 'impact_other1_comment',
  IMPACT_OTHER2_COMMENT: 'impact_other2_comment',
  USER_IDS: 'userIds',
  RESPONSIBLE: 'responsible',
  ACCOUNTABLE: 'accountable',
  MANAGEMENT_LEVEL_ID: 'managementLevelId',
  STATUS: 'status',
  GROSS_LIKELIHOOD: 'grossLikelihood',
  TOLERANCE_LIKELIHOOD: 'toleranceLikelihood',
  GROSS_IMPACT: 'grossImpact',
  TOLERANCE_IMPACT: 'toleranceImpact',
  CALCULATION_COMMENT: 'calculationsComment',
  LIKELIHOOD_SCORE: 'likelihood_score',
  RISK_GAP: 'riskGap',
  RPI: 'rpi',
  CLOSED: 'closed',
  PREVIOUS_RISK: 'previousRisk',
  RISK_TYPE_ID: 'riskTypeId',
  GRID_STATUS: 'gridStatus',
  PARENT: 'parent'
};
export var scoresNameByField = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.INHERENT_CONSEQUENCE, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES), RiskBaseModelProps.INHERENT_PROBABILITY, RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES), RiskBaseModelProps.RESIDUAL_CONSEQUENCE, RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES), RiskBaseModelProps.RESIDUAL_PROBABILITY, RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES);
export var columnsTypeByScoresName = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, RiskBaseModelProps.INHERENT_CONSEQUENCE_ROW_SCORES, 'impactColumns'), RiskBaseModelProps.INHERENT_PROBABILITY_ROW_SCORES, 'likelihoodColumns'), RiskBaseModelProps.RESIDUAL_CONSEQUENCE_ROW_SCORES, 'impactColumns'), RiskBaseModelProps.RESIDUAL_PROBABILITY_ROW_SCORES, 'likelihoodColumns');