// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-043ac22e]  .modal-wrapper {
  padding: 80px 0; /* 80px is the .top height ( was bug that .top covers this component ) */
}
[data-v-043ac22e]  .risma-title h3 {
  color: #737373;
}
[data-v-043ac22e]  .modal-header {
  margin-bottom: 40px;
}
[data-v-043ac22e]  .modal-container.large {
  width: 50vw;
}
[data-v-043ac22e]  .input-field {
  width: 100%;
}
[data-v-043ac22e]  .notification.form-notification {
  padding: 0px;
  margin-bottom: 0px;
}
[data-v-043ac22e]  .notification.form-notification.error {
  background-color: inherit;
  border: none;
}
[data-v-043ac22e]  .notification-up .input-field {
  margin: 0;
}
[data-v-043ac22e]  .notification-up input {
  border: 1px solid #dc3545;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
