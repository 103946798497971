import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex justify-between mb-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_quiz_questionnaire = _resolveComponent("quiz-questionnaire");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    type: "big",
    title: $data.translate.quizQuestionnaires
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
    icon: "plus",
    width: "40",
    height: "40",
    class: "cursor-pointer",
    onClick: $options.handleOpenQuizQuestionnaireModal
  }, null, 8 /* PROPS */, ["onClick"])]), $data.isModalOpened ? (_openBlock(), _createBlock(_component_quiz_questionnaire, {
    key: 0,
    quiz: $data.quiz,
    "load-state": $data.modalLoadState,
    onDismiss: $options.dismissModal,
    "onCreate:quiz": $options.createNewQuiz,
    "onUpdate:quiz": $options.updateCurrentQuiz
  }, null, 8 /* PROPS */, ["quiz", "load-state", "onDismiss", "onCreate:quiz", "onUpdate:quiz"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 1
  })) : _createCommentVNode("v-if", true), _withDirectives((_openBlock(), _createBlock(_component_risma_table, {
    ref: "quizTable",
    key: $data.tableGeneratedKey,
    "state-key": $data.stateKey,
    api: $options.getQuizQuestionnairesBE,
    onDelete: $options.handleDeleteQuiz,
    onIconClicked: $options.handleIconClick
  }, null, 8 /* PROPS */, ["state-key", "api", "onDelete", "onIconClicked"])), [[_vShow, $data.isLoaded]])]);
}