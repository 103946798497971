import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "grid grid-cols-3 gap-8 mb-6 mr-12"
};
var _hoisted_2 = {
  class: "grid grid-cols-3 gap-8 mb-6 mr-12"
};
var _hoisted_3 = {
  class: "mb-6"
};
var _hoisted_4 = {
  class: "flex"
};
var _hoisted_5 = ["title"];
var _hoisted_6 = {
  class: "flex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
    "model-value": $props.selectedOptions[$data.AssetsTypes.CONTROLLERS].action,
    "with-reset": true,
    title: $data.translate.dataController,
    options: $options.addAssetOptions,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.onOptionChange($event, $data.AssetsTypes.CONTROLLERS, 'action');
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder"]), $props.selectedOptions[$data.AssetsTypes.CONTROLLERS].action === $data.AddAssetActions.CHOOSE_EXISTING ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    "model-value": $props.selectedOptions[$data.AssetsTypes.CONTROLLERS].id,
    title: $data.translate.chooseExistingController,
    options: $options.controllersOptions,
    placeholder: $data.translate.select,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $options.onOptionChange($event, $data.AssetsTypes.CONTROLLERS, 'id');
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_single_select, {
    "model-value": $props.selectedOptions[$data.AssetsTypes.PROCESSORS].action,
    "with-reset": true,
    title: $data.translate.dataProcessor,
    options: $options.addAssetOptions,
    placeholder: $data.translate.select,
    "un-sorted": true,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $options.onOptionChange($event, $data.AssetsTypes.PROCESSORS, 'action');
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder"]), $props.selectedOptions[$data.AssetsTypes.PROCESSORS].action === $data.AddAssetActions.CHOOSE_EXISTING ? (_openBlock(), _createBlock(_component_single_select, {
    key: 0,
    "model-value": $props.selectedOptions[$data.AssetsTypes.PROCESSORS].id,
    title: $data.translate.chooseExistingProcessor,
    options: $options.processorsOptions,
    placeholder: $data.translate.select,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $options.onOptionChange($event, $data.AssetsTypes.PROCESSORS, 'id');
    })
  }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selectedOptions[$data.AssetsTypes.SYSTEMS], function (system, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat(idx, "_").concat(system.id),
      class: "grid grid-cols-3 gap-8 mb-3 mr-12 relative"
    }, [_createVNode(_component_single_select, {
      title: $data.translate.systems,
      "model-value": system.action,
      "with-reset": true,
      placeholder: $data.translate.select,
      options: $options.addAssetOptions,
      "un-sorted": true,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onRowOptionChange($event, 'action', idx, $data.AssetsTypes.SYSTEMS);
      }
    }, null, 8 /* PROPS */, ["title", "model-value", "placeholder", "options", "onUpdate:modelValue"]), system.action === $data.AddAssetActions.CHOOSE_EXISTING ? (_openBlock(), _createBlock(_component_single_select, {
      key: 0,
      "model-value": system.id,
      options: $options.getSystemOptions(system.id),
      title: $data.translate.chooseExistingSystem,
      placeholder: $data.translate.select,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onRowOptionChange($event, 'id', idx, $data.AssetsTypes.SYSTEMS);
      }
    }, null, 8 /* PROPS */, ["model-value", "options", "title", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), system.action === $data.AddAssetActions.CREATE_NEW ? (_openBlock(), _createBlock(_component_input_field, {
      key: 1,
      "model-value": system.title,
      title: $data.translate.newSystem,
      placeholder: $data.translate.enterTitle,
      type: "text",
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onRowOptionChange($event.trim(), 'title', idx, $data.AssetsTypes.SYSTEMS);
      }
    }, null, 8 /* PROPS */, ["model-value", "title", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center", $options.addSystemOptionAvailable ? 'cursor-pointer' : 'cursor-not-allowed']),
    title: $options.addSystemOptionAvailable ? '' : $data.translate.youCantAddMoreAssets,
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.addNewRow($options.addSystemOptionAvailable, $data.AssetsTypes.SYSTEMS);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-blue mr-1"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.add), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_5)])]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selectedOptions[$data.AssetsTypes.INFORMATION_ASSETS_API], function (customAsset, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: "".concat(idx, "_").concat(customAsset.id),
      class: "grid grid-cols-3 gap-8 mb-3 mr-12 relative"
    }, [_createVNode(_component_single_select, {
      "model-value": customAsset.action,
      title: $data.translate.customInformationAssets,
      "with-reset": true,
      placeholder: $data.translate.select,
      options: $options.addAssetOptions,
      "un-sorted": true,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onRowOptionChange($event, 'action', idx, $data.AssetsTypes.INFORMATION_ASSETS_API);
      }
    }, null, 8 /* PROPS */, ["model-value", "title", "placeholder", "options", "onUpdate:modelValue"]), customAsset.action !== $data.AddAssetActions.NONE ? (_openBlock(), _createBlock(_component_single_select, {
      key: 0,
      "model-value": customAsset.activityTypeId,
      title: $data.translate.customIAType,
      options: $options.customIAActivityTypesOptions,
      placeholder: $data.translate.select,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onRowOptionChange($event, 'activityTypeId', idx, $data.AssetsTypes.INFORMATION_ASSETS_API);
      }
    }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true), customAsset.action === $data.AddAssetActions.CHOOSE_EXISTING && customAsset.activityTypeId ? (_openBlock(), _createBlock(_component_single_select, {
      key: 1,
      "model-value": customAsset.id,
      title: $data.translate.chooseExistingIA,
      options: $options.getIAOptionsByActivityType(customAsset.activityTypeId, customAsset.id),
      placeholder: $data.translate.select,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return $options.onRowOptionChange($event, 'id', idx, $data.AssetsTypes.INFORMATION_ASSETS_API);
      }
    }, null, 8 /* PROPS */, ["model-value", "title", "options", "placeholder", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
    class: _normalizeClass(["flex items-center", $options.addIAOptionAvailable ? 'cursor-pointer' : 'cursor-not-allowed']),
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.addNewRow($options.addIAOptionAvailable, $data.AssetsTypes.INFORMATION_ASSETS_API);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-blue mr-1"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.add), 1 /* TEXT */)], 2 /* CLASS */)])])]);
}