function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import * as RiskApi from '@/api/risk/risk';
import { getAllByType } from '@/api/compliance/informationAssets';
import { getInformationAssetsSystems } from '@/api/risma/data';
import * as Utils from '@/utils/Utils';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { RiskProjects } from '@/constants/risks/RiskProjects';
export var RiskUniversalMixin = {
  data: function data() {
    return {
      riskProjects: [],
      currentRiskProject: {},
      periods: [],
      periodSelected: null,
      threats: [],
      levelOfThreats: [],
      vulnerabilities: [],
      probabilities: [],
      riskTypes: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTagsStore, ['tags'])), {}, {
    processes: function processes() {
      if (!this.currentUser.iaAccess || !this.complianceNodes) {
        return [];
      }
      var processes = [];
      var systems = this.systems;
      for (var j = 0; j < systems.length; j++) {
        var system = systems[j];
        processes[system.id] = [];
        var nodes = this.complianceNodes;
        for (var i = 0; i < nodes.length; i++) {
          var node = nodes[i];
          if (system.complianceNodes && system.complianceNodes.indexOf(node.id) !== -1) {
            processes[system.id].push({
              title: node.title,
              link: node.url
            });
          }
        }
      }
      return processes;
    }
  }),
  methods: {
    prepareData: function prepareData() {
      var _this = this;
      this.currentRiskProject = this.riskProjects.find(function (item) {
        return +item.id === +_this.projectId;
      });
      this.riskApprovalEnabled = !!this.currentRiskProject.riskApproval;
      this.periods = _toConsumableArray(this.currentRiskProject.projectPeriods).reverse();
      if (this.periodId) {
        this.periodSelected = this.periods.find(function (item) {
          return item.id === +_this.periodId;
        });
      } else {
        this.periodSelected = this.periods.find(function (item) {
          return item.isCurrent;
        });
      }
      if (typeof this.periodSelected == 'undefined') {
        this.periodSelected = {};
        this.periodSelected.threatLevels = [];
        this.periodSelected.probabilityLabels = [];
        this.periodSelected.consequenceRows = [];
      }
    },
    afterPrepareData: function afterPrepareData() {
      this.getThreats();
      this.getLevelOfThreats();
      this.getVulnerabilities();
      this.getProbabilities();
    },
    getRiskProjects: function getRiskProjects() {
      var _this2 = this;
      return RiskApi.getRiskProjects().then(function (_ref) {
        var list = _ref.list;
        _this2.riskProjects = _this2.adjustRiskProjects(list);
      }).catch(function (e) {
        throw e;
      });
    },
    adjustRiskProjects: function adjustRiskProjects(projects) {
      return projects.map(function (project) {
        if (project.type === RiskProjects.ENTERPRISE_RISK_MANAGEMENT.type) return project;
        var projectPeriods = project.projectPeriods.map(function (period) {
          var consequenceRows = period.consequenceRows.map(function (cRow) {
            return _objectSpread(_objectSpread({}, cRow), {}, {
              impactColumns: cRow.impactColumns.splice(0, period.consequenceLevels)
            });
          });
          var probabilityRows = period.probabilityRows.map(function (pRow) {
            return _objectSpread(_objectSpread({}, pRow), {}, {
              likelihoodColumns: pRow.likelihoodColumns.splice(0, period.probabilityLevels)
            });
          });
          return _objectSpread(_objectSpread({}, period), {}, {
            consequenceRows: consequenceRows,
            probabilityRows: probabilityRows
          });
        });
        return _objectSpread(_objectSpread({}, project), {}, {
          projectPeriods: projectPeriods
        });
      });
    },
    getThreats: function getThreats() {
      this.threats = this.currentRiskProject.threats.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: Utils.getThreatLabelWithCategory(item),
          value: item.id
        });
      });
    },
    getLevelOfThreats: function getLevelOfThreats() {
      this.levelOfThreats = this.periodSelected.threatLevels.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: item.title,
          value: item.id
        });
      });
    },
    getVulnerabilities: function getVulnerabilities() {
      this.vulnerabilities = this.currentRiskProject.vulnerabilities.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          label: item.title,
          value: item.id
        });
      });
    },
    getProbabilities: function getProbabilities() {
      if (this.periodSelected.probabilityRows) {
        this.probabilities = this.periodSelected.probabilityRows.map(function (item) {
          return {
            id: item.index,
            label: item.label,
            value: item.index
          };
        });
      }
    },
    getAssets: function getAssets() {
      var _this3 = this;
      var promise;
      if (!this.currentUser.iaAccess) {
        promise = getInformationAssetsSystems();
      } else {
        promise = getAllByType(AssetsTypes.SYSTEMS, '?data[]=questionnaire_associations&data[]=relations');
      }
      return promise.then(function (_ref2) {
        var list = _ref2.list;
        _this3.systems = _this3.getSystemsListWithChildrenFlat(list);
      });
    },
    getSystemsListWithChildrenFlat: function getSystemsListWithChildrenFlat(items) {
      return items.reduce(function (list, item) {
        var _item$childrenObjects;
        list.push(item);
        if ((_item$childrenObjects = item.childrenObjects) !== null && _item$childrenObjects !== void 0 && _item$childrenObjects.length) {
          list.push.apply(list, _toConsumableArray(item.childrenObjects));
        }
        return list;
      }, []);
    },
    getRiskTypes: function getRiskTypes() {
      var _this4 = this;
      return RiskApi.getRiskTypes().then(function (_ref3) {
        var list = _ref3.list;
        return _this4.riskTypes = list;
      });
    }
  }
};