import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_control_title = _resolveComponent("control-title");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.testControls, function (control) {
    return _openBlock(), _createBlock(_component_control_title, {
      key: control.id,
      control: control
    }, null, 8 /* PROPS */, ["control"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}