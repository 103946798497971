import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "mx-0.5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("li", _hoisted_1, [_createVNode(_component_router_link, {
    to: $props.link,
    class: _normalizeClass({
      disabled: $props.disabled
    })
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["to", "class"])]);
}