function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { i18n } from '@/i18n';
export function catch403(router, routeName) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  router.push({
    name: routeName,
    params: _objectSpread({}, params),
    query: {
      detailLoadedError: true
    }
  });
}
function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}
export function check403AccessError(route, entityName) {
  var _route$query;
  var isDetailLoadedError = route === null || route === void 0 || (_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.detailLoadedError;
  if (isDetailLoadedError) {
    return "".concat(i18n.t("".concat(capitalize(entityName), " not found")), " / ").concat(i18n.t("You do not have permission to view this ".concat(entityName)));
  }
  return null;
}
export function prepareNotifyErrors(errors, defaultErrorMsg, $trans) {
  var notifyData = {
    type: 'error'
  };
  if (errors.length) {
    var errorHtml = '';
    notifyData.message = $trans(defaultErrorMsg);
    errors.map(function (error) {
      if (typeof error === 'string') {
        errorHtml += "<li>".concat($trans(error), "</li>\n");
      } else if (_typeof(error) === 'object') {
        errorHtml += "<li>".concat($trans(error.text), " ").concat(error.value, "</li>\n");
      }
    });
    notifyData.details = "<ul>".concat(errorHtml, "</ul>");
  }
  return notifyData;
}
export function getErrorMessage(error) {
  //lots of errors, but they all have different structure!
  if (error.response) {
    var resp = error.response;
    if (resp.error) {
      return resp.error + ' ' + (resp.message || '');
    }
    if (resp.data && resp.data.error) {
      return resp.data.error;
    }
    return resp;
  }
  if (error.error) {
    return error.error;
  }
  if (error.fullError) {
    if (error.fullError.response) {
      if (error.fullError.response.error) {
        return error.fullError.response.error;
      }
      return error.fullError.response;
    }
    return error.fullError;
  }
  return error;
}