import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "text-right"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "flex justify-between"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "ml-2"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  class: "ml-2"
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  class: "ml-2 text-red-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_gap_schema_article_modal = _resolveComponent("gap-schema-article-modal");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", {
    class: "inline-flex items-center italic font-semibold cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.toggleArticleModal(null);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-2 mr-2"
  }), _createElementVNode("span", null, _toDisplayString($data.translate.addArticle), 1 /* TEXT */)])]), _createVNode(_component_static_table, {
    columns: $options.columns,
    "dataset-table": $options.formattedData,
    class: "main-table"
  }, _createSlots({
    options: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "flex w-8/25 cursor-pointer",
        onClick: function onClick($event) {
          return $options.toggleArticleModal(slotData.id);
        }
      }, [_createVNode(_component_feather_icon, {
        icon: "edit"
      }), _createElementVNode("span", _hoisted_5, _toDisplayString($data.translate.editArticle), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_4), _createElementVNode("div", {
        class: "flex w-8/25 cursor-pointer",
        onClick: function onClick($event) {
          return $options.updateVisibility(slotData.id, {
            hidden: !slotData.hidden ? 1 : 0
          });
        }
      }, [_createVNode(_component_feather_icon, {
        icon: slotData.hidden ? 'eye' : 'eye-off'
      }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("span", _hoisted_7, _toDisplayString(slotData.hidden ? $data.translate.showGap : $data.translate.hideGap), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_6), slotData.canBeDeleted ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "flex w-8/25 cursor-pointer",
        onClick: function onClick($event) {
          return $options.onDeleteArticle(slotData.id);
        }
      }, [_createVNode(_component_feather_icon, {
        icon: "x",
        class: "text-red-50"
      }), _createElementVNode("span", _hoisted_9, _toDisplayString($data.translate.deleteArticle), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_8)) : _createCommentVNode("v-if", true)])];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.listOfLineThroughSlots, function (slotName) {
    return {
      name: slotName,
      fn: _withCtx(function (_ref2) {
        var slotData = _ref2.slotData;
        return [_createElementVNode("span", {
          class: _normalizeClass({
            'line-through': slotData.hidden
          }),
          innerHTML: slotData.text
        }, null, 10 /* CLASS, PROPS */, _hoisted_2)];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "dataset-table"]), $data.showArticleModal ? (_openBlock(), _createBlock(_component_gap_schema_article_modal, {
    key: 0,
    article: $data.selectedArticle,
    domains: $data.domains,
    "gap-schema-id": $props.gapSchemaId,
    onToggleModal: $options.toggleArticleModal,
    onOnSubmit: $options.onSubmitArticleModal,
    onCreateOption: $options.onCreateDomain
  }, null, 8 /* PROPS */, ["article", "domains", "gap-schema-id", "onToggleModal", "onOnSubmit", "onCreateOption"])) : _createCommentVNode("v-if", true)]);
}