import ButtonsRow from '@/components/Molecules/ButtonsRow';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  components: {
    ButtonsRow: ButtonsRow,
    FeatherIcon: FeatherIcon
  },
  computed: {
    buttonsRowData: function buttonsRowData() {
      return [{
        text: 'Create',
        type: 'save',
        disabled: true,
        slotName: 'check'
      }, {
        text: 'Dismiss',
        type: 'util',
        disabled: false
      }];
    }
  }
};