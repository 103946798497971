function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { mapToCommaList } from '@/utils/format/mapTo';
import { getFieldsFromObjectForPreview } from '@/utils/preview';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
import { sortAlphabetic } from '@/utils/sort';
import { destroy } from '@/api/compliance/informationAssets';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import StaticTable from '@/components/Molecules/StaticTable';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import InfoToolTip from '@/components/Molecules/InfoToolTip';
import * as Utils from '@/utils/Utils';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import { clickOutside } from '@/utils/directives/clickOutside';
import { InfoToolTipMixin } from '@/mixins/InfoToolTipMixin';
import { InformationAssetsDeleteMixin } from '@/components/Pages/InformationAssets/InformationAssetsDeleteMixin';
export default {
  name: 'InformationAssetsSimpleView',
  directives: {
    clickOutside: clickOutside
  },
  components: {
    FeatherIcon: FeatherIcon,
    StaticTable: StaticTable,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    InfoToolTip: InfoToolTip
  },
  mixins: [InformationAssetsDeleteMixin, InfoToolTipMixin],
  props: {
    typeId: {
      type: [Number, String],
      required: false,
      default: null,
      note: 'Id of information asset type'
    },
    informationAssets: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of information assets'
    },
    users: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of all users'
    },
    activityType: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      },
      note: 'current Activity type, needed for custom IA'
    }
  },
  emits: ['refreshData'],
  data: function data() {
    return {
      hoverTitles: {
        actions: 'delete'
      },
      deleteIA: null,
      updated: false,
      translate: getTranslate['InformationAssetsSimpleView']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    informationAssetsFiltered: function informationAssetsFiltered() {
      return this.informationAssets;
    },
    systemsEnabled: function systemsEnabled() {
      return this.getSettingValue('feature.enable_systems') == undefined || this.getSettingValue('feature.enable_systems');
    },
    columns: function columns() {
      var systemsColumn = [];
      switch (this.typeId) {
        case AssetsTypes.PROCESSORS:
          systemsColumn = this.systemsEnabled ? [this.translate.systems] : [];
          return ['', this.translate.id, this.translate.name, this.translate.description, this.translate.responsible, this.translate.completionDegreeIn].concat(_toConsumableArray(systemsColumn), [this.translate.processes, '']);
        case AssetsTypes.SYSTEMS:
          return ['', this.translate.id, this.translate.name, this.translate.responsible, this.translate.completionDegreeIn, this.translate.processors, this.translate.processes, ''];
        case AssetsTypes.CONTROLLERS:
          return ['', this.translate.id, this.translate.name, this.translate.responsible, this.translate.processes, ''];
        default:
          return ['', this.translate.name, this.translate.responsible, this.translate.processes, ''];
      }
    },
    dataset: function dataset() {
      switch (this.typeId) {
        case AssetsTypes.CONTROLLERS:
          return this.getControllersData();
        case AssetsTypes.PROCESSORS:
          return this.getProcessorsData();
        case AssetsTypes.SYSTEMS:
          return this.getSystemsData();
        default:
          return this.getInformationAssetsData();
      }
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightByObjectTypeAndNumber'])), {}, {
    getControllersData: function getControllersData() {
      var _this = this;
      return this.informationAssetsFiltered.map(function (item, index) {
        var trafficLight = _this.getTrafficLightByObjectTypeAndNumber(item.objType, item.trafficLight, true);
        return {
          trafficlight: {
            color: trafficLight.color
          },
          id: item.uniqueId,
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.name
          },
          responsible: _this.getResponsible(item.responsibleUserIds),
          processes: {
            count: item.complianceNodeCount || 0,
            nodes: [],
            index: index
          },
          actions: {
            objType: _this.typeId,
            id: item.id,
            removable: !item.complianceNodeCount && item.currentUserRights.canDelete
          }
        };
      });
    },
    getProcessorsData: function getProcessorsData() {
      var _this2 = this;
      var result = [];
      this.informationAssetsFiltered.forEach(function (item, index) {
        var _item$questionnaires;
        if (item.parents.length > 0) {
          return false;
        }
        var trafficLight = _this2.getTrafficLightByObjectTypeAndNumber(item.objType, item.trafficLight, true);
        var item_data = {
          trafficlight: {
            color: trafficLight.color
          },
          id: item.uniqueId,
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.name
          },
          description: Utils.truncate(item.description, 200) || '',
          responsible: _this2.getResponsible(item.responsibleUserIds),
          completionDegree: (_item$questionnaires = item.questionnaires) !== null && _item$questionnaires !== void 0 && _item$questionnaires[0] ? item.questionnaires[0].questionnaireAnsweredPercentage + ' %' : '-',
          systems: item.systems ? item.systems.length : 0,
          processes: {
            count: item.complianceNodeCount || 0,
            nodes: [],
            index: index
          },
          actions: {
            objType: _this2.typeId,
            id: item.id,
            removable: !item.complianceNodeCount && item.currentUserRights.canDelete
          },
          children: []
        };
        if (!_this2.systemsEnabled) {
          delete item_data.systems;
        }
        if (item.childrenObjects && item.childrenObjects.length > 0) {
          var childrenList = sortAlphabetic(item.childrenObjects, 'name');
          childrenList.forEach(function (child, childIndex) {
            var trafficLight = _this2.getTrafficLightByObjectTypeAndNumber(child.objType, child.trafficLight, true);
            var childItem = {
              trafficlight: {
                color: trafficLight.color
              },
              id: child.uniqueId,
              name: {
                data: getFieldsFromObjectForPreview(child),
                name: child.name
              },
              description: Utils.truncate(child.description, 200) || '',
              responsible: _this2.getResponsible(child.responsibleUserIds),
              completionDegree: '-',
              systems: child.systems ? child.systems.length : 0,
              processes: {
                count: item.complianceNodeCount || 0,
                nodes: [],
                index: "".concat(index, "_").concat(childIndex)
              },
              actions: {
                objType: _this2.typeId,
                id: child.id,
                removable: !child.complianceNodeCount && child.currentUserRights.canDelete
              }
            };
            if (!_this2.systemsEnabled) {
              delete childItem.systems;
            }
            item_data.children.push(childItem);
          });
        }
        if (item_data.children.length === 0) {
          delete item_data.children;
        }
        result.push(item_data);
      });
      return result;
    },
    getSystemsData: function getSystemsData() {
      var _this3 = this;
      var result = [];
      this.informationAssetsFiltered.forEach(function (item, index) {
        var _item$questionnaires2, _item$processors;
        if (item.parentId !== null) {
          if (_this3.informationAssetsFiltered.find(function (system) {
            return system.id === item.parentId;
          })) {
            return false;
          }
        }
        var trafficLight = _this3.getTrafficLightByObjectTypeAndNumber(item.objType, item.trafficLight, true);
        var itemData = {
          trafficlight: {
            color: trafficLight.color
          },
          id: item.uniqueId,
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.name
          },
          responsible: _this3.getResponsible(item.responsibleUserIds),
          completionDegree: (_item$questionnaires2 = item.questionnaires) !== null && _item$questionnaires2 !== void 0 && _item$questionnaires2[0] ? item.questionnaires[0].questionnaireAnsweredPercentage + ' %' : '-',
          processors: ((_item$processors = item.processors) === null || _item$processors === void 0 ? void 0 : _item$processors.length) || 0,
          processes: {
            count: item.complianceNodeCount || 0,
            nodes: item.complianceNodes,
            index: index
          },
          actions: {
            objType: _this3.typeId,
            id: item.id,
            removable: !((item.complianceNodeCount > 0 || item.children && item.children.length > 0) && item.currentUserRights.canDelete)
          },
          children: []
        };
        if (item.childrenObjects && item.childrenObjects.length > 0) {
          var childrenList = sortAlphabetic(item.childrenObjects, 'name');
          childrenList.forEach(function (child, childIndex) {
            var trafficLight = _this3.getTrafficLightByObjectTypeAndNumber(child.objType, child.trafficLight, true);
            itemData.children.push({
              trafficlight: {
                color: trafficLight.color
              },
              id: child.uniqueId,
              name: {
                data: getFieldsFromObjectForPreview(child),
                name: child.name
              },
              responsible: _this3.getResponsible(child.responsibleUserIds),
              completionDegree: '-',
              processors: child.processors.length,
              processes: {
                count: child.complianceNodeCount || 0,
                nodes: child.complianceNodes,
                index: "".concat(index, "_").concat(childIndex)
              },
              actions: {
                objType: _this3.typeId,
                id: child.id,
                removable: !child.complianceNodeCount && child.currentUserRights.canDelete
              }
            });
          });
        }
        if (itemData.children.length === 0) {
          delete itemData.children;
        }
        result.push(itemData);
      });
      return result;
    },
    getInformationAssetsData: function getInformationAssetsData() {
      var _this4 = this;
      return this.informationAssetsFiltered.map(function (item, index) {
        var trafficLight = _this4.getTrafficLightByObjectTypeAndNumber(item.objType, item.trafficLight, true);
        return {
          trafficlight: {
            color: trafficLight.color
          },
          name: {
            data: getFieldsFromObjectForPreview(item),
            name: item.title
          },
          responsible: _this4.getResponsible(item.responsibleUserIds),
          processes: {
            count: item.complianceNodeCount || 0,
            nodes: item.complianceNodes,
            index: index
          },
          actions: {
            objType: 'informationAssets',
            id: item.id,
            removable: !item.complianceNodeCount && item.currentUserRights.canDelete
          }
        };
      });
    },
    deleteItem: function deleteItem(item) {
      var _this$activityType,
        _this5 = this;
      this.deleteIA = _objectSpread(_objectSpread({}, item), {}, {
        deleteLabel: ((_this$activityType = this.activityType) === null || _this$activityType === void 0 ? void 0 : _this$activityType.label) || ''
      });
      this.$confirm(this.deleteHeader, this.deleteBody, function (res) {
        return res ? _this5.handleDelete() : _this5.deleteIA = null;
      }, {
        buttonOkText: this.translate.delete,
        buttonDismissText: this.translate.cancel
      });
    },
    handleDelete: function handleDelete() {
      var _this6 = this;
      if (!this.deleteIA) return;
      return destroy(this.deleteIA.objType, this.deleteIA.id).then(function () {
        _this6.deleteIA = null;
        _this6.$emit('refreshData');
      });
    },
    getResponsible: function getResponsible(usersIds) {
      if (!usersIds) return [];
      return mapToCommaList(this.users.filter(function (user) {
        return usersIds.indexOf(user.id) !== -1;
      }), 'display_name');
    },
    dismissPreviewModal: function dismissPreviewModal() {
      if (this.updated) {
        this.$emit('refreshData');
        this.updated = false;
      }
    }
  })
};