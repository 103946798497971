function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import MainWell from '@/components/Atoms/Containers/MainWell';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import Notification from '@/components/Molecules/Notification';
import { cacheItem } from '@/routes/storeCache';
import { sendEmail } from '@/api/rismaadmin/email';
import { patchUtilSettings } from '@/api/risma/settings';
import { isEmailValid } from '@/utils/validations';
export default {
  name: 'EmailSetup',
  components: {
    MainWell: MainWell,
    RismaTitle: RismaTitle,
    RismaButton: RismaButton,
    Notification: Notification,
    RismaToggle: RismaToggle
  },
  data: function data() {
    return {
      email: '',
      error: '',
      recipientEmail: '',
      timer: 0,
      loading: false,
      translate: getTranslate['EmailSetup']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    smtp_host: function smtp_host() {
      return this.getSettingValue('risma.smtp.host');
    },
    smtp_port: function smtp_port() {
      return this.getSettingValue('risma.smtp.port');
    },
    smtp_user: function smtp_user() {
      return this.getSettingValue('risma.smtp.user');
    },
    smtp_encryption: function smtp_encryption() {
      return this.getSettingValue('risma.smtp.encryption');
    },
    smtp_disable_delivery: function smtp_disable_delivery() {
      return this.getSettingValue('risma.smtp.disable_delivery');
    },
    overrideRecipientEmail: function overrideRecipientEmail() {
      return this.getSettingValue('risma.override_recipient_email');
    }
  }),
  mounted: function mounted() {
    this.recipientEmail = this.overrideRecipientEmail;
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    updateSettingsValue: function updateSettingsValue(value) {
      return this.patchSetting(_defineProperty({}, 'risma.smtp.disable_delivery', value ? 0 : 1));
    },
    patchSetting: function patchSetting(settingsData) {
      var _this = this;
      return patchUtilSettings(settingsData).then(function () {
        return cacheItem('settings', function () {
          return _this.loadSettings();
        }, 180);
      });
    },
    sendTestEmail: function sendTestEmail() {
      var _this2 = this;
      if (!isEmailValid(this.email)) {
        this.error = this.translate.invalidEmailAddress;
        return;
      }
      sendEmail(this.email).then(function (response) {
        _this2.$notify({
          title: "Server response: ".concat(response),
          type: 'success'
        });
      }).catch(function (response) {
        _this2.$notify({
          title: "Server failed with this message: ".concat(response.response.error),
          type: 'error'
        });
      }).finally(function () {
        return _this2.error = '';
      });
    },
    updateEmail: function updateEmail(a) {
      var _this3 = this;
      var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.$debounceTimeout / 2;
      clearTimeout(this.timer);
      if (this.recipientEmail === this.overrideRecipientEmail) return;
      this.timer = setTimeout(function () {
        _this3.patchSetting(_defineProperty({}, 'risma.override_recipient_email', _this3.recipientEmail));
      }, timeout);
    }
  })
};