export var DateFormats = {
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
  DATE_TIME_FORMAT_FULL: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_Z_FORMAT: 'YYYY-MM-DD HH:mmZ',
  DATE_LONG_FORMAT: 'DD MMM YY',
  DATE_SHORT_FORMAT: 'MMM YY',
  DATE_DAY_FORMAT: 'MMM DD',
  DATE_MONTH_YEAR_FORMAT: "MMM 'YY",
  DATE_YEAR_FORMAT: 'YYYY',
  DATE_TIME_FORMAT_LAST: 'YYYY-MM-DD 23:59Z',
  DATE_TIME_FORMAT_FULL_LAST: 'YYYY-MM-DD 23:59:00',
  DATE_FORMAT_DK: 'DD.MM.YYYY',
  DATE_FORMAT_M: 'M'
};