import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "p-10 mx-auto"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  var _component_router_link = _resolveComponent("router-link");
  var _component_notification_pop_up = _resolveComponent("notification-pop-up");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_description_modal = _resolveComponent("description-modal");
  var _component_alert = _resolveComponent("alert");
  var _component_header_nav_bar = _resolveComponent("header-nav-bar");
  var _component_ai_assistant = _resolveComponent("ai-assistant");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.userIsLoggedIn ? $data.topPadding : ''], "-mt-px"])
  }, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_component_router_view, {
    key: "".concat($options.keyField, "_").concat($data.routerViewIndex),
    onUpdateHeader: _cache[0] || (_cache[0] = function ($event) {
      return $data.navBarIndex++;
    })
  }))]), _ctx.notificationPopUpVisible ? (_openBlock(), _createBlock(_component_notification_pop_up, {
    key: 0,
    type: _ctx.notificationPopUpData.type,
    "show-timeout": _ctx.notificationPopUpData.showTimeout,
    focus: _ctx.notificationPopUpData.focus,
    onDismiss: _ctx.dismissNotificationPopUp
  }, {
    default: _withCtx(function () {
      return [_ctx.notificationPopUpData.title ? (_openBlock(), _createElementBlock("div", _hoisted_2, [Array.isArray(_ctx.notificationPopUpData.title) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationPopUpData.title, function (title, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx
        }, _toDisplayString(title), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.notificationPopUpData.title), 1 /* TEXT */))])) : _createCommentVNode("v-if", true), _ctx.notificationPopUpData.url ? (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: _ctx.notificationPopUpData.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.notificationPopUpData.urlTitle || _ctx.notificationPopUpData.url), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])) : _ctx.notificationPopUpData.activity ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "hover:underline cursor-pointer",
        tabindex: "0",
        onKeyup: _cache[1] || (_cache[1] = _withKeys(function () {
          return $options.showPreview && $options.showPreview.apply($options, arguments);
        }, ["enter"])),
        onClick: _cache[2] || (_cache[2] = function () {
          return $options.showPreview && $options.showPreview.apply($options, arguments);
        })
      }, _toDisplayString(_ctx.notificationPopUpData.urlTitle || _ctx.notificationPopUpData.activity.title), 33 /* TEXT, NEED_HYDRATION */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type", "show-timeout", "focus", "onDismiss"])) : _createCommentVNode("v-if", true), $data.previewActivity ? (_openBlock(), _createBlock(_component_preview_modal_link, {
    key: 1,
    ref: "preview",
    activity: $data.previewActivity,
    "selected-route": $data.previewSelectedRoute,
    onUpdated: _cache[3] || (_cache[3] = function ($event) {
      return $data.updatedFromPreview = true;
    }),
    onDeleteItem: _cache[4] || (_cache[4] = function ($event) {
      return $data.updatedFromPreview = true;
    }),
    onDismissModal: $options.onPreviewDismiss
  }, {
    default: _withCtx(function () {
      return _cache[9] || (_cache[9] = [_createTextVNode(_toDisplayString(''))]);
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["activity", "selected-route", "onDismissModal"])) : _createCommentVNode("v-if", true), $options.updatesForPath ? (_openBlock(), _createBlock(_component_description_modal, {
    key: 2,
    header: $options.updatesForPath.header,
    body: $options.updatesForPath.body,
    "update-date": $options.updatesForPath.date || '',
    "show-more-field": $options.updatesForPath.showMore,
    "show-update-date": !!$options.updatesForPath.date,
    "key-field": $options.updatesForPath.path,
    "show-button": true,
    changes: $options.updatesForPath.changes
  }, null, 8 /* PROPS */, ["header", "body", "update-date", "show-more-field", "show-update-date", "key-field", "changes"])) : _createCommentVNode("v-if", true), _ctx.notificationVisible ? (_openBlock(), _createBlock(_component_alert, {
    key: 3,
    modelValue: $data.promptText,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.promptText = $event;
    }),
    header: _ctx.notificationHeader,
    "header-type": _ctx.options.headerType,
    body: _ctx.notificationBody,
    "alert-type": _ctx.notificationType,
    "button-ok-text": _ctx.options.buttonOkText,
    "button-dismiss-text": _ctx.options.buttonDismissText,
    onAccept: _cache[6] || (_cache[6] = function ($event) {
      return $options.onHandleAlert(true);
    }),
    onDismiss: _cache[7] || (_cache[7] = function ($event) {
      return $options.onHandleAlert(false);
    })
  }, null, 8 /* PROPS */, ["modelValue", "header", "header-type", "body", "alert-type", "button-ok-text", "button-dismiss-text"])) : _createCommentVNode("v-if", true), _ctx.userIsLoggedIn ? (_openBlock(), _createBlock(_component_header_nav_bar, {
    id: _ctx.currentUser.id,
    key: "key_".concat($data.navBarIndex),
    risma2enable: true,
    "user-menu-enabled": _ctx.userIsLoggedIn,
    onAdjustContentPadding: _cache[8] || (_cache[8] = function ($event) {
      return $data.topPadding = $event;
    })
  }, null, 8 /* PROPS */, ["id", "user-menu-enabled"])) : _createCommentVNode("v-if", true), _ctx.userIsLoggedIn && _ctx.aiAssistantEnabled && _ctx.aiTermsAccepted ? (_openBlock(), _createBlock(_component_ai_assistant, {
    key: 5
  })) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}