function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _excluded = ["activityTypeId", "activityType"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { filterArrayByListOfPropValues } from '@/utils/Utils';
var KPI_TYPES = {
  multiSelect: 'multi_select',
  singleSelect: 'single_select'
};
export default {
  name: 'KPIParameters',
  introduction: 'Component shows kpi parameters',
  description: 'This component is part of KPITable',
  components: {
    RismaSelect: RismaSelect,
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect
  },
  props: {
    metadata: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    values: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['update'],
  data: function data() {
    return {
      TYPES: KPI_TYPES,
      translate: {
        select: this.$trans('Select')
      }
    };
  },
  computed: {
    selectedOptions: function selectedOptions() {
      var data = {};
      for (var key in this.values) {
        var _this$metadata$parame, _this$metadata$parame2;
        if (((_this$metadata$parame = this.metadata.parameters[key]) === null || _this$metadata$parame === void 0 ? void 0 : _this$metadata$parame.type) === this.TYPES.multiSelect) {
          data[key] = this.getSelectedArray(this.metadata.parameters[key].options, this.values[key]);
        }
        if (((_this$metadata$parame2 = this.metadata.parameters[key]) === null || _this$metadata$parame2 === void 0 ? void 0 : _this$metadata$parame2.type) === this.TYPES.singleSelect) {
          data[key] = this.values[key];
        }
      }
      return data;
    }
  },
  methods: {
    handleSelect: function handleSelect(selectedValues, paramName) {
      var ids = selectedValues.map(function (item) {
        return +item.value;
      });
      this.updateData(ids, paramName);
    },
    getSelectedArray: function getSelectedArray(list, value) {
      if (!value) return [];
      if (Array.isArray(value)) {
        return filterArrayByListOfPropValues(list, value, 'value');
      }
      return filterArrayByListOfPropValues(list, [value], 'value');
    },
    updateData: function updateData(value, paramName) {
      var _this$values = this.values,
        activityTypeId = _this$values.activityTypeId,
        activityType = _this$values.activityType,
        rest = _objectWithoutProperties(_this$values, _excluded);
      this.$emit('update', _objectSpread(_objectSpread({}, rest), {}, _defineProperty({
        activityType: activityType || undefined,
        activityTypeId: activityTypeId || []
      }, paramName, value)));
    }
  }
};