import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import { compareDates } from '@/utils/compare';
import { DateFormats } from '@/constants/DateFormats';
import { calculateUTCDifference, formatCurrentDateTime } from '@/utils/date';
export default {
  name: 'ControlAddDeadlineModal',
  components: {
    Modal: Modal,
    DateTimePicker: DateTimePicker,
    FeatherIcon: FeatherIcon,
    Notification: Notification
  },
  props: {
    control: {
      type: Object,
      required: true,
      note: 'Main control data object.'
    },
    deadlines: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'accept', 'deadlinesChanged'],
  data: function data() {
    return {
      allDeadlines: [],
      selectedDeadline: '',
      dateTimeOptions: {
        displayFormat: DateFormats.DATE_TIME_FORMAT,
        format: DateFormats.DATE_TIME_FORMAT
      },
      addDeadlineError: '',
      translate: getTranslate['ControlAddDeadlineModal']()
    };
  },
  computed: {
    reviewDeadlineEnabled: function reviewDeadlineEnabled() {
      return this.control.userIds.review && this.control.userIds.review.length && !this.control.isMothercontrol;
    }
  },
  mounted: function mounted() {
    this.allDeadlines = this.deadlines.slice(0);
    this.selectedDeadline = formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT);
  },
  methods: {
    removeDeadline: function removeDeadline(idx) {
      if (this.allDeadlines.length > 1) {
        this.allDeadlines.splice(idx, 1);
      } else {
        this.allDeadlines = [];
      }
    },
    onAddDeadline: function onAddDeadline() {
      if (!this.validateDeadline(this.selectedDeadline)) return;
      this.allDeadlines.push({
        deadline: this.selectedDeadline
      });
      this.sortDeadlines();
    },
    validateDeadline: function validateDeadline(deadline) {
      this.addDeadlineError = '';
      var deadlineExist = this.allDeadlines.find(function (dl) {
        return calculateUTCDifference(dl.deadline, deadline) === 0;
      });
      if (calculateUTCDifference(deadline, undefined) < 0) {
        this.addDeadlineError = this.translate.youCantSelectDateFromThePast;
      }
      if (deadlineExist) {
        this.addDeadlineError = this.translate.youCantAddDatesWhichAlreadyExist;
      }
      return !this.addDeadlineError;
    },
    sortDeadlines: function sortDeadlines() {
      this.allDeadlines.sort(function (date1, date2) {
        return compareDates(date1.deadline, date2.deadline);
      });
    },
    onModalAccept: function onModalAccept(clickEvent) {
      clickEvent.stopPropagation();
      this.$emit('deadlinesChanged', this.allDeadlines);
      this.$emit('dismiss');
    },
    onModalDismiss: function onModalDismiss(_, clickEvent) {
      clickEvent.stopPropagation();
      this.$emit('dismiss');
    }
  }
};