function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import * as Utils from '@/utils/Utils';
import { sortByIndex } from '@/utils/sort';
export default {
  name: '',
  components: {
    RismaDatatable: RismaDatatable
  },
  mixins: [RismaDatatableDefaultMixin],
  data: function data() {
    return {
      columnsBig: {
        title: this.$trans('Title'),
        status: this.$trans('Status'),
        cpath: this.$trans('ID'),
        completedComments: this.$trans('Completion comments'),
        description: this.$trans('Description'),
        'mothercontrol.description': this.$trans('Description from Mother Control'),
        'mothercontrol.expectedEvidence': this.$trans('Expected evidence from Mother Control')
      },
      datasetBig: this.prepareDatasetBig(),
      columnsMeta: this.prepareColumnsMeta(),
      pagingOptions: [1, 2, 3, 5, 100],
      columnFiltersSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpath
      }],
      columnSorting: [{
        key: 'cpath',
        sortingFunc: this.sortingByCpathObject
      }],
      stateLocalRismaDatatable: 'controls_kitchensink_datatable',
      pageLength: 3,
      orderNew: [{
        index: 'cpath',
        dir: 'desc',
        sortingFunc: this.sortingByCpathObject
      }],
      excelFilename: 'Atoms',
      disabledFilterByColumns: ['description', 'completedComments'],
      decoupleFilterFields: [],
      filterByColumn: true
    };
  },
  mounted: function mounted() {
    window.$router = this.$router; //jquery access to router, didn`t find other way
    this.enhanceDataset(this.datasetBig, this.columnsBig);
  },
  methods: {
    sortingByCpath: function sortingByCpath(data) {
      return sortByIndex(data);
    },
    sortingByCpathObject: function sortingByCpathObject(data, key) {
      return data.sort(function (a, b) {
        var x = Utils.purifyItemField(a[key], true);
        var y = Utils.purifyItemField(b[key], true);
        if (parseInt(x) === parseInt(y)) {
          return x < y ? -1 : x > y ? 1 : 0;
        }
        return parseInt(x) - parseInt(y);
      });
    },
    prepareColumnsMeta: function prepareColumnsMeta() {
      return [].concat(_toConsumableArray(this.prepareVisibleColumns()), _toConsumableArray(this.prepareColumnWidths()));
    },
    prepareVisibleColumns: function prepareVisibleColumns() {
      return [{
        targets: ['mothercontrol.expectedEvidence', 'mothercontrol.description'],
        visible: false
      }];
    },
    prepareColumnWidths: function prepareColumnWidths() {
      return [{
        targets: ['completedComments', 'mothercontrol.expectedEvidence'],
        width: '315px'
      }, {
        targets: ['mothercontrol.description', 'description'],
        width: '400px'
      }];
    },
    prepareDatasetBig: function prepareDatasetBig() {
      return [{
        title: {
          fieldText: 'Debtor check',
          field: '<a href="/controls2/controls/3/description" onClick="if(event.ctrlKey === false && event.metaKey === false) {window.$router.push(\'/controls2/controls/3/description\'); return false;}" class="">Debtor check</a>'
        },
        status: {
          field: 'Completed'
        },
        cpath: {
          field: '3'
        },
        completedComments: {
          field: "I've fixed it"
        },
        description: {
          field: 'Look up key figures of top 100 customers to ensure their credit standing.\t'
        },
        'mothercontrol.description': {
          field: 'Mother control description'
        },
        'mothercontrol.expectedEvidence': {
          field: 'Expected evidence text here......'
        }
      }, {
        title: {
          fieldText: 'contract review Denmark',
          field: '<a href="/controls2/controls/6.A/description" onClick="if(event.ctrlKey === false && event.metaKey === false) {window.$router.push(\'/controls2/controls/6.A/description\'); return false;}" class="">contract review Denmark</a>'
        },
        status: {
          field: 'Pending review *'
        },
        cpath: {
          field: '6.A'
        },
        completedComments: {
          field: '-----'
        },
        description: {
          field: 'Look up key figures of top 100 customers to ensure their credit standing.\t'
        },
        'mothercontrol.description': {
          field: 'Mother control description 2222'
        },
        'mothercontrol.expectedEvidence': {
          field: '--------------'
        }
      }, {
        title: {
          fieldText: 'Datterkontrol 1',
          field: '<a href="/controls2/controls/7.A/description" onClick="if(event.ctrlKey === false && event.metaKey === false) {window.$router.push(\'/controls2/controls/7.A/description\'); return false;}" class="">Datterkontrol 1</a>'
        },
        status: {
          field: 'Pending'
        },
        cpath: {
          field: '7.A'
        },
        completedComments: {
          field: 'Test 123123'
        },
        description: {
          field: ''
        },
        'mothercontrol.description': {
          field: 'Mother control description 3333'
        },
        'mothercontrol.expectedEvidence': {
          field: '-------------- 33333333333 ROCK!'
        }
      }, {
        title: {
          fieldText: 'Datterkontrol 33',
          field: '<a href="/controls2/controls/33/description" onClick="if(event.ctrlKey === false && event.metaKey === false) {window.$router.push(\'/controls2/controls/12/description\'); return false;}" class="">Datterkontrol 33</a>'
        },
        status: {
          field: 'Completed'
        },
        cpath: {
          field: '12.A'
        },
        completedComments: {
          field: 'Test 55555555555555 7777777777777777777777777777777777777'
        },
        description: {
          field: '123123897a89e7130azbxcmzmnbadsogh16y31&*^&*(^#&*huasjdmnzxc'
        },
        'mothercontrol.description': {
          field: ''
        },
        'mothercontrol.expectedEvidence': {
          field: null
        }
      }, {
        title: {
          fieldText: 'Datterkontrol 2',
          field: '<a href="/controls2/controls/2/description" onClick="if(event.ctrlKey === false && event.metaKey === false) {window.$router.push(\'/controls2/controls/12.A/description\'); return false;}" class="">Datterkontrol 2</a>'
        },
        status: {
          field: 'Pending review'
        },
        cpath: {
          field: '12'
        },
        completedComments: {
          field: 'Approved.'
        },
        description: {
          field: 'Mother control &#*(#&@*#'
        },
        'mothercontrol.description': {
          field: 'asdasdasdasd'
        },
        'mothercontrol.expectedEvidence': {
          field: null
        }
      }];
    }
  }
};