function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import * as DataAPI from '@/api/risma/data';
import { getSingleInitiative, updateSingleInitiative, deleteAction as _deleteAction } from '@/api/execution/initiative';
import DepWell from '@/components/Organisms/DepWell/DepWell';
import DelegationWell from '@/components/Organisms/General/coreWells/DelegationWell';
import Notification from '@/components/Molecules/Notification';
import ActionBreadcrumbs from '@/components/Molecules/Actions/ActionBreadcrumbs';
import ActivityTypeSelector from '@/components/Molecules/ActivityTypeSelector';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { DelegationWellMixin } from '@/mixins/DelegationWellMixin';
import { saveStatus } from '@/constants/SaveStatuses';
import { TabTypes, DepRelationsTabDelegation, DepRelationsTabDetails } from '@/constants/Tabs';
import { createTabsList } from '@/utils/base/tabs';
import { getLinksLabel } from '@/utils/links';
import { MODULES } from '@/constants/modules';
import { toDateFormat } from '@/utils/date';
import { updateFileLocally } from '@/utils/Attachments';
import { catch403 } from '@/utils/handleAPIErrors';
export default {
  name: 'ActionEntry',
  description: 'Common page for show action',
  token: '<action-entry />',
  components: {
    DepWell: DepWell,
    DelegationWell: DelegationWell,
    Notification: Notification,
    ActionBreadcrumbs: ActionBreadcrumbs,
    ActivityTypeSelector: ActivityTypeSelector,
    RismaTitle: RismaTitle
  },
  mixins: [DelegationWellMixin],
  inject: {
    isPreview: {
      default: false
    }
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  emits: ['changeTab', 'deleteItem', 'updated', 'updateFromPreviewMode', 'accessError'],
  data: function data() {
    return {
      // Header part
      initiative: {},
      dirtyInitiative: {},
      // Other fields
      isLoaded: false,
      notificationType: '',
      notificationMessage: '',
      notificationDetails: '',
      isNotificationShow: false,
      initiatives: [],
      saveStatus: saveStatus.SAVED,
      depRelationsTabs: [DepRelationsTabDelegation(), DepRelationsTabDetails()],
      translate: getTranslate['ActionEntry']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useUserStore, ['currentUser'])), mapState(useTagsStore, ['tags'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useActivityTypesStore, {
    initiativeTypes: 'executionActivityTypes'
  })), {}, {
    isUserLocked: function isUserLocked() {
      return this.currentUser.level_e_locked === 1;
    },
    inputsDisabled: function inputsDisabled() {
      return !!(this.initiative.closed || this.isUserLocked);
    },
    initiativeFormatted: function initiativeFormatted() {
      var result = _objectSpread(_objectSpread({}, this.dirtyInitiative), {}, {
        tags: this.initiative.tags.map(function (tag) {
          return tag.id;
        }),
        target: this.initiative.target ? this.initiative.target : '',
        status: this.initiative.status ? this.initiative.status : '',
        comment: this.initiative.comment ? this.initiative.comment : '',
        customFieldValues: this.dirtyInitiative.customFieldValuesParsed
      });
      if (!result.customFieldValues) {
        delete result.customFieldValues;
      }
      return result;
    },
    baseActionLink: function baseActionLink() {
      return "/actions/i/-/".concat(this.slug, "/").concat(this.initiative.id);
    },
    trafficLightSettings: function trafficLightSettings() {
      var settings = {
        value: this.initiative.trafficLight,
        lightType: MODULES.EXECUTION,
        field: 'trafficLight',
        readOnly: this.inputsDisabled
      };
      if (this.getSettingValue('risma.traffic_light_automatic')) settings.readOnly = true;
      return settings;
    },
    tabs: function tabs() {
      var tabList = [TabTypes.DESCRIPTION, TabTypes.LINKS, TabTypes.CHANGELOG];
      return createTabsList(tabList, this.baseActionLink, getLinksLabel(this.initiative));
    },
    attachments: function attachments() {
      return this.initiative.attachments;
    },
    depHeaderProps: function depHeaderProps() {
      if (!this.isLoaded) {
        return {};
      }
      return {
        id: this.initiative.ipath,
        title: this.initiative.title,
        editableId: false,
        editableTitle: !this.inputsDisabled,
        filesList: this.initiative.attachments,
        trafficLightSettings: this.trafficLightSettings,
        moduleId: this.initiative.id,
        module: MODULES.EXECUTION,
        titleField: 'title',
        users: this.users,
        tabs: this.tabs,
        saveStatus: this.saveStatus,
        editableAttachments: !this.inputsDisabled,
        showCheckmark: !!this.initiative.closed
      };
    },
    delegationData: function delegationData() {
      return {
        objType: this.initiative.objType,
        tagIds: this.initiative.tagIds || [],
        dependingOn: this.initiative.dependingOn || [],
        precursorTo: this.initiative.precursorTo || [],
        initiativeType: this.initiative.activityType ? [this.initiative.activityType] : [],
        organisationIds: this.initiative.organisationIds || [],
        responsibleUserIds: (this.initiative.userIds ? this.initiative.userIds.responsible : []) || [],
        notificationsUserIds: (this.initiative.userIds ? this.initiative.userIds.notifications : []) || [],
        dailyTeamUsersIds: (this.initiative.dailyTeamIds ? this.initiative.dailyTeamIds.users : []) || [],
        dailyTeamOrganisationsIds: (this.initiative.dailyTeamIds ? this.initiative.dailyTeamIds.organisations : []) || [],
        module: MODULES.EXECUTION,
        solutionIds: this.initiative.solutionIds || []
      };
    },
    isStartAfterDeadline: function isStartAfterDeadline() {
      return this.initiative.start > this.initiative.deadline;
    },
    allInitiativesExceptCurrent: function allInitiativesExceptCurrent() {
      var _this = this;
      return this.initiatives.filter(function (initiative) {
        return +initiative.id !== +_this.initiative.id;
      });
    },
    closedMessage: function closedMessage() {
      return this.initiative.archived ? this.translate.actionIsClosedAndArchived : this.translate.actionIsClosedPleaseReopenBeforeEditing;
    }
  }),
  mounted: function mounted() {
    var _this2 = this;
    this.getData().then(function () {
      _this2.isLoaded = true;
    });
  },
  methods: {
    deleteAction: function deleteAction() {
      var _this3 = this;
      _deleteAction(this.slug).then(function () {
        if (_this3.isPreview) {
          _this3.$emit('deleteItem', _this3.initiative);
        } else {
          _this3.$router.replace('/actions/actions');
        }
      });
    },
    getData: function getData() {
      var _this4 = this;
      return Promise.all([this.getInitiative(), this.getAllInitiatives()]).catch(function () {
        if (_this4.isPreview) {
          _this4.$emit('accessError');
          return;
        }
        catch403(_this4.$router, 'ActionsMy');
      });
    },
    getAllInitiatives: function getAllInitiatives() {
      var _this5 = this;
      return DataAPI.getInitiatives().then(function (_ref) {
        var list = _ref.list;
        _this5.initiatives = list.map(function (initiative) {
          return Object.assign(initiative, {
            label: "".concat(initiative.ipath, " ").concat(initiative.title)
          });
        });
      });
    },
    getInitiative: function getInitiative() {
      var _this6 = this;
      return getSingleInitiative(this.slug).then(function (response) {
        return _this6.initiative = response;
      });
    },
    handleShowNotify: function handleShowNotify(_ref2) {
      var type = _ref2.type,
        message = _ref2.message,
        details = _ref2.details;
      this.notificationType = type;
      this.notificationMessage = message;
      this.notificationDetails = details;
      this.isNotificationShow = true;
      this.$scrollTo(document.body, 100);
      setTimeout(this.handleDismissNotification, 5000);
    },
    handleDismissNotification: function handleDismissNotification() {
      this.notificationMessage = '';
      this.notificationDetails = '';
      this.isNotificationShow = false;
    },
    onActionArchive: function onActionArchive() {
      this.activityPropertyChanged({
        property: 'archived',
        value: 1
      });
    },
    onStatusChanged: function onStatusChanged(status) {
      this.activityPropertyChanged({
        property: 'closed',
        value: status
      });
    },
    activityPropertyChanged: function activityPropertyChanged(arg) {
      var property = arg.property,
        value = arg.value;
      if (property === undefined) {
        this.dirtyInitiative = Object.assign(this.dirtyInitiative, arg);
      } else if (property === 'responsibleUserIds') {
        this.dirtyInitiative.userIds = _objectSpread(_objectSpread({}, this.initiative.userIds), {}, {
          responsible: value
        });
      } else if (property === 'notificationsUserIds') {
        this.dirtyInitiative.userIds = _objectSpread(_objectSpread({}, this.initiative.userIds), {}, {
          notifications: value
        });
      } else if (property === 'dailyTeamUsersIds') {
        this.dirtyInitiative.dailyTeamIds = _objectSpread(_objectSpread({}, this.initiative.dailyTeamIds), {}, {
          users: value
        });
      } else if (property === 'dailyTeamOrganisationsIds') {
        this.dirtyInitiative.dailyTeamIds = _objectSpread(_objectSpread({}, this.initiative.dailyTeamIds), {}, {
          organisations: value
        });
      } else if (property === 'tagIds') {
        var tagsSelected = this.tags.filter(function (tag) {
          return value.indexOf(tag.id) !== -1;
        });
        this.dirtyInitiative.tags = tagsSelected;
        this.dirtyInitiative.tagIds = tagsSelected.map(function (tag) {
          return tag.id;
        });
      } else if (property === 'initiativeType') {
        this.dirtyInitiative.activityType = value[0] ? value[0] : -1;
      } else if (property === 'deadline') {
        this.dirtyInitiative[property] = toDateFormat(value);
      } else if (property === 'completionPercentage') {
        this.dirtyInitiative[property] = value || 0;
      } else {
        this.dirtyInitiative[property] = value;
      }
      this.initiative = Object.assign({}, this.initiative, this.dirtyInitiative);
      this.setUpdateTimer();
    },
    saveChangesImmediately: function saveChangesImmediately(updatedProperty) {
      this.activityPropertyChanged(updatedProperty);
      this.saveStatusClicked();
    },
    setUpdateTimer: function setUpdateTimer() {
      this.saveStatus = saveStatus.NOT_SAVED;
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(this.saveUpdates, this.$autoSaveDebounceTime);
    },
    saveStatusClicked: function saveStatusClicked() {
      if (this.saveStatus !== saveStatus.SAVED) {
        clearTimeout(this.updateTimer);
        this.saveUpdates();
      }
    },
    saveUpdates: function saveUpdates() {
      var _this7 = this;
      if (!this.checkValidPeriod()) return;
      this.saveStatus = saveStatus.SAVING;
      var timerId = this.updateTimer;
      updateSingleInitiative(this.initiative.slug, this.initiativeFormatted).then(function (response) {
        // update the initiative if there were no new changes during the save time
        if (timerId === _this7.updateTimer) {
          _this7.initiative = response;
        }
        if (_this7.slug !== _this7.initiative.slug && !_this7.isPreview) {
          _this7.$router.replace("/actions/i/-/".concat(_this7.initiative.slug, "/").concat(_this7.initiative.id, "/description"));
        }
        _this7.saveStatus = saveStatus.SAVED;
        _this7.dirtyInitiative = {};
        _this7.$emit('updated', _objectSpread({}, _this7.initiative));
        _this7.$emit('updateFromPreviewMode');
      }).catch(function () {
        _this7.saveStatus = saveStatus.NOT_SAVED;
      });
    },
    checkValidPeriod: function checkValidPeriod() {
      if (this.initiative.start && !this.initiative.deadline) {
        this.handleShowNotify({
          type: 'warning',
          message: this.translate.pleaseSelectDeadlineFirst
        });
        return;
      }
      if (this.isStartAfterDeadline) {
        this.handleShowNotify({
          type: 'warning',
          message: this.translate.startDateCannotBeAfterDeadline
        });
        return;
      }
      return true;
    },
    onFileUpdate: function onFileUpdate(fileId) {
      var _this$initiative;
      updateFileLocally(fileId, (_this$initiative = this.initiative) === null || _this$initiative === void 0 ? void 0 : _this$initiative.attachments);
      this.saveStatus = saveStatus.SAVED;
    },
    activityTypeChanged: function activityTypeChanged(value) {
      this.activityPropertyChanged({
        property: 'activityType',
        value: value
      });
    },
    updatingAttachments: function updatingAttachments() {
      this.saveStatus = saveStatus.SAVING;
    },
    getActivityData: function getActivityData() {
      var _this8 = this;
      this.getInitiative().then(function () {
        return _this8.saveStatus = saveStatus.SAVED;
      });
    }
  }
};