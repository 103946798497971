import TagItem from '@/components/Atoms/Containers/TagItem';
export default {
  name: 'DataTableFilterTags',
  token: '<data-table-filter-tags :items="items"></data-table-filter-tags>',
  components: {
    TagItem: TagItem
  },
  emits: ['removeFilter'],
  data: function data() {
    return {
      items: [],
      translate: {
        columnsWithAppliedFilters: this.$trans('Columns with applied filters')
      }
    };
  },
  computed: {
    filterTagsLabel: function filterTagsLabel() {
      return this.translate.columnsWithAppliedFilters;
    }
  },
  methods: {
    addFilterTag: function addFilterTag(filterItem) {
      var isFound = this.items.filter(function (item) {
        return item.index == filterItem.index;
      });
      if (!isFound.length) {
        this.items.push(filterItem);
      }
    },
    removeFilterTag: function removeFilterTag(filterItem) {
      var foundIndex = -1;
      this.items.map(function (item, index) {
        if (item.index == filterItem.index) {
          foundIndex = index;
        }
      });
      if (foundIndex !== -1) {
        this.items.splice(foundIndex, 1);
      }
    },
    resetAllTags: function resetAllTags() {
      this.items = [];
    },
    removeItem: function removeItem(item) {
      this.$emit('removeFilter', item);
      this.removeFilterTag(item);
    }
  }
};