function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  key: 3,
  class: "pt-6"
};
var _hoisted_3 = {
  class: "w-full"
};
var _hoisted_4 = {
  key: 1
};
var _hoisted_5 = {
  key: 2,
  class: "flex justify-center items-center"
};
var _hoisted_6 = {
  class: "border-t border-gray-450"
};
var _hoisted_7 = {
  class: "mt-2"
};
var _hoisted_8 = {
  key: 2,
  class: "text-center"
};
var _hoisted_9 = {
  key: 3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_delegation_modal = _resolveComponent("delegation-modal");
  var _component_user_action_modal = _resolveComponent("user-action-modal");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_row_of_rights = _resolveComponent("row-of-rights");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isUserModalOpened ? (_openBlock(), _createBlock(_component_delegation_modal, {
    key: 0,
    "options-list": _ctx.activeUsers,
    "preselected-items": _toConsumableArray($setup.userListIds),
    "modal-settings": $options.modalSettings,
    "skip-api": true,
    onUpdateDelegationItem: $options.handleUpdateUsers,
    onCloseDelegationModal: _cache[0] || (_cache[0] = function ($event) {
      return $data.isUserModalOpened = false;
    })
  }, null, 8 /* PROPS */, ["options-list", "preselected-items", "modal-settings", "onUpdateDelegationItem"])) : _createCommentVNode("v-if", true), $data.modalKey ? (_openBlock(), _createBlock(_component_user_action_modal, {
    key: 1,
    data: $data.data[$data.modalKey],
    "default-data": $data.defaultData,
    title: $data.modalKey,
    onAccept: $options.handleUpdateAccess,
    onDismiss: _cache[1] || (_cache[1] = function ($event) {
      return $data.modalKey = null;
    })
  }, null, 8 /* PROPS */, ["data", "default-data", "title", "onAccept"])) : _createCommentVNode("v-if", true), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 2
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("table", _hoisted_3, [_createElementVNode("thead", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (item, index) {
    return _openBlock(), _createElementBlock("th", {
      key: "user_action_head_".concat(index)
    }, [item === 'userIcon' ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "user-plus",
      class: "cursor-pointer mx-auto",
      onClick: _cache[2] || (_cache[2] = function ($event) {
        return $data.isUserModalOpened = true;
      })
    })) : typeof item === 'string' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createTextVNode(_toDisplayString(item.display_name) + " ", 1 /* TEXT */), _createVNode(_component_feather_icon, {
      icon: "x",
      class: "ml-2 cursor-pointer",
      onClick: function onClick($event) {
        return $options.removeUser(item);
      }
    }, null, 8 /* PROPS */, ["onClick"])]))]);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data, function (item, index) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: "user_action_body_".concat(index)
    }, [_createElementVNode("tr", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.headRows, function (rightItem, rightIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: "user_action_head_rows_".concat(index, "_").concat(rightIndex)
      }, [_createElementVNode("div", _hoisted_7, [rightItem === 'icon' ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "edit",
        class: "mx-auto mt-2 cursor-pointer",
        onClick: function onClick($event) {
          return $options.handleModalOpen(index);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : rightItem === 'title' ? (_openBlock(), _createBlock(_component_risma_title, {
        key: 1,
        type: "medium",
        title: index,
        class: "mt-2"
      }, null, 8 /* PROPS */, ["title"])) : rightItem ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($options.getUserAccessLevelLabel(rightItem, item)), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_9))])]);
    }), 128 /* KEYED_FRAGMENT */))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data[index], function (rightItem, rightIndex) {
      return _openBlock(), _createBlock(_component_row_of_rights, {
        key: "user_action_row_".concat(index, "_").concat(rightIndex),
        "access-level": rightItem.accessLevel,
        module: rightItem.module,
        "cell-classes": rightIndex === $data.data[index].length - 1 ? 'mb-2' : 'mb-1',
        title: rightItem.title,
        "users-data": $options.usersList,
        "project-id": rightItem.complianceProjectId || rightItem.riskProjectId || null
      }, null, 8 /* PROPS */, ["access-level", "module", "cell-classes", "title", "users-data", "project-id"]);
    }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */))])])]))]);
}