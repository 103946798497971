function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'GapSchemaNavigation',
  components: {
    RismaTitle: RismaTitle,
    TrafficLight: TrafficLight,
    FeatherIcon: FeatherIcon
  },
  props: {
    gaps: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      },
      note: 'list of gaps'
    },
    gapActiveId: {
      required: false,
      type: Number || String,
      default: null,
      note: 'active gap id'
    },
    showDomain: {
      required: false,
      type: Boolean,
      default: true,
      note: 'display domain info'
    }
  },
  emits: ['selectGap'],
  data: function data() {
    return {
      indexExpandedView: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useTrafficLights, {
    gapColors: 'gapTrafficLights'
  })), {}, {
    gapListByDomain: function gapListByDomain() {
      var _this = this;
      var result = [];
      var domain;
      this.gaps.forEach(function (gap, index) {
        var currentGap = gap.article;
        var previousGap = _this.gaps[index - 1] ? _this.gaps[index - 1].article : gap.article;
        if (previousGap.domain.label !== currentGap.domain.label || index === 0) {
          domain = _objectSpread({}, currentGap.domain);
          result.push(domain);
          domain._gaps = [];
        }
        domain._gaps.push(gap);
      });
      return result;
    }
  }),
  watch: {
    gapActiveId: function gapActiveId() {
      this.setIndexExpandedView();
    }
  },
  mounted: function mounted() {
    this.setIndexExpandedView();
  },
  methods: {
    setIndexExpandedView: function setIndexExpandedView() {
      var _this2 = this;
      Object.keys(this.gapListByDomain).forEach(function (key, index) {
        var isActive = _this2.gapListByDomain[key]._gaps.find(function (item) {
          return _this2.gapActiveId === item.articleId;
        });
        if (isActive) return _this2.indexExpandedView = index;
      });
    },
    getGapTitleForSidebar: function getGapTitleForSidebar(gap) {
      return "".concat(gap.article.article, ". ").concat(gap.article.requirement);
    },
    toggleGap: function toggleGap(value) {
      this.indexExpandedView = this.indexExpandedView === value ? null : value;
    },
    startTransition: function startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    endTransition: function endTransition(el) {
      el.style.height = '';
    }
  }
};