import i18n from '@/i18n';
export var getProjectDescriptionLabel = function getProjectDescriptionLabel(project) {
  return (project === null || project === void 0 ? void 0 : project.descriptionLabel) || i18n.t('Overall description');
};
export var getProjectPurposeLabel = function getProjectPurposeLabel(project) {
  return (project === null || project === void 0 ? void 0 : project.purposeLabel) || i18n.t('Purpose');
};
export var getProjectStatusLabel = function getProjectStatusLabel(project) {
  return (project === null || project === void 0 ? void 0 : project.statusLabel) || i18n.t('Process status');
};
export var getDPOLabel = function getDPOLabel() {
  return i18n.t('Data Protection Officer') + ' (DPO)';
};