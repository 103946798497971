// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.controls-nothing[data-v-ccb8b50a] {
  margin: 20px auto;
  text-align: center;
}
.controls-nothing[data-v-ccb8b50a]  .risma-title > h3 {
  font-family: "Inter", sans-serif;
  font-size: 27px;
  font-weight: 300;
  line-height: 1;
  padding-bottom: 10px;
  color: #347480;
}
.controls-nothing-text[data-v-ccb8b50a] {
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #4D4D4D;
}
.my-controls[data-v-ccb8b50a] {
  margin: 0 0 1.5rem;
}
.my-controls[data-v-ccb8b50a]  .cards-wrapper {
  margin: 0 0 1.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
