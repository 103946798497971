import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap"
};
var _hoisted_2 = {
  class: "mb-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_file_upload = _resolveComponent("risma-file-upload");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_file_upload, {
    onUpload: $options.fileUploaded
  }, null, 8 /* PROPS */, ["onUpload"])]), _createElementVNode("div", null, [_cache[0] || (_cache[0] = _createElementVNode("div", null, "Native design example", -1 /* HOISTED */)), _createVNode(_component_risma_file_upload, {
    "native-design": true,
    "show-extensions-title": false,
    onUpload: $options.fileUploaded
  }, null, 8 /* PROPS */, ["onUpload"])])]);
}