import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue";
var _hoisted_1 = ["href", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center px-1 py-0.5 rounded-sm text-white bg-blue-750", {
      'cursor-pointer': $props.link
    }]),
    onClick: _cache[3] || (_cache[3] = function () {
      return $options.onBoxClick && $options.onBoxClick.apply($options, arguments);
    })
  }, [$props.icon ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    icon: $props.icon,
    color: "white",
    class: "mr-1"
  }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), $props.link ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    ref: "clickableLink",
    href: $props.link,
    title: $props.tooltip,
    class: _normalizeClass(["grow", {
      truncate: !$props.isTag
    }]),
    rel: "noopener noreferrer",
    target: "_blank",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"]))
  }, _toDisplayString($props.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_1)) : (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(["p-0.5", {
      truncate: !$props.isTag
    }])
  }, _toDisplayString($props.title), 3 /* TEXT, CLASS */)), _ctx.$slots.preview ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {}, ["stop"]))
  }, [_renderSlot(_ctx.$slots, "preview")])) : _createCommentVNode("v-if", true), _ctx.$slots.end ? (_openBlock(), _createElementBlock("div", {
    key: 4,
    class: "pl-2",
    onClick: _cache[2] || (_cache[2] = _withModifiers(function () {}, ["stop"]))
  }, [_renderSlot(_ctx.$slots, "end")])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}