import { Http } from '../index';
import { OPTIONAL_FIELDS } from '@/constants/AdminActivityTypesOptionalFields';
import * as HttpUtils from '../httpUtils';
var CUSTOM_FIELD_URL = '/customfields';
export function getCustomFields(params) {
  var url = CUSTOM_FIELD_URL;
  if (params) {
    url += params;
  }
  return HttpUtils.handleRequestResponse(Http.get(url));
}

// In case we move it to backend. At the moment returns hardcoded fields.
export function getOptionalFields(getSettingValue) {
  return new Promise(function (resolve) {
    resolve(OPTIONAL_FIELDS(getSettingValue));
  });
}
export function getCustomField(id) {
  var url = "".concat(CUSTOM_FIELD_URL, "/").concat(id);
  return HttpUtils.handleRequestResponse(Http.get(url));
}
export function editCustomField(id, data) {
  var url = "".concat(CUSTOM_FIELD_URL, "/").concat(id);
  return HttpUtils.handleRequestResponse(Http.patch(url, data));
}
export function createCustomField(data) {
  var url = CUSTOM_FIELD_URL;
  return HttpUtils.handleRequestResponse(Http.post(url, data));
}
export function deleteCustomField(id) {
  var url = "".concat(CUSTOM_FIELD_URL, "/").concat(id);
  return HttpUtils.handleRequestResponse(Http.delete(url));
}
export function checkUserSyncConnection(data) {
  return HttpUtils.handleRequestResponse(Http.post('/admin/user_sync/run', data));
}
export function getSiemSetups() {
  return HttpUtils.handleRequestResponse(Http.get('/admin/siem'));
}
export function getSiemSetupById(id) {
  return HttpUtils.handleRequestResponse(Http.get("/admin/siem/".concat(id)));
}
export function createSiemSetup(data) {
  return HttpUtils.handleRequestResponse(Http.post('/admin/siem', data));
}
export function patchSiemSetup(id, data) {
  return HttpUtils.handleRequestResponse(Http.patch("/admin/siem/".concat(id), data));
}
export function deleteSiemSetup(id) {
  return HttpUtils.handleRequestResponse(Http.delete("/admin/siem/".concat(id)));
}
export function getUserActionConfiguration() {
  var getDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var query = '';
  if (getDefault) {
    query = '?default=true';
  }
  return HttpUtils.handleRequestResponse(Http.get('/admin/access_requirements' + query));
}
export function updateUserActionConfiguration(data) {
  return HttpUtils.handleRequestResponse(Http.post('/admin/access_requirements', data));
}
export function resetUserActionConfiguration(identifier) {
  return HttpUtils.handleRequestResponse(Http.delete('/admin/access_requirements/' + identifier));
}