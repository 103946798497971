function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { getContractsPayments } from '@/api/contracts/dashboard';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import YearWheelTable from '@/components/Molecules/YearWheelTable';
import currency from '@/constants/contracts/currency';
import { getIntervalOptions, getMonthOptions } from '@/constants/contracts/contract';
import { generateComputed } from '@/utils/base/generateComputed';
import { getCurrentYear } from '@/utils/date';
export default {
  name: 'ContractFinance',
  description: 'This is the Page component for /contracts/contract/:id/payments',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    InputFieldSelect: InputFieldSelect,
    YearWheelTable: YearWheelTable
  },
  props: {
    data: {
      type: Object,
      required: true,
      note: 'Contract object'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    updateIdx: {
      type: Number,
      required: false,
      default: 0
    }
  },
  emits: ['propertyChanged', 'validationError'],
  data: function data() {
    return {
      currencyOptions: currency,
      paymentIntervalOptions: getIntervalOptions(),
      paymentMonthOptions: getMonthOptions(),
      currentYear: getCurrentYear(),
      payments: null,
      paymentLoading: false,
      translate: getTranslate['ContractFinance']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), generateComputed(['contractAmount', 'contractAmountCurrency', 'paymentInterval', 'paymentIntervalStartMonth'], {
    object: 'data',
    event: 'propertyChanged'
  })),
  watch: {
    updateIdx: function updateIdx() {
      this.getContractPaymentsData();
    },
    contractAmountCurrency: function contractAmountCurrency(currency) {
      if (currency) {
        this.emitInfoMessage('');
        this.getContractPaymentsData();
      } else {
        this.emitInfoMessage(this.translate.inOrderToCalculateTheTotalPayment);
      }
    }
  },
  mounted: function mounted() {
    if (this.contractAmountCurrency) this.getContractPaymentsData();
    if (!this.contractAmountCurrency) {
      this.emitInfoMessage(this.translate.inOrderToCalculateTheTotalPayment);
    }
  },
  methods: {
    getContractPaymentsData: function getContractPaymentsData() {
      var _this = this;
      this.paymentLoading = true;
      return getContractsPayments(this.currentYear, this.contractAmountCurrency, this.data.id).then(function (data) {
        return _this.payments = data;
      }).catch(function () {}).finally(function () {
        return _this.paymentLoading = false;
      });
    },
    emitInfoMessage: function emitInfoMessage(message) {
      this.$emit('validationError', {
        message: message,
        type: 'info',
        closeable: false
      });
    },
    handleYearUpdate: function handleYearUpdate(newYear) {
      this.currentYear = newYear;
      this.getContractPaymentsData();
    }
  }
};