import i18n from '@/i18n';
import { UserLevels } from '@/constants/UserLevels';
import { useSettingsStore } from '@/Store/settingsStore';
var accessLevelsBase = [{
  value: UserLevels.NONE,
  label: 'NONE'
}, {
  value: UserLevels.NORMAL,
  label: 'NORMAL'
}, {
  value: UserLevels.PRIVILEGED,
  label: 'PRIVILEGED'
}, {
  value: UserLevels.SUPER,
  label: 'SUPER'
}];
var accessLevels = [].concat(accessLevelsBase, [{
  value: UserLevels.ADMIN,
  label: 'ADMIN'
}]);
var accessLevelsCompanies = [{
  value: UserLevels.ADMIN,
  label: 'ADMIN'
}, {
  value: UserLevels.NONE,
  label: 'NONE'
}, {
  value: UserLevels.NORMAL,
  label: 'NORMAL'
}, {
  value: UserLevels.SUPER,
  label: 'SUPER'
}];
var adminLevels = function adminLevels() {
  return [{
    value: 0,
    label: i18n.t('No')
  }, {
    value: 1,
    label: i18n.t('Yes')
  }];
};
var filteredColumns = function filteredColumns(excluded) {
  var settingsStore = useSettingsStore();
  var columns = [{
    columnName: 'Object Id',
    translatable: true
  }, {
    columnName: 'Name',
    translatable: true
  }, {
    columnName: 'Admin',
    translatable: true
  }, {
    columnName: 'Controls',
    translatable: false
  }, {
    columnName: 'Actions',
    translatable: false
  }, {
    columnName: 'Incidents',
    translatable: false
  }, {
    columnName: 'Risks',
    translatable: false
  }, {
    columnName: 'Contracts',
    translatable: false
  }, {
    columnName: 'Companies',
    translatable: true
  }, {
    columnName: settingsStore.informationAssetsName,
    translatable: false
  }, {
    columnName: settingsStore.processLibraryName,
    translatable: false
  }, {
    columnName: 'Locked fields',
    translatable: true
  }];
  var filteredColumns = columns.filter(function (column) {
    return !excluded.includes(column.columnName);
  });
  return filteredColumns.map(function (column) {
    return column.translatable ? i18n.t(column.columnName) : column.columnName;
  });
};
var columns = function columns() {
  return filteredColumns([]);
};
export { columns, adminLevels, accessLevelsBase, accessLevels, accessLevelsCompanies };