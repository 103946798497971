// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-reports-wrap[data-v-931aad50]  .traffic-light {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #dedede;
  margin-left: 5px;
}
.action-reports-wrap[data-v-931aad50]  .collapsible-panel-header {
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0 0.5rem 0 0;
}
.action-reports-wrap .action-table[data-v-931aad50]  th {
  text-align: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
