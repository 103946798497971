import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "flex flex-col sticky left-6 z-10 pr-6"
};
var _hoisted_2 = {
  key: 1,
  class: "sticky left-6 flex justify-center mb-5 pl-6 items-center w-vw-75"
};
var _hoisted_3 = ["href"];
var _hoisted_4 = {
  key: 0,
  class: "flex justify-center p-3 w-fit mx-auto min-h-180px"
};
var _hoisted_5 = {
  class: "relative z-2 bg-white"
};
var _hoisted_6 = {
  class: "sticky z-2 top-117px flex items-center justify-between h-62px px-5 py-2 border-b-2 border-black bg-white"
};
var _hoisted_7 = {
  class: "flex"
};
var _hoisted_8 = {
  class: "flex"
};
var _hoisted_9 = {
  class: "relative"
};
var _hoisted_10 = {
  class: "sticky top-117px flex h-62px pt-5 pb-4 border-b-2 border-black bg-white"
};
var _hoisted_11 = ["title"];
var _hoisted_12 = {
  key: 1,
  class: "w-6 h-6 border-2 rounded-full border-gray-450 bg-white mx-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_control_filters = _resolveComponent("control-filters");
  var _component_no_search_results = _resolveComponent("no-search-results");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_risma_title_with_icon = _resolveComponent("risma-title-with-icon");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.annualOverview,
    class: "-mb-40px"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_control_filters, {
    modelValue: $data.filters,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.filters = $event;
    }),
    data: $options.filtersData,
    "state-local": $data.stateLocal,
    "header-styles": "pl-80",
    onResetFilters: $options.resetFilters
  }, null, 8 /* PROPS */, ["modelValue", "data", "state-local", "onResetFilters"])]), !$options.slicedDashboard.length ? (_openBlock(), _createBlock(_component_no_search_results, {
    key: 0,
    body: $data.noSearchText
  }, null, 8 /* PROPS */, ["body"])) : _createCommentVNode("v-if", true), $options.slicedDashboard.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$props.showExportButton ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: $options.downloadUrl,
    download: "overview.png",
    class: "mr-24 p-2 border rounded-full border-blue-750"
  }, [_createVNode(_component_feather_icon, {
    icon: "download",
    height: "25",
    width: "25",
    class: "stroke-2"
  })], 8 /* PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.legends, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "mr-6"
    }, [_createElementVNode("span", {
      style: _normalizeStyle({
        background: item.color
      }),
      class: "inline-block w-3 h-3 mr-1"
    }, null, 4 /* STYLE */), _createElementVNode("span", null, _toDisplayString(item.label), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $data.showOverview ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [$options.slicedDashboard.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "absolute top-0 right-0 bottom-0 -left-24px bg-white"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-3 mr-6", $options.isPastYearLimit ? 'text-gray-450' : 'text-blue-750 cursor-pointer']),
    icon: "rewind",
    onClick: $options.goYearBack
  }, null, 8 /* PROPS */, ["class", "onClick"]), _createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-3", $options.isPastMonthLimit ? 'text-gray-450' : 'text-blue-750 cursor-pointer']),
    icon: "chevron-left",
    onClick: $options.goMonthBack
  }, null, 8 /* PROPS */, ["class", "onClick"])]), _createVNode(_component_single_select, {
    "model-value": $data.yearsToDisplay,
    options: $options.yearsToDisplayOptions,
    class: "max-w-120px",
    "onUpdate:modelValue": $options.yearsToDisplaySelected
  }, null, 8 /* PROPS */, ["model-value", "options", "onUpdate:modelValue"]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-3 mr-6", $options.isFutureMonthLimit ? 'text-gray-450' : 'text-blue-750 cursor-pointer']),
    icon: "chevron-right",
    onClick: $options.goMonthForward
  }, null, 8 /* PROPS */, ["class", "onClick"]), _createVNode(_component_feather_icon, {
    class: _normalizeClass(["stroke-3", $options.isFutureYearLimit ? 'text-gray-450' : 'text-blue-750 cursor-pointer']),
    icon: "fast-forward",
    onClick: $options.goYearForward
  }, null, 8 /* PROPS */, ["class", "onClick"])])]), _createElementVNode("ul", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredDashboard, function (item) {
    return _openBlock(), _createElementBlock("li", {
      key: item.control.id,
      class: "py-2 pl-5 pr-10 even:bg-gray-250"
    }, [_createVNode(_component_preview_modal_link, {
      activity: item.control,
      class: "hover:underline",
      onClick: function onClick($event) {
        return _ctx.$emit('openPreview', item.control.id);
      },
      onUpdated: _cache[1] || (_cache[1] = function ($event) {
        return _ctx.$emit('updated', $event);
      }),
      onDismissModal: _cache[2] || (_cache[2] = function ($event) {
        return _ctx.$emit('dismissModal');
      })
    }, {
      default: _withCtx(function () {
        return [_createVNode(_component_risma_title_with_icon, {
          icon: "confidential",
          type: "medium",
          title: "".concat(item.control.cpath, " ").concat(item.control.title),
          "title-attribute": $options.getItemHoverTitle(item.control),
          "display-icon": item.control.confidential
        }, null, 8 /* PROPS */, ["title", "title-attribute", "display-icon"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onClick"])]);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.displayMonths, function (month) {
    return _openBlock(), _createElementBlock("div", {
      key: month,
      class: "flex w-16 timeline relative"
    }, _toDisplayString(month), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.slicedDashboard, function (item) {
    return _openBlock(), _createElementBlock("li", {
      key: item.id,
      class: "flex py-2 even:bg-gray-250"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.months, function (month) {
      return _openBlock(), _createElementBlock("div", {
        key: "".concat(month.month, "-").concat(month.year),
        title: month.hoverTitle,
        class: "flex w-16 h-6"
      }, [month.legendValue === $data.ControlStatusesUI.NOT_PERFORMED ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "x",
        width: "24",
        height: "24",
        class: "stroke-3 text-white border-2 rounded-full border-orange-50 bg-orange-50 mx-auto"
      })) : _createCommentVNode("v-if", true), month.legendValue === $data.ControlStatusesUI.NOT_COMPLETED_YET ? (_openBlock(), _createElementBlock("div", _hoisted_12)) : month.legendValue !== null ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 2,
        icon: "check",
        width: "24",
        height: "24",
        style: _normalizeStyle({
          borderColor: $options.getLegendColor(month.legendValue),
          backgroundColor: $options.getLegendColor(month.legendValue)
        }),
        class: "stroke-3 border-2 rounded-full text-white mx-auto"
      }, null, 8 /* PROPS */, ["style"])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_11);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]);
}