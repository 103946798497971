import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, null, {
    default: _withCtx(function () {
      return [$data.loading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 0
      })) : $data.dataset.length ? (_openBlock(), _createBlock(_component_risma_datatable, {
        key: 1,
        "dataset-main": _ctx.datasetSlice,
        "dataset-total": $data.dataset,
        "dataset-total-sliced": _ctx.datasetSliceTotal,
        "columns-by-default": $options.columns,
        "state-local": "deleted-nodes-table",
        onPageChange: _ctx.onPageChange,
        onOnStateChanged: _ctx.onStateChanged
      }, null, 8 /* PROPS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "columns-by-default", "onPageChange", "onOnStateChanged"])) : (_openBlock(), _createBlock(_component_risma_title, {
        key: 2,
        class: "text-center",
        title: $data.translate.noNodesToShow,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]))];
    }),
    _: 1 /* STABLE */
  });
}