import { Http } from '../../index';
import { handleRequestResponse, objectToQueryString } from '../../httpUtils';

/**
 * Get the Article 30 report
 */
export function getReport2(projectId, questionnaireId, questionIds, nodeIds) {
  var data = {
    questionnaireId: questionnaireId,
    questionIds: questionIds.toString(),
    nodeIds: nodeIds.toString()
  };
  return handleRequestResponse(Http.fakeGet("/compliance/projects/".concat(projectId, "/report/questionnaire"), data));
}

/**
 * Get report url
 */
export function getReport2Url(projectId, questionnaireId, questionIds, nodeIds) {
  var object = {
    questionnaireId: questionnaireId,
    questionIds: questionIds.toString(),
    nodeIds: nodeIds.toString()
  };
  var qs = objectToQueryString(object);
  return "/compliance/projects/".concat(projectId, "/report/questionnaire?").concat(qs);
}