import i18n from '@/i18n';
export var getTranslate = {
  RelevantQuestions: function RelevantQuestions() {
    return {
      relevantQuestions: i18n.t('Relevant questions'),
      chooseGapArticle: i18n.t('Choose gap article'),
      chooseQuestion: i18n.t('Choose question'),
      add: i18n.t('Add'),
      save: i18n.t('Save'),
      saved: i18n.t('Saved')
    };
  },
  SecurityGroups: function SecurityGroups() {
    return {
      singleSignonSettings: i18n.t('Single sign-on settings'),
      createNewComplianceAccess: i18n.t('Create new compliance access'),
      createNewRiskAccess: i18n.t('Create new risk access'),
      update: i18n.t('Update'),
      hideProjects: i18n.t('Hide projects'),
      showProjects: i18n.t('Show projects'),
      delete: i18n.t('Delete'),
      areYouSureYouWantToDeleteThisSecurityGroup: i18n.t('Are you sure you want to delete this') + ' ' + i18n.t('Security group').toLowerCase(),
      securityGroupHasBeenDeleted: i18n.t('Security group') + ' ' + i18n.t('was successfully deleted'),
      userSynchronisation: i18n.t('User synchronisation')
    };
  }
};