import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "col-1"
};
var _hoisted_2 = {
  class: "col-11"
};
var _hoisted_3 = {
  class: "row"
};
var _hoisted_4 = {
  class: "col-11"
};
var _hoisted_5 = {
  class: "question-container"
};
var _hoisted_6 = {
  class: "question-number"
};
var _hoisted_7 = {
  class: "question-text"
};
var _hoisted_8 = {
  key: 0,
  class: "row help-text"
};
var _hoisted_9 = {
  class: "col-12"
};
var _hoisted_10 = {
  class: "row"
};
var _hoisted_11 = {
  class: "col-12"
};
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['gap-logic-information-box-question', 'row', {
      expanded: $data.expanded
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_traffic_light, {
    "is-ban": $props.question.trafficlight === 5,
    color: _ctx.getGapColor($props.question.trafficlight),
    size: "small"
  }, null, 8 /* PROPS */, ["is-ban", "color"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString($props.question.questionnumber) + ".", 1 /* TEXT */), _createElementVNode("span", _hoisted_7, _toDisplayString($props.question.question), 1 /* TEXT */)])]), !$data.expanded ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "col-1 show-more",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.expanded = true;
    })
  }, _toDisplayString($data.translate.seeMore), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), $data.expanded ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("p", null, [_createTextVNode(_toDisplayString($props.question.help) + " ", 1 /* TEXT */), _createElementVNode("span", {
    class: "show-less",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.expanded = false;
    })
  }, _toDisplayString($data.translate.seeLess), 1 /* TEXT */)])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("p", null, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "answer-letter"
  }, "A:", -1 /* HOISTED */)), $props.question.questionAnswer ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($props.question.questionAnswer), 1 /* TEXT */)) : _createCommentVNode("v-if", true), !$props.question.questionAnswer ? (_openBlock(), _createElementBlock("span", _hoisted_13, [_createElementVNode("i", null, _toDisplayString($data.translate.notAnswered), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])])])])], 2 /* CLASS */);
}