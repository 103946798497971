import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, resolveDirective as _resolveDirective } from "vue";
var _hoisted_1 = {
  class: "flex justify-between"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = ["title"];
var _hoisted_4 = ["tabindex"];
var _hoisted_5 = {
  class: "flex justify-between items-center"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  class: "flex py-1 text-rm-signal-grey-dark hover:text-rm-text cursor-pointer"
};
var _hoisted_10 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_ai_clarify_sender = _resolveComponent("ai-clarify-sender");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_editor = _resolveComponent("editor");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "headline", {}, function () {
    return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h2", {
      class: "text-base text-gray-750 font-bold truncate cursor-pointer",
      title: $props.headline,
      onMousedown: _cache[0] || (_cache[0] = _withModifiers(function () {
        return $options.toggleTinyEditor && $options.toggleTinyEditor.apply($options, arguments);
      }, ["stop"]))
    }, _toDisplayString($props.headline), 41 /* TEXT, PROPS, NEED_HYDRATION */, _hoisted_3), $options.showEditButton ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "ml-2 cursor-pointer text-gray-750",
      icon: "edit",
      onClick: _cache[1] || (_cache[1] = function ($event) {
        return $data.isEditMode = true;
      })
    })) : _createCommentVNode("v-if", true), $data.content && $props.aiSource ? (_openBlock(), _createBlock(_component_ai_clarify_sender, {
      key: 1,
      context: $data.content,
      source: $props.aiSource
    }, null, 8 /* PROPS */, ["context", "source"])) : _createCommentVNode("v-if", true)]), _renderSlot(_ctx.$slots, "newOption", {}, undefined, true)])];
  }, true), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tinymce-box", $props.editorWrapperClass]),
    tabindex: $props.readOnly ? -1 : 0,
    onKeyup: _cache[7] || (_cache[7] = _withKeys(_withModifiers(function () {
      return $options.toggleTinyEditor && $options.toggleTinyEditor.apply($options, arguments);
    }, ["stop"]), ["enter"]))
  }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_renderSlot(_ctx.$slots, "header", {}, undefined, true)]), !$props.readOnly && $props.showControlButtons ? (_openBlock(), _createElementBlock("div", _hoisted_6, [!$data.isEditMode ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_button, {
    text: $data.translate.edit,
    type: "util",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.isEditMode = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "edit",
        class: "text-gray-610 stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"])])) : (_openBlock(), _createBlock(_component_buttons_row, {
    key: 1,
    data: $options.buttonsRowData,
    texts: [$data.translate.cancel, $data.translate.save],
    type: ['util', 'save'],
    "click-events": [$options.cancelChanges, $options.saveChanges]
  }, null, 8 /* PROPS */, ["data", "texts", "click-events"]))])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", null, [$options.showPreviewData ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["tinymce-box-content", {
      open: $data.isEditMode
    }]),
    onClick: _cache[4] || (_cache[4] = _withModifiers(function () {
      return $options.onContentClick && $options.onContentClick.apply($options, arguments);
    }, ["stop"]))
  }, [$options.textData ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass({
      'cursor-pointer': !$props.readOnly && !$props.showControlButtons
    }),
    innerHTML: $options.shortenedData
  }, null, 10 /* CLASS, PROPS */, _hoisted_8)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", {
    class: "glob-l1",
    onClick: _cache[3] || (_cache[3] = _withModifiers(function () {
      return $options.onExpansionLabelClick && $options.onExpansionLabelClick.apply($options, arguments);
    }, ["stop"]))
  }, _toDisplayString($options.expansionLabel), 1 /* TEXT */), $options.showEditIcon ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    width: "16",
    height: "16",
    class: "ml-1 stroke-2",
    icon: "edit"
  })) : _createCommentVNode("v-if", true)])], 2 /* CLASS */)) : _createCommentVNode("v-if", true), (!$props.readOnly || $props.alwaysOpen) && $data.tinymceModulesLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_withDirectives(_createElementVNode("div", null, [_createVNode(_component_editor, {
    id: $data.id,
    modelValue: $data.content,
    "onUpdate:modelValue": [_cache[5] || (_cache[5] = function ($event) {
      return $data.content = $event;
    }), $options.onChange],
    init: $options.init,
    disabled: $props.readOnly,
    "output-format": "html",
    onFocus: _cache[6] || (_cache[6] = function ($event) {
      return $data.isEditMode = true;
    })
  }, null, 8 /* PROPS */, ["id", "modelValue", "init", "disabled", "onUpdate:modelValue"])], 512 /* NEED_PATCH */), [[_vShow, !$options.showPreviewData]])])) : _createCommentVNode("v-if", true)])], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4)), [[_directive_click_outside, $options.onOutsideClick]])]);
}