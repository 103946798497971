function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Http } from '../index';
import { flushRisksCache } from '@/api/risma/data';
import { getPromiseWithCacheTimeout } from '@/api/HttpCache';
import { handleRequestResponse, throwError } from '../httpUtils';
export function getRisksFromIdArray(idList) {
  return handleRequestResponse(Http.get('risks/risk?idList=' + idList.join()));
}
export function getMyRisks() {
  return handleRequestResponse(Http.get('/risks/my'));
}
var baseUrlThreats = 'risks/data/threats';
var baseUrlVulnerabilities = 'risks/data/vulnerabilities';
export function getThreats(projectId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return handleRequestResponse(Http.get("".concat(baseUrlThreats, "?projectId=").concat(projectId, "&").concat(params)));
}
export function createThreat(data) {
  return handleRequestResponse(Http.post(baseUrlThreats, data));
}
export function updateThreat(id, data) {
  return handleRequestResponse(Http.patch("".concat(baseUrlThreats, "/").concat(id), data));
}
export function deleteThreat(id) {
  return handleRequestResponse(Http.delete("".concat(baseUrlThreats, "/").concat(id)));
}
export function deleteThreatFromProject(data) {
  return handleRequestResponse(Http.delete('risks/data/probabilityproject', {
    data: data
  }));
}
export function getRiskProjects() {
  return getPromiseWithCacheTimeout('risks/projects');
}
export function getSingleRiskProject(projectId) {
  return getRiskProjects().then(function (_ref) {
    var list = _ref.list;
    return list.find(function (project) {
      return +project.id === +projectId;
    });
  });
}
export function getAllRiskProjects() {
  return handleRequestResponse(Http.get('risks/projects?filters[includeDisabled]=1'));
}
export function createRiskProject(data) {
  return handleRequestResponse(Http.post('risks/projects', data));
}
export function updateRiskProject(projectId, data) {
  return handleRequestResponse(Http.patch("risks/projects/".concat(projectId), data));
}
export function getVulnerabilities() {
  return handleRequestResponse(Http.get(baseUrlVulnerabilities));
}
export function createVulnerability(data) {
  return handleRequestResponse(Http.post(baseUrlVulnerabilities, data));
}
export function updateVulnerability(id, data) {
  return handleRequestResponse(Http.patch("".concat(baseUrlVulnerabilities, "/").concat(id), data));
}
export function deleteVulnerability(id) {
  return handleRequestResponse(Http.delete("".concat(baseUrlVulnerabilities, "/").concat(id)));
}
export function getRiskData(projectId, periodId, riskPath) {
  var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var skip401Check = arguments.length > 4 ? arguments[4] : undefined;
  return Http.get("risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk/").concat(riskPath).concat(params)).then(function (response) {
    return response.data;
  }).catch(function (err) {
    return throwError(err, skip401Check);
  });
}
export function deleteRisk(projectId, periodId, riskId) {
  return handleRequestResponse(Http.delete("risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk/").concat(riskId)));
}
export function copyRisk(projectId, periodId, riskNumber, withChildren) {
  return createRisk(projectId, periodId, {
    copyFrom: riskNumber,
    withChildren: withChildren
  });
}
export function createRiskPeriods(id, data) {
  return handleRequestResponse(Http.post("risks/projects/".concat(id, "/periods"), data));
}
export function createPeriod(projectId, data) {
  return handleRequestResponse(Http.post("risks/projects/".concat(projectId, "/periods"), data));
}
export function updatePeriod(projectId, periodId, data) {
  return handleRequestResponse(Http.patch("risks/projects/".concat(projectId, "/periods/").concat(periodId), data));
}
export function deletePeriod(projectId, periodId) {
  return handleRequestResponse(Http.delete("risks/projects/".concat(projectId, "/periods/").concat(periodId)));
}
export function getPeriod(projectId, periodId) {
  return handleRequestResponse(Http.get("risks/projects/".concat(projectId, "/periods/").concat(periodId)));
}
export function createRisk(projectId, periodId, data) {
  flushRisksCache();
  return handleRequestResponse(Http.post("risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk"), data));
}
export function updateRisk(projectId, periodId, riskPath, data) {
  return handleRequestResponse(Http.patch("risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk/").concat(riskPath), data));
}
export function getRisks(projectId, periodId, query) {
  var url = "risks/projects/".concat(projectId, "/periods/").concat(periodId, "/risk");
  if (query) {
    url += "?".concat(query);
  }
  return handleRequestResponse(Http.get(url));
}
export function getReports() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var url = '/risks/reports';
  var promise;
  if (params === null) {
    params = '';
  }
  if (typeof params === 'string') {
    promise = Http.get(url + params);
  } else {
    promise = Http.fakeGet(url, params);
  }
  return handleRequestResponse(promise);
}
export function getReportsThreat(projectId) {
  var url = "/risks/reports/threat?filters[projectId]=".concat(projectId);
  return handleRequestResponse(Http.get(url));
}
export function getReportsVulnerabilities(projectId) {
  var url = "/risks/reports/vulnerability?filters[projectId]=".concat(projectId);
  return handleRequestResponse(Http.get(url));
}
export function getDailyTeamRisks() {
  return handleRequestResponse(Http.get('/risks/dailyteam'));
}
export function getRisksDashboardsOverview(projectId, periodId) {
  return handleRequestResponse(Http.get("/risks/dashboards/overview?filters[projectId]=".concat(projectId).concat(periodId ? "&filters[projectPeriodId]=".concat(periodId) : '')));
}
export function getRiskTypes() {
  return handleRequestResponse(Http.get('/risks/data/risktype'));
}
export function createRiskType(data) {
  return handleRequestResponse(Http.post('/risks/data/risktype', data));
}
export function updateRiskType(id, data) {
  return handleRequestResponse(Http.patch("/risks/data/risktype/".concat(id), data));
}
export function deleteRiskType(id) {
  return handleRequestResponse(Http.delete("/risks/data/risktype/".concat(id)));
}
export function getRisksManagementLevels() {
  return handleRequestResponse(Http.get('/risks/data/managementlevel'));
}
export function createRisksManagementLevel(name) {
  return handleRequestResponse(Http.post('/risks/data/managementlevel', {
    name: name
  }));
}
export function deleteRisksManagementLevel(id) {
  return handleRequestResponse(Http.delete("/risks/data/managementlevel/".concat(id)));
}
export function updateApprovalStatus(data) {
  return handleRequestResponse(Http.patch('/risks/approve', data));
}
export function getParentRisksList(projectId) {
  return handleRequestResponse(Http.get("/data/risks/riskparents/".concat(projectId)));
}
export function getManagementReports() {
  return handleRequestResponse(Http.get('/risks/reports/managementreport'));
}
export function getManagementReportById(id) {
  return handleRequestResponse(Http.get('/risks/reports/managementreport/' + id));
}
export function saveManagementReport(id, body) {
  return handleRequestResponse(Http.patch('/risks/reports/managementreport/' + id, body));
}
export function createManagementReport(body) {
  return handleRequestResponse(Http.post('/risks/reports/managementreport', body));
}
export function removeManagementReport(id) {
  return handleRequestResponse(Http.delete('/risks/reports/managementreport/' + id));
}
export function getManagementReportPreview(id) {
  return handleRequestResponse(Http.get("/risks/reports/managementreport/".concat(id, "/backgroundimage"), {
    responseType: 'blob'
  }));
}
export function setManagementReportPreview(id, body) {
  return handleRequestResponse(Http.post("/risks/reports/managementreport/".concat(id, "/backgroundimage"), body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));
}
export function getRiskThreatCategories() {
  return handleRequestResponse(Http.get('risks/threatcategory'));
}
export function createRiskThreatCategory(data) {
  return handleRequestResponse(Http.post('risks/threatcategory', data));
}
export function setHeatmapColors(projectId, data) {
  return handleRequestResponse(Http.post("risks/projects/".concat(projectId, "/options/heatmap"), data));
}
export function getRisk2ManagementReports(projectId) {
  return handleRequestResponse(Http.get("/risks/projects/".concat(projectId, "/managementreport")));
}
export function editRisk2ManagementReport(projectId, body) {
  return handleRequestResponse(Http.patch("/risks/projects/".concat(projectId, "/managementreport/").concat(body.id), _objectSpread({}, body)));
}
export function createRisk2ManagementReport(projectId, body) {
  return handleRequestResponse(Http.post("/risks/projects/".concat(projectId, "/managementreport"), body));
}
export function deleteRisk2ManagementReport(projectId, reportId) {
  return handleRequestResponse(Http.delete("/risks/projects/".concat(projectId, "/managementreport/").concat(reportId)));
}
export function getThreatAssessmentsReport(projectId, params) {
  return handleRequestResponse(Http.get("/risks/reports/threatassessments?filters[projectId]=".concat(projectId, "&").concat(params)));
}