import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-full relative"
};
var _hoisted_2 = {
  key: 0,
  class: "w-full"
};
var _hoisted_3 = {
  class: "border-b border-b-gray-450"
};
var _hoisted_4 = {
  class: "py-3 px-4"
};
var _hoisted_5 = {
  class: "py-3 px-4"
};
var _hoisted_6 = {
  class: "py-3 px-4"
};
var _hoisted_7 = {
  class: "border-b border-b-gray-450"
};
var _hoisted_8 = {
  class: "py-3 px-4"
};
var _hoisted_9 = {
  class: "py-3 px-4"
};
var _hoisted_10 = {
  class: "py-3 px-4 text-center"
};
var _hoisted_11 = {
  class: "py-3 px-4"
};
var _hoisted_12 = {
  class: "py-3 px-4"
};
var _hoisted_13 = {
  class: "py-3 px-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_input_field = _resolveComponent("input-field");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_draggable = _resolveComponent("draggable");
  var _component_risma_button = _resolveComponent("risma-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.workflowStatusesAdministration,
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), $options.workflowStatuses.length ? (_openBlock(), _createElementBlock("table", _hoisted_2, [_createElementVNode("thead", null, [_createElementVNode("tr", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("th", {
    class: "py-3 px-4"
  }, null, -1 /* HOISTED */)), _createElementVNode("th", _hoisted_4, _toDisplayString($data.translate.title), 1 /* TEXT */), _createElementVNode("th", _hoisted_5, _toDisplayString($data.translate.order), 1 /* TEXT */), _createElementVNode("th", _hoisted_6, _toDisplayString($data.translate.delete), 1 /* TEXT */)])]), _createVNode(_component_draggable, _mergeProps({
    modelValue: $options.workflowStatuses,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.workflowStatuses = $event;
    }),
    tag: "tbody",
    "component-data": {
      type: 'transition'
    },
    "item-key": "id",
    handle: ".drag-icon"
  }, $data.DragOptions, {
    disabled: $data.updateInProgress,
    onChange: $options.statusMoved
  }), {
    item: _withCtx(function (_ref) {
      var element = _ref.element;
      return [_createElementVNode("tr", _hoisted_7, [_createElementVNode("td", _hoisted_8, [_createVNode(_component_drag_and_drop_icon, {
        class: "drag-icon",
        disabled: $data.updateInProgress
      }, null, 8 /* PROPS */, ["disabled"])]), _createElementVNode("td", _hoisted_9, [_createVNode(_component_input_field, {
        "model-value": $data.getWorkflowStatusTitle(element.title),
        maxlength: 100,
        type: "text",
        onUpdated: function onUpdated($event) {
          return $options.updateTitle(element, $event);
        },
        onBlur: $options.handleUpdate
      }, null, 8 /* PROPS */, ["model-value", "onUpdated", "onBlur"])]), _createElementVNode("td", _hoisted_10, _toDisplayString(element.order), 1 /* TEXT */), _createElementVNode("td", _hoisted_11, [!element.inUse ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "x",
        class: "stroke-2 text-red-50 cursor-pointer m-auto",
        onClick: function onClick($event) {
          return $options.handleDeleteStatus(element);
        }
      }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue", "disabled", "onChange"]), _createElementVNode("tbody", null, [_createElementVNode("tr", null, [_cache[3] || (_cache[3] = _createElementVNode("td", {
    class: "py-3 px-4"
  }, null, -1 /* HOISTED */)), _createElementVNode("td", _hoisted_12, [_createVNode(_component_input_field, {
    modelValue: $data.newStatusName,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.newStatusName = $event;
    }),
    placeholder: $data.translate.title,
    maxlength: 100,
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])]), _cache[4] || (_cache[4] = _createElementVNode("td", {
    class: "py-3 px-4"
  }, null, -1 /* HOISTED */)), _createElementVNode("td", _hoisted_13, [_createVNode(_component_risma_button, {
    text: $data.translate.createStatus,
    type: "save",
    disabled: !$data.newStatusName,
    onClick: $options.createStatus
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])])])])])) : _createCommentVNode("v-if", true)]);
}