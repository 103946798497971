import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaTable from '@/components/Molecules/RismaTable/RismaTable';
import { getChangelogReport } from '@/api/risma/changelog';
import { MODULES } from '@/constants/modules';
import { generateKey } from '@/utils/Utils';
var POSSIBLE_MODULES = [MODULES.CONTROLS, MODULES.EXECUTION, MODULES.ACCESS_REQUIREMENTS];
export default {
  name: 'ChangelogReport',
  components: {
    RismaTitle: RismaTitle,
    RismaTable: RismaTable
  },
  props: {
    module: {
      type: String,
      required: true,
      default: MODULES.CONTROLS,
      validator: function validator(value) {
        return POSSIBLE_MODULES.includes(value);
      }
    }
  },
  data: function data() {
    return {
      tableGeneratedKey: generateKey(),
      tableStateKey: "".concat(this.module, "_changelog_report"),
      translate: {
        changelogReport: this.$trans('Changelog report')
      }
    };
  },
  methods: {
    getDataBE: function getDataBE(params) {
      return getChangelogReport(this.module, '?' + params);
    }
  }
};