// Needed for historic changelogs
export var INCIDENT_STATES = {
  OPEN: {
    name: 'Open',
    value: 0
  },
  REPORTED: {
    name: 'Reported',
    value: 1
  },
  CLOSED: {
    name: 'Closed',
    value: 2
  },
  MITIGATED: {
    name: 'Mitigated',
    value: 3
  }
};