import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "flex justify-between items-center mb-4 bg-white"
};
var _hoisted_2 = {
  key: 2
};
var _hoisted_3 = {
  class: "flex items-center mb-12 bg-white"
};
var _hoisted_4 = ["placeholder"];
var _hoisted_5 = {
  key: 3
};
var _hoisted_6 = {
  key: 4,
  class: "tables"
};
var _hoisted_7 = ["title", "onClick"];
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  class: "flex items-center"
};
var _hoisted_10 = {
  key: 0,
  src: "/assets/svg/azure.svg",
  width: "21",
  height: "21",
  alt: "Active Directory user"
};
var _hoisted_11 = ["onClick"];
var _hoisted_12 = {
  key: 1
};
var _hoisted_13 = {
  key: 0
};
var _hoisted_14 = {
  key: 1
};
var _hoisted_15 = {
  key: 2
};
var _hoisted_16 = {
  class: "admin-cells activity-cell bg-blue-750 group-hover:bg-blue-910"
};
var _hoisted_17 = ["checked", "disabled", "onChange"];
var _hoisted_18 = {
  key: 5,
  class: "flex justify-center mt-8 w-73vw"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_tiny_mce_box = _resolveComponent("tiny-mce-box");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_risma_table = _resolveComponent("risma-table");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_risma_user_rights_picker = _resolveComponent("risma-user-rights-picker");
  var _component_risma_modules_rights_picker = _resolveComponent("risma-modules-rights-picker");
  var _component_user_edit_modal = _resolveComponent("user-edit-modal");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-rights-table", $options.isUserRightsTableNewEnabled ? 'mr-16' : 'mr-32'])
  }, [$data.showAllUserEmailsModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.sendEmailToAllUsers,
    "button-dismiss-text": $data.translate.close,
    "show-ok-button": false,
    "header-truncate": false,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showAllUserEmailsModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [_createVNode(_component_risma_title, {
        title: $options.copyEmailsMessage,
        class: "mb-4",
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_tiny_mce_box, {
        height: 200,
        "always-open": true,
        "initial-content": $data.userEmails,
        "focus-on-mount": true,
        type: $data.TinyMceBoxTypes.COMMENT
      }, null, 8 /* PROPS */, ["initial-content", "type"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text"])) : _createCommentVNode("v-if", true), $options.isUserRightsTableNewEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    class: "main-title bg-white",
    title: $data.translate.userAdministration
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_risma_button, {
    text: $data.translate.createUser,
    type: "save",
    class: "mr-2",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.toggleModal(-1, 'create');
    })
  }, null, 8 /* PROPS */, ["text"])])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    class: "main-title bg-white",
    title: $data.translate.userAdministration
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_button, {
    text: $data.translate.createUser,
    type: "save",
    class: "mr-2",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $options.toggleModal(-1, 'create');
    })
  }, null, 8 /* PROPS */, ["text"]), _createVNode(_component_risma_toggle, {
    modelValue: $data.filteringParameters.showDeactivated,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.filteringParameters.showDeactivated = $event;
    }),
    label: $data.translate.showDeactivatedUsers,
    class: "whitespace-nowrap w-fit hover:bg-gray-250 p-3 rounded-large mr-2"
  }, null, 8 /* PROPS */, ["modelValue", "label"]), _createElementVNode("div", {
    class: "cursor-pointer mr-2 flex items-center hover:bg-gray-250 p-3 rounded-large",
    onClick: _cache[4] || (_cache[4] = function () {
      return $options.buildEmails && $options.buildEmails.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    class: "mr-2",
    icon: "mail"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.sendEmailToAllUsers,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createVNode(_component_router_link, {
    to: "/settings/administration/users-view",
    class: "flex items-center hover:bg-gray-250 p-3 rounded-large mr-2"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        class: "mr-2",
        icon: "users"
      }), _createVNode(_component_risma_title, {
        title: $data.translate.expandedView,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    _: 1 /* STABLE */
  }), _withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
      return $data.filteringParameters.search = $event;
    }),
    placeholder: $data.translate.search,
    class: _normalizeClass([$data.inputBaseStyles, $data.inputInteractionStyles]),
    type: "text"
  }, null, 10 /* CLASS, PROPS */, _hoisted_4), [[_vModelText, $data.filteringParameters.search]])])])), $options.isUserRightsTableNewEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": $data.translate.users,
    "state-key": $data.stateKey,
    "preselected-page-size": 100,
    "show-search-field": true,
    api: $options.getUsersBE
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api"]))])) : $options.projects && $options.filteredUserList.length ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("table", null, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.columns, function (item, index) {
    return _openBlock(), _createElementBlock("th", {
      key: "user-rights-table-head-".concat(index),
      class: _normalizeClass(["user-rights-table-head-cell", [item.class, {
        'cursor-pointer': item.sortingKey
      }]]),
      title: item.title,
      onClick: function onClick($event) {
        return item.sortingKey && $options.sortByField(item.sortingKey);
      }
    }, [_createElementVNode("div", {
      style: _normalizeStyle(item.style),
      class: "flex h-5"
    }, [_createVNode(_component_risma_title, {
      title: item.title,
      truncate: false,
      type: "medium",
      class: "text-left"
    }, null, 8 /* PROPS */, ["title"]), item.sortingKey === $data.sortBy ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: $data.sortOrder === $data.SortDirection.ASC ? 'arrow-up' : 'arrow-down',
      class: "ml-2"
    }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true)], 4 /* STYLE */)], 10 /* CLASS, PROPS */, _hoisted_7);
  }), 128 /* KEYED_FRAGMENT */))])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedUserList, function (user, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: "user-rights-table-body-".concat(index),
      class: "group hover:bg-gray-150"
    }, [_createElementVNode("td", null, [_createElementVNode("div", {
      class: "pointer-cursor",
      onClick: function onClick($event) {
        return $options.changeActiveStatus(user.id);
      }
    }, [_createVNode(_component_feather_icon, {
      icon: user.active ? 'x' : 'check',
      class: _normalizeClass(["stroke-2 cursor-pointer", user.active ? 'text-red-50' : 'text-green-150'])
    }, null, 8 /* PROPS */, ["icon", "class"])], 8 /* PROPS */, _hoisted_8)]), _createElementVNode("td", null, [_createElementVNode("span", _hoisted_9, [user.ad_objectid ? (_openBlock(), _createElementBlock("img", _hoisted_10)) : (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: _normalizeClass([$options.getMaxIcon(user), {
        'opacity-40': !user.active
      }])
    }, null, 2 /* CLASS */))])]), _createElementVNode("td", null, [user.active ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "cursor-pointer",
      onClick: function onClick($event) {
        return $options.toggleModal(user.id);
      }
    }, _toDisplayString(user.username), 9 /* TEXT, PROPS */, _hoisted_11)) : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(user.username), 1 /* TEXT */))]), !$options.isUserPreviewModalEnabled ? (_openBlock(), _createElementBlock("td", _hoisted_13, _toDisplayString(user.fullname), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("td", _hoisted_14, [_createVNode(_component_preview_modal_link, {
      class: "hover:underline",
      activity: user,
      onDismissModal: _cache[6] || (_cache[6] = function ($event) {
        return _ctx.$emit('dismissModal');
      })
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", null, _toDisplayString(user.fullname), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity"])])), _createElementVNode("td", null, _toDisplayString(user.initials), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString(user.email), 1 /* TEXT */), $options.isPerformanceReportEnabled ? (_openBlock(), _createElementBlock("td", _hoisted_15, [_createVNode(_component_checkboxes, {
      "checkbox-size": 20,
      options: {
        checked: !!user.performance_report_access,
        disabled: !user.active
      },
      "center-checkbox": true,
      onUpdated: function onUpdated($event) {
        return $options.updateUserFields({
          id: user.id,
          field: 'performance_report_access',
          value: user.performance_report_access ? 0 : 1
        });
      }
    }, null, 8 /* PROPS */, ["options", "onUpdated"])])) : _createCommentVNode("v-if", true), _createElementVNode("td", null, [_createVNode(_component_checkboxes, {
      "checkbox-size": 20,
      options: {
        checked: !!user.legal_hub_access,
        disabled: !user.active
      },
      "center-checkbox": true,
      onUpdated: function onUpdated($event) {
        return $options.updateUserFields({
          id: user.id,
          field: 'legal_hub_access',
          value: user.legal_hub_access ? 0 : 1
        });
      }
    }, null, 8 /* PROPS */, ["options", "onUpdated"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getFilteredProjects(user.projects), function (project, projectIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: "user-rights-table-body-project-".concat(user.id, "-").concat(projectIndex),
        class: _normalizeClass(["activity-cell bg-blue-750", {
          'bg-gray-620': $data.currentOpened.columnIndex === index && $data.currentOpened.rowIndex === projectIndex && $data.currentOpened.type === 'project'
        }])
      }, [_createVNode(_component_risma_user_rights_picker, {
        readonly: !user.active,
        "project-property": project,
        "user-property": user,
        onUpdateUser: $options.changeUser,
        onError: $options.notifyError,
        "onUpdate:opened": function onUpdateOpened($event) {
          return $options.setOpened(index, projectIndex, 'project');
        }
      }, null, 8 /* PROPS */, ["readonly", "project-property", "user-property", "onUpdateUser", "onError", "onUpdate:opened"])], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("td", _hoisted_16, [_createElementVNode("input", {
      checked: user.level_admin,
      disabled: !user.active || $options.isAzureUser(user),
      type: "checkbox",
      onChange: function onChange($event) {
        return $options.updateUserModule({
          id: user.id,
          field: 'all',
          value: $event
        });
      }
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_17)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.moduleFields, function (field, fieldIndex) {
      return _openBlock(), _createElementBlock("td", {
        key: "user-rights-table-body-module-".concat(index, "-").concat(fieldIndex),
        class: _normalizeClass(["activity-cell bg-blue-750", {
          'bg-gray-620': $data.currentOpened.columnIndex === index && $data.currentOpened.rowIndex === fieldIndex && $data.currentOpened.type === 'module'
        }])
      }, [_createVNode(_component_risma_modules_rights_picker, {
        readonly: !user.active || $options.isAzureUser(user),
        user: user,
        field: field,
        onUpdateModule: $options.updateUserModule,
        "onUpdate:opened": function onUpdateOpened($event) {
          return $options.setOpened(index, fieldIndex, 'module');
        }
      }, null, 8 /* PROPS */, ["readonly", "user", "field", "onUpdateModule", "onUpdate:opened"])], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), !$options.isUserRightsTableNewEnabled && $options.filteredUserList.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString($data.translate.noRecordsMatching), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.openModal ? (_openBlock(), _createBlock(_component_user_edit_modal, {
    key: 6,
    "all-organisations": _ctx.organisations,
    information: _ctx.information,
    "show-edit-link": false,
    type: $data.type,
    onDismiss: $options.toggleModal,
    onCreateUser: $options.notifyCreateUser
  }, null, 8 /* PROPS */, ["all-organisations", "information", "type", "onDismiss", "onCreateUser"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}