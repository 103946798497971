function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { create } from '@/api/execution/initiative';
import { Colors } from '@/Colors';
import { DateFormats } from '@/constants/DateFormats';
import { clickOutside } from '@/utils/directives/clickOutside';
import { generateComputed } from '@/utils/base/generateComputed';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import RismaToggle from '@/components/Atoms/Inputs/RismaToggle';
import ToggleMenu from '@/components/Atoms/ToggleMenu/ToggleMenu';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import Notification from '@/components/Molecules/Notification';
import ActivityCustomFields from '@/components/Molecules/ActivityCustomFields';
import ActionChangeDatesModal from '@/components/Molecules/Actions/ActionChangeDatesModal';
import ActionUpdateSequenceNumberModal from '@/components/Molecules/Actions/ActionUpdateSequenceNumberModal';
import TinyMceBox from '@/components/Molecules/TinyMceBox';
import AttachmentSelector from '@/components/Organisms/AttachmentSelector/AttachmentSelector';
import { TinyMceBoxTypes } from '@/utils/base/TinyMceBoxConfigs';
import { getLocalUnixTimestamp } from '@/utils/date';
import { UserLevels } from '../../../constants/UserLevels';
export default {
  name: 'ActionDescription',
  introduction: '',
  description: 'This is the Page component for /actions/i/-/:slug/:id/description',
  token: '<action-description></action-description>',
  components: {
    RismaToggle: RismaToggle,
    DateTimePicker: DateTimePicker,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    TinyMceBox: TinyMceBox,
    Notification: Notification,
    ActivityCustomFields: ActivityCustomFields,
    InputField: InputField,
    ToggleMenu: ToggleMenu,
    AttachmentSelector: AttachmentSelector,
    ActionChangeDatesModal: ActionChangeDatesModal,
    ActionUpdateSequenceNumberModal: ActionUpdateSequenceNumberModal
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {}
    }
  },
  emits: ['deleteAction', 'archiveAction', 'notify', 'statusChanged', 'silentUpdate:attachments', 'propertyChanged'],
  data: function data() {
    return {
      TinyMceBoxTypes: TinyMceBoxTypes,
      isLoaded: false,
      showAddNumericalUnitPopUp: false,
      dateTimeOptions: {
        format: DateFormats.DATE_FORMAT,
        saveFormat: DateFormats.DATE_FORMAT,
        widgetPositioning: {
          horizontal: 'right',
          vertical: 'bottom'
        }
      },
      inherited: false,
      numericalUnits: [{
        title: 'Budget',
        date: '23-02-2017 16:16',
        amount: '2500',
        unit: 'DKK',
        total: '2,500',
        show: false
      }, {
        title: 'Forbrug',
        date: '23-02-2017 16:16',
        amount: '3500',
        unit: 'DKK',
        total: '3,500',
        show: false
      }, {
        title: 'Forventet antal timer',
        date: '23-02-2017 16:16',
        amount: '500',
        unit: 'Timer',
        total: '500',
        show: false
      }, {
        title: 'Forbrug antal timer',
        date: '23-02-2017 16:16',
        amount: '400',
        unit: 'Timer',
        total: '400',
        show: false
      }],
      defaultTitleLabel: '_titleWithPath',
      Colors: Colors,
      showDatesModal: false,
      showSequenceNumberModal: false,
      attachments: {},
      translate: getTranslate['ActionDescription']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useActivityTypesStore, {
    initiativeTypes: 'executionActivityTypes'
  })), {}, {
    isUserLocked: function isUserLocked() {
      return this.currentUser.level_e_locked === 1;
    },
    canDelete: function canDelete() {
      return this.data.currentUserRights.canDelete;
    },
    canCreate: function canCreate() {
      return !this.inputsDisabled && this.currentUser.AccessLevels.Actions.canCreate;
    },
    canCreateSubsequent: function canCreateSubsequent() {
      return !this.inputsDisabled && this.currentUser.AccessLevels.Actions.canCreateTopLevelAction;
    }
  }, generateComputed(['status', 'description', 'target', 'completionPercentage', 'comment', 'start', 'deadline', 'activityType', 'tags', 'tagIds', 'dependingOn'], {
    object: 'data',
    event: 'propertyChanged'
  })), {}, {
    archiveFeatureEnabled: function archiveFeatureEnabled() {
      return !!this.getSettingValue('feature.archived_actions_enabled');
    },
    defaultActivityType: function defaultActivityType() {
      return this.getSettingValue('feature.actions_default_activity_type_id');
    },
    currentActivityType: function currentActivityType() {
      var _this = this;
      return this.initiativeTypes.find(function (item) {
        return item.id === _this.data.activityType;
      });
    },
    timestamp: function timestamp() {
      return getLocalUnixTimestamp(this.data.changed);
    },
    inputsDisabled: function inputsDisabled() {
      return !!(this.data.closed || this.isUserLocked);
    },
    reversedActionStatus: function reversedActionStatus() {
      return this.data.closed ? 0 : 1;
    },
    changeCloseStatusTitle: function changeCloseStatusTitle() {
      var childrenNumber;
      if (!this.data || !this.data.children) {
        childrenNumber = 'all';
      } else {
        childrenNumber = this.data.children.length;
      }
      if (this.data.closed === 0) {
        return this.translate.closeActionAnd + ' ' + childrenNumber + ' ' + (childrenNumber == 1 ? this.translate.underlyingAction : this.translate.underlyingActions);
      } else if (childrenNumber !== 0) {
        return this.translate.reopenActionAnd + ' ' + childrenNumber + ' ' + (childrenNumber == 1 ? this.translate.underlyingAction : this.translate.underlyingActions);
      } else {
        return this.translate.reopenAction;
      }
    },
    toggleMenuOptions: function toggleMenuOptions() {
      var _this2 = this;
      return [{
        id: 1,
        icon: 'plus',
        style: {
          color: Colors.system.black
        },
        hidden: !this.canCreate,
        title: this.translate.createUnderlyingAction,
        action: function action() {
          var params = {
            parent: _this2.data.id,
            title: _this2.data.title
          };
          if (_this2.currentUser.level_execution <= UserLevels.PRIVILEGED) {
            params['owner'] = [_this2.currentUser.id];
          }
          if (_this2.defaultActivityType) {
            params['activityType'] = _this2.defaultActivityType;
          }
          _this2.createInitiative(params);
        }
      }, {
        id: 2,
        icon: 'plus',
        style: {
          color: Colors.system.black
        },
        hidden: !this.canCreateSubsequent,
        title: this.translate.createSubsequentActionAtThisLevel,
        action: function action() {
          var _this2$data$parent$id, _this2$data$parent;
          var params = {
            parent: (_this2$data$parent$id = (_this2$data$parent = _this2.data.parent) === null || _this2$data$parent === void 0 ? void 0 : _this2$data$parent.id) !== null && _this2$data$parent$id !== void 0 ? _this2$data$parent$id : -1,
            title: _this2.data.title
          };
          if (_this2.currentUser.level_execution <= UserLevels.PRIVILEGED) {
            params['owner'] = [_this2.currentUser.id];
          }
          if (_this2.defaultActivityType) {
            params['activityType'] = _this2.defaultActivityType;
          }
          _this2.createInitiative(params);
        }
      }, {
        id: 3,
        icon: 'copy',
        style: {
          color: Colors.system.black
        },
        hidden: !this.canCreate,
        title: this.translate.copyActionUnderlying,
        action: function action() {
          _this2.$router.push("/execution/i/-/".concat(_this2.data.id, "/copy/").concat(_this2.timestamp));
        }
      }, {
        id: 4,
        icon: 'calendar',
        style: {
          color: Colors.system.black
        },
        hidden: this.inputsDisabled,
        title: this.translate.shiftTheDatesOfThisAction,
        action: function action() {
          _this2.showDatesModal = true;
        }
      }, {
        id: 5,
        icon: 'x',
        style: {
          color: Colors.system.warning
        },
        hidden: this.hideReopenCloseAction,
        title: this.changeCloseStatusTitle,
        action: function action() {
          _this2.$emit('statusChanged', _this2.reversedActionStatus);
        }
      }, {
        id: 7,
        icon: 'x',
        style: {
          color: Colors.system.warning
        },
        hidden: !this.canDelete,
        title: this.translate.deleteActionAndAllUnderlyingActions,
        action: function action() {
          _this2.$confirm(_this2.translate.areYouSure, _this2.translate.areYouSureTheActionInclUnderlyingActions, function (res) {
            return res && _this2.$emit('deleteAction');
          }, {
            buttonOkText: _this2.translate.delete,
            buttonDismissText: _this2.translate.cancel
          });
        }
      }, {
        id: 8,
        icon: 'hash',
        style: {
          color: Colors.system.warning
        },
        hidden: this.inputsDisabled || !!this.data.parent,
        title: this.translate.updateSequenceNumber,
        action: function action() {
          _this2.showSequenceNumberModal = true;
        }
      }, {
        id: 9,
        icon: 'archive',
        style: {
          color: Colors.system.warning
        },
        hidden: !this.archiveFeatureEnabled || !!this.data.archived,
        title: this.translate.archiveActionAndAllUnderlyingActions,
        action: function action() {
          _this2.$confirm(_this2.translate.areYouSure, _this2.translate.warningActionAndAllUnderlyingActionsWillBeClosed, function (res) {
            return res && _this2.$emit('archiveAction');
          }, {
            buttonOkText: _this2.translate.archive,
            buttonDismissText: _this2.translate.cancel
          });
        }
      }];
    },
    hideReopenCloseAction: function hideReopenCloseAction() {
      return this.data.archived || this.data.parent && this.data.parent.closed;
    },
    showToggleMenu: function showToggleMenu() {
      return this.toggleMenuOptions.some(function (item) {
        return !item.hidden;
      });
    }
  }),
  watch: {
    'data.attachments': function dataAttachments() {
      this.updateAttachments();
    }
  },
  mounted: function mounted() {
    this.handleInitiative();
  },
  methods: {
    focusOnPercentageInput: function focusOnPercentageInput() {
      var _this$$refs$completio;
      var el = (_this$$refs$completio = this.$refs.completionPercentageInput) === null || _this$$refs$completio === void 0 ? void 0 : _this$$refs$completio.$el.querySelector('input');
      if (!el) return;
      el.focus();
    },
    handleInitiative: function handleInitiative() {
      this.updateAttachments();
      this.inherited = !!this.data.numericalUnitInheritance;
    },
    addDefaultTitleLabel: function addDefaultTitleLabel(list, label) {
      var _this3 = this;
      return list.map(function (item) {
        item[_this3.defaultTitleLabel] = "".concat(item[label], "  ").concat(item.title);
        return item;
      });
    },
    createInitiative: function createInitiative(params) {
      var _this4 = this;
      return create(params).then(function (response) {
        return _this4.$router.push("/actions/i/-/".concat(response.slug, "/").concat(response.id, "/description"));
      });
    },
    isFieldInOptionalFields: function isFieldInOptionalFields(field) {
      if (!this.currentActivityType) return true;
      return this.currentActivityType.optionalFields.find(function (item) {
        return item == field;
      });
    },
    toggleShowAddNumericalUnitPopUp: function toggleShowAddNumericalUnitPopUp() {
      this.showAddNumericalUnitPopUp = !this.showAddNumericalUnitPopUp;
    },
    toggleShowNumericalUnit: function toggleShowNumericalUnit(unitNumber) {
      this.numericalUnits[unitNumber].show = !this.numericalUnits[unitNumber].show;
    },
    showSuccessMoveMessage: function showSuccessMoveMessage(message) {
      if (message) {
        this.$emit('notify', {
          type: 'success',
          message: this.$trans(message)
        });
      }
    },
    onCompletePercentageBlur: function onCompletePercentageBlur(e) {
      if (e > 100) this.completionPercentage = 100;
      if (e < 0) this.completionPercentage = 0;
    },
    moveInitiatives: function moveInitiatives(start, deadline, message) {
      this.start = start;
      this.deadline = deadline;
      this.showSuccessMoveMessage(message);
    },
    updateAttachments: function updateAttachments() {
      var _this5 = this;
      this.attachments = {
        status: [],
        description: [],
        target: [],
        comment: [],
        attachments: []
      };
      this.data.attachments && this.data.attachments.forEach(function (attachment) {
        _this5.attachments[attachment.field || 'attachments'].push(attachment);
      });
    },
    getAllFiles: function getAllFiles() {
      var _this$data;
      // get all attachments as array with new elements
      var allAttachments = Object.values(this.attachments).flat();

      // prepare them in array -> sort like this.data.attachment without deleted elements and with newly created ones
      var data = (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.attachments.reduce(function (acc, next) {
        var index = allAttachments.findIndex(function (item) {
          return item.id === next.id;
        });
        if (index !== -1) {
          acc.push(next);
          allAttachments.splice(index, 1);
        }
        return acc;
      }, []);
      data = [].concat(_toConsumableArray(data), _toConsumableArray(allAttachments));
      return data;
    },
    handleFileChange: function handleFileChange(_ref) {
      var files = _ref.files,
        field = _ref.field;
      this.attachments[field] = files;
      this.$emit('silentUpdate:attachments', this.getAllFiles());
    }
  }
};