import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
export default {
  name: 'UserActionConfigurationEntry',
  components: {
    LightTabItem: LightTabItem
  },
  data: function data() {
    return {
      translate: {
        userActionsConfiguration: this.$trans('User actions configuration'),
        changelog: this.$trans('Changelog')
      }
    };
  },
  computed: {
    tabList: function tabList() {
      return [{
        url: '/settings/requirements/configuration',
        name: this.translate.userActionsConfiguration
      }, {
        url: '/settings/requirements/changelog',
        name: this.translate.changelog
      }];
    }
  }
};