import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "component-wrap flex"
};
var _hoisted_2 = {
  class: "mr-5"
};
var _hoisted_3 = {
  class: "ml-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_checkboxes = _resolveComponent("checkboxes");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("p", {
    class: "mb-3"
  }, "Label position: right; check icon: check", -1 /* HOISTED */)), _createVNode(_component_checkboxes, {
    modelValue: $data.values,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.values = $event;
    }),
    options: $data.options,
    onUpdated: $options.handleChange
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createElementVNode("p", null, "V-model result: " + _toDisplayString(JSON.stringify($data.values)), 1 /* TEXT */)]), _createElementVNode("div", null, [_cache[4] || (_cache[4] = _createElementVNode("p", {
    class: "mb-3"
  }, "Label position: left; check icon: X; size: 20", -1 /* HOISTED */)), _createVNode(_component_checkboxes, {
    modelValue: $data.values,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.values = $event;
    }),
    "label-position": "left",
    "check-icon": "x",
    "checkbox-size": 20,
    options: $data.options,
    onUpdated: $options.handleChange
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"]), _createElementVNode("p", null, "V-model result: " + _toDisplayString(JSON.stringify($data.values)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_3, [_cache[5] || (_cache[5] = _createTextVNode(" Disabled checkboxes ")), _createVNode(_component_checkboxes, {
    modelValue: $data.values,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $data.values = $event;
    }),
    options: $data.disabledOptions,
    onUpdated: $options.handleChange
  }, null, 8 /* PROPS */, ["modelValue", "options", "onUpdated"])])]);
}