function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Colors } from '@/Colors';
import InlineSelect from '@/components/Atoms/Inputs/InlineSelect';
export default {
  name: 'SelectableLegend',
  token: "<selectable-legend\n                v-model=\"adjusted by legend dataset\"\n                :applied-colors=\"colorts from scoped slot #legend from the chart\"\n                :total-dataset=\"[{label: 'dataset1', data: [1, 3]}, [{label: 'dataset2', data: [1,5,8]}]\"\n            />",
  introduction: 'Legend for charts',
  description: "Selectable legend for charts.\n    You can pass array of objects and arrays. Arrays will be considered as selectable option.\n    ",
  components: {
    InlineSelect: InlineSelect
  },
  props: {
    appliedColors: {
      type: Array,
      required: false,
      default: function _default() {
        return [Colors.notSet];
      }
    },
    modelValue: {
      type: Array,
      required: true
    },
    totalDataset: {
      type: Array,
      required: true,
      note: 'Arrays of objects or arrays. Objects will be standalone options, arrays will be selectable'
    }
  },
  emits: ['update:modelValue'],
  data: function data() {
    return {
      toggledDatasets: new Array(this.totalDataset.length).fill(true),
      selectedMultipleDatasets: new Array(this.totalDataset.length).fill(0)
    };
  },
  computed: {
    optionStyles: function optionStyles() {
      return {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: Colors.system.darkGray
      };
    },
    combinedDataset: function combinedDataset() {
      var _this = this;
      return this.totalDataset.map(function (dataset, idx) {
        var copiedDataset = _objectSpread({}, dataset);
        if (Array.isArray(dataset)) {
          copiedDataset = dataset[_this.selectedMultipleDatasets[idx]];
        }
        if (!_this.toggledDatasets[idx]) {
          copiedDataset = _objectSpread(_objectSpread({}, copiedDataset), {}, {
            data: []
          });
        }
        return copiedDataset;
      });
    }
  },
  watch: {
    combinedDataset: {
      handler: function handler() {
        this.$emit('update:modelValue', this.combinedDataset);
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function mounted() {
    this.selectLastForMultipleDataSets();
  },
  methods: {
    selectLastForMultipleDataSets: function selectLastForMultipleDataSets() {
      var _this2 = this;
      this.totalDataset.forEach(function (item, index) {
        if (Array.isArray(item)) _this2.selectedMultipleDatasets[index] = item.length - 1;
      });
    },
    toggleDataset: function toggleDataset(idx) {
      var reversedValue = !this.toggledDatasets[idx];
      this.toggledDatasets.splice(idx, 1, reversedValue);
    },
    selectMultipleDataset: function selectMultipleDataset(value, idx) {
      this.selectedMultipleDatasets.splice(idx, 1, +value);
    }
  }
};