import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 2,
  class: "pt-4"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  class: "text-base text-gray-750 font-bold"
};
var _hoisted_5 = {
  class: "grid grid-cols-2 gap-4"
};
var _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_security_groups_creation_modal = _resolveComponent("security-groups-creation-modal");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_ad_settings = _resolveComponent("ad-settings");
  var _component_tab_list = _resolveComponent("tab-list");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  var _component_user_synchronisation = _resolveComponent("user-synchronisation");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createBlock(_component_main_well, {
    class: "w-full"
  }, {
    default: _withCtx(function () {
      return [$data.showCreationModal ? (_openBlock(), _createBlock(_component_security_groups_creation_modal, {
        key: 0,
        "modal-data": _ctx.selectedGroup,
        onCreate: $options.handleGroupCreation,
        onDismiss: $options.handleDismissCreation,
        onUpdate: $options.handleGroupUpdate
      }, null, 8 /* PROPS */, ["modal-data", "onCreate", "onDismiss", "onUpdate"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.singleSignonSettings
      }, null, 8 /* PROPS */, ["title"]), $data.pageIsLoaded ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createVNode(_component_ad_settings, {
        "initial-client-secret": $data.initialClientSecret,
        "onUpdated:clientSecret": _cache[0] || (_cache[0] = function ($event) {
          return $options.getSecurityGroups(true);
        })
      }, null, 8 /* PROPS */, ["initial-client-secret"]), $options.isUserSynchronisationEnabled ? (_openBlock(), _createBlock(_component_tab_list, {
        key: 0,
        tabs: $options.tabs,
        "background-class": "bg-zinc-50",
        class: "flex mt-4",
        onUpdate: _cache[1] || (_cache[1] = function ($event) {
          return $data.currentTab = $event;
        })
      }, null, 8 /* PROPS */, ["tabs"])) : _createCommentVNode("v-if", true), $data.isLoading ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.currentTab === $options.tabs[0].slotName || !$options.isUserSynchronisationEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_button, {
        text: $data.translate.create,
        type: "save",
        class: "mb-4",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $data.showCreationModal = true;
        })
      }, null, 8 /* PROPS */, ["text"]), _createVNode(_component_static_table, {
        "dataset-table": $options.dataset,
        columns: $options.columns,
        theme: "zebra"
      }, {
        access: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [slotData.isEmpty ? (_openBlock(), _createElementBlock("span", _hoisted_3)) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(slotData.projects, function (project, key) {
            return _openBlock(), _createElementBlock("div", {
              key: key,
              class: "mb-4 min-w-56"
            }, [_createElementVNode("span", _hoisted_4, _toDisplayString(project.title), 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project.projects, function (item) {
              return _openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", null, _toDisplayString(item.projectName), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString(item.access), 1 /* TEXT */)]);
            }), 256 /* UNKEYED_FRAGMENT */))]);
          }), 128 /* KEYED_FRAGMENT */)), _cache[3] || (_cache[3] = _createElementVNode("span", null, null, -1 /* HOISTED */))];
        }),
        edit: _withCtx(function (_ref2) {
          var slotData = _ref2.slotData;
          return [_createElementVNode("button", {
            class: "focus:outline-none",
            onClick: function onClick($event) {
              return $options.openUpdateModal(slotData.index);
            }
          }, [_createVNode(_component_feather_icon, {
            class: "text-blue-350 stroke-2 cursor-pointer",
            icon: "edit"
          })], 8 /* PROPS */, _hoisted_6)];
        }),
        delete: _withCtx(function (_ref3) {
          var slotData = _ref3.slotData;
          return [_createVNode(_component_feather_icon, {
            icon: "x",
            class: "cursor-pointer text-red-50 h-10 ml-4",
            onClick: function onClick($event) {
              return $options.deleteGroup(slotData.id);
            }
          }, null, 8 /* PROPS */, ["onClick"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns"])])) : (_openBlock(), _createBlock(_component_user_synchronisation, {
        key: 1,
        "credentials-valid": $options.credentialsValid
      }, null, 8 /* PROPS */, ["credentials-valid"]))]))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_loading_indicator_large, {
        key: 2
      }))];
    }),
    _: 1 /* STABLE */
  });
}