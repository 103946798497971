import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = ["title", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.views, function (viewItem) {
    return _openBlock(), _createElementBlock("span", {
      key: viewItem.name,
      title: _ctx.$trans(viewItem.title),
      class: _normalizeClass(["view-item cursor-pointer mr-3", [{
        active: $props.view === viewItem.name
      }, "view-item-".concat(viewItem.name)]]),
      onClick: function onClick($event) {
        return _ctx.$emit('update:view', viewItem.name);
      }
    }, null, 10 /* CLASS, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))]);
}