import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = {
  class: "mb-3"
};
var _hoisted_2 = {
  class: "validation-error"
};
var _hoisted_3 = {
  class: "input-field-container"
};
var _hoisted_4 = {
  key: 2,
  class: "mb-3"
};
var _hoisted_5 = {
  class: "flex justify-between p-3 pt-0"
};
var _hoisted_6 = {
  class: "w-9/2 mb-2"
};
var _hoisted_7 = {
  class: "w-9/2 mb-2"
};
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  key: 2,
  class: "mb-3"
};
var _hoisted_10 = {
  class: "table-admin-types"
};
var _hoisted_11 = {
  class: "thead-small"
};
var _hoisted_12 = {
  class: "thead-small"
};
var _hoisted_13 = {
  class: "thead-small"
};
var _hoisted_14 = {
  class: "min-w-240px"
};
var _hoisted_15 = {
  class: "table-row-custom-field"
};
var _hoisted_16 = {
  class: "font-semibold"
};
var _hoisted_17 = ["colspan"];
var _hoisted_18 = ["title", "disabled", "onClick"];
var _hoisted_19 = {
  key: 3,
  class: "mb-3"
};
var _hoisted_20 = {
  class: "not-italic font-normal text-gray-800"
};
var _hoisted_21 = {
  key: 0,
  class: "flex ml-2"
};
var _hoisted_22 = {
  key: 4
};
var _hoisted_23 = {
  class: "table-admin-types"
};
var _hoisted_24 = {
  class: "thead-small"
};
var _hoisted_25 = {
  class: "thead-small"
};
var _hoisted_26 = {
  key: 0,
  class: "thead-small"
};
var _hoisted_27 = {
  class: "table-row-custom-field"
};
var _hoisted_28 = {
  class: "font-semibold"
};
var _hoisted_29 = ["colspan"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_frameworks_selector = _resolveComponent("frameworks-selector");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_optional_fields_table = _resolveComponent("optional-fields-table");
  var _component_drag_and_drop_icon = _resolveComponent("drag-and-drop-icon");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_draggable = _resolveComponent("draggable");
  var _component_risma_toggle = _resolveComponent("risma-toggle");
  var _component_buttons_row = _resolveComponent("buttons-row");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_modal, {
    header: $options.modalHeader,
    "show-buttons": false,
    "dismiss-on-click-outside": false,
    "modal-fixed-width": "1200px",
    onDismiss: _ctx.toggleModal
  }, {
    body: _withCtx(function () {
      var _$props$fields;
      return [_createElementVNode("fieldset", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass([{
          error: $data.labelNameValidation
        }, "input-field-container"])
      }, [_createVNode(_component_input_field, {
        modelValue: $data.labelName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.labelName = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": "",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])], 2 /* CLASS */), _createElementVNode("span", _hoisted_2, _toDisplayString($data.labelNameValidation), 1 /* TEXT */)]), _createElementVNode("div", {
        class: _normalizeClass([{
          disabled: $props.isEditMode
        }, "mb-3"])
      }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
        title: $data.translate.module,
        options: _ctx.moduleTypesOptions,
        "model-value": $data.moduleType,
        disabled: $props.isEditMode,
        "onUpdate:modelValue": $options.onChangeModuleType
      }, null, 8 /* PROPS */, ["title", "options", "model-value", "disabled", "onUpdate:modelValue"])])], 2 /* CLASS */), $data.moduleType === $data.MODULES.INCIDENTS ? (_openBlock(), _createBlock(_component_frameworks_selector, {
        key: 0,
        class: "pb-3",
        "selected-options": $data.selectedFrameworkId,
        multiple: false,
        onSelected: _cache[1] || (_cache[1] = function ($event) {
          return $data.selectedFrameworkId = $event;
        })
      }, null, 8 /* PROPS */, ["selected-options"])) : _createCommentVNode("v-if", true), $data.moduleType === $data.MODULES.INCIDENTS ? (_openBlock(), _createBlock(_component_risma_select, {
        key: 1,
        title: $data.translate.defaultResponsible,
        options: $options.incidentResponsibleOptions,
        placeholder: $data.translate.select,
        "selected-options": $data.incidentResponsibleUsers,
        "label-key": "display_name",
        class: "mb-3",
        onSelected: _cache[2] || (_cache[2] = function ($event) {
          return $data.incidentResponsibleUsers = $event;
        })
      }, null, 8 /* PROPS */, ["title", "options", "placeholder", "selected-options"])) : _createCommentVNode("v-if", true), $options.isContractModule ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_collapsible_panel, {
        class: "mb-3"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: $data.translate.roleTitle,
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        content: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_input_field, {
            modelValue: $data.delegationLabels.responsible,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $data.delegationLabels.responsible = $event;
            }),
            title: "Responsible",
            type: "text",
            class: "mr-3",
            onBlur: _cache[4] || (_cache[4] = function ($event) {
              return $options.handleBlur('responsible');
            })
          }, null, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_input_field, {
            modelValue: $data.delegationLabels.accountable,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
              return $data.delegationLabels.accountable = $event;
            }),
            title: "Accountable",
            type: "text",
            onBlur: _cache[6] || (_cache[6] = function ($event) {
              return $options.handleBlur('accountable');
            })
          }, null, 8 /* PROPS */, ["modelValue"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_input_field, {
            modelValue: $data.delegationLabels.consulted,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
              return $data.delegationLabels.consulted = $event;
            }),
            title: "Consulted",
            type: "text",
            class: "mr-2",
            onBlur: _cache[8] || (_cache[8] = function ($event) {
              return $options.handleBlur('consulted');
            })
          }, null, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_input_field, {
            modelValue: $data.delegationLabels.informed,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
              return $data.delegationLabels.informed = $event;
            }),
            title: "Informed",
            type: "text",
            onBlur: _cache[10] || (_cache[10] = function ($event) {
              return $options.handleBlur('informed');
            })
          }, null, 8 /* PROPS */, ["modelValue"])])])];
        }),
        _: 1 /* STABLE */
      })])) : _createCommentVNode("v-if", true)]), _createElementVNode("fieldset", null, [$props.fields.optionalFields[$data.moduleType] && $data.moduleType !== $data.MODULES.CONTRACTS ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_collapsible_panel, {
        class: "mb-3"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: $data.translate.optionalFields,
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        content: _withCtx(function () {
          return [_createVNode(_component_checkboxes, {
            modelValue: $data.values[$data.moduleType].optional,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
              return $data.values[$data.moduleType].optional = $event;
            }),
            options: $options.optionalFieldsFiltered
          }, null, 8 /* PROPS */, ["modelValue", "options"])];
        }),
        _: 1 /* STABLE */
      })])) : _createCommentVNode("v-if", true), $props.fields.optionalFields[$data.moduleType] && $data.moduleType === $data.MODULES.CONTRACTS ? (_openBlock(), _createBlock(_component_optional_fields_table, {
        key: 1,
        modelValue: $data.values[$data.moduleType].optional,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) {
          return $data.values[$data.moduleType].optional = $event;
        }),
        class: "mb-3",
        "optional-fields": $props.fields.optionalFields[$data.moduleType]
      }, null, 8 /* PROPS */, ["modelValue", "optional-fields"])) : _createCommentVNode("v-if", true), $data.moduleType === $data.MODULES.CONTROLS && $props.fields.customFields && $props.fields.customFields.control ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_collapsible_panel, {
        class: "mb-3"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: $data.translate.customFields,
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        content: _withCtx(function () {
          return [_createElementVNode("table", _hoisted_10, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_cache[17] || (_cache[17] = _createElementVNode("th", null, null, -1 /* HOISTED */)), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
            title: $data.translate.title,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
            title: $data.translate.fieldType,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_11, [_createVNode(_component_risma_title, {
            title: $data.translate.dontShow,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_12, [_createVNode(_component_risma_title, {
            title: $data.translate.descriptionPage,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_13, [_createVNode(_component_risma_title, {
            title: $data.translate.completionWindow,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_14, [_createVNode(_component_risma_title, {
            title: $data.translate.visibleTo,
            truncate: false,
            placeholder: $data.translate.visibleToAll,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title", "placeholder"])]), _cache[18] || (_cache[18] = _createElementVNode("th", null, null, -1 /* HOISTED */))])]), _createVNode(_component_draggable, _mergeProps({
            modelValue: $props.fields.customFields.control,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = function ($event) {
              return $props.fields.customFields.control = $event;
            }),
            tag: "tbody",
            "component-data": {
              type: 'transition'
            },
            "item-key": "id",
            handle: ".drag-icon"
          }, $data.DragOptions), {
            item: _withCtx(function (_ref) {
              var element = _ref.element;
              return [_createElementVNode("tr", _hoisted_15, [_createElementVNode("td", null, [_createVNode(_component_drag_and_drop_icon, {
                class: "drag-icon"
              })]), _createElementVNode("td", _hoisted_16, _toDisplayString(element.label), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString(_ctx.$trans($options.getCustomFieldTypeText(element.fieldType))), 1 /* TEXT */), _createElementVNode("td", {
                colspan: $data.controlsFieldsOptions.length,
                class: "table-radio-buttons"
              }, [_createVNode(_component_radio_buttons, {
                modelValue: $data.values.control.custom[element.id].activityLinkModule,
                "onUpdate:modelValue": [function ($event) {
                  return $data.values.control.custom[element.id].activityLinkModule = $event;
                }, function ($event) {
                  return $options.onControlFieldsChange($event, element.id);
                }],
                class: _normalizeClass("grid grid-cols-".concat($data.controlsFieldsOptions.length)),
                "no-unchecked-value": true,
                options: $data.controlsFieldsOptions
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "options"])], 8 /* PROPS */, _hoisted_17), _createElementVNode("td", null, [_createVNode(_component_single_select, {
                modelValue: $data.values.control.custom[element.id].visibilityUserLevel,
                "onUpdate:modelValue": function onUpdateModelValue($event) {
                  return $data.values.control.custom[element.id].visibilityUserLevel = $event;
                },
                options: $options.visibleToOptions,
                disabled: $data.values.control.custom[element.id].activityLinkModule === $data.customStates.DONT_SHOW,
                "un-sorted": true
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "disabled"])]), _createElementVNode("td", null, [_createElementVNode("button", {
                title: $data.translate.applyVisibilityToAll,
                disabled: $data.values.control.custom[element.id].activityLinkModule === $data.customStates.DONT_SHOW,
                onClick: function onClick($event) {
                  return $options.applyVisibilityToAll($data.values.control.custom[element.id].visibilityUserLevel);
                }
              }, [_createVNode(_component_feather_icon, {
                title: $data.translate.visibleToAll,
                icon: "copy",
                class: _normalizeClass(['stroke-2', $data.values.control.custom[element.id].activityLinkModule === $data.customStates.DONT_SHOW ? 'stroke-gray' : 'stroke-blue'])
              }, null, 8 /* PROPS */, ["title", "class"])], 8 /* PROPS */, _hoisted_18)])])];
            }),
            _: 1 /* STABLE */
          }, 16 /* FULL_PROPS */, ["modelValue"])])];
        }),
        _: 1 /* STABLE */
      })])) : _createCommentVNode("v-if", true), $data.moduleType === $data.MODULES.CONTROLS && $options.efficiencyAssessmentEnabled ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_collapsible_panel, {
        class: "mb-3"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: $data.translate.efficiencyAssessment,
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        content: _withCtx(function () {
          return [_createElementVNode("div", null, [_createVNode(_component_radio_buttons, {
            modelValue: $data.controlsEfficiencyType,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = function ($event) {
              return $data.controlsEfficiencyType = $event;
            }),
            "no-unchecked-value": true,
            options: $options.controlsEfficiencyOptions,
            class: "mb-5"
          }, {
            label: _withCtx(function (slotData) {
              return [_createElementVNode("span", _hoisted_20, _toDisplayString(slotData.label), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "options"]), $data.controlsEfficiencyType !== $data.ControlsEfficiencyTypes.default ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createVNode(_component_risma_toggle, {
            modelValue: $data.controlsEfficiencyMandatory,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = function ($event) {
              return $data.controlsEfficiencyMandatory = $event;
            }),
            class: "mr-2"
          }, null, 8 /* PROPS */, ["modelValue"]), _createElementVNode("span", null, _toDisplayString($data.translate.makeAssessmentMandatoryOnControlCompletion), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)])];
        }),
        _: 1 /* STABLE */
      })])) : _createCommentVNode("v-if", true), $options.showCustomFields && (_$props$fields = $props.fields) !== null && _$props$fields !== void 0 && (_$props$fields = _$props$fields.customFields[$data.moduleType]) !== null && _$props$fields !== void 0 && _$props$fields.length ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_collapsible_panel, {
        class: "mb-3"
      }, {
        header: _withCtx(function () {
          return [_createVNode(_component_risma_title, {
            title: $data.translate.customFields,
            type: "medium"
          }, null, 8 /* PROPS */, ["title"])];
        }),
        content: _withCtx(function () {
          return [_createElementVNode("table", _hoisted_23, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_cache[19] || (_cache[19] = _createElementVNode("th", null, null, -1 /* HOISTED */)), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
            title: $data.translate.title,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", null, [_createVNode(_component_risma_title, {
            title: $data.translate.fieldType,
            truncate: false,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_24, [_createVNode(_component_risma_title, {
            title: $data.translate.dontShow,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_25, [_createVNode(_component_risma_title, {
            title: $data.translate.active,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])]), $options.hasRequiredCustomFields ? (_openBlock(), _createElementBlock("th", _hoisted_26, [_createVNode(_component_risma_title, {
            title: $data.translate.required,
            type: "small",
            class: "text-left"
          }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true)])]), _createVNode(_component_draggable, _mergeProps({
            modelValue: $props.fields.customFields[$data.moduleType],
            "onUpdate:modelValue": _cache[16] || (_cache[16] = function ($event) {
              return $props.fields.customFields[$data.moduleType] = $event;
            }),
            tag: "tbody",
            "item-key": "id",
            "component-data": {
              type: 'transition'
            },
            handle: ".drag-icon"
          }, $data.DragOptions), {
            item: _withCtx(function (_ref2) {
              var element = _ref2.element;
              return [_createElementVNode("tr", _hoisted_27, [_createElementVNode("td", null, [_createVNode(_component_drag_and_drop_icon, {
                class: "drag-icon"
              })]), _createElementVNode("td", _hoisted_28, _toDisplayString(element.label), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString(_ctx.$trans($options.getCustomFieldTypeText(element.fieldType))), 1 /* TEXT */), _createElementVNode("td", {
                colspan: $options.customFieldsOptions.length,
                class: "table-radio-buttons"
              }, [_createVNode(_component_radio_buttons, {
                modelValue: $data.values[$data.moduleType].custom[element.id].activityLinkModule,
                "onUpdate:modelValue": function onUpdateModelValue($event) {
                  return $data.values[$data.moduleType].custom[element.id].activityLinkModule = $event;
                },
                class: _normalizeClass("grid grid-cols-".concat($options.customFieldsOptions.length)),
                "no-unchecked-value": true,
                options: $options.customFieldsOptions
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "options"])], 8 /* PROPS */, _hoisted_29)])];
            }),
            _: 1 /* STABLE */
          }, 16 /* FULL_PROPS */, ["modelValue"])])];
        }),
        _: 1 /* STABLE */
      })])) : _createCommentVNode("v-if", true)]), _createVNode(_component_buttons_row, {
        class: "actions",
        data: $options.buttonsRowData
      }, null, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"])]);
}