export var htmlLegendPlugin = {
  id: 'htmlLegend',
  afterInit: function afterInit(chart, args, options) {
    var legendContainer = document.getElementById(options.containerId);
    legendContainer.onclick = function (event) {
      var dataIndex = event.target.dataset.index || event.target.parentNode.dataset.index;
      if (dataIndex === undefined) return;
      chart.toggleDataVisibility(dataIndex);
      chart.update();
      options.callback(dataIndex);
    };
  }
};
export var xAxisInteractionPlugin = function xAxisInteractionPlugin(clickCallback) {
  var xPosition = 0;
  var mousemoveScale = undefined;
  var PADDING_RIGHT = 10;
  return {
    id: 'xAxisInteractionPlugin',
    beforeDraw: function beforeDraw(chart) {
      var ctx = chart.ctx,
        y = chart.scales.y;
      var right = y.right;
      ctx.save();
      var tickPosition = Math.round(mousemoveScale);
      ctx.beginPath();
      ctx.moveTo(xPosition, y.getPixelForValue(tickPosition) + PADDING_RIGHT);
      ctx.lineTo(right - PADDING_RIGHT, y.getPixelForValue(tickPosition) + PADDING_RIGHT);
      ctx.stroke();
    },
    afterEvent: function afterEvent(chart, args) {
      var ctx = chart.ctx,
        canvas = chart.canvas,
        y = chart.scales.y;
      var top = y.top,
        left = y.left,
        right = y.right,
        height = y.height,
        ticks = y.ticks;
      var actualHeight = height / ticks.length;
      var xCoordinate = args.event.x;
      var yCoordinate = args.event.y;
      if ((args.event.type === 'mousemove' || args.event.type === 'click') && !args.inChartArea) {
        for (var i = 0; i < ticks.length; i++) {
          var isCursorOverLabelFromX = xCoordinate >= left && xCoordinate >= right - ctx.measureText(ticks[i].label).width - 10 && xCoordinate <= right;
          var isCursorOverLabelFromY = yCoordinate >= top + actualHeight * i && yCoordinate <= top + actualHeight + actualHeight * i;
          if (isCursorOverLabelFromX && isCursorOverLabelFromY) {
            if (args.event.type === 'click') {
              clickCallback(i);
            }
            xPosition = right - ctx.measureText(ticks[i].label).width - 10;
            canvas.style.cursor = 'pointer';
            mousemoveScale = y.getValueForPixel(args.event.y);
            break;
          } else {
            mousemoveScale = undefined;
          }
          if (args.inChartArea) {
            canvas.style.cursor = 'default';
          }
        }
      }
      args.changed = true;
    }
  };
};