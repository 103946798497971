import { Http } from '../index';
import { handleRequestResponse } from '../httpUtils';
export function getAllTrafficLights() {
  return handleRequestResponse(Http.get('data/trafficlights'));
}
export function getTrafficLightsByModule(module) {
  return handleRequestResponse(Http.get("data/trafficlights/".concat(module)));
}
export function updateTrafficLight(data) {
  return handleRequestResponse(Http.patch("admin/trafficlights/".concat(data.id), data));
}
export function createTrafficLight(data) {
  return handleRequestResponse(Http.post('admin/trafficlights', data));
}
export function deleteTrafficLight(id) {
  return handleRequestResponse(Http.delete("admin/trafficlights/".concat(id)));
}