import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "flex flex-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_badge_item = _resolveComponent("badge-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item) {
    return _openBlock(), _createBlock(_component_badge_item, {
      key: item,
      title: item,
      class: "mr-2 mb-2"
    }, null, 8 /* PROPS */, ["title"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}