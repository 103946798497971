var _Tabs;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ObjectTypes } from '@/constants/ObjectTypes';
export var TabTypes = {
  ASSESSMENT: 'assessment',
  AWARENESS: 'awareness',
  CHANGELOG: 'changelog',
  DATAFLOW: 'dataFlow',
  DESCRIPTION: 'description',
  GAPLINKS: 'gapLinks',
  GAPANALYSIS: 'gapAnalysis',
  HISTORY: 'history',
  LINKS: 'links',
  QUESTIONNAIRE: 'questionnaire',
  DPM: 'dpm',
  AUDIT: 'audit',
  AUDIT_QUESTIONNAIRE: 'auditQuestionnaire',
  CONTRACT_PARTY: 'party',
  RELATED_CONTRACTS: 'relate',
  DOCUMENTS: 'documents',
  CONTACTS: 'contacts',
  RIGHTS: 'rights',
  PAYMENTS: 'payments'
};
export var LinkTabTitles = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ObjectTypes.EXECUTION_INITIATIVE, 'Actions'), ObjectTypes.CONTROLS_CONTROL, 'Control'), ObjectTypes.COMPLIANCE_PROCESS_TREE_NODE, 'Node'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_CONTROLLER, 'Asset'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_PROCESSOR, 'Asset'), ObjectTypes.COMPLIANCE_INFORMATION_ASSET_SYSTEM, 'Asset'), ObjectTypes.INFORMATION_ASSET, 'Asset'), ObjectTypes.RISK_RISK, 'Risk'), ObjectTypes.COMPLIANCE_NODE, 'Node'), ObjectTypes.COMPANY, 'Company');
export var Tabs = (_Tabs = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_Tabs, TabTypes.DESCRIPTION, {
  url: 'description',
  label: 'Home'
}), TabTypes.QUESTIONNAIRE, {
  url: 'questionnaire',
  label: 'Questionnaire'
}), TabTypes.HISTORY, {
  url: 'history',
  label: 'History'
}), TabTypes.ASSESSMENT, {
  url: 'assessment',
  label: 'Assessment'
}), TabTypes.GAPANALYSIS, {
  url: 'gap',
  label: 'Gap analysis'
}), TabTypes.GAPLINKS, {
  url: 'gap',
  label: 'Gaps'
}), TabTypes.DATAFLOW, {
  url: 'dataflow',
  label: 'Data flow',
  style: 'transform scale-x-100 -scale-y-100 rotate-90'
}), TabTypes.CHANGELOG, {
  url: 'changelog',
  label: 'Changelog'
}), TabTypes.LINKS, {
  url: 'links',
  label: 'Links'
}), TabTypes.DPM, {
  url: 'dpm',
  label: 'Data Processor Management'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_Tabs, TabTypes.AUDIT, {
  url: 'dpm',
  label: 'Audit'
}), TabTypes.AUDIT_QUESTIONNAIRE, {
  url: 'dpm',
  label: 'Send audit'
}), TabTypes.CONTRACT_PARTY, {
  url: 'party',
  label: 'Contract party'
}), TabTypes.RELATED_CONTRACTS, {
  url: 'related',
  label: 'Related contracts'
}), TabTypes.DOCUMENTS, {
  url: 'documents',
  label: 'Documents'
}), TabTypes.AWARENESS, {
  url: 'awareness',
  label: 'Awareness'
}), TabTypes.CONTACTS, {
  url: 'contacts',
  label: 'Contacts'
}), TabTypes.RIGHTS, {
  url: 'rights',
  label: 'Rights'
}), TabTypes.PAYMENTS, {
  url: 'payments',
  label: 'Payments'
}));
export var DepRelationsTabDelegation = function DepRelationsTabDelegation() {
  for (var _len = arguments.length, conditions = new Array(_len), _key = 0; _key < _len; _key++) {
    conditions[_key] = arguments[_key];
  }
  return {
    name: 'Delegation',
    slotName: 'delegation',
    isEnabled: !conditions.length || conditions.some(function (value) {
      return value;
    })
  };
};
export var DepRelationsTabDetails = function DepRelationsTabDetails() {
  for (var _len2 = arguments.length, conditions = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    conditions[_key2] = arguments[_key2];
  }
  return {
    name: 'Details',
    slotName: 'details',
    isEnabled: !conditions.length || conditions.some(function (value) {
      return value;
    })
  };
};
export var TabsDepRelations = function TabsDepRelations() {
  return [DepRelationsTabDelegation(), DepRelationsTabDetails()];
};