import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "w-80"
};
var _hoisted_2 = {
  class: "rounded-full p-1 bg-blue-750 text-white"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_selector = _resolveComponent("selector");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_selector, {
    items: $data.items,
    title: 'Add new users',
    "search-placeholder": 'Search users',
    "search-fields": ['name'],
    class: "mb-6",
    onSelected: $options.selected
  }, {
    displayedName: _withCtx(function (slotProps) {
      return [_createElementVNode("p", null, [_createElementVNode("span", _hoisted_2, _toDisplayString(slotProps.item.initials), 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(slotProps.item.fullname), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["items", "onSelected"])]);
}