import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_attachment_dropdown = _resolveComponent("attachment-dropdown");
  var _component_edit_attachment = _resolveComponent("edit-attachment");
  var _component_attachment_modal = _resolveComponent("attachment-modal");
  var _component_attachment_versions_modal = _resolveComponent("attachment-versions-modal");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["attachment-button attachments relative flex items-center h-54px p-4 border-b-2 text-sm font-medium text-rGrey-800 hover:text-rGrey-900 hover:bg-rGrey-100 cursor-pointer", {
      'text-rGrey-600 hover:text-rGrey-600 cursor-default pointer-events-none': !$options.isToggleDropdownActive,
      'text-brand-500 hover:text-brand-500 open': $data.isDropdownOpen
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.isToggleDropdownActive ? $data.isDropdownOpen = !$data.isDropdownOpen : null;
    })
  }, [_createElementVNode("span", null, _toDisplayString($options.attachmentTitle), 1 /* TEXT */), _createElementVNode("span", {
    class: _normalizeClass(["files-number h-5 min-w-20px ml-2 px-1 rounded-sm text-[13px] font-medium text-center text-rGrey-800 bg-rGrey-300", {
      'text-rGrey-600': !$options.isToggleDropdownActive
    }])
  }, _toDisplayString($options.filteredFilesList.length), 3 /* TEXT, CLASS */)], 2 /* CLASS */)]), $data.isDropdownOpen ? (_openBlock(), _createBlock(_component_attachment_dropdown, {
    key: 0,
    "files-list": $options.sortedFilesList,
    "read-only": $props.readOnly,
    "has-version-buttons": $props.hasVersionButtons,
    "add-link-button-enabled": $props.filesAndLinksSeparated,
    "edit-file-enabled": $props.editFileEnabled,
    "scroll-left": $props.scrollLeft,
    onTriggerModal: _cache[1] || (_cache[1] = function ($event) {
      return $options.toggleModal($event, true);
    }),
    onOnDelete: $options.openConfirmModal,
    onOnCreateNewVersion: $options.onCreateNewVersion,
    onOnShowVersions: $options.onShowVersions,
    onOnAttachmentEdit: $options.openEditModal,
    onOnUnpin: $options.togglePin
  }, null, 8 /* PROPS */, ["files-list", "read-only", "has-version-buttons", "add-link-button-enabled", "edit-file-enabled", "scroll-left", "onOnDelete", "onOnCreateNewVersion", "onOnShowVersions", "onOnAttachmentEdit", "onOnUnpin"])) : _createCommentVNode("v-if", true)]), $data.isEditModalActive ? (_openBlock(), _createBlock(_component_edit_attachment, {
    key: 0,
    "edited-file-data": $data.editedFile,
    "type-options": $props.typeOptions,
    "file-types-enabled": $props.fileTypesEnabled,
    onEditFile: $options.onFileEdit,
    onDismissEdit: _cache[2] || (_cache[2] = function ($event) {
      return $data.isEditModalActive = false;
    })
  }, null, 8 /* PROPS */, ["edited-file-data", "type-options", "file-types-enabled", "onEditFile"])) : _createCommentVNode("v-if", true), $data.isModalOpen ? (_openBlock(), _createBlock(_component_attachment_modal, {
    key: 1,
    "server-error-message": _ctx.attachFileErrorMessage,
    "single-file-load": $props.singleFileLoad,
    "has-pin": $props.hasPin,
    "pinned-by-default": $data.pinnedByDefault,
    "has-title": $props.hasTitle,
    "type-options": $props.typeOptions,
    "document-type-id": $data.documentType,
    "is-apply-disabled": $data.isApplyDisabled,
    "modal-mode": $data.attachmentModalMode,
    onUpload: $options.handleAttachmentsNew,
    onDismiss: $options.closeAttachmentModal
  }, null, 8 /* PROPS */, ["server-error-message", "single-file-load", "has-pin", "pinned-by-default", "has-title", "type-options", "document-type-id", "is-apply-disabled", "modal-mode", "onUpload", "onDismiss"])) : _createCommentVNode("v-if", true), $data.isVersionsModalOpen ? (_openBlock(), _createBlock(_component_attachment_versions_modal, {
    key: 2,
    file: $data.file,
    "add-mode": $data.fileVersionsAddNewMode,
    "type-options": $props.typeOptions,
    "read-only": $props.readOnly,
    "is-apply-disabled": $data.isApplyDisabled,
    onUpload: _cache[3] || (_cache[3] = function ($event) {
      return $options.handleAttachmentsNew($event, true);
    }),
    onOnDelete: $options.openConfirmModal,
    onDismiss: $options.dismissVersionModal
  }, null, 8 /* PROPS */, ["file", "add-mode", "type-options", "read-only", "is-apply-disabled", "onOnDelete", "onDismiss"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hide]]);
}