import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "data-lane flex ml-5/100 mr-5/100 mb-0"
};
var _hoisted_2 = {
  class: "w-1/4"
};
var _hoisted_3 = {
  class: "ml-100px flex flex-grow"
};
var _hoisted_4 = {
  class: "ml-100px w-3/10 flex"
};
var _hoisted_5 = {
  class: "bg-white w-1/4 min-h-40px relative flex items-center"
};
var _hoisted_6 = {
  class: "w-10/12 float-left text-left"
};
var _hoisted_7 = {
  class: "inline-block w-3/4"
};
var _hoisted_8 = {
  key: 2,
  class: "ml-5/100 mr-5/100 text-left mt-5"
};
var _hoisted_9 = {
  class: "flex mt-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_data_type_legal_basis_creation_modal = _resolveComponent("data-type-legal-basis-creation-modal");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_single_select = _resolveComponent("single-select");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_risma_select = _resolveComponent("risma-select");
  var _component_text_box = _resolveComponent("text-box");
  var _component_data_flow_datatable = _resolveComponent("data-flow-datatable");
  return _openBlock(), _createElementBlock("div", null, [$data.showDataTypeLegalBasisModal ? (_openBlock(), _createBlock(_component_data_type_legal_basis_creation_modal, {
    key: 0,
    flows: $data.subFlows,
    questions: $props.questions,
    onAccept: $options.updateLegalBasisRelation,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showDataTypeLegalBasisModal = false;
    })
  }, null, 8 /* PROPS */, ["flows", "questions", "onAccept"])) : _createCommentVNode("v-if", true), $data.showChangeSystemTitleModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $data.translate.updateTitle,
    "button-dismiss-text": $data.translate.cancel,
    "header-truncate": false,
    onAccept: $options.onChangeSystemTitle,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showChangeSystemTitleModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", null, [$data.emptySystemTitleError ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        closeable: false,
        type: "error"
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.emptySystemTitleError), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_risma_title, {
        title: $data.translate.enterNewHeadlineForSystems,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_input_field, {
        modelValue: $data.newSystemTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.newSystemTitle = $event;
        }),
        modelModifiers: {
          trim: true
        },
        placeholder: $data.translate.enterTitle,
        "focus-on-mount": true,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_title, {
    class: "break-words text-left",
    truncate: false,
    title: $data.dataFlowStakeholdersTitle
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
    class: "w-3/10 text-left",
    title: $data.translate.dataTypes
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_risma_title, {
    editable: false,
    title: $options.dataFlowSystemsTitle,
    class: "mr-2"
  }, null, 8 /* PROPS */, ["title"]), !$props.isProjectLocked ? (_openBlock(), _createBlock(_component_feather_icon, {
    key: 0,
    width: "20",
    height: "20",
    class: "ml-2 cursor-pointer fill-primaryDark self-center",
    icon: "edit-2",
    onClick: $options.showSystemTitleChangeModal
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]), $options.showComments ? (_openBlock(), _createBlock(_component_risma_title, {
    key: 0,
    class: "ml-6",
    title: $data.translate.comments
  }, null, 8 /* PROPS */, ["title"])) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", {
    class: _normalizeClass(['relative flex items-start justify-center ml-5/100 mr-5/100', {
      'ml-32': !$options.showComments
    }])
  }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_single_select, {
    options: $options.stakeholdersOptions,
    "model-value": $props.flow.stakeholder.uniqueId,
    disabled: $props.isProjectLocked,
    "un-sorted": true,
    "onUpdate:modelValue": $options.onChangeStakeholder
  }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "onUpdate:modelValue"])]), !$props.isProjectLocked ? (_openBlock(), _createBlock(_component_toggle_menu, {
    key: 0,
    class: "w-1/6 py-1 border-2 rounded-md border-gray-350 h-full font-normal flex justify-center",
    "menu-style": "minimal",
    side: "right",
    options: $options.popUpActions
  }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.subFlows, function (subFlow, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx,
      class: "lane-column relative ml-100px flex"
    }, [_createVNode(_component_risma_select, {
      class: _normalizeClass([$options.showComments ? 'w-3/10 min-w-3/10' : 'w-5/12 min-w-5/12', "arrow-line"]),
      options: $props.dataTypes,
      "selected-options": subFlow.dataTypes,
      "max-visible-tags": 1000,
      disabled: $props.isProjectLocked,
      "is-selected-sorted": true,
      "label-key": "text",
      "track-by": "uniqueId",
      "selected-label": "",
      "select-label": "",
      "deselect-label": "",
      onSelected: function onSelected($event) {
        return $options.updateSingleFlow($event, 'dataTypes', subFlow.id);
      }
    }, null, 8 /* PROPS */, ["class", "options", "selected-options", "disabled", "onSelected"]), !($options.flowDirection === 'left' && idx > 0) ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass("datatype-arrow arrow-".concat($options.flowDirection))
    }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
      class: _normalizeClass(["relative ml-100px", $options.showComments ? 'w-3/10 min-w-3/10' : 'w-5/12 min-w-5/12'])
    }, [_createVNode(_component_risma_select, {
      class: "arrow-line always-first-arrow-line",
      options: $options.systemsOptions,
      "selected-options": subFlow.systems,
      "max-visible-tags": 1000,
      disabled: $props.isProjectLocked,
      "is-selected-sorted": true,
      "label-key": "text",
      "track-by": "uniqueId",
      "group-values": "items",
      "group-label": "label",
      "selected-label": "",
      "select-label": "",
      "deselect-label": "",
      onSelected: function onSelected($event) {
        return $options.updateSingleFlow($event, 'systems', subFlow.id);
      }
    }, null, 8 /* PROPS */, ["options", "selected-options", "disabled", "onSelected"]), _createElementVNode("div", {
      class: _normalizeClass("system-arrow arrow-".concat($options.flowDirection))
    }, null, 2 /* CLASS */)], 2 /* CLASS */), $options.showComments && !$props.isProjectLocked ? (_openBlock(), _createBlock(_component_text_box, {
      key: 1,
      rows: 3,
      text: subFlow.comments,
      "max-length": 10000,
      class: "ml-6 w-3/10 mr-2",
      onUpdated: function onUpdated($event) {
        return $options.updateSingleFlow($event, 'comments', subFlow.id);
      }
    }, null, 8 /* PROPS */, ["text", "onUpdated"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [!$props.isProjectLocked ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      class: "cursor-pointer text-red-50",
      width: "35",
      height: "35",
      icon: "x",
      onClick: function onClick($event) {
        return $options.onDeleteDataFlow(subFlow.id);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])]);
  }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */), $options.hasDataflowWizard ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_data_flow_datatable, {
    "data-flows": $data.subFlows,
    questions: $props.questions
  }, null, 8 /* PROPS */, ["data-flows", "questions"]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
    class: "flex items-center cursor-pointer",
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $data.showDataTypeLegalBasisModal = true;
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "edit",
    class: "text-blue-750 stroke-2 mr-1"
  }), _createVNode(_component_risma_title, {
    title: $data.translate.editLegalBasisAndStoragePeriod,
    type: "small",
    class: "text-blue-750"
  }, null, 8 /* PROPS */, ["title"])])])])) : _createCommentVNode("v-if", true)]);
}