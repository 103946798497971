import LightTabItem from '@/components/Atoms/TabItem/LightTabItem';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import MainWell from '@/components/Atoms/Containers/MainWell';
export default {
  name: 'AdminAiPartner',
  components: {
    LightTabItem: LightTabItem,
    RismaTitle: RismaTitle,
    MainWell: MainWell
  },
  data: function data() {
    return {
      baseUrl: '/settings/aipartner',
      translate: {
        aiPartner: this.$trans('AI Partner'),
        aiPartnerAccess: this.$trans('AI Partner Access')
      }
    };
  },
  computed: {
    tabList: function tabList() {
      return [{
        url: "".concat(this.baseUrl, "/access"),
        name: this.translate.aiPartnerAccess
      }];
    }
  }
};