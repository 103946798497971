export default {
  name: 'CodeHighlight',
  introduction: 'A code codehighlighter',
  description: 'A code codehighlighter what show description and a code snippet, uses HLXML & syntax-highlighter libraries',
  token: "<code-hightlight :code=\"['this','is','a','test','<b>HTML<b>']\"/>",
  props: {
    code: {
      type: String,
      required: true,
      note: 'the code snippet to showcase'
    },
    type: {
      type: String,
      required: false,
      default: 'html',
      note: 'what language the code snippet is written in'
    },
    desc: {
      type: String,
      required: true,
      note: 'the description of the code'
    }
  }
};