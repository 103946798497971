// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-description[data-v-cc1dec94]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.asset-description[data-v-cc1dec94]  ul ul {
  list-style-type: circle;
}
.asset-description[data-v-cc1dec94]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.asset-description[data-v-cc1dec94]  li {
  padding-left: 0.3em;
}
[data-v-cc1dec94]  .card {
  padding: 16px;
  min-height: 175px;
  z-index: 2;
  position: relative;
  background-color: #f3f3f3;
  border: 1px solid #BEBEBE;
  margin-bottom: 30px;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.06);
}
[data-v-cc1dec94]  .card .trafficlight_placer {
  line-height: 40px;
}
[data-v-cc1dec94]  .card .header-placer {
  display: inline-block;
  margin-left: 25px;
}
.traffic-light-wrapper[data-v-cc1dec94] {
  display: flex;
}
.traffic-light[data-v-cc1dec94] {
  padding-right: 8px;
}
[data-v-cc1dec94]  .card-information-assets {
  background-image: url(/assets/svg/icon_compliance.svg);
  background-size: 100%;
  background-position: -100px 50%;
  background-repeat: no-repeat;
}
[data-v-cc1dec94]  .risma-title .big {
  font-size: 24px;
}
.asset-type[data-v-cc1dec94]::first-letter {
  text-transform: uppercase;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
