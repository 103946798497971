import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  class: "container inline risk-overview"
};
var _hoisted_2 = {
  key: 1
};
var _hoisted_3 = {
  class: "risks-overview-options mb-6"
};
var _hoisted_4 = {
  class: "flex justify-between items-center"
};
var _hoisted_5 = {
  key: 1,
  class: "flex items-center ml-24"
};
var _hoisted_6 = {
  class: "flex border-2 border-gray-350 px-3 py-2 rounded-md w-48 static-table-switcher-label"
};
var _hoisted_7 = {
  class: "pl-1 text-blue-750"
};
var _hoisted_8 = {
  key: 2,
  class: "risks-body"
};
var _hoisted_9 = {
  key: 3
};
var _hoisted_10 = {
  key: 4
};
var _hoisted_11 = {
  key: 0
};
var _hoisted_12 = {
  key: 1
};
var _hoisted_13 = {
  key: 5,
  class: "risks-empty"
};
var _hoisted_14 = {
  class: "risks-empty-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_scoring_info_box = _resolveComponent("scoring-info-box");
  var _component_risk_filters = _resolveComponent("risk-filters");
  var _component_risk_group_sort_by = _resolveComponent("risk-group-sort-by");
  var _component_list_options = _resolveComponent("list-options");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_tag_item = _resolveComponent("tag-item");
  var _component_catalogue_header = _resolveComponent("catalogue-header");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_column_switcher = _resolveComponent("risma-column-switcher");
  var _component_preview_list_tile = _resolveComponent("preview-list-tile");
  var _component_preview_list_expanded = _resolveComponent("preview-list-expanded");
  var _component_compact_card = _resolveComponent("compact-card");
  var _component_preview_list = _resolveComponent("preview-list");
  var _component_risma_table = _resolveComponent("risma-table");
  var _component_risk_creation_modal = _resolveComponent("risk-creation-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_risma_title, {
    title: "".concat($data.translate.riskOverview, " - ").concat(_ctx.periodTitle).concat($options.scoringTitle),
    class: "mb-2"
  }, null, 8 /* PROPS */, ["title"]), $options.showScoringInfoBox ? (_openBlock(), _createBlock(_component_scoring_info_box, {
    key: 0,
    scoring: $data.scoring,
    class: "mb-6"
  }, null, 8 /* PROPS */, ["scoring"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [!$options.showRismaTable ? (_openBlock(), _createBlock(_component_list_options, {
    key: 0,
    "is-expanded": $setup.isOverviewOptionsExpanded,
    onReset: $options.resetOptions,
    onToggle: $options.toggleViews
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_risk_filters, {
        organisations: _ctx.organisations,
        "organisations-selected": $data.options.filterByOrganisations,
        "parent-risks": $options.parentRisks,
        "parent-risk-selected": $data.options.filterByParentRisk,
        tags: _ctx.tags,
        "tags-selected": $data.options.filterByTags,
        search: $data.options.search,
        onUpdateOrgs: _cache[0] || (_cache[0] = function (orgsUpdated) {
          return $options.handleFilterByArray('filterByOrganisations', orgsUpdated, 'visible_name');
        }),
        onUpdatePrisk: _cache[1] || (_cache[1] = function (parentRiskIdSelected) {
          return $data.options.filterByParentRisk = parentRiskIdSelected;
        }),
        onUpdateTag: _cache[2] || (_cache[2] = function (tagsUpdated) {
          return $options.handleFilterByArray('filterByTags', tagsUpdated, 'tag');
        }),
        onUpdateSearch: _cache[3] || (_cache[3] = function (query) {
          return $data.options.search = query;
        })
      }, null, 8 /* PROPS */, ["organisations", "organisations-selected", "parent-risks", "parent-risk-selected", "tags", "tags-selected", "search"]), _createVNode(_component_risk_group_sort_by, {
        "is-erm": $options.isERM,
        "sort-by": $data.options.sortBy,
        "group-by": $data.options.groupBy,
        onUpdateGroupBy: _cache[4] || (_cache[4] = function (value) {
          return $data.options.groupBy = value;
        }),
        onUpdateSortBy: _cache[5] || (_cache[5] = function (value) {
          return $data.options.sortBy = value;
        })
      }, null, 8 /* PROPS */, ["is-erm", "sort-by", "group-by"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-expanded", "onReset", "onToggle"])) : _createCommentVNode("v-if", true), _createVNode(_component_catalogue_header, {
    "view-type": $setup.viewSelected,
    "view-types": $data.STANDARD_VIEWS,
    "overview-options-state": $setup.isOverviewOptionsExpanded,
    "is-overview-options-available": !$options.showRismaTable,
    "onUpdate:viewType": _cache[6] || (_cache[6] = function ($event) {
      return $setup.viewSelected = $event;
    }),
    "onUpdate:overviewoption": _cache[7] || (_cache[7] = function ($event) {
      return $setup.isOverviewOptionsExpanded = $event;
    })
  }, {
    actions: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [$options.userCanCreateRisk ? (_openBlock(), _createBlock(_component_risma_button, {
        key: 0,
        text: $data.translate.createNewRisk,
        type: "save",
        onClick: $options.toggleRiskCreationModal
      }, null, 8 /* PROPS */, ["text", "onClick"])) : _createCommentVNode("v-if", true), !$setup.isOverviewOptionsExpanded && $options.appliedFilters.length ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
        title: "".concat($data.translate.appliedFilters, ":"),
        type: "medium",
        class: "mr-4 shrink-0"
      }, null, 8 /* PROPS */, ["title"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.appliedFilters, function (filter) {
        return _openBlock(), _createBlock(_component_tag_item, {
          key: filter.key,
          title: filter.title,
          class: "mr-2",
          onClose: function onClose($event) {
            return $options.removeFilter(filter.key);
          }
        }, null, 8 /* PROPS */, ["title", "onClose"]);
      }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["view-type", "view-types", "overview-options-state", "is-overview-options-available"]), $options.columnSwitchVisible ? (_openBlock(), _createBlock(_component_risma_column_switcher, {
    key: 1,
    columns: $data.visibleColumns,
    "always-visible-columns": ['Title'],
    "always-visible-columns-key": "label",
    class: "pb-4 inline-flex",
    onChanged: $options.changeVisibleColumn
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_6, [_createVNode(_component_feather_icon, {
        icon: "columns",
        class: "text-blue-750"
      }), _createElementVNode("span", _hoisted_7, _toDisplayString($data.translate.columnVisibility), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "onChanged"])) : _createCommentVNode("v-if", true)])])), $data.isLoaded && $setup.viewSelected === $data.viewTypes.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.output, function (section, i) {
    return _openBlock(), _createBlock(_component_preview_list_tile, {
      key: "risks-section-".concat(section.title, "-").concat(i),
      ref_for: true,
      ref: section.title,
      activities: section.list,
      title: _ctx.$trans(section.title),
      "is-approval-flow-enabled": !!_ctx.riskProject.riskApproval,
      "cookie-hidden-name": "rismahider-risk-overview-tiles",
      "watermark-image": "url('/assets/svg/ikon_risk.svg')",
      onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
        return $options.updateFromPreviewMode($event, i);
      },
      onDeleteItem: $options.deleteRiskFromList
    }, null, 8 /* PROPS */, ["activities", "title", "is-approval-flow-enabled", "onUpdateFromPreviewMode", "onDeleteItem"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $data.isLoaded && $setup.viewSelected === $data.viewTypes.EXPANDED ? (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.output, function (section, i) {
    return _openBlock(), _createBlock(_component_preview_list_expanded, {
      key: "risks-section-".concat(section.title, "-").concat(i),
      ref_for: true,
      ref: section.title,
      title: section.title,
      activities: section.list,
      users: _ctx.activeUsers,
      organisations: _ctx.organisations,
      scoring: $data.scoring,
      period: _ctx.period,
      "is-approval-flow-enabled": !!_ctx.riskProject.riskApproval,
      "watermark-image": "url('/assets/svg/ikon_risk.svg')",
      "cookie-hidden-name": "rismahider-risk-overview-expanded",
      onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
        return $options.updateFromPreviewMode($event, i);
      },
      onDeleteItem: $options.deleteRiskFromList
    }, null, 8 /* PROPS */, ["title", "activities", "users", "organisations", "scoring", "period", "is-approval-flow-enabled", "onUpdateFromPreviewMode", "onDeleteItem"]);
  }), 128 /* KEYED_FRAGMENT */))])) : $setup.viewSelected === $data.viewTypes.COMPACT ? (_openBlock(), _createElementBlock("div", _hoisted_10, [$options.isERM ? (_openBlock(), _createElementBlock("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.output, function (section, i) {
    return _openBlock(), _createBlock(_component_preview_list, {
      key: "risks-compact-".concat(section.title, "-").concat(i),
      ref_for: true,
      ref: section.title,
      title: section.title,
      length: section.list.length,
      "cookie-hidden-name": "rismahider-risk-overview-compact"
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", null, [_createVNode(_component_compact_card, {
          activities: section.list,
          module: $data.MODULE_NAME,
          users: _ctx.activeUsers,
          organisations: _ctx.organisations,
          "is-approval-flow-enabled": !!_ctx.riskProject.riskApproval,
          "exclude-activity-visible-props": $options.hiddenColumnKeys,
          scoring: $data.scoring,
          "risk-types": $data.riskTypes,
          "visibility-switch": false,
          class: "mb-8",
          "watermark-image": "url('/assets/svg/ikon_controls.svg')",
          onUpdateFromPreviewMode: function onUpdateFromPreviewMode($event) {
            return $options.updateFromPreviewMode($event, i);
          },
          onDeleteItem: $options.deleteRiskFromList
        }, null, 8 /* PROPS */, ["activities", "module", "users", "organisations", "is-approval-flow-enabled", "exclude-activity-visible-props", "scoring", "risk-types", "onUpdateFromPreviewMode", "onDeleteItem"])])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "length"]);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [$data.isLoaded ? (_openBlock(), _createBlock(_component_risma_table, {
    key: $data.tableGeneratedKey,
    "print-title": _ctx.periodTitle,
    "state-key": $options.tableStateKey,
    "show-search-field": true,
    api: $options.getRisksBE
  }, null, 8 /* PROPS */, ["print-title", "state-key", "api"])) : _createCommentVNode("v-if", true)]))])) : _createCommentVNode("v-if", true), $data.isLoaded && (!$data.output.length || !$data.output.some(function (item) {
    var _item$list;
    return item === null || item === void 0 || (_item$list = item.list) === null || _item$list === void 0 ? void 0 : _item$list.length;
  })) && !$options.showRismaTable ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_risma_title, {
    title: $data.translate.nothingToShow,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_14, _toDisplayString($data.translate.theSelectedSearchCriteriaHaveNoResultsToDi), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $data.showRiskCreationModal ? (_openBlock(), _createBlock(_component_risk_creation_modal, {
    key: 6,
    "project-id": _ctx.projectId,
    "period-id": _ctx.periodId,
    risks: $data.unformattedRisks,
    onCreate: $options.handleCreateRisk,
    onDismiss: $options.toggleRiskCreationModal
  }, null, 8 /* PROPS */, ["project-id", "period-id", "risks", "onCreate", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}