import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import TextBox from '@/components/Atoms/Inputs/TextBox';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import { GapsTrafficLights } from '@/constants/compliance/Admin';
import QuestionTypes from '@/constants/compliance/QuestionTypes';
import { getQuestionnaireRules, saveQuestionnaireRules } from '@/api/compliance/admin';
import ProjectQuestionnaireGapSelector from '@/components/Organisms/Compliance/Admin/ProjectQuestionnaireGapSelector';
import ProjectQuestionGapSelectorMixin from '@/components/Organisms/Compliance/ProjectQuestionGapSelectorMixin';
export default {
  name: 'QuestionRules',
  introduction: 'question rules edit',
  description: 'this component uses TextBox component to edit the question rules',
  token: '<question-rules></question-rules>',
  components: {
    ProjectQuestionnaireGapSelector: ProjectQuestionnaireGapSelector,
    SingleSelect: SingleSelect,
    RismaButton: RismaButton,
    TextBox: TextBox,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Notification: Notification
  },
  mixins: [ProjectQuestionGapSelectorMixin],
  data: function data() {
    return {
      error: '',
      success: '',
      timer: 0,
      rules: '',
      answer: null,
      trafficLight: null,
      questionsLoaded: true,
      result: null,
      trafficOptions: GapsTrafficLights(this.$trans),
      translate: getTranslate['QuestionRules']()
    };
  },
  computed: {
    allQuestions: function allQuestions() {
      var _this = this;
      return this.questions.filter(function (question) {
        return _this.checkQuestionType(question);
      }).map(function (question) {
        return {
          label: "".concat(question.questionnumber, " - ").concat(question.question),
          value: question.id
        };
      });
    },
    answers: function answers() {
      var _this2 = this;
      if (!this.questions.length || !this.questionNumber) return [];
      var question = this.questions.find(function (question) {
        return question.questionnumber === _this2.questionNumber;
      });
      if (!question) return [];
      return question.options;
    },
    allAnswers: function allAnswers() {
      var result = [];
      for (var key in this.answers) {
        result.push({
          label: this.answers[key],
          value: this.answers[key]
        });
      }
      return result;
    },
    classResultSelect: function classResultSelect() {
      return {
        high: this.result === 'HIGH',
        medium: this.result === 'MEDIUM',
        low: this.result === 'LOW',
        'no-gap': this.result === 'NOGAP',
        na: this.result === 'NA'
      };
    },
    isValidForm: function isValidForm() {
      return this.questionNumber && this.answer && this.articleNumber && this.result;
    },
    parsedRules: function parsedRules() {
      if (!this.rules) return [];
      return this.rules.split('\n');
    }
  },
  watch: {
    questionnaireId: function questionnaireId() {
      this.getRules();
      this.getQuestions();
    },
    gapSchemaId: function gapSchemaId() {
      this.getRules();
      this.articleId = null;
    }
  },
  methods: {
    postRules: function postRules() {
      var _this3 = this;
      if (!(this.questionnaireId && this.gapSchemaId)) {
        this.success = '';
        this.error = this.translate.youMustSelectBothAQuestionnaireAndAGapSch;
        this.setNotificationTimer();
        return;
      }
      return saveQuestionnaireRules(this.questionnaireId, this.gapSchemaId, this.rules).then(function () {
        _this3.error = '';
        _this3.$notify({
          title: _this3.translate.saved,
          type: 'success'
        });
      }).catch(function (response) {
        _this3.success = '';
        _this3.error = response.response.errors.map(function (err) {
          return _this3.translate.validationError + ': ' + err + '<br/>\n';
        }).join('<br/>');
        _this3.setNotificationTimer();
      });
    },
    addRule: function addRule() {
      if (!this.isValidForm) {
        this.success = '';
        this.error = this.translate.youMustSelectAllQuestionAnswerGapArticle;
        this.setNotificationTimer();
        return;
      }
      if (this.parsedRules.length) this.rules += '\n';
      this.rules += "\"".concat(this.questionNumber, "\" = \"").concat(this.answer, "\" => \"").concat(this.articleNumber, "\" = TL:").concat(this.result);
    },
    setNotificationTimer: function setNotificationTimer() {
      var _this4 = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this4.success = '';
        _this4.error = '';
      }, 60000);
    },
    getRules: function getRules() {
      var _this5 = this;
      if (!this.questionnaireId || !this.gapSchemaId) {
        this.rules = '';
        return;
      }
      return getQuestionnaireRules(this.questionnaireId, this.gapSchemaId).then(function (response) {
        return _this5.rules = response.data;
      });
    },
    handleRemoveRule: function handleRemoveRule(id) {
      var deletePattern = this.parsedRules[id];
      if (id !== this.parsedRules.length - 1) {
        deletePattern += '\n';
      } else if (this.parsedRules.length !== 1) {
        deletePattern = '\n' + deletePattern;
      }
      this.rules = this.rules.replace(deletePattern, '');
    },
    checkQuestionType: function checkQuestionType(question) {
      return question.questiontype === QuestionTypes().singleChoice.id || question.questiontype === QuestionTypes().multipleChoice.id;
    }
  }
};