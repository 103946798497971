import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  class: "grid grid-cols-2 gap-x-4"
};
var _hoisted_2 = {
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "mb-3"
};
var _hoisted_4 = {
  class: "mb-3 flex items-center"
};
var _hoisted_5 = {
  key: 0,
  class: "p-2 mx-2"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  class: "glob-h3 text-rm-text ml-0.5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $options.modalHeadline,
    onAccept: $options.handleAccept,
    onDismiss: _cache[13] || (_cache[13] = function ($event) {
      return _ctx.$emit('dismiss');
    })
  }, {
    body: _withCtx(function () {
      return [$data.errorMessage ? (_openBlock(), _createBlock(_component_notification, {
        key: 0,
        class: "mb-4",
        type: "error",
        onDismiss: _cache[0] || (_cache[0] = function ($event) {
          return $data.errorMessage = '';
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$trans($data.errorMessage)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.group.objectId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.group.objectId = $event;
        }),
        placeholder: $data.translate.objectId,
        title: $data.translate.objectId,
        invalid: !!$data.errors.objectId,
        "error-help-text": $data.errors.objectId ? $data.errors.objectId : '',
        class: "mb-4",
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "title", "invalid", "error-help-text"]), _createVNode(_component_input_field, {
        modelValue: $data.group.name,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return $data.group.name = $event;
        }),
        placeholder: $data.translate.name,
        title: $data.translate.name,
        invalid: !!$data.errors.name,
        "error-help-text": $data.errors.name ? $data.errors.name : '',
        type: "text",
        class: "mb-4"
      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "title", "invalid", "error-help-text"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_single_select, {
        modelValue: $data.group.levelAdmin,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
          return $data.group.levelAdmin = $event;
        }),
        title: $data.translate.admin,
        class: "mb-4",
        options: $data.adminLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelControls,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.group.levelControls = $event;
        }),
        title: $data.translate.controls,
        class: "mb-4",
        options: $data.accessLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelExecution,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $data.group.levelExecution = $event;
        }),
        title: $data.translate.actions,
        class: "mb-4",
        options: $data.accessLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelIncidents,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
          return $data.group.levelIncidents = $event;
        }),
        title: $data.translate.incidents,
        class: "mb-4",
        options: $data.accessLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelRisk,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
          return $data.group.levelRisk = $event;
        }),
        title: $data.translate.risks,
        class: "mb-4",
        options: $data.accessLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelContracts,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
          return $data.group.levelContracts = $event;
        }),
        title: $data.translate.contracts,
        class: "mb-4",
        options: $data.accessLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelCompany,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
          return $data.group.levelCompany = $event;
        }),
        title: $data.translate.companies,
        class: "mb-4",
        options: $data.accessLevelsCompanies
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelInformationAssets,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) {
          return $data.group.levelInformationAssets = $event;
        }),
        title: _ctx.informationAssetsName,
        class: "mb-4",
        options: $data.accessLevelsBase
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createVNode(_component_single_select, {
        modelValue: $data.group.levelProcessLibrary,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) {
          return $data.group.levelProcessLibrary = $event;
        }),
        title: _ctx.processLibraryName,
        class: "mb-4",
        options: $data.accessLevels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_checkboxes, {
        "checkbox-size": 20,
        options: $options.groupedFieldOptions,
        "label-position": "left",
        onUpdated: _cache[12] || (_cache[12] = function ($event) {
          return $data.group.lockedFields = $event.isChecked;
        })
      }, null, 8 /* PROPS */, ["options"])])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.projectAccessSections, function (section, projectName) {
        return _openBlock(), _createElementBlock("div", {
          key: projectName,
          class: "my-4"
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.group[projectName], function (project, index) {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            class: "grid gap-4 grid-cols-2"
          }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_single_select, {
            options: section.options,
            "model-value": !!project.id ? project["".concat(projectName, "Id")] : project.projectId,
            disabled: !!project.id,
            "un-sorted": true,
            "onUpdate:modelValue": function onUpdateModelValue(value) {
              return project.projectId = value;
            }
          }, null, 8 /* PROPS */, ["options", "model-value", "disabled", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_single_select, {
            options: $data.accessLevels,
            "model-value": project.accessLevel,
            "un-sorted": true,
            "onUpdate:modelValue": function onUpdateModelValue(value) {
              return project.accessLevel = value;
            }
          }, null, 8 /* PROPS */, ["options", "model-value", "onUpdate:modelValue"]), !project.id ? (_openBlock(), _createElementBlock("button", _hoisted_5, [_createVNode(_component_feather_icon, {
            icon: "x",
            class: "stroke-2 cursor-pointer text-red-50",
            onClick: _withModifiers(function ($event) {
              return $options.deleteProject(projectName, index);
            }, ["prevent"])
          }, null, 8 /* PROPS */, ["onClick"])])) : _createCommentVNode("v-if", true)])]);
        }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("button", {
          class: "mb-4",
          onClick: function onClick($event) {
            return $options.createProject(projectName, section.options[0].value);
          }
        }, [_createVNode(_component_feather_icon, {
          icon: "plus",
          class: "inline-block stroke-2 align-text-bottom"
        }), _createElementVNode("span", _hoisted_7, _toDisplayString(section.label), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_6)]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onAccept"]);
}