import i18n from '@/i18n';
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import InputField from '@/components/Atoms/Inputs/InputField';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import AttachmentItem from '@/components/Atoms/Attachment/AttachmentItem';
import LoadingIndicatorSmall from '@/components/Atoms/LoadingIndicators/Small';
import Notification from '@/components/Molecules/Notification';
export default {
  name: 'AttachmentChoice',
  introduction: 'AttachmentChoice with possible add some media',
  description: 'The ability to download or provide a link with a name for the media files, as well as display the list of provided files.',
  token: "\n<attachment-choice :files=\"files\" @answered=\"fn\"/>\n// Properties looks like, preloaded files\nfiles: []\n",
  components: {
    Notification: Notification,
    LoadingIndicatorSmall: LoadingIndicatorSmall,
    InputField: InputField,
    FeatherIcon: FeatherIcon,
    RismaButton: RismaButton,
    AttachmentItem: AttachmentItem
  },
  props: {
    files: {
      type: Array,
      required: true,
      note: 'Each files is object with properties: url, mimeType and filename.'
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
      note: 'Attachments are read-only'
    }
  },
  emits: ['answered'],
  data: function data() {
    return {
      t: {
        attach: i18n.t('Attach file'),
        add: i18n.t('Add'),
        link: i18n.t('Add link'),
        name: i18n.t('Name'),
        or: i18n.t('Or')
      },
      newURL: {
        filename: '',
        url: ''
      },
      loadedFiles: [],
      fileLoad: false,
      error: null,
      deletionId: null,
      translate: getTranslate['AttachmentChoice']()
    };
  },
  computed: {
    deleteMessage: function deleteMessage() {
      var _this = this;
      if (!this.deletionId) return '';
      var file = this.loadedFiles.find(function (item) {
        return item.id === _this.deletionId;
      });
      return "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(file === null || file === void 0 ? void 0 : file.filename, "?");
    }
  },
  watch: {
    files: {
      handler: function handler(newVal) {
        if (this.fileLoad && newVal.length === 0) {
          this.error = this.translate.somethingWentWrongWhileUploading;
        }
        this.fileLoad = false;
        this.loadedFiles = newVal;
      },
      deep: true
    }
  },
  mounted: function mounted() {
    if (!this.files) {
      this.files = [];
    }
    this.loadedFiles = cloneDeep(this.files);
  },
  methods: {
    onInputFile: function onInputFile(e) {
      this.addAttachment({
        target: {
          files: e.target.files
        }
      }, 'file');
      this.$refs.inputfile.value = '';
    },
    addAttachment: function addAttachment(event, type) {
      var that = this;
      this.fileLoad = true;
      this.error = null;
      if (type === 'file') {
        var numOfFiles = Array.from(event.target.files).length;
        var loadedFiles = 0;
        var files = that.files.slice(0);
        Array.from(event.target.files).forEach(function (file) {
          var fileOBJ = {};
          fileOBJ['filename'] = file.name;
          fileOBJ['mimeType'] = file.type;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            var base64 = e.target.result.substring(e.target.result.indexOf('base64,') + 7);
            fileOBJ['content'] = base64;
            files.push(fileOBJ);
          };
          reader.onloadend = function () {
            loadedFiles = loadedFiles + 1;
            if (loadedFiles == numOfFiles) {
              that.$emit('answered', files);
            }
          };
        });
      } else if (type === 'url') {
        if (this.newURL.url === '') {
          this.error = this.translate.youNeedToGiveTheLinkAnUrl;
          this.fileLoad = false;
          return;
        }
        if (this.newURL.filename === '') {
          this.error = this.translate.youNeedToGiveTheLinkAName;
          this.fileLoad = false;
          return;
        }
        var _files = this.files.slice(0);
        var fileOBJ = {};
        fileOBJ['url'] = this.newURL.url;
        fileOBJ['filename'] = this.newURL.filename;
        _files.push(fileOBJ);
        this.newURL.url = '';
        this.newURL.filename = '';
        this.$emit('answered', _files);
      }
    },
    handleDeleteNotification: function handleDeleteNotification(fileId) {
      var _this2 = this;
      this.deletionId = fileId;
      this.$confirm(this.translate.deleteFile, this.deleteMessage, function (res) {
        return res && _this2.deleteAttachment();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    deleteAttachment: function deleteAttachment() {
      var _this3 = this;
      this.loadedFiles.forEach(function (file, key) {
        if (file.id === _this3.deletionId) _this3.loadedFiles.splice(key, 1);
      });
      this.$forceUpdate();
      this.$emit('answered', this.loadedFiles);
      this.deletionId = null;
    }
  }
};