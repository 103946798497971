// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-item[data-v-63ca7775] {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 15px;
  z-index: 2;
  background-color: #f3f3f3;
}
.card-item[data-v-63ca7775]:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: -5% 50%;
  opacity: 0.7;
}
.list-reset-styles[data-v-63ca7775]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-63ca7775]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-63ca7775]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-63ca7775]  li {
  padding-left: 0.3em;
}
.card-item[data-v-63ca7775] {
  margin: 0 15px 30px 15px;
  min-height: 165px;
  border: 1px solid #CCCCCC;
}
.card-item[data-v-63ca7775]:before {
  background-image: url(/assets/svg/ikon_incident.svg);
}
.node-header[data-v-63ca7775] {
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
}
.node-header .node-title[data-v-63ca7775] {
  flex-grow: 1;
  display: flex;
  align-items: baseline;
}
.node-header .node-title[data-v-63ca7775]  .risma-title h3 {
  cursor: pointer !important;
}
.node-status-title[data-v-63ca7775]  p {
  margin-bottom: 0;
}
.progress-libraries-body[data-v-63ca7775] {
  display: flex;
  flex-grow: 1;
}
.progress-libraries-body .progress-libraries-body-main[data-v-63ca7775] {
  width: 66%;
  border-right: 1px solid rgb(204, 204, 204);
}
.progress-libraries-body .progress-libraries-body-side[data-v-63ca7775] {
  width: 17%;
  padding: 0 8px;
}
.progress-libraries-body .progress-libraries-body-main-top[data-v-63ca7775] {
  display: flex;
  justify-content: space-between;
}
.progress-libraries-body .progress-libraries-body-main-top-column[data-v-63ca7775] {
  width: 270px;
}
.progress-libraries-body[data-v-63ca7775]  h3 {
  color: #737373;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
