import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "flex"
};
var _hoisted_2 = {
  class: "relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_report_saving_modal = _resolveComponent("report-saving-modal");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_saved_reports = _resolveComponent("saved-reports");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showSaveReportModal ? (_openBlock(), _createBlock(_component_report_saving_modal, {
    key: 0,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.showSaveReportModal = false;
    }),
    onSave: $options.saveReport
  }, null, 8 /* PROPS */, ["onSave"])) : _createCommentVNode("v-if", true), _createVNode(_component_risma_button, {
    text: $data.translate.savedReports,
    type: "util",
    class: "mr-2 my-2",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $data.savedReportsVisible = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "folder",
        class: "stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"]), $data.savedReportsVisible ? (_openBlock(), _createBlock(_component_modal, {
    key: 1,
    header: $options.savedReportsHeader,
    "show-buttons": false,
    type: "big",
    onDismiss: $options.hideSavedReports
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_saved_reports, {
        type: $props.type,
        "project-id": $options.projectId,
        onLoad: $options.onLoadReport,
        onReportShared: $options.onReportShare,
        onReportDeleted: $options.onReportDelete
      }, null, 8 /* PROPS */, ["type", "project-id", "onLoad", "onReportShared", "onReportDeleted"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "onDismiss"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_risma_button, {
    text: $data.translate.saveCurrentReport,
    type: "util",
    class: "my-2 mr-2",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $data.showSaveReportModal = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "save",
        class: "stroke-2 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text"])])]);
}