function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState, mapActions } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import Checkboxes from '@/components/Atoms/Inputs/Checkboxes';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import InputField from '@/components/Atoms/Inputs/InputField';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Notification from '@/components/Molecules/Notification';
import { patchUtilSettings as _patchUtilSettings } from '@/api/risma/settings';
import { isValidString, isEndpointValid } from '@/utils/validations';
export default {
  name: 'AdSettings',
  components: {
    RismaTitle: RismaTitle,
    InputField: InputField,
    RismaButton: RismaButton,
    Notification: Notification,
    Checkboxes: Checkboxes,
    FeatherIcon: FeatherIcon
  },
  props: {
    initialClientSecret: {
      type: String,
      default: ''
    }
  },
  emits: ['updated:client-secret'],
  data: function data() {
    return {
      tenantId: '',
      endpoint: '',
      clientId: '',
      clientSecret: '',
      appOidcEmail: '',
      errors: [],
      ignoreAdPermissions: true,
      adAuthenticationMode: false,
      isSubmitBtnDisabled: false,
      isSubmitSuccessNotificationShown: false,
      isClientSecretDisabled: false,
      data: {},
      dataUpdate: {},
      translate: getTranslate['AdSettings']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    isPreferredEndpoint: function isPreferredEndpoint() {
      // eslint-disable-next-line no-useless-escape
      var regExp = /^(https:\/\/sts.windows.net\/)[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\//;
      return regExp.test(this.endpoint);
    },
    isClientSecretValid: function isClientSecretValid() {
      var isValid = true;
      var regExp = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
      if (this.clientSecret.trim() !== '') {
        isValid = !regExp.test(this.clientSecret);
      }
      return isValid;
    }
  }),
  watch: {
    initialClientSecret: function initialClientSecret() {
      this.clientSecret = this.initialClientSecret;
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useSettingsStore, {
    loadSettings: 'load'
  })), {}, {
    init: function init() {
      this.dataUpdate = {};
      this.tenantId = this.getSettingValue('app.oidc.tenant_id');
      this.endpoint = this.getSettingValue('app.oidc.endpoint');
      this.clientId = this.getSettingValue('app.oidc.client_id');
      this.appOidcEmail = this.getSettingValue('app.oidc.email') || 'upn';
      this.ignoreAdPermissions = !!this.getSettingValue('app.ignore_ad_permissions');
      this.adAuthenticationMode = this.getSettingValue('app.authentication_mode') === 'oidc';
      this.isClientSecretDisabled = !!this.initialClientSecret;
      this.clientSecret = this.initialClientSecret || '';
      this.data = {
        adAuthenticationMode: {
          value: this.getSettingValue('app.authentication_mode') === 'oidc',
          propName: 'app.authentication_mode'
        },
        ignoreAdPermissions: {
          value: !!this.getSettingValue('app.ignore_ad_permissions'),
          propName: 'app.ignore_ad_permissions'
        },
        tenantId: {
          value: this.getSettingValue('app.oidc.tenant_id'),
          propName: 'app.oidc.tenant_id'
        },
        endpoint: {
          value: this.getSettingValue('app.oidc.endpoint'),
          checkFn: function checkFn(endpoint) {
            return isEndpointValid(endpoint);
          },
          saveWithWarning: true,
          error: this.translate.enterAValidEndpoint,
          propName: 'app.oidc.endpoint'
        },
        clientId: {
          value: this.getSettingValue('app.oidc.client_id'),
          error: this.translate.enterAValidClientId,
          checkFn: function checkFn(value) {
            return value.trim().length > 0;
          },
          propName: 'app.oidc.client_id'
        },
        appOidcEmail: {
          value: this.getSettingValue('app.oidc.email') || 'upn',
          checkFn: function checkFn(value) {
            return isValidString(value);
          },
          error: this.translate.enterAValidEmailMapping,
          propName: 'app.oidc.email'
        }
      };
    },
    update: function update() {
      var _this = this;
      var data = {};
      this.errors = [];
      this.isSubmitBtnDisabled = true;
      this.isSubmitSuccessNotificationShown = false;
      Object.keys(this.dataUpdate).forEach(function (prop) {
        var propObjData = _this.data[prop];
        if (propObjData.checkFn && !propObjData.checkFn(_this.dataUpdate[prop])) {
          _this.errors.push(propObjData);
        }
        data[_this.data[prop].propName] = _this.dataUpdate[prop];
      });
      if (this.errors.length && this.errors.some(function (error) {
        return !error.saveWithWarning;
      })) {
        this.isSubmitBtnDisabled = false;
        return;
      }
      this.patchUtilSettings(data).then(function () {
        return _this.loadSettings();
      }).then(function () {
        return _this.isSubmitSuccessNotificationShown = true;
      }).catch(function () {
        return _this.init();
      }).finally(function () {
        return _this.isSubmitBtnDisabled = false;
      });
    },
    patchUtilSettings: function patchUtilSettings(data) {
      return new Promise(function (resolve, reject) {
        _patchUtilSettings(data).then(function (response) {
          return resolve(response);
        }).catch(function (error) {
          return reject(error);
        });
      });
    },
    handleChange: function handleChange(propName, value) {
      var isPropExisted = !!this.dataUpdate[propName];
      var isPropChanged = !(this.data[propName] === value);
      if (isPropChanged) {
        this.dataUpdate[propName] = value;
      } else if (!isPropChanged && isPropExisted) {
        delete this.dataUpdate[propName];
      }
    },
    editClientSecret: function editClientSecret() {
      var _this2 = this;
      this.isClientSecretDisabled = false;
      this.clientSecret = '';
      this.$nextTick(function () {
        _this2.$refs.clientSecretInput.$el.querySelector('input').focus();
      });
    },
    cancelUpdateClientSecret: function cancelUpdateClientSecret() {
      this.isClientSecretDisabled = true;
      this.clientSecret = this.initialClientSecret;
    },
    saveClientSecret: function saveClientSecret() {
      var _this3 = this;
      if (this.clientSecret) {
        this.patchUtilSettings({
          'app.oidc.client_secret': this.clientSecret
        }).then(function () {
          _this3.isClientSecretDisabled = true;
          _this3.$emit('updated:client-secret');
          _this3.$notify({
            title: _this3.translate.settingsAreSaved,
            type: 'success'
          });
        }).catch(function () {
          return _this3.$notify({
            title: _this3.translate.somethingWentWrongWhileUploading,
            type: 'error'
          });
        });
      }
    }
  })
};