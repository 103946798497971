import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "w-full p-12"
};
var _hoisted_2 = {
  class: "xl:flex"
};
var _hoisted_3 = {
  class: "w-full h-full"
};
var _hoisted_4 = {
  class: "project-charts-wrapper grid gap-4 justify-center p-2"
};
var _hoisted_5 = {
  class: "w-full mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_doughnut_chart = _resolveComponent("risma-doughnut-chart");
  var _component_performance_card = _resolveComponent("performance-card");
  var _component_risma_line_chart = _resolveComponent("risma-line-chart");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_performance_card, {
    class: "xl:mr-10 xl:w-7/20 flex-shrink-0 xl:min-w-370px flex compliance-indicator",
    "overridden-overlay-classes": "flex border border-gray-350 bg-white",
    "overridden-export-frame-classes": "flex items-center justify-center relative",
    title: "Compliance indicator",
    "is-export-on-parent": true,
    "sub-title": "An average score of all dashboards",
    "use-default-export": false
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_doughnut_chart, {
        cover: false,
        "chart-max-width": 'calc(100% - 4rem)',
        "chart-min-width": 'calc(100% - 4rem)',
        "is-export-on-parent": true,
        datasets: $options.complianceDatasets,
        options: $options.complianceOptions
      }, null, 8 /* PROPS */, ["datasets", "options"])])];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_performance_card, {
    title: "Overview",
    "sub-title": "The current score for all dashboards",
    "overridden-overlay-classes": "border border-gray-350 bg-white",
    class: "flex-auto"
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.projectStatusDatasets, function (chart, index) {
        return _openBlock(), _createBlock(_component_risma_doughnut_chart, {
          key: index,
          class: "project-chart mx-1",
          datasets: chart.datasets,
          title: chart.title || '',
          description: chart.description || '',
          options: chart.options,
          "chart-max-width": '150px',
          "chart-min-width": '100px',
          "use-default-export": false
        }, null, 8 /* PROPS */, ["datasets", "title", "description", "options"]);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_performance_card, {
    title: "Progress",
    "overridden-overlay-classes": "border border-gray-350 bg-white",
    "monthly-daily-value": $props.monthlyDailyValue,
    "monthly-daily-options": $options.monthlyDailyOptions,
    "sub-title": "Each dashboards score over time",
    "use-default-export": false,
    onProgressTypeChanged: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('progressTypeChanged', $event);
    })
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", null, [_createVNode(_component_risma_line_chart, {
        labels: $props.performanceLineData.labels.labels,
        datasets: $props.performanceLineData.data,
        options: $options.modifiedLineChartOptions,
        "local-storage-key": $data.lineChartStorageKey
      }, null, 8 /* PROPS */, ["labels", "datasets", "options", "local-storage-key"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["monthly-daily-value", "monthly-daily-options"])])])]);
}