import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_compact_tab_item = _resolveComponent("compact-tab-item");
  var _component_compact_tabs = _resolveComponent("compact-tabs");
  var _component_main_well = _resolveComponent("main-well");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_compact_tabs, null, {
    default: _withCtx(function () {
      return [_createVNode(_component_compact_tab_item, {
        link: "/dev/kitchensink/atoms/compact-tabs"
      }, {
        default: _withCtx(function () {
          return _cache[0] || (_cache[0] = [_createTextVNode(" this is the first tab ")]);
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_compact_tab_item, {
        link: "link-to-project"
      }, {
        default: _withCtx(function () {
          return _cache[1] || (_cache[1] = [_createTextVNode("this is the second tab")]);
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_main_well, null, {
    default: _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createTextVNode("some text here")]);
    }),
    _: 1 /* STABLE */
  })]);
}