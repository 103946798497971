import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "org-selector"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_select = _resolveComponent("risma-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.availableOptions !== null ? (_openBlock(), _createBlock(_component_risma_select, _mergeProps({
    key: 0,
    title: _ctx.$trans($props.title),
    options: $props.availableOptions,
    "selected-options": $props.selectedOptions,
    placeholder: _ctx.$trans($props.placeholder),
    "max-visible-tags": $options.maxVisibleTags,
    "open-direction": $props.openDirection,
    "label-key": $props.labelKey
  }, _ctx.$attrs), null, 16 /* FULL_PROPS */, ["title", "options", "selected-options", "placeholder", "max-visible-tags", "open-direction", "label-key"])) : _createCommentVNode("v-if", true)]);
}