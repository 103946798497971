import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-8"
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_assessment_indicators = _resolveComponent("assessment-indicators");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $props.title,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, [$props.assessmentData.totalScore ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_createElementVNode("b", null, _toDisplayString($data.translate.score) + ":", 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($props.assessmentData.totalScore), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createElementVNode("b", null, _toDisplayString($data.translate.assessmentText) + ":", 1 /* TEXT */), _createElementVNode("span", {
    style: _normalizeStyle({
      color: $props.assessmentData.textColor
    })
  }, _toDisplayString($props.assessmentData.assessmentText), 5 /* TEXT, STYLE */), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.questionnaire) + ": " + _toDisplayString($props.assessmentData.questionnaireTitle) + " ", 1 /* TEXT */), _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1 /* HOISTED */)), _createTextVNode(" " + _toDisplayString($data.translate.auditSentTo) + ": " + _toDisplayString($props.assessmentData.userName) + " (" + _toDisplayString($props.assessmentData.userEmail) + ") ", 1 /* TEXT */), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1 /* HOISTED */))]), $options.assessmentIndicatorData.length ? (_openBlock(), _createBlock(_component_assessment_indicators, {
    key: 0,
    "model-value": $options.assessmentIndicatorData,
    "total-score": $props.assessmentData.totalScore
  }, null, 8 /* PROPS */, ["model-value", "total-score"])) : _createCommentVNode("v-if", true)]);
}