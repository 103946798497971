import { getTranslate } from './translate';
import VerticalMenu from '@/components/Molecules/VerticalMenu/VerticalMenu';
import { CONTRACTS_OVERVIEW } from '@/constants/routes/contracts';
export default {
  name: 'ContractsOverviewWrapper',
  description: 'Wrapper with vertical menu for contracts overview page',
  token: '<contracts-overview-wrapper/>',
  components: {
    VerticalMenu: VerticalMenu
  },
  data: function data() {
    return {
      translate: getTranslate['ContractsOverviewWrapper']()
    };
  },
  computed: {
    menuItems: function menuItems() {
      return [{
        path: CONTRACTS_OVERVIEW.MY_CONTRACTS,
        title: this.translate.myContracts
      }, {
        path: CONTRACTS_OVERVIEW.MY_COMPANIES,
        title: this.translate.myCompanies
      }, {
        path: CONTRACTS_OVERVIEW.ALL,
        title: this.translate.overview
      }, {
        path: CONTRACTS_OVERVIEW.EXPIRED,
        title: this.translate.expiredContracts
      }, {
        path: CONTRACTS_OVERVIEW.DPA,
        title: this.translate.dataProcessorAgreements
      }, {
        path: CONTRACTS_OVERVIEW.DOCUMENTS,
        title: this.translate.searchDocuments
      }, {
        path: CONTRACTS_OVERVIEW.CONTENT,
        title: this.translate.searchContent
      }];
    }
  }
};