function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapActions } from 'pinia';
import { useTrafficLights } from '@/Store/trafficLights';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import { MODULES } from '@/constants/modules';
import { ObjectTypes } from '@/constants/ObjectTypes';
export default {
  name: 'RismaTreeBranch',
  token: "<risma-tree-branch\n          :modelValue=\"item\"\n          :is-child=\"isChild\"\n          :disable-item-menu=\"disableItemMenu\"\n          :enable-add-button=\"enableAddButton\"\n          @dragStart=\"onMove\" />",
  components: {
    FeatherIcon: FeatherIcon,
    TrafficLight: TrafficLight
  },
  props: {
    modelValue: {
      required: true,
      type: Object,
      default: function _default() {},
      note: 'object data with treedata child. modelValue should have children, needed for drag and drop'
    },
    isChild: {
      required: true,
      type: Boolean,
      default: true,
      note: 'depth > 0?'
    },
    disableItemMenu: {
      type: Boolean,
      default: false,
      required: false
    },
    enableAddButton: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['dragStart'],
  data: function data() {
    return {
      objectTypes: ObjectTypes,
      isOpened: false
    };
  },
  computed: {
    trafficLightColor: function trafficLightColor() {
      var trafficLight = this.modelValue.traffic_light || this.modelValue.trafficLight;
      if (trafficLight !== undefined) {
        return {
          color: this.getTrafficLightColorByObjectTypeAndNumber(this.modelValue.objType, trafficLight)
        };
      }
      return false;
    },
    hasChildren: function hasChildren() {
      return this.modelValue.children && this.modelValue.children.length;
    },
    rightTitle: function rightTitle() {
      var item = this.modelValue;
      switch (item.objType) {
        case MODULES.CONTROLS:
          return "".concat(item.cpath, ". ").concat(item.title);
        case MODULES.EXECUTION:
          return "".concat(item.ipath, " ").concat(item.title);
        default:
          return item.title || item.name;
      }
    }
  },
  methods: _objectSpread({}, mapActions(useTrafficLights, ['getTrafficLightColorByObjectTypeAndNumber']))
};