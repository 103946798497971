import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "ml-3"
};
var _hoisted_2 = {
  key: 0,
  class: "expansion-panel-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["expansion-panel bg-rm-white", $options.classList]),
    tabindex: 0,
    onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(function ($event) {
      return $data.completeIsExpanded = !$data.completeIsExpanded;
    }, ["stop"]), ["enter"]))
  }, [_createElementVNode("div", {
    class: _normalizeClass(["expansion-panel-header flex flex-row items-center h-44px cursor-pointer", {
      'pl-4 pr-3': $props.type === $data.ExpansionTypes.DEFAULT
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $data.completeIsExpanded = !$data.completeIsExpanded;
    })
  }, [_renderSlot(_ctx.$slots, "header", {}, undefined, true), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [!$data.completeIsExpanded ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "collapsed-header", {}, undefined, true)])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }), _createVNode(_component_feather_icon, {
    icon: "chevron-down",
    width: "24",
    height: "24",
    class: _normalizeClass(["chevron ml-auto stroke-2 text-rm-grey transform duration-500 cursor-pointer", [{
      'rotate-180 text-rm-signal-grey-dark': $data.completeIsExpanded
    }]])
  }, null, 8 /* PROPS */, ["class"])], 2 /* CLASS */), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(function () {
      return [$data.completeIsExpanded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  })], 34 /* CLASS, NEED_HYDRATION */);
}