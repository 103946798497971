import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "user-rights-table-wrapper overflow-auto relative px-5"
};
var _hoisted_2 = {
  class: "user-rights-table m-auto bg-zinc-50 w-full"
};
var _hoisted_3 = {
  class: "z-2 sticky top-0 bg-zinc-50"
};
var _hoisted_4 = {
  class: "text-blue-750 align-baseline"
};
var _hoisted_5 = {
  class: "flex flex-col items-center"
};
var _hoisted_6 = {
  class: "flex flex-col items-center"
};
var _hoisted_7 = {
  class: "flex flex-col items-center"
};
var _hoisted_8 = {
  key: 0
};
var _hoisted_9 = {
  class: "flex items-center justify-center"
};
var _hoisted_10 = {
  colspan: "1"
};
var _hoisted_11 = {
  colspan: "1"
};
var _hoisted_12 = {
  colspan: "1"
};
var _hoisted_13 = {
  colspan: "1"
};
var _hoisted_14 = {
  key: 0
};
var _hoisted_15 = {
  key: 1
};
var _hoisted_16 = {
  key: 0,
  class: "flex items-center justify-center"
};
var _hoisted_17 = {
  key: 2
};
var _hoisted_18 = {
  key: 4
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_checkboxes = _resolveComponent("checkboxes");
  var _component_radio_buttons = _resolveComponent("radio-buttons");
  return _openBlock(), _createElementBlock("div", null, [$data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    onDismiss: _cache[0] || (_cache[0] = function ($event) {
      return $data.notificationMessage = '';
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("table", _hoisted_2, [_createElementVNode("thead", _hoisted_3, [_createElementVNode("tr", _hoisted_4, [_cache[3] || (_cache[3] = _createElementVNode("th", null, null, -1 /* HOISTED */)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tableHeaders, function (header) {
    return _openBlock(), _createElementBlock("th", {
      key: header.level
    }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
      class: _normalizeClass(["icon mb-2", header.icon])
    }, null, 2 /* CLASS */), _createElementVNode("div", null, _toDisplayString(header.label), 1 /* TEXT */)])]);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("th", null, [_createElementVNode("div", _hoisted_6, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "icon mb-2 locked-access-icon"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", null, _toDisplayString($data.translate.locked), 1 /* TEXT */)])]), _createElementVNode("th", null, [_createElementVNode("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "icon mb-2 admin-access-icon"
  }, null, -1 /* HOISTED */)), _createElementVNode("div", null, _toDisplayString($data.translate.admin), 1 /* TEXT */)])])])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tableBodies, function (body, idx) {
    return _openBlock(), _createElementBlock("tbody", {
      key: idx
    }, [_createElementVNode("tr", null, [_createElementVNode("td", null, [_createVNode(_component_risma_title, {
      title: body.title,
      type: "medium"
    }, null, 8 /* PROPS */, ["title"])]), _cache[4] || (_cache[4] = _createElementVNode("td", {
      colspan: "7"
    }, null, -1 /* HOISTED */))]), body.adminSetting ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [_createElementVNode("td", null, [_createVNode(_component_risma_title, {
      title: "Admin",
      type: "small"
    })]), _cache[5] || (_cache[5] = _createElementVNode("td", {
      colspan: "6"
    }, null, -1 /* HOISTED */)), _createElementVNode("td", null, [_createElementVNode("div", _hoisted_9, [(_openBlock(), _createBlock(_component_checkboxes, {
      key: $data.tableKey,
      "checkbox-size": 20,
      disabled: $options.isAzureUser,
      options: $options.getRowCheckboxOption(body.adminSetting.checked),
      onUpdated: function onUpdated($event) {
        return body.adminSetting.onAdminUpdate($event.isChecked);
      }
    }, null, 8 /* PROPS */, ["disabled", "options", "onUpdated"]))])])])) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(body.rows, function (row) {
      return _openBlock(), _createElementBlock("tr", {
        key: row.property
      }, [_createElementVNode("td", null, _toDisplayString(row.title), 1 /* TEXT */), _createElementVNode("td", _hoisted_10, [(_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly || body.locked,
        options: $data.noneOption,
        "no-unchecked-value": true,
        class: "grid grid-cols-3 place-items-center",
        onChange: function onChange($event) {
          return row.onRightsUpdate(row, $event);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onChange"]))]), _createElementVNode("td", _hoisted_11, [(_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly || body.locked,
        options: $data.normalOption,
        "no-unchecked-value": true,
        class: "grid grid-cols-3 place-items-center",
        onChange: function onChange($event) {
          return row.onRightsUpdate(row, $event);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onChange"]))]), _createElementVNode("td", _hoisted_12, [!row.isPrivilegedOptionHidden ? (_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly || body.locked,
        options: $data.privilegedOption,
        "no-unchecked-value": true,
        class: "grid grid-cols-3 place-items-center",
        onChange: function onChange($event) {
          return row.onRightsUpdate(row, $event);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onChange"])) : _createCommentVNode("v-if", true)]), _createElementVNode("td", _hoisted_13, [(_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly || body.locked && !body.isComplianceProject,
        options: $data.superOptions,
        "no-unchecked-value": true,
        class: "grid place-items-center",
        onChange: function onChange($event) {
          return row.onRightsUpdate(row, $event);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onChange"]))]), $options.supervisorFeatureEnabled && row.hasSupervisor ? (_openBlock(), _createElementBlock("td", _hoisted_14, [(_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly,
        options: $data.supervisorOption,
        "no-unchecked-value": true,
        class: "grid place-items-center",
        onChange: function onChange($event) {
          return row.onRightsUpdate(row, $data.UserLevels.SUPER, true);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onChange"]))])) : (_openBlock(), _createElementBlock("td", _hoisted_15)), _createElementVNode("td", null, [row.isLockAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_16, [row.isLockAvailable ? (_openBlock(), _createBlock(_component_checkboxes, {
        key: $data.tableKey,
        "checkbox-size": 20,
        disabled: body.locked,
        options: $options.getRowCheckboxOption(row.locked, $props.readOnly),
        onUpdated: function onUpdated($event) {
          return row.onLockUpdate(row, $event.isChecked);
        }
      }, null, 8 /* PROPS */, ["disabled", "options", "onUpdated"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]), !body.adminSetting ? (_openBlock(), _createElementBlock("td", _hoisted_17, [(_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly || body.locked,
        options: $data.adminOption,
        "no-unchecked-value": true,
        class: "grid place-items-center",
        onUpdated: function onUpdated($event) {
          return row.onAdminUpdate(row);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onUpdated"]))])) : row.isAdmin && row.access_level < $data.UserLevels.ADMIN ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 3
      }, [_createCommentVNode(" if data is corrupted "), _createElementVNode("td", null, [(_openBlock(), _createBlock(_component_radio_buttons, {
        key: $data.tableKey,
        modelValue: $data.checkedValues[row.property],
        "onUpdate:modelValue": function onUpdateModelValue($event) {
          return $data.checkedValues[row.property] = $event;
        },
        disabled: $props.readOnly,
        options: $data.adminOption,
        "no-unchecked-value": true,
        class: "grid place-items-center",
        onChange: function onChange($event) {
          return row.onRightsUpdate(row, $data.UserLevels.ADMIN, true);
        }
      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "options", "onChange"]))])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)) : (_openBlock(), _createElementBlock("td", _hoisted_18))]);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))])])]);
}