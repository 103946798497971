import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "relative"
};
var _hoisted_2 = {
  class: "truncate w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tool_tip = _resolveComponent("tool-tip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass("cursor-pointer m-2 rounded-full text-center\n      flex border-2 items-center shadow-md ".concat($props.bowStyles)),
    onMouseleave: _cache[0] || (_cache[0] = function () {
      return $options.hide && $options.hide.apply($options, arguments);
    }),
    onMouseover: _cache[1] || (_cache[1] = function () {
      return $options.show && $options.show.apply($options, arguments);
    })
  }, [_createElementVNode("p", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])], 34 /* CLASS, NEED_HYDRATION */), _createVNode(_component_tool_tip, {
    hidden: $data.hidden && !$data.clicked,
    "arrow-direction": "top",
    "tooltip-classes": "w-48"
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "toolTip")];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["hidden"])]);
}