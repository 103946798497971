function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import LinkItem from '@/components/Atoms/Containers/LinkItem';
import AttachmentModal from '@/components/Organisms/AttachmentModal';
import { AttachmentModalMixin } from '@/components/Organisms/AttachmentModalMixin';
import * as fileAPI from '@/api/risma/file';
import FilePreviewModal from '@/components/Molecules/FilePreviewModal/FilePreviewModal';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
export default {
  name: 'AttachmentSelector',
  introduction: 'AttachmentSelector is modal for choosing files & list of choosed files',
  description: "AttachmentSelector gives opportunity to choose file by AttachmentModal and shown a list of uploaded attachments.\n    Could set existed files by property 'files-existed'.\n    UpdatedFiles event will fire with all choosen files (array) previously. This event would be fired for each attempt to add attachment so be careful when updating parent object to avoid duplication of attachments",
  token: '<attachment-selector :files-existed="[{}, {}]" @updatedFiles="fn"/>',
  components: {
    FeatherIcon: FeatherIcon,
    FilePreviewModal: FilePreviewModal,
    LinkItem: LinkItem,
    AttachmentModal: AttachmentModal
  },
  mixins: [AttachmentModalMixin],
  props: {
    attachmentsExisted: {
      type: Array,
      required: false,
      note: 'Array of objects of existed attachments. Each object must contains filename, url properties.',
      default: function _default() {
        return [];
      }
    },
    ownerId: {
      type: Number,
      required: true,
      note: 'An id of owner of an attachments'
    },
    module: {
      type: String,
      required: true,
      note: 'A name of module, for example, "control" or "risk"'
    },
    moduleId: {
      type: [String, Number],
      required: false,
      default: '',
      note: 'An id of one of the entities of modules. For example, an cpath of the specific control'
    },
    remote: {
      type: Boolean,
      default: true,
      note: 'If true, the component will upload the attachments to the database instantly. Usually should be true for existing items (controls, incidents etc) and false for new (attachment would be stored locally and uploaded only after parent item creation)'
    },
    removable: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Manage removing buttons in each attachment'
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
      note: 'If not possible to add attachments'
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Gives posibillity to load multiple attachment in one'
    },
    field: {
      type: String,
      required: false,
      note: 'Field for components where more than one attachment section can exist',
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
      note: 'If false, "Attachments" title will not be shown'
    }
  },
  emits: ['updatedFiles'],
  data: function data() {
    return {
      idToDelete: null,
      files: [],
      isUploading: false,
      uploadingValue: 0,
      previewFile: null,
      isModalOpened: false,
      translate: {
        showPreview: this.$trans('Show preview'),
        attachments: this.$trans('Attachments'),
        areYouSureYouWantToDeleteThisDocument: this.$trans('Are you sure you want to delete this document?')
      }
    };
  },
  watch: {
    attachmentsExisted: function attachmentsExisted() {
      this.files = [].concat(this.attachmentsExisted);
    }
  },
  created: function created() {
    this.files = [].concat(this.attachmentsExisted);
  },
  methods: {
    toggleModal: function toggleModal() {
      this.isModalOpened = !this.isModalOpened;
    },
    addAttachmentsRemote: function addAttachmentsRemote(attachments) {
      var _this = this;
      this.isUploading = true;
      fileAPI.uploadFiles(attachments, {
        onUploadProgress: function onUploadProgress(progressEvent) {
          _this.uploadingValue = Math.round(progressEvent.loaded * 100 / progressEvent.total);
        }
      }).then(function (uploadedAttachments) {
        _this.addAttachmentLocally(uploadedAttachments);
        _this.isUploading = false;
      }).catch(function (error) {
        _this.attachFileError = true;
        _this.attachFileErrorMessage = _this.handleResponseError(error);
        _this.isUploading = false;
      });
    },
    addAttachmentLocally: function addAttachmentLocally(attachments) {
      var _this2 = this;
      attachments.forEach(function (attach) {
        var attachFormatted = Object.assign({}, attach, _objectSpread(_objectSpread({
          type: attach.mimeType ? 'file' : 'link'
        }, attach.url ? null : {
          url: '#'
        }), attach.id ? null : {
          id: _this2.files[_this2.files.length - 1] && _this2.files[_this2.files.length - 1].id ? parseInt(_this2.files[_this2.files.length - 1].id) + 1 : 1
        }));
        _this2.files.push(attachFormatted);
      });
      this.emitUpdatedFiles();
      this.toggleModal();
    },
    handleAttachmentsNew: function handleAttachmentsNew(attachmentsNew) {
      this.attachFileError = false;
      this.attachFileErrorMessage = this.defaultErrorMessage;
      if (!attachmentsNew.length) {
        return;
      }
      if (this.remote) {
        if (!this.moduleId) {
          throw new Error('The property "moduleId" is require for remote mode');
        }
      }
      var attachmentBase = {
        module: this.module,
        moduleId: this.remote ? this.moduleId : '',
        owner: this.ownerId,
        field: this.field
      };
      var attachments = attachmentsNew.map(function (attach) {
        return Object.assign({}, attachmentBase, attach);
      });
      if (this.remote) {
        this.addAttachmentsRemote(attachments);
      } else {
        this.addAttachmentLocally(attachments);
      }
    },
    removeAttachmentRemote: function removeAttachmentRemote(id) {
      return fileAPI.deleteFile(id);
    },
    removeAttachmentLocally: function removeAttachmentLocally(id) {
      this.files = this.files.filter(function (file) {
        return file.id !== id;
      });
      this.emitUpdatedFiles();
    },
    onDeleteConfirm: function onDeleteConfirm() {
      var _this3 = this;
      if (!this.removable) {
        throw new Error('The property "removable" is off');
      }
      if (this.remote) {
        this.removeAttachmentRemote(this.idToDelete).then(function () {
          return _this3.removeAttachmentLocally(_this3.idToDelete);
        }).catch(function () {
          return _this3.actionError = true;
        });
      } else {
        this.removeAttachmentLocally(this.idToDelete);
      }
    },
    remove: function remove(attachmentId) {
      var _this4 = this;
      this.idToDelete = attachmentId;
      this.$confirm('', this.translate.areYouSureYouWantToDeleteThisDocument, function (res) {
        return res && _this4.onDeleteConfirm();
      });
    },
    selectFilePreview: function selectFilePreview(file) {
      this.previewFile = {
        fileSrc: file.url,
        fileType: file.mimeType === 'application/pdf' ? 'pdf' : 'image'
      };
    },
    emitUpdatedFiles: function emitUpdatedFiles() {
      this.$emit('updatedFiles', {
        files: this.files,
        field: this.field
      });
    }
  }
};