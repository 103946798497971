function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ControlsSettings } from '@/constants/controls/ControlsDashboard';
import { replaceMultipleStringsByMultiple } from '@/utils/replaceStrings';
import { mapToSeparatorList } from '@/utils/format/mapTo';
export var StatusOverviewMixin = {
  data: function data() {
    return {
      columnsTable: ControlsSettings().pieTableColumn
    };
  },
  methods: {
    prepareTableInfoForPie: function prepareTableInfoForPie(data) {
      var _this = this;
      var columns = Array(this.columnsTable.length).fill('');
      var dataset = [];
      data.forEach(function (node, index) {
        var preparedItem = {};
        _this.columnsTable.forEach(function (column) {
          preparedItem[column.key] = _this.prepareCompactHtml(column.key, node, ++index);
        });
        dataset.push(preparedItem);
      });
      return {
        columns: columns,
        dataset: dataset
      };
    },
    prepareCompactHtml: function prepareCompactHtml(column, node, index) {
      var result = '';
      var cleanedTitle = this.cleanupTitle(node.text);
      switch (column) {
        case 'index':
          result = index;
          break;
        case 'text':
          result = {
            url: node.url,
            title: node.text,
            titleParam: node.confidential ? this.getHoverTitleWithOwners(node.owners) : cleanedTitle,
            confidential: node.confidential
          };
          break;
        default:
          result = node[column] || '';
      }
      return result;
    },
    enhanceChartDataByControlTitles: function enhanceChartDataByControlTitles(data) {
      var _this2 = this;
      if (!Array.isArray(data)) return data;
      return data.map(function (item) {
        var cleanControls = _this2.cleanControls(item.controls);
        return _objectSpread(_objectSpread({}, item), {}, {
          controls_titles: mapToSeparatorList(cleanControls, 'text', ',\n')
        });
      });
    },
    cleanControls: function cleanControls(controls) {
      var _this3 = this;
      if (!Array.isArray(controls)) return controls;
      return controls.map(function (control) {
        return _objectSpread(_objectSpread({}, control), {}, {
          text: _this3.cleanupTitle(control.text)
        });
      });
    },
    cleanupTitle: function cleanupTitle(title) {
      var map = {
        '<br/>': ' ',
        '<br>': ' ',
        '&lt;None&gt;': '<None>'
      };
      return replaceMultipleStringsByMultiple(title, map);
    },
    getHoverTitleWithOwners: function getHoverTitleWithOwners(owners) {
      var ownersNumber = owners.split(',').length;
      var note = ownersNumber > 1 ? this.$trans('are able to see and edit this activity') : this.$trans('is able to see and edit this activity');
      return "".concat(this.$trans('Only'), " ").concat(owners, " ").concat(note);
    },
    prepareChartData: function prepareChartData(key, chartData, labelField, dataField) {
      var columnColor = this.datasets[key].colors || chartData[key].map(function (item) {
        return item.color;
      });
      var data = [];
      var labels = [];
      var backgroundColor = [];
      chartData[key].forEach(function (item, idx) {
        backgroundColor.push(columnColor[idx % columnColor.length]);
        data.push(item[dataField]);
        labels.push(item[labelField]);
      });
      return {
        datasets: [{
          data: data,
          backgroundColor: backgroundColor
        }],
        labels: labels
      };
    }
  }
};