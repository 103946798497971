import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "h-fit"
};
var _hoisted_2 = {
  key: 0,
  class: "flex items-center"
};
var _hoisted_3 = {
  class: "overflow-hidden"
};
var _hoisted_4 = {
  class: "flex items-center"
};
var _hoisted_5 = {
  class: "overflow-hidden"
};
var _hoisted_6 = {
  class: "overflow-hidden"
};
var _hoisted_7 = {
  class: "flex items-center"
};
var _hoisted_8 = {
  class: "overflow-hidden"
};
var _hoisted_9 = {
  class: "overflow-hidden"
};
var _hoisted_10 = {
  class: "flex"
};
var _hoisted_11 = {
  class: "mr-12"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_router_link = _resolveComponent("router-link");
  return $props.gapList.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.enhancedGapList, function (gap, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "border border-solid border-gray-350 bg-white p-8 mb-4"
    }, [_createElementVNode("div", null, [$props.readOnly ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_traffic_light, {
      color: _ctx.getGapColor(gap.analysis_rag),
      class: "mr-3"
    }, null, 8 /* PROPS */, ["color"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_risma_title, {
      title: gap.article.article,
      type: "big"
    }, null, 8 /* PROPS */, ["title"])])])) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [!$options.isPreview ? (_openBlock(), _createBlock(_component_preview_modal_link, {
      key: 0,
      activity: gap,
      "selected-route": gap.route
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_traffic_light, {
          color: _ctx.getGapColor(gap.analysis_rag),
          class: "mr-3"
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_risma_title, {
          title: gap.article.article,
          type: "big"
        }, null, 8 /* PROPS */, ["title"])])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_title, {
          title: gap.article.articleTitle,
          type: "big",
          class: "article-title"
        }, null, 8 /* PROPS */, ["title"])])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "selected-route"])) : (_openBlock(), _createBlock(_component_router_link, {
      key: 1,
      to: gap.url,
      onClick: function onClick($event) {
        return $options.onLinkClick(gap.articleId);
      }
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("div", _hoisted_7, [_createVNode(_component_traffic_light, {
          color: _ctx.getGapColor(gap.analysis_rag),
          class: "mr-3"
        }, null, 8 /* PROPS */, ["color"]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_risma_title, {
          title: gap.article.article,
          type: "big"
        }, null, 8 /* PROPS */, ["title"])])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_risma_title, {
          title: gap.article.articleTitle,
          type: "big",
          class: "article-title"
        }, null, 8 /* PROPS */, ["title"])])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "onClick"]))], 64 /* STABLE_FRAGMENT */))]), _createElementVNode("p", null, _toDisplayString(gap.article.requirement), 1 /* TEXT */), _createVNode(_component_risma_title, {
      title: $data.translate.analysis,
      type: "medium",
      class: "gap-section-title"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(gap.analysisControlsInitiatives), 1 /* TEXT */), _createVNode(_component_risma_title, {
      title: $data.translate.riskAssessment,
      type: "medium",
      class: "gap-section-title"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(gap.analysis), 1 /* TEXT */), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_risma_title, {
      title: "".concat($data.translate.riskAssessment, " ").concat($data.translate.before),
      type: "medium",
      class: "gap-section-title"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(gap.rpi_gross), 1 /* TEXT */)]), _createElementVNode("div", null, [_createVNode(_component_risma_title, {
      title: "".concat($data.translate.riskAssessment, " ").concat($data.translate.after),
      type: "medium",
      class: "gap-section-title"
    }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", null, _toDisplayString(gap.rpi_net), 1 /* TEXT */)])])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true);
}