import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_card_compliance = _resolveComponent("card-compliance");
  var _component_load_more_wrapper = _resolveComponent("load-more-wrapper");
  var _component_preview_list = _resolveComponent("preview-list");
  return _openBlock(), _createBlock(_component_preview_list, {
    length: $props.processes.length || '0',
    title: _ctx.$trans($props.title),
    class: "process",
    "cookie-hidden-name": "hidden_cards_wrapper_compliance_my_processes",
    onChangeShowState: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('changeShowState', $event);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_load_more_wrapper, {
        activities: $props.processes
      }, {
        default: _withCtx(function (_ref) {
          var activitiesLimited = _ref.activitiesLimited;
          return [_createElementVNode("div", {
            class: _normalizeClass("processes-".concat($props.viewMode, "-list"))
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesLimited, function (process) {
            return _openBlock(), _createElementBlock("div", {
              key: "preview-process".concat(process.id),
              class: _normalizeClass("processes-".concat($props.viewMode, "-item"))
            }, [_createVNode(_component_card_compliance, {
              activity: process
            }, null, 8 /* PROPS */, ["activity"])], 2 /* CLASS */);
          }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["activities"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["length", "title"]);
}