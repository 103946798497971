import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = {
  key: 0,
  class: "text-red-50"
};
var _hoisted_3 = {
  class: "flex justify-between items-center mb-2"
};
var _hoisted_4 = {
  class: "glob-h1 text-rm-text"
};
var _hoisted_5 = {
  key: 1,
  class: "mb-6 glob-l1 text-rm-signal-grey-dark"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  class: "flex justify-center w-full h-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_input_field = _resolveComponent("input-field");
  var _component_single_select = _resolveComponent("single-select");
  var _component_modal = _resolveComponent("modal");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_static_table = _resolveComponent("static-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showWorkflowModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $data.translate.createWorkflow,
    "button-ok-text": $data.translate.create,
    "button-dismiss-text": $data.translate.cancel,
    onAccept: $options.createWorkflow,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return $data.showWorkflowModal = false;
    })
  }, {
    body: _withCtx(function () {
      return [_createVNode(_component_input_field, {
        modelValue: $data.editedWorkflowData.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.editedWorkflowData.title = $event;
        }),
        title: $data.translate.title,
        "focus-on-mount": true,
        invalid: $options.isWorkflowTitleInvalid,
        placeholder: $data.translate.enterTitle,
        maxlength: 50,
        type: "text"
      }, null, 8 /* PROPS */, ["modelValue", "title", "invalid", "placeholder"]), $options.isWorkflowTitleInvalid ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString("".concat($data.translate.title, " ").concat($data.translate.isMandatory)), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_single_select, {
        modelValue: $data.editedWorkflowData.activityTypeId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.editedWorkflowData.activityTypeId = $event;
        }),
        title: $data.translate.activityType,
        options: $options.activityTypesOptions,
        placeholder: $data.translate.selectAStatus,
        class: "mt-4"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options", "placeholder"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-ok-text", "button-dismiss-text", "onAccept"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString($data.translate.incidentsWorkflows), 1 /* TEXT */), !$options.readOnly ? (_openBlock(), _createBlock(_component_risma_button, {
    key: 0,
    class: "create-button",
    text: $data.translate.createWorkflow,
    type: "save",
    disabled: $data.saveStatus === $data.saveStatuses.SAVING,
    onClick: $options.openWorkflowModal
  }, null, 8 /* PROPS */, ["text", "disabled", "onClick"])) : _createCommentVNode("v-if", true)]), !$options.readOnly ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($data.translate.createAndEditYourOwnWorkflowsForIncidents), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.saveStatus === $data.saveStatuses.SAVING ? (_openBlock(), _createBlock(_component_loading_indicator_small, {
    key: 2
  })) : $options.dataset.length ? (_openBlock(), _createBlock(_component_static_table, {
    key: 3,
    columns: $options.columns,
    "dataset-table": $options.dataset,
    "columns-styles": $data.columnsStyles,
    class: "static-table",
    "columns-max-width": $data.columnsMaxWidth,
    theme: "zebra"
  }, {
    workflow: _withCtx(function (_ref) {
      var slotData = _ref.slotData;
      return [_createElementVNode("p", {
        class: "hover:font-semibold hover:underline cursor-pointer",
        onClick: function onClick($event) {
          return $options.editWorkflow(slotData.id, slotData.activityType);
        }
      }, _toDisplayString(slotData.title), 9 /* TEXT, PROPS */, _hoisted_6)];
    }),
    validate: _withCtx(function (_ref2) {
      var slotData = _ref2.slotData;
      return [_createElementVNode("div", _hoisted_7, [slotData ? (_openBlock(), _createBlock(_component_feather_icon, {
        key: 0,
        icon: "check",
        width: "24",
        height: "24",
        class: "stroke-3 text-white border-2 rounded-full border-green-150 bg-green-150 mx-auto"
      })) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["columns", "dataset-table", "columns-styles", "columns-max-width"])) : _createCommentVNode("v-if", true)]);
}