// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answerWrap[data-v-eab7740e] {
  height: 54vh;
  max-height: 500px;
  overflow: auto;
  border: 1px solid #CCCCCC;
  padding: 0.25rem 0.375rem;
  background-color: #FFFFFF;
}
.answerWrap .answer[data-v-eab7740e] {
  position: relative;
  margin: 0 !important;
}
.answerWrap .answer .question[data-v-eab7740e] {
  margin: 0;
  width: 100%;
  margin-bottom: 0.5rem;
  font-weight: bolder;
  font-size: 1rem;
  cursor: pointer;
}
.answerWrap .answer .question span[data-v-eab7740e] {
  display: inline-block;
}
.answerWrap .answer .question span[data-v-eab7740e]:nth-child(1) {
  width: 85%;
}
.answerWrap .answer .question span[data-v-eab7740e]:nth-child(2) {
  position: absolute;
  right: 0;
}
.question-answer:last-child span[data-v-eab7740e] {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
