function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import axios from 'axios';
import { humioEvent } from '@/api/humio';
var CSRFMeta = document.querySelector('meta[name="csrf-token"]');
var CSRFToken;
if (CSRFMeta) {
  CSRFToken = CSRFMeta.getAttribute('content');
}
var headers = {};
if (CSRFToken) {
  headers['X-CSRF-TOKEN'] = CSRFToken;
}
/**
 * API instance
 * @type {AxiosInstance}
 */
export var Http = axios.create({
  baseURL: '/api/2.0/',
  headers: headers
});

//additional code for HTTP.get API request to send humioEvent on Crash - that usually appears when you have too long url
var getApi = Http.get;
Http.get = function (url, config) {
  return getApi(url, config).catch(function (error) {
    var tooLongApiErrorName = 'Network Error';
    if (error.code === 'ERR_NETWORK' && error.message === tooLongApiErrorName) {
      humioEvent('error', tooLongApiErrorName, error.stack);
    }
    return Promise.reject(error);
  });
};
Http.fakePatch = function (url, data, config) {
  if (!config) config = {};
  var headers = config.headers || {};
  headers['X-HTTP-METHOD-OVERRIDE'] = 'PATCH';
  config.headers = headers;
  return Http.post(url, _objectSpread(_objectSpread({}, data), {}, {
    _method: 'PATCH'
  }), config);
};
Http.fakeGet = function (url, data, config) {
  if (!config) config = {};
  var headers = config.headers || {};
  headers['X-HTTP-METHOD-OVERRIDE'] = 'GET';
  config.headers = headers;
  return Http.post(url, _objectSpread(_objectSpread({}, data), {}, {
    _method: 'GET'
  }), config);
};
export function useFakeMethods() {
  Http.patch = Http.fakePatch;
}
export default Http;

// Without baseURL (from Risma1 backend)
export var HttpLegacy = axios.create({
  headers: headers
});