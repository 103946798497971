function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { getTranslate } from './translate';
import { getControlsDashboardCompletionData, getSliceControls as _getSliceControls } from '@/api/controls/controls';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import PaginatedTable from '@/components/Organisms/PaginatedTable';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaDoughnutChart from '@/components/Molecules/Charts/RismaDoughnutChart';
import ControlsDashboardFilters from '@/components/Pages/Controls/ControlsDashboardFilters';
import { StatusOverviewMixin } from '@/components/Pages/Controls/StatusOverviewMixin';
import { ControlsSettings, ChartsKey } from '@/constants/controls/ControlsDashboard';
import { toLocalDateTimeFull } from '@/utils/date';
export default {
  name: 'ControlsCompletionDashboard',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    RismaTitle: RismaTitle,
    PaginatedTable: PaginatedTable,
    RismaTitleWithIcon: RismaTitleWithIcon,
    RismaDoughnutChart: RismaDoughnutChart,
    ControlsDashboardFilters: ControlsDashboardFilters
  },
  mixins: [StatusOverviewMixin],
  data: function data() {
    return {
      isLoaded: false,
      pieChartsStaticTable: {},
      charts: [ChartsKey.CONTROL_STATUS, ChartsKey.OVERALL_REVIEWS, ChartsKey.OVERALL_STATUS, ChartsKey.OVERALL_COMPLETED],
      datasets: {},
      tablesCache: {},
      translate: getTranslate['ControlsCompletionDashboard']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useOrganisationsStore, ['organisations'])),
  methods: {
    applyFilters: function applyFilters(searchData) {
      this.isLoaded = false;
      this.getData(searchData);
    },
    getData: function getData(searchData) {
      var _this = this;
      this.isLoaded = false;
      return getControlsDashboardCompletionData(searchData).then(function (_ref) {
        var charts = _ref.charts;
        var chartData = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ChartsKey.CONTROL_STATUS, charts[0].data), ChartsKey.OVERALL_REVIEWS, charts[1].data), ChartsKey.OVERALL_STATUS, charts[2].data), ChartsKey.OVERALL_COMPLETED, charts[3].data);
        _this.prepareDatasets(chartData);
        _this.tablesCache = {};
        _this.isLoaded = true;
      });
    },
    prepareDatasets: function prepareDatasets(data) {
      var _this2 = this;
      this.datasets = JSON.parse(JSON.stringify(ControlsSettings().completionCharts));
      this.pieChartsStaticTable = {};
      Object.keys(data).forEach(function (key) {
        if (typeof data[key] === 'string') {
          return _this2.datasets[key].empty = true;
        }
        var _this2$prepareChartDa = _this2.prepareChartData(key, data, 'title', 'value'),
          datasets = _this2$prepareChartDa.datasets,
          labels = _this2$prepareChartDa.labels;
        _this2.datasets[key].datasets = datasets;
        _this2.datasets[key].labels = labels;
        _this2.datasets[key].controlsUrl = data[key].map(function (item) {
          return item.controlsUrl;
        });
      });
    },
    clickSlice: function clickSlice(key, sliceNumber) {
      var _this$pieChartsStatic,
        _this3 = this;
      var title = this.datasets[key].labels[sliceNumber];
      if (!title || ((_this$pieChartsStatic = this.pieChartsStaticTable[key]) === null || _this$pieChartsStatic === void 0 ? void 0 : _this$pieChartsStatic.title) === title) {
        this.pieChartsStaticTable = {};
        return;
      }
      var cacheKey = "".concat(key, "-").concat(sliceNumber);
      var cachedTable = this.tablesCache[cacheKey];
      if (cachedTable) {
        this.setPieTable(key, title, cachedTable);
        return;
      }
      this.getSliceControls(key, sliceNumber).then(function (controls) {
        var table = _this3.prepareTableInfoForPie(controls);
        _this3.setPieTable(key, title, table);
        _this3.tablesCache[cacheKey] = table;
      });
    },
    setPieTable: function setPieTable(key, title, table) {
      this.pieChartsStaticTable = _defineProperty({}, key, {
        title: title,
        table: table
      });
    },
    getSliceControls: function getSliceControls(key, sliceNumber) {
      var _this4 = this;
      return _getSliceControls(this.datasets[key].controlsUrl[sliceNumber]).then(function (_ref2) {
        var list = _ref2.list;
        return list.map(function (control) {
          var _control$userIds;
          return _objectSpread(_objectSpread({}, control), {}, {
            text: "".concat(control.cpath, " ").concat(control.title, " (").concat(toLocalDateTimeFull(control.deadline), ")<br>").concat(_this4.translate.processes, ": ").concat(_this4.getProcessesNames(control.processes)),
            owners: _this4.getUsersNames((_control$userIds = control.userIds) === null || _control$userIds === void 0 ? void 0 : _control$userIds.responsible),
            organisations: _this4.getOrganisationNames(control.organisationIds)
          });
        });
      });
    },
    getUsersNames: function getUsersNames() {
      var _this5 = this;
      var ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return ids.map(function (id) {
        var _this5$users$find;
        return (_this5$users$find = _this5.users.find(function (user) {
          return +user.id === +id;
        })) === null || _this5$users$find === void 0 ? void 0 : _this5$users$find.display_name;
      }).filter(Boolean).join(', ');
    },
    getOrganisationNames: function getOrganisationNames(ids) {
      var _this6 = this;
      return ids.map(function (id) {
        var _this6$organisations$;
        return (_this6$organisations$ = _this6.organisations.find(function (organisation) {
          return +organisation.id === +id;
        })) === null || _this6$organisations$ === void 0 ? void 0 : _this6$organisations$.visible_name;
      }).filter(Boolean).join(', ');
    },
    getProcessesNames: function getProcessesNames(processes) {
      if (!processes.length) return "<".concat(this.translate.none, ">");
      return processes.map(function (process) {
        return process.title;
      }).join(', ');
    },
    getPieChartTableSize: function getPieChartTableSize(datasetLength) {
      return datasetLength < 20 ? datasetLength : 20;
    }
  }
};