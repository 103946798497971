import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "mb-9"
};
var _hoisted_2 = {
  key: 0,
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_single_select = _resolveComponent("single-select");
  var _component_input_field = _resolveComponent("input-field");
  var _component_modal = _resolveComponent("modal");
  return _openBlock(), _createBlock(_component_modal, {
    header: $data.translate.addOutsourcingArrangement,
    "button-dismiss-text": $data.translate.cancel,
    "button-ok-text": $data.translate.add,
    "ok-button-disabled": !$data.mainTitle,
    onDismiss: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('dismiss');
    }),
    onAccept: $options.saveNode
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [$options.showProjectSelect ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_single_select, {
        modelValue: $data.projectId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.projectId = $event;
        }),
        title: $data.translate.outsourcingProject,
        options: $options.formattedProjects,
        "un-sorted": true
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"])])) : _createCommentVNode("v-if", true), _createVNode(_component_input_field, {
        modelValue: $data.mainTitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return $data.mainTitle = $event;
        }),
        title: $data.translate.title,
        placeholder: $data.translate.enterTitle
      }, null, 8 /* PROPS */, ["modelValue", "title", "placeholder"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "button-dismiss-text", "button-ok-text", "ok-button-disabled", "onAccept"]);
}