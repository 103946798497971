function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useUserStore } from '@/Store/userStore';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { CompanyWizardFields, AddAssetActions } from '@/constants/compliance/WizardFlow';
import { AssetsTypes } from '@/constants/compliance/AssetsTypes';
export default {
  name: 'CompanyWizardStepConfirmation',
  introduction: '',
  token: '<company-wizard-step-company-confirmation />',
  components: {
    RismaTitle: RismaTitle
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    selectedOptions: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      },
      note: 'selected options for all steps'
    }
  },
  data: function data() {
    return {
      translate: getTranslate['CompanyWizardStepConfirmation']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useActivityTypesStore, ['complianceIACustomActivityTypes'])), mapState(useUserStore, ['currentUser'])), {}, {
    hasIAModule: function hasIAModule() {
      return this.currentUser.iaAccess;
    },
    companyTitle: function companyTitle() {
      if (this.selectedOptions[CompanyWizardFields.IS_VAT_CREATION_MODE]) {
        var companyIdx = this.selectedOptions[CompanyWizardFields.SELECTED_COMPANY_IDX];
        var company = this.selectedOptions[CompanyWizardFields.FOUND_COMPANIES][companyIdx];
        return company.name;
      } else {
        return this.selectedOptions[CompanyWizardFields.COMPANY_OBJECT].name;
      }
    },
    customIAGroupedByActivityType: function customIAGroupedByActivityType() {
      var _this = this;
      var result = {};
      this.selectedOptions[AssetsTypes.INFORMATION_ASSETS_API].forEach(function (asset) {
        var _this$data$AssetsType;
        if (asset.action === AddAssetActions.NONE) return;
        var action = asset.action,
          activityTypeId = asset.activityTypeId,
          id = asset.id;
        var activityType = _this.complianceIACustomActivityTypes.find(function (at) {
          return at.id === activityTypeId;
        });
        if (!result[activityTypeId]) {
          result[activityTypeId] = {
            activityTypeTitle: activityType.label,
            items: []
          };
        }
        var assetTitle = action === AddAssetActions.CREATE_NEW ? _this.companyTitle : (_this$data$AssetsType = _this.data[AssetsTypes.INFORMATION_ASSETS_API].find(function (at) {
          return at.id === id;
        })) === null || _this$data$AssetsType === void 0 ? void 0 : _this$data$AssetsType.title;
        result[activityTypeId].items.push(assetTitle);
      });
      return result;
    },
    systemsTitle: function systemsTitle() {
      var _this2 = this;
      var result = [];
      this.selectedOptions[AssetsTypes.SYSTEMS].forEach(function (system) {
        var _this2$data$AssetsTyp;
        if (system.action === AddAssetActions.NONE) return;
        var systemTitle = system.action === AddAssetActions.CREATE_NEW ? system.title : (_this2$data$AssetsTyp = _this2.data[AssetsTypes.SYSTEMS].find(function (st) {
          return st.id === system.id;
        })) === null || _this2$data$AssetsTyp === void 0 ? void 0 : _this2$data$AssetsTyp.title;
        result.push(systemTitle);
      });
      return result.join(', ');
    },
    dataProcessorTitle: function dataProcessorTitle() {
      var _this$data$AssetsType2;
      var _this$selectedOptions = this.selectedOptions[AssetsTypes.PROCESSORS],
        id = _this$selectedOptions.id,
        action = _this$selectedOptions.action;
      if (action === AddAssetActions.NONE) return '';
      if (action === AddAssetActions.CREATE_NEW) return this.companyTitle;
      if (!id) return '';
      return ((_this$data$AssetsType2 = this.data[AssetsTypes.PROCESSORS].find(function (processor) {
        return processor.id === id;
      })) === null || _this$data$AssetsType2 === void 0 ? void 0 : _this$data$AssetsType2.title) || '';
    },
    dataControllerTitle: function dataControllerTitle() {
      var _this$data$AssetsType3;
      var _this$selectedOptions2 = this.selectedOptions[AssetsTypes.CONTROLLERS],
        id = _this$selectedOptions2.id,
        action = _this$selectedOptions2.action;
      if (action === AddAssetActions.NONE) return '';
      if (action === AddAssetActions.CREATE_NEW) return this.companyTitle;
      if (!id) return '';
      return ((_this$data$AssetsType3 = this.data[AssetsTypes.CONTROLLERS].find(function (processor) {
        return processor.id === id;
      })) === null || _this$data$AssetsType3 === void 0 ? void 0 : _this$data$AssetsType3.title) || '';
    }
  })
};