function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import InputFieldSelect from '@/components/Atoms/Inputs/InputFieldSelect';
import DateTimePicker from '@/components/Atoms/DateTimePicker/DateTimePicker';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import Notification from '@/components/Molecules/Notification';
import currency from '@/constants/contracts/currency';
import { getIntervalOptions, getMonthOptions } from '@/constants/contracts/contract';
import { DateFormats } from '@/constants/DateFormats';
import { ERROR_MESSAGES } from '@/constants/contracts/errorMessages';
import { isDateAfter, isDateBefore, addTimeAndFormatDate, formatCurrentDateTime } from '@/utils/date';
export default {
  name: 'ContractRenewModal',
  token: "<contract-renew-modal\n      v-if=\"showRenewModal\"\n      :notification-options=\"notificationOptions\"\n      :status-list=\"workflowStatuses\"\n      :data=\"data\"\n      @dismiss=\"showRenewModal = false\"\n      @update=\"updateDataFromModal\" />",
  components: {
    Modal: Modal,
    InputFieldSelect: InputFieldSelect,
    DateTimePicker: DateTimePicker,
    SingleSelect: SingleSelect,
    Notification: Notification
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function _default() {}
    },
    notificationOptions: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    statusList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    currencyOptions: {
      type: Array,
      required: false,
      default: currency,
      note: 'Better to send it as prop (so it would be 100% the same as on desc page)'
    }
  },
  emits: ['dismiss', 'update'],
  data: function data() {
    return {
      modalData: {},
      datePickerOptions: {
        format: DateFormats.DATE_FORMAT
      },
      paymentIntervalOptions: getIntervalOptions(),
      paymentMonthOptions: getMonthOptions(),
      fieldUpdated: 'startDate',
      translate: getTranslate['ContractRenewModal']()
    };
  },
  computed: {
    statuses: function statuses() {
      var _this = this;
      return this.statusList.map(function (item) {
        return {
          label: _this.$trans(item.title),
          value: item.id
        };
      });
    },
    renewHeader: function renewHeader() {
      return this.translate.renew + ' "' + this.data.title + '"';
    },
    errorMessage: function errorMessage() {
      if (this.modalData.startDate && this.modalData.endDate && isDateAfter(this.modalData.startDate, this.modalData.endDate)) {
        return this.$trans(ERROR_MESSAGES[this.fieldUpdated]);
      }
      if (this.isNotInRange(this.modalData.renewalDate)) {
        return this.$trans(ERROR_MESSAGES.renewalDate);
      }
      if (this.isNotInRange(this.modalData.bindingDate)) {
        return this.$trans(ERROR_MESSAGES.bindingDate);
      }
      return '';
    }
  },
  mounted: function mounted() {
    this.populateFields(this.data);
  },
  methods: {
    populateFields: function populateFields(data) {
      var startDate = data.endDate ? data.endDate : formatCurrentDateTime(DateFormats.DATE_TIME_FORMAT_FULL);
      var endDate = addTimeAndFormatDate(startDate, 1, 'year', DateFormats.DATE_TIME_FORMAT_FULL);
      this.modalData = _objectSpread(_objectSpread({}, data), {}, {
        startDate: startDate,
        endDate: endDate,
        renewalDate: null,
        bindingDate: null,
        contractAmount: data.renewalAmount ? data.renewalAmount : data.contractAmount,
        contractNumber: data.contractNumber + 1,
        contractAmountCurrency: data.renewalAmountCurrency ? data.renewalAmountCurrency : data.contractAmountCurrency
      });
    },
    isNotInRange: function isNotInRange(date) {
      if (!date) return;
      return this.modalData.startDate && isDateBefore(date, this.modalData.startDate) || this.modalData.endDate && isDateAfter(date, this.modalData.endDate);
    }
  }
};