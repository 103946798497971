import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "modal-wrap"
};
var _hoisted_2 = {
  class: "w-29rem mb-3"
};
var _hoisted_3 = {
  class: "mb-3 textarea-wrap"
};
var _hoisted_4 = ["rows", "cols"];
var _hoisted_5 = {
  class: "validation-error"
};
var _hoisted_6 = {
  class: "flex justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_modal = _resolveComponent("modal");
  var _directive_focus = _resolveDirective("focus");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.showModal ? (_openBlock(), _createBlock(_component_modal, {
    key: 0,
    header: $options.modalHeaderTitle,
    "show-buttons": $data.showButtons,
    "modal-fixed-width": "fit-content",
    onDismiss: $options.toggleModal
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, _toDisplayString($options.modalHeaderDescription), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: _normalizeClass([{
          error: $data.validation
        }, "input-field-container"])
      }, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.rowData = $event;
        }),
        rows: $data.rows,
        cols: $data.cols,
        class: "border-2 border-gray-550",
        onInput: _cache[1] || (_cache[1] = function () {
          return $options.onInput && $options.onInput.apply($options, arguments);
        })
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4), [[_vModelText, $data.rowData], [_directive_focus, true]])], 2 /* CLASS */), _createElementVNode("span", _hoisted_5, _toDisplayString($data.validation), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_risma_button, {
        text: $data.translate.cancel,
        type: "util",
        class: "mr-2",
        onClick: $options.cancelCreation
      }, null, 8 /* PROPS */, ["text", "onClick"]), _createVNode(_component_risma_button, {
        text: $data.translate.create,
        type: "save",
        onClick: $options.create
      }, null, 8 /* PROPS */, ["text", "onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "show-buttons", "onDismiss"])) : _createCommentVNode("v-if", true)]);
}