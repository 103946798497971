function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useSettingsStore } from '@/Store/settingsStore';
import { getTranslate } from './translate';
import Modal from '@/components/Molecules/Modal/Modal';
import CompleteControl from '@/components/Molecules/Controls/CompleteControl';
export default {
  name: 'CompleteModal',
  introduction: 'A modal that shows latest completion information of control and handle complete functionality',
  description: 'This is a Molecule component for /controls2/controls/:id. Parent component: ControlDetails',
  token: '<complete-modal :cpath="cpath" :id="id" />',
  components: {
    Modal: Modal,
    CompleteControl: CompleteControl
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
      note: 'An id of a control'
    },
    cpath: {
      type: [Number, String],
      required: true,
      note: 'A cpath of a control'
    }
  },
  emits: ['update', 'close'],
  data: function data() {
    return {
      title: '',
      interactionDisabled: false,
      translate: getTranslate['CompleteModal'](),
      isUpdated: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), {}, {
    completeModalWide: function completeModalWide() {
      return this.getSettingValue('feature.complete_modal_wide');
    },
    isFilesDisable: function isFilesDisable() {
      return this.getSettingValue('feature.control_complete_modal_files_disabled') ? true : false;
    }
  }),
  methods: {
    handleClose: function handleClose() {
      if (this.interactionDisabled) return;
      this.$emit('close');
    },
    handleAccept: function handleAccept() {
      this.$emit('close');
    },
    toggleDisableInteraction: function toggleDisableInteraction() {
      this.interactionDisabled = !this.interactionDisabled;
    },
    setModalTitle: function setModalTitle(title) {
      this.title = title;
    },
    onDismiss: function onDismiss() {
      var _this = this;
      if (this.isUpdated) {
        this.showConfirmation = true;
        this.$confirm(this.translate.closeCompletionReviewWindow, this.translate.areYouSureYouWishToCloseTheWindowAllEnteredDataWillBeDeleted, function (res) {
          return res && _this.handleClose();
        });
        return;
      }
      this.handleClose();
    }
  }
};