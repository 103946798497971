import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue";
var _hoisted_1 = {
  class: "awareness"
};
var _hoisted_2 = {
  key: 1,
  class: "flex flex-wrap -mx-20"
};
var _hoisted_3 = {
  class: "header"
};
var _hoisted_4 = {
  class: "header-title"
};
var _hoisted_5 = {
  key: 0,
  class: "table thead-light table-bordered table-striped mt-2"
};
var _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_view_switcher = _resolveComponent("view-switcher");
  var _component_toggle_menu = _resolveComponent("toggle-menu");
  var _component_risma_pie_chart = _resolveComponent("risma-pie-chart");
  var _component_router_link = _resolveComponent("router-link");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_datatable = _resolveComponent("risma-datatable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_risma_title, {
    title: $data.translate.rismaawarenessDashboard,
    class: "main-component-title"
  }, null, 8 /* PROPS */, ["title"]), !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : _createCommentVNode("v-if", true), _createVNode(_component_view_switcher, {
    view: $setup.viewSelected,
    "onUpdate:view": _cache[0] || (_cache[0] = function ($event) {
      return $setup.viewSelected = $event;
    }),
    views: $data.AWARENESS_DASHBOARDS_VIEWS,
    class: "justify-end mb-2"
  }, null, 8 /* PROPS */, ["view", "views"]), $setup.viewSelected === $data.viewTypes.TILES ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.requests, function (request) {
    return _openBlock(), _createElementBlock("div", {
      key: "preview-request".concat(request.id),
      class: "preview-request mx-20"
    }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h2", null, _toDisplayString(request.title), 1 /* TEXT */)]), !$options.isUserLocked ? (_openBlock(), _createBlock(_component_toggle_menu, {
      key: 0,
      options: $options.dataMenuOptions(request.id),
      class: "header-toggle-menu mr-0.5"
    }, null, 8 /* PROPS */, ["options"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_risma_pie_chart, {
      data: request.data,
      labels: request.labels,
      width: 600,
      height: 400,
      colors: $data.colors,
      class: "chart-item",
      onChartClicked: function onChartClicked(e) {
        return $options.clickSlice(e, request.id);
      }
    }, null, 8 /* PROPS */, ["data", "labels", "colors", "onChartClicked"]), _createElementVNode("p", null, _toDisplayString($data.translate.requestsSentInTotal) + ": " + _toDisplayString(request.users.length) + " (" + _toDisplayString($data.translate.on) + " " + _toDisplayString(request.sentDateInLocalTime) + ") ", 1 /* TEXT */), _createTextVNode(" " + _toDisplayString($data.translate.linkTo) + " " + _toDisplayString(_ctx.processLibraryTitle) + ": ", 1 /* TEXT */), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(request.links.compliance_process_tree_node, function (link) {
      return _openBlock(), _createBlock(_component_router_link, {
        key: "preview-request-link".concat(link.id),
        to: link.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(link.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
    }), 128 /* KEYED_FRAGMENT */)), request.userFilter ? (_openBlock(), _createElementBlock("table", _hoisted_5, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("td", null, [_createElementVNode("b", null, _toDisplayString($data.translate.user), 1 /* TEXT */)]), _createElementVNode("td", null, [_createElementVNode("b", null, _toDisplayString($data.translate.status), 1 /* TEXT */)]), _createElementVNode("td", null, [_createElementVNode("b", null, _toDisplayString($data.translate.requestRead), 1 /* TEXT */)]), _createElementVNode("td", null, [_createElementVNode("b", null, _toDisplayString($data.translate.latestReminder), 1 /* TEXT */)]), _createElementVNode("td", null, [_createElementVNode("b", null, _toDisplayString($data.translate.requestApproved), 1 /* TEXT */)])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredUsers(request), function (user) {
      return _openBlock(), _createElementBlock("tr", {
        key: "preview-request-users".concat(user.id)
      }, [_createElementVNode("td", null, _toDisplayString(user.fullname) + " " + _toDisplayString(user.fullname != '' ? '/' : '') + " " + _toDisplayString(user.email), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.getUserRequestStatus(user)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.toLocalDateTimeFull(user.firstClick)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.toLocalDateTimeFull(user.latestReminder)), 1 /* TEXT */), _createElementVNode("td", null, _toDisplayString($options.toLocalDateTimeFull(user.signoff)), 1 /* TEXT */)]);
    }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $setup.viewSelected === $data.viewTypes.COMPACT ? (_openBlock(), _createBlock(_component_risma_datatable, {
    key: 2,
    "dataset-main": _ctx.datasetSlice,
    "dataset-total": $data.dataset,
    "dataset-total-sliced": _ctx.datasetSliceTotal,
    "wrapper-height-sticky": 500,
    "custom-export": $options.filterColumnsFn,
    class: "p-6 border border-gray-350",
    onSendReminder: $options.showReminderConfirm,
    onDeleteDashboard: $options.showDeleteConfirm,
    onPageChange: _ctx.onPageChange,
    onOnStateChanged: _ctx.onStateChanged
  }, _createSlots({
    process_library_activity: _withCtx(function (_ref) {
      var item = _ref.item;
      return [$options.isNormalField(item.field) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "hover:underline",
        innerHTML: item.field
      }, null, 8 /* PROPS */, _hoisted_6)) : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: item.field.url
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(item.field.title), 1 /* TEXT */)];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))];
    }),
    _: 2 /* DYNAMIC */
  }, [_renderList($data.featherIconFields, function (linkCol) {
    return {
      name: linkCol,
      fn: _withCtx(function (_ref2) {
        var item = _ref2.item;
        return [_createVNode(_component_feather_icon, {
          icon: item.field.icon,
          class: _normalizeClass(["cursor-pointer stroke-2", item.field.additionalCss || '']),
          onClick: function onClick($event) {
            return item.field.isReminder ? $options.showReminderConfirm(item.field.data) : $options.showDeleteConfirm(item.field.data);
          }
        }, null, 8 /* PROPS */, ["icon", "class", "onClick"])];
      })
    };
  })]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-main", "dataset-total", "dataset-total-sliced", "custom-export", "onSendReminder", "onDeleteDashboard", "onPageChange", "onOnStateChanged"])) : _createCommentVNode("v-if", true)]);
}