function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import { useSettingsStore } from '@/Store/settingsStore';
import { useUsersStore } from '@/Store/usersStore';
import { useOrganisationsStore } from '@/Store/organisationsStore';
import { useTagsStore } from '@/Store/tagsStore';
import { useActivityTypesStore } from '@/Store/activityTypesStore';
import { useFrameworksStore } from '@/Store/frameworksStore';
import { useTrafficLights } from '@/Store/trafficLights';
import { getAllActionMainReport } from '@/api/execution/initiative';
import { getInitiativesFromIds } from '@/utils/format/objectsFromIds';
import GanttChartActions from '@/components/Molecules/Charts/GanttChartActions';
import CollapsiblePanel from '@/components/Molecules/CollapsiblePanel/CollapsiblePanel';
import PreviewModalLink from '@/components/Organisms/PreviewModalLink/PreviewModalLink';
import TrafficLight from '@/components/Atoms/Trafficlight/TrafficLight';
import SaveReport from '@/components/Organisms/Reports/SaveReport';
import RismaTitleWithIcon from '@/components/Molecules/RismaTitleWithIcon';
import RismaDatatable from '@/components/Atoms/RismaDatatable/RismaDatatable';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import { RismaDatatableDefaultMixin } from '@/components/Atoms/RismaDatatable/RismaDatatableDefaultMixin';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { SaveReportMixin } from '@/mixins/SaveReportMixin';
import { LinkedActivitiesForReportMixin } from '@/mixins/LinkedActivitiesForReportMixin';
import { DatatableRenderer } from '@/utils/DatatableRenderer';
import * as Utils from '@/utils/Utils';
import { compareStrings } from '@/utils/compare';
import { ReportTypes } from '@/constants/reports';
import { MODULES } from '@/constants/modules';
import { TabTypes } from '@/constants/Tabs';
import { getGapsLinkedToActions } from '@/api/compliance/reports/gap';
var linksGapLabel = 'links-gap';
export default {
  name: 'ActionMainReport',
  introduction: 'Action Main Report',
  description: 'This is Page component for /actions/reports/',
  token: '<action-main-report/>',
  components: {
    LoadingIndicatorLarge: LoadingIndicatorLarge,
    CollapsiblePanel: CollapsiblePanel,
    GanttChartActions: GanttChartActions,
    RismaDatatable: RismaDatatable,
    RismaTitle: RismaTitle,
    PreviewModalLink: PreviewModalLink,
    TrafficLight: TrafficLight,
    SaveReport: SaveReport,
    RismaTitleWithIcon: RismaTitleWithIcon
  },
  mixins: [RismaDatatableDefaultMixin, SaveReportMixin, LinkedActivitiesForReportMixin],
  data: function data() {
    return {
      isReportSaved: false,
      actions: [],
      updatedAction: null,
      dataset: [],
      columnsMeta: [{
        targets: ['deadline', 'title'],
        width: '170px'
      }, {
        targets: ['description'],
        width: '600px'
      }, {
        targets: linksGapLabel,
        width: '300px'
      }],
      columnSorting: [{
        key: 'trafficLight',
        sortingFunc: this.trafficLightSort
      }],
      disabledFilterByColumns: ['path', 'description', 'status', 'deadline', 'percentage', 'target', 'comment'],
      showGanttLoader: true,
      stateLocal: 'action_main_report_',
      cfColumns: [],
      customFieldsValuesByActionId: {},
      reportType: ReportTypes.ACTIONS,
      translate: getTranslate['ActionMainReport'](),
      isLoading: false,
      gapsByAction: {},
      linksGapLabel: linksGapLabel,
      TabTypes: TabTypes
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(useSettingsStore, {
    getSettingValue: 'getValue'
  })), mapState(useUserStore, ['currentUser'])), mapState(useOrganisationsStore, ['organisations'])), mapState(useUsersStore, {
    users: 'activeUsers'
  })), mapState(useTagsStore, ['tags'])), mapState(useActivityTypesStore, {
    activityTypes: 'executionActivityTypes'
  })), mapState(useFrameworksStore, {
    frameworks: 'enabledFrameworks'
  })), {}, {
    dependingOnTitle: function dependingOnTitle() {
      return this.getSettingValue('risma.depending_on_name') || this.translate.dependingOn;
    },
    precursorToTitle: function precursorToTitle() {
      return this.getSettingValue('risma.precursor_to_name') || this.translate.precursorTo;
    },
    columns: function columns() {
      return _objectSpread(_objectSpread({
        trafficLight: this.translate.trafficLight,
        path: this.translate.path,
        title: this.translate.title,
        description: this.translate.description,
        status: this.translate.status,
        openClosed: this.translate.open + '/' + this.translate.closed,
        deadline: this.translate.start + '/' + this.translate.deadline,
        organisations: this.translate.organisations,
        responsible: this.translate.responsible,
        notifications: this.translate.notifications,
        tags: this.translate.tags,
        frameworks: this.translate.frameworks,
        dependingOn: this.dependingOnTitle,
        precursorTo: this.precursorToTitle,
        percentage: this.translate.completionPercentage,
        target: this.translate.target,
        comment: this.translate.otherComments
      }, this.cfColumns), this.enhancedLinkedActivitiesColumns);
    },
    enhancedLinkedActivitiesColumns: function enhancedLinkedActivitiesColumns() {
      if (Object.keys(this.gapsByAction).length === 0) {
        return this.getLinkedActivitiesColumns();
      }
      return _objectSpread(_defineProperty({}, this.linksGapLabel, this.translate.linkedGaps), this.getLinkedActivitiesColumns());
    },
    visibleActions: function visibleActions() {
      var _this = this;
      return this.datasetSlice.map(function (item) {
        return _this.actions.find(function (action) {
          return item.title.field === action.title && item.path.field === action.ipath;
        });
      }).filter(Boolean);
    },
    customExport: function customExport() {
      var _this2 = this;
      return {
        columns: function columns(_columns) {
          var index = _columns.findIndex(function (item) {
            return item.key === 'deadline';
          });
          if (index === -1) return _columns;
          var customizedColumns = _toConsumableArray(_columns);
          var splitColumns = [{
            key: 'start',
            label: _this2.translate.start
          }, {
            key: 'deadline',
            label: _this2.translate.deadline
          }];
          customizedColumns.splice.apply(customizedColumns, [index, 1].concat(splitColumns));
          return customizedColumns;
        },
        dataset: function dataset(_dataset) {
          return _dataset.map(function (row) {
            var customizedRow = _objectSpread({}, row);
            var _ref = customizedRow.deadline.fieldText ? customizedRow.deadline.fieldText.split('/') : [],
              _ref2 = _slicedToArray(_ref, 2),
              _ref2$ = _ref2[0],
              startDate = _ref2$ === void 0 ? '' : _ref2$,
              _ref2$2 = _ref2[1],
              deadlineDate = _ref2$2 === void 0 ? '' : _ref2$2;
            customizedRow.start = {
              field: startDate,
              fieldText: startDate,
              label: _this2.translate.start
            };
            customizedRow.deadline = {
              field: deadlineDate,
              fieldText: deadlineDate,
              label: _this2.translate.deadline
            };
            return customizedRow;
          });
        }
      };
    },
    decoupleFilterFields: function decoupleFilterFields() {
      return [{
        key: 'organisations'
      }, {
        key: 'responsible'
      }, {
        key: 'notifications'
      }, {
        key: 'tags'
      }, {
        key: 'frameworks'
      }, {
        key: 'dependingOn'
      }, {
        key: 'precursorTo'
      }, {
        key: linksGapLabel
      }].concat(_toConsumableArray(this.decoupleLinkedActivitiesFilterFields));
    }
  }),
  created: function created() {
    var _this3 = this;
    this.getAllActionReport().then(function () {
      return _this3.getLinkedActivitiesRequests(_this3.actions);
    }).then(function () {
      return _this3.getLinkedGaps();
    }).then(function () {
      return _this3.refreshDatatable();
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTrafficLights, ['getGapColor'])), {}, {
    getLinkedGaps: function getLinkedGaps() {
      var _this4 = this;
      return getGapsLinkedToActions().then(function (response) {
        _this4.gapsByAction = response;
      });
    },
    refreshDatatable: function refreshDatatable() {
      var _this$getPropertiesWi = this.getPropertiesWithCustomFields(this.actions, this.activityTypes),
        cfByEntities = _this$getPropertiesWi.cfByEntities,
        cfColumns = _this$getPropertiesWi.cfColumns,
        cfDisabledColumns = _this$getPropertiesWi.cfDisabledColumns;
      this.customFieldsValuesByActionId = cfByEntities;
      this.cfColumns = cfColumns;
      this.disabledFilterByColumns = this.disabledFilterByColumns.concat(cfDisabledColumns);
      this.prepareDataset(this.actions);
      this.isLoading = false;
      this.loadSavedReport();
    },
    beforeLeavePanel: function beforeLeavePanel() {
      this.showGanttChart = false;
      this.showGanttLoader = true;
    },
    updateFromPreviewMode: function updateFromPreviewMode(item) {
      this.updatedAction = item;
    },
    onDismissModal: function onDismissModal() {
      var _this5 = this;
      if (!this.updatedAction) return;
      var index = this.actions.findIndex(function (action) {
        return +action.id === +_this5.updatedAction.id;
      });
      this.actions[index] = this.updatedAction;
      this.updatedAction = null;
      this.prepareDataset(this.actions);
    },
    onItemDelete: function onItemDelete() {
      this.getAllActionReport().then(this.refreshDatatable);
    },
    getAllActionReport: function getAllActionReport() {
      var _this6 = this;
      this.isLoading = true;
      var params = '?data[]=customFields&filters[status]=1,0&data[]=links';
      return getAllActionMainReport(params).then(function (_ref3) {
        var list = _ref3.list;
        _this6.actions = list.sort(function (a, b) {
          return compareStrings(a.ipath, b.ipath);
        });
      });
    },
    prepareDataset: function prepareDataset(rows) {
      var _this7 = this;
      this.dataset = rows.map(function (node) {
        var dependingActivitiesList = [];
        var precursorActivitiesList = [];
        if (node.dependingOn && node.dependingOn.length) {
          dependingActivitiesList = getInitiativesFromIds(node.dependingOn, _this7.actions);
        }
        if (node.precursorTo && node.precursorTo.length) {
          precursorActivitiesList = getInitiativesFromIds(node.precursorTo, _this7.actions);
        }
        var trafficLightField = DatatableRenderer.prepareTrafficLightField(node.trafficLight, MODULES.EXECUTION);
        var linkedGaps = {};
        if (_this7.columns[_this7.linksGapLabel]) {
          var gaps = _this7.gapsByAction[node.id];
          var field = gaps ? gaps.reduce(function (acc, cur) {
            var title = "".concat(cur.article.article, " ").concat(cur.article.articleTitle);
            if (!acc) return title;
            return [acc, title].join(',\n');
          }, '') : '';
          linkedGaps = {
            field: field,
            mitigationActivities: _this7.gapsByAction[node.id]
          };
        }
        var linkedActivitiesFields = _this7.getLinkedActivitiesFields(node.id, node.links);
        if (linkedActivitiesFields['links-compliance']) {
          var arrayOfEntries = Object.entries(linkedActivitiesFields);
          var index = arrayOfEntries.findIndex(function (item) {
            return item[0] === 'links-compliance';
          });
          arrayOfEntries.splice(index + 1, 0, [linksGapLabel, linkedGaps]);
          linkedActivitiesFields = Object.fromEntries(arrayOfEntries);
        } else {
          linkedActivitiesFields = _objectSpread(_objectSpread({}, linkedActivitiesFields), {}, _defineProperty({}, linksGapLabel, linkedGaps));
        }
        return _objectSpread(_objectSpread({
          trafficLight: {
            color: trafficLightField.color,
            field: trafficLightField,
            fieldText: trafficLightField.label
          },
          path: {
            field: node.ipath
          },
          title: {
            activity: node,
            field: node.title
          },
          description: {
            field: node.description || '',
            fieldText: node.description || ''
          },
          status: {
            field: _this7.checkFieldForNull(node.status)
          },
          openClosed: {
            field: node.closed ? _this7.translate.closed : _this7.translate.open
          },
          deadline: {
            field: node.start + '<b>/</b>' + node.deadline,
            fieldText: "".concat(node.start, "/").concat(node.deadline)
          },
          organisations: {
            field: Utils.findPropsByIds(node.organisationIds, _this7.organisations, 'visible_name')
          },
          responsible: {
            field: Utils.findPropsByIds(node.userIds.responsible, _this7.users, 'display_name')
          },
          notifications: {
            field: Utils.findPropsByIds(node.userIds.notifications, _this7.users, 'display_name')
          },
          tags: {
            field: Utils.findPropsByIds(node.tagIds, _this7.tags, 'tag')
          },
          frameworks: {
            field: Utils.findPropsByIds(node.solutionIds, _this7.frameworks, 'displayName')
          },
          dependingOn: {
            field: DatatableRenderer.prepareLinks(dependingActivitiesList.map(function (item) {
              return {
                link: item.url,
                title: "".concat(item.ipath, " - ").concat(item.title),
                isRouterLinkWithClick: true
              };
            }), _this7),
            fieldText: dependingActivitiesList.map(function (item) {
              return "".concat(item.ipath, " - ").concat(item.title);
            }).join(', ')
          },
          precursorTo: {
            field: DatatableRenderer.prepareLinks(precursorActivitiesList.map(function (item) {
              return {
                link: item.url,
                title: "".concat(item.ipath, " - ").concat(item.title),
                isRouterLinkWithClick: true
              };
            }), _this7),
            fieldText: precursorActivitiesList.map(function (item) {
              return "".concat(item.ipath, " - ").concat(item.title);
            }).join(', ')
          },
          percentage: {
            field: node.completionPercentage + '%'
          },
          target: {
            field: _this7.checkFieldForNull(node.target)
          },
          comment: {
            field: _this7.checkFieldForNull(node.comment)
          }
        }, _this7.customFieldsValuesByActionId[node.id]), linkedActivitiesFields);
      });
      this.enhanceDataset(this.dataset, this.columns);
    },
    trafficLightSort: function trafficLightSort(data, index) {
      return data.sort(function (prev, current) {
        var _prev$index, _current$index;
        var prevNumericValue = (_prev$index = prev[index]) === null || _prev$index === void 0 || (_prev$index = _prev$index.field) === null || _prev$index === void 0 ? void 0 : _prev$index.value;
        var currentNumericValue = (_current$index = current[index]) === null || _current$index === void 0 || (_current$index = _current$index.field) === null || _current$index === void 0 ? void 0 : _current$index.value;
        return currentNumericValue - prevNumericValue;
      });
    },
    checkFieldForNull: function checkFieldForNull(field) {
      return field === null ? '' : field;
    },
    onLoadReport: function onLoadReport(data) {
      this.applySettingsFromLoadedReport(data);
    },
    loadSavedReport: function loadSavedReport() {
      var _this$$route$query,
        _this8 = this;
      if (!((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.query)) return;
      this.$nextTick(function () {
        return _this8.applySettingsFromLoadedReport(JSON.parse(_this8.$route.query.query));
      });
    }
  })
};