import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "risma-progress-bar"
};
var _hoisted_2 = {
  class: "risma-progress-bar-total"
};
var _hoisted_3 = {
  key: 0,
  class: "risma-progress-bar-label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.label ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    style: _normalizeStyle('width:' + $props.value + '%'),
    class: "risma-progress-bar-done"
  }, null, 4 /* STYLE */)])]);
}