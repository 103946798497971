// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-reset-styles[data-v-30d91eed]  ul {
  padding-left: 1.5em;
  list-style-type: disc;
}
.list-reset-styles[data-v-30d91eed]  ul ul {
  list-style-type: circle;
}
.list-reset-styles[data-v-30d91eed]  ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}
.list-reset-styles[data-v-30d91eed]  li {
  padding-left: 0.3em;
}
.dt-wrapper[data-v-30d91eed] {
  background: #f5f5f2;
  border: 1px solid #CCCCCC;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.06);
  /*margin: 0 auto 24px auto;*/
  height: 70vh;
  overflow-y: auto;
}
.btn-group[data-v-30d91eed] {
  margin-left: 5px;
  margin-bottom: 15px;
}
.btn-group button[data-v-30d91eed] {
  margin: 5px;
}
.btn-group button i[data-v-30d91eed] {
  margin-right: 10px;
}
button.btn.btn-transparent[data-v-30d91eed] {
  background: transparent !important;
  border: 0;
  color: #347480;
  text-decoration: underline;
}
tbody tr:first-child td[data-v-30d91eed] {
  padding-top: 15px;
}
th[data-v-30d91eed]:first-child,
td[data-v-30d91eed]:first-child {
  padding-left: 15px;
}
th[data-v-30d91eed]:last-child,
td[data-v-30d91eed]:last-child {
  padding-right: 15px;
}
td[data-v-30d91eed] {
  vertical-align: top;
  padding: 5px;
}
td a[data-v-30d91eed] {
  line-height: 30px;
  font-size: 16px;
}
th[data-v-30d91eed] {
  min-width: 200px !important;
  vertical-align: top;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #347480 !important;
  border-bottom: 1px solid #347480 !important;
  line-height: 20px !important;
  padding: 5px 8px !important;
}
tr:hover td[data-v-30d91eed] {
  color: #000000 !important;
  background-color: #FFFFFF !important;
}
table td ul[data-v-30d91eed] {
  padding-left: 20px;
}
tr[data-v-30d91eed] > * {
  border: 1px solid #347480 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
