import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_table = _resolveComponent("risma-table");
  return _openBlock(), _createBlock(_component_risma_table, {
    ref: "contractTable",
    "print-title": "test",
    "state-key": $data.stateLocal,
    "show-search-field": true,
    api: $options.getDataflowsBE
  }, null, 8 /* PROPS */, ["state-key", "api"]);
}