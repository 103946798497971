function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions, mapState } from 'pinia';
import { useWorkflowStore } from '@/Store/workflowStore';
import { updateWorkflowStatusesByModule, updateWorkflowStatus } from '@/api/admin/workflow';
import DragAndDropIcon from '@/components/Atoms/FeatherIcon/DragAndDropIcon';
import draggable from 'vuedraggable';
import { DragOptions } from '@/constants/DragOptions';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import InputField from '@/components/Atoms/Inputs/InputField';
import { getWorkflowStatusTitle } from '@/constants/admin/WorkflowStatuses';
import { MODULES } from '@/constants/modules';
export default {
  name: 'WorkflowStatuses',
  components: {
    draggable: draggable,
    DragAndDropIcon: DragAndDropIcon,
    RismaButton: RismaButton,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    InputField: InputField
  },
  data: function data() {
    return {
      updatedWorkflowStatuses: [],
      uniqueNames: new Set(),
      module: MODULES.CONTRACTS,
      DragOptions: DragOptions,
      updateTimer: 0,
      newStatusName: '',
      workflowStatus: {},
      getWorkflowStatusTitle: getWorkflowStatusTitle,
      updateInProgress: false,
      translate: getTranslate['WorkflowStatuses']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useWorkflowStore, ['defaultWorkflowByModule', 'workflows'])), {}, {
    defaultWorkflow: function defaultWorkflow() {
      return this.defaultWorkflowByModule(this.module);
    },
    workflowStatuses: function workflowStatuses() {
      var _this$defaultWorkflow;
      return ((_this$defaultWorkflow = this.defaultWorkflow) === null || _this$defaultWorkflow === void 0 ? void 0 : _this$defaultWorkflow.workflowStatuses) || [];
    },
    workflowId: function workflowId() {
      var _this$defaultWorkflow2;
      return (_this$defaultWorkflow2 = this.defaultWorkflow) === null || _this$defaultWorkflow2 === void 0 ? void 0 : _this$defaultWorkflow2.id;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions(useWorkflowStore, {
    loadAllWorkflows: 'load',
    deleteWorkflowStatusByModule: 'deleteWorkflowStatusByModule',
    createWorkflowStatusByModule: 'createWorkflowStatusByModule'
  })), {}, {
    statusMoved: function statusMoved(_ref) {
      var moved = _ref.moved;
      this.workflowStatus = _objectSpread(_objectSpread({}, moved.element), {}, {
        order: moved.newIndex + 1
      });
      return this.updateWorkflowStatusIndex();
    },
    updateWorkflowStatusIndex: function updateWorkflowStatusIndex() {
      var _this = this;
      return updateWorkflowStatus(this.module, this.workflowStatus).then(function () {
        _this.$notify({
          title: ["".concat(_this.translate.workflowStatus, " ").concat(_this.translate.updated), getWorkflowStatusTitle(_this.workflowStatus.title)]
        });
      }).then(this.loadAllWorkflows).catch(this.handleError);
    },
    updateTitle: function updateTitle(status, value) {
      var _this2 = this;
      this.updateInProgress = true;
      this.updatedWorkflowStatuses = this.getUpdatedWorkflowStatuses(status, value);
      this.uniqueNames.add(status.title);
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(function () {
        return _this2.updateMultipleTitles();
      }, this.$autoSaveDebounceTime);
    },
    getUpdatedWorkflowStatuses: function getUpdatedWorkflowStatuses(status, value) {
      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'title';
      if (this.updatedWorkflowStatuses.length) {
        return this.updatedWorkflowStatuses.map(function (_ref2) {
          var id = _ref2.id,
            title = _ref2.title,
            finalStatus = _ref2.finalStatus;
          if (id === status.id) return _defineProperty({
            id: id,
            title: title,
            finalStatus: finalStatus
          }, field, value);
          return {
            id: id,
            title: title,
            finalStatus: finalStatus
          };
        });
      }
      return this.workflowStatuses.map(function (_ref4) {
        var id = _ref4.id,
          title = _ref4.title,
          finalStatus = _ref4.finalStatus;
        if (id === status.id) return _defineProperty({
          id: id,
          title: title,
          finalStatus: finalStatus
        }, field, value);
        return {
          id: id,
          title: title,
          finalStatus: finalStatus
        };
      });
    },
    updateMultipleTitles: function updateMultipleTitles() {
      var _this3 = this;
      if (!this.validateMultipleStatuses()) return;
      var workflowStatusesNames = Array.from(this.uniqueNames).map(function (title) {
        return getWorkflowStatusTitle(title);
      }).join(', ');
      return updateWorkflowStatusesByModule(this.module, {
        workflowStatuses: this.updatedWorkflowStatuses
      }).then(this.loadAllWorkflows).then(function () {
        _this3.updatedWorkflowStatuses = [];
        _this3.$notify({
          title: ["".concat(_this3.translate.workflowStatuses, " ").concat(_this3.translate.updated), workflowStatusesNames],
          focus: false
        });
        _this3.uniqueNames.clear();
      }).catch(this.handleError).finally(function () {
        return _this3.updateInProgress = false;
      });
    },
    createStatus: function createStatus() {
      var _this4 = this;
      if (!this.validateStatus(this.newStatusName)) return;
      return this.createWorkflowStatusByModule(this.module, {
        id: null,
        workflowId: this.workflowId,
        title: this.newStatusName
      }).then(function () {
        _this4.$notify({
          title: ["".concat(_this4.translate.workflowStatus, " ").concat(_this4.translate.created.toLowerCase()), getWorkflowStatusTitle(_this4.newStatusName)]
        });
        _this4.newStatusName = '';
      }).catch(this.handleError);
    },
    handleDeleteStatus: function handleDeleteStatus(status) {
      var _this5 = this;
      this.workflowStatus = status;
      this.$confirm(this.translate.deleteWorkflowStatus, "".concat(this.translate.areYouSureYouWantToDelete, " ").concat(getWorkflowStatusTitle(status.title), "?"), function (res) {
        return res && _this5.deleteWorkflowStatus();
      }, {
        buttonOkText: this.translate.delete
      });
    },
    deleteWorkflowStatus: function deleteWorkflowStatus() {
      var _this6 = this;
      return this.deleteWorkflowStatusByModule(this.module, this.workflowStatus.id).then(function () {
        _this6.$notify({
          title: ["".concat(_this6.translate.workflowStatus, " ").concat(_this6.translate.deleted), getWorkflowStatusTitle(_this6.workflowStatus.title)]
        });
      }).catch(this.handleError);
    },
    validateStatus: function validateStatus(statusName) {
      if (!statusName) {
        this.$notify({
          title: this.translate.pleaseFillOutTheTitle,
          type: 'error'
        });
        return false;
      }
      if (this.workflowStatuses.map(function (_ref6) {
        var title = _ref6.title;
        return title;
      }).includes(statusName)) {
        this.$notify({
          title: this.translate.titleHaveToBeUnique,
          type: 'error'
        });
        return false;
      }
      return true;
    },
    validateMultipleStatuses: function validateMultipleStatuses() {
      var titles = this.updatedWorkflowStatuses.map(function (_ref7) {
        var title = _ref7.title;
        return title;
      });
      if (!titles.every(Boolean)) {
        this.$notify({
          title: this.translate.pleaseFillOutTheTitle,
          type: 'error',
          focus: false
        });
        return false;
      }
      var uniqueTitles = new Set(titles);
      if (uniqueTitles.size !== this.updatedWorkflowStatuses.length) {
        this.$notify({
          title: this.translate.titleHaveToBeUnique,
          type: 'error',
          focus: false
        });
        return false;
      }
      return true;
    },
    handleUpdate: function handleUpdate() {
      if (!this.updateTimer || !this.updatedWorkflowStatuses.length) return;
      clearTimeout(this.updateTimer);
      this.updateMultipleTitles();
    },
    handleError: function handleError(err) {
      var _err$response$error, _err$response;
      this.$notify({
        title: (_err$response$error = err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.error) !== null && _err$response$error !== void 0 ? _err$response$error : this.translate.anErrorOccurred,
        type: 'error'
      });
    }
  })
};