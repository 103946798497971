function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { defineStore } from 'pinia';
import { getAll as getActivityTypes, editActivityType, createActivityType, deleteActivityType } from '../api/risma/activityTypes';
import { MODULES } from '@/constants/modules';
import cloneDeep from 'lodash/cloneDeep';
export var useActivityTypesStore = defineStore('activityTypesStore', {
  state: function state() {
    return {
      activityTypes: []
    };
  },
  getters: {
    getActivityTypesByModule: function getActivityTypesByModule(state) {
      return function (module) {
        return state.activityTypes.filter(function (activityType) {
          return activityType.module === module;
        });
      };
    },
    getActivityTypeById: function getActivityTypeById(state) {
      return function (id) {
        return state.activityTypes.find(function (activityType) {
          return +activityType.id === +id;
        });
      };
    },
    controlActivityTypes: function controlActivityTypes() {
      return this.getActivityTypesByModule(MODULES.CONTROLS);
    },
    controlActivityTypesWithFilteredFields: function controlActivityTypesWithFilteredFields() {
      var _this = this;
      return function (filterFn) {
        return _this.getActivityTypesByModule(MODULES.CONTROLS).map(function (at) {
          var _at$fields;
          var fields = (_at$fields = at.fields) === null || _at$fields === void 0 ? void 0 : _at$fields.filter(filterFn);
          return _objectSpread(_objectSpread({}, at), {}, {
            fields: fields
          });
        });
      };
    },
    complianceActivityTypes: function complianceActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE);
    },
    complianceIAControllerActivityTypes: function complianceIAControllerActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_CONTROLLER);
    },
    complianceIAProcessorActivityTypes: function complianceIAProcessorActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_PROCESSOR);
    },
    complianceIASystemActivityTypes: function complianceIASystemActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_IA_SYSTEM);
    },
    complianceIACustomActivityTypes: function complianceIACustomActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_INFORMATION_ASSETS);
    },
    riskActivityTypes: function riskActivityTypes() {
      return this.getActivityTypesByModule(MODULES.RISK);
    },
    executionActivityTypes: function executionActivityTypes() {
      return this.getActivityTypesByModule(MODULES.EXECUTION);
    },
    incidentActivityTypes: function incidentActivityTypes() {
      return this.getActivityTypesByModule(MODULES.INCIDENTS);
    },
    contractActivityTypes: function contractActivityTypes() {
      return this.getActivityTypesByModule(MODULES.CONTRACTS);
    },
    processNodeActivityTypes: function processNodeActivityTypes() {
      return this.getActivityTypesByModule(MODULES.COMPLIANCE_PROCESS_TREE_NODE);
    },
    multipleActivityTypes: function multipleActivityTypes() {
      var _this2 = this;
      return function () {
        for (var _len = arguments.length, modules = new Array(_len), _key = 0; _key < _len; _key++) {
          modules[_key] = arguments[_key];
        }
        return modules.reduce(function (acc, module) {
          return [].concat(_toConsumableArray(acc), [_this2.getActivityTypesByModule(module)]);
        }, []);
      };
    }
  },
  actions: {
    load: function load() {
      var _this3 = this;
      var params = '?data[]=optionalfields&data[]=fields';
      return getActivityTypes(params).then(function (_ref) {
        var list = _ref.list;
        _this3.activityTypes = list;
      });
    },
    set: function set(activityTypes) {
      this.activityTypes = cloneDeep(activityTypes);
    },
    add: function add(activityType) {
      var _this4 = this;
      return createActivityType(activityType).then(function (response) {
        _this4.activityTypes.push(_objectSpread(_objectSpread({}, response), {}, {
          id: +response.id
        }));
      });
    },
    update: function update(id, data) {
      var _this5 = this;
      return editActivityType(id, data).then(function (activityType) {
        var activityTypeIndex = _this5.activityTypes.findIndex(function (a) {
          return a.id == activityType.id;
        });
        _this5.activityTypes[activityTypeIndex] = activityType;
      });
    },
    delete: function _delete(activityTypeId) {
      var _this6 = this;
      return deleteActivityType(activityTypeId).then(function () {
        _this6.activityTypes = _this6.activityTypes.filter(function (a) {
          return a.id != activityTypeId;
        });
      });
    }
  }
});