function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
export function escapeRegex(val) {
  // Proudly stolen from DataTable library
  var reEscapeRegex = new RegExp('(\\' + ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^', '-'].join('|\\') + ')', 'g');
  return val.replace(reEscapeRegex, '\\$1');
}
export function unEscapeRegex(string) {
  return string.replace(/\\(.)/g, '$1');
}
export function isHexColor(str) {
  var regex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/g;
  return regex.test(str);
}
export function prepareFilterRegex(item) {
  var space = '\\s*';
  var oneOrMoreSpaces = '\\s+';
  var comma = '(,)+';
  var semicolon = '(;)+';
  var twoOrMore = '{2,}';
  var exactMatch = "^".concat(item, "$"); // 100% match
  var commaBefore = "".concat(comma).concat(space).concat(item);
  var commaAfter = "".concat(item).concat(comma).concat(space);
  var semicolonBefore = "".concat(semicolon).concat(space).concat(item);
  var semicolonAfter = "".concat(item).concat(semicolon).concat(space);
  var twoOrMoreMatch = "(".concat(item).concat(oneOrMoreSpaces, ")").concat(twoOrMore);

  // Please modify filterString carefully:
  // don't forget to change delta in removeFilterRegex as well if add additional |
  return "".concat(exactMatch, "|").concat(commaBefore, "|").concat(semicolonBefore, "|").concat(commaAfter, "|").concat(semicolonAfter, "|").concat(twoOrMoreMatch, "|");
}
export function removeFilterRegex(data) {
  var SYMBOL_BEGIN = '^';
  var SYMBOL_LAST = '$';
  var delta = 6;
  var result = removeExcessParts(data, delta);
  result = result.map(function (el) {
    if (el) {
      el = unEscapeRegex(el);
      if (el[0] === SYMBOL_BEGIN) {
        el = el.substring(1, el.length);
      }
      if (el[el.length - 1] === SYMBOL_LAST) {
        el = el.substring(0, el.length - 1);
      }
      return el;
    }
  });
  return result;
}
export function removeExcessParts(data, delta) {
  var result = [];
  if (data.length % delta === 0) {
    for (var i = 0; i < data.length; i = i + delta) {
      result.push(data[i]);
    }
  } else {
    result = _toConsumableArray(data);
  }
  return result;
}