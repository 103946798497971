function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'pinia';
import { useUsersStore } from '@/Store/usersStore';
import { useUserStore } from '@/Store/userStore';
import { getTranslate } from './translate';
import { changeUserActiveStatus, anonymizeUser as _anonymizeUser } from '@/api/admin/user';
import { getComplianceProjects as _getComplianceProjects } from '@/api/risma/data';
import { getAllRiskProjects } from '@/api/risk/risk';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import RadioButtons from '@/components/Atoms/Inputs/RadioButtons';
import RismaButton from '@/components/Atoms/Buttons/RismaButton';
import UsersActivitiesList from '@/components/Pages/Admin/UsersActivitiesList';
import { capitalize } from '@/utils';
export default {
  name: 'UsersAnonymization',
  description: 'Page used for anonymizing/deactivating users',
  token: '<users-anonymization />',
  components: {
    RismaTitle: RismaTitle,
    SingleSelect: SingleSelect,
    RadioButtons: RadioButtons,
    RismaButton: RismaButton,
    UsersActivitiesList: UsersActivitiesList
  },
  data: function data() {
    return {
      isLoading: false,
      selectedUserId: null,
      actionSelected: null,
      activities: [],
      complianceProjects: [],
      riskProjects: [],
      capitalize: capitalize,
      translate: getTranslate['UsersAnonymization']()
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(useUsersStore, ['users'])), mapState(useUserStore, ['currentUser'])), {}, {
    filteredUsers: function filteredUsers() {
      var _this = this;
      return this.users.filter(function (user) {
        return (!user.anonymized || user.initials) && user.id !== _this.currentUser.id;
      });
    },
    usersOptions: function usersOptions() {
      return this.filteredUsers.map(function (user) {
        return {
          label: user.display_name,
          value: user.id
        };
      });
    },
    selectedUserData: function selectedUserData() {
      var _this2 = this;
      return this.filteredUsers.find(function (_ref) {
        var id = _ref.id;
        return +_this2.selectedUserId === +id;
      });
    },
    optionsTemplate: function optionsTemplate() {
      return [{
        value: 1,
        action: this.deactivateUser
      }, {
        value: 2,
        action: this.partiallyAnonymizeUser
      }, {
        value: 3,
        action: this.anonymizeAllUserData
      }];
    },
    actionOptions: function actionOptions() {
      if (!this.selectedUserData) return [];
      if (this.selectedUserData.active) {
        return [_objectSpread({
          label: this.translate.deactivateKeepUserData
        }, this.optionsTemplate[0]), _objectSpread({
          label: this.translate.deactivateAnonymizeAllUserDataExceptUserInitials
        }, this.optionsTemplate[1]), _objectSpread({
          label: this.translate.deactivateAnonymizeAllUserData
        }, this.optionsTemplate[2])];
      }
      var nonActiveOptions = [_objectSpread({
        label: this.translate.anonymizeAllUserDataExceptUserInitials
      }, this.optionsTemplate[1]), _objectSpread({
        label: this.translate.anonymizeAllUserData
      }, this.optionsTemplate[2])];
      if (this.selectedUserData.anonymized) {
        return [nonActiveOptions[1]];
      }
      return nonActiveOptions;
    },
    actionSelectedData: function actionSelectedData() {
      var _this3 = this;
      return this.actionOptions.find(function (_ref2) {
        var value = _ref2.value;
        return +_this3.actionSelected === +value;
      });
    }
  }),
  mounted: function mounted() {
    this.getProjects();
  },
  methods: {
    getProjects: function getProjects() {
      var _this4 = this;
      this.isLoading = true;
      return Promise.all([this.getComplianceProjects(), this.getRiskProjects()]).then(function () {
        return _this4.isLoading = false;
      });
    },
    getComplianceProjects: function getComplianceProjects() {
      var _this5 = this;
      return _getComplianceProjects().then(function (_ref3) {
        var list = _ref3.list;
        return _this5.complianceProjects = list;
      });
    },
    getRiskProjects: function getRiskProjects() {
      var _this6 = this;
      return getAllRiskProjects().then(function (_ref4) {
        var list = _ref4.list;
        return _this6.riskProjects = list;
      });
    },
    onUserSelect: function onUserSelect() {
      this.actionSelected = this.actionOptions[0].value;
    },
    onSubmit: function onSubmit() {
      var _this$actionSelectedD,
        _this7 = this;
      this.$confirm((_this$actionSelectedD = this.actionSelectedData) === null || _this$actionSelectedD === void 0 ? void 0 : _this$actionSelectedD.label, this.translate.areYouSureYouWantToContinue, function (res) {
        return res && _this7.actionSelectedData.action();
      });
    },
    deactivateUser: function deactivateUser() {
      var _this8 = this;
      var name = this.selectedUserData.display_name;
      return changeUserActiveStatus(this.selectedUserId, {}, this.selectedUserData).then(function () {
        return _this8.showSuccessPopup("".concat(name, ": ").concat(_this8.translate.userDeactivated));
      }).catch(function (_ref5) {
        var response = _ref5.response;
        return _this8.showErrorPopup(_this8.$trans(response === null || response === void 0 ? void 0 : response.error));
      });
    },
    partiallyAnonymizeUser: function partiallyAnonymizeUser() {
      this.anonymizeUser(true);
    },
    anonymizeAllUserData: function anonymizeAllUserData() {
      this.anonymizeUser(false);
    },
    anonymizeUser: function anonymizeUser(keepInitials) {
      var _this9 = this;
      var name = this.selectedUserData.display_name;
      return _anonymizeUser(this.selectedUserId, {
        anonymize: keepInitials ? 2 : 1
      }).then(function () {
        var message = keepInitials ? _this9.translate.allUserIdentifiableDataAnonymizedExceptUserInitials : _this9.translate.allUserIdentifiableDataAnonymized;
        _this9.showSuccessPopup("".concat(name, ": ").concat(message));
      }).catch(function (_ref6) {
        var response = _ref6.response;
        return _this9.showErrorPopup(_this9.$trans(response === null || response === void 0 ? void 0 : response.error));
      });
    },
    showSuccessPopup: function showSuccessPopup(title) {
      this.$notify({
        type: 'success',
        title: title
      });
    },
    showErrorPopup: function showErrorPopup(title) {
      this.$notify({
        type: 'error',
        title: title
      });
    }
  }
};