import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["type", "disabled", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["risma-button", {
      'w-fit': !$props.isCustomButtonSize
    }]),
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.buttonClicked && $options.buttonClicked.apply($options, arguments);
    })
  }, [_createElementVNode("button", {
    type: $props.func,
    class: _normalizeClass(["".concat($props.type, " risma-button-btn flex items-center active:outline-none rounded-large py-2.5 px-15px font-semibold align-middle whitespace-nowrap text-center border-none shadow-none"), "".concat($props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'), {
      'bg-gray-450': $props.disabled && $props.type === 'util'
    }, {
      'bg-blue-350 hover:bg-blue-650 text-white': $props.type === 'save'
    }, {
      'bg-transparent text-black hover:bg-gray-250': $props.type === 'util'
    }, {
      'bg-orange-50 hover:bg-orange-150 text-white': $props.type === 'danger'
    }, {
      'text-blue-750 bg-transparent': $props.type === 'secondary'
    }]),
    disabled: $props.disabled,
    title: $props.title
  }, [_renderSlot(_ctx.$slots, "default"), _createTextVNode(" " + _toDisplayString($props.text), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_1)], 2 /* CLASS */);
}