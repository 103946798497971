import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_buttons_row = _resolveComponent("buttons-row");
  return _openBlock(), _createBlock(_component_buttons_row, {
    data: $options.buttonsRowData
  }, {
    "button-check": _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "check",
        class: "stroke-2 mr-2"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"]);
}