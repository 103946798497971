import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "input-field-select flex items-center numerical-select-type"
};
var _hoisted_2 = {
  class: "select-label-box rounded-l-none pr-16 pl-3 py-2 text-white font-semibold flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$options$, _$props$options$2;
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_split_button = _resolveComponent("risma-split-button");
  var _component_single_select = _resolveComponent("single-select");
  return _openBlock(), _createElementBlock("div", null, [$props.title ? (_openBlock(), _createBlock(_component_risma_label, {
    key: 0,
    title: $props.title,
    for: $options.inputId
  }, null, 8 /* PROPS */, ["title", "for"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
    class: "rounded-r-none w-full",
    placeholder: $props.inputPlaceholder,
    "model-value": $props.inputValue,
    enabled: $props.inputEnabled,
    maxlength: $props.maxlength,
    type: $props.type,
    tabindex: $props.tabindex,
    "input-id": $options.inputId,
    "enable-currency-template": $props.enableCurrencyTemplate,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('inputChange', $event);
    }),
    onOnEnter: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('onEnter');
    })
  }, null, 8 /* PROPS */, ["placeholder", "model-value", "enabled", "maxlength", "type", "tabindex", "input-id", "enable-currency-template"]), $props.options && $props.options.length < 2 ? (_openBlock(), _createBlock(_component_risma_split_button, {
    key: 0,
    class: "rounded-l-none split-button italic",
    "disabled-dropdown": true,
    tabindex: $props.tabindex,
    text: ((_$props$options$ = $props.options[0]) === null || _$props$options$ === void 0 ? void 0 : _$props$options$.label) || ((_$props$options$2 = $props.options[0]) === null || _$props$options$2 === void 0 ? void 0 : _$props$options$2.value) || ''
  }, null, 8 /* PROPS */, ["tabindex", "text"])) : (_openBlock(), _createBlock(_component_single_select, {
    key: 1,
    class: "numerical-select",
    "chevron-custom-look": "text-white stroke-3 right-0.7rem",
    "model-value": $props.optionValue,
    options: $props.options,
    disabled: !$props.optionEnabled,
    "with-reset": $props.withReset,
    "un-sorted": $props.unSorted,
    tabindex: $props.tabindex,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('optionChange', $event);
    })
  }, {
    singleLabel: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", {
        class: _normalizeClass(["select-label text-sm text-white font-semibold align-middle whitespace-nowrap", {
          italic: !$props.optionValue
        }])
      }, _toDisplayString($options.selectedOptionLabel || _ctx.$trans($props.optionPlaceholder)), 3 /* TEXT, CLASS */)])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "options", "disabled", "with-reset", "un-sorted", "tabindex"]))])]);
}