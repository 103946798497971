function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { sortAlphabetic } from '@/utils/sort';
import RismaVueMultiselect from '@/components/Atoms/Inputs/RismaVueMultiselect/RismaVueMultiselect';
import RismaVueMultiselectPopUp from '@/components/Atoms/Inputs/RismaVueMultiselect/RismaVueMultiselectPopUp';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaSelect',
  introduction: 'Select for selecting things',
  description: "Risma-select creates a select from options and selected options given.\n        When selecting items in the select, it will emit a \"selected\" event, with the selected items.\n        To make a custom markup of option need to pass '<template v-slot:slot:optionCustom=\"props\">HTML</template>. '",
  token: "<risma-select :options='testOptions' :selectedOptions='testSelected' labelKey=\"option\" placeholder=\"Test\" @selected=\"someFunc\"/>'\n        testOptions: [\n            {id:'1', option:'Hello'},\n            {id:'2', option:'To'},\n            {id:'3', option:'You'}\n        ],\n        testSelected:[\n            {id:'2', option:'To'}'\n        ]",
  components: {
    RismaVueMultiselect: RismaVueMultiselect,
    RismaVueMultiselectPopUp: RismaVueMultiselectPopUp
  },
  directives: {
    clickOutside: clickOutside
  },
  props: {
    options: {
      type: Array,
      required: true,
      note: 'All options given to the select'
    },
    enableSelectAll: {
      type: Boolean,
      default: true,
      note: 'Boolean to determine if show `Select All` feature in select'
    },
    confirmSelectAll: {
      type: Boolean,
      required: false,
      default: false,
      note: 'If show confirmation popup on `Select All`'
    },
    selectedOptions: {
      type: [Array, Object],
      default: function _default() {
        return [];
      },
      note: 'The already selected options'
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
      note: 'Key to compare options by'
    },
    labelKey: {
      type: String,
      required: true,
      note: 'The key to create the labels by'
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
      note: 'Label that is visible when no options are selected'
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
      note: 'Boolean to determine if the select should close when an option is selected'
    },
    modelValue: {
      type: Array,
      required: false,
      default: null,
      note: 'V-model support. Kind of. Only works one-way'
    },
    maxVisibleTags: {
      required: false,
      type: Number,
      default: 6,
      note: 'the max number of selected tags to be displayed. After the number has been reached the select will display: "{number} selected", instead of tags. if prop is -1 it will display all'
    },
    searchable: {
      required: false,
      default: true,
      type: Boolean,
      note: 'should the list e searchable?'
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: false,
      note: 'group-select is used to define if selecting the group label should select/unselect all values in the group, or do nothing.'
    },
    groupValues: {
      type: String,
      required: false,
      default: '',
      note: 'group-values should point to the group’s option list.'
    },
    groupLabel: {
      type: String,
      required: false,
      default: '',
      note: 'The key to create the labels by'
    },
    unSorted: {
      required: false,
      default: false,
      type: Boolean,
      note: 'Do not sort items by label - keep in the order that was given'
    },
    openDirection: {
      required: false,
      default: '',
      type: String,
      note: 'Fixed opening direction (instead of auto). Options are "above"/"top" or "below"/"bottom'
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
      note: 'Set to true to disable switch'
    },
    isSelectedSorted: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Decide whether we should show selected values sorted or not, used on dataflow'
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    truncateLength: {
      type: Number,
      required: false,
      default: 200
    },
    hasTagWrapNewLine: {
      type: Boolean,
      required: false,
      default: true
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    helpText: {
      type: String,
      required: false,
      default: ''
    },
    addNewOptionLabel: {
      type: String,
      default: '',
      required: false
    },
    newOptionPosition: {
      type: String,
      default: 'top',
      required: false,
      note: 'newOptionPosition can be top/bottom'
    },
    lockedOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    searchFields: {
      type: Array,
      default: null
    },
    allDataShown: {
      type: Boolean,
      default: true
    },
    isLazyLoadingShown: {
      type: Boolean,
      default: false
    },
    titleType: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['selected', 'update:modelValue', 'tag', 'initialized', 'close', 'tagClicked', 'open', 'load', 'update:searchValue'],
  data: function data() {
    return {
      selected: [],
      values: null,
      isOpen: false
    };
  },
  watch: {
    options: {
      deep: true,
      handler: function handler() {
        this.refreshValues();
      }
    },
    selectedOptions: {
      deep: true,
      handler: function handler() {
        var _this = this;
        if (this.selectedOptions) {
          this.selected = this.getSelectedOptions();
        } else {
          this.selected = [];
        }
        this.$nextTick(function () {
          return _this.refreshValues();
        });
      }
    }
  },
  created: function created() {
    this.selected = this.getSelectedOptions();
    this.refreshValues();
  },
  mounted: function mounted() {
    this.$emit('initialized', this.$el);
  },
  methods: {
    getSelectedOptions: function getSelectedOptions() {
      return this.selectedOptions.slice(0);
    },
    refreshValues: function refreshValues() {
      this.values = this.groupValues ? this.sortGroupValuesBySelected() : this.sortValuesBySelected();
    },
    sortGroupValuesBySelected: function sortGroupValuesBySelected() {
      var _this2 = this;
      var groups = this.options.slice(0);
      var sortedGroups = [];
      groups.forEach(function (group) {
        var notSelectedValues = [];
        var values = group[_this2.groupValues].slice(0);
        values.forEach(function (value) {
          notSelectedValues.push(value);
        });
        sortedGroups.push(_defineProperty(_defineProperty({}, _this2.groupLabel, group[_this2.groupLabel]), _this2.groupValues, notSelectedValues));
      });
      return sortedGroups;
    },
    sortValuesBySelected: function sortValuesBySelected() {
      var values = this.unSorted ? this.options.slice(0) : sortAlphabetic(this.options.slice(0), this.labelKey);
      return values;
    },
    handleSelected: function handleSelected(data) {
      this.selected = data;
      this.emit();
    },
    handleClose: function handleClose() {
      if (!this.isOpen) return;
      this.isOpen = false;
      this.$emit('close');
    },
    handleOpen: function handleOpen() {
      this.isOpen = true;
      this.$emit('open');
    },
    toggle: function toggle() {
      if (this.disabled) return;
      this.isOpen = !this.isOpen;
    },
    emit: function emit() {
      this.$emit('selected', this.selected);
      this.$emit('update:modelValue', this.selected);
    }
  }
};