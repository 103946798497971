import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_notification, {
    type: "error"
  }, {
    default: _withCtx(function () {
      return _cache[0] || (_cache[0] = [_createTextVNode(" This is an error notification with possibility to hide it ")]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_notification, {
    closeable: false,
    type: "warning"
  }, {
    default: _withCtx(function () {
      return _cache[1] || (_cache[1] = [_createTextVNode(" This is a warning notification WITHOUT possibility to hide it ")]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_notification, {
    type: "info"
  }, {
    default: _withCtx(function () {
      return _cache[2] || (_cache[2] = [_createTextVNode(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ")]);
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_notification, {
    type: "success"
  }, {
    default: _withCtx(function () {
      return _cache[3] || (_cache[3] = [_createTextVNode("This is a success notification.")]);
    }),
    _: 1 /* STABLE */
  })]);
}