import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "vertical-menu relative -ml-4 mr-10"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  key: 0,
  class: "vertical-menu-group ml-5"
};
var _hoisted_4 = {
  key: 0,
  class: "bottom-0 fixed w-20vw-2rem bg-white mt-2 ml-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$bottomItems;
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_vertical_menu_item = _resolveComponent("vertical-menu-item");
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (group, i) {
    return _openBlock(), _createElementBlock("div", {
      key: "vertical-menu-group-".concat(i)
    }, [group.groupTitle ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("div", {
      class: "flex flex-row items-center cursor-pointer",
      onClick: function onClick($event) {
        return $options.handleGroupToggle(group.groupTitle);
      }
    }, [_createVNode(_component_risma_title, {
      title: group.groupTitle,
      class: "text-21px py-2.5 px-15px",
      type: "big"
    }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
      class: "mx-2 group-chevron",
      icon: $options.isGroupClosed(group.groupTitle) ? 'chevron-up' : 'chevron-down'
    }, null, 8 /* PROPS */, ["icon"])], 8 /* PROPS */, _hoisted_2), !$options.isGroupClosed(group.groupTitle) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.groupItems, function (item, j) {
      return _openBlock(), _createBlock(_component_vertical_menu_item, {
        key: "vertical-menu-item-".concat(j),
        item: item,
        "selected-id": $props.selectedId,
        removable: $props.removable,
        "exact-match": $props.exactMatch,
        onRemoveItem: $options.removeItem,
        onChangeItem: $options.changeItem
      }, null, 8 /* PROPS */, ["item", "selected-id", "removable", "exact-match", "onRemoveItem", "onChangeItem"]);
    }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_vertical_menu_item, {
      key: 1,
      item: group,
      "selected-id": $props.selectedId,
      removable: $props.removable,
      "exact-match": $props.exactMatch,
      onRemoveItem: $options.removeItem,
      onChangeItem: $options.changeItem
    }, null, 8 /* PROPS */, ["item", "selected-id", "removable", "exact-match", "onRemoveItem", "onChangeItem"]))]);
  }), 128 /* KEYED_FRAGMENT */))]), (_$props$bottomItems = $props.bottomItems) !== null && _$props$bottomItems !== void 0 && _$props$bottomItems.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bottomItems, function (item, i) {
    return _openBlock(), _createElementBlock("div", {
      key: "vertical-menu-bottom-group-".concat(i)
    }, [_createVNode(_component_router_link, {
      to: item.url,
      class: "flex items-center my-4"
    }, {
      default: _withCtx(function () {
        return [_createVNode(_component_risma_title, {
          title: item.title,
          type: "medium",
          class: "mr-4"
        }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_feather_icon, {
          icon: "arrow-right"
        })];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)]);
}