import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex items-center flex-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_date_range_picker = _resolveComponent("risma-date-range-picker");
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.ranges, function (range) {
    return _openBlock(), _createElementBlock("div", {
      key: range.id,
      class: "flex items-center mr-5 my-1"
    }, [_createVNode(_component_risma_date_range_picker, {
      ref_for: true,
      ref: "ranges",
      "start-date": range.dateStart,
      "end-date": range.dateEnd,
      class: "mr-1",
      onChanged: function onChanged($event) {
        return $options.changedDates(range.id, $event);
      }
    }, null, 8 /* PROPS */, ["start-date", "end-date", "onChanged"]), $data.ranges.length > $props.minRangesNumber ? (_openBlock(), _createBlock(_component_feather_icon, {
      key: 0,
      icon: "x",
      class: "cursor-pointer",
      onClick: function onClick($event) {
        return $options.removeRange(range.id);
      }
    }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)]);
  }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_feather_icon, {
    icon: "plus",
    class: "stroke-2 cursor-pointer",
    onClick: $options.onCreateRange
  }, null, 8 /* PROPS */, ["onClick"])]);
}