import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "flex mb-2"
};
var _hoisted_2 = {
  key: 1,
  class: "grid grid-cols-3 gap-4"
};
var _hoisted_3 = {
  class: "flex relative"
};
var _hoisted_4 = {
  class: "hidden"
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  class: "mr-2"
};
var _hoisted_8 = {
  class: "mr-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  var _component_input_field = _resolveComponent("input-field");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_router_link = _resolveComponent("router-link");
  var _component_information_asset_creation_modal = _resolveComponent("information-asset-creation-modal");
  var _component_preview_modal_link = _resolveComponent("preview-modal-link");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_paginated_table = _resolveComponent("paginated-table");
  var _component_main_well = _resolveComponent("main-well");
  var _component_info_tool_tip = _resolveComponent("info-tool-tip");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _openBlock(), _createElementBlock("div", null, [$options.detailLoadedError ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.detailLoadedError), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_input_field, {
    modelValue: $data.filterString,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
      return $data.filterString = $event;
    }), $options.onSearch],
    placeholder: $data.translate.search,
    class: "ml-auto",
    type: "text"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "onUpdate:modelValue"])]), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.data, function (section, key) {
    return _openBlock(), _createBlock(_component_main_well, {
      id: section.label,
      key: key,
      class: "w-full"
    }, {
      default: _withCtx(function () {
        return [_createElementVNode("span", _hoisted_3, [_createVNode(_component_risma_title, {
          title: section.type === $data.AssetsTypes.INFORMATION_ASSETS ? $options.capitalize(section.label) : _ctx.$trans($options.capitalize(section.label))
        }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_router_link, {
          to: $options.getExtendedViewLink(section),
          class: "ml-auto"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_feather_icon, {
              icon: "maximize-2",
              height: "30",
              width: "30"
            })];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]), $options.canCreate(section.type, section.activityTypeId || 0) ? (_openBlock(), _createBlock(_component_feather_icon, {
          key: 0,
          icon: "plus",
          height: "30",
          width: "30",
          class: "cursor-pointer",
          onClick: _withModifiers(function ($event) {
            return $data.createModal = key;
          }, ["stop"])
        }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), $data.createModal === key ? (_openBlock(), _createBlock(_component_information_asset_creation_modal, {
          key: 1,
          type: section.type,
          "data-processors": section.fullList && section.fullList.length ? section.fullList : section.list,
          "activity-type-id": section.activityTypeId,
          "default-questionnaire-id": section.defaultQuestionnaireId,
          onDismiss: $options.dismissCreateModal,
          onCreated: $options.update
        }, null, 8 /* PROPS */, ["type", "data-processors", "activity-type-id", "default-questionnaire-id", "onDismiss", "onCreated"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_paginated_table, {
          "dataset-table": $options.dataset.find(function (dt) {
            return dt.id === section.id;
          }).list,
          columns: _ctx.formatColumnsIA(section.type),
          "session-cookie-name": "pagination-page-".concat(section.label).concat($options.myIaExtention),
          "populate-with-empty": false,
          "backend-pagination": section.type !== $data.AssetsTypes.INFORMATION_ASSETS,
          total: $options.dataset.find(function (dt) {
            return dt.id === section.id;
          }).total,
          "page-size": $data.PAGE_SIZE,
          "show-child-default-value": $options.showAllChildren,
          class: "flex-grow",
          onTrigger: $options.deleteItem,
          onChangePage: function onChangePage($event) {
            return $options.changePage($event, section.type);
          }
        }, {
          name: _withCtx(function (_ref) {
            var slotData = _ref.slotData;
            return [_createVNode(_component_preview_modal_link, {
              activity: slotData.data,
              class: "hover:underline",
              onUpdated: _cache[1] || (_cache[1] = function ($event) {
                return $data.updated = true;
              }),
              onDismissModal: $options.dismissPreviewModal
            }, {
              default: _withCtx(function () {
                return [_createTextVNode(_toDisplayString(slotData.name), 1 /* TEXT */)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["activity", "onDismissModal"])];
          }),
          trafficlight: _withCtx(function (_ref2) {
            var slotData = _ref2.slotData;
            return [_createVNode(_component_traffic_light, {
              color: slotData.color
            }, null, 8 /* PROPS */, ["color"]), _createElementVNode("p", _hoisted_4, _toDisplayString(slotData.value) + "-" + _toDisplayString(slotData.label), 1 /* TEXT */)];
          }),
          actions: _withCtx(function (_ref3) {
            var slotData = _ref3.slotData;
            return [slotData.disableAction ? (_openBlock(), _createElementBlock("div", _hoisted_5)) : (_openBlock(), _createBlock(_component_feather_icon, {
              key: 1,
              class: "text-red-50 stroke-2 cursor-pointer",
              title: $data.translate.delete,
              icon: "x",
              onClick: function onClick($event) {
                return $options.deleteItem(slotData);
              }
            }, null, 8 /* PROPS */, ["title", "onClick"]))];
          }),
          processes: _withCtx(function (_ref4) {
            var _slotData$nodes;
            var slotData = _ref4.slotData;
            return [!(slotData !== null && slotData !== void 0 && slotData.index) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", _hoisted_7, _toDisplayString(slotData), 1 /* TEXT */)])) : _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 1,
              ref_for: true,
              ref: "helptooltip".concat(slotData.index, "-").concat(section.type),
              class: "flex"
            }, [_createElementVNode("span", _hoisted_8, _toDisplayString(slotData.count), 1 /* TEXT */), (_slotData$nodes = slotData.nodes) !== null && _slotData$nodes !== void 0 && _slotData$nodes.length ? (_openBlock(), _createBlock(_component_feather_icon, {
              key: 0,
              icon: "info",
              class: "stroke-3 text-blue-750 cursor-pointer",
              onClick: function onClick($event) {
                return _ctx.handleHelpCircleClick(slotData.nodes, slotData.index, section.type);
              }
            }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, function (evt) {
              return _ctx.handleClickOusideToolTip(slotData.index, evt);
            }]])];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["dataset-table", "columns", "session-cookie-name", "backend-pagination", "total", "page-size", "show-child-default-value", "onTrigger", "onChangePage"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createVNode(_component_info_tool_tip, {
    "tool-tip-data": _ctx.currentToolTipData,
    onRefresh: $options.getData,
    "onReset:toolTipData": _ctx.resetToolTip
  }, null, 8 /* PROPS */, ["tool-tip-data", "onRefresh", "onReset:toolTipData"])]);
}