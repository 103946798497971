import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_notification = _resolveComponent("notification");
  return $props.missingRequiredFields.length ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: "error",
    closeable: true
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString($data.translate.formHasRequiredFields), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($data.translate.followingFieldsAreMissingValues), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($props.missingRequiredFields.join(', ')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true);
}