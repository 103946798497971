import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  key: 1,
  class: "flex"
};
var _hoisted_2 = {
  key: 0,
  class: "w-1/5 flex flex-col h-fit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_loading_indicator_large = _resolveComponent("loading-indicator-large");
  var _component_vertical_menu = _resolveComponent("vertical-menu");
  var _component_router_view = _resolveComponent("router-view");
  return !$data.isLoaded ? (_openBlock(), _createBlock(_component_loading_indicator_large, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [$options.hasQuiz ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_vertical_menu, {
    items: $options.menuItems,
    "bottom-items": $options.bottomItems,
    class: "w-215 flex-shrink-0 overflow-y-auto"
  }, null, 8 /* PROPS */, ["items", "bottom-items"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass({
      'w-4/5': $options.hasQuiz
    })
  }, [_createVNode(_component_router_view, {
    "data-list": $data.requests
  }, null, 8 /* PROPS */, ["data-list"])], 2 /* CLASS */)]));
}