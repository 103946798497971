// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.security-groups-project[data-v-35d28429] {
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
}
.security-groups-project .security-groups-project-item[data-v-35d28429] {
  flex-basis: 200px;
  padding: 0 5px 0 0;
}
.security-groups-project .security-groups-project-item[data-v-35d28429]  input {
  margin: 0 0 0 0;
  height: 42px;
  width: 300px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
