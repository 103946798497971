// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-reports-threts-body[data-v-fe9d5852] {
  padding: 1rem 0 0 0;
}
.risk-reports-threts-body[data-v-fe9d5852]  .datatable-body p {
  margin-bottom: 0;
}
[data-v-fe9d5852]  .horizontal {
  width: 250px;
}
[data-v-fe9d5852]  .vertical {
  width: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
