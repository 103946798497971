import { getTranslate } from './translate';
import RismaTitle from '@/components/Atoms/Titles/RismaTitle';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import Modal from '@/components/Molecules/Modal/Modal';
export default {
  name: 'MultipleQuestionnaireSelector',
  token: "<multiple-questionnaire-selector>\n        </multiple-questionnaire-selector>",
  components: {
    SingleSelect: SingleSelect,
    RismaTitle: RismaTitle,
    FeatherIcon: FeatherIcon,
    Modal: Modal
  },
  props: {
    questionnaireOptions: {
      type: Array,
      required: false,
      note: 'All questionnaires for node/asset',
      default: function _default() {
        return [];
      }
    },
    selectedQuestionnaires: {
      type: Array,
      required: false,
      note: 'Added questionnaires on current node/asset',
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Is the functionality disabled'
    }
  },
  emits: ['removeQuestionnaire', 'addQuestionnaire'],
  data: function data() {
    return {
      value: null,
      confirmMessage: '',
      showModal: false,
      questionnaireToRemove: null,
      translate: getTranslate['MultipleQuestionnaireSelector']()
    };
  },
  computed: {
    formattedQuestionnaireOptions: function formattedQuestionnaireOptions() {
      var _this = this;
      return this.questionnaireOptions.filter(function (questionnaire) {
        return !_this.selectedQuestionnaires.find(function (q) {
          return q.questionnaireId == questionnaire.id;
        });
      }).map(function (option) {
        return {
          label: option.title,
          value: option.id
        };
      });
    },
    availableQuestionnairesMap: function availableQuestionnairesMap() {
      var result = {};
      this.questionnaireOptions.forEach(function (questionnaire) {
        result[questionnaire.id] = questionnaire;
      });
      return result;
    },
    removeHeader: function removeHeader() {
      var questionnaireToRemove = this.availableQuestionnairesMap[this.questionnaireToRemove];
      return questionnaireToRemove ? questionnaireToRemove.title : '';
    }
  },
  methods: {
    toggleModal: function toggleModal() {
      this.showModal = !this.showModal;
    },
    addQuestionnaire: function addQuestionnaire() {
      if (this.value) this.$emit('addQuestionnaire', this.value);
      this.value = null;
      this.toggleModal();
    },
    showRemoveQuestionnaireAlert: function showRemoveQuestionnaireAlert(id) {
      var _this2 = this;
      this.questionnaireToRemove = id;
      this.$confirm(this.removeHeader, this.translate.areYouSureYouWantToRemoveThisQuestionnaire, function (res) {
        return res ? _this2.removeQuestionnaire() : _this2.questionnaireToRemove = null;
      }, {
        buttonOkText: this.translate.yes,
        buttonDismissText: this.translate.no
      });
    },
    removeQuestionnaire: function removeQuestionnaire() {
      this.$emit('removeQuestionnaire', this.questionnaireToRemove);
      this.questionnaireToRemove = null;
    }
  }
};