// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risma-progress-bar-total[data-v-a4e3df78] {
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  bottom: 0px;
  background: #f25050;
}
.risma-progress-bar-done[data-v-a4e3df78] {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 5px;
  background: #4CB3C0;
}
.risma-progress-bar-label[data-v-a4e3df78] {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  color: #737373;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
