import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "complete-control"
};
var _hoisted_2 = {
  key: 1,
  class: "complete-control-body"
};
var _hoisted_3 = {
  key: 0,
  class: "complete-control-section"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  key: 1,
  class: "complete-control-section"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = {
  key: 2,
  class: "complete-control-section"
};
var _hoisted_8 = ["innerHTML"];
var _hoisted_9 = {
  key: 3,
  class: "complete-control-section"
};
var _hoisted_10 = ["innerHTML"];
var _hoisted_11 = {
  class: "flex flex-col"
};
var _hoisted_12 = {
  class: "prev-completed-date inline-block w-9.5rem mr-2 text-gray-650"
};
var _hoisted_13 = ["onClick"];
var _hoisted_14 = {
  class: "complete-control-section-expand-content"
};
var _hoisted_15 = {
  class: "prev-history-table"
};
var _hoisted_16 = {
  class: "date-cell"
};
var _hoisted_17 = {
  class: "action-cell"
};
var _hoisted_18 = {
  class: "user-cell"
};
var _hoisted_19 = {
  class: "comments-cell"
};
var _hoisted_20 = {
  class: "deadline-cell"
};
var _hoisted_21 = {
  class: "attachments-cell"
};
var _hoisted_22 = {
  class: "date-cell"
};
var _hoisted_23 = {
  class: "action-cell"
};
var _hoisted_24 = {
  class: "user-cell"
};
var _hoisted_25 = {
  class: "comments-cell"
};
var _hoisted_26 = {
  class: "deadline-cell"
};
var _hoisted_27 = {
  class: "attachments-cell"
};
var _hoisted_28 = ["href"];
var _hoisted_29 = {
  key: 5,
  class: "complete-control-section-expand-content"
};
var _hoisted_30 = {
  class: "review-history-table text-left"
};
var _hoisted_31 = {
  class: "date-cell"
};
var _hoisted_32 = {
  class: "action-cell"
};
var _hoisted_33 = {
  class: "user-cell"
};
var _hoisted_34 = {
  class: "comments-cell"
};
var _hoisted_35 = {
  class: "deadline-cell"
};
var _hoisted_36 = {
  class: "attachments-cell"
};
var _hoisted_37 = {
  class: "date-cell"
};
var _hoisted_38 = {
  class: "action-cell"
};
var _hoisted_39 = {
  class: "user-cell"
};
var _hoisted_40 = {
  class: "comments-cell"
};
var _hoisted_41 = {
  class: "deadline-cell"
};
var _hoisted_42 = {
  class: "attachments-cell"
};
var _hoisted_43 = ["href"];
var _hoisted_44 = {
  class: "pb-2"
};
var _hoisted_45 = {
  class: "flex pb-4 w-full"
};
var _hoisted_46 = {
  class: "w-1/2 mr-4"
};
var _hoisted_47 = {
  class: "flex justify-end"
};
var _hoisted_48 = {
  class: "w-1/2 ml-4"
};
var _hoisted_49 = {
  class: "complete-control-section pt-2"
};
var _hoisted_50 = {
  key: 6,
  class: "border-t-2 pt-4"
};
var _hoisted_51 = {
  class: "pb-4"
};
var _hoisted_52 = {
  class: "py-2"
};
var _hoisted_53 = {
  class: "flex mt-2"
};
var _hoisted_54 = {
  class: "w-32"
};
var _hoisted_55 = {
  class: "w-32"
};
var _hoisted_56 = {
  class: "grow"
};
var _hoisted_57 = {
  key: 7,
  class: "complete-control-section !mb-2"
};
var _hoisted_58 = {
  key: 8,
  class: "complete-control-section"
};
var _hoisted_59 = {
  class: "complete-control-section-body"
};
var _hoisted_60 = {
  class: "complete-control-section complete-control-section-action flow-root"
};
var _hoisted_61 = {
  class: "flex justify-end items-center"
};
var _hoisted_62 = {
  key: 0,
  class: "py-2"
};
var _hoisted_63 = ["text", "disabled", "title"];
var _hoisted_64 = {
  key: 1,
  class: "py-2"
};
var _hoisted_65 = ["disabled", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$options$currentActi;
  var _component_loading_indicator_small = _resolveComponent("loading-indicator-small");
  var _component_notification = _resolveComponent("notification");
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_link_item = _resolveComponent("link-item");
  var _component_collapsible_panel = _resolveComponent("collapsible-panel");
  var _component_attachment_section = _resolveComponent("attachment-section");
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _component_risma_button = _resolveComponent("risma-button");
  var _component_attachment_item = _resolveComponent("attachment-item");
  var _component_text_box = _resolveComponent("text-box");
  var _component_risma_label = _resolveComponent("risma-label");
  var _component_traffic_light_selector = _resolveComponent("traffic-light-selector");
  var _component_traffic_light = _resolveComponent("traffic-light");
  var _component_static_table = _resolveComponent("static-table");
  var _component_activity_custom_fields = _resolveComponent("activity-custom-fields");
  var _component_risma_split_button = _resolveComponent("risma-split-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_loading_indicator_small, null, null, 512 /* NEED_PATCH */), [[_vShow, !$data.isLoaded]]), $data.notificationMessage ? (_openBlock(), _createBlock(_component_notification, {
    key: 0,
    type: $data.notificationType,
    closeable: false
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($data.notificationMessage), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"])) : _createCommentVNode("v-if", true), $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.control.mothercontrol && $data.control.mothercontrol.description ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_risma_title, {
    title: $data.translate.descriptionFromMotherControl,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "complete-control-section-body list-reset-styles",
    innerHTML: $data.control.mothercontrol.description
  }, null, 8 /* PROPS */, _hoisted_4)])) : _createCommentVNode("v-if", true), $data.control.mothercontrol && $options.isExpectedEvidenceShow($data.control.mothercontrol.expectedEvidence) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_risma_title, {
    title: $data.translate.expectedEvidenceFromMotherControl,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "complete-control-section-body list-reset-styles",
    innerHTML: $data.control.mothercontrol.expectedEvidence
  }, null, 8 /* PROPS */, _hoisted_6)])) : _createCommentVNode("v-if", true), $options.isExistedAndFilled($data.control.description) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_risma_title, {
    title: $data.translate.description,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "complete-control-section-body list-reset-styles",
    innerHTML: $data.control.description
  }, null, 8 /* PROPS */, _hoisted_8)])) : _createCommentVNode("v-if", true), $options.isExpectedEvidenceShow($data.control.expectedEvidence) ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_risma_title, {
    title: $data.translate.expectedEvidence,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", {
    class: "complete-control-section-body list-reset-styles",
    innerHTML: $data.control.expectedEvidence
  }, null, 8 /* PROPS */, _hoisted_10)])) : _createCommentVNode("v-if", true), $options.hasHistory ? (_openBlock(), _createBlock(_component_collapsible_panel, {
    key: 4,
    class: "mb-4"
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.previousCompletions,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.prevCompletions, function (previousCompletion) {
        return _openBlock(), _createElementBlock("div", {
          key: previousCompletion.id,
          class: _normalizeClass([{
            active: previousCompletion.id === $data.activeHistoryItem.id
          }, "complete-control-section-expand-content prev-completions-wrap"])
        }, [_createElementVNode("span", _hoisted_12, _toDisplayString($options.formattedDate(previousCompletion.completed, $data.control.deadlineTimezone)), 1 /* TEXT */), _createElementVNode("a", {
          href: "#",
          class: "show-history-link text-blue-750",
          onClick: _withModifiers(function ($event) {
            return $options.showFullHistory(previousCompletion);
          }, ["prevent"])
        }, _toDisplayString($data.translate.showHistory), 9 /* TEXT, PROPS */, _hoisted_13)], 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_14, [_createElementVNode("table", _hoisted_15, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_16, [_createVNode(_component_risma_title, {
        title: $data.translate.date,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_17, [_createVNode(_component_risma_title, {
        title: $data.translate.action,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_18, [_createVNode(_component_risma_title, {
        title: $data.translate.user,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_19, [_createVNode(_component_risma_title, {
        title: $data.translate.comments,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_20, [_createVNode(_component_risma_title, {
        title: $data.translate.newDeadline,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_21, [_createVNode(_component_risma_title, {
        title: $data.translate.urlattachments,
        type: "medium"
      }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.activeHistoryItem.reviews, function (review) {
        return _openBlock(), _createElementBlock("tr", {
          key: review.id
        }, [_createElementVNode("td", _hoisted_22, _toDisplayString($options.formattedDate(review.created, $data.control.deadlineTimezone)), 1 /* TEXT */), _createElementVNode("td", _hoisted_23, _toDisplayString(review._actionHuman), 1 /* TEXT */), _createElementVNode("td", _hoisted_24, _toDisplayString($options.findUserNameById(review.userId)), 1 /* TEXT */), _createElementVNode("td", _hoisted_25, _toDisplayString(review.comment), 1 /* TEXT */), _createElementVNode("td", _hoisted_26, _toDisplayString(review.reviewDeadline ? $options.formattedDate(review.reviewDeadline, $data.control.deadlineTimezone) : ''), 1 /* TEXT */), _createElementVNode("td", _hoisted_27, [_createElementVNode("a", {
          href: review.url,
          target: "_blank"
        }, _toDisplayString(review.urlTitle), 9 /* TEXT, PROPS */, _hoisted_28), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(review.files, function (file) {
          return _openBlock(), _createBlock(_component_link_item, {
            key: file.id,
            title: file.name,
            icon: file.mimeType === 'text/uri-list' ? 'link' : 'file',
            link: $options.getAttachmentLink(file)
          }, null, 8 /* PROPS */, ["title", "icon", "link"]);
        }), 128 /* KEYED_FRAGMENT */))])]);
      }), 128 /* KEYED_FRAGMENT */))])])])])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $options.hasReviews ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createVNode(_component_risma_title, {
    title: $data.translate.reviewHistory,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("table", _hoisted_30, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", _hoisted_31, [_createVNode(_component_risma_title, {
    title: $data.translate.date,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_32, [_createVNode(_component_risma_title, {
    title: $data.translate.action,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_33, [_createVNode(_component_risma_title, {
    title: $data.translate.user,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_34, [_createVNode(_component_risma_title, {
    title: $data.translate.comments,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_35, [_createVNode(_component_risma_title, {
    title: $data.translate.newDeadline,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])]), _createElementVNode("th", _hoisted_36, [_createVNode(_component_risma_title, {
    title: $data.translate.urlattachments,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.control.reviews, function (review) {
    return _openBlock(), _createElementBlock("tr", {
      key: review.id
    }, [_createElementVNode("td", _hoisted_37, _toDisplayString($options.formattedDate(review.created, $data.control.deadlineTimezone)), 1 /* TEXT */), _createElementVNode("td", _hoisted_38, _toDisplayString(review._actionHuman), 1 /* TEXT */), _createElementVNode("td", _hoisted_39, _toDisplayString($options.findUserNameById(review.userId)), 1 /* TEXT */), _createElementVNode("td", _hoisted_40, _toDisplayString(review.comment), 1 /* TEXT */), _createElementVNode("td", _hoisted_41, _toDisplayString(review.reviewDeadline ? $options.formattedDate(review.reviewDeadline, $data.control.deadlineTimezone) : ''), 1 /* TEXT */), _createElementVNode("td", _hoisted_42, [_createElementVNode("a", {
      href: review.url,
      target: "_blank"
    }, _toDisplayString(review.urlTitle), 9 /* TEXT, PROPS */, _hoisted_43), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(review.files, function (file) {
      return _openBlock(), _createBlock(_component_link_item, {
        key: file.id,
        title: file.name,
        icon: file.mimeType === 'text/uri-list' ? 'link' : 'file',
        link: $options.getAttachmentLink(file)
      }, null, 8 /* PROPS */, ["title", "icon", "link"]);
    }), 128 /* KEYED_FRAGMENT */))])]);
  }), 128 /* KEYED_FRAGMENT */))])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_44, [_createVNode(_component_collapsible_panel, null, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.addAttachment,
        type: "medium",
        class: "text-blue-750 inline-block"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_45, [_createElementVNode("div", _hoisted_46, [$data.isLoaded ? (_openBlock(), _createBlock(_component_attachment_section, {
        key: "attachment-section-".concat($data.attachmentSectionIdx),
        "link-title-predefined": $data.completedFilename,
        "link-url-predefined": $data.completedUrl,
        "uploading-max": $data.uploadingMax,
        "uploading-value": $data.uploadingValue,
        "is-files-disabled": $props.isFilesDisable,
        "files-list": $options.filesList,
        "show-progress-bar": $data.showProgressBar,
        onStartLoading: _cache[0] || (_cache[0] = function ($event) {
          return $data.showProgressBar = true;
        }),
        onInput: $options.handleAddedAttach,
        onUploadFiles: $options.uploadFormDataFiles,
        onUpdate: $options.handleLinkUpdate,
        onDelete: $options.handleRemoveAttachment
      }, null, 8 /* PROPS */, ["link-title-predefined", "link-url-predefined", "uploading-max", "uploading-value", "is-files-disabled", "files-list", "show-progress-bar", "onInput", "onUploadFiles", "onUpdate", "onDelete"])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_47, [_createVNode(_component_risma_button, {
        text: $data.translate.addLink,
        disabled: $data.interactionDisabled,
        type: "util",
        onClick: $options.uploadLinkAsFile
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_feather_icon, {
            icon: "upload",
            class: "stroke-2 mr-1"
          })];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["text", "disabled", "onClick"])])]), _createElementVNode("div", _hoisted_48, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filesList, function (file) {
        return _openBlock(), _createBlock(_component_attachment_item, {
          key: file.id,
          file: file,
          "hide-edit-icon": true,
          onOnDelete: function onOnDelete($event) {
            return $options.handleRemoveAttachment(file.id);
          }
        }, null, 8 /* PROPS */, ["file", "onOnDelete"]);
      }), 128 /* KEYED_FRAGMENT */))])])];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", _hoisted_49, [_createVNode(_component_risma_title, {
    title: $data.translate.addComment,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
    text: $data.completedComment,
    rows: 5,
    class: "w-full",
    placeholder: $data.translate.addCommentForControlCompletion,
    onUpdated: $options.commentUpdated
  }, null, 8 /* PROPS */, ["text", "placeholder", "onUpdated"])]), $options.efficiencyAssessmentVisible ? (_openBlock(), _createElementBlock("div", _hoisted_50, [_createElementVNode("div", _hoisted_51, [_createVNode(_component_risma_title, {
    title: $data.translate.controlEfficiencyAssessment,
    type: "big"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_52, [_createVNode(_component_risma_title, {
    title: $data.translate.currentControlEfficiency,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("div", _hoisted_53, [_createElementVNode("div", _hoisted_54, [_createVNode(_component_risma_label, {
    title: $data.translate.date
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("span", null, _toDisplayString($options.firstEfficiencyAssessmentDate), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_55, [_createVNode(_component_risma_label, {
    title: $data.translate.assessment
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_traffic_light_selector, {
    "model-value": $data.completedEfficiency,
    "custom-traffic-lights": $options.completedEfficiencyTrafficLights,
    class: "self-center",
    "onUpdate:modelValue": $options.updateCompletedEfficiency
  }, null, 8 /* PROPS */, ["model-value", "custom-traffic-lights", "onUpdate:modelValue"])]), _createElementVNode("div", _hoisted_56, [_createVNode(_component_risma_label, {
    title: $data.translate.comment
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_text_box, {
    text: $data.completedEfficiencyComment,
    rows: 3,
    "max-length": 500,
    class: "w-full",
    placeholder: $data.translate.addCommentForControlEffectiveness,
    onUpdated: $options.updateCompletedEfficiencyComment
  }, null, 8 /* PROPS */, ["text", "placeholder", "onUpdated"])])])]), $options.efficiencyAssessmentsDataset.length ? (_openBlock(), _createBlock(_component_collapsible_panel, {
    key: 0,
    class: "mt-4"
  }, {
    header: _withCtx(function () {
      return [_createVNode(_component_risma_title, {
        title: $data.translate.previousControlEfficiencyAssessments,
        type: "medium",
        class: "text-blue-750 inline-block"
      }, null, 8 /* PROPS */, ["title"])];
    }),
    content: _withCtx(function () {
      return [_createVNode(_component_static_table, {
        "dataset-table": $options.efficiencyAssessmentsDataset,
        columns: $options.efficiencyAssessmentsColumns,
        "columns-min-width": {
          date: '190px',
          user: '100px'
        }
      }, {
        assessment: _withCtx(function (_ref) {
          var slotData = _ref.slotData;
          return [_createVNode(_component_traffic_light, {
            color: slotData.color
          }, null, 8 /* PROPS */, ["color"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["dataset-table", "columns"])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), $data.isLoaded && $data.control.activityTypeId && (_$options$currentActi = $options.currentActivityType) !== null && _$options$currentActi !== void 0 && (_$options$currentActi = _$options$currentActi.fields) !== null && _$options$currentActi !== void 0 && _$options$currentActi.length ? (_openBlock(), _createElementBlock("div", _hoisted_57, [_createVNode(_component_activity_custom_fields, {
    "activity-type": $options.currentActivityType,
    "custom-field-values": $data.control.customFieldValues,
    onChanged: $options.onActivityCustomFieldsChanged
  }, null, 8 /* PROPS */, ["activity-type", "custom-field-values", "onChanged"])])) : _createCommentVNode("v-if", true), $options.reviewers ? (_openBlock(), _createElementBlock("div", _hoisted_58, [_createVNode(_component_risma_title, {
    title: $data.translate.startReview,
    type: "medium"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("p", _hoisted_59, _toDisplayString($data.translate.completingThisControlWillStartTheReviewWork) + ": " + _toDisplayString($options.reviewers), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_60, [_createElementVNode("div", _hoisted_61, [_createVNode(_component_risma_button, {
    text: $data.translate.saveAndClose,
    disabled: $data.interactionDisabled,
    title: $data.interactionDisabled ? $data.translate.fileUploadInProgress : '',
    type: "util",
    class: "mr-2",
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return $options.handleCompleteControl('$event', false, true);
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_feather_icon, {
        icon: "save",
        class: "stroke-3 mr-1"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "title"]), $options.isPartiallyFlowOn || $options.showSkipButton ? (_openBlock(), _createBlock(_component_risma_split_button, {
    key: 0,
    text: $data.translate.submit,
    disabled: $data.interactionDisabled,
    title: $data.interactionDisabled ? $data.translate.fileUploadInProgress : '',
    "disabled-dropdown": false,
    "vertical-dropdown-position": "top",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return $options.handleCompleteControl('$event');
    })
  }, {
    dropContent: _withCtx(function () {
      return [$options.isPartiallyFlowOn ? (_openBlock(), _createElementBlock("div", _hoisted_62, [_createElementVNode("button", {
        text: $data.translate.completedWithActions,
        disabled: $data.interactionDisabled,
        title: $data.interactionDisabled ? $data.translate.fileUploadInProgress : '',
        class: "focus:outline-none active:outline-none",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.handleCompleteControl('$event', true);
        })
      }, _toDisplayString($data.translate.completedWithActions), 9 /* TEXT, PROPS */, _hoisted_63)])) : _createCommentVNode("v-if", true), $options.showSkipButton ? (_openBlock(), _createElementBlock("div", _hoisted_64, [_createElementVNode("button", {
        disabled: $data.interactionDisabled,
        title: $data.interactionDisabled ? $data.translate.fileUploadInProgress : '',
        class: "focus:outline-none active:outline-none w-full text-left",
        onClick: _cache[3] || (_cache[3] = function () {
          return $options.handleSkipControl && $options.handleSkipControl.apply($options, arguments);
        })
      }, _toDisplayString($data.translate.skip), 9 /* TEXT, PROPS */, _hoisted_65)])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["text", "disabled", "title"])) : (_openBlock(), _createBlock(_component_risma_button, {
    key: 1,
    text: $data.translate.submit,
    disabled: $data.interactionDisabled,
    type: "save",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return $options.handleCompleteControl('$event');
    })
  }, null, 8 /* PROPS */, ["text", "disabled"]))])])])) : _createCommentVNode("v-if", true)]);
}