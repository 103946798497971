import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "risma-split-button inline-flex relative"
};
var _hoisted_2 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("button", {
    type: "button",
    class: _normalizeClass(['rounded-l-large mr-0 ', {
      'rounded-large': $options.isSlotEmpty
    }, {
      'rounded-r-large cursor-default': $props.disabledDropdown
    }, $props.noHover || $props.disabled ? 'cursor-default' : 'hover:bg-blue-650 cursor-pointer', $options.buttonStyle]),
    disabled: $props.disabled,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('click');
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), _createTextVNode(" " + _toDisplayString($props.text), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_2), !$options.isSlotEmpty && !$props.disabledDropdown ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    class: _normalizeClass(['rounded-r-large ml-0', $options.buttonStyle, $props.disabled ? 'cursor-not-allowed' : 'hover:bg-blue-650 cursor-pointer', {
      'bg-blue-650': $data.showDropdown
    }]),
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.toggleDropdown && $options.toggleDropdown.apply($options, arguments);
    })
  }, [_createVNode(_component_feather_icon, {
    icon: "chevron-down",
    class: _normalizeClass(['transform duration-500 text-white stroke-2 mr-2 inline-block align-top'])
  })], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $data.showDropdown && !$options.isSlotEmpty ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass("absolute right-0 p-4 bg-white shadow-modal-sm z-100 whitespace-nowrap ".concat($options.verticalPosition)),
    onClick: _cache[2] || (_cache[2] = function () {
      return $options.hide && $options.hide.apply($options, arguments);
    })
  }, [_renderSlot(_ctx.$slots, "dropContent", {}, undefined, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)])), [[_directive_click_outside, $options.hide]]);
}