import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_risma_title = _resolveComponent("risma-title");
  var _component_gap_summary_table = _resolveComponent("gap-summary-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_risma_title, {
    class: "mb-4",
    title: $data.translate.headline
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_gap_summary_table, {
    columns: $data.columns,
    "dataset-main": $options.dataset,
    "totals-row-enabled": false,
    "subhead-enabled": false,
    onUpdateFromPreview: $options.updateFromPreviewModalLink,
    onDismissPreviewModal: $options.onPreviewModalDismiss
  }, null, 8 /* PROPS */, ["columns", "dataset-main", "onUpdateFromPreview", "onDismissPreviewModal"])]);
}