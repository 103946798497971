function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import cloneDeep from 'lodash/cloneDeep';
import { getTranslate } from './translate';
import { mapState } from 'pinia';
import { useUserStore } from '@/Store/userStore';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaSelect from '@/components/Atoms/Inputs/RismaSelect';
import Modal from '@/components/Molecules/Modal/Modal';
import SingleSelect from '@/components/Atoms/Inputs/SingleSelect';
import { DelegationModalSettings, DelegationTypes } from '@/constants/relations/DelegationModal';
import { compareStrings } from '@/utils/compare';
import { sortAlphabetic } from '@/utils/sort';
import { checkERMByProjectId } from '@/utils/risks/check';
import { MODULES } from '@/constants/modules';
import { removeDuplicates } from '@/utils/filterArray';
import { generateKey } from '@/utils/Utils';
import { getUsersWithoutTimeout } from '@/api/risma/data';
export default {
  name: 'DelegationModal',
  introduction: 'DelegationModal multiselect popup',
  description: 'DelegationModal with  selector and list of selected items',
  token: '<delegation-modal :options-list=[] :preselected-items=[] />',
  components: {
    FeatherIcon: FeatherIcon,
    Modal: Modal,
    RismaSelect: RismaSelect,
    SingleSelect: SingleSelect
  },
  props: {
    optionsList: {
      type: Array,
      required: true,
      note: 'List of all delegation options to choose from (users, organisations)'
    },
    delegationListLabel: {
      type: String,
      required: false,
      default: '',
      note: 'Display delegation list label that initiated modal for editing'
    },
    preselectedItems: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      },
      note: 'List of already selected delegation items'
    },
    modalSettings: {
      type: Object,
      required: false,
      default: function _default() {
        return DelegationModalSettings.users;
      },
      note: 'Fields settings for delegation modal'
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Can multiple entities be assigned'
    },
    showAddItem: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Display add item button or not'
    },
    withReset: {
      type: Boolean,
      required: false,
      default: true,
      note: 'Display remove icon next to selected items'
    },
    accessRights: {
      required: false,
      type: Object,
      default: function _default() {
        return {};
      }
    },
    skipApi: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['updateDelegationItem', 'closeDelegationModal', 'create', 'update:optionsList', 'update:preselectedItems'],
  data: function data() {
    return {
      selectedData: [],
      additionalOptions: [],
      searchValue: '',
      total: 0,
      start: 0,
      limit: 100,
      isLazyLoading: false,
      loadedItems: 0,
      fetchRequest: [],
      timeoutBeforeRequest: 300,
      isSelectExpanded: false,
      translate: getTranslate['DelegationModal']()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(useUserStore, ['currentUser'])), {}, {
    allDataShown: function allDataShown() {
      if (this.isUserList) return this.total <= this.sortedOptionsList.length;
      return true;
    },
    userAPIAdditionalParameters: function userAPIAdditionalParameters() {
      return {
        filters: this.getRightFiltersByAccess(this.accessRights)
      };
    },
    singleSelectOptions: function singleSelectOptions() {
      var _this = this;
      if (this.isMultiple) return [];
      return this.sortedOptionsList.map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          label: option[_this.modalSettings.titleField],
          value: option.id
        });
      });
    },
    sortedOptionsList: function sortedOptionsList() {
      var _this2 = this;
      var list = removeDuplicates([].concat(_toConsumableArray(this.optionsList), _toConsumableArray(this.additionalOptions)));
      return list.sort(function (a, b) {
        return compareStrings(a[_this2.modalSettings.labelKey], b[_this2.modalSettings.labelKey]);
      });
    },
    isUserList: function isUserList() {
      return this.modalSettings.type === DelegationTypes.users;
    },
    searchPlaceholder: function searchPlaceholder() {
      return this.$trans(this.modalSettings.searchPlaceholder);
    },
    optionsLabel: function optionsLabel() {
      return this.delegationListLabel || this.$trans(this.modalSettings.label);
    },
    isCurrentUserSelected: function isCurrentUserSelected() {
      var _this3 = this;
      if (this.isMultiple) {
        return this.selectedData.find(function (user) {
          return user.id === +_this3.currentUser.id;
        });
      }
      return this.selectedData === this.currentUser.id;
    },
    showAssignToMeButton: function showAssignToMeButton() {
      return this.modalSettings.enableAssignToMe && !this.isCurrentUserSelected;
    },
    excludedIds: function excludedIds() {
      if (this.searchValue) return [];
      if (this.isMultiple) {
        return this.selectedData.map(function (item) {
          return item.id;
        });
      }
      return [this.selectedData];
    }
  }),
  watch: {
    preselectedItems: {
      deep: true,
      handler: function handler() {
        this.handleSelectedDataUpdate();
      }
    }
  },
  // so it would send selectedItemIds before searchableSelector is mounted;
  created: function created() {
    this.handleSelectedDataUpdate();
  },
  mounted: function mounted() {
    if (this.isUserList) {
      this.createRequest();
    }
  },
  methods: {
    handleSelectedDataUpdate: function handleSelectedDataUpdate() {
      var _this4 = this;
      this.selectedData = this.sortedOptionsList.filter(function (item) {
        return _this4.preselectedItems.includes(item.id);
      });
      if (!this.isMultiple) this.selectedData = this.selectedData.length ? this.selectedData[0].id : null;
    },
    onSingleSelectChange: function onSingleSelectChange(id) {
      this.selectedData = id;
      var data = this.singleSelectOptions.find(function (option) {
        return +option.id === +id;
      });
      if (data) this.$emit('update:optionsList', [data]);
      this.$emit('update:preselectedItems', [id]);
    },
    updateOptionsList: function updateOptionsList(list) {
      this.additionalOptions = removeDuplicates([].concat(_toConsumableArray(this.additionalOptions), _toConsumableArray(list)));
    },
    getRightFiltersByAccess: function getRightFiltersByAccess(data) {
      var module = data.module,
        projectId = data.projectId;
      switch (module) {
        case MODULES.RISK:
          return checkERMByProjectId(projectId, this.currentUser) ? {
            hasAccessToModule: module
          } : {
            hasAccessToRiskProject: projectId
          };
        case MODULES.COMPLIANCE:
          return {
            hasAccessToComplianceProject: projectId
          };
        case MODULES.COMPLIANCE_PROCESS_TREE_NODE:
          return {
            hasAccessToProcessLibrary: projectId
          };
        default:
          return {
            hasAccessToModule: module
          };
      }
    },
    selected: function selected(data) {
      this.selectedData = data;
      this.$emit('update:optionsList', data);
      this.$emit('update:preselectedItems', data.map(function (item) {
        return item.id;
      }));
    },
    getUsersAPI: function getUsersAPI(params) {
      return getUsersWithoutTimeout(params).then(function (response) {
        response.list = sortAlphabetic(response.list, 'display_name');
        return Promise.resolve(response);
      });
    },
    openCreationModal: function openCreationModal() {
      this.$emit('create');
    },
    assignToMe: function assignToMe() {
      if (this.isMultiple) {
        this.selected([].concat(_toConsumableArray(this.selectedData), [this.currentUser]));
      } else {
        this.onSingleSelectChange(this.currentUser.id);
      }
    },
    toggleSelect: function toggleSelect(isExpanded) {
      this.isSelectExpanded = isExpanded;
    },
    handleInput: function handleInput(text) {
      var _this5 = this;
      if (this.allDataShown || text.length < 2) return;
      this.start = 0;
      this.searchValue = text;
      this.isLoading = true;
      clearTimeout(this.requestTimer);
      this.requestTimer = setTimeout(function () {
        _this5.createRequest();
      }, this.timeoutBeforeRequest);
    },
    createRequest: function createRequest() {
      var _this6 = this;
      var id = generateKey();
      var request = new Promise(function (res) {
        return _this6.getUsersAPI(_this6.getParams()).then(function (response) {
          var requestIdx = _this6.fetchRequest.findIndex(function (req) {
            return req.id === id;
          });
          if (requestIdx === _this6.fetchRequest.length - 1) {
            // last request
            res(response);
          }
        });
      }).then(function (response) {
        _this6.fetchRequest = [];
        _this6.isLazyLoading = false;
        _this6.handleResponse(response);
      }).catch(function () {});
      this.fetchRequest.push({
        id: id,
        request: request
      });
      return request;
    },
    handleResponse: function handleResponse(response) {
      var list = response.list,
        total = response.total;
      this.total = total + this.excludedIds.length;
      this.updateOptionsList(list);
    },
    handleLoadNewDataPortion: function handleLoadNewDataPortion() {
      if (this.allDataShown || this.fetchRequest.length) return;
      this.isLazyLoading = true;
      this.start += this.limit;
      this.createRequest();
    },
    getParams: function getParams() {
      var filterBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      if (filterBy.length) {
        var result = cloneDeep(this.userAPIAdditionalParameters);
        result.filters = Object.assign(result.filters || {}, {
          id: filterBy
        });
        return result;
      }
      var params = _objectSpread({
        q: this.searchValue,
        limit: this.limit,
        start: this.start
      }, cloneDeep(this.userAPIAdditionalParameters));
      if (this.excludedIds.length) {
        params.excludes = Object.assign(params.excludes || {}, {
          id: this.excludedIds
        });
      }
      return params;
    }
  }
};