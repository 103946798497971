import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "tag-item flex items-center justify-between my-0.5 py-1 pl-2.5 pr-1 rounded-lg text-sm font-medium whitespace-nowrap text-white bg-blue-920"
};
var _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_feather_icon = _resolveComponent("feather-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    title: $props.title,
    class: "truncate"
  }, _toDisplayString($props.title), 9 /* TEXT, PROPS */, _hoisted_2), _createElementVNode("span", null, [_createVNode(_component_feather_icon, {
    icon: "x",
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('close');
    })
  })])]);
}