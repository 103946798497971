function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTranslate } from './translate';
import { mapActions } from 'pinia';
import { useTagsStore } from '@/Store/tagsStore';
import InputField from '@/components/Atoms/Inputs/InputField';
import Notification from '@/components/Molecules/Notification';
import Modal from '@/components/Molecules/Modal/Modal';
import { Colors } from '@/Colors';
import * as tagsAPI from '@/api/tags/tags';
export default {
  name: 'TagCreationModal',
  components: {
    InputField: InputField,
    Notification: Notification,
    Modal: Modal
  },
  props: {
    currentTagId: {
      required: false,
      type: [Number, String],
      default: null,
      note: 'if currentTagId exists it should update current tag instead of creation new one'
    },
    tags: {
      required: false,
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['dismiss', 'create', 'update'],
  data: function data() {
    return {
      color: Colors,
      updatedTagName: '',
      modalErrorMessage: '',
      translate: getTranslate['TagCreationModal']()
    };
  },
  computed: {
    header: function header() {
      return this.currentTagId ? this.translate.renameTag : this.translate.createTag;
    },
    creationMode: function creationMode() {
      return !this.currentTagId;
    },
    currentTag: function currentTag() {
      var _this = this;
      return this.tags.find(function (tag) {
        return tag.id === _this.currentTagId;
      });
    },
    preparedTagName: function preparedTagName() {
      return this.updatedTagName.trim().replace(/  +/g, ' ');
    },
    hasDuplicates: function hasDuplicates() {
      var _this2 = this;
      return this.tags.some(function (t) {
        return t.tag === _this2.preparedTagName;
      });
    },
    isTitleUpdated: function isTitleUpdated() {
      var _this$currentTag;
      return !this.currentTagId || ((_this$currentTag = this.currentTag) === null || _this$currentTag === void 0 ? void 0 : _this$currentTag.tag) !== this.preparedTagName;
    }
  },
  mounted: function mounted() {
    var _this$currentTag2;
    this.updatedTagName = ((_this$currentTag2 = this.currentTag) === null || _this$currentTag2 === void 0 ? void 0 : _this$currentTag2.tag) || '';
  },
  methods: _objectSpread(_objectSpread({}, mapActions(useTagsStore, {
    newTag: 'newTag',
    updateTag: 'updateTag'
  })), {}, {
    handleAccept: function handleAccept() {
      if (!this.isTitleUpdated) {
        this.$emit('dismiss');
        return;
      }
      if (this.currentTagId && this.hasDuplicates) {
        this.modalErrorMessage = 'Tag already exist';
        return;
      }
      if (!this.isTagValidToSave()) return;
      if (this.creationMode) {
        this.createNewTag();
      } else {
        this.updateSelectedTag();
      }
    },
    createNewTag: function createNewTag() {
      var _this3 = this;
      var data = {
        tagName: this.preparedTagName
      };
      tagsAPI.create(data).then(function (response) {
        _this3.handleTagSuccess('create', response);
        _this3.newTag(response);
      }).catch(this.handleTagFailure);
    },
    updateSelectedTag: function updateSelectedTag() {
      var _this4 = this;
      var data = {
        name: this.preparedTagName
      };
      tagsAPI.update(this.currentTagId, data).then(function (response) {
        _this4.handleTagSuccess('update', response);
        _this4.updateTag(response);
      }).catch(this.handleTagFailure);
    },
    handleTagSuccess: function handleTagSuccess(emit, response) {
      this.$emit(emit, response);
    },
    handleTagFailure: function handleTagFailure(error) {
      this.modalErrorMessage = error.response;
    },
    isTagValidToSave: function isTagValidToSave() {
      if (!this.preparedTagName) {
        this.modalErrorMessage = 'Title cannot be empty';
        return false;
      }
      if (!this.currentTagId && this.hasDuplicates) {
        this.modalErrorMessage = 'Tag already exist';
      }
      return !this.hasDuplicates;
    }
  })
};