function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';
import FeatherIcon from '@/components/Atoms/FeatherIcon/FeatherIcon';
import RismaTableCell from '@/components/Molecules/RismaTable/RismaTableCell';
import RismaTableColumnMenu from '@/components/Molecules/RismaTable/RismaTableColumnMenu';
import LoadingIndicatorLarge from '@/components/Atoms/LoadingIndicators/Large';
import { SortDirection } from '@/constants/SortDirection';
import { clickOutside } from '@/utils/directives/clickOutside';
export default {
  name: 'RismaTableBody',
  description: 'Tables main part',
  token: '<risma-table-body :columns="[]" :datasetMain="[]"/>',
  components: {
    draggable: draggable,
    FeatherIcon: FeatherIcon,
    RismaTableCell: RismaTableCell,
    RismaTableColumnMenu: RismaTableColumnMenu,
    LoadingIndicatorLarge: LoadingIndicatorLarge
  },
  directives: {
    clickOutside: clickOutside
  },
  inject: {
    tableDataLoading: {
      default: false
    }
  },
  props: {
    columns: {
      required: true,
      type: Array,
      note: 'Array of objects with columns data'
    },
    datasetMain: {
      required: true,
      type: Array,
      note: 'Array of objects with rows data'
    },
    bodyHeight: {
      type: [String, Number],
      required: false,
      default: ''
    },
    bodyOffset: {
      type: [String, Number],
      required: false,
      default: 300
    },
    underlyingCheckboxValues: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['columnsChange', 'sortByColumn', 'dismissModal', 'delete', 'filtersChange', 'columnResize', 'starred', 'error', 'reload', 'iconClicked', 'update:loading', 'update:columns'],
  data: function data() {
    return {
      resizedColumn: null,
      neighboringColumn: null,
      localChangedColumns: [],
      startCursorPosition: 0,
      startTableWidth: 0,
      SortDirection: SortDirection,
      openColumnMenu: null,
      blockColumnMenu: false,
      tableHeaderHeight: '40px',
      translate: {
        columnsWithAppliedFilters: this.$trans('Columns with applied filters'),
        noRecordsMatching: this.$trans('No records matching')
      }
    };
  },
  computed: {
    columnMenuAdditionalOption: function columnMenuAdditionalOption() {
      if (!this.openColumnMenu) return '';
      if (this.openColumnMenu.includeUnderlyingFilterVisible) {
        return this.openColumnMenu.includeUnderlyingFilterVisibleLabel + ' ' + this.openColumnMenu.label;
      }
      return '';
    },
    heightStyle: function heightStyle() {
      var _this$columnsWithSele;
      if (this.bodyHeight) return "".concat(this.bodyHeight, "px");
      var bodyOffset = this.bodyOffset || 375;
      if ((_this$columnsWithSele = this.columnsWithSelectedFilters) !== null && _this$columnsWithSele !== void 0 && _this$columnsWithSele.length) {
        bodyOffset += 50;
      }
      return "calc(100vh - ".concat(bodyOffset, "px)");
    },
    columnsWithSelectedFilters: function columnsWithSelectedFilters() {
      var result = [];
      this.columns.forEach(function (col) {
        if (col.filters && col.filters.some(function (filter) {
          return filter.active;
        })) {
          result.push(col);
        }
        if (col.filterApi && col.filterApi.activeValues.length > 0) {
          result.push(col);
        }
        if (col.filterFreeText && col.filterFreeText.searchString !== '') {
          result.push(col);
        }
        if (col.datesFilter && col.datesFilter['after'] && col.datesFilter['before']) {
          result.push(col);
        }
      });
      return result;
    },
    stickyColumnsKeys: function stickyColumnsKeys() {
      if (!this.columns) return [];
      return this.columns.filter(function (col) {
        return col.sticky;
      }).map(function (col) {
        return col.key;
      });
    },
    visibleColumns: function visibleColumns() {
      return this.columns.filter(function (col) {
        return col.visible;
      });
    }
  },
  watch: {
    tableDataLoading: function tableDataLoading(isLoading) {
      if (!isLoading) {
        this.adjustFilterMenuOffset();
      }
    }
  },
  unmounted: function unmounted() {
    this.discardResizeEvents();
  },
  methods: {
    onResizerMousedown: function onResizerMousedown(e, index) {
      if (this.visibleColumns.length < 2) return;
      var parentElement = e.target.parentElement;
      var neighboringElement = parentElement.nextElementSibling || parentElement.previousElementSibling;
      var neighboringCol = this.columns.slice(index + 1).find(function (col) {
        return col.visible;
      }) || this.columns.slice(0, index).findLast(function (col) {
        return col.visible;
      });
      this.resizedColumn = this.getColumnResizingObject(parentElement, this.columns[index].key);
      this.neighboringColumn = this.getColumnResizingObject(neighboringElement, neighboringCol.key);
      this.startCursorPosition = e.pageX;
      this.startTableWidth = this.$refs.table.offsetWidth;
      this.setupResizeEvents();
    },
    onMouseMove: function onMouseMove(e) {
      if (!this.resizedColumn) return;
      var tableHasScroll = this.$refs.rismaTableWrapper.scrollWidth > this.$refs.rismaTableWrapper.offsetWidth;
      var cursorOffset = e.pageX - this.startCursorPosition;
      var offsetResult = "".concat(this.resizedColumn.startWidth + cursorOffset, "px");
      if (cursorOffset > 0 || tableHasScroll) {
        this.$refs.table.style.width = "".concat(this.startTableWidth + cursorOffset, "px");
        this.resizedColumn.el.style.width = offsetResult;
        this.resizedColumn.el.style.minWidth = offsetResult;
      } else {
        this.resizedColumn.el.style.width = offsetResult;
        this.resizedColumn.el.style.minWidth = offsetResult;
        this.neighboringColumn.el.style.width = "".concat(this.neighboringColumn.startWidth - cursorOffset, "px");
      }
    },
    onMouseUp: function onMouseUp(e) {
      if (!this.resizedColumn) return;
      if (!e.target.classList.contains('resizer') && this.resizedColumn.el.contains(e.target)) {
        this.blockColumnMenu = true;
      }
      this.$emit('columnResize', {
        key: this.resizedColumn.key,
        width: this.resizedColumn.el.offsetWidth
      });
      this.$emit('columnResize', {
        key: this.neighboringColumn.key,
        width: this.neighboringColumn.el.offsetWidth
      });
      this.neighboringColumn = null;
      this.resizedColumn = null;
      this.startCursorPosition = 0;
    },
    getColumnResizingObject: function getColumnResizingObject(element, key) {
      if (!element) return null;
      return {
        el: element,
        startWidth: element.offsetWidth,
        key: key
      };
    },
    setupResizeEvents: function setupResizeEvents() {
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    discardResizeEvents: function discardResizeEvents() {
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
    displayColumnMenu: function displayColumnMenu(evt, column, colIndex) {
      var _column$filters;
      if (!column.sortable && !((_column$filters = column.filters) !== null && _column$filters !== void 0 && _column$filters.length) && !column.filterApi) return;
      if (this.blockColumnMenu) {
        this.blockColumnMenu = false;
        return;
      }
      var th = evt.target.closest('th');
      this.openColumnMenu = _objectSpread(_objectSpread({}, column), {}, {
        colIndex: colIndex,
        offsetLeft: th.offsetLeft + 'px',
        offsetTop: this.tableHeaderHeight
      });
      this.$nextTick(function () {
        var menuElement = document.getElementsByClassName('column-menu')[0];
        if (!menuElement) return;
        menuElement.scrollIntoView({
          block: 'nearest'
        });
      });
    },
    closeColumnMenu: function closeColumnMenu(e) {
      var _e$target;
      if (e && e !== null && e !== void 0 && (_e$target = e.target) !== null && _e$target !== void 0 && _e$target.closest('.table-date-range-picker')) return;
      this.openColumnMenu = null;
    },
    onFiltersChange: function onFiltersChange(data) {
      this.$emit('filtersChange', data);
    },
    deselectAllFiltersFor: function deselectAllFiltersFor(columnKey, isString) {
      var filters = isString ? '' : [];
      this.$emit('filtersChange', {
        filters: filters,
        columnKey: columnKey
      });
    },
    sortByColumn: function sortByColumn(_ref) {
      var index = _ref.index,
        direction = _ref.direction;
      this.$emit('sortByColumn', {
        index: index,
        direction: direction
      });
      this.$nextTick(this.closeColumnMenu);
    },
    onSortIconClick: function onSortIconClick(element, index) {
      var reversedDirection = element.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
      this.sortByColumn({
        index: index,
        direction: reversedDirection
      });
    },
    getHeaderClasses: function getHeaderClasses(element) {
      if (!element.classes) return '';
      return element.classes.join(' ');
    },
    getHeaderStyles: function getHeaderStyles(element) {
      var result = {};
      if (element.styles) {
        result = _objectSpread({}, element.styles);
      }
      if (element.userSelectedWidth) {
        result = _objectSpread(_objectSpread({}, result), {}, {
          minWidth: element.userSelectedWidth + 'px',
          width: element.userSelectedWidth + 'px'
        });
      }
      return result;
    },
    columnsChange: function columnsChange(_ref2) {
      var moved = _ref2.moved;
      if (moved) {
        var column = moved.element;
        var columns = cloneDeep(this.columns);
        columns.splice(moved.oldIndex, 1);
        columns.splice(moved.newIndex, 0, column);
        this.$emit('columnsChange', columns);
      }
    },
    adjustFilterMenuOffset: function adjustFilterMenuOffset() {
      var _this = this;
      this.$nextTick(function () {
        var menuElement = document.querySelector('.th-selected');
        if (!menuElement) return;
        _this.openColumnMenu = _objectSpread(_objectSpread({}, _this.openColumnMenu), {}, {
          offsetLeft: "".concat(menuElement.offsetLeft, "px")
        });
      });
    }
  }
};